class RetailVendorsActions {
  constructor() {
    this.generateActions(
      'fetchSuccess',
      'fetchError',
      'listSuccess',
      'listError',
      'createSuccess',
      'createError',
      'updateSuccess',
      'updateError',
      'listCategoriesSuccess',
      'listCategoriesError'
    );
  }
}

export default alt.createActions(RetailVendorsActions);
