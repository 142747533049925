import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { TextField } from '@upperhand/playmaker';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import ZebraActions from 'shared/actions/ZebraActions.jsx';
import { DEFAULT, MEDIA_VIEW } from 'shared/stores/ZebraStore.js';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const onChange = handleChange({
  actions: ZebraActions,
  namedAction: 'updatePrinterSettings',
});

const saveMediaSettings = () => {
  ZebraActions.changeView(DEFAULT);
  ZebraActions.update();
};
const closeMediaSettings = () => ZebraActions.changeView(DEFAULT);
const openMediaSettings = () => ZebraActions.changeView(MEDIA_VIEW);

const styles = {
  menuButton: {
    height: 40,
    width: 100,
  },

  button: {
    height: 50,
  },

  buttonLabel: {
    fontSize: 16,
  },
};

const inputStyles = {
  root: {
    marginBottom: 20,
  },
};

function SettingsHeader() {
  return (
    <FlexBoxCenter className="drawer-header">
      <IconButton onClick={closeMediaSettings}>
        <ChevronLeftIcon sx={{ color: uhColors.activeBlue }} />
      </IconButton>
      <h2 className="drawer-header__text">
        <FormattedMessage id={messageId('.media_setting', __filenamespace)} />
      </h2>
    </FlexBoxCenter>
  );
}

function ActionButtons({ intl }) {
  return (
    <div>
      <Button
        fullWidth
        variant="contained"
        style={{ ...styles.button, marginBottom: 10 }}
        onClick={saveMediaSettings}
      >
        {t('actions.save', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="default"
        style={styles.button}
        onClick={closeMediaSettings}
      >
        {t('actions.cancel', intl, __filenamespace)}
      </Button>
    </div>
  );
}

const Settings = withStyles(inputStyles)(
  ({
    classes,
    intl,
    zebraSettings: { widthLabel, heightLabel, widthReceipt },
  }) => (
    <>
      <div className="drawer-body__label drawer-body__heading">
        <FormattedMessage
          id={messageId('.label_dimensions', __filenamespace)}
        />
      </div>
      <div className="drawer-body__label">
        <FormattedMessage
          id={messageId('.label_description', __filenamespace)}
        />
      </div>
      <TextField
        fullWidth
        label={t('.width', intl, __filenamespace)}
        classes={{ root: classes.root }}
        onChange={onChange}
        value={widthLabel}
        name="widthLabel"
      />
      <TextField
        fullWidth
        label={t('.height', intl, __filenamespace)}
        classes={{ root: classes.root }}
        onChange={onChange}
        value={heightLabel}
        name="heightLabel"
      />

      <div className="drawer-body__label drawer-body__heading">
        <FormattedMessage
          id={messageId('.receipt_dimensions', __filenamespace)}
        />
      </div>
      <div className="drawer-body__label">
        <FormattedMessage
          id={messageId('.receipt_description', __filenamespace)}
        />
      </div>
      <TextField
        fullWidth
        label={t('.width', intl, __filenamespace)}
        classes={{ root: classes.root }}
        onChange={onChange}
        value={widthReceipt}
        name="widthReceipt"
      />
    </>
  )
);
function MediaSettingsDrawer({ intl, mediaOpen, zebraSettings }) {
  return (
    <Drawer
      disableEnforceFocus
      hideBackdrop
      open={mediaOpen}
      className="zebra-media-settings-drawer"
      anchor="right"
      PaperProps={{
        sx: {
          width: smallScreen(420)
            ? window.innerWidth * 0.9
            : STANDARD_DRAWER_WIDTH,
        },
      }}
      onClose={closeMediaSettings}
    >
      <SettingsHeader intl={intl} />
      <div className="drawer-body">
        <Settings intl={intl} zebraSettings={zebraSettings} />
        <ActionButtons intl={intl} />
      </div>
    </Drawer>
  );
}

function ZebraMediaSettings({ intl, mediaOpen, zebraSettings }) {
  return (
    <div>
      <Button
        variant="contained"
        color="default"
        style={styles.menuButton}
        onClick={openMediaSettings}
      >
        {t('.media', intl, __filenamespace)}
      </Button>
      <MediaSettingsDrawer
        intl={intl}
        mediaOpen={mediaOpen}
        zebraSettings={zebraSettings}
      />
    </div>
  );
}

export default ZebraMediaSettings;
