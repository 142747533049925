/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  ContentModal,
  Typography,
  Dropdown,
  Grid,
  CurrencyTextField,
  Spinner,
} from '@upperhand/playmaker';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import { Payment, PaymentType } from 'containers/payments';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import Client from 'shared/records/Client.jsx';
import Order from 'event_mgmt/shared/records/Order.jsx';

function TopUpModal({
  accountCreditAmount = null,
  open = false,
  order = new Order(),
  variants = [],
  loading = false,
  loadingVariants = false,
  selectedVariantId = null,
  client = new Client(),
  managingClient = new Client(),
  onClose = () => {},
  onVariantChange = () => {},
  onSuccessfulPurchase = () => {},
  onAccountCreditChanged = () => {},
}) {
  const intl = useIntl();
  const isManagedBy = Boolean(currentUser().managed_by_id);
  const availableAccountCreditAmount = isManagedBy
    ? managingClient.get('total_account_credit', 0)
    : client.get('total_account_credit', 0);
  const hasAccess = isManagedBy
    ? managingClient.access_to_vault
    : client.access_to_vault;
  const purchasingUserId =
    isManagedBy && !currentUser().login_enabled
      ? managingClient.user_id
      : currentUser().user_id;
  const purchaserId =
    isManagedBy && !currentUser().login_enabled ? managingClient.id : client.id;
  const variant = variants[0];
  const selectedVariant = variants.find(v => v.id === selectedVariantId);
  const variantOptions = variants.map(v => ({
    value: v.id,
    label: v.variant,
  }));

  return (
    <ContentModal
      open={open}
      showActions={false}
      onAbort={onClose}
      title={
        <Typography variant="h6">
          {t('.title', intl, __filenamespace)}
        </Typography>
      }
    >
      <ClientDetails client={client} />
      {loadingVariants && (
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <Spinner />
          </Grid>
        </Grid>
      )}
      {!loadingVariants && (
        <>
          <Grid container spacing={2}>
            {variant && (
              <Grid item xs={12}>
                <Typography variant="h5">{variant.name}</Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Dropdown
                fullWidth
                disabled={loadingVariants || loading}
                label={t('.amount', intl, __filenamespace)}
                placeholder={t('.choose_amount', intl, __filenamespace)}
                value={selectedVariantId}
                items={variantOptions}
                onChange={onVariantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyTextField
                disabled
                fullWidth
                value={selectedVariant?.price ? order.total / 100 : 0}
                label={t('.total', intl, __filenamespace)}
                onChange={() => null}
              />
            </Grid>
          </Grid>
          {order?.id && (
            <div className="payment-wrapper">
              <Payment
                allowAccountCredits
                accountCreditAmount={accountCreditAmount}
                availableAccountCreditAmount={availableAccountCreditAmount}
                itemId={order.id}
                total={Number(order.total) > 0 ? order.total : 0}
                order={order}
                paymentType={PaymentType.ORDER}
                hasAccess={hasAccess}
                purchaserId={purchaserId}
                purchasingUserId={purchasingUserId}
                disablePaymentBtn={loading}
                successAction={onSuccessfulPurchase}
                onAccountCreditChanged={onAccountCreditChanged}
                onApplyCredit={onAccountCreditChanged}
              />
            </div>
          )}
        </>
      )}
    </ContentModal>
  );
}

TopUpModal.propTypes = {
  order: PropTypes.instanceOf(Order),
  selectedVariantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  variants: PropTypes.array,
  loading: PropTypes.bool,
  loadingVariants: PropTypes.bool,
  client: PropTypes.instanceOf(Client),
  managingClient: PropTypes.instanceOf(Client),
  accountCreditAmount: PropTypes.number,
  onSuccessfulPurchase: PropTypes.func,
  onClose: PropTypes.func,
  onVariantChange: PropTypes.func,
};

export default memo(TopUpModal);
