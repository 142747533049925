import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions.js';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { MembershipSubscriptionSource } from 'sources';
import { convertDateToServerValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

class MembershipSuspensionStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      stageRequest: MembershipSuspensionActions.SUSPENSION_REQUESTED,
      abortRequest: MembershipSuspensionActions.SUSPENSION_ABORTED,

      suspendMembership: MembershipSuspensionActions.SUSPENSION_CONFIRMED,
      suspendMembershipSuccess:
        MembershipSuspensionActions.SUSPEND_MEMBERSHIP_SUCCESS,
      suspendMembershipError:
        MembershipSuspensionActions.SUSPEND_MEMBERSHIP_ERROR,

      getCustomerSubscriptions:
        MembershipSuspensionActions.GET_CUSTOMER_SUBSCRIPTIONS,
      updateSuspensionMode: MembershipSuspensionActions.REACTIVATE_ON_FUTURE,

      setActiveSubscription:
        MembershipSuspensionActions.LIST_SUBSCRIPTION_SUCCESS,
      handleListSubscriptionError:
        MembershipSuspensionActions.LIST_SUBSCRIPTION_ERROR,

      setSuspendAtDate: MembershipSuspensionActions.SET_SUSPEND_AT_DATE,
      setReactivationDate: MembershipSuspensionActions.SET_REACTIVATION_DATE,

      reactivateRequest: MembershipSuspensionActions.REACTIVATION_REQUESTED,
      reactivationAborted: MembershipSuspensionActions.REACTIVATION_ABORTED,
      reactivateMembership: MembershipSuspensionActions.REACTIVATE_MEMBERSHIP,
      reactivationMembershipSuccess:
        MembershipSuspensionActions.REACTIVATE_MEMBERSHIP_SUCCESS,
      reactivationMembershipError:
        MembershipSuspensionActions.REACTIVATE_MEMBERSHIP_ERROR,
    });
  }

  reset() {
    this.client = null;
    this.membership = null;
    this.suspendAt = null;
    this.reactivationDate = null;
    this.subscription = null;
    this.reactivateOnFuture = false;
    this.suspendInProgress = false;
    this.showReactivationDialog = false;
  }

  stageRequest([client, membership]) {
    this.client = client;
    this.membership = membership;

    this.getCustomerSubscriptions({ customerUserId: client.id });
  }

  abortRequest() {
    this.reset();
  }

  updateSuspensionMode() {
    this.reactivateOnFuture = !this.reactivateOnFuture;
  }

  setSuspendAtDate(date) {
    this.suspendAt = date;
    if (this.reactivationDate && this.suspendAt >= this.reactivationDate) {
      this.reactivationDate = null;
    }
  }

  setReactivationDate(date) {
    this.reactivationDate = date;
  }

  suspendMembership() {
    if (!this.subscription || this.suspensionInProgress) {
      return;
    }

    this.suspendInProgress = true;
    const data = {
      suspend_at: convertDateToServerValue(this.suspendAt),
    };
    if (this.reactivateOnFuture) {
      data.reactivate_on = convertDateToServerValue(this.reactivationDate);
    }
    uhApiClient.post({
      url: `membership_subscriptions/${this.subscription.id}/suspend`,
      data: JSON.stringify(data),
      success: MembershipSuspensionActions.suspendMembershipSuccess,
      error: MembershipSuspensionActions.suspendMembershipError,
    });
  }

  suspendMembershipSuccess(_data) {
    this.reset();
    MessageWindowActions.addMessage.defer(
      'Subscription was successfully suspended!'
    );
  }

  suspendMembershipError(...args) {
    this.suspendInProgress = false;
    this.notifyError('error suspending client membership subscription', args);
  }

  reactivateRequest([client, membership, active]) {
    this.membership = membership;
    this.client = client;
    this.showReactivationDialog = true;
    this.getCustomerSubscriptions({
      customerUserId: client.id,
      activeSubscriptions: active,
    });
  }

  reactivationAborted() {
    this.reset();
  }

  reactivateMembership() {
    if (!this.subscription) {
      return;
    }

    uhApiClient.post({
      url: `membership_subscriptions/${this.subscription.id}/reactivate`,
      success: MembershipSuspensionActions.reactivateMembershipSuccess,
      error: MembershipSuspensionActions.reactivateMembershipError,
    });
  }

  reactivationMembershipSuccess(_data) {
    this.reset();
    MessageWindowActions.addMessage.defer(
      'Subscription was successfully reactivated!'
    );
  }

  reactivationMembershipError(...args) {
    this.reset();
    this.notifyError('error client reactivation membership subscription', args);
  }

  getCustomerSubscriptions({ customerUserId, activeSubscriptions = true }) {
    this.isLoading = true;

    MembershipSubscriptionSource.list({
      params: {
        client_ids: [customerUserId],
        // cannot reactivate expired membership, so not requesting them at all
        status: activeSubscriptions ? 'active' : 'suspended',
        per_page: 1, // expires_at sort by default, so receiving the most recent subscription
        fields: ['membership', 'has_embed_product'],
      },
      success: MembershipSuspensionActions.listSubscriptionSuccess,
      error: MembershipSuspensionActions.listSubscriptionError,
    });
  }

  setActiveSubscription({ membership_subscriptions: membershipSubscriptions }) {
    if (membershipSubscriptions.isEmpty()) {
      this.reset();
      return;
    }

    this.subscription = membershipSubscriptions.first();
    this.membership = this.subscription.membership;
  }

  handleListSubscriptionError(...args) {
    this.notifyError('error listing client membership subscriptions', args);
  }
}

export default alt.createStore(
  MembershipSuspensionStore,
  'MembershipSuspensionStore'
);
