import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  expanderRow: {
    fontSize: 15,
    paddingLeft: 5,
    cursor: 'pointer',
  },

  CollapsibleIcon: {
    verticalAlign: 'middle',
    color: '#878FA4',
  },

  EmptyRow: {
    fontWeight: 'bold',
    color: '#878FA4',
  },
};

function Title({ title, expanded }) {
  return (
    <div>
      <ArrowDropDownIcon
        style={merge(
          styles.CollapsibleIcon,
          expanded ? {} : { transform: 'rotate(-90deg)' }
        )}
      />
      <p style={{ display: 'inline-block' }}>{title}</p>
    </div>
  );
}

export function EmptyRow({ colSpan }) {
  return (
    <tr style={styles.EmptyRow}>
      <td style={{ textAlign: 'center' }} colSpan={colSpan}>
        n/a
      </td>
    </tr>
  );
}

class CollapsibleTable extends React.Component {
  state = {
    expanded: true,
  };

  toggle = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { expanded } = this.state;
    const { columns, title, children, totals } = this.props;

    return (
      <table className="ReportTable SalesDetailTable">
        <thead>
          <tr onClick={this.toggle}>
            <th colSpan={columns.length} style={styles.expanderRow}>
              <Title title={title} expanded={expanded} />
            </th>
          </tr>

          {expanded && (
            <tr>
              {columns.map(c => (
                <th key={c}>
                  <FormattedMessage id={c} />
                </th>
              ))}
            </tr>
          )}
        </thead>

        {expanded && <tbody>{children}</tbody>}
        {expanded && totals}
      </table>
    );
  }
}

export default CollapsibleTable;
