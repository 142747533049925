import * as React from 'react';
import { injectIntl } from 'react-intl';

import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import ContactGroupStore from 'shared/stores/ContactGroupStore.jsx';
import DeleteConfirmationModal from 'shared/components/DeleteConfirmationModal.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import MessagingDrawer from 'shared/components/MessagingDrawer.jsx';
import NoClientsIcon from 'shared/components/icons/empty_states/Clients.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import GroupsTabSmall from './components/GroupsTabSmall.jsx';
import GroupsTabLarge from './components/GroupsTabLarge.jsx';
import ContactsGroupsStore from './Store';
import ContactsGroupsActions from './Actions';

const ContactsGroups = injectIntl(
  ({
    contactGroupStore: {
      allContactGroups,
      awaitingConfirmation,
      contactGroup,
      isAddingContact,
      isLoading,
      isSaving,
      selectedContacts,
    },
    contactsGroupsStore: {
      addContactContactList,
      contactList,
      contactListParams,
      allClientsCount,
      allStaffCount,
    },
    intl,
  }) => {
    React.useEffect(() => {
      ContactsGroupsActions.mounted();
    }, []);

    if (contactGroup) {
      return (
        <div>
          <SpinWhileLoading isLoading={isLoading} spinWhile="isLoading">
            <ResponsiveElement
              largeScreen={
                <GroupsTabLarge
                  addContactContactList={addContactContactList}
                  allContactGroups={allContactGroups}
                  contactGroup={contactGroup}
                  isSaving={isSaving}
                  selectedContacts={selectedContacts}
                  contacts={contactList}
                  updateTableStore={() => {}}
                  perPage={contactListParams.get('per_page')}
                  currentPage={contactListParams.get('page')}
                  totalCount={contactListParams.get('total_count')}
                  allContactsCount={allClientsCount + allStaffCount}
                />
              }
              smallScreen={
                <GroupsTabSmall
                  addContactContactList={addContactContactList}
                  allContactGroups={allContactGroups}
                  contactGroup={contactGroup}
                  isSaving={isSaving}
                  isAddingContact={isAddingContact}
                  selectedContacts={selectedContacts}
                  contacts={contactList}
                  updateTableStore={() => {}}
                  perPage={contactListParams.get('per_page')}
                  currentPage={contactListParams.get('page')}
                  totalCount={contactListParams.get('total_count')}
                  allContactsCount={allClientsCount + allStaffCount}
                />
              }
            />
            <DeleteConfirmationModal
              awaitingConfirmation={awaitingConfirmation}
              cancelDelete={ContactGroupActions.deleteCancelled}
              doDelete={ContactGroupActions.delete}
              name={t('.contact_group', intl, __filenamespace)}
              title={t('.remove_contact_group', intl, __filenamespace)}
            />
          </SpinWhileLoading>
          <MessagingDrawer title={t('.create_email', intl, __filenamespace)} />
        </div>
      );
    }
    return (
      <EmptyState
        image={<NoClientsIcon />}
        headerText={t('.empty_state_header', intl, __filenamespace)}
        messageText={t('.empty_state_message', intl, __filenamespace)}
        primaryAction={{
          label: t('.create_contact_group', intl, __filenamespace),
          action: ContactsGroupsActions.selectContactGroup,
        }}
      />
    );
  }
);

export default altContainer({
  stores: {
    contactGroupStore: ContactGroupStore,
    contactsGroupsStore: ContactsGroupsStore,
  },
})(ContactsGroups);
