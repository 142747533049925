import * as React from 'react';

import Columns from 'calendar/components/views/day/_Columns.jsx';
import CustomizeButton from 'calendar/components/views/shared/_CustomizeButton.jsx';
import StandardCalendar from 'calendar/components/views/shared/_StandardCalendar.jsx';

import * as CalendarUtils from 'calendar/utils/CalendarUtils.jsx';
import OWNER_TYPE from 'calendar/types/OwnerType.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const styles = {
  DayWrap: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  Columns: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '0 0 48px',
  },
  CustomizeButton: {
    zIndex: 3,
    top: 0,
    left: 0,
    position: 'sticky',
  },
  CustomizeButtonInnerDiv: {
    position: 'absolute',
    width: '50px',
    height: '42px',
    alignItems: 'center',
    borderBottom: '2px solid #e3e3e3',
    borderRight: '1px solid #e3e3e3',
    backgroundColor: 'white',
  },
};

function Day({
  allStaffMap,
  availableStaffOnly,
  clientsMap,
  currentCalendarView,
  date,
  eventsMap,
  eventTimes,
  fuzzySearch,
  loading,
  membershipsMap,
  resourcesMap,
  showAvailability,
  showPaid,
  showUnpaid,
  staffIds,
  showAllStaff,
  sessions,
}) {
  const isCoach = currentUser().isCoach();
  let owner;

  const calendars = currentCalendarView.calendars().filter(c => {
    switch (c.ownerType) {
      case OWNER_TYPE.STAFF: {
        owner = allStaffMap.get(c.id);
        return owner
          ? CalendarUtils.fuzzySearchCalendarOwner(fuzzySearch, c, owner)
          : false;
      }
      case OWNER_TYPE.RESOURCE:
        owner = resourcesMap.get(c.id);
        return owner
          ? CalendarUtils.fuzzySearchCalendarOwner(fuzzySearch, c, owner)
          : false;
      default:
        return true;
    }
  });

  return (
    <StandardCalendar loading={loading}>
      <div style={styles.CustomizeButton}>
        <FlexBoxJustifyCenter style={styles.CustomizeButtonInnerDiv}>
          {!isCoach && <CustomizeButton />}
        </FlexBoxJustifyCenter>
      </div>
      <div style={styles.Columns}>
        <Columns
          allStaffMap={allStaffMap}
          calendars={calendars}
          availableStaffOnly={availableStaffOnly}
          clientsMap={clientsMap}
          date={date}
          eventsMap={eventsMap}
          eventTimes={eventTimes}
          membershipsMap={membershipsMap}
          resourcesMap={resourcesMap}
          showAvailability={showAvailability}
          showPaid={showPaid}
          showUnpaid={showUnpaid}
          staffIds={staffIds}
          showAllStaff={showAllStaff}
          sessions={sessions}
        />
      </div>
    </StandardCalendar>
  );
}

export default Day;
