import { Map } from 'immutable';
import {
  getAllUrlParameters,
  resetUrl,
  setUrlParameter,
} from 'shared/utils/UrlParameters.js';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class FilterStoreImpl {
  constructor(actions) {
    this.bookedEventsOnly = false;
    this.filterDrawerOpen = false;

    this.resetFilters();

    this.honorAll = true;

    this.statusFilters = Map();
    this.eventStatusFilters = [];

    this.anyFiltersEnabled = () =>
      this.ageFilters.get('enabled') ||
      this.attendeeFilters.some((v, k) => k !== 'all' && v) ||
      this.locationFilters.some((v, k) => k !== 'all' && v) ||
      this.typeFilters.some((v, k) => k !== 'all' && v);

    this.bindListeners(
      merge(actions, {
        handleAthleteList: AthleteActions.LIST_SUCCESS,
        handleEventTypeList: EventTypeListingActions.LIST_SUCCESS,
        handleLocationList: LocationActions.LIST_SUCCESS,
      })
    );
  }

  arrayToObject(arr) {
    const rv = {};
    for (let i = 0; i < arr.length; ++i) {
      rv[arr[i]] = true;
    }
    return rv;
  }

  resetFilters() {
    // A hack to handle if reset is actually initialize or user initiated
    const urlParams = getAllUrlParameters() || Map();
    this.titleFilter = '';
    if (
      typeof this.honorAll === 'undefined' &&
      typeof urlParams.get === 'function'
    ) {
      this.typeFilters =
        typeof urlParams.get('event_type') !== 'undefined'
          ? Map(this.arrayToObject(urlParams.get('event_type').toJS()))
          : Map({ all: true });

      this.ageFilters =
        typeof urlParams.get('age') !== 'undefined'
          ? Map(this.arrayToObject(urlParams.get('age').toJS()))
          : Map({ min: null, max: null, enabled: false });

      this.locationFilters =
        typeof urlParams.get('location') !== 'undefined'
          ? Map(this.arrayToObject(urlParams.get('location').toJS()))
          : Map({ all: true });

      this.attendeeFilters =
        typeof urlParams.get('attendee') !== 'undefined'
          ? Map(this.arrayToObject(urlParams.get('attendee').toJS()))
          : Map({ all: true });
    } else {
      resetUrl();
      this.typeFilters = Map({ all: true });
      this.ageFilters = Map({ all: true });
      this.locationFilters = Map({ all: true });
      this.attendeeFilters = Map({ all: true });
      this.scheduleType = '';
    }
  }

  toggleBookedEventsOnly() {
    this.bookedEventsOnly = !this.bookedEventsOnly;
  }

  updateFilterDrawerOpen(open) {
    this.filterDrawerOpen = open;
  }

  toggleFilterDrawer() {
    this.filterDrawerOpen = !this.filterDrawerOpen;
  }

  updateAttendeeFilters(args) {
    this.attendeeFilters = this.updateFilterSet(
      this.attendeeFilters,
      args[0],
      args[1],
      'attendee'
    );
  }

  updateStatusFilters(args) {
    this.statusFilters = this.updateFilterSet(
      this.statusFilters,
      args[0],
      args[1],
      'status'
    );
  }

  updateTypeFilters(args) {
    this.typeFilters = this.updateFilterSet(
      this.typeFilters,
      args[0],
      args[1],
      'event_type'
    );
  }

  updateLocationFilters(args) {
    this.locationFilters = this.updateFilterSet(
      this.locationFilters,
      args[0],
      args[1],
      'location'
    );
  }

  updateMinAge(age) {
    const newAge = age === '' ? null : parseInt(age, 10);
    this.ageFilters = this.ageFilters.set('min', newAge);
  }

  updateMaxAge(age) {
    const newAge = age === '' ? null : parseInt(age, 10);
    this.ageFilters = this.ageFilters.set('max', newAge);
  }

  toggleAgeFilter(enabled) {
    this.ageFilters = this.ageFilters.set('enabled', enabled);
  }

  updateFilterSet(filterSet, key, value, filterName) {
    if (key === 'all') {
      return this.setAllFilters(filterSet, value);
    }
    const arr = [];
    const newFilterSet = filterSet.withMutations(set => {
      set.set(key, value);
      set.delete('all', value && this.areAllFiltersEnabled(set));
    });

    newFilterSet.forEach((val, filter) => {
      if (val) {
        arr.push(filter);
      }
    });

    setUrlParameter(filterName, arr, window.location.pathname);
    return newFilterSet;
  }

  setAllFilters(filterSet, value) {
    return filterSet.withMutations(set => {
      set.forEach((_, key) => set.set(key, value));
    });
  }

  areAllFiltersEnabled(filterSet) {
    return filterSet.every((value, key) => key === 'all' || value);
  }

  updateTitleFilter(title) {
    this.titleFilter = title;
  }

  updateScheduleTypeFilter(type) {
    this.scheduleType = type;
  }

  handleEventTypeList() {
    this.waitFor(EventTypeListingStore);
    const { isLoading, eventTypes } = EventTypeListingStore.getState();
    if (!isLoading) {
      const typeSetup = Map({ all: true }).withMutations(filterSet =>
        eventTypes.forEach(type => {
          if (!this.typeFilters.has(type.id.toString())) {
            filterSet.set(type.id.toString(), false);
          }
        })
      );

      this.typeFilters = this.typeFilters.merge(typeSetup);
    }
  }

  handleLocationList() {
    this.waitFor(LocationStore);

    const locationSetup = Map({ all: true }).withMutations(filterSet =>
      LocationStore.getState().allLocations.forEach(location => {
        if (!this.locationFilters.has(location.id.toString())) {
          filterSet.set(location.id.toString(), false);
        }
      })
    );
    this.locationFilters = this.locationFilters.merge(locationSetup);
  }

  handleAthleteList() {
    this.waitFor(AthleteStore);

    this.attendeeFilters = Map({ all: true }).withMutations(filterSet => {
      AthleteStore.getState().allAthletes.forEach(athlete => {
        filterSet.set(
          athlete.id.toString(),
          this.attendeeFilters.get(athlete.id.toString(), false)
        );
      });
    });
  }
}

export default FilterStoreImpl;
