import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map, List } from 'immutable';
import { Card, Grid, Typography, Spinner } from '@upperhand/playmaker';

import EmptyState from 'containers/reports/ordersReport/components/OrdersReportTable/EmptyState.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Paginator from 'shared/components/Paginator.jsx';

import Header from './Header.jsx';

function Table({ intl, orders, pagination, loading, onPageSelect }) {
  return (
    <>
      <Header />
      {loading && (
        <div className="closeout-report__loading-container">
          <Spinner />
        </div>
      )}
      {!loading && orders.size === 0 && <EmptyState intl={intl} />}
      {!loading && orders.size > 0 && (
        <div className="closeout-report__orders-list">
          {orders.map(order => {
            const id = order.get('order_id');
            const orderedAt = order.get('effective_at');

            return (
              <Card
                key={id}
                classes={{
                  root: 'closeout-report__row',
                  content: 'closeout-report__row-content',
                }}
              >
                <Grid
                  container
                  key={id}
                  spacing={1}
                  alignContent="center"
                  justify="center"
                >
                  <Grid item md={2} sm={2} xs={6}>
                    <Typography
                      variant="body2"
                      className="closeout-report__row-item"
                    >
                      {moment(orderedAt).format('MM/DD/YYYY • h:mma')}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={6}>
                    <Typography
                      variant="body2"
                      className="closeout-report__row-item"
                    >
                      {order.get('order_code')}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={6}>
                    <Typography
                      variant="body2"
                      className="closeout-report__row-item"
                    >
                      {order.get('buyer_name')}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={6}>
                    <Typography
                      variant="body2"
                      className="closeout-report__row-item"
                    >
                      {order.get('product')}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={6}>
                    <Typography
                      variant="body2"
                      className="closeout-report__row-item"
                    >
                      {order.get('payment_method')}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={6}>
                    <Typography
                      variant="body2"
                      className="closeout-report__row-item"
                    >
                      <FormattedCurrency
                        value={order.get('amount')}
                        fromCents
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        </div>
      )}
      <Paginator
        showInfo
        pageLimit={0}
        className="closeout-report__paginator"
        currentPage={pagination.get('page')}
        perPage={pagination.get('perPage')}
        totalCount={pagination.get('totalCount')}
        onPageSelect={onPageSelect}
      />
    </>
  );
}

Table.propTypes = {
  intl: PropTypes.object.isRequired,
  orders: PropTypes.instanceOf(List).isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  onPageSelect: PropTypes.func.isRequired,
};

export default injectIntl(Table);
