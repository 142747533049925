class PaymentPlansActions {
  constructor() {
    this.generateActions(
      'clientListError',
      'clientListSuccess',
      'listError',
      'listSuccess',
      'mounted',
      'selectPage',
      'updateFilter',
      'list'
    );
  }
}

export default alt.createActions(PaymentPlansActions);
