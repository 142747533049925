import * as React from 'react';
import { injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';

import { t } from 'shared/utils/LocaleUtils.js';
import { getFieldStyles } from 'containers/payments/helpers/field';

function CardNumberField({
  fieldId,
  children,
  errorText,
  hideHint,
  intl,
  onCardNumberChange,
  value,
}) {
  const { inputProps, textFiledStyle, inputLabelProps } = getFieldStyles(
    children,
    fieldId
  );

  return (
    <TextField
      data-fs-exclude
      fullWidth
      name="number"
      variant="standard"
      value={value}
      error={!!errorText}
      helperText={errorText}
      label={t('.card_number', intl, __filenamespace)}
      placeholder={!hideHint ? t('.card_number', intl, __filenamespace) : ''}
      sx={textFiledStyle}
      inputProps={{ maxLength: 19 }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      onChange={e => onCardNumberChange(e, e.target.value)}
    />
  );
}

export default injectIntl(CardNumberField);
