import React from 'react';

import EventTitleAndDate from 'event_mgmt/shared/components/_EventTitleAndDate.jsx';
import EventToolbar from 'event_mgmt/shared/components/_EventToolbar.jsx';
import EventMembershipInfo from 'event_mgmt/shared/components/_EventMembershipInfo.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    fontSize: 16,
  },

  container: {
    flex: '1',
    flexWrap: 'wrap',
    marginLeft: LEFT_MARGIN_PIXELS,
  },

  membershipInfo: {
    marginLeft: LEFT_MARGIN_PIXELS,
    paddingBottom: 10,
  },

  rightContent: {
    flex: '0 0 60%',
    marginBottom: 10,
  },

  titleAndDate: {
    flex: '1 0 auto',
    marginBottom: 10,
  },
};

function EventHeader({
  event,
  memberships,
  actions,
  rightContent,
  style,
  containerStyle,
}) {
  return (
    <div className="iphone-x-content" style={{ ...styles.root, ...style }}>
      <EventToolbar actions={actions} />
      <div style={merge(styles.container, containerStyle)}>
        <EventTitleAndDate
          event={event}
          memberships={memberships}
          style={styles.titleAndDate}
        />
        <div style={styles.rightContent}>{rightContent}</div>
      </div>
      <EventMembershipInfo
        event={event}
        memberships={memberships}
        style={styles.membershipInfo}
      />
    </div>
  );
}

export default EventHeader;
