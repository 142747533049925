import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

function useUpdateTime() {
  const [currentTime, setCurrentTime] = useState(moment().format('h:mm A'));

  useEffect(() => {
    const updateNextMinute = () => {
      const now = moment();
      const secondsUntilNextMinute = 60 - now.seconds(); // Calculate seconds until the next minute
      const millisecondsUntilNextMinute = secondsUntilNextMinute * 1000;

      const timeoutId = setTimeout(() => {
        setCurrentTime(moment().format('h:mm A')); // Update current time when the next minute starts
        updateNextMinute(); // Schedule the next update
      }, millisecondsUntilNextMinute);

      return () => clearTimeout(timeoutId); // Cleanup function to clear timeout
    };

    const cleanup = updateNextMinute(); // Initial call to start updating time

    return cleanup; // Cleanup function for unmounting
  }, []); // Empty dependency array ensures effect runs only once

  return currentTime;
}

export default useUpdateTime;
