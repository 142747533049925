import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import { isNative, browserIncompatible } from 'shared/utils/UserAgentUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  scary: {
    backgroundColor: '#960018',
    color: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    position: 'fixed',
    textAlign: 'center',
    top: 0,
    width: '100%',
    zIndex: 1,
  },

  button: {
    backgroundColor: 'white',
    color: '#960018',
  },

  table: {
    margin: 'auto',
  },

  tr: {
    height: 56,
  },

  td: {
    padding: 10,
    textAlign: 'left',
  },
};

const setTop = () => {
  const banner = document.getElementById('incompatibility-banner');
  const header = document.getElementById('site-header');
  const mainContent = document.getElementById('main-content-wrapper');

  if (banner) {
    const warningHeight = banner.offsetHeight;

    header.style.top = `${warningHeight}px`;
    mainContent.style.paddingTop = `${warningHeight}px`;
  }
};

class IncompatibilityBanner extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', setTop);

    setTop();
  }

  componentWillUnmount() {
    if (typeof setTop !== 'undefined') {
      window.removeEventListener('resize', setTop);
    }
  }

  render() {
    const { intl } = this.props;
    const isIncompatible = browserIncompatible() && !isNative();

    if (isIncompatible) {
      return (
        <div id="incompatibility-banner" style={styles.scary}>
          <table style={styles.table}>
            <tbody>
              <tr style={styles.tr}>
                <td style={styles.td}>
                  <FormattedMessage
                    id={messageId('.warning', __filenamespace)}
                  />
                </td>
                <td style={styles.td}>
                  <Button
                    href="http://help.upperhand.io/updates-and-new-features/browser-compatibility"
                    target="_blank"
                    style={styles.button}
                  >
                    {t('.help', intl, __filenamespace)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }
}

export default injectIntl(IncompatibilityBanner);
