import * as React from 'react';
import { Grid, Button, Typography } from '@upperhand/playmaker';
import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { ClientDataStore } from 'dataStores';
import useEditMode from '../useEditMode';

function Notes({ clientDataStore: { clients }, clientId, onClientSave, intl }) {
  const client = clients.get(clientId);
  const [edit, toggleEdit] = useEditMode(clientId);

  const [note, setNote] = React.useState('');
  React.useEffect(() => setNote(client?.getRichNote()), [client]);

  const saveClient = () => {
    toggleEdit();
    onClientSave(client, { note: note.toString('html') });
  };

  if (!client) return null;

  return (
    <Grid
      className="notes"
      direction="row"
      alignItems="center"
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h4" color="secondary">
          {t('.header', intl, __filenamespace)}
        </Typography>
      </Grid>
      <div className="contact-info__edit-button">
        <Button
          type="tertiary"
          rounded
          fullWidth
          size="1x"
          icon={edit ? 'close' : 'edit'}
          onClick={toggleEdit}
        />
      </div>
      {edit ? (
        <Grid item container spacing={1} direction="row">
          <Grid item>
            <WysiwygEditor value={note} onChange={setNote} />
          </Grid>
          <Grid item className="notes__form-actions">
            <Button onClick={saveClient}>
              {t('.save', intl, __filenamespace)}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: client.getHtmlNoteCleaned() || '-',
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(Notes);
