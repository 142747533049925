import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  RadioButton,
  Confirmation,
  Checkbox,
  Typography,
  Dropdown,
  TextField,
} from '@upperhand/playmaker';
import Box from '@mui/material/Box';

import CancellationDialogModal from 'memberships/components/CancellationDialogModal/index.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { l, messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';

import CancellationDialogModalActions from 'memberships/components/CancellationDialogModal/CancellationDialogModalActions.js';
import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions.js';

import MembershipCancellationStore, {
  CANCELATION_REASONS,
} from 'memberships/stores/MembershipCancellationStore.jsx';

const styles = {
  container: {
    marginBottom: '20px',
    lineHeight: '22px',
  },
  warningContainer: {
    paddingLeft: '5px',
  },
  warning: {
    color: uhColors.warning,
  },
  radioContainer: {
    paddingLeft: '5px',
  },
  cancellationReason: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
};

function CancellationDialog({ membershipCancellationStore, intl }) {
  const {
    client,
    membership,
    subscription,
    cancelMode,
    cancelInProgress,
    unscheduleRegistrations,
    cancellationReason,
    cancellationNotes,
  } = membershipCancellationStore;

  const clientName = client ? client.name() : '';
  const membershipName = membership ? membership.name : '';
  const membershipEndsAt = subscription?.commitment_ends_at;
  const paymentPeriodEnd = subscription
    ? l(subscription.expires_at, 'dates.shortNumeric', intl)
    : '';
  const confirmLabel = cancelInProgress
    ? t('.cancelling', intl, __filenamespace)
    : t('.confirm_cancellation', intl, __filenamespace);

  const radioButtons = [
    {
      label: t('.cancel_immediately', intl, __filenamespace),
      value: 'immediate',
    },
    {
      label: t('.cancel_future', intl, __filenamespace),
      value: 'future',
    },
  ];

  if (membership && !membership.isLifetime()) {
    radioButtons.push({
      label: (
        <FormattedMessage
          id={messageId('.cancel_at_end', __filenamespace)}
          values={{
            paymentPeriodEnd: (
              <span style={{ whiteSpace: 'nowrap' }}>({paymentPeriodEnd})</span>
            ),
          }}
        />
      ),
      value: 'end_of_period',
    });
  }

  return (
    <Confirmation
      title={
        <FormattedMessage
          id={messageId('.cancel_membership', __filenamespace)}
        />
      }
      open={!!client}
      level="warning"
      hasCheckbox={false}
      confirmButtonLabel={confirmLabel}
      confirmationDisabled={cancelInProgress || !cancellationReason}
      cancelButtonLabel={t('.discard_cancellation', intl, __filenamespace)}
      onCancel={MembershipCancellationActions.cancellationAborted}
      onConfirm={MembershipCancellationActions.cancellationConfirmed}
    >
      <Box style={styles.container}>
        <FormattedMessage
          id={messageId('.are_you_sure', __filenamespace)}
          values={{
            clientName: <span style={boldText}>{clientName}</span>,
            membershipName: <span style={boldText}>{membershipName}</span>,
          }}
        />
      </Box>
      <Typography variant="subtitle1">
        <FormattedMessage
          id={messageId('.cancellation_date', __filenamespace)}
        />
      </Typography>
      <Box style={styles.radioContainer}>
        <RadioButton
          orientation="vertical"
          selectedValue={cancelMode}
          options={radioButtons}
          onChange={(_, value) => {
            if (value === 'future') {
              CancellationDialogModalActions.openModal(membershipEndsAt);
            } else {
              MembershipCancellationActions.cancelModeChanged(value);
            }
          }}
        />
      </Box>
      <Box style={styles.cancellationReason}>
        <Dropdown
          fullWidth
          label={t('.cancellation_reason', intl, __filenamespace)}
          placeholder={t(
            '.cancellation_reason_placeholder',
            intl,
            __filenamespace
          )}
          items={CANCELATION_REASONS.map(r => ({
            value: r,
            label: r,
          })).toArray()}
          value={cancellationReason}
          onChange={e =>
            MembershipCancellationActions.cancellationReasonChanged(
              e.target.value
            )
          }
        />
        <TextField
          fullWidth
          multiline
          rows={3}
          label={t('.cancellation_notes', intl, __filenamespace)}
          value={cancellationNotes}
          onChange={(e, value) =>
            MembershipCancellationActions.cancellationNotesChanged(value)
          }
        />
      </Box>
      <Box style={styles.warningContainer}>
        <Checkbox
          label={
            <Typography>
              {t('.unschedule_label', intl, __filenamespace)}
            </Typography>
          }
          checked={unscheduleRegistrations}
          onChange={MembershipCancellationActions.toggleUnscheduleRegistrations}
        />
        {unscheduleRegistrations && (
          <Box style={styles.warning}>
            <Typography>
              {t('.unschedule_warning', intl, __filenamespace)}
            </Typography>
          </Box>
        )}
      </Box>
      <CancellationDialogModal />
    </Confirmation>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      membershipCancellationStore: MembershipCancellationStore,
    },
  })
)(CancellationDialog);
