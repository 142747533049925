import { Record } from 'immutable';

import Client from 'shared/records/Client.jsx';

class EmailTemplate extends Record({
  id: null,
  body: '',
  subject: '',
  created_by: new Client(),
}) {
  constructor(obj = {}, options = {}) {
    super({ ...obj, created_by: new Client(obj.created_by) }, options);
  }

  isValid() {
    return this.body.length > 0 && this.subject.length > 0;
  }
}

export default EmailTemplate;
