import * as React from 'react';

import AccountSetupIncompleteAlert from 'onboarding/components/AccountSetupIncompleteAlert.jsx';
import CustomerTermsDialog from 'customers/TermsDialog.jsx';
import ErrorDialogWithMessageWindow from 'shared/components/ErrorDialogWithMessageWindow.jsx';
import IncompatibilityBanner from 'shared/components/IncompatibilityBanner.jsx';
import InviteDialog from 'user_management/shared/components/InviteDialog.jsx';
import NewReleaseBanner from 'shared/components/NewReleaseBanner.jsx';
import AgreementFormDrawer from 'cart/index/components/_AgreementFormDrawer.jsx';
import NewMessageDrawer from 'containers/events/admin/clients/components/NewMessageDrawer/index.jsx';
import SiteHeader from 'shared/components/navigation/SiteHeader.jsx';
import POSDrawer from 'point_of_sale/components/POSDrawer.jsx';
import SignInModal from 'shared/components/signUp/SignInModal.jsx';
import SignUpModal from 'shared/components/signUp/SignUpModal.jsx';
import ClientProfileDrawer from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/ClientProfileDrawer.jsx';
import AccountCreditDrawer from 'containers/clientProfile/components/AccountCredits/AccountCreditDrawer.jsx';
import EditPaymentPlanDrawer from 'containers/reports/paymentPlanDetailsDrawer/components/EditPaymentPlanDrawer.jsx';
import AllPaymentsPlanCancellationDialog from 'containers/reports/paymentPlanDetailsDrawer/components/_CancellationDialog.jsx';
import MembershipEmbedEditModal from 'containers/clientProfile/components/MembershipEmbed/MembershipEmbedEditModal.jsx';
import EditMembershipCancellationModal from 'containers/clientProfile/components/EditMembershipCancellationModal/EditMembershipCancellationModal.jsx';
import RemoveMembershipCancellationModal from 'containers/clientProfile/components/EditMembershipCancellationModal/RemoveMembershipCancellationModal.jsx';
import MembershipOverviewDrawer from 'containers/clientProfile/components/MembershipInfo/OverviewDrawer/index.jsx';
import MachineSettingsDrawer from 'customers/settings/MachineSettingsDrawer/MachineSettingsDrawer.jsx';
import SwingCreditsDrawer from 'containers/clientProfile/components/Drawers/SwingCreditsDrawer/index.jsx';
import ManagementRequestModal from 'containers/clientProfile/components/MembershipInfo/ManagementRequestModal/index.jsx';

import {
  currentCustomer,
  enabledCustomerFeatures,
} from 'shared/utils/CustomerUtils';
import { isLoggedIn, currentUser } from 'shared/utils/UserUtils.jsx';
import { isDBatTheme } from 'shared/utils/ThemeUtils';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CustomerActions from 'shared/actions/CustomerActions.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions';
import WaiverAgreementDrawer from 'containers/waiver/WaiverAgreementDrawer.jsx';

class BaseLayout extends React.Component {
  async componentDidMount() {
    if (isLoggedIn() && currentUser()?.id) {
      CustomerActions.fetch({
        id: currentCustomer().id,
        fields: ['bank_accounts', 'legal_entity', 'logo'],
      });
      CartActions.fetch({});
      CreditPassListingActions.listRequested({});
    }
  }

  render() {
    return (
      <>
        <IncompatibilityBanner />
        <NewReleaseBanner />
        <AgreementFormDrawer />
        <AccountCreditDrawer />
        {/* this is a symptom of us having accounts located in the wrong place.  */}
        {!window.location.pathname.includes('/create_user') ? (
          <SiteHeader />
        ) : (
          <div />
        )}
        <POSDrawer />
        <NewMessageDrawer />
        <ClientProfileDrawer />
        <SwingCreditsDrawer />
        {!window.location.pathname.includes('users/settings') ? (
          <CustomerTermsDialog />
        ) : (
          <div />
        )}
        <ErrorDialogWithMessageWindow />
        <AccountSetupIncompleteAlert />
        <InviteDialog />
        <EditPaymentPlanDrawer />
        <AllPaymentsPlanCancellationDialog />

        <WaiverAgreementDrawer />
        <MembershipEmbedEditModal />
        <EditMembershipCancellationModal />
        <RemoveMembershipCancellationModal />
        <MembershipOverviewDrawer />
        {currentUser().isClient() && <ManagementRequestModal />}
        {isDBatTheme() && enabledCustomerFeatures(['ap_credits']) && (
          <MachineSettingsDrawer />
        )}
        {!isLoggedIn() && (
          <>
            <SignInModal />
            <SignUpModal />
          </>
        )}
      </>
    );
  }
}

export default BaseLayout;
