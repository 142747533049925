import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Map, List as ImmutableList } from 'immutable';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';
import FilterActions from 'team_mgmt/index/actions/FilterActions.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';

const styles = {
  header: {
    padding: '15px',
  },

  formGroup: {
    padding: '0 15px 15px',
  },

  formLabel: {
    marginLeft: '0px',
    justifyContent: 'space-between',
  },

  toggle: {
    top: '3px',
  },

  overlayStyle: {
    opacity: 0,
    backgroundColor: 'transparent',
  },
  teamTypeColorStyle: {
    borderRadius: '2px',
    border: '2px',
    width: 4,
    marginRight: '0.5em',
  },
};

function FilterDrawer(props) {
  const {
    filterActions,
    teamActions,
    showStatusesInFilter,
    teamStatusFilters,
    statusFilters,
    typeFilters,
    teamTypes,
    filterDrawerOpen,
  } = props;

  const children = [];
  const isAllStatusesChecked = statusFilters.every(filter => filter);
  const isAllTypesChecked = typeFilters.every(filter => filter);
  React.useEffect(() => {
    TeamTypeListingActions.list({});
  }, []);

  const teamTypeCallback = id => (e, checked) => {
    filterActions.updateTypeFilters(id, checked);
    teamActions.list({
      fields: ['team_detail', 'team_type', 'registered_client_ids'],
      page: 1,
    });
  };

  const buildTeamStateFilterCallback = filterKey => (e, checked) => {
    filterActions.updateStatusFilters(filterKey, checked);
    teamActions.list({
      fields: ['team_detail', 'team_type', 'registered_client_ids'],
      page: 1,
    });
  };

  if (showStatusesInFilter) {
    children.push(
      <Typography key="status-subheader" sx={styles.header}>
        <FormattedMessage id={messageId('.team_status', __filenamespace)} />
      </Typography>
    );
    children.push(
      <FormGroup key="status-filter-list" sx={styles.formGroup}>
        {teamStatusFilters.map(filter => (
          <FormControlLabel
            key={uuidv4()}
            label={filter.name}
            labelPlacement="start"
            onChange={buildTeamStateFilterCallback(filter.filterKey)}
            checked={
              isAllStatusesChecked || statusFilters.get(filter.filterKey)
            }
            control={<Switch />}
            sx={styles.formLabel}
          />
        ))}
      </FormGroup>
    );
    children.push(<Divider key="status-filter-divider" />);
  }

  children.push(
    <Typography key="team-type-subheader" style={{ padding: 15 }}>
      <FormattedMessage id={messageId('.team_type', __filenamespace)} />
    </Typography>
  );
  children.push(
    <FormGroup key="team-type-filter-list" sx={styles.formGroup}>
      <FormControlLabel
        key="eventTypeAll"
        label={
          <>
            <Box
              component="span"
              style={{
                ...styles.teamTypeColorStyle,
                color: 'var(--color-white)',
              }}
            >
              &nbsp;
            </Box>
            <Box component="span">All</Box>
          </>
        }
        labelPlacement="start"
        onChange={teamTypeCallback('all')}
        checked={isAllTypesChecked}
        control={<Switch />}
        sx={styles.formLabel}
      />
      {teamTypes.map(type => (
        <FormControlLabel
          key={type.id}
          label={
            <>
              <Box
                component="span"
                sx={{
                  ...styles.teamTypeColorStyle,
                  backgroundColor: type.color,
                }}
              >
                &nbsp;
              </Box>
              <Box component="span">{type.name}</Box>
            </>
          }
          labelPlacement="start"
          onChange={teamTypeCallback(type.id)}
          checked={!!typeFilters.get(type.id)}
          control={<Switch />}
          sx={styles.formLabel}
        />
      ))}
    </FormGroup>
  );

  return (
    <Drawer
      disableEnforceFocus
      ModalProps={{
        BackdropProps: {
          sx: styles.overlayStyle,
        },
      }}
      open={filterDrawerOpen}
      anchor="right"
      onClose={() => filterActions.updateFilterDrawerOpen(false)}
      PaperProps={{
        sx: { width: smallScreen() ? window.innerWidth * 0.8 : null },
      }}
    >
      <Box className="iphone-x-content">{children}</Box>
    </Drawer>
  );
}

FilterDrawer.propTypes = {
  filterActions: PropTypes.object,
  teamActions: PropTypes.object,
  showStatusesInFilter: PropTypes.bool,
  filterDrawerOpen: PropTypes.bool,
  teamStatusFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      filterKey: PropTypes.string,
    })
  ),
  statusFilters: PropTypes.instanceOf(Map),
  typeFilters: PropTypes.instanceOf(Map),
  teamTypes: PropTypes.instanceOf(ImmutableList),
};

FilterDrawer.defaultProps = {
  filterActions: FilterActions,
  teamActions: TeamActions,
  showStatusesInFilter: true,
  filterDrawerOpen: false,
  teamStatusFilters: [],
  statusFilters: Map(),
  typeFilters: Map(),
  teamTypes: ImmutableList(),
};

export default FilterDrawer;
