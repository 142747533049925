import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DiscountValue from 'shared/components/DiscountValue.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function MembershipDiscount({
  membership,
  customerUser,
  discount,
  discountAmount,
}) {
  return (
    <div
      style={{
        fontWeight: 'bold',
        paddingLeft: 25,
        position: 'relative',
        marginBottom: 15,
      }}
    >
      <MembershipIcon
        style={{ height: 16, width: 16, position: 'absolute', left: 0 }}
        color={membership?.color ?? 'black'}
      />

      <div>
        <FlexBoxJustify style={{ marginBottom: 6 }}>
          <FormattedMessage id={messageId('.membership', __filenamespace)} />
          <FormattedCurrency value={-discountAmount} fromCents />
        </FlexBoxJustify>

        <div style={{ color: '#807F7F' }}>
          <div style={{ marginBottom: 6 }}>
            <DiscountValue discount={discount} />
          </div>
          <div style={{ marginBottom: 6 }}>{customerUser.name()}</div>
        </div>
      </div>
    </div>
  );
}

export default MembershipDiscount;
