import React from 'react';
import { injectIntl } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const CreateTeamButton = injectIntl(({ intl }) => (
  <ReactRouterLink
    to={customerScopedRoute('/teams/new')}
    style={{ textDecoration: 'none' }}
  >
    <Button
      color="dark"
      variant="contained"
      startIcon={<AddIcon sx={{ color: uhColors.white }} />}
    >
      {t('.create_new_team', intl, __filenamespace)}
    </Button>
  </ReactRouterLink>
));

function Header({ intl }) {
  return (
    <PageHeader
      title={t('.manage_teams', intl, __filenamespace)}
      actions={
        !currentUser().isCoach() ? <CreateTeamButton intl={intl} /> : null
      }
    />
  );
}

export default injectIntl(Header);
