import * as React from 'react';
import PropTypes from 'prop-types';

import WaitlistActions from './actions';

function WaitList({ sessionId }) {
  React.useEffect(() => {
    WaitlistActions.mounted(sessionId);
  }, [sessionId]);

  return <div>WaitListTab content</div>;
}

WaitList.propTypes = {
  sessionId: PropTypes.string.isRequired,
};

export default WaitList;
