import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { DateTimeField, Dropdown } from '@upperhand/playmaker';

import { Box, IconButton, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Typography from '@mui/material/Typography';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

const styles = {
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  rowContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
  },

  tooltipStyles: {
    textAlign: 'justify',
    '& p': { marginBottom: '1em' },
    '& span:first-of-type': { fontWeight: 'bold' },
  },

  infoIconWrapper: {
    height: '16px',
    position: 'absolute',
    right: 20,
    top: 0,
    width: '16px',
    zIndex: 999,
  },

  infoIcon: {
    height: 20,
    width: 20,
    position: 'absolute',
    zIndex: 999,
    color: 'var(--color-primary)',
  },

  featureIconWrapper: {
    height: '16px',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '16px',
    zIndex: 999,
  },

  featureIcon: {
    height: 20,
    width: 20,
    position: 'absolute',
    zIndex: 999,
    color: 'var(--color-primary)',
  },

  dobHeader: {
    fontWeight: 'bold',
    padding: '15px 0px 10px 0px',
    lineHeight: '15px',
    marginBottom: '8px',
    paddingBottom: '',
  },

  clearSections: theme => ({
    fontFamily: 'inherit',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '13.13px',
    textAlign: 'left',
    color: theme.palette.primary1Color,
    width: '130px',
    cursor: 'pointer',
  }),
};

function getDropdownOptions(intl) {
  return [
    {
      label: t('.is_before', intl, __filenamespace),
      value: 'Is Before',
    },
    {
      label: t('.is_after', intl, __filenamespace),
      value: 'Is After',
    },
    {
      label: t('.is_between', intl, __filenamespace),
      value: 'Is Between',
    },
  ];
}

function Tooltip({
  id,
  content,
  position,
  backgroundColor,
  textColor,
  className,
}) {
  return (
    <ReactTooltip
      id={id}
      effect="solid"
      place={position}
      backgroundColor={backgroundColor}
      textColor={textColor}
      className={className}
    >
      <Box
        sx={styles.tooltipStyles}
        component="span"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </ReactTooltip>
  );
}

function LargeScreenDobFields({
  participants,
  isBetween,
  hasAgeRestriction,
  isEndDateDisabled,
  isDateDisabled,
  canClearSelections,
  handleDateChange,
  handleDobTypeChange,
  handleResetDobFields,
  intl,
}) {
  return (
    <Box sx={{ ...styles.rowContainer, width: isBetween ? '650px' : '460px' }}>
      <Box sx={{ width: 137 }}>
        <Dropdown
          id="type"
          fullWidth
          items={getDropdownOptions(intl)}
          placeholder={t('actions.select', intl)}
          name="type"
          classes={{ disabled: 'dob-dropdown-disabled' }}
          disabled={hasAgeRestriction}
          onChange={handleDobTypeChange}
          value={participants.getIn(['date_of_birth', 'operator'])}
        />
      </Box>
      <Box sx={{ width: 164 }}>
        <DateTimeField
          autoOk
          fullWidth
          disabled={isDateDisabled}
          name="start_date"
          placeholder="MM/DD/YYYY"
          onChange={date => handleDateChange('start_date', date)}
          type="date"
          value={participants.getIn(['date_of_birth', 'start_date'])}
        />
      </Box>
      {isBetween && (
        <>
          <Box sx={{ border: '1px solid #454952', width: '16px' }} />
          <Box sx={{ width: 164 }}>
            <DateTimeField
              autoOk
              fullWidth
              name="end_date"
              placeholder="MM/DD/YYYY"
              disabled={isEndDateDisabled}
              onChange={date => handleDateChange('end_date', date)}
              type="date"
              value={participants.getIn(['date_of_birth', 'end_date'])}
            />
          </Box>
        </>
      )}

      <Button
        sx={{
          ...styles.clearSections,
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            cursor: 'not-allowed',
          },
        }}
        disabled={!canClearSelections}
        onClick={handleResetDobFields}
      >
        <FormattedMessage id={messageId('.clear_selection', __filenamespace)} />
      </Button>
    </Box>
  );
}
function SmallScreenDobFields({
  participants,
  isBetween,
  hasAgeRestriction,
  isEndDateDisabled,
  isDateDisabled,
  canClearSelections,
  handleDateChange,
  handleDobTypeChange,
  handleResetDobFields,
  intl,
}) {
  return (
    <Box sx={styles.rowContainerMobile}>
      <Box sx={{ width: '250px' }}>
        <Dropdown
          id="type"
          fullWidth
          items={getDropdownOptions(intl)}
          placeholder={t('actions.select', intl)}
          name="type"
          classes={{ disabled: 'dob-dropdown-disabled' }}
          disabled={hasAgeRestriction}
          onChange={handleDobTypeChange}
          value={participants.getIn(['date_of_birth', 'operator'])}
        />
      </Box>
      <Box sx={{ width: '250px', marginTop: '1rem' }}>
        <DateTimeField
          autoOk
          fullWidth
          disabled={isDateDisabled}
          name="start_date"
          placeholder="MM/DD/YYYY"
          onChange={date => handleDateChange('start_date', date)}
          type="date"
          value={participants.getIn(['date_of_birth', 'start_date'])}
        />
      </Box>
      {isBetween && (
        <Box sx={{ width: '250px', marginTop: '1rem' }}>
          <DateTimeField
            autoOk
            fullWidth
            name="end_date"
            placeholder="MM/DD/YYYY"
            disabled={isEndDateDisabled}
            onChange={date => handleDateChange('end_date', date)}
            type="date"
            value={participants.getIn(['date_of_birth', 'end_date'])}
          />
        </Box>
      )}

      <Button
        sx={{
          ...styles.clearSections,
          marginTop: '1rem',
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            cursor: 'not-allowed !important',
          },
        }}
        disabled={!canClearSelections}
        onClick={handleResetDobFields}
      >
        <FormattedMessage id={messageId('.clear_selection', __filenamespace)} />
      </Button>
    </Box>
  );
}

function RestrictionsSectionDobRows({
  participants,
  handleParticipantChange,
  actions,
  intl,
}) {
  const hasAgeRestriction = participants.hasAgeRestriction();
  const dateOfBirth = participants.get('date_of_birth');
  const isBetween = dateOfBirth.isDobTypeBetween();
  const canClearSelections = dateOfBirth.canClearSelections();
  const hasDobTypeSelected = dateOfBirth.hasDobTypeSelected();
  const isDateDisabled = !hasDobTypeSelected || hasAgeRestriction;
  const isEndDateDisabled =
    !hasDobTypeSelected ||
    hasAgeRestriction ||
    (isBetween && !dateOfBirth.get('start_date'));
  const handleDateChange = (name, value) => {
    handleParticipantChange(
      ['date_of_birth', name],
      value?.tz(customerTZ(), true)
    );
  };

  const handleDobTypeChange = e => {
    handleParticipantChange(['date_of_birth', 'operator'], e.target.value);
    actions.resetAgeFields();
  };
  const handleResetDobFields = () => {
    actions.resetDobFields();
  };
  return (
    <Box component="section">
      <Typography sx={styles.dobHeader}>
        <FormattedMessage id={messageId('.dob', __filenamespace)} />
        <Box
          component="span"
          style={{ position: 'relative', marginLeft: '3rem' }}
        >
          <Box
            component="span"
            data-tip
            data-for="type"
            sx={styles.infoIconWrapper}
          >
            <IconButton sx={styles.infoIcon}>
              <InfoIcon />
            </IconButton>
          </Box>
          <Box
            component="span"
            data-tip
            data-for="feature-info"
            sx={styles.featureIconWrapper}
          >
            <IconButton sx={styles.featureIcon}>
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Typography>

      <Tooltip
        id="type"
        content={t('.dob_info', intl, __filenamespace)}
        position="bottom"
        className="dob-info-tooltip uh-tooltip"
      />

      <Tooltip
        id="feature-info"
        content={t('.dob_feature_info', intl, __filenamespace)}
        position="right"
        className="dob-feature-tooltip"
      />

      <ResponsiveElement
        largeScreen={
          <LargeScreenDobFields
            intl={intl}
            participants={participants}
            isBetween={isBetween}
            isDateDisabled={isDateDisabled}
            hasAgeRestriction={hasAgeRestriction}
            isEndDateDisabled={isEndDateDisabled}
            canClearSelections={canClearSelections}
            handleDateChange={handleDateChange}
            handleParticipantChange={handleParticipantChange}
            handleDobTypeChange={handleDobTypeChange}
            handleResetDobFields={handleResetDobFields}
          />
        }
        smallScreen={
          <SmallScreenDobFields
            intl={intl}
            participants={participants}
            isBetween={isBetween}
            isDateDisabled={isDateDisabled}
            hasAgeRestriction={hasAgeRestriction}
            isEndDateDisabled={isEndDateDisabled}
            canClearSelections={canClearSelections}
            handleDateChange={handleDateChange}
            handleParticipantChange={handleParticipantChange}
            handleDobTypeChange={handleDobTypeChange}
            handleResetDobFields={handleResetDobFields}
          />
        }
      />
    </Box>
  );
}

export default RestrictionsSectionDobRows;
