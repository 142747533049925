import AvailableTimesStore from 'event_mgmt/shared/stores/AvailableTimesStore.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSEventStore from 'point_of_sale/stores/POSEventStore.jsx';
import POSCartStore from 'point_of_sale/stores/POSCartStore.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSPurchasedRegistrationActions from 'point_of_sale/actions/POSPurchasedRegistrationActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import POSSchedulingStore from 'point_of_sale/stores/POSSchedulingStore.jsx';

export default alt.createStore(
  AvailableTimesStore,
  'POSSchedulingAvailableTimesStore',
  {
    setEvent: [
      POSProductListActions.EVENT_SELECTED,
      POSEventActions.FETCH_EVENT_SUCCESS,
    ],

    updateExistingScheduledTimes: [
      POSActions.CLIENT_BACK_CLICKED,
      POSCartActions.ADD_SUCCESS,
      POSCartActions.FETCH_SUCCESS,
      POSCartActions.UPDATE_SUCCESS,
      POSCheckoutActions.CLOSE_BUTTON_CLICKED,
      POSEventActions.EVENT_SCHEDULING_CANCEL_CLICKED,
      POSPurchasedRegistrationActions.COMPLETE_BUTTON_CLICKED,
      POSSchedulingActions.ADD_ATTENDEE_CLICKED,
      POSSchedulingActions.REGISTRATION_BOOKED,
      POSSchedulingActions.REGISTRATION_CANCELLED,
      POSSchedulingActions.SCHEDULE_NOW_CLICKED,
      POSSchedulingActions.VIEW_IN_CART_CLICKED,
    ],

    clearSelections: POSSchedulingActions.SCHEDULE_NOW_CLICKED,

    handleStaffChange: POSSchedulingActions.REGISTRATION_STAFF_CHANGED,
    handleDateChange: POSSchedulingActions.REGISTRATION_DATE_CHANGED,
    handleTimeChange: POSSchedulingActions.REGISTRATION_TIME_CHANGED,
    handleFetchAvailabilities: POSSchedulingActions.FETCH_AVAILABILITIES,
  },
  POSEventStore.displayName,
  POSSchedulingStore.displayName,
  POSCartStore.displayName
);
