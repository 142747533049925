import AutomationTemplateDescription from 'shared/records/AutomationTemplateDescription.jsx';
import PerSessionPricingOption from 'shared/records/automations/PerSessionPricingOption.jsx';

class PerSessionPricingDescription extends AutomationTemplateDescription {
  constructor(obj = {}) {
    super(obj);
  }

  packages() {
    return this.template_parameters.map(
      pkg => new PerSessionPricingOption(pkg)
    );
  }

  getOption(uuid) {
    return new PerSessionPricingOption(super.getOption(uuid));
  }

  getMinimumPrice() {
    const prices = this.template_parameters.map(option => option.get('price'));

    return prices.size === 0 ? -1 : prices.min();
  }
}

PerSessionPricingDescription.fromServer = obj =>
  new PerSessionPricingDescription(obj, { fromServer: true });

export default PerSessionPricingDescription;
