export default alt.generateActions(
  'list',
  'listSuccess',
  'listError',
  'fetch',
  'fetchSuccess',
  'fetchError',
  'create',
  'createSuccess',
  'createError',
  'update',
  'updateSuccess',
  'updateError',
  'delete',
  'deleteSuccess',
  'deleteError',
  'deleteParameter',
  'updateStore',
  'updateStoreKeypath',
  'deselectCurrent'
);
