import moment from 'moment-timezone';
import { List, Record, Iterable, fromJS } from 'immutable';
import PricingUtils from 'event_mgmt/shared/utils/PricingUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { sumInt } from 'shared/utils/ImmutableUtils.js';

class RecurringPaymentPlanOption extends Record({
  uuid: null,
  name: '',
  description: '',
  payments: List(),
  fee: null,
  amountLessFees: null,
}) {
  constructor(obj = {}) {
    let newObj = obj;

    if (Iterable.isIterable(newObj)) {
      newObj = newObj.toJS();
    }

    const payments = fromJS(newObj.payments || []).map(payment =>
      payment.set('date', moment(payment.get('date')))
    );

    super(merge(newObj, { payments }));
  }

  totalPrice() {
    return this.payments.reduce(sumInt('amount'), 0);
  }

  totalDueImmediately() {
    return this.paymentsDueImmediately().reduce(sumInt('amount'), 0);
  }

  paymentsDueImmediately() {
    const now = moment();
    return this.payments.filter(p => p.get('date').isSameOrBefore(now));
  }

  couponDiscount(coupon) {
    if (!coupon || !coupon.isRecurring()) {
      return 0;
    }

    return this.payments.reduce(
      (sum, payment) =>
        sum + coupon.discount.discountFor(payment.get('amount')),
      0
    );
  }

  calculateFees(feePlan) {
    const paymentsWithFees = this.get('payments').map(payment => {
      const amount = parseInt(payment.get('amount'), 10);
      const fee = PricingUtils.processingFee(amount / 100, feePlan) * 100;
      const amountLessFees = amount - fee;

      return payment.set('fee', fee).set('amountLessFees', amountLessFees);
    });

    const fee = paymentsWithFees.reduce(sumInt('fee'), 0);
    const amountLessFees = paymentsWithFees.reduce(sumInt('amountLessFees'), 0);

    return this.withMutations(p => {
      p.set('fee', fee);
      p.set('amountLessFees', amountLessFees);
      p.set('payments', paymentsWithFees);
    });
  }
}

export default RecurringPaymentPlanOption;
