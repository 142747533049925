const DAYS_IN_WEEK = 7;
const DAYS_IN_MONTH = 30;

/**
 * Converts given amount with units into amount of days
 * If unknown unit was passed return amount without changes
 *
 * @param {number} amount
 * @param {string} type Should be in ['months', 'weeks', 'days']
 * @return {number} Amount of days
 */
const convertToDays = (amount, type) => {
  if (amount === '') return '';
  switch (type) {
    case 'weeks':
      return DAYS_IN_WEEK * amount;
    case 'months':
      return DAYS_IN_MONTH * amount;
    default:
      return amount;
  }
};

/**
 * Converts given amount of days into amount of given units
 * If unknown unit was passed return amount without changes
 *
 * @param {number} amount
 * @param {string} type Should be in ['months', 'weeks', 'days']
 * @return {number} Amount of units to contain given amount of days
 */
const convertFromDays = (amount, type) => {
  if (amount === '') return '';
  switch (type) {
    case 'weeks':
      return Math.floor(amount / DAYS_IN_WEEK);
    case 'months':
      return Math.floor(amount / DAYS_IN_MONTH);
    default:
      return amount;
  }
};

export { convertToDays, convertFromDays };
