import MembershipActions from 'shared/actions/MembershipActions.jsx';
import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';
import UIStore from 'shared/stores/UIStore.jsx';

class MembershipViewingUIStore extends UIStore {
  constructor() {
    super();

    this.detailsExpanded = false;
    this.awaitingRemoveConfirmation = false;

    this.bindListeners({
      handleUpdateStore: MembershipViewingActions.UPDATE_STORE,
      handleLoading: [
        MembershipActions.FETCH,
        MembershipViewingActions.LIST_CLIENTS,
      ],
      handleDoneWaiting: [
        MembershipActions.ERROR,
        MembershipActions.MEMBERSHIP_LOADED,
        MembershipViewingActions.CLIENTS_LOADED,
      ],
      toggleExpanded: MembershipViewingActions.TOGGLE_EXPANDED,

      showRemoveConfirmation: MembershipViewingActions.REMOVE_REQUESTED,
      hideRemoveConfirmation: [
        MembershipViewingActions.REMOVE_ABORTED,
        MembershipViewingActions.REMOVE_CONFIRMED,
      ],

      showLoading: MembershipViewingActions.REMOVE_SUCCESS,
    });
  }

  showLoading() {
    this.handleLoading({});
  }

  toggleExpanded() {
    this.detailsExpanded = !this.detailsExpanded;
  }

  showRemoveConfirmation() {
    this.awaitingRemoveConfirmation = true;
  }

  hideRemoveConfirmation() {
    this.awaitingRemoveConfirmation = false;
  }
}

export default alt.createStore(
  MembershipViewingUIStore,
  'MembershipViewingUIStore'
);
