class CreditPassViewingActions {
  constructor() {
    this.generateActions(
      'clientsLoaded',
      'invite',
      'inviteSearchStringUpdated',
      'inviteSuccess',
      'listClients',
      'listCreditGrantError',
      'listCreditGrantSuccess',
      'queriedClientsLoaded',
      'remindClicked',
      'remindSuccess',
      'removeAborted',
      'removeConfirmed',
      'removeError',
      'removeRequested',
      'removeSuccess',
      'requestClientsPage',
      'searchStringUpdated',
      'toggleExpanded',
      'transactionsClicked',
      'updateStore'
    );
  }
}

export default alt.createActions(CreditPassViewingActions);
