import * as React from 'react';

import { formattedHourString } from 'calendar/utils/CalendarUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  AvailabilityOverlay: {
    position: 'absolute',
    zIndex: '0',
    left: '0',
    width: '100%',
    padding: '8px',
    backgroundColor: 'rgba(190, 243, 255, 0.5)',
    border: '1px solid rgba(0, 159, 221, 0.1)',
    color: 'rgba(0, 159, 221, 1)',
    pointerEvents: 'none',
  },
};

function AvailabilityOverlay({ timeRange }) {
  const durationInHours = Math.round(timeRange.diff('hours', true) * 10) / 10;
  const hoursFromMidnight =
    timeRange.start.hours() + timeRange.start.minutes() / 60;
  const cellHeight = 192;

  return (
    <div
      style={merge(styles.AvailabilityOverlay, {
        top: `${hoursFromMidnight * cellHeight + 42}px`,
        height: `${durationInHours * cellHeight}px`,
      })}
    >
      {`
        ${formattedHourString(timeRange.start)} -
        ${formattedHourString(timeRange.end)}
      `}
    </div>
  );
}

export default AvailabilityOverlay;
