class MembershipSubscriptionActions {
  constructor() {
    this.generateActions(
      'listSuccess',
      'listError',
      'sendManagementRequestSuccess',
      'sendManagementRequestError'
    );
  }
}

export default alt.createActions(MembershipSubscriptionActions);
