import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import ReactTooltip from 'react-tooltip';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import DecoratedTextField from 'shared/components/DecoratedTextField.jsx';

const styles = {
  root: {
    backgroundColor: 'var(--color-highlight-yellow)',
    padding: '0 20px',
  },
  buttonLabel: {
    fontSize: 15,
  },
  checkoutButton: {
    height: 36,
  },
  tooltipContainer: {
    marginBottom: 15,
    marginTop: 15,
    position: 'relative',
  },
  tooltipIcon: {
    height: 20,
    width: 20,
    position: 'absolute',
    right: 4,
    top: 4,
    zIndex: 999,
    color: uhColors.activeBlue,
  },
  tooltipIconActive: {
    color: uhColors.white,
  },
  tooltip: {
    textAlign: 'center',
  },
};

function OrderItemDiscount({ item }) {
  const qdAdjustment = item.get('applied_adjustments').find(a => a.isManual());
  const discountValue = qdAdjustment.get('amount') * -1;

  return (
    <FlexBoxJustify style={{ padding: '10px 0' }}>
      <span style={{ fontSize: 15 }}>
        {`$${parseFloat(discountValue / 100).toFixed(2)} discount`}
      </span>
      <span style={{ fontWeight: 'bold', fontSize: 15 }}>
        {`$${parseFloat(item.total / 100).toFixed(2)}`}
      </span>
    </FlexBoxJustify>
  );
}

function QuickDiscountForm({
  isQuickDiscountApplied,
  onApplyDiscount,
  onDiscountTypeChange,
  onDiscountValueChange,
  item,
  intl,
}) {
  const hasMembershipJoinFee =
    item.isMembershipItem() &&
    item.getIn(['orderable', 'subscribable_join_fee']) > 0;
  const isDisabled =
    !item.get('manual_discount')?.value || item.errors.hasErrors();
  const tooltipKey = 'quick-discount-tooltip';

  return (
    <div style={styles.root}>
      {isQuickDiscountApplied ? (
        <OrderItemDiscount item={item} intl={intl} />
      ) : (
        <div>
          <FlexBoxJustify style={{ alignItems: 'center' }}>
            <div style={{ flex: '1 1 50%', fontSize: 15 }}>
              <FormattedMessage id={messageId('.discount', __filenamespace)} />
            </div>
            <FlexBox style={{ flex: '1 1 50%' }}>
              <div style={{ display: 'inline-block' }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>{t('.type', intl, __filenamespace)}</InputLabel>
                  <Select
                    label={t('.type', intl, __filenamespace)}
                    value={item.get('manual_discount').type}
                    onChange={e => onDiscountTypeChange(e.target.value)}
                  >
                    <MenuItem key="amount-discount" value="amount">
                      {t('.amount', intl, __filenamespace)}
                    </MenuItem>
                    <MenuItem key="percent-discount" value="percent">
                      {t('.percentage', intl, __filenamespace)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ width: 20, display: 'inline-block' }} />
              <div
                style={{ width: 102, display: 'flex', alignItems: 'flex-end' }}
              >
                {item.get('manual_discount').type === 'amount' ? (
                  <CurrencyTextField
                    variant="standard"
                    name="value"
                    textFieldStyle={{ width: 'inherit' }}
                    onChange={onDiscountValueChange}
                    value={item.get('manual_discount').value}
                    errorText={item.errors.getErrors('discount_value', intl)}
                    symbolStyle={{ top: '52%' }}
                    converter={e => parseInt(e, 10)}
                  />
                ) : (
                  <DecoratedTextField
                    variant="standard"
                    name="value"
                    textFieldStyle={{ width: 'inherit' }}
                    fullWidth
                    onChange={onDiscountValueChange}
                    value={item.get('manual_discount').value}
                    decoration="%"
                    errorText={item.errors.getErrors('discount_value', intl)}
                    decorationStyle={{ top: '52%' }}
                    position="right"
                  />
                )}
              </div>
            </FlexBox>
          </FlexBoxJustify>
          <div style={styles.tooltipContainer}>
            <Button
              fullWidth
              variant="contained"
              disabled={isDisabled}
              style={{ ...styles.checkoutButton, ...styles.buttonLabel }}
              onClick={onApplyDiscount}
            >
              <span>{t('.apply_discount', intl, __filenamespace)}</span>
            </Button>
            {hasMembershipJoinFee && (
              <>
                <InfoIcon
                  data-tip
                  data-for={tooltipKey}
                  style={{
                    ...styles.tooltipIcon,
                    ...(!isDisabled ? styles.tooltipIconActive : {}),
                  }}
                />
                <ReactTooltip
                  id={tooltipKey}
                  effect="solid"
                  className="uh-tooltip"
                >
                  <div style={styles.tooltip}>
                    {t('.tooltip', intl, __filenamespace)}
                  </div>
                </ReactTooltip>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default injectIntl(QuickDiscountForm);
