import * as React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { injectIntl } from 'react-intl';
import { FormModal, Grid, TextField, Spinner } from '@upperhand/playmaker';

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { uhFlexbox } from 'shared/styles/uhStyles.jsx';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { toSnakeCase } from 'shared/utils/ObjectUtils.jsx';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

import UpdateContactActions from './Actions';
import UpdateContactStore from './Store';

import './styles.scss';

const ROLES = ['staff_member', 'instructor', 'manager', 'staff_admin', 'coach'];

function LoadingSpinner() {
  return (
    <Box sx={{ textAlign: 'center', height: '50px' }}>
      <Spinner />
    </Box>
  );
}

function StaffRoleChangeButtons({ role, isStaffContact, intl }) {
  const hasRoleChangeAccess = hasAdminPermission() && isStaffContact;

  if (!hasRoleChangeAccess) {
    return null;
  }

  const isCoachEnabled = enabledCustomerFeatures(['coach']);

  if (!isCoachEnabled && ROLES.includes('coach')) {
    ROLES.splice(ROLES.indexOf('coach'), 1);
  }

  const handleRoleChange = e => {
    UpdateContactActions.updateContactFields({
      target: {
        name: 'role',
        value: e.target.value,
      },
    });
  };

  return (
    <Box sx={{ marginBottom: '1em', display: 'inline-block' }}>
      <FormControl>
        <RadioGroup
          row
          name="role"
          sx={{ ...uhFlexbox.row, ...{ justifyContent: 'flex-start' } }}
          value={role}
          onChange={handleRoleChange}
        >
          {ROLES.map(eachRole => (
            <FormControlLabel
              key={eachRole}
              value={eachRole}
              control={<Radio />}
              label={t(`.${eachRole}`, intl, __filenamespace)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

function TextFieldComponent({ name, value, errors, intl }) {
  return (
    <Grid item>
      <TextField
        errorText={errors.getErrors(name, intl)}
        fullWidth
        label={t(`.${toSnakeCase(name)}`, intl, __filenamespace)}
        name={name}
        onChange={e => {
          UpdateContactActions.updateContactFields(e);
        }}
        outline
        value={value}
      />
    </Grid>
  );
}

function Content({
  firstName,
  lastName,
  email,
  errors,
  role,
  isStaffContact,
  intl,
}) {
  return (
    <>
      <StaffRoleChangeButtons
        role={role}
        intl={intl}
        isStaffContact={isStaffContact}
      />
      <Grid container direction="column" spacing={2}>
        <TextFieldComponent
          name="firstName"
          value={firstName}
          errors={errors}
          intl={intl}
        />
        <TextFieldComponent
          name="lastName"
          value={lastName}
          errors={errors}
          intl={intl}
        />
        <TextFieldComponent
          name="email"
          value={email}
          errors={errors}
          intl={intl}
        />
      </Grid>
    </>
  );
}

function UpdateContact({
  contactId,
  contactType,
  isStaffContact = false,
  intl,
  updateContactStore: { loading, firstName, lastName, email, id, role, errors },
}) {
  React.useEffect(() => {
    UpdateContactActions.mounted({ contactId, contactType });
  }, [contactId, contactType]);

  if (!id) {
    return null;
  }

  return (
    <FormModal
      open
      classes={{
        root: 'update-contacts',
        content: 'update-contacts__form-modal-content',
      }}
      title={t('.title', intl, __filenamespace)}
      actions={[
        {
          label: 'Save',
          type: 'primary',
          action: UpdateContactActions.saveContact,
        },
        {
          label: 'Cancel',
          type: 'tertiary',
          action: UpdateContactActions.reset,
        },
      ]}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Content
          firstName={firstName}
          lastName={lastName}
          email={email}
          errors={errors}
          intl={intl}
          role={role}
          isStaffContact={isStaffContact}
        />
      )}
    </FormModal>
  );
}

export default altContainer({
  stores: {
    updateContactStore: UpdateContactStore,
  },
})(injectIntl(UpdateContact));
