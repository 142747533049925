/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import * as React from 'react';
import { injectIntl } from 'react-intl';
import ImageUpload, { AvatarPreview } from 'components/ImageUpload';
import { TextField } from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';
import { compose } from 'shared/utils/SharedUtils';
import {
  FirstName,
  LastName,
  Email,
  DateOfBirth,
  Gender,
  Password,
  PasswordConfirmation,
  LastFourOfSSN,
} from 'user_management/shared/components/UserProfileFields.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    '& > div': {
      marginBottom: '0.5rem',
    },
  },
};

const shouldShowSSN = () => currentUser().role === 'StaffAdmin';

const passwordText = (hideFields, intl) =>
  t(
    hideFields.includes('CurrentPassword') ? '.password' : '.new_password',
    intl,
    __filenamespace
  );

const passwordConfirmationText = (hideFields, intl) => {
  if (hideFields.includes('CurrentPassword')) {
    return t('.password_confirmation', intl, __filenamespace);
  }
  return t('.new_password_confirmation', intl, __filenamespace);
};

function AccountInformationEditor({
  classes,
  errors,
  hideFields,
  intl,
  ...props
}) {
  return (
    <div className={classes.root}>
      {!hideFields.includes('ImageUpload') && (
        <ImageUpload
          circularCrop
          cropAspect={1}
          uploadContainerWidth="100%"
          onChange={value => {
            props.onChange(null, value, 'profile_image');
          }}
          image={props.userProfile.profile_image}
          preview={<AvatarPreview user={props.userProfile} />}
        />
      )}
      {!hideFields.includes('FirstName') && (
        <FirstName
          {...props}
          label={t('.your_first_name', intl, __filenamespace)}
          errorText={errors.getErrors('first_name', intl)}
        />
      )}
      {!hideFields.includes('LastName') && (
        <LastName
          {...props}
          label={t('.your_last_name', intl, __filenamespace)}
          errorText={errors.getErrors('last_name', intl)}
        />
      )}
      <Email
        {...props}
        label={t('.your_email', intl, __filenamespace)}
        errorText={errors.getErrors('email', intl)}
      />
      {!hideFields.includes('DateOfBirth') && (
        <DateOfBirth
          {...props}
          errorText={errors.getErrors('date_of_birth', intl)}
        />
      )}
      <Gender {...props} errorText={errors.getErrors('gender', intl)} />
      {!hideFields.includes('LastFourOfSSN') && shouldShowSSN() && (
        <LastFourOfSSN {...props} maxLength={4} />
      )}
      {!hideFields.includes('CurrentPassword') && (
        <TextField
          errorText={errors.getErrors('current_password', intl)}
          label={t('.current_password', intl, __filenamespace)}
          fullWidth
          placeholder={t('.current_password', intl, __filenamespace)}
          name="current_password"
          onChange={props.onChange}
          type="password"
          value={props.userProfile.current_password || ''}
        />
      )}
      <Password
        {...props}
        errorText={errors.getErrors('password', intl)}
        label={passwordText(hideFields, intl)}
        placeholder={passwordText(hideFields, intl)}
      />
      <PasswordConfirmation
        {...props}
        errorText={errors.getErrors('password_confirmation', intl)}
        label={passwordConfirmationText(hideFields, intl)}
        placeholder={passwordConfirmationText(hideFields, intl)}
      />
    </div>
  );
}

export default compose(
  withStyles(styles),
  injectIntl
)(AccountInformationEditor);
