import * as React from 'react';
import { injectIntl } from 'react-intl';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import CartBar from 'point_of_sale/components/CartBar.jsx';
import ItemCard from 'shared/components/checkout/ItemCard.jsx';
import Header from 'point_of_sale/components/Header.jsx';

import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import uhTheme from '_uh_theme.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  button: {
    height: 50,
  },

  buttonLabel: {
    fontSize: 16,
  },

  cartContainer: {
    padding: 20,
  },

  orderItemCard: {
    margin: '0 0 20px 0',
    width: '100%',
  },
};

const addEventClicked = () => POSCartActions.addEventClicked();
const cancelCartClicked = () => POSCartActions.cancelCartClicked();
const continueToPaymentClicked = e => {
  e.preventDefault();
  POSCartActions.continueToPaymentClicked();
};

function CartSummary({
  posStore,
  posAutomationsStore,
  posCartStore,
  sessionDataStore: { sessions },
  staffDataStore: { staff },
  intl,
}) {
  return (
    <div>
      <Header selectedClient={posStore.selectedClient} />
      <CartBar cart={posCartStore.cart} active />
      <Divider style={{ height: 2 }} />

      {posCartStore.cart && (
        <div>
          <div style={styles.cartContainer}>
            <h2 style={{ fontSize: 20, marginBottom: 20 }}>Cart</h2>
            {posCartStore.cart.order_items.map(orderItem => (
              <ItemCard
                key={orderItem.id}
                allProfiles={posStore.allProfiles}
                automations={posAutomationsStore.descriptions}
                sessions={sessions}
                staff={staff}
                onRemove={() => POSCartActions.itemRemoved(orderItem.id)}
                onEdit={() => POSCartActions.itemEditSelected(orderItem)}
                onView={() => POSCartActions.itemViewSelected(orderItem)}
                orderItem={orderItem}
                style={styles.orderItemCard}
              />
            ))}

            <Button
              fullWidth
              variant="contained"
              color="default"
              style={{
                ...merge(styles.button, { marginBottom: 20 }),
                ...merge(styles.buttonLabel, {
                  color: uhTheme.palette.primary1Color,
                }),
              }}
              onClick={addEventClicked}
            >
              {t('.add_product', intl, __filenamespace)}
            </Button>
            <Divider style={{ marginBottom: 20, height: 2 }} />
            <Button
              fullWidth
              variant="contained"
              style={merge(styles.button, styles.buttonLabel, {
                marginBottom: 12,
              })}
              onClick={continueToPaymentClicked}
            >
              {t('actions.continue', intl, __filenamespace)}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="default"
              style={merge(styles.button, styles.buttonLabel, {
                color: uhTheme.palette.primary1Color,
              })}
              onClick={cancelCartClicked}
            >
              {t('actions.cancel', intl, __filenamespace)}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default injectIntl(CartSummary);
