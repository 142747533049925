import * as React from 'react';
import isEqual from 'lodash.isequal';

import Day from 'calendar/components/views/day/Day.jsx';
import Week from 'calendar/components/views/week/Week.jsx';

import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import ResourceListingActions from 'resources/actions/ResourceListingActions.js';

const styles = {
  UHCalendar: {
    width: '100%',
    height: 'calc(100% - 42px)',
  },
  Literals: `
    .ContextMenuLink,
    .ContextMenuLink:hover {
      text-decoration: none;
    }
  `,
};

function View({
  calendarStore,
  calendarViewStore,
  resourceStore,
  staffStore,
  sessionStore,
}) {
  const staffIds = calendarStore.staffIds.map(item => item.id);
  const showPaid = calendarStore.calendarFilters.get('showPaid');
  const showUnpaid = calendarStore.calendarFilters.get('showUnpaid');
  const showAllStaff = calendarStore.calendarFilters.get('showAllStaff');
  React.useEffect(() => {
    if (currentCustomer().resources_enabled) {
      ResourceListingActions.paginationOptionsUpdated(1, 100);
    }
  }, []);

  switch (calendarStore.view) {
    case CALENDAR_TYPE.DAY:
      return (
        <Day
          allStaffMap={staffStore.allStaffMap}
          availableStaffOnly={calendarStore.availableStaffOnly}
          clientsMap={calendarStore.clients}
          currentCalendarView={calendarViewStore.currentCalendarView}
          date={calendarStore.date}
          eventsMap={calendarStore.events}
          eventTimes={calendarStore.eventTimes}
          fuzzySearch={calendarStore.fuzzySearch}
          loading={calendarStore.loading}
          membershipsMap={calendarStore.memberships}
          resourcesMap={resourceStore.resourcesMap}
          showAvailability={calendarStore.showAvailability}
          showPaid={showPaid}
          showUnpaid={showUnpaid}
          showAllStaff={showAllStaff}
          staffIds={staffIds}
          sessions={sessionStore.sessions}
        />
      );
    case CALENDAR_TYPE.WEEK:
      return (
        <Week
          allStaffMap={staffStore.allStaffMap}
          date={calendarStore.date}
          clientsMap={calendarStore.clients}
          eventsMap={calendarStore.events}
          filteredEventTimesByWeekday={
            calendarStore.filteredEventTimesByWeekday
          }
          loading={calendarStore.loading}
          membershipsMap={calendarStore.memberships}
          resourcesMap={resourceStore.resourcesMap}
          showPaid={showPaid}
          showUnpaid={showUnpaid}
          showAllStaff={showAllStaff}
          staffIds={staffIds}
          sessions={sessionStore.sessions}
        />
      );
    default:
      return false;
  }
}

class CalendarView extends React.Component {
  shouldComponentUpdate(nextProps) {
    // HACK: use deep compare to prevent re-render
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      calendarStore,
      calendarViewStore,
      resourceStore,
      staffStore,
      sessionStore,
    } = this.props;
    return (
      <div className="UHCalendar" style={styles.UHCalendar}>
        <style
          type="text/css"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: styles.Literals }}
        />
        <View
          calendarStore={calendarStore}
          calendarViewStore={calendarViewStore}
          resourceStore={resourceStore}
          staffStore={staffStore}
          sessionStore={sessionStore}
        />
      </div>
    );
  }
}

export default CalendarView;
