import EventRateDeletionActions from 'contacts/shared/actions/event_rates/EventRateDeletionActions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class EventRateDeletionStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      handleRemoveEventRateRequest: EventRateDeletionActions.REMOVE_EVENT_RATE,
      handleRemoveEventRate:
        EventRateDeletionActions.REMOVE_EVENT_RATE_CONFIRMED,
      handleAbortRemoving: EventRateDeletionActions.REMOVE_EVENT_RATE_ABORTED,
      handleRemoveEventRateSuccess:
        EventRateDeletionActions.REMOVE_EVENT_RATE_SUCCESS,
      handleRemoveEventRateError:
        EventRateDeletionActions.REMOVE_EVENT_RATE_ERROR,
    });
  }

  reset() {
    this.eventRate = null;
  }

  handleRemoveEventRateRequest(eventRate) {
    this.eventRate = eventRate;
  }

  handleRemoveEventRate() {
    if (!this.eventRate.id) {
      return;
    }
    uhApiClient.delete({
      url: `event_rates/${this.eventRate.id}`,
      success: {
        action: EventRateDeletionActions.removeEventRateSuccess,
        args: [this.eventRate.id],
      },
      error: EventRateDeletionActions.removeEventRateError,
    });
  }

  handleAbortRemoving() {
    this.reset();
  }

  handleRemoveEventRateSuccess() {
    const message = new TranslatableMessage({
      id: '.deleted',
      filename: __filenamespace,
    });
    this.reset();
    return MessageWindowActions.addMessage.defer(message);
  }

  handleRemoveEventRateError() {
    this.reset();
    this.notifyError('error removing event rate', arguments);
  }
}

export default alt.createStore(
  EventRateDeletionStore,
  'EventRateDeletionStore'
);
