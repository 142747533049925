import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

import PaysafeService from './PaysafeService';
import StripeService from './StripeService';
import { PaymentMethodManagementServiceResponse } from '../types';

/**
 * @description get gateway token using provided card Record
 * @private
 * @param cardRecord {PaymentCard}
 * @returns {Promise<*>}
 */
async function getCardToken(cardRecord) {
  let payload;
  let tokenFunction;
  const stripeCustomer = currentCustomer().use_stripe;

  if (stripeCustomer) {
    payload = cardRecord.stripePayload();
    tokenFunction = StripeService.tokenize;
  } else {
    payload = cardRecord.paysafePayload();
    tokenFunction = PaysafeService.tokenize;
  }

  try {
    return new Promise((resolve, reject) => {
      tokenFunction(payload, (...args) => {
        let _; // eslint-disable-line no-unused-vars
        let status;
        let result;
        let error;

        if (stripeCustomer) {
          [status, result] = args;
          if (status !== 200) reject(result.error);
        } else {
          // eslint-disable-next-line no-unused-vars
          [_, error, result] = args;
        }

        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * @description
 * @private
 * @param cardRecord {PaymentCard}
 * @returns {Promise<void>}
 */
async function getGatewayValidation(cardRecord) {
  const stripeCustomer = currentCustomer().use_stripe;

  if (stripeCustomer) {
    return StripeService.validateCard(cardRecord);
  }

  return PaysafeService.validateCard();
}

/**
 * @description create request payload based on payment method and card data
 * @private
 * @param cardRecord {PaymentCard} - optional
 * @returns {Promise<*>}
 */
async function buildPayload({ cardRecord }) {
  let payload;
  let token;

  if (cardRecord.isValid()) {
    let tokenRequest;

    try {
      await getGatewayValidation(cardRecord);
      tokenRequest = await getCardToken(cardRecord);
    } catch (e) {
      throw new Error(e.message);
    }

    token = tokenRequest.id || tokenRequest.token;
    payload = {
      card_token_id: token,
      method: 'card',
      payment_method: 'card',
    };
  } else {
    // Payment form will display errors, so exit here
    payload = null;
    throw new Error('Card record is invalid.');
  }

  return payload;
}

/**
 * @description creating card for a user
 * @public
 * @param cardRecord {PaymentCard} - required
 * @param response {function} - action to communicate w/ store
 * @returns {Promise<*>}
 */
async function createCard({ userId, cardRecord, response }) {
  let payload;

  try {
    payload = await buildPayload({ cardRecord });
  } catch (e) {
    response(PaymentMethodManagementServiceResponse.TOKEN_ERROR, e);
    return false;
  }

  response(PaymentMethodManagementServiceResponse.TOKEN_SUCCESS, '');

  return uhApiClient.post({
    url: `/users/${userId}/payment_methods`,
    data: JSON.stringify({
      card_token_id: payload.card_token_id,
      default: false,
    }),
    success: data =>
      response(
        PaymentMethodManagementServiceResponse.CREATE_SUCCESS,
        data,
        payload
      ),
    error: err =>
      response(PaymentMethodManagementServiceResponse.CREATE_ERROR, err),
  });
}

async function createAch({ userId, achRecord, response }) {
  const bankAccount = achRecord.toJS();
  delete bankAccount.errors;
  delete bankAccount.ach_payment_method_id;

  return uhApiClient.post({
    url: `/users/${userId}/payment_methods`,
    data: JSON.stringify({ ach_bank_account: bankAccount }),
    success: data =>
      response(PaymentMethodManagementServiceResponse.CREATE_SUCCESS, data, {
        ach_bank_account: bankAccount,
      }),
    error: err =>
      response(PaymentMethodManagementServiceResponse.CREATE_ERROR, err),
  });
}

const PaymentMethodManagementService = { createCard, createAch };
export default PaymentMethodManagementService;
