import { List, Map, Set } from 'immutable';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import MembershipListingStore from 'memberships/stores/MembershipListingStore.jsx';
import MembershipEventDiscount from 'shared/records/MembershipEventDiscount.jsx';
import MembershipDiscountActions from 'event_mgmt/display/actions/MembershipDiscountActions.js';
import POSMembershipListingActions from 'point_of_sale/actions/POSMembershipListingActions.js';
import POSMembershipListingStore from 'point_of_sale/stores/POSMembershipListingStore.js';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';

const addEventDiscount =
  med =>
  (ls = Set()) =>
    ls.add(med);

class MembershipDiscountStore extends UpperHandStore {
  constructor() {
    super();

    this.eventDiscountMap = Map();
    this.eventTypeDiscountMap = Map();

    this.findByEventId = eventId => this.eventDiscountMap.get(eventId, List());
    this.findByEventTypeId = id => {
      const allEventsDiscounts = this.eventTypeDiscountMap.get('all', Set());
      const eventTypeDiscounts = this.eventTypeDiscountMap.get(id, Set());

      const typeSpecificMembershipIds = eventTypeDiscounts.map(
        typeMed => typeMed.membership
      );

      return allEventsDiscounts
        .filter(med => !typeSpecificMembershipIds.has(med.membership_id))
        .union(eventTypeDiscounts);
    };

    this.bindListeners({
      list: EventDisplayActions.CLIENT_DISPLAY_MOUNTED,

      listSuccess: MembershipDiscountActions.LIST_SUCCESS,
      listError: MembershipDiscountActions.LIST_ERROR,

      membershipsLoaded: MembershipListingActions.LIST_RECEIVED,
      posMembershipsLoaded: POSMembershipListingActions.LIST_SUCCESS,
      posEventSelected: POSProductListActions.eventSelected,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  list({ eventId }) {
    if (!isLoggedIn()) {
      return;
    }

    uhApiClient.get({
      url: `events/${eventId}/membership_event_discounts`,
      data: { fields: ['subscribable'] },
      success: {
        action: MembershipDiscountActions.listSuccess,
        args: [eventId],
      },
      error: MembershipDiscountActions.listError,
    });
  }

  listSuccess([data, eventId]) {
    const eventDiscounts = List(
      data.membership_event_discounts.map(
        med => new MembershipEventDiscount(med)
      )
    );

    this.eventDiscountMap = this.eventDiscountMap.set(eventId, eventDiscounts);

    this.loadFromMembershipsEventDiscounts(eventDiscounts);
  }

  listError(...args) {
    this.notifyError('error while listing membership event discounts', args);
  }

  membershipsLoaded() {
    this.waitFor(MembershipListingStore);

    this.loadFromMemberships(MembershipListingStore.getState().records);
  }

  posMembershipsLoaded() {
    this.waitFor(POSMembershipListingStore);

    this.loadFromMemberships(POSMembershipListingStore.getState().memberships);
  }

  posEventSelected(event) {
    this.list({ eventId: event.id });
  }

  loadFromMembershipsEventDiscounts(membershipEventDiscounts) {
    this.eventTypeDiscountMap = this.eventTypeDiscountMap.withMutations(map => {
      membershipEventDiscounts.forEach(med => {
        if (med.all_events) {
          map.update('all', addEventDiscount(med));
        } else {
          med.event_type_ids.forEach(id =>
            map.update(id, addEventDiscount(med))
          );
        }
      });
    });
  }

  loadFromMemberships(memberships) {
    this.eventTypeDiscountMap = this.eventTypeDiscountMap.withMutations(map => {
      memberships.forEach(membership => {
        membership.membership_event_discounts.forEach(med => {
          if (med.all_events) {
            map.update('all', addEventDiscount(med));
          } else {
            med.event_type_ids.forEach(id =>
              map.update(id, addEventDiscount(med))
            );
          }
        });
      });
    });
  }
}

export default alt.createStore(
  MembershipDiscountStore,
  'MembershipDiscountStore'
);
