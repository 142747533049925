import * as React from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';

import AccountInformationEditor from 'user_management/shared/components/_AccountInformationEditor.jsx';
import Client from 'shared/records/Client.jsx';
import ContactInformationEditor from 'user_management/shared/components/_ContactInformationEditor.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import Staff from 'shared/records/Staff.jsx';
import User from 'event_mgmt/shared/records/User.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  column: {
    flex: 0.5,
    margin: `${LEFT_MARGIN_PIXELS}px 45px 0 45px`,
    maxWidth: SINGLE_COLUMN_WIDTH,
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

function ActionRow({ positionActionsRight, actions, justifyForm }) {
  if (positionActionsRight) {
    return (
      <FlexBox
        style={merge(styles.row, {
          justifyContent: justifyForm,
          marginBottom: '1rem',
        })}
      >
        <div style={merge(styles.column, { marginTop: 0, marginBottom: 0 })} />
        <FlexBox
          style={merge(styles.column, {
            justifyContent: 'flex-start',
            flex: 1,
            margin: 0,
          })}
        >
          {actions}
        </FlexBox>
      </FlexBox>
    );
  }
  return (
    <FlexBox
      style={merge(styles.row, {
        justifyContent: justifyForm,
        marginBottom: '1rem',
      })}
    >
      <FlexBox
        style={merge(styles.column, { justifyContent: 'flex-end', flex: 1 })}
      >
        {actions}
      </FlexBox>
      <div style={merge(styles.column, { marginTop: 0, marginBottom: 0 })} />
    </FlexBox>
  );
}

function UserPresentation({
  errors,
  userProfile,
  handleUserProfileChange,
  handleAddressChange,
  actions,
  positionActionsRight,
  hideFields,
  justifyForm,
  dobLabelText,
}) {
  return (
    <div
      className="user-presentation"
      style={{ ...styles.root, justifyContent: justifyForm }}
    >
      <div style={styles.column}>
        <AccountInformationEditor
          userProfile={userProfile}
          errors={errors}
          onChange={handleUserProfileChange}
          hideFields={hideFields}
          dobLabelText={dobLabelText}
        />
      </div>
      <div style={styles.column}>
        <ContactInformationEditor
          userProfile={userProfile}
          onAddressChange={handleAddressChange}
          onChange={handleUserProfileChange}
          errors={errors}
          hideFields={hideFields}
        />
        <ActionRow
          positionActionsRight={positionActionsRight}
          actions={actions}
          justifyForm={justifyForm}
        />
      </div>
    </div>
  );
}

UserPresentation.propTypes = {
  handleAddressChange: PropTypes.func.isRequired,
  handleUserProfileChange: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(FieldErrors).isRequired,
  userProfile: PropTypes.oneOfType([
    PropTypes.instanceOf(Client),
    PropTypes.instanceOf(Staff),
    PropTypes.instanceOf(User),
  ]).isRequired,
  positionActionsRight: PropTypes.bool,
  actions: PropTypes.element.isRequired,
  hideFields: PropTypes.instanceOf(Set),
  justifyForm: PropTypes.string,
  dobLabelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

UserPresentation.defaultProps = {
  hideFields: Set(),
  positionActionsRight: false,
  justifyForm: 'center',
  dobLabelText: '',
};

export default UserPresentation;
