import * as React from 'react';
import { padStart } from 'event_mgmt/shared/utils/FormattingUtils.jsx';

export default class Timer extends React.Component {
  constructor(props) {
    super(props);

    const startMs = props.start.toDate().getTime();

    this.state = {
      start: startMs,
      elapsedMs: Date.now() - startMs,
    };

    this.updateElapsedMs = this.updateElapsedMs.bind(this);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.updateElapsedMs, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  updateElapsedMs() {
    const { start } = this.state;

    this.setState({ elapsedMs: Date.now() - start });
  }

  // eslint-disable-next-line class-methods-use-this
  msToTimeString(ms) {
    const hours = Math.floor(ms / (1000 * 60 * 60)).toString();
    const minutes = Math.floor((ms / (1000 * 60)) % 60).toString();
    const seconds = Math.floor((ms / 1000) % 60).toString();

    return `${hours}:${padStart(minutes, 2)}:${padStart(seconds, 2)}`;
  }

  render() {
    const { elapsedMs } = this.state;

    return (
      <div style={{ fontFeatureSettings: '"tnum"' }}>
        {this.msToTimeString(elapsedMs)}
      </div>
    );
  }
}
