import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import AddButton from 'shared/components/AddButton.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventRateCreationActions from 'contacts/shared/actions/event_rates/EventRateCreationActions';
import EventRateCreationStore from 'contacts/shared/stores/event_rates/EventRateCreationStore';
import EventRateDeletionActions from 'contacts/shared/actions/event_rates/EventRateDeletionActions';
import EventRateDeletionStore from 'contacts/shared/stores/event_rates/EventRateDeletionStore';
import EventRateEditingStore from 'contacts/shared/stores/event_rates/EventRateEditingStore';
import EventRateListingStore from 'contacts/shared/stores/event_rates/EventRateListingStore';
import EventRateForm from 'contacts/index/components/event_rates/EventRateForm.jsx';
import EventRateList from 'contacts/index/components/event_rates/EventRateList.jsx';
import CommissionRateCreationActions from 'contacts/shared/actions/commission_rates/CommissionRateCreationActions';
import CommissionRateCreationStore from 'contacts/shared/stores/commission_rates/CommissionRateCreationStore';
import CommissionRateDeletionActions from 'contacts/shared/actions/commission_rates/CommissionRateDeletionActions';
import CommissionRateDeletionStore from 'contacts/shared/stores/commission_rates/CommissionRateDeletionStore';
import CommissionRateEditingStore from 'contacts/shared/stores/commission_rates/CommissionRateEditingStore';
import CommissionRateListingStore from 'contacts/shared/stores/commission_rates/CommissionRateListingStore';
import CommissionRateForm from 'contacts/index/components/commission_rates/CommissionRateForm.jsx';
import CommissionRateList from 'contacts/index/components/commission_rates/CommissionRateList.jsx';

import Form from 'shared/components/Form.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import SpecificEventsListStore from 'shared/stores/SpecificEventsListStore.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';
import StaffDetailsDrawerStore from 'contacts/shared/stores/StaffDetailsDrawerStore.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxJustify,
} from 'shared/components/FlexBox.jsx';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import CommissionRateListingActions from 'contacts/shared/actions/commission_rates/CommissionRateListingActions';

const styles = {
  body: (isEditing, canEdit) => ({
    padding: canEdit ? '0px 8px 0 20px' : '0px 20px',
    height: isEditing ? 96 : 48,
    overflow: 'hidden',
    transition: 'height 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  }),

  currencyIcon: {
    top: '50%',
  },

  hourlyRateInput: {
    textAlign: 'right',
    fontSize: '14px',
  },

  hourlyRateTextField: {
    width: '5em',
  },
};

const toggleEdit = _ => StaffDetailsDrawerActions.togglePayrollEditMode();

const onRateChange = (e, value) => {
  e.stopPropagation();
  StaffDetailsDrawerActions.hourlyRateUpdated(value);
};

const onRateSaved = _ => StaffDetailsDrawerActions.hourlyRateSaved();

function HourlyRate({ staff, hourlyRate, isEditing, canEditPayroll, intl }) {
  if (isEditing) {
    return (
      <Form onSubmit={onRateSaved}>
        <CurrencyTextField
          autoFocus
          variant="standard"
          name="hourly_rate"
          value={hourlyRate}
          symbolStyle={styles.currencyIcon}
          textFieldStyle={styles.hourlyRateTextField}
          inputProps={{ sx: styles.hourlyRateInput }}
          onChange={onRateChange}
          converter={e => parseInt(e, 10)}
        />
      </Form>
    );
  }
  if (staff.hourly_rate === 0 && canEditPayroll) {
    return (
      <Button onClick={toggleEdit}>
        {t('actions.add', intl, __filenamespace)}
      </Button>
    );
  }
  return <FormattedCurrency value={staff.hourly_rate} fromCents />;
}

function DeletionConfirmation({ eventRate, intl }) {
  return (
    <ConfirmationDialog
      awaitingConfirmation={!!eventRate}
      onCancel={EventRateDeletionActions.removeEventRateAborted}
      onConfirm={EventRateDeletionActions.removeEventRateConfirmed}
      title={t('.remove_event_rate', intl, __filenamespace)}
    >
      <div style={{ marginBottom: 20 }}>
        <FormattedMessage id={messageId('.are_you_sure', __filenamespace)} />
      </div>
    </ConfirmationDialog>
  );
}

function CommissionDeletionConfirmation({ commissionRate, intl }) {
  return (
    <ConfirmationDialog
      awaitingConfirmation={!!commissionRate}
      onCancel={CommissionRateDeletionActions.removeCommissionRateAborted}
      onConfirm={CommissionRateDeletionActions.removeCommissionRateConfirmed}
      title={t('.remove_commission_rate', intl, __filenamespace)}
    >
      <div style={{ marginBottom: 20 }}>
        <FormattedMessage
          id={messageId('.confirm_delete_commission', __filenamespace)}
        />
      </div>
    </ConfirmationDialog>
  );
}

function StaffDetailsDrawerPayroll({
  eventRateCreationStore,
  eventRateDeletionStore: { eventRate: eventRateToDelete },
  eventRateEditingStore,
  eventRateListingStore,

  commissionRateCreationStore,
  commissionRateDeletionStore: { commissionRate: commissionRateToDelete },
  commissionRateEditingStore,
  commissionRateListingStore,
  specificEventsListStore,
  staffDetailsDrawerStore: {
    staff,
    hourlyRate,
    canEditPayroll,
    payrollEditMode: isEditing,
    isSavingHourlyRate: isSaving,
  },
  intl,
}) {
  React.useEffect(() => {
    CommissionRateListingActions.list({
      customerUserId: staff.id,
      isStaffDrawer: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={styles.body(isEditing, canEditPayroll)}>
        <FlexBoxJustify style={{ alignItems: 'center' }}>
          <div style={{ fontWeight: 'bold' }}>
            <FormattedMessage id={messageId('.hourly_rate', __filenamespace)} />
          </div>
          <FlexBoxCenter style={{ minHeight: 48 }}>
            <HourlyRate
              staff={staff}
              hourlyRate={hourlyRate}
              isEditing={isEditing}
              canEditPayroll={canEditPayroll}
              intl={intl}
            />
            {staff.hourly_rate > 0 && canEditPayroll && (
              <IconButton onClick={toggleEdit}>
                <EditIcon sx={{ color: uhColors.navIconGrey }} />
              </IconButton>
            )}
          </FlexBoxCenter>
        </FlexBoxJustify>

        {isEditing && (
          <FlexBox style={{ marginBottom: 12 }}>
            <StateChangingButton
              onClick={onRateSaved}
              disabled={isSaving}
              inProgress={isSaving}
              style={{ marginRight: 5 }}
            />
            <Button
              onClick={toggleEdit}
              disabled={isSaving}
              style={{ marginLeft: 5 }}
            >
              {t('actions.cancel', intl, __filenamespace)}
            </Button>
          </FlexBox>
        )}
      </div>

      <Divider />
      <div style={{ padding: 16 }}>
        <SpinWhileLoading
          isLoading={
            eventRateListingStore.isLoading ||
            specificEventsListStore.isLoading ||
            commissionRateListingStore.isLoading
          }
        >
          {hasAdminPermission() && (
            <div>
              {eventRateCreationStore.eventRate ? (
                <EventRateForm
                  selectedEvents={specificEventsListStore.events.filter(e =>
                    eventRateCreationStore.eventRate.event_ids.has(e.id)
                  )}
                  eventRateStore={eventRateCreationStore}
                  selectedEventIds={eventRateListingStore.selectedEventIds}
                  style={{ marginBottom: 10 }}
                  intl={intl}
                />
              ) : (
                <AddButton
                  disabled={eventRateEditingStore.eventRate}
                  label={t('.add_event_rate', intl, __filenamespace)}
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    EventActions.list({
                      exceptIds:
                        eventRateListingStore.selectedEventIds.toArray(),
                    });
                    EventRateCreationActions.begin();
                  }}
                />
              )}
            </div>
          )}

          {hasAdminPermission() && (
            <div>
              {commissionRateCreationStore.commissionRate ? (
                <CommissionRateForm
                  selectedItems={commissionRateListingStore.items
                    .filter(e =>
                      commissionRateCreationStore.commissionRate.item_ids.has(
                        e?.id
                      )
                    )
                    .groupBy(item => item.id)
                    .map(group => group.first())
                    .toList()}
                  commissionRateStore={commissionRateCreationStore}
                  selectedItemIds={commissionRateListingStore.selectedItemIds}
                  style={{ marginBottom: 10 }}
                  intl={intl}
                  items={commissionRateListingStore.items}
                />
              ) : (
                <AddButton
                  disabled={commissionRateEditingStore.commissionRate}
                  label={t('.add_commission_rate', intl, __filenamespace)}
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    CommissionRateListingActions.handleSearch({
                      searchText: '',
                    });
                    CommissionRateCreationActions.begin();
                  }}
                />
              )}
            </div>
          )}

          <CommissionRateList
            commissionRateCreationStore={commissionRateCreationStore}
            commissionRateEditingStore={commissionRateEditingStore}
            commissionRateListingStore={commissionRateListingStore}
          />
          <EventRateList
            specificEventsListStore={specificEventsListStore}
            eventRateCreationStore={eventRateCreationStore}
            eventRateEditingStore={eventRateEditingStore}
            eventRateListingStore={eventRateListingStore}
          />
        </SpinWhileLoading>
        <DeletionConfirmation eventRate={eventRateToDelete} intl={intl} />
        <CommissionDeletionConfirmation
          commissionRate={commissionRateToDelete}
          intl={intl}
        />
      </div>
    </div>
  );
}

function WrappedStaffDetailsDrawerPayroll(props) {
  return (
    <AltContainer
      stores={{
        staffDetailsDrawerStore: StaffDetailsDrawerStore,
        eventRateCreationStore: EventRateCreationStore,
        eventRateDeletionStore: EventRateDeletionStore,
        eventRateEditingStore: EventRateEditingStore,
        eventRateListingStore: EventRateListingStore,
        specificEventsListStore: SpecificEventsListStore,
        commissionRateCreationStore: CommissionRateCreationStore,
        commissionRateDeletionStore: CommissionRateDeletionStore,
        commissionRateEditingStore: CommissionRateEditingStore,
        commissionRateListingStore: CommissionRateListingStore,
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StaffDetailsDrawerPayroll {...props} />
    </AltContainer>
  );
}

export default injectIntl(WrappedStaffDetailsDrawerPayroll);
