import { List, Record, Set } from 'immutable';

class AvailableResourceIdCollection extends Record({
  staff_ids: List(),
  resource_ids: List(),
}) {
  constructor(object = {}, options = {}) {
    super(
      {
        staff_ids: Set(object.staff_ids),
        resource_ids: Set(object.resource_ids),
      },
      options
    );
  }
}

export default AvailableResourceIdCollection;
