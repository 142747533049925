import * as React from 'react';
import { Route } from 'react-router-dom';

import CreditPassEditing from 'credit_passes/components/CreditPassEditing.jsx';
import CreditPassViewing from 'credit_passes/components/CreditPassViewing.jsx';
import { CreditPassesPage } from 'routes/helpers/CustomersPageHelpers.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';
import Page from 'routes/Page.jsx';

import {
  isAdmin,
  isStaff,
  isStaffAdmin,
  isManager,
  isClient,
  isUnauthorized,
} from 'routes/helpers/routePermissions';

const creditPassesRoutes = () => (
  <Route path=":customer_id">
    <Route
      path="credit_passes"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaff, isClient, isUnauthorized]}
        >
          <Page>
            <CreditPassesPage />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="credit_passes/new"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <CreditPassEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="credit_passes/:creditPassId"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <CreditPassViewing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="credit_passes/:creditPassId/edit"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <CreditPassEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
  </Route>
);

export default creditPassesRoutes;
