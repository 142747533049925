import * as React from 'react';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

function QuickAccessButton({ intl }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const historyLink = () => {
    navigate(customerScopedRoute('/events/new'));
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Fab color="secondary" size="small" onClick={handleClick}>
        <AddIcon />
      </Fab>
      <Menu anchorEl={anchorEl} open={open} onClick={handleClose}>
        {!currentUser().isCoach() && (
          <MenuItem onClick={() => POSActions.posClicked()}>
            {t('.point_of_sale', intl, __filenamespace)}
          </MenuItem>
        )}
        <MenuItem onClick={InviteDialogActions.openInviteDialog}>
          {t('.send_invite', intl, __filenamespace)}
        </MenuItem>
        <Divider />
        {hasAdminPermission() && (
          <MenuItem onClick={historyLink}>
            {t('.new_event', intl, __filenamespace)}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default injectIntl(QuickAccessButton);
