import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import WaitlistActions from 'event_mgmt/shared/actions/WaitlistActions.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import { FlexBoxJustify, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

class WaitlistEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { awaitingRemoval: false };
  }

  render() {
    const { athlete, intl } = this.props;
    const { awaitingRemoval } = this.state;
    return (
      <FlexBoxJustify style={{ marginBottom: '0.5rem' }}>
        <FlexBoxCenter>
          <AvatarWithName user={athlete} style={{ marginRight: '0.75em' }} />
        </FlexBoxCenter>
        <div style={{ alignSelf: 'flex-end' }}>
          <IconButton
            iconStyle={{ height: '20px', width: '20px' }}
            onClick={() => {
              this.setState({ awaitingRemoval: true });
            }}
          >
            <ClearIcon sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </div>
        <ConfirmationDialog
          awaitingConfirmation={awaitingRemoval}
          onCancel={() => {
            this.setState({ awaitingRemoval: false });
          }}
          onConfirm={() => {
            this.setState({ awaitingRemoval: false });
            WaitlistActions.delete(athlete.id);
          }}
          title={t('.dialog_title', intl, __filenamespace)}
          submitText={t('.yes', intl, __filenamespace)}
          cancelText={t('.no', intl, __filenamespace)}
        >
          <FormattedMessage
            id={messageId('.are_you_sure', __filenamespace)}
            values={{ name: athlete.name() }}
          />
        </ConfirmationDialog>
      </FlexBoxJustify>
    );
  }
}

export default injectIntl(WaitlistEntry);
