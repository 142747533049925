import * as React from 'react';
import { injectIntl } from 'react-intl';
import Form from 'onboarding/components/AccountSetupPaysafe/forms/_Form.jsx';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { t } from 'shared/utils/LocaleUtils.js';

function Question({ id, title, answers, onChange }) {
  const children = answers
    .entrySeq()
    .map(([key, label]) => (
      <FormControlLabel
        key={key}
        value={key}
        control={<Radio />}
        label={label}
      />
    ));
  return (
    <div data-fs-exclude style={Styles.identity_questions.container}>
      <h2 style={Styles.radioHeader}>{title}</h2>
      <RadioGroup name={`question-${id}`} onChange={onChange}>
        {children}
      </RadioGroup>
    </div>
  );
}

function IdentityQuestions({ intl, questions, onChange }) {
  const children = questions
    .entrySeq()
    .map(([key, value]) => (
      <Question
        key={key}
        id={key}
        title={value.get('question')}
        answers={value.get('answers')}
        onChange={onChange}
      />
    ));

  return <Form header={t('.header', intl, __filenamespace)}>{children}</Form>;
}

export default injectIntl(IdentityQuestions);
