/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@upperhand/playmaker';

const COUNTRIES_LIST = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
];

function CountrySelector({
  name = 'country',
  countries = COUNTRIES_LIST,
  fullWidth = true,
  label = 'Country',
  placeholder = 'Select a country',
  value = 'US',
  outline = true,
  disableUnderline = false,
  classes = {},
  onChange = () => {},
}) {
  return (
    <Dropdown
      fullWidth={fullWidth}
      classes={classes}
      outline={outline}
      disableUnderline={disableUnderline}
      name={name}
      label={label}
      placeholder={placeholder}
      items={countries}
      value={value}
      onChange={e => onChange(e, e.target.value, name)}
    />
  );
}

CountrySelector.propTypes = {
  countries: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(CountrySelector);
