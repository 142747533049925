import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@upperhand/playmaker';

import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions.js';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import { t } from 'shared/utils/LocaleUtils.js';
import { Balance } from 'records';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';
import OrderSummaryDrawerActions from 'containers/reports/orderSummaryDrawer/Actions';
import PaymentPlanDetailsDrawerActions from 'containers/reports/paymentPlanDetailsDrawer/Actions';
import BalanceDetailsDrawerActions from '../Actions';
import PaymentDetailsDrawerActions from '../../paymentDetailsDrawer/Actions';

const openModal = (finalAction, isPaymentDetailsDrawer) => {
  // Need to close drawers because QPay will not be focusable (MUI 0.x vs MUI 4 conflict)
  if (isPaymentDetailsDrawer) {
    PaymentDetailsDrawerActions.closeDrawer();
    PaymentPlanDetailsDrawerActions.closeAllDrawers();
  } else {
    BalanceDetailsDrawerActions.closeDrawer();
  }
  BalanceListDrawerActions.closeDrawer();
  OrderSummaryDrawerActions.closeDrawer();
  finalAction();
};

function DotsMenu({
  anchorEl,
  balance,
  intl,
  showReceiptEnabled,
  isPaymentDetailsDrawer,
}) {
  if (!balance) return null;

  const computedPermission =
    (currentUser().isStaffMember() &&
      currentCustomer().enable_staff_member_refund) ||
    hasAdminPermission();
  const hasOutstandingBalance = balance.outstanding > 0;
  const allowRefund = computedPermission && balance.get('paid') > 0;
  const allowWaive = computedPermission && hasOutstandingBalance;
  const menuItems = [];
  const handleSendReceipt = () => {
    if (isPaymentDetailsDrawer) {
      PaymentDetailsDrawerActions.sendReceipt();
    } else {
      BalanceDetailsDrawerActions.sendReceipt();
    }
  };
  const handleCloseMenu = () => {
    if (isPaymentDetailsDrawer) {
      PaymentDetailsDrawerActions.toggleDotsMenu();
    } else {
      BalanceDetailsDrawerActions.toggleDotsMenu();
    }
  };

  if (showReceiptEnabled) {
    menuItems.push({
      label: t('.send_receipt', intl, __filenamespace),
      onClick: handleSendReceipt,
    });
  }

  if (hasOutstandingBalance) {
    menuItems.push({
      label: t('.pay_balance', intl, __filenamespace),
      onClick: () =>
        openModal(
          () => QuickpayModalActions.toggleQuickpayModal(balance, 'balance'),
          isPaymentDetailsDrawer
        ),
    });
  }

  if (allowRefund) {
    menuItems.push({
      label: t('.refund', intl, __filenamespace),
      onClick: () =>
        openModal(
          () =>
            RefundModalActions.openModal(
              balance.get('productId'),
              balance.details.get(-1).get('type'),
              'refund'
            ),
          isPaymentDetailsDrawer
        ),
    });
  }

  if (allowWaive) {
    menuItems.push({
      label: t('.waive', intl, __filenamespace),
      onClick: () =>
        openModal(
          () =>
            RefundModalActions.openModal(
              balance.get('productId'),
              balance.details.get(-1).get('type'),
              'waive'
            ),
          isPaymentDetailsDrawer
        ),
    });
  }

  return <Menu anchor={anchorEl} items={menuItems} onClose={handleCloseMenu} />;
}

DotsMenu.propTypes = {
  anchorEl: PropTypes.any,
  balance: PropTypes.instanceOf(Balance).isRequired,
  intl: PropTypes.object.isRequired,
  showReceiptEnabled: PropTypes.bool,
};

DotsMenu.defaultProps = {
  anchorEl: null,
  showReceiptEnabled: false,
};

export default React.memo(DotsMenu);
