import ContactImportActions from 'contacts/index/actions/ContactImportActions.js';
import ContactUpload from 'shared/records/ContactUpload.js';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { upload } from 'shared/utils/S3Utils';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

export const STEP_WAITING = 'waiting';
export const STEP_UPLOADING = 'uploading';
export const STEP_PROCESSING = 'processing';
export const STEP_SUCCESS = 'success';
export const STEP_FAILURE = 'failure';
export const STEP_ERROR = 'error';

class ContactImportDesktopStore extends UpperHandStore {
  constructor() {
    super();

    this.step = STEP_WAITING;
    this.drawerOpen = false;
    this.contactUpload = new ContactUpload();
    this.error = null;

    this.interval = null;

    this.bindListeners({
      selectFile: ContactImportActions.FILE_SELECTED,
      prepareDrawer: ContactImportActions.DRAWER_OPENED,
      closeDrawer: ContactImportActions.DRAWER_CLOSED,
      existingUploadListSuccess:
        ContactImportActions.EXISTING_UPLOAD_LIST_SUCCESS,
      existingUploadListError: ContactImportActions.EXISTING_UPLOAD_LIST_ERROR,
      handleFileUploadSuccess: ContactImportActions.FILE_UPLOAD_SUCCESS,
      handleFileUploadError: ContactImportActions.FILE_UPLOAD_ERROR,
      createContactUploadError:
        ContactImportActions.CREATE_CONTACT_UPLOAD_ERROR,
      checkUploadStatus: [
        ContactImportActions.FETCH_CONTACT_UPLOAD_SUCCESS,
        ContactImportActions.CREATE_CONTACT_UPLOAD_SUCCESS,
      ],
      fetchContactUploadError: ContactImportActions.FETCH_CONTACT_UPLOAD_ERROR,
    });
  }

  prepareDrawer() {
    uhApiClient.get({
      url: '/contact_uploads',
      data: { status: 'in_progress' },
      success: ContactImportActions.existingUploadListSuccess,
      error: ContactImportActions.existingUploadListError,
    });

    this.drawerOpen = true;
  }

  closeDrawer() {
    this.drawerOpen = false;
  }

  existingUploadListSuccess(data) {
    if (data.total_count > 0) {
      this.checkUploadStatus(data.contact_uploads[0]);
    }
  }

  existingUploadListError(...args) {
    this.notifyError('error retrieving existing contact uploads', args);
  }

  selectFile(file) {
    this.step = STEP_UPLOADING;
    upload(
      file,
      false,
      false,
      ContactImportActions.fileUploadSuccess,
      ContactImportActions.fileUploadError
    );
  }

  handleFileUploadSuccess(csv) {
    this.step = STEP_UPLOADING;
    this.contactUpload = new ContactUpload({
      csv,
    });

    return uhApiClient.post({
      url: '/contact_uploads',
      data: JSON.stringify({ attributes: this.contactUpload.toJS() }),
      success: ContactImportActions.createContactUploadSuccess,
      error: ContactImportActions.createContactUploadError,
    });
  }

  handleFileUploadError(data) {
    this.step = STEP_WAITING;
    this.error = new TranslatableMessage({
      id: '.upload_error',
      filename: __filenamespace,
    });

    this.notifyError('error uploading file', data);
  }

  createContactUploadSuccess(data) {
    this.step = STEP_PROCESSING;
    this.contactUpload = new ContactUpload(data);

    setTimeout(() => this.fetchContactUpload(), 2000);
  }

  createContactUploadError(...args) {
    this.error = new TranslatableMessage({
      id: '.unknown_error',
      filename: __filenamespace,
    });
    this.step = STEP_WAITING;

    this.notifyError('error creating contact upload', args);
  }

  fetchContactUpload() {
    uhApiClient.get({
      url: `/contact_uploads/${this.contactUpload.id}`,
      success: ContactImportActions.fetchContactUploadSuccess,
      error: ContactImportActions.fetchContactUploadError,
    });
  }

  fetchContactUploadError(...args) {
    this.notifyError('error fetching ContactUpload', args);
  }

  checkUploadStatus(data) {
    this.contactUpload = new ContactUpload(data);

    if (this.contactUpload.isInProgress()) {
      this.step = STEP_PROCESSING;
      setTimeout(() => this.fetchContactUpload(), 2000);
    } else if (this.contactUpload.hasErrors()) {
      this.step = STEP_ERROR;
    } else if (this.contactUpload.isFailed()) {
      this.step = STEP_FAILURE;
      this.error = new TranslatableMessage({
        id: '.import_failed',
        filename: __filenamespace,
        values: { failureReason: this.contactUpload.failure_reason },
      });
    } else {
      this.step = STEP_SUCCESS;
    }
  }
}

export default alt.createStore(
  ContactImportDesktopStore,
  'ContactImportDesktopStore'
);
