class RefundModalActions {
  constructor() {
    this.generateActions(
      'closeModal',
      'openModal',
      'setStartDate',
      'setEndDate',
      'download',
      'selectReport',
      'downloadSuccess',
      'downloadError'
    );
  }
}

export default alt.createActions(RefundModalActions);
