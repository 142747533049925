import { Iterable, List, Map } from 'immutable';

import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';
import TeamTranslator from 'team_mgmt/shared/translators/TeamTranslator.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import CustomerTeam from 'team_mgmt/shared/records/CustomerTeam.jsx';
import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import { isCollectionWithContents, merge } from 'shared/utils/ObjectUtils.jsx';
import {
  currentCustomer,
  enabledCustomerFeatures,
} from 'shared/utils/CustomerUtils';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import { TeamSource } from 'sources';
import DateOfBirth from 'records/DateOfBirth.jsx';

const teamUrl = id => (id ? `team_events/${id}` : 'team_events');

class TeamStore extends UpperHandStore {
  constructor() {
    super();
    this.handleReset();

    this.bindListeners({
      handleReset: StoreActions.PREPARE_FOR_REUSE,
      handleUpdateTeamStore: TeamActions.UPDATE_TEAM_STORE,
      handlePostTeam: TeamActions.POST_TEAM,
      handleCreateOrUpdateServer: TeamActions.CREATE_OR_UPDATE_SERVER,
      handleUpdateTeamSelection: TeamActions.UPDATE_TEAM_SELECTION,
      handleFetch: TeamActions.FETCH,

      updateTeamStoreFromServer: [TeamActions.FETCH_SUCCESS],
      handleError: TeamActions.ERROR,
      markDirty: TeamActions.MARK_DIRTY,
      handleTeamTypeSelect: TeamActions.TEAM_TYPE_SELECTED,
      fillPaymentMethods: TeamActions.FILL_PAYMENT_METHODS,
      redirectAfterSaveForNewTeams: TeamActions.CREATE_SUCCESS,
      handleTeamUpdateSuccess: TeamActions.UPDATE_SUCCESS,
      handleTeamCreateSuccess: TeamActions.CREATE_SUCCESS,
      handleCloneTeam: TeamActions.clone,
      handleCloneSuccess: TeamActions.cloneSuccess,
      handleResetDobFields: TeamActions.resetDobFields,
      handleResetAgeFields: TeamActions.resetAgeFields,
    });
  }

  handleReset() {
    this.customerTeam = new CustomerTeam();
    this.serverTeam = new CustomerTeam();
    this.fieldErrors = Map();
    this.isDeleting = false;
    this.isLoadingTeam = false;
    this.isSavingTeam = false;
    this.saveRequests = Map();
    this.savedState =
      'save'; /* make this a function of isSavingTeam and saveRequests, see https://www.pivotaltracker.com/story/show/138437383 */
    this.teamsToUpdate = List();
    this.selectedTeams = List();
  }

  markDirty([key, value]) {
    if (!this.saveRequests || !Iterable.isIterable(this.saveRequests)) {
      this.saveRequests = Map(this.saveRequests);
    }
    if (value) {
      this.saveRequests = this.saveRequests.set(key, true);
    } else {
      this.saveRequests = this.saveRequests.remove(key);
    }
    this.savedState =
      this.saveRequests.filter((v, _k) => !!v).size > 0 ? 'save' : 'saved';
  }

  handleUpdateTeamStore(args) {
    const keyPath = args[0];
    const value = args[1];

    if (keyPath.length) {
      this.customerTeam = this.customerTeam.setIn(keyPath, value);
    } else {
      this.customerTeam = value;
    }
    this.markDirty(['TeamStore', true]);
  }

  handleTeamTypeSelect(teamTypeId) {
    const teamType = TeamTypeListingStore.getState().findById(teamTypeId);
    this.customerTeam = this.customerTeam
      .set('team_type', teamType)
      .set('team_type_id', teamTypeId);
  }

  handlePostTeam(_e) {
    this.handleUpdateTeamStore([['status'], 'active']);
    if (this.teamIsValid()) {
      this.setPostedTeamDefaults();
      this.handleCreateOrUpdateServer();
    } else {
      this.handleUpdateTeamStore([['status'], 'draft']);
    }
  }

  setPostedTeamDefaults() {
    if (this.customerTeam.description === '') {
      this.handleUpdateTeamStore([
        ['description'],
        'More team information to follow. Let us know if you have any questions in the meantime.',
      ]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  teamIsValid() {
    const { status, sport_type: sportType, seasons } = this.customerTeam;

    this.fieldErrors = Map();
    if (
      status === 'cancelled' ||
      status === 'completed' ||
      status === 'deleted'
    ) {
      return true;
    }
    const title = this.customerTeam.title
      ? this.customerTeam.title.trim()
      : this.customerTeam.title;

    if (title === '' && status === 'active') {
      this.addError('title', 'Title is required to post team.');
    }

    if (this.customerTeam.getIn(['team_detail', 'roster_size']) === '') {
      this.addError('roster_size', 'Roster Size is required to post team.');
    }

    if (sportType === '') {
      this.addError('sport_type', 'Sport type is required to post team.');
    }

    if (seasons.size === 0) {
      this.addError('seasons', 'Season is required to post team.');
    }

    this.validateTeamType();
    this.validatePrice();
    this.validatePaymentMethods();
    return this.fieldErrors.isEmpty();
  }

  validatePaymentMethods() {
    this.clearError('payment_methods');

    if (!this.customerTeam.get('id')) return;

    const paymentMethods = this.customerTeam.get('payment_methods');
    const isInvalid = !paymentMethods.some(m => m);

    if (isInvalid) {
      this.addError(
        'payment_methods',
        'At least one payment method need to be selected.'
      );
    }
  }

  validateTeamType() {
    if (this.customerTeam.status !== 'active') {
      return;
    }
    const teamTypeId = this.customerTeam.team_type_id;
    if (teamTypeId) {
      return;
    }
    this.addError('team_type', 'Team type is required to post team.');
  }

  validatePrice() {
    const descriptions = AutomationTemplateDescriptionStore.getState().forEvent(
      this.customerTeam.id
    );

    let price;
    if (isCollectionWithContents(descriptions)) {
      price =
        PricingAutomationCollection.create(descriptions).getMinimumPrice();
    } else {
      price = this.customerTeam.base_price;
    }

    if (
      this.customerTeam.status === 'active' &&
      (price === '' || price == null)
    ) {
      this.addError('base_price', 'Price required to post team.');
    }
  }

  addError(field, error) {
    const fieldErrors = this.fieldErrors.get(field)
      ? this.fieldErrors.get(field).push(error)
      : List([error]);

    this.fieldErrors = this.fieldErrors.set(field, fieldErrors);
  }

  clearError(field) {
    this.fieldErrors = this.fieldErrors.delete(field);
  }

  handleUpdateTeamSelection([teams, status]) {
    this.selectedTeams = List(teams);
    this.teamsToUpdate = this.selectedTeams.map(team =>
      team.set('status', status)
    );
    if (this.teamsToUpdate.size) {
      this.customerTeam = this.teamsToUpdate.first();
      if (
        this.customerTeam.status !==
        this.selectedTeams.find(e => e.id === this.customerTeam.id).status
      ) {
        this.updateTeam(true);
      }
    }
  }

  handleSelectedTeamUpdateSuccess(data) {
    this.teamsToUpdate = this.teamsToUpdate.filterNot(
      team => team.id === data.id
    );
    if (this.teamsToUpdate.size) {
      this.customerTeam = this.teamsToUpdate.first();
      if (
        this.customerTeam.status !==
        this.selectedTeams.find(e => e.id === this.customerTeam.id).status
      ) {
        this.updateTeam();
      }
    } else {
      this.teamsToUpdate = List();
      this.selectedTeams = List();
    }
  }

  handleCreateOrUpdateServer(team = null) {
    if (team) {
      this.customerTeam = team;
    }
    if (this.teamIsValid()) {
      this.isSavingTeam = true;
      this.savedState = 'saving';
      if (this.customerTeam.id) {
        this.updateTeam();
      } else {
        this.createTeam();
      }
    }
  }

  async createTeam() {
    const payload = await this.payload();

    return TeamSource.postTeam({
      url: teamUrl(),
      params: payload,
      success: TeamActions.createSuccess,
      error: TeamActions.error,
    });
  }

  async updateTeam(hasStatusChanged) {
    if (this.customerTeam.status === 'deleted') {
      this.isDeleting = true;
    }
    const teamId = this.customerTeam.id;
    const keysToCompare = [
      'min_age',
      'max_age',
      'gender_restriction',
      'roster_size',
    ];

    const dateOfBirthKeysToCompare = ['operator', 'start_date', 'end_date'];

    const hasTeamDetailChanged =
      keysToCompare.some(
        key =>
          this.customerTeam.getIn(['team_detail', key]) !==
          this.serverTeam.getIn(['team_detail', key])
      ) ||
      dateOfBirthKeysToCompare.some(
        key =>
          this.customerTeam.getIn(['team_detail', 'date_of_birth', key]) !==
          this.serverTeam.getIn(['team_detail', 'date_of_birth', key])
      );

    const payload = await this.payload(hasTeamDetailChanged, hasStatusChanged);

    return TeamSource.updateTeam({
      id: teamId,
      params: payload,
      success: TeamActions.updateSuccess,
      error: TeamActions.error,
    });
  }

  handleCheckConflict() {
    this.validateAvailableStaff();
  }

  async payload(hasTeamDetailChanged, hasStatusChanged) {
    const payload = await new TeamTranslator(this.customerTeam).toServer();
    const image = this.customerTeam.getIn(['image', 'url']);

    if (!image) {
      payload.image = null;
    }
    if (hasTeamDetailChanged === false || hasStatusChanged) {
      delete payload.team_detail;
    }
    return JSON.stringify(
      merge(
        {
          fields: ['image', 'team_detail', 'team_type'],
        },
        { attributes: payload }
      )
    );
  }

  handleFetch(args) {
    const [id, fields = []] = Array.isArray(args) ? args : [args];
    this.isLoadingTeam = true;

    return TeamSource.fetch({
      id,
      params: { fields: ['image', ...fields] },
      success: TeamActions.fetchSuccess,
      error: TeamActions.error,
    });
  }

  updateTeamStoreFromServer(data) {
    this.markDirty(['TeamStore', false]);
    this.isLoadingTeam = false;
    this.isSavingTeam = false;
    this.serverTeam = new TeamTranslator(data).toClient();
    this.customerTeam = this.serverTeam;
    if (this.selectedTeams.size) {
      this.handleSelectedTeamUpdateSuccess(data);
    }
  }

  handleTeamUpdateSuccess(data) {
    const message = new TranslatableMessage({
      id: 'team_mgmt.shared.update_success',
    });
    MessageWindowActions.addMessage.defer(message);
    this.updateTeamStoreFromServer(data);
  }

  handleTeamCreateSuccess(data) {
    const message = new TranslatableMessage({
      id: 'team_mgmt.shared.create_success',
    });
    MessageWindowActions.addMessage.defer(message);
    this.updateTeamStoreFromServer(data);
  }

  handleError(...args) {
    this.markDirty(['TeamStore', true]);
    this.isLoadingTeam = false;
    this.isSavingTeam = false;
    const error = args[0];

    if (error.status === 422) {
      ApiErrorDialogActions.error.defer(error);
    } else {
      this.notifyError('Error while processing team', args);
    }
  }

  fillPaymentMethods() {
    const { event_checkout_methods: customerMethods } = currentCustomer();

    let availableMethods = Map(customerMethods);

    if (!enabledCustomerFeatures(['ach'])) {
      availableMethods = availableMethods.delete('bank');
    }

    this.customerTeam = this.customerTeam.set(
      'payment_methods',
      availableMethods
    );
  }

  // eslint-disable-next-line class-methods-use-this
  redirectAfterSaveForNewTeams(data) {
    const customerTeam = new TeamTranslator(data).toClient();
    if (/\/teams\/new/.test(window.location.href)) {
      const teamEditPath = customerTeam.editUrl();
      window.location.href = `${teamEditPath}`;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleCloneTeam(id) {
    TeamSource.cloneTeam({
      id,
      success: TeamActions.cloneSuccess,
      error: TeamActions.error,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleCloneSuccess(data) {
    const message = new TranslatableMessage({
      id: 'team_mgmt.shared.clone_success',
    });
    MessageWindowActions.addMessage.defer(message);

    const clonedTeam = new TeamTranslator(data).toClient();
    window.location.href = clonedTeam.admin_path;
  }

  handleResetAgeFields() {
    this.customerTeam = this.customerTeam.updateIn(
      ['team_detail'],
      participant => participant.merge({ max_age: null, min_age: null })
    );
  }

  handleResetDobFields() {
    this.customerTeam = this.customerTeam.setIn(
      ['team_detail', 'date_of_birth'],
      new DateOfBirth()
    );
  }
}

export default alt.createStore(TeamStore, 'TeamStore');
