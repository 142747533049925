import { Record } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

class CardReader extends Record({
  id: null,
  customerId: null,
  nickname: null,
  deviceType: null,
  serialNumber: null,
  enabled: null,
  status: null,
  lastSeenAt: null,
  lastPingAt: null,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({
      ...parsedObject,
    });
  }

  get activationStatus() {
    // Status can be three things:
    // default - this is stored in local storage
    // enabled / disabled as denoted by the boolean
    if (
      this.enabled &&
      window.localStorage.getItem('cardReaderId') === this.id
    ) {
      return 'default';
    }
    if (this.enabled) {
      return 'enabled';
    }
    return 'disabled';
  }

  get enabledString() {
    return this.enabled ? 'enabled' : 'disabled';
  }

  // Not sure if appropriate to put this translation in the record, but it keeps it in one place.
  get statusIconName() {
    switch (this.status) {
      case 'available':
        return 'deviceConnected';
      case 'offline':
        return 'deviceDisconnected';
      case 'busy':
        return 'deviceBusy';
      case 'unknown':
        return 'deviceUnknown';
      default:
        return 'devicePending';
    }
  }
}

export default CardReader;
