import { List, Set } from 'immutable';
import AvailableStaffActions from 'event_mgmt/editing/actions/AvailableStaffActions.jsx';
import AvailableStaffStore from 'event_mgmt/editing/stores/AvailableStaffStore.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStaffDrawerActions from 'event_mgmt/editing/actions/EventStaffDrawerActions.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { byFields } from 'shared/utils/ImmutableUtils.js';

class EventStaffDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.filterString = '';

    this.allStaff = List();
    this.selectableStaff = List();
    this.selectedStaff = List();
    this.availableStaff = List();
    this.unavailableStaff = List();

    this.availableStaffIds = Set();
    this.selectedStaffIds = Set();

    this.bindListeners({
      setEventScheduleInfo: [
        EventActions.FETCH_SUCCESS,
        EventActions.UPDATE_SUCCESS,
        EventActions.CREATE_SUCCESS,
        EventActions.UPDATE_EVENT_STORE,
        EventActions.SCHEDULE_TYPE_SELECTED,
      ],

      setSelectedStaff: [EventActions.STAFF_ADDED, EventActions.STAFF_REMOVED],

      setStaff: StaffActions.LIST_SUCCESS,
      updateFilterString: EventStaffDrawerActions.FILTER_STRING_UPDATED,
      setAvailableStaffIds: AvailableStaffActions.LIST_SUCCESS,
    });
  }

  setEventScheduleInfo() {
    this.waitFor(EventStore);

    const event = EventStore.getState().customerEvent;
    const availabilitySchedule = event.getIn([
      'schedules',
      0,
      'availability_schedule',
    ]);

    this.isOpenBooking = event.isOpenBooking();
    this.isScheduled =
      this.isOpenBooking ||
      (availabilitySchedule && !availabilitySchedule.sessions().isEmpty());

    this.setSelectedStaff();
  }

  updateFilterString(filterString) {
    this.filterString = filterString;

    this.filterStaff();
  }

  filterStaff() {
    this.selectableStaff = StaffStore.getState().allStaff.filter(
      s =>
        !this.selectedStaffIds.has(s.id) &&
        byFields(this.filterString, ['first_name', 'last_name'])(s)
    );

    this.setStaffAvailability();
  }

  setSelectedStaff() {
    this.waitFor(EventStore);

    this.selectedStaffIds = Set(
      EventStore.getState().customerEvent.getIn([
        'schedules',
        0,
        'customer_user_ids',
      ])
    );

    this.selectedStaff = this.allStaff.filter(s =>
      this.selectedStaffIds.has(s.id)
    );

    this.filterStaff();
    this.setStaffAvailability();
  }

  setAvailableStaffIds() {
    this.waitFor(AvailableStaffStore);
    this.availableStaffIds = AvailableStaffStore.getState().staffIds;

    this.filterStaff();
    this.setStaffAvailability();
  }

  setStaff() {
    this.waitFor(StaffStore);
    this.allStaff = StaffStore.getState().allStaff;

    this.setSelectedStaff();
    this.filterStaff();
    this.setStaffAvailability();
  }

  setStaffAvailability() {
    const partitionedStaff = this.selectableStaff.groupBy(
      s => this.isOpenBooking || this.availableStaffIds.has(s.id)
    );

    this.availableStaff = partitionedStaff.get(true, List());
    this.unavailableStaff = partitionedStaff.get(false, List());
  }
}

export default alt.createStore(EventStaffDrawerStore, 'EventStaffDrawerStore');
