import { Map } from 'immutable';
import BrowseEventsFilterActions from 'client_portal/actions/BrowseEventsFilterActions.jsx';
import FilterStoreImpl from 'event_mgmt/index/stores/_FilterStoreImplementation.jsx';

class BrowseEventsFilterStore extends FilterStoreImpl {
  constructor() {
    super({
      toggleFilterDrawer: BrowseEventsFilterActions.TOGGLE_FILTER_DRAWER,
      updateFilterDrawerOpen:
        BrowseEventsFilterActions.UPDATE_FILTER_DRAWER_OPEN,

      updateAttendeeFilters: BrowseEventsFilterActions.UPDATE_ATTENDEE_FILTERS,
      updateLocationFilters: BrowseEventsFilterActions.UPDATE_LOCATION_FILTERS,
      updateStatusFilters: BrowseEventsFilterActions.UPDATE_STATUS_FILTERS,
      updateTitleFilter: BrowseEventsFilterActions.UPDATE_TITLE_FILTER,
      updateTypeFilters: BrowseEventsFilterActions.UPDATE_TYPE_FILTERS,

      updateMaxAge: BrowseEventsFilterActions.MAX_AGE_UPDATED,
      updateMinAge: BrowseEventsFilterActions.MIN_AGE_UPDATED,
      toggleAgeFilter: BrowseEventsFilterActions.AGE_TOGGLED,

      resetFilters: BrowseEventsFilterActions.FILTERS_CLEARED,
    });

    this.statusFilters = Map({
      all: false,
      active: true,
      draft: false,
      completed: false,
      cancelled: false,
      deleted: false,
    });

    this.eventStatusFilters = [
      { name: 'All', filterKey: 'all' },
      { name: 'Active', filterKey: 'active' },
      { name: 'Draft', filterKey: 'draft' },
      { name: 'Completed', filterKey: 'completed' },
      { name: 'Cancelled', filterKey: 'cancelled' },
      { name: 'Deleted', filterKey: 'deleted' },
    ];

    this.honorAll = false;
  }
}

export default alt.createStore(
  BrowseEventsFilterStore,
  'BrowseEventsFilterStore'
);
