class ContactsFiltersDrawerActions {
  constructor() {
    this.generateActions(
      'openDrawer',
      'closeDrawer',
      'applyFilters',
      'clearFilters',
      'saveFilter',
      'saveFilterSuccess',
      'saveFilterError',
      'updateFilter',
      'createGroup',
      'filterValueChange',
      'changeStep',
      'listFilters',
      'listFiltersSuccess',
      'listFiltersError',
      'searchProducts',
      'listProducts',
      'listProductsSuccess',
      'listProductsError',
      'selectSavedFilter',
      'deleteFilter',
      'deleteFilterSuccess',
      'deleteFilterError',
      'applyFilter',
      'searchProductTypes',
      'listProductTypes',
      'listProductTypesSuccess',
      'listProductTypesError'
    );
  }
}

export default alt.createActions(ContactsFiltersDrawerActions);
