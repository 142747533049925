import CommissionRateDeletionActions from 'contacts/shared/actions/commission_rates/CommissionRateDeletionActions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { CommissionRateSource } from 'sources';

class CommissionRateDeletionStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      handleRemoveCommissionRateRequest:
        CommissionRateDeletionActions.REMOVE_COMMISSION_RATE,
      handleRemoveCommissionRate:
        CommissionRateDeletionActions.REMOVE_COMMISSION_RATE_CONFIRMED,
      handleAbortRemoving:
        CommissionRateDeletionActions.REMOVE_COMMISSION_RATE_ABORTED,
      handleRemoveCommissionRateSuccess:
        CommissionRateDeletionActions.REMOVE_COMMISSION_RATE_SUCCESS,
      handleRemoveCommissionRateError:
        CommissionRateDeletionActions.REMOVE_COMMISSION_RATE_ERROR,
    });
  }

  reset() {
    this.commissionRate = null;
  }

  handleRemoveCommissionRateRequest(commissionRate) {
    this.commissionRate = commissionRate;
  }

  handleRemoveCommissionRate() {
    if (!this.commissionRate.id) {
      return;
    }
    CommissionRateSource.deleteCommission({
      id: this.commissionRate.id,
      success: {
        action: CommissionRateDeletionActions.removeCommissionRateSuccess,
        args: [this.commissionRate.id],
      },
      error: CommissionRateDeletionActions.removeCommissionRateError,
    });
  }

  handleAbortRemoving() {
    this.reset();
  }

  handleRemoveCommissionRateSuccess() {
    const message = new TranslatableMessage({
      id: '.deleted',
      filename: __filenamespace,
    });
    this.reset();
    return MessageWindowActions.addMessage.defer(message);
  }

  handleRemoveCommissionRateError(...args) {
    this.reset();
    this.notifyError('error removing commission rate', args);
  }
}

export default alt.createStore(
  CommissionRateDeletionStore,
  'CommissionRateDeletionStore'
);
