import React from 'react';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { Dropdown, TextField } from '@upperhand/playmaker';

import {
  convertToDays,
  convertFromDays,
} from 'shared/utils/DayConversionUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils';

const styles = {
  root: {
    display: 'flex',
  },
  textField: {
    width: 70,
    marginRight: 8,
  },
  tooltip: {
    maxWidth: 240,
  },
};

function DaySelector({ classes, disabled, value, onDaysChange, intl }) {
  const [type, setType] = React.useState('days');
  const converted = convertFromDays(value, type);

  return (
    <div style={styles.root}>
      <TextField
        classes={{
          root: classes.textField,
        }}
        name="amount"
        type="number"
        disabled={disabled}
        value={converted}
        onChange={e => onDaysChange(convertToDays(e.target.value, type))}
      />
      <div data-tip data-for={`rounding-info-${type}`}>
        <Dropdown
          disabled={disabled}
          value={type}
          onChange={e => {
            onDaysChange(convertToDays(converted, e.target.value));
            setType(e.target.value);
          }}
          items={[
            {
              value: 'days',
              label: t('.days', intl, __filenamespace),
            },
            {
              value: 'weeks',
              label: t('.weeks', intl, __filenamespace),
            },
            {
              value: 'months',
              label: t('.months', intl, __filenamespace),
            },
          ]}
        />
      </div>
      {type === 'months' && !disabled && (
        <ReactTooltip
          id={`rounding-info-${type}`}
          effect="solid"
          style={styles.tooltip}
          className="uh-tooltip"
        >
          {t('.rounding', intl, __filenamespace)}
        </ReactTooltip>
      )}
    </div>
  );
}

export default compose(withStyles(styles), injectIntl)(DaySelector);
