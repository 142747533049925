import { List, Range, Set, fromJS } from 'immutable';
import { escapeStringRegexp } from 'shared/utils/StringUtils.jsx';

export const sum =
  (attr, parser = x => x) =>
  (s, obj) =>
    s + parser(obj[attr] || (obj.asImmutable ? obj.get(attr) : 0)) || 0;

export const sumInt = (attr, radix = 10) => sum(attr, x => parseInt(x, radix));
export const sumFloat = (attr, radix = 10) =>
  sum(attr, x => parseFloat(x, radix));

export const keyIn = keys => {
  const keySet = Set(keys);
  return (v, k) => keySet.has(k);
};
// eslint-disable-next-line
export const byFields = (queryString = '', fields) => {
  const queryTerms = queryString
    .split(' ')
    .map(term => RegExp(escapeStringRegexp(term), 'i'));

  return element =>
    queryTerms.every(queryTerm =>
      fields.some(fieldPath => {
        const value = element.getIn(fromJS([fieldPath]).flatten());
        return value !== null && value !== undefined && queryTerm.test(value);
      })
    );
};

export const setToggle = (set, v) => (set.has(v) ? set.delete(v) : set.add(v));

export const multiplyList = (list, n) =>
  Range(0, n).reduce(agg => agg.concat(list), List());
