import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';
import OWNER_TYPE from 'calendar/types/OwnerType.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';

import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Sticky: {
    top: 0,
    zIndex: 1,
    position: 'sticky',
  },
  StickyInnerDiv: {
    position: 'absolute',
    alignItems: 'center',
    width: '100%',
    height: '42px',
    padding: '16px',
    backgroundColor: 'white',
    borderBottom: '2px solid #e3e3e3',
    borderRight: '1px solid #e3e3e3',
    overflow: 'hidden',
  },
  RemoveButton: {
    margin: '0 -16px 0 auto',
    opacity: '0',
    transition: 'opacity 200ms ease-in-out',
    position: 'absolute',
    right: 0,
  },
  ResourceIcon: {
    margin: '0 8px 0 0',
  },
  ResourceText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  AvatarFont: {
    fontSize: '14px',
    lineHeight: '14px',
  },
  PopoverStyle: {
    width: '200px',
    height: '42px',
    overflow: 'hidden',
    alignItems: 'center',
    borderStyle: 'solid',
    position: 'relative',
    top: 0,
    zIndex: 1,
  },
  MenuItem: isMobile => ({
    fontSize: isMobile ? '15px' : '13px',
  }),
};

const removeButtonStyle = (hovering, isMobile) => {
  const normalStyle = {
    opacity: hovering ? '1' : '0',
    visibility: hovering ? 'visible' : 'hidden',
  };

  const mobileStyle = {
    opacity: '1',
    visibility: 'visible',
  };

  if (isMobile) {
    return mobileStyle;
  }
  return normalStyle;
};

class HeaderCell extends React.Component {
  state = {
    hovering: false,
    open: false,
    anchorEl: null,
  };

  delayingHovering = false;

  handleOnCellClick = e => {
    e.preventDefault();
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleOnMouseMove = e => {
    const { smallCellMode } = this.props;
    const { anchorEl, hovering } = this.state;
    const different = anchorEl !== e.currentTarget;

    this.setState({
      anchorEl: e.currentTarget,
    });

    if (!hovering || different) {
      this.delayingHovering = true;
      setTimeout(() => {
        if (this.delayingHovering) {
          this.setState({
            hovering: true,
            open: smallCellMode,
          });
          this.delayingHovering = false;
        }
      }, 100);
    }
  };

  handleOnMouseLeavePopover = () => {
    this.delayingHovering = false;
    this.setState({ open: false });
  };

  handleOnMouseLeave = () => {
    this.delayingHovering = false;
    this.setState({ hovering: false });
  };

  handleOnRemoveClick = () => {
    const { onUpdateCellMode, calendar } = this.props;
    CalendarViewActions.removeCalendar(calendar);
    this.setState({ open: false, hovering: false, anchorEl: null });
    onUpdateCellMode();
  };

  render() {
    const { calendar, owner, smallCellMode } = this.props;
    const { hovering, open, anchorEl } = this.state;
    const isMobile = smallScreen();
    const removeButtonStyles = removeButtonStyle(hovering, isMobile);

    return (
      <div
        role="presentation"
        onClick={smallCellMode && !open ? this.handleOnCellClick : null}
        onMouseMove={this.handleOnMouseMove}
        onMouseOver={this.handleOnMouseMove}
        onMouseLeave={this.handleOnMouseLeave}
        onFocus={() => null}
        style={styles.Sticky}
      >
        <FlexBoxJustify style={styles.StickyInnerDiv}>
          {calendar.ownerType === OWNER_TYPE.STAFF &&
            (smallCellMode ? (
              <span style={styles.ResourceText}>{owner && owner.name()}</span>
            ) : (
              <AvatarWithName
                user={owner}
                style={merge(styles.AvatarFont, {
                  width: 'calc(100% - 28px)',
                })}
                avatarOptions={{ size: 24 }}
              />
            ))}
          {calendar.ownerType === OWNER_TYPE.RESOURCE &&
            (smallCellMode ? (
              <span style={styles.ResourceText}>{owner && owner.name}</span>
            ) : (
              <FlexBoxCenter>
                <ResourceIcon
                  color={uhColors.iconGrey}
                  style={styles.ResourceIcon}
                />
                {owner && owner.name}
              </FlexBoxCenter>
            ))}
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            onClose={this.handleRequestClose}
            open={open && smallCellMode}
          >
            <div
              onMouseLeave={this.handleOnMouseLeavePopover}
              style={styles.PopoverStyle}
            >
              <FlexBoxCenter>
                <div
                  style={{
                    overflow: 'hidden',
                    width: 140,
                    paddingTop:
                      calendar.ownerType === OWNER_TYPE.RESOURCE ? 9 : 5,
                    paddingBottom:
                      calendar.ownerType === OWNER_TYPE.RESOURCE ? 9 : 5,
                    paddingLeft: 9,
                  }}
                >
                  {calendar.ownerType === OWNER_TYPE.STAFF && (
                    <AvatarWithName style={styles.AvatarFont} user={owner} />
                  )}
                  {calendar.ownerType === OWNER_TYPE.RESOURCE && (
                    <FlexBoxCenter>
                      <ResourceIcon
                        color={uhColors.iconGrey}
                        style={styles.ResourceIcon}
                      />
                      <div
                        style={{
                          overflow: 'hidden',
                          width: 100,
                          maxHeight: 30,
                        }}
                      >
                        {owner && owner.name}
                      </div>
                    </FlexBoxCenter>
                  )}
                </div>
                <IconButton
                  onClick={this.handleOnRemoveClick}
                  style={merge(styles.RemoveButton, {
                    opacity: 1,
                    marginLeft: 0,
                    // our goal is 9, this is 9 - 5 that comes built into the icon
                    marginRight: 4,
                    padding: '0 0',
                    width: 24,
                    color: uhColors.iconLightGrey,
                  })}
                >
                  <CloseIcon />
                </IconButton>
              </FlexBoxCenter>
            </div>
          </Popover>
          {!smallCellMode && (
            <IconButton
              onClick={this.handleOnRemoveClick}
              style={merge(styles.RemoveButton, removeButtonStyles, {
                marginRight: -4,
                color: uhColors.iconLightGrey,
              })}
            >
              <CloseIcon />
            </IconButton>
          )}
        </FlexBoxJustify>
      </div>
    );
  }
}

export default HeaderCell;
