import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Box } from '@mui/material';

import { Grid, Button } from '@upperhand/playmaker';

import FilterDropdown from 'components/FilterDropdown.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';

import BrowseTeamsActions from '../Actions';

const styles = {
  root: {
    padding: '16px 8px',
    width: '100%',
  },
  filterHeader: {
    height: 42,
    borderBottom: `1px solid ${uhColors.lighterGrey}`,
    display: 'flex',
    alignItems: 'center',
  },
  searchBarDivStyles: {
    display: 'flex',
    backgroundColor: '#454952',
    alignItems: 'center',
    marginBottom: '20px',
    height: '100px',
  },

  searchbarIconStyles: {
    display: 'none',
  },
  searchbarStyles: {
    padding: '0 20px',
    fontSize: 16,
    backgroundColor: uhColors.tunaBlack,
  },
  searchbarInputStyles: {
    fontWeight: 'bold',
    color: '#FFF',
  },
};

const BrowseEventsRoomy = React.memo(
  ({ children, teamItems, teamTypeFilter, staffItems, staffFilter }) => {
    const [showSearch, setShowSearch] = React.useState(false);
    return (
      <Box sx={styles.root}>
        {showSearch && (
          <Box sx={styles.searchBarDivStyles}>
            <Button
              icon="close"
              onClick={() => {
                BrowseTeamsActions.searchFilterUpdated('');
                setShowSearch(false);
              }}
              className="browse-teams__disable-btn"
            />
            <SearchBar
              onTypingEnd={value =>
                BrowseTeamsActions.searchFilterUpdated(value)
              }
              iconStyle={styles.searchbarIconStyles}
              style={styles.searchbarStyles}
              inputStyle={styles.searchbarInputStyles}
            />
          </Box>
        )}
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid container item spacing={1} xs={12} alignContent="flex-start">
            <Grid container item spacing={1} xs={12}>
              <Grid container item spacing={1} xs={12}>
                {showSearch ? (
                  <Grid item>
                    <Button
                      onClick={() => setShowSearch(true)}
                      type="tertiary"
                      rounded
                      icon="search"
                    />
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      onClick={() => setShowSearch(true)}
                      rounded
                      icon="search"
                      className="browse-teams__disable-btn"
                    />
                  </Grid>
                )}

                <Grid item xs={3} className="browse-teams__filter-item">
                  <FilterDropdown
                    items={teamItems}
                    filterValue={teamTypeFilter}
                    name="team type"
                    onChange={BrowseTeamsActions.teamTypeFilterUpdated}
                  />
                </Grid>
                <Grid item xs={3} className="browse-teams__filter-item">
                  <FilterDropdown
                    items={staffItems}
                    filterValue={staffFilter}
                    name="staff"
                    onChange={BrowseTeamsActions.staffFilterUpdated}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default injectIntl(BrowseEventsRoomy);
