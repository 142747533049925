import { List } from 'immutable';
import EventType from 'event_mgmt/shared/records/EventType.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeEditingActions from 'shared/actions/EventTypeEditingActions.jsx';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class EventTypeListingStore extends UpperHandStore {
  constructor() {
    super();

    this.eventTypes = List();
    this.findById = this.findById.bind(this);
    this.isLoading = false;

    this.bindListeners({
      handleList: [EventTypeListingActions.LIST, PayrollReportActions.MOUNTED],

      handleListSuccess: EventTypeListingActions.listSuccess,
      handleListError: EventTypeListingActions.listError,

      handleCreateOrUpdateSuccess:
        EventTypeEditingActions.createOrUpdateSuccess,
    });
  }

  handleList(options) {
    const { page = 1 } = options || {};
    this.isLoading = true;

    /*
     * TODO: [JROGERS:2016-08-30] change URL below to `${url()}?fields[]=event_count`
     * when we add the counts back into the UI.
     */
    return uhApiClient.get({
      url: this.url(),
      data: { page, per_page: 50 },
      success: EventTypeListingActions.listSuccess,
      error: EventTypeListingActions.listError,
    });
  }

  handleListSuccess({
    event_types: eventTypes,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    const partitionedEventTypes = this.sorted(
      List(eventTypes.map(object => new EventType(object)))
    );
    this.eventTypes =
      page === 1
        ? partitionedEventTypes
        : this.eventTypes.concat(partitionedEventTypes);

    if (eventTypes.length === perPage && totalCount > this.eventTypes.size) {
      this.handleList({ page: page + 1 });
    } else {
      this.isLoading = false;
    }
  }

  handleListError(...args) {
    this.isLoading = false;

    this.notifyError('error while listing event types', args);
  }

  handleCreateOrUpdateSuccess([attributes]) {
    this.insertOrUpdateEventType(attributes);
  }

  /*
   * PRIVATE HELPERS
   */

  findById(id) {
    return this.eventTypes.find(eventType => eventType.id === id);
  }

  insertOrUpdateEventType(attributes) {
    const eventType = this.convertToEventType(attributes);
    const index = this.eventTypes.findIndex(type => type.id === attributes.id);

    if (index !== -1) {
      this.eventTypes = this.sorted(this.eventTypes.setIn([index], eventType));
    } else {
      this.eventTypes = this.sorted(this.eventTypes.push(eventType));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  convertToEventType(attributes) {
    const convertedAttributes = attributes;

    if (attributes.created_at) {
      convertedAttributes.created_at = new Date(attributes.created_at);
    }
    if (attributes.updated_at) {
      convertedAttributes.updated_at = new Date(attributes.updated_at);
    }
    return new EventType(convertedAttributes);
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(eventTypes) {
    return eventTypes.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return a.name > b.name ? 1 : 0;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  url() {
    return 'event_types';
  }
}

export default alt.createStore(EventTypeListingStore, 'EventTypeListingStore');
