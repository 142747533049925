import * as React from 'react';
import { Popover, Button } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import DateNavigator from 'calendar/components/_DateNavigator.jsx';
import Search from 'calendar/components/_Search.jsx';
import ViewSelect from 'calendar/components/_ViewSelect.jsx';
import FilterButton from 'calendar/components/_FilterButton.jsx';
import CalendarLegend from 'calendar/components//CalendarLegend.jsx';

import { smallScreen } from 'shared/utils/DOMUtils';

const styles = {
  Toolbar: {
    zIndex: '10',
    width: '100%',
    height: '43px',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    borderBottom: '1px solid #e3e3e3',
    backgroundColor: '#fff',
    fontSize: '15px',
  },
  ToolbarMobile: {
    fontSize: '13px',
  },
};

function MobileTools({ view }) {
  const [anchor, setAnchor] = React.useState(null);
  const openMenu = e => setAnchor(e.currentTarget);
  const closeMenu = () => setAnchor(null);

  return (
    <Box>
      <Button
        disableTouchRipple
        fullWidth
        type="tertiary"
        icon="moreVert"
        onClick={openMenu}
        classes={{ root: 'calendar-toolbar-mobile-button' }}
      />
      <Popover anchor={anchor} onClose={closeMenu}>
        <Search view={view} />
      </Popover>
    </Box>
  );
}

function Toolbar({ calendarStore, style }) {
  const { date, view, calendarFilters } = calendarStore;
  const isMobile = smallScreen(896);

  return (
    <div
      id="calendar-toolbar"
      style={{
        ...style,
        ...styles.Toolbar,
        ...(isMobile ? styles.ToolbarMobile : {}),
      }}
    >
      <ViewSelect view={view} />
      <DateNavigator date={date} view={view} />
      {!isMobile && <Search view={view} />}
      <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <CalendarLegend />
        <FilterButton
          large={!isMobile}
          active={calendarFilters.get('isActive')}
        />
      </Box>
      {isMobile && <MobileTools view={view} />}
    </div>
  );
}

export default Toolbar;
