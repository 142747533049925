import * as React from 'react';
import PropTypes from 'prop-types';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';

function EventTypeLabel({ label, description, example }) {
  return (
    <div>
      <div
        style={{
          ...boldText,
          color: uhColors.darkGrey,
          paddingBottom: '0.25em',
        }}
      >
        {label}
      </div>
      <div style={{ color: uhColors.grey, lineHeight: '1.3em' }}>
        {description}
      </div>
      <div style={{ lineHeight: '1.3em' }}>
        <em>{example}</em>
      </div>
    </div>
  );
}

EventTypeLabel.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  example: PropTypes.string.isRequired,
};

export default EventTypeLabel;
