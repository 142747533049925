import { Map } from 'immutable';

import { AccountCreditActions } from 'sources';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';

class AccountCreditsDataStore extends UpperHandStore {
  constructor() {
    super();

    this.accountCredits = Map();
    this.bindListeners({
      createSuccess: AccountCreditActions.createSuccess,
      createError: AccountCreditActions.createError,
      listSuccess: AccountCreditActions.listSuccess,
      listError: AccountCreditActions.listError,
      fetchSuccess: AccountCreditActions.fetchSuccess,
      fetchError: AccountCreditActions.fetchError,
    });
  }

  listSuccess({ account_credits: accountCredits }) {
    const credits = accountCredits.groupBy(c => c.id).map(c => c.first());

    this.accountCredits = this.accountCredits.merge(credits);
  }

  listError(...args) {
    this.notifyError('Error listing classes', args);
  }

  fetchSuccess(credit) {
    const { id } = credit;

    this.accountCredits = this.accountCredits.set(id, credit);
  }

  fetchError(...args) {
    this.notifyError('Error fetching class', args);
  }

  createSuccess(credit) {
    const { id } = credit;

    this.accountCredits = this.accountCredits.set(id, credit);
  }

  createError(...args) {
    this.notifyError('Error creating class', args);
  }
}

export default alt.createStore(
  AccountCreditsDataStore,
  'AccountCreditsDataStore'
);
