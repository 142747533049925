class InventoryReportAdjustmentModalActions {
  constructor() {
    this.generateActions(
      'closeModal',
      'openModal',
      'selectReason',
      'setAmount',
      'saveAdjustment',
      'saveAdjustmentSuccess',
      'saveAdjustmentError'
    );
  }
}

export default alt.createActions(InventoryReportAdjustmentModalActions);
