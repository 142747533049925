class CouponActions {
  constructor() {
    this.generateActions(
      'addDiscountableItem',
      'back',
      'cancelCreate',
      'cancelUpdate',
      'cancelRemove',
      'confirmRemove',
      'createSuccess',
      'createError',
      'creditPassListSuccess',
      'discountableTypeChanged',
      'dismissAlert',
      'edit',
      'eventListSuccess',
      'fetch',
      'fetchError',
      'fetchSuccess',
      'leave',
      'membershipListSuccess',
      'mounted',
      'openCreateModal',
      'remove',
      'removeSuccess',
      'removeError',
      'removeDiscountableItem',
      'save',
      'search',
      'selectedCreditPassListSuccess',
      'selectedEventListSuccess',
      'selectedMembershipListSuccess',
      'selectedVariantListSuccess',
      'tabChanged',
      'toggleConfirmation',
      'updateField',
      'updateSuccess',
      'updateError',
      'variantListSuccess',
      'teamListSuccess',
      'selectedTeamListSuccess'
    );
  }
}

export default alt.createActions(CouponActions);
