import * as React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';

import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import SearchBar from 'shared/components/SearchBar.jsx';
import { isNative } from 'shared/utils/UserAgentUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  Root: {
    position: 'relative',
  },
  ClickAwayLayer: {
    position: 'fixed',
    zIndex: 10,
    // Negative position because this component already the child
    // of a fixed element. --joshuamanns
    top: '-9999px',
    right: '-9999px',
    bottom: '-9999px',
    left: '-9999px',
  },
  TextField: {
    width: '100%',
    borderBottom: '1px solid #e3e3e3',
    paddingLeft: 15,
    fontSize: 15,
  },
  Menu: {
    zIndex: 100,
    top: 45,
    position: 'absolute',
    width: 'calc(100% - 32px)',
    overflowY: 'auto',
    margin: '0 16px',
  },
  MenuItems: anchorEl => ({
    maxHeight: anchorEl
      ? `${window.innerHeight - anchorEl.getBoundingClientRect().bottom - 64}px`
      : 'none',
    overflowY: 'auto',
  }),
  MenuItem: {
    width: '100%',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class SearchablePopoverMenu extends React.Component {
  constructor(props) {
    super(props);

    this.rootEl = null;

    this.state = {
      open: false,
      searchTerm: '',
      anchorEl: null,
    };
  }

  handleSearchChange = e => {
    const { onSearchTermChange } = this.props;
    const searchTerm = e.target.value;

    this.setState({ searchTerm });
    onSearchTermChange(searchTerm);
  };

  handleSearchFocus = () => {
    if (isNative()) {
      this.setState({ anchorEl: this.rootEl });
      setTimeout(() => {
        this.setState({ open: true });
      }, 750);
    } else {
      this.setState({
        anchorEl: this.rootEl,
        open: true,
      });
    }
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  menuStuff = () => {
    const { menuItems } = this.props;

    const menuItemProps = props => ({
      ...props,
      onClick: e => {
        e.preventDefault();

        if (props.onClick) {
          props.onClick(e);
        }

        this.handleRequestClose();
      },
    });

    // Addendeess.jsx supplikes SearchablePopoverMenu with an Immutable map
    // and EventSelector.jsx supplies an Array so this checks for that
    if (menuItems instanceof OrderedMap) {
      return menuItems.map
        ? menuItems.valueSeq().map((item, key) => (
            // TODO: Use uuid instead of array index
            // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
            <MenuItem key={key} {...menuItemProps(item.props)} />
          ))
        : menuItems;
    }
    return menuItems.map
      ? menuItems.map((item, key) => (
          // TODO: Use uuid instead of array index
          // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
          <MenuItem key={key} {...menuItemProps(item.props)} />
        ))
      : menuItems;
  };

  render() {
    const { footer, hintText, header, style, searchBarStyle, value } =
      this.props;
    const { anchorEl, open, searchTerm } = this.state;

    return (
      <div
        ref={ref => {
          this.rootEl = ref;
        }}
        style={merge(styles.Root, style)}
      >
        {open && (
          <div
            role="presentation"
            style={styles.ClickAwayLayer}
            onClick={this.handleRequestClose}
          />
        )}
        <SearchBar
          placeholder={hintText}
          value={!open && value ? value : searchTerm}
          onChange={this.handleSearchChange}
          onClick={this.handleSearchFocus}
          style={merge(styles.TextField, searchBarStyle)}
          inputStyle={{ fontWeight: 'bold' }}
        />
        {open && (
          // We can't use a Material-UI Menu or Popover here because
          // it commands focus from TextField --joshuamanns
          <Paper style={styles.Menu}>
            {header}
            <div style={styles.MenuItems(anchorEl)}>{this.menuStuff()}</div>
            {footer}
          </Paper>
        )}
      </div>
    );
  }
}

SearchablePopoverMenu.propTypes = {
  value: PropTypes.string,
  onSearchTermChange: PropTypes.func,
  hintText: PropTypes.string,
  menuItems: PropTypes.any,
  header: PropTypes.node,
  footer: PropTypes.node,
};

SearchablePopoverMenu.defaultProps = {
  value: '',
  onSearchTermChange: () => {},
  hintText: '',
  menuItems: null,
  header: null,
  footer: null,
};

export default SearchablePopoverMenu;
