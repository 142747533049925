export default alt.generateActions(
  'addToCartClicked',
  'cancelClicked',
  'fetchError',
  'fetchSuccess',
  'imageSelected',
  'listImagesError',
  'listImagesSuccess',
  'listOptionTypesError',
  'listOptionTypesSuccess',
  'listVariantsError',
  'listVariantsSuccess',
  'optionSelected',
  'quantitySelected',
  'swingCardChange',
  'clientChange',
  'selectNextImage',
  'selectPreviousImage',
  'updateCartClicked',
  'viewInCartClicked',
  'validate'
);
