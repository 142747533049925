/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown, TextField } from '@upperhand/playmaker';

import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import { grey } from '@mui/material/colors';

import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import RegistrationFieldStore from 'shared/stores/RegistrationFieldStore.jsx';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
  },
  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    marginTop: 15,
    maxWidth: '100%',
  },
  columnHeader: {
    marginBottom: 15,
  },
  fieldDirection: {
    lineHeight: '1.5em',
    marginBottom: 15,
    color: uhColors.tabGrey,
  },
  fieldHeader: {
    color: uhColors.leftNavGrey,
    marginBottom: 15,
    fontWeight: 'bold',
  },
  spacer: {
    color: '#e5e6e5',
  },
  categorySelection: {
    marginBottom: 8,
  },
  optionSelectionTextFiled: {
    marginTop: 16,
  },
};

const whileStaged = (id, commit) => {
  RegistrationFieldActions.stageRegistrationField({
    metaRegistrationFieldId: id,
  });
  commit();
  RegistrationFieldActions.clearRegistrationField();
  RegistrationFieldActions.validateSelectedFields();
};

const addField = (id, ordinal) =>
  whileStaged(id, () => {
    RegistrationFieldActions.updateStore([
      ['selected', true],
      ['ordinal', ordinal],
    ]);
  });

const removeField = id =>
  whileStaged(id, () =>
    RegistrationFieldActions.updateStore([['selected', false]])
  );

const setOptions = (id, value) =>
  whileStaged(id, () =>
    RegistrationFieldActions.updateStore([['options', value]])
  );

const setRequired = (id, required) =>
  whileStaged(id, () =>
    RegistrationFieldActions.updateStore([['required', required]])
  );

function CategorySelection({ category, unselectedFields, selectedFields }) {
  return (
    <div style={styles.categorySelection}>
      <Dropdown
        classes={{
          select: 'calculated-column-width-20',
        }}
        placeholder={category}
        onChange={e => addField(e.target.value, selectedFields.size)}
        items={unselectedFields
          .filter(f => f.category === category)
          .map(field => ({
            value: field.meta_registration_field_id,
            label: field.name,
          }))
          .toArray()}
      />
    </div>
  );
}

function Fields(props) {
  return (
    <FlexBox style={styles.root}>
      <div style={styles.column}>
        <h2 style={styles.fieldHeader}>
          <FormattedMessage
            id={messageId('.field_categories', __filenamespace)}
          />
        </h2>
        <h2 style={styles.fieldDirection}>
          <FormattedMessage
            id={messageId('.add_fields_directions', __filenamespace)}
          />
        </h2>
        <UnselectedFields {...props} />
      </div>
      <div style={{ width: 40 }} />
      <div style={styles.column}>
        <h2 style={styles.columnHeader}>
          <span style={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id={messageId('.event_form_fields', __filenamespace)}
            />
          </span>
          <FormattedMessage
            id={messageId('.event_form_fields_check', __filenamespace)}
          />
        </h2>
        <h2 style={styles.fieldDirection}>
          <FormattedMessage
            id={messageId('.event_form_directions', __filenamespace)}
          />
        </h2>
        <SelectedFields {...props} />
      </div>
    </FlexBox>
  );
}

function SelectedFields({ intl, selectedFields }) {
  return (
    <div>
      {selectedFields.map(field => {
        const display = field.isListable() ? (
          <OptionSelection field={field} intl={intl} />
        ) : (
          <TextSelection field={field} />
        );
        return <div key={field.meta_registration_field_id}>{display}</div>;
      })}
    </div>
  );
}

function OptionSelection({ field, intl }) {
  return (
    <div>
      <TextSelection field={field} />
      <div style={styles.optionSelectionTextFiled}>
        <TextField
          key={`options-${field.meta_registration_field_id}`}
          onChange={(_, value) =>
            setOptions(field.meta_registration_field_id, value)
          }
          label={t('.field_options', intl, __filenamespace)}
          fullWidth
          value={field.options}
          errorText={field.errors.getErrors('options')}
        />
      </div>
    </div>
  );
}

function TextSelection({ field }) {
  return (
    <FlexBoxJustify style={{ width: '100%', alignItems: 'center' }}>
      <FlexBox style={{ alignItems: 'center' }}>
        <div>
          <Checkbox
            onChange={e =>
              setRequired(field.meta_registration_field_id, e.target.checked)
            }
            checked={field.isRequired()}
          />
        </div>
        <div>{field.name}</div>
      </FlexBox>
      <ClearIcon
        style={{ height: 18, width: 18, color: grey[500] }}
        onClick={() => removeField(field.meta_registration_field_id)}
      />
    </FlexBoxJustify>
  );
}

function UnselectedFields(props) {
  const { allRegistrationFieldCategories } = props;

  return (
    <div>
      {allRegistrationFieldCategories.map(category => (
        <CategorySelection key={category} {...props} category={category} />
      ))}
    </div>
  );
}

function TabRegistrationForm(props) {
  return (
    <AltContainer store={RegistrationFieldStore}>
      <Fields {...props} />
    </AltContainer>
  );
}

export default injectIntl(TabRegistrationForm);
