import * as React from 'react';
import PropTypes from 'prop-types';

import EventTypeDiscountBenefits from 'memberships/components/_DiscountBenefits/_EventTypeDiscountBenefits.jsx';
import CreditPassDiscountBenefits from 'memberships/components/_DiscountBenefits/_CreditPassDiscountBenifits.jsx';
import RetailCategoryDiscountBenefits from 'memberships/components/_DiscountBenefits/_RetailCategoryDiscountBenefits.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

function DiscountBenefits({
  membership,
  eventTypes,
  creditPasses,
  constStyle,
  retailCategories,
  customWrapper,
  itemStyle,
  textStyle,
  containerStyles,
}) {
  return (
    <div style={constStyle || customWrapper}>
      <EventTypeDiscountBenefits
        membership={membership}
        eventTypes={eventTypes}
        itemStyle={itemStyle}
        textStyle={textStyle}
        containerStyles={containerStyles}
      />
      <CreditPassDiscountBenefits
        membership={membership}
        creditPasses={creditPasses}
        itemStyle={itemStyle}
        textStyle={textStyle}
        containerStyles={containerStyles}
      />
      {currentCustomer().retail_enabled && (
        <RetailCategoryDiscountBenefits
          membership={membership}
          retailCategories={retailCategories}
          itemStyle={itemStyle}
          textStyle={textStyle}
          containerStyles={containerStyles}
        />
      )}
    </div>
  );
}

DiscountBenefits.propTypes = {
  membership: PropTypes.object.isRequired,
  eventTypes: PropTypes.object.isRequired,
  retailCategories: PropTypes.object.isRequired,
  // style: PropTypes.object,
};

export default DiscountBenefits;
