import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Contacts({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-18.000000, -258.000000)" fill={color}>
          <g transform="translate(0.000000, 60.000000)">
            <path d="M38.9995,213.0166 L32.3995,213.0166 L32.1905,213.0166 C32.7025,212.1336 32.9995,211.1106 32.9995,210.0166 L37.6515,210.0166 C38.4655,210.5506 38.9995,211.3966 38.9995,212.2116 L38.9995,213.0166 Z M32.9995,219.0166 L20.9995,219.0166 L20.9995,218.2116 C20.9995,217.3966 21.5345,216.5506 22.3485,216.0166 L25.4525,216.0166 L31.6515,216.0166 C32.4655,216.5506 32.9995,217.3966 32.9995,218.2116 L32.9995,219.0166 Z M26.9995,207.0166 C28.6565,207.0166 29.9995,208.3596 29.9995,210.0166 C29.9995,211.6736 28.6565,213.0166 26.9995,213.0166 C25.5175,213.0166 24.2935,211.9386 24.0515,210.5246 C24.0195,210.3596 23.9995,210.1906 23.9995,210.0166 C23.9995,208.3596 25.3435,207.0166 26.9995,207.0166 L26.9995,207.0166 Z M32.9995,201.0166 C34.6535,201.0166 35.9995,202.3626 35.9995,204.0166 C35.9995,205.6706 34.6535,207.0166 32.9995,207.0166 C32.6835,207.0166 32.3845,206.9546 32.0975,206.8626 C31.6075,206.0736 30.9425,205.4086 30.1535,204.9196 C30.0625,204.6326 29.9995,204.3336 29.9995,204.0166 C29.9995,202.3626 31.3465,201.0166 32.9995,201.0166 L32.9995,201.0166 Z M38.3995,207.0166 L38.1905,207.0166 C38.7025,206.1336 38.9995,205.1106 38.9995,204.0166 C38.9995,200.7036 36.3135,198.0166 32.9995,198.0166 C29.6865,198.0166 26.9995,200.7036 26.9995,204.0166 C23.6865,204.0166 20.9995,206.7036 20.9995,210.0166 C20.9995,211.1106 21.2975,212.1336 21.8095,213.0166 L21.6005,213.0166 C19.4625,213.9746 17.9995,216.0246 17.9995,218.2116 L17.9995,220.5166 C17.9995,221.0426 18.8305,222.0166 19.4525,222.0166 L34.6875,222.0166 C35.3105,222.0166 35.9995,221.0426 35.9995,220.5166 L35.9995,218.2116 C35.9995,217.4486 35.8125,216.7056 35.4895,216.0166 L40.6865,216.0166 C41.3105,216.0166 41.9995,215.0426 41.9995,214.5166 L41.9995,212.2116 C41.9995,210.0246 40.5375,207.9746 38.3995,207.0166 L38.3995,207.0166 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Contacts.defaultProps = {
  color: '#9A9EAD',
};

export default Contacts;
