import React, { memo } from 'react';
import clsx from 'clsx';
import { Button } from '@upperhand/playmaker';

import { currentUser } from 'shared/utils/UserUtils.jsx';

function NoteItem({ note, title, onEdit, onDelete }) {
  const isStaff = currentUser().isStaff();
  const isCoach = currentUser().isCoach();

  return (
    <div
      className={clsx(
        'note-item',
        currentUser().isClient() && 'note-item-client'
      )}
    >
      <div className="note-item__title">
        {title}
        {(isStaff || isCoach) && (
          <div className="note-item__actions">
            <Button
              rounded
              icon="edit"
              onClick={onEdit}
              size="1x"
              type="tertiary"
            />
            <Button
              rounded
              icon="delete"
              onClick={onDelete}
              size="1x"
              type="tertiary"
            />
          </div>
        )}
      </div>
      <div className="note-item__content">{note}</div>
    </div>
  );
}
export default memo(NoteItem);
