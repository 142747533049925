import * as React from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';
import { Grid, Typography } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function MembershipCard({ clients, inner, orderItem, intl }) {
  const userIds = orderItem.getIn(['orderable', 'customer_user_ids'], Set());

  return inner ? (
    <>
      <Grid container item xs={12} className="order-item__info-item">
        <Typography
          className="order-item__membership-name"
          align="left"
          variant="h4"
        >
          {orderItem.getIn(['orderable', 'subscribable_name'])}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        className="order-item__info-item"
      >
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.client', intl, __filenamespace, {
              clientsCount: userIds.size,
            })}
          </Typography>
        </Grid>
        <Grid item>
          {userIds.map(userId => (
            <Typography key={userId} variant="subtitle2" align="right">
              {clients.get(userId).name()}
            </Typography>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        className="order-item__info-item"
      >
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.price', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" align="right">
            <FormattedCurrency
              value={
                orderItem.getIn(['orderable', 'subscribable_base_price']) || 0
              }
              fromCents
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.billed', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" align="right">
            {t(
              `.${
                orderItem.getIn([
                  'orderable',
                  'subscribable_interval_months',
                ]) === 1
                  ? 'membership_monthly'
                  : 'membership_yearly'
              }`,
              intl,
              __filenamespace
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid item className="order-item__qty">
        <Typography className="order-item__title" variant="subtitle2">
          {t('.qty', intl, __filenamespace, {
            qty: orderItem.get('display_quantity'),
          })}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" align="right">
          <FormattedCurrency value={orderItem.get('total') || 0} fromCents />
        </Typography>
      </Grid>
    </>
  );
}

MembershipCard.propTypes = {
  intl: PropTypes.object.isRequired,
  orderItem: PropTypes.object,
};

MembershipCard.defaultProps = {
  orderItem: {},
};

export default MembershipCard;
