import { Record } from 'immutable';

class WaitlistEntry extends Record({
  customer_user_id: null,
  invited: null,
}) {
  constructor(object = {}) {
    super(object);
  }
}

export default WaitlistEntry;
