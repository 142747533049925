import { List, Set } from 'immutable';
import POSCreditPassActions from 'point_of_sale/actions/POSCreditPassActions.js';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSSpecificEventsListActions from 'point_of_sale/actions/POSSpecificEventsListActions.js';
import { EventSource } from 'sources';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class POSSpecificEventsListStore extends UpperHandStore {
  constructor() {
    super();
    this.events = List();
    this.isLoading = false;
    this.bindListeners({
      listEvents: [
        POSProductListActions.CREDIT_PASS_SELECTED,
        POSCreditPassActions.FETCH_SUCCESS,
      ],

      listSuccess: POSSpecificEventsListActions.LIST_SUCCESS,
      listError: POSSpecificEventsListActions.LIST_ERROR,
    });
  }

  listEvents(creditPass) {
    const eventIds = creditPass.credit_pass_credits.reduce(
      (ids, credit) => ids.union(credit.event_ids),
      Set()
    );
    if (eventIds.isEmpty()) return;
    this.isLoading = true;
    EventSource.list({
      params: {
        fields: ['event_types'],
        per_page: 50,
        ids: eventIds.toJS(),
      },
      success: POSSpecificEventsListActions.listSuccess,
      error: POSSpecificEventsListActions.listErrors,
    });
  }

  listSuccess({ events }) {
    this.events = events;
    this.isLoading = false;
  }

  listError() {
    this.isLoading = false;
    this.notifyError('error listing events', arguments);
  }
}

export default alt.createStore(
  POSSpecificEventsListStore,
  'POSSpecificEventsListStore'
);
