export const hasMembershipAgreementAccepted = (
  customerUserIds,
  profiles,
  membershipAgreementContent
) =>
  customerUserIds.every(id => {
    const index = profiles.findIndex(client => client.get('id') === id);
    const client = profiles.get(index);
    return (
      client && client.getMembershipAgreement() === membershipAgreementContent
    );
  });

export const getAgreementSignedDate = (
  customerUserIds,
  profiles,
  membershipAgreementContent
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const id of customerUserIds) {
    const profile = profiles.find(client => client.get('id') === id);
    if (
      profile &&
      profile.getMembershipAgreement() === membershipAgreementContent
    ) {
      return profile.getAgreementSignedDate();
    }
  }
  // Return a default value (e.g., null) if no matching profile is found
  return null;
};
