import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Attendee(props) {
  return (
    <SvgIcon
      width="22px"
      height="19px"
      viewBox="0 0 16 17"
      version="1.1"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g
        id="Facility-/-Resource-3.0"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Quick-Schedule-Flow"
          transform="translate(-120.000000, -709.000000)"
          fill="#9A9EAD"
        >
          <g id="Group-8" transform="translate(103.000000, 545.000000)">
            <g id="Event">
              <path
                d="M24,172.75 C21.6635556,172.75 17,173.921333 17,176.25 L17,178 L31,178 L31,176.25 C31,173.921333 26.3364444,172.75 24,172.75 M24,171 C25.9335556,171 27.5,169.432 27.5,167.5 C27.5,165.566444 25.9335556,164 24,164 C22.0664444,164 20.5,165.566444 20.5,167.5 C20.5,169.432 22.0664444,171 24,171"
                id="Attendee-Icon"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Attendee;
