import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function NewImage({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-142.000000, -1137.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g transform="translate(91.000000, 1127.000000)">
            <g transform="translate(51.000000, 10.000000)">
              <path
                d="M18.5263158,5.78947368 L18.5263158,9.25157895 C18.5263158,9.25157895 16.2221053,
              9.26315789 16.2105263,9.25157895 L16.2105263,5.78947368 L12.7368421,
              5.78947368 C12.7368421,5.78947368 12.7484211,3.48526316 12.7368421,
              3.47368421 L16.2105263,3.47368421 L16.2105263,0 L18.5263158,0 L18.5263158,
              3.47368421 L22,3.47368421 L22,5.78947368 L18.5263158,5.78947368 Z M15.0526316,
              10.4210526 L15.0526316,6.94736842 L11.5789474,6.94736842 L11.5789474,
              3.47368421 L2.31578947,3.47368421 C1.04210526,3.47368421 0,4.51578947 0,
              5.78947368 L0,19.6842105 C0,20.9578947 1.04210526,22 2.31578947,
              22 L16.2105263,22 C17.4842105,22 18.5263158,20.9578947 18.5263158,
              19.6842105 L18.5263158,10.4210526 L15.0526316,10.4210526 Z M2.31578947,
              19.6842105 L5.78947368,15.0526316 L8.10526316,18.5263158 L11.5789474,
              13.8947368 L16.2105263,19.6842105 L2.31578947,19.6842105 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

NewImage.defaultProps = {
  color: '#9A9EAD',
};

export default NewImage;
