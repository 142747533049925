import AuthActions from 'shared/actions/AuthActions.jsx';
import { redirectTo } from 'shared/utils/RouteUtils';
import ResendAccountVerificationActions from 'shared/actions/ResendAccountVerificationActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class ResendAccountVerificationStore extends UpperHandStore {
  constructor() {
    super();
    this.showVerificationConfirm = false;
    this.bindListeners({
      handleRequestError: ResendAccountVerificationActions.REQUEST_ERROR,
      handleRequestSuccess: ResendAccountVerificationActions.REQUEST_SUCCESS,
      handleSend: ResendAccountVerificationActions.SEND,
      handleBackToLogin: ResendAccountVerificationActions.BACK_TO_LOGIN,

      handleUpdateStore: AuthActions.updateStore,
    });
  }

  handleSend({ redirect = true }) {
    return uhApiClient.post({
      url: 'accounts/verification_email',
      data: JSON.stringify({ email: this.email }),
      success: {
        action: ResendAccountVerificationActions.requestSuccess,
        args: [redirect],
      },
      error: ResendAccountVerificationActions.requestError,
    });
  }

  handleRequestSuccess([_, redirect]) {
    if (redirect) {
      redirectTo({
        path: '/accounts/verification_sent',
        query: { to: this.email },
      });
    } else {
      this.showVerificationConfirm = true;
    }
  }

  handleRequestError(...args) {
    this.notifyError('error while sending verification email', args);
  }

  handleBackToLogin() {
    this.showVerificationConfirm = false;
  }
}

export default alt.createStore(
  ResendAccountVerificationStore,
  'ResendAccountVerificationStore'
);
