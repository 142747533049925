import { Record } from 'immutable';
import moment from 'moment-timezone';

import Address from 'shared/records/Address.jsx';
import Client from 'shared/records/Client.jsx';
import { Image } from 'records';
import { convertDateToServerValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import {
  formatPhone,
  formatPhoneToE164,
} from 'shared/utils/FormattingUtils.jsx';

class User extends Record({
  id: null,
  address: new Address(),
  active_membership_color: null,
  current_password: null,
  customer_user_id: null,
  date_of_birth: null,
  email: null,
  first_name: null,
  last_login_at: null,
  gender: null,
  get_email_notifications: true,
  last_name: null,
  password_confirmation: null,
  password: null,
  phone: null,
  profile_image: new Image(),
  role: null,
  ssn_last_4: null,
  uuid: null,
}) {
  constructor(o = {}, options = { fromServer: false }) {
    const obj = o;
    if (options.fromServer) {
      obj.date_of_birth = moment(obj.date_of_birth);
    }

    // eslint-disable-next-line camelcase
    const profile_image = new Image(obj.profile_image);
    const address = new Address(obj.address || {});
    const phone = obj.phone ? formatPhone(obj.phone) : '';

    super({ ...obj, profile_image, address, phone });
  }

  alternativeImage(name) {
    if (!this.profile_image) {
      return null;
    }
    return this.profile_image.getAlternative(name);
  }

  asClient() {
    if (!this.isClient()) {
      throw new Error(
        `tried to convert ${this.email || 'no email'}:${
          this.id
        } to a client, but role is ${this.role}`
      );
    }
    return new Client(this.toJS())
      .set('id', this.customer_user_id)
      .set('user_id', this.id)
      .set('address', this.address);
  }

  isBlank() {
    return !(this.first_name || this.last_name || this.id);
  }

  isAdmin() {
    return this.role === 'Admin';
  }

  isClient() {
    return this.role === 'Client';
  }

  isStaffAdmin() {
    return this.role === 'StaffAdmin';
  }

  isStaffMember() {
    return this.role === 'StaffMember';
  }

  isStaff() {
    return ['Admin', 'StaffAdmin', 'StaffMember'].includes(user.role);
  }

  age() {
    return parseInt(moment().diff(this.date_of_birth, 'years', true), 10);
  }

  underThirteen() {
    const age = this.age();
    return Number.isNaN(age) || age < 13;
  }

  name() {
    return `${this.first_name} ${this.last_name}`;
  }

  async toServer() {
    const data = this.toJS();
    if (data.profile_image && !data.profile_image.file) {
      delete data.profile_image;
    } else {
      data.profile_image = await this.profile_image.toServer();
    }

    if (!data.password) {
      delete data.password;
      delete data.password_confirmation;
    }

    data.date_of_birth = this.date_of_birth.format('YYYY-MM-DD');

    if (new Address(data.address || {}).isBlank()) {
      delete data.address;
    }

    if (!data.email || !data.email.trim().length) {
      delete data.email;
    }

    if (data.phone) {
      data.phone = formatPhoneToE164(data.phone);
    } else {
      data.phone = null;
    }

    return data;
  }
}

User.fromServer = data => new User(data, { fromServer: true });

export default User;
