import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@upperhand/playmaker';

const styles = {
  headerContainer: {
    marginBottom: '16px',
    textTransform: 'capitalize',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

function PageHeader({ title, actions, wrapperStyles }) {
  return (
    <div style={{ ...styles.headerContainer, ...wrapperStyles }}>
      <Typography variant="h4">{title}</Typography>
      {actions && <div>{actions}</div>}
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actions: PropTypes.node,
  wrapperStyles: PropTypes.object,
};

PageHeader.defaultProps = {
  title: '',
  actions: null,
  wrapperStyles: {},
};

export default PageHeader;
