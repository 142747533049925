import * as React from 'react';
import { List } from 'immutable';
import Drawer from '@mui/material/Drawer';
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import SideNavMenuItem from 'side_nav/_SideNavMenuItem.jsx';
import MenuDivider from 'side_nav/_MenuDivider.jsx';
import UserMenuItem from 'side_nav/_UserMenuItem.jsx';
import HelpItem from 'side_nav/_HelpItem.jsx';
import SignOutItem from 'side_nav/_SignOutItem.jsx';
import NonLoggedInItems from 'side_nav/_NonLoggedInItems.jsx';
import ExportModal from 'containers/reports/exportModal/ExportModal.jsx';
import SideNavItems from 'side_nav/SideNavItems.js';
import uhTheme from '_uh_theme.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { humanizeUrl } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const drawerAnimationMs = 350;

const styles = {
  MobileList: {
    // This is a less-than-elegant hack but doesn't have any practical side effects.
    height: '100.1vh',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    color: uhTheme.palette.lightGrey,
    backgroundColor: uhColors.leftNavGrey,
  },
  SideNav: ({ mobile, hovering }) => ({
    minWidth: 56,
    width: hovering ? 250 : 56,
    height: mobile ? 0 : 'auto',
    color: uhTheme.palette.lightGrey,
    fontSize: 15,
    fontFamily: 'benton-sans, sans-serif',
    transition: `all ${drawerAnimationMs}ms cubic-bezier(0.3, 0, 0.16, 1)`,
    transitionProperty: 'width',
  }),
  DrawerRoot: ({ mobile, hovering }) => ({
    zIndex: 1200,
    position: mobile ? 'relative' : 'fixed',
    // top: '56px',
    bottom: mobile ? '0' : 'none',
    width: hovering ? 250 : 56,
    height: mobile ? 'auto' : 'calc(100% - 56px)',
    color: 'inherit',
    backgroundColor: uhColors.leftNavGrey,
    transition: `all ${drawerAnimationMs}ms cubic-bezier(0.3, 0, 0.16, 1)`,
  }),
  DrawerContainer: ({ mobile }, ...moreStyles) => ({
    zIndex: 1200,
    position: mobile ? 'fixed' : 'relative',
    bottom: mobile ? '0' : 'none',
    height: mobile ? 'auto' : '100%',
    backgroundColor: uhColors.leftNavGrey,
    boxShadow: mobile
      ? 'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px'
      : 'none',
    color: 'inherit',
    transition: `all ${drawerAnimationMs}ms cubic-bezier(0.3, 0, 0.16, 1)`,
    overflowX: 'hidden',
    ...merge(...moreStyles),
  }),
};

const customerTimeZone = () => {
  if (
    currentUser().isAdmin() &&
    currentCustomer() &&
    currentCustomer().tz_shortname
  ) {
    return currentCustomer().tz_shortname;
  }
  return null;
};

function CustomerUrl() {
  return (
    <div>
      <ListItem disablePadding style={{ color: uhTheme.palette.lightGrey }}>
        <ListItemButton href={currentCustomer().external_url}>
          <ListItemText
            style={{ color: uhTheme.palette.lightGrey }}
            secondary={
              <div style={{ color: uhTheme.palette.lightGrey }}>
                {customerTimeZone()}
              </div>
            }
          >
            {humanizeUrl(currentCustomer().external_url)}
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <MenuDivider />
    </div>
  );
}

function SideNavMenuItems({ mobile, drawerExpanded }) {
  const items = SideNavItems(mobile);

  return (
    <>
      {items.reduce((reduction, item) => {
        if (!mobile || !item.hide_on_mobile) {
          return reduction.push(
            <SideNavMenuItem
              key={`SideNavMenuItem-${item.title}-${item.selected}`}
              item={item}
              drawerExpanded={drawerExpanded}
            />
          );
        }
        return reduction;
      }, List())}
    </>
  );
}

class SideNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      drawerExpanded: false,
    };
  }

  componentDidMount() {
    this.isNavMounted = true;
  }

  componentWillUnmount() {
    this.isNavMounted = false;
  }

  handleMouseEnter = _e => {
    if (!this.isNavMounted) {
      return;
    }
    this.setState(
      () => ({
        hovering: true,
      }),
      this.setDrawerExpanded
    );
  };

  handleMouseLeave = _e => {
    if (!this.isNavMounted) {
      return;
    }
    this.setState(
      () => ({
        hovering: false,
      }),
      this.setDrawerExpanded
    );
  };

  setDrawerExpanded = () => {
    window.setTimeout(() => {
      if (!this.isNavMounted) {
        return;
      }
      this.setState(prevState => ({
        drawerExpanded: prevState.hovering,
      }));
    }, drawerAnimationMs * 0.5);
  };

  render() {
    const {
      sideNavStore,
      drawerContainerStyle,
      onRequestChange,
      customerStore: { customer },
      ...props
    } = this.props;
    const { hovering, drawerExpanded } = this.state;
    const mobile = smallScreen();
    const loggedIn = isLoggedIn();
    const showUserMenuItems = mobile && loggedIn;
    const drawerWidth = styles.SideNav({
      mobile,
      hovering,
    }).width;

    const sideNavStoreOpen = sideNavStore ? sideNavStore.open : false;
    const isSideNavOpen = mobile ? sideNavStoreOpen : true;
    return (
      <div
        id="side-menu"
        style={styles.SideNav({
          mobile,
          hovering,
        })}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <ExportModal />
        <Drawer
          disableEnforceFocus={mobile}
          variant={mobile ? 'temporary' : 'permanent'}
          open={isSideNavOpen}
          hideBackdrop={!mobile}
          onClose={() => onRequestChange([false])}
          style={styles.DrawerRoot({
            mobile,
            hovering,
          })}
          PaperProps={{
            sx: {
              width: mobile ? window.innerWidth * 0.9 : drawerWidth,
              ...styles.DrawerContainer(
                {
                  mobile,
                  hovering,
                },
                drawerContainerStyle
              ),
            },
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          <MuiList
            style={
              mobile ? styles.MobileList : { color: uhTheme.palette.lightGrey }
            }
          >
            {showUserMenuItems && <CustomerUrl />}
            {showUserMenuItems && <UserMenuItem />}
            <SideNavMenuItems
              mobile={mobile}
              drawerExpanded={drawerExpanded}
              hasAddOnsFeature={customer.features.toJS().add_ons}
            />
            {showUserMenuItems && <HelpItem />}
            {showUserMenuItems && <SignOutItem />}
            {!loggedIn && (
              <NonLoggedInItems onItemClick={() => onRequestChange([false])} />
            )}
          </MuiList>
        </Drawer>
      </div>
    );
  }
}

SideNav.defaultProps = {
  drawerContainerStyle: {},
  onRequestChange: () => {},
};

export default SideNav;
