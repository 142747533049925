import * as React from 'react';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import CardInfoCard from 'shared/components/CardInfoCard.jsx';
import InvalidCardInfoCard from 'shared/components/InvalidCardInfoCard.jsx';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { boldText } from 'shared/styles/uhStyles.jsx';
import { numberToCurrency } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import uhTheme from '_uh_theme.jsx';

const styles = {
  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '90%',
  },

  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    marginTop: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
  },
};

function CardInfo({ card }) {
  if (card.isValid()) {
    return (
      <CardInfoCard
        brand={card.card_type}
        cardholderName={card.name()}
        identifyingDigits={card.identifyingDigits()}
        expMonth={card.expiration_month}
        expYear={card.expiration_year}
        style={{ marginTop: 25, maxWidth: SINGLE_COLUMN_WIDTH }}
      />
    );
  }
  return (
    <InvalidCardInfoCard
      style={{ marginTop: 25, maxWidth: SINGLE_COLUMN_WIDTH }}
    />
  );
}

const intervalAbbr = (interval, unit) => {
  if (unit === 'month') {
    if (interval === 1) {
      return 'm';
    }
    if (interval === 3) {
      return 'q';
    }
    if (interval === 12) {
      return 'yr';
    }
    return `${interval}m`;
  }
  return `${interval}${unit[0]}`;
};

function SubscriptionInfo({ subscription }) {
  const { product, components } = subscription;
  const intervalLabel = intervalAbbr(product.interval, product.interval_unit);
  const totalPrice = components
    .map(addon => addon.get('unit_price') / 100)
    .push(product.price_in_cents / 100)
    .reduce((prev, current) => prev + current);

  return (
    <div style={{ marginTop: 25 }}>
      <FlexBoxJustify style={{ paddingBottom: 15 }}>
        <div style={boldText}>{subscription.product.name}</div>
        <div>
          {numberToCurrency(product.price_in_cents / 100, { precision: 0 })}/
          {intervalLabel}
        </div>
      </FlexBoxJustify>
      {subscription.components.map(addon => (
        <div key={`${addon.get('id')}-${addon.get('name')}`}>
          <Divider />
          <FlexBoxJustify style={{ padding: '15px 0' }}>
            <div style={boldText}>{addon.get('name')}</div>
            <div>
              {numberToCurrency(addon.get('unit_price') / 100, {
                precision: 0,
              })}
              /{intervalLabel}
            </div>
          </FlexBoxJustify>
        </div>
      ))}
      <Divider />
      <FlexBoxJustify style={{ padding: '15px 0', fontWeight: 600 }}>
        <div>Total</div>
        <div>
          {numberToCurrency(totalPrice, { precision: 0 })}/{intervalLabel}
        </div>
      </FlexBoxJustify>
    </div>
  );
}

function BillingTab({ subscription, managementUrl }) {
  const shouldSpin = subscription.isLoading || managementUrl.isLoading;

  return (
    <SpinWhileLoading isLoading={shouldSpin} spinWhile="isLoading">
      <FlexBox style={styles.root}>
        <div style={styles.column}>
          <h2>Payment Info</h2>
          <CardInfo card={subscription.subscription.credit_card} />
          <Button
            variant="contained"
            color="default"
            style={{ marginTop: 20, color: uhTheme.palette.primary1Color }}
            href={managementUrl.url}
            target="_blank"
          >
            Edit
          </Button>
        </div>
        <div style={{ width: 90 }} />
        <div style={styles.column}>
          <h2>Products</h2>
          <SubscriptionInfo subscription={subscription.subscription} />
        </div>
      </FlexBox>
    </SpinWhileLoading>
  );
}

export default BillingTab;
