/* eslint-disable class-methods-use-this */
import { Map, List, Range, Set } from 'immutable';
import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions.js';
import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';
import MembershipSubscriptionActions from 'memberships/actions/MembershipSubscriptionActions.jsx';
import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions.js';
import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';
import UpperHandRecordStore from 'shared/stores/UpperHandRecordStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { sumInt } from 'shared/utils/ImmutableUtils.js';
import { MembershipSubscriptionSource } from 'sources';

class MembershipSubscriptionStore extends UpperHandRecordStore {
  constructor() {
    super();

    this.currentListOptions = {};
    this.handledPages = Set();
    this.isLoading = false;
    this.loadedPages = Set();
    this.subscriptions = Map();
    this.totalPages = 0;
    this.membershipId = null;

    this.subscriptionsForClient = clientId =>
      this.subscriptions.get(clientId, List());

    this.activeDateForClient = clientId => {
      const all = this.subscriptionsForClient(clientId);
      const subscr = all && all.first();
      return subscr && subscr.starts_at;
    };

    this.bindListeners({
      handleEnrolledClientsSubscriptionsList:
        MembershipSubscriptionActions.enrolledClientsSubscriptionsList,
      handleEnrolledClientsSubscriptionsSuccess:
        MembershipSubscriptionActions.enrolledClientsSubscriptionsSuccess,
      handleList: [
        MembershipSubscriptionActions.LIST,
        MembershipViewingActions.INVITE_SUCCESS,
      ],
      handleListSuccess: MembershipSubscriptionActions.LIST_SUCCESS,
      handleListError: MembershipSubscriptionActions.LIST_ERROR,

      updateSubscription: [
        MembershipCancellationActions.CANCEL_MEMBERSHIP_SUCCESS,
        MembershipSuspensionActions.SUSPEND_MEMBERSHIP_SUCCESS,
        MembershipSuspensionActions.REACTIVATE_MEMBERSHIP_SUCCESS,
      ],
    });
  }

  getNextPage() {
    return Range(1, this.totalPages + 1).find(
      pageNo => !this.handledPages.has(pageNo)
    );
  }

  handleList({ membershipId, options }) {
    this.isLoading = true;
    this.currentListOptions = merge({ per_page: 50 }, options);
    this.handledPages = this.handledPages.add(this.currentListOptions.page);
    this.membershipId = membershipId || this.membershipId;

    uhApiClient.get({
      url: this.url(this.membershipId),
      data: this.currentListOptions,
      success: MembershipSubscriptionActions.listSuccess,
      error: MembershipSubscriptionActions.listError,
    });
  }

  handleEnrolledClientsSubscriptionsList({ clientIds, membershipIds }) {
    MembershipSubscriptionSource.list({
      params: {
        client_ids: clientIds,
        membership_ids: membershipIds,
        fields: ['membership'],
      },
      success:
        MembershipSubscriptionActions.enrolledClientsSubscriptionsSuccess,
      error: MembershipSubscriptionActions.listError,
    });
  }

  handleEnrolledClientsSubscriptionsSuccess(data) {
    this.subscriptions = data.membership_subscriptions;
  }

  handleListSuccess(data) {
    if (data.page === 1) {
      this.subscriptions = Map();
      this.handledPages = Set([1]);
      this.loadedPages = Set();
      this.totalPages = Math.trunc((data.total_count - 1) / data.per_page) + 1;
    }

    this.subscriptions = this.subscriptions.withMutations(map => {
      data.subscriptions.forEach(ms => {
        map.update(ms.client_id, (currentList = List()) =>
          currentList.push(this.newRecord(ms))
        );
      });
    });

    this.loadedPages = this.loadedPages.add(data.page);

    if (data.total_count > this.subscriptions.reduce(sumInt('size'), 0)) {
      while (
        this.getNextPage() &&
        this.handledPages.subtract(this.loadedPages).size < 3
      ) {
        this.handleList({
          membershipId: this.membershipId,
          options: merge(this.currentListOptions, { page: this.getNextPage() }),
        });
      }
    } else {
      this.isLoading = false;
      this.currentListOptions = {};
    }
  }

  handleListError(...arg) {
    this.notifyError('error listing membership subscriptions', arg);
  }

  updateSubscription(data) {
    const updatedSub = new MembershipSubscription(data);
    const existingSubs = this.subscriptionsForClient(updatedSub.client_id);
    const index = existingSubs.findIndex(s => s.id === updatedSub.id);

    if (index === undefined) {
      this.subscriptions = this.subscriptions.set(
        updatedSub.client_id,
        existingSubs.push(updatedSub)
      );
    } else {
      this.subscriptions = this.subscriptions.set(
        updatedSub.client_id,
        existingSubs.set(index, updatedSub)
      );
    }
  }

  newRecord(options) {
    return new MembershipSubscription(options);
  }

  url(membershipId) {
    return `memberships/${membershipId}/subscriptions`;
  }
}

export default alt.createStore(
  MembershipSubscriptionStore,
  'MembershipSubscriptionStore'
);
