import * as React from 'react';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import DuplicateMembershipWarning from 'shared/components/memberships/DuplicateMembershipWarning.jsx';
import Header from 'point_of_sale/components/Header.jsx';
import MembershipCommitment from 'point_of_sale/components/MembershipCommitment.jsx';
import MembershipPrice from 'point_of_sale/components/MembershipPrice.jsx';
import ProductInformation from 'shared/components/ProductInformation.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import SelectedProfileList from 'shared/components/_SelectedProfileList.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { MembershipViewingTiersBenefits } from 'memberships/components/MembershipViewing.jsx';
import MembershipTierSelector from 'shared/components/memberships/MembershipTierSelector.jsx';

import POSMembershipRegistrationActions from 'point_of_sale/actions/POSMembershipRegistrationActions.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const styles = {
  benefitsBox: {
    backgroundColor: uhColors.lightYellow,
    marginBottom: 25,
    padding: '12px 12px 14px 18px',
  },

  cancelButton: {
    height: 50,
  },

  contentContainer: {
    padding: 20,
    lineHeight: '24px',
  },

  purchaseTarget: {
    fontWeight: 600,
    fontSize: 15,
  },

  disablePadding: {
    padding: 0,
  },

  benefitExpandBtn: {
    backgroundColor: 'var(--color-highlight-yellow)',
    padding: '5px',
  },

  benefitsList: {
    backgroundColor: 'var(--color-highlight-yellow)',
  },

  benefitWrapper: {
    padding: '10px 5px',
  },

  benefitItem: {
    marginLeft: -2,
  },

  actionsContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },

  divider: {
    margin: '20px -1.5rem',
  },
};

const membershipCancelClicked = () =>
  POSMembershipRegistrationActions.cancelClicked();
const viewInCartClicked = () => {
  POSMembershipRegistrationActions.viewInCartClicked();
};

function MembershipRegistering({
  intl,
  posEventTypeListStore: { isLoading, eventTypes },
  posRetailCategoryListStore,
  posMembershipRegistrationStore,
  posStore,
  creditPassListingStore: { records: creditPasses },
}) {
  const {
    availableProfiles,
    cartMembershipMap,
    changed,
    membership,
    orderItemId,
    packageIsValid,
    selectedProfiles,
    subscriptionPackage,
    embedErrors,
    membershipTiers,
  } = posMembershipRegistrationStore;

  const { features } = currentCustomer();
  const { embed_api: embedApiEnabled } = features;
  const { has_embed_product: membershipHasEmbedProduct } = membership;
  const { embed_card_ids: embedCardIds, membership_tier_id: membershipTierId } =
    subscriptionPackage;
  const showEmbedCardInput =
    embedApiEnabled && membershipHasEmbedProduct && currentUser().isStaff();

  const warnings = cartMembershipMap.map(
    ([membershipId, membershipName], customerUserId) => {
      if (membershipId === membership.id) {
        return null;
      }

      const profile = selectedProfiles.find(p => p.id === customerUserId);
      const profileName = profile ? profile.name() : '';

      return (
        <DuplicateMembershipWarning
          profileName={profileName}
          existingMembershipName={membershipName}
          newMembershipName={membership.name}
        />
      );
    }
  );

  return (
    <div>
      <Header selectedClient={posStore.selectedClient}>
        <ProductInformation
          primaryText={membership.name}
          secondaryText={<MembershipCommitment membership={membership} />}
          color={membership.color}
          typeText={t('.membership', intl, __filenamespace)}
          price={<MembershipPrice membership={membership} />}
        />
      </Header>
      <SpinWhileLoading isLoading={isLoading}>
        <div style={styles.contentContainer}>
          <div>{membership.description}</div>
          <Divider sx={styles.divider} />
          <MembershipViewingTiersBenefits
            membershipTiers={
              membership.tiered ? membershipTiers : List([membership])
            }
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={posRetailCategoryListStore.retailCategories}
            membershipTiersLoading={false}
            wrapperStyles={styles.disablePadding}
            benefitsItemStyles={styles.disablePadding}
            expanderBtnStyles={styles.benefitExpandBtn}
            benefitsListStyles={styles.benefitsList}
            benefitsWrapperStyle={styles.benefitWrapper}
            discountsItemStyle={styles.benefitItem}
            exclusivitiesItemStyle={styles.benefitItem}
          />
          <Divider sx={styles.divider} />
          {membership.tiered && (
            <MembershipTierSelector
              fullWidth
              tiers={membershipTiers.toArray()}
              value={membershipTierId}
              classes={{
                root: 'membership-tier-selector',
                inputLabel: 'membership-tier-selector__label',
                inputLabelTypography:
                  'membership-tier-selector__label-typography',
              }}
              onChange={e =>
                POSMembershipRegistrationActions.membershipTierSelected(
                  e.target.value
                )
              }
            />
          )}

          <ProfileSelector
            afterProfileCreate={POSMembershipRegistrationActions.profileCreated}
            athletes={availableProfiles}
            header={t('.for_whom', intl, __filenamespace)}
            headerStyle={styles.purchaseTarget}
            managingUserId={posStore.selectedClient.user_id}
            onChange={(_, __, id) =>
              POSMembershipRegistrationActions.profileSelected(id)
            }
            style={{ marginBottom: 25 }}
            value={null}
          />

          <SelectedProfileList
            embedCardIds={embedCardIds}
            showEmbedCardInput={showEmbedCardInput}
            onEmbedCardChange={POSMembershipRegistrationActions.embedCardChange}
            onEmbedInputBlur={POSMembershipRegistrationActions.validate}
            embedErrors={embedErrors}
            athletes={selectedProfiles}
            style={{ marginBottom: 25, marginTop: 25 }}
            onRemove={POSMembershipRegistrationActions.profileRemoved}
            warnings={warnings}
          />

          <Box sx={styles.actionsContainer}>
            <Button
              fullWidth
              variant="outlined"
              color="dark"
              onClick={membershipCancelClicked}
              style={styles.cancelButton}
            >
              {t('actions.cancel', intl, __filenamespace)}
            </Button>
            <CartCommitButton
              addToCartLabel={
                membership.price === 0 && !membership.tiered
                  ? t('actions.enroll', intl)
                  : null
              }
              hasOrderItemId={orderItemId}
              hasUpdate={changed}
              itemIsValid={packageIsValid && embedErrors.isEmpty()}
              handleAddToCartClick={() => {
                POSMembershipRegistrationActions.addToCartClick(
                  subscriptionPackage
                );
              }}
              handleUpdateCartClick={() => {
                POSMembershipRegistrationActions.updateCartClick(orderItemId, {
                  orderable: subscriptionPackage,
                });
              }}
              handleViewInCartClick={viewInCartClicked}
            />
          </Box>
        </div>
      </SpinWhileLoading>
    </div>
  );
}

export default injectIntl(MembershipRegistering);
