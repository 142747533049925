import Client from 'shared/records/Client.jsx';
import WaiverAgreementDrawerActions from './actions';

class WaiverDrawerStore {
  constructor() {
    this.reset();

    this.bindListeners({
      openDrawer: WaiverAgreementDrawerActions.OPEN_DRAWER,
      closeDrawer: WaiverAgreementDrawerActions.CLOSE_DRAWER,
    });
  }

  reset() {
    this.client = new Client();
    this.open = false;
  }

  openDrawer(client) {
    this.client = client;
    this.open = true;
  }

  closeDrawer() {
    this.reset();
  }
}

export default alt.createStore(WaiverDrawerStore, 'WaiverDrawerStore');
