class CustomerCreationActions {
  constructor() {
    this.generateActions(
      'signUp',
      'signUpSuccess',
      'signUpError',
      'verifyToken',
      'verifyTokenError',
      'verifyTokenSuccess',
      'updateStore',
      'closeDuplicateOrgNameModal'
    );
  }
}

export default alt.createActions(CustomerCreationActions);
