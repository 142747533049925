import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventResourceDrawerActions from 'event_mgmt/editing/actions/EventResourceDrawerActions.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import ResourceActions from 'resources/actions/ResourceActions.js';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

export const STEP_LISTING = 'stepListing';
export const STEP_CREATING = 'stepCreating';

class EventResourceDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.step = STEP_LISTING;
    this.isOpenBooking = false;

    this.bindListeners({
      switchToCreation: ResourceActions.CREATE_RESOURCE_CLICKED,
      switchToListing: [
        EventResourceDrawerActions.CREATE_RESOURCE_CANCELLED,
        ResourceActions.CREATE_OR_UPDATE_SUCCESS,
        SecondaryDrawerActions.CLOSE,
      ],

      setEventScheduleInfo: [
        EventActions.FETCH_SUCCESS,
        EventActions.UPDATE_SUCCESS,
        EventActions.CREATE_SUCCESS,
        EventActions.SCHEDULE_TYPE_SELECTED,
      ],
    });
  }

  switchToCreation() {
    this.step = STEP_CREATING;
  }

  switchToListing() {
    this.step = STEP_LISTING;
  }

  setEventScheduleInfo() {
    this.waitFor(EventStore);

    this.isOpenBooking = EventStore.getState().customerEvent.isOpenBooking();
  }
}

export default alt.createStore(
  EventResourceDrawerStore,
  'EventResourceDrawerStore'
);
