import { Map, OrderedSet } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';

import {
  MembershipSource,
  MembershipTierSource,
  MembershipSubscriptionSource,
} from 'sources';
import { MembershipDataStore } from 'dataStores';

import MembershipManagementActions from './Actions';

export const MANAGEMENT_OPTIONS = {
  SUSPEND: 'suspend',
  UPGRADE: 'upgrade',
  DOWNGRADE: 'downgrade',
  TERMINATE: 'terminate',
};

export const SUSPENSION_OPTIONS = {
  '30DAYS': '30_days',
  '60DAYS': '60_days',
  '90DAYS': '90_days',
  '120DAYS': '120_days',
  '150DAYS': '150_days',
};

export const TERMINATION_OPTIONS = {
  IMMEDIATE: 'immediately',
  END_OF_BILLING: 'end_of_billing',
};

class MembershipManagementStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      closeManagementModal: MembershipManagementActions.closeModal,
      openManagementModal: MembershipManagementActions.openModal,

      updateForm: MembershipManagementActions.updateForm,

      listMembershipsSuccess:
        MembershipManagementActions.listMembershipsSuccess,
      listMembershipsError: MembershipManagementActions.listMembershipsError,

      listMembershipTiersSuccess:
        MembershipManagementActions.listMembershipTiersSuccess,
      listMembershipTiersError:
        MembershipManagementActions.listMembershipTiersError,

      sendManagementRequest: MembershipManagementActions.sendManagementRequest,
      sendManagementRequestSuccess:
        MembershipManagementActions.sendManagementRequestSuccess,
      sendManagementRequestError:
        MembershipManagementActions.sendManagementRequestError,
    });
  }

  reset() {
    this.openModal = false;
    this.membershipsLoading = false;
    this.membershipTiersLoading = false;
    this.sendingRequest = false;
    this.requestSent = false;
    this.subscription = null;
    this.membershipIds = OrderedSet();
    this.membershipTierIds = OrderedSet();
    this.errors = new FieldErrors();

    this.managementForm = Map({
      option: null,
      reason: null,
      new_membership_id: null,
      new_membership_tier_id: null,
      suspension_length: null,
      suspension_start_date: null,
      termination: null,
    });
  }

  get isTieredMembership() {
    const { memberships } = MembershipDataStore.getState();
    const newMembershipId = this.managementForm.get('new_membership_id');

    return memberships.get(newMembershipId)?.tiered;
  }

  getRequestAttributes() {
    return this.managementForm.toJS();
  }

  closeManagementModal() {
    this.reset();
  }

  openManagementModal({ subscription }) {
    this.reset();

    this.openModal = true;
    this.subscription = subscription;
  }

  updateForm({ key, value }) {
    this.managementForm = this.managementForm.set(key, value);

    const isUpdatingMembership =
      value === MANAGEMENT_OPTIONS.DOWNGRADE ||
      value === MANAGEMENT_OPTIONS.UPGRADE;

    if (isUpdatingMembership && this.membershipIds.size === 0) {
      this.listMemberships();
    }

    if (key === 'new_membership_id' && this.isTieredMembership) {
      this.membershipTierIds = OrderedSet();
      this.managementForm = this.managementForm.set(
        'new_membership_tier_id',
        null
      );
      this.listMembershipTiers();
    }
  }

  listMemberships(page = 1) {
    this.membershipsLoading = true;
    MembershipSource.list({
      params: {
        page,
        per_page: 50,
      },
      success: MembershipManagementActions.listMembershipsSuccess,
      error: MembershipManagementActions.listMembershipsError,
    });
  }

  listMembershipsSuccess({ memberships, page, perPage, totalCount }) {
    this.membershipIds = this.membershipIds.union(
      OrderedSet(memberships.map(membership => membership.id))
    );

    if (page * perPage >= totalCount) {
      this.membershipsLoading = false;
    } else {
      this.listMemberships(page + 1);
    }
  }

  listMembershipsError(...args) {
    this.membershipsLoading = false;
    this.NotifyError('Failed to load memberships', args);
  }

  listMembershipTiers(page = 1) {
    this.membershipTiersLoading = true;
    MembershipTierSource.list({
      params: {
        page,
        per_page: 50,
        membership_id: this.managementForm.get('new_membership_id'),
      },
      success: MembershipManagementActions.listMembershipTiersSuccess,
      error: MembershipManagementActions.listMembershipTiersError,
    });
  }

  listMembershipTiersSuccess({
    membership_tiers: membershipTiers,
    page,
    perPage,
    totalCount,
  }) {
    this.membershipTierIds = this.membershipTierIds.union(
      OrderedSet(membershipTiers.map(tier => tier.id))
    );

    if (page * perPage >= totalCount) {
      this.membershipTiersLoading = false;
    } else {
      this.listMembershipTiers(page + 1);
    }
  }

  listMembershipTiersError(...args) {
    this.membershipTiersLoading = false;
    this.notifyError('Failed to load membership tiers', args);
  }

  validateForm() {
    const selectedOption = this.managementForm.get('option');
    const suspensionLength = this.managementForm.get('suspension_length');
    const suspensionStartDate = this.managementForm.get(
      'suspension_start_date'
    );
    const terminationOption = this.managementForm.get('termination');
    const newMembershipId = this.managementForm.get('new_membership_id');
    const newMembershipTierId = this.managementForm.get(
      'new_membership_tier_id'
    );
    const reason = this.managementForm.get('reason');
    const { memberships } = MembershipDataStore.getState();
    const isMembershipTiered = memberships.get(newMembershipId)?.tiered;

    this.errors = new FieldErrors();

    if (!selectedOption) {
      this.errors = this.errors.add(
        'option',
        'Please select an option to proceed'
      );
    }

    if (!reason) {
      this.errors = this.errors.add(
        'reason',
        'Please provide a reason for this request'
      );
    }

    if (selectedOption === MANAGEMENT_OPTIONS.SUSPEND && !suspensionLength) {
      this.errors = this.errors.add(
        'suspension_length',
        'Please select a suspension length to proceed'
      );
    }

    if (selectedOption === MANAGEMENT_OPTIONS.SUSPEND && !suspensionStartDate) {
      this.errors = this.errors.add(
        'suspension_start_date',
        'Please select a suspension start date to proceed'
      );
    }

    if (selectedOption === MANAGEMENT_OPTIONS.TERMINATE && !terminationOption) {
      this.errors = this.errors.add(
        'termination',
        'Please select a termination option to proceed'
      );
    }

    if (selectedOption === MANAGEMENT_OPTIONS.UPGRADE && !newMembershipId) {
      this.errors = this.errors.add(
        'new_membership_id',
        'Please select a new membership to upgrade to'
      );
    }

    if (
      selectedOption === MANAGEMENT_OPTIONS.UPGRADE &&
      isMembershipTiered &&
      !newMembershipTierId
    ) {
      this.errors = this.errors.add(
        'new_membership_tier_id',
        'Please select a new membership tier to upgrade to'
      );
    }

    if (selectedOption === MANAGEMENT_OPTIONS.DOWNGRADE && !newMembershipId) {
      this.errors = this.errors.add(
        'new_membership_id',
        'Please select a new membership to downgrade to'
      );
    }

    if (
      selectedOption === MANAGEMENT_OPTIONS.DOWNGRADE &&
      isMembershipTiered &&
      !newMembershipTierId
    ) {
      this.errors = this.errors.add(
        'new_membership_tier_id',
        'Please select a new membership tier to downgrade to'
      );
    }
  }

  sendManagementRequest() {
    this.validateForm();

    if (this.errors.hasErrors()) {
      return;
    }

    const attributes = this.getRequestAttributes();

    this.sendingRequest = true;
    this.requestSent = false;
    MembershipSubscriptionSource.managementRequest({
      membershipId: this.subscription.id,
      params: {
        attributes,
      },
      success: MembershipManagementActions.sendManagementRequestSuccess,
      error: MembershipManagementActions.sendManagementRequestError,
    });
  }

  sendManagementRequestSuccess() {
    this.sendingRequest = false;
    this.requestSent = true;
  }

  sendManagementRequestError(...args) {
    this.sendingRequest = false;
    this.requestSent = false;
    this.notifyError('Failed to send management request', args);
  }
}

export default alt.createStore(
  MembershipManagementStore,
  'MembershipManagementStore'
);
