/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Map, List } from 'immutable';

import EventIcon from '@mui/icons-material/Event';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from '@mui/icons-material/Email';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import Tabs from 'components/Tabs/Tabs.jsx';

import TabEventAndLocation from 'event_mgmt/editing/components/_TabEventAndLocation.jsx';
import TabSchedule from 'event_mgmt/editing/components/_TabSchedule.jsx';
import TabParticipants from 'event_mgmt/editing/components/_TabParticipants.jsx';
import TabStaffAndResources from 'event_mgmt/editing/components/_TabStaffAndResources.jsx';
import TabPricing from 'event_mgmt/editing/components/_TabPricing.jsx';
import TabMarketing from 'event_mgmt/editing/components/_TabMarketing.jsx';
import TabRegistrationForm from 'event_mgmt/editing/components/_TabRegistrationForm.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

const styles = {
  tab: {
    backgroundColor: 'var(--color-bg-gray)',
    height: 50,
    textTransform: 'capitalize',
    zIndex: 'unset',
  },
  tabContent: {
    padding: '20px 30px',
  },
};

class TabContainer extends React.PureComponent {
  state = {
    errors: null,
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.preventNavigationIfDirty);
  }

  componentDidUpdate() {
    const { errors } = this.state;
    const tabErrors = this.getTabs({ metadataOnly: true })
      .map(c => (c.hasErrors ? c.labelText : null))
      .filter(s => !!s);
    if (tabErrors.size > 0 && !tabErrors.equals(errors)) {
      this.setState({ errors: tabErrors });
      MessageWindowActions.addMessage.defer(
        `Event is invalid. Check ${tabErrors.join(', ')} for more information.`
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.preventNavigationIfDirty);
  }

  preventNavigationIfDirty = event => {
    const { savedState } = this.props;

    if (savedState === 'save') {
      // Cancel the event as stated by the standard.
      event.preventDefault();

      // Chrome requires returnValue to be set.
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }
  };

  getTabs = ({ metadataOnly = false } = {}) => {
    const {
      customerEvent,
      handleEventInputChange,
      handleScheduleChange,
      eventFieldErrors,
      eventTypes,
      confirmSSPDialogOpened,
    } = this.props;

    const props = {
      customerEvent,
      handleInputChange: handleEventInputChange,
      handleScheduleChange,
      schedules: customerEvent ? customerEvent.get('schedules') : List([Map()]),
      fieldErrors: eventFieldErrors,
      eventTypes,
    };

    const hasError = attribute =>
      props.fieldErrors && !!props.fieldErrors.get(attribute);

    const hasErrors = attributes =>
      attributes.some(attribute => hasError(attribute));
    return [
      {
        tabIndex: 0,
        tabLabel: 'Event & Location',
        tabHashValue: 'event-location',
        tabIcon: <EventIcon />,
        tabHasErrors: hasErrors(['title', 'event_type', 'seasons']),
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabEventAndLocation {...props} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 1,
        tabLabel: 'Schedule',
        tabHashValue: 'schedule',
        tabIcon: <DateRangeIcon />,
        tabHasErrors: hasErrors([
          'start_date',
          'end_date',
          'start_time',
          'end_time',
          'times',
        ]),
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabSchedule {...props} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 2,
        tabLabel: 'Participants',
        tabHashValue: 'participants',
        tabIcon: <PersonIcon />,
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabParticipants {...props} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 3,
        tabLabel: 'Staff & Resources',
        tabHashValue: 'staff',
        tabIcon: <AssignmentIndIcon />,
        tabHasErrors: hasErrors([
          'unavailable_staff',
          'unavailable_resources',
          'booked_staff',
        ]),
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabStaffAndResources {...props} />
          </div>
        ),
        tabVisible: !(customerEvent && customerEvent.isClassSchedule()),
      },
      {
        tabIndex: 4,
        tabLabel: 'Pricing',
        tabHashValue: 'pricing',
        tabIcon: <AttachMoneyIcon />,
        tabHasErrors: hasErrors([
          'base_price',
          'payment_methods',
          'scheduled_date',
          'scheduled_price',
        ]),
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabPricing
              confirmSSPDialogOpened={confirmSSPDialogOpened}
              {...props}
            />
          </div>
        ),
        tabVisible: !(customerEvent && customerEvent.isClassSchedule()),
      },
      {
        tabIndex: 5,
        tabLabel: 'Marketing',
        tabHashValue: 'marketing',
        tabIcon: <EmailIcon />,
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabMarketing {...props} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 6,
        tabLabel: 'Registration',
        tabHashValue: 'registration',
        tabIcon: <ContentPasteIcon />,
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabRegistrationForm {...props} />
          </div>
        ),
        tabVisible: !(customerEvent && customerEvent.isClassSchedule()),
      },
    ];
  };

  render() {
    return (
      <Tabs
        showPrevNextButtons
        defaultActiveTab={0}
        variant="fullWidth"
        tabItems={this.getTabs()}
      />
    );
  }
}

export default TabContainer;
