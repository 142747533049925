import * as React from 'react';
import { Grid, Typography } from '@upperhand/playmaker';

const styles = {
  root: {
    border: '0.69px solid #E2E2E2',
    padding: 8,
  },
};

function TableHeader() {
  return (
    <div style={styles.root}>
      <Grid container direction="row" justify="flex-start">
        <Grid item md={5}>
          <Typography variant="subtitle2">Staff</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="subtitle2">Phone</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="subtitle2">Email</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default TableHeader;
