import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// Custom hook to check if the device is mobile
function useIsMobile() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobile;
}

export default useIsMobile;
