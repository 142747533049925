import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function SignOut({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 36 36" {...props}>
      <g
        id="Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile-Nav"
          transform="translate(-24.000000, -737.000000)"
          fill={color}
        >
          <g id="Nav" transform="translate(0.000000, 158.000000)">
            <path
              d="M39.9123401,610.302964 L28.6954446,610.302964 L28.6954446,583.695445 L39.9123401,583.695445 C41.2068481,583.695445 42.2600624,582.64223 42.2600624,581.347722 C42.2600624,580.053214 41.2068481,579 39.9123401,579 L26.3477223,579 C25.0532143,579 24,580.053214 24,581.347722 L24,612.650686 C24,613.945194 25.0532143,614.998409 26.3477223,614.998409 L39.9123401,614.998409 C41.2068481,614.998409 42.2600624,613.945194 42.2600624,612.650686 C42.2600624,611.356178 41.2068481,610.302964 39.9123401,610.302964 Z M59.3123782,595.339352 L52.0086141,588.034544 C51.0932633,587.119845 49.6038943,587.119845 48.6881522,588.034935 C47.7730622,588.950678 47.7731926,590.440046 48.6882826,591.355136 L51.9846152,594.651469 L34.6967706,594.651469 C33.4022627,594.651469 32.3490483,595.704683 32.3490483,596.999191 C32.3490483,598.293699 33.4022627,599.346914 34.6967706,599.346914 L51.9844847,599.346914 L48.688413,602.642855 C48.2449544,603.086183 48.0005304,603.675983 48.0005304,604.302955 C48.0005304,604.930058 48.2449544,605.519728 48.688413,605.963186 C49.1317413,606.406645 49.7214109,606.650939 50.3482527,606.650939 C50.9757467,606.650939 51.5654163,606.406645 52.008875,605.963186 L59.3121174,598.659422 C59.7557065,598.216094 60,597.626685 60,596.999452 C60,596.372349 59.7557065,595.78268 59.3123782,595.339352 Z"
              id="Sign-Out-Icon"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

SignOut.defaultProps = {
  color: '#9A9EAD',
};

export default SignOut;
