import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { TransactionLedger } from 'records';

function RowMobile({ intl, transactionLedger }) {
  const effectiveAt = moment(transactionLedger.get('effectiveAt'));

  return (
    <Card
      classes={{
        root: 'transactions-ledger-report__row-mobile',
        content: 'transactions-ledger-report__row-mobile-content',
      }}
      contentDirection="row"
      onClick={() => {
        // transactionLedger.get('entryId')
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        alignContent="center"
        justify="space-between"
      >
        <Grid item xs={7}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">
                {transactionLedger.get('clientName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                className="transactions-ledger-report__row-item_muted"
              >
                {effectiveAt.isSame(moment(), 'day')
                  ? t('.date_today', intl, __filenamespace, {
                      date: effectiveAt.format('h:mma'),
                    })
                  : effectiveAt.format('MM/DD/YYYY • h:mma')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                className="transactions-ledger-report__row-total"
              >
                <FormattedCurrency
                  value={transactionLedger.get('amount')}
                  fromCents
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="right"
                className="transactions-ledger-report__row-item_muted"
              >
                {transactionLedger.get('sourceType')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

RowMobile.propTypes = {
  intl: PropTypes.object.isRequired,
  transactionLedger: PropTypes.instanceOf(TransactionLedger).isRequired,
};

export default RowMobile;
