import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import { redirectTo, customerScopedRoute } from 'shared/utils/RouteUtils';

import { ClassesSource } from 'sources';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import ClassCancellationActions from './Actions';

export const creditOperations = {
  REFUND_CREDIT: 'refund_credit',
  REVOKE_CREDIT: 'revoke_credit',
};

class ClassCancellationStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      handleChange: ClassCancellationActions.handleChange,
      toggleCancelModal: ClassCancellationActions.toggleCancelModal,
      classCancel: ClassCancellationActions.classCancel,
      classCancelSuccess: ClassCancellationActions.classCancelSuccess,
      classCancelError: ClassCancellationActions.classCancelError,
    });
  }

  reset() {
    this.classIdToCancel = null;
    this.creditOperation = creditOperations.REFUND_CREDIT;
    this.loading = false;
  }

  toggleCancelModal({ classId = null }) {
    this.reset();
    this.classIdToCancel = classId;
  }

  handleChange(creditOperation) {
    this.creditOperation = creditOperation;
  }

  classCancel() {
    const params = {
      revoke_credit: false,
    };

    if (this.creditOperation === creditOperations.REVOKE_CREDIT) {
      params.revoke_credit = true;
    }

    this.loading = true;
    ClassesSource.destroy({
      id: this.classIdToCancel,
      params,
      success: ClassCancellationActions.classCancelSuccess,
      error: ClassCancellationActions.classCancelError,
    });
  }

  classCancelSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.classes.classesCancellation.ClassCancelModal.success_message',
    });

    this.loading = false;
    this.toggleCancelModal({});
    MessageWindowActions.addMessage.defer(message);
    redirectTo({ path: customerScopedRoute('classes') });
  }

  classCancelError() {
    this.loading = false;
  }
}

export default alt.createStore(
  ClassCancellationStore,
  'ClassCancellationStore'
);
