import { Map } from 'immutable';

class MembershipEventDiscountCalculator {
  constructor({
    registrationPackage,
    pricingAutomation,
    eventDiscounts,
    customerUsers,
    totalPrice,
    event,
  }) {
    this.registrationPackage = registrationPackage;
    this.pricingAutomation = pricingAutomation;
    this.eventDiscounts = eventDiscounts;
    this.customerUsers = customerUsers;
    this.totalPrice = totalPrice;
    this.event = event;
  }

  discount() {
    if (this.hasPaymentPlan()) {
      return 0;
    }

    return this.eventDiscountMap().reduce(
      (sum, eventDiscount, clientId) =>
        sum + eventDiscount.discount.discountFor(this.costFor(clientId)),
      0
    );
  }

  discountForCustomerUser(clientId) {
    if (this.hasPaymentPlan()) {
      return 0;
    }

    const eventDiscount = this.eventDiscountMap().get(clientId);

    if (eventDiscount) {
      return eventDiscount.discount.discountFor(this.costFor(clientId));
    }
    return 0;
  }

  hasPaymentPlan() {
    return (
      this.pricingAutomation && this.pricingAutomation.isRecurringPaymentPlan()
    );
  }

  eventDiscountMap() {
    if (!this.eventDiscountMapData) {
      const registeredClientIds = this.registrationPackage.tentative_details
        .map(d => d.get('client_id'))
        .toSet();

      const registeredCustomerUsers = this.customerUsers.filter(c =>
        registeredClientIds.has(c.id)
      );

      this.eventDiscountMapData = registeredCustomerUsers.reduce((map, c) => {
        const eventDiscount = this.eventDiscounts.find(
          d => d.membership_id === c.active_membership_id
        );

        return eventDiscount ? map.set(c.id, eventDiscount) : map;
      }, Map());
    }

    return this.eventDiscountMapData;
  }

  costFor(clientId) {
    if (this.registrationPackage.single_session_price) {
      return this.sspCost(clientId);
    }
    return this.nonSSPCost(clientId);
  }

  nonSSPCost(clientId) {
    const totalRegistrations = this.registrationPackage.quantity;

    if (totalRegistrations === 0) {
      return 0;
    }

    const clientRegistrations =
      this.registrationPackage.tentative_details.count(
        d => d.get('client_id') === clientId
      );

    return this.totalPrice * (clientRegistrations / totalRegistrations);
  }

  sspCost(clientId) {
    const sessionCount = this.registrationPackage.tentative_details.count(
      s => s.client_id === clientId
    );
    const isAllSelected = sessionCount === this.event.session_ids.size;
    const isTiersEnabled =
      this.event.isSSPTurnedOn() && this.event.hasPricingTiers();
    const tieredPrice = this.event.getTieredPrice(
      sessionCount,
      this.registrationPackage.single_session_price / 100
    );

    if (isAllSelected) {
      return this.registrationPackage.all_sessions_price;
    }

    if (isTiersEnabled && tieredPrice && !isAllSelected) {
      return sessionCount * tieredPrice * 100;
    }

    return sessionCount * this.registrationPackage.single_session_price;
  }
}

export default MembershipEventDiscountCalculator;
