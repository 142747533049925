export default alt.generateActions(
  'createOrUpdateError',
  'createOrUpdateSuccess',
  'createResourceClicked',
  'editResourceClicked',
  'linkLocationToggled',
  'locationUpdated',
  'nameUpdated',
  'areaUpdated',
  'resourceSaved',
  'staffPreferenceToggled',
  'staffPreferrenceAdded',
  'staffPreferrenceRemoved'
);
