import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Spinner } from '@upperhand/playmaker';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';

import Header from './components/Header/Header.jsx';

function ViewComponent({ header, isLoading, leftColumn, rightColumn }) {
  return (
    <div className="view">
      {isLoading ? (
        <FlexBoxJustifyCenter>
          <Spinner />
        </FlexBoxJustifyCenter>
      ) : (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <Header>{header}</Header>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={3}>
              {leftColumn}
            </Grid>
            <Grid item xs={12} md={9}>
              {rightColumn}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

ViewComponent.propTypes = {
  header: PropTypes.node.isRequired,
  leftColumn: PropTypes.node.isRequired,
  rightColumn: PropTypes.node.isRequired,
};

export default ViewComponent;
