import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const defaultStyle = {
  height: 106,
  width: 97,
};

function Resources({ style, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 97 106" {...props} style={merge(defaultStyle, style)}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-553.000000, -296.000000)">
          <g transform="translate(553.000000, 296.000000)">
            <g transform="translate(30.000000, 0.000000)">
              <mask fill="white">
                <rect x="0" y="0" width="69" height="98" />
              </mask>
              <rect x="0" y="0" width="69" height="98" />
              <g mask="url(#mask-2)">
                <g transform="translate(6.000000, -41.000000)">
                  <g
                    strokeWidth="1"
                    fill="none"
                    transform="translate(0.000000, 62.000000)"
                  >
                    <path
                      d="M46.4988333,49 L11.4988333,49 C9.56683333,49 7.99883333,
                       47.4343333 7.99883333,45.5 C7.99883333,43.5656667 9.56683333,42 11.4988333,
                       42 L46.4988333,42 C48.4331667,42 49.9988333,43.5656667 49.9988333,
                       45.5 C49.9988333,47.4343333 48.4331667,49 46.4988333,49 Z M19.6655,
                       11.6666667 L30.4525,11.6666667 C35.2568333,11.6666667 38.1945,
                       14.8493333 38.1945,19.1893333 C38.1945,23.317 35.6371667,25.5196667 33.1475,
                       26.1146667 L38.3321667,35 L32.6995,35 L28.1705,26.7096667 L24.5748333,
                       26.7096667 L24.5748333,35 L19.6655,35 L19.6655,11.6666667 Z M49.1238333,
                       0 L8.87383333,0 C4.52683333,0 0.998833333,3.52566667 0.998833333,
                       7.875 L0.998833333,48.125 C0.998833333,52.4743333 4.52683333,56 8.87383333,
                       56 L49.1238333,56 C53.4731667,56 56.9988333,52.4743333 56.9988333,
                       48.125 L56.9988333,7.875 C56.9988333,3.52566667 53.4731667,0 49.1238333,
                       0 Z"
                      fill="#BFE7F6"
                    />
                  </g>
                  <path
                    d="M49.125,0 L8.875,0 C4.528,0 1,3.52566667 1,7.875 L1,48.125 C1,52.4743333 4.528,
                     56 8.875,56 L49.125,56 C53.4743333,56 57,52.4743333 57,48.125 L57,7.875 C57,
                     3.52566667 53.4743333,0 49.125,0 Z"
                    fill="#BFE7F6"
                  />
                  <rect
                    fill="var(--color-white)"
                    x="9"
                    y="8"
                    width="40"
                    height="40"
                    rx="2"
                  />
                  <path
                    d="M49.125,124 L8.875,124 C4.528,124 1,127.525667 1,131.875 L1,172.125 C1,
                     176.474333 4.528,180 8.875,180 L49.125,180 C53.4743333,180 57,176.474333 57,
                     172.125 L57,131.875 C57,127.525667 53.4743333,124 49.125,124 Z"
                    fill="#BFE7F6"
                  />
                  <rect
                    fill="var(--color-white)"
                    x="9"
                    y="132"
                    width="40"
                    height="40"
                    rx="2"
                  />
                </g>
              </g>
            </g>
            <g transform="translate(0.000000, 16.000000)">
              <path
                d="M35.9571275,47.5986711 C36.1822282,48.751396 35.7772215,49.8889396 34.8740268,
                 50.6415436 C33.9710067,51.3927517 32.7788456,51.5855705 31.6861477,
                 51.1540403 L29.716255,50.3791007 C27.3134362,49.4343758 24.6680671,49.4343758
                 22.2661208,50.3791007 L20.2974497,51.1540403 C19.2028322,51.5855705 18.0106711,
                 51.3927517 17.1074765,50.6415436 C16.2042819,49.8889396 15.7994497,
                 48.751396 16.0243758,47.5986711 C16.0789933,47.3138926 16.170953,
                 47.0458658 16.2936242,46.7972081 C19.1442013,48.0609128 22.4739463,
                 48.726094 25.9891812,48.726094 C29.5070336,48.726094 32.8360805,
                 48.0609128 35.6880537,46.7984295 C35.8107248,47.0458658 35.9033826,
                 47.3138926 35.9571275,47.5986711 Z"
                fill="#CE0649"
              />
              <path
                d="M46.9771141,28.5656242 C48.5782953,29.0475839 49.7486443,30.5341208 49.7486443,
                 32.2900805 C49.7486443,34.0451678 48.5782953,35.5318792 46.9771141,
                 36.0131409 L46.9771141,28.5656242 Z"
                fill="#FFD8B5"
              />
              <path
                d="M5.00456376,28.5656242 L5.00456376,36.0131409 C3.40338255,35.5318792 2.23373154,
                 34.0451678 2.23373154,32.2900805 C2.23373154,30.5341208 3.40338255,
                 29.0475839 5.00456376,28.5656242 Z"
                fill="#FFD8B5"
              />
              <path
                d="M44.1012349,23.2237584 C44.1012349,20.6063087 42.8185101,18.1980805 40.6685369,
                 16.7837852 C40.5711678,16.7195705 40.4764161,16.6597181 40.3856779,
                 16.6064966 C38.3545369,15.4239329 36.0410604,14.7988859 33.693557,
                 14.7988859 L18.2888188,14.7988859 C16.0577047,14.7988859 13.8466577,
                 15.365302 11.8922953,16.4388054 C9.4174094,17.7986577 7.88026846,
                 20.4024966 7.88026846,23.2345772 L7.88026846,33.8047114 C7.88026846,
                 35.852953 8.62606711,37.9046846 9.98644295,39.5910201 L10.1248188,
                 39.7669128 C12.013745,42.1573423 15.4225369,42.8021074 18.0528993,
                 41.2691544 C20.4611275,39.8663758 23.2052617,39.1247651 25.9917987,
                 39.1247651 C28.7762416,39.1247651 31.5224698,39.8663758 33.929302,
                 41.2691544 C34.8897315,41.8287651 35.9545101,42.0987114 37.0103893,
                 42.0987114 C38.8473154,42.0987114 40.6577181,41.2848591 41.8566846,
                 39.7669128 L42.0032617,39.5814228 C43.3563087,37.9046846 44.1012349,
                 35.852953 44.1012349,33.8047114 L44.1012349,23.2237584 Z M42.8675436,
                 15.8048591 C43.7229262,17.8784027 44.7433826,21.6908054 44.7433826,
                 28.0831409 L44.7433826,43.0249396 C44.7337852,44.5735973 44.2355973,
                 46.0418121 43.3044832,47.2728859 L35.0602148,58.1688054 C32.9226309,
                 60.9926846 29.5328591,62.6790201 25.9917987,62.6790201 C22.4500403,
                 62.6790201 19.059047,60.9926846 16.9221611,58.1688054 L8.67841611,
                 47.2728859 C7.72688591,46.0152886 7.21578523,44.4537181 7.23812081,
                 42.874349 L7.23812081,22.7922282 C7.23812081,19.1721208 8.24287248,
                 15.6338523 10.1447114,12.558349 C12.0245638,9.51477852 14.6992483,
                 7.05210738 17.8797987,5.43591946 C18.6016913,5.06860403 21.0010201,
                 4.0666443 23.8739329,3.19957047 C22.2237181,5.53747651 21.8890336,
                 7.63632215 21.8707114,7.75899329 C21.8024832,8.20954362 22.0158926,
                 8.65660403 22.409906,8.88711409 C22.8018255,9.11884564 23.296,
                 9.08604027 23.6558121,8.8085906 C23.7219463,8.75816107 29.5539732,
                 4.31477852 36.191651,3.30863087 C34.374443,5.7250604 33.3370604,
                 7.96681879 33.282443,8.08826846 C33.0746174,8.5435302 33.1932752,
                 9.08063087 33.5755973,9.40449664 C33.957396,9.72888591 34.5088054,
                 9.75820134 34.9232349,9.47865772 C38.4000805,7.13045638 43.421745,
                 6.80397315 46.3588725,6.84428188 L44.1926711,9.06021477 C42.4374094,
                 10.8563087 41.9167114,13.5030738 42.8675436,15.8048591 Z M36.3035034,
                 52.3585906 C37.8432617,51.0770872 38.533047,49.138604 38.1507248,
                 47.1713289 C37.5951275,44.3181342 34.9061342,42.4251946 32.0351409,
                 42.8621342 C28.0245101,43.4721745 23.9578658,43.4721745 19.9470604,
                 42.8621342 C17.0753691,42.4265906 14.3884698,44.3181342 13.831651,
                 47.1713289 C13.4484564,49.138604 14.1382416,51.0756913 15.6781745,
                 52.3585906 C16.6759463,53.1881477 17.8811946,53.6181074 19.112094,
                 53.6181074 C19.7807651,53.6181074 20.4590336,53.4907248 21.1154899,
                 53.2324698 L23.0839866,52.4580537 C24.9584295,51.7204564 27.0230738,
                 51.7192349 28.8982148,52.4580537 L30.8661879,53.2324698 C32.7310336,
                 53.9671007 34.763745,53.6399195 36.3035034,52.3585906 Z"
                fill="#6E6E6E"
              />
              <g id="Group-8">
                <mask id="mask-4" fill="white">
                  <polygon
                    points="0.0104697987 64.9127517 51.9816779 64.9127517 51.9816779 0.0369439228
                          0.0104697987 0.0369439228"
                  />
                </mask>
                <g />
                <path
                  d="M41.8683758,23.2237584 L41.8683758,33.8047114 C41.8683758,35.3444698 41.2984698,
                   36.8985369 40.2582953,38.1868456 L40.1049128,38.3824564 C38.9019329,
                   39.9052886 36.7294497,40.3169262 35.0541074,39.340094 C32.3057852,
                   37.7376913 29.171651,36.8910336 25.9917987,36.8910336 C22.8100268,
                   36.8910336 19.6779866,37.7376913 16.9289664,39.340094 C15.2520537,
                   40.3169262 13.0818389,39.9052886 11.8765906,38.3824564 L11.7165772,
                   38.1800403 C10.6832081,36.8985369 10.114,35.3444698 10.114,33.8047114 L10.114,
                   23.2345772 C10.114,21.217745 11.206698,19.3637181 12.9671946,
                   18.3978792 C14.5936779,17.5042819 16.433396,17.032443 18.2888188,
                   17.032443 L33.693557,17.032443 C35.646349,17.032443 37.5712215,
                   17.5512215 39.260349,18.5367785 C39.3189799,18.5709799 39.3790067,
                   18.6084966 39.440255,18.6486309 C40.9602953,19.6498926 41.8683758,
                   21.3594362 41.8683758,23.2237584 Z M39.8199597,30.2955839 C39.8199597,
                   29.6801342 39.3203758,29.1784564 38.7035302,29.1784564 C37.2222282,
                   29.1784564 36.0157584,27.9740805 36.0157584,26.4927785 C36.0157584,
                   25.8759329 35.5161745,25.376349 34.8993289,25.376349 C34.2838792,
                   25.376349 33.7828993,25.8759329 33.7828993,26.4927785 C33.7828993,
                   29.2049799 35.9913289,31.4121879 38.7035302,31.4121879 C39.3203758,
                   31.4121879 39.8199597,30.9124295 39.8199597,30.2955839 Z M38.7565772,
                   34.5864564 C38.7565772,33.5073691 37.8814765,32.6322685 36.8016913,
                   32.6322685 C35.7227785,32.6322685 34.8475034,33.5073691 34.8475034,
                   34.5864564 C34.8475034,35.6667651 35.7227785,36.5406443 36.8016913,
                   36.5406443 C37.8814765,36.5406443 38.7565772,35.6667651 38.7565772,
                   34.5864564 Z M18.1994765,26.4927785 C18.1994765,25.8759329 17.6984966,
                   25.376349 17.082349,25.376349 C16.4653289,25.376349 15.965745,
                   25.8759329 15.965745,26.4927785 C15.965745,27.9740805 14.7599732,
                   29.1784564 13.2788456,29.1784564 C12.661302,29.1784564 12.1615436,
                   29.6801342 12.1615436,30.2955839 C12.1615436,30.9124295 12.661302,
                   31.4121879 13.2788456,31.4121879 C15.991047,31.4121879 18.1994765,
                   29.2049799 18.1994765,26.4927785 Z M17.134,34.5864564 C17.134,
                   33.5073691 16.2588993,32.6322685 15.1798121,32.6322685 C14.1008993,
                   32.6322685 13.2256242,33.5073691 13.2256242,34.5864564 C13.2256242,
                   35.6667651 14.1008993,36.5406443 15.1798121,36.5406443 C16.2588993,
                   36.5406443 17.134,35.6667651 17.134,34.5864564 Z"
                  fill="#FFD8B5"
                  mask="url(#mask-4)"
                />
                <path
                  d="M51.9816779,32.2900805 C51.9816779,35.283047 49.8208859,37.7806174 46.9771141,
                   38.3067248 L46.9771141,43.0312215 C46.9635034,45.0733557 46.3098389,
                   47.0022416 45.0841745,48.6219195 L36.841302,59.5155705 C34.2838792,
                   62.895745 30.2280537,64.9127517 25.9917987,64.9127517 C21.7534497,
                   64.9127517 17.6984966,62.895745 15.1410738,59.5155705 L6.89732886,
                   48.6219195 C5.64793289,46.9703087 4.9766443,44.9213691 5.00456376,
                   42.8492215 L5.00456376,38.3067248 C2.16079195,37.7806174 0,35.283047 0,
                   32.2900805 C0,29.2957181 2.16079195,26.7995436 5.00456376,26.2720403 L5.00456376,
                   22.7922282 C5.00456376,18.7576913 6.1257047,14.8124966 8.24426846,
                   11.3838121 C10.3417181,7.99212081 13.3231678,5.24711409 16.8675436,
                   3.44491275 C18.0038658,2.86767785 22.5564832,0.99114094 27.168255,
                   0.0586308725 C27.6781342,-0.0429261745 28.1915034,0.219516779 28.4068322,
                   0.692577181 C28.6214631,1.16703356 28.4804698,1.72524832 28.0674362,
                   2.04213423 C26.8152483,2.99715436 25.945557,4.06385235 25.3470336,
                   5.03248322 C28.4293423,3.23167785 33.4740403,0.875275168 38.7647785,
                   0.875275168 C39.2127114,0.875275168 39.6161477,1.14173154 39.7920403,
                   1.55214765 C39.9679329,1.96448322 39.8813826,2.44016107 39.5718255,
                   2.76402685 C38.5834765,3.79739597 37.7389128,4.93092617 37.0690201,
                   5.94510067 C42.5157584,4.01063087 48.6967785,4.71681879 49.011047,
                   4.75433557 C49.4343758,4.8047651 49.7901745,5.09111409 49.9325638,
                   5.49315436 C50.0737315,5.89397315 49.9742685,6.34051007 49.6771007,
                   6.64448322 L45.7889664,10.6203893 C44.6603221,11.7778255 44.3221477,
                   13.4777718 44.9321879,14.9522685 C46.3628859,18.4196913 46.8373423,
                   22.7554094 46.9491946,26.2678523 C49.8072752,26.7831409 51.9816779,
                   29.2861208 51.9816779,32.2900805 Z M49.7486443,32.2900805 C49.7486443,
                   30.5341208 48.5782953,29.0475839 46.9771141,28.5656242 L46.9771141,
                   36.0131409 C48.5782953,35.5318792 49.7486443,34.0451678 49.7486443,
                   32.2900805 Z M44.1926711,9.06021477 L46.3588725,6.84428188 C43.421745,
                   6.80397315 38.4000805,7.13045638 34.9232349,9.47865772 C34.5088054,
                   9.75820134 33.957396,9.72888591 33.5755973,9.40449664 C33.1932752,
                   9.08063087 33.0746174,8.5435302 33.282443,8.08826846 C33.3370604,
                   7.96681879 34.374443,5.7250604 36.191651,3.30863087 C29.5539732,
                   4.31477852 23.7219463,8.75816107 23.6558121,8.8085906 C23.296,
                   9.08604027 22.8018255,9.11884564 22.409906,8.88711409 C22.0158926,
                   8.65660403 21.8024832,8.20954362 21.8707114,7.75899329 C21.8890336,
                   7.63632215 22.2237181,5.53747651 23.8739329,3.19957047 C21.0010201,
                   4.0666443 18.6016913,5.06860403 17.8797987,5.43591946 C14.6992483,
                   7.05210738 12.0245638,9.51477852 10.1447114,12.558349 C8.24287248,
                   15.6338523 7.23812081,19.1721208 7.23812081,22.7922282 L7.23812081,
                   42.874349 C7.21578523,44.4537181 7.72688591,46.0152886 8.67841611,
                   47.2728859 L16.9221611,58.1688054 C19.059047,60.9926846 22.4500403,
                   62.6790201 25.9917987,62.6790201 C29.5328591,62.6790201 32.9226309,
                   60.9926846 35.0602148,58.1688054 L43.3044832,47.2728859 C44.2355973,
                   46.0418121 44.7337852,44.5735973 44.7433826,43.0249396 L44.7433826,
                   28.0831409 C44.7433826,21.6908054 43.7229262,17.8784027 42.8675436,
                   15.8048591 C41.9167114,13.5030738 42.4374094,10.8563087 44.1926711,
                   9.06021477 Z M5.00456376,36.0131409 L5.00456376,28.5656242 C3.40338255,
                   29.0475839 2.23373154,30.5341208 2.23373154,32.2900805 C2.23373154,
                   34.0451678 3.40338255,35.5318792 5.00456376,36.0131409 Z"
                  fill="#424242"
                  mask="url(#mask-4)"
                />
              </g>
              <path
                d="M44.1012349,23.2237584 L44.1012349,33.8047114 C44.1012349,35.852953 43.3563087,
                 37.9046846 42.0032617,39.5814228 L41.8566846,39.7669128 C40.6577181,
                 41.2848591 38.8473154,42.0987114 37.0103893,42.0987114 C35.9545101,
                 42.0987114 34.8897315,41.8287651 33.929302,41.2691544 C31.5224698,
                 39.8663758 28.7762416,39.1247651 25.9917987,39.1247651 C23.2052617,
                 39.1247651 20.4611275,39.8663758 18.0528993,41.2691544 C15.4225369,
                 42.8021074 12.013745,42.1573423 10.1248188,39.7669128 L9.98644295,
                 39.5910201 C8.62606711,37.9046846 7.88026846,35.852953 7.88026846,
                 33.8047114 L7.88026846,23.2345772 C7.88026846,20.4024966 9.4174094,
                 17.7986577 11.8922953,16.4388054 C13.8466577,15.365302 16.0577047,
                 14.7988859 18.2888188,14.7988859 L33.693557,14.7988859 C36.0410604,
                 14.7988859 38.3545369,15.4239329 40.3856779,16.6064966 C40.4764161,
                 16.6597181 40.5711678,16.7195705 40.6685369,16.7837852 C42.8185101,
                 18.1980805 44.1012349,20.6063087 44.1012349,23.2237584 Z M41.8683758,
                 33.8047114 L41.8683758,23.2237584 C41.8683758,21.3594362 40.9602953,
                 19.6498926 39.440255,18.6486309 C39.3790067,18.6084966 39.3189799,
                 18.5709799 39.260349,18.5367785 C37.5712215,17.5512215 35.646349,
                 17.032443 33.693557,17.032443 L18.2888188,17.032443 C16.433396,
                 17.032443 14.5936779,17.5042819 12.9671946,18.3978792 C11.206698,
                 19.3637181 10.114,21.217745 10.114,23.2345772 L10.114,33.8047114 C10.114,
                 35.3444698 10.6832081,36.8985369 11.7165772,38.1800403 L11.8765906,
                 38.3824564 C13.0818389,39.9052886 15.2520537,40.3169262 16.9289664,
                 39.340094 C19.6779866,37.7376913 22.8100268,36.8910336 25.9917987,
                 36.8910336 C29.171651,36.8910336 32.3057852,37.7376913 35.0541074,
                 39.340094 C36.7294497,40.3169262 38.9019329,39.9052886 40.1049128,
                 38.3824564 L40.2582953,38.1868456 C41.2984698,36.8985369 41.8683758,
                 35.3444698 41.8683758,33.8047114 Z"
                fill="#424242"
              />
              <path
                d="M38.7035302,29.1784564 C39.3203758,29.1784564 39.8199597,29.6801342 39.8199597,
                 30.2955839 C39.8199597,30.9124295 39.3203758,31.4121879 38.7035302,
                 31.4121879 C35.9913289,31.4121879 33.7828993,29.2049799 33.7828993,
                 26.4927785 C33.7828993,25.8759329 34.2838792,25.376349 34.8993289,
                 25.376349 C35.5161745,25.376349 36.0157584,25.8759329 36.0157584,
                 26.4927785 C36.0157584,27.9740805 37.2222282,29.1784564 38.7035302,
                 29.1784564 Z"
                fill="#424242"
              />
              <path
                d="M36.8016913,32.6322685 C37.8814765,32.6322685 38.7565772,33.5073691 38.7565772,
                 34.5864564 C38.7565772,35.6667651 37.8814765,36.5406443 36.8016913,
                 36.5406443 C35.7227785,36.5406443 34.8475034,35.6667651 34.8475034,
                 34.5864564 C34.8475034,33.5073691 35.7227785,32.6322685 36.8016913,
                 32.6322685 Z"
                fill="#8B572A"
              />
              <path
                d="M38.1507248,47.1713289 C38.533047,49.138604 37.8432617,51.0770872 36.3035034,
                52.3585906 C34.763745,53.6399195 32.7310336,53.9671007 30.8661879,
                53.2324698 L28.8982148,52.4580537 C27.0230738,51.7192349 24.9584295,
                51.7204564 23.0839866,52.4580537 L21.1154899,53.2324698 C20.4590336,
                53.4907248 19.7807651,53.6181074 19.112094,53.6181074 C17.8811946,
                53.6181074 16.6759463,53.1881477 15.6781745,52.3585906 C14.1382416,
                51.0756913 13.4484564,49.138604 13.831651,47.1713289 C14.3884698,
                44.3181342 17.0753691,42.4265906 19.9470604,42.8621342 C23.9578658,
                43.4721745 28.0245101,43.4721745 32.0351409,42.8621342 C34.9061342,
                42.4251946 37.5951275,44.3181342 38.1507248,47.1713289 Z M34.8740268,
                50.6415436 C35.7772215,49.8889396 36.1822282,48.751396 35.9571275,
                47.5986711 C35.9033826,47.3138926 35.8107248,47.0458658 35.6880537,
                46.7984295 C32.8360805,48.0609128 29.5070336,48.726094 25.9891812,
                48.726094 C22.4739463,48.726094 19.1442013,48.0609128 16.2936242,
                46.7972081 C16.170953,47.0458658 16.0789933,47.3138926 16.0243758,
                47.5986711 C15.7994497,48.751396 16.2042819,49.8889396 17.1074765,
                50.6415436 C18.0106711,51.3927517 19.2028322,51.5855705 20.2974497,
                51.1540403 L22.2661208,50.3791007 C24.6680671,49.4343758 27.3134362,
                49.4343758 29.716255,50.3791007 L31.6861477,51.1540403 C32.7788456,
                51.5855705 33.9710067,51.3927517 34.8740268,50.6415436 Z M33.7616107,
                45.1681074 C33.3248456,45.0372349 32.8517852,44.9976242 32.3698255,
                45.0705638 C28.1368859,45.7141074 23.8446174,45.7141074 19.6118523,
                45.0705638 C19.4488725,45.0454362 19.2879866,45.033745 19.1277987,
                45.033745 C18.8163221,45.033745 18.512349,45.081557 18.2233826,
                45.1681074 C20.5558792,46.0282013 23.2441745,46.4930604 25.9891812,
                46.4930604 C28.7375034,46.4930604 31.4291141,46.0269799 33.7616107,
                45.1681074 Z"
                fill="#424242"
              />
              <path
                d="M17.082349,25.376349 C17.6984966,25.376349 18.1994765,25.8759329 18.1994765,
                 26.4927785 C18.1994765,29.2049799 15.991047,31.4121879 13.2788456,
                 31.4121879 C12.661302,31.4121879 12.1615436,30.9124295 12.1615436,
                 30.2955839 C12.1615436,29.6801342 12.661302,29.1784564 13.2788456,
                 29.1784564 C14.7599732,29.1784564 15.965745,27.9740805 15.965745,
                 26.4927785 C15.965745,25.8759329 16.4653289,25.376349 17.082349,25.376349 Z"
                fill="#424242"
              />
              <path
                d="M15.1798121,32.6322685 C16.2588993,32.6322685 17.134,33.5073691 17.134,
                 34.5864564 C17.134,35.6667651 16.2588993,36.5406443 15.1798121,
                 36.5406443 C14.1008993,36.5406443 13.2256242,35.6667651 13.2256242,
                 34.5864564 C13.2256242,33.5073691 14.1008993,32.6322685 15.1798121,32.6322685 Z"
                fill="#8B572A"
              />
            </g>
            <path
              d="M69.1825,40.1884 C69.1825,38.2284 67.6961667,37.0384 65.7268333,37.0384 L60.5771667,
               37.0384 L60.5771667,43.3360667 L65.7268333,43.3360667 C67.6961667,43.3360667 69.1825,
               42.1460667 69.1825,40.1884"
              fill="#BFE7F6"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Resources;
