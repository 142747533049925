import SettingTabsActions from 'shared/actions/SettingTabsActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

export const SETTING_TABS = {
  Accounting: 'accounting',
  Balance: 'balance',
  Billing: 'billing',
  BusinessInfo: 'business_info',
  DirectDeposit: 'direct_deposit',
  Locations: 'locations',
  PolicyTerms: 'policy_and_terms',
  Preferences: 'preferences',
  TimeTracking: 'time_tracking',
  Config: 'config',
};

class SettingTabsStore extends UpperHandStore {
  constructor() {
    super();
    this.activeTab = SETTING_TABS.BusinessInfo;

    this.bindListeners({
      changeTab: SettingTabsActions.CHANGE_TAB,
    });
  }

  changeTab([value]) {
    this.activeTab = value || 'business_info';
  }
}

export default alt.createStore(SettingTabsStore, 'SettingTabsStore');
