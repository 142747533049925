import * as React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import SalesDetailActions from 'reporting/sales_detail/actions/SalesDetailActions.js';
import DateRangeDropdown from 'components/DateRangeDropdown';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  clearFilterButton: {
    backgroundColor: 'white',
    borderRadius: 500,
    flex: '0 0 auto',
    margin: '0 8px 6px 8px',
    fontWeight: 'bold',
  },
};

const resetFilters = e => {
  e.preventDefault();
  SalesDetailActions.resetFilters();
};

function SalesFilters({ filters, filtersApplied, intl }) {
  return (
    <div className="sales-report__filters-container">
      <div className="sales-report__filters">
        <DateRangeDropdown
          label={false}
          value={filters.getIn(['dateRange', 'value'])}
          onChange={SalesDetailActions.dateFilterUpdated}
        />
      </div>
      {filtersApplied && (
        <Button onClick={resetFilters} style={styles.clearFilterButton}>
          {t('.reset_filters', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

export default injectIntl(SalesFilters);
