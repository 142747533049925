import { List } from 'immutable';
import { RetailVendor } from 'records';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import RetailVendorsActions from './RetailVendorsActions';

const parserTo = { type: RetailVendor, paginationKey: 'retail_vendors' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([RetailVendorsActions.fetchSuccess, success])
    ),
    error: onError(List([RetailVendorsActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([RetailVendorsActions.listSuccess, success])
    ),
    error: onError(List([RetailVendorsActions.listError, error])),
  });
};

export const post = ({ params, success, error }) => {
  uhApiClient.post({
    url: parserTo.paginationKey,
    data: JSON.stringify({ attributes: params }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([RetailVendorsActions.createSuccess, success])
    ),
    error: onError(List([RetailVendorsActions.createError, error])),
  });
};

export const patch = ({ id, params, success, error }) => {
  uhApiClient.patch({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify({ attributes: params }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([RetailVendorsActions.updateSuccess, success])
    ),
    error: onError(List([RetailVendorsActions.updateError, error])),
  });
};

export const getRetailCategoryList = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'retail_categories',
    data: params,
    success: onSuccess(
      data => data,
      List([RetailVendorsActions.listCategoriesSuccess, success])
    ),
    error: onError(List([RetailVendorsActions.listCategoriesSuccess, error])),
  });
};
