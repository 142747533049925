/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { injectIntl } from 'react-intl';

import AlteredField from 'onboarding/components/AccountSetupPaysafe/fields/_TextField.jsx';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';
import StateField from 'onboarding/components/AccountSetupPaysafe/fields/_StateField.jsx';
import { Dropdown, TextField, Grid } from '@upperhand/playmaker';
import { states } from 'shared/components/StateSelector.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

export const IdentityAddressField = injectIntl(
  ({ action, index, intl, owner, countryCode = 'US' }) => (
    <Grid container item spacing={2} direction="column">
      <Grid item>
        <h3 style={Styles.header3}>{t('.address', intl, __filenamespace)}</h3>
      </Grid>
      <Grid item>
        <TextField
          errorText={owner.errors.getErrors(['address', 'street'], intl)}
          label={t('.street_label', intl, __filenamespace)}
          fullWidth
          name="street"
          onChange={(_, value) =>
            action({ keyPath: ['address', 'street'], value, index })
          }
          value={owner.address.street}
        />
      </Grid>
      <Grid item>
        <TextField
          errorText={owner.errors.getErrors(['address', 'street2'], intl)}
          label={t('.street2_label', intl, __filenamespace)}
          fullWidth
          name="street2"
          onChange={(_, value) =>
            action({
              keyPath: ['address', 'street2'],
              value,
              index,
            })
          }
          value={owner.address.street2}
        />
      </Grid>
      <Grid item>
        <TextField
          errorText={owner.errors.getErrors(['address', 'city'], intl)}
          label={t('.city_label', intl, __filenamespace)}
          fullWidth
          name="city"
          onChange={(_, value) =>
            action({ keyPath: ['address', 'city'], value, index })
          }
          value={owner.address.city}
        />
      </Grid>
      <Grid item>
        <Dropdown
          errorText={owner.errors.getErrors(['address', 'state'], intl)}
          label={t('.state_label', intl, __filenamespace)}
          fullWidth
          name="state"
          onChange={e => {
            action({
              keyPath: ['address', 'state'],
              value: e.target.value,
              index,
            });
          }}
          value={owner.address.state}
          items={states
            .get(countryCode)
            .map((name, abbrev) => ({ label: name, value: abbrev }))
            .toArray()}
        />
      </Grid>
      <Grid item>
        <TextField
          errorText={owner.errors.getErrors(['address', 'zip'], intl)}
          label={t('.zip_label', intl, __filenamespace)}
          fullWidth
          name="zip"
          onChange={(_, value) =>
            action({ keyPath: ['address', 'zip'], value, index })
          }
          value={owner.address.zip}
        />
      </Grid>
    </Grid>
  )
);

const AddressField = injectIntl(({ intl, prefix, propsForField }) => (
  <Grid container item spacing={2} direction="column">
    <Grid item>
      <h3 style={{ ...Styles.header3, position: 'relative' }}>
        {t('.address', intl, __filenamespace)}
        {prefix === 'Business' && (
          <>
            <InfoIcon
              data-for="business_address"
              data-tip
              style={Styles.infoIcon}
            />
            <ReactTooltip
              id="business_address"
              effect="solid"
              className="charge-failure-tooltip uh-tooltip"
            >
              <span style={{ fontWeight: 'normal' }}>
                {t('.business_address_tooltip', intl, __filenamespace)}
              </span>
            </ReactTooltip>
          </>
        )}
      </h3>
    </Grid>
    <Grid item>
      <AlteredField {...propsForField(`${prefix}Street`)} />
    </Grid>
    <Grid item>
      <AlteredField {...propsForField(`${prefix}Street2`)} />
    </Grid>
    <Grid item>
      <AlteredField {...propsForField(`${prefix}City`)} />
    </Grid>
    <Grid item>
      <StateField {...propsForField(`${prefix}State`)} />
    </Grid>
    <Grid item>
      <AlteredField {...propsForField(`${prefix}Zip`)} />
    </Grid>
  </Grid>
));

export default injectIntl(AddressField);
