import React from 'react';
import { List } from 'immutable';
import moment from 'moment-timezone';
import { Button, Card, Grid, Typography } from '@upperhand/playmaker';
import clsx from 'clsx';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PaymentTitle from 'containers/reports/paymentPlanDetailsDrawer/components/PaymentTitle.jsx';
import PaymentDetailsDrawerActions from 'containers/reports/paymentDetailsDrawer/Actions';

import { t } from 'shared/utils/LocaleUtils.js';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { Box } from '@mui/material';

function DetailItemContainer({ children, onClick }) {
  if (onClick) {
    return (
      <Button
        onClick={onClick}
        type="tertiary"
        classes={{ root: 'scheduled-payments__view-button-wrapper' }}
      >
        {children}
      </Button>
    );
  }

  return <Box>{children}</Box>;
}

function ScheduledPayment({ scheduledPayment, balance, intl }) {
  // Quick calculation of status for now.
  // Future consideration could make this much nicer to deal with.
  const details = balance ? balance.get('details') : List();
  const hasDetails = details.size > 0;
  const waivedAmount = details
    .filter(d => d.get('type') === 'DebitForgiveness')
    .reduce((sum, x) => sum + x.get('amount'), 0);
  const fullyWaivedStatus =
    waivedAmount === scheduledPayment.get('amount') && 'fully_waived';
  const waivedStatus =
    fullyWaivedStatus || (waivedAmount > 0 && 'partially_waived');
  const amount = waivedStatus
    ? scheduledPayment.amount - waivedAmount
    : scheduledPayment.amount;
  return (
    <Card
      classes={{
        root: clsx(
          'scheduled-payments__card',
          scheduledPayment.isScheduled && 'scheduled-payments__scheduled'
        ),
      }}
      contentDirection="column"
    >
      <DetailItemContainer
        onClick={
          hasDetails
            ? () => {
                PaymentDetailsDrawerActions.openDrawer(
                  balance.get('compoundId')
                );
              }
            : null
        }
      >
        <Grid container xs={12} spacing={1}>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <PaymentTitle
                variant="subtitle2"
                className="scheduled-payments__title"
                waivedStatus={waivedStatus}
                payment={scheduledPayment}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                align="right"
                className={clsx(
                  scheduledPayment.isOutstanding &&
                    'scheduled-payments__outstanding_balance'
                )}
              >
                <FormattedCurrency value={amount ?? 0} fromCents />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body2" align="right">
                {scheduledPayment.dueDate
                  ? moment(scheduledPayment.dueDate).format('MMM DD, YYYY')
                  : '-'}
              </Typography>
            </Grid>
            <Grid item />
            <Grid item>
              {hasDetails && (
                <Typography
                  variant="subtitle2"
                  align="right"
                  className="scheduled-payments__view-plan"
                >
                  {t('.view', intl, __filenamespace)}
                  <ChevronRightIcon />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DetailItemContainer>
    </Card>
  );
}

function ScheduledPaymentsInfo({ intl, scheduledPayments, balances }) {
  return (
    <>
      <Typography className="scheduled-payments__header" variant="subtitle1">
        {t('.title_details', intl, __filenamespace)}
      </Typography>
      {scheduledPayments.map(
        sp =>
          sp.isScheduled && (
            <ScheduledPayment
              key={sp.id}
              scheduledPayment={sp}
              balance={balances.find(b => b.get('productId') === sp.get('id'))}
              intl={intl}
            />
          )
      )}
      {scheduledPayments.map(
        sp =>
          !sp.isScheduled && (
            <ScheduledPayment
              key={sp.id}
              scheduledPayment={sp}
              balance={balances.find(b => b.get('productId') === sp.get('id'))}
              intl={intl}
            />
          )
      )}
    </>
  );
}

export default ScheduledPaymentsInfo;
