import { Map } from 'immutable';

import { redirectTo } from 'shared/utils/RouteUtils';
import { setActiveToken, clearStorage } from 'shared/utils/AuthUtils.js';
import NativeBiometric from 'shared/native/BiometricAuthenticator.js';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import AuthActions from 'shared/actions/AuthActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UserActions from 'shared/actions/UserActions.jsx';
import ResendAccountVerificationActions from 'shared/actions/ResendAccountVerificationActions.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import UserCreationStore from 'user_management/shared/stores/UserCreationStore.jsx';
import ModalRegistrationStore from 'shared/components/signUp/Store';

import { RoleSource } from 'sources';

const loginComplete = (redirectAfterLogin, redirectPath) => {
  if (redirectAfterLogin && !redirectPath) {
    const path = '/accounts/choose';
    const query = window.location.search;

    redirectTo({
      path: query ? path + query : path,
    });
  }

  if (redirectAfterLogin && redirectPath) {
    redirectTo({
      path: redirectPath,
      refreshPage: true,
    });
  }
};

class AuthStore extends UpperHandStore {
  constructor() {
    super();
    this.authenticationAttempted = false;
    this.authenticated = false;
    this.authenticating = false;
    this.biometricMethod = 'none';
    this.showBiometricOption = false;
    this.credentials = Map();
    this.showResetLink = false;
    this.showCustomerStep = false;
    this.bindListeners({
      handleUpdateStore: AuthActions.updateStore,
      handleAuthenticate: AuthActions.authenticate,
      handleAutomaticLogin: AuthActions.attemptAutomaticLogin,
      handleAuthenticateSuccess: [
        AuthActions.authenticateSuccess,
        UserActions.createSuccess,
      ],
      handleAuthenticateError: AuthActions.authenticateError,
      roleListSuccess: AuthActions.roleListSuccess,
      roleListError: AuthActions.roleListError,
      handleResetCredentials: ResendAccountVerificationActions.backToLogin,
    });
  }

  handleResetCredentials() {
    this.showResetLink = false;
    this.authenticationAttempted = false;
    this.loginErrorText = '';
    this.credentials = Map();
  }

  handleUpdateStore(data) {
    this.credentials = this.credentials.merge(data);
  }

  handleAuthenticate({ redirectAfterLogin = true }) {
    this.authenticationAttempted = true;
    this.authenticating = true;

    const auth = `${this.credentials.get('email')}:${this.credentials.get(
      'password'
    )}`;
    const { redirectTo: redirect } = ModalRegistrationStore.getState();

    return uhApiClient.get({
      url: 'auth',
      headers: { Authorization: `Basic ${btoa(auth)}` },
      success: {
        action: AuthActions.authenticateSuccess,
        args: [
          {
            redirectAfterLogin: Boolean(redirect) || redirectAfterLogin,
            redirectPath: redirect,
          },
        ],
      },
      error: AuthActions.authenticateError,
    });
  }

  handleAutomaticLogin() {
    NativeBiometric.supportedMethod()
      .then(method => {
        if (method === NativeBiometric.NONE) {
          throw new Error('Biometrics not supported');
        }
      })
      .then(() => NativeBiometric.fetchCredentials('Authenticate to login'))
      .then(credentials => {
        this.credentials = this.credentials.merge(credentials);
        this.handleAuthenticate({});
      })
      .catch(error => {
        if (error.message === 'Biometrics not supported') {
          // Ignore
        } else if (error.message === 'Key does not exist') {
          // Ignore
        } else {
          // Initial attempt failed
          this.activateBiometricOption();
        }
      });
  }

  activateBiometricOption() {
    Promise.all([
      NativeBiometric.hasCredentials(),
      NativeBiometric.supportedMethod(),
    ])
      .then(([credentialsStored, method]) => {
        this.setState({
          biometricMethod: method,
          showBiometricOption:
            credentialsStored && method !== NativeBiometric.NONE,
        });
      })
      .catch(() => {
        /* Consume any errors */
      });
  }

  handleAuthenticateSuccess([data, { redirectAfterLogin, redirectPath }]) {
    if (data.code === 2201) {
      MessageWindowActions.addMessage.defer(
        'Given email address is already in use. Try another one or log in.'
      );
      this.authenticated = false;
      this.authenticating = false;
    } else if (data.code === 2202) {
      setTimeout(() => {
        redirectTo({
          path: `/accounts/verification_sent?to=${encodeURIComponent(
            data.email
          )}`,
        });
      }, 0);
    } else if (data) {
      setActiveToken(data);

      RoleSource.list({
        success: {
          action: AuthActions.roleListSuccess,
          args: [{ redirectAfterLogin, redirectPath }],
        },
        error: AuthActions.roleListError,
      });
    } else {
      this.authenticated = false;
      this.authenticating = false;
    }
  }

  roleListSuccess([{ roles }, { redirectAfterLogin, redirectPath }]) {
    if (roles.size === 0) {
      clearStorage();
      this.authenticated = false;
      this.authenticating = false;
      this.loginErrorText = '.empty_roles';
    } else {
      this.authenticated = true;
      this.showCustomerStep = true;

      this.storeCredentials()
        .then(() => {
          loginComplete(redirectAfterLogin, redirectPath);
          this.credentials = Map();
        })
        .catch(() => {
          loginComplete(redirectAfterLogin, redirectPath);
          this.credentials = Map();
        });
    }
  }

  roleListError() {
    this.authenticated = false;
    this.authenticating = false;
  }

  storeCredentials() {
    // Read credentials from new user object
    if (this.credentials.isEmpty()) {
      const { newUser } = UserCreationStore.getState();
      this.credentials = Map({
        email: newUser.email,
        password: newUser.password,
      });
    }

    return NativeBiometric.store(
      this.credentials.get('email'),
      this.credentials.get('password')
    );
  }

  handleAuthenticateError(data, ...args) {
    switch (data.response.status) {
      case 401:
        this.loginErrorText = '.not_recognized';
        this.showResetLink = false;
        break;
      case 403:
        this.loginErrorText = '.awaiting_verification';
        this.showResetLink = true;
        break;
      default:
        this.loginErrorText = data.response.responseText;
        this.notifyError(this.loginErrorText, args);
    }
    this.authenticated = false;
    this.authenticating = false;
    this.credentials = this.credentials.delete('password');
  }
}

export default alt.createStore(AuthStore, 'AuthStore');
