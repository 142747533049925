import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { List, Set, fromJS } from 'immutable';
import RevenueReportActions from 'reporting/revenue/actions/_RevenueReportActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const filterProperties = [
  'product',
  'productType',
  'startDate',
  'endDate',
  'buyer',
  'client',
  'customerIds',
];

function midnightLocally(moment) {
  return moment.tz(customerTZ(), true).startOf('day');
}

class RevenueReportStore {
  constructor() {
    this.totalsByMonth = List();
    this.isTotalsByMonthLoading = false;
    this.defaultCustomerIds = Set();
    this.transactionCountsByMonth = [];
    this.cumulativeTransactionCountsByMonth = [];
    this.revenueYearOverYear = [];
    this.revenueByMonth = [];
    this.cumulativeRevenueByMonth = [];
    this.transactionCountsYearOverYear = [];

    this.initializeFilters();

    this.bindListeners({
      loadData: RevenueReportActions.MOUNTED,
      setTotalsByMonthResult: RevenueReportActions.TOTALS_BY_MONTH_LOADED,
      setTotalsByMonthError: RevenueReportActions.TOTALS_BY_MONTH_ERROR,
      updateFilters: RevenueReportActions.FILTER_UPDATED,
      applyFilters: RevenueReportActions.APPLY_FILTERS,
      clearFilters: RevenueReportActions.CLEAR_FILTERS,
    });
  }

  initializeFilters() {
    this.isApplyingFilter = false;
    this.isFilterDirty = false;
    this.appliedFilters = {};
    filterProperties.forEach(p => {
      this[p] = null;
    });
    filterProperties.forEach(p => {
      this.appliedFilters[p] = this[p];
    });
    this.customerIds = this.defaultCustomerIds;
    this.updateStates();
  }

  loadData() {
    if (this.defaultCustomerIds.isEmpty()) {
      this.setDefaultCustomerIds();
    }
    if (currentUser().isAdmin() && !this.appliedFilters.customerIds.size) {
      this.totalsByMonth = List();
      this.buildCharts();
      this.canApplyFilter = false;
      return;
    }
    this.fetchTotalsByMonth();
  }

  fetchTotalsByMonth() {
    this.isTotalsByMonthLoading = true;
    this.updateStates();
    return uhApiClient.get({
      url: 'analytics/revenue/totals_by_month',
      data: this.getRequestParams(),
      success: RevenueReportActions.totalsByMonthLoaded,
      error: RevenueReportActions.totalsByMonthError,
    });
  }

  setTotalsByMonthResult(data) {
    const records = data.records.map(record => ({
      month: record.month,
      year: record.year,
      count: record.count,
      revenue: record.revenue,
      monthValue: record.year * 12 + record.month,
    }));
    this.totalsByMonth = fromJS(records);
    this.buildCharts();
    this.isTotalsByMonthLoading = false;
    this.updateStates();
  }

  buildCharts() {
    this.buildTransactionCountsByMonth();
    this.buildCumulativeTransactionCountsByMonth();
    this.buildRevenueByMonth();
    this.buildCumulativeRevenueByMonth();
    this.buildTransactionCountsYearOverYear();
    this.buildRevenueYearOverYear();
  }

  setTotalsByMonthError() {
    this.isTotalsByMonthLoading = false;
    this.updateStates();
  }

  buildTransactionCountsByMonth() {
    const counts = this.totalsByMonth.map(t => t.get('count'));
    const minRadius = 5;
    const maxRadius = 20;
    const minCount = Math.min(...counts);
    const maxCount = Math.max(...counts);
    const getRadius = count =>
      Math.round(
        ((count - minCount) / (maxCount - minCount)) * (maxRadius - minRadius) +
          minRadius
      );
    this.transactionCountsByMonth = this.totalsByMonth
      .map(t => ({
        x: t.get('monthValue'),
        y: t.get('count'),
        r: getRadius(t.get('count')),
      }))
      .toArray();
  }

  buildCumulativeTransactionCountsByMonth() {
    const months = this.totalsByMonth.map(t => t.get('monthValue')).toArray();
    const minMonth = Math.min(...months);
    const maxMonth = Math.max(...months);

    const values = [];
    let total = 0;
    for (let i = minMonth; i <= maxMonth; i += 1) {
      const record = this.totalsByMonth.find(t => t.get('monthValue') === i);
      const value = record ? record.get('count') : 0;
      values.push({ month: i, count: total + value });
      total += value;
    }
    this.cumulativeTransactionCountsByMonth = values;
  }

  buildRevenueByMonth() {
    const values = this.totalsByMonth.map(t => t.get('revenue'));
    const minRadius = 5;
    const maxRadius = 20;
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const getRadius = value =>
      Math.round(
        ((value - minValue) / (maxValue - minValue)) * (maxRadius - minRadius) +
          minRadius
      );
    this.revenueByMonth = this.totalsByMonth
      .map(t => ({
        x: t.get('monthValue'),
        y: t.get('revenue'),
        r: getRadius(t.get('revenue')),
      }))
      .toArray();
  }

  buildCumulativeRevenueByMonth() {
    const months = this.totalsByMonth.map(t => t.get('monthValue')).toArray();
    const minMonth = Math.min(...months);
    const maxMonth = Math.max(...months);

    const values = [];
    let total = 0;
    for (let i = minMonth; i <= maxMonth; i += 1) {
      const record = this.totalsByMonth.find(t => t.get('monthValue') === i);
      const value = record ? record.get('revenue') : 0;
      values.push({ month: i, count: total + value });
      total += value;
    }
    this.cumulativeRevenueByMonth = values;
  }

  buildTransactionCountsYearOverYear() {
    const transactionsByYear = {};
    this.totalsByMonth.forEach(t => {
      const year = String(t.get('year'));
      transactionsByYear[year] = transactionsByYear[year] || Array(12).fill(0);
      const month = t.get('month') - 1;
      transactionsByYear[year][month] = t.get('count');
    });
    const years = Object.keys(transactionsByYear).sort();
    const result = years.map(year => ({
      year: parseInt(year, 10),
      transactions: transactionsByYear[year],
    }));
    this.transactionCountsYearOverYear = result;
  }

  buildRevenueYearOverYear() {
    const revenueByYear = {};
    this.totalsByMonth.forEach(t => {
      const year = String(t.get('year'));
      revenueByYear[year] = revenueByYear[year] || Array(12).fill(0);
      const month = t.get('month') - 1;
      revenueByYear[year][month] = t.get('revenue');
    });
    const years = Object.keys(revenueByYear).sort();
    const result = years.map(year => ({
      year: parseInt(year, 10),
      revenue: revenueByYear[year],
    }));
    this.revenueYearOverYear = result;
  }

  updateFilters([filter, value]) {
    this[filter] = filter === 'customerIds' ? Set(value) : value;
    this.updateStates();
  }

  clearFilters() {
    filterProperties.forEach(p => {
      this[p] = null;
    });
    this.customerIds = this.defaultCustomerIds;
    this.updateStates();
    this.applyFilters();
  }

  getRequestParams() {
    const result = {};
    const filters = this.appliedFilters;
    if (filters.startDate) {
      result.start_date = midnightLocally(filters.startDate).toISOString();
    }
    if (filters.endDate) {
      result.end_date = midnightLocally(filters.endDate).toISOString();
    }
    if (filters.client) {
      result.client = filters.client;
    }
    if (filters.buyer) {
      result.buyer = filters.buyer;
    }
    if (filters.product) {
      result.product = filters.product;
    }
    if (filters.productType) {
      result.product_type = filters.productType;
    }
    if (currentUser().isAdmin() && filters.customerIds.size) {
      result.customer_ids = filters.customerIds.toJS();
    }
    return result;
  }

  applyFilters() {
    this.appliedFilters = {};
    filterProperties.forEach(p => {
      this.appliedFilters[p] = this[p];
    });
    this.loadData();
  }

  updateStates() {
    this.isFilterDirty =
      filterProperties.some(p => this.appliedFilters[p] !== this[p]) ||
      !this.appliedFilters.customerIds.equals(this.customerIds);
    this.isApplyingFilter = this.isTotalsByMonthLoading;
    this.canAdjustFilter = !this.isApplyingFilter;
    this.canApplyFilter = this.canAdjustFilter && this.isFilterDirty;
    const hasFilter = filterProperties.some(p => this[p]);
    this.canClearFilter = this.canAdjustFilter && hasFilter;
  }

  setDefaultCustomerIds() {
    this.defaultCustomerIds = this.defaultCustomerIds.add(currentCustomer().id);
    this.customerIds = this.defaultCustomerIds;
    this.appliedFilters.customerIds = this.customerIds;
  }
}

export default alt.createStore(RevenueReportStore, 'RevenueReportStore');
