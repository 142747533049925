import { List } from 'immutable';
import TeamStore from 'team_mgmt/shared/stores/TeamStore.jsx';
import TeamListActions from 'team_mgmt/index/actions/TeamListActions.jsx';
import TeamTranslator from 'team_mgmt/shared/translators/TeamTranslator.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { TeamSource } from 'sources';

class TeamListStoreImpl extends UpperHandStore {
  constructor(teamActions, listeners) {
    super();
    this.teams = List();
    this.fields = List();
    this.isLoading = false;
    this.page = 1;
    this.perPage = 20;
    this.totalCount = 0;
    this.teamActions = teamActions;
    this.findById = id => this.teams.find(team => team.id === id);

    const createSuccessAction = teamActions.createSuccess || teamActions.list;
    this.bindListeners(
      merge(
        {
          handleListTeams: [teamActions.LIST, createSuccessAction],
          handleListTeamsSuccess: teamActions.LIST_SUCCESS,
          handleListTeamsError: teamActions.LIST_ERROR,
          handleUpdatePageParams: TeamListActions.UPDATE_PAGE_PARAMS,
        },
        listeners,
        !teamActions.CREATE_OR_UPDATE_SERVER
          ? {}
          : { handleCreateOrUpdateTeam: teamActions.CREATE_OR_UPDATE_SERVER },
        teamActions.UPDATE_SUCCESS
          ? {
              handleUpdateTeamSelection: teamActions.UPDATE_SUCCESS,
            }
          : {}
      )
    );
  }

  handleListTeams({ fields, data, page, perPage }) {
    this.isLoading = true;
    const paginationData = {
      page: page || this.page,
      per_page: perPage || this.perPage,
      fields: List(fields || [])
        .concat(this.fields)
        .toJS(),
    };

    return TeamSource.list({
      params: merge(data, paginationData),
      success: this.teamActions.listSuccess,
      error: this.teamActions.listError,
    });
  }

  handleListTeamsSuccess(data) {
    this.page = data.page;
    this.perPage = data.per_page;
    this.totalCount = data.total_count;
    this.isLoading = false;
    this.fields = List();
    this.teams = List(
      data.team_events.map(team => new TeamTranslator(team).toClient())
    );
  }

  handleListTeamsError() {
    this.isLoading = false;
  }

  handleCreateOrUpdateTeam() {
    this.waitFor(TeamStore);
    const updatedTeam = TeamStore.getState().customerTeam;
    this.teams = this.teams.map(team => {
      if (team.id === updatedTeam.id) {
        return updatedTeam;
      }
      return team;
    });
  }

  handleUpdateTeamSelection(_team) {
    const updatedTeam = new TeamTranslator(_team).toClient();
    if (updatedTeam) {
      this.teams = this.teams.map(team => {
        if (team.id === updatedTeam.id) {
          return updatedTeam;
        }
        return team;
      });
    }
  }

  handleUpdatePageParams(pageParams) {
    this.page = pageParams.page;
    this.perPage = pageParams.perPage;
  }
}

export default TeamListStoreImpl;
