import * as React from 'react';
import AltContainer from 'alt-container';
import { Link as ReactRouterLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import CartIcon from 'shared/components/icons/Cart.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import uhThemes from '_uh_theme.jsx';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';

const styles = {
  common: {
    alignSelf: 'center',
  },

  emptyCart: {
    color: '#878787',
  },

  populatedCart: {
    color: uhThemes.icons.cart,
  },
};

function PopulatedCart({ count, compact }) {
  const cartCount = (
    <Avatar
      sx={{
        width: 26,
        height: 26,
        backgroundColor: styles.populatedCart.color,
      }}
    >
      {count}
    </Avatar>
  );

  return compact ? (
    <ReactRouterLink
      to={customerScopedRoute('/cart')}
      style={{ textDecoration: 'none' }}
    >
      <IconButton disableTouchRipple onClick={CartActions.resetOnUnmount}>
        {cartCount}
      </IconButton>
    </ReactRouterLink>
  ) : (
    <ReactRouterLink
      to={customerScopedRoute('/cart')}
      style={{ textDecoration: 'none' }}
    >
      <Button
        style={styles.common}
        startIcon={<CartIcon sx={{ color: styles.populatedCart.color }} />}
        onClick={CartActions.resetOnUnmount}
      >
        {cartCount}
      </Button>
    </ReactRouterLink>
  );
}

function CartButton({ cartSize, cart, compact }) {
  if (cartSize < 1 || cart.get('status') === 'completed') {
    return null;
  }
  return <PopulatedCart count={cartSize} compact={compact} />;
}

function WrappedCartButton(props) {
  return (
    <AltContainer store={CartStore}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CartButton {...props} />
    </AltContainer>
  );
}

CartButton.defaultProps = {
  compact: false,
};

export default WrappedCartButton;
