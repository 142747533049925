import React, { memo, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { InfiniteScroller } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';

import { BalancesDataStore } from 'dataStores';

import BalanceCard from './BalanceCard.jsx';

function BalancesList({
  intl,
  balancesIds,
  pagination,
  balancesDataStore,
  isLoading,
  onScroll,
}) {
  const { balances } = balancesDataStore;
  const hasMore = pagination.page * pagination.perPage < pagination.totalCount;

  const handleScroll = useCallback(() => {
    if (hasMore && !isLoading) {
      onScroll();
    }
  }, [hasMore, isLoading, onScroll]);

  return (
    <InfiniteScroller
      showLoader
      height={300}
      isLoading={isLoading}
      onScroll={handleScroll}
      hasMore={hasMore}
    >
      {balancesIds.map(id => (
        <BalanceCard key={id} intl={intl} balance={balances.get(id)} />
      ))}
    </InfiniteScroller>
  );
}

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      balancesDataStore: BalancesDataStore,
    },
  })
)(BalancesList);
