// load alt onto the window
// important to use relative import here to import from our file and not node_modules
import alt from './alt';

try {
  window.Typekit.load({ async: true });
} catch (e) {
  // eslint-disable-next-line no-console
  console.error(
    `There was an error while loading font: ${e}. Using fallback font instead`
  );
}

window.BrowserPrint = require('../public/BrowserPrint-1.0.4.min');

window.alt = alt;

// do we need Native to be declared on window to initialize it?
window.Native = require('shared/native/Initializer.js').default;

// these need to stay in until there added to react-router routes.
window.AppRoot = require('AppRoot.jsx');
// routes
