import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Drawer,
  Spinner,
  TextField,
  Typography,
  Button,
} from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { Map, OrderedSet } from 'immutable';

import ResourceIcon from 'shared/components/icons/Resource.jsx';

import Resource from 'shared/records/Resource';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ResourceDataStore } from 'dataStores';

export function ResourceCard({ resource, isSelected, onClick, onRemove }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={clsx('available-resources__card', isSelected && 'selected')}
      onClick={() => (onClick ? onClick(resource.id) : null)}
    >
      <div className="available-resources__card-title">
        <ResourceIcon className="available-resources__icon" />
        <Typography className="resource-name" variant="caption">
          {resource.name}
        </Typography>
      </div>
      {onRemove && (
        <Button
          icon="delete"
          type="tertiary"
          classes={{ root: 'remove-resource' }}
          onClick={() => onRemove(resource.id)}
        />
      )}
    </div>
  );
}

ResourceCard.propTypes = {
  resource: PropTypes.instanceOf(Resource),
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

ResourceCard.defaultProps = {
  resource: new Resource(),
  onClick: undefined,
  onRemove: undefined,
};

function ResourcesDrawer({
  intl,
  open,
  isLoading,
  resourceIds,
  selectedIds,
  searchText,
  onClose,
  onSearch,
  onItemClick,
  onSave,
  resourceDataStore: { records: resources },
}) {
  return (
    <Drawer
      title={t('.title', intl, __filenamespace)}
      open={open}
      onClose={onClose}
      classes={{ footer: 'resources-drawer-footer' }}
      content={
        <div className="available-resources">
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              <TextField
                value={searchText}
                classes={{ root: 'search-field' }}
                fullWidth
                placeholder={t('.search', intl, __filenamespace)}
                onChange={e => onSearch(e.target.value)}
              />
              {resourceIds.map(id => {
                const resource = resources.get(id);

                if (!resource) return null;

                return (
                  <ResourceCard
                    key={id}
                    isSelected={selectedIds.includes(resource.id)}
                    resource={resource}
                    onClick={onItemClick}
                  />
                );
              })}
            </>
          )}
        </div>
      }
      footer={
        selectedIds.size !== 0 ? (
          <Button fullWidth onClick={onSave}>
            {t('.save_btn', intl, __filenamespace)}
          </Button>
        ) : null
      }
    />
  );
}

ResourcesDrawer.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  resourceIds: PropTypes.instanceOf(OrderedSet),
  selectedIds: PropTypes.instanceOf(OrderedSet),
  searchText: PropTypes.string,
  onClose: PropTypes.func,
  onItemClick: PropTypes.func,
  onSave: PropTypes.func,
  resourceDataStore: PropTypes.shape({ records: PropTypes.instanceOf(Map) }),
};

ResourcesDrawer.defaultProps = {
  intl: {},
  open: false,
  isLoading: false,
  resourceIds: OrderedSet(),
  selectedIds: OrderedSet(),
  searchText: '',
  onClose: () => null,
  onItemClick: () => null,
  onSave: () => null,
  resourceDataStore: {
    records: Map(),
  },
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      resourceDataStore: ResourceDataStore,
    },
  })
)(ResourcesDrawer);
