import { fromJS } from 'immutable';
import moment from 'moment-timezone';

import Address from 'shared/records/Address.jsx';
import { Image } from 'records';
import { isPlainObjectAt } from 'shared/utils/ObjectUtils.jsx';
import { convertDateToClientValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { formatPhone } from 'shared/utils/FormattingUtils.jsx';

const customerUserAttributes = o => {
  let object = o;

  if (object.asImmutable) {
    object = object.toJS();
  }

  if (object.first_name && object.last_name) {
    delete object.name;
  } else if (object.name) {
    const parts = object.name.split(' ');
    object.first_name = parts.shift();
    object.last_name = parts.join(' ');
    delete object.name;
  }

  if (isPlainObjectAt(object, 'address')) {
    object = { ...object, address: new Address(object.address) };
  }

  if (object.date_of_birth) {
    object = {
      ...object,
      date_of_birth: moment(object.date_of_birth),
    };
  }

  object.profile_image = new Image(object.profile_image);

  object.accepted_customer_terms_at = convertDateToClientValue(
    object.accepted_customer_terms_at
  );
  object.accepted_upper_hand_terms_at = convertDateToClientValue(
    object.accepted_upper_hand_terms_at
  );

  object.phone = object.phone ? formatPhone(object.phone) : '';

  return fromJS(object);
};

export default customerUserAttributes;
