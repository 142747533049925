import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import WaitlistEntry from 'event_mgmt/display/components/_WaitlistEntry.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';

function EventWaitingList({ waitlist, athletes, style }) {
  if (waitlist.size === 0) {
    return null;
  }

  const waitlistIds = waitlist.map(wl => wl.get('customer_user_id'));
  const waitlistEntries = athletes.allAthletes
    .filter(athlete => waitlistIds.includes(athlete.id))
    .toSet();

  if (waitlistEntries.isEmpty()) {
    return null;
  }

  return (
    <div style={style}>
      <SectionHeader style={{ marginBottom: 20 }}>
        <FormattedMessage id={messageId('.waiting_list', __filenamespace)} />
      </SectionHeader>
      {waitlistEntries.map(athlete => (
        <WaitlistEntry athlete={athlete} key={athlete.id} />
      ))}
    </div>
  );
}

export default EventWaitingList;
