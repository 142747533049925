/* eslint-disable react/require-default-props */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';

import EmptyStateIcon from 'shared/components/icons/empty_states/OrdersReport.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function EmptyState(props) {
  const intl = useIntl();
  const {
    title = t('.title', intl, __filenamespace),
    description = t('.description', intl, __filenamespace),
  } = props;

  return (
    <div className="empty-state">
      <div className="empty-state__icon">
        <EmptyStateIcon />
      </div>
      <Typography className="empty-state__title" variant="h3">
        {title}
      </Typography>
      <Typography className="empty-state__description" variant="body3">
        {description}
      </Typography>
    </div>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default EmptyState;
