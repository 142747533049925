import * as React from 'react';
import { injectIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  ViewSelect: isMobile => ({
    width: !isMobile ? 'auto' : '42px',
    margin: `0 0 0 ${!isMobile ? '16px' : '8px'}`,
    padding: '0 0 0 16px',
    textAlign: !isMobile ? 'right' : 'center',
    fontSize: !isMobile ? '15px' : '13px',
  }),
  MenuItem: isMobile => ({
    fontSize: isMobile ? '15px' : '13px',
  }),
};

const handleOnChange = (value, handleRequestClose) => {
  CalendarActions.setView(value);
  handleRequestClose();
};

const viewText = (view, intl) => {
  switch (CALENDAR_TYPE[view]) {
    case CALENDAR_TYPE.DAY:
      return t('.day', intl, __filenamespace);
    case CALENDAR_TYPE.WEEK:
      return t('.week', intl, __filenamespace);
    default:
      return CALENDAR_TYPE[view];
  }
};

function MenuItems({ intl, isMobile, handleRequestClose }) {
  return (
    <>
      {Object.keys(CALENDAR_TYPE).map(key => {
        const text = viewText(key, intl);

        return (
          <MenuItem
            key={`ViewMenuItem-${key}`}
            style={styles.MenuItem(isMobile)}
            onClick={() =>
              handleOnChange(CALENDAR_TYPE[key], handleRequestClose)
            }
          >
            {text}
          </MenuItem>
        );
      })}
    </>
  );
}

class ViewSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
    };

    this.handlePopoverButtonClick = this.handlePopoverButtonClick.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  handlePopoverButtonClick(e) {
    e.preventDefault();

    this.setState({ open: true, anchorEl: e.currentTarget });
  }

  handleRequestClose() {
    this.setState({ open: false });
  }

  render() {
    const { view, intl } = this.props;
    const { anchorEl, open } = this.state;
    const isMobile = smallScreen();

    return (
      <div>
        <Button
          startIcon={<ArrowDropDownIcon />}
          onClick={this.handlePopoverButtonClick}
        >
          {viewText(
            Object.keys(CALENDAR_TYPE).find(v => CALENDAR_TYPE[v] === view),
            intl
          )}
        </Button>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={open}
          onClose={this.handleRequestClose}
        >
          <MenuItems
            intl={intl}
            isMobile={isMobile}
            handleRequestClose={this.handleRequestClose}
          />
        </Popover>
      </div>
    );
  }
}

export default injectIntl(ViewSelect);
