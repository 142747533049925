export default alt.generateActions(
  'list',
  'listSuccess',
  'listError',
  'stageAvailabilityScheduleFor',
  'createOrUpdate',
  'createOrUpdateSuccess',
  'createOrUpdateError',
  'updateStore'
);
