/* eslint-disable react/require-default-props */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Set, List } from 'immutable';
import { Typography } from '@upperhand/playmaker';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import PackageSelector from 'shared/components/registration/PackageSelector.jsx';

import RegistrationPackage from 'shared/records/RegistrationPackage';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import PerSessionPricingDescription from 'shared/records/automations/PerSessionPricingDescription.jsx';

import { t } from 'shared/utils/LocaleUtils';

const inlineStyles = {
  actionBtn: {
    height: '50px',
    textTransform: 'initial',
    flex: 1,
    minWidth: '200px',
  },
  container: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '.package-selector-label__typography': {
      fontSize: '16px',
    },
  },
  actionsContainer: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  selectorsContainer: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
  },
};

function OpenBookingSchedulerNoScheduling({
  clientId = null,
  profiles = List(),
  event = new CustomerEvent(),
  eventDiscounts = Set(),
  packagePricing = new PerSessionPricingDescription(),
  onClientChange = () => {},
  onReturnToScheduling = () => {},
  onAddToCart = () => {},
  onProfileCreated = () => {},
}) {
  const intl = useIntl();
  const [registrationPackage, setRegistrationPackage] = useState(
    new RegistrationPackage({
      quantity: 1,
      client_ids: Set([clientId]),
      event_id: event.id,
    })
  );
  const regClient = registrationPackage.client_ids.first();
  const selectedProfile = profiles.find(profile => profile.id === regClient);
  const eventDiscount = eventDiscounts?.findLast(
    m =>
      (m.subscribable_type === 'Membership' &&
        m.subscribable_id === selectedProfile?.active_membership_id) ||
      (m.subscribable_type === 'MembershipTier' &&
        m.subscribable_id === selectedProfile?.active_membership_tier_id)
  );
  const disableAddToCart =
    !clientId ||
    (packagePricing && !registrationPackage.automation_option_uuid);

  const handleAutomationOptionSelected = (_, __, uuid) => {
    const { template_parameters: templateParameters } = packagePricing;
    const templateParameter = templateParameters.find(
      tp => tp.get('uuid') === uuid
    );

    setRegistrationPackage(
      registrationPackage
        .set('event_id', event.id)
        .set('quantity', templateParameter.get('quantity'))
        .set('automation_option_uuid', uuid)
        .set('automation_template_description_id', packagePricing.get('id'))
    );
  };

  const handleDefaultOptionSelected = (_, __, size) => {
    setRegistrationPackage(
      registrationPackage
        .set('event_id', event.id)
        .set('quantity', size)
        .set('automation_option_uuid', null)
        .set('automation_template_description_id', null)
    );
  };

  const handleClientChange = (_, __, value) => {
    onClientChange(_, __, value);
    setRegistrationPackage(
      registrationPackage
        .set('event_id', event.id)
        .set('client_ids', Set([value]))
    );
  };

  const handleAddToCart = () => {
    onAddToCart(registrationPackage);
  };

  return (
    <Box sx={inlineStyles.container}>
      <Box sx={inlineStyles.selectorsContainer}>
        <ProfileSelector
          header={
            <Typography variant="caption">
              {t('.choose_profile', intl, __filenamespace)}
            </Typography>
          }
          athletes={profiles}
          value={regClient}
          onChange={handleClientChange}
          afterProfileCreate={onProfileCreated}
        />
        <PackageSelector
          label={t('.choose_package', intl, __filenamespace)}
          discount={eventDiscount && eventDiscount.discount}
          packagePricingDescription={packagePricing}
          price={event.price}
          selectedValue={
            packagePricing
              ? registrationPackage.automation_option_uuid
              : registrationPackage.quantity
          }
          registrationPackage={registrationPackage}
          onAutomationOptionSelect={handleAutomationOptionSelected}
          onDefaultOptionSelect={handleDefaultOptionSelected}
        />
      </Box>
      <Box sx={inlineStyles.actionsContainer}>
        <Button
          fullWidth
          variant="outlined"
          color="dark"
          sx={inlineStyles.actionBtn}
          onClick={onReturnToScheduling}
        >
          {t('.return_to_scheduling', intl, __filenamespace)}
        </Button>
        <Button
          fullWidth
          variant="contained"
          disabled={disableAddToCart}
          sx={inlineStyles.actionBtn}
          onClick={handleAddToCart}
        >
          {t('.add_to_cart', intl, __filenamespace)}
        </Button>
      </Box>
    </Box>
  );
}

OpenBookingSchedulerNoScheduling.propTypes = {
  clientId: PropTypes.number,
  profiles: PropTypes.instanceOf(List),
  event: PropTypes.instanceOf(CustomerEvent),
  eventDiscounts: PropTypes.instanceOf(Set),
  packagePricing: PropTypes.instanceOf(PerSessionPricingDescription),
  onClientChange: PropTypes.func,
  onReturnToScheduling: PropTypes.func,
  onAddToCart: PropTypes.func,
  onProfileCreated: PropTypes.func,
};

export default memo(OpenBookingSchedulerNoScheduling);
