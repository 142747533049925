import * as React from 'react';
import { Map } from 'immutable';
import AltContainer from 'alt-container';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStaffDrawer from 'event_mgmt/editing/components/_EventStaffDrawer.jsx';
import EventStaffDrawerStore from 'event_mgmt/editing/stores/EventStaffDrawerStore.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import StaffCard from 'event_mgmt/editing/components/_StaffCard.jsx';
import Schedule from 'event_mgmt/shared/records/Schedule.jsx';

import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import { StaffDataStore } from 'dataStores';

function StaffCardWrapped({ id, index, staff, errors }) {
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (dragIndex !== hoverIndex) {
        // eslint-disable-next-line no-param-reassign
        item.index = hoverIndex;
        EventActions.listSwap({
          oldIndex: dragIndex,
          newIndex: hoverIndex,
          key: 'schedule_staff',
        });
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!staff) {
    return <li />;
  }

  const opacity = isDragging ? 0.3 : 1;

  drag(drop(ref));

  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      className="staff-list__item"
      style={{ opacity }}
    >
      <StaffCard
        staffMember={staff}
        onRemove={EventActions.staffRemoved}
        errors={errors}
      />
    </li>
  );
}

const UsageSelector = injectIntl(({ intl, schedule }) => {
  const onModeSelect = e => {
    EventActions.updateEventStore(
      ['schedules', 0, 'staff_usage_mode'],
      e.target.value
    );
  };

  return (
    <FlexBoxCenter>
      <Select
        fullWidth
        variant="standard"
        value={schedule.staff_usage_mode}
        onChange={onModeSelect}
      >
        <MenuItem value={Schedule.RESOURCE_USAGE_ALL}>
          {t('.all', intl, __filenamespace)}
        </MenuItem>
        <MenuItem value={Schedule.STAFF_USAGE_ONE}>
          {t('.single', intl, __filenamespace)}
        </MenuItem>
      </Select>
      <div style={{ height: 20, width: 20 }}>
        <InfoIcon
          data-tip
          data-for="staff-usage-info"
          style={{ height: 20, width: 20 }}
        />
        <ReactTooltip
          id="staff-usage-info"
          effect="solid"
          className="uh-tooltip charge-failure-tooltip"
        >
          <div style={{ lineHeight: 1.4 }}>
            {schedule.staff_usage_mode === Schedule.RESOURCE_USAGE_ALL ? (
              <FormattedMessage
                id={messageId('.usage_information_all', __filenamespace)}
              />
            ) : (
              <FormattedMessage
                id={messageId('.usage_information_one', __filenamespace)}
              />
            )}
          </div>
        </ReactTooltip>
      </div>
    </FlexBoxCenter>
  );
});

const StaffList = injectIntl(
  ({ staff, event, isCheckingConflicts, errors, intl }) => {
    const schedule = event.getIn(['schedules', 0]);

    if (isCheckingConflicts) {
      return (
        <div>
          <Divider className="staff-column__divider" />
          <div className="staff-column__info-text">
            <FormattedMessage
              id={messageId('.loading_staff', __filenamespace)}
            />
          </div>
        </div>
      );
    }
    if (schedule.customer_user_ids.isEmpty()) {
      return (
        <div>
          <Divider className="staff-column__divider" />
          <div className="staff-column__info-text">
            <FormattedMessage
              id={messageId('.no_staff_assigned', __filenamespace)}
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        {event.isOpenBooking() && (
          <Stack>
            <Divider className="staff-column__divider" />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginBottom: '10px' }}
            >
              <FormControlLabel
                name="allow_staff_selection"
                label={t('.allow_staff_selection', intl, __filenamespace)}
                control={
                  <Checkbox
                    checked={event.allow_staff_selection}
                    onChange={e => {
                      EventActions.staffSelectToggled(e.target.checked);
                      if (e.target.checked) {
                        EventActions.updateEventStore(
                          ['schedules', 0, 'staff_usage_mode'],
                          Schedule.RESOURCE_USAGE_ALL
                        );
                      }
                    }}
                  />
                }
              />
              {!event.allow_staff_selection && (
                <UsageSelector schedule={schedule} />
              )}
            </Stack>
          </Stack>
        )}
        <DndProvider backend={HTML5Backend}>
          <ul className="staff-list">
            {schedule.get('schedule_staff').map(st => (
              <StaffCardWrapped
                key={st.customer_user_id}
                id={st.position}
                index={st.position}
                staff={staff.get(st.customer_user_id)}
                errors={errors}
              />
            ))}
          </ul>
        </DndProvider>
      </div>
    );
  }
);

const staffDrawerOpened = () =>
  SecondaryDrawerActions.setActive('EventStaffDrawer');

function StaffColumnContent({
  schedule,
  staff,
  event,
  isCheckingConflicts,
  errors,
}) {
  return (
    <div className="staff-column">
      <FlexBoxJustify className="staff-column__header-container">
        <FlexBoxCenter>
          <div className="staff-column__fab-btn-container">
            <Fab
              size="small"
              color="primary"
              className="staff-column__fab-btn"
              onClick={staffDrawerOpened}
            >
              <AddIcon />
            </Fab>
          </div>
          <FormattedMessage id={messageId('.staff', __filenamespace)} />
        </FlexBoxCenter>

        {schedule.get('schedule_staff').size > 0 && (
          <div className="staff-column__staff-count">
            {schedule.get('schedule_staff').size}
          </div>
        )}
      </FlexBoxJustify>

      <StaffList
        staff={staff}
        event={event}
        isCheckingConflicts={isCheckingConflicts}
        errors={errors}
      />

      <EventStaffDrawer />
    </div>
  );
}

function StaffColumn() {
  return (
    <AltContainer
      stores={{
        errors: () => ({
          store: EventStore,
          value: EventStore.getState().fieldErrors,
        }),
        isCheckingConflicts: () => ({
          store: EventStore,
          value: EventStore.getState().isCheckingConflicts,
        }),
        event: () => ({
          store: EventStore,
          value: EventStore.getState().customerEvent
            ? EventStore.getState().customerEvent
            : Map(),
        }),
        selectedStaff: () => ({
          store: EventStaffDrawerStore,
          value: EventStaffDrawerStore.getState().selectedStaff,
        }),
        schedule: () => ({
          store: EventStore,
          value: EventStore.getState().customerEvent
            ? EventStore.getState().customerEvent.get('schedules').get(0)
            : Map(),
        }),
        staff: () => ({
          store: StaffDataStore,
          value: StaffDataStore.getState().staff
            ? StaffDataStore.getState().staff
            : Map(),
        }),
      }}
    >
      <StaffColumnContent />
    </AltContainer>
  );
}

export default StaffColumn;
