import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import CustomerTermsBanner from 'shared/components/_CustomerTermsBanner.jsx';
import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import TermsBanner from 'shared/components/_TermsBanner.jsx';
import TermsTemplate from 'shared/components/_TermsTemplate.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { compose } from 'shared/utils/SharedUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { t, messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import './styles.scss';

function ClientTermDialog({
  intl,
  clientId,
  client,
  currentContextStore: { openDialog },
  currentContextActions: { closeDialog, acceptTerms },
}) {
  const showCustomerBanner = !client.has_accepted_terms && client.login_enabled;
  const showCustomerTerms =
    currentCustomer().policy_and_terms !== null &&
    currentCustomer().policy_and_terms !== '';

  return (
    <Confirmation
      hasCheckbox={false}
      open={openDialog}
      classes={{
        root: 'terms-modal-dialog',
        title: 'dialog-title',
        actions: 'modal-actions',
      }}
      title={
        showCustomerBanner ? (
          <CustomerTermsBanner />
        ) : (
          <TermsBanner name={t('theme_vendor', intl)} />
        )
      }
      confirmButtonLabel={t('actions.accept', intl)}
      cancelButtonLabel={t('actions.cancel', intl)}
      onCancel={closeDialog}
      onConfirm={() => acceptTerms(clientId)}
    >
      {showCustomerTerms ? (
        <>
          <TermsTemplate terms={currentCustomer().policy_and_terms} />
          <Actions clientId={clientId} />
        </>
      ) : (
        <UhTerms client={client} clientId={clientId} />
      )}
    </Confirmation>
  );
}

function UhTerms() {
  return (
    <FlexBoxCenter
      style={{ flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <a
        href={window.upperHandTermsUrl}
        target="blank"
        style={{ color: uhColors.activeBlue }}
      >
        <FormattedMessage
          id={messageId('.view_policy_and_terms', __filenamespace)}
        />
      </a>
      <a
        href={window.upperHandPrivacyUrl}
        target="blank"
        style={{ color: uhColors.activeBlue }}
      >
        <FormattedMessage
          id={messageId('.view_privacy_and_cookies', __filenamespace)}
        />
      </a>

      <div style={{ padding: '2em' }}>
        <FormattedMessage
          id={messageId('.accept', __filenamespace)}
          values={{ customerName: currentCustomer().name }}
        />
      </div>
    </FlexBoxCenter>
  );
}

function Actions() {
  return (
    <FlexBoxJustify
      style={{
        alignItems: 'center',
      }}
    >
      <div
        style={{
          color: uhColors.tabGrey,
          padding: '0 20px',
          textAlign: 'center',
        }}
      >
        <FormattedMessage
          id={messageId('.accept', __filenamespace)}
          values={{ customerName: currentCustomer().name }}
        />
        &nbsp;
        <a
          href={window.upperHandTermsUrl}
          target="blank"
          style={{ color: uhColors.activeBlue }}
        >
          <FormattedMessage id={messageId('.view_terms', __filenamespace)} />
        </a>
        &nbsp;
        <FormattedMessage id={messageId('.and', __filenamespace)} />
        &nbsp;
        <a
          href={window.upperHandPrivacyUrl}
          target="blank"
          style={{ color: uhColors.activeBlue }}
        >
          <FormattedMessage
            id={messageId('.view_privacy_and_cookies', __filenamespace)}
          />
        </a>
      </div>
    </FlexBoxJustify>
  );
}

export default compose(
  altContainer({
    stores: {
      currentContextStore: CurrentContextStore,
    },
    actions: {
      currentContextActions: CurrentContextActions,
    },
  }),
  injectIntl
)(ClientTermDialog);
