import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  TextField,
  Typography,
  Grid,
  RadioButton,
  Checkbox,
  Dropdown,
} from '@upperhand/playmaker';

import Schedule from 'event_mgmt/shared/records/Schedule.jsx';
import SchedulingTimeFrame from 'event_mgmt/editing/components/_SchedulingTimeFrame.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';
import EventSessionCreationStore, {
  SCHEDULING_UNITS,
} from 'containers/eventSessionCreation/Store';

const genderOptions = [
  { label: 'Coed', value: 'coed' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

const schedulingDeadlineUnits = [
  { label: 'Min', value: 'min' },
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
];

const cancellationUnits = [
  { label: 'Min', value: 'min' },
  { label: 'Hour', value: 'hour' },
];

function Participants({
  intl,
  eventSessionCreationStore: {
    schedule,
    scheduleDeadlineUnit,
    scheduleDeadlineValue,
    cancellationDeadlineUnit,
    cancellationDeadlineValue,
  },
}) {
  const {
    max_size: maxSize,
    min_age: minAge,
    max_age: maxAge,
    gender_restriction: genderRestriction,
    scheduling_deadline: schedulingDeadline,
    cancellation_deadline: cancellationDeadline,
  } = schedule;

  const getValue = ({ e, defaultValue = 1, minValue = 1 }) => {
    const value = parseInt(e.target.value, 10);

    return value >= minValue ? value : defaultValue;
  };

  return (
    <div className="participants-container">
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.class_size', intl, __filenamespace)}
      </Typography>
      <div className="events-session-creation__form-item">
        <TextField
          type="number"
          value={maxSize}
          onChange={e =>
            EventSessionCreationActions.handleFieldChange({
              field: 'max_size',
              value: getValue({ e }),
            })
          }
        />
      </div>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.gender', intl, __filenamespace)}
      </Typography>
      <div className="events-session-creation__form-item">
        <RadioButton
          classes={{ labelRoot: 'gender-label' }}
          options={genderOptions}
          selectedValue={genderRestriction}
          onChange={e =>
            EventSessionCreationActions.handleFieldChange({
              field: 'gender_restriction',
              value: e.target.value,
            })
          }
        />
      </div>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.age', intl, __filenamespace)}
      </Typography>
      <div className="events-session-creation__form-item">
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <TextField
              fullWidth
              placeholder="Min age"
              type="number"
              value={minAge}
              onChange={e =>
                EventSessionCreationActions.handleFieldChange({
                  field: 'min_age',
                  value: getValue({ e }),
                })
              }
            />
          </Grid>
          <Grid item container justify="center" xs={2}>
            &nbsp; — &nbsp;
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              placeholder="Max age"
              type="number"
              value={maxAge}
              onChange={e =>
                EventSessionCreationActions.handleFieldChange({
                  field: 'max_age',
                  value: getValue({ e }),
                })
              }
            />
          </Grid>
        </Grid>
      </div>
      <div className="events-session-creation__form-title">
        <Typography variant="h5">
          {t('.scheduling_deadline', intl, __filenamespace)}
        </Typography>
        <div className="checkbox">
          <Checkbox
            checked={!!schedulingDeadline}
            onChange={() =>
              schedulingDeadline
                ? EventSessionCreationActions.handleFieldChange({
                    field: 'scheduling_deadline',
                    value: null,
                  })
                : EventSessionCreationActions.calculateDeadline({})
            }
          />
        </div>
      </div>
      <div className="events-session-creation__form-item">
        {schedulingDeadline && (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={5}>
              <Typography variant="fieldLabel">
                {t('.before_session', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={scheduleDeadlineValue}
                fullWidth
                type="number"
                onChange={e =>
                  EventSessionCreationActions.setScheduleDeadline(
                    getValue({ e })
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                fullWidth
                value={scheduleDeadlineUnit}
                items={schedulingDeadlineUnits}
                onChange={e =>
                  EventSessionCreationActions.setScheduleDeadlineUnit(
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        )}
        {!schedulingDeadline && (
          <Typography>
            {t('.scheduling_info', intl, __filenamespace)}
          </Typography>
        )}
      </div>
      <div className="events-session-creation__form-title">
        <Typography variant="h5">
          {t('.cancellation_deadline', intl, __filenamespace)}
        </Typography>
        <div className="checkbox">
          <Checkbox
            checked={!!cancellationDeadline}
            onChange={() =>
              cancellationDeadline
                ? EventSessionCreationActions.handleFieldChange({
                    field: 'cancellation_deadline',
                    value: null,
                  })
                : EventSessionCreationActions.calculateDeadline({
                    unitField: 'cancellationDeadlineUnit',
                    valueField: 'cancellationDeadlineValue',
                    storeField: 'cancellation_deadline',
                  })
            }
          />
        </div>
      </div>
      <div className="events-session-creation__form-item">
        {cancellationDeadline && (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={5}>
              <Typography variant="fieldLabel">
                {t('.before_session', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={cancellationDeadlineValue}
                fullWidth
                type="number"
                onChange={e =>
                  EventSessionCreationActions.setCancellationDeadline(
                    getValue({ e })
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                fullWidth
                value={cancellationDeadlineUnit}
                items={cancellationUnits}
                onChange={e =>
                  EventSessionCreationActions.setCancellationDeadlineUnit(
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        )}
        {!cancellationDeadline && (
          <Typography>
            {t('.cancellation_info', intl, __filenamespace)}
          </Typography>
        )}
      </div>
      <SchedulingTimeFrame
        labelVariant="h5"
        explanationVariant="fieldLabel"
        schedule={schedule}
        handleScheduleChange={(field, value) =>
          EventSessionCreationActions.handleFieldChange({
            field,
            value,
          })
        }
      />
    </div>
  );
}

Participants.propTypes = {
  intl: PropTypes.object,
  eventSessionCreationStore: PropTypes.shape({
    schedule: PropTypes.instanceOf(Schedule),
    scheduleDeadlineUnit: PropTypes.string,
    scheduleDeadlineValue: PropTypes.number,
    cancellationDeadlineUnit: PropTypes.string,
    cancellationDeadlineValue: PropTypes.number,
  }),
};

Participants.defaultProps = {
  intl: {},
  eventSessionCreationStore: {
    schedule: new Schedule(),
    schedulingDeadlineUnit: SCHEDULING_UNITS.DAY,
    scheduleDeadlineValue: 1,
    cancellationDeadlineUnit: SCHEDULING_UNITS.HOUR,
    cancellationDeadlineValue: 24,
  },
};

export default compose(
  injectIntl,
  altContainer({
    stores: { eventSessionCreationStore: EventSessionCreationStore },
  })
)(Participants);
