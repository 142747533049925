import { List, Map, Set } from 'immutable';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CreditPass from 'shared/records/CreditPass.js';
import CreditPassPackage from 'shared/records/CreditPassPackage.js';
import CreditPassPurchasingActions from 'credit_passes/actions/CreditPassPurchasingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';

class CreditPassPurchasingStore extends UpperHandStore {
  constructor() {
    super();

    this.creditPass = new CreditPass();
    this.creditPassPackage = new CreditPassPackage();
    this.clientsPassPurchases = Map();
    this.isLoading = true;
    this.buyerId = null;
    this.orderItem = null;

    this.availableProfiles = List();
    this.allProfiles = List();

    this.bindListeners({
      addProfile: [
        CreditPassPurchasingActions.PROFILE_ADDED,
        CreditPassPurchasingActions.PROFILE_CREATED,
      ],

      updatePackageFromCart: CartActions.FETCH_SUCCESS,
      purchase: CreditPassPurchasingActions.PURCHASE_CLICKED,
      listingCreditPassPurchasesSuccess:
        CreditPassPurchasingActions.listingCreditPassPurchasesSuccess,
      listingCreditPassPurchasesError:
        CreditPassPurchasingActions.listingCreditPassPurchasesError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setClientIdsAsKeys(profiles) {
    return Map(profiles.map(profile => [profile.id, profile]));
  }

  isValidProfile(profile) {
    return (
      profile &&
      profile.type === 'Client' &&
      !profile.archived &&
      !this.creditPassPackage.client_ids.has(profile.id)
    );
  }

  setAvailableProfiles(listPurchases = true) {
    this.availableProfiles = this.allProfiles
      .filter(profile => this.isValidProfile(profile))
      .toList();
    // check if this creditpass package has a limit of purchases
    if (this.creditPass.max_num_of_purchases !== 0 && listPurchases) {
      this.listingCreditPassPurchases(
        this.availableProfiles.map(x => x.id).toJS()
      );
    } else {
      this.isLoading = false;
    }
  }

  setAvailableProfilesWithPurchases() {
    this.availableProfiles = this.availableProfiles.filter(profile => {
      const isAvailable =
        this.clientsPassPurchases.size > 0
          ? this.clientsPassPurchases.every(
              p =>
                !p.client_ids.includes(profile.id) ||
                (p.client_ids.includes(profile.id) && p.purchases_remaining > 0)
            )
          : true;

      return isAvailable;
    });
  }

  listingCreditPassPurchases(clientIds) {
    this.isLoading = true;

    return uhApiClient.get({
      url: `credit_passes/${this.creditPass.id}/purchases`,
      data: { client_ids: clientIds },
      success: CreditPassPurchasingActions.listingCreditPassPurchasesSuccess,
      error: CreditPassPurchasingActions.listingCreditPassPurchasesError,
    });
  }

  listingCreditPassPurchasesSuccess(data) {
    this.clientsPassPurchases = List(data.results);

    this.setAvailableProfilesWithPurchases();

    this.isLoading = false;
  }

  listingCreditPassPurchasesError(...args) {
    this.notifyError('Error listing credit pass purchases', args);

    this.isLoading = false;
  }

  updatePackageFromCart() {
    if (!this.creditPass.id) {
      return;
    }
    this.getCart();

    const passFromCart = this.orderItemFromCart(this.creditPass.id);

    if (passFromCart) {
      this.creditPassPackage = passFromCart.orderable;
    }
  }

  orderItemFromCart(creditPassId) {
    return this.cart.order_items.find(
      oi =>
        oi.isCreditPassItem() &&
        oi.orderable.credit_pass_id === creditPassId &&
        oi.orderable.client_id === this.buyerId
    );
  }

  getCart() {
    this.waitFor(CartStore);
    this.cart = CartStore.getState().cart;
  }

  purchase({ creditPass, buyerId, orderItem = null }) {
    this.orderItem = orderItem;
    this.isLoading = true;

    this.waitFor([CurrentContextStore]);

    const { customerUser } = CurrentContextStore.getState();

    this.availableProfiles =
      customerUser && customerUser.managed_customer_users
        ? customerUser.managed_customer_users
            .push(customerUser)
            .filter(client => client.isClient())
        : [];
    this.allProfiles = this.setClientIdsAsKeys(this.availableProfiles);

    if (!creditPass) return;

    this.getCart();

    if (this.creditPass.id !== creditPass.id) {
      const clientIds = orderItem
        ? orderItem?.getIn(['orderable', 'client_ids'], null)
        : Set();

      this.buyerId = buyerId;

      this.creditPassPackage = new CreditPassPackage({
        credit_pass_id: creditPass.id,
        price: creditPass.price,
        client_ids: clientIds,
      });
    }
    this.creditPass = creditPass;

    // check if this creditpass package has a limit of purchases
    if (this.creditPass.max_num_of_purchases !== 0) {
      this.listingCreditPassPurchases(
        this.availableProfiles.map(x => x.id).toJS()
      );
    } else {
      this.isLoading = false;
    }
  }

  addProfile(id) {
    this.buyerId = id;

    this.creditPassPackage = new CreditPassPackage({
      credit_pass_id: this.creditPass.id,
      price: this.creditPass.price,
      client_ids: this.creditPassPackage.client_ids,
    });

    if (!this.creditPassPackage.client_ids.has(id)) {
      const clientIds = this.creditPassPackage.client_ids.add(id);

      this.creditPassPackage = this.creditPassPackage.set(
        'client_ids',
        clientIds
      );
    } else {
      const clientIds = this.creditPassPackage.client_ids.remove(id);

      this.creditPassPackage = this.creditPassPackage.set(
        'client_ids',
        clientIds
      );
    }

    this.setAvailableProfiles(false);
  }
}

export default alt.createStore(
  CreditPassPurchasingStore,
  'CreditPassPurchasingStore'
);
