import { Iterable, Record } from 'immutable';
import Address from 'shared/records/Address.jsx';

class Location extends Record({
  id: null,
  name: '',
  address: new Address(),
}) {
  constructor(obj = {}, options = { fromServer: false }) {
    if (Iterable.isIterable(obj)) {
      obj = obj.toJS();
    }

    super({ ...obj, address: new Address(obj.address) });
  }

  toServer() {
    return this.toJS();
  }
}

export default Location;
