export default alt.generateActions(
  'setReactivationDate',
  'setSuspendAtDate',
  'suspensionAborted',
  'suspensionRequested',
  'suspensionConfirmed',
  'suspendMembershipError',
  'suspendMembershipSuccess',
  'reactivateOnFuture',
  'listSubscriptionError',
  'listSubscriptionSuccess',
  'getCustomerSubscriptions',
  'reactivationRequested',
  'reactivationAborted',
  'reactivateMembership',
  'reactivateMembershipSuccess',
  'reactivateMembershipError'
);
