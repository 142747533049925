import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Dashboard({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 22" {...props}>
      <g
        id="MockUps"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Cust.-Dash-Stripped"
          transform="translate(-18.000000, -79.000000)"
          fill={color}
        >
          <g id="Left-Nav" transform="translate(0.000000, 60.000000)">
            <path
              d="M32.3765,36.8008 C31.7585,38.1348 30.1705,38.7178 28.8365,38.0988 C27.5025,37.4808
               26.9195,35.8918 27.5375,34.5578 C28.1565,33.2238 29.7445,32.6418 31.0785,33.2598
               C32.4125,33.8788 32.9945,35.4668 32.3765,36.8008 M33.3305,31.5718 L35.0925,27.7718
               C35.4015,27.1038 35.1105,26.3118 34.4425,26.0018 C33.7745,25.6918 32.9815,25.9828
               32.6725,26.6508 L30.9105,30.4508 C28.5775,30.0248 26.1625,31.1838 25.1185,33.4368
               C23.8795,36.1088 25.0415,39.2798 27.7145,40.5178 C30.3875,41.7568 33.5575,40.5948
               34.7965,37.9228 C35.8405,35.6698 35.1635,33.0778 33.3305,31.5718 M30.0005,19.7128
               C23.3725,19.7128 18.0005,25.0848 18.0005,31.7128 C18.0005,32.4488 18.5975,33.0458
               19.3335,33.0458 C20.0695,33.0458 20.6665,32.4488 20.6665,31.7128 C20.6665,26.5658
               24.8545,22.3788 30.0005,22.3788 C35.1475,22.3788 39.3335,26.5658 39.3335,31.7128
               C39.3335,32.4488 39.9305,33.0458 40.6665,33.0458 C41.4025,33.0458 42.0005,32.4488
               42.0005,31.7128 C42.0005,25.0848 36.6275,19.7128 30.0005,19.7128"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Dashboard.defaultProps = {
  color: '#9A9EAD',
};

export default Dashboard;
