/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import CompletedEventSchedules from './completed_event_schedules/CompletedEventSchedules.jsx';
import CompletedEventSessions from './completed_event_sessions/CompletedEventSessions.jsx';

function CompletedEventSchedule(props) {
  const [scheduleViewMode, toggleViewMode] = React.useState(false);

  const onToggleViewMode = () => {
    toggleViewMode(mode => !mode);
  };

  return scheduleViewMode ? (
    <CompletedEventSchedules onToggleViewMode={onToggleViewMode} {...props} />
  ) : (
    <CompletedEventSessions onToggleViewMode={onToggleViewMode} {...props} />
  );
}

export default CompletedEventSchedule;
