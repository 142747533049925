import * as React from 'react';
import debounce from 'lodash.debounce';
import { FormattedMessage, injectIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import SearchablePopoverMenu from 'calendar/components/quick_schedule/SearchablePopoverMenu.jsx';
import AttendeeIcon from 'shared/components/icons/Attendee.jsx';
import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import QSClientListActions from 'calendar/actions/QSClientListActions';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';

const styles = {
  Attendees: {
    position: 'relative',
    width: '100%',
  },

  MenuItem: {
    width: '100%',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  Loading: {
    textAlign: 'center',
  },

  RemoveButton: {
    cursor: 'pointer',
    marginRight: 2,
  },

  warningText: {
    marginTop: 15,
    fontSize: 14,
    lineHeight: 1.2,
    color: uhColors.darkRed,
  },
};

const handleOnRemoveClient = client => {
  QuickScheduleActions.removeClient(client);
};

const menuItems = ({
  qsClientListStore: { clients, isLoading },
  selectedEvent,
}) => {
  if (!isLoading) {
    return clients.map(client => (
      <MenuItem
        key={client.id}
        onClick={() => {
          // It's a little unfortunate that we need to pass the event as well
          // but we're avoiding a circular dependency with ClientCreditStore.
          QuickScheduleActions.selectClient(client, selectedEvent);
        }}
        style={styles.MenuItem}
      >
        <AvatarWithName user={client} avatarOptions={{ size: 24 }} />
      </MenuItem>
    ));
  }
  return (
    <div style={styles.Loading}>
      <CircularProgress size={45} />
    </div>
  );
};

function PageSelector({ qsClientListStore }) {
  return (
    <Paginator
      compact
      currentPage={qsClientListStore.page}
      perPage={qsClientListStore.perPage}
      totalCount={qsClientListStore.totalCount}
      onPageSelect={(page, _) => QSClientListActions.pageSelected(page)}
      style={styles.paginator}
    />
  );
}

function SelectedClient({ qsClientStore: { client, clientIsValid } }) {
  return (
    <div>
      <FlexBoxJustify style={{ alignItems: 'center', paddingTop: 15 }}>
        <AvatarWithName user={client} avatarOptions={{ size: 24 }} />
        <IconButton
          style={styles.RemoveButton}
          onClick={() => handleOnRemoveClient(client)}
        >
          <CloseIcon
            style={{ height: 21, width: 21 }}
            color={uhColors.iconLightGrey}
          />
        </IconButton>
      </FlexBoxJustify>

      {/* TODO [zgoldman:2018-09-14]: Make this an actionable warning. */}
      {!clientIsValid && (
        <div style={styles.warningText}>
          <FormattedMessage
            id={messageId('.invalid_client_warning', __filenamespace)}
          />
        </div>
      )}
    </div>
  );
}

class Attendees extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleSearchTermChange(searchTerm) {
    QSClientListActions.searchStringUpdated(searchTerm);
  }

  render() {
    const {
      intl,
      qsClientStore,
      qsClientListStore,
      quickScheduleStore: { selectedClient, selectedEvent },
    } = this.props;

    return (
      <div style={{ paddingLeft: 15, paddingRight: 10, marginBottom: 15 }}>
        {/* TODO [zgoldman:2018-09-14]: Change the icon to an Attendee */}
        {selectedClient ? (
          <SelectedClient qsClientStore={qsClientStore} />
        ) : (
          <SearchablePopoverMenu
            icon={
              <AttendeeIcon
                style={{ height: 18, width: 18 }}
                color={uhColors.tableGrey}
              />
            }
            locked={!!selectedClient}
            menuItems={menuItems({ qsClientListStore, selectedEvent })}
            footer={<PageSelector qsClientListStore={qsClientListStore} />}
            hintText={t('.add_attendee', intl, __filenamespace)}
            onSearchTermChange={debounce(this.handleSearchTermChange, 350)}
            style={{ borderBottom: '1px solid var(--color-divide)' }}
            searchBarStyle={{ paddingLeft: 0 }}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(Attendees);
