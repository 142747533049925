import { List } from 'immutable';
import AutomationTemplateDescription from 'shared/records/AutomationTemplateDescription.jsx';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';
import DataStoreActions from './AutomationTemplateDescriptionActions';

const parserTo = {
  type: AutomationTemplateDescription,
  paginationKey: 'automation_template_descriptions',
};

const parser = new Parser(parserTo);

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error,
  });
};
