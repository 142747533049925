import * as React from 'react';

import PurchaseDrawer from 'containers/purchaseDrawer/PurchaseDrawer.jsx';

import BrowseTeamsWrapped from './BrowseTeamsWrapped.jsx';

import './styles.scss';

function BrowseEvents() {
  return (
    <>
      <BrowseTeamsWrapped />
      <PurchaseDrawer />
    </>
  );
}

export default BrowseEvents;
