import * as React from 'react';
import { Card, Grid, Typography, Button } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import MembershipEmbedActions from 'containers/clientProfile/components/MembershipEmbed/MembershipEmbedActions';
import SwingCreditsDrawerActions from 'containers/clientProfile/components/Drawers/SwingCreditsDrawer/Actions';

import { ClientDataStore, MembershipSubscriptionDataStore } from 'dataStores';

function CashCreditsInfo({
  intl,
  clientId,
  totalCredits,
  subscriptionId,
  clientDataStore: { clients },
  membershipSubscriptionDataStore: { membershipSubscriptions },
}) {
  const client = clients.get(clientId);
  const membershipSubscription = membershipSubscriptions.get(subscriptionId);
  const { features } = currentCustomer();
  const { ap_credits: apCreditsEnabled } = features;

  if (!client || !apCreditsEnabled) return null;

  return (
    <Card
      classes={{
        root: 'credit-info',
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" className="credit-info__header">
            {t('.header', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3" className="credit-info__value">
            {totalCredits}
          </Typography>
        </Grid>
        {currentUser().isStaff() && (
          <div className="credit-info__open-drawer-btn">
            <Button
              size="1x"
              type="tertiary"
              rounded
              onClick={() =>
                MembershipEmbedActions.openModal({
                  subscription: membershipSubscription,
                  redeem: true,
                  clientId,
                })
              }
            >
              {t('.redeem', intl, __filenamespace)}
            </Button>
            <Button
              size="1x"
              type="tertiary"
              rounded
              onClick={() => SwingCreditsDrawerActions.mounted({ clientId })}
            >
              {t('.view', intl, __filenamespace)}
            </Button>
          </div>
        )}
        {currentUser().isClient() && (
          <div className="credit-info__open-drawer-btn">
            <Button
              size="1x"
              type="tertiary"
              rounded
              onClick={() => SwingCreditsDrawerActions.mounted({ clientId })}
            >
              {t('.view', intl, __filenamespace)}
            </Button>
          </div>
        )}
      </Grid>
    </Card>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
    membershipSubscriptionDataStore: MembershipSubscriptionDataStore,
  },
})(CashCreditsInfo);
