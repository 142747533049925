import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Key(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M14.6667 12.6667H10.6667V10H8.88C8.12 11.6133 6.48 12.6667 4.66667 12.6667C2.09333 12.6667 0 10.5733 0 8.00001C0 5.42668 2.09333 3.33334 4.66667 3.33334C6.48 3.33334 8.11333 4.38668 8.88 6.00001H16V10H14.6667V12.6667ZM12 11.3333H13.3333V8.66668H14.6667V7.33334H7.96L7.80667 6.88668C7.34 5.56001 6.07333 4.66668 4.66667 4.66668C2.82667 4.66668 1.33333 6.16001 1.33333 8.00001C1.33333 9.84001 2.82667 11.3333 4.66667 11.3333C6.07333 11.3333 7.34 10.44 7.80667 9.11334L7.96 8.66668H12V11.3333ZM4.66667 10C3.56667 10 2.66667 9.10001 2.66667 8.00001C2.66667 6.90001 3.56667 6.00001 4.66667 6.00001C5.76667 6.00001 6.66667 6.90001 6.66667 8.00001C6.66667 9.10001 5.76667 10 4.66667 10ZM4.66667 7.33334C4.3 7.33334 4 7.63334 4 8.00001C4 8.36668 4.3 8.66668 4.66667 8.66668C5.03333 8.66668 5.33333 8.36668 5.33333 8.00001C5.33333 7.63334 5.03333 7.33334 4.66667 7.33334Z"
        fill="var(--color-tab-gray)"
        id="Key"
      />
    </SvgIcon>
  );
}

export default Key;
