import * as React from 'react';
import { injectIntl } from 'react-intl';
import { TextField } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import MessagingIcon from 'shared/components/icons/Messaging.jsx';
import ContactGroupIcon from 'shared/components/icons/ContactGroup.jsx';
import ButtonMenu from 'shared/components/ButtonMenu.jsx';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import MessagingDrawerActions from 'shared/actions/MessagingDrawerActions.js';
import uhTheme from '_uh_theme.jsx';
import { isCollectionWithContents } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import ContactsGroupsActions from '../Actions';

const styles = {
  actionButton: {
    margin: '10px 6px',
  },

  groupSelector: {
    fontSize: 18,
    marginRight: 0,
    overflow: 'hidden',
  },

  groupSelectorValue: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  toolbar: {
    backgroundColor: 'white',
    justifyContent: 'space-between',
    padding: '0 10px',
  },

  toolbarTitle: {
    color: uhTheme.palette.textColor,
    flex: '0 0 auto',
    paddingRight: 8,
    fontSize: 20,
  },
};

const handleOnKeyDown = event => {
  // Check if the key is the 'Enter' key.
  if (event.keyCode === 13) {
    ContactGroupActions.create({});
  }
};

const SmallScreenToolbar = injectIntl(
  ({ disabled, isAddingGroup, menuItems, selectedGroup, intl }) => (
    <Toolbar style={styles.toolbar}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        style={{ width: '100%' }}
      >
        <ContactGroupIcon style={{ minWidth: 24 }} />
        {isAddingGroup && (
          <TextField
            fullWidth
            placeholder={t('.group_name', intl, __filenamespace)}
            onChange={(_, value) =>
              ContactGroupActions.updateStore(['name'], value)
            }
            onKeyDown={handleOnKeyDown}
            autoFocus
            outline={false}
            value={selectedGroup.name}
            disabled={disabled}
          />
        )}
        <>
          <Select
            fullWidth
            disabled={disabled}
            variant="standard"
            value={selectedGroup.id}
            style={styles.groupSelector}
            SelectDisplayProps={{
              sx: styles.groupSelectorValue,
            }}
            onChange={e =>
              ContactsGroupsActions.selectContactGroup(e.target.value)
            }
          >
            {menuItems}
          </Select>
          {!isAddingGroup && (
            <>
              <IconButton
                onClick={() =>
                  MessagingDrawerActions.contactGroupDrawerOpened()
                }
                style={{ alignSelf: 'center' }}
              >
                <MessagingIcon color={uhColors.blue} />
              </IconButton>
              {selectedGroup.id !== -1 && (
                <IconButton
                  key="remove_group"
                  disabled={disabled}
                  onClick={() =>
                    ContactGroupActions.deleteRequested(selectedGroup.id)
                  }
                  style={{ alignSelf: 'center' }}
                >
                  <DeleteIcon sx={{ color: uhTheme.palette.lightGrey }} />
                </IconButton>
              )}
            </>
          )}
        </>
      </Stack>
    </Toolbar>
  )
);

const LargeScreenToolbar = injectIntl(
  ({
    disabled,
    intl,
    isAddingGroup,
    isSaving,
    menuItems,
    selectedContacts,
    selectedGroup,
  }) => (
    <Toolbar style={styles.toolbar}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ maxWidth: '60%' }}
        >
          <Typography style={styles.toolbarTitle}>
            {t('.contact_group', intl, __filenamespace)}
          </Typography>
          <Select
            sx={{
              '&:before': { borderBottom: 0 },
            }}
            disabled={disabled}
            variant="standard"
            value={selectedGroup.id || ''}
            style={styles.groupSelector}
            SelectDisplayProps={{
              sx: styles.groupSelectorValue,
            }}
            onChange={e =>
              ContactsGroupsActions.selectContactGroup(e.target.value)
            }
          >
            {menuItems}
          </Select>
          {isAddingGroup && (
            <TextField
              disabled={disabled}
              fullWidth
              placeholder={t('.group_name', intl, __filenamespace)}
              onChange={(_, value) =>
                ContactGroupActions.updateStore(['name'], value)
              }
              outline={false}
              onKeyDown={handleOnKeyDown}
              value={selectedGroup.name}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {isAddingGroup && (
            <Button
              variant="contained"
              disabled={!selectedGroup.name || isSaving || disabled}
              key="save_group"
              primary
              style={styles.actionButton}
              onClick={() => ContactGroupActions.create({})}
            >
              {t('.save', intl, __filenamespace)}
            </Button>
          )}
          {!isAddingGroup && (
            <div style={{ display: 'flex' }}>
              <IconButton
                onClick={() =>
                  MessagingDrawerActions.contactGroupDrawerOpened()
                }
                style={{ alignSelf: 'center' }}
              >
                <MessagingIcon color={uhColors.blue} />
              </IconButton>
              {selectedGroup.id !== -1 && (
                <Button
                  color="dark"
                  disabled={disabled}
                  key="remove_group"
                  style={styles.actionButton}
                  onClick={() =>
                    ContactGroupActions.deleteRequested(selectedGroup.id)
                  }
                >
                  {t('.remove_group', intl, __filenamespace)}
                </Button>
              )}
            </div>
          )}
          {selectedGroup.id !== -1 && !selectedGroup.contact_filter_id && (
            <ButtonMenu
              color="dark"
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              disabled={!isCollectionWithContents(selectedContacts) || disabled}
              style={styles.actionButton}
              label={t('.action', intl, __filenamespace)}
              labelPosition="before"
              icon={<MoreVertIcon />}
            >
              <MenuItem
                sx={{ fontSize: '14px' }}
                disabled={
                  !isCollectionWithContents(selectedContacts) || disabled
                }
                onClick={() => ContactsGroupsActions.removeSelectedContacts()}
              >
                {t('.remove', intl, __filenamespace)}
              </MenuItem>
            </ButtonMenu>
          )}
        </Stack>
      </Stack>
    </Toolbar>
  )
);

const ContactGroupToolbar = injectIntl(
  ({
    contactGroups,
    disabled,
    intl,
    selectedContacts,
    selectedGroup,
    smallScreen,
    allContactsCount,
  }) => {
    const isAddingGroup = selectedGroup.id === null;
    const newGroupMenuItem = (
      <MenuItem key="new_group" value={null} sx={{ fontSize: '14px' }}>
        {t('.create_new_group', intl, __filenamespace)}
      </MenuItem>
    );
    const menuItems = contactGroups
      .map(group => {
        const count =
          group.id === -1 ? allContactsCount : group.customer_users_count;

        return (
          <MenuItem key={group.id} value={group.id} sx={{ fontSize: '14px' }}>
            {`${group.name} (${count})`}
          </MenuItem>
        );
      })
      .unshift(newGroupMenuItem);

    if (smallScreen) {
      return (
        <SmallScreenToolbar
          disabled={disabled}
          isAddingGroup={isAddingGroup}
          menuItems={menuItems}
          selectedGroup={selectedGroup}
        />
      );
    }
    return (
      <LargeScreenToolbar
        disabled={disabled}
        isAddingGroup={isAddingGroup}
        menuItems={menuItems}
        selectedContacts={selectedContacts}
        selectedGroup={selectedGroup}
      />
    );
  }
);

export default ContactGroupToolbar;
