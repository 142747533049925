import React from 'react';
import { injectIntl } from 'react-intl';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import ContactImportNativeActions from 'contacts/index/actions/ContactImportNativeActions.js';
import ContactSelect from 'contacts/index/components/ContactImportNative/_ContactSelect.jsx';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import ShowMoreButton from 'contacts/index/components/ContactImportNative/_ShowMoreButton.jsx';
import styles from 'contacts/index/components/ContactImportNative/styles';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const StepSelect = injectIntl(props => {
  const {
    hideIneligible,
    filterString,
    filteredContacts,
    selectedContacts,
    hasMoreToShow,
    intl,
  } = props;

  const enableButton = selectedContacts.size > 0;

  return (
    <div style={styles.selectContacts}>
      <FlexBox style={{ flexDirection: 'column' }}>
        <SearchBar
          onTypingEnd={ContactImportNativeActions.filterStringUpdated}
          iconStyle={styles.searchBarIcon}
          searchText={filterString}
          autoComplete="off"
          style={{ height: 44, flex: '0 0 auto' }}
        />
        <Divider style={merge(styles.divider, { width: 'auto' })} />
      </FlexBox>
      <div style={styles.contactList(enableButton)}>
        <FormControlLabel
          label={t('.ineligible_label', intl, __filenamespace)}
          control={
            <Switch
              labelPlacement="right"
              checked={hideIneligible}
              onChange={() => ContactImportNativeActions.toggleIneligible()}
            />
          }
        />
        {filteredContacts.map(contact => (
          <ContactSelect key={contact.id} contact={contact} />
        ))}
        {filteredContacts.size === 0 && (
          <h2 style={merge({ textAlign: 'left' }, styles.headerText)}>
            {t('.empty_search', intl, __filenamespace)}
          </h2>
        )}
        {hasMoreToShow && <ShowMoreButton />}
      </div>
      <FlexBoxCenter style={styles.buttonContainer(enableButton)}>
        <Button
          variant="contained"
          className="import-contacts-primary-action"
          style={styles.button}
          onClick={ContactImportNativeActions.select}
        >
          {t('.select', intl, __filenamespace, {
            count: selectedContacts.size,
          })}
        </Button>
      </FlexBoxCenter>
    </div>
  );
});

export default StepSelect;
