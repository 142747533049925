import React from 'react';

import MemberCard from 'memberships/components/_MemberCard.jsx';
import ListComponent from 'components/list/index.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import NoMembersIcon from 'shared/components/icons/empty_states/Members.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { copyTextToClipboard } from 'shared/utils/ClipboardUtils.js';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';
import Header from 'memberships/components/MembershipMembers/Header.jsx';
import ListRowHeader from 'memberships/components/MembershipMembers/ListRowHeader.jsx';

function MembersList({
  membershipCancellationStore,
  membershipStore,
  membershipTierDataStore,
  subscriptionsStore,
  intl,
}) {
  const clientIds = membershipStore.clients.map(item => item.id).toOrderedSet();
  const { isMembersLoading, record } = membershipStore;
  const { tiered } = record;
  const activeFilter = membershipStore.getActiveFilterKey();
  const titleIds = !tiered
    ? ListRowHeader(activeFilter).delete('active_membership_tier_name')
    : ListRowHeader(activeFilter);

  const recordCard = clientId => (
    <MemberCard
      key={clientId}
      clientId={clientId}
      membership={membershipStore.record}
      client={membershipStore.clients.get(clientId)}
      subscriptionsStore={subscriptionsStore}
      membershipTierDataStore={membershipTierDataStore}
      membershipCancellationStore={membershipCancellationStore}
      activeFilter={activeFilter}
    />
  );
  return (
    <ListComponent
      header={<Header membershipStore={membershipStore} intl={intl} />}
      actions={MembershipViewingActions}
      ids={clientIds}
      isLoading={isMembersLoading}
      recordCard={recordCard}
      page={membershipStore.clientsPage || 1}
      titleIds={titleIds}
      perPage={membershipStore.clientsPerPage || 10}
      totalCount={membershipStore.totalClientsCount}
      isFromMembersList
      emptyState={
        <EmptyState
          image={<NoMembersIcon />}
          headerText={t('.empty_state_header', intl, __filenamespace)}
          messageText={t('.empty_state_message', intl, __filenamespace)}
          primaryAction={{
            label: t('.copy_url', intl, __filenamespace),
            action: () =>
              copyTextToClipboard(membershipStore.record.url(), () =>
                MessageWindowActions.addMessage(
                  t('.url_copied', intl, __filenamespace)
                )
              ),
          }}
        />
      }
    />
  );
}

export default MembersList;
