import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Typography } from '@upperhand/playmaker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';

import MembershipPurchasingActions from 'memberships/actions/MembershipPurchasingActions.jsx';

function PageHeaderBanner({ membership }) {
  const isMobile = smallScreen();
  const hasPurchaseEnabled = !isLoggedIn() || currentUser().isClient();

  return (
    <Box className="page-header">
      <ReactRouterLink
        className="page-header__back-btn"
        to={customerScopedRoute('memberships')}
      >
        <ArrowBackIcon />
        <FormattedMessage id={messageId('.memberships', __filenamespace)} />
      </ReactRouterLink>
      <Box
        className="page-header__content"
        sx={{ borderLeftColor: membership.color }}
      >
        <Box>
          <Typography variant="h4">{membership.name}</Typography>
          {membership.tiered && (
            <Typography variant="body1">
              {membership.membership_tier_count} Pricing Options
            </Typography>
          )}
          {!membership.tiered && (
            <Typography variant="body1">
              <FormattedCurrency fromCents value={membership.price} />
              {membership.intervalMonths && (
                <span>/{membership.intervalMonths}</span>
              )}
              &nbsp;+&nbsp;
              {membership.join_fee ? (
                <FormattedCurrency fromCents value={membership.join_fee} />
              ) : (
                <FormattedMessage
                  id={messageId('.no_join_fee', __filenamespace)}
                />
              )}
            </Typography>
          )}
        </Box>
        {hasPurchaseEnabled && !isMobile && (
          <Button
            variant="contained"
            classes={{ root: 'page-header__purchase-button' }}
            onClick={() => {
              MembershipPurchasingActions.purchaseClicked({
                membership,
                buyerId: currentUser().customer_user_id,
              });
            }}
          >
            <FormattedMessage id={messageId('.purchase', __filenamespace)} />
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default PageHeaderBanner;
