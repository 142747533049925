import { List, Set } from 'immutable';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import RegistrationField from 'shared/records/RegistrationField.jsx';
import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import TeamStore from 'team_mgmt/shared/stores/TeamStore.jsx';
import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';

const url = ({ eventId, id }) => {
  if (id) {
    return `registration_fields/${id}`;
  }
  if (eventId) {
    return `registration_fields?event_id=${eventId}`;
  }
  return 'registration_fields';
};

const batchUrl = eventId => {
  if (!eventId) {
    throw new Error('eventId is required');
  } else {
    return `registration_fields/batch?event_id=${eventId}`;
  }
};

class RegistrationFieldStore extends UpperHandStore {
  constructor() {
    super();
    this.allRegistrationFields = List();
    this.allRegistrationFieldCategories = Set();
    this.createSubsets();
    this.registrationField = new RegistrationField();
    this.isLoading = false;

    // Creating and updating
    this.clearOnSuccess = false;
    this.saveInProgress = false;
    this.successMessage = null;

    // Deleting
    this.awaitingConfirmation = false;
    this.toDelete = null;

    this.findById = id =>
      this.allRegistrationFields.find(
        registrationField => registrationField.id === id
      );

    this.findByFieldId = id =>
      this.allRegistrationFields.find(
        registrationField => registrationField.meta_registration_field_id === id
      );

    this.bindListeners({
      handleUpdateStore: RegistrationFieldActions.UPDATE_STORE,

      handleList: RegistrationFieldActions.LIST,
      handleListSuccess: [
        RegistrationFieldActions.LIST_SUCCESS,
        RegistrationFieldActions.PERSIST_ALL_CHANGES_SUCCESS,
      ],
      handleListError: RegistrationFieldActions.LIST_ERROR,

      stageRegistrationField: RegistrationFieldActions.STAGE_REGISTRATION_FIELD,
      clearRegistrationField: RegistrationFieldActions.CLEAR_REGISTRATION_FIELD,
      validateSelectedFields: RegistrationFieldActions.VALIDATE_SELECTED_FIELDS,

      handleCreateOrUpdate: RegistrationFieldActions.CREATE_OR_UPDATE,
      handleCreateOrUpdateSuccess:
        RegistrationFieldActions.CREATE_OR_UPDATE_SUCCESS,
      handleCreateOrUpdateError:
        RegistrationFieldActions.CREATE_OR_UPDATE_ERROR,

      persistAllChanges: [
        EventActions.CREATE_SUCCESS,
        EventActions.UPDATE_SUCCESS,
      ],
      persistAllChangesTeam: [
        TeamActions.CREATE_SUCCESS,
        TeamActions.UPDATE_SUCCESS,
      ],
      persistAllChangesError:
        RegistrationFieldActions.PERSIST_ALL_CHANGES_ERROR,
    });
  }

  createSubsets() {
    this.selectedFields = this.allRegistrationFields
      .filter(f => (f.selected || !!f.id) && !f.isMarkedForDeletion())
      .sortBy(f => f.ordinal);
    this.unselectedFields = this.allRegistrationFields.filter(
      f => (!f.selected && !f.id) || f.isMarkedForDeletion()
    );
    const fieldsMarkedForRemoval = this.allRegistrationFields.filter(f =>
      f.isMarkedForDeletion()
    );

    if (this.selectedFields.size > 0 || fieldsMarkedForRemoval.size > 0) {
      EventActions.markDirty.defer(['RegistrationFieldStore', true]);
    } else {
      EventActions.markDirty.defer(['RegistrationFieldStore', false]);
    }
  }

  replaceInList() {
    const index = this.allRegistrationFields.findIndex(
      f =>
        f.meta_registration_field_id ===
        this.registrationField.meta_registration_field_id
    );
    if (index >= 0) {
      this.allRegistrationFields = this.allRegistrationFields
        .remove(index)
        .insert(index, this.registrationField);
      this.createSubsets();
    }
  }

  stageRegistrationField({ id, metaRegistrationFieldId }) {
    this.registrationField =
      (id ? this.findById(id) : this.findByFieldId(metaRegistrationFieldId)) ||
      new RegistrationField();
    this.registrationField = this.registrationField.validate();
  }

  clearRegistrationField() {
    this.registrationField = new RegistrationField();
  }

  validateSelectedFields() {
    this.selectedFields = this.selectedFields.map(field => field.validate());
  }

  handleUpdateStore(keypathsAndValues) {
    List(keypathsAndValues).forEach(([keypath, value]) => {
      this.registrationField = this.registrationField
        .setIn([keypath], value)
        .set('dirty', true);
      if (keypath === 'selected') {
        if (!value && !!this.registrationField.id) {
          // removing a field that was added in another store session
          this.registrationField = this.registrationField.markForDeletion();
        } else if (this.registrationField.isMarkedForDeletion() && !!value) {
          this.registrationField =
            this.registrationField.markForDeletion(false);
        }
      }
    });

    this.registrationField.validate();
    this.replaceInList();
  }

  handleList(eventId) {
    this.isLoading = true;
    return uhApiClient.get({
      url: url({ eventId }),
      success: RegistrationFieldActions.listSuccess,
      error: RegistrationFieldActions.listError,
    });
  }

  handleListSuccess(data) {
    this.isLoading = false;
    const categories = [];
    this.allRegistrationFields = List(
      data.registration_fields.map(registrationField => {
        categories.push(registrationField.category);
        return new RegistrationField(registrationField);
      })
    );
    this.allRegistrationFieldCategories = Set(categories);
    this.createSubsets();
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing Registration Fields', args);
  }

  handleCreateOrUpdate(options = {}) {
    const opts = options || {};

    this.clearOnSuccess = !!opts.clearOnSuccess;

    if (this.registrationField.id) {
      this.update(opts.showSuccessMessage);
    } else {
      this.create(opts.showSuccessMessage, opts.eventId);
    }
  }

  create(showSuccessMessage, eventId) {
    this.registrationField = this.registrationField.validate();
    if (!this.registrationField.hasErrors() && !this.saveInProgress) {
      this.saveInProgress = true;

      if (showSuccessMessage) {
        this.successMessage = 'Registration Field created successfully.';
      }

      uhApiClient.post({
        url: url({ eventId }),
        data: this.payload(),
        success: RegistrationFieldActions.createOrUpdateSuccess,
        error: RegistrationFieldActions.createOrUpdateError,
      });
    }
  }

  update(showSuccessMessage) {
    this.registrationField = this.registrationField.validate();
    if (!this.registrationField.hasErrors() && !this.saveInProgress) {
      this.saveInProgress = true;

      if (showSuccessMessage) {
        this.successMessage = 'Registration Field updated successfully.';
      }

      uhApiClient.put({
        url: url({ id: this.registrationField.id }),
        data: this.payload(),
        success: RegistrationFieldActions.createOrUpdateSuccess,
        error: RegistrationFieldActions.createOrUpdateError,
      });
    }
  }

  handleCreateOrUpdateSuccess(data) {
    const newRegistrationField = new RegistrationField(data);
    const i = this.allRegistrationFields.findKey(
      registrationField =>
        (newRegistrationField.id &&
          newRegistrationField.id === registrationField.id) ||
        newRegistrationField.meta_registration_field_id ===
          registrationField.meta_registration_field_id
    );

    if (i >= 0) {
      this.allRegistrationFields = this.allRegistrationFields.set(
        i,
        newRegistrationField
      );
    } else {
      this.allRegistrationFields =
        this.allRegistrationFields.push(newRegistrationField);
    }

    if (this.clearOnSuccess) {
      this.registrationField = new RegistrationField();
    } else {
      this.registrationField = newRegistrationField;
    }

    if (this.successMessage) {
      MessageWindowActions.addMessage.defer(this.successMessage);
    }

    this.clearOnSuccess = false;
    this.saveInProgress = false;
    this.successMessage = null;
  }

  handleCreateOrUpdateError(...args) {
    this.clearOnSuccess = false;
    this.saveInProgress = false;
    this.inlineEditorOpen = false;
    this.successMessage = null;

    this.notifyError('error while saving Registration Field', args);
  }

  /*
   * needs specs
   */
  persistAllChanges() {
    // f.dirty && f.id - fields which already saved but changed
    // f.dirty && !f.id && f.selected - prevent sending fields which changed but is not selected
    const dirtyFields = this.allRegistrationFields.filter(
      f => (f.dirty && f.id) || (f.dirty && !f.id && f.selected)
    );
    if (dirtyFields.size === 0) {
      return;
    }

    this.waitFor(EventStore);

    const isValid = dirtyFields.every(field => !field.validate().hasErrors());
    if (isValid) {
      const payload = {
        registration_fields: dirtyFields.map(field => field.toServer()),
      };
      uhApiClient.put({
        url: batchUrl(EventStore.getState().customerEvent.id),
        data: JSON.stringify({ attributes: payload }),
        success: RegistrationFieldActions.listSuccess,
        error: RegistrationFieldActions.persistAllChangesError,
      });
    }
  }

  persistAllChangesTeam() {
    const dirtyFields = this.allRegistrationFields.filter(
      f => (f.dirty && f.id) || (f.dirty && !f.id && f.selected)
    );
    if (dirtyFields.size === 0) {
      return;
    }

    this.waitFor(TeamStore);

    const isValid = dirtyFields.every(field => !field.validate().hasErrors());
    if (isValid) {
      const payload = {
        registration_fields: dirtyFields.map(field => field.toServer()),
      };
      uhApiClient.put({
        url: batchUrl(TeamStore.getState().customerTeam.id),
        data: JSON.stringify({ attributes: payload }),
        success: RegistrationFieldActions.listSuccess,
        error: RegistrationFieldActions.persistAllChangesError,
      });
    }
  }

  persistAllChangesError(...args) {
    this.clearOnSuccess = false;
    this.saveInProgress = false;
    this.inlineEditorOpen = false;
    this.successMessage = null;

    this.notifyError(
      'error while batch-persisting changed Registration Fields',
      args
    );
  }

  payload() {
    return JSON.stringify({ attributes: this.registrationField.toServer() });
  }
}

export default alt.createStore(
  RegistrationFieldStore,
  'RegistrationFieldStore'
);
