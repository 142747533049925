import * as React from 'react';
import moment from 'moment-timezone';
import AltContainer from 'alt-container';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, TextField } from '@upperhand/playmaker';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

import ChatBubbleIcon from 'shared/components/icons/ChatBubble.jsx';
import CalendarIcon from 'shared/components/icons/Calendar.jsx';
import CustomerDashboardActions from 'dashboards/actions/CustomerDashboardActions.jsx';
import CustomerDashboardStore from 'dashboards/stores/CustomerDashboardStore.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ReportIcon from 'shared/components/icons/Report.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { t, l, messageId } from 'shared/utils/LocaleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  alert: {
    position: 'relative',
    marginBottom: 10,
    border: '1px solid #F5A623',
  },

  alertsContainer: {
    marginBottom: 30,
  },

  announcementContainer: {
    marginBottom: 30,
    padding: 20,
  },

  content: {
    margin: LEFT_MARGIN_PIXELS,
  },

  currentDate: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'benton-sans-condensed, sans-serif',
    marginBottom: 30,
  },

  header: {
    background: 'white',
    fontSize: 22,
    height: 64,
    paddingLeft: LEFT_MARGIN_PIXELS,
  },

  reportsContainer: {
    marginBottom: 30,
  },

  reportLabel: {
    fontSize: 18,
    fontFamily: 'benton-sans-condensed, sans-serif',
    fontWeight: 'bold',
  },

  reportButton: {
    width: '100%',
    height: 60,
  },
};

const startDate = moment().startOf('week');
const endDate = moment().endOf('week');

function Alert({ alert }) {
  return (
    <Paper style={styles.alert}>
      <Grid container justify="center">
        <div style={{ padding: '10px 0 10px 20px' }}>
          <WarningIcon style={{ marginRight: 10 }} />
          <Link to={customerScopedRoute(alert.link)} style={{ fontSize: 15 }}>
            {alert.message}
          </Link>
        </div>
      </Grid>
      <IconButton
        onClick={() => CustomerDashboardActions.alertDismissed(alert.id)}
        style={{ position: 'absolute', top: -2, right: 0 }}
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
}

function ReportBox({ title, report, currency }) {
  const current = report.get('current');
  const previous = report.get('previous');
  const previousColor = current >= previous ? 'lightGreen' : 'red';

  const currentDisplay = currency ? (
    <FormattedCurrency value={current} fromCents />
  ) : (
    <span>{current}</span>
  );

  const previousDisplay = currency ? (
    <span style={{ color: previousColor }}>
      <FormattedCurrency value={previous} fromCents />
    </span>
  ) : (
    <span style={{ color: previousColor }}>{previous}</span>
  );

  return (
    <Grid item justify="center" alignContent="stretch" lg={3} sm={6} xs={12}>
      <div
        style={{
          height: 150,
          backgroundColor: 'var(--color-old-gray)',
          color: 'white',
          padding: '30px',
        }}
      >
        <Grid container justify="center">
          <Grid item>
            <div
              style={{
                fontSize: 18,
                fontFamily: 'benton-sans-condensed, sans-serif',
                fontWeight: 'bold',
                marginBottom: 8,
              }}
            >
              {title}
            </div>
            <div style={{ fontSize: 30, marginBottom: 15 }}>
              {currentDisplay}
            </div>
            <div style={{ fontSize: 14 }}>
              <FormattedMessage
                id={messageId('.last_week', __filenamespace)}
                values={{ previousValue: previousDisplay }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

function ReportsContainer({ children }) {
  return (
    <div style={styles.reportsContainer}>
      <Grid container spacing={3} justify="space-between">
        {children}
      </Grid>
    </div>
  );
}

const onAnnouncementClear = () =>
  CustomerDashboardActions.announcementTextCleared();
const onAnnouncementSave = () =>
  CustomerDashboardActions.announcementTextSaved();
const onAnnouncementFieldChange = (_, text) =>
  CustomerDashboardActions.announcementTextUpdated(text);

function AnnouncementForm({ announcement, announcementChanged, intl }) {
  return (
    <Paper style={styles.announcementContainer}>
      <Grid container alignItems="center">
        <ChatBubbleIcon style={{ marginRight: 10 }} />
        <h1
          style={{
            fontSize: 20,
            fontFamily: 'benton-sans-condensed, sans-serif',
            fontWeight: 'bold',
          }}
        >
          <FormattedMessage id={messageId('.announcement', __filenamespace)} />
        </h1>
      </Grid>
      <p style={{ marginBottom: 15, marginTop: 15, color: uhColors.iconGrey }}>
        <FormattedMessage
          id={messageId('.announcement_info', __filenamespace)}
        />
      </p>
      <div style={{ marginBottom: 16 }}>
        <TextField
          fullWidth
          placeholder={t('.announcement_hint', intl, __filenamespace)}
          multiline
          onChange={onAnnouncementFieldChange}
          value={announcement}
        />
      </div>
      <Grid container justify="flex-end">
        <Button style={{ marginRight: 5 }} onClick={onAnnouncementClear}>
          {t('actions.clear', intl, __filenamespace)}
        </Button>
        <Button
          variant="contained"
          disabled={!announcementChanged}
          onClick={onAnnouncementSave}
        >
          {t('actions.save', intl, __filenamespace)}
        </Button>
      </Grid>
    </Paper>
  );
}

function CustomerDashboard({ dashboardStore, intl }) {
  const isManager = currentUser().isManager();
  return (
    <div>
      <FlexBoxCenter style={styles.header}>
        <FormattedMessage
          id={messageId('.welcome_back', __filenamespace)}
          values={{ name: currentUser().first_name }}
        />
      </FlexBoxCenter>

      <Divider style={{ height: 2 }} />

      <div className="iphone-x-content" style={styles.content}>
        {dashboardStore.alerts.size > 0 && (
          <div style={styles.alertsContainer}>
            {dashboardStore.alerts.map(alert => (
              <Alert alert={alert} />
            ))}
          </div>
        )}

        <div style={styles.currentDate}>
          {l(startDate, 'dates.full', intl)} - {l(endDate, 'dates.full', intl)}
        </div>

        <ReportsContainer>
          <ReportBox
            title={t('.new_clients', intl, __filenamespace)}
            report={dashboardStore.clientReport}
          />
          <ReportBox
            title={t('.events', intl, __filenamespace)}
            report={dashboardStore.eventReport}
          />
          <ReportBox
            title={t('.participants', intl, __filenamespace)}
            report={dashboardStore.participantReport}
          />
          <ReportBox
            title={t('.revenue', intl, __filenamespace)}
            report={dashboardStore.revenueReport}
            currency
          />
        </ReportsContainer>

        <AnnouncementForm
          announcement={dashboardStore.announcementText}
          announcementChanged={dashboardStore.announcementTextChanged}
          intl={intl}
        />
        <Grid container spacing={4} justify="space-between">
          <Grid item lg={6} xs={12}>
            <Link
              to={customerScopedRoute('/events')}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="default"
                startIcon={
                  <CalendarIcon
                    color={uhColors.activeBlue}
                    style={{ marginRight: 8, verticalAlign: 'sub' }}
                  />
                }
                style={styles.reportButton}
              >
                <Typography style={styles.reportLabel}>
                  {t('.view_events', intl, __filenamespace)}
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid item lg={6} xs={12}>
            {isManager ? (
              <Button
                variant="contained"
                color="default"
                disabled
                startIcon={
                  <ReportIcon
                    color={uhColors.pink}
                    style={{ marginRight: 8, verticalAlign: 'sub' }}
                  />
                }
                style={styles.reportButton}
              >
                <Typography style={styles.reportLabel}>
                  {t('.view_reports', intl, __filenamespace)}
                </Typography>
              </Button>
            ) : (
              <Link
                to={customerScopedRoute('/reports/contacts_report')}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  color="default"
                  startIcon={
                    <ReportIcon
                      color={uhColors.pink}
                      style={{ marginRight: 8, verticalAlign: 'sub' }}
                    />
                  }
                  style={styles.reportButton}
                >
                  <Typography style={styles.reportLabel}>
                    {t('.view_reports', intl, __filenamespace)}
                  </Typography>
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function CustomerDashboardWrapper({ intl }) {
  React.useEffect(() => {
    CustomerDashboardActions.mounted();
  }, []);

  return (
    <AltContainer stores={{ dashboardStore: CustomerDashboardStore }}>
      <CustomerDashboard intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(CustomerDashboardWrapper);
