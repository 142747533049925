class BrowseEventsBookDrawerActions {
  constructor() {
    this.generateActions(
      'openDrawer',
      'closeDrawer',
      'scheduleSession',
      'sessionBooked',
      'fetchEventSuccess',
      'fetchEventError'
    );
  }
}

export default alt.createActions(BrowseEventsBookDrawerActions);
