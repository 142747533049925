export default alt.generateActions(
  'list',
  'listSuccess',
  'listError',

  'schedule',
  'scheduleSuccess',
  'scheduleError',

  'cancel',
  'cancelSuccess',
  'cancelError',

  'updateStore'
);
