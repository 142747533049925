import { Iterable, List, Record } from 'immutable';

import CardBilling from 'containers/payments/records/CardBilling.js';

import FieldErrors from 'shared/records/FieldErrors.jsx';

const NUMERIC_FIELDS = List(['number', 'cvc', 'address_zip']);

const addSpaces = string =>
  string
    .replace(/\W/gi, '')
    .replace(/(.{4})/g, '$& ')
    .trim();

const stripNonNumeric = string => string.replace(/[\D]/g, '');

const formatExpString = string => {
  let s = string;

  // once we have 3 digits, add the spaces and slash
  if (/^\d{3,}$/.test(string)) {
    // eslint-disable-next-line prefer-regex-literals
    s = string.match(new RegExp('.{1,2}', 'g')).join(' / ');
  }

  // get rid of anything that is not a number, slash, or space
  // eslint-disable-next-line prefer-regex-literals
  s = s.replace(new RegExp(/[^\d\s/]/, 'g'), '');

  return s;
};

// TODO: reconcile fields with PaymentMethod record/parser
export default class PaymentCard extends Record({
  brand: '',
  number: '',
  last4: '',
  name: '',
  exp: '',
  exp_month: '',
  expMonth: '',
  exp_year: '',
  expYear: '',
  cvc: '',
  address_zip: '',
  billingAddress: new CardBilling(),
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    let newObj;

    if (Iterable.isIterable(obj)) {
      newObj = obj.toJS();
    } else {
      newObj = obj;
    }

    super({
      ...newObj,
      billingAddress: new CardBilling(newObj.billingAddress),
    });
  }

  // HACK: include multiple cases of some fields
  isUsable() {
    return (
      !!this.brand &&
      (!!this.expMonth || !!this.exp_month) &&
      (!!this.expYear || !!this.exp_year) &&
      !!this.last4
    );
  }

  set(key, value) {
    let val = value;

    if (NUMERIC_FIELDS.includes(key)) {
      val = stripNonNumeric(value);
    }

    if (key === 'number') {
      val = addSpaces(value);
    }

    if (key === 'exp') {
      val = formatExpString(value);
    }

    return Record.prototype.set.call(this, key, val);
  }

  setError(key, value) {
    const errors = this.errors.add(key, value);

    return this.set('errors', errors);
  }

  validate(stripeUsed = false) {
    let errors = new FieldErrors();

    if (!this.name.length) {
      errors = errors.add('name', 'Name is required');
    }

    if (!stripeUsed) {
      errors = errors.merge(this.billingAddress.validate());
    }

    return this.set('errors', errors);
  }

  validateStripe() {
    let errors = new FieldErrors();

    if (!this.name.length) {
      errors = errors.add('name', 'Name is required');
    }

    return this.set('errors', errors);
  }

  validateBillingAddress() {
    const errors = this.billingAddress.validate();

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  stripePayload() {
    const data = this.toJS();

    data.address_zip = data.billingAddress.zip;

    delete data.errors;
    delete data.billingAddress;

    return data;
  }

  paysafePayload() {
    const data = {
      vault: {
        holderName: this.name,
        billingAddress: {
          country: this.billingAddress.country,
          zip: this.billingAddress.zip,
          city: this.billingAddress.city,
          state: this.billingAddress.state,
          street: this.billingAddress.street,
          street2: this.billingAddress.street2,
        },
      },
    };

    return data;
  }
}
