import React from 'react';
import { injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';
import { t } from 'shared/utils/LocaleUtils.js';
import { PAYMENT_STATUS } from 'records/ScheduledPayment';

function PaymentTitle({
  payment,
  waivedStatus,
  variant,
  hasErrorColor = false,
  className,
  showWaivedAmount,
  intl,
}) {
  const status =
    payment.isCancelled && !payment.isCompleted
      ? PAYMENT_STATUS.CANCELLED
      : waivedStatus || payment.status;
  const paymentLabel = showWaivedAmount
    ? `.title_amount_${status}`
    : `.title_payment_${status}`;
  return (
    <Typography
      variant={variant}
      className={className}
      color={hasErrorColor ? 'error' : 'initial'}
    >
      {t(paymentLabel, intl, __filenamespace)}
    </Typography>
  );
}

export default injectIntl(PaymentTitle);
