import { List, Map, fromJS } from 'immutable';

import AccountSetupPaysafeActions from 'onboarding/actions/AccountSetupPaysafeActions.js';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import IdentityStepActions from 'onboarding/actions/IdentityStepActions.js';
import OnboardAPI from 'onboarding/OnboardAPI.js';
import PaysafeUBO from 'shared/records/PaysafeUBO';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { asArray } from 'shared/utils/ObjectUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

function identityFieldsToJSON(fields) {
  const questions = {};
  fields
    .get('questions')
    .entrySeq()
    .forEach(([key, value]) => {
      questions[key] = value.get('selection');
    });

  return {
    attributes: {
      responseId: fields.get('responseId'),
      questionSetId: fields.get('questionSetId'),
      answers: questions,
    },
  };
}

class IdentityStepStore extends UpperHandStore {
  constructor() {
    super();

    this.owners = List();
    this.isLoading = false;

    this.bindListeners({
      setDefaultOwnerInfo: AccountSetupPaysafeActions.stepperMounted,
      handleUpdate: IdentityStepActions.ownerUpdated,
      handleSubmit: IdentityStepActions.submitForm,
      handleAddOwner: IdentityStepActions.addOwner,
      handleRemoveOwner: IdentityStepActions.removeOwner,
      handleAnswerIdentityQuestion: IdentityStepActions.answerIdentityQuestion,
      handleUpdatePercentageOwnership:
        IdentityStepActions.updatePercentageOwnership,
    });

    this.initialSetup = true;
    this.identityVerification = Map();
    this.identity_answers = {};
    this.disableIdentitySubmission = true;
    this.isLoading = false;
    this.fields = null;
    this.forms = List();
    this.hasError = false;
  }

  setDefaultOwnerInfo() {
    if (!this.initialSetup) {
      return;
    }

    this.initialSetup = false;
    const user = currentUser();
    this.owners = List([
      new PaysafeUBO({
        first_name: user.first_name,
        last_name: user.last_name,
        address: {
          street: user.address_line_1,
          street2: user.address_line_2,
          city: user.city,
          state: user.state,
          zip: user.postal_code,
        },
      }),
    ]);
  }

  handleUpdate({ keyPath, value, index }) {
    let val = value;

    if (keyPath === 'phone') {
      val = value.replace(/[^0-9-]/g, '');
    }

    if (keyPath === 'ssn') {
      val = value.replace(/[^\d]*/g, '');
    }

    this.owners = this.owners.setIn([index, ...asArray(keyPath)], val);

    if (!this.owners.get(index).isValid()) {
      this.owners = this.owners.update(index, owner => owner.validate());
    }
  }

  handleAddOwner() {
    if (this.owners.size >= 4) {
      return false;
    }

    this.owners = this.owners.push(new PaysafeUBO());
  }

  handleRemoveOwner(index) {
    if (index === 0) {
      return false;
    }

    this.owners = this.owners.delete(index);
  }

  handleAddForm() {
    this.forms = this.forms.set(this.forms.size, this.setupFields());
  }

  handleSubmit() {
    this.owners = this.owners.map(o => o.validate());

    if (this.owners.some(o => !o.isValid())) {
      return;
    }

    if (this._identityQuestionsEnabled()) {
      this._submitIdentityVerificationSubmit();
    } else {
      this._submitIdentity();
    }
  }

  handleUpdatePercentageOwnership(checked) {
    this.hasControllingInterest = checked;
  }

  _identityQuestionsEnabled() {
    const questions = this.identityVerification.get('questions');
    return questions && !questions.isEmpty();
  }

  async _loadIdentityVerificationQuestions() {
    const response = await OnboardAPI.beginIdentityRequest(this.ownerPayload());
    const oldQuestions = this.identityVerification.get('questions');

    this.identityVerification = fromJS(response);

    // Select same questions as before to match UI
    if (oldQuestions !== undefined) {
      oldQuestions.forEach((value, questionID) => {
        const selection = value.get('selection');
        const fieldPath = ['questions', questionID, 'selection'];
        this.identityVerification = this.identityVerification.setIn(
          fieldPath,
          selection
        );
      });
    }
  }

  async _submitIdentity() {
    this.isLoading = true;

    try {
      await OnboardAPI.submitIdentity(this.ownerPayload());
      AccountSetupPaysafeActions.nextStep.defer();
    } catch (error) {
      ApiErrorDialogActions.error.defer(error);
      this.notifyError('error submitting identity step', error);
    }

    this.isLoading = false;
    this.emitChange();
  }

  validateIdentityForm() {
    let questionsAnswered = true;

    const questions = this.identityVerification.get('questions');
    questions.entrySeq().forEach(([_, value]) => {
      if (typeof value.get('selection') === 'undefined') {
        questionsAnswered = false;
      }
    });

    this.disableIdentitySubmission = !questionsAnswered;
  }

  handleAnswerIdentityQuestion([event, value]) {
    const questionId = event.target.name.split('-').pop();
    const fieldPath = ['questions', questionId, 'selection'];
    this.identityVerification = this.identityVerification.setIn(
      fieldPath,
      value
    );
    this.validateIdentityForm();
  }

  async _submitIdentityVerificationSubmit() {
    this.isLoading = true;
    const data = JSON.stringify(
      identityFieldsToJSON(this.identityVerification)
    );

    try {
      await OnboardAPI.completeIdentityRequest(data);
      AccountSetupPaysafeActions.nextStep.defer();
    } catch (error) {
      ApiErrorDialogActions.error.defer(error);
      this.notifyError('error submitting identity verification step', error);

      try {
        await this._loadIdentityVerificationQuestions();
      } catch (_) {
        // ignore error
      }
    }

    this.isLoading = false;
    this.emitChange();
  }

  ownerPayload() {
    return JSON.stringify({
      owners: this.owners.map(o => o.toServer()).toJS(),
    });
  }
}

export default alt.createStore(IdentityStepStore, 'IdentityStepStore');
