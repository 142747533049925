import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { Grid, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { Balance } from 'records';

function BalanceCard({ intl, balance }) {
  const effectiveAt = moment(balance.get('effectiveAt'));
  const effectiveAtValue = effectiveAt.isSame(moment(), 'day')
    ? t('.date_today', intl, __filenamespace, {
        date: effectiveAt.format('h:mma'),
      })
    : effectiveAt.format('MM/DD/YYYY • h:mma');

  return (
    <div className="details__card">
      <Grid container xs={12} spacing={1}>
        <Grid container item xs={12} justify="space-between">
          <Grid item>
            <Typography variant="subtitle2" className="details__title">
              {balance.get('product')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              align="right"
              className={clsx(
                'details__action',
                balance.get('balanceRemaining') < 0 &&
                  'details__negative_balance'
              )}
            >
              <FormattedCurrency
                value={Math.abs(balance.get('balanceRemaining'))}
                fromCents
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-between">
          <Grid item>{effectiveAtValue}</Grid>
          <Grid item>
            {balance.get('paymentType') && (
              <Typography variant="body2">
                {t(
                  `.payment_method_${
                    balance.get('paymentType')?.split(',')[0]
                  }`,
                  intl,
                  __filenamespace
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

BalanceCard.propTypes = {
  intl: PropTypes.object.isRequired,
  balance: PropTypes.instanceOf(Balance).isRequired,
};

export default BalanceCard;
