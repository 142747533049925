import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import {
  TextField,
  Grid,
  Dropdown,
  Button,
  Typography,
} from '@upperhand/playmaker';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';

import AccountCreditsActions from 'containers/clientProfile/components/AccountCredits/Actions';
import AccountCreditStore, {
  CREDIT_TYPES,
} from 'containers/clientProfile/components/AccountCredits/Store';

function CreationStep({ intl, accountCreditStore, clientDataStore }) {
  const { formFields, clientId } = accountCreditStore;
  const { clients } = clientDataStore;
  const client = clients.get(clientId, null);

  const handleFieldChange = e => {
    AccountCreditsActions.handleFieldChange({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleAmountChange = (e, value) => {
    AccountCreditsActions.handleFieldChange({
      field: e.target.name,
      value,
    });
  };

  const handleCardNumberChange = e => {
    const regex = /^\d{0,15}$/;

    if (regex.test(e.target.value)) {
      AccountCreditsActions.handleFieldChange({
        field: e.target.name,
        value: e.target.value,
      });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="space-between"
      className="account-credits-create"
    >
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body1" className="account-credits-create__title">
            {t('.title', intl, __filenamespace)}
          </Typography>
        </Grid>
        {client && (
          <Grid item>
            <ClientDetails client={client} />
          </Grid>
        )}
        <Grid item>
          <Typography
            variant="fieldLabel"
            className="account-credits-create__input-label"
          >
            {t('.amount', intl, __filenamespace)}
          </Typography>
          <CurrencyTextField
            fullWidth
            classes={{ root: 'account-credits-create__input-root' }}
            name="amount"
            value={formFields.get('amount')}
            converter={e => parseInt(e, 10)}
            onChange={handleAmountChange}
          />
        </Grid>
        {formFields.get('credit_type') === CREDIT_TYPES.gift_card && (
          <Grid item>
            <TextField
              name="card_number"
              label={t('.card_number', intl, __filenamespace)}
              value={formFields.get('card_number')}
              onChange={handleCardNumberChange}
            />
          </Grid>
        )}
        <Grid item container spacing={2} direction="column">
          <Grid item>
            <TextField
              multiline
              rows={4}
              name="note"
              label={t('.note', intl, __filenamespace)}
              value={formFields.get('note')}
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item>
            <Dropdown
              fullWidth
              label={t('.credit_type', intl, __filenamespace)}
              name="credit_type"
              value={formFields.get('credit_type')}
              items={[
                {
                  label: t('.gift', intl, __filenamespace),
                  value: CREDIT_TYPES.gift_card,
                },
                {
                  label: t('.store', intl, __filenamespace),
                  value: CREDIT_TYPES.store_credit,
                },
              ]}
              onChange={handleFieldChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          fullWidth
          classes={{
            root: 'account-credits-create__add-btn',
          }}
          disabled={!formFields.get('amount', null)}
          onClick={AccountCreditsActions.createCredit}
        >
          {t('.add_credit', intl, __filenamespace)}
        </Button>
      </Grid>
    </Grid>
  );
}

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      accountCreditStore: AccountCreditStore,
      clientDataStore: ClientDataStore,
    },
  })
)(CreationStep);
