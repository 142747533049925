class RSVPActions {
  constructor() {
    this.generateActions(
      'mounted',
      'reset',
      'selectTab',
      'remind',
      'remindSuccess',
      'remindError',
      'updateRsvpStatus',
      'updateRsvpStatusSuccess',
      'updateRsvpStatusError',
      'listRegistrationsSuccess',
      'listRegistrationsError',
      'listClientsSuccess',
      'listClientsError'
    );
  }
}

export default alt.createActions(RSVPActions);
