import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Icon } from '@upperhand/playmaker';
import Avatar from '@mui/material/Avatar';

import uhTheme from '_uh_theme.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { getInitials } from 'shared/utils/StringUtils.jsx';

import User from 'event_mgmt/shared/records/User.jsx';
import Staff from 'shared/records/Staff.jsx';
import Client from 'shared/records/Client.jsx';

function AvatarPreview({ intl, onPopupOpen, user }) {
  const text = user.isBlank()
    ? t('.default_name', intl, __filenamespace)
    : user.name();

  return (
    <div className="client-profile__avatar-preview">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="client-profile__avatar-preview__popup-container"
        onClick={onPopupOpen}
      >
        <Avatar
          size={150}
          color={uhTheme.palette.lightGrey}
          src={user.profile_image.url}
          imgProps={{ crossOrigin: 'anonymous' }}
          className="client-profile__avatar-preview__avatar"
        >
          {getInitials(text)}
        </Avatar>
        <Button
          classes={{
            root: 'client-profile__avatar-preview__popup',
          }}
          size="1x"
          type="secondary"
          icon={
            <Icon
              name="camera"
              className="client-profile__avatar-preview__icon"
            />
          }
          rounded
        />
      </div>
    </div>
  );
}

AvatarPreview.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.instanceOf(User),
    PropTypes.instanceOf(Staff),
    PropTypes.instanceOf(Client),
  ]).isRequired,
  intl: PropTypes.object.isRequired,
  onPopupOpen: PropTypes.func,
};

AvatarPreview.defaultProps = {
  onPopupOpen: () => {},
};

export default injectIntl(AvatarPreview);
