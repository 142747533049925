import { Map } from 'immutable';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import AutoCompleteItemListActions from 'shared/actions/AutoCompleteItemListActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { MembershipSource } from 'sources';

class AutoCompleteItemListStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      listItems: PayrollReportActions.MOUNTED,
      listSuccess: AutoCompleteItemListActions.LIST_SUCCESS,
      listError: AutoCompleteItemListActions.LIST_ERROR,
      updateItemSearchString: AutoCompleteItemListActions.SEARCH_STRING_UPDATED,
    });
  }

  reset() {
    this.items = Map();
    this.itemSearchString = '';
    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.shouldFetch = true;
    this.isLoading = false;

    this.formattedItems = Map();
  }

  listItems() {
    if (!hasAdminPermission()) {
      return;
    }
    if (this.shouldFetch) {
      this.isLoading = true;
      this.shouldFetch = false;
      MembershipSource.getMembershipList({
        params: this.queryParams(),
        success: AutoCompleteItemListActions.listSuccess,
        error: AutoCompleteItemListActions.listError,
      });
    }
  }

  listSuccess({ memberships, total_count: totalCount }) {
    this.isLoading = false;
    this.totalCount = totalCount;
    this.items = memberships;
    this.formattedItems = this.items.map(i => ({ text: i.name, value: i.id }));
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing items', args);
  }

  updateItemSearchString(itemSearchString) {
    if (!this.shouldFetch && this.itemSearchString === itemSearchString) {
      return;
    }

    this.itemSearchString = itemSearchString;
    this.page = 1;
    this.shouldFetch = true;

    this.listItems();
  }

  queryParams() {
    return {
      page: this.page,
      per_page: this.perPage,
      search: this.itemSearchString,
    };
  }
}

export default alt.createStore(
  AutoCompleteItemListStore,
  'AutoCompleteItemListStore'
);
