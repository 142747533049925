import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import WaiversReportActions from 'reporting/waivers/actions/WaiversReportActions';
import { formatDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';
import { WAIVERS_REPORT_NAMES } from 'reporting/waivers/stores/WaiversReportStore';
import { t } from 'shared/utils/LocaleUtils.js';

function CustomWaiverandUpperHandPolicyReport({ policyReport, intl }) {
  return (
    <Widget
      title={t('.title', intl, __filenamespace)}
      actions={[
        {
          icon: 'cloud_download',
          onClick: () =>
            WaiversReportActions.downloadReport(
              WAIVERS_REPORT_NAMES.POLICY_REPORT
            ),
        },
      ]}
    >
      <TableContainer sx={{ maxHeight: 300 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('.full_name', intl, __filenamespace)}</TableCell>
              <TableCell>
                {t('.last_accepted_custom_waiver', intl, __filenamespace)}
              </TableCell>
              <TableCell>
                {t('.days_past_due', intl, __filenamespace)}
              </TableCell>
              <TableCell>
                {t('.last_accepted_terms', intl, __filenamespace)}
              </TableCell>
              <TableCell>{t('.last_login', intl, __filenamespace)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policyReport.map((policyItem, i) => (
              /* eslint-disable react/no-array-index-key */
              <TableRow key={`${i}+${policyItem.id}`}>
                <TableCell>{policyItem.full_name}</TableCell>
                <TableCell>
                  {formatDate(policyItem.accepted_customer_terms_at)}
                </TableCell>
                <TableCell>{policyItem.days_past_due}</TableCell>
                <TableCell>
                  {formatDate(policyItem.accepted_upper_hand_terms_at)}
                </TableCell>
                <TableCell>{formatDate(policyItem.last_login_at)}</TableCell>
              </TableRow>
              /* eslint-enable react/no-array-index-key */
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Widget>
  );
}

export default CustomWaiverandUpperHandPolicyReport;
