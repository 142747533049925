/* eslint-disable import/prefer-default-export */
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { List } from 'immutable';
import { onError, onSuccess } from 'sources/handlers';

export const postPaidUnpaid = ({ params, success, error }) => {
  uhApiClient.post({
    url: 'commission_payrolls/paid_unpaid',
    data: JSON.stringify(params),
    success: () => success(),
    error: onError(List([error])),
  });
};

export const listAttendee = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'customer_users',
    data: params,
    success: onSuccess(data => data, List([success])),
    error: onError(List([error])),
  });
};
