import { Map, OrderedSet } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import EventClass from 'shared/records/EventClass';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import { ClassesSource, EventTypeSource } from 'sources';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ClassCreationActions from './Actions';

class ClassCreationStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      toggleEditModal: ClassCreationActions.toggleEditModal,
      toggleCreationModal: ClassCreationActions.toggleCreationModal,
      createClass: ClassCreationActions.createClass,
      updateClass: ClassCreationActions.updateClass,
      updateClassSuccess: ClassCreationActions.updateClassSuccess,
      updateClassError: ClassCreationActions.updateClassError,
      completeClass: ClassCreationActions.completeClass,
      completeClassSuccess: ClassCreationActions.completeClassSuccess,
      createClassSuccess: ClassCreationActions.createClassSuccess,
      createClassError: ClassCreationActions.createClassError,
      handleFieldChange: ClassCreationActions.handleFieldChange,
      fetchEventTypes: ClassCreationActions.fetchEventTypes,
      fetchEventTypesSuccess: ClassCreationActions.fetchEventTypesSuccess,
      fetchEventTypesError: ClassCreationActions.fetchEventTypesError,
      createEventTypeSuccess: ClassCreationActions.createEventTypeSuccess,
    });
  }

  reset() {
    this.eventTypesIds = OrderedSet();
    this.partialReset();
  }

  partialReset() {
    this.classIdToUpdate = null;
    this.classForm = Map(new EventClass());
    this.formErrors = new FieldErrors({});
    this.showCreationModal = false;
    this.creatingClass = false;
    this.updatingClass = false;
    this.eventTypesLoading = false;
  }

  toggleEditModal({ classItem = {} }) {
    const classEvent = new EventClass(classItem);

    this.classForm = Map(classEvent);
    this.classIdToUpdate = classEvent.get('id');
    this.toggleCreationModal();
  }

  toggleCreationModal() {
    this.showCreationModal = !this.showCreationModal;

    if (this.eventTypesIds.size === 0) {
      this.fetchEventTypes({});
    }

    if (!this.showCreationModal) {
      this.partialReset();
    }
  }

  fetchEventTypes({ page = 1, eventTypeId = null }) {
    const params = { page, per_page: 50 };

    if (eventTypeId) {
      params.ids = [eventTypeId];
    }

    this.eventTypesLoading = true;
    EventTypeSource.list({
      params,
      success: ClassCreationActions.fetchEventTypesSuccess,
    });
  }

  fetchEventTypesSuccess({
    event_types: eventTypes,
    page,
    perPage,
    totalCount,
  }) {
    this.eventTypesIds = this.eventTypesIds.concat(
      eventTypes.map(type => type.id)
    );

    if (page * perPage < totalCount) {
      this.fetchEventTypes({ page: page + 1 });
    } else {
      this.eventTypesLoading = false;
    }
  }

  fetchEventTypesError() {
    this.eventTypesLoading = false;
  }

  createEventTypeSuccess(eventTypeId) {
    this.eventTypesIds = this.eventTypesIds.concat([eventTypeId]);
    this.fetchEventTypes({ eventTypeId });
    this.handleFieldChange({ field: ['event_type', 'id'], value: eventTypeId });
  }

  getRequestAttributes(classItem) {
    const classData = classItem || this.classForm;
    const attributes = {
      description: classData.get('description').toString('html'),
      title: classData.get('title'),
      event_type_id: classData.getIn(['event_type', 'id']),
    };
    if (classItem) {
      attributes.status = 'completed';
    }
    return attributes;
  }

  async createClass() {
    this.validate();
    if (!this.formErrors.hasErrors()) {
      this.creatingClass = true;

      const attributes = this.getRequestAttributes();
      const payload = !this.classForm.get('image').isBlank()
        ? await this.classForm.get('image').toServer()
        : null;

      if (payload) {
        attributes.image = payload;
      }

      ClassesSource.create({
        params: { attributes, fields: ['image'] },
        success: ClassCreationActions.createClassSuccess,
        error: ClassCreationActions.createClassError,
      });
    }
  }

  createClassSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.classes.classesCreation.ClassCreateModal.create_success',
    });

    this.creatingClass = false;
    this.toggleCreationModal();
    MessageWindowActions.addMessage.defer(message);
  }

  createClassError() {
    this.creatingClass = false;
  }

  async updateClass() {
    this.validate();
    if (!this.formErrors.hasErrors()) {
      this.updatingClass = true;

      const attributes = this.getRequestAttributes();
      const payload = !this.classForm.get('image').isBlank()
        ? await this.classForm.get('image').toServer()
        : null;

      if (payload) {
        attributes.image = payload;
      }

      ClassesSource.update({
        params: { attributes, fields: ['image'] },
        id: this.classIdToUpdate,
        success: ClassCreationActions.updateClassSuccess,
        error: ClassCreationActions.updateClassError,
      });
    }
  }

  async completeClass({ classItem }) {
    this.updatingClass = true;
    const attributes = this.getRequestAttributes(classItem);
    const payload = !classItem.get('image').isBlank()
      ? await classItem.get('image').toServer()
      : null;

    if (payload) {
      attributes.image = payload;
    }

    ClassesSource.update({
      params: { attributes, fields: ['image'] },
      id: classItem.id,
      success: ClassCreationActions.completeClassSuccess,
      error: ClassCreationActions.updateClassError,
    });
  }

  updateClassSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.classes.classesCreation.ClassCreateModal.update_success',
    });

    this.updatingClass = false;
    this.toggleEditModal({});
    MessageWindowActions.addMessage.defer(message);
  }

  updateClassError() {
    this.updatingClass = false;
  }

  completeClassSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.classes.classesCreation.ClassCreateModal.complete_class_success',
    });
    this.updatingClass = false;
    MessageWindowActions.addMessage.defer(message);
  }

  handleFieldChange({ field, value }) {
    const fieldToUpdate = typeof field === 'string' ? [field] : field;

    this.classForm = this.classForm.setIn(fieldToUpdate, value);
  }

  validate() {
    this.formErrors = this.formErrors.clear();

    if (!this.classForm.get('title')) {
      this.formErrors = this.formErrors.add(
        'title',
        'containers.classes.classesCreation.ClassCreateModal.validation.required_field'
      );
    }

    if (!this.classForm.getIn(['event_type', 'id'])) {
      this.formErrors = this.formErrors.add(
        'event_type_id',
        'containers.classes.classesCreation.ClassCreateModal.validation.required_field'
      );
    }

    const isDescriptionEmpty = this.classForm.get('description');

    if (!isDescriptionEmpty) {
      this.formErrors = this.formErrors.add(
        'description',
        'containers.classes.classesCreation.ClassCreateModal.validation.required_field'
      );
    }
  }
}

export default alt.createStore(ClassCreationStore, 'ClassCreationStore');
