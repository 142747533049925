import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
// This import brings the POSReceiptStore to global alt store. Don't remove!
// eslint-disable-next-line no-unused-vars
import POSReceiptStore from 'point_of_sale/stores/POSReceiptStore.js';
import POSReceiptActions from 'point_of_sale/actions/POSReceiptActions.js';
import ZebraStore from 'shared/stores/ZebraStore.js';
import { t } from 'shared/utils/LocaleUtils.js';

const printReceipt = orderId => {
  POSReceiptActions.receiptRequest(orderId);
};

const Receipt = injectIntl(
  ({ orderId, zebraStore: { printing, defaultPrinter }, intl }) => (
    <div>
      {defaultPrinter && (
        <Button
          fullWidth
          disabled={printing}
          style={{ height: 50, marginBottom: 10, fontSize: 16 }}
          onClick={() => printReceipt(orderId)}
        >
          {printing
            ? t('.printing', intl, __filenamespace)
            : t('.print_receipt', intl, __filenamespace)}
        </Button>
      )}
    </div>
  )
);

function ReceiptDialog({ orderId }) {
  return (
    <AltContainer stores={{ zebraStore: ZebraStore }}>
      <Receipt orderId={orderId} />
    </AltContainer>
  );
}

export default ReceiptDialog;
