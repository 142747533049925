import * as React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import CardMenu from 'shared/components/CardMenu.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import SelectedItemOrMembership from 'shared/components/SelectedEventOrType.jsx';

import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import CommissionRate from 'shared/records/CommissionRate.jsx';
import CommissionRateDeletionActions from 'contacts/shared/actions/commission_rates/CommissionRateDeletionActions';
import CommissionRateEditingActions from 'contacts/shared/actions/commission_rates/CommissionRateEditingActions';

const styles = {
  commissionRateCard: {
    padding: 16,
    position: 'relative',
  },
  cardLabel: {
    fontSize: 13,
    fontWeight: 600,
    color: 'var(--color-old-gray)',
    marginBottom: 5,
  },
  cardValue: {
    fontSize: 13,
    color: 'var(--color-old-gray)',
  },
  infoIconStyle: { height: 20, width: 20, marginLeft: 5 },
};

function SelectedItemsList({ selectedItems }) {
  return (
    <div style={{ marginTop: 5 }}>
      {selectedItems.map(item => (
        <SelectedItemOrMembership
          key={item.id}
          event={item}
          title={item.name}
          id={item.id}
          barColor={item?.color}
        />
      ))}
    </div>
  );
}

function CommissionRateCard({
  disabledEditing,
  commissionRate,
  selectedItems,
  style,
  intl,
}) {
  return (
    <Paper style={merge(styles.commissionRateCard, style)}>
      {(currentUser().isAdmin() || currentUser().isStaffAdmin()) && (
        <CardMenu>
          <MenuItem
            sx={{ fontSize: 'initial' }}
            disabled={disabledEditing}
            onClick={() => {
              CommissionRateEditingActions.begin(commissionRate);
            }}
          >
            {t('actions.edit', intl, __filenamespace)}
          </MenuItem>
          <MenuItem
            sx={{ fontSize: 'initial' }}
            onClick={() =>
              CommissionRateDeletionActions.removeCommissionRate(commissionRate)
            }
          >
            {t('actions.remove', intl, __filenamespace)}
          </MenuItem>
        </CardMenu>
      )}

      <FlexBox style={{ flexDirection: 'column', marginBottom: 20 }}>
        <div style={styles.cardLabel}>
          <FormattedMessage
            id={messageId('.commission', __filenamespace)}
            values={{ n: selectedItems.size }}
          />
        </div>
        {commissionRate.all_memberships && (
          <SelectedItemOrMembership
            key={commissionRate.id}
            title={t('.all_memberships', intl, __filenamespace)}
            id={commissionRate.id}
            barColor={uhColors.fadedHintActive}
          />
        )}
        {commissionRate.all_retail_categories && (
          <SelectedItemOrMembership
            key={commissionRate.id}
            title={t('.all_retail_categories', intl, __filenamespace)}
            id={commissionRate.id}
            barColor={uhColors.fadedHintActive}
          />
        )}
        {!commissionRate.all_memberships &&
          !commissionRate.all_retail_categories && (
            <SelectedItemsList selectedItems={selectedItems} />
          )}
      </FlexBox>
      <FlexBox>
        <div style={{ flex: '1 1 50%' }}>
          <FlexBox style={{ flexDirection: 'column' }}>
            <div style={styles.cardLabel}>
              <FormattedMessage
                id={messageId('.value_type', __filenamespace)}
              />
            </div>
            <div style={styles.cardValue}>
              <FormattedMessage
                id={messageId(
                  `.${
                    commissionRate.rate_type === 'amount'
                      ? 'dollar'
                      : 'percentage'
                  }`,
                  __filenamespace
                )}
              />
            </div>
          </FlexBox>
        </div>
        <div style={{ flex: '1 1 50%', marginBottom: 10 }}>
          <FlexBox style={{ flexDirection: 'column' }}>
            <div style={styles.cardLabel}>
              <FormattedMessage id={messageId('.value', __filenamespace)} />
            </div>
            <div style={styles.cardValue}>
              {commissionRate.rate_type === 'amount' ? (
                <FormattedCurrency value={commissionRate.value} fromCents />
              ) : (
                /* eslint-disable react/style-prop-object */
                <FormattedNumber
                  value={commissionRate.value / 100}
                  style="percent"
                />
              )}
            </div>
          </FlexBox>
        </div>
      </FlexBox>
      {commissionRate.discount_sensitive && (
        <FlexBoxCenter style={{ marginTop: 10, fontWeight: 'bold' }}>
          <FormattedMessage
            id={messageId('.discount_sensitive_rate', __filenamespace)}
          />
          <InfoIcon
            data-tip
            data-for="usage-info"
            style={styles.infoIconStyle}
          />
          <ReactTooltip
            id="usage-info"
            effect="solid"
            style={{ maxWidth: 240 }}
            className="charge-failure-tooltip uh-tooltip"
          >
            <div style={{ lineHeight: 1.4 }}>
              <FormattedMessage
                id={messageId('.sensitive_rate', __filenamespace)}
              />
            </div>
          </ReactTooltip>
        </FlexBoxCenter>
      )}
    </Paper>
  );
}

CommissionRateCard.propTypes = {
  disabledEditing: PropTypes.bool,
  commissionRate: PropTypes.instanceOf(CommissionRate).isRequired,
  selectedItems: PropTypes.instanceOf(List).isRequired,
  style: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

CommissionRateCard.defaultProps = {
  style: {},
  disabledEditing: true,
};

export default CommissionRateCard;
