export default alt.generateActions(
  'closeButtonClicked',
  'continueToPaymentClicked',
  'fetchStatusSuccess',
  'returnToReviewClicked',
  'reviewBackClicked',
  'switchToSummary',
  'closeEmbedWarning',
  'fetchOrderSuccess',
  'fetchOrderError'
);
