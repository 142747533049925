import { List } from 'immutable';
import RetailCategory from 'shared/records/RetailCategory.js';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

class POSRetailCategoryListStore extends UpperHandStore {
  constructor() {
    super();

    this.retailCategories = List();
    this.shouldList = true;

    // TODO: Watch for the creation of new event types and the updating of existing ones.
    this.bindListeners({
      list: POSProductListActions.MEMBERSHIP_SELECTED,
      listSuccess: RetailCategoryListingActions.listSuccess,
      listError: RetailCategoryListingActions.listError,
    });
  }

  list() {
    if (!this.shouldList || !currentCustomer().retail_enabled) {
      return false;
    }

    this.shouldList = false;

    return uhApiClient.get({
      url: 'retail_categories',
      data: { per_page: 100 },
      success: RetailCategoryListingActions.listSuccess,
      error: RetailCategoryListingActions.listError,
    });
  }

  listSuccess(data) {
    this.retailCategories = this.sorted(
      List(data.retail_categories.map(t => new RetailCategory(t)))
    );
  }

  listError() {
    this.shouldList = true;
    this.notifyError('error while listing retail category', arguments);
  }

  sorted(retailCategories) {
    return retailCategories.sortBy(t => t.name);
  }
}

export default alt.createStore(
  POSRetailCategoryListStore,
  'POSRetailCategoryListStore'
);
