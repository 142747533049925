import * as React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { Grid, InfiniteScroller, Spinner } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import Paginator from 'shared/components/Paginator.jsx';

import TransactionsLedgerReportActions from '../../Actions';
import EmptyState from './EmptyState.jsx';
import HeaderDesktop from './HeaderDesktop.jsx';
import HeaderMobile from './HeaderMobile.jsx';
import RowDesktop from './RowDesktop.jsx';
import RowMobile from './RowMobile.jsx';

function Table({
  columns,
  filters,
  intl,
  isLoadingReport,
  transactionLedgerIds,
  transactionsLedger,
  page,
  perPage,
  statistics,
  totalCount,
}) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <ResponsiveElement
        largeScreen={
          isLoadingReport ? (
            <FlexBoxJustifyCenter>
              <Spinner />
            </FlexBoxJustifyCenter>
          ) : (
            <>
              <Grid item xs={12}>
                <HeaderDesktop columns={columns} intl={intl} />
              </Grid>
              {transactionLedgerIds.size === 0 ? (
                <Grid item xs={12}>
                  <EmptyState intl={intl} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    {transactionLedgerIds.map(entryId => (
                      <RowDesktop
                        key={entryId}
                        intl={intl}
                        transactionLedger={transactionsLedger.get(entryId)}
                        columns={columns}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Paginator
                      className="transactions-ledger-report__pagination"
                      currentPage={page}
                      perPage={perPage}
                      totalCount={totalCount}
                      onPageSelect={TransactionsLedgerReportActions.selectPage}
                      pageLimit={0}
                      showInfo
                    />
                  </Grid>
                </>
              )}
            </>
          )
        }
        smallScreen={
          <>
            <Grid item xs={12}>
              <HeaderMobile
                intl={intl}
                filters={filters}
                statistics={statistics}
                transactionsLedger={transactionsLedger}
              />
            </Grid>
            {transactionLedgerIds.size === 0 ? (
              <Grid item xs={12}>
                <EmptyState intl={intl} />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <InfiniteScroller
                  hasMore={transactionLedgerIds.size < totalCount}
                  onScroll={TransactionsLedgerReportActions.list.defer}
                  isLoading={isLoadingReport}
                  showLoader
                >
                  {transactionLedgerIds.map(entryId => (
                    <RowMobile
                      key={entryId}
                      intl={intl}
                      transactionLedger={transactionsLedger.get(entryId)}
                    />
                  ))}
                </InfiniteScroller>
              </Grid>
            )}
          </>
        }
      />
    </Grid>
  );
}

Table.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isLoadingReport: PropTypes.bool.isRequired,
  transactionLedgerIds: PropTypes.instanceOf(OrderedSet).isRequired,
  transactionsLedger: PropTypes.instanceOf(Map).isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default injectIntl(Table);
