/**
 * Manages native capabilities
 */
class Native {
  constructor() {
    this.capabilities = {};
    this.available = {};
  }

  /**
   * Check if capability exists and is available
   * @param  {String}  name Name of the feature
   * @return {Boolean}
   */
  hasCapability(name) {
    const key = this.getNameToKey(name);
    return !!this.capabilities[key] && !!this.available[key];
  }

  /**
   * Retrieve a capability
   * @param  {String} name Name of the feature
   * @return {Object}      A NativePlugin instance
   */
  resolve(name) {
    const key = this.getNameToKey(name);
    return this.capabilities[key];
  }

  /**
   * Registers a new capability
   * @param  {String}  name    Name to use for fetching
   * @param  {Object}  object  Native Plugin to register
   * @return {Promise}
   */
  register(name, object) {
    const key = this.getNameToKey(name);
    this.capabilities[key] = object;
    this.capabilities[key].isAvailable().then(result => {
      this.available[key] = result;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getNameToKey(name) {
    return name.toLowerCase();
  }
}

export default new Native();
