import TaskEditingActions from 'shared/actions/TaskEditingActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import TaskEditingStoreInterface from 'shared/stores/TaskEditingStoreInterface';

export default alt.createStore(
  TaskEditingStoreInterface,
  'TaskEditingStore',
  TaskEditingActions,
  new TranslatableMessage({ id: '.updated', filename: __filenamespace })
);
