/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import {
  FirstName,
  LastName,
  Email,
} from 'user_management/shared/components/UserProfileFields.jsx';
import EditProfile from 'user_management/shared/components/_EditProfile.jsx';

import ClientActions from 'shared/actions/ClientActions.jsx';
import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import InviteDialogStore from 'shared/stores/InviteDialogStore.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import {
  CLIENT,
  currentUser,
  getValidUserRole,
  hasAdminPermission,
} from 'shared/utils/UserUtils.jsx';
import { uhFlexbox } from 'shared/styles/uhStyles.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { isTexasTwelveTheme } from 'shared/utils/ThemeUtils';
import { t } from 'shared/utils/LocaleUtils';

function ClientEditor({ userProfile, onChange }) {
  const intl = useIntl();
  const props = { onChange, userProfile: userProfile.toJS() };
  const isAdmin = currentUser().isAdmin();
  const isStaffAdmin = currentUser().isStaffAdmin();
  const canInviteCoach =
    (isAdmin || isStaffAdmin) && currentCustomer().features.coach;

  return (
    <EditProfile style={{ width: '100%', minWidth: 320 }}>
      {hasAdminPermission() && (
        <div style={{ marginBottom: '1em', display: 'inline-block' }}>
          <FormControl>
            <RadioGroup
              row
              name="role"
              style={{
                ...uhFlexbox.row,
                ...{ justifyContent: 'flex-start', flexWrap: 'wrap' },
              }}
              value={userProfile.toJS().role}
              onChange={onChange}
            >
              <FormControlLabel
                value="client"
                control={<Radio />}
                label={t('.client', intl, __filenamespace)}
              />
              {!isTexasTwelveTheme() && (
                <FormControlLabel
                  value="staff_member"
                  control={<Radio />}
                  label={t('.staff', intl, __filenamespace)}
                />
              )}
              <FormControlLabel
                value="instructor"
                control={<Radio />}
                label={t('.instructor', intl, __filenamespace)}
              />
              <FormControlLabel
                value="manager"
                control={<Radio />}
                label={t('.manager', intl, __filenamespace)}
              />
              {isAdmin && (
                <FormControlLabel
                  value="staff_admin"
                  control={<Radio />}
                  label={t('.admin', intl, __filenamespace)}
                />
              )}
              <FormControlLabel value="lead" control={<Radio />} label="Lead" />
              {canInviteCoach && (
                <FormControlLabel
                  value="coach"
                  control={<Radio />}
                  label={t('.coach', intl, __filenamespace)}
                />
              )}
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <div style={{ marginBottom: 10 }}>
        <FirstName {...props} />
      </div>
      <div style={{ marginBottom: 10 }}>
        <LastName {...props} />
      </div>
      <Email {...props} />
    </EditProfile>
  );
}

function InviteDialog({ inviteDialogStore }) {
  const intl = useIntl();
  const { profile, open } = inviteDialogStore;

  const handleProfileChange = e => {
    InviteDialogActions.fieldChange({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const createUser = () => {
    const isClient = profile.get('type') === CLIENT;
    const typeAction = isClient ? ClientActions : StaffActions;
    const userProfile = {
      ...profile.toJS(),
      role: getValidUserRole(profile.get('role')),
    };
    typeAction.updateStore(userProfile);
    typeAction.validateUser({
      requiredFields: ['email'],
      isNewUser: false,
    });
    typeAction.create({
      afterCreate: () => typeAction.list.defer({}),
      showSuccessMessage: true,
    });
    InviteDialogActions.closeInviteDialog.defer();
  };

  return (
    <Confirmation
      open={open}
      onConfirm={createUser}
      onCancel={() => InviteDialogActions.closeInviteDialog()}
      level="trivial"
      confirmButtonLabel={t('.confirm_label', intl, __filenamespace)}
      cancelButtonLabel={t('.cancel_label', intl, __filenamespace)}
      title={t('.title', intl, __filenamespace)}
      hasCheckbox={false}
      classes={{
        root: 'invite-confirmation-dialog',
      }}
    >
      <ClientEditor userProfile={profile} onChange={handleProfileChange} />
    </Confirmation>
  );
}

InviteDialog.propTypes = {
  inviteDialogStore: PropTypes.object,
};

InviteDialog.defaultProps = {
  inviteDialogStore: {},
};

export default altContainer({
  stores: {
    inviteDialogStore: InviteDialogStore,
  },
})(InviteDialog);
