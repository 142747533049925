class MarketingEmailStatisticsActions {
  constructor() {
    this.generateActions(
      'toggleFiltersDrawer',
      'handleFieldChange',
      'handlePageSelect',
      'handleFilterChange',
      'applyFilters',
      'resetFilters',
      'removeFilter',
      'searchMarketingEmailStatistics',
      'listMarketingEmailStatistics',
      'listMarketingEmailStatisticsSuccess',
      'listMarketingEmailStatisticsError',
      'openStatisticsDrawer',
      'closeStatisticsDrawer',
      'setActiveTab',
      'listCampaignClients',
      'listCampaignClientsSuccess',
      'listCampaignClientsError',
      'clientsPageSelect',
      'clientsSearchTextChange'
    );
  }
}

export default alt.createActions(MarketingEmailStatisticsActions);
