import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Range } from 'immutable';
import { Dropdown } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import Discount from 'shared/records/Discount.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { isDBatTheme } from 'shared/utils/ThemeUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import './styles.scss';

function AutomationPackageSelector({
  packagePricing,
  discount,
  intl,
  value,
  label,
  onChange,
}) {
  const handleSelectItem = e => {
    const { value: eventValue } = e.target;
    // eslint-disable-next-line no-undef
    onChange(_, _, eventValue);
  };

  const items = packagePricing
    .packages()
    .map(pkg => ({
      label: (
        <FlexBoxJustify style={{ marginRight: '15px' }}>
          <div>{pkg.description}</div>
          <FormattedCurrency value={discount.applyTo(pkg.price)} fromCents />
        </FlexBoxJustify>
      ),
      value: pkg.uuid,
    }))
    .toJS();

  return (
    <Dropdown
      fullWidth
      placeholder={t('.select_package', intl, __filenamespace)}
      classes={{
        root: 'package-selector',
        inputLabelTypography: 'package-selector-label__typography',
        inputLabel: 'package-selector-label',
      }}
      label={label}
      value={value}
      items={items}
      onChange={handleSelectItem}
    />
  );
}

function DefaultPackageSelector({
  price,
  discount,
  maxPackageValue,
  minPackageValue,
  ...props
}) {
  const { value, label, onChange } = props;
  const defaultPackages = Range(minPackageValue, maxPackageValue);
  const isDBatClient = isDBatTheme() && currentUser().isClient();
  const items = defaultPackages
    .map(packageSize => ({
      label: (
        <FlexBoxJustify style={{ marginRight: '15px' }}>
          <div>{packageSize}</div>
          {!isDBatClient && (
            <FormattedCurrency
              value={discount.applyTo(packageSize * price * 100)}
              fromCents
            />
          )}
        </FlexBoxJustify>
      ),
      value: packageSize,
    }))
    .toJS();

  const handleSelectItem = e => {
    const { value: eventValue } = e.target;
    // eslint-disable-next-line no-undef
    onChange(_, _, eventValue);
  };

  return (
    <Dropdown
      fullWidth
      classes={{
        root: 'package-selector',
        inputLabelTypography: 'package-selector-label__typography',
        inputLabel: 'package-selector-label',
      }}
      label={label}
      value={value}
      items={items}
      onChange={handleSelectItem}
    />
  );
}

DefaultPackageSelector.defaultProps = {
  maxPackageValue: 21,
  minPackageValue: 1,
};

function PackageSelector({
  packagePricingDescription,
  registrationPackage,
  onAutomationOptionSelect,
  onDefaultOptionSelect,
  selectedValue,
  maxPackageValue,
  minPackageValue,
  ...props
}) {
  if (packagePricingDescription) {
    return (
      <AutomationPackageSelector
        packagePricing={packagePricingDescription}
        onChange={onAutomationOptionSelect}
        value={registrationPackage.automation_option_uuid}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
  return (
    <DefaultPackageSelector
      registrationPackage={registrationPackage}
      onChange={onDefaultOptionSelect}
      value={selectedValue}
      maxPackageValue={maxPackageValue}
      minPackageValue={minPackageValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

PackageSelector.defaultProps = {
  discount: new Discount({ type: Discount.ABSOLUTE, amount: 0 }),
};

export default injectIntl(PackageSelector);
