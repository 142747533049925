/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = {
  accordionDetails: {
    maxHeight: '300px',
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  filterItem: {
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  typography: {
    fontWeight: 'bold',
  },
  typography2: {
    fontWeight: 'normal',
  },
  typography3: {
    color: 'textSecondary',
  },
  titleText: expanded => ({
    fontWeight: expanded ? '600' : '400',
    fontSize: '16px',
  }),
  checkBox: {
    color: 'var(--color-primary)',
    svg: {
      color: 'var(--color-primary)',
    },
  },
};

function FilterAccordion({
  title,
  onChange,
  allTitle = '',
  items = [],
  filterValue = [],
  labelStyle = {},
  accordionNode = null,
  allSelected = true,
  showColorItem = false,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = e => {
    const newFilterValue = filterValue.filter(v => {
      const value = parseInt(e.target.value, 10);
      // eslint-disable-next-line no-restricted-globals
      const selectedValue = isNaN(value) ? e.target.value : value;

      return selectedValue !== v;
    });

    if (e.target.checked) {
      const value = parseInt(e.target.value, 10);

      // eslint-disable-next-line no-restricted-globals
      newFilterValue.push(isNaN(value) ? e.target.value : value);
    }

    const newAllSelected = newFilterValue.length === items.length;

    onChange(newFilterValue, newAllSelected);
  };

  const handleSelectAll = () => {
    if (allSelected) {
      onChange([], false);
      return;
    }

    const newFilterValue = items.map(i => i.value);

    onChange(newFilterValue, true);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="h6"
          style={{ ...styles.titleText(expanded), ...labelStyle }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        {items.length > 0 && (
          <>
            {allTitle && (
              <FormControlLabel
                sx={{
                  ...styles.filterItem,
                  borderLeft: showColorItem ? '5px solid transparent' : 0,
                }}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    defaultChecked
                    checked={allSelected}
                    onChange={handleSelectAll}
                  />
                }
                label={allTitle}
              />
            )}
            {items.map(item => (
              <FormControlLabel
                sx={{
                  ...styles.filterItem,
                  borderLeft: showColorItem
                    ? `5px solid ${item.color}`
                    : 'none',
                }}
                control={
                  <Checkbox
                    defaultChecked
                    sx={styles.checkBox}
                    name={title}
                    value={item.value}
                    checked={filterValue.includes(item.value)}
                    onChange={handleChange}
                  />
                }
                label={
                  <Box>
                    <Typography
                      sx={item.address ? styles.typography : styles.typography2}
                    >
                      {item.label}
                    </Typography>
                    {item.address && (
                      <>
                        <Typography variant="body2" sx={styles.typography3}>
                          {item.address.streetAddress}
                        </Typography>
                        <Typography variant="body2" sx={styles.typography3}>
                          {item.address.fullAddress}
                        </Typography>
                      </>
                    )}
                  </Box>
                }
              />
            ))}
          </>
        )}
        {accordionNode}
      </AccordionDetails>
    </Accordion>
  );
}

FilterAccordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.array,
  accordionNode: PropTypes.node,
  filterValue: PropTypes.array,
  labelStyle: PropTypes.object,
  allSelected: PropTypes.bool,
  allTitle: PropTypes.string,
  showColorItem: PropTypes.bool,
};

export default FilterAccordion;
