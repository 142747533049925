import React from 'react';
import StaffColumn from 'event_mgmt/editing/components/_StaffColumn.jsx';
import ResourceColumn from 'event_mgmt/editing/components/_ResourceColumn.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';

import './TabStaffAndResources.scss';

function TabStaffAndResources({ customerEvent }) {
  return (
    <div className="staff-and-resources-tab" style={{ overflow: 'hidden' }}>
      <FlexBox style={{ margin: '0 -15px', flexWrap: 'wrap' }}>
        <div style={{ flex: '0 0 320px', margin: '0 15px' }}>
          <StaffColumn event={customerEvent} />
        </div>
        <div style={{ flex: '0 0 320px', margin: '0 15px' }}>
          <ResourceColumn />
        </div>
      </FlexBox>
    </div>
  );
}

export default TabStaffAndResources;
