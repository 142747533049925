import * as React from 'react';

const Colors = [
  'rgba(235, 59, 90,0.7)',
  'rgba(250, 130, 49,0.7)',
  'rgba(247, 183, 49,0.7)',
  'rgba(32, 191, 107,0.7)',
  'rgba(15, 185, 177,0.7)',
  'rgba(45, 152, 218,0.7)',
  'rgba(56, 103, 214,0.7)',
  'rgba(136, 84, 208,0.7)',
  'rgba(165, 177, 194,0.7)',
  'rgba(75, 101, 132,0.7)',
  'rgba(252, 92, 101,0.7)',
  'rgba(253, 150, 68,0.7)',
  'rgba(254, 211, 48,0.7)',
  'rgba(38, 222, 129,0.7)',
  'rgba(43, 203, 186,0.7)',
  'rgba(69, 170, 242,0.7)',
  'rgba(75, 123, 236,0.7)',
  'rgba(165, 94, 234,0.7)',
  'rgba(209, 216, 224,0.7)',
  'rgba(119, 140, 163,0.7)',
];

const BorderColors = [
  'rgba(235, 59, 90,1.0)',
  'rgba(250, 130, 49,1.0)',
  'rgba(247, 183, 49,1.0)',
  'rgba(32, 191, 107,1.0)',
  'rgba(15, 185, 177,1.0)',
  'rgba(45, 152, 218,1.0)',
  'rgba(56, 103, 214,1.0)',
  'rgba(136, 84, 208,1.0)',
  'rgba(165, 177, 194,1.0)',
  'rgba(75, 101, 132,1.0)',
  'rgba(252, 92, 101,1.0)',
  'rgba(253, 150, 68,1.0)',
  'rgba(254, 211, 48,1.0)',
  'rgba(38, 222, 129,1.0)',
  'rgba(43, 203, 186,1.0)',
  'rgba(69, 170, 242,1.0)',
  'rgba(75, 123, 236,1.0)',
  'rgba(165, 94, 234,1.0)',
  'rgba(209, 216, 224,1.0)',
  'rgba(119, 140, 163,1.0)',
];

const HoverColors = [
  'rgba(235, 59, 90,1.0)',
  'rgba(250, 130, 49,1.0)',
  'rgba(247, 183, 49,1.0)',
  'rgba(32, 191, 107,1.0)',
  'rgba(15, 185, 177,1.0)',
  'rgba(45, 152, 218,1.0)',
  'rgba(56, 103, 214,1.0)',
  'rgba(136, 84, 208,1.0)',
  'rgba(165, 177, 194,1.0)',
  'rgba(75, 101, 132,1.0)',
  'rgba(252, 92, 101,1.0)',
  'rgba(253, 150, 68,1.0)',
  'rgba(254, 211, 48,1.0)',
  'rgba(38, 222, 129,1.0)',
  'rgba(43, 203, 186,1.0)',
  'rgba(69, 170, 242,1.0)',
  'rgba(75, 123, 236,1.0)',
  'rgba(165, 94, 234,1.0)',
  'rgba(209, 216, 224,1.0)',
  'rgba(119, 140, 163,1.0)',
];

export { Colors, BorderColors, HoverColors };
