import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Gear({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-18.000000, -557.000000)" fill={color}>
          <g transform="translate(0.000000, 60.000000)">
            <path
              d="M30,513.5 C27.51525,513.5 25.5,511.48475 25.5,509 C25.5,506.51525 27.51525,504.5 30,504.5 C32.48475,504.5 34.5,506.51525 34.5,509 C34.5,511.48475 32.48475,513.5 30,513.5 M40.74675,507.2915 L38.75925,506.96 C38.52975,505.97375 38.13975,505.052 37.61625,504.22025 L38.72325,502.67075 C39.14475,502.079 39.078,501.26975 38.565,500.756 L38.24325,500.435 C37.73025,499.922 36.921,499.8545 36.32925,500.27675 L34.77975,501.38375 C33.948,500.86025 33.02625,500.4695 32.04075,500.24075 L31.7085,498.25325 C31.5885,497.53025 30.96225,497 30.2295,497 L29.7705,497 C29.03775,497 28.4115,497.53025 28.2915,498.25325 L27.96,500.24075 C26.97375,500.4695 26.052,500.86025 25.22025,501.38375 L23.67075,500.27675 C23.079,499.8545 22.26975,499.922 21.756,500.435 L21.435,500.756 C20.922,501.26975 20.8545,502.079 21.27675,502.67075 L22.38375,504.22025 C21.86025,505.052 21.4695,505.97375 21.24075,506.96 L19.25325,507.2915 C18.53025,507.4115 18,508.03775 18,508.7705 L18,509.2295 C18,509.96225 18.53025,510.5885 19.25325,510.7085 L21.24075,511.04075 C21.4695,512.02625 21.86025,512.948 22.38375,513.77975 L21.27675,515.32925 C20.8545,515.921 20.922,516.73025 21.435,517.24325 L21.756,517.565 C22.26975,518.078 23.079,518.14475 23.67075,517.72325 L25.22025,516.61625 C26.052,517.13975 26.97375,517.52975 27.96,517.75925 L28.2915,519.74675 C28.4115,520.46975 29.03775,521 29.7705,521 L30.2295,521 C30.96225,521 31.5885,520.46975 31.7085,519.74675 L32.04075,517.75925 C33.02625,517.52975 33.948,517.13975 34.77975,516.61625 L36.32925,517.72325 C36.921,518.14475 37.73025,518.078 38.24325,517.565 L38.565,517.24325 C39.078,516.73025 39.14475,515.921 38.72325,515.32925 L37.61625,513.77975 C38.13975,512.948 38.52975,512.02625 38.75925,511.04075 L40.74675,510.7085 C41.46975,510.5885 42,509.96225 42,509.2295 L42,508.7705 C42,508.03775 41.46975,507.4115 40.74675,507.2915"
              id="Gear"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Gear.defaultProps = {
  color: '#9A9EAD',
};

export default Gear;
