import { List } from 'immutable';
import Client from 'shared/records/Client.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import QSClientActions from 'calendar/actions/QSClientActions';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import UserValidator from 'shared/utils/UserValidator.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

// TODO [zgoldman:2018-09-14]: Centralize this somewhere.
const REQUIRED_CLIENT_FIELDS = List([
  'date_of_birth',
  'email',
  'first_name',
  'gender',
  'last_name',
  'phone',
]);

class QSClientStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      selectClient: QuickScheduleActions.SELECT_CLIENT,
      fetchManagingUserSuccess: QSClientActions.FETCH_MANAGING_USER_SUCCESS,
      fetchManagingUserError: QSClientActions.FETCH_MANAGING_USER_ERROR,
    });
  }

  reset() {
    this.client = null;
    this.managingCustomerUser = null;
    this.clientFieldErrors = new FieldErrors();
    this.clientIsValid = false;
    this.loadingManager = false;
  }

  selectClient([client]) {
    this.reset();

    this.client = client;

    if (client.managing_customer_user_id) {
      this.fetchManagingUser(client.managing_customer_user_id);
    } else {
      this.managingCustomerUser = client;

      this.validateClient();
    }
  }

  fetchManagingUser(customerUserId) {
    this.loadingManager = true;

    return uhApiClient.get({
      url: `customer_users/${customerUserId}`,
      data: { fields: ['active_membership_id', 'active_membership_tier_id'] },
      success: QSClientActions.fetchManagingUserSuccess,
      error: QSClientActions.fetchManagingUserError,
    });
  }

  fetchManagingUserSuccess(data) {
    this.managingCustomerUser = new Client(data);
    this.existingAccount = this.client;
    this.loadingManager = false;

    this.validateClient();
  }

  fetchManagingUserError(...args) {
    this.loadingManager = false;
    this.notifyError(
      `error while fetching managing user for ${this.client.name}`,
      args
    );
  }

  validateClient() {
    const validator = new UserValidator(this.managingCustomerUser);

    this.clientFieldErrors = validator.validate(
      REQUIRED_CLIENT_FIELDS,
      false
    ).errors;
    this.clientIsValid = !this.clientFieldErrors.hasErrors();
  }
}

export default alt.createStore(QSClientStore, 'QSClientStore');
