import * as React from 'react';
import AltContainer from 'alt-container';

import SideNav from 'side_nav/SideNav.jsx';
import AccountSetupIncompleteBanner from 'onboarding/components/AccountSetupIncompleteBanner.jsx';
import AccountSetupPaysafeStore from 'onboarding/stores/AccountSetupPaysafeStore.js';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import CustomerStore from 'shared/stores/CustomerStore.jsx';

function ContentLayoutLarge({ children }) {
  const isStaffLoggedIn = isLoggedIn() && currentUser().isStaff();
  const isInstructorLoggedIn = isLoggedIn() && currentUser().isInstructor();
  const isCoachLoggedIn = isLoggedIn() && currentUser().isCoach();
  const shouldShowSideBar =
    (isStaffLoggedIn || isInstructorLoggedIn || isCoachLoggedIn) &&
    !window.location.pathname.includes('/create_user');

  const styles = {
    Container: {
      flex: 1,
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
    },
    Main: {
      flex: 1,
      position: 'relative',
      maxWidth: '100%',
    },
  };

  return (
    <div style={styles.Container}>
      {shouldShowSideBar && (
        <AltContainer
          stores={{
            customerStore: CustomerStore,
          }}
        >
          <SideNav />
        </AltContainer>
      )}

      <main id="main-content-wrapper" style={styles.Main}>
        <AltContainer store={AccountSetupPaysafeStore}>
          <AccountSetupIncompleteBanner />
        </AltContainer>
        <div style={{ position: 'absolute', width: '100%' }}>{children} </div>
      </main>
    </div>
  );
}

export default ContentLayoutLarge;
