/* eslint-disable */
import debounce from 'lodash.debounce';
import { List, Map, Set } from 'immutable';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import InventoryReportActions from 'reporting/inventory/actions/InventoryReportActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { RetailVendorsSource } from 'sources';

import DateRange, { THIS_MONTH } from 'shared/records/DateRange';
import InventoryItem from 'reporting/inventory/records/InventoryItem.js';
import FilteredSelection from 'shared/records/FilteredSelection.js';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import * as SharedUtils from 'shared/utils/SharedUtils.js';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import InventoryReportAdjustmentModalActions from '../actions/InventoryReportAdjustmentModalActions.js';

class InventoryReportStore extends UpperHandStore {
  constructor() {
    super();

    this.defaultRetailCategoryIds = Set();
    this.defaultRetailVendorIds = Set();
    this.reset();
    this.bindListeners({
      reset: InventoryReportActions.RESET,
      mounted: InventoryReportActions.MOUNTED,
      getReport: [
        InventoryReportActions.GET_REPORT,
        InventoryReportAdjustmentModalActions.saveAdjustmentSuccess,
      ],
      getReportSuccess: InventoryReportActions.GET_REPORT_SUCCESS,
      getReportError: InventoryReportActions.GET_REPORT_ERROR,
      updateFilter: InventoryReportActions.UPDATE_FILTER,
      updateExportColumns: InventoryReportActions.UPDATE_EXPORT_COLUMNS,
      export: InventoryReportActions.EXPORT,
      exportSuccess: InventoryReportActions.EXPORT_SUCCESS,
      exportError: InventoryReportActions.EXPORT_ERROR,
      getRetailCategorySuccess: RetailCategoryListingActions.LIST_SUCCESS,
      listRetailVendorsSuccess:
        InventoryReportActions.LIST_RETAIL_VENDORS_SUCCESS,
      updatePagination: InventoryReportActions.pageSelected,
    });
  }

  reset() {
    this.isLoading = false;
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;

    const columns = new FilteredSelection({
      source: [
        {
          key: 'vendor_name',
          value: new TranslatableMessage({
            id: '.column.vendor_name',
            filename: __filenamespace,
          }),
        },
        {
          key: 'sku',
          value: new TranslatableMessage({
            id: '.column.sku',
            filename: __filenamespace,
          }),
        },
        {
          key: 'barcode',
          value: new TranslatableMessage({
            id: '.column.gtin',
            filename: __filenamespace,
          }),
        },
        {
          key: 'live_qty',
          value: new TranslatableMessage({
            id: '.column.live_qty',
            filename: __filenamespace,
          }),
        },
        {
          key: 'status',
          value: new TranslatableMessage({
            id: '.column.status',
            filename: __filenamespace,
          }),
        },
        {
          key: 'received',
          value: new TranslatableMessage({
            id: '.column.received',
            filename: __filenamespace,
          }),
        },
        {
          key: 'sold',
          value: new TranslatableMessage({
            id: '.column.sold',
            filename: __filenamespace,
          }),
        },
        {
          key: 'returned',
          value: new TranslatableMessage({
            id: '.column.returned',
            filename: __filenamespace,
          }),
        },
        {
          key: 'avg_cost',
          value: new TranslatableMessage({
            id: '.column.avg_cost',
            filename: __filenamespace,
          }),
        },
        {
          key: 'avg_price',
          value: new TranslatableMessage({
            id: '.column.avg_price',
            filename: __filenamespace,
          }),
        },
        {
          key: 'adjustment',
          value: new TranslatableMessage({
            id: '.column.adjustment',
            filename: __filenamespace,
          }),
        },
        {
          key: 'adjustment_reasons',
          value: new TranslatableMessage({
            id: '.column.adjustment_reasons',
            filename: __filenamespace,
          }),
        },
        {
          key: 'cost',
          value: new TranslatableMessage({
            id: '.column.cost',
            filename: __filenamespace,
          }),
        },
        {
          key: 'price',
          value: new TranslatableMessage({
            id: '.column.price',
            filename: __filenamespace,
          }),
        },
        {
          key: 'total_profit',
          value: new TranslatableMessage({
            id: '.column.total_profit',
            filename: __filenamespace,
          }),
        },
      ],
    });

    this.filters = Map({
      dateRange: new DateRange({ value: THIS_MONTH }),
      search: '',
      outOfStock: null,
      lowStock: null,
      retailCategoryIds: Set(),
      retailVendorIds: Set(),
      serverRetailVendorIds: Set(),
      columns,
    });

    this.exportColumns = columns.deleteItem('status');
    this.inventory = List();

    this.debouncedReport = debounce(() => this.getReport(false), 600);
  }

  mounted() {
    this.getRetailVendors();
    this.getReport(true);
  }

  getRetailVendors() {
    const params = {
      per_page: 100,
    };

    RetailVendorsSource.list({
      params,
      success: InventoryReportActions.listRetailVendorsSuccess,
    });
  }

  listRetailVendorsSuccess({ retail_vendors: records }) {
    this.defaultRetailVendorIds = Set(records.map(item => item.id));
    this.filters = this.filters.set(
      'retailVendorIds',
      this.defaultRetailVendorIds
    );
  }

  getReport(showLoading) {
    this.isLoading = showLoading;
    const { startTime, endTime } = this.filters.get('dateRange');

    const data = {
      start_date: startTime.toISOString(),
      end_date: endTime.toISOString(),
      search: this.filters.get('search'),
      out_of_stock: this.filters.get('outOfStock'),
      low_stock: this.filters.get('lowStock'),
      retail_category_ids: this.filters.get('retailCategoryIds').toJS(),
      retail_vendor_ids: this.filters.get('serverRetailVendorIds').toJS(),
      per_page: this.perPage,
      page: this.page,
    };

    uhApiClient.get({
      url: 'analytics/inventory',
      data,
      success: InventoryReportActions.getReportSuccess,
      error: InventoryReportActions.getReportError,
    });
  }

  getReportSuccess({
    records,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.inventory = List(records.map(item => new InventoryItem(item)));
    this.isLoading = false;
    this.page = page;
    this.perPage = perPage;
    this.totalCount = totalCount;
  }

  getReportError(_data) {
    this.inventory = List();
    this.isLoading = false;
    this.notifyError('error getting inventory report', arguments);
  }

  updateFilter([filter, value]) {
    switch (filter) {
      case 'dateRange':
        this.filters = this.filters.set(
          filter,
          new DateRange({
            value: value.value,
            startDate: value.from,
            endDate: value.to,
          })
        );
        break;
      case 'retailCategoryIds':
        this.filters = this.filters.set(filter, Set(value));
        break;
      case 'retailVendorIds':
        this.filters = this.filters.set(filter, Set(value));
        this.filters = this.filters.set('serverRetailVendorIds', Set(value));
        break;
      default:
        this.filters = this.filters.set(filter, value);
    }

    if (filter === 'search') {
      this.debouncedReport();
      return;
    }

    if (filter !== 'columns') {
      this.getReport(false);
    }
  }

  updatePagination([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.getReport(true);
  }

  updateExportColumns(value) {
    this.exportColumns = value;
  }

  export() {
    const { startTime, endTime } = this.filters.get('dateRange');

    const data = {
      start_date: startTime.toISOString(),
      end_date: endTime.toISOString(),
      search: this.filters.get('search'),
      out_of_stock: this.filters.get('outOfStock'),
      low_stock: this.filters.get('lowStock'),
      retail_category_ids: this.filters.get('retailCategoryIds').toJS(),
      retail_vendor_ids: this.filters.get('serverRetailVendorIds').toJS(),
      columns: this.exportColumns.addItem('product', true).toServer(),
    };

    uhApiClient.get({
      url: 'analytics/inventory_csv',
      data,
      success: InventoryReportActions.exportSuccess,
      error: InventoryReportActions.exportError,
    });
  }

  exportSuccess(data) {
    SharedUtils.downloadFile({
      data,
      fileName: 'inventory_report.csv',
    });
  }

  exportError() {
    this.notifyError('error during exporting inventory report', arguments);
  }

  getRetailCategorySuccess(data) {
    this.defaultRetailCategoryIds = Set(
      data.retail_categories.map(item => item.id)
    );
    this.filters = this.filters.set(
      'retailCategoryIds',
      this.defaultRetailCategoryIds
    );
  }
}

export default alt.createStore(InventoryReportStore, 'InventoryReportStore');
