import * as React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  waitlistButtons: {
    fontSize: 16,
    margin: '1rem 0',
    height: 50, // MUI hardcodes a PX unit for height. See https://github.com/callemall/material-ui/issues/4473
  },
};

function WaitlistCommitButton({ intl, itemIsValid, handleAddToWaitlistClick }) {
  const disabled = !itemIsValid;
  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      style={styles.waitlistButtons}
      disabled={disabled}
      onClick={handleAddToWaitlistClick}
    >
      {t('.add_to_waitlist', intl, __filenamespace)}
    </Button>
  );
}

export default injectIntl(WaitlistCommitButton);
