import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { OrderActions } from 'sources';

class OrderDataStore extends UpperHandStore {
  constructor() {
    super();

    this.orders = Map();

    this.bindListeners({
      listSuccess: OrderActions.listSuccess,
      fetchSuccess: OrderActions.fetchSuccess,
      listError: OrderActions.listError,
      fetchError: OrderActions.fetchError,
    });
  }

  fetchSuccess(order) {
    this.orders = this.orders.set(order.id, order);
  }

  fetchError(...args) {
    this.notifyError('error fetching order', args);
  }

  listSuccess({ orders }) {
    const fetchedOrders = orders.groupBy(o => o.id).map(o => o.first());

    this.orders = this.orders.merge(fetchedOrders);
  }

  listError(...args) {
    this.notifyError('error listing orders', args);
  }
}

export default alt.createStore(OrderDataStore, 'OrderDataStore');
