import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Spinner } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId } from 'shared/utils/LocaleUtils.js';

import RefundModalActions from 'containers/reports/refundModal/Actions';

import PaymentActions from './Actions';
import PaymentStore from './Store';
// eslint-disable-next-line import/no-cycle
import { PaymentButton, PaymentMethodSegment } from './components/fields';
import { PaymentMethodWrapper, AccountCreditForm } from './components/forms';

import './styles.scss';

function Payment({
  disablePaymentBtn = false,
  disableAmountField,
  disabledPaymentMethods,
  hasAccess = false,
  hasFutureBillableItem = false,
  hasPreSaleItem = false,
  allowAccountCredits = false,
  itemId,
  addonOrderId,
  paymentStore,
  paymentType,
  purchaserId,
  purchasingUserId,
  successAction,
  total = 0,
  allowFreePurchase = true,
  order,
  additionalActions,
  balance,
  maxAmount,
  accountCreditAmount,
  availableAccountCreditAmount,
  onAccountCreditChanged,
}) {
  useEffect(() => {
    PaymentActions.mounted.defer({
      allowAccountCredits,
      disabledPaymentMethods,
      hasAccess,
      hasFutureBillableItem,
      hasPreSaleItem,
      itemId,
      addonOrderId,
      paymentType,
      purchaserId,
      purchasingUserId,
      successAction,
      total,
      order,
    });
  }, [
    allowAccountCredits,
    disabledPaymentMethods,
    hasAccess,
    hasFutureBillableItem,
    hasPreSaleItem,
    itemId,
    addonOrderId,
    paymentType,
    purchaserId,
    purchasingUserId,
    successAction,
    total,
    order,
  ]);

  const {
    allowedPaymentMethods,
    cardUsage,
    isLoadingPaymentMethods,
    paymentCard,
    paymentMethods,
    paymentMethod,
    processingFields,
    processingPayment,
    hasProcessed,
  } = paymentStore;

  const accountCreditFreePurchase =
    accountCreditAmount &&
    allowAccountCredits &&
    total === 0 &&
    !(hasPreSaleItem || hasFutureBillableItem);

  // hasProcessed: If we have successfully completed our action, we should not show the form
  // again. Instead, the parent component ought to show something else.
  if (processingPayment || isLoadingPaymentMethods || hasProcessed) {
    return (
      <div style={{ textAlign: 'center', margin: '115px 0' }}>
        <Spinner size={60} />
        <div style={{ marginTop: '1rem' }}>
          <FormattedMessage
            id={messageId(
              processingPayment ? '.processing_payment' : '.loading_methods',
              __filenamespace
            )}
          />
        </div>
      </div>
    );
  }
  // only show payment button and default to cash when free/not membership
  if (
    total === 0 &&
    !(hasPreSaleItem || hasFutureBillableItem) &&
    !order?.couponSingleTimeAndMembership() &&
    order?.get('prorate_date') === null
  ) {
    return (
      <PaymentButton
        currentPaymentMethod={paymentMethod}
        // If we allow free purchase, then disabled = processingFields
        disabled={!allowFreePurchase || processingFields || disablePaymentBtn}
      />
    );
  }

  if (accountCreditFreePurchase) {
    return (
      <>
        <AccountCreditForm
          total={total}
          amount={accountCreditAmount}
          amountAvailable={availableAccountCreditAmount}
          onAccountCreditChanged={onAccountCreditChanged}
        />
        <PaymentButton currentPaymentMethod={paymentMethod} />
      </>
    );
  }

  return (
    <>
      {allowedPaymentMethods.size > 1 && (
        <PaymentMethodSegment
          allowedPaymentMethods={allowedPaymentMethods}
          currentPaymentMethod={paymentMethod}
          disabled={processingFields}
          showWaiveOption={!!balance}
          onWaive={() => {
            RefundModalActions.openModal(
              balance.get('productId'),
              balance.details.get(-1).get('type'),
              'waive',
              true,
              maxAmount
            );
          }}
        />
      )}
      {allowAccountCredits && (
        <AccountCreditForm
          total={total}
          amount={accountCreditAmount}
          amountAvailable={availableAccountCreditAmount}
          onAccountCreditChanged={onAccountCreditChanged}
        />
      )}
      <PaymentMethodWrapper
        order={order}
        total={total}
        disableAmountField={disableAmountField}
        allowedPaymentMethods={allowedPaymentMethods}
        cardUsage={cardUsage}
        paymentMethods={paymentMethods}
        paymentCard={paymentCard}
        paymentMethod={paymentMethod}
        processingFields={processingFields}
        hasFutureBillableItem={hasFutureBillableItem}
        hasPreSaleItem={hasPreSaleItem}
        hasAccess={hasAccess}
      />
      {additionalActions}
      <PaymentButton
        currentPaymentMethod={paymentMethod}
        disabled={processingFields || disablePaymentBtn}
      />
    </>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      paymentStore: PaymentStore,
    },
  })
)(Payment);
