import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const defaultStyle = {
  height: 151,
  width: 245,
};

function OrdersReport({ style }) {
  return (
    <SvgIcon viewBox="0 0 245 151" style={{ ...defaultStyle, ...style }}>
      <g opacity="0.65">
        <path
          d="M214.88 144.525H22.1931C21.5369 144.525 21 143.988 21 143.331C21 142.675 21.5369 142.138 22.1931 142.138H214.88C215.537 142.138 216.073 142.675 216.073 143.331C216.073 143.988 215.543 144.525 214.88 144.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M230.618 144.525H223.459C222.803 144.525 222.266 143.988 222.266 143.331C222.266 142.675 222.803 142.138 223.459 142.138H230.618C231.274 142.138 231.811 142.675 231.811 143.331C231.811 143.988 231.274 144.525 230.618 144.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M38.3379 108.973H31.1911C30.536 108.973 30 108.436 30 107.779C30 107.122 30.536 106.584 31.1911 106.584H38.3379C38.993 106.584 39.529 107.122 39.529 107.779C39.529 108.436 38.993 108.973 38.3379 108.973Z"
          fill="#8E8EA0"
        />
        <path
          d="M34.7645 112.557C34.1094 112.557 33.5734 112.02 33.5734 111.363V104.195C33.5734 103.538 34.1094 103 34.7645 103C35.4196 103 35.9556 103.538 35.9556 104.195V111.363C35.9556 112.02 35.4196 112.557 34.7645 112.557Z"
          fill="#8E8EA0"
        />
        <path
          d="M196.852 19.3358H189.705C189.05 19.3358 188.514 18.7982 188.514 18.1412C188.514 17.4841 189.05 16.9465 189.705 16.9465H196.852C197.507 16.9465 198.043 17.4841 198.043 18.1412C198.043 18.7982 197.507 19.3358 196.852 19.3358Z"
          fill="#8E8EA0"
        />
        <path
          d="M193.279 22.9197C192.623 22.9197 192.087 22.3821 192.087 21.7251V14.5572C192.087 13.9001 192.623 13.3625 193.279 13.3625C193.934 13.3625 194.47 13.9001 194.47 14.5572V21.7251C194.47 22.3821 193.934 22.9197 193.279 22.9197Z"
          fill="#8E8EA0"
        />
        <path
          d="M243.765 54.1813H240.191C239.536 54.1813 239 53.6437 239 52.9866C239 52.3296 239.536 51.792 240.191 51.792H243.765C244.42 51.792 244.956 52.3296 244.956 52.9866C244.956 53.6437 244.42 54.1813 243.765 54.1813Z"
          fill="#8E8EA0"
        />
        <path
          d="M241.978 55.9733C241.323 55.9733 240.787 55.4357 240.787 54.7786V51.1947C240.787 50.5376 241.323 50 241.978 50C242.633 50 243.169 50.5376 243.169 51.1947V54.7786C243.169 55.4357 242.633 55.9733 241.978 55.9733Z"
          fill="#8E8EA0"
        />
        <path
          d="M52.7645 56.1813H49.1911C48.536 56.1813 48 55.6437 48 54.9866C48 54.3296 48.536 53.792 49.1911 53.792H52.7645C53.4196 53.792 53.9556 54.3296 53.9556 54.9866C53.9556 55.6437 53.4196 56.1813 52.7645 56.1813Z"
          fill="#8E8EA0"
        />
        <path
          d="M50.9778 57.9732C50.3227 57.9732 49.7867 57.4357 49.7867 56.7786V53.1946C49.7867 52.5376 50.3227 52 50.9778 52C51.6329 52 52.1689 52.5376 52.1689 53.1946V56.7786C52.1689 57.4357 51.6329 57.9732 50.9778 57.9732Z"
          fill="#8E8EA0"
        />
        <path
          d="M200.239 70.4576C199.935 70.4576 199.631 70.3381 199.399 70.1052L194.348 65.0399C193.884 64.5739 193.884 63.8153 194.348 63.3494C194.813 62.8835 195.569 62.8835 196.034 63.3494L201.084 68.4147C201.549 68.8806 201.549 69.6393 201.084 70.1052C200.852 70.3441 200.542 70.4576 200.239 70.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M195.188 70.4576C194.884 70.4576 194.581 70.3381 194.348 70.1052C193.884 69.6393 193.884 68.8806 194.348 68.4147L199.399 63.3494C199.863 62.8835 200.62 62.8835 201.084 63.3494C201.549 63.8153 201.549 64.5739 201.084 65.0399L196.034 70.1052C195.796 70.3441 195.492 70.4576 195.188 70.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M6.23857 74.4576C5.93483 74.4576 5.63109 74.3381 5.39882 74.1052L0.348408 69.0399C-0.116136 68.5739 -0.116136 67.8153 0.348408 67.3494C0.812951 66.8835 1.56931 66.8835 2.03386 67.3494L7.08429 72.4147C7.54883 72.8806 7.54883 73.6393 7.08429 74.1052C6.85201 74.3441 6.54231 74.4576 6.23857 74.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M1.18816 74.4576C0.884421 74.4576 0.580679 74.3381 0.348408 74.1052C-0.116136 73.6393 -0.116136 72.8806 0.348408 72.4147L5.39882 67.3494C5.86336 66.8835 6.61974 66.8835 7.08429 67.3494C7.54883 67.8153 7.54883 68.5739 7.08429 69.0399L2.03386 74.1052C1.79563 74.3441 1.4919 74.4576 1.18816 74.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M92.5655 20.4576C92.3432 20.4576 92.1209 20.3702 91.9509 20.1997L88.255 16.4928C87.915 16.1518 87.915 15.5967 88.255 15.2557C88.5949 14.9148 89.1484 14.9148 89.4884 15.2557L93.1844 18.9626C93.5243 19.3036 93.5243 19.8587 93.1844 20.1997C93.0144 20.3745 92.7877 20.4576 92.5655 20.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M88.8695 20.4576C88.6472 20.4576 88.4249 20.3702 88.255 20.1997C87.915 19.8587 87.915 19.3036 88.255 18.9626L91.9509 15.2557C92.2909 14.9148 92.8444 14.9148 93.1844 15.2557C93.5243 15.5967 93.5243 16.1518 93.1844 16.4928L89.4884 20.1997C89.3141 20.3745 89.0918 20.4576 88.8695 20.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M159.713 36.9249C159.41 36.9249 159.106 36.8055 158.874 36.5725L156.348 34.0399C155.884 33.5739 155.884 32.8153 156.348 32.3494C156.813 31.8835 157.569 31.8835 158.034 32.3494L160.559 34.8821C161.024 35.348 161.024 36.1066 160.559 36.5725C160.321 36.8114 160.017 36.9249 159.713 36.9249Z"
          fill="#8E8EA0"
        />
        <path
          d="M157.188 36.9249C156.884 36.9249 156.581 36.8055 156.348 36.5725C155.884 36.1066 155.884 35.348 156.348 34.8821L158.874 32.3494C159.338 31.8835 160.094 31.8835 160.559 32.3494C161.024 32.8153 161.024 33.5739 160.559 34.0399L158.034 36.5725C157.796 36.8114 157.492 36.9249 157.188 36.9249Z"
          fill="#8E8EA0"
        />
        <path
          d="M40.1689 24.3625C37.8701 24.3625 36 22.4869 36 20.1813C36 17.8756 37.8701 16 40.1689 16C42.4678 16 44.3379 17.8756 44.3379 20.1813C44.3379 22.4869 42.4678 24.3625 40.1689 24.3625ZM40.1689 18.3893C39.1863 18.3893 38.3823 19.1957 38.3823 20.1813C38.3823 21.1669 39.1863 21.9732 40.1689 21.9732C41.1516 21.9732 41.9556 21.1669 41.9556 20.1813C41.9556 19.1957 41.1516 18.3893 40.1689 18.3893Z"
          fill="#8E8EA0"
        />
        <path
          d="M216.169 115.363C213.87 115.363 212 113.487 212 111.181C212 108.876 213.87 107 216.169 107C218.468 107 220.338 108.876 220.338 111.181C220.338 113.487 218.468 115.363 216.169 115.363ZM216.169 109.389C215.186 109.389 214.382 110.196 214.382 111.181C214.382 112.167 215.186 112.973 216.169 112.973C217.152 112.973 217.956 112.167 217.956 111.181C217.956 110.196 217.152 109.389 216.169 109.389Z"
          fill="#8E8EA0"
        />
        <path
          d="M136.104 8.36254C133.805 8.36254 131.935 6.48694 131.935 4.18127C131.935 1.8756 133.805 0 136.104 0C138.403 0 140.273 1.8756 140.273 4.18127C140.273 6.48694 138.403 8.36254 136.104 8.36254ZM136.104 2.3893C135.122 2.3893 134.318 3.19569 134.318 4.18127C134.318 5.16686 135.122 5.97325 136.104 5.97325C137.087 5.97325 137.891 5.16686 137.891 4.18127C137.891 3.19569 137.087 2.3893 136.104 2.3893Z"
          fill="#8E8EA0"
        />
        <path
          d="M163.512 27.6984L163.506 25.3091H163.512V27.6984Z"
          fill="#8E8EA0"
        />
        <path
          d="M214.731 50.3966L214.725 48.0073H214.731V50.3966Z"
          fill="#8E8EA0"
        />
        <path
          d="M238.553 42.2555L238.547 39.8662H238.553V42.2555Z"
          fill="#8E8EA0"
        />
        <path
          d="M12.8347 58.7324L12.8287 56.3431H12.8347V58.7324Z"
          fill="#8E8EA0"
        />
        <path
          d="M52.1419 16.9464L52.136 14.5571H52.1419V16.9464Z"
          fill="#8E8EA0"
        />
        <path
          d="M107.529 53.6046L107.523 51.2153H107.529V53.6046Z"
          fill="#8E8EA0"
        />
        <path
          d="M79 79.6571C76.2386 79.6571 74 81.8957 74 84.6571V143.019C74 145.78 76.2386 148.019 79 148.019H174C176.761 148.019 179 145.78 179 143.019V84.6571C179 81.8957 176.761 79.6571 174 79.6571H79Z"
          fill="#393C44"
          stroke="#8E8EA0"
          strokeWidth="2"
        />
        <path
          d="M131.34 49C131.34 46.2386 129.102 44 126.34 44L78 44C75.2386 44 73 46.2386 73 49L73 123.559C73 126.32 75.2386 128.559 78 128.559L126.34 128.559C129.102 128.559 131.34 126.32 131.34 123.559L131.34 49Z"
          fill="white"
          stroke="#8E8EA0"
          strokeWidth="2"
        />
        <rect
          x="122.174"
          y="50.351"
          width="32.1062"
          height="1.54357"
          rx="0.771783"
          transform="rotate(90 122.174 50.351)"
          fill="#8E8EA0"
        />
        <rect
          x="90.3485"
          y="45"
          width="82.5587"
          height="12.3485"
          transform="rotate(90 90.3485 45)"
          fill="#454952"
        />
        <g opacity="0.2">
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="79"
            y="45"
            width="10"
            height="83"
          >
            <rect
              x="88.8049"
              y="45"
              width="82.5587"
              height="9.2614"
              transform="rotate(90 88.8049 45)"
              fill="#454952"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              width="5.49866"
              height="98.8987"
              transform="matrix(0.490882 0.871226 -0.940818 0.338913 116.782 40.4496)"
              fill="#7E899C"
            />
            <rect
              width="5.49866"
              height="98.8987"
              transform="matrix(0.490882 0.871226 -0.940818 0.338913 122.956 84.0042)"
              fill="#7E899C"
            />
            <rect
              width="11.6242"
              height="98.8987"
              transform="matrix(0.490882 0.871226 -0.940818 0.338913 117.829 51.6875)"
              fill="#7E899C"
            />
            <rect
              width="11.6242"
              height="98.8987"
              transform="matrix(0.490882 0.871226 -0.940818 0.338913 124.003 95.2421)"
              fill="#7E899C"
            />
          </g>
        </g>
        <rect
          x="105.261"
          y="50.3511"
          width="43.5727"
          height="9.2614"
          transform="rotate(90 105.261 50.3511)"
          fill="#F2F5F7"
        />
        <rect
          x="117.544"
          y="50.351"
          width="28.284"
          height="1.54357"
          rx="0.771783"
          transform="rotate(90 117.544 50.351)"
          fill="#8E8EA0"
        />
        <path
          d="M114 136.435C114 139.196 116.239 141.435 119 141.435L167.34 141.435C170.102 141.435 172.34 139.196 172.34 136.435L172.34 61.8761C172.34 59.1147 170.102 56.8761 167.34 56.8761L119 56.8761C116.239 56.8761 114 59.1147 114 61.8761L114 136.435Z"
          fill="white"
          stroke="#8E8EA0"
          strokeWidth="2"
        />
        <path d="M172.5 85L164 81L167.5 85.5V92.5H172.5V85Z" fill="#8E8EA0" />
        <rect x="122" y="93.5333" width="52" height="51.5048" fill="#393C44" />
        <path d="M117 92.5428H173" stroke="#8E8EA0" strokeWidth="2" />
        <path
          d="M157.23 67.1067H155.687V70.6267H157.23V67.1067Z"
          fill="#454952"
        />
        <path
          d="M160.575 67.1067H159.032V70.6267H160.575V67.1067Z"
          fill="#454952"
        />
        <path
          d="M164.95 71.6323H154.657V73.1409H164.95V71.6323Z"
          fill="#454952"
        />
        <path
          d="M163.92 67.1067H162.377V70.6267H163.92V67.1067Z"
          fill="#454952"
        />
        <path
          d="M159.803 62.5809L154.657 65.0952V66.1009H164.95V65.0952L159.803 62.5809Z"
          fill="#454952"
        />
        <rect
          x="157.448"
          y="135.084"
          width="32.1062"
          height="1.54357"
          rx="0.771783"
          transform="rotate(-90 157.448 135.084)"
          fill="#8E8EA0"
        />
        <rect
          x="138.153"
          y="135.084"
          width="43.5727"
          height="9.2614"
          transform="rotate(-90 138.153 135.084)"
          fill="#F2F5F7"
        />
        <rect
          x="162.079"
          y="135.084"
          width="28.284"
          height="1.54357"
          rx="0.771783"
          transform="rotate(-90 162.079 135.084)"
          fill="#8E8EA0"
        />
        <path
          d="M71 81.6381C68.2386 81.6381 66 83.8766 66 86.6381V145C66 147.761 68.2386 150 71 150H162C164.761 150 167 147.761 167 145V86.6381C167 83.8766 164.761 81.6381 162 81.6381H71Z"
          fill="#454952"
          stroke="#8E8EA0"
          strokeWidth="2"
        />
        <path
          d="M72 91.5905C72 89.3813 73.7909 87.5905 76 87.5905H157C159.209 87.5905 161 89.3813 161 91.5905V140.048C161 142.257 159.209 144.048 157 144.048H76C73.7909 144.048 72 142.257 72 140.048V91.5905Z"
          fill="#454952"
          stroke="#8E8EA0"
          strokeLinecap="round"
          strokeDasharray="6 6"
        />
      </g>
    </SvgIcon>
  );
}

export default OrdersReport;
