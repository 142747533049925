class NotesActions {
  constructor() {
    this.generateActions(
      'mounted',
      'updateNote',
      'updateNoteSuccess',
      'updateNoteError',
      'createClientNoteSuccess',
      'createClientNoteError',
      'deleteSessionNote',
      'deleteSessionNoteSuccess',
      'deleteSessionNoteError',
      'handleFieldChange',
      'toggleNoteAdding',
      'toggleEditMode'
    );
  }
}

export default alt.createActions(NotesActions);
