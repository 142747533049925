import { List, Map, fromJS } from 'immutable';
import CustomerDashboardActions from 'dashboards/actions/CustomerDashboardActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

const CLIENT_REPORT_URL = 'customer_dashboard/clients_report';
const EVENT_REPORT_URL = 'customer_dashboard/events_report';
const REVENUE_REPORT_URL = 'customer_dashboard/revenue_report';
const PARTICIPANTS_REPORT_URL = 'customer_dashboard/participants_report';

class CustomerDashboardStore {
  constructor() {
    this.alerts = List();
    this.clientReport = Map({ current: 0, previous: 0 });
    this.eventReport = Map({ current: 0, previous: 0 });
    this.participantReport = Map({ current: 0, previous: 0 });
    this.revenueReport = Map({ current: 0, previous: 0 });

    this.announcementText = '';
    this.originalAnnouncementText = '';
    this.announcementTextChanged = false;

    this.bindListeners({
      loadData: CustomerDashboardActions.MOUNTED,

      setClientReport: CustomerDashboardActions.CLIENT_REPORT_LOADED,
      setEventReport: CustomerDashboardActions.EVENT_REPORT_LOADED,
      setRevenueReport: CustomerDashboardActions.REVENUE_REPORT_LOADED,
      setParticipantsReport:
        CustomerDashboardActions.PARTICIPANTS_REPORT_LOADED,

      updateAnnouncementText:
        CustomerDashboardActions.ANNOUNCEMENT_TEXT_UPDATED,
      saveAnnouncementText: CustomerDashboardActions.ANNOUNCEMENT_TEXT_SAVED,
      clearAnnouncementText: CustomerDashboardActions.ANNOUNCEMENT_TEXT_CLEARED,
      saveAnnouncementTextSuccess:
        CustomerDashboardActions.SAVE_ANNOUNCEMENT_TEXT_SUCCESS,
      saveAnnouncementTextError:
        CustomerDashboardActions.SAVE_ANNOUNCEMENT_TEXT_ERROR,
    });
  }

  loadData() {
    this.announcementText = currentCustomer().announcement_text || '';
    this.originalAnnouncementText = this.announcementText;

    this.fetchClientReport();
    this.fetchEventReport();
    this.fetchParticipantsReport();
    this.fetchRevenueReport();
  }

  updateAnnouncementText(text) {
    this.announcementText = text;
    this.announcementTextChanged =
      this.announcementText !== this.originalAnnouncementText;
  }

  clearAnnouncementText() {
    this.announcementText = '';
    this.announcementTextChanged =
      this.announcementText !== this.originalAnnouncementText;
  }

  saveAnnouncementText() {
    this.announcementTextChanged = false;

    return uhApiClient.put({
      url: `customers/${currentCustomer().id}`,
      data: JSON.stringify({
        attributes: { announcement_text: this.announcementText },
      }),
      success: CustomerDashboardActions.saveAnnouncementTextSuccess,
      error: CustomerDashboardActions.saveAnnouncementTextError,
    });
  }

  saveAnnouncementTextSuccess(data) {
    this.announcementText = data.customer.announcement_text;
    this.originalAnnouncementText = this.announcementText;

    MessageWindowActions.addMessage.defer('Announcement updated.');
  }

  saveAnnouncementTextError() {
    this.announcementTextChanged = true;
  }

  // eslint-disable-next-line class-methods-use-this
  fetchClientReport() {
    uhApiClient.get({
      url: CLIENT_REPORT_URL,
      success: CustomerDashboardActions.clientReportLoaded,
      error: CustomerDashboardActions.clientReportError,
    });
  }

  setClientReport(data) {
    this.clientReport = fromJS(data);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchEventReport() {
    uhApiClient.get({
      url: EVENT_REPORT_URL,
      success: CustomerDashboardActions.eventReportLoaded,
      error: CustomerDashboardActions.eventReportError,
    });
  }

  setEventReport(data) {
    this.eventReport = fromJS(data);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchParticipantsReport() {
    uhApiClient.get({
      url: PARTICIPANTS_REPORT_URL,
      success: CustomerDashboardActions.participantsReportLoaded,
      error: CustomerDashboardActions.participantsReportError,
    });
  }

  setParticipantsReport(data) {
    this.participantReport = fromJS(data);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchRevenueReport() {
    uhApiClient.get({
      url: REVENUE_REPORT_URL,
      success: CustomerDashboardActions.revenueReportLoaded,
      error: CustomerDashboardActions.revenueReportError,
    });
  }

  setRevenueReport(data) {
    this.revenueReport = fromJS(data);
  }
}

export default alt.createStore(
  CustomerDashboardStore,
  'CustomerDashboardStore'
);
