import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Set } from 'immutable';
import ReactTooltip from 'react-tooltip';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import ProfileManager from 'user_management/shared/components/ProfileManager.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import UserPresentation from 'onboarding/components/UserPresentation.jsx';

import UserActions from 'shared/actions/UserActions.jsx';
import UserCreationActions from 'user_management/shared/actions/UserCreationActions.jsx';

import Header from './Header.jsx';
import DisplayText from './DisplayText.jsx';
import ActionButtons from './ActionButtons.jsx';
import {
  REQUIRED_MANAGED_ACCOUNT_FIELDS,
  REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT,
  REQUIRED_PRIMARY_ACCOUNT_FIELDS_STAFF,
} from '../const';

function Content({
  customerStore,
  customerUserActions,
  customerUserStore,
  intl,
  isClient,
  userCreationStore,
  userStore,
  modalOpen,
}) {
  const handleAddressChange = handleChange({
    actions: customerUserActions,
    prefix: 'address',
  });
  const handleUserProfileChange = handleChange({
    actions: customerUserActions,
  });
  const accessor = isClient ? 'client' : 'staffMember';
  let hideFields = Set(['CurrentPassword', 'LastFourOfSSN']);

  if (modalOpen) {
    hideFields = hideFields.add('ImageUpload');
  }

  return (
    <div>
      <Header
        clientHeader={isClient}
        step={userCreationStore.currentStep}
        customerStore={customerStore}
        userCreationStore={userCreationStore}
        isSaving={userStore.isSaving}
        intl={intl}
        modalOpen={modalOpen}
        customerUserStore={customerUserStore}
      />
      {userCreationStore.currentStep === 'accountCreation' && (
        <div>
          {isClient && !modalOpen && (
            <DisplayText value={userCreationStore.accountType} />
          )}
          <UserPresentation
            modalOpen={modalOpen}
            handleAddressChange={handleAddressChange}
            handleUserProfileChange={(e, value, name) => {
              handleUserProfileChange(e, value, name);
              if (isClient) {
                customerUserActions.validateUser({});
              }
            }}
            hideFields={hideFields}
            errors={customerUserStore.fieldErrors}
            userProfile={customerUserStore[accessor]}
            positionActionsRight
            dobLabelText={
              <FormattedMessage
                id={messageId('.your_date_of_birth', __filenamespace)}
              />
            }
            actions={
              isClient ? (
                <ActionButtons
                  accountType={userCreationStore.accountType}
                  customerUserActions={customerUserActions}
                  customerUserStore={customerUserStore}
                  intl={intl}
                  accessor={accessor}
                  isSaving={userStore.isSaving}
                  modalOpen={modalOpen}
                />
              ) : (
                <StateChangingButton
                  disabled={!customerUserStore.isValid}
                  inProgress={userStore.isSaving}
                  onClick={() => {
                    customerUserActions.validateUser({
                      requiredFields: REQUIRED_PRIMARY_ACCOUNT_FIELDS_STAFF,
                      isNewUser: true,
                    });
                    UserCreationActions.updateNewUser(
                      [],
                      customerUserStore[accessor]
                    );
                    UserActions.register({
                      skipProfiles: true,
                      redirectAfterLogin: true,
                    });
                  }}
                  label={t('actions.continue', intl)}
                  labelInProgress={t('actions.saving', intl)}
                />
              )
            }
          />
        </div>
      )}

      {userCreationStore.currentStep === 'profileCreation' && (
        <div>
          <ProfileManager
            canRemove={athlete => athlete.id < 0}
            currentProfile={customerUserStore[accessor]}
            errors={customerUserStore.fieldErrors}
            isSaving={false}
            onChange={handleUserProfileChange}
            onSave={() => {
              customerUserActions.validateUser({
                requiredFields: REQUIRED_MANAGED_ACCOUNT_FIELDS,
                isNewUser: false,
              });
              UserCreationActions.addProfile();
            }}
            onCancel={() => customerUserActions.clearClient()}
            onRemove={UserCreationActions.removeProfile}
            athletes={userCreationStore.managedProfiles}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            actions={
              <Stack direction="row" spacing={2}>
                <Stack sx={{ flex: 0.5 }}>
                  <Button
                    fullWidth
                    color="dark"
                    variant="contained"
                    sx={{ height: '45px' }}
                    onClick={() => {
                      customerUserActions.stageClient(
                        userCreationStore.newUser
                      );
                      customerUserActions.validateUser({
                        requiredUser: REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT,
                      });
                      UserCreationActions.prevStep();
                    }}
                  >
                    {t('actions.back', intl)}
                  </Button>
                </Stack>
                <Stack data-tip data-for="continue_btn" sx={{ flex: 0.5 }}>
                  <StateChangingButton
                    fullWidth
                    inProgress={userStore.isSaving}
                    sx={{ height: '45px' }}
                    onClick={() => {
                      UserActions.register({
                        skipProfiles: false,
                        redirectAfterLogin: true,
                        redirectPath: modalOpen
                          ? window.location.pathname
                          : '/',
                      });
                    }}
                    disabled={!userCreationStore.managedProfiles.size}
                    label={t('actions.continue', intl)}
                    labelInProgress={t('actions.saving', intl)}
                  />
                </Stack>
                {!userCreationStore.managedProfiles.size && (
                  <ReactTooltip
                    id="continue_btn"
                    effect="solid"
                    style={{ maxWidth: 240 }}
                    className="uh-tooltip"
                  >
                    <FormattedMessage
                      id={messageId('.profile_required', __filenamespace)}
                    />
                  </ReactTooltip>
                )}
              </Stack>
            }
          />
        </div>
      )}
    </div>
  );
}

Content.propTypes = {
  customerStore: PropTypes.object.isRequired,
  customerUserActions: PropTypes.object.isRequired,
  customerUserStore: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isClient: PropTypes.bool.isRequired,
  userCreationStore: PropTypes.object.isRequired,
  userStore: PropTypes.object.isRequired,
};

export default injectIntl(Content);
