/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
import * as React from 'react';
import { Set, List } from 'immutable';
import CustomerActions from 'shared/actions/CustomerActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { logError } from 'ErrorLogger.js';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { Button, Checkbox, Dropdown } from '@upperhand/playmaker';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import {
  SINGLE_COLUMN_WIDTH,
  LEFT_MARGIN_PIXELS,
  RIGHT_MARGIN_PIXELS,
} from 'shared/utils/DOMUtils';

const styles = {
  section: { marginTop: '25px' },
  layout: {
    maxWidth: SINGLE_COLUMN_WIDTH,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: RIGHT_MARGIN_PIXELS,
  },
};

const updateCustomer = handleChange({ actions: CustomerActions });

const featuresUpdate = (e, key, value, features) => {
  const updatedFeatures = features.set(key, value);
  updateCustomer(e, updatedFeatures, 'features');
};

class Config extends React.Component {
  constructor(props) {
    super(props);
    const viewable = currentUser().isAdmin();
    // manual setup our errorhandling and utilize the upperhandstore errorhandling
    this.errorHandler = new UpperHandStore();
    this.errorHandler.NotifyError = logError('AdminConfig');

    this.state = {
      canView: viewable,
      canEdit: currentUser().isAdmin(),
      loading: true,
      saving: false,
      staffAdmins: Set(),
    };

    if (viewable) {
      this.fetchStaffAdmins();
    }
  }

  async patchCustomer() {
    try {
      this.setState({ saving: true });

      const {
        customerStore: { customer },
      } = this.props;
      // eslint-disable-next-line camelcase
      /* eslint-disable camelcase */
      const { features, marketing_admin_id, status } = customer;

      const payload = {
        attributes: {
          feature_flags: features.toJS(),
          marketing_admin_id,
          status,
        },
      };

      await uhApiClient.asyncPatch(`customers/${customer.id}/admin`, payload);
      MessageWindowActions.addMessage('Customer Updated');
      // in the future we could do something with the response, but because we update the "global" customer we dont really need to.
    } catch (e) {
      MessageWindowActions.addMessage('Error Saving');
      this.errorHandler.notifyError('Error while patching customer config', e);
    } finally {
      this.setState({ saving: false });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchStaffAdmins() {
    this.setState({ loading: true });
    try {
      const response = await uhApiClient.asyncGet('customer_users', {
        types: ['staff_admin'],
      });

      const staffAdmin = List(response.data.customer_users)
        .groupBy(sa => sa.id)
        .map(x => x.first());

      this.setState({
        staffAdmins: staffAdmin,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });

      this.errorHandler.notifyError(
        'error while fetching staff Admins for customer',
        e
      );
    }
  }

  customerFeatures() {
    const { canEdit } = this.state;

    const {
      customerStore: {
        customer: { features },
      },
    } = this.props;

    return features
      .map((value, key) => {
        if (typeof value === 'string') {
          return (
            <div>
              {key}: {value}
            </div>
          );
        }

        return (
          <div>
            <Checkbox
              classes={{
                root: 'checkbox',
              }}
              checked={value}
              disabled={!canEdit}
              label={key}
              size="1x"
              onChange={(e, v) => featuresUpdate(e, key, v, features)}
            />
          </div>
        );
      })
      .toList();
  }

  render() {
    const { canView, canEdit, loading, saving, staffAdmins } = this.state;
    const {
      customerStore: { customer },
    } = this.props;

    if (!canView) {
      return <div />;
    }

    if (loading) {
      return <SpinWhileLoading isLoading spinWhile="isLoading" />;
    }

    const staffAdminOptions = staffAdmins
      .toArray()
      .map(x => ({ label: x.email, value: x.id }));

    return (
      <div style={styles.layout}>
        <h2>Admin Config</h2>
        <div style={styles.section}>
          <Dropdown
            id="customerStatus"
            name="status"
            label="Customer status"
            items={[
              { label: 'Active', value: 'active' },
              { label: 'Churned', value: 'churned' },
              { label: 'Test', value: 'test' },
            ]}
            onChange={e => updateCustomer(null, e.target.value, 'status')}
            disabled={!canEdit}
            outline
            fullWidth
            value={customer.status}
          />
        </div>
        <div style={styles.section}>
          <Dropdown
            id="MarketingAdmin"
            name="marketingAdmin"
            label="Marketing email (staffAdmins)"
            items={staffAdminOptions}
            onChange={e =>
              updateCustomer(null, e.target.value, 'marketing_admin_id')
            }
            disabled={!canEdit}
            fullWidth
            outline
            value={customer.marketing_admin_id}
          />
        </div>
        <div style={styles.section}>
          <h2>Customer Features (Admin options)</h2>
          {this.customerFeatures()}
        </div>
        <div style={styles.section}>
          <Button
            disabled={!canEdit || saving}
            onClick={() => this.patchCustomer()}
            size="3x"
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default Config;
