import { Map } from 'immutable';

import { ClassesActions } from 'sources';

import { UpperHandStore } from 'dataStores';

class ClassesDataStore extends UpperHandStore {
  constructor() {
    super();

    this.classes = Map();
    this.bindListeners({
      listSuccess: ClassesActions.listSuccess,
      listError: ClassesActions.listError,
      fetchSuccess: ClassesActions.fetchSuccess,
      fetchError: ClassesActions.fetchError,
      updateSuccess: ClassesActions.updateSuccess,
      updateError: ClassesActions.updateError,
    });
  }

  listSuccess({ class_events: classes }) {
    const fetchedClasses = classes.groupBy(c => c.id).map(c => c.first());

    this.classes = this.classes.merge(fetchedClasses);
  }

  listError(...args) {
    this.notifyError('Error listing classes', args);
  }

  fetchSuccess(classData) {
    const { id } = classData;

    this.classes = this.classes.set(id, classData);
  }

  fetchError(...args) {
    this.notifyError('Error fetching class', args);
  }

  createSuccess(classData) {
    const { id } = classData;

    this.classes = this.classes.set(id, classData);
  }

  createError(...args) {
    this.notifyError('Error creating class', args);
  }

  updateSuccess(classData) {
    const { id } = classData;

    this.classes = this.classes.set(id, classData);
  }

  updateError(...args) {
    this.notifyError('Error updating class', args);
  }
}

export default alt.createStore(ClassesDataStore, 'ClassesDataStore');
