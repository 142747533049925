import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, Grid } from '@upperhand/playmaker';

import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import ButtonMenu from 'shared/components/ButtonMenu.jsx';
import EventTypeSelector from 'customers/settings/components/accounting_tab/EventTypeSelector.jsx';
import TeamTypeSelector from 'customers/settings/components/accounting_tab/TeamTypeSelector.jsx';
import Form from 'shared/components/Form.jsx';
import RetailCategorySelector from 'customers/settings/components/accounting_tab/RetailCategorySelector.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { CODE_TYPES } from 'shared/records/AccountingCode';
import RateForm from './RateForm.jsx';
import SelectedRates from './SelectedRates.jsx';

const styles = {
  clearButton: {
    padding: 9,
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 1,
  },
};

function FormHeader({ accountingData, isFeeRate }) {
  let editHeadingKey = '.edit_accounting_code';
  let createHeadingKey = '.create_accounting_code';
  if (isFeeRate) {
    editHeadingKey = '.edit_service_fee';
    createHeadingKey = '.create_service_fee';
  }
  return (
    <div style={{ fontWeight: 'bold', fontSize: 15 }}>
      {accountingData && accountingData?.id ? (
        <FormattedMessage id={messageId(editHeadingKey, __filenamespace)} />
      ) : (
        <FormattedMessage id={messageId(createHeadingKey, __filenamespace)} />
      )}
    </div>
  );
}
function TaxRateHeader({ isFeeRate }) {
  return (
    <div
      style={{
        fontWeight: 'bold',
        fontSize: 13,
        marginBottom: 10,
        marginTop: 10,
      }}
    >
      <FormattedMessage
        id={messageId(
          isFeeRate ? '.fee_rate' : '.tax_rate_optional',
          __filenamespace
        )}
      />
    </div>
  );
}

const CodeTypeField = injectIntl(
  ({ accountingData, codeTypes, onChange, intl, isFeeRate }) => (
    <>
      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
        {t(isFeeRate ? '.fee_type' : '.code_type', intl, __filenamespace)}
      </Typography>
      <FormControl
        fullWidth
        variant="standard"
        error={!!accountingData.errors.getErrors('code_type', intl)}
      >
        <Select
          displayEmpty
          renderValue={val =>
            val ? (
              t(`.${val}_code_type`, intl, __filenamespace)
            ) : (
              <Typography sx={{ color: uhColors.hint }}>
                {t(
                  isFeeRate
                    ? '.choose_service_fee_type'
                    : '.choose_accounting_code_type',
                  intl,
                  __filenamespace
                )}
              </Typography>
            )
          }
          disabled={accountingData.has_associated_transactions}
          value={accountingData.code_type || ''}
          onChange={onChange}
        >
          {codeTypes.map(type => {
            const cc = currentCustomer();
            if (
              !cc.credit_passes_enabled &&
              type === CODE_TYPES.CREDIT_PASS_CODE_TYPE
            ) {
              return null;
            }
            if (!cc.retail_enabled && type === CODE_TYPES.RETAIL_CODE_TYPE) {
              return null;
            }
            return (
              <MenuItem key={type} value={type}>
                {t(`.${type}_code_type`, intl, __filenamespace)}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {accountingData.errors.getErrors('code_type', intl)}
        </FormHelperText>
      </FormControl>
    </>
  )
);

const CodeField = injectIntl(
  ({ accountingData, onChange, intl, isFeeRate }) => (
    <TextField
      disabled={accountingData.has_associated_transactions}
      placeholder={t('.code', intl, __filenamespace)}
      errorText={accountingData.errors.getErrors('code', intl)}
      label={t(
        isFeeRate ? '.fee_code' : '.accounting_code',
        intl,
        __filenamespace
      )}
      fullWidth
      name="code"
      onChange={onChange}
      value={accountingData.code}
    />
  )
);

const DescriptionField = injectIntl(({ accountingData, onChange, intl }) => (
  <TextField
    errorText={accountingData.errors.getErrors('description', intl)}
    label={t('.description', intl, __filenamespace)}
    fullWidth
    placeholder={t('.description', intl, __filenamespace)}
    multiline
    name="description"
    onChange={onChange}
    value={accountingData.description}
  />
));

export default class AccountingForm extends React.PureComponent {
  onCreateClick = () => {
    const {
      rateCreationStore: { actions },
    } = this.props;
    actions.begin();
  };

  handleTextFieldUpdate = (e, value) => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.updated({ [e.target.name]: value });
  };

  handleCodeTypeUpdate = e => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.codeTypeUpdated(e.target.value);
  };

  handleEventTypeAdded = e => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.eventTypeAdded(e.target.value);
  };

  handleEventTypeCreated = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.eventTypeAdded(id);
  };

  handleEventTypeRemoved = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.eventTypeRemoved(id);
  };

  handleTeamTypeAdded = e => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.teamTypeAdded(e.target.value);
  };

  handleTeamTypeCreated = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.teamTypeAdded(id);
  };

  handleTeamTypeRemoved = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.teamTypeRemoved(id);
  };

  handleRetailCategoryAdded = e => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.retailCategoryAdded(e.target.value);
  };

  handleRetailCategoryCreated = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.retailCategoryAdded(id);
  };

  handleRetailCategoryRemoved = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.retailCategoryRemoved(id);
  };

  handleRateAdded = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.rateAdded(id);
  };

  handleRateRemoved = id => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.rateRemoved(id);
  };

  handleRateEdit = r => {
    const {
      rateEditingStore: { actions },
      rateCreationStore,
      isFeeRate,
    } = this.props;
    const getRate = () => {
      if (isFeeRate) {
        return { rate: rateCreationStore?.feeRate };
      }
      return { rate: rateCreationStore?.taxRate };
    };
    const { rate } = getRate();
    if (!rate) {
      actions.begin(r);
    }
  };

  handleSubmit = () => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.save();
  };

  handleCancel = () => {
    const {
      accountingStore: { actions },
    } = this.props;
    actions.cancel();
  };

  ratesMenuItems() {
    const { intl, isFeeRate, accountingStore } = this.props;
    const getRateList = () => {
      if (isFeeRate) {
        return { rateList: accountingStore?.feeRates };
      }
      return { rateList: accountingStore?.taxRates };
    };
    const { rateList } = getRateList();

    const ratesItems = rateList.map(rate => (
      <MenuItem
        key={rate.id}
        value={rate.id}
        style={{ fontSize: 14 }}
        onClick={() => this.handleRateAdded(rate.id)}
      >
        {rate.name}
      </MenuItem>
    ));

    return (
      <div>
        <MenuItem
          value="new_item"
          onClick={this.onCreateClick}
          style={{ fontSize: 14 }}
        >
          {t(
            isFeeRate ? '.new_fee_rate' : '.new_tax_rate',
            intl,
            __filenamespace
          )}
        </MenuItem>
        <Divider />
        {ratesItems}
      </div>
    );
  }

  render() {
    const {
      accountingStore: {
        codeTypes,
        eventTypes,
        isSaving,
        retailCategories,
        serviceFee,
        accountingCode,
        teamTypes,
      },
      isFeeRate,
      rateEditingStore,
      rateCreationStore,
      intl,
      style,
    } = this.props;
    const getAccountingData = () => {
      if (isFeeRate) {
        return { accountingData: serviceFee };
      }
      return { accountingData: accountingCode };
    };
    const { accountingData } = getAccountingData();
    const selectedRates = accountingData.get(
      isFeeRate ? 'fee_rates' : 'tax_rates'
    );
    return (
      <Paper style={merge({ padding: 16, position: 'relative' }, style)}>
        <IconButton style={styles.clearButton} onClick={this.handleCancel}>
          <ClearIcon sx={{ color: uhColors.iconLightGrey }} />
        </IconButton>

        <FormHeader accountingData={accountingData} isFeeRate={isFeeRate} />

        <Form onSubmit={this.handleSubmit}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <CodeTypeField
                accountingData={accountingData}
                codeTypes={codeTypes}
                onChange={this.handleCodeTypeUpdate}
                isFeeRate={isFeeRate}
              />
            </Grid>
            {accountingData?.isEventCodeType() && (
              <Grid item>
                <EventTypeSelector
                  accountingCode={accountingData}
                  eventTypes={eventTypes}
                  onCreateOrUpdateSuccess={this.handleEventTypeCreated}
                  onEventTypeSelect={this.handleEventTypeAdded}
                  onEventTypeRemoved={this.handleEventTypeRemoved}
                />
              </Grid>
            )}
            {accountingData?.isTeamCodeType() && (
              <Grid item>
                <TeamTypeSelector
                  accountingCode={accountingData}
                  teamTypes={teamTypes}
                  onCreateOrUpdateSuccess={this.handleTeamTypeCreated}
                  onTeamTypeSelect={this.handleTeamTypeAdded}
                  onTeamTypeRemoved={this.handleTeamTypeRemoved}
                />
              </Grid>
            )}
            {accountingData?.isRetailCodeType() && (
              <Grid item>
                <RetailCategorySelector
                  accountingCode={accountingData}
                  retailCategories={retailCategories}
                  onCreateOrUpdateSuccess={this.handleRetailCategoryCreated}
                  onRetailCategorySelect={this.handleRetailCategoryAdded}
                  onRetailCategoryRemoved={this.handleRetailCategoryRemoved}
                />
              </Grid>
            )}
            <Grid item>
              <CodeField
                accountingData={accountingData}
                onChange={this.handleTextFieldUpdate}
                isFeeRate={isFeeRate}
              />
            </Grid>
            <Grid item>
              <DescriptionField
                accountingData={accountingData}
                onChange={this.handleTextFieldUpdate}
                isFeeRate={isFeeRate}
              />
            </Grid>
            <Grid item>
              <TaxRateHeader isFeeRate={isFeeRate} />
              <SelectedRates
                accountingData={accountingData}
                onEdit={this.handleRateEdit}
                onRemove={this.handleRateRemoved}
                rateEditingStore={rateEditingStore}
                isFeeRate={isFeeRate}
                intl={intl}
              />
            </Grid>
            {rateCreationStore?.taxRate || rateCreationStore?.feeRate ? (
              <Grid item>
                <RateForm
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...rateCreationStore}
                  style={{ marginBottom: 20 }}
                  intl={intl}
                  isFeeRate={isFeeRate}
                />
              </Grid>
            ) : (
              <Grid item>
                {selectedRates.size > 0 ? (
                  <ButtonMenu
                    label={t(
                      isFeeRate ? '.add_fee_rate' : '.add_tax_rate',
                      intl,
                      __filenamespace
                    )}
                    labelPosition="after"
                    icon={<AddIcon sx={{ color: uhColors.activeBlue }} />}
                    style={styles.actionMenuButton}
                    anchorOrigin={styles.popover}
                    targetOrigin={styles.popover}
                  >
                    {this.ratesMenuItems()}
                  </ButtonMenu>
                ) : (
                  <Select
                    displayEmpty
                    fullWidth
                    variant="standard"
                    name="tax_rate"
                    renderValue={val =>
                      val || (
                        <Typography sx={{ color: uhColors.hint }}>
                          {t(
                            isFeeRate
                              ? '.choose_fee_rates'
                              : '.choose_tax_rates',
                            intl,
                            __filenamespace
                          )}
                        </Typography>
                      )
                    }
                  >
                    {this.ratesMenuItems()}
                  </Select>
                )}
              </Grid>
            )}
            <Grid item>
              <StateChangingButton
                label={t('.save_code', intl, __filenamespace)}
                inProgress={isSaving}
                onClick={this.handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}
