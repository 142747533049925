import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventTranslator from '../translators/EventTranslator.jsx';

class EventRedirectionStore {
  constructor() {
    this.bindListeners({
      redirectAfterSaveForNewEvents: [EventActions.CREATE_SUCCESS],
    });
  }

  // eslint-disable-next-line class-methods-use-this
  redirectAfterSaveForNewEvents(data) {
    const event = new EventTranslator(data).toClient();

    if (/\/events\/new/.test(window.location.href)) {
      if (event.isClassSchedule()) {
        window.location.href = `${event.admin_path}#schedule`;
      } else {
        const eventEditPath = event.editUrl();

        window.location.href = `${eventEditPath}`;
      }
    }
  }
}

export default alt.createStore(EventRedirectionStore, 'EventRedirectionStore');
