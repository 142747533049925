import * as React from 'react';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import AltContainer from 'alt-container';
import moment from 'moment-timezone';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';

import WhenSignedIn from 'shared/components/WhenSignedIn.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import OpenBookingScheduler, {
  compareFn,
} from 'shared/components/OpenBookingScheduler.jsx';
import EventPrice from 'shared/components/EventPrice.jsx';
import OpenBookingRegistrations from 'shared/components/OpenBookingRegistrations.jsx';

import AvailableTimesStore from 'event_mgmt/shared/stores/SchedulingDrawerAvailableTimesStore.js';
import ClientCreditStore from 'shared/stores/ClientCreditStore';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import EventStaffStore from 'event_mgmt/display/stores/EventStaffStore.js';
import MembershipDiscountStore from 'event_mgmt/display/stores/MembershipDiscountStore.js';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';

import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions.js';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import SchedulingDrawerStore from 'shared/stores/SchedulingDrawerStore.js';

import { SessionDataStore } from 'dataStores';
import { smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhContrast } from 'shared/styles/uhStyles.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { enabledCustomerPreferences } from 'shared/utils/CustomerUtils';

const styles = {
  content: {
    padding: '2rem',
    flex: 1,
  },
  innerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: merge(uhContrast.lightOnDark, {
    fontSize: 15,
  }),
  headerPlaceholder: {
    '.UH-MuiSkeleton-root': { transform: 'none' },
    padding: '15px 30px',
  },
  skeleton: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  productWrapper: {
    padding: '1rem',
  },
  productColor: color => ({
    color,
    width: '15px',
  }),
  productSecondaryInfo: {
    paddingRight: '25px',
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  contentPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    gap: '10px',

    '.UH-MuiSkeleton-root': { transform: 'none' },
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};

function Header({ isLoading, customerEvent, automations, onClose }) {
  if (isLoading)
    return (
      <Stack spacing={1} sx={styles.headerPlaceholder}>
        <Stack direction="row" spacing={1}>
          <Skeleton width="100%" height={20} sx={styles.skeleton} />
          <Skeleton width="100%" height={20} sx={styles.skeleton} />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Skeleton width="100%" height={20} sx={styles.skeleton} />
          <Skeleton width="100%" height={20} sx={styles.skeleton} />
        </Stack>
      </Stack>
    );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      sx={styles.productWrapper}
    >
      <Stack spacing={1}>
        <Typography>{customerEvent.title}</Typography>
        <Stack spacing={1} direction="row">
          <CircleIcon sx={styles.productColor(customerEvent.typeColor())} />
          <Typography>{customerEvent.event_type.name}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Stack spacing={1} sx={styles.productSecondaryInfo}>
          <Typography>{customerEvent.scheduleText()}</Typography>
          <EventPrice event={customerEvent} automations={automations} />
        </Stack>
        <IconButton sx={styles.closeBtn} onClick={onClose}>
          <CloseIcon sx={{ color: '#5B6A72' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
}

function RescheduleHeader({ isLoading, customerEvent, onClose }) {
  return (
    <Stack sx={{ padding: '10px 2rem' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Typography>Reschedule</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: '#5B6A72' }} />
        </IconButton>
      </Stack>
      {isLoading ? (
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Skeleton width="50%" height={20} />
          <Skeleton width="50%" height={20} />
        </Stack>
      ) : (
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Typography sx={{ fontWeight: 'bold' }}>
            {customerEvent.title}
          </Typography>
          <Stack spacing={1} direction="row">
            <CircleIcon sx={styles.productColor(customerEvent.typeColor())} />
            <Typography>{customerEvent.event_type.name}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function SchedulingDrawer({
  clientCreditStore: { clientCredits },
  eventStore: { customerEvent, isLoadingEvent },
  availableTimesStore: {
    availableTimes,
    filteredAvailableTimes,
    staffIds,
    availabilitiesLoading,
    firstAvailableDate,
    areInitialFilteredTimesFetched,
  },
  eventStaffStore: { staff },
  schedulingDrawerStore: {
    client,
    clientRegistrations,
    clients,
    open,
    selectedClientId,
    date,
    step,
    dateTimes,
    isLoading,
    packagePricing,
    isHandling,
    rescheduleRegistrationId,
    rescheduleSessionId,
  },
  sessionDataStore: { sessions },
  cartStore: { cart },
  membershipDiscountStore,
}) {
  const clientAllowedToSkipScheduling = enabledCustomerPreferences([
    'allow_clients_to_skip_scheduling',
  ]);
  const rescheduleSession = sessions.get(rescheduleSessionId);

  React.useEffect(() => {
    if (open && isLoggedIn() && rescheduleSession && customerEvent?.id) {
      SchedulingDrawerActions.registrationStaffChanged([
        rescheduleSession.staff_ids.toArray(),
      ]);
      SchedulingDrawerActions.registrationDateChanged([
        'startDate',
        rescheduleSession.starts_at,
      ]);
    }
  }, [open, customerEvent, rescheduleSession]);

  React.useEffect(() => {
    const canFetchAvailabilities =
      open &&
      isLoggedIn() &&
      customerEvent?.id &&
      !availabilitiesLoading &&
      !areInitialFilteredTimesFetched &&
      !rescheduleSession;

    if (canFetchAvailabilities) {
      SchedulingDrawerActions.fetchAvailabilities();
    }
  }, [
    areInitialFilteredTimesFetched,
    rescheduleSession,
    availabilitiesLoading,
    customerEvent,
    open,
  ]);

  React.useEffect(() => {
    if (
      open &&
      !isLoading &&
      !availabilitiesLoading &&
      !rescheduleRegistrationId
    ) {
      SchedulingDrawerActions.registrationDateChanged([
        'startDate',
        moment(firstAvailableDate),
      ]);
    }
  }, [
    open,
    isLoading,
    firstAvailableDate,
    availabilitiesLoading,
    rescheduleRegistrationId,
  ]);

  const getSelectedStaffId = () => {
    if (staffIds && staffIds.size === 1) {
      return staffIds.first();
    }

    if (staffIds && staffIds.size > 1) {
      return staffIds.toArray().toString();
    }

    return '';
  };

  const handleMonthChange = month => {
    if (!availabilitiesLoading) {
      SchedulingDrawerActions.registrationDateChanged(['startDate', month]);
      SchedulingDrawerActions.fetchAvailabilities(
        moment(month).add(-1, 'month')
      );
    }
  };

  const handleStaffChange = id => {
    SchedulingDrawerActions.registrationStaffChanged([id]);
    SchedulingDrawerActions.registrationDateChanged(['startDate', moment()]);
  };

  const handleDateChange = d =>
    SchedulingDrawerActions.registrationDateChanged(['startDate', d]);

  const handleTimeChange = t =>
    SchedulingDrawerActions.registrationTimeChanged(['startTime', t]);

  const getTemplate = qty => {
    const templates =
      packagePricing?.get('template_parameters', List()).sort(compareFn) ||
      List();
    const template = templates.find(tmp => Number(tmp.get('quantity')) === qty);

    return template;
  };

  const handleAddToCart = (pkg, enableCustomNotification = true) => {
    const { order_items: orderItems } = cart;
    const existedItem = orderItems.find(
      oi =>
        oi.getIn(['orderable', 'event_id']) === pkg.event_id &&
        oi.getIn(['orderable', 'client_ids']).has(selectedClientId)
    );

    if (existedItem) {
      const { orderable } = existedItem;
      const { tentative_details: tentativeDetails } = orderable;
      const updatedTentativeDetails = tentativeDetails
        .concat(pkg.tentative_details)
        .groupBy(d => d.starts_at)
        .map(d => d)
        .toList()
        .flatten(1);

      let updatedPkg = pkg
        .set('tentative_details', updatedTentativeDetails)
        .set('quantity', updatedTentativeDetails.size);

      if (packagePricing) {
        const template = getTemplate(updatedTentativeDetails.size);

        if (template) {
          updatedPkg = updatedPkg
            .set('automation_option_uuid', template.get('uuid'))
            .set(
              'automation_template_description_id',
              packagePricing.get('id')
            );
        } else {
          CartActions.registrationPackageAdded(pkg, {
            redirect: false,
            customSuccessAction: enableCustomNotification
              ? SchedulingDrawerActions.packageAddedToCart
              : undefined,
          });
          return;
        }
      }

      CartActions.update(existedItem.id, updatedPkg);
      return;
    }
    CartActions.registrationPackageAdded(pkg, {
      redirect: false,
      customSuccessAction: enableCustomNotification
        ? SchedulingDrawerActions.packageAddedToCart
        : undefined,
    });
  };

  const handleAfterProfileCreate = id => {
    SchedulingDrawerActions.setClients.defer(id);
  };

  if (!open) return null;

  return (
    <Drawer
      disableEnforceFocus
      sx={{ zIndex: 1300 }}
      PaperProps={{
        sx: {
          width: smallScreen() ? window.innerWidth * 0.9 : '500px',
        },
      }}
      anchor="right"
      open={open}
    >
      {rescheduleRegistrationId ? (
        <RescheduleHeader
          isLoading={isLoadingEvent}
          customerEvent={customerEvent}
          onClose={() => SchedulingDrawerActions.drawerClosed()}
        />
      ) : (
        <div style={styles.header}>
          <Header
            isLoading={isLoadingEvent}
            customerEvent={customerEvent}
            automations={List([packagePricing])}
            onClose={() => SchedulingDrawerActions.drawerClosed()}
          />
        </div>
      )}
      <div style={styles.content}>
        <WhenSignedIn
          notSignedIn={<SignInPrompt />}
          style={styles.innerContent}
        >
          {isLoading && (
            <Stack spacing={1} sx={styles.contentPlaceholder}>
              <Stack sx={styles.topContent}>
                <Skeleton height={60} />
                <Stack direction="row" spacing={1}>
                  {customerEvent.allow_staff_selection && (
                    <Skeleton width="100%" height={60} />
                  )}
                  <Skeleton width="100%" height={60} />
                </Stack>
                <Skeleton width="100%" height={320} />
              </Stack>
              <Stack direction="row" spacing={1}>
                {clientAllowedToSkipScheduling && (
                  <Skeleton width="50%" height={60} />
                )}
                <Skeleton
                  width={clientAllowedToSkipScheduling ? '50%' : '100%'}
                  height={60}
                />
              </Stack>
            </Stack>
          )}
          {!isLoading && (
            <>
              {step === 1 && (
                <OpenBookingScheduler
                  availabilitiesLoading={availabilitiesLoading}
                  isLoading={isHandling}
                  clientId={selectedClientId || client.id}
                  clientCredits={clientCredits}
                  availableTimes={availableTimes}
                  filteredAvailableTimes={filteredAvailableTimes}
                  event={customerEvent}
                  eventDiscounts={membershipDiscountStore.findByEventTypeId(
                    customerEvent.event_type.id
                  )}
                  staff={staff}
                  allProfiles={clients.filter(c =>
                    c.hasAccessTo(customerEvent)
                  )}
                  selectedDate={date}
                  selectedStaffId={getSelectedStaffId()}
                  selectedDateTimes={dateTimes}
                  packagePricing={packagePricing}
                  rescheduleSession={rescheduleSession}
                  rescheduleRegistrationId={rescheduleRegistrationId}
                  onMonthChange={handleMonthChange}
                  onClientChange={
                    SchedulingDrawerActions.registrationAttendeeSelected
                  }
                  onStaffChange={handleStaffChange}
                  onDateChange={handleDateChange}
                  onTimeChange={handleTimeChange}
                  onAddToCart={handleAddToCart}
                  onRegistrationBooked={
                    SchedulingDrawerActions.registrationBooked
                  }
                  onRegistrationReschedule={
                    SchedulingDrawerActions.registrationReschedule
                  }
                  onProfileCreated={handleAfterProfileCreate}
                  onClose={SchedulingDrawerActions.drawerClosed}
                />
              )}
              {step === 2 && (
                <OpenBookingRegistrations
                  rescheduleRegistrationId={rescheduleRegistrationId}
                  registrations={clientRegistrations}
                  event={customerEvent}
                  staff={staff}
                  allAthletes={clients}
                  sessions={sessions}
                  onComplete={() => SchedulingDrawerActions.drawerClosed()}
                  onReturn={() => SchedulingDrawerActions.setCurrentStep(1)}
                />
              )}
            </>
          )}
        </WhenSignedIn>
      </div>
    </Drawer>
  );
}

function WrappedSchedulingDrawer(props) {
  return (
    <AltContainer
      stores={{
        availableTimesStore: AvailableTimesStore,
        clientCreditStore: ClientCreditStore,
        eventStaffStore: EventStaffStore,
        eventStore: EventStore,
        schedulingDrawerStore: SchedulingDrawerStore,
        sessionDataStore: SessionDataStore,
        cartStore: CartStore,
        membershipDiscountStore: MembershipDiscountStore,
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SchedulingDrawer {...props} />
    </AltContainer>
  );
}

export default injectIntl(WrappedSchedulingDrawer);
