import * as React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Icon,
} from '@upperhand/playmaker';
import { Filters } from 'containers/reports/types';
import ReactTooltip from 'react-tooltip';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { t } from 'shared/utils/LocaleUtils';

import DateRangeDropdown from 'components/DateRangeDropdown';
import ActiveFilters from './ActiveFilters.jsx';
import Statistics from './Statistics.jsx';

import { hasFilterDrawerDefaults } from '../../utils';
import TransactionsLedgerReportActions from '../../Actions';

function Header({
  defaultFilters,
  filters,
  intl,
  showHeaderTitle,
  statistics,
}) {
  return (
    <Grid container item spacing={2} alignContent="center" justify="center">
      {showHeaderTitle && (
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className="transactions-ledger-report__title"
          >
            {t('.header', intl, __filenamespace)}
            <div
              data-tip={t('.tooltip', intl, __filenamespace)}
              data-for="header-tooltip"
              className="transactions-ledger-report__tooltip"
              data-html
            >
              <Icon
                name="info"
                className="transactions-ledger-report__tooltip_icon"
              />
            </div>
          </Typography>
          <ReactTooltip
            id="header-tooltip"
            className="uh-tooltip"
            effect="solid"
            place="bottom"
            multiline
            html
          />
        </Grid>
      )}

      <ResponsiveElement
        largeScreen={
          <>
            <Grid item xs={12}>
              <Grid container item spacing={1}>
                <Grid item xs={2}>
                  <DateRangeDropdown
                    label={false}
                    onChange={value =>
                      TransactionsLedgerReportActions.updateFilter(
                        Filters.PERIOD,
                        value
                      )
                    }
                    value={
                      filters.get('period')
                        ? filters.get('period').value
                        : defaultFilters.get('period').value
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    classes={{
                      root: 'transactions-ledger-report__search-field',
                    }}
                    fullWidth
                    icon="search"
                    onChange={event =>
                      TransactionsLedgerReportActions.updateFilter(
                        Filters.SEARCH,
                        event.target.value
                      )
                    }
                    placeholder={t('.search', intl, __filenamespace)}
                    rounded
                    value={
                      filters.get('search') || defaultFilters.get('search')
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    classes={{
                      root: 'transactions-ledger-report__filter-button',
                      label: 'transactions-ledger-report__header-button-label',
                    }}
                    fullWidth
                    icon="filter"
                    iconPosition="right"
                    labelAlign="space-between"
                    onClick={TransactionsLedgerReportActions.openFilterDrawer}
                    type="tertiary"
                    rounded
                  >
                    {t('.btn_filter', intl, __filenamespace)}
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    icon={
                      <Icon
                        name="cloudDownload"
                        className="transactions-ledger-report__export-button-icon"
                      />
                    }
                    onClick={TransactionsLedgerReportActions.downloadList}
                    type="tertiary"
                    rounded
                    classes={{
                      root: 'transactions-ledger-report__export-button',
                      label: 'transactions-ledger-report__header-button-label',
                    }}
                  >
                    {t('.btn_export', intl, __filenamespace)}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {filters.size > 0 && (
              <Grid item xs={12}>
                <ActiveFilters
                  activeFilters={filters}
                  defaultFilters={defaultFilters}
                  onRemoveFilterChips={
                    TransactionsLedgerReportActions.removeFilter
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Statistics statistics={statistics} />
            </Grid>
          </>
        }
        smallScreen={
          <Grid item xs={12}>
            <Grid container item spacing={1}>
              <Grid item xs>
                <TextField
                  classes={{
                    root: 'transactions-ledger-report__search-field',
                  }}
                  fullWidth
                  icon="search"
                  onChange={event =>
                    TransactionsLedgerReportActions.updateFilter(
                      'search',
                      event.target.value
                    )
                  }
                  placeholder={t('.search', intl, __filenamespace)}
                  rounded
                  value={filters.get('search')}
                />
              </Grid>
              <Grid item>
                <Button
                  type={
                    hasFilterDrawerDefaults(filters) ? 'tertiary' : 'primary'
                  }
                  fullWidth
                  icon="filter"
                  rounded
                  onClick={TransactionsLedgerReportActions.openFilterDrawer}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
}

Header.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  showHeaderTitle: PropTypes.bool.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
};

export default injectIntl(Header);
