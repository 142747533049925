import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';

import PreferredStaffSelector from 'resources/components/PreferredStaffSelector.jsx';
import LocationSelector from 'resources/components/LocationSelector.jsx';
import ResourceDeletionActions from 'resources/actions/ResourceDeletionActions.js';
import ResourceActions from 'resources/actions/ResourceActions.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    padding: 16,
    fontSize: 15,
    '& > *': {
      marginTop: '16px !important',
    },
  },
  title: {
    fontWeight: 'bold',
    margin: '0 0 16px !important',
  },
};

function ResourceForm({
  resourceEditingStore: {
    resource,
    allowStaffPreference,
    linkLocation,
    availableStaff,
    selectedStaff,
  },
  intl,
  style,
  classes,
}) {
  return (
    <Paper className={classes.root} style={style}>
      <div className={classes.title}>
        {resource.id ? (
          <FormattedMessage id={messageId('.edit_resource', __filenamespace)} />
        ) : (
          <FormattedMessage
            id={messageId('.create_new_resource', __filenamespace)}
          />
        )}
      </div>

      <TextField
        errorText={resource.errors.getErrors('name', intl)}
        placeholder={t('.resource_name', intl, __filenamespace)}
        label={t('.resource_name', intl, __filenamespace)}
        fullWidth
        onChange={(_, value) => ResourceActions.nameUpdated(value)}
        value={resource.name}
      />

      <PreferredStaffSelector
        allowStaffPreference={allowStaffPreference}
        availableStaff={availableStaff}
        selectedStaff={selectedStaff}
        intl={intl}
      />

      <LocationSelector
        resource={resource}
        linkLocation={linkLocation}
        intl={intl}
      />

      <TextField
        errorText={resource.errors.getErrors('square_feet', intl)}
        placeholder={t('.area_square_feet', intl, __filenamespace)}
        label={t('.area_square_feet', intl, __filenamespace)}
        fullWidth
        onChange={(_, value) => ResourceActions.areaUpdated(value)}
        value={resource.square_feet}
      />

      {resource.id && (
        <Button
          startIcon={
            <DeleteIcon
              style={{ marginLeft: 0, color: uhColors.iconLightGrey }}
              onClick={() => ResourceDeletionActions.deleteRequested(resource)}
            />
          }
          color="dark"
        >
          {t('.remove_resource', intl, __filenamespace)}
        </Button>
      )}
    </Paper>
  );
}

export default withStyles(styles)(ResourceForm);
