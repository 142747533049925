import * as React from 'react';
import { injectIntl } from 'react-intl';

import EventTypeInlineEditor from 'shared/components/EventTypeInlineEditor.jsx';
import SelectedEventType from 'shared/components/SelectedEventType.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhFormElement } from 'shared/styles/uhStyles.jsx';

const EventTypeSelector = injectIntl(
  ({
    accountingCode,
    eventTypes,
    onCreateOrUpdateSuccess,
    onEventTypeSelect,
    onEventTypeRemoved,
    intl,
  }) => (
    <div>
      <EventTypeInlineEditor
        eventTypes={eventTypes}
        errorText={accountingCode.errors.getErrors('event_type_ids', intl)}
        label={t('.assign_event_types', intl, __filenamespace)}
        floatingLabelFixed
        floatingLabelStyle={uhFormElement.floatingLabel}
        hintText={t('.choose_event_types', intl, __filenamespace)}
        onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
        onEventTypeSelect={onEventTypeSelect}
        fullWidth
      />

      {accountingCode.event_types.map(et => (
        <SelectedEventType
          eventType={et}
          onRemove={onEventTypeRemoved}
          style={{ fontWeight: 'bold' }}
          key={et.id}
        />
      ))}
    </div>
  )
);

export default EventTypeSelector;
