import TimeLogEditingActions from 'reporting/payroll/actions/TimeLogEditingActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import TimeLogEditingStoreInterface from 'reporting/payroll/stores/TimeLogEditingStoreInterface';

export default alt.createStore(
  TimeLogEditingStoreInterface,
  'TimeLogEditingStore',
  {
    actions: TimeLogEditingActions,
    successMessage: new TranslatableMessage({
      id: '.updated',
      filename: __filenamespace,
    }),
  }
);
