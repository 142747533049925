import { Record, Set } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent } from 'shared/utils/ObjectUtils.jsx';
import { isJumpStartSportsTheme } from 'shared/utils/ThemeUtils';

const MAX_RATE_PERCENTAGE = isJumpStartSportsTheme() ? 5.3 : 4.0;

class FeeRate extends Record({
  id: null,
  name: '',
  rate: null,
  accounting_code_ids: Set(),
  errors: new FieldErrors(),
}) {
  validate() {
    let errors = this.errors.clear();

    ['name', 'rate'].forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
      if (
        isPresent(
          this[f] &&
            f === 'rate' &&
            !(this[f] >= 0 && this[f] <= MAX_RATE_PERCENTAGE)
        )
      ) {
        errors = errors.add(f, 'rate value should be between 0 and 5');
      }
    });

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  toServer() {
    return this.update('rate', rate => rate || 0.0).toJS();
  }
}

export default FeeRate;
