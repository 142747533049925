import { getFileInfo } from 'shared/utils/SharedUtils.js';
import { Image } from 'records';

export const getRemoteImage = original => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', original.getSrc());
  xhr.responseType = 'blob';
  xhr.send();

  const fileUrl = getFileInfo(original.getSrc());

  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      const image = new Image({
        ...original,
        file: {
          ...xhr.response,
          name:
            original.file?.name ||
            original.name ||
            `${fileUrl.getName()}.${fileUrl.getExtension()}`,
        },
        url: URL.createObjectURL(xhr.response),
      });

      resolve(image);
    };
    xhr.ontimeout = () => reject(xhr);
    xhr.abort = () => reject(xhr);
    xhr.error = () => reject(xhr);
  });
};

export const getCroppedImg = (imageDOM, crop, imageSrc) => {
  const canvas = document.createElement('canvas');
  const scaleX = imageDOM.naturalWidth / imageDOM.width;
  const scaleY = imageDOM.naturalHeight / imageDOM.height;
  canvas.width = crop.width * scaleX;
  canvas.height = canvas.width * (1 / crop.aspect);
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    imageDOM,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      /* Inside cordova app we are using cordova-plugin-file that has next classes: File, FileReader and it causes issues on the mobile side when the user tries to upload an image.
         That issue appears because of the cordova-plugin-remote-injection plugin. It injects cordova and installed plugin JS into the webview for any remotely browsed page 
         allowing them the same access to the cordova object and its plugins as a packaged cordova app. So File, FileReader from cordova-plugin-file will override corresponding 
         classes from Web API. So new File() will not work in the way as we expected so we will create a file from the blob without using the new File() construction.
         Blob() is almost a File() - it's just missing the two properties below which we will add those properties.
      */
      const file = blob;

      file.name = imageSrc.name || '';
      file.lastModifiedDate = new Date();

      const image = {
        file,
        url: URL.createObjectURL(blob),
        originalData: imageSrc.getSrcForCropping(),
      };

      resolve(image);
    }, imageSrc?.file?.type || 'image/png');
  });
};
