import { createBrowserHistory } from 'history';
import { urlChange } from 'Trackers.js';

// Singleton instance
const history = createBrowserHistory();

export default history;

// notify trackers of page change
history.listen(location => {
  urlChange(location);
});
