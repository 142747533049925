import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import './icons_styles.scss';

export default function (props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props} className="admin-client-wavier">
      <path
        d="M22.6327 4.95914C20.8504 3.43489 17.988 3.7667 16.2483 5.69534L8.23587 14.592C8.08158 14.763 8.00178 14.986 8.01242 15.2141L8.0337 15.6651L8.07626 16.5413L8.11882 17.4175L8.25183 20.1653C8.26779 20.409 8.37952 20.6371 8.57105 20.7926C8.75194 20.9378 8.98604 21.0104 9.22013 20.9948L9.25737 20.9896L14.1202 20.409C14.3383 20.3779 14.5405 20.2794 14.6841 20.1186L22.7018 11.222C23.5318 10.3044 23.984 9.15859 23.9894 7.99726C24 6.78927 23.5159 5.71089 22.6327 4.95914ZM22.2017 7.99207C22.2017 8.72827 21.9038 9.46447 21.3664 10.0607L13.5668 18.7085L10.0075 19.1336L9.91177 17.2775L9.86921 16.4013L9.82665 15.5252L9.82133 15.4785L17.605 6.8463C18.685 5.64349 20.4141 5.38945 21.4462 6.276C21.9304 6.69076 22.1964 7.30254 22.2017 7.99207Z"
        fill="#9A9EAD"
      />
      <path
        d="M8.07094 5.81456L6.93771 4.67397C6.83662 4.57547 6.67701 4.57028 6.57061 4.66879L4.45312 6.68038L2.38883 4.61694C2.28774 4.51325 2.12813 4.51325 2.02173 4.61176L0.856574 5.71606C0.755488 5.80938 0.750168 5.9701 0.851254 6.0686L2.91554 8.13722L0.79273 10.1488C0.691644 10.2473 0.686324 10.4029 0.78741 10.5065L1.92064 11.6419C2.02173 11.7405 2.18134 11.7456 2.28774 11.6471L4.41055 9.63554L6.47484 11.7042C6.57593 11.8027 6.73554 11.8079 6.84194 11.7093L8.00709 10.605C8.10818 10.5065 8.1135 10.351 8.01242 10.2473L5.94813 8.1787L8.07094 6.16711C8.1667 6.07379 8.17202 5.91307 8.07094 5.81456Z"
        fill="var(--color-unpaid-red)"
      />
      <path
        d="M16.6403 6.8145L15.4536 8.13123L20.9584 12.8423L22.1451 11.5256L16.6403 6.8145Z"
        fill="#9A9EAD"
      />
      <path
        d="M9.59255 14.9134L9.56063 15.7896L9.53403 16.6658H0.899135C0.404345 16.6709 0 16.2769 0 15.7948C0 15.3074 0.404345 14.9186 0.899135 14.9186H9.59255V14.9134Z"
        fill="#9A9EAD"
      />
    </SvgIcon>
  );
}
