import { OrderedSet, Set, Map } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import {
  CreditGrantSource,
  EventSource,
  EventTypeSource,
  CreditCountSource,
  RegistrationPackageSource,
  ClientSource,
} from 'sources';

import CreditListDrawerActions from './Actions';

export const TABS = {
  CREDIT_GRANTS: 'CREDIT_GRANTS',
  REGISTRATION_PACKAGE_CREDITS: 'REGISTRATION_PACKAGE_CREDITS',
};

class CreditListDrawerStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      openDrawer: CreditListDrawerActions.openDrawer,
      closeDrawer: CreditListDrawerActions.closeDrawer,
      onTabChange: CreditListDrawerActions.onTabChange,
      listCreditGrantsSuccess: CreditListDrawerActions.listCreditGrantsSuccess,
      listCreditGrantsError: CreditListDrawerActions.listCreditGrantsError,
      listGrantsPrevNext: CreditListDrawerActions.listGrantsPrevNext,
      listEventsSuccess: CreditListDrawerActions.listEventsSuccess,
      listEventsError: CreditListDrawerActions.listEventsError,
      listEventTypesSuccess: CreditListDrawerActions.listEventTypesSuccess,
      listEventTypesError: CreditListDrawerActions.listEventTypesError,
      openAdjustmentModal: CreditListDrawerActions.openAdjustmentModal,
      closeAdjustmentModal: CreditListDrawerActions.closeAdjustmentModal,
      updateCreditGrant: CreditListDrawerActions.updateCreditGrant,
      updateCreditGrantSuccess:
        CreditListDrawerActions.updateCreditGrantSuccess,
      updateCreditGrantError: CreditListDrawerActions.updateCreditGrantError,
      listCreditCounts: CreditListDrawerActions.listCreditCounts,
      listCreditCountsSuccess: CreditListDrawerActions.listCreditCountsSuccess,
      listCountsPrevNext: CreditListDrawerActions.listCountsPrevNext,
      listRegistrationPackages:
        CreditListDrawerActions.listRegistrationPackages,
      listRegistrationPackagesSuccess:
        CreditListDrawerActions.listRegistrationPackagesSuccess,
      togglePackageAdjustmentModal:
        CreditListDrawerActions.togglePackageAdjustmentModal,
      updateRegistrationPackage:
        CreditListDrawerActions.updateRegistrationPackage,
      updateRegistrationPackageSuccess:
        CreditListDrawerActions.updateRegistrationPackageSuccess,
      updateRegistrationPackageError:
        CreditListDrawerActions.updateRegistrationPackageError,
    });
  }

  reset() {
    this.clientId = 0;
    this.packageAdjustment = Map({
      packageId: null,
      eventId: null,
    });
    this.isOpen = false;
    this.packagesLoading = Map();
    this.isLoadingGrants = false;
    this.isLoadingRegistrationPackages = false;
    this.isLoadingEvents = false;
    this.isLoadingEventTypes = false;
    this.creditGrantIds = OrderedSet();
    this.totalCredits = 0;
    this.adjustableCreditGrantId = null;
    this.activeTab = TABS.CREDIT_GRANTS;
    this.pagination = Map({
      registrationPackages: Map({ page: 1, perPage: 20, totalCount: 0 }),
      creditGrants: Map({ page: 1, perPage: 20, totalCount: 0 }),
    });
  }

  openDrawer({ clientId }) {
    this.isOpen = true;
    this.clientId = clientId;
    this.fetchDependencies();
    this.listCreditCounts();
  }

  closeDrawer() {
    this.reset();
  }

  onTabChange(newTab) {
    this.activeTab = newTab;
  }

  fetchDependencies() {
    const page = this.pagination.getIn(['creditGrants', 'page']);
    const perPage = this.pagination.getIn(['creditGrants', 'perPage']);

    this.isLoadingGrants = true;
    CreditGrantSource.list({
      params: {
        client_ids: [this.clientId],
        per_page: perPage,
        page,
      },
      success: CreditListDrawerActions.listCreditGrantsSuccess,
      error: CreditListDrawerActions.listCreditGrantsError,
    });
  }

  listGrantsPrevNext(pagination) {
    this.pagination = this.pagination.setIn(
      ['creditGrants', 'page'],
      pagination[0]
    );
    this.fetchDependencies();
  }

  listCreditGrantsSuccess({ credit_grants: creditGrants, page, totalCount }) {
    this.isLoadingGrants = false;
    let creditGrantIds = OrderedSet();
    let eventIds = Set();
    let eventTypeIds = Set();
    creditGrants.forEach(cg => {
      creditGrantIds = creditGrantIds.add(cg.id);
      eventIds = eventIds.union(cg.event_ids);
      eventTypeIds = eventTypeIds.union(cg.event_type_ids);
    });

    this.creditGrantIds = creditGrantIds;
    this.pagination = this.pagination.setIn(['creditGrants', 'page'], page);
    this.pagination = this.pagination.setIn(
      ['creditGrants', 'totalCount'],
      totalCount
    );

    if (eventIds.size > 0) {
      this.isLoadingEvents = true;
      EventSource.list({
        params: {
          ids: eventIds.toArray(),
        },
        success: CreditListDrawerActions.listEventsSuccess,
        error: CreditListDrawerActions.listEventsError,
      });
    }

    if (eventTypeIds.size > 0) {
      this.listEventTypes({ eventTypeIds });
    }
  }

  listEventsSuccess() {
    this.isLoadingEvents = false;
  }

  listEventTypes({ eventTypeIds = Set(), page = 1 }) {
    this.isLoadingEventTypes = true;
    EventTypeSource.list({
      params: {
        page,
        per_page: 50,
        ids: eventTypeIds.toArray(),
      },
      success: {
        action: CreditListDrawerActions.listEventTypesSuccess,
        args: [eventTypeIds],
      },
      error: CreditListDrawerActions.listEventTypesError,
    });
  }

  listEventTypesSuccess([{ page, perPage, totalCount }, eventTypeIds]) {
    if (page * perPage < totalCount) {
      this.listEventTypes({ eventTypeIds, page: page + 1 });
    } else {
      this.isLoadingEventTypes = false;
    }
  }

  listCreditGrantsError(...args) {
    this.notifyError('Error listing credit grants:', ...args);
    this.isLoadingGrants = false;
  }

  listEventsError(...args) {
    this.notifyError('Error listing events:', ...args);
    this.isLoadingEvents = false;
  }

  listEventTypesError(...args) {
    this.notifyError('Error listing event types:', ...args);
    this.isLoadingEventTypes = false;
  }

  openAdjustmentModal(creditGrantId) {
    this.adjustableCreditGrantId = creditGrantId;
  }

  closeAdjustmentModal() {
    this.adjustableCreditGrantId = null;
  }

  updateCreditGrant({ id, quantity, expiresAt }) {
    const attributes = {};

    if (quantity >= 0 && quantity !== Infinity) attributes.quantity = quantity;
    if (expiresAt) attributes.expires_at = expiresAt;

    if (Object.keys(attributes).length === 0) {
      this.adjustableCreditGrantId = null;
    } else {
      CreditGrantSource.update({
        id,
        attributes,
        success: CreditListDrawerActions.updateCreditGrantSuccess,
        error: CreditListDrawerActions.updateCreditGrantError,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateCreditGrantError() {
    const message = new TranslatableMessage({
      id: 'containers.creditListDrawer.Store.grant_update_error',
    });

    MessageWindowActions.addMessage.defer(message);
  }

  updateCreditGrantSuccess() {
    this.adjustableCreditGrantId = null;

    const message = new TranslatableMessage({
      id: 'containers.creditListDrawer.Store.grant_update_success',
    });
    MessageWindowActions.addMessage.defer(message);
  }

  listCreditCounts() {
    const page = this.pagination.getIn(['registrationPackages', 'page']);
    const perPage = this.pagination.getIn(['registrationPackages', 'perPage']);

    this.isLoadingRegistrationPackages = true;
    CreditCountSource.list({
      params: {
        client_ids: this.clientId,
        source_types: ['registration_package'],
        per_page: perPage,
        page,
      },
      success: CreditListDrawerActions.listCreditCountsSuccess,
      error: () => null,
    });
  }

  listCreditCountsSuccess({ credit_counts: creditsList, page, totalCount }) {
    this.isLoadingRegistrationPackages = false;
    this.pagination = this.pagination.setIn(
      ['registrationPackages', 'page'],
      page
    );
    this.pagination = this.pagination.setIn(
      ['registrationPackages', 'totalCount'],
      totalCount
    );

    const eventIds = creditsList.map(credit => credit.event_id).toOrderedSet();

    if (eventIds.size > 0) {
      const perPage = this.pagination.getIn([
        'registrationPackages',
        'perPage',
      ]);

      this.isLoadingEvents = true;
      EventSource.list({
        params: {
          ids: eventIds.toArray(),
          per_page: perPage,
        },
        success: CreditListDrawerActions.listEventsSuccess,
        error: CreditListDrawerActions.listEventsError,
      });
    }
  }

  listCountsPrevNext(pagination) {
    this.pagination = this.pagination.setIn(
      ['registrationPackages', 'page'],
      pagination[0]
    );
    this.listCreditCounts();
  }

  listRegistrationPackages({ eventId, page = 1 }) {
    this.packagesLoading = this.packagesLoading.set(eventId, true);
    RegistrationPackageSource.list({
      params: {
        client_ids: [this.clientId],
        event_ids: [eventId],
        with_available_credits: true,
        per_page: 20,
        page,
      },
      success: {
        action: CreditListDrawerActions.listRegistrationPackagesSuccess,
        args: [{ eventId }],
      },
    });
  }

  listRegistrationPackagesSuccess([
    { page, registration_packages: packages },
    { eventId },
  ]) {
    if (packages.size > 0) {
      this.listRegistrationPackages({ eventId, page: page + 1 });
    } else {
      this.packagesLoading = this.packagesLoading.set(eventId, false);
    }
  }

  togglePackageAdjustmentModal({ packageId = null, eventId = null }) {
    this.packageAdjustment = this.packageAdjustment.set('packageId', packageId);
    this.packageAdjustment = this.packageAdjustment.set('eventId', eventId);
  }

  updateRegistrationPackage({ quantity, updatedQuantity }) {
    const params = {
      quantity,
    };
    if (updatedQuantity < quantity) {
      params.quantity_revoked = quantity - updatedQuantity;
    } else {
      params.quantity = updatedQuantity;
    }
    RegistrationPackageSource.update({
      id: this.packageAdjustment.get('packageId'),
      params,
      success: CreditListDrawerActions.updateRegistrationPackageSuccess,
      error: CreditListDrawerActions.updateRegistrationPackageError,
    });
  }

  updateRegistrationPackageSuccess({ client_ids: clientIds }) {
    ClientSource.fetch({
      id: clientIds.first(),
      params: { fields: ['total_credits'] },
      success: () => null,
    });
    this.togglePackageAdjustmentModal({});

    const message = new TranslatableMessage({
      id: 'containers.creditListDrawer.Store.grant_update_success',
    });

    MessageWindowActions.addMessage.defer(message);
  }

  updateRegistrationPackageError() {
    this.togglePackageAdjustmentModal({});

    const message = new TranslatableMessage({
      id: 'containers.creditListDrawer.Store.grant_update_error',
    });

    MessageWindowActions.addMessage.defer(message);
  }
}

export default alt.createStore(CreditListDrawerStore, 'CreditListDrawerStore');
