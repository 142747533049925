import React from 'react';
import { Grid, Typography } from '@upperhand/playmaker';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';

import { t } from 'shared/utils/LocaleUtils';

import DetailsActions from '../actions';

// TODO: Refactor Autocomplete to use this as CSS
const styles = {
  popUpIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

// TODO: Add autocomplete from Playmaker once it is done.
function AddResource({ intl, selectableResources, searchedResourceName }) {
  return (
    <Grid item xs={12}>
      <Typography variant="caption">
        {t('.add_resource', intl, __filenamespace)}
      </Typography>
      <Autocomplete
        clearOnBlur
        noOptionsText=""
        inputValue={searchedResourceName || ''}
        sx={{ margin: '5px 0' }}
        options={selectableResources}
        getOptionLabel={option => option.text}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={t('.placeholder', intl, __filenamespace)}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            sx={{ justifyContent: 'space-between' }}
            key={option.value}
            onClick={() =>
              DetailsActions.addResource([{ value: option.value }])
            }
          >
            {option.text}
          </MenuItem>
        )}
        popupIcon={
          <div
            role="presentation"
            style={styles.popUpIcon}
            onClick={DetailsActions.cancelResourceAdd}
          >
            <CancelIcon />
          </div>
        }
        onInputChange={(_e, value) =>
          DetailsActions.updateResourceSearchText([value])
        }
      />
      <Typography color="secondary" variant="body2">
        {t('.hint', intl, __filenamespace)}
      </Typography>
    </Grid>
  );
}

export default AddResource;
