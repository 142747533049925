import moment from 'moment-timezone';
import { Iterable, Record, Set } from 'immutable';

class TentativeRegistration extends Record({
  client_id: null,
  session_id: null,
  schedule_id: null,
  starts_at: null,
  ends_at: null,
  staff_ids: Set(),
  resource_ids: Set(),
  open_booking_starts_at: null, // there is start date of open booking we need to end date of
}) {
  constructor(obj = {}) {
    const plainObj = Iterable.isIterable(obj) ? obj.toJS() : obj;

    const startsAt = obj.starts_at ? moment(obj.starts_at) : null;
    const endsAt = obj.ends_at ? moment(obj.ends_at) : null;
    const openBookingStartsAt = obj.open_booking_starts_at
      ? moment(obj.open_booking_starts_at)
      : null;
    super({
      ...plainObj,
      starts_at: startsAt,
      ends_at: endsAt,
      open_booking_starts_at: openBookingStartsAt,
    });
  }

  get start_time() {
    return this.starts_at ? this.starts_at.format('HH:mm') : null;
  }

  get open_booking_starts_at() {
    return this.starts_at ? this.open_booking_starts_at.format('HH:mm') : null;
  }

  get end_time() {
    return this.start_at ? this.ends_at.format('HH:mm') : null;
  }

  toServer() {
    const obj = this.toJS();

    if (this.session_id) {
      delete obj.schedule_id;
      delete obj.starts_at;
      delete obj.ends_at;
      delete obj.staff_ids;
      delete obj.resource_ids;
    } else {
      delete obj.session_id;
    }

    return obj;
  }
}

export default TentativeRegistration;
