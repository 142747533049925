import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Confirmation,
  Typography,
  RadioButton,
  Grid,
} from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import ClassCancellationActions from './Actions';
import ClassCancellationStore, { creditOperations } from './Store';

import './styles.scss';

function ClassCancelModal({
  intl,
  onConfirm,
  onCancel,
  classCancellationStore: { classIdToCancel, creditOperation, loading },
}) {
  return (
    <Confirmation
      level="warning"
      title={t('.title', intl, __filenamespace)}
      open={!!classIdToCancel}
      onConfirm={onConfirm}
      onCancel={onCancel}
      hasCheckbox={false}
      confirmationDisabled={loading}
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
    >
      <div className="class-cancel-modal">
        <Typography variant="body1" className="class-cancel-modal__warning">
          {t('.warning', intl, __filenamespace)}
        </Typography>
        <Typography variant="subtitle2">
          {t('.options', intl, __filenamespace)}
        </Typography>
        <RadioButton
          size="4x"
          selectedValue={creditOperation}
          onChange={(_, value) => ClassCancellationActions.handleChange(value)}
          orientation="vertical"
          options={[
            {
              label: (
                <Grid container direction="column">
                  {t('.refund_title', intl, __filenamespace)}
                  <Typography variant="body2" color="secondary">
                    {t('.refund_subtitle', intl, __filenamespace)}
                  </Typography>
                </Grid>
              ),
              value: creditOperations.REFUND_CREDIT,
            },
            {
              label: (
                <Grid container direction="column">
                  {t('.revoke_title', intl, __filenamespace)}
                  <Typography variant="body2" color="secondary">
                    {t('.revoke_subtitle', intl, __filenamespace)}
                  </Typography>
                </Grid>
              ),
              value: creditOperations.REVOKE_CREDIT,
            },
          ]}
        />
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" component="strong">
              {t('.note_title', intl, __filenamespace)}
            </Typography>
            <Typography variant="body2" color="secondary" component="span">
              &nbsp;{t('.note_subtitle', intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Confirmation>
  );
}

ClassCancelModal.propTypes = {
  classIdToCancel: PropTypes.number,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  classCancellationStore: PropTypes.object,
};

ClassCancelModal.defaultProps = {
  classIdToCancel: null,
  classCancellationStore: {
    loading: false,
    classIdToCancel: null,
    creditOperation: creditOperations.REFUND_CREDIT,
  },
  onConfirm: ClassCancellationActions.classCancel,
  onCancel: () => ClassCancellationActions.toggleCancelModal({}),
};

export default compose(
  injectIntl,
  altContainer({ stores: { classCancellationStore: ClassCancellationStore } })
)(ClassCancelModal);
