import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentModal, Button, Grid } from '@upperhand/playmaker';

import POSActions from 'point_of_sale/actions/POSActions.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { ClientDataStore } from 'dataStores';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import DetailsActions from '../actions.js';

function PurchaseModal({ clientId, intl, clientDataStore: { clients } }) {
  const client = clients.get(clientId);

  return (
    <ContentModal
      showActions={false}
      open={!!client}
      size="2x"
      title={t('.purchase_modal_title', intl, __filenamespace)}
      classes={{
        content: 'details-container__modal-content',
        root: 'details-container__modal-root',
      }}
      onAbort={DetailsActions.closePurchaseModal}
    >
      <Grid container spacing={2}>
        <Grid item>
          <FormattedMessage
            id={messageId('.purchase_modal_content', __filenamespace)}
          />
        </Grid>
        <Grid item container spacing={2} justify="center">
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                DetailsActions.closePurchaseModal();
                POSActions.clientSelected(client);
                POSActions.posClicked();
              }}
            >
              {t('.purchase_modal_button', intl, __filenamespace)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ContentModal>
  );
}

export default altContainer({ stores: { clientDataStore: ClientDataStore } })(
  PurchaseModal
);
