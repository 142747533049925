import { List, Map } from 'immutable';
import DateRange, { THIS_MONTH } from 'shared/records/DateRange';

import SalesDetailActions from 'reporting/sales_detail/actions/SalesDetailActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const totalsFor = data => {
  const item = data.first();
  if (!item) return Map({});
  const fields = Object.keys(item).filter(
    field => typeof item[field] === 'number'
  );
  const obj = {};
  fields.forEach(f => {
    obj[f] = data.map(i => i[f]).reduce((s, i) => s + i);
  });
  return Map(obj);
};

class SalesDetailStore extends UpperHandStore {
  constructor() {
    super();

    this.bindListeners({
      dateFilterUpdated: SalesDetailActions.DATE_FILTER_UPDATED,
      fetchData: SalesDetailActions.FETCH_DATA,
      fetchDataSuccess: SalesDetailActions.FETCH_DATA_SUCCESS,
      fetchDataError: SalesDetailActions.FETCH_DATA_ERROR,
      mounted: SalesDetailActions.MOUNTED,
      resetFilters: SalesDetailActions.RESET_FILTERS,
    });

    this.isLoading = false;
    this.filtersApplied = false;
    this.filters = Map();
    this.resetDateRangeFilter();

    this.paymentTypeSales = List();
    this.paymentTypeTotals = Map();

    this.retailCategorySales = List();
    this.retailCategoryTotals = Map();

    this.productTypeSales = List();
    this.productTypeTotals = Map();

    this.saleTypeSales = List();
    this.saleTypeTotals = Map();
  }

  resetDateRangeFilter() {
    this.filters = this.filters.merge({
      dateRange: new DateRange({ value: THIS_MONTH }),
    });
  }

  mounted() {
    this.resetDateRangeFilter();
    this.fetchData();
  }

  fetchData() {
    this.isLoading = true;
    const { startTime, endTime } = this.filters.get('dateRange');
    const payload = {
      start_time: startTime.toISOString(),
      end_time: endTime.toISOString(),
    };
    return uhApiClient.get({
      url: 'analytics/sales_details',
      data: payload,
      success: SalesDetailActions.fetchDataSuccess,
      error: SalesDetailActions.fetchDataError,
    });
  }

  fetchDataSuccess(data) {
    const {
      // eslint-disable-next-line camelcase
      records: { payment_type, product_type, sale_type, retail_category },
    } = data;
    this.paymentTypeSales = List(payment_type);
    this.productTypeSales = List(product_type);
    this.saleTypeSales = List(sale_type);
    this.retailCategorySales = List(retail_category);
    this.adjustTotals();
    this.isLoading = false;
  }

  fetchDataError(...args) {
    this.isLoading = false;
    this.notifyError('failed to fetch sales details', args);
  }

  dateFilterUpdated({ value, from, to }) {
    this.filters = this.filters.set(
      'dateRange',
      new DateRange({ value, startDate: from, endDate: to })
    );
    this.filtersApplied = true;
    this.fetchData();
  }

  resetFilters() {
    this.resetDateRangeFilter();
    this.filtersApplied = false;
    this.fetchData();
  }

  adjustTotals() {
    this.paymentTypeTotals = totalsFor(this.paymentTypeSales);
    this.productTypeTotals = totalsFor(this.productTypeSales);
    this.retailCategoryTotals = totalsFor(this.retailCategorySales);
    this.saleTypeTotals = totalsFor(this.saleTypeSales);
  }
}

export default alt.createStore(SalesDetailStore, 'SalesDetailStore');
