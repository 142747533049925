import { Iterable, Record } from 'immutable';
import moment from 'moment-timezone';
import Discount from 'shared/records/Discount.js';
import { formatDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const ONE_TIME = 'one_time';
const RECURRING = 'recurring';
const RECURRING_ONCE = 'recurring_once';

class Coupon extends Record({
  id: null,
  code: '',
  coupon_type: ONE_TIME,
  description: '',
  discount_type: '',
  value: 0.0,
  name: '',
  start_date: new Date(),
  end_date: new Date(),
  redemption_limit: null,
  redemption_limit_strategy: null,
  redemption_count: 0,
}) {
  constructor(obj = {}) {
    let newObj = obj;
    if (Iterable.isIterable(obj)) {
      newObj = obj.toJS();
    }

    if (newObj.start_date && typeof newObj.start_date === 'string') {
      newObj.start_date = moment(newObj.start_date);
    }

    if (newObj.end_date && typeof newObj.end_date === 'string') {
      newObj.end_date = moment(newObj.end_date);
    }

    super(merge({}, newObj));
  }

  static get ONE_TIME() {
    return ONE_TIME;
  }

  static get RECURRING() {
    return RECURRING;
  }

  applicable() {
    return this.discount.isValid();
  }

  dateText() {
    if (!this.start_date || !this.end_date) {
      return null;
    }
    return `${formatDate(this.start_date)} - ${formatDate(this.end_date)}`;
  }

  get discount() {
    return new Discount({ type: this.discount_type, value: this.value });
  }

  isOneTime() {
    return this.coupon_type === ONE_TIME;
  }

  isRecurring() {
    return this.coupon_type === RECURRING;
  }

  isRecurringOnce() {
    return this.coupon_type === RECURRING_ONCE;
  }

  isOneTime100Percent() {
    return (
      this?.isRecurringOnce() &&
      this.value === 100 &&
      this.discount_type === 'percent'
    );
  }

  toServer() {
    return this.toJS();
  }
}

export default Coupon;
