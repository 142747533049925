const FilterActions = alt.generateActions(
  'toggleFilterDrawer',
  'updateFilterDrawerOpen',
  'updateStatusFilters',
  'updateTypeFilters',
  'updateLocationFilters',
  'updateTitleFilter',
  'updateAttendeeFilters',
  'maxAgeUpdated',
  'minAgeUpdated',
  'ageToggled',
  'filtersCleared'
);

export default FilterActions;
