import InviteActions from 'user_management/shared/actions/InviteActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = id => {
  if (id) {
    return `customer_users/${id}/invite`;
  }
  throw new Exception('a customer_user ID must be provided');
};

class InviteStore extends UpperHandStore {
  constructor() {
    super();
    this.customerUserIdForDelete = null;

    this.bindListeners({
      handleSend: InviteActions.SEND,
      handleSendSuccess: InviteActions.SEND_SUCCESS,
      handleSendError: InviteActions.SEND_ERROR,

      handleDelete: InviteActions.DELETE,
      handleDeleteError: InviteActions.DELETE_ERROR,
    });
  }

  handleSend(customerUserId) {
    return uhApiClient.put({
      url: url(customerUserId),
      success: InviteActions.sendSuccess,
      error: InviteActions.sendError,
    });
  }

  handleSendSuccess() {
    MessageWindowActions.addMessage.defer('Reminder sent.');
  }

  handleDelete(customerUserId) {
    this.customerUserIdForDelete = customerUserId;
    return uhApiClient.delete({
      url: url(customerUserId),
      success: { action: InviteActions.deleteSuccess, args: [customerUserId] },
      error: InviteActions.deleteError,
    });
  }

  handleSendError() {
    this.notifyError('error while sending invite', arguments);
  }

  handleDeleteError() {
    this.customerUserIdForDelete = null;
    this.notifyError('error while delete invite', arguments);
  }
}

export default alt.createStore(InviteStore, 'InviteStore');
