import wait from 'shared/utils/Promise';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

export class OnboardAPIError extends Error {
  constructor(response) {
    super(response.error);
    // Object.setPrototypeOf workaround is here because the current Babel version doesn't transpile
    // subclasses of standard classes properly.
    // https://stackoverflow.com/questions/30402287/extended-errors-do-not-have-message-or-stack-trace
    Object.setPrototypeOf(this, OnboardAPIError.prototype);
    this.response = response;
  }
}

class OnboardAPI {
  /**
   * ===================================
   *  Public Methods
   * ===================================
   */

  async getStatus() {
    return this._request('get', 'paysafe_onboard_status').then(response => {
      if (response.in_progress) {
        return wait(2000).then(() => this.getStatus());
      }
      if (response.error) {
        throw new OnboardAPIError(response);
      } else {
        return {
          result: response.result,
          current_step: response.current_step,
        };
      }
    });
  }

  async submitBusiness(data) {
    return this._requestJob('post', 'paysafe_onboard_business', data);
  }

  async submitBankAccount(data) {
    return this._requestJob('post', 'paysafe_onboard_bank_account', data);
  }

  async submitIdentity(data) {
    return this._requestJob('post', 'paysafe_onboard_identity', data);
  }

  async beginIdentityRequest(data) {
    return this._requestJob(
      'post',
      'paysafe_onboard_begin_identity_verification',
      data
    );
  }

  async completeIdentityRequest(data) {
    return this._requestJob(
      'post',
      'paysafe_onboard_complete_identity_verification',
      data
    );
  }

  async getTermsOfService() {
    return this._requestJob('get', 'paysafe_onboard_terms_of_service');
  }

  async submit(data) {
    return this._requestJob('post', 'paysafe_onboard_submit', data);
  }

  /**
   * ===================================
   *  Private Methods
   * ===================================
   */

  _url(relativePath) {
    const customerID = currentCustomer().id;
    return `customers/${customerID}/${relativePath}`;
  }

  _backendRequest(method, url, data = null) {
    return new Promise((resolve, reject) => {
      uhApiClient[method]({
        url,
        data,
        success: resolve,
        error: reject,
      });
    });
  }

  _request(method, relativePath, data = null) {
    return this._backendRequest(method, this._url(relativePath), data);
  }

  async _requestJob(method, relativePath, data = null) {
    await this._request(method, relativePath, data);
    const response = await this.getStatus();
    return response.result;
  }
}

export default new OnboardAPI();
