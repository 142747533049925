import * as React from 'react';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { DateTimePicker } from '@upperhand/playmaker';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import './styles.scss';

const moment = extendMoment(Moment);

class DateSelector extends React.Component {
  handleDateChange = value => {
    const { quickScheduleStore } = this.props;
    const { dateRange } = quickScheduleStore;

    const start = moment(value)
      .tz(customerTZ(), true)
      .hours(dateRange.start.hours())
      .minutes(dateRange.start.minutes());

    const end = moment(value)
      .tz(customerTZ(), true)
      .hours(dateRange.end.hours())
      .minutes(dateRange.end.minutes());

    CalendarActions.setCalendarDate(moment(value));
    QuickScheduleActions.updateDateRange(moment.range(start, end));
  };

  shouldDisableDate = date => {
    const { quickScheduleStore } = this.props;
    const { selectedEvent } = quickScheduleStore;
    const availabilitySchedule = selectedEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
    ]);

    return (
      moment(date).isBefore(moment().startOf('day')) ||
      !availabilitySchedule.includeDate(date, true)
    );
  };

  render() {
    const { quickScheduleStore } = this.props;
    const { dateRange } = quickScheduleStore;

    return (
      <div className="qs-date-picker">
        <div className="qs-date-picker__wrapper">
          <DateTimePicker
            classes={{
              root: 'picker',
              startAdornment: 'picker__icon',
              input: 'picker__input',
            }}
            fullWidth
            showClearIcon={false}
            views={['date']}
            icon="calendar"
            type="date"
            format="ddd MMM D"
            value={dateRange.start.format('YYYY-MM-DD')}
            onChange={this.handleDateChange}
            shouldDisableDate={this.shouldDisableDate}
          />
        </div>
      </div>
    );
  }
}

export default DateSelector;
