import React from 'react';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';

import { t } from 'shared/utils/LocaleUtils';
import uhTheme from '_uh_theme.jsx';

import './styles.scss';

function AutomationCard({ atd, index, addAutomation, intl, isTeamEvent }) {
  const atdDescription = isTeamEvent
    ? atd.description.replace('event', 'team')
    : atd.description;
  return (
    <Paper
      key={`atd-${atd.id || atd.name}-${index}`}
      className="automation-list-drawer__automation-card"
    >
      <Box
        className="automation-list-drawer__header"
        style={{ position: 'relative' }}
      >
        {atd.name}
      </Box>
      <Divider className="automation-list-drawer__divider" />
      <Box className="automation-list-drawer__card-content">
        <p>{atdDescription}</p>
        <Button
          disableRipple
          disableFocusRipple
          className="automation-list-drawer__add-automation"
          onClick={() => addAutomation(atd)}
        >
          {t('.add', intl, __filenamespace)}
        </Button>
      </Box>
    </Paper>
  );
}

function AutomationListDrawer(props) {
  const {
    onAutomationSelect,
    open,
    onRequestChange,
    automationTemplateDescriptions,
    isTeamEvent,
    intl,
  } = props;

  const addAutomation = atd => {
    onRequestChange(false, 'adding automation');
    if (onAutomationSelect) {
      onAutomationSelect(atd);
    }
  };

  return (
    <article>
      <Drawer
        disableEnforceFocus
        anchor="right"
        open={open}
        onClose={onRequestChange}
        PaperProps={{
          sx: {
            width: '350px',
            backgroundColor: uhTheme.palette.charcoalBlack,
          },
        }}
        className="automation-list-drawer"
      >
        <Toolbar className="automation-list-drawer__toolbar">
          <Stack style={{ flex: '1 100%' }} direction="row">
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box className="automation-list-drawer__drawer-title">
                {t('.automation', intl, __filenamespace)}
              </Box>
            </Box>
            <IconButton
              onClick={() => onRequestChange(false, 'userInitiatedClose')}
              style={{ marginLeft: 'auto' }}
            >
              <ClearIcon className="automation-list-drawer__clear-icon-style" />
            </IconButton>
          </Stack>
        </Toolbar>
        <Box className="automation-list-drawer__card-space-style">
          {automationTemplateDescriptions.map((e, index) => (
            <AutomationCard
              atd={e}
              index={index}
              addAutomation={addAutomation}
              intl={intl}
              isTeamEvent={isTeamEvent}
            />
          ))}
        </Box>
      </Drawer>
    </article>
  );
}

export default AutomationListDrawer;
