import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const styles = {
  textField: disableKeyboardEditing => ({
    marginTop: '10px',
    input: {
      cursor: disableKeyboardEditing ? 'pointer' : 'text',
    },
  }),
};

function TimeField({ value, disableKeyboardEditing, onAccept }) {
  const [time, setTime] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOnFocus = e => {
    if (disableKeyboardEditing) {
      setOpen(true);
      e.target.blur();
    }
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <TimePicker
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      renderInput={params => (
        <TextField
          fullWidth
          sx={styles.textField(disableKeyboardEditing)}
          onFocus={handleOnFocus}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
      )}
      value={time || value}
      DialogProps={{ disableEnforceFocus: true }}
      onChange={timeValue => setTime(timeValue)}
      onAccept={timeValue => {
        onAccept(timeValue);
        setTime(null);
      }}
    />
  );
}

TimeField.propTypes = {
  value: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  disableKeyboardEditing: PropTypes.bool,
};

TimeField.defaultProps = {
  disableKeyboardEditing: false,
};

export default TimeField;
