export default alt.generateActions(
  'export',
  'exportError',
  'exportSuccess',
  'getReport',
  'getReportError',
  'getReportSuccess',
  'listRetailVendorsSuccess',
  'mounted',
  'pageSelected',
  'reset',
  'updateExportColumns',
  'updateFilter'
);
