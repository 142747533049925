import * as React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';

import LandingTemplate from 'shared/components/login/_LandingTemplate.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import ResendAccountVerificationActions from 'shared/actions/ResendAccountVerificationActions.jsx';

const styles = {
  container: modalOpen => ({
    padding: modalOpen ? 0 : '15px',
  }),
  header: {
    fontSize: 26,
    color: uhColors.darkGrey,
    paddingBottom: 30,
  },
  description: width => ({
    paddingBottom: 30,
    width: '100%',
    maxWidth: width,
    minWidth: 300,
    lineHeight: 1.3,
    color: uhColors.grey,
  }),
  emailText: width => ({
    paddingBottom: 30,
    width: '100%',
    maxWidth: width,
    minWidth: 300,
    fontSize: 20,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  backBtn: {
    color: grey[400],
    alignSelf: 'flex-start',
    fontWeight: 600,
    padding: 0,
    backgroundColor: 'transparent',
  },
};

export const Content = injectIntl(({ width, email, intl, modalOpen }) => (
  <Stack sx={styles.container(modalOpen)}>
    <div style={styles.header}>Account Verification</div>
    <div style={styles.description(width)}>
      We recognize this email but we need to verify your identity. We&apos;ve
      emailed you a link to finish setting up your account to this address:
    </div>
    <div style={styles.emailText(width)}>{email}</div>
    {!modalOpen && (
      <Link to="/accounts/login">
        <Button style={styles.backBtn}>
          {t('.back_to_login', intl, __filenamespace)}{' '}
        </Button>
      </Link>
    )}
    {modalOpen && (
      <Button
        color="dark"
        variant="contained"
        sx={{ width: '145px', height: '45px' }}
        onClick={ResendAccountVerificationActions.backToLogin}
      >
        {t('.back_to_login', intl, __filenamespace)}{' '}
      </Button>
    )}
  </Stack>
));

function VerificationSent({ to }) {
  return (
    <LandingTemplate
      largeScreen={<Content width={400} email={to} />}
      smallScreen={<Content width={300} email={to} />}
    />
  );
}
export default VerificationSent;
