import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AltContainer from 'alt-container';

import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import ResourceDeletionActions from 'resources/actions/ResourceDeletionActions.js';
import ResourceDeletionStore from 'resources/stores/ResourceDeletionStore.js';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

function DeletionConfirmationContent({ resource, confirmationOpen, intl }) {
  return (
    <ConfirmationDialog
      awaitingConfirmation={confirmationOpen}
      onCancel={ResourceDeletionActions.deleteAborted}
      onConfirm={ResourceDeletionActions.deleteConfirmed}
      title={t('.remove_resource', intl, __filenamespace)}
    >
      <div style={{ marginBottom: 20 }}>
        <FormattedMessage id={messageId('.are_you_sure', __filenamespace)} />
      </div>

      <FlexBoxCenter>
        <ResourceIcon style={{ marginRight: 15 }} />
        <div>{resource.name}</div>
      </FlexBoxCenter>
    </ConfirmationDialog>
  );
}

function DeletionConfirmation({ intl }) {
  return (
    <AltContainer store={ResourceDeletionStore}>
      <DeletionConfirmationContent intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(DeletionConfirmation);
