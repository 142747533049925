import FieldErrors from 'shared/records/FieldErrors.jsx';
import PasswordValidator from 'shared/utils/PasswordValidator.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { redirectTo } from 'shared/utils/RouteUtils';
import ResetPasswordActions from './Actions';

class ResetPasswordStore extends UpperHandStore {
  constructor() {
    super();
    this.errors = new FieldErrors();
    this.requestStatus = null;
    this.resetToken = null;
    this.password = null;
    this.password_confirmation = null;

    this.validPasswords = () => {
      const validator = new PasswordValidator({
        password: this.password,
        password_confirmation: this.password_confirmation,
      });
      return validator.passwordsBothValid();
    };

    this.bindListeners({
      handleUpdateStore: ResetPasswordActions.UPDATE_STORE,
      changePassword: ResetPasswordActions.CHANGE_PASSWORD,
      changePasswordSuccess: ResetPasswordActions.CHANGE_PASSWORD_SUCCESS,
      changePasswordError: ResetPasswordActions.CHANGE_PASSWORD_ERROR,
    });
  }

  handleUpdateStore(data) {
    super.handleUpdateStore(data);
  }

  changePassword() {
    if (!this.validPasswords()) return;

    this.requestStatus = 'sending';
    uhApiClient.post({
      url: 'accounts/change_password',
      data: JSON.stringify({
        password: this.password,
        reset_token: this.resetToken,
      }),
      success: ResetPasswordActions.changePasswordSuccess,
      error: ResetPasswordActions.changePasswordError,
    });
  }

  /* eslint-disable class-methods-use-this */
  // alt actions required to be class methods
  changePasswordSuccess(data) {
    if (data.success) {
      // This fixing uncaught Error: Invariant Violation: Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch
      setTimeout(() => {
        redirectTo({ path: '/' });
      }, 0);
    }
  }
  /* eslint-enable class-methods-use-this */

  changePasswordError(...args) {
    this.requestStatus = 'failed';
    this.notifyError('error while changing password', args);
  }
}

export default alt.createStore(ResetPasswordStore, 'ResetPasswordStore');
