/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { TextField, Grid } from '@upperhand/playmaker';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import Form from 'shared/components/Form.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const PercentField = injectIntl(({ rate, onChange, intl }) => (
  <TextField
    icon="%"
    iconPlacement="right"
    errorText={rate.errors.getErrors('rate', intl)}
    label={t('.rate', intl, __filenamespace)}
    fullWidth
    placeholder={t('.percent_rate', intl, __filenamespace)}
    name="rate"
    onChange={onChange}
    type="number"
    numberInputProps={{ min: 0, max: 100 }}
    value={rate.rate || ''}
  />
));

const NameField = injectIntl(({ rate, onChange, intl }) => (
  <TextField
    errorText={rate.errors.getErrors('name', intl)}
    label={t('.name', intl, __filenamespace)}
    fullWidth
    placeholder={t('.name', intl, __filenamespace)}
    multiline
    name="name"
    onChange={onChange}
    value={rate.name}
  />
));

export default class RateForm extends React.PureComponent {
  handleTextFieldUpdate = (e, value) => {
    const { actions } = this.props;

    actions.updated({ [e.target.name]: value });
  };

  handleSubmit = e => {
    // Prevent the outer AccountingCodeForm from submitting as well.
    e.stopPropagation();
    const { actions } = this.props;

    actions.save();
  };

  handleCancel = () => {
    const { actions } = this.props;

    actions.cancel();
  };

  render() {
    const { intl, style, isFeeRate } = this.props;
    const getRate = () => {
      const { feeRate, taxRate } = this.props;
      return isFeeRate ? feeRate : taxRate;
    };
    const rate = getRate();

    return (
      <Paper style={{ padding: 16, position: 'relative', ...style }}>
        <Form onSubmit={this.handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <NameField rate={rate} onChange={this.handleTextFieldUpdate} />
            </Grid>
            <Grid item>
              <PercentField rate={rate} onChange={this.handleTextFieldUpdate} />
            </Grid>
            <Grid item container direction="row">
              <Button
                variant="contained"
                style={{ marginRight: 15 }}
                onClick={this.handleSubmit}
              >
                {t('actions.save', intl, __filenamespace)}
              </Button>
              <Button variant="outlined" onClick={this.handleCancel}>
                {t('actions.cancel', intl, __filenamespace)}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}
