// because our parser uses snakeCase, we really should change parser to auto convert snake to camel
/* eslint-disable camelcase */
import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { PaymentPlanActions } from 'sources';

class PaymentPlanDataStore extends UpperHandStore {
  constructor() {
    super();

    this.records = Map();

    this.bindListeners({
      fetchSuccess: [PaymentPlanActions.fetchSuccess],
      fetchError: [PaymentPlanActions.fetchError],
      listSuccess: [PaymentPlanActions.listSuccess],
      listError: [PaymentPlanActions.listError],
    });
  }

  fetchSuccess(payment_plan) {
    this.records = this.records.set(payment_plan.id, payment_plan);
  }

  fetchError(...args) {
    this.notifyError('error paymentPlan fetching', args);
  }

  listSuccess({ payment_plans }) {
    const listed = payment_plans.groupBy(c => c.id).map(c => c.first());

    this.records = this.records.merge(listed);
  }

  listError(...args) {
    this.notifyError('error listing paymentPlans', args);
  }
}

export default alt.createStore(PaymentPlanDataStore, 'PaymentPlanDataStore');
