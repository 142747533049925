import OWNER_TYPE from 'calendar/types/OwnerType.jsx';

export const formattedHourString = (
  date,
  {
    format = '',
    hourFormat = 'h',
    minuteFormat = 'mm',
    meridiemFormat = 'a',
    truncateMeridiem = true,
    trailingZeros = false,
  } = {}
) => {
  const minutes = date.minutes();
  let minutesFormatted = date.format(minuteFormat);

  if (!trailingZeros && minutes === 0) {
    minutesFormatted = '';
  }

  let fullFormatted = format;

  if (fullFormatted === '') {
    let minutesDelimiter = ':';

    if (!trailingZeros && minutes === 0) {
      minutesDelimiter = '';
    }

    fullFormatted = `${hourFormat}${minutesDelimiter}${minutesFormatted}${meridiemFormat}`;
  }

  let formattedDate = date.format(fullFormatted);

  if (truncateMeridiem) {
    formattedDate = formattedDate.slice(0, -1);
  }

  return formattedDate;
};

export const eventTimesForCalendar = (eventTimes, calendar) =>
  eventTimes.filter(eventTime => {
    switch (calendar.ownerType) {
      case OWNER_TYPE.STAFF: {
        return eventTime.staff_ids.find(id => id === calendar.id);
      }
      case OWNER_TYPE.RESOURCE: {
        return eventTime.resource_ids.find(id => id === calendar.id);
      }
      default:
        return false;
    }
  });

export const filteredEventTimes = (
  eventTimes,
  showPaid,
  showUnpaid,
  showAllStaff,
  staffIds
) => {
  let eventTimesTemp = eventTimes;
  if (showPaid) {
    eventTimesTemp = eventTimes.filter(
      eventTime =>
        eventTime.client_status.get('succeeded').size > 0 &&
        eventTime.client_status.get('pending').size === 0
    );
  }
  if (showUnpaid) {
    eventTimesTemp = eventTimes.filter(
      eventTime => eventTime.client_status.get('pending').size > 0
    );
  }
  if (showPaid && showUnpaid) {
    eventTimesTemp = eventTimes.filter(
      eventTime =>
        eventTime.client_status.get('pending').size > 0 ||
        eventTime.client_status.get('succeeded').size > 0
    );
  }
  if (showAllStaff && staffIds && staffIds.length > 0) {
    eventTimesTemp = eventTimes.filter(eventTime =>
      eventTime.staff_ids.find(id => staffIds.includes(id))
    );
  }
  return eventTimesTemp;
};

export const needlesInHaystack = (needles, haystack) => {
  const emptyRegex = /^\s*$/;
  const normalizedHaystack = haystack.toLowerCase();
  let found = false;

  needles.forEach(needle => {
    const normalizedNeedle = needle.toLowerCase();
    if (
      !emptyRegex.test(normalizedNeedle) &&
      normalizedHaystack.indexOf(normalizedNeedle) >= 0
    ) {
      found = true;
    }
  });

  return found;
};

export const fuzzySearchString = (searchTerm, string) => {
  const emptyRegex = /^\s*$/;

  if (emptyRegex.test(searchTerm)) return true;

  const needles = searchTerm.split(/[ ,]+/);
  const haystack = string.toLowerCase();

  return needlesInHaystack(needles, haystack);
};

export const fuzzySearchCalendarOwner = (searchTerm, calendar, owner) => {
  const emptyRegex = /^\s*$/;

  if (emptyRegex.test(searchTerm)) return true;

  const needles = searchTerm.split(/[ ,]+/);

  switch (calendar.ownerType) {
    case OWNER_TYPE.STAFF: {
      const haystack = owner.name();
      return needlesInHaystack(needles, haystack);
    }
    case OWNER_TYPE.RESOURCE: {
      const haystack = owner.name;
      return needlesInHaystack(needles, haystack);
    }
    default:
      return true;
  }
};

export const getIndicatorLineHeight = currentTime => {
  const totalMinutes = parseInt(currentTime.split(':')[1], 10);
  const lineHeight = (totalMinutes / 60) * 192;
  return lineHeight;
};
