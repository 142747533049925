import React from 'react';
import { injectIntl } from 'react-intl';

import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';

import { t } from 'shared/utils/LocaleUtils.js';

import PaymentActions from '../../Actions';
import { PaymentMethod } from '../../types';

const styles = {
  buttonLabel: {
    fontSize: 16,
  },
  checkoutButton: {
    width: '100%',
    margin: '1rem 0 1rem 0',
    height: 50,
  },
};

const setLabelMessage = paymentMethod => {
  switch (paymentMethod) {
    case PaymentMethod.PAY_LATER:
      return '.continue';

    default:
      return '.purchase';
  }
};

function PaymentButton({ currentPaymentMethod, disabled, intl }) {
  return (
    <Button
      disabled={disabled}
      fullWidth
      startIcon={<LockIcon />}
      onClick={PaymentActions.purchase}
      variant="contained"
      color="secondary"
      style={styles.checkoutButton}
    >
      {t(setLabelMessage(currentPaymentMethod), intl, __filenamespace)}
    </Button>
  );
}

export default injectIntl(PaymentButton);
