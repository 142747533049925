import * as React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function FilterHeader({ text, activeFilters, actions, intl }) {
  return (
    <FlexBoxJustify style={{ alignItems: 'center', marginBottom: 10 }}>
      <div style={{ fontSize: 20 }}>{text}</div>
      {activeFilters ? (
        <Button
          sx={{ color: uhColors.blue }}
          onClick={() => actions.filtersCleared()}
        >
          {t('.clear_filters', intl, __filenamespace)}
        </Button>
      ) : (
        <div style={{ height: 36 }}>&nbsp;</div>
      )}
    </FlexBoxJustify>
  );
}

export default injectIntl(FilterHeader);
