export default alt.generateActions(
  'addAttendeeClicked',
  'addToCartClick',
  'allAvailableSingleSessionsAdded',
  'allAvailableSingleSessionsRemoved',
  'automatedPackageSelected',
  'classScheduleBookSuccess',
  'classScheduleClicked',
  'completeButtonClicked',
  'defaultPackageSelected',
  'listPurchasedRegistrationsError',
  'listPurchasedRegistrationsSuccess',
  'paymentPlanSelected',
  'profileCreated',
  'profileCreatedOpenBooking',
  'profileRemoved',
  'profileSelected',
  'registrationAthleteChanged',
  'registrationBooked',
  'registrationCancelled',
  'registrationDateChanged',
  'registrationStaffChanged',
  'registrationTimeChanged',
  'scheduleLaterClicked',
  'scheduleNowClicked',
  'scheduleRemainingClicked',
  'singleSessionAdded',
  'singleSessionAttendeeAdded',
  'singleSessionAttendeeRemoved',
  'singleSessionAttendeeRemoved',
  'singleSessionProfileCreated',
  'singleSessionRemoved',
  'spsTimeChanged',
  'updateCartClick',
  'updatePurchaseScheduling',
  'viewInCartClicked',
  'fetchAvailabilities'
);
