import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import MembershipEmbedActions from './MembershipEmbedActions';

class MembershipEmbedStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      closeModal: MembershipEmbedActions.closeModal,
      openModal: MembershipEmbedActions.openModal,
      changeEmbedCard: MembershipEmbedActions.changeEmbedCard,
      recycleEmbedCard: MembershipEmbedActions.recycleEmbedCard,
      recycleEmbedCardError: MembershipEmbedActions.recycleEmbedCardError,
      recycleEmbedCardSuccess: MembershipEmbedActions.recycleEmbedCardSuccess,
      redeemSuccess: MembershipEmbedActions.redeemSuccess,
      redeemError: MembershipEmbedActions.redeemError,
      topUp: MembershipEmbedActions.topUp,
      topUpSuccess: MembershipEmbedActions.topUpSuccess,
      topUpError: MembershipEmbedActions.topUpError,
      validate: MembershipEmbedActions.validate,
    });
  }

  reset() {
    this.redeem = false;
    this.isLoading = false;
    this.editModalOpen = false;
    this.editModalMode = 'edit';
    this.subscriptionId = null;
    this.embedCardId = null;
    this.clientId = null;
    this.embedErrors = new FieldErrors();
  }

  closeModal() {
    this.editModalOpen = false;
  }

  openModal({ subscription, redeem = false, clientId = null }) {
    this.reset();

    this.editModalOpen = true;
    this.redeem = redeem;
    this.clientId = clientId;

    if (!redeem) {
      const subscriptionId = subscription.get('id');
      const embedCardId = subscription.get('embed_card_id');

      this.clientId = subscription.get('client_id');
      this.subscriptionId = subscriptionId;
      this.embedCardId = embedCardId;
      this.editModalMode = embedCardId ? 'edit' : 'activate';
    }
  }

  changeEmbedCard(embedCardId) {
    this.embedErrors = this.embedErrors.clear();
    // eslint-disable-next-line no-useless-escape
    const matchEmbedCard = embedCardId?.match(/\;(.*)\?/)?.pop();

    this.embedCardId = matchEmbedCard || embedCardId;
  }

  recycleEmbedCard({
    cancelCard = false,
    subscriptionId = this.subscriptionId,
    embedCardId = null,
  }) {
    if (!this.embedErrors.isEmpty()) return;

    if (this.redeem) {
      this.isLoading = true;

      uhApiClient.post({
        url: `/clients/${this.clientId}/redeem_embed_card`,
        data: JSON.stringify({ barcode: this.embedCardId }),
        success: MembershipEmbedActions.redeemSuccess,
        error: MembershipEmbedActions.redeemError,
      });
      return;
    }

    const cardId = !cancelCard ? this.embedCardId : null;
    const params = {
      embed_card_id: !cancelCard ? cardId : null,
      fields: ['membership', 'has_embed_product'],
    };

    if (embedCardId) {
      params.embed_card_id = embedCardId;
    }

    this.isLoading = true;
    uhApiClient.post({
      url: `membership_subscriptions/${subscriptionId}/change_embed_card`,
      data: JSON.stringify(params),
      success: MembershipEmbedActions.recycleEmbedCardSuccess,
      error: MembershipEmbedActions.recycleEmbedCardError,
    });
  }

  redeemSuccess() {
    this.isLoading = false;
    this.closeModal();
  }

  redeemError(...args) {
    this.isLoading = false;
    this.notifyError('Error while redeem membership card', args);
  }

  recycleEmbedCardSuccess() {
    this.isLoading = false;
    this.closeModal();
  }

  recycleEmbedCardError(...args) {
    this.isLoading = false;
    this.notifyError('Error updating embed card', args);
  }

  // eslint-disable-next-line class-methods-use-this
  topUp(subscriptionId) {
    uhApiClient.post({
      url: `membership_subscriptions/${subscriptionId}/top_up`,
      success: MembershipEmbedActions.topUpSuccess,
      error: MembershipEmbedActions.topUpError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  topUpSuccess() {
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.top_up_success',
        filename: __filenamespace,
      })
    );
  }

  topUpError(...args) {
    this.notifyError(
      new TranslatableMessage({
        id: '.top_up_error',
        filename: __filenamespace,
      }),
      args
    );
  }

  validate() {
    this.embedErrors = this.embedErrors.clear();

    if (this.embedCardId && this.embedCardId.length !== 19) {
      this.embedErrors = this.embedErrors.add(
        'embedCardError',
        'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.input_error'
      );
    }
  }
}

export default alt.createStore(MembershipEmbedStore, 'MembershipEmbedStore');
