import { List, Set } from 'immutable';
import CreditPassEditingActions from 'credit_passes/actions/CreditPassEditingActions.js';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';
import CreditPassSpecificEventsListActions from 'credit_passes/actions/CreditPassSpecificEventsListActions.js';
import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { EventSource } from 'sources';

const EVENTS_PER_PAGE = 50;

class CreditPassSpecificEventsListStore extends UpperHandStore {
  constructor() {
    super();
    this.events = List();
    this.isLoading = false;
    this.bindListeners({
      listCreditPassesEvents: CreditPassListingActions.LIST_RECEIVED,
      listCreditPassEvents: CreditPassEditingActions.FETCH_SUCCESS,
      listSuccess: CreditPassSpecificEventsListActions.LIST_SUCCESS,
      listError: CreditPassSpecificEventsListActions.LIST_ERROR,

      addSpecificEvent: CreditPassSpecificEventsListActions.ADD_SPECIFIC_EVENT,
      removeSpecificEvent:
        CreditPassSpecificEventsListActions.REMOVE_SPECIFIC_EVENT,
    });
  }

  listCreditPassesEvents({ credit_passes: creditPasses }) {
    const eventIds = creditPasses.reduce(
      (accumulator, creditPass) =>
        accumulator.union(
          creditPass.credit_pass_credits.reduce(
            (ids, credit) => ids.union(credit.event_ids),
            Set()
          )
        ),
      Set()
    );
    if (eventIds.isEmpty()) return;
    this.isLoading = true;
    EventSource.list({
      params: {
        fields: ['event_types'],
        per_page: EVENTS_PER_PAGE,
        ids: eventIds.toJS(),
      },
      success: CreditPassSpecificEventsListActions.listSuccess,
      error: CreditPassSpecificEventsListActions.listErrors,
    });
  }

  listCreditPassEvents(creditPass) {
    const eventIds = creditPass.credit_pass_credits.reduce(
      (ids, credit) => ids.union(credit.event_ids),
      Set()
    );
    if (eventIds.isEmpty()) return;
    this.isLoading = true;
    EventSource.list({
      params: {
        fields: ['event_types'],
        per_page: EVENTS_PER_PAGE,
        ids: eventIds.toJS(),
      },
      success: CreditPassSpecificEventsListActions.listSuccess,
      error: CreditPassSpecificEventsListActions.listErrors,
    });
  }

  listSuccess({ events }) {
    this.events = events;
    this.isLoading = false;
  }

  listError() {
    this.isLoading = false;
    this.notifyError('error listing events', arguments);
  }

  addSpecificEvent(id) {
    const index = this.events.findIndex(e => e.id === id);
    if (index === -1) {
      this.waitFor(EventListStore);
      const { events } = EventListStore.getState();
      const newEventToAdd = events.find(e => e.id === id);
      this.events = this.events.push(newEventToAdd);
    }
  }

  removeSpecificEvent(id) {
    this.events = this.events.filter(se => se.id !== id);
  }
}

export default alt.createStore(
  CreditPassSpecificEventsListStore,
  'CreditPassSpecificEventsListStore'
);
