/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TextField, Spinner, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import { isSimilarColor } from 'shared/utils/StyleUtils';

import './styles.scss';

function ProductSelector(props) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const productSelectorRef = useRef(null);

  const {
    searchValue = '',
    placeholder = t('.purchased_product_placeholder', intl, __filenamespace),
    noFoundLabel = t('.no_products', intl, __filenamespace),
    products = [],
    productsLoading = false,
    onProductSearch = () => null,
    onProductSelect = () => null,
  } = props;

  useEffect(() => {
    const handleWindowClick = e => {
      if (!productSelectorRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  return (
    <div className="product-selector" ref={productSelectorRef}>
      <TextField
        value={searchValue}
        icon="search"
        iconPlacement="left"
        placeholder={placeholder}
        onChange={onProductSearch}
        onFocus={() => setOpen(true)}
      />
      {open && (
        <div className="product-selector__list">
          {productsLoading && (
            <div className="product-selector__list-spinner">
              <Spinner />
            </div>
          )}
          {!productsLoading &&
            products?.map(product => {
              const { color } = product;
              return (
                <div
                  key={product.id}
                  role="presentation"
                  className="product-selector__list-item"
                  style={{
                    borderLeft: `4px solid ${
                      color && !isSimilarColor(color, '#ffffff', 50)
                        ? color
                        : 'var(--color-primary)'
                    }`,
                  }}
                  onClick={() => {
                    onProductSelect(product);
                    setOpen(false);
                  }}
                >
                  <p>{product.name || product.title}</p>
                </div>
              );
            })}
          {!productsLoading && products?.length === 0 && (
            <Typography className="product-selector__list-empty">
              {noFoundLabel}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

ProductSelector.propTypes = {
  searchValue: PropTypes.string,
  placeholder: PropTypes.string,
  products: PropTypes.array,
  productsLoading: PropTypes.bool,
  onProductSearch: PropTypes.func,
  onProductSelect: PropTypes.func,
};

export default ProductSelector;
