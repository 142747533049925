import * as React from 'react';
import { injectIntl } from 'react-intl';
import ContactsReportStore from 'reporting/index/stores/_ContactsReportStore.jsx';
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Popup, useMap } from 'react-leaflet';
import 'leaflet.markercluster';
import CustomMapMarker, {
  buildLeafletIcon,
} from 'reporting/index/components/_CustomMapMarker.jsx';
import AltContainer from 'alt-container';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

// continental US
const DEFAULT_BOUNDS = [
  [49.3457868, -124.7844079],
  [24.7433195, -66.9513812],
];

function getPosition(record) {
  return [record.get('latitude'), record.get('longitude')];
}

function ClusterGroup({ data }) {
  const map = useMap();
  const markers = Leaflet.markerClusterGroup({
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: true,
    zoomToBoundsOnClick: true,
  });

  data.map(c =>
    markers.addLayer(
      Leaflet.marker(Leaflet.latLng(c.get('latitude'), c.get('longitude')), {
        icon: buildLeafletIcon({ icon: 'bullet', color: '#2b80cc' }),
      })
    )
  );
  map.addLayer(markers);

  return null;
}

class ContactsMap extends React.Component {
  shouldComponentUpdate({ contactsReportStore: nextStore }) {
    const { contactsReportStore: currentStore } = this.props;
    const statusChanged =
      nextStore.isContactsMapLoading !== currentStore.isContactsMapLoading;
    const contactsMapChanged =
      nextStore.contactsMapResult !== currentStore.contactsMapResult;
    const locationsMapChanged =
      nextStore.locationsMapResult !== currentStore.locationsMapResult;
    return statusChanged || contactsMapChanged || locationsMapChanged;
  }

  renderMap() {
    const {
      contactsReportStore: { contactsMapResult, locationsMapResult },
    } = this.props;
    const positions = contactsMapResult.concat(locationsMapResult);
    const boundsArray = positions.size
      ? positions.map(getPosition).toArray()
      : DEFAULT_BOUNDS;
    const bounds = Leaflet.latLngBounds(boundsArray);

    const locationMarkers = locationsMapResult.map(l => (
      <CustomMapMarker
        key={l.get('id')}
        position={getPosition(l)}
        color="#fa8900"
        icon="star"
      >
        <Popup>
          <p>{l.get('name')}</p>
        </Popup>
      </CustomMapMarker>
    ));

    return (
      <MapContainer
        maxZoom={18}
        zoom={100}
        bounds={bounds}
        scrollWheelZoom={false}
        style={{ width: '100%', height: '500px' }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locationMarkers.size ? locationMarkers : null}
        <ClusterGroup data={contactsMapResult} />
      </MapContainer>
    );
  }

  render() {
    const {
      contactsReportStore: { isContactsMapLoading },
      intl,
    } = this.props;
    return (
      <Widget
        title={t('.title', intl, __filenamespace)}
        bodyStyle={{ padding: '0px' }}
        isLoading={isContactsMapLoading}
      >
        {this.renderMap()}
      </Widget>
    );
  }
}

function ContactsMapWrapper({ intl }) {
  return (
    <AltContainer stores={{ contactsReportStore: ContactsReportStore }}>
      <ContactsMap intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(ContactsMapWrapper);
