export default alt.generateActions(
  'fetch',
  'fetchSuccess',
  'append',
  'appendSuccess',
  'invite',
  'inviteSuccess',
  'delete',
  'deleteSuccess',
  'error'
);
