import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';

import { FlexBox } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import camelCase from 'camelcase';

const styles = {
  ItemDetails: {
    margin: '5px 0',
    fontSize: 16,
    color: 'var(--color-old-gray)',
  },
};

function ItemDetails({ balance }) {
  if (!balance || !balance.product) {
    return null;
  }

  const product = camelCase(balance.product);
  const formattedDate = balance.effectiveAt
    ? moment(balance.effectiveAt).format('MMMM DD, YYYY @ h:mm a')
    : '-';

  return (
    <div style={styles.EventBox}>
      <FlexBox>
        <div style={{ flex: '0 0 91%', fontSize: 14 }}>
          <div
            style={{
              fontWeight: 600,
              marginBottom: 5,
              textTransform: 'capitalize',
            }}
          >
            {product}
          </div>
          <div>
            <FormattedMessage
              id={messageId('.due_date', __filenamespace)}
              values={{ formattedDate }}
            />
          </div>
        </div>
      </FlexBox>
    </div>
  );
}

export default ItemDetails;
