import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { ContentModal } from '@upperhand/playmaker';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { isUrl } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils';

class ModalCrop extends PureComponent {
  state = {
    crop: null,
    imageDOM: null,
  };

  get content() {
    const { image, filename, intl, onUpload, circularCrop, cropAspect } =
      this.props;
    const { crop } = this.state;

    return (
      <div className="modal-crop__content">
        <div className="modal-crop__breadcrumb">
          <button
            type="button"
            className="modal-crop__breadcrumb-item"
            onClick={onUpload}
          >
            {t('.upload_button', intl, __filenamespace)}
          </button>
          <div className="modal-crop__breadcrumb-item">
            <ChevronRightIcon />
          </div>
          <div className="modal-crop__breadcrumb-item modal-crop__breadcrumb-filename">
            {filename}
          </div>
        </div>
        <div className="modal-crop__editor">
          <ReactCrop
            crop={crop}
            aspect={cropAspect}
            onChange={this.handleOnChange}
            onComplete={this.handleOnComplete}
            circularCrop={circularCrop}
          >
            <img
              src={image}
              crossOrigin={isUrl(image) ? 'anonymous' : undefined}
              alt="React crop img"
              onLoad={this.handleOnImageLoaded}
            />
          </ReactCrop>
        </div>
      </div>
    );
  }

  handleOnSuccess = () => {
    const { onSuccess, cropAspect } = this.props;
    const { imageDOM, crop } = this.state;

    onSuccess(imageDOM, { ...crop, aspect: cropAspect });
  };

  handleOnChange = crop => {
    this.setState({ crop });
  };

  handleOnComplete = crop => {
    this.setState({ crop });
  };

  handleOnImageLoaded = e => {
    const { width, height } = e.currentTarget;
    const { cropAspect } = this.props;

    this.setState({
      imageDOM: e.currentTarget,
      crop: {
        unit: 'px',
        x: 0,
        y: 0,
        aspect: cropAspect,
        width: width > height ? height : width,
        height: height > width ? width : height,
      },
    });
    return false; // Return false when setting crop state in here.
  };

  render() {
    const { intl, onAbort, isOpen, image } = this.props;

    if (!image) {
      return null;
    }

    return (
      <ContentModal
        classes={{
          root: 'modal-crop',
        }}
        open={isOpen}
        title={t('.title', intl, __filenamespace)}
        successLabel={t('.success_label', intl, __filenamespace)}
        cancelLabel={t('.cancel_label', intl, __filenamespace)}
        onSuccess={this.handleOnSuccess}
        onAbort={onAbort}
      >
        {this.content}
      </ContentModal>
    );
  }
}

ModalCrop.propTypes = {
  circularCrop: PropTypes.bool.isRequired,
  cropAspect: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default ModalCrop;
