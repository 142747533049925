import { List, Map, OrderedSet, Set } from 'immutable';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';

import { Filters, FilterPeriod } from 'containers/reports/types';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions.js';
import {
  BalancesSource,
  ClientSource,
  LocationSource,
  ScheduledPaymentSource,
} from 'sources';
import { downloadFile } from 'shared/utils/SharedUtils.js';

import { hasFilterDrawerDefaults } from './utils';
import BalancesReportActions from './Actions';

class BalancesReportStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.debouncedListBalances = debounce(() => {
      this.page = 1;
      this.list();
      this.fetchStatistics();
    }, 600);

    this.bindListeners({
      mounted: BalancesReportActions.mounted,
      openFilterDrawer: BalancesReportActions.openFilterDrawer,
      closeFilterDrawer: BalancesReportActions.closeFilterDrawer,
      downloadList: BalancesReportActions.downloadList,
      downloadListSuccess: BalancesReportActions.downloadListSuccess,
      downloadListError: BalancesReportActions.downloadListError,
      fetchStatistics: BalancesReportActions.fetchStatistics,
      fetchStatisticsSuccess: BalancesReportActions.fetchStatisticsSuccess,
      fetchStatisticsError: BalancesReportActions.fetchStatisticsError,
      listReportData: [
        QuickpayModalActions.checkoutSuccess,
        RefundModalActions.waiveSuccess,
      ],
      list: BalancesReportActions.list,
      listSuccess: BalancesReportActions.listSuccess,
      listError: BalancesReportActions.listError,
      listLocationSuccess: BalancesReportActions.listLocationSuccess,
      listLocationError: BalancesReportActions.listLocationError,
      listClientsSuccess: BalancesReportActions.listClientsSuccess,
      listClientsError: BalancesReportActions.listClientsError,
      applyFilters: BalancesReportActions.applyFilters,
      clearFilters: BalancesReportActions.clearFilters,
      updateFilter: BalancesReportActions.updateFilter,
      removeFilter: BalancesReportActions.removeFilter,
      selectPage: BalancesReportActions.selectPage,
      selectDay: BalancesReportActions.selectDay,
      openColumnDrawer: BalancesReportActions.openColumnDrawer,
      closeColumnDrawer: BalancesReportActions.closeColumnDrawer,
      changeColumnVisibility: BalancesReportActions.changeColumnVisibility,
      listScheduledPaymentsSuccess:
        BalancesReportActions.listScheduledPaymentsSuccess,
    });
  }

  reset() {
    this.isMobile = false;
    this.isFilterDrawerOpen = false;
    this.isColumnDrawerOpen = false;
    this.isLoadingReport = false;
    this.isLoadingStatistics = false;
    this.isLoadingLocation = false;
    this.isLoadingClients = false;
    this.locationIds = Set();
    this.eventIds = Set();
    this.statistics = Map();
    this.defaultFilters = Map({
      search: '',
      period: {
        value: FilterPeriod.today,
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      },
      product: '',
      productType: '',
      paymentTypes: [],
      location: [],
    });
    this.activeFilters = Map({
      period: this.defaultFilters.get(Filters.PERIOD),
      balanceRemainingMin: 0,
    });
    this.drawerFilters = Map();
    this.isFilterDrawerChanged = false;
    this.columns = Map({
      buyer: true,
      product: true,
      productType: true,
      paymentType: true,
      paid: true,
      balance: true,
      quantity: true,
    });
    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.balanceIds = new OrderedSet();
    this.filterErrors = new FieldErrors();
    this.reportMode = false;
    this.paymentPlanIds = [];
    this.scheduledPayments = List();
  }

  mounted({ isMobile, eventId, reportMode }) {
    this.reset();
    this.reportMode = reportMode;
    if (eventId) {
      this.eventIds = this.eventIds.add(eventId);
    }
    if (!reportMode) {
      this.columns = Map({
        buyer: true,
        paymentType: true,
        paid: true,
        balance: true,
        quantity: true,
      });
      this.defaultFilters = this.defaultFilters.set(Filters.PERIOD, {
        value: FilterPeriod.all_time,
        from: null,
        to: null,
      });
      this.activeFilters = new Map({
        period: this.defaultFilters.get(Filters.PERIOD),
      });
    }
    this.isMobile = isMobile;
    this.isLoadingStatistics = true; // Changing here for showing header during on filter change
    this.listReportData();
  }

  openFilterDrawer() {
    this.isFilterDrawerOpen = true;
    this.isFilterDrawerChanged = false;
    this.drawerFilters = Map(this.activeFilters);
    this.listLocations();
  }

  closeFilterDrawer() {
    this.isFilterDrawerOpen = false;
    this.drawerFilters = Map();
  }

  listLocations(page = 1) {
    this.isLoadingLocation = true;

    LocationSource.list({
      params: {
        page,
        per_page: 50,
      },
      success: BalancesReportActions.listLocationSuccess,
      error: BalancesReportActions.listLocationError,
    });
  }

  listLocationSuccess({ locations, page, totalCount }) {
    this.locationIds = this.locationIds.union(locations.map(l => l.id).toSet());

    if (totalCount > this.locationIds.count()) {
      this.listLocations(page + 1);
      return;
    }
    this.defaultFilters = this.defaultFilters.set(
      Filters.LOCATION,
      this.locationIds.toJS()
    );
    this.isLoadingLocation = false;
  }

  listLocationError() {
    this.isLoadingLocation = false;
  }

  getParams() {
    const params = {};

    if (
      this.activeFilters.get(Filters.PERIOD) &&
      this.activeFilters.get(Filters.PERIOD).value !== FilterPeriod.all_time
    ) {
      params.effective_at_min = this.activeFilters
        .get(Filters.PERIOD)
        .from.toISOString();
      params.effective_at_max = this.activeFilters
        .get(Filters.PERIOD)
        .to.toISOString();
    }
    if (this.activeFilters.get(Filters.SEARCH)) {
      params.client_name = this.activeFilters.get(Filters.SEARCH);
    }

    if (this.activeFilters.get(Filters.PRODUCT)) {
      params.product = this.activeFilters.get(Filters.PRODUCT);
    }

    if (this.activeFilters.get(Filters.PRODUCT_TYPE)) {
      params.product_type = this.activeFilters.get(Filters.PRODUCT_TYPE);
    }

    if (
      this.activeFilters.get(Filters.PAYMENT_TYPES) &&
      this.activeFilters.get(Filters.PAYMENT_TYPES).length !==
        this.defaultFilters.get(Filters.PAYMENT_TYPES).length
    ) {
      params.payment_types = this.activeFilters.get(Filters.PAYMENT_TYPES);
    }

    if (
      this.activeFilters.get(Filters.LOCATION) &&
      this.activeFilters.get(Filters.LOCATION).length !==
        this.defaultFilters.get(Filters.LOCATION).length
    ) {
      params.location_ids = this.activeFilters.get(Filters.LOCATION);
    }

    if (this.eventIds.size) {
      params.event_ids = this.eventIds.toJS();
    }

    // Since BE operate with negative values - we have switch min => max
    if (
      this.activeFilters.get(Filters.BALANCE_REMAINING_MIN) ||
      this.activeFilters.get(Filters.BALANCE_REMAINING_MIN) === 0
    ) {
      params.balance_remaining_max =
        this.activeFilters.get(Filters.BALANCE_REMAINING_MIN) * -100;
      // This is needed to filtrate balances !== 0 and to not show 0.01 on UI. https://www.pivotaltracker.com/story/show/174389433
      if (params.balance_remaining_max === 0) {
        params.balance_remaining_max -= 1;
      }
    }

    if (
      this.activeFilters.get(Filters.BALANCE_REMAINING_MAX) ||
      this.activeFilters.get(Filters.BALANCE_REMAINING_MAX) === 0
    ) {
      params.balance_remaining_min =
        this.activeFilters.get(Filters.BALANCE_REMAINING_MAX) * -100;
    }

    return params;
  }

  listReportData() {
    // Avoid additional balances list with QPay on Client Profile
    if (!this.isViewingClient()) {
      this.list();
      this.fetchStatistics();
    }
  }

  list() {
    this.isLoadingReport = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      ...this.getParams(),
    };

    BalancesSource.list({
      params,
      success: BalancesReportActions.listSuccess,
      error: BalancesReportActions.listError,
    });
  }

  listSuccess({ records, page, perPage, totalCount }) {
    const clientIds = records.map(el => el.clientId);

    if (clientIds.size > 0) {
      this.isLoadingClients = true;
      ClientSource.list({
        params: {
          ids: clientIds.toJS(),
          fields: ['profile_image'],
          per_page: this.perPage,
        },
        success: BalancesReportActions.listClientsSuccess,
        error: BalancesReportActions.listClientsError,
      });
    }

    const newIds = records.map(el => el.compoundId).toOrderedSet();

    if (this.isMobile) {
      this.balanceIds = page === 1 ? newIds : this.balanceIds.union(newIds);
    } else {
      this.balanceIds = newIds;
    }
    this.page = this.isMobile ? page + 1 : page;
    this.perPage = perPage;
    this.totalCount = totalCount;
    if (records.size > 0) {
      ScheduledPaymentSource.list({
        params: {
          ids: records.map(el => el.productId).toJS(),
          page: 1,
          per_page: this.perPage,
        },
        success: BalancesReportActions.listScheduledPaymentsSuccess,
        error: BalancesReportActions.listError,
      });
    } else {
      this.isLoadingReport = false;
    }
  }

  listScheduledPaymentsSuccess({ scheduled_payments: scheduledPayments }) {
    this.scheduledPayments = scheduledPayments;
    this.isLoadingReport = false;
  }

  listError() {
    this.isLoadingReport = false;
  }

  listClientsSuccess() {
    this.isLoadingClients = false;
  }

  listClientsError() {
    this.isLoadingClients = false;
  }

  fetchStatistics() {
    const params = this.getParams();

    uhApiClient.get({
      url: 'ledger/balances_statistics',
      data: params,
      success: BalancesReportActions.fetchStatisticsSuccess,
      error: BalancesReportActions.fetchStatisticsError,
    });
  }

  fetchStatisticsSuccess(data) {
    this.statistics = new Map(data);
    this.isLoadingStatistics = false;
  }

  fetchStatisticsError(...args) {
    this.isLoadingStatistics = false;
    this.notifyError('error fetching order statistics', args);
  }

  applyFilters() {
    if (this.validateFilters()) {
      this.activeFilters = Map(this.drawerFilters);
      this.page = 1;
      this.listReportData();
      this.isFilterDrawerOpen = false;
      this.isFilterDrawerChanged = false;
    }
  }

  clearFilters() {
    if (this.isFilterDrawerOpen) {
      this.drawerFilters = Map(this.activeFilters)
        .delete(Filters.PAYMENT_TYPES)
        .delete(Filters.LOCATION)
        .delete(Filters.PRODUCT_TYPE)
        .delete(Filters.PRODUCT)
        .delete(Filters.BALANCE_REMAINING_MAX)
        .set(Filters.BALANCE_REMAINING_MIN, 0);
      if (!this.reportMode) {
        this.drawerFilters = this.drawerFilters
          .set(Filters.PERIOD, this.defaultFilters.get(Filters.PERIOD))
          .delete(Filters.BALANCE_REMAINING_MIN);
      }
      this.isFilterDrawerChanged = !hasFilterDrawerDefaults(
        this.activeFilters,
        this.reportMode
      );
    } else {
      this.activeFilters = new Map({
        period: this.defaultFilters.get(Filters.PERIOD),
        balanceRemainingMin: 0,
      });
      this.page = 1;
      this.listReportData();
    }
  }

  updateFilter([key, value]) {
    let newFilters = this.isFilterDrawerOpen
      ? this.drawerFilters
      : this.activeFilters;

    newFilters = newFilters.set(key, value);

    if (
      key === Filters.BALANCE_REMAINING_MIN ||
      key === Filters.BALANCE_REMAINING_MAX
    ) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(value)) {
        newFilters = newFilters.delete(key);
      } else {
        newFilters = newFilters.set(key, Math.abs(value));
      }
    }

    if (this.isFilterDrawerOpen) {
      this.drawerFilters = newFilters;
      this.isFilterDrawerChanged = true;
    } else {
      this.activeFilters = newFilters;
      if (this.validateFilters(key)) {
        if (
          key === Filters.SEARCH ||
          key === Filters.PRODUCT_TYPE ||
          key === Filters.PRODUCT
        ) {
          this.debouncedListBalances();
        } else {
          this.page = 1;
          this.listReportData();
        }
      }
    }
  }

  removeFilter(filterName) {
    if (filterName === Filters.PERIOD) {
      this.activeFilters = this.activeFilters.set(
        filterName,
        this.defaultFilters.get(Filters.PERIOD)
      );
    } else {
      this.activeFilters = this.activeFilters.delete(filterName);
    }
    this.page = 1;
    this.listReportData();
  }

  validateFilters(key) {
    const filters = this.isFilterDrawerOpen
      ? this.drawerFilters
      : this.activeFilters;

    this.filterErrors = this.filterErrors.clear();

    if (
      (key === Filters.BALANCE_REMAINING_MIN || key === undefined) &&
      filters.get(Filters.BALANCE_REMAINING_MIN) >
        filters.get(Filters.BALANCE_REMAINING_MAX)
    ) {
      this.filterErrors = this.filterErrors.add(
        'balanceRemainingMin',
        'containers.reports.balancesReport.errors.balance_remaining_min'
      );
    }

    if (
      (key === Filters.BALANCE_REMAINING_MAX || key === undefined) &&
      filters.get(Filters.BALANCE_REMAINING_MAX) <
        filters.get(Filters.BALANCE_REMAINING_MIN)
    ) {
      this.filterErrors = this.filterErrors.add(
        Filters.BALANCE_REMAINING_MAX,
        'containers.reports.balancesReport.errors.balance_remaining_max'
      );
    }

    return this.filterErrors.isEmpty();
  }

  selectPage([page, perPage]) {
    this.page = page;
    this.perPage = perPage;
    this.listReportData();
  }

  selectDay(value) {
    this.page = 1;

    if (value === 1 || value === -1) {
      this.activeFilters = this.activeFilters.set(Filters.PERIOD, {
        value: FilterPeriod.custom,
        from: this.activeFilters.get(Filters.PERIOD).from.add(value, 'days'),
        to: this.activeFilters.get(Filters.PERIOD).to.add(value, 'days'),
      });
    } else {
      const date = value || moment();

      this.activeFilters = this.activeFilters.set(Filters.PERIOD, {
        value: FilterPeriod.custom,
        from: date.clone().local().startOf('day'),
        to: date.clone().local().endOf('day'),
      });
    }
    this.listReportData();
  }

  openColumnDrawer() {
    this.isColumnDrawerOpen = true;
  }

  closeColumnDrawer() {
    this.isColumnDrawerOpen = false;
  }

  changeColumnVisibility([key, value]) {
    this.columns = this.columns.set(key, value);
  }

  downloadList() {
    this.isBalanceListDownloading = false;
    BalancesSource.downloadCsv({
      params: this.getParams(),
      success: BalancesReportActions.downloadListSuccess,
      error: BalancesReportActions.downloadListError,
    });
  }

  downloadListSuccess(data) {
    downloadFile({
      data,
      fileName: 'balance_list.csv',
    });
    this.isBalanceListDownloading = false;
  }

  downloadListError() {
    this.isBalanceListDownloading = false;
  }

  // eslint-disable-next-line class-methods-use-this
  isViewingClient() {
    return /clients\/\d+$/.test(window.location.pathname);
  }
}

export default alt.createStore(BalancesReportStore, 'BalancesReportStore');
