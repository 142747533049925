export default alt.generateActions(
  'menuMounted',
  'menuOpened',
  'menuClosed',
  'taskSelected',
  'locationSelected',
  'listSuccess',
  'listError',
  'clockIn',
  'clockInSuccess',
  'clockInError',
  'clockOut',
  'clockOutSuccess',
  'clockOutError'
);
