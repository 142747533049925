import { Map, List } from 'immutable';
import moment from 'moment-timezone';

import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';

import * as DateUtils from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import CalendarViewStore, {
  defaultViews,
} from 'calendar/stores/CalendarViewStore.jsx';
import CheckInActions from 'containers/events/admin/sessionSummaryDrawer/components/checkIn//actions.js';
import Client from 'shared/records/Client.jsx';
import DetailsActions from 'containers/events/admin/sessionSummaryDrawer/components/details/actions';
import EventTime from 'calendar/records/EventTime.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import Membership from 'shared/records/Membership.jsx';
import { SessionSource } from 'sources';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import * as CalendarUtils from '../utils/CalendarUtils.jsx';

class CalendarStore extends UpperHandStore {
  constructor() {
    super();

    this.date = moment();
    this.view = CALENDAR_TYPE.DAY;
    this.fuzzySearch = '';
    this.availableStaffOnly = true;
    this.showAvailability = true;
    this.loading = true;

    this.clients = Map();
    this.eventTimes = List();
    this.events = Map();
    this.filteredEventTimes = List();
    this.filteredEventTimesByWeekday = List();
    this.memberships = Map();

    this.sessionSummaryDrawer = {
      isOpen: false,
      eventId: null,
      sessionId: null,
      selectedTab: 'details',
    };

    this.isFilterDrawerOpen = false;
    this.calendarFilters = Map({
      showPaid: false,
      showUnpaid: false,
      showAllStaff: false,
      isActive: false,
    });
    this.staffIds = [];

    this.bindListeners({
      checkIn: CalendarActions.CHECK_IN,
      checkOut: CalendarActions.CHECK_OUT,
      noShowHandler: CalendarActions.noShowHandler,
      noShowSuccess: CalendarActions.noShowSuccess,
      checkAttendanceSuccess: [
        CalendarActions.CHECK_ATTENDANCE_SUCCESS,
        CheckInActions.checkInSuccess,
        CheckInActions.checkOutSuccess,
      ],
      setCalendarDate: CalendarActions.SET_CALENDAR_DATE,
      setView: CalendarActions.SET_VIEW,
      handleList: [
        CalendarActions.LIST,
        QuickScheduleActions.UPDATE_REGISTRATION_SUCCESS,
        QuickScheduleActions.CHECKOUT_SUCCESS,
      ],
      handleSessionUpdate: DetailsActions.sessionUpdateSuccess,
      handleListSuccess: CalendarActions.LIST_SUCCESS,
      handleListError: CalendarActions.LIST_ERROR,
      handleToggleAvailableStaff: CalendarActions.toggleAvailableStaff,
      handleToggleShowAvailability: CalendarActions.toggleShowAvailability,
      handleSessionOpen: CalendarActions.VIEW_SESSION_CLICKED,
      handleSessionClose: CalendarActions.VIEW_SESSION_CLOSED,
      updateFuzzySearch: [
        CalendarActions.UPDATE_FUZZY_SEARCH,
        CalendarActions.SET_VIEW,
      ],
      clearFuzzySearch: [
        CalendarActions.CLEAR_FUZZY_SEARCH,
        CalendarActions.SET_VIEW,
      ],
      handleFilterDrawerOpen: CalendarActions.filterDrawerOpen,
      handleFilterDrawerClose: CalendarActions.filterDrawerClose,
      handleFilterChange: CalendarActions.onFilterChange,
      handleClearFilters: CalendarActions.clearFilters,
      handleSetStaffIds: CalendarActions.setStaffIds,
    });
  }

  /**
   * @param {moment | date} date
   */
  setCalendarDate(date) {
    const newDate = date.tz(customerTZ(), true);

    this.date = newDate.isValid() ? newDate : this.date;

    this.handleList();
  }

  /**
   * @param {CALENDAR_VIEW_TYPE} view
   */
  setView(view) {
    if (view !== this.view) {
      switch (view) {
        case CALENDAR_TYPE.DAY:
        case CALENDAR_TYPE.WEEK:
          this.view = view;
          break;
        default:
          break;
      }

      this.handleList();
    }
  }

  handleList() {
    this.loading = true;

    const params = {
      fields: ['team_type'],
    };

    switch (this.view) {
      case CALENDAR_TYPE.DAY: {
        params.start_date = this.date;
        break;
      }
      case CALENDAR_TYPE.WEEK: {
        const dateRange = DateUtils.weekRangeFromDate(this.date);

        params.start_date = dateRange.start;
        params.end_date = dateRange.end;
        break;
      }
      default:
        break;
    }

    return uhApiClient.get({
      url: '/calendar',
      data: params,
      success: CalendarActions.listSuccess,
      error: CalendarActions.listError,
    });
  }

  handleListSuccess(data) {
    this.loading = false;
    this.waitFor(CalendarViewStore);
    // Disable available staff only display if default staff calendar will be rendered
    // to avoid empty calendar rendered in case current staff is unavailable
    const { currentCalendarView } = CalendarViewStore.getState();
    if (currentCalendarView.get('id') === defaultViews.MY_CALENDAR) {
      this.availableStaffOnly = false;
    }
    this.clients = List(data.clients.map(client => new Client(client)))
      .groupBy(c => c.id)
      .map(c => c.first());
    this.events = List(
      data.events.map(event => new EventTranslator(event).toClient())
    )
      .groupBy(c => c.id)
      .map(c => c.first());
    this.eventTimes = List(
      data.event_times.map(eventTime => new EventTime(eventTime))
    );
    this.memberships = List(
      data.memberships.map(membership => new Membership(membership))
    )
      .groupBy(c => c.id)
      .map(c => c.first());
    this.updateFilteredEvents();
  }

  handleListError(...args) {
    this.loading = false;
    this.notifyError('Error creating calendar view.', args);
  }

  // TODO: Get information from the data store so we don't need to watch another stores' actions.
  handleSessionUpdate(session) {
    const index = this.eventTimes.findIndex(
      et => et.get('session_id') === session.id
    );

    this.eventTimes = this.eventTimes
      .setIn([index, 'staff_ids'], session.staff_ids.toList())
      .setIn([index, 'resource_ids'], session.resource_ids.toList());
  }

  /**
   * @param {boolean} show
   */
  handleToggleAvailableStaff() {
    this.availableStaffOnly = !this.availableStaffOnly;
  }

  handleToggleShowAvailability() {
    this.showAvailability = !this.showAvailability;
  }

  updateFuzzySearch(value) {
    this.fuzzySearch = value ? value.toLowerCase() : '';
    this.updateFilteredEvents();
  }

  clearFuzzySearch() {
    this.fuzzySearch = '';
    this.updateFilteredEvents();
  }

  handleSessionOpen({ eventId, sessionId, selectedTab = 0 }) {
    this.sessionSummaryDrawer = {
      isOpen: true,
      sessionId,
      eventId,
      selectedTab,
    };
  }

  handleSessionClose() {
    this.sessionSummaryDrawer = {
      isOpen: false,
      sessionId: null,
      eventId: null,
      selectedTab: 0,
    };
  }

  checkIn({ sessionId, customerUserId }) {
    this.loading = true;

    SessionSource.checkIn({
      id: sessionId,
      params: {
        client_ids: [customerUserId],
      },
      success: CalendarActions.checkAttendanceSuccess,
    });
  }

  checkOut({ sessionId, customerUserId }) {
    this.loading = true;

    SessionSource.checkOut({
      id: sessionId,
      params: {
        client_ids: [customerUserId],
      },
      success: CalendarActions.checkAttendanceSuccess,
    });
  }

  noShowHandler({ sessionId, customerUserId }) {
    this.loading = true;
    SessionSource.noShow({
      id: sessionId,
      params: {
        client_ids: [customerUserId],
      },
      success: CalendarActions.noShowSuccess,
    });
  }

  noShowSuccess(session) {
    this.loading = false;
    const idx = this.eventTimes.findIndex(et => et.session_id === session.id);
    if (this.view === CALENDAR_TYPE.WEEK) {
      this.handleList();
    }
    if (this.view !== CALENDAR_TYPE.WEEK) {
      if (idx !== -1) {
        this.eventTimes = this.eventTimes.setIn(
          [idx],
          this.eventTimes
            .get(idx)
            .set(
              'no_show_customer_user_ids',
              session.attendance_map.get('no_show')
            )
        );
        this.eventTimes = this.eventTimes.setIn(
          [idx],
          this.eventTimes
            .get(idx)
            .set(
              'attendance_customer_user_ids',
              session.attendance_map.get('checked_in')
            )
        );
      }
      CheckInActions.mounted.defer(session.id);
    }
  }

  checkAttendanceSuccess(session) {
    this.loading = false;
    const idx = this.eventTimes.findIndex(et => et.session_id === session.id);
    if (this.view === CALENDAR_TYPE.WEEK) {
      this.handleList();
    }
    if (this.view !== CALENDAR_TYPE.WEEK) {
      if (idx !== -1) {
        this.eventTimes = this.eventTimes.setIn(
          [idx],
          this.eventTimes
            .get(idx)
            .set(
              'attendance_customer_user_ids',
              session.attendance_map.get('checked_in')
            )
        );
        this.eventTimes = this.eventTimes.setIn(
          [idx],
          this.eventTimes
            .get(idx)
            .set(
              'no_show_customer_user_ids',
              session.attendance_map.get('no_show')
            )
        );
      }
    }
  }

  updateFilteredEvents() {
    const isCoach = currentUser().isCoach();

    this.filteredEventTimes = this.eventTimes.filter(eventTime => {
      const event = this.events.get(eventTime.event_id);
      return CalendarUtils.fuzzySearchString(
        this.fuzzySearch,
        event?.title ?? ''
      );
    });
    if (isCoach && this.view === CALENDAR_TYPE.WEEK) {
      const currentStaffMemberId = currentUser().customer_user_id;
      this.filteredEventTimes = this.filteredEventTimes.filter(eventTime =>
        eventTime.staff_ids.includes(currentStaffMemberId)
      );
    }
    this.filteredEventTimesByWeekday = this.filteredEventTimes.groupBy(
      eventTime => {
        const { start } = eventTime.dateRange();
        return start.format('YYYY-MM-DD');
      }
    );
  }

  handleFilterDrawerOpen() {
    this.isFilterDrawerOpen = true;
  }

  handleFilterDrawerClose() {
    this.isFilterDrawerOpen = false;
  }

  handleFilterChange(key) {
    this.calendarFilters = this.calendarFilters.set(
      key,
      !this.calendarFilters.get(key)
    );
    if (this.calendarFilters.get('showAllStaff')) {
      this.calendarFilters = this.calendarFilters
        .set('showPaid', false)
        .set('showUnpaid', false);
    }
    if (
      this.calendarFilters.get('showPaid') ||
      this.calendarFilters.get('showUnpaid') ||
      this.calendarFilters.get('showAllStaff')
    ) {
      this.calendarFilters = this.calendarFilters.set('isActive', true);
    } else {
      this.calendarFilters = this.calendarFilters.set('isActive', false);
    }
  }

  handleClearFilters() {
    this.calendarFilters = Map({
      showPaid: false,
      showUnpaid: false,
      showAllStaff: false,
      isActive: false,
    });
    this.staffIds = [];
  }

  handleSetStaffIds(ids) {
    this.staffIds = ids;
  }
}

export default alt.createStore(CalendarStore, 'CalendarStore');
