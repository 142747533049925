import * as React from 'react';
import { Button } from '@upperhand/playmaker';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Card, Grid, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import PurchaseDrawerActions from 'containers/purchaseDrawer/Actions';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import { STATUS } from 'records/compoundObjects/BrowseEvent.js';
import CustomerTeam from 'team_mgmt/shared/records/CustomerTeam.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';

function TeamCard({ browseTeam, intl }) {
  const {
    id: eventId,
    title,
    team_type: { name, color },
    status,
    path,
  } = browseTeam;
  const userId = currentUser().customer_user_id;
  const isAlreadyRegistered = browseTeam.isAlreadyRegistered(userId);

  const navigate = useNavigate();
  const handleClick = _e => {
    _e.stopPropagation();
    if (isLoggedIn()) {
      CartActions.fetch({});
      EventDisplayActions.clientDisplayMounted({ eventId, isTeamsPage: true });
      AutomationTemplateDescriptionActions.list({
        eventId,
      });
      ClientCreditActions.list({ eventIds: eventId });
      EventActions.fetchAvailability({ id: eventId });
      RegistrationActions.list({ event_id: eventId });
    }
    PurchaseDrawerActions.openDrawer();
  };

  return (
    <ResponsiveElement
      largeScreen={
        <Card
          className="browse-teams__card"
          sx={{ borderLeft: `4px solid ${color}` }}
          onClick={() => navigate(path)}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            xs={12}
            spacing={1}
          >
            <Grid item xs={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle2" className="browse-teams__title">
                  {title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="browse-teams__type-title"
                >
                  {name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={1}>
              {isAlreadyRegistered ? (
                <Button
                  fullWidth
                  icon={<DoneIcon sx={{ color: uhColors.green }} />}
                  iconPosition="right"
                  onClick={handleClick}
                >
                  {t('.registered_btn', intl, __filenamespace)}
                </Button>
              ) : (
                <Button
                  type="secondary"
                  fullWidth
                  disabled={status !== STATUS.active}
                  onClick={handleClick}
                  classes={{
                    root: 'browse-teams__button',
                  }}
                >
                  {t('.btn', intl, __filenamespace)}
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      }
      smallScreen={
        <Card
          className="browse-teams__card"
          sx={{ borderLeft: `4px solid ${color}` }}
          onClick={() => navigate(path)}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            xs={12}
            spacing={1}
            className="browse-teams__mobile-content"
          >
            <Grid
              container
              justifyContent="space-between"
              item
              xs={6}
              flexDirection="column"
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
              <Typography variant="body" align="left">
                {name}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {isAlreadyRegistered ? (
                <Button
                  icon={<DoneIcon sx={{ color: uhColors.green }} />}
                  iconPosition="right"
                  classes={{
                    root: 'browse-teams__button-mobile',
                  }}
                >
                  {t('.registered_btn', intl, __filenamespace)}
                </Button>
              ) : (
                <Button
                  type="secondary"
                  disabled={status !== STATUS.active}
                  onClick={handleClick}
                  classes={{
                    root: 'browse-teams__button-mobile',
                  }}
                >
                  {t('.btn', intl, __filenamespace)}
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      }
    />
  );
}
TeamCard.propTypes = {
  browseTeam: PropTypes.instanceOf(CustomerTeam).isRequired,
  intl: PropTypes.object.isRequired,
};

export default TeamCard;
