import * as React from 'react';
import AltContainer from 'alt-container';

import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import BrowseEventListStore from 'client_portal/stores/BrowseEventListStore.jsx';
import BrowseEventsFilterStore from 'client_portal/stores/BrowseEventsFilterStore.jsx';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import MembershipListingStore from 'memberships/stores/MembershipListingStore.jsx';
import PortalTabs from 'client_portal/components/PortalTabs.jsx';
import PortalTabsStore from 'client_portal/stores/PortalTabsStore.jsx';
import ClientProfileStore from 'containers/clientProfile/Store';

function ClientPortal() {
  return (
    <main>
      <AltContainer
        stores={{
          creditPassListingStore: CreditPassListingStore,
          automationStore: AutomationTemplateDescriptionStore,
          browseEventListStore: BrowseEventListStore,
          browseEventsFilterStore: BrowseEventsFilterStore,
          eventTypeListingStore: EventTypeListingStore,
          locationStore: LocationStore,
          membershipListingStore: MembershipListingStore,
          tabStore: PortalTabsStore,
          clientProfileStore: ClientProfileStore,
        }}
      >
        <PortalTabs />
      </AltContainer>
    </main>
  );
}

export default ClientPortal;
