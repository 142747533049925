import InviteActions from 'user_management/shared/actions/InviteActions.jsx';
import ClientActions from 'shared/actions/ClientActions.jsx';
import UserCreationActions from 'user_management/shared/actions/UserCreationActions.jsx';
import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';

import ClientStoreInterface from 'shared/stores/ClientStoreInterface.jsx';

class ClientStore extends ClientStoreInterface {
  constructor() {
    super();

    this.actions = ClientActions;

    this.bindListeners({
      handleReset: [
        StoreActions.prepareForReuse,
        InviteDialogActions.openInviteDialog,
      ],

      handleAcceptTerms: ClientActions.acceptTerms,

      handleList: ClientActions.list,
      handleListSuccess: ClientActions.listSuccess,
      handleListError: ClientActions.listError,

      handleFetch: ClientActions.fetch,
      handleFetchSuccess: ClientActions.fetchSuccess,
      handleFetchError: ClientActions.fetchError,

      handleCreateOrUpdate: ClientActions.createOrUpdate,

      handleCreate: ClientActions.create,
      handleCreateSuccess: ClientActions.createSuccess,
      handleCreateError: ClientActions.createError,

      setCreatingManagedProfile: UserCreationActions.NEXT_STEP,
      unsetCreatingManagedProfile: UserCreationActions.PREV_STEP,

      handleUpdate: ClientActions.update,
      handleUpdateSuccess: ClientActions.updateSuccess,
      handleUpdateError: ClientActions.updateError,

      handleUpdateStore: ClientActions.updateStore,
      handleClear: ClientActions.clearClient,

      handleStageClient: ClientActions.stageClient,
      validateUser: ClientActions.validateUser,

      handleSendInvite: InviteActions.SEND_SUCCESS,
      handleDeleteInvite: InviteActions.DELETE_SUCCESS,

      handleArchive: ClientActions.ARCHIVE,
      handleUnarchive: ClientActions.UNARCHIVE,
      handleArchiveOrUnarchiveSuccess: [
        ClientActions.ARCHIVE_SUCCESS,
        ClientActions.UNARCHIVE_SUCCESS,
      ],
      handleArchiveOrUnarchiveError: ClientActions.ARCHIVE_OR_UNARCHIVE_ERROR,
    });
  }
}

export default alt.createStore(ClientStore, 'ClientStore');
