import TaxRateCreationActions from 'shared/actions/tax_rates/TaxRateCreationActions';
import TaxRateEditingStoreInterface from 'shared/stores/tax_rates/TaxRateEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  TaxRateEditingStoreInterface,
  'TaxRateCreationStore',
  {
    actions: TaxRateCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
