import * as React from 'react';
import { injectIntl } from 'react-intl';

import { Dropdown, Grid, Typography, Button } from '@upperhand/playmaker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import SearchBar from 'shared/components/SearchBar.jsx';
import ChatBubbleIcon from 'shared/components/icons/ChatBubble.jsx';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import Expander from 'shared/components/Expander.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import eventSeasons from 'shared/constants/eventSeasons.js';

import FilterAccordion from 'components/FilterAccordion.jsx';
import BrowseEventsActions from '../Actions';
import { VIEW_MODE } from '../Store';

const styles = {
  root: {
    padding: '16px',
    width: '100%',
  },
  searchBarDivStyles: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
  },
  searchbarIconStyles: {
    marginRight: '10px',
    color: uhColors.lighterGrey,
    fontSize: '30px',
  },
  searchbarStyles: {
    fontSize: 16,
    flexGrow: 2,
    width: '100%',
    backgroundColor: uhColors.white,
    height: '42px',
    borderRadius: '42px',
    padding: '0 10px',
  },
  searchbarInputStyles: {
    color: '#000',
  },
  disableButtonStyles: {
    backgroundColor: uhColors.tunaBlack,
  },
  filterWrapper: {
    paddingTop: '16px',
    backgroundColor: '#F4F5F7',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  announcementWrapper: {
    whiteSpace: 'pre-wrap',
    paddingTop: '16px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
  },
  iconSpacing: {
    marginRight: '10px',
    marginBottom: '-3px',
    color: 'var(--color-old-gray)',
  },
  filterHeader: {
    marginBottom: '20px',
  },
  filterHeaderWithAction: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '5px',
    alignItems: 'flex-end',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  applyButton: {
    marginLeft: '8px',
    backgroundColor: '#5A5A5A',
    color: '#FFF',
  },
  buttonContainer: {
    marginTop: '10px',
    marginLeft: '16px',
  },
  announcementLabel: {
    color: '#000',
    alignItems: 'flex-start',
    maxHeight: 'initial',
    height: 'initial',
  },
  announcementReadLabel: {
    color: uhColors.activeBlue,
    cursor: 'pointer',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  announcementContent: {
    backgroundColor: '#FFF',
    padding: '18px',
    marginTop: '16px',
  },
  announcementText: {
    maxHeight: '330px',
    overflow: 'auto',
    padding: '0 10px',
  },
};

const BrowseEventsRoomy = React.memo(
  ({
    children,
    eventItems,
    eventTypeFilter,
    sportTypeItems,
    sportTypeFilter,
    intl,
    viewMode,
    locationItems,
    locationFilter,
    staffItems,
    staffFilter,
    viewOptions,
    seasonFilter,
    eventTypeAllSelected,
    locationAllSelected,
    staffAllSelected,
    sportTypeAllSelected,
    seasonAllSelected,
    search,
    ageFilter = [],
  }) => {
    const [showOpenText, setShowOpenText] = React.useState(false);
    const showClearAgeFilter = ageFilter.some(f => Boolean(f));
    const disableApplyAge = ageFilter.every(f => !f);

    const toggleExpansion = () => {
      setShowOpenText(prevShowOpenText => !prevShowOpenText);
    };

    return (
      <div style={styles.root}>
        <Grid container spacing={2} alignContent="flex-start">
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} md={9}>
                <div style={styles.searchBarDivStyles}>
                  <SearchBar
                    useStartAdornment
                    searchText={search}
                    endAdornment={
                      search ? (
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={() => BrowseEventsActions.clearFilters(true)}
                        >
                          <Close />
                        </IconButton>
                      ) : null
                    }
                    iconStyle={styles.searchbarIconStyles}
                    style={styles.searchbarStyles}
                    inputStyle={styles.searchbarInputStyles}
                    onTypingEnd={value =>
                      BrowseEventsActions.searchFilterUpdated(value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <Dropdown
                  fullWidth
                  outline
                  rounded
                  classes={{
                    root: 'browse-events__filter-dropdown',
                    selectedItem: 'browse-events__filter-item-selected',
                  }}
                  icon={viewMode === VIEW_MODE.week ? 'calendar' : 'list'}
                  items={viewOptions}
                  value={viewMode}
                  onChange={BrowseEventsActions.toggleBrowseViewMode}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              {children}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box style={styles.announcementWrapper}>
              <Expander
                startsExpanded
                label={
                  <Typography>
                    <Typography variant="h4">
                      <ChatBubbleIcon
                        style={styles.iconSpacing}
                        color={styles.iconSpacing.color}
                      />
                      {t('.announcement', intl, __filenamespace)}
                    </Typography>
                    <Typography>
                      {showOpenText && (
                        <Box sx={styles.announcementReadLabel}>
                          {t('.read_announcement', intl, __filenamespace, {
                            customerName: currentCustomer().name,
                          })}
                        </Box>
                      )}
                    </Typography>
                  </Typography>
                }
                labelStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'calc(100% - 21px)',
                }}
                labelContainerStyle={styles.announcementLabel}
                onExpand={toggleExpansion}
              >
                <Box sx={styles.announcementContent}>
                  <Box sx={styles.announcementText}>
                    {currentCustomer().announcement_text ||
                      t('.announcement_empty', intl, __filenamespace)}
                  </Box>
                </Box>
              </Expander>
            </Box>
            <div style={styles.filterWrapper}>
              <div
                style={{
                  ...styles.filterHeader,
                  ...styles.filterHeaderWithAction,
                }}
              >
                <Typography variant="h4">
                  <FilterAltOutlinedIcon style={styles.iconSpacing} />
                  {t('.filter_events', intl, __filenamespace)}
                </Typography>
                <Button
                  type="tertiary"
                  classes={{ root: 'browse-events__clear-filters' }}
                  onClick={() => BrowseEventsActions.clearFilters()}
                >
                  {t('.clear_filters', intl, __filenamespace)}
                </Button>
              </div>
              <FilterAccordion
                showColorItem
                title={t('.category', intl, __filenamespace)}
                allTitle={t('.all_categories', intl, __filenamespace)}
                items={eventItems}
                filterValue={eventTypeFilter}
                allSelected={eventTypeAllSelected}
                onChange={BrowseEventsActions.eventTypeFilterUpdated}
              />
              <FilterAccordion
                title={t('.location', intl, __filenamespace)}
                allTitle={t('.all_locations', intl, __filenamespace)}
                items={locationItems}
                filterValue={locationFilter}
                allSelected={locationAllSelected}
                onChange={BrowseEventsActions.locationFilterUpdated}
              />
              <FilterAccordion
                title={t('.sport_type', intl, __filenamespace)}
                allTitle={t('.all_sport_types', intl, __filenamespace)}
                items={sportTypeItems}
                filterValue={sportTypeFilter}
                allSelected={sportTypeAllSelected}
                onChange={BrowseEventsActions.sportTypeFilterUpdated}
              />
              <FilterAccordion
                title={t('.season', intl, __filenamespace)}
                allTitle={t('.all_seasons', intl, __filenamespace)}
                items={eventSeasons}
                filterValue={seasonFilter}
                allSelected={seasonAllSelected}
                onChange={BrowseEventsActions.seasonFilterUpdated}
              />
              <FilterAccordion
                title={
                  <>
                    {t('.age', intl, __filenamespace)}
                    {showClearAgeFilter && (
                      <Button
                        type="tertiary"
                        classes={{ root: 'browse-events__clear-filters' }}
                        onClick={e => {
                          e.stopPropagation();
                          BrowseEventsActions.clearFilter('age');
                        }}
                      >
                        {t('.clear_filter', intl, __filenamespace)}
                      </Button>
                    )}
                  </>
                }
                accordionNode={
                  <div style={styles.inputGroup}>
                    <div style={styles.inputContainer}>
                      <InputLabel>
                        {t('.min', intl, __filenamespace)}
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        value={ageFilter[0] || ''}
                        onChange={event => {
                          BrowseEventsActions.ageFilterUpdated([
                            event.target.value,
                            ageFilter[1],
                          ]);
                        }}
                      />
                    </div>
                    <div style={{ margin: '20px 5px 5px' }}>
                      <Typography variant="body1">—</Typography>
                    </div>
                    <div style={styles.inputContainer}>
                      <InputLabel>
                        {t('.max', intl, __filenamespace)}
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        value={ageFilter[1] || ''}
                        onChange={event => {
                          BrowseEventsActions.ageFilterUpdated([
                            ageFilter[0],
                            event.target.value,
                          ]);
                        }}
                      />
                    </div>
                    <div style={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        disabled={disableApplyAge}
                        style={styles.applyButton}
                        onClick={BrowseEventsActions.applyFilters}
                      >
                        {t('.apply', intl, __filenamespace)}
                      </Button>
                    </div>
                  </div>
                }
                filterValue={ageFilter}
              />
              <FilterAccordion
                title={t('.staff', intl, __filenamespace)}
                allTitle={t('.all_staff', intl, __filenamespace)}
                items={staffItems}
                filterValue={staffFilter}
                allSelected={staffAllSelected}
                onChange={BrowseEventsActions.staffFilterUpdated}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
);

export default injectIntl(BrowseEventsRoomy);
