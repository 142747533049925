import { Set } from 'immutable';
import EventRate from 'shared/records/EventRate.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import StaffDetailsDrawerStore from 'contacts/shared/stores/StaffDetailsDrawerStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = id => (id ? `event_rates/${id}` : 'event_rates');

export default class EventRateEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();

    this.eventRate = null;
    this.isSaving = false;
    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      updateEventRate: this.actions.UPDATED,
      eventRateEventSelected: this.actions.EVENT_RATE_EVENT_SELECTED,
      eventRateEventRemoved: this.actions.EVENT_RATE_EVENT_REMOVED,

      beginEdit: this.actions.BEGIN,
      cancelEdit: this.actions.CANCEL,

      createOrUpdate: this.actions.SAVE,
      createOrUpdateSuccess: this.actions.SAVE_SUCCESS,
      createOrUpdateError: this.actions.SAVE_ERROR,
    });
  }

  updateEventRate(updatedFields) {
    if (!this.eventRate) {
      return false;
    }
    this.eventRate = this.eventRate.merge(updatedFields);
  }

  eventRateEventSelected(id) {
    this.eventRate = this.eventRate.updateIn(['event_ids'], (set = Set()) =>
      set.add(id)
    );
  }

  eventRateEventRemoved(id) {
    this.eventRate = this.eventRate.updateIn(['event_ids'], (set = Set()) =>
      set.remove(id)
    );
  }

  beginEdit(eventRate) {
    this.waitFor(StaffDetailsDrawerStore);
    const { id } = StaffDetailsDrawerStore.getState().staff;
    this.eventRate = eventRate || new EventRate({ customer_user_id: id });
  }

  cancelEdit() {
    this.eventRate = null;
  }

  createOrUpdate() {
    if (this.isSaving) {
      return false;
    }

    this.eventRate = this.eventRate.validate();
    if (this.eventRate.isValid()) {
      this.isSaving = true;

      const saveValue = {
        url: url(this.eventRate.id),
        data: JSON.stringify({ attributes: this.eventRate.toServer() }),
        success: this.actions.saveSuccess,
        error: this.actions.saveError,
      };

      if (this.eventRate.id) {
        uhApiClient.put(saveValue);
      } else {
        uhApiClient.post(saveValue);
      }
    }
  }

  createOrUpdateSuccess(_data) {
    this.eventRate = null;
    this.isSaving = false;

    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError() {
    this.isSaving = false;
    this.notifyError('error updating event rate', arguments);
  }
}
