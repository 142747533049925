import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  header: {
    fontSize: 22,
  },
};

function Header() {
  return (
    <h1 style={styles.header}>
      <FormattedMessage id={messageId('.title', __filenamespace)} />
    </h1>
  );
}

export default Header;
