import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ResourceNav({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-18.000000, -564.000000)" fill={color}>
          <g transform="translate(0.000000, 60.000000)">
            <g>
              <g transform="translate(18.000000, 504.000000)">
                <path
                  d="M13.7925,8.2236 C13.7925,7.3836 13.1555,6.8736 12.3115,6.8736 L10.1045,
                   6.8736 L10.1045,9.5726 L12.3115,9.5726 C13.1555,9.5726 13.7925,9.0626 13.7925,
                   8.2236"
                />
                <g>
                  <path
                    d="M19.4995,21 L4.4995,21 C3.6715,21 2.9995,20.329 2.9995,19.5 C2.9995,
                     18.671 3.6715,18 4.4995,18 L19.4995,18 C20.3285,18 20.9995,18.671 20.9995,
                     19.5 C20.9995,20.329 20.3285,21 19.4995,21 Z M7.9995,5 L12.6225,5 C14.6815,
                     5 15.9405,6.364 15.9405,8.224 C15.9405,9.993 14.8445,10.937 13.7775,
                     11.192 L15.9995,15 L13.5855,15 L11.6445,11.447 L10.1035,11.447 L10.1035,
                     15 L7.9995,15 L7.9995,5 Z M20.6245,0 L3.3745,0 C1.5115,0 -0.0005,1.511 -0.0005,
                     3.375 L-0.0005,20.625 C-0.0005,22.489 1.5115,24 3.3745,24 L20.6245,24 C22.4885,
                     24 23.9995,22.489 23.9995,20.625 L23.9995,3.375 C23.9995,1.511 22.4885,
                     0 20.6245,0 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

ResourceNav.defaultProps = {
  color: '#9A9EAD',
};

export default ResourceNav;
