import * as React from 'react';
import Paper from '@mui/material//Paper';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    padding: '0 15px',
    borderLeft: '1px solid var(--color-green)',
  },
};

function InvalidCardInfoCard({ style }) {
  return (
    <Paper style={merge(styles.root, style)}>
      <div style={{ padding: '15px 0' }}>
        Your card information is unavailable.
      </div>
    </Paper>
  );
}

export default InvalidCardInfoCard;
