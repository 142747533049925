import { Map } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';

class PasswordValidator {
  constructor(userProfile) {
    const up = userProfile && userProfile.get ? userProfile : Map(userProfile);
    this.password = up.get('password');
    this.password_confirmation = up.get('password_confirmation');

    this.errors = new FieldErrors();
  }

  getErrors(...args) {
    return this.errors.getErrors(...args);
  }

  validate() {
    if (!this.passwordMatchesConfirmation()) {
      this.errors = this.errors.add(
        'password_confirmation',
        'validation.password.confirmation'
      );
    }
    if (!this.hasUppercaseAndNumber()) {
      this.errors = this.errors.add(
        'password',
        'validation.password.complexity'
      );
    }
    if (!this.meetsLengthRequirement()) {
      this.errors = this.errors.add('password', 'validation.password.length');
    }
    return this;
  }

  passwordsBothValid() {
    return this.passwordMatchesConfirmation() && this.passwordIsValid();
  }

  passwordMatchesConfirmation() {
    return this.password === this.password_confirmation;
  }

  passwordIsValid() {
    return this.meetsLengthRequirement() && this.hasUppercaseAndNumber();
  }

  meetsLengthRequirement() {
    return this.hasMinimumLength() && !this.isTooLong();
  }

  hasMinimumLength() {
    return !!this.password && this.password.length >= 8;
  }

  isTooLong() {
    return !!this.password && this.password.length > 100;
  }

  hasUppercaseAndNumber() {
    return (
      !!this.password &&
      !!(this.password.match(/.*\d.*/) && this.password.match(/.*[A-Z].*/))
    );
  }
}

export default PasswordValidator;
