import * as React from 'react';
import { Grid } from '@upperhand/playmaker';

import { smallScreen } from 'shared/utils/DOMUtils';

import EmptyState from './EmptyState.jsx';
import TableHeader from './TableHeader.jsx';
import ClientCard from './ClientCard.jsx';

function Table({ waitlistEntries, eventId }) {
  return (
    <div>
      <Grid item container spacing={1}>
        {/* Add Hidden from MUI 4.0 to Playmaker and use it instead. */}
        {/* 960 is equivilent to the md breakpoint in PM. */}
        {!smallScreen(959) && (
          <Grid item md={12}>
            <TableHeader />
          </Grid>
        )}
        {waitlistEntries.map(we => (
          <Grid item key={we.customer_user_id} xs={12}>
            <ClientCard waitlistEntry={we} eventId={eventId} />
          </Grid>
        ))}
        {waitlistEntries.isEmpty() && (
          <Grid item xs={12}>
            <EmptyState eventId={eventId} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Table;
