import React from 'react';

import AttendeeList from './AttendeeList.jsx';
import EmptyState from './EmptyState.jsx';

function Attendees({
  session,
  clientIds,
  registrationIds,
  filteredCheckedInClientIds,
  serverCheckedInClientIds,
}) {
  return clientIds.isEmpty() ? (
    <EmptyState />
  ) : (
    <AttendeeList
      session={session}
      clientsIds={clientIds}
      registrationIds={registrationIds}
      filteredCheckedInClientIds={filteredCheckedInClientIds}
      serverCheckedInClientIds={serverCheckedInClientIds}
    />
  );
}

export default Attendees;
