/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import altContainer from 'shared/hocs/altContainer.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import { ClientDataStore, RegistrationDataStore } from 'dataStores';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import Gift from 'shared/components/icons/Gift.jsx';

import { List, Map } from 'immutable';
import DollarIcon from 'shared/components/icons/Dollar.jsx';
import CheckInActions from '../actions';

const styles = {
  attendeeListItem: {
    cursor: 'pointer',
    minHeight: 56,
    minWidth: 100,
    justifyContent: 'space-between',
  },
  evenItem: {
    backgroundColor: uhColors.headerGrey,
  },
  UnpaidIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '2px',
    backgroundColor: 'var(--color-unpaid-red)',
    width: 17,
    height: 17,
  },
  DollarIcon: {
    width: '6px',
    height: 'auto',
  },
};

function AttendanceButtonWrapper({
  disabled,
  onClick,
  children,
  ...restProps
}) {
  return (
    <IconButton onClick={onClick} disabled={disabled} {...restProps}>
      {children}
    </IconButton>
  );
}

const noShowHandler = (e, eid, cuid) => {
  e.stopPropagation();
  CalendarActions.noShowHandler({
    sessionId: eid,
    customerUserId: cuid,
  });
};
function NoShowButton({ disabled, sessionId, clientId, session }) {
  const isNoShow = session?.attendance_map?.get('no_show').includes(clientId);
  return (
    <AttendanceButtonWrapper
      disabled={disabled}
      onClick={e => noShowHandler(e, sessionId, clientId)}
      style={{ marginLeft: '27px', padding: 0 }}
    >
      <RemoveCircleIcon
        sx={{
          color: isNoShow ? uhColors.moderateWarning : uhColors.lightGrey,
        }}
      />
    </AttendanceButtonWrapper>
  );
}

function AttendeeListItem({
  client,
  checkedIn,
  serverCheckedIn,
  style,
  session,
  paid,
}) {
  return (
    <FlexBoxCenter style={{ ...styles.attendeeListItem, ...style }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginLeft: '-48px',
        }}
      >
        <NoShowButton
          disabled={false}
          session={session}
          sessionId={session?.id}
          clientId={client.id}
        />
        <FlexBoxCenter
          onClick={() => CheckInActions.attendeeChecked(client.id)}
        >
          <Checkbox
            checked={checkedIn}
            style={{
              margin: 10,
              color: serverCheckedIn ? uhColors.charcoalBlack : 'initial',
            }}
          />
          <UserAvatar user={client} style={{ flex: '0 0 auto' }} />
          <div style={{ flex: '1 1 auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ margin: '0 5px' }}>{client.name()} </p>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                {client.isTodayBirthDay() && <Gift color="#4AD669" />}
                {client.get('is_new_user') && (
                  <div
                    style={{
                      color: 'var(--color-green)',
                      fontWeight: '500',
                      marginTop: '3px',
                    }}
                  >
                    <FormattedMessage
                      id={messageId('.new_user', __filenamespace)}
                    />
                  </div>
                )}
                {!paid && (
                  <div style={styles.UnpaidIcon}>
                    <DollarIcon style={styles.DollarIcon} color="white" />
                  </div>
                )}
              </Stack>
            </div>
          </div>
        </FlexBoxCenter>
      </div>
    </FlexBoxCenter>
  );
}

function AttendeeList({
  session,
  clientDataStore: { clients },
  registrationDataStore: { registrations },
  clientsIds,
  registrationIds,
  filteredCheckedInClientIds,
  serverCheckedInClientIds,
}) {
  const clientsList = registrationIds
    .reduce((cs, id) => {
      const registration = registrations.get(id);
      const client = clients.get(registration?.client_id);

      return client ? cs.push(Map({ client, paid: registration.paid })) : cs;
    }, List())
    .sort((a, b) =>
      a
        .getIn(['client', 'last_name'])
        .localeCompare(b.getIn(['client', 'last_name']))
    );
  return (
    <div style={styles.root}>
      <FlexBoxCenter
        style={styles.attendeeListItem}
        onClick={CheckInActions.allSelected}
      >
        <Checkbox
          style={{ marginLeft: 10 }}
          checked={clientsIds.equals(filteredCheckedInClientIds)}
        />
        <div style={{ flex: '1 1 auto', fontWeight: 'bold' }}>
          <FormattedMessage id={messageId('.select_all', __filenamespace)} />
        </div>
      </FlexBoxCenter>

      {clientsList.map((c, index) => {
        const client = c.get('client');
        return (
          <AttendeeListItem
            session={session}
            key={client.id}
            client={client}
            checkedIn={filteredCheckedInClientIds.includes(client.id)}
            serverCheckedIn={serverCheckedInClientIds.includes(client.id)}
            style={index % 2 ? styles.evenItem : {}}
            paid={c.get('paid')}
          />
        );
      })}
    </div>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
    registrationDataStore: RegistrationDataStore,
  },
})(AttendeeList);
