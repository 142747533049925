import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import ImageUpload from 'components/ImageUpload';

import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import ProductImageActions from 'retail/actions/ProductImageActions.js';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  container: {
    padding: '0 30px',
  },
};

function Image({ productImage, style }) {
  return (
    <FlexBoxCenter style={style}>
      <Paper style={{ marginRight: 12 }}>
        <img
          src={productImage.getAlternative('thumb_small')}
          alt={productImage.id}
          height="50"
          width="50"
          style={{ flex: '0 0 auto', objectFit: 'contain' }}
        />
      </Paper>
      <IconButton
        onClick={() => ProductImageActions.deleteRequested(productImage.id)}
      >
        <DeleteIcon sx={{ color: uhColors.iconLightGrey }} />
      </IconButton>
      <IconButton
        onClick={() =>
          ProductImageActions.featureImageSelected(productImage.id)
        }
      >
        <StarIcon
          sx={{
            color: productImage.featured ? '#FFDE00' : uhColors.iconLightGrey,
          }}
        />
      </IconButton>
      {productImage.featured && (
        <FormattedMessage id={messageId('.featured', __filenamespace)} />
      )}
    </FlexBoxCenter>
  );
}

function UploadingImage({ productImage }) {
  return (
    <FlexBoxCenter style={{ marginBottom: 10 }}>
      <Paper style={{ marginRight: 12, height: 50, width: 50 }}>
        {productImage.image && productImage.image.getSrc() && (
          <img
            src={productImage.getAlternative('thumb_small')}
            alt={productImage.id}
            crossOrigin="anonymous"
            height="50"
            width="50"
            style={{ flex: '0 0 auto', objectFit: 'contain' }}
          />
        )}
      </Paper>
      <FormattedMessage id={messageId('.uploading', __filenamespace)} />
    </FlexBoxCenter>
  );
}

function ProductImageEditingContent({
  productImageStore: {
    deleteConfirmationOpen,
    productImages,
    imageToUpload,
    isLoading,
  },
  intl,
}) {
  if (typeof productImages === 'undefined') {
    return null;
  }

  return (
    <div style={styles.container}>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 15 }}>
        <FormattedMessage id={messageId('.product_images', __filenamespace)} />
      </div>

      <SpinWhileLoading isLoading={isLoading}>
        {productImages
          .map(productImage => (
            <Image
              key={productImage.id}
              productImage={productImage}
              style={{ marginBottom: 10 }}
            />
          ))
          .toList()}
      </SpinWhileLoading>

      {imageToUpload && <UploadingImage productImage={imageToUpload} />}

      <ImageUpload
        cropAspect={1}
        uploadContainerHeight={64}
        uploadContainerWidth={300}
        onChange={ProductImageActions.imageSelected}
        disabled={!!imageToUpload}
        style={{ marginBottom: 30 }}
      />

      <ConfirmationDialog
        awaitingConfirmation={deleteConfirmationOpen}
        onCancel={ProductImageActions.deleteAborted}
        onConfirm={ProductImageActions.deleteConfirmed}
        title={t('.remove_image', intl, __filenamespace)}
      >
        <div style={{ marginBottom: 20 }}>
          <FormattedMessage id={messageId('.are_you_sure', __filenamespace)} />
        </div>
      </ConfirmationDialog>
    </div>
  );
}

function ProductImageEditing(props) {
  const { retailEditingStore } = props;

  if (!retailEditingStore.product.id) {
    return null;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ProductImageEditingContent {...props} />;
}

export default injectIntl(ProductImageEditing);
