import * as React from 'react';
import {
  Step,
  NextAction,
  ErrorMessage,
} from 'onboarding/components/AccountSetupPaysafe/steps/_Helpers.jsx';
import BusinessForm from 'onboarding/components/AccountSetupPaysafe/forms/_BusinessForm.jsx';
import BusinessStepActions from 'onboarding/actions/BusinessStepActions';

class BusinessStep extends React.Component {
  propsForField = fieldName => {
    const { fields } = this.props;
    return {
      fieldName,
      value: fields.getIn([fieldName, 'value'], ''),
      errorKey: fields.getIn([fieldName, 'error'], ''),
      onChange: BusinessStepActions.updateForm,
    };
  };

  render() {
    const { hasError, isLoading } = this.props;
    return (
      <Step>
        <BusinessForm {...this.props} propsForField={this.propsForField} />
        <ErrorMessage show={hasError} />
        <NextAction
          action={BusinessStepActions.submitBusinessForm}
          isLoading={isLoading}
          disabled={hasError || isLoading}
        />
      </Step>
    );
  }
}

export default BusinessStep;
