import * as React from 'react';
import { Route } from 'react-router-dom';

import MembershipEditing from 'memberships/components/MembershipEditing.jsx';
import MembershipViewing from 'memberships/components/MembershipViewing.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';
import { MembershipListPage } from 'routes/helpers/CustomersPageHelpers.jsx';
import Page from 'routes/Page.jsx';

import {
  isAdmin,
  isStaff,
  isStaffAdmin,
  isManager,
  isClient,
  isUnauthorized,
} from 'routes/helpers/routePermissions';

const membershipsRoutes = () => (
  <Route path=":customer_id">
    <Route
      path="memberships"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaff, isClient, isManager, isUnauthorized]}
        >
          <MembershipListPage />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="memberships/new"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <MembershipEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="memberships/:membershipID"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <MembershipViewing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="memberships/:membershipId/edit"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <MembershipEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
  </Route>
);

export default membershipsRoutes;
