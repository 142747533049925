import * as React from 'react';
import { injectIntl } from 'react-intl';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: '20',
    width: '100%',
    height: 'calc(100vh - 42px - 42px)',
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    textAlign: 'center',
  },
  LoaderContainer: {
    width: '25%',
    minWidth: '320px',
  },
  Message: {
    margin: '16px 0 0 0',
    color: uhColors.activeBlue,
  },
  Literal: `
  .sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }

  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
  }
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${uhColors.activeBlue};
    -webkit-animation: sk-foldCubeAngle 1s infinite linear both;
            animation: sk-foldCubeAngle 1s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);
  }
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);
  }
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);
  }
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.35s;
            animation-delay: 0.35s;
  }
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.65s;
            animation-delay: 0.65s;
  }
  @-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }

  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
  `,
};

const Loading = ({ intl, loading, message, loader, overlayStyle }) => {
  if (loading) {
    return (
      <div style={merge(styles.Loading, overlayStyle)}>
        <div style={styles.LoaderContainer}>
          {!loader && (
            <div className="sk-folding-cube">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{ __html: styles.Literal }}
              />
              <div className="sk-cube1 sk-cube" />
              <div className="sk-cube2 sk-cube" />
              <div className="sk-cube4 sk-cube" />
              <div className="sk-cube3 sk-cube" />
            </div>
          )}
          {loader}
          {message && (
            <div style={styles.Message}>{message.toString({ intl })}</div>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default injectIntl(Loading);
