import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FlexBox, FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    flexDirection: 'column',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    marginTop: 0,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: '-45px',
    marginRight: '-45px',
  },
  column: {
    flex: '0 1 85.5rem',
    margin: `${LEFT_MARGIN_PIXELS}px 45px 0 45px`,
    maxWidth: '100%',
  },
};

function DisplayText({ value }) {
  if (value === 'manager') {
    return (
      <FlexBoxJustifyCenter style={styles.root}>
        <FlexBox style={merge(styles.row, { justifyContent: 'center' })}>
          <div style={styles.column}>
            <div style={{ fontSize: '1.5em' }}>
              <FormattedMessage
                id={messageId('.parent_header', __filenamespace)}
              />
              <div style={{ fontSize: 16, marginTop: 10 }}>
                <FormattedMessage
                  id={messageId('.parent_message', __filenamespace)}
                />
              </div>
            </div>
          </div>
        </FlexBox>
      </FlexBoxJustifyCenter>
    );
  }
  if (value === 'participant') {
    return (
      <FlexBoxJustifyCenter style={styles.root}>
        <FlexBox style={merge(styles.row, { justifyContent: 'center' })}>
          <div style={merge(styles.column, { fontSize: '1.5em' })}>
            <FormattedMessage
              id={messageId('.participant_header', __filenamespace)}
            />
          </div>
        </FlexBox>
      </FlexBoxJustifyCenter>
    );
  }
  return null;
}

DisplayText.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DisplayText;
