import * as React from 'react';
import { Expander, Typography } from '@upperhand/playmaker';
import { List } from 'immutable';

import altContainer from 'shared/hocs/altContainer.jsx';
import Resource from 'shared/records/Resource.js';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import { ResourceDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import { withStyles } from '@material-ui/styles';
import AddResource from './AddResource.jsx';
import ResourceListCard from './ResourceListCard.jsx';
import DetailsActions from '../actions.js';

const styles = {
  container: {
    margin: 10,
  },
  resourceIcon: {
    width: 18,
    height: 18,
  },
  headerTypographyRoot: {
    maxWidth: '100px',
  },
};

function Resources({
  expanded,
  intl,
  isAddingResource,
  resourceDataStore: { records: resources },
  resourceIds = List(),
  selectableResources,
  searchedResourceName,
  teamResources,
  isTeamEvent,
  classes,
}) {
  const canRemove = resourceIds.size > 1;
  if (isTeamEvent) {
    return (
      <Expander
        classes={{
          headerTypographyRoot: classes.headerTypographyRoot,
        }}
        header={
          <Typography variant="subtitle2">
            {t('.team_resources', intl, __filenamespace, {
              count: teamResources.size,
            })}
          </Typography>
        }
        expanded={expanded}
        behavior="controlled"
        onChange={DetailsActions.resourcesChangedTeam}
        contentOffset={false}
        arrowRight
      >
        {teamResources.map(resource => (
          <ResourceListCard
            key={resource.id}
            name={resource.name}
            avatar={
              <ResourceIcon
                style={styles.resourceIcon}
                color={uhColors.defaultIcon}
              />
            }
            intl={intl}
            isTeamEvent={isTeamEvent}
          />
        ))}
      </Expander>
    );
  }

  return (
    <Expander
      header={
        <Typography variant="subtitle2">
          {t('.resources', intl, __filenamespace, {
            count: resourceIds.size,
          })}
        </Typography>
      }
      secondaryAction={DetailsActions.initResourceAdd}
      secondaryActionIcon="add"
      expanded={expanded}
      behavior="controlled"
      onChange={DetailsActions.resourcesChanged}
      contentOffset={false}
    >
      {isAddingResource && (
        <div style={styles.container}>
          <AddResource
            intl={intl}
            selectableResources={selectableResources}
            searchedResourceName={searchedResourceName}
          />
        </div>
      )}
      {resourceIds.map(r => {
        const resource = resources.get(r, new Resource());
        const onRemove = canRemove
          ? () => DetailsActions.removeResource(resource.id)
          : undefined;

        return (
          <ResourceListCard
            key={resource.id}
            name={resource.name}
            avatar={
              <ResourceIcon
                style={styles.resourceIcon}
                color={uhColors.defaultIcon}
              />
            }
            intl={intl}
            onRemove={onRemove}
          />
        );
      })}
    </Expander>
  );
}

export default altContainer({
  stores: {
    resourceDataStore: ResourceDataStore,
  },
})(withStyles(styles)(Resources));
