import { DefaultSourceActions } from './DefaultSourceActionList';

class RegistrationActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'reschedule',
      'rescheduleSuccess',
      'rescheduleError',
      'remind',
      'remindSuccess',
      'remindError',
      'updateRsvpStatus',
      'updateRsvpStatusSuccess',
      'updateRsvpStatusError'
    );
  }
}

export default alt.createActions(RegistrationActions);
