import debounce from 'lodash.debounce';
import { List } from 'immutable';

import { ClientSource, TeamSource } from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import FutureEventSessionsActions from 'containers/events/admin/schedule/future_event_sessions/Actions';
import CompletedEventSessionsActions from 'containers/events/admin/schedule/completed_event_sessions/Actions';
import ClientsActions from './actions';

class ClientsStore extends UpperHandStore {
  constructor() {
    super();

    this.eventId = null;
    this.clientIds = List();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.search = '';
    this.filtersPresent = false;
    this.loading = false;
    this.participantIds = List();
    this.team = false;
    this.clientIdToRemove = null;

    this.debouncedListClients = debounce(() => {
      this.listClients();
      this.emitChange();
    }, 600);

    this.bindListeners({
      mounted: ClientsActions.mounted,
      clientsListSuccess: ClientsActions.clientListSuccess,
      clientsListError: ClientsActions.clientListError,
      pageSelected: ClientsActions.pageSelected,
      searchUpdated: ClientsActions.searchUpdated,
      handleRemoveClient: ClientsActions.removeClient,
      handleRemoveClientSuccess: ClientsActions.removeClientSuccess,
      handleRemoveClientError: ClientsActions.removeClientError,
    });
  }

  mounted({ eventId, ids = List(), team = false }) {
    this.eventId = eventId;
    this.participantIds = ids;
    this.team = team;
    this.listClients();
  }

  listClients() {
    const params = {
      page: this.page,
      per_page: this.perPage,
      event_ids: [this.eventId],
      team: this.team,
    };
    if (this.participantIds.size > 0) {
      params.ids = this.participantIds.toJS();
    }

    if (this.search) {
      params.search = this.search;
    }

    this.loading = true;

    ClientSource.list({
      params,
      success: ClientsActions.clientListSuccess,
      error: ClientsActions.clientListError,
    });
  }

  // eslint-disable-next-line no-unused-vars
  clientsListSuccess({ clients, page, perPage, totalCount }) {
    this.clientIds = clients.map(c => c.id);
    this.totalCount = totalCount;
    this.loading = false;
    this.filtersPresent = this.search !== '';
  }

  clientsListError() {
    this.loading = false;
    // eslint-disable-next-line prefer-rest-params
    this.notifyError('error listing clients', arguments);
  }

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listClients();
  }

  searchUpdated(newSearch) {
    this.search = newSearch;
    this.page = 1;

    this.debouncedListClients();
  }

  handleRemoveClient({ clientId }) {
    this.clientIdToRemove = clientId;
    TeamSource.removeClient({
      params: { event_id: this.eventId, client_id: this.clientIdToRemove },
      success: ClientsActions.removeClientSuccess,
      error: ClientsActions.removeClientError,
    });
  }

  handleRemoveClientSuccess() {
    this.clientIds = this.clientIds.filterNot(
      clientId => clientId === this.clientIdToRemove
    );
    this.clientIdToRemove = null;
    MessageWindowActions.addMessage.defer('Client removed successfully.');
    FutureEventSessionsActions.mounted.defer({
      eventId: this.eventId,
      isTeamEvent: true,
    });
    CompletedEventSessionsActions.mounted.defer({
      eventId: this.eventId,
      isTeamEvent: true,
    });
  }

  handleRemoveClientError(...args) {
    this.clientIdToRemove = null;
    this.notifyError('Error occured while removing client', args);
  }
}

export default alt.createStore(ClientsStore, 'ClientsStore');
