import { Record } from 'immutable';

class PerSessionPricingOption extends Record({
  description: '',
  price: 0,
  quantity: 0,
  uuid: null,
}) {}

export default PerSessionPricingOption;
