import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Settings(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.5C9.51525 16.5 7.5 14.4847 7.5 12C7.5 9.51525 9.51525 7.5 12 7.5C14.4847 7.5 16.5 9.51525 16.5 12C16.5 14.4847 14.4847 16.5 12 16.5ZM22.7467 10.2915L20.7593 9.96C20.5298 8.97375 20.1397 8.052 19.6162 7.22025L20.7232 5.67075C21.1447 5.079 21.078 4.26975 20.565 3.756L20.2432 3.435C19.7302 2.922 18.921 2.8545 18.3293 3.27675L16.7798 4.38375C15.948 3.86025 15.0262 3.4695 14.0407 3.24075L13.7085 1.25325C13.5885 0.53025 12.9622 0 12.2295 0H11.7705C11.0378 0 10.4115 0.53025 10.2915 1.25325L9.96 3.24075C8.97375 3.4695 8.052 3.86025 7.22025 4.38375L5.67075 3.27675C5.079 2.8545 4.26975 2.922 3.756 3.435L3.435 3.756C2.922 4.26975 2.8545 5.079 3.27675 5.67075L4.38375 7.22025C3.86025 8.052 3.4695 8.97375 3.24075 9.96L1.25325 10.2915C0.53025 10.4115 0 11.0378 0 11.7705V12.2295C0 12.9622 0.53025 13.5885 1.25325 13.7085L3.24075 14.0407C3.4695 15.0262 3.86025 15.948 4.38375 16.7798L3.27675 18.3293C2.8545 18.921 2.922 19.7302 3.435 20.2432L3.756 20.565C4.26975 21.078 5.079 21.1447 5.67075 20.7232L7.22025 19.6162C8.052 20.1397 8.97375 20.5298 9.96 20.7593L10.2915 22.7467C10.4115 23.4697 11.0378 24 11.7705 24H12.2295C12.9622 24 13.5885 23.4697 13.7085 22.7467L14.0407 20.7593C15.0262 20.5298 15.948 20.1397 16.7798 19.6162L18.3293 20.7232C18.921 21.1447 19.7302 21.078 20.2432 20.565L20.565 20.2432C21.078 19.7302 21.1447 18.921 20.7232 18.3293L19.6162 16.7798C20.1397 15.948 20.5298 15.0262 20.7593 14.0407L22.7467 13.7085C23.4697 13.5885 24 12.9622 24 12.2295V11.7705C24 11.0378 23.4697 10.4115 22.7467 10.2915Z"
        fill="#9A9EAD"
      />
    </SvgIcon>
  );
}

Settings.defaultProps = {
  color: '#9A9EAD',
};

export default Settings;
