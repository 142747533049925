/**
 * @description service object for interacting with Stripe using their JS SDK
 *              callback-based since Stripe SDK uses callbacks not Promises/AsAw
 *              SDK is hosted by Stripe and loaded in _stripe.slim.html
 *              "Stripe" has been added to .eslintrc as a Global¡
 * @type {*}
 */
const StripeService = {};

/**
 * @description get card token from global Stripe JS instance
 * @public
 * @param payload
 * @param callback {function}
 * @returns {*}
 */
function tokenize(payload, callback) {
  return Stripe.card.createToken(payload, callback);
}

/**
 * @description use Stripe to validate/check PCI data
 *              PaymentCard Record should be validated before this is called
 * @public
 * @param cardRecord {PaymentCard}
 */
function validateCard(cardRecord) {
  if (!Stripe.card.validateCardNumber(cardRecord.number)) {
    throw new Error('Invalid card number.');
  }

  if (!Stripe.card.validateExpiry(cardRecord.exp)) {
    throw new Error('Invalid expiration date.');
  }

  if (!Stripe.card.validateCVC(cardRecord.cvc)) {
    throw new Error('Invalid security code.');
  }

  return true;
}

StripeService.tokenize = tokenize;
StripeService.validateCard = validateCard;

export default StripeService;
