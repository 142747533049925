import RetailCategory from 'shared/records/RetailCategory.js';
import RetailCategoryEditingActions from 'shared/actions/RetailCategoryEditingActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import RetailCategorySelectorUIActions from 'shared/actions/RetailCategorySelectorUIActions.jsx';
import UpperHandRecordStore from 'shared/stores/UpperHandRecordStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class RetailCategoryEditingStore extends UpperHandRecordStore {
  constructor() {
    super();

    this.bindListeners({
      handleUpdateStore: RetailCategoryEditingActions.UPDATE_STORE,
      handleCreateOrUpdate: RetailCategoryEditingActions.CREATE_OR_UPDATE,
      handleError: RetailCategoryEditingActions.ERROR,
      handleInitialLoad: RetailCategoryEditingActions.INITIAL_LOAD,
      handleOpenDialog: RetailCategorySelectorUIActions.OPEN_DIALOG,
      handleSaveSuccess: RetailCategoryEditingActions.CREATE_OR_UPDATE_SUCCESS,
    });
  }

  handleUpdateStore(data) {
    super.handleUpdateStore(data);
  }

  handleCreateOrUpdate(opts) {
    const params = {
      url: this.url(),
      data: this.payload(),
      success: {
        action: RetailCategoryEditingActions.createOrUpdateSuccess,
        args: [opts || {}],
      },
      error: RetailCategoryEditingActions.error,
    };

    return this.record.id ? uhApiClient.put(params) : uhApiClient.post(params);
  }

  handleSaveSuccess([attributes, args]) {
    this.waitFor(RetailCategoryListingStore);
    this.reset();
    if (args && args.callback) {
      setTimeout(() => args.callback(attributes.id));
    }
  }

  handleError(args) {
    this.notifyError('error while creating or updating retail category', args);
  }

  handleOpenDialog({ newRetailCategory = false, retailCategoryId = null }) {
    if (newRetailCategory) {
      this.reset();
    } else if (retailCategoryId) {
      this.load(retailCategoryId);
    }
  }

  handleInitialLoad(retailCategoryId) {
    this.load(retailCategoryId);
  }

  /*
   * PRIVATE HELPERS
   */

  newRecord(options) {
    return new RetailCategory(options);
  }

  reset() {
    this.record = this.newRecord();
  }

  load(retailCategoryId) {
    const retailCategory =
      RetailCategoryListingStore.getState().findById(retailCategoryId) ||
      new RetailCategory();
    this.record = retailCategory;
  }

  payload() {
    const payload = this.record.toJSON();

    if (!payload.id) {
      delete payload.id;
    }
    delete payload.created_at;
    delete payload.updated_at;

    return JSON.stringify({ attributes: payload });
  }

  url() {
    if (this.record.id) {
      return `retail_categories/${this.record.id}`;
    }
    return 'retail_categories';
  }
}

export default alt.createStore(
  RetailCategoryEditingStore,
  'RetailCategoryEditingStore'
);
