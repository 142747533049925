/* eslint-disable camelcase */
import moment from 'moment-timezone';
import { List, OrderedMap, Set } from 'immutable';
import MembershipDiscountStore from 'event_mgmt/display/stores/MembershipDiscountStore.js';
import RegistrationPackage from 'shared/records/RegistrationPackage';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import TentativeRegistration from 'shared/records/TentativeRegistration';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { SessionDataStore } from 'dataStores';
import MembershipEventDiscountCalculator from '../records/MembershipEventDiscountCalculator.js';

class RegistrationPackageSchedulingStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
  }

  reset() {
    this.registrationPackage = new RegistrationPackage({ quantity: 0 });
    this.selectedClientId = null;
    this.changed = false;
    this.packageIsValid = false;
    this.isSchedulingNow = false;

    this.event = null;
    this.eventSchedule = null;
    this.paymentPlanDescription = null;
    this.packagePricingDescription = null;
    this.automationsLoaded = false;
    this.totalPrice = 0;

    this.orderItemId = null;
    this.purchasedRegistrations = List();

    this.allProfiles = List();
    this.registeredAthleteIds = Set();
    this.tentativeAthleteIds = Set();

    this.allowSingleSessionPurchase = false;
    this.rawSessionAvailability = List();
    this.sessionAvailability = OrderedMap();
    this.singleSessionRegistrationCount = 0;
    this.allDayRegistrationCount = 0;
    this.startDateRepeat = '';
    this.endDateRepeat = '';
    this.isSessionAvailable = session =>
      this.sessionAvailability.get(
        `${session.get('date')}-${session.get('start_time')}-${session.get(
          'end_time'
        )}`,
        0
      ) > 0;
  }

  resetPackage() {
    this.registrationPackage = new RegistrationPackage({
      event_id: this.event ? this.event.id : null,
      quantity: 0,
    });
    this.changed = false;
    this.packageIsValid = false;

    this.orderItemId = null;
    this.selectedClientId = null;
    this.allRegisteredAthleteIds = Set();
  }

  /* FIXED SCHEDULE */

  addProfileFixedSchedule(clientId) {
    const { sessions } = SessionDataStore.getState();
    const tentativeDetails = this.event.session_ids
      .filterNot(id => sessions.get(id)?.isCancelled())
      .map(
        id => new TentativeRegistration({ session_id: id, client_id: clientId })
      );

    const newDetails = this.registrationPackage.tentative_details
      .filterNot(d => d.client_id === clientId)
      .concat(tentativeDetails);

    this.registrationPackage = this.registrationPackage.merge({
      client_ids: this.registrationPackage.client_ids.add(clientId),
      quantity: newDetails.size,
      tentative_details: newDetails,
    });

    this.changed = true;

    this.updateCounts();
    this.setTotalPrice();
    this.validatePackage();
  }

  removeProfileFixedSchedule(clientId) {
    const newDetails = this.registrationPackage.tentative_details.filterNot(
      d => d.client_id === clientId
    );

    this.registrationPackage = this.registrationPackage.merge({
      client_ids: this.registrationPackage.client_ids.delete(clientId),
      quantity: newDetails.size,
      tentative_details: newDetails,
    });

    this.changed = true;

    this.setTotalPrice();
    this.validatePackage();
  }

  selectPaymentPlan(uuid) {
    if (
      this.paymentPlanDescription &&
      this.registrationPackage.automation_option_uuid !== uuid
    ) {
      this.registrationPackage = this.registrationPackage
        .set('automation_template_description', this.paymentPlanDescription)
        .set(
          'automation_template_description_id',
          this.paymentPlanDescription.id
        )
        .set('automation_option_uuid', uuid);
      this.changed = true;
    }

    this.setTotalPrice();
    this.validatePackage();
  }

  selectDefaultPackage(size) {
    const newPkg = this.registrationPackage.setSize(size);

    if (this.registrationPackage !== newPkg) {
      this.registrationPackage = newPkg;
      this.changed = true;
    }

    this.setTotalPrice();
    this.validatePackage();
  }

  /* SINGLE SESSION PURCHASE */

  addAttendeeSingleSession(clientId) {
    if (this.areAllSessionsAvailable()) {
      this.addProfileFixedSchedule(clientId);
    }
  }

  registerSession([clientId, sessionId]) {
    if (this.sessionAvailability.get(sessionId) <= 0) {
      return;
    }

    const newDetails = this.registrationPackage.tentative_details
      .filterNot(d => d.client_id === clientId && d.session_id === sessionId)
      .push(
        new TentativeRegistration({
          client_id: clientId,
          session_id: sessionId,
        })
      );

    this.registrationPackage = this.registrationPackage.merge({
      client_ids: newDetails.map(r => r.client_id).toSet(),
      quantity: newDetails.size,
      tentative_details: newDetails,
    });

    this.changed = true;

    this.updateSessionAvailability();
    this.updateCounts();
    this.setTotalPrice();
    this.validatePackage();
  }

  unregisterSession([clientId, sessionId]) {
    const newDetails = this.registrationPackage.tentative_details.filterNot(
      d => d.client_id === clientId && d.session_id === sessionId
    );

    this.registrationPackage = this.registrationPackage.merge({
      client_ids: newDetails.map(r => r.client_id).toSet(),
      quantity: newDetails.size,
      tentative_details: newDetails,
    });

    this.changed = true;

    this.updateSessionAvailability();
    this.updateCounts();
    this.setTotalPrice();
    this.validatePackage();
  }

  areAllSessionsAvailable() {
    return this.sessionAvailability.every(a => a > 0);
  }

  registerAllDays(clientId) {
    this.addProfileFixedSchedule(clientId);

    this.updateSessionAvailability();
    this.updateCounts();
    this.setTotalPrice();
  }

  unregisterAllDays(clientId) {
    const newDetails = this.registrationPackage.tentative_details.filterNot(
      d => d.client_id === clientId
    );

    this.registrationPackage = this.registrationPackage.merge({
      quantity: newDetails.size,
      tentative_details: newDetails,
    });

    this.changed = true;

    this.updateSessionAvailability();
    this.updateCounts();
    this.setTotalPrice();
    this.validatePackage();
  }

  setupSingleSessionPurchase() {
    this.updateSessionAvailability();
    this.updateCounts();
    this.setTotalPrice();
  }

  updateSessionAvailability() {
    if (this.rawSessionAvailability) {
      this.sessionAvailability = OrderedMap(
        this.rawSessionAvailability.map(session => {
          const sessionId = session.get('session_id');
          const spotsRemaining = session.get('spots_remaining');

          const sessionCount = this.registrationPackage.tentative_details.count(
            d => d.session_id === sessionId
          );

          return [sessionId, spotsRemaining - sessionCount];
        })
      );
    }
  }

  updateCounts() {
    const sessionCounts = this.registrationPackage.tentative_details
      .groupBy(d => d.client_id)
      .map(d => d.size);

    this.singleSessionRegistrationCount = sessionCounts
      .filter(n => n < this.event.session_ids.size)
      .reduce((sum, n) => sum + n, 0);

    this.allDayRegistrationCount = sessionCounts.count(
      n => n === this.event.session_ids.size
    );
  }

  /* OPEN BOOKING */

  selectAutomatedPackage(uuid) {
    if (
      this.packagePricingDescription &&
      this.registrationPackage.automation_option_uuid !== uuid
    ) {
      const selectedPackage = this.packagePricingDescription.getOption(uuid);

      this.registrationPackage = this.registrationPackage
        .setSize(selectedPackage.quantity, this.selectedClientId)
        .set('automation_template_description', this.packagePricingDescription)
        .set(
          'automation_template_description_id',
          this.packagePricingDescription.id
        )
        .set('automation_option_uuid', uuid);
      this.changed = true;
      this.validatePackage();
    }
  }

  setRegistrationStaff([staffId, registrationIndex]) {
    const newValue = staffId === null ? Set() : Set([staffId]);
    this.registrationPackage = this.registrationPackage.updateIn(
      ['tentative_details', registrationIndex],
      (r = new TentativeRegistration()) => r.set('staff_ids', newValue)
    );
  }

  setRepeatRegistrationStaff(staffId, registrationIndex) {
    const newValue = staffId === null ? Set() : Set([staffId]);
    this.registrationPackage = this.registrationPackage.updateIn(
      ['tentative_details', registrationIndex],
      (r = new TentativeRegistration()) => r.set('staff_ids', newValue)
    );
  }

  setRepeatRegistrationClient(clientId, registrationIndex) {
    this.registrationPackage = this.registrationPackage.updateIn(
      ['tentative_details', registrationIndex],
      (r = new TentativeRegistration()) => r.set('client_id', clientId)
    );
  }

  setRegistrationDate([key, date, registrationIndex]) {
    if (key === 'startDateRepeat' || key === 'endDateRepeat') {
      this[key] = date;
    } else {
      this.registrationPackage = this.registrationPackage.updateIn(
        ['tentative_details', registrationIndex],
        (r = new TentativeRegistration()) =>
          r.set('starts_at', moment(date).tz(customerTZ(), true))
      );
    }
  }

  setRegistrationRepeatDate(date, registrationIndex) {
    this.registrationPackage = this.registrationPackage.updateIn(
      ['tentative_details', registrationIndex],
      (r = new TentativeRegistration()) =>
        r.set('starts_at', moment(date).tz(customerTZ(), true))
    );
  }

  setRegistrationRepeatSchedule(registrationIndex) {
    this.registrationPackage = this.registrationPackage.updateIn(
      ['tentative_details', registrationIndex],
      (r = new TentativeRegistration()) =>
        r.set('schedule_id', this.eventSchedule.id) // remove + registrationIndex
    );
  }

  updateTime(key, time, count, activeDates, registrationIndex) {
    const newTime = moment.tz(time, 'HH:mm', customerTZ());

    const currentTime = this.registrationPackage.getIn([
      'tentative_details',
      registrationIndex,
      'starts_at',
    ]);

    const startsAt = currentTime
      ?.clone()
      .hours(newTime.hours())
      .minutes(newTime.minutes());

    const endsAt = startsAt
      ?.clone()
      .add(this.eventSchedule.duration, 'seconds');

    this.registrationPackage = this.registrationPackage.updateIn(
      ['tentative_details', registrationIndex],
      (r = new TentativeRegistration()) =>
        r.merge({
          starts_at: startsAt,
          ends_at: endsAt,
        })
    );
  }

  async executeRepeatSchedule({
    date,
    index,
    client_id,
    staff_id,
    key,
    time,
    count,
    activeDates,
  }) {
    await this.setRegistrationRepeatDate(date, index, client_id, staff_id);
    await this.setRepeatRegistrationStaff(staff_id, index);
    await this.setRepeatRegistrationClient(client_id, index);
    await this.setRegistrationRepeatSchedule(index);
    await this.updateTime(key, time, count, activeDates, index);
  }

  async executeRepeatedScheduleTasks({
    key,
    time,
    count,
    activeDates,
    client_id,
    staff_id,
    callBack,
    schedulingNow,
  }) {
    await Promise.all(
      activeDates.map((date, index) =>
        this.executeRepeatSchedule({
          key,
          time,
          count,
          client_id,
          staff_id,
          date,
          index,
          activeDates,
          schedulingNow,
        })
      )
    );
    callBack('repeatedScheduling');
  }

  setRegistrationTime([
    key,
    time,
    count,
    activeDates,
    client_id,
    staff_id,
    callBack,
    schedulingNow,
    registrationIndex,
  ]) {
    if (key === 'repeatFunctionsCall') {
      this.executeRepeatedScheduleTasks({
        key,
        time,
        count,
        activeDates,
        client_id,
        staff_id,
        callBack,
        schedulingNow,
      });
    } else if (key === 'startTime') {
      this.updateTime(key, time, count, activeDates, registrationIndex);
    }
  }

  setRegistrationAthlete(customerUserId) {
    this.selectedClientId = customerUserId;
    this.registrationPackage = this.registrationPackage
      .set('client_ids', Set([customerUserId]))
      .updateIn(['tentative_details'], (details = List()) =>
        details.map(d => (d.client_id ? d.set('client_id', customerUserId) : d))
      );
  }

  beginScheduling() {
    this.isSchedulingNow = true;
  }

  stopScheduling() {
    // here we work for conflict scheduling
    this.isSchedulingNow = false;
    this.registrationPackage = this.registrationPackage.deleteIn([
      'tentative_details',
      this.registrationPackage.scheduledRegistrationCount,
    ]);
  }

  bookRegistration(registrationIndex) {
    if (registrationIndex.length > 1) {
      this.isSchedulingNow = true;
    } else {
      this.isSchedulingNow = false;
      this.registrationPackage = this.registrationPackage.updateIn(
        ['tentative_details', registrationIndex],
        (r = new TentativeRegistration()) =>
          r.merge({
            client_id: this.selectedClientId,
            schedule_id: this.eventSchedule.id,
          })
      );
    }
    this.changed = true;
  }

  cancelRegistration(registrationIndex) {
    this.registrationPackage = this.registrationPackage.deleteIn([
      'tentative_details',
      registrationIndex,
    ]);
    this.changed = true;
    this.validatePackage();
  }

  setTotalPrice() {
    this.waitFor(MembershipDiscountStore);
    if (!this.event) {
      return;
    }
    let nonDiscountedPrice;

    if (this.event.isOpenBooking()) {
      nonDiscountedPrice = 0;
    } else if (this.event.allow_single_session_purchase) {
      nonDiscountedPrice = this.sspPrice();
    } else if (this.paymentPlanDescription) {
      nonDiscountedPrice = this.recurringPaymentPrice();
    } else {
      nonDiscountedPrice = this.fixedSchedulePrice();
    }
    const discount = new MembershipEventDiscountCalculator({
      registrationPackage: this.registrationPackage,
      event: this.event,
      pricingAutomation:
        this.packagePricingDescription || this.paymentPlanDescription,
      eventDiscounts: MembershipDiscountStore.getState().findByEventTypeId(
        this.event.event_type && this.event.event_type.id
      ),
      customerUsers: this.allProfiles,
      totalPrice: nonDiscountedPrice,
      singleSessionCount: this.singleSessionRegistrationCount,
      fullSessionCount: this.allDayRegistrationCount,
    }).discount();

    this.totalPrice = nonDiscountedPrice - discount;
  }

  validatePackage() {
    if (!this.eventSchedule || !this.automationsLoaded) {
      this.packageIsValid = false;
      return true; /* returning true causes the store to emit a change */
    }

    const automation = this.event.isOpenBooking()
      ? this.packagePricingDescription
      : this.paymentPlanDescription;
    const hasSelection =
      !!automation &&
      this.registrationPackage.get('automation_option_uuid') &&
      automation.hasOption(
        this.registrationPackage.get('automation_option_uuid')
      ) &&
      this.registrationPackage.get('automation_template_description_id') ===
        automation.id;
    this.packageIsValid = this.event.isTeamEvent()
      ? this.registrationPackage.client_ids.size > 0 &&
        (!automation || hasSelection)
      : this.registrationPackage.quantity > 0 && (!automation || hasSelection);
    return undefined;
  }

  fixedSchedulePrice() {
    if (
      this.event.hasScheduledPriceDate() &&
      !this.event.hasPricingScheduleDisabled() &&
      this.event.get('scheduled_price')
    ) {
      return (
        this.registrationPackage.client_ids.size *
        this.event.get('scheduled_price') *
        100
      );
    }
    return this.registrationPackage.client_ids.size * this.event.price * 100;
  }

  sspPrice() {
    const isTiersEnabled =
      this.event.isSSPTurnedOn() && this.event.hasPricingTiers();

    if (isTiersEnabled) {
      const groupedPackages =
        this.registrationPackage.tentative_details.groupBy(td => td.client_id);

      const sspPrice = groupedPackages.reduce((sum, details) => {
        const count = details.size;
        const isAllSelected = this.event.session_ids.size === count;
        const clientSspPrice = isAllSelected
          ? this.event.single_session_price
          : this.event.getTieredPrice(count, this.event.single_session_price);

        const price = isAllSelected ? this.event.price : clientSspPrice * count;

        return sum + price;
      }, 0);

      return sspPrice * 100;
    }

    return (
      this.event.price * 100 * this.allDayRegistrationCount +
      this.event.single_session_price *
        100 *
        this.singleSessionRegistrationCount
    );
  }

  recurringPaymentPrice() {
    const plan = this.paymentPlanDescription.getPlan(
      this.registrationPackage.automation_option_uuid
    );

    if (plan) {
      return this.registrationPackage.client_ids.size * plan.totalPrice();
    }
    return -1;
  }
}

export default RegistrationPackageSchedulingStore;
