import Bowser from 'bowser';
import { Capacitor } from '@capacitor/core';

// check original browser file config/initializers/001_browsers.rb
// see Browser documentation for alias names.
const supportedBrowsers = {
  chrome: '>=43',
  firefox: '>=45',
  opera: '>=43',
  edge: '>=13',
  safari: '>=9',
  android: '>=4',
};

/**
 * Wraps our userAgent library. Ensures we don't re-parse userAgent every time we need it.
 * private exposed through our "getter"
 */
const userAgent = Bowser.getParser(window.navigator.userAgent);

/**
 * returns our parsed userAgent information object
 */
export const userAgentInfo = () => userAgent;

/**
 * returns true if current browser is supported by upperhand
 */
export const browserCompatible = () => {
  if (userAgent.satisfies(supportedBrowsers)) {
    return true;
  }

  // Bowser doesn't do the greatest job at parsing the UA string on iOS. Specifically it fails to
  // set the Safari version string which causes the satisfies check above to fail. Because the
  // version of Safari is tied to the os version we fall back to checking the OS version for iOS.
  const { name = '', version = '' } = userAgent.getOS();
  return name === 'iOS' && version.split('.')[0] > 9;
};

/**
 * returns true if current browser is Not supported by upperhand
 */
export const browserIncompatible = () => !browserCompatible();

/**
 * Checks if user runs app in Capacitor environment
 */
export const isNative = () => Capacitor.isNativePlatform();
