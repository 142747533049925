import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import { Grid, Typography, Spinner, Button, Icon } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import EmptyState from 'containers/clientProfile/components/AccountCredits/EmptyState.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import AccountCreditsActions from 'containers/clientProfile/components/AccountCredits/Actions';

import { ClientDataStore, AccountCreditsDataStore } from 'dataStores';
import AccountCreditStore, {
  CREDIT_TYPES,
} from 'containers/clientProfile/components/AccountCredits/Store';

export const CreditItem = injectIntl(
  ({
    intl,
    credit,
    isClickable = true,
    className = 'account-credits-list',
  }) => {
    const creditType = credit.get('credit_type');
    const isPositiveCredit =
      creditType === CREDIT_TYPES.store_credit ||
      creditType === CREDIT_TYPES.gift_card;
    const isNegativeCredit = !isPositiveCredit;

    return (
      <Grid container className={`${className}__list-item`}>
        <Grid item container alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant="caption">
              {t(`.${creditType}`, intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              className={clsx(
                `${className}__credit-amount`,
                `${className}__${creditType}`
              )}
            >
              {isPositiveCredit && <span>+</span>}
              {isNegativeCredit && <span>-</span>}
              <FormattedCurrency fromCents value={credit.get('amount')} />
              {isClickable && <Icon name="arrowRight" />}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>
            {moment(credit.get('created_at')).format('MM/DD/YYYY')}
            &nbsp;&bull;&nbsp;
            {moment(credit.get('created_at')).format('h:mma')}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

function ListStep({
  intl,
  clientDataStore,
  accountCreditStore,
  accountCreditsDataStore,
}) {
  const { clients } = clientDataStore;
  const { accountCredits } = accountCreditsDataStore;
  const {
    creditsAmount,
    clientId,
    creditsIds,
    creditsLoading,
    hasMore,
    moreCreditsLoading,
  } = accountCreditStore;
  const client = clients.get(clientId, null);
  const creditsListEmpty = creditsIds.size === 0;
  const showClientInfo = client && !creditsListEmpty;

  return (
    <div className="account-credits-list">
      {!creditsListEmpty && (
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item>
            <Typography variant="body1" className="account-credits-list__title">
              {t('.title', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" className="account-credits-list__amount">
              <FormattedCurrency fromCents value={creditsAmount} />
            </Typography>
          </Grid>
        </Grid>
      )}

      {showClientInfo && <ClientDetails client={client} />}

      {!creditsListEmpty && (
        <Typography
          variant="drawerTitle"
          className="account-credits-list__list-title"
        >
          {t('.list_title', intl, __filenamespace)}
        </Typography>
      )}

      {creditsLoading && (
        <div className="account-credits-list__loading-container">
          <Spinner />
        </div>
      )}

      {creditsListEmpty && !creditsLoading && (
        <div className="account-credits-list__loading-container">
          <EmptyState />
        </div>
      )}

      {!creditsListEmpty && (
        <>
          {creditsIds.map(id => (
            <div
              key={id}
              role="presentation"
              onClick={() =>
                AccountCreditsActions.toggleDetails(accountCredits.get(id))
              }
            >
              <CreditItem key={id} credit={accountCredits.get(id)} />
            </div>
          ))}

          {hasMore && !moreCreditsLoading && (
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Button
                  rounded
                  type="tertiary"
                  classes={{ root: 'account-credits-list__load-more-btn' }}
                  onClick={AccountCreditsActions.listMoreCredits}
                >
                  {t('.more_btn', intl, __filenamespace)}
                </Button>
              </Grid>
            </Grid>
          )}

          {hasMore && moreCreditsLoading && (
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Spinner size={25} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

ListStep.propTypes = {
  intl: PropTypes.object,
  clientDataStore: PropTypes.object,
  accountCreditStore: PropTypes.object,
  accountCreditsDataStore: PropTypes.object,
};

ListStep.defaultProps = {
  intl: {},
  clientDataStore: {},
  accountCreditStore: {},
  accountCreditsDataStore: {},
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
      accountCreditStore: AccountCreditStore,
      accountCreditsDataStore: AccountCreditsDataStore,
    },
  })
)(ListStep);
