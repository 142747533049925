import { Map } from 'immutable';

import AccountSetupPaysafeActions from 'onboarding/actions/AccountSetupPaysafeActions.js';
import BaseStepStore, {
  makeFieldGetter,
} from 'onboarding/stores/BaseStepStore.js';
import BankStepActions from 'onboarding/actions/BankStepActions.js';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import OnboardAPI from 'onboarding/OnboardAPI.js';

function bankFieldsToJSON(fields) {
  const get = makeFieldGetter(fields);
  return {
    attributes: {
      account_number: get('AccountNumber'),
      routing_number: get('RoutingNumber'),
    },
  };
}
class BankStepStore extends BaseStepStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      handleUpdateForm: BankStepActions.updateForm,
      handleSubmit: BankStepActions.submitBankForm,
      reset: BankStepActions.mounted,
    });
  }

  reset() {
    this.fields = Map({
      AccountNumber: this.newField({
        validate: value => !!value.match(/^[0-9]*$/),
      }),
      AccountNumberConfirmation: this.newField({
        validate: value =>
          this.fields.getIn(['AccountNumber', 'value']) === value,
      }),
      RoutingNumber: this.newField({
        validate: value => !!value.match(/^\d{9}$/),
      }),
    });
  }

  async handleSubmit(...args) {
    this.isLoading = true;
    try {
      const data = JSON.stringify(bankFieldsToJSON(this.fields));
      await OnboardAPI.submitBankAccount(data);
      AccountSetupPaysafeActions.nextStep.defer();
    } catch (error) {
      ApiErrorDialogActions.error.defer(error);
      this.notifyError('error submitting bank step', args);
    }
    this.isLoading = false;
    this.emitChange();
  }
}

export default alt.createStore(BankStepStore, 'BankStepStore');
