import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@upperhand/playmaker';
import { Range } from 'immutable';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import Expander from 'shared/components/Expander.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Header from 'point_of_sale/components/Header.jsx';
import POSProductActions from 'point_of_sale/actions/POSProductActions.js';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

const styles = {
  cancelButton: {
    height: 50,
  },

  name: {
    fontSize: 22,
    fontWeight: 'bold',
    fontFamily: 'benton-sans-condensed, sans-serif',
    marginBottom: 10,
  },

  price: {
    fontSize: 22,
    fontWeight: 'bold',
    fontFamily: 'benton-sans-condensed, sans-serif',
    backgroundColor: '#f7f7f7',
    padding: 10,
    marginBottom: 20,
    letterSpacing: -1,
  },

  profileSelect: {
    '& > div': { height: '43px' },
    '.UH-MuiSelect-select': {
      padding: '5px',
    },
  },
};

function ProductPrice({ product, selectedVariant }) {
  if (selectedVariant) {
    return <FormattedCurrency value={selectedVariant.price} fromCents />;
  }
  if (product.max_price === product.min_price) {
    return <FormattedCurrency value={product.max_price} fromCents />;
  }
  return (
    <FormattedMessage
      id={messageId('.price_range', __filenamespace)}
      values={{
        min: <FormattedCurrency value={product.min_price} fromCents />,
        max: <FormattedCurrency value={product.max_price} fromCents />,
      }}
    />
  );
}

function OptionType({ optionType, selectedValueId }) {
  return (
    <FormControl fullWidth>
      <InputLabel id="option-type-label">{optionType.name}</InputLabel>
      <Select
        label={optionType.name}
        labelId="option-type-label"
        id="option-type"
        value={selectedValueId || ''}
        style={{ marginBottom: 15 }}
        onChange={e =>
          POSProductActions.optionSelected(optionType.id, e.target.value)
        }
      >
        {optionType.option_values.map(v => (
          <MenuItem key={v.id} value={v.id}>
            {v.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const previousImageSelected = () => POSProductActions.selectPreviousImage();
const nextImageSelected = () => POSProductActions.selectNextImage();

function ImageCarousel({ images, selectedImage, product }) {
  return (
    <div>
      <FlexBox
        style={{
          justifyContent: images.size > 1 ? 'space-between' : 'center',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        {images.size > 1 && (
          <IconButton
            onClick={previousImageSelected}
            style={{ height: 72, width: 72 }}
            iconStyle={{ height: 48, width: 48 }}
          >
            <ChevronLeftIcon sx={{ color: uhColors.activeBlu }} />
          </IconButton>
        )}

        <img
          src={selectedImage.getAlternative('product_small')}
          alt={product.name}
          height={150}
          width={150}
          style={{ objectFit: 'contain' }}
        />

        {images.size > 1 && (
          <IconButton
            onClick={nextImageSelected}
            style={{ height: 72, width: 72 }}
            iconStyle={{ height: 48, width: 48 }}
          >
            <ChevronRightIcon sx={{ color: uhColors.activeBlue }} />
          </IconButton>
        )}
      </FlexBox>

      {images.size > 1 && (
        <FlexBox style={{ marginBottom: 20, flexWrap: 'wrap' }}>
          {images.map(image => {
            const selected = selectedImage.id === image.id;

            const borderColor = selected
              ? uhColors.primaryMain
              : uhColors.lighterGrey;
            const borderThickness = selected ? 2 : 1;
            const padding = 3 - borderThickness;

            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <img
                src={image.getAlternative('thumb_large')}
                alt={product.name}
                height={68}
                width={68}
                style={{
                  border: `${borderThickness}px solid ${borderColor}`,
                  padding,
                  cursor: 'pointer',
                  objectFit: 'contain',
                }}
                onClick={() => POSProductActions.imageSelected(image.id)}
              />
            );
          })}
        </FlexBox>
      )}
    </div>
  );
}

const cancelClicked = () => POSProductActions.cancelClicked();

function ProductView({
  posStore: { selectedClient, allProfiles },
  posProductStore: {
    product,
    optionTypes,
    images,
    selectedImage,
    selectedOptions,
    selectedVariant,
    selectedQuantity,
    cashCreditDestinationId,
    orderItemId,
    changed,
    errors,
  },
  intl,
}) {
  React.useEffect(() => {
    POSProductActions.validate();
  }, []);

  const swingCardEnabled = product.get('swing_card', false);
  const { features } = currentCustomer();
  const { embed_api: embedApiEnabled, ap_credits: apCreditsEnabled } = features;
  const showSwingInput =
    swingCardEnabled && embedApiEnabled && !apCreditsEnabled;
  const showProfileSelector = apCreditsEnabled && !showSwingInput;
  const swingCardError =
    embedApiEnabled && errors.getErrors('cashCreditDestinationId', intl);
  const creditsProfileError =
    apCreditsEnabled && errors.getErrors('creditsProfile', intl);

  return (
    <div className="product-view">
      <Header selectedClient={selectedClient} />
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: 13, marginBottom: 10 }}>
          {product.retail_category.name}
        </div>
        <div style={styles.name}>{product.name}</div>
        <div style={styles.price}>
          <ProductPrice product={product} selectedVariant={selectedVariant} />
        </div>

        <ImageCarousel
          selectedImage={selectedImage}
          images={images}
          product={product}
        />

        <div
          style={{ padding: 10, backgroundColor: '#F7F7F7', marginBottom: 20 }}
        >
          <Expander
            label={t('.description', intl, __filenamespace)}
            labelContainerStyle={{
              fontSize: 15,
              fontWeight: 'bold',
              height: 'inherit',
              color: 'inherit',
            }}
            contentContainerStyle={{ marginTop: 15, lineHeight: 1.4 }}
          >
            {product.description}
          </Expander>
        </div>

        {optionTypes.map(o => (
          <OptionType
            key={o.id}
            optionType={o}
            selectedValueId={selectedOptions.get(o.id)}
          />
        ))}

        {!showSwingInput && (
          <FormControl fullWidth>
            <InputLabel id="option-type-label">
              {t('.quantity', intl, __filenamespace)}
            </InputLabel>
            <Select
              label={t('.quantity', intl, __filenamespace)}
              labelId="option-type-label"
              id="option-type"
              value={selectedQuantity || ''}
              onChange={e => POSProductActions.quantitySelected(e.target.value)}
            >
              {Range(1, 31).map(i => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showProfileSelector && (
          <FormControl fullWidth sx={{ marginTop: '15px' }}>
            <InputLabel id="option-type-label">
              {t('.profile', intl, __filenamespace)}
            </InputLabel>
            <Select
              label={t('.profile', intl, __filenamespace)}
              labelId="option-type-label"
              id="option-type"
              value={cashCreditDestinationId || ''}
              sx={styles.profileSelect}
              errorText={creditsProfileError}
              onChange={e => POSProductActions.clientChange(e.target.value)}
            >
              {allProfiles.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  <AvatarWithName user={c} style={{ height: '43px' }} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showSwingInput && (
          <TextField
            fullWidth
            id="swing-card-input"
            label={t('.swing_card', intl, __filenamespace)}
            value={cashCreditDestinationId}
            errorText={swingCardError}
            placeholder={t('.swing_card_placeholder', intl, __filenamespace)}
            onChange={e => POSProductActions.swingCardChange(e.target.value)}
            onBlur={() => POSProductActions.validate()}
          />
        )}

        <CartCommitButton
          hasOrderItemId={orderItemId}
          hasUpdate={changed}
          itemIsValid={
            !!selectedVariant && !swingCardError && !creditsProfileError
          }
          handleAddToCartClick={() => {
            POSProductActions.addToCartClicked(
              selectedVariant.id,
              selectedQuantity,
              cashCreditDestinationId
            );
          }}
          handleUpdateCartClick={() => {
            POSProductActions.updateCartClicked(orderItemId, {
              orderable_id: selectedVariant.id,
              quantity: selectedQuantity,
              cash_credit_destination_id: cashCreditDestinationId,
            });
          }}
          handleViewInCartClick={() => POSProductActions.viewInCartClicked()}
        />

        <Button
          fullWidth
          variant="contained"
          color="default"
          style={styles.cancelButton}
          onClick={cancelClicked}
        >
          {t('actions.cancel', intl, __filenamespace)}
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(ProductView);
