export default alt.generateActions(
  'begin',
  'cancel',
  'updated',
  'codeTypeUpdated',
  'eventTypeAdded',
  'eventTypeRemoved',
  'teamTypeAdded',
  'teamTypeRemoved',
  'retailCategoryAdded',
  'retailCategoryRemoved',
  'rateAdded',
  'rateRemoved',
  'save',
  'saveSuccess',
  'saveError'
);
