import React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  TextField,
  CurrencyTextField,
  Grid,
  DateTimeField,
} from '@upperhand/playmaker';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';

import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { numberToCurrency } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

const styles = {
  bigButton: {
    height: 50,
  },
  clearButtonIcon: {
    width: 18,
    height: 18,
  },
  paper: {
    padding: 10,
    marginBottom: 10,
    position: 'relative',
  },
  clearButton: {
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 1,
    padding: 0,
    height: 32,
    width: 32,
  },
};

// TODO: This is duplicate, extract to parent
const handleParameterChange = (keypath, value) => {
  AutomationTemplateDescriptionActions.updateStoreKeypath(
    ['template_parameters'].concat(keypath),
    value
  );
};

function PaymentCard({
  payment,
  planIndex,
  paymentIndex,
  errors,
  canRemove,
  onRemove,
  intl,
}) {
  const errorKeypath = field => [
    'template_parameters',
    planIndex,
    'payments',
    paymentIndex,
    field,
  ];
  const paramKeypath = field => [planIndex, 'payments', paymentIndex, field];

  return (
    <Paper elevation={2} style={styles.paper}>
      <Grid container direction="row" justify="space-between" spacing={1}>
        <Grid item>
          <FormattedMessage
            id={messageId('.payment_n', __filenamespace)}
            values={{ n: paymentIndex + 1 }}
          />
        </Grid>
        <Grid item>
          {canRemove && (
            <IconButton
              style={styles.clearButton}
              onClick={() => onRemove(paymentIndex)}
            >
              <ClearIcon color={grey[500]} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={6}>
          <DateTimeField
            autoOk
            disablePast
            type="date"
            errorText={errors.getErrors(errorKeypath('date'), intl)}
            label={t('.payment_date', intl, __filenamespace)}
            fullWidth
            placeholder="MM/DD/YYYY"
            hintStyle={{ color: uhColors.hint }}
            onChange={value =>
              handleParameterChange(
                paramKeypath('date'),
                value ? value.tz(customerTZ(), true) : null
              )
            }
            value={
              payment.get('date')
                ? moment.tz(payment.get('date'), customerTZ())
                : payment.get('date')
            }
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyTextField
            errorText={errors.getErrors(errorKeypath('amount'), intl)}
            label={t('.amount', intl, __filenamespace)}
            fullWidth
            value={payment.get('amount') / 100}
            onChange={(_, value) => {
              handleParameterChange(
                paramKeypath('amount'),
                Math.round((value + Number.EPSILON) * 100)
              );
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

function PlanEditor({
  plan,
  index,
  errors,
  onSave,
  onAddPayment,
  onRemovePayment,
  onCancel,
  intl,
}) {
  return (
    <div>
      <TextField
        errorText={errors.getErrors(
          ['template_parameters', index, 'name'],
          intl
        )}
        label={t('.plan_name', intl, __filenamespace)}
        fullWidth
        placeholder={t('.name', intl, __filenamespace)}
        onChange={(_, value) => handleParameterChange([index, 'name'], value)}
        value={plan.get('name')}
      />
      <div style={{ paddingTop: 15 }}>
        {plan
          .get('payments')
          .map((payment, i) => (
            <PaymentCard
              canRemove={plan.get('payments').size > 1}
              errors={errors}
              key={`${plan.get('uuid')}-payment`}
              onRemove={onRemovePayment}
              payment={payment}
              paymentIndex={i}
              planIndex={index}
              intl={intl}
            />
          ))
          .toList()}
      </div>
      <Button
        onClick={onAddPayment}
        variant="text"
        startIcon={<AddIcon />}
        style={{ marginBottom: 10 }}
      >
        {t('.add_payment', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        variant="contained"
        style={{ ...styles.bigButton, marginBottom: 20 }}
        onClick={onSave}
      >
        {t('.save', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        variant="outlined"
        style={{ ...styles.bigButton, marginBottom: 20 }}
        onClick={() => onCancel(index)}
      >
        {t('.cancel', intl, __filenamespace)}
      </Button>
    </div>
  );
}

function PlanCard({ plan, index, onEdit, onRemove }) {
  return (
    <Paper
      key={`parameter${plan.get('uuid')}`}
      elevation={2}
      style={{
        padding: 15,
        marginBottom: 10,
        position: 'relative',
        fontSize: 14,
        cursor: 'pointer',
      }}
      onClick={() => onEdit(index)}
    >
      <Grid container direction="row" justify="space-between">
        <Grid item>
          <div style={boldText}>{plan.get('name')}</div>
          <div style={{ marginBottom: 10 }}>
            <FormattedMessage
              id={messageId('.n_payments', __filenamespace)}
              values={{ n: plan.get('payments').size }}
            />
          </div>
        </Grid>
        <Grid item>
          <IconButton
            style={styles.clearButton}
            onClick={e => {
              e.stopPropagation();
              onRemove(index);
            }}
          >
            <ClearIcon color={grey[500]} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-between">
        <Grid item>
          <span style={boldText}>
            <FormattedMessage id={messageId('.total', __filenamespace)} />
          </span>
        </Grid>
        <Grid item>
          {numberToCurrency(plan.totalPrice(), { fromCents: true })}
        </Grid>
      </Grid>
    </Paper>
  );
}

function TopLevelEditor({
  template,
  onSave,
  onAddPlan,
  onEditPlan,
  onRemovePlan,
  intl,
}) {
  return (
    <div>
      <div style={{ fontWeight: 600, fontSize: 15, marginBottom: 20 }}>
        {template.name}
      </div>
      {template.plans().map((plan, i) => (
        <PlanCard
          plan={plan}
          index={i}
          key={plan.get('id')}
          canRemove={template.template_parameters.size > 1}
          onRemove={onRemovePlan}
          onEdit={onEditPlan}
        />
      ))}

      <Button
        fullWidth
        variant="outlined"
        style={{
          ...styles.bigButton,
          margin: '10px 0 20px 0',
        }}
        onClick={onAddPlan}
      >
        {t('.create_plan', intl, __filenamespace)}
      </Button>

      {template.template_parameters.size > 0 && (
        <Button
          fullWidth
          variant="contained"
          style={{
            ...styles.bigButton,
            marginBottom: 20,
          }}
          onClick={onSave}
        >
          {t('.save', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

class RecurringPaymentPlanEditor extends React.Component {
  state = { editingPlanIndex: null, previousPlan: null };

  handleAddPlan = () => {
    const { template } = this.props;
    AutomationTemplateDescriptionActions.updateStoreKeypath(
      [],
      template.addOption()
    );
    this.setState({
      editingPlanIndex: template.template_parameters.size,
    });
  };

  handleEditPlan = index => {
    const { template } = this.props;

    this.setState({
      editingPlanIndex: index,
      previousPlan: template.getIn(['template_parameters', index]),
    });
  };

  // eslint-disable-next-line class-methods-use-this
  handleRemovePlan = planIndex => {
    AutomationTemplateDescriptionActions.deleteParameter([
      'template_parameters',
      planIndex,
    ]);
  };

  handleSavePlan = () => {
    const { template } = this.props;
    const validatedATD = template.validate();

    if (validatedATD.isValid()) {
      this.setState({ editingPlanIndex: null });
    } else {
      AutomationTemplateDescriptionActions.updateStoreKeypath([], validatedATD);
    }
  };

  handleCancelPlan = planIndex => {
    const { previousPlan } = this.state;
    // If we are canceling an edit reset the plan to it's pre edit state.
    // Otherwise just discard the plan.
    if (previousPlan) {
      AutomationTemplateDescriptionActions.updateStoreKeypath(
        ['template_parameters', planIndex],
        previousPlan
      );
    } else {
      this.handleRemovePlan(planIndex);
    }

    this.setState({ editingPlanIndex: null, previousPlan: null });
  };

  handleAddPayment = () => {
    const { editingPlanIndex } = this.state;
    const { template } = this.props;

    const paymentsKeypath = [
      'template_parameters',
      editingPlanIndex,
      'payments',
    ];
    const payments = template.getIn(paymentsKeypath);
    const blankParams = payments.last().map(_p => null);

    AutomationTemplateDescriptionActions.updateStoreKeypath(
      paymentsKeypath,
      payments.push(blankParams.set('amount', payments.last().get('amount')))
    );
  };

  handleRemovePayment = paymentIndex => {
    const { editingPlanIndex } = this.state;

    AutomationTemplateDescriptionActions.deleteParameter([
      'template_parameters',
      editingPlanIndex,
      'payments',
      paymentIndex,
    ]);
  };

  isEditingPlan() {
    const { editingPlanIndex } = this.state;
    return editingPlanIndex === 0 || editingPlanIndex > 0;
  }

  render() {
    const { editingPlanIndex } = this.state;
    const { template, intl } = this.props;

    return this.isEditingPlan() ? (
      <PlanEditor
        plan={template.template_parameters.get(editingPlanIndex)}
        errors={template.errors}
        index={editingPlanIndex}
        onAddPayment={this.handleAddPayment}
        onRemovePayment={this.handleRemovePayment}
        onSave={this.handleSavePlan}
        onCancel={this.handleCancelPlan}
        intl={intl}
      />
    ) : (
      <TopLevelEditor
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        onAddPlan={this.handleAddPlan}
        onEditPlan={this.handleEditPlan}
        onRemovePlan={this.handleRemovePlan}
      />
    );
  }
}

export default injectIntl(RecurringPaymentPlanEditor);
