import { List, Record } from 'immutable';
import FilteredSelectionSource from 'shared/records/FilteredSelectionSource.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const defaultValues = {
  selectedAll: true,
  selected: List(),
  source: List(),
};

class FilteredSelection extends Record(defaultValues) {
  constructor(obj = {}) {
    const source = List(obj.source).map(
      item => new FilteredSelectionSource(item)
    );
    const selectedAll =
      typeof obj.selectedAll === 'boolean'
        ? obj.selectedAll
        : defaultValues.selectedAll;

    super(
      merge(obj, {
        selectedAll,
        source,
        selected: List(selectedAll ? source.map(item => item.key) : []),
      })
    );
  }

  changeSelectionAll(value) {
    let self = this;
    self = self.set('selectedAll', value);
    if (value) {
      self = self.set(
        'selected',
        self.source.map(item => item.key)
      );
    } else {
      self = self.set('selected', List());
    }
    return self;
  }

  changeSelectionItem(key, value) {
    let self = this;
    if (value) {
      self = self.set('selected', self.selected.push(key));
    } else {
      const index = self.selected.findIndex(item => item === key);
      if (index !== -1) {
        self = self.set('selected', self.selected.delete(index));
      }
    }
    if (this.selectedAll && !value) {
      self = self.set('selectedAll', false);
    } else if (self.selected.size === self.source.size) {
      self = self.set('selectedAll', true);
    }
    return self;
  }

  isSelectedAll() {
    return this.selectedAll;
  }

  isSelectedItem(key) {
    return this.selected.findIndex(item => item === key) !== -1;
  }

  getItem(key) {
    return this.source.find(item => item.key === key);
  }

  addItem(key, select) {
    let self = this.update('source', (list = List()) =>
      list.unshift(
        new FilteredSelectionSource({
          key,
        })
      )
    );
    if (select) {
      self = self.set('selected', self.selected.unshift(key));
    }
    return self;
  }

  deleteItem(key) {
    let self = this;
    const sourceIndex = this.source.findIndex(item => item.key === key);
    const selectedIndex = this.selected.findIndex(item => item === key);

    if (sourceIndex !== -1) {
      self = self.update('source', (list = List()) => list.delete(sourceIndex));
    }
    if (selectedIndex !== -1) {
      self = self.update('selected', (list = List()) =>
        list.delete(selectedIndex)
      );
    }

    return self;
  }

  toServer() {
    return this.selected.toArray();
  }
}

export default FilteredSelection;
