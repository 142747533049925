import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import MailTo from 'shared/components/MailTo.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import WaitlistActions from 'containers/events/admin/waitlist/actions.js';

import { ClientDataStore } from 'dataStores';

function ClientCard({ waitlistEntry, clientDataStore: { clients } }) {
  const client = clients.get(waitlistEntry.customer_user_id);

  if (!client) {
    return null;
  }

  const email = client.email || client.managing_customer_user_email;

  return (
    <Card contentDirection="row">
      <Grid container alignItems="center" xs={12} spacing={1}>
        <Grid container item alignItems="center" xs={12} md={5}>
          <Grid item>
            <UserAvatar user={client} />
          </Grid>
          <Grid item>
            <div role="presentation">
              <Typography>{client.name()}</Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {email ? (
            <MailTo email={email} />
          ) : (
            <FormattedMessage id={messageId('.email_na', __filenamespace)} />
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {waitlistEntry.invited ? (
            <FormattedMessage id={messageId('.invited', __filenamespace)} />
          ) : (
            <div
              role="presentation"
              onClick={() => WaitlistActions.waitlistInvite(client.id)}
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              <FormattedMessage id={messageId('.invite', __filenamespace)} />
            </div>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(ClientCard);
