import { Record } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent } from 'shared/utils/ObjectUtils.jsx';

class Task extends Record({
  id: null,
  name: '',
  removed: false,
  errors: new FieldErrors(),
}) {
  validate() {
    let errors = new FieldErrors();

    if (!isPresent(this.name)) {
      errors = errors.add('name', 'records.errors.required');
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  toServer() {
    const obj = this.toJS();
    delete obj.id;
    delete obj.errors;

    return obj;
  }
}

export default Task;
