import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  message: {
    fontSize: 15,
    padding: '0 20px',
    marginTop: 20,
  },
};

function EmptyState() {
  return (
    <div style={styles.message}>
      <FormattedMessage id={messageId('.no_registrations', __filenamespace)} />
    </div>
  );
}

export default EmptyState;
