import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, TextField } from '@upperhand/playmaker';

import FiltersBlock from 'containers/contacts/contactsFilters/steps/filterBlocks/FiltersBlock.jsx';
import ClearAction from 'containers/contacts/contactsFilters/components/ClearAction.jsx';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import { t } from 'shared/utils/LocaleUtils';

function Name({ filter, onChange }) {
  const intl = useIntl();
  const filterName = filter.get('name', null);

  const handleClear = () => {
    onChange([
      {
        target: { name: 'name', value: null },
      },
    ]);
  };

  return (
    <FiltersBlock title="Filter Name">
      <Grid container direction="column" spacing={1}>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <TextField
            fullWidth
            name="name"
            value={filterName}
            placeholder={t('.placeholder', intl, __filenamespace)}
            classes={{ root: 'filters-block__text-input' }}
            errorText={filter.errors.getErrors('name', intl)}
            onChange={onChange}
          />
          {filterName && <ClearAction onClear={handleClear} />}
        </Grid>
      </Grid>
    </FiltersBlock>
  );
}

Name.propTypes = {
  filter: PropTypes.instanceOf(ContactFilter),
  onChange: PropTypes.func,
};

Name.defaultProps = {
  filter: new ContactFilter(),
  onChange: () => null,
};

export default memo(Name);
