import React, { memo } from 'react';
import { Typography } from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions';

const styles = {
  divider: {
    marginBottom: '10px',
    borderColor: 'var(--color-nav-icon)',
  },
  deleteIcon: {
    width: '15px',
    height: '15px',
  },
};

function AddOnDetails({ addOnOrder }) {
  if (!addOnOrder) {
    return null;
  }

  return (
    <>
      <Divider sx={styles.divider} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography variant="subtitle2">
            {addOnOrder.primary_display_text}
          </Typography>
          <Typography>{addOnOrder.secondary_display_text}</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="flex-end">
          <FormattedCurrency fromCents value={addOnOrder.total} />
          <IconButton
            onClick={() =>
              QuickpayModalActions.removeRetailProduct(addOnOrder.id)
            }
          >
            <DeleteIcon sx={styles.deleteIcon} />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
}

export default memo(AddOnDetails);
