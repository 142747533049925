import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const convertToDataSource = events =>
  events
    .map(e => ({ title: e.title, value: e.id }))
    .toList()
    .toJS();

class EventAutoComplete extends React.PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    clearOnSelect: true,
  };

  constructor(props) {
    super(props);
    const { selectedEventTitle } = props;

    this.state = {
      searchText: selectedEventTitle || '',
    };
  }

  handleUpdateInput = (e, value) => {
    const { onChange } = this.props;

    onChange(value);
    this.setState({ searchText: value });
  };

  handleSelect = selection => {
    const { onEventSelect, clearOnSelect } = this.props;

    onEventSelect(selection.value);
    this.setState({
      searchText: clearOnSelect ? '' : selection.text,
    });
  };

  onBlur = () => {
    const { onLeave, clearOnSelect, selectedEventTitle } = this.props;
    const { searchText } = this.state;

    if (clearOnSelect) {
      this.setState({ searchText: '' });
    } else if (selectedEventTitle && selectedEventTitle !== searchText) {
      this.setState({ searchText: selectedEventTitle });
    }

    if (onLeave) {
      // onSelect is triggered after onLeave and this may lead
      // to potential race condition with dataSource resets
      // We need to wait some time before reset may be dispatched
      setTimeout(onLeave, 200);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  handleOptionEqual = (option, selectedOption) => {
    if (typeof option === 'object' && typeof selectedOption === 'object') {
      return option.value === selectedOption.value;
    }
    return option === '' || selectedOption === '';
  };

  render() {
    const { searchText } = this.state;
    const {
      hintText,
      events,
      inputVariant = 'standard',
      inputRootStyle,
      popupIcon = <ArrowDropDownIcon />,
    } = this.props;
    const options = convertToDataSource(events);

    return (
      <Autocomplete
        fullWidth
        blurOnSelect
        value=""
        inputValue={searchText || ''}
        options={options}
        getOptionLabel={option => option.title || ''}
        isOptionEqualToValue={this.handleOptionEqual}
        popupIcon={popupIcon}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant={inputVariant}
            placeholder={hintText}
            InputProps={{
              ...params.InputProps,
              sx: inputRootStyle,
              startAdornment: <SearchIcon />,
            }}
          />
        )}
        renderOption={(optionProps, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <MenuItem {...optionProps} key={option.value}>
            {option.title}
          </MenuItem>
        )}
        onInputChange={this.handleUpdateInput}
        onBlur={this.onBlur}
        onChange={(_event, option, reason) => {
          if (reason === 'selectOption') {
            this.handleSelect(option);
          }
        }}
      />
    );
  }
}

export default EventAutoComplete;
