class NewMessageDrawerActions {
  constructor() {
    this.generateActions(
      'toggleDrawer',
      'updateAndValidateMessage',
      'sendMessage',
      'sendMessageSuccess',
      'sendMessageError'
    );
  }
}

export default alt.createActions(NewMessageDrawerActions);
