import * as React from 'react';
import Location from 'event_mgmt/display/components/_Location.jsx';
import StaffList from 'event_mgmt/display/components/_StaffList.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';

const EventLocationAndStaff = ({ event, eventStaffStore, style }) => {
  const location = event.getIn(['schedules', 0, 'location']);

  if (!location && eventStaffStore.staff.isEmpty()) {
    return null;
  }

  return (
    <FlexBox style={style}>
      <Location location={location} style={{ flexBasis: '50%' }} />
      <StaffList {...eventStaffStore} style={{ flexBasis: '50%' }} />
    </FlexBox>
  );
};

export default EventLocationAndStaff;
