export default alt.generateActions(
  'begin',
  'updateDate',
  'update',
  'setStaff',
  'confirm',
  'success',
  'addSuccess',
  'editSuccess',
  'error',
  'closeModal',
  'setClient',
  'setItem',
  'load',
  'loadSuccess',
  'loadError',
  'setRetailCategory'
);
