import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Typography } from '@upperhand/playmaker';

import CouponForm from 'shared/components/checkout/CouponForm.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  couponDetails: {
    lineHeight: '18px',
    padding: '15px 20px',
    backgroundColor: uhColors.lightYellow,
  },
};

const CouponRemoveButton = ({ onCouponRemove, loading }) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className="link"
    style={{ color: uhColors.activeBlue }}
    onClick={() => !loading && onCouponRemove({ code: null })}
  >
    <Typography variant="subtitle2">
      <FormattedMessage
        id={messageId(
          `${loading ? '.removing' : 'actions.remove'}`,
          __filenamespace
        )}
      />
    </Typography>
  </button>
);

const CouponDetails = ({ order, onCouponRemove, loading }) => (
  <div style={styles.couponDetails}>
    <FlexBoxJustify>
      <div>
        <div style={{ fontWeight: 'bold' }}>{order.coupon.name}</div>
      </div>
      <Typography variant="subtitle2">
        <FormattedMessage
          id={messageId('.value', __filenamespace)}
          values={{
            value: order.coupon.discount.isPercent() ? (
              <FormattedNumber
                value={order.coupon.get('value') / 100}
                // eslint-disable-next-line react/style-prop-object
                style="percent"
              />
            ) : (
              <FormattedCurrency value={order.coupon.value} fromCents />
            ),
          }}
        />
      </Typography>
    </FlexBoxJustify>
    {order.сouponAdjustments().size ? (
      order.order_items.map(oi => {
        const couponAdjustment = oi.applied_adjustments.find(
          a => a.get('type') === 'Coupon'
        );
        if (couponAdjustment) {
          return (
            <FlexBoxJustify style={{ marginBottom: 4, marginTop: 4 }}>
              <div style={{ color: 'var(--color-tab-gray)' }}>
                <div>{oi.primary_display_text}</div>
              </div>
              <div>
                <FormattedCurrency value={couponAdjustment.amount} fromCents />
              </div>
            </FlexBoxJustify>
          );
        }
        return false;
      })
    ) : (
      <div style={{ color: 'red', marginTop: 8 }}>
        <FormattedMessage
          id={messageId('.invalid_coupon_type', __filenamespace)}
        />
      </div>
    )}
    <FlexBoxJustify style={{ marginTop: 16 }}>
      <CouponRemoveButton onCouponRemove={onCouponRemove} loading={loading} />
    </FlexBoxJustify>
  </div>
);

const CouponSummary = ({ order, onCouponApply, loading }) => (
  <div style={{ marginBottom: 15 }}>
    {order.coupon ? (
      <CouponDetails
        order={order}
        onCouponRemove={onCouponApply}
        loading={loading}
      />
    ) : (
      <CouponForm
        onCouponApply={onCouponApply}
        isLoading={loading}
        styles={styles}
      />
    )}
  </div>
);

export default CouponSummary;
