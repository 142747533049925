import * as React from 'react';
import { injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const convertToDataSource = contacts =>
  contacts.map(c => ({ title: c.name(), value: c.id })).toJS();

class ContactAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    const dataSource = convertToDataSource(props.contacts);

    this.state = {
      dataSource,
      searchText: '',
      textFieldFocused: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { contacts } = this.props;

    if (prevProps.contacts !== contacts) {
      this.setState({
        dataSource: convertToDataSource(contacts),
      });
    }
  }

  handleContactSelect = selection => {
    const { onSelect } = this.props;
    // Handles the case where the user selects from the dropdown without typing.
    this.setState({ searchText: selection.text });
    this.setState({ searchText: '' });
    onSelect(selection.value);
    this.setState({ dataSource: [] });
  };

  handleUpdateInput = (e, value) => {
    const { onUpdateInput } = this.props;

    this.setState({ searchText: value });
    onUpdateInput(value);
  };

  // eslint-disable-next-line class-methods-use-this
  handleOptionEqual = (option, selectedOption) => {
    if (typeof option === 'object' && typeof selectedOption === 'object') {
      return option.value === selectedOption.value;
    }
    return option === '' || selectedOption === '';
  };

  render() {
    const {
      contacts,
      iconStyle = {},
      inputStyle = {},
      intl,
      style = {},
      hintText,
      onSelect,
      onUpdateInput,
      ...props
    } = this.props;
    const { textFieldFocused, dataSource, searchText } = this.state;
    const { fontSize = 16, ...rootStyle } = style;
    const iconColor = textFieldFocused ? uhColors.blue : uhColors.iconGrey;

    return (
      <Autocomplete
        fullWidth
        blurOnSelect
        value=""
        sx={merge({ padding: '0 15px' }, rootStyle)}
        inputValue={searchText || ''}
        options={dataSource}
        isOptionEqualToValue={this.handleOptionEqual}
        getOptionLabel={option => option.title || ''}
        renderOption={(optionProps, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <MenuItem {...optionProps} key={option.value}>
            {option.title}
          </MenuItem>
        )}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="standard"
            placeholder={hintText || t('.add_contact', intl, __filenamespace)}
            inputProps={{
              ...params.inputProps,
              style: { padding: 15, fontSize, ...inputStyle },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <PersonAddIcon style={{ ...iconStyle, color: iconColor }} />
              ),
              endAdornment: undefined,
              disableUnderline: true,
            }}
          />
        )}
        onInputChange={this.handleUpdateInput}
        onBlur={() => this.setState({ textFieldFocused: false })}
        onFocus={() => this.setState({ textFieldFocused: true })}
        onChange={(_event, option, reason) => {
          if (reason === 'selectOption') {
            this.handleContactSelect(option);
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
}

export default injectIntl(ContactAutocomplete);
