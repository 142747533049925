import { List, Set } from 'immutable';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import { getUrlParameter } from 'shared/utils/UrlParameters.js';
import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import MembershipDiscountActions from 'event_mgmt/display/actions/MembershipDiscountActions.js';
import POSMembershipListingActions from 'point_of_sale/actions/POSMembershipListingActions.js';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import RegistrationPackageSchedulingStore from 'shared/stores/RegistrationPackageSchedulingStore.jsx';
import RegistrationStore from 'event_mgmt/display/stores/RegistrationStore.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';
import PDActions from 'containers/purchaseDrawer/Actions';
import PurchaseDrawerActions from '../actions/PurchaseDrawerActions';

class PurchaseDrawerRegistrationPackageStore extends RegistrationPackageSchedulingStore {
  constructor() {
    super();

    /*
     *  From super:
     *
     * this.registrationPackage            = new RegistrationPackage();
     * this.selectedClientId               = null;
     * this.changed                        = false;
     * this.packageIsValid                 = false;
     * this.isSchedulingNow                = false;
     *
     * this.event                          = null;
     * this.eventSchedule                  = null;
     * this.paymentPlanDescription         = null;
     * this.packagePricingDescription      = null;
     * this.automationsLoaded              = false;
     * this.totalPrice                     = 0;
     *
     * this.orderItemId                    = null;
     * this.purchasedRegistrations         = List();
     * this.registeredAthleteIds           = Set();
     * this.tentativeAthleteIds            = Set();
     *
     * this.allowSingleSessionPurchase     = false;
     * this.rawSessionAvailability         = List();
     * this.sessionAvailability            = Map();
     * this.singleSessionRegistrationCount = 0;
     * this.allDayRegistrationCount        = 0;
     */

    this.allRegisteredAthleteIds = Set();

    this.cartLoaded = false;
    this.registrationsLoaded = false;

    this.purchasedRegistrationsLoaded = false;

    const orderItemId = getUrlParameter('order_item_id');
    this.orderItemId = orderItemId ? parseInt(orderItemId, 10) : null;

    this.bindListeners({
      handleFixedScheduleAthleteAdded: [
        PDRegistrationPackageActions.FIXED_SCHEDULE_ATHLETE_ADDED,
        PDRegistrationPackageActions.FIXED_SCHEDULE_PROFILE_CREATED,
      ],

      handleFixedScheduleAthleteRemoval:
        PDRegistrationPackageActions.FIXED_SCHEDULE_ATHLETE_REMOVED,

      selectPaymentPlan: PDRegistrationPackageActions.PAYMENT_PLAN_SELECTED,

      selectDefaultPackage:
        PDRegistrationPackageActions.DEFAULT_PACKAGE_SELECTED,
      selectAutomatedPackage:
        PDRegistrationPackageActions.AUTOMATED_PACKAGE_SELECTED,

      beginScheduling: PDRegistrationPackageActions.SCHEDULE_NOW_CLICKED,
      stopScheduling: [
        PDRegistrationPackageActions.SCHEDULE_LATER_CLICKED,
        PDActions.CLOSE_DRAWER,
      ],

      setRegistrationStaff:
        PDRegistrationPackageActions.REGISTRATION_STAFF_CHANGED,
      setRegistrationDate:
        PDRegistrationPackageActions.REGISTRATION_DATE_CHANGED,
      setRegistrationTime:
        PDRegistrationPackageActions.REGISTRATION_TIME_CHANGED,
      bookRegistration: PDRegistrationPackageActions.REGISTRATION_BOOKED,
      cancelRegistration: PDRegistrationPackageActions.REGISTRATION_CANCELLED,
      setRegistrationAthlete: [
        PDRegistrationPackageActions.REGISTRATION_ATHLETE_CHANGED,
        PDRegistrationPackageActions.PROFILE_CREATED_OPEN_BOOKING,
      ],

      resetPackage: PDRegistrationPackageActions.ADD_ATTENDEE_CLICKED,
      // We have 2 Purchase Drawers (why?) and both needs to be reset
      handleOnDrawerClose: [
        PDActions.closeDrawer,
        PurchaseDrawerActions.drawerClosed,
      ],

      loadPackageFromCart: CartActions.FETCH_SUCCESS,
      setEventInformation: [
        EventActions.FETCH_SUCCESS,
        PurchaseDrawerActions.DRAWER_OPENED,
      ],
      setRegisteredAthleteInformation: RegistrationActions.LIST_SUCCESS,
      setEventAutomations: [
        AutomationTemplateDescriptionActions.LIST_SUCCESS,
        PurchaseDrawerActions.DRAWER_OPENED,
      ],
      setAllProfiles: AthleteActions.LIST_SUCCESS,

      setTotalPrice: [
        MembershipDiscountActions.LIST_SUCCESS,
        MembershipListingActions.LIST_RECEIVED,
        POSMembershipListingActions.LIST_SUCCESS,
        PurchaseDrawerActions.DRAWER_OPENED,
        CartActions.FETCH_SUCCESS,
      ],

      /* Single Session Purchase */
      setSingleSessions: [EventActions.FETCH_AVAILABILITY_SUCCESS],
      handleSingleSessionAttendeeAdd: [
        PDRegistrationPackageActions.SINGLE_SESSION_ATTENDEE_ADDED,
        PDRegistrationPackageActions.SINGLE_SESSION_PROFILE_CREATED,
      ],
      handleSingleSessionAttendeeRemove:
        PDRegistrationPackageActions.SINGLE_SESSION_ATTENDEE_REMOVED,

      registerSession: PDRegistrationPackageActions.SINGLE_SESSION_ADDED,
      unregisterSession: PDRegistrationPackageActions.SINGLE_SESSION_REMOVED,
      registerAllDays:
        PDRegistrationPackageActions.ALL_AVAILABLE_SINGLE_SESSIONS_ADDED,
      unregisterAllDays:
        PDRegistrationPackageActions.ALL_AVAILABLE_SINGLE_SESSIONS_REMOVED,
    });
  }

  handleOnDrawerClose() {
    // Reset store only if a user isn't on the event single page because on this page data is fetched only one time on the first render.
    if (this.event && !window.location.pathname.includes(this.event.id)) {
      super.reset();
    }
  }

  handleFixedScheduleAthleteAdded(customerUserId) {
    super.addProfileFixedSchedule(customerUserId);
    if (!this.allowSingleSessionPurchase) {
      this.tentativeAthleteIds = this.registrationPackage.client_ids;
    }
    this.setAllRegisteredAthleteIds();
  }

  handleFixedScheduleAthleteRemoval(customerUserId) {
    super.removeProfileFixedSchedule(customerUserId);

    if (this.changed) {
      this.tentativeAthleteIds = this.registrationPackage.client_ids;
      this.setAllRegisteredAthleteIds();
    }
  }

  loadPackageFromCart() {
    this.waitFor(CartStore);

    this.cartLoaded = true;

    if (this.eventSchedule) {
      this.getRegistrationPackageFromCart();
    }

    if (this.registrationsLoaded) {
      this.setSingleSessions();
    }
  }

  setEventInformation() {
    this.waitFor(EventStore);

    const event = EventStore.getState().customerEvent;

    this.event = event;
    this.eventSchedule = event.getIn(['schedules', 0]);
    this.allowSingleSessionPurchase = event.allow_single_session_purchase;
    this.registrationPackage = this.registrationPackage
      .set('event_id', this.event.id)
      .set('single_session_price', this.event.single_session_price * 100)
      .set('all_sessions_price', this.event.price * 100);

    if (this.cartLoaded) {
      this.getRegistrationPackageFromCart();
    }

    if (this.automationsLoaded) {
      this.getEventAutomations();
    }

    this.setSingleSessions();
  }

  setRegisteredAthleteInformation() {
    this.waitFor(RegistrationStore);

    this.registrationsLoaded = true;
    this.purchasedRegistrations = RegistrationStore.getState().allRegistrations;
    this.purchasedRegistrationsLoaded = true;

    this.registeredAthleteIds = this.purchasedRegistrations
      .map(cr => cr.client_id)
      .toSet();

    this.setAllRegisteredAthleteIds();

    if (this.cartLoaded) {
      this.setSingleSessions();
    }
  }

  setAllRegisteredAthleteIds() {
    this.allRegisteredAthleteIds = this.tentativeAthleteIds.union(
      this.registeredAthleteIds
    );
  }

  setEventAutomations() {
    this.waitFor(AutomationTemplateDescriptionStore);

    this.automationsLoaded = true;

    if (this.event) {
      this.getEventAutomations();
    }
  }

  setAllProfiles() {
    this.waitFor(AthleteStore);

    this.allProfiles = AthleteStore.getState().allAthletes;
  }

  getEventAutomations() {
    const atds =
      AutomationTemplateDescriptionStore.getState().descriptions.filter(
        atd => atd && atd.event_id === this.event.id
      );

    this.paymentPlanDescription =
      atds.find(atd => atd.isRecurringPaymentPlan()) || null;
    this.packagePricingDescription =
      atds.find(atd => atd.isPackagePricing()) || null;
    super.validatePackage();
  }

  getRegistrationPackageFromCart() {
    const byEvent = oi => oi.getIn(['orderable', 'event_id']) === this.event.id;
    const byId = oi => oi.id === this.orderItemId && byEvent(oi);

    const orderItems = CartStore.getState().cart.get('order_items', List());
    const cartItem =
      this.event.isOpenBooking() || this.orderItemId
        ? orderItems.find(byId)
        : orderItems.find(byEvent);

    if (cartItem) {
      this.registrationPackage = cartItem.orderable;
      this.orderItemId = cartItem.id;
      this.tentativeAthleteIds = this.registrationPackage.client_ids;
      this.setAllRegisteredAthleteIds();

      if (this.event.isOpenBooking()) {
        this.setRegistrationAthlete(
          this.registrationPackage.client_ids.first()
        );
      }
    }

    super.validatePackage();
  }

  /* single session purchase */

  handleSingleSessionAttendeeAdd(clientId) {
    super.addAttendeeSingleSession(clientId);

    this.tentativeAthleteIds = this.tentativeAthleteIds.add(clientId);
    this.setAllRegisteredAthleteIds();
  }

  handleSingleSessionAttendeeRemove(clientId) {
    super.removeProfileFixedSchedule(clientId);

    this.tentativeAthleteIds = this.tentativeAthleteIds.delete(clientId);
    this.setAllRegisteredAthleteIds();
  }

  registerAllDays(clientId) {
    super.registerAllDays(clientId);

    if (!this.allowSingleSessionPurchase) {
      this.tentativeAthleteIds = this.registrationPackage.client_ids;
    }
    this.setAllRegisteredAthleteIds();
  }

  setSingleSessions() {
    this.waitFor([EventStore, RegistrationStore]);

    this.rawSessionAvailability = EventStore.getState().fixedScheduleSessions;

    if (
      this.cartLoaded &&
      this.registrationsLoaded &&
      this.allowSingleSessionPurchase &&
      RegistrationStore.getState().registrationsLoaded &&
      EventStore.getState().fixedScheduleSessionsLoaded
    ) {
      super.setupSingleSessionPurchase();
    }
  }
}

export default alt.createStore(
  PurchaseDrawerRegistrationPackageStore,
  'PurchaseDrawerRegistrationPackageStore'
);
