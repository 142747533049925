import TimeLogCreationActions from 'reporting/payroll/actions/TimeLogCreationActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import TimeLogEditingStoreInterface from 'reporting/payroll/stores/TimeLogEditingStoreInterface';

export default alt.createStore(
  TimeLogEditingStoreInterface,
  'TimeLogCreationStore',
  {
    actions: TimeLogCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
