import React from 'react';
import PerSessionPricingEditor from 'automations/editing/components/PerSessionPricingEditor.jsx';
import RecurringPaymentPlanEditor from 'automations/editing/components/RecurringPaymentPlanEditor.jsx';

const componentMap = {
  PerSessionPricingTemplate: PerSessionPricingEditor,
  RecurringPaymentPlanTemplate: RecurringPaymentPlanEditor,
};

function AutomationEditor({ template, ...restProps }) {
  const { template_type: templateType } = template;
  const EditorComponent = componentMap[templateType];

  if (EditorComponent) {
    // eslint-disable-next-line
    return <EditorComponent template={template} {...restProps} />;
  }
  return null;
}

export default AutomationEditor;
