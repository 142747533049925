import { List } from 'immutable';
import { Payment } from 'records';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './PaymentSourceActions';

const parserTo = { type: Payment, paginationKey: 'payments' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

/**
 * amount: the total amount to refund
 * allocation: a map of {"source_ids": "amount"}
 * ex: refund({id: 'dc01d330-a834-4a66-a13f-fd50df8f430c', amount: 100, allocation: {"e7d22114-e1ec-4de2-bdff-0740c88f44ee": 50}})
 */
export const refund = ({ id, amount, allocation, success, error }) => {
  // const params = { amount, allocation };

  uhApiClient.post({
    url: `${parserTo.paginationKey}/${id}/refund`,
    data: JSON.stringify({
      amount,
      allocation,
    }),
    success: onSuccess(
      // Should really become a Refund record rather than a Payment record
      data => data,
      List([DataStoreActions.refundSuccess, success])
    ),
    error: onError(List([DataStoreActions.refundError, error])),
  });
};

/**
 * amount: Integer (Required) - The payment amount
 * allocation: Hash (optional) - Hash of source_ids to allocation amount from the payment to be made
 */
export const submit = ({ id, amount, allocation, success, error }) => {
  uhApiClient.post({
    url: `client/${id}/payment`,
    data: JSON.stringify({
      amount,
      allocation,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.submitSuccess, success])
    ),
    error: onError(List([DataStoreActions.submitError, error])),
  });
};
