export default alt.generateActions(
  'hourlyRateSaveError',
  'hourlyRateSaveSuccess',
  'hourlyRateSaved',
  'hourlyRateUpdated',
  'staffSelected',
  'tabSelected',
  'toggleInfoEditMode',
  'togglePayrollEditMode',

  'addEventRate',
  'removeEventRate'
);
