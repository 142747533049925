/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@upperhand/playmaker';
import { t } from 'shared/utils/LocaleUtils.js';
import { injectIntl } from 'react-intl';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import Document from 'records/Document';

function PDFUpload({ intl, onChange, publicFile }) {
  const onDrop = useCallback(acceptedFiles => {
    const PDFUrl = URL.createObjectURL(acceptedFiles[0]);
    const uploadedPdf = new Document({
      file: acceptedFiles[0],
      url: PDFUrl,
      originalData: PDFUrl,
      public_file: publicFile,
    });
    onChange(uploadedPdf);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragReject,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
    minSize: 0,
    multiple: false,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragReject && t('.drag_error', intl, __filenamespace)}
        <div className="upload-preview__empty">
          <Icon name="cloudUpload" />
          <div className="upload-preview__empty-text">
            <div className="upload-preview__empty-title">
              {t('.empty_text', intl, __filenamespace)}
            </div>
            <div className="upload-preview__empty-desc">
              {t('.empty_desc', intl, __filenamespace)}
              <div className="upload-preview__empty-link">
                {t('.empty_link', intl, __filenamespace)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {acceptedFiles.map(acceptedFile => (
        <ul className="success-list-style">
          <li style={{ color: uhColors.activeBlue }}>{acceptedFile.name}</li>
        </ul>
      ))}
      {fileRejections.length > 0 && (
        <ul className="error-list-style">
          <li>{fileRejections[0].errors[0].message}</li>
        </ul>
      )}
    </>
  );
}

export default injectIntl(PDFUpload);
