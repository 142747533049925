import jwtDecode from 'jwt-decode';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { RoleSource } from 'sources';
// import { redirectTo } from 'shared/utils/RouteUtils';
import { setActiveToken } from 'shared/utils/AuthUtils';
import VerifyActions from './Actions';

class VerifyStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      fetchUserError: VerifyActions.fetchUserError,
      fetchUserSuccess: VerifyActions.fetchUserSuccess,
      mounted: VerifyActions.mounted,
      roleListError: VerifyActions.roleListError,
      roleListSuccess: VerifyActions.roleListSuccess,
      verifyAccountError: VerifyActions.verifyAccountError,
      verifyAccountSuccess: VerifyActions.verifyAccountSuccess,
    });
  }

  reset() {
    this.hasVerifyError = false;
    this.isRoleLoading = false;
    this.isVerifiedLoading = true;
    this.isUserLoading = false;
    this.lastLoginAt = null;
    this.updateLoading();
  }

  updateLoading() {
    this.isLoading = this.isUserLoading || this.isVerifiedLoading;
  }

  mounted(key) {
    this.isVerifiedLoading = false;
    this.updateLoading();

    uhApiClient.get({
      url: 'accounts/verify',
      data: { key },
      success: VerifyActions.verifyAccountSuccess,
      error: VerifyActions.verifyAccountError,
    });
  }

  verifyAccountSuccess(data) {
    this.isUserLoading = true;
    this.isVerifiedLoading = false;
    this.updateLoading();

    setActiveToken(data);

    const tokenData = jwtDecode(data);

    // get user info here to see last logged in and choose correct redirect path
    uhApiClient.get({
      url: `users/${tokenData.sub}`,
      success: VerifyActions.fetchUserSuccess,
      error: VerifyActions.fetchUserError,
    });
  }

  verifyAccountError() {
    this.hasVerifyError = true;
  }

  fetchUserSuccess(data) {
    this.isRoleLoading = true;
    this.lastLoginAt = data.last_login_at;

    RoleSource.list({
      success: VerifyActions.roleListSuccess,
      error: VerifyActions.roleListError,
    });
    this.isUserLoading = false;
    this.updateLoading();
  }

  fetchUserError() {
    this.hasVerifyError = true;
    this.isUserLoading = false;
    this.updateLoading();
  }

  roleListSuccess({ roles }) {
    this.isRoleLoading = false;
    this.updateLoading();

    if (this.lastLoginAt) {
      // because redirect causes dispatch in a dispatch....
      window.location.href = `/`;
      // redirectTo({
      //   path: '/',
      // });
    } else {
      // because redirect causes dispatch in a dispatch....
      // redirectTo({ path: `/customers/${roles.first().slug}/create_user` });
      window.location.href = `/customers/${roles.first().slug}/create_user`;
    }
  }

  roleListError(...args) {
    this.hasVerifyError = true;
    this.isRoleLoading = false;
    this.updateLoading();
    this.notifyError('error while Listing Roles of current user', args);
  }
}

export default alt.createStore(VerifyStore, 'VerifyStore');
