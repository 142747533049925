import { Record } from 'immutable';

class ContactUpload extends Record({
  id: null,
  customer_user_id: null,
  completed_at: null,
  csv: null,
  errors_url: null,
  failed_at: null,
  failure_reason: null,
  success_row_count: 0,
  failed_row_count: 0,
}) {
  hasErrors() {
    return this.errors_url !== null && this.errors_url !== undefined;
  }

  isCompleted() {
    return this.completed_at !== null && this.completed_at !== undefined;
  }

  isFailed() {
    return this.failed_at !== null && this.failed_at !== undefined;
  }

  isInProgress() {
    return !this.isCompleted() && !this.isFailed();
  }
}

export default ContactUpload;
