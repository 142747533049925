export default alt.generateActions(
  'resetContactGroup',
  'stageContactGroup',
  'updateStore',
  'list',
  'listSuccess',
  'listError',
  'create',
  'createSuccess',
  'createError',
  'deleteRequested',
  'deleteCancelled',
  'delete',
  'deleteSuccess',
  'deleteError',
  'toggleAddContact',
  'addContact',
  'addContactSuccess',
  'addContactError',
  'removeContacts',
  'selection',
  'contactsSelected',
  'removeSelectedContacts',
  'removeContactsSuccess',
  'removeContactsError'
);
