import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Map, OrderedSet } from 'immutable';
import { Card, Grid, Spinner, Typography } from '@upperhand/playmaker';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NoteIcon from '@mui/icons-material/InsertDriveFile';
import PersonIcon from '@mui/icons-material/Person';

import EmptyState from 'containers/classes/classesList/components/Sessions/EmptyState.jsx';
import StaffSection from 'containers/classes/classesList/components/Sessions/StaffSection.jsx';
import WarningIcon from 'containers/classes/classesList/components/Sessions/WarningIcon.jsx';
import ColumnsMenu from 'containers/classes/classesList/components/ColumnsMenu.jsx';

import Paginator from 'shared/components/Paginator.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import EventClass from 'shared/records/EventClass';

import CancelSessionActions from 'containers/events/admin/schedule/cancelSessionModal/Actions';

import {
  SessionDataStore,
  StaffDataStore,
  ScheduleDataStore,
} from 'dataStores';

const styles = {
  cancelledIcon: {
    display: 'inline-block',
    marginRight: 10,
    marginTop: 5,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  noteContainer: {
    width: '24px',
    height: '24px',
    padding: '4px',
    position: 'absolute',
    right: '-15px',
    top: 'calc(50% - 12px)',
  },
  icon: {
    height: '12px',
    width: '12px',
    marginRight: '8px',
    color: uhColors.lightGrey,
  },
  noteIcon: {
    height: '12px',
    width: '12px',
    marginRight: 0,
    color: 'var(--color-warning-yellow)',
  },
};

function StatusIcon({ isWarning }) {
  return (
    <>
      <span data-tip style={styles.cancelledIcon}>
        <WarningIcon
          color={isWarning ? uhColors.icons.warn : uhColors.icons.error}
          height={17}
          width={20}
        />
      </span>
      <ReactTooltip className="uh-tooltip" effect="solid">
        <FormattedMessage
          id={
            isWarning
              ? messageId('.cancelled', __filenamespace)
              : messageId('.conflicting', __filenamespace)
          }
        />
      </ReactTooltip>
    </>
  );
}

const SessionCard = injectIntl(
  ({
    sessionId,
    openSessionSummaryDrawer,
    sessions,
    staff,
    schedules,
    intl,
  }) => {
    const session = sessions.get(sessionId);

    if (!session) {
      return null;
    }

    const hasAdminNote = Boolean(session.note);
    const hasClientNote = session.client_note !== null;
    const hasNotes = hasAdminNote || hasClientNote;

    const clientCount = session.registration_ids.count();

    const schedule = schedules.get(session.schedule_id, {
      location: { name: 'TBD' },
      max_size: 1,
    });

    const location = schedule.location || { name: 'TBD' };
    const eventId = session.get('event_id', null);

    const menuOptions = [
      {
        label: t('.view_session', intl, __filenamespace),
        onClick: () => openSessionSummaryDrawer({ sessionId, eventId }),
        visible: true,
      },
      {
        label: t('.cancel', intl, __filenamespace),
        onClick: () => CancelSessionActions.initCancellation(sessionId),
        visible: !session.isCancelled(),
      },
    ]
      .filter(item => item.visible)
      .map(({ visible, ...rest }) => rest);

    return (
      <Card contentDirection="row" menuOptions={menuOptions}>
        <Box sx={styles.flexCenter}>
          <Grid container alignItems="center" xs={12} spacing={1}>
            <Grid item alignItems="center" xs={6} md={2} spacing={1}>
              <Box sx={styles.flexCenter}>
                {session.isCancelled() && <StatusIcon isWarning />}
                {session.isConflicting() && <StatusIcon />}
                <Typography variant="subtitle2" display="inline">
                  {session.starts_at.format('MMM DD YY')}
                </Typography>
                &nbsp;
                <Typography display="inline">
                  {session.starts_at.format('ddd')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography>
                <FormattedMessage
                  id={messageId('.duration', __filenamespace)}
                  values={{
                    startsAt: session.starts_at.format('h:mma'),
                    duration: session.duration().as('minutes'),
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>{location.name}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <StaffSection
                intl={intl}
                staffIds={session.staff_ids}
                staff={staff}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>
                <FormattedMessage
                  id={messageId('.client_count', __filenamespace)}
                  values={{ clientCount }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              {/* Can't get Grid to actually center with the icon so using flexbox to save time. */}
              <Box sx={styles.flexCenter}>
                <PersonIcon style={styles.icon} />
                <Typography>
                  <FormattedMessage
                    id={messageId('.attendance_details', __filenamespace)}
                    values={{
                      clientCount,
                      maxClients: session.max_size,
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={1} alignItems="center" spacing={1}>
              {/* Can't get Grid to actually center with the icon so using flexbox to save time. */}
              <Box sx={styles.flexCenter}>
                <ResourceIcon style={styles.icon} />
                <Typography>{session.resource_ids.count()}</Typography>
              </Box>
            </Grid>
          </Grid>
          {hasNotes && (
            <IconButton
              sx={styles.noteContainer}
              onClick={() =>
                openSessionSummaryDrawer({
                  sessionId,
                  eventId,
                  defaultSelectedTab: 'notes',
                })
              }
            >
              <NoteIcon sx={styles.noteIcon} />
            </IconButton>
          )}
        </Box>
      </Card>
    );
  }
);

function SessionsList({
  intl,
  eventId,
  selectedClass,
  listUpcoming,
  loading,
  onSessionCancel,
  onViewAttendeesClicked,
  openSessionSummaryDrawer,
  sessionIds,
  columnSettings,
  scheduleDataStore: { schedules },
  sessionDataStore: { sessions },
  staffDataStore: { staff },
  page,
  onPageSelect,
  perPage,
  totalCount,
}) {
  const showColumnSettings = columnSettings.length > 0;

  return loading ? (
    <Grid item xs={12}>
      <div style={{ textAlign: 'center' }}>
        <Spinner type="indeterminate" />
      </div>
    </Grid>
  ) : (
    <Grid item container spacing={1} className="class-sessions">
      <Grid item xs={12} className="class-sessions__table-header">
        <div className="class-sessions__table-header-content">
          <Grid container xs={12} alignItems="center" spacing={1}>
            <Grid
              item
              container
              xs={2}
              direction="row"
              alignItems="center"
              spacing={showColumnSettings ? 1 : 0}
            >
              {showColumnSettings && (
                <Grid item>
                  <ColumnsMenu
                    active={columnSettings.some(({ active }) => active)}
                    className="class-sessions__table-header-column-settings"
                    menuItems={columnSettings}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography variant="fieldLabel">
                  {t('.date', intl, __filenamespace)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="fieldLabel">
                {t('.time', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="fieldLabel">
                {t('.location', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="fieldLabel">
                {t('.staff', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="fieldLabel">
                {t('.attendees', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="fieldLabel">
                {t('.checked_in', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="fieldLabel">
                {t('.resources', intl, __filenamespace)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {sessionIds.map(id => (
        <Grid item key={id} xs={12}>
          <SessionCard
            eventId={eventId}
            onSessionCancel={() => onSessionCancel(id)}
            onViewAttendeesClicked={onViewAttendeesClicked}
            openSessionSummaryDrawer={openSessionSummaryDrawer}
            schedules={schedules}
            sessionId={id}
            sessions={sessions}
            staff={staff}
          />
        </Grid>
      ))}
      {sessionIds.isEmpty() && (
        <Grid item xs={12}>
          <EmptyState
            isClassSelected={!!selectedClass}
            listUpcoming={listUpcoming}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paginator
          currentPage={page}
          onPageSelect={onPageSelect}
          perPage={perPage}
          totalCount={totalCount}
        />
      </Grid>
    </Grid>
  );
}

SessionsList.propTypes = {
  page: PropTypes.number,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  eventId: PropTypes.number,
  selectedClass: PropTypes.instanceOf(EventClass),
  listUpcoming: PropTypes.bool,
  loading: PropTypes.bool,
  sessionIds: PropTypes.instanceOf(OrderedSet),
  columnSettings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      onClick: PropTypes.func,
    })
  ),
  onSessionCancel: PropTypes.func,
  onViewAttendeesClicked: PropTypes.func,
  openSessionSummaryDrawer: PropTypes.func,
  onPageSelect: PropTypes.func,
  scheduleDataStore: PropTypes.shape({ schedules: PropTypes.instanceOf(Map) }),
  sessionDataStore: PropTypes.shape({ sessions: PropTypes.instanceOf(Map) }),
  staffDataStore: PropTypes.shape({ staff: PropTypes.instanceOf(Map) }),
};

SessionsList.defaultProps = {
  page: 1,
  perPage: 15,
  totalCount: 0,
  eventId: null,
  selectedClass: null,
  listUpcoming: true,
  loading: false,
  sessionIds: OrderedSet(),
  columnSettings: [],
  onSessionCancel: () => null,
  onViewAttendeesClicked: () => null,
  openSessionSummaryDrawer: () => null,
  onPageSelect: () => null,
  scheduleDataStore: {
    schedules: Map(),
  },
  sessionDataStore: {
    sessions: Map(),
  },
  staffDataStore: {
    staff: Map(),
  },
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      sessionDataStore: SessionDataStore,
      staffDataStore: StaffDataStore,
      scheduleDataStore: ScheduleDataStore,
    },
  })
)(SessionsList);
