import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const defaultStyle = {
  height: 167,
  width: 268,
};

function MonthlyRecurringRevenue({ style }) {
  return (
    <SvgIcon viewBox="0 0 268 167" style={{ ...defaultStyle, ...style }}>
      <g opacity="0.65">
        <path
          d="M225.88 162.525H33.1931C32.5369 162.525 32 161.988 32 161.331C32 160.675 32.5369 160.138 33.1931 160.138H225.88C226.537 160.138 227.073 160.675 227.073 161.331C227.073 161.988 226.543 162.525 225.88 162.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M241.618 162.525H234.459C233.803 162.525 233.266 161.988 233.266 161.331C233.266 160.675 233.803 160.138 234.459 160.138H241.618C242.274 160.138 242.811 160.675 242.811 161.331C242.811 161.988 242.274 162.525 241.618 162.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M225.218 69.1363L221.946 93.4684L221.887 94.0633L221.768 95.1936L221.351 98.2872L221.054 100.429L213.677 155.102C213.439 156.946 212.725 158.79 211.654 160.396C210.405 162.241 208.739 163.847 206.716 165.037C206.597 165.096 206.478 165.156 206.3 165.275C205.11 165.87 203.861 166.346 202.611 166.643C201.6 166.881 200.589 167 199.577 167H197.257V165.81L69.8855 74.907L68.7551 74.7285L69.588 68.8388C70.4804 62.2948 76.7865 57 83.6875 57H214.093C217.663 57 220.935 58.4873 223.017 60.9859C224.861 63.1871 225.635 66.1022 225.218 69.1363Z"
          fill="#393C44"
        />
        <path
          d="M222.006 93.4088C221.768 95.015 221.53 96.6213 221.351 98.2871L221.054 100.429C217.187 105.605 213.26 110.84 209.393 116.016C201.362 116.373 193.39 116.789 185.359 117.146L180.421 70.7425C180.54 70.5045 180.659 70.326 180.778 70.1475C184.288 66.578 203.742 79.6662 222.006 93.4088Z"
          fill="#1C1D21"
        />
        <path
          d="M220.697 95.729C217.901 116.373 215.105 136.957 212.368 157.6C212.368 157.838 212.249 159.028 211.713 160.396C210.464 162.241 208.798 163.847 206.776 165.037C206.657 165.096 206.538 165.215 206.359 165.275C205.883 165.394 205.407 165.453 205.05 165.453C200.41 145.94 195.77 126.486 191.129 106.973L220.697 95.729Z"
          fill="#8E8EA0"
        />
        <path
          d="M189.761 106.259C193.509 126.367 197.198 146.475 200.946 166.584C201.481 166.584 202.076 166.584 202.611 166.524C203.861 166.227 205.11 165.81 206.3 165.156C206.419 165.096 206.538 165.037 206.716 164.918C208.739 163.728 210.464 162.122 211.654 160.277C212.725 158.671 213.379 156.886 213.677 154.983L221.887 93.9443L189.761 106.259ZM211.178 157.541C211.178 157.957 210.821 161.586 207.966 163.371C207.371 163.787 206.657 164.025 205.943 164.204L192.498 107.627L219.328 97.3949L211.178 157.541Z"
          fill="#8E8EA0"
        />
        <path
          d="M180.361 69.9692H49.9557C43.5901 69.9692 39.6042 75.145 40.9725 81.5701L56.7973 154.209C58.1656 160.634 64.4717 165.81 70.8373 165.81H201.243C207.609 165.81 211.594 160.634 210.226 154.209L194.401 81.5701C192.974 75.145 186.727 69.9692 180.361 69.9692Z"
          fill="white"
        />
        <path
          d="M201.243 166.94H70.8374C63.9958 166.94 57.2138 161.348 55.667 154.447L39.8422 81.808C39.0688 78.298 39.7233 75.0259 41.746 72.5273C43.6497 70.1476 46.5648 68.7793 49.9558 68.7793H180.361C187.203 68.7793 193.985 74.3715 195.532 81.2725L211.357 153.912C212.13 157.422 211.476 160.694 209.453 163.192C207.549 165.632 204.634 166.94 201.243 166.94ZM49.9558 71.159C47.2787 71.159 45.018 72.1703 43.5307 74.0146C41.9839 75.9183 41.508 78.5359 42.1029 81.332L57.9277 153.971C59.2365 159.861 65.0072 164.68 70.8374 164.68H201.243C203.92 164.68 206.181 163.668 207.668 161.824C209.215 159.92 209.691 157.303 209.096 154.507L193.271 81.8675C191.962 75.9778 186.192 71.159 180.361 71.159H49.9558Z"
          fill="#8E8EA0"
        />
        <path
          d="M116.408 135.588H110.34L108.02 125.475H114.028L116.408 135.588Z"
          fill="#393C44"
        />
        <path
          d="M128.544 135.588H122.476L118.371 117.86H124.439L128.544 135.588Z"
          fill="#393C44"
        />
        <path
          d="M140.74 135.588H134.672L131.281 121.251H137.349L140.74 135.588Z"
          fill="#393C44"
        />
        <path
          d="M152.876 135.588H146.808L140.68 109.472H146.749L152.876 135.588Z"
          fill="#393C44"
        />
        <path
          d="M109.996 118.858C110.427 118.319 110.235 117.45 109.567 116.917C108.899 116.384 108.009 116.389 107.579 116.929C107.148 117.468 107.34 118.337 108.008 118.87C108.676 119.403 109.566 119.398 109.996 118.858Z"
          fill="#393C44"
        />
        <path
          d="M111.173 117.86C111.173 117.8 111.113 117.741 111.113 117.681C110.935 117.027 110.459 116.491 109.864 116.075C109.388 115.778 108.852 115.599 108.258 115.599C106.949 115.599 106.175 116.61 106.473 117.86C106.77 119.109 108.079 120.12 109.328 120.12C110.637 120.18 111.47 119.109 111.173 117.86ZM108.317 117.86C108.258 117.681 108.317 117.503 108.495 117.443C108.555 117.384 108.614 117.384 108.674 117.384C108.912 117.384 109.209 117.622 109.269 117.86C109.328 118.038 109.269 118.157 109.15 118.276C109.09 118.336 109.031 118.336 108.912 118.336C108.674 118.336 108.377 118.157 108.317 117.86Z"
          fill="#393C44"
        />
        <path
          d="M121.025 112.122C121.455 111.582 121.263 110.713 120.595 110.18C119.927 109.647 119.037 109.652 118.607 110.192C118.176 110.731 118.369 111.6 119.036 112.133C119.704 112.666 120.594 112.661 121.025 112.122Z"
          fill="#393C44"
        />
        <path
          d="M122.179 111.137C122.119 111.018 122.119 110.899 122.06 110.78C121.643 109.71 120.453 108.877 119.323 108.877C118.014 108.877 117.241 109.888 117.538 111.137C117.538 111.197 117.538 111.256 117.598 111.316C117.776 111.97 118.252 112.565 118.847 112.922C119.323 113.22 119.858 113.458 120.453 113.458C121.227 113.458 121.822 113.101 122.119 112.565C122.238 112.149 122.298 111.673 122.179 111.137ZM119.68 110.661C119.977 110.661 120.215 110.899 120.275 111.137C120.334 111.375 120.156 111.613 119.918 111.613C119.68 111.613 119.382 111.375 119.323 111.137C119.263 110.84 119.442 110.661 119.68 110.661Z"
          fill="#393C44"
        />
        <path
          d="M133.993 115.48C134.424 114.941 134.231 114.072 133.563 113.539C132.896 113.006 132.006 113.011 131.575 113.551C131.145 114.09 131.337 114.959 132.005 115.492C132.672 116.025 133.563 116.02 133.993 115.48Z"
          fill="#393C44"
        />
        <path
          d="M135.088 114.529C135.029 114.231 134.85 113.934 134.672 113.636C134.315 113.041 133.72 112.625 133.066 112.387C132.768 112.268 132.471 112.268 132.233 112.268C131.519 112.268 130.924 112.565 130.626 113.101C130.388 113.517 130.269 113.993 130.448 114.588C130.448 114.707 130.507 114.766 130.507 114.885C130.924 116.016 132.114 116.908 133.304 116.908C134.553 116.789 135.386 115.778 135.088 114.529ZM133.244 114.529C133.304 114.766 133.125 115.004 132.887 115.004C132.649 115.004 132.352 114.766 132.292 114.529C132.233 114.291 132.411 114.053 132.649 114.053C132.887 114.053 133.185 114.231 133.244 114.529Z"
          fill="#393C44"
        />
        <path
          d="M143.317 103.624C143.748 103.085 143.555 102.215 142.888 101.683C142.22 101.15 141.33 101.155 140.899 101.694C140.469 102.233 140.661 103.103 141.329 103.636C141.997 104.168 142.887 104.163 143.317 103.624Z"
          fill="#393C44"
        />
        <path
          d="M144.488 102.63C144.19 101.381 142.882 100.369 141.632 100.369C140.323 100.369 139.55 101.381 139.848 102.63C139.907 102.928 140.026 103.225 140.204 103.463C140.561 104.058 141.156 104.534 141.811 104.712C142.108 104.831 142.406 104.891 142.703 104.891C143.952 104.95 144.785 103.879 144.488 102.63ZM141.632 102.69C141.573 102.392 141.751 102.154 141.989 102.154C142.227 102.154 142.525 102.392 142.584 102.63C142.644 102.868 142.465 103.106 142.227 103.106H142.168C141.93 103.106 141.751 102.928 141.632 102.69Z"
          fill="#393C44"
        />
        <path
          d="M109.507 118.693L108.258 117.146L119.442 110.067L132.173 113.458L141.156 102.273L142.763 103.523L133.184 115.48L120.156 112.03L109.507 118.693Z"
          fill="#393C44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.495 115.599C107.306 115.599 106.413 116.611 106.592 117.801C106.77 118.99 107.841 120.002 109.031 120.002C110.221 120.002 111.113 118.99 110.935 117.801C110.816 116.611 109.685 115.599 108.495 115.599Z"
          fill="#393C44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.906 96.324C90.5885 96.324 85.4127 102.214 86.3051 109.531C87.1975 116.849 93.8606 122.738 101.119 122.738C102.963 122.738 104.688 122.381 106.175 121.667C104.926 120.775 103.974 119.347 103.796 117.8C103.736 117.087 103.796 116.432 103.974 115.837L102.903 111.435L102.606 110.364L101.892 114.231C101.713 115.242 101.238 115.659 100.286 115.659C99.3338 115.659 98.7389 115.242 98.3224 114.231L96.6567 110.364L96.4782 113.636C96.4782 114.35 96.4187 115.659 94.8124 115.659C93.8011 115.659 93.0872 114.826 93.0277 114.052C92.9682 113.636 93.0277 112.446 93.0872 111.137L93.3846 105.367C93.5036 103.641 94.5745 103.403 95.2289 103.403C96.8351 103.403 97.3111 104.474 98.2034 106.438L99.3933 109.115L99.9287 106.438C100.345 104.474 100.524 103.403 102.13 103.403C102.784 103.403 103.974 103.641 104.45 105.367L106.175 111.137C106.354 111.792 106.592 112.506 106.711 113.041C107.127 112.922 107.544 112.803 108.02 112.803C109.566 112.803 111.113 113.517 112.124 114.707C112.6 113.101 112.779 111.375 112.541 109.531C111.827 102.273 105.164 96.324 97.906 96.324Z"
          fill="#393C44"
        />
        <path
          d="M8.3379 80.2228H1.19113C0.536008 80.2228 0 79.6852 0 79.0281C0 78.3711 0.536008 77.8335 1.19113 77.8335H8.3379C8.99302 77.8335 9.52903 78.3711 9.52903 79.0281C9.52903 79.6852 8.99302 80.2228 8.3379 80.2228Z"
          fill="#8E8EA0"
        />
        <path
          d="M4.76449 83.8067C4.10937 83.8067 3.57336 83.2691 3.57336 82.6121V75.4442C3.57336 74.7871 4.10937 74.2495 4.76449 74.2495C5.41961 74.2495 5.95562 74.7871 5.95562 75.4442V82.6121C5.95562 83.2691 5.41961 83.8067 4.76449 83.8067Z"
          fill="#8E8EA0"
        />
        <path
          d="M207.852 14.3358H200.705C200.05 14.3358 199.514 13.7982 199.514 13.1412C199.514 12.4841 200.05 11.9465 200.705 11.9465H207.852C208.507 11.9465 209.043 12.4841 209.043 13.1412C209.043 13.7982 208.507 14.3358 207.852 14.3358Z"
          fill="#8E8EA0"
        />
        <path
          d="M204.279 17.9197C203.623 17.9197 203.087 17.3821 203.087 16.7251V9.5572C203.087 8.90014 203.623 8.36255 204.279 8.36255C204.934 8.36255 205.47 8.90014 205.47 9.5572V16.7251C205.47 17.3821 204.934 17.9197 204.279 17.9197Z"
          fill="#8E8EA0"
        />
        <path
          d="M266.394 124.74H262.821C262.166 124.74 261.63 124.202 261.63 123.545C261.63 122.888 262.166 122.351 262.821 122.351H266.394C267.05 122.351 267.586 122.888 267.586 123.545C267.586 124.202 267.05 124.74 266.394 124.74Z"
          fill="#8E8EA0"
        />
        <path
          d="M264.608 126.532C263.953 126.532 263.417 125.994 263.417 125.337V121.753C263.417 121.096 263.953 120.559 264.608 120.559C265.263 120.559 265.799 121.096 265.799 121.753V125.337C265.799 125.994 265.263 126.532 264.608 126.532Z"
          fill="#8E8EA0"
        />
        <path
          d="M65.5121 42.41H61.9387C61.2836 42.41 60.7476 41.8725 60.7476 41.2154C60.7476 40.5583 61.2836 40.0208 61.9387 40.0208H65.5121C66.1673 40.0208 66.7033 40.5583 66.7033 41.2154C66.7033 41.8725 66.1673 42.41 65.5121 42.41Z"
          fill="#8E8EA0"
        />
        <path
          d="M63.7254 44.202C63.0703 44.202 62.5343 43.6644 62.5343 43.0074V39.4234C62.5343 38.7664 63.0703 38.2288 63.7254 38.2288C64.3806 38.2288 64.9166 38.7664 64.9166 39.4234V43.0074C64.9166 43.6644 64.3806 44.202 63.7254 44.202Z"
          fill="#8E8EA0"
        />
        <path
          d="M259.809 67.0438C259.505 67.0438 259.202 66.9243 258.969 66.6913L253.919 61.626C253.454 61.1601 253.454 60.4015 253.919 59.9356C254.384 59.4697 255.14 59.4697 255.604 59.9356L260.655 65.0009C261.119 65.4668 261.119 66.2254 260.655 66.6913C260.423 66.9303 260.113 67.0438 259.809 67.0438Z"
          fill="#8E8EA0"
        />
        <path
          d="M254.759 67.0438C254.455 67.0438 254.151 66.9243 253.919 66.6913C253.454 66.2254 253.454 65.4668 253.919 65.0009L258.969 59.9356C259.434 59.4697 260.19 59.4697 260.655 59.9356C261.119 60.4015 261.119 61.1601 260.655 61.626L255.604 66.6913C255.366 66.9303 255.062 67.0438 254.759 67.0438Z"
          fill="#8E8EA0"
        />
        <path
          d="M113.3 18.6605C112.997 18.6605 112.693 18.541 112.461 18.3081L107.41 13.2427C106.946 12.7768 106.946 12.0182 107.41 11.5523C107.875 11.0864 108.631 11.0864 109.096 11.5523L114.146 16.6176C114.611 17.0835 114.611 17.8421 114.146 18.3081C113.914 18.547 113.604 18.6605 113.3 18.6605Z"
          fill="#8E8EA0"
        />
        <path
          d="M108.25 18.6605C107.946 18.6605 107.643 18.541 107.41 18.3081C106.946 17.8421 106.946 17.0835 107.41 16.6176L112.461 11.5523C112.925 11.0864 113.682 11.0864 114.146 11.5523C114.611 12.0182 114.611 12.7768 114.146 13.2427L109.096 18.3081C108.857 18.547 108.554 18.6605 108.25 18.6605Z"
          fill="#8E8EA0"
        />
        <path
          d="M25.8003 125.091C25.4965 125.091 25.1928 124.972 24.9605 124.739L22.4353 122.206C21.9708 121.74 21.9708 120.982 22.4353 120.516C22.8999 120.05 23.6562 120.05 24.1208 120.516L26.646 123.048C27.1105 123.514 27.1105 124.273 26.646 124.739C26.4077 124.978 26.104 125.091 25.8003 125.091Z"
          fill="#8E8EA0"
        />
        <path
          d="M23.2751 125.091C22.9713 125.091 22.6676 124.972 22.4353 124.739C21.9708 124.273 21.9708 123.514 22.4353 123.048L24.9605 120.516C25.4251 120.05 26.1814 120.05 26.646 120.516C27.1105 120.982 27.1105 121.74 26.646 122.206L24.1208 124.739C23.8825 124.978 23.5788 125.091 23.2751 125.091Z"
          fill="#8E8EA0"
        />
        <path
          d="M170.927 40.7614C170.623 40.7614 170.32 40.6419 170.087 40.4089L167.562 37.8763C167.098 37.4104 167.098 36.6518 167.562 36.1859C168.027 35.7199 168.783 35.7199 169.248 36.1859L171.773 38.7185C172.237 39.1844 172.237 39.943 171.773 40.4089C171.535 40.6479 171.231 40.7614 170.927 40.7614Z"
          fill="#8E8EA0"
        />
        <path
          d="M168.402 40.7614C168.098 40.7614 167.795 40.6419 167.562 40.4089C167.098 39.943 167.098 39.1844 167.562 38.7185L170.087 36.1859C170.552 35.7199 171.308 35.7199 171.773 36.1859C172.237 36.6518 172.237 37.4104 171.773 37.8763L169.248 40.4089C169.009 40.6479 168.706 40.7614 168.402 40.7614Z"
          fill="#8E8EA0"
        />
        <path
          d="M14.4844 37.2422C12.1855 37.2422 10.3154 35.3666 10.3154 33.0609C10.3154 30.7552 12.1855 28.8796 14.4844 28.8796C16.7833 28.8796 18.6533 30.7552 18.6533 33.0609C18.6533 35.3666 16.7833 37.2422 14.4844 37.2422ZM14.4844 31.2689C13.5017 31.2689 12.6977 32.0753 12.6977 33.0609C12.6977 34.0465 13.5017 34.8529 14.4844 34.8529C15.4671 34.8529 16.2711 34.0465 16.2711 33.0609C16.2711 32.0753 15.4671 31.2689 14.4844 31.2689Z"
          fill="#8E8EA0"
        />
        <path
          d="M243.924 100.948C241.625 100.948 239.755 99.0722 239.755 96.7665C239.755 94.4608 241.625 92.5852 243.924 92.5852C246.223 92.5852 248.093 94.4608 248.093 96.7665C248.093 99.0722 246.223 100.948 243.924 100.948ZM243.924 94.9745C242.941 94.9745 242.137 95.7809 242.137 96.7665C242.137 97.7521 242.941 98.5585 243.924 98.5585C244.906 98.5585 245.71 97.7521 245.71 96.7665C245.71 95.7809 244.906 94.9745 243.924 94.9745Z"
          fill="#8E8EA0"
        />
        <path
          d="M147.104 8.36254C144.805 8.36254 142.935 6.48694 142.935 4.18127C142.935 1.8756 144.805 0 147.104 0C149.403 0 151.273 1.8756 151.273 4.18127C151.273 6.48694 149.403 8.36254 147.104 8.36254ZM147.104 2.3893C146.122 2.3893 145.318 3.19569 145.318 4.18127C145.318 5.16686 146.122 5.97325 147.104 5.97325C148.087 5.97325 148.891 5.16686 148.891 4.18127C148.891 3.19569 148.087 2.3893 147.104 2.3893Z"
          fill="#8E8EA0"
        />
        <path
          d="M174.512 22.6984L174.506 20.3091H174.512V22.6984Z"
          fill="#8E8EA0"
        />
        <path
          d="M225.731 45.3966L225.725 43.0073H225.731V45.3966Z"
          fill="#8E8EA0"
        />
        <path
          d="M249.553 32.2555L249.547 29.8662H249.553V32.2555Z"
          fill="#8E8EA0"
        />
        <path
          d="M23.8347 59.7326L23.8287 57.3433H23.8347V59.7326Z"
          fill="#8E8EA0"
        />
        <path
          d="M63.1419 11.9464L63.136 9.55713H63.1419V11.9464Z"
          fill="#8E8EA0"
        />
        <path
          d="M118.529 43.6046L118.523 41.2153H118.529V43.6046Z"
          fill="#8E8EA0"
        />
      </g>
    </SvgIcon>
  );
}

export default MonthlyRecurringRevenue;
