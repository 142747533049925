import React from 'react';
import AltContainer from 'alt-container';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { injectIntl } from 'react-intl';
import DateRangeDropdown from 'components/DateRangeDropdown';
import DateRange from 'shared/records/DateRange';
import { Grid, Dropdown } from '@upperhand/playmaker';
import { t } from 'shared/utils/LocaleUtils.js';

import MrrReportStore from '../stores/MonthlyRecurringRevenueReportStore.jsx';
import MrrReportActions from '../actions/MonthlyRecurringRevenueReportActions.jsx';

const styles = {
  exportButtonContainer: {
    marginLeft: 'auto',
    padding: 12,
  },
  exportButton: {
    color: 'var(--color-secondary)',
  },
  exportButtonDisabled: {
    color: 'var(--color-nav-icon-gray)',
  },
};

class MonthlyRecurringRevenueFilters extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  handleDownload = () => {
    MrrReportActions.downloadMonthlyRecurringRevenueList();
  };

  // eslint-disable-next-line class-methods-use-this
  handleChangeMembershipType = e => {
    MrrReportActions.filterUpdated('membershipType', e.target.value);
    MrrReportActions.hideDetails();
  };

  // eslint-disable-next-line class-methods-use-this
  handleDateChange = ({ value, from: startDate, to: endDate }) => {
    MrrReportActions.filterUpdated(
      'dateRange',
      new DateRange({ value, startDate, endDate })
    );
  };

  render() {
    const { intl, mrrReportStore: store } = this.props;
    const {
      dateRange,
      membershipTypes,
      membershipType,
      monthlyRecurringRevenueListResult,
    } = store;

    return (
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={4}>
          <DateRangeDropdown
            label={t('.time_span', intl, __filenamespace)}
            value={dateRange.value}
            intl={intl}
            onChange={this.handleDateChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Dropdown
            fullWidth
            rounded
            outline={false}
            label={t('.membership_type', intl, __filenamespace)}
            value={membershipType}
            onChange={this.handleChangeMembershipType}
            items={[
              { value: 'all', label: 'All' },
              ...membershipTypes.map(type => ({
                value: type.get('id'),
                label: type.get('name'),
              })),
            ]}
          />
        </Grid>
        <div style={styles.exportButtonContainer}>
          <Button
            style={
              monthlyRecurringRevenueListResult.length === 0
                ? styles.exportButtonDisabled
                : styles.exportButton
            }
            onClick={this.handleDownload}
            endIcon={<CloudDownloadIcon />}
            disabled={monthlyRecurringRevenueListResult.length === 0}
          >
            {t('.export_report', intl, __filenamespace)}
          </Button>
        </div>
      </Grid>
    );
  }
}

function MonthlyRecurringRevenueFiltersWrapper({ intl }) {
  return (
    <AltContainer
      stores={{
        mrrReportStore: MrrReportStore,
      }}
    >
      <MonthlyRecurringRevenueFilters intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(MonthlyRecurringRevenueFiltersWrapper);
