// NOTE: experiment with not using class? can still dispatch from console?
class CustomerActions {
  constructor() {
    this.generateActions(
      'fetch',
      'fetchSuccess',
      'fetchError',
      'addBankAccount',
      'addBankAccountSuccess',
      'addBankAccountError',
      'changeEmailPreference',
      'removeBankAccount',
      'removeBankAccountSuccess',
      'removeBankAccountError',
      'update',
      'updateSuccess',
      'updateError',
      'updateNewBankAccount',
      'updateLegalEntity',
      'validate',
      'updateStore',
      'setBlackoutDates',
      'deleteBlackoutDates',
      'saveBlackoutDates',
      'blackoutDateSuccess',
      'saveBlackoutDatesError',
      'handleUpdateSportsOffered',
      'handleAvailabilityChange'
    );
  }
}

export default alt.createActions(CustomerActions);
