import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import UIStore from 'shared/stores/UIStore.jsx';

class MembershipListingUIStore extends UIStore {
  constructor() {
    super();

    this.bindListeners({
      handleLoading: MembershipListingActions.LIST_REQUESTED,

      handleDoneWaiting: [
        MembershipListingActions.LIST_RECEIVED,
        MembershipListingActions.ERROR,
      ],
    });
  }
}

export default alt.createStore(
  MembershipListingUIStore,
  'MembershipListingUIStore'
);
