import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import Membership from 'shared/records/Membership.jsx';

import ModalComponentMembership from './ModalComponentMemberhips.jsx';
import { MembershipViewingAdminEventsModal } from './MembershipViewing.jsx';

export default function MembershipViewingAdmin({
  membershipTiers,
  membershipTiersLoading,
  membership,
  eventTypes,
  creditPasses,
  retailCategories,
  isOpen,
  isClose,
}) {
  return (
    <ModalComponentMembership isOpen={isOpen} isClose={isClose}>
      <MembershipViewingAdminEventsModal
        membershipTiers={membershipTiers}
        membershipTiersLoading={membershipTiersLoading}
        membership={membership}
        retailCategories={retailCategories}
        eventTypes={eventTypes}
        creditPasses={creditPasses}
      />
    </ModalComponentMembership>
  );
}

MembershipViewingAdmin.propTypes = {
  membershipTiers: PropTypes.instanceOf(List),
  membershipTiersLoading: PropTypes.bool,
  membership: PropTypes.instanceOf(Membership),
  eventTypes: PropTypes.instanceOf(List),
  creditPasses: PropTypes.instanceOf(List),
  retailCategories: PropTypes.instanceOf(List),
  isOpen: PropTypes.bool,
  isClose: PropTypes.func,
};

MembershipViewingAdmin.defaultProps = {
  membershipTiers: List(),
  membershipTiersLoading: false,
  membership: new Membership(),
  eventTypes: List(),
  creditPasses: List(),
  retailCategories: List(),
  isOpen: false,
  isClose: () => {},
};
