import ServiceFee from 'shared/records/ServiceFee';
import ServiceFeeArchivingActions from 'shared/actions/service_fee/ServiceFeeArchivingActions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class ServiceFeeArchivingStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      askForConfirmation: ServiceFeeArchivingActions.ARCHIVE_REQUESTED,
      archiveServiceFee: ServiceFeeArchivingActions.ARCHIVE_CONFIRMED,
      abortArchive: ServiceFeeArchivingActions.ARCHIVE_ABORTED,
      archiveSuccess: ServiceFeeArchivingActions.ARCHIVE_SUCCESS,
      archiveError: ServiceFeeArchivingActions.ARCHIVE_ERROR,
    });
  }

  reset() {
    this.serviceFee = new ServiceFee();
    this.confirmationOpen = false;
  }

  askForConfirmation(serviceFee) {
    this.serviceFee = serviceFee;
    this.confirmationOpen = true;
  }

  archiveServiceFee() {
    if (!this.serviceFee.id) {
      return false;
    }

    return uhApiClient.patch({
      url: `accounting_codes/${this.serviceFee.id}`,
      data: JSON.stringify({ attributes: { archived: true } }),
      success: ServiceFeeArchivingActions.archiveSuccess,
      error: ServiceFeeArchivingActions.archiveError,
    });
  }

  abortArchive() {
    this.reset();
  }

  archiveSuccess(_data) {
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({ id: '.archived', filename: __filenamespace })
    );

    this.reset();
  }

  archiveError() {
    this.notifyError('error archiving accountingCode', arguments);
  }
}

export default alt.createStore(
  ServiceFeeArchivingStore,
  'ServiceFeeArchivingStore'
);
