import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const defaultStyle = {
  height: 167,
  width: 268,
};

function OrdersReport({ style }) {
  return (
    <SvgIcon
      viewBox="0 0 268 167"
      fill="none"
      style={{ ...defaultStyle, ...style }}
    >
      <g opacity="0.65">
        <path
          d="M225.88 162.525H33.1931C32.5369 162.525 32 161.988 32 161.331C32 160.675 32.5369 160.138 33.1931 160.138H225.88C226.537 160.138 227.073 160.675 227.073 161.331C227.073 161.988 226.543 162.525 225.88 162.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M241.618 162.525H234.459C233.803 162.525 233.266 161.988 233.266 161.331C233.266 160.675 233.803 160.138 234.459 160.138H241.618C242.274 160.138 242.811 160.675 242.811 161.331C242.811 161.988 242.274 162.525 241.618 162.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M225.779 68.9082L222.492 93.3447L222.415 93.9116L222.266 95.0334L221.848 98.1305L221.562 100.261L214.182 155.113C213.932 156.987 213.234 158.795 212.172 160.412C210.949 162.274 209.249 163.885 207.197 165.067C207.059 165.144 206.928 165.216 206.791 165.293C206.785 165.299 206.779 165.299 206.779 165.299C205.604 165.92 204.363 166.38 203.092 166.666C202.096 166.893 201.076 167 200.056 167H197.759V165.789L69.983 74.6787L68.8436 74.5056L69.6489 68.5681C70.5318 62.0219 76.8672 56.687 83.7694 56.687H214.558C218.161 56.687 221.43 58.149 223.53 60.7031C225.386 62.9707 226.185 65.8828 225.779 68.9082Z"
          fill="var(--color-primary)"
        />
        <path
          d="M222.528 93.2553C222.301 94.8784 222.074 96.5075 221.848 98.1306L221.561 100.261C217.66 105.471 213.764 110.68 209.863 115.89C201.827 116.271 193.786 116.659 185.75 117.041L180.781 70.5255C180.876 70.2987 181.002 70.1078 181.157 69.9526C184.73 66.3185 204.214 79.4646 222.528 93.2553Z"
          fill="var(--color-old-gray)"
        />
        <path
          d="M221.199 95.5647C218.407 116.242 215.615 136.925 212.823 157.602C212.805 157.834 212.716 159.016 212.173 160.412C210.95 162.274 209.25 163.885 207.198 165.067C207.061 165.144 206.929 165.222 206.792 165.294C206.786 165.3 206.78 165.3 206.78 165.3C206.291 165.425 205.85 165.479 205.492 165.502C200.85 145.953 196.203 126.392 191.562 106.843L221.199 95.5647Z"
          fill="#8E8EA0"
        />
        <path
          d="M190.207 106.175C193.942 126.344 197.682 146.52 201.422 166.696C201.977 166.684 202.538 166.678 203.093 166.666C204.363 166.386 205.604 165.926 206.78 165.3C206.78 165.3 206.785 165.3 206.791 165.294C206.929 165.222 207.06 165.144 207.197 165.067C209.249 163.885 210.949 162.274 212.172 160.412C213.234 158.795 213.932 156.987 214.183 155.113L222.415 93.9058L190.207 106.175ZM211.677 157.59C211.647 158.001 211.331 161.618 208.426 163.426C207.806 163.82 207.12 164.088 206.392 164.237L192.916 107.511L219.814 97.2714L211.677 157.59Z"
          fill="#8E8EA0"
        />
        <path
          d="M180.781 69.7615H49.9859C43.6206 69.7615 39.5879 74.9651 40.9898 81.386L56.8642 154.218C58.2661 160.639 64.5597 165.842 70.9309 165.842H201.726C208.091 165.842 212.124 160.639 210.722 154.218L194.847 81.386C193.445 74.9651 187.146 69.7615 180.781 69.7615Z"
          fill="white"
        />
        <path
          d="M201.726 167H70.9314C64.0651 167 57.2524 161.379 55.7491 154.469L39.8688 81.6307C39.1052 78.1219 39.7734 74.8219 41.7599 72.3395C43.6808 69.9286 46.6039 68.5979 49.9864 68.5979H180.781C187.647 68.5979 194.46 74.2192 195.963 81.1294L211.844 153.961C212.607 157.47 211.939 160.77 209.953 163.253C208.032 165.669 205.109 167 201.726 167ZM49.9864 70.9192C47.3138 70.9192 45.0231 71.9396 43.5496 73.7955C42.0105 75.723 41.5034 78.3307 42.1119 81.1354L57.9922 153.967C59.2807 159.875 65.0852 164.685 70.9374 164.685H201.732C204.405 164.685 206.695 163.664 208.169 161.809C209.708 159.881 210.215 157.273 209.607 154.469L193.726 81.6366C192.438 75.7289 186.633 70.9192 180.781 70.9192H49.9864Z"
          fill="#8E8EA0"
        />
        <path
          d="M105.717 134.985H98.5166L95.7068 122.949H102.907L105.717 134.985Z"
          fill="var(--color-primary)"
        />
        <path
          d="M120.148 134.985H112.947L108.032 113.92H115.232L120.148 134.985Z"
          fill="var(--color-primary)"
        />
        <path
          d="M134.579 134.985H127.384L123.399 117.93H130.6L134.579 134.985Z"
          fill="var(--color-primary)"
        />
        <path
          d="M149.015 134.985H141.815L134.567 103.943H141.767L149.015 134.985Z"
          fill="var(--color-primary)"
        />
        <path
          d="M98.1428 115.087C98.6524 114.448 98.4143 113.41 97.6109 112.769C96.8074 112.127 95.7429 112.125 95.2332 112.764C94.7236 113.403 94.9617 114.441 95.7651 115.082C96.5686 115.724 97.6331 115.726 98.1428 115.087Z"
          fill="var(--color-primary)"
        />
        <path
          d="M97.3174 116.63C95.7843 116.63 94.2511 115.412 93.8992 113.914C93.5472 112.417 94.5136 111.199 96.0467 111.199C97.5799 111.199 99.113 112.417 99.465 113.914C99.817 115.412 98.8506 116.63 97.3174 116.63ZM96.5538 113.353C96.2317 113.353 96.0348 113.61 96.1064 113.92C96.178 114.231 96.5001 114.487 96.8163 114.487C97.1384 114.487 97.3353 114.231 97.2637 113.92C97.1921 113.604 96.87 113.353 96.5538 113.353Z"
          fill="var(--color-primary)"
        />
        <path
          d="M111.214 107.062C111.724 106.423 111.486 105.385 110.682 104.744C109.879 104.102 108.814 104.1 108.305 104.739C107.795 105.378 108.033 106.416 108.836 107.057C109.64 107.699 110.704 107.701 111.214 107.062Z"
          fill="var(--color-primary)"
        />
        <path
          d="M110.388 108.603C108.855 108.603 107.321 107.386 106.969 105.888C106.617 104.39 107.584 103.173 109.117 103.173C110.65 103.173 112.183 104.39 112.535 105.888C112.887 107.386 111.921 108.603 110.388 108.603ZM109.624 105.327C109.302 105.327 109.105 105.584 109.177 105.894C109.248 106.204 109.57 106.461 109.887 106.461C110.209 106.461 110.406 106.204 110.334 105.894C110.262 105.578 109.946 105.327 109.624 105.327Z"
          fill="var(--color-primary)"
        />
        <path
          d="M126.581 111.075C127.09 110.436 126.852 109.398 126.049 108.757C125.245 108.115 124.181 108.113 123.671 108.752C123.161 109.391 123.4 110.429 124.203 111.07C125.006 111.711 126.071 111.714 126.581 111.075Z"
          fill="var(--color-primary)"
        />
        <path
          d="M125.76 112.619C124.227 112.619 122.694 111.402 122.342 109.904C121.99 108.406 122.956 107.189 124.489 107.189C126.023 107.189 127.556 108.406 127.908 109.904C128.254 111.402 127.293 112.619 125.76 112.619ZM124.991 109.337C124.668 109.337 124.472 109.594 124.543 109.904C124.615 110.214 124.937 110.471 125.253 110.471C125.575 110.471 125.772 110.214 125.7 109.904C125.629 109.594 125.313 109.337 124.991 109.337Z"
          fill="var(--color-primary)"
        />
        <path
          d="M137.726 96.9769C138.236 96.3381 137.997 95.3002 137.194 94.6588C136.391 94.0174 135.326 94.0153 134.816 94.6541C134.307 95.2929 134.545 96.3308 135.348 96.9722C136.152 97.6136 137.216 97.6157 137.726 96.9769Z"
          fill="var(--color-primary)"
        />
        <path
          d="M136.9 98.5244C135.366 98.5244 133.833 97.3071 133.481 95.8093C133.129 94.3115 134.096 93.0941 135.629 93.0941C137.162 93.0941 138.695 94.3115 139.047 95.8093C139.399 97.3071 138.433 98.5244 136.9 98.5244ZM136.136 95.2424C135.814 95.2424 135.617 95.499 135.688 95.8093C135.76 96.1196 136.082 96.3762 136.398 96.3762C136.721 96.3762 136.917 96.1196 136.846 95.8093C136.774 95.499 136.452 95.2424 136.136 95.2424Z"
          fill="var(--color-primary)"
        />
        <path
          d="M97.5022 114.893L95.987 113.019L109.326 104.641L124.461 108.669L135.079 95.3616L136.988 96.8833L125.654 111.086L110.137 106.956L97.5022 114.893Z"
          fill="var(--color-primary)"
        />
        <path
          d="M8.3379 80.2227H1.19113C0.536008 80.2227 0 79.6852 0 79.0281C0 78.371 0.536008 77.8335 1.19113 77.8335H8.3379C8.99302 77.8335 9.52903 78.371 9.52903 79.0281C9.52903 79.6852 8.99302 80.2227 8.3379 80.2227Z"
          fill="#8E8EA0"
        />
        <path
          d="M4.76449 83.8067C4.10937 83.8067 3.57336 83.2691 3.57336 82.6121V75.4442C3.57336 74.7871 4.10937 74.2495 4.76449 74.2495C5.41961 74.2495 5.95562 74.7871 5.95562 75.4442V82.6121C5.95562 83.2691 5.41961 83.8067 4.76449 83.8067Z"
          fill="#8E8EA0"
        />
        <path
          d="M207.852 14.3358H200.705C200.05 14.3358 199.514 13.7982 199.514 13.1411C199.514 12.4841 200.05 11.9465 200.705 11.9465H207.852C208.507 11.9465 209.043 12.4841 209.043 13.1411C209.043 13.7982 208.507 14.3358 207.852 14.3358Z"
          fill="#8E8EA0"
        />
        <path
          d="M204.279 17.9197C203.623 17.9197 203.087 17.3821 203.087 16.7251V9.55718C203.087 8.90012 203.623 8.36253 204.279 8.36253C204.934 8.36253 205.47 8.90012 205.47 9.55718V16.7251C205.47 17.3821 204.934 17.9197 204.279 17.9197Z"
          fill="#8E8EA0"
        />
        <path
          d="M266.394 124.74H262.821C262.166 124.74 261.63 124.202 261.63 123.545C261.63 122.888 262.166 122.351 262.821 122.351H266.394C267.05 122.351 267.586 122.888 267.586 123.545C267.586 124.202 267.05 124.74 266.394 124.74Z"
          fill="#8E8EA0"
        />
        <path
          d="M264.608 126.532C263.953 126.532 263.417 125.994 263.417 125.337V121.753C263.417 121.096 263.953 120.559 264.608 120.559C265.263 120.559 265.799 121.096 265.799 121.753V125.337C265.799 125.994 265.263 126.532 264.608 126.532Z"
          fill="#8E8EA0"
        />
        <path
          d="M65.5121 42.41H61.9387C61.2836 42.41 60.7476 41.8724 60.7476 41.2154C60.7476 40.5583 61.2836 40.0207 61.9387 40.0207H65.5121C66.1673 40.0207 66.7033 40.5583 66.7033 41.2154C66.7033 41.8724 66.1673 42.41 65.5121 42.41Z"
          fill="#8E8EA0"
        />
        <path
          d="M63.7254 44.202C63.0703 44.202 62.5343 43.6644 62.5343 43.0073V39.4234C62.5343 38.7663 63.0703 38.2287 63.7254 38.2287C64.3806 38.2287 64.9166 38.7663 64.9166 39.4234V43.0073C64.9166 43.6644 64.3806 44.202 63.7254 44.202Z"
          fill="#8E8EA0"
        />
        <path
          d="M259.809 67.0438C259.505 67.0438 259.202 66.9243 258.969 66.6913L253.919 61.626C253.454 61.1601 253.454 60.4015 253.919 59.9356C254.384 59.4697 255.14 59.4697 255.604 59.9356L260.655 65.0009C261.119 65.4668 261.119 66.2254 260.655 66.6913C260.423 66.9303 260.113 67.0438 259.809 67.0438Z"
          fill="#8E8EA0"
        />
        <path
          d="M254.759 67.0438C254.455 67.0438 254.151 66.9243 253.919 66.6913C253.454 66.2254 253.454 65.4668 253.919 65.0009L258.969 59.9356C259.434 59.4697 260.19 59.4697 260.655 59.9356C261.119 60.4015 261.119 61.1601 260.655 61.626L255.604 66.6913C255.366 66.9303 255.062 67.0438 254.759 67.0438Z"
          fill="#8E8EA0"
        />
        <path
          d="M113.3 18.6605C112.997 18.6605 112.693 18.541 112.461 18.3081L107.41 13.2428C106.946 12.7769 106.946 12.0183 107.41 11.5523C107.875 11.0864 108.631 11.0864 109.096 11.5523L114.146 16.6177C114.611 17.0836 114.611 17.8422 114.146 18.3081C113.914 18.547 113.604 18.6605 113.3 18.6605Z"
          fill="#8E8EA0"
        />
        <path
          d="M108.25 18.6605C107.946 18.6605 107.643 18.541 107.41 18.3081C106.946 17.8422 106.946 17.0836 107.41 16.6177L112.461 11.5523C112.925 11.0864 113.682 11.0864 114.146 11.5523C114.611 12.0183 114.611 12.7769 114.146 13.2428L109.096 18.3081C108.857 18.547 108.554 18.6605 108.25 18.6605Z"
          fill="#8E8EA0"
        />
        <path
          d="M25.8003 125.091C25.4965 125.091 25.1928 124.972 24.9605 124.739L22.4353 122.206C21.9708 121.74 21.9708 120.982 22.4353 120.516C22.8999 120.05 23.6562 120.05 24.1208 120.516L26.646 123.048C27.1105 123.514 27.1105 124.273 26.646 124.739C26.4077 124.978 26.104 125.091 25.8003 125.091Z"
          fill="#8E8EA0"
        />
        <path
          d="M23.2751 125.091C22.9713 125.091 22.6676 124.972 22.4353 124.739C21.9708 124.273 21.9708 123.514 22.4353 123.048L24.9605 120.516C25.4251 120.05 26.1814 120.05 26.646 120.516C27.1105 120.982 27.1105 121.74 26.646 122.206L24.1208 124.739C23.8825 124.978 23.5788 125.091 23.2751 125.091Z"
          fill="#8E8EA0"
        />
        <path
          d="M170.927 40.7613C170.623 40.7613 170.32 40.6419 170.087 40.4089L167.562 37.8763C167.098 37.4103 167.098 36.6517 167.562 36.1858C168.027 35.7199 168.783 35.7199 169.248 36.1858L171.773 38.7185C172.237 39.1844 172.237 39.943 171.773 40.4089C171.535 40.6478 171.231 40.7613 170.927 40.7613Z"
          fill="#8E8EA0"
        />
        <path
          d="M168.402 40.7613C168.098 40.7613 167.795 40.6419 167.562 40.4089C167.098 39.943 167.098 39.1844 167.562 38.7185L170.087 36.1858C170.552 35.7199 171.308 35.7199 171.773 36.1858C172.237 36.6517 172.237 37.4103 171.773 37.8763L169.248 40.4089C169.009 40.6478 168.706 40.7613 168.402 40.7613Z"
          fill="#8E8EA0"
        />
        <path
          d="M14.4844 37.2422C12.1855 37.2422 10.3154 35.3666 10.3154 33.0609C10.3154 30.7552 12.1855 28.8796 14.4844 28.8796C16.7833 28.8796 18.6533 30.7552 18.6533 33.0609C18.6533 35.3666 16.7833 37.2422 14.4844 37.2422ZM14.4844 31.2689C13.5017 31.2689 12.6977 32.0753 12.6977 33.0609C12.6977 34.0465 13.5017 34.8529 14.4844 34.8529C15.4671 34.8529 16.2711 34.0465 16.2711 33.0609C16.2711 32.0753 15.4671 31.2689 14.4844 31.2689Z"
          fill="#8E8EA0"
        />
        <path
          d="M243.924 100.948C241.625 100.948 239.755 99.0722 239.755 96.7665C239.755 94.4608 241.625 92.5852 243.924 92.5852C246.223 92.5852 248.093 94.4608 248.093 96.7665C248.093 99.0722 246.223 100.948 243.924 100.948ZM243.924 94.9745C242.941 94.9745 242.137 95.7809 242.137 96.7665C242.137 97.7521 242.941 98.5585 243.924 98.5585C244.906 98.5585 245.71 97.7521 245.71 96.7665C245.71 95.7809 244.906 94.9745 243.924 94.9745Z"
          fill="#8E8EA0"
        />
        <path
          d="M147.104 8.36254C144.805 8.36254 142.935 6.48694 142.935 4.18127C142.935 1.8756 144.805 0 147.104 0C149.403 0 151.273 1.8756 151.273 4.18127C151.273 6.48694 149.403 8.36254 147.104 8.36254ZM147.104 2.3893C146.122 2.3893 145.318 3.19569 145.318 4.18127C145.318 5.16686 146.122 5.97325 147.104 5.97325C148.087 5.97325 148.891 5.16686 148.891 4.18127C148.891 3.19569 148.087 2.3893 147.104 2.3893Z"
          fill="#8E8EA0"
        />
        <path
          d="M174.512 22.6984L174.506 20.3091H174.512V22.6984Z"
          fill="#8E8EA0"
        />
        <path
          d="M225.731 45.3967L225.725 43.0074H225.731V45.3967Z"
          fill="#8E8EA0"
        />
        <path
          d="M249.553 32.2555L249.547 29.8662H249.553V32.2555Z"
          fill="#8E8EA0"
        />
        <path
          d="M23.8347 59.7325L23.8287 57.3432H23.8347V59.7325Z"
          fill="#8E8EA0"
        />
        <path
          d="M63.1419 11.9465L63.136 9.55717H63.1419V11.9465Z"
          fill="#8E8EA0"
        />
        <path
          d="M118.529 43.6047L118.523 41.2154H118.529V43.6047Z"
          fill="#8E8EA0"
        />
      </g>
    </SvgIcon>
  );
}

export default OrdersReport;
