import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function UpperHand(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 39.1 43.9" {...props}>
      <path
        className="st0"
        d="M39.1,29.9c-2.3-1.3-4.6-2.3-6.9-3.2V0l-9.7,2.9C21,3.4,20,4.8,20,6.4v5.4L8.4,15.2c-1.1,0.3-1.8,1.3-1.8,2.4v0l-4.1,1.2C1,19.3,0,20.7,0,22.3v2.3v1.9v6v4.7v0c0,0.3,0.2,0.6,0.5,0.8l10.2,5.9c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0,0c0.5,0,0.9-0.4,0.9-0.9c0-0.3-0.2-0.6-0.4-0.7l-6.7-6c-0.8-0.7-1.2-1.7-1.2-2.7v-2.3c1.1-0.7,2.3-1.2,3.5-1.7c0.6,1.4,2.4,4.4,6.3,5.3c0.4,1.7,1.7,3.1,2.3,3.7c0.5-4.8,3.2-6.7,3.2-6.7c-3.7,0-5.1-1.9-5.6-3.7c1-0.1,1.9-0.2,2.9-0.2c4.4,0,8.6,1,12.1,2.2v3.4c0,1-0.4,2-1.2,2.7l-6.8,6.1c-0.3,0.3-0.4,0.8-0.1,1.2c0.2,0.2,0.4,0.4,0.7,0.4c0.1,0,0.3,0,0.4-0.1l10.2-5.9c0.3-0.2,0.5-0.5,0.5-0.8v0v-5.5c0.8,0.4,1.5,0.7,2.1,1L39.1,29.9z M23.7,10.6V7.8C23.7,7,24.2,6.3,25,6L28.5,5v4.2V13v12.4c-1.6-0.5-3.2-0.8-4.8-1v-9.9V10.6z M20,15.6V24c-0.6,0-1.1-0.1-1.7-0.1c-0.4,0-0.8,0-1.3,0v-7.5L20,15.6z M13.3,17.6v6.7c-1,0.2-2,0.3-3,0.6v-6.4L13.3,17.6z M3.7,24.6v-2.2l3-0.9v1.3V26c-1.1,0.4-2.1,0.8-3,1.3v-0.9V24.6z"
      />
    </SvgIcon>
  );
}

UpperHand.defaultProps = {
  color: 'var(--color-green)',
};

export default UpperHand;
