import { List } from 'immutable';
import Variant from 'shared/records/Variant.js';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './VariantActions';

const parserTo = { type: Variant, paginationKey: 'variants' };

const parser = new Parser(parserTo);

// eslint-disable-next-line import/prefer-default-export
export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error,
  });
};

export const patch = ({ id, params, success, error }) => {
  uhApiClient.patch({
    url: `purchase_orders/${id}`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const post = ({ newOrderItems, success, error }) => {
  uhApiClient.post({
    url: `inventory_adjustments/bulk_creating`,
    data: JSON.stringify({ attributes: newOrderItems }),
    success: onSuccess(data => parser.parseSingle(data), List([success])),
    error: onError(List([error])),
  });
};
