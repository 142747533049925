import SideNavActions from 'shared/actions/SideNavActions.jsx';

class SideNavStore {
  constructor() {
    this.open = false;

    this.bindListeners({
      handleToggle: SideNavActions.TOGGLE,
      handleClose: SideNavActions.CLOSE,
    });
  }

  handleToggle([open]) {
    this.open = open;
  }

  handleClose() {
    this.open = false;
  }
}

export default alt.createStore(SideNavStore, 'SideNavStore');
