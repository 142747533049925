export default alt.generateActions(
  'mounted',
  'payrollEntriesList',
  'payrollEntriesListSuccess',
  'payrollEntriesListError',
  'filterUpdated',
  'combinedFilterUpdated',
  'dateFilterUpdated',
  'paymentFilterUpdated',
  'attendeeFilterUpdated',
  'filtersCleared',
  'downloadList',
  'downloadListSuccess',
  'downloadListError',
  'toggleFilterDrawer',
  'changePaymentStatus',
  'paymentStatusSuccess',
  'paymentStatusError',
  'clientFilterUpdated',
  'itemFilterUpdated'
);
