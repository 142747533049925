import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown } from '@upperhand/playmaker';
import { t } from 'shared/utils/LocaleUtils.js';

// SelectField expects:
//  - values[fieldName] & errors[fieldName] are in AccountSetupPaysafeStore
//  - .{fieldName}_label & .{fieldName}_hint are in intl
function SelectField({ intl, fieldName, value, errorKey, onChange, items }) {
  return (
    <Dropdown
      name={fieldName}
      onChange={e => onChange(fieldName, e.target.value)}
      value={value}
      items={items}
      fullWidth
      label={t(`.${fieldName}_label`, intl, __filenamespace)}
      placeholder={t(`.${fieldName}_hint`, intl, __filenamespace)}
      errorText={errorKey}
    />
  );
}

export default injectIntl(SelectField);
