import { Map } from 'immutable';
import ContactsReportActions from 'reporting/index/actions/_ContactsReportActions.jsx';
import FranchiseLocationsActions from 'reporting/index/actions/_FranchiseLocationsActions.jsx';
import RevenueReportActions from 'reporting/revenue/actions/_RevenueReportActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

class FranchiseLocationsStore {
  constructor() {
    this.areFranchiseLocationsLoading = false;
    this.franchiseLocations = Map();

    this.bindListeners({
      fetchFranchiseLocations: [
        FranchiseLocationsActions.FETCH,
        ContactsReportActions.MOUNTED,
        RevenueReportActions.MOUNTED,
      ],
      fetchFranchiseLocationsSuccess:
        FranchiseLocationsActions.FETCH_FRANCHISE_LOCATIONS_SUCCESS,
      fetchFranchiseLocationsError:
        FranchiseLocationsActions.FETCH_FRANCHISE_LOCATIONS_ERROR,
    });
  }

  fetchFranchiseLocations() {
    if (currentUser().isAdmin()) {
      this.areFranchiseLocationsLoading = true;
      uhApiClient.get({
        url: 'analytics/franchise_locations',
        success: FranchiseLocationsActions.fetchFranchiseLocationsSuccess,
        error: FranchiseLocationsActions.fetchFranchiseLocationsError,
      });
    }
  }

  fetchFranchiseLocationsSuccess(data) {
    this.franchiseLocations = Map().withMutations(m =>
      data.franchise_locations.map(s => m.set(s.id, s))
    );
    this.areFranchiseLocationsLoading = false;
  }

  fetchFranchiseLocationsError() {
    this.areFranchiseLocationsLoading = false;
  }
}

export default alt.createStore(
  FranchiseLocationsStore,
  'FranchiseLocationsStore'
);
