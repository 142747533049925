import { BalancesDataStore } from 'dataStores';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import {
  BalancesSource,
  ClientSource,
  LocationSource,
  PaymentPlanSource,
} from 'sources';
import { isFunction, isArray } from 'shared/utils/SharedUtils.js';
import ClientProfileActions from 'containers/clientProfile/Actions';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import BalanceDetailsDrawerActions from './Actions';

class BalanceDetailsDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      fetchBalanceSuccess: BalanceDetailsDrawerActions.fetchBalanceSuccess,
      fetchBalanceDetailsSuccess:
        BalanceDetailsDrawerActions.fetchBalanceDetailsSuccess,
      fetchBalanceDetailsError:
        BalanceDetailsDrawerActions.fetchBalanceDetailsError,
      openDrawer: [
        BalanceDetailsDrawerActions.openDrawer,
        ClientProfileActions.openViewBalanceDrawer,
        ClientProfileDrawerActions.openViewBalanceDrawer,
      ],
      closeDrawer: BalanceDetailsDrawerActions.closeDrawer,
      closeAllDrawers: BalanceDetailsDrawerActions.closeAllDrawers,
      fetchClientResponse: BalanceDetailsDrawerActions.fetchClientResponse,
      fetchLocationResponse: BalanceDetailsDrawerActions.fetchLocationResponse,
      sendReceipt: BalanceDetailsDrawerActions.sendReceipt,
      payBalance: BalanceDetailsDrawerActions.payBalance,
      toggleDotsMenu: BalanceDetailsDrawerActions.toggleDotsMenu,
      fetchPaymentPlanDetailSuccess:
        BalanceDetailsDrawerActions.planDetailsFetchSuccess,
      fetchPaymentPlanDetailError:
        BalanceDetailsDrawerActions.planDetailsFetchError,
    });
  }

  reset() {
    this.balanceCompoundId = null;
    this.balanceProductId = null;
    this.balanceLocationId = null;
    this.balanceClientId = null;
    this.isLoadingBalanceDetails = false;
    this.isLoadingClient = false;
    this.isLoadingLocation = false;
    this.isLoadingBalance = false;
    this.dotsMenuAnchorEl = null;
    this.closeOtherDrawersActions = [];
    this.paymentPlanId = null;
    this.remainingScheduledPayments = 0;
  }

  openDrawer(args) {
    const [compoundId, closeOtherDrawersActions = [], orderItemId] = isArray(
      args
    )
      ? args
      : [args];

    this.closeOtherDrawersActions = closeOtherDrawersActions;

    if (compoundId) {
      this.setBalanceData(compoundId);
      this.isLoadingBalanceDetails = true;
      this.fetchBalanceDetails();
      this.fetchDependencies();
    } else {
      this.isLoadingBalance = true;
      this.balanceProductId = orderItemId;
      this.balanceProductId = orderItemId;
      this.fetchBalance();
    }
  }

  setBalanceData(compoundId) {
    const { balances } = BalancesDataStore.getState();
    const balance = balances.get(compoundId);

    this.balanceCompoundId = compoundId;
    this.balanceProductId = balance.get('productId');
    this.balanceLocationId = balance.get('locationId');
    this.balanceClientId = balance.get('clientId');
  }

  fetchBalance() {
    BalancesSource.list({
      params: {
        product_ids: this.balanceProductId,
      },
      success: BalanceDetailsDrawerActions.fetchBalanceSuccess,
    });
  }

  fetchBalanceSuccess({ records }) {
    const balance = records.first();

    if (!balance) return;

    this.balanceCompoundId = balance.get('compoundId');
    this.balanceProductId = balance.get('productId');
    this.balanceLocationId = balance.get('locationId');
    this.balanceClientId = balance.get('clientId');

    this.isLoadingBalance = false;
    this.isLoadingBalanceDetails = true;

    this.fetchBalanceDetails();
    this.fetchDependencies();
  }

  fetchBalanceDetails() {
    BalancesSource.details({
      productId: this.balanceProductId,
      compoundId: this.balanceCompoundId,
      success: BalanceDetailsDrawerActions.fetchBalanceDetailsSuccess,
      error: BalanceDetailsDrawerActions.fetchBalanceDetailsError,
    });
  }

  fetchBalanceDetailsSuccess({ collection }) {
    this.paymentPlanId = collection.find(
      detail => detail.type === 'PaymentPlan'
    )?.sourceId;
    if (this.paymentPlanId) {
      PaymentPlanSource.fetch({
        id: this.paymentPlanId,
        params: { fields: ['allocations'] },
        success: BalanceDetailsDrawerActions.planDetailsFetchSuccess,
        error: BalanceDetailsDrawerActions.planDetailsFetchError,
      });
    } else {
      this.isLoadingBalanceDetails = false;
    }
  }

  fetchPaymentPlanDetailSuccess(data) {
    this.remainingScheduledPayments = data.remainingScheduledPayments.size;
    this.isLoadingBalanceDetails = false;
  }

  fetchPaymentPlanDetailError(...args) {
    this.isLoadingBalanceDetails = false;
    this.notifyError('error getting payment plan  detail', args);
  }

  fetchBalanceDetailsError(...args) {
    this.isLoadingBalanceDetails = false;
    this.notifyError('error getting balance detail', args);
  }

  fetchDependencies() {
    if (this.balanceClientId) {
      this.isLoadingClient = true;
      ClientSource.fetch({
        id: this.balanceClientId,
        params: {
          fields: [
            'total_account_credit',
            'agreement_details',
            'total_credits',
          ],
        },
        success: BalanceDetailsDrawerActions.fetchClientResponse,
        error: BalanceDetailsDrawerActions.fetchClientResponse,
      });
    }

    if (this.balanceLocationId) {
      this.isLoadingLocation = true;
      LocationSource.fetch({
        id: this.balanceLocationId,
        success: BalanceDetailsDrawerActions.fetchLocationResponse,
        error: BalanceDetailsDrawerActions.fetchLocationResponse,
      });
    }
  }

  fetchClientResponse() {
    this.isLoadingClient = false;
  }

  fetchLocationResponse() {
    this.isLoadingLocation = false;
  }

  closeDrawer() {
    this.reset();
  }

  closeAllDrawers() {
    this.closeOtherDrawersActions.forEach(closeAction => {
      if (isFunction(closeAction)) {
        closeAction();
      }
    });
    this.reset();
  }

  // eslint-disable-next-line class-methods-use-this
  sendReceipt() {
    // TODO?
  }

  // eslint-disable-next-line class-methods-use-this
  payBalance() {
    // TODO
  }

  toggleDotsMenu(event) {
    this.dotsMenuAnchorEl = event && event.currentTarget;
  }
}

export default alt.createStore(
  BalanceDetailsDrawerStore,
  'BalanceDetailsDrawerStore'
);
