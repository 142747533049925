import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import Form from 'onboarding/components/AccountSetupPaysafe/forms/_Form.jsx';
import TextField from 'onboarding/components/AccountSetupPaysafe/fields/_TextField.jsx';
import WarningIcon from 'shared/components/icons/WarningIcon.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const BankAccountForm = ({ intl, propsForField }) => (
  <Form header={t('.header', intl, __filenamespace)}>
    <Grid container direction="column" spacing={4}>
      <Grid container item>
        <Grid item>
          <Typography align="center" variant="body1">
            <WarningIcon />
            {t('.warning', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center" item>
        <Grid item className="fs-exclude">
          <TextField {...propsForField('AccountNumber')} />
        </Grid>
        <Grid item className="fs-exclude">
          <TextField {...propsForField('AccountNumberConfirmation')} />
        </Grid>
        <Grid item className="fs-exclude">
          <TextField {...propsForField('RoutingNumber')} />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item>
          <Typography component="strong" variant="subtitle1">
            {t('shared.important_note', intl, __filenamespace)}
          </Typography>
          <Typography component="span" variant="body1">
            {t('.note', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Form>
);

export default injectIntl(BankAccountForm);
