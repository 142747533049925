import * as React from 'react';
import { injectIntl } from 'react-intl';
import CollapsibleTable, {
  EmptyRow,
} from 'reporting/sales_detail/components/tables/_CollapsibleTable.jsx';
import { RowExtended } from 'reporting/sales_detail/components/tables/_Rows.jsx';
import { TotalRowExtended } from 'reporting/sales_detail/components/tables/_TotalRows.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const COLUMNS = [
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.product_type',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.sales',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.qty',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.returns',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.returns_tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.returns_qty',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.ProductTypeTable.total',
    __filenamespace
  ),
];

const COLUMNS_SIZE = COLUMNS.length;

const ProductTypeTable = ({ intl, productTypeSales, productTypeTotals }) => (
  <CollapsibleTable
    title={t('.title', intl, __filenamespace)}
    columns={COLUMNS}
    totals={
      productTypeSales.size > 0 && (
        <TotalRowExtended
          data={productTypeTotals}
          label={t('.subtotal', intl, __filenamespace)}
        />
      )
    }
  >
    {productTypeSales.size > 0 ? (
      productTypeSales.map((r, i) => <RowExtended key={i} data={r} />)
    ) : (
      <EmptyRow colSpan={COLUMNS_SIZE} />
    )}
  </CollapsibleTable>
);

export default injectIntl(ProductTypeTable);
