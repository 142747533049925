import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function DollarIcon({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 11 20" {...props}>
      <g
        id="Dollar_Icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="dollar-symbol" fill={color} fillRule="nonzero">
          <path
            d="M7.97649485,9.06985567 C7.19298969,8.63686598 6.36,8.30696907
             5.53525773,7.96470103 C5.05690722,7.76676289 4.59917526,7.53583505
             4.19505155,7.21418557 C3.39917526,6.57913402 3.55175258,5.54820619
             4.48371134,5.13995876 C4.74762887,5.02449485 5.02391753,4.98738144
             5.3043299,4.9708866 C6.38474227,4.91315464 7.41154639,5.11109278
             8.38886598,5.58119588 C8.87546392,5.81624742 9.03628866,5.74202062
             9.20123711,5.23480412 C9.37443299,4.69872165 9.51876289,4.15439175
             9.67958763,3.61418557 C9.78680412,3.25129897 9.65484536,3.01212371
             9.31257732,2.85954639 C8.6857732,2.58325773 8.04247423,2.38531959
             7.36618557,2.27810309 C6.48371134,2.14202062 6.48371134,2.13789691
             6.47958763,1.25129897 C6.47546392,0.00181443299 6.47546392,0.00181443299
             5.22185567,0.00181443299 C5.04041237,0.00181443299 4.85896907,-0.00230927835
             4.67752577,0.00181443299 C4.09195876,0.0183092784 3.99298969,0.121402062
             3.97649485,0.711092784 C3.96824742,0.975010309 3.97649485,1.23892784
             3.97237113,1.50696907 C3.96824742,2.29047423 3.96412371,2.27810309
             3.21360825,2.55026804 C1.39917526,3.21006186 0.277525773,4.44717526
             0.157938144,6.4265567 C0.0507216495,8.17913402 0.966185567,9.36263918
             2.40536082,10.2244948 C3.29195876,10.7564536 4.27340206,11.0698557
             5.21360825,11.4863505 C5.58061856,11.6471753 5.93113402,11.8327423
             6.23628866,12.0884124 C7.13938144,12.8348041 6.97443299,14.0760412
             5.90226804,14.5461443 C5.32907216,14.7976907 4.7228866,14.8595464
             4.10020619,14.7811959 C3.13938144,14.6616082 2.21979381,14.4100619
             1.35381443,13.9605773 C0.846597938,13.6966598 0.69814433,13.7667629
             0.524948454,14.3152165 C0.376494845,14.7894433 0.244536082,15.2677938
             0.11257732,15.7461443 C-0.064742268,16.3894433 0.0012371134,16.5420206
             0.615670103,16.8430515 C1.39917526,17.222433 2.23628866,17.4162474
             3.08989691,17.5523299 C3.75793814,17.6595464 3.7785567,17.6884124
             3.78680412,18.3811959 C3.79092784,18.6945979 3.79092784,19.0121237
             3.79505155,19.3255258 C3.79917526,19.7214021 3.98886598,19.9523299
             4.3971134,19.9605773 C4.85896907,19.9688247 5.32494845,19.9688247
             5.78680412,19.9564536 C6.16618557,19.9482062 6.36,19.7420206 6.36,19.3585155
             C6.36,18.9296495 6.38061856,18.4966598 6.36412371,18.0677938
             C6.34350515,17.6306804 6.53319588,17.408 6.95381443,17.2925361
             C7.9228866,17.0286186 8.74762887,16.5090309 9.38268041,15.7337732
             C11.1476289,13.5894433 10.4754639,10.451299 7.97649485,9.06985567 Z"
            id="Shape"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

DollarIcon.defaultProps = {
  color: uhColors.iconGrey,
};

export default DollarIcon;
