class PaymentPlanDetailsDrawerActions {
  constructor() {
    this.generateActions(
      'openDrawer',
      'closeDrawer',
      'closeAllDrawers',
      'toggleDotsMenu',
      'fetchError',
      'fetchSuccess',
      'fetchClientSuccess',
      'fetchClientError',
      'listScheduledPaymentsSuccess',
      'listScheduledPaymentsError',
      'listOrderItemsSuccess',
      'listOrderItemsError',
      'listEventsSuccess',
      'listEventsError',
      'listEventTypesSuccess',
      'listEventTypesError',
      'listBalancesSuccess',
      'listBalancesError',
      'fetchBalanceDetailsSuccess',
      'fetchBalanceDetailsError',
      'toggleCancelAllPayments',
      'toggleEditPayments',
      'updateScheduledPayments',
      'updateScheduledPaymentsSuccess',
      'updateScheduledPaymentsError'
    );
  }
}

export default alt.createActions(PaymentPlanDetailsDrawerActions);
