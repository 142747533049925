import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import {
  CurrencyTextField,
  DateTimePicker,
  Checkbox,
} from '@upperhand/playmaker';
import Paper from '@mui/material/Paper';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  formInputWidths: {
    width: '100%',
    maxWidth: '100%',
  },
  columnHeader: {
    fontWeight: 600,
    marginBottom: 4,
  },
};
function PricingScheduleSection({
  customerEvent,
  hasAutomation,
  fieldErrors,
  intl,
}) {
  const scheduleChecked = customerEvent.hasPriceScheduleChecked();
  const hasDisabled =
    customerEvent.hasPricingScheduleDisabled() || hasAutomation;
  const onCheck = (e, check) => {
    EventActions.updateEventStore([e.target.name], check);
  };

  return (
    <div>
      <Checkbox
        label={
          <div>
            <p style={styles.columnHeader}>
              <FormattedMessage
                id={messageId('.pricing_schedule_header', __filenamespace)}
              />
            </p>
            <FormattedMessage id={messageId('.checkbox', __filenamespace)} />
          </div>
        }
        checked={scheduleChecked}
        disabled={hasDisabled}
        onChange={onCheck}
        name="date_based_price"
      />
      {scheduleChecked && !hasDisabled && (
        <div style={{ marginTop: '10px' }}>
          <Paper style={{ padding: '20px', minWidth: 330 }}>
            <DateTimePicker
              fieldName="scheduled_date"
              errorText={fieldErrors.get('scheduled_date')}
              disablePast
              autoOk
              fullWidth
              showClearIcon={false}
              type="date"
              label={t('.schedule_price_date_label', intl, __filenamespace)}
              placeholder="MM/DD/YYYY"
              value={customerEvent.get('scheduled_date')}
              onChange={date =>
                EventActions.updateEventStore(
                  ['scheduled_date'],
                  date.format('YYYY-MM-DD')
                )
              }
            />
            <div style={{ marginTop: '10px' }} />
            <CurrencyTextField
              fieldName="scheduled_price"
              errorText={fieldErrors.get('scheduled_price')}
              label={t('.price_field_label', intl, __filenamespace)}
              fullWidth
              value={customerEvent.get('scheduled_price')}
              onBlur={(e, newValue) =>
                EventActions.updateEventStore(['scheduled_price'], newValue)
              }
              onChange={(e, newValue) =>
                EventActions.updateEventStore(['scheduled_price'], newValue)
              }
            />
          </Paper>
        </div>
      )}
    </div>
  );
}

export default injectIntl(PricingScheduleSection);
