import * as React from 'react';
import PropTypes from 'prop-types';

import ColoredEventType from 'memberships/components/_ColoredEventType.jsx';
import GenericDiscount from 'memberships/components/_DiscountBenefits/_GenericDiscount.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

function EventTypeDiscount({ discount, eventTypes, itemStyle, textStyle }) {
  return (
    <GenericDiscount discount={discount} messageKey=".value_off_types">
      {eventTypes.map(eventType => (
        <ColoredEventType
          key={eventType.id}
          eventType={eventType}
          style={merge({ marginLeft: -2 }, itemStyle)}
          textStyle={textStyle}
        />
      ))}
    </GenericDiscount>
  );
}

EventTypeDiscount.propTypes = {
  discount: PropTypes.object.isRequired,
  eventTypes: PropTypes.object.isRequired,
};

export default EventTypeDiscount;
