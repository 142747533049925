import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { List, Map } from 'immutable';

import SelectWithNewItem from 'shared/components/_SelectWithNewItem.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import ModalEdit from './ModalEdit.jsx';
import RetailVendorsActions from '../Actions';

function Content({
  canEdit,
  floatingLabelText,
  intl,
  itemName,
  isModalOpen,
  modalData,
  onChange,
  value,
  vendors,
}) {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <SelectWithNewItem
        fullWidth
        value={value}
        itemName={itemName}
        newItemCallback={canEdit && RetailVendorsActions.openDialog}
        onChange={handleChange}
        label={floatingLabelText}
        formControlStyle={{ margin: '20px 0' }}
      >
        {vendors.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </SelectWithNewItem>
      {canEdit && value && (
        <Button
          variant="contained"
          color="default"
          onClick={() =>
            RetailVendorsActions.openDialog(
              vendors.find(item => item.id === value)
            )
          }
        >
          {t('.edit_button', intl, __filenamespace)}
        </Button>
      )}
      <ModalEdit isOpen={isModalOpen} intl={intl} modalData={modalData} />
    </Stack>
  );
}

Content.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  floatingLabelText: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  itemName: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  vendors: PropTypes.instanceOf(List).isRequired,
  modalData: PropTypes.instanceOf(Map).isRequired,
};

Content.defaultProps = {
  value: null,
};

export default React.memo(Content);
