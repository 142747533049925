import * as React from 'react';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import SwipeableViews from 'react-swipeable-views';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import Print from '@mui/icons-material/Print';

import QRIcon from 'shared/components/icons/QRIcon.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { mod } from 'shared/utils/MathUtils';
import { smallScreen } from 'shared/utils/DOMUtils';
import { isNative } from 'shared/utils/UserAgentUtils.js';

const styles = {
  qrContainer: usersSize => ({
    overflow: 'hidden',
    paddingBottom: usersSize > 1 ? 40 : 0,
    height: '100%',
    justifyContent: 'center',
  }),
  qrSwipeViewsContainer: {
    width: '100%',
    maxWidth: 300,
  },
  arrowBtn: {
    border: 0,
    padding: 0,
    marginTop: '40px',
  },
  arrowIcon: {
    color: uhColors.activeBlue,
    height: '48px',
    width: '48px',
  },
  clientName: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 'bold',
    color: uhColors.primaryMain,
    overflow: 'hidden',
  },
  qrCode: {
    display: 'block',
    margin: 'auto',
    padding: 10,
  },
  qrDialogTitle: {
    textAlign: 'center',
  },
  qrDialogContentNoPadding: {
    padding: 0,
  },
  qrDialogActions: {
    position: 'absolute',
    right: 0,
    top: '2px',
  },
};

function QRCards({ index, handleChange, userList }) {
  return (
    <FlexBoxCenter style={styles.qrContainer(userList.size)}>
      {userList.size > 1 && (
        <IconButton
          style={styles.arrowBtn}
          onClick={() => handleChange(index - 1)}
        >
          <ChevronLeftIcon sx={styles.arrowIcon} />
        </IconButton>
      )}
      <SwipeableViews
        enableMouseEvents
        index={index}
        style={styles.qrSwipeViewsContainer}
        onChangeIndex={handleChange}
      >
        {userList.map(p => (
          <Stack key={p.id} className="uh-qr-codes" spacing={1}>
            <Typography sx={styles.clientName}>{p.first_name}</Typography>
            <QRCard uuid={p.qr_code} />
          </Stack>
        ))}
      </SwipeableViews>
      {userList.size > 1 && (
        <IconButton
          style={styles.arrowBtn}
          onClick={() => handleChange(index + 1)}
        >
          <ChevronRightIcon sx={styles.arrowIcon} />
        </IconButton>
      )}
    </FlexBoxCenter>
  );
}

const QRCard = React.memo(({ uuid }) => (
  <Stack alignItems="center" justifyContent="center">
    <QRCode
      value={`client_id=${uuid}&t=${Math.floor(Date.now() / 1000)}`}
      width="100%"
      height="100%"
      level="H"
      renderAs="svg"
      style={styles.qrCode}
    />
  </Stack>
));

const QR = injectIntl(
  ({
    intl,
    actionButton,
    qrOpen,
    handleChange,
    handleQRClosed,
    index,
    userList,
  }) => (
    <Dialog
      disableEnforceFocus
      fullScreen={smallScreen(600)}
      open={qrOpen}
      onClose={handleQRClosed}
    >
      <DialogTitle sx={styles.qrDialogTitle}>
        {t('.check_in', intl, __filenamespace)}
      </DialogTitle>
      <DialogContent sx={smallScreen() ? styles.qrDialogContentNoPadding : {}}>
        <QRCards
          handleChange={handleChange}
          index={index}
          userList={userList}
        />
      </DialogContent>

      <DialogActions sx={styles.qrDialogActions}>{actionButton}</DialogActions>
    </Dialog>
  )
);

class QRDialog extends React.Component {
  state = {
    qrOpen: false,
    index: 0,
    userList: List(),
  };

  componentDidMount() {
    const { usersList } = this.props;

    this.originalArray = currentUser()
      .managed_users.concat(currentUser())
      .reverse();
    this.setState({
      userList: usersList.size ? usersList : List(this.originalArray),
    });
  }

  handleQRClicked = () => {
    this.setState({ qrOpen: true });
  };

  handleQRClosed = () => {
    this.setState({ qrOpen: false });
  };

  // eslint-disable-next-line class-methods-use-this
  handlePrintQRCodes = () => {
    const codes = [...document.getElementsByClassName('uh-qr-codes')];
    const print = window.open(window.location.origin, '_blank');

    print.document.write('<html>');
    print.document.write(`<head>
    <style type="text/css">
      body {
        background-color: #fafafa;
        font-size: 12pt;
        height: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      
      * {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      
      .page {
        background: #fff;
        margin: 10mm auto;
        min-height: 297mm;
        padding: 20mm;
        width: 210mm;
        position: relative;
      }

      .page-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 35px;
        display: flex;
        justify-content: space-between;
      }

      .page-header p {
        margin: 0;
      }

      .page-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 35px;
        display: flex;
        justify-content: flex-end;
      }

      .page-footer p {
        margin: 0;
      }
      
      .subpage {
        height: 257mm;
        outline: 2cm;
        padding: 1cm;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }

      .subpage p {
        text-align: center;
        margin: 0;
        font-weight: 600;
        font-size: 16pt;
      }
      
      .subpage svg {
        height: 50mm;
      }

      @page {
        margin: 0;
        size: a4;
      }

      @media print {
        html,
        body {
          height: 297mm;
          width: 210mm;
        }
      
        .page {
          background: initial;
          margin: 0;
          min-height: initial;
          page-break-after: always;
          width: initial;
        }
      }
    </style
    </head>`);
    print.document.write(`<body>`);

    const chunkSize = 2;
    let page = 0;

    for (let i = 0; i < codes.length; i += chunkSize) {
      const chunk = codes.slice(i, i + chunkSize);
      page += 1;

      print.document.write('<div class="page">');
      print.document.write(
        `<div class="page-header">
          <p>${currentCustomer().name}</p>
          <p>${new Date().toLocaleDateString()}</p>
        </div>`
      );
      print.document.write('<div class="subpage">');

      for (let j = 0; j < chunk.length; j += 1) {
        print.document.write(`<div>${chunk[j].innerHTML}</div>`);
      }

      print.document.write('</div>');
      print.document.write(
        `<div class="page-footer">
          <p>${page}/${Math.round(codes.length / chunkSize)}</p>
        </div>`
      );
      print.document.write('</div>');
    }
    print.document.write('</body></html>');
    print.document.close();
    print.print();
  };

  handleChange = value => {
    const { userList } = this.state;

    this.setState({
      index: mod(value, userList.size),
    });
  };

  render() {
    const { icon, skipCheck } = this.props;

    if ((currentUser().isStaff() || currentUser().isInstructor()) && !skipCheck)
      return null;

    const { qrOpen, index, userList } = this.state;

    const actions = [
      <IconButton onClick={() => this.handlePrintQRCodes()}>
        <Print />
      </IconButton>,
      <IconButton onClick={() => this.handleQRClosed(false)}>
        <Close />
      </IconButton>,
    ];

    if (isNative()) {
      actions.shift();
    }

    return (
      <>
        <IconButton onClick={this.handleQRClicked} style={{ margin: 0 }}>
          {icon === 'qr' && <QRIcon />}
          {icon === 'key' && <VpnKeyIcon />}
        </IconButton>
        <QR
          actionButton={actions}
          qrOpen={qrOpen}
          handleChange={this.handleChange}
          index={index}
          handleQRClosed={this.handleQRClosed}
          userList={userList}
        />
      </>
    );
  }
}

QRDialog.propTypes = {
  icon: PropTypes.string,
  skipCheck: PropTypes.bool,
  usersList: PropTypes.instanceOf(List),
};

QRDialog.defaultProps = {
  icon: 'key',
  skipCheck: false,
  usersList: List(),
};

export default QRDialog;
