import { Set } from 'immutable';
import debounce from 'lodash.debounce';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { CouponSource } from 'sources';

import CouponsActions from './Actions';
import CouponActions from '../couponView/Actions';

class CouponsStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.debouncedListCoupons = debounce(() => {
      this.list();
    }, 600);

    this.bindListeners({
      mounted: CouponsActions.mounted,
      list: CouponsActions.list,
      listSuccess: CouponsActions.listSuccess,
      listError: CouponsActions.listError,
      listAfterCouponCreate: CouponActions.createSuccess,
      selectPage: CouponsActions.selectPage,
      updateFilter: CouponsActions.updateFilter,
      reset: CouponsActions.reset,
    });
  }

  reset() {
    this.isLoading = true;

    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.couponIds = Set();
    this.search = '';
  }

  mounted(isMobile) {
    this.reset();
    this.isMobile = isMobile;
    this.list();
  }

  listAfterCouponCreate() {
    this.reset();
    this.list();
  }

  list() {
    this.isLoading = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      search: this.search,
    };

    CouponSource.list({
      params,
      success: CouponsActions.listSuccess,
      error: CouponsActions.listError,
    });
  }

  updateFilter(value) {
    this.page = 1;
    this.search = value;
    this.debouncedListCoupons();
  }

  listSuccess({ coupons, page, perPage, totalCount }) {
    const newIds = coupons.map(el => el.id).toOrderedSet();

    if (this.isMobile) {
      this.couponIds = page === 1 ? newIds : this.couponIds.union(newIds);
    } else {
      this.couponIds = newIds;
    }

    this.page = this.isMobile ? page + 1 : page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.isLoading = false;
  }

  listError() {
    this.isLoading = false;
  }

  selectPage([page, perPage]) {
    this.page = page;
    this.perPage = perPage;
    this.list();
  }
}

export default alt.createStore(CouponsStore, 'CouponsStore');
