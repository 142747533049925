import { Record } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

const msInHour = 60 * 60 * 1000;

class PayrollCommissions extends Record({
  id: null,
  client_id: null,
  customer_user_id: null,
  item_id: null,
  item_name: null,
  commission_type: null,
  total: null,
  due_date: null,
  payment_method: null,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    // TODO: It seems like Immutable.Record transforms nested records into plain JS objects.
    // The possible workaround explicitly call record constructor
    // Looking for better idea how to handle this issue
    const { errors } = obj;

    super(
      merge(obj, {
        errors: new FieldErrors(errors),
      })
    );
  }

  isValid() {
    return this.errors.isEmpty();
  }

  getDuration() {
    return this.end_time && this.start_time
      ? ((this.end_time - this.start_time) / msInHour).toFixed(1)
      : 0;
  }

  validate(managing = false) {
    let errors = new FieldErrors();
    let requiredFields = [
      'commission_type',
      'item_id',
      'item_name',
      'client_id',
      'total',
    ];
    if (managing) {
      requiredFields = requiredFields.concat(['customer_user_id', 'due_date']);
    }
    requiredFields.forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });
    return this.set('errors', errors);
  }

  toServer() {
    return this.toJS();
  }
}

export default PayrollCommissions;
