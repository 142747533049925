import * as React from 'react';
import { injectIntl } from 'react-intl';
import { TextField, Confirmation } from '@upperhand/playmaker';
import AltContainer from 'alt-container';
import { v4 as uuidv4 } from 'uuid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import RetailCategoryEditingActions from 'shared/actions/RetailCategoryEditingActions.jsx';
import SelectWithNewItem from 'shared/components/_SelectWithNewItem.jsx';
import RetailCategoryEditingStore from 'shared/stores/RetailCategoryEditingStore.jsx';
import RetailCategorySelectorUIActions from 'shared/actions/RetailCategorySelectorUIActions.jsx';
import RetailCategorySelectorUIStore from 'shared/stores/RetailCategorySelectorUIStore.jsx';
import { FlexBoxJustify, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const RetailCategoryDialog = injectIntl(
  ({ retailCategory, open, onClose, onSubmit, intl }) => {
    const isNew = retailCategory ? retailCategory.id === null : false;
    const onChange = handleChange({ actions: RetailCategoryEditingActions });

    const dialogTitle = isNew
      ? t('.dialog_title_create_mode', intl, __filenamespace)
      : t('.dialog_title_edit_mode', intl, __filenamespace);

    return (
      <Confirmation
        hasCheckbox={false}
        title={dialogTitle}
        open={open}
        onConfirm={onSubmit}
        onCancel={onClose}
        cancelButtonLabel={t('.dialog_cancel_button', intl, __filenamespace)}
        confirmButtonLabel={t('.dialog_submit_button', intl, __filenamespace)}
      >
        <TextField
          label={t('.name_label', intl, __filenamespace)}
          value={retailCategory.name}
          name="name"
          onChange={onChange}
        />
      </Confirmation>
    );
  }
);

function EditableRetailCategorySelect({
  selectedRetailCategoryId,
  componentId,
  errorText,
  onChange,
  menuItems,
  onCreateOrUpdateSuccess,
  retailCategoryUIStore,
  intl,
  ...props
}) {
  return (
    <FlexBoxJustify>
      <SelectWithNewItem
        value={selectedRetailCategoryId}
        itemName={t('.item_name', intl, __filenamespace)}
        newItemCallback={() =>
          RetailCategorySelectorUIActions.openDialog({
            componentId,
            newRetailCategory: true,
          })
        }
        error={!!errorText}
        helperText={errorText}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: document.documentElement.clientHeight * 0.4,
            },
          },
        }}
        // maxHeight={document.documentElement.clientHeight * 0.4}
        onChange={onChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {menuItems}
      </SelectWithNewItem>
      <AltContainer
        stores={{
          retailCategory: () => ({
            store: RetailCategoryEditingStore,
            value: RetailCategoryEditingStore.getState().record,
          }),
        }}
      >
        <RetailCategoryDialog
          open={retailCategoryUIStore.shouldOpen(componentId)}
          onSubmit={() => {
            RetailCategoryEditingActions.createOrUpdate({
              callback: onCreateOrUpdateSuccess,
            });
            RetailCategorySelectorUIActions.closeDialog();
          }}
          onClose={RetailCategorySelectorUIActions.closeDialog}
        />
      </AltContainer>
      {selectedRetailCategoryId && (
        <FlexBoxCenter>
          <Button
            sx={{ marginLeft: '16px' }}
            color="default"
            variant="contained"
            onClick={() =>
              RetailCategorySelectorUIActions.openDialog({ componentId })
            }
          >
            {t('.edit_button', intl, __filenamespace)}
          </Button>
        </FlexBoxCenter>
      )}
    </FlexBoxJustify>
  );
}

const RetailCategorySelect = injectIntl(
  ({
    retailCategoryUIStore,
    selectedRetailCategoryId,
    retailCategories,
    onChange,
    errorText,
    onCreateOrUpdateSuccess,
    componentId,
    intl,
    ...props
  }) => {
    const menuItems = [];
    if (retailCategories.size) {
      retailCategories.forEach(retailCategory => {
        menuItems.push(
          <MenuItem key={retailCategory.id} value={retailCategory.id}>
            {retailCategory.name}
          </MenuItem>
        );
      });
    }

    return (
      <div>
        {currentCustomer().restrict_editing && !currentUser().isAdmin() ? (
          <FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="select-standard-label">
              {t('.item_name', intl, __filenamespace)}
            </InputLabel>
            <Select
              labelId="select-standard-label"
              label={t('.item_name', intl, __filenamespace)}
              value={selectedRetailCategoryId || ''}
              error={!!errorText}
              helperText={errorText}
              onChange={onChange}
            >
              {menuItems}
            </Select>
          </FormControl>
        ) : (
          <EditableRetailCategorySelect
            fullWidth
            label={t('.item_name', intl, __filenamespace)}
            itemName={t('.item_name', intl, __filenamespace)}
            selectedRetailCategoryId={selectedRetailCategoryId}
            componentId={componentId}
            errorText={errorText}
            onChange={onChange}
            menuItems={menuItems}
            onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
            retailCategoryUIStore={retailCategoryUIStore}
            intl={intl}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      </div>
    );
  }
);

export default class RetailCategoryInlineEditor extends React.Component {
  constructor(props) {
    super(props);
    this.id = uuidv4();
  }

  componentDidUpdate(prevProps) {
    const { selectedRetailCategoryId } = this.props;
    const { selectedRetailCategoryId: prevCategoryId } = prevProps;
    if (
      selectedRetailCategoryId &&
      selectedRetailCategoryId !== prevCategoryId
    ) {
      RetailCategoryEditingActions.initialLoad.defer(selectedRetailCategoryId);
    }
  }

  render() {
    const {
      style,
      retailCategories,
      selectedRetailCategoryId,
      onRetailCategorySelect,
      onCreateOrUpdateSuccess,
      errorText,
      ...props
    } = this.props;
    return (
      <div style={style}>
        <AltContainer
          style={style}
          stores={{ retailCategoryUIStore: RetailCategorySelectorUIStore }}
        >
          <RetailCategorySelect
            retailCategories={retailCategories}
            selectedRetailCategoryId={selectedRetailCategoryId}
            componentId={this.id}
            onChange={onRetailCategorySelect}
            onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
            errorText={errorText}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        </AltContainer>
      </div>
    );
  }
}
