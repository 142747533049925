// Helper method for preparing retail_discounts and membership_event_discounts for display
/*
  Argument discounts is a list of instances of Discount

  Example return value:
    List<MembershipEventDiscount> -> OrderedMap<Disccount: List<EventType>>
  Or
    List<MembershipRetailDiscount> -> OrderedMap<Disccount: List<RetailCategory>>
*/

export default function mapDiscounts(discounts, mapper) {
  return discounts
    .sortBy(d => -d.discount.value)
    .groupBy(d => d.discount)
    .map((ds_, __) => ds_.flatMap(d => mapper(d)));
}
