import * as React from 'react';
import moment from 'moment-timezone';
import { Typography } from '@upperhand/playmaker';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
  StyleSheet,
  Image,
  Link,
} from '@react-pdf/renderer';

import TermsTemplate from 'shared/components/_TermsTemplate.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { STANDARD_DRAWER_WIDTH, parseHTML } from 'shared/utils/DOMUtils';
import { uhColors, uhContrast } from 'shared/styles/uhStyles.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import WaiverAgreementDrawerActions from './actions';
import WaiverDrawerStore from './store';

const styles = {
  buttonContainer: {
    padding: '1em 2em',
    justifyContent: 'center',
  },
  drawer: {
    width: STANDARD_DRAWER_WIDTH,
  },
  header: merge(
    {
      width: '100%',
      padding: '2em 2em 1em 2em',
    },
    uhContrast.lightOnDark
  ),
  scrollableContent: merge(
    {
      padding: '2em 2em',
      overflowY: 'auto',
      lineHeight: '17px',
      fontSize: 14,
      borderBottom: '1px solid rgb(224, 224, 224)',
      flex: '1 0 0',
      borderTop: '1px solid #E0E2EB',
    },
    uhContrast.darkOnLight
  ),
  iconWrapper: {
    position: 'absolute',
    right: '13px',
    top: '12px',
  },
  agreementHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
  },
  downloadButtonWrapper: {
    backgroundColor: 'var(--color-secondary)',
    color: 'var(--color-white)',
    width: '90%',
    height: '42px',
    borderRadius: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  downloadButton: {
    color: 'var(--color-white)',
  },
  clientNameBox: {
    marginLeft: '2em',
    marginRight: '2em',
    marginBottom: '.5rem',
    marginTop: '.5rem',
    border: '0.5px solid #b5bace',
    height: '40px',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
  },
  acceptanceDateBox: {
    marginLeft: '2em',
    marginRight: '2em',
    marginBottom: '.5rem',
    border: '0.5px solid #b5bace',
    height: '40px',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
  },
  nameDateTextWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

const docStyles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: 'Helvetica',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  viewWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  text: {
    fontSize: '14px',
    color: '#393c44',
    marginBottom: 10,
  },
  textBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: 10,
    fontFamily: 'Helvetica-Bold',
  },
  nameDateValue: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  policyWrapper: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: 10,
    padding: 0,
    fontFamily: 'Helvetica-Bold',
  },
  contentWrapper: {
    fontSize: '14px',
    color: '#393c44',
    lineHeight: '1.5px',
    fontWeight: 400,
  },
  linkText: {
    fontSize: '14px',
    color: '#009fdd',
    lineHeight: '2px',
    fontWeight: 400,
  },
  linkWrapper: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  linkSection: {
    margin: 10,
    padding: 10,
  },
});

function DefaultTermsTemplate() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <a
        href={window.upperHandTermsUrl}
        target="blank"
        style={{ color: uhColors.activeBlue }}
      >
        <FormattedMessage
          id={messageId('.view_policy_and_terms', __filenamespace)}
        />
      </a>
      <a
        href={window.upperHandPrivacyUrl}
        target="blank"
        style={{ color: uhColors.activeBlue, marginTop: '.5rem' }}
      >
        <FormattedMessage
          id={messageId('.view_privacy_and_cookies', __filenamespace)}
        />
      </a>

      <Box sx={{ marginTop: '1rem' }}>
        <FormattedMessage
          id={messageId('.accept', __filenamespace)}
          values={{ customerName: window.customer.name }}
        />
      </Box>
    </Box>
  );
}
function WaiverAgreementDrawer(props) {
  const {
    intl,
    waiverDrawerStore: { open, client },
  } = props;
  const clientName = client.name();
  const acceptanceDate = client.accepted_customer_terms_at;
  const waiverAgreementContent = window.customer.policy_and_terms;
  return (
    <Drawer
      disableEnforceFocus
      open={open}
      anchor="right"
      sx={{ zIndex: 1301 }}
      PaperProps={{ sx: styles.drawer }}
      onClose={WaiverAgreementDrawerActions.closeDrawer}
    >
      <Box sx={styles.header}>
        <Box sx={{ marginBottom: '4px', fontSize: '16px' }}>
          <Box sx={styles.agreementHeader}>
            <FormattedMessage
              id={messageId('.agreement_header_label', __filenamespace)}
            />
          </Box>
          <Box sx={styles.iconWrapper}>
            <IconButton
              size="large"
              onClick={WaiverAgreementDrawerActions.closeDrawer}
            >
              <CloseIcon sx={{ color: uhColors.navIconGrey }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.clientNameBox}>
        <Box sx={styles.nameDateTextWrapper}>
          <Typography variant="subtitle1">
            {t('.client_name', intl, __filenamespace)}
          </Typography>
          <Box sx={{ marginLeft: '1rem' }}>
            <Typography variant="p">{clientName} </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.acceptanceDateBox}>
        <Box sx={styles.nameDateTextWrapper}>
          <Typography variant="subtitle1">
            {t('.acceptance_date', intl, __filenamespace)}
          </Typography>
          <Box sx={{ marginLeft: '1rem' }}>
            <Typography variant="p">
              {moment.tz(acceptanceDate, customerTZ()).format('MM/DD/YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.scrollableContent}>
        {waiverAgreementContent ? (
          <TermsTemplate terms={waiverAgreementContent} />
        ) : (
          <DefaultTermsTemplate />
        )}
      </Box>
      <Stack spacing={2} direction="row" sx={styles.buttonContainer}>
        <PDFDownloadLink
          style={styles.downloadButtonWrapper}
          document={
            <Document size="A4">
              <Page style={docStyles.page}>
                <View style={docStyles.section}>
                  <View style={docStyles.header}>
                    <View>
                      <View style={docStyles.viewWrapper}>
                        <Text style={docStyles.text}>
                          {window.customer.name}
                        </Text>
                      </View>
                      <View style={docStyles.viewWrapper}>
                        <View>
                          <Text style={docStyles.textBold}>
                            {t('.client_name', intl, __filenamespace)}
                          </Text>
                        </View>
                        <View style={docStyles.nameDateValue}>
                          <Text style={docStyles.text}>{clientName}</Text>
                        </View>
                      </View>
                      <View style={docStyles.viewWrapper}>
                        <View>
                          <Text style={docStyles.textBold}>
                            {t('.acceptance_date', intl, __filenamespace)}
                          </Text>
                        </View>
                        <View style={docStyles.nameDateValue}>
                          <Text style={docStyles.text}>
                            {moment
                              .tz(acceptanceDate, customerTZ())
                              .format('MM/DD/YYYY')}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <Image
                      src={window.customer.logo.getSrc()}
                      style={{ width: 120, height: 120 }}
                    />
                  </View>
                  <View style={docStyles.policyWrapper}>
                    <Text style={docStyles.policyWrapper}>
                      {t('.policy_terms', intl, __filenamespace)}
                    </Text>
                  </View>
                </View>

                {waiverAgreementContent ? (
                  <View style={docStyles.section}>
                    <Text style={docStyles.contentWrapper}>
                      {parseHTML(waiverAgreementContent)}
                    </Text>
                  </View>
                ) : (
                  <View style={docStyles.linkSection}>
                    <View style={docStyles.linkWrapper}>
                      <Link href={window.upperHandTermsUrl}>
                        <Text style={docStyles.linkText}>
                          {t('.view_policy_and_terms', intl, __filenamespace)}
                        </Text>
                      </Link>
                      <Link href={window.upperHandPrivacyUrl}>
                        <Text style={docStyles.linkText}>
                          {t(
                            '.view_privacy_and_cookies',
                            intl,
                            __filenamespace
                          )}
                        </Text>
                      </Link>
                    </View>
                    <View style={docStyles.linkWrapper}>
                      <Text style={{ ...docStyles.text, marginTop: 10 }}>
                        {t('.accept', intl, __filenamespace, {
                          customerName: window.customer.name,
                        })}
                      </Text>
                    </View>
                  </View>
                )}
              </Page>
            </Document>
          }
          fileName={`${window.customer.slug}_waiver.pdf`}
        >
          <Button sx={styles.downloadButton} startIcon={<CloudDownloadIcon />}>
            {t('.download', intl, __filenamespace)}
          </Button>
        </PDFDownloadLink>
      </Stack>
    </Drawer>
  );
}

export default altContainer({
  stores: {
    waiverDrawerStore: WaiverDrawerStore,
  },
})(injectIntl(WaiverAgreementDrawer));
