/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';

import Content from 'team_mgmt/index/components/_Content.jsx';
import Header from 'team_mgmt/index/components/_Header.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';

import StoreActions from 'shared/actions/StoreActions.jsx';
import TeamListActions from 'team_mgmt/index/actions/TeamListActions.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import FilterStore from 'team_mgmt/index/stores/FilterStore.jsx';
import TeamStore from 'team_mgmt/shared/stores/TeamStore.jsx';
import TeamListStore from 'team_mgmt/index/stores/TeamListStore.jsx';

import '../styles.scss';

function TeamMgmtIndex(props) {
  const { readOnly } = props;
  React.useEffect(() => {
    StoreActions.prepareForReuse();
    TeamListActions.teamsDeselected();
    AutomationTemplateDescriptionActions.list({
      listAll: true,
      requireToken: true,
    });
  }, []);

  return (
    <Box>
      <Box className="teams">
        <Header />
        <Content readOnly={readOnly} {...props} />
      </Box>
    </Box>
  );
}

export default altContainer({
  stores: {
    automations: AutomationTemplateDescriptionStore,
    teamList: TeamListStore,
    teamTypes: TeamTypeListingStore,
    teamStore: TeamStore,
    filter: FilterStore,
  },
})(TeamMgmtIndex);
