import * as React from 'react';
import { injectIntl } from 'react-intl';

import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';

import BrowseEventsFilterActions from 'client_portal/actions/BrowseEventsFilterActions.jsx';
import BrowseEventListActions from 'client_portal/actions/BrowseEventListActions.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import EventList from 'client_portal/components/EventList.jsx';
import FilterDrawer from 'client_portal/components/FilterDrawer.jsx';
import FilterHeader from 'client_portal/components/FilterHeader.jsx';
import NoEventsIcon from 'shared/components/icons/empty_states/Events.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, RIGHT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import {
  Filters,
  AGE,
  EVENT_TYPE,
  LOCATION,
} from 'client_portal/components/Filters.jsx';

const NoEventsEmptyState = injectIntl(({ intl }) => (
  <EmptyState
    image={<NoEventsIcon />}
    headerText={t('.empty_state_header', intl, __filenamespace)}
    messageText={t('.empty_state_message', intl, __filenamespace)}
  />
));

const LayoutLarge = injectIntl(
  ({
    automations,
    events,
    eventTypeListingStore,
    filterStore,
    intl,
    isLastEventPage,
    isLoading,
    locationStore,
  }) => (
    <div
      style={{
        padding: `20px ${RIGHT_MARGIN_PIXELS}px 0 ${LEFT_MARGIN_PIXELS}px`,
      }}
    >
      <FlexBox style={{ margin: '0px -15px', flexWrap: 'wrap' }}>
        <div style={{ flex: '0 0 auto', margin: '0 15px' }}>
          <FilterHeader
            actions={BrowseEventsFilterActions}
            activeFilters={filterStore.anyFiltersEnabled()}
            text={t('.filter_header_text', intl, __filenamespace)}
          />
          <Filters
            actions={BrowseEventsFilterActions}
            eventTypes={eventTypeListingStore.eventTypes}
            filterStore={filterStore}
            filters={[EVENT_TYPE, LOCATION, AGE]}
            locations={locationStore.allLocations}
          />
        </div>
        <div style={{ flex: '1 0 auto', margin: '0 15px' }}>
          <EventList
            automations={automations}
            emptyState={<NoEventsEmptyState />}
            events={events}
            filterStore={filterStore}
            isLastEventPage={isLastEventPage}
            isLoading={isLoading}
            onClearFilters={BrowseEventsFilterActions.filtersCleared}
            onSeeMoreClicked={BrowseEventListActions.moreClicked}
            onTypingEnd={BrowseEventsFilterActions.updateTitleFilter}
          />
        </div>
      </FlexBox>
    </div>
  )
);

function LayoutSmall({
  isLastEventPage,
  automations,
  eventTypeListingStore,
  filterStore,
  locationStore,
  events,
  isLoading,
}) {
  return (
    <div
      style={{
        padding: `15px ${RIGHT_MARGIN_PIXELS}px 0 ${LEFT_MARGIN_PIXELS}px`,
      }}
    >
      <EventList
        automations={automations}
        emptyState={<NoEventsEmptyState />}
        events={events}
        filterStore={filterStore}
        filterIcon={
          <IconButton
            onClick={() =>
              BrowseEventsFilterActions.updateFilterDrawerOpen(true)
            }
          >
            <FilterListIcon sx={{ color: uhColors.iconGrey }} />
          </IconButton>
        }
        isLastEventPage={isLastEventPage}
        isLoading={isLoading}
        onClearFilters={BrowseEventsFilterActions.filtersCleared}
        onSeeMoreClicked={BrowseEventListActions.moreClicked}
        onTypingEnd={BrowseEventsFilterActions.updateTitleFilter}
      />
      <FilterDrawer
        actions={BrowseEventsFilterActions}
        eventTypes={eventTypeListingStore.eventTypes}
        filterStore={filterStore}
        filters={[EVENT_TYPE, LOCATION, AGE]}
        locations={locationStore.allLocations}
        open={filterStore.filterDrawerOpen}
      />
    </div>
  );
}

function BrowseEventsTab(props) {
  return (
    <ResponsiveElement
      // eslint-disable-next-line react/jsx-props-no-spreading
      largeScreen={<LayoutLarge {...props} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      smallScreen={<LayoutSmall {...props} />}
    />
  );
}

export default BrowseEventsTab;
