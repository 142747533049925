import { List } from 'immutable';
import Session from 'shared/records/Session';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './SessionActions';

const parser = new Parser({ type: Session, paginationKey: 'sessions' });

export const cancel = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `sessions/${id}/cancel`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const checkIn = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `sessions/${id}/check_in`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const checkOut = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `sessions/${id}/check_out`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const sendMessage = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `sessions/${id}/message`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const noShow = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `sessions/${id}/no_show`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `sessions/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'sessions',
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const put = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `sessions/${id}`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error,
  });
};
