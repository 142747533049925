import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';

import { EmailTemplatesActions } from 'sources';

class EmailTemplatesDataStore extends UpperHandStore {
  constructor() {
    super();

    this.emailTemplates = Map();

    this.bindListeners({
      createSuccess: EmailTemplatesActions.createSuccess,
      createError: EmailTemplatesActions.createError,
      updateSuccess: EmailTemplatesActions.updateSuccess,
      updateError: EmailTemplatesActions.updateError,
      deleteSuccess: EmailTemplatesActions.deleteSuccess,
      deleteError: EmailTemplatesActions.deleteError,
      listSuccess: EmailTemplatesActions.listSuccess,
      listError: EmailTemplatesActions.listError,
    });
  }

  createSuccess(emailTemplate) {
    this.emailTemplates = this.emailTemplates.set(
      emailTemplate.id,
      emailTemplate
    );
  }

  createError(...args) {
    this.notifyError('error creating email template', args);
  }

  updateSuccess(emailTemplate) {
    this.emailTemplates = this.emailTemplates.set(
      emailTemplate.id,
      emailTemplate
    );
  }

  updateError(...args) {
    this.notifyError('error updating email template', args);
  }

  deleteSuccess(emailTemplate) {
    this.emailTemplates = this.emailTemplates.delete(emailTemplate.id);
  }

  deleteError(...args) {
    this.notifyError('error deleting email template', args);
  }

  listSuccess({ email_templates: emailTemplates }) {
    const fetchedTemplates = emailTemplates
      .groupBy(et => et.id)
      .map(et => et.first());

    this.emailTemplates = this.emailTemplates.merge(fetchedTemplates);
  }

  listError(...args) {
    this.notifyError('error listing email templates', args);
  }
}

export default alt.createStore(
  EmailTemplatesDataStore,
  'EmailTemplatesDataStore'
);
