import { OrderedSet } from 'immutable';

const ACTIVE = 'active';
const SUSPENDED = 'suspended';
const CANCELLED = 'cancelled';
const INVITED = 'invited';

const headerMap = {
  [ACTIVE]: OrderedSet([
    'member_name',
    'active_membership_tier_name',
    'member_length',
    'next_payment',
    'commitment',
    'price',
  ]),
  [SUSPENDED]: OrderedSet([
    'member_name',
    'active_membership_tier_name',
    'suspension_date',
    'reactivation_date',
    'commitment',
    'price',
  ]),
  [CANCELLED]: OrderedSet([
    'member_name',
    'active_membership_tier_name',
    'cancellation_date_time',
    'commitment',
    'price',
  ]),
  [INVITED]: OrderedSet(['member_name', 'invitation_date', 'status']),
};

const ListRowHeader = activeFilter =>
  headerMap[activeFilter] ||
  OrderedSet([
    'member_name',
    'active_membership_tier_name',
    'status',
    'member_length',
    'commitment',
    'price',
  ]);

export default ListRowHeader;
