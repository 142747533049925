import React from 'react';
import { Box } from '@mui/material';
import { Button, Drawer, Dropdown, Grid } from '@upperhand/playmaker';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';

import { t } from 'shared/utils/LocaleUtils';
import { smallScreen } from 'shared/utils/DOMUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import {
  FILTER_RESOURCE_ALL,
  FILTER_SCHEDULE_NAME_ALL,
  FILTER_STAFF_ALL,
} from '../completeScheduleModal/Store';

const styles = {
  filtersContainer: {
    position: 'relative',
    width: '100%',
    padding: '8px 0',
  },
  dropdownsContainer: {
    width: '100%',
    padding: '0 8px',
  },
  dropdownsMobileContainer: {
    padding: '50px 8px 8px 8px',
  },
  toggleViewButtonPadding: {
    paddingRight: '142px',
  },

  scheduleToggleBtnContainer: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
};
const getFilterContainerStyles = canShowToggleViewModeButton =>
  smallScreen()
    ? {
        ...styles.dropdownsContainer,
        ...(canShowToggleViewModeButton && styles.dropdownsMobileContainer),
      }
    : {
        ...styles.dropdownsContainer,
        ...(canShowToggleViewModeButton && styles.toggleViewButtonPadding),
      };
function TeamSessionHeader({
  canShowToggleViewModeButton,
  staffItems,
  staffFilter,
  staffFilterUpdated,
  resourceItems,
  resourceFilter,
  resourceFilterUpdated,
  eventId,
  onToggleViewMode,
  scheduleNames,
  scheduleNameFilter,
  scheduleNameFilterUpdated,
  intl,
}) {
  const isClient = currentUser().isClient();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  if (isClient) {
    return (
      <Button onClick={onToggleViewMode} type="tertiary" fullWidth rounded>
        {t('.view_schedule', intl, __filenamespace)}
      </Button>
    );
  }
  return (
    <ResponsiveElement
      largeScreen={
        <Box sx={styles.filtersContainer}>
          <Box
            sx={{
              ...getFilterContainerStyles(canShowToggleViewModeButton),
              paddingRight: '10px',
            }}
          >
            <Grid
              alignItems="center"
              container
              justify="space-between"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12} sm={4}>
                <Dropdown
                  id="scheduleType"
                  name="scheduleType"
                  fullWidth
                  items={[
                    {
                      label: 'Schedule Name: All',
                      value: FILTER_SCHEDULE_NAME_ALL,
                    },
                  ].concat(scheduleNames)}
                  onChange={e => scheduleNameFilterUpdated(e.target.value)}
                  outline={false}
                  rounded
                  value={scheduleNameFilter}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  id="staff"
                  name="staff"
                  fullWidth
                  items={[
                    { label: 'Staff: All', value: FILTER_STAFF_ALL },
                  ].concat(staffItems)}
                  onChange={e => staffFilterUpdated(e.target.value)}
                  outline={false}
                  rounded
                  value={staffFilter}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  id="resource"
                  name="resource"
                  rounded
                  fullWidth
                  outline={false}
                  items={[
                    { label: 'Resource: All', value: FILTER_RESOURCE_ALL },
                  ].concat(resourceItems)}
                  value={resourceFilter}
                  onChange={e => resourceFilterUpdated(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  onClick={onToggleViewMode}
                  type="tertiary"
                  fullWidth
                  rounded
                >
                  {t('.view_schedule', intl, __filenamespace)}
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  rounded
                  fullWidth
                  onClick={() =>
                    EventSessionCreationActions.toggleDrawer({
                      eventId,
                    })
                  }
                >
                  {t('.create_session', intl, __filenamespace)}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
      smallScreen={
        <Box sx={styles.filtersContainer}>
          <Box
            sx={{
              ...getFilterContainerStyles(canShowToggleViewModeButton),
              paddingRight: '10px',
            }}
          >
            <Grid
              alignItems="center"
              container
              justify="space-between"
              spacing={1}
              xs={12}
            >
              <Grid item xs={7}>
                <Dropdown
                  id="scheduleType"
                  name="scheduleType"
                  fullWidth
                  items={[
                    {
                      label: 'Schedule Name: All',
                      value: FILTER_SCHEDULE_NAME_ALL,
                    },
                  ].concat(scheduleNames)}
                  onChange={e => scheduleNameFilterUpdated(e.target.value)}
                  outline={false}
                  rounded
                  value={scheduleNameFilter}
                />
              </Grid>
              <Grid item xs={5}>
                <Button
                  type="tertiary"
                  rounded
                  icon="filter"
                  fullWidth
                  onClick={() => setOpenDrawer(true)}
                >
                  {t('.filters', intl, __filenamespace)}
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button
                  onClick={onToggleViewMode}
                  type="tertiary"
                  fullWidth
                  rounded
                >
                  {t('.view_schedule', intl, __filenamespace)}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  rounded
                  fullWidth
                  onClick={() =>
                    EventSessionCreationActions.toggleDrawer({
                      eventId,
                    })
                  }
                >
                  {t('.create_session', intl, __filenamespace)}
                </Button>
              </Grid>
              <Drawer
                className="filter-drawer"
                open={openDrawer}
                title={t('.filters', intl, __filenamespace)}
                onClose={() => setOpenDrawer(false)}
                content={
                  <Grid container xs={12} spacing={1} alignItems="center">
                    <Grid item xs={12}>
                      <Dropdown
                        id="staff"
                        name="staff"
                        fullWidth
                        items={[
                          { label: 'Staff: All', value: FILTER_STAFF_ALL },
                        ].concat(staffItems)}
                        onChange={e => staffFilterUpdated(e.target.value)}
                        value={staffFilter}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Dropdown
                        id="resource"
                        name="resource"
                        fullWidth
                        items={[
                          {
                            label: 'Resource: All',
                            value: FILTER_RESOURCE_ALL,
                          },
                        ].concat(resourceItems)}
                        value={resourceFilter}
                        onChange={e => resourceFilterUpdated(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </Box>
        </Box>
      }
    />
  );
}

export default TeamSessionHeader;
