let style = null;

// eslint-disable-next-line import/prefer-default-export
export const getVar = value => {
  if (style === null) {
    style = window.getComputedStyle(document.body);
  }

  return style
    .getPropertyValue(value.replace(/^var\(/, '').replace(/\)$/, ''))
    .trim();
};

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const colorDistance = ([r1, g1, b1], [r2, g2, b2]) =>
  Math.sqrt((r2 - r1) ** 2 + (g2 - g1) ** 2 + (b2 - b1) ** 2);

export const isSimilarColor = (color1, color2, threshold = 100) => {
  // Convert colors to RGB arrays
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  // Calculate color distance
  const distance = colorDistance(rgb1, rgb2);

  // Compare distance to threshold
  return distance <= threshold;
};
