import * as React from 'react';
import moment from 'moment-timezone';

import Loading from 'calendar/components/views/shared/_Loading.jsx';

const styles = {
  StandardCalendar: {
    zIndex: '1',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowX: 'auto',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    overscrollBehavior: 'none',
  },
};

class StandardCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { didScrollToCurrentHour: false };
    this.el = null;
  }

  componentDidUpdate() {
    const { loading } = this.props;

    if (!loading) {
      setTimeout(() => {
        window.requestAnimationFrame(this.scrollToCurrentHour);
      }, 350);
    }
  }

  scrollToCurrentHour = () => {
    const { didScrollToCurrentHour } = this.state;

    if (!didScrollToCurrentHour) {
      const now = moment();
      const currentTime = now.hours() + now.minutes() / 60;
      const cellHeight = 192;
      const topBuffer = cellHeight * 2;
      const scrollOffset = Math.max(currentTime * cellHeight - topBuffer, 0);

      this.setState(
        () => ({
          didScrollToCurrentHour: true,
        }),
        () => {
          this.el.scrollTo({ top: scrollOffset, behavior: 'smooth' });
        }
      );
    }
  };

  render() {
    const { loading, children } = this.props;

    return (
      <div
        ref={ref => {
          this.el = ref;
        }}
        style={styles.StandardCalendar}
        id="StandardCalendar"
      >
        <Loading loading={loading} />
        {children}
      </div>
    );
  }
}

export default StandardCalendar;
