import { Map, OrderedSet } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { BalancesSource } from 'sources';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import PayAllBalancesActions from './Actions';

class PayAllBalancesStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      open: PayAllBalancesActions.open,
      close: PayAllBalancesActions.close,
      balanceList: PayAllBalancesActions.balanceList,
      balanceListSuccess: PayAllBalancesActions.balanceListSuccess,
      balanceListError: PayAllBalancesActions.balanceListError,
      fetchStatisticSuccess: PayAllBalancesActions.fetchStatisticSuccess,
      fetchStatisticError: PayAllBalancesActions.fetchStatisticError,
    });
  }

  reset() {
    this.openModal = false;
    this.clientId = null;
    this.balancesIds = new OrderedSet();
    this.statistics = new Map();
    this.pagination = new Map({
      balances: {
        page: 0,
        totalCount: 0,
        perPage: 15,
      },
    });
    this.loadings = new Map({
      initial: false,
      balances: false,
    });
  }

  open({ clientId }) {
    this.reset();

    this.openModal = true;
    this.clientId = clientId;
    this.loadings = this.loadings.set('initial', true);

    this.fetchStatistic();
  }

  close() {
    this.reset();
  }

  fetchStatistic() {
    uhApiClient.get({
      url: 'ledger/balances_statistics',
      data: {
        client_ids: [this.clientId],
        balance_remaining_max: -1,
      },
      success: PayAllBalancesActions.fetchStatisticSuccess,
      error: PayAllBalancesActions.fetchStatisticError,
    });
  }

  fetchStatisticSuccess(statistics) {
    this.statistics = new Map(statistics);

    this.balanceList();
  }

  fetchStatisticError(...args) {
    this.notifyError('Error fetching balances statistics:', args);
  }

  balanceList() {
    const pagination = this.pagination.get('balances');

    this.loadings = this.loadings.set('balances', true);
    BalancesSource.list({
      params: {
        client_ids: [this.clientId],
        balance_remaining_max: -1,
        page: pagination.page + 1,
        per_page: pagination.perPage,
      },
      success: PayAllBalancesActions.balanceListSuccess,
      error: PayAllBalancesActions.balanceListError,
    });
  }

  balanceListSuccess({ records, page, perPage, totalCount }) {
    const ids = records.map(el => el.compoundId).toOrderedSet();

    this.loadings = this.loadings.set('initial', false).set('balances', false);
    this.balancesIds = this.balancesIds.union(ids);
    this.pagination = this.pagination.set('balances', {
      page,
      perPage,
      totalCount,
    });
  }

  balanceListError(...args) {
    this.loadings = this.loadings.set('initial', false).set('balances', false);
    this.notifyError('Error fetching balances:', args);
  }
}

export default alt.createStore(PayAllBalancesStore, 'PayAllBalancesStore');
