class EventActions {
  constructor() {
    this.generateActions(
      'updateEventStore',
      'startDateUpdated',
      'endDateUpdated',
      'scheduleTypeSelected',
      'staffSelectToggled',
      'staffAdded',
      'staffRemoved',
      'resourceAdded',
      'resourceRemoved',
      'preferenceOverrideAdded',
      'preferenceOverrideRemoved',
      'preferenceOverrideAllRemoved',
      'postEvent',
      'clone',
      'cloneSuccess',
      'createOrUpdateServer',
      'createSuccess',
      'updateSuccess',
      'list',
      'loadMoreSessions',
      'listError',
      'listSuccess',
      'fetch',
      'fetchSuccess',
      'clone',
      'error',
      'markDirty',
      'toggleSSPConfirmation',
      'fillPaymentMethods',
      'listSwap',

      // TODO: This probably doesn't belong here
      'fetchAvailability',
      'fetchAvailabilitySuccess',

      'checkConflict',
      'eventTypeSelected',
      'updateEventSelection',
      'fetchTeam',
      'fetchTeamSuccess',
      'resetDobFields',
      'resetAgeFields'
    );
  }
}

export default alt.createActions(EventActions);
