export default alt.generateActions(
  'acceptTerms',
  'changePreferences',
  'refreshCustomerUser',
  'fetch',
  'openDialog',
  'closeDialog',
  'fetchCustomerError',
  'fetchCustomerSuccess',
  'fetchCustomerUserError',
  'fetchCustomerUserSuccess',
  'joinSuccess',
  'joinError',
  'loadManagedProfiles',
  'loadManagedProfilesError',
  'loadManagedProfilesSuccess',
  'roleListSuccess',
  'roleListError',
  'updateError',
  'updatePreferences',
  'updateSuccess',
  'acceptTermsSuccess'
);
