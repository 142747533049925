import { List } from 'immutable';
import TeamType from 'records/TeamType.jsx';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';
import TeamTypeEditingActions from 'shared/actions/TeamTypeEditingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { TeamTypeSource } from 'sources';

class TeamTypeListingStore extends UpperHandStore {
  constructor() {
    super();

    this.teamTypes = List();
    this.findById = this.findById.bind(this);
    this.isLoading = false;

    this.bindListeners({
      handleList: TeamTypeListingActions.LIST,
      handleListSuccess: TeamTypeListingActions.LIST_SUCCESS,
      handleListError: TeamTypeListingActions.LIST_ERROR,
      handleCreateOrUpdateSuccess:
        TeamTypeEditingActions.CREATE_OR_UPDATE_SUCCESS,
    });
  }

  handleList(options) {
    const { page = 1 } = options || {};
    this.isLoading = true;
    return TeamTypeSource.list({
      params: { page, per_page: 50 },
      success: TeamTypeListingActions.listSuccess,
      error: TeamTypeListingActions.listError,
    });
  }

  handleListSuccess({
    team_types: teamTypes,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    const partitionedTeamTypes = List(
      teamTypes.map(object => new TeamType(object))
    );

    this.teamTypes =
      page === 1
        ? partitionedTeamTypes
        : this.teamTypes.concat(partitionedTeamTypes);

    if (teamTypes.length === perPage && totalCount > this.teamTypes.size) {
      this.handleList({ page: page + 1 });
    } else {
      this.isLoading = false;
    }
  }

  handleListError(...args) {
    this.isLoading = false;

    this.notifyError('error while listing team types', args);
  }

  handleCreateOrUpdateSuccess([attributes]) {
    this.insertOrUpdateTeamType(attributes);
  }

  /*
   * PRIVATE HELPERS
   */

  findById(id) {
    return this.teamTypes.find(teamType => teamType.id === id);
  }

  insertOrUpdateTeamType(attributes) {
    const teamType = new TeamType(attributes);
    const index = this.teamTypes.findIndex(type => type.id === attributes.id);

    if (index !== -1) {
      this.teamTypes = this.teamTypes.setIn([index], teamType);
    } else {
      this.teamTypes = this.teamTypes.push(teamType);
    }
  }
}

export default alt.createStore(TeamTypeListingStore, 'TeamTypeListingStore');
