import * as React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import DateRangeDropdown from 'components/DateRangeDropdown';
import WaiversReportActions from 'reporting/waivers/actions/WaiversReportActions';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  clearFilterButton: {
    backgroundColor: 'white',
    borderRadius: 500,
    flex: '0 0 auto',
    margin: '0 8px 6px 8px',
    fontWeight: 'bold',
  },
};

function Filters({ disabled, filters, filtersApplied, intl }) {
  return (
    <div className="waivers-report__filters-container">
      <div className="waivers-report__filters">
        <DateRangeDropdown
          disabled={disabled}
          label={false}
          value={filters.getIn(['dateRange', 'value'])}
          onChange={WaiversReportActions.dateFilterUpdated}
          className="waivers-report__date-range"
        />
      </div>

      {filtersApplied && (
        <Button
          label={t('.clear_filters', intl, __filenamespace)}
          onClick={WaiversReportActions.filtersCleared}
          style={styles.clearFilterButton}
        >
          {t('.clear_filters', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

export default injectIntl(Filters);
