import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import MailTo from 'shared/components/MailTo.jsx';
import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import Tel from 'shared/components/Tel.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';

import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import ContactsArchivedActions from '../Actions.js';

const styles = {
  managingUserIcon: {
    height: 12,
    width: 12,
  },
};

function ClientCard({ client, intl }) {
  return (
    <Card
      menuOptions={[
        {
          label: t('.unarchive', intl, __filenamespace),
          onClick: () => ContactsArchivedActions.clientUnarchived(client.id),
        },
      ]}
      contentDirection="row"
      classes={{ content: 'card-content' }}
    >
      <Grid container alignItems="center" xs={12} spacing={1}>
        <Grid item container xs={12} md={3} alignItems="center">
          <Grid item>
            <UserAvatar user={client} />
          </Grid>
          <Grid item>
            <Link
              to={customerScopedRoute(`clients/${client.id}`)}
              style={{ textDecoration: 'none' }}
              onClick={e => {
                if (smallScreen()) {
                  e.preventDefault();
                  ClientProfileDrawerActions.mounted({ clientId: client.id });
                }
              }}
            >
              <Typography>{client.name()}</Typography>
            </Link>

            {client.managing_customer_user_name && (
              <div>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <ManagingUserIcon style={styles.managingUserIcon} />
                  </Grid>
                  <Grid item>
                    <Link
                      to={customerScopedRoute(`clients/${client.id}`)}
                      style={{ textDecoration: 'none' }}
                      onClick={e => {
                        if (smallScreen()) {
                          e.preventDefault();
                          ClientProfileDrawerActions.mounted({
                            clientId: client.get('managing_customer_user_id'),
                          });
                        }
                      }}
                    >
                      <Typography>
                        {client.managing_customer_user_name}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={1}>
          <Tel number={client.phone} />
        </Grid>

        <Grid item xs={12} md={3} className="client-email">
          <MailTo email={client.email} />
        </Grid>
      </Grid>
    </Card>
  );
}

export default injectIntl(ClientCard);
