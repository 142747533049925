import { List } from 'immutable';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import EventStaffActions from 'event_mgmt/display/actions/EventStaffActions.js';
import Staff from 'shared/records/Staff.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { StaffSource } from 'sources';

class EventStaffStore extends UpperHandStore {
  constructor() {
    super();

    this.staff = List();
    this.visibleStaff = List();
    this.expanded = false;

    this.bindListeners({
      listStaff: EventDisplayActions.CLIENT_DISPLAY_MOUNTED,
      listSuccess: EventStaffActions.LIST_SUCCESS,
      toggleExpand: EventStaffActions.TOGGLE_EXPAND,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  listStaff({ eventId }) {
    const params = {
      access_revoked: false,
      per_page: 100,
      event_ids: [eventId],
    };

    StaffSource.list({
      params,
      success: EventStaffActions.listSuccess,
      error: EventStaffActions.listError,
    });
  }

  listSuccess(data) {
    this.staff = List(data.staff.map(s => new Staff(s)));
    this.setVisibleStaff();
  }

  listError(...args) {
    this.notifyError('error while listing membership event discounts', args);
  }

  toggleExpand() {
    this.expanded = !this.expanded;
    this.setVisibleStaff();
  }

  setVisibleStaff() {
    this.visibleStaff = this.expanded ? this.staff : this.staff.take(3);
  }
}

export default alt.createStore(EventStaffStore, 'EventStaffStore');
