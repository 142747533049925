import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { grey, green } from '@mui/material/colors';
import Divider from '@mui/material/Divider';

import CurrencySymbol from 'shared/components/CurrencySymbol.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import PricingUtils from 'event_mgmt/shared/utils/PricingUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import uhTheme from '_uh_theme.jsx';

const styles = {
  automationInterface: {
    width: '34.5rem',
    backgroundColor: 'white',
    padding: '0.5em',
  },

  card: {
    border: '2px solid #CACBCA',
    padding: '15px 10px',
  },

  nameHeader: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: uhTheme.palette.charcoalBlack,
    marginTop: '0.5em',
    marginBottom: '1.5em',
    paddingLeft: '0.5em',
  },

  optionRow: {
    padding: '7px 10px',
  },

  optionRowDark: {
    backgroundColor: grey[100],
  },

  optionRowBold: {
    color: uhTheme.palette.charcoalBlack,
    fontWeight: 600,
  },

  processingFeeExtraInformation: {
    position: 'relative',
    top: '-4.6em',
    right: '-8.5em',
    color: grey[500],
    fontSize: 12,
  },

  processingFeeNote: {
    color: grey[500],
    fontWeight: 500,
    fontSize: '1.2rem',
    marginTop: 15,
  },

  separator: {
    width: '100%',
    margin: '',
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 0,
    marginRight: 0,
  },
};

// TODO: Make this DRY. Maybe move to FeePlan.
const processingFeeExtraInformation = (feePlan, intl) => {
  if (!feePlan) {
    return { percentage: '', dollarAmount: '' };
  }

  const percentage = intl.formatNumber(feePlan.percentage / 100, {
    style: 'percent',
    maximumFractionDigits: 1,
  });

  const dollarAmount = intl.formatNumber(feePlan.dollar_amount, {
    style: 'currency',
    currency: 'USD',
  });

  return { percentage, dollarAmount };
};

function PerSessionPricingDetailsCard({ atd, feePlan, style, intl }) {
  return (
    <div style={merge(styles.card, style)}>
      <div style={styles.nameHeader}>{atd.name}</div>
      {atd.template_parameters.map((option, index) => {
        const price = option.get('price');
        return (
          <section key={option.get('uuid')}>
            {index > 0 && <Divider style={styles.separator} />}
            <div style={merge(styles.optionRow, styles.optionRowBold)}>
              {option.get('name')}
            </div>
            <FlexBoxJustify style={styles.optionRow}>
              <div>{option.get('description')}</div>
              <div>
                <FormattedCurrency value={price} fromCents />
              </div>
            </FlexBoxJustify>
            {!feePlan.interchange_plus && currentCustomer().display_fees && (
              <div>
                <FlexBoxJustify
                  style={merge(styles.optionRow, styles.optionRowDark)}
                >
                  <div>
                    <FormattedMessage
                      id={messageId('.processing_fee', __filenamespace)}
                    />
                  </div>
                  <div>
                    -&nbsp;
                    <FormattedCurrency
                      value={
                        PricingUtils.processingFee(price / 100, feePlan) * 100
                      }
                      fromCents
                    />
                  </div>
                </FlexBoxJustify>
                <FlexBoxJustify
                  style={merge(styles.optionRow, styles.optionRowBold)}
                >
                  <div>
                    <FormattedMessage
                      id={messageId('.total_earnings', __filenamespace)}
                    />
                  </div>
                  <div>
                    <span style={{ color: green.A400 }}>
                      <CurrencySymbol currency="USD" />
                      &nbsp;
                    </span>
                    <FormattedCurrency
                      value={
                        PricingUtils.totalEarnings(price / 100, feePlan) * 100
                      }
                      fromCents
                      showSymbol={false}
                    />
                  </div>
                </FlexBoxJustify>
              </div>
            )}
          </section>
        );
      })}
      {!feePlan.interchange_plus && currentCustomer().display_fees && (
        <div style={merge(styles.optionRow, styles.processingFeeNote)}>
          <FormattedMessage
            id={messageId('.processing_fee_note', __filenamespace)}
            values={processingFeeExtraInformation(feePlan, intl)}
          />
        </div>
      )}
    </div>
  );
}

export default injectIntl(PerSessionPricingDetailsCard);
