import React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { Button, Typography } from '@upperhand/playmaker';
import { Line } from 'react-chartjs-2';

import { l, messageId } from 'shared/utils/LocaleUtils.js';
import MrrReportStore from '../stores/MonthlyRecurringRevenueReportStore.jsx';
import MrrReportActions from '../actions/MonthlyRecurringRevenueReportActions.jsx';

import EmptyState from './EmptyState.jsx';

import { Widget } from '../../index/shared/components/ReportWidget.jsx';
import ToolTip, {
  ToolTipText,
  ToolTipList,
  ToolTipListItem,
} from './ToolTip.jsx';

const styles = {
  title: {
    marginRight: 16,
  },
  padTop: {
    paddingTop: 24,
  },
  tabButton: {
    borderTopWidth: 4,
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    color: '#7f7f7f',
  },
  activeTabButton: {
    borderTopColor: 'rgba(0, 159, 221, 1)',
    color: '#000',
  },
  activeTabLabel: {
    fontWeight: 700,
  },
  tabLabel: {
    fontWeight: 'normal',
  },
  listHeader: {
    color: 'white',
    fontWeight: 700,
  },
};

class MonthlyRecurringRevenueByPeriod extends React.Component {
  getLabels() {
    const { mrrReportStore: store } = this.props;
    return store.monthlyRecurringRevenueListResult.map(r =>
      this.formatDate(r.Date, store.interval)
    );
  }

  getDatasets() {
    const {
      mrrReportStore: {
        monthlyRecurringRevenueListResult = [],
        activeCell: { paginatedColumn },
      },
    } = this.props;

    const standardObject = {
      fill: 'origin',
      lineTension: 0.1,
      backgroundColor: 'rgba(240, 250, 253, 0.8)',
      borderColor: 'rgba(186, 188, 198, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: monthlyRecurringRevenueListResult.map((r, i) =>
        i === paginatedColumn ? 'rgb(0, 159, 221)' : 'rgba(186, 188, 198, 1)'
      ),
      pointBorderColor: monthlyRecurringRevenueListResult.map((r, i) =>
        i === paginatedColumn ? 'rgb(0, 159, 221)' : 'rgba(186, 188, 198, 1)'
      ),
      borderWidth: 1,
      pointBorderWidth: 1,
      pointHoverBorderWidth: 3,
      pointHoverBackgroundColor: 'rgb(0, 159, 221)',
      pointHoverBorderColor: 'rgba(0, 159, 221)',
      pointRadius: monthlyRecurringRevenueListResult.map((r, i) =>
        i === paginatedColumn ? 5 : 3
      ),
      pointHoverRadius: 5,
      pointHitRadius: 10,
    };

    const comparisonNow = moment();
    const smallestDistance = Math.min(
      ...monthlyRecurringRevenueListResult
        .map(r => comparisonNow.diff(moment.utc(r.Date)))
        .filter(d => d > 0)
    );

    return [
      {
        ...standardObject,
        label: 'past',
        data: monthlyRecurringRevenueListResult.map(r =>
          comparisonNow.diff(moment.utc(r.Date)) >= smallestDistance
            ? r.mrr || '0'
            : null
        ),
      },
      {
        ...standardObject,
        label: 'future',
        borderDash: [5, 10],
        backgroundColor: 'rgba(240, 250, 253, 0.2)',
        pointBackgroundColor: monthlyRecurringRevenueListResult.map((r, i) =>
          i === paginatedColumn ? 'rgb(0, 159, 221)' : 'rgba(255, 255, 255, 0)'
        ),
        data: monthlyRecurringRevenueListResult.map(r =>
          comparisonNow.diff(moment.utc(r.Date)) <= smallestDistance
            ? r.mrr || '0'
            : null
        ),
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  handleInterval = interval => () => {
    MrrReportActions.hideDetails();
    MrrReportActions.filterUpdated('interval', interval);
  };

  getTitle = () => {
    const { mrrReportStore: store } = this.props;
    const { interval, intervals } = store;

    return (
      <>
        <div style={{ ...styles.padTop, ...styles.title }}>
          <Typography display="inline" variant="h4">
            <FormattedMessage id={messageId('.title', __filenamespace)} />
          </Typography>
        </div>
        <div style={{ flex: '1 1 auto' }}>
          <ToolTip>
            <ToolTipText>
              <span style={styles.listHeader}>
                <FormattedMessage
                  id={messageId('.tooltip_description_header', __filenamespace)}
                />
              </span>{' '}
              <FormattedMessage
                id={messageId('.tooltip_description_body', __filenamespace)}
              />
            </ToolTipText>
            <ToolTipText>
              <span style={styles.listHeader}>
                <FormattedMessage
                  id={messageId('.tooltip_calculation_header', __filenamespace)}
                />
              </span>{' '}
              <FormattedMessage
                id={messageId('.tooltip_calculation_body', __filenamespace)}
              />
            </ToolTipText>
            <ToolTipText>
              <span style={styles.listHeader}>
                <FormattedMessage
                  id={messageId('.tooltip_note_header', __filenamespace)}
                />
              </span>
            </ToolTipText>
            <ToolTipList>
              <ToolTipListItem>
                <FormattedMessage
                  id={messageId(
                    '.tooltip_note_one_time_charges',
                    __filenamespace
                  )}
                />
              </ToolTipListItem>
              <ToolTipListItem>
                <FormattedMessage
                  id={messageId(
                    '.tooltip_note_refunds_waives',
                    __filenamespace
                  )}
                />
              </ToolTipListItem>
              <ToolTipListItem>
                <FormattedMessage
                  id={messageId('.tooltip_note_discounts', __filenamespace)}
                />
              </ToolTipListItem>
              <ToolTipListItem>
                <FormattedMessage
                  id={messageId('.tooltip_note_fees', __filenamespace)}
                />
              </ToolTipListItem>
            </ToolTipList>
          </ToolTip>
        </div>
        {intervals.map(i => (
          <div
            key={i}
            style={{
              ...styles.padTop,
              ...styles.tabButton,
              ...(i === interval ? styles.activeTabButton : {}),
            }}
          >
            <Button onClick={this.handleInterval(i)} type="tertiary">
              <span
                style={i === interval ? styles.activeTabLabel : styles.tabLabel}
              >
                {i}
              </span>
            </Button>
          </div>
        ))}
      </>
    );
  };

  formatDate(value, interval) {
    const { intl } = this.props;
    /* react-chartjs-2 shows dates in the local timezone by default and we can't change this option.
    So, to fix an issue with a graph displaying we manually added an offset to the date 
    and when it will be displayed in the local timezone it will be correct. */
    const date = moment(value)
      .add(moment(value).utcOffset() * -1, 'minutes')
      .toDate();

    return l(date, `.dateFormat${interval || 'Months'}`, intl, __filenamespace);
  }

  render() {
    const { intl, mrrReportStore: store } = this.props;
    const data = {
      labels: this.getLabels(),
      datasets: this.getDatasets(),
    };

    return store.monthlyRecurringRevenueListResult.length > 0 ? (
      <Widget
        title={this.getTitle()}
        titleStyle={{
          padding: '0 0 32px 0',
          display: 'flex',
          alignItems: 'center',
        }}
        style={{ width: '100%', padding: '0 24px 24px 24px' }}
        isLoading={store.isMonthlyRecurringRevenueListLoading}
        bodyStyle={{ padding: 0 }}
      >
        <Line
          redraw
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
          }}
          height={300}
        />
      </Widget>
    ) : (
      <EmptyState intl={intl} />
    );
  }
}

function MonthlyRecurringRevenueByPeriodWrapper({ intl }) {
  return (
    <AltContainer
      stores={{
        mrrReportStore: MrrReportStore,
      }}
    >
      <MonthlyRecurringRevenueByPeriod intl={intl} />
    </AltContainer>
  );
}
export default injectIntl(MonthlyRecurringRevenueByPeriodWrapper);
