import * as React from 'react';
import { Route } from 'react-router-dom';

import RetailContainer from 'retail/components/RetailContainer.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';
import Page from 'routes/Page.jsx';

import {
  isAdmin,
  isStaffAdmin,
  isManager,
} from 'routes/helpers/routePermissions';

const retailRoutes = () => (
  <Route path=":customer_id">
    <Route
      path="retail"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <RetailContainer />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="retail/new"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <RetailContainer />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="retail/:productId/edit"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <RetailContainer />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
  </Route>
);

export default retailRoutes;
