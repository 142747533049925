// this is a developer testing store, we want to console log info
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import DevTestActions from './Actions';

class DevTestStore extends UpperHandStore {
  constructor() {
    super();

    this.paginationKey = '';
    this.totalCount = null;
    this.fetchId = null;

    this.bindListeners({
      mounted: DevTestActions.mounted,
      showMounted: DevTestActions.showMounted,
      fetchError: DevTestActions.fetchError,
      fetchSuccess: DevTestActions.fetchSuccess,
      listError: DevTestActions.listError,
      listSuccess: DevTestActions.listSuccess,
    });
  }

  mounted({ dataSource, paginationKey }) {
    this.paginationKey = paginationKey;
    this.dataSource = dataSource;
    this.list();
  }

  showMounted({ dataSource, paginationKey, entityId }) {
    this.paginationKey = paginationKey;
    this.dataSource = dataSource;
    this.fetch(entityId);
  }

  list() {
    this.dataSource.list({
      // params: { },
      success: DevTestActions.listSuccess,
      error: DevTestActions.listError,
    });
  }

  listSuccess(response) {
    console.log('Listing Success!');
    this.totalCount = response.totalCount;
  }

  listError(...args) {
    console.log('Listing Error');
    this.notifyError(
      'error List for Testing Dev store. see data store message for more info.',
      args
    );
  }

  fetch(entityId) {
    this.dataSource.fetch({
      id: entityId,
      success: DevTestActions.fetchSuccess,
      error: DevTestActions.fetchError,
    });
  }

  fetchSuccess(entity) {
    this.fetchId = entity.id;
    console.log('Fetching Success!');
  }

  fetchError(...args) {
    console.log('Fetching Error');
    this.notifyError(
      'error in Fetch for Testing Dev store. see data store message for more info.',
      args
    );
  }
}

export default alt.createStore(DevTestStore, 'DevTestStore');
