class CreditGrantActions {
  constructor() {
    this.generateActions(
      'listSuccess',
      'listError',
      'fetchSuccess',
      'fetchError',
      'updateSuccess'
    );
  }
}

export default alt.createActions(CreditGrantActions);
