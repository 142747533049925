import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Checkbox } from '@upperhand/playmaker';

import AdvancedSearch from 'containers/classes/classesList/components/AdvancedSearch.jsx';
import SessionsList from 'containers/classes/classesList/components/Sessions/SessionsList.jsx';
import ScheduleList from 'containers/classes/classesList/components/Schedules/SchedulesList.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import ClassesListActions from 'containers/classes/classesList/Actions';
import ClassesListStore, {
  VIEW_MODES,
} from 'containers/classes/classesList/Store';

function Schedule({
  classesListStore: {
    viewMode,
    sessionsIds,
    viewSchedulesIds,
    staffIds,
    resourcesIds,
    locationsIds,
    selectedClass,
    loadingSessions,
    loadingSchedules,
    loadingResources,
    loadingStaff,
    loadingEvent,
    searchText,
    staffFilter,
    resourceFilter,
    locationFilter,
    pagination,
    sessionsParams,
  },
  listUpcoming,
}) {
  const intl = useIntl();

  React.useEffect(() => {
    ClassesListActions.listSessions.defer({ listUpcoming });
    ClassesListActions.listStaff.defer({});
    ClassesListActions.listResources.defer();
  }, [listUpcoming]);

  const isCancelledListed = sessionsParams
    .get('statuses')
    .includes('cancelled');
  const paginationData = pagination.get('sessions');
  const schedulePagination = pagination.get('schedules');
  const loading =
    loadingSessions ||
    loadingSchedules ||
    loadingStaff ||
    loadingResources ||
    loadingEvent;

  const columnSettings = [
    {
      label: (
        <Checkbox
          checked={isCancelledListed}
          label={t('.show_all_cancelled', intl, __filenamespace)}
          onChange={e =>
            ClassesListActions.listSessionsWithStatus({
              statuses: e.target.checked ? ['cancelled'] : undefined,
            })
          }
        />
      ),
      active: isCancelledListed,
      onClick: () => {},
    },
  ];

  return (
    <>
      <AdvancedSearch
        locationsIds={locationsIds}
        resourcesIds={resourcesIds}
        staffIds={staffIds}
        searchValue={searchText}
        staffFilter={staffFilter}
        resourceFilter={resourceFilter}
        locationFilter={locationFilter}
        viewMode={viewMode}
        classId={selectedClass?.id}
        onSearchChange={e => ClassesListActions.searchSessions(e.target.value)}
        onStaffFilterChange={e =>
          ClassesListActions.staffFilterChange(e.target.value)
        }
        onResourceFilterChange={e =>
          ClassesListActions.resourceFilterChange(e.target.value)
        }
        onLocationFilterChange={e =>
          ClassesListActions.locationFilterChange(e.target.value)
        }
        onChangeMode={() =>
          ClassesListActions.switchMode(
            viewMode === VIEW_MODES.SESSIONS
              ? VIEW_MODES.SCHEDULES
              : VIEW_MODES.SESSIONS
          )
        }
      />
      {viewMode === VIEW_MODES.SESSIONS && (
        <SessionsList
          listUpcoming={listUpcoming}
          eventId={selectedClass?.id}
          selectedClass={selectedClass}
          loading={loading}
          openSessionSummaryDrawer={
            ClassesListActions.toggleSessionSummaryDrawer
          }
          sessionIds={sessionsIds}
          columnSettings={columnSettings}
          page={paginationData.page}
          onPageSelect={ClassesListActions.sessionsPageChange}
          perPage={paginationData.perPage}
          totalCount={paginationData.totalCount}
        />
      )}
      {viewMode === VIEW_MODES.SCHEDULES && (
        <ScheduleList
          scheduleIds={viewSchedulesIds}
          listUpcoming={listUpcoming}
          isLoading={loading}
          page={schedulePagination.page}
          perPage={schedulePagination.perPage}
          totalCount={schedulePagination.totalCount}
          onPageSelect={ClassesListActions.schedulePageChange}
        />
      )}
    </>
  );
}

Schedule.propTypes = {
  classesListStore: PropTypes.object,
};

Schedule.defaultProps = {
  classesListStore: {},
};

export default compose(
  altContainer({
    stores: {
      classesListStore: ClassesListStore,
    },
  })
)(Schedule);
