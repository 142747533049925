import { List } from 'immutable';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import Product from 'shared/records/Product.js';
import ProductListingActions from 'retail/actions/ProductListingActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

export const ALL_PRODUCTS = 'all';
export const UNPUBLISHED_PRODUCTS = 'unpublished';
export const PUBLISHED_PRODUCTS = 'published';

class ProductListingStore extends UpperHandStore {
  constructor() {
    super();

    this.products = List();

    this.page = 1;
    this.perPage = 15;
    this.totalCount = 0;
    this.searchString = '';
    this.filterString = ALL_PRODUCTS;
    this.successMessage = '';

    this.isChanged = false;
    this.isLoading = false;

    this.bindListeners({
      handleError: ProductListingActions.ERROR,
      list: ProductListingActions.LIST,
      listReceived: ProductListingActions.LIST_RECEIVED,
      updateSearchString: ProductListingActions.SEARCH_STRING_UPDATED,
      clearSearchString: ProductListingActions.CLEAR_SEARCH_CLICKED,
      updatePaginationOptions: ProductListingActions.PAGINATION_OPTIONS_UPDATED,
      publishedToggle: ProductListingActions.TOGGLE_PUBLISHED_UPDATED,
      updateFilter: ProductListingActions.FILTER_UPDATED,
    });
  }

  publishedToggle(data) {
    const product = data.togglePublished();
    this.isChanged = true;
    this.successMessage = product.published
      ? new TranslatableMessage({
          id: '.has_been_published',
          filename: __filenamespace,
        })
      : new TranslatableMessage({
          id: '.has_been_unpublished',
          filename: __filenamespace,
        });

    uhApiClient.patch({
      url: `products/${product.id}`,
      data: JSON.stringify({
        attributes: { id: product.id, published: product.published },
      }),
      success: ProductListingActions.list,
      error: ProductListingActions.error,
    });
  }

  updateFilter(data) {
    if (data === PUBLISHED_PRODUCTS) {
      this.filterString = PUBLISHED_PRODUCTS;
    }

    if (data === UNPUBLISHED_PRODUCTS) {
      this.filterString = UNPUBLISHED_PRODUCTS;
    }

    if (data === ALL_PRODUCTS) {
      this.filterString = ALL_PRODUCTS;
    }

    this.list();
  }

  list() {
    this.isLoading = true;

    if (this.isChanged) {
      this.isChanged = false;
      MessageWindowActions.addMessage.defer(this.successMessage);
      this.successMessage = '';
    }

    uhApiClient.get({
      url: 'products',
      data: {
        page: this.page,
        per_page: this.perPage,
        name: this.searchString,
        filter_string: this.filterString,
        fields: ['retail_category'],
      },
      success: ProductListingActions.listReceived,
      error: ProductListingActions.error,
    });
  }

  listReceived({ products, page, per_page: perPage, total_count: totalCount }) {
    this.products = List(products.map(p => new Product(p)));

    this.page = page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.isLoading = false;
  }

  handleError(...args) {
    this.isLoading = false;
    this.isChanged = false;
    this.successMessage = '';
    this.notifyError('error listing products', args);
  }

  updateSearchString(string) {
    this.searchString = string;
    this.page = 1;

    this.list();
  }

  clearSearchString() {
    this.updateSearchString('');
  }

  updatePaginationOptions([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.list();
  }
}

export default alt.createStore(ProductListingStore, 'ProductListingStore');
