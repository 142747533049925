import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material//IconButton';
import Stack from '@mui/material/Stack';
import { STANDARD_DRAWER_WIDTH } from 'shared/utils/DOMUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function PurchaseDrawerContainer({
  children,
  close,
  open,
  style,
  headerContainerStyle,
  title,
  width,
}) {
  return (
    <Drawer
      disableEnforceFocus
      PaperProps={{ sx: { width } }}
      open={open}
      anchor="right"
      hideBackdrop
    >
      <div
        style={{
          backgroundColor: uhColors.leftNavGrey,
          color: 'white',
          padding: '2rem',
          fontSize: '13pt',
          position: 'relative',
          ...headerContainerStyle,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={{ textAlign: 'start' }}>{title}</div>
          <IconButton onClick={close} style={{ padding: '0' }}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </Stack>
      </div>
      <div
        style={{
          padding: '2.5rem 3rem',
          textAlign: 'start',
          fontSize: 15,
          ...style,
        }}
      >
        {children}
      </div>
    </Drawer>
  );
}

PurchaseDrawerContainer.defaultProps = {
  width: STANDARD_DRAWER_WIDTH,
};

export default PurchaseDrawerContainer;
