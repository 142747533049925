import { logError } from 'ErrorLogger.js';

/**
 * @description service object for interacting with gateway using Paysafe.js
 *              callback-based since Paysafe uses callbacks not Promises/AsAw
 *              SDK is hosted by Paysafe and loaded in index.html
 *              https://developer.paysafe.com/en/sdks/paysafejs/overview/
 * @type {*}
 */
const PaysafeService = {};

/**
 * @description assign Paysafe.js instance to service
 *              invoked by setting up card form
 * @public
 * @param instance {Object} - initialized Paysafe.js instance
 * @param error {Error} - error object from Paysafe.js
 */
function setupCallback(instance, error) {
  if (error) {
    logError('PaysafeCardForm')(error);
  } else {
    PaysafeService.instance = instance;
  }
}

/**
 * @description get card token from Paysafe
 * @public
 * @param payload {Object} - card data
 * @param callback {function} - reference from caller
 * @returns {number|*|void}
 */
function tokenize(payload, callback) {
  return PaysafeService.instance.tokenize(payload, callback);
}

/**
 * @description use Paysafe.js to validate/check PCI data
 *              PaymentCard Record is validated before this is called
 *              we don't send the card info since this form is "iFramed"
 * @public
 */
function validateCard() {
  if (PaysafeService.instance.areAllFieldsValid()) {
    return true;
  }
  if (!PaysafeService.instance.fields.cardNumber.isValid()) {
    throw new Error('Invalid card number.');
  }
  if (!PaysafeService.instance.fields.cvv.isValid()) {
    throw new Error('Invalid security code.');
  }
  if (!PaysafeService.instance.fields.expiryDate.isValid()) {
    throw new Error('Invalid expiration date.');
  }
  return false;
}

PaysafeService.setupCallback = setupCallback;
PaysafeService.tokenize = tokenize;
PaysafeService.validateCard = validateCard;

export default PaysafeService;
