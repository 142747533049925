import React, { memo, useEffect } from 'react';
import { List } from 'immutable';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Button, Typography, Icon } from '@upperhand/playmaker';

import MembershipTierDeleteConfirmation from 'memberships/components/MembershipTiers/MembershipTierDeleteConfirmation.jsx';
import MembershipTierConfig from 'memberships/components/MembershipTiers/MembershipTierConfig.jsx';
import MembershipTiersList from 'memberships/components/MembershipTiers/MembershipTiersList.jsx';
import MembershipTiersCreationDrawer from 'memberships/components/MembershipTiers/MembershipTiersCreationDrawer.jsx';
import DiscountsSelector from 'memberships/components/DiscountsSelector/DiscountsSelector.jsx';
import EventCreditSelector from 'memberships/components/EventCreditSelector.jsx';
import ExclusiveEventTypeSelector from 'memberships/components/ExclusiveEventTypeSelector.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';

import { compose } from 'shared/utils/SharedUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils';

import MembershipTiersActions from 'memberships/actions/MembershipTiersActions';
import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';

import MembershipTiersStore from 'memberships/stores/MembershipTiersStore';
import MembershipEditingStore from 'memberships/stores/MembershipEditingStore.jsx';

import './styles.scss';

function MembershipTiers({
  membershipId,
  isTieredMembership,
  events,
  specificEvents,
  eventTypes,
  creditPasses,
  retailCategories,
  membershipTiersStore,
  membershipEditingStore,
}) {
  const {
    record,
    membershipTiersIds,
    disabledAddingNewCredit,
    existingAllEventsCredits,
    unselectedEventTypes,
    selectedEventIds,
    selectedEventTypeIds,
    exclusiveExpanded,
    tierIdToDelete,
    joinFeeChecked,
  } = membershipTiersStore;
  const intl = useIntl();
  const isMobile = smallScreen();
  const { record: membership } = membershipEditingStore;

  useEffect(() => {
    if (membershipId && isTieredMembership) {
      MembershipTiersActions.mounted(membershipId);
    }
    return () => MembershipTiersActions.mounted(null);
  }, [membershipId, isTieredMembership]);

  const handleSaveTier = () => {
    MembershipTiersActions.validate();

    const { id } = membership;
    const isValidRecord = record.isValid();

    if (!id && isValidRecord) {
      MembershipEditingActions.validateAndSave();
    }

    if (id) {
      MembershipTiersActions.saveTier();
    }
  };

  return (
    <div className="membership-tiers">
      <div className="membership-tiers__add-tier-container">
        <Typography>{t('.title', intl, __filenamespace)}</Typography>
        <div data-tip data-for="add-tiered-pricing">
          <Icon name="info" />
        </div>
        <ReactTooltip
          id="add-tiered-pricing"
          effect="solid"
          className="uh-tooltip"
        >
          {t('.add-tiered-pricing-tooltip', intl, __filenamespace)}
        </ReactTooltip>
        <Button
          icon="add"
          classes={{ root: 'membership-tiers__add-tier-btn' }}
          type="tertiary"
          onClick={
            isMobile
              ? () => MembershipTiersActions.toggleCreationDrawer()
              : MembershipTiersActions.addTier
          }
        >
          {t('.add_tier', intl, __filenamespace)}
        </Button>
      </div>
      {isMobile && (
        <MembershipTiersCreationDrawer
          eventTypes={eventTypes}
          creditPasses={creditPasses}
          retailCategories={retailCategories}
          specificEvents={specificEvents}
          events={events}
        />
      )}
      {record && !isMobile && (
        <>
          <MembershipTierConfig
            tier={record}
            joinFeeChecked={joinFeeChecked}
            onJoinFeeToggle={MembershipTiersActions.toggleJoinFee}
            onFieldChange={MembershipTiersActions.fieldChanged}
          />
          <div className="membership-tiers__discounts-container">
            <DiscountsSelector
              actions={MembershipTiersActions}
              membership={record}
              eventTypes={eventTypes}
              creditPasses={creditPasses}
              retailCategories={retailCategories}
            />
            {currentCustomer().membership_credits_enabled && (
              <EventCreditSelector
                actions={MembershipTiersActions}
                disabledAddingNewCredit={disabledAddingNewCredit}
                specificEvents={specificEvents}
                events={events}
                selectedEventIds={selectedEventIds}
                selectedEventTypeIds={selectedEventTypeIds}
                unselectedEventTypes={unselectedEventTypes}
                existingAllEventsCredits={existingAllEventsCredits}
                eventTypes={eventTypes}
                membership={record}
                expanded={!record.membership_event_credits.isEmpty()}
              />
            )}
            <ExclusiveEventTypeSelector
              actions={MembershipTiersActions}
              eventTypes={eventTypes}
              membership={record}
              expanded={exclusiveExpanded}
            />
            <div className="membership-tiers__actions">
              <Button
                type="tertiary"
                onClick={MembershipTiersActions.cancelTier}
              >
                {t('.cancel_tier', intl, __filenamespace)}
              </Button>
              <Button onClick={handleSaveTier}>
                {t(
                  record.id ? '.update_tier' : '.save_tier',
                  intl,
                  __filenamespace
                )}
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="membership-tiers__list">
        <MembershipTiersList
          tierIds={membershipTiersIds}
          eventTypes={eventTypes}
          creditPasses={creditPasses}
          retailCategories={retailCategories}
        />
      </div>
      <MembershipTierDeleteConfirmation
        membershipTierId={tierIdToDelete}
        onConfirm={() => MembershipTiersActions.deleteTier()}
        onCancel={() => MembershipTiersActions.showDeleteConfirmation(null)}
      />
    </div>
  );
}

MembershipTiers.propTypes = {
  membershipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isTieredMembership: PropTypes.bool,
  events: PropTypes.instanceOf(List),
  specificEvents: PropTypes.instanceOf(List),
  eventTypes: PropTypes.instanceOf(List),
  creditPasses: PropTypes.instanceOf(List),
  retailCategories: PropTypes.instanceOf(List),
  membershipTiersStore: PropTypes.object,
  membershipEditingStore: PropTypes.object,
};

MembershipTiers.defaultProps = {
  membershipId: null,
  isTieredMembership: false,
  events: List(),
  specificEvents: List(),
  eventTypes: List(),
  creditPasses: List(),
  retailCategories: List(),
  membershipTiersStore: {},
  membershipEditingStore: {},
};

export default compose(
  memo,
  altContainer({
    stores: {
      membershipTiersStore: MembershipTiersStore,
      membershipEditingStore: MembershipEditingStore,
    },
  })
)(MembershipTiers);
