import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Set } from 'immutable';
import { Confirmation } from '@upperhand/playmaker';

import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import RegistrationScheduler from 'shared/components/registration/RegistrationScheduler.jsx';
import ScheduledSession from 'shared/components/registration/ScheduledSession.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import TentativeRegistration from 'shared/records/TentativeRegistration';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

class Scheduler extends React.Component {
  constructor(props) {
    super(props);

    const tentativeRegistration = new TentativeRegistration();

    this.state = {
      tentativeRegistration,
      dialogOpen: false,
      staffId: null,
    };

    this.getEventSchedule = this.getEventSchedule.bind(this);
    this.getScheduledRegistrationCount =
      this.getScheduledRegistrationCount.bind(this);
    this.packageScheduler = this.packageScheduler.bind(this);
    this.handleStaffChange = this.handleStaffChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateChangeStartTime = this.handleDateChangeStartTime.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleBooking = this.handleBooking.bind(this);
    this.handleCloseConfirmCancel = this.handleCloseConfirmCancel.bind(this);
    this.handleCancelRegistration = this.handleCancelRegistration.bind(this);
  }

  handleStaffChange(_e, _index, staffId) {
    this.setState({ staffId });

    const { tentativeRegistration } = this.state;
    const { onStaffChange } = this.props;
    const newValue = staffId === null ? Set() : Set([staffId]);
    const updatedRegistration = tentativeRegistration.set(
      'staff_ids',
      newValue
    );

    this.setState({ tentativeRegistration: updatedRegistration });
    onStaffChange.defer(staffId, null);
  }

  handleDateChange(value, key, _) {
    const { tentativeRegistration } = this.state;
    const { onDateChange } = this.props;

    const updatedRegistration = tentativeRegistration.set(
      'starts_at',
      moment(value).tz(customerTZ(), true)
    );

    this.setState({ tentativeRegistration: updatedRegistration });

    onDateChange(key, value, null);
  }

  handleDateChangeStartTime(_, value) {
    const { tentativeRegistration } = this.state;
    const { onDateChange } = this.props;

    const updatedRegistration = tentativeRegistration.set(
      'open_booking_starts_at',
      moment(value).tz(customerTZ(), true)
    );

    this.setState({ tentativeRegistration: updatedRegistration });
    onDateChange(value, null);
  }

  handleTimeChange(value, key, scheduleDate) {
    const { tentativeRegistration } = this.state;
    const { onTimeChange } = this.props;

    const newTime = moment.tz(value, 'HH:mm', customerTZ());
    const currentTime = tentativeRegistration.get('starts_at');

    const startsAt = currentTime
      .clone()
      .hours(newTime.hours())
      .minutes(newTime.minutes());

    const endsAt = startsAt
      .clone()
      .add(this.getEventSchedule().duration, 'seconds');

    const updatedRegistration = tentativeRegistration.merge({
      starts_at: startsAt,
      ends_at: endsAt,
    });

    this.setState({ tentativeRegistration: updatedRegistration });
    if (onTimeChange) onTimeChange(key, value, scheduleDate);
  }

  handleBooking() {
    const { tentativeRegistration } = this.state;
    const { selectedAthleteId, onRegistrationBooked } = this.props;

    const updatedRegistration = tentativeRegistration.merge({
      client_id: selectedAthleteId,
      schedule_id: this.getEventSchedule().id,
    });

    onRegistrationBooked({
      tentativeRegistration: updatedRegistration,
      repeatBooking: false,
    });

    this.setState({
      tentativeRegistration: updatedRegistration,
    });
  }

  handleCancelRegistration({ id }) {
    const { purchasedRegistrations } = this.props;
    this.setState({
      dialogOpen: true,
      registrationToCancel: purchasedRegistrations.find(cr => cr.id === id),
    });
  }

  handleCloseConfirmCancel(confirmed) {
    const { registrationToCancel } = this.state;

    if (confirmed) {
      RegistrationActions.cancel(registrationToCancel);
    }

    this.setState({
      dialogOpen: false,
      registrationToCancel: null,
    });
  }

  getEventSchedule() {
    const {
      event: { customerEvent },
    } = this.props;
    return customerEvent.getIn(['schedules', 0]);
  }

  getScheduledRegistrationCount() {
    const { purchasedRegistrations } = this.props;
    return purchasedRegistrations.size;
  }

  packageScheduler() {
    const {
      afterProfileCreate,
      availableTimesStore,
      creditsRemaining,
      dateAndTimeOnly,
      event,
      fetchAvailabilities,
      handleAthleteChange,
      label,
      managingUserId,
      purchasedRegistrations,
      selectableAthletes,
      selectedAthleteId,
      staff,
    } = this.props;
    const { tentativeRegistration, staffId } = this.state;
    const totalAvailableSessions =
      purchasedRegistrations.size + creditsRemaining;

    if (totalAvailableSessions <= this.getScheduledRegistrationCount()) {
      return (
        <div style={{ margin: '1rem 0 1.5rem 0', fontSize: 18 }}>
          <FormattedMessage
            id={messageId('.no_sessions_remaining', __filenamespace)}
          />
        </div>
      );
    }

    if (this.getScheduledRegistrationCount() > totalAvailableSessions) {
      return (
        <FlexBoxJustify style={{ margin: '1rem 0 1.5rem 0', fontSize: 18 }}>
          <FormattedMessage id={messageId('.scheduled', __filenamespace)} />
          {totalAvailableSessions === Infinity ? (
            <FormattedMessage
              id={messageId('.unlimited_credits', __filenamespace)}
            />
          ) : (
            <FormattedMessage
              id={messageId('.n_of_m', __filenamespace)}
              values={{
                n: (purchasedRegistrations.size + 1).toString(),
                m: totalAvailableSessions.toString(),
              }}
            />
          )}
        </FlexBoxJustify>
      );
    }

    return (
      <div>
        <FlexBoxJustify style={{ margin: '1rem 0 1.5rem 0', fontSize: 18 }}>
          <FormattedMessage id={messageId('.schedule', __filenamespace)} />
          {totalAvailableSessions === Infinity ? (
            <FormattedMessage
              id={messageId('.unlimited_credits', __filenamespace)}
            />
          ) : (
            <FormattedMessage
              id={messageId('.n_of_m', __filenamespace)}
              values={{
                n: (purchasedRegistrations.size + 1).toString(),
                m: totalAvailableSessions.toString(),
              }}
            />
          )}
        </FlexBoxJustify>
        <RegistrationScheduler
          dateAndTimeOnly={dateAndTimeOnly}
          afterProfileCreate={afterProfileCreate}
          athletes={selectableAthletes}
          event={event}
          availableTimesStore={availableTimesStore}
          label={label}
          staff={staff}
          staffId={staffId}
          managingUserId={managingUserId}
          onAthleteChange={(_, __, value) => handleAthleteChange(value)}
          onBookRegistration={() =>
            this.handleBooking(this.getScheduledRegistrationCount())
          }
          onStaffChange={this.handleStaffChange}
          onDateChange={this.handleDateChange}
          onDateChangeStartTime={this.handleDateChangeStartTime}
          onTimeChange={(value, _key) =>
            this.handleTimeChange(value, 'startTime')
          }
          fetchAvailabilities={fetchAvailabilities}
          registration={tentativeRegistration}
          selectedAthleteId={selectedAthleteId}
        />
      </div>
    );
  }

  confirmationDialog() {
    const { intl, confirmationText } = this.props;
    const { dialogOpen, registrationToCancel } = this.state;

    return (
      <Confirmation
        hasCheckbox={false}
        title={t('.remove_booking', intl, __filenamespace)}
        open={dialogOpen}
        onCancel={() => this.handleCloseConfirmCancel(false)}
        onConfirm={() => this.handleCloseConfirmCancel(true)}
        cancelButtonLabel={t('actions.cancel', intl, __filenamespace)}
        confirmButtonLabel={t('actions.submit', intl, __filenamespace)}
      >
        {confirmationText(registrationToCancel)}
      </Confirmation>
    );
  }

  render() {
    const {
      style,
      purchasedRegistrations,
      event: { customerEvent },
      allAthletes,
      staff,
      sessions,
    } = this.props;
    return (
      <div style={style}>
        <div>
          {this.packageScheduler()}
          <hr />
          {purchasedRegistrations.map(r => {
            const session = sessions.get(r.session_id);

            if (!session) {
              return null;
            }

            return (
              <ScheduledSession
                key={r.id}
                cancellationDeadline={
                  this.getEventSchedule().cancellation_deadline
                }
                event={customerEvent}
                onRegistrationCancel={this.handleCancelRegistration}
                profiles={allAthletes}
                registrationId={r.id}
                client_id={r.client_id}
                starts_at={session.starts_at}
                staff_ids={session.staff_ids}
                staff={staff}
                style={{ marginTop: 10 }}
              />
            );
          })}
        </div>
        {this.confirmationDialog()}
      </div>
    );
  }
}

export default injectIntl(Scheduler);
