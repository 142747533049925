import { List } from 'immutable';
import Staff from 'shared/records/Staff.jsx';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './StaffActions';
import ClientDataStoreActions from './ClientActions';

const parserTo = { type: Staff, paginationKey: 'staff' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  // staff currently does not have a fetch it only has a list. As a stop gap and for easy replace handle that here.

  // temp url: go to list instead of fetch /id
  // temp params: filter list by ID
  const params = { ids: [id] };
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      // TEMP make the parser act as we would expect for a fetch to return
      data => parser.parsePagination(data).staff.first(),
      // data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(DataStoreActions.fetchError, error),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(DataStoreActions.listError, error),
  });
};

export const put = ({ id, recordAttributes, success, error }) => {
  // use customer_users endpoint for update. Staff does not have its own yet.
  // we need to call both the success action for staff and client, because if we are converting an invited contact these can update both stores.
  uhApiClient.put({
    url: `customer_users/${id}`,
    data: JSON.stringify({
      attributes: recordAttributes,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([
        DataStoreActions.updateSuccess,
        ClientDataStoreActions.updateSuccess,
        success,
      ])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};
