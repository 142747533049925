import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';
import Button from '@mui/material/Button';

import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  noCredits: {
    flexDirection: 'column',
  },
  button: {
    height: 50,
    marginBottom: 10,
  },
  buttonLabel: {
    fontSize: 16,
  },
};

function NoClientCredits({ intl }) {
  return (
    <FlexBoxCenter style={styles.noCredits}>
      <span style={{ marginTop: 10 }}>
        <Typography variant="h4" align="center">
          <FormattedMessage
            id={messageId('.credits_needed_header', __filenamespace)}
          />
        </Typography>
      </span>
      <span style={{ margin: '10px 0' }}>
        <Typography variant="body2" align="center">
          <FormattedMessage
            id={messageId('.credits_needed_text', __filenamespace)}
          />
        </Typography>
      </span>
      <Button
        fullWidth
        variant="contained"
        style={merge(styles.button, {
          marginTop: 10,
          marginBottom: 10,
          backgroundColor: uhColors.activeBlue,
          color: uhColors.white,
        })}
        onClick={() => POSProductListActions.listByType(['membership'])}
      >
        {t('.view_memberships', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        variant="contained"
        style={merge(styles.button, {
          marginTop: 10,
          marginBottom: 20,
          backgroundColor: uhColors.activeBlue,
          color: uhColors.white,
        })}
        onClick={() => POSProductListActions.listByType(['credit_pass'])}
      >
        {t('.view_passes', intl, __filenamespace)}
      </Button>
    </FlexBoxCenter>
  );
}

export default injectIntl(NoClientCredits);
