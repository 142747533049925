import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

function MembershipCommitment({ membership }) {
  if (!membership.commitment_months) {
    return null;
  }

  return (
    <span style={{ fontStyle: 'italic' }}>
      <FormattedMessage
        id={messageId('.commitment_months', __filenamespace)}
        values={{ months: membership.commitment_months }}
      />
    </span>
  );
}

export default MembershipCommitment;
