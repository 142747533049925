import { List } from 'immutable';
import Role from 'records/Role.js';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import { getCurrentContext } from 'shared/utils/AuthUtils.js';
import DataStoreActions from './RoleActions';

const parserTo = { type: Role, paginationKey: 'roles' };

const parser = new Parser(parserTo);

export const fetch = (/* { id, success, error } */) => {
  throw new Error('RoleSource Fetch Not Yet Implemented');
};

export const list = ({ params, success, error }) => {
  const { userId } = getCurrentContext();
  uhApiClient.get({
    url: `users/${userId}/roles`,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};
