import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import { messageId } from 'shared/utils/LocaleUtils.js';

function EmptyState({ filtersPresent, isTeamEvent }) {
  const noSessionLabel = isTeamEvent
    ? '.no_upcoming_sessions_team'
    : '.no_upcoming_sessions';
  return (
    <Card>
      <Grid container justify="center">
        <Grid item>
          <Typography>
            {filtersPresent ? (
              <FormattedMessage
                id={messageId('.no_results', __filenamespace)}
              />
            ) : (
              <FormattedMessage
                id={messageId(noSessionLabel, __filenamespace)}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default EmptyState;
