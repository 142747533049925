export default alt.generateActions(
  'updateStore',
  'list',
  'listSuccess',
  'listError',
  'createOrUpdate',
  'createOrUpdateSuccess',
  'createOrUpdateError',
  'persistAllChangesSuccess',
  'persistAllChangesError',
  'clearRegistrationField',
  'stageRegistrationField',
  'validateSelectedFields'
);
