import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { ContentModal, TextField } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import MembershipEmbedActions from './MembershipEmbedActions';
import MembershipEmbedStore from './Store';

function MembershipEmbedEditModal({ intl, membershipEmbedStore }) {
  const { editModalOpen, embedCardId, editModalMode, embedErrors, redeem } =
    membershipEmbedStore;
  const isEditMode = editModalMode === 'edit';

  const getTitle = () => {
    if (redeem) {
      return t('.redeem_title', intl, __filenamespace);
    }

    return isEditMode
      ? t('.update_title', intl, __filenamespace)
      : t('.activate_title', intl, __filenamespace);
  };

  const getLabel = () => {
    if (redeem) {
      return t('.redeem', intl, __filenamespace);
    }

    return isEditMode
      ? t('.update_action', intl, __filenamespace)
      : t('.activate_action', intl, __filenamespace);
  };

  return (
    <ContentModal
      title={getTitle()}
      open={editModalOpen}
      successLabel={getLabel()}
      onAbort={MembershipEmbedActions.closeModal}
      onSuccess={() => MembershipEmbedActions.recycleEmbedCard({})}
    >
      <TextField
        fullWidth
        autoFocus
        errorText={embedErrors.getErrors('embedCardError', intl)}
        placeholder={t(
          redeem ? '.redeem_placeholder' : '.input_placeholder',
          intl,
          __filenamespace
        )}
        value={embedCardId}
        onChange={e => MembershipEmbedActions.changeEmbedCard(e.target.value)}
        onBlur={MembershipEmbedActions.validate}
      />
    </ContentModal>
  );
}

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      membershipEmbedStore: MembershipEmbedStore,
    },
  })
)(MembershipEmbedEditModal);
