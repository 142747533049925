import * as React from 'react';
import { Grid, Typography } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import SquareIcon from '@mui/icons-material/Square';

import { CREDIT_PASS_CREDIT_TYPES } from 'shared/records/CreditPassCredit.js';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  badge: color => ({
    width: 12,
    height: 12,
    color,
  }),
};

const CreditPassCredits = ({ creditPass, eventTypes, events, intl }) =>
  creditPass.get('credit_pass_credits').map(creditPassCredit => (
    <div key={creditPassCredit.get('id')}>
      {creditPassCredit.type === CREDIT_PASS_CREDIT_TYPES.all_events ? (
        <Typography variant="subtitle2" align="right">
          {t('.all_event', intl, __filenamespace)}
        </Typography>
      ) : (
        <>
          {creditPassCredit.get('event_type_ids').map(eventTypeId => (
            <Grid
              key={eventTypeId}
              container
              justify="flex-end"
              alignItems="center"
            >
              <SquareIcon
                style={styles.badge(eventTypes.get(eventTypeId).get('color'))}
              />
              <Typography key={eventTypeId} variant="subtitle2" align="right">
                {eventTypes.getIn([eventTypeId, 'name'])}
              </Typography>
            </Grid>
          ))}
          {creditPassCredit.get('event_ids').map(eventId => (
            <Typography key={eventId} variant="subtitle2" align="right">
              {events.get(eventId).get('title')}
            </Typography>
          ))}
        </>
      )}
    </div>
  ));

export default injectIntl(CreditPassCredits);
