import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Switch, Typography } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import MachineSettingsDrawerActions from './Actions';
import MachineSettingsDrawerStore from './Store';

import './styles.scss';

function MachineSettingsDrawer({
  open = false,
  isLoading = false,
  machinesOptionDisabled = false,
}) {
  const intl = useIntl();
  const status = machinesOptionDisabled ? 'disabled' : 'active';

  return (
    <Drawer
      title={t('.title', intl, __filenamespace)}
      open={open}
      headerType="dark"
      classes={{
        root: 'machine-settings-drawer',
        content: 'machine-settings-drawer__content',
      }}
      onClose={MachineSettingsDrawerActions.toggleDrawer}
      content={
        <div>
          <Switch
            disabled={isLoading}
            labelVariant="subtitle1"
            labelValue="Disable Machines"
            labelPlacement="start"
            checked={machinesOptionDisabled}
            classes={{
              root: 'machine-settings-drawer__switch',
            }}
            onChange={MachineSettingsDrawerActions.toggleMachineSettings}
          />
          <Typography
            variant="body1"
            className="machine-settings-drawer__status"
          >
            {t('.current_status', intl, __filenamespace, {
              status: (
                <span className={status}>
                  {t(`.${status}`, intl, __filenamespace)}
                </span>
              ),
            })}
          </Typography>
          <Typography
            variant="body1"
            className="machine-settings-drawer__description"
          >
            {t('.description', intl, __filenamespace)}
          </Typography>
        </div>
      }
    />
  );
}

export default compose(
  memo,
  altContainer({
    store: MachineSettingsDrawerStore,
  })
)(MachineSettingsDrawer);
