import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';

class SelectedEventsStore {
  constructor() {
    this.selectedEvents = [];

    this.bindListeners({
      updateSelectedEvents: EventListActions.eventsSelected,
      deselectEvents: [
        EventListActions.eventsDeselected,
        EventListActions.EVENT_DELETED,
      ],
    });
  }

  updateSelectedEvents(selectedEvents) {
    this.selectedEvents = selectedEvents;
  }

  deselectEvents() {
    this.selectedEvents = [];
  }
}

export default alt.createStore(SelectedEventsStore, 'SelectedEventsStore');
