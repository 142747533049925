import { Record } from 'immutable';
import DateOfBirth from 'records/DateOfBirth.jsx';

class TeamDetail extends Record({
  id: null,
  gender_restriction: 'coed',
  min_age: null,
  max_age: null,
  roster_size: 1,
  date_of_birth: new DateOfBirth(),
}) {
  hasAgeRestriction() {
    return this.min_age || this.max_age;
  }
}

export default TeamDetail;
