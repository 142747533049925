import * as React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function FixedEventCard({ clients, inner, orderItem, intl, hasPaymentPlan }) {
  const clientIds = orderItem.getIn(['orderable', 'client_ids'], Set());

  return inner ? (
    <>
      <Grid container item xs={12}>
        <Typography className="order-item__name" align="left" variant="h4">
          {orderItem.get('primary_display_text')}
        </Typography>
      </Grid>
      <Grid
        className="order-item__fixed-event-date"
        container
        item
        xs={12}
        justify="space-between"
      >
        <Grid item xs={12}>
          <Typography
            className="order-item__title order-item__date"
            variant="caption"
          >
            {t('.dates', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className="order-item__fixed-date-value">
            {orderItem.get('secondary_display_accent_text')}
          </Typography>
        </Grid>
      </Grid>

      {hasPaymentPlan && (
        <Grid container item xs={12} justify="space-between">
          <Grid item>
            <Button
              classes={{
                root: 'order-item__payment-plan-indicator',
                label: 'order-item__indicator-button-label',
              }}
            >
              {t('.plan_indicator', intl, __filenamespace)}
            </Button>
          </Grid>
          <Grid item />
        </Grid>
      )}
      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.client', intl, __filenamespace, {
              clientsCount: clientIds.size,
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right">
            {clientIds.map(id => (
              <Typography variant="subtitle2">
                {clients.get(id).name()}
              </Typography>
            ))}
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid item>
        <Typography className="order-item__title" variant="subtitle2">
          {t('.qty', intl, __filenamespace, {
            qty: orderItem.get('display_quantity'),
          })}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" align="right">
          <FormattedCurrency value={orderItem.get('total') || 0} fromCents />
        </Typography>
      </Grid>
    </>
  );
}

FixedEventCard.propTypes = {
  intl: PropTypes.object.isRequired,
  orderItem: PropTypes.object,
};

FixedEventCard.defaultProps = {
  orderItem: {},
};

export default FixedEventCard;
