import * as React from 'react';

import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import ProductCard from 'point_of_sale/components/products/list/cards/ProductCard.jsx';

function EventCard({ event }) {
  return (
    <ProductCard
      className="event-card"
      color={event.typeColor()}
      onClick={() => POSProductListActions.eventSelected(event)}
      primaryText={event.title}
      secondaryText={event.scheduleText()}
      tertiaryText={event.typeName()}
    />
  );
}

export default EventCard;
