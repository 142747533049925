import { ClientSource, StaffSource } from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent, toSnakeCase } from 'shared/utils/ObjectUtils.jsx';
import {
  CLIENT,
  STANDARD,
  USER_ROLES,
  getValidUserRole,
  mapRoleToType,
} from 'shared/utils/UserUtils.jsx';
import UpdateContactActions from './Actions';

class UpdateContactStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      mounted: UpdateContactActions.mounted,
      contactFetchSuccess: UpdateContactActions.clientFetchSuccess,
      contactFetchError: UpdateContactActions.clientFetchError,
      reset: UpdateContactActions.reset,
      updateContactFields: UpdateContactActions.updateContactFields,
      updateContactSuccess: UpdateContactActions.updateContactSuccess,
      updateContactError: UpdateContactActions.updateContactError,
      saveContact: UpdateContactActions.saveContact,
    });
  }

  isStaff() {
    return this.type !== CLIENT;
  }

  // role = 'client' | 'staff_member'
  mounted({ contactId, contactType = CLIENT }) {
    if (contactId) {
      this.id = contactId;
      this.type = contactType;
      this.fetchContact();
    }
  }

  fetchContact() {
    this.loading = true;

    const Source = this.isStaff() ? StaffSource : ClientSource;
    return Source.fetch({
      id: this.id,
      success: UpdateContactActions.clientFetchSuccess,
      error: UpdateContactActions.clientFetchError,
    });
  }

  contactFetchSuccess(contact) {
    const { role, type } = contact;
    this.type = toSnakeCase(type);
    if (contact.isStaff()) {
      if (USER_ROLES.includes(role)) {
        this.role = role;
      } else {
        this.role = toSnakeCase(this.type);
      }
    } else {
      this.role = STANDARD;
    }

    this.firstName = contact.first_name;
    this.lastName = contact.last_name;
    this.email = contact.email;
    this.loading = false;
  }

  contactFetchError() {
    this.loading = false;
  }

  updateContactFields({ target: { name, value } }) {
    if (name === 'role') {
      this.role = value;
      this.type = mapRoleToType(this.role);
    }
    if (name === 'firstName') {
      this.firstName = value;
    }
    if (name === 'lastName') {
      this.lastName = value;
    }
    if (name === 'email') {
      this.email = value;
    }

    if (this.errors.hasErrors()) {
      this.validate();
    }
  }

  validate() {
    this.errors = this.errors.clear();
    if (!isPresent(this.firstName)) {
      this.errors = this.errors.add('firstName', 'records.errors.required');
    }
    if (!isPresent(this.lastName)) {
      this.errors = this.errors.add('lastName', 'records.errors.required');
    }

    if (!isPresent(this.email)) {
      this.errors = this.errors.add('email', 'records.errors.required');
    }

    if (isPresent(this.email) && !/@/.test(this.email)) {
      this.errors = this.errors.add('email', 'validation.email.invalid');
    }
    return this.errors.isEmpty();
  }

  saveContact() {
    if (!this.validate()) {
      return;
    }
    this.loading = true;
    if (this.isStaff()) {
      StaffSource.put({
        id: this.id,
        recordAttributes: {
          email: this.email,
          first_name: this.firstName,
          last_name: this.lastName,
          type: this.type,
          role: getValidUserRole(this.role),
        },
        success: UpdateContactActions.updateContactSuccess,
        error: UpdateContactActions.updateContactError,
      });
      return;
    }
    ClientSource.put({
      id: this.id,
      recordAttributes: {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        type: this.type,
        role: this.role,
      },
      success: UpdateContactActions.updateContactSuccess,
      error: UpdateContactActions.updateContactError,
    });
  }

  updateContactSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.contacts.updateContact.Store.update_success',
    });
    MessageWindowActions.addMessage.defer(message);

    // DataStore will automatically be updated based on source that is called and populate changes.
    this.reset(); // close modal
    this.loading = false;
  }

  updateContactError() {
    this.loading = false;
  }

  reset() {
    this.errors = new FieldErrors();
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.loading = false;
    this.role = STANDARD;
    this.type = CLIENT;
    // this will also close the modal
    this.id = null;
  }
}

export default alt.createStore(UpdateContactStore, 'UpdateContactStore');
