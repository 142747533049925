import * as React from 'react';
import { Grid, Spinner } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import Filters from 'containers/events/admin/staff/components/Filters.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import Table from 'containers/events/admin/staff/components/Table.jsx';
import StaffActions from './actions';
import StaffStore from './store';

const styles = {
  root: {
    padding: '12px 0',
    overflow: 'hidden',
  },
};

function Staff({
  eventId,
  staffStore: {
    loading,
    page,
    perPage,
    search,
    staffIds,
    totalCount,
    filtersPresent,
  },
}) {
  React.useEffect(() => {
    StaffActions.mounted(eventId);
  }, [eventId]);

  return (
    <div style={styles.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters search={search} />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Table staffIds={staffIds} filtersPresent={filtersPresent} />
            </Grid>
            <Grid item xs={12}>
              <Paginator
                currentPage={page}
                perPage={perPage}
                totalCount={totalCount}
                onPageSelect={StaffActions.pageSelected}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

// export default Staff;
export default altContainer({
  stores: {
    staffStore: StaffStore,
  },
})(Staff);
