import { Record, List, Map } from 'immutable';
import { capitalizeSentence } from 'shared/utils/StringUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const getKeypath = field => {
  const fieldpath = field.constructor === Array ? field : [field];
  return ['errors'].concat(fieldpath);
};

class FieldErrors extends Record({
  errors: Map(),
}) {
  constructor(object = {}) {
    const errors = object.errors ? Map(object.errors) : Map();

    super(merge(object, { errors }));
  }

  add(field, error) {
    return this.updateIn(getKeypath(field), List(), errors =>
      errors.push(error)
    );
  }

  remove(field) {
    return this.set('errors', this.errors.delete(field));
  }

  // eslint-disable-next-line class-methods-use-this
  clear() {
    return new FieldErrors();
  }

  merge(other) {
    return new FieldErrors({ errors: this.errors.merge(other.errors) });
  }

  getErrors(field, intl = null) {
    const err = this.getIn(getKeypath(field), []);
    if (err.length === 0) return '';

    if (intl) {
      return capitalizeSentence(`${err.map(id => t(id, intl)).join(', ')}.`);
    }
    return capitalizeSentence(`${err.join(', ')}.`);
  }

  isEmpty() {
    return this.errors.isEmpty();
  }

  hasErrors() {
    return !this.isEmpty();
  }
}

export default FieldErrors;
