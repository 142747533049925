import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Staff(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <defs>
        <circle id="path-1" cx="12" cy="12" r="12" />
      </defs>
      <g
        id="Reports"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Add-Entry-Button-Update"
          transform="translate(-199.000000, -271.000000)"
        >
          <g id="Group-2" transform="translate(167.000000, 207.000000)">
            <g
              id="Empty_Staff_Icon"
              transform="translate(32.000000, 64.000000)"
            >
              <mask id="mask-2" fill="white">
                <circle cx="12" cy="12" r="12" />
              </mask>
              <circle
                id="Mask"
                fill="var(--color-old-gray)"
                cx="12"
                cy="12"
                r="12"
              />
              <path
                d="M12,13.25 C10.3311111,13.25 7,14.0866667 7,15.75 L7,17 L17,17 L17,15.75 C17,14.0866667
                13.6688889,13.25 12,13.25 M12,12 C13.3811111,12 14.5,10.88 14.5,9.5 C14.5,8.11888889
                13.3811111,7 12,7 C10.6188889,7 9.5,8.11888889 9.5,9.5 C9.5,10.88 10.6188889,12 12,12"
                id="Fill-1"
                fill="var(--color-white)"
                mask="url(#mask-2)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Staff;
