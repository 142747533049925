import * as React from 'react';
import PropTypes from 'prop-types';
import { ContentModal } from '@upperhand/playmaker';

import TeamTypeForm from 'shared/components/TeamTypeForm.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import TeamTypeEditingStore from 'shared/stores/TeamTypeEditingStore.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

function Modal({ teamType, isOpen, onSuccess, onAbort, intl }) {
  const isNew = teamType ? teamType.id === null : false;
  const title = isNew
    ? t('.title_create_mode', intl, __filenamespace)
    : t('.title_edit_mode', intl, __filenamespace);

  return (
    <ContentModal
      open={isOpen}
      title={title}
      onSuccess={onSuccess}
      onAbort={onAbort}
      cancelLabel={t('.cancel_button', intl, __filenamespace)}
      successLabel={t('.success_button', intl, __filenamespace)}
    >
      <TeamTypeForm teamType={teamType} />
    </ContentModal>
  );
}

Modal.propTypes = {
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
};

export default compose(
  altContainer({
    stores: {
      teamType: () => ({
        store: TeamTypeEditingStore,
        value: TeamTypeEditingStore.getState().record,
      }),
    },
  })
)(Modal);
