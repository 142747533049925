import React from 'react';
import { fromJS, List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import EventLink from 'event_mgmt/shared/components/_EventLink.jsx';

import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';

import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';

import uhTheme from '_uh_theme.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { numberToCurrency } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { redirectTo } from 'shared/utils/RouteUtils';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';

/* TODO: Styles needs cleanup as well */
const styles = {
  title: {
    ...boldText,
  },
  eventType: {
    fontSize: '12px',
    color: uhTheme.palette.inactiveTabTextColor,
  },
  dateRange: {
    paddingTop: '4px',
    fontSize: '13px',
  },
  status: {
    fontSize: '12px',
    marginBottom: '5px',
  },
  location: {
    fontSize: '13px',
  },
  clientsLabel: {
    fontSize: '13px',
    width: '50px',
  },
  clientsCount: {
    ...boldText,
    fontSize: '13px',
  },
  priceLabel: {
    fontSize: '13px',
    width: '50px',
  },
  priceValue: {
    ...boldText,
    fontSize: '13px',
  },
  isPurchased: {
    width: 18,
    height: 18,
  },
  cardStyle: event => ({
    marginTop: '1em',
    marginBottom: '1em',
    padding: '10px',
    borderLeftStyle: 'solid',
    borderLeftColor: event.typeColor(),
    borderLeftWidth: 8,
    textTransform: 'capitalize',
    fontSize: '15px',
    color: uhTheme.palette.headerGrey,
    lineHeight: '18px',
  }),
  privateLabel: {
    backgroundColor: grey[800],
    color: 'white',
    padding: '2px',
    borderRadius: 2,
    textTransform: 'uppercase',
    fontSize: '0.7em',
    fontWeight: 600,
    width: '60px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkBox: {
    marginLeft: '-9px',
    marginTop: '-7px',
  },
};

const getPrice = (event, automations) => {
  const atds = automations.descriptions.filter(
    atd => atd.event_id === event.id
  );
  const collection = PricingAutomationCollection.create(atds);
  if (collection.getNumberOfPricingOptions() > 0) {
    return collection.asText();
  }
  return numberToCurrency(event.price || 0);
};

function PrivateLabel() {
  return (
    <Paper style={styles.privateLabel} zDepth={0}>
      <FormattedMessage id={messageId('.private', __filenamespace)} />
    </Paper>
  );
}

function EventCard({
  event,
  onSelect,
  isEventSelected,
  allowDeleteEvents,
  purchasedViewable,
  clientView,
  automations,
}) {
  const showPurchasedIcon =
    purchasedViewable && event.isPurchased(currentUser().id);

  return (
    <Paper style={styles.cardStyle(event)} onClick={() => onSelect(true)}>
      <Grid container>
        <Grid item container xs={12} wrap="nowrap">
          {!clientView && (
            <Grid item xs="auto">
              <Checkbox
                sx={styles.checkBox}
                onChange={onSelect}
                checked={isEventSelected}
              />
            </Grid>
          )}
          <Grid item container xs={8}>
            <Grid item xs={12}>
              <Stack>
                <Typography sx={styles.title}>
                  <EventLink
                    event={event}
                    showDeletedEvents={allowDeleteEvents}
                  />
                </Typography>
                <Typography sx={styles.eventType}>
                  {event.event_type.name}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Stack alignItems="flex-end">
              <Typography sx={styles.status}>{event.status}</Typography>
              {event.privacy && <PrivateLabel />}
            </Stack>
          </Grid>
        </Grid>
        <Grid item container xs={12} wrap="nowrap">
          <Grid item xs={showPurchasedIcon ? 10 : 12}>
            <Stack>
              <Typography sx={styles.dateRange}>
                {event.dateRangeText()}
              </Typography>
              <Typography sx={styles.location}>{event.locationName}</Typography>
            </Stack>
          </Grid>
          {showPurchasedIcon && (
            <Grid
              item
              container
              xs={2}
              alignItems="center"
              justifyContent="center"
            >
              <CheckBoxIcon
                sx={{
                  ...styles.isPurchased,
                  flex: '1 1 30%',
                  color: uhColors.green,
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ margin: '5px 0' }} />
        </Grid>
        {!clientView && (
          <Grid item xs={12}>
            <Stack sx={{ paddingTop: '10px' }}>
              <Stack direction="row" spacing={1}>
                <Typography sx={styles.clientsLabel}>
                  <FormattedMessage
                    id={messageId('.clients', __filenamespace)}
                  />
                </Typography>
                <Typography sx={styles.clientsCount}>
                  {event.participant_count || '0'}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={styles.priceLabel}>
                  <FormattedMessage id={messageId('.price', __filenamespace)} />
                </Typography>
                <Typography sx={styles.priceValue}>
                  {getPrice(event, automations)}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

class ContentCards extends React.Component {
  handleEventSelection(event) {
    const { clientView, selectedEvents } = this.props;

    if (clientView) {
      redirectTo({ path: event.path });
      return;
    }

    const prevSelected = fromJS(selectedEvents).toSet();
    const eventInSelected = prevSelected.find(e => e.get('id') === event.id);

    if (!eventInSelected) {
      EventListActions.eventsSelected(prevSelected.add(event).toArray());
    } else {
      EventListActions.eventsSelected(
        prevSelected.remove(eventInSelected).toArray()
      );
    }
  }

  isEventSelected(event) {
    const { selectedEvents } = this.props;

    return !!selectedEvents.find(
      selectedEvent => selectedEvent.id === event.id
    );
  }

  render() {
    const {
      events,
      clientView,
      automations,
      purchasedViewable,
      allowDeleteEvents,
    } = this.props;

    return (
      <div>
        {events.map(event => (
          <EventCard
            key={event.id}
            event={event}
            onSelect={() => this.handleEventSelection(event)}
            isEventSelected={!clientView && this.isEventSelected(event)}
            clientView={clientView}
            automations={automations}
            purchasedViewable={purchasedViewable}
            allowDeleteEvents={allowDeleteEvents}
          />
        ))}
      </div>
    );
  }
}

ContentCards.propTypes = {
  purchasedViewable: PropTypes.bool,
  clientView: PropTypes.bool,
  allowDeleteEvents: PropTypes.bool,
  events: PropTypes.instanceOf(List),
};

ContentCards.defaultProps = {
  purchasedViewable: true,
  clientView: false,
  allowDeleteEvents: false,
  events: List(),
};

export { ContentCards, EventCard };
