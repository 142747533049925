import * as React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

export function Action({ action, label, disabled }) {
  return (
    <Button
      variant="contained"
      onClick={e => {
        e.preventDefault();
        action();
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

export const NextAction = injectIntl(
  ({ action, isLoading = false, disabled, intl }) => (
    <SpinWhileLoading
      isProcessing={isLoading}
      spinWhile="isProcessing"
      absolute={false}
    >
      <Action
        action={action}
        label={t(
          'onboarding.AccountSetupPaysafe._Stepper.next',
          intl,
          __filenamespace
        )}
        disabled={disabled}
      />
    </SpinWhileLoading>
  )
);

export const ErrorMessage = injectIntl(({ intl, show }) => {
  if (show) {
    return (
      <div style={Styles.error}>{t('.form_error', intl, __filenamespace)}</div>
    );
  }
  return null;
});

export function Step({ children }) {
  return <div style={Styles.root}>{children}</div>;
}
