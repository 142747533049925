import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import SearchablePopoverMenu from 'calendar/components/quick_schedule/SearchablePopoverMenu.jsx';

import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  EventSelector: {
    position: 'relative',
    width: '100%',
  },
  MenuItemText: {
    maxWidth: '100%',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Loading: {
    textAlign: 'center',
  },
};

const handleSelect = event => {
  QuickScheduleActions.selectEvent(event);
  EventDisplayActions.clientDisplayMounted({ eventId: event.id });
};

const menuItems = (filteredEvents, fetchingAvailableEvents) => {
  if (!fetchingAvailableEvents) {
    if (filteredEvents.length) {
      return filteredEvents.map(event => (
        <MenuItem key={event.id} onClick={() => handleSelect(event)}>
          <div style={styles.MenuItemText}>{event.title}</div>
        </MenuItem>
      ));
    }
    return (
      <MenuItem onClick={() => {}}>
        <div style={styles.MenuItemText}>
          <FormattedMessage
            id={messageId('.no_events_available', __filenamespace)}
          />
        </div>
      </MenuItem>
    );
  }
  return (
    <div style={styles.Loading}>
      <CircularProgress size={45} />
    </div>
  );
};

function EventSelector({
  intl,
  events,
  selectedEvent,
  fetchingAvailableEvents,
  onSearchTermChange,
}) {
  return (
    <div>
      <SearchablePopoverMenu
        menuItems={menuItems(events, fetchingAvailableEvents)}
        hintText={t('.search_events', intl, __filenamespace)}
        value={selectedEvent?.title}
        onSearchTermChange={onSearchTermChange}
      />
    </div>
  );
}

export default injectIntl(EventSelector);
