import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AltContainer from 'alt-container';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import DownloadIcon from '@mui/icons-material/Download';

import ContactImportActions from 'contacts/index/actions/ContactImportActions.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, uhContrast } from 'shared/styles/uhStyles.jsx';

import ContactImportDesktopStore, {
  STEP_WAITING,
  STEP_UPLOADING,
  STEP_PROCESSING,
  STEP_SUCCESS,
  STEP_ERROR,
  STEP_FAILURE,
} from 'contacts/index/stores/ContactImportDesktopStore.js';

const styles = {
  button: {
    height: '50px',
    textTransform: 'initial',
  },

  buttonLabel: {
    fontSize: '15px',
    textTransform: 'none',
  },

  contentContainer: {
    padding: 20,
  },

  header: merge(uhContrast.lightOnDark, {
    alignItems: 'center',
    fontSize: 15,
    paddingLeft: 20,
    minHeight: 56,
  }),
};

const drawerClosed = () => ContactImportActions.drawerClosed();

const DownloadSection = injectIntl(({ intl }) => (
  <div>
    <div style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 20 }}>
      <FormattedMessage id={messageId('.download_template', __filenamespace)} />
    </div>

    <div style={{ lineHeight: 1.4 }}>
      <FormattedMessage
        id={messageId('.template_description', __filenamespace)}
      />
    </div>
    <Button
      disableRipple
      href={window.contactImportTemplateUrl}
      sx={merge(styles.button, {
        padding: 0,
        textTransform: 'initial',
        '&:hover': { backgroundColor: 'transparent' },
      })}
      startIcon={<DownloadIcon />}
    >
      {t('.download_template_csv', intl, __filenamespace)}
    </Button>
  </div>
));

const handleFileSelect = event => {
  ContactImportActions.fileSelected(event.target.files[0]);
};

function UploadButton({ label, style }) {
  return (
    <Button
      fullWidth
      variant="contained"
      component="label"
      sx={merge(styles.button, style)}
    >
      <>
        {label}
        <input
          type="file"
          accept="text/csv"
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />
      </>
    </Button>
  );
}

function Waiting({ error, intl }) {
  return (
    <div>
      {error && (
        <div style={{ color: uhColors.darkRed, marginBottom: 10 }}>
          {error.toString({ intl })}
        </div>
      )}
      <UploadButton label={t('.upload_csv', intl, __filenamespace)} />
    </div>
  );
}

function Progress({ step }) {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <FormattedMessage id={messageId(`.${step}`, __filenamespace)} />
      </div>
      <LinearProgress mode="indeterminate" />
    </div>
  );
}

function CompletionMessage({ contactUpload, style }) {
  const messageKey =
    contactUpload.failed_row_count === 0
      ? '.complete'
      : '.complete_with_errors';

  return (
    <div style={style}>
      <FormattedMessage
        id={messageId(messageKey, __filenamespace)}
        values={{
          success_row_count: contactUpload.success_row_count,
          failed_row_count: contactUpload.failed_row_count,
        }}
      />
    </div>
  );
}

function Complete({ contactUpload, intl }) {
  return (
    <div>
      <CompletionMessage
        contactUpload={contactUpload}
        style={{ marginBottom: 10 }}
      />
      <UploadButton
        label={t('.upload_another_csv', intl, __filenamespace)}
        style={{ marginBottom: '10px' }}
      />
      {contactUpload.hasErrors() && (
        <Button
          fullWidth
          color="error"
          href={contactUpload.errors_url}
          variant="outlined"
          sx={merge(styles.button, styles.buttonLabel, {
            marginBottom: '10px',
          })}
        >
          {t('.view_errors', intl, __filenamespace)}
        </Button>
      )}
      <Button
        fullWidth
        variant="outlined"
        color="info"
        sx={styles.button}
        onClick={drawerClosed}
      >
        {t('actions.close', intl, __filenamespace)}
      </Button>
    </div>
  );
}

const UploadControls = injectIntl(({ contactUpload, step, error, intl }) => {
  switch (step) {
    case STEP_WAITING:
    case STEP_FAILURE:
      return <Waiting error={error} intl={intl} />;
    case STEP_UPLOADING:
    case STEP_PROCESSING:
      return <Progress step={step} />;
    case STEP_SUCCESS:
    case STEP_ERROR:
      return <Complete contactUpload={contactUpload} intl={intl} />;
    default:
      return null;
  }
});

function UploadSection({ contactUpload, step, error, style }) {
  return (
    <div style={style}>
      <div style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 20 }}>
        <FormattedMessage
          id={messageId('.upload_spreadsheet', __filenamespace)}
        />
      </div>

      <div style={{ marginBottom: 10, lineHeight: 1.4 }}>
        <FormattedMessage
          id={messageId('.upload_description', __filenamespace)}
        />
      </div>

      <div style={{ marginBottom: 20, fontStyle: 'italic', lineHeight: 1.4 }}>
        <FormattedMessage id={messageId('.email_warning', __filenamespace)} />
      </div>

      <UploadControls contactUpload={contactUpload} step={step} error={error} />
    </div>
  );
}

function ContactImportDesktop({ contactUpload, step, error }) {
  return (
    <div style={styles.contentContainer}>
      <DownloadSection />
      <Divider sx={{ height: '2px', marginBottom: '20px' }} />
      <AltContainer store={ContactImportDesktopStore}>
        <UploadSection
          contactUpload={contactUpload}
          step={step}
          error={error}
        />
      </AltContainer>
    </div>
  );
}

export default ContactImportDesktop;
