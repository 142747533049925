import * as React from 'react';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button, Grid, Typography } from '@upperhand/playmaker';

import ColumnarForm from 'shared/components/ColumnarForm.jsx';
import CreditPassEditingActions from 'credit_passes/actions/CreditPassEditingActions.js';
import CreditPassEditingStore from 'credit_passes/stores/CreditPassEditingStore.js';
import CreditPassSpecificEventsListStore from 'credit_passes/stores/CreditPassSpecificEventsListStore.jsx';
import CreditsForm from 'credit_passes/components/editing/_CreditsForm.jsx';
import EditingForm from 'credit_passes/components/editing/_EditingForm.jsx';
import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  columnarForm: {
    root: {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

class CreditPassEditing extends React.PureComponent {
  componentDidMount() {
    CreditPassEditingActions.reset();
    EventTypeListingActions.list();
    const { creditPassId } = this.props;
    if (creditPassId) {
      CreditPassEditingActions.fetch(creditPassId);
    }
  }

  render() {
    const {
      creditPassEditingStore: {
        disabledAddingNewCredit,
        expirationEnabled,
        existingAllEventsCredits,
        isLoading: isPassLoading,
        isSaving,
        record,
        selectedEventIds,
        selectedEventTypeIds,
        unselectedEventTypes,
      },
      creditPassSpecificEventsListStore: { events: specificEvents, isLoading },
      eventTypes,
      events,
      intl,
    } = this.props;
    return (
      <div className="credit-pass-container">
        <SpinWhileLoading isLoading={isPassLoading || isLoading}>
          <Grid container item className="credit-pass-container__header">
            <Grid container item xs>
              <ReactRouterLink to={customerScopedRoute('credit_passes')}>
                <Button
                  type="tertiary"
                  disableRipple
                  classes={{ root: 'credit-pass-container__back-btn' }}
                  icon="arrowLeft"
                />
              </ReactRouterLink>
              <Typography
                variant="h4"
                className={clsx(
                  'credit-pass-container__name',
                  smallScreen() && 'credit-pass-container__mobile-name'
                )}
              >
                {t(
                  record.id ? '.edit_header' : '.create_header',
                  intl,
                  __filenamespace
                )}
              </Typography>
            </Grid>
          </Grid>
          <ColumnarForm
            className="iphone-x-content"
            intl={intl}
            record={record}
            columns={[
              <EditingForm
                isSaving={isSaving}
                expirationEnabled={expirationEnabled}
              />,
              <CreditsForm
                disabledAddingNewCredit={disabledAddingNewCredit}
                events={events}
                existingAllEventsCredits={existingAllEventsCredits}
                eventTypes={eventTypes}
                selectedEventIds={selectedEventIds}
                selectedEventTypeIds={selectedEventTypeIds}
                specificEvents={specificEvents}
                unselectedEventTypes={unselectedEventTypes}
              />,
            ]}
            style={styles.columnarForm}
          />
        </SpinWhileLoading>
      </div>
    );
  }
}

export default altContainer({
  stores: {
    creditPassEditingStore: CreditPassEditingStore,
    creditPassSpecificEventsListStore: CreditPassSpecificEventsListStore,
    eventTypes: () => ({
      store: EventTypeListingStore,
      value: EventTypeListingStore.getState().eventTypes,
    }),
    events: () => ({
      store: EventListStore,
      value: EventListStore.getState().events,
    }),
  },
})(injectIntl(CreditPassEditing));
