import FieldErrors from 'shared/records/FieldErrors.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { isEmailValid } from 'shared/utils/UserUtils.jsx';
import ForgotPasswordActions from './Actions';

class ForgotPasswordStore extends UpperHandStore {
  constructor() {
    super();
    this.email = null;
    this.errors = new FieldErrors();
    this.requestStatus = null;
    this.isClickButton = false;

    this.bindListeners({
      handleUpdateStore: ForgotPasswordActions.UPDATE_STORE,
      sendResetRequest: ForgotPasswordActions.SEND_RESET_REQUEST,
      resetRequestSuccess: ForgotPasswordActions.RESET_REQUEST_SUCCESS,
      resetRequestError: ForgotPasswordActions.RESET_REQUEST_ERROR,
      tryAgain: ForgotPasswordActions.TRY_AGAIN,
      clickButton: ForgotPasswordActions.clickButton,
    });
  }

  clickButton() {
    this.isClickButton = !this.isClickButton;
  }

  tryAgain() {
    this.requestStatus = null;
  }

  handleUpdateStore(data) {
    super.handleUpdateStore(data);
    if (this.errors.hasErrors()) this.validateEmail();
  }

  sendResetRequest() {
    this.errors = new FieldErrors(); // reset to initial state
    if (!this.emailIsValid()) return;

    this.requestStatus = 'sending';
    uhApiClient.post({
      url: 'accounts/reset_password_email',
      data: JSON.stringify({ email: this.email }),
      success: ForgotPasswordActions.resetRequestSuccess,
      error: ForgotPasswordActions.resetRequestError,
    });
  }

  emailIsValid() {
    this.validateEmail();
    return this.errors.isEmpty();
  }

  validateEmail() {
    this.errors = new FieldErrors();

    if (!isEmailValid(this.email)) {
      this.errors = this.errors.add('email', 'validation.email.invalid');
    }
  }

  resetRequestError(...args) {
    this.requestStatus = 'failed';
    this.errors = this.errors.add('email', 'validation.password.reset.unknown');
    this.notifyError('error while requesting password reset', args);
  }

  resetRequestSuccess(data) {
    if (!data) {
      /* the API responds with a 204 (No Content) when a User or SP account is blank? */
      this.requestStatus = 'failed';
      this.errors = this.errors.add(
        'email',
        'validation.password.reset.no_such_email'
      );
    } else {
      /* otherwise, it responds with a 201 and a success boolean */
      this.requestStatus = data.success ? 'success' : 'failed';
      this.emailExists = true;

      if (data.success) {
        this.errors = new FieldErrors();
      } else {
        this.errors = this.errors.add(
          'email',
          'validation.password.reset.unknown'
        );
      }
    }
  }
}

export default alt.createStore(ForgotPasswordStore, 'ForgotPasswordStore');
