import { OrderedMap } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { TeamTypeActions } from 'sources';

class TeamTypeDataStore extends UpperHandStore {
  constructor() {
    super();

    this.teamTypes = OrderedMap();

    this.bindListeners({
      listSuccess: [TeamTypeActions.listSuccess],
      listError: [TeamTypeActions.listError],
    });
  }

  listSuccess({ team_types: teamTypes }) {
    const fetchedTeamTypes = teamTypes.groupBy(s => s.id).map(s => s.first());

    this.teamTypes = this.teamTypes.merge(fetchedTeamTypes);
  }

  listError(args) {
    this.notifyError('error fetching event Types', ...args);
  }
}

export default alt.createStore(TeamTypeDataStore, 'TeamTypeDataStore');
