// neeeeeeds spec
import MarketingActions from 'event_mgmt/shared/actions/MarketingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class MarketingStore extends UpperHandStore {
  constructor() {
    super();
    this.posting = false;
    this.eventTitle = '';
    this.bindListeners({
      handlePost: MarketingActions.post,
      handlePostSuccess: MarketingActions.postSuccess,
      handlePostError: MarketingActions.postError,
    });
  }

  handlePost(data) {
    this.posting = true;
    this.eventTitle = data.eventTitle;
    return uhApiClient.post({
      url: `/events/${data.eventId}/marketing`,
      data: JSON.stringify(data.params),
      success: MarketingActions.postSuccess,
      error: MarketingActions.postError,
    });
  }

  handlePostSuccess(data) {
    this.success = data.success;
    this.posting = false;
  }

  handlePostError(...args) {
    this.success = false;
    this.posting = false;
    this.notifyError('error while posting marketing email', args);
  }
}

export default alt.createStore(MarketingStore, 'MarketingStore');
