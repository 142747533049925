import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ColoredEventType from 'memberships/components/_ColoredEventType.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const wrapper = {
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: -2,
  },

  outer: {
    display: 'flex',
    flexDirection: 'column',
  },
};
function ExclusiveEventBenefits({
  membership,
  eventTypes,
  style,
  leftStyles,
  itemStyle,
}) {
  const exclusiveEventTypeIds = membership.membership_event_permissions.map(
    p => p.event_type_id
  );
  const exclusiveEventTypes = eventTypes.filter(t =>
    exclusiveEventTypeIds.includes(t.id)
  );

  if (exclusiveEventTypes.isEmpty()) {
    return null;
  }

  return (
    <div style={style}>
      <div style={{ fontWeight: 800 }}>
        <FormattedMessage
          id={messageId('.exclusive_event_access', __filenamespace)}
        />
      </div>
      <div style={leftStyles ? wrapper.inner : wrapper.outer}>
        {exclusiveEventTypes.map(t => (
          <ColoredEventType key={t.id} eventType={t} style={itemStyle} />
        ))}
      </div>
    </div>
  );
}

export default injectIntl(ExclusiveEventBenefits);
