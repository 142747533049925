import * as React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';

import { Link } from 'react-router-dom';
import { Typography, Button } from '@upperhand/playmaker';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';

import DevTestActions from './Actions';
import DevTestStore from './Store';
import RecordCard from './components/RecordCard.jsx';

const listRecords = (dataStore, paginationKey) => {
  // some of our DataStores use the paginationkey "string" instead of a generic property "records"
  const records = dataStore.records || dataStore[paginationKey];

  if (records && records.size > 0) {
    return records
      .map(r => (
        <>
          <Link
            to={customerScopedRoute(
              `/devTools/${paginationKey}/${String(r.id)}`
            )}
          >
            <Button> Show Page {r.id}</Button>
          </Link>
          <RecordCard key={r.id} record={r} />
        </>
      ))
      .toList();
  }
  return <div> no records yet</div>;
};

function ListComponent({
  devTestStore: { totalCount },
  dataSource,
  dataStore,
  paginationKey,
}) {
  React.useEffect(() => {
    DevTestActions.mounted({ dataSource, paginationKey });
  }, [dataSource, paginationKey]);

  return (
    <>
      <Typography variant="h2">List {paginationKey}</Typography>
      <div>TotalCount: {totalCount}</div>
      {listRecords(dataStore, paginationKey)}
    </>
  );
}

export default altContainer({
  stores: {
    devTestStore: DevTestStore,
  },
})(ListComponent);
