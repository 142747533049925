import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import FilteredEventListStoreImpl from 'event_mgmt/index/stores/_FilteredEventListStoreImplementation.jsx';
import SpecificEventsActions from 'event_mgmt/index/actions/SpecificEventsActions.jsx';
import SpecificEventsFilterStore from 'shared/stores/SpecificEventsFilterStore.jsx';

class SpecificEventsListStore extends FilteredEventListStoreImpl {
  constructor() {
    super(SpecificEventsActions);
    this.bindListeners({
      addSpecificEvent: SpecificEventsActions.ADD_SPECIFIC_EVENT,
      removeSpecificEvent: SpecificEventsActions.REMOVE_SPECIFIC_EVENT,
    });
  }

  addSpecificEvent(id) {
    const index = this.events.findIndex(e => e.id === id);
    if (index === -1) {
      this.waitFor(EventListStore);
      const { events } = EventListStore.getState();
      const newEventToAdd = events.find(e => e.id === id);
      this.events = this.events.push(newEventToAdd);
    }
  }

  removeSpecificEvent(id) {
    this.events = this.events.filter(se => se.id !== id);
  }

  // eslint-disable-next-line class-methods-use-this
  filterStore() {
    return SpecificEventsFilterStore;
  }
}

export default alt.createStore(
  SpecificEventsListStore,
  'SpecificEventsListStore'
);
