import { Map } from 'immutable';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import AutoCompleteRetailListActions from 'shared/actions/AutoCompleteRetailListActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { RetailVendorsSource } from 'sources';

class AutoCompleteRetailListStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      listProducts: PayrollReportActions.MOUNTED,
      listSuccess: AutoCompleteRetailListActions.LIST_SUCCESS,
      listError: AutoCompleteRetailListActions.LIST_ERROR,
      updateProductSearchString:
        AutoCompleteRetailListActions.SEARCH_STRING_UPDATED,
    });
  }

  reset() {
    this.retailCategories = Map();
    this.retailCategorySearchString = '';
    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.shouldFetch = true;
    this.isLoading = false;

    this.formattedRetailCategories = Map();
  }

  listProducts() {
    if (!hasAdminPermission()) {
      return;
    }
    if (this.shouldFetch) {
      this.isLoading = true;
      this.shouldFetch = false;
      RetailVendorsSource.getRetailCategoryList({
        params: this.queryParams(),
        success: AutoCompleteRetailListActions.listSuccess,
        error: AutoCompleteRetailListActions.listError,
      });
    }
  }

  listSuccess({
    retail_categories: retailCategories,
    total_count: totalCount,
  }) {
    this.isLoading = false;
    this.totalCount = totalCount;
    this.retailCategories = retailCategories;
    this.formattedRetailCategories = this.retailCategories.map(i => ({
      text: i.name,
      value: i.id,
    }));
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing products', args);
  }

  updateProductSearchString(retailCategorySearchString) {
    if (
      !this.shouldFetch &&
      this.retailCategorySearchString === retailCategorySearchString
    ) {
      return;
    }

    this.retailCategorySearchString = retailCategorySearchString;
    this.page = 1;
    this.shouldFetch = true;
    this.listProducts();
  }

  queryParams() {
    return {
      page: this.page,
      per_page: this.perPage,
      name: this.retailCategorySearchString,
    };
  }
}

export default alt.createStore(
  AutoCompleteRetailListStore,
  'AutoCompleteRetailListStore'
);
