import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { OrderItemActions } from 'sources';

class OrderItemDataStore extends UpperHandStore {
  constructor() {
    super();

    this.orderItems = Map();

    this.bindListeners({
      listSuccess: [OrderItemActions.listSuccess],
      listError: [OrderItemActions.listError],
    });
  }

  listSuccess({ order_items: orderItems }) {
    const fetchedOrderItems = orderItems.groupBy(t => t.id).map(t => t.first());

    this.orderItems = this.orderItems.merge(fetchedOrderItems);
  }

  listError() {
    this.notifyError('error fetching orderItems', arguments);
  }
}

export default alt.createStore(OrderItemDataStore, 'OrderItemDataStore');
