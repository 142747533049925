import Moment from 'moment-timezone';
import { Map } from 'immutable';
import { extendMoment } from 'moment-range';
import {
  nativeJs,
  DayOfWeek,
  TemporalAdjusters,
  LocalDate,
} from '@js-joda/core';
import { currentCustomer } from 'shared/utils/CustomerUtils';

const moment = extendMoment(Moment);

export const MonthDayFormat = 'MMM D';
export const MonthDayYearFormat = 'MMM D, Y';
export const HoursInDay = Array.from(Array(24).keys());
export const weekdayLabels = Map({
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
});

// note: at the very least accepted_customer_terms_at counts on this returning
// a date with time information. If this ever gets refactored to use joda-js
// we need to make sure everything that uses this is really a time-less date
export function convertDateToClientValue(value) {
  if (value && value !== '') {
    let _value = value;
    if (!value._isAMomentObject) {
      _value = moment(value);
    }
    return moment(_value).toDate();
  }
  return value;
}

// JS-Joda LocalDate specific utils

export const toLocalDate = value => {
  const d = typeof value === 'string' ? moment(value) : value;
  // eslint-disable-next-line no-underscore-dangle
  if (value._isAMomentObject && !value.isValid()) {
    return null;
  }
  return d instanceof Date || moment.isMoment(d)
    ? LocalDate.from(nativeJs(d))
    : d;
};

export function convertDateToServerValue(value) {
  if (value && value !== '') {
    return toLocalDate(value)?.toString();
  }
  return null;
}

export function formatClientTime(string, format, timezone) {
  if (!string || string.length === 0) {
    return string;
  }

  const userTimezone =
    timezone || currentCustomer().tz_name || moment.tz.guess();

  return moment.tz(string, 'HH:mm:ss', userTimezone).format(format);
}

export function formatDate(value) {
  if (value) {
    return moment(value).format('MMM D, YYYY');
  }
  return null;
}

export function hmsStringFromDate(date) {
  return date.toTimeString().split(' ')[0];
}

export function dateWithTime(time, date) {
  if (!time) {
    return null;
  }
  const d = date || new Date();
  const [hour, min, sec = 0] = time.split(':').map(t => parseInt(t, 10));
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), hour, min, sec);
}

export function prettyFormat(
  date,
  options = { withTime: true, withOffset: false, withWeekDay: false }
) {
  if (!date) {
    return null;
  }
  let format = 'MMM D';
  if (options.withTime) {
    format = `${format}${options.timeSeparator || ', '}h:mma`;
  }
  if (options.withDayOfWeek) {
    format = `ddd, ${format}`;
  }
  if (options.withOffset) {
    format = `${format} ZZ`;
  }
  if (options.withTimezone) {
    format = `${format} (zz)`;
  }
  return moment
    .tz(date, currentCustomer().tz_name || moment.tz.guess())
    .format(format);
}

export function dateRange(start, end, format = MonthDayYearFormat) {
  return `${moment(start).format(format)} - ${moment(end).format(format)}`;
}

export function dateDiff(date1, date2, units = 'days') {
  return moment(date1).diff(moment(date2), units);
}

export const beginningOfWeek = localDate =>
  localDate.with(TemporalAdjusters.previousOrSame(DayOfWeek.SUNDAY));

export const endOfWeek = localDate =>
  localDate.with(TemporalAdjusters.nextOrSame(DayOfWeek.SATURDAY));

// js-joda uses ISO weekday numbering Monday: 1 to Sunday: 7.
// Schedulability (and by extension us) use Sunday: 0 to Saturday: 6
export const dayOfWeek = date => toLocalDate(date).dayOfWeek().value() % 7;

/**
 * @param {int} hour
 * @returns {Moment.range}
 */
export const timeRangeFromHour = (hour, date = moment()) => {
  const momentDate = moment.isMoment(date) ? date : moment(date);

  const forDate = time =>
    time
      .clone()
      .year(momentDate.year())
      .month(momentDate.month())
      .date(momentDate.date());

  const startTime = forDate(moment(`${hour}: 00: 00`, 'h:mm:ss'));
  const endTime = forDate(moment(`${hour}: 59: 59`, 'h:mm:ss'));

  return moment.range(startTime, endTime);
};

export const weekRangeFromDate = date => {
  const weekday = date.weekday();
  const startDate = date.clone().subtract(weekday, 'day');
  const endDate = date.clone().add(6 - weekday, 'day');

  return moment.range(startDate, endDate);
};

/**
 * @param {{start_time, end_time}} daytime
 */
export const daytimeToTimeRange = (daytime, date = moment()) => {
  const momentDate = moment.isMoment(date) ? date : moment(date);
  const start = daytime.get('start_time');
  const end = daytime.get('end_time');

  const daytimeMoment = time => {
    let result = moment(time, 'HH:mm:ss');
    result = result.isValid() ? result : moment('00:00:00', 'HH:mm:ss');

    return result
      .clone()
      .year(momentDate.year())
      .month(momentDate.month())
      .date(momentDate.date());
  };

  const startTime = daytimeMoment(start);
  // No end means infinite... use the start date as end date so we can check equality
  const endTime = end ? daytimeMoment(end) : startTime;

  return moment.range(startTime, endTime);
};

export function convertToDate(value) {
  if (value instanceof Date) {
    return value;
  }
  if (moment.isMoment(value)) {
    return moment(value).toDate();
  }
  if (value === '' || value === null || value === undefined) {
    return new Date();
  }

  return new Date(value);
}

export const customerTZ = () => currentCustomer().tz_name || 'America/New_York';

export const getCustomerMoment = value => moment.tz(value, customerTZ());
