/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from '@mui/icons-material/Email';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import Tabs from 'components/Tabs/Tabs.jsx';
import TabTeamInformation from 'team_mgmt/editing/components/_TabTeamInformation.jsx';
import TabParticipants from 'team_mgmt/editing/components/_TabParticipants.jsx';
import TabPricing from 'team_mgmt/editing/components/_TabPricing.jsx';
import TabMarketing from 'team_mgmt/editing/components/_TabMarketing.jsx';
import TabRegistrationForm from 'team_mgmt/editing/components/_TabRegistrationForm.jsx';
import { t } from 'shared/utils/LocaleUtils';

const styles = {
  tab: {
    backgroundColor: 'var(--color-bg-gray)',
    height: 50,
    textTransform: 'capitalize',
    zIndex: 'unset',
  },
  tabContent: {
    padding: '20px 30px',
  },
};

function TabsContainer(props) {
  const {
    customerTeam,
    handleEventInputChange,
    handleParticipantChange,
    fieldErrors,
    teamTypes,
    intl,
  } = props;

  const transformProps = {
    customerTeam,
    handleInputChange: handleEventInputChange,
    handleParticipantChange,
    participants: customerTeam ? customerTeam.get('team_detail') : Map(),
    fieldErrors,
    teamTypes,
  };
  const getTabs = ({ metadataOnly = false } = {}) => {
    const hasError = attribute =>
      transformProps.fieldErrors && !!transformProps.fieldErrors.get(attribute);

    const hasErrors = attributes =>
      attributes.some(attribute => hasError(attribute));
    return [
      {
        tabIndex: 0,
        tabLabel: t('.team_info', intl, __filenamespace),
        tabHashValue: 'team-information',
        tabIcon: <EventIcon />,
        tabHasErrors: hasErrors(['title', 'team_type', 'seasons']),
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabTeamInformation {...transformProps} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 1,
        tabLabel: t('.athletes', intl, __filenamespace),
        tabHashValue: 'athletes',
        tabHasErrors: hasErrors(['roster_size']),
        tabIcon: <PersonIcon />,
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabParticipants {...transformProps} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 2,
        tabLabel: t('.pricing', intl, __filenamespace),
        tabHashValue: 'pricing',
        tabIcon: <AttachMoneyIcon />,
        tabHasErrors: hasErrors(['base_price', 'payment_methods']),
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabPricing {...transformProps} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 3,
        tabLabel: t('.marketing', intl, __filenamespace),
        tabHashValue: 'marketing',
        tabIcon: <EmailIcon />,
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabMarketing {...transformProps} />
          </div>
        ),
        tabVisible: true,
      },
      {
        tabIndex: 4,
        tabLabel: t('.registration', intl, __filenamespace),
        tabHashValue: 'registration',
        tabIcon: <ContentPasteIcon />,
        tabContent: metadataOnly ? null : (
          <div style={styles.tabContent}>
            <TabRegistrationForm {...transformProps} />
          </div>
        ),
        tabVisible: true,
      },
    ];
  };

  return (
    <Tabs
      showPrevNextButtons
      defaultActiveTab={0}
      variant="fullWidth"
      tabItems={getTabs()}
    />
  );
}

export default injectIntl(TabsContainer);
