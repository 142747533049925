class EnrolledEventListActions {
  constructor() {
    this.generateActions(
      'list',
      'listSuccess',
      'listError',
      'toggleExpandEvent',
      'setExpandedEvents',
      'clearRegistration',
      'stageRegistrationToCancel',
      'cancelRegistration',
      'cancelRegistrationSuccess',
      'cancelRegistrationError',
      'setWaive',
      'setCreditOperation'
    );
  }
}

export default alt.createActions(EnrolledEventListActions);
