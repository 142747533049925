import * as React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@upperhand/playmaker';
import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { t } from 'shared/utils/LocaleUtils';

import { FilterPeriod } from 'containers/reports/types';

const styles = {
  root: {
    position: 'relative',
    width: 180,
  },
  popover: {
    padding: '16px',
    marginTop: '46px',
  },
};

class TimeRangeDropdown extends React.PureComponent {
  state = {
    anchorEl: null,
    customStartDate: moment().startOf('day'),
    customEndDate: moment().endOf('day'),
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.value,
  };

  static getDerivedStateFromProps(props, state) {
    const { value } = state;
    const { value: propValue } = props;

    if (value !== propValue) {
      return {
        value: propValue,
      };
    }
    return null;
  }

  getItems() {
    const { intl } = this.props;

    const items = [
      {
        value: FilterPeriod.today,
        label: t(`.item_${FilterPeriod.today}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.yesterday,
        label: t(`.item_${FilterPeriod.yesterday}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_week,
        label: t(`.item_${FilterPeriod.this_week}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_week,
        label: t(`.item_${FilterPeriod.last_week}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_month,
        label: t(`.item_${FilterPeriod.this_month}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_month,
        label: t(`.item_${FilterPeriod.last_month}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_quarter,
        label: t(`.item_${FilterPeriod.this_quarter}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_quarter,
        label: t(`.item_${FilterPeriod.last_quarter}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_year,
        label: t(`.item_${FilterPeriod.this_year}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_year,
        label: t(`.item_${FilterPeriod.last_year}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.custom,
        label: t(`.item_${FilterPeriod.custom}`, intl, __filenamespace),
      },
    ];
    return items;
  }

  getPeriodRange(period) {
    const { customStartDate, customEndDate } = this.state;

    switch (period) {
      case FilterPeriod.today:
        return {
          from: moment().startOf('day'),
          to: moment().endOf('day'),
        };

      case FilterPeriod.yesterday:
        return {
          from: moment().startOf('day').subtract(1, 'days'),
          to: moment().endOf('day').subtract(1, 'days'),
        };

      case FilterPeriod.this_week:
        return {
          from: moment().startOf('week'),
          to: moment().endOf('week'),
        };

      case FilterPeriod.last_week:
        return {
          from: moment().startOf('week').subtract(1, 'weeks'),
          to: moment().startOf('week').subtract(1, 'weeks').endOf('week'),
        };

      case FilterPeriod.this_month:
        return {
          from: moment().startOf('month'),
          to: moment().endOf('month'),
        };

      case FilterPeriod.last_month:
        return {
          from: moment().startOf('month').subtract(1, 'months'),
          to: moment().startOf('month').subtract(1, 'months').endOf('month'),
        };

      case FilterPeriod.this_quarter:
        return {
          from: moment().startOf('quarter'),
          to: moment().endOf('quarter'),
        };

      case FilterPeriod.last_quarter:
        return {
          from: moment().startOf('quarter').subtract(1, 'quarters'),
          to: moment()
            .startOf('quarter')
            .subtract(1, 'quarters')
            .endOf('quarter'),
        };

      case FilterPeriod.this_year:
        return {
          from: moment().startOf('year'),
          to: moment().endOf('year'),
        };

      case FilterPeriod.last_year:
        return {
          from: moment().startOf('year').subtract(1, 'years'),
          to: moment().startOf('year').subtract(1, 'years').endOf('year'),
        };

      case FilterPeriod.custom:
        return {
          from: customStartDate.startOf('day'),
          to: customEndDate.endOf('day'),
        };

      default:
        return {
          from: moment().startOf('day'),
          to: moment().endOf('day'),
        };
    }
  }

  handleChange = event => {
    const { anchorEl } = this.state;
    const { onChange } = this.props;
    const period = event.target.value || FilterPeriod.custom;
    if (period === FilterPeriod.custom && !anchorEl) {
      this.setState({
        value: FilterPeriod.custom,
        anchorEl: this.rootEl,
      });
    } else {
      onChange({
        value: period,
        ...this.getPeriodRange(period),
      });
    }
  };

  handlePopoverClose = () => {
    const { value } = this.props;
    this.setState({ value, anchorEl: null });
  };

  handleStartDateChange = value => {
    const { customEndDate } = this.state;
    if (customEndDate.isBefore(moment(value))) {
      this.setState({
        customStartDate: moment(value),
        customEndDate: moment(value),
      });
    } else {
      this.setState({
        customStartDate: moment(value),
      });
    }
  };

  handleEndDateChange = value => {
    const { customStartDate } = this.state;
    if (customStartDate.isAfter(moment(value))) {
      this.setState({
        customStartDate: moment(value),
        customEndDate: moment(value),
      });
    } else {
      this.setState({
        customEndDate: moment(value),
      });
    }
  };

  render() {
    const { label, intl } = this.props;
    const { value, anchorEl, customStartDate, customEndDate } = this.state;

    return (
      <div
        ref={ref => {
          this.rootEl = ref;
        }}
        style={styles.root}
      >
        <Dropdown
          classes={{
            notchedOutline: 'retail__time-range-dropdown',
          }}
          fullWidth
          id="dropdown-period"
          items={this.getItems()}
          name="period"
          onChange={this.handleChange}
          outline={false}
          rounded
          label={label}
          value={value}
        />
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          open={!!anchorEl}
          onClose={this.handlePopoverClose}
          PaperProps={{ sx: styles.popover }}
        >
          <Stack spacing={2}>
            <DatePicker
              value={customStartDate}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...{
                    ...params,
                    ...{
                      fullWidth: true,
                      label: t('.start_date', intl, __filenamespace),
                    },
                  }}
                />
              )}
              onChange={this.handleStartDateChange}
              DialogProps={{ disableEnforceFocus: true }}
            />
            <DatePicker
              value={customEndDate}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...{
                    ...params,
                    ...{
                      fullWidth: true,
                      label: t('.end_date', intl, __filenamespace),
                    },
                  }}
                />
              )}
              onChange={this.handleEndDateChange}
              DialogProps={{ disableEnforceFocus: true }}
            />
            <Button variant="contained" onClick={this.handleChange}>
              {t('actions.apply', intl, __filenamespace)}
            </Button>
          </Stack>
        </Popover>
      </div>
    );
  }
}

TimeRangeDropdown.defaultProps = {
  label: null,
  value: null,
};

TimeRangeDropdown.propTypes = {
  intl: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default TimeRangeDropdown;
