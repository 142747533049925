/* eslint-disable class-methods-use-this */
import axios from 'axios';
import qs from 'qs';

import {
  getActiveToken,
  getCurrentContext,
  logout,
} from 'shared/utils/AuthUtils.js';
import HttpError from './HttpError';

// Needed to make axios Get requests format correctly for API.
/* This configuration changes how axios parses url params for Get requests.
   - by manually calling the paramsSerializer we reset axios defualt configuration.
     When its reset we need to tell it to format arrays with brackets again
   - resetting also removes null/empty paramters, which is what the API expects! (dont send empty objects)
   - Manual configure date serilization toString is needed. By default it will json parse
     which will set to iso 8601 this causes issues with timezones in places like the calander quick schedule

   reference docs here ->  https://github.com/axios/axios/issues/1548
*/
axios.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.paramsSerializer = params =>
    qs.stringify(params, {
      serializeDate: date => date.toString(),
      arrayFormat: 'brackets',
    });
  return config;
});

const backendAuthErrors = [
  'The token is invalid.',
  'The token has expired.',
  'The token does not have a valid issuer.',
  'The token iat has not been reached.',
  'The token nbf has not been reached.',
  'Invalid or missing token.',
  'Your session has expired. Please refresh the page.',
  'Missing required header: `X-Customer-Id`',
  'The action cannot be completed because the supplied customer is inactive.',
];

class ApiClient {
  // TODO:keep track of all our requests, this will be useful for caching in the future.
  // requestHistory = new Map();

  headers(extraHeaders) {
    // un-contexted endpoints can pass X-Customer_Id of null or not inclue the header at all.
    const headers = {
      'X-Customer-Id': getCurrentContext().customerId,
    };

    const jwt = getActiveToken();

    if (jwt) {
      headers['X-Jwt-Token'] = `${jwt}`;
    }

    return { headers: { ...headers, ...extraHeaders } };
  }

  requestUrl(endpoint) {
    return `${window.api_host}/api/${endpoint}`;
  }

  // TODO: rework this its from legacy.
  // Should not be doing string compares. API should responde with a "SPECIFIC" error code for this
  shouldLogout(error) {
    return (
      backendAuthErrors.includes(error.httpMessage) ||
      backendAuthErrors.includes(error.message)
    );
  }

  errorHandler(error) {
    if (error.message === 'Network Error') {
      // eslint-disable-next-line no-param-reassign
      error.response = { status: 1000 };
    }

    // Convert to custom HttpError object, bubble the error up, so stores can manually handle errors or pass to UpperHandStore.
    const httpError = HttpError.fromError(error);

    if (this.shouldLogout(httpError)) {
      logout();
      return;
    }

    throw httpError;
  }

  // Public methods

  async asyncGet(url, params, headers) {
    return axios
      .get(this.requestUrl(url), {
        params,
        ...this.headers(headers),
      })
      .catch(error => this.errorHandler(error));
  }

  async asyncPatch(url, obj, headers) {
    return axios
      .patch(this.requestUrl(url), obj, this.headers(headers))
      .catch(error => this.errorHandler(error));
  }

  async asyncPut(url, obj, headers) {
    return axios
      .put(this.requestUrl(url), obj, this.headers(headers))
      .catch(error => this.errorHandler(error));
  }

  async asyncPost(url, obj, headers) {
    return axios
      .post(this.requestUrl(url), obj, this.headers(headers))
      .catch(error => this.errorHandler(error));
  }

  async asyncDelete(url, headers, obj = {}) {
    const stringHeaders = this.headers(headers).headers;
    return axios
      .delete(this.requestUrl(url), {
        data: obj,
        headers: stringHeaders,
      })
      .catch(error => this.errorHandler(error));
  }

  /** To support Legacy Calls, to support non async await. */
  responseToAltResponse = successCallback => response => {
    if (typeof successCallback === 'function' && successCallback.defer) {
      successCallback.defer(response.data);
    } else if (typeof successCallback === 'function') {
      // set timeout so that function callbacks happen outside of the event loop
      setTimeout(() => successCallback(response.data), 1);
    } else if (successCallback.action) {
      successCallback.action.defer([response.data, ...successCallback.args]);
    }
  };

  /**
   * @deprecated For Legacy Support only, use asyncGet when possible,
   *  be aware asyncGet parses URL params diffrently
   */
  get({ success, error, url, data, headers }) {
    this.asyncGet(url, data, headers).then(
      this.responseToAltResponse(success),
      error
    );
  }

  /**
   * @deprecated For Legacy Support only, use asyncPatch when possible,
   */
  patch({ success, error, url, data, headers }) {
    this.asyncPatch(url, data ? JSON.parse(data) : data, headers).then(
      this.responseToAltResponse(success),
      error
    );
  }

  /**
   * @deprecated For Legacy Support only, use asyncPut when possible,
   */
  put({ success, error, url, data, headers }) {
    this.asyncPut(url, data ? JSON.parse(data) : data, headers).then(
      this.responseToAltResponse(success),
      error
    );
  }

  /**
   * @deprecated For Legacy Support only, use asyncPost when possible,
   */
  post({ success, error, url, data, headers }) {
    this.asyncPost(url, data ? JSON.parse(data) : data, headers).then(
      this.responseToAltResponse(success),
      error
    );
  }

  /**
   * @deprecated For Legacy Support only, use asyncDelete when possible,
   */
  delete({ success, error, url, data, headers }) {
    this.asyncDelete(url, headers, data ? JSON.parse(data) : {}).then(
      this.responseToAltResponse(success),
      error
    );
  }
}

const uhApiClient = new ApiClient();

export default uhApiClient;
