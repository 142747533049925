import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import VerticalDivider from 'shared/components/VerticalDivider.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import uhTheme from '_uh_theme.jsx';

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: 'white',
    height: 56,
    paddingRight: 20,
  },
};

const DEFAULT_ORDERABLE_TYPE = 'variant';

function CartText({ cart }) {
  if (cart && cart.order_items.size > 0) {
    // using toSet() to remove duplicates
    const orderableTypes = cart.order_items
      .map(oi => oi.orderable_type)
      .toSet();
    const name =
      orderableTypes.size > 1 ? DEFAULT_ORDERABLE_TYPE : orderableTypes.first();

    return (
      <div id="cart-bar">
        <FormattedMessage
          id={messageId(`.n_${name}`, __filenamespace)}
          values={{ n: cart.order_items.size }}
        />
        <VerticalDivider />
        <FormattedCurrency value={cart.total} fromCents />
      </div>
    );
  }
  return (
    <div style={{ color: uhColors.iconLightGrey }}>
      <FormattedMessage
        id={messageId('.empty_cart_message', __filenamespace)}
      />
    </div>
  );
}

function CartBar({ cart, active, onCartButtonClick }) {
  return (
    <FlexBoxJustify style={styles.root}>
      <IconButton onClick={onCartButtonClick}>
        <ShoppingCartIcon
          sx={{
            color: active ? uhTheme.palette.activeBlue : uhColors.tableGrey,
          }}
        />
      </IconButton>
      <CartText cart={cart} />
    </FlexBoxJustify>
  );
}

export default CartBar;
