import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';

export const ToolTipInfo = styled(InfoIcon)`
  color: var(--color-secondary);
`;

export const ToolTipList = styled.ul`
  list-style: disc;
  padding-left: 16px;
`;

export const ToolTipListItem = styled.li`
  margin: 8px;
`;

export const ToolTipText = styled.p`
  margin: 8px;
`;

const ToolTipBody = styled.div`
  width: 400px;
  visibility: hidden;
  background-color: rgba(112, 112, 112, 1);
  color: white;
  border-radius: 3px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  left: 50%;
  margin-left: -60px;
`;

const ToolTipContainer = styled.div`
  padding-top: 24px;
  position: relative;
  display: inline-block;

  &:hover {

    ${ToolTipBody} {
      visibility: visible;
    }
`;

function ToolTip({ Icon = ToolTipInfo, children }) {
  return (
    <ToolTipContainer>
      <Icon style={{ color: 'var(--color-secondary)' }} />
      <ToolTipBody>{children}</ToolTipBody>
    </ToolTipContainer>
  );
}

export default ToolTip;
