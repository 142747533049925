import React from 'react';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  subDivButton: {
    justifyContent: 'center',
    height: '50px',
    display: 'flex',
    background: uhColors.primaryButtonColor,
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 'auto',
    borderRadius: '5px',
    marginTop: '20px',
    marginTop: 'auto',
    color: uhColors.textWhite,
    border: `1px solid ${uhColors.primaryButtonColor}`,
  },
};
const BookingButton = ({ title, handleClicked, disable }) => {
  return (
    <button
      disabled={disable}
      onClick={() => handleClicked()}
      style={
        disable
          ? merge(styles.subDivButton, {
              background: uhColors.disableBlue,
              border: `1px solid ${uhColors.disableBlue}`,
              cursor: 'not-allowed',
            })
          : styles.subDivButton
      }
    >
      {title}
    </button>
  );
};

export default BookingButton;
