import { List, Map } from 'immutable';
import OptionValue from 'shared/records/OptionValue.js';
import Product from 'shared/records/Product.js';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import RetailEditingActions from 'retail/actions/RetailEditingActions.js';
import StoreActions from 'shared/actions/StoreActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { RetailVendorsActions } from 'sources';

class RetailEditingStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      reset: [
        StoreActions.PREPARE_FOR_REUSE,
        RetailEditingActions.PRODUCT_RESET,
      ],
      handleUpdateStore: RetailEditingActions.UPDATE_STORE,

      handleFetch: RetailEditingActions.FETCH,
      setProduct: RetailEditingActions.PRODUCT_FETCH_SUCCESS,
      productFetchError: RetailEditingActions.PRODUCT_FETCH_ERROR,

      addOptionType: RetailEditingActions.NEW_OPTION_TYPE,
      removeOptionType: RetailEditingActions.OPTION_TYPE_REMOVED,
      updateOptionTypeName: RetailEditingActions.OPTION_TYPE_NAME_UPDATED,

      updateOptionValueValue: RetailEditingActions.OPTION_VALUE_VALUE_UPDATED,
      stageOptionValue: RetailEditingActions.OPTION_VALUE_STAGED,
      addOrUpdateOptionValue: RetailEditingActions.OPTION_VALUE_SAVED,
      removeOptionValue: RetailEditingActions.OPTION_VALUE_REMOVED,

      handleRetailVendorSelected: RetailEditingActions.RETAIL_VENDOR_SELECTED,
      handleVendorCreated: RetailVendorsActions.CREATE_SUCCESS,

      handleRetailCategorySelected:
        RetailEditingActions.RETAIL_CATEGORY_SELECTED,

      handleCreateOrUpdate: RetailEditingActions.CREATE_OR_UPDATE,
      handleSaveSuccess: RetailEditingActions.SAVE_OR_UPDATE_SUCCESS,
      handleError: RetailEditingActions.ERROR,
      handleValidateAndSave: RetailEditingActions.VALIDATE_AND_SAVE,

      handlePublish: RetailEditingActions.PUBLISH,
      handlePublishError: RetailEditingActions.PUBLISH_ERROR,
    });
  }

  reset() {
    this.product = new Product();
    this.stagedOptionValues = List();
    this.optionTypesDirty = false;
  }

  addOptionType() {
    this.product = this.product.addOptionType();
    this.optionTypesDirty = true;

    this.stagedOptionValues = this.stagedOptionValues.push(
      Map({
        index: null,
        optionValue: new OptionValue(),
      })
    );
  }

  removeOptionType(index) {
    this.product = this.product.removeOptionType(index);
    this.optionTypesDirty = true;
  }

  updateOptionTypeName([index, name]) {
    this.product = this.product.setIn(['option_types', index, 'name'], name);
  }

  updateOptionValueValue([typeIndex, value]) {
    this.stagedOptionValues = this.stagedOptionValues.setIn(
      [typeIndex, 'optionValue', 'value'],
      value
    );
  }

  stageOptionValue([typeIndex, valueIndex]) {
    this.stagedOptionValues = this.stagedOptionValues.set(
      typeIndex,
      Map({
        index: valueIndex,
        optionValue: this.product.getIn([
          'option_types',
          typeIndex,
          'option_values',
          valueIndex,
        ]),
      })
    );
  }

  addOrUpdateOptionValue(typeIndex) {
    const stagedValue = this.stagedOptionValues.get(typeIndex);

    if (stagedValue.get('index') === null) {
      this.product = this.product.updateIn(['option_types', typeIndex], type =>
        type.addOptionValue(stagedValue.get('optionValue'))
      );
    } else {
      this.product = this.product.setIn(
        ['option_types', typeIndex, 'option_values', stagedValue.get('index')],
        stagedValue.get('optionValue')
      );
    }

    this.stagedOptionValues = this.stagedOptionValues.set(
      typeIndex,
      Map({
        index: null,
        optionValue: new OptionValue(),
      })
    );
  }

  removeOptionValue(typeIndex) {
    const stagedValue = this.stagedOptionValues.get(typeIndex);

    this.product = this.product.updateIn(['option_types', typeIndex], type =>
      type.removeOptionValue(stagedValue.get('index'))
    );

    this.stagedOptionValues = this.stagedOptionValues.set(
      typeIndex,
      Map({
        index: null,
        optionValue: new OptionValue(),
      })
    );

    this.optionTypesDirty = true;
  }

  handleUpdateStore(state) {
    this.product = this.product.merge(state);
  }

  handleRetailVendorSelected(retailVendorId) {
    this.product = this.product.set('retail_vendor_id', retailVendorId);
  }

  handleVendorCreated({ id }) {
    this.handleRetailVendorSelected(id);
  }

  handleRetailCategorySelected(retailCategoryId) {
    const retailCategory =
      RetailCategoryListingStore.getState().findById(retailCategoryId);
    this.product = this.product.set('retail_category', retailCategory);
  }

  handleSaveSuccess(result) {
    if (!this.product.id) {
      window.history.pushState(
        null,
        document.title,
        window.location.pathname.replace('/new', `/${result.id}/edit`)
      );
    }

    this.setProduct(result);
  }

  handleCreateOrUpdate() {
    return this.product.id
      ? uhApiClient.put(this.apiArgs())
      : uhApiClient.post(this.apiArgs());
  }

  handleValidateAndSave() {
    this.product = this.product.validate();

    if (this.product.isValid()) {
      this.handleCreateOrUpdate();
    }
  }

  handlePublish() {
    if (this.product.published) {
      return this.handleValidateAndSave();
    }

    this.product = this.product.validate();

    if (this.product.isValid()) {
      this.product = this.product.set('published', true);

      // add error handler for this special case
      const apiArgs = {
        ...this.apiArgs(),
        error: RetailEditingActions.PUBLISH_ERROR,
      };

      return this.product.id
        ? uhApiClient.put(apiArgs)
        : uhApiClient.post(apiArgs);
    }

    return null;
  }

  handlePublishError() {
    // reset product publish status since publishing failed
    this.product = this.product.set('published', false);
  }

  handleFetch(id) {
    this.loadProduct(id);
  }

  loadProduct(id) {
    uhApiClient.get({
      url: this.url(id),
      data: { fields: ['option_types', 'retail_category'] },
      success: RetailEditingActions.productFetchSuccess,
      error: RetailEditingActions.productFetchError,
    });
  }

  setProduct(data) {
    this.product = new Product(data);

    this.stagedOptionValues = this.product.option_types.map(() =>
      Map({
        index: null,
        optionValue: new OptionValue(),
      })
    );

    this.optionTypesDirty = false;
  }

  productFetchError(...args) {
    this.notifyError('error fetching product', args);
  }

  apiArgs() {
    return {
      url: this.url(),
      data: JSON.stringify({
        attributes: this.product.toServer(),
        fields: ['option_types', 'retail_category'],
      }),
      success: RetailEditingActions.saveOrUpdateSuccess,
      error: RetailEditingActions.error,
    };
  }

  url(id = this.product.id) {
    return id ? `products/${id}` : 'products';
  }
}

export default alt.createStore(RetailEditingStore, 'RetailEditingStore');
