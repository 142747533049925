import * as React from 'react';

import ResourceIcon from 'shared/components/icons/Resource.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  AssignedResources: {
    padding: '4px 0',
  },
  ResourceIcon: {
    width: '20px',
    height: '20px',
    margin: '0 8px 0 0',
  },
  Count: {
    fontWeight: '600',
    paddingRight: 2,
  },
};

function AssignedResources({ resources, intl }) {
  let text = '';
  const resourcesText = t('.resources', intl, __filenamespace);

  switch (resources.size) {
    case 1:
      text = resources.first() ? resources.first().name : text;
      break;
    default:
      text = (
        <span>
          <span style={styles.Count}>{resources.size}</span>
          {resourcesText}
        </span>
      );
      break;
  }

  return resources.size > 0 ? (
    <FlexBoxCenter style={styles.AssignedResources}>
      <ResourceIcon style={styles.ResourceIcon} color={uhColors.iconGrey} />
      <div>{text}</div>
    </FlexBoxCenter>
  ) : null;
}

export default AssignedResources;
