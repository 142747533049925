import React from 'react';
import Button from '@mui/material/Button';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventHeader from 'event_mgmt/shared/components/_EventHeader.jsx';
import { Link as ReactRouterLink } from 'react-router-dom';

const styles = {
  buttonStyle: {
    marginLeft: 8,
    marginRight: 8,
  },
};

class Header extends React.Component {
  shouldComponentUpdate(nextProps) {
    const {
      isLoadingEvent,
      isSavingEvent,
      savedState,
      editing,
      customerEvent,
      memberships,
    } = this.props;

    return (
      isLoadingEvent !== nextProps.isLoadingEvent ||
      isSavingEvent !== nextProps.isSavingEvent ||
      savedState !== nextProps.savedState ||
      editing !== nextProps.editing ||
      customerEvent.status !== nextProps.customerEvent.status ||
      customerEvent.title !== nextProps.customerEvent.title ||
      customerEvent.event_type !== nextProps.customerEvent.event_type ||
      customerEvent.schedules !== nextProps.customerEvent.schedules ||
      customerEvent.id !== nextProps.customerEvent.id ||
      customerEvent.path !== nextProps.customerEvent.path ||
      memberships !== nextProps.memberships
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  eventTypeName() {
    const { customerEvent } = this.props;

    if (customerEvent.get('event_type')) {
      return customerEvent.get('event_type').name;
    }
    return '';
  }

  isActive() {
    const { customerEvent } = this.props;

    return customerEvent.status === 'active';
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  hasStatus() {
    const { customerEvent } = this.props;

    return !!customerEvent.status;
  }

  render() {
    const {
      customerEvent,
      memberships,
      isLoadingEvent,
      isSavingEvent,
      savedState,
    } = this.props;
    const actions = [
      customerEvent.id && (
        <ReactRouterLink
          to={customerEvent.path}
          style={{ textDecoration: 'none', display: 'flex' }}
        >
          <Button
            variant="contained"
            disabled={isLoadingEvent || isSavingEvent}
            color="default"
            style={styles.buttonStyle}
          >
            Preview
          </Button>
        </ReactRouterLink>
      ),
      <Button
        disabled={isLoadingEvent || isSavingEvent}
        onClick={() => EventActions.createOrUpdateServer()}
        color="default"
        variant="contained"
        style={styles.buttonStyle}
      >
        {savedState}
      </Button>,
      <Button
        disabled={isLoadingEvent || isSavingEvent}
        onClick={() => EventActions.postEvent()}
        color="secondary"
        variant="contained"
        style={styles.buttonStyle}
      >
        {this.isActive() ? 'posted' : 'post'}
      </Button>,
    ];

    return (
      <EventHeader
        actions={actions}
        event={customerEvent}
        memberships={memberships}
      />
    );
  }
}

export default Header;
