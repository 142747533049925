import { List, Record, fromJS } from 'immutable';
import Adjustment from 'shared/records/Adjustment';
import CreditPassPackage from 'shared/records/CreditPassPackage.js';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import RegistrationPackage from 'shared/records/RegistrationPackage';
import MembershipSubscriptionPackage from 'shared/records/MembershipSubscriptionPackage.jsx';
import Variant from 'shared/records/Variant.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const ORDERABLE_CONSTRUCTORS = {
  registration_package: (attributes, options) =>
    new RegistrationPackage(attributes, options),
  credit_pass_package: (attributes, options) =>
    new CreditPassPackage(attributes, options),
  membership_subscription_package: (attributes, options) =>
    new MembershipSubscriptionPackage(attributes, options),
  variant: (attributes, _options) => new Variant(attributes),
};

class OrderItem extends Record({
  id: null,
  applied_adjustments: List(),
  adjusted_payments: null,
  buyer_id: null,
  buyer_name: null,
  display_quantity: 1,
  errors: new FieldErrors(),
  event_id: null,
  fee: null,
  order_id: '',
  addon_order_item_id: '',
  order_status: '',
  orderable: Record(),
  orderable_id: null,
  orderable_type: '',
  ordered_at: null,
  payment_method: null,
  payment_status: null,
  primary_display_text: '',
  quantity: 1,
  manual_discount: null,
  secondary_display_accent_text: '',
  secondary_display_text: '',
  cash_credit_destination_id: null,
  account_credit_amount: null,
  show_path: '#',
  subtotal: 0,
  tax: 0,
  total: 0,
}) {
  constructor(obj = {}, options = {}) {
    super(
      merge(obj, {
        orderable: ORDERABLE_CONSTRUCTORS[obj.orderable_type](
          obj.orderable,
          options
        ),
        applied_adjustments: (obj.applied_adjustments || []).map(
          a => new Adjustment(a)
        ),
        adjusted_payments: fromJS(obj.adjusted_payments),
      })
    );
  }

  isMembershipItem() {
    return this.orderable_type === 'membership_subscription_package';
  }

  isCreditPassItem() {
    return this.orderable_type === 'credit_pass_package';
  }

  isRecurringMembershipItem() {
    return (
      this.isMembershipItem() &&
      this.orderable.subscribable_interval_months !== 0
    );
  }

  isEventItem() {
    return this.orderable_type === 'registration_package';
  }

  isRetailItem() {
    return this.orderable_type === 'variant';
  }

  isOpenBookingItem() {
    return this.orderable.schedule_type === 'open_booking';
  }

  isFixedScheduleItem() {
    return this.orderable.schedule_type === 'fixed_schedule';
  }

  isTeamItem() {
    return this.orderable.schedule_type === 'team_schedule';
  }

  editPath() {
    return `${this.show_path}?order_item_id=${this.id}#pdo`;
  }

  totalBeforeManualDiscount() {
    const adjustment = this.get('applied_adjustments').find(a => a.isManual());
    return adjustment ? this.total - adjustment.get('amount') : this.total;
  }

  validate() {
    let errors = this.errors.clear();
    if (this.manual_discount) {
      if (
        this.manual_discount.type === 'percent' &&
        (parseFloat(this.manual_discount.value, 10) > 100 ||
          parseFloat(this.manual_discount.value, 10) < 0)
      ) {
        errors = errors.add(
          'discount_value',
          'records.errors.cant_be_more_than_100'
        );
      }
      if (this.manual_discount.type === 'amount') {
        const maxAmount =
          this.orderable_type === 'membership_subscription_package'
            ? this.orderable.subscribable_join_fee
            : this.total;

        if (this.manual_discount.value > maxAmount) {
          errors = errors.add(
            'discount_value',
            'records.errors.cant_be_more_than_item_price'
          );
        }
      }
    }
    return this.set('errors', errors);
  }

  toServer() {
    const serverValue = this.toJS();

    if (this.orderable_type === 'registration_package') {
      serverValue.registration_package_attributes = this.orderable.toServer();
      delete serverValue.orderable_id;
    } else if (this.orderable_type === 'membership_subscription_package') {
      serverValue.membership_subscription_package_attributes =
        this.orderable.toServer();
      delete serverValue.orderable_id;
    } else if (this.orderable_type === 'credit_pass_package') {
      serverValue.credit_pass_package_attributes = this.orderable.toJS();
      delete serverValue.orderable_id;
    }

    delete serverValue.orderable;
    delete serverValue.event_id;

    return serverValue;
  }

  get refundLabel() {
    return this.payment_method === 'pay_later' ? '.waive' : '.refund';
  }
}

export default OrderItem;
