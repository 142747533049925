import { List } from 'immutable';
import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';
import DataStoreActions from './AvailabilityScheduleActions';

const parserTo = {
  type: AvailabilitySchedule,
  paginationKey: 'availability_schedules',
};

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `customer_users/${id}/availability_schedule`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'customer_users/availability_schedules',
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error,
  });
};

export const update = ({ staffId, availabilitySchedule, success, error }) => {
  uhApiClient.put({
    url: `customer_users/${staffId}/availability_schedule`,
    data: JSON.stringify({
      attributes: availabilitySchedule.toServer(),
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const create = ({ staffId, availabilitySchedule, success, error }) => {
  uhApiClient.post({
    url: `customer_users/${staffId}/availability_schedule`,
    data: JSON.stringify({
      attributes: availabilitySchedule.toServer(),
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};
