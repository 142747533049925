import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  Expander,
  Spinner,
  Button,
} from '@upperhand/playmaker';
import { FormattedMessage, injectIntl } from 'react-intl';

import altContainer from 'shared/hocs/altContainer.jsx';
import Circle from 'shared/components/Circle.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { formatDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import { EventDataStore, RegistrationPackageDataStore } from 'dataStores';

import CreditListDrawerActions from 'containers/creditListDrawer/Actions';

function RegistrationPackageCard({
  intl,
  credit,
  packagesLoading,
  clientId,
  eventDataStore: { events },
  registrationPackageDataStore: { registrationPackages },
}) {
  if (!credit) return null;

  const isStaff = currentUser().isStaff();
  const eventId = credit.get('event_id', null);
  const event = events.get(eventId, null);
  const eventTypeColor = event.getIn(['event_type', 'color'], null);
  const eventTitle = event.get('title', '');
  const eventStaffPath = event.get('admin_path', '');
  const eventClientPath = event.get('path', '');
  const creditsRemaining = credit.get('credits_remaining', 0);
  const packages = registrationPackages.getIn([clientId, eventId], Map());
  const isLoading = packagesLoading.get(eventId);

  const handleExpand = expanded => {
    if (expanded && creditsRemaining) {
      CreditListDrawerActions.listRegistrationPackages({ eventId });
    }
  };

  const handleEdit = item => {
    CreditListDrawerActions.togglePackageAdjustmentModal({
      packageId: item.id,
      eventId,
    });
  };

  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      className="credit-grant-card"
    >
      <Grid item container xs={12} alignItems="center">
        <Expander
          arrowRight
          contentOffset={false}
          classes={{
            expanderRoot: 'credit-grant-card__root-expander',
            buttonContainer: 'credit-grant-card__expand-btn-container',
          }}
          header={
            <div className="credit-grant-card-item__title">
              <Link
                className="credit-grant-card-item__link"
                style={{ color: eventTypeColor }}
                to={isStaff ? eventStaffPath : eventClientPath}
              >
                <Circle
                  diameter={10}
                  color={eventTypeColor}
                  style={{ marginBottom: 2 }}
                />
                <Typography
                  className="credit-grant-card-item__event-title"
                  variant="subtitle1"
                >
                  {eventTitle}
                </Typography>
              </Link>
              <Typography
                variant="subtitle2"
                className="credit-grant-card-item__credits"
              >
                <FormattedMessage
                  id={messageId('.credits', __filenamespace)}
                  values={{
                    n: creditsRemaining,
                  }}
                />
              </Typography>
            </div>
          }
          onChange={handleExpand}
        >
          <Grid container className="credit-grant-card-item__packages">
            {isLoading && (
              <Grid container xs={12} alignItems="center" justify="center">
                <Spinner />
              </Grid>
            )}
            {!isLoading && packages.size === 0 && (
              <Grid container xs={12} alignItems="center" justify="center">
                <Typography variant="subtitle2">
                  {t('.no_packages', intl, __filenamespace)}
                </Typography>
              </Grid>
            )}
            {!isLoading &&
              packages.toArray().map(item => (
                <Grid container xs={12} key={item.id}>
                  <Grid item xs={5}>
                    <Typography variant="subtitle2">
                      <FormattedMessage
                        id={messageId('.credits', __filenamespace)}
                        values={{
                          n: item.get('quantity_remaining', 0),
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {formatDate(item.ordered_at)}
                  </Grid>
                  <Grid item container xs={2} justify="flex-end">
                    {isStaff && (
                      <Button
                        classes={{
                          root: 'credit-grant-card-item__edit-btn',
                          label: 'credit-grant-card-item__edit-label',
                        }}
                        size="1x"
                        type="tertiary"
                        onClick={() => handleEdit(item)}
                      >
                        {t('.edit', intl, __filenamespace)}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Expander>
      </Grid>
    </Grid>
  );
}

RegistrationPackageCard.propTypes = {
  intl: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  credit: PropTypes.object.isRequired,
  eventDataStore: PropTypes.object.isRequired,
  registrationPackageDataStore: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      eventDataStore: EventDataStore,
      registrationPackageDataStore: RegistrationPackageDataStore,
    },
  })
)(RegistrationPackageCard);
