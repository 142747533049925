export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'Upper Hand',
  mainInstance: true,
  logo: {
    url: 'https://upperhand-app.s3.amazonaws.com/static/images/Upper+Hand+Dark+Logo.svg',
    width: 222,
  },
  sidebar: () => {},
};
