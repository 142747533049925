import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { StaffDataStore } from 'dataStores';

function StaffCard({ staffId, staffDataStore: { staff: staffMap } }) {
  const staff = staffMap.get(staffId);

  if (!staff) {
    return null;
  }

  return (
    <Card contentDirection="row">
      <Grid container alignItems="center" xs={12}>
        <Grid container item alignItems="center" xs={12} md={4}>
          <UserAvatar user={staff} />
          {staff.name()}
        </Grid>
        <Grid item xs={12} md={3}>
          {staff.phone}
        </Grid>
        <Grid item xs={12} md={5} className="staff-email">
          {staff.email}
        </Grid>
      </Grid>
    </Card>
  );
}

StaffCard.propTypes = {
  staffId: PropTypes.number,
  staffDataStore: PropTypes.object,
};

StaffCard.defaultProps = {
  staffId: null,
  staffDataStore: {},
};

export default altContainer({
  stores: {
    staffDataStore: StaffDataStore,
  },
})(StaffCard);
