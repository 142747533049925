import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Map } from 'immutable';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import CartIndicator from 'shared/components/CartIndicator.jsx';
import EventPrice from 'shared/components/EventPrice.jsx';
import FixedScheduleProfileList from 'shared/components/registration/FixedScheduleProfileList.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Header from 'point_of_sale/components/Header.jsx';
import OpenBookingScheduling from 'shared/components/OpenBookingScheduling.jsx';
import PaymentPlanSelector from 'shared/components/registration/PaymentPlanSelector.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import NoClientCredits from 'point_of_sale/components/_NoClientCredits.jsx';
import POSPurchasedRegistrationActions from 'point_of_sale/actions/POSPurchasedRegistrationActions.jsx';
import POSSingleSessionContentActions from 'point_of_sale/actions/POSSingleSessionContentActions.js';
import ProductInformation from 'shared/components/ProductInformation.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import ScheduleButtons from 'shared/components/registration/ScheduleButtons.jsx';
import Scheduler from 'shared/components/Scheduler.jsx';
import SessionScheduling from 'containers/sessionScheduling/SessionScheduling.jsx';
import SingleSessionPurchasableContent from 'shared/components/checkout/SingleSessionPurchasableContent.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

const styles = {
  addToCartButton: {
    height: 50,
    marginBottom: 10,
  },

  cancelButton: {
    height: 50,
  },

  cancelButtonLabel: {
    fontSize: 16,
    color: uhColors.activeBlue,
  },

  sessionButton: {
    height: 50,
    marginBottom: 20,
  },

  contentContainer: isClassSchedule => ({
    padding: 20,
    height: '100%',
    overflow: isClassSchedule ? 'hidden' : 'initial',
  }),

  profileCard: {
    padding: 10,
    marginBottom: 10,
  },

  totalContainer: {
    marginTop: 20,
    marginBottom: 25,
  },

  scheduleLabel: {
    alignItems: 'center',
    width: '100%',
    fontSize: 15,
  },

  scheduledRegistrationActionButton: {
    height: 50,
    marginBottom: 10,
  },

  spinnerContainer: isClassSchedule => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: isClassSchedule ? 'hidden' : 'initial',
  }),
};

const completeClicked = () => {
  POSPurchasedRegistrationActions.completeButtonClicked();
};

const handleBackButtonClicked = () => {
  POSPurchasedRegistrationActions.backButtonClicked();
};

const handleScheduleRemainingClicked = () => {
  POSSchedulingActions.scheduleRemainingClicked();
};

const eventSchedulingCancelClicked = () =>
  POSEventActions.eventSchedulingCancelClicked();

const classScheduleBookSuccess = () =>
  POSSchedulingActions.classScheduleBookSuccess();

function ScheduleButtonLabel({ posSchedulingStore }) {
  return (
    <FlexBoxJustify style={styles.scheduleLabel}>
      <FormattedMessage
        id={messageId('.unscheduled_session', __filenamespace)}
      />
      {posSchedulingStore.scheduleableRegistrations.count(
        r => !r.isScheduled()
      )}
    </FlexBoxJustify>
  );
}

function CreditNotice({ expDate, clientName }) {
  return (
    <div>
      {expDate && expDate.isSameOrBefore(moment()) ? (
        <FormattedMessage
          id={messageId('.credit_expired', __filenamespace)}
          values={{ expDate: expDate.format('LLL'), clientName }}
        />
      ) : (
        <FormattedMessage
          id={messageId('.credit_refunded', __filenamespace)}
          values={{ clientName }}
        />
      )}
    </div>
  );
}

function EventSchedulingClasses({
  clientCredits,
  posEventStore,
  posSchedulingStore,
}) {
  const hasCredits = posSchedulingStore.allProfiles.some(c => {
    const hasAccess = c.hasAccessTo(posEventStore.event);

    if (!hasAccess) return false;

    const remainingCredits = clientCredits.getIn([
      posEventStore.event.id,
      c.id,
      'credits_remaining',
    ]);
    const unlimitedCredits = clientCredits.getIn([
      posEventStore.event.id,
      c.id,
      'unlimited',
    ]);

    return unlimitedCredits || (!!remainingCredits && remainingCredits > 0);
  });

  return (
    <div className="class-scheduling">
      {!posSchedulingStore.selectedClientId &&
        (hasCredits ? (
          <ScheduleButtons
            clientCredits={clientCredits.get(posEventStore.event.id, Map())}
            event={posEventStore.event}
            registrations={[]}
            profiles={posSchedulingStore.allProfiles}
            onClientSelect={client =>
              POSSchedulingActions.classScheduleClicked(client)
            }
            style={{ marginBottom: 20 }}
          />
        ) : (
          <NoClientCredits />
        ))}

      {posSchedulingStore.selectedClientId && (
        <SessionScheduling
          clientId={posSchedulingStore.selectedClientId}
          eventId={posEventStore.event.id}
          allowPast={posEventStore.event.isClassSchedule()}
          onNext={() => {
            classScheduleBookSuccess();
          }}
          onBack={() => {
            eventSchedulingCancelClicked();
          }}
        />
      )}
    </div>
  );
}

function EventSchedulingOpenBooking({
  clientCredits,
  eventDiscounts,
  posStore,
  posCartStore,
  posEventStore,
  posEventStaffStore,
  posSchedulingStore,
  posSchedulingAvailableTimesStore,
}) {
  const {
    clientPurchasedRegistrations,
    purchasedRegistrationSelectedClient: client,
    purchasedRegistrationSelectedClientId: selectedClientId,
  } = posSchedulingStore;
  const [customerUserId, setCustomerUserId] = React.useState(null);

  return (
    <div>
      {/* TODO: It seems that this button is never shown. Just updated button component and leave it here because maybe it will be needed in the future */}
      {false && (
        <Button
          fullWidth
          variant="contained"
          style={styles.sessionButton}
          onClick={handleScheduleRemainingClicked}
        >
          <ScheduleButtonLabel posSchedulingStore={posSchedulingStore} />
        </Button>
      )}

      {!posSchedulingStore.selectedClientId && (
        <ScheduleButtons
          clientCredits={clientCredits.get(posEventStore.event.id, Map())}
          event={posEventStore.event}
          registrations={posSchedulingStore.scheduleableRegistrations}
          profiles={posSchedulingStore.allProfiles}
          onClientSelect={c => POSSchedulingActions.scheduleRemainingClicked(c)}
          style={{ marginBottom: 20 }}
        />
      )}
      <OpenBookingScheduling
        allowProfileClear
        athletes={posSchedulingStore.availableProfiles}
        availableTimesStore={posSchedulingAvailableTimesStore}
        clientEventCredits={clientCredits.get(posEventStore.event.id, Map())}
        membershipEventDiscounts={eventDiscounts}
        eventStore={posEventStore}
        managingUserId={posStore.selectedClient.user_id}
        onContinueToCart={(registrationPackage, orderItemId) => {
          if (orderItemId == null) {
            POSSchedulingActions.addToCartClick(registrationPackage, {
              redirect: true,
            });
          } else {
            POSSchedulingActions.updateCartClick(orderItemId, {
              orderable: registrationPackage,
            });
          }
        }}
        isUpdatingCart={posCartStore.isUpdatingCart}
        isNavigatingToCart={false}
        schedulingStore={posSchedulingStore}
        schedulingActions={POSSchedulingActions}
        staff={posEventStaffStore.staff}
        customerUserId={customerUserId}
        setCustomerUserId={setCustomerUserId}
        clientCredits={clientCredits}
        completeClicked={completeClicked}
        onRegistrationBooked={
          POSPurchasedRegistrationActions.registrationBooked
        }
        selectedAthleteId={client?.id || selectedClientId}
        purchasedRegistrations={clientPurchasedRegistrations}
      />
    </div>
  );
}

const handlePlanSelect = uuid => {
  POSSchedulingActions.paymentPlanSelected(uuid);
};

const handleProfileSelect = (_, __, id) =>
  POSSchedulingActions.profileSelected(id);

const EventSchedulingFixedSchedule = injectIntl(
  ({ intl, eventDiscounts, posStore, posEventStore, posSchedulingStore }) => (
    <div>
      {posEventStore.paymentPlanDescription && (
        <PaymentPlanSelector
          hideLabel
          registrationPackage={posSchedulingStore.registrationPackage}
          paymentPlan={posEventStore.paymentPlanDescription}
          onChange={handlePlanSelect}
        />
      )}

      <ProfileSelector
        afterProfileCreate={POSSchedulingActions.profileCreated}
        athletes={posSchedulingStore.availableProfiles}
        disabled={
          posSchedulingStore.tentativeAthleteIds.size >=
          posEventStore.spotsRemaining
        }
        disabledText={t('.event_is_full', intl, __filenamespace)}
        header={t('actions.choose_attendees', intl)}
        managingUserId={posStore.selectedClient.user_id}
        onChange={handleProfileSelect}
        preventProfileCreation={posEventStore.event.isMembershipExclusive()}
        value={null}
      />

      <FixedScheduleProfileList
        profiles={posSchedulingStore.selectedProfiles}
        eventDiscounts={
          posSchedulingStore.paymentPlanDescription ? List() : eventDiscounts
        }
        eventPrice={posEventStore.event.price}
        onRemove={POSSchedulingActions.profileRemoved}
      />

      <FlexBoxJustify style={styles.totalContainer}>
        <FormattedMessage id={messageId('.total_label', __filenamespace)} />
        <span style={boldText}>
          {posSchedulingStore.totalPrice >= 0 ? (
            <FormattedCurrency
              value={posSchedulingStore.totalPrice}
              fromCents
            />
          ) : (
            <span>&ndash;</span>
          )}
        </span>
      </FlexBoxJustify>
    </div>
  )
);

function EventSchedulingSingleSession({
  eventDiscounts,
  posEventStore,
  posSchedulingStore,
  posSingleSessionContentStore,
  posStore,
  sessionDataStore: { sessions, loadingMore },
}) {
  const { event } = posEventStore;
  const { session_ids: eventSessionIds } = event;
  const loadedSessions = sessions.filter(
    session => session.event_id === event.id
  );
  const showLoadMore = loadedSessions.size < eventSessionIds.size;
  const sspPrice = posEventStore.event.getTieredPrice(
    posSchedulingStore.singleSessionRegistrationCount,
    posEventStore.event.single_session_price
  );

  return (
    <SingleSessionPurchasableContent
      afterProfileCreate={POSSchedulingActions.singleSessionProfileCreated}
      allDayRegistrationCount={posSchedulingStore.allDayRegistrationCount}
      eventIsFull={
        posSchedulingStore.tentativeAthleteIds.size >=
        posEventStore.spotsRemaining
      }
      contentActions={POSSingleSessionContentActions}
      fullPrice={posEventStore.event.price}
      isLoadingAvailability={posEventStore.isLoadingAvailability}
      isSessionAvailable={posSchedulingStore.isSessionAvailable}
      managingUserId={posStore.selectedClient.user_id}
      membershipEventDiscounts={eventDiscounts}
      onProfileSelect={(_, __, customerUserId) => {
        POSSchedulingActions.singleSessionAttendeeAdded(customerUserId);
      }}
      purchasedRegistrations={posSchedulingStore.purchasedRegistrations}
      registrationPackage={posSchedulingStore.registrationPackage}
      schedulingActions={POSSchedulingActions}
      selectableAthletes={posSchedulingStore.availableProfiles}
      selectedAthletes={posSchedulingStore.selectedProfiles}
      singleSessionPrice={sspPrice}
      singleSessionRegistrationCount={
        posSchedulingStore.singleSessionRegistrationCount
      }
      sessionAvailability={posSchedulingStore.sessionAvailability}
      sspStore={posSingleSessionContentStore}
      event={posEventStore.event}
      totalPrice={posSchedulingStore.totalPrice}
      sessions={sessions}
      sessionsShowMore={showLoadMore}
      sessionsLoadingMore={loadingMore}
      onSessionsLoadMore={() =>
        POSSingleSessionContentActions.listMoreEventSessions(
          posEventStore.event
        )
      }
    />
  );
}

const viewInCartClicked = () => POSSchedulingActions.viewInCartClicked();

function EventScheduler({
  clientCreditStore: { clientCredits },
  membershipDiscountStore,
  posCartStore,
  posEventStore,
  posEventStaffStore,
  posSchedulingStore,
  posSchedulingAvailableTimesStore,
  posSingleSessionContentStore,
  posStore,
  sessionDataStore,
}) {
  const eventDiscounts = membershipDiscountStore.findByEventTypeId(
    posEventStore.event.event_type.id
  );
  const isExtarnalUrl =
    enabledCustomerFeatures(['external_event_url']) &&
    posEventStore.event.isFixedSchedule() &&
    posEventStore.event.get('external_url', null);

  if (posEventStore.event.isOpenBooking()) {
    return (
      <EventSchedulingOpenBooking
        eventDiscounts={eventDiscounts}
        clientCredits={clientCredits}
        posStore={posStore}
        posCartStore={posCartStore}
        posEventStore={posEventStore}
        posEventStaffStore={posEventStaffStore}
        posSchedulingStore={posSchedulingStore}
        posSchedulingAvailableTimesStore={posSchedulingAvailableTimesStore}
      />
    );
  }
  if (posEventStore.event.allow_single_session_purchase) {
    return (
      <div>
        <EventSchedulingSingleSession
          eventDiscounts={eventDiscounts}
          posEventStore={posEventStore}
          posSchedulingStore={posSchedulingStore}
          posSingleSessionContentStore={posSingleSessionContentStore}
          posStore={posStore}
          sessionDataStore={sessionDataStore}
        />
        <CartCommitButton
          itemIsValid={posSchedulingStore.packageIsValid}
          isUpdatingCart={posCartStore.isUpdatingCart}
          isNavigatingToCart={false}
          hasOrderItemId={posSchedulingStore.orderItemId}
          hasUpdate={posSchedulingStore.changed}
          handleAddToCartClick={() => {
            POSSchedulingActions.addToCartClick(
              posSchedulingStore.registrationPackage,
              { redirect: true }
            );
          }}
          handleUpdateCartClick={() => {
            POSSchedulingActions.updateCartClick(
              posSchedulingStore.orderItemId,
              { orderable: posSchedulingStore.registrationPackage }
            );
          }}
          handleViewInCartClick={viewInCartClicked}
        />
      </div>
    );
  }
  if (posEventStore.event.isClassSchedule()) {
    return (
      <EventSchedulingClasses
        clientCredits={clientCredits}
        posStore={posStore}
        posEventStore={posEventStore}
        posSchedulingStore={posSchedulingStore}
      />
    );
  }
  return (
    <div>
      <EventSchedulingFixedSchedule
        eventDiscounts={eventDiscounts}
        posStore={posStore}
        posSchedulingStore={posSchedulingStore}
        posEventStore={posEventStore}
      />
      <CartCommitButton
        itemIsValid={posSchedulingStore.packageIsValid && !isExtarnalUrl}
        isUpdatingCart={posCartStore.isUpdatingCart}
        isNavigatingToCart={false}
        hasOrderItemId={posSchedulingStore.orderItemId}
        hasUpdate={posSchedulingStore.changed}
        handleAddToCartClick={() => {
          POSSchedulingActions.addToCartClick(
            posSchedulingStore.registrationPackage,
            { redirect: true }
          );
        }}
        handleUpdateCartClick={() => {
          POSSchedulingActions.updateCartClick(posSchedulingStore.orderItemId, {
            orderable: posSchedulingStore.registrationPackage,
          });
        }}
        handleViewInCartClick={viewInCartClicked}
      />
    </div>
  );
}

const confirmationText = (intl, posSchedulingStore) =>
  function (registration) {
    if (!registration) {
      return '';
    }
    const client = posSchedulingStore.allProfiles.find(
      // eslint-disable-next-line react/destructuring-assignment
      p => p.id === registration.client_id
    );

    return client ? (
      <div>
        <FormattedMessage
          id={messageId('.remove_booking_confirmation', __filenamespace)}
          values={{
            clientName: client.name(),
          }}
        />
        &nbsp;
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {registration.isCreditBased() && (
          <CreditNotice
            // eslint-disable-next-line react/destructuring-assignment
            expDate={registration.credit_expires_at}
            clientName={client.name()}
          />
        )}
      </div>
    ) : null;
  };

function PurchasedRegistrationScheduler({
  intl,
  availableTimesStore,
  clientCreditStore: { clientCredits },
  sessionDataStore: { sessions },
  posEventStore,
  posEventStaffStore,
  posSchedulingStore,
  posStore,
}) {
  const {
    allProfiles,
    availableProfiles,
    clientPurchasedRegistrations,
    purchasedRegistrationSelectedClient: client,
    purchasedRegistrationSelectedClientId: selectedClientId,
  } = posSchedulingStore;

  const clientCredit = clientCredits.getIn(
    [posEventStore.event.id, client.id || selectedClientId],
    Map()
  );

  const creditsRemaining = clientCredit.get('unlimited')
    ? Infinity
    : clientCredit.get('credits_remaining', 0);

  return (
    <div style={styles.contentContainer(false)}>
      {client.id && (
        <FlexBoxCenter style={{ marginBottom: 20 }}>
          <UserAvatar user={client} />
          <div>{client.name()}</div>
        </FlexBoxCenter>
      )}

      <Scheduler
        availableTimesStore={availableTimesStore}
        creditsRemaining={creditsRemaining}
        dateAndTimeOnly={!!client.id}
        afterProfileCreate={POSPurchasedRegistrationActions.profileCreated}
        allAthletes={allProfiles}
        confirmationText={confirmationText(intl, posSchedulingStore)}
        event={posEventStore}
        handleAthleteChange={
          POSPurchasedRegistrationActions.registrationAthleteChanged
        }
        staff={posEventStaffStore.staff}
        label={t('.sessions_available', intl, __filenamespace)}
        managingUserId={posStore.selectedClient.user_id}
        onStaffChange={POSPurchasedRegistrationActions.registrationStaffChanged}
        onDateChange={POSPurchasedRegistrationActions.registrationDateChanged}
        onRegistrationBooked={
          POSPurchasedRegistrationActions.registrationBooked
        }
        onTimeChange={POSPurchasedRegistrationActions.registrationTimeChanged}
        fetchAvailabilities={
          POSPurchasedRegistrationActions.fetchAvailabilities
        }
        purchasedRegistrations={clientPurchasedRegistrations}
        removeSessionIcon={<DeleteIcon sx={{ color: uhColors.lightGrey }} />}
        scheduledSessionLabel={t('.scheduled', intl, __filenamespace)}
        selectableAthletes={availableProfiles}
        selectedAthleteId={client.id || selectedClientId}
        sessions={sessions}
      />

      <Button
        fullWidth
        variant="contained"
        style={merge(styles.scheduledRegistrationActionButton, {
          marginTop: 10,
          backgroundColor: uhColors.activeBlue,
          color: uhColors.white,
        })}
        onClick={completeClicked}
      >
        {t('actions.complete', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="default"
        style={styles.scheduledRegistrationActionButton}
        onClick={handleBackButtonClicked}
      >
        {t('actions.back', intl, __filenamespace)}
      </Button>
    </div>
  );
}

function EventScheduling({
  intl,
  clientCreditStore,
  membershipDiscountStore,
  posCartStore,
  posEventStaffStore,
  posEventStore,
  posRegistrationAvailableTimesStore,
  posSchedulingAvailableTimesStore,
  posSchedulingStore,
  posSingleSessionContentStore,
  posStore,
  sessionDataStore,
}) {
  return (
    <SpinWhileLoading
      isLoading={clientCreditStore.isLoading}
      contentContainerStyle={styles.spinnerContainer(
        posEventStore.event.isClassSchedule()
      )}
      outerContainerStyle={styles.spinnerContainer(
        posEventStore.event.isClassSchedule()
      )}
    >
      <Header selectedClient={posStore.selectedClient}>
        <ProductInformation
          primaryText={posEventStore.event.title}
          secondaryText={posEventStore.event.scheduleText()}
          color={posEventStore.event.typeColor()}
          typeText={posEventStore.event.typeName()}
          price={
            posEventStore.automationsLoaded ? (
              <EventPrice
                event={posEventStore.event}
                automations={List([
                  posEventStore.packagePricingDescription,
                  posEventStore.paymentPlanDescription,
                ])}
              />
            ) : (
              <span>&ndash;</span>
            )
          }
        />
      </Header>
      {!posSchedulingStore.displayRegistrationPackage &&
        posEventStore.automationsLoaded && (
          <>
            <CartIndicator
              cart={posCartStore.cart}
              schedule={posSchedulingStore.eventSchedule}
              onView={POSSchedulingActions.viewInCartClicked}
            />
            <div
              className="event-scheduling-content-container"
              style={styles.contentContainer(
                posEventStore.event.isClassSchedule()
              )}
            >
              <EventScheduler
                clientCreditStore={clientCreditStore}
                membershipDiscountStore={membershipDiscountStore}
                posCartStore={posCartStore}
                posEventStore={posEventStore}
                posEventStaffStore={posEventStaffStore}
                posSchedulingStore={posSchedulingStore}
                posSchedulingAvailableTimesStore={
                  posSchedulingAvailableTimesStore
                }
                posSingleSessionContentStore={posSingleSessionContentStore}
                posStore={posStore}
                sessionDataStore={sessionDataStore}
              />
              {posEventStore.event.isClassSchedule() &&
                !posSchedulingStore.selectedClientId && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="default"
                    style={{
                      ...styles.cancelButton,
                      ...styles.cancelButtonLabel,
                    }}
                    onClick={eventSchedulingCancelClicked}
                  >
                    {t('actions.cancel', intl, __filenamespace)}
                  </Button>
                )}
            </div>
          </>
        )}

      {posSchedulingStore.displayRegistrationPackage && (
        <PurchasedRegistrationScheduler
          availableTimesStore={posRegistrationAvailableTimesStore}
          clientCreditStore={clientCreditStore}
          posEventStore={posEventStore}
          posEventStaffStore={posEventStaffStore}
          posSchedulingStore={posSchedulingStore}
          posStore={posStore}
          sessionDataStore={sessionDataStore}
          intl={intl}
        />
      )}
    </SpinWhileLoading>
  );
}

export default injectIntl(EventScheduling);
