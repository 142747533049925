import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown } from '@upperhand/playmaker';
import altContainer from 'shared/hocs/altContainer.jsx';
import { StaffDataStore } from 'dataStores';

export const FILTER_STAFF_ALL = 'all';

const StaffFilter = injectIntl(
  ({ staffIds, value, onChange, staffDataStore: { staff } }) => {
    const staffItems = staffIds
      .map(id => ({
        label: `Staff: ${staff.get(id, { name: () => '' }).name()}`,
        value: id,
      }))
      .toArray();

    const handleChange = e =>
      e.target.value !== value && onChange(e.target.value);

    return (
      <Dropdown
        id="staff"
        name="staff"
        fullWidth
        outlined
        rounded
        items={[{ label: 'Staff: All', value: FILTER_STAFF_ALL }].concat(
          staffItems
        )}
        onChange={handleChange}
        value={value}
        label="Choose Staff"
      />
    );
  }
);

export default altContainer({
  stores: {
    staffDataStore: StaffDataStore,
  },
})(StaffFilter);
