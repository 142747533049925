import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import Membership from 'shared/records/Membership.jsx';
import MembershipActions from 'shared/actions/MembershipActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class MembershipStoreInterface extends UpperHandStore {
  constructor() {
    super();

    this.record = new Membership();
    this.shoudFetchMembership = false;
    this.requestedMembershipFetchBy = null;

    this.bindListeners({
      handleError: MembershipActions.ERROR,
      handleFetch: MembershipActions.FETCH,
      handleFetchSuccess: MembershipActions.MEMBERSHIP_LOADED,
      clientLoaded: CurrentContextActions.fetchCustomerUserSuccess,
    });
  }

  clientLoaded(data) {
    if (data.type === 'Client') {
      this.handleFetch({
        id: data.active_membership_id,
        requestedBy: this.displayName,
      });
    }
  }

  handleFetch({ id, requestedBy, fields }) {
    if (id) {
      if (requestedBy) {
        this.requestedMembershipFetchBy = requestedBy;
      }
      uhApiClient.get({
        url: this.url(id),
        data: { fields: fields || [] },
        success: MembershipActions.membershipLoaded,
        error: MembershipActions.error,
      });
    }
  }

  handleFetchSuccess(data) {
    if (this.requestedMembershipFetchBy === this.displayName) {
      this.record = new Membership(data);
      this.requestedMembershipFetchBy = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  url(id) {
    return `memberships/${id}`;
  }
}

export default MembershipStoreInterface;
