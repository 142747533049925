import { List } from 'immutable';

import { onError, onSuccess } from 'sources/handlers';
import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import DataStoreActions from './MembershipSubscriptionActions';

const parserTo = {
  type: MembershipSubscription,
  paginationKey: 'membership_subscriptions',
};

const parser = new Parser(parserTo);

// eslint-disable-next-line import/prefer-default-export
export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const putChargeDate = ({ membershipId, params, success, error }) => {
  uhApiClient.put({
    url: `membership_subscriptions/${membershipId}`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseNone(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const managementRequest = ({ membershipId, params, success, error }) => {
  uhApiClient.post({
    url: `membership_subscriptions/${membershipId}/management_requests`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseNone(data),
      List([DataStoreActions.sendManagementRequestSuccess, success])
    ),
    error: onError(List([DataStoreActions.sendManagementRequestError, error])),
  });
};
