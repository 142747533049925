import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Avatar, Card, Grid, Typography } from '@upperhand/playmaker';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { Balance } from 'records';
import Client from 'shared/records/Client.jsx';

import BalanceDetailsDrawerActions from 'containers/reports/balanceDetailsDrawer/Actions';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';

function RowMobile({ intl, balance, client, reportMode, scheduledPayment }) {
  const paidAmount = scheduledPayment
    ? scheduledPayment.paidAmount
    : balance.get('paid');

  const unpaidAmount = scheduledPayment
    ? scheduledPayment.unpaidAmount
    : Math.abs(balance.get('balanceRemaining'));
  const effectiveAt = scheduledPayment?.effectiveAt
    ? moment(scheduledPayment?.effectiveAt)
    : moment(balance.get('effectiveAt'));

  return (
    <Card
      classes={{
        root: 'balances-report__row-mobile',
        content: 'balances-report__row-mobile-content',
      }}
      contentDirection="row"
      onClick={() =>
        BalanceDetailsDrawerActions.openDrawer(balance.get('compoundId'))
      }
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        alignContent="center"
        justify="space-between"
      >
        <Grid item xs={7}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid container item xs={12} alignItems="center">
              <Button
                disableRipple
                disableElevation
                disableFocusRipple
                color="dark"
                className="balances-report__buyer-mobile"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  ClientProfileDrawerActions.mounted({ clientId: client.id });
                }}
              >
                <Avatar
                  size="1x"
                  src={
                    client &&
                    client.get('profile_image').getAlternative('avatar')
                  }
                  classes={{
                    root: 'balances-report__avatar',
                  }}
                >
                  {balance.get('clientName')}
                </Avatar>
                <Typography variant="subtitle2" align="left">
                  {balance.get('clientName')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                className="balances-report__row-item_muted"
              >
                {effectiveAt.isSame(moment(), 'day')
                  ? t('.date_today', intl, __filenamespace, {
                      date: effectiveAt.format('h:mma'),
                    })
                  : effectiveAt.format('MM/DD/YYYY • h:mma')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                className="balances-report__row-total"
              >
                <FormattedCurrency
                  value={reportMode ? unpaidAmount : paidAmount}
                  fromCents
                />
                <ChevronRightIcon />
              </Typography>
            </Grid>
            {reportMode && (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="right"
                  className="balances-report__row-item_muted"
                >
                  {balance.get('product')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

RowMobile.propTypes = {
  intl: PropTypes.object.isRequired,
  balance: PropTypes.instanceOf(Balance).isRequired,
  client: PropTypes.instanceOf(Client),
};

RowMobile.defaultProps = {
  client: new Client(),
};

export default RowMobile;
