import React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';
import { injectIntl } from 'react-intl';
import { compose } from 'shared/utils/SharedUtils.js';

import {
  ContentModal,
  Grid,
  Typography,
  DateTimePicker,
} from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';
import { t } from 'shared/utils/LocaleUtils.js';
import MembershipChargeDateActions from 'memberships/actions/MembershipChargeDateActions.js';
import MembershipChargeDateStore from 'memberships/stores/MembershipChargeDateStore.jsx';
import moment from 'moment-timezone';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

const styles = {
  typography: {
    paddingBottom: 14,
  },
};

function ChargeDateChangeDialog({
  membershipChargeDateStore: { showChargeDate, chargeDate },
  intl,
  classes,
}) {
  const handleDateChange = value => {
    const date = moment(value).tz(customerTZ(), true);
    MembershipChargeDateActions.setChargeDate(date);
  };
  return (
    <ContentModal
      open={showChargeDate}
      onSuccess={MembershipChargeDateActions.handleChargeDateChange}
      onAbort={() => MembershipChargeDateActions.toggleShowChargeDate()}
      title={t('.title', intl, __filenamespace)}
      successLabel={t('.confirm', intl, __filenamespace)}
      cancelLabel={t('.cancel', intl, __filenamespace)}
      size="2x"
    >
      <Typography className={classes.typography}>
        {t('.description', intl, __filenamespace)}
      </Typography>

      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography>{t('.charge_date', intl, __filenamespace)}</Typography>
          <DateTimePicker
            autoOk
            disablePast
            type="date"
            fullWidth
            placeholder="MM/DD/YYYY"
            minDate={moment().toDate()}
            onChange={handleDateChange}
            value={chargeDate}
          />
        </Grid>
      </Grid>
    </ContentModal>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      membershipChargeDateStore: MembershipChargeDateStore,
    },
  }),
  withStyles(styles)
)(ChargeDateChangeDialog);
