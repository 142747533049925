import { List, Set } from 'immutable';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventRate from 'shared/records/EventRate.jsx';
import EventRateCreationActions from 'contacts/shared/actions/event_rates/EventRateCreationActions';
import EventRateCreationStore from 'contacts/shared/stores/event_rates/EventRateCreationStore';
import EventRateDeletionActions from 'contacts/shared/actions/event_rates/EventRateDeletionActions';
import EventRateEditingActions from 'contacts/shared/actions/event_rates/EventRateEditingActions';
import EventRateListingActions from 'contacts/shared/actions/event_rates/EventRateListingActions';
import SpecificEventsActions from 'event_mgmt/index/actions/SpecificEventsActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

class EventRateListingStore extends UpperHandStore {
  constructor() {
    super();

    this.eventRates = List();
    this.selectedEventIds = Set();
    this.isLoading = false;
    this.customerUserId = null;
    this.isStaffDrawer = false;

    this.isDirtySelectedEventIdsSet = false;

    this.bindListeners({
      list: EventRateListingActions.LIST,
      listSuccess: EventRateListingActions.LIST_SUCCESS,
      listError: EventRateListingActions.LIST_ERROR,

      eventRateCreated: EventRateCreationActions.SAVE_SUCCESS,
      eventRateUpdated: EventRateEditingActions.SAVE_SUCCESS,
      eventRateRemoved: EventRateDeletionActions.REMOVE_EVENT_RATE_SUCCESS,

      addSelectedEventId: [
        EventRateCreationActions.EVENT_RATE_EVENT_SELECTED,
        EventRateEditingActions.EVENT_RATE_EVENT_SELECTED,
      ],
      removeSelectedEventId: [
        EventRateCreationActions.EVENT_RATE_EVENT_REMOVED,
        EventRateEditingActions.EVENT_RATE_EVENT_REMOVED,
      ],
      cancelEdit: [
        EventRateEditingActions.CANCEL,
        EventRateCreationActions.CANCEL,
      ],
    });
  }

  fetchEvent() {
    EventActions.list.defer({
      fields: ['participant_count'],
      exceptIds: this.selectedEventIds.toArray(),
    });
  }

  updateEventList() {
    this.setSelectedEventIds();
    this.fetchEvent();
    this.isDirtySelectedEventIdsSet = false;
  }

  cancelEdit() {
    if (this.isDirtySelectedEventIdsSet) {
      this.updateEventList();
    }
  }

  list(options) {
    if (!currentCustomer().payroll_enabled) {
      return;
    }

    const { page = 1, customerUserId, isStaffDrawer } = options || {};
    this.isStaffDrawer = isStaffDrawer;
    this.isLoading = true;
    if (customerUserId) {
      this.eventRates = List();
      this.selectedEventIds = Set();
      this.customerUserId = customerUserId;
    }
    uhApiClient.get({
      url: 'event_rates',
      data: {
        page,
        per_page: 100,
        customer_user_id: this.customerUserId,
      },
      success: EventRateListingActions.listSuccess,
      error: EventRateListingActions.listError,
    });
  }

  listSuccess(data) {
    const {
      event_rates: eventRates,
      page,
      per_page: perPage,
      total_count: totalCount,
    } = data;

    const partitionedRates = List(eventRates.map(c => new EventRate(c)));

    this.eventRates = this.eventRates.concat(partitionedRates);
    if (eventRates.length === perPage && totalCount > this.eventRates.size) {
      this.list({ page: page + 1 });
    } else {
      this.updateEventList();
      SpecificEventsActions.list.defer({
        fields: ['participant_count'],
        page: 1,
        perPage: 50,
        ids: this.selectedEventIds.toArray(),
        isStaffDrawer: this.isStaffDrawer,
      });
      this.isLoading = false;
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing accounting codes', args);
  }

  eventRateCreated(data) {
    this.eventRates = this.eventRates.push(new EventRate(data));
    this.updateEventList();
  }

  eventRateUpdated(data) {
    const index = this.eventRates.findIndex(r => r.id === data.id);

    this.eventRates = this.eventRates.set(index, new EventRate(data));
    this.updateEventList();
  }

  eventRateRemoved([_, id]) {
    const index = this.eventRates.findIndex(r => r.id === id);
    if (index >= 0) {
      this.eventRates = this.eventRates.delete(index);
      this.waitFor(EventRateCreationStore);
      const { eventRate } = EventRateCreationStore.getState();
      const isCreationEventRateEventIdsDirty =
        eventRate && eventRate.event_ids.size;
      this.setSelectedEventIds();
      if (isCreationEventRateEventIdsDirty) {
        this.selectedEventIds = this.selectedEventIds.merge(
          eventRate.event_ids
        );
      }
      this.fetchEvent();
      this.isDirtySelectedEventIdsSet = isCreationEventRateEventIdsDirty;
    }
  }

  addSelectedEventId(id) {
    this.selectedEventIds = this.selectedEventIds.add(id);
    this.fetchEvent();
    this.isDirtySelectedEventIdsSet = true;
  }

  removeSelectedEventId(id) {
    this.selectedEventIds = this.selectedEventIds.remove(id);
    this.fetchEvent();
    this.isDirtySelectedEventIdsSet = true;
  }

  setSelectedEventIds() {
    this.selectedEventIds = this.eventRates.reduce(
      (set, rate) => set.union(rate.event_ids),
      Set()
    );
  }
}

export default alt.createStore(EventRateListingStore, 'EventRateListingStore');
