export default alt.generateActions(
  'fileSelected',
  'drawerOpened',
  'drawerClosed',
  'existingUploadListSuccess',
  'existingUploadListError',
  'fileUploadSuccess',
  'fileUploadError',
  'createContactUploadSuccess',
  'createContactUploadError',
  'fetchContactUploadSuccess',
  'fetchContactUploadError'
);
