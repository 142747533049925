export default alt.generateActions(
  'reset',
  'closeDialog',
  'clearProduct',
  'openDialog',
  'openDialogForOrder',
  'variantsList',
  'variantsListError',
  'variantsListSuccess',
  'updateFilter',
  'localUpdatePurchaseOrder',
  'localUpdatePurchaseOrderItem',
  'processPurchaseOrder',
  'createPurchaseOrder',
  'createPurchaseOrderError',
  'createPurchaseOrderSuccess',
  'updatePurchaseOrder',
  'updatePurchaseOrderSuccess',
  'updatePurchaseOrderError',
  'purchaseOrderItemList',
  'purchaseOrderItemListSuccess',
  'purchaseOrderItemListError',
  'enableEditMode',
  'updatePurchaseOrderItems',
  'updatePurchaseOrderItemsSuccess',
  'updatePurchaseOrderItemsError',
  'createInventoryAdjustments',
  'onProductClear'
);
