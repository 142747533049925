/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { useParams } from 'react-router';

import history from 'routes/History.js';

import { t } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import { ClientDataStore } from 'dataStores';

import ClientProfileActions from '../Actions';

// TODO: This should be standardized. It is also present in the NotFound component.
import LeftCarrot from './left-carrot.svg';

function Header({ intl, clientId, clientDataStore: { clients } }) {
  const params = useParams();
  const mainClientId = parseInt(params.clientId, 10);
  const client = clients.get(clientId);
  const isViewOwnProfile = currentUser().customer_user_id === clientId;
  const isStaff = currentUser().isStaff();

  if (!client || isViewOwnProfile) {
    return null;
  }

  const handleGoBack = () => {
    if (isStaff) {
      history.back();
      return;
    }

    if (mainClientId !== clientId && client?.managed_by_id) {
      ClientProfileActions.mounted({
        clientId: client.managing_customer_user_id,
        openDrawer: !smallScreen(),
      });
      return;
    }

    if (mainClientId !== clientId && !client?.managed_by_id) {
      ClientProfileActions.mounted({
        clientId: mainClientId,
        openDrawer: !smallScreen(),
      });
      return;
    }

    history.back();
  };

  return (
    <div className="client-profile__header">
      <h3>
        <img src={LeftCarrot} onClick={handleGoBack} alt="Back" />
        <span className="vl" />
        {isViewOwnProfile || isStaff
          ? t('.client_profile', intl, __filenamespace)
          : t('.managed_profile', intl, __filenamespace)}
      </h3>
      <hr />
    </div>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(Header);
