import * as React from 'react';
import AltContainer from 'alt-container';
import { Navigate } from 'react-router-dom';

import ErrorDialogWithMessageWindow from 'shared/components/ErrorDialogWithMessageWindow.jsx';
import LandingTemplate from 'shared/components/login/_LandingTemplate.jsx';
import { getAllUrlParameters } from 'shared/utils/UrlParameters';
import ResetPasswordActions from './Actions';
import ResetPasswordStore from './Store';
import DesktopResetRequest from './components/DesktopResetRequest.jsx';
import MobileResetRequest from './components/MobileResetRequest.jsx';

function ResetPassword() {
  // The url parameter `key` can't be passed as a props
  const key = getAllUrlParameters().get('key');

  React.useEffect(() => {
    ResetPasswordActions.updateStore({ resetToken: key });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!key) {
    return <Navigate to="/" />;
  }

  return (
    <div style={{ height: '100%' }}>
      <LandingTemplate
        largeScreen={
          <AltContainer
            store={ResetPasswordStore}
            actions={ResetPasswordActions}
          >
            <DesktopResetRequest />
          </AltContainer>
        }
        smallScreen={
          <AltContainer
            store={ResetPasswordStore}
            actions={ResetPasswordActions}
          >
            <MobileResetRequest />
          </AltContainer>
        }
      />
      <ErrorDialogWithMessageWindow />
    </div>
  );
}

export default ResetPassword;
