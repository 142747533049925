import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { FormattedMessage, injectIntl } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

function MultiSelectField({ value, label, data, onChange }) {
  const isAllSelected = data.length === value.length;
  const isNothingSelected = value.length === 0;

  const renderValue = useCallback(() => {
    if (isAllSelected)
      return <FormattedMessage id={messageId('.all', __filenamespace)} />;

    if (isNothingSelected)
      return <FormattedMessage id={messageId('.none', __filenamespace)} />;

    if (value.length > 1) {
      <FormattedMessage
        id={messageId('.n_items', __filenamespace)}
        values={{ n: value.length, itemName: label }}
      />;
    }

    return value.map(id => data.find(d => d.id === id)?.name).join(', ');
  }, [isAllSelected, isNothingSelected, label, value, data]);

  return (
    <FormControl sx={{ width: 300, marginBottom: '10px' }}>
      <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
      <Select
        input={<OutlinedInput label={label} />}
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        renderValue={renderValue}
      >
        <MenuItem key="all" value="all">
          <Checkbox checked={isAllSelected} />
          <ListItemText
            primary={
              <FormattedMessage id={messageId('.all', __filenamespace)} />
            }
          />
        </MenuItem>
        {data.map(item => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={value.indexOf(item.id) > -1 || isAllSelected} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultiSelectField.propTypes = {
  value: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default injectIntl(memo(MultiSelectField));
