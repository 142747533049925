import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Create';
import AssignmentIcon from '@mui/icons-material/Assignment';

import SearchBar from 'shared/components/SearchBar.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import FilterMenu from 'retail/components/InventoryDialog/FilterMenu.jsx';
import VariantTable from 'retail/components/InventoryDialog/VariantTable.jsx';

import { formatDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';

import './styles.scss';

const styles = {
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  deleteIconColorPrimary: {
    color: 'white',
  },
  dialog: {
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 15,
      backgroundColor: uhColors.dialogTitle,
      padding: '0 10px 0 24px',
      color: 'var(--color-white)',
    },
    iconClose: {
      height: 20,
      width: 20,
      color: '#C3C3C3',
    },
    bodyStyle: {
      padding: 0,
      overflowY: 'hidden',
      borderTop: 'none',
    },
  },
  title: {
    container: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      backgroundColor: uhColors.dialogTitle,
      padding: '0 10px 10px 24px',
      color: 'var(--color-white)',
    },
    dateInputContainer: {
      marginLeft: 10,
      minHeight: 50,
    },
    numberInputContainer: {
      minHeight: 50,
    },
    inputRoot: {
      color: 'var(--color-white)',
      marginRight: 14,
    },
    input: {
      paddingLeft: 27,
      color: 'var(--color-white)',
    },
    hint: {
      paddingLeft: 27,
      color: '#CACBCA',
    },
    icon: {
      height: 17,
      width: 17,
      margin: '2px 0 0 0',
      color: 'var(--color-white)',
    },
    label: {
      paddingRight: 5,
      whiteSpace: 'nowrap',
    },
    value: {
      fontWeight: 'bold',
    },
  },
  subTitle: {
    container: {
      height: 40,
      color: '#C3C3C3',
      fontSize: 13,
    },
    submitted: {
      width: '100%',
    },
    iconEdit: {
      padding: '0 12px',
    },
  },
  spinnerContainer: {
    paddingBottom: '3em',
    minHeight: 428,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 24px 10px 10px',
  },
  searchInput: {
    container: {
      marginLeft: 10,
      width: '100%',
      fontSize: 15,
    },
    icon: {
      height: 17,
      width: 17,
    },
  },
};

class InventoryDialog extends React.PureComponent {
  // eslint-disable-next-line class-methods-use-this
  get inventoryTitle() {
    return (
      <div style={styles.dialog.title}>
        <h3>
          <FormattedMessage id={messageId('.title', __filenamespace)} />
        </h3>
        <IconButton onClick={InventoryDialogActions.closeDialog}>
          <ClearIcon style={styles.dialog.iconClose} />
        </IconButton>
      </div>
    );
  }

  get orderHistoryTitle() {
    const {
      intl,
      inventoryDialogStore: { purchaseOrder, isEditMode },
    } = this.props;

    return (
      <div style={{ ...styles.dialog.title, paddingTop: 10 }}>
        <div style={styles.inlineContainer}>
          <div style={styles.title.label}>
            <FormattedMessage
              id={messageId('.labels.order_number', __filenamespace)}
            />
          </div>
          {isEditMode ? (
            <TextField
              style={styles.title.inputRoot}
              variant="standard"
              onBlur={event =>
                InventoryDialogActions.localUpdatePurchaseOrder(
                  'order_number',
                  event.target.value
                )
              }
              defaultValue={purchaseOrder.order_number || ''}
              placeholder={t('.hints.order_number', intl, __filenamespace)}
              InputProps={{
                startAdornment: <AssignmentIcon />,
                sx: {
                  color: 'var(--color-white)',
                  '& input': {
                    paddingLeft: '5px',
                  },
                  '&::before': {
                    borderBottom: '1px solid var(--color-white)',
                  },
                },
              }}
            />
          ) : (
            <div style={styles.title.value}>{purchaseOrder.order_number}</div>
          )}
        </div>
        <div style={styles.inlineContainer}>
          <div style={styles.title.label}>
            <FormattedMessage
              id={messageId('.labels.order_date', __filenamespace)}
            />
          </div>
          {isEditMode ? (
            <DatePicker
              onChange={value =>
                InventoryDialogActions.localUpdatePurchaseOrder(
                  'order_date',
                  value
                )
              }
              value={
                purchaseOrder.order_date
                  ? purchaseOrder.order_date.toDate()
                  : {}
              }
              renderInput={params => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...{
                    ...params,
                    placeholder: t('.hints.order_date', intl, __filenamespace),
                    variant: 'standard',
                    helperText: purchaseOrder.errors.getErrors(
                      'order_date',
                      intl
                    ),
                    error: !!purchaseOrder.errors.getErrors('order_date', intl),
                    style: styles.title.inputRoot,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      color: 'var(--color-white)',
                      '& input': {
                        paddingLeft: '5px',
                      },
                      '&::before': {
                        borderBottom: '1px solid var(--color-white)',
                      },
                      '& svg': {
                        color: 'var(--color-white)',
                      },
                    },
                  }}
                />
              )}
              DialogProps={{ disableEnforceFocus: true }}
            />
          ) : (
            <div style={styles.title.value}>
              {purchaseOrder.order_date
                ? formatDate(purchaseOrder.order_date)
                : ''}
            </div>
          )}
        </div>
        <IconButton onClick={InventoryDialogActions.closeDialog}>
          <ClearIcon style={styles.dialog.iconClose} />
        </IconButton>
      </div>
    );
  }

  get inventorySubtitle() {
    const {
      intl,
      inventoryDialogStore: { purchaseOrder },
    } = this.props;

    return (
      <div style={styles.title.container}>
        <TextField
          fullWidth
          variant="standard"
          defaultValue={purchaseOrder.order_number || ''}
          placeholder={t('.hints.order_number', intl, __filenamespace)}
          style={styles.title.inputRoot}
          InputProps={{
            startAdornment: <AssignmentIcon />,
            sx: {
              color: 'var(--color-white)',
              '& input': {
                paddingLeft: '5px',
              },
              '&::before': {
                borderBottom: '1px solid var(--color-white)',
              },
            },
          }}
          onBlur={event =>
            InventoryDialogActions.localUpdatePurchaseOrder(
              'order_number',
              event.target.value
            )
          }
        />
        <DatePicker
          value={
            purchaseOrder.order_date ? purchaseOrder.order_date.toDate() : ''
          }
          renderInput={params => (
            <TextField
              fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                ...params,
                placeholder: t('.hints.order_date', intl, __filenamespace),
                variant: 'standard',
                helperText: purchaseOrder.errors.getErrors('order_date', intl),
                error: !!purchaseOrder.errors.getErrors('order_date', intl),
                style: styles.title.inputRoot,
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  color: 'var(--color-white)',
                  '& input': {
                    paddingLeft: '5px',
                  },
                  '&::before': {
                    borderBottom: '1px solid var(--color-white)',
                  },
                  '& svg': {
                    color: 'var(--color-white)',
                  },
                },
              }}
            />
          )}
          onChange={value =>
            InventoryDialogActions.localUpdatePurchaseOrder('order_date', value)
          }
        />
      </div>
    );
  }

  get orderHistorySubtitle() {
    const {
      inventoryDialogStore: { purchaseOrder, customerUser, isEditMode },
    } = this.props;
    const isCustomerUser = customerUser && customerUser.id;

    return (
      <div
        style={{
          ...styles.title.container,
          ...styles.subTitle.container,
          ...styles.inlineContainer,
          paddingBottom: 15,
        }}
      >
        <div style={styles.subTitle.submitted}>
          <FormattedMessage
            id={messageId('.labels.submitted', __filenamespace)}
            values={{
              date: formatDate(purchaseOrder.get('created_at')),
              divider: isCustomerUser ? ' \u2022 ' : '',
              user: isCustomerUser ? customerUser.fullName() : '',
            }}
          />
        </div>
        {isEditMode ? (
          <div style={{ paddingRight: 14 }}>
            <FormattedMessage
              id={messageId('.labels.editing', __filenamespace)}
            />
          </div>
        ) : (
          <IconButton
            onClick={InventoryDialogActions.enableEditMode}
            style={styles.subTitle.iconEdit}
          >
            <CreateIcon style={styles.dialog.iconClose} />
          </IconButton>
        )}
      </div>
    );
  }

  get container() {
    const {
      intl,
      inventoryDialogStore,
      inventoryDialogStore: { filters, productId, productName, disable },
      isProductPage,
    } = this.props;

    return (
      <div style={styles.container}>
        <div style={styles.searchContainer}>
          <FilterMenu
            filteredSelection={filters.get('retail_category_ids')}
            disabled={isProductPage && disable}
            onChange={value =>
              InventoryDialogActions.updateFilter('retail_category_ids', value)
            }
          />
          <SearchBar
            hintText={t('.search-bar', intl, __filenamespace)}
            searchText={filters.get('search')}
            onTypingEnd={value =>
              InventoryDialogActions.updateFilter('search', value)
            }
            style={styles.searchInput.container}
            iconStyle={styles.searchInput.icon}
            inputStyle={styles.searchInput.input}
            underlineShow
            autoFocus
          />
          {productId && (
            <Chip
              label={productName}
              deleteIcon={<CancelIcon sx={{ fill: uhColors.white }} />}
              sx={{
                color: uhColors.white,
                marginLeft: '16px',
                backgroundColor: uhColors.activeBlue,
              }}
              onDelete={InventoryDialogActions.clearProduct}
            />
          )}
        </div>
        <VariantTable
          inventoryDialogStore={inventoryDialogStore}
          isProductPage={isProductPage}
        />
      </div>
    );
  }

  render() {
    const {
      intl,
      inventoryDialogStore: {
        isInventory,
        isLoading,
        isOpen,
        isChanged,
        isEditMode,
      },
    } = this.props;

    return (
      <Confirmation
        hasCheckbox={false}
        open={isOpen}
        classes={{
          root: 'inventory-dialog',
          content: 'inventory-dialog-content',
          actions: clsx(
            'inventory-dialog-actions',
            !isEditMode && 'hide-save-btn'
          ),
          title: 'inventory-dialog-title',
        }}
        confirmationDisabled={!isChanged || !isEditMode}
        title={isInventory ? this.inventoryTitle : this.orderHistoryTitle}
        cancelButtonLabel={t('.btn.close', intl, __filenamespace)}
        confirmButtonLabel={t('.btn.save', intl, __filenamespace)}
        onCancel={InventoryDialogActions.closeDialog}
        onConfirm={InventoryDialogActions.processPurchaseOrder}
      >
        {isInventory ? this.inventorySubtitle : this.orderHistorySubtitle}
        <SpinWhileLoading
          isLoading={isLoading}
          spinnerContainerStyle={styles.spinnerContainer}
        >
          {this.container}
        </SpinWhileLoading>
      </Confirmation>
    );
  }
}

InventoryDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  inventoryDialogStore: PropTypes.object,
  isProductPage: PropTypes.bool,
};

InventoryDialog.defaultProps = {
  inventoryDialogStore: {},
  isProductPage: true,
};

export default injectIntl(InventoryDialog);
