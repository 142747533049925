export default alt.generateActions(
  'begin',
  'cancel',
  'updated',
  'eventRateEventSelected',
  'eventRateEventRemoved',
  'save',
  'saveSuccess',
  'saveError'
);
