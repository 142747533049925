import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AltContainer from 'alt-container';

import PageHeader from 'components/PageHeader/PageHeader.jsx';
import CustomWaiverCountsforBilling from 'reporting/waivers/components/_CustomWaiverCountsforBilling.jsx';
import Filters from 'reporting/waivers/components/_Filters.jsx';
import CustomWaiverandUpperHandPolicyReport from 'reporting/waivers/components/_CustomWaiverandUpperHandPolicyReport.jsx';
import WaiversBlock from 'reporting/waivers/components/_WaiversBlock.jsx';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import WaiversReportStore, {
  WAIVERS_REPORT_NAMES,
} from 'reporting/waivers/stores/WaiversReportStore';
import WaiversReportActions from 'reporting/waivers/actions/WaiversReportActions';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';

import '../styles.scss';

const styles = {
  waiversInfoContainer: {
    margin: '10px 0',
  },
};

function ReportsBlockContainer({ children }) {
  return smallScreen() ? (
    <div style={styles.waiversInfoContainer}>{children}</div>
  ) : (
    <FlexBoxJustify style={styles.waiversInfoContainer}>
      {children}
    </FlexBoxJustify>
  );
}

function WaiversReport({
  waiversReportStore: {
    isLoading,
    filters,
    filtersApplied,
    policyReport,
    customAcceptances,
    pastDueAcceptances,
    uhAcceptances,
    waiversForBilling,
  },
  intl,
}) {
  return (
    <div
      className="waivers-report"
      style={{
        padding: '25px 30px 30px',
        height: '100%',
      }}
    >
      <FlexBoxJustify>
        <PageHeader
          title={
            <FormattedMessage id={messageId('.waivers', __filenamespace)} />
          }
        />
      </FlexBoxJustify>

      <Filters filters={filters} filtersApplied={filtersApplied} intl={intl} />

      <SpinWhileLoading isLoading={isLoading}>
        <ReportsBlockContainer>
          <WaiversBlock
            reportName={WAIVERS_REPORT_NAMES.CUSTOM_ACCEPTANCES}
            value={customAcceptances}
          />
          <WaiversBlock
            reportName={WAIVERS_REPORT_NAMES.UH_ACCEPTANCES}
            value={uhAcceptances}
            style={{ margin: `0 ${smallScreen() ? '0' : '10px'}` }}
          />
          <WaiversBlock
            reportName={WAIVERS_REPORT_NAMES.PAST_DUE_ACCEPTANCES}
            value={pastDueAcceptances}
          />
        </ReportsBlockContainer>
        <CustomWaiverandUpperHandPolicyReport
          policyReport={policyReport}
          intl={intl}
        />
        <CustomWaiverCountsforBilling
          intl={intl}
          dateRange={filters.getIn(['dateRange', 'value'])}
          waiversForBilling={waiversForBilling}
        />
      </SpinWhileLoading>
    </div>
  );
}
// eslint-disable-next-line
class PayrollReportContainer extends React.PureComponent {
  componentDidMount() {
    WaiversReportActions.mounted();
  }

  render() {
    return (
      <AltContainer
        stores={{
          waiversReportStore: WaiversReportStore,
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <WaiversReport {...this.props} />
      </AltContainer>
    );
  }
}

export default injectIntl(PayrollReportContainer);
