import * as React from 'react';
import AltContainer from 'alt-container';

import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
// do not remove this import ... it allows the page to redirect when a new event is saved
// eslint-disable-next-line no-unused-vars
import EventRedirectionStore from 'event_mgmt/shared/stores/EventRedirectionStore.jsx';
import EventResourceActions from 'event_mgmt/shared/actions/EventResourceActions.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import Header from 'event_mgmt/editing/components/_Header.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import MembershipListingStore from 'memberships/stores/MembershipListingStore.jsx';
import ResourceListingActions from 'resources/actions/ResourceListingActions.js';
import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import TabContainer from 'event_mgmt/editing/components/_TabsContainer.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { LEFT_MARGIN_PIXELS, windowWidth } from 'shared/utils/DOMUtils';

class EventMgmtEditing extends React.Component {
  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { eventSlug } = this.props;
    const { eventSlug: prevSlug } = prevProps;
    const isNewEventPage = /\/events\/new/.test(window.location.href);

    if (eventSlug !== prevSlug && isNewEventPage) {
      this.init();
    }
  }

  init() {
    StoreActions.prepareForReuse();

    const { eventSlug } = this.props;
    const isResourcesEnabled = currentCustomer().resources_enabled;

    this.eventId = eventSlug
      ? parseInt(eventSlug.split('-')[0], 10)
      : undefined;

    if (this.eventId) {
      EventActions.fetch(this.eventId, [
        'schedule_resources',
        'schedule_staff',
      ]);
    } else {
      EventActions.fillPaymentMethods();
      EventActions.scheduleTypeSelected();
    }

    if (isResourcesEnabled) {
      EventResourceActions.list({ eventId: this.eventId });
      ResourceListingActions.list();
    }

    RegistrationFieldActions.list(this.eventId);
    EventTypeListingActions.list({});
    MembershipListingActions.listRequested({});
    StaffActions.list({});
  }

  // eslint-disable-next-line class-methods-use-this
  updateEventAttribute(e, value, path = []) {
    let newValue = value;
    if (!newValue) {
      newValue =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    }
    EventActions.updateEventStore(
      path.length > 0 ? path : [e.target.name],
      newValue
    );
  }

  // eslint-disable-next-line class-methods-use-this
  updateEventStoreSchedules(keyPath, value) {
    EventActions.updateEventStore(['schedules', 0].concat(keyPath), value);
  }

  render() {
    const styles = {};
    const currentWidth = windowWidth();
    if (currentWidth <= 768) {
      styles.maxWidth = currentWidth - LEFT_MARGIN_PIXELS;
    }
    return (
      <main>
        <AltContainer
          stores={{
            creditPassListingStore: CreditPassListingStore,
            event: EventStore,
            eventTypeListingStore: EventTypeListingStore,
            membershipListingStore: MembershipListingStore,
          }}
          transform={({
            creditPassListingStore,
            event,
            eventTypeListingStore,
            membershipListingStore,
            ...rest
          }) => {
            const { fieldErrors, ...eventProps } = event;

            const isLoading =
              eventProps.isLoadingEvent ||
              membershipListingStore.isLoading ||
              creditPassListingStore.isLoading;
            return {
              eventFieldErrors: fieldErrors,
              ...eventProps,
              eventTypes: eventTypeListingStore.eventTypes,
              handleScheduleChange: this.updateEventStoreSchedules,
              handleEventInputChange: this.updateEventAttribute,
              handleEventScheduleInputChange: this.updateEventScheduleAttribute,
              isLoading,
              memberships: membershipListingStore.records,
              passes: creditPassListingStore.records,
              ...rest,
            };
          }}
        >
          <Header editing={!!this.eventId} />
          <SpinWhileLoading spinWhile="isLoading" renderHiddenContent>
            <TabContainer className="iphone-x-content" style={styles} />
          </SpinWhileLoading>
        </AltContainer>
      </main>
    );
  }
}

export default EventMgmtEditing;
