import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Divider from '@mui/material/Divider';

import { messageId } from 'shared/utils/LocaleUtils.js';

import AccountType from './AccountType.jsx';

const styles = {
  profileCreation: {
    fontSize: '1.5em',
    marginBottom: 15,
    marginTop: 20,
    color: '#393c45',
  },
};

function ClientHeaderText({ step, userCreationStore }) {
  if (step === 'accountCreation') {
    return (
      <div style={{ fontSize: '1.5em', color: '#393c45' }}>
        <div style={{ marginTop: 20, marginBottom: 20, fontWeight: 600 }}>
          <FormattedMessage
            id={messageId('.create_account_header', __filenamespace)}
          />
        </div>
        {!userCreationStore.managedProfiles.some(p => p.id) && (
          <div>
            <div style={{ marginBottom: 20 }}>
              <FormattedMessage
                id={messageId('.account_type', __filenamespace)}
              />
            </div>
            <AccountType userCreationStore={userCreationStore} />
          </div>
        )}
        <Divider />
      </div>
    );
  }

  if (step === 'profileCreation') {
    return (
      <div>
        <div style={styles.profileCreation}>
          <FormattedMessage
            id={messageId('.create_profile_header', __filenamespace)}
          />
        </div>
        <div style={{ fontSize: 16 }}>
          <FormattedMessage
            id={messageId('.create_profile_sub_header', __filenamespace)}
          />
          <div style={{ fontStyle: 'italic' }}>
            <FormattedMessage
              id={messageId('.create_sub_header', __filenamespace)}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
}

ClientHeaderText.propTypes = {
  step: PropTypes.string.isRequired,
  userCreationStore: PropTypes.object.isRequired,
};

export default ClientHeaderText;
