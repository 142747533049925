import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';

import MachineSettingsDrawerActions from './Actions';

class MachineSettingsDrawerStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      toggleDrawer: MachineSettingsDrawerActions.toggleDrawer,
      toggleMachineSettings: MachineSettingsDrawerActions.toggleMachineSettings,
      updateMachineSettingsSuccess:
        MachineSettingsDrawerActions.updateMachineSettingsSuccess,
      updateMachineSettingsError:
        MachineSettingsDrawerActions.updateMachineSettingsError,
    });
  }

  reset() {
    this.open = false;
    this.isLoading = false;
    this.machinesOptionDisabled = false;
  }

  toggleDrawer() {
    const { customer } = CurrentContextStore.getState();

    this.open = !this.open;
    this.machinesOptionDisabled = customer.getIn(
      ['preferences', 'hpp_machines_disabled'],
      false
    );
  }

  toggleMachineSettings() {
    this.machinesOptionDisabled = !this.machinesOptionDisabled;
    this.updateMachineSettings();
  }

  updateMachineSettings() {
    const { customer } = CurrentContextStore.getState();

    this.isLoading = true;
    uhApiClient.put({
      url: `/customers/${customer.get('id')}`,
      data: JSON.stringify({
        attributes: {
          ...customer,
          preferences: {
            ...customer.get('preferences').toJS(),
            hpp_machines_disabled: this.machinesOptionDisabled,
          },
        },
        fields: ['logo', 'legal_entity'],
      }),
      success: MachineSettingsDrawerActions.updateMachineSettingsSuccess,
      error: MachineSettingsDrawerActions.updateMachineSettingsError,
    });
  }

  updateMachineSettingsSuccess({ customer }) {
    const { preferences } = customer;

    this.machinesOptionDisabled = Boolean(preferences?.hpp_machines_disabled);
    this.isLoading = false;
  }

  updateMachineSettingsError(...args) {
    this.isLoading = false;
    this.notifyError('Error updating machine settings', args);
  }
}

export default alt.createStore(
  MachineSettingsDrawerStore,
  'MachineSettingsDrawerStore'
);
