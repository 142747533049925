import { List } from 'immutable';
import Membership from 'shared/records/Membership.jsx';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';
import DataStoreActions from './MembershipActions';

const parserTo = { type: Membership, paginationKey: 'memberships' };

const parser = new Parser(parserTo);

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess].concat(success || []))
    ),
    ...(error ? { error } : {}),
  });
};

export const postMembershipAgreement = ({ params, success, error }) => {
  uhApiClient.post({
    url: `clients/membership_agreement`,
    data: JSON.stringify(params),
    success: data => success(data),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const downloadCsv = ({ membershipID, params, success, error }) => {
  uhApiClient.get({
    url: `memberships/${membershipID}/subscribers_detail`,
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

export const getMembershipList = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'memberships',
    data: params,
    success: onSuccess(data => data, List([success])),
    error: onError(List([error])),
  });
};
