import AvailableTimesStore from 'event_mgmt/shared/stores/AvailableTimesStore.js';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';
import PurchaseDrawerActions from 'event_mgmt/display/actions/PurchaseDrawerActions.js';
import PDRegistrationPackageStore from 'event_mgmt/display/stores/PurchaseDrawerRegistrationPackageStore.jsx';

export default alt.createStore(
  AvailableTimesStore,
  'PurchaseDrawerAvailableTimesStore',
  {
    setEvent: EventActions.FETCH_SUCCESS,

    updateExistingScheduledTimes: [
      PDRegistrationPackageActions.REGISTRATION_BOOKED,
      PDRegistrationPackageActions.REGISTRATION_CANCELLED,
      CartActions.FETCH_SUCCESS,
      PurchaseDrawerActions.DRAWER_CLOSED,
      PDRegistrationPackageActions.ADD_ATTENDEE_CLICKED,
      PDRegistrationPackageActions.SCHEDULE_NOW_CLICKED,
      PDRegistrationPackageActions.REGISTRATION_CANCELLED,
    ],

    clearSelections: PDRegistrationPackageActions.SCHEDULE_NOW_CLICKED,

    handleStaffChange: PDRegistrationPackageActions.REGISTRATION_STAFF_CHANGED,
    handleDateChange: PDRegistrationPackageActions.REGISTRATION_DATE_CHANGED,
    handleTimeChange: PDRegistrationPackageActions.REGISTRATION_TIME_CHANGED,

    handleFetchAvailabilities:
      PDRegistrationPackageActions.FETCH_AVAILABILITIES,
  },
  EventStore.displayName,
  PDRegistrationPackageStore.displayName,
  CartStore.displayName
);
