import * as React from 'react';
import { FormattedData } from 'reporting/sales_detail/components/tables/_Rows.jsx';

export function TotalRow({ data, label }) {
  return (
    <tfoot>
      <tr style={{ height: 'auto' }}>
        <td>{label}</td>
        <td>
          <FormattedData data={data.get('amount')} />
        </td>
        <td>
          <FormattedData data={data.get('tax')} />
        </td>
        <td
          style={
            data.get('refunded_amount') === 0
              ? { color: 'inherit' }
              : { color: 'red' }
          }
        >
          <FormattedData data={data.get('refunded_amount')} />
        </td>
        <td
          style={
            data.get('refunded_tax') === 0
              ? { color: 'inherit' }
              : { color: 'red' }
          }
        >
          <FormattedData data={data.get('refunded_tax')} />
        </td>
        <td>
          <FormattedData data={data.get('total')} />
        </td>
      </tr>
    </tfoot>
  );
}

export function TotalRowExtended({ data, label }) {
  return (
    <tfoot>
      <tr style={{ height: 'auto' }}>
        <td>{label}</td>
        <td>
          <FormattedData data={data.get('amount')} />
        </td>
        <td>
          <FormattedData data={data.get('tax')} />
        </td>
        <td>
          {!data.get('quantity') || data.get('quantity') === 0.0
            ? '-'
            : data.get('quantity')}
        </td>
        <td
          style={
            data.get('refunded_amount') === 0
              ? { color: 'inherit' }
              : { color: 'red' }
          }
        >
          <FormattedData data={data.get('refunded_amount')} />
        </td>
        <td
          style={
            data.get('refunded_tax') === 0
              ? { color: 'inherit' }
              : { color: 'red' }
          }
        >
          <FormattedData data={data.get('refunded_tax')} />
        </td>
        <td>
          {!data.get('refunded_quantity') ||
          data.get('refunded_quantity') === 0.0
            ? '-'
            : data.get('refunded_quantity')}
        </td>
        <td>
          <FormattedData data={data.get('total')} />
        </td>
      </tr>
    </tfoot>
  );
}
