import React from 'react';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import ReactTooltip from 'react-tooltip';

const styles = {
  creditContainer: {
    padding: '10px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  creditText: {
    fontSize: '13px',
    color: uhColors.textGrey,
    fontWeight: '400',
  },
  availableCredit: {
    color: uhColors.charcoalBlack,
    fontSize: '14px',
    fontWeight: '600',
  },
  toolContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolTipPoint: {
    height: '9px',
    width: '9px',
    borderRadius: '10px',
    background: '#fc9403',
    marginRight: '3px',
    cursor: 'pointer',
  },
};

function AvailableCredits({
  numberOfAvailableCredits,
  numberOfSessions,
  sessions,
}) {
  const creditsCount = Number(numberOfAvailableCredits);
  const sessionsCount = Number(numberOfSessions);

  const getTooltipCredits = () => {
    if (creditsCount >= sessionsCount) {
      return `${sessionsCount}/${creditsCount} Credits Used`;
    }

    if (creditsCount < sessionsCount) {
      return `Need to buy ${sessionsCount - creditsCount} Credit`;
    }

    if (creditsCount === 0) {
      return '0 Available Credits';
    }

    return null;
  };

  const getAvailableCredits = () => {
    if (creditsCount >= sessionsCount || creditsCount < sessionsCount) {
      return `${creditsCount} Available Credits`;
    }

    if (creditsCount === 0) {
      return '0 Available Credits';
    }

    return null;
  };

  return (
    <div>
      {sessions ? ( // calculate the number of sessions and credits
        <div style={styles.creditContainer}>
          <pre style={styles.creditText}>{numberOfSessions} Sessions</pre>
          <div style={styles.toolContainer}>
            <div
              style={styles.toolTipPoint}
              data-tip="msg to show"
              data-for="smileFace"
            />
            <ReactTooltip id="smileFace" type="warning" effect="solid">
              <span>{getTooltipCredits()}</span>
            </ReactTooltip>
            <span style={styles.availableCredit}>{getAvailableCredits()}</span>
          </div>
        </div>
      ) : (
        <div style={styles.creditContainer}>
          <span style={styles.creditText}>Complete series details below,</span>
          <span style={styles.availableCredit}>
            {numberOfAvailableCredits} credits Available
          </span>
        </div>
      )}
    </div>
  );
}

export default AvailableCredits;
