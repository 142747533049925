export default alt.generateActions(
  'backButtonClicked',
  'completeButtonClicked',
  'profileCreated',
  'registrationAthleteChanged',
  'registrationBooked',
  'registrationCancelled',
  'registrationStaffChanged',
  'registrationDateChanged',
  'registrationTimeChanged',
  'registrationBooked',
  'fetchAvailabilities',
  'setRepeatBookingRegistrationPackage'
);
