import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import ContactImportActions from 'contacts/index/actions/ContactImportActions.js';
import ContactsClientsActions from 'containers/contacts/contactsClients/Actions';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const onImportClick = () => ContactImportActions.drawerOpened();
const onExportClick = () => ContactsClientsActions.downloadList();

function ButtonActions({ disableExport }) {
  return (
    <Grid
      container
      spacing={1}
      justify="flex-end"
      className="contacts__abs-button-container"
    >
      <div className="btn-wrapper">
        <Button
          color="dark"
          onClick={onImportClick}
          startIcon={
            <CloudUploadIcon
              style={{ height: 20, width: 20, color: uhColors.activeBlue }}
            />
          }
        >
          <FormattedMessage id={messageId('.import', __filenamespace)} />
        </Button>
        <Button
          color="dark"
          disabled={disableExport}
          onClick={onExportClick}
          startIcon={
            <CloudDownloadIcon
              style={{ height: 20, width: 20, color: uhColors.activeBlue }}
            />
          }
        >
          <FormattedMessage id={messageId('.export', __filenamespace)} />
        </Button>
      </div>
    </Grid>
  );
}

function MenuActions() {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onImportClick}>
          {t('.import', intl, __filenamespace)}
        </MenuItem>
        <MenuItem onClick={onExportClick}>
          {t('.export', intl, __filenamespace)}
        </MenuItem>
      </Menu>
    </>
  );
}

function ActionButtons({ appliedFilters, selectedTab, disableExport }) {
  return (
    <ResponsiveElement
      largeScreen={
        <ButtonActions
          appliedFilters={appliedFilters}
          selectedTab={selectedTab}
          disableExport={disableExport}
        />
      }
      smallScreen={
        <MenuActions
          appliedFilters={appliedFilters}
          selectedTab={selectedTab}
        />
      }
    />
  );
}

ActionButtons.propTypes = {
  appliedFilters: PropTypes.instanceOf(ContactFilter),
  selectedTab: PropTypes.number,
  disableExport: PropTypes.bool,
};

ActionButtons.defaultProps = {
  appliedFilters: new ContactFilter(),
  selectedTab: 0,
  disableExport: false,
};

export default ActionButtons;
