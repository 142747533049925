import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';

import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

function Header({ intl }) {
  return (
    <div className="closeout-report__orders-list-header">
      <Grid container>
        <Grid
          item
          container
          xs={12}
          spacing={1}
          alignContent="center"
          justify="center"
        >
          <Grid item md={2} sm={2}>
            <Typography
              variant="body3"
              className="closeout-report__orders-list-header-item"
            >
              {t('.date_time', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2}>
            <Typography
              variant="body3"
              className="closeout-report__orders-list-header-item"
            >
              {t('.order_number', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2}>
            <Typography
              variant="body3"
              className="closeout-report__orders-list-header-item"
            >
              {t('.buyer', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2}>
            <Typography
              variant="body3"
              className="closeout-report__orders-list-header-item"
            >
              {t('.product', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2}>
            <Typography
              variant="body3"
              className="closeout-report__orders-list-header-item"
            >
              {t('.payment_method', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2}>
            <Typography
              variant="body3"
              className="closeout-report__orders-list-header-item"
            >
              {t('.amount', intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default compose(memo, injectIntl)(Header);
