import moment from 'moment-timezone';
import { Record, List } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import CreditPassCredit from 'shared/records/CreditPassCredit.js';

import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

export const CREDIT_PASS_EXPIRATION_STRATEGIES = {
  never: 'never',
  days_from_purchase: 'days_from_purchase',
  date: 'date',
  days_from_first_use: 'days_from_first_use',
};

export default class CreditPass extends Record({
  id: null,
  active_client_count: 0,
  credit_pass_credits: List(),
  description: '',
  errors: new FieldErrors(),
  expiration_days: null,
  expiration_strategy: CREDIT_PASS_EXPIRATION_STRATEGIES.never,
  expires_at: null,
  max_num_of_purchases: 0,
  name: '',
  price: 0,
  product_type: '',
  published: true,
  hide_in_pos: false,
}) {
  constructor(obj = {}) {
    const errors = new FieldErrors(obj.errors);

    const creditPassCredits = List(
      (obj.credit_pass_credits || []).map(
        credit => new CreditPassCredit(credit)
      )
    );

    const expiresAt = obj.expires_at
      ? moment.tz(obj.expires_at, customerTZ())
      : null;

    super(
      merge(obj, {
        errors,
        credit_pass_credits: creditPassCredits,
        expires_at: expiresAt,
      })
    );
  }

  isValid() {
    return (
      this.errors.isEmpty() &&
      this.credit_pass_credits.every(credit => credit.isValid())
    );
  }

  validate() {
    let errors = new FieldErrors();

    ['name', 'description', 'price'].forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });

    // because of type "casting" of empty strings we need to check zero independent of the <=
    if (
      this.max_num_of_purchases <= 0 &&
      this.max_num_of_purchases !== '0' &&
      this.max_num_of_purchases !== 0
    ) {
      errors = errors.add(
        'max_num_of_purchases',
        'records.CreditPass.errors.max_num_of_purchases_should_be_positive'
      );
    }

    if (
      this.expiration_strategy === CREDIT_PASS_EXPIRATION_STRATEGIES.date &&
      !this.expires_at
    ) {
      errors = errors.add('expires_at', 'records.errors.required');
    }

    if (
      (this.expiration_strategy ===
        CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_first_use ||
        this.expiration_strategy ===
          CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_purchase) &&
      !this.expiration_days
    ) {
      errors = errors.add('expiration_days', 'records.errors.required');
    }

    const creditPassCredits = this.credit_pass_credits.map(credit =>
      credit.validate()
    );

    return this.merge({
      errors,
      credit_pass_credits: creditPassCredits,
    });
  }

  addCredit(attrs = {}) {
    return this.update('credit_pass_credits', (list = List()) =>
      list.push(new CreditPassCredit(attrs))
    );
  }

  removeCredit({ index, id }) {
    if (index != null) {
      return this.deleteIn(['credit_pass_credits', index]);
    }
    const i = this.credit_pass_credits.findIndex(d => d.id === id);
    return i >= 0 ? this.deleteIn(['credit_pass_credits', i]) : this;
  }

  url({ relative = false } = {}) {
    const parser = document.createElement('a');

    parser.href = customerScopedRoute('credit_passes');
    parser.hash = this.id ? `#pdo${this.id}` : '';

    return relative
      ? `${parser.pathname}${parser.hash}`
      : `${window.app_host || parser.host}${parser.pathname}${parser.hash}`;
  }

  /**
   * Given that credit passes can be a very variable length (spanning from days
   * to months), giving a revenue per month value does not quite make sense.
   * But, a total revenue value can make sense.
   * It can simply equal the total number of people who have ever used the credit
   * pass times the price.
   */
  customerUserCount() {
    return this.active_client_count;
  }

  revenue() {
    return this.customerUserCount() * this.price;
  }

  /**
   * Required for the listing, this returns the sum of the quantity of each credit pass
   * credit that this credit pass contains.
   */
  totalCredits() {
    return this.credit_pass_credits.reduce((total, credit) => {
      const quantity = credit.unlimited ? Infinity : credit.quantity;

      return total + quantity;
    }, 0);
  }

  toServer() {
    const obj = this.toJS();

    obj.expires_at = this.expires_at ? this.expires_at.format() : null;

    return obj;
  }
}
