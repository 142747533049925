import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import RevenueReportStore from 'reporting/revenue/stores/_RevenueReportStore.jsx';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';
import { l, t } from 'shared/utils/LocaleUtils.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

class CumulativeTransactionsByMonth extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { revenueReportStore: current } = this.props;
    const next = nextProps.revenueReportStore;
    const statusChanged =
      current.isTotalsByMonthLoading !== next.isTotalsByMonthLoading;
    const resultChanged =
      current.cumulativeTransactionCountsByMonth !==
      next.cumulativeTransactionCountsByMonth;
    return statusChanged || resultChanged;
  }

  getMonthLabel(value) {
    const { intl } = this.props;
    const year = Math.floor(value / 12);
    const month = value % 12;
    const date = new Date(year, month - 1, 10);
    return l(date, '.dateFormat', intl, __filenamespace);
  }

  getLabels() {
    const { revenueReportStore: store } = this.props;
    return store.cumulativeTransactionCountsByMonth.map(r =>
      this.getMonthLabel(r.month)
    );
  }

  getDatasets() {
    const { revenueReportStore: store } = this.props;
    return [
      {
        fill: 'origin',
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: store.cumulativeTransactionCountsByMonth.map(r => r.count),
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  getChartOptions() {
    return {
      scaleShowValues: true,
      scales: {
        x: {
          ticks: {
            padding: 5,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }

  render() {
    const { revenueReportStore: store, intl } = this.props;
    const data = {
      labels: this.getLabels(),
      datasets: this.getDatasets(),
    };
    const options = this.getChartOptions();

    return (
      <Widget
        title={t('.title', intl, __filenamespace)}
        style={{ width: '100%', overflowX: 'auto' }}
        isLoading={store.isTotalsByMonthLoading}
      >
        <div style={{ position: 'relative' }}>
          <Line data={data} options={options} width={100} height={50} />
        </div>
      </Widget>
    );
  }
}

function CumulativeTransactionsByMonthWrapper({ intl }) {
  return (
    <AltContainer stores={{ revenueReportStore: RevenueReportStore }}>
      <CumulativeTransactionsByMonth intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(CumulativeTransactionsByMonthWrapper);
