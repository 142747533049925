import * as React from 'react';
import { Expander, Typography } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import altContainer from 'shared/hocs/altContainer.jsx';
import Staff from 'shared/records/Staff.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { StaffDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';

import { withStyles } from '@material-ui/styles';
import AddStaff from './AddStaff.jsx';
import StaffListCard from './StaffListCard.jsx';
import DetailsActions from '../actions.js';

const styles = {
  container: {
    margin: 10,
  },
  headerTypographyRoot: {
    maxWidth: '55px',
  },
};

function StaffList({
  expanded,
  intl,
  isAddingStaff,
  staffDataStore: { staff: allStaff },
  selectableStaff,
  staffIds,
  searchedStaffName,
  isTeamEvent,
  classes,
}) {
  const canRemove = staffIds.size > 1;

  if (isTeamEvent) {
    return (
      <Expander
        classes={{
          headerTypographyRoot: classes.headerTypographyRoot,
        }}
        header={
          <Typography variant="subtitle2">
            {t('.team_staff', intl, __filenamespace, {
              count: staffIds.size,
            })}
          </Typography>
        }
        expanded={expanded}
        behavior="controlled"
        onChange={DetailsActions.staffChangedTeam}
        contentOffset={false}
        arrowRight
      >
        {staffIds.map(s => {
          const staff = allStaff.get(s, new Staff());
          return (
            <Box
              sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}
            >
              <Box>
                <UserAvatar user={staff} />
              </Box>
              <Box>
                <Box sx={{ fontWeight: 600 }}>{staff.name()}</Box>
                <Box>{staff.email}</Box>
              </Box>
            </Box>
          );
        })}
      </Expander>
    );
  }
  return (
    <Expander
      header={
        <Typography variant="subtitle2">
          {t('.staff', intl, __filenamespace, {
            count: staffIds.size,
          })}
        </Typography>
      }
      secondaryAction={DetailsActions.initStaffAdd}
      secondaryActionIcon="add"
      expanded={expanded}
      behavior="controlled"
      onChange={DetailsActions.staffChanged}
      contentOffset={false}
    >
      {isAddingStaff && (
        <div style={styles.container}>
          <AddStaff
            intl={intl}
            selectableStaff={selectableStaff}
            searchedStaffName={searchedStaffName}
          />
        </div>
      )}
      {staffIds.map(s => {
        const staff = allStaff.get(s, new Staff());
        const onRemove = canRemove
          ? () => DetailsActions.removeStaff(staff.id)
          : undefined;

        return (
          <StaffListCard
            key={staff.id}
            avatar={<UserAvatar user={staff} size={24} />}
            intl={intl}
            name={staff.name()}
            onRemove={onRemove}
          />
        );
      })}
    </Expander>
  );
}

export default altContainer({
  stores: {
    staffDataStore: StaffDataStore,
  },
})(withStyles(styles)(StaffList));
