import CreditPass from 'shared/records/CreditPass.js';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCreditPassActions from 'point_of_sale/actions/POSCreditPassActions.js';
import POSCreditPassPackageActions from 'point_of_sale/actions/POSCreditPassPackageActions.js';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { CreditPassSource } from 'sources';

class POSCreditPassStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      selectCreditPass: POSProductListActions.CREDIT_PASS_SELECTED,

      fetchSuccess: POSCreditPassActions.FETCH_SUCCESS,
      fetchError: POSCreditPassActions.FETCH_ERROR,

      loadOrderItem: [
        POSCartActions.ITEM_EDIT_SELECTED,
        POSCartActions.ITEM_VIEW_SELECTED,
      ],

      reset: [
        POSCreditPassPackageActions.CANCEL_CLICKED,
        POSCreditPassPackageActions.VIEW_IN_CART_CLICKED,
      ],
    });
  }

  reset() {
    this.creditPass = new CreditPass();
  }

  selectCreditPass(creditPass) {
    this.creditPass = creditPass;
  }

  fetch(id) {
    CreditPassSource.fetch({
      id,
      success: POSCreditPassActions.fetchSuccess,
      error: POSCreditPassActions.fetchError,
    });
  }

  loadOrderItem(orderItem) {
    if (orderItem.isCreditPassItem()) {
      this.fetch(orderItem.orderable.credit_pass_id);
    }
  }

  fetchSuccess(creditPass) {
    this.selectCreditPass(creditPass);
  }

  fetchError() {
    this.notifyError('error fetching credit pass', arguments);
  }
}

export default alt.createStore(POSCreditPassStore, 'POSCreditPassStore');
