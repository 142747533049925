class RosterTransferDrawerActions {
  constructor() {
    this.generateActions(
      'fetchEventList',
      'listSuccess',
      'listError',
      'toggleTransferDrawer',
      'setCurrentSelectedEvent',
      'handleRosterTransfer',
      'rosterTransferSuccess',
      'rosterTransferError'
    );
  }
}

export default alt.createActions(RosterTransferDrawerActions);
