/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import AccountingCodeArchivingActions from 'shared/actions/accounting_codes/AccountingCodeArchivingActions';
import AccountingCodeArchivingStore from 'shared/stores/accounting_codes/AccountingCodeArchivingStore';
import AccountingCodeCreationActions from 'shared/actions/accounting_codes/AccountingCodeCreationActions';
import AccountingCodeCreationStore from 'shared/stores/accounting_codes/AccountingCodeCreationStore';
import AccountingCodeEditingActions from 'shared/actions/accounting_codes/AccountingCodeEditingActions';
import AccountingCodeEditingStore from 'shared/stores/accounting_codes/AccountingCodeEditingStore';
import AccountingCodeListingStore from 'shared/stores/accounting_codes/AccountingCodeListingStore';
import ServiceFeeCreationStore from 'shared/stores/service_fee/ServiceFeeCreationStore';
import RetailCategoryList from 'customers/settings/components/accounting_tab/RetailCategoryList.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import TaxRateCreationStore from 'shared/stores/tax_rates/TaxRateCreationStore';
import FeeRateCreationStore from 'shared/stores/fee_rates/FeeRateCreationStore';
import TaxRateEditingStore from 'shared/stores/tax_rates/TaxRateEditingStore';
import TaxRateListingStore from 'shared/stores/tax_rates/TaxRateListingStore';
import AddButton from 'shared/components/AddButton.jsx';
import CardMenu from 'shared/components/CardMenu.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import Expander from 'shared/components/Expander.jsx';
import ExplanatoryText from 'shared/components/_ExplanatoryText.jsx';
import SelectedEventType from 'shared/components/SelectedEventType.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import ServiceFeeCreationActions from 'shared/actions/service_fee/ServiceFeeCreationActions.js';
import ServiceFeeListingStore from 'shared/stores/service_fee/ServiceFeeListingStore.js';
import FeeRateEditingStore from 'shared/stores/fee_rates/FeeRateEditingStore.js';
import FeeRateListingStore from 'shared/stores/fee_rates/FeeRateListingStore.js';
import ServiceFeeArchivingStore from 'shared/stores/service_fee/ServiceFeeArchivingStore.js';
import ServiceFeeEditingStore from 'shared/stores/service_fee/ServiceFeeEditingStore.js';
import ServiceFeeEditingActions from 'shared/actions/service_fee/ServiceFeeEditingActions.js';
import ServiceFeeArchivingActions from 'shared/actions/service_fee/ServiceFeeArchivingActions.js';
import SelectedTeamType from 'shared/components/SelectedTeamType.jsx';
import AccountingForm from './accounting_tab/AccountingForm.jsx';

const styles = {
  root: {
    margin: 30,
    maxWidth: SINGLE_COLUMN_WIDTH,
  },

  accountingCodeCard: {
    padding: 16,
    position: 'relative',
  },

  header: {
    fontSize: 22,
    marginBottom: 20,
  },
};

function ArchivalConfirmation({ accountingCode, confirmationOpen, intl }) {
  return (
    <ConfirmationDialog
      awaitingConfirmation={confirmationOpen}
      onCancel={AccountingCodeArchivingActions.archiveAborted}
      onConfirm={AccountingCodeArchivingActions.archiveConfirmed}
      title={t('.archive_accounting_code', intl, __filenamespace)}
    >
      <div style={{ marginBottom: 20 }}>
        <FormattedMessage
          id={messageId('.are_you_sure', __filenamespace)}
          values={{ code: accountingCode.code }}
        />
      </div>
    </ConfirmationDialog>
  );
}
function ArchivalConfirmationService({ serviceFee, confirmationOpen, intl }) {
  return (
    <ConfirmationDialog
      awaitingConfirmation={confirmationOpen}
      onCancel={ServiceFeeArchivingActions.archiveAborted}
      onConfirm={ServiceFeeArchivingActions.archiveConfirmed}
      title={t('.archive_service_fee', intl, __filenamespace)}
    >
      <div style={{ marginBottom: 20 }}>
        <FormattedMessage
          id={messageId('.are_you_sure_fee', __filenamespace)}
          values={{ code: serviceFee?.code }}
        />
      </div>
    </ConfirmationDialog>
  );
}

const onCreateAccountingCodeClick = () => AccountingCodeCreationActions.begin();
const onCreateServiceFeeClick = () => ServiceFeeCreationActions.begin();

function AccountingCard({
  cardData,
  style,
  intl,
  accountingType,
  onEditClick,
  onArchiveClick,
}) {
  return (
    <Paper style={merge(styles.accountingCodeCard, style)}>
      {!cardData?.archived && (
        <CardMenu>
          <MenuItem onClick={onEditClick}>
            {t('actions.edit', intl, __filenamespace)}
          </MenuItem>
          <MenuItem onClick={onArchiveClick}>
            {t('actions.archive', intl, __filenamespace)}
          </MenuItem>
        </CardMenu>
      )}

      <div style={{ fontWeight: 'bold', marginBottom: 4 }}>
        {cardData?.code}
      </div>
      <div style={{ fontSize: 13, marginBottom: 20 }}>
        <FormattedMessage
          id={messageId(`.${cardData?.code_type}_code_type`, __filenamespace)}
        />
      </div>

      <FlexBox style={{ marginBottom: 20 }}>
        <div style={{ flex: '0 0 35%' }}>
          <FormattedMessage
            id={messageId(`.${accountingType}`, __filenamespace)}
          />
        </div>

        <div style={{ flex: '0 0 65%' }}>
          {cardData?.get(`${accountingType}s`).map(c => (
            <FlexBox style={{ marginBottom: 5 }} key={c.id}>
              <div style={{ flex: '0 0 80%', fontWeight: 'bold' }}>
                <span>{c.name}</span>
              </div>
              <div style={{ flex: '0 0 20%', textAlign: 'end' }}>
                {/* eslint-disable react/style-prop-object */}
                <FormattedNumber
                  value={c.rate / 100}
                  style="percent"
                  maximumFractionDigits={2}
                />
                {/* eslint-enable react/style-prop-object */}
              </div>
            </FlexBox>
          ))}
        </div>
      </FlexBox>

      {cardData?.event_types.map(et => (
        <SelectedEventType
          key={et.id}
          eventType={et}
          style={{ fontWeight: 'bold' }}
        />
      ))}

      {cardData?.team_types.map(type => (
        <SelectedTeamType
          key={type.id}
          teamType={type}
          style={{ fontWeight: 'bold' }}
        />
      ))}

      <RetailCategoryList accountingCode={cardData} />

      {cardData?.description && (
        <div>
          <Divider style={{ marginBottom: 20 }} />
          <div style={{ lineHeight: 1.2 }}>{cardData?.description}</div>
        </div>
      )}
    </Paper>
  );
}

const AccountingCodeList = injectIntl(
  ({
    accountingCodeEditingStore,
    taxRateEditingStore,
    taxRateCreationStore,
    accountingCodeListingStore: { accountingCodes, archivedCodes, isLoading },
    intl,
  }) => (
    <SpinWhileLoading
      isLoading={isLoading}
      outerContainerStyle={styles.accountingCodeCard}
    >
      {accountingCodes.map(c =>
        accountingCodeEditingStore.accountingCode &&
        accountingCodeEditingStore.accountingCode.id === c.id ? (
          <AccountingForm
            key={`accounting-code-form-${c.id}}`}
            accountingStore={accountingCodeEditingStore}
            rateCreationStore={taxRateCreationStore}
            rateEditingStore={taxRateEditingStore}
            style={{ marginBottom: 10 }}
            intl={intl}
          />
        ) : (
          <AccountingCard
            key={`accounting-code-card-${c.id}}`}
            cardData={c}
            style={{ marginBottom: 10 }}
            intl={intl}
            accountingType="tax_rate"
            onEditClick={() => AccountingCodeEditingActions.begin(c)}
            onArchiveClick={() =>
              AccountingCodeArchivingActions.archiveRequested(c)
            }
          />
        )
      )}

      {archivedCodes.size > 0 && (
        <Expander
          label={t('.archived', intl, __filenamespace, {
            count: archivedCodes.size,
          })}
          labelContainerStyle={{
            color: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'bold',
            marginBottom: 8,
          }}
          style={{ marginBottom: 10 }}
        >
          {archivedCodes.map(c => (
            <AccountingCard
              key={`accounting-code-card-${c.id}}`}
              cardData={c}
              readOnly
              style={{ marginBottom: 10 }}
              intl={intl}
              accountingType="tax_rate"
            />
          ))}
        </Expander>
      )}
    </SpinWhileLoading>
  )
);
const ServiceFeeList = injectIntl(
  ({
    serviceFeeEditingStore,
    feeRateEditingStore,
    feeRateCreationStore,
    serviceFeeListingStore: { serviceFeesList, archivedCodes, isLoading },
    intl,
  }) => (
    <SpinWhileLoading
      isLoading={isLoading}
      outerContainerStyle={styles.accountingCodeCard}
    >
      {serviceFeesList &&
        serviceFeesList.map(c =>
          serviceFeeEditingStore?.serviceFee &&
          serviceFeeEditingStore?.serviceFee?.id === c.id ? (
            <AccountingForm
              key={`accounting-service-fee-form-${c.id}}`}
              accountingStore={serviceFeeEditingStore}
              rateCreationStore={feeRateCreationStore}
              rateEditingStore={feeRateEditingStore}
              isFeeRate
              style={{ marginBottom: 10 }}
              intl={intl}
            />
          ) : (
            <AccountingCard
              key={`accounting-service-fee-card-${c.id}}`}
              cardData={c}
              style={{ marginBottom: 10 }}
              intl={intl}
              accountingType="fee_rate"
              onEditClick={() => ServiceFeeEditingActions.begin(c)}
              onArchiveClick={() =>
                ServiceFeeArchivingActions.archiveRequested(c)
              }
            />
          )
        )}

      {archivedCodes.size > 0 && (
        <Expander
          label={t('.archived', intl, __filenamespace, {
            count: archivedCodes.size,
          })}
          labelContainerStyle={{
            color: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'bold',
            marginBottom: 8,
          }}
          style={{ marginBottom: 10 }}
        >
          {archivedCodes.map(c => (
            <AccountingCard
              key={`service-fee-code-card-${c.id}`}
              cardData={c}
              readOnly
              style={{ marginBottom: 10 }}
              intl={intl}
              accountingType="fee_rate"
            />
          ))}
        </Expander>
      )}
    </SpinWhileLoading>
  )
);
function ContentHeader({ header, explantion, intl }) {
  return (
    <div style={{ height: '130px' }}>
      <h2 style={styles.header}>
        <FormattedMessage id={messageId(header, __filenamespace)} />
      </h2>

      <ExplanatoryText
        text={t(explantion, intl, __filenamespace)}
        style={{ marginBottom: 30 }}
      />
    </div>
  );
}
function AccountingCodeTabContent({
  accountingCodeArchivingStore,
  accountingCodeCreationStore,
  accountingCodeEditingStore,
  accountingCodeListingStore,
  taxRateCreationStore,
  taxRateEditingStore,
  taxRateListingStore,
  intl,
}) {
  return (
    <div style={styles.root}>
      <ContentHeader
        intl={intl}
        header=".accounting_codes"
        explantion=".accounting_explanation"
      />

      {accountingCodeCreationStore.accountingCode ? (
        <AccountingForm
          accountingStore={accountingCodeCreationStore}
          rateCreationStore={taxRateCreationStore}
          rateEditingStore={taxRateEditingStore}
          style={{ marginBottom: 20 }}
          intl={intl}
        />
      ) : (
        <AddButton
          label={t('.create_accounting_code', intl, __filenamespace)}
          style={{ marginBottom: 30 }}
          onClick={onCreateAccountingCodeClick}
        />
      )}

      <AccountingCodeList
        accountingCodeEditingStore={accountingCodeEditingStore}
        accountingCodeListingStore={accountingCodeListingStore}
        taxRateListingStore={taxRateListingStore}
        taxRateEditingStore={taxRateEditingStore}
        taxRateCreationStore={taxRateCreationStore}
      />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ArchivalConfirmation {...accountingCodeArchivingStore} intl={intl} />
    </div>
  );
}
function AccountingServiceFeeTabContent({
  serviceFeeArchivingStore,
  serviceFeeCreationStore,
  serviceFeeEditingStore,
  serviceFeeListingStore,
  feeRateCreationStore,
  feeRateEditingStore,
  feeRateListingStore,
  intl,
}) {
  return (
    <div style={styles.root}>
      <ContentHeader
        intl={intl}
        header=".service_fee"
        explantion=".service_fee_explanation"
      />
      {serviceFeeCreationStore?.serviceFee ? (
        <AccountingForm
          accountingStore={serviceFeeCreationStore}
          rateCreationStore={feeRateCreationStore}
          rateEditingStore={feeRateEditingStore}
          isFeeRate
          style={{ marginBottom: 20 }}
          intl={intl}
        />
      ) : (
        <AddButton
          label={t('.create_service_fee', intl, __filenamespace)}
          style={{ marginBottom: 30 }}
          onClick={onCreateServiceFeeClick}
        />
      )}

      <ServiceFeeList
        serviceFeeEditingStore={serviceFeeEditingStore}
        serviceFeeListingStore={serviceFeeListingStore}
        feeRateListingStore={feeRateListingStore}
        feeRateEditingStore={feeRateEditingStore}
        feeRateCreationStore={feeRateCreationStore}
      />

      <ArchivalConfirmationService {...serviceFeeArchivingStore} intl={intl} />
    </div>
  );
}
function AccountingTab({ intl }) {
  return (
    <div style={{ display: 'flex' }}>
      <AltContainer
        stores={{
          accountingCodeListingStore: AccountingCodeListingStore,
          accountingCodeCreationStore: AccountingCodeCreationStore,
          accountingCodeEditingStore: AccountingCodeEditingStore,
          accountingCodeArchivingStore: AccountingCodeArchivingStore,
          retailCategoryListingStore: RetailCategoryListingStore,
          taxRateCreationStore: TaxRateCreationStore,
          taxRateEditingStore: TaxRateEditingStore,
          taxRateListingStore: TaxRateListingStore,
        }}
      >
        <AccountingCodeTabContent intl={intl} />
      </AltContainer>
      <AltContainer
        stores={{
          serviceFeeListingStore: ServiceFeeListingStore,
          serviceFeeCreationStore: ServiceFeeCreationStore,
          serviceFeeEditingStore: ServiceFeeEditingStore,
          serviceFeeArchivingStore: ServiceFeeArchivingStore,
          retailCategoryListingStore: RetailCategoryListingStore,
          feeRateCreationStore: FeeRateCreationStore,
          feeRateEditingStore: FeeRateEditingStore,
          feeRateListingStore: FeeRateListingStore,
        }}
      >
        <AccountingServiceFeeTabContent intl={intl} />
      </AltContainer>
    </div>
  );
}

export default injectIntl(AccountingTab);
