import { List, Map } from 'immutable';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';
import FilterStoreImpl from 'event_mgmt/index/stores/_FilterStoreImplementation.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';

class FilterStore extends FilterStoreImpl {
  constructor() {
    super({
      toggleFilterDrawer: FilterActions.toggleFilterDrawer,
      updateFilterDrawerOpen: FilterActions.updateFilterDrawerOpen,
      updateStatusFilters: FilterActions.updateStatusFilters,
      updateTitleFilter: FilterActions.updateTitleFilter,
      updateTypeFilters: FilterActions.updateTypeFilters,
      resetFilters: StoreActions.PREPARE_FOR_REUSE,
      updateScheduleTypeFilter: FilterActions.updateScheduleTypeFilter,
    });

    this.statusFilters = Map({
      all: false,
      active: true,
      draft: true,
      completed: true,
      cancelled: false,
      deleted: false,
    });

    this.eventTypes = List();
    this.eventStatusFilters = [
      { name: 'All', filterKey: 'all' },
      { name: 'Active', filterKey: 'active' },
      { name: 'Draft', filterKey: 'draft' },
      { name: 'Completed', filterKey: 'completed' },
      { name: 'Cancelled', filterKey: 'cancelled' },
      { name: 'Deleted', filterKey: 'deleted' },
    ];
  }

  resetFilters() {
    super.resetFilters();
    this.resetTypeFilters();
  }

  resetTypeFilters() {
    if (this.eventTypes && !this.eventTypes.isEmpty()) {
      this.typeFilters = Map({ all: true }).withMutations(filterSet =>
        this.eventTypes.forEach(type => filterSet.set(type.id, true))
      );
    }
  }

  handleEventTypeList() {
    this.waitFor(EventTypeListingStore);
    const { isLoading, eventTypes } = EventTypeListingStore.getState();
    if (!isLoading) {
      this.eventTypes = eventTypes;
      this.resetTypeFilters();
    }
  }
}

export default alt.createStore(FilterStore, 'FilterStore');
