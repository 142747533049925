import * as React from 'react';
import { injectIntl } from 'react-intl';

import { blue } from '@mui/material/colors';

import Tabs from 'components/Tabs/Tabs.jsx';

import DaytimesExpander from 'event_mgmt/display/components/_DaytimesExpander.jsx';
import EventAthletes from 'event_mgmt/display/components/_EventAthletes.jsx';
import EventDescription from 'event_mgmt/display/components/_EventDescription.jsx';
import EventImage from 'event_mgmt/display/components/_EventImage.jsx';
import EventRestrictions from 'event_mgmt/display/components/_EventRestrictions.jsx';
import EventSchedule from 'event_mgmt/display/components/_EventSchedule.jsx';
import EventWaitingList from 'event_mgmt/display/components/_EventWaitingList.jsx';
import MembershipDiscountList from 'event_mgmt/display/components/_MembershipDiscountList.jsx';
import Location from 'event_mgmt/display/components/_Location.jsx';
import EventSeasons from 'event_mgmt/display/components/_EventSeasons.jsx';
import ScheduleButtons from 'shared/components/registration/ScheduleButtons.jsx';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';
import StaffList from 'event_mgmt/display/components/_StaffList.jsx';
import uhTheme from '_uh_theme.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    fontFamily: uhTheme.fontFamily,
    margin: '2rem auto 0 auto',
    width: '90%',
  },

  almostFull: {
    border: '1px solid var(--color-unpaid-red)',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
    padding: '1em',
    fontSize: '16px',
  },

  bottomDivider: {
    paddingBottom: 20,
    borderBottom: `2px solid ${uhColors.dividerGrey}`,
  },

  literal: `
    a { color: ${blue[300]}; }
    .event-description { font-family: ${uhTheme.fontFamily}; line-height: 1.7em; font-size: 15px; }
  `,

  tabLabel: {
    textTransform: 'capitalize',
  },
};

function Registrations({
  athleteStore,
  clientCredits,
  currentRegistrations,
  enrolledAthletes,
  event,
  eventStaffStore,
  purchased,
  registrations,
  sessions,
  waitlist,
  clients,
}) {
  return (
    <div style={styles.root}>
      <style
        type="text/css"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: styles.literal }}
      />
      {!event.isOpenBooking() && (
        <DaytimesExpander
          style={{ marginBottom: 20 }}
          daytimes={event.schedules.first().availability_schedule.dailyHours()}
        />
      )}
      {event.isOpenBooking() &&
        !currentCustomer().disable_client_scheduling && (
          <ScheduleButtons
            event={event}
            clientCredits={clientCredits}
            profiles={athleteStore.allAthletes}
            registrations={currentRegistrations}
            onClientSelect={client =>
              SchedulingDrawerActions.clientSelected(client)
            }
            style={{ marginBottom: 20 }}
          />
        )}
      {!event.isOpenBooking() && (
        <EventAthletes
          athletes={enrolledAthletes}
          style={{ marginBottom: 20 }}
        />
      )}
      {isLoggedIn() && purchased && (
        <EventSchedule
          allowSingleSessionPurchase={event.allow_single_session_purchase}
          athletes={athleteStore}
          clients={clients}
          event={event}
          registrations={registrations}
          sessions={sessions}
          staff={eventStaffStore.staff}
          style={merge(styles.bottomDivider, { paddingBottom: 10 })}
        />
      )}
      {event.allow_waitlist && (
        <EventWaitingList waitlist={waitlist} athletes={athleteStore} />
      )}
    </div>
  );
}

function Details({
  activeEventDiscounts,
  event,
  eventStaffStore,
  hasGenderOrAgeRestriction,
  sessionsHasMore,
  sessionsLoading,
}) {
  return (
    <div style={styles.root}>
      <style
        type="text/css"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: styles.literal }}
      />
      {!event.isOpenBooking() && (
        <DaytimesExpander
          style={{ marginTop: '2.4rem' }}
          sessionIds={event.session_ids}
          sessionsHasMore={sessionsHasMore}
          sessionsLoading={sessionsLoading}
        />
      )}
      <MembershipDiscountList
        eventDiscounts={activeEventDiscounts}
        style={{ marginBottom: 20 }}
      />
      <Location
        location={event.getIn(['schedules', 0, 'location'])}
        style={{ marginBottom: 20 }}
      />
      <StaffList
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...eventStaffStore}
        style={merge(styles.bottomDivider, { marginBottom: 20 })}
      />
      {event.isFixedSchedule() && (
        <EventSeasons seasons={event.seasons.join(', ')} />
      )}
      {hasGenderOrAgeRestriction && (
        <EventRestrictions
          event={event}
          style={merge(styles.bottomDivider, { marginBottom: 20 })}
        />
      )}
      <EventDescription event={event} />
      <EventImage event={event} />
    </div>
  );
}

function EventSmall({
  activeEventDiscounts,
  athleteStore,
  clientCredits,
  currentRegistrations,
  enrolledAthletes,
  event,
  eventStaffStore,
  hasGenderOrAgeRestriction,
  intl,
  purchased,
  registrations,
  sessions,
  clients,
  waitlist,
  waitlisted,
  sessionsHasMore,
  sessionsLoading,
}) {
  return isLoggedIn() &&
    (purchased || waitlisted || !clientCredits.isEmpty()) ? (
    <Tabs
      defaultActiveTab={0}
      variant="fullWidth"
      tabItems={[
        {
          tabLabel: t('.registrations', intl, __filenamespace),
          tabContent: (
            <Registrations
              athleteStore={athleteStore}
              clientCredits={clientCredits}
              currentRegistrations={currentRegistrations}
              enrolledAthletes={enrolledAthletes}
              event={event}
              eventStaffStore={eventStaffStore}
              purchased={purchased}
              registrations={registrations}
              sessions={sessions}
              waitlist={waitlist}
              clients={clients}
            />
          ),
          tabIndex: 0,
          tabVisible: true,
        },
        {
          tabLabel: t('.details', intl, __filenamespace),
          tabContent: (
            <Details
              activeEventDiscounts={activeEventDiscounts}
              event={event}
              eventStaffStore={eventStaffStore}
              hasGenderOrAgeRestriction={hasGenderOrAgeRestriction}
              sessionsHasMore={sessionsHasMore}
              sessionsLoading={sessionsLoading}
            />
          ),
          tabIndex: 1,
          tabVisible: true,
        },
      ]}
      style={{ backgroundColor: '#F9F9F9' }}
    />
  ) : (
    <Details
      activeEventDiscounts={activeEventDiscounts}
      event={event}
      eventStaffStore={eventStaffStore}
      hasGenderOrAgeRestriction={hasGenderOrAgeRestriction}
      sessionsHasMore={sessionsHasMore}
      sessionsLoading={sessionsLoading}
    />
  );
}

export default injectIntl(EventSmall);
