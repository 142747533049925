import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Divider from '@mui/material/Divider';

import altContainer from 'shared/hocs/altContainer.jsx';
import { MembershipDataStore } from 'dataStores';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ItemSummaryHeader from 'shared/components/checkout/_ItemSummaryHeader.jsx';
import ManageOrderItem from 'shared/components/checkout/_ManageOrderItem.jsx';
import MembershipDiscount from 'cart/index/components/_MembershipDiscount.jsx';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  quantity: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },
};

function MembershipDiscountInfo({ adjustment, customerUsers, memberships }) {
  return (
    <div>
      {adjustment.details.map(details => {
        const customerUser = customerUsers.find(
          c => c.id === details.get('customer_user_id')
        );
        const membership = memberships.get(
          details.get('membership_retail_discount')?.get('subscribable_id')
        );

        return (
          <MembershipDiscount
            key={customerUser.id}
            membership={membership}
            customerUser={customerUser}
            discount={details.getIn(['membership_retail_discount', 'discount'])}
            discountAmount={details.get('discount_amount')}
          />
        );
      })}
    </div>
  );
}

function CouponDiscountInfo({ adjustment }) {
  return (
    <FlexBoxJustify style={{ marginBottom: 15, fontWeight: 'bold' }}>
      <div>{adjustment.details.name}</div>
      <FormattedCurrency value={adjustment.amount} fromCents />
    </FlexBoxJustify>
  );
}

function DiscountInfo({ adjustment, customerUsers, memberships }) {
  if (adjustment.isCoupon()) {
    return <CouponDiscountInfo adjustment={adjustment} />;
  }
  if (adjustment.isMembershipRetailDiscount()) {
    return (
      <MembershipDiscountInfo
        adjustment={adjustment}
        customerUsers={customerUsers}
        memberships={memberships}
      />
    );
  }
  return null;
}

function VariantSummary({
  appliedAccountCredits,
  athletes,
  item,
  onEdit,
  onRemove,
  onView,
  purchased,
  membershipDataStore: { memberships },
}) {
  return (
    <div>
      <FlexBox style={{ marginBottom: 15 }}>
        <img
          src={item.orderable.image.getAlternative('thumb_small')}
          alt={item.primary_display_text}
          height={50}
          width={50}
          style={{ marginRight: 20, objectFit: 'contain' }}
        />
        <div style={{ flex: '1 1 auto', marginBottom: 0 }}>
          <ItemSummaryHeader
            item={item}
            onRemove={onRemove}
            purchased={purchased}
          />

          {item.applied_adjustments.map(adjustment => (
            <DiscountInfo
              adjustment={adjustment}
              customerUsers={athletes}
              memberships={memberships}
            />
          ))}
        </div>
      </FlexBox>

      <Divider sx={{ height: '2px', marginBottom: '15px' }} />

      <FlexBoxJustify style={styles.quantity}>
        <div>
          <FormattedMessage id={messageId('.quantity', __filenamespace)} />
          &nbsp;
          {item.quantity}
        </div>
        <FormattedCurrency value={item.totalBeforeManualDiscount()} fromCents />
      </FlexBoxJustify>

      <ManageOrderItem
        item={item}
        purchased={purchased}
        appliedAccountCredits={appliedAccountCredits}
        onEdit={onEdit}
        onView={onView}
      />
    </div>
  );
}

export default altContainer({
  stores: {
    membershipDataStore: MembershipDataStore,
  },
})(VariantSummary);
