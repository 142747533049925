import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import UserActions from 'shared/actions/UserActions.jsx';
import UserCreationActions from 'user_management/shared/actions/UserCreationActions.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import { REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT } from '../const';

function ActionButtons({
  accountType,
  customerUserActions,
  customerUserStore,
  intl,
  accessor,
  isSaving,
  modalOpen,
}) {
  if (accountType === 'manager') {
    return (
      <>
        {modalOpen && (
          <Stack spacing={2} direction="row">
            <Button
              fullWidth
              color="dark"
              variant="contained"
              sx={{ height: '45px' }}
              onClick={() => {
                customerUserActions.validateUser({
                  requiredFields: REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT,
                  isNewUser: true,
                });
                UserCreationActions.updateNewUser(
                  [],
                  customerUserStore[accessor]
                );
                UserCreationActions.nextStep();
              }}
              disabled={!customerUserStore.isValid}
            >
              {t('.add_participant', intl, __filenamespace)}
            </Button>
            <StateChangingButton
              fullWidth
              disabled={!customerUserStore.isValid}
              inProgress={isSaving}
              sx={{ height: '45px' }}
              onClick={() => {
                customerUserActions.validateUser({
                  requiredFields: REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT,
                  isNewUser: true,
                });
                UserCreationActions.updateNewUser(
                  [],
                  customerUserStore[accessor]
                );
                UserActions.register({
                  skipProfiles: true,
                  redirectAfterLogin: true,
                  redirectPath: window.location.pathname,
                });
              }}
              label={t('actions.complete', intl)}
              labelInProgress={t('actions.saving', intl)}
            />
          </Stack>
        )}
        {!modalOpen && (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            sx={{ height: '45px' }}
            onClick={() => {
              customerUserActions.validateUser({
                requiredFields: REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT,
                isNewUser: true,
              });
              UserCreationActions.updateNewUser(
                [],
                customerUserStore[accessor]
              );
              UserCreationActions.nextStep();
            }}
          >
            {t('actions.continue', intl)}
          </Button>
        )}
      </>
    );
  }

  return (
    <Stack spacing={2} direction="row">
      <Button
        fullWidth
        color="dark"
        variant="contained"
        sx={{ height: '45px' }}
        onClick={() => {
          customerUserActions.validateUser({
            requiredFields: REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT,
            isNewUser: true,
          });
          UserCreationActions.updateNewUser([], customerUserStore[accessor]);
          UserCreationActions.nextStep();
        }}
        disabled={!customerUserStore.isValid}
      >
        {t('.add_participant', intl, __filenamespace)}
      </Button>
      <StateChangingButton
        fullWidth
        sx={{ height: '45px' }}
        disabled={!customerUserStore.isValid}
        inProgress={isSaving}
        onClick={() => {
          customerUserActions.validateUser({
            requiredFields: REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT,
            isNewUser: true,
          });
          UserCreationActions.updateNewUser([], customerUserStore[accessor]);
          UserActions.register({
            skipProfiles: true,
            redirectAfterLogin: true,
          });
        }}
        label={t('actions.complete', intl)}
        labelInProgress={t('actions.saving', intl)}
      />
    </Stack>
  );
}

ActionButtons.propTypes = {
  accountType: PropTypes.string.isRequired,
  customerUserActions: PropTypes.object.isRequired,
  customerUserStore: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  accessor: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default ActionButtons;
