import React from 'react';
import AltContainer from 'alt-container';

import Content from 'event_mgmt/index/components/_Content.jsx';
import Header from 'event_mgmt/index/components/_Header.jsx';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import FilterStore from 'event_mgmt/index/stores/FilterStore.jsx';
import SelectedEventsStore from 'event_mgmt/index/stores/SelectedEventsStore.jsx';
import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';
import '../styles.scss';

class EventMgmtIndex extends React.Component {
  componentDidMount() {
    StoreActions.prepareForReuse();
    EventListActions.eventsDeselected();
    AutomationTemplateDescriptionActions.list({
      listAll: true,
      requireToken: true,
    });
  }

  render() {
    return (
      <div className="events">
        <Header />
        <AltContainer
          stores={{
            automations: AutomationTemplateDescriptionStore,
            eventList: EventListStore,
            eventTypes: EventTypeListingStore,
            eventStore: EventStore,
            filter: FilterStore,
            selectedEvents: SelectedEventsStore,
          }}
        >
          <Content readOnly={this.props.readOnly} />
        </AltContainer>
      </div>
    );
  }
}

export default EventMgmtIndex;
