import React from 'react';
import { Box } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';

const styles = {
  rowTopText: {
    margin: 0,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 3,
  },
};

function TeamLink({ team, showDeletedTeams }) {
  return team.status !== 'deleted' ||
    (showDeletedTeams && team.status === 'deleted') ? (
    <ReactRouterLink to={team.getTeamLink()}>{team.title}</ReactRouterLink>
  ) : (
    <Box component="span" sx={styles.rowTopText}>
      {team.title}
    </Box>
  );
}

export default TeamLink;
