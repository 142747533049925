import * as React from 'react';
import { states } from 'shared/components/StateSelector.jsx';
import SelectField from 'onboarding/components/AccountSetupPaysafe/fields/_SelectField.jsx';

function StateField({ fieldName, countryCode = 'US', ...props }) {
  return (
    <SelectField
      fieldName={fieldName}
      fullWidth
      items={states
        .get(countryCode)
        .map((name, abbrev) => ({ label: name, value: abbrev }))
        .toArray()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default StateField;
