import { List, Record, Set } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

export const ABSOLUTE = 'amount';
export const PERCENT = 'percent';

const VALID_TYPES = [ABSOLUTE, PERCENT];

class CommissionRate extends Record({
  id: null,
  item_ids: Set(),
  customer_user_id: null,
  rate_type: PERCENT,
  value: 0,
  discount_sensitive: false,
  errors: new FieldErrors(),
  all_retail_categories: false,
  all_memberships: false,
  membership_ids: List(),
  retail_category_ids: List(),
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        item_ids: Set([
          ...(obj.membership_ids || []),
          ...(obj.retail_category_ids || []),
        ]),
      })
    );
  }

  validate() {
    let errors = new FieldErrors();

    if (!isPresent(this.value)) {
      errors = errors.add('value', 'records.errors.required');
    }

    if (
      this.rate_type === PERCENT &&
      isPresent(this.value) &&
      (this.value <= 0.0 || this.value > 100.0)
    ) {
      errors = errors.add(
        'value',
        'records.CommissionRate.errors.value_out_of_range'
      );
    }

    if (
      this.rate_type === ABSOLUTE &&
      isPresent(this.value) &&
      this.value <= 0
    ) {
      errors = errors.add(
        'value',
        'records.CommissionRate.errors.more_than_zero'
      );
    }

    if (
      !this.all_memberships &&
      !this.all_retail_categories &&
      !this.membership_ids.size &&
      !this.retail_category_ids.size
    ) {
      errors = errors.add(
        'commission',
        'records.CommissionRate.errors.commission_must_be_selected'
      );
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty() && VALID_TYPES.includes(this.rate_type);
  }

  toServer() {
    const params = this.toJS();
    delete params.item_ids;
    return params;
  }
}

export default CommissionRate;
