import { DefaultSourceActions } from './DefaultSourceActionList';

class ScheduleActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'removeSuccess',
      'removeError',
      'cancelSuccess',
      'cancelError'
    );
  }
}

export default alt.createActions(ScheduleActions);
