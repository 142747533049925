import React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';
import { injectIntl } from 'react-intl';
import { compose } from 'shared/utils/SharedUtils.js';

import {
  ContentModal,
  Grid,
  Typography,
  DateTimePicker,
} from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';
import { t } from 'shared/utils/LocaleUtils.js';
import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions.js';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import CancellationDialogModalActions from './CancellationDialogModalActions.js';
import CancellationDialogModalStore from './CancellationDialogModalStore.jsx';

const styles = {
  typography: {
    paddingBottom: 14,
  },
};

function CancellationDialogModal({
  cancellationDialogModalStore: { isOpen, futureDate },
  intl,
  classes,
}) {
  return (
    <ContentModal
      open={isOpen}
      onSuccess={() => {
        if (futureDate !== '') {
          MembershipCancellationActions.cancelModeChanged('future');
          CancellationDialogModalActions.closeModal();
        }

        if (futureDate === '') {
          MessageWindowActions.addMessage('Date is required.');
        }
      }}
      onAbort={CancellationDialogModalActions.closeModal}
      title={t('.title', intl, __filenamespace)}
      successLabel={t('.confirm', intl, __filenamespace)}
      cancelLabel={t('.cancel', intl, __filenamespace)}
      size="2x"
    >
      <Typography className={classes.typography}>
        {t('.description', intl, __filenamespace)}
      </Typography>

      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography>{t('.future_date', intl, __filenamespace)}</Typography>
          <DateTimePicker
            autoOk
            disablePast
            type="date"
            fullWidth
            placeholder="MM/DD/YYYY"
            onChange={value => {
              CancellationDialogModalActions.setFutureDate(
                value?.tz(customerTZ(), true)
              );
            }}
            value={futureDate}
          />
        </Grid>
      </Grid>
    </ContentModal>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      cancellationDialogModalStore: CancellationDialogModalStore,
    },
  }),
  withStyles(styles)
)(CancellationDialogModal);
