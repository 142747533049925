import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Map, List as ImmutableList } from 'immutable';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';

import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';

const styles = {
  header: {
    padding: 15,
  },

  formGroup: {
    padding: '0 15px 15px',
  },

  formLabel: {
    marginLeft: 0,
    justifyContent: 'space-between',
  },

  toggle: {
    top: 3,
  },

  overlayStyle: {
    opacity: 0,
    backgroundColor: 'transparent',
  },
};

class FilterDrawer extends React.Component {
  componentDidMount() {
    EventTypeListingActions.list({});
  }

  eventTypeCallback(id) {
    const { filterActions, eventActions } = this.props;

    return (e, checked) => {
      filterActions.updateTypeFilters(id, checked);
      eventActions.list({
        fields: ['participant_count'],
        scheduleType: [SCHEDULE_TYPE.openBooking, SCHEDULE_TYPE.fixedSchedule],
        page: 1,
      });
    };
  }

  buildEventStateFilterCallback(filterKey) {
    const { filterActions, eventActions } = this.props;

    return (e, checked) => {
      filterActions.updateStatusFilters(filterKey, checked);
      eventActions.list({
        fields: ['participant_count'],
        scheduleType: [SCHEDULE_TYPE.openBooking, SCHEDULE_TYPE.fixedSchedule],
        page: 1,
      });
    };
  }

  // eslint-disable-next-line class-methods-use-this
  eventTypeColorStyle(type) {
    return {
      backgroundColor: type.color,
      borderRadius: 2,
      border: 2,
      width: 4,
      marginRight: '0.5em',
    };
  }

  render() {
    const {
      showStatusesInFilter,
      eventStatusFilters,
      statusFilters,
      typeFilters,
      eventTypes,
      filterDrawerOpen,
      filterActions,
    } = this.props;
    const children = [];
    const isAllStatusesChecked = statusFilters.every(filter => filter);
    const isAllTypesChecked = typeFilters.every(filter => filter);

    if (showStatusesInFilter) {
      children.push(
        <Typography key="status-subheader" style={styles.header}>
          <FormattedMessage id={messageId('.event_status', __filenamespace)} />
        </Typography>
      );
      children.push(
        <FormGroup
          key="status-filter-list"
          className="status-filter-list"
          style={styles.formGroup}
        >
          {eventStatusFilters.map(filter => (
            <FormControlLabel
              key={uuidv4()}
              label={filter.name}
              labelPlacement="start"
              onChange={this.buildEventStateFilterCallback(filter.filterKey)}
              checked={
                isAllStatusesChecked || statusFilters.get(filter.filterKey)
              }
              control={<Switch />}
              style={styles.formLabel}
            />
          ))}
        </FormGroup>
      );
      children.push(<Divider key="status-filter-divider" />);
    }

    children.push(
      <Typography key="event-type-subheader" style={{ padding: 15 }}>
        <FormattedMessage id={messageId('.event_type', __filenamespace)} />
      </Typography>
    );
    children.push(
      <FormGroup
        key="event-type-filter-list"
        className="event-type-filter-list"
        style={styles.formGroup}
      >
        <FormControlLabel
          key="eventTypeAll"
          label={
            <>
              <span
                style={this.eventTypeColorStyle({
                  color: 'var(--color-white)',
                })}
              >
                &nbsp;
              </span>
              <span>All</span>
            </>
          }
          labelPlacement="start"
          onChange={this.eventTypeCallback('all')}
          checked={isAllTypesChecked}
          control={<Switch />}
          style={styles.formLabel}
        />
        {eventTypes.map(type => (
          <FormControlLabel
            key={type.id}
            label={
              <>
                <span style={this.eventTypeColorStyle(type)}>&nbsp;</span>
                <span>{type.name}</span>
              </>
            }
            labelPlacement="start"
            onChange={this.eventTypeCallback(type.id)}
            checked={!!typeFilters.get(type.id)}
            control={<Switch />}
            style={styles.formLabel}
          />
        ))}
      </FormGroup>
    );

    return (
      <Drawer
        disableEnforceFocus
        ModalProps={{
          BackdropProps: {
            sx: styles.overlayStyle,
          },
        }}
        open={filterDrawerOpen}
        anchor="right"
        onClose={() => filterActions.updateFilterDrawerOpen(false)}
        PaperProps={{
          sx: { width: smallScreen() ? window.innerWidth * 0.8 : null },
        }}
      >
        <div className="iphone-x-content">{children}</div>
      </Drawer>
    );
  }
}

FilterDrawer.propTypes = {
  filterActions: PropTypes.object,
  eventActions: PropTypes.object,
  showStatusesInFilter: PropTypes.bool,
  filterDrawerOpen: PropTypes.bool,
  eventStatusFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      filterKey: PropTypes.string,
    })
  ),
  statusFilters: PropTypes.instanceOf(Map),
  typeFilters: PropTypes.instanceOf(Map),
  eventTypes: PropTypes.instanceOf(ImmutableList),
};

FilterDrawer.defaultProps = {
  filterActions: FilterActions,
  eventActions: EventActions,
  showStatusesInFilter: true,
  filterDrawerOpen: false,
  eventStatusFilters: [],
  statusFilters: Map(),
  typeFilters: Map(),
  eventTypes: ImmutableList(),
};

export default FilterDrawer;
