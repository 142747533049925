import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

function ServiceFeeExemptToggle({
  order,
  onFeeExemptToggle,
  disabled,
  style,
  intl,
}) {
  return (
    <div style={style}>
      <Checkbox
        label={t('.remove_service_fee', intl, __filenamespace)}
        checked={order?.fee_exempt}
        onChange={(_, checked) => onFeeExemptToggle(checked)}
        disabled={disabled}
      />
    </div>
  );
}

export default injectIntl(ServiceFeeExemptToggle);
