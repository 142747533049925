import * as React from 'react';
import moment from 'moment-timezone';

import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';

import { smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { weekRangeFromDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  DatePreview: {
    flexBasis: '34%',
    maxWidth: '34%',
    padding: '16px',
    backgroundColor: uhColors.activeBlue,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  DatePreviewMobile: {
    width: '100%',
    maxWidth: 'initial',
  },
  DatePreviewYear: {
    margin: '0 0 8px',
    fontSize: '1em',
    opacity: '0.5',
  },
  DatePreviewText: {
    fontSize: '2.1333em',
  },
  DatePreviewTextMobile: {
    fontSize: '1.333em',
  },
  DatePreviewWeekTextMobile: {
    display: 'flex',
  },
  ButtonContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  Button: {
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    border: 'none',
    padding: '8px 0',
    margin: '4px 0',
    cursor: 'pointer',
    fontSize: '1em',
    textAlign: 'left',
  },
};

const datePreviewText = (view, selectedDate) => {
  switch (view) {
    case CALENDAR_TYPE.DAY:
      return selectedDate.format('ddd, MMM. D');
    case CALENDAR_TYPE.WEEK: {
      const weekRange = weekRangeFromDate(selectedDate);

      return (
        <div style={smallScreen() ? styles.DatePreviewWeekTextMobile : {}}>
          <div>{weekRange.start.format('ddd,')}&nbsp;</div>
          <div>{weekRange.start.format('MMM. D')}</div>
          <div>&nbsp;&mdash;&nbsp;</div>
          <div>{weekRange.end.format('ddd,')}&nbsp;</div>
          <div>{weekRange.end.format('MMM. D')}</div>
        </div>
      );
    }
    default:
      return null;
  }
};

const navigate = ({ toDate = moment(), view }) => {
  CalendarActions.setCalendarDate(toDate);
  CalendarActions.setView(view);
};

function DatePreview({ intl, view, selectedDate, handleRequestClose }) {
  const isMobile = smallScreen();

  const handleDateChange = (targetDate, viewType) => {
    // Navigate to the target date
    navigate({
      toDate: targetDate,
      view: viewType,
    });

    // Close the calendar after navigation
    handleRequestClose();
  };

  return (
    <div
      style={merge(
        styles.DatePreview,
        isMobile ? styles.DatePreviewMobile : {}
      )}
    >
      <div style={styles.DatePreviewYear}>{selectedDate.format('YYYY')}</div>
      <div
        style={merge(
          styles.DatePreviewText,
          isMobile ? styles.DatePreviewTextMobile : {}
        )}
      >
        {datePreviewText(view, selectedDate)}
      </div>
      <div style={styles.ButtonContainer}>
        <button
          type="button"
          style={styles.Button}
          onClick={() => handleDateChange(moment(), CALENDAR_TYPE.DAY)}
        >
          {t('.today', intl, __filenamespace)}
        </button>
        <button
          type="button"
          style={styles.Button}
          onClick={() =>
            handleDateChange(moment().subtract(1, 'day'), CALENDAR_TYPE.DAY)
          }
        >
          {t('.yesterday', intl, __filenamespace)}
        </button>
        <button
          type="button"
          style={styles.Button}
          onClick={() =>
            handleDateChange(
              moment().add(1, 'weeks').startOf('isoWeek'),
              CALENDAR_TYPE.WEEK
            )
          }
        >
          {t('.nextWeek', intl, __filenamespace)}
        </button>
        <button
          type="button"
          style={styles.Button}
          onClick={() =>
            handleDateChange(
              moment().subtract(1, 'week').startOf('isoWeek'),
              CALENDAR_TYPE.WEEK
            )
          }
        >
          {t('.lastWeek', intl, __filenamespace)}
        </button>
      </div>
    </div>
  );
}

export default DatePreview;
