import PropTypes from 'prop-types';
import * as React from 'react';
import { uhFlexbox } from 'shared/styles/uhStyles.jsx';

function EditProfile({ headerText, children, save, cancel, style }) {
  return (
    <div style={{ ...style, marginBottom: '10px' }}>
      {headerText && (
        <span style={{ textTransform: 'capitalize', fontWeight: 600 }}>
          {headerText}
        </span>
      )}
      {children}
      {(save || cancel) && (
        <div className="save-cancel" style={uhFlexbox.row}>
          {save}
          {cancel}
        </div>
      )}
    </div>
  );
}

EditProfile.propTypes = {
  cancel: PropTypes.element,
  headerText: PropTypes.string,
  save: PropTypes.element,
};

EditProfile.defaultProps = {
  cancel: null,
  headerText: '',
  save: null,
};

export default EditProfile;
