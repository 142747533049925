import * as React from 'react';
import { Icon, Card } from '@upperhand/playmaker';

import PaymentMethodModalActions from 'containers/client/settings/paymentMethodModal/Actions';

function CardAdd() {
  return (
    <Card
      classes={{
        root: 'card-info card-add',
        content: 'card-add__wrapper',
      }}
      onClick={PaymentMethodModalActions.openModal}
    >
      <Icon name="add" />
    </Card>
  );
}

export default React.memo(CardAdd);
