import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import MembershipActions from 'sources/MembershipActions.js';

class MembershipDataStore extends UpperHandStore {
  constructor() {
    super();

    this.memberships = Map();

    this.bindListeners({
      fetchSuccess: MembershipActions.fetchSuccess,
      listSuccess: MembershipActions.listSuccess,
      error: MembershipActions.listError,
    });
  }

  fetchSuccess(membership) {
    this.memberships = this.memberships.set(membership.id, membership);
  }

  listSuccess({ memberships }) {
    const fetchedMemberships = memberships
      .groupBy(m => m.id)
      .map(m => m.first());

    this.memberships = this.memberships.merge(fetchedMemberships);
  }

  error(...args) {
    this.notifyError('error fetching memberships', args);
  }
}

export default alt.createStore(MembershipDataStore, 'MembershipDataStore');
