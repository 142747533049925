import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { uhColors } from 'shared/styles/uhStyles.jsx';

function CardMenu({ children }) {
  const [anchorEl, setAnchor] = React.useState();

  return (
    <>
      <IconButton
        style={{
          top: 0,
          right: 0,
          margin: 'auto',
          position: 'absolute',
        }}
        onClick={e => setAnchor(e.currentTarget)}
      >
        <MoreVertIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClick={() => setAnchor(null)}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {children}
      </Menu>
    </>
  );
}

export default CardMenu;
