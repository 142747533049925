import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import { List } from 'immutable';

import * as ClientSource from 'sources/ClientSource';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import ContactsArchivedActions from './Actions';

class ContactsArchivedStore extends UpperHandStore {
  constructor() {
    super();

    this.clientIds = List();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.search = '';
    this.loading = false;
    this.loadedAt = null;

    this.debouncedListClients = debounce(this.listClients, 600);

    this.bindListeners({
      mounted: ContactsArchivedActions.mounted,
      clientListSuccess: ContactsArchivedActions.clientListSuccess,
      clientListError: ContactsArchivedActions.clientListError,
      pageSelected: ContactsArchivedActions.pageSelected,
      searchUpdated: ContactsArchivedActions.searchUpdated,
      clientUnarchived: ContactsArchivedActions.clientUnarchived,
      clientUnarchivedSuccess: ContactsArchivedActions.clientUnarchivedSuccess,
      clientUnarchivedError: ContactsArchivedActions.clientUnarchivedError,
    });
  }

  mounted() {
    if (
      !this.loadedAt ||
      this.loadedAt.isBefore(moment().subtract(5, 'minutes'))
    ) {
      this.listClients();
    }
  }

  listClients() {
    const params = {
      page: this.page,
      per_page: this.perPage,
      statuses: ['archived'],
    };

    if (this.search) {
      params.search = this.search;
    }

    this.loading = true;

    ClientSource.list({
      params,
      success: ContactsArchivedActions.clientListSuccess,
      error: ContactsArchivedActions.clientListError,
    });
  }

  clientListSuccess({ clients, page, perPage, totalCount }) {
    this.clientIds = clients.map(c => c.id);
    this.totalCount = totalCount;
    this.loading = false;
    this.loadedAt = moment();
  }

  clientListError() {
    this.loading = false;
    this.notifyError('error listing client contacts', arguments);
  }

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listClients();
  }

  searchUpdated(newSearch) {
    this.search = newSearch;
    this.page = 1;

    this.debouncedListClients();
  }

  clientUnarchived(id) {
    ClientSource.patch({
      id,
      recordAttributes: { archived: false },
      success: ContactsArchivedActions.clientUnarchivedSuccess,
      error: ContactsArchivedActions.clientUnarchivedError,
    });
  }

  clientUnarchivedSuccess() {
    this.listClients();
  }

  clientUnarchivedError() {
    this.notifyError('error archiving client', arguments);
  }
}

export default alt.createStore(ContactsArchivedStore, 'ContactsArchivedStore');
