import { Record, Set } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent } from 'shared/utils/ObjectUtils.jsx';

class TaxRate extends Record({
  id: null,
  name: '',
  rate: null,
  accounting_code_ids: Set(),
  errors: new FieldErrors(),
}) {
  validate() {
    let errors = this.errors.clear();

    ['name', 'rate'].forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  toServer() {
    return this.update('rate', rate => rate || 0.0).toJS();
  }
}

export default TaxRate;
