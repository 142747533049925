import * as React from 'react';
import { injectIntl } from 'react-intl';
import { TextField, MaskedTextField } from '@upperhand/playmaker';

import StateSelector from 'shared/components/StateSelector.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

export const Address1Field = injectIntl(
  ({ classes, errorText, intl, label, name, onChange, placeholder, value }) => (
    <TextField
      classes={classes}
      errorText={errorText}
      fullWidth
      label={label || t('.street_address', intl, __filenamespace)}
      name={name}
      onChange={onChange}
      placeholder={placeholder || t('.street_address', intl, __filenamespace)}
      value={value}
    />
  )
);

export const Address2Field = injectIntl(
  ({ classes, errorText, intl, name, onChange, placeholder, value }) => (
    <TextField
      classes={classes}
      errorText={errorText}
      fullWidth
      name={name}
      onChange={onChange}
      placeholder={placeholder || t('.address_two', intl, __filenamespace)}
      value={value}
    />
  )
);

export const CityField = injectIntl(
  ({ classes, errorText, intl, onChange, value }) => (
    <TextField
      classes={classes}
      errorText={errorText}
      fullWidth
      label={t('.city', intl, __filenamespace)}
      name="city"
      onChange={onChange}
      placeholder={t('.city', intl, __filenamespace)}
      value={value}
    />
  )
);

export const PostalCodeField = injectIntl(
  ({ classes, errorText, intl, onChange, value }) => (
    <MaskedTextField
      classes={classes}
      errorText={errorText}
      fullWidth
      label={t('.postal_code', intl, __filenamespace)}
      name="postal_code"
      onChange={e => onChange(e, e.target.value)}
      placeholder={t('.postal_code', intl, __filenamespace)}
      value={value}
      mask="99999"
    />
  )
);

export const StateField = injectIntl(
  ({
    classes,
    errorText,
    intl,
    onChange,
    placeholder,
    label,
    useAbbreviations,
    value,
  }) => (
    <StateSelector
      classes={classes}
      errorText={errorText}
      label={label || t('.state', intl, __filenamespace)}
      name="state"
      onChange={onChange}
      placeholder={placeholder || t('actions.select', intl)}
      useAbbreviations={useAbbreviations}
      value={value}
    />
  )
);
