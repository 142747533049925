import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Drawer, Typography, Button } from '@upperhand/playmaker';

import HistoryItem from 'containers/clientProfile/components/Drawers/SwingCreditsDrawer/components/HistoryItem.jsx';
import EmptyState from 'containers/clientProfile/components/Drawers/SwingCreditsDrawer/components/EmptyState.jsx';
import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import TopUpModal from 'containers/clientProfile/components/Drawers/SwingCreditsDrawer/components/TopUpModal.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

import Client from 'shared/records/Client.jsx';

import { ClientDataStore } from 'dataStores';
import PaymentStore from 'containers/payments/Store';

import SwingCreditsDrawerActions from './Actions';
import SwingCreditsDrawerStore from './Store';

import './styles.scss';

function SwingCreditsDrawer({
  swingCreditsDrawerStore,
  clientDataStore,
  paymentStore,
}) {
  const intl = useIntl();
  const { clients } = clientDataStore;
  const { processingPayment } = paymentStore;
  const {
    clientId,
    swings,
    pagination,
    clientLoading,
    swingsLoading,
    topUpModalOpen,
    loadingVariants,
    variants,
    selectedVariantId,
    order,
    creatingOrderItem,
    purchasing,
    fetchingOrder,
    accountCreditAmount,
  } = swingCreditsDrawerStore;
  const historyPagination = pagination.get('swingsHistory').toJS();
  const { page, perPage, totalCount } = historyPagination;
  const client = clients.get(clientId, new Client());
  const managingClientId = client.managing_customer_user_id;
  const managingClient = clients.get(managingClientId, new Client());
  const hasSwings = swings.length > 0;
  const showTopUpBtn =
    currentUser().isClient() &&
    currentUser().login_enabled &&
    enabledCustomerFeatures(['ap_credits', 'hpp_top_up']);
  const loading =
    creatingOrderItem || purchasing || fetchingOrder || processingPayment;

  return (
    <Drawer
      classes={{
        root: 'swing-credits-drawer',
        footer: 'swing-credits-drawer__footer',
        header: 'swing-credits-drawer__header',
        actions: 'swing-credits-drawer__actions',
      }}
      open={Boolean(clientId)}
      title={
        <div className="swing-credits-drawer__title">
          <div className="swing-credits-drawer__title-with-action">
            <Typography variant="drawerTitle">
              {t('.title', intl, __filenamespace)}
            </Typography>
            {showTopUpBtn && (
              <Button onClick={SwingCreditsDrawerActions.toggleTopUpModal}>
                {t('.top_up', intl, __filenamespace)}
              </Button>
            )}
          </div>
          {!clientLoading && <ClientDetails client={client} />}
        </div>
      }
      content={
        <SpinWhileLoading isLoading={swingsLoading} absolute={false}>
          <div className="swing-credits-drawer__history">
            {!hasSwings ? <EmptyState intl={intl} /> : null}
            {swings.map(swing => (
              <HistoryItem key={swing.id} swing={swing} />
            ))}
          </div>
          <TopUpModal
            loading={loading}
            accountCreditAmount={accountCreditAmount}
            order={order}
            selectedVariantId={selectedVariantId}
            client={client}
            managingClient={managingClient}
            variants={variants}
            loadingVariants={loadingVariants}
            open={topUpModalOpen}
            onClose={SwingCreditsDrawerActions.toggleTopUpModal}
            onSuccessfulPurchase={
              SwingCreditsDrawerActions.purchaseSuccess.defer
            }
            onVariantChange={e =>
              SwingCreditsDrawerActions.variantChange({
                variantId: e.target.value,
              })
            }
            onAccountCreditChanged={
              SwingCreditsDrawerActions.accountCreditChanged
            }
          />
        </SpinWhileLoading>
      }
      footer={
        hasSwings ? (
          <Paginator
            showInfo
            className="swing-credits-drawer__paginator"
            currentPage={page}
            perPage={perPage}
            totalCount={totalCount}
            onPageSelect={SwingCreditsDrawerActions.swingsPageSelected}
            pageLimit={0}
          />
        ) : null
      }
      onClose={() => SwingCreditsDrawerActions.mounted({ clientId: null })}
    />
  );
}

SwingCreditsDrawer.propTypes = {
  swingCreditsDrawerStore: PropTypes.object.isRequired,
  clientDataStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      swingCreditsDrawerStore: SwingCreditsDrawerStore,
      clientDataStore: ClientDataStore,
      paymentStore: PaymentStore,
    },
  })
)(SwingCreditsDrawer);
