class MonthlyRecurringRevenueReportActions {
  constructor() {
    this.generateActions(
      'mounted',
      'monthlyRecurringRevenueListResultLoaded',
      'monthlyRecurringRevenueListResultError',
      'membershipTypesListResultLoaded',
      'membershipTypesListResultError',
      'cancellationDetailsListResultLoaded',
      'cancellationDetailsListResultError',
      'downloadMonthlyRecurringRevenueList',
      'downloadMonthlyRecurringRevenueListSuccess',
      'downloadMonthlyRecurringRevenueListError',
      'downloadDetails',
      'downloadDetailsSuccess',
      'downloadDetailsError',
      'spendingListResultLoaded',
      'spendingListResultError',
      'averageMembershipLengthsResultLoaded',
      'averageMembershipLengthsResultError',
      'filterUpdated',
      'fetchDetailsList',
      'detailsListResultLoaded',
      'detailsListResultError',
      'hideDetails',
      'applyFilters',
      'clearFilters',
      'updateMetricsPageParams',
      'updateDetailsPageParams'
    );
  }
}

export default alt.createActions(MonthlyRecurringRevenueReportActions);
