import * as React from 'react';
import { blue } from '@mui/material/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhContrast } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: merge(uhContrast.lightOnDark, {
    alignItems: 'center',
    fontSize: 15,
    paddingLeft: 20,
    minHeight: 56,
  }),
};

function DrawerHeader({ title, onClose, onBack, centerTitle, style }) {
  return (
    <FlexBoxJustify
      style={merge(styles.root, onBack ? { paddingLeft: 0 } : {}, style)}
    >
      {onBack && (
        <IconButton onClick={onBack}>
          <ChevronLeftIcon sx={{ color: blue[500] }} />
        </IconButton>
      )}

      {onBack === null && centerTitle && (
        <div style={{ width: 28, height: 28 }} />
      )}

      <div>{title}</div>
      {onClose && (
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
    </FlexBoxJustify>
  );
}

export default DrawerHeader;
