const EventListActions = alt.generateActions(
  'eventsSelected',
  'updatePageParams',
  'updateRequestedDate',
  'updateRequestedFields',
  'eventDeleted',
  'eventsDeselected',
  'reset'
);

export default EventListActions;
