import { onError, onSuccess } from 'sources/handlers';

import Client from 'shared/records/Client.jsx';
import { List } from 'immutable';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import DataStoreActions from './ClientActions';
import StaffDataStoreActions from './StaffActions';

const parserTo = { type: Client, paginationKey: 'clients' };

const parser = new Parser(parserTo);

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const patch = ({ id, recordAttributes, fields, success, error }) => {
  uhApiClient.patch({
    url: `customer_users/${id}`,
    data: JSON.stringify({
      attributes: recordAttributes,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const put = ({ id, recordAttributes, fields, success, error }) => {
  // use customer_users endpoint for update. Client does not have its own yet.
  // we need to call both the success action for staff and client, because if we are converting an invited contact these can update both stores.
  uhApiClient.put({
    url: `customer_users/${id}`,
    data: JSON.stringify({
      attributes: recordAttributes,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([
        DataStoreActions.updateSuccess,
        StaffDataStoreActions.updateSuccess,
        success,
      ])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const sendMessage = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `clients/${id}/message`,
    data: JSON.stringify(params),
    success: () => success(),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const join = ({ id, success, error }) => {
  uhApiClient.post({
    url: `customers/${id}/join`,
    success: onSuccess(data => parser.parseSingle(data), List([success])),
    error: onError(List([error])),
  });
};

export const deleteFile = ({ clientId, documentId }) => {
  uhApiClient.delete({
    url: `customer_users/${clientId}?document_id=${documentId}`,
    success: () => DataStoreActions.deleteSuccess({ clientId, documentId }),
    error: () => DataStoreActions.deleteError(),
  });
};
