import moment from 'moment-timezone';
import { Record, List } from 'immutable';
import { merge, toCamelCase, toSnakeCase } from 'shared/utils/ObjectUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { getCustomerMoment } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { toSlug } from 'shared/utils/StringUtils.jsx';
import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';

const DEFAULT_COLOR = '#FFF';
export const STATUS = {
  draft: 'draft',
  active: 'active',
  cancelled: 'cancelled',
  completed: 'completed',
  deleted: 'deleted',
};

class BrowseEvent extends Record({
  allowWaitlist: false,
  eventId: null,
  eventTitle: null,
  eventTypeId: null,
  eventTypeName: null,
  eventTypeColor: DEFAULT_COLOR,
  sessionId: null,
  startsAt: null,
  spotsRemaining: null,
  schedulingDeadline: null,
  registrationDeadline: null,
  duration: null,
  staffIds: List(),
  dayCount: null,
  scheduleType: null,
  status: STATUS.active,
  path: '',
  externalUrl: '',
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);
    const startsAt = parsedObject.startsAt
      ? getCustomerMoment(parsedObject.startsAt)
      : null;
    const path = customerScopedRoute(
      `events/${toSlug(`${parsedObject.eventId}-${parsedObject.eventTitle}`)}`
    );

    super(
      merge(parsedObject, {
        startsAt,
        eventTypeColor: parsedObject.eventTypeColor || DEFAULT_COLOR,
        path,
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  toClient(obj) {
    return toCamelCase(obj);
  }

  toServer() {
    const payload = this.toJS();

    return toSnakeCase(payload);
  }

  hasDeadlineReached() {
    switch (this.scheduleType) {
      case SCHEDULE_TYPE.classSchedule:
        return (
          !!this.schedulingDeadline &&
          moment()
            .add(this.schedulingDeadline, 'seconds')
            .isSameOrAfter(this.startsAt)
        );
      case SCHEDULE_TYPE.fixedSchedule:
        return (
          !!this.registrationDeadline &&
          moment().isAfter(this.registrationDeadline, 'day')
        );
      default:
        return false;
    }
  }
}

export default BrowseEvent;
