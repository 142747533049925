import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function USDIcon({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 20 30" {...props}>
      <g
        id="Point-of-Sale"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="POS_Coupon_Graphic"
          transform="translate(-293.000000, -794.000000)"
          fill={color}
        >
          <path
            d="M309.56,812.952 C309.56,816.024 307.576,818.936 302.712,819.32 L302.712,822.2
          L300.44,822.2 L300.44,819.352 C297.144,819.128 294.712,817.816 293.08,815.992
          L294.904,813.528 C296.152,814.904 298.04,816.152 300.44,816.44 L300.44,809.464
          C297.176,808.632 293.752,807.448 293.752,803.416 C293.752,800.12 296.472,797.656
          300.44,797.368 L300.44,794.424 L302.712,794.424 L302.712,797.4 C305.368,797.656
          307.48,798.68 309.08,800.312 L307.192,802.68 C305.976,801.4 304.408,800.632
          302.712,800.344 L302.712,806.584 C306.04,807.48 309.56,808.696 309.56,812.952 Z
          M297.048,803.192 C297.048,804.728 298.52,805.432 300.44,805.976 L300.44,800.216
          C298.392,800.376 297.048,801.528 297.048,803.192 Z M302.712,816.44 C305.336,816.152
          306.296,814.584 306.296,813.24 C306.296,811.416 304.728,810.68 302.712,810.072
          L302.712,816.44 Z"
            id="usd_icon_v2"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

USDIcon.defaultProps = {
  color: uhColors.iconGrey,
};

export default USDIcon;
