import * as React from 'react';

import InventoryReportActions from 'reporting/inventory/actions/InventoryReportActions.js';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';

import Header from 'reporting/inventory/components/Header.jsx';
import Content from 'reporting/inventory/components/Content.jsx';
import '../styles.scss';

class InventoryReport extends React.PureComponent {
  componentDidMount() {
    RetailCategoryListingActions.list();
    InventoryReportActions.mounted();
  }

  render() {
    return (
      <div className="inventory">
        <Header />
        <Content />
      </div>
    );
  }
}

export default InventoryReport;
