import * as React from 'react';
import { Tabs } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { useIntl } from 'react-intl';

// This is not used directly, but it needs to be imported here so that
// reminding an deleting invites can be handled.
// eslint-disable-next-line no-unused-vars
import InviteStore from 'user_management/shared/stores/InviteStore.jsx';

import ContactsArchived from 'containers/contacts/contactsArchived/ContactsArchived.jsx';
import ContactsClients from 'containers/contacts/contactsClients/ContactsClients.jsx';
import ContactsGroups from 'containers/contacts/contactsGroups/ContactsGroups.jsx';
import ContactsStaff from 'containers/contacts/contactsStaff/ContactsStaff.jsx';
import ContactsArchivedStaff from 'containers/contacts/contactsArchivedStaff/ContactsArchivedStaff.jsx';
import ContactImportDrawer from 'contacts/index/components/ContactImportDrawer.jsx';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import ContactsClientStore from 'containers/contacts/contactsClients/Store';
import ContactsPageActions from './Actions';
import ContactsPageStore from './Store';
import ActionButtons from './components/ActionButtons.jsx';

import '../styles.scss';

const styles = {
  root: {
    height: '100%',
    backgroundColor: 'var(--color-bg-gray)',
    position: 'relative',
  },

  contactActions: {
    position: 'absolute',
    zIndex: 1,
  },
};

const onTabChange = (_event, value) => ContactsPageActions.tabSelected(value);

function ContactsPage({
  contactsPageStore: { selectedTab },
  contactsClientStore: { isContactListDownloading },
}) {
  React.useEffect(() => {
    ContactsPageActions.mounted();
  }, []);

  const intl = useIntl();

  const user = currentUser();
  const isStaff = user.isStaff() || user.isCoach();
  const isStaffOrInstructor = isStaff || user.isInstructor();
  const tabs = [
    {
      label: 'Clients',
      content: <ContactsClients />,
      visible: isStaff,
    },
    {
      label: 'Staff',
      content: <ContactsStaff />,
      visible: isStaffOrInstructor,
    },
    {
      label: 'Groups',
      content: <ContactsGroups />,
      visible: isStaff,
    },
    {
      label: 'Archived',
      content: <ContactsArchived />,
      visible: isStaff,
    },
    {
      label: 'Archived Staff',
      content: <ContactsArchivedStaff />,
      visible: isStaff,
    },
  ].filter(tab => tab.visible);

  return (
    <div style={styles.root} className="contacts">
      <PageHeader
        title={t('.header', intl, __filenamespace)}
        wrapperStyles={{ marginBottom: '0' }}
        actions={
          isStaff && <ActionButtons disableExport={isContactListDownloading} />
        }
      />
      <Tabs
        variant="scrollable"
        tabItems={tabs}
        onChange={onTabChange}
        value={selectedTab}
      />

      {isStaff && <ContactImportDrawer />}
    </div>
  );
}

export default compose(
  altContainer({
    stores: {
      contactsPageStore: ContactsPageStore,
      contactsClientStore: ContactsClientStore,
    },
  })
)(ContactsPage);
