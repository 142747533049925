import uhAPIClient from 'shared/helpers/uhApiClient.jsx';

/**
 * Backend API Wrapper
 *
 * Provides a promise-based interface to backend calls
 */
class BackendAPI {
  /**
   * GET
   * @param  {String}  url       URL to fetch
   * @param  {Object}  data      [Optional] Data to include in the request
   * @param  {Object}  options   [Optional] Additional options to configure the request.
   *                             See `request` below.
   * @return {Promise}
   */
  async get(url, data = null, options = {}) {
    return this.request('get', url, data, options);
  }

  /**
   * POST
   * @param  {String}  url       URL to post to
   * @param  {Object}  data      [Optional] Data to include in the request
   * @param  {Object}  options   [Optional] Additional options to configure the request.
   *                             See `request` below.
   * @return {Promise}
   */
  async post(url, data = null, options = {}) {
    return this.request('post', url, data, options);
  }

  /**
   * Submit a request to the backend
   * @param  {String}  url       URL to fetch
   * @param  {Object}  data      [Optional] Data to include in the request
   * @param  {Object}  options   [Optional] Additional options to configure the request.
   *                             - wrapper {String} Wraps request data inside an object with the
   *                                                wrapper used as the key. Use 'none' to not
   *                                                wrap the data. (defaults to 'attributes'.)
   *
   * @return {Promise}           Resolves successfully on success, rejects on request failure.
   */
  async request(method, url, data = null, options = {}) {
    const wrapper = options.wrapper || 'attributes';
    const payload =
      method !== 'get' && wrapper !== 'none' && data !== null
        ? { [wrapper]: data }
        : data;
    return new Promise((resolve, reject) => {
      uhAPIClient[method]({
        url,
        data:
          method !== 'get' && payload !== null
            ? JSON.stringify(payload)
            : payload,
        success: resolve,
        error: reject,
      });
    });
  }
}

export default new BackendAPI();
