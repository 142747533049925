import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { OrderedSet } from 'immutable';
import { Dropdown, Grid, Typography } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import FieldErrors from 'shared/records/FieldErrors.jsx';

import { MembershipTierDataStore, MembershipDataStore } from 'dataStores';

const BILLING_LABEL = {
  0: '.lifetime',
  1: '.monthly',
  12: '.annually',
};

function MembershipItem({ membership }) {
  const intl = useIntl();
  const isFree = membership?.isFree();

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={7}>
        <Typography variant="body1" className="membership-option-name">
          {membership.get('name')}
        </Typography>
      </Grid>
      <Grid item container xs={5} justify="flex-end">
        <Typography variant="body1">
          {membership.get('tiered') ? (
            t('.tiered_pricing', intl, __filenamespace)
          ) : (
            <div>
              {!isFree && (
                <>
                  <FormattedCurrency value={membership.price} fromCents />/
                  {t(
                    BILLING_LABEL[membership.interval_months],
                    intl,
                    __filenamespace
                  )}
                </>
              )}
              {isFree && 'Free'}
            </div>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

function TerminationFields({
  errors,
  membershipsLoading,
  membershipTiersLoading,
  membershipIds,
  membershipTierIds,
  newMembershipId,
  newMembershipTierId,
  onChange,
  membershipDataStore,
  membershipTierDataStore,
}) {
  const intl = useIntl();
  const { memberships } = membershipDataStore;
  const { tiers } = membershipTierDataStore;
  const selectedMembership = memberships.get(newMembershipId);

  const items = membershipIds.map(id => ({
    label: <MembershipItem membership={memberships.get(id)} />,
    value: id,
  }));
  const tierItems = membershipTierIds.map(id => ({
    label: <MembershipItem membership={tiers.get(id)} />,
    value: id,
  }));

  return (
    <>
      <Dropdown
        fullWidth
        disabled={membershipsLoading}
        label={t('.new_membership', intl, __filenamespace)}
        errorText={errors.getErrors('new_membership_id')}
        value={newMembershipId}
        items={items.toArray()}
        onChange={e =>
          onChange('new_membership_id', parseInt(e.target.value, 10))
        }
      />
      {selectedMembership && selectedMembership.get('tiered') && (
        <Dropdown
          fullWidth
          disabled={membershipTiersLoading}
          label={t('.new_membership_tier', intl, __filenamespace)}
          errorText={errors.getErrors('new_membership_tier_id')}
          value={newMembershipTierId}
          items={tierItems.toArray()}
          onChange={e =>
            onChange('new_membership_tier_id', parseInt(e.target.value, 10))
          }
        />
      )}
    </>
  );
}

TerminationFields.propTypes = {
  errors: PropTypes.instanceOf(FieldErrors).isRequired,
  membershipIds: PropTypes.instanceOf(OrderedSet).isRequired,
  membershipTierIds: PropTypes.instanceOf(OrderedSet).isRequired,
  newMembershipId: PropTypes.number.isRequired,
  newMembershipTierId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  membershipDataStore: PropTypes.instanceOf(MembershipDataStore).isRequired,
  membershipTierDataStore: PropTypes.instanceOf(MembershipTierDataStore)
    .isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      membershipTierDataStore: MembershipTierDataStore,
      membershipDataStore: MembershipDataStore,
    },
  })
)(TerminationFields);
