class EventTypeActions {
  constructor() {
    this.generateActions(
      'fetchSuccess',
      'fetchError',
      'listSuccess',
      'listError'
    );
  }
}

export default alt.createActions(EventTypeActions);
