import React from 'react';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function EventSeasons({ seasons }) {
  return (
    <Box
      sx={{
        paddingBottom: '20px',
        marginBottom: '20px',
        borderBottom: `2px solid ${uhColors.dividerGrey}`,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          marginBottom: '20px',
          fontSize: '15px',
          color: 'rgb(127, 127, 127)',
        }}
      >
        <FormattedMessage id={messageId('.seasons', __filenamespace)} />
      </Box>
      <Box>{seasons}</Box>
    </Box>
  );
}

export default EventSeasons;
