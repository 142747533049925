/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import AltContainer from 'alt-container';
import { List } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { Grid } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import TriangleCheckmarkIcon from 'shared/components/icons/TriangleCheckmark.jsx';
import ExclusiveEventBenefits from 'memberships/components/_ExclusiveEventBenefits.jsx';
import CreditBenefits from 'memberships/components/_CreditBenefits.jsx';
import DiscountBenefits from 'memberships/components/_DiscountBenefits/_DiscountBenefits.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import LockIcon from 'shared/components/icons/LockIcon.jsx';
import PageHeader from 'shared/components/PageHeader.jsx';
import PurchaseDrawer from 'memberships/components/PurchaseDrawer.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import UnlockIcon from 'shared/components/icons/UnlockIcon.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';

import ClientProfileDrawer from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/ClientProfileDrawer.jsx';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { isUnauthorized } from 'routes/helpers/routePermissions.js';

import MembershipPurchasingActions from 'memberships/actions/MembershipPurchasingActions.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';

import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import MembershipListingStore from 'memberships/stores/MembershipListingStore.jsx';
import MembershipListingUIStore from 'memberships/stores/MembershipListingUIStore.jsx';
import MembershipPermissionStore from 'memberships/stores/MembershipPermissionStore.jsx';
import MembershipPurchasingStore from 'memberships/stores/MembershipPurchasingStore.jsx';
import SecondaryDrawerStore from 'shared/stores/SecondaryDrawerStore.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';

import ModalComponentMembership from './ModalComponentMemberhips.jsx';
import MembershipViewingAdmin from './MembershipViewingAdmin.jsx';
import MembershipCardWrapper from './MembershipCard/index.jsx';
import { ModalContent } from './MembershipViewing.jsx';

const styles = {
  actionButton: {
    height: 40,
    width: '100%',
  },
  actionButtonBs: {
    boxShadow: 'none',
  },
  sectionSpacing: {
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  actionButtonLabel: {
    padding: 0,
  },
  constStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  expanderLabel: {
    color: 'inherit',
    fontSize: 15,
    fontWeight: 'bold',
    margin: '0 40px',
  },

  lockUnlockIcon: {
    marginBottom: -1,
    marginRight: 5,
  },
  wrapper: {
    padding: '4.5rem 4.5rem 1rem 4.5rem',
    backgroundColor: '#E5E5E5',
    textAlign: 'center',
    marginTop: '-60px',
  },
  totalPrice: {
    color: 'black',
    fontWeight: 'bold',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  discountWrapper: {
    borderBottom: '1px solid #C5C5C5',
    margin: '0px  15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  enrolledProfilesTitle: {
    color: 'white',
    textAlign: 'center',
    fontSize: 20,
    paddingTop: '10px',
    fontFamily: 'benton-sans-condensed, sans-serif',
  },
  membershipNameStyles: {
    width: '80%',
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  descStyles: {
    padding: '10px 20px',
    textAlign: 'center',
    font: 'normal normal normal 16px/17px benton-sans-condensed',
    letterSpacing: '0px',
    color: '#8B8B8B',
    opacity: 1,
  },
  flatBUttonStyles: {
    backgroundColor: uhColors.aliceBlue,
    width: '100%',
    maxWidth: 320,
    height: 55,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    color: uhColors.charcoalBlack,
    padding: '0 30px',
  },
  discountStyles: {
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
    marginBottom: '-15px',
  },
  creditBenefits: {
    lineHeight: 1.2,
    fontSize: 15,
  },
  benefitsItem: {
    minWidth: 140,
    marginBottom: 10,
  },
};

const BILLING_LABEL = {
  0: '.lifetime',
  1: '.monthly',
  12: '.annually',
};

const paperStyles = (color, padding) => ({
  marginRight: 15,
  borderTopWidth: 4,
  borderTopColor: color,
  borderStyle: 'solid',
  fontSize: 15,
  lineHeight: '24px',
  position: 'relative',
  textAlign: 'center',
  height: '100%',
  paddingBottom: padding,
});

const pricingStyles = {
  pricingWrapper: {
    textAlign: 'center',
    backgroundColor: uhColors.leftNavGrey,
    color: 'white',
    width: '100%',
    minHeight: '175px',
  },
  triangle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 40,
    width: 40,
  },
  enrolledProfiles: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  membershipDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
};
const PageTitle = injectIntl(({ intl }) => (
  <PageHeader title={t('.header', intl, __filenamespace)} />
));

// eslint-disable-next-line no-shadow
const NewMembershipButton = injectIntl(({ intl, membershipListingStore }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={8} md={9} lg={9}>
      <ReactRouterLink
        to={customerScopedRoute('/memberships/new')}
        style={{ display: 'flex', width: 'fit-content' }}
      >
        <Button
          startIcon={<AddIcon sx={{ color: uhColors.activeBlue }} />}
          style={styles.flatBUttonStyles}
        >
          {t('.create_new', intl, __filenamespace)}
        </Button>
      </ReactRouterLink>
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={3}>
      <SearchBar
        onChange={e =>
          MembershipListingActions.membershipSearchHandler(e.target.value)
        }
        style={{
          padding: '0 24px',
          fontSize: 18,
          flex: '1 1 auto',
          color: uhColors.charcoalBlack,
          borderRadius: '50px',
          backgroundColor: uhColors.headerGrey,
        }}
        value={membershipListingStore?.getState()?.search}
      />
    </Grid>
  </Grid>
));

function PricingInfo({ price, label }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: 16, paddingTop: 10 }}>
        <FormattedCurrency value={price} fromCents />
      </div>
      <div style={{ color: uhColors.iconGrey }}>{label}</div>
    </div>
  );
}

const JoinFee = injectIntl(({ intl, fee }) => (
  <div style={{ textAlign: 'center', display: 'flex' }}>
    <div style={{ paddingTop: 12 }}>
      <AddIcon style={{ color: 'var(--color-white)' }} />
    </div>
    <PricingInfo price={fee} label={t('.join_fee', intl, __filenamespace)} />
  </div>
));

const PricingAndDescription = injectIntl(
  ({ membership, intl, activeMembers, showDivider, purchased }) => {
    const [isShown, setIsShown] = React.useState(false);
    return (
      <div style={pricingStyles.pricingWrapper}>
        {purchased && (
          <div
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setIsShown(true)}
            onClick={() => setIsShown(true)}
          >
            <TriangleCheckmarkIcon style={pricingStyles.triangle} />
          </div>
        )}
        {isShown ? (
          <>
            <div style={styles.enrolledProfilesTitle}>Enrolled Profiles</div>
            <div
              style={pricingStyles.enrolledProfiles}
              onMouseLeave={() => setIsShown(false)}
            >
              <ActiveCustomerUserList
                activeMembers={activeMembers}
                showDivider={showDivider}
              />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                fontFamily: 'benton-sans-condensed, sans-serif',
                padding: '15px 5px 0',
                fontSize: 20,
              }}
            >
              <p style={styles.membershipNameStyles}>{membership.name}</p>
            </div>
            {membership.price === 0 ? (
              <div style={{ fontSize: 16, paddingTop: 10 }}>
                <FormattedMessage id={messageId('.free', __filenamespace)} />
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PricingInfo
                  price={membership.price}
                  label={t(
                    BILLING_LABEL[membership.interval_months],
                    intl,
                    __filenamespace
                  )}
                />
                {membership.join_fee > 0 && (
                  <JoinFee fee={membership.join_fee} />
                )}
              </div>
            )}
            <div className="membership__description">
              <p style={pricingStyles.membershipDescription}>
                {membership.description}
              </p>
            </div>
          </>
        )}
      </div>
    );
  }
);

function Commitment({ length, style }) {
  return (
    <div style={merge({ fontStyle: 'italic' }, style)}>
      <FormattedMessage
        id={messageId('.commitment', __filenamespace)}
        values={{ length }}
      />
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
function FreeSessions() {
  return null;
}

function BundleDiscount() {
  return null;
}

function PurchaseButtonLabel({ membership, permitted, purchased }) {
  let icon;

  if (membership.invite_only) {
    icon = permitted ? (
      <UnlockIcon
        style={styles.lockUnlockIcon}
        color="var(--color-secondary)"
        alt="unlock icon"
      />
    ) : (
      <LockIcon style={styles.lockUnlockIcon} alt="lock icon" />
    );
  }

  let label;

  if (membership.invite_only && !permitted) {
    label = (
      <FormattedMessage id={messageId('.invite_only', __filenamespace)} />
    );
  } else if (purchased) {
    label = !membership.tiered ? (
      <FormattedMessage
        id={messageId(
          membership.isFree() ? '.enroll_more' : '.purchase_more',
          __filenamespace
        )}
      />
    ) : (
      <FormattedMessage id={messageId('.purchase_more', __filenamespace)} />
    );
  } else {
    label = !membership.tiered ? (
      <FormattedMessage
        id={messageId(
          membership.isFree() ? 'actions.enroll' : 'actions.purchase',
          __filenamespace
        )}
      />
    ) : (
      <FormattedMessage id={messageId('actions.purchase', __filenamespace)} />
    );
  }

  return (
    <FlexBoxJustify>
      <div style={{ whiteSpace: 'nowrap' }}>
        {icon}
        {label}
        {membership.isFree() && !membership.tiered && permitted && ' Free'}
      </div>
    </FlexBoxJustify>
  );
}

function PurchaseButton({
  membership,
  permitted,
  purchased,
  eventTypes,
  creditPasses,
  retailCategories,
  label,
  staffMemberButton,
}) {
  const navigate = useNavigate();
  const isClient = currentUser().isClient();
  const isUnauthorizedRoute = isUnauthorized();
  const labelStyle =
    purchased && (permitted || !membership.invite_only)
      ? { color: uhColors.activeBlue }
      : {};
  const Label = String(label).split('.')[1];
  const priceLabel = Label.charAt(0).toUpperCase() + Label.slice(1);
  const handleClick = () => {
    MembershipPurchasingActions.purchaseClicked({
      membership,
      buyerId: currentUser().customer_user_id,
    });

    if (membership.tiered) {
      MembershipListingActions.listMembershipTiers(membership.id);
    }
  };
  const [open, openModal] = React.useState(false);
  const openHandle = () => {
    if (isClient || isUnauthorizedRoute) {
      navigate(customerScopedRoute(`/client-memberships/${membership.id}`));
    } else {
      openModal(true);
    }
  };

  const closeHandle = () => {
    openModal(false);
  };
  const TotalPrice = membership.price / 100;

  return (
    <>
      <ModalComponentMembership isOpen={open} isClose={closeHandle}>
        <div className="mem-view">
          <div style={styles.wrapper}>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                wordBreak: 'break-all',
              }}
            >
              {membership.name}
            </p>
            <p style={styles.totalPrice}>{`$${TotalPrice}`}</p>
            <p style={{ color: uhColors.priceLabelColor }}>
              {membership.price === 0 ? 'Free' : priceLabel}
            </p>
          </div>
          {membership && (
            <>
              <p style={{ color: 'black' }}>
                {membership.commitment_months > 0 && (
                  <Commitment
                    length={membership.commitment_months}
                    style={{ margin: '20px 0', textAlign: 'center' }}
                  />
                )}
              </p>
              <ModalContent withBorder>
                <CreditBenefits
                  leftStyles
                  membership={membership}
                  creditBenefitsStyle={styles.creditBenefits}
                  styles={styles.benefitsItem}
                />
              </ModalContent>
            </>
          )}
          {(membership || eventTypes || retailCategories || creditPasses) && (
            <ModalContent withBorder>
              <DiscountBenefits
                membership={membership}
                eventTypes={eventTypes}
                retailCategories={retailCategories}
                customWrapper={{ lineHeight: 1.2 }}
                creditPasses={creditPasses}
              />
              <ExclusiveEventBenefits
                membership={membership}
                eventTypes={eventTypes}
                itemStyle={{ marginLeft: -4 }}
              />

              <BundleDiscount style={{ margin: '15px 0' }} />
            </ModalContent>
          )}
          <div style={styles.descStyles}>{membership.description}</div>
        </div>
      </ModalComponentMembership>
      <>
        {!staffMemberButton && (
          <Button
            fullWidth
            color={
              !purchased && (permitted || !membership.invite_only)
                ? 'primary'
                : 'default'
            }
            variant="contained"
            style={{
              ...styles.actionButton,
              ...styles.actionButtonBs,
              ...labelStyle,
            }}
            onClick={handleClick}
          >
            <PurchaseButtonLabel
              membership={membership}
              permitted={permitted}
              purchased={purchased}
            />
          </Button>
        )}
        <Button
          variant="contained"
          color={
            !purchased && (permitted || !membership.invite_only)
              ? 'primary'
              : 'default'
          }
          style={{
            ...styles.actionButton,
            ...styles.actionButtonBs,
            ...labelStyle,
          }}
          onClick={openHandle}
        >
          View Details
        </Button>
      </>
    </>
  );
}

function ViewButtonLabel({ membership }) {
  return (
    <ReactRouterLink
      to={customerScopedRoute(`/memberships/${membership.id}`)}
      style={{ textDecoration: 'none', color: 'white' }}
    >
      <FlexBoxJustify>
        <div>
          {membership.invite_only && (
            <LockIcon
              style={styles.lockUnlockIcon}
              alt="lock icon"
              color="white"
            />
          )}
          <FormattedMessage
            id={messageId('.members_count', __filenamespace)}
            values={{ count: membership.active_client_count }}
          />
        </div>
      </FlexBoxJustify>
    </ReactRouterLink>
  );
}

function ViewButton({
  membership,
  eventTypes,
  retailCategories,
  creditPasses,
  tiersLoading,
  membershipTiers,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    if (membership.tiered) {
      MembershipListingActions.listMembershipTiers(membership.id);
    }

    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ReactRouterLink
        to={customerScopedRoute(`/memberships/${membership.id}`)}
        style={{ width: '50%', textDecoration: 'none' }}
      >
        <Button
          fullWidth
          variant="contained"
          style={{ ...styles.actionButton, ...styles.actionButtonBs }}
        >
          <ViewButtonLabel membership={membership} />
        </Button>
      </ReactRouterLink>
      <div style={{ width: '50%' }}>
        <Button
          fullWidth
          variant="contained"
          style={{ ...styles.actionButton, ...styles.actionButtonBs }}
          onClick={handleOpen}
        >
          View Details
        </Button>
      </div>
      <MembershipViewingAdmin
        isOpen={isOpen}
        isClose={handleClose}
        membership={membership}
        eventTypes={eventTypes}
        creditPasses={creditPasses}
        retailCategories={retailCategories}
        membershipTiers={membershipTiers}
        membershipTiersLoading={tiersLoading}
      />
    </>
  );
}

function PrimaryActionButton({
  membership,
  retailCategories,
  eventTypes,
  creditPasses,
  readOnly,
  permitted,
  purchased,
  activeMembers,
  showDivider,
  staffMemberButton,
  tiersLoading,
  membershipTiers,
}) {
  return readOnly || staffMemberButton ? (
    <PurchaseButton
      membership={membership}
      permitted={permitted}
      purchased={purchased}
      eventTypes={eventTypes}
      creditPasses={creditPasses}
      retailCategories={retailCategories}
      label={BILLING_LABEL[membership.interval_months]}
      labels="View Details"
      activeMembers={activeMembers}
      showDivider={showDivider}
      staffMemberButton={staffMemberButton}
    />
  ) : (
    <ViewButton
      membership={membership}
      retailCategories={retailCategories}
      eventTypes={eventTypes}
      creditPasses={creditPasses}
      tiersLoading={tiersLoading}
      membershipTiers={membershipTiers}
    />
  );
}

function ActiveCustomerUserList({ activeMembers, style }) {
  return (
    <div style={style}>
      <MuiList>
        {activeMembers.map(member => (
          <ListItem
            key={member.id}
            style={{ paddingLeft: 3, color: 'white' }}
            onClick={() =>
              ClientProfileDrawerActions.mounted({ clientId: member.id })
            }
          >
            <ListItemIcon>
              <UserAvatar user={member} />
            </ListItemIcon>
            <ListItemText primary={member.name()} />
          </ListItem>
        ))}
      </MuiList>
    </div>
  );
}

const MembershipCard = injectIntl(
  ({
    activeMembers,
    eventTypes,
    creditPasses,
    retailCategories,
    membership,
    permitted,
    readOnly,
    tiersLoading,
    membershipTiers,
  }) => {
    const shouldShowBenefitsSection =
      membership.commitment_months > 0 || membership.hasBenefits;

    const shouldShowButton = !currentUser().isStaffMember();
    const staffMemberButton = currentUser().isStaffMember();

    return (
      <MembershipCardWrapper
        readOnly={readOnly}
        membership={membership}
        purchased={activeMembers.size > 0}
        actions={
          <>
            {shouldShowButton && (
              <PrimaryActionButton
                membership={membership}
                readOnly={readOnly}
                permitted={permitted}
                purchased={activeMembers.size > 0}
                eventTypes={eventTypes}
                creditPasses={creditPasses}
                retailCategories={retailCategories}
                activeMembers={activeMembers}
                showDivider={shouldShowBenefitsSection}
                tiersLoading={tiersLoading}
                membershipTiers={membershipTiers}
              />
            )}

            {staffMemberButton && (
              <PrimaryActionButton
                membership={membership}
                readOnly={readOnly}
                permitted={permitted}
                purchased={activeMembers.size > 0}
                eventTypes={eventTypes}
                creditPasses={creditPasses}
                retailCategories={retailCategories}
                activeMembers={activeMembers}
                showDivider={shouldShowBenefitsSection}
                staffMemberButton={staffMemberButton}
                tiersLoading={tiersLoading}
                membershipTiers={membershipTiers}
              />
            )}
          </>
        }
      />
    );
  }
);

function MembershipCards({
  memberships,
  eventTypes,
  creditPasses,
  retailCategories,
  readOnly,
  athleteStore,
  isPermittedToPurchase,
  membershipListingStore,
}) {
  const { tiersLoading, membershipTiers } = MembershipListingStore.getState();
  const athleteMembershipMap = athleteStore.allAthletes.groupBy(
    a => a.active_membership_id
  );
  return (
    <div className="membership-listing-cards-container">
      {memberships
        .filterNot(m => readOnly && m.hidden)
        .sortBy(m => -m.price)
        .map(m => (
          <MembershipCard
            key={m.id}
            activeMembers={athleteMembershipMap.get(m.id, List())}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
            membership={m}
            readOnly={readOnly}
            permitted={isPermittedToPurchase(m)}
            tiersLoading={tiersLoading}
            membershipTiers={membershipTiers}
          />
        ))}
    </div>
  );
}

const showSpinner = () => {
  const { isLoading } = EventTypeListingStore.getState();
  const { loading } = MembershipListingUIStore.getState();
  return isLoading || loading;
};

function Content({ readOnly, ...props }) {
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {!readOnly && <NewMembershipButton {...props} />}
      <SpinWhileLoading isLoading={showSpinner()}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <MembershipCards readOnly={readOnly} {...props} />
        <Paginator
          className="contacts__pagination"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          currentPage={MembershipListingStore.getState().page}
          perPage={MembershipListingStore.getState().perPage}
          totalCount={MembershipListingStore.getState().totalCount}
          onPageSelect={MembershipListingActions.pageSelected}
          pageLimit={0}
          showInfo
        />
      </SpinWhileLoading>
    </>
  );
}

function MembershipListing({ readOnly, intl }) {
  return (
    <div>
      {!readOnly && <PageTitle />}
      <div style={{ margin: LEFT_MARGIN_PIXELS }}>
        <AltContainer
          stores={{
            cartStore: CartStore,
            customerUser: () => ({
              store: CurrentContextStore,
              value: CurrentContextStore.getState().customerUser,
            }),
            drawer: SecondaryDrawerStore,
            ui: MembershipListingUIStore,
            isPermittedToPurchase: () => ({
              store: MembershipPermissionStore,
              value: MembershipPermissionStore.getState().isPermittedToPurchase,
            }),
            membershipPurchasing: MembershipPurchasingStore,
            athleteStore: AthleteStore,
            currentCustomerUser: () => ({
              store: CurrentContextStore,
              value: CurrentContextStore.getState().customerUser,
            }),
            memberships: () => ({
              store: MembershipListingStore,
              value: MembershipListingStore.getState().records,
            }),
            tiers: () => ({
              store: MembershipListingStore,
              value: MembershipListingStore.getState().membershipTiers,
            }),
            tiersLoading: () => ({
              store: MembershipListingStore,
              value: MembershipListingStore.getState().tiersLoading,
            }),
            membershipListingStore: () => ({
              store: MembershipListingStore,
              value: MembershipListingStore,
            }),
            retailCategories: () => ({
              store: RetailCategoryListingStore,
              value: RetailCategoryListingStore.getState().retailCategories,
            }),
            eventTypes: () => ({
              store: EventTypeListingStore,
              value: EventTypeListingStore.getState().eventTypes,
            }),
            creditPasses: () => ({
              store: CreditPassListingStore,
              value: CreditPassListingStore.getState().records,
            }),
          }}
          actions={SecondaryDrawerActions}
        >
          <Content readOnly={readOnly} />
          {/* Doing this because AltContainer doesn't seem to clone children which are booleans */}
          {readOnly ? (
            <PurchaseDrawer open title={t('.header', intl, __filenamespace)} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </AltContainer>
      </div>
    </div>
  );
}

export default injectIntl(MembershipListing);
