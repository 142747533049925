import moment from 'moment-timezone';
import { Map, Record } from 'immutable';
import StripeAddress from 'shared/records/StripeAddress.jsx';
import { isPlainObjectAt, merge } from 'shared/utils/ObjectUtils.jsx';

const isValidDOB = dob =>
  !!dob && !!dob.day && !!dob.year && (!!dob.month || dob.month === 0);

class LegalEntity extends Record({
  address: new StripeAddress(),
  personal_address: new StripeAddress(),
  dob: Map({ day: null, month: null, year: null }),
  date_of_birth: null,
  first_name: null,
  last_name: null,
  phone_number: null,
  business_name: null,
  business_tax_id: null,
  business_vat_id: null,
  ssn_last_4: null,
  type: null,
  business_tax_id_provided: false,
  business_vat_id_provided: false,
  personal_id_number_provided: false,
  ssn_last_4_provided: false,
}) {
  constructor(obj, opts) {
    if (!obj) {
      super(obj, opts);
    }

    if (isPlainObjectAt(obj, 'address')) {
      if (!obj.address.line1) {
        delete obj.address;
      } else {
        obj = merge({}, obj, { address: new StripeAddress(obj.address) });
      }
    }

    if (isPlainObjectAt(obj, 'personal_address')) {
      if (!obj.personal_address.line1) {
        delete obj.personal_address;
      } else {
        obj = merge({}, obj, {
          personal_address: new StripeAddress(obj.personal_address),
        });
      }
    }

    if (isPlainObjectAt(obj, 'dob') && isValidDOB(obj.dob)) {
      const dob = moment(new Date(obj.dob.year, obj.dob.month, obj.dob.day));
      obj = merge({}, obj, { dob: Map(obj.dob), date_of_birth: dob });
    }

    super(obj, opts);
  }

  dobFromDateOfBirth() {
    if (this.date_of_birth) {
      return {
        day: this.date_of_birth.format('D'),
        month: this.date_of_birth.format('M'),
        year: this.date_of_birth.format('Y'),
      };
    }
    return this.get('dob');
  }

  hasValidDOB() {
    return !!this.date_of_birth?.isValid() || isValidDOB(this.dob);
  }

  isBlank() {
    return (
      (!this.address || this.address.isBlank()) &&
      (!this.personal_address || this.personal_address.isBlank()) &&
      !this.business_name &&
      !this.business_tax_id &&
      !this.business_vat_id &&
      !this.ssn_last_4 &&
      !this.type
    );
  }

  isCompany() {
    return this.type === 'company';
  }

  isIndividual() {
    return this.type === 'individual';
  }

  toServer() {
    return this.remove('business_tax_id_provided')
      .remove('business_vat_id_provided')
      .remove('personal_id_number_provided')
      .remove('ssn_last_4_provided')
      .set('dob', this.dobFromDateOfBirth())
      .toJS();
  }
}

export default LegalEntity;
