import * as React from 'react';
import { Grid, TextField } from '@upperhand/playmaker';
import EventStaffActions from '../actions';

const onSearchChange = (_event, value) =>
  EventStaffActions.searchUpdated(value);

function Filters({ search }) {
  return (
    <div style={{ padding: 4 }}>
      <Grid container spacing={1}>
        <TextField
          fullWidth
          icon="search"
          onChange={onSearchChange}
          outline={false}
          placeholder="Search Staff"
          rounded
          value={search}
        />
      </Grid>
    </div>
  );
}

export default Filters;
