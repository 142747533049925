import * as React from 'react';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';

const styles = {
  StickyInnerDiv: (hovering, today) => ({
    position: 'absolute',
    display: 'flex',
    flexWrap: 'nowrap',
    flex: '1',
    alignItems: 'center',
    width: '100%',
    height: '42px',
    padding: '16px',
    borderTop: hovering ? `2px solid ${uhColors.activeBlue}` : 'none',
    borderRight: '1px solid #e3e3e3',
    borderBottom: '2px solid #e3e3e3',
    backgroundColor: 'white',
    zIndex: hovering ? 1 : 0,
    color: hovering || today ? uhColors.activeBlue : 'inherit',
    boxShadow: hovering
      ? 'rgba(0, 0, 0, 0.1) 0px 2px 6px, rgba(0, 0, 0, 0.15) 0px 2px 6px'
      : 'none',
    cursor: 'pointer',
    transition: 'all 300ms ease-in-out',
  }),
  DayText: {
    fontWeight: '600',
  },
  Sticky: {
    minWidth: '200px',
    cursor: 'pointer',
    position: 'sticky',
    zIndex: 1,
    top: 0,
  },
};

class HeaderCell extends React.Component {
  state = { hovering: false };

  handleOnMouseEnter = () => {
    this.setState({ hovering: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ hovering: false });
  };

  handleOnCellClick = date => {
    CalendarActions.setView(CALENDAR_TYPE.DAY);
    CalendarActions.setCalendarDate(date);
  };

  render() {
    const { weekday } = this.props;
    const { hovering } = this.state;
    const isToday = weekday.isSame(moment(), 'day');

    return (
      <div
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        onClick={_ => this.handleOnCellClick(weekday)}
        style={styles.Sticky}
      >
        <div style={styles.StickyInnerDiv(hovering, isToday)}>
          <span style={styles.DayText}>{weekday.format('ddd')}</span>
          <span>
            &nbsp;
            {weekday.format('MMM D')}
          </span>
        </div>
      </div>
    );
  }
}

export default injectIntl(HeaderCell);
