import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';

import Discount from 'shared/records/Discount';
import AddRemoveQuickDiscount from 'shared/components/checkout/_AddRemoveQuickDiscount.jsx';
import QuickDiscountForm from 'shared/components/checkout/QuickDiscountForm.jsx';
import AccountCreditsForm from 'shared/components/checkout/AccountCreditsForm.jsx';

import ViewEditLink from 'shared/components/checkout/_ViewEditLink.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';

import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';

class ManageOrderItem extends React.Component {
  onApplyDiscount = () => {
    const { item } = this.props;

    POSCartActions.manageDiscount({ item, applying: true });
  };

  onRemoveDiscount = () => {
    const { item } = this.props;

    POSCartActions.manageDiscount({ item });
  };

  onDiscountValueChange = (_, discountValue) => {
    const { item } = this.props;
    let value = parseFloat(discountValue);
    if (!value) {
      value = 0;
    }
    POSCartActions.updateManualDiscount(
      item,
      new Discount({ value, type: item.get('manual_discount').type })
    );
  };

  onDiscountTypeChange = type => {
    const { item } = this.props;
    POSCartActions.updateManualDiscount(item, new Discount({ value: 0, type }));
  };

  showDiscountForm = () => {
    const { item } = this.props;
    POSCartActions.updateManualDiscount(item, new Discount());
  };

  hideDiscountForm = () => {
    const { item } = this.props;
    POSCartActions.updateManualDiscount(item, null);
  };

  render() {
    const { item, purchased, onEdit, onView, intl, appliedAccountCredits } =
      this.props;
    const actions = currentUser().isStaff() ? POSCartActions : CartActions;

    const isQuickDiscountApplied =
      !!item.get('applied_adjustments').length &&
      item.get('applied_adjustments').some(a => a.isManual());
    const isQuickDiscountDisabled =
      (item.get('orderable_type') === 'membership_subscription_package' &&
        item.getIn(['orderable', 'subscribable_join_fee']) === 0) ||
      currentUser().isClient() ||
      purchased;
    const clientAccountCreditDisabled = Boolean(currentUser().managed_by_id);
    const isAccountCreditDisabled = purchased || clientAccountCreditDisabled;
    const isAccountCreditApplied = item.get('account_credit_amount', false);

    return (
      <Grid container spacing={1}>
        {(item.get('manual_discount') || isQuickDiscountApplied) && (
          <Grid item xs={12}>
            <QuickDiscountForm
              onApplyDiscount={this.onApplyDiscount}
              onDiscountTypeChange={this.onDiscountTypeChange}
              onDiscountValueChange={this.onDiscountValueChange}
              item={item}
              isQuickDiscountApplied={isQuickDiscountApplied}
              intl={intl}
            />
          </Grid>
        )}

        {!isQuickDiscountDisabled && (
          <Grid item container xs={12} justify="space-between">
            <Grid item xs={3} />
            <Grid item container xs={9} justify="flex-end">
              <AddRemoveQuickDiscount
                item={item}
                expanded={Boolean(
                  item.get('manual_discount') || isQuickDiscountApplied
                )}
                onAdd={this.showDiscountForm}
                onRemove={
                  isQuickDiscountApplied
                    ? this.onRemoveDiscount
                    : this.hideDiscountForm
                }
              />
            </Grid>
          </Grid>
        )}

        {item.get('account_credit_amount') !== null && (
          <Grid item xs={12}>
            <AccountCreditsForm
              item={item}
              appliedAccountCredits={appliedAccountCredits}
              onApply={actions.applyAccountCredits}
            />
          </Grid>
        )}

        <Grid item container xs={12} justify="space-between">
          <Grid item xs={3}>
            <ViewEditLink
              item={item}
              purchased={purchased}
              onEdit={onEdit}
              onView={onView}
            />
          </Grid>
          {!isAccountCreditDisabled && (
            <Grid item container xs={9} justify="flex-end">
              <AddRemoveQuickDiscount
                item={item}
                expanded={item.get('account_credit_amount') !== null}
                addLabel="Apply AccountCredit"
                removeLabel="Remove Credit"
                onAdd={() =>
                  actions.updateAccountCredits({
                    item,
                    creditsAmount: 0,
                  })
                }
                onRemove={() =>
                  isAccountCreditApplied
                    ? actions.applyAccountCredits({
                        item: item.set('account_credit_amount', null),
                      })
                    : actions.updateAccountCredits({
                        item,
                        creditsAmount: null,
                      })
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(ManageOrderItem);
