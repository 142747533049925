import * as React from 'react';
import { Grid, Spinner } from '@upperhand/playmaker';

import DotsMenu from './DotsMenu.jsx';
import PaymentPlanInfo from './PaymentPlanInfo.jsx';
import ScheduledPaymentsInfo from './ScheduledPaymentsInfo.jsx';

import '../../balanceDetailsDrawer/styles.scss';

function Content({
  dotsMenuAnchorEl,
  client,
  intl,
  isLoading,
  paymentPlan,
  scheduledPayments,
  events,
  eventTypes,
  pseudoBalance,
  balances,
  allPaymentsAreCancelled,
}) {
  return (
    <div className="payment-plan-details-drawer__content">
      {isLoading ? (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      ) : (
        <>
          <PaymentPlanInfo
            intl={intl}
            paymentPlan={paymentPlan}
            events={events}
            eventTypes={eventTypes}
            client={client}
          />
          <ScheduledPaymentsInfo
            intl={intl}
            scheduledPayments={scheduledPayments}
            balances={balances}
          />
          <DotsMenu
            intl={intl}
            anchorEl={dotsMenuAnchorEl}
            balance={pseudoBalance}
            showCancelAll={!allPaymentsAreCancelled}
          />
        </>
      )}
    </div>
  );
}

export default Content;
