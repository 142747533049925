import { Map } from 'immutable';
import ProductImage from 'shared/records/ProductImage.js';
import ProductImageActions from 'retail/actions/ProductImageActions.js';
import RetailEditingActions from 'retail/actions/RetailEditingActions.js';
import StoreActions from 'shared/actions/StoreActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class ProductImageStore extends UpperHandStore {
  constructor() {
    super();

    this.bindListeners({
      reset: StoreActions.prepareForReuse,
      listProductImages: RetailEditingActions.FETCH,
      productImageListSuccess: ProductImageActions.PRODUCT_IMAGE_LIST_SUCCESS,
      productImageListError: ProductImageActions.PRODUCT_IMAGE_LIST_ERROR,

      setProductId: RetailEditingActions.SAVE_OR_UPDATE_SUCCESS,

      productImageSaveSuccess: ProductImageActions.PRODUCT_IMAGE_SAVE_SUCCESS,
      productImageSaveError: ProductImageActions.PRODUCT_IMAGE_SAVE_ERROR,

      setFeatureImage: ProductImageActions.FEATURE_IMAGE_SELECTED,

      stageForDelete: ProductImageActions.DELETE_REQUESTED,
      performDelete: ProductImageActions.DELETE_CONFIRMED,
      abortDelete: ProductImageActions.DELETE_ABORTED,
      deleteSuccess: ProductImageActions.DELETE_SUCCESS,
      deleteError: ProductImageActions.DELETE_ERROR,

      uploadImage: ProductImageActions.IMAGE_SELECTED,
      createSuccess: ProductImageActions.PRODUCT_IMAGE_CREATE_SUCCESS,
      createError: ProductImageActions.PRODUCT_IMAGE_CREATE_ERROR,
    });
  }

  reset() {
    this.productImages = Map();
    this.featuredImageId = null;

    this.idToDelete = null;
    this.deleteConfirmationOpen = false;
  }

  listProductImages(productId) {
    this.productId = productId;
    this.isLoading = true;

    uhApiClient.get({
      url: `products/${productId}/product_images`,
      data: { per_page: 100 },
      success: ProductImageActions.productImageListSuccess,
      error: ProductImageActions.productImageListError,
    });
  }

  productImageListSuccess({
    product_images: productImages,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.isLoading = false;

    this.page = page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.productImages = Map().withMutations(m =>
      productImages.forEach(i => {
        m.set(i.id, new ProductImage(i));

        if (i.featured) {
          this.featuredImageId = i.id;
        }
      })
    );
  }

  productImageListError(...args) {
    this.notifyError('error listing product images', args);
  }

  setProductId(data) {
    this.productId = data.id;
  }

  setFeatureImage(id) {
    if (id !== this.featuredImageId) {
      if (this.featuredImageId) {
        this.productImages = this.productImages.setIn(
          [this.featuredImageId, 'featured'],
          false
        );
      }

      this.productImages = this.productImages.setIn([id, 'featured'], true);
      this.featuredImageId = id;

      uhApiClient.patch({
        url: `products/${this.productId}/product_images/${this.featuredImageId}`,
        data: JSON.stringify({
          attributes: this.productImages.get(this.featuredImageId).toJS(),
        }),
        success: ProductImageActions.productImageSaveSuccess,
        error: ProductImageActions.productImageSaveError,
      });
    }
  }

  productImageSaveSuccess(data) {
    this.productImages = this.productImages.set(
      data.id,
      new ProductImage(data)
    );
  }

  productImageSaveError(...args) {
    this.notifyError('error saving product image', args);
  }

  stageForDelete(id) {
    this.idToDelete = id;
    this.deleteConfirmationOpen = true;
  }

  performDelete() {
    if (!this.idToDelete) {
      this.deleteConfirmationOpen = false;
      return;
    }

    uhApiClient.delete({
      url: `products/${this.productId}/product_images/${this.idToDelete}`,
      success: {
        action: ProductImageActions.deleteSuccess,
        args: [this.idToDelete],
      },
      error: ProductImageActions.deleteError,
    });
  }

  abortDelete() {
    this.idToDelete = null;
    this.deleteConfirmationOpen = false;
  }

  deleteSuccess([_, productImageId]) {
    this.idToDelete = null;
    this.deleteConfirmationOpen = false;

    this.productImages = this.productImages.delete(productImageId);
  }

  deleteError(...args) {
    this.notifyError('error deleting product image', args);
    this.abortDelete();
  }

  async uploadImage(image) {
    this.imageToUpload = new ProductImage({ image });
    const payload = await this.imageToUpload.toServer();

    uhApiClient.post({
      url: `products/${this.productId}/product_images`,
      data: JSON.stringify({ attributes: payload }),
      success: ProductImageActions.productImageCreateSuccess,
      error: ProductImageActions.productImageCreateError,
    });
  }

  createSuccess(data) {
    this.productImages = this.productImages.set(
      data.id,
      new ProductImage(data)
    );
    this.imageToUpload = false;
  }

  createError(...args) {
    this.notifyError('error creating product image', args);
  }
}

export default alt.createStore(ProductImageStore, 'ProductImageStore');
