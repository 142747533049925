import { Map } from 'immutable';

import { MembershipTierActions } from 'sources';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';

class MembershipTierDataStore extends UpperHandStore {
  constructor() {
    super();

    this.tiers = Map();
    this.bindListeners({
      createSuccess: MembershipTierActions.createSuccess,
      createError: MembershipTierActions.createError,
      updateSuccess: MembershipTierActions.updateSuccess,
      updateError: MembershipTierActions.updateError,
      listSuccess: MembershipTierActions.listSuccess,
      listError: MembershipTierActions.listError,
      fetchSuccess: MembershipTierActions.fetchSuccess,
      fetchError: MembershipTierActions.fetchError,
      deleteSuccess: MembershipTierActions.deleteSuccess,
      deleteError: MembershipTierActions.deleteError,
    });
  }

  fetchSuccess(tier) {
    this.tiers = this.tiers.set(tier.id, tier);
  }

  fetchError(...args) {
    this.notifyError('Error fetching membership tier', args);
  }

  listSuccess({ membership_tiers: membershipTiers }) {
    const fetchedTiers = membershipTiers.reduce(
      (obj, tier) => obj.set(tier.get('id'), tier),
      Map()
    );

    this.tiers = this.tiers.merge(fetchedTiers);
  }

  listError(...args) {
    this.notifyError('Error listing membership tiers', args);
  }

  createSuccess(tier) {
    const { id } = tier;

    this.tiers = this.tiers.set(id, tier);
  }

  createError(...args) {
    this.notifyError('Error creating class', args);
  }

  updateSuccess(tier) {
    const { id } = tier;

    if (tier.best_value) {
      this.tiers = this.tiers.map(t => t.set('best_value', false));
    }

    this.tiers = this.tiers.set(id, tier);
  }

  updateError(...args) {
    this.notifyError('Error updating membership tier', args);
  }

  deleteSuccess({ id }) {
    this.tiers = this.tiers.delete(id);
  }

  deleteError(...args) {
    this.notifyError('Error deleting membership tier', args);
  }
}

export default alt.createStore(
  MembershipTierDataStore,
  'MembershipTierDataStore'
);
