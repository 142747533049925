/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  TextField,
  MaskedTextField,
  Dropdown,
  DateTimeField,
  Checkbox,
} from '@upperhand/playmaker';

import PasswordValidator from 'shared/utils/PasswordValidator.jsx';

import {
  formatPhoneToE164,
  cleanPhoneFormat,
} from 'shared/utils/FormattingUtils.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils.js';
import { getValue } from 'shared/utils/ObjectUtils.jsx';
import {
  Address1Field,
  Address2Field,
  CityField,
  StateField,
  PostalCodeField,
} from 'shared/components/AddressFields.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

const DOB_REQUIRED_HELP_LINK =
  'https://help.upperhand.com/why-date-of-birth-is-required';

export function Address1({
  classes,
  defaultValue,
  disabled,
  errorText,
  fieldName = 'line_1',
  onChange,
  userProfile,
}) {
  const address = userProfile.address || {};

  return (
    <Address1Field
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      name={fieldName}
      onChange={onChange}
      value={getValue(address[fieldName], defaultValue)}
    />
  );
}

export function Address2({
  classes,
  defaultValue,
  disabled,
  errorText,
  fieldName = 'line_2',
  onChange,
  userProfile,
}) {
  const address = userProfile.address || {};

  return (
    <Address2Field
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      name={fieldName}
      onChange={onChange}
      value={getValue(address[fieldName], defaultValue)}
    />
  );
}

export function City({
  classes,
  defaultValue,
  disabled,
  errorText,
  onChange,
  userProfile,
}) {
  const address = userProfile.address || {};

  return (
    <CityField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      onChange={onChange}
      value={getValue(address.city, defaultValue)}
    />
  );
}

export function PostalCode({
  classes,
  defaultValue,
  disabled,
  errorText,
  onChange,
  userProfile,
}) {
  const address = userProfile.address || {};

  return (
    <PostalCodeField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      onChange={onChange}
      value={getValue(address.postal_code, defaultValue)}
    />
  );
}

export function State({
  classes,
  defaultValue,
  disabled,
  errorText,
  onChange,
  userProfile,
}) {
  const address = userProfile.address || {};

  return (
    <StateField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      onChange={(e, value) => onChange(e, value, 'state')}
      useAbbreviations
      value={getValue(address.state, defaultValue)}
    />
  );
}

export function DateOfBirth({
  classes,
  disabled,
  errorText,
  label,
  onChange,
  userProfile,
}) {
  return (
    <DateTimeField
      autoOk
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      type="date"
      label={
        <div>
          {label || (
            <FormattedMessage
              id={messageId('.date_of_birth', __filenamespace)}
            />
          )}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={DOB_REQUIRED_HELP_LINK}
            style={{ color: 'var(--color-secondary)', pointerEvents: 'auto' }}
          >
            <FormattedMessage
              id={messageId('.required_by_law', __filenamespace)}
            />
          </a>
        </div>
      }
      onChange={value => {
        if (value) {
          value.tz(customerTZ(), true);
        }

        onChange(
          {
            target: {
              name: 'date_of_birth',
              attributes: { name: { value } },
            },
          },
          value,
          'date_of_birth'
        );
      }}
      name="date_of_birth"
      placeholder="MM/DD/YYYY"
      value={userProfile.date_of_birth}
      disableFuture
    />
  );
}

export const Email = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    label,
    onChange,
    userProfile,
  }) => (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || t('.email_address', intl, __filenamespace)}
      name="email"
      onChange={onChange}
      placeholder={t('.example_email', intl, __filenamespace)}
      type="email"
      value={getValue(userProfile.email, defaultValue)}
    />
  )
);

export const EnableLogin = injectIntl(
  ({ intl, classes, userProfile, onChange }) => {
    if (!userProfile.underThirteen()) {
      return (
        <Checkbox
          checked={userProfile.login_enabled}
          classes={classes}
          label={t('.separate_login', intl, __filenamespace)}
          name="login_enabled"
          onChange={onChange}
          size="1x"
        />
      );
    }

    return null;
  }
);

export const FirstName = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    label,
    onChange,
    placeholder,
    userProfile,
  }) => (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || t('.first_name', intl, __filenamespace)}
      name="first_name"
      onChange={onChange}
      placeholder={placeholder || t('.first_name', intl, __filenamespace)}
      value={getValue(userProfile.first_name, defaultValue)}
    />
  )
);

export const Gender = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    onChange,
    userProfile,
  }) => (
    <Dropdown
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={t('.gender', intl, __filenamespace)}
      placeholder={t('.select_gender', intl, __filenamespace)}
      name="gender"
      items={[
        {
          value: 'female',
          label: t('.female', intl, __filenamespace),
        },
        {
          value: 'male',
          label: t('.male', intl, __filenamespace),
        },
      ]}
      onChange={e => onChange(e, e.target.value, 'gender')}
      value={getValue(userProfile.gender, defaultValue)}
    />
  )
);

export const LastFourOfSSN = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    label,
    onChange,
    placeholder,
    userProfile,
  }) => (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || t('.ssn', intl, __filenamespace)}
      name="ssn_last_4"
      onChange={onChange}
      placeholder={placeholder || t('.zero', intl, __filenamespace)}
      value={getValue(userProfile.ssn_last_4, defaultValue)}
    />
  )
);

export const LastName = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    label,
    onChange,
    placeholder,
    userProfile,
  }) => (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || t('.last_name', intl, __filenamespace)}
      name="last_name"
      onChange={onChange}
      placeholder={placeholder || t('.last_name', intl, __filenamespace)}
      value={getValue(userProfile.last_name, defaultValue)}
    />
  )
);

export function Password({
  classes,
  disabled,
  errorText,
  label,
  onChange,
  placeholder,
  userProfile,
}) {
  return (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || 'Password'}
      name="password"
      onChange={onChange}
      placeholder={placeholder || 'Password'}
      type="password"
      value={userProfile.password || ''}
    />
  );
}

export function PasswordConfirmation({
  classes,
  disabled,
  errorText,
  label,
  onChange,
  placeholder,
  userProfile,
}) {
  return (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || 'Password Confirmation'}
      name="password_confirmation"
      onChange={onChange}
      placeholder={placeholder || 'Password Confirmation'}
      type="password"
      value={userProfile.password_confirmation || ''}
    />
  );
}

export const PhoneNumber = injectIntl(
  ({
    intl,
    classes,
    disabled,
    errorText,
    userProfile,
    defaultValue,
    label,
    placeholder,
    onChange,
  }) => (
    <MaskedTextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || t('.phone', intl, __filenamespace)}
      name="phone"
      placeholder={placeholder || t('.phone_number', intl, __filenamespace)}
      onChange={e => {
        const parsedValue = formatPhoneToE164(e.target.value);
        onChange(
          {
            target: {
              name: 'phone',
              value: parsedValue,
            },
          },
          parsedValue
        );
      }}
      value={cleanPhoneFormat(getValue(userProfile.phone, defaultValue))}
      mask="+1 999-999-9999"
    />
  )
);

export const SetPassword = injectIntl(
  ({ intl, onChange, classes, label, placeholder, userProfile, disabled }) => {
    const [validator, setValidator] = React.useState(
      new PasswordValidator(userProfile)
    );

    React.useEffect(() => {
      if (userProfile.password || userProfile.password_confirmation) {
        setValidator(new PasswordValidator(userProfile).validate());
      }
    }, [userProfile]);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Password
          classes={classes}
          disabled={disabled}
          errorText={validator.getErrors('password', intl)}
          label={label}
          onChange={onChange}
          placeholder={placeholder}
          userProfile={userProfile}
        />
        <PasswordConfirmation
          classes={classes}
          disabled={disabled}
          errorText={validator.getErrors('password_confirmation', intl)}
          label={label}
          onChange={onChange}
          placeholder={placeholder}
          userProfile={userProfile}
        />
      </div>
    );
  }
);
