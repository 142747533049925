import { Map } from 'immutable';

import ActionCable from 'actioncable';

import { getActiveToken } from 'shared/utils/AuthUtils';

let subscriptions = Map();
const cable = ActionCable.createConsumer(
  `${window.api_host}/cable`.replace('http', 'ws')
);

const getKey = connectionParams =>
  JSON.stringify(connectionParams, Object.keys(connectionParams).sort());

// Limitations: this makes us only able to use received and not any other
// attributes in the subscription success object.  Not sure if this matters, though.
export const subscribe = (connectionParams, success) => {
  // This should be equivalent to `identifier` of the Subscription object.
  const key = getKey(connectionParams);

  if (subscriptions.has(key) && !success) {
    return subscriptions.get(key);
  }
  if (subscriptions.has(key)) {
    const subscription = subscriptions.get(key);
    subscription.received = success;
    subscriptions.set(key, subscription);

    return subscription;
  }
  const subscription = cable.subscriptions.create(
    {
      ...connectionParams,
      token: getActiveToken(),
    },
    {
      received(data) {
        success(data);
      },
    }
  );

  subscriptions = subscriptions.set(key, subscription);

  return subscription;
};

export const unsubscribe = connectionParams => {
  const key = getKey(connectionParams);

  subscriptions.get(key).unsubscribe();
  subscriptions = subscriptions.delete(key);

  return key;
};
