import React from 'react';

import CreditSelector from 'credit_passes/components/editing/_CreditSelector.jsx';

function CreditsForm(props) {
  const {
    disabledAddingNewCredit,
    events,
    eventTypes,
    existingAllEventsCredits,
    intl,
    record,
    selectedEventIds,
    selectedEventTypeIds,
    specificEvents,
    unselectedEventTypes,
  } = props;
  return (
    <CreditSelector
      disabledAddingNewCredit={disabledAddingNewCredit}
      specificEvents={specificEvents}
      events={events}
      selectedEventIds={selectedEventIds}
      selectedEventTypeIds={selectedEventTypeIds}
      unselectedEventTypes={unselectedEventTypes}
      existingAllEventsCredits={existingAllEventsCredits}
      eventTypes={eventTypes}
      creditPass={record}
      intl={intl}
      expanded={!record.credit_pass_credits.isEmpty()}
    />
  );
}

export default CreditsForm;
