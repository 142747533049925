// eslint-disable-next-line import/prefer-default-export
export const getFieldStyles = (hasChildren, fieldId) => ({
  inputProps: hasChildren
    ? {
        startAdornment: hasChildren,
        sx: {
          height: '32px',
        },
      }
    : {},
  textFiledStyle: {
    marginBottom: '10px',
    input: {
      display: hasChildren ? 'none' : 'initial',
      fontSize: '16px',
      '&::placeholder': {
        color: 'var(--color-old-gray)',
        fontWeight: 'lighter',
        opacity: 0.7,
      },
    },
    [`#${fieldId}`]: {
      height: hasChildren ? '32px' : 'initial',
    },
  },
  inputLabelProps: {
    shrink: true,
    sx: {
      fontSize: '16px',
    },
  },
});
