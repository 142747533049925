import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { ContentModal } from '@upperhand/playmaker';
import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import ModalTitle from './ModalTitle.jsx';
import Coupon from '../Coupon.jsx';
import CouponActions from '../Actions';
import CouponStore from '../Store';

function ModalCreate({
  couponStore: { errors, isOpenCreateModal, updatedCoupon },
  intl,
}) {
  return (
    <ContentModal
      classes={{
        root: 'coupon__modal-create',
        content: 'create-coupon__modal-content',
        title: 'create-coupon__modal-title',
      }}
      open={isOpenCreateModal}
      fullScreen
      title={
        <ModalTitle
          actions={CouponActions}
          coupon={updatedCoupon}
          errors={errors}
          intl={intl}
        />
      }
      showActions={false}
      onSuccess={CouponActions.save}
      onAbort={CouponActions.cancelCreate}
    >
      <Coupon createModal editRoute />
    </ContentModal>
  );
}

ModalCreate.propTypes = {
  intl: PropTypes.object.isRequired,
  couponStore: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      couponStore: CouponStore,
    },
  })
)(ModalCreate);
