import { List, Map } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import ManagedClientActions from 'shared/actions/ManagedClientActions.jsx';
import { SessionActions } from 'sources';
import EventStaffActions from 'event_mgmt/display/actions/EventStaffActions.js';
import MembershipDiscountActions from 'event_mgmt/display/actions/MembershipDiscountActions.js';

import PurchaseDrawerActions from './Actions';

class PurchaseDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      openDrawer: PurchaseDrawerActions.openDrawer,
      closeDrawer: PurchaseDrawerActions.closeDrawer,
      cartStoreReady: [CartActions.fetchSuccess, CartActions.fetchError],
      automationTemplateDescriptionResult: [
        AutomationTemplateDescriptionActions.listSuccess,
        AutomationTemplateDescriptionActions.listError,
      ],
      eventFetchAvailabilityResult: [
        EventActions.error,
        EventActions.fetchAvailabilitySuccess,
      ],
      eventStoreReady: [EventActions.fetchSuccess, EventActions.error],
      athleteStoreReady: [
        AthleteActions.listSuccess,
        ManagedClientActions.createSuccess,
        AthleteActions.listError,
      ],
      sessionDataStoreReady: [
        SessionActions.listSuccess,
        SessionActions.listError,
      ],
      eventStaffStoreReady: [
        EventStaffActions.listSuccess,
        EventStaffActions.listError,
      ],
      membershipDiscountStoreReady: [
        MembershipDiscountActions.listSuccess,
        MembershipDiscountActions.listError,
      ],
    });
  }

  reset() {
    this.eventId = null;
    this.isOpen = false;
    this.isLoading = false;
    this.filteredAthletes = List();
    this.loadDetails = Map({
      cartStore: false,
      automationTemplateDescription: false,
      eventFetchAvailability: false,
      eventStore: false,
      athleteStore: false,
      sessionDataStore: false,
      eventStaffStore: false,
      membershipDiscountStore: false,
    });
  }

  updateLoading(key, value = true) {
    this.loadDetails = this.loadDetails.set(key, value);
    this.isLoading = !this.loadDetails.every(item => item);
    if (
      this.loadDetails.get('eventStore') &&
      this.loadDetails.get('athleteStore')
    ) {
      this.getAuthorizedProfiles();
    }
  }

  getAuthorizedProfiles() {
    this.waitFor(AthleteStore);
    this.waitFor(EventStore);
    const { allAthletes } = AthleteStore.getState();
    const { customerEvent } = EventStore.getState();
    if (customerEvent.isMembershipExclusive()) {
      this.filteredAthletes = allAthletes.filter(a =>
        a.hasAccessTo(customerEvent)
      );
    }
    this.filteredAthletes = allAthletes;
  }

  openDrawer() {
    this.isOpen = true;
    this.isLoading = !this.loadDetails.every(item => item);
  }

  closeDrawer() {
    this.reset();
  }

  cartStoreReady() {
    this.updateLoading('cartStore');
  }

  automationTemplateDescriptionResult() {
    this.updateLoading('automationTemplateDescription');
  }

  eventFetchAvailabilityResult() {
    this.updateLoading('eventFetchAvailability');
  }

  eventStoreReady() {
    this.updateLoading('eventStore');
  }

  athleteStoreReady() {
    this.updateLoading('athleteStore');
  }

  sessionDataStoreReady() {
    this.updateLoading('sessionDataStore');
  }

  eventStaffStoreReady() {
    this.updateLoading('eventStaffStore');
  }

  membershipDiscountStoreReady() {
    this.updateLoading('membershipDiscountStore');
  }
}

export default alt.createStore(PurchaseDrawerStore, 'NewPurchaseDrawerStore');
