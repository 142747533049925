import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import WaitlistCommitButton from 'event_mgmt/display/components/waitlist_drawer/WaitlistCommitButton.jsx';
import WDSimpleFixedScheduleContent from 'event_mgmt/display/components/waitlist_drawer/_WDSimpleFixedScheduleContent.jsx';
import WaitlistActions from 'event_mgmt/shared/actions/WaitlistActions.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function WDFixedScheduleContent({
  pdRegistrationPackageStore,
  eventStore,
  athletes,
  onRequestClose,
}) {
  const { allRegisteredAthleteIds, registrationPackage, tentativeAthleteIds } =
    pdRegistrationPackageStore;

  const { customerEvent } = eventStore;

  const selectableAthletes = athletes.filter(
    a => !allRegisteredAthleteIds.has(a.id)
  );

  const selectedAthletes = athletes.filter(a => tentativeAthleteIds.has(a.id));

  const handleAddToWaitlistClick = () => {
    const customerUserIds = selectedAthletes.map(ath => ath.id).toArray();
    WaitlistActions.append(customerEvent.id, customerUserIds);
    onRequestClose();
  };

  return (
    <div>
      <div style={{ marginBottom: '1.5em', lineHeight: '1.5em' }}>
        <FormattedMessage
          id={messageId('.waiting_list_full', __filenamespace)}
        />
      </div>

      <WDSimpleFixedScheduleContent
        event={customerEvent}
        selectableAthletes={selectableAthletes}
        selectedAthletes={selectedAthletes}
      />

      <WaitlistCommitButton
        itemIsValid={
          customerEvent.isFixedSchedule() &&
          registrationPackage.client_ids.size > 0
        }
        handleAddToWaitlistClick={handleAddToWaitlistClick}
      />
    </div>
  );
}

export default WDFixedScheduleContent;
