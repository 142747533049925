import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

function EmptyState({ intl }) {
  return (
    <div className="empty-state">
      <Typography className="empty-state__title" variant="h3">
        {t('.title', intl, __filenamespace)}
      </Typography>
      <Typography className="empty-state__description" variant="body3">
        {t('.description', intl, __filenamespace)}
      </Typography>
    </div>
  );
}

EmptyState.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default EmptyState;
