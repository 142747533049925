import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon } from '@upperhand/playmaker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material//Divider';
import Button from '@mui/material/Button';

import FilteredSelection from 'shared/records/FilteredSelection.js';

import ButtonMenu from 'shared/components/ButtonMenu.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  buttonStyle: {
    margin: '10px 0',
  },
  labelStyle: {
    fontSize: 15,
    fontWeight: 'bold',
    color: uhColors.activeBlue,
  },
  iconStyle: {
    height: 18,
    width: 18,
  },
  menuItem: {
    padding: '10px 16px',
    width: '100%',
  },
  menuItemLabel: {
    fontSize: 15,
  },
};

class ExportMenu extends React.PureComponent {
  handleClickAll = value => {
    const { onChange, filteredSelection } = this.props;
    onChange(filteredSelection.changeSelectionAll(value));
  };

  handleClick = (key, value) => {
    const { onChange, filteredSelection } = this.props;
    onChange(filteredSelection.changeSelectionItem(key, value));
  };

  handleExport = () => {
    const { onExport } = this.props;
    onExport();
  };

  isExportDisabled = () => {
    const { filteredSelection } = this.props;
    return filteredSelection.selected.size > 0;
  };

  render() {
    const { intl, label, filteredSelection } = this.props;

    return (
      <ButtonMenu
        closeOnSelect={false}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        label={label}
        icon={
          <Icon
            name="cloudDownload"
            className="inventory__export-button-icon"
          />
        }
        buttonType="tertiary"
      >
        <FormGroup>
          <FormControlLabel
            key="all"
            control={<Checkbox />}
            label={t('.all', intl, __filenamespace)}
            checked={filteredSelection.isSelectedAll()}
            onChange={e => this.handleClickAll(e.target.checked)}
            style={styles.menuItem}
          />
          <Divider />
          {filteredSelection.get('source').map(item => (
            <FormControlLabel
              key={item.key}
              control={<Checkbox />}
              label={item.value.toString({ intl })}
              checked={filteredSelection.isSelectedItem(item.key)}
              onChange={e => this.handleClick(item.key, e.target.checked)}
              style={styles.menuItem}
            />
          ))}
        </FormGroup>
        <Button
          fullWidth
          onClick={this.handleExport}
          disabled={!this.isExportDisabled()}
        >
          {t('.btn', intl, __filenamespace)}
        </Button>
      </ButtonMenu>
    );
  }
}

ExportMenu.propTypes = {
  intl: PropTypes.object,
  filteredSelection: PropTypes.instanceOf(FilteredSelection).isRequired,
  onChange: PropTypes.func,
  onExport: PropTypes.func,
};

ExportMenu.defaultProps = {
  intl: {},
  onChange: () => {},
  onExport: () => {},
};

export default injectIntl(ExportMenu);
