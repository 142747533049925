import moment from 'moment-timezone';
import { Map } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { SessionSource, SessionActions, ScheduleSource } from 'sources';

import {
  SessionDataStore,
  EventDataStore,
  ScheduleDataStore,
} from 'dataStores';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import EditDateTimeActions from './actions';
import SessionSummaryDrawerActions from '../../Actions';

const SCHEDULING_UNITS = {
  MIN: 'min',
  HOUR: 'hour',
  DAY: 'day',
};

const SCHEDULING_TIME_FRAME_UNITS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const setDate = (date, value) =>
  value.year(date.year()).month(date.month()).date(date.date());

class EditDateTimeStore extends UpperHandStore {
  constructor() {
    super();

    this.sessionId = null;
    this.changed = false;
    this.isConfirmationOpen = false;

    this.data = Map({
      date: null,
      startTime: null,
      endTime: null,
      note: '',
      client_ids: [],
    });
    this.locationId = null;

    this.bindListeners({
      mounted: EditDateTimeActions.mounted,
      update: EditDateTimeActions.update,
      cancelChanges: EditDateTimeActions.cancelChanges,
      checkConfirmation: EditDateTimeActions.checkConfirmation,
      saveChanges: EditDateTimeActions.saveChanges,
      sessionUpdateError: EditDateTimeActions.sessionUpdateError,
      sessionUpdateSuccess: EditDateTimeActions.sessionUpdateSuccess,
      sessionFetchSuccess: SessionActions.fetchSuccess,
      setSchedulingUnit: EditDateTimeActions.setSchedulingUnit,
      setCancellationUnit: EditDateTimeActions.setCancellationUnit,
      setSchedulingTimeFrameUnit:
        EditDateTimeActions.setSchedulingTimeFrameUnit,
      handleLocationChange: EditDateTimeActions.handleLocationChange,
      scheduleUpdateError: EditDateTimeActions.scheduleUpdateError,
      scheduleUpdateSuccess: EditDateTimeActions.scheduleUpdateSuccess,
    });
  }

  reset() {
    this.data = this.data.set('note', '');
    this.changed = false;
    this.isConfirmationOpen = false;
    this.scheduleDeadlineUnit = SCHEDULING_UNITS.DAY;
    this.cancellationDeadlineUnit = SCHEDULING_UNITS.HOUR;
    this.schedulingTimeFrameUnit = SCHEDULING_TIME_FRAME_UNITS.DAY;
  }

  mounted(sessionId) {
    this.sessionId = sessionId;
    this.reset();

    const { sessions } = SessionDataStore.getState();
    const session = sessions.get(sessionId);

    if (session) {
      this.setInitialData(session);
    }
  }

  setInitialData(session) {
    const schedulingValue = session.get('scheduling_deadline', null);
    const cancellationValue = session.get('cancellation_deadline', null);
    const schedulingTimeFrameValue = session.get('scheduling_timeframe', null);
    const { events } = EventDataStore.getState();
    const event = events.get(session.get('event_id'));

    if (schedulingValue) {
      this.setDeadlineUnit({
        deadlineValue: schedulingValue,
        unitFiled: 'scheduleDeadlineUnit',
      });
    }

    if (cancellationValue) {
      this.setDeadlineUnit({
        deadlineValue: cancellationValue,
        unitFiled: 'cancellationDeadlineUnit',
        excludeDays: true,
      });
    }

    if (schedulingTimeFrameValue) {
      this.setTimeFrameUnit({ timeFrameValue: schedulingTimeFrameValue });
    }

    this.data = this.data
      .set('date', session.starts_at.clone())
      .set('startTime', session.starts_at.clone().tz(customerTZ()))
      .set('endTime', session.ends_at.clone().tz(customerTZ()))
      .set('max_size', session.get('max_size'))
      .set('client_ids', session.get('client_ids'))
      .set(
        'scheduling_deadline',
        this.calculateDeadline({
          key: 'scheduling_deadline',
          fromSeconds: true,
          deadline: schedulingValue,
        })
      )
      .set(
        'cancellation_deadline',
        this.calculateDeadline({
          fromSeconds: true,
          unitField: 'cancellationDeadlineUnit',
          deadline: cancellationValue,
        })
      )
      .set(
        'scheduling_timeframe',
        this.calculateTimeFrame({
          timeFrameValue: schedulingTimeFrameValue,
          fromDays: true,
        })
      )
      .set('is_fixed_schedule', event?.isFixedSchedule())
      .set('is_ssp_on', event?.isSSPTurnedOn());
  }

  calculateDeadline({
    fromSeconds = false,
    unitField = 'scheduleDeadlineUnit',
    deadline = null,
  }) {
    if (this[unitField] === SCHEDULING_UNITS.MIN && deadline) {
      return fromSeconds ? deadline / 60 : deadline * 60;
    }

    if (this[unitField] === SCHEDULING_UNITS.HOUR && deadline) {
      return fromSeconds ? deadline / 3600 : deadline * 3600;
    }

    if (this[unitField] === SCHEDULING_UNITS.DAY && deadline) {
      return fromSeconds ? deadline / 86400 : deadline * 86400;
    }
    return deadline;
  }

  calculateTimeFrame({ timeFrameValue, fromDays = false }) {
    if (this.schedulingTimeFrameUnit === SCHEDULING_TIME_FRAME_UNITS.MONTH) {
      return fromDays ? timeFrameValue / 30 : timeFrameValue * 30;
    }

    if (this.schedulingTimeFrameUnit === SCHEDULING_TIME_FRAME_UNITS.WEEK) {
      return fromDays ? timeFrameValue / 7 : timeFrameValue * 7;
    }

    return timeFrameValue;
  }

  setDeadlineUnit({ deadlineValue, unitFiled, excludeDays = false }) {
    if (deadlineValue % 86400 === 0 && !excludeDays) {
      this[unitFiled] = SCHEDULING_UNITS.DAY;
    } else if (deadlineValue % 3600 === 0) {
      this[unitFiled] = SCHEDULING_UNITS.HOUR;
    } else {
      this[unitFiled] = SCHEDULING_UNITS.MIN;
    }
  }

  setTimeFrameUnit({ timeFrameValue }) {
    if (timeFrameValue % 30 === 0) {
      this.schedulingTimeFrameUnit = SCHEDULING_TIME_FRAME_UNITS.MONTH;
    } else if (timeFrameValue % 7 === 0) {
      this.schedulingTimeFrameUnit = SCHEDULING_TIME_FRAME_UNITS.WEEK;
    } else {
      this.schedulingTimeFrameUnit = SCHEDULING_TIME_FRAME_UNITS.DAY;
    }
  }

  update([key, value]) {
    if (key === 'date') {
      const date = moment(new Date(value).toISOString());
      this.data = this.data
        .set('date', date)
        .set('startTime', setDate(date, this.data.get('startTime')))
        .set('endTime', setDate(date, this.data.get('endTime')));
    } else if (key === 'startTime' || key === 'endTime') {
      // Get previous values and set selected date
      let startTime = setDate(
        this.data.get('date'),
        this.data.get('startTime')
      );
      let endTime = setDate(this.data.get('date'), this.data.get('endTime'));

      // Get new value and set selected date
      const newValue = setDate(
        this.data.get('date'),
        moment(new Date(value).toISOString()).tz(customerTZ())
      );

      // Set new value
      if (key === 'startTime') {
        startTime = newValue;
      }

      if (key === 'endTime') {
        endTime = newValue;
      }

      const validateStartTime =
        key === 'endTime' &&
        (endTime.isBefore(startTime) || endTime.isSame(startTime));

      if (validateStartTime) {
        const duration = this.getSessionDuration();

        startTime = endTime.clone().subtract(duration.as('m'), 'm');
      }

      const validateEndTime =
        key === 'startTime' &&
        (startTime.isAfter(endTime) || startTime.isSame(endTime));

      if (validateEndTime) {
        const duration = this.getSessionDuration();

        endTime = startTime.clone().add(duration.as('m'), 'm');
      }

      // Save values
      this.data = this.data.set('startTime', startTime).set('endTime', endTime);
    } else {
      this.data = this.data.set(key, value);
    }

    this.changed = true;
  }

  getSessionDuration() {
    const { sessions } = SessionDataStore.getState();

    return sessions.get(this.sessionId).duration();
  }

  cancelChanges() {
    this.reset();
  }

  checkConfirmation({ isTeamEvent }) {
    const { sessions } = SessionDataStore.getState();
    const { schedules } = ScheduleDataStore.getState();
    const session = sessions.get(this.sessionId);
    const schedule = schedules.get(session.get('schedule_id'));
    const hasLocationUpdated =
      isTeamEvent &&
      this.locationId &&
      schedule?.location?.id !== this.locationId;
    if (hasLocationUpdated) {
      ScheduleSource.patch({
        id: schedule.id,
        params: { attributes: { location_id: this.locationId } },
        success: EditDateTimeActions.scheduleUpdateSuccess,
        error: EditDateTimeActions.scheduleUpdateError,
      });
    }
    if (session) {
      const clientsCount = session.client_ids.count();
      if (clientsCount > 0) {
        this.isConfirmationOpen = true;
      } else {
        this.saveChanges();
      }
    } else {
      this.cancelChanges();
    }
  }

  saveChanges() {
    const { sessions } = SessionDataStore.getState();
    const session = sessions.get(this.sessionId);
    const changes = {
      starts_at: this.data.get('startTime'),
      ends_at: this.data.get('endTime'),
      max_size: this.data.get('max_size'),
      scheduling_deadline: this.calculateDeadline({
        deadline: this.data.get('scheduling_deadline'),
      }),
      cancellation_deadline: this.calculateDeadline({
        unitField: 'cancellationDeadlineUnit',
        deadline: this.data.get('cancellation_deadline'),
      }),
      scheduling_timeframe: this.calculateTimeFrame({
        timeFrameValue: this.data.get('scheduling_timeframe'),
      }),
    };

    if (session) {
      SessionSource.put({
        id: this.sessionId,
        params: {
          attributes: session.merge(changes),
          reason: this.data.get('note'),
          fields: ['note'],
        },
        success: EditDateTimeActions.sessionUpdateSuccess,
        error: EditDateTimeActions.sessionUpdateError,
      });
    } else {
      this.cancelChanges();
    }
  }

  sessionUpdateSuccess() {
    MessageWindowActions.addMessage.defer('Session updated successfully');
    this.reset();
    SessionSummaryDrawerActions.setEditMode.defer(false);
  }

  sessionUpdateError(...args) {
    this.notifyError('Error during updating session', args);
  }

  // eslint-disable-next-line class-methods-use-this
  scheduleUpdateSuccess() {
    MessageWindowActions.addMessage.defer(
      'Schedule location  updated successfully'
    );
  }

  scheduleUpdateError(...args) {
    this.notifyError('Error during updating schedule location', args);
  }

  sessionFetchSuccess(session) {
    this.setInitialData(session);
  }

  setSchedulingUnit(unit) {
    this.scheduleDeadlineUnit = unit;
    this.changed = true;
  }

  setCancellationUnit(unit) {
    this.cancellationDeadlineUnit = unit;
    this.changed = true;
  }

  setSchedulingTimeFrameUnit(unit) {
    this.schedulingTimeFrameUnit = unit;
    this.changed = true;
  }

  handleLocationChange(locationId) {
    this.locationId = locationId;
    this.changed = true;
  }
}

export default alt.createStore(EditDateTimeStore, 'EditDateTimeStore');
