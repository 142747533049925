import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  button: {
    padding: '0',
    height: '20px',
    width: '20px',
  },

  icon: {
    height: '20px',
    width: '20px',
  },

  memberPrice: {
    backgroundColor: '#E5E5E5',
    minHeight: 32,
    fontWeight: 'bold',
    fontSize: 15,
  },

  profileCard: {
    marginBottom: 10,
  },

  profileCardContentContainer: {
    padding: 10,
    alignItems: 'center',
  },
};

function MemberPrice({ eventDiscount, eventPrice }) {
  return (
    <FlexBoxJustify
      style={merge(styles.profileCardContentContainer, styles.memberPrice)}
    >
      <FormattedMessage id={messageId('.member_price', __filenamespace)} />
      <FormattedCurrency
        value={eventDiscount.discount.applyTo(eventPrice * 100)}
        fromCents
      />
    </FlexBoxJustify>
  );
}

function ProfileCard({ profile, eventDiscount, eventPrice, onRemove }) {
  return (
    <Paper style={styles.profileCard} key={profile.id}>
      <FlexBoxJustify
        key={profile.id}
        style={styles.profileCardContentContainer}
      >
        <AvatarWithName user={profile} />
        <IconButton
          iconStyle={styles.icon}
          onClick={() => onRemove(profile.id)}
          style={styles.button}
        >
          <ClearIcon sx={{ color: uhColors.iconGrey }} />
        </IconButton>
      </FlexBoxJustify>

      {eventDiscount && (
        <MemberPrice eventDiscount={eventDiscount} eventPrice={eventPrice} />
      )}
    </Paper>
  );
}

function FixedScheduleProfileList({
  profiles,
  eventDiscounts,
  eventPrice,
  onRemove,
}) {
  return (
    <div>
      {profiles.map(profile => {
        const eventDiscount = eventDiscounts.find(
          discount => discount.membership_id === profile.active_membership_id
        );

        return (
          <ProfileCard
            key={profile.id}
            onRemove={onRemove}
            eventDiscount={eventDiscount}
            eventPrice={eventPrice}
            profile={profile}
          />
        );
      })}
    </div>
  );
}

FixedScheduleProfileList.defaultProps = {
  eventDiscounts: List(),
  eventPrice: 0,
};

export default FixedScheduleProfileList;
