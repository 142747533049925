import { List, Record } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';

class RegistrationField extends Record({
  id: null,
  category: '',
  dirty: false,
  errors: new FieldErrors(),
  field_type: '',
  marked_for_deletion: false,
  meta_registration_field_id: null,
  name: '',
  options: List(),
  options_listable: false,
  ordinal: 0,
  required: false,
  selected: false,
  text: null,
}) {
  clearErrors() {
    return this.remove('errors'); // resets errors to the default
  }

  hasErrors() {
    return !!this.errors && !this.errors.isEmpty();
  }

  isBlank() {
    return !this.id;
  }

  isListable() {
    return !!this.options_listable;
  }

  isMarkedForDeletion() {
    return !!this.marked_for_deletion;
  }

  isOptional() {
    return !this.isRequired();
  }

  isPresent() {
    return !this.isBlank();
  }

  isRequired() {
    return this.required;
  }

  markForDeletion(newValue = true) {
    return this.set('marked_for_deletion', newValue);
  }

  optionsList() {
    if (!this.isListable()) {
      return List();
    }
    return List(this.options);
  }

  toServer() {
    const data = this.toJS();
    delete data.dirty;
    delete data.errors;
    delete data.selected;
    return data;
  }

  validate() {
    if (!this.isListable()) {
      return this;
    }

    let errors = new FieldErrors();

    if (!this.options || !this.options.length) {
      errors = errors.add('options', 'Required');
    }

    if (errors.isEmpty()) {
      return this;
    }
    return this.set('errors', errors);
  }
}

export default RegistrationField;
