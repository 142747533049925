import * as React from 'react';
import { injectIntl } from 'react-intl';

import CommissionRateCard from 'contacts/index/components/commission_rates/CommissionRateCard.jsx';
import CommissionRateForm from 'contacts/index/components/commission_rates/CommissionRateForm.jsx';

const CommissionRateList = injectIntl(
  ({
    commissionRateCreationStore: { commissionRate },
    commissionRateEditingStore,
    commissionRateListingStore: { commissionRates, selectedItemIds, items },
    intl,
  }) => (
    <div>
      {commissionRates.map(cr => {
        if (
          commissionRateEditingStore.commissionRate &&
          commissionRateEditingStore.commissionRate.id === cr.id
        ) {
          return (
            <CommissionRateForm
              selectedItems={items
                .filter(i =>
                  commissionRateEditingStore.commissionRate.item_ids.has(i.id)
                )
                .groupBy(item => item.id)
                .map(group => group.first())
                .toList()}
              commissionRateStore={commissionRateEditingStore}
              key={cr.id}
              selectedItemIds={selectedItemIds}
              style={{ marginBottom: 10 }}
              intl={intl}
              items={items}
            />
          );
        }
        return (
          <CommissionRateCard
            selectedItems={items
              .filter(e => cr.item_ids.has(e.id))
              .groupBy(item => item.id)
              .map(group => group.first())
              .toList()}
            disabledEditing={commissionRate}
            commissionRate={cr}
            key={cr.id}
            selectedItemIds={selectedItemIds}
            style={{ marginBottom: 10 }}
            intl={intl}
          />
        );
      })}
    </div>
  )
);

export default CommissionRateList;
