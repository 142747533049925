export default alt.generateActions(
  'posClicked',
  'drawerDismissed',
  'addClientClick',
  'cancelAddClientClick',
  'addProfileClick',
  'cancelAddProfileClick',
  'clientSelected',
  'managedProfilesListSuccess',
  'managedProfilesListError',
  'clientBackClicked',
  'switchToCheckout',
  'proRateCheck',
  'onAgreementSignedSuccess'
);
