import * as React from 'react';
import { injectIntl } from 'react-intl';

import { TextField, Checkbox } from '@upperhand/playmaker';

import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  ViewProperties: {
    padding: '0 0 24px',
  },
};

const handleTitleChange = (e, onTitleChange) => {
  const { value } = e.target;

  onTitleChange(value);
};

const handleDefaultChange = value => {
  CalendarViewActions.updateCurrentViewAttribute({
    attributeKey: 'default_view',
    value,
  });
};

function ViewProperties({
  calendarView,
  currentViewTitle,
  onTitleChange,
  intl,
}) {
  return (
    <div style={styles.ViewProperties}>
      {(calendarView.id >= 0 || calendarView.id === undefined) && (
        <div>
          <TextField
            fullWidth
            value={currentViewTitle}
            label={t('.view_name', intl, __filenamespace)}
            onChange={e => handleTitleChange(e, onTitleChange)}
          />
        </div>
      )}
      <div>
        <Checkbox
          label={t('.make_default_view', intl, __filenamespace)}
          checked={calendarView.default_view}
          onChange={(_, checked) => handleDefaultChange(checked)}
        />
      </div>
    </div>
  );
}

export default injectIntl(ViewProperties);
