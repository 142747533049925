import * as React from 'react';
import {
  Outlet,
  Router,
  Route,
  Navigate,
  Routes as ReactRoutes,
} from 'react-router-dom';

import AppContext from 'AppContext.jsx';
import BaseLayout from 'layouts/BaseLayout.jsx';
import NotFound from 'not_found/NotFound.jsx';

import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

import history from 'routes/History.js';

import accountsRoutes from 'routes/accountsRoutes.jsx';
import customersRoutes from 'routes/customersRoutes.jsx';

function UhRouter({ basename, children, customHistory }) {
  const [state, setState] = React.useState({
    action: customHistory.action,
    location: customHistory.location,
  });

  React.useLayoutEffect(() => customHistory.listen(setState), [customHistory]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={customHistory}
    >
      {children}
    </Router>
  );
}

function CheckIsLoggedIn() {
  return (
    <Navigate to={isLoggedIn() ? '/accounts/choose' : '/accounts/login'} />
  );
}

function Help() {
  window.location.href = currentCustomer().helpdesk_url;
  return null;
}

function Privacy() {
  window.location.href = window.upperHandPrivacyUrl;
  return null;
}

/**
 * Highest level of routing component. this is the main app routing
 */
function Routes() {
  return (
    <UhRouter customHistory={history} location={history.location}>
      <ReactRoutes>
        <Route
          path="/customers"
          element={
            <AppContext>
              <BaseLayout />
              <Outlet />
            </AppContext>
          }
        >
          {customersRoutes()}
        </Route>
        <Route path="/accounts">{accountsRoutes()}</Route>
        <Route path="/help" element={<Help />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/" element={<CheckIsLoggedIn />} />
        <Route path="*" element={<NotFound />} />
      </ReactRoutes>
    </UhRouter>
  );
}

export default Routes;
