import * as React from 'react';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { SetPassword } from 'user_management/shared/components/UserProfileFields.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import ResetPasswordActions from '../Actions';

function MobileResetRequest({
  password,
  // eslint-disable-next-line camelcase
  password_confirmation,
  requestStatus,
  changePassword,
  validPasswords,
}) {
  return (
    <FlexBoxCenter style={{ width: '100%', flexDirection: 'column' }}>
      <FlexBoxCenter style={{ flex: '1 1 auto', flexDirection: 'column' }}>
        <div
          style={{ paddingBottom: '3em', paddingTop: '2em', width: '300px' }}
        >
          <SetPassword
            style={{ width: 256, marginBottom: '1em' }}
            userProfile={{
              password,
              // eslint-disable-next-line camelcase
              password_confirmation,
            }}
            onChange={handleChange({ actions: ResetPasswordActions })}
          />
        </div>
      </FlexBoxCenter>
      <FlexBoxCenter
        style={{
          width: '100%',
          flex: ' 0 1 80px',
        }}
      >
        <StateChangingButton
          color="dark"
          label="Change Password"
          labelInProgress="Changing password..."
          inProgress={requestStatus === 'sending'}
          disabled={requestStatus === 'sending' || !validPasswords()}
          onClick={changePassword}
          style={{
            width: '100%',
            height: 80,
            color: 'white',
            fontSize: '22px',
            borderRadius: '0',
          }}
          type="submit"
        />
      </FlexBoxCenter>
    </FlexBoxCenter>
  );
}

export default MobileResetRequest;
