import moment from 'moment-timezone';
import { List, Map, fromJS } from 'immutable';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import RepeatBookingActions from 'event_mgmt/shared/actions/RepeatBookingActions.jsx';
import {
  asArray,
  asPlainObject,
  isCollectionWithContents,
  merge,
} from 'shared/utils/ObjectUtils.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

class AvailableTimesStore extends UpperHandStore {
  constructor(
    listeners,
    eventStoreName,
    registrationPackageStoreName,
    cartStoreName
  ) {
    super();

    this.eventStoreName = eventStoreName;
    this.registrationPackageStoreName = registrationPackageStoreName;
    this.cartStoreName = cartStoreName;
    this.allDates = [];
    this.weekDays = [];
    this.numberOfSessions = null;
    this.sessionConflicts = [];
    this.bookableSessions = [];
    this.suggestedTimes = [];
    this.nonBookableSessions = [];
    this.summaryScreen = false;
    this.conflictScreen = false;
    this.cartScreen = false;
    this.repeat = false;

    this.availableTimesActions = alt.generateActions(
      'fetchSuccess',
      'fetchError'
    );

    this.availableConflictsActions = alt.generateActions(
      'fetchSuccess',
      'fetchError'
    );

    this.reset();

    this.bindListeners(
      merge(listeners, {
        fetchAvailabilitySuccess: this.availableTimesActions.fetchSuccess,
        fetchAvailabilityError: this.availableTimesActions.fetchError,
        fetchConflictsSuccess: this.availableConflictsActions.fetchSuccess,
        fetchConflictsError: this.availableConflictsActions.fetchError,
        enableSummaryScreen: RepeatBookingActions.enableSummaryScreen,
        disableSummaryOrConflictScreen:
          RepeatBookingActions.disableSummaryOrConflictScreen,
        enableBookedScreen: RepeatBookingActions.enableBookedScreen,
        repeatButtonHandler: RepeatBookingActions.repeatButtonHandler,
        setBookAbleSessions: RepeatBookingActions.setBookAbleSessions,
      })
    );
  }

  repeatButtonHandler(value) {
    this.repeat = !value;
  }

  setBookAbleSessions(value) {
    this.bookableSessions = [...value];
  }

  enableSummaryScreen() {
    this.loading = true;
    this.suggestedTimes = [];
    this.nonBookableSessions = [];
    this.conflictScreen = false;
    this.summaryScreen = true;
    this.loading = false;
  }

  disableSummaryOrConflictScreen() {
    this.loading = true;
    this.conflictScreen = false;
    this.summaryScreen = false;
    this.loading = false;
  }

  enableBookedScreen() {
    this.conflictScreen = false;
    this.summaryScreen = false;
    this.cartScreen = true;
  }

  reset() {
    this.event = new CustomerEvent();
    this.existingScheduledTimes = List();
    this.rawAvailableTimes = Map();
    this.filteredAvailableTimes = Map();
    this.availableTimes = List();
    this.availableTimesRepeat = List();
    this.isCurrentTimeAvailable = true;
    this.isCurrentTimeAvailableRepeat = true;
    this.loading = false;
    this.availabilitiesLoading = false;
    this.conflictScreen = false;
    this.summaryScreen = false;
    this.cartScreen = false;
    this.repeat = false;
    this.areInitialFilteredTimesFetched = false;

    this.date = null;
    this.firstAvailableDate = moment();
    this.time = null;
    this.startDateRepeat = null;
    this.startTimeRepeat = null;
    this.endDateRepeat = null;
    this.staffIds = List();

    this.windowStart = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    this.windowEnd = moment()
      .add(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');

    // start date
    this.start_date = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    // end date
    this.end_date = moment()
      .add(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
  }

  setEventId({ eventId }) {
    this.eventId = eventId;
  }

  setEvent(_data) {
    const EventStore = alt.stores[this.eventStoreName];

    this.waitFor(EventStore);
    this.reset();

    this.event = EventStore.getState().customerEvent;
    this.updateExistingScheduledTimes();
  }

  updateExistingScheduledTimes() {
    const registrationPackageStore =
      alt.stores[this.registrationPackageStoreName];
    const cartStore = alt.stores[this.cartStoreName];
    let registrationPackage;

    if (registrationPackageStore) {
      this.waitFor(registrationPackageStore);

      registrationPackage =
        registrationPackageStore.getState().registrationPackage;

      this.existingScheduledTimes = registrationPackage.tentative_details;
    }

    if (cartStore) {
      this.waitFor(cartStore);

      const order = cartStore.getState().cart;
      if (order) {
        order.get('order_items').forEach(oi => {
          if (
            oi.orderable.event_id === this.event.id &&
            oi.orderable.id !== registrationPackage.id
          ) {
            this.existingScheduledTimes = this.existingScheduledTimes.concat(
              oi.orderable.tentative_details
            );
          }
        });
      }
    }

    this.filterSelectedDateTimes();
  }

  handleStaffChange([staffId]) {
    this.staffIds = staffId ? List(asArray(staffId)) : List();
    // If a staff is changed need to clear previous selected data.
    this.date = null;
    this.time = null;
    this.startTimeRepeat = null;
    this.endDateRepeat = null;
    this.startDateRepeat = null;

    this.rawAvailableTimes = Map();
    this.areInitialFilteredTimesFetched = false;
    this.fetchAvailability({
      date: moment().format('YYYY-MM-DD'),
      staffIds: this.staffIds,
    });
  }

  fetchConflictsSuccess = data => {
    const {
      suggested_time: suggestedTime,
      bookable_dates: bookableDates,
      unbookable_dates: nonBookableDates,
    } = data;
    this.suggestedTimes = suggestedTime;
    this.nonBookableSessions = nonBookableDates;
    if (suggestedTime === '') {
      this.suggestedTimes = [];
    }
    this.bookableSessions = bookableDates;
    if (
      this.suggestedTimes?.length === 0 &&
      this.nonBookableSessions?.length === 0 &&
      this.bookableSessions?.length > 0
    ) {
      this.summaryScreen = true;
      this.isLoading = false;
    }
    if (
      this.suggestedTimes?.length !== 0 &&
      this.nonBookableSessions?.length !== 0 &&
      this.bookableSessions?.length > 0
    ) {
      this.conflictScreen = true;
      this.isLoading = false;
    }
    if (
      this.suggestedTimes?.length === 0 &&
      this.nonBookableSessions?.length !== 0 &&
      this.bookableSessions?.length > 0
    ) {
      this.conflictScreen = true;
      this.isLoading = false;
    }
    if (
      this.suggestedTimes?.length !== 0 &&
      this.nonBookableSessions?.length === 0 &&
      this.bookableSessions?.length > 0
    ) {
      this.conflictScreen = true;
      this.isLoading = false;
    }
    if (
      this.suggestedTimes?.length !== 0 &&
      this.nonBookableSessions?.length === 0 &&
      this.bookableSessions?.length === 0
    ) {
      this.conflictScreen = true;
      this.isLoading = false;
    }
    if (
      this.suggestedTimes?.length === 0 &&
      this.nonBookableSessions?.length !== 0 &&
      this.bookableSessions?.length === 0
    ) {
      this.conflictScreen = true;
      this.isLoading = false;
    }
    if (
      this.suggestedTimes?.length !== 0 &&
      this.nonBookableSessions?.length !== 0 &&
      this.bookableSessions?.length === 0
    ) {
      this.conflictScreen = true;
      this.isLoading = false;
    }
  };

  fetchConflictsError = () => {
    this.isLoading = false;
  };

  fetchConflicts({
    eventId,
    start_date: startDate,
    end_date: endDate,
    booked_sessions_days: bookedSessionsDays,
    start_time: startTime,
    staff_id: staffId,
  }) {
    this.isLoading = true;
    const data = {
      id: eventId || this.event.id || this.eventId,
      start_date: moment(startDate).format('DD-MM-YYYY'),
      end_date: moment(endDate).format('DD-MM-YYYY'),
      staff_id: staffId,
      selected_days: bookedSessionsDays,
      start_time: `${startTime}:00`,
      request_hash: this.requestHash(),
    };
    return uhApiClient.get({
      url: `event_conflicts/`,
      data,
      success: this.availableConflictsActions.fetchSuccess,
      error: this.availableConflictsActions.fetchError,
      requireToken: false,
    });
  }

  handleRepeatSessions = data => {
    const {
      user_id: userId,
      start_date: startDate,
      end_date: endDate,
      booked_sessions_days: bookedSessionsDays,
      start_time: startTime,
      staff_id: staffId,
    } = data;
    this.fetchConflicts({
      user_id: userId,
      start_date: startDate,
      end_date: endDate,
      booked_sessions_days: bookedSessionsDays,
      start_time: startTime,
      staff_id: staffId,
    });
  };

  handleDateChange([key, date]) {
    if (key === 'startDateRepeat') {
      this.startDateRepeat = moment(date).format('YYYY-MM-DD');
      this.startTimeRepeat = null;
      this.filterSelectedDateTimes();
      this.setCurrentTimeAvailable(); // add setCureentTimeAvailableStart
    } else if (key === 'endDateRepeat') {
      this.endDateRepeat = moment(date).format('YYYY-MM-DD');
      this.time = null;
      this.startTimeRepeat = null;
      this.filterSelectedDateTimes();
      this.setCurrentTimeAvailable();
    } else {
      this.date = moment(date).format('YYYY-MM-DD');
      this.time = null;
      this.filterSelectedDateTimes();
      this.setCurrentTimeAvailable();
    }
  }

  handleFetchAvailabilities(data) {
    let date = data;
    if (data instanceof Array) [date = null] = data;

    const serverDate = date
      ? moment(date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');

    this.time = null;

    const isOpenBooking = this.event.isOpenBooking();
    const availabilityData = {
      date: serverDate,
      staffIds: this.staffIds,
    };
    const schedulingTimeFrame =
      this.event.schedules.first().scheduling_timeframe;

    if (isOpenBooking && currentUser().isClient() && schedulingTimeFrame) {
      availabilityData.schedulingTimeFrame = schedulingTimeFrame;
    }

    this.fetchAvailability(availabilityData);
  }

  handleTimeChange([key, time]) {
    if (key === 'startTimeRepeat') {
      this.startTimeRepeat = time;
      this.setCurrentTimeAvailable();
    } else {
      this.time = time;
      this.setCurrentTimeAvailable();
    }
  }

  clearSelections() {
    this.date = null;
    this.time = null;
    this.staffIds = List();
    this.startDateRepeat = null;
    this.endDateRepeat = null;
    this.startTimeRepeat = null;
  }

  fetchAvailability({ eventId, date, staffIds, schedulingTimeFrame }) {
    this.availabilitiesLoading = true;
    this.windowEnd = !schedulingTimeFrame
      ? moment(date).add(1, 'months').endOf('month').format('YYYY-MM-DD')
      : moment(date).add(schedulingTimeFrame, 'days').format('YYYY-MM-DD');

    const isOpenBooking = this.event.isOpenBooking();
    const needCheckToFetchMore =
      currentUser().isClient() &&
      this.areInitialFilteredTimesFetched &&
      schedulingTimeFrame &&
      isOpenBooking;

    if (needCheckToFetchMore) {
      const needFetchMore =
        moment(this.windowEnd).diff(moment(), 'days') < schedulingTimeFrame;
      this.availabilitiesLoading = false;

      return needFetchMore;
    }

    const data = {
      window_start: this.areInitialFilteredTimesFetched
        ? moment(this.windowEnd).startOf('month').format('YYYY-MM-DD')
        : this.windowStart,
      window_end: this.windowEnd,
      staff_ids: isCollectionWithContents(staffIds)
        ? asPlainObject(staffIds)
        : null,
      request_hash: this.requestHash(),
    };
    return uhApiClient.get({
      url: `events/${eventId || this.event.id || this.eventId}/availability`,
      data,
      success: this.availableTimesActions.fetchSuccess,
      error: this.availableTimesActions.fetchError,
      requireToken: false,
    });
  }

  fetchAvailabilitySuccess(data) {
    const defaultDate = this.date
      ? moment(this.date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');

    this.allDates = data?.available_times;
    this.firstAvailableDate =
      data?.available_times.length > 0
        ? data.available_times[0].date
        : defaultDate;
    // If the request doesn't match the current request hash. Then it is for a
    // different set of staff and can be ignored.
    if (data.request_hash !== this.requestHash()) {
      return;
    }

    this.rawAvailableTimes = this.rawAvailableTimes.merge(
      fromJS(data.available_times).groupBy(t => t.get('date'))
    );

    this.areInitialFilteredTimesFetched = true;

    this.filterSelectedDateTimes();
    this.setCurrentTimeAvailable();
    this.setFirstAvailableDate(data?.available_times);
    this.availabilitiesLoading = false;
  }

  fetchAvailabilityError({ response }) {
    this.isLoading = false;
    this.availabilitiesLoading = false;

    if (response.status !== 401 && response.status !== 403) {
      // eslint-disable-next-line prefer-rest-params
      this.notifyError('error retrieving availability', arguments);
    }
  }

  setFirstAvailableDate(availableTimes = []) {
    if (availableTimes.length) {
      this.firstAvailableDate = availableTimes[0].date;
    } else {
      this.firstAvailableDate = this.date
        ? this.date
        : moment().format('YYYY-MM-DD');
    }
  }

  filterSelectedDateTimes() {
    this.filteredAvailableTimes = this.rawAvailableTimes.map((times, date) =>
      this.filterTentativeDetailsFromAvailableTimes(
        date,
        this.existingScheduledTimes,
        times
      )
    );
    this.availableTimes = this.filteredAvailableTimes.get(this.date, List());
    this.availableTimesRepeat = this.filteredAvailableTimes.get(
      this.startDateRepeat,
      List()
    ); // second step to set the available times
  }

  setCurrentTimeAvailable() {
    this.isCurrentTimeAvailable =
      !this.date || !this.time || this.availableTimes.includes(this.time);
    this.isCurrentTimeAvailableRepeat =
      !this.startDateRepeat ||
      !this.startTimeRepeat ||
      this.availableTimesRepeat.includes(this.startTimeRepeat); // third check current available times
  }

  requestHash() {
    return JSON.stringify(
      isCollectionWithContents(this.staffIds)
        ? asPlainObject(this.staffIds)
        : null
    );
  }

  // eslint-disable-next-line class-methods-use-this
  filterTentativeDetailsFromAvailableTimes(
    date,
    tentativeDetails,
    availableTimes
  ) {
    // If there is nothing tentative then we can return available times unchanged, since
    // there is no chance that the client's tentative selections could allow them to overbook.
    if (!tentativeDetails || !date) {
      return availableTimes.map(e => e.get('start_time').substring(0, 5));
    }

    // Get the list of tentatively scheduled times on the selected date.
    const momentDate = moment(date);
    const tentativeRegistrationsOnDate = tentativeDetails.filter(d =>
      momentDate.isSame(moment(d.get('starts_at')), 'day')
    );
    // There are no tentative registrations on the selected date, so available times is
    // not impacted.
    if (tentativeRegistrationsOnDate.size === 0) {
      return availableTimes.map(e => e.get('start_time').substring(0, 5));
    }
    // If there are tentative registrations on the selected date, then we want to exclude
    // any times that conflict with the tentative registrations or times that would become
    // full due to the tentative registrations.
    const filteredAvailableTimes = availableTimes.filter(t => {
      const availableTime = moment.tz(
        `${t.get('date')}T${t.get('start_time')}`,
        customerTZ()
      );

      const notOverlapping = !tentativeRegistrationsOnDate.some(
        tentativeSession =>
          availableTime.isBetween(
            tentativeSession.starts_at,
            tentativeSession.ends_at,
            null,
            '()'
          )
      );

      const notFull =
        tentativeRegistrationsOnDate.count(tentativeSession =>
          tentativeSession?.starts_at.isSame(availableTime)
        ) < t.get('spots_remaining');

      return notOverlapping && notFull;
    });

    // Lastly return just the time portion of the available times object.
    return filteredAvailableTimes.map(e => e.get('start_time').substring(0, 5));
  }
}

export default AvailableTimesStore;
