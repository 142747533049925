import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import CreateUser from 'containers/accounts/createUser/CreateUser.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import altContainer from 'shared/hocs/altContainer.jsx';

import UserCreationStore from 'user_management/shared/stores/UserCreationStore.jsx';

import ModalRegistrationActions from './Actions';
import ModalRegistrationStore from './Store';

import './styles.scss';

function SignUpModal({
  modalRegistrationStore: { isSignUpModalOpened },
  userCreationStore: { currentStep },
}) {
  return (
    <Dialog
      open={isSignUpModalOpened}
      onClose={() => ModalRegistrationActions.toggleSignUpModal({})}
      maxWidth="md"
      className="sign-up"
      PaperProps={{ className: 'sign-up__paper-component' }}
    >
      <DialogTitle className="sign-up__title">
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <img
            className="sign-up__logo"
            src={currentCustomer().logo.getAlternative('email')}
            alt={currentCustomer().name}
          />
          <FormattedMessage
            id={messageId(
              currentStep === 'accountCreation'
                ? '.create_account_header'
                : '.create_participants_header',
              __filenamespace
            )}
          />
          <IconButton
            aria-label="close"
            onClick={() => ModalRegistrationActions.toggleSignUpModal({})}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent className="sign-up__content">
        <CreateUser modalOpen />
      </DialogContent>
    </Dialog>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      modalRegistrationStore: ModalRegistrationStore,
      userCreationStore: UserCreationStore,
    },
  })
)(SignUpModal);
