import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

function WrapperPreview({ children, cropAspect, height, intl, width, image }) {
  return (
    <div
      className="upload-preview"
      style={{
        height: height || 'auto',
        width: width || (cropAspect && height ? height * cropAspect : '100%'),
      }}
    >
      {image && !image.isBlank() ? (
        children
      ) : (
        <div className="upload-preview__empty">
          <Icon name="cloudUpload" />
          <div className="upload-preview__empty-text">
            <div className="upload-preview__empty-title">
              {t('.empty_text', intl, __filenamespace)}
            </div>
            <div className="upload-preview__empty-desc">
              {t('.empty_desc', intl, __filenamespace)}
              <div className="upload-preview__empty-link">
                {t('.empty_link', intl, __filenamespace)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

WrapperPreview.propTypes = {
  children: PropTypes.node,
  cropAspect: PropTypes.number,
  height: PropTypes.number,
  image: PropTypes.object,
  intl: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

WrapperPreview.defaultProps = {
  children: null,
  cropAspect: null,
  height: null,
  image: null,
  width: null,
};

export default WrapperPreview;
