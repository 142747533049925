import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import IconFlag from 'shared/components/icons/Flag.jsx';
import { translate } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#009fdc',
    height: 40,
    width: '100%',
    zIndex: 1101,
    color: '#fff',
    cursor: 'pointer',
  },
  icon: {
    height: 18,
    color: '#fff',
    margin: '0 15px',
  },
  title: {
    fontSize: 16,
  },
  link: {
    paddingLeft: 5,
    fontSize: 16,
    fontWeight: 'bold',
  },
};

const POLLING_INTERVAL = 60000; // Request update each minute

const useNewReleaseWatcher = () => {
  const [hasNewRelease, setHasNewRelease] = React.useState(false);
  const version = React.useRef(window.version);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const el = document.createElement('script');
      el.src = '/env.js';

      // Add callbacks
      el.onload = () => {
        document.body.removeChild(el);
        if (window.version !== version.current) {
          setHasNewRelease(true);
          clearInterval(interval); // No need to continue polling after banner was displayed
        }
      };
      el.onerror = () => clearInterval(interval);

      // Evaluate script
      document.body.appendChild(el);
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  });

  return hasNewRelease;
};

function NewReleaseBanner({ intl }) {
  const hasNewRelease = useNewReleaseWatcher();
  return (
    hasNewRelease && (
      <div
        className="new-release-banner"
        style={styles.root}
        onClick={() => window.location.reload()}
        onKeyDown={() => window.location.reload()}
        role="button"
        tabIndex={0}
      >
        <IconFlag style={styles.icon} />
        <span style={styles.title}>
          {translate('.title', intl, __filenamespace)}
          <span style={styles.link}>
            {translate('.link', intl, __filenamespace)}
          </span>
        </span>
      </div>
    )
  );
}

NewReleaseBanner.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(NewReleaseBanner);
