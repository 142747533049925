import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import Task from 'shared/records/Task';
import TaskDeletionActions from 'shared/actions/TaskDeletionActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class TaskDeletionStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      askForConfirmation: TaskDeletionActions.DELETE_REQUESTED,
      deleteTask: TaskDeletionActions.DELETE_CONFIRMED,
      abortDeletion: TaskDeletionActions.DELETE_ABORTED,
      deleteSuccess: TaskDeletionActions.DELETE_SUCCESS,
      deleteError: TaskDeletionActions.DELETE_ERROR,
    });
  }

  reset() {
    this.confirmationOpen = false;
    this.task = new Task();
  }

  askForConfirmation(task) {
    this.task = task;
    this.confirmationOpen = true;
  }

  deleteTask() {
    if (!this.task.id) {
      return false;
    }

    return uhApiClient.delete({
      url: `tasks/${this.task.id}`,
      success: {
        action: TaskDeletionActions.deleteSuccess,
        args: [this.task.id],
      },
      error: TaskDeletionActions.deleteError,
    });
  }

  abortDeletion() {
    this.reset();
  }

  deleteSuccess([_, _taskId]) {
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({ id: '.deleted', filename: __filenamespace })
    );

    this.reset();
  }

  deleteError() {
    this.notifyError('error deleting task', arguments);
  }
}

export default alt.createStore(TaskDeletionStore, 'TaskDeletionStore');
