import React, { memo, useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { formatClientTime } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import OrderItem from 'shared/records/OrderItem';

const styles = {
  cardContent: {
    padding: '1.5rem',
  },
  label: {
    fontWeight: 700,
    fontSize: '16px',
  },
  datesContainer: {
    height: '100%',
    maxHeight: '200px',
    overflow: 'auto',
  },
  startTime: {
    width: '50px',
  },
  timeSeparator: {
    width: '10px',
  },
  endTime: {
    width: '50px',
    textAlign: 'right',
  },
};

function FixedScheduleCardContent({ intl, orderItem, sessions }) {
  const [open, setOpen] = useState(false);

  const { orderable } = orderItem;
  const { tentative_details: tentativeDetails } = orderable;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={styles.cardContent}>
      <List sx={{ padding: 0 }}>
        <ListItemButton sx={{ padding: 0 }} onClick={handleClick}>
          <ListItemText
            primaryTypographyProps={{
              component: 'div',
            }}
            primary={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={styles.label}>
                  {t('.event_dates', intl, __filenamespace)}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={styles.label}>
                    {t('.view_details', intl, __filenamespace)}
                  </Typography>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Stack>
              </Stack>
            }
          />
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={styles.datesContainer}>
            {tentativeDetails.map((detail, index) => {
              const session = sessions.get(detail.session_id, null);

              if (!session) return null;
              return (
                <Stack
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>
                    {moment(session.starts_at).format('MM/DD/YYYY')}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography sx={styles.startTime}>
                      {formatClientTime(session.starts_at, 'h:mma')}
                    </Typography>
                    <Typography sx={styles.timeSeparator}>
                      &nbsp;-&nbsp;
                    </Typography>
                    <Typography sx={styles.endTime}>
                      {formatClientTime(session.ends_at, 'h:mma')}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
          </Box>
        </Collapse>
      </List>
    </Box>
  );
}

FixedScheduleCardContent.propTypes = {
  orderItem: PropTypes.instanceOf(OrderItem),
  sessions: PropTypes.instanceOf(Map),
  intl: PropTypes.object,
};

FixedScheduleCardContent.defaultProps = {
  orderItem: new OrderItem({ orderable_type: 'registration_package' }),
  sessions: Map(),
  intl: {},
};

export default compose(injectIntl, memo)(FixedScheduleCardContent);
