import * as React from 'react';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FormattedNumber } from 'react-intl';

function DiscountValue({ discount }) {
  return discount.isPercent() ? (
    // eslint-disable-next-line react/style-prop-object
    <FormattedNumber value={discount.value / 100} style="percent" />
  ) : (
    <FormattedCurrency value={discount.value} fromCents />
  );
}

export default DiscountValue;
