import * as React from 'react';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import ReactTooltip from 'react-tooltip';
import { merge } from 'shared/utils/ObjectUtils.jsx';

function BadgedAvatar({
  avatar,
  badgeColor,
  badgeLetter,
  badgeTooltip,
  border,
  id,
  style,
}) {
  const avatarRadius = avatar.props.size / 2;
  const borderWidth = border ? avatarRadius / 16 : 0;
  const borderStyle = border
    ? { borderWidth, borderColor: 'white', borderStyle: 'solid' }
    : {};

  return (
    <div style={merge({ position: 'relative' }, style)}>
      <Badge
        data-tip
        data-for={id}
        sx={{
          '& .UH-MuiBadge-badge': {
            backgroundColor: badgeColor,
            width: '14px',
            height: '14px',
            minWidth: 'initial',
            ...borderStyle,
          },
        }}
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Typography
            variant="caption"
            component="span"
            sx={{ color: 'white' }}
          >
            {badgeLetter}
          </Typography>
        }
      >
        {avatar}
      </Badge>
      {badgeTooltip && (
        <ReactTooltip id={id} className="uh-tooltip" place="bottom">
          {badgeTooltip}
        </ReactTooltip>
      )}
    </div>
  );
}

export default BadgedAvatar;
