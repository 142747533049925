export const STAFF_TYPES_OPTIONS = [
  { label: 'Admin', value: 'admin' },
  { label: 'Instructor', value: 'instructor' },
  { label: 'Manager', value: 'manager' },
  { label: 'Staff', value: 'staff' },
];

export const FILTER_STAFF_TYPES_ALL = [
  'admin',
  'instructor',
  'manager',
  'staff',
];
