import { List } from 'immutable';
import Schedule from 'shared/records/Schedule';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';
import DataStoreActions from './ScheduleActions';

const parserTo = { type: Schedule, paginationKey: 'schedules' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const create = ({ availabilitySchedule, success, error }) => {
  uhApiClient.post({
    url: parserTo.paginationKey,
    data: JSON.stringify({
      attributes: availabilitySchedule,
    }),
    success: onSuccess(data => parser.parseSingle(data), success),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const destroy = ({ id, success, error }) => {
  uhApiClient.delete({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.removeSuccess, success])
    ),
    error: onError(List([DataStoreActions.removeError, error])),
  });
};

export const cancel = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/${id}/cancel`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.cancelSuccess, success])
    ),
    error: onError(List([DataStoreActions.cancelError, error])),
  });
};

export const patch = ({ id, params, success, error }) => {
  uhApiClient.patch({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const put = ({
  id,
  availabilitySchedule,
  fields,
  reason = '',
  success,
  error,
}) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify({ attributes: availabilitySchedule, reason, fields }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const listScheduleName = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(data => data, success),
    error: onError(error),
  });
};
