import * as React from 'react';
import { Navigate } from 'react-router-dom';

function NewUserRedirect({ customer_id: customerId, redirect_to: redirect }) {
  const aftercomplete = redirect ? `?redirect_to=${redirect}` : '';
  // we don't have the customer name for the slug. the name is not actually used, we always use the customer id anyway.
  return (
    <Navigate to={`/customers/${customerId}/create_user${aftercomplete}`} />
  );
}

export default NewUserRedirect;
