import moment from 'moment-timezone';
import { List, OrderedSet } from 'immutable';
import POSPurchasedRegistrationActions from 'point_of_sale/actions/POSPurchasedRegistrationActions.jsx';
import Registration from 'shared/records/Registration';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import {
  list as listRegistrations,
  destroy as destroyRegistration,
} from 'sources/RegistrationSource';
import RepeatBookingActions from 'event_mgmt/shared/actions/RepeatBookingActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import ClientCreditActions from 'shared/actions/ClientCreditActions.js';

class RegistrationStore extends UpperHandStore {
  constructor() {
    super();

    this.isLoading = false;
    this.eventId = null;
    this.allRegistrations = List(); // for listing
    this.clientRegistration = new Registration(); // for fetching, creating, updating
    this.repeatBooking = false;
    this.clientIds = OrderedSet();
    this.findById = id =>
      this.allRegistrations.find(client => client.id === id);
    this.registrationsLoaded = false;
    this.bindListeners({
      handleList: RegistrationActions.list,
      handleListSuccess: RegistrationActions.listSuccess,
      handleListError: RegistrationActions.listError,

      handleSchedule: [
        SchedulingDrawerActions.REGISTRATION_BOOKED,
        POSPurchasedRegistrationActions.REGISTRATION_BOOKED,
      ],
      handleScheduleSuccess: RegistrationActions.scheduleSuccess,
      handleScheduleError: RegistrationActions.scheduleError,

      handleCancel: RegistrationActions.cancel,
      handleCancelSuccess: RegistrationActions.cancelSuccess,
      handleCancelError: RegistrationActions.cancelError,

      handleUpdateStore: RegistrationActions.updateStore,
    });
  }

  handleUpdateStore([keyPath, value]) {
    if (keyPath.length) {
      this.clientRegistration = this.clientRegistration.setIn(keyPath, value);
    } else {
      this.clientRegistration = value;
    }
  }

  handleList({ event_id: eventId, client_ids: clientIds, page }) {
    this.isLoading = true;
    this.eventId = eventId;
    this.clientIds = clientIds;

    listRegistrations({
      params: {
        page: page || 1,
        per_page: 100,
        event_ids: eventId,
        start_time: moment().format(),
        client_ids: this.clientIds,
        sort_direction: 'asc',
      },
      success: RegistrationActions.listSuccess,
      error: RegistrationActions.listError,
    });
  }

  handleListSuccess({ registrations, page, _perPage, totalCount }) {
    this.allRegistrations =
      page === 1
        ? List(registrations)
        : this.allRegistrations.concat(registrations);

    if (totalCount > this.allRegistrations.size) {
      this.handleList({
        event_id: this.eventId,
        client_ids: this.clientIds,
        page: page + 1,
      });
    } else {
      this.eventId = null;
      this.isLoading = false;
    }
    this.registrationsLoaded = true;
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing client registrations', args);
  }

  // eslint-disable-next-line class-methods-use-this
  handleSchedule({ tentativeRegistration, repeatBooking }) {
    this.repeatBooking = repeatBooking;
    if (tentativeRegistration?.length > 0) {
      this.isLoading = true;
      uhApiClient.post({
        url: 'registrations/schedule',
        data: JSON.stringify({
          attributes: tentativeRegistration,
        }),
        success: RegistrationActions.scheduleSuccess,
        error: RegistrationActions.scheduleError,
      });
    } else {
      uhApiClient.post({
        url: 'registrations/schedule',
        data: JSON.stringify({
          attributes: [tentativeRegistration.toServer()],
        }),
        success: RegistrationActions.scheduleSuccess,
        error: RegistrationActions.scheduleError,
      });
    }
  }

  addNewRegistration(data) {
    const registration = new Registration(data);
    const index = this.allRegistrations.findIndex(
      r => r.id === registration.id
    );
    if (index >= 0) {
      this.allRegistrations = this.allRegistrations.updateIn(
        [index],
        () => registration
      );
    } else {
      this.allRegistrations = this.allRegistrations
        .filter(r => r.id !== null)
        .push(registration);
    }
  }

  async handleScheduleSuccess(data) {
    this.isLoading = false;

    if (currentUser().isStaff() && this.repeatBooking) {
      RepeatBookingActions.enableBookedScreen.defer();
    }

    if (!this.repeatBooking && data.length === 1) {
      this.addNewRegistration(data[0]);
    }

    if (!this.repeatBooking && data.length > 1) {
      data.forEach(registration => {
        this.addNewRegistration(registration);
      });
    }

    if (!this.repeatBooking) {
      POSSchedulingActions.listPurchasedRegistrationsSuccess.defer({
        registrations: this.allRegistrations,
      });
      if (currentUser().isStaff()) {
        this.allRegistrations = List();
      }
      let eventIds = this.allRegistrations.map(item => item.event_id);
      eventIds = eventIds.toArray();
      if (eventIds.length > 0) {
        ClientCreditActions.list.defer({ eventIds });
      }
    }
  }

  handleScheduleError(...args) {
    this.isLoading = false;
    this.notifyError('error scheduling registration', args);
  }

  // eslint-disable-next-line class-methods-use-this
  handleCancel(registration) {
    destroyRegistration({
      id: registration.id,
      success: {
        action: RegistrationActions.cancelSuccess,
        args: [registration],
      },
      error: RegistrationActions.cancelError,
    });
  }

  handleCancelSuccess([_, registration]) {
    const index = this.allRegistrations.findIndex(
      cr => cr.id === registration.id
    );

    if (index >= 0) {
      this.allRegistrations = this.allRegistrations.delete(index);
    }
  }

  handleCancelError(...args) {
    this.notifyError('error while updating client registration', args);
  }
}

export default alt.createStore(RegistrationStore, 'RegistrationStore');
