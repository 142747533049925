import * as React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@upperhand/playmaker';

import StaffDisplay from 'components/StaffDisplay.jsx';

import { BrowseEvent } from 'records';
import { getCustomerMoment } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { humanizeDuration } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';

import BrowseEventsBookDrawerActions from 'containers/browseEventsBookDrawer/Actions.js';

import { VIEW_MODE } from 'containers/browseEvents/Store';

function ClassScheduleCard({
  browseEvent,
  intl,
  hasDeadlineReached,
  viewMode,
}) {
  const { eventId, sessionId, staffIds, startsAt, spotsRemaining, duration } =
    browseEvent;
  const isFull = spotsRemaining < 1 && spotsRemaining !== null;
  const isMultipleStaff = staffIds.length > 1;

  const isSmallScreen = smallScreen();
  const spotsBlock = (
    <Grid item xs>
      {sessionId && spotsRemaining > 0 && spotsRemaining <= 5 && (
        <Typography variant="body3" color="error" align="center">
          {spotsRemaining === 1
            ? t('.spot', intl, __filenamespace)
            : t('.spots', intl, __filenamespace, {
                count: spotsRemaining,
              })}
        </Typography>
      )}
    </Grid>
  );

  const handleClick = _e => {
    _e.stopPropagation();
    BrowseEventsBookDrawerActions.openDrawer({
      eventId,
      sessionIds: sessionId ? Set([sessionId]) : Set(),
    });
  };

  return (
    <Grid
      container
      alignItems={isSmallScreen ? 'flex-start' : 'center'}
      xs={12}
      spacing={1}
    >
      <Grid container item xs={3} sm={2}>
        {duration && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">{humanizeDuration(duration)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="drawerTitle">
                {viewMode === VIEW_MODE.week
                  ? startsAt.format('h:mm a')
                  : t('.session', intl, __filenamespace)}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item xs={6} sm={7}>
        <Grid container item xs={12} sm={8} justify="center" direction="column">
          <Grid item>
            <Typography variant="h5" className="browse-events__event-title">
              {browseEvent.eventTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="drawerTitle">
              {browseEvent.eventTypeName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={4} alignItems="center">
          {isMultipleStaff && (
            <Typography>
              {t('.staff_count', intl, __filenamespace, {
                count: staffIds.length,
              })}
            </Typography>
          )}
          {!isMultipleStaff &&
            staffIds.map(staffId => (
              <StaffDisplay key={staffId} staffId={staffId} />
            ))}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={3}
        sm={3}
        direction={isSmallScreen ? 'column' : 'row'}
        alignItems={isSmallScreen ? 'stretch' : 'center'}
      >
        {!isSmallScreen && spotsBlock}
        <Grid item xs={isSmallScreen ? 12 : 6}>
          {hasDeadlineReached ? (
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {t('.registration_closed', intl, __filenamespace)}
            </p>
          ) : (
            <Button
              type="secondary"
              fullWidth
              disabled={
                isFull || (sessionId && startsAt.isBefore(getCustomerMoment()))
              }
              onClick={handleClick}
              classes={{
                root: 'browse-events__button',
              }}
            >
              {isFull
                ? t('.full_btn', intl, __filenamespace)
                : t('.btn', intl, __filenamespace)}
            </Button>
          )}
        </Grid>
        {isSmallScreen && spotsBlock}
      </Grid>
    </Grid>
  );
}

ClassScheduleCard.propTypes = {
  browseEvent: PropTypes.instanceOf(BrowseEvent).isRequired,
  intl: PropTypes.object.isRequired,
};

export default ClassScheduleCard;
