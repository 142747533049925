import React from 'react';
import { TextField } from '@upperhand/playmaker';

export default function NumberField({
  value,
  name,
  min,
  label,
  onChangeCallback,
  classes,
  disabled,
}) {
  return (
    <TextField
      classes={{
        root: classes.numberField,
      }}
      label={label}
      min={min}
      name={name}
      type="number"
      disabled={disabled}
      value={value}
      onChange={onChangeCallback}
    />
  );
}
