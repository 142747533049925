import React from 'react';
import { injectIntl } from 'react-intl';
import { ContentModal, Button, Typography } from '@upperhand/playmaker';
import moment from 'moment-timezone';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import ClientBox from 'containers/reports/refundModal/components/ClientBox.jsx';
import RefundAmount from 'containers/reports/refundModal/components/RefundAmount.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { generateHtmlId } from 'shared/utils/DOMUtils.js';

import {
  ClientDataStore,
  BalancesDataStore,
  CreditPassDataStore,
  EventDataStore,
  EventTypeDataStore,
} from 'dataStores';

import OrderItemCard from '../orderSummaryDrawer/components/OrderItemCard.jsx';

import RefundModalActions from './Actions';
import RefundModalStore, { PAYMENT_METHODS_TO_CHECK } from './Store';

import './styles.scss';
import 'containers/reports/orderSummaryDrawer/styles.scss';

function RefundModal({
  clientDataStore: { clients },
  balancesDataStore: { balances },
  creditPassDataStore: { creditPasses },
  eventDataStore: { events },
  eventTypeDataStore: { eventTypes },
  refundModalStore: {
    errors,
    isOpen,
    isLoadingPayments,
    isLoadingProducts,
    productToRefund,
    orderToRefund,
    buyerId,
    primaryDisplayText,
    payments,
    paymentIds,
    refundAmounts,
    maxRefunds,
    mode,
    showBackBtn,
    hasDefaultAch,
  },
  intl,
}) {
  const isBankPayment = productToRefund?.get('payment_method') === 'bank';
  const isNotEqual = paymentIds.some(
    paymentId => refundAmounts.get(paymentId) !== maxRefunds.get(paymentId)
  );

  const waiveLabel =
    !showBackBtn || !isNotEqual
      ? t(`.issue_${mode}`, intl, __filenamespace)
      : t(`.continue`, intl, __filenamespace);

  const onRefundValueChange = (value, paymentId) => {
    RefundModalActions.refundAmountUpdated(paymentId, value);
  };

  const onRefundSubmitClick = () => {
    RefundModalActions.refundSubmit();
  };

  return (
    <ContentModal
      open={isOpen}
      showActions={false}
      title={t(`.issue_${mode}`, intl, __filenamespace)}
      onAbort={RefundModalActions.closeModal}
      onSuccess={RefundModalActions.closeModal}
      classes={{
        root: 'refund-modal',
        title: 'refund-modal-title',
        content: 'refund-modal-content',
      }}
    >
      <span id={generateHtmlId('body', __filenamespace)}>
        <SpinWhileLoading isLoading={isLoadingPayments || isLoadingProducts}>
          {productToRefund && !orderToRefund && (
            <>
              <ClientBox client={clients.get(buyerId)} />
              <div className="refund-product-name"> {primaryDisplayText} </div>
            </>
          )}
          {orderToRefund && (
            <>
              <ClientBox client={clients.get(buyerId)} />
              {orderToRefund.order_items.map(oi => (
                <OrderItemCard
                  disableMenuOptions
                  intl={intl}
                  order={orderToRefund}
                  orderItem={oi}
                  clients={clients}
                  creditPasses={creditPasses}
                  events={events}
                  eventTypes={eventTypes}
                  balances={balances}
                />
              ))}
            </>
          )}
          {paymentIds.map(paymentId => {
            const payment = payments?.get(paymentId);
            const paidAt = payment?.get('paidAt');
            const paymentMethod = payment?.get('paymentMethod');
            const showVoidWarning =
              moment().diff(moment(paidAt), 'hours') < 24 &&
              PAYMENT_METHODS_TO_CHECK.includes(paymentMethod);

            return (
              <div key={paymentId}>
                <RefundAmount
                  disabled={showVoidWarning || isBankPayment}
                  payment={payment}
                  paymentId={paymentId}
                  refundAmount={refundAmounts.get(paymentId)}
                  maxValueToRefund={maxRefunds.get(paymentId)}
                  onChange={(e, value) =>
                    onRefundValueChange(value * 100, paymentId)
                  }
                  errors={errors}
                  mode={mode}
                  intl={intl}
                />
                {showVoidWarning && (
                  <Typography className="refund-modal-content__paysafe-warning">
                    {t('.paysafe_warning', intl, __filenamespace)}
                  </Typography>
                )}
              </div>
            );
          })}
          {showBackBtn && (
            <Button
              fullWidth
              variant="contained"
              color="dark"
              classes={{
                root: 'back-button',
                label: 'back-button-label',
              }}
              onClick={RefundModalActions.closeModal}
            >
              {t('.back_action', intl, __filenamespace)}
            </Button>
          )}
          {isBankPayment && !hasDefaultAch && (
            <Typography className="refund-modal-content__ach-warning">
              {t('.ach_warning', intl, __filenamespace)}
            </Typography>
          )}
          <Button
            fullWidth
            onClick={onRefundSubmitClick}
            disabled={
              refundAmounts.every(amount => amount <= 0) ||
              (isBankPayment && !hasDefaultAch) ||
              errors.hasErrors()
            }
            classes={{
              root: 'refund-button',
              label: 'refund-button-label',
            }}
          >
            {waiveLabel}
          </Button>
        </SpinWhileLoading>
      </span>
    </ContentModal>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
      refundModalStore: RefundModalStore,
      balancesDataStore: BalancesDataStore,
      creditPassDataStore: CreditPassDataStore,
      eventDataStore: EventDataStore,
      eventTypeDataStore: EventTypeDataStore,
    },
  })
)(RefundModal);
