import * as React from 'react';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import SignIn from 'shared/components/icons/SignIn.jsx';
import Hand from 'shared/components/icons/Hand.jsx';
import activeTheme from '_uh_theme.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function UhThemeSmallLogin({ styles, keyboardOpen, justifyStyle, children }) {
  return (
    <FlexBox
      style={merge(
        { flexDirection: 'column', backgroundColor: uhColors.white },
        justifyStyle,
        styles.safariSupport
      )}
    >
      {!keyboardOpen && (
        <FlexBoxCenter
          style={{
            flex: '18 1 auto',
            width: '100%',
            backgroundColor: '#181b2b',
          }}
        >
          <SignIn
            style={{
              height: '100%',
              width: '100%',
              maxWidth: '60%',
              margin: '0 auto',
            }}
          />
        </FlexBoxCenter>
      )}

      {!keyboardOpen && (
        <FlexBoxCenter
          style={{
            flex: '0 1 65px',
            width: '100%',
            backgroundColor: '#181b2b',
          }}
        />
      )}

      {!keyboardOpen && (
        <FlexBoxCenter style={{ flex: '1 1 auto', flexDirection: 'column' }}>
          <div
            style={{
              position: 'relative',
              top: '-65px',
              backgroundColor: 'transparent',
              marginBottom: '-80px',
            }}
          >
            <Hand style={{ height: '100px', width: '100px' }} />
          </div>
        </FlexBoxCenter>
      )}
      {children}
    </FlexBox>
  );
}

function EnterpriseSmallLogin({
  styles,
  keyboardOpen,
  justifyStyle,
  children,
}) {
  return (
    <FlexBox
      style={merge(
        { flexDirection: 'column', backgroundColor: uhColors.white },
        justifyStyle,
        styles.safariSupport
      )}
    >
      {!keyboardOpen && (
        <FlexBoxCenter style={{ flex: '3 1 auto', width: '100%' }}>
          <img
            src={activeTheme.logo.url}
            style={{ margin: '0 auto' }}
            width="65%"
            alt="logo"
          />
        </FlexBoxCenter>
      )}
      {children}
    </FlexBox>
  );
}

const ThemeSmallLogin = props => {
  if (activeTheme.mainInstance) {
    return UhThemeSmallLogin(props);
  }

  return EnterpriseSmallLogin(props);
};

export default ThemeSmallLogin;
