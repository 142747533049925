import { DefaultSourceActions } from './DefaultSourceActionList';

class TransactionLedgerActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'downloadList',
      'downloadListSuccess',
      'downloadListError'
    );
  }
}

export default alt.createActions(TransactionLedgerActions);
