import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar } from '@upperhand/playmaker';
import BadgedAvatar from 'shared/components/_BadgedAvatar.jsx';
import uhTheme from '_uh_theme.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const defaultStyle = {
  marginRight: '0.75em',
};

const getUserBadge = user => {
  if (user.isStaff()) {
    if (user.isCoach()) {
      return {
        badgeLetter: 'C',
        badgeColor: '#0000FF',
      };
    }
    if (user.isManager()) {
      return {
        badgeLetter: 'M',
        badgeColor: '#393C44',
      };
    }
    if (user.isInstructor()) {
      return {
        badgeLetter: 'І',
        badgeColor: uhColors.darkBlue,
      };
    }
  }

  if (user.type === 'StaffMember' || user.type === 'StaffAdmin') {
    const badgeLetter = user.type === 'StaffAdmin' ? 'A' : 'S';
    const badgeColor =
      user.type === 'StaffAdmin' ? 'var(--color-green)' : uhColors.purple;
    return {
      badgeLetter,
      badgeColor,
    };
  }
  return {};
};

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brokenSrc: false,
    };
  }

  handleSrcError = () => {
    this.setState({ brokenSrc: true });
  };

  render() {
    const { user, size, style, showTypeBadge, color, membershipColor } =
      this.props;
    const { brokenSrc } = this.state;

    const navImage = user?.alternativeImage('avatar');
    const avatarColor = color || uhTheme.palette.lightGrey;

    let avatar;
    if (navImage && !navImage.match('missing.png') && !brokenSrc) {
      avatar = (
        <Avatar
          classes={{ root: 'user-avatar' }}
          size={size}
          color={avatarColor}
          src={user.alternativeImage('avatar')}
          onError={this.handleSrcError}
        />
      );
    } else {
      avatar = (
        <Avatar
          classes={{ root: 'user-avatar' }}
          size={size}
          color={avatarColor}
        >
          {user?.first_name}
          {user?.last_name}
        </Avatar>
      );
    }
    if (showTypeBadge) {
      const userBadge = getUserBadge(user);

      if (userBadge) {
        const { badgeLetter, badgeColor } = userBadge;

        return (
          <BadgedAvatar
            avatar={avatar}
            badgeColor={badgeColor}
            badgeLetter={badgeLetter}
            style={{ ...defaultStyle, ...style }}
          />
        );
      }
    }

    if (user.active_membership_color || membershipColor) {
      const badgeColor = user.has_subscription_balance_owed
        ? 'var(--color-error-red)'
        : user.active_membership_color || membershipColor;
      return (
        <BadgedAvatar
          avatar={avatar}
          badgeColor={badgeColor}
          id={user.id?.toString()}
          badgeLetter={user.has_subscription_balance_owed && '!'}
          badgeTooltip={
            user.has_subscription_balance_owed && (
              <FormattedMessage
                id={messageId('.unpaid_membership', __filenamespace)}
              />
            )
          }
          border
          style={{ ...defaultStyle, ...style }}
        />
      );
    }
    return (
      <div style={{ ...defaultStyle, ...style }}>
        {React.cloneElement(avatar)}
      </div>
    );
  }
}

UserAvatar.defaultProps = {
  size: 32,
};

export default UserAvatar;
