import { Iterable, Map, Record } from 'immutable';
import { isUrl } from 'shared/utils/SharedUtils.js';
import { upload } from 'shared/utils/S3Utils';

class Document extends Record({
  file: null,
  url: '',
  name: '',
  alternatives: Map({}),
  public_file: false,
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      // eslint-disable-next-line no-param-reassign
      obj = obj.toJS();
    }

    super({
      ...obj,
      name: obj.name || obj.file?.name || '',
      // Prevent Chrome caching and CORS errors
      // https://stackoverflow.com/questions/49503171/the-image-tag-with-crossorigin-anonymous-cant-load-success-from-s3
      url: isUrl(obj.url) ? `${obj.url}#${Date.now()}` : obj.url,
    });
  }

  async toServer() {
    try {
      if (this.file) {
        return await upload(this.file, this.public_file);
      }

      return null;
    } catch (e) {
      return null;
    }
  }
}

export default Document;
