import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CartIcon from 'shared/components/icons/Cart.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { messageId } from 'shared/utils/LocaleUtils.js';

function ViewCartLink({ onView }) {
  return onView ? (
    <span
      role="presentation"
      onClick={onView}
      style={{ color: 'white', cursor: 'pointer' }}
    >
      <FormattedMessage id={messageId('actions.view', __filenamespace)} />
    </span>
  ) : (
    <Link to={customerScopedRoute('/cart')} style={{ color: 'white' }}>
      <FormattedMessage id={messageId('actions.view', __filenamespace)} />
    </Link>
  );
}

function CartIndicator({ cart, eventId, onView, clientView }) {
  if (
    !cart ||
    !cart.order_items.some(
      oi => oi.getIn(['orderable', 'event_id']) === eventId
    )
  ) {
    return null;
  }

  return (
    <FlexBoxCenter
      style={{
        minHeight: 56,
        backgroundColor: uhColors.green,
        justifyContent: 'center',
        fontSize: 16,
      }}
    >
      <CartIcon color="white" style={{ marginRight: 10 }} />
      <span>
        {clientView ? (
          <FormattedMessage
            id={messageId('.client_message', __filenamespace)}
            values={{ link: <ViewCartLink onView={onView} /> }}
          />
        ) : (
          <FormattedMessage
            id={messageId('.customer_message', __filenamespace)}
            values={{ link: <ViewCartLink onView={onView} /> }}
          />
        )}
      </span>
    </FlexBoxCenter>
  );
}

export default CartIndicator;
