import { Map, fromJS } from 'immutable';
import AccountSetupPaysafeActions from 'onboarding/actions/AccountSetupPaysafeActions.js';
import TOSStepActions from 'onboarding/actions/TOSStepActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import OnboardAPI from 'onboarding/OnboardAPI.js';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';

function TOSFieldsToJSON(tos, tosAccepted) {
  return {
    attributes: {
      tos: {
        accepted: tosAccepted,
        version: tos.get('version'),
      },
    },
  };
}

class TOSStepStore extends UpperHandStore {
  constructor() {
    super();

    this.bindListeners({
      handleTOSLoad: TOSStepActions.loadTOS,
      handleAcceptTOS: TOSStepActions.acceptTOS,
      handleSubmit: TOSStepActions.submitTOSForm,
    });
    this.isLoading = false;
    this.tos = Map();
    this.tosAccepted = false;
  }

  async handleTOSLoad() {
    this.isLoading = true;
    try {
      const response = await OnboardAPI.getTermsOfService();
      this.tos = fromJS(response);
    } catch (error) {
      ApiErrorDialogActions.error.defer(error);
      this.notifyError('error fetching terms and conditions step', error);
    }
    this.isLoading = false;
    this.emitChange();
  }

  handleAcceptTOS(accepted) {
    this.tosAccepted = accepted;
  }

  async handleSubmit() {
    this.isLoading = true;
    try {
      const data = JSON.stringify(TOSFieldsToJSON(this.tos, this.tosAccepted));
      await OnboardAPI.submit(data);
      AccountSetupPaysafeActions.nextStep.defer();
    } catch (error) {
      ApiErrorDialogActions.error.defer(error);
      this.notifyError('Error while submitting application', arguments);
    }
    this.isLoading = false;
    this.emitChange();
  }
}

export default alt.createStore(TOSStepStore, 'TOSStepStore');
