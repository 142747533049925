import React from 'react';
import { Typography, Button, Icon } from '@upperhand/playmaker';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';

import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';

function DrawerTitle({ title, clientIdsHistory }) {
  const clientIds = clientIdsHistory.toArray();
  const goBack = clientIds.length ? clientIds[clientIds.length - 2] : null;

  return (
    <div className="client-profile-drawer-title">
      {goBack?.clientId && (
        <Button
          size="1x"
          type="tertiary"
          classes={{ root: 'client-profile-drawer-title__back-btn' }}
          onClick={() =>
            ClientProfileDrawerActions.handleGoBack(goBack?.clientId)
          }
        >
          <Icon size="x-large" name="arrowLeft" />
        </Button>
      )}
      <Typography variant="subtitle1">{title}</Typography>
    </div>
  );
}

DrawerTitle.propTypes = {
  title: PropTypes.string,
  clientIdsHistory: PropTypes.instanceOf(OrderedMap).isRequired,
};

DrawerTitle.defaultProps = {
  title: '',
};

export default React.memo(DrawerTitle);
