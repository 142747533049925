import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function EventPrice({ event, automations }) {
  const scheduleType = event.schedule_type;

  const collection = new PricingAutomationCollection({
    automations: automations.filter(
      atd =>
        atd &&
        atd.event_id === event.id &&
        atd.supported_event_type === scheduleType
    ),
  });

  if (collection.isEmpty()) {
    let { price } = event;

    if (event.hasAccessToScheduledPrice()) {
      price = event.get('scheduled_price');
    }

    if (event.isFixedSchedule() && event.isSSPTurnedOn()) {
      const { single_session_price: sspPrice, pricing_tiers: pricingTiers } =
        event;
      const minPriceTier =
        pricingTiers.size > 0
          ? pricingTiers
              .minBy(p => p.get('single_session_price', 0))
              .get('single_session_price', 0)
          : 0;
      const maxPriceTier =
        pricingTiers.size > 0
          ? pricingTiers
              .maxBy(p => p.get('single_session_price', 0))
              .get('single_session_price', 0)
          : 0;
      const minPrice =
        minPriceTier && minPriceTier < sspPrice ? minPriceTier : sspPrice;
      const maxPrice =
        maxPriceTier && maxPriceTier > price ? maxPriceTier : price;

      return (
        <div>
          <FormattedCurrency value={minPrice < price ? minPrice : price} />
          &nbsp;&ndash;&nbsp;
          <FormattedCurrency value={maxPrice > price ? maxPrice : price} />
        </div>
      );
    }

    return <FormattedCurrency value={price} />;
  }
  return (
    <FormattedMessage
      id={messageId('currency.starting_at', __filenamespace)}
      values={{
        amount: (
          <FormattedCurrency value={collection.getMinimumPrice()} fromCents />
        ),
      }}
    />
  );
}

export default EventPrice;
