import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Map } from 'immutable';
import Button from '@mui/material/Button';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import PayoutsActions from 'shared/actions/PayoutsActions.jsx';
import PayoutsHistoryDrawer from 'customers/settings/components/_PayoutsHistoryDrawer.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Root: {
    margin: '16px 32px',
    fontSize: '16px',
  },
  AvailableBalance: {
    fontSize: '18px',
  },
  Ledger: {
    maxWidth: '480px',
    borderBottom: `1px solid ${uhColors.dividerGrey}`,
  },
  LedgerRow: {
    padding: '16px 0',
  },
  Amount: {
    fontWeight: 600,
  },
  WithdrawalHistoryButton: {
    margin: '16px 0',
    color: uhColors.activeBlue,
  },
};

function AvailableBalance({ balance = Map() }) {
  const currency = balance.get('currency') || 'USD';
  const amount = balance.get('amount') || 0;

  return (
    <FlexBoxJustify style={merge(styles.LedgerRow, styles.AvailableBalance)}>
      <span style={styles.Label}>
        <FormattedMessage
          id={messageId('.available', __filenamespace)}
          values={{ currency: currency.toUpperCase() }}
        />
      </span>
      <span style={styles.Amount}>
        <FormattedCurrency value={amount} fromCents />
      </span>
    </FlexBoxJustify>
  );
}

function PendingBalance({ balance = Map() }) {
  const currency = balance.get('currency') || 'USD';
  const amount = balance.get('amount') || 0;

  return (
    <FlexBoxJustify style={styles.LedgerRow}>
      <span style={styles.Label}>
        <FormattedMessage
          id={messageId('.pending', __filenamespace)}
          values={{ currency: currency.toUpperCase() }}
        />
      </span>
      <span style={styles.Amount}>
        <FormattedCurrency value={amount} fromCents />
      </span>
    </FlexBoxJustify>
  );
}

function AvailableBalances({ balance }) {
  const available = balance.get('available') || List();

  return (
    <div style={styles.Ledger}>
      {available.map(availableBalance => (
        <AvailableBalance
          key={`available-balance-${availableBalance.currency}`}
          balance={availableBalance}
        />
      ))}
    </div>
  );
}

function PendingBalances({ balance }) {
  const pending = balance.get('pending') || List();

  return (
    <div style={styles.Ledger}>
      {pending.map(pendingBalance => (
        <PendingBalance
          key={`pending-balance-${pendingBalance.currency}`}
          balance={pendingBalance}
        />
      ))}
    </div>
  );
}

const handleHistoryClick = e => {
  e.preventDefault();
  PayoutsActions.toggleHistoryDrawerOpen(true);
};

function PayoutsTab({ payoutsStore, intl }) {
  const { balance } = payoutsStore;

  return (
    <div style={styles.Root}>
      <SpinWhileLoading
        isLoading={!payoutsStore.balanceLoaded}
        spinWhile="isLoading"
      >
        <AvailableBalances balance={balance} />
        <PendingBalances balance={balance} />
        <Button
          variant="contained"
          color="default"
          style={styles.WithdrawalHistoryButton}
          onClick={handleHistoryClick}
        >
          {t('.withdrawal_history', intl, __filenamespace)}
        </Button>
        <PayoutsHistoryDrawer payoutsStore={payoutsStore} />
      </SpinWhileLoading>
    </div>
  );
}

export default injectIntl(PayoutsTab);
