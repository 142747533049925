import * as React from 'react';
import { Card } from '@upperhand/playmaker';

function RecordCard({ record }) {
  return (
    <Card>
      <div
        style={{
          border: 'solid grey 2px',
          padding: '3px',
          borderRadius: '10px',
          boxShadow: '2px 2px',
        }}
      >
        {JSON.stringify(record, null, 4)}
      </div>
    </Card>
  );
}

export default RecordCard;
