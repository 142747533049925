import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import { OrderableTypes } from 'containers/reports/types';
import { t } from 'shared/utils/LocaleUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import BalanceDetailsDrawerActions from 'containers/reports/balanceDetailsDrawer/Actions.js';

import CreditPassCard from './orderItemCards/CreditPassCard.jsx';
import FixedEventCard from './orderItemCards/FixedEventCard.jsx';
import MembershipCard from './orderItemCards/MembershipCard.jsx';
import OpenBookingClassEventCard from './orderItemCards/OpenBookingClassEventCard.jsx';
import RetailCard from './orderItemCards/RetailCard.jsx';

import OrderSummaryDrawerActions from '../Actions';

export const SCHEDULE_TYPE = {
  fixedSchedule: 'fixed_schedule',
  openBooking: 'open_booking',
  classSchedule: 'class_schedule',
};

const getCardComponent = ({ type, events, orderItem }) => {
  switch (type) {
    case OrderableTypes.membership_subscription_package: {
      return MembershipCard;
    }
    case OrderableTypes.variant: {
      return RetailCard;
    }
    case OrderableTypes.registration_package: {
      const event = events.get(orderItem.getIn(['orderable', 'event_id']));

      return event.get('schedule_type') === SCHEDULE_TYPE.fixedSchedule ||
        event.isTeamEvent()
        ? FixedEventCard
        : OpenBookingClassEventCard;
    }
    case OrderableTypes.credit_pass_package: {
      return CreditPassCard;
    }
    default:
      return null;
  }
};

function OrderItemCard({
  balances,
  clients,
  creditPasses,
  events,
  eventTypes,
  onRefund,
  order,
  orderItem,
  intl,
  disableMenuOptions,
}) {
  const type = orderItem.get('orderable_type');
  const desiredBalance = balances.find(
    balance => balance.productId === orderItem.id
  );
  const paymentPlan = desiredBalance
    ?.get('details')
    .find(detail => detail.type === 'PaymentPlan');
  const orderTotal = order.get('total');
  const balancePaid = desiredBalance ? desiredBalance.get('paid') : 0;
  const balanceRemaining = desiredBalance
    ? desiredBalance.get('balanceRemaining')
    : 0;

  const fullyProcessed =
    balancePaid === 0 && orderTotal !== 0 && balanceRemaining === 0;

  const hasWaives =
    desiredBalance && desiredBalance.hasDetailsOfType('DebitForgiveness');
  const hasRefunds =
    desiredBalance && desiredBalance.hasDetailsOfType('Refund');

  const status = (hasWaives ? ['.waived'] : []).concat(
    hasRefunds ? ['.refunded'] : []
  );

  const CardContent = getCardComponent({
    type,
    events,
    orderItem,
  });

  const isStaff = currentUser().isStaff();
  const canWaive = balanceRemaining !== 0 && isStaff;
  const canRefund = balancePaid !== 0 && isStaff;

  // The product type passed in can be anything here - 'OrderItem' just makes initial sense.
  const threeDotOptions = (
    canWaive
      ? [
          {
            label: t('.waive', intl, __filenamespace, 'waive'),
            onClick: () => onRefund(orderItem, 'OrderItem', 'waive'),
          },
        ]
      : []
  )
    .concat(
      canRefund
        ? [
            {
              label: t('.refund', intl, __filenamespace),
              onClick: () => onRefund(orderItem, 'OrderItem', 'refund'),
            },
          ]
        : []
    )
    .concat([
      {
        label: t('.view_balance', intl, __filenamespace),
        onClick: () =>
          BalanceDetailsDrawerActions.openDrawer(
            desiredBalance.get('compoundId'),
            [OrderSummaryDrawerActions.closeDrawer.defer]
          ),
      },
    ]);

  return (
    <>
      <Card
        classes={{
          root: 'order-item__card',
          header: 'order-item__header',
          content: 'order-item__content',
        }}
        header={orderItem.get('secondary_display_text')}
        contentDirection="column"
        menuOptions={
          orderTotal !== 0 &&
          threeDotOptions.length > 0 &&
          !disableMenuOptions &&
          threeDotOptions
        }
      >
        <Grid container xs={12}>
          <CardContent
            inner
            clients={clients}
            creditPasses={creditPasses}
            events={events}
            eventTypes={eventTypes}
            orderItem={orderItem}
            intl={intl}
            hasPaymentPlan={paymentPlan}
          />
        </Grid>
      </Card>
      <div className="order-item__bottom-block">
        <div className="order-item__bottom-inner">
          <Grid container item xs={12} justify="space-between">
            <CardContent
              inner={false}
              clients={clients}
              creditPasses={creditPasses}
              events={events}
              eventTypes={eventTypes}
              orderItem={orderItem}
              intl={intl}
            />
          </Grid>
        </div>

        {fullyProcessed && status.length > 0 && (
          <div className="order-item__partial-status">
            <Grid container item xs={12} justify="center">
              <Typography variant="subtitle2">
                {status.map(s => t(s, intl, __filenamespace)).join(' / ')}
              </Typography>
            </Grid>
          </div>
        )}

        {!fullyProcessed && (hasWaives || hasRefunds) && (
          <div className="order-item__partial-status">
            <Grid container item xs={12} justify="center">
              <Typography variant="subtitle2">
                {t('.partial_status', intl, __filenamespace, {
                  status: status.join(' ').replace('.', ''),
                })}
              </Typography>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

OrderItemCard.propTypes = {
  intl: PropTypes.object.isRequired,
  orderItem: PropTypes.object,
  disableMenuOptions: PropTypes.bool,
};

OrderItemCard.defaultProps = {
  orderItem: {},
  disableMenuOptions: false,
};

export default OrderItemCard;
