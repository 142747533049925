import React from 'react';

import { grey } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';

import uhTheme from '_uh_theme.jsx';

const styles = {
  automationCard: {
    margin: '1rem',
    backgroundColor: 'white',
  },

  cardContent: {
    padding: '1.5rem',
    fontSize: '0.95em',
  },

  divider: {
    backgroundColor: grey[300],
  },

  header: {
    backgroundColor: 'white',
    color: '#3a3c44',
    padding: '1.5rem',
    fontSize: '1em',
    fontWeight: '600',
  },

  addAutomation: {
    textAlign: 'left',
    paddingLeft: '0px',
    justifyContent: 'flex-start',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

class AutomationListDrawer extends React.Component {
  addAutomation(atd) {
    const { onRequestChange, onAutomationSelect } = this.props;

    onRequestChange(false, 'adding automation');
    if (onAutomationSelect) {
      onAutomationSelect(atd);
    }
  }

  automationCard(atd, index) {
    const { style } = this.props;

    return (
      <Paper
        key={`atd-${atd.id || atd.name}-${index}`}
        style={{ ...styles.automationCard, ...style }}
      >
        <div style={{ ...styles.header, ...{ position: 'relative' } }}>
          {atd.name}
        </div>
        <Divider style={styles.divider} />
        <div style={{ ...styles.cardContent }}>
          <p>{atd.description}</p>
          <Button
            disableRipple
            disableFocusRipple
            sx={styles.addAutomation}
            onClick={() => this.addAutomation(atd)}
          >
            Add
          </Button>
        </div>
      </Paper>
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  descriptionById(templateId, props = this.props) {
    return props.automationTemplateDescriptions.find(
      each => each.id === templateId
    );
  }

  render() {
    const style = {
      cardSpaceStyle: {
        backgroundColor: uhTheme.palette.charcoalBlack,
        paddingLeft: '1.5em',
        paddingRight: '1.5em',
      },
      drawer: {
        backgroundColor: uhTheme.palette.charcoalBlack,
      },
      toolbar: {
        padding: '0 0 0 0',
        color: 'white',
        backgroundColor: uhTheme.palette.charcoalBlack,
      },
    };

    const { open, onRequestChange, automationTemplateDescriptions } =
      this.props;

    return (
      <article>
        <Drawer
          disableEnforceFocus
          anchor="right"
          open={open}
          onClose={onRequestChange}
          PaperProps={{ sx: { width: '350px', ...style.drawer } }}
        >
          <Toolbar style={style.toolbar}>
            <Stack style={{ flex: '1 100%' }} direction="row">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{ marginLeft: '2em', fontSize: 16, display: 'inline' }}
                >
                  Automation
                </div>
              </div>
              <IconButton
                onClick={() => onRequestChange(false, 'userInitiatedClose')}
                style={{ marginLeft: 'auto' }}
              >
                <ClearIcon
                  sx={{ color: '#5B6A72', width: '24px', height: '24px' }}
                />
              </IconButton>
            </Stack>
          </Toolbar>
          <div style={style.cardSpaceStyle}>
            {automationTemplateDescriptions.map((e, index) =>
              this.automationCard(e, index)
            )}
          </div>
        </Drawer>
      </article>
    );
  }
}

export default AutomationListDrawer;
