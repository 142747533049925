import PropTypes from 'prop-types';
import * as React from 'react';
import { Confirmation } from '@upperhand/playmaker';

function ConfirmationDialog({
  awaitingConfirmation,
  onCancel,
  onConfirm,
  title,
  submitText,
  cancelText,
  children,
}) {
  return (
    <Confirmation
      cancelButtonLabel={cancelText}
      confirmButtonLabel={submitText}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={awaitingConfirmation}
      title={title}
      hasCheckbox={false}
    >
      {children}
    </Confirmation>
  );
}

ConfirmationDialog.propTypes = {
  awaitingConfirmation: PropTypes.bool,
  cancelText: PropTypes.string,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  submitText: PropTypes.string,
  title: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  title: '',
  awaitingConfirmation: false,
  cancelText: 'Cancel',
  submitText: 'Submit',
  onCancel: () => null,
  onConfirm: () => null,
};

export default ConfirmationDialog;
