import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Box } from '@mui/material';
import { withStyles } from '@material-ui/styles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InfoIcon from '@mui/icons-material/Info';

import NumberField from 'shared/components/NumberField.jsx';
import RestrictionsSectionDobRows from 'shared/components/RestrictionsSectionDobRows.jsx';

import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { compose } from 'shared/utils/SharedUtils';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';

const styles = {
  title: {
    fontWeight: 'bold',
    marginBottom: 15,
    fontSize: 15,
  },
  clearBoth: {
    clear: 'both',
  },
  datePickerTextFieldStyle: {
    maxWidth: '10em',
    textAlign: 'center',
    color: uhColors.leftNavGrey,
  },
  horizontallySpacedRadioButton: {
    display: 'inline-block',
    width: '',
    paddingRight: 20,
  },
  numStyle: {
    maxWidth: '3em',
    color: uhColors.leftNavGrey,
  },
  spaceyBold: {
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: 'bold',
  },
  subheader: {
    fontWeight: 'bold',
    padding: '15px 0px 10px 0px',
    lineHeight: '15px',
  },
  ageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  ageDivider: {
    padding: '0 8px 14px',
  },
  eventSizeSubheader: {
    fontWeight: 'bold',
    padding: '15px 0px 10px 0px',
    lineHeight: '15px',
    marginBottom: 8,
    paddingBottom: '',
  },
  waitlist: {
    icon: {
      height: 20,
      width: 20,
      position: 'absolute',
      right: 105,
      zIndex: 999,
    },
    tooltip: {
      maxWidth: 240,
    },
  },
  numberField: {
    width: 70,
  },
};

function WaitListSection({ customerEvent, handleInputChange }) {
  if (
    !customerEvent.isFixedSchedule() ||
    customerEvent.allow_single_session_purchase
  ) {
    return null;
  }

  return (
    <Box component="section">
      <Typography style={styles.subheader}>
        <FormattedMessage id={messageId('.title.waitlist', __filenamespace)} />
      </Typography>
      <Box style={{ position: 'relative' }}>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <>
              <FormattedMessage
                id={messageId('.allow_waitlist', __filenamespace)}
              />
              <InfoIcon
                data-tip
                data-for="allow-waitlist-help"
                style={styles.waitlist.icon}
              />
              <ReactTooltip
                id="allow-waitlist-help"
                effect="solid"
                style={styles.waitlist.tooltip}
                className="charge-failure-tooltip uh-tooltip"
              >
                <FormattedMessage
                  id={messageId('.allow_waitlist_help_text', __filenamespace)}
                />
              </ReactTooltip>
            </>
          }
          name="allow_waitlist"
          checked={customerEvent.get('allow_waitlist')}
          onChange={handleInputChange}
        />
      </Box>
    </Box>
  );
}

function RestrictionsSectionCapacityRows({
  customerEvent,
  schedules,
  classes,
  handleInputChange,
}) {
  if (customerEvent.isClassSchedule()) {
    return null;
  }

  return (
    <Box component="section">
      <Typography style={styles.eventSizeSubheader}>
        <FormattedMessage id={messageId('.event_size', __filenamespace)} />
      </Typography>
      <Box>
        <NumberField
          value={schedules.get(0).max_size}
          name="max_size"
          min={0}
          label="Max"
          classes={classes}
          onChangeCallback={handleInputChange}
        />
      </Box>
    </Box>
  );
}

function RestrictionsSectionGenderRows({ intl, schedules, handleInputChange }) {
  const genderRestriction = schedules.getIn([0, 'gender_restriction']);

  return (
    <Box component="section" sx={{ clear: 'both' }}>
      <Typography style={styles.subheader}>
        <FormattedMessage id={messageId('.gender', __filenamespace)} />
      </Typography>
      <table style={styles.tableBorder}>
        <tbody>
          <tr>
            <td>
              <RadioGroup
                row
                defaultValue={genderRestriction}
                name="gender_restriction"
                onChange={handleInputChange}
                value={genderRestriction}
              >
                <FormControlLabel
                  label={t('.coed', intl, __filenamespace)}
                  style={styles.horizontallySpacedRadioButton}
                  value="coed"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="male"
                  label={t('.male', intl, __filenamespace)}
                  style={styles.horizontallySpacedRadioButton}
                  control={<Radio />}
                />
                <FormControlLabel
                  value="female"
                  label={t('.female', intl, __filenamespace)}
                  style={styles.horizontallySpacedRadioButton}
                  control={<Radio />}
                />
              </RadioGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
}

function RestrictionsSectionAgeRows({
  actions,
  schedules,
  classes,
  handleInputChange,
}) {
  const hasDobRestriction = schedules
    .get(0)
    ?.date_of_birth?.hasDobRestriction();

  const handleMinChange = (e, value) => {
    const min = value ? parseInt(value, 10) : null;
    const maxValue = Math.max.apply(Math, [schedules.get(0).max_age, min]);
    handleInputChange(e, min);
    handleInputChange({ target: { value: maxValue, name: 'max_age' } });
    actions.resetDobFields();
  };

  const hasMinAge = () => !!schedules.get(0).min_age;

  const handleMaxChange = (e, value) => {
    handleInputChange(e, value);
    actions.resetDobFields();
  };
  return (
    <Box component="section">
      <Typography style={styles.eventSizeSubheader}>
        <FormattedMessage id={messageId('.age', __filenamespace)} />
      </Typography>
      <Box sx={styles.ageContainer}>
        <NumberField
          value={schedules.get(0).min_age}
          name="min_age"
          min={0}
          label="Min"
          classes={classes}
          disabled={hasDobRestriction}
          onChangeCallback={handleMinChange}
        />
        <Box sx={styles.ageDivider}>&mdash;</Box>
        <NumberField
          value={schedules.get(0).max_age}
          name="max_age"
          min={hasMinAge() ? schedules.get(0).min_age : 0}
          label="Max"
          classes={classes}
          disabled={hasDobRestriction}
          onChangeCallback={handleMaxChange}
        />
      </Box>
    </Box>
  );
}

function RestrictionsSection({
  intl,
  classes,
  customerEvent,
  schedules,
  handleScheduleChange,
}) {
  const handleInputChange = (e, value) => {
    const updateValue = value ?? e.target.value;
    handleScheduleChange([e.target.name], updateValue);
  };
  return (
    <Box component="section">
      <RestrictionsSectionCapacityRows
        classes={classes}
        customerEvent={customerEvent}
        schedules={schedules}
        handleInputChange={handleInputChange}
      />
      <RestrictionsSectionGenderRows
        schedules={schedules}
        handleInputChange={handleInputChange}
        intl={intl}
      />
      <RestrictionsSectionAgeRows
        actions={EventActions}
        classes={classes}
        schedules={schedules}
        handleInputChange={handleInputChange}
      />
      <RestrictionsSectionDobRows
        actions={EventActions}
        participants={schedules.get(0)}
        intl={intl}
        handleParticipantChange={handleScheduleChange}
      />
    </Box>
  );
}
function TabParticipants({
  intl,
  customerEvent,
  schedules,
  classes,
  handleInputChange,
  handleScheduleChange,
}) {
  return (
    <Box component="section" style={{ width: SINGLE_COLUMN_WIDTH }}>
      <WaitListSection
        customerEvent={customerEvent}
        handleInputChange={handleInputChange}
      />
      <RestrictionsSection
        intl={intl}
        customerEvent={customerEvent}
        schedules={schedules}
        handleScheduleChange={handleScheduleChange}
        classes={classes}
      />
    </Box>
  );
}

export default compose(withStyles(styles), injectIntl)(TabParticipants);
