/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@upperhand/playmaker';

import MUIButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

const styles = {
  labelStyle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  overlayStyle: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

class ButtonMenu extends React.PureComponent {
  static muiName = 'FlatButton';

  state = {
    actionMenuAnchorEl: null,
    open: false,
  };

  get button() {
    const {
      anchorOrigin,
      targetOrigin,
      children,
      menuStyle,
      buttonStyle,
      icon,
      buttonType,
      label,
      labelStyle,
      labelPosition,
      ...buttonProps
    } = this.props;

    const endIcon = labelPosition === 'before' ? icon : null;
    const startIcon = labelPosition === 'after' ? icon : null;

    switch (buttonType) {
      case 'raised':
        return (
          <MUIButton
            {...buttonProps}
            style={buttonStyle}
            variant="contained"
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={this.handleActionMenuClick}
          >
            <Typography style={{ ...styles.labelStyle, ...labelStyle }}>
              {label}
            </Typography>
          </MUIButton>
        );
      case 'tertiary':
        return (
          <Button
            {...buttonProps}
            fullWidth
            icon={icon}
            onClick={this.handleActionMenuClick}
            type="tertiary"
            rounded
            classes={{
              root: 'inventory__export-button',
              label: 'inventory__header-button-label',
            }}
          >
            {label}
          </Button>
        );

      case 'icon':
        return (
          <IconButton
            {...buttonProps}
            style={buttonStyle}
            onClick={this.handleActionMenuClick}
          >
            {icon}
          </IconButton>
        );

      default:
        return (
          <MUIButton
            {...buttonProps}
            style={{ ...buttonStyle }}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={this.handleActionMenuClick}
          >
            <Typography style={{ ...styles.labelStyle, ...labelStyle }}>
              {label}
            </Typography>
          </MUIButton>
        );
    }
  }

  handleActionMenuClick = event => {
    const { open, actionMenuAnchorEl } = this.state;
    const { onClick } = this.props;

    // Prevent the ghost click.
    event.preventDefault();

    if (actionMenuAnchorEl !== event.currentTarget) {
      this.setState({ actionMenuAnchorEl: event.currentTarget });
    }

    this.setState({ open: !open });

    onClick(event);
  };

  handleMenuClick = () => {
    const { closeOnSelect } = this.props;

    if (closeOnSelect) {
      this.setState({ open: false });
    }
  };

  handleMenuClose = () => {
    this.setState({
      open: false,
      actionMenuAnchorEl: null,
    });
  };

  render() {
    const { anchorOrigin, children, menuStyle } = this.props;
    const { open, actionMenuAnchorEl } = this.state;

    return (
      <div>
        {this.button}
        <Menu
          disableEnforceFocus
          anchorOrigin={anchorOrigin}
          anchorEl={actionMenuAnchorEl}
          open={open}
          PaperProps={{
            style: menuStyle,
          }}
          onClick={this.handleMenuClick}
          onClose={this.handleMenuClose}
        >
          {children}
        </Menu>
      </div>
    );
  }
}

ButtonMenu.propTypes = {
  children: PropTypes.any,
  buttonType: PropTypes.oneOf(['raised', 'icon', 'flat']),
  menuStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  anchorOrigin: PropTypes.object,
  targetOrigin: PropTypes.object,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  labelPosition: PropTypes.string,
  closeOnSelect: PropTypes.bool,
};

ButtonMenu.defaultProps = {
  children: null,
  buttonType: 'flat',
  menuStyle: {},
  buttonStyle: {},
  labelStyle: {},
  anchorOrigin: {},
  targetOrigin: {},
  onClick: () => {},
  icon: null,
  labelPosition: 'after',
  closeOnSelect: true,
};

export default ButtonMenu;
