import React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button, Card, Grid, Typography } from '@upperhand/playmaker';
import ClientProfileActions from 'containers/clientProfile/Actions';
import ClientProfileStore from 'containers/clientProfile/Store';
import { ClientDataStore } from 'dataStores';
import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import CancelIcon from '@mui/icons-material/Cancel';
import AtttachmentsUploadModal from './AtttachmentsUploadModal.jsx';
import './styles.scss';

function Attachments({
  clientProfileStore: { isFileUploadDrawer },
  clientDataStore: { clients },
  clientId,
  onClientSave,
  intl,
}) {
  const client = clients.get(clientId);
  const documents = client.get('documents');

  return (
    <Grid
      className="notes"
      direction="row"
      alignItems="center"
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h4" color="secondary">
          {t('.header', intl, __filenamespace)}
        </Typography>
      </Grid>
      <div className="contact-info__edit-button">
        <Button
          type="tertiary"
          rounded
          fullWidth
          size="1x"
          icon={isFileUploadDrawer ? 'close' : 'edit'}
          onClick={ClientProfileActions.toggleFileUploadDrawer}
        />
      </div>
      <AtttachmentsUploadModal client={client} onClientSave={onClientSave} />
      {documents.map(doc => (
        <Grid item xs={12} spacing={1} direction="row">
          <Card contentDirection="row" className="docBlock" key={doc.get('id')}>
            <div className="doc-content">
              <div className="doc-list-style">
                <PictureAsPdfIcon />
                <Typography>
                  <a
                    href={doc.get('url')}
                    target="_blank"
                    rel="noreferrer"
                    className="link-style"
                  >
                    {doc.get('name')}
                  </a>
                </Typography>
              </div>
              <CancelIcon
                className="cancel-icon-style"
                onClick={() => ClientProfileActions.onDeleteFile(doc.get('id'))}
              />
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default altContainer({
  stores: {
    clientProfileStore: ClientProfileStore,
    clientDataStore: ClientDataStore,
  },
})(Attachments);
