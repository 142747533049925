import * as React from 'react';
import { Grid } from '@upperhand/playmaker';

import Paper from '@mui/material/Paper';
import { boldText, uhContrast } from 'shared/styles/uhStyles.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  cardGrid: {
    padding: '12px 17px 12px 17px',
  },
  eventCardTypeIndicator: {
    height: '100%',
    width: 3,
    position: 'absolute',
  },
  productCard: {
    cursor: 'pointer',
    position: 'relative',
    marginBottom: 6,
  },
};

function ProductCard({
  color = 'white',
  icon,
  primaryText,
  secondaryText,
  tertiaryText,
  onClick,
  dark,
  className,
}) {
  const style = dark
    ? merge(styles.productCard, uhContrast.lightOnDark)
    : styles.productCard;

  return (
    <Paper style={style} onClick={onClick}>
      <div
        className={className ? `product-card ${className}` : 'product-card'}
        style={merge(styles.eventCardTypeIndicator, { backgroundColor: color })}
      >
        &nbsp;
      </div>
      <div style={styles.cardGrid}>
        <Grid container spacing={1}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <div>
              <div style={merge(boldText, { marginBottom: 4 })}>
                {primaryText}
              </div>
              <div style={{ marginBottom: tertiaryText ? 4 : 0 }}>
                {secondaryText}
              </div>
              <div>{tertiaryText}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

export default ProductCard;
