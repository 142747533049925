import React from 'react';
import { Typography, Grid } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import { ClientDataStore } from 'dataStores';

function ClientInfo({ clientId, clientDataStore: { clients } }) {
  const client = clients.get(clientId);

  return (
    <Grid container className="client-info" alignItems="center">
      <Grid item xs="auto">
        <UserAvatar user={client} showTypeBadge />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1">
          {client.get('first_name')} {client?.get('last_name')}
        </Typography>
        <Typography className="client-info__email">
          {client.get('email')}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default altContainer({
  stores: { clientDataStore: ClientDataStore },
})(ClientInfo);
