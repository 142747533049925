import * as React from 'react';
import { injectIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import AccountSetupPaysafeActions from 'onboarding/actions/AccountSetupPaysafeActions';
import BusinessStep from 'onboarding/components/AccountSetupPaysafe/steps/_BusinessStep.jsx';
import BankStep from 'onboarding/components/AccountSetupPaysafe/steps/_BankStep.jsx';
import IdentityStep from 'onboarding/components/AccountSetupPaysafe/steps/_IdentityStep.jsx';
import TOSStep from 'onboarding/components/AccountSetupPaysafe/steps/_TOSStep.jsx';

class AccountStepper extends React.Component {
  componentDidMount() {
    AccountSetupPaysafeActions.stepperMounted();
  }

  getStepContent() {
    const { step, handleNext } = this.props;
    switch (step) {
      case 0: {
        const {
          businessStepStore: { hasError, fields, isLoading },
        } = this.props;
        return (
          <BusinessStep
            handleNext={handleNext}
            hasError={hasError}
            fields={fields}
            isLoading={isLoading}
          />
        );
      }
      case 1: {
        const {
          bankStepStore: { hasError, fields, isLoading },
        } = this.props;
        return (
          <BankStep
            handleNext={handleNext}
            isLoading={isLoading}
            hasError={hasError}
            fields={fields}
          />
        );
      }
      case 2: {
        const {
          identityStepStore: {
            hasControllingInterest,
            hasError,
            isLoading,
            owners,
            identityVerification,
          },
        } = this.props;
        return (
          <IdentityStep
            handleNext={handleNext}
            hasControllingInterest={hasControllingInterest}
            hasError={hasError}
            isLoading={isLoading}
            owners={owners}
            identityVerification={identityVerification}
          />
        );
      }
      case 3: {
        const {
          tosStepStore: { tos, tosAccepted, isLoading },
        } = this.props;
        return (
          <TOSStep
            handleNext={handleNext}
            tos={tos}
            tosAccepted={tosAccepted}
            isLoading={isLoading}
          />
        );
      }
      default:
        return null;
    }
  }

  render() {
    const {
      step,
      steps,
      accountSetupPaysafeStore: { isProcessing },
    } = this.props;

    return (
      <Box sx={{ p: 5 }}>
        <Box sx={{ pb: 3 }}>
          <Stepper activeStep={step}>
            {steps.map(s => (
              <Step key={s}>
                <StepLabel>{s}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box>
          <SpinWhileLoading
            isProcessing={isProcessing}
            spinWhile="isProcessing"
            absolute={false}
          >
            {this.getStepContent()}
          </SpinWhileLoading>
        </Box>
      </Box>
    );
  }
}

export default injectIntl(AccountStepper);
