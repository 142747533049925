import * as React from 'react';
import { List } from 'immutable';
import { injectIntl } from 'react-intl';
import { Alert } from '@upperhand/playmaker';
import { href } from 'shared/components/MailTo.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import ApiErrorDialogStore from 'shared/stores/ApiErrorDialogStore.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { b64EncodeUnicode } from 'shared/utils/StringUtils.jsx';
import HttpError from 'shared/helpers/HttpError';

// enum?
// we can make this map to an I18 message so they can be internatinolized
// we should make a function to accesss this. this should also be its own file. reserver 0xx - 5xx for http error codes, we could use codes above that for custom errors we return
// Should this really be at the "top" level for messaging, so components have a chance to mannually override this?
const userErrorMessageMapper = {
  0: 'unknown issues', // this is the default value of an HttpError
  // 205: 'reset content', // could we use this to force page refreshes with updates? // because its a 200 level this does not actually error
  305: 'Use proxy ', // example of a 300 level error
  400: 'Bad Request', // not sure if we should report these? probably should prevent user from them
  401: 'Unauthenticated', // send user back to Login before we even report this?
  422: 'Unprocessable entity', // normally user has entered a bad form
  403: 'Unauthorized', // trying to access something user should not be... report this
  500: 'We seem to be having some issues, if this continues please contact support',
  504: 'Gateway Timeout',
  // custom error codes
  1000: 'You appear to be offline. Refresh the page, or check your internet connection!',
};

const ErrorDisplayMessage = error => {
  if (error instanceof HttpError) {
    return error.httpMessage || userErrorMessageMapper[error.status];
  }

  return error.message;
};

const styles = {
  errorList: { listStyle: 'disc inside' },
  errorItem: { whiteSpace: 'pre-wrap', textAlign: 'justify' },
};

const mailToSubject = userID => `[ERROR][${userID.toString(4)}] Please help!`;

const mailToBody = (userID, errors) => {
  const errorInformation = List(errors).concat([`\ncurrentUser: ${userID}`]);

  return (
    "\n\nInclude a personal message above this line if you'd like to, but please do not" +
    ` delete the following:\n${b64EncodeUnicode(errorInformation.join('\n'))}`
  );
};

function ErrorList({ errors }) {
  // Removing duplicates
  const filtered = errors
    .groupBy(item => item.message)
    .map(item => item.first())
    .toList();

  if (filtered.size === 1) {
    return (
      <p style={styles.errorItem}>{ErrorDisplayMessage(filtered.first())}</p>
    );
  }

  return (
    <ul style={styles.errorList}>
      {filtered.map(error => (
        <li key={ErrorDisplayMessage(error)} style={styles.errorItem}>
          {ErrorDisplayMessage(error)}
        </li>
      ))}
    </ul>
  );
}

function ErrorDialog(props) {
  const { errorsProp, ackErrorCallback, closeOn, intl, ...rest } = props;
  const errors = rest[errorsProp];
  const dialogOpen = !!errors && errors.size > 0;
  const userID = (currentUser() && currentUser().id) || -1;
  const mailTo = href({
    email: 'support@getupperhand.com',
    subject: mailToSubject(userID),
    body: mailToBody(userID, errors),
  });

  return (
    <Alert
      title={t('.error_title', intl, __filenamespace)}
      open={dialogOpen && !rest[closeOn]}
      onDismiss={ackErrorCallback}
      buttonText={t('.error_dismiss', intl, __filenamespace)}
      type="error"
      onSecondaryButtonClick={() => {
        window.location.href = mailTo;
      }}
      secondaryButtonText={t('.report', intl, __filenamespace)}
    >
      {dialogOpen && <ErrorList errors={errors} />}
    </Alert>
  );
}

export default compose(
  altContainer({
    store: ApiErrorDialogStore,
  }),
  injectIntl
)(ErrorDialog);
