import { Map, OrderedSet, Set } from 'immutable';
import debounce from 'lodash.debounce';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { ClientSource, PaymentPlanSource } from 'sources';
import { Filters } from 'containers/reports/types';

import PaymentPlansActions from './Actions';

class PaymentPlansStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.debouncedList = debounce(() => {
      this.page = 1;
      this.list();
    }, 600);

    this.bindListeners({
      mounted: PaymentPlansActions.mounted,
      listSuccess: PaymentPlansActions.listSuccess,
      listError: PaymentPlansActions.listError,
      clientListError: PaymentPlansActions.clientListError,
      clientListSuccess: PaymentPlansActions.clientListSuccess,
      selectPage: PaymentPlansActions.selectPage,
      updateFilter: PaymentPlansActions.updateFilter,
      list: PaymentPlansActions.list,
    });
  }

  reset() {
    this.isLoading = false;
    this.isLoadingClients = false;
    this.paymentPlanIds = OrderedSet();
    this.eventIds = Set();
    this.page = 1;
    this.perPage = 20;
    this.activeFilters = Map();
    this.defaultFilters = Map();
  }

  mounted({ eventId }) {
    this.reset();
    if (eventId) {
      this.eventIds = this.eventIds.add(eventId);
    }
    this.list();
  }

  getParams() {
    const params = {};

    if (this.eventIds.size) {
      params.event_ids = this.eventIds.toJS();
    }

    if (this.activeFilters.get(Filters.SEARCH)) {
      params.client_name = this.activeFilters.get(Filters.SEARCH);
    }

    return params;
  }

  list() {
    this.isLoading = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      ...this.getParams(),
    };

    PaymentPlanSource.list({
      params,
      success: PaymentPlansActions.listSuccess,
      error: PaymentPlansActions.listError,
    });
  }

  listSuccess({ payment_plans: paymentPlans, page, perPage, totalCount }) {
    const newIds = paymentPlans.map(el => el.id).toOrderedSet();

    const clientIds = paymentPlans
      .reduce(
        (set, paymentPlan) =>
          paymentPlan.get('clientId') > 0
            ? set.add(paymentPlan.get('clientId'))
            : set,
        Set()
      )
      .toJS();
    if (clientIds.length) {
      this.isLoadingClients = true;
      ClientSource.list({
        params: {
          ids: clientIds,
          per_page: this.perPage,
        },
        success: PaymentPlansActions.clientListSuccess,
        error: PaymentPlansActions.clientListError,
      });
    }

    this.page = page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.paymentPlanIds = newIds;
    this.isLoading = false;
  }

  listError() {
    this.isLoading = false;
  }

  clientListSuccess() {
    this.isLoadingClients = false;
  }

  clientListError() {
    this.isLoadingClients = false;
  }

  selectPage([page, perPage]) {
    this.page = page;
    this.perPage = perPage;
    this.list();
  }

  updateFilter([key, value]) {
    this.activeFilters = this.activeFilters.set(key, value);
    this.debouncedList();
  }
}

export default alt.createStore(PaymentPlansStore, 'PaymentPlansStore');
