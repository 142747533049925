import PropTypes from 'prop-types';
import * as React from 'react';
// eslint-disable-next-line no-unused-vars
function FreeSessions({ membership, style }) {
  return <div className="mem-view-free-sessions" style={style} />;
}

FreeSessions.propTypes = {
  membership: PropTypes.object.isRequired,
  style: PropTypes.object,
};

FreeSessions.defaultProps = {
  style: {},
};

export default FreeSessions;
