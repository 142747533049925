import { blue, grey, red } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import uhTheme from '../../_uh_theme.jsx';

const boldText = { fontWeight: 600 };

const uhColors = {
  // TODO rename and replace all colors per the styleguide
  activeBlue: uhTheme.palette.activeBlue,
  activeRepeatButton: '#454952',
  aliceBlue: 'var(--color-highlight-blue)',
  backgroundGrey: grey[100],
  blue: blue[600],
  tunaBlack: '#454952',
  charcoalBlack: '#2D333A',
  chevronBlue: blue[500],
  disabledGrey: '#B1B2B5',
  dividerGrey: 'var(--color-divide)',
  defaultIcon: '#9A9EAD',
  darkGrey: uhTheme.palette.darkGrey,
  darkRed: 'var(--color-error-red)',
  errorColor: red[500],
  fadedBlue: alpha(blue[600], 0.7),
  fadedGreen: alpha('#4ad669', 0.5),
  fadedGrey: alpha(grey[600], 0.5),
  green: uhTheme.palette.upperHandGreen,
  grey: grey[600],
  headerGrey: '#F9F9F9',
  hint: '#9A9EAD',
  hintActive: 'var(--color-old-gray)',
  iconGrey: '#7F7F7F',
  pmIconGray: 'var(--color-ui-gray)',
  primaryMain: 'var(--color-primary)',
  backgroundGray: 'var(--color-bg-gray)',
  iconLightGrey: 'var(--icon-light-grey)',
  leftNavGrey: 'var(--color-old-gray)',
  lightBlue: blue[400],
  lightGrey: grey[400],
  lighterGrey: '#E2E2E2',
  lightYellow: 'var(--color-highlight-yellow)',
  navIconGrey: '#9A9EAC',
  pink: '#EE375A',
  tabGrey: '#7F7F7F',
  textGrey: '#7F7F7F',
  textWhite: '#ffff',
  tableGrey: '#9A9EAD',
  dialogTitle: 'var(--color-old-gray)',
  fadedHintActive: alpha('#393c44', 0.5),
  warning: 'var(--color-warning-yellow)',
  moderateWarning: 'rgba(244, 182, 10, 0.55)',
  lightWarning: '#FAF0D4',
  white: 'var(--color-white)',
  resendLink: `#2196f3`,
  primaryButtonColor: '#00a0dd',
  disableBlue: '#c8ebf7',
  icons: {
    error: 'var(--color-error-red)',
    warn: 'var(--color-warning-yellow)',
  },
  purple: '#874AD6',
  darkBlue: '#000099',
};

const uhContrast = {
  darkOnLight: {
    color: uhColors.darkGrey,
    backgroundColor: 'var(--color-white)',
  },
  lightOnDark: {
    color: 'var(--color-white)',
    backgroundColor: uhColors.darkGrey,
  },
};

const uhFormElement = {
  field: {
    marginTop: '-12px',
    width: '100%',
  },
  floatingLabel: {
    fontWeight: uhTheme.textField.floatingLabelFontWeight,
    color: uhTheme.textField.floatingLabelColor,
    fontSize: uhTheme.textField.floatingLabelFontSize,
  },
  hint: {
    color: uhColors.hint,
  },
  mimicMuiFloatingLabel: {
    fontWeight: uhTheme.textField.floatingLabelFontWeight,
    lineHeight: '22px',
    fontSize: '0.75em',
    color: 'rgba(57, 60, 68, 0.498039)',
  },
};

const uhFlexbox = {
  row: {
    flex: '1em 2em',
    flexFlow: 'row nowrap',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  column: {
    flexFlow: 'column nowrap',
    display: 'flex',
  },
};

const uhButton = {
  default: {
    label: {
      textTransform: 'capitalize',
      color: uhColors.blue,
    },
  },
  primary: {
    label: {
      textTransform: 'capitalize',
    },
  },
  secondary: {
    label: {
      textTransform: 'capitalize',
    },
  },
};

const uhLink = {
  color: uhColors.blue,
  cursor: 'pointer',
};

const uhTable = {
  headerText: {
    fontVariant: 'small-caps',
    fontSize: '13px',
  },
};

export {
  boldText,
  uhButton,
  uhColors,
  uhContrast,
  uhFlexbox,
  uhFormElement,
  uhLink,
  uhTable,
};
