import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import { messageId, t } from 'shared/utils/LocaleUtils';

function ConfirmRemoveModal({ actions, coupon, isRemoving, intl }) {
  return (
    isRemoving &&
    coupon && (
      <Confirmation
        cancelButtonLabel={t('.btn.cancel', intl, __filenamespace)}
        confirmButtonLabel={t('.btn.confirm', intl, __filenamespace)}
        hasCheckbox={false}
        onCancel={actions.cancelRemove}
        onConfirm={actions.confirmRemove}
        open={isRemoving}
        title={t('.title', intl, __filenamespace)}
      >
        <FormattedMessage
          id={messageId('.text', __filenamespace)}
          values={{ name: coupon.get('name') }}
        />
      </Confirmation>
    )
  );
}

ConfirmRemoveModal.propTypes = {
  actions: PropTypes.object.isRequired,
  coupon: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isRemoving: PropTypes.bool.isRequired,
};

export default ConfirmRemoveModal;
