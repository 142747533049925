import EventTypeSelectorUIActions from 'shared/actions/EventTypeSelectorUIActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class EventTypeSelectorUIStore extends UpperHandStore {
  constructor() {
    super();

    this.componentId = null;

    this.shouldOpen = componentId => {
      if (componentId) {
        return this.dialogOpenRequested && componentId === this.componentId;
      }
      return this.dialogOpenRequested;
    };

    this.dialogOpenRequested = false;

    this.bindListeners({
      handleOpenDialog: EventTypeSelectorUIActions.OPEN_DIALOG,
      handleCloseDialog: EventTypeSelectorUIActions.CLOSE_DIALOG,
    });
  }

  handleOpenDialog({ componentId = null }) {
    this.componentId = componentId;
    this.dialogOpenRequested = true;
  }

  handleCloseDialog() {
    this.dialogOpenRequested = false;
    this.componentId = null;
  }
}

export default alt.createStore(
  EventTypeSelectorUIStore,
  'EventTypeSelectorUIStore'
);
