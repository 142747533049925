import React from 'react';
import PropTypes from 'prop-types';
import {
  FormModal,
  TextField,
  Grid,
  Button,
  Typography,
} from '@upperhand/playmaker';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';
import altContainer from 'shared/hocs/altContainer.jsx';

import { RegistrationPackageDataStore } from 'dataStores';

import CreditListDrawerActions from 'containers/creditListDrawer/Actions';

function RegistrationPackageAdjustmentModal({
  packageAdjustment,
  clientId,
  intl,
  registrationPackageDataStore: { registrationPackages },
}) {
  const [quantity, setQuantity] = React.useState(null);
  const pkg = registrationPackages.getIn([
    clientId,
    packageAdjustment.get('eventId'),
    packageAdjustment.get('packageId'),
  ]);
  const totalQty = pkg?.get('quantity', 0);
  const remainingQty = pkg?.get('quantity_remaining', 0);
  const disabled = quantity <= totalQty - remainingQty;

  React.useEffect(() => {
    setQuantity(pkg?.get('quantity', 0));
  }, [pkg]);
  return (
    <FormModal
      classes={{
        root: 'credit-adjustment-modal',
        content: 'credit-adjustment-modal__package-content',
      }}
      open={!!packageAdjustment.get('packageId', null)}
      title={t('.title', intl, __filenamespace)}
      actions={[
        {
          label: t('.cancel', intl, __filenamespace),
          action: CreditListDrawerActions.togglePackageAdjustmentModal,
          type: 'secondary',
        },
        {
          label: t('.confirm', intl, __filenamespace),
          action: () =>
            CreditListDrawerActions.updateRegistrationPackage({
              quantity: totalQty,
              updatedQuantity: quantity,
            }),
          type: 'primary',
        },
      ]}
    >
      <Grid container spacing={1} direction="row" justify="space-between">
        <Grid item container spacing={1} xs={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t('.available_total', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {remainingQty}/{totalQty}
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={8}>
          <Grid item>
            <Typography variant="subtitle2">
              {t('.adjusted_qty', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid
            item
            container
            className="credit-adjustment-modal__actions-container"
            direction="row"
            xs={12}
          >
            <Grid item container xs={3} justify="flex-end">
              <Button
                disabled={disabled}
                type="secondary"
                icon={<RemoveIcon />}
                onClick={() => setQuantity(quantity - 1)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={quantity}
                readOnly
                fullWidth
                onChange={() => null}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                type="secondary"
                icon={<AddIcon />}
                onClick={() => setQuantity(quantity + 1)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormModal>
  );
}

RegistrationPackageAdjustmentModal.propTypes = {
  intl: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  packageAdjustment: PropTypes.instanceOf(Map),
  registrationPackageDataStore: PropTypes.object,
};

RegistrationPackageAdjustmentModal.defaultProps = {
  packageAdjustment: Map({
    packageId: null,
    eventId: null,
  }),
  registrationPackageDataStore: {
    registrationPackages: Map(),
  },
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      registrationPackageDataStore: RegistrationPackageDataStore,
    },
  })
)(RegistrationPackageAdjustmentModal);
