import { Record } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

export default class ScheduledPayment extends Record({
  id: null,
  buyerId: null,
  clientId: null,
  membershipSubscriptionId: null,
  membershipId: 0,
  oldExpiration: 0,
  newExpiration: 0,
  subtotal: null,
  total: null,
  applied_adjustments: [],
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);
    super(parsedObject);
  }
}
