import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  boldText: {
    fontWeight: 'bold',
    marginTop: '5px',
  },
};

function CheckMessage({ payableTo }) {
  return (
    <div>
      <div style={{ margin: '2rem 0 1rem' }}>
        <div>
          <FormattedMessage
            id={messageId('.check_payable_to', __filenamespace)}
          />
        </div>
        <div style={styles.boldText}>{payableTo}</div>
      </div>
      <div style={{ margin: '1rem 0' }}>
        <FormattedMessage id={messageId('.payment_due', __filenamespace)} />
        <div style={styles.boldText}>
          <FormattedMessage id={messageId('.first_session', __filenamespace)} />
        </div>
      </div>
    </div>
  );
}

CheckMessage.propTypes = { payableTo: PropTypes.string.isRequired };

export default CheckMessage;
