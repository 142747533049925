import * as React from 'react';
import { injectIntl } from 'react-intl';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { sumInt } from 'shared/utils/ImmutableUtils';
import { t } from 'shared/utils/LocaleUtils.js';

function LineItem({ label, value }) {
  return (
    <FlexBoxJustify style={{ marginBottom: 5 }}>
      <div style={{ fontWeight: 'bold' }}>{label}</div>
      <FormattedCurrency value={value} fromCents />
    </FlexBoxJustify>
  );
}

function Total({ label, value }) {
  return (
    <FlexBoxJustify style={{ fontSize: 16 }}>
      <div>{label}</div>
      <div style={{ fontWeight: 'bold' }}>
        <FormattedCurrency value={value} fromCents />
      </div>
    </FlexBoxJustify>
  );
}

function OrderTotals({ order, style, intl }) {
  const allAdjustments = order.order_items.flatMap(i => i.applied_adjustments);

  const membershipAdjustment = allAdjustments
    .filter(
      a =>
        a.isMembershipEventDiscount() ||
        a.isMembershipRetailDiscount() ||
        a.isMembershipCreditPassDiscount()
    )
    .reduce(sumInt('amount'), 0);

  const couponAdjustment = allAdjustments
    .filter(a => a.isCoupon())
    .reduce(sumInt('amount'), 0);

  const manualAdjustment = allAdjustments
    .filter(a => a.isManual())
    .reduce(sumInt('amount'), 0);

  const accountCreditAdjustment = allAdjustments
    .filter(a => a.isAccountCredit())
    .reduce(sumInt('amount'), 0);

  const dueTotal = order.due_immediately;
  const { total } = order;

  return (
    <div style={style}>
      <div style={{ marginBottom: 10 }}>
        {(order?.subtotal !== order?.total ||
          order?.tax_exempt ||
          order?.fee_exempt) && (
          <LineItem
            label={t('.subtotal', intl, __filenamespace)}
            value={order.subtotal}
          />
        )}

        {membershipAdjustment < 0 && (
          <LineItem
            label={t('.membership_discounts', intl, __filenamespace)}
            value={membershipAdjustment}
          />
        )}

        {couponAdjustment < 0 && (
          <LineItem
            label={t('.coupon', intl, __filenamespace)}
            value={couponAdjustment}
          />
        )}

        {manualAdjustment < 0 && (
          <LineItem
            label={t('.manual', intl, __filenamespace)}
            value={manualAdjustment}
          />
        )}

        {accountCreditAdjustment < 0 && (
          <LineItem
            label={t('.account_credit', intl, __filenamespace)}
            value={accountCreditAdjustment}
          />
        )}

        {(order.tax > 0 || order.tax_exempt) && (
          <LineItem
            label={t('.sales_tax', intl, __filenamespace)}
            value={order.tax}
          />
        )}

        {(order?.fee > 0 || order?.fee_exempt) && (
          <LineItem
            label={t('.service_fee', intl, __filenamespace)}
            value={order?.fee}
          />
        )}

        {order.hasPaymentPlans() && (
          <LineItem
            label={t('.total', intl, __filenamespace)}
            value={Number(order.total) > 0 ? total : 0}
          />
        )}
      </div>

      <Total
        label={t(
          order.hasPaymentPlans() ? '.due_today' : '.total',
          intl,
          __filenamespace
        )}
        value={dueTotal || 0}
      />
    </div>
  );
}

export default injectIntl(OrderTotals);
