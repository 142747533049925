import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

function DuplicateMembershipWarning({
  profileName,
  existingMembershipName,
  newMembershipName,
}) {
  return (
    <FormattedMessage
      id={messageId('.already_present_warning', __filenamespace)}
      values={{
        interjection: (
          <span style={{ color: 'red' }}>
            <FormattedMessage id={messageId('.warning', __filenamespace)} />
          </span>
        ),
        profileName: <span style={{ fontWeight: 'bold' }}>{profileName}</span>,
        existingMembershipName: (
          <span style={{ fontWeight: 'bold' }}>{existingMembershipName}</span>
        ),
        newMembershipName: (
          <span style={{ fontWeight: 'bold' }}>{newMembershipName}</span>
        ),
      }}
    />
  );
}

export default DuplicateMembershipWarning;
