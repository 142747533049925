import React from 'react';
import { injectIntl } from 'react-intl';

import PageHeader from 'components/PageHeader/PageHeader.jsx';
import FilterButton from 'event_mgmt/index/components/_FilterButton.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function Header({ intl, filterActions }) {
  return (
    <PageHeader
      title={t('.manage_events', intl, __filenamespace)}
      actions={
        <ResponsiveElement
          largeScreen={<div />}
          smallScreen={<FilterButton filterActions={filterActions} />}
        />
      }
    />
  );
}

export default injectIntl(Header);
