import CapacitorPlugin from 'shared/native/helpers/CapacitorPlugin';
import { List } from 'immutable';
import { Contacts as CapacitorContacts } from '@capacitor-community/contacts';

// Wrapper for https://github.com/capacitor-community/contacts
class Contacts extends CapacitorPlugin {
  pluginName = 'Contacts';

  /**
   * Returns all contacts on the device
   * @return {Promise}
   */
  async allContacts() {
    await this.ready();
    const projection = {
      name: true,
      phones: true,
      emails: true,
      birthday: true,
      postalAddresses: true,
      image: true,
    };
    const response = await CapacitorContacts.getContacts({ projection });
    return List(response.contacts);
  }
}

export default new Contacts();
