import { List, Map, Set } from 'immutable';

import { isObject } from 'shared/utils/ObjectUtils.jsx';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils';

import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';

import { RegistrationSource, SessionSource } from 'sources';

import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import Client from 'shared/records/Client.jsx';
import Registration from 'shared/records/Registration';

class SchedulingDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.open = /sdo/.test(window.location.hash);

    this.isHandling = false;
    this.isLoading = true;
    this.eventId = null;
    this.client = new Client();
    this.selectedClientId = null;
    this.clients = List();
    this.clientRegistrations = List();
    this.step = 1;
    this.date = null;
    this.dateTimes = Map();
    this.packagePricing = null;
    this.rescheduleRegistrationId = null;
    this.rescheduleSessionId = null;
    this.loadDetails = Map({
      athleteStore: false,
      clientCreditStore: false,
      eventStore: false,
      automationStore: false,
    });

    this.bindListeners({
      toggleReschedule: SchedulingDrawerActions.TOGGLE_RESCHEDULE,
      setEventId: EventDisplayActions.CLIENT_DISPLAY_MOUNTED,
      setClient: SchedulingDrawerActions.CLIENT_SELECTED,
      setRegistrationAttendee:
        SchedulingDrawerActions.REGISTRATION_ATTENDEE_SELECTED,
      setClients: [
        AthleteActions.LIST_SUCCESS,
        SchedulingDrawerActions.SET_CLIENTS,
      ],
      setStaff: SchedulingDrawerActions.REGISTRATION_STAFF_CHANGED,

      closeDrawer: SchedulingDrawerActions.DRAWER_CLOSED,

      setHandling: [
        SchedulingDrawerActions.REGISTRATION_BOOKED,
        CartActions.REGISTRATION_PACKAGE_ADDED,
        CartActions.UPDATE,
      ],

      unsetHandling: [
        RegistrationActions.SCHEDULE_ERROR,
        RegistrationActions.SCHEDULE_SUCCESS,
        CartActions.ADD_ERROR,
        CartActions.ADD_SUCCESS,
        CartActions.UPDATE_SUCCESS,
      ],

      registrationScheduleSuccess: RegistrationActions.SCHEDULE_SUCCESS,
      registrationCancelled: RegistrationActions.CANCEL_SUCCESS,

      registrationReschedule: SchedulingDrawerActions.REGISTRATION_RESCHEDULE,
      registrationRescheduleSuccess:
        SchedulingDrawerActions.REGISTRATION_RESCHEDULE_SUCCESS,
      registrationRescheduleError:
        SchedulingDrawerActions.REGISTRATION_RESCHEDULE_ERROR,

      setCurrentStep: SchedulingDrawerActions.SET_CURRENT_STEP,
      setDate: SchedulingDrawerActions.REGISTRATION_DATE_CHANGED,
      setTime: SchedulingDrawerActions.REGISTRATION_TIME_CHANGED,
      packageAddedToCart: SchedulingDrawerActions.PACKAGE_ADDED_TO_CART,
      packageUpdatedInCart: CartActions.UPDATE_SUCCESS,

      athleteStoreReady: [
        AthleteActions.LIST_SUCCESS,
        AthleteActions.LIST_ERROR,
      ],
      clientCreditStoreReady: [
        ClientCreditActions.LIST_SUCCESS,
        ClientCreditActions.LIST_ERROR,
      ],
      eventStoreReady: [EventActions.FETCH_SUCCESS, EventActions.ERROR],
      automationStoreReady: [
        AutomationTemplateDescriptionActions.LIST_SUCCESS,
        AutomationTemplateDescriptionActions.LIST_ERROR,
      ],
    });
  }

  closeDrawer() {
    this.isBooking = false;
    this.isHandling = false;
    this.open = false;
    this.client = new Client();
    this.selectedClientId = null;
    this.selectedStaff = null;
    this.clientRegistrations = List();
    this.step = 1;
    this.date = null;
    this.dateTimes = Map();
    this.rescheduleRegistrationId = null;
    this.rescheduleSessionId = null;

    // Hack for event single page, On that page data won't refetch after drawer will be closed.
    if (!window.location.pathname.includes(this.eventId)) {
      StoreActions.prepareForReuse.defer();

      this.isLoading = true;
      this.packagePricing = null;
      this.loadDetails = Map({
        athleteStore: false,
        clientCreditStore: false,
        eventStore: false,
        automationStore: false,
      });
    }
  }

  setHandling() {
    this.isHandling = true;
  }

  unsetHandling() {
    this.isHandling = false;
  }

  getTimeItemKey(date) {
    const staffKey = this.selectedStaff || 'any';
    if (date) {
      return `${staffKey}_${this.selectedClientId}_${date}`;
    }
    return `${staffKey}_${this.selectedClientId}_${this.date}`;
  }

  setDate([_key, date]) {
    const formattedDate = date.format('YYYY-MM-DD');
    const key = this.getTimeItemKey(formattedDate);
    const times = this.dateTimes.get(key, Set());

    this.date = formattedDate;

    if (this.rescheduleRegistrationId) {
      this.dateTimes = Map();
      this.dateTimes = this.dateTimes.set(key, Set());
      return;
    }

    this.dateTimes = this.dateTimes.filter(t => t.size > 0);
    this.dateTimes = this.dateTimes.set(key, times.size === 0 ? Set() : times);
  }

  setTime([_key, time]) {
    const key = this.getTimeItemKey();

    if (this.rescheduleRegistrationId) {
      this.dateTimes = this.dateTimes.set(key, Set([time]));
      return;
    }

    const timeItem = this.dateTimes.get(key, Set());

    if (timeItem.size === 0) {
      this.dateTimes = this.dateTimes.set(key, Set([time]));
    } else {
      const newTimes = timeItem.includes(time)
        ? timeItem.remove(time)
        : timeItem.add(time);
      this.dateTimes = this.dateTimes.set(key, newTimes);
    }
  }

  toggleReschedule({ session, registration, fetchAdditionalInfo = false }) {
    const { id: sessionId, starts_at: startsAt, event_id: eventId } = session;
    const { id: registrationId, client_id: clientId } = registration;

    if (!this.open) {
      this.eventId = eventId;
      this.selectedClientId = clientId;
      this.rescheduleRegistrationId = registrationId;
      this.rescheduleSessionId = sessionId;
      this.client = new Client({ id: clientId });
      this.date = startsAt.format('YYYY-MM-DD');
      this.open = true;
      this.setRegistrationsForCurrentClient();

      if (!fetchAdditionalInfo) {
        this.updateLoading('clientCreditStore');
        this.updateLoading('athleteStore');
        this.updateLoading('automationStore');
      }

      if (!window.location.pathname.includes(eventId)) {
        EventDisplayActions.clientDisplayMounted.defer({
          eventId,
          fetchAdditionalInfo,
        });
      }
    } else {
      this.closeDrawer();
    }
  }

  registrationReschedule({ params }) {
    this.isHandling = true;

    RegistrationSource.reschedule({
      id: this.rescheduleRegistrationId,
      params,
      success: SchedulingDrawerActions.registrationRescheduleSuccess,
      error: SchedulingDrawerActions.registrationRescheduleError,
    });
  }

  registrationRescheduleSuccess(registration) {
    this.isHandling = false;

    SessionSource.fetch({
      id: this.rescheduleSessionId,
      success: () => {
        this.clientRegistrations = this.clientRegistrations.merge(
          List([registration])
        );
        this.step = 2;
      },
    });
  }

  registrationRescheduleError(...args) {
    this.isHandling = false;

    this.notifyError('Registration reschedule error', args);
  }

  setEventId({ eventId }) {
    this.eventId = eventId;
  }

  setClient(client) {
    this.closeDrawer();
    this.open = true;
    this.client = client || new Client();
    this.selectedClientId = this.client.id;

    this.setRegistrationsForCurrentClient();
  }

  setClients(data) {
    this.waitFor(AthleteStore);

    this.clients = AthleteStore.getState().allAthletes;

    if (data && !isObject(data)) {
      this.selectedClientId = data;
    }
  }

  setRegistrationsForCurrentClient() {
    this.clientRegistrations = this.clientRegistrations.filter(
      r => r.client_id === this.selectedClientId
    );
  }

  setRegistrationAttendee(id) {
    this.selectedClientId = id;
    this.dateTimes = Map();
  }

  setStaff([id]) {
    this.selectedStaff = id;
  }

  registrationCancelled([_, registration]) {
    const index = this.clientRegistrations.findIndex(
      r => r.id === registration.id
    );
    if (index >= 0) {
      this.clientRegistrations = this.clientRegistrations.delete(index);
    }
    this.setRegistrationsForCurrentClient();
  }

  registrationScheduleSuccess(registrations) {
    this.clientRegistrations = this.clientRegistrations.concat(
      List(registrations.map(r => new Registration(r)))
    );
    this.step = 2;
    this.date = null;
    this.dateTimes = Map();
    this.isHandling = false;
    MessageWindowActions.addMessage.defer('Session(s) booked successfully.');
  }

  setCurrentStep(step) {
    this.step = step;
  }

  packageAddedToCart(order) {
    this.date = null;
    this.dateTimes = Map();
    this.isHandling = false;

    CartActions.fetch.defer({ shouldFetchAdditionalInfo: false });
    MessageWindowActions.addMessageWithConfig.defer([
      'Added to Cart',
      {
        primaryText: `Qty: ${order.orderable.quantity} Sessions`,
        secondaryText: `Product: ${order.primary_display_text}`,
        position: {
          vertical: 'top',
          horizontal: 'right',
        },
        actions: [
          {
            label: 'View Cart',
            action: () => {
              SchedulingDrawerActions.drawerClosed.defer();
              redirectTo({ path: customerScopedRoute('cart') });
            },
          },
          {
            label: 'Back to Browse',
            action: SchedulingDrawerActions.drawerClosed.defer,
          },
        ],
      },
    ]);
  }

  packageUpdatedInCart() {
    this.date = null;
    this.dateTimes = Map();
    this.isHandling = false;

    CartActions.fetch.defer({ shouldFetchAdditionalInfo: false });
  }

  updateLoading(key, value = true) {
    this.loadDetails = this.loadDetails.set(key, value);
    this.isLoading = !this.loadDetails.every(item => item);
  }

  automationStoreReady({
    automation_template_descriptions: automationTemplates,
  }) {
    if ([1, 2].includes(automationTemplates.length)) {
      this.waitFor(AutomationTemplateDescriptionStore);

      const { descriptions } = AutomationTemplateDescriptionStore.getState();
      const atds = descriptions.filter(d => d && d.event_id === this.eventId);

      this.packagePricing = atds.find(atd => atd.isPackagePricing()) || null;
      this.updateLoading('automationStore');
    } else {
      this.updateLoading('automationStore');
    }
  }

  eventStoreReady() {
    this.updateLoading('eventStore');
  }

  clientCreditStoreReady([paginationData]) {
    const { page, per_page: perPage, total_count: totalCount } = paginationData;

    if (page * perPage >= totalCount) {
      this.updateLoading('clientCreditStore');
    }
  }

  athleteStoreReady() {
    this.updateLoading('athleteStore');
  }
}

export default alt.createStore(SchedulingDrawerStore, 'SchedulingDrawerStore');
