import { List, Map, Set, fromJS } from 'immutable';
import RegistrationField from 'shared/records/RegistrationField.jsx';
import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { isCollectionWithContents } from 'shared/utils/ObjectUtils.jsx';

class RegistrationFormStoreInterface extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.registrationFormActions = null;

    this.currentRegistrantId = () =>
      this.registrantIds.get(this.currentRegistrantIndex);
    this.isCompleted = registrationId => !!this.completed.get(registrationId);
    this.isCompletingRegistrationFor = eventId =>
      this.currentEventId === eventId;
    this.shouldShowCompleted = registrationId =>
      !!this.showCompleted.get(registrationId);
    this.hasRegistrationForm = eventId =>
      this.selectedFields.get(eventId, List()).size > 0;

    this.isAnswered = (questionId, registrantId = this.currentRegistrantId()) =>
      !!this.valueFor(questionId, registrantId);

    this.valueFor = (questionId, registrantId = this.currentRegistrantId()) =>
      this.answers.getIn([registrantId, questionId, 'answer']);
  }

  reset() {
    this.answers = Map();
    this.completed = Map();
    this.showCompleted = Map();
    this.requiredFields = Map();
    this.selectedFields = Map();
    this.registrantIdMap = Map();
    this.registrantIds = List();
    this.isLoading = true;

    this.currentEventId = null;
    this.currentRegistrationId = null;
    this.currentRegistrantIndex = 0;
  }

  handleAnswer([questionId, value]) {
    const defaultAnswer = Map({
      customer_user_id: this.currentRegistrantId(),
      eventId: this.currentEventId,
      custom_registration_field_id: questionId,
    });
    const answer = this.answers.getIn(
      [this.currentRegistrantId(), questionId],
      defaultAnswer
    );

    this.answers = this.answers.setIn(
      [this.currentRegistrantId(), questionId],
      answer.set('answer', value)
    );
  }

  handleComplete(eventId) {
    if (this.validateRegistration(this.currentRegistrationId)) {
      this.currentEventId = eventId;

      uhApiClient.post({
        url: `events/${eventId}/register`,
        data: JSON.stringify(this.payloadFor(eventId)),
        success: this.registrationFormActions.setCompletedSuccess,
        error: this.registrationFormActions.setCompletedError,
      });
    } else {
      this.completed = this.completed.set(this.currentRegistrationId, false);
      MessageWindowActions.addMessage.defer(
        'Please answer all required fields.'
      );
    }
  }

  handleCompleteSuccess() {
    this.completed = this.completed.set(this.currentRegistrationId, true);
    this.showCompleted = this.showCompleted.set(
      this.currentRegistrationId,
      this.validateRegistration(this.currentRegistrationId)
    );

    this.currentEventId = null;
    this.currentRegistrationId = null;
    this.registrantIds = List();
    this.currentRegistrantIndex = 0;

    MessageWindowActions.addMessage.defer(
      'Registration form submitted successfully.'
    );
  }

  handleCompleteError(...args) {
    this.completed = this.completed.set(this.currentRegistrationId, false);
    this.notifyError('error while submitting registration form', args);
  }

  handleStage([eventId, clientIds, registrationId]) {
    let registrantIds = List();

    if (isCollectionWithContents(clientIds)) {
      registrantIds = Set(clientIds).toList();
    } else {
      /* This special case exists because we can purchase events (and need to fill out
       * the registration) without actually booking the event (in which case the order
       * item won't have a user associated with it).
       */
      registrantIds = List([this.defaulRegistrationId]);
    }

    this.registrantIdMap = this.registrantIdMap.set(
      registrationId,
      Map({ event_id: eventId, registrant_ids: registrantIds })
    );

    if (eventId) {
      this.listAnswers(eventId);
    }
  }

  handleStart([eventId, registrationId]) {
    this.currentRegistrantIndex = 0;
    this.currentEventId = eventId;
    this.currentRegistrationId = registrationId;
    this.registrantIds = this.registrantIdMap
      .get(registrationId)
      .get('registrant_ids');
  }

  handleStop() {
    this.currentEventId = null;
    this.registrantIds = List();
  }

  handlePreviousRegistrant() {
    if (this.currentRegistrantIndex > 0) {
      this.currentRegistrantIndex -= 1;
    }
  }

  handleNextRegistrant() {
    if (this.currentRegistrantIndex < this.registrantIds.size - 1) {
      this.currentRegistrantIndex += 1;
    }
  }

  listAnswers(eventId) {
    uhApiClient.get({
      url: `events/${eventId}/registration_answers`,
      success: {
        action: this.registrationFormActions.listAnswersSuccess,
        args: [{ eventId }],
      },
      error: this.registrationFormActions.listAnswersError,
    });
  }

  loadAnswerData([data, { eventId }]) {
    /*
      {
        "answers": [
          {
            "id": 1,
            "answer": "71.75\"",
            "event_id": null,
            "meta_registration_field_id": 40
          },
          ...
        ]
      }
    */

    const answers = fromJS(data.answers);

    this.answers = this.answers.mergeDeep(
      answers
        .groupBy(a => a.get('customer_user_id'))
        .map((as, _) => as.groupBy(a => a.get('custom_registration_field_id')))
        .map((questions, _) => questions.map(as => as.first()))
    );

    RegistrationFieldActions.list.defer(eventId);
  }

  listAnswersError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing registration answers', args);
  }

  loadFieldData(data) {
    const eventId = parseInt(data.event_id, 10);
    const fields = List(data.registration_fields).map(
      f => new RegistrationField(f)
    );
    const requiredFileds = fields.filter(f => !!f.id && f.isRequired());
    const selectedFields = fields.filter(f => !!f.id).sortBy(f => f.ordinal);

    this.selectedFields = this.selectedFields.set(eventId, selectedFields);
    this.requiredFields = this.requiredFields.set(eventId, requiredFileds);

    this.validate();
    this.isLoading = false;
  }

  payloadFor(eventId) {
    const selectedFieldIds = this.selectedFields.get(eventId).map(f => f.id);

    const payload = this.answers
      .filter((_, registrantId) => this.registrantIds.includes(registrantId))
      .valueSeq()
      .map(questionMap => questionMap.valueSeq())
      .flatten(true)
      .filter(answer =>
        selectedFieldIds.includes(answer.get('custom_registration_field_id'))
      )
      .toJS();

    return { attributes: { answers: payload } };
  }

  validate() {
    this.registrantIdMap.forEach((_registrantIds, registrationId) => {
      this.validateRegistration(registrationId);
    });
  }

  validateRegistration(registrationId) {
    let isCompleted = false;
    const registration = this.registrantIdMap.get(registrationId);
    const eventId = registration.get('event_id');
    const registrants = registration.get('registrant_ids');
    const fields = this.requiredFields.get(eventId, List());

    if (fields.size === 0) {
      isCompleted = true;

      this.completed = this.completed.set(registrationId, isCompleted);
      this.showCompleted = this.completed.set(registrationId, isCompleted);
    } else {
      isCompleted = registrants.every(rId =>
        fields.every(f => this.answers.getIn([rId, f.id, 'answer']))
      );

      this.completed = this.completed.set(registrationId, isCompleted);
      this.showCompleted = this.completed.set(registrationId, isCompleted);
    }

    return isCompleted;
  }
}

export default RegistrationFormStoreInterface;
