class EditMembershipCancellationActions {
  constructor() {
    this.generateActions(
      'toggleModal',
      'toggleConfirmationModal',
      'setCancellationDate',
      'setCancellationReason',
      'updateCancellationDate',
      'updateCancellationDateSuccess',
      'updateCancellationDateError'
    );
  }
}

export default alt.createActions(EditMembershipCancellationActions);
