import SparkMD5 from 'spark-md5';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const getChecksum = file =>
  new Promise((resolve, reject) => {
    const chunkSize = 2097152; // 2MB
    const chunkCount = Math.ceil(file.size / chunkSize);
    const md5Buffer = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let chunkIndex = 0;

    const readNextChunk = () => {
      if (chunkIndex < chunkCount || (chunkIndex === 0 && chunkCount === 0)) {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const bytes = file.slice(start, end);

        fileReader.readAsArrayBuffer(bytes);
        chunkIndex += 1;

        return true;
      }

      return false;
    };

    fileReader.onload = event => {
      md5Buffer.append(event.target.result);

      if (!readNextChunk()) {
        const binaryDigest = md5Buffer.end(true);
        const base64digest = btoa(binaryDigest);
        resolve(base64digest);
      }
    };

    fileReader.onerror = event => reject(event);

    readNextChunk();
  });

const getPreSignedURL = (file, checksum, isPublicFile = false) =>
  new Promise((resolve, reject) => {
    uhApiClient.post({
      url: 'attachments/direct_uploads',
      data: JSON.stringify({
        attributes: {
          filename: file.name,
          byte_size: file.size,
          checksum,
          content_type: file.type || 'application/octet-stream',
          public: isPublicFile,
        },
      }),
      success: data => resolve(data),
      error: e => reject(e),
    });
  });

// eslint-disable-next-line import/prefer-default-export
export const upload = async (
  file,
  isPublicFile = false,
  returnUrl = false,
  onSuccess = () => {},
  onError = () => {}
) => {
  try {
    const checksum = await getChecksum(file);
    const {
      url,
      headers,
      signed_id: signedId,
    } = await getPreSignedURL(file, checksum, isPublicFile);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in headers) {
        xhr.setRequestHeader(key, headers[key]);
      }
      xhr.addEventListener('load', event => {
        const { status, readyState } = xhr;

        if (readyState === 4 && status >= 200 && status < 300) {
          const publicUrl = new URL(url);
          const fileUrl = publicUrl.origin + publicUrl.pathname;
          const response =
            isPublicFile && returnUrl ? { signedId, url: fileUrl } : signedId;

          onSuccess(response);
          resolve(response);
        } else {
          onError(event);
          reject(event);
        }
      });
      xhr.addEventListener('error', event => {
        onError(event);
        reject(event);
      });
      xhr.send(file.slice());
    });
  } catch (e) {
    onError(e);
    return Promise.reject(e);
  }
};
