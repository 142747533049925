class AdminPageActions {
  constructor() {
    this.generateActions(
      'mounted',
      'fetchTeam',
      'tabSelected',
      'eventFetchSuccess',
      'eventFetchError',
      'eventRevenueFetchSuccess',
      'eventRevenueFetchError',
      'automationTemplateDescriptionListSuccess',
      'automationTemplateDescriptionListError',
      'selectTabFromUrl'
    );
  }
}

export default alt.createActions(AdminPageActions);
