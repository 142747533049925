import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { List, Map } from 'immutable';
import Calendar from 'shared/components/icons/Calendar.jsx';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Button as PMButton, DateTimePicker } from '@upperhand/playmaker';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { convertDateToClientValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import TimeRangeList from 'shared/components/scheduling/TimeRangeList.jsx';
import VerticalDivider from 'shared/components/VerticalDivider.jsx';
import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

const styles = {
  addAnother: {
    textTransform: 'capitalize',
  },
  datePickerWrapper: {
    display: 'flex',
    marginBottom: 8,
  },
  specificDatesClientWrapper: {
    padding: '0 1em 1em 20px',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    marginBottom: 10,
    backgroundColor: 'var(--color-white)',
  },
  specificDatesEventWrapper: {
    padding: 16,
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: 'var(--color-white)',
  },
  mainDialogContainer: {
    display: 'none',
  },
  deleteButton: {
    backgroundColor: 'transparent',
    marginLeft: 4,
  },
  timeRangeListHeader: {
    backgroundColor: uhColors.headerGrey,
    padding: 0,
    height: '48px',
    marginBottom: 8,
    alignItems: 'center',
  },
};

const DateSpecificDaytimePicker = injectIntl(
  ({
    classes,
    intl,
    availabilitySchedule,
    handleAvailabilityScheduleChange,
    style,
    legend,
    fieldErrors,
    clientDrawer,
    showLabel,
    addBtnLabel,
  }) => {
    const mainDialog = React.useRef(null);

    const daytimes = () => availabilitySchedule.get('date_specific_daytimes');

    const maxDate = () => {
      if (availabilitySchedule.end_date) {
        return availabilitySchedule.end_date;
      }

      return moment().add(100, 'years').toDate();
    };

    const handleDayTimeChange = (keyPath, value) => {
      handleAvailabilityScheduleChange(
        ['date_specific_daytimes'].concat(keyPath),
        value
      );
    };

    const updateDayTimes = (day, modifierCallback) => {
      modifierCallback(daytimes().get(day), day, daytimes());
    };

    const addDate = date => {
      handleDayTimeChange(
        [date],
        List([Map({ start_time: '', end_time: '' })])
      );
    };

    const onChangeDate = (newDate, oldDate) => {
      handleAvailabilityScheduleChange(
        ['date_specific_daytimes'],
        daytimes().set(newDate, daytimes().get(oldDate)).delete(oldDate)
      );
    };

    const removeDate = date => {
      handleAvailabilityScheduleChange(
        ['date_specific_daytimes'],
        daytimes().delete(date)
      );
    };

    function TimeRangeListHeader({ day }) {
      return (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={styles.timeRangeListHeader}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={merge(boldText, {
              color: uhColors.primaryMain,
              fontSize: '15px',
            })}
          >
            <Calendar
              style={{
                color: uhColors.navIconGrey,
                marginLeft: '19px',
                marginRight: '15px',
                height: '20px',
                width: '20px',
              }}
            />
            <FormattedDate
              value={convertDateToClientValue(day)}
              weekday="short"
            />
            <VerticalDivider />
            <FormattedDate
              value={convertDateToClientValue(day)}
              month="short"
              day="numeric"
              year="numeric"
            />
          </Stack>
          <PMButton
            classes={{
              root: classes.deleteButton,
            }}
            type="tertiary"
            icon="close"
            rounded
            onClick={() => removeDate(day)}
          />
        </Stack>
      );
    }

    return (
      <div style={style}>
        <fieldset>
          <FlexBoxJustify style={{ flexDirection: 'column', marginBottom: 8 }}>
            {legend && <legend style={boldText}>{legend}</legend>}
            {clientDrawer && (
              <div style={{ fontWeight: 400, margin: '20px 0' }}>
                <FormattedMessage
                  id={messageId(
                    '.date_specific_availability_description',
                    __filenamespace
                  )}
                />
              </div>
            )}
          </FlexBoxJustify>
          {daytimes()
            .sortBy((times, day) => day)
            .map((times, day, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`specific_date_picker_wrapper_${day}`}
                style={
                  clientDrawer
                    ? styles.specificDatesClientWrapper
                    : styles.specificDatesEventWrapper
                }
              >
                {clientDrawer && (
                  <div style={styles.datePickerWrapper}>
                    <DateTimePicker
                      autoOk
                      fullWidth
                      type="date"
                      label={
                        i === 0 && showLabel
                          ? t('.blackout_dates', intl, __filenamespace)
                          : ''
                      }
                      placeholder="MMM D, YYYY"
                      name={`specific_availability_${i}`}
                      onChange={value =>
                        onChangeDate(value.format('YYYY-MM-DD'), day, i)
                      }
                      value={moment(day)}
                      format="MMM D, YYYY"
                      showClearIcon={false}
                      shouldDisableDate={value =>
                        availabilitySchedule.isDateSpecific(
                          value.format('YYYY-MM-DD')
                        )
                      }
                      maxDate={maxDate()}
                      minDate={availabilitySchedule.start_date}
                    />
                    <PMButton
                      classes={{
                        root: classes.deleteButton,
                      }}
                      type="tertiary"
                      icon="close"
                      rounded
                      onClick={() => removeDate(day)}
                    />
                  </div>
                )}
                <TimeRangeList
                  // eslint-disable-next-line react/no-array-index-key
                  key={day}
                  times={times}
                  label={!clientDrawer && <TimeRangeListHeader day={day} />}
                  paperDepth={0}
                  fieldErrors={fieldErrors}
                  day={day}
                  showPaper={false}
                  onUpdateDayTimes={updateDayTimes}
                  onChangeDayTime={handleDayTimeChange}
                />
              </div>
            ))
            .toList()}
        </fieldset>
        <Button
          color="dark"
          onClick={() => mainDialog.current.handleOpen()}
          disabled={!availabilitySchedule.start_date}
          startIcon={<AddIcon sx={{ color: uhColors.lightBlue }} />}
          style={{ textTransform: 'capitalize' }}
        >
          {addBtnLabel || t('.add_date_specific_date', intl, __filenamespace)}
        </Button>
        <div style={styles.mainDialogContainer}>
          <DateTimePicker
            ref={mainDialog}
            autoOk
            type="date"
            maxDate={maxDate()}
            minDate={availabilitySchedule.start_date}
            name="new-date-specific-date"
            value={
              availabilitySchedule.start_date
                ? new Date(availabilitySchedule.start_date)
                : new Date()
            }
            shouldDisableDate={value =>
              availabilitySchedule.isDateSpecific(value.format('YYYY-MM-DD'))
            }
            onChange={value => addDate(value.format('YYYY-MM-DD'))}
          />
        </div>
      </div>
    );
  }
);

export default withStyles(styles)(DateSpecificDaytimePicker);
