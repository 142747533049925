import { logError } from 'ErrorLogger.js';
import { fromJS } from 'immutable';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import HttpError from 'shared/helpers/HttpError';

class UpperHandStore {
  handleUpdateStore(state) {
    fromJS(state).forEach((value, key) => {
      this[key] = value;
    });
  }

  handleError(...args) {
    const errorType = args[1].split('.')[1].match(/.*[\D]/)[0];
    this.notifyError(`${errorType}`, args);
  }

  /** message: name of the function where we are reporting this error from.
   * TODO: rename to Action, if we want to provide a message to honeybadger to log something we should use new Error(message) once we convert */
  notifyError(message, errorArgs) {
    const error = errorArgs[0];
    if (!this.NotifyError) {
      this.NotifyError = logError(this.displayName);
    }

    if (error instanceof HttpError) {
      // if this is a network error dont report this to honeybadger we cant do anything about it
      if (error.status !== 1000) {
        // report to logger we have an api error response
        this.NotifyError(error, {
          name: error.name + error.status,
          message: error.httpMessage || error.message,
          action: message,
          params: error.data,
        });
      }

      // report to the user we have an issue.
      ApiErrorDialogActions.error.defer(error);
    } else {
      this.NotifyError(new Error(message), {
        // We are creating an error, "override the Error class name here so we get more then 'Error' "
        name: 'AltStoreError',
      });
    }
  }
}

export default UpperHandStore;
