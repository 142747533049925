/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { injectIntl } from 'react-intl';
import AltContainer from 'alt-container';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Button as PMButton,
  Grid,
  Icon,
  TextField,
} from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CalendarIcon from 'shared/components/icons/Calendar.jsx';
import DeletionConfirmation from 'resources/components/DeletionConfirmation.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import NoResourcesIcon from 'shared/components/icons/empty_states/Resources.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import ResourceDeletionActions from 'resources/actions/ResourceDeletionActions.js';
import ResourceDrawer from 'resources/components/ResourceDrawer.jsx';
import ResourceActions from 'resources/actions/ResourceActions.js';
import ResourceDrawerStore from 'resources/stores/ResourceDrawerStore.js';
import ResourceEditingStore from 'resources/stores/ResourceEditingStore.js';
import ResourceListingActions from 'resources/actions/ResourceListingActions.js';
import ResourceListingStore from 'resources/stores/ResourceListingStore.js';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffExpander from 'resources/components/_StaffExpander.jsx';
import PageHeader from 'components/PageHeader/PageHeader.jsx';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';

import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import '../styles.scss';

const styles = {
  resourceName: {
    fontWeight: 'bold',
    fontFamily: 'benton-sans-condensed, sans-serif',
    fontSize: 16,
  },

  searchBar: {
    padding: '0 20px',
    fontSize: 18,
    flex: '1 1 auto',
  },

  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },

  // Mimic MUI Paper
  tableRow: {
    boxSizing: 'border-box',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    borderRadius: 2,
    verticalAlign: 'top',
    backgroundColor: 'var(--color-white)',
  },

  breadcrumbSpacing: {
    marginTop: '16px',
  },

  pageHeader: {
    fontSize: 20,
    paddingBottom: 18,
  },
};

const onCreateClick = () => ResourceActions.createResourceClicked();
const onClearSearch = () => ResourceListingActions.clearSearchClicked();

function NoResourcesEmptyState({ searchString, intl }) {
  return searchString ? (
    <EmptyState
      image={<NoResourcesIcon />}
      headerText={t('.empty_state_search_header', intl, __filenamespace)}
      messageText={t('.empty_state_message', intl, __filenamespace)}
      primaryAction={{
        label: t('.create_resource', intl, __filenamespace),
        action: onCreateClick,
      }}
      secondaryAction={{
        label: t('.clear_search', intl, __filenamespace),
        action: onClearSearch,
      }}
    />
  ) : (
    <EmptyState
      image={<NoResourcesIcon />}
      headerText={t('.empty_state_header', intl, __filenamespace)}
      messageText={t('.empty_state_message', intl, __filenamespace)}
      primaryAction={{
        label: t('.create_resource', intl, __filenamespace),
        action: onCreateClick,
      }}
    />
  );
}

function ResourceLocation({ location, style, iconStyle }) {
  if (!location) {
    return null;
  }

  return (
    <FlexBoxCenter style={style}>
      <LocationOnIcon
        sx={{ color: uhColors.navIconGrey }}
        style={merge({ marginRight: 10 }, iconStyle)}
      />
      <div>{location.name}</div>
    </FlexBoxCenter>
  );
}

const ResourceCardsSmall = injectIntl(({ resources, intl }) => (
  <div>
    {resources.map(resource => {
      const [anchorEl, setAnchor] = React.useState();

      return (
        <Paper
          key={resource.id}
          style={{ padding: 16, margin: '10px 0', position: 'relative' }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <div style={merge(styles.resourceName, { marginBottom: 16 })}>
                {resource.name}
              </div>

              <ResourceLocation
                location={resource.location}
                style={{ marginLeft: -4 }}
                iconStyle={{ marginRight: 7 }}
              />

              <StaffExpander
                staff={resource.preferring_staff}
                style={{ marginBottom: -16 }}
              />
            </Stack>
            <div>
              <IconButton onClick={e => setAnchor(e.currentTarget)}>
                <MoreVertIcon sx={{ color: uhColors.iconGrey }} />
              </IconButton>
            </div>
          </Stack>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClick={() => setAnchor(null)}
          >
            <MenuItem
              onClick={() => ResourceActions.editResourceClicked(resource)}
            >
              {t('actions.edit', intl)}
            </MenuItem>
            <MenuItem
              onClick={() => ResourceDeletionActions.deleteRequested(resource)}
            >
              {t('actions.delete', intl)}
            </MenuItem>
          </Menu>
        </Paper>
      );
    })}
  </div>
));

function ResourceCardsLarge({ resources }) {
  return (
    <table style={styles.table}>
      <tbody>
        {resources.map(resource => (
          <tr key={resource.id} style={styles.tableRow}>
            <td
              style={merge(
                { paddingLeft: 16, paddingTop: 16 },
                styles.resourceName
              )}
            >
              {resource.name}
            </td>
            <td>
              <StaffExpander staff={resource.preferring_staff} />
            </td>
            <td>
              <ResourceLocation
                location={resource.location}
                style={{ height: 48 }}
              />
            </td>
            <td style={{ width: 96 }}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                style={{ height: 48 }}
              >
                <IconButton
                  onClick={() => ResourceActions.editResourceClicked(resource)}
                >
                  <EditIcon sx={{ color: uhColors.navIconGrey }} />
                </IconButton>
                <IconButton
                  onClick={() =>
                    ResourceDeletionActions.deleteRequested(resource)
                  }
                >
                  <DeleteIcon sx={{ color: uhColors.navIconGrey }} />
                </IconButton>
              </Stack>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function ResourceCards(props) {
  return (
    <ResponsiveElement
      smallScreen={<ResourceCardsSmall {...props} />}
      largeScreen={<ResourceCardsLarge {...props} />}
    />
  );
}

function ActionButtonsSmall() {
  return (
    <FlexBox>
      {/* TODO: Link to the resources calendar when it exists. */}
      <ReactRouterLink to={customerScopedRoute('calendar')}>
        <IconButton iconStyle={{ height: 20, width: 20 }}>
          <CalendarIcon color={uhColors.activeBlue} />
        </IconButton>
      </ReactRouterLink>
    </FlexBox>
  );
}

function ActionButtonsLarge({ intl }) {
  return (
    <FlexBox>
      {/* TODO: Link to the resources calendar when it exists. */}
      <ReactRouterLink
        to={customerScopedRoute('calendar')}
        style={{ textDecoration: 'none' }}
      >
        <Button
          color="dark"
          startIcon={
            <CalendarIcon
              color={uhColors.activeBlue}
              style={{ height: 20, width: 20 }}
            />
          }
        >
          {t('.resource_calendar', intl, __filenamespace)}
        </Button>
      </ReactRouterLink>
    </FlexBox>
  );
}

function ActionButtons(props) {
  return (
    <ResponsiveElement
      smallScreen={<ActionButtonsSmall {...props} />}
      largeScreen={<ActionButtonsLarge {...props} />}
    />
  );
}

const onPageSelect = (page, perPage) => {
  ResourceListingActions.paginationOptionsUpdated(page, perPage);
};

function ResourceListingContent({
  resourceDrawerStore,
  resourceListingStore,
  resourceEditingStore,
  intl,
}) {
  return (
    <div className="resources">
      <PageHeader
        title={t('.resources', intl, __filenamespace)}
        actions={<ActionButtons intl={intl} />}
      />
      <div
        className="iphone-x-content resources__header"
        style={styles.container}
      >
        <Grid container item spacing={1}>
          <div className="resources__add-button">
            <PMButton
              onClick={onCreateClick}
              rounded
              icon={<Icon name="add" className="resources__add-button-icon" />}
              size="2x"
            />
          </div>
          <Grid item xs>
            <TextField
              classes={{
                root: 'resources__search-field',
              }}
              fullWidth
              icon="search"
              onChange={event =>
                ResourceListingActions.searchStringUpdated(event.target.value)
              }
              placeholder={t('.search', intl, __filenamespace)}
              rounded
              value={resourceListingStore.searchString}
            />
          </Grid>
        </Grid>

        <SpinWhileLoading
          isLoading={resourceListingStore.isLoading}
          spinWhile="isLoading"
        >
          {resourceListingStore.resources.isEmpty() ? (
            <NoResourcesEmptyState
              searchString={resourceListingStore.searchString}
              intl={intl}
            />
          ) : (
            <div />
          )}
          <ResourceCards resources={resourceListingStore.resources} />
        </SpinWhileLoading>

        <Paginator
          currentPage={resourceListingStore.page}
          perPage={resourceListingStore.perPage}
          totalCount={resourceListingStore.totalCount}
          onPageSelect={onPageSelect}
          style={{ marginBottom: 10 }}
        />
      </div>

      <ResourceDrawer
        resourceDrawerStore={resourceDrawerStore}
        resourceEditingStore={resourceEditingStore}
      />
      <DeletionConfirmation />
    </div>
  );
}

class ResourceListing extends React.Component {
  componentDidMount() {
    if (isLoggedIn()) {
      StaffActions.list({});
      LocationActions.list();
      ResourceListingActions.list();
    }
  }

  render() {
    return (
      <AltContainer
        stores={{
          resourceDrawerStore: ResourceDrawerStore,
          resourceEditingStore: ResourceEditingStore,
          resourceListingStore: ResourceListingStore,
        }}
      >
        <ResourceListingContent {...this.props} />
      </AltContainer>
    );
  }
}

export default injectIntl(ResourceListing);
