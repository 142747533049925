import React, { memo, useCallback } from 'react';
import AltContainer from 'alt-container';
import { Set } from 'immutable';
import { injectIntl } from 'react-intl';

import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ContactsReportActions from 'reporting/index/actions/_ContactsReportActions.jsx';
import ContactsReportStore from 'reporting/index/stores/_ContactsReportStore.jsx';
import FranchiseLocationsStore from 'reporting/index/stores/_FranchiseLocationsStore.jsx';
import MultiSelectField from 'shared/components/MultiSelectField.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';

function ContactsFilters({
  contactReportStore: {
    canAdjustFilter,
    canApplyFilter,
    canClearFilter,
    isApplyingFilter,
    lastLoginAtAfter,
    lastLoginAtBefore,
    customerIds,
    neverLoggedIn,
  },
  franchiseLocationsStore: { franchiseLocations },
  intl,
}) {
  const lastLoginAtAfterChanged = useCallback(
    value => ContactsReportActions.filterUpdated('lastLoginAtAfter', value),
    []
  );

  const lastLoginAtBeforeChanged = useCallback(
    value => ContactsReportActions.filterUpdated('lastLoginAtBefore', value),
    []
  );

  const neverLoggedInChanged = useCallback(
    e => ContactsReportActions.filterUpdated('neverLoggedIn', e.target.checked),
    []
  );

  const franchiseLocationUpdated = useCallback(
    e => {
      const { value } = e.target;

      if (value[value.length - 1] === 'all') {
        const allIds = franchiseLocations.map(l => l.id);
        const isAllSelected = allIds.size === customerIds.size;
        const ids = !isAllSelected ? Set(allIds.toArray()) : Set();

        ContactsReportActions.filterUpdated('customerIds', ids);
      } else {
        ContactsReportActions.filterUpdated('customerIds', Set(value));
      }
    },
    [customerIds.size, franchiseLocations]
  );

  return (
    <Widget title={t('.title', intl, __filenamespace)} style={{ marginTop: 0 }}>
      <FormGroup>
        {currentUser().isAdmin() && (
          <MultiSelectField
            label={t('.franchise_location', intl, __filenamespace)}
            value={customerIds.toArray()}
            data={franchiseLocations.toArray()}
            onChange={franchiseLocationUpdated}
          />
        )}
        <DatePicker
          fullWidth
          name="last_login_at_after"
          disabled={!(canAdjustFilter && !neverLoggedIn)}
          label={t('.lastLoginAtAfter', intl, __filenamespace)}
          value={lastLoginAtAfter}
          shouldDisableDate={date =>
            lastLoginAtBefore && date > lastLoginAtBefore
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={params => <TextField fullWidth {...params} />}
          InputProps={{
            sx: { marginBottom: '10px' },
          }}
          DialogProps={{ disableEnforceFocus: true }}
          onChange={lastLoginAtAfterChanged}
        />
        <DatePicker
          fullWidth
          name="last_login_at_before"
          disabled={!(canAdjustFilter && !neverLoggedIn)}
          label={t('.lastLoginAtBefore', intl, __filenamespace)}
          value={lastLoginAtBefore}
          shouldDisableDate={date =>
            lastLoginAtAfter && date < lastLoginAtAfter
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={params => <TextField fullWidth {...params} />}
          InputProps={{
            sx: { marginBottom: '10px' },
          }}
          DialogProps={{ disableEnforceFocus: true }}
          onChange={lastLoginAtBeforeChanged}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={t('.neverLoggedIn', intl, __filenamespace)}
          checked={neverLoggedIn}
          style={{ marginBottom: 10 }}
          disabled={!canAdjustFilter}
          onChange={neverLoggedInChanged}
        />
      </FormGroup>
      <Button
        variant="contained"
        disabled={!canApplyFilter}
        onClick={ContactsReportActions.applyFilters}
      >
        {t(isApplyingFilter ? '.applying' : '.apply', intl, __filenamespace)}
      </Button>
      <Button
        disabled={!canClearFilter}
        onClick={ContactsReportActions.clearFilters}
      >
        {t('.clear', intl, __filenamespace)}
      </Button>
    </Widget>
  );
}

function ContactsFiltersWrapper({ intl }) {
  return (
    <AltContainer
      stores={{
        contactReportStore: ContactsReportStore,
        franchiseLocationsStore: FranchiseLocationsStore,
      }}
    >
      <ContactsFilters intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(memo(ContactsFiltersWrapper));
