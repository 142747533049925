import { Map } from 'immutable';
import Client from 'shared/records/Client.jsx';
import AutoCompleteAttendeeListActions from 'shared/actions/AutoCompleteAttendeeListActions';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { PayrollReportSource } from 'sources';

class AutoCompleteAttendeeListStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      listAttendee: PayrollReportActions.MOUNTED,
      listSuccess: AutoCompleteAttendeeListActions.LIST_SUCCESS,
      listError: AutoCompleteAttendeeListActions.LIST_ERROR,
      updateSearchString: AutoCompleteAttendeeListActions.SEARCH_STRING_UPDATED,
    });
  }

  reset() {
    this.clients = Map();
    this.searchString = '';
    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.shouldFetch = true;
    this.isLoading = false;

    this.formattedClients = Map();
  }

  listAttendee() {
    if (this.shouldFetch) {
      this.isLoading = true;
      this.shouldFetch = false;
      PayrollReportSource.listAttendee({
        params: this.queryParams(),
        success: AutoCompleteAttendeeListActions.listSuccess,
        error: AutoCompleteAttendeeListActions.listError,
      });
    }
  }

  listSuccess({ customer_users: customerUsers, total_count: totalCount }) {
    this.isLoading = false;
    this.totalCount = totalCount;
    this.clients = Map().withMutations(clientMap =>
      customerUsers.map(c => clientMap.set(c.id, new Client(c)))
    );
    this.formattedClients = this.clients
      .map(client => ({ text: client.name(), value: client.id }))
      .toList()
      .toJS();
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing clients', args);
  }

  updateSearchString(searchString) {
    if (!this.shouldFetch && this.searchString === searchString) {
      return;
    }

    this.searchString = searchString;
    this.page = 1;
    this.shouldFetch = true;

    this.listAttendee();
  }

  queryParams() {
    return {
      page: this.page,
      per_page: this.perPage,
      search_string: this.searchString,
      type: 'client',
      fields: [
        'managing_customer_user_id',
        'managing_customer_user_name',
        'active_membership_id',
        'active_membership_tier_id',
      ],
    };
  }
}

export default alt.createStore(
  AutoCompleteAttendeeListStore,
  'AutoCompleteAttendeeListStore'
);
