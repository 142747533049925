import * as React from 'react';
import { injectIntl } from 'react-intl';
import {
  Grid,
  Button as UHButton,
  Icon,
  Typography,
} from '@upperhand/playmaker';

import DateRangeDropdown from 'components/DateRangeDropdown';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import UsersAutoComplete from 'reporting/payroll/components/UsersAutoComplete.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';

import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import AutoCompleteAttendeeListActions from 'shared/actions/AutoCompleteAttendeeListActions';
import { Box } from '@mui/material';

const styles = {
  clearFilterBtnWrapper: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginRight: '1rem',
  },
  clearFilterLink: {
    color: '#009fdd',
    fontWeight: 600,
  },
  filterCheckbox: {
    display: 'none',
    margin: '0 8px 12px 8px',
    width: 'unset',
  },
  filterSelectField: {
    margin: '-4px 8px 0 8px',
    minWidth: '100%',
  },
  filterSubtitle: {
    marginLeft: 26,
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 12,
    marginBottom: 8,
  },
  filterEmptyState: {
    marginLeft: 26,
    fontSize: 15,
    marginTop: 8,
    marginBottom: 8,
    color: uhColors.textGrey,
  },
};

const onSearchClient = searchText =>
  AutoCompleteAttendeeListActions.searchStringUpdated(searchText);

const onCloseAutocomplete = (selectedAttendee, searchString) => {
  if (selectedAttendee && !searchString) {
    PayrollReportActions.attendeeFilterUpdated(null);
    AutoCompleteAttendeeListActions.searchStringUpdated(searchString);
  } else {
    AutoCompleteAttendeeListActions.searchStringUpdated(
      selectedAttendee ? selectedAttendee.name() : searchString
    );
  }
};

const attendeeSelected = id => {
  PayrollReportActions.attendeeFilterUpdated(id);
};

const clearFilters = e => {
  e.preventDefault();
  PayrollReportActions.filtersCleared();
  AutoCompleteAttendeeListActions.searchStringUpdated('');
};

function FilterDrawerToggleBtn({ intl, selfPayrollReportPermission }) {
  if (selfPayrollReportPermission) return null;
  return (
    <UHButton
      classes={{
        root: 'payroll-report__filter-button',
        label: 'payroll-report__header-button-label',
      }}
      fullWidth
      icon="filter"
      iconPosition="right"
      labelAlign="space-between"
      type="tertiary"
      rounded
      onClick={PayrollReportActions.toggleFilterDrawer}
    >
      {t('.btn_filter', intl, __filenamespace)}
    </UHButton>
  );
}

function LargeScreenFilters({
  attendeeSearchString,
  formattedClients,
  disabled,
  filters,
  selectedAttendee,
  filtersApplied,
  selfPayrollReportPermission,
  intl,
}) {
  return (
    <div className="payroll-report__filters-container">
      <Grid
        className="payroll-report__filters"
        container
        spacing={3}
        alignContent="center"
        justify="space-between"
      >
        <Grid item sm xs={12} lg={!hasAdminPermission() ? 3 : 2}>
          <DateRangeDropdown
            className="payroll-report__date-range"
            disabled={disabled}
            label={false}
            value={filters.getIn(['dateRange', 'value'])}
            onChange={PayrollReportActions.dateFilterUpdated}
          />
        </Grid>

        <Grid item sm xs={12} lg={6} alignItems="center" container>
          <UsersAutoComplete
            searchText={attendeeSearchString}
            dataSource={formattedClients}
            label=""
            variant="standard"
            selectedItem={selectedAttendee}
            placeholder={t('.name', intl, __filenamespace)}
            onChange={onSearchClient}
            onItemSelect={attendeeSelected}
            onLeave={() =>
              onCloseAutocomplete(selectedAttendee, attendeeSearchString)
            }
            style={styles.filterSelectField}
          />
        </Grid>
        <Grid item sm xs={12} lg={!hasAdminPermission() ? 3 : 2}>
          <FilterDrawerToggleBtn
            selfPayrollReportPermission={selfPayrollReportPermission}
            intl={intl}
          />
        </Grid>
        <Grid item sm xs={12} lg={2}>
          {!selfPayrollReportPermission && hasAdminPermission() && (
            <UHButton
              fullWidth
              icon={
                <Icon
                  name="cloudDownload"
                  className="payroll-report-export-button-icon"
                />
              }
              onClick={PayrollReportActions.downloadList}
              type="tertiary"
              rounded
              classes={{
                root: 'payroll-report-export-button',
                label: 'payroll-report-export-header-button-label',
              }}
            >
              {t('.btn_export', intl, __filenamespace)}
            </UHButton>
          )}
        </Grid>
      </Grid>
      {filtersApplied && (
        <Grid
          className="payroll-report__filters"
          container
          spacing={3}
          alignContent="center"
          justify="space-between"
        >
          <Box />
          <Box style={styles.clearFilterBtnWrapper}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a onClick={clearFilters} style={styles.clearFilterLink}>
              <Typography variant="subtitle2">
                {t('.clear_filters', intl, __filenamespace)}
              </Typography>
            </a>
          </Box>
        </Grid>
      )}
    </div>
  );
}
function SmallScreenFilters({
  attendeeSearchString,
  formattedClients,
  disabled,
  filters,
  selectedAttendee,
  selfPayrollReportPermission,
  intl,
}) {
  return (
    <div className="payroll-report__filters-container">
      <Grid
        className="payroll-report__filters"
        container
        spacing={3}
        alignContent="center"
        justify="space-between"
      >
        <Grid item sm xs={12} lg={2}>
          {!selfPayrollReportPermission && hasAdminPermission() && (
            <UHButton
              fullWidth
              icon={
                <Icon
                  name="cloudDownload"
                  className="payroll-report-export-button-icon"
                />
              }
              onClick={PayrollReportActions.downloadList}
              type="tertiary"
              rounded
              classes={{
                root: 'payroll-report-export-button',
                label: 'payroll-report-export-header-button-label',
              }}
            >
              {t('.btn_export', intl, __filenamespace)}
            </UHButton>
          )}
        </Grid>

        <Grid
          item
          sm
          xs={selfPayrollReportPermission ? 12 : 6}
          lg={!hasAdminPermission() ? 3 : 2}
        >
          <DateRangeDropdown
            className="payroll-report__date-range"
            disabled={disabled}
            label={false}
            value={filters.getIn(['dateRange', 'value'])}
            onChange={PayrollReportActions.dateFilterUpdated}
          />
        </Grid>

        <Grid item sm xs={6} lg={!hasAdminPermission() ? 3 : 2}>
          <FilterDrawerToggleBtn
            selfPayrollReportPermission={selfPayrollReportPermission}
            intl={intl}
          />
        </Grid>
        <Grid item sm xs={12} lg={6} alignItems="center" container>
          <UsersAutoComplete
            searchText={attendeeSearchString}
            dataSource={formattedClients}
            label=""
            variant="standard"
            selectedItem={selectedAttendee}
            placeholder={t('.name', intl, __filenamespace)}
            onChange={onSearchClient}
            onItemSelect={attendeeSelected}
            onLeave={() =>
              onCloseAutocomplete(selectedAttendee, attendeeSearchString)
            }
            style={styles.filterSelectField}
          />
        </Grid>
      </Grid>
    </div>
  );
}
function Filters(props) {
  return (
    <ResponsiveElement
      // eslint-disable-next-line react/jsx-props-no-spreading
      largeScreen={<LargeScreenFilters {...props} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      smallScreen={<SmallScreenFilters {...props} />}
    />
  );
}

export default injectIntl(Filters);
