import React from 'react';
import { ContentModal } from '@upperhand/playmaker';

export const equalWidth = () => {
  const containers = document.querySelectorAll('.mem-view__content-container');

  if (containers && containers.length) {
    let width = 210;
    // Find widest container
    for (let i = 0; i < containers.length; i += 1) {
      const blockWidth = containers[i].clientWidth;

      if (width < blockWidth) {
        width = blockWidth;
      }
    }

    // Set all containers width to be equal to widest
    for (let i = 0; i < containers.length; i += 1) {
      containers[i].style.width = `${width}px`;
    }
  }
};

function MembershipModal({ children, isOpen, isClose }) {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      equalWidth();
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  return (
    <ContentModal
      classes={{
        root: 'modal-component-memberships',
        content: 'modal-component-memberships__wrapper',
        title: 'modal-component-memberships__title',
        closeButton: 'modal-component-memberships__close-button',
      }}
      open={isOpen}
      showActions={false}
      title=""
      onSuccess={isClose}
      onAbort={isClose}
      size="1x"
    >
      {children}
    </ContentModal>
  );
}

export default MembershipModal;
