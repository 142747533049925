import PropTypes from 'prop-types';
import * as React from 'react';
import { debounce } from 'lodash';
import { smallScreen } from 'shared/utils/DOMUtils';

class ResponsiveElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
    this.setWidth = this.setWidth.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    window.addEventListener('resize', debounce(this.setWidth, 500));
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener('resize', debounce(this.setWidth, 500));
  }

  setWidth() {
    if (this._ismounted && this.state.windowWidth !== window.innerWidth) {
      this.setState({ windowWidth: window.innerWidth });
    }
  }

  render() {
    let component = smallScreen(this.props.breakpoint)
      ? this.props.smallScreen
      : this.props.largeScreen;

    if (typeof component === 'function') {
      component = component();
    }

    if (this.props.children) {
      component = React.cloneElement(component, {}, this.props.children);
    }

    // eslint-disable-next-line no-extra-boolean-cast
    return !!component ? component : null;
  }
}

ResponsiveElement.defaultProps = {
  breakpoint: 768,
  children: null,
};

ResponsiveElement.propTypes = {
  breakpoint: PropTypes.number,
  children: PropTypes.node,
  largeScreen: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  smallScreen: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default ResponsiveElement;
