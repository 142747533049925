import PropTypes from 'prop-types';
import * as React from 'react';
import Button from '@mui/material/Button';
import { merge } from 'shared/utils/ObjectUtils.jsx';

function StateChangingButton({
  label,
  disabled,
  labelInProgress,
  inProgress,
  ...otherProps
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...merge(otherProps, { disabled: disabled || inProgress })}>
      {inProgress ? labelInProgress : label}
    </Button>
  );
}

StateChangingButton.defaultProps = {
  disabled: false,
  label: 'Save',
  labelInProgress: 'Saving',
  style: {},
  inProgress: false,
  variant: 'contained',
};

StateChangingButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelInProgress: PropTypes.string,
  inProgress: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.string,
};

export default StateChangingButton;
