/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import EventPrice from 'shared/components/EventPrice.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { dateRange } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { humanizeDuration } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  wrapper: {
    borderLeftStyle: 'solid',
    borderLeftWidth: '4px',
    marginBottom: '10px',
  },

  eventTitle: {
    fontFamily: 'benton-sans-condensed, sans-serif',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '21px',
    color: '#2B2D34',
  },

  eventType: {
    fontSize: 14,
    lineHeight: '17px',
    color: '#2B2D34',
    display: 'inline-block',
  },

  almostFull: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '17px',
    color: 'var(--color-old-gray)',
    border: '1px solid var(--color-unpaid-red)',
    marginLeft: '0.5em',
    padding: '0 0.5em',
    display: 'inline-block',
  },

  scheduleInfoLine1: {
    fontFamily: 'benton-sans-condensed, sans-serif',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '21px',
    color: '#2B2D34',
    whiteSpace: 'nowrap',
  },

  scheduleInfoLine2: {
    fontSize: 14,
    lineHeight: '17px',
    color: '#6D6C6C',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },

  AttendeeInfo: {
    marginTop: 6,
    marginBottom: 4,
  },
};

function BlueButton({ label }) {
  return (
    <Button
      variant="contained"
      disableTouchRipple
      sx={{ color: uhColors.white, backgroundColor: uhColors.activeBlue }}
    >
      {label}
    </Button>
  );
}

const AttendeeInfo = injectIntl(({ event, athletes, intl }) => {
  const attendeeIds = event.purchased_registrations
    .map(r => r.customer_user_id)
    .filter(id => id != null)
    .toSet();

  if (attendeeIds.size === 0) {
    return <BlueButton label={t('.schedule', intl, __filenamespace)} />;
  }
  const attendee =
    attendeeIds.size === 1
      ? athletes.find(a => a.id === attendeeIds.first(), null, {
          first_name: ' ',
          alternativeImage: () => null,
        })
      : {
          first_name: attendeeIds.size.toString(),
          alternativeImage: () => null,
        };

  return <UserAvatar user={attendee} style={{ marginRight: 0 }} />;
});

function CardLayout({
  athletes,
  automations,
  event,
  purchased,
  scheduleInfoLine1,
  scheduleInfoLine2,
}) {
  return (
    <Paper
      sx={merge(styles.wrapper, {
        borderLeftColor: event.event_type.color,
        padding: '6px 16px 6px 18px',
      })}
    >
      <Link to={event.url()} style={{ textDecoration: 'none' }}>
        <FlexBoxJustify style={{ flexWrap: 'wrap' }}>
          <div style={{ flex: '1 1 60%', margin: '6px 0' }}>
            <div style={styles.eventTitle}>{event.title}</div>
            <div style={styles.eventType}>{event.event_type.name}</div>
            {event.isAlmostFull() && (
              <div style={styles.almostFull}>
                <span style={{ color: 'var(--color-unpaid-red)' }}>
                  {event.spots_remaining}
                </span>
                &nbsp;
                <FormattedMessage
                  id={messageId('.spaces_left', __filenamespace)}
                  values={{ spots: event.spots_remaining }}
                />
              </div>
            )}
          </div>
          <FlexBoxJustify
            style={{ flex: '1 1 40%', alignItems: 'center', margin: '6px 0' }}
          >
            <div>
              <div style={styles.scheduleInfoLine1}>{scheduleInfoLine1}</div>
              <div style={styles.scheduleInfoLine2}>{scheduleInfoLine2}</div>
            </div>
            {purchased ? (
              <AttendeeInfo event={event} athletes={athletes} />
            ) : (
              <BlueButton
                label={<EventPrice event={event} automations={automations} />}
              />
            )}
          </FlexBoxJustify>
        </FlexBoxJustify>
      </Link>
    </Paper>
  );
}

const OpenBookingCard = injectIntl(({ intl, ...props }) => (
  <CardLayout
    {...props}
    scheduleInfoLine1={
      <FlexBox style={{ alignItems: 'baseline' }}>
        <AccessTimeIcon
          sx={{ height: '16px', width: '16px', marginRight: '2px' }}
        />
        <div>
          {humanizeDuration(props.event.getIn(['schedules', 0, 'duration']))}
        </div>
      </FlexBox>
    }
    scheduleInfoLine2={t('.session', intl, __filenamespace)}
  />
));

function FixedScheduleCard({ event, ...props }) {
  const startDate = moment(
    event.getIn(['schedules', 0, 'availability_schedule', 'start_date'])
  );
  const endDate = moment(
    event.getIn(['schedules', 0, 'availability_schedule', 'end_date'])
  );

  const dateRangeText =
    startDate === endDate
      ? startDate.format('MMM D YY')
      : dateRange(startDate, endDate, 'MMM D YY');

  return (
    <CardLayout
      {...props}
      event={event}
      scheduleInfoLine1={dateRangeText}
      scheduleInfoLine2={startDate.format('ddd')}
    />
  );
}

function EventCard(props) {
  const { event } = props;
  if (event.isOpenBooking()) {
    return <OpenBookingCard {...props} />;
  }
  return <FixedScheduleCard {...props} />;
}

export default EventCard;
