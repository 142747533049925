import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DiscountValue from 'shared/components/DiscountValue.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';
import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  discountContainer: {
    position: 'relative',
    paddingLeft: 25,
    fontSize: 15,
    marginBottom: 15,
  },

  icon: {
    height: 16,
    width: 16,
    position: 'absolute',
    left: 0,
  },
};

function MembershipDiscount({ eventDiscount }) {
  return (
    <div style={styles.discountContainer}>
      <MembershipIcon
        style={styles.icon}
        color={eventDiscount?.subscribable_color}
      />
      <FlexBoxJustify>
        <div style={{ fontWeight: 'bold' }}>
          {eventDiscount?.subscribable_name}
        </div>
        <FormattedMessage
          id={messageId('.value_off', __filenamespace)}
          values={{
            value: <DiscountValue discount={eventDiscount.discount} />,
          }}
        />
      </FlexBoxJustify>
    </div>
  );
}

function MembershipDiscountList({ eventDiscounts, style }) {
  if (!eventDiscounts || eventDiscounts.isEmpty()) {
    return null;
  }

  return (
    <div style={style}>
      <SectionHeader style={{ marginBottom: 20 }}>
        <FormattedMessage
          id={messageId('.membership_discounts', __filenamespace)}
        />
      </SectionHeader>
      {eventDiscounts.map(med => (
        <MembershipDiscount key={med.id} eventDiscount={med} />
      ))}
    </div>
  );
}

export default MembershipDiscountList;
