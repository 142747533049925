import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage } from 'react-intl';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import SalesFilters from 'reporting/sales_detail/components/_SalesFilters.jsx';
import SalesDetailActions from 'reporting/sales_detail/actions/SalesDetailActions.js';
import SalesDetailStore from 'reporting/sales_detail/stores/SalesDetailStore.js';
import SalesTables from 'reporting/sales_detail/components/_SalesTables.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';

import '../styles.scss';

function ReportWrapper(props) {
  const {
    salesDetailStore: { filters, filtersApplied },
  } = props;
  const {
    salesDetailStore: {
      productTypeSales,
      retailCategorySales,
      paymentTypeSales,
      saleTypeSales,
    },
  } = props;
  const {
    salesDetailStore: {
      productTypeTotals,
      retailCategoryTotals,
      paymentTypeTotals,
      saleTypeTotals,
    },
  } = props;
  return (
    <div
      className="sales-report"
      style={{
        padding: '25px 30px 30px',
        height: '100%',
      }}
    >
      <PageHeader
        title={
          <FormattedMessage id={messageId('.sales_detail', __filenamespace)} />
        }
      />

      <SalesFilters filters={filters} filtersApplied={filtersApplied} />
      <SalesTables
        productTypeSales={productTypeSales}
        productTypeTotals={productTypeTotals}
        retailCategorySales={retailCategorySales}
        retailCategoryTotals={retailCategoryTotals}
        paymentTypeSales={paymentTypeSales}
        paymentTypeTotals={paymentTypeTotals}
        saleTypeSales={saleTypeSales}
        saleTypeTotals={saleTypeTotals}
      />
    </div>
  );
}

class SalesDetail extends React.Component {
  componentDidMount() {
    SalesDetailActions.mounted();
  }

  render() {
    return (
      <AltContainer stores={{ salesDetailStore: SalesDetailStore }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ReportWrapper {...this.props} />
      </AltContainer>
    );
  }
}

export default SalesDetail;
