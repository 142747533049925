import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function UnpaidIcon() {
  return (
    <SvgIcon viewBox="0 0 18 22">
      <rect width={18} height={22} rx={2} fill="#FF3049" />
      <path
        d="M8.528 15.108C6.98 14.952 6.104 14.16 5.516 13.2L6.824 12.36C7.316 13.044 7.784 13.596 8.588 13.752V11.208C7.136 10.848 5.756 10.32 5.756 8.616C5.756 7.104 6.836 6.132 8.528 6V5.172H9.68V6.036C11.012 6.216 11.732 6.864 12.26 7.62L10.964 8.556C10.616 8.052 10.256 7.632 9.644 7.464V9.804C11.312 10.2 12.572 10.776 12.572 12.468C12.572 13.788 11.72 14.928 9.68 15.108V16.116H8.528V15.108ZM8.588 7.392C7.868 7.488 7.532 7.908 7.532 8.448C7.532 9.036 7.892 9.312 8.588 9.528V7.392ZM9.644 13.764C10.412 13.656 10.796 13.308 10.796 12.648C10.796 12.048 10.424 11.724 9.644 11.484V13.764Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default UnpaidIcon;
