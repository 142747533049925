import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Set } from 'immutable';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';

import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import CreditPassCredits from 'credit_passes/components/_CreditPassCredits.jsx';
import CreditPassExpiration from 'credit_passes/components/_CreditPassExpiration.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Header from 'point_of_sale/components/Header.jsx';
import POSCreditPassPackageActions from 'point_of_sale/actions/POSCreditPassPackageActions.js';
import ProductInformation from 'shared/components/ProductInformation.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  benefitsBox: {
    backgroundColor: uhColors.lightYellow,
    marginBottom: 25,
    padding: '12px 12px 14px 18px',
  },

  cancelButton: {
    height: 50,
    marginTop: 10,
  },

  contentContainer: {
    padding: 20,
    lineHeight: '24px',
  },
  purchaseTarget: {
    fontWeight: 600,
    fontSize: 15,
  },
  creditPassExpiration: {
    display: 'block',
    marginTop: 15,
    marginBottom: 12,
    fontSize: 15,
    lineHeight: '24px',
    fontStyle: 'italic',
  },
  purchasedComponent: {
    color: uhColors.darkRed,
    textAlign: 'right',
  },
  profileCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 0 5px 10px',
  },
  selectedProfilesList: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};

function ProfileCard({ profile, onRemove }) {
  return (
    <Paper style={styles.profileCard} key={profile.id}>
      <AvatarWithName user={profile} />
      <IconButton
        iconStyle={styles.icon}
        onClick={() => onRemove(profile.id)}
        style={styles.button}
      >
        <ClearIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
    </Paper>
  );
}

const CreditPassRegistering = React.memo(
  ({
    intl,
    posCreditPassPackageStore,
    posEventTypeListStore: { isLoading: typesLoading, eventTypes },
    posSpecificEventsListStore: { isLoading: eventsLoading, events },
    posStore,
  }) => {
    const {
      changed,
      creditPass,
      orderItemId,
      allProfiles,
      creditPassPackage,
      availableProfiles,
      isLoading: creditPassLoading,
    } = posCreditPassPackageStore;

    const clientIds = creditPassPackage.get('client_ids', Set()).toJS();

    return (
      <div className="credit-pass-registering">
        <Header selectedClient={posStore.selectedClient}>
          <ProductInformation
            price={<FormattedCurrency value={creditPass.price} fromCents />}
            primaryText={creditPass.name}
            typeText={t('.credit_pass', intl, __filenamespace)}
          />
        </Header>
        <SpinWhileLoading
          isLoading={typesLoading || eventsLoading || creditPassLoading}
        >
          <div style={styles.contentContainer}>
            <div style={{ marginBottom: 25 }}>{creditPass.description}</div>

            {creditPass.credit_pass_credits.size > 0 && (
              <div style={styles.benefitsBox}>
                <CreditPassCredits
                  creditPass={creditPass}
                  events={events}
                  eventTypes={eventTypes}
                />

                <CreditPassExpiration
                  creditPassId={creditPass.id}
                  style={styles.creditPassExpiration}
                />
              </div>
            )}

            <ProfileSelector
              preventProfileCreation
              header={t('.for_whom', intl, __filenamespace)}
              headerStyle={styles.purchaseTarget}
              hintText={t('.choose_profile', intl, __filenamespace)}
              athletes={availableProfiles}
              value={null}
              afterProfileCreate={() => null}
              onChange={(_, __, id) =>
                POSCreditPassPackageActions.selectProfile(id)
              }
            />

            <div style={styles.selectedProfilesList}>
              {clientIds.map(id => (
                <ProfileCard
                  key={id}
                  profile={allProfiles.get(id)}
                  onRemove={clientId =>
                    POSCreditPassPackageActions.selectProfile(clientId)
                  }
                />
              ))}
            </div>

            <CartCommitButton
              handleAddToCartClick={() => {
                POSCreditPassPackageActions.addToCartClick(creditPassPackage);
              }}
              handleUpdateCartClick={() => {
                POSCreditPassPackageActions.updateCartClick(orderItemId, {
                  orderable: creditPassPackage,
                });
              }}
              handleViewInCartClick={
                POSCreditPassPackageActions.viewInCartClicked
              }
              hasOrderItemId={orderItemId}
              hasUpdate={changed}
              itemIsValid={clientIds.length > 0}
              // itemIsValid={profileCanPurchase(creditPassPackage.client_id)}
            />
            <Button
              fullWidth
              variant="contained"
              color="default"
              style={styles.cancelButton}
              onClick={POSCreditPassPackageActions.cancelClicked}
            >
              {t('actions.cancel', intl, __filenamespace)}
            </Button>
          </div>
        </SpinWhileLoading>
      </div>
    );
  }
);

export default injectIntl(CreditPassRegistering);
