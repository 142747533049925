import * as React from 'react';
import { Grid, Card, Typography, Button } from '@upperhand/playmaker';
import { ClientDataStore } from 'dataStores';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import Client from 'shared/records/Client.jsx';
import ManagedClientActions from 'shared/actions/ManagedClientActions.jsx';
import ManageProfilesDialog from 'user_management/shared/components/_ManageProfilesDialog.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import history from 'routes/History.js';
import { t } from 'shared/utils/LocaleUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import ClientProfileActions from '../Actions';

function ProfileCard({ client, isMobile, useOutsideDrawer }) {
  let actions = ClientProfileActions;

  if (currentUser().isStaff()) {
    actions = ClientProfileDrawerActions;
  }

  if (currentUser().isStaff() && useOutsideDrawer) {
    actions = ClientProfileActions;
  }

  return (
    <Card
      contentDirection="row"
      onClick={() =>
        !currentUser().isStaff() || (currentUser().isStaff() && isMobile)
          ? actions.mounted({
              clientId: client.id,
              isInitialPoint: false,
            })
          : history.push(customerScopedRoute(`/clients/${client.id}`))
      }
      rootStyle={{ backgroundColor: 'inherit' }}
    >
      <Grid container direction="row" alignItems="center">
        <UserAvatar user={client} />
        <Typography>{client.name()}</Typography>
      </Grid>
    </Card>
  );
}

function ManagedProfiles({
  clientId,
  managedProfileIds,
  clientDataStore: { clients },
  intl,
  isMobile,
  useOutsideDrawer,
}) {
  const actions = useOutsideDrawer
    ? ClientProfileActions
    : ClientProfileDrawerActions;
  const client = clients.get(clientId);
  const [isAddingProfile, setIsAddingProfile] = React.useState(false);

  const onProfileAdd = () => {
    ManagedClientActions.updateStore(
      new Client({ managed_by_id: client.user_id }).toJS()
    );
    setIsAddingProfile(true);
  };

  const hidden = !client || client.managed_by_id;

  if (hidden) return null;

  return (
    <Grid className="managed-profiles" direction="column" container xs={12}>
      <Typography variant="h4" color="secondary">
        {t('.header', intl, __filenamespace)}
      </Typography>
      <div className="managed-profiles__add-button">
        <Button
          type="tertiary"
          rounded
          size="1x"
          icon="add"
          onClick={onProfileAdd}
        >
          {t('.add_profile', intl, __filenamespace)}
        </Button>
      </div>
      {managedProfileIds.map(id => {
        const managee = clients.get(id);
        if (!managee) return null;

        return (
          <ProfileCard
            key={id}
            client={managee}
            isMobile={isMobile}
            useOutsideDrawer={useOutsideDrawer}
          />
        );
      })}
      <ManageProfilesDialog
        afterCreate={actions.listManagedProfiles.defer}
        closeDialog={() => setIsAddingProfile(false)}
        open={isAddingProfile}
        intl={intl}
      />
    </Grid>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(ManagedProfiles);
