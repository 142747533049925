import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Resource({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-433.000000, -563.000000)">
          <g transform="translate(415.000000, 423.000000)">
            <g transform="translate(18.000000, 140.000000)">
              <rect
                fill={color}
                x="0"
                y="0"
                width="18"
                height="18"
                rx="3.375"
              />
              <path
                d="M11.86,12.003 L10.393,12.003 L9.214,9.87 L8.278,9.87 L8.278,12.003 L7,12.003 L7,
                 6 L9.808,6 C11.059,6 11.824,6.819 11.824,7.935 C11.824,8.997 11.158,9.564 10.51,
                 9.717 L11.86,12.003 Z M9.619,8.745 C10.132,8.745 10.519,8.439 10.519,7.935 C10.519,
                 7.431 10.132,7.125 9.619,7.125 L8.278,7.125 L8.278,8.745 L9.619,8.745 Z"
                fill="var(--color-white)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Resource.defaultProps = {
  color: '#9A9EAD',
};

export default Resource;
