class TeamActions {
  constructor() {
    this.generateActions(
      'updateTeamStore',
      'postTeam',
      'createOrUpdateServer',
      'createSuccess',
      'updateSuccess',
      'list',
      'listError',
      'listSuccess',
      'fetch',
      'fetchSuccess',
      'error',
      'markDirty',
      'fillPaymentMethods',
      'teamTypeSelected',
      'updateTeamSelection',
      'clone',
      'cloneSuccess',
      'resetDobFields',
      'resetAgeFields'
    );
  }
}

export default alt.createActions(TeamActions);
