import { Map } from 'immutable';

import Session from 'shared/records/Session';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import { SessionDataStore } from 'dataStores';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { SessionSource } from 'sources';

import NotesActions from './actions';

class NotesStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      mounted: NotesActions.mounted,
      toggleEditMode: NotesActions.toggleEditMode,
      handleFieldChange: NotesActions.handleFieldChange,
      updateNote: NotesActions.updateNote,
      updateNoteSuccess: NotesActions.updateNoteSuccess,
      updateNoteError: NotesActions.updateNoteError,
      createClientNoteSuccess: NotesActions.createClientNoteSuccess,
      createClientNoteError: NotesActions.createClientNoteError,
      deleteSessionNote: NotesActions.deleteSessionNote,
      deleteSessionNoteSuccess: NotesActions.deleteSessionNoteSuccess,
      deleteSessionNoteError: NotesActions.deleteSessionNoteError,
    });
  }

  reset() {
    this.sessionId = null;
    this.session = new Session();
    this.dirty = false;

    this.notes = Map({
      admin_note: '',
      client_note: '',
    });

    this.editModes = Map({
      admin_note: false,
      client_note: false,
    });
  }

  setSessionNote() {
    this.waitFor(SessionDataStore);

    const { sessions } = SessionDataStore.getState();
    const session = sessions.get(this.sessionId, new Session());
    const adminNote = session.get('note');
    const clientNote = session.get('client_note')?.description;

    this.session = session;
    this.notes = this.notes
      .set('admin_note', adminNote)
      .set('client_note', clientNote);
  }

  mounted(sessionId) {
    this.reset();
    this.sessionId = sessionId;
    this.setSessionNote();
  }

  toggleEditMode(noteField) {
    this.editModes = this.editModes.set(
      noteField,
      !this.editModes.get(noteField)
    );
  }

  handleFieldChange({ field, value }) {
    this.dirty = true;
    this.notes = this.notes.set(field, value);
  }

  toggleNoteEditModes() {
    if (this.editModes.get('admin_note')) {
      this.toggleEditMode('admin_note');
    }
    if (this.editModes.get('client_note')) {
      this.toggleEditMode('client_note');
    }
  }

  updateNote() {
    this.waitFor(SessionDataStore);

    const { sessions } = SessionDataStore.getState();
    const session = sessions.get(this.sessionId, new Session());
    const params = {
      fields: ['note', 'client_note'],
      attributes: session.merge({
        note: this.notes.get('admin_note'),
      }),
    };
    const clientNote = this.notes.get('client_note');
    const adminNote = this.notes.get('admin_note');
    const prevClientNote = session.get('client_note')?.description;
    const prevAdminNote = session.get('note');

    if (adminNote && adminNote !== prevAdminNote) {
      SessionSource.put({
        id: this.sessionId,
        params,
        success: NotesActions.updateNoteSuccess,
        error: NotesActions.updateNoteError,
      });
    }

    if (clientNote && clientNote !== prevClientNote) {
      this.createClientNote();
    }
  }

  updateNoteSuccess() {
    this.toggleNoteEditModes();
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.note_added',
        filename: __filenamespace,
      })
    );
  }

  updateNoteError(...args) {
    this.toggleNoteEditModes();
    this.notifyError('error updating session note', args);
  }

  fetchSession({ successAction = () => null }) {
    SessionSource.fetch({
      id: this.sessionId,
      params: { fields: ['client_note', 'note'] },
      success: successAction,
    });
  }

  createClientNote() {
    const clientNote = this.notes.get('client_note');
    const prevClientNote = this.session.get('client_note')?.description;

    if (this.session.get('client_note') && clientNote !== prevClientNote) {
      uhApiClient.put({
        url: `/sessions/${this.sessionId}/notes`,
        data: JSON.stringify({
          attributes: {
            description: this.notes.get('client_note'),
          },
        }),
        success: NotesActions.createClientNoteSuccess,
        error: NotesActions.createClientNoteError,
      });
      return;
    }

    uhApiClient.post({
      url: `/sessions/${this.sessionId}/notes`,
      data: JSON.stringify({
        attributes: {
          description: this.notes.get('client_note'),
        },
      }),
      success: NotesActions.createClientNoteSuccess,
      error: NotesActions.createClientNoteError,
    });
  }

  createClientNoteSuccess() {
    this.toggleNoteEditModes();
    this.fetchSession({});

    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.note_added',
        filename: __filenamespace,
      })
    );
  }

  createClientNoteError(...args) {
    this.toggleNoteEditModes();
    this.notifyError('error creating client note', args);
  }

  deleteClientNote() {
    uhApiClient.delete({
      url: `/sessions/${this.sessionId}/notes`,
      success: NotesActions.deleteSessionNoteSuccess,
      error: NotesActions.deleteSessionNoteError,
    });
  }

  deleteAdminNote() {
    SessionSource.put({
      id: this.sessionId,
      params: {
        fields: ['note', 'client_note'],
        attributes: this.session.merge({
          note: this.notes.get('admin_note'),
        }),
      },
      success: NotesActions.deleteSessionNoteSuccess,
      error: NotesActions.deleteSessionNoteError,
    });
  }

  deleteSessionNote(noteType = 'admin') {
    if (noteType === 'admin') {
      this.notes = this.notes.set('admin_note', '');
      this.deleteAdminNote();
    } else {
      this.notes = this.notes.set('client_note', '');
      this.deleteClientNote();
    }
  }

  deleteSessionNoteSuccess() {
    this.fetchSession({});
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.note_deleted',
        filename: __filenamespace,
      })
    );
  }

  deleteSessionNoteError(...args) {
    this.notifyError('error deleting session note', args);
  }
}

export default alt.createStore(NotesStore, 'NotesStore');
