import { Record } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

class Payment extends Record({
  id: null,
  gatewayChargeType: '',
  gatewayChargeId: null,
  amount: null,
  fee: null,
  paidAt: null,
  paymentMethod: '',
  status: '',
  availableToRefund: null,
  failureReason: '',
  metadata: null,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({
      ...parsedObject,
    });
  }
}

export default Payment;
