import * as React from 'react';
import { Icon, Card } from '@upperhand/playmaker';

import CardReaderDropdownActions from 'containers/cardReaderDropdown/Actions';

function Refresh() {
  return (
    <Card
      classes={{
        root: 'device-info device-refresh',
        content: 'device-refresh__wrapper',
      }}
      onClick={CardReaderDropdownActions.refresh}
    >
      <Icon name="refresh" />
    </Card>
  );
}

export default React.memo(Refresh);
