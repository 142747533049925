export default class HttpError extends Error {
  constructor(message, status = 0, httpMessage = '') {
    super(message);
    this.name = 'HttpError';

    this.status = status;
    this.httpMessage = httpMessage;
    this.url = '';
    this.method = '';
    this.data = {};
  }

  /** Converts from standard error to custom HttpError if we have a response object */
  static fromError(error) {
    try {
      const { config, response } = error;

      if (error instanceof Error && response && config) {
        const httpError = new HttpError(
          '',
          response.status,
          response.data?.response?.message
        );

        httpError.response = response;
        httpError.url = config.url;
        try {
          // TODO: we probably want to remove when its Authentication data
          httpError.data = JSON.parse(config.data);
        } catch {
          // its ok if we cant parse, its probably a get request
        }

        httpError.method = config.method;

        // Copy the basic Error prototype info
        httpError.message = error.message;
        httpError.stack = error.stack;
        httpError.fileName = error.fileName;
        httpError.lineNumber = error.lineNumber;
        httpError.columnNumber = error.columnNumber;

        return httpError;
      }
    } catch {
      // if we cant parse for some reason ignore this and return basic error
    }

    return error;
  }
}
