import * as React from 'react';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import Customer from 'shared/records/Customer.jsx';
import CustomerTermsBanner from 'shared/components/_CustomerTermsBanner.jsx';
import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import TermsBanner from 'shared/components/_TermsBanner.jsx';
import TermsTemplate from 'shared/components/_TermsTemplate.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { compose } from 'shared/utils/SharedUtils.js';
import { convertDateToClientValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const isSystemAdmin = customerUser =>
  !customerUser && currentUser().role === 'Admin';

const showDialogFor = (customerUser, upperHandTermsLastUpdatedAt) => {
  if (isSystemAdmin(customerUser)) return false;
  return (
    !!customerUser.id &&
    (customerUser.needsToAcceptCustomerTerms(
      new Customer(currentCustomer()).policy_updated_at
    ) ||
      customerUser.needsToAcceptUpperHandTerms(upperHandTermsLastUpdatedAt))
  );
};

const showCustomerPolicy = customerUser => {
  if (isSystemAdmin(customerUser)) return false;

  if (customerUser && customerUser.isStaff()) return false;

  return (
    currentCustomer().policy_and_terms &&
    customerUser.needsToAcceptCustomerTerms(
      new Customer(currentCustomer()).policy_updated_at
    )
  );
};

function TermsDialog({
  intl,
  currentContextStore: { customerUser },
  currentContextActions: { acceptTerms },
}) {
  return (
    <Confirmation
      hasCheckbox={false}
      classes={{
        root: 'terms-modal-dialog',
        title: 'dialog-title',
        actions: clsx('modal-actions', 'hide-cancel-btn'),
      }}
      title={
        showCustomerPolicy(customerUser) ? (
          <CustomerTermsBanner />
        ) : (
          <TermsBanner name={t('theme_vendor', intl)} />
        )
      }
      open={showDialogFor(
        customerUser,
        convertDateToClientValue(window.upperHandTermsLastUpdatedAt)
      )}
      confirmButtonLabel={t('actions.accept', intl)}
      onConfirm={() => acceptTerms(customerUser.id)}
      onCancel={() => null}
    >
      {showCustomerPolicy(customerUser) ? (
        <>
          <TermsTemplate terms={currentCustomer().policy_and_terms} />
          <Actions />
        </>
      ) : (
        <UhTerms customerUser={customerUser} />
      )}
    </Confirmation>
  );
}

function UhTerms() {
  return (
    <FlexBoxCenter
      style={{ flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <a
        href={window.upperHandTermsUrl}
        target="blank"
        style={{ color: uhColors.activeBlue }}
      >
        <FormattedMessage
          id={messageId('.view_policy_and_terms', __filenamespace)}
        />
      </a>
      <a
        href={window.upperHandPrivacyUrl}
        target="blank"
        style={{ color: uhColors.activeBlue }}
      >
        <FormattedMessage
          id={messageId('.view_privacy_and_cookies', __filenamespace)}
        />
      </a>

      <div style={{ padding: '2em' }}>
        <FormattedMessage
          id={messageId('.accept', __filenamespace)}
          values={{ customerName: 'theme_vendor' }}
        />
      </div>
    </FlexBoxCenter>
  );
}

function Actions() {
  return (
    <FlexBoxJustify
      style={{
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: '0 20px',
          textAlign: 'center',
          color: uhColors.tabGrey,
        }}
      >
        <FormattedMessage
          id={messageId('.accept', __filenamespace)}
          values={{ customerName: currentCustomer().name }}
        />
        &nbsp;
        <a
          href={window.upperHandTermsUrl}
          target="blank"
          style={{ color: uhColors.activeBlue }}
        >
          <FormattedMessage id={messageId('.view_terms', __filenamespace)} />
        </a>
        &nbsp;
        <FormattedMessage id={messageId('.and', __filenamespace)} />
        &nbsp;
        <a
          href={window.upperHandPrivacyUrl}
          target="blank"
          style={{ color: uhColors.activeBlue }}
        >
          <FormattedMessage
            id={messageId('.view_privacy_and_cookies', __filenamespace)}
          />
        </a>
      </div>
    </FlexBoxJustify>
  );
}

export default compose(
  altContainer({
    stores: {
      currentContextStore: CurrentContextStore,
    },
    actions: {
      currentContextActions: CurrentContextActions,
    },
  }),
  injectIntl
)(TermsDialog);
