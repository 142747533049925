import * as React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Form from 'onboarding/components/AccountSetupPaysafe/forms/_Form.jsx';
import { Step } from 'onboarding/components/AccountSetupPaysafe/steps/_Helpers.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { customerScopedRoute } from 'shared/utils/RouteUtils';

function CompleteStep({ intl }) {
  return (
    <Step>
      <Form>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '15px' }}>
            {t('.complete', intl, __filenamespace)}
          </div>
          <div>{t('.bank_account_validation', intl, __filenamespace)}</div>
        </div>
      </Form>
      <Link to={customerScopedRoute('/dashboard')}>
        <Button variant="contained">
          {t('.open_dashboard', intl, __filenamespace)}
        </Button>
      </Link>
    </Step>
  );
}

export default injectIntl(CompleteStep);
