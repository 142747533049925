import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';

import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils';
import uhTheme from '_uh_theme.jsx';
import { Email } from 'user_management/shared/components/UserProfileFields.jsx';
import {
  FlexBox,
  FlexBoxColumnLayout,
  FlexBoxCenter,
} from 'shared/components/FlexBox.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import ForgotPasswordActions from '../Actions';
import ForgotPasswordStore from '../Store';

function MobileResetRequest({
  forgotPasswordStore: { email, errors, requestStatus },
  intl,
  modalOpen,
}) {
  if (requestStatus !== 'success') {
    return (
      <FlexBoxCenter style={{ width: '100%', flexDirection: 'column' }}>
        <FlexBoxCenter style={{ flex: '1 1 auto', flexDirection: 'column' }}>
          <div
            style={{ paddingBottom: '4em', paddingTop: '4em', width: '300px' }}
          >
            {modalOpen && (
              <div style={{ marginBottom: '46px' }}>
                <FormattedMessage
                  style={{ fontSize: '12px' }}
                  id={messageId('.forgot_password_info', __filenamespace)}
                />
              </div>
            )}
            <Email
              style={{ width: 256, marginBottom: '1em' }}
              userProfile={{ email }}
              onChange={handleChange({ actions: ForgotPasswordActions })}
              errorText={errors.getErrors('email', intl)}
            />
          </div>
        </FlexBoxCenter>
        <FlexBoxCenter>
          {modalOpen ? (
            <Button
              sx={{
                marginTop: '140px',
                backgroundColor: uhTheme.palette.dark.main,
                color: uhTheme.palette.dark.contrastText,
                width: '130px',
              }}
              onClick={ForgotPasswordActions.clickButton}
            >
              {t('.back_to_login', intl, __filenamespace)}
            </Button>
          ) : (
            <Link to="/accounts/login">
              <Button
                style={{
                  color: grey[400],
                  alignSelf: 'flex-start',
                  fontWeight: 600,
                  padding: 0,
                }}
              >
                {t('.back_to_login', intl, __filenamespace)}
              </Button>
            </Link>
          )}
        </FlexBoxCenter>
        <FlexBoxCenter
          style={{
            width: '100%',
            flex: ' 0 1 80px',
          }}
        >
          <StateChangingButton
            color="dark"
            label="Reset"
            labelInProgress="Sending request..."
            inProgress={requestStatus === 'sending'}
            disabled={
              requestStatus === 'sending' || !email || errors.hasErrors()
            }
            onClick={ForgotPasswordActions.sendResetRequest}
            style={{
              width: '100%',
              height: 80,
              color: 'white',
              fontSize: '22px',
              borderRadius: '0',
            }}
            type="submit"
          />
        </FlexBoxCenter>
      </FlexBoxCenter>
    );
  }

  return (
    <FlexBoxColumnLayout
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <div
        style={{
          fontSize: 26,
          color: uhColors.darkGrey,
          paddingBottom: 46,
        }}
      >
        Email sent!
      </div>
      <div
        style={{
          paddingBottom: 30,
          width: 300,
          minWidth: 300,
          lineHeight: '150%',
        }}
      >
        We sent an email to <span style={boldText}>{email}</span> so you can
        reset your password.
      </div>
      <FlexBox style={{ alignItems: 'baseline', paddingBottom: '4em' }}>
        <span>Not your email address?</span>
        <Link
          style={{ textDecoration: 'none' }}
          to="/accounts/forgot_password"
          onClick={ForgotPasswordActions.tryAgain}
        >
          <Button style={{ color: uhColors.lightBlue, fontWeight: 600 }}>
            Try again
          </Button>
        </Link>
      </FlexBox>
      <Link
        to="/"
        style={{
          width: '100%',
          margin: 0,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          style={{
            width: '100%',
            height: 80,
            fontSize: '22px',
          }}
        >
          {t('.login_link', intl, __filenamespace)}
        </Button>
      </Link>
    </FlexBoxColumnLayout>
  );
}

export default altContainer({
  stores: {
    forgotPasswordStore: ForgotPasswordStore,
  },
})(MobileResetRequest);
