import * as React from 'react';
import { getAllUrlParameters } from 'shared/utils/UrlParameters.js';

import altContainer from 'shared/hocs/altContainer.jsx';

import ErrorDialogWithMessageWindow from 'shared/components/ErrorDialogWithMessageWindow.jsx';
import MailToBtn from 'components/MailToBtn.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import SignupResults from '../signupResults/SignupResults.jsx';

import VerifyActions from './Actions';
import VerifyStore from './Store';

function Verify({ verifyStore: { hasVerifyError, isLoading } }) {
  // Init store
  React.useEffect(() => {
    // key is a reserved word so we cant use "page component" to get it.
    const { key } = getAllUrlParameters().toJS();
    VerifyActions.mounted(key);
  }, []);

  if (hasVerifyError) {
    const errorDescription = (
      <>
        <p style={{ margin: '14px 0px' }}>
          You may be missing some characters from pasting the link. Please check
          the link from your email and try again.
        </p>
        <p style={{ margin: '14px 0px' }}>
          If you are sure that you are following the correct link, please
          contact us.
        </p>
      </>
    );

    return (
      <SignupResults
        headerText="Invalid or missing key."
        description={errorDescription}
        callToAction={
          <MailToBtn
            btnLabel="Contact support"
            emailSubject="My account verification link did not work"
            mailTo="support@getupperhand.com"
          />
        }
      />
    );
  }

  return (
    <SpinWhileLoading
      contentContainerStyle={{ height: '100%' }}
      outerContainerStyle={{ height: '100%' }}
      isLoading={isLoading}
    >
      <ErrorDialogWithMessageWindow />
    </SpinWhileLoading>
  );
}

export default altContainer({
  stores: {
    verifyStore: VerifyStore,
  },
})(Verify);
