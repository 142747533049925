import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'shared/utils/SharedUtils.js';
import { Chip, Grid, Typography } from '@upperhand/playmaker';

import { COUPON_DISCOUNTABLE } from 'records/DiscountableItem';
import CreditPassIcon from 'shared/components/icons/CreditPass.jsx';
import EventIcon from 'shared/components/icons/Event.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';
import RetailIcon from 'shared/components/icons/Retail.jsx';
import TeamIcon from 'shared/components/icons/Teams.jsx';

import {
  CreditPassDataStore,
  EventDataStore,
  MembershipDataStore,
  VariantDataStore,
} from 'dataStores';
import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';

const iconStyle = {
  width: 16,
  height: 16,
};
// TODO: ignore this, we will use Playmaker icon when move custom icons into playmaker
const icons = {
  [COUPON_DISCOUNTABLE.CreditPass]: (
    <CreditPassIcon style={iconStyle} secondaryColor="var(--color-divide)" />
  ),
  [COUPON_DISCOUNTABLE.Event]: <EventIcon style={iconStyle} />,
  [COUPON_DISCOUNTABLE.Membership]: <MembershipIcon style={iconStyle} />,
  [COUPON_DISCOUNTABLE.Variant]: <RetailIcon style={iconStyle} />,
  [COUPON_DISCOUNTABLE.Team]: <TeamIcon style={iconStyle} />,
};

const DiscountableItems = injectIntl(
  ({
    actions,
    creditPassDataStore: { creditPasses },
    eventDataStore: { events },
    coupon,
    membershipDataStore: { memberships },
    removableItems,
    variantDataStore: { variants },
    intl,
  }) => {
    const eventsData = events.filter(
      e => e.schedule_type !== SCHEDULE_TYPE.teamSchedule
    );
    const teamsData = events.filter(
      e => e.schedule_type === SCHEDULE_TYPE.teamSchedule
    );

    const dataStores = {
      [COUPON_DISCOUNTABLE.CreditPass]: creditPasses,
      [COUPON_DISCOUNTABLE.Event]: eventsData,
      [COUPON_DISCOUNTABLE.Team]: teamsData,
      [COUPON_DISCOUNTABLE.Membership]: memberships,
      [COUPON_DISCOUNTABLE.Variant]: variants,
    };
    return (
      <Grid container spacing={1} xs={12} className="coupon__chips">
        {coupon.get('all_items') ? (
          <Typography variant="body3" className="coupon__chip-item">
            {t('.all_items', intl, __filenamespace)}
          </Typography>
        ) : (
          <>
            {coupon.get('discountables').map(discountableType => (
              <Chip
                key={discountableType}
                icon={icons[discountableType]}
                label={t(`.all_${discountableType}`, intl, __filenamespace)}
                onDelete={
                  removableItems
                    ? () =>
                        actions.discountableTypeChanged(discountableType, false)
                    : null
                }
              />
            ))}
            {coupon.get('discountable_items').map(di => {
              const item = dataStores[di.item_type].find(
                i => i[di.isCreditPass() ? 'id' : 'uuid'] === di.item_id
              );
              const label = item?.get(
                `${di.isEvent() || di.isTeam() ? 'title' : 'name'}`
              );
              const variantLabel = item?.get('variant', '');
              const subLabel = variantLabel ? `(${variantLabel})` : '';

              return (
                <Chip
                  key={di.item_id}
                  icon={icons[di.item_type]}
                  label={di.isVariant() ? `${label} ${subLabel}` : label}
                  onDelete={
                    removableItems
                      ? () =>
                          actions.removeDiscountableItem(
                            di.item_type,
                            di.item_id
                          )
                      : null
                  }
                />
              );
            })}
          </>
        )}
      </Grid>
    );
  }
);

DiscountableItems.propTypes = {
  coupon: PropTypes.object.isRequired,
  removableItems: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

DiscountableItems.defaultProps = {
  removableItems: false,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      creditPassDataStore: CreditPassDataStore,
      eventDataStore: EventDataStore,
      membershipDataStore: MembershipDataStore,
      variantDataStore: VariantDataStore,
    },
  })
)(DiscountableItems);
