import * as React from 'react';
import { FormattedNumber } from 'react-intl';

function FormattedCurrency({
  intl,
  value,
  fromCents,
  precision = 2,
  showSymbol = true,
}) {
  let formattedValue = value;
  if (!formattedValue) {
    formattedValue = 0.0;
  }

  if (!formattedValue.toFixed) {
    formattedValue = parseFloat(formattedValue, 10);
  }

  if (fromCents) {
    formattedValue /= 100;
  }

  formattedValue = formattedValue.toFixed(precision);

  if (showSymbol) {
    if (intl) {
      return intl.formatNumber(formattedValue, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      });
    }

    return (
      <FormattedNumber
        value={formattedValue}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="USD"
        maximumFractionDigits={precision}
        minimumFractionDigits={precision}
      />
    );
  }

  if (intl) {
    return intl.formatNumber(formattedValue, {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    });
  }

  return (
    <FormattedNumber
      value={formattedValue}
      maximumFractionDigits={precision}
      minimumFractionDigits={precision}
    />
  );
}

export default FormattedCurrency;
