/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { fromJS } from 'immutable';
import CustomerTeam from 'team_mgmt/shared/records/CustomerTeam.jsx';
import TeamType from 'records/TeamType.jsx';
import DateOfBirth from 'records/DateOfBirth.jsx';

class TeamTranslator {
  constructor(obj) {
    this.customerTeam = new CustomerTeam(fromJS(obj));
  }

  toClient() {
    this.customerTeam = this.customerTeam.withMutations(customerTeam => {
      this.translateTeamTypeToClient(customerTeam);
      this.translatePriceToClient(customerTeam);
      this.translateDescriptionToClient(customerTeam);
      this.translateDobToClient(customerTeam);
    });
    return this.customerTeam;
  }

  toServer() {
    this.customerTeam = this.translatePriceToServer(this.customerTeam);
    this.customerTeam = this.translateDescriptionToServer(this.customerTeam);
    this.customerTeam = this.translateTeamTypeToServer(this.customerTeam);

    return this.customerTeam.toServer();
  }

  translateDescriptionToClient(customerTeam) {
    customerTeam.set('description', customerTeam.getRichDescription());
  }

  translateDescriptionToServer(customerTeam) {
    return customerTeam.set('description', customerTeam.getHtmlDescription());
  }

  translatePriceToClient(customerTeam) {
    const price = parseInt(customerTeam.get('price'), 10) / 100;
    const basePrice = parseInt(customerTeam.get('base_price'), 10) / 100;
    return customerTeam
      .set('price', Number.isNaN(price) ? '' : price)
      .set('freeEvent', basePrice === 0)
      .set('base_price', Number.isNaN(basePrice) ? '' : basePrice);
  }

  translateTeamTypeToClient(customerTeam) {
    return customerTeam
      .set('team_type', new TeamType(customerTeam.get('team_type')))
      .set('team_type_id', customerTeam.getIn(['team_type', 'id']));
  }

  translateTeamTypeToServer(customerTeam) {
    return customerTeam.set(
      'team_type_id',
      customerTeam.getIn(['team_type', 'id'])
    );
  }

  translateDobToClient(customerTeam) {
    return customerTeam.setIn(
      ['team_detail', 'date_of_birth'],
      new DateOfBirth(customerTeam.team_detail.date_of_birth)
    );
  }

  translatePriceToServer(customerTeam) {
    const floatPrice = parseFloat(customerTeam.get('base_price'));
    const intPrice = parseInt((floatPrice * 100).toFixed(0), 10);

    return customerTeam.set(
      'base_price',
      Number.isNaN(intPrice) ? '' : intPrice
    );
  }
}

export default TeamTranslator;
