import React, { memo } from 'react';
import { List } from 'immutable';
import { useIntl } from 'react-intl';
import { Typography, Grid } from '@upperhand/playmaker';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import DiscountCard from 'memberships/components/DiscountsSelector/DiscountCard.jsx';

import { t } from 'shared/utils/LocaleUtils';

import Membership from 'shared/records/Membership.jsx';
import MembershipTier from 'shared/records/MembershipTier';

import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';

import './styles.scss';

function DiscountsSelector({
  actions,
  membership,
  eventTypes,
  creditPasses,
  retailCategories,
}) {
  const intl = useIntl();

  if (!membership) return null;

  const { membership_all_discounts: discounts } = membership;

  return (
    <Grid container spacing={1} className="membership-discounts">
      <Grid item xs={12} className="membership-discounts__title-container">
        <Typography
          variant="fieldLabel"
          className="membership-discounts__title"
        >
          {t('.discounts', intl, __filenamespace)}
        </Typography>
        <Typography variant="body2">
          {t('.discounts_description', intl, __filenamespace)}
        </Typography>
      </Grid>
      <Grid item container xs={12} className="membership-discounts__list">
        {discounts.size > 0 && (
          <>
            {discounts.map((discount, index) => (
              <DiscountCard
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                actions={actions}
                membership={membership}
                discount={discount}
                eventTypes={eventTypes}
                creditPasses={creditPasses}
                retailCategories={retailCategories}
              />
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={() => actions.eventDiscountAdded(true)}
            >
              {t('.add_discount', intl, __filenamespace)}
            </Button>
          </>
        )}
        {discounts.size === 0 && (
          <Button
            fullWidth
            startIcon={<AddIcon />}
            className="membership-discounts__add-discount"
            color="default"
            variant="contained"
            onClick={() => actions.eventDiscountAdded(true)}
          >
            {t('.create_discount', intl, __filenamespace)}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

DiscountsSelector.propTypes = {
  actions: PropTypes.shape({}),
  eventTypes: PropTypes.instanceOf(List),
  creditPasses: PropTypes.instanceOf(List),
  retailCategories: PropTypes.instanceOf(List),
  membership: PropTypes.oneOfType([
    PropTypes.instanceOf(Membership),
    PropTypes.instanceOf(MembershipTier),
  ]),
};

DiscountsSelector.defaultProps = {
  actions: MembershipEditingActions,
  eventTypes: List(),
  creditPasses: List(),
  retailCategories: List(),
  membership: new Membership(),
};

export default memo(DiscountsSelector);
