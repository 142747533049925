import React from 'react';
import moment from 'moment-timezone';

import { CurrencyTextField } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

function RefundAmount({
  payment,
  paymentId,
  refundAmount,
  onChange,
  maxValueToRefund,
  errors,
  intl,
  mode,
  disabled,
}) {
  return (
    <div className="refund-amount">
      {payment && (
        <div className="refund-amount__payment">
          <div className="refund-amount__payment-method">
            {t(
              `.payment_method_${payment.get('paymentMethod')}`,
              intl,
              __filenamespace
            )}
            {payment.get('metadata').last4 !== undefined &&
              ` • ${payment.get('metadata').last4}`}
          </div>
          <div className="refund-amount__payment-date">
            {moment(payment.get('paidAt')).format('MM/DD/YYYY • h:mma')}
          </div>
        </div>
      )}
      <div className="refund-amount__amount">
        <CurrencyTextField
          fullWidth
          label={t(`.${mode}`, intl, __filenamespace)}
          name="refund"
          value={refundAmount / 100}
          disabled={disabled || maxValueToRefund <= 0}
          onChange={onChange}
          errorText={errors.getErrors(paymentId, intl)}
        />
        {Boolean(maxValueToRefund) && (
          <CurrencyTextField
            fullWidth
            label={t(`.out_of`, intl, __filenamespace)}
            name="max"
            value={maxValueToRefund / 100}
            disabled
            onChange={() => null}
            errorText=""
          />
        )}
      </div>
    </div>
  );
}

export default RefundAmount;
