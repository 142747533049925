import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Clock({ style, color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1088.000000, -18.000000)"
          fill={(style && style.color) || color}
        >
          <g>
            <g transform="translate(1088.000000, 18.000000)">
              <path
                d="M12,21 C7.03725,21 3,16.96275 3,12 C3,7.03725 7.03725,3 12,3 C16.96275,3 21,
                 7.03725 21,12 C21,16.96275 16.96275,21 12,21 M12,0 C5.373,0 0,5.373 0,
                 12 C0,18.62775 5.373,24 12,24 C18.62775,24 24,18.62775 24,12 C24,5.373 18.62775,
                 0 12,0"
              />
              <path
                d="M15.015,12 L12.75,12 C12.336,12 12,11.664 12,11.25 L12,8.985 C12,8.16525 11.3355,
                 7.5 10.515,7.5 L10.485,7.5 C9.66525,7.5 9,8.16525 9,8.985 L9,13.515 C9,
                 14.33475 9.66525,15 10.485,15 L10.5,15 L12,15 L15.015,15 C15.8355,
                 15 16.5,14.33475 16.5,13.515 L16.5,13.485 C16.5,12.66525 15.8355,12 15.015,12"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Clock.defaultProps = {
  color: '#9A9EAD',
};

export default Clock;
