import { List } from 'immutable';
import BackendAPI from 'shared/services/BackendAPI';
import ClientActions from 'shared/actions/ClientActions.jsx';
import ClientStore from 'shared/stores/ClientStore.jsx';
import ContactImportActions from 'contacts/index/actions/ContactImportActions.js';
import ContactImportNativeActions from 'contacts/index/actions/ContactImportNativeActions.js';
import Contacts from 'shared/native/Contacts';
import CordovaLifeCycleActions from 'shared/actions/CordovaLifeCycleActions';
import { byFields } from 'shared/utils/ImmutableUtils.js';
import NativeContactMapper from 'contacts/index/helpers/NativeContactMapper.js';
import PermissionDeniedActions from 'shared/actions/PermissionDeniedActions.js';
import { waitUntil } from 'shared/utils/Promise.js';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

export const STEP_LOADING = 'loading';
export const STEP_SELECT = 'select';
export const STEP_REVIEW = 'review';
export const STEP_PROCESSING = 'processing';
export const STEP_SUCCESS = 'success';
export const STEP_ERROR = 'error';

// Indicates how many additional contacts to display when tapping "show more".
// This is also the default number of contacts initially displayed.
const CONTACT_DISPLAY_INCREMENT = 25;

/**
 * Manages the Native Contact Import Drawer
 */
class ContactImportNativeStore extends UpperHandStore {
  constructor(injector = {}) {
    super();

    this.feedback = injector.feedback || {
      openPermissionDenied: message => PermissionDeniedActions.open(message),
    };
    this.invite = injector.invite || {
      send: async nativeContact => {
        const payload = await nativeContact.toServer();
        const clientData = await BackendAPI.post('customer_users', payload);

        // Update store
        if (payload.type === 'staff_member') {
          StaffActions.createSuccess(clientData);
        } else {
          ClientActions.createSuccess(clientData);
        }
      },
    };
    this.nativeContactManager = injector.nativeContactManager || Contacts;
    this.nativeContactMapper =
      injector.nativeContactMapper || new NativeContactMapper();
    this.uhContactManager = injector.uhContactManager || {
      allContacts: async () => {
        const loaded = () =>
          !ClientStore.getState().isLoading && !StaffStore.getState().isLoading;
        await waitUntil(loaded);

        return List()
          .concat(ClientStore.getState().allClients)
          .concat(StaffStore.getState().allStaff);
      },
    };

    this.bindListeners = this.bindListeners || (() => {});
    this.bindListeners({
      openDrawer: ContactImportActions.DRAWER_OPENED,
      closeDrawer: ContactImportActions.DRAWER_CLOSED,
      reloadData: CordovaLifeCycleActions.RESUME,
      filterStringUpdated: ContactImportNativeActions.FILTER_STRING_UPDATED,
      toggleContactSelected: ContactImportNativeActions.TOGGLE_CONTACT_SELECTED,
      toggleIneligible: ContactImportNativeActions.TOGGLE_INELIGIBLE,
      toggleType: ContactImportNativeActions.TOGGLE_TYPE,
      selectContacts: ContactImportNativeActions.SELECT,
      showMore: ContactImportNativeActions.SHOW_MORE,
      backToSelect: ContactImportNativeActions.BACK_TO_SELECT,
      inviteContacts: ContactImportNativeActions.INVITE,
    });
    this.reset();
  }

  reset() {
    this.step = STEP_LOADING;
    this.drawerTitle = 'Loading';
    this.onBack = null;
    this.drawerOpen = false;
    this.filterString = '';
    this.allContacts = this.garbageCollectList(this.allContacts);
    this.filteredContacts = this.garbageCollectList(this.filteredContacts);
    this.selectedContacts = this.garbageCollectList(this.selectedContacts);
    this.hasMoreToShow = false;
    this.resetDisplayLimit();
    this.hideIneligible = true;
    this.error = null;
  }

  resetDisplayLimit() {
    this.displayLimit = CONTACT_DISPLAY_INCREMENT;
  }
  // eslint-disable-next-line
  garbageCollectList(list) {
    if (!list) {
      return List();
    }
    list.forEach(c => c.garbageCollect());
    return List();
  }

  async openDrawer() {
    this.reset();
    this.updateStep(STEP_LOADING, 'Loading');
    this.drawerOpen = true;
    this.emitChange();
    try {
      const canLoadContacts = await this.nativeContactManager.isAvailable();
      if (!canLoadContacts) {
        this.setError('Cannot load contacts.');
        this.emitChange();
        return;
      }

      await this.reloadData();
    } catch (error) {
      this.setError(error);
    }
    this.emitChange();
  }

  closeDrawer() {
    this.reset();
  }

  async reloadData() {
    if (!this.drawerOpen) {
      return;
    }

    // Only reload if loading (See Android quirk) or selecting contacts
    // Android: The first time on Android, after permission check, reload will fire
    if (![STEP_LOADING, STEP_SELECT].includes(this.step)) {
      return;
    }

    this.allContacts = this.garbageCollectList(this.allContacts);
    this.allContacts = await this.fetchAllContacts();
    this.selectedContacts = List(); // Reset selection since we garbage collected
    this.updateFilteredContacts();

    this.updateStep(STEP_SELECT, 'Select Contacts');
    this.emitChange();
  }

  filterStringUpdated(newString) {
    this.filterString = newString;
    this.resetDisplayLimit();
    this.updateFilteredContacts();
  }

  toggleContactSelected(contact) {
    if (!contact.canImport()) {
      return;
    }

    // Update selection
    const selectedIndex = this.selectedContacts.findIndex(
      c => c.id === contact.id
    );
    const selected = selectedIndex === -1;
    const updatedContact = contact.set('selected', selected);

    if (selected) {
      this.selectedContacts = this.selectedContacts.push(updatedContact);
    } else {
      this.selectedContacts = this.selectedContacts.remove(selectedIndex);
    }

    this.allContacts = this.setContact(this.allContacts, updatedContact);
    this.filteredContacts = this.setContact(
      this.filteredContacts,
      updatedContact
    );
  }

  toggleIneligible() {
    this.hideIneligible = !this.hideIneligible;
    this.resetDisplayLimit();
    this.updateFilteredContacts();
  }

  toggleType(contact) {
    const newType = contact.isStaff() ? 'client' : 'staff_member';
    const updatedContact = contact.set('type', newType);

    this.selectedContacts = this.setContact(
      this.selectedContacts,
      updatedContact
    );
    this.allContacts = this.setContact(this.allContacts, updatedContact);
    this.filteredContacts = this.setContact(
      this.filteredContacts,
      updatedContact
    );
  }

  selectContacts() {
    this.updateStep(STEP_REVIEW, 'Review Contacts', () =>
      ContactImportNativeActions.backToSelect()
    );
  }

  showMore() {
    this.displayLimit += CONTACT_DISPLAY_INCREMENT;
    this.updateFilteredContacts();
  }

  backToSelect() {
    this.updateStep(STEP_SELECT, 'Select Contacts');
  }

  async inviteContacts() {
    this.updateStep(STEP_PROCESSING, 'Inviting');
    this.emitChange();

    try {
      const invitePromises = this.selectedContacts.map(this.invite.send);
      await Promise.all(invitePromises);
      this.updateStep(STEP_SUCCESS, 'Success');
    } catch (error) {
      this.notifyError('Error while sending invitations', error);
      this.setError('Sending failed');
    }
    this.emitChange();
  }

  updateStep(step, title = '', onBack = null) {
    this.step = step;
    this.drawerTitle = title;
    this.onBack = onBack;
  }

  setError(error) {
    this.updateStep(STEP_ERROR);
    const permissionDenied = error === undefined;
    if (permissionDenied) {
      this.feedback.openPermissionDenied(
        'shared.Permission.contacts_instructions'
      );
      ContactImportActions.drawerClosed();
      this.error = 'Permission Denied';
    } else {
      this.error = error;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setContact(list, newContact) {
    const index = list.findIndex(c => c.id === newContact.id);
    return list.set(index, newContact);
  }

  async fetchAllContacts() {
    const loadAllContacts = async () => {
      const deviceContacts = await this.nativeContactManager.allContacts();
      const toNativePromises = deviceContacts.map(c =>
        this.nativeContactMapper.convert(c)
      );
      const nativeContacts = await Promise.all(toNativePromises);
      return List(nativeContacts);
    };

    const fullNameExists = c => c.fullName() !== '';

    const fullName = c => c.fullName();

    const allUHContacts = await this.uhContactManager.allContacts();
    const setAlreadyExists = c => {
      const alreadyExists =
        allUHContacts.find(c2 => c2.email === c.email) !== undefined && c.email;
      return c.set('alreadyExists', alreadyExists);
    };

    return (await loadAllContacts())
      .filter(fullNameExists)
      .sortBy(fullName)
      .map(setAlreadyExists);
  }

  updateFilteredContacts() {
    const canImport = c => c.canImport() || !this.hideIneligible;

    const containsSearchText = c =>
      byFields(this.filterString, ['first_name', 'last_name', 'email'])(c);

    const take = limit => (_, index) => index < limit;

    const allMatching = this.allContacts
      .filter(canImport)
      .filter(containsSearchText);

    this.filteredContacts = allMatching.filter(take(this.displayLimit));

    const willDisplayCount = this.filteredContacts.size;
    this.hasMoreToShow =
      willDisplayCount !== 0 && willDisplayCount !== allMatching.size;
  }
}

export default alt.createStore(
  ContactImportNativeStore,
  'ContactImportNativeStore'
);
