import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import InfoIcon from '@mui/icons-material/Info';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import PriceFields from 'event_mgmt/shared/components/_PricingFields.jsx';
import SingleSessionPricingTiers from 'event_mgmt/editing/components/_SingleSessionPricingTiers.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

function SingleSessionPurchaseSection({
  customerEvent,
  handleInputChange,
  isFree,
  feePlan,
  fieldErrors,
  withConfirmation,
  intl,
}) {
  const singleSessionPurchasable =
    customerEvent.isMultiDayEvent() && customerEvent.hasValidDaytime();
  const allowsSSP = customerEvent.get('allow_single_session_purchase');
  const scheduleChecked = customerEvent.hasPriceScheduleChecked();

  const onCheck = e => {
    const { checked } = e.target;
    // Event not saved yet or checkbox was checked
    if (!withConfirmation || checked) {
      handleInputChange(e, checked);
      return;
    }
    EventActions.toggleSSPConfirmation(true);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <div>
        <ListItem style={{ padding: 0 }}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <div>
                <FormattedMessage
                  id={messageId('.checkbox', __filenamespace)}
                />
                <InfoIcon
                  data-tip
                  data-for="ssp-help"
                  style={{
                    height: 20,
                    width: 20,
                    position: 'absolute',
                    right: 0,
                    zIndex: 999,
                  }}
                />
                <ReactTooltip
                  id="ssp-help"
                  effect="solid"
                  style={{ maxWidth: 240 }}
                  className="charge-failure-tooltip uh-tooltip"
                >
                  <FormattedMessage
                    id={messageId('.help_text', __filenamespace)}
                  />
                </ReactTooltip>
              </div>
            }
            name="allow_single_session_purchase"
            disabled={!singleSessionPurchasable || scheduleChecked}
            checked={singleSessionPurchasable && allowsSSP}
            onChange={onCheck}
          />
        </ListItem>
      </div>
      {allowsSSP && (
        <div style={{ marginTop: '10px' }}>
          <Paper style={{ padding: '15px', minWidth: 300 }}>
            <div style={{ fontSize: 16 }}>
              {t('.single_session_price_header', intl, __filenamespace)}
            </div>
            <PriceFields
              showFieldLabel={false}
              isFree={isFree}
              fieldName="single_session_price"
              value={customerEvent.get('single_session_price')}
              feePlan={feePlan}
              errorText={
                !isFree && fieldErrors.get('single_session_price', []).join(',')
              }
              handleInputChange={handleInputChange}
            />
            <SingleSessionPricingTiers
              singleSessionPrice={customerEvent.get('single_session_price')}
              pricingTiers={customerEvent.get('pricing_tiers')}
              handleInputChange={handleInputChange}
              handleSave={EventActions.createOrUpdateServer}
              onSave={EventActions.createOrUpdateServer}
            />
          </Paper>
        </div>
      )}
    </div>
  );
}

export default injectIntl(SingleSessionPurchaseSection);
