import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';

// eslint-disable-next-line import/prefer-default-export
export const downloadCsv = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'orders/cost_of_goods_csv',
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => data, success),
    error,
  });
};
