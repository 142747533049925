import PurchaseDrawerActions from 'event_mgmt/display/actions/PurchaseDrawerActions.js';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { shouldOpenPurchaseDrawer } from 'shared/utils/RouteUtils';

class PurchaseDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.open = shouldOpenPurchaseDrawer();

    this.bindListeners({
      openDrawer: PurchaseDrawerActions.DRAWER_OPENED,
      closeDrawer: [
        PurchaseDrawerActions.DRAWER_CLOSED,
        SchedulingDrawerActions.CLIENT_SELECTED,
      ],
    });
  }

  openDrawer() {
    this.open = true;
  }

  closeDrawer() {
    this.open = false;
  }
}

export default alt.createStore(PurchaseDrawerStore, 'PurchaseDrawerStore');
