import { List } from 'immutable';
import { Balance, BalanceDetail } from 'records';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';
import DataStoreActions from './BalancesActions';

const parserTo = { type: Balance, paginationKey: 'records' };

const parser = new Parser(parserTo);

// eslint-disable-next-line import/prefer-default-export
export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'ledger/balances',
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const details = ({ productId, compoundId, success, error }) => {
  // Need a custom Parser because we will be making BalanceDetail elements.
  const balanceDetailParser = new Parser({
    type: BalanceDetail,
  });

  uhApiClient.get({
    url: `ledger/balances/${productId}/details`,
    success: onSuccess(
      // Custom function to allow us to link collection to a certain productId.
      data => ({
        collection: balanceDetailParser.parseCollection(data),
        compoundId,
      }),
      List([DataStoreActions.detailsSuccess, success])
    ),
    error: onError(List([DataStoreActions.detailsError, error])),
  });
};

export const downloadCsv = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'ledger/balances_csv',
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

export const orderBalanceDetails = ({ productId, success, error }) => {
  const balanceDetailParser = new Parser({
    type: BalanceDetail,
  });

  uhApiClient.get({
    url: `ledger/balances/${productId}/details`,
    success: onSuccess(
      data => balanceDetailParser.parseCollection(data),
      success
    ),
    error,
  });
};
