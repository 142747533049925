import { Record } from 'immutable';

class ZebraPrinterSettings extends Record({
  name: '',
  sn: '',
  ip_address: '',
  widthLabel: 0,
  heightLabel: 0,
  widthReceipt: 0,
}) {
  updateSn(data) {
    return this.set('sn', data);
  }

  updateIP(data) {
    return this.set('ip_address', data);
  }

  toServer() {
    return this.toJS();
  }
}

export default ZebraPrinterSettings;
