import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateTimeField } from '@upperhand/playmaker';

import CheckboxExpander from 'memberships/components/CheckboxExpander.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

function PreSale({ membership, checked, onCheck, onDateChange, style, intl }) {
  return (
    <CheckboxExpander
      label={t('.allow_pre_sale', intl, __filenamespace)}
      checked={checked}
      onChange={onCheck}
      style={style}
    >
      <div className="membership__preSale">
        <FormattedMessage id={messageId('.set_active_date', __filenamespace)} />
        <DateTimeField
          classes={{
            root: 'membership__preSale-value',
          }}
          type="date"
          autoOk
          placeholder="MM/DD/YYYY"
          disablePast
          name="active_at"
          onChange={value =>
            onDateChange(value ? value.tz(customerTZ(), true) : value)
          }
          value={membership.active_at}
        />
      </div>
    </CheckboxExpander>
  );
}

export default injectIntl(PreSale);
