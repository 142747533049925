import { List } from 'immutable';
import Client from 'shared/records/Client.jsx';
import ClientActions from 'shared/actions/ClientActions.jsx';
import ClientStore from 'shared/stores/ClientStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import UserCreationActions from 'user_management/shared/actions/UserCreationActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const STEPS = List(['accountCreation', 'profileCreation']);

class UserCreationStore extends UpperHandStore {
  constructor() {
    super();
    this.newUser = new Client();
    this.currentProfile = new Client();
    this.managedProfiles = List();
    this.idCounter = -1;
    this.currentStep = STEPS.first();
    this.accountType = 'manager';

    this.bindListeners({
      updateNewUser: UserCreationActions.UPDATE_NEW_USER,

      nextStep: UserCreationActions.NEXT_STEP,
      prevStep: UserCreationActions.PREV_STEP,

      addProfile: UserCreationActions.ADD_PROFILE,
      removeProfile: UserCreationActions.REMOVE_PROFILE,

      listManagedProfiles: UserCreationActions.LIST_MANAGED_PROFILES,
      listManagedProfilesSuccess:
        UserCreationActions.LIST_MANAGED_PROFILES_SUCCESS,
      listManagedProfilesError: UserCreationActions.LIST_MANAGED_PROFILES_ERROR,
      handleAccountTypeChange: UserCreationActions.ACCOUNT_TYPE,
    });
  }

  updateNewUser([keypath, value]) {
    if (keypath.length) {
      this.newUser = this.newUser.setIn(keypath, value);
    } else {
      this.newUser = value;
    }
  }

  nextStep() {
    this.waitFor(ClientStore);
    if (ClientStore.getState().isValid) {
      ClientActions.clearClient.defer();
      this.currentStep = STEPS.last();
    }
  }

  prevStep() {
    this.waitFor(ClientStore);
    this.currentStep = STEPS.first();
  }

  addProfile() {
    this.waitFor(ClientStore);
    const clientStore = ClientStore.getState();

    if (clientStore.isValid) {
      const newProfile = clientStore.client;

      if (newProfile.id === null) {
        this.managedProfiles = this.managedProfiles.push(
          newProfile.set('id', this.idCounter)
        );
        this.idCounter -= 1;
      } else {
        const index = this.managedProfiles.findKey(p => p.id === newProfile.id);
        this.managedProfiles = this.managedProfiles.set(index, newProfile);
      }
      ClientActions.clearClient.defer();
    }
  }

  removeProfile(id) {
    // We don't let Clients delete managed profiles. Any profile with an id > 0
    // is one that already exists in the system. (Created in POS add client)
    if (id > 0) {
      return;
    }

    this.waitFor(ClientStore);
    const clientStore = ClientStore.getState();

    const index = this.managedProfiles.findKey(p => p.id === id);

    this.managedProfiles = this.managedProfiles.delete(index);

    if (clientStore.client && clientStore.client.id === id) {
      ClientActions.clearClient.defer();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  listManagedProfiles() {
    if (!currentUser().customer_user_id) {
      return;
    }

    uhApiClient.get({
      url: `users/${currentUser().id}/managed_users`,
      data: { per_page: 50 },
      success: UserCreationActions.listManagedProfilesSuccess,
      error: UserCreationActions.listManagedProfilesError,
    });
  }

  listManagedProfilesSuccess(data) {
    this.managedProfiles = List(data.managed_users.map(c => new Client(c)));
  }

  listManagedProfilesError(...args) {
    this.notifyError(
      `Error listing managed customer_users for Client ${
        currentUser().customer_user_id
      }`,
      args
    );
  }

  handleAccountTypeChange(value) {
    if (value === 'manager' || value === 'participant') {
      this.accountType = value;
    }
  }
}

export default alt.createStore(UserCreationStore, 'UserCreationStore');
