import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash.debounce';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Dropdown,
  TextField,
  RadioButton,
  Checkbox,
  Grid,
  Typography,
} from '@upperhand/playmaker';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

import CollapsedBenefitSelector from 'memberships/components/CollapsedBenefitSelector.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventAutoComplete from 'shared/components/_EventAutoComplete.jsx';
import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';
import EventTypeEditor from 'components/EventTypeEditor';
import SelectedEventOrType from 'shared/components/SelectedEventOrType.jsx';
import SpecificEventsActions from 'event_mgmt/index/actions/SpecificEventsActions.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import {
  MEMBERSHIP_CREDIT_TYPES,
  FREQUENCY_TYPES,
} from 'shared/records/MembershipEventCredit.jsx';

const styles = {
  creditRolloverField: {
    marginBottom: '14px',
    padding: '8px 0px',
  },
  header: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  hintText: {
    fontSize: 13,
    marginBottom: 12,
    marginTop: 12,
    color: '#7F7F7F',
    lineHeight: '18px',
  },
  root: {
    marginBottom: 25,
  },
  labelWithTooltip: {
    maxWidth: 'max-content',
  },
};

function UnlimitedSelector({
  actions,
  eventCredit,
  isUnlimitedDisabled,
  index,
  intl,
}) {
  return (
    <Grid container xs={12}>
      <Grid item xs={8}>
        <RadioButton
          classes={{
            root: 'membership__unlimited',
            labelRoot: 'membership__unlimitedSelector',
          }}
          name="unlimited"
          orientation="horizontal"
          selectedValue={eventCredit.unlimited}
          onChange={(_, value) =>
            actions.eventCreditUnlimitedToggled(index, value === 'true')
          }
          options={[
            {
              label: t('.unlimited', intl, __filenamespace),
              disabled: isUnlimitedDisabled,
              value: true,
            },
            {
              label: t('.qty', intl, __filenamespace),
              value: false,
            },
          ]}
        />
      </Grid>
      {!eventCredit.unlimited && (
        <Grid item xs={4}>
          <TextField
            fullWidth
            classes={{
              root: 'membership__unlimitedSelector-quantity',
            }}
            name="quantity"
            type="number"
            value={eventCredit.quantity}
            min={1}
            onChange={(e, value) =>
              actions.eventCreditUpdated(e.target.name, index, value)
            }
            errorText={eventCredit.errors.getErrors('quantity', intl)}
          />
        </Grid>
      )}
    </Grid>
  );
}

function EventTypeSelect({
  index,
  actions,
  eventCredit,
  unselectedEventTypes,
  intl,
}) {
  return (
    <EventTypeEditor
      eventTypes={unselectedEventTypes}
      errorText={eventCredit.errors.getErrors('event_types', intl)}
      onCreateOrUpdateSuccess={eventTypeId =>
        actions.eventCreditEventTypeAdded(index, eventTypeId)
      }
      onEventTypeSelect={value =>
        actions.eventCreditEventTypeAdded(index, value)
      }
      placeholder={t('.choose_event_types', intl, __filenamespace)}
    />
  );
}

function SelectedEventTypesList({ actions, selectedEventTypes, index }) {
  return (
    <div className="membership__selectedEventTypesList">
      {selectedEventTypes.map(eventType => (
        <SelectedEventOrType
          key={eventType.id}
          eventType={eventType}
          title={eventType.name}
          id={eventType.id}
          barColor={eventType.color}
          onRemove={id => actions.eventCreditEventTypeRemoved(index, id)}
        />
      ))}
    </div>
  );
}

function SelectedEventsList({ actions, selectedEvents, index }) {
  return (
    <div className="membership__selectedEventTypesList">
      {selectedEvents.map(event => (
        <SelectedEventOrType
          key={event.id}
          event={event}
          title={event.title}
          id={event.id}
          barColor={event.event_type.color}
          onRemove={id => {
            actions.eventCreditEventRemoved(index, id);
            SpecificEventsActions.removeSpecificEvent(id);
          }}
        />
      ))}
    </div>
  );
}

const creditTypeSelectHandler = (
  index,
  value,
  selectedEventIds,
  selectedEventTypeIds,
  actions
) => {
  actions.eventCreditTypeChanged(index, value);

  if (value === MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC) {
    FilterActions.updateTitleFilter('');
    FilterActions.updateScheduleTypeFilter(['open_booking', 'class_schedule']);
    EventActions.list({
      fields: [],
      exceptIds: selectedEventIds.toArray(),
      exceptTypes: selectedEventTypeIds.toArray(),
    });
  }
};

const EventCreditCard = injectIntl(
  ({
    actions,
    eventCredit,
    events,
    eventTypes,
    existingAllEventsCredits,
    index,
    isRolloverAllowed,
    allowFutureScheduling,
    selectedEventIds,
    selectedEventTypeIds,
    specificEvents,
    style,
    unselectedEventTypes,
    intl,
  }) => {
    const selectedEventTypes = eventTypes.filter(et =>
      eventCredit.event_type_ids.has(et.id)
    );
    const selectedEvents = specificEvents.filter(e =>
      eventCredit.event_ids.has(e.id)
    );

    const isUnlimitedDisabled =
      eventCredit.all_events &&
      selectedEventTypeIds.size !== eventCredit.event_type_ids.size;
    const isAllEventDisabled =
      (existingAllEventsCredits && !eventCredit.all_events) ||
      (selectedEventTypeIds.size &&
        selectedEventTypeIds.size !== eventCredit.event_type_ids.size &&
        eventCredit.unlimited) ||
      (selectedEventIds.size &&
        selectedEventIds.size !== eventCredit.event_ids.size &&
        eventCredit.unlimited);

    const debouncedSearch = debounce(searchText => {
      FilterActions.updateTitleFilter(searchText);
      EventActions.list({
        fields: [],
        exceptIds: selectedEventIds.toArray(),
        exceptTypes: selectedEventTypeIds.toArray(),
      });
    }, 600);

    return (
      <Paper style={merge({ padding: '20px', position: 'relative' }, style)}>
        <IconButton
          onClick={() => actions.eventCreditRemoved({ index })}
          style={{ position: 'absolute', top: 0, right: 0 }}
        >
          <ClearIcon sx={{ color: uhColors.lightGrey }} />
        </IconButton>

        <Typography variant="fieldLabel">
          <FormattedMessage
            id={messageId('.session_credits', __filenamespace)}
          />
        </Typography>

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item xs={12}>
              <UnlimitedSelector
                actions={actions}
                eventCredit={eventCredit}
                isUnlimitedDisabled={isUnlimitedDisabled}
                index={index}
                intl={intl}
              />
            </Grid>

            {!eventCredit.unlimited && (
              <Grid item xs={12}>
                <Dropdown
                  name="frequency"
                  value={eventCredit.frequency}
                  onChange={e =>
                    actions.eventCreditUpdated(
                      'frequency',
                      index,
                      e.target.value
                    )
                  }
                  label={t('.recurring', intl, __filenamespace)}
                  fullWidth
                  items={[
                    {
                      value: FREQUENCY_TYPES.DAY,
                      label: t('.daily', intl, __filenamespace),
                    },
                    {
                      value: FREQUENCY_TYPES.WEEK,
                      label: t('.weekly', intl, __filenamespace),
                    },
                    {
                      value: FREQUENCY_TYPES.MONTH,
                      label: t('.monthly', intl, __filenamespace),
                    },
                    {
                      value: FREQUENCY_TYPES.YEAR,
                      label: t('.yearly', intl, __filenamespace),
                    },
                  ]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Dropdown
                name="type"
                value={eventCredit.type}
                onChange={e =>
                  creditTypeSelectHandler(
                    index,
                    e.target.value,
                    selectedEventIds,
                    selectedEventTypeIds,
                    actions
                  )
                }
                label={t('.how_should_this_be_applied', intl, __filenamespace)}
                fullWidth
                items={[
                  ...(isAllEventDisabled
                    ? []
                    : [
                        {
                          value: MEMBERSHIP_CREDIT_TYPES.ALL_EVENTS,
                          label: t('.all_events', intl, __filenamespace),
                        },
                      ]),
                  {
                    value: MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES,
                    label: t('.event_types', intl, __filenamespace),
                  },
                  {
                    value: MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC,
                    label: t('.event_specific', intl, __filenamespace),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={styles.hintText}>
                <FormattedMessage
                  id={messageId(
                    `.hint_text_${eventCredit.type}`,
                    __filenamespace
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              {eventCredit.type === MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES && (
                <div>
                  <EventTypeSelect
                    index={index}
                    actions={actions}
                    eventCredit={eventCredit}
                    unselectedEventTypes={unselectedEventTypes}
                    intl={intl}
                  />
                  <SelectedEventTypesList
                    actions={actions}
                    selectedEventTypes={selectedEventTypes}
                    index={index}
                  />
                </div>
              )}

              {eventCredit.type === MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC && (
                <div>
                  <EventAutoComplete
                    inputVariant="outlined"
                    popupIcon={null}
                    hintText={t('.search_event', intl, __filenamespace)}
                    events={events}
                    index={index}
                    onChange={debouncedSearch}
                    onEventSelect={eventId => {
                      actions.eventCreditEventAdded(index, eventId);
                      SpecificEventsActions.addSpecificEvent(eventId);
                    }}
                    onLeave={() => {
                      FilterActions.updateTitleFilter('');
                      EventActions.list({
                        fields: [],
                        exceptIds: selectedEventIds.toArray(),
                        exceptTypes: selectedEventTypeIds.toArray(),
                      });
                    }}
                    errorText={eventCredit.errors.getErrors(
                      'event_specific',
                      intl
                    )}
                    inputRootStyle={{
                      borderRadius: 0,
                      padding: '0 30px 0 10px',
                      input: {
                        padding: '10px 0',
                      },
                    }}
                    withIcon
                  />
                  <SelectedEventsList
                    actions={actions}
                    selectedEvents={selectedEvents}
                    index={index}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item xs={12}>
              <ReactTooltip
                id="grant-ahead-tip"
                className="uh-tooltip"
                effect="solid"
              >
                {t('.grant_ahead_tooltip', intl, __filenamespace)}
              </ReactTooltip>
              <Dropdown
                name="grant_ahead"
                placeholder={t('.no_grant_ahead', intl, __filenamespace)}
                disabled={allowFutureScheduling || isRolloverAllowed}
                value={eventCredit.grant_ahead}
                onChange={e => {
                  actions.eventGrantAheadChanged({
                    grantAhead: e.target.value,
                    index,
                  });
                }}
                label={
                  <div
                    style={styles.labelWithTooltip}
                    data-tip
                    data-for="grant-ahead-tip"
                  >
                    {t('.grant_ahead', intl, __filenamespace)}
                  </div>
                }
                fullWidth
                items={[
                  {
                    value: 0,
                    label: t('.no_grant_ahead', intl, __filenamespace),
                  },
                  {
                    value: 7,
                    label: (
                      <FormattedMessage
                        id={messageId('.grant_ahead_week', __filenamespace)}
                        values={{ weekCount: 1 }}
                      />
                    ),
                  },
                  {
                    value: 14,
                    label: (
                      <FormattedMessage
                        id={messageId('.grant_ahead_week', __filenamespace)}
                        values={{ weekCount: 2 }}
                      />
                    ),
                  },
                  {
                    value: 21,
                    label: (
                      <FormattedMessage
                        id={messageId('.grant_ahead_week', __filenamespace)}
                        values={{ weekCount: 3 }}
                      />
                    ),
                  },
                  {
                    value: 30,
                    label: (
                      <FormattedMessage
                        id={messageId('.grant_ahead_month', __filenamespace)}
                        values={{ monthCount: 1 }}
                      />
                    ),
                  },
                  {
                    value: 60,
                    label: (
                      <FormattedMessage
                        id={messageId('.grant_ahead_month', __filenamespace)}
                        values={{ monthCount: 2 }}
                      />
                    ),
                  },
                  {
                    value: 91,
                    label: (
                      <FormattedMessage
                        id={messageId('.grant_ahead_month', __filenamespace)}
                        values={{ monthCount: 3 }}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
            {!eventCredit.unlimited && (
              <Grid item xs={12}>
                <Checkbox
                  checked={isRolloverAllowed}
                  label={t('.allow_credit_rollover', intl, __filenamespace)}
                  onChange={() => {
                    actions.toggleCreditRollover(index);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Checkbox
                checked={allowFutureScheduling}
                label={t('.allow_future_scheduling', intl, __filenamespace)}
                onChange={() => {
                  actions.toggleFutureScheduling(index);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);

function ExpandedSelector({
  actions,
  disabledAddingNewCredit,
  events,
  existingAllEventsCredits,
  selectedEventIds,
  selectedEventTypeIds,
  unselectedEventTypes,
  specificEvents,
  eventTypes,
  membership,
  intl,
}) {
  return (
    <div>
      {membership.membership_event_credits.map((eventCredit, index) => (
        <EventCreditCard
          key={eventCredit.id || index}
          actions={actions}
          eventCredit={eventCredit}
          eventTypes={eventTypes}
          events={events}
          existingAllEventsCredits={existingAllEventsCredits}
          index={index}
          isRolloverAllowed={eventCredit.rollover}
          allowFutureScheduling={eventCredit.allow_future_scheduling}
          selectedEventIds={selectedEventIds}
          selectedEventTypeIds={selectedEventTypeIds}
          specificEvents={specificEvents}
          style={{ marginBottom: 15 }}
          unselectedEventTypes={unselectedEventTypes}
        />
      ))}
      {!disabledAddingNewCredit && (
        <Button
          style={{ marginBottom: 10 }}
          startIcon={<AddIcon style={{ color: uhColors.activeBlue }} />}
          onClick={() => actions.eventCreditAdded()}
        >
          {t('.additional_credits', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

function EventCreditSelector({
  actions,
  disabledAddingNewCredit,
  events,
  existingAllEventsCredits,
  selectedEventIds,
  selectedEventTypeIds,
  unselectedEventTypes,
  specificEvents,
  eventTypes,
  membership,
  expanded,
  intl,
}) {
  return (
    <div className="membership-discounts">
      <Grid container>
        <Grid item xs={12} className="membership-discounts__title-container">
          <Typography
            variant="fieldLabel"
            className="membership-discounts__title"
          >
            <FormattedMessage id={messageId('.header_text', __filenamespace)} />
            <InfoIcon
              data-tip={t('.expiration_tooltip', intl, __filenamespace)}
            />
            <ReactTooltip effect="solid" className="uh-tooltip uh-tooltip_sm" />
          </Typography>
          <Typography variant="body2">
            {t('.explanatory_text', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
      {expanded ? (
        <ExpandedSelector
          actions={actions}
          disabledAddingNewCredit={disabledAddingNewCredit}
          events={events}
          existingAllEventsCredits={existingAllEventsCredits}
          selectedEventIds={selectedEventIds}
          selectedEventTypeIds={selectedEventTypeIds}
          unselectedEventTypes={unselectedEventTypes}
          specificEvents={specificEvents}
          eventTypes={eventTypes}
          membership={membership}
          intl={intl}
        />
      ) : (
        <CollapsedBenefitSelector
          buttonText={t('.create_discount', intl, __filenamespace)}
          onExpand={actions.eventCreditAdded}
        />
      )}
    </div>
  );
}

export default injectIntl(EventCreditSelector);
