import React from 'react';
import moment from 'moment-timezone';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { l, t } from 'shared/utils/LocaleUtils.js';
import Paginator from '../../../shared/components/Paginator.jsx';
import MrrReportStore from '../stores/MonthlyRecurringRevenueReportStore.jsx';
import MrrReportActions from '../actions/MonthlyRecurringRevenueReportActions.jsx';
import { Widget } from '../../index/shared/components/ReportWidget.jsx';

const styles = {
  exportButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '36px 0 12px 12px',
  },
  exportButton: {
    color: 'var(--color-secondary)',
  },
};

const AlignedColumn = styled(TableCell)(({ align = 'left' }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: align,
    paddingRight: align === 'right' ? 0 : 24,
  },
}));

const DetailHeader = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: 'var(--color-old-gray)',
    fontWeight: 600,
    fontSize: 15,
  },
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

class MonthlyRecurringRevenueDetails extends React.Component {
  detailsRef = null;

  componentDidUpdate(prevProps) {
    const { monthlyRecurringRevenueReportStore: store } = this.props;
    const { monthlyRecurringRevenueReportStore: prevStore } = prevProps;
    if (
      store.detailsListResult.length &&
      prevStore.detailsListResult.length !== store.detailsListResult.length
    ) {
      this.scroll();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleDownloadDetails = () => {
    MrrReportActions.downloadDetails();
  };

  scroll = () => {
    window.scrollTo(0, this.detailsRef.offsetTop);
  };

  // eslint-disable-next-line class-methods-use-this
  handlePageSelect = (page, perPage) => {
    MrrReportActions.updateDetailsPageParams({ page, perPage });
  };

  // eslint-disable-next-line class-methods-use-this
  getDate = date => {
    const utcDate = moment(date).utc();
    const returnUTC =
      utcDate.hours() === 0 &&
      utcDate.minutes() === 0 &&
      utcDate.seconds() === 0;

    if (returnUTC) {
      return utcDate;
    }

    return date;
  };

  formatColumn = (column, value) => {
    const { intl } = this.props;

    switch (column) {
      case 'Revenue':
      case '$ Amount of Membership':
        return MrrReportStore.formatCurrency(intl, value);
      case 'Date':
        return l(this.getDate(value), `.dateFormat`, intl, __filenamespace);
      default:
        return value;
    }
  };

  render() {
    const {
      intl,
      monthlyRecurringRevenueReportStore: {
        interval,
        detailsListResult = [],
        detailLabel,
        detailPeriod,
        detailsPageParams: { page, perPage },
        isDetailsLoading,
        monthlyRecurringRevenueListResult,
      },
    } = this.props;

    const columns = detailsListResult.length
      ? Object.keys(detailsListResult[0])
      : [];

    const from = (page - 1) * perPage;
    const to = page * perPage;
    const paginatedDetails = detailsListResult.slice(from, to);

    return (
      monthlyRecurringRevenueListResult.length > 0 && (
        <div>
          {!!detailsListResult.length && (
            <div
              ref={ref => {
                this.detailsRef = ref;
              }}
            >
              <div style={styles.exportButtonContainer}>
                <Button
                  style={styles.exportButton}
                  onClick={this.handleDownloadDetails}
                  endIcon={<CloudDownloadIcon />}
                >
                  {t('.export_details', intl, __filenamespace)}
                </Button>
              </div>
              <Widget
                titleStyle={{ display: 'none' }}
                bodyStyle={{ padding: 0 }}
                isLoading={isDetailsLoading}
              >
                <TableContainer>
                  <Table selectable>
                    <TableHead>
                      <TableRow>
                        <DetailHeader colSpan={columns.length}>
                          {l(
                            detailPeriod,
                            `.dateFormat${interval}`,
                            intl,
                            __filenamespace
                          )}{' '}
                          {detailLabel}
                        </DetailHeader>
                        <AlignedColumn align="right">
                          <IconButton
                            tooltip="close"
                            onClick={MrrReportActions.hideDetails}
                          >
                            <CloseIcon />
                          </IconButton>
                        </AlignedColumn>
                      </TableRow>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell key={column}>{column}</TableCell>
                        ))}
                        <AlignedColumn align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody displayRowCheckbox={false} stripedRows>
                      {paginatedDetails.map(detail => (
                        <StyledRow
                          key={`${detail['Client Name']}-${detail.Date}`}
                        >
                          {columns.map(column => (
                            <TableCell key={column}>
                              {this.formatColumn(column, detail[column])}
                            </TableCell>
                          ))}
                          <AlignedColumn align="right" />
                        </StyledRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Widget>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Paginator
                  currentPage={page}
                  perPage={perPage}
                  totalCount={detailsListResult.length}
                  onPageSelect={this.handlePageSelect}
                />
              </div>
            </div>
          )}
        </div>
      )
    );
  }
}

const StyledAltContainer = styled(AltContainer)`
  margin: 0;
`;

function MonthlyRecurringRevenueDetailsWrapper({ intl }) {
  return (
    <StyledAltContainer
      stores={{
        monthlyRecurringRevenueReportStore: MrrReportStore,
      }}
    >
      <MonthlyRecurringRevenueDetails intl={intl} />
    </StyledAltContainer>
  );
}

export default injectIntl(MonthlyRecurringRevenueDetailsWrapper);
