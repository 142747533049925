import React, { useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Map } from 'immutable';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import ScheduledSession from 'shared/components/registration/ScheduledSession.jsx';

import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import Registration from 'shared/records/Registration';

import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';

function ConfirmRemoveRegistration({ registration, onClose, onConfirm }) {
  if (!registration) return null;

  const expDate = registration.credit_expires_at;

  return (
    <Dialog open={!!registration}>
      <DialogTitle>
        {' '}
        <FormattedMessage id={messageId('.remove_booking', __filenamespace)} />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage
          id={messageId('.remove_booking_confirmation', __filenamespace)}
        />
        {registration.isCreditBased() && (
          <Typography sx={{ marginTop: '10px' }}>
            {expDate && expDate.isSameOrBefore(moment()) ? (
              <FormattedMessage
                id={messageId('.credit_expired', __filenamespace)}
                values={{ expDate: expDate.format('LLL') }}
              />
            ) : (
              <FormattedMessage
                id={messageId('.credit_refunded', __filenamespace)}
              />
            )}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="dark" onClick={onClose}>
          <FormattedMessage id={messageId('actions.no', __filenamespace)} />
        </Button>
        <Button variant="contained" color="dark" onClick={onConfirm} autoFocus>
          <FormattedMessage id={messageId('actions.yes', __filenamespace)} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmRemoveRegistration.propTypes = {
  registration: PropTypes.instanceOf(Registration),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ConfirmRemoveRegistration.defaultProps = {
  registration: null,
  onClose: () => null,
  onConfirm: () => null,
};

function OpenBookingRegistrations({
  rescheduleRegistrationId,
  registrations,
  event,
  allAthletes,
  staff,
  sessions,
  onComplete,
  onReturn,
}) {
  const [registrationToCancel, setRegistrationToCancel] = useState(null);

  const handleCancel = () => setRegistrationToCancel(null);
  const handleConfirm = () => {
    RegistrationActions.cancel(registrationToCancel);
    setRegistrationToCancel(null);
  };

  return (
    <>
      {registrations.map(r => {
        const session = sessions.get(r.session_id);

        if (!session) {
          return null;
        }

        return (
          <ScheduledSession
            key={r.id}
            cancellationDeadline={
              event.getIn(['schedules', 0]).cancellation_deadline
            }
            event={event}
            profiles={allAthletes}
            registrationId={r.id}
            client_id={r.client_id}
            starts_at={session.starts_at}
            staff_ids={session.staff_ids}
            staff={staff}
            style={{ marginTop: 10 }}
            onRegistrationCancel={
              !rescheduleRegistrationId
                ? reg => setRegistrationToCancel(reg)
                : undefined
            }
          />
        );
      })}
      <Stack spacing={2} sx={{ marginTop: '50px' }}>
        {!rescheduleRegistrationId && (
          <Button
            color="dark"
            variant="contained"
            sx={{ height: '50px' }}
            onClick={onReturn}
          >
            <FormattedMessage id={messageId('.return', __filenamespace)} />
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ height: '50px' }}
          onClick={onComplete}
        >
          <FormattedMessage id={messageId('.complete', __filenamespace)} />
        </Button>
      </Stack>
      <ConfirmRemoveRegistration
        registration={registrations.find(
          r => r.get('id') === registrationToCancel?.id
        )}
        onClose={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
}

OpenBookingRegistrations.propTypes = {
  rescheduleRegistrationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  registrations: PropTypes.instanceOf(List),
  event: PropTypes.instanceOf(CustomerEvent),
  allAthletes: PropTypes.instanceOf(List),
  staff: PropTypes.instanceOf(List),
  sessions: PropTypes.instanceOf(Map),
  onComplete: PropTypes.func,
  onReturn: PropTypes.func,
};

OpenBookingRegistrations.defaultProps = {
  rescheduleRegistrationId: null,
  registrations: List(),
  event: new CustomerEvent(),
  allAthletes: List(),
  staff: List(),
  sessions: Map(),
  onComplete: () => null,
  onReturn: () => null,
};

export default injectIntl(OpenBookingRegistrations);
