import { List, Record, Set } from 'immutable';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

export const convertResourceToDataSource = data =>
  data
    .map(r => ({ text: r.name, value: r.get('id') }))
    .toList()
    .toJS();

class Resource extends Record({
  id: null,
  name: '',
  square_feet: null,
  location_id: null,
  preferring_staff_ids: Set(),
  errors: new FieldErrors(),

  location: null,
  preferring_staff: List(),
}) {
  constructor(obj = {}) {
    const preferringStaffIds = Set(obj.preferring_staff_ids);

    super(merge(obj, { preferring_staff_ids: preferringStaffIds }));
  }

  addPreferringStaff(staffId) {
    return this.update('preferring_staff_ids', (set = Set()) =>
      set.add(staffId)
    );
  }

  removePreferringStaff(staffId) {
    return this.update('preferring_staff_ids', (set = Set()) =>
      set.delete(staffId)
    );
  }

  validate() {
    let errors = new FieldErrors();

    if (!isPresent(this.name)) {
      errors = errors.add('name', 'records.errors.required');
    }

    if (
      !Number(this.square_feet) &&
      this.square_feet !== null &&
      this.square_feet !== ''
    ) {
      errors = errors.add('square_feet', 'records.errors.number');
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  toServer() {
    const obj = this.toJS();

    delete obj.location;
    delete obj.preferring_staff;

    return obj;
  }
}

export default Resource;
