/* eslint-disable react/require-default-props */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { Typography, TextField, Grid, Button } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';

function AttendeeList({
  isLoading,
  registrations,
  clientDataStore,
  showRemindBtn = false,
  onRemindClick = () => {},
}) {
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [filteredAttendeeIds, setFilteredAttendeeIds] = useState(Map());
  const attendeeIds = registrations.map(reg => reg.get('client_id'));

  useEffect(() => {
    setFilteredAttendeeIds(attendeeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrations]);

  const { clients } = clientDataStore;

  const handleSearch = e => {
    const { value } = e.target;
    setSearch(value);

    if (value === '') {
      setFilteredAttendeeIds(attendeeIds);
    } else {
      const filtered = attendeeIds.filter(id => {
        const attendeeName = clients.get(id).name().toLowerCase();
        return attendeeName.includes(value.toLowerCase());
      });
      setFilteredAttendeeIds(filtered);
    }
  };

  return (
    <SpinWhileLoading isLoading={isLoading} absolute={false}>
      <Grid container spacing={2} className="attendee-list">
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={search}
            icon="search"
            iconPlacement="left"
            placeholder={t('.search_placeholder', intl, __filenamespace)}
            disabled={attendeeIds.length === 0}
            classes={{
              root: 'search-input',
              startAdornment: 'search-input__search-icon',
            }}
            onChange={handleSearch}
          />
        </Grid>
        {filteredAttendeeIds.size === 0 && (
          <Grid item xs={12}>
            <Typography variant="body2" align="center">
              {t('.no_attendees', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {filteredAttendeeIds.size > 0 && (
          <Grid item container xs={12}>
            {filteredAttendeeIds.valueSeq().map((id, index) => {
              const attendee = clients.get(id);

              if (!attendee) {
                return null;
              }

              const clientRegistration = registrations.find(
                reg => reg.client_id === id
              );
              const isReminded = Boolean(clientRegistration?.last_reminded_at);

              return (
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${id}-${index}`}
                  className="attendee"
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-start"
                    xs={9}
                  >
                    <UserAvatar user={attendee} style={{ width: '32px' }} />
                    <Typography variant="body2">{attendee?.name()}</Typography>
                  </Grid>
                  {showRemindBtn && (
                    <Grid
                      item
                      container
                      xs={3}
                      alignItems="center"
                      justify="flex-end"
                    >
                      <Button
                        disabled={isReminded}
                        type="tertiary"
                        classes={{ root: 'remind-btn' }}
                        onClick={() => onRemindClick(clientRegistration)}
                      >
                        {!isReminded
                          ? t('.remind', intl, __filenamespace)
                          : t('.reminded', intl, __filenamespace)}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </SpinWhileLoading>
  );
}

AttendeeList.propTypes = {
  isLoading: PropTypes.bool,
  showRemindBtn: PropTypes.bool,
  clientDataStore: PropTypes.shape({
    clients: PropTypes.instanceOf(Map).isRequired,
  }).isRequired,
  registrations: PropTypes.instanceOf(Map).isRequired,
  onRemindClick: PropTypes.func,
};

export default compose(
  memo,
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
    },
  })
)(AttendeeList);
