import NativePlugin from 'shared/native/helpers/NativePlugin';
import CordovaLifeCycleActions from 'shared/actions/CordovaLifeCycleActions';

/**
 * Converts cordova lifecycle events into Alt.js Actions
 *
 * Don't use this class directly. Rather, subscribe to one of the available alt events
 */
class LifeCycle extends NativePlugin {
  onDeviceReady() {
    CordovaLifeCycleActions.deviceReady();
  }

  onPause() {
    CordovaLifeCycleActions.pause();
  }

  onResume() {
    CordovaLifeCycleActions.resume();
  }
}

export default new LifeCycle();
