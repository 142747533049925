import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage } from 'react-intl';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import ContactsMap from 'reporting/index/components/_ContactsMap.jsx';
import ContactsList from 'reporting/index/components/_ContactsList.jsx';
import ContactsFilters from 'reporting/index/components/_ContactsFilters.jsx';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import ContactsReportActions from 'reporting/index/actions/_ContactsReportActions.jsx';

function ContactsReport() {
  return (
    <FlexBoxJustify
      style={{ flexDirection: 'column', padding: '25px 30px 30px 30px' }}
    >
      <PageHeader
        title={<FormattedMessage id={messageId('.header', __filenamespace)} />}
      />
      <div>
        <ContactsFilters />
        <ContactsMap />
        <ContactsList />
      </div>
    </FlexBoxJustify>
  );
}

class ContactsReportWrapper extends React.Component {
  componentDidMount() {
    ContactsReportActions.mounted();
  }

  render() {
    return (
      <AltContainer>
        <ContactsReport />
      </AltContainer>
    );
  }
}

export default ContactsReportWrapper;
