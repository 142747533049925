export default alt.generateActions(
  'list',
  'listSuccess',
  'listError',
  'addSpecificItem',
  'removeSpecificItem',
  'toggleItemType',
  'listItems',
  'listItemsSuccess',
  'listItemsError',
  'handleSearch'
);
