import * as React from 'react';
import ClientActions from 'shared/actions/ClientActions.jsx';
import ProfileManager from 'user_management/shared/components/ProfileManager.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { REQUIRED_MANAGED_ACCOUNT_FIELDS } from 'containers/accounts/createUser/const.js';

const onChange = handleChange({ actions: ClientActions });
const onSave = () => {
  ClientActions.validateUser({
    requiredFields: REQUIRED_MANAGED_ACCOUNT_FIELDS,
  });
  ClientActions.createOrUpdate({
    clearOnSuccess: true,
    showSuccessMessage: true,
    resourceName: 'Profile',
    managedByCurrentClient: true,
  });
};

function ProfilesTab({ athleteStore, clientStore }) {
  return (
    <SpinWhileLoading isLoading={athleteStore.isLoading} spinWhile="isLoading">
      <ProfileManager
        justifyForm="flex-start"
        currentProfile={clientStore.client}
        errors={clientStore.fieldErrors}
        isSaving={clientStore.isSaving}
        onChange={onChange}
        onSave={onSave}
        onCancel={() => ClientActions.clearClient()}
        athletes={athleteStore.allAthletes.filterNot(
          athlete => athlete.user_id === currentUser().id
        )}
      />
    </SpinWhileLoading>
  );
}

export default ProfilesTab;
