import { List, Map, fromJS } from 'immutable';
import moment from 'moment-timezone';
import CreditPass from 'shared/records/CreditPass.js';
import CreditPassStoreInterface from 'shared/stores/CreditPassStoreInterface.jsx';
import CreditPassViewingActions from 'credit_passes/actions/CreditPassViewingActions.js';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { ClientSource, CreditGrantSource } from 'sources';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const CLIENTS_PER_PAGE = 20;

class CreditPassViewingStore extends CreditPassStoreInterface {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      listCreditGrantSuccess:
        CreditPassViewingActions.LIST_CREDIT_GRANT_SUCCESS,

      listClients: CreditPassViewingActions.LIST_CLIENTS,
      listClientsSuccess: CreditPassViewingActions.CLIENTS_LOADED,

      removeCreditPass: CreditPassViewingActions.REMOVE_CONFIRMED,
      removeCreditPassError: CreditPassViewingActions.REMOVE_ERROR,
      removeCreditPassSuccess: CreditPassViewingActions.REMOVE_SUCCESS,

      requestClientsPage: CreditPassViewingActions.REQUEST_CLIENTS_PAGE,
      updateSearchString: CreditPassViewingActions.SEARCH_STRING_UPDATED,
    });
  }

  reset() {
    this.record = new CreditPass();
    this.creditPassId = null;
    this.clients = List();
    this.clientsPage = 1;
    this.clientsPerPage = CLIENTS_PER_PAGE;
    this.searchString = '';
    this.shouldFetchClients = true;
    this.totalClientsCount = 0;

    this.creditGrantsByClient = Map();
    this.clientDetails = Map();
  }

  clientsQueryParams() {
    return {
      page: this.clientsPage,
      per_page: this.clientsPerPage,
      search: this.searchString,
    };
  }

  listClientsSuccess({ clients, _page, _perPage, totalCount }) {
    this.totalClientsCount = totalCount;
    this.clients = clients;

    this.listCreditGrants();
  }

  handleFetch({ id }) {
    this.reset();
    super.handleFetch({ id, fields: ['events', 'event_types'] });
  }

  listClients(opts) {
    const options = opts || {};
    const recordID = this.record && this.record.id;
    this.creditPassId = options.id || recordID;

    const shouldFetch =
      this.shouldFetchClients || this.creditPassId !== recordID;

    if (shouldFetch) {
      ClientSource.list({
        params: merge(this.clientsQueryParams(), {
          // Comment the line below to bring any CustomerUser into the credit pass view
          // (only relevant for testing).
          credit_pass_ids: [this.creditPassId],
        }),
        success: CreditPassViewingActions.clientsLoaded,
        error: CreditPassViewingActions.error,
      });
    }
  }

  requestClientsPage(page) {
    if (this.clientsPage === page) {
      return;
    }

    this.clientsPage = page;
    this.shouldFetchClients = true;

    this.listClients();
  }

  updateSearchString(newSearchString) {
    if (this.searchString === newSearchString) {
      return;
    }
    this.searchString = newSearchString;
    this.clientsPage = 1;
    this.shouldFetchClients = true;
    this.listClients();
  }

  removeCreditPass() {
    uhApiClient.delete({
      url: this.url(this.record.id),
      success: CreditPassViewingActions.removeSuccess,
      error: CreditPassViewingActions.removeError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  removeCreditPassSuccess() {
    window.location.href = customerScopedRoute('credit_passes');
  }

  removeCreditPassError(...args) {
    this.notifyError(
      `error removing credit pass with id ${this.record.id}`,
      args
    );
  }

  listCreditGrants() {
    CreditGrantSource.list({
      params: {
        credit_pass_ids: [this.creditPassId],
        client_ids: this.clients.map(c => c.id).toJS(),
        expired: false,
        per_page: 100,
      },
      success: CreditPassViewingActions.listCreditGrantSuccess,
      error: CreditPassViewingActions.listCreditGrantError,
    });
  }

  listCreditGrantSuccess({ credit_grants: creditGrants }) {
    this.creditGrantsByClient = creditGrants.groupBy(g => g.client_id);
    this.clientDetails = this.creditGrantsByClient.map(grants =>
      fromJS(
        grants.reduce(
          (details, creditGrant) => {
            /* eslint-disable no-param-reassign */
            details.creditsUsed += creditGrant.quantity - creditGrant.available;
            details.creditsTotal += creditGrant.quantity;
            details.unlimited = details.unlimited || creditGrant.unlimited;

            let expiration;
            if (details.expiresAt === undefined) {
              expiration = creditGrant.expires_at;
            } else if (
              details.expiresAt === null ||
              creditGrant.expires_at === null
            ) {
              expiration = null;
            } else {
              expiration = moment.max([
                details.expiresAt,
                moment(creditGrant.expires_at),
              ]);
            }

            details.expiresAt = expiration
              ? moment.tz(expiration, customerTZ())
              : expiration;
            /* eslint-enable no-param-reassign */
            return details;
          },
          { creditsUsed: 0, creditsTotal: 0 }
        )
      )
    );
  }
}

export default alt.createStore(
  CreditPassViewingStore,
  'CreditPassViewingStore'
);
