import React from 'react';
import { injectIntl } from 'react-intl';

import LandingTemplate from 'shared/components/login/_LandingTemplate.jsx';

import DesktopResetRequest from './components/DesktopResetRequest.jsx';
import MobileResetRequest from './components/MobileResetRequest.jsx';

function ForgotPassword({ intl }) {
  return (
    <LandingTemplate
      largeScreen={<DesktopResetRequest intl={intl} />}
      smallScreen={<MobileResetRequest intl={intl} />}
    />
  );
}

export default injectIntl(ForgotPassword);
