import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import FilteredEventListStoreImpl from 'event_mgmt/index/stores/_FilteredEventListStoreImplementation.jsx';
import FilterStore from 'event_mgmt/index/stores/FilterStore.jsx';

class EventListStore extends FilteredEventListStoreImpl {
  constructor() {
    super(EventActions);
  }

  filterStore() {
    return FilterStore;
  }
}

export default alt.createStore(EventListStore, 'EventListStore');
