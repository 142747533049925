import { Record } from 'immutable';
import { toSlug } from 'shared/utils/StringUtils.jsx';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

class Role extends Record({
  customerId: null,
  customerName: '',
  roleType: null,
  userRole: null,
  roleId: null,
  slug: '',
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({
      ...parsedObject,
      slug: toSlug(`${parsedObject.customerId} ${parsedObject.customerName}`),
    });
  }
}

export default Role;
