import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import {
  Grid,
  Typography,
  FormModal,
  TextField,
  DateTimePicker,
} from '@upperhand/playmaker';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { CreditGrantDataStore } from 'dataStores';
import CreditListDrawerActions from '../Actions';

function CreditAdjustmentModal({
  intl,
  creditGrantId,
  creditGrantDataStore: { creditGrants },
}) {
  const creditGrant = creditGrants.get(creditGrantId);

  const [quantity, setQuantity] = React.useState(null);
  const [expiresAt, setExpiresAt] = React.useState(null);
  const [errors, setErrors] = React.useState(new FieldErrors());

  React.useEffect(() => {
    setErrors(new FieldErrors());
    setExpiresAt(null);
    setQuantity(creditGrant?.total);
  }, [creditGrant, creditGrantId]);

  if (!creditGrant) return null;

  const updateCreditGrant = () => {
    let newErrors = new FieldErrors();

    if (expiresAt && (!expiresAt.isValid() || expiresAt.isBefore(moment())))
      newErrors = newErrors.add(
        'expiresAt',
        t('.expires_at_error', intl, __filenamespace)
      );

    setErrors(newErrors);
    if (newErrors.isEmpty()) {
      CreditListDrawerActions.updateCreditGrant({
        id: creditGrant.id,
        quantity,
        expiresAt,
      });
    }
  };

  const isExpirationAdjustable = creditGrant.isMembershipBased();

  return (
    <FormModal
      classes={{
        root: 'credit-adjustment-modal',
        content: 'credit-adjustment-modal__content',
      }}
      open={!!creditGrantId}
      title={t('.title', intl, __filenamespace)}
      actions={[
        {
          label: t('.cancel', intl, __filenamespace),
          action: CreditListDrawerActions.closeAdjustmentModal,
          type: 'secondary',
        },
        {
          label: t('.confirm', intl, __filenamespace),
          action: updateCreditGrant,
          type: 'primary',
        },
      ]}
    >
      <Grid container spacing={1} direction="row">
        <Grid container item xs={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t('.available_total', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <FormattedMessage
                id={messageId('.qty', __filenamespace)}
                values={{
                  quantity: creditGrant.total,
                  available: creditGrant.usable,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t('.adjusted_qty', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item container xs={12} alignItems="center" spacing={1}>
            <Grid item xs={2}>
              <IconButton
                disabled={
                  quantity === Infinity ||
                  quantity <= creditGrant.total - creditGrant.usable
                }
                onClick={() => setQuantity(quantity - 1)}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={quantity === Infinity ? '∞' : quantity}
                readOnly
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                disabled={quantity === Infinity}
                onClick={() => setQuantity(quantity + 1)}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t('.current_expiration', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {creditGrant.expires_at?.format('MM/DD/YYYY') ??
                t('.indefinite', intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t('.adjusted_expiration', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div data-tip={t('.expiration_restricted', intl, __filenamespace)}>
              <DateTimePicker
                autoOk
                disablePast
                type="date"
                fullWidth
                disabled={isExpirationAdjustable}
                placeholder="MM/DD/YYYY"
                onChange={value => setExpiresAt(value?.tz(customerTZ(), true))}
                value={expiresAt}
                errorText={errors.getErrors('expiresAt')}
              />
            </div>
            {isExpirationAdjustable && <ReactTooltip />}
          </Grid>
        </Grid>
      </Grid>
    </FormModal>
  );
}

export default altContainer({
  stores: { creditGrantDataStore: CreditGrantDataStore },
})(CreditAdjustmentModal);
