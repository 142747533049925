import React, { useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import { List } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  Drawer,
  Typography,
  CurrencyTextField,
} from '@upperhand/playmaker';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/CloseOutlined';
import { DatePicker } from '@mui/x-date-pickers';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import PaymentTitle from 'containers/reports/paymentPlanDetailsDrawer/components/PaymentTitle.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils.js';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import {
  BalancesDataStore,
  EventDataStore,
  EventTypeDataStore,
  PaymentPlanDataStore,
  ScheduledPaymentDataStore,
  ClientDataStore,
} from 'dataStores';
import PaymentPlanDetailsDrawerActions from 'containers/reports/paymentPlanDetailsDrawer/Actions';
import PaymentPlanDetailsDrawerStore from 'containers/reports/paymentPlanDetailsDrawer/Store';

const styles = {
  productInfoContainer: {
    borderBottom: `1px solid ${uhColors.lighterGrey}`,
    paddingBottom: '1rem',
  },
  circleStyle: {
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    marginRight: '.5rem',
  },
  productTypeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  unpaidContainer: {
    border: `2px solid ${uhColors.lighterGrey}`,
    padding: '20px',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  unpaidItemStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  amountContainer: {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelPaymentContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    gap: '10px',
    marginTop: '1rem',
  },
  cancelPaymentLabel: {},
  paidPaymentContainer: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    border: `2px solid ${uhColors.lighterGrey}`,
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: '20px',
  },
  clientContainer: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
};

function ProductInfo({ productName, productTypeName, color }) {
  return (
    <Box sx={styles.productInfoContainer}>
      <Typography variant="h6">{productName}</Typography>
      <Box sx={styles.productTypeContainer}>
        <Box sx={{ ...styles.circleStyle, backgroundColor: color }} />
        <Typography variant="body1">{productTypeName}</Typography>
      </Box>
    </Box>
  );
}

function UnPaidScheduledPayment({
  payment,
  balance,
  unpaidPayments,
  setUnpaidPayments,
  intl,
}) {
  const timeoutRef = useRef(null);

  // Clear timeout when "Hack to revert the amount field if the new amount is greater than the old amount" triggered
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    },
    [payment?.amount]
  );

  const details = balance ? balance.get('details') : List();
  const waivedAmount = details
    .filter(d => d.get('type') === 'DebitForgiveness')
    .reduce((sum, x) => sum + x.get('amount'), 0);
  const fullyWaivedStatus =
    waivedAmount === payment.get('amount') && 'fully_waived';
  const waivedStatus =
    fullyWaivedStatus || (waivedAmount > 0 && 'partially_waived');
  const hasEmptyDate = unpaidPayments.find(
    item => item.id === payment.id && !item.dueDate
  );
  const hasEmptyAmount = unpaidPayments.find(
    item => item.id === payment.id && !item.amount
  );
  const disableAmountField =
    moment(payment.get('dueDate')).isBefore() || payment.get('effectiveAt');

  const handlePaymentChange = ({ key, value, paymentId }) => {
    setUnpaidPayments(
      unpaidPayments.map(item => {
        if (item.id === paymentId) {
          if (key === 'dueDate' && value) {
            return item.set(key, moment.tz(value, customerTZ()));
          }
          return item.set(key, value);
        }
        return item;
      })
    );
  };

  const handleAmountChange = (e, value) => {
    const oldAmount = payment.get('unpaidAmount');
    const amount = Math.round((value + Number.EPSILON) * 100);

    handlePaymentChange({
      key: 'amount',
      value: amount,
      paymentId: payment.id,
    });

    // Hack to revert the amount field if the new amount is greater than the old amount
    if (amount > oldAmount) {
      const newAmount = amount > oldAmount ? oldAmount : amount;

      timeoutRef.current = setTimeout(() => {
        handlePaymentChange({
          key: 'amount',
          value: newAmount,
          paymentId: payment.id,
        });
      });
    }
  };

  return (
    <Box
      sx={{
        ...styles.unpaidContainer,
        height: 'max-content',
      }}
    >
      <PaymentTitle
        variant="subtitle1"
        hasErrorColor={payment.isCancelled}
        payment={payment}
        waivedStatus={waivedStatus}
      />

      <Box sx={styles.unpaidItemStyle}>
        <Box width="60%">
          <DatePicker
            disableMaskedInput
            inputFormat="MMM D, YYYY"
            disablePast
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField
                fullWidth
                size="small"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
            DialogProps={{ disableEnforceFocus: true }}
            value={
              payment.get('dueDate')
                ? moment.tz(payment.get('dueDate'), customerTZ())
                : payment.get('dueDate')
            }
            onChange={date =>
              handlePaymentChange({
                key: 'dueDate',
                value: date,
                paymentId: payment.id,
              })
            }
          />
        </Box>
        <Box width="35%">
          <Box
            sx={{
              ...styles.amountContainer,
              color: payment.isOutstanding ? 'var(--color-error-red)' : '',
            }}
          >
            <CurrencyTextField
              fullWidth
              classes={{ root: 'currency-text-field' }}
              disabled={disableAmountField}
              value={(payment.get('amount') - payment.get('paidAmount')) / 100}
              onChange={handleAmountChange}
            />
          </Box>
        </Box>
      </Box>
      {(hasEmptyDate || hasEmptyAmount) && (
        <Typography variant="error" color="error" className="error-info">
          {hasEmptyDate && !hasEmptyAmount && (
            <span>{t('.empty_date', intl, __filenamespace)}</span>
          )}
          {hasEmptyAmount && !hasEmptyDate && (
            <span>{t('.empty_amount', intl, __filenamespace)}</span>
          )}
          {hasEmptyDate && hasEmptyAmount && (
            <span>{t('.empty_all', intl, __filenamespace)}</span>
          )}
        </Typography>
      )}
      {payment.isCancelled ? (
        <Typography variant="body1">
          <Box
            sx={styles.cancelPaymentContainer}
            onClick={() => {
              handlePaymentChange({
                key: 'paymentStatus',
                value: 'active',
                paymentId: payment.id,
              });
            }}
          >
            <Box sx={styles.cancelPaymentLabel}>
              {t('.undo_cancellation', intl, __filenamespace)}
            </Box>
          </Box>
        </Typography>
      ) : (
        <Typography variant="body1">
          <Box
            sx={styles.cancelPaymentContainer}
            onClick={() => {
              handlePaymentChange({
                key: 'paymentStatus',
                value: 'cancelled',
                paymentId: payment.id,
              });
            }}
          >
            <CancelIcon sx={{ color: 'red' }} />
            <Box sx={styles.cancelPaymentLabel}>
              {t('.cancel_payment', intl, __filenamespace)}
            </Box>
          </Box>
        </Typography>
      )}
    </Box>
  );
}

function PaidScheduledPayment({ payment }) {
  return (
    <Box sx={styles.paidPaymentContainer}>
      <Box>
        <Typography variant="subtitle1">Paid</Typography>
      </Box>
      <Box sx={{ marginLeft: '1rem' }}>
        <Typography variant="body1">
          <FormattedMessage
            id={messageId('.paid_item', __filenamespace)}
            values={{
              date: moment(payment.get('dueDate')).format('MMM DD, YYYY'),
              amount: (
                <FormattedCurrency
                  value={payment.get('paidAmount')}
                  fromCents
                />
              ),
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}

function Content({
  client,
  productName,
  productType,
  balances,
  paidPayments,
  unpaidPayments,
  setUnpaidPayments,
  intl,
}) {
  return (
    <Box className="payment-plan-details-drawer__content">
      <ProductInfo
        productName={productName}
        productTypeName={productType?.name}
        color={productType?.color}
      />
      <Box sx={styles.clientContainer}>
        <ClientDetails client={client} />
      </Box>
      {unpaidPayments.map(payment => (
        <UnPaidScheduledPayment
          key={payment.id}
          payment={payment}
          unpaidPayments={unpaidPayments}
          setUnpaidPayments={setUnpaidPayments}
          balance={balances.find(b => b.get('productId') === payment.get('id'))}
          intl={intl}
        />
      ))}
      {paidPayments.map(payment => (
        <PaidScheduledPayment key={payment.id} payment={payment} />
      ))}
    </Box>
  );
}

function EditPaymentPlanDrawer({
  clientDataStore: { clients },
  paymentPlanDataStore: { records },
  paymentPlanDetailsDrawerStore: {
    isEditPaymentsModalOpen,
    paymentPlanId,
    scheduledPaymentIds,
    eventIds,
    eventTypeIds,
    balanceIds,
  },
  scheduledPaymentDataStore: { scheduledPayments: scheduledPaymentsData },
  eventDataStore: { events: eventsData },
  eventTypeDataStore: { eventTypes: eventTypesData },
  balancesDataStore: { balances: balancesData },

  intl,
}) {
  const paymentPlan = records.get(paymentPlanId);
  const client = clients.get(paymentPlan?.clientId);
  const scheduledPayments = scheduledPaymentIds.map(id =>
    scheduledPaymentsData.get(id)
  );
  const balances = balanceIds.map(compoundId => balancesData.get(compoundId));
  const events = eventIds.map(id => eventsData.get(id));
  const eventTypes = eventTypeIds.map(id => eventTypesData.get(id));
  const event = events?.first();
  const isTeamSchedule = event?.isTeamEvent();
  const productType = isTeamSchedule
    ? event.get('team_type')
    : eventTypes?.first();
  const productName = event?.title;
  const [paidPayments, setPaidPayments] = React.useState([]);
  const [unpaidPayments, setUnpaidPayments] = React.useState([]);
  const hasEmptyDate = unpaidPayments.some(item => !item.dueDate);
  const hasEmptyAmount = unpaidPayments.some(item => !item.amount);

  React.useEffect(() => {
    setUnpaidPayments(scheduledPayments.filter(pmt => !pmt.isCompleted));
    setPaidPayments(scheduledPayments.filter(pmt => pmt.isCompleted));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPaymentsModalOpen]);

  const handlePaymentsUpdate = () => {
    const params = unpaidPayments.map(payment => ({
      id: payment.id,
      payment_status: payment.paymentStatus,
      due_date: moment.tz(payment.dueDate, customerTZ()),
      amount: payment.amount,
    }));
    PaymentPlanDetailsDrawerActions.updateScheduledPayments({
      scheduled_payments: params,
    });
  };

  return (
    <Drawer
      classes={{
        root: 'payment-plan-details-drawer',
      }}
      title={t('.edit_payment_plan', intl, __filenamespace)}
      open={isEditPaymentsModalOpen}
      onClose={PaymentPlanDetailsDrawerActions.toggleEditPayments}
      content={
        <Content
          client={client}
          productType={productType}
          productName={productName}
          intl={intl}
          balances={balances}
          paidPayments={paidPayments}
          unpaidPayments={unpaidPayments}
          setUnpaidPayments={setUnpaidPayments}
        />
      }
      footer={
        <Box className="payment-plan-details-drawer__footer">
          <Button
            type="secondary"
            classes={{ root: 'action-btn' }}
            onClick={PaymentPlanDetailsDrawerActions.toggleEditPayments}
          >
            {t('.discard_cancellation', intl, __filenamespace)}
          </Button>
          <Button
            classes={{ root: 'action-btn' }}
            disabled={hasEmptyDate || hasEmptyAmount}
            onClick={handlePaymentsUpdate}
          >
            {t('.confirm_cancellation', intl, __filenamespace)}
          </Button>
        </Box>
      }
    />
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      paymentPlanDetailsDrawerStore: PaymentPlanDetailsDrawerStore,
      clientDataStore: ClientDataStore,
      paymentPlanDataStore: PaymentPlanDataStore,
      scheduledPaymentDataStore: ScheduledPaymentDataStore,
      eventDataStore: EventDataStore,
      eventTypeDataStore: EventTypeDataStore,
      balancesDataStore: BalancesDataStore,
    },
  })
)(EditPaymentPlanDrawer);
