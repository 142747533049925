import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { Email } from 'user_management/shared/components/UserProfileFields.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import ForgotPasswordActions from '../Actions';
import ForgotPasswordStore from '../Store';

const styles = {
  modalBackBtn: {
    marginTop: '110px',
    width: '145px',
    height: '45px',
  },
  backBtn: {
    color: grey[400],
    alignSelf: 'flex-start',
    fontWeight: 600,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: grey[400],
    },
  },
  resetBtn: modalOpen => ({
    marginTop: modalOpen ? '110px' : 0,
    minWidth: modalOpen ? '145px' : 'initial',
    height: modalOpen ? '45px' : 'initial',
  }),
  emailSent: {
    fontSize: 26,
    color: uhColors.darkGrey,
    paddingBottom: 46,
  },
  infoText: {
    paddingBottom: 30,
    width: 300,
    minWidth: 300,
    lineHeight: '150%',
  },
  tryAgain: {
    fontSize: '14px',
    paddingLeft: '5px',
    fontWeight: 600,
  },
  returnBtn: {
    height: '45px',
    width: '145px',
  },
};

function DesktopResetRequest({
  forgotPasswordStore: { email, errors, requestStatus },
  intl,
  modalOpen,
}) {
  return requestStatus !== 'success' ? (
    <Stack spacing={1} sx={{ width: '320px' }}>
      {modalOpen && (
        <div style={{ marginBottom: '46px' }}>
          <FormattedMessage
            style={{ fontSize: '12px' }}
            id={messageId('.forgot_password_info', __filenamespace)}
          />
        </div>
      )}
      <Email
        userProfile={{ email }}
        onChange={handleChange({ actions: ForgotPasswordActions })}
        errorText={errors.getErrors('email', intl)}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {modalOpen ? (
          <Button
            variant="contained"
            color="dark"
            sx={styles.modalBackBtn}
            onClick={ForgotPasswordActions.clickButton}
          >
            {t('.back_to_login', intl, __filenamespace)}
          </Button>
        ) : (
          <Link to="/accounts/login" style={{ textDecoration: 'none' }}>
            <Button sx={styles.backBtn}>
              {t('.back_to_login', intl, __filenamespace)}
            </Button>
          </Link>
        )}
        <StateChangingButton
          secondary
          label="Reset"
          labelInProgress="Sending request..."
          inProgress={requestStatus === 'sending'}
          disabled={requestStatus === 'sending' || !email || errors.hasErrors()}
          onClick={ForgotPasswordActions.sendResetRequest}
          type="submit"
          style={styles.resetBtn(modalOpen)}
        />
      </Stack>
    </Stack>
  ) : (
    <Stack alignItems="flex-start" justifyContent="center">
      <div style={styles.emailSent}>Email sent!</div>
      <div style={styles.infoText}>
        We sent an email to <span style={boldText}>{email}</span> so you can
        reset your password.
      </div>
      <Stack
        alignItems="baseline"
        direction="row"
        style={{ paddingBottom: 30 }}
      >
        <span>Not your email address?</span>
        {modalOpen && (
          <Button onClick={ForgotPasswordActions.tryAgain}>
            <Typography color="primary" sx={styles.tryAgain}>
              Try again
            </Typography>
          </Button>
        )}
        {!modalOpen && (
          <Link
            to="/accounts/forgot_password"
            onClick={ForgotPasswordActions.tryAgain}
          >
            <Typography color="primary" sx={styles.tryAgain}>
              Try again
            </Typography>
          </Link>
        )}
      </Stack>
      {modalOpen && (
        <Button
          sx={styles.returnBtn}
          onClick={() => {
            ForgotPasswordActions.clickButton();
            ForgotPasswordActions.tryAgain();
          }}
          color="dark"
          variant="contained"
        >
          {t('.login_link', intl, __filenamespace)}
        </Button>
      )}
      {!modalOpen && (
        <Button href="/" color="secondary" variant="contained">
          {t('.login_link', intl, __filenamespace)}
        </Button>
      )}
    </Stack>
  );
}

export default altContainer({
  stores: {
    forgotPasswordStore: ForgotPasswordStore,
  },
})(DesktopResetRequest);
