import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';

import { FlexBox } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function WidgetHeaderButton({ icon, onClick }) {
  return (
    <IconButton onClick={onClick}>
      <Icon baseClassName="material-icons">{icon}</Icon>
    </IconButton>
  );
}

function WidgetHeader({ style, title, actions }) {
  return (
    <FlexBox>
      <div
        style={merge(
          { flex: '1 1 auto', padding: '12px', fontSize: 16 },
          style
        )}
      >
        {title}
      </div>
      <div style={{ flex: '0 0 auto' }}>
        {actions
          ? actions.map(action => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <WidgetHeaderButton key={action.key || action.icon} {...action} />
            ))
          : null}
      </div>
    </FlexBox>
  );
}

function WidgetBody({ style, children }) {
  return (
    <div style={merge({ padding: '0 20px 20px 20px' }, style || {})}>
      {children}
    </div>
  );
}

function WidgetSpinner({ loadingMessage }) {
  return (
    <div
      style={{ textAlign: 'center', paddingTop: '2em', paddingBottom: '2em' }}
    >
      <CircularProgress size={45} />
      <p style={{ paddingTop: '1em' }}>
        <FormattedMessage
          id={loadingMessage || messageId('.loading', __filenamespace)}
        />
      </p>
    </div>
  );
}

function Widget({
  style,
  title,
  titleStyle,
  bodyStyle,
  isLoading,
  loadingMessage,
  actions,
  children,
}) {
  return (
    <Paper
      style={merge(
        { width: '100%', position: 'relative', margin: '20px 0px' },
        style
      )}
    >
      <WidgetHeader title={title} style={titleStyle} actions={actions} />
      <WidgetBody style={bodyStyle}>
        {isLoading ? (
          <WidgetSpinner loadingMessage={loadingMessage} />
        ) : (
          children
        )}
      </WidgetBody>
    </Paper>
  );
}

export { WidgetHeader, WidgetBody, WidgetSpinner, Widget };
