import { Record, Set, List } from 'immutable';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import EventType from 'event_mgmt/shared/records/EventType.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

export const MEMBERSHIP_CREDIT_TYPES = {
  ALL_EVENTS: 'all_events',
  EVENT_TYPES: 'event_types',
  EVENT_SPECIFIC: 'event_specific',
};

export const FREQUENCY_TYPES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

class MembershipEventCredit extends Record({
  all_events: true,
  id: null,
  rollover: false,
  errors: new FieldErrors(),
  event_ids: Set(),
  event_types: List(),
  event_type_ids: Set(),
  events: List(),
  frequency: FREQUENCY_TYPES.MONTH,
  interval: 0,
  membership: null,
  membership_id: null,
  quantity: 0,
  type: MEMBERSHIP_CREDIT_TYPES.ALL_EVENTS,
  unlimited: true,
  grant_ahead: 0,
  allow_future_scheduling: false,
}) {
  constructor(object = {}, _options = {}) {
    const eventIds = Set(object.event_ids);
    const eventTypeIds = Set(object.event_type_ids);

    const events = object.events
      ? List(object.events.map(e => new EventTranslator(e).toClient()))
      : List();

    const eventTypes = object.event_types
      ? List(object.event_types.map(et => new EventType(et)))
      : List();

    let type = MEMBERSHIP_CREDIT_TYPES.ALL_EVENTS;
    if (eventTypeIds.size) {
      type = MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES;
    } else if (eventIds.size) {
      type = MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC;
    }
    super(
      merge(object, {
        event_ids: eventIds,
        event_type_ids: eventTypeIds,
        events,
        event_types: eventTypes,
        type: object.type || type,
      })
    );
  }

  validate() {
    let errors = new FieldErrors();
    if (!this.type) {
      errors = errors.add(
        'all_events',
        'records.MembershipEventCredit.errors.one_type_should_be_selected'
      );
    }

    if (
      (this.all_events && !this.event_type_ids.isEmpty()) ||
      (this.all_events && !this.event_ids.isEmpty())
    ) {
      errors = errors.add(
        'all_events',
        'records.MembershipEventCredit.errors.no_event_types_or_events_when_all_events'
      );
    }

    if (
      this.type === MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES &&
      !this.event_type_ids.size
    ) {
      errors = errors.add(
        'event_types',
        'records.MembershipEventCredit.errors.at_least_one_event_type_should_be_selected'
      );
    }

    if (
      this.type === MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC &&
      !this.event_ids.size
    ) {
      errors = errors.add(
        'event_specific',
        'records.MembershipEventCredit.errors.at_least_one_event_should_be_selected'
      );
    }

    if (!this.unlimited && this.quantity <= 0) {
      errors = errors.add(
        'quantity',
        'records.MembershipEventCredit.errors.quantity_should_be_positive'
      );
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }
}

export default MembershipEventCredit;
