import { Record } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

class Transaction extends Record({
  id: null,
  clientId: null,
  paymentId: null,
  status: '',
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({
      ...parsedObject,
    });
  }
}

export default Transaction;
