import * as React from 'react';
import { injectIntl } from 'react-intl';

import Drawer from '@mui/material/Drawer';
import altContainer from 'shared/hocs/altContainer.jsx';

import ContactImportActions from 'contacts/index/actions/ContactImportActions.js';
import ContactImportDesktop from 'contacts/index/components/_ContactImportDesktop.jsx';
import ContactImportDesktopStore from 'contacts/index/stores/ContactImportDesktopStore.js';
import ContactImportNative from 'contacts/index/components/_ContactImportNative.jsx';
import ContactImportNativeStore from 'contacts/index/stores/ContactImportNativeStore.js';
import DrawerHeader from 'shared/components/DrawerHeader.jsx';
import Native from 'shared/native/Native';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { isNative } from 'shared/utils/UserAgentUtils';

function Content(props) {
  return isNative() && Native.hasCapability('contacts') ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ContactImportNative {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ContactImportDesktop {...props} />
  );
}

const ContactImportDrawer = injectIntl(
  ({
    contactImportNativeStore: { drawerTitle, onBack, drawerOpen: nativeOpen },
    contactImportDesktopStore: { drawerOpen: desktopOpen },
    intl,
    ...props
  }) => (
    <Drawer
      disableEnforceFocus
      open={desktopOpen || nativeOpen}
      anchor="right"
      PaperProps={{
        sx: {
          width: smallScreen()
            ? window.innerWidth * 0.9
            : STANDARD_DRAWER_WIDTH,
        },
      }}
    >
      <DrawerHeader
        title={drawerTitle || t('.import_contacts', intl, __filenamespace)}
        centerTitle={Native.hasCapability('contacts')}
        onBack={onBack}
        onClose={() => ContactImportActions.drawerClosed()}
      />
      <div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Content {...props} />
      </div>
    </Drawer>
  )
);

export default altContainer({
  stores: {
    contactImportNativeStore: ContactImportNativeStore,
    contactImportDesktopStore: ContactImportDesktopStore,
  },
})(ContactImportDrawer);
