import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function University({ color = '#9B9EAC', ...restProps }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 26 26" {...restProps}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7.65714V1.2C1 1.08954 1.08954 1 1.2 1H11.0857C11.1962 1 11.2857 1.08954 11.2857 1.2V7.65714C11.2857 7.7676 11.1962 7.85714 11.0857 7.85714H9.77143C9.66097 7.85714 9.57143 7.94669 9.57143 8.05714V13V17.9429C9.57143 18.0533 9.66097 18.1429 9.77143 18.1429H16.2286C16.339 18.1429 16.4286 18.0533 16.4286 17.9429V8.05714C16.4286 7.94669 16.339 7.85714 16.2286 7.85714H14.9143C14.8038 7.85714 14.7143 7.7676 14.7143 7.65714V1.2C14.7143 1.08954 14.8038 1 14.9143 1H24.8C24.9105 1 25 1.08954 25 1.2V7.65714C25 7.7676 24.9105 7.85714 24.8 7.85714H23.4857C23.3753 7.85714 23.2857 7.94669 23.2857 8.05714V19.7743C23.2857 19.8273 23.2646 19.8782 23.2271 19.9157L18.2014 24.9414C18.1639 24.9789 18.1131 25 18.06 25H7.93999C7.88694 25 7.83607 24.9789 7.79856 24.9414L2.77286 19.9157C2.73536 19.8782 2.71429 19.8273 2.71429 19.7743V8.05714C2.71429 7.94669 2.62474 7.85714 2.51429 7.85714H1.2C1.08954 7.85714 1 7.7676 1 7.65714Z"
          stroke={color}
        />
        <path
          d="M9.5 2.9998H3V5.9998C3.81098 6.08179 4.42815 6.76452 4.42815 7.57963V19.0013L8.71387 23.2871H17.2853L21.571 19.0013V7.85848V7.57318C21.571 6.78397 22.2108 6.14419 23 6.14419V2.9998H16.5V5.9998C17.4295 6.08151 18.1424 6.85991 18.1424 7.79297V7.85848V18.9998C18.1424 19.5521 17.6947 19.9998 17.1424 19.9998H8.98971C8.44146 19.9998 7.99544 19.5584 7.98977 19.0101L7.87378 7.79371C7.86416 6.8628 8.57261 6.08129 9.5 5.9998V2.9998Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
}

export default University;
