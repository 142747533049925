import PortalTabsActions from 'client_portal/actions/PortalTabsActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';

export const MY_PROFILE_TAB = 'my_profile';
export const BROWSE_EVENTS_TAB = 'browse_events';
export const TEAMS_TAB = 'teams';
export const MEMBERSHIPS_TAB = 'memberships';
export const PASSES_TAB = 'passes';
export const VISION_TAB = 'ar_vision';

export const TABS_EXPIRE_INTERVAL = 1;
export const TABS_EXPIRE_INTERVAL_UNIT = 'minute';

const paths = {
  [MY_PROFILE_TAB]: 'my_profile',
  [BROWSE_EVENTS_TAB]: 'events',
  [TEAMS_TAB]: 'teams',
  [MEMBERSHIPS_TAB]: 'memberships',
  [PASSES_TAB]: 'credit_passes',
  [VISION_TAB]: 'ar_vision',
};

class PortalTabsStore extends UpperHandStore {
  constructor() {
    super();

    this.activeTab = null;

    this.getTabFromPathname = () => {
      const currentPath = window.location.pathname.replace(
        customerScopedRoute(''),
        ''
      );
      return Object.keys(paths).find(key => paths[key] === currentPath);
    };

    this.bindListeners({
      changeTab: PortalTabsActions.TAB_SELECTED,
    });
  }

  changeTab(newTab) {
    this.activeTab = newTab;
    window.history.replaceState(null, '', customerScopedRoute(paths[newTab]));
  }
}

export default alt.createStore(PortalTabsStore, 'PortalTabsStore');
