import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';

function PayLaterMessage() {
  return (
    <div style={{ margin: '2rem 0 1rem', fontWeight: 'bold' }}>
      <FormattedMessage
        id={messageId('.payment_due_upon_arrival', __filenamespace)}
      />
    </div>
  );
}

export default PayLaterMessage;
