import RegistrationFormStoreInterface from 'shared/stores/RegistrationFormStoreInterface.jsx';
import RegistrationFormActions from 'shared/actions/RegistrationFormActions.jsx';
import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

class RegistrationFormStore extends RegistrationFormStoreInterface {
  constructor() {
    super();

    this.registrationFormActions = RegistrationFormActions;

    this.bindListeners({
      handleAnswer: RegistrationFormActions.ANSWER,
      handleStage: RegistrationFormActions.STAGE,
      handleStart: RegistrationFormActions.START,
      handleStop: RegistrationFormActions.STOP,
      handlePreviousRegistrant: RegistrationFormActions.PREVIOUS_REGISTRANT,
      handleNextRegistrant: RegistrationFormActions.NEXT_REGISTRANT,

      loadFieldData: RegistrationFieldActions.LIST_SUCCESS,

      listAnswers: RegistrationFormActions.LIST_ANSWERS,
      loadAnswerData: RegistrationFormActions.LIST_ANSWERS_SUCCESS,
      listAnswersError: RegistrationFormActions.LIST_ANSWERS_ERROR,

      handleComplete: RegistrationFormActions.SET_COMPLETED,
      handleCompleteSuccess: RegistrationFormActions.SET_COMPLETED_SUCCESS,
      handleCompleteError: RegistrationFormActions.SET_COMPLETED_ERROR,
    });
  }

  handleStage([eventId, clientIds, registrationId]) {
    this.defaulRegistrationId = currentUser().customer_user_id;

    super.handleStage([eventId, clientIds, registrationId]);
  }
}

export default alt.createStore(RegistrationFormStore, 'RegistrationFormStore');
