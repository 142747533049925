export default alt.generateActions(
  'cancellationAborted',
  'cancellationRequested',
  'cancellationConfirmed',
  'cancelMembershipError',
  'cancelMembershipSuccess',
  'cancelModeChanged',
  'listSubscriptionError',
  'listSubscriptionSuccess',
  'toggleUnscheduleRegistrations',
  'cancellationReasonChanged',
  'cancellationNotesChanged'
);
