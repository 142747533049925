export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Community Fieldhouse',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    'Welcome! The Community Fieldhouse is a sports, dining, and entertainment venue in Spring, TX. We appreciate you joining our community. Please log in or create an account to sign-up for our sports programming and events.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
};
