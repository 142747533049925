class MembershipManagementActions {
  constructor() {
    this.generateActions(
      'closeModal',
      'openModal',
      'updateForm',
      'listMembershipsSuccess',
      'listMembershipsError',
      'listMembershipTiersSuccess',
      'listMembershipTiersError',
      'sendManagementRequest',
      'sendManagementRequestSuccess',
      'sendManagementRequestError'
    );
  }
}

export default alt.createActions(MembershipManagementActions);
