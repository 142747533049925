import React from 'react';

import AgreementTermsBanner from 'shared/components/_AgreementTermsBanner.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import TermsTemplate from 'shared/components/_TermsTemplate.jsx';

function MembershipAgreement() {
  return (
    <div style={{ marginBottom: 54 }}>
      <FlexBoxJustifyCenter style={{ height: 116 }}>
        <img
          alt="logo"
          src={window.customer.logo.url}
          style={{ paddingTop: 25, paddingBottom: 25 }}
        />
      </FlexBoxJustifyCenter>
      <AgreementTermsBanner />
      <FlexBoxJustifyCenter
        style={{
          marginTop: '61px',
          overflowY: 'scroll',
        }}
      >
        <TermsTemplate terms={window.customer.membership_agreement} />
      </FlexBoxJustifyCenter>
    </div>
  );
}

export default MembershipAgreement;
