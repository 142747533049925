import { Iterable, List, Record, fromJS } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { v1 as uuidv1 } from 'uuid';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class AutomationTemplateDescription extends Record({
  id: null,
  cloneable: false,
  default_option_parameters: {},
  description: '',
  event_id: null,
  name: '',
  parent_id: null,
  supported_event_type: null,
  template_parameters: List(),
  template_type: null,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      obj = obj.toJS();
    }

    const template_parameters = fromJS(obj.template_parameters || []).map(p =>
      p.get('uuid') ? p : p.set('uuid', uuidv1())
    );

    const default_option_parameters = fromJS(
      obj.default_option_parameters || {}
    );

    const errors = new FieldErrors(obj.errors);

    super(
      merge(obj, {
        template_parameters,
        default_option_parameters,
        errors,
      })
    );
  }

  isBlank() {
    return (
      this.id === null &&
      this.event_id === null &&
      this.name === '' &&
      this.description === '' &&
      this.template_parameters.size === 0 &&
      this.parent_id === null
    );
  }

  clone() {
    return this.set('parent_id', this.id).set('id', null);
  }

  getOption(uuid) {
    return this.template_parameters.find(tp => tp.get('uuid') === uuid);
  }

  addOption(params) {
    if (!params) {
      params = this.default_option_parameters;
    }

    if (!Iterable.isIterable(params)) {
      params = fromJS(params);
    }

    return this.set(
      'template_parameters',
      this.template_parameters.push(params.update('uuid', v => v || uuidv1()))
    );
  }

  getMinimumPrice() {
    return -1;
  }

  hasOption(uuid) {
    return !!this.getOption(uuid);
  }

  isPackagePricing() {
    return this.template_type === 'PerSessionPricingTemplate';
  }

  isRecurringPaymentPlan() {
    return this.template_type === 'RecurringPaymentPlanTemplate';
  }

  get isPaymentPlan() {
    return this.name === 'Recurring Payments';
  }
}

AutomationTemplateDescription.fromServer = (obj = {}) => {
  return new AutomationTemplateDescription(obj, { fromServer: true });
};

export default AutomationTemplateDescription;
