import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Box } from '@mui/material';

import FilterDropdown from 'components/FilterDropdown.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';

import { Button, Drawer, Grid } from '@upperhand/playmaker';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import BrowseTeamsActions from '../Actions';

const styles = {
  root: {
    padding: '16px',
    width: '100%',
  },
  searchBardivStyles: {
    display: 'flex',
    backgroundColor: uhColors.tunaBlack,
    alignItems: 'center',
    marginBottom: '20px',
    height: '100px',
  },
  searchbarIconStyles: {
    display: 'none',
  },
  searchbarStyles: {
    padding: '0 20px',
    fontSize: 16,
    backgroundColor: uhColors.tunaBlack,
  },
  searchbarInputStyles: {
    fontWeight: 'bold',
    color: '#FFF',
  },
};

const BrowseTeamsCompact = React.memo(
  ({
    children,
    teamItems,
    teamTypeFilter,
    intl,
    isFiltersDrawerOpen,
    staffItems,
    staffFilter,
  }) => {
    const [showSearch, setShowSearch] = React.useState(false);
    return (
      <Box sx={styles.root}>
        {showSearch && (
          <Box sx={styles.searchBardivStyles}>
            <Button
              icon="close"
              onClick={() => {
                BrowseTeamsActions.searchFilterUpdated('');
                setShowSearch(false);
              }}
              className="browse-teams__disable-btn"
            />
            <SearchBar
              onTypingEnd={value =>
                BrowseTeamsActions.searchFilterUpdated(value)
              }
              iconStyle={styles.searchbarIconStyles}
              style={styles.searchbarStyles}
              inputStyle={styles.searchbarInputStyles}
            />
          </Box>
        )}
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid container item spacing={1} xs={12}>
            <Grid item xs>
              {showSearch ? (
                <Button
                  onClick={() => setShowSearch(true)}
                  type="tertiary"
                  rounded
                  icon="search"
                />
              ) : (
                <Button
                  onClick={() => setShowSearch(true)}
                  rounded
                  icon="search"
                  className="browse-teams__disable-btn"
                />
              )}
            </Grid>
            <Grid item>
              <Button
                onClick={BrowseTeamsActions.openFiltersDrawer}
                type="tertiary"
                rounded
                icon="filter"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            {children}
          </Grid>
        </Grid>
        <Drawer
          className="filter-drawer"
          open={isFiltersDrawerOpen}
          title={t('.filters', intl, __filenamespace)}
          onClose={BrowseTeamsActions.closeFiltersDrawer}
          content={
            <Grid container xs={12} spacing={1} alignItems="center">
              <Grid item xs={12}>
                <FilterDropdown
                  mobile
                  items={teamItems}
                  filterValue={teamTypeFilter}
                  name="team type"
                  onChange={BrowseTeamsActions.teamTypeFilterUpdated}
                />
              </Grid>
              <Grid item xs={12}>
                <FilterDropdown
                  items={staffItems}
                  filterValue={staffFilter}
                  mobile
                  name="staff"
                  onChange={BrowseTeamsActions.staffFilterUpdated}
                />
              </Grid>
            </Grid>
          }
        />
      </Box>
    );
  }
);

export default injectIntl(BrowseTeamsCompact);
