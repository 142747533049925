import { Iterable, Record } from 'immutable';

class StripeAddress extends Record({
  line1: '',
  line2: '',
  line3: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      obj = obj.toJS();
    }

    super({ ...obj });
  }

  isBlank() {
    return (
      !this.line1 &&
      !this.line2 &&
      !this.line3 &&
      !this.city &&
      !this.state &&
      !this.postal_code
    );
  }

  isValid() {
    return this.line1 && this.city && this.state && this.postal_code;
  }
}

export default StripeAddress;
