import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { ClientActions } from 'sources';

class ClientDataStore extends UpperHandStore {
  constructor() {
    super();

    this.clients = Map();

    this.bindListeners({
      fetchSuccess: [ClientActions.fetchSuccess],
      fetchError: [ClientActions.fetchError],
      listSuccess: [ClientActions.listSuccess],
      listError: [ClientActions.listError],
      updateSuccess: [ClientActions.updateSuccess],
      updateError: [ClientActions.updateError],
      deleteSuccess: [ClientActions.deleteSuccess],
      deleteError: [ClientActions.deleteError],
    });
  }

  fetchSuccess(client) {
    this.clients = this.clients.set(client.id, client);
  }

  fetchError(...args) {
    this.notifyError('error client fetching', args);
  }

  listSuccess({ clients }) {
    const fetchedClients = clients.groupBy(c => c.id).map(c => c.first());

    this.clients = this.clients.merge(fetchedClients);
  }

  listError(...args) {
    this.notifyError('error listing clients', args);
  }

  updateError(...args) {
    this.notifyError('error client updating', args);
  }

  updateSuccess(client) {
    if (client.type !== 'Client') {
      // if client has changed role types, we need to remove them from this datastore, the staff store will handle adding it
      this.clients = this.clients.delete(client.id);
      return;
    }

    this.clients = this.clients.set(client.id, client);
  }

  deleteSuccess({ clientId, documentId }) {
    const client = this.clients.get(clientId);
    if (!client) return;
    this.clients = this.clients.set(
      clientId,
      client.set(
        'documents',
        client.get('documents').filter(doc => doc.get('id') !== documentId)
      )
    );
  }

  deleteError() {
    this.notifyError('error in deleting document');
  }
}

export default alt.createStore(ClientDataStore, 'ClientDataStore');
