import React from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AutoCompleteStaffListActions from 'shared/actions/AutoCompleteStaffListActions';
import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import UsersAutoComplete from 'reporting/payroll/components/UsersAutoComplete.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  TimeInputLeft: {
    flex: 1,
    marginRight: 10,
  },
  TimeInputRight: {
    flex: 1,
    marginLeft: 10,
  },
  TextInputField: {
    width: '100%',
  },
  UnderlineStyle: {
    borderBottomWidth: 2,
  },
  SolidUnderlineStyle: {
    borderBottom: '2px solid var(--color-divide)',
    borderColor: 'var(--color-divide)',
  },
  TimeInputDialog: {
    minHeight: '380px',
  },
};

const onSearchStaff = searchText =>
  AutoCompleteStaffListActions.searchStringUpdated(searchText);

const onCloseAutocomplete = (selectedStaff, searchString) =>
  AutoCompleteStaffListActions.searchStringUpdated(
    selectedStaff ? selectedStaff.name() : searchString
  );

function TimeLogModal({
  actions: { closeModal, confirm, setStaff, update, updateDate },
  open,
  editing,
  formattedStaff,
  timeLog,
  staffMember,
  tasks,
  locations,
  staffSearchString,
  intl,
}) {
  const getDatePickerParams = (params, placeholder) => ({
    ...params,
    ...{
      fullWidth: true,
      error: !!timeLog.errors.getErrors('start_time', intl),
      helperText: timeLog.errors.getErrors('start_time', intl),
      inputProps: {
        ...params.inputProps,
        placeholder,
      },
      InputLabelProps: { shrink: true },
    },
  });

  const getTimePickerParams = (params, name) => ({
    ...params,
    ...{
      fullWidth: true,
      error: !!timeLog.errors.getErrors(name, intl),
      helperText: timeLog.errors.getErrors(name, intl),
      InputLabelProps: { shrink: true },
    },
  });

  React.useEffect(() => {
    if (staffMember && open) {
      AutoCompleteStaffListActions.searchStringUpdated(staffMember.name());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Dialog disableEnforceFocus open={open} onClose={closeModal}>
      <DialogTitle>
        {t(`${editing ? '.edit_entry' : '.add_entry'}`, intl, __filenamespace)}
      </DialogTitle>
      <DialogContent>
        {open && (
          <>
            <Stack spacing={1}>
              <UsersAutoComplete
                withAvatar
                dataSource={formattedStaff}
                selectedItem={staffMember}
                label={t('.search_staff', intl, __filenamespace)}
                onItemSelect={setStaff}
                onChange={onSearchStaff}
                onLeave={() =>
                  onCloseAutocomplete(staffMember, staffSearchString)
                }
                helperText={timeLog.errors.getErrors('customer_user_id', intl)}
                searchText={staffSearchString}
              />
              <FormControl fullWidth>
                <DatePicker
                  name="sendDate"
                  value={timeLog.start_time || ''}
                  label={t('.date', intl, __filenamespace)}
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getDatePickerParams(
                        params,
                        t('.select_date', intl, __filenamespace)
                      )}
                    />
                  )}
                  DialogProps={{ disableEnforceFocus: true }}
                  onChange={date => updateDate(date)}
                />
              </FormControl>
              <FormControl
                fullWidth
                disabled={!timeLog.start_time}
                error={!!timeLog.errors.getErrors('task_id', intl)}
              >
                <InputLabel shrink>
                  {t('.task', intl, __filenamespace)}
                </InputLabel>
                <Select
                  notched
                  displayEmpty
                  label={t('.task', intl, __filenamespace)}
                  value={timeLog.task_id || ''}
                  onChange={e => update('task_id', e.target.value)}
                >
                  <MenuItem disabled value="">
                    <span
                      style={{
                        opacity: timeLog.start_time ? 0.4 : 1,
                      }}
                    >
                      {t('.choose_task', intl, __filenamespace)}
                    </span>
                  </MenuItem>
                  {tasks.valueSeq().map(task => (
                    <MenuItem key={task.id} value={task.id}>
                      {task.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {timeLog.errors.getErrors('task_id', intl)}
                </FormHelperText>
              </FormControl>

              <FormControl
                fullWidth
                disabled={!timeLog.start_time}
                error={!!timeLog.errors.getErrors('location_id', intl)}
              >
                <InputLabel shrink>
                  {t('.location', intl, __filenamespace)}
                </InputLabel>
                <Select
                  notched
                  displayEmpty
                  label={t('.location', intl, __filenamespace)}
                  value={timeLog.location_id || ''}
                  onChange={e => update('location_id', e.target.value)}
                >
                  <MenuItem disabled value="">
                    <span
                      style={{
                        opacity: timeLog.start_time ? 0.4 : 1,
                      }}
                    >
                      {t('.choose_location', intl, __filenamespace)}
                    </span>
                  </MenuItem>
                  {locations.map(l => (
                    <MenuItem key={l.id} value={l.id}>
                      {l.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {timeLog.errors.getErrors('location_id', intl)}
                </FormHelperText>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={1} style={{ marginTop: 8 }}>
              <TimePicker
                name="clock_in"
                label={t('.clock_in', intl, __filenamespace)}
                value={timeLog.start_time || ''}
                InputLabelProps={{ shrink: true }}
                renderInput={params => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField {...getTimePickerParams(params, 'start_time')} />
                )}
                DialogProps={{ disableEnforceFocus: true }}
                onChange={date => update('start_time', date)}
              />
              <TimePicker
                name="clock_out"
                label={t('.clock_out', intl, __filenamespace)}
                value={timeLog.end_time || ''}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={params => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField {...getTimePickerParams(params, 'end_time')} />
                )}
                DialogProps={{ disableEnforceFocus: true }}
                onChange={date => update('end_time', date)}
              />
            </Stack>
            <Stack direction="row" spacing={1} style={{ marginTop: 8 }}>
              <CurrencyTextField
                name="rate"
                value={timeLog.hourly_rate}
                onChange={(_, value) => update('hourly_rate', value)}
                converter={e => parseInt(e, 10)}
                floatingLabelText={t('.hourly_rate', intl, __filenamespace)}
                symbolStyle={styles.currencyIcon}
                textFieldStyle={styles.TextInputField}
              />
              <TextField
                name="total"
                disabled
                value={timeLog && timeLog.getDuration()}
                label={t('.total', intl, __filenamespace)}
                min={0.0}
                style={styles.TextInputField}
              />
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          {t('.cancel', intl, __filenamespace)}
        </Button>
        <Button variant="contained" onClick={confirm}>
          {t(`${editing ? '.save' : '.add'}`, intl, __filenamespace)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TimeLogModal;
