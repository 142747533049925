import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import { ChromePicker } from 'react-color';

import { boldText } from 'shared/styles/uhStyles.jsx';
import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const swatchColors = [
  ['#E91E63'],
  ['#9C27B0'],
  ['#673AB7'],
  ['#2196F3'],
  ['#00BCD4'],
  ['#009688'],
  ['#8BC34A'],
  ['#FFEB3B'],
  ['#FF9800'],
  ['#FF5722'],
  ['#4565d9'],
  ['#303F9F'],
];

const closePopup = () => MembershipEditingActions.clearUI('colorPicker');
const openPopup = e =>
  MembershipEditingActions.updateUI({ colorPicker: e.currentTarget });

function ColorSelector({ ui, color, onChange }) {
  return (
    <FlexBox style={{ flexDirection: 'column', paddingTop: 12 }}>
      <span style={boldText}>
        <FormattedMessage id={messageId('.badge_color', __filenamespace)} />
      </span>
      <FlexBoxCenter style={{ paddingTop: 19, paddingBottom: 19 }}>
        <UserAvatar
          user={{
            alternativeImage: () => null,
            first_name: 'U',
            type: 'Client',
          }}
          membershipColor={color}
        />
        <FlexBoxCenter
          onClick={e => openPopup(e)}
          style={{ cursor: 'pointer' }}
        >
          <Paper
            style={{
              backgroundColor: color,
              width: 32,
              height: 32,
              marginRight: 13,
            }}
          />
          <FormattedMessage id={messageId('.select_color', __filenamespace)} />
        </FlexBoxCenter>
      </FlexBoxCenter>
      <Popover
        anchorEl={ui.colorPicker}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={closePopup}
        open={!!ui.colorPicker}
      >
        <ChromePicker
          disableAlpha
          color={color}
          colors={swatchColors}
          height={100}
          onChangeComplete={pickerColor => {
            onChange(null, pickerColor.hex, 'color');
          }}
        />
      </Popover>
      <Divider />
    </FlexBox>
  );
}

export default ColorSelector;
