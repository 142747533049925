import * as React from 'react';
import OnlineInStoreTable from 'reporting/sales_detail/components/tables/OnlineInStoreTable.jsx';
import PaymentTypeTable from 'reporting/sales_detail/components/tables/PaymentTypeTable.jsx';
import ProductTypeTable from 'reporting/sales_detail/components/tables/ProductTypeTable.jsx';
import RetailCategoryTable from 'reporting/sales_detail/components/tables/RetailCategoryTable.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

function SalesTables({
  productTypeSales,
  productTypeTotals,
  retailCategorySales,
  retailCategoryTotals,
  paymentTypeSales,
  paymentTypeTotals,
  saleTypeSales,
  saleTypeTotals,
}) {
  return (
    <div>
      <PaymentTypeTable
        paymentTypeSales={paymentTypeSales}
        paymentTypeTotals={paymentTypeTotals}
      />
      <ProductTypeTable
        productTypeSales={productTypeSales}
        productTypeTotals={productTypeTotals}
      />
      {currentCustomer().retail_enabled && (
        <RetailCategoryTable
          retailCategorySales={retailCategorySales}
          retailCategoryTotals={retailCategoryTotals}
        />
      )}
      <OnlineInStoreTable
        saleTypeSales={saleTypeSales}
        saleTypeTotals={saleTypeTotals}
      />
    </div>
  );
}

export default SalesTables;
