import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import ClearIcon from '@mui/icons-material/Clear';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

import OrderReportActions from 'containers/reports/ordersReport/Actions';
import {
  Filters,
  FilterPeriod,
  FilterPaymentMethod,
  FilterOrderMethod,
} from 'containers/reports/types';
import { FlexBox } from 'shared/components/FlexBox.jsx';

import { t } from 'shared/utils/LocaleUtils';

function ActiveFilters({
  activeFilters,
  defaultFilters,
  intl,
  onRemoveFilterChips,
}) {
  const getFilterButton = (key, value) => {
    let label = null;
    if (key === Filters.PAYMENT_METHOD && value === FilterPaymentMethod.ALL) {
      return null;
    }

    if (key === Filters.ORDER_METHOD && value === FilterOrderMethod.ALL) {
      return null;
    }

    if (key === Filters.PERIOD) {
      label =
        value.value === FilterPeriod.today ||
        value.value === FilterPeriod.yesterday
          ? (label = value.from.format('MM/DD/YYYY'))
          : (label = `
        ${value.from.format('MM/DD/YYYY')}
        -
        ${value.to.format('MM/DD/YYYY')}
      `);
    }

    if (key === Filters.SEARCH) {
      if (value === '') {
        return null;
      }
      label = value;
    }

    return (
      <button
        key={key}
        type="button"
        className="filter-chip"
        onClick={() => onRemoveFilterChips(key)}
      >
        <Typography>
          {label || t(`.${value}`, intl, __filenamespace)}
        </Typography>
        <ClearIcon />
      </button>
    );
  };

  const hideCleanBtn = activeFilters.equals(
    Map({
      period: defaultFilters.get(Filters.PERIOD),
    })
  );

  return (
    <Grid
      container
      item
      spacing={1}
      justify="space-between"
      xs={12}
      className="active-filters"
    >
      <Grid item justify="flex-start" xs>
        <FlexBox>
          {activeFilters.entrySeq().map(([key, value]) =>
            // we need to add icon button to Playmaker to handle click on X icon
            getFilterButton(key, value)
          )}
        </FlexBox>
      </Grid>
      {!hideCleanBtn && (
        <Grid item justify="flex-end">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            onClick={OrderReportActions.clearFilters}
            className="active-filters__link"
          >
            <Typography variant="body2">
              {t('.clear', intl, __filenamespace)}
            </Typography>
          </a>
        </Grid>
      )}
    </Grid>
  );
}

ActiveFilters.propTypes = {
  activeFilters: PropTypes.object,
  defaultFilters: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onRemoveFilterChips: PropTypes.func.isRequired,
};

ActiveFilters.defaultProps = {
  activeFilters: {},
  defaultFilters: {},
};

export default injectIntl(ActiveFilters);
