import { List } from 'immutable';
import ScheduledPayment from 'records/ScheduledPayment';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';
import DataStoreActions from './ScheduledPaymentActions';

const parserTo = {
  type: ScheduledPayment,
  paginationKey: 'scheduled_payments',
};

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const put = ({ params, success, error }) => {
  uhApiClient.put({
    url: 'scheduled_payments/bulk_update',
    data: JSON.stringify(params),
    success: onSuccess(data => data, success),
    error,
  });
};
