// This component is outdated and should be changed to be fully controlled and use Playmaker
import * as React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { blue, grey } from '@mui/material/colors';
import { byFields } from 'shared/utils/ImmutableUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  searchIcon: {
    height: 20,
    marginRight: 13,
    width: 17,
  },

  searchTitleHint: {
    fontSize: 18,
    color: '#9A9EAD',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 'normal',
  },

  searchTitleInput: {
    fontSize: 18,
    color: uhColors.leftNavGrey,
  },
};

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.typingTimeout = null;
    this.state = {
      textFieldFocused: false,
      searchText: props.searchText || '',
    };
  }

  componentDidUpdate(prevProps) {
    const { searchText } = this.state;
    const { searchText: prevSearchText, data: prevData } = prevProps;
    const { searchText: nextSearchText, data: nextData, onSearch } = this.props;

    if (
      nextSearchText !== undefined &&
      nextSearchText !== prevSearchText &&
      searchText !== nextSearchText
    ) {
      this.setState({ searchText: nextSearchText });
    }

    if (prevData !== nextData && !!searchText) {
      onSearch(this.filteredData(searchText, nextData), searchText);
    }
  }

  handleChange(e) {
    const { onSearch, onTypingEnd, timeout } = this.props;

    let onTimeout;

    this.setState({ searchText: e.target.value });

    if (onTypingEnd) {
      onTimeout = () => onTypingEnd(e.target.value);
    } else if (onSearch) {
      onTimeout = () =>
        onSearch(this.filteredData(e.target.value), e.target.value);
    }

    clearTimeout(this.typingTimeout);

    if (onTimeout) {
      this.typingTimeout = setTimeout(onTimeout, timeout);
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  filteredData(queryString, data = this.props.data) {
    if (!queryString) {
      return data;
    }

    const { fields } = this.props;

    return data.filter(byFields(queryString, fields));
  }

  render() {
    const {
      hintText,
      underlineShow,
      autoComplete,
      autoFocus,
      className,
      data,
      fields,
      hintStyle,
      iconStyle,
      inputStyle,
      onSearch,
      onTypingEnd,
      searchText,
      style,
      timeout,
      onBlur,
      useStartAdornment,
      startAdornment,
      endAdornment,
      ...props
    } = this.props;

    const { fontSize = 18, ...rootStyle } = style;
    const { textFieldFocused, searchText: stateSearchText } = this.state;
    const iconColor = textFieldFocused ? blue[600] : grey[600];
    const placeholderStyles = merge(
      styles.searchTitleHint,
      { fontSize },
      hintStyle
    );

    return (
      <FlexBoxCenter className={className} style={rootStyle}>
        {!useStartAdornment && (
          <SearchIcon
            style={merge(styles.searchIcon, iconStyle, { color: iconColor })}
          />
        )}
        <TextField
          fullWidth
          placeholder={hintText}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          value={stateSearchText}
          sx={{
            input: { '&::placeholder': placeholderStyles },
          }}
          inputProps={{
            style: merge(styles.searchTitleInput, { fontSize }, inputStyle),
            onBlur: () => {
              this.setState({ textFieldFocused: false });
              onBlur();
            },
            onFocus: () => this.setState({ textFieldFocused: true }),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            disableUnderline: !underlineShow,
            startAdornment:
              useStartAdornment && startAdornment ? startAdornment : null,
            endAdornment: endAdornment || null,
          }}
          onChange={this.handleChange}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </FlexBoxCenter>
    );
  }
}

SearchBar.propTypes = {
  /**
   * The input autoComplete type. Defaults to 'on'.
   */
  autoComplete: PropTypes.string,

  /**
   * CSS class
   */
  className: PropTypes.string,

  /**
   * Immutable.List of source data to filter. Elements should be Immutable.Maps.
   */
  data: PropTypes.instanceOf(List),

  /**
   * List of data object properties to filter on.
   */
  fields: PropTypes.instanceOf(List),

  /**
   * Text to be shown when SearchBar is empty. Defaults to Search.
   */
  hintText: PropTypes.string,

  /**
   * Type of input. Defaults to standard.
   */
  variant: PropTypes.string,

  /**
   * Override the inline-styles of the hint text element.
   */
  hintStyle: PropTypes.object,

  /**
   * Override the inline-styles of the input element.
   */
  inputStyle: PropTypes.object,

  /**
   * Callback function fired when the data has been filtered. Only fired when
   * onTypingEnd is not present.
   *
   * @param {List} results The subset of data that matches the query string.
   * @param {string} queryString The String the user entered in the text field.
   */
  onSearch: PropTypes.func,

  /**
   * Function that fires on input blur event.
   */
  onBlur: PropTypes.func,

  /**
   * Callback function fired when the user stops typing. When present onSearch
   * does not fire.
   *
   * @param {string} queryString The String the user entered in the text field.
   */
  onTypingEnd: PropTypes.func,

  /**
   * Initial value for the searchString.
   */
  searchText: PropTypes.string,

  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.object,

  /**
   * Milliseconds before filter/search applied.
   */
  timeout: PropTypes.number,

  /**
   * Show underline.
   */
  underlineShow: PropTypes.bool,

  /** Input icon */
  startAdornment: PropTypes.node,

  /** Use start adornment */
  useStartAdornment: PropTypes.bool,

  /** Icon style */
  endAdornment: PropTypes.node,
};

SearchBar.defaultProps = {
  variant: 'standard',
  autoComplete: 'on',
  className: 'search-bar',
  data: List(),
  fields: List(),
  hintText: 'Search',
  hintStyle: {},
  inputStyle: {},
  onSearch: null,
  onTypingEnd: null,
  onBlur: () => null,
  searchText: undefined,
  style: {},
  timeout: 600,
  underlineShow: false,
  startAdornment: <SearchIcon />,
  useStartAdornment: false,
  endAdornment: null,
};

export default SearchBar;
