import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    width: 11,
    height: 15,
  },
};

function UnlockIcon({ style, color, ...props }) {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...merge(props, { style: merge(styles.root, style) })}
      viewBox="0 0 11 15"
    >
      <title>Unlocked</title>
      <g
        id="Memberships"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Memberships-Page---Invite-Only-(Client)"
          transform="translate(-769.000000, -477.000000)"
          fill={color}
        >
          <g id="Group" transform="translate(711.000000, 159.000000)">
            <path
              d="M67.4325,325.717143 C67.6706956,325.717143 67.8731589,325.80051 68.0398958,325.967247 C68.2066327,326.133984 68.29,326.336447 68.29,326.574643 L68.29,331.719643 C68.29,331.957838 68.2066327,332.160302 68.0398958,332.327039 C67.8731589,332.493776 67.6706956,332.577143 67.4325,332.577143 L58.8575,332.577143 C58.6193044,332.577143 58.4168411,332.493776 58.2501042,332.327039 C58.0833672,332.160302 58,331.957838 58,331.719643 L58,326.574643 C58,326.336447 58.0833672,326.133984 58.2501042,325.967247 C58.4168411,325.80051 58.6193044,325.717143 58.8575,325.717143 L59.1433333,325.717143 L59.1433333,322.85881 C59.1433333,321.757155 59.5348615,320.814807 60.3179297,320.031739 C61.1009978,319.248671 62.0433452,318.857143 63.145,318.857143 C64.2466548,318.857143 65.1890021,319.248671 65.9720703,320.031739 C66.7551384,320.814807 67.1466666,321.757155 67.1466666,322.85881 C67.1466666,323.013637 67.090096,323.14762 66.9769531,323.260763 C66.8638102,323.373906 66.7298271,323.430476 66.575,323.430476 L66.0033333,323.430476 C65.8485061,323.430476 65.7145231,323.373906 65.6013802,323.260763 C65.4882373,323.14762 65.4316666,323.013637 65.4316666,322.85881 C65.4316666,322.227591 65.2083616,321.688682 64.7617448,321.242065 C64.315128,320.795448 63.7762184,320.572143 63.145,320.572143 C62.5137815,320.572143 61.974872,320.795448 61.5282552,321.242065 C61.0816384,321.688682 60.8583333,322.227591 60.8583333,322.85881 L60.8583333,325.717143 L67.4325,325.717143 Z"
              id="Unlocked-Icon"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

UnlockIcon.defaultProps = {
  color: 'var(--color-white)',
};

export default UnlockIcon;
