import ClientStoreInterface from 'shared/stores/ClientStoreInterface.jsx';
import ManagedClientActions from 'shared/actions/ManagedClientActions.jsx';

class ManagedClientStore extends ClientStoreInterface {
  constructor() {
    super();

    this.actions = ManagedClientActions;

    this.bindListeners({
      handleCreate: ManagedClientActions.create,
      handleCreateSuccess: ManagedClientActions.createSuccess,
      handleCreateError: ManagedClientActions.createError,

      handleUpdateStore: ManagedClientActions.updateStore,
      validateUser: ManagedClientActions.validateUser,
    });
  }
}

export default alt.createStore(ManagedClientStore, 'ManagedClientStore');
