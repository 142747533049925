import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { StaffActions } from 'sources';

class StaffDataStore extends UpperHandStore {
  constructor() {
    super();

    this.staff = Map();

    this.bindListeners({
      listSuccess: [StaffActions.listSuccess],
      listError: [StaffActions.listError],
      updateError: [StaffActions.updateError],
      updateSuccess: [StaffActions.updateSuccess],
    });
  }

  listSuccess({ staff }) {
    const fetchedStaff = staff.groupBy(s => s.id).map(s => s.first());
    this.staff = this.staff.merge(fetchedStaff);
  }

  listError(...args) {
    this.notifyError('error listing staff', args);
  }

  updateError(...args) {
    this.notifyError('error updating staff', args);
  }

  updateSuccess(staff) {
    if (!(staff.type === 'StaffMember' || staff.type === 'StaffAdmin')) {
      // if client has changed role types, we need to remove them from this datastore, the staff store will handle adding it
      this.staff = this.staff.delete(staff.id);
      return;
    }

    this.staff = this.staff.set(staff.id, staff);
  }
}

export default alt.createStore(StaffDataStore, 'StaffDataStore');
