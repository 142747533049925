class CancelSessionActions {
  constructor() {
    this.generateActions(
      'initCancellation',
      'reset',
      'sessionCanceled',
      'sessionCanceledError',
      'sessionCanceledSuccess',
      'setCreditOperation',
      'setWaive',
      'toggleNotice',
      'updateNote'
    );
  }
}

export default alt.createActions(CancelSessionActions);
