import CommissionRateEditingActions from 'contacts/shared/actions/commission_rates/CommissionRateEditingActions';
import CommissionRateEditingStoreInterface from 'contacts/shared/stores/commission_rates/CommissionRateEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  CommissionRateEditingStoreInterface,
  'CommissionRateEditingStore',
  {
    actions: CommissionRateEditingActions,
    successMessage: new TranslatableMessage({
      id: '.updated',
      filename: __filenamespace,
    }),
  }
);
