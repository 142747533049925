import TaxRate from 'shared/records/TaxRate';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = taxRate => (taxRate.id ? `tax_rates/${taxRate.id}` : 'tax_rates');

export default class TaxRateEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();

    this.taxRate = null;
    this.isSaving = false;

    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      updateTaxRate: this.actions.UPDATED,

      beginEdit: this.actions.BEGIN,
      cancelEdit: this.actions.CANCEL,

      createOrUpdate: this.actions.SAVE,
      createOrUpdateSuccess: this.actions.SAVE_SUCCESS,
      createOrUpdateError: this.actions.SAVE_ERROR,
    });
  }

  updateTaxRate(updatedFields) {
    if (!this.taxRate) {
      return false;
    }

    this.taxRate = this.taxRate.merge(updatedFields);
  }

  beginEdit(taxRate) {
    this.taxRate = taxRate || new TaxRate();
  }

  cancelEdit() {
    this.taxRate = null;
  }

  createOrUpdate() {
    if (this.isSaving) {
      return false;
    }

    this.taxRate = this.taxRate.validate();

    if (this.taxRate.isValid()) {
      this.isSaving = true;

      const saveValue = {
        url: url(this.taxRate),
        data: JSON.stringify({ attributes: this.taxRate.toServer() }),
        success: this.actions.saveSuccess,
        error: this.actions.saveError,
      };

      if (this.taxRate.id) {
        uhApiClient.put(saveValue);
      } else {
        uhApiClient.post(saveValue);
      }
    }
  }

  createOrUpdateSuccess(_data) {
    this.taxRate = null;
    this.isSaving = false;
    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError() {
    this.isSaving = false;
    this.notifyError('error updating accountingCode', arguments);
  }
}
