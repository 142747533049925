import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Grid, TextField, Typography } from '@upperhand/playmaker';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Coupon from 'records/Coupon';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';

import { t } from 'shared/utils/LocaleUtils';

function Header({ actions, coupon, createMode, editMode, errors, intl }) {
  return (
    <Grid container item className="coupon__header">
      <Grid container item xs={6}>
        {editMode && (
          <Grid item xs={12}>
            <Typography className="coupon__input-label" variant="fieldLabel">
              {t('.name', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={`coupon__${editMode ? 'input-area' : 'label-area'}`}
        >
          {editMode ? (
            <TextField
              errorText={errors.getErrors(['name'])}
              fullWidth
              icon="create"
              name="name"
              onChange={e => actions.updateField('name', e.target.value)}
              outline={false}
              placeholder={t('.name_placeholder', intl, __filenamespace)}
              transparent
              value={coupon.get('name')}
            />
          ) : (
            <>
              <Button
                type="tertiary"
                disableRipple
                classes={{ root: 'coupon__back-btn' }}
                icon="arrowLeft"
                onClick={actions.back}
              />
              <Typography
                variant="h4"
                className={clsx(
                  'coupon__name',
                  smallScreen() && 'coupon__mobile-name'
                )}
              >
                {coupon ? coupon.get('name') : 'new'}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        justify="flex-end"
        alignItems="center"
        spacing={1}
      >
        {editMode ? (
          <>
            <Button
              onClick={createMode ? actions.cancelCreate : actions.cancelUpdate}
              classes={{
                root: 'coupon__cancel-button',
              }}
              type="secondary"
            >
              {t('.cancel', intl, __filenamespace)}
            </Button>
            <Button
              onClick={actions.save}
              classes={{
                root: 'coupon__create-button',
              }}
              type="primary"
            >
              {t('.save', intl, __filenamespace)}
            </Button>
          </>
        ) : (
          <>
            <Grid item>
              <div
                className="coupon__link"
                onClick={e => {
                  redirectTo({
                    path: customerScopedRoute(
                      `/coupons/${coupon.get('id')}/edit`
                    ),
                  });
                  e.preventDefault();
                }}
                role="presentation"
              >
                <EditIcon
                  style={{ width: 20, height: 20, color: uhColors.pmIconGray }}
                />
              </div>
            </Grid>
            <Grid item>
              <div
                className="coupon__link"
                onClick={actions.remove}
                role="presentation"
              >
                <DeleteIcon
                  style={{ width: 20, height: 20, color: uhColors.pmIconGray }}
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  coupon: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

Header.defaultProps = {
  coupon: new Coupon(),
};

export default Header;
