// eslint-disable-next-line import/prefer-default-export
export const handleChange = ({ actions, prefix, namedAction }) => {
  if (!namedAction) {
    // eslint-disable-next-line no-param-reassign
    namedAction = 'updateStore';
  }
  return (e, value, name) => {
    const field = name || e.target.name || e.target.attributes.name.value;
    if (prefix) {
      actions[namedAction]({ [prefix]: { [field]: value } });
    } else {
      actions[namedAction]({ [field]: value });
    }
  };
};
