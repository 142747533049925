export default alt.generateActions(
  'productImageListSuccess',
  'productImageListError',
  'featureImageSelected',
  'productImageSaveSuccess',
  'productImageSaveError',
  'deleteRequested',
  'deleteAborted',
  'deleteConfirmed',
  'deleteSuccess',
  'deleteError',
  'imageSelected',
  'productImageCreateSuccess',
  'productImageCreateError'
);
