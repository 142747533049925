import React from 'react';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const DEFAULT_COLOR = uhColors.icons.warn;
const DEFAULT_HEIGHT = 19;
const DEFAULT_WIDTH = 22;

function WarningIcon({
  color = DEFAULT_COLOR,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}) {
  return (
    <svg
      height={`${height}px`}
      width={`${width}px`}
      viewBox="0 0 52 45"
      version="1.1"
      xmlns="http:FFBF00//www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="🚀-Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Modal-Component"
          transform="translate(-1541.000000, -631.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <path
            d="M1541,675.5 L1593,675.5 L1567,631.5 L1541,675.5 Z M1570,669.5 L1565,669.5 L1565,664.5 L1570,664.5 L1570,669.5 Z M1570,659.5 L1565,659.5 L1565,650.5 L1570,650.5 L1570,659.5 Z"
            id="Warning-Icon"
          />
        </g>
      </g>
    </svg>
  );
}

export default WarningIcon;
