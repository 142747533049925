import React from 'react';

import moment from 'moment-timezone';
import { Grid, Typography } from '@upperhand/playmaker';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function PaymentPlanInfo({ intl, paymentPlan, events, eventTypes, client }) {
  const isTeamSchedule = events?.first()?.isTeamSchedule();
  return (
    <div className="payment-plan-info">
      <Grid className="payment-plan-info__header" container spacing={1}>
        <Grid item xs={12}>
          <div className="payment-plan-info__title">
            <Typography align="center" variant="body1">
              {t('.header', intl, __filenamespace)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            <FormattedCurrency value={paymentPlan.remainingAmount} fromCents />
          </Typography>
        </Grid>
      </Grid>
      <ClientDetails client={client} />
      <div className="info__card">
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Typography className="info__title" variant="subtitle2">
              {t('.title', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_purchase_date', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {t('.purchase_date', intl, __filenamespace, {
                  date: paymentPlan.effectiveAt
                    ? moment(paymentPlan.effectiveAt).format('MMM DD, YYYY')
                    : '-',
                  time: paymentPlan.effectiveAt
                    ? moment(paymentPlan.effectiveAt).format('h:mm a')
                    : '-',
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_next_due', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {t('.next_due', intl, __filenamespace, {
                  date: paymentPlan.nextPaymentDate
                    ? moment(paymentPlan.nextPaymentDate).format('MMM DD, YYYY')
                    : '-',
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_product_type', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="right">
                {isTeamSchedule
                  ? events.first().getIn(['team_type', 'name'])
                  : eventTypes.map(et => et.get('name')).join(', ')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_product', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="right">
                {events.map(e => (
                  <div className="payment-plan-info__ellipsis-name">
                    {e.get('title')}
                  </div>
                ))}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_total', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                <FormattedCurrency value={paymentPlan.amount ?? 0} fromCents />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PaymentPlanInfo;
