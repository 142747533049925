import React from 'react';
import { Button, Dropdown, Grid } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import { t } from 'shared/utils/LocaleUtils';
import { smallScreen } from 'shared/utils/DOMUtils';

import {
  FILTER_LOCATION_ALL,
  FILTER_RESOURCE_ALL,
  FILTER_STAFF_ALL,
} from '../future_event_schedules/Store';

const styles = {
  filtersContainer: {
    position: 'relative',
    width: '100%',
    padding: '8px 0',
  },
  dropdownsContainer: {
    width: '100%',
  },
  dropdownsDesktopContainer: {
    paddingLeft: '62px',
    paddingRight: '138px',
  },
  dropdownsMobileContainer: {
    padding: '50px 8px 8px 8px',
  },

  scheduleToggleBtnContainer: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
};
function EventScheduleHeader({
  locationItems,
  locationFilterUpdated,
  staffItems,
  staffFilterUpdated,
  locationFilter,
  staffFilter,
  resourceItems,
  resourceFilter,
  resourceFilterUpdated,
  onToggleViewMode,
  intl,
}) {
  const mergedStyles = smallScreen()
    ? { ...styles.dropdownsContainer, ...styles.dropdownsMobileContainer }
    : { ...styles.dropdownsContainer, ...styles.dropdownsDesktopContainer };
  return (
    <Box sx={styles.filtersContainer}>
      <Box sx={mergedStyles}>
        <Grid
          alignItems="center"
          container
          justify="space-between"
          spacing={1}
          xs={12}
        >
          <Grid item xs={12} sm={4}>
            <Dropdown
              id="location"
              name="location"
              fullWidth
              items={[
                { label: 'Location: All', value: FILTER_LOCATION_ALL },
              ].concat(locationItems)}
              onChange={e => locationFilterUpdated(e.target.value)}
              outline={false}
              rounded
              value={locationFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              id="staff"
              name="staff"
              fullWidth
              items={[{ label: 'Staff: All', value: FILTER_STAFF_ALL }].concat(
                staffItems
              )}
              onChange={e => staffFilterUpdated(e.target.value)}
              outline={false}
              rounded
              value={staffFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              id="resource"
              name="resource"
              rounded
              fullWidth
              outline={false}
              items={[
                { label: 'Resource: All', value: FILTER_RESOURCE_ALL },
              ].concat(resourceItems)}
              value={resourceFilter}
              onChange={e => resourceFilterUpdated(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.scheduleToggleBtnContainer}>
        <Button
          onClick={onToggleViewMode}
          type="tertiary"
          size="2x"
          fullWidth
          rounded
        >
          {t('.view_sessions', intl, __filenamespace)}
        </Button>
      </Box>
    </Box>
  );
}

export default EventScheduleHeader;
