import * as React from 'react';
import { injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';

import { t } from 'shared/utils/LocaleUtils';
import { getFieldStyles } from 'containers/payments/helpers/field';

function CardHolderField({
  fieldId,
  children,
  errorText,
  intl,
  onChangeCardHolder,
  value,
}) {
  const { inputProps, textFiledStyle, inputLabelProps } = getFieldStyles(
    children,
    fieldId
  );

  return (
    <TextField
      data-fs-exclude
      fullWidth
      name="name"
      variant="standard"
      value={value}
      error={!!errorText}
      helperText={errorText}
      label={t('.card_holder', intl, __filenamespace)}
      placeholder={t('.name', intl, __filenamespace)}
      sx={textFiledStyle}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      onChange={e => onChangeCardHolder(e, e.target.value)}
    />
  );
}

export default injectIntl(CardHolderField);
