import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import CreditPassIcon from 'shared/components/icons/CreditPass.jsx';
import CreditPassExpiration from 'credit_passes/components/_CreditPassExpiration.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import ProductCard from 'point_of_sale/components/products/list/cards/ProductCard.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  creditPassExpiration: {
    fontStyle: 'italic',
    lineHeight: '14px',
  },
};

function CreditPassCard({ creditPass }) {
  return (
    <ProductCard
      className="credit-pass"
      icon={<CreditPassIcon color={uhColors.darkGrey} secondaryColor="white" />}
      onClick={() => POSProductListActions.creditPassSelected(creditPass)}
      primaryText={creditPass.name}
      secondaryText={
        <span>
          <FormattedCurrency value={creditPass.price} fromCents />
          <FormattedMessage
            id={messageId('.total_credits', __filenamespace)}
            values={{ total: creditPass.totalCredits() }}
          />
        </span>
      }
      tertiaryText={
        <CreditPassExpiration
          creditPassId={creditPass.id}
          style={styles.creditPassExpiration}
        />
      }
    />
  );
}

export default CreditPassCard;
