import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Cart(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path d="M20,12a2.25,2.25,0,0,0,2.19-1.73L23.92,2.8A2.25,2.25,0,0,0,21.74,0H1.5a1.5,1.5,0,0,0,0,3H3V15.75A2.25,2.25,0,0,0,5.25,18H21a1.5,1.5,0,0,0,0-3H6V12ZM6,3H20.79L19.37,9H6Zm.75,16.5A2.25,2.25,0,1,0,9,21.75,2.25,2.25,0,0,0,6.75,19.5Zm12,0A2.25,2.25,0,1,0,21,21.75,2.25,2.25,0,0,0,18.74,19.5Z" />
    </SvgIcon>
  );
}

export default Cart;
