class FutureEventScheduleActions {
  constructor() {
    this.generateActions(
      'cancelScheduleSelected',
      'completeScheduleModalCancelClicked',
      'completeScheduleModalConfirmClicked',
      'completeScheduleNoteUpdated',
      'completeScheduleSelected',
      'locationFilterUpdated',
      'locationListSuccess',
      'mounted',
      'pageSelected',
      'resourceFilterUpdated',
      'resourceListSuccess',
      'scheduleCancelError',
      'scheduleCancelSuccess',
      'scheduleCompleteError',
      'scheduleCompleteSuccess',
      'scheduleListError',
      'scheduleListSuccess',
      'staffFilterUpdated',
      'staffListSuccess'
    );
  }
}

export default alt.createActions(FutureEventScheduleActions);
