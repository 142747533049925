import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  Root: {
    alignItems: 'baseline',
    borderBottom: '1px solid var(--color-divide)',
    paddingBottom: 7,
  },
  Label: {
    width: '100%',
    flex: '1',
  },
};

class Amount extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  handleChange = (_e, _value) => {
    // TODO: When fluid payments is in place, make this controlled
    // with QuickScheduleActions.updateCheckoutTotal(rawValue); --joshuamanns
  };

  render() {
    const { quickScheduleStore } = this.props;

    // Set the input size explcitly for dynamic width
    // with a minimum of 5 (i.e, 00.00) --joshuamanns
    const size =
      Math.ceil(quickScheduleStore.checkoutTotal.toString().length) + 1;

    return (
      <FlexBox style={styles.Root}>
        <div style={styles.Label}>
          <FormattedMessage id={messageId('.amount', __filenamespace)} />
        </div>
        <CurrencyTextField
          disabled
          variant="standard"
          floatingLabelText=""
          id="quickScheduleAmount"
          key="quickScheduleAmount"
          value={quickScheduleStore.checkoutTotal}
          onChange={this.handleChange}
          inputProps={{ disableUnderline: true }}
          inputElementProps={{ size: Math.max(size, 5) }}
        />
      </FlexBox>
    );
  }
}

export default Amount;
