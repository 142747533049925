import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { t, l } from 'shared/utils/LocaleUtils';

import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';

const styles = {
  header: {
    fontSize: 15,
    padding: '16px 12px',
  },
  row: {
    backgroundColor: 'var(--color-white)',
  },
  rowOdd: {
    backgroundColor: '#F9F9F9',
  },
  rowText: {
    margin: 0,
    fontSize: 13,
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'var(--color-old-gray)',
  },
  cell: {
    height: 50,
  },
};

function RowMenu({ intl, purchaseOrder, customerUser }) {
  const [anchorEl, setAnchor] = React.useState();

  return (
    <>
      <IconButton onClick={e => setAnchor(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={() => setAnchor(null)}
      >
        <MenuItem
          onClick={() =>
            InventoryDialogActions.openDialogForOrder({
              purchaseOrder,
              customerUser,
            })
          }
        >
          {t('.view_modal', intl, __filenamespace)}
        </MenuItem>
      </Menu>
    </>
  );
}

const getFullName = (customerUsers, id) => {
  const customerUser = customerUsers.get(id);
  return customerUser ? customerUser.fullName() : '';
};

function ContentTable({ intl, purchaseOrders, customerUsers }) {
  return (
    <table className="responsive-table ReportTable OrderHistoryTable">
      <thead>
        <tr>
          <th style={{ ...styles.header, paddingLeft: 24 }}>
            {t('.order_number', intl, __filenamespace)}
          </th>
          <th style={styles.header}>
            {t('.order_date', intl, __filenamespace)}
          </th>
          <th style={styles.header}>
            {t('.submitted_by', intl, __filenamespace)}
          </th>
          <th style={styles.header}>
            {t('.submission_date', intl, __filenamespace)}
          </th>
          <th className="centered" style={styles.header}>
            {t('.items_added', intl, __filenamespace)}
          </th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th style={{ width: 30 }} />
        </tr>
      </thead>
      <tbody>
        {purchaseOrders.map((item, index) => (
          <tr
            key={item.get('id')}
            style={index % 2 === 0 ? styles.rowOdd : styles.row}
            className={index % 2 === 0 ? 'odd' : ''}
          >
            <td
              style={{ ...styles.cell, paddingLeft: 24 }}
              data-title={t('.order_number', intl, __filenamespace)}
            >
              <p style={styles.rowText}>{item.displayableNumber()}</p>
            </td>
            <td
              style={styles.cell}
              data-title={t('.order_date', intl, __filenamespace)}
            >
              <p style={styles.rowText}>
                {l(item.get('order_date'), 'dates.full', intl, __filenamespace)}
              </p>
            </td>
            <td
              style={styles.cell}
              data-title={t('.submitted_by', intl, __filenamespace)}
            >
              <p style={styles.rowText}>
                {getFullName(customerUsers, item.get('created_by_id'))}
              </p>
            </td>
            <td
              style={styles.cell}
              data-title={t('.submission_date', intl, __filenamespace)}
            >
              <p style={styles.rowText}>
                {l(item.get('created_at'), 'dates.full', intl, __filenamespace)}
              </p>
            </td>
            <td
              className="centered"
              style={styles.cell}
              data-title={t('.items_added', intl, __filenamespace)}
            >
              <p style={styles.rowText}>{item.get('total_quantity')}</p>
            </td>
            <td className="icon-menu" style={styles.cell}>
              <RowMenu
                intl={intl}
                purchaseOrder={item}
                customerUser={customerUsers.get(item.get('created_by_id'))}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ContentTable;
