import * as React from 'react';
import { Grid, Spinner } from '@upperhand/playmaker';

import Table from 'containers/events/admin/waitlist/components/Table.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { ClientDataStore } from 'dataStores';
import WaitlistActions from './actions';
import WaitlistStore from './store';

const styles = {
  root: {
    padding: '12px 0',
    overflow: 'hidden',
  },
  spinnerContainer: {
    textAlign: 'center',
  },
};

function Waitlist({ eventId, waitlistStore: { waitlistEntries, loading } }) {
  React.useEffect(() => {
    WaitlistActions.mounted(eventId);
  }, [eventId]);
  return (
    <div style={styles.root}>
      <Grid container spacing={1}>
        {loading ? (
          <Grid item xs={12}>
            <div className="spinner-container" style={styles.spinnerContainer}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Table waitlistEntries={waitlistEntries} eventId={eventId} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default altContainer({
  stores: {
    waitlistStore: WaitlistStore,
    clientDataStore: ClientDataStore,
  },
})(Waitlist);
