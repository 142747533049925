/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CheckboxExpander({ children, style, ...props }) {
  return (
    <FormGroup style={style}>
      <FormControlLabel control={<Checkbox />} {...props} />
      {props.checked && children}
    </FormGroup>
  );
}

export default CheckboxExpander;
