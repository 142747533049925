import * as React from 'react';
import { Checkbox } from '@upperhand/playmaker';
import Form from 'onboarding/components/AccountSetupPaysafe/forms/_Form.jsx';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';
import { t } from 'shared/utils/LocaleUtils.js';

function PaysafeTOS({ intl, terms, accepted, onAcceptedChanged }) {
  return (
    <Form
      style={Styles.formContainer}
      header={t('.header', intl, __filenamespace)}
    >
      <div style={{ width: '100%' }}>
        <iframe
          title="Paysafe TOS"
          srcDoc={terms}
          style={Styles.tos.container}
        />
        <Checkbox
          label={t('.accept_tos', intl, __filenamespace)}
          onChange={(_, isChecked) => onAcceptedChanged(isChecked)}
          checked={accepted}
        />
      </div>
    </Form>
  );
}

export default PaysafeTOS;
