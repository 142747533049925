// TODO: Refactor this to have a more consistant interface. Or move into FeePlan.
const PricingUtils = {
  totalEarnings: (price, feePlan) => {
    if (!isValidNumber(price)) return '';

    return parsedNumber(price) - processingFee(price, feePlan);
  },

  fixedDecimal,

  processingFee,
};

function fixedDecimal(number) {
  return isValidNumber(number) ? parsedNumber(number).toFixed(2) : number;
}

function parsedNumber(number) {
  return parseFloat(number, 10);
}

function processingFee(price, feePlan) {
  if (!isValidNumber(price)) return '';

  const parsedPrice = parsedNumber(price);
  const fee = parsedPrice * (feePlan.percentage / 100) + feePlan.dollar_amount;

  return fixedDecimal(fee > parsedPrice ? parsedPrice : fee);
}

function isValidNumber(number) {
  return (
    number !== '' && number !== null && number !== undefined && !isNaN(number)
  );
}

export default PricingUtils;
