class ResetPasswordActions {
  constructor() {
    this.generateActions(
      'updateStore',
      'changePassword',
      'changePasswordSuccess',
      'changePasswordError'
    );
  }
}

export default alt.createActions(ResetPasswordActions);
