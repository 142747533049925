import {
  FilterOrderMethod,
  FilterPaymentMethod,
  Filters,
} from 'containers/reports/types';

// Check if FilterDrawer has default values
// eslint-disable-next-line import/prefer-default-export
export const hasFilterDrawerDefaults = filters =>
  (!filters.get(Filters.PAYMENT_METHOD) ||
    filters.get(Filters.PAYMENT_METHOD) === FilterPaymentMethod.ALL) &&
  (!filters.get(Filters.ORDER_METHOD) ||
    filters.get(Filters.ORDER_METHOD) === FilterOrderMethod.ALL);
