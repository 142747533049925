import * as React from 'react';
import { Route } from 'react-router-dom';

import UserLogin from 'shared/components/login/UserLogin.jsx';
import CustomerAccountSelection from 'shared/components/login/CustomerAccountSelection.jsx';
import NewUserRedirect from 'containers/accounts/newUserRedirect/NewUserRedirect.jsx';
import ChargifySignUp from 'containers/accounts/chargifySignUp/ChargifySignUp.jsx';
import ForgotPassword from 'containers/accounts/forgotPassword/ForgotPassword.jsx';
import CustomerAccountCreation from 'containers/accounts/createCustomer/CustomerAccountCreation.jsx';
import ResetPassword from 'containers/accounts/resetPassword/ResetPassword.jsx';
import SignupSuccess from 'containers/accounts/signupResults/components/SignupSuccess.jsx';
import VerificationSent from 'containers/accounts/verification_sent/VerificationSent.jsx';
import Verify from 'containers/accounts/verify/Verify.jsx';
import Page from 'routes/Page.jsx';

const accountsRoutes = () => (
  <>
    <Route path="login" element={<UserLogin />} />
    <Route
      path="choose"
      element={
        <Page>
          <CustomerAccountSelection />
        </Page>
      }
    />
    <Route
      path="new_user"
      element={
        <Page>
          <NewUserRedirect />
        </Page>
      }
    />
    <Route
      path="signup_success"
      element={
        <Page>
          <SignupSuccess />
        </Page>
      }
    />
    <Route
      path="new"
      element={
        <Page>
          <CustomerAccountCreation />
        </Page>
      }
    />
    <Route path="chargify_callback" element={<ChargifySignUp />} />
    <Route path="verify" element={<Verify />} />
    <Route
      path="verification_sent"
      element={
        <Page>
          <VerificationSent />
        </Page>
      }
    />
    <Route path="reset_password" element={<ResetPassword />} />
    <Route path="forgot_password" element={<ForgotPassword />} />
  </>
);

export default accountsRoutes;
