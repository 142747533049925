class MarketingCampaignsActions {
  constructor() {
    this.generateActions(
      'handleFieldChange',
      'handlePageSelect',
      'searchMarketingCampaigns',
      'openMarketingCampaignDrawer',
      'closeMarketingCampaignDrawer',
      'toggleMarketingEmailEdit',
      'toggleMarketingEmailPreview',
      'toggleSendLater',
      'listMarketingCampaigns',
      'listMarketingCampaignsSuccess',
      'listMarketingCampaignsError',
      'listMarketingEmailTemplates',
      'listMarketingEmailTemplatesSuccess',
      'listMarketingEmailTemplatesError',
      'createMarketingCampaign',
      'createMarketingCampaignSuccess',
      'createMarketingCampaignError',
      'deleteMarketingCampaign',
      'deleteMarketingCampaignSuccess',
      'deleteMarketingCampaignError',
      'cancelMarketingCampaign',
      'cancelMarketingCampaignSuccess',
      'cancelMarketingCampaignError',
      'listStaff',
      'listStaffSuccess',
      'listStaffError',
      'listContactGroups',
      'listContactGroupsSuccess',
      'listContactGroupsError',
      'exportMarketingCampaigns',
      'exportMarketingCampaignsSuccess',
      'exportMarketingCampaignsError',
      'toggleFiltersDrawer',
      'handleFilterChange',
      'removeFilter',
      'applyFilters',
      'resetFilters',
      'fetchTemplatePreviewSuccess',
      'fetchTemplatePreviewError'
    );
  }
}

export default alt.createActions(MarketingCampaignsActions);
