import * as React from 'react';
import Button from '@mui/material/Button';

import { redirectTo } from 'shared/utils/RouteUtils';

const styles = {
  root: {
    textAlign: 'center',
    margin: '80px auto 0 auto',
  },

  actionButtonContainer: {
    margin: '0 -8px',
  },

  actionButton: {
    margin: '0 8px',
    minWidth: 120,
  },

  copyContainer: {
    maxWidth: 256,
    margin: 'auto',
  },

  headerText: {
    fontSize: 22,
    marginTop: 16,
    marginBottom: 16,
  },

  messageText: {
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 40,
  },
};

const onActionClick = action =>
  action && action.href
    ? () => redirectTo({ path: action.href })
    : action.action;

function EmptyState({
  headerText,
  image,
  messageText,
  primaryAction,
  secondaryAction,
}) {
  return (
    <div style={styles.root}>
      {image}
      <div style={styles.copyContainer}>
        <h2 style={styles.headerText}>{headerText}</h2>
        <p style={styles.messageText}>{messageText}</p>
      </div>
      <div style={styles.actionButtonContainer}>
        {primaryAction && (
          <Button
            variant="contained"
            style={styles.actionButton}
            onClick={onActionClick(primaryAction)}
          >
            {primaryAction.label}
          </Button>
        )}

        {secondaryAction && (
          <Button
            variant="contained"
            color="secondary"
            style={styles.actionButton}
            onClick={onActionClick(secondaryAction)}
          >
            {secondaryAction.label}
          </Button>
        )}
      </div>
    </div>
  );
}

export default EmptyState;
