import { List } from 'immutable';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';

class EventListStoreImpl extends UpperHandStore {
  constructor(eventActions, listeners) {
    super();
    this.events = List();
    this.fields = List();
    this.isLoading = false;
    this.page = 1;
    this.perPage = 20;
    this.totalCount = 0;
    this.eventActions = eventActions;
    this.findById = id => this.events.find(event => event.id === id);

    const createSuccessAction = eventActions.createSuccess || eventActions.list;
    this.bindListeners(
      merge(
        {
          handleListEvents: [eventActions.list, createSuccessAction],
          handleListEventsSuccess: eventActions.listSuccess,
          handleListEventsError: eventActions.listError,
          handleUpdatePageParams: EventListActions.updatePageParams,
          handleUpdateRequestedFields: EventListActions.updateRequestedFields,
          filterDeletedEvents: EventListActions.EVENT_DELETED,
        },
        listeners,
        !eventActions.createOrUpdateServer
          ? {}
          : { handleCreateOrUpdateEvent: eventActions.createOrUpdateServer },
        eventActions.updateSuccess
          ? {
              handleUpdateEventSelection: eventActions.updateSuccess,
            }
          : {}
      )
    );
  }

  handleListEvents({ fields, data, page, perPage }) {
    this.isLoading = true;
    const paginationData = {
      page: page || this.page,
      per_page: perPage || this.perPage,
      fields: List(fields || [])
        .concat(this.fields)
        .toJS(),
    };

    return uhApiClient.get({
      url: this.eventUrl,
      data: merge(data, paginationData),
      success: this.eventActions.listSuccess,
      error: this.eventActions.listError,
    });
  }

  handleListEventsSuccess(data) {
    this.page = data.page;
    this.perPage = data.per_page;
    this.totalCount = data.total_count;
    this.isLoading = false;
    // Reset fields after each request so that optional data needed by one
    // page isn't returned for another after a ReactRouterLink transition
    this.fields = List();
    this.events = List(
      data.events.map(event => new EventTranslator(event).toClient())
    );
  }

  handleListEventsError() {
    // TODO: [mmm 2016-08-02] Hide or disable the main content of the page
    this.isLoading = false;
  }

  handleCreateOrUpdateEvent() {
    this.waitFor(EventStore);

    const updatedEvent = EventStore.getState().customerEvent;

    this.events = this.events
      .map(event => {
        if (event.id === updatedEvent.id) {
          const updEvent = List(event).mergeDeep(List(updatedEvent)).toJS();

          return new CustomerEvent(updEvent);
        }
        return event;
      })
      .toList();
  }

  handleUpdateEventSelection(_event) {
    const updatedEvent = new EventTranslator(_event).toClient();

    if (updatedEvent) {
      this.events = this.events
        .map(event => {
          if (event.id === updatedEvent.id) {
            const updEvent = List(event).mergeDeep(List(updatedEvent)).toJS();

            return new CustomerEvent(updEvent);
          }
          return event;
        })
        .toList();
    }
  }

  handleUpdatePageParams(pageParams) {
    this.page = pageParams.page;
    this.perPage = pageParams.perPage;
  }

  handleUpdateRequestedFields(fields) {
    this.fields = List(fields);
  }

  filterDeletedEvents() {
    this.events = this.events.filter(event => event.status !== 'deleted');
  }
}

export default EventListStoreImpl;
