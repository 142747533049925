import * as React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import ExplanatoryText from 'shared/components/_ExplanatoryText.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    marginBottom: 0,
  },

  expansionButton: {
    height: 50,
    color: uhColors.activeBlue,
    fontSize: 16,
  },

  explanatoryText: {
    lineHeight: 1.2,
    color: uhColors.iconGrey,
  },
};

function CollapsedSelector({ explanatoryText, buttonText, onExpand }) {
  return (
    <div style={styles.root}>
      {explanatoryText && (
        <ExplanatoryText style={{ marginBottom: 20 }} text={explanatoryText} />
      )}
      <Button
        fullWidth
        color="default"
        variant="contained"
        style={styles.expansionButton}
        startIcon={<AddIcon />}
        onClick={onExpand}
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default CollapsedSelector;
