import React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer, Grid, MultipleSelect } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';
import {
  COMMISSION_TYPES,
  PAYMENT_STATUSES,
} from 'reporting/payroll/components/constants';

import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import AutoCompleteAttendeeListActions from 'shared/actions/AutoCompleteAttendeeListActions';

function PayrollFilterDrawer({
  intl,
  isOpen,
  disabled,
  filters,
  staff,
  tasks,
  eventTypes,
  filtersApplied,
}) {
  const staffFilterUpdated = value =>
    PayrollReportActions.combinedFilterUpdated('staffIds', Set(value));

  const tasksFilterUpdated = value =>
    PayrollReportActions.combinedFilterUpdated('taskIds', Set(value));

  const eventTypesFilterUpdated = value =>
    PayrollReportActions.combinedFilterUpdated('eventTypeIds', Set(value));

  const paymentStatusFilterUpdated = value =>
    PayrollReportActions.combinedFilterUpdated('paymentStatus', Set(value));

  const commissionFilterUpdated = value =>
    PayrollReportActions.combinedFilterUpdated('commissionType', Set(value));

  const clearFilters = e => {
    e.preventDefault();
    PayrollReportActions.filtersCleared();
    AutoCompleteAttendeeListActions.searchStringUpdated('');
  };
  return (
    <Drawer
      classes={{
        root: 'payroll-report__filter-drawer',
        content: 'filter-drawer__content',
      }}
      open={isOpen}
      onClose={PayrollReportActions.toggleFilterDrawer}
      title={t('.title', intl, __filenamespace)}
      content={
        <Grid container xs={12} spacing={4} alignItems="center">
          {filtersApplied && (
            <Grid justify="flex-end" container item xs={12}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a className="filter-drawer__clear" onClick={clearFilters}>
                {t('.clear', intl, __filenamespace)}
              </a>
            </Grid>
          )}
          <Grid item sm xs>
            <MultipleSelect
              label={t('.staff', intl, __filenamespace)}
              fullWidth
              items={staff
                .sortBy(a => a.first_name.toLowerCase())
                .valueSeq()
                .map(s => ({
                  value: s.id,
                  label: s.name(),
                }))
                .toArray()}
              name="staff"
              rounded={false}
              outline
              selectedItems={filters.get('staffIds').toArray()}
              selectAll
              onChange={staffFilterUpdated}
            />
          </Grid>
          <Grid item sm xs>
            <MultipleSelect
              label={t('.payout_types', intl, __filenamespace)}
              fullWidth
              items={tasks
                .valueSeq()
                .map(task => ({
                  value: task.id,
                  label: task.name,
                }))
                .toArray()}
              name="tasks"
              rounded={false}
              outline
              selectedItems={filters.get('taskIds').toArray()}
              selectAll
              disabled={disabled}
              onChange={tasksFilterUpdated}
            />
          </Grid>
          <Grid item sm xs>
            <MultipleSelect
              label={t('.commission_types', intl, __filenamespace)}
              fullWidth
              items={COMMISSION_TYPES}
              name="commission types"
              rounded={false}
              outline
              selectedItems={filters.get('commissionType').toArray()}
              selectAll
              disabled={disabled}
              onChange={commissionFilterUpdated}
            />
          </Grid>
          <Grid item sm xs>
            <MultipleSelect
              label={t('.event_types', intl, __filenamespace)}
              fullWidth
              items={eventTypes
                .valueSeq()
                .map(eventType => ({
                  value: eventType.id,
                  label: eventType.name,
                }))
                .toArray()}
              name="event types"
              rounded={false}
              outline
              selectedItems={filters.get('eventTypeIds').toArray()}
              selectAll
              disabled={disabled}
              onChange={eventTypesFilterUpdated}
            />
          </Grid>
          <Grid item sm xs>
            <MultipleSelect
              label={t('.payment_status', intl, __filenamespace)}
              fullWidth
              items={PAYMENT_STATUSES}
              name="payment statuses"
              rounded={false}
              outline
              selectedItems={filters.get('paymentStatus').toArray()}
              selectAll
              disabled={disabled}
              onChange={paymentStatusFilterUpdated}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

PayrollFilterDrawer.propTypes = {
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};

PayrollFilterDrawer.defaultProps = {
  isOpen: false,
};

export default injectIntl(PayrollFilterDrawer);
