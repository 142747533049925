import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentModal, Icon } from '@upperhand/playmaker';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const ANNOUNCEMENT_KEYS = {
  text: 'announcementText',
  dismissed: 'announcementDismissed',
};

function Announcement() {
  const isStaff = currentUser().isStaff();
  const announcementText = currentCustomer().announcement_text;
  const localAnnouncement = localStorage.getItem(ANNOUNCEMENT_KEYS.text);
  const isEqual = announcementText === localAnnouncement;
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (!isEqual && announcementText && !isStaff) {
      localStorage.setItem(ANNOUNCEMENT_KEYS.dismissed, JSON.stringify(false));
    }

    const announcementDismissed = JSON.parse(
      localStorage.getItem(ANNOUNCEMENT_KEYS.dismissed)
    );

    if (!announcementDismissed && !isStaff) {
      localStorage.setItem(ANNOUNCEMENT_KEYS.text, announcementText);
      setShowModal(true);
    }
  }, [announcementText, isEqual, isStaff]);

  if (!announcementText || isStaff) {
    return null;
  }

  const dismissAnnouncement = () => {
    setShowModal(false);
    localStorage.setItem('announcementDismissed', JSON.stringify(true));
  };

  return (
    <ContentModal
      open={showModal}
      onAbort={dismissAnnouncement}
      showActions={false}
      classes={{ root: 'announcement-container' }}
      title={
        <div className="announcement-title">
          <Icon name="chatBubble" className="announcement-icon" />
          <FormattedMessage
            className="announcement-header"
            id={messageId('.announcement_header', __filenamespace)}
            values={{ customerName: currentCustomer().name }}
          />
        </div>
      }
    >
      <div className="announcement-wrapper">
        {announcementText && (
          <p className="announcement-text">{announcementText}</p>
        )}
        {!announcementText && (
          <FormattedMessage
            id={messageId('.announcement_empty', __filenamespace)}
          />
        )}
      </div>
    </ContentModal>
  );
}

export default Announcement;
