import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { MembershipRenewalActions } from 'sources';

class MembershipRenewalDataStore extends UpperHandStore {
  constructor() {
    super();

    this.membershipRenewal = Map();

    this.bindListeners({
      fetchSuccess: MembershipRenewalActions.fetchSuccess,
      fetchError: MembershipRenewalActions.fetchError,
      listSuccess: MembershipRenewalActions.listSuccess,
      listError: MembershipRenewalActions.listError,
    });
  }

  fetchSuccess(membershipRenewal) {
    this.membershipRenewal = this.membershipRenewal.set(
      membershipRenewal.id,
      membershipRenewal
    );
  }

  fetchError(...args) {
    this.notifyError('error fetching membershipRenewals', args);
  }

  listSuccess({ membership_renewals: membershipRenewals }) {
    this.membershipRenewals = this.membershipRenewals.merge(
      membershipRenewals.groupBy(s => s.id).map(s => s.first())
    );
  }

  listError(...args) {
    this.notifyError('error listing membershipRenewals', args);
  }
}

export default alt.createStore(
  MembershipRenewalDataStore,
  'MembershipRenewalDataStore'
);
