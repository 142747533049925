import AvailableTimesStore from 'event_mgmt/shared/stores/AvailableTimesStore.js';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions.js';

export default alt.createStore(
  AvailableTimesStore,
  'SchedulingDrawerAvailableTimesStore',
  {
    setEventId: EventDisplayActions.CLIENT_DISPLAY_MOUNTED,
    setEvent: EventActions.FETCH_SUCCESS,

    clearSelections: SchedulingDrawerActions.DRAWER_CLOSED,

    handleStaffChange: SchedulingDrawerActions.REGISTRATION_STAFF_CHANGED,
    handleDateChange: SchedulingDrawerActions.REGISTRATION_DATE_CHANGED,
    handleTimeChange: SchedulingDrawerActions.REGISTRATION_TIME_CHANGED,
    handleFetchAvailabilities: SchedulingDrawerActions.FETCH_AVAILABILITIES,
  },
  EventStore.displayName
);
