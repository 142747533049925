import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { TextField, Grid, Button, Typography } from '@upperhand/playmaker';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';

import AccountCreditsActions from 'containers/clientProfile/components/AccountCredits/Actions';
import AccountCreditStore from 'containers/clientProfile/components/AccountCredits/Store';

function CreationStep({ intl, accountCreditStore, clientDataStore }) {
  const { formFields, clientId, creditsAmount } = accountCreditStore;
  const { clients } = clientDataStore;
  const client = clients.get(clientId, null);
  const disableClearing =
    !formFields.get('amount', null) || formFields.get('amount') > creditsAmount;

  const handleFieldChange = e => {
    AccountCreditsActions.handleFieldChange({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleAmountChange = (e, value) => {
    AccountCreditsActions.handleFieldChange({
      field: e.target.name,
      value,
    });
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="space-between"
      className="account-credits-clear"
    >
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body1" className="account-credits-clear__title">
            {t('.title', intl, __filenamespace)}
          </Typography>
        </Grid>
        {client && (
          <Grid item>
            <ClientDetails client={client} />
          </Grid>
        )}
        <Grid item>
          <Typography
            variant="fieldLabel"
            className="account-credits-clear__input-label"
          >
            {t('.amount_available', intl, __filenamespace)}
          </Typography>
          <CurrencyTextField
            disabled
            fullWidth
            classes={{ root: 'account-credits-clear__input-root' }}
            name="amount_available"
            value={creditsAmount}
            onChange={() => null}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="fieldLabel"
            className="account-credits-clear__input-label"
          >
            {t('.amount', intl, __filenamespace)}
          </Typography>
          <CurrencyTextField
            fullWidth
            classes={{ root: 'account-credits-clear__input-root' }}
            name="amount"
            converter={e => parseInt(e, 10)}
            value={formFields.get('amount')}
            onChange={handleAmountChange}
          />
        </Grid>
        <Grid item container spacing={2} direction="column">
          <Grid item>
            <TextField
              multiline
              rows={4}
              name="note"
              label={t('.note', intl, __filenamespace)}
              value={formFields.get('note')}
              onChange={handleFieldChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          fullWidth
          classes={{
            root: 'account-credits-clear__add-btn',
          }}
          disabled={disableClearing}
          onClick={AccountCreditsActions.createCredit}
        >
          {t('.clear_credit', intl, __filenamespace)}
        </Button>
      </Grid>
    </Grid>
  );
}

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      accountCreditStore: AccountCreditStore,
      clientDataStore: ClientDataStore,
    },
  })
)(CreationStep);
