export default alt.generateActions(
  'profileSelected',
  'profileRemoved',
  'profileCreated',
  'addToCartClick',
  'updateCartClick',
  'cancelClicked',
  'viewInCartClicked',
  'embedCardChange',
  'membershipTierSelected',
  'validate'
);
