/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
// TODO refactor to toServer, fromServer methods on records
// https://www.pivotaltracker.com/story/show/136066563
import { List, fromJS } from 'immutable';
import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import Schedule from 'event_mgmt/shared/records/Schedule.jsx';
import ScheduleResource from 'shared/records/ScheduleResource.js';
import ScheduleStaff from 'shared/records/ScheduleStaff.js';
import Location from 'shared/records/Location.jsx';
import EventType from 'event_mgmt/shared/records/EventType.jsx';

import {
  convertDateToServerValue,
  convertDateToClientValue,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import TeamType from 'records/TeamType.jsx';
import DateOfBirth from 'records/DateOfBirth.jsx';

class EventTranslator {
  constructor(obj) {
    this.customerEvent = new CustomerEvent(fromJS(obj));
  }

  toClient() {
    this.customerEvent = this.customerEvent.withMutations(customerEvent => {
      this.translateEventTypeToClient(customerEvent);
      this.translatePriceToClient(customerEvent);
      this.translateSchedulesToClient(customerEvent);
      this.translateDescriptionToClient(customerEvent);
      this.translateTieredPriceToClient(customerEvent);
      this.translateTeamTypeToClient(customerEvent);
      if (customerEvent.isTeamEvent()) {
        return customerEvent.setIn(
          ['team_detail', 'date_of_birth'],
          new DateOfBirth(customerEvent.team_detail.get('date_of_birth'))
        );
      }
    });

    return this.customerEvent;
  }

  toServer() {
    this.customerEvent = this.translatePriceToServer(this.customerEvent);
    this.customerEvent = this.translateSchedulesToServer(this.customerEvent);
    this.customerEvent = this.translateDescriptionToServer(this.customerEvent);
    this.customerEvent = this.translateTieredPriceToServer(this.customerEvent);

    return this.customerEvent.toServer();
  }

  translateDescriptionToClient(customerEvent) {
    customerEvent.set('description', customerEvent.getRichDescription());
  }

  translateDescriptionToServer(customerEvent) {
    return customerEvent.set('description', customerEvent.getHtmlDescription());
  }

  translatePriceToClient(customerEvent) {
    const price = parseInt(customerEvent.get('price'), 10) / 100;
    const singleSessionPrice =
      parseInt(customerEvent.get('single_session_price'), 10) / 100;
    const basePrice = parseInt(customerEvent.get('base_price'), 10) / 100;
    const scheduledPrice =
      parseInt(customerEvent.get('scheduled_price'), 10) / 100;

    return customerEvent
      .set('price', Number.isNaN(price) ? '' : price)
      .set('freeEvent', basePrice === 0)
      .set(
        'single_session_price',
        Number.isNaN(singleSessionPrice) ? '' : singleSessionPrice
      )
      .set('date_based_price', Boolean(customerEvent.get('scheduled_price')))
      .set('base_price', Number.isNaN(basePrice) ? '' : basePrice)
      .set(
        'scheduled_price',
        Number.isNaN(scheduledPrice) ? '' : scheduledPrice
      );
  }

  translateTieredPriceToClient(customerEvent) {
    return customerEvent.set(
      'pricing_tiers',
      customerEvent
        .get('pricing_tiers')
        .map(tier =>
          tier.set(
            'single_session_price',
            tier.get('single_session_price') / 100
          )
        )
    );
  }

  translateEventTypeToClient(customerEvent) {
    customerEvent.set(
      'event_type',
      new EventType(customerEvent.get('event_type'))
    );
  }

  translateTeamTypeToClient(customerTeam) {
    customerTeam.set('team_type', new TeamType(customerTeam.get('team_type')));
  }

  translateSchedulesToClient(customerEvent) {
    if (customerEvent.get('schedules')) {
      if (customerEvent.get('schedules').size > 0) {
        return customerEvent.set(
          'schedules',
          customerEvent
            .get('schedules')
            .map(schedule =>
              this.translateSingleScheduleToClient(new Schedule(schedule))
            )
        );
      }
      customerEvent.set(
        'schedules',
        customerEvent.get('schedules').push(new Schedule())
      );

      return customerEvent;
    }
  }

  translateSingleScheduleToClient(schedule) {
    const scheduleMap = schedule.withMutations(mutableSchedule => {
      mutableSchedule.set(
        'availability_schedule',
        this.translateAvailabilityScheduleToClient(
          mutableSchedule.get('availability_schedule'),
          !!mutableSchedule.get('duration')
        )
      );

      if (mutableSchedule.get('location')) {
        mutableSchedule.set(
          'location',
          new Location(mutableSchedule.get('location'))
        );
      } else {
        mutableSchedule.set('location', new Location());
      }

      mutableSchedule.update('schedule_resources', (ls = List()) =>
        ls.map(sr => new ScheduleResource(sr)).sortBy(sr => sr.position)
      );
      mutableSchedule.update('schedule_staff', (ls = List()) =>
        ls.map(ss => new ScheduleStaff(ss)).sortBy(ss => ss.position)
      );

      this.translateDobToClient(mutableSchedule);
    });
    return new Schedule(scheduleMap);
  }

  translateDobToClient(schedule) {
    return schedule.set(
      'date_of_birth',
      new DateOfBirth(schedule.date_of_birth)
    );
  }

  translateAvailabilityScheduleToClient(availabilitySchedule, isOpenBooking) {
    const availabilityScheduleRecord = new AvailabilitySchedule(
      availabilitySchedule
    );

    return availabilityScheduleRecord.withMutations(schedule => {
      schedule.set(
        'exclusions',
        List(
          schedule
            .get('exclusions')
            .map(exclusion => convertDateToClientValue(exclusion))
        )
      );
      schedule.set('stops_by_date', schedule.get('end_date'));

      if (schedule.get('repeat_duration')) {
        schedule.set('repeat_mode', 'occurrences');
      } else {
        schedule.set('repeat_mode', 'until');
        schedule.set('repeat_duration', 1);
      }
      schedule.set(
        'indefinite',
        !schedule.get('end_date') && isOpenBooking && !schedule.get('frequency')
      );

      schedule.set('daytimesAreUnique', schedule.daytimes.toSet().size !== 1);
    });
  }

  translatePriceToServer(customerEvent) {
    const floatSchedulePrice = parseFloat(customerEvent.get('scheduled_price'));
    const intSchedulePrice = parseInt(
      (floatSchedulePrice * 100).toFixed(0),
      10
    );
    const floatPrice = parseFloat(customerEvent.get('base_price'));
    const intPrice = parseInt((floatPrice * 100).toFixed(0), 10);
    const singleSessionPrice = customerEvent.get(
      'allow_single_session_purchase'
    )
      ? Math.round(
          (customerEvent.get('single_session_price') + Number.EPSILON) * 100
        )
      : '';

    return customerEvent
      .set('base_price', Number.isNaN(intPrice) ? '' : intPrice)
      .set(
        'scheduled_price',
        Number.isNaN(intSchedulePrice) ? '' : intSchedulePrice
      )
      .set(
        'single_session_price',
        Number.isNaN(singleSessionPrice) ? '' : singleSessionPrice
      );
  }

  translateTieredPriceToServer(customerEvent) {
    return customerEvent.set(
      'pricing_tiers',
      customerEvent
        .get('pricing_tiers')
        .map(tier =>
          tier.set(
            'single_session_price',
            Math.round(
              (tier.get('single_session_price') + Number.EPSILON) * 100
            )
          )
        )
    );
  }

  translateSchedulesToServer(customerEvent) {
    if (
      customerEvent.get('schedules') &&
      customerEvent.get('schedules').size > 0
    ) {
      if (!customerEvent.id && customerEvent.isClassSchedule())
        return customerEvent.set('schedules', List());
      return customerEvent.set(
        'schedules',
        customerEvent
          .get('schedules')
          .map(schedule => this.translateSingleScheduleToServer(schedule))
      );
    }
    return customerEvent;
  }

  translateSingleScheduleToServer(schedule) {
    const scheduleItem = !schedule.asImmutable
      ? new Schedule(schedule)
      : schedule;

    return scheduleItem.withMutations(mutableSchedule => {
      mutableSchedule.set(
        'availability_schedule',
        this.translateAvailabilityScheduleToServer(
          mutableSchedule.get('availability_schedule')
        )
      );
    });
  }

  translateAvailabilityScheduleToServer(availabilitySchedule) {
    if (availabilitySchedule) {
      const availabilityScheduleItem = !availabilitySchedule.asImmutable
        ? new AvailabilitySchedule(availabilitySchedule)
        : availabilitySchedule;

      return availabilityScheduleItem.withMutations(schedule => {
        const scrubbedDayTimes = schedule
          .get('daytimes')
          .filter((_, v) => v !== 'unique');
        schedule.set('daytimes', scrubbedDayTimes);

        schedule.set(
          'exclusions',
          schedule
            .get('exclusions')
            .map(exclusion => convertDateToServerValue(exclusion))
        );
        schedule.set('start_date', schedule.get('start_date'));
        schedule.set('end_date', schedule.get('end_date'));

        if (schedule.get('repeat_mode') === 'until') {
          schedule.set('repeat_duration', null);
        }
      });
    }
  }
}

export default EventTranslator;
