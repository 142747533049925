import * as React from 'react';
import { injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import EmptyState from 'shared/components/EmptyState.jsx';
import EventCard from 'client_portal/components/EventCard.jsx';
import NoEventsIcon from 'shared/components/icons/empty_states/Events.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  searchBar: {
    padding: '0 20px',
    fontSize: 18,
    flex: '1 1 auto',
  },

  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },
};

const FilterEmptyState = injectIntl(({ intl, onClearFilters }) => (
  <EmptyState
    image={<NoEventsIcon />}
    headerText={t('.empty_state_header', intl, __filenamespace)}
    messageText={t('.empty_state_message', intl, __filenamespace)}
    primaryAction={{
      label: t('.clear_filters', intl, __filenamespace),
      action: onClearFilters,
    }}
  />
));

const PaginatedCardList = injectIntl(
  ({
    athletes,
    automations,
    events,
    intl,
    isLastPage,
    isLoading,
    onSeeMoreClicked,
    purchased,
  }) => (
    <div>
      {events.map(event => (
        <EventCard
          key={event.id}
          event={event}
          automations={automations}
          athletes={athletes}
          purchased={purchased}
        />
      ))}
      <SpinWhileLoading isLoading={isLoading} spinWhile="isLoading">
        {isLastPage ? (
          <div />
        ) : (
          <Button
            fullWidth
            variant="outlined"
            sx={{ height: '62px', fontSize: '16px' }}
            onClick={onSeeMoreClicked}
          >
            {t('.see_more', intl, __filenamespace)}
          </Button>
        )}
      </SpinWhileLoading>
    </div>
  )
);

function EventList({
  athletes,
  automations,
  emptyState,
  events,
  filterIcon,
  filterStore,
  isLastEventPage,
  isLoading,
  onClearFilters,
  onSeeMoreClicked,
  onTypingEnd,
  purchased,
}) {
  return (
    <div>
      <FlexBox>
        <SearchBar
          onTypingEnd={onTypingEnd}
          style={styles.searchBar}
          iconStyle={styles.searchBarIcon}
          searchText={filterStore.titleFilter}
        />
        {filterIcon}
      </FlexBox>
      <Divider sx={{ marginBottom: '20px' }} />
      <PaginatedCardList
        athletes={athletes}
        events={events}
        automations={automations}
        isLoading={isLoading}
        isLastPage={isLastEventPage}
        onSeeMoreClicked={onSeeMoreClicked}
        purchased={purchased}
      />
      {!isLoading &&
        events.size === 0 &&
        !filterStore.anyFiltersEnabled() &&
        emptyState}
      {!isLoading && events.size === 0 && filterStore.anyFiltersEnabled() && (
        <FilterEmptyState onClearFilters={onClearFilters} />
      )}
    </div>
  );
}

export default EventList;
