import ResourceActions from 'resources/actions/ResourceActions.js';
import ResourceDrawerActions from 'resources/actions/ResourceDrawerActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class ResourceDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.drawerOpen = false;
    this.resourceId = null;

    this.bindListeners({
      openDrawer: [
        ResourceActions.CREATE_RESOURCE_CLICKED,
        ResourceActions.EDIT_RESOURCE_CLICKED,
      ],

      closeDrawer: [
        ResourceDrawerActions.DRAWER_CLOSED,
        ResourceActions.CREATE_OR_UPDATE_SUCCESS,
      ],
    });
  }

  openDrawer(resource) {
    this.resourceId = resource ? resource.id : null;
    this.drawerOpen = true;
  }

  closeDrawer() {
    this.resourceId = null;
    this.drawerOpen = false;
  }

  handleDelete([_data, resourceId]) {
    if (this.resourceId === resourceId) {
      this.closeDrawer();
    }
  }
}

export default alt.createStore(ResourceDrawerStore, 'ResourceDrawerStore');
