import React from 'react';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import {
  Checkbox,
  TextField,
  CurrencyTextField,
  RadioButton,
  DateTimePicker,
  Grid,
} from '@upperhand/playmaker';

import ActionButtons from 'credit_passes/components/editing/_ActionButtons.jsx';
import CreditPassEditingActions from 'credit_passes/actions/CreditPassEditingActions.js';
import DaySelector from 'credit_passes/components/editing/_DaySelector.jsx';
import { CREDIT_PASS_EXPIRATION_STRATEGIES } from 'shared/records/CreditPass.js';
import { formatDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  group: {
    marginBottom: 16,
  },
  radioButtonRoot: {
    marginLeft: 32,
  },
  radioButtonLabel: {
    height: '100%',
  },
  radioContent: {
    display: 'flex',
    alignItems: 'center',
    margin: '2px 0',
  },
  radioDesc: {
    paddingLeft: 8,
  },
  dateDialogContainer: {
    display: 'none',
  },
};

function EditingForm({ classes, intl, record, expirationEnabled, isSaving }) {
  const dateDialogRef = React.useRef(null);

  const getUsageLimitValue = value => (value === 0 ? '' : value);

  const handleUsageLimitChange = (_, value) => {
    const newValue = value ? parseInt(value, 10) : 0;

    CreditPassEditingActions.updateField('max_num_of_purchases', newValue);
  };

  return (
    <div style={styles.root}>
      <div style={styles.header}>
        <FormattedMessage id={messageId('.header_text', __filenamespace)} />
      </div>
      <div style={styles.group}>
        <TextField
          onChange={(_, value) =>
            CreditPassEditingActions.updateField('name', value)
          }
          errorText={record.errors.getErrors('name', intl)}
          name="name"
          value={record.name}
          label={t('.name', intl, __filenamespace)}
          placeholder={t('.name_hint', intl, __filenamespace)}
        />
      </div>
      <div style={styles.group}>
        <TextField
          onChange={(_, value) =>
            CreditPassEditingActions.updateField('description', value)
          }
          errorText={record.errors.getErrors('description', intl)}
          name="description"
          value={record.description}
          label={t('.description', intl, __filenamespace)}
          placeholder={t('.description', intl, __filenamespace)}
          multiline
        />
      </div>
      <div style={styles.group}>
        <CurrencyTextField
          label={t('.pricing', intl, __filenamespace)}
          name="price"
          value={record.price / 100}
          onChange={(_, value) => {
            CreditPassEditingActions.updateField(
              'price',
              Math.round((value + Number.EPSILON) * 100)
            );
          }}
          errorText={record.errors.getErrors('price', intl)}
        />
      </div>

      <div style={styles.header}>
        <FormattedMessage id={messageId('.limit_header', __filenamespace)} />
      </div>

      <div>
        <TextField
          classes={{
            root: 'credit-pass__maxPurchases-field',
          }}
          name="max_num_of_purchases"
          type="number"
          value={getUsageLimitValue(record.max_num_of_purchases)}
          min={0}
          label={
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                {t('.max_num_of_purchases_label', intl, __filenamespace)}
              </Grid>
              <Grid item>
                <InfoIcon
                  data-tip={t(
                    '.max_num_of_purchases_info',
                    intl,
                    __filenamespace
                  )}
                  data-place="right"
                />
              </Grid>
            </Grid>
          }
          onChange={handleUsageLimitChange}
          errorText={record.errors.getErrors('max_num_of_purchases', intl)}
        />
      </div>

      <Checkbox
        checked={record.hide_in_pos}
        label={t('.hide_in_pos', intl, __filenamespace)}
        name="hide_in_pos"
        onChange={CreditPassEditingActions.togglePOS}
      />

      <Checkbox
        checked={!record.published}
        label={t('.set_published', intl, __filenamespace)}
        name="set_published"
        onChange={CreditPassEditingActions.togglePurchasable}
      />

      <Grid container direction="row" alignItems="center">
        <Checkbox
          checked={expirationEnabled}
          label={t('.set_expiration', intl, __filenamespace)}
          name="set_expiration"
          onChange={CreditPassEditingActions.toggleExpiration}
        />
        <InfoIcon data-tip={t('.expiration_tooltip', intl, __filenamespace)} />
      </Grid>

      <ReactTooltip effect="solid" className="uh-tooltip uh-tooltip_sm" />

      {expirationEnabled && (
        <RadioButton
          classes={{
            root: classes.radioButtonRoot,
            labelRoot: classes.radioButtonLabel,
          }}
          onChange={(_, value) => {
            if (value === 'date') {
              dateDialogRef.current.handleOpen();
            }
            CreditPassEditingActions.updateField('expiration_strategy', value);
          }}
          options={[
            {
              label: (
                <div style={styles.radioContent}>
                  <DaySelector
                    disabled={
                      record.expiration_strategy !==
                      CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_purchase
                    }
                    value={
                      record.expiration_strategy !==
                      CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_purchase
                        ? ''
                        : record.expiration_days
                    }
                    onDaysChange={value =>
                      CreditPassEditingActions.updateField(
                        'expiration_days',
                        parseInt(value, 10) || ''
                      )
                    }
                  />
                  <div style={styles.radioDesc}>
                    {t('.expire_from_purchase', intl, __filenamespace)}
                  </div>
                </div>
              ),
              value: CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_purchase,
            },
            {
              label: (
                <div style={styles.radioContent}>
                  <DaySelector
                    disabled={
                      record.expiration_strategy !==
                      CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_first_use
                    }
                    value={
                      record.expiration_strategy !==
                      CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_first_use
                        ? ''
                        : record.expiration_days
                    }
                    onDaysChange={value =>
                      CreditPassEditingActions.updateField(
                        'expiration_days',
                        parseInt(value, 10) || ''
                      )
                    }
                  />
                  <div style={styles.radioDesc}>
                    {t('.expire_from_first_use', intl, __filenamespace)}
                  </div>
                </div>
              ),
              value: CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_first_use,
            },
            {
              label: (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div onClick={() => dateDialogRef.current.handleOpen()}>
                  <FormattedMessage
                    id={messageId('.on_expire_date', __filenamespace)}
                  />
                  <span style={{ color: 'var(--color-secondary)' }}>
                    <FormattedMessage
                      id={messageId('.expire_date', __filenamespace)}
                      values={{
                        expirationDate: formatDate(record.expires_at),
                      }}
                    />
                  </span>
                </div>
              ),
              value: CREDIT_PASS_EXPIRATION_STRATEGIES.date,
            },
          ]}
          selectedValue={record.expiration_strategy}
          name="expiration_strategy"
        />
      )}
      <div style={styles.dateDialogContainer}>
        <DateTimePicker
          ref={dateDialogRef}
          autoOk
          type="date"
          name="expirationDate"
          onChange={value =>
            CreditPassEditingActions.updateField('expires_at', value)
          }
          value={record.expires_at}
        />
      </div>
      <ActionButtons record={record} intl={intl} isSaving={isSaving} />
    </div>
  );
}

export default withStyles(styles)(EditingForm);
