import { Iterable, Record } from 'immutable';
import AddressType from 'shared/records/AddressType.jsx';

class Address extends Record({
  id: null,
  line_1: '',
  line_2: '',
  line_3: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
  address_type: new AddressType(),
}) {
  constructor(obj = {}) {
    let newObj = obj;

    if (Iterable.isIterable(newObj)) {
      newObj = newObj.toJS();
    }

    super({ ...newObj, address_type: new AddressType(newObj.address_type) });
  }

  isBlank() {
    return (
      (!this.line_1 &&
        !this.line_2 &&
        !this.line_3 &&
        !this.city &&
        !this.state &&
        !this.postal_code) ||
      this.line_1 === '' ||
      this.city === '' ||
      this.state === '' ||
      this.postal_code === ''
    );
  }

  get fullAddress() {
    return `${this.city}, ${this.state} ${this.postal_code}`;
  }

  get streetAddress() {
    return this.line_1;
  }

  get additionalAddress() {
    return this.line_2;
  }

  isValid() {
    return this.line_1 && this.city && this.state && this.postal_code;
  }
}

export default Address;
