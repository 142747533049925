import { List, Set, Record, Map } from 'immutable';
import moment from 'moment-timezone';

import Address from 'shared/records/Address.jsx';
import customerUserAttributes from 'shared/utils/CustomerUserUtils.jsx';
import { isPlainObject } from 'shared/utils/ObjectUtils.jsx';
import { formatPhoneToE164 } from 'shared/utils/FormattingUtils.jsx';
import { Preferences, Image } from 'records';
import { currentUser } from 'shared/utils/UserUtils.jsx';

export const convertClientToDataSource = data =>
  data
    .map(r => ({ text: r.name(), value: r.get('id') }))
    .toList()
    .toJS();

class Client extends Record({
  id: null,
  accepted_customer_terms_at: null,
  accepted_upper_hand_terms_at: null,
  access_to_vault: null,
  active_membership_id: null,
  active_membership_color: null,
  active_membership_name: null,
  active_membership_tier_name: null,
  active_membership_tier_id: null,
  account_credit_auto_pay: false,
  age: null,
  address: new Address(),
  archived: false,
  date_of_birth: null,
  email: null,
  first_name: null,
  gender: null,
  get_text_notifications: true,
  get_email_notifications: true,
  has_accepted_terms: false,
  has_purchases: false,
  has_subscription_balance_owed: false,
  invite_sent_at: null,
  is_new_user: null,
  last_login_at: null,
  last_name: null,
  login_enabled: false,
  managed_by_id: null,
  managed_customer_users: List(),
  managing_customer_user: null,
  managing_customer_user_name: null,
  managing_customer_user_email: null,
  managing_customer_user_phone: null,
  managing_customer_user_id: null,
  note: '',
  password: null,
  password_confirmation: null,
  permitted_membership_ids: Set(),
  phone: null,
  preferences: new Preferences(),
  profile_image: new Image(),
  ssn_last_4: null,
  type: null,
  user_id: null,
  uuid: null,
  total_credits: null,
  total_account_credit: null,
  total_cash_credits: null,
  qr_code: null,
  documents: List(),
  agreement_details: null,
  mem_invite_date: null,
  role: 'standard',
}) {
  constructor(object = {}, options = {}) {
    let attrs = customerUserAttributes(object);

    attrs = attrs.set('preferences', new Preferences(object.preferences));
    if (attrs.get('managing_customer_user')) {
      attrs = attrs.set(
        'managing_customer_user',
        new Client(attrs.get('managing_customer_user'))
      );
    }

    if (attrs.get('managed_customer_users')) {
      attrs = attrs.set(
        'managed_customer_users',
        List(attrs.get('managed_customer_users').map(c => new Client(c)))
      );
    }

    if (attrs.get('permitted_membership_ids')) {
      attrs = attrs.set(
        'permitted_membership_ids',
        Set(attrs.get('permitted_membership_ids'))
      );
    }

    if (attrs.get('agreement_details')) {
      attrs = attrs.set(
        'agreement_details',
        Map(attrs.get('agreement_details'))
      );
    }

    super(attrs, options);
    this.set('note', this.getRichNote());
  }

  /*
   * @param profiles a list of objects or Client records to be added. If a list of
   *        objects is given, they will first be converted to Client records.
   *
   * @return a new Client with the profiles added to its managed_customer_users
   */

  get customerUserId() {
    const isClient = currentUser().isClient();
    const currentUserClientId = currentUser().customer_user_id;
    const isSameClient = this.id === currentUserClientId;

    if (isClient && !isSameClient && this.managed_by_id) {
      return this.managing_customer_user_id;
    }

    if (isClient && this.managed_by_id && !this.login_enabled) {
      return this.managing_customer_user_id;
    }

    if (isClient && this.managed_by_id && this.login_enabled) {
      return this.id;
    }

    return this.id;
  }

  getHtmlNote() {
    if (!this.note) {
      return '';
    }
    return this.note.toString('html');
  }

  getHtmlNoteCleaned() {
    if (!this.note || this.note === '<p><br></p>') {
      return '';
    }
    return this.note.toString('html');
  }

  getEmail() {
    if (!this.email && this.managed_by_id) {
      return this.managing_customer_user_email;
    }

    return this.email;
  }

  getRichNote() {
    return this.note;
  }

  addManagedProfiles(profiles) {
    let thisClient = this;
    profiles.forEach(clientData => {
      let client;
      if (isPlainObject(clientData)) {
        client = new Client(clientData);
      } else {
        client = clientData;
      }
      if (!client.get('managing_customer_user')) {
        client = client.set('managing_customer_user', thisClient);
      }
      const managedCustUsers = thisClient.get('managed_customer_users');
      const clientIndex = managedCustUsers.findIndex(c => c.id === client.id);
      let newManagedCustUsers;
      if (clientIndex < 0) {
        newManagedCustUsers = managedCustUsers.push(client);
      } else {
        newManagedCustUsers = managedCustUsers.updateIn(
          [clientIndex],
          () => client
        );
      }
      thisClient = thisClient.set(
        'managed_customer_users',
        newManagedCustUsers
      );
    });
    return thisClient;
  }

  addManager(manager) {
    return this.set('managing_customer_user', manager);
  }

  alternativeImage(name) {
    if (!this.profile_image) {
      return null;
    }
    return this.profile_image.getAlternative(name);
  }

  age() {
    return parseInt(moment().diff(this.date_of_birth, 'years', true), 10);
  }

  underThirteen() {
    const age = this.age();
    return Number.isNaN(age) || age < 13;
  }

  name() {
    return `${this.first_name || ''} ${this.last_name || ''}`.trim();
  }

  phoneNumber() {
    return this.phone || this.managing_customer_user_phone || '';
  }

  isBlank() {
    return !(this.first_name || this.last_name || this.id);
  }

  async toServer(forCreate) {
    const data = this.toJS();

    data.note = this.getHtmlNote();

    if (forCreate) {
      delete data.id;
    }
    if (data.profile_image && !data.profile_image.file) {
      delete data.profile_image;
    } else {
      data.profile_image = await this.profile_image.toServer();
    }
    if (new Address(data.address || {}).isBlank()) {
      delete data.address;
    }
    if (!data.email || !data.email.trim().length) {
      delete data.email;
    }
    data.preferences = this.preferences.toServer();

    if (data.phone) {
      data.phone = formatPhoneToE164(data.phone);
    } else {
      data.phone = null;
    }

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  typeDisplay() {
    return 'client';
  }

  hasAccessTo(event) {
    const isNotExclusive =
      !event.isMembershipExclusive() && !event.isMembershipTierExclusive();

    if (isNotExclusive) {
      return true;
    }

    const isMembershipTierExclusive =
      this.active_membership_tier_id &&
      event.isMembershipTierExclusive() &&
      event.exclusive_membership_tier_ids.includes(
        this.active_membership_tier_id
      );

    const isMembershipExclusive =
      this.active_membership_id &&
      event.isMembershipExclusive() &&
      event.exclusive_membership_ids.includes(this.active_membership_id);

    return isMembershipTierExclusive || isMembershipExclusive;
  }

  isActive() {
    return this.last_login_at || this.managed_by_id;
  }

  isUnclaimedAccount() {
    if (this.managed_by_id) {
      return !this.last_login_at && !!this.email && !this.invite_sent_at;
    }
    return !this.last_login_at && !this.invite_sent_at;
  }

  isInvitePending() {
    if (this.managed_by_id) {
      return !this.last_login_at && !!this.invite_sent_at && !!this.email;
    }
    return !!this.invite_sent_at && !this.last_login_at;
  }

  // eslint-disable-next-line class-methods-use-this
  isStaff() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isClient() {
    return true;
  }

  isLead() {
    return this.role === 'lead';
  }

  hasEmailAndLoginEnabled() {
    return currentUser().isStaff() && this.email && this.login_enabled;
  }

  needsToAcceptCustomerTerms(policyUpdatedAt) {
    return (
      policyUpdatedAt &&
      (!this.accepted_customer_terms_at ||
        this.accepted_customer_terms_at < policyUpdatedAt)
    );
  }

  needsToAcceptUpperHandTerms(policyUpdatedAt) {
    return (
      !this.accepted_upper_hand_terms_at ||
      this.accepted_upper_hand_terms_at < policyUpdatedAt
    );
  }

  // eslint-disable-next-line class-methods-use-this
  isTodayBirthDay() {
    if (!this.date_of_birth) {
      return false;
    }
    const birthDate = new Date(this.date_of_birth);
    const today = new Date();
    const firstDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const lastDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 5)
    );
    return (
      birthDate.getMonth() === today.getMonth() &&
      birthDate.getDate() >= firstDayOfWeek.getDate() &&
      birthDate.getDate() <= lastDayOfWeek.getDate()
    );
  }

  getMembershipAgreement() {
    return this.getIn(['agreement_details', 'agreement', 'agreement']);
  }

  getAgreementSignedDate() {
    return this.getIn(['agreement_details', 'agreement', 'signed_at']);
  }

  hasUnSignedAgreement(membershipSubscription) {
    const signedAt = this.getIn([
      'agreement_details',
      'agreement',
      'signed_at',
    ]);
    const agreementUpdatedAt = this.getIn([
      'agreement_details',
      'agreement_updated_at',
    ]);
    if (this.agreement_details === null) {
      return true;
    }
    return (
      moment(signedAt).isBefore(agreementUpdatedAt) &&
      moment(agreementUpdatedAt).isBefore(membershipSubscription.created_at)
    );
  }
}

export default Client;
