export default alt.generateActions(
  'mounted',
  'fetchCustomAcceptancesSuccess',
  'fetchCustomAcceptancesError',
  'fetchPastDueAcceptancesSuccess',
  'fetchPastDueAcceptancesError',
  'fetchPolicyReportSuccess',
  'fetchPolicyReportError',
  'fetchUhAcceptancesSuccess',
  'fetchUhAcceptancesError',
  'fetchWaiversForBillingSuccess',
  'fetchWaiversForBillingError',
  'dateFilterUpdated',
  'filtersCleared',
  'downloadReport',
  'downloadReportSuccess',
  'downloadReportError'
);
