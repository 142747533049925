import * as React from 'react';
import PropTypes from 'prop-types';
import Coupon from 'records/Coupon';

import ViewCard from './ViewCard.jsx';
import EditForm from './EditForm.jsx';

function CouponCard({
  actions,
  coupon,
  createModal,
  editMode,
  errors,
  updatedCoupon,
  intl,
}) {
  return editMode ? (
    <EditForm
      actions={actions}
      createModal={createModal}
      coupon={updatedCoupon}
      errors={errors}
      intl={intl}
    />
  ) : (
    <ViewCard actions={actions} coupon={coupon} intl={intl} />
  );
}

CouponCard.propTypes = {
  coupon: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

CouponCard.defaultProps = {
  coupon: new Coupon(),
};

export default CouponCard;
