import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

function ClearAction({ hidden, onClear }) {
  if (hidden) return null;

  return (
    <IconButton onClick={onClear}>
      <ClearIcon />
    </IconButton>
  );
}

ClearAction.propTypes = {
  hidden: PropTypes.bool,
  onClear: PropTypes.func,
};

ClearAction.defaultProps = {
  hidden: false,
  onClear: () => {},
};

export default ClearAction;
