export const generateCheckDigit = input => {
  if (!input || ![7, 11, 12, 13].includes(input.length)) {
    return '';
  }

  let checksum = 0;

  [...input].reverse().forEach((c, i) => {
    checksum += parseInt(c, 10) * (i % 2 ? 1 : 3);
  });

  return ((10 - Math.abs(checksum % 10)) % 10).toString();
};

export const isValidGTIN = code =>
  !!code && generateCheckDigit(code.slice(0, -1)) === code.slice(-1);
