import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { Dropdown, DateTimePicker, Confirmation } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import { CANCELATION_REASONS } from 'memberships/stores/MembershipCancellationStore.jsx';

import EditMembershipCancellationActions from './Actions';
import EditMembershipCancellationStore from './Store';

import './styles.scss';

function EditMembershipCancellationModal({
  intl,
  editMembershipCancellationStore,
}) {
  const reasons = CANCELATION_REASONS.map(r => ({
    value: r,
    label: r,
  })).toArray();
  const { isOpen, isLoading, cancellationReason, cancellationDate } =
    editMembershipCancellationStore;

  if (!isOpen) return null;

  return (
    <Confirmation
      title={t('.title', intl, __filenamespace)}
      open={isOpen}
      hasCheckbox={false}
      confirmationDisabled={isLoading}
      classes={{
        root: 'edit-cancellation-dialog',
        content: 'edit-cancellation-dialog__content',
      }}
      onCancel={() => EditMembershipCancellationActions.toggleModal()}
      onConfirm={() =>
        EditMembershipCancellationActions.updateCancellationDate()
      }
    >
      <DateTimePicker
        classes={{
          root: 'picker',
          startAdornment: 'picker__icon',
          input: 'picker__input',
        }}
        fullWidth
        disablePast
        showClearIcon={false}
        views={['date']}
        icon="calendar"
        type="date"
        format="ddd MMM D"
        label={t('.cancellation_date', intl, __filenamespace)}
        value={moment(cancellationDate).format('YYYY-MM-DD')}
        onChange={date =>
          EditMembershipCancellationActions.setCancellationDate(date)
        }
      />
      <Dropdown
        fullWidth
        label={t('.cancellation_reason', intl, __filenamespace)}
        placeholder={t(
          '.cancellation_reason_placeholder',
          intl,
          __filenamespace
        )}
        items={reasons}
        value={cancellationReason}
        onChange={e =>
          EditMembershipCancellationActions.setCancellationReason(
            e.target.value
          )
        }
      />
    </Confirmation>
  );
}

EditMembershipCancellationModal.propTypes = {
  intl: PropTypes.object.isRequired,
  editMembershipCancellationStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      editMembershipCancellationStore: EditMembershipCancellationStore,
    },
  })
)(EditMembershipCancellationModal);
