import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const styles = {
  root: {
    border: '0.69px solid #E2E2E2',
    padding: '8px 42px 8px 8px',
  },
};
function TableHeader({ isTeamEvent }) {
  const isClient = currentUser().isClient();
  if (isClient && isTeamEvent) {
    return (
      <div style={{ ...styles.root, padding: '8px 8px 8px 8px' }}>
        <Grid container direction="row" justify="flex-start">
          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.schedule', __filenamespace)} />
            </Typography>
          </Grid>

          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.date', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.time', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.location', __filenamespace)} />
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
  if (isTeamEvent) {
    return (
      <div style={styles.root}>
        <Grid container direction="row" justify="flex-start">
          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.schedule', __filenamespace)} />
            </Typography>
          </Grid>

          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.date', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.time', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.location', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.staff', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.roster', __filenamespace)} />
            </Typography>
          </Grid>

          <Grid item xs={1} alignItems="center" spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.resource', __filenamespace)} />
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div style={styles.root}>
      <Grid container direction="row" justify="flex-start">
        <Grid item alignItems="center" xs={2} spacing={1}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.date', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.time', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.location', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.staff', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.attendees', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.checked_in', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={1} alignItems="center" spacing={1}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.resource', __filenamespace)} />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default TableHeader;
