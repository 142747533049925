import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import AccountSetupPaysafeActions from 'onboarding/actions/AccountSetupPaysafeActions.js';
import OnboardAPI, { OnboardAPIError } from 'onboarding/OnboardAPI.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';

const STEPS = {
  business: 0,
  bank_account: 1,
  identity: 2,
  terms_and_conditions: 3,
  complete: 4,
};

const STEPS_SIZE = Object.keys(STEPS).length;
class AccountSetupPaysafeStore extends UpperHandStore {
  constructor() {
    super();

    this.bindListeners({
      handleCloseAlert: AccountSetupPaysafeActions.closeAlert,
      handleStatusFetch: AccountSetupPaysafeActions.fetchStatus,
      handleNext: AccountSetupPaysafeActions.nextStep,
      handleSuccessfullyCompleted:
        AccountSetupPaysafeActions.successfullyCompleted,
      mounted: AccountSetupPaysafeActions.mounted,
    });

    this.currentStep = 0;
    this.signup_complete = true;
    this.accountEnabled = true;
    this.showBanner = false;
    this.showAlert = false;
    this.alertAcknowledged = false;
    this.accountStatus = 'UNSUBMITTED';
  }

  mounted() {
    const customer = currentCustomer();
    // check to see if we set the onboarding_step, only StaffMembers,StaffAdmin, Admins will get these from API.
    if (
      customer?.active_payment_gateway === 'paysafe' &&
      customer?.paysafe_onboarding_step
    ) {
      const {
        paysafe_onboarding_step: onboardingStep,
        paysafe_account_status: accountStatus,
        active_payment_gateway: activeGateway,
        primary_payment_gateway: primaryGateway,
      } = customer;

      this.accountStatus = accountStatus;
      this.signup_complete = onboardingStep === 'complete';
      this.finished = false;
      this.accountEnabled = accountStatus === 'ENABLED';
      this.currentStep = 0;
      this.migratingFromStripe = activeGateway !== primaryGateway;

      this.showAlert =
        !this.alertAcknowledged &&
        !this.signup_complete &&
        !/\/complete_account/.test(window.location.pathname);

      this.showBanner =
        !(this.signup_complete && this.accountEnabled) &&
        !(this.accountStatus === 'APPROVED' && this.migratingFromStripe);
    }
  }

  handleNext() {
    this.currentStep += 1;
    this.updateFinished();
  }

  updateFinished() {
    this.finished = this.currentStep >= STEPS_SIZE - 1;
  }

  processResponse(response) {
    this.currentStep = STEPS[response.current_step];
    this.updateFinished();
  }

  async handleStatusFetch(...args) {
    this.isProcessing = true;
    try {
      const response = await OnboardAPI.getStatus();
      this.processResponse(response);
    } catch (error) {
      if (error instanceof OnboardAPIError) {
        this.processResponse(error.response);
      }
      this.notifyError('error fetching status', args);
    }
    this.isProcessing = false;
    this.emitChange();
  }

  handleCloseAlert() {
    this.showAlert = false;
    this.alertAcknowledged = true;
  }

  /* eslint-disable class-methods-use-this */
  // alt listeners
  handleSuccessfullyCompleted() {
    window.location.reload();
  }
}
/* eslint-enable class-methods-use-this */

export default alt.createStore(
  AccountSetupPaysafeStore,
  'AccountSetupPaysafeStore'
);
