import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import { Map } from 'immutable';
import IconButton from '@mui/material/IconButton';

import SettingsIcon from 'shared/components/icons/Settings.jsx';
import { t } from 'shared/utils/LocaleUtils';

import OrderReportActions from '../../Actions';

function HeaderDesktop({ columns, intl }) {
  // Have to add one because date / time cannot be toggled.
  const activeItems = columns.filter(c => c).count() + 1;

  return (
    <div className="orders-report__header">
      <Grid container spacing={1} alignContent="center" justify="center">
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography variant="body3" className="orders-report__header-item">
            {t('.dateTime', intl, __filenamespace)}
          </Typography>
        </Grid>
        {columns.get('orderNumber') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography variant="body3" className="orders-report__header-item">
              {t('.orderNumber', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('buyer') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography variant="body3" className="orders-report__header-item">
              {t('.buyer', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('channel') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography variant="body3" className="orders-report__header-item">
              {t('.channel', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('amount') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography variant="body3" className="orders-report__header-item">
              {t('.amount', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('method') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography variant="body3" className="orders-report__header-item">
              {t('.method', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <div className="orders-report__row-action">
        <IconButton onClick={OrderReportActions.openColumnDrawer}>
          <SettingsIcon />
        </IconButton>
      </div>
    </div>
  );
}

HeaderDesktop.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
};

export default HeaderDesktop;
