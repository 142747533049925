import * as React from 'react';
import PropTypes from 'prop-types';

import GenericDiscount from 'memberships/components/_DiscountBenefits/_GenericDiscount.jsx';
import RetailCategoryBar from 'memberships/components/_RetailCategoryBar.jsx';

function RetailCategoryDiscount({
  discount,
  retailCategories,
  style,
  textStyle,
}) {
  return (
    <GenericDiscount discount={discount} messageKey=".value_off_retail">
      {retailCategories
        .map(retailCategory => (
          <RetailCategoryBar
            key={retailCategory.id}
            retailCategory={retailCategory}
            style={style}
            textStyle={textStyle}
          />
        ))
        .toList()}
    </GenericDiscount>
  );
}

RetailCategoryDiscount.propTypes = {
  discount: PropTypes.object.isRequired,
  retailCategories: PropTypes.object.isRequired,
};

export default RetailCategoryDiscount;
