import uhTheme from '_uh_theme.jsx';
import {
  DOUBLE_COLUMN_WIDTH,
  LEFT_MARGIN_PIXELS,
  SINGLE_COLUMN_WIDTH,
} from 'shared/utils/DOMUtils';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: LEFT_MARGIN_PIXELS,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SINGLE_COLUMN_WIDTH,
  },
  headerContainer: {
    width: '100%',
    backgroundColor: uhTheme.palette.headerGrey,
    textAlign: 'center',
    color: 'white',
    marginBottom: '25px',
  },
  title: {
    fontSize: '42px',
    margin: 'auto 0',
    padding: '25px 0px',
  },
  subtitle: {
    fontSize: '24px',
    margin: 'auto 0',
    paddingBottom: '25px',
    textAlign: 'center',
  },
  header2: {
    color: uhTheme.palette.textColor,
    fontSize: '18px',
    marginBottom: '12px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  header3: {
    color: uhTheme.palette.textColor,
    marginTop: '12px',
    marginBottom: '6px',
    fontWeight: 'bold',
  },
  group: {
    marginBottom: LEFT_MARGIN_PIXELS,
  },
  field: {
    container: {
      color: uhTheme.palette.textColor,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '10px',
    },
    name: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  singleAction: {
    width: '100%',
  },
  error: {
    color: '#ff2f49',
    marginBottom: '12px',
  },
  formLargeLayout: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'start',
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
  },

  form: {
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    width: DOUBLE_COLUMN_WIDTH,
  },
  instructions: {
    padding: '20px 15px',
  },
  formContainer: {
    padding: '0 15px 20px 15px',
    display: 'flex',
    flexDirection: 'row',
  },

  formColumn: {
    flex: '50%',
    margin: '0 20px',
  },

  tos: {
    container: {
      width: '100%',
      height: '400px',
    },
    checkbox: {
      color: uhTheme.palette.textColor,
      marginTop: '12px',
    },
  },
  businessLegalName: {
    position: 'relative',
  },
  sameAsBusiness: {
    position: 'absolute',
    lineHeight: '12px',
    fontSize: '12px',
    bottom: '3px',
    right: '0px',
  },

  identity_questions: {
    container: {
      marginBottom: '24px',
    },
  },
  radioHeader: {
    color: uhTheme.palette.textColor,
    marginTop: '12px',
    marginBottom: '8px',
    fontWeight: 'bold',
  },
  radioButton: {
    padding: '4px 0px',
  },
  infoIcon: {
    height: 20,
    width: 20,
    marginLeft: 5,
    position: 'absolute',
    right: 0,
  },
};
