class ContactsArchivedActions {
  constructor() {
    this.generateActions(
      'mounted',
      'clientListSuccess',
      'clientListError',
      'pageSelected',
      'searchUpdated',
      'clientUnarchived',
      'clientUnarchivedSuccess',
      'clientUnarchivedError'
    );
  }
}

export default alt.createActions(ContactsArchivedActions);
