export default alt.generateActions(
  'filterStringUpdated',
  'toggleContactSelected',
  'toggleIneligible',
  'toggleType',
  'openSettings',
  'backToSelect',
  'select',
  'showMore',
  'invite'
);
