import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@upperhand/playmaker';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import Form from 'shared/components/Form.jsx';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSClientActions from 'point_of_sale/actions/POSClientActions.jsx';
import ProfileCard from 'user_management/shared/components/ProfileCard.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t, l } from 'shared/utils/LocaleUtils.js';
import { uhContrast } from 'shared/styles/uhStyles.jsx';
import uhTheme from '_uh_theme.jsx';

import {
  ACCOUNT_CREATION,
  EXISTING_ACCOUNT_CONFIRMATION,
  EXISTING_ACCOUNT_UNARCHIVE,
  MORE_INFORMATION,
  PROFILE_CREATION,
} from 'point_of_sale/stores/POSClientStore.jsx';

import {
  DateOfBirth,
  Email,
  EnableLogin,
  FirstName,
  Gender,
  LastName,
  PhoneNumber,
} from 'user_management/shared/components/UserProfileFields.jsx';

const styles = {
  header: merge(uhContrast.lightOnDark, {
    height: 56,
    fontSize: 15,
    paddingLeft: 20,
  }),

  button: {
    height: 50,
    marginBottom: 12,
  },
};

const emailBlurred = () => POSClientActions.emailBlurred();

function ClientForm({ userProfile, onSave, onFieldUpdate, errors, intl }) {
  return (
    <Form onSubmit={onSave}>
      <Grid container spacing={3} direction="column">
        <Grid item xs>
          <FirstName
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('first_name', value)}
            errorText={errors.getErrors('first_name', intl)}
          />
        </Grid>
        <Grid item xs>
          <LastName
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('last_name', value)}
            errorText={errors.getErrors('last_name', intl)}
          />
        </Grid>
        <Grid item xs>
          <Email
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('email', value)}
            errorText={errors.getErrors('email', intl)}
            onBlur={emailBlurred}
          />
        </Grid>
        <Grid item xs>
          <PhoneNumber
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('phone', value)}
            errorText={errors.getErrors('phone', intl)}
          />
        </Grid>
        <Grid item xs>
          <DateOfBirth
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('date_of_birth', value)}
            errorText={errors.getErrors('date_of_birth', intl)}
          />
        </Grid>
        <Grid item xs>
          <Gender
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('gender', value)}
            errorText={errors.getErrors('gender', intl)}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

function ManagedClientForm({
  userProfile,
  onSave,
  onFieldUpdate,
  errors,
  intl,
}) {
  return (
    <Form onSubmit={onSave}>
      <Grid container direction="column" spacing={3}>
        <Grid item xs>
          <FirstName
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('first_name', value)}
            errorText={errors.getErrors('first_name', intl)}
          />
        </Grid>
        <Grid item xs>
          <LastName
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('last_name', value)}
            errorText={errors.getErrors('last_name', intl)}
          />
        </Grid>
        <Grid item xs>
          <DateOfBirth
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('date_of_birth', value)}
            errorText={errors.getErrors('date_of_birth', intl)}
          />
        </Grid>
        <Grid item xs>
          <Gender
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('gender', value)}
            errorText={errors.getErrors('gender', intl)}
          />
        </Grid>
        <Grid item xs>
          <EnableLogin
            userProfile={userProfile}
            onChange={(_, value) => onFieldUpdate('login_enabled', value)}
          />
        </Grid>
        {userProfile.login_enabled && (
          <Grid item xs>
            <Email
              userProfile={userProfile}
              onChange={(_, value) => onFieldUpdate('email', value)}
              errorText={errors.getErrors('email', intl)}
              onBlur={emailBlurred}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

const saveMoreInformation = () => POSClientActions.moreInformationSaveClicked();
const cancelMoreInformation = () =>
  POSClientActions.moreInformationCancelClicked();

function MoreInformationForm({
  errors,
  intl,
  onFieldUpdate = POSClientActions.formUpdated,
  posClientStore,
  userProfile,
}) {
  return (
    <div>
      <div style={{ fontSize: 15, marginBottom: 10 }}>
        <FormattedMessage
          id={messageId('.more_information_instructions', __filenamespace)}
        />
      </div>
      <Form onSubmit={saveMoreInformation}>
        <FirstName
          userProfile={userProfile}
          onChange={(_, value) => onFieldUpdate('first_name', value)}
          errorText={errors.getErrors('first_name', intl)}
          disabled={!!posClientStore.existingAccount.first_name}
        />
        <LastName
          userProfile={userProfile}
          onChange={(_, value) => onFieldUpdate('last_name', value)}
          errorText={errors.getErrors('last_name', intl)}
          disabled={!!posClientStore.existingAccount.last_name}
        />
        <Email
          userProfile={userProfile}
          onChange={(_, value) => onFieldUpdate('email', value)}
          errorText={errors.getErrors('email', intl)}
          disabled={!!posClientStore.existingAccount.email}
        />
        <PhoneNumber
          userProfile={userProfile}
          onChange={(_, value) => onFieldUpdate('phone', value)}
          errorText={errors.getErrors('phone', intl)}
          disabled={!!posClientStore.existingAccount.phone}
        />
        <DateOfBirth
          userProfile={userProfile}
          onChange={(_, value) => onFieldUpdate('date_of_birth', value)}
          errorText={errors.getErrors('date_of_birth', intl)}
          disabled={!!posClientStore.existingAccount.date_of_birth}
        />
        <Gender
          userProfile={userProfile}
          onChange={(_, value) => onFieldUpdate('gender', value)}
          errorText={errors.getErrors('gender', intl)}
          disabled={!!posClientStore.existingAccount.gender}
        />
      </Form>
      <StateChangingButton
        fullWidth
        inProgress={posClientStore.isSaving}
        label={t('.save_and_continue', intl, __filenamespace)}
        labelInProgress={t('actions.saving', intl, __filenamespace)}
        onClick={saveMoreInformation}
        primary
        style={merge(styles.button, { marginTop: 30 })}
      />
      <Button
        fullWidth
        variant="contained"
        color="default"
        disabled={posClientStore.isSaving}
        style={{
          ...styles.button,
          ...{ color: uhTheme.palette.primary1Color },
        }}
        onClick={cancelMoreInformation}
      >
        {t('actions.cancel', intl, __filenamespace)}
      </Button>
    </div>
  );
}

const existingAccountContinue = () =>
  POSClientActions.existingAccountContinue();
const existingAccountCancel = () => POSClientActions.existingAccountCancel();

const unarchiveClient = () => POSClientActions.unarchiveClient();

function ExistingUserForm({ archived, userProfile, isSaving, errors, intl }) {
  return (
    <>
      <FirstName
        userProfile={userProfile}
        errorText={errors.getErrors('first_name', intl)}
      />
      <LastName
        userProfile={userProfile}
        errorText={errors.getErrors('last_name', intl)}
      />
      <Email
        userProfile={userProfile}
        errorText={errors.getErrors('email', intl)}
      />
      <FlexBoxCenter style={{ marginBottom: 10 }}>
        <UserAvatar user={userProfile} />
        <div>
          <div>{userProfile.name()}</div>
          {userProfile.date_of_birth ? (
            <FormattedMessage
              id={messageId('.date_of_birth', __filenamespace)}
              values={{
                date: l(userProfile.date_of_birth, 'dates.shortNumeric', intl),
              }}
            />
          ) : (
            <FormattedMessage
              id={messageId('.date_of_birth_missing', __filenamespace)}
            />
          )}
        </div>
      </FlexBoxCenter>
      <div style={{ marginBotton: 10 }}>
        <FormattedMessage
          id={messageId(
            `.client_${archived ? 'archived' : 'exists'}_message`,
            __filenamespace
          )}
        />
      </div>
      <StateChangingButton
        fullWidth
        inProgress={isSaving}
        label={t(
          `actions.${archived ? 'unarchive' : 'continue'}`,
          intl,
          __filenamespace
        )}
        labelInProgress={t('actions.saving', intl, __filenamespace)}
        onClick={archived ? unarchiveClient : existingAccountContinue}
        primary
        style={merge(styles.button, { marginTop: 15 })}
      />
      <Button
        fullWidth
        disabled={isSaving}
        variant="contained"
        color="default"
        style={{
          ...styles.button,
          ...{ color: uhTheme.palette.primary1Color },
        }}
        onClick={existingAccountCancel}
      >
        {t('actions.cancel', intl, __filenamespace)}
      </Button>
    </>
  );
}

const saveAccountCreation = () => POSClientActions.accountCreationSave();
const cancelAccountCreation = () => POSActions.cancelAddClientClick();

function AccountCreation({ intl, userProfile, errors }) {
  return (
    <div>
      <ClientForm
        intl={intl}
        errors={errors}
        onFieldUpdate={POSClientActions.formUpdated}
        onSave={POSClientActions.accountCreationSave}
        userProfile={userProfile}
      />
      <Button
        fullWidth
        variant="contained"
        style={merge(styles.button, { marginTop: 15 })}
        onClick={saveAccountCreation}
      >
        {t('actions.next', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="default"
        style={{
          ...styles.button,
          ...{ color: uhTheme.palette.primary1Color },
        }}
        onClick={cancelAccountCreation}
      >
        {t('actions.cancel', intl, __filenamespace)}
      </Button>
    </div>
  );
}

const addProfile = () => POSClientActions.addProfile();
const addProfileClick = () => POSClientActions.addProfileClick();
const cancelAddProfileClick = () => POSClientActions.cancelAddProfileClick();
const cancelAddProfiles = () => POSClientActions.cancelAddProfiles();
const saveClient = () => POSClientActions.saveClient();

function ProfileCreation({ errors, intl, posClientStore }) {
  return (
    <div>
      <ProfileCard
        client={posClientStore.client}
        subtext={posClientStore.client.email}
      />

      {!posClientStore.addingProfile && (
        <div>
          <Button
            fullWidth
            variant="contained"
            color="default"
            disabled={posClientStore.isSaving}
            style={merge(styles.button, {
              marginBottom: 0,
              color: uhTheme.palette.primary1Color,
            })}
            onClick={addProfileClick}
          >
            {t('.add_managed_profile', intl, __filenamespace)}
          </Button>

          {posClientStore.managedProfiles.size > 0 && (
            <div style={{ marginTop: 20 }}>
              <h2 style={{ marginBottom: 15, fontSize: 16 }}>
                <FormattedMessage
                  id={messageId('.profiles', __filenamespace)}
                />
              </h2>
              {posClientStore.managedProfiles.map(profile => (
                <ProfileCard
                  client={profile}
                  onClearClick={() =>
                    POSClientActions.removeProfile(profile.id)
                  }
                  onClick={() => POSClientActions.profileSelected(profile.id)}
                  subtext={profile.email}
                  style={{ marginBottom: 15 }}
                />
              ))}
            </div>
          )}

          <Divider style={{ height: 2, marginTop: 30 }} />

          <StateChangingButton
            fullWidth
            inProgress={posClientStore.isSaving}
            label={t('.save_and_continue', intl, __filenamespace)}
            labelInProgress={t('actions.saving', intl, __filenamespace)}
            onClick={saveClient}
            primary
            style={merge(styles.button, { marginTop: 30 })}
          />
          <Button
            fullWidth
            variant="contained"
            color="default"
            disabled={posClientStore.isSaving}
            style={{
              ...styles.button,
              ...{ color: uhTheme.palette.primary1Color },
            }}
            onClick={cancelAddProfiles}
          >
            {t('actions.cancel', intl, __filenamespace)}
          </Button>
        </div>
      )}

      {posClientStore.addingProfile && (
        <div>
          <ManagedClientForm
            errors={errors}
            intl={intl}
            onFieldUpdate={POSClientActions.managedProfileFormUpdated}
            onSave={POSClientActions.addProfile}
            userProfile={posClientStore.currentProfile}
          />
          <Button
            fullWidth
            variant="contained"
            style={merge(styles.button, { marginTop: 15 })}
            onClick={addProfile}
          >
            {t('actions.continue', intl, __filenamespace)}
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="default"
            style={{
              ...styles.button,
              ...{ color: uhTheme.palette.primary1Color },
            }}
            onClick={cancelAddProfileClick}
          >
            {t('actions.cancel', intl, __filenamespace)}
          </Button>
        </div>
      )}
    </div>
  );
}

function Content({ step, ...props }) {
  switch (step) {
    case ACCOUNT_CREATION:
      return (
        <AccountCreation
          errors={props.posClientStore.clientFieldErrors}
          intl={props.intl}
          posClientStore={props.posClientStore}
          userProfile={props.posClientStore.client}
        />
      );
    case PROFILE_CREATION:
      return (
        <ProfileCreation
          errors={props.posClientStore.currentProfileFieldErrors}
          intl={props.intl}
          posClientStore={props.posClientStore}
          posStore={props.posStore}
          userProfile={props.posClientStore.currentProfile}
        />
      );
    case EXISTING_ACCOUNT_CONFIRMATION:
      return (
        <ExistingUserForm
          archived={false}
          intl={props.intl}
          isSaving={props.posClientStore.isSaving}
          errors={props.posClientStore.clientFieldErrors}
          userProfile={props.posClientStore.existingAccount}
        />
      );
    case EXISTING_ACCOUNT_UNARCHIVE:
      return (
        <ExistingUserForm
          archived
          intl={props.intl}
          isSaving={props.posClientStore.isSaving}
          errors={props.posClientStore.clientFieldErrors}
          userProfile={props.posClientStore.existingAccount}
        />
      );
    case MORE_INFORMATION:
      return (
        <MoreInformationForm
          errors={props.posClientStore.clientFieldErrors}
          intl={props.intl}
          posClientStore={props.posClientStore}
          userProfile={props.posClientStore.client}
        />
      );
    default:
      return <div />;
  }
}

const drawerDismissed = () => POSActions.drawerDismissed();

function AddClient(props) {
  const { posClientStore } = props;

  return (
    <div>
      <FlexBoxCenter style={styles.header}>
        <FormattedMessage id={messageId('.new_client', __filenamespace)} />
        <IconButton
          onClick={drawerDismissed}
          style={{ marginLeft: 'auto', marginRight: 5 }}
        >
          <ClearIcon sx={{ color: '#5B6A72' }} />
        </IconButton>
      </FlexBoxCenter>
      <div style={{ padding: 20 }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Content step={posClientStore.currentStep} {...props} />
      </div>
    </div>
  );
}

export default AddClient;
