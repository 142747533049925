import * as React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import { Grid, TextField } from '@upperhand/playmaker';
import { Filters } from 'containers/reports/types';

import { t } from 'shared/utils/LocaleUtils';

import PaymentPlansActions from '../../Actions';

function Header({ defaultFilters, activeFilters, intl }) {
  return (
    <Grid container item spacing={2} alignContent="center" justify="center">
      <Grid item xs={12}>
        <Grid container item spacing={1}>
          <Grid item xs>
            <TextField
              classes={{
                root: 'payment-plans__search-field',
              }}
              fullWidth
              icon="search"
              onChange={event =>
                PaymentPlansActions.updateFilter(
                  Filters.SEARCH,
                  event.target.value
                )
              }
              placeholder={t('.search', intl, __filenamespace)}
              rounded
              value={
                activeFilters.get('search') || defaultFilters.get('search')
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  activeFilters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Header);
