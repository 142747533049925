import { Set } from 'immutable';
import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { MembershipSubscriptionSource } from 'sources';
import CancellationDialogModalStore from 'memberships/components/CancellationDialogModal/CancellationDialogModalStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

export const CANCELATION_REASONS = Set([
  'Membership Cost',
  'Scheduling Conflict',
  'Transfer Membership',
  'Lack of Usage',
  'Upgrade',
  'Downgrade',
  'Out of State',
  'Other',
]);

class MembershipCancellationStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      stageRequest: MembershipCancellationActions.CANCELLATION_REQUESTED,
      abortRequest: MembershipCancellationActions.CANCELLATION_ABORTED,

      cancelMembership: MembershipCancellationActions.CANCELLATION_CONFIRMED,
      cancelMembershipSuccess:
        MembershipCancellationActions.CANCEL_MEMBERSHIP_SUCCESS,
      cancelMembershipError:
        MembershipCancellationActions.CANCEL_MEMBERSHIP_ERROR,

      updateCancelMode: MembershipCancellationActions.CANCEL_MODE_CHANGED,
      toggleUnscheduleRegistrations:
        MembershipCancellationActions.toggleUnscheduleRegistrations,

      setActiveSubscription:
        MembershipCancellationActions.LIST_SUBSCRIPTION_SUCCESS,
      handleListSubscriptionError:
        MembershipCancellationActions.LIST_SUBSCRIPTION_ERROR,

      cancellationReasonChanged:
        MembershipCancellationActions.CANCELLATION_REASON_CHANGED,
      cancellationNotesChanged:
        MembershipCancellationActions.CANCELLATION_NOTES_CHANGED,
    });
  }

  reset() {
    this.client = null;
    this.membership = null;
    this.subscription = null;
    this.cancelMode = 'end_of_period';
    this.cancelInProgress = false;
    this.unscheduleRegistrations = false;
    this.cancellationReason = null;
    this.cancellationNotes = null;
  }

  stageRequest([client, membership]) {
    this.client = client;
    this.membership = membership;
    this.cancelMode = membership.isLifetime() ? 'immediate' : 'end_of_period';

    this.listSubscriptions({ customerUserId: client.id });
  }

  abortRequest() {
    this.reset();
  }

  updateCancelMode(newMode) {
    this.cancelMode = newMode;
  }

  toggleUnscheduleRegistrations() {
    this.unscheduleRegistrations = !this.unscheduleRegistrations;
  }

  cancellationReasonChanged(reason) {
    this.cancellationReason = reason;
  }

  cancellationNotesChanged(notes) {
    this.cancellationNotes = notes;
  }

  cancelMembership() {
    this.cancelInProgress = true;
    if (this.cancelMode === 'future') {
      this.waitFor(CancellationDialogModalStore);
      const { futureDate } = CancellationDialogModalStore.getState();
      uhApiClient.post({
        url: `membership_subscriptions/${this.subscription.id}/future_cancel`,
        data: JSON.stringify({
          cancellation_date: String(futureDate),
          unschedule_registrations: this.unscheduleRegistrations,
          cancellation_reason: this.cancellationReason,
          cancellation_note: this.cancellationNotes,
        }),
        success: MembershipCancellationActions.cancelMembershipSuccess,
        error: MembershipCancellationActions.cancelMembershipError,
      });
    }
    if (this.cancelMode !== 'future') {
      uhApiClient.post({
        url: `membership_subscriptions/${this.subscription.id}/cancel`,
        data: JSON.stringify({
          cancel_immediately: this.cancelMode === 'immediate',
          unschedule_registrations: this.unscheduleRegistrations,
          cancellation_reason: this.cancellationReason,
          cancellation_note: this.cancellationNotes,
        }),
        success: MembershipCancellationActions.cancelMembershipSuccess,
        error: MembershipCancellationActions.cancelMembershipError,
      });
    }
  }

  cancelMembershipSuccess(_data) {
    if (this.cancelMode === 'future') {
      MessageWindowActions.addMessage.defer(
        'cancellation future date added successfully.'
      );
    }
    this.reset();
  }

  cancelMembershipError(...args) {
    this.cancelInProgress = false;
    this.notifyError('error cancelling client membership subscription', args);
  }

  listSubscriptions({ customerUserId }) {
    this.isLoading = true;

    MembershipSubscriptionSource.list({
      params: {
        client_ids: [customerUserId],
        per_page: 1, // expires_at sort by default, so receiving the most recent subscription
        fields: [
          'membership',
          'has_embed_product',
          'membership_tier',
          'last_payment_at',
        ],
      },
      success: MembershipCancellationActions.listSubscriptionSuccess,
      error: MembershipCancellationActions.listSubscriptionError,
    });
  }

  setActiveSubscription({ membership_subscriptions: membershipSubscriptions }) {
    if (membershipSubscriptions.isEmpty()) {
      this.subscription = null;
      return;
    }

    this.subscription = membershipSubscriptions.first();
    this.membership = this.subscription.membership;
  }

  handleListSubscriptionError(...args) {
    this.notifyError('error listing client membership subscriptions', args);
  }
}

export default alt.createStore(
  MembershipCancellationStore,
  'MembershipCancellationStore'
);
