import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Spinner, TextField, Typography } from '@upperhand/playmaker';

import Paginator from 'shared/components/Paginator.jsx';

import ClientCard from 'containers/classes/classesList/components/Roster/ClientCard.jsx';
import EmptyState from 'containers/classes/classesList/components/Roster/EmptyState.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import ClassesListActions from 'containers/classes/classesList/Actions';
import ClassesListStore from 'containers/classes/classesList/Store';

function Roster({
  intl,
  classesListStore: {
    selectedClass,
    rosterClientIds,
    loadingRoster,
    pagination,
    searchRosterText,
  },
}) {
  const rosterPagination = pagination.get('roster');

  return (
    <Grid container spacing={1} className="class-roster">
      <Grid item xs={12}>
        <TextField
          rounded
          fullWidth
          value={searchRosterText}
          outline={false}
          placeholder={t('.search', intl, __filenamespace)}
          onChange={(_event, value) => ClassesListActions.searchRoster(value)}
        />
      </Grid>
      {loadingRoster && (
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <Spinner type="indeterminate" />
          </div>
        </Grid>
      )}
      <Grid item xs={12} className="class-roster__table-header">
        <div className="class-roster__table-header-content">
          <Grid container xs={12} alignItems="center" spacing={1}>
            <Grid item xs={12} md={5}>
              <Typography variant="fieldLabel">
                {t('.name', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="fieldLabel">
                {t('.gender', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={11} md={2}>
              <Typography variant="fieldLabel">
                {t('.age', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="fieldLabel">
                {t('.managed_by', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="fieldLabel">
                {t('.phone', intl, __filenamespace)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {rosterClientIds.size > 0 && !loadingRoster && (
        <>
          {rosterClientIds.map(id => (
            <Grid key={id} item xs={12}>
              <ClientCard clientId={id} eventId={selectedClass?.id} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Paginator
              currentPage={rosterPagination.page}
              perPage={rosterPagination.perPage}
              totalCount={rosterPagination.totalCount}
              onPageSelect={ClassesListActions.rosterPageChange}
            />
          </Grid>
        </>
      )}
      {rosterClientIds.size === 0 && !loadingRoster && (
        <Grid item xs={12}>
          <EmptyState />
        </Grid>
      )}
    </Grid>
  );
}

Roster.propTypes = {
  classesListStore: PropTypes.object,
};

Roster.defaultProps = {
  classesListStore: {},
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      classesListStore: ClassesListStore,
    },
  })
)(Roster);
