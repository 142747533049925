import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import { post } from 'sources/VariantSource.js';
import InventoryReportAdjustmentModalActions from '../actions/InventoryReportAdjustmentModalActions.js';

export const ADJUSTMENT_REASONS = [
  '.placeholder',
  '.damaged',
  '.stolen',
  '.input_error',
];

class InventoryReportAdjustmentModalStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      closeModal: InventoryReportAdjustmentModalActions.closeModal,
      openModal: InventoryReportAdjustmentModalActions.openModal,
      selectReason: InventoryReportAdjustmentModalActions.selectReason,
      setAmount: InventoryReportAdjustmentModalActions.setAmount,
      saveAdjustment: InventoryReportAdjustmentModalActions.saveAdjustment,
      saveAdjustmentSuccess:
        InventoryReportAdjustmentModalActions.saveAdjustmentSuccess,
      saveAdjustmentError:
        InventoryReportAdjustmentModalActions.saveAdjustmentError,
    });
  }

  reset() {
    this.amount = 0;
    this.reason = '.placeholder';
    this.isOpen = false;
    this.product = null;
  }

  closeModal() {
    this.isOpen = false;
  }

  openModal({ product }) {
    this.isOpen = true;
    this.product = product;
  }

  setAmount(value) {
    this.amount = value;
  }

  selectReason(value) {
    this.reason = value;
  }

  saveAdjustment(product) {
    const newAdjustments = {
      variant_id: product.get('variant_id'),
      expected_quantity: Number(product.get('live_qty')) || 0,
      actual_quantity: Number(this.amount),
      adjustment_reason:
        this.reason === '.placeholder' ? '' : this.reason.split('.')[1],
    };
    const newOrderItems = [newAdjustments];
    post({
      newOrderItems,
      success: InventoryReportAdjustmentModalActions.saveAdjustmentSuccess,
      error: InventoryReportAdjustmentModalActions.saveAdjustmentError,
    });
  }

  saveAdjustmentSuccess() {
    MessageWindowActions.addMessage.defer('Inventory saved successfully!');
    this.reset();
    this.closeModal();
  }

  saveAdjustmentError({ ...args }) {
    this.notifyError('Error during saving inventory', args);
  }
}

export default alt.createStore(
  InventoryReportAdjustmentModalStore,
  'InventoryReportAdjustmentModalStore'
);
