class CancelCompleteScheduleActions {
  constructor() {
    this.generateActions(
      'cancelScheduleSelected',
      'completeScheduleModalCancelClicked',
      'completeScheduleModalConfirmClicked',
      'completeScheduleNoteUpdated',
      'completeScheduleSelected',
      'scheduleCancelError',
      'scheduleCancelSuccess',
      'scheduleCompleteError',
      'scheduleCompleteSuccess'
    );
  }
}

export default alt.createActions(CancelCompleteScheduleActions);
