import { Map, OrderedSet } from 'immutable';
import debounce from 'lodash.debounce';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { CardReaderSource } from 'sources';

import CardReaderEditActions from 'containers/cardReaderEdit/Actions';
import CardReaderDropdownActions from 'containers/cardReaderDropdown/Actions';
import DeviceListActions from './Actions';

import { FILTERS } from './filters';

const ALLOWED_DEVICE_TYPES = ['paxa920', 'paxa920pro', 'paxa60'];

class DeviceListStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.debouncedList = debounce(() => {
      this.page = 1;
      this.listCardReaders();
    }, 600);

    this.bindListeners({
      mounted: DeviceListActions.mounted,
      listCardReaders: DeviceListActions.listCardReaders,
      listCardReadersSuccess: DeviceListActions.listCardReadersSuccess,
      listCardReadersError: DeviceListActions.listCardReadersError,
      updateFilter: DeviceListActions.updateFilter,
      selectPage: DeviceListActions.selectPage,
      openEditModal: DeviceListActions.openEditModal,
      closeEditModal: [
        DeviceListActions.closeEditModal,
        CardReaderEditActions.submitEdit,
      ],
      syncSuccess: CardReaderDropdownActions.syncSuccess,
      syncError: CardReaderDropdownActions.syncError,
      newDefaultChosen: CardReaderDropdownActions.selectCardReader,
      toggleEnabled: DeviceListActions.toggleEnabled,
      updateCardReaderError: DeviceListActions.updateCardReaderError,
    });
  }

  reset() {
    this.isEditOpen = false;
    this.infiniteScroll = true;
    this.isLoadingCardReaders = false;
    this.isRefreshing = false;
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.cardReaderIds = OrderedSet();

    this.activeFilters = Map();
  }

  mounted({ isMobile }) {
    this.reset();
    this.listCardReaders();
  }

  getParams() {
    const params = {};

    if (this.activeFilters.get(FILTERS.search)) {
      params.search = this.activeFilters.get(FILTERS.search);
    }

    return params;
  }

  listCardReaders() {
    this.isLoadingCardReaders = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      device_types: ALLOWED_DEVICE_TYPES,
      ...this.getParams(),
    };

    CardReaderSource.list({
      params,
      success: DeviceListActions.listCardReadersSuccess,
      error: DeviceListActions.listCardReadersError,
    });
  }

  listCardReadersSuccess({
    card_readers: cardReaders,
    page,
    perPage,
    totalCount,
  }) {
    const newIds = cardReaders.map(el => el.id).toOrderedSet();

    if (this.infiniteScroll) {
      this.cardReaderIds =
        page === 1 ? newIds : this.cardReaderIds.union(newIds);
    } else {
      this.cardReaderIds = newIds;
    }

    this.page = this.infiniteScroll ? page + 1 : page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.isLoadingCardReaders = false;

    const customerIds = cardReaders.map(cr => cr.get('customerId')).toSet();
    CardReaderSource.subscribeToChanges({
      customerIds,
    });

    // Now that we are subscribed to updates, let's ping all of the card readers.
    if (this.cardReaderIds.count() > 0) {
      CardReaderSource.groupPing({
        cardReaderIds: this.cardReaderIds,
      });
    }
  }

  listCardReadersError(...args) {
    this.isLoadingCardReaders = false;
    this.notifyError('error listing card readers', args);
  }

  updateFilter([key, value]) {
    this.activeFilters = this.activeFilters.set(key, value);

    if (key === FILTERS.search) {
      this.debouncedList();
    }
  }

  selectPage([page, perPage]) {
    this.page = page;
    this.perPage = perPage;
    this.listCardReaders();
  }

  openEditModal() {
    this.isEditOpen = true;
  }

  closeEditModal() {
    this.isEditOpen = false;
  }

  syncSuccess() {
    this.reset();
    this.listCardReaders();
  }

  syncError(...args) {
    this.isRefreshing = false;
    this.notifyError('error syncing card readers', args);
  }

  newDefaultChosen() {
    this.emitChange();
  }

  toggleEnabled(cardReader) {
    CardReaderSource.put({
      id: cardReader.get('id'),
      params: {
        enabled: !cardReader.get('enabled'),
      },
      success: DeviceListActions.updateCardReaderSuccess,
      error: DeviceListActions.updateCardReaderError,
    });
  }

  updateCardReaderError(...args) {
    this.notifyError('error updating card reader', args);
  }
}

export default alt.createStore(DeviceListStore, 'DeviceListStore');
