import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';

import { LoginFormWrapper as LoginForm } from 'shared/components/login/UserLogin.jsx';
import DesktopResetRequest from 'containers/accounts/forgotPassword/components/DesktopResetRequest.jsx';
import ThemeSidebar from 'client_theme/ThemeSidebar.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils';
import { isUpperHandTheme } from 'shared/utils/ThemeUtils';
import activeTheme from '_uh_theme.jsx';

import ForgotPasswordStore from 'containers/accounts/forgotPassword/Store.js';
import ResendAccountVerificationStore from 'shared/stores/ResendAccountVerificationStore.jsx';

import ModalRegistrationStore from './Store';
import ModalRegistrationActions from './Actions';

function SignInModal({
  intl,
  modalRegistrationStore: { isSignInModalOpened },
  forgotPasswordStore: { isClickButton },
}) {
  return (
    <Dialog
      open={isSignInModalOpened}
      maxWidth="md"
      className="sign-in"
      PaperProps={{
        className: 'sign-in__dialog-paper',
      }}
      onClose={() => ModalRegistrationActions.toggleSignInModal({})}
    >
      <div
        className={clsx(
          'sign-in__dialog-container',
          !isUpperHandTheme() && 'sign-in__dialog-container-white'
        )}
      >
        {!isUpperHandTheme() && (
          <ThemeSidebar modalOpen className="sign-in__side-bar-column" />
        )}
        {isUpperHandTheme() && <div className="sign-in__image-column" />}
        <div className="sign-in__content-column">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="sign-in__dialog-header"
          >
            <Link to="/help">
              <Button className="sign-in__help-btn">
                {t('.need_help', intl, __filenamespace)}
              </Button>
            </Link>
            <Stack>
              <IconButton
                aria-label="close"
                onClick={() => ModalRegistrationActions.toggleSignInModal({})}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
          <DialogContent className="sign-in__dialog-content">
            <img
              src={activeTheme.logo.url}
              className="sign-in__logo"
              alt="logo"
            />
            {isClickButton ? (
              <DesktopResetRequest intl={intl} modalOpen />
            ) : (
              <LoginForm modalOpen />
            )}
          </DialogContent>
        </div>
      </div>
    </Dialog>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      modalRegistrationStore: ModalRegistrationStore,
      forgotPasswordStore: ForgotPasswordStore,
      resendAccountVerificationStore: ResendAccountVerificationStore,
    },
  })
)(SignInModal);
