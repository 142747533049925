class AccountCreditActions {
  constructor() {
    this.generateActions(
      'open',
      'close',
      'toggleClear',
      'toggleDetails',
      'changeStep',
      'createCredit',
      'createCreditSuccess',
      'createCreditError',
      'listCredits',
      'listMoreCredits',
      'listCreditsSuccess',
      'listCreditsError',
      'handleFieldChange'
    );
  }
}

export default alt.createActions(AccountCreditActions);
