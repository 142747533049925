import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ContentModal, Spinner, Typography } from '@upperhand/playmaker';
import Button from '@mui/material/Button';

import CheckoutClientBox from 'calendar/components/quick_schedule/CheckoutClientBox.jsx';
import ItemDetails from 'quickpay/components/ItemDetails.jsx';
import AddOnDetails from 'quickpay/components/AddonDetails.jsx';
import AddOnProductsModal from 'quickpay/components/AddonProductsModal.jsx';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions';
import QuickpayModalStore from 'quickpay/stores/QuickpayModalStore.js';
import altContainer from 'shared/hocs/altContainer.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { ClientDataStore } from 'dataStores';

import {
  Payment,
  PaymentActions,
  PaymentMethod,
  PaymentType,
  PaymentStore,
} from 'containers/payments';

import { smallScreen } from 'shared/utils/DOMUtils';

const styles = {
  title: {
    fontSize: '1.25em',
    display: 'inline',
    textTransform: 'capitalize',
  },
  UnPaidLabel: {
    backgroundColor: 'var(--color-unpaid-red)',
    borderRadius: 2,
    fontSize: '11px',
    padding: '5px 10px',
    cursor: 'pointer',
    color: 'white',
    fontWeight: 600,
  },
  totalAmount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 5,
    marginTop: 10,
  },
};

const disabledPaymentMethods = [PaymentMethod.PAY_LATER];

const closeDialog = () => {
  QuickpayModalActions.closeModal();
  PaymentActions.reset.defer();
};

const onCheckoutSuccess = data => {
  QuickpayModalActions.checkoutSuccess.defer(data);
  PaymentActions.reset.defer();
};

const getPaymentType = mode => {
  switch (mode) {
    case 'payment_plan':
      return PaymentType.PAYMENT_PLAN;

    case 'pay_all_balances':
      return PaymentType.PAY_ALL_BALANCES;

    default:
      return PaymentType.BALANCE;
  }
};

function Loader() {
  return (
    <div style={{ textAlign: 'center', margin: '115px 0' }}>
      <Spinner size={60} />
      <div style={{ marginTop: '1rem' }}>
        <FormattedMessage id={messageId('.loading', __filenamespace)} />
      </div>
    </div>
  );
}

function QuickpayModal({
  quickpayModalStore,
  clientDataStore: { clients },
  onSuccess,
  intl,
}) {
  const { processingPayment } = PaymentStore.getState();
  const {
    isModalOpen,
    isOnCalendar,
    clientId,
    isLoading,
    balance,
    paymentAmount,
    amount,
    mode,
    addonOrder,
    accountCreditAmount,
    availableAccountCreditAmount,
  } = quickpayModalStore;
  const client = clients.get(clientId);

  const paymentType = getPaymentType(mode);
  const disableAmountField = mode === 'pay_all_balances';

  const successCallback = onSuccess
    ? data => {
        onCheckoutSuccess(data);
        onSuccess(data);
      }
    : onCheckoutSuccess;

  const calcHasAccess = () => {
    if (typeof client === 'undefined') {
      return false;
    }
    return client.access_to_vault;
  };

  const calcPurchasingUserId = () => {
    if (typeof client === 'undefined') {
      return null;
    }

    return balance.clientId === client.id
      ? client.user_id
      : client.managed_by_id;
  };

  return (
    // We are using a ContentModal here instead of a FormModal, because the Payment form does
    // not follow the submit / cancel button pattern of the FormModal, so we need to use the
    // 'X' from the ContentModal to cancel the action.
    <>
      <ContentModal
        open={isModalOpen}
        onAbort={closeDialog}
        showActions={false}
        // Mimic the FormModal's auto full screen on mobile.
        fullScreen={smallScreen(600)}
        title={t('.pay_balance', intl, __filenamespace)}
      >
        {isLoading || processingPayment ? (
          <Loader />
        ) : (
          <>
            <CheckoutClientBox selectedClient={client} />
            <ItemDetails balance={balance} />
            <FlexBoxJustify style={{ alignItems: 'center', margin: '18px 0' }}>
              <div style={styles.UnPaidLabel}>
                <FormattedMessage id={messageId('.unpaid', __filenamespace)} />
              </div>
              <FormattedCurrency fromCents value={amount} />
            </FlexBoxJustify>
            <AddOnDetails addOnOrder={addonOrder} />
            <CurrencyTextField
              disabled={disableAmountField}
              variant="standard"
              value={paymentAmount}
              floatingLabelText="Payment Amount"
              inputLabelProps={{
                sx: {
                  fontSize: 17,
                  color: 'var(--color-old-gray)',
                  fontWeight: 600,
                },
              }}
              name="payment_amount"
              onChange={(_, value) =>
                QuickpayModalActions.changePaymentAmount(value)
              }
              inputElementProps={{ max: balance.outstanding }}
              converter={e => parseInt(e, 10)}
              fullWidth
            />
            {addonOrder && (
              <div style={styles.totalAmount}>
                <Typography variant="subtitle2">
                  {t('.total', intl, __filenamespace)}
                </Typography>
                <FormattedCurrency
                  fromCents
                  value={paymentAmount + addonOrder.total}
                />
              </div>
            )}
            <Payment
              allowAccountCredits
              accountCreditAmount={accountCreditAmount}
              availableAccountCreditAmount={availableAccountCreditAmount}
              onAccountCreditChanged={
                QuickpayModalActions.changeAccountCreditAmount
              }
              onApplyCredit={QuickpayModalActions.changeAccountCreditAmount}
              balance={balance}
              maxAmount={paymentAmount}
              addonOrderId={addonOrder?.order_id}
              disableAmountField={disableAmountField}
              itemId={balance.productId}
              total={paymentAmount}
              paymentType={paymentType}
              purchaserId={balance.clientId}
              purchasingUserId={calcPurchasingUserId()}
              hasAccess={calcHasAccess()}
              successAction={successCallback}
              disabledPaymentMethods={disabledPaymentMethods}
              allowFreePurchase={false}
              additionalActions={
                <div>
                  {!addonOrder && isOnCalendar && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="default"
                      sx={{ height: '50px' }}
                      onClick={QuickpayModalActions.toggleAddonProductModal}
                    >
                      {t('.add_product_btn', intl, __filenamespace)}
                    </Button>
                  )}
                </div>
              }
            />
          </>
        )}
      </ContentModal>
      <AddOnProductsModal />
    </>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      quickpayModalStore: QuickpayModalStore,
      clientDataStore: ClientDataStore,
    },
  })
)(QuickpayModal);
