import * as React from 'react';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import ErrorDialog from 'shared/components/ErrorDialog.jsx';
import MessageWindow from 'shared/components/_MessageWindow.jsx';

function ErrorDialogWithMessageWindow() {
  return (
    <>
      <ErrorDialog
        errorsProp="errors"
        ackErrorCallback={() => ApiErrorDialogActions.ackError()}
        closeOn="errorAcked"
      />
      <MessageWindow />
    </>
  );
}

export default ErrorDialogWithMessageWindow;
