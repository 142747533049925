import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'shared/utils/LocaleUtils';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import OrderSummaryDrawerActions from 'containers/reports/orderSummaryDrawer/Actions';

function RowMobile({ intl, orders, orderId }) {
  const order = orders.get(orderId);
  const orderItem = order.getIn(['order_items', 0]);

  if (!orderItem) {
    return null;
  }

  const orderedAt = moment(orderItem.ordered_at);

  return (
    <Card
      classes={{
        root: 'orders-report__row-mobile',
        content: 'orders-report__row-mobile-content',
      }}
      contentDirection="row"
      onClick={() => OrderSummaryDrawerActions.openDrawer(order.id)}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        alignContent="center"
        justify="space-between"
      >
        <Grid item xs={7}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">
                {orderItem.buyer_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                className="orders-report__row-item_muted"
              >
                {orderedAt.isSame(moment(), 'day')
                  ? t('.date_today', intl, __filenamespace, {
                      date: orderedAt.format('h:mma'),
                    })
                  : orderedAt.format('MM/DD/YYYY • h:mma')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                className="orders-report__row-total"
              >
                <FormattedCurrency value={order.total} fromCents />
                <ChevronRightIcon />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="right"
                className="orders-report__row-item_muted"
              >
                {order.order_number}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

RowMobile.propTypes = {
  intl: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
};

export default RowMobile;
