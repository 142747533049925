import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { Button, Box } from '@mui/material';
import { List } from 'immutable';

import TeamTypeEditingActions from 'shared/actions/TeamTypeEditingActions.jsx';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import Modal from 'components/TeamTypeEditor/Modal.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

function TeamTypeEditor({
  label,
  placeholder,
  selectedTeamTypeId,
  teamTypes,
  onTeamTypeSelect,
  errorText,
  onCreateOrUpdateSuccess,
  intl,
}) {
  const [isModalOpen, setModalState] = useState(false);

  const items = teamTypes?.size
    ? teamTypes
        .map(teamType => ({
          value: teamType?.id,
          label: (
            <Box className="team-type-editor__menu-item">
              <FiberManualRecordIcon sx={{ color: teamType?.color }} />
              <Box>{teamType?.name}</Box>
            </Box>
          ),
        }))
        .toArray()
    : [];

  const canAddCreate = !(
    currentCustomer().restrict_editing && !currentUser().isAdmin()
  );

  const handleEditClick = () => {
    TeamTypeEditingActions.initialLoad(selectedTeamTypeId);
    setModalState(true);
  };

  const handleOnChange = event => {
    if (event.target.value === 0) {
      TeamTypeEditingActions.initialLoad();
      setModalState(true);
    } else {
      onTeamTypeSelect(event.target.value);
    }
  };

  return (
    <Box className="team-type-editor">
      <Dropdown
        fullWidth
        value={selectedTeamTypeId}
        label={label}
        placeholder={placeholder || t('.placeholder', intl, __filenamespace)}
        errorText={errorText}
        onChange={handleOnChange}
        items={[
          ...(canAddCreate
            ? [
                {
                  value: 0,
                  label: t('.new_item', intl, __filenamespace),
                },
              ]
            : []),
          ...items,
        ]}
        name="event-type"
      />
      {canAddCreate && (
        <Modal
          intl={intl}
          isOpen={isModalOpen}
          onSuccess={() => {
            TeamTypeEditingActions.createOrUpdate({
              callback: onCreateOrUpdateSuccess,
            });
            setModalState(false);
          }}
          onAbort={() => setModalState(false)}
        />
      )}
      {canAddCreate && selectedTeamTypeId && (
        <Box className="team-type-editor__edit-button">
          <Button variant="outlined" onClick={handleEditClick}>
            {t('.edit_button', intl, __filenamespace)}
          </Button>
        </Box>
      )}
    </Box>
  );
}

TeamTypeEditor.propTypes = {
  errorText: PropTypes.string.isRequired,
  teamTypes: PropTypes.instanceOf(List).isRequired,
  intl: PropTypes.object.isRequired,
  label: PropTypes.string,
  onCreateOrUpdateSuccess: PropTypes.func.isRequired,
  onTeamTypeSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectedTeamTypeId: PropTypes.number,
};

TeamTypeEditor.defaultProps = {
  label: '',
  placeholder: '',
  selectedTeamTypeId: null,
};

export default injectIntl(TeamTypeEditor);
