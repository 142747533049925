import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, TextField } from '@upperhand/playmaker';
import altContainer from 'shared/hocs/altContainer.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import NoEventsIcon from 'shared/components/icons/empty_states/Events.jsx';
import ContentTable from 'retail/components/OrderHistory/ContentTable.jsx';
import OrderHistoryActions from 'retail/actions/OrderHistoryActions';
import OrderHistoryStore from 'retail/stores/OrderHistoryStore';
import TimeRangeDropdown from 'shared/components/TimeRangeDropdown.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import { t } from 'shared/utils/LocaleUtils';

const styles = {
  container: {
    height: '100vh',
  },
  searchBar: {
    padding: '0 10px',
    margin: '0 0 10px',
    fontSize: 18,
    width: '100%',
    height: 42,
  },
  searchBarIcon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  paginator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

function Content({
  intl,
  orderHistoryStore: {
    isLoading,
    purchaseOrders,
    customerUsers,
    dateFilter,
    search,
    pagination,
    total,
  },
}) {
  return (
    <div style={styles.container}>
      <Grid container item spacing={1} className="retail__order-filters">
        <Grid item>
          <TimeRangeDropdown
            intl={intl}
            onChange={OrderHistoryActions.updateDateFilter}
            value={dateFilter.get('value')}
          />
        </Grid>
        <Grid item xs>
          <TextField
            classes={{
              root: 'retail__search-field',
            }}
            fullWidth
            icon="search"
            onChange={event => OrderHistoryActions.search(event.target.value)}
            placeholder={t('.search', intl, __filenamespace)}
            rounded
            value={search}
          />
        </Grid>
      </Grid>
      <SpinWhileLoading isLoading={isLoading} spinWhile="isLoading">
        {purchaseOrders.size > 0 ? (
          <>
            <ContentTable
              intl={intl}
              purchaseOrders={purchaseOrders}
              customerUsers={customerUsers}
            />
            <div style={styles.paginator}>
              <Paginator
                className="retail__pagination"
                currentPage={pagination.get('page')}
                perPage={pagination.get('per_page')}
                totalCount={total}
                onPageSelect={(page, _) =>
                  OrderHistoryActions.pageChanged(page)
                }
                pageLimit={0}
                showInfo
              />
            </div>
          </>
        ) : (
          <EmptyState
            image={<NoEventsIcon />}
            headerText={t('.empty_header', intl, __filenamespace)}
            messageText={t('.empty_message', intl, __filenamespace)}
          />
        )}
      </SpinWhileLoading>
    </div>
  );
}

export default altContainer({
  stores: {
    orderHistoryStore: OrderHistoryStore,
  },
})(injectIntl(Content));
