import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

export const DIRECTION = {
  top: 1,
  bottom: 2,
};

const styles = {
  scrollbar: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  scrollbarHorizontal: {
    overflowX: 'auto',
    paddingTop: 10,
  },
  scrollbarVertical: {
    overflowY: 'auto',
    paddingRight: 10,
  },
  loader: {
    textAlign: 'center',
    padding: '15px 0',
  },
};

const getBrowserName = () => {
  let name = 'unknown';
  if (navigator.userAgent.indexOf('MSIE') !== -1) {
    name = 'msie';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    name = 'firefox';
  } else if (navigator.userAgent.indexOf('Opera') !== -1) {
    name = 'opera';
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    name = 'chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    name = 'safari';
  }

  return name;
};

const pageScrollContainer =
  getBrowserName() === 'safari' ? document.body : document.documentElement;

class Scrollbar extends React.PureComponent {
  prevScrollHeight = null;

  isScrolledToTop = false;

  isScrolledToBottom = false;

  state = {
    loading: false,
  };

  componentDidMount() {
    const { onScroll, manualHeight, direction } = this.props;

    if (direction === DIRECTION.top) {
      this.scroll.scrollTop =
        this.scroll.scrollHeight - this.scroll.clientHeight;
    }
    if (onScroll) {
      if (manualHeight) {
        this.scroll.addEventListener('scroll', () => {
          this.handleScrollEvent(
            this.scroll.scrollTop,
            this.scroll.clientHeight,
            this.scroll.scrollHeight
          );
        });
      } else {
        window.addEventListener('scroll', () => {
          this.handleScrollEvent(
            window.pageYOffset,
            window.innerHeight,
            pageScrollContainer.scrollHeight
          );
        });
      }

      this.additionLoadingOnLargeScreen();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { loading } = state;
    const { showLoader, dataCount, totalCount } = props;

    if (dataCount !== totalCount && showLoader && loading) {
      return {
        loading: false,
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.canLoadMore()) {
      this.fixScrollPosition();
      this.additionLoadingOnLargeScreen();
    }
  }

  componentWillUnmount() {
    const { manualHeight } = this.props;

    if (manualHeight) {
      window.removeEventListener('scroll', this.scroll);
    } else {
      window.removeEventListener('scroll');
    }
  }

  get loader() {
    return (
      <div style={styles.loader}>
        <FormattedMessage id={messageId('.loading', __filenamespace)} />
      </div>
    );
  }

  canLoadMore = () => {
    const { dataCount, totalCount } = this.props;

    return dataCount < totalCount;
  };

  additionLoadingOnLargeScreen = () => {
    const { onScroll, manualHeight, direction } = this.props;

    if (this.canLoadMore()) {
      if (
        manualHeight &&
        direction === DIRECTION.top &&
        this.scroll.clientHeight === this.scroll.scrollHeight
      ) {
        onScroll();
      }
      if (
        !manualHeight &&
        direction === DIRECTION.bottom &&
        this.scroll.clientHeight < window.innerHeight
      ) {
        onScroll();
      }
    }
  };

  handleScrollEvent = async (
    distanceFromTop,
    innerHeightPage,
    documentScrollHeight
  ) => {
    const { onScroll, showLoader, direction } = this.props;
    const { loading } = this.state;

    this.prevScrollHeight = documentScrollHeight;
    this.isScrolledToTop = distanceFromTop === 0;
    this.isScrolledToBottom =
      distanceFromTop + innerHeightPage >= documentScrollHeight;
    const isEnd =
      direction === DIRECTION.top
        ? this.isScrolledToTop
        : this.isScrolledToBottom;

    if (onScroll && this.canLoadMore() && !loading && isEnd) {
      if (showLoader) {
        await this.setState({ loading: true });
        onScroll();
      } else {
        onScroll();
      }
    }
  };

  fixScrollPosition = () => {
    const { direction, manualHeight } = this.props;

    if (direction === DIRECTION.bottom) {
      return;
    }

    let newScrollHeight;
    if (manualHeight) {
      newScrollHeight = this.scroll.scrollHeight;
    } else {
      newScrollHeight = pageScrollContainer.scrollHeight;
    }

    if (this.isScrolledToBottom) {
      this.scroll.scrollTop =
        this.scroll.scrollHeight - this.scroll.clientHeight;
    } else {
      this.scroll.scrollTop = newScrollHeight - this.prevScrollHeight;
    }
    this.prevScrollHeight = newScrollHeight;
  };

  render() {
    const {
      children,
      style,
      horizontal,
      vertical,
      direction,
      manualHeight,
      onScroll,
      showLoader,
      dataCount,
      totalCount,
      ...props
    } = this.props;
    const { loading } = this.state;

    return (
      <div
        ref={ref => {
          this.scroll = ref;
        }}
        style={{
          ...styles.scrollbar,
          ...(horizontal ? styles.scrollbarHorizontal : {}),
          ...(vertical ? styles.scrollbarVertical : {}),
          ...style,
        }}
        {...props}
      >
        {loading && direction === DIRECTION.top && this.loader}
        {children}
        {loading && direction === DIRECTION.bottom && this.loader}
      </div>
    );
  }
}

Scrollbar.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  manualHeight: PropTypes.bool,
  showLoader: PropTypes.bool,
  onScroll: PropTypes.func,
  direction: PropTypes.number,
  dataCount: PropTypes.number,
  totalCount: PropTypes.number,
};

Scrollbar.defaultProps = {
  children: null,
  style: {},
  horizontal: false,
  vertical: false,
  manualHeight: false,
  showLoader: false,
  onScroll: () => {},
  direction: DIRECTION.bottom,
  dataCount: 0,
  totalCount: 0,
};

export default Scrollbar;
