import debounce from 'lodash.debounce';
import { Set } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import AvailableStaffActions from 'event_mgmt/editing/actions/AvailableStaffActions.jsx';
import AvailableResourceIdCollection from 'shared/records/AvailableResourceIdCollection.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';

class AvailableStaffStore extends UpperHandStore {
  constructor() {
    super();

    this.staffIds = Set();
    this.resourceIds = Set();

    this.bindListeners({
      updateFromEvent: [
        EventActions.fetchSuccess,
        EventActions.updateEventStore,
      ],
      handleList: AvailableStaffActions.LIST,
      handleListSuccess: AvailableStaffActions.LIST_SUCCESS,
      handleListError: AvailableStaffActions.LIST_ERROR,
    });
    this.debouncedHandleList = debounce(this.handleList, 2000);
  }

  updateFromEvent(args) {
    if (isLoggedIn() && this.shouldRefreshList(args)) {
      this.waitFor(EventStore);
      this.debouncedHandleList();
    }
  }

  // args will be an event looking object for fetchSuccess
  // and an array [keyPath, value] for updateEventStore
  shouldRefreshList(args) {
    const keyPath = Array.isArray(args) ? args[0] : [];
    return (
      this.isCreatingOrEditingEvent() &&
      (keyPath.length === 0 ||
        (keyPath.includes('schedules') &&
          !keyPath.includes('customer_user_ids')))
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleList() {
    const { customerEvent } = EventStore.getState();
    const availabilitySchedule =
      customerEvent.schedules.first().availability_schedule;

    if (customerEvent.get('id') && customerEvent.isOpenBooking()) {
      return uhApiClient.get({
        url: `events/${customerEvent.id}/registrations/available_staff`,
        success: AvailableStaffActions.listSuccess,
        error: AvailableStaffActions.listError,
      });
    }
    return uhApiClient.get({
      url: 'available_resources',
      data: {
        availability_schedule: JSON.stringify(availabilitySchedule.toServer()),
        customer_id: currentCustomer().id,
        types: ['staff', 'resources'],
        ignoring_availability_schedule_id: availabilitySchedule.id,
      },
      success: AvailableStaffActions.listSuccess,
      error: AvailableStaffActions.listError,
    });
  }

  handleListSuccess(availableResources) {
    const idCollection = new AvailableResourceIdCollection(availableResources);

    this.staffIds = idCollection.staff_ids;
    this.resourceIds = idCollection.resource_ids;
  }

  handleListError(...args) {
    this.notifyError('error listing available staff', args);
  }

  isCreatingOrEditingEvent() {
    return this.isCreatingEvent() || this.isEditingEvent();
  }

  // eslint-disable-next-line class-methods-use-this
  isCreatingEvent() {
    return /events\/new/.test(window.location.pathname);
  }

  // eslint-disable-next-line class-methods-use-this
  isEditingEvent() {
    return /events\/.+\/edit/.test(window.location.pathname);
  }
}

export default alt.createStore(AvailableStaffStore, 'AvailableStaffStore');
