import {
  Iterable,
  Map,
  Set,
  List,
  OrderedMap,
  Range,
  Record,
  fromJS,
} from 'immutable';
import moment from 'moment-timezone';

import Session from 'event_mgmt/shared/records/MagicSession';
import {
  convertDateToClientValue,
  convertDateToServerValue,
  beginningOfWeek,
  toLocalDate,
  dayOfWeek,
  daytimeToTimeRange,
  timeRangeFromHour,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const convertObjectFromServer = obj => {
  const objRef = { ...obj };

  objRef.exclusions = (objRef.exclusions || []).map(e =>
    convertDateToClientValue(e)
  );
  objRef.start_date = convertDateToClientValue(objRef.start_date);
  objRef.end_date = convertDateToClientValue(objRef.end_date);
  objRef.stops_by_date = convertDateToClientValue(objRef.end_date);

  const differentStartAtTimes = List(Object.values(objRef.daytimes))
    .flatMap(times => times.map(time => time.start_time))
    .toSet();

  objRef.daytimesAreUnique = differentStartAtTimes.size > 1;
  if (!objRef.end_date) {
    objRef.repeat_mode = 'never';
  } else if (objRef.repeat_duration) {
    objRef.repeat_mode = 'occurrences';
  } else {
    objRef.repeat_mode = 'until';
    objRef.repeat_duration = 1;
  }

  objRef.indefinite = !(objRef.end_date || objRef.frequency);

  return objRef;
};

class AvailabilitySchedule extends Record({
  id: null,
  start_date: '',
  end_date: '',
  indefinite: true,
  daytimes: Map({
    none: List([Map({ start_time: '', end_time: '' })]),
  }),
  date_specific_daytimes: Map(),
  daytimesAreUnique: false,
  exclusions: List(),
  frequency: null,
  interval: 1,
  repeat_duration: 1,
  repeat_mode: 'until',
  stops_by_date: '',
  owner_id: null,
}) {
  constructor(obj = {}, options = { fromServer: false }) {
    let objRef = Iterable.isIterable(obj) ? obj.toJS() : { ...obj };

    if (options.fromServer) {
      objRef = convertObjectFromServer(objRef);
    }

    const daytimes = fromJS(
      objRef.daytimes || { none: [{ start_time: '', end_time: '' }] }
    );
    const exclusions = fromJS(objRef.exclusions || []).sort();
    const dateSpecificDaytimes = fromJS(objRef.date_specific_daytimes || {});

    super(
      merge(objRef, {
        daytimes,
        exclusions,
        date_specific_daytimes: dateSpecificDaytimes,
      })
    );
  }

  occursDuringHour(hour, date) {
    if (!date) {
      return false;
    }
    try {
      const dayTimesForDate = this.daytimesFor(toLocalDate(date));
      if (!dayTimesForDate) {
        return false;
      }

      const hourRange = timeRangeFromHour(hour, date);

      return !!dayTimesForDate.find(daytime => {
        const timeRange = daytimeToTimeRange(daytime, date);
        return timeRange.overlaps(hourRange);
      });
    } catch (error) {
      return false;
    }
  }

  startsDuringHour(hour, date) {
    if (!this.occursDuringHour(hour, date)) {
      return false;
    }
    const dayTimesForDate = this.daytimesFor(
      toLocalDate(date || this.start_date)
    );
    return !!dayTimesForDate.find(daytime => {
      const startHour = parseInt(daytime.get('start_time').split(':')[0], 10);
      return startHour === hour;
    });
  }

  includeTime(date, respectDaytimes) {
    if (!this.includeDate(date, respectDaytimes)) {
      return false;
    }

    const momentDate = moment(date || this.start_date);
    const dayTimesForDate = this.daytimesFor(toLocalDate(date));

    if (!dayTimesForDate) {
      return false;
    }

    return !!dayTimesForDate.find(daytime => {
      const timeRange = daytimeToTimeRange(daytime, momentDate);

      return timeRange.contains(momentDate);
    });
  }

  isDateSpecific(date) {
    return this.date_specific_daytimes.has(convertDateToServerValue(date));
  }

  includeDate(d, respectDaytimes) {
    if (!d) return false;

    const date = toLocalDate(d);
    const localStartDate = this.start_date
      ? toLocalDate(this.start_date)
      : null;
    const localEndDate = this.end_date ? toLocalDate(this.end_date) : null;

    return (
      !!localStartDate &&
      (date.equals(localStartDate) || date.isAfter(localStartDate)) &&
      (!localEndDate ||
        date.equals(localEndDate) ||
        date.isBefore(localEndDate)) &&
      this.isDateInIncludedWeekdays(date, respectDaytimes) &&
      !this.isDateExcluded(date) &&
      (this.interval === 1 || this.isDateInIncludedWeek(date, localStartDate))
    );
  }

  scheduledRepeatingDates() {
    const days = this.daysInScheduleRange();
    const weekdays = this.repeatingWeekdays().map(d => parseInt(d, 10));

    return days
      .toSet()
      .filter(d => weekdays.contains(new Date(d).getDay()))
      .map(d => new Date(d));
  }

  scheduledSpecificDates() {
    return this.date_specific_daytimes
      .map((times, date) => Date.parse(convertDateToClientValue(date)))
      .toSet();
  }

  scheduledDates() {
    return this.scheduledSpecificDates().merge(this.scheduledRepeatingDates());
  }

  daysInScheduleRange() {
    const oneDay = 60 * 60 * 24 * 1000;
    if (!this.end_date) {
      if (this.start_date) {
        const value = moment(this.start_date).valueOf();
        return new Range(value, value + 1);
      }
      return new Range(0, 7 * oneDay, oneDay);
    }
    const endDate = moment(this.end_date);
    const startDate = moment(this.start_date);

    return Range(startDate.valueOf(), endDate.valueOf() + oneDay, oneDay);
  }

  repeatingWeekdays() {
    return Set(this.daytimes)
      .map(v => v[0])
      .filter(d => d !== 'none' && d !== 'unique');
  }

  dailyHours() {
    if (!this.start_date || !this.end_date || !this.daytimes.size) {
      return OrderedMap();
    }

    const localStartDate = this.start_date
      ? toLocalDate(this.start_date)
      : null;
    const localEndDate = this.end_date ? toLocalDate(this.end_date) : null;

    const dailyTimes = OrderedMap()
      .withMutations(_dailyTimes => {
        // Using this loop seems to have solved the issue for the specific setup.
        //   Client: CDT
        //   Customer: MDT
        //   Computer: EDT
        for (
          let d = localStartDate;
          d.isBefore(localEndDate.plusDays(1));
          d = d.plusDays(1)
        ) {
          // We do this check twice, here and then again in daytimesFor(). Can we avoid that?
          if (this.includeDate(d)) {
            _dailyTimes.set(moment(d.toString()), this.daytimesFor(d));
          }
        }
      })
      .filter(v => !!v);

    return dailyTimes;
  }

  daytimesFor(d) {
    const date = toLocalDate(d);
    if (!this.includeDate(date)) {
      return [];
    }
    return (
      this.daytimesForSpecificDate(date) ||
      this.daytimesForRepeatingWeekday(date) ||
      []
    );
  }

  daytimesForSpecificDate(localDate) {
    return this.date_specific_daytimes.get(convertDateToServerValue(localDate));
  }

  daytimesForRepeatingWeekday(localDate) {
    return this.daytimes.get(dayOfWeek(localDate).toString());
  }

  areAllStartTimesPresent() {
    return this.daytimes
      .merge(this.date_specific_daytimes)
      .every((timeRangeList, _) =>
        timeRangeList.every(timeRange => timeRange.get('start_time'))
      );
  }

  areAllEndTimesPresent() {
    return this.daytimes
      .merge(this.date_specific_daytimes)
      .every((timeRangeList, _) =>
        timeRangeList.every(timeRange => timeRange.get('end_time'))
      );
  }

  areAllTimesValid() {
    return this.daytimes
      .merge(this.date_specific_daytimes)
      .every((timeRangeList, _) =>
        timeRangeList.every(timeRange => {
          const start = moment(timeRange.get('start_time'), 'hh:mm:ss');
          const end = moment(timeRange.get('end_time'), 'hh:mm:ss');

          return start.isBefore(end, 'minutes');
        })
      );
  }

  isDateInIncludedWeekdays(localDate, respectDaytimes) {
    return (
      (!this.frequency && !respectDaytimes) ||
      this.daytimes.has(dayOfWeek(localDate).toString()) ||
      this.isDateSpecific(localDate)
    );
  }

  isDateInIncludedWeek(localDate, localStartDate) {
    return (
      !this.frequency ||
      Math.trunc(beginningOfWeek(localStartDate).daysUntil(localDate) / 7) %
        this.interval ===
        0
    );
  }

  isDateExcluded(localDate) {
    return this.exclusions.some(d => toLocalDate(d).equals(localDate));
  }

  // eslint-disable-next-line class-methods-use-this
  timezone() {
    return currentCustomer().tz_name || moment.tz.guess();
  }

  momentDate(date) {
    return moment.tz(date || this.start_date, this.timezone());
  }

  containsDateTime(date, time) {
    return (
      this.includeDate(date.toDate(), false) && this.matchesTime(date, time)
    );
  }

  matchesTime(date, time) {
    const daytimesForDay = this.daytimesFor(this.momentDate(date));
    return daytimesForDay && daytimesForDay.contains(time);
  }

  isDaytimeValid() {
    return this.get('daytimes').every(dt =>
      dt.every(
        t =>
          t.get('start_time') &&
          t.get('end_time') &&
          !!t.get('start_time').length &&
          !!t.get('end_time').length
      )
    );
  }

  isMultiDay() {
    return moment(this.end_date).isAfter(moment(this.start_date));
  }

  sessions() {
    return List().withMutations(sessions =>
      this.dailyHours().forEach((times, date) => {
        times.forEach(startEndPair => {
          const startTime = moment(
            startEndPair.get('start_time')?.substring(0, 5),
            'HH:mm'
          );
          const endTime = moment(
            startEndPair.get('end_time')?.substring(0, 5),
            'HH:mm'
          );

          if (startTime.isValid() && endTime.isValid()) {
            sessions.push(
              new Session({
                startDateTime: date
                  .clone()
                  .hour(startTime.hour())
                  .minute(startTime.minute()),
                endDateTime: date
                  .clone()
                  .hour(endTime.hour())
                  .minute(endTime.minute()),
              })
            );
          }
        });
      })
    );
  }

  toServer() {
    const serverValue = this.toJS();

    serverValue.exclusions = serverValue.exclusions.map(exclusion =>
      convertDateToServerValue(exclusion)
    );
    if (this.repeat_mode === 'until') serverValue.repeat_duration = null;

    const serverDaytimes = this.daytimes.filter((_, k) => k !== 'unique');

    serverValue.daytimes = serverDaytimes.toJS();

    return serverValue;
  }
}

AvailabilitySchedule.fromServer = data =>
  new AvailabilitySchedule(data, { fromServer: true });

export default AvailabilitySchedule;
