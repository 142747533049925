import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ClockOut({ color, style, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-135.000000, -610.000000)">
          <g transform="translate(103.000000, 426.000000)">
            <g transform="translate(32.000000, 184.000000)">
              <g>
                <path
                  d="M39.234,19.617 C39.234,8.783 30.451,0 19.617,0 C8.783,0 0,8.783 0,19.617 C0,
                   30.451 8.783,39.234 19.617,39.234 C30.451,39.234 39.234,30.451 39.234,19.617"
                  fill={(style && style.color) || color}
                />
                <rect
                  fill="var(--color-white)"
                  x="14"
                  y="14"
                  width="12"
                  height="12"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

ClockOut.defaultProps = {
  color: '#9A9EAD',
};

export default ClockOut;
