import * as React from 'react';

import ClientPortal from 'client_portal/components/ClientPortal.jsx';
import EventMgmtIndex from 'event_mgmt/index/components/EventMgmtIndex.jsx';
import StaffMembershipListing from 'memberships/components/StaffMembershipListing.jsx';
import StaffCreditPassListing from 'credit_passes/components/StaffCreditPassListing.jsx';

import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import TeamMgmtIndex from 'team_mgmt/index/components/TeamMgmtIndex.jsx';

export function EventPage(props) {
  const readOnly = !hasAdminPermission();
  const { path } = props;
  const useStaffComponent = currentUser().isStaff() || currentUser().isCoach();
  const PageContent = useStaffComponent ? EventMgmtIndex : ClientPortal;

  return <PageContent path={path} readOnly={readOnly} />;
}

export function TeamPage(props) {
  const readOnly = !hasAdminPermission();
  const { path } = props;
  const useStaffComponent = currentUser().isStaff() || currentUser().isCoach();
  const PageContent = useStaffComponent ? TeamMgmtIndex : ClientPortal;

  return <PageContent path={path} readOnly={readOnly} />;
}

export function MembershipListPage() {
  const readOnly = !hasAdminPermission();

  if (currentUser().isStaff()) {
    return <StaffMembershipListing readOnly={readOnly} />;
  }

  return <ClientPortal readOnly />;
}

export function CreditPassesPage() {
  const readOnly = !hasAdminPermission();

  if (currentUser().isStaff()) {
    return <StaffCreditPassListing readOnly={readOnly} />;
  }

  return <ClientPortal readOnly />;
}
