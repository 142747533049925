import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Classes({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g
        id="Classes"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <g id="Export-Clients" fill={color}>
          <g id="Left-Nav">
            <path
              d="M21 20.25C21 20.665 20.664 21 20.25 21H3.75C3.335 21 3 20.665 3 20.25V6.75C3 6.336 3.335 6 3.75 6H20.25C20.664 6 21 6.336 21 6.75V20.25ZM7 8.90899V10.728C7 11.23 7.406 11.636 7.909 11.636H17C17.502 11.636 17.909 11.23 17.909 10.728V8.90899C17.909 8.40699 17.502 7.99999 17 7.99999H7.909C7.406 7.99999 7 8.40699 7 8.90899ZM7 15.909V17.728C7 18.23 7.406 18.636 7.909 18.636H17C17.502 18.636 17.909 18.23 17.909 17.728V15.909C17.909 15.407 17.502 15 17 15H7.909C7.406 15 7 15.407 7 15.909ZM22.5 3H18V1.485C18 0.665 17.334 0 16.515 0H16.484C15.664 0 15 0.665 15 1.485V3H9V1.485C9 0.665 8.334 0 7.515 0H7.484C6.664 0 6 0.665 6 1.485V3H1.5C0.671 3 0 3.672 0 4.5V22.5C0 23.329 0.671 24 1.5 24H22.5C23.328 24 24 23.329 24 22.5V4.5C24 3.672 23.328 3 22.5 3Z"
              id="Classes"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Classes.defaultProps = {
  color: '#9A9EAD',
};

export default Classes;
