import { List } from 'immutable';
import FeeRate from 'shared/records/FeeRate';
import FeeRateCreationActions from 'shared/actions/fee_rates/FeeRateCreationActions';
import FeeRateEditingActions from 'shared/actions/fee_rates/FeeRateEditingActions';
import FeeRateListingActions from 'shared/actions/fee_rates/FeeRateListingActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class FeeRateListingStore extends UpperHandStore {
  constructor() {
    super();

    this.feeRates = List();
    this.isLoading = false;
    this.bindListeners({
      list: FeeRateListingActions.LIST,
      listSuccess: FeeRateListingActions.LIST_SUCCESS,
      listError: FeeRateListingActions.LIST_ERROR,

      rateCreated: FeeRateCreationActions.SAVE_SUCCESS,
      rateUpdated: FeeRateEditingActions.SAVE_SUCCESS,
    });
  }

  list() {
    this.isLoading = true;
    uhApiClient.get({
      url: 'service_fees',
      success: FeeRateListingActions.listSuccess,
      error: FeeRateListingActions.listError,
    });
  }

  listSuccess(data) {
    const { service_fees: FeeRates } = data;

    this.feeRates = List(FeeRates.map(t => new FeeRate(t))).sortBy(t => t.name);
    this.isLoading = false;
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing tax codes', args);
  }

  rateCreated(data) {
    this.feeRates = this.feeRates.push(new FeeRate(data)).sortBy(t => t.name);
  }

  rateUpdated(data) {
    const index = this.feeRates.findIndex(c => c.id === data.id);

    this.feeRates = this.feeRates
      .set(index, new FeeRate(data))
      .sortBy(t => t.name);
  }
}

export default alt.createStore(FeeRateListingStore, 'FeeRateListingStore');
