import { List } from 'immutable';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';

class ApiErrorDialogStore {
  constructor() {
    this.errors = List();
    this.errorAcked = true;
    this.bindListeners({
      handleError: ApiErrorDialogActions.error,
      handleAckError: ApiErrorDialogActions.ackError,
    });
  }

  handleError(err) {
    this.errorAcked = false;
    this.errors = this.errors.push(err);
  }

  handleAckError() {
    this.errorAcked = true;
    this.errors = List();
  }
}

export default alt.createStore(ApiErrorDialogStore, 'ApiErrorDialogStore');
