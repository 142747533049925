export default {
  name: 'Community Fieldhouse Sports and Events',
  siteURL: 'https://communityfieldhouse.com',
  logo: {
    url: 'https://upperhand-app.s3.amazonaws.com/static/images/community_fieldhouse.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#FFC000',
    mobileSignInColor: '#FFC000',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#FFC000',
    accent1Color: '#808080',

    primary: {
      main: '#FFC000',
    },
    secondary: {
      main: '#808080',
    },
  },
  sideNav: {
    selectedColor: '#FFC000',
  },
  icons: {
    cart: '#FFC000',
  },
  statusBar: {
    backgroundColor: '#FFC000',
  },
};
