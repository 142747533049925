import * as React from 'react';
import Button from '@mui/material/Button';

import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';

const cancel = e => {
  e.preventDefault();
  QuickScheduleActions.close();
};

const save = e => {
  e.preventDefault();
  QuickScheduleActions.updateRegistration();
};

const saveAndCharge = e => {
  e.preventDefault();
  QuickScheduleActions.saveAndCharge();
};

function ScheduleActions({
  qsClientStore: { clientIsValid },
  quickScheduleStore: {
    creatingOrderItem,
    creditsRemaining,
    selectedClient,
    selectedEvent,
    selectedResources,
    selectedStaff,
    registrationPackage,
    packagePricingDescription,
  },
}) {
  const disable =
    creatingOrderItem ||
    !selectedClient ||
    !clientIsValid ||
    !selectedEvent ||
    (selectedStaff.isEmpty() &&
      !selectedEvent.schedules.get(0).customer_user_ids.isEmpty()) ||
    // TODO Seems like selectedResources should be defined if selectedStaff is defined
    ((!selectedResources || selectedResources.isEmpty()) &&
      !selectedEvent.schedules.get(0).schedule_resources.isEmpty()) ||
    (packagePricingDescription &&
      !registrationPackage.automation_option_uuid &&
      creditsRemaining <= 0);

  return (
    <FlexBox style={{ justifyContent: 'flex-end', padding: '10px 5px' }}>
      <Button
        variant="outlined"
        onClick={cancel}
        disabled={creatingOrderItem}
        sx={{ marginRight: '16px' }}
      >
        Cancel
      </Button>

      {creditsRemaining > 0 ? (
        <Button variant="contained" onClick={save} disabled={disable}>
          Save
        </Button>
      ) : (
        <Button variant="contained" onClick={saveAndCharge} disabled={disable}>
          Continue to Payment
        </Button>
      )}
    </FlexBox>
  );
}

export default ScheduleActions;
