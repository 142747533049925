import FeeRateCreationActions from 'shared/actions/fee_rates/FeeRateCreationActions';
import FeeRateEditingStoreInterface from 'shared/stores/fee_rates/FeeRateEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  FeeRateEditingStoreInterface,
  'FeeRateCreationStore',
  {
    actions: FeeRateCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
