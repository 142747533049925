import moment from 'moment-timezone';
import { List } from 'immutable';

import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSPurchasedRegistrationActions from 'point_of_sale/actions/POSPurchasedRegistrationActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';

import { list as listRegistrations } from 'sources/RegistrationSource';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';

import POSStore from 'point_of_sale/stores/POSStore.jsx';
import POSCartStore from 'point_of_sale/stores/POSCartStore.jsx';
import POSEventStore from 'point_of_sale/stores/POSEventStore.jsx';
import RegistrationPackageSchedulingStore from 'shared/stores/RegistrationPackageSchedulingStore.jsx';

import Registration from 'shared/records/Registration';
import TentativeRegistration from 'shared/records/TentativeRegistration';
import Client from 'shared/records/Client.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

class POSSchedulingStore extends RegistrationPackageSchedulingStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      selectEvent: [
        POSProductListActions.EVENT_SELECTED,
        POSEventActions.FETCH_EVENT_SUCCESS,
      ],

      setOrderItemId: POSCartActions.ITEM_EDIT_SELECTED,

      getExistingPackageFromCart: POSCartActions.FETCH_SUCCESS,

      setPurchasedRegistrations:
        POSSchedulingActions.LIST_PURCHASED_REGISTRATIONS_SUCCESS,
      listPurchasedRegistrationsError:
        POSSchedulingActions.LIST_PURCHASED_REGISTRATIONS_ERROR,

      registrationScheduled: RegistrationActions.SCHEDULE_SUCCESS,
      registrationCancelled: RegistrationActions.CANCEL_SUCCESS,

      setAutomationDescriptions: POSEventActions.AUTOMATION_LIST_SUCCESS,

      selectPaymentPlan: POSSchedulingActions.PAYMENT_PLAN_SELECTED,

      selectProfile: [
        POSSchedulingActions.PROFILE_SELECTED,
        POSSchedulingActions.PROFILE_CREATED,
      ],
      removeProfile: POSSchedulingActions.PROFILE_REMOVED,

      selectProfileSingleSession: [
        POSSchedulingActions.SINGLE_SESSION_ATTENDEE_ADDED,
        POSSchedulingActions.SINGLE_SESSION_PROFILE_CREATED,
      ],

      removeSingleSessionAttendee:
        POSSchedulingActions.SINGLE_SESSION_ATTENDEE_REMOVED,
      registerSession: POSSchedulingActions.SINGLE_SESSION_ADDED,
      unregisterSession: POSSchedulingActions.SINGLE_SESSION_REMOVED,
      registerAllDays: POSSchedulingActions.ALL_AVAILABLE_SINGLE_SESSIONS_ADDED,
      unregisterAllDays:
        POSSchedulingActions.ALL_AVAILABLE_SINGLE_SESSIONS_REMOVED,

      selectDefaultPackage: POSSchedulingActions.DEFAULT_PACKAGE_SELECTED,
      selectAutomatedPackage: POSSchedulingActions.AUTOMATED_PACKAGE_SELECTED,

      beginScheduling: POSSchedulingActions.SCHEDULE_NOW_CLICKED,
      stopScheduling: POSSchedulingActions.SCHEDULE_LATER_CLICKED,

      setRegistrationStaff: POSSchedulingActions.REGISTRATION_STAFF_CHANGED,
      setRegistrationDate: POSSchedulingActions.REGISTRATION_DATE_CHANGED,
      setRegistrationTime: POSSchedulingActions.REGISTRATION_TIME_CHANGED,
      bookRegistration: POSSchedulingActions.REGISTRATION_BOOKED,
      setRegistrationAthlete: [
        POSSchedulingActions.REGISTRATION_ATHLETE_CHANGED,
        POSSchedulingActions.PROFILE_CREATED_OPEN_BOOKING,
      ],

      handleCancelRegistration: POSSchedulingActions.REGISTRATION_CANCELLED,

      setManagedProfiles: POSActions.MANAGED_PROFILES_LIST_SUCCESS,

      setSingleSessions: POSEventActions.FETCH_AVAILABILITY_SUCCESS,

      resetPackage: POSSchedulingActions.ADD_ATTENDEE_CLICKED,

      reset: [
        POSCartActions.ADD_SUCCESS,
        POSCartActions.UPDATE_SUCCESS,
        POSActions.CLIENT_BACK_CLICKED,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
        POSEventActions.EVENT_SCHEDULING_CANCEL_CLICKED,
        POSSchedulingActions.VIEW_IN_CART_CLICKED,
        POSPurchasedRegistrationActions.COMPLETE_BUTTON_CLICKED,
        POSSchedulingActions.classScheduleBookSuccess,
      ],

      classScheduleBookSuccess: POSSchedulingActions.classScheduleBookSuccess,

      showPurchasedRegistrations:
        POSSchedulingActions.SCHEDULE_REMAINING_CLICKED,
      hidePurchasedRegistrations: [
        POSPurchasedRegistrationActions.BACK_BUTTON_CLICKED,
      ],

      setPurchasedRegistrationAthlete: [
        POSPurchasedRegistrationActions.PROFILE_CREATED,
        POSPurchasedRegistrationActions.REGISTRATION_ATHLETE_CHANGED,
      ],

      setRepeatBookingRegistrationPackage:
        POSPurchasedRegistrationActions.setRepeatBookingRegistrationPackage,

      showClassScheduler: POSSchedulingActions.classScheduleClicked,
    });
  }

  reset() {
    super.reset();

    /*
     *  From super:
     *
     * this.registrationPackage            = new RegistrationPackage();
     * this.selectedClientId               = null;
     * this.changed                        = false;
     * this.packageIsValid                 = false;
     * this.isSchedulingNow                = false;
     *
     * this.event                          = null;
     * this.eventSchedule                  = null;
     * this.paymentPlanDescription         = null;
     * this.packagePricingDescription      = null;
     * this.automationsLoaded              = false;
     * this.totalPrice                     = 0;
     *
     * this.orderItemId                    = null;
     * this.purchasedRegistrations         = List();
     * this.registeredAthleteIds           = Set();
     * this.tentativeAthleteIds            = Set();
     *
     * this.allowSingleSessionPurchase     = false;
     * this.rawSessionAvailability                 = List();
     * this.sessionAvailability            = Map();
     * this.singleSessionRegistrationCount = 0;
     * this.allDayRegistrationCount        = 0;
     */

    this.client = null;
    this.managedClients = List();
    this.cartChecked = false;

    this.availableProfiles = List();
    this.selectedProfiles = List();

    this.displayRegistrationPackage = false;
    this.purchasedRegistrationSelectedClient = new Client();
    this.purchasedRegistrationSelectedClientId = null;
    this.clientPurchasedRegistrations = List();
  }

  // eslint-disable-next-line class-methods-use-this
  classScheduleBookSuccess() {
    const message = new TranslatableMessage({
      id: 'point_of_sale.EventScheduling.book_success',
    });

    MessageWindowActions.addMessage.defer(message);
  }

  setRepeatBookingRegistrationPackage(value) {
    const newData = value.map(item => new TentativeRegistration(item));
    this.registrationPackage = this.registrationPackage.set(
      'tentative_details',
      List(newData)
    );
  }

  selectEvent() {
    this.waitFor(POSStore);
    this.waitFor(POSEventStore);

    const posStoreState = POSStore.getState();

    this.client = posStoreState.selectedClient;
    this.managedClients = posStoreState.managedProfiles;
    this.event = POSEventStore.getState().event;
    this.eventSchedule = this.event.schedules.first();
    this.allowSingleSessionPurchase = this.event.allow_single_session_purchase;
    this.registrationPackage = this.registrationPackage
      .set('event_id', this.event.id)
      .set('single_session_price', this.event.single_session_price * 100)
      .set('all_sessions_price', this.event.price * 100);

    if (posStoreState.originalClient) {
      this.purchasedRegistrationSelectedAthleteId =
        posStoreState.originalClient.id;
    }

    this.getExistingPackageFromCart();
    this.setAvailableProfiles();
    this.listPurchasedRegistrations({ per_page: 50 });
  }

  listPurchasedRegistrations(
    { page, per_page: perPage } = { page: 1, per_page: 10 }
  ) {
    return listRegistrations({
      params: {
        event_ids: [this.event.id],
        purchasing_client_ids: [this.client.id],
        start_time: moment().format(),
        per_page: perPage,
        page,
      },
      success: POSSchedulingActions.listPurchasedRegistrationsSuccess,
      error: POSSchedulingActions.listPurchasedRegistrationsError,
    });
  }

  setPurchasedRegistrations(data) {
    this.purchasedRegistrations = this.purchasedRegistrations
      .concat(List(data.registrations.map(r => new Registration(r))))
      .toSet()
      .toList();

    if (this.purchasedRegistrations.size < data.total_count) {
      this.listPurchasedRegistrations({
        page: data.page + 1,
        per_page: data.per_page,
      });
    } else {
      this.purchasedRegistrationsLoaded = true;

      this.registeredAthleteIds = this.purchasedRegistrations
        .map(r => r.client_id)
        .toSet();

      this.setAvailableProfiles();
      this.setSingleSessions();
      this.setRegistrationsForCurrentClient();
    }
  }

  registrationScheduled(data) {
    data.forEach(registrationData => {
      const registration = new Registration(registrationData);
      const index = this.purchasedRegistrations.findIndex(
        r => r.id === data.id
      );

      if (index >= 0) {
        this.purchasedRegistrations = this.purchasedRegistrations.update(
          index,
          () => registration
        );
      } else {
        this.purchasedRegistrations =
          this.purchasedRegistrations.push(registration);
      }
    });

    this.setRegistrationsForCurrentClient();
  }

  registrationCancelled([_, registration]) {
    const index = this.purchasedRegistrations.findIndex(
      r => r.id === registration.id
    );

    if (index >= 0) {
      this.purchasedRegistrations = this.purchasedRegistrations.delete(index);
    }

    this.setRegistrationsForCurrentClient();
  }

  listPurchasedRegistrationsError(...args) {
    this.notifyError(
      `error while fetching registrations for client ${this.client.id} for event ${this.event.id}`,
      args
    );
  }

  setAutomationDescriptions() {
    this.waitFor(POSEventStore);

    const eventStoreState = POSEventStore.getState();

    this.paymentPlanDescription = eventStoreState.paymentPlanDescription;
    this.packagePricingDescription = eventStoreState.packagePricingDescription;
    this.automationsLoaded = true;

    super.setTotalPrice();
    this.validatePackage();
  }

  setOrderItemId(orderItem) {
    this.orderItemId = orderItem.id;
  }

  /* FIXED SCHEDULE */
  selectProfile(customerUserId) {
    super.addProfileFixedSchedule(customerUserId);

    this.tentativeAthleteIds = this.registrationPackage.client_ids;

    this.setAvailableProfiles();
  }

  removeProfile(customerUserId) {
    super.removeProfileFixedSchedule(customerUserId);

    if (this.changed) {
      this.tentativeAthleteIds = this.registrationPackage.client_ids;

      this.setAvailableProfiles();
    }
  }

  setAvailableProfiles() {
    this.allProfiles = this.managedClients
      .push(this.client)
      .filter(c => c && c.type === 'Client');

    this.selectedProfiles = this.allowSingleSessionPurchase
      ? this.allProfiles.filter(
          c =>
            this.tentativeAthleteIds.has(c.id) ||
            this.registeredAthleteIds.has(c.id)
        )
      : this.allProfiles.filter(c => this.tentativeAthleteIds.has(c.id));

    const authorizedProfiles = this.allProfiles.filter(
      c => c.hasAccessTo(this.event) && !c.get('archived')
    );
    const isOpenBookingEvent =
      this.event !== null && this.event.isOpenBooking();

    this.availableProfiles = isOpenBookingEvent
      ? authorizedProfiles
      : authorizedProfiles.filter(
          client =>
            !this.registeredAthleteIds.has(client.id) &&
            !this.tentativeAthleteIds.has(client.id) &&
            !this.event.participant_ids.has(client.id) &&
            !this.event.registered_client_ids.has(client.id)
        );
  }

  /* SINGLE SESSION PURCHASE */

  selectProfileSingleSession(clientId) {
    super.addProfileFixedSchedule(clientId);

    // We need to add the client to the tentativeAthleteIds manually here
    // so that they show up in the list of selected profiles even if they
    // do not get any registrations due to the event being full.
    this.tentativeAthleteIds = this.tentativeAthleteIds.add(clientId);

    this.setAvailableProfiles();
    super.setTotalPrice();
  }

  removeSingleSessionAttendee(clientId) {
    super.removeProfileFixedSchedule(clientId);

    // We need to remove the client from the tentativeAthleteIds manually here
    // so that we do not remove other athletes that have been added but do not
    // currently have any sessions selected.
    this.tentativeAthleteIds = this.tentativeAthleteIds.remove(clientId);

    this.setAvailableProfiles();
    super.setTotalPrice();
  }

  registerAllDays(clientId) {
    super.registerAllDays(clientId);

    if (!this.allowSingleSessionPurchase) {
      this.tentativeAthleteIds = this.registrationPackage.client_ids;
    }
    this.setAvailableProfiles();
    super.setTotalPrice();
  }

  setSingleSessions() {
    this.waitFor(POSEventStore);

    this.rawSessionAvailability = POSEventStore.getState().individualSessions;

    if (this.allowSingleSessionPurchase) {
      super.setupSingleSessionPurchase();
    }
  }

  /* OPEN BOOKING */

  selectDefaultPackage(size) {
    super.selectDefaultPackage(size);
  }

  selectAutomatedPackage(uuid) {
    super.selectAutomatedPackage(uuid);
  }

  handleCancelRegistration(index) {
    super.cancelRegistration(index);
  }

  /* HELPERS */

  markClean() {
    this.changed = false;
  }

  setManagedProfiles() {
    this.waitFor(POSStore);

    this.managedClients = POSStore.getState().managedProfiles;

    if (this.event) {
      this.setAvailableProfiles();
    }
  }

  getExistingPackageFromCart() {
    this.waitFor(POSCartStore);

    const byEvent = oi => oi.getIn(['orderable', 'event_id']) === this.event.id;
    const byId = oi => oi.id === this.orderItemId;

    const { cart, cartLoaded } = POSCartStore.getState();

    if (this.event && cartLoaded && !this.cartChecked) {
      const orderItems = cart.get('order_items', List());
      const cartItem =
        this.event.isOpenBooking() || this.orderItemId
          ? orderItems.find(byId)
          : orderItems.find(byEvent);

      if (cartItem) {
        this.registrationPackage = cartItem.orderable;
        this.orderItemId = cartItem.id;
        this.tentativeAthleteIds = this.registrationPackage.client_ids;
        this.setAvailableProfiles();

        if (this.event.isOpenBooking()) {
          this.setRegistrationAthlete(
            this.registrationPackage.client_ids.first()
          );
        }
      }

      this.cartChecked = true;

      this.setSingleSessions();
      super.setTotalPrice();
      this.validatePackage();
    }
  }

  showPurchasedRegistrations(customerUser) {
    this.displayRegistrationPackage = true;
    this.purchasedRegistrationSelectedClient = customerUser || new Client();
    this.setRegistrationsForCurrentClient();
  }

  hidePurchasedRegistrations() {
    this.displayRegistrationPackage = false;
    this.purchasedRegistrationSelectedClient = null;
    this.clientPurchasedRegistrations = List();
  }

  setPurchasedRegistrationAthlete(customerUserId) {
    if (this.purchasedRegistrationSelectedClient.id) {
      this.purchasedRegistrationSelectedClientId =
        this.purchasedRegistrationSelectedClient.id;
    } else {
      this.purchasedRegistrationSelectedClientId = customerUserId;
    }
  }

  setRegistrationsForCurrentClient() {
    if (!this.purchasedRegistrationSelectedClient) {
      return;
    }

    this.clientPurchasedRegistrations = this.purchasedRegistrations.filter(
      r => r.client_id === this.purchasedRegistrationSelectedClient.id
    );
  }

  showClassScheduler(client) {
    this.selectedClientId = client.id;
  }
}

export default alt.createStore(POSSchedulingStore, 'POSSchedulingStore');
