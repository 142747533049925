import React from 'react';
import { useIntl } from 'react-intl';
import { Card, Typography } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { t } from 'shared/utils/LocaleUtils';

function AccountCredits({ accountCredits = 0 }) {
  const intl = useIntl();

  return (
    <Card
      classes={{
        root: 'client-billing-credits',
        content: 'client-billing-credits__content',
      }}
    >
      <Typography variant="h5" className="client-billing-credits__title">
        {t('.account_credit', intl, __filenamespace)}
      </Typography>
      <Typography
        variant="body1"
        className="client-billing-credits__description"
      >
        {t('.account_credit_description', intl, __filenamespace)}
      </Typography>
      <Typography variant="h4">
        <FormattedCurrency value={accountCredits} fromCents />
      </Typography>
    </Card>
  );
}

export default AccountCredits;
