import { List, Record } from 'immutable';

import CalendarViewSettings from 'calendar/records/CalendarViewSettings.jsx';
import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';

class CalendarView extends Record({
  id: undefined,
  customer_user_id: undefined,
  title: 'New calendar view',
  calendar_type: CALENDAR_TYPE.DAY,
  settings: undefined,
  default_view: false,
}) {
  constructor(props = {}) {
    const settings = new CalendarViewSettings(props.settings);

    super({ ...props, settings });
  }

  calendars() {
    return this.settings.calendars || List();
  }

  isAllStaff() {
    return this.id === -1;
  }

  isAllResources() {
    return this.id === -2;
  }

  isCurrentStaff() {
    return this.id === -3;
  }

  setCalendars(calendars = List()) {
    return this.set('settings', this.settings.set('calendars', calendars));
  }
}

export default CalendarView;
