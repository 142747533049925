class CheckInActions {
  constructor() {
    this.generateActions(
      'allSelected',
      'attendeeChecked',
      'checkInSuccess',
      'checkInError',
      'checkOutSuccess',
      'checkOutError',
      'confirmChanges',
      'clientListSuccess',
      'clientListError',
      'filterApplied',
      'mounted'
    );
  }
}

export default alt.createActions(CheckInActions);
