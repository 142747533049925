const FilterActions = alt.generateActions(
  'toggleFilterDrawer',
  'updateFilterDrawerOpen',
  'updateStatusFilters',
  'updateTypeFilters',
  'updateTitleFilter',
  'updateScheduleTypeFilter'
);

export default FilterActions;
