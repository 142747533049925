/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AltContainer from 'alt-container';
import queryString from 'query-string';

import Tabs from 'components/Tabs/Tabs.jsx';

import InventoryDialog from 'retail/components/InventoryDialog/index.jsx';
import InventoryAdjustmentDialog from 'retail/components/InventoryAdjustmentDialog/index.jsx';
import InventoryDialogStore from 'retail/stores/InventoryDialogStore.js';
import InventoryAdjustmentDialogStore from 'retail/stores/InventoryAdjustmentDialogStore.js';

import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import RetailEditingStore from 'retail/stores/RetailEditingStore.js';
import StoreActions from 'shared/actions/StoreActions.jsx';

import PageHeader from 'shared/components/PageHeader.jsx';
import TabMenu from 'retail/components/TabMenu.jsx';
import TabOrders from 'retail/components/TabOrders.jsx';
import TabProduct from 'retail/components/TabProduct.jsx';
import TabProducts from 'retail/components/TabProducts.jsx';

import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

import '../styles.scss';

const TABS = {
  products: 'products',
  orders: 'orders',
};

class RetailContainer extends React.PureComponent {
  componentDidMount() {
    RetailCategoryListingActions.list();
  }

  get tabMenu() {
    const {
      match: { pathname: path },
      customer_id: customerId,
    } = this.props;

    return (
      <AltContainer
        stores={{
          inventoryDialogStore: InventoryDialogStore,
          retailEditingStore: RetailEditingStore,
        }}
      >
        <TabMenu
          isCreatingProduct={path === `/customers/${customerId}/retail/new`}
        />
      </AltContainer>
    );
  }

  getActiveTab = () => {
    const {
      location: { search },
    } = this.props;
    return queryString.parse(search).tab || TABS.products;
  };

  render() {
    const {
      intl,
      history,
      productId,
      customer_id: customerId,
      match: { pathname: path },
    } = this.props;
    const isProductPage = !!productId;
    const isProduct =
      isProductPage || path === `/customers/${customerId}/retail/new`;

    const handleTabChange = (e, activeTab) => {
      StoreActions.prepareForReuse();

      if (activeTab === TABS.orders) {
        history.push(`${customerScopedRoute('retail')}?tab=orders`);
      } else {
        history.push(customerScopedRoute('retail'));
      }
    };

    return (
      <div className="retail">
        <PageHeader
          title={t('.retail', intl, __filenamespace)}
          breadcrumbHref={isProduct ? customerScopedRoute('retail') : undefined}
          actions={this.tabMenu}
          style={{ padding: 0 }}
        />

        <div className="iphone-x-content" style={{ position: 'relative' }}>
          <Tabs
            variant="fullWidth"
            value={this.getActiveTab()}
            tabItems={[
              {
                tabIndex: TABS.products,
                tabLabel: t('.tab.products', intl, __filenamespace),
                tabVisible: true,
                tabContent: isProduct ? (
                  <TabProduct {...this.props} />
                ) : (
                  <TabProducts {...this.props} />
                ),
              },
              {
                tabIndex: TABS.orders,
                tabLabel: t('.tab.orders', intl, __filenamespace),
                tabVisible: true,
                tabContent: <TabOrders {...this.props} />,
              },
            ]}
            handleChange={handleTabChange}
          />
          <AltContainer
            stores={{
              inventoryDialogStore: InventoryDialogStore,
              retailEditingStore: RetailEditingStore,
              inventoryAdjustmentDialogStore: InventoryAdjustmentDialogStore,
            }}
          >
            <InventoryDialog isProductPage={isProductPage} />
            <InventoryAdjustmentDialog isProductPage={isProductPage} />
          </AltContainer>
        </div>
      </div>
    );
  }
}

RetailContainer.propTypes = {
  intl: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  productId: PropTypes.string,
};

RetailContainer.defaultProps = {
  intl: {},
  match: {},
  location: {},
  productId: undefined,
};

export default injectIntl(RetailContainer);
