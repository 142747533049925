/* eslint-disable class-methods-use-this */
import TeamListStoreImpl from 'shared/stores/_TeamListStoreImpl.jsx';

class FilteredTeamListStoreImpl extends TeamListStoreImpl {
  constructor(teamActions, listeners) {
    super(teamActions, listeners);

    this.teamUrl = 'team_events';
  }

  handleListTeams({ fields, perPage, page, ids, exceptIds, exceptTypes }) {
    this.waitFor(this.filterStore());
    const filterStoreState = this.filterStore().getState();
    const data = {};

    data.statuses = this.statusesQueryParam(filterStoreState);
    data.types = this.typesQueryParam(filterStoreState);

    if (ids?.length) {
      data.ids = ids;
    }

    if (exceptIds?.length) {
      data.except_ids = exceptIds;
    }

    if (exceptTypes?.length) {
      data.except_types = exceptTypes;
    }

    if (this.filterStore().getState().titleFilter) {
      data.title = this.filterStore().getState().titleFilter;
    }

    super.handleListTeams({ fields, data, page, perPage });
  }

  statusesQueryParam(filterStoreState) {
    const statusFilters = [];
    filterStoreState.statusFilters.forEach((isEnabled, filter) => {
      if (filter !== 'all' && isEnabled) {
        statusFilters.push(filter);
      }
    });
    return statusFilters.length ? statusFilters : 'none';
  }

  typesQueryParam(filterStoreState) {
    const { honorAll, typeFilters } = filterStoreState;
    const typeFilterParams = [];

    if (honorAll && typeFilters.get('all')) {
      typeFilterParams.push('all');
    } else {
      typeFilters.forEach((isEnabled, filter) => {
        if (filter !== 'all' && isEnabled) {
          typeFilterParams.push(filter);
        }
      });
    }

    const emptyValue = honorAll ? 'none' : ['all'];
    return typeFilterParams.length ? typeFilterParams : emptyValue;
  }
}

export default FilteredTeamListStoreImpl;
