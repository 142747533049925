import * as React from 'react';
import PropTypes from 'prop-types';

import uhTheme from '_uh_theme.jsx';

import { Typography } from '@upperhand/playmaker';

const FULL_BORDER_HEIGHT = '100';

const styles = {
  main: {
    height: `calc(100vh - ${FULL_BORDER_HEIGHT}px)`,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  margin5: {
    margin: '2.5rem 1rem 0rem 1rem',
  },
  pageBorder: {
    height: `${FULL_BORDER_HEIGHT / 2}px`,
    backgroundColor: uhTheme.palette.upperHandGreen,
  },
};

function SignupResults({ callToAction, description, headerText }) {
  return (
    <>
      <div style={styles.pageBorder} />
      <main style={styles.main}>
        <div style={styles.content}>
          <img
            alt="Upper Hand logo"
            src={uhTheme.logo.url}
            style={{ width: uhTheme.logo.width }}
          />
          <div style={styles.margin5}>
            <Typography variant="h2" color="primary">
              {headerText}
            </Typography>
          </div>

          <div style={styles.margin5}>{description}</div>

          <div style={styles.margin5}>{callToAction}</div>
        </div>
      </main>
      <div style={styles.pageBorder} />
    </>
  );
}

SignupResults.propTypes = {
  callToAction: PropTypes.element.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  headerText: PropTypes.string.isRequired,
};

export default SignupResults;
