import { Map } from 'immutable';

import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import {
  hasAdminPermission,
  mapRoleToType,
  CLIENT,
  STAFF_MEMBER,
  STANDARD,
} from 'shared/utils/UserUtils.jsx';

class InviteDialogStore {
  constructor() {
    this.reset();
    this.bindListeners({
      handleOpenDialog: InviteDialogActions.OPEN_INVITE_DIALOG,
      handleOpenStaffDialog: InviteDialogActions.OPEN_STAFF_INVITE_DIALOG,
      handleCloseDialog: InviteDialogActions.CLOSE_INVITE_DIALOG,
      handleFieldChange: InviteDialogActions.FIELD_CHANGE,
    });
  }

  reset() {
    this.open = false;
    this.profile = Map({
      first_name: '',
      last_name: '',
      email: '',
      login_enabled: true,
      type: CLIENT,
      role: STANDARD,
    });
  }

  handleOpenDialog() {
    const isStaffTab =
      window.location.hash === '#staff' ||
      window.location.hash === '#archived-staff';

    if (isStaffTab && hasAdminPermission()) {
      this.profile = this.profile
        .set('role', STAFF_MEMBER)
        .set('type', STAFF_MEMBER);
    } else {
      this.profile = this.profile.set('role', CLIENT).set('type', CLIENT);
    }
    this.open = true;
  }

  handleOpenStaffDialog() {
    this.selectedType = STAFF_MEMBER;
    this.open = true;
  }

  handleCloseDialog() {
    this.reset();
  }

  handleFieldChange({ field, value }) {
    if (field === 'role') {
      const selectedRole = value;
      const selectedType = mapRoleToType(selectedRole);

      this.profile = this.profile
        .set(field, selectedRole)
        .set('type', selectedType);
    } else {
      this.profile = this.profile.set(field, value);
    }
  }
}

export default alt.createStore(InviteDialogStore, 'InviteDialogStore');
