import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { PaymentSourceActions } from 'sources';

class PaymentDataStore extends UpperHandStore {
  constructor() {
    super();

    this.records = Map();

    this.bindListeners({
      fetchSuccess: [PaymentSourceActions.fetchSuccess],
      fetchError: [PaymentSourceActions.fetchError],
      listSuccess: [PaymentSourceActions.listSuccess],
      listError: [PaymentSourceActions.listError],
      submitError: [PaymentSourceActions.submitError],
    });
  }

  fetchSuccess(payment) {
    this.records = this.records.set(payment.id, payment);
  }

  fetchError(...args) {
    this.notifyError('error payment fetching', args);
  }

  listSuccess({ payments }) {
    const listedPayments = payments.groupBy(c => c.id).map(c => c.first());

    this.records = this.records.merge(listedPayments);
  }

  listError(...args) {
    this.notifyError('error listing payments', args);
  }

  submitError(...args) {
    this.notifyError('error submitting payment', args);
  }
}

export default alt.createStore(PaymentDataStore, 'PaymentDataStore');
