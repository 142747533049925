class DevTestActions {
  constructor() {
    this.generateActions(
      'mounted',
      'showMounted',
      'fetchSuccess',
      'fetchError',
      'listError',
      'listSuccess'
    );
  }
}

export default alt.createActions(DevTestActions);
