/* eslint-disable no-unused-vars */
import { List, Map } from 'immutable';
import moment from 'moment-timezone';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import {
  DebitForgivenessSource,
  MembershipRenewalSource,
  OrderItemSource,
  PaymentSource,
  PaymentPlanSource,
  ScheduledPaymentSource,
  PaymentMethodSource,
} from 'sources';
import {
  BalancesDataStore,
  MembershipRenewalDataStore,
  OrderItemDataStore,
  ScheduledPaymentDataStore,
  OrderDataStore,
  ClientDataStore,
} from 'dataStores';
import RefundModalActions from './Actions';

const modes = {
  WAIVE: 'waive',
  WAIVE_PAYMENT_PLAN: 'waive_payment_plan',
  REFUND: 'refund',
};

export const PAYMENT_METHODS_TO_CHECK = [
  'card',
  'handpoint_cloud',
  'card_present',
];

class RefundModalStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      closeModal: RefundModalActions.closeModal,
      listPaymentsSuccess: RefundModalActions.listPaymentsSuccess,
      listPaymentsError: RefundModalActions.listPaymentsError,
      openModal: RefundModalActions.openModal,
      updateRefundAmount: RefundModalActions.refundAmountUpdated,
      submitRefund: RefundModalActions.refundSubmit,
      refundSuccess: RefundModalActions.refundSuccess,
      refundError: RefundModalActions.refundError,
      waiveSuccess: RefundModalActions.waiveSuccess,
      waiveError: RefundModalActions.waiveError,
      listProductsSuccess: RefundModalActions.listProductsSuccess,
      listProductsError: RefundModalActions.listProductsError,
      fetchProductSuccess: RefundModalActions.fetchProductSuccess,
      fetchProductError: RefundModalActions.fetchProductError,
      listBuyerPaymentMethods: RefundModalActions.listBuyerPaymentMethods,
      listBuyerPaymentMethodsSuccess:
        RefundModalActions.listBuyerPaymentMethodsSuccess,
      listBuyerPaymentMethodsError:
        RefundModalActions.listBuyerPaymentMethodsError,
    });
  }

  reset() {
    this.isOpen = false;
    this.isLoadingPayments = false;
    this.isLoadingProduct = false;
    this.hasDefaultAch = false;
    this.productToRefund = null;
    this.orderToRefund = null;
    this.buyerId = null;
    this.primaryDisplayText = '';

    // better to store payments in PaymentDataStore later
    this.payments = List();
    this.paymentIds = [];
    this.maxRefunds = Map();

    this.refundAmounts = Map(); // {paymentId => amount (source_id from productToRefund)}
    // exception is for waive, where we will store source_id there from the start due to no payments
    this.internalSubmittingRefunds = Map(); // stores when an item finishes submitting

    this.mode = modes.REFUND;
    this.balanceRemaining = 0;
    this.errors = new FieldErrors();

    this.showBackBtn = false;
    this.maxAmount = false;
  }

  closeModal() {
    this.reset();
  }

  openModal([
    product,
    productType,
    mode = modes.REFUND,
    showBackBtn = false,
    maxAmount = null,
  ]) {
    this.showBackBtn = showBackBtn;
    this.maxAmount = maxAmount;
    // Possibly should get refactored in some fashion, but works for the limited cases for now.
    // Allows passing an order item ID or object.
    if (productType === 'MembershipRenewal') {
      const { products } = MembershipRenewalDataStore.getState();
      if (products !== undefined)
        this.productToRefund = products.find(oi => oi.id === product);
    } else if (productType === 'ScheduledPayment') {
      const { products } = ScheduledPaymentDataStore.getState();
      if (products !== undefined)
        this.productToRefund = products.find(oi => oi.id === product);
    } else if (typeof product === 'string') {
      const { products } = OrderItemDataStore.getState();
      if (products !== undefined)
        this.productToRefund = products.find(oi => oi.id === product);
    } else {
      this.productToRefund = product;
    }

    this.mode = mode;
    this.isOpen = true;

    if (this.productToRefund) {
      this.processProduct();
    } else if (productType === 'MembershipRenewal') {
      this.isLoadingProducts = true;
      MembershipRenewalSource.fetch({
        id: product,
        success: RefundModalActions.fetchProductSuccess,
        error: RefundModalActions.fetchProductError,
      });
    } else if (productType === 'ScheduledPayment') {
      this.isLoadingProducts = true;
      ScheduledPaymentSource.list({
        params: {
          ids: product,
        },
        success: RefundModalActions.listProductsSuccess,
        error: RefundModalActions.listProductsError,
      });
      // Final condition is more of a sanity check - PaymentPlan should have product set to balance.
    } else if (productType !== 'PaymentPlan') {
      this.isLoadingProducts = true;
      OrderItemSource.list({
        params: {
          ids: product,
        },
        success: RefundModalActions.listProductsSuccess,
        error: RefundModalActions.listProductsError,
      });
    }
  }

  processProduct() {
    // If we cannot find a balance, we probably set it as the product instead.
    const { balances } = BalancesDataStore.getState();
    const balance =
      balances.find(b => b.productId === this.productToRefund.id) ||
      this.productToRefund;

    this.primaryDisplayText = balance.get('product');
    this.buyerId =
      this.productToRefund.get('buyer_id') ||
      this.productToRefund.get('buyerId') ||
      this.productToRefund.get('clientId');

    // This more means how much we can still refund or waive.
    switch (this.mode) {
      case modes.REFUND:
        this.balanceRemaining = balance.get('paid');
        this.listBuyerPaymentMethods();
        break;
      case modes.WAIVE:
        this.balanceRemaining = -balance.get('balanceRemaining');
        break;
      case modes.WAIVE_PAYMENT_PLAN:
        this.balanceRemaining = balance.get('balanceRemaining');
        break;
      default:
        this.balanceRemaining = 0;
    }

    if (this.mode === modes.WAIVE || this.mode === modes.WAIVE_PAYMENT_PLAN) {
      const relevantId =
        this.productToRefund.id || this.productToRefund.get('productId');

      this.refundAmounts = this.refundAmounts.set(
        relevantId,
        this.balanceRemaining
      );

      // maybe needs a better name?
      this.paymentIds = [relevantId];

      this.maxRefunds = this.refundAmounts;

      if (this.showBackBtn && this.maxAmount) {
        this.refundAmounts = this.refundAmounts.set(relevantId, 0);
        this.maxRefunds = this.maxRefunds.set(relevantId, this.maxAmount);
      }
    } else {
      // No payments for waive
      this.isLoadingPayments = true;
      PaymentSource.list({
        params: {
          // Increased to 50 to handle multiple payments. TODO: Need to handle pagination.
          per_page: 50,
          source_ids: [this.productToRefund.get('id')],
          fields: ['available_to_refund'],
        },
        success: RefundModalActions.listPaymentsSuccess,
        error: RefundModalActions.listPaymentsError,
      });
    }
  }

  proceedVoidPayment({ payments }) {
    const isVoidPayment = this.payments.some(
      p =>
        moment().diff(moment(p.get('paidAt')), 'hours') < 24 &&
        PAYMENT_METHODS_TO_CHECK.includes(p.get('paymentMethod'))
    );

    if (this.mode === modes.REFUND && isVoidPayment) {
      const { orders } = OrderDataStore.getState();
      const orderToRefund = orders.get(this.productToRefund.get('order_id'));

      this.orderToRefund = orderToRefund;
      this.refundAmounts = Map(
        payments.map(p => [p.get('id'), p.get('availableToRefund')])
      );
      this.maxRefunds = this.refundAmounts;
    }
  }

  listBuyerPaymentMethods() {
    const paymentMethod = this.productToRefund.get('payment_method');

    if (paymentMethod === 'bank') {
      const { clients } = ClientDataStore.getState();
      const client = clients.get(this.buyerId);

      this.isLoadingPayments = true;
      PaymentMethodSource.list({
        userId: client.user_id,
        success: RefundModalActions.listBuyerPaymentMethodsSuccess,
        error: RefundModalActions.listBuyerPaymentMethodsError,
      });
    }
  }

  listBuyerPaymentMethodsSuccess({ payment_methods: paymentMethods }) {
    this.hasDefaultAch = paymentMethods.some(
      pm => pm.default && pm.type === 'bank'
    );
    this.isLoadingPayments = false;
  }

  listBuyerPaymentMethodsError(...args) {
    this.isLoadingPayments = false;
    this.notifyError('Error fetching payment methods', args);
  }

  listPaymentsSuccess({ payments }) {
    this.isLoadingPayments = false;
    this.payments = Map(payments.map(p => [p.get('id'), p]));
    this.paymentIds = payments.map(p => p.get('id')).toJS();

    // Set up allocation amounts.
    this.refundAmounts = Map(
      payments.map(p => [
        p.get('id'),
        Math.min(this.balanceRemaining, p.get('availableToRefund')),
      ])
    );

    this.maxRefunds = this.refundAmounts;

    this.proceedVoidPayment({ payments });
  }

  listPaymentsError(...args) {
    this.notifyError('Error fetching', args);
  }

  updateRefundAmount([id, amount]) {
    this.refundAmounts = this.refundAmounts.set(id, amount);
    this.validate();
  }

  submitRefund() {
    switch (this.mode) {
      case modes.REFUND:
        this.refund();
        break;
      case modes.WAIVE:
      case modes.WAIVE_PAYMENT_PLAN:
        this.waive();
        break;
      default:
        break;
    }
  }

  refund() {
    // be sure refundAllocation is an Immutable.js Map
    if (this.refundAmounts.some(amount => amount > 0)) {
      this.refundAmounts
        .filter(amount => amount > 0)
        .forEach((amount, paymentId) => {
          this.internalSubmittingRefunds = this.internalSubmittingRefunds.set(
            paymentId,
            true
          );

          PaymentSource.refund({
            id: paymentId,
            amount,
            allocation: {
              [this.productToRefund.get('id')]: amount,
            },
            success: RefundModalActions.refundSuccess,
            error: RefundModalActions.refundError,
          });
        });
    } else {
      MessageWindowActions.addMessage.defer('Error refunding order item');
    }
  }

  waive() {
    if (this.mode === modes.WAIVE) {
      DebitForgivenessSource.create({
        sourceId: this.productToRefund.id,
        amount: this.refundAmounts.get(this.productToRefund.id),
        success: RefundModalActions.waiveSuccess,
        error: RefundModalActions.waiveError,
      });
    } else if (this.mode === modes.WAIVE_PAYMENT_PLAN) {
      PaymentPlanSource.waive({
        id: this.productToRefund.get('productId'),
        amount: this.refundAmounts.get(this.productToRefund.get('productId')),
        success: RefundModalActions.waiveSuccess,
        error: RefundModalActions.waiveError,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  refundSuccess(data) {
    this.internalSubmittingRefunds = this.internalSubmittingRefunds.set(
      data.payment_id,
      false
    );

    if (this.internalSubmittingRefunds.every(loading => !loading)) {
      MessageWindowActions.addMessage.defer('Refund submitted successfully.');
      this.closeModal();
    }
  }

  refundError(...args) {
    this.notifyError('Error refunding order item', args);
    this.closeModal();
  }

  // eslint-disable-next-line class-methods-use-this
  waiveSuccess() {
    MessageWindowActions.addMessage.defer('Waive submitted successfully.');
    this.closeModal();
  }

  waiveError(...args) {
    this.notifyError('Error waiving order item', args);
    this.closeModal();
  }

  fetchProductSuccess(product) {
    this.isLoadingProducts = false;
    this.productToRefund = product;
    this.processProduct();
  }

  fetchProductError(...args) {
    this.notifyError('Error listing products', args);
    this.closeModal();
  }

  listProductsSuccess({
    scheduled_payments: scheduledPayments,
    order_items: orderItems,
  }) {
    this.isLoadingProducts = false;
    if (orderItems !== undefined) this.productToRefund = orderItems.get(0);
    if (scheduledPayments !== undefined)
      this.productToRefund = scheduledPayments.get(0);
    this.processProduct();
  }

  listProductsError(...args) {
    this.notifyError('Error listing products', args);
    this.closeModal();
  }

  validate() {
    this.errors = this.errors.clear();

    this.refundAmounts.forEach((amount, paymentId) => {
      if (amount > this.maxRefunds.get(paymentId)) {
        this.errors = this.errors.add(
          paymentId,
          'containers.reports.refundModal.Store.refund_exceeds_maximum'
        );
      }
      if (this.refundAmounts.count() === 0 && amount === 0) {
        this.errors = this.errors.add(
          paymentId,
          'containers.reports.refundModal.Store.refund_is_zero'
        );
      }
    });
  }
}

export default alt.createStore(RefundModalStore, 'RefundModalStore');
