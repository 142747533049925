import { List, OrderedMap } from 'immutable';
import CreditPass from 'shared/records/CreditPass.js';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import Membership from 'shared/records/Membership.jsx';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import Product from 'shared/records/Product.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import Variant from 'shared/records/Variant';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

const url = () => `customers/${currentCustomer().id}/products`;

class POSProductListStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      clearFilters: POSProductListActions.CLEAR_FILTERS,

      listByType: POSProductListActions.LIST_BY_TYPE,
      listProducts: POSActions.POS_CLICKED,
      listSuccess: POSProductListActions.LIST_SUCCESS,
      listError: POSProductListActions.LIST_ERROR,

      updateSearchString: POSProductListActions.SEARCH_STRING_UPDATED,
      requestPage: POSProductListActions.PAGE_SELECTED,

      reset: [
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
        POSActions.DRAWER_DISMISSED,
      ],
    });
  }

  reset() {
    this.products = OrderedMap();
    this.variant = null; // Set when performing a code search.

    this.searchString = '';
    this.page = 1;
    this.perPage = 10;
    this.productsTypes = List();
    this.totalCount = 0;

    this.shouldFetch = true;
    this.isLoading = false;
  }

  clearFilters() {
    this.reset();
    this.listProducts();
  }

  listByType(types) {
    this.reset();
    this.productsTypes = List(types);
    this.listProducts();
  }

  // eslint-disable-next-line consistent-return
  listProducts() {
    if (this.shouldFetch) {
      this.isLoading = true;
      this.shouldFetch = false;

      return uhApiClient.get({
        url: url(),
        data: this.queryParams(),
        success: POSProductListActions.listSuccess,
        error: POSProductListActions.listError,
      });
    }
  }

  // eslint-disable-next-line camelcase
  listSuccess({ products, page, per_page, total_count, variant }) {
    this.isLoading = false;
    this.page = page;
    this.perPage = per_page; // eslint-disable-line camelcase
    this.totalCount = total_count; // eslint-disable-line camelcase

    this.products = OrderedMap().withMutations(productMap =>
      products.forEach(p =>
        productMap.set(`${p.product_type}-${p.id}`, this.buildProduct(p))
      )
    );

    this.variant = variant ? new Variant(variant) : null;

    if (variant) {
      this.updateSearchString('');
    }
  }

  listError(args) {
    this.isLoading = false;
    this.notifyError('error listing products', ...args);
  }

  updateSearchString(newString) {
    if (this.searchString === newString) {
      return false;
    }

    this.searchString = newString;
    this.page = 1;
    this.shouldFetch = true;

    return this.listProducts();
  }

  requestPage(page) {
    if (this.page === page) {
      return false;
    }

    this.page = page;
    this.shouldFetch = true;

    return this.listProducts();
  }

  queryParams() {
    return {
      page: this.page,
      per_page: this.perPage,
      product_types: this.productsTypes.toJS(),
      search_string: this.searchString,
      statuses: ['active'],
      fields: [
        'exclusive_membership_ids',
        'events',
        'event_types',
        'has_embed_product',
        'participant_ids',
        'registered_client_ids',
      ],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  buildProduct(product) {
    switch (product.product_type) {
      case 'event':
        return new EventTranslator(product).toClient();
      case 'membership':
        return new Membership(product);
      case 'product':
        return new Product(product);
      case 'credit_pass':
        return new CreditPass(product);
      default:
        return null;
    }
  }
}

export default alt.createStore(POSProductListStore, 'POSProductListStore');
