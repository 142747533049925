import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List } from 'immutable';

import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ActionToday from '@mui/icons-material/Today';
import DeviceAccessTime from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';

import uhTheme from '_uh_theme.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { Spinner } from '@upperhand/playmaker';
import {
  formatClientTime,
  convertDateToClientValue,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import AvailableCredits from 'shared/components/registration/AvailableCredits.jsx';
import BookingButton from 'shared/components/registration/BookingButton.jsx';

const styles = {
  bigLabel: {
    fontSize: 18,
  },

  buttonLabel: {
    fontSize: 16,
  },

  bookButton: {
    margin: '1rem 0 2rem 0',
    height: 40, // MUI hardcodes a PX unit for height. See https://github.com/callemall/material-ui/issues/4473
  },

  schedulingInstructions: {
    color: uhColors.textGrey,
    fontSize: 15,
    lineHeight: 1.2,
    marginBottom: 15,
  },
  repeatContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  availableCredit: {
    color: uhColors.charcoalBlack,
    fontSize: '14px',
    fontWeight: '600',
  },
  scheduleOptionContainer: {
    main: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    dateContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px',
    },
    selectDateContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    inputWeekNumber: {
      width: '50px',
      outline: '0',
      borderWidth: '0 0 1px',
      borderColor: uhColors.lighterGrey,
      ':focus': {
        borderColor: uhColors.charcoalBlack,
      },
    },
  },
  paymentOptionButton: {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    verticalAlign: 'middle',
    lineHeight: '90px',
    flexDirection: 'column',
    height: '100px',
  },
  weekDaysStyle: {
    dayContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    dayBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${uhColors.activeRepeatButton}`,
      width: '50px',
      height: '50px',
      backgroundColor: uhColors.lighterGrey,
      cursor: 'pointer',
    },
    dayBoxActive: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${uhColors.textGrey}`,
      width: '50px',
      height: '50px',
      backgroundColor: uhColors.activeRepeatButton,
      color: uhColors.textWhite,
      cursor: 'pointer',
    },
    dayBoxOff: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${uhColors.activeRepeatButton}`,
      width: '50px',
      height: '50px',
      backgroundColor: uhColors.textWhite,
    },
    dayText: {
      color: uhColors.activeRepeatButton,
      fontSize: '15px',
    },
    dayTextActive: {
      color: uhColors.textWhite,
      fontSize: '15px',
    },
  },
  loading: {
    root: {
      backgroundColor: uhColors.textWhite,
      height: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
    },
    loading: {
      padding: '16px',
    },
  },
  activeDays: {
    '.UH-MuiPickersDay-root:not([class*="Mui-disabled"])': {
      backgroundColor: uhTheme.palette.primary.main,
      color: uhTheme.palette.primary.contrastText,
      border: 0,
    },
  },
};

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Week days
function WeekDays({ _availableDays, _activeDays, setHandleDay }) {
  const [activeDays, setActiveDays] = React.useState(_activeDays);

  React.useEffect(() => {
    setActiveDays(_activeDays);
  }, [_activeDays]);

  const uniqueArray = _availableDays.filter(
    (item, pos) => String(_availableDays.indexOf(item)) === String(pos)
  );

  // ertract the filter day
  const handleDay = d => {
    const newday = uniqueArray.filter(day => day === d);
    return newday.toString();
  };
  // add the selected day
  const handleClick = e => {
    const day = activeDays[e.target.value];
    setHandleDay({
      ...activeDays,
      [e.target.value]: { ...day, active: !day.active },
    });
  };

  const isDayActive = day => activeDays[day].active;
  const viewDay = day => day.slice(0, 3);

  return (
    <div style={styles.weekDaysStyle.dayContainer}>
      {days.map(day => (
        <div key={day}>
          <button
            type="button"
            value={day}
            onClick={e => handleClick(e)}
            disabled={handleDay(day) !== day}
            style={merge(
              styles.weekDaysStyle.dayBoxOff,
              handleDay(day) === day && styles.weekDaysStyle.dayBox,
              isDayActive(day) && styles.weekDaysStyle.dayBoxActive
            )}
          >
            {viewDay(day)}
          </button>
        </div>
      ))}
    </div>
  );
}

function DayRepeats({
  _availableDays,
  handleAddDays,
  activeDays,
  setHandleDay,
}) {
  return (
    <>
      <div style={{ paddingTop: 15 }}>
        <span style={styles.availableCredit}> Repeats On </span>
      </div>
      <WeekDays
        handleAddDays={handleAddDays}
        _availableDays={_availableDays}
        _activeDays={activeDays}
        setHandleDay={setHandleDay}
      />
    </>
  );
}

class RegistrationScheduler extends React.Component {
  state = {
    datePickerOpen: false,
    datePickerOpenStartTime: false,
    datePickerOpenEndTime: false,
    currentCalendarViewDate: null,
    currentCalendarViewDateRepeat: null,
    endDatePicker: null,
  };

  componentDidMount() {
    const { registration } = this.props;

    this.fetchInitialAvailabilities(registration.get('starts_at'));
  }

  fetchInitialAvailabilities = date => {
    const { fetchAvailabilities } = this.props;
    fetchAvailabilities.defer(date);
  };

  // update function by change date
  handleMonthChange = month => {
    const {
      currentCalendarViewDate,
      currentCalendarViewDateRepeat,
      endDatePicker,
    } = this.state;
    const { availableTimesStore, fetchAvailabilities } = this.props;
    const { windowEnd } = availableTimesStore;
    const nextViewDate = month;

    if (moment(nextViewDate).add(1, 'months').isAfter(windowEnd)) {
      fetchAvailabilities(nextViewDate);
    }
    if (currentCalendarViewDate) {
      this.setState({ currentCalendarViewDate: nextViewDate });
    } else if (currentCalendarViewDateRepeat) {
      this.setState({
        currentCalendarViewDateRepeat: nextViewDate,
      });
    } else if (endDatePicker) {
      this.setState({ endDatePicker: nextViewDate });
    }
  };

  shouldDisableDate = date => {
    const {
      availableTimesStore: { filteredAvailableTimes },
    } = this.props;
    return filteredAvailableTimes
      .get(moment(date).format('YYYY-MM-DD'), List())
      .isEmpty();
  };

  shouldDisableDateRepeat = startDateRepeat => {
    const {
      availableTimesStore: { filteredAvailableTimes },
    } = this.props;

    return filteredAvailableTimes
      .get(moment(startDateRepeat).format('YYYY-MM-DD'), List())
      .isEmpty();
  };

  shouldDisableDateEndTime = endDateRepeat => {
    const {
      availableTimesStore: { filteredAvailableTimes },
    } = this.props;

    return filteredAvailableTimes
      .get(moment(endDateRepeat).format('YYYY-MM-DD'), List())
      .isEmpty();
  };

  handleShowDatePicker = () => {
    const {
      availableTimesStore: { date },
    } = this.props;
    this.setState({
      datePickerOpen: true,
      currentCalendarViewDate: date
        ? moment(date).startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD'),
    });
  };

  // onRepeat start date picker
  handleShowDatePickerRepeat = () => {
    const {
      availableTimesStore: { startDateRepeat },
    } = this.props;
    this.setState({
      datePickerOpenStartTime: true,
      currentCalendarViewDateRepeat: startDateRepeat
        ? moment(startDateRepeat).startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD'),
    });
  };

  // end Date picker click
  handleShowDatePickerEnd = () => {
    const {
      availableTimesStore: { endDateRepeat },
    } = this.props;
    this.setState({
      datePickerOpenEndTime: true,
      endDatePicker: endDateRepeat
        ? moment(endDateRepeat).startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD'),
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleDismissDatePicker = () => {
    this.setState({ datePickerOpen: false });
  };

  handleDismissDatePickerStartTime = () => {
    this.setState({ datePickerOpenStartTime: false });
  };

  handleDismissDatePickerEndTime = () => {
    this.setState({ datePickerOpenEndTime: false });
  };

  // handle change date picker
  handleChangeDatePicker = (e, date) => {
    const { onDateChange } = this.props;
    onDateChange(date, 'startDate');
  };

  timeSelectHintText = () => {
    const {
      availableTimesStore: {
        isLoading,
        isCurrentTimeAvailable,
        time,
        availableTimes,
      },
      intl,
      registration,
    } = this.props;

    if (isLoading) {
      return t('.check_availabilty', intl, __filenamespace);
    }
    if (!isCurrentTimeAvailable) {
      return formatClientTime(time, 'h:mm A');
    }
    if (
      (availableTimes && availableTimes.count() > 0) ||
      !registration.get('starts_at')
    ) {
      return t('.select_time', intl, __filenamespace);
    }
    return t('.no_availabilty', intl, __filenamespace);
  };

  // Repeat time select
  timeSelectHintTextRepeat = () => {
    const {
      availableTimesStore: {
        isLoading,
        isCurrentTimeAvailable,
        startTimeRepeat,
        availableTimesRepeat,
      },
      intl,
      registration,
    } = this.props;

    if (isLoading) {
      return t('.check_availabilty', intl, __filenamespace);
    }
    if (!isCurrentTimeAvailable) {
      return formatClientTime(startTimeRepeat, 'h:mm A');
    }
    if (
      (availableTimesRepeat && availableTimesRepeat.count() > 0) ||
      !registration.get('starts_at')
    ) {
      return t('.select_time', intl, __filenamespace);
    }
    return t('.no_availabilty', intl, __filenamespace);
  };

  handleOpenConflictScreen = async () => {
    const {
      availableTimesStore,
      customerUserId,
      staffId,
      onTimeChange,
      _saveSessions,
      addDay,
    } = this.props;
    const {
      startDateRepeat,
      endDateRepeat,
      startTimeRepeat,
      handleRepeatSessions,
    } = availableTimesStore;
    const { count, returnedDates } = await this.getCountOfSessions();
    const schedulingNow = true;
    await onTimeChange(
      startTimeRepeat,
      'repeatFunctionsCall',
      count,
      returnedDates,
      customerUserId,
      staffId,
      _saveSessions,
      schedulingNow
    );
    const ApiData = {
      user_id: customerUserId,
      start_date: startDateRepeat,
      end_date: endDateRepeat,
      booked_sessions_days: addDay,
      start_time: startTimeRepeat,
      staff_id: staffId,
    };
    this.setState({
      currentCalendarViewDateRepeat: null,
      endDatePicker: null,
    });
    await handleRepeatSessions(ApiData);
  };

  // calculate the sessions in available dates
  getCountOfSessions = () => {
    const { availableTimesStore, setActives, setCounts, setNumberOfSessions } =
      this.props;
    const { startDateRepeat, endDateRepeat, allDates } = availableTimesStore;
    // debugger;
    let count = 0;
    const startDate = moment(startDateRepeat).format('YYYY-MM-DD');
    const endDate = moment(endDateRepeat).format('YYYY-MM-DD');
    const activeDates = [];
    let returnedDates = [];
    const getDates = (startDate1, stopDate1) => {
      const dateArray = [];
      let currentDate = moment(startDate1);
      const stopDate = moment(stopDate1);
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
      }
      return dateArray;
    };
    allDates.forEach(item => {
      const condition = moment(item.date).isBetween(
        startDate,
        endDate,
        undefined,
        '[)'
      );
      if (condition && activeDates.indexOf(item.date) === -1) {
        activeDates.push(item.date);
        setActives(activeDates);
      }
    });
    const resultedDates = getDates(startDateRepeat, endDateRepeat);
    returnedDates = resultedDates.filter(date => {
      const { addDay } = this.props;
      return addDay.some(item => item === moment(date).format('ddd'));
    });
    count = returnedDates?.length;
    setCounts(count);
    setNumberOfSessions(count);
    return { count, returnedDates };
  };

  handlerSessionTime = async value => {
    const { onTimeChange } = this.props;
    const { count, returnedDates } = await this.getCountOfSessions();
    await onTimeChange(value, 'startTimeRepeat', count, returnedDates);
  };

  // schedule options coponent
  renderScheduleOptions = selectDay => {
    const { datePickerOpenStartTime, datePickerOpenEndTime } = this.state;
    const { availableTimesStore, onDateChange } = this.props;
    const {
      startDateRepeat,
      endDateRepeat,
      isLoading,
      isCurrentTimeAvailableRepeat,
      availableTimesRepeat,
      startTimeRepeat,
    } = availableTimesStore;
    const disablePicker = isLoading || !(selectDay.length > 0);
    return (
      <div style={styles.scheduleOptionContainer.main}>
        <div style={{ marginTop: '20px' }}>
          <span style={styles.availableCredit}>Schedule options</span>
        </div>
        <div style={styles.scheduleOptionContainer.dateContainer}>
          {/* Start Date */}
          <DesktopDatePicker
            disableMaskedInput
            disableOpenPicker
            open={datePickerOpenStartTime}
            disabled={disablePicker}
            inputFormat="MMM D, YYYY"
            views={['day']}
            renderInput={params => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'Start Date',
                  style: { cursor: 'pointer' },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ActionToday />
                    </InputAdornment>
                  ),
                  sx: {
                    cursor: 'pointer',
                  },
                }}
                onClick={
                  !disablePicker ? this.handleShowDatePickerRepeat : () => null
                }
              />
            )}
            PaperProps={{
              sx: styles.activeDays,
            }}
            value={convertDateToClientValue(startDateRepeat)}
            minDate={moment()}
            shouldDisableDate={this.shouldDisableDateRepeat}
            onChange={d => onDateChange(d, 'startDateRepeat')}
            onMonthChange={this.handleMonthChange}
            onClose={this.handleDismissDatePickerStartTime}
          />
        </div>
        <div style={styles.scheduleOptionContainer.dateContainer}>
          {/* End date */}
          <DesktopDatePicker
            disableMaskedInput
            disableOpenPicker
            open={datePickerOpenEndTime}
            disabled={disablePicker}
            inputFormat="MMM D, YYYY"
            views={['day']}
            renderInput={params => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'End Date',
                  style: { cursor: 'pointer' },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ActionToday />
                    </InputAdornment>
                  ),
                  sx: {
                    cursor: 'pointer',
                  },
                }}
                onClick={
                  !disablePicker ? this.handleShowDatePickerEnd : () => null
                }
              />
            )}
            PaperProps={{
              sx: styles.activeDays,
            }}
            value={convertDateToClientValue(endDateRepeat)}
            minDate={moment(startDateRepeat)}
            shouldDisableDate={this.shouldDisableDateEndTime}
            onChange={d => onDateChange(d, 'endDateRepeat')}
            onMonthChange={this.handleMonthChange}
            onClose={this.handleDismissDatePickerEndTime}
          />
        </div>
        <Select
          fullWidth
          displayEmpty
          startAdornment={<DeviceAccessTime />}
          sx={{ marginTop: '10px' }}
          renderValue={val =>
            val ? (
              <span style={{ paddingLeft: 5 }}>
                {formatClientTime(val, 'h:mm A')}
              </span>
            ) : (
              <span style={{ color: uhColors.hint, paddingLeft: 5 }}>
                {this.timeSelectHintTextRepeat()}
              </span>
            )
          }
          value={isCurrentTimeAvailableRepeat ? startTimeRepeat : ''}
          disabled={
            isLoading ||
            (availableTimesRepeat && availableTimesRepeat.size === 0)
          }
          onChange={e => {
            this.handlerSessionTime(e.target.value);
          }}
        >
          {availableTimesRepeat.map(time => (
            <MenuItem key={time} value={time}>
              {formatClientTime(time, 'h:mm A')}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  render() {
    const {
      availableTimesStore,
      style,
      repeatButton,
      onStaffChange,
      staff,
      repeatEnabled,
      clientCreditCount,
      intl,
      onTimeChange,
      dateAndTimeOnly,
      afterProfileCreate,
      athletes,
      managingUserId,
      onAthleteChange,
      selectedAthleteId,
      hideBookButton,
      onBookRegistration,
      numberOfSessions,
      setHandleDay,
      activeDays,
      _availableDays,
      handleAddDays,
      addDay,
      staffId,
      event: { customerEvent: event }, // TODO: event -> eventStore
    } = this.props;
    const {
      date,
      time,
      isCurrentTimeAvailable,
      isLoading,
      availableTimes,
      startTimeRepeat,
    } = availableTimesStore;

    const { datePickerOpen } = this.state;

    if (isLoading) {
      return (
        <div style={{ ...styles.loading.root, ...styles.loading.loading }}>
          <Spinner />
        </div>
      );
    }
    return (
      <div style={style}>
        <div style={styles.schedulingInstructions}>
          {event.allow_staff_selection ? (
            <FormattedMessage
              id={messageId('.instructions_staff_select', __filenamespace)}
            />
          ) : (
            <FormattedMessage
              id={messageId('.instructions_no_staff_select', __filenamespace)}
            />
          )}
        </div>

        {event.allow_staff_selection && (
          <div>
            <div style={merge(styles.bigLabel, { marginBottom: 10 })}>
              <FormattedMessage
                id={messageId('.choose_staff', __filenamespace)}
              />
            </div>
            <div style={styles.repeatContainer}>
              <div
                style={{
                  width: repeatButton ? '75%' : '100%',
                }}
              >
                <Select
                  fullWidth
                  displayEmpty
                  value={staffId || ''}
                  sx={{
                    '& > div': { height: 'initial' },
                    '.UH-MuiSelect-select': {
                      padding: '5px',
                    },
                  }}
                  // eslint-disable-next-line no-undef
                  onChange={e => onStaffChange(_, _, e.target.value)}
                >
                  <MenuItem value="">
                    <AvatarWithName
                      name="Staff: Any available"
                      letter="S"
                      style={{ height: 43 }}
                    />
                  </MenuItem>
                  {staff.map(s => (
                    <MenuItem key={s.id} value={s.id}>
                      <AvatarWithName user={s} style={{ height: 43 }} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}
        {!repeatEnabled && clientCreditCount !== null ? (
          <>
            <div style={merge(styles.bigLabel, { marginTop: '10px' })}>
              <FormattedMessage
                id={messageId('.pick_a_date_and_time', __filenamespace)}
              />
            </div>

            {!isCurrentTimeAvailable && (
              <FlexBox
                style={{
                  backgroundColor: 'var(--color-highlight-yellow)',
                  padding: 10,
                  margin: '0 -5px',
                }}
              >
                <ErrorIcon
                  style={{
                    margin: '0 5px',
                    height: 16,
                    width: 16,
                    flex: '0 0 auto',
                    color: uhColors.darkRed,
                  }}
                />
                <div style={{ margin: '0 5px', flex: '1 1 auto' }}>
                  <FormattedMessage
                    id={messageId('.unavailable_warning', __filenamespace)}
                  />
                </div>
              </FlexBox>
            )}
            {/* repeatBooking */}
            <DesktopDatePicker
              disableOpenPicker
              open={datePickerOpen}
              shouldDisableDate={this.shouldDisableDate}
              value={convertDateToClientValue(date)}
              inputFormat="MMM D, YYYY"
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: isLoading
                      ? t('.check_availabilty', intl, __filenamespace)
                      : t('.select_date', intl, __filenamespace),
                    style: { cursor: 'pointer' },
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <ActionToday />
                      </InputAdornment>
                    ),
                    sx: {
                      cursor: 'pointer',
                      marginTop: '10px',
                    },
                  }}
                  onClick={this.handleShowDatePicker}
                />
              )}
              PaperProps={{
                sx: styles.activeDays,
              }}
              onMonthChange={this.handleMonthChange}
              onClose={this.handleDismissDatePicker}
              onChange={d => this.handleChangeDatePicker(null, d)}
            />
            <FlexBox style={{ alignItems: 'center', marginTop: '10px' }}>
              <Select
                fullWidth
                displayEmpty
                startAdornment={<DeviceAccessTime />}
                renderValue={val =>
                  val ? (
                    <span style={{ paddingLeft: 5 }}>
                      {formatClientTime(val, 'h:mm A')}
                    </span>
                  ) : (
                    <span style={{ color: uhColors.hint, paddingLeft: 5 }}>
                      {this.timeSelectHintText()}
                    </span>
                  )
                }
                value={isCurrentTimeAvailable ? time : ''}
                disabled={
                  isLoading || (availableTimes && availableTimes.size === 0)
                }
                onChange={e => onTimeChange(e.target.value, 'startTime')}
              >
                {availableTimes.map(at => (
                  <MenuItem key={at} value={at}>
                    {formatClientTime(at, 'h:mm A')}
                  </MenuItem>
                ))}
              </Select>
            </FlexBox>
            {!dateAndTimeOnly && (
              <ProfileSelector
                afterProfileCreate={afterProfileCreate}
                athletes={athletes}
                fullWidth
                header={t('actions.choose_attendees', intl)}
                managingUserId={managingUserId}
                onChange={onAthleteChange}
                preventProfileCreation={event.isMembershipExclusive()}
                value={selectedAthleteId}
              />
            )}

            {!hideBookButton && (
              <Button
                fullWidth
                variant="contained"
                disabled={
                  !date || !time || !isCurrentTimeAvailable || isLoading
                }
                onClick={onBookRegistration}
                style={{ ...styles.bookButton, ...styles.buttonLabel }}
              >
                {t('.book', intl, __filenamespace)}
              </Button>
            )}
          </>
        ) : (
          <div>
            <AvailableCredits
              sessions={numberOfSessions > 0}
              numberOfAvailableCredits={clientCreditCount}
              numberOfSessions={numberOfSessions}
            />
            <DayRepeats
              setHandleDay={setHandleDay}
              activeDays={activeDays}
              _availableDays={_availableDays}
              handleAddDays={handleAddDays}
            />
            {this.renderScheduleOptions(addDay)}
            <div style={styles.paymentOptionButton}>
              {staffId === null ? (
                <BookingButton
                  disable={!!(startTimeRepeat === null)}
                  title="CONTINUE TO PAYMENT OPTIONS"
                  handleClicked={() => this.handleOpenConflictScreen()}
                />
              ) : (
                <BookingButton
                  disable={!!(startTimeRepeat === null || staffId === null)}
                  title="CONTINUE TO PAYMENT OPTIONS"
                  handleClicked={() => this.handleOpenConflictScreen()}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(RegistrationScheduler);
