import * as React from 'react';

export const handleOnEnterKeyDown = (event, callback) => {
  // Check if the key is the 'Enter' key.
  if (event.key === 'Enter') {
    callback(event);
  }
};

const Form = ({ children, onSubmit, style }) => (
  <div onKeyDown={e => handleOnEnterKeyDown(e, onSubmit)} style={style}>
    {children}
  </div>
);

export default Form;
