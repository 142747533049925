import { List, Record } from 'immutable';
import moment from 'moment-timezone';

import Customer from 'shared/records/Customer.jsx';
import PurchaseOrderItem from 'retail/records/PurchaseOrderItem.js';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const prepareValue = (key, value) => {
  if (key === 'order_number') {
    return value || null;
  }
  if (key === 'order_date') {
    return moment(value);
  }
  return value;
};

class PurchaseOrder extends Record({
  id: null,
  order_number: null,
  order_date: null,
  purchase_order_items: List(),
  purchase_order_item_ids: List(),
  created_at: null,
  updated_at: null,
  total_quantity: 0,
  adjusted_reason: '',
  actual_quantity: 0,
  expected_quantity: 0,
  created_by_id: null,
  created_by: null,
  updated_by_id: null,
  updated_by: null,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    const orderDate = obj.order_date
      ? prepareValue('order_date', obj.order_date)
      : null;

    const createdAt = obj.created_at ? moment(obj.created_at) : null;
    const updatedAt = obj.updated_at ? moment(obj.updated_at) : null;

    super(
      merge(obj, {
        order_number: prepareValue('order_number', obj.order_number),
        order_date: orderDate,
        created_at: createdAt,
        created_by: new Customer(obj.created_by || {}),
        updated_at: updatedAt,
        updated_by: new Customer(obj.updated_by || {}),
        purchase_order_items: List(
          (obj.purchase_order_items || []).map(
            item => new PurchaseOrderItem(item)
          )
        ),
        errors: new FieldErrors(),
      })
    );
  }

  set(key, value) {
    return super.set(key, prepareValue(key, value));
  }

  setItem({ variant, key, value }) {
    const orderItem = this.purchase_order_items.find(
      item => item.variant_id === variant.id
    );
    const orderItemIndex = this.purchase_order_items.indexOf(orderItem);

    if (orderItemIndex === -1) {
      const updatedOrderItem = new PurchaseOrderItem({ [key]: value });
      if (!updatedOrderItem.isEmpty()) {
        return this.update('purchase_order_items', (list = List()) =>
          list.push(
            new PurchaseOrderItem({
              variant_id: variant.id,
              [key]: value,
            })
          )
        );
      }
      return this;
    }
    const updatedOrderItem = orderItem.set(key, value);
    if (!updatedOrderItem.isEmpty()) {
      return this.update('purchase_order_items', (list = List()) =>
        list.update(orderItemIndex, item => item.set(key, value))
      );
    }
    return this.update('purchase_order_items', (list = List()) =>
      list.delete(orderItemIndex)
    );
  }

  getItem(variantId) {
    const index = this.purchase_order_items.findIndex(
      item => item.variant_id === variantId
    );
    if (index === -1) {
      return new PurchaseOrderItem();
    }
    return this.purchase_order_items.get(index);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  displayableNumber() {
    return this.order_number ? `#${this.order_number}` : '';
  }

  validate() {
    let self = this;
    let errors = new FieldErrors();

    if (self.order_date === null) {
      errors = errors.add(
        'order_date',
        'records.PurchaseOrder.errors.order_date'
      );
    }

    self = self.update('purchase_order_items', (list = List()) =>
      list.map(item => item.validate())
    );

    const hasError = !!self.purchase_order_items.find(item => !item.isValid());
    if (hasError) {
      errors = errors.add(
        'purchase_order_items',
        'records.PurchaseOrder.errors.purchase_order_items'
      );
    }

    return self.set('errors', errors);
  }

  toServer() {
    const data = this.toJS();

    delete data.errors;
    delete data.created_at;
    data.purchase_order_items = this.purchase_order_items
      .map(item => item.toServer())
      .toArray();
    if (!data.order_number) {
      delete data.order_number;
    }
    // For some case to prevent error if order_date is not a moment object
    data.order_date = moment(data.order_date).format();

    return data;
  }

  toServerForUpdate() {
    const data = this.toJS();

    return {
      order_date: moment(data.order_date).format(),
      ...(data.order_number ? { order_number: data.order_number } : {}),
    };
  }
}

export default PurchaseOrder;
