import PayrollEntry from 'shared/records/PayrollEntry';
import PayrollEntryEditingActions from 'reporting/payroll/actions/PayrollEntryEditingActions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class PayrollEntryEditingStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      handleLoad: PayrollEntryEditingActions.LOAD,
      handleLoadSuccess: PayrollEntryEditingActions.LOAD_SUCCESS,
      handleLoadError: PayrollEntryEditingActions.LOAD_ERROR,
      handleUpdate: PayrollEntryEditingActions.UPDATE,
      handleCloseModal: PayrollEntryEditingActions.CLOSE_MODAL,
      handleConfirm: PayrollEntryEditingActions.CONFIRM,
      handleSuccess: PayrollEntryEditingActions.SUCCESS,
      handleError: PayrollEntryEditingActions.ERROR,
    });
  }

  reset() {
    this.payrollEntry = null;
    this.staffMember = null;
    this.isLoading = false;
  }

  validatePayrollEntry() {
    this.payrollEntry = this.payrollEntry.validate();
  }

  handleLoad({ payrollEntry, staffMember }) {
    this.payrollEntry = new PayrollEntry(payrollEntry);
    this.staffMember = staffMember;
    this.isLoading = true;
    uhApiClient.get({
      url: `payroll_entries/${payrollEntry.id}`,
      success: PayrollEntryEditingActions.loadSuccess,
      error: PayrollEntryEditingActions.loadError,
    });
  }

  // eslint-disable-next-line camelcase
  handleLoadSuccess({ rate_type, value }) {
    this.payrollEntry = this.payrollEntry.merge({ rate_type, value });
    this.isLoading = false;
  }

  handleLoadError(...args) {
    this.notifyError('error loading event payroll entry', args);
    this.reset();
  }

  handleUpdate(updatedFields) {
    if (!this.payrollEntry) {
      return;
    }
    this.payrollEntry = this.payrollEntry.merge(updatedFields);
    if (this.payrollEntry.errors.get('errors').size) {
      this.validatePayrollEntry();
    }
  }

  handleConfirm() {
    this.validatePayrollEntry();

    if (this.payrollEntry.isValid()) {
      uhApiClient.put({
        url: `payroll_entries/${this.payrollEntry.id}`,
        data: JSON.stringify({ attributes: this.payrollEntry.toServer() }),
        success: PayrollEntryEditingActions.success,
        error: PayrollEntryEditingActions.error,
      });
    }
  }

  handleSuccess() {
    this.reset();
    return MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.updated',
        filename: __filenamespace,
      })
    );
  }

  handleError(...args) {
    this.notifyError('error managing event payroll entry', args);
    this.reset();
  }

  handleCloseModal() {
    this.reset();
  }
}

export default alt.createStore(
  PayrollEntryEditingStore,
  'PayrollEntryEditingStore'
);
