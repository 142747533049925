import { List, Set } from 'immutable';

import debounce from 'lodash.debounce';
import CommissionRate from 'shared/records/CommissionRate.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

import CommissionRateCreationActions from 'contacts/shared/actions/commission_rates/CommissionRateCreationActions';
import CommissionRateDeletionActions from 'contacts/shared/actions/commission_rates/CommissionRateDeletionActions';
import CommissionRateEditingActions from 'contacts/shared/actions/commission_rates/CommissionRateEditingActions';
import CommissionRateListingActions from 'contacts/shared/actions/commission_rates/CommissionRateListingActions';
// eslint-disable-next-line import/no-cycle
import CommissionRateCreationStore from 'contacts/shared/stores/commission_rates/CommissionRateCreationStore';
import { CommissionRateSource } from 'sources';

class CommissionRateListingStore extends UpperHandStore {
  constructor() {
    super();

    this.commissionRates = List();
    this.selectedItemIds = Set();
    this.isLoading = false;
    this.customerUserId = null;
    this.isStaffDrawer = false;
    this.items = List();
    this.search = '';

    this.isDirtySelectedItemIdsSet = false;
    this.debouncedList = debounce(this.handleListItems, 300);
    this.bindListeners({
      list: CommissionRateListingActions.LIST,
      listSuccess: CommissionRateListingActions.LIST_SUCCESS,
      listError: CommissionRateListingActions.LIST_ERROR,

      commissionRateCreated: CommissionRateCreationActions.SAVE_SUCCESS,
      commissionRateUpdated: CommissionRateEditingActions.SAVE_SUCCESS,
      commissionRateRemoved:
        CommissionRateDeletionActions.REMOVE_COMMISSION_RATE_SUCCESS,

      addSelectedItemId: [
        CommissionRateCreationActions.COMMISSION_RATE_COMMISSION_SELECTED,
        CommissionRateEditingActions.COMMISSION_RATE_COMMISSION_SELECTED,
      ],
      removeSelectedItemId: [
        CommissionRateCreationActions.COMMISSION_RATE_COMMISSION_REMOVED,
        CommissionRateEditingActions.COMMISSION_RATE_COMMISSION_REMOVED,
      ],
      cancelEdit: [
        CommissionRateEditingActions.CANCEL,
        CommissionRateCreationActions.CANCEL,
      ],
      addSpecificItem: CommissionRateListingActions.ADD_SPECIFIC_ITEM,
      removeSpecificItem: CommissionRateListingActions.REMOVE_SPECIFIC_ITEM,
      handleListItems: CommissionRateListingActions.LIST_ITEMS,
      handleListItemsSuccess: CommissionRateListingActions.LIST_ITEMS_SUCCESS,
      handleListItemsError: CommissionRateListingActions.LIST_ITEMS_ERROR,
      handleSearch: CommissionRateListingActions.HANDLE_SEARCH,
    });
  }

  fetchItem() {
    this.handleListItems();
  }

  updateItemList() {
    this.setSelectedItemIds();
    this.fetchItem();
    this.isDirtySelectedItemIdsSet = false;
  }

  cancelEdit() {
    if (this.isDirtySelectedItemIdsSet) {
      this.updateItemList();
    }
  }

  list(options) {
    if (!currentCustomer().payroll_enabled) {
      return;
    }

    const { page = 1, customerUserId, isStaffDrawer } = options || {};
    this.isStaffDrawer = isStaffDrawer;
    this.isLoading = true;
    if (customerUserId) {
      this.commissionRates = List();
      this.selectedItemIds = Set();
      this.customerUserId = customerUserId;
    }
    CommissionRateSource.fetchCommissions({
      url: 'commission_rates',
      params: {
        page,
        per_page: 100,
        customer_user_id: this.customerUserId,
      },
      success: CommissionRateListingActions.listSuccess,
      error: CommissionRateListingActions.listError,
    });
  }

  listSuccess(data) {
    const {
      commission_rates: commissionRates,
      page,
      per_page: perPage,
      total_count: totalCount,
    } = data;

    const partitionedRates = List(
      commissionRates.map(c => new CommissionRate(c))
    );

    this.commissionRates = this.commissionRates.concat(partitionedRates);
    if (
      commissionRates.length === perPage &&
      totalCount > this.commissionRates.size
    ) {
      this.list({ page: page + 1 });
    } else {
      this.setSelectedItemIds();
      this.isDirtySelectedItemIdsSet = false;
      const membershipIds = this.commissionRates.reduce(
        (set, rate) => set.union(rate.membership_ids),
        Set()
      );
      const categoryIds = this.commissionRates.reduce(
        (set, rate) => set.union(rate.retail_category_ids),
        Set()
      );
      const params = {
        page: 1,
        per_page: 20,
        include_membership_ids: membershipIds.toArray(),
        include_category_ids: categoryIds.toArray(),
      };
      CommissionRateSource.listItems({
        params,
        success: CommissionRateListingActions.listItemsSuccess,
        error: CommissionRateListingActions.listItemsError,
      });
      this.isLoading = false;
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing accounting codes', args);
  }

  commissionRateCreated(data) {
    this.commissionRates = this.commissionRates.push(new CommissionRate(data));
    this.updateItemList();
  }

  commissionRateUpdated(data) {
    const index = this.commissionRates.findIndex(r => r.id === data.id);

    this.commissionRates = this.commissionRates.set(
      index,
      new CommissionRate(data)
    );
    this.updateItemList();
  }

  commissionRateRemoved([_, id]) {
    const index = this.commissionRates.findIndex(r => r.id === id);
    if (index >= 0) {
      this.commissionRates = this.commissionRates.delete(index);
      this.waitFor(CommissionRateCreationStore);
      const { commissionRate } = CommissionRateCreationStore.getState();
      const isCreationCommissionRateCommissionIdsDirty =
        commissionRate && commissionRate.item_ids.size;
      this.setSelectedItemIds();
      if (isCreationCommissionRateCommissionIdsDirty) {
        this.selectedItemIds = this.selectedItemIds.merge(
          commissionRate.item_ids
        );
      }
      this.fetchItem();
      this.isDirtySelectedItemIdsSet =
        isCreationCommissionRateCommissionIdsDirty;
    }
  }

  addSelectedItemId(id) {
    this.selectedItemIds = this.selectedItemIds.add(id);
    this.fetchItem();
    this.isDirtySelectedItemIdsSet = true;
  }

  removeSelectedItemId(id) {
    this.selectedItemIds = this.selectedItemIds.remove(id);
    this.fetchItem();
    this.isDirtySelectedItemIdsSet = true;
  }

  setSelectedItemIds() {
    this.selectedItemIds = this.commissionRates.reduce(
      (set, rate) => set.union(rate.item_ids),
      Set()
    );
  }

  addSpecificItem(id) {
    const index = this.items.findIndex(e => e.id === id);
    if (index === -1) {
      const newItemToAdd = this.items.find(e => e.id === id);
      this.items = this.items.push(newItemToAdd);
    }
  }

  removeSpecificItem(id) {
    this.items = this.items.filter(se => se.id !== id);
  }

  handleListItems() {
    const params = {
      search: this.search,
      page: 1,
      per_page: 20,
    };
    CommissionRateSource.listItems({
      params,
      success: CommissionRateListingActions.listItemsSuccess,
      error: CommissionRateListingActions.listItemsError,
    });
  }

  handleListItemsSuccess(data) {
    this.items = this.items
      .filter(i => this.selectedItemIds.has(i.id))
      .concat(
        List(
          data.records.map(i => ({
            name: i.name,
            id: i.id,
            type: i.type,
            color: i?.color,
          }))
        )
      );
  }

  handleListItemsError(...args) {
    this.notifyError('error listing items', args);
  }

  handleSearch({ searchText }) {
    this.search = searchText;
    this.debouncedList();
  }
}

export default alt.createStore(
  CommissionRateListingStore,
  'CommissionRateListingStore'
);
