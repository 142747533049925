/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { List } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import DuplicateMembershipWarning from 'shared/components/memberships/DuplicateMembershipWarning.jsx';
import SelectedProfileList from 'shared/components/_SelectedProfileList.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import MembershipTierSelector from 'shared/components/memberships/MembershipTierSelector.jsx';
import PurchaseDrawerContainer from 'shared/components/PurchaseDrawerContainer.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import MembershipPurchasingActions from 'memberships/actions/MembershipPurchasingActions.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import WhenSignedIn from 'shared/components/WhenSignedIn.jsx';
import { MembershipViewingTiersBenefits } from 'memberships/components/MembershipViewing.jsx';

import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import './styles.scss';

const styles = {
  membershipTitle: {
    fontWeight: 600,
    fontFamily: 'benton-sans-condensed, sans-serif',
    textTransform: 'capitalize',
    fontSize: 18,
    paddingBottom: 7,
  },

  disablePadding: {
    padding: 0,
  },

  benefitExpandBtn: {
    backgroundColor: 'var(--color-highlight-yellow)',
    padding: '5px',
  },

  benefitsList: {
    backgroundColor: 'var(--color-highlight-yellow)',
  },

  benefitWrapper: {
    padding: '10px 5px',
  },

  benefitItem: {
    marginLeft: -2,
  },

  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100px',
  },

  divider: {
    margin: '20px -1.5rem',
  },
};

function NotPermittedDrawerContent({ intl, membership, ...containerProps }) {
  return (
    <PurchaseDrawerContainer {...containerProps}>
      <div style={styles.membershipTitle}>
        <FormattedMessage
          id={messageId('.invite_only_title', __filenamespace)}
          values={{ membership_name: membership.name }}
        />
      </div>
      <div style={{ marginBottom: 15 }}>
        <FormattedMessage
          id={messageId('.invite_only_description', __filenamespace)}
          values={{ customer: currentCustomer().name }}
        />
      </div>
      <Button
        fullWidth
        color="default"
        variant="contained"
        style={{
          margin: '1rem 0',
          height: 50,
          color: uhColors.activeBlue,
        }}
        onClick={containerProps.close}
      >
        {t('actions.close', intl)}
      </Button>
    </PurchaseDrawerContainer>
  );
}

class PurchaseDrawer extends React.Component {
  componentDidMount() {
    MembershipPurchasingActions.purchaseUrlCheck.defer();
  }

  render() {
    const {
      intl,
      cartStore,
      close,
      drawer,
      eventTypes,
      retailCategories,
      athleteStore,
      membershipPurchasing,
      title,
      isPermittedToPurchase,
      disableViewInCart,
      creditPasses,
      tiers,
      tiersLoading,
    } = this.props;

    const {
      cartMembershipMap,
      dirty,
      membership,
      orderItem,
      subscriptionPackage,
    } = membershipPurchasing;
    const packageIsValid = membership.tiered
      ? subscriptionPackage.customer_user_ids.size > 0 &&
        subscriptionPackage.membership_tier_id
      : subscriptionPackage.customer_user_ids.size > 0;

    const containerProps = {
      close,
      open: drawer.activeDrawer === 'MembershipPurchaseDrawer',
      style: { lineHeight: '24px', padding: '1.5rem' },
      headerContainerStyle: { padding: '1.5rem' },
      title: (
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
          }}
        >
          <Box sx={boldText}>{membership.name}</Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <span
              style={{ color: membership.color, lineHeight: 0, fontSize: 30 }}
            >
              &bull;
            </span>
            {title}
          </Box>
        </Box>
      ),
    };

    if (!isPermittedToPurchase(membership)) {
      return (
        <NotPermittedDrawerContent
          intl={intl}
          membership={membership}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...containerProps}
        />
      );
    }

    const selectedProfiles = athleteStore.allAthletes.filter(athlete =>
      subscriptionPackage.hasCustomerUser(athlete)
    );

    let selectableProfiles = athleteStore.allAthletes.filterNot(
      athlete =>
        athlete.active_membership_id ||
        subscriptionPackage.hasCustomerUser(athlete)
    );

    if (membership.get('invite_only')) {
      selectableProfiles = selectableProfiles.filter(athlete =>
        athlete.get('permitted_membership_ids').has(membership.id)
      );
    }

    const warnings = cartMembershipMap.map(
      ([membershipId, membershipName], customerUserId) => {
        if (membershipId === membership.id) {
          return null;
        }

        const profile = selectedProfiles.find(p => p.id === customerUserId);
        const profileName = profile ? profile.name() : '';

        return (
          <DuplicateMembershipWarning
            profileName={profileName}
            existingMembershipName={membershipName}
            newMembershipName={membership.name}
          />
        );
      }
    );

    const addToCart = () =>
      CartActions.subscriptionPackageAdded(subscriptionPackage);
    const updateCart = () =>
      CartActions.update(orderItem.id, subscriptionPackage);

    const membershipOrderInProgress = () => !!subscriptionPackage.id;

    const hasUpdate = disableViewInCart
      ? orderItem
      : dirty && membershipOrderInProgress();

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <PurchaseDrawerContainer {...containerProps}>
        <Box>{membership.description}</Box>
        <Divider sx={styles.divider} />
        {tiersLoading && (
          <Box sx={styles.loadingContainer}>
            <CircularProgress color="dark" />
          </Box>
        )}
        {(!tiersLoading || !membership.tiered) && (
          <MembershipViewingTiersBenefits
            membershipTiers={membership.tiered ? tiers : List([membership])}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
            membershipTiersLoading={false}
            wrapperStyles={styles.disablePadding}
            benefitsItemStyles={styles.disablePadding}
            expanderBtnStyles={styles.benefitExpandBtn}
            benefitsListStyles={styles.benefitsList}
            benefitsWrapperStyle={styles.benefitWrapper}
            discountsItemStyle={styles.benefitItem}
            exclusivitiesItemStyle={styles.benefitItem}
          />
        )}
        <Divider sx={styles.divider} />
        <WhenSignedIn
          notSignedIn={
            <SignInPrompt redirectTo={membership.url({ relative: true })} />
          }
        >
          {membership.tiered && (
            <MembershipTierSelector
              fullWidth
              disabled={tiersLoading}
              tiers={tiers.toArray()}
              value={subscriptionPackage.membership_tier_id}
              classes={{
                root: 'membership-tier-selector',
                inputLabel: 'membership-tier-selector__label',
                inputLabelTypography:
                  'membership-tier-selector__label-typography',
              }}
              onChange={e =>
                MembershipPurchasingActions.membershipTierSelected(
                  e.target.value
                )
              }
            />
          )}
          <ProfileSelector
            style={{ marginBottom: 20 }}
            header={t('.for_whom', intl, __filenamespace)}
            afterProfileCreate={MembershipPurchasingActions.profileCreated}
            athletes={selectableProfiles}
            onChange={(_, __, id) =>
              MembershipPurchasingActions.profileAdded(id)
            }
            preventProfileCreation={membership.get('invite_only')}
            value={null}
          />
          <SelectedProfileList
            athletes={selectedProfiles}
            onRemove={id => MembershipPurchasingActions.profileRemoved(id)}
            warnings={warnings}
          />
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button
              fullWidth
              color="dark"
              variant="outlined"
              sx={{
                margin: '1rem 0',
                height: '50px',
              }}
              onClick={close}
            >
              {t('actions.cancel', intl)}
            </Button>
            <CartCommitButton
              addToCartLabel={
                membership.price === 0 && !membership.tiered
                  ? t('actions.enroll', intl)
                  : null
              }
              handleAddToCartClick={addToCart}
              handleUpdateCartClick={updateCart}
              hasOrderItemId={membershipOrderInProgress()}
              hasUpdate={hasUpdate}
              itemIsValid={packageIsValid}
              isNavigatingToCart={cartStore.isNavigatingToCart}
              isUpdatingCart={cartStore.isUpdatingCart}
            />
          </Box>
        </WhenSignedIn>
      </PurchaseDrawerContainer>
    );
  }
}

export default injectIntl(PurchaseDrawer);
