import React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import ContactImportNativeActions from 'contacts/index/actions/ContactImportNativeActions.js';
import ContactReview from 'contacts/index/components/ContactImportNative/_ContactReview.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import styles from 'contacts/index/components/ContactImportNative/styles';
import { t } from 'shared/utils/LocaleUtils.js';

const StepReview = injectIntl(({ selectedContacts, intl }) => {
  const enableButton = selectedContacts.size > 0;
  return (
    <div style={styles.selectContacts}>
      <div style={styles.contactList(enableButton)}>
        {selectedContacts.map(contact => (
          <ContactReview key={contact.id} contact={contact} />
        ))}
      </div>
      <FlexBoxCenter style={styles.buttonContainer(enableButton)}>
        <Button
          variant="contained"
          className="import-contacts-primary-action"
          disabled={!enableButton}
          style={styles.button}
          onClick={ContactImportNativeActions.invite}
        >
          {t('.invite', intl, __filenamespace, {
            count: selectedContacts.size,
          })}
        </Button>
      </FlexBoxCenter>
    </div>
  );
});

export default StepReview;
