export default alt.generateActions(
  'listCustomerUsers',
  'listCustomerUsersSuccess',
  'listCustomerUsersError',
  'pageChanged',
  'purchaseOrderList',
  'purchaseOrderListSuccess',
  'purchaseOrderListError',
  'search',
  'updateDateFilter'
);
