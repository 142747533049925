import * as React from 'react';
import { injectIntl } from 'react-intl';

import SelectedProfileList from 'shared/components/_SelectedProfileList.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const onProfileSelect = (_, __, id) =>
  PDRegistrationPackageActions.fixedScheduleAthleteAdded(id);
const onProfileRemove = id =>
  PDRegistrationPackageActions.fixedScheduleAthleteRemoved(id);

function WDSimpleFixedScheduleContent({
  intl,
  event,
  selectableAthletes,
  selectedAthletes,
}) {
  return (
    <div>
      <ProfileSelector
        afterProfileCreate={
          PDRegistrationPackageActions.fixedScheduleProfileCreated
        }
        athletes={selectableAthletes}
        header={t('actions.choose_attendees', intl)}
        onChange={onProfileSelect}
        preventProfileCreation={event.isMembershipExclusive()}
        value={null}
      />
      <SelectedProfileList
        athletes={selectedAthletes}
        onRemove={onProfileRemove}
      />
    </div>
  );
}

export default injectIntl(WDSimpleFixedScheduleContent);
