import { List, Map, OrderedMap, OrderedSet } from 'immutable';
import debounce from 'lodash.debounce';
import Client from 'shared/records/Client.jsx';
import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions.js';
import MembershipStoreInterface from 'shared/stores/MembershipStoreInterface.jsx';
import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';
import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions.js';
import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import MembershipActions from 'shared/actions/MembershipActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils';
import { downloadFile } from 'shared/utils/SharedUtils.js';
import { MembershipSource, MembershipTierSource } from 'sources';

const MAX_SEARCH_RESULTS = 8;
const API_CALL_INTERVAL = 400;
const ALL = 'all';

class MembershipViewingStore extends MembershipStoreInterface {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      membershipLoaded: MembershipActions.MEMBERSHIP_LOADED,
      listTiersSuccess: MembershipViewingActions.LIST_TIERS_SUCCESS,
      listTiersError: MembershipViewingActions.LIST_TIERS_ERROR,
      clientsLoaded: MembershipViewingActions.CLIENTS_LOADED,
      handleCancelledSubscription:
        MembershipCancellationActions.CANCEL_MEMBERSHIP_SUCCESS,
      handleSubscriptionSuspendReactivation: [
        MembershipSuspensionActions.SUSPEND_MEMBERSHIP_SUCCESS,
        MembershipSuspensionActions.REACTIVATE_MEMBERSHIP_SUCCESS,
      ],
      handleInviteSuccess: MembershipViewingActions.INVITE_SUCCESS,
      handleSearchClients:
        MembershipViewingActions.INVITE_SEARCH_STRING_UPDATED,
      handleSearchClientsSuccess:
        MembershipViewingActions.QUERIED_CLIENTS_LOADED,
      handleUpdateStore: MembershipViewingActions.UPDATE_STORE,
      inviteClient: MembershipViewingActions.INVITE,
      listClients: MembershipViewingActions.LIST_CLIENTS,
      removeMembership: MembershipViewingActions.REMOVE_CONFIRMED,
      removeMembershipError: MembershipViewingActions.REMOVE_ERROR,
      removeMembershipSuccess: MembershipViewingActions.REMOVE_SUCCESS,
      requestClientsPage: MembershipViewingActions.SELECT_PAGE,
      updateSearchString: MembershipViewingActions.SEARCH_STRING_UPDATED,
      handleRemind: MembershipViewingActions.REMIND_CLICKED,
      handleRemindSuccess: MembershipViewingActions.REMIND_SUCCESS,
      handleFilterDrawerOpen: MembershipViewingActions.filterDrawerOpen,
      handleFilterDrawerClose: MembershipViewingActions.filterDrawerClose,
      handleFilterChange: MembershipViewingActions.onFilterChange,
      downloadList: MembershipViewingActions.downloadList,
      downloadListSuccess: MembershipViewingActions.downloadListSuccess,
      downloadListError: MembershipViewingActions.downloadListError,
      error: MembershipViewingActions.error,
    });

    this.debouncedSearchClients = debounce(
      this.searchClientsApiCall,
      API_CALL_INTERVAL
    );
    this.getActiveFilterKey = () => this.clientFilters.findKey(value => value);
  }

  reset() {
    this.clients = OrderedMap();
    this.queriedClients = List();
    this.searchString = '';
    this.shouldFetchClients = true;
    this.totalClientsCount = 0;
    this.clientsPage = 1;
    this.isFilterDrawerOpen = false;
    this.isMembersListDownloading = false;
    this.isMembersLoading = false;
    this.tiersLoading = false;
    this.tiersIds = OrderedSet();
    this.clientFilters = Map({
      suspended: false,
      cancelled: false,
      active: true,
      invited: false,
      all: false,
    });
  }

  membershipLoaded(membership) {
    if (membership?.tiered) {
      this.listMembershipTiers();
    }
  }

  listMembershipTiers() {
    this.tiersLoading = true;
    MembershipTierSource.list({
      params: {
        membership_id: this.record.id,
        fields: ['events', 'event_types'],
        per_page: 50,
      },
      success: MembershipViewingActions.listTiersSuccess,
      error: MembershipViewingActions.listTiersError,
    });
  }

  listTiersSuccess({ membership_tiers: tiers }) {
    this.tiersLoading = false;
    this.tiersIds = this.tiersIds.union(OrderedSet(tiers.map(tier => tier.id)));
  }

  listTiersError(...args) {
    this.tiersLoading = false;
    this.notifyError('error fetching membership tiers', args);
  }

  clientsQueryParams() {
    const params = {
      page: this.clientsPage,
      per_page: this.clientsPerPage,
      search_string: this.searchString,
    };
    const activeFilter = this.getActiveFilterKey();
    if (activeFilter && activeFilter !== ALL) {
      params.status = activeFilter;
    }
    return params;
  }

  clientsLoaded({
    customer_users: customerUsers,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.isMembersLoading = false;
    this.clientsPage = page;
    this.clientsPerPage = perPage;
    this.totalClientsCount = totalCount;

    this.clients = OrderedMap().withMutations(clientMap =>
      customerUsers.forEach(c => clientMap.set(c.id, new Client(c)))
    );
  }

  handleFetch({ id }) {
    this.reset();
    this.requestedMembershipFetchBy = this.displayName;
    super.handleFetch({ id, fields: ['events', 'event_types', 'revenue'] });
  }

  handleSubscriptionSuspendReactivation() {
    // Only do this for membership viewing page
    if (!this.isViewingMembership()) {
      return;
    }

    this.shouldFetchClients = true;
    this.listClients();
  }

  handleCancelledSubscription(data) {
    // Only do this for membership viewing page
    if (!this.isViewingMembership()) {
      return;
    }

    const cancelledSub = new MembershipSubscription(data);
    if (!cancelledSub.isActive()) {
      this.shouldFetchClients = true;
      this.listClients();
      this.record = this.record.update(
        'active_client_count',
        count => count - 1
      );
    }
  }

  listClients(opts) {
    const options = opts || {};
    const recordID = this.record?.id;
    const membershipID = options.id || recordID;
    const shouldFetch = this.shouldFetchClients || membershipID !== recordID;
    if (shouldFetch) {
      uhApiClient.get({
        url: `${this.url(membershipID)}/clients`,
        data: this.clientsQueryParams(),
        success: MembershipViewingActions.clientsLoaded,
        error: MembershipViewingActions.error,
      });
    }
  }

  requestClientsPage([page]) {
    if (this.clientsPage === page) {
      return;
    }

    this.clientsPage = page;
    this.shouldFetchClients = true;

    this.listClients();
  }

  updateSearchString(newSearchString) {
    if (this.searchString === newSearchString) {
      return;
    }
    this.searchString = newSearchString;
    this.clientsPage = 1;
    this.shouldFetchClients = true;
    this.listClients();
  }

  handleSearchClientsSuccess({ customer_users: customerUsers }) {
    this.queriedClients = List(customerUsers.map(c => new Client(c)));
  }

  handleSearchClients(newSearchString) {
    this.debouncedSearchClients(newSearchString);
  }

  searchClientsApiCall(newSearchString) {
    uhApiClient.get({
      url: `${this.url(this.record.id)}/unsubscribed_clients`,
      data: {
        page: 1,
        per_page: MAX_SEARCH_RESULTS,
        search_string: newSearchString,
      },
      success: MembershipViewingActions.queriedClientsLoaded,
      error: MembershipViewingActions.error,
    });
  }

  inviteClient(clientId) {
    uhApiClient.post({
      url: this.inviteUrl(),
      data: JSON.stringify({
        customer_user_id: clientId,
      }),
      success: MembershipViewingActions.inviteSuccess,
      error: MembershipViewingActions.error,
    });
  }

  handleInviteSuccess() {
    MessageWindowActions.addMessage.defer('Contact added successfully!');
    this.shouldFetchClients = true;
    this.listClients();
  }

  removeMembership() {
    uhApiClient.delete({
      url: this.url(this.record.id),
      success: MembershipViewingActions.removeSuccess,
      error: MembershipViewingActions.removeError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  removeMembershipSuccess() {
    window.location.href = customerScopedRoute('memberships');
  }

  removeMembershipError(...args) {
    this.notifyError(
      `error removing membership with id ${this.record.id}`,
      args
    );
  }

  handleRemind(clientId) {
    uhApiClient.post({
      url: this.inviteUrl(),
      data: JSON.stringify({
        customer_user_id: clientId,
      }),
      success: MembershipViewingActions.remindSuccess,
      error: MembershipViewingActions.error,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleRemindSuccess() {
    MessageWindowActions.addMessage.defer('Reminded!');
  }

  // eslint-disable-next-line class-methods-use-this
  error(...args) {
    const error = args[0];
    const isInviteError =
      error &&
      error.status === 403 &&
      error.url.includes(`memberships/${this.record.id}/invite`);

    if (isInviteError) {
      MessageWindowActions.addMessage.defer(error.httpMessage || error.message);
      return;
    }

    this.notifyError('MembershipViewingStore error', args);
  }

  inviteUrl() {
    return `${this.url(this.record.id)}/invite`;
  }

  isViewingMembership() {
    return window.location.pathname.includes(`memberships/${this.record?.id}`);
  }

  handleFilterDrawerOpen() {
    this.isFilterDrawerOpen = true;
  }

  handleFilterDrawerClose() {
    this.isFilterDrawerOpen = false;
  }

  handleFilterChange(keyToChange) {
    this.clientFilters = this.clientFilters.map((value, key) =>
      key === keyToChange ? !value : false
    );
    this.isMembersLoading = true;
    this.listClients();
  }

  downloadList() {
    this.isMembersListDownloading = true;
    const params = {
      search_string: this.searchString,
    };
    const activeFilter = this.getActiveFilterKey();
    if (activeFilter && activeFilter !== ALL) {
      params.status = activeFilter;
    }
    MembershipSource.downloadCsv({
      membershipID: this.record?.id,
      params,
      success: MembershipViewingActions.downloadListSuccess,
      error: MembershipViewingActions.downloadListError,
    });
  }

  downloadListSuccess(data) {
    downloadFile({
      data,
      fileName: 'members_list.csv',
    });
    this.isMembersListDownloading = false;
  }

  downloadListError(...args) {
    this.isMembersListDownloading = false;
    this.notifyError(`error while downloading members list `, args);
  }
}

export default alt.createStore(
  MembershipViewingStore,
  'MembershipViewingStore'
);
