import React from 'react';

function NoAddressOnFile({
  className = '',
  color = '#B32032',
  onClick = () => {},
}) {
  return (
    <svg
      className={className}
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 18.75V17.25C10 17.1119 9.88807 17 9.75 17H5.25C5.11193 17 5 16.8881 5 16.75V7.61709C5 7.5429 5.03296 7.47254 5.08995 7.42504L10.84 2.63337C10.9327 2.55611 11.0673 2.55611 11.16 2.63337L16.91 7.42504C16.967 7.47254 17 7.5429 17 7.61709V9.75C17 9.88807 17.1119 10 17.25 10H18.75C18.8881 10 19 9.88807 19 9.75V9.25C19 9.11193 19.1119 9 19.25 9H21.2996C21.5346 9 21.6398 8.70529 21.458 8.55651L11.1583 0.129526C11.0662 0.0541783 10.9338 0.0541779 10.8417 0.129525L0.542042 8.55651C0.360204 8.70529 0.465406 9 0.700352 9H2.75C2.88807 9 3 9.11193 3 9.25V18.75C3 18.8881 3.11193 19 3.25 19H9.75C9.88807 19 10 18.8881 10 18.75Z"
        fill={color}
      />
      <path
        d="M18.9568 13.026L17.8236 11.8855C17.7225 11.787 17.5629 11.7818 17.4565 11.8803L15.339 13.8919L13.2747 11.8284C13.1736 11.7247 13.014 11.7247 12.9076 11.8232L11.7425 12.9275C11.6414 13.0209 11.6361 13.1816 11.7371 13.2801L13.8014 15.3487L11.6786 17.3603C11.5775 17.4588 11.5722 17.6143 11.6733 17.718L12.8065 18.8534C12.9076 18.9519 13.0672 18.9571 13.1736 18.8586L15.2964 16.847L17.3607 18.9156C17.4618 19.0142 17.6214 19.0193 17.7278 18.9208L18.893 17.8165C18.9941 17.718 18.9994 17.5625 18.8983 17.4588L16.834 15.3902L18.9568 13.3786C19.0526 13.2853 19.0579 13.1246 18.9568 13.026Z"
        fill={color}
      />
    </svg>
  );
}

export default NoAddressOnFile;
