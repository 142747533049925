import { List } from 'immutable';
import Registration from 'shared/records/Registration';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';
import DataStoreActions from './RegistrationActions';

const parser = new Parser({
  type: Registration,
  paginationKey: 'registrations',
});

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `registrations/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'registrations',
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error,
  });
};

export const destroy = ({ id, params = {}, success, error }) => {
  uhApiClient.delete({
    url: `registrations/${id}`,
    data: JSON.stringify(params),
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

export const destroyFixedSSPOff = ({ eventId, params, success, error }) => {
  uhApiClient.post({
    url: `events/${eventId}/remove_clients`,
    data: JSON.stringify(params),
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

export const schedule = ({ scheduleObj, success, error }) => {
  uhApiClient.post({
    url: `registrations/schedule`,
    data: JSON.stringify({
      attributes: [scheduleObj.toServer()],
    }),
    success: onSuccess(data => parser.parseSingle(data), success),
    error,
  });
};

export const reschedule = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `registrations/${id}/reschedule`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.rescheduleSuccess, success])
    ),
    error,
  });
};

export const remind = ({ id, success, error }) => {
  uhApiClient.post({
    url: `registrations/${id}/remind`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.remindSuccess, success])
    ),
    error,
  });
};

export const updateRsvpStatus = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `registrations/${id}/rsvp`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateRsvpStatusSuccess, success])
    ),
    error,
  });
};

// /special object for unique required params of schedule endpoint.
export class Schedule {
  constructor(clientId) {
    this.clientId = clientId;

    this.sessionId = '';

    this.scheduleId = 0;
    this.resourceIds = [];
    this.staffIds = [];
    this.startsAt = '';
    this.endsAt = '';
  }

  bySession(sessionId) {
    this.sessionId = sessionId;
  }

  bySchedule(scheduleId, resourceIds, staffIds, startsAt, endsAt) {
    this.scheduleId = scheduleId;
    this.resourceIds = resourceIds;
    this.staffIds = staffIds;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
  }

  toServer() {
    if (this.sessionId !== '') {
      return {
        client_id: this.clientId,
        session_id: this.sessionId,
      };
    }

    return {
      schedule_id: this.scheduleId,
      resource_ids: this.resourceIds,
      staff_ids: this.staffIds,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
    };
  }
}
