import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Tabs } from '@upperhand/playmaker';
import AltContainer from 'alt-container';

import AccountIcon from '@mui/icons-material/AccountBox';
import ProfilesIcon from '@mui/icons-material/SupervisorAccount';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NotificationsIcon from '@mui/icons-material/Sms';

import AccountTab from 'users/settings/components/AccountTab.jsx';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import Billing from 'containers/client/settings/billing/Billing.jsx';
import ClientActions from 'shared/actions/ClientActions.jsx';
import ClientStore from 'shared/stores/ClientStore.jsx';
import EventToolbar from 'event_mgmt/shared/components/_EventToolbar.jsx';
import NotificationsTab from 'users/settings/components/NotificationsTab.jsx';
import PageHeader from 'shared/components/PageHeader.jsx';
import ProfilesTab from 'users/settings/components/ProfilesTab.jsx';
import UserActions from 'shared/actions/UserActions.jsx';
import UserStore from 'shared/stores/UserStore.jsx';

import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  header: {
    backgroundColor: 'var(--color-bg-gray)',
  },
};

function Settings({ intl }) {
  React.useEffect(() => {
    UserActions.fetch();
    AthleteActions.list();
    ClientActions.updateStore({ managed_by_id: currentUser().id });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedTab, setSelectedTab] = React.useState(0);

  const onTabChange = (_event, value) => setSelectedTab(value);

  const getTabLabel = tabName => {
    if (!smallScreen(896)) return tabName;

    const mobileLabels = {
      Account: <AccountIcon />,
      Profiles: <ProfilesIcon />,
      Billing: <CreditCardIcon />,
      Notifications: <NotificationsIcon />,
    };
    return mobileLabels[tabName];
  };

  const tabs = [
    {
      label: getTabLabel('Account'),
      content: (
        <AltContainer store={UserStore}>
          <AccountTab />
        </AltContainer>
      ),
    },
    !currentUser().isClient()
      ? {
          label: getTabLabel('Profiles'),
          content: (
            <AltContainer
              stores={{ athleteStore: AthleteStore, clientStore: ClientStore }}
            >
              <ProfilesTab />
            </AltContainer>
          ),
        }
      : null,
    !currentUser().isClient()
      ? {
          label: getTabLabel('Billing'),
          content: (
            <Billing
              userId={currentUser().id}
              access={currentUser().access_to_vault}
              customerUserId={currentUser().customer_user_id}
            />
          ),
        }
      : null,
    {
      label: getTabLabel('Notifications'),
      content: (
        <AltContainer
          stores={{
            userStore: UserStore,
            CurrentContextStore,
          }}
        >
          <NotificationsTab intl={intl} />
        </AltContainer>
      ),
    },
  ].filter(tab => !!tab);

  return (
    <div>
      {currentUser().isClient() && <EventToolbar />}
      <PageHeader
        title={t('.header', intl, __filenamespace)}
        style={{
          ...styles.header,
          ...(currentUser().isClient() ? { paddingTop: 0 } : {}),
        }}
      />
      <Tabs
        variant="fullWidth"
        tabItems={tabs}
        onChange={onTabChange}
        value={selectedTab}
      />
    </div>
  );
}

export default injectIntl(Settings);
