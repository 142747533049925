import { List, Map, Set } from 'immutable';
import { SessionDataStore } from 'dataStores';

class SingleSessionContentStoreInterface {
  constructor() {
    this.expandedIds = Set();
    this.allDayAvailableIds = Set();
    this.allDayCheckedIds = Set();
    this.clientSessionMap = Map();
  }

  setStoreStates(schedulingStore) {
    this.waitFor(schedulingStore);

    const {
      event,
      registrationPackage,
      tentativeAthleteIds,
      sessionAvailability,
      purchasedRegistrations,
      purchasedRegistrationsLoaded,
    } = schedulingStore.getState();

    const { sessions: sessionRecords } = SessionDataStore.getState();
    const activeEventSessions = event?.session_ids.filterNot(sessionId =>
      sessionRecords.get(sessionId)?.isCancelled()
    );

    if (purchasedRegistrationsLoaded) {
      const tentativeRegistrations = registrationPackage.tentative_details;
      const purchasedAndTentativeRegistrations = tentativeRegistrations.concat(
        purchasedRegistrations
      );

      this.clientSessionMap = purchasedAndTentativeRegistrations
        .groupBy(r => r.client_id)
        .map(registrations =>
          registrations.map(r =>
            Map({ session_id: r.session_id, purchased: !!r.id })
          )
        );

      tentativeAthleteIds.forEach(client => {
        if (!this.clientSessionMap.has(client)) {
          this.clientSessionMap = this.clientSessionMap.set(client, List());
        }
      });

      const allSessionsAvailable = sessionAvailability.every(a => a > 0);

      this.allDayAvailableIds = allSessionsAvailable
        ? tentativeAthleteIds
        : Set();

      if (allSessionsAvailable) {
        this.allDayAvailableIds = tentativeAthleteIds;
      } else {
        this.allDayAvailableIds = tentativeAthleteIds.filter(clientId => {
          const sessions = this.clientSessionMap.get(clientId);
          return sessionAvailability.every(
            (availability, session) =>
              availability +
                sessions.count(s => s.get('session_id') === session) >
              0
          );
        });
      }

      if (activeEventSessions) {
        this.allDayCheckedIds = Set(
          this.clientSessionMap
            .filter(sessions => sessions.size === activeEventSessions.size)
            .keySeq()
        );
      }
    }
  }

  onProfileSelect(id) {
    this.expandedIds = this.expandedIds.add(id);
  }

  toggleExpand(athleteId) {
    if (this.expandedIds.includes(athleteId)) {
      this.expandedIds = this.expandedIds.delete(athleteId);
    } else {
      this.expandedIds = this.expandedIds.add(athleteId);
    }
  }
}

export default SingleSessionContentStoreInterface;
