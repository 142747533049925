import * as React from 'react';
import PropTypes from 'prop-types';

import VisaIcon from 'shared/components/icons/payment_cards/Visa.jsx';
import MastercardIcon from 'shared/components/icons/payment_cards/Mastercard.jsx';
import DiscoverIcon from 'shared/components/icons/payment_cards/Discover.jsx';
import AmexIcon from 'shared/components/icons/payment_cards/Amex.jsx';
// import BitcoinIcon from 'shared/components/icons/payment_cards/Bitcoin.jsx';
import MaestroIcon from 'shared/components/icons/payment_cards/Maestro.jsx';

function CardIcon({ brand, style }) {
  // 'AM' => 'American Express',
  // 'DC' => 'Diners Club',
  // 'DI' => 'Discover',
  // 'JC' => 'JCB',
  // 'MC' => 'Mastercard',
  // 'MD' => 'Maestro',
  // 'SO' => 'Solo',
  // 'VI' => 'Visa',
  // 'VD' => 'Visa Debit',
  // 'VE' => 'Visa Electron',
  let cardIcon;
  switch (brand) {
    case 'VI':
      cardIcon = <VisaIcon style={style} />;
      break;
    case 'MC':
      cardIcon = <MastercardIcon style={style} />;
      break;
    case 'MD':
      cardIcon = <MaestroIcon style={style} />;
      break;
    case 'DI':
      cardIcon = <DiscoverIcon style={style} />;
      break;
    case 'AM':
      cardIcon = <AmexIcon style={style} />;
      break;
    default:
      cardIcon = brand;
  }
  return cardIcon;
}

CardIcon.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default CardIcon;
