import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { messageId } from 'shared/utils/LocaleUtils.js';

import OWNER_TYPE from 'calendar/types/OwnerType.jsx';

import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';

const styles = {
  AddButton: {
    padding: '8px',
  },
  AddButtonText: {
    margin: '0 auto 0 8px',
  },
  MenuItem: {
    fontSize: '15px',
  },
  ResourceIcon: {
    margin: '0 8px 0 0',
  },
};

const handleAddItem = (id, ownerType) => {
  CalendarViewActions.addCalendar({ id, ownerType });
  CalendarViewActions.toggleShowAddCalendarsMenu(false);
};

const excludedStaff = (calendarView, staff) =>
  staff.filter(
    s =>
      !calendarView
        .calendars()
        .find(c => c.ownerType === OWNER_TYPE.STAFF && c.id === s.id)
  );

const excludedResources = (calendarView, resources) =>
  resources.filter(
    r =>
      !calendarView
        .calendars()
        .find(c => c.ownerType === OWNER_TYPE.RESOURCE && c.id === r.id)
  );

const staffMenuItems = items =>
  items.map(s => (
    <MenuItem
      key={`MenuItem-Staff-${s.id}`}
      onClick={() => handleAddItem(s.id, OWNER_TYPE.STAFF)}
      style={styles.MenuItem}
    >
      <AvatarWithName user={s} />
    </MenuItem>
  ));

const resourceMenuItems = items =>
  items.map(r => (
    <MenuItem
      key={`MenuItem-Resource-${r.id}`}
      onClick={() => handleAddItem(r.id, OWNER_TYPE.RESOURCE)}
      style={styles.MenuItem}
    >
      <FlexBoxCenter>
        <ResourceIcon color={uhColors.iconGrey} style={styles.ResourceIcon} />
        <div>{r.name}</div>
      </FlexBoxCenter>
    </MenuItem>
  ));

function AddButton({ handleClick, resourcesEnabled }) {
  const messageKey = resourcesEnabled
    ? '.add_staff_resource_calendar'
    : '.add_staff_calendar';

  return (
    <Button
      fullWidth
      variant="outlined"
      startIcon={<AddIcon color={uhColors.activeBlue} />}
      onClick={handleClick}
      style={styles.AddButton}
    >
      <span style={styles.AddButtonText}>
        <FormattedMessage id={messageId(messageKey, __filenamespace)} />
      </span>
    </Button>
  );
}

function AddCalendarsDropdown({ calendarView, staff, resources, open }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const staffItems = excludedStaff(calendarView, staff);
  const resourceItems = excludedResources(calendarView, resources);
  const hasItems = staffItems.size || resourceItems.size;
  const resourcesEnabled = currentCustomer().resources_enabled;

  const handleAdd = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    CalendarViewActions.toggleShowAddCalendarsMenu(true);
  };

  return hasItems ? (
    <div>
      <AddButton handleClick={handleAdd} resourcesEnabled={resourcesEnabled} />
      <Menu
        open={open}
        anchorEl={anchorEl}
        style={{ maxHeight: 350 }}
        onClose={() => CalendarViewActions.toggleShowAddCalendarsMenu(false)}
      >
        {staffMenuItems(staffItems)}
        {resourcesEnabled && resourceMenuItems(resourceItems)}
      </Menu>
    </div>
  ) : null;
}

export default injectIntl(AddCalendarsDropdown);
