import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';

import Client from 'shared/records/Client.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';

import './styles.scss';

function ClientDetails({ client }) {
  return (
    <Grid
      container
      className="client-details"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={2}>
        <UserAvatar user={client} />
      </Grid>
      <Grid item xs={10}>
        <Typography align="left" variant="subtitle1">
          {client.name()}
        </Typography>
        <Typography
          className="client-details__email"
          align="left"
          variant="body3"
        >
          {client.email ?? '-'}
        </Typography>
      </Grid>
    </Grid>
  );
}

ClientDetails.propTypes = {
  client: PropTypes.instanceOf(Client).isRequired,
};

export default ClientDetails;
