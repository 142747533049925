import * as React from 'react';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    fontSize: 15,
    color: uhColors.textGrey,
  },
};

function SectionHeader({ children, style }) {
  return <div style={merge(styles.root, style)}>{children}</div>;
}

export default SectionHeader;
