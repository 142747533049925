import { List } from 'immutable';
import { TransactionLedger } from 'records';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';
import DataStoreActions from './TransactionLedgerActions';

const parserTo = { type: TransactionLedger, paginationKey: 'records' };

const parser = new Parser(parserTo);

// eslint-disable-next-line import/prefer-default-export
export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'ledger/entries',
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

// TODO: patternize object with downloadPaymentAllocations
export const downloadCsv = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'ledger/entries_csv',
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

export const downloadPaymentAllocations = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'ledger/payment_allocations_csv',
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};
