/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AltContainer from 'alt-container';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button, Dropdown, Grid, Icon, TextField } from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';

import EmptyState from 'shared/components/EmptyState.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import NoResourcesIcon from 'shared/components/icons/empty_states/Resources.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import ProductListingActions from 'retail/actions/ProductListingActions.js';
import ProductListingStore, {
  ALL_PRODUCTS,
  UNPUBLISHED_PRODUCTS,
  PUBLISHED_PRODUCTS,
} from 'retail/stores/ProductListingStore.js';

const styles = {
  publishBadge: {
    display: 'inline-block',
    padding: 5,
    fontWeight: 'bold',
    fontSize: 12,
    color: 'var(--color-white)',
    backgroundColor: uhColors.charcoalBlack,
  },

  price: {
    marginBottom: 5,
  },

  header: {
    alignItems: 'center',
    fontSize: 18,
    minHeight: 56,
    fontWeight: 'bold',
  },

  searchBar: {
    padding: '0 20px',
    fontSize: 18,
    flex: '1 1 auto',
  },

  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },

  productName: {
    fontWeight: 'bold',
    fontSize: 15,
    textAlign: 'left',
  },

  productCategory: {
    margin: '10px 0',
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },

  // Mimic MUI Paper
  tableRow: {
    boxSizing: 'border-box',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    borderRadius: 2,
    verticalAlign: 'top',
    backgroundColor: 'var(--color-white)',
  },
};

const onClearSearch = () => ProductListingActions.clearSearchClicked();
const onCreateClick = () =>
  redirectTo({ path: customerScopedRoute('retail/new') });

const onPageSelect = (page, perPage) => {
  ProductListingActions.paginationOptionsUpdated(page, perPage);
};

function NoProductsEmptyState({ searchString, intl }) {
  return searchString ? (
    <EmptyState
      image={<NoResourcesIcon />}
      headerText={t('.empty_state_search_header', intl, __filenamespace)}
      messageText={t('.empty_state_message', intl, __filenamespace)}
      primaryAction={{
        label: t('.create_product', intl, __filenamespace),
        action: onCreateClick,
      }}
      secondaryAction={{
        label: t('.clear_search', intl, __filenamespace),
        action: onClearSearch,
      }}
    />
  ) : (
    <EmptyState
      image={<NoResourcesIcon />}
      headerText={t('.empty_state_header', intl, __filenamespace)}
      messageText={t('.empty_state_message', intl, __filenamespace)}
      primaryAction={{
        label: t('.create_product', intl, __filenamespace),
        action: onCreateClick,
      }}
    />
  );
}

function ProductCards(props) {
  return (
    <ResponsiveElement
      smallScreen={<ProductCardsSmall {...props} />}
      largeScreen={<ProductCardsLarge {...props} />}
    />
  );
}

function ProductCardsSmall({ products }) {
  return (
    <div>
      {products.map(product => (
        <Paper
          key={product.id}
          style={{
            padding: 15,
            margin: 10,
            position: 'relative',
            backgroundColor: 'var(--color-white)',
          }}
        >
          <FlexBox>
            <img
              src={product.featured_image.getAlternative('thumb')}
              alt={product.name}
              height="60"
              width="60"
              style={{
                marginRight: 15,
                flex: '0 0 auto',
                objectFit: 'contain',
              }}
            />

            <div style={{ flex: '1 1 auto' }}>
              <div style={merge(styles.productName, { marginBottom: 10 })}>
                {product.name}
              </div>
              <div style={styles.productCategory}>
                {product.retail_category.name}
              </div>
              <ProductPublished
                product={product}
                style={{ marginBottom: 10 }}
              />

              <ProductPrice product={product} />
            </div>
          </FlexBox>
          <FlexBox style={{ position: 'absolute', top: 0, right: 0 }}>
            <ProductActionButton product={product} />
          </FlexBox>
        </Paper>
      ))}
    </div>
  );
}

function ProductCardsLarge({ products }) {
  return (
    <table style={styles.table}>
      <tbody>
        {products.map(product => (
          <tr key={product.id} style={styles.tableRow}>
            <td width={60} style={{ padding: 12, verticalAlign: 'middle' }}>
              <img
                src={product.featured_image.getAlternative('thumb')}
                alt={product.name}
                height="60"
                width="60"
                style={{ objectFit: 'contain' }}
              />
            </td>
            <td style={{ padding: '12px 0' }}>
              <div style={styles.productName}>{product.name}</div>
              <div style={styles.productCategory}>
                {product.retail_category.name}
              </div>
            </td>
            <td style={{ padding: '12px 0' }}>
              <ProductPrice product={product} />
            </td>
            <td style={{ padding: '12px 0' }}>
              <ProductPublished product={product} />
            </td>
            <td style={{ width: 48 }}>
              <ProductActionButton product={product} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function ProductPrice({ product }) {
  return (
    <FlexBox>
      <div style={{ fontSize: 15 }}>
        <div style={styles.price}>
          <FormattedMessage id={messageId('.price', __filenamespace)} />
        </div>
        <div style={styles.price}>
          <FormattedMessage id={messageId('.cost', __filenamespace)} />
        </div>
        <div>
          <FormattedMessage id={messageId('.variants', __filenamespace)} />
        </div>
      </div>
      <div style={{ fontSize: 15, marginLeft: 20 }}>
        {product.min_price !== product.max_price ? (
          <div style={styles.price}>
            <FormattedCurrency value={product.max_price} fromCents />
          </div>
        ) : (
          <div style={styles.price}>
            <FormattedCurrency value={product.min_price} fromCents />
            -
            <FormattedCurrency value={product.max_price} fromCents />
          </div>
        )}
        {product.min_cost !== product.max_cost ? (
          <div style={styles.price}>
            <FormattedCurrency value={product.max_cost} fromCents />
          </div>
        ) : (
          <div style={styles.price}>
            <FormattedCurrency value={product.min_cost} fromCents />
            -
            <FormattedCurrency value={product.max_cost} fromCents />
          </div>
        )}
        <div>{product.variant_ids.size}</div>
      </div>
    </FlexBox>
  );
}

function ProductPublished({ product, style }) {
  return (
    <div>
      {!product.published && (
        <div style={merge(styles.publishBadge, style)}>
          <FormattedMessage id={messageId('.unpublished', __filenamespace)} />
        </div>
      )}
    </div>
  );
}

const ProductActionButton = injectIntl(({ intl, product }) => {
  const [anchorEl, setAnchor] = React.useState();

  return (
    <>
      <IconButton onClick={e => setAnchor(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={() => setAnchor(null)}
      >
        <MenuItem
          onClick={() => {
            redirectTo({
              path: customerScopedRoute(`/retail/${product.id}/edit`),
            });
          }}
        >
          {t('actions.edit', intl, __filenamespace)}
        </MenuItem>
        {product.published ? (
          <MenuItem
            onClick={() => {
              ProductListingActions.togglePublishedUpdated(product);
            }}
          >
            {t('.unpublish', intl, __filenamespace)}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              ProductListingActions.togglePublishedUpdated(product);
            }}
          >
            {t('.publish', intl, __filenamespace)}
          </MenuItem>
        )}
      </Menu>
    </>
  );
});

function RetailListingContent({ productListingStore, intl }) {
  return (
    <div className="retail__product_filters">
      <Grid item xs={12}>
        <Grid container item spacing={1}>
          <div className="retail__add-button">
            <ReactRouterLink to={customerScopedRoute('/retail/new')}>
              <Button
                rounded
                icon={<Icon name="add" className="retail__add-button-icon" />}
                size="2x"
              />
            </ReactRouterLink>
          </div>
          <Grid item xs>
            <TextField
              classes={{
                root: 'retail__search-field',
              }}
              fullWidth
              icon="search"
              onChange={event =>
                ProductListingActions.searchStringUpdated(event.target.value)
              }
              placeholder={t('.search', intl, __filenamespace)}
              rounded
              value={productListingStore.searchString}
            />
          </Grid>
          <Grid item>
            <div className="retail__filter-dropdown-container">
              <Dropdown
                classes={{
                  notchedOutline: 'retail__filter-dropdown',
                }}
                items={[
                  {
                    label: t('.no_filter', intl, __filenamespace),
                    value: ALL_PRODUCTS,
                  },
                  {
                    label: t('.published_filter', intl, __filenamespace),
                    value: PUBLISHED_PRODUCTS,
                  },
                  {
                    label: t('.unpublished_filter', intl, __filenamespace),
                    value: UNPUBLISHED_PRODUCTS,
                  },
                ]}
                fullWidth
                outline={false}
                onChange={event =>
                  ProductListingActions.filterUpdated(event.target.value)
                }
                rounded
                value={productListingStore.filterString}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <SpinWhileLoading
        isLoading={productListingStore.isLoading}
        spinWhile="isLoading"
      >
        {productListingStore.products.isEmpty() && (
          <NoProductsEmptyState
            searchString={productListingStore.searchString}
            intl={intl}
          />
        )}
        <ProductCards products={productListingStore.products} />
      </SpinWhileLoading>

      <Paginator
        className="retail__pagination"
        currentPage={productListingStore.page}
        perPage={productListingStore.perPage}
        totalCount={productListingStore.totalCount}
        onPageSelect={onPageSelect}
        pageLimit={0}
        showInfo
      />
    </div>
  );
}

class TabProducts extends React.PureComponent {
  componentDidMount() {
    if (isLoggedIn()) {
      ProductListingActions.list();
    }
  }

  render() {
    return (
      <AltContainer
        stores={{
          productListingStore: ProductListingStore,
        }}
      >
        <RetailListingContent className="iphone-x-content" {...this.props} />
      </AltContainer>
    );
  }
}

export default injectIntl(TabProducts);
