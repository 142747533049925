export default alt.generateActions(
  'load',
  'loadSuccess',
  'loadError',
  'update',
  'confirm',
  'success',
  'error',
  'closeModal'
);
