import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import uhTheme from '_uh_theme.jsx';

function Help({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g
        id="App-Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Help"
          transform="translate(-18.000000, -556.000000)"
          fill={color}
        >
          <g id="Left-Nav">
            <path
              d="M37.32825,573.20575 L35.1645,571.04275 C35.69175,570.15025 36,569.11225 36,568 C36,566.88775 35.69175,565.84975 35.1645,564.9565 L37.32825,562.7935 C38.37675,564.265 39,566.059 39,568 C39,569.94025 38.37675,571.73425 37.32825,573.20575 M30,577 C28.05975,577 26.26575,576.37675 24.79425,575.3275 L26.95725,573.16375 C27.8505,573.691 28.8885,574 30,574 C31.113,574 32.151,573.691 33.0435,573.16375 L35.2065,575.3275 C33.735,576.37675 31.941,577 30,577 M21,568 C21,566.059 21.624,564.265 22.6725,562.7935 L24.8355,564.9565 C24.30825,565.84975 24,566.88775 24,568 C24,569.11225 24.30825,570.15025 24.8355,571.04275 L22.6725,573.20575 C21.624,571.73425 21,569.94025 21,568 M33,568 C33,568.15825 32.9805,568.31125 32.9535,568.462 C32.75325,569.746 31.74675,570.7525 30.46275,570.95275 C30.312,570.97975 30.159,571 30,571 C29.84175,571 29.68875,570.97975 29.538,570.95275 C28.254,570.7525 27.2475,569.746 27.04725,568.462 C27.0195,568.31125 27,568.15825 27,568 C27,567.841 27.0195,567.688 27.04725,567.53725 C27.2475,566.25325 28.254,565.24675 29.538,565.0465 C29.68875,565.01875 29.84175,565 30,565 C30.159,565 30.312,565.01875 30.46275,565.0465 C31.74675,565.24675 32.75325,566.25325 32.9535,567.53725 C32.9805,567.688 33,567.841 33,568 M30,559 C31.941,559 33.735,559.62325 35.2065,560.67175 L33.0435,562.83475 C32.151,562.3075 31.113,562 30,562 C28.8885,562 27.8505,562.3075 26.95725,562.83475 L24.79425,560.67175 C26.26575,559.62325 28.05975,559 30,559 M30,556 C23.373,556 18,561.37225 18,568 C18,574.62775 23.373,580 30,580 C36.62775,580 42,574.62775 42,568 C42,561.37225 36.62775,556 30,556"
              id="Help/Support"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Help.defaultProps = {
  color: uhTheme.palette.lightGrey,
};

export default Help;
