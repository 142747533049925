class PaymentDetailsDrawerActions {
  constructor() {
    this.generateActions(
      'closeDrawer',
      'fetchBalanceDetailsError',
      'fetchBalanceSuccess',
      'fetchBalanceDetailsSuccess',
      'fetchClientResponse',
      'fetchLocationResponse',
      'openDrawer',
      'sendReceipt',
      'payBalance',
      'toggleDotsMenu'
    );
  }
}

export default alt.createActions(PaymentDetailsDrawerActions);
