import moment from 'moment-timezone';
import { Record } from 'immutable';

export default class SubscriptionCard extends Record({
  first_name: '',
  last_name: '',
  masked_card_number: '',
  card_type: '',
  expiration_month: '',
  expiration_year: '',
}) {
  name() {
    return `${this.first_name} ${this.last_name}`;
  }

  identifyingDigits() {
    return this.isValid() && this.masked_card_number.split('-').pop();
  }

  isValid() {
    return !!this.masked_card_number;
  }

  expirationDate() {
    return moment(
      `${this.expiration_month}-${this.expiration_year}`,
      'M-YYYY'
    ).format('MM/YYYY');
  }
}
