import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import { TextField, Dropdown, Checkbox, Grid } from '@upperhand/playmaker';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';

import CustomerActions from 'shared/actions/CustomerActions.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import ValidateMicroDepositActions from 'shared/actions/ValidateMicroDepositActions.js';
import ValidateMicroDepositStore from 'shared/stores/ValidateMicroDepositStore.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { lastN } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhFormElement, uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  accountCard: {
    defaultText: {
      fontWeight: 'bold',
      color: uhColors.green,
    },
    deleteButton: {
      marginTop: -20,
      marginRight: -16,
    },
    bankName: {
      color: 'var(--color-old-gray)',
      fontSize: 15,
      fontWeight: 600,
      margin: '14px 0px 0px',
    },
    holderName: {
      color: 'var(--color-old-gray)',
      fontSize: 15,
      fontWeight: 600,
      margin: '15px 0px',
    },
    paper: {
      height: 118,
      width: 240,
      marginLeft: 0,
      marginTop: 24,
      paddingBottom: 18,
      padding: '0px 14px',
      textAlign: 'left',
      display: 'inline-block',
      borderLeft: `solid 2px ${uhColors.green}`,
    },
  },

  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    marginTop: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
  },

  singleColumn: {
    maxWidth: SINGLE_COLUMN_WIDTH,
    margin: LEFT_MARGIN_PIXELS,
  },

  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '90%',
  },

  sectionTitle: {
    color: 'var(--color-old-gray)',
    marginTop: '3rem',
  },
};

const currentAccounts = (customer, intl) =>
  customer.bank_accounts.map(bankAccount => (
    <DirectDepositAccountCard
      key={bankAccount.id}
      value={bankAccount}
      intl={intl}
    />
  ));

const handleBankAccountChange = name => e =>
  CustomerActions.updateNewBankAccount({ [name]: e.target.value });

const handleDefaultForCurrencyChange = (e, value) => {
  CustomerActions.updateNewBankAccount({ default_for_currency: !!value });
};

const handleRemoveBankAccount = bankAccountId => () =>
  CustomerActions.removeBankAccount(bankAccountId);

const handleSave = _ => CustomerActions.addBankAccount({});

function BankAccountForm({ customerStore, style, intl }) {
  const { newBankAccount, fieldErrors } = customerStore;
  return (
    <Paper style={merge({ padding: 16 }, style)} className="fs-exclude">
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            name="routing_number"
            onChange={handleBankAccountChange('routing_number')}
            label={t('.routing_number', intl, __filenamespace)}
            value={newBankAccount?.get('routing_number')}
            errorText={fieldErrors.get('newBankAccount.routing_number')}
          />
        </Grid>
        <Grid item>
          <TextField
            name="account_number"
            onChange={handleBankAccountChange('account_number')}
            label={t('.account_number', intl, __filenamespace)}
            value={newBankAccount?.get('account_number')}
            errorText={fieldErrors.get('newBankAccount.account_number')}
          />
        </Grid>
        <Grid item>
          {' '}
          <TextField
            name="account_holder_name"
            onChange={handleBankAccountChange('account_holder_name')}
            label={t('.account_holder_name', intl, __filenamespace)}
            value={newBankAccount?.get('account_holder_name')}
            errorText={fieldErrors.get('newBankAccount.account_holder_name')}
          />
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              errorText={fieldErrors.get('newBankAccount.account_holder_type')}
              name="account_holder_type"
              fullWidth
              label={t('.account_holder_type', intl, __filenamespace)}
              value={newBankAccount?.get('account_holder_type')}
              items={[
                {
                  value: 'Business',
                  label: t('.type_company', intl, __filenamespace),
                },
                {
                  value: 'Personal',
                  label: t('.type_individual', intl, __filenamespace),
                },
              ]}
              onChange={e => {
                CustomerActions.updateNewBankAccount({
                  account_holder_type: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              errorText={fieldErrors.get('newBankAccount.currency')}
              name="currency"
              fullWidth
              label={t('.currency', intl, __filenamespace)}
              value={newBankAccount?.get('currency')}
              onChange={e => {
                CustomerActions.updateNewBankAccount({
                  currency: e.target.value,
                });
              }}
              items={[
                {
                  value: 'usd',
                  label: t('.currency_usd', intl, __filenamespace),
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Checkbox
            label={t('.default_for_currency', intl, __filenamespace)}
            labelPosition="right"
            name="default_for_currency"
            onChange={handleDefaultForCurrencyChange}
            checked={newBankAccount.default_for_currency}
          />
        </Grid>
      </Grid>

      <div>
        <StateChangingButton
          onClick={handleSave}
          disabled={customerStore.hasBeenValidated && !customerStore.isValid}
          inProgress={customerStore.isSaving}
        />
      </div>
    </Paper>
  );
}

function DefaultText({ text }) {
  return <div style={styles.accountCard.defaultText}>{text}</div>;
}

function DirectDepositAccountCard({ intl, value }) {
  const isDefault = value.get('default_for_currency');
  return (
    <Paper style={styles.accountCard.paper}>
      <div style={styles.accountCard.holderName}>
        {value.get('account_holder_name')}
      </div>
      <Divider />
      <div style={styles.accountCard.bankName}>{value.get('bank_name')}</div>
      <FlexBoxJustify style={{ marginTop: 10 }}>
        <div>{lastN(value.get('last4'), 4)}</div>
        {isDefault ? (
          <DefaultText text={t('.default_text', intl, __filenamespace)} />
        ) : (
          <TrashCan value={value} intl={intl} />
        )}
      </FlexBoxJustify>
    </Paper>
  );
}

function TrashCan({ intl, value }) {
  return (
    <Tooltip title={t('.remove_bank_account', intl, __filenamespace)}>
      <IconButton
        style={styles.accountCard.deleteButton}
        onClick={handleRemoveBankAccount(value.id)}
      >
        <DeleteIcon sx={{ color: styles.accountCard.holderName.color }} />
      </IconButton>
    </Tooltip>
  );
}

function ValidateMicroDeposits({
  intl,
  style,
  microdepositStore: {
    submitting,
    hasConfirmationError,
    valid,
    microdeposit,
    confirmation,
  },
}) {
  return (
    <div style={style}>
      <h2>{t('.validate_bank_account', intl, __filenamespace)}</h2>
      <Paper style={{ padding: 16, marginTop: 25 }} className="fs-exclude">
        <Grid container spacing={2} direction="column">
          <Grid item>
            {t('.validation_instructions', intl, __filenamespace)}
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              onChange={ValidateMicroDepositActions.update}
              name="micro_deposit"
              label={t('.deposit_label', intl, __filenamespace)}
              placeholder={t('.deposit_hint', intl, __filenamespace)}
              errorText=""
              value={microdeposit}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              onChange={ValidateMicroDepositActions.update}
              name="micro_deposit_confirmation"
              label={t('.deposit_confirmation_label', intl, __filenamespace)}
              placeholder={t(
                '.deposit_confirmation_hint',
                intl,
                __filenamespace
              )}
              floatingLabelStyle={uhFormElement.floatingLabel}
              errorText={
                hasConfirmationError
                  ? t('.deposit_confirmation_error', intl, __filenamespace)
                  : ''
              }
              value={confirmation}
            />
          </Grid>
          <Grid item>
            <StateChangingButton
              label={t('.submit', intl, __filenamespace)}
              labelInProgress={t('.processing', intl, __filenamespace)}
              onClick={ValidateMicroDepositActions.submit}
              disabled={!valid}
              inProgress={submitting}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

function CurrentAccounts({ intl, customerStore, style = {} }) {
  return (
    <div style={style}>
      <h2>{t('.saved_accounts', intl, __filenamespace)}</h2>
      {currentAccounts(customerStore.customer, intl)}
    </div>
  );
}

function Row({ name, value }) {
  return (
    <FlexBoxJustify style={{ marginTop: 10 }}>
      <div>{name}</div>
      <div>{value}</div>
    </FlexBoxJustify>
  );
}

function PaysafeDirectDepositAccountCard({ intl, value }) {
  return (
    <Paper style={merge(styles.accountCard.paper, { height: '58px' })}>
      <Row
        name={t('.account_row', intl, __filenamespace)}
        value={value.get('last4')}
      />
      <Row
        name={t('.routing_row', intl, __filenamespace)}
        value={value.get('routing_number')}
      />
    </Paper>
  );
}

function Message({ message }) {
  return (
    <Paper
      style={{
        width: SINGLE_COLUMN_WIDTH,
        margin: LEFT_MARGIN_PIXELS,
        padding: 16,
      }}
    >
      {message}
    </Paper>
  );
}

function PaysafeDirectDeposit({ intl, customerStore }) {
  if (currentCustomer().bank_account_validated) {
    if (customerStore.customer.bank_accounts.isEmpty()) {
      return (
        <Message message={t('.no_bank_accounts', intl, __filenamespace)} />
      );
    }
    return (
      <div style={styles.singleColumn}>
        <h2>{t('.saved_accounts', intl, __filenamespace)}</h2>
        <PaysafeDirectDepositAccountCard
          value={customerStore.customer.bank_accounts.first()}
          intl={intl}
        />
      </div>
    );
  }
  return (
    <AltContainer
      stores={{
        microdepositStore: ValidateMicroDepositStore,
      }}
    >
      <ValidateMicroDeposits style={styles.singleColumn} intl={intl} />
    </AltContainer>
  );
}

function StripeDirectDeposit({ intl, customerStore }) {
  return (
    <FlexBox style={styles.root}>
      <div style={styles.column}>
        <h2>{t('.add_bank_account', intl, __filenamespace)}</h2>
        <BankAccountForm
          intl={intl}
          customerStore={customerStore}
          style={{ marginTop: 25 }}
        />
      </div>
      <div style={{ width: 90 }} />
      <CurrentAccounts
        customerStore={customerStore}
        style={styles.column}
        intl={intl}
      />
    </FlexBox>
  );
}

function DirectDepositTab({ customerStore, intl }) {
  const shouldSpin = customerStore.isLoadingCustomer;
  return (
    <SpinWhileLoading isLoading={shouldSpin} spinWhile="isLoading">
      {currentCustomer().paysafe_onboarding_step === 'complete' ||
      currentCustomer().use_paysafe ? (
        <PaysafeDirectDeposit customerStore={customerStore} intl={intl} />
      ) : (
        <StripeDirectDeposit customerStore={customerStore} intl={intl} />
      )}
    </SpinWhileLoading>
  );
}

export default injectIntl(DirectDepositTab);
