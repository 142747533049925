import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';

import CollapsedBenefitSelector from 'memberships/components/CollapsedBenefitSelector.jsx';
import EventTypeEditor from 'components/EventTypeEditor';
import SelectedEventType from 'shared/components/SelectedEventType.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  paper: {
    padding: '22px 16px 16px',
    position: 'relative',
  },
  clearBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  clearBtnIcon: {
    color: uhColors.lightGrey,
  },
};

const ExpandedSelector = injectIntl(
  ({ actions, eventTypes, membership, intl }) => {
    const selectedEventTypeIds = membership.membership_event_permissions.map(
      p => p.event_type_id
    );
    const selectedEventTypes = eventTypes.filter(et =>
      selectedEventTypeIds.includes(et.id)
    );
    const unselectedEventTypes = eventTypes.filter(
      et => !selectedEventTypeIds.includes(et.id)
    );

    return (
      <Paper style={styles.paper}>
        <IconButton
          onClick={() => actions.exclusiveEventTypesCleared()}
          style={styles.clearBtn}
        >
          <ClearIcon sx={styles.clearBtnIcon} />
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormattedMessage
              id={messageId('.instructions', __filenamespace)}
            />
          </Grid>
          <Grid item container md={6} xs={12}>
            <Grid item xs={12}>
              <EventTypeEditor
                eventTypes={unselectedEventTypes}
                onCreateOrUpdateSuccess={eventTypeId =>
                  actions.exclusiveEventTypeAdded(eventTypeId)
                }
                onEventTypeSelect={eventTypeId =>
                  actions.exclusiveEventTypeAdded(eventTypeId)
                }
                placeholder={t('.choose_event_types', intl, __filenamespace)}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="membership__selectedEventTypesList">
                {selectedEventTypes.map((eventType, index) => (
                  <SelectedEventType
                    // eslint-disable-next-line react/no-array-index-key
                    key={`selected-event-type-${index}`}
                    eventType={eventType}
                    onRemove={actions.exclusiveEventTypeRemoved}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);

function ExclusiveEventTypeSelector({
  actions,
  eventTypes,
  membership,
  expanded,
  intl,
}) {
  return (
    <div className="membership-discounts">
      <Grid container>
        <Grid item xs={12} className="membership-discounts__title-container">
          <Typography
            variant="fieldLabel"
            className="membership-discounts__title"
          >
            <FormattedMessage id={messageId('.header_text', __filenamespace)} />
          </Typography>
          <Typography variant="body2">
            {t('.explanatory_text', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
      {expanded ? (
        <ExpandedSelector
          actions={actions}
          eventTypes={eventTypes}
          membership={membership}
        />
      ) : (
        <CollapsedBenefitSelector
          buttonText={t(
            '.define_an_exclusive_event_type',
            intl,
            __filenamespace
          )}
          onExpand={() => actions.exclusiveEventTypesExpanded()}
        />
      )}
    </div>
  );
}

export default injectIntl(ExclusiveEventTypeSelector);
