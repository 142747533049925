import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function AgeRange({ min, max }) {
  if (min && max) {
    return (
      <FormattedMessage
        id={messageId('.age_n_to_m', __filenamespace)}
        values={{ n: min, m: max }}
      />
    );
  }
  if (min) {
    return (
      <FormattedMessage
        id={messageId('.age_n_and_over', __filenamespace)}
        values={{ n: min }}
      />
    );
  }
  if (max) {
    return (
      <FormattedMessage
        id={messageId('.age_n_and_under', __filenamespace)}
        values={{ n: max }}
      />
    );
  }
  return <FormattedMessage id={messageId('.all_ages', __filenamespace)} />;
}

function DOBRestriction({ dob }) {
  return (
    <FormattedMessage
      id={messageId('.dob_restriction', __filenamespace)}
      values={{
        dob,
      }}
    />
  );
}

function Restriction({ name, children, style }) {
  return (
    <div style={merge({ fontSize: 15 }, style)}>
      <FormattedMessage
        id={messageId('.restriction', __filenamespace)}
        values={{
          restrictionName: (
            <span style={{ color: uhColors.textGrey }}>{name}</span>
          ),
          restrictionValue: (
            <span style={{ fontWeight: 'bold' }}>{children}</span>
          ),
        }}
      />
    </div>
  );
}

function EventRestrictions({ event, style, intl }) {
  const schedule = event.schedules.first();

  const {
    gender_restriction: genderRestriction,
    min_age: minAge,
    max_age: maxAge,
  } = schedule;
  const dateOfBirth = schedule.get('date_of_birth');
  const hasDobRestriction = dateOfBirth?.hasDobRestriction();

  return (
    <FlexBox style={style}>
      <SectionHeader style={{ flexBasis: '50%' }}>
        <FormattedMessage id={messageId('.restrictions', __filenamespace)} />
      </SectionHeader>

      <FlexBox style={{ flexBasis: '60%', margin: '0 -5px' }}>
        {genderRestriction && (
          <Restriction
            name={t('.gender', intl, __filenamespace)}
            style={{ margin: '0 5px' }}
          >
            <span style={{ textTransform: 'capitalize' }}>
              {genderRestriction}
            </span>
          </Restriction>
        )}
        {hasDobRestriction && (
          <Restriction
            name={t('.age', intl, __filenamespace)}
            style={{ margin: '0 5px' }}
          >
            <DOBRestriction dob={event.getDobRestriction(dateOfBirth)} />
          </Restriction>
        )}
        {(minAge || maxAge) && (
          <Restriction
            name={t('.age', intl, __filenamespace)}
            style={{ margin: '0 5px' }}
          >
            <AgeRange min={minAge} max={maxAge} />
          </Restriction>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default injectIntl(EventRestrictions);
