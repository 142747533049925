class RefundModalActions {
  constructor() {
    this.generateActions(
      'closeModal',
      'listPayments',
      'listPaymentsSuccess',
      'listPaymentsError',
      'openModal',
      'refundAmountUpdated',
      'refundSubmit',
      'refundSuccess',
      'refundError',
      'waiveSuccess',
      'waiveError',
      'listProductsSuccess',
      'listProductsError',
      'fetchProductSuccess',
      'fetchProductError',
      'listBuyerPaymentMethods',
      'listBuyerPaymentMethodsSuccess',
      'listBuyerPaymentMethodsError'
    );
  }
}

export default alt.createActions(RefundModalActions);
