import moment from 'moment-timezone';
import { Set } from 'immutable';
import CreditPassEditingActions from 'credit_passes/actions/CreditPassEditingActions.js';
import CreditPass, {
  CREDIT_PASS_EXPIRATION_STRATEGIES,
} from 'shared/records/CreditPass.js';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import SpecificEventsActions from 'event_mgmt/index/actions/SpecificEventsActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { CREDIT_PASS_CREDIT_TYPES } from 'shared/records/CreditPassCredit.js';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

class CreditPassEditingStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      addCredit: CreditPassEditingActions.ADD_CREDIT,
      removeCredit: CreditPassEditingActions.REMOVE_CREDIT,
      updateCredit: CreditPassEditingActions.UPDATE_CREDIT,
      updateCreditType: CreditPassEditingActions.CREDIT_TYPE_CHANGED,

      addEventTypeCredit: CreditPassEditingActions.ADD_EVENT_TYPE_CREDIT,
      removeEventTypeCredit: CreditPassEditingActions.REMOVE_EVENT_TYPE_CREDIT,

      addEventCredit: CreditPassEditingActions.ADD_EVENT_CREDIT,
      removeEventCredit: CreditPassEditingActions.REMOVE_EVENT_CREDIT,

      updateCreditUnlimited: CreditPassEditingActions.TOGGLE_UNLIMITED_CREDITS,

      handleFetch: CreditPassEditingActions.FETCH,
      handleFetchSuccess: CreditPassEditingActions.FETCH_SUCCESS,
      handleFetchError: CreditPassEditingActions.FETCH_ERROR,

      handleSave: CreditPassEditingActions.SAVE,
      handleSaveSuccess: CreditPassEditingActions.SAVE_SUCCESS,
      handleSaveError: CreditPassEditingActions.SAVE_ERROR,

      handleToggleExpiration: CreditPassEditingActions.TOGGLE_EXPIRATION,
      handlePurchasable: CreditPassEditingActions.TOGGLE_PURCHASABLE,
      handlePOS: CreditPassEditingActions.TOGGLE_POS,
      handleUpdateField: CreditPassEditingActions.UPDATE_FIELD,
      reset: CreditPassEditingActions.RESET,
    });
  }

  handleFetch(id) {
    this.isLoading = true;
    uhApiClient.get({
      url: `credit_passes/${id}`,
      success: CreditPassEditingActions.fetchSuccess,
      error: CreditPassEditingActions.fetchError,
    });
  }

  handleFetchSuccess(data) {
    this.isLoading = false;
    this.record = new CreditPass(data);
    this.expirationEnabled =
      this.record.expiration_strategy !==
      CREDIT_PASS_EXPIRATION_STRATEGIES.never;
    this.updateSelectedEventTypes();
    this.setSelectedEventIds();
    this.setDisabledAddingNewCredit();
    this.setExistingAllEventsCredits();
  }

  handleFetchError(...args) {
    this.isLoading = false;
    this.notifyError('Error while fetching credit pass', args);
  }

  handleSave() {
    this.record = this.record.validate();
    if (this.record.isValid()) {
      this.isSaving = true;
      return this.record.id
        ? uhApiClient.put(this.apiArgs())
        : uhApiClient.post(this.apiArgs());
    }
    return true;
  }

  handleSaveSuccess(data) {
    // if creating
    if (!this.record.id) {
      window.history.pushState(
        null,
        document.title,
        window.location.pathname.replace('/new', `/${data.id}/edit`)
      );
    }

    this.record = new CreditPass(data);
    this.updateEventsStatus(true);
    this.isSaving = false;
  }

  handleSaveError(...args) {
    this.isSaving = false;
    this.notifyError('Error while saving credit pass', args);
  }

  handleToggleExpiration() {
    this.expirationEnabled = !this.expirationEnabled;
    if (!this.expirationEnabled)
      this.record = this.record.merge({
        expiration_strategy: CREDIT_PASS_EXPIRATION_STRATEGIES.never,
        expiration_days: null,
        expires_at: null,
      });
  }

  handlePurchasable() {
    this.record = this.record.merge({
      published: !this.record.published,
    });
  }

  handlePOS() {
    this.record = this.record.merge({
      hide_in_pos: !this.record.hide_in_pos,
    });
  }

  handleUpdateField([field, value]) {
    this.record = this.record.set(field, value);

    if (field === 'expiration_strategy') {
      this.updateExpiration(value);
    }

    if (field === 'expires_at') {
      this.updateExpirationDatetime(value);
    }
  }

  setDisabledAddingNewCredit() {
    this.disabledAddingNewCredit = this.record.credit_pass_credits.some(
      c =>
        (c.all_events && c.unlimited) ||
        (c.type === CREDIT_PASS_CREDIT_TYPES.event_types &&
          !c.event_type_ids.size) ||
        (c.type === CREDIT_PASS_CREDIT_TYPES.event_specific &&
          !c.event_ids.size)
    );
  }

  setExistingAllEventsCredits() {
    this.existingAllEventsCredits = this.record.credit_pass_credits.some(
      c => c.all_events
    );
  }

  updateSelectedEventTypes() {
    this.waitFor(EventTypeListingStore);
    const { eventTypes } = EventTypeListingStore.getState();
    this.selectedEventTypeIds = this.record.credit_pass_credits.reduce(
      (set, credit) => set.union(credit.event_type_ids),
      Set()
    );

    this.unselectedEventTypes = eventTypes.filter(
      t => !this.selectedEventTypeIds.has(t.id)
    );
  }

  addCredit() {
    this.setExistingAllEventsCredits();
    const isAllowedAllEvent =
      !this.existingAllEventsCredits &&
      !this.selectedEventTypeIds.size &&
      !this.selectedEventIds.size;
    this.record = this.record.addCredit({
      type: isAllowedAllEvent
        ? CREDIT_PASS_CREDIT_TYPES.all_events
        : CREDIT_PASS_CREDIT_TYPES.event_types,
      all_events: isAllowedAllEvent,
    });
    this.updateCreditHelpers();
  }

  removeCredit({ index }) {
    this.record = this.record.removeCredit({ index });
    this.updateCreditHelpers();
  }

  updateCredit([prop, discountIndex, value]) {
    this.record = this.record.setIn(
      ['credit_pass_credits', discountIndex, prop],
      value
    );
  }

  updateCreditUnlimited([discountIndex, value]) {
    if (value) {
      this.record = this.record.mergeIn(
        ['credit_pass_credits', discountIndex],
        { unlimited: value, quantity: 0 }
      );
    } else {
      this.record = this.record.mergeIn(
        ['credit_pass_credits', discountIndex],
        { unlimited: value, quantity: 1 }
      );
    }
    this.setDisabledAddingNewCredit();
  }

  updateRecordForAllEventSelected(discountIndex) {
    this.record = this.record.mergeIn(['credit_pass_credits', discountIndex], {
      type: CREDIT_PASS_CREDIT_TYPES.all_events,
      all_events: true,
      event_type_ids: Set(),
      event_ids: Set(),
    });
    this.updateCreditHelpers();
  }

  updateRecordForEventTypesSelected(discountIndex) {
    this.record = this.record.mergeIn(['credit_pass_credits', discountIndex], {
      type: CREDIT_PASS_CREDIT_TYPES.event_types,
      all_events: false,
      event_ids: Set(),
    });
    this.updateCreditHelpers();
  }

  updateRecordForEventSpecificSelected(discountIndex) {
    this.record = this.record.mergeIn(['credit_pass_credits', discountIndex], {
      type: CREDIT_PASS_CREDIT_TYPES.event_specific,
      all_events: false,
      event_type_ids: Set(),
    });
    this.updateEventsStatus();
  }

  updateCreditType([discountIndex, value]) {
    switch (value) {
      case CREDIT_PASS_CREDIT_TYPES.all_events: {
        this.updateRecordForAllEventSelected(discountIndex);
        break;
      }
      case CREDIT_PASS_CREDIT_TYPES.event_types: {
        this.updateRecordForEventTypesSelected(discountIndex);
        break;
      }
      case CREDIT_PASS_CREDIT_TYPES.event_specific: {
        this.updateRecordForEventSpecificSelected(discountIndex);
        break;
      }
      default:
        break;
    }
  }

  addEventTypeCredit([discountIndex, eventTypeId]) {
    this.record = this.record.updateIn(
      ['credit_pass_credits', discountIndex, 'event_type_ids'],
      (set = Set()) => set.add(eventTypeId)
    );
    this.updateSelectedEventTypes();
    this.setDisabledAddingNewCredit();
  }

  removeEventTypeCredit([discountIndex, eventTypeId]) {
    this.record = this.record.updateIn(
      ['credit_pass_credits', discountIndex, 'event_type_ids'],
      (set = Set()) => set.remove(eventTypeId)
    );
    this.updateSelectedEventTypes();
    this.setDisabledAddingNewCredit();
  }

  addEventCredit([discountIndex, eventId]) {
    this.record = this.record.updateIn(
      ['credit_pass_credits', discountIndex, 'event_ids'],
      (set = Set()) => set.add(eventId)
    );
    this.setDisabledAddingNewCredit();
    this.updateEventsStatus();
  }

  updateExpiration(value) {
    switch (value) {
      case CREDIT_PASS_EXPIRATION_STRATEGIES.date:
        this.record = this.record.set('expiration_days', null);
        break;
      case CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_purchase:
      case CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_first_use:
        this.record = this.record.merge({
          expiration_days: 0,
          expires_at: null,
        });
        break;
      default:
        this.record = this.record.merge({
          expiration_days: null,
          expires_at: null,
        });
        break;
    }
  }

  updateExpirationDatetime(value) {
    this.record = this.record.set(
      'expires_at',
      moment(value).endOf('day').tz(customerTZ(), true)
    );
  }

  removeEventCredit([discountIndex, eventId]) {
    this.record = this.record.updateIn(
      ['credit_pass_credits', discountIndex, 'event_ids'],
      (set = Set()) => set.remove(eventId)
    );
    this.setDisabledAddingNewCredit();
    this.updateEventsStatus();
  }

  setSelectedEventIds() {
    this.selectedEventIds = this.record.credit_pass_credits.reduce(
      (set, credit) => set.union(credit.event_ids),
      Set()
    );
  }

  updateCreditHelpers() {
    this.setSelectedEventIds();
    this.updateSelectedEventTypes();
    this.setExistingAllEventsCredits();
    this.setDisabledAddingNewCredit();
  }

  updateEventsStatus(initial = false) {
    this.updateCreditHelpers();
    this.fetchEvents(initial);
  }

  fetchEvents(initial) {
    EventActions.list.defer({
      fields: ['participant_count'],
      exceptIds: this.selectedEventIds.toArray(),
      exceptTypes: this.selectedEventTypeIds.toArray(),
    });
    if (initial && this.selectedEventIds.size) {
      SpecificEventsActions.list.defer({
        fields: ['participant_count'],
        ids: this.selectedEventIds.toArray(),
      });
    }
  }

  reset() {
    this.record = new CreditPass();
    this.expirationEnabled = false;
    this.isLoading = false;
    this.isSaving = false;
    this.selectedEventTypeIds = Set();
    this.selectedEventIds = Set();
    this.disabledAddingNewCredit = false;
    this.existingAllEventsCredits = false;
  }

  apiArgs() {
    return {
      url: this.url(),
      data: JSON.stringify({
        attributes: this.record.toServer(),
      }),
      success: CreditPassEditingActions.saveSuccess,
      error: CreditPassEditingActions.saveError,
    };
  }

  url(id = this.record.id) {
    return id ? `credit_passes/${id}` : 'credit_passes';
  }
}

export default alt.createStore(
  CreditPassEditingStore,
  'CreditPassEditingStore'
);
