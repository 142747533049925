import TaxRateEditingActions from 'shared/actions/tax_rates/TaxRateEditingActions';
import TaxRateEditingStoreInterface from 'shared/stores/tax_rates/TaxRateEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  TaxRateEditingStoreInterface,
  'TaxRateEditingStore',
  {
    actions: TaxRateEditingActions,
    successMessage: new TranslatableMessage({
      id: '.updated',
      filename: __filenamespace,
    }),
  }
);
