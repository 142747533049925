import { Map, OrderedSet } from 'immutable';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { BalancesSource, ClientSource } from 'sources';

import BalanceListDrawerActions from './Actions';

class BalanceListDrawerStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      fetchStatistics: BalanceListDrawerActions.fetchStatistics,
      fetchStatisticsSuccess: BalanceListDrawerActions.fetchStatisticsSuccess,
      fetchStatisticsError: BalanceListDrawerActions.fetchStatisticsError,
      listBalances: BalanceListDrawerActions.listBalances,
      listBalancesSuccess: BalanceListDrawerActions.listBalancesSuccess,
      listBalancesError: BalanceListDrawerActions.listBalancesError,
      openDrawer: BalanceListDrawerActions.openDrawer,
      closeDrawer: BalanceListDrawerActions.closeDrawer,
      fetchClientResponse: BalanceListDrawerActions.fetchClientResponse,
    });
  }

  reset() {
    this.clientId = 0;
    this.isOpen = false;
    this.isLoading = false;
    this.isLoadingStatistics = false;
    this.page = 1;
    this.perPage = 5;
    this.totalCount = 0;
    this.balanceIds = new OrderedSet();
    this.params = {};
    this.statistics = new Map();
  }

  // eslint-disable-next-line class-methods-use-this
  getParams({
    clientId,
    membershipSubscriberId,
    creditPassGranteeIds,
    creditPassIds,
    customerIds,
    debitEntryIds,
    eventIds,
    locationIds,
    membershipIds,
    productId,
    productType,
    balanceRemainingMax,
  }) {
    const params = {};

    // Weird logic to let passing null yield value not getting set, but set value otherwise.
    if (balanceRemainingMax !== undefined && balanceRemainingMax) {
      params.balance_remaining_max = balanceRemainingMax;
    } else if (balanceRemainingMax === undefined) {
      // balance_remaining_max set to 0.01$ to get rid items with balanceRemaining === 0
      params.balance_remaining_max = -1;
    }

    if (productId) {
      params.product = productId;
    }
    if (membershipIds) {
      params.membership_ids = membershipIds;
    }
    if (creditPassIds) {
      params.credit_pass_ids = creditPassIds;
    }
    if (eventIds) {
      params.event_ids = eventIds;
    }
    if (productType) {
      params.product_type = productType;
    }
    if (locationIds && locationIds.length > 0) {
      params.location_ids = locationIds;
    }
    if (clientId) {
      this.clientId = clientId;
      params.client_ids = [clientId];
    }
    if (membershipSubscriberId) {
      params.membership_subscriber_ids = membershipSubscriberId;
    }
    if (creditPassGranteeIds) {
      params.credit_pass_grantee_ids = creditPassGranteeIds;
    }
    if (customerIds && customerIds.length > 0) {
      params.customer_ids = customerIds;
    }
    if (debitEntryIds && debitEntryIds.length > 0) {
      params.debit_entry_ids = debitEntryIds;
    }

    return params;
  }

  openDrawer(params) {
    this.isOpen = true;
    this.params = this.getParams(params);
    this.listBalances();
    this.fetchStatistics();
    this.fetchDependencies();
  }

  closeDrawer() {
    this.reset();
  }

  listBalances() {
    this.isLoading = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      ...this.params,
    };

    BalancesSource.list({
      params,
      success: BalanceListDrawerActions.listBalancesSuccess,
      error: BalanceListDrawerActions.listBalancesError,
    });
  }

  listBalancesSuccess({ records, page, perPage, totalCount }) {
    const newIds = records.map(el => el.compoundId).toOrderedSet();

    this.balanceIds = page === 1 ? newIds : this.balanceIds.union(newIds);

    this.page = page + 1;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.isLoading = false;
  }

  listBalancesError() {
    this.isLoading = false;
  }

  fetchStatistics() {
    this.isLoadingStatistics = true;

    uhApiClient.get({
      url: 'ledger/balances_statistics',
      data: this.params,
      success: BalanceListDrawerActions.fetchStatisticsSuccess,
      error: BalanceListDrawerActions.fetchStatisticsError,
    });
  }

  fetchStatisticsSuccess(data) {
    this.statistics = new Map(data);
    this.isLoadingStatistics = false;
  }

  fetchStatisticsError(...args) {
    this.isLoadingStatistics = false;
    this.notifyError('error fetching order statistics', args);
  }

  fetchDependencies() {
    if (this.clientId > 0) {
      this.isLoadingClient = true;
      ClientSource.fetch({
        id: this.clientId,
        params: {
          fields: [
            'total_account_credit',
            'agreement_details',
            'total_credits',
          ],
        },
        success: BalanceListDrawerActions.fetchClientResponse,
        error: BalanceListDrawerActions.fetchClientResponse,
      });
    }
  }

  fetchClientResponse() {
    this.isLoadingClient = false;
  }
}

export default alt.createStore(
  BalanceListDrawerStore,
  'BalanceListDrawerStore'
);
