class EditDateTimeActions {
  constructor() {
    this.generateActions(
      'cancelChanges',
      'checkConfirmation',
      'mounted',
      'saveChanges',
      'sessionUpdateError',
      'sessionUpdateSuccess',
      'update',
      'setSchedulingUnit',
      'setCancellationUnit',
      'setSchedulingTimeFrameUnit',
      'handleLocationChange',
      'scheduleUpdateError',
      'scheduleUpdateSuccess'
    );
  }
}

export default alt.createActions(EditDateTimeActions);
