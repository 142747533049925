class DeviceListActions {
  constructor() {
    this.generateActions(
      'mounted',
      'listCardReaders',
      'listCardReadersSuccess',
      'listCardReadersError',
      'updateFilter',
      'selectPage',
      'openEditModal',
      'closeEditModal',
      'toggleEnabled',
      'updateCardReaderSuccess',
      'updateCardReaderError'
    );
  }
}

export default alt.createActions(DeviceListActions);
