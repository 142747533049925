import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@upperhand/playmaker';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import uhTheme from '../../_uh_theme.jsx';

const styles = {
  info: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
  },
  infoText: {
    fontWeight: 'bold',
    padding: '0 4px',
  },
  pageButton: {
    minWidth: '4rem',
  },
  currentBtn: {
    position: 'relative',
  },
  currentBtnAfter: {
    position: 'absolute',
    bottom: 0,
    display: 'block',
    backgroundColor: uhTheme.palette.primary.main,
    width: '50%',
    height: '2px',
  },
};

class Paginator extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { currentPage, pageLimit, perPage, totalCount } = this.props;
    return (
      currentPage !== nextProps.currentPage ||
      pageLimit !== nextProps.pageLimit ||
      perPage !== nextProps.perPage ||
      totalCount !== nextProps.totalCount
    );
  }

  handleFirstPageSelect = () => {
    const { onPageSelect, perPage } = this.props;
    onPageSelect(1, perPage);
  };

  handleLastPageSelect = () => {
    const { onPageSelect, perPage } = this.props;
    onPageSelect(this.totalPages(), perPage);
  };

  handlePreviousPageSelect = () => {
    const { onPageSelect, currentPage, perPage } = this.props;
    onPageSelect(Math.max(currentPage - 1, 1), perPage);
  };

  handleNextPageSelect = () => {
    const { onPageSelect, currentPage, perPage } = this.props;

    onPageSelect(Math.min(currentPage + 1, this.totalPages()), perPage);
  };

  createPageButton(pageNumber) {
    const { currentPage, onPageSelect, perPage } = this.props;
    const callback =
      pageNumber === currentPage
        ? () => {}
        : () => onPageSelect(pageNumber, perPage);

    return (
      <Button
        key={`page-${pageNumber}`}
        primary={pageNumber === currentPage}
        sx={
          pageNumber === currentPage
            ? { ...styles.currentBtn, ...styles.pageButton }
            : styles.pageButton
        }
        onClick={callback}
      >
        {pageNumber === currentPage && <div style={styles.currentBtnAfter} />}
        {pageNumber}
      </Button>
    );
  }

  pageButtons() {
    const { currentPage, pageLimit } = this.props;

    const totalPages = this.totalPages();

    // Calculate the lowest page visible based on a sliding window of pageLimit pages visible
    // at one time and at most pageLimit / 2 pages before the current selected page.
    const firstPage = Math.max(
      1,
      // eslint-disable-next-line no-bitwise
      Math.min(currentPage - ~~(pageLimit / 2), totalPages + 1 - pageLimit)
    );

    const lastPage = Math.min(totalPages, pageLimit);

    return [...Array(lastPage).keys()].map(i =>
      this.createPageButton(i + firstPage)
    );
  }

  canNavigateBackward() {
    const { currentPage } = this.props;
    return currentPage > 1;
  }

  canNavigateForward() {
    const { currentPage } = this.props;
    return currentPage < this.totalPages();
  }

  totalPages() {
    const { totalCount, perPage } = this.props;

    // eslint-disable-next-line no-bitwise
    return perPage === 0 ? 0 : ~~((totalCount - 1) / perPage) + 1;
  }

  render() {
    const { className, compact, totalCount, style, pageLimit, showInfo } =
      this.props;
    const pageCount = this.totalPages();

    if (pageCount <= 1) {
      return null;
    }

    const { currentPage, perPage } = this.props;

    if (compact) {
      const firstItemIndex = (currentPage - 1) * perPage + 1;
      const lastItemIndex = Math.min(currentPage * perPage, totalCount);

      return (
        <FlexBoxJustify style={merge({ alignItems: 'center' }, style)}>
          <Button
            color="dark"
            className="prev-page"
            disabled={!this.canNavigateBackward()}
            startIcon={<ChevronLeft />}
            onClick={this.handlePreviousPageSelect}
            style={styles.pageButton}
          />
          <span>
            {firstItemIndex} - {lastItemIndex}
          </span>
          <Button
            color="dark"
            className="next-page"
            disabled={!this.canNavigateForward()}
            startIcon={<ChevronRight />}
            onClick={this.handleNextPageSelect}
            style={styles.pageButton}
          />
        </FlexBoxJustify>
      );
    }

    const fromPage = (currentPage - 1) * perPage + 1;
    const toPage = (currentPage - 1) * perPage + perPage;

    return (
      <div className={className} style={style}>
        {showInfo && (
          <div style={styles.info}>
            <Typography variant="body2">
              <span style={styles.infoText}>
                {fromPage}&nbsp;-&nbsp;
                {toPage > totalCount ? totalCount : toPage}
              </span>
              of
              <span style={styles.infoText}>{totalCount}</span>
            </Typography>
          </div>
        )}
        {pageCount > pageLimit && (
          <Button
            color="dark"
            className="first-page"
            disabled={!this.canNavigateBackward()}
            onClick={this.handleFirstPageSelect}
            style={styles.pageButton}
          >
            <FirstPage />
          </Button>
        )}

        <Button
          color="dark"
          className="prev-page"
          onClick={this.handlePreviousPageSelect}
          style={styles.pageButton}
          disabled={!this.canNavigateBackward()}
        >
          <ChevronLeft />
        </Button>

        {this.pageButtons(styles)}

        <Button
          color="dark"
          className="next-page"
          onClick={this.handleNextPageSelect}
          style={styles.pageButton}
          disabled={!this.canNavigateForward()}
        >
          <ChevronRight />
        </Button>

        {pageCount > pageLimit && (
          <Button
            color="dark"
            className="last-page"
            disabled={!this.canNavigateForward()}
            onClick={this.handleLastPageSelect}
            style={styles.pageButton}
          >
            <LastPage />
          </Button>
        )}
      </div>
    );
  }
}

Paginator.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
  showInfo: PropTypes.bool,
  /**
   * The currently selected page. Counting starts from 1.
   */
  currentPage: PropTypes.number.isRequired,

  /**
   * Callback function fired when a new page is selected.
   *
   * @param {integer} page    The selected page number.
   * @param {integer} perPage The number of records per page.
   */
  onPageSelect: PropTypes.func,

  /**
   * The maximum number of pages displayed in the paginator at once. Default 6.
   */
  pageLimit: PropTypes.number,

  /**
   * The number of records per page.
   */
  perPage: PropTypes.number.isRequired,

  /**
   * The total number of records.
   */
  totalCount: PropTypes.number.isRequired,
};

Paginator.defaultProps = {
  showInfo: false,
  className: '',
  compact: false,
  pageLimit: 6,
  onPageSelect: () => {},
};

export default Paginator;
