import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';
import { Grid } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import StatisticItem from './StatisticItem.jsx';

function OrderStatistics({ intl, statistics }) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <Grid item md={4} xs={6}>
        <StatisticItem
          title={t('.total', intl, __filenamespace)}
          tooltip={t('.total_tooltip', intl, __filenamespace)}
          value={intl.formatNumber(statistics.get('total') / 100, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </Grid>
      <Grid item md={4} xs={6}>
        <StatisticItem
          title={t('.transactions', intl, __filenamespace)}
          tooltip={t('.transactions_tooltip', intl, __filenamespace)}
          value={statistics.get('transactions')}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <StatisticItem
          title={t('.unique_buyers', intl, __filenamespace)}
          tooltip={t('.unique_buyers_tooltip', intl, __filenamespace)}
          value={statistics.get('unique_buyers')}
        />
      </Grid>
    </Grid>
  );
}

OrderStatistics.propTypes = {
  intl: PropTypes.object.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
};

export default compose(memo, injectIntl)(OrderStatistics);
