import React from 'react';
import moment from 'moment-timezone';
import { Box } from '@mui/material';

import useUpdateTime from 'shared/utils/hooks/useUpdateTime';
import {
  formattedHourString,
  getIndicatorLineHeight,
} from 'calendar/utils/CalendarUtils.jsx';
import {
  HoursInDay,
  timeRangeFromHour,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  TimeBar: {
    zIndex: 2,
    position: 'sticky',
    top: 0,
    left: 0,
    width: '50px',
    minWidth: '50px',
    paddingTop: '42px',
    backgroundColor: '#fff',
    borderRight: '1px solid #e3e3e3',
  },
  Hour: {
    height: '192px',
    borderBottom: '1px solid #e3e3e3',
    color: uhColors.iconGrey,
    position: 'relative',
  },
  timeBadge: {
    backgroundColor: 'var(--color-secondary)',
    color: 'var(--color-white)',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    justifyContent: 'center',
    borderRadius: '30px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: '10px',
    width: '50px',
  },
  startHour: {
    paddingTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
};

function TimeBadge({ isCurrentHour, currentTime, lineHeight }) {
  if (!isCurrentHour) return <Box />;
  return (
    <Box
      sx={{
        ...styles.timeBadge,
        marginTop: `${lineHeight - 10}px`,
      }}
    >
      {`${currentTime}`}
    </Box>
  );
}

function Hour({ hour, lineHeight, currentTime }) {
  const currentHour = moment().hour();
  const isCurrentHour = hour === currentHour;
  const hourRange = timeRangeFromHour(hour);

  return (
    <div style={styles.Hour}>
      <Box sx={styles.startHour}>
        {formattedHourString(hourRange.start, {
          truncateMeridiem: false,
          format: 'ha',
        })}
      </Box>
      <TimeBadge
        currentTime={currentTime}
        isCurrentHour={isCurrentHour}
        lineHeight={lineHeight}
      />
    </div>
  );
}

function TimeBar() {
  const currentTime = useUpdateTime();
  const lineHeight = getIndicatorLineHeight(currentTime);
  return (
    <div style={styles.TimeBar}>
      <div>
        {HoursInDay.map(hour => (
          <Hour
            key={hour}
            hour={hour}
            lineHeight={lineHeight}
            currentTime={currentTime}
          />
        ))}
      </div>
    </div>
  );
}

export default TimeBar;
