import EventMarketingActions from 'event_mgmt/display/actions/EventMarketingActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class EventMarketingStore extends UpperHandStore {
  constructor() {
    super();

    this.formOpen = false;

    this.bindListeners({
      openForm: EventMarketingActions.FORM_OPENED,
      closeForm: EventMarketingActions.FORM_CLOSED,
    });
  }

  openForm() {
    this.formOpen = true;
  }

  closeForm() {
    this.formOpen = false;
  }
}

export default EventMarketingStore;
