import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import uhTheme from '_uh_theme.jsx';

function ContactGroup({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 50 40" {...props}>
      <g
        id="Contact-Group"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Contact-Group-Mobile---Group-Dropdown"
          transform="translate(-632.000000, -275.000000)"
          fillRule="nonzero"
          fill={color}
        >
          <g id="Contact-Group-Nav" transform="translate(0.000000, 243.000000)">
            <path
              d="M677,37 L657,37 L652,32 L637,32 C634.25,32 632.025,34.25 632.025,37 L632,67 C632,69.75 634.25,72 637,72 L677,72 C679.75,72 682,69.75 682,67 L682,42 C682,39.25 679.75,37 677,37 Z M664.5,44.5 C667.25,44.5 669.5,46.75 669.5,49.5 C669.5,52.25 667.25,54.5 664.5,54.5 C661.75,54.5 659.5,52.25 659.5,49.5 C659.5,46.75 661.75,44.5 664.5,44.5 Z M674.5,64.5 L654.5,64.5 L654.5,62 C654.5,58.675 661.175,57 664.5,57 C667.825,57 674.5,58.675 674.5,62 L674.5,64.5 Z"
              id="Shape-Copy"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

ContactGroup.defaultProps = {
  color: uhTheme.palette.lightGrey,
};

export default ContactGroup;
