import { Record } from 'immutable';

export const COUPON_DISCOUNTABLE_TYPES = {
  all: 'all',
  specific: 'specificEvent',
};

export const COUPON_DISCOUNTABLE = {
  Event: 'Event',
  Team: 'Team',
  Membership: 'Membership',
  CreditPass: 'CreditPass',
  Variant: 'Variant',
};

class DiscountableItem extends Record({
  id: null,
  item_id: null,
  item_type: null,
}) {
  isCreditPass() {
    return this.item_type === COUPON_DISCOUNTABLE.CreditPass;
  }

  isEvent() {
    return this.item_type === COUPON_DISCOUNTABLE.Event;
  }

  isTeam() {
    return this.item_type === COUPON_DISCOUNTABLE.Team;
  }

  isMembership() {
    return this.item_type === COUPON_DISCOUNTABLE.Membership;
  }

  isVariant() {
    return this.item_type === COUPON_DISCOUNTABLE.Variant;
  }
}

export default DiscountableItem;
