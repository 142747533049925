import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { CardReaderSource } from 'sources';

import DeviceListActions from 'containers/deviceList/Actions';
import CardReaderEditActions from './Actions';

class CardReaderEditStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      fetchCardReaderSuccess: CardReaderEditActions.fetchCardReaderSuccess,
      fetchCardReaderError: CardReaderEditActions.fetchCardReaderError,
      // loadContent and reset should likely eventually have an array of actions
      // to listen to.
      // The below listens to DeviceListActions are just a start.
      loadContent: DeviceListActions.openEditModal,
      reset: DeviceListActions.closeEditModal,
      submitEdit: CardReaderEditActions.submitEdit,
      updateCardReaderSuccess: CardReaderEditActions.updateCardReaderSuccess,
      updateCardReaderError: CardReaderEditActions.updateCardReaderError,
      setNickname: CardReaderEditActions.setNickname,
    });
  }

  reset() {
    this.cardReaderId = null;
    this.nickname = null;
  }

  fetchCardReaderSuccess(cardReader) {
    this.nickname = cardReader.get('nickname');
  }

  fetchCardReaderError(...args) {
    this.notifyError('error fetching card reader', args);
  }

  loadContent(id) {
    this.cardReaderId = id;

    CardReaderSource.fetch({
      id,
      success: CardReaderEditActions.fetchCardReaderSuccess,
      error: CardReaderEditActions.fetchCardReaderError,
    });
  }

  createParams() {
    return {
      nickname: this.nickname,
    };
  }

  submitEdit() {
    CardReaderSource.put({
      id: this.cardReaderId,
      params: this.createParams(),
      success: CardReaderEditActions.updateCardReaderSuccess,
      error: CardReaderEditActions.updateCardReaderError,
    });
  }

  updateCardReaderSuccess() {
    this.reset();
  }

  updateCardReaderError(...args) {
    this.notifyError('error updating card reader', args);
    this.reset();
  }

  setNickname(nickname) {
    this.nickname = nickname;
  }
}

export default alt.createStore(CardReaderEditStore, 'CardReaderEditStore');
