import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '@upperhand/playmaker';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class SpinWhileLoading extends React.PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    children: PropTypes.any,
    absolute: PropTypes.bool,
    className: PropTypes.string,
    renderHiddenContent: PropTypes.bool,
    contentContainerStyle: PropTypes.object,
    outerContainerStyle: PropTypes.object,
    spinnerContainerStyle: PropTypes.object,
    spinWhile: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    children: null,
    absolute: true,
    className: '',
    renderHiddenContent: false,
    contentContainerStyle: {},
    outerContainerStyle: {},
    spinnerContainerStyle: {},
    spinWhile: 'isLoading',
  };

  // eslint-disable-next-line class-methods-use-this
  filterProps = (props, filters = []) =>
    filters.reduce(
      (accumulator, value) => {
        const { [value]: _, ...newProps } = accumulator;

        return newProps;
      },
      { ...props }
    );

  shouldSpin = () => {
    const { spinWhile } = this.props;
    const propPath = spinWhile.constructor === Array ? spinWhile : [spinWhile];
    let shouldSpin = this.props;

    for (let i = 0; i < propPath.length; i += 1) {
      shouldSpin = shouldSpin[propPath[i]];
    }

    return shouldSpin;
  };

  render() {
    const {
      children,
      renderHiddenContent,
      contentContainerStyle,
      outerContainerStyle,
      spinnerContainerStyle,
      spinnerWrapperStyle,
      absolute,
      className,
      ...props
    } = this.props;

    const shouldSpin = this.shouldSpin();
    const childrenWithProps = React.Children.map(children, child =>
      child
        ? React.cloneElement(
            child,
            this.filterProps(props, ['isLoading', 'spinWhile'])
          )
        : null
    );
    const renderContent = (shouldSpin && renderHiddenContent) || !shouldSpin;

    const selfContentContainerStyle = shouldSpin
      ? { display: 'none' }
      : { display: 'inherit' };
    const selfSpinnerContainerStyle = shouldSpin
      ? { display: 'inherit' }
      : { display: 'none' };

    return (
      <div className={className} style={outerContainerStyle}>
        <div style={merge(selfSpinnerContainerStyle, spinnerContainerStyle)}>
          <div
            style={merge(
              {
                textAlign: 'center',
                paddingTop: '10%',
                width: '100%',
                position: absolute ? 'absolute' : 'static',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              },
              spinnerWrapperStyle
            )}
          >
            <Spinner />
            <p style={{ paddingTop: '1em' }}>
              <FormattedMessage id={messageId('.loading', __filenamespace)} />
            </p>
          </div>
        </div>
        {renderContent && (
          <div style={merge(selfContentContainerStyle, contentContainerStyle)}>
            {childrenWithProps}
          </div>
        )}
      </div>
    );
  }
}

export default SpinWhileLoading;
