class ContactsStaffActions {
  constructor() {
    this.generateActions(
      'listStaffAvailabilityError',
      'listStaffAvailabilitySuccess',
      'fetchStaffAvailabilityError',
      'fetchStaffAvailabilitySuccess',
      'mounted',
      'pageSelected',
      'searchUpdated',
      'staffListError',
      'staffListSuccess',
      'statusesUpdated',
      'updateContact',
      'revokeAccess',
      'revokeAccessError',
      'revokeAccessSuccess',
      'sessionsListError',
      'sessionsListSuccess',
      'openRevokeAccessModal',
      'closeRevokeAccessModal',
      'staffTypesUpdated'
    );
  }
}

export default alt.createActions(ContactsStaffActions);
