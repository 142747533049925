import { Record } from 'immutable';

class RetailCategory extends Record({
  id: null,
  name: '',
  created_at: null,
  updated_at: null,
}) {}

export default RetailCategory;
