import * as React from 'react';
import { Route } from 'react-router-dom';

import AccountSetupPaysafe from 'onboarding/components/AccountSetupPaysafe/AccountSetupPaysafe.jsx';
import Calendar from 'calendar/components/Calendar.jsx';
import CartIndex from 'cart/index/components/CartIndex.jsx';
import ContactsPage from 'containers/contacts/contactsPage/ContactsPage.jsx';
import ClientProfile from 'containers/clientProfile/ClientProfile.jsx';
import CustomerDashboard from 'dashboards/components/CustomerDashboard.jsx';
import ClassesListPage from 'containers/classes/classesList/ClassesList.jsx';
import CustomerSettings from 'customers/settings/components/Settings.jsx';
import DeviceList from 'containers/deviceList/DeviceList.jsx';
import ResourceListing from 'resources/components/ResourceListing.jsx';
import UserSettings from 'users/settings/components/Settings.jsx';
import CreateUser from 'containers/accounts/createUser/CreateUser.jsx';
import Marketing from 'containers/marketing/index.jsx';
import Terms from 'customers/Terms.jsx';
import NotFound from 'not_found/NotFound.jsx';
import Page from 'routes/Page.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';
import { EventPage, TeamPage } from 'routes/helpers/CustomersPageHelpers.jsx';
import { isARtheme } from 'shared/utils/ThemeUtils';

import {
  isAdmin,
  isStaff,
  isStaffAdmin,
  isManager,
  isClient,
  isInstructor,
  isCoach,
  isUnauthorized,
} from 'routes/helpers/routePermissions';

import eventsRoutes from 'routes/eventsRoutes.jsx';
import reportsRoutes from 'routes/reportsRoutes.jsx';
import membershipsRoutes from 'routes/membershipsRoutes.jsx';
import creditPassesRoutes from 'routes/creditPassesRoutes.jsx';
import retailRoutes from 'routes/retailRoutes.jsx';
import couponsRoutes from 'routes/couponsRoutes.jsx';
import devRoutes from 'routes/devRoutes.jsx';
import teamsRoutes from 'routes/teamsRoutes.jsx';
import AddOnsTabs from 'customers/AddOns/AddOnsTabs.jsx';
import ClientPortal from 'client_portal/components/ClientPortal.jsx';
import MembershipAgreement from 'customers/MembershipAgreement.jsx';
import MembershipViewClient from 'memberships/components/MembershipViewClient/_MembershipViewClient.jsx';

const customersRoutes = () => (
  <>
    {/* Dashboard */}
    <Route
      path=":customer_id"
      element={
        <AuthorizedPageWrapper
          redirectOnLogin
          allowedRoles={[isAdmin, isStaffAdmin]}
        >
          <CustomerDashboard />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/dashboard"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <CustomerDashboard />
        </AuthorizedPageWrapper>
      }
    />
    {/* Onboarding */}
    <Route
      path=":customer_id/complete_account"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <AccountSetupPaysafe />
        </AuthorizedPageWrapper>
      }
    />

    {/* Customer My Profile */}
    <Route
      path=":customer_id/my_profile"
      element={
        <AuthorizedPageWrapper allowedRoles={[isClient, isStaff]}>
          <EventPage />
        </AuthorizedPageWrapper>
      }
    />

    {/* Classes */}
    <Route
      path=":customer_id/classes"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff]}>
          <ClassesListPage />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/classes/:classSlug"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff]}>
          <Page>
            <ClassesListPage />
          </Page>
        </AuthorizedPageWrapper>
      }
    />

    {/* Calendar */}
    <Route
      path=":customer_id/calendar"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff, isCoach, isInstructor]}>
          <Calendar />
        </AuthorizedPageWrapper>
      }
    />

    {/* Contacts */}
    <Route
      path=":customer_id/contacts"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff, isCoach, isInstructor]}>
          <ContactsPage />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/clients/:clientId"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaff, isCoach]}
        >
          <Page>
            <ClientProfile />
          </Page>
        </AuthorizedPageWrapper>
      }
    />

    {/* Resources */}
    <Route
      path=":customer_id/resources"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <ResourceListing />
        </AuthorizedPageWrapper>
      }
    />

    {/* Resources */}
    <Route
      path=":customer_id/cart"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isClient, isManager]}
        >
          <CartIndex />
        </AuthorizedPageWrapper>
      }
    />

    {/* Devices */}
    <Route
      path=":customer_id/devices"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin, isStaff]}>
          <DeviceList />
        </AuthorizedPageWrapper>
      }
    />

    {/* Settings */}
    <Route
      path=":customer_id/settings"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <CustomerSettings />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/users/settings"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isStaff, isClient, isInstructor, isCoach]}
        >
          <UserSettings />
        </AuthorizedPageWrapper>
      }
    />
    {/* AddOns */}
    <Route
      path=":customer_id/add-ons"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <AddOnsTabs />
        </AuthorizedPageWrapper>
      }
    />
    {/* Create new user */}
    <Route
      path=":customer_id/create_user"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[
            isStaff,
            isClient,
            isInstructor,
            isCoach,
            isUnauthorized,
          ]}
        >
          <CreateUser />
        </AuthorizedPageWrapper>
      }
    />

    {/* Terms */}
    <Route
      path=":customer_id/terms"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaff, isClient, isInstructor, isUnauthorized]}
        >
          <Terms />
        </AuthorizedPageWrapper>
      }
    />

    {/* Membership Agreement */}
    <Route
      path=":customer_id/membership-agreement"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaff, isClient, isInstructor, isUnauthorized]}
        >
          <MembershipAgreement />
        </AuthorizedPageWrapper>
      }
    />

    <Route
      path=":customer_id/client-memberships/:membershipID"
      element={
        <Page>
          <MembershipViewClient />
        </Page>
      }
    />

    {/* Events */}
    <Route
      path=":customer_id/events"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaff, isCoach, isClient, isUnauthorized]}
        >
          <EventPage />
        </AuthorizedPageWrapper>
      }
    />

    {/* AR Vision */}
    {isARtheme() && (
      <Route
        path=":customer_id/ar_vision"
        element={
          <AuthorizedPageWrapper allowedRoles={[isClient, isUnauthorized]}>
            <ClientPortal readOnly />
          </AuthorizedPageWrapper>
        }
      />
    )}

    {/* Marketing */}
    <Route
      path=":customer_id/marketing"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Marketing />
        </AuthorizedPageWrapper>
      }
    />

    {/* Teams */}
    <Route
      path=":customer_id/teams"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaff, isCoach, isClient, isUnauthorized]}
        >
          <TeamPage />
        </AuthorizedPageWrapper>
      }
    />
    {/* Teams  */}
    {eventsRoutes()}

    {/* Teams  */}
    {teamsRoutes()}

    {/* Reports  */}
    {reportsRoutes()}

    {/* Memberships */}
    {membershipsRoutes()}

    {/* Credit passes */}
    {creditPassesRoutes()}

    {/* Retail */}
    {retailRoutes()}

    {/* Coupons */}
    {couponsRoutes()}

    {/* Devtools */}
    {devRoutes()}

    <Route
      path="*"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff, isClient]}>
          <NotFound />
        </AuthorizedPageWrapper>
      }
    />
  </>
);

export default customersRoutes;
