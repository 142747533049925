import { Record } from 'immutable';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { Image } from 'records';

class ProductImage extends Record({
  id: null,
  featured: false,
  image: new Image(),
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        image: new Image(obj.image),
      })
    );
  }

  getAlternative(size) {
    const src = this.image ? this.image.getAlternative(size) : null;

    // TODO: Put this in S3.
    return src || '/no_image.png';
  }

  async toServer() {
    return {
      featured: this.featured,
      image: await this.image.toServer(),
    };
  }
}

export default ProductImage;
