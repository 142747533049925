class CouponsActions {
  constructor() {
    this.generateActions(
      'list',
      'listError',
      'listSuccess',
      'mounted',
      'selectPage',
      'updateFilter',
      'reset'
    );
  }
}

export default alt.createActions(CouponsActions);
