import { List, Record } from 'immutable';

import CalendarMeta from 'calendar/records/CalendarMeta.jsx';

class CalendarViewSettings extends Record({
  calendars: List(),
}) {
  constructor(props = {}) {
    const calendars = props.calendars || List();

    const calendarMetaRecords = calendars.map(
      calendar => new CalendarMeta(calendar)
    );

    const calendarsProp = {
      calendars: List(calendarMetaRecords),
    };

    super({ ...props, ...calendarsProp });
  }
}

export default CalendarViewSettings;
