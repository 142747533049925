import * as React from 'react';
import PropTypes from 'prop-types';
import { ContentModal } from '@upperhand/playmaker';
import { List, Map } from 'immutable';

import LocationFormFields from 'shared/components/LocationFormFields.jsx';
import Location from 'shared/records/Location.jsx';
import { t } from 'shared/utils/LocaleUtils';

function Modal({
  location,
  errors,
  addressTypes,
  isOpen,
  onSuccess,
  onAbort,
  intl,
}) {
  const title = location.id
    ? t('.title_edit_mode', intl, __filenamespace)
    : t('.title_create_mode', intl, __filenamespace);

  return (
    <ContentModal
      open={isOpen}
      title={title}
      onSuccess={onSuccess}
      onAbort={onAbort}
      cancelLabel={t('.cancel_button', intl, __filenamespace)}
      successLabel={t('.success_button', intl, __filenamespace)}
    >
      <LocationFormFields
        addressTypes={addressTypes}
        errors={errors}
        location={location}
      />
    </ContentModal>
  );
}

Modal.propTypes = {
  addressTypes: PropTypes.instanceOf(List).isRequired,
  errors: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  location: PropTypes.instanceOf(Location).isRequired,
  onAbort: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Modal;
