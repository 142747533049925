import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List } from 'immutable';
import AttendeeSessionExpander from 'shared/components/checkout/_AttendeeSessionExpander.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

function SingleSessionPurchasableContent({
  afterProfileCreate,
  contentActions,
  event,
  fullPrice,
  intl,
  isSessionAvailable,
  managingUserId,
  membershipEventDiscounts,
  onProfileSelect,
  registrationPackage,
  schedulingActions,
  sessions,
  sessionsShowMore,
  sessionsLoadingMore,
  onSessionsLoadMore,
  selectableAthletes,
  selectedAthletes,
  singleSessionPrice,
  sessionAvailability,
  sspStore,
  totalPrice,
}) {
  const hasSelectedAthletes = selectedAthletes.count() > 0;
  const singleSessionSize = registrationPackage.quantity;
  const isTiersEnabled = event.isSSPTurnedOn() && event.hasPricingTiers();

  return (
    <div>
      {!hasSelectedAthletes && (
        <div style={{ marginBottom: 12 }}>
          {t('.start', intl, __filenamespace)}
        </div>
      )}

      <ProfileSelector
        afterProfileCreate={afterProfileCreate}
        athletes={selectableAthletes}
        header={t('actions.choose_attendees', intl)}
        managingUserId={managingUserId}
        onChange={onProfileSelect}
        preventProfileCreation={event.isMembershipExclusive()}
        value={null}
      />

      {selectedAthletes.map(athlete => {
        const activeMembershipId = athlete?.active_membership_id;
        const activeMembershipTierId = athlete?.active_membership_tier_id;
        const eventDiscount = membershipEventDiscounts.find(
          med =>
            (med.subscribable_type === 'MembershipTier' &&
              med.subscribable_id === activeMembershipTierId) ||
            (med.subscribable_type === 'Membership' &&
              med.subscribable_id === activeMembershipId)
        );
        const sessionsCount = registrationPackage.tentative_details.filter(
          td => td.client_id === athlete.id
        ).size;
        const isAllSelected = sessionsCount === event.session_ids.size;
        const tieredPrice = event.getTieredPrice(
          sessionsCount,
          event.single_session_price
        );
        const allPrice = event.price / sessionsCount;
        const sspTieredPrice = isAllSelected ? allPrice : tieredPrice;
        const sspPrice = isAllSelected ? allPrice : singleSessionPrice;

        return (
          <AttendeeSessionExpander
            allDayAvailable={sspStore.allDayAvailableIds.includes(athlete.id)}
            allDayChecked={sspStore.allDayCheckedIds.includes(athlete.id)}
            athlete={athlete}
            athleteSessions={sspStore.clientSessionMap.get(athlete.id, List())}
            eventDiscount={eventDiscount}
            expanded={sspStore.expandedIds.includes(athlete.id)}
            fullPrice={fullPrice}
            isSessionAvailable={isSessionAvailable}
            key={athlete.id}
            onToggleExpand={() => contentActions.expandToggled(athlete.id)}
            schedulingActions={schedulingActions}
            singleSessionPrice={isTiersEnabled ? sspTieredPrice : sspPrice}
            sessionAvailability={sessionAvailability}
            sessions={sessions}
            sessionsShowMore={sessionsShowMore}
            sessionsLoadingMore={sessionsLoadingMore}
            onSessionsLoadMore={onSessionsLoadMore}
          />
        );
      })}

      {singleSessionSize > 0 && (
        <FlexBoxJustify style={{ fontSize: 16, margin: '20px 0 10px 0' }}>
          <FormattedMessage id={messageId('.total', __filenamespace)} />
          <div style={{ fontWeight: 'bold' }}>
            <FormattedCurrency value={totalPrice} fromCents />
          </div>
        </FlexBoxJustify>
      )}
    </div>
  );
}

export default injectIntl(SingleSessionPurchasableContent);
