import ZebraPrinterBluetooth from 'shared/native/zebra_printer/Bluetooth.js';
import ZebraPrinterWifi from 'shared/native/zebra_printer/Wifi.js';
import { BLUETOOTH } from 'shared/stores/ZebraStore.js';

const PORT_NUM = 6101;

export class Printer {
  constructor(printer, name) {
    this.device = printer;
    this.name = name;
  }

  async connectPrinter() {
    return null;
  }

  async disconnectPrinter() {
    return null;
  }

  async sendZPL(zpl) {
    this.device.send(zpl);
  }

  async sendThenReadZPL(zpl) {
    return new Promise((resolve, reject) => {
      this.device.sendThenRead(
        zpl,
        data => {
          resolve(data);
        },
        () => {
          reject();
        }
      );
    });
  }
}

export class NativeWifiPrinter extends Printer {
  async connectPrinter() {
    await ZebraPrinterWifi.connect(this.device, PORT_NUM);
  }

  async disconnectPrinter() {
    await ZebraPrinterWifi.disconnect();
  }

  async sendZPL(zpl) {
    await ZebraPrinterWifi.send(zpl);
  }

  async sendThenReadZPL(zpl) {
    return ZebraPrinterWifi.sendThenReadZPL(zpl);
  }
}

export class NativeBluetoothPrinter extends Printer {
  async connectPrinter() {
    await ZebraPrinterBluetooth.connect(this.device);
  }

  async disconnectPrinter() {
    await ZebraPrinterBluetooth.disconnect();
  }

  async sendZPL(zpl) {
    await ZebraPrinterBluetooth.send(zpl);
  }

  async sendThenReadZPL(_) {
    return BLUETOOTH;
  }
}
