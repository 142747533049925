import * as React from 'react';
import PropTypes from 'prop-types';

import GenericDiscount from 'memberships/components/_DiscountBenefits/_GenericDiscount.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import ColoredItem from '../_ColoredItem.jsx';

function CreditPassDiscount({ discount, creditPasses, itemStyle, textStyle }) {
  return (
    <GenericDiscount discount={discount} messageKey=".value_off_passes">
      {creditPasses.map(creditPass => (
        <ColoredItem
          key={creditPass.id}
          className="ColoredItem"
          text={creditPass.name}
          color={creditPass.color}
          style={merge({ marginLeft: -4 }, itemStyle)}
          textStyle={textStyle}
        />
      ))}
    </GenericDiscount>
  );
}

CreditPassDiscount.propTypes = {
  discount: PropTypes.object.isRequired,
  creditPasses: PropTypes.object.isRequired,
};

export default CreditPassDiscount;
