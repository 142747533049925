import { DefaultSourceActions } from './DefaultSourceActionList';

class PaymentSourceActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'refundError',
      'refundSuccess',
      'submitError',
      'submitSuccess'
    );
  }
}

export default alt.createActions(PaymentSourceActions);
