import * as React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { Grid, InfiniteScroller, Spinner } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import Paginator from 'shared/components/Paginator.jsx';

import OrderReportActions from '../../Actions';
import EmptyState from './EmptyState.jsx';
import HeaderDesktop from './HeaderDesktop.jsx';
import HeaderMobile from './HeaderMobile.jsx';
import RowDesktop from './RowDesktop.jsx';
import RowMobile from './RowMobile.jsx';

function OrdersReportTable({
  columns,
  filters,
  intl,
  isLoadingOrder,
  orderIds,
  orders,
  page,
  perPage,
  statistics,
  totalCount,
}) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <ResponsiveElement
        largeScreen={
          isLoadingOrder ? (
            <FlexBoxJustifyCenter>
              <Spinner />
            </FlexBoxJustifyCenter>
          ) : (
            <>
              <Grid item xs={12}>
                <HeaderDesktop columns={columns} intl={intl} />
              </Grid>
              {orderIds.size === 0 ? (
                <Grid item xs={12}>
                  <EmptyState intl={intl} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    {orderIds.map(id => (
                      <RowDesktop
                        key={id}
                        intl={intl}
                        orderId={id}
                        orders={orders}
                        columns={columns}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Paginator
                      className="orders-report__pagination"
                      currentPage={page}
                      perPage={perPage}
                      totalCount={totalCount}
                      onPageSelect={OrderReportActions.selectPage}
                      pageLimit={0}
                      showInfo
                    />
                  </Grid>
                </>
              )}
            </>
          )
        }
        smallScreen={
          <>
            <Grid item xs={12}>
              <HeaderMobile
                intl={intl}
                filters={filters}
                statistics={statistics}
                orderIds={orderIds}
              />
            </Grid>
            {orderIds.size === 0 ? (
              <Grid item xs={12}>
                <EmptyState intl={intl} />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <InfiniteScroller
                  hasMore={orderIds.size < totalCount}
                  onScroll={OrderReportActions.list.defer}
                  isLoading={isLoadingOrder}
                  showLoader
                >
                  {orderIds.map(id => (
                    <RowMobile
                      key={id}
                      intl={intl}
                      orderId={id}
                      orders={orders}
                    />
                  ))}
                </InfiniteScroller>
              </Grid>
            )}
          </>
        }
      />
    </Grid>
  );
}

OrdersReportTable.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isLoadingOrder: PropTypes.bool.isRequired,
  orderIds: PropTypes.instanceOf(OrderedSet).isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default injectIntl(OrdersReportTable);
