import { List, Map } from 'immutable';
import Location from 'shared/records/Location.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import TimeTrackingActions from 'shared/actions/TimeTrackingActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = id => {
  if (id) {
    return `locations/${id}`;
  }
  return 'locations';
};

class LocationStore extends UpperHandStore {
  constructor() {
    super();
    this.allLocations = List(); // for listing
    this.location = new Location(); // for fetching, creating, updating
    this.errors = Map();
    this.isLoading = false;
    // Creating and updating
    this.clearOnSuccess = false;
    this.saveInProgress = false;
    this.inlineEditorOpen = null;
    this.successMessage = null;

    // Deleting
    this.awaitingConfirmation = false;
    this.toDelete = null;

    this.findById = id =>
      this.allLocations.find(location => location.id === id);

    this.bindListeners({
      handleUpdateStore: LocationActions.UPDATE_STORE,

      handleList: [
        LocationActions.LIST,
        TimeTrackingActions.MENU_OPENED,
        PayrollReportActions.MOUNTED,
      ],
      handleListSuccess: LocationActions.LIST_SUCCESS,
      handleListError: LocationActions.LIST_ERROR,

      stageLocation: LocationActions.STAGE_LOCATION,
      clearLocation: LocationActions.CLEAR_LOCATION,

      handleInlineEditorToggled: LocationActions.INLINE_EDITOR_TOGGLED,
      handleCreateOrUpdate: LocationActions.CREATE_OR_UPDATE,
      handleCreateOrUpdateSuccess: LocationActions.CREATE_OR_UPDATE_SUCCESS,
      handleCreateOrUpdateError: LocationActions.CREATE_OR_UPDATE_ERROR,

      handleDeleteRequested: LocationActions.DELETE_REQUESTED,
      handleDeleteCancelled: LocationActions.DELETE_CANCELLED,
      handleDelete: LocationActions.DELETE,
      handleDeleteSuccess: LocationActions.DELETE_SUCCESS,
      handleDeleteError: LocationActions.DELETE_ERROR,
    });
  }

  stageLocation(locationId) {
    this.location = this.findById(locationId) || new Location();
    this.validateLocation();
  }

  clearLocation() {
    this.location = new Location();
    this.errors = Map();
  }

  handleUpdateStore([keypath, value]) {
    this.location = this.location.setIn(keypath, value);
    if (!this.errors.isEmpty()) {
      this.validateLocation();
    }
  }

  handleList() {
    this.isLoading = true;

    return uhApiClient.get({
      url: url(),
      data: {
        per_page: 100,
        fields: ['address'],
      },
      success: LocationActions.listSuccess,
      error: LocationActions.listError,
    });
  }

  handleListSuccess(data) {
    this.isLoading = false;

    this.allLocations = List(
      data.locations.map(location => new Location(location))
    );
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing locations', args);
  }

  handleInlineEditorToggled(id) {
    this.inlineEditorOpen = id;
  }

  handleCreateOrUpdate(options) {
    const opts = options || {};

    this.clearOnSuccess = !!opts.clearOnSuccess;

    if (this.location.id) {
      this.update(opts);
    } else {
      this.create(opts);
    }
  }

  create({ showSuccessMessage, parentObject }) {
    if (this.validateLocation() && !this.saveInProgress) {
      this.saveInProgress = true;

      if (showSuccessMessage) {
        this.successMessage = 'Location created successfully.';
      }

      uhApiClient.post({
        url: url(this.location.id),
        data: this.payload(),
        success: {
          action: LocationActions.createOrUpdateSuccess,
          args: [parentObject],
        },
        error: LocationActions.createOrUpdateError,
      });
    }
  }

  update({ showSuccessMessage, parentObject }) {
    if (this.validateLocation() && !this.saveInProgress) {
      this.saveInProgress = true;

      if (showSuccessMessage) {
        this.successMessage = 'Location updated successfully.';
      }

      uhApiClient.put({
        url: url(this.location.id),
        data: this.payload(),
        success: {
          action: LocationActions.createOrUpdateSuccess,
          args: [parentObject],
        },
        error: LocationActions.createOrUpdateError,
      });
    }
  }

  handleCreateOrUpdateSuccess([data, _parentObject]) {
    const newLocation = new Location(data);
    const i = this.allLocations.findKey(
      location => location.id === newLocation.id
    );

    if (i >= 0) {
      this.allLocations = this.allLocations.set(i, newLocation);
    } else {
      this.allLocations = this.allLocations.push(newLocation);
    }

    if (this.clearOnSuccess) {
      this.location = new Location();
    } else {
      this.location = newLocation;
    }

    if (this.successMessage) {
      MessageWindowActions.addMessage.defer(this.successMessage);
    }

    this.clearOnSuccess = false;
    this.saveInProgress = false;
    this.inlineEditorOpen = null;
    this.successMessage = null;
  }

  handleCreateOrUpdateError(...args) {
    this.clearOnSuccess = false;
    this.saveInProgress = false;
    this.inlineEditorOpen = null;
    this.successMessage = null;

    this.notifyError('error while saving location', args);
  }

  handleDeleteRequested(id) {
    if (id) {
      this.toDelete = id;
      this.awaitingConfirmation = true;
    }
  }

  handleDeleteCancelled() {
    this.toDelete = null;
    this.awaitingConfirmation = false;
  }

  handleDelete() {
    this.awaitingConfirmation = false;

    if (this.toDelete) {
      uhApiClient.delete({
        url: url(this.toDelete),
        data: JSON.stringify({ fields: ['address'] }),
        success: LocationActions.deleteSuccess,
        error: LocationActions.deleteError,
      });
    }
  }

  handleDeleteSuccess() {
    this.allLocations = this.allLocations.filterNot(
      location => location.id === this.toDelete
    );
    if (this.location.id === this.toDelete) {
      this.clearLocation();
    }
    this.toDelete = null;
  }

  handleDeleteError(...args) {
    this.toDelete = null;
    this.notifyError('error while deleting location', args);
  }

  payload() {
    return JSON.stringify({
      attributes: this.location.toServer(),
      fields: ['address'],
    });
  }

  validateLocation() {
    const requiredFields = [
      ['name'],
      ['address', 'line_1'],
      ['address', 'city'],
      ['address', 'state'],
      ['address', 'postal_code'],
    ];

    this.errors = Map();

    requiredFields.forEach(field => {
      if (!this.location.getIn(field)) {
        this.addError(field, 'Required.');
      }
    });

    return this.errors.isEmpty();
  }

  addError(keypath, error) {
    this.errors = this.errors.updateIn(keypath, List(), errors =>
      errors.push(error)
    );
  }
}

export default alt.createStore(LocationStore, 'LocationStore');
