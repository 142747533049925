import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    enableBackground: 'new 0 0 47.793 47.793',
  },
};

function Switches({ style, ...props }) {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...merge(props, { style: merge(styles.root, style) })}
      viewBox="0 0 47.793 47.793"
    >
      <title>Switches</title>
      <g>
        <path
          d="M13.747,20.831h20.207c5.752,0,10.416-4.663,10.416-10.416S39.706,0,33.953,0H13.747C7.995,0,3.331,4.663,3.331,10.416
    	S7.994,20.831,13.747,20.831z M13.747,3.769c3.671,0,6.647,2.976,6.647,6.647s-2.976,6.647-6.647,6.647S7.1,14.087,7.1,10.416
    	S10.076,3.769,13.747,3.769z"
        />
        <path
          d="M34.046,26.961H13.84c-5.752,0-10.416,4.663-10.416,10.416S8.087,47.793,13.84,47.793h20.206
    	c5.752,0,10.416-4.663,10.416-10.416S39.799,26.961,34.046,26.961z M34.046,44.023c-3.671,0-6.646-2.978-6.646-6.647
    	s2.977-6.647,6.646-6.647s6.647,2.978,6.647,6.647S37.717,44.023,34.046,44.023z"
        />
      </g>
    </SvgIcon>
  );
}

export default Switches;
