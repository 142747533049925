import { Map } from 'immutable';
import { Transaction } from 'records';
import Parser from 'sources/Parser';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { subscribe, unsubscribe } from 'cable.js';
import { onSuccess } from 'sources/handlers';
import DataStoreActions from './TransactionActions';

const parserTo = { type: Transaction, paginationKey: 'transactions' };

const parser = new Parser(parserTo);

let subscribeSuccessFunctions = Map();

export const subscribeToChanges = ({ id, success }) => {
  if (success) {
    subscribeSuccessFunctions = subscribeSuccessFunctions.set(id, success);
  }

  return subscribe(
    {
      channel: 'TransactionChannel',
      transaction_id: id,
      customer_id: currentCustomer().id,
    },
    onSuccess(
      data => parser.parseSingle(data),
      subscribeSuccessFunctions.toList().concat(DataStoreActions.fetchSuccess)
    )
  );
};

export const unsubscribeToChanges = ({ id }) => {
  subscribeSuccessFunctions = subscribeSuccessFunctions.delete(id);

  unsubscribe({
    channel: 'TransactionChannel',
    transaction_id: id,
    customer_id: currentCustomer().id,
  });
};
