import { UpperHandStore } from 'dataStores';
import { RoleActions } from 'sources';
import { setRoles, getRoles } from 'shared/utils/AuthUtils.js';

class RoleDataStore extends UpperHandStore {
  constructor() {
    super();

    // setting this as a list so we match our sessionStorage... for now
    this.records = getRoles();

    this.bindListeners({
      listSuccess: [RoleActions.listSuccess],
      listError: [RoleActions.listError],
    });
  }

  listSuccess({ roles }) {
    setRoles(roles);
    this.records = getRoles();
  }

  listError(...args) {
    this.notifyError('error listing roles', args);
  }
}

export default alt.createStore(RoleDataStore, 'RoleDataStore');
