import React from 'react';
import debounce from 'lodash.debounce';
import { Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import RosterTransferDrawerActions from './Actions';

function RosterTransferAutocomplete({
  onChange,
  placeholder,
  rosterTransferDrawerStore: {
    events,
    page,
    perPage,
    title,
    totalCount,
    selectedEvent,
  },
}) {
  const handleScroll = e => {
    const node = e.currentTarget;
    const atBottom = node.scrollTop + node.clientHeight === node.scrollHeight;

    if (atBottom && perPage * page <= totalCount) {
      RosterTransferDrawerActions.fetchEventList({
        page: page + 1,
        perPage,
        title,
      });
    }
  };

  const handleInputChange = value =>
    RosterTransferDrawerActions.fetchEventList({
      page: 1,
      perPage,
      title: value,
    });

  const optimizedFn = debounce(handleInputChange, 500);

  return (
    <Autocomplete
      clearOnEscape
      clearOnBlur={false}
      disableCloseOnSelect
      options={events.toJS()}
      filterOptions={options => options}
      getOptionLabel={option => option.title}
      value={selectedEvent}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      renderOption={(props, option, { selected }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.title}
        </li>
      )}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          placeholder={placeholder}
          inputProps={{ ...params.inputProps, sx: { padding: '10px' } }}
        />
      )}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      onChange={onChange}
      onInputChange={(e, _value, reason) => {
        if (reason === 'input') {
          optimizedFn(e.target.value);
        }
        if (reason === 'clear') {
          optimizedFn('');
        }
      }}
    />
  );
}

export default RosterTransferAutocomplete;
