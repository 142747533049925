export default alt.generateActions(
  'listRequested',
  'error',
  'listReceived',
  'pageSelected',
  'membershipSearchHandler',
  'listMembershipTiers',
  'listMembershipTiersSuccess',
  'listMembershipTiersError'
);
