import moment from 'moment-timezone';
import { List, Map } from 'immutable';
import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';
import StaffAvailabilityActions from 'shared/actions/StaffAvailabilityActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { AvailabilityScheduleDataStore } from 'dataStores';
import { AvailabilityScheduleSource } from 'sources';

const url = id => {
  if (id) {
    return `customer_users/${id}/availability_schedule`;
  }
  return 'customer_users/availability_schedules';
};

class StaffAvailabilityStore extends UpperHandStore {
  constructor() {
    super();
    this.allAvailabilitySchedules = Map(); // for listing
    this.availabilitySchedule = new AvailabilitySchedule(); // for fetching, creating, updating
    this.errors = Map();
    this.staffId = null;

    this.findById = id =>
      this.allAvailabilitySchedules.find(schedule => schedule.id === id);

    this.findByOwnerId = id =>
      this.allAvailabilitySchedules.find(schedule => schedule.owner_id === id);

    this.bindListeners({
      handleUpdateStore: StaffAvailabilityActions.UPDATE_STORE,

      handleList: StaffAvailabilityActions.LIST,
      handleListSuccess: StaffAvailabilityActions.LIST_SUCCESS,
      handleListError: StaffAvailabilityActions.LIST_ERROR,

      stageAvailabilityScheduleFor:
        StaffAvailabilityActions.STAGE_AVAILABILITY_SCHEDULE_FOR,

      handleCreateOrUpdate: StaffAvailabilityActions.CREATE_OR_UPDATE,
      handleCreateOrUpdateSuccess:
        StaffAvailabilityActions.CREATE_OR_UPDATE_SUCCESS,
      handleCreateOrUpdateError:
        StaffAvailabilityActions.CREATE_OR_UPDATE_ERROR,
    });
  }

  handleUpdateStore([keypath, value]) {
    this.availabilitySchedule = this.availabilitySchedule.setIn(keypath, value);
    if (!this.errors.isEmpty()) {
      this.validateSchedule();
    }
  }

  stageAvailabilityScheduleFor(staffMember) {
    this.waitFor(AvailabilityScheduleDataStore);

    const availabilitySchedule =
      AvailabilityScheduleDataStore.getState().availabilitySchedules.get(
        staffMember.availability_schedule_id
      );

    this.staffId = staffMember.id;
    this.availabilitySchedule =
      availabilitySchedule || new AvailabilitySchedule();
  }

  // eslint-disable-next-line class-methods-use-this
  handleList(page = 1) {
    uhApiClient.get({
      url: url(),
      data: { page, per_page: 100 },
      success: StaffAvailabilityActions.listSuccess,
      error: StaffAvailabilityActions.listError,
    });
  }

  handleListSuccess(data) {
    const {
      page,
      per_page: perPage,
      total_count: totalCount,
      availability_schedules: availabilitySchedules,
    } = data;

    const availability = Map().withMutations(a =>
      availabilitySchedules.forEach(s =>
        a.set(s.id, AvailabilitySchedule.fromServer(s))
      )
    );

    this.allAvailabilitySchedules =
      this.allAvailabilitySchedules.merge(availability);

    if (page * perPage < totalCount) {
      this.handleList(page + 1);
    }
  }

  handleListError(...args) {
    this.notifyError('error while listing staff availability schedules', args);
  }

  handleCreateOrUpdate() {
    if (this.availabilitySchedule.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    if (this.validateSchedule()) {
      AvailabilityScheduleSource.create({
        staffId: this.staffId,
        availabilitySchedule: this.availabilitySchedule,
        success: StaffAvailabilityActions.createOrUpdateSuccess,
        error: StaffAvailabilityActions.createOrUpdateError,
      });
    }
  }

  update() {
    if (this.validateSchedule()) {
      AvailabilityScheduleSource.update({
        staffId: this.staffId,
        availabilitySchedule: this.availabilitySchedule,
        success: StaffAvailabilityActions.createOrUpdateSuccess,
        error: StaffAvailabilityActions.createOrUpdateError,
      });
    }
  }

  handleCreateOrUpdateSuccess(data) {
    const { id } = data;

    this.availabilitySchedule = AvailabilitySchedule.fromServer(data);
    this.allAvailabilitySchedules = this.allAvailabilitySchedules.set(
      id,
      this.availabilitySchedule
    );
  }

  handleCreateOrUpdateError(...args) {
    this.notifyError('error while saving staff availability schedule', args);
  }

  payload() {
    return JSON.stringify({ attributes: this.availabilitySchedule.toServer() });
  }

  validateSchedule() {
    this.errors = Map();

    if (!this.availabilitySchedule.start_date) {
      this.addError('start_date', 'Start date required.');
    }

    if (
      !this.availabilitySchedule.end_date &&
      !this.availabilitySchedule.indefinite
    ) {
      this.addError('end_date', 'End date required.');
    }

    if (
      moment(this.availabilitySchedule.end_date).isBefore(
        moment(this.availabilitySchedule.start_date)
      )
    ) {
      this.addError('end_date', 'End date cannot be before start date.');
    }

    if (!this.availabilitySchedule.areAllStartTimesPresent()) {
      this.addError('start_time', 'Required.');
    }

    if (!this.availabilitySchedule.areAllEndTimesPresent()) {
      this.addError('end_time', 'Required.');
    }

    const selectedWeekdays = this.availabilitySchedule.daytimes
      .keySeq()
      .toArray();
    if (
      selectedWeekdays.length === 0 ||
      (selectedWeekdays.length === 1 && selectedWeekdays[0] === 'none')
    ) {
      this.addError('daytimes', 'At least one weekday must be selected.');
    }

    return this.errors.isEmpty();
  }

  addError(field, error) {
    this.errors = this.errors.update(field, List(), errors =>
      errors.push(error)
    );
  }
}

export default alt.createStore(
  StaffAvailabilityStore,
  'StaffAvailabilityStore'
);
