import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { TransactionActions } from 'sources';

class TransactionDataStore extends UpperHandStore {
  constructor() {
    super();

    this.transactions = Map();

    this.bindListeners({
      fetchSuccess: [TransactionActions.fetchSuccess],
    });
  }

  fetchSuccess(transaction) {
    this.transactions = this.transactions.set(transaction.id, transaction);
  }
}

export default alt.createStore(TransactionDataStore, 'TransactionDataStore');
