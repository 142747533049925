import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@upperhand/playmaker';

import EmptyStateIcon from 'shared/components/icons/empty_states/PaymentMethods.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function NotAvailableState({ intl }) {
  return (
    <div className="empty-state">
      <div className="empty-state__icon">
        <EmptyStateIcon />
      </div>
      <Typography className="empty-state__title" variant="h3">
        {t('.na_state', intl, __filenamespace)}
      </Typography>
    </div>
  );
}

NotAvailableState.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default NotAvailableState;
