import { Iterable, List, Map, Record, fromJS } from 'immutable';
import ChargifyProduct from 'shared/records/ChargifyProduct.jsx';
import SubscriptionCard from 'shared/records/SubscriptionCard.js';

class Subscription extends Record({
  id: null,
  product: new ChargifyProduct(),
  components: List(),
  credit_card: Map(),
}) {
  constructor(obj = {}, options = {}) {
    if (Iterable.isIterable(obj)) {
      obj = obj.toJS();
    }

    const components = fromJS(obj.components || []);
    const credit_card = new SubscriptionCard(obj.credit_card || {});

    super({
      ...obj,
      product: new ChargifyProduct(obj.product),
      components,
      credit_card,
    });
  }
}

export default Subscription;
