import { Record } from 'immutable';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class TeamType extends Record({
  id: null,
  name: '',
  color: '#800080',
  created_at: null,
  updated_at: null,
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        created_at: obj?.created_at ? new Date(obj.created_at) : null,
        updated_at: obj?.updated_at ? new Date(obj.updated_at) : null,
      })
    );
  }
}

export default TeamType;
