import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import VerticalDivider from 'shared/components/VerticalDivider.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function TermsBanner({ name, style, children }) {
  return (
    <FlexBoxCenter
      style={merge(style, {
        backgroundColor: uhColors.leftNavGrey,
        color: 'var(--color-white)',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: 30,
      })}
    >
      <div style={{ fontSize: 22 }}>
        {name}
        <VerticalDivider
          style={{ color: uhColors.navIconGrey, fontWeight: 100 }}
        />
        <span style={{ textTransform: 'capitalize' }}>
          <FormattedMessage
            id={messageId('.policy_and_terms', __filenamespace)}
          />
        </span>
      </div>
      {children && <div style={{ fontSize: 15 }}>{children}</div>}
    </FlexBoxCenter>
  );
}

export default TermsBanner;
