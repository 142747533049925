import CreditPassActions from 'shared/actions/CreditPassActions.js';
import CreditPassViewingActions from 'credit_passes/actions/CreditPassViewingActions.js';
import UIStore from 'shared/stores/UIStore.jsx';

class CreditPassViewingUIStore extends UIStore {
  constructor() {
    super();

    this.awaitingRemoveConfirmation = false;

    this.bindListeners({
      handleUpdateStore: CreditPassViewingActions.UPDATE_STORE,
      handleLoading: [
        CreditPassActions.FETCH,
        CreditPassViewingActions.LIST_CLIENTS,
      ],
      handleDoneWaiting: [
        CreditPassActions.ERROR,
        CreditPassActions.CREDIT_PASS_LOADED,
        CreditPassViewingActions.CLIENTS_LOADED,
      ],

      showRemoveConfirmation: CreditPassViewingActions.REMOVE_REQUESTED,
      hideRemoveConfirmation: [
        CreditPassViewingActions.REMOVE_ABORTED,
        CreditPassViewingActions.REMOVE_CONFIRMED,
      ],

      showLoading: CreditPassViewingActions.REMOVE_SUCCESS,
    });
  }

  showLoading() {
    this.handleLoading({});
  }

  showRemoveConfirmation() {
    this.awaitingRemoveConfirmation = true;
  }

  hideRemoveConfirmation() {
    this.awaitingRemoveConfirmation = false;
  }
}

export default alt.createStore(
  CreditPassViewingUIStore,
  'CreditPassViewingUIStore'
);
