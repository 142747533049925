// Skip default actions because we will not have create.
class CardReaderActions {
  constructor() {
    this.generateActions(
      'fetchSuccess',
      'fetchError',
      'listSuccess',
      'listError',
      'updateSuccess',
      'updateError',
      'syncSuccess',
      'syncError'
    );
  }
}

export default alt.createActions(CardReaderActions);
