import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, Typography } from '@upperhand/playmaker';
import { SwatchesPicker } from 'react-color';

import EventTypeEditingActions from 'shared/actions/EventTypeEditingActions.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  labelStyle: {
    paddingTop: 16,
    paddingBottom: 8,
  },
};

const swatchColors = [
  ['#E91E63'],
  ['#9C27B0'],
  ['#673AB7'],
  ['#2196F3'],
  ['#00BCD4'],
  ['#009688'],
  ['#8BC34A'],
  ['#FFEB3B'],
  ['#FF9800'],
  ['#FF5722'],
  ['#4565d9'],
  ['#303F9F'],
];

const onChange = handleChange({ actions: EventTypeEditingActions });

function EventTypeForm({ eventType, intl }) {
  return (
    <>
      <TextField
        fullWidth
        label={t('.name_label', intl, __filenamespace)}
        name="name"
        onChange={onChange}
        placeholder={t('.name_label', intl, __filenamespace)}
        value={eventType.name}
      />
      <div style={styles.labelStyle}>
        <Typography variant="caption">
          <FormattedMessage id={messageId('.color_label', __filenamespace)} />
        </Typography>
      </div>
      <SwatchesPicker
        color={eventType.color}
        colors={swatchColors}
        height={90}
        onChangeComplete={newColor =>
          EventTypeEditingActions.colorChanged(newColor.hex)
        }
      />
    </>
  );
}

export default injectIntl(EventTypeForm);
