import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, CurrencyTextField } from '@upperhand/playmaker';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { numberToCurrency } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import FeePlanStore from 'event_mgmt/shared/stores/FeePlanStore.jsx';

const styles = {
  bigButton: {
    height: 50,
  },
  clearButtonIcon: {
    width: 18,
    height: 18,
  },
  paper: {
    padding: 20,
    marginBottom: 10,
    position: 'relative',
  },
  clearButton: {
    width: 36,
    height: 36,
    padding: 9,
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 1,
  },
  textField: {
    marginBottom: 20,
  },
};

const handleParameterChange = (keypath, value) => {
  AutomationTemplateDescriptionActions.updateStoreKeypath(
    ['template_parameters'].concat(keypath),
    value
  );
};

const validatePackagePrice = (pkg, feePlan) => {
  const minPrice = Math.max(feePlan.breakEvenPrice(), 0.49) + 0.01;
  const price = pkg.get('price') / 100;
  return price < minPrice;
};

function PackageCard({ feePlan, pkg, index, canRemove, intl }) {
  const minPrice = Math.max(feePlan.breakEvenPrice(), 0.49) + 0.01;
  const showError = validatePackagePrice(pkg, feePlan);

  const handleRemovePkg = pkgIndex => {
    if (canRemove) {
      AutomationTemplateDescriptionActions.deleteParameter([
        'template_parameters',
        pkgIndex,
      ]);
    }
  };

  return (
    <Paper key={`parameter${pkg.get('uuid')}`} style={styles.paper}>
      {canRemove && (
        <IconButton
          style={styles.clearButton}
          onClick={() => handleRemovePkg(index)}
        >
          <ClearIcon color={grey[500]} />
        </IconButton>
      )}
      <Stack spacing={2}>
        <TextField
          label={t('.option_name', intl, __filenamespace)}
          fullWidth
          value={pkg.get('name')}
          onChange={(_, value) => handleParameterChange([index, 'name'], value)}
        />
        <TextField
          label={t('.cart_description', intl, __filenamespace)}
          fullWidth
          value={pkg.get('description')}
          onChange={(_, value) =>
            handleParameterChange([index, 'description'], value)
          }
        />
        <FlexBoxJustify style={{ alignItems: 'baseline' }}>
          <TextField
            label={t('.quantity', intl, __filenamespace)}
            fullWidth
            value={pkg.get('quantity')}
            onChange={(_, value) =>
              handleParameterChange([index, 'quantity'], value)
            }
          />
          <div style={{ fontWeight: 600, margin: '0 20px' }}>
            <FormattedMessage id={messageId('.for', __filenamespace)} />
          </div>
          <CurrencyTextField
            errorText={
              showError ? (
                <FormattedMessage
                  id={messageId('.fee_error', __filenamespace)}
                  values={{ price: numberToCurrency(minPrice) }}
                />
              ) : (
                ''
              )
            }
            label={t('.price', intl, __filenamespace)}
            fullWidth
            value={pkg.get('price') / 100}
            onChange={(_, value) =>
              handleParameterChange(
                [index, 'price'],
                Math.round((value + Number.EPSILON) * 100)
              )
            }
          />
        </FlexBoxJustify>
      </Stack>
    </Paper>
  );
}

function PerSessionPricingEditor({
  template,
  onSave,
  intl,
  feePlanStore: { feePlan },
}) {
  const disableSaveBtn = template.template_parameters.some(pkg =>
    validatePackagePrice(pkg, feePlan)
  );
  const handleAddPkg = () => {
    AutomationTemplateDescriptionActions.updateStoreKeypath(
      [],
      template.addOption()
    );
  };

  return (
    <div>
      <div style={{ fontWeight: 600, marginBottom: 20 }}>{template.name}</div>
      {template.template_parameters.map((pkg, i) => (
        <PackageCard
          key={`PackageCard-${pkg.get('uuid')}`}
          feePlan={feePlan}
          pkg={pkg}
          index={i}
          canRemove={template.template_parameters.size > 1}
          intl={intl}
        />
      ))}

      <Button
        variant="text"
        startIcon={<AddIcon />}
        style={{ marginBottom: 10 }}
        onClick={handleAddPkg}
      >
        {t('.add_option', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        disabled={disableSaveBtn}
        variant="contained"
        style={{ ...styles.bigButton }}
        onClick={onSave}
      >
        {t('.save', intl, __filenamespace)}
      </Button>
    </div>
  );
}

export default compose(
  injectIntl,
  altContainer({ stores: { feePlanStore: FeePlanStore } })
)(PerSessionPricingEditor);
