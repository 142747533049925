import { Record } from 'immutable';

class CreditCount extends Record({
  client_id: null,
  credits_expired: 0,
  credits_granted: 0,
  credits_remaining: 0,
  credits_revokable: 0,
  credits_used: 0,
  event_id: null,
  unlimited: false,
}) {
  availableCreditCount() {
    return this.unlimited ? Infinity : this.credits_remaining;
  }
}

export default CreditCount;
