import * as React from 'react';
import { injectIntl } from 'react-intl';
import {
  FormModal,
  Grid,
  InfiniteScroller,
  Spinner,
} from '@upperhand/playmaker';
import clsx from 'clsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';

import { t } from 'shared/utils/LocaleUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { CardReaderDataStore } from 'dataStores';

import CardReaderEdit from 'containers/cardReaderEdit/CardReaderEdit.jsx';
import CardReaderEditActions from 'containers/cardReaderEdit/Actions';

import DeviceInfo from './components/DeviceInfo.jsx';
import EmptyState from './components/EmptyState.jsx';
import Header from './components/Header.jsx';
import Refresh from './components/Refresh.jsx';

import DeviceListActions from './Actions';
import DeviceListStore from './Store';

import './styles.scss';

function DeviceList({
  cardReaderDataStore: { cardReaders },
  deviceListStore: {
    cardReaderIds,
    isLoadingCardReaders,
    isRefreshing,
    totalCount,
    isEditOpen,
  },
  intl,
}) {
  // Feels like this design calls for extra small screen.
  const isMobile = smallScreen();

  React.useEffect(() => {
    DeviceListActions.mounted({ isMobile });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSpinner = isLoadingCardReaders || isRefreshing;

  return (
    <div className="device-list">
      <Header intl={intl} isMobile={isMobile} />
      {showSpinner && cardReaderIds.isEmpty() && (
        <FlexBoxJustifyCenter>
          <Spinner />
        </FlexBoxJustifyCenter>
      )}
      {!showSpinner && cardReaderIds.isEmpty() && (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <EmptyState intl={intl} />
          </Grid>
        </Grid>
      )}
      {!cardReaderIds.isEmpty() && (
        <InfiniteScroller
          hasMore={cardReaderIds.size < totalCount}
          onScroll={DeviceListActions.listCardReaders.defer}
          isLoading={isLoadingCardReaders}
          showLoader
          className={clsx(
            'device-list__content',
            isMobile && 'device-list__content-mobile'
          )}
        >
          {isMobile && <Refresh />}
          {cardReaderIds.map(id => (
            <DeviceInfo cardReader={cardReaders.get(id)} intl={intl} key={id} />
          ))}
          {!isMobile && <Refresh />}
        </InfiniteScroller>
      )}
      <FormModal
        title="Rename"
        open={isEditOpen}
        actions={[
          {
            type: 'primary',
            label: t('.save', intl, __filenamespace),
            action: CardReaderEditActions.submitEdit,
          },
          {
            type: 'tertiary',
            label: t('.cancel', intl, __filenamespace),
            action: DeviceListActions.closeEditModal,
          },
        ]}
      >
        <CardReaderEdit />
      </FormModal>
    </div>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      cardReaderDataStore: CardReaderDataStore,
      deviceListStore: DeviceListStore,
    },
  })
)(DeviceList);
