class AccountProfilesActions {
  constructor() {
    this.generateActions(
      'creditCountListSuccess',
      'creditCountListError',
      'clientListSuccess',
      'clientListError',
      'managedClientListSuccess',
      'managedClientListError',
      'mount'
    );
  }
}

export default alt.createActions(AccountProfilesActions);
