import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

import PageHeader from 'components/PageHeader/PageHeader.jsx';
import Filters from 'containers/reports/dailyCloseoutReport/components/Header/Filters.jsx';
import Statistics from 'containers/reports/dailyCloseoutReport/components/Header/Statistics.jsx';

import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

function CloseoutHeader({
  intl,
  isDownloadingReport,
  statistics,
  filters,
  onChange,
  onFilterClick,
  onCalculatorClick,
  onExportClick,
  onDeleteFilter,
}) {
  return (
    <>
      <PageHeader title={t('.title', intl, __filenamespace)} />
      <div className="closeout-report__header-actions">
        <Filters
          isDownloadingReport={isDownloadingReport}
          filters={filters}
          onChange={onChange}
          onCalculatorClick={onCalculatorClick}
          onFilterClick={onFilterClick}
          onExportClick={onExportClick}
          onDeleteFilter={onDeleteFilter}
        />
        <Statistics statistics={statistics} />
      </div>
    </>
  );
}

CloseoutHeader.propTypes = {
  intl: PropTypes.object.isRequired,
  isDownloadingReport: PropTypes.bool.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  onCalculatorClick: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
};

export default compose(memo, injectIntl)(CloseoutHeader);
