import * as React from 'react';
import {
  Step,
  NextAction,
  ErrorMessage,
} from 'onboarding/components/AccountSetupPaysafe/steps/_Helpers.jsx';
import BankAccountForm from 'onboarding/components/AccountSetupPaysafe/forms/_BankAccountForm.jsx';
import BankStepActions from 'onboarding/actions/BankStepActions';

class BankStep extends React.Component {
  propsForField = fieldName => {
    const { fields } = this.props;
    return {
      fieldName,
      value: fields.getIn([fieldName, 'value'], ''),
      errorKey: fields.getIn([fieldName, 'error'], ''),
      onChange: BankStepActions.updateForm,
    };
  };

  render() {
    const { hasError, isLoading } = this.props;
    return (
      <Step>
        <BankAccountForm {...this.props} propsForField={this.propsForField} />
        <ErrorMessage show={hasError} />
        <NextAction
          action={BankStepActions.submitBankForm}
          isLoading={isLoading}
          disabled={hasError || isLoading}
        />
      </Step>
    );
  }
}

export default BankStep;
