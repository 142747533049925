import moment from 'moment-timezone';
import { Record } from 'immutable';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const THIS_WEEK = 'this_week';
export const LAST_WEEK = 'last_week';
export const THIS_MONTH = 'this_month';
export const LAST_MONTH = 'last_month';
export const THIS_QUARTER = 'this_quarter';
export const LAST_QUARTER = 'last_quarter';
export const THIS_YEAR = 'this_year';
export const LAST_YEAR = 'last_year';
export const CUSTOM = 'custom';

export const RANGES = [
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_QUARTER,
  LAST_QUARTER,
  THIS_YEAR,
  LAST_YEAR,
  CUSTOM,
];

class DateRange extends Record({
  value: null,
  startTime: null,
  endTime: null,
}) {
  constructor({ value, startDate, endDate }) {
    const timeZone = currentCustomer().tz_name;
    let range = {};

    switch (value) {
      case TODAY:
        range = {
          startTime: moment.tz(timeZone).startOf('day'),
          endTime: moment.tz(timeZone).endOf('day'),
        };
        break;
      case YESTERDAY:
        range = {
          startTime: moment.tz(timeZone).subtract(1, 'day').startOf('day'),
          endTime: moment.tz(timeZone).subtract(1, 'day').endOf('day'),
        };
        break;
      case THIS_WEEK:
        range = {
          startTime: moment.tz(timeZone).startOf('week'),
          endTime: moment.tz(timeZone).endOf('week'),
        };
        break;
      case LAST_WEEK:
        range = {
          startTime: moment.tz(timeZone).subtract(1, 'week').startOf('week'),
          endTime: moment.tz(timeZone).subtract(1, 'week').endOf('week'),
        };
        break;
      case THIS_MONTH:
        range = {
          startTime: moment.tz(timeZone).startOf('month'),
          endTime: moment.tz(timeZone).endOf('month'),
        };
        break;
      case LAST_MONTH:
        range = {
          startTime: moment.tz(timeZone).subtract(1, 'month').startOf('month'),
          endTime: moment.tz(timeZone).subtract(1, 'month').endOf('month'),
        };
        break;
      case THIS_QUARTER:
        range = {
          startTime: moment.tz(timeZone).startOf('quarter'),
          endTime: moment.tz(timeZone).endOf('quarter'),
        };
        break;
      case LAST_QUARTER:
        range = {
          startTime: moment
            .tz(timeZone)
            .subtract(1, 'quarter')
            .startOf('quarter'),
          endTime: moment.tz(timeZone).subtract(1, 'quarter').endOf('quarter'),
        };
        break;
      case THIS_YEAR:
        range = {
          startTime: moment.tz(timeZone).startOf('year'),
          endTime: moment.tz(timeZone).endOf('year'),
        };
        break;
      case LAST_YEAR:
        range = {
          startTime: moment.tz(timeZone).subtract(1, 'year').startOf('year'),
          endTime: moment.tz(timeZone).subtract(1, 'year').endOf('year'),
        };
        break;
      case CUSTOM:
        range = {
          startTime: moment(startDate).tz(timeZone, true).startOf('day'),
          endTime: moment(endDate).tz(timeZone, true).endOf('day'),
        };
        break;
      default:
        range = { startTime: null, endTime: null };
        break;
    }

    super({ value, ...range });
  }

  toString({ intl }) {
    return new TranslatableMessage({
      id: `.${this.value}`,
      filename: __filenamespace,
    }).toString({ intl });
  }
}

export default DateRange;
