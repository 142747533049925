import debounce from 'lodash.debounce';
import { List } from 'immutable';

import { ClientSource, WaitlistSource } from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import WaitlistActions from './actions';

class WaitlistEventAdminTabStore extends UpperHandStore {
  constructor() {
    super();

    this.eventId = null;
    this.waitlistEntries = List();
    this.loading = false;

    this.bindListeners({
      mounted: WaitlistActions.mounted,
      waitlistFetchSuccess: WaitlistActions.waitlistFetchSuccess,
      waitlistFetchError: WaitlistActions.waitlistFetchError,
      waitlistInvite: WaitlistActions.waitlistInvite,
      waitlistInviteSuccess: WaitlistActions.waitlistInviteSuccess,
      waitlistInviteError: WaitlistActions.waitlistInviteError,
      clientsListSuccess: WaitlistActions.clientListSuccess,
      clientsListError: WaitlistActions.clientListError,
    });
  }

  mounted(eventId) {
    this.eventId = eventId;
    this.fetchWaitlist();
  }

  fetchWaitlist() {
    this.loading = true;

    WaitlistSource.fetch({
      id: this.eventId,
      success: WaitlistActions.waitlistFetchSuccess,
      error: WaitlistActions.waitlistFetchError,
    });
  }

  waitlistFetchSuccess(waitlist) {
    this.waitlistEntries = waitlist.waitlist_entries;

    this.listClients();
  }

  waitlistFetchError() {
    this.loading = false;
    this.notifyError('error listing waitlist', arguments);
  }

  waitlistInvite(clientId) {
    WaitlistSource.invite({
      id: this.eventId,
      customer_user_ids: [clientId],
      success: WaitlistActions.waitlistInviteSuccess,
      error: WaitlistActions.waitlistInviteError,
    });
  }

  waitlistInviteSuccess(waitlist) {
    this.waitlistFetchSuccess(waitlist);
  }

  waitlistInviteError() {
    this.loading = false;
    this.notifyError('error inviting from waitlist', arguments);
  }

  listClients() {
    const params = {
      page: 1,
      per_page: this.waitlistEntries.size,
      ids: this.waitlistEntries.map(we => we.customer_user_id).toJS(),
    };

    ClientSource.list({
      params,
      success: WaitlistActions.clientListSuccess,
      error: WaitlistActions.clientListError,
    });
  }

  clientsListSuccess({ clients, page, perPage, totalCount }) {
    this.loading = false;
  }

  clientsListError() {
    this.loading = false;
    this.notifyError('error listing clients', arguments);
  }
}

export default alt.createStore(
  WaitlistEventAdminTabStore,
  'WaitlistEventAdminTabStore'
);
