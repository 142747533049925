import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { ScheduleActions } from 'sources';

class ScheduleDataStore extends UpperHandStore {
  constructor() {
    super();

    this.schedules = Map();

    this.bindListeners({
      createSuccess: ScheduleActions.createSuccess,
      createError: ScheduleActions.createError,
      fetchSuccess: ScheduleActions.fetchSuccess,
      fetchError: ScheduleActions.fetchError,
      listSuccess: ScheduleActions.listSuccess,
      listError: ScheduleActions.listError,
      updateSuccess: ScheduleActions.updateSuccess,
      cancelSuccess: ScheduleActions.cancelSuccess,
      cancelError: ScheduleActions.cancelError,
      updateError: ScheduleActions.updateError,
      removeSuccess: ScheduleActions.removeSuccess,
      removeError: ScheduleActions.removeError,
    });
  }

  createSuccess(schedule) {
    this.schedules = this.schedules.set(schedule.id, schedule);
  }

  createError(...args) {
    this.notifyError('error creating schedule', args);
  }

  fetchSuccess(schedule) {
    this.schedules = this.schedules.set(schedule.id, schedule);
  }

  fetchError(...args) {
    this.notifyError('error fetching schedule', args);
  }

  listSuccess({ schedules }) {
    const fetchedSchedules = schedules.groupBy(s => s.id).map(s => s.first());

    this.schedules = this.schedules.merge(fetchedSchedules);
  }

  listError(...args) {
    this.notifyError('error listing schedules', args);
  }

  updateSuccess(schedule) {
    this.schedules = this.schedules.set(schedule.id, schedule);
  }

  updateError(...args) {
    this.notifyError('error updating schedule', args);
  }

  cancelSuccess(schedule) {
    this.schedules = this.schedules.set(schedule.id, schedule);
  }

  cancelError(...args) {
    this.notifyError('error cancelling schedule', args);
  }

  // eslint-disable-next-line class-methods-use-this
  removeSuccess() {}

  removeError(...args) {
    this.notifyError('error canceling schedule', args);
  }
}

export default alt.createStore(ScheduleDataStore, 'ScheduleDataStore');
