import React from 'react';
import AltContainer from 'alt-container';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { v4 } from 'uuid';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';

import { l, t } from 'shared/utils/LocaleUtils.js';
import Paginator from '../../../shared/components/Paginator.jsx';

import MrrReportStore from '../stores/MonthlyRecurringRevenueReportStore.jsx';
import MrrReportActions from '../actions/MonthlyRecurringRevenueReportActions.jsx';
import { Widget } from '../../index/shared/components/ReportWidget.jsx';

/* Moment shows dates in the local timezone.
So, to fix an issue with a date labels displaying we manually added an offset to the date
and when it will be displayed in the local timezone it will be correct. */
function formatDate(intl, value, interval) {
  return l(
    moment(value)
      .add(moment(value).utcOffset() * -1, 'minutes')
      .toDate(),
    `.dateFormat${interval || 'Month'}`,
    intl,
    __filenamespace
  );
}

const StyledRow = styled(TableRow)(({ theme }) => ({
  border: 'none',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledColumn = styled(TableCell)(props => ({
  [`&.${tableCellClasses.body}`]: {
    height: 45,
    padding: 12,
    textAlign: 'center',
    backgroundColor: props['data-highlight']
      ? 'rgba(254, 255, 237, 0.75)'
      : 'initial',
    borderTop: props['data-highlight'] ? 'none' : 'initial',
    borderBottom: props['data-highlight'] ? 'none' : 'initial',
    color: props.value < 0 ? 'var(--color-unpaid-red)' : 'inherit',
    fontWeight: props['data-bold'] ? 'bold' : 'normal',
    pointerEvents:
      props.value === 0 || !props['data-has-details'] ? 'none' : 'auto',

    '&:hover': {
      cursor:
        props.value === 0 || !props['data-has-details'] ? 'initial' : 'pointer',
      boxShadow:
        props.value === 0 || !props['data-has-details']
          ? 'none'
          : `0 -1px 8px 0 rgba(100, 121, 143, 0.12),
        0 3px 4px 0 rgba(0, 0, 0, 0.24)`,
    },
  },
}));

const RowHeader = styled(TableCell)(props => ({
  [`&.${tableCellClasses.body}`]: {
    height: 45,
    padding: 12,
    textAlign: 'right',
    fontWeight: props['data-highlight'] ? 'bold' : 'normal',
  },
}));

const ColumnHeader = styled(TableCell)(props => ({
  [`&.${tableCellClasses.head}`]: {
    height: 45,
    textAlign: 'center',
    backgroundColor: props['data-highlight']
      ? 'rgba(254, 255, 237, 0.75)'
      : 'initial',
    borderTop: props['data-highlight'] ? 'none' : 'initial',
    borderBottom: props['data-highlight'] ? 'none' : 'initial',
  },
}));

class MonthlyRecurringRevenueMetrics extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  handlePageSelect = (page, perPage) => {
    MrrReportActions.updateMetricsPageParams({ page, perPage });
  };

  renderRow = (values, index) => {
    const {
      intl,
      monthlyRecurringRevenueReportStore: {
        rows,
        columnsWithDetail,
        metricsPageParams: { page, perPage },
        activeCell: { row, column, paginatedColumn },
      },
    } = this.props;
    const from = (page - 1) * perPage;
    const to = page * perPage;
    const paginatedValues = values.slice(from, to);
    const paddedValues = new Array(perPage)
      .fill('')
      .map((def, i) => paginatedValues[i] ?? def);

    return (
      <StyledRow key={index}>
        {paddedValues.map((value, i) => (
          <StyledColumn
            key={v4()}
            data-highlight={(page - 1) * perPage + i === paginatedColumn}
            data-bold={rows[index + 1].bold || (index === row && i === column)}
            data-has-details={columnsWithDetail.includes(
              rows[index + 1].column
            )}
            value={Number(value)}
            onClick={() => MrrReportActions.fetchDetailsList([index, i])}
          >
            {i >= paginatedValues.length &&
            value === '' &&
            paginatedValues.length < perPage
              ? value
              : rows[index + 1].format(intl, value)}
          </StyledColumn>
        ))}
      </StyledRow>
    );
  };

  renderTable = () => {
    const {
      intl,
      monthlyRecurringRevenueReportStore: {
        interval,
        monthlyRecurringRevenueListResultTransposed = [],
        metricsPageParams: { page, perPage },
        activeCell: { paginatedColumn },
      },
    } = this.props;

    let columns = [];
    let rows = [];
    if (monthlyRecurringRevenueListResultTransposed.length) {
      [columns, ...rows] = monthlyRecurringRevenueListResultTransposed;
    }

    const from = (page - 1) * perPage;
    const to = page * perPage;
    const paginatedColumns = columns.slice(from, to);
    const paddedColumns = new Array(perPage)
      .fill('')
      .map((def, i) => paginatedColumns[i] || def);

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {paddedColumns.map((c, i) => (
                <ColumnHeader
                  data-highlight={(page - 1) * perPage + i === paginatedColumn}
                  key={v4()}
                  style={{
                    fontWeight: 'bold',
                    color: 'var(--color-old-gray)',
                    padding: 12,
                  }}
                >
                  {i >= paginatedColumns.length &&
                  c === '' &&
                  paginatedColumns.length < perPage
                    ? c
                    : formatDate(intl, c, interval)}
                </ColumnHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rows.map(this.renderRow)}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    const {
      monthlyRecurringRevenueReportStore: {
        monthlyRecurringRevenueListResult,
        isMonthlyRecurringRevenueListLoading,
        metricsPageParams,
        detailLabel,
        rows,
      },
      intl,
    } = this.props;
    return (
      monthlyRecurringRevenueListResult.length > 0 && (
        <>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Widget
              style={{
                margin: '0 4px 0 0',
                width: 204,
                minWidth: 204,
              }}
              titleStyle={{ display: 'none' }}
              bodyStyle={{ padding: 0 }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <ColumnHeader />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(1).map(row => (
                      <StyledRow key={row.label}>
                        <RowHeader
                          data-highlight={row.label === detailLabel}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div>{row.label}</div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <span
                              data-tip
                              data-for={row.label}
                              style={{ height: 18, marginLeft: 8 }}
                            >
                              <InfoIcon
                                style={{
                                  width: 16,
                                  height: 16,
                                  color: 'var(--color-ui-gray',
                                }}
                              />
                            </span>
                            <ReactTooltip
                              id={row.label}
                              effect="solid"
                              className="uh-tooltip"
                              place="right"
                            >
                              {t(`.${row.label}`, intl, __filenamespace)}
                            </ReactTooltip>
                          </div>
                        </RowHeader>
                      </StyledRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Widget>
            <Widget
              style={{ margin: 0 }}
              titleStyle={{ display: 'none' }}
              bodyStyle={{ padding: 0 }}
              isLoading={isMonthlyRecurringRevenueListLoading}
            >
              {this.renderTable()}
            </Widget>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Paginator
              currentPage={metricsPageParams.page}
              perPage={metricsPageParams.perPage}
              totalCount={monthlyRecurringRevenueListResult.length}
              onPageSelect={this.handlePageSelect}
            />
          </div>
        </>
      )
    );
  }
}

function MonthlyRecurringRevenueMetricsWrapper({ intl }) {
  return (
    <AltContainer
      stores={{
        monthlyRecurringRevenueReportStore: MrrReportStore,
      }}
    >
      <MonthlyRecurringRevenueMetrics intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(MonthlyRecurringRevenueMetricsWrapper);
