import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';

const styles = {
  header: {
    width: '100%',
    padding: '2em 0 1em 0',
  },

  formGroup: {
    padding: '0 15px 15px',
    width: '100%',
  },

  formLabel: {
    marginLeft: 0,
    justifyContent: 'space-between',
  },

  toggle: {
    top: 3,
  },

  overlayStyle: {
    opacity: 0,
    backgroundColor: 'transparent',
  },
  clearButton: {
    border: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  iconWrapper: {
    position: 'absolute',
    right: '13px',
    top: '6px',
  },
  title: {
    color: '#929BB2',
    fontWeight: 'bold',
  },
};

function MembershipViewFilterDrawer({ intl, membershipStore }) {
  const isMobile = smallScreen();
  const { clientFilters, isFilterDrawerOpen } = membershipStore;
  const active = clientFilters.get('active');
  const suspended = clientFilters.get('suspended');
  const cancelled = clientFilters.get('cancelled');
  const invited = clientFilters.get('invited');
  const all = clientFilters.get('all');

  const onToggle = e => MembershipViewingActions.onFilterChange(e.target.name);
  return (
    <Drawer
      open={isFilterDrawerOpen}
      onClose={MembershipViewingActions.filterDrawerClose}
      PaperProps={{
        sx: {
          width: isMobile ? window.innerWidth * 0.9 : STANDARD_DRAWER_WIDTH,
          padding: '0 30px',
        },
      }}
      anchor="right"
    >
      <Box sx={styles.header}>
        <FlexBoxJustify style={{ fontSize: 16, marginBottom: 4 }}>
          <Box sx={styles.title}>
            <FormattedMessage id={messageId('.title', __filenamespace)} />
          </Box>
          <Box sx={styles.iconWrapper}>
            <IconButton
              size="large"
              onClick={MembershipViewingActions.filterDrawerClose}
            >
              <CloseIcon sx={{ color: '#929BB2' }} />
            </IconButton>
          </Box>
        </FlexBoxJustify>
      </Box>
      <Grid container xs={12} spacing={3} alignItems="center">
        <Typography variant="subtitle2" className="membership-status-heading">
          {t('.membership_status', intl, __filenamespace)}
        </Typography>
        <FormGroup
          key="status-filter-list"
          className="membership-status-filter-list"
          style={styles.formGroup}
        >
          <FormControlLabel
            name="all"
            label={<span>{t('.all', intl, __filenamespace)}</span>}
            labelPlacement="start"
            onChange={onToggle}
            checked={all}
            disabled={all}
            control={<Switch />}
            style={styles.formLabel}
          />
          <FormControlLabel
            name="active"
            label={<span>{t('.active', intl, __filenamespace)}</span>}
            labelPlacement="start"
            onChange={onToggle}
            checked={active}
            control={<Switch />}
            disabled={active}
            style={styles.formLabel}
          />
          <FormControlLabel
            name="suspended"
            label={<span>{t('.suspended', intl, __filenamespace)}</span>}
            labelPlacement="start"
            onChange={onToggle}
            checked={suspended}
            disabled={suspended}
            control={<Switch />}
            style={styles.formLabel}
          />
          <FormControlLabel
            name="cancelled"
            label={<span>{t('.cancelled', intl, __filenamespace)}</span>}
            labelPlacement="start"
            onChange={onToggle}
            checked={cancelled}
            disabled={cancelled}
            control={<Switch />}
            style={styles.formLabel}
          />
          <FormControlLabel
            name="invited"
            label={<span>{t('.invited', intl, __filenamespace)}</span>}
            labelPlacement="start"
            onChange={onToggle}
            checked={invited}
            disabled={invited}
            control={<Switch />}
            style={styles.formLabel}
          />
        </FormGroup>
      </Grid>
    </Drawer>
  );
}

MembershipViewFilterDrawer.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(MembershipViewFilterDrawer);
