import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import AddButton from 'shared/components/AddButton.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import ExplanatoryText from 'shared/components/_ExplanatoryText.jsx';
import Form from 'shared/components/Form.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import TaskCreationActions from 'shared/actions/TaskCreationActions';
import TaskCreationStore from 'shared/stores/TaskCreationStore';
import TaskDeletionActions from 'shared/actions/TaskDeletionActions';
import TaskDeletionStore from 'shared/stores/TaskDeletionStore';
import TaskEditingActions from 'shared/actions/TaskEditingActions';
import TaskEditingStore from 'shared/stores/TaskEditingStore';
import TaskListingStore from 'shared/stores/TaskListingStore';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    margin: 30,
    maxWidth: SINGLE_COLUMN_WIDTH,
  },

  header: {
    fontSize: 22,
    marginBottom: 20,
  },

  taskCard: editInProgress => ({
    fontSize: 16,
    paddingLeft: 12,
    paddingTop: editInProgress ? 12 : 6,
    height: editInProgress ? 160 : 48,
  }),
};

function DeletionConfirmation({ task, confirmationOpen, intl }) {
  return (
    <ConfirmationDialog
      awaitingConfirmation={confirmationOpen}
      onCancel={TaskDeletionActions.deleteAborted}
      onConfirm={TaskDeletionActions.deleteConfirmed}
      title={t('.remove_task', intl, __filenamespace)}
    >
      <div style={{ marginBottom: 20 }}>
        <FormattedMessage
          id={messageId('.are_you_sure', __filenamespace)}
          values={{ name: task.name }}
        />
      </div>
    </ConfirmationDialog>
  );
}

const onEditSave = () => TaskEditingActions.save();
const onEditCancel = () => TaskEditingActions.cancel();

function TaskCard({ task, editInProgress, isSaving, style, intl }) {
  return (
    <Paper style={merge(styles.taskCard(editInProgress), style)}>
      {editInProgress && (
        <div style={{ fontWeight: 'bold', fontSize: 15 }}>
          <FormattedMessage id={messageId('.edit_task', __filenamespace)} />
        </div>
      )}
      <FlexBoxJustify style={{ alignItems: 'center' }}>
        {editInProgress ? (
          <Form
            onSubmit={onEditSave}
            style={{ flex: '1 1 auto', margin: '16px 0' }}
          >
            <TextField
              errorText={task.errors.getErrors('name', intl)}
              fullWidth
              name="name"
              onChange={(_, value) =>
                TaskEditingActions.taskUpdated({ name: value })
              }
              value={task.name}
            />
          </Form>
        ) : (
          <div>{task.name}</div>
        )}

        <FlexBox>
          <IconButton onClick={() => TaskEditingActions.begin(task)}>
            <EditIcon sx={{ color: uhColors.navIconGrey }} />
          </IconButton>

          <IconButton onClick={() => TaskDeletionActions.deleteRequested(task)}>
            <DeleteIcon sx={{ color: uhColors.navIconGrey }} />
          </IconButton>
        </FlexBox>
      </FlexBoxJustify>

      {editInProgress && (
        <FlexBox style={{ marginBottom: 12 }}>
          <StateChangingButton
            onClick={onEditSave}
            disabled={isSaving}
            inProgress={isSaving}
            style={{ marginRight: 5 }}
          />
          <Button
            style={{ marginLeft: 5 }}
            onClick={onEditCancel}
            disabled={isSaving}
          >
            {t('actions.cancel', intl, __filenamespace)}
          </Button>
        </FlexBox>
      )}
    </Paper>
  );
}

const onCreateSave = () => TaskCreationActions.save();

function TaskForm({ taskCreationStore: { task, isSaving }, style, intl }) {
  return (
    <Paper style={merge({ padding: 12 }, style)}>
      <div style={{ fontWeight: 'bold', fontSize: 15 }}>
        <FormattedMessage id={messageId('.create_task', __filenamespace)} />
      </div>

      <Form onSubmit={onCreateSave} style={{ margin: '16px 0' }}>
        <TextField
          errorText={task.errors.getErrors('name', intl)}
          placeholder={t('.name', intl, __filenamespace)}
          fullWidth
          name="name"
          onChange={(_, value) =>
            TaskCreationActions.taskUpdated({ name: value })
          }
          value={task.name}
        />
      </Form>

      <StateChangingButton
        onClick={onCreateSave}
        disabled={isSaving}
        inProgress={isSaving}
      />
    </Paper>
  );
}

const onCreateClicked = () => TaskCreationActions.begin();

function TasksTabContent({
  taskListingStore: { tasks, isLoading },
  taskCreationStore,
  taskDeletionStore,
  taskEditingStore,
  intl,
}) {
  return (
    <div style={styles.root}>
      <h2 style={styles.header}>
        <FormattedMessage id={messageId('.tasks', __filenamespace)} />
      </h2>

      <ExplanatoryText
        text={t('.tasks_explanation', intl, __filenamespace)}
        style={{ marginBottom: 30 }}
      />

      {taskCreationStore.task ? (
        <TaskForm
          taskCreationStore={taskCreationStore}
          intl={intl}
          style={{ marginBottom: 10 }}
        />
      ) : (
        <AddButton
          label={t('.create_task', intl, __filenamespace)}
          onClick={onCreateClicked}
          style={{ marginBottom: 30 }}
        />
      )}

      <SpinWhileLoading isLoading={isLoading}>
        {tasks.valueSeq().map(task => {
          const editInProgress =
            taskEditingStore.task && taskEditingStore.task.id === task.id;

          return (
            <TaskCard
              key={task.id}
              task={editInProgress ? taskEditingStore.task : task}
              editInProgress={editInProgress}
              isSaving={taskEditingStore.isSaving}
              taskEditingStore={taskEditingStore}
              style={{ marginBottom: 10 }}
              intl={intl}
            />
          );
        })}
      </SpinWhileLoading>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DeletionConfirmation {...taskDeletionStore} intl={intl} />
    </div>
  );
}

function TasksTab({ intl }) {
  return (
    <AltContainer
      stores={{
        taskListingStore: TaskListingStore,
        taskEditingStore: TaskEditingStore,
        taskCreationStore: TaskCreationStore,
        taskDeletionStore: TaskDeletionStore,
      }}
    >
      <TasksTabContent intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(TasksTab);
