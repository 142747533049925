import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import { Map } from 'immutable';
import IconButton from '@mui/material/IconButton';

import SettingsIcon from 'shared/components/icons/Settings.jsx';
import { t } from 'shared/utils/LocaleUtils';

import TransactionsLedgerReportActions from '../../Actions';

function HeaderDesktop({ columns, intl }) {
  // Have to add one because date / time cannot be toggled.
  const activeItems = columns.filter(c => c).count() + 1;

  return (
    <div className="transactions-ledger-report__header">
      <Grid container spacing={1} alignContent="center" justify="center">
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography
            variant="body3"
            className="transactions-ledger-report__header-item"
          >
            {t('.effectiveAt', intl, __filenamespace)}
          </Typography>
        </Grid>
        {columns.get('transactionId') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography
              variant="body3"
              className="transactions-ledger-report__header-item"
            >
              {t('.transactionId', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('clientName') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography
              variant="body3"
              className="transactions-ledger-report__header-item"
            >
              {t('.clientName', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('sourceType') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography
              variant="body3"
              className="transactions-ledger-report__header-item"
            >
              {t('.sourceType', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('paymentMethod') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography
              variant="body3"
              className="transactions-ledger-report__header-item"
            >
              {t('.paymentMethod', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('last4') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography
              variant="body3"
              className="transactions-ledger-report__header-item"
            >
              {t('.last4', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('amount') && (
          <Grid item className={`even-cols-${activeItems}`}>
            <Typography
              variant="body3"
              className="transactions-ledger-report__header-item"
            >
              {t('.amount', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <div className="transactions-ledger-report__row-action">
        <IconButton onClick={TransactionsLedgerReportActions.openColumnDrawer}>
          <SettingsIcon />
        </IconButton>
      </div>
    </div>
  );
}

HeaderDesktop.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
};

export default HeaderDesktop;
