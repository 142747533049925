import { Record, Set } from 'immutable';
import { toCamelCase, toSnakeCase } from 'shared/utils/ObjectUtils.jsx';

import PreferencesCalendar from './PreferencesCalendar';

class Preferences extends Record({
  calendar: new PreferencesCalendar(),
  sessionReminderTime: 1440,
  sessionReminderMethods: Set(['email']),
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({
      ...parsedObject,
      calendar: new PreferencesCalendar(parsedObject.calendar),
      sessionReminderMethods: Set(parsedObject.sessionReminderMethods),
    });
  }

  toServer() {
    const payload = this.toJS();

    return toSnakeCase(payload);
  }
}

export default Preferences;
