import AutomationTemplateDescription from 'shared/records/AutomationTemplateDescription.jsx';
import PerSessionPricingDescription from 'shared/records/automations/PerSessionPricingDescription.jsx';
import RecurringPaymentPlanDescription from 'shared/records/automations/RecurringPaymentPlanDescription.jsx';

const typeMap = {
  PerSessionPricingTemplate: PerSessionPricingDescription,
  RecurringPaymentPlanTemplate: RecurringPaymentPlanDescription,
};

const getType = templateType =>
  typeMap[templateType] || AutomationTemplateDescription;
const initATD = attributes =>
  new getType(attributes.template_type).fromServer(attributes);

export default initATD;
