import debounce from 'lodash.debounce';
import { List } from 'immutable';
import moment from 'moment-timezone';
import StaffActions from 'shared/actions/StaffActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import * as AvailabilityScheduleSource from 'sources/AvailabilityScheduleSource';
import { StaffSource, SessionSource } from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import ContactsStaffActions from './Actions';
import { FILTER_STAFF_TYPES_ALL } from './constants';

export const FILTER_STATUS_ALL = 'all';
export const FILTER_STATUS_ACTIVE = 'active';
export const FILTER_STATUS_INVITED = 'invited';

class ContactsStaffStore extends UpperHandStore {
  constructor() {
    super();

    this.staffIds = List();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.search = '';
    this.statuses = FILTER_STATUS_ALL;
    this.loading = false;
    this.loadedAt = null;
    this.updateContactId = null;
    this.isRevokeAccessModalOpen = false;
    this.isRevokeDataLoading = false;
    this.revokeAccessStaff = null;
    this.revokeAccessSessionsCount = null;
    this.staffTypes = FILTER_STAFF_TYPES_ALL;

    this.debouncedListStaff = debounce(() => {
      this.listStaff();
      this.emitChange();
    }, 600);

    this.bindListeners({
      mounted: ContactsStaffActions.mounted,
      staffListSuccess: ContactsStaffActions.staffListSuccess,
      staffListError: ContactsStaffActions.staffListError,
      pageSelected: ContactsStaffActions.pageSelected,
      searchUpdated: ContactsStaffActions.searchUpdated,
      statusesUpdated: ContactsStaffActions.statusesUpdated,
      updateContact: ContactsStaffActions.updateContact,
      openRevokeAccessModal: ContactsStaffActions.openRevokeAccessModal,
      closeRevokeAccessModal: ContactsStaffActions.closeRevokeAccessModal,
      revokeAccess: ContactsStaffActions.revokeAccess,
      revokeAccessError: ContactsStaffActions.revokeAccessError,
      revokeAccessSuccess: ContactsStaffActions.revokeAccessSuccess,
      sessionsListError: ContactsStaffActions.sessionsListError,
      sessionsListSuccess: ContactsStaffActions.sessionsListSuccess,
      listStaffAvailabilitySuccess:
        ContactsStaffActions.listStaffAvailabilitySuccess,
      listStaffAvailabilityError:
        ContactsStaffActions.listStaffAvailabilityError,
      fetchStaffAvailabilityError:
        ContactsStaffActions.fetchStaffAvailabilityError,
      fetchStaffAvailability: StaffActions.createSuccess,
      handleStaffTypesSelection: ContactsStaffActions.staffTypesUpdated,
    });
  }

  mounted() {
    if (
      !this.loadedAt ||
      this.loadedAt.isBefore(moment().subtract(5, 'minutes'))
    ) {
      if (currentCustomer().features.coach) {
        this.staffTypes = [...this.staffTypes, 'coach'];
      }
      this.listStaff();
      this.listStaffAvailability();
    }
  }

  listStaff() {
    const statuses =
      this.statuses === FILTER_STATUS_ALL
        ? [FILTER_STATUS_ACTIVE, FILTER_STATUS_INVITED]
        : [this.statuses];

    const params = {
      access_revoked: false,
      page: this.page,
      per_page: this.perPage,
      statuses,
    };
    if (this.staffTypes.length > 0) {
      params.staff_types = this.staffTypes;
    }

    if (this.search) {
      params.search = this.search;
    }

    if (currentUser().isInstructor()) {
      params.ids = [currentUser().customer_user_id];
    }

    this.loading = true;

    StaffSource.list({
      params,
      success: ContactsStaffActions.staffListSuccess,
      error: ContactsStaffActions.staffListError,
    });
  }

  staffListSuccess({ staff, totalCount }) {
    this.staffIds = staff.map(c => c.id);
    this.totalCount = totalCount;
    this.loading = false;
    this.loadedAt = moment();
  }

  staffListError(...args) {
    this.loading = false;
    this.notifyError('error listing staff contacts', args);
  }

  /* eslint-disable class-methods-use-this */
  // this is an alt action listener, it has to be a method on the class object.
  listStaffAvailability() {
    AvailabilityScheduleSource.list({
      params: { per_page: 100 },
      success: ContactsStaffActions.listStaffAvailabilitySuccess,
      error: ContactsStaffActions.listStaffAvailabilityError,
    });
  }

  listStaffAvailabilitySuccess({ page, perPage, totalCount }) {
    if (page * perPage < totalCount) {
      AvailabilityScheduleSource.list({
        params: { per_page: 100, page: page + 1 },
        success: ContactsStaffActions.listStaffAvailabilitySuccess,
        error: ContactsStaffActions.listStaffAvailabilityError,
      });
    }
  }

  listStaffAvailabilityError(...args) {
    this.notifyError('error listing staff availability', args);
  }

  // Fetch availability schedule for invited staff
  fetchStaffAvailability({ type, id }) {
    const isStaffInvited = type === 'StaffMember';
    const needFetchAvailability = id && isStaffInvited;

    if (needFetchAvailability) {
      AvailabilityScheduleSource.fetch({
        id,
        success: ContactsStaffActions.fetchStaffAvailabilitySuccess,
        error: ContactsStaffActions.fetchStaffAvailabilityError,
      });
    }
  }

  fetchStaffAvailabilityError(...args) {
    this.notifyError('error fetching staff availability', args);
  }

  /* eslint-enable class-methods-use-this */

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listStaff();
  }

  searchUpdated(newSearch) {
    this.search = newSearch;
    this.page = 1;

    this.debouncedListStaff();
  }

  statusesUpdated(newStatuses) {
    if (this.statuses === newStatuses) {
      return false;
    }

    this.statuses = newStatuses;
    this.page = 1;

    this.listStaff();

    return true;
  }

  updateContact(id) {
    this.updateContactId = id;
  }

  openRevokeAccessModal(staff) {
    this.isRevokeDataLoading = true;
    this.isRevokeAccessModalOpen = true;
    this.revokeAccessStaff = staff;

    SessionSource.list({
      params: {
        staff_ids: [staff.id],
        start_time: moment().toISOString(),
      },
      success: ContactsStaffActions.sessionsListSuccess,
      error: ContactsStaffActions.sessionsListError,
    });
  }

  closeRevokeAccessModal() {
    this.isRevokeAccessModalOpen = false;
    this.revokeAccessStaff = null;
  }

  revokeAccess() {
    this.isRevokeDataLoading = true;
    uhApiClient.post({
      url: `/staff/${this.revokeAccessStaff.id}/revoke_access`,
      success: ContactsStaffActions.revokeAccessSuccess,
      error: ContactsStaffActions.revokeAccessError,
    });
  }

  revokeAccessError(...args) {
    this.notifyError('error during customer sign up', args);

    this.closeRevokeAccessModal();
  }

  revokeAccessSuccess() {
    MessageWindowActions.addMessage.defer('Staff member successfully removed');
    this.listStaff();
    this.closeRevokeAccessModal();
  }

  sessionsListError() {
    MessageWindowActions.addMessage.defer('Error listing session');
    this.isRevokeDataLoading = false;
  }

  sessionsListSuccess({ totalCount }) {
    this.isRevokeDataLoading = false;
    this.revokeAccessSessionsCount = totalCount;
  }

  handleStaffTypesSelection(newSelectedTypes) {
    this.staffTypes = newSelectedTypes;
    this.page = 1;
    this.listStaff();
  }
}

export default alt.createStore(ContactsStaffStore, 'ContactsStaffStore');
