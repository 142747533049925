class ClassCreationActions {
  constructor() {
    this.generateActions(
      'toggleCreationModal',
      'toggleEditModal',
      'createClass',
      'createClassSuccess',
      'createClassError',
      'updateClass',
      'updateClassSuccess',
      'updateClassError',
      'fetchEventTypes',
      'fetchEventTypesSuccess',
      'fetchEventTypesError',
      'createEventTypeSuccess',
      'handleFieldChange',
      'completeClass',
      'completeClassSuccess'
    );
  }
}

export default alt.createActions(ClassCreationActions);
