import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import VariantActions from 'sources/VariantActions.js';

class VariantDataStore extends UpperHandStore {
  constructor() {
    super();

    this.variants = Map();

    this.bindListeners({
      listSuccess: VariantActions.listSuccess,
    });
  }

  listSuccess({ variants }) {
    const fetchedVariants = variants.groupBy(v => v.id).map(v => v.first());

    this.variants = this.variants.merge(fetchedVariants);
  }

  error(...args) {
    this.notifyError('error fetching credit passes', args);
  }
}

export default alt.createStore(VariantDataStore, 'VariantDataStore');
