import * as React from 'react';

import MembershipIcon from 'shared/components/icons/Membership.jsx';
import MembershipCommitment from 'point_of_sale/components/MembershipCommitment.jsx';
import MembershipPrice from 'point_of_sale/components/MembershipPrice.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import ProductCard from 'point_of_sale/components/products/list/cards/ProductCard.jsx';

function MembershipCard({ membership }) {
  return (
    <ProductCard
      className="membership-card"
      icon={<MembershipIcon color={membership.color} />}
      onClick={() => POSProductListActions.membershipSelected(membership)}
      primaryText={membership.name}
      secondaryText={<MembershipPrice membership={membership} />}
      tertiaryText={<MembershipCommitment membership={membership} />}
    />
  );
}

export default MembershipCard;
