import {
  NativeBiometric,
  BiometryType,
} from '@capgo/capacitor-native-biometric';
import CapacitorPlugin from 'shared/native/helpers/CapacitorPlugin';

// Wrapper for @capgo/capacitor-native-biometric
class BiometricAuthenticator extends CapacitorPlugin {
  pluginName = 'NativeBiometric';

  NONE = 'none';

  FINGERPRINT = 'fingerprint';

  FACE = 'face';

  KEYCHAIN_CREDENTIALS_KEY = 'io.upperhand.app.credentials';

  /**
   * Retrieves the supported biometric type: NONE, FINGERPRINT, or FACE.
   * @return {Promise} String
   */
  async supportedMethod() {
    if (!this.isNative) {
      return this.NONE;
    }
    await this.ready();
    const availableResult = await NativeBiometric.isAvailable({
      useFallback: false,
    });
    const { isAvailable } = availableResult;
    const biometryType = isAvailable && availableResult.biometryType;
    const isFace =
      biometryType === BiometryType.FACE_ID ||
      biometryType === BiometryType.FACE_AUTHENTICATION;
    const isTouch =
      biometryType === BiometryType.TOUCH_ID ||
      biometryType === BiometryType.FINGERPRINT;
    if (isFace) {
      return this.FACE;
    }
    if (isTouch) {
      return this.FINGERPRINT;
    }
    return this.NONE;
  }

  /**
   * Stores the email & password in the keychain for future use
   * @param  {String} email
   * @param  {String} password
   * @return {Promise}         null
   */
  async store(email, password) {
    if (!this.isNative) {
      return null;
    }
    await this.ready();
    return NativeBiometric.setCredentials({
      server: this.KEYCHAIN_CREDENTIALS_KEY,
      username: email,
      password,
    });
  }

  /**
   * Deletes stored credentials.
   * @return {Promise} null
   */
  async deleteCredentials() {
    if (!this.isNative) {
      return null;
    }
    await this.ready();
    const hasCredentials = await this.hasCredentials();
    if (hasCredentials) {
      return NativeBiometric.deleteCredentials({
        server: this.KEYCHAIN_CREDENTIALS_KEY,
      });
    }
    return null;
  }

  /**
   * Checks whether credentials are currently stored
   * @return {Promise} True if credentials are stored, false otherwise
   */
  async hasCredentials() {
    if (!this.isNative) {
      return null;
    }
    await this.ready();
    try {
      const credentials = await NativeBiometric.getCredentials({
        server: this.KEYCHAIN_CREDENTIALS_KEY,
      });
      return !!credentials;
    } catch (e) {
      return false;
    }
  }

  /**
   * Performs the biometric authentication
   * @return {Promise} True if authentication was successful, false otherwise
   */
  async verifyIdentify(message) {
    if (!this.isNative) {
      return false;
    }
    await this.ready();
    try {
      await NativeBiometric.verifyIdentity({ title: message });
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Retrieves credentials and displays the TouchID/FaceID authentication modal.
   * @param  {String} message Authentication message to display in modal.
   * @return {Promise}        {email: String, password: String}
   */
  async fetchCredentials(message) {
    if (!this.isNative) {
      return null;
    }
    await this.ready();
    const hasCredentials = await this.hasCredentials();
    if (hasCredentials) {
      const verified = await this.verifyIdentify(message);
      if (verified) {
        const credentials = await NativeBiometric.getCredentials({
          server: this.KEYCHAIN_CREDENTIALS_KEY,
        });
        return { email: credentials.username, password: credentials.password };
      }
      return null;
    }
    throw new Error('Key does not exist');
  }
}

export default new BiometricAuthenticator();
