import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StaffIcon from 'shared/components/icons/Staff.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function SingleStaff({ staff, style }) {
  return (
    <FlexBoxCenter style={merge({ height: 48 }, style)}>
      <StaffIcon
        color={uhColors.navIconGrey}
        style={{ height: 18, width: 18, marginRight: 10, marginBottom: 4 }}
      />
      <div key={staff.id}>{staff.name()}</div>
    </FlexBoxCenter>
  );
}

class StaffExpander extends React.Component {
  constructor(params) {
    super(params);

    this.state = { expanded: false };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  getIconStyle() {
    const { expanded } = this.state;

    return expanded
      ? { transform: 'rotate(-180deg)', marginLeft: 10 }
      : { marginLeft: 10 };
  }

  toggleExpanded() {
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  }

  render() {
    const { expanded } = this.state;
    const { staff, style } = this.props;
    const staffCount = staff.size;

    if (staffCount === 0) {
      return null;
    }
    if (staffCount === 1) {
      return <SingleStaff staff={staff.first()} style={style} />;
    }
    // This is a bit of a hack to make the transition work on the <tr> when
    // expanding the staff list. Unfortunately <tr> and <td> will not transition
    // on height, however we can fake it by setting max-height on the inner div
    // and transitioning on that. See https://stackoverflow.com/a/27787456 for a
    // more detailed explaination.
    const intitalHeight = 48;
    const expectedHeight = staffCount * 35 + intitalHeight;

    return (
      <div
        style={merge(
          {
            maxHeight: expanded ? expectedHeight : intitalHeight,
            transition: 'max-height 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
            overflow: 'hidden',
            fontSize: 15,
          },
          style
        )}
      >
        <FlexBoxCenter
          onClick={this.toggleExpanded}
          style={{ cursor: 'pointer', height: intitalHeight }}
        >
          <StaffIcon
            color={uhColors.navIconGrey}
            style={{
              height: 18,
              width: 18,
              marginRight: 10,
              marginBottom: 4,
            }}
          />

          <div style={{ fontWeight: expanded ? 'bold' : 'normal' }}>
            <FormattedMessage
              id={messageId('.n_staff', __filenamespace)}
              values={{ n: staffCount }}
            />
          </div>

          <ExpandLessIcon
            sx={{ color: uhColors.iconLightGrey }}
            style={this.getIconStyle()}
          />
        </FlexBoxCenter>

        {staff.map(s => (
          <div key={s.id} style={{ marginBottom: 20, paddingLeft: 28 }}>
            {s.name()}
          </div>
        ))}
      </div>
    );
  }
}

export default StaffExpander;
