import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CalendarViewMenu from 'calendar/components/views/shared/_CalendarViewMenu.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    alignItems: 'center',
  },
  label: {
    marginTop: '5px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },
  dropDownMenu: {
    flex: '1',
    minWidth: '25%',
  },
  dropDownUnderline: {
    display: 'none',
  },
  dropDownLabelStyle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  createNew: disabled => ({
    width: '100%',
    margin: '4px 0',
    textAlign: 'left',
    color: !disabled ? uhColors.activeBlue : uhColors.disabledGrey,
  }),
};

class CurrentViewSection extends React.PureComponent {
  state = {
    popoverOpen: false,
    anchorEl: null,
  };

  handlePopoverButtonClick = e => {
    e.preventDefault();

    this.setState({
      popoverOpen: true,
      anchorEl: e.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      popoverOpen: false,
      anchorEl: null,
    });
  };

  handleCreateViewClick = () => {
    CalendarViewActions.duplicate();
    this.handleRequestClose();
  };

  render() {
    const { currentCalendarView, intl } = this.props;
    const { popoverOpen, anchorEl } = this.state;

    return (
      <FlexBoxJustify style={styles.root}>
        <span style={styles.label}>
          <FormattedMessage id={messageId('.current_view', __filenamespace)} />
        </span>
        <Button
          variant="text"
          endIcon={<ExpandMoreIcon />}
          onClick={this.handlePopoverButtonClick}
        >
          {currentCalendarView.title}
        </Button>
        <CalendarViewMenu
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={this.handleRequestClose}
          showSettings={false}
          footer={
            <Button
              fullWidth
              variant="text"
              sx={{ text: { padding: '8px 0' } }}
              disabled={!currentCalendarView.id}
              style={styles.createNew(!currentCalendarView.id)}
              onClick={this.handleCreateViewClick}
            >
              {t('.create_new', intl, __filenamespace)}
            </Button>
          }
        />
      </FlexBoxJustify>
    );
  }
}

export default injectIntl(CurrentViewSection);
