import AccountingCode from 'shared/records/AccountingCode';
import AccountingCodeArchivingActions from 'shared/actions/accounting_codes/AccountingCodeArchivingActions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class AccountingCodeArchivingStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      askForConfirmation: AccountingCodeArchivingActions.ARCHIVE_REQUESTED,
      archiveAccountingCode: AccountingCodeArchivingActions.ARCHIVE_CONFIRMED,
      abortArchive: AccountingCodeArchivingActions.ARCHIVE_ABORTED,
      archiveSuccess: AccountingCodeArchivingActions.ARCHIVE_SUCCESS,
      archiveError: AccountingCodeArchivingActions.ARCHIVE_ERROR,
    });
  }

  reset() {
    this.accountingCode = new AccountingCode();
    this.confirmationOpen = false;
  }

  askForConfirmation(accountingCode) {
    this.accountingCode = accountingCode;
    this.confirmationOpen = true;
  }

  archiveAccountingCode() {
    if (!this.accountingCode.id) {
      return false;
    }

    return uhApiClient.patch({
      url: `accounting_codes/${this.accountingCode.id}`,
      data: JSON.stringify({ attributes: { archived: true } }),
      success: AccountingCodeArchivingActions.archiveSuccess,
      error: AccountingCodeArchivingActions.archiveError,
    });
  }

  abortArchive() {
    this.reset();
  }

  archiveSuccess(_data) {
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({ id: '.archived', filename: __filenamespace })
    );
    this.reset();
  }

  archiveError() {
    this.notifyError('error archiving accountingCode', arguments);
  }
}

export default alt.createStore(
  AccountingCodeArchivingStore,
  'AccountingCodeArchivingStore'
);
