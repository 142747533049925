import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Spinner } from '@upperhand/playmaker';
import clsx from 'clsx';

import BalanceDetailsDrawer from 'containers/reports/balanceDetailsDrawer/BalanceDetailsDrawer.jsx';
import OrderSummaryDrawer from 'containers/reports/orderSummaryDrawer/OrderSummaryDrawer.jsx';
import PaymentPlanDetailsDrawer from 'containers/reports/paymentPlanDetailsDrawer/PaymentPlanDetailsDrawer.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { OrderDataStore } from 'dataStores';

import OrdersReportHeader from 'containers/reports/ordersReport/components/OrdersReportHeader/OrdersReportHeader.jsx';
import OrdersReportTable from './components/OrdersReportTable/OrdersReportTable.jsx';
import OrdersColumnDrawer from './components/OrdersColumnDrawer.jsx';
import OrdersFilterDrawer from './components/OrdersFilterDrawer.jsx';
import PaymentDetailsDrawer from '../paymentDetailsDrawer/PaymentDetailsDrawer.jsx';

import OrderReportActions from './Actions';
import OrderReportStore from './Store';

import './styles.scss';

function OrdersReport({
  orderDataStore: { orders },
  orderReportStore: {
    activeFilters,
    columns,
    defaultFilters,
    drawerFilters,
    isColumnDrawerOpen,
    isFilterDrawerChanged,
    isFilterDrawerOpen,
    isLoadingClients,
    isLoadingOrder,
    isLoadingStatistics,
    orderIds,
    page,
    perPage,
    statistics,
    totalCount,
  },
}) {
  const isMobile = smallScreen();

  React.useEffect(() => {
    OrderReportActions.mounted(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx('orders-report', isMobile && 'orders-report__mobile')}>
      {isLoadingOrder && isLoadingStatistics && isLoadingClients ? (
        <FlexBoxJustifyCenter>
          <Spinner />
        </FlexBoxJustifyCenter>
      ) : (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <OrdersReportHeader
              defaultFilters={defaultFilters}
              filters={activeFilters}
              statistics={statistics}
            />
          </Grid>
          <Grid item xs={12}>
            <OrdersReportTable
              filters={activeFilters}
              isLoadingOrder={isLoadingOrder}
              orderIds={orderIds}
              orders={orders}
              page={page}
              perPage={perPage}
              totalCount={totalCount}
              statistics={statistics}
              columns={columns}
            />
          </Grid>
        </Grid>
      )}
      <OrderSummaryDrawer />
      <BalanceDetailsDrawer withBackLink />
      <PaymentDetailsDrawer />
      <PaymentPlanDetailsDrawer withBackLink />
      <OrdersColumnDrawer columns={columns} isOpen={isColumnDrawerOpen} />
      <OrdersFilterDrawer
        filters={drawerFilters}
        defaultFilters={defaultFilters}
        isOpen={isFilterDrawerOpen}
        isChanged={isFilterDrawerChanged}
      />
      <QuickpayModal />
      <RefundModal />
    </div>
  );
}

OrdersReport.propTypes = {
  orderDataStore: PropTypes.object,
  orderReportStore: PropTypes.object,
};

OrdersReport.defaultProps = {
  orderDataStore: {},
  orderReportStore: {},
};

export default compose(
  altContainer({
    stores: {
      orderDataStore: OrderDataStore,
      orderReportStore: OrderReportStore,
    },
  })
)(OrdersReport);
