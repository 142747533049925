import { Record, Set } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

export const ABSOLUTE = 'amount';
export const PERCENT = 'percent';

const VALID_TYPES = [ABSOLUTE, PERCENT];

class EventRate extends Record({
  id: null,
  event_ids: Set(),
  customer_user_id: null,
  rate_type: PERCENT,
  value: 0,
  discount_sensitive: false,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    super(merge(obj, { event_ids: Set(obj.event_ids) }));
  }

  validate() {
    let errors = new FieldErrors();

    if (!isPresent(this.value)) {
      errors = errors.add('value', 'records.errors.required');
    }

    if (!this.event_ids.size) {
      errors = errors.add(
        'event',
        'records.EventRate.errors.event_must_be_selected'
      );
    }

    if (
      this.rate_type === PERCENT &&
      isPresent(this.value) &&
      (this.value <= 0.0 || this.value > 100.0)
    ) {
      errors = errors.add(
        'value',
        'records.EventRate.errors.value_out_of_range'
      );
    }

    if (
      this.rate_type === ABSOLUTE &&
      isPresent(this.value) &&
      this.value <= 0
    ) {
      errors = errors.add('value', 'records.EventRate.errors.more_than_zero');
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty() && VALID_TYPES.includes(this.rate_type);
  }

  toServer() {
    return this.toJS();
  }
}

export default EventRate;
