import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { Grid, Typography, Button, DateTimeField } from '@upperhand/playmaker';
import { Map, OrderedSet } from 'immutable';

import { t } from 'shared/utils/LocaleUtils';

import OrderReportActions from '../../Actions';

function HeaderMobile({ filters, intl, orderIds, statistics }) {
  const isNow = moment().isSame(filters.get('period').from, 'day');

  const selectPrevDay = () => {
    OrderReportActions.selectDay(-1);
  };

  const selectNextDay = () => {
    OrderReportActions.selectDay(1);
  };

  return (
    <Grid
      className="orders-report__header-mobile"
      container
      spacing={1}
      alignItems="center"
      alignContent="center"
      justify="center"
    >
      <Grid item>
        <Button onClick={selectPrevDay} type="tertiary" icon="arrowLeft" />
      </Grid>
      <Grid item xs>
        <DateTimeField
          classes={{
            root: clsx(
              'mobile-date-picker__container',
              isNow && 'mobile-date-picker__container-hide-clear'
            ),
            input: 'mobile-date-picker__input',
          }}
          type="date"
          autoOk
          disableUnderline
          fullWidth
          onChange={OrderReportActions.selectDay}
          outline={false}
          value={filters.get('period').from}
        />
      </Grid>
      <Grid item>
        <Button onClick={selectNextDay} type="tertiary" icon="arrowRight" />
      </Grid>
      {orderIds.size > 0 && statistics.size > 0 && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            className="orders-report__statistic-mobile"
          >
            {t('.statistic', intl, __filenamespace, {
              count: statistics.get('count'),
              amount: intl.formatNumber(statistics.get('total') / 100, {
                style: 'currency',
                currency: 'USD',
              }),
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

HeaderMobile.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  orderIds: PropTypes.instanceOf(OrderedSet).isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
};

export default HeaderMobile;
