import * as React from 'react';
import { t } from 'shared/utils/LocaleUtils.js';
import { Checkbox, Dropdown } from '@upperhand/playmaker';

function DayOfWeekFilter({ value = new Set(), onChange, intl }) {
  const dayItems = [
    { label: 'All Days', value: -1 },
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
  ];

  const handleChange = (event, selectedDay) => {
    const isChecked = event.target.checked;

    onChange({
      selectedDay,
      isChecked,
    });
  };

  const handleClick = (event, selectedDay) => {
    const isChecked = !value.has(selectedDay);
    onChange({
      selectedDay,
      isChecked,
    });

    event.stopPropagation();
  };

  const items = dayItems.map(item => {
    const checkboxId = `checkbox-${item.value}`;
    return {
      label: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <label
          htmlFor={checkboxId}
          style={{ display: 'flex', alignItems: 'center', height: '24px' }}
          onClick={event => handleClick(event, item.value)}
        >
          <Checkbox
            value={item.value}
            name={item.label}
            checked={value.has(-1) || value.has(item.value)}
            onChange={e => handleChange(e, item.value)}
            onClick={e => e.stopPropagation()}
          />
          <span>{item.label}</span>
        </label>
      ),
      value: item.value.toString(),
    };
  });

  const renderCustomLabel = () => {
    if (value.size > 1 && value.size < 7 && !value.has(-1)) {
      return `${value.size} Days Selected`;
    }
    if (value.size === 1 && !value.has(-1)) {
      return dayItems.find(item => item.value === value.first())?.label;
    }
    return 'All Days Selected';
  };

  return (
    <Dropdown
      label={
        <div style={{ marginTop: '8px' }}>
          {t('.choose_days', intl, __filenamespace)}
        </div>
      }
      fullWidth
      outlined
      rounded
      items={items}
      renderValue={renderCustomLabel}
    />
  );
}

export default DayOfWeekFilter;
