import moment from 'moment-timezone';
import { Record, Set } from 'immutable';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class AmusementCreditRedemption extends Record({
  id: null,
  client_id: null,
  cage_id: '',
  cage_price: 0,
  credit_types: Set(),
  created_at: null,
  updated_at: null,
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        created_at: obj.created_at ? moment(obj.created_at) : null,
        updated_at: obj.updated_at ? moment(obj.updated_at) : null,
      })
    );
  }
}

export default AmusementCreditRedemption;
