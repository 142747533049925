import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, TextField } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import ClientsActions from '../actions';

const onSearchChange = (_event, value) => ClientsActions.searchUpdated(value);

function Filters({ search, isClient, intl }) {
  return (
    <div style={{ padding: 4 }}>
      <Grid container spacing={1}>
        <TextField
          fullWidth
          icon="search"
          onChange={onSearchChange}
          outline={false}
          placeholder={
            isClient
              ? t('.search_label-client', intl, __filenamespace)
              : t('.search', intl, __filenamespace)
          }
          rounded
          value={search}
        />
      </Grid>
    </div>
  );
}

export default injectIntl(Filters);
