import { List, Map, Record, fromJS } from 'immutable';
import Coupon from 'shared/records/Coupon.jsx';
import Discount from 'shared/records/Discount';
import MembershipEventDiscount from 'shared/records/MembershipEventDiscount.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const COUPON_TYPE = 'Coupon';
const MEMBERSHIP_EVENT_DISCOUNT_TYPE = 'MembershipEventDiscount';
const MEMBERSHIP_CREDIT_PASS_DISCOUNT = 'MembershipCreditPassDiscount';
const MEMBERSHIP_RETAIL_DISCOUNT_TYPE = 'MembershipRetailDiscount';
const MANUAL_DISCOUNT_TYPE = 'Manual';
const ACCOUNT_CREDIT_TYPE = 'AccountCredit';

const couponDetails = details => new Coupon(details);

const membershipEventDiscountDetails = details =>
  List(
    details.map(d =>
      Map({
        discount_amount: d.discount_amount,
        customer_user_id: d.customer_user_id,
        membership_event_discount: new MembershipEventDiscount(
          d.membership_event_discount
        ),
      })
    )
  );

const manualDiscount = details =>
  Map({
    // there is not data sent from API, onle discount_by_id
    // discount_amount: d.discount_amount,
    // customer_user_id: d.customer_user_id,
    discount_by_id: details.discount_by_id,
    manual_discount: new Discount({
      value: details.value,
      type: details.discount_type,
    }),
  });

const membershipRetailDiscountDetails = details =>
  List(
    details.map(d =>
      Map({
        discount_amount: d.discount_amount,
        customer_user_id: d.customer_user_id,
        membership_retail_discount: fromJS(d.membership_retail_discount).set(
          'discount',
          new Discount({
            value: d.membership_retail_discount.value,
            type: Discount.PERCENT,
          })
        ),
      })
    )
  );

const detailsForType = (type, details) => {
  switch (type) {
    case COUPON_TYPE:
      return couponDetails(details);
    case MEMBERSHIP_EVENT_DISCOUNT_TYPE:
      return membershipEventDiscountDetails(details);
    case MEMBERSHIP_RETAIL_DISCOUNT_TYPE:
      return membershipRetailDiscountDetails(details);
    case MANUAL_DISCOUNT_TYPE:
      return manualDiscount(details);
    default:
      return Map();
  }
};

class Adjustment extends Record({
  amount: 0,
  type: null,
  details: Map(),
}) {
  constructor(obj = {}) {
    const details = detailsForType(obj.type, obj.details);

    super(merge(obj, { details }));
  }

  isCoupon() {
    return this.type === COUPON_TYPE;
  }

  isManual() {
    return this.type === MANUAL_DISCOUNT_TYPE;
  }

  isMembershipEventDiscount() {
    return this.type === MEMBERSHIP_EVENT_DISCOUNT_TYPE;
  }

  isMembershipCreditPassDiscount() {
    return this.type === MEMBERSHIP_CREDIT_PASS_DISCOUNT;
  }

  isMembershipRetailDiscount() {
    return this.type === MEMBERSHIP_RETAIL_DISCOUNT_TYPE;
  }

  isAccountCredit() {
    return this.type === ACCOUNT_CREDIT_TYPE;
  }
}

export default Adjustment;
