import { isEmpty } from 'shared/utils/SharedUtils';
import { toSnakeCase } from 'shared/utils/ObjectUtils.jsx';

export const toSlug = value =>
  value
    ? toSnakeCase(value)
        .replace(/&/g, ' and ')
        .replace(/\W/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '')
    : '';

export const capitalizeSentence = input =>
  input.replace(/(:?\.\s?|^)([A-Za-z\u00C0-\u1FFF\u2800-\uFFFD])/gi, match =>
    match.toUpperCase()
  );

export const escapeStringRegexp = string => {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};

export const b64EncodeUnicode = string =>
  btoa(
    encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, (_match, p1) =>
      String.fromCharCode(`0x${p1}`)
    )
  );

export const toString = value => (isEmpty(value) ? '' : value.toString());

export const getInitials = name => {
  const words = name.split(' ');
  const initials =
    words.length > 1
      ? words[0][0] + words[words.length - 1][0]
      : words[0].slice(0, 2);

  return initials.toUpperCase();
};

export const capitalizeString = string => {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
