import { Map, OrderedSet } from 'immutable';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';

import { Filters, FilterPeriod } from 'containers/reports/types';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { TransactionLedgerSource } from 'sources';
import { downloadFile } from 'shared/utils/SharedUtils.js';

import { hasFilterDrawerDefaults } from './utils';
import TransactionsLedgerReportActions from './Actions';

class TransactionsLedgerReportStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.debouncedList = debounce(() => {
      this.page = 1;
      this.list();
      this.fetchStatistics();
    }, 600);

    this.bindListeners({
      mounted: TransactionsLedgerReportActions.mounted,
      openFilterDrawer: TransactionsLedgerReportActions.openFilterDrawer,
      closeFilterDrawer: TransactionsLedgerReportActions.closeFilterDrawer,
      downloadList: TransactionsLedgerReportActions.downloadList,
      downloadListSuccess: TransactionsLedgerReportActions.downloadListSuccess,
      downloadListError: TransactionsLedgerReportActions.downloadListError,
      fetchStatistics: TransactionsLedgerReportActions.fetchStatistics,
      fetchStatisticsSuccess:
        TransactionsLedgerReportActions.fetchStatisticsSuccess,
      fetchStatisticsError:
        TransactionsLedgerReportActions.fetchStatisticsError,
      list: [TransactionsLedgerReportActions.list],
      listSuccess: TransactionsLedgerReportActions.listSuccess,
      listError: TransactionsLedgerReportActions.listError,
      applyFilters: TransactionsLedgerReportActions.applyFilters,
      clearFilters: TransactionsLedgerReportActions.clearFilters,
      updateFilter: TransactionsLedgerReportActions.updateFilter,
      removeFilter: TransactionsLedgerReportActions.removeFilter,
      selectPage: TransactionsLedgerReportActions.selectPage,
      selectDay: TransactionsLedgerReportActions.selectDay,
      openColumnDrawer: TransactionsLedgerReportActions.openColumnDrawer,
      closeColumnDrawer: TransactionsLedgerReportActions.closeColumnDrawer,
      changeColumnVisibility:
        TransactionsLedgerReportActions.changeColumnVisibility,
    });
  }

  reset() {
    this.isMobile = false;
    this.isFilterDrawerOpen = false;
    this.isColumnDrawerOpen = false;
    this.isLoadingReport = false;
    this.isLoadingStatistics = false;
    this.statistics = new Map();
    this.defaultFilters = new Map({
      search: '',
      period: {
        value: FilterPeriod.today,
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      },
      sourceTypes: [],
    });
    this.activeFilters = new Map({
      period: this.defaultFilters.get(Filters.PERIOD),
    });
    this.drawerFilters = Map();
    this.isFilterDrawerChanged = false;
    this.columns = new Map({
      transactionId: false,
      clientName: true,
      sourceType: true,
      paymentMethod: true,
      amount: true,
      last4: true,
    });
    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.transactionLedgerIds = new OrderedSet();
    this.filterErrors = new FieldErrors();
  }

  mounted({ isMobile }) {
    this.reset();
    this.isMobile = isMobile;
    this.isLoadingStatistics = true; // Changing here for showing header during on filter change
    this.list();
    this.fetchStatistics();
  }

  openFilterDrawer() {
    this.isFilterDrawerOpen = true;
    this.isFilterDrawerChanged = false;
    this.drawerFilters = Map(this.activeFilters);
  }

  closeFilterDrawer() {
    this.isFilterDrawerOpen = false;
    this.drawerFilters = Map();
  }

  getParams() {
    const params = {};

    if (
      this.activeFilters.get(Filters.PERIOD) &&
      this.activeFilters.get(Filters.PERIOD).value !== FilterPeriod.all_time
    ) {
      params.effective_at_min = this.activeFilters
        .get(Filters.PERIOD)
        .from.toISOString();
      params.effective_at_max = this.activeFilters
        .get(Filters.PERIOD)
        .to.toISOString();
    }
    if (this.activeFilters.get(Filters.SEARCH)) {
      params.search = this.activeFilters.get(Filters.SEARCH);
    }

    if (
      this.activeFilters.get(Filters.SOURCE_TYPES) &&
      this.activeFilters.get(Filters.SOURCE_TYPES).length !==
        this.defaultFilters.get(Filters.SOURCE_TYPES).length
    ) {
      params.source_types = this.activeFilters.get(Filters.SOURCE_TYPES);
    }

    if (
      this.activeFilters.get(Filters.AMOUNT_MIN) ||
      this.activeFilters.get(Filters.AMOUNT_MIN) === 0
    ) {
      params.amount_min = this.activeFilters.get(Filters.AMOUNT_MIN) * 100;
    }

    if (
      this.activeFilters.get(Filters.AMOUNT_MAX) ||
      this.activeFilters.get(Filters.AMOUNT_MAX) === 0
    ) {
      params.amount_max = this.activeFilters.get(Filters.AMOUNT_MAX) * 100;
    }

    return params;
  }

  list() {
    this.isLoadingReport = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      ...this.getParams(),
    };

    TransactionLedgerSource.list({
      params,
      success: TransactionsLedgerReportActions.listSuccess,
      error: TransactionsLedgerReportActions.listError,
    });
  }

  listSuccess({ records, page, perPage, totalCount }) {
    const newIds = records.map(el => el.entryId).toOrderedSet();

    if (this.isMobile) {
      this.transactionLedgerIds =
        page === 1 ? newIds : this.transactionLedgerIds.union(newIds);
    } else {
      this.transactionLedgerIds = newIds;
    }

    this.page = this.isMobile ? page + 1 : page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.isLoadingReport = false;
  }

  listError() {
    this.isLoadingReport = false;
  }

  fetchStatistics() {
    const params = this.getParams();

    uhApiClient.get({
      url: 'ledger/entries_statistics',
      data: params,
      success: TransactionsLedgerReportActions.fetchStatisticsSuccess,
      error: TransactionsLedgerReportActions.fetchStatisticsError,
    });
  }

  fetchStatisticsSuccess(data) {
    this.statistics = new Map(data);
    this.isLoadingStatistics = false;
  }

  fetchStatisticsError(...args) {
    this.isLoadingStatistics = false;
    this.notifyError('error fetching transactions ledger statistics', args);
  }

  applyFilters() {
    if (this.validateFilters()) {
      this.activeFilters = Map(this.drawerFilters);
      this.page = 1;
      this.list();
      this.fetchStatistics();
      this.isFilterDrawerOpen = false;
      this.isFilterDrawerChanged = false;
    }
  }

  clearFilters() {
    if (this.isFilterDrawerOpen) {
      this.drawerFilters = Map(this.activeFilters)
        .delete(Filters.SOURCE_TYPES)
        .delete(Filters.AMOUNT_MIN)
        .delete(Filters.AMOUNT_MAX);
      this.isFilterDrawerChanged = !hasFilterDrawerDefaults(this.activeFilters);
    } else {
      this.activeFilters = Map({
        period: this.defaultFilters.get(Filters.PERIOD),
      });
      this.page = 1;
      this.list();
      this.fetchStatistics();
    }
  }

  updateFilter([key, value]) {
    let newFilters = this.isFilterDrawerOpen
      ? this.drawerFilters
      : this.activeFilters;

    newFilters = newFilters.set(key, value);

    if (key === Filters.AMOUNT_MIN || key === Filters.AMOUNT_MAX) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(value)) {
        newFilters = newFilters.delete(key);
      } else {
        newFilters = newFilters.set(key, value);
      }
    }

    if (this.isFilterDrawerOpen) {
      this.drawerFilters = newFilters;
      this.isFilterDrawerChanged = true;
    } else {
      this.activeFilters = newFilters;
      if (this.validateFilters(key)) {
        if (key === Filters.SEARCH) {
          this.debouncedList();
        } else {
          this.page = 1;
          this.list();
          this.fetchStatistics();
        }
      }
    }
  }

  removeFilter(filterName) {
    if (filterName === Filters.PERIOD) {
      this.activeFilters = this.activeFilters.set(filterName, {
        value: FilterPeriod.today,
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      });
    } else {
      this.activeFilters = this.activeFilters.delete(filterName);
    }
    this.page = 1;
    this.list();
    this.fetchStatistics();
  }

  validateFilters(key) {
    const filters = this.isFilterDrawerOpen
      ? this.drawerFilters
      : this.activeFilters;

    this.filterErrors = this.filterErrors.clear();

    if (
      (key === Filters.AMOUNT_MIN || key === undefined) &&
      filters.get(Filters.AMOUNT_MIN) > filters.get(Filters.AMOUNT_MAX)
    ) {
      this.filterErrors = this.filterErrors.add(
        Filters.AMOUNT_MIN,
        'containers.reports.transactionsLedgerReport.errors.amount_min'
      );
    }

    if (
      (key === Filters.AMOUNT_MAX || key === undefined) &&
      filters.get(Filters.AMOUNT_MAX) < filters.get(Filters.AMOUNT_MIN)
    ) {
      this.filterErrors = this.filterErrors.add(
        Filters.AMOUNT_MAX,
        'containers.reports.transactionsLedgerReport.errors.amount_max'
      );
    }

    return this.filterErrors.isEmpty();
  }

  selectPage([page, perPage]) {
    this.page = page;
    this.perPage = perPage;
    this.list();
  }

  selectDay(value) {
    this.page = 1;

    if (value === 1 || value === -1) {
      this.activeFilters = this.activeFilters.set(Filters.PERIOD, {
        value: FilterPeriod.custom,
        from: this.activeFilters.get(Filters.PERIOD).from.add(value, 'days'),
        to: this.activeFilters.get(Filters.PERIOD).to.add(value, 'days'),
      });
    } else {
      const date = value || moment();

      this.activeFilters = this.activeFilters.set(Filters.PERIOD, {
        value: FilterPeriod.custom,
        from: date.clone().local().startOf('day'),
        to: date.clone().local().endOf('day'),
      });
    }

    this.list();
    this.fetchStatistics();
  }

  openColumnDrawer() {
    this.isColumnDrawerOpen = true;
  }

  closeColumnDrawer() {
    this.isColumnDrawerOpen = false;
  }

  changeColumnVisibility([key, value]) {
    this.columns = this.columns.set(key, value);
  }

  downloadList() {
    this.isDownloading = false;
    TransactionLedgerSource.downloadCsv({
      params: this.getParams(),
      success: TransactionsLedgerReportActions.downloadListSuccess,
      error: TransactionsLedgerReportActions.downloadListError,
    });
  }

  downloadListSuccess(data) {
    downloadFile({
      data,
      fileName: 'transaction_ledger_list.csv',
    });
    this.isDownloading = false;
  }

  downloadListError() {
    this.isDownloading = false;
  }
}

export default alt.createStore(
  TransactionsLedgerReportStore,
  'TransactionsLedgerReportStore'
);
