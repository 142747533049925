import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Retail({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-18.000000, -497.000000)" fill={color}>
          <g transform="translate(0.000000, 60.000000)">
            <path
              d="M26.2505,443.9815 C25.0075,443.9815 24.0005,444.9895 24.0005,446.2335 C24.0005,
            447.4775 25.0075,448.4855 26.2505,448.4855 C27.4925,448.4855 28.5005,447.4775 28.5005,
            446.2335 C28.5005,444.9895 27.4925,443.9815 26.2505,443.9815 M37.8655,450.8685 L30.8725,
            457.8625 C30.5795,458.1555 30.1045,458.1555 29.8125,457.8625 L21.0005,449.0495 L21.0005,
            441.7295 C21.0005,441.3145 21.3365,440.9785 21.7505,440.9785 L29.0725,440.9785 L37.8675,
            449.8075 C38.1595,450.1005 38.1585,450.5755 37.8655,450.8685 M41.5505,449.2525 L30.7705,
            438.4285 C30.4805,438.1385 30.0885,437.9755 29.6795,437.9755 L19.5395,437.9755 C18.6895,
            437.9755 18.0005,438.6655 18.0005,439.5165 L18.0005,449.6555 C18.0005,450.0645 18.1625,
            450.4565 18.4515,450.7455 L29.2545,461.5485 C29.5555,461.8495 29.9485,462.0005 30.3425,
            462.0005 C30.7365,462.0005 31.1305,461.8495 31.4305,461.5485 L41.5485,451.4305 C42.1495,
            450.8295 42.1505,449.8545 41.5505,449.2525"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Retail.defaultProps = {
  color: '#9A9EAD',
};

export default Retail;
