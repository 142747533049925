import * as React from 'react';
import { injectIntl } from 'react-intl';
import ClientActions from 'shared/actions/ClientActions.jsx';
import ManagedProfileEditor from 'user_management/shared/components/ManagedProfileEditor.jsx';
import ProfileCard from 'user_management/shared/components/ProfileCard.jsx';
import Button from '@mui/material/Button';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import VerticalDivider from 'shared/components/VerticalDivider.jsx';

import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  column: {
    flex: 0.5,
    margin: `${LEFT_MARGIN_PIXELS}px 45px 0 45px`,
    maxWidth: SINGLE_COLUMN_WIDTH,
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

function GenderAndAgeText({ gender, age }) {
  if (gender && age) {
    return (
      <div>
        <span style={{ textTransform: 'capitalize' }}>{gender}</span>
        <VerticalDivider />
        {age} yo.
      </div>
    );
  }
  if (gender) {
    return (
      <div style={{ textTransform: 'capitalize', marginTop: 4 }}>{gender}</div>
    );
  }
  if (age) {
    return <div style={{ marginTop: 4 }}>{age} yo.</div>;
  }
  return null;
}

function AthleteCard({ athlete, onRemove, canRemove }) {
  return (
    <ProfileCard
      client={athlete}
      onClick={() => {
        ClientActions.stageClient(athlete);
        ClientActions.validateUser({ requiredFields: [] });
      }}
      subtext={<GenderAndAgeText gender={athlete.gender} age={athlete.age()} />}
      onClearClick={
        canRemove(athlete) && onRemove && (() => onRemove(athlete.id))
      }
    />
  );
}

function ProfileManager({
  athletes,
  canRemove,
  currentProfile,
  errors,
  actions,
  intl,
  isSaving,
  onCancel,
  onChange,
  onRemove,
  onSave,
  style,
  justifyForm,
}) {
  return (
    <div style={merge(styles.root, style, { justifyContent: justifyForm })}>
      <div style={styles.column}>
        <div>
          <h2>{currentProfile.id ? 'Update Profile' : 'Add Profile'}</h2>
          <ManagedProfileEditor
            actionButtons={
              <div>
                <StateChangingButton
                  primary
                  disabled={isSaving}
                  label={currentProfile.id ? 'Save' : 'Add Profile'}
                  style={{ marginTop: 20, marginRight: 8 }}
                  inProgress={isSaving}
                  onClick={onSave}
                />
                {currentProfile.id && (
                  <Button
                    color="default"
                    variant="contained"
                    disabled={isSaving}
                    style={{ marginTop: 20, marginLeft: 8 }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            }
            errors={errors}
            intl={intl}
            isSaving={isSaving}
            onChange={onChange}
            userProfile={currentProfile}
          />
        </div>
      </div>
      <div style={styles.column}>
        <div>
          <div>
            <h2>Profiles</h2>
            <div style={{ margin: '25px 2px 2px 2px' }}>
              {athletes.map(athlete => (
                <AthleteCard
                  key={athlete.id}
                  athlete={athlete}
                  onRemove={onRemove}
                  canRemove={canRemove}
                />
              ))}
            </div>
          </div>
        </div>
        {actions}
      </div>
    </div>
  );
}

ProfileManager.defaultProps = {
  actions: null,
  justifyForm: 'center',
  canRemove: _athlete => true,
};

export default injectIntl(ProfileManager);
