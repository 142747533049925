/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { v4 } from 'uuid';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import BookingButton from 'shared/components/registration/BookingButton.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import PackageSelector from 'shared/components/registration/PackageSelector.jsx';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { Spinner } from '@upperhand/playmaker';
import moment from 'moment-timezone';
import { List } from 'immutable';

const styles = {
  summaryScreen: {
    container: {
      padding: '5px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    needCreditsContainer: {
      width: '100%',
      alignItems: 'center',
      marginTop: '10px',
    },
    creditText: {
      color: uhColors.repeatEnabled,
      fontSize: '14px',
      marginBottom: '5px',
    },
    availableCraditContainer: {
      borderBottom: `1px solid ${uhColors.activeRepeatButton}`,
      paddingBottom: '7px',
      marginTop: '5px',
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
    sessionDropdownContainer: {
      marginTop: '20px',
    },
    selectedProfile: {
      width: '100%',
    },
    selectedCradit: {
      width: '100%',
    },
    selectedProfileText: {
      color: uhColors.activeRepeatButton,
      fontSize: '16px',
      fontWeight: '500',
    },
    profileContainer: {
      width: '100%',
      height: '40px',
      marginTop: '8px',
      alignItems: 'center',
      display: 'flex',
    },
    profile: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      width: '30px',
      height: '30px',
      background: '#cccc',
      borderRadius: '50%',
    },
    profiletext: {
      color: uhColors.textGrey,
      fontSize: '15px',
      alignSelf: 'center',
    },
    bottomBtn: {
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
      verticalAlign: 'middle',
      lineHeight: '90px',
      height: '100px',
    },
    text: {
      color: uhColors.activeRepeatButton,
      fontSize: '14px',
      lineHeight: '1.25',
    },
    tableRow: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      height: '40px',
      padding: '10px',
    },
  },
  loading: {
    root: {
      backgroundColor: uhColors.textWhite,
      height: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
    },
    loading: {
      padding: '16px',
    },
  },
};

function SummaryConflict({
  selectedClientId,
  athletes,
  packagePricingDescription,
  registrationPackage,
  schedulingActions,
  bookableSessions,
  sessionforBooking,
  clientCreditCount,
  onContinueToCart,
  orderItemId,
  price,
  eventDiscount,
  onBookRegistration,
  RegistrationStore,
}) {
  const [sessionValue, setSessionValue] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);
  const selectedProfile = athletes.find(a => a.id === selectedClientId);
  const neededCredits =
    Number(bookableSessions.length) - Number(clientCreditCount);

  const totalCredits = Number(clientCreditCount) + Number(selectedValue);
  const selectedPackage = packagePricingDescription?.template_parameters?.find(
    tmp => tmp?.get('uuid') === registrationPackage.automation_option_uuid
  );
  const isQuantityAvailable = selectedPackage?.get('quantity');
  const isBookableSessionsExceeded =
    Number(bookableSessions.length) > isQuantityAvailable;
  const isTotalCreditsExceeded =
    Number(bookableSessions.length) > Number(totalCredits);
  const isBookingButtonDisabled = isQuantityAvailable
    ? isBookableSessionsExceeded
    : isTotalCreditsExceeded;

  const handleBooking = async () => {
    if (Number(clientCreditCount) < Number(bookableSessions.length)) {
      const updatedWithBookAbleDates = bookableSessions.filter(item =>
        // eslint-disable-next-line array-callback-return, consistent-return
        sessionforBooking.some(({ date, resource_id: resourceId }) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item?.starts_at?._i === date && resourceId !== null) {
            // eslint-disable-next-line no-return-assign, no-param-reassign
            return (item.resource_ids = [...resourceId]);
          }
          // eslint-disable-next-line no-underscore-dangle
          if (item?.starts_at?._i === date && resourceId === null) {
            return item.resource_ids;
          }
        })
      );
      const updatedPackage = registrationPackage.set(
        'tentative_details',
        List(updatedWithBookAbleDates)
      );
      // added to updated pruchasing credits while repeat booking
      const updatedWithCredits = updatedPackage.set(
        'purchasing_credits',
        Number(selectedValue)
      );
      await onContinueToCart(updatedWithCredits, orderItemId);
    } else {
      await onBookRegistration();
    }
  };

  if (RegistrationStore?.isLoading) {
    return (
      <div style={{ ...styles.loading.root, ...styles.loading.loading }}>
        <Spinner />
      </div>
    );
  }
  return (
    <div style={styles.summaryScreen.container}>
      <div style={styles.summaryScreen.selectedProfile}>
        <span style={styles.summaryScreen.selectedProfileText}>
          selected profile
        </span>
      </div>
      <FlexBoxCenter style={{ height: 43 }}>
        <UserAvatar user={selectedProfile} style={{ marginRight: '1rem' }} />
        {selectedProfile.name()}
      </FlexBoxCenter>
      <div style={styles.summaryScreen.sessionDropdownContainer}>
        <div
          role="presentation"
          onClick={() => setSessionValue(!sessionValue)}
          style={{
            borderBottom: `0.7px solid ${uhColors.activeRepeatButton}`,
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            {bookableSessions.length} Sessions Scheduled
          </span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '16px', fontWeight: '500' }}>Details</span>
            <IconButton onClick={() => setSessionValue(!sessionValue)}>
              <ChevronRight sx={{ color: uhColors.activeBlue }} />
            </IconButton>
          </div>
        </div>
        {sessionValue &&
          bookableSessions?.map((data, _i) => {
            const { starts_at: startAt } = data;
            const time = moment(startAt).format('hh:mm a');
            // eslint-disable-next-line no-underscore-dangle
            const startAtD = startAt?._i;
            return (
              <div key={v4()} style={merge(styles.summaryScreen.tableRow)}>
                <span>{startAtD}</span>
                <span>{time}</span>
              </div>
            );
          })}
        <div style={{ height: '20px' }} />
      </div>
      {Number(clientCreditCount) < Number(bookableSessions.length) && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '20px',
            }}
          >
            <div
              style={{ borderBottom: '1px solid #cccc', paddingBottom: '10px' }}
            >
              <span>{clientCreditCount} Available Credits</span>
            </div>
            <div style={{ marginTop: '10px' }}>
              <span style={{ fontSize: '16px', fontWeight: '500' }}>
                {Number(neededCredits)} Credit(s) Needed
              </span>
            </div>
            <div style={{ marginTop: '10px' }}>
              <span style={{ fontSize: '13px', color: uhColors.textGrey }}>
                You need {Number(neededCredits)} credit to complete your
                registration. Select a credit package below.
              </span>
            </div>
          </div>
          <div style={{ marginTop: '15px' }}>
            <div style={styles.summaryScreen.selectedCradit}>
              <span style={styles.summaryScreen.selectedProfileText}>
                Credit Packages
              </span>
            </div>
            <div style={{ height: '5px' }} />
            <PackageSelector
              discount={eventDiscount && eventDiscount.discount}
              packagePricingDescription={packagePricingDescription}
              price={price}
              selectedValue={selectedValue}
              registrationPackage={registrationPackage}
              onAutomationOptionSelect={(_, __, uuid) => {
                schedulingActions.automatedPackageSelected(uuid);
              }}
              onDefaultOptionSelect={(_, __, size) => {
                const result = Number(size) + Number(clientCreditCount);
                setSelectedValue(size);
                if (result >= bookableSessions.length) {
                  schedulingActions.defaultPackageSelected(result);
                }
              }}
              style={{ marginBottom: 10 }}
            />
          </div>
        </>
      )}

      <div style={styles.summaryScreen.bottomBtn}>
        <BookingButton
          disable={isBookingButtonDisabled}
          title={
            Number(clientCreditCount) < Number(bookableSessions.length)
              ? 'ADD TO CART'
              : 'CONFIRM BOOKING'
          }
          handleClicked={handleBooking}
        />
      </div>
    </div>
  );
}

export default SummaryConflict;
