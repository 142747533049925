import debounce from 'lodash.debounce';
import { List, Map } from 'immutable';
import DateRange, { THIS_MONTH } from 'shared/records/DateRange';
import WaiversReportActions from 'reporting/waivers/actions/WaiversReportActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

export const WAIVERS_REPORT_NAMES = {
  CUSTOM_ACCEPTANCES: 'custom_acceptances',
  UH_ACCEPTANCES: 'uh_acceptances',
  PAST_DUE_ACCEPTANCES: 'past_due_acceptances',
  POLICY_REPORT: 'policy_report',
  WAIVERS_FOR_BILLING: 'waivers_for_billing',
};

class WaiversReportStore extends UpperHandStore {
  constructor() {
    super();

    this.customAcceptances = 0;
    this.pastDueAcceptances = 0;
    this.policyReport = List();
    this.uhAcceptances = 0;
    this.waiversForBilling = List();

    this.isLoading = true;
    this.section = null;

    this.filters = Map({
      dateRange: new DateRange({ value: THIS_MONTH }),
    });

    this.filtersApplied = false;

    this.debouncedReloadWithNewFilters = debounce(
      this.reloadWithNewFilters,
      500
    );

    this.bindListeners({
      listWaivers: WaiversReportActions.MOUNTED,

      fetchCustomAcceptancesSuccess:
        WaiversReportActions.FETCH_CUSTOM_ACCEPTANCES_SUCCESS,
      fetchCustomAcceptancesError:
        WaiversReportActions.FETCH_CUSTOM_ACCEPTANCES_ERROR,

      fetchPastDueAcceptancesSuccess:
        WaiversReportActions.FETCH_PAST_DUE_ACCEPTANCES_SUCCESS,
      fetchPastDueAcceptancesError:
        WaiversReportActions.FETCH_PAST_DUE_ACCEPTANCES_ERROR,

      fetchPolicyReportSuccess:
        WaiversReportActions.FETCH_POLICY_REPORT_SUCCESS,
      fetchPolicyReportError: WaiversReportActions.FETCH_POLICY_REPORT_ERROR,

      fetchUhAcceptancesSuccess:
        WaiversReportActions.FETCH_UH_ACCEPTANCES_SUCCESS,
      fetchUhAcceptancesError: WaiversReportActions.FETCH_UH_ACCEPTANCES_ERROR,

      fetchWaiversForBillingSuccess:
        WaiversReportActions.FETCH_WAIVERS_FOR_BILLING_SUCCESS,
      fetchWaiversForBillingError:
        WaiversReportActions.FETCH_WAIVERS_FOR_BILLING_ERROR,

      updateDateFilter: WaiversReportActions.DATE_FILTER_UPDATED,
      resetFilters: WaiversReportActions.FILTERS_CLEARED,

      downloadReport: WaiversReportActions.DOWNLOAD_REPORT,
      downloadReportSuccess: WaiversReportActions.DOWNLOAD_REPORT_SUCCESS,
      downloadReportError: WaiversReportActions.DOWNLOAD_REPORT_ERROR,
    });
  }

  _getWaiversRequestParam() {
    const { startTime, endTime } = this.filters.get('dateRange');
    return {
      start_time: startTime.format(),
      end_time: endTime.format(),
    };
  }

  setLoadingState() {
    this.isLoading =
      this.areLoadingCustomAcceptances ||
      this.areLoadingPastDueAcceptance ||
      this.isLoadingPolicyReport ||
      this.areLoadingUHAcceptances ||
      this.areLoadinghWaiversForBilling;
  }

  listWaivers() {
    this.isLoading = true;
    this.fetchCustomAcceptances();
    this.fetchPastDueAcceptances();
    this.fetchPolicyReport();
    this.fetchUhAcceptances();
    this.fetchWaiversForBilling();
  }

  fetchCustomAcceptances() {
    this.areLoadingCustomAcceptances = true;
    uhApiClient.get({
      url: 'analytics/waivers/custom_acceptances',
      data: this._getWaiversRequestParam(),
      success: WaiversReportActions.fetchCustomAcceptancesSuccess,
      error: WaiversReportActions.fetchCustomAcceptancesError,
    });
  }

  fetchPastDueAcceptances() {
    this.areLoadingPastDueAcceptances = true;

    uhApiClient.get({
      url: 'analytics/waivers/past_due_acceptances',
      data: this._getWaiversRequestParam(),
      success: WaiversReportActions.fetchPastDueAcceptancesSuccess,
      error: WaiversReportActions.fetchPastDueAcceptancesError,
    });
  }

  fetchPolicyReport() {
    this.isLoadingPolicyReport = true;

    uhApiClient.get({
      url: 'analytics/waivers/policy_report',
      data: this._getWaiversRequestParam(),
      success: WaiversReportActions.fetchPolicyReportSuccess,
      error: WaiversReportActions.fetchPolicyReportError,
    });
  }

  fetchUhAcceptances() {
    this.areLoadingUHAcceptances = true;

    uhApiClient.get({
      url: 'analytics/waivers/uh_acceptances',
      data: this._getWaiversRequestParam(),
      success: WaiversReportActions.fetchUhAcceptancesSuccess,
      error: WaiversReportActions.fetchUhAcceptancesError,
    });
  }

  fetchWaiversForBilling() {
    this.areLoadinghWaiversForBilling = true;

    uhApiClient.get({
      url: 'analytics/waivers/waivers_for_billing',
      data: this._getWaiversRequestParam(),
      success: WaiversReportActions.fetchWaiversForBillingSuccess,
      error: WaiversReportActions.fetchWaiversForBillingError,
    });
  }

  fetchCustomAcceptancesSuccess({ count }) {
    this.customAcceptances = count || 0;
    this.areLoadingCustomAcceptances = false;
    this.setLoadingState();
  }

  fetchCustomAcceptancesError() {
    this.areLoadingCustomAcceptances = false;
    this.notifyError('error fetching waivers', arguments);
  }

  fetchPastDueAcceptancesSuccess({ count }) {
    this.pastDueAcceptances = count || 0;
    this.areLoadingPastDueAcceptances = false;
    this.setLoadingState();
  }

  fetchPastDueAcceptancesError() {
    this.areLoadingPastDueAcceptances = false;
    this.notifyError('error fetching waivers', arguments);
  }

  fetchPolicyReportSuccess({ records }) {
    this.policyReport = List(records);
    this.isLoadingPolicyReport = false;
    this.setLoadingState();
  }

  fetchPolicyReportError() {
    this.isLoadingPolicyReport = false;
    this.notifyError('error fetching waivers', arguments);
  }

  fetchUhAcceptancesSuccess({ count }) {
    this.uhAcceptances = count || 0;
    this.areLoadingUHAcceptances = false;
    this.setLoadingState();
  }

  fetchUhAcceptancesError() {
    this.areLoadingUHAcceptances = false;
    this.notifyError('error fetching waivers', arguments);
  }

  fetchWaiversForBillingSuccess({ records }) {
    this.waiversForBilling = List(records);
    this.areLoadinghWaiversForBilling = false;
    this.setLoadingState();
  }

  fetchWaiversForBillingError() {
    this.areLoadinghWaiversForBilling = false;
    this.notifyError('error fetching waivers', arguments);
  }

  updateDateFilter({ value, from, to }) {
    this.filters = this.filters.set(
      'dateRange',
      new DateRange({ value, startDate: from, endDate: to })
    );
    this.reloadWithNewFilters();
  }

  resetFilters() {
    this.filters = this.filters.set(
      'dateRange',
      new DateRange({ value: THIS_MONTH })
    );
    this.reloadWithNewFilters();
  }

  reloadWithNewFilters() {
    this.filtersApplied =
      this.filters.getIn(['dateRange', 'value']) !== THIS_MONTH;

    this.listWaivers();

    // Manually emit a change here, so that the UI rerenders when using the
    // debounced function.
    this.emitChange();
  }

  downloadReport(section) {
    this.section = section;
    return uhApiClient.get({
      url: 'analytics/waivers/waivers_csv',
      headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
      data: merge(this._getWaiversRequestParam(), { section }),
      success: WaiversReportActions.downloadReportSuccess,
      error: WaiversReportActions.downloadReportError,
    });
  }

  downloadReportSuccess(data) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const blob = new Blob([data], { type: 'application/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `waivers_report_${this.section}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.section = null;
  }

  downloadReportError() {
    this.notifyError('error downloading report', arguments);
  }
}

export default alt.createStore(WaiversReportStore, 'WaiversReportStore');
