import { OrderedSet } from 'immutable';
import Contact from 'event_mgmt/shared/records/Contact.jsx';
import ContactActions from 'event_mgmt/shared/actions/ContactActions.jsx';
import MarketingActions from 'event_mgmt/shared/actions/MarketingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class ContactStore extends UpperHandStore {
  constructor() {
    super();

    this.allContacts = OrderedSet();
    this.selectedContacts = OrderedSet();

    this.bindListeners({
      handleList: ContactActions.LIST,
      handleListSuccess: ContactActions.LIST_SUCCESS,
      handleListError: ContactActions.LIST_ERROR,

      handleSelection: ContactActions.SELECTION,
      clearSelectedContacts: MarketingActions.POST_SUCCESS,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleList(args) {
    uhApiClient.get({
      url: '/contacts',
      data: merge({ per_page: 100 }, args),
      success: ContactActions.listSuccess,
      error: ContactActions.listError,
    });
  }

  handleListSuccess(data) {
    this.allContacts = this.sorted(
      this.allContacts.withMutations(contacts => {
        data.contacts.forEach(contact => {
          contacts.add(new Contact(contact));
        });
      })
    );
    if (data.total_count > this.allContacts.size) {
      ContactActions.list.defer({
        page: data.page + 1,
      });
    }
  }

  handleListError(...args) {
    this.notifyError('error while listing contacts', args);
  }

  handleSelection(newSelection) {
    this.selectedContacts = this.sorted(newSelection);
  }

  clearSelectedContacts() {
    this.selectedContacts = OrderedSet();
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(set) {
    return set.sortBy(x => x.name);
  }
}

export default alt.createStore(ContactStore, 'ContactStore');
