/* eslint-disable react/require-default-props */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@upperhand/playmaker';

import Gear from 'shared/components/icons/Gear.jsx';

function ColumnsMenu({ active = false, className = '', menuItems = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={className}>
      <Gear
        color={active ? 'var(--color-secondary' : 'var(--color-primary)'}
        onClick={e => setAnchorEl(e.currentTarget)}
      />
      <Menu
        classes={{
          root: 'columns-settings',
          content: 'columns-settings__content',
        }}
        anchor={anchorEl}
        items={menuItems}
        onClose={() => setAnchorEl(null)}
      />
    </div>
  );
}

ColumnsMenu.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
};

export default memo(ColumnsMenu);
