import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Flag(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="15px" height="17px" viewBox="0 0 15 17" {...props}>
      <g
        id="New-Event-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="mobile_refresh" transform="translate(-14.000000, -13.000000)">
          <g id="baseline-flag-24px" transform="translate(9.000000, 9.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <polygon
              id="Path"
              fill="var(--color-white)"
              fillRule="nonzero"
              points="14.4 6 14 4 5 4 5 21 7 21 7 14 12.6 14 13 16 20 16 20 6"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Flag;
