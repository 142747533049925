export default alt.generateActions(
  'updateStore',
  'list',
  'listSuccess',
  'listError',
  'inlineEditorToggled',
  'createOrUpdate',
  'createOrUpdateSuccess',
  'createOrUpdateError',
  'deleteRequested',
  'deleteCancelled',
  'delete',
  'deleteSuccess',
  'deleteError',
  'stageLocation',
  'clearLocation',
  'setBlackoutDates',
  'deleteBlackoutDates',
  'saveBlackoutDates'
);
