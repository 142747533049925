import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner, Button } from '@upperhand/playmaker';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import DiscountValue from 'shared/components/DiscountValue.jsx';
import SessionCheckBoxList from 'shared/components/checkout/_SessionCheckBoxList.jsx';
import {
  FlexBox,
  FlexBoxJustify,
  FlexBoxJustifyCenter,
} from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  checkboxContainer: {
    padding: '2px 18px 2px 10px',
  },

  membershipDiscountBanner: {
    alignItems: 'center',
    backgroundColor: '#E5E5E5',
    minHeight: 32,
    fontWeight: 'bold',
    fontSize: 15,
    padding: '2px 18px 2px 10px',
    marginBottom: 10,
  },

  nameContainer: {
    padding: '2px 0px 2px 10px',
  },
};

function AttendeeSessionExpander({
  allDayAvailable,
  allDayChecked,
  athlete,
  athleteSessions,
  eventDiscount,
  expanded,
  fullPrice,
  isSessionAvailable,
  onToggleExpand,
  schedulingActions,
  singleSessionPrice,
  sessions,
  sessionsShowMore,
  sessionsLoadingMore,
  sessionAvailability,
  onSessionsLoadMore,
}) {
  const isRemovable = !athleteSessions.some(s => s.get('purchased'));
  const sspPrice = eventDiscount
    ? eventDiscount.discount.applyTo(singleSessionPrice)
    : singleSessionPrice;

  return (
    <Paper style={{ marginTop: 10, marginBottom: 10 }}>
      <FlexBoxJustify style={styles.nameContainer} onClick={onToggleExpand}>
        <AvatarWithName user={athlete} />
        <FlexBox>
          <IconButton
            sx={{
              svg: {
                transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
              },
            }}
          >
            <ExpandMoreIcon sx={{ color: uhColors.activeBlue }} />
          </IconButton>
          {isRemovable && (
            <IconButton
              onClick={() =>
                schedulingActions.singleSessionAttendeeRemoved(athlete.id)
              }
            >
              <ClearIcon sx={{ color: uhColors.iconLightGrey }} />
            </IconButton>
          )}
        </FlexBox>
      </FlexBoxJustify>

      {eventDiscount && (
        <FlexBoxJustify
          style={merge(
            styles.membershipDiscountBanner,
            styles.checkboxContainer
          )}
        >
          <div style={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id={messageId('.member_discount', __filenamespace)}
            />
          </div>
          <DiscountValue discount={eventDiscount.discount} />
        </FlexBoxJustify>
      )}

      {expanded && (
        <div style={styles.checkboxContainer}>
          <SessionCheckBoxList
            allDayAvailable={allDayAvailable}
            allDayChecked={allDayChecked}
            athleteId={athlete.id}
            athleteSessions={athleteSessions}
            fullPrice={
              eventDiscount
                ? eventDiscount.discount.applyTo(fullPrice)
                : fullPrice
            }
            isSessionAvailable={isSessionAvailable}
            schedulingActions={schedulingActions}
            singleSessionPrice={sspPrice}
            sessionAvailability={sessionAvailability}
            sessions={sessions}
          />
          <FlexBoxJustifyCenter>
            <>
              {!sessionsLoadingMore && sessionsShowMore && (
                <Button type="tertiary" onClick={onSessionsLoadMore}>
                  View more
                </Button>
              )}
              {sessionsLoadingMore && <Spinner />}
            </>
          </FlexBoxJustifyCenter>
        </div>
      )}
    </Paper>
  );
}

export default AttendeeSessionExpander;
