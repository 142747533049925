import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AltContainer from 'alt-container';
import { Tabs, Grid } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import AddMoreEventCard from 'cart/index/components/_AddMoreEventCard.jsx';
import CheckoutCard from 'cart/index/components/_CheckoutCard.jsx';
import EmptyCartIcon from 'shared/components/icons/empty_states/Cart.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import EventToolbar from 'event_mgmt/shared/components/_EventToolbar.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ItemCard from 'shared/components/checkout/ItemCard.jsx';
import OrderCompleteIcon from 'shared/components/icons/empty_states/OrderComplete.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import PurchaseDrawer from 'credit_passes/components/PurchaseDrawer.jsx';
import PurchaseDrawerMembership from 'memberships/components/PurchaseDrawer.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import MembershipPermissionStore from 'memberships/stores/MembershipPermissionStore.jsx';
import MembershipPurchasingStore from 'memberships/stores/MembershipPurchasingStore.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { SUMMARY } from 'shared/components/checkout/CheckoutStepper.jsx';

import SecondaryDrawerStore from 'shared/stores/SecondaryDrawerStore.jsx';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';
import CreditPassSpecificEventsListStore from 'credit_passes/stores/CreditPassSpecificEventsListStore.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import CreditPassPurchasingStore from 'credit_passes/stores/CreditPassPurchasingStore.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CreditPassPurchasingActions from 'credit_passes/actions/CreditPassPurchasingActions.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import MembershipPurchasingActions from 'memberships/actions/MembershipPurchasingActions.jsx';
import { PaymentActions } from 'containers/payments';

const styles = {
  checkoutCard: {
    largeScreen: {
      position: 'absolute',
      top: '-56', // Height of checkout card header with text
      right: '3rem',
    },
    smallScreen: {
      margin: '2rem auto 0 auto',
      width: '90%',
    },
  },
  container: {
    display: 'grid',
    marginLeft: '2rem',
    position: 'relative',
  },
  header: {
    backgroundColor: uhColors.backgroundGray,
    padding: '1rem 3rem 0',
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
  },
  completeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  orderItemCard: {
    margin: '2rem auto 0 auto',
    width: '90%',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
  },
  addMoreBtn: {
    width: '100%',
    margin: 0,
    height: 70,
    marginBottom: 15,
  },
  continueBtn: {
    height: '70px',
    fontSize: '16px',
  },
};

const OrderComplete = injectIntl(({ intl }) => {
  React.useEffect(
    () => () => {
      PaymentActions.reset();
      CartActions.resetAfterSuccess();
      CartActions.fetch({});
    },
    []
  );

  return (
    <EmptyState
      image={<OrderCompleteIcon />}
      headerText={t('.order_complete_header', intl, __filenamespace)}
      messageText={t('.order_complete_message', intl, __filenamespace)}
      primaryAction={{
        label: t('.view_my_schedule', intl, __filenamespace),
        href: customerScopedRoute('my_profile'),
      }}
      secondaryAction={{
        label: t('.browse_events', intl, __filenamespace),
        href: customerScopedRoute('events'),
      }}
    />
  );
});

class Cart extends React.Component {
  handleEditClick = orderItem => {
    const {
      creditPassDataStore: { creditPasses },
    } = this.props;
    const creditPassId = orderItem?.getIn(
      ['orderable', 'credit_pass_id'],
      null
    );
    const creditPass = creditPasses?.get(creditPassId, null);

    CreditPassPurchasingActions.purchaseClicked({
      buyerId: orderItem?.get('buyer_id', null),
      creditPass,
      orderItem,
    });
  };

  handleMembershipEdit = orderItem => {
    const { membershipListingStore } = this.props;
    const { records: memberships } = membershipListingStore;
    const membershipId = orderItem?.getIn(['orderable', 'membership_id'], null);
    const membership = memberships?.find(m => m.id === membershipId);

    MembershipPurchasingActions.purchaseClicked({
      membership,
      buyerId: orderItem?.get('buyer_id', null),
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getItemEditHref = orderItem =>
    !orderItem?.isCreditPassItem() && !orderItem?.isMembershipItem()
      ? orderItem.editPath()
      : false;

  // eslint-disable-next-line class-methods-use-this
  getItemViewHref = orderItem =>
    !orderItem?.isCreditPassItem() && !orderItem?.isMembershipItem()
      ? orderItem.show_path
      : false;

  getItemEditHandler = orderItem => {
    if (orderItem?.isCreditPassItem()) return this.handleEditClick(orderItem);
    if (orderItem?.isMembershipItem())
      return this.handleMembershipEdit(orderItem);
    return () => null;
  };

  onCheckoutEdit = orderItem =>
    orderItem?.isCreditPassItem() || orderItem?.isMembershipItem()
      ? this.getItemEditHandler(orderItem)
      : redirectTo({ path: orderItem?.get('show_path', '') });

  getCartItems = () => {
    const { cart } = this.cartStore();
    const { order_items: items } = cart;
    const openBookingItems = items.filter(
      item => item.orderable.schedule_type === 'open_booking'
    );
    const restItems = items.filter(
      item => item.orderable.schedule_type !== 'open_booking'
    );

    return openBookingItems.concat(restItems);
  };

  cartStore = () => {
    const { cart } = this.props;
    return cart;
  };

  largeScreenLayout = () => {
    const { cart, checkoutStep, showContinueToCheckout } = this.cartStore();
    const {
      payableTo,
      registrationForms,
      atdStore,
      athletes,
      membershipListingStore,
      staffDataStore: { staff },
      membershipViewingStore: {
        record: { membership_event_discounts: membershipEventDiscounts },
      },
      sessionDataStore: { sessions },
      eventDataStore: { events },
    } = this.props;
    const showOrderComplete = checkoutStep === SUMMARY;
    const orderItems = this.getCartItems();

    return (
      <div>
        {currentUser().isClient() && <EventToolbar />}
        <div style={styles.header}>
          <div style={{ fontSize: '16pt', fontWeight: 700 }}>
            {showContinueToCheckout ? (
              <FormattedMessage id={messageId('.cart', __filenamespace)} />
            ) : (
              <FormattedMessage id={messageId('.checkout', __filenamespace)} />
            )}
          </div>
          <div style={{ fontSize: '12pt' }}>
            <FormattedMessage
              id={messageId('.n_items', __filenamespace)}
              values={{ n: cart.order_items.size }}
            />
            &nbsp;|&nbsp;
            <FormattedCurrency value={cart.due_immediately} fromCents />
          </div>
        </div>
        <Divider sx={{ margin: '1rem 0 2rem' }} />
        <div style={styles.container}>
          <Grid container spacing={1}>
            {showOrderComplete && (
              <Grid item xs={9}>
                <div style={styles.completeContainer}>
                  <OrderComplete />
                </div>
              </Grid>
            )}
            {!showOrderComplete && (
              <Grid item xs={9}>
                <div style={styles.cardsContainer}>
                  {orderItems.map(orderItem => (
                    <ItemCard
                      key={orderItem.id}
                      event={events.get(
                        orderItem.getIn(['orderable', 'event_id'])
                      )}
                      membershipEventDiscounts={membershipEventDiscounts}
                      isBeforeCheckout={showContinueToCheckout}
                      staff={staff}
                      allProfiles={athletes.allAthletes}
                      automations={atdStore.descriptions}
                      sessions={sessions}
                      orderItem={orderItem}
                      editHref={
                        orderItem.isOpenBookingItem()
                          ? undefined
                          : this.getItemEditHref(orderItem)
                      }
                      onEdit={() =>
                        orderItem.isOpenBookingItem()
                          ? CartActions.toggleCheckoutStep()
                          : this.getItemEditHandler(orderItem)
                      }
                      onRemove={() => CartActions.remove(orderItem.id)}
                      onUpdate={CartActions.update}
                      viewHref={this.getItemViewHref(orderItem)}
                      onView={() => this.getItemEditHandler(orderItem)}
                      style={{ height: 'min-content' }}
                    />
                  ))}
                </div>
              </Grid>
            )}
            <Grid item container spacing={1} xs={3}>
              <Grid item xs={12}>
                {showContinueToCheckout && (
                  <>
                    <AddMoreEventCard
                      mainFontSize={20}
                      descriptionFontSize={14}
                      style={styles.addMoreBtn}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      sx={styles.continueBtn}
                      onClick={CartActions.toggleCheckoutStep}
                    >
                      <FormattedMessage
                        id={messageId('.checkout_continue', __filenamespace)}
                      />
                    </Button>
                  </>
                )}
                {!showContinueToCheckout && (
                  <CheckoutCard
                    onItemEdit={this.onCheckoutEdit}
                    athletes={athletes}
                    automations={atdStore.descriptions}
                    cart={cart}
                    cartStore={this.cartStore()}
                    memberships={membershipListingStore.records}
                    payableTo={payableTo}
                    registrationForms={registrationForms}
                    step={checkoutStep}
                    style={{ width: '100%' }}
                    totalDueNow={cart.due_immediately}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  smallScreenLayout = () => {
    const { cart, showContinueToCheckout } = this.cartStore();
    const orderItems = this.getCartItems();
    const {
      atdStore,
      athletes,
      membershipListingStore,
      payableTo,
      registrationForms,
      staffDataStore: { staff },
      membershipViewingStore: {
        record: { membership_event_discounts: membershipEventDiscounts },
      },
      sessionDataStore: { sessions },
    } = this.props;

    const selectedTab = showContinueToCheckout ? 'cart-tab' : 'order-tab';

    return (
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={CartActions.toggleCheckoutStep}
        tabItems={[
          {
            label: `Cart (${cart.order_items.size})`,
            value: 'cart-tab',
            content: (
              <div>
                {this.cartStore().checkoutStep === SUMMARY ? (
                  <OrderComplete />
                ) : (
                  <div style={{ paddingBottom: 20 }}>
                    {orderItems.map(orderItem => (
                      <ItemCard
                        isBeforeCheckout
                        key={orderItem.id}
                        membershipEventDiscounts={membershipEventDiscounts}
                        staff={staff}
                        allProfiles={athletes.allAthletes}
                        automations={atdStore.descriptions}
                        sessions={sessions}
                        orderItem={orderItem}
                        onRemove={() => CartActions.remove(orderItem.id)}
                        onUpdate={CartActions.update}
                        editHref={this.getItemEditHref(orderItem)}
                        onEdit={() => this.getItemEditHandler(orderItem)}
                        viewHref={this.getItemViewHref(orderItem)}
                        onView={() => this.getItemEditHandler(orderItem)}
                        style={styles.orderItemCard}
                      />
                    ))}
                    <AddMoreEventCard
                      descriptionFontSize={15}
                      mainFontSize={20}
                      style={styles.orderItemCard}
                    />
                    <div style={styles.orderItemCard}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={CartActions.toggleCheckoutStep}
                        style={{ height: 50 }}
                      >
                        Continue to Check out
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ),
          },
          {
            label: 'Order',
            value: 'order-tab',
            content: (
              <div>
                <CheckoutCard
                  onItemEdit={this.getItemEditHandler}
                  athletes={athletes}
                  automations={atdStore.descriptions}
                  cart={cart}
                  cartStore={this.cartStore()}
                  memberships={membershipListingStore.records}
                  payableTo={payableTo}
                  registrationForms={registrationForms}
                  step={this.cartStore().checkoutStep}
                  style={styles.checkoutCard.smallScreen}
                  totalDueNow={cart.due_immediately}
                />
              </div>
            ),
          },
        ]}
      />
    );
  };

  render() {
    const { intl } = this.props;

    if (this.cartStore().cart.order_items.size > 0) {
      return (
        <>
          <Backdrop
            sx={{ zIndex: 9999, color: 'var(--color-white)' }}
            open={this.cartStore().isUpdatingCart}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ResponsiveElement
            largeScreen={this.largeScreenLayout}
            smallScreen={this.smallScreenLayout}
          />
          <AltContainer
            stores={{
              drawer: SecondaryDrawerStore,
              creditPassListingStore: CreditPassListingStore,
              creditPassSpecificEventsListStore:
                CreditPassSpecificEventsListStore,
              CurrentContextStore,
              eventTypeListingStore: EventTypeListingStore,
              creditPassPurchasingStore: CreditPassPurchasingStore,
              cartStore: CartStore,
            }}
            actions={SecondaryDrawerActions}
          >
            <PurchaseDrawer
              title={t('.credit_pass_drawer_title', intl, __filenamespace)}
            />
          </AltContainer>
          <AltContainer
            stores={{
              drawer: SecondaryDrawerStore,
              eventTypeListingStore: EventTypeListingStore,
              membershipPurchasing: MembershipPurchasingStore,
              athleteStore: AthleteStore,
              cartStore: CartStore,
              isPermittedToPurchase: () => ({
                store: MembershipPermissionStore,
                value:
                  MembershipPermissionStore.getState().isPermittedToPurchase,
              }),
              retailCategories: () => ({
                store: RetailCategoryListingStore,
                value: RetailCategoryListingStore.getState().retailCategories,
              }),
              eventTypes: () => ({
                store: EventTypeListingStore,
                value: EventTypeListingStore.getState().eventTypes,
              }),
              creditPasses: () => ({
                store: CreditPassListingStore,
                value: CreditPassListingStore.getState().records,
              }),
            }}
            actions={SecondaryDrawerActions}
          >
            <PurchaseDrawerMembership
              title={t('.membership_drawer_title', intl, __filenamespace)}
              disableViewInCart
            />
          </AltContainer>
        </>
      );
    }

    return (
      <EmptyState
        image={<EmptyCartIcon />}
        headerText={t('.empty_state_header', intl, __filenamespace)}
        messageText={t('.empty_state_message', intl, __filenamespace)}
        primaryAction={{
          label: t('.view_details', intl, __filenamespace),
          href: customerScopedRoute('/events'),
        }}
      />
    );
  }
}

export default injectIntl(Cart);
