/* eslint-disable no-param-reassign */
import Honeybadger from '@honeybadger-io/js';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const sendErrorsToHoneybadger =
  window.environment !== 'local' && window.environment !== 'development';

export const resetLogContext = () => {
  const context = {
    userId: '',
    userEmail: '',
    userName: '',
    customerId: '',
    customerName: '',
    tags: '',
  };
  const customer = currentCustomer();

  if (customer && customer.id) {
    context.customerId = customer.id;
    context.customerName = customer.name;
  }

  const user = currentUser();
  if (user && user.id) {
    context.userId = user.id;
    context.userEmail = user.email;
    context.userName = user.name;
    context.role = user.role;
  }

  // if we have fullstory(FS) setup include the link to a replay
  if (window.FS && window.FS.getCurrentSessionURL) {
    context.mostRecentFullStorySession = window.FS.getCurrentSessionURL();
  }

  Honeybadger.clear();
  Honeybadger.setContext(context);
};

export const init = () => {
  if (!sendErrorsToHoneybadger) {
    return null;
  }

  const config = {
    apiKey: window.honeybadgerKey,
    reportData: true,
    // project_root : "/",
    // onerror: true, // default is true to report all onerror calls.
    environment: window.environment,
    revision: window.version,
    ssl: true,
  };

  const honeybadger = Honeybadger.configure(config);
  resetLogContext();

  return honeybadger;
};

export const logError =
  component =>
  (error, info = {}) => {
    const defaultInfo = {
      // message: 'we get this from the error provided',
      // name: 'ErrorLogger', // this is the Errortype EX: Error, TypeError, SyntaxError
      component,
      action: 'LoggerAction',
      context: {},
      // fingerprint: 'This unique string will group similar errors together',
      // projectRoot: 'https://www.example.com/',
      // params: { key: 'value' },
      // cookies: { key: 'value' },
    };

    if (sendErrorsToHoneybadger) {
      Honeybadger.notify(error, { ...defaultInfo, ...info });
    } else {
      console.error(component, error); // eslint-disable-line no-console
    }
  };

// add to window for debug use only
window.logErrorTest = logError('ManualTest');

const ignoreErrors = notice => {
  // ignore this error from the instagram browser.
  if (
    /Instagram/i.test(window.navigator.userAgent) &&
    /_AutofillCallbackHandlEr/i.test(notice.message)
  ) {
    return false;
  }

  // ignore specific bots. if bots are having errors i dont want to get a honeybadger error for it.
  // https://developers.google.com/search/docs/advanced/crawling/overview-google-crawlers
  // https://support.apple.com/en-us/HT204683
  // https://webmaster.petalsearch.com/site/petalbot
  // dont know where the documenation is for facebot... asuming this is facebook
  const bots = [/googlebot/i, /applebot/i, /petalbot/i, /facebot/i];

  if (bots.find(bot => bot.test(window.navigator.userAgent))) {
    return false;
  }

  // if we have a POTENTIAL bot that is not one listed above tag it to call attention to it.
  if (/bot/i.test(window.navigator.userAgent)) {
    notice.tags = notice.tags.concat('robot');
  }

  // ignore outlook safelink errors.
  // best i can tell these are caused from following links from outlook based emails (@outlook, @live, @hotmail, custom domains using outlook)
  // these do not seem to be causing an issue for the users (maybe because of page refreshes.)
  // see this link for more details https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/7
  const safelinkError = /Object Not Found Matching Id/i;
  if (safelinkError.test(notice.message)) {
    return false;
  }

  return true;
};

// error Boundry error, lets give better error info!
Honeybadger.beforeNotify(notice => {
  try {
    if (notice.context.componentStack !== undefined) {
      // We cant use the notice.context.componentStack because of minification lets at least get the page
      // const splitURL = notice.url.split('/');
      // notice.component = splitURL[splitURL.length - 1];
      notice.component = 'ErrorBoundryC';
      notice.action = 'ErrorBoundryA';
    }
  } catch (err) {
    // if we error here, "swallow" the error and just report it as normal to honeybadger
  }

  return ignoreErrors(notice);
});
