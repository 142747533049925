import CapacitorPlugin from 'shared/native/helpers/CapacitorPlugin';
import { Capacitor } from '@capacitor/core';
import { StatusBar as CapacitorStatusBar, Style } from '@capacitor/status-bar';
import { SafeArea } from 'capacitor-plugin-safe-area';
import uhTheme from '_uh_theme.jsx';

/**
 * Wrapper for https://capacitorjs.com/docs/apis/status-bar
 * Most of the status bar management is pure HTML, JS, and CSS.
 * The web view includes the entire screen, including the status bar.
 * Therefore, the background color and sizing of it is managed by the web app,
 * specifically the <MobileStatusBar /> component. The only thing that
 * the native app must manage is the text color (dark or light).
 * Doing it this way is more universally compatible and gives us more control
 * within the web app itself.
 * */
class StatusBar extends CapacitorPlugin {
  pluginName = 'StatusBar';

  backgroundColor = uhTheme.statusBar.backgroundColor;

  DARK = Style.Dark;

  LIGHT = Style.Light;

  constructor() {
    super();
    if (this.isInstalled) {
      // set the default style to dark
      this.setStyle(this.DARK);
      // force the status bar to overlay the web view across all devices
      CapacitorStatusBar.setOverlaysWebView({ overlay: true });
      // ensure the safe areas are set
      this.maintainSafeAreas();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async setStyle(style) {
    return CapacitorStatusBar.setStyle({ style });
  }

  maintainSafeAreas() {
    // sets --safe-area-inset-* CSS variables for Android --
    // iOS maintains safe areas automatically, but Android needs this plugin
    if (Capacitor.getPlatform() === 'android') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        this.setSafeAreas(insets);
      });
      SafeArea.addListener('safeAreaChanged', ({ insets }) => {
        this.setSafeAreas(insets);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setSafeAreas(insets) {
    ['top', 'left', 'right', 'bottom'].forEach(key => {
      document.documentElement.style.setProperty(
        `--safe-area-inset-${key}`,
        `${insets[key] || 0}px`
      );
    });
  }
}

export default new StatusBar();
