import moment from 'moment-timezone';
import { Record } from 'immutable';
import Membership from 'shared/records/Membership.jsx';
import MembershipTier from 'shared/records/MembershipTier';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const convertToDate = v => v && new Date(Date.parse(v));

class MembershipSubscription extends Record({
  id: null,
  commitment_ends_at: null,
  client_id: null,
  expires_at: null,
  last_payment_at: null,
  is_cancelled: false,
  is_suspended: false,
  membership_id: null,
  membership: new Membership(),
  membership_tier: new MembershipTier(),
  membership_tier_id: null,
  starts_at: null,
  suspended_at: null,
  reactivate_on: null,
  billing_status: null,
  _destroy: false,
  embed_card_id: null,
  embed_card_status: null,
  embed_card_error: null,
  embed_platinum: false,
  future_cancelling: false,
  created_at: null,
  amusement_credits: null,
  cancellation_reason: null,
  cancellation_note: null,
}) {
  constructor(object = {}, _options = {}) {
    super(
      merge(object, {
        membership: new Membership(object?.membership),
        membership_tier: new MembershipTier(object?.membership_tier || {}),
        commitment_ends_at: convertToDate(object?.commitment_ends_at),
        expires_at: convertToDate(object?.expires_at),
        starts_at: convertToDate(object?.starts_at),
      })
    );
  }

  isActive() {
    const start = moment(this.starts_at);
    const expires = this.expires_at ? moment(this.expires_at) : null;
    const now = moment();

    return (
      (now.isSameOrAfter(start) && expires === null) ||
      now.isBetween(start, expires)
    );
  }

  isPreSale() {
    return this.billing_status === 'pre_sale';
  }

  futureCancellingDateToShow() {
    const expiresAt = moment(this.expires_at).format('MM/DD/YYYY');
    const commitmentEndsAt = moment(this.commitment_ends_at).format(
      'MM/DD/YYYY'
    );
    return this.future_cancelling ? commitmentEndsAt : expiresAt;
  }

  get status() {
    const isExpired = !this.isActive();
    if (
      !this.is_suspended &&
      !isExpired &&
      this.is_cancelled &&
      moment().isBefore(moment(this.expires_at))
    )
      return 'cancelled_at';
    if (
      !this.is_suspended &&
      !isExpired &&
      !this.is_cancelled &&
      this.future_cancelling
    )
      return 'cancelled_at';

    if (this.is_cancelled && !this.is_suspended) return 'cancelled';

    if (this.suspended_at) {
      if (this.is_suspended && !this.reactivate_on) return 'suspended';

      return this.is_suspended ? 'suspended_until' : 'suspending';
    }

    if (!this.is_cancelled && !this.isPreSale() && isExpired) return 'expired';

    return 'active';
  }
}

export default MembershipSubscription;
