import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { Card, Grid, Typography } from '@upperhand/playmaker';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { TransactionLedger } from 'records';

function RowDesktop({ transactionLedger, columns, intl }) {
  const effectiveAt = moment(transactionLedger.get('effectiveAt'));

  // Have to add one because date / time cannot be toggled.
  const activeItems = columns.filter(c => c).count() + 1;

  return (
    <Card
      classes={{
        root: 'transactions-ledger-report__row',
        content: 'transactions-ledger-report__row-content',
      }}
      contentDirection="row"
    >
      <Grid container spacing={1} alignContent="center" justify="center">
        <Grid
          item
          className={`transactions-ledger-report__row-item even-cols-${activeItems}`}
        >
          <Typography variant="body2">
            {effectiveAt.isSame(moment(), 'day')
              ? t('.date_today', intl, __filenamespace, {
                  date: effectiveAt.format('h:mma'),
                })
              : effectiveAt.format('MM/DD/YYYY • h:mma')}
          </Typography>
        </Grid>
        {columns.get('transactionId') && (
          <Grid
            item
            className={`transactions-ledger-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              {transactionLedger.get('transactionId')}
            </Typography>
          </Grid>
        )}
        {columns.get('clientName') && (
          <Grid
            item
            className={`transactions-ledger-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              {transactionLedger.get('clientName')}
            </Typography>
          </Grid>
        )}
        {columns.get('sourceType') && (
          <Grid
            item
            className={`transactions-ledger-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              {t(
                `.source_${transactionLedger.get('sourceType')}`,
                intl,
                __filenamespace
              )}
            </Typography>
          </Grid>
        )}
        {columns.get('paymentMethod') && (
          <Grid
            item
            className={`transactions-ledger-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              {transactionLedger.get('paymentMethod') &&
                t(
                  `.payment_method_${transactionLedger.get('paymentMethod')}`,
                  intl,
                  __filenamespace
                )}
            </Typography>
          </Grid>
        )}
        {columns.get('last4') && (
          <Grid
            item
            className={`transactions-ledger-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              {transactionLedger.get('last4')}
            </Typography>
          </Grid>
        )}
        {columns.get('amount') && (
          <Grid
            item
            className={`transactions-ledger-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              <FormattedCurrency
                value={transactionLedger.get('amount')}
                fromCents
              />
            </Typography>
          </Grid>
        )}
      </Grid>
      <div className="transactions-ledger-report__row-item transactions-ledger-report__row-action">
        {/* <ChevronRightIcon /> */}
      </div>
    </Card>
  );
}

RowDesktop.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  transactionLedger: PropTypes.instanceOf(TransactionLedger).isRequired,
};

export default RowDesktop;
