import CreditPass from 'shared/records/CreditPass.js';
import CreditPassActions from 'shared/actions/CreditPassActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { CreditPassSource } from 'sources';

class CreditPassStoreInterface extends UpperHandStore {
  constructor() {
    super();

    this.record = new CreditPass();
    this.isLoading = false;

    this.bindListeners({
      handleError: CreditPassActions.ERROR,
      handleFetch: CreditPassActions.FETCH,
      handleFetchSuccess: CreditPassActions.CREDIT_PASS_LOADED,
    });
  }

  handleFetch({ id }) {
    if (id) {
      this.isLoading = true;

      CreditPassSource.fetch({
        id,
        success: CreditPassActions.creditPassLoaded,
        error: CreditPassActions.error,
      });
    }
  }

  handleError() {
    this.isLoading = false;
  }

  handleFetchSuccess(creditPass) {
    this.record = creditPass;
    this.isLoading = false;
  }

  // eslint-disable-next-line class-methods-use-this
  url(id) {
    return `credit_passes/${id}`;
  }
}

export default CreditPassStoreInterface;
