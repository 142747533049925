import * as React from 'react';
import PropTypes from 'prop-types';

import MailToBtn from 'components/MailToBtn.jsx';
import SignupResults from '../SignupResults.jsx';

function SignupError({ emailSubject, errorMessage }) {
  const body = `I received this error: ${errorMessage}`;
  const label = 'Contact support';

  return (
    <SignupResults
      headerText="Something went wrong"
      description={errorMessage}
      callToAction={
        <MailToBtn
          btnLabel={label}
          emailBody={body}
          emailSubject={emailSubject}
          mailTo="support@getupperhand.com"
        />
      }
    />
  );
}

SignupError.defaultProps = {
  emailSubject: 'I received an error',
};

SignupError.propTypes = {
  emailSubject: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
};

export default SignupError;
