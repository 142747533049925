import { List, Map } from 'immutable';

import OptionType from 'shared/records/OptionType.js';
import Product from 'shared/records/Product.js';
import ProductImage from 'shared/records/ProductImage.js';
import Variant from 'shared/records/Variant.js';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { mod } from 'shared/utils/MathUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import FieldErrors from 'shared/records/FieldErrors.jsx';

import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSProductActions from 'point_of_sale/actions/POSProductActions.js';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';

import POSProductListStore from 'point_of_sale/stores/POSProductListStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import POSStore from 'point_of_sale/stores/POSStore.jsx';

class POSProductStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      selectProduct: POSProductListActions.PRODUCT_SELECTED,
      handleCodeSearch: POSProductListActions.LIST_SUCCESS,

      handleCartView: POSCartActions.ITEM_VIEW_SELECTED,
      handleCartEdit: POSCartActions.ITEM_EDIT_SELECTED,

      fetchProductSuccess: POSProductActions.FETCH_SUCCESS,
      fetchProductError: POSProductActions.FETCH_ERROR,

      listImagesSuccess: POSProductActions.LIST_IMAGES_SUCCESS,
      listImagesError: POSProductActions.LIST_IMAGES_ERROR,

      selectNextImage: POSProductActions.SELECT_NEXT_IMAGE,
      selectPreviousImage: POSProductActions.SELECT_PREVIOUS_IMAGE,
      selectSpecificImage: POSProductActions.IMAGE_SELECTED,

      listOptionTypesSuccess: POSProductActions.LIST_OPTION_TYPES_SUCCESS,
      listOptionTypesError: POSProductActions.LIST_OPTION_TYPES_ERROR,

      listVariantsSuccess: POSProductActions.LIST_VARIANTS_SUCCESS,
      listVariantsError: POSProductActions.LIST_VARIANTS_ERROR,

      selectOption: POSProductActions.OPTION_SELECTED,
      selectQuantity: POSProductActions.QUANTITY_SELECTED,
      swingCardChange: POSProductActions.SWING_CARD_CHANGE,

      clientChange: POSProductActions.CLIENT_CHANGE,
      preselectClient: [
        POSActions.CLIENT_SELECTED,
        POSCartActions.ADD_EVENT_CLICKED,
      ],

      reset: [
        POSProductActions.CANCEL_CLICKED,
        POSProductActions.ADD_TO_CART_CLICKED,
      ],

      validate: POSProductActions.VALIDATE,
    });
  }

  reset() {
    this.product = new Product();
    this.images = List();
    this.optionTypes = List();

    this.selectedImage = new ProductImage();
    this.selectedImageIndex = 0;
    this.selectedOptions = Map();
    this.selectedVariant = null;
    this.selectedQuantity = 1;
    this.cashCreditDestinationId = null;

    this.orderItemId = null;
    this.changed = false;
    this.errors = new FieldErrors();
  }

  selectProduct(product) {
    this.product = product;

    if (product.featured_image) {
      this.selectedImage = product.featured_image;
    }

    this.loadImages();
    this.loadOptionTypes();
  }

  handleCodeSearch() {
    this.waitFor(POSProductListStore);

    const productListStore = POSProductListStore.getState();

    if (productListStore.variant) {
      this.selectProduct(productListStore.products.first());
      this.selectedVariant = productListStore.variant;
      this.selectSpecificImage(this.selectedVariant.product_image_ids.first());
    }
  }

  handleCartView(orderItem) {
    if (orderItem.isRetailItem()) {
      this.fetchProduct(orderItem.orderable.product_id);
    }
  }

  handleCartEdit(orderItem) {
    if (orderItem.isRetailItem()) {
      this.orderItemId = orderItem.id;
      this.selectedVariant = orderItem.orderable;
      this.selectedQuantity = orderItem.quantity;
      this.cashCreditDestinationId = orderItem.get(
        'cash_credit_destination_id',
        null
      );

      this.fetchProduct(orderItem.orderable.product_id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  fetchProduct(id) {
    uhApiClient.get({
      url: `products/${id}`,
      success: POSProductActions.fetchSuccess,
      error: POSProductActions.fetchError,
    });
  }

  fetchProductSuccess(data) {
    this.selectProduct(new Product(data));
  }

  fetchProductError(...args) {
    this.notifyError('error fetching product', args);
  }

  loadImages() {
    uhApiClient.get({
      url: `products/${this.product.id}/product_images`,
      data: { per_page: 100 },
      success: POSProductActions.listImagesSuccess,
      error: POSProductActions.listImagesError,
    });
  }

  listImagesSuccess(data) {
    this.images = List(
      data.product_images.map(i => new ProductImage(i))
    ).sortBy(i => !i.featured);

    if (this.images.size === 0) {
      return;
    }

    if (this.selectedVariant) {
      this.selectSpecificImage(this.selectedVariant.product_image_ids.first());
    } else {
      this.selectedImage = this.images.first();
      this.selectedImageIndex = 0;
    }
  }

  listImagesError(...args) {
    this.notifyError('error fetching product images', args);
  }

  selectImage(index) {
    this.selectedImageIndex = mod(index, this.images.size);
    this.selectedImage = this.images.get(this.selectedImageIndex);
  }

  selectNextImage() {
    this.selectImage(this.selectedImageIndex + 1);
  }

  selectPreviousImage() {
    this.selectImage(this.selectedImageIndex - 1);
  }

  selectSpecificImage(id) {
    const [index, image] = this.images.findEntry(i => i.id === id, null, [
      -1,
      undefined,
    ]);

    if (index >= 0) {
      this.selectedImageIndex = index;
      this.selectedImage = image;
    }
  }

  loadOptionTypes() {
    if (this.product.option_type_ids.isEmpty()) {
      // The product has no options to choose from. So we can go ahead and load
      // the only variant.
      this.loadVariant();
    } else {
      uhApiClient.get({
        url: `products/${this.product.id}/option_types`,
        success: POSProductActions.listOptionTypesSuccess,
        error: POSProductActions.listOptionTypesError,
      });
    }
  }

  listOptionTypesSuccess(data) {
    this.optionTypes = List(data.option_types.map(o => new OptionType(o)));

    if (this.selectedVariant) {
      this.selectedOptions = this.optionTypes.reduce(
        (map, o) =>
          map.set(
            o.id,
            o.option_values
              .map(v => v.id)
              .find(id => this.selectedVariant.option_value_ids.has(id))
          ),
        Map()
      );
    } else {
      this.selectedOptions = this.optionTypes.reduce(
        (map, o) => map.set(o.id, null),
        Map()
      );
    }
  }

  listOptionTypesError(...args) {
    this.notifyError('error fetching product options', args);
  }

  selectOption([optionTypeId, optionValueId]) {
    this.changed =
      this.changed || this.selectedOptions.get(optionTypeId) !== optionValueId;
    this.selectedOptions = this.selectedOptions.set(
      optionTypeId,
      optionValueId
    );

    if (this.areAllOptionsSelected()) {
      this.loadVariant();
    } else {
      this.selectedVariant = null;
    }

    this.validate();
  }

  loadVariant() {
    uhApiClient.get({
      url: `products/${this.product.id}/variants`,
      data: { option_value_ids: this.selectedOptions.toList().toJS() },
      success: POSProductActions.listVariantsSuccess,
      error: POSProductActions.listVariantsError,
    });
  }

  listVariantsSuccess(data) {
    this.selectedVariant = new Variant(data.variants[0]);
    this.selectSpecificImage(this.selectedVariant.product_image_ids.first());
  }

  listVariantsError(...args) {
    this.notifyError('error fetching product variants', args);
  }

  selectQuantity(quantity) {
    this.changed = this.changed || this.selectedQuantity !== quantity;
    this.selectedQuantity = quantity;
    this.validate();
  }

  swingCardChange(cardNumber) {
    this.errors = this.errors.clear();
    // eslint-disable-next-line no-useless-escape
    const matchSwingCard = cardNumber?.match(/\;(.*)\?/)?.pop();

    this.cashCreditDestinationId = matchSwingCard || cardNumber;
    this.changed = true;
  }

  clientChange(clientId) {
    this.errors = this.errors.clear();
    this.cashCreditDestinationId = clientId;
    this.changed = true;
  }

  preselectClient(client) {
    const { features } = currentCustomer();
    const { ap_credits: apCreditsEnabled } = features;

    if (apCreditsEnabled && !client) {
      this.waitFor(POSStore);
      const { selectedClient } = POSStore.getState();

      this.cashCreditDestinationId = selectedClient?.id;
      this.changed = false;
    }

    if (apCreditsEnabled && client) {
      this.cashCreditDestinationId = client?.id;
      this.changed = false;
    }
  }

  validate() {
    const { features } = currentCustomer();
    const { embed_api: embedApiEnabled, ap_credits: apCreditsEnabled } =
      features;

    this.errors = this.errors.clear();

    if (
      this.cashCreditDestinationId &&
      this.cashCreditDestinationId.length !== 19 &&
      embedApiEnabled
    ) {
      this.errors = this.errors.add(
        'cashCreditDestinationId',
        'point_of_sale.ProductView.swing_card_error'
      );
    }

    if (!this.cashCreditDestinationId && apCreditsEnabled) {
      this.errors = this.errors.add(
        'creditsProfile',
        'point_of_sale.ProductView.profile_required'
      );
    }
  }

  areAllOptionsSelected() {
    return this.selectedOptions.every(v => v);
  }
}

export default alt.createStore(POSProductStore, 'POSProductStore');
