import React from 'react';
import { Button, Grid, Icon, TextField } from '@upperhand/playmaker';

import ContactAutocomplete from 'shared/components/ContactAutocomplete.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';

import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';

const styles = {
  contactSearch: {
    fontSize: 14,
    paddingLeft: '10px',
    height: '42px',
    transition: 'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: 'white',
    borderRadius: '21px',
  },
  contactSearchInput: {
    padding: '10px',
  },
};

function Header({ membershipStore, intl }) {
  const hasFilterActive = membershipStore.getActiveFilterKey();
  const { isMembersListDownloading } = membershipStore;
  return (
    <ResponsiveElement
      largeScreen={
        <Grid container item spacing={1}>
          <Grid item lg={membershipStore.record.invite_only ? 4 : 8} xs={11}>
            <TextField
              classes={{
                root: 'membership__search-field',
              }}
              fullWidth
              icon="search"
              onChange={event =>
                MembershipViewingActions.searchStringUpdated(event.target.value)
              }
              placeholder={t('.search', intl, __filenamespace)}
              rounded
              value={membershipStore.searchString}
            />
          </Grid>
          {membershipStore.record.invite_only && (
            <Grid item lg={4} xs={11}>
              <ContactAutocomplete
                contacts={membershipStore.queriedClients}
                hintText={t(
                  '.invite_client_field_hint_text',
                  intl,
                  __filenamespace
                )}
                onUpdateInput={
                  MembershipViewingActions.inviteSearchStringUpdated
                }
                onSelect={MembershipViewingActions.invite}
                style={styles.contactSearch}
                inputStyle={styles.contactSearchInput}
              />
            </Grid>
          )}
          <Grid item lg={2} xs={11}>
            <Button
              classes={{
                label: 'membership__header-button-label',
              }}
              fullWidth
              icon={
                <Icon
                  name="filter"
                  className={
                    hasFilterActive
                      ? 'membership__active-filter-button-icon'
                      : 'membership__filter-button-icon'
                  }
                />
              }
              iconPosition="right"
              labelAlign="space-between"
              type="tertiary"
              rounded
              onClick={MembershipViewingActions.filterDrawerOpen}
            >
              {t('.btn_filter', intl, __filenamespace)}
            </Button>
          </Grid>
          <Grid item lg={2} xs={11}>
            <Button
              fullWidth
              icon={
                <Icon
                  name="cloudDownload"
                  className="membership__export-button-icon"
                />
              }
              type="tertiary"
              rounded
              classes={{
                root: 'membership__export-button',
                label: 'membership__header-button-label',
              }}
              disabled={isMembersListDownloading}
              onClick={() => MembershipViewingActions.downloadList()}
            >
              {t('.btn_export', intl, __filenamespace)}
            </Button>
          </Grid>
        </Grid>
      }
      smallScreen={
        <Grid container item spacing={1}>
          <Grid item xs={10}>
            <TextField
              classes={{
                root: 'membership__search-field-mobile',
              }}
              fullWidth
              icon="search"
              onChange={event =>
                MembershipViewingActions.searchStringUpdated(event.target.value)
              }
              placeholder={t('.search', intl, __filenamespace)}
              rounded
              value={membershipStore.searchString}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              classes={{
                root: 'membership__header-button-mobile',
                label: 'membership__header-button-label',
              }}
              icon={
                <Icon
                  name="filter"
                  className={
                    hasFilterActive
                      ? 'membership__active-filter-button-icon'
                      : 'membership__filter-button-icon'
                  }
                />
              }
              fullWidth
              iconPosition="right"
              labelAlign="space-between"
              type="tertiary"
              rounded
              onClick={MembershipViewingActions.filterDrawerOpen}
            />
          </Grid>
          {membershipStore.record.invite_only && (
            <Grid item xs={8}>
              <ContactAutocomplete
                contacts={membershipStore.queriedClients}
                hintText={t(
                  '.invite_client_field_hint_text',
                  intl,
                  __filenamespace
                )}
                onUpdateInput={
                  MembershipViewingActions.inviteSearchStringUpdated
                }
                onSelect={MembershipViewingActions.invite}
                style={merge(styles.contactSearch, { backgroundColor: 'none' })}
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

export default Header;
