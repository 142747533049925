import { Record, Set, List } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

export const CREDIT_PASS_CREDIT_TYPES = {
  all_events: 'all_events',
  event_types: 'event_types',
  event_specific: 'event_specific',
};

export default class CreditPassCredit extends Record({
  id: null,
  all_events: false,
  event_ids: Set(),
  events: List(),
  event_type_ids: Set(),
  event_types: List(),
  credit_pass_id: null,
  quantity: 0,
  unlimited: true,
  errors: new FieldErrors(),
  type: CREDIT_PASS_CREDIT_TYPES.all_events,
}) {
  constructor(object = {}) {
    const errors = new FieldErrors(object.errors);
    const event_ids = Set(object.event_ids);
    const event_type_ids = Set(object.event_type_ids);

    let type = CREDIT_PASS_CREDIT_TYPES.all_events;
    if (event_ids.size) {
      type = CREDIT_PASS_CREDIT_TYPES.event_specific;
    } else if (event_type_ids.size) {
      type = CREDIT_PASS_CREDIT_TYPES.event_types;
    }

    super(merge(object, { errors, event_ids, event_type_ids, type }));
  }

  isValid() {
    return this.errors.isEmpty();
  }

  validate() {
    let errors = new FieldErrors();

    if (!this.type) {
      errors = errors.add(
        'all_events',
        'records.CreditPassCredit.errors.one_type_should_be_selected'
      );
    }

    if (
      (this.all_events && !this.event_type_ids.isEmpty()) ||
      (this.all_events && !this.event_ids.isEmpty())
    ) {
      errors = errors.add(
        'all_events',
        'records.CreditPassCredit.errors.no_event_types_or_events_when_all_events'
      );
    }

    if (
      this.type === CREDIT_PASS_CREDIT_TYPES.event_types &&
      !this.event_type_ids.size
    ) {
      errors = errors.add(
        'event_types',
        'records.CreditPassCredit.errors.at_least_one_event_type_should_be_selected'
      );
    }

    if (
      this.type === CREDIT_PASS_CREDIT_TYPES.event_specific &&
      !this.event_ids.size
    ) {
      errors = errors.add(
        'event_specific',
        'records.CreditPassCredit.errors.at_least_one_event_should_be_selected'
      );
    }

    if (!this.unlimited && this.quantity <= 0) {
      errors = errors.add(
        'quantity',
        'records.CreditPassCredit.errors.quantity_should_be_positive'
      );
    }

    return this.set('errors', errors);
  }
}
