import * as React from 'react';
import ImageUpload, { AvatarPreview } from 'components/ImageUpload';

import {
  DateOfBirth,
  Email,
  FirstName,
  Gender,
  LastFourOfSSN,
  LastName,
  SetPassword,
} from 'user_management/shared/components/UserProfileFields.jsx';
import EditProfile from 'user_management/shared/components/_EditProfile.jsx';

import { handleChange } from 'shared/helpers/ChangeHandler.jsx';

import CustomerCreationActions from '../Actions';

const handleUserProfileChange = handleChange({
  actions: CustomerCreationActions,
  prefix: 'staffMember',
});

function StaffEditor({ staffMember, intl, errors }) {
  return (
    <EditProfile headerText="Create Admin Account">
      <ImageUpload
        circularCrop
        cropAspect={1}
        uploadContainerWidth="100%"
        onChange={value => {
          handleUserProfileChange(null, value, 'profile_image');
        }}
        image={staffMember.profile_image}
        preview={<AvatarPreview user={staffMember} />}
        style={{ margin: '8px 0 12px' }}
      />
      <FirstName
        onChange={handleUserProfileChange}
        userProfile={staffMember}
        errorText={errors.getErrors('staffMember.first_name')}
      />
      <LastName
        userProfile={staffMember}
        onChange={handleUserProfileChange}
        errorText={errors.getErrors('staffMember.last_name')}
      />
      <Email
        userProfile={staffMember}
        onChange={handleUserProfileChange}
        errorText={errors.getErrors('staffMember.email')}
      />
      <SetPassword
        userProfile={staffMember}
        onChange={handleUserProfileChange}
      />
      <DateOfBirth
        userProfile={staffMember}
        onChange={handleUserProfileChange}
        errorText={errors.getErrors('staffMember.date_of_birth', intl)}
      />
      <Gender userProfile={staffMember} onChange={handleUserProfileChange} />
      <LastFourOfSSN
        userProfile={staffMember}
        onChange={handleUserProfileChange}
        errorText={errors.getErrors('staffMember.ssn_last_4', intl)}
      />
    </EditProfile>
  );
}

export default StaffEditor;
