import * as React from 'react';
import { injectIntl } from 'react-intl';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import DrawerHeader from 'shared/components/DrawerHeader.jsx';
import ResourceActions from 'resources/actions/ResourceActions.js';
import ResourceDrawerActions from 'resources/actions/ResourceDrawerActions.js';
import ResourceForm from 'resources/components/ResourceForm.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  button: {
    height: 50,
  },

  buttonLabel: {
    fontSize: 16,
  },

  contentContainer: {
    padding: 10,
  },
};

const onDrawerClose = () => ResourceDrawerActions.drawerClosed();
const onSave = () => ResourceActions.resourceSaved();

function ResourceDrawer({ resourceDrawerStore, resourceEditingStore, intl }) {
  return (
    <Drawer
      disableEnforceFocus
      anchor="right"
      open={resourceDrawerStore.drawerOpen}
      PaperProps={{
        sx: {
          width: smallScreen()
            ? window.innerWidth * 0.9
            : STANDARD_DRAWER_WIDTH,
        },
      }}
    >
      <DrawerHeader
        title={
          resourceDrawerStore.resourceId
            ? t('.edit_resource', intl, __filenamespace)
            : t('.create_resource', intl, __filenamespace)
        }
        onClose={onDrawerClose}
      />

      <div style={styles.contentContainer}>
        <ResourceForm
          resourceEditingStore={resourceEditingStore}
          intl={intl}
          style={{ marginBottom: 10 }}
        />

        <StateChangingButton
          primary
          fullWidth
          label={t('actions.save', intl, __filenamespace)}
          labelInProgress={t('actions.saving', intl, __filenamespace)}
          labelStyle={styles.buttonLabel}
          inProgress={resourceEditingStore.isSaving}
          style={merge(styles.button, { marginBottom: 10 })}
          onClick={onSave}
        />

        <Button
          fullWidth
          color="default"
          variant="contained"
          style={merge(styles.button, { color: uhColors.activeBlue })}
          onClick={onDrawerClose}
        >
          {t('actions.cancel', intl, __filenamespace)}
        </Button>
      </div>
    </Drawer>
  );
}

export default injectIntl(ResourceDrawer);
