import * as React from 'react';
import { Button, Grid, Icon, Spinner, TextField } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';

import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import { ClientDataStore } from 'dataStores';
import ClientCard from './components/ClientCard.jsx';
import ContactsArchivedActions from './Actions';
import ContactsArchivedStore from './Store';

const onSearchChange = (_event, value) =>
  ContactsArchivedActions.searchUpdated(value);

function ContactsArchived({
  clientDataStore: { clients },
  contactsArchivedStore: {
    clientIds,
    page,
    perPage,
    totalCount,
    search,
    loading,
  },
}) {
  React.useEffect(() => {
    ContactsArchivedActions.mounted();
  }, []);

  return (
    <div style={{ paddingTop: 16 }}>
      <Grid container spacing={1} alignItems="center">
        <div className="contacts__add-button">
          <Button
            onClick={InviteDialogActions.openInviteDialog}
            rounded
            icon={<Icon name="add" className="contacts__add-button-icon" />}
            size="2x"
          />
        </div>
        <Grid item xs>
          <TextField
            fullWidth
            rounded
            outline={false}
            icon="search"
            placeholder="Search"
            value={search}
            onChange={onSearchChange}
          />
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', marginTop: 100 }}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <>
            <Grid
              item
              container
              spacing={1}
              className="contacts__archived-list"
            >
              {clientIds.map(id => (
                <Grid item xs={12} key={id}>
                  <ClientCard client={clients.get(id)} />
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Paginator
                className="contacts__pagination"
                currentPage={page}
                perPage={perPage}
                totalCount={totalCount}
                onPageSelect={ContactsArchivedActions.pageSelected}
                pageLimit={0}
                showInfo
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
    contactsArchivedStore: ContactsArchivedStore,
  },
})(ContactsArchived);
