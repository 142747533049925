import { List, Set } from 'immutable';
import EventListStoreImpl from 'shared/stores/_EventListStoreImpl.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import EnrolledEventListActions from 'user_management/shared/actions/EnrolledEventListActions.jsx';
import { destroy as destroyRegistration } from 'sources/RegistrationSource';

export const creditOperations = {
  REFUND_CREDIT: 'refund_credit',
  REVOKE_CREDIT: 'revoke_credit',
};

class EnrolledEventListStore extends EventListStoreImpl {
  constructor() {
    super(EnrolledEventListActions, {
      handleClearRegistration: EnrolledEventListActions.CLEAR_REGISTRATION,
      handleCancelRegistrationError:
        EnrolledEventListActions.cancelRegistrationError,
      handleCancelRegistration: EnrolledEventListActions.cancelRegistration,
      handleCancelRegistrationSuccess:
        EnrolledEventListActions.cancelRegistrationSuccess,
      handleStageRegistrationToCancel:
        EnrolledEventListActions.stageRegistrationToCancel,
      handleToggleExpandEvent: EnrolledEventListActions.TOGGLE_EXPAND_EVENT,
      handleSetExpandedEvents: EnrolledEventListActions.SET_EXPANDED_EVENTS,
      handleListEvents: EnrolledEventListActions.LIST,
      setWaive: EnrolledEventListActions.setWaive,
      setCreditOperation: EnrolledEventListActions.setCreditOperation,
    });
    this.expandedEventIds = Set();
    this.loadMore = true;

    this.creditOperation = undefined;
    this.waiveBalance = false;
  }

  reset() {
    this.page = 1;
    this.events = List();
    this.loadMore = true;

    this.creditOperation = undefined;
    this.waiveBalance = false;
  }

  handleClearRegistration() {
    this.registration = null;
    this.eventToModify = null;
  }

  handleListEvents(client) {
    if (
      this.clientToModify &&
      this.clientToModify.get('id') !== client.get('id')
    ) {
      this.reset();
    }
    if (this.loadMore) {
      this.clientToModify = client;
      this.eventUrl = `customer_users/${client.id}/enrolled_events`;
      super.handleListEvents({ page: this.page });
    }
  }

  handleListEventsSuccess(data) {
    this.page = data.page;
    this.perPage = data.per_page;
    this.total_count = data.total_count;
    this.events = this.events.concat(
      List(data.events.map(event => new EventTranslator(event).toClient()))
    );

    if (this.total_count > this.events.size) {
      this.page += 1;
      this.handleListEvents(this.clientToModify);
    } else {
      this.isLoading = false;
      this.loadMore = false;
    }
  }

  handleStageRegistrationToCancel({ event, registration }) {
    this.registration = registration;
    this.eventToModify = event;

    if (!registration) {
      return;
    }

    this.creditOperation = registration.paid
      ? creditOperations.REFUND_CREDIT
      : creditOperations.REVOKE_CREDIT;

    this.waiveBalance = !registration.paid;
  }

  handleToggleExpandEvent({ eventId }) {
    this.handleSetExpandedEvents({
      ids: this.expandedEventIds.includes(eventId)
        ? this.expandedEventIds.delete(eventId)
        : this.expandedEventIds.add(eventId),
    });
  }

  handleSetExpandedEvents({ ids }) {
    this.expandedEventIds = ids;
  }

  handleCancelRegistration() {
    this.isLoading = true;

    return destroyRegistration({
      id: this.registration.id,
      params: this.params(),
      success: {
        action: EnrolledEventListActions.cancelRegistrationSuccess,
        args: [this.registration],
      },
      error: EnrolledEventListActions.cancelRegistrationError,
    });
  }

  handleCancelRegistrationSuccess() {
    this.reset();
    this.handleListEvents(this.clientToModify);
    this.registration = null;
  }

  handleCancelRegistrationError(...args) {
    this.isLoading = false;
    this.notifyError(`error while deleting registration`, args);
  }

  setCreditOperation(operation) {
    this.creditOperation = operation;
  }

  setWaive(checked) {
    this.waiveBalance = checked;
  }

  params() {
    if (this.creditOperation === creditOperations.REVOKE_CREDIT) {
      return { waive_balance: this.waiveBalance, revoke_credit: true };
    }

    return {};
  }
}

export default alt.createStore(
  EnrolledEventListStore,
  'EnrolledEventListStore'
);
