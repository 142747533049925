import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import CreditPass from 'shared/records/CreditPass.js';
import CreditPassActions from 'sources/CreditPassActions.js';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';

class CreditPassDataStore extends UpperHandStore {
  constructor() {
    super();

    this.creditPasses = Map();

    this.bindListeners({
      fetchSuccess: [CreditPassActions.fetchSuccess],
      listSuccess: [CreditPassActions.listSuccess],
      // TODO: CPP, we need to figure out how to make this generic
      productListSuccess: [POSProductListActions.listSuccess],
      error: [CreditPassActions.listError],
    });
  }

  fetchSuccess(creditPass) {
    this.creditPasses = this.creditPasses.set(creditPass.id, creditPass);
  }

  listSuccess({ credit_passes }) {
    const fetchedCreditPasses = credit_passes
      .groupBy(cp => cp.id)
      .map(cp => cp.first());

    this.creditPasses = this.creditPasses.merge(fetchedCreditPasses);
  }

  productListSuccess({ products }) {
    products.forEach(p => {
      if (p.product_type === 'credit_pass') {
        this.creditPasses = this.creditPasses.set(p.id, new CreditPass(p));
      }
    });
  }

  error() {
    this.notifyError('error fetching credit passes', arguments);
  }
}

export default alt.createStore(CreditPassDataStore, 'CreditPassDataStore');
