import * as React from 'react';
import { injectIntl } from 'react-intl';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import StaffIcon from 'shared/components/icons/Staff.jsx';
import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import Selectbox from 'calendar/components/quick_schedule/Selectbox.jsx';

import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Staff: {
    position: 'relative',
    width: '100%',
  },
  MenuItem: {
    width: '100%',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Loading: {
    textAlign: 'center',
  },
  StaffIcon: {
    margin: '0 8px 0 0',
    width: 20,
    height: 20,
  },
  RemoveButton: {
    cursor: 'pointer',
    padding: '3px',
  },
  StaffItem: {
    paddingLeft: '16px',
  },
};

const handleSelect = staff => {
  QuickScheduleActions.selectStaff(staff);
};

const handleOnRemoveStaff = staff => {
  QuickScheduleActions.removeStaff(staff);
};

const menuItems = ({ filteredStaff, staffStore, allowStaffSelection }) => {
  if (!staffStore.isLoading) {
    return filteredStaff.map(staff => (
      <MenuItem
        key={staff.id}
        onClick={() => {
          if (allowStaffSelection) {
            handleSelect(staff);
          }
        }}
        style={styles.MenuItem}
      >
        <AvatarWithName user={staff} avatarOptions={{ size: 24 }} />
      </MenuItem>
    ));
  }
  return (
    <div style={styles.Loading}>
      <CircularProgress size={45} />
    </div>
  );
};

function StaffWithIcon({ staff }) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <StaffIcon color={uhColors.leftNavGrey} style={styles.StaffIcon} />
      <div>{staff.name()}</div>
    </Stack>
  );
}

function SelectedStaff({ staff, removable }) {
  return (
    <div style={{ padding: '3px 0' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.StaffItem}
      >
        <StaffWithIcon staff={staff} />
        {removable && (
          <IconButton
            sx={styles.RemoveButton}
            onClick={() => handleOnRemoveStaff(staff)}
          >
            <CloseIcon
              sx={{ height: 21, width: 21, color: uhColors.iconLightGrey }}
            />
          </IconButton>
        )}
      </Stack>
    </div>
  );
}

function Staff({ intl, quickScheduleStore, staffStore }) {
  const { selectedStaff, selectedEvent, filteredStaff } = quickScheduleStore;
  const schedule = selectedEvent.getSchedule();
  const allowStaffSelection = selectedEvent.allow_staff_selection;
  const useAllStaff = schedule.staff_usage_mode === 'use_all';
  const locked = !allowStaffSelection && useAllStaff;

  return (
    <div>
      <Selectbox
        icon={
          <StaffIcon
            style={{ height: 18, width: 18 }}
            color={uhColors.tableGrey}
          />
        }
        locked={locked}
        menuItems={menuItems({
          filteredStaff,
          staffStore,
          allowStaffSelection: allowStaffSelection || !useAllStaff,
        })}
        hintText={t('.choose_staff', intl, __filenamespace)}
      />
      {selectedStaff.map(staff => (
        <SelectedStaff
          key={staff.id}
          staff={staff}
          removable={allowStaffSelection || !useAllStaff}
        />
      ))}
    </div>
  );
}

export default injectIntl(Staff);
