class PayoutsActions {
  constructor() {
    this.generateActions(
      'toggleHistoryDrawerOpen',
      'fetchBalance',
      'fetchBalanceSuccess',
      'fetchBalanceError',
      'list',
      'listSuccess',
      'listError'
    );
  }
}

export default alt.createActions(PayoutsActions);
