import * as React from 'react';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const defaultStyles = {
  evenRow: {
    backgroundColor: 'inherit',
  },

  oddRow: {
    backgroundColor: '#F9F9F9',
  },
};

const even = i => i % 2 === 0;

const StripedList = ({ children, style }) => {
  const styles = merge(defaultStyles, style);

  return (
    <ol>
      {children.map((item, i) => (
        <li key={i} style={even(i) ? styles.evenRow : styles.oddRow}>
          {item}
        </li>
      ))}
    </ol>
  );
};
export default StripedList;
