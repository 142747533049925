import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover } from '@upperhand/playmaker';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import NewImageIcon from 'shared/components/icons/NewImage.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

class VariantImageSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { anchorEl: null };

    this.onClick = this.onClick.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  handleRequestClose() {
    this.setState({ anchorEl: null });
  }

  onClick(event) {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  render() {
    const { variant, productImages, onImageSelect } = this.props;
    const { anchorEl } = this.state;

    if (typeof productImages === 'undefined') {
      return null;
    }

    return (
      <div>
        <IconButton onClick={this.onClick}>
          <NewImageIcon
            color={anchorEl ? uhColors.activeBlue : uhColors.iconLightGrey}
          />
        </IconButton>

        <Popover
          anchor={anchorEl}
          classes={{ content: 'variant-image-popover' }}
          onClose={this.handleRequestClose}
        >
          <div style={{ fontSize: 15, fontWeight: 'bold', margin: 10 }}>
            <FormattedMessage
              id={messageId('.choose_image', __filenamespace)}
            />
          </div>
          <FlexBox style={{ margin: 7, flexWrap: 'wrap', width: 224 }}>
            {productImages
              .map(productImage => {
                const selected = variant.product_image_ids.has(productImage.id);
                const padding = selected ? 0 : 3;
                const border = selected
                  ? `3px solid ${uhColors.activeBlue}`
                  : 'none';

                return (
                  <Paper
                    key={productImage.id}
                    onClick={() => onImageSelect(productImage.id)}
                    style={{
                      margin: 3,
                      cursor: 'pointer',
                      height: 50,
                      width: 50,
                    }}
                  >
                    <img
                      src={productImage.getAlternative('thumb_small')}
                      alt={productImage.id}
                      height="50"
                      width="50"
                      style={{
                        flex: '0 0 auto',
                        objectFit: 'contain',
                        padding,
                        border,
                      }}
                    />
                  </Paper>
                );
              })
              .toList()}
          </FlexBox>
        </Popover>
      </div>
    );
  }
}

export default VariantImageSelector;
