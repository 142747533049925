import { List } from 'immutable';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

class MessageWindowStore {
  constructor() {
    this.messages = List();
    this.messageAcked = true;
    this.open = false;
    this.settings = {
      primaryText: '',
      secondaryText: '',
      position: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      actions: [],
    };

    this.bindListeners({
      handleAckMessage: MessageWindowActions.ackMessage,
      handleAddMessage: MessageWindowActions.addMessage,
      handleAddMessages: MessageWindowActions.addMessages,
      handleAddMessageWithConfig: MessageWindowActions.addMessageWithConfig,
    });
  }

  handleAddMessageWithConfig([message, settings]) {
    if (settings) {
      this.settings = { ...this.settings, ...settings };
    }

    this.handleAddMessages([message]);
  }

  handleAddMessage(message) {
    this.handleAddMessages([message]);
  }

  handleAddMessages(messages) {
    this.messageAcked = false;
    this.open = true;
    messages.forEach(message => {
      if (!this.messages.includes(message)) {
        this.messages = this.messages.push(message);
      }
    });
  }

  handleAckMessage() {
    this.messageAcked = true;
    this.messages = List();
    this.open = false;
    this.settings = {
      primaryText: '',
      secondaryText: '',
      position: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      actions: [],
    };
  }
}

export default alt.createStore(MessageWindowStore, 'MessageWindowStore');
