import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, Dropdown } from '@upperhand/playmaker';

import Checkbox from '@mui/material/Checkbox';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';

class SchedulingDeadline extends React.Component {
  constructor(props) {
    super(props);

    if (props.schedule.scheduling_deadline) {
      if (props.schedule.scheduling_deadline % 86400 === 0) {
        this.state = {
          deadline: props.schedule.scheduling_deadline / 86400,
          deadlineUnits: 'days',
          showDeadline: true,
        };
      } else if (props.schedule.scheduling_deadline % 3600 === 0) {
        this.state = {
          deadline: props.schedule.scheduling_deadline / 3600,
          deadlineUnits: 'hours',
          showDeadline: true,
        };
      } else {
        this.state = {
          deadline: props.schedule.scheduling_deadline / 60,
          deadlineUnits: 'minutes',
          showDeadline: true,
        };
      }
    } else {
      this.state = {
        deadline: '',
        showDeadline: false,
      };
    }
  }

  handleToggle = e => {
    const { checked } = e.target;

    if (checked) {
      this.setState(
        {
          deadline: 1,
          deadlineUnits: 'days',
          showDeadline: true,
        },
        this.sendDeadlineUpdate
      );
    } else {
      this.setState(
        {
          deadline: '',
          showDeadline: false,
        },
        this.sendDeadlineUpdate
      );
    }
  };

  handleDeadline = (_, value) => {
    const newValue = value.replace(/^-/, '').replace(/^00+$/, '0');
    this.setState({ deadline: newValue }, this.sendDeadlineUpdate);
  };

  handleDeadlineUnits = e => {
    this.setState({ deadlineUnits: e.target.value }, this.sendDeadlineUpdate);
  };

  sendDeadlineUpdate() {
    const { deadline, deadlineUnits } = this.state;
    const { handleScheduleChange } = this.props;
    if (deadline === '' || deadline === '0') {
      handleScheduleChange(['scheduling_deadline'], null);
    } else {
      switch (deadlineUnits) {
        case 'days':
          handleScheduleChange(['scheduling_deadline'], deadline * 86400);
          break;
        case 'hours':
          handleScheduleChange(['scheduling_deadline'], deadline * 3600);
          break;
        default:
          handleScheduleChange(['scheduling_deadline'], deadline * 60);
      }
    }
  }

  render() {
    const { showDeadline, deadline, deadlineUnits } = this.state;
    const { styles, intl, classes } = this.props;

    return (
      <div>
        <FlexBoxJustify style={{ alignItems: 'center' }}>
          <div style={boldText}>
            <FormattedMessage
              id={messageId('.deadline_label', __filenamespace)}
            />
          </div>
          <div>
            <Checkbox
              name="deadline_none"
              onChange={this.handleToggle}
              checked={showDeadline}
            />
          </div>
        </FlexBoxJustify>
        {showDeadline && (
          <div style={styles.durationContainer}>
            <div style={{ color: uhColors.leftNavGrey }}>
              <FormattedMessage
                id={messageId('.deadline_before', __filenamespace)}
              />
            </div>
            <div style={styles.durationValues}>
              <TextField
                classes={{
                  root: classes.durationNumber,
                }}
                min="1"
                name="deadline"
                type="number"
                value={deadline}
                onChange={this.handleDeadline}
              />
              <Dropdown
                classes={{
                  select: classes.durationUnit,
                }}
                value={deadlineUnits}
                onChange={this.handleDeadlineUnits}
                items={[
                  {
                    value: 'minutes',
                    label: t('.min', intl, __filenamespace),
                  },
                  {
                    value: 'hours',
                    label: t('.hour', intl, __filenamespace),
                  },
                  {
                    value: 'days',
                    label: t('.day', intl, __filenamespace),
                  },
                ]}
              />
            </div>
          </div>
        )}
        {!showDeadline && (
          <div
            style={{
              color: uhColors.tabGrey,
              lineHeight: '1.2em',
              fontSize: '15px',
            }}
          >
            <FormattedMessage id={messageId('.explanation', __filenamespace)} />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SchedulingDeadline);
