import React, { memo } from 'react';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { List, Set } from 'immutable';
import {
  Dropdown,
  TextField,
  Typography,
  MultipleSelect,
} from '@upperhand/playmaker';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Switch, FormControlLabel } from '@mui/material';

import TeamTypeEditor from 'components/TeamTypeEditor';
import sportTypes from 'shared/constants/sportTypes';
import TeamType from 'records/TeamType.jsx';

import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

const styles = {
  group: {
    width: '80%',
    marginBottom: '16px',
  },
  sportTypeLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0 5px',
  },
};

const seasonsSortFn = (a, b) => {
  const [seasonA, yearA] = a.split(' ');
  const [seasonB, yearB] = b.split(' ');
  const seasonOrder = { Winter: 0, Spring: 1, Summer: 2, Fall: 3 };

  if (seasonOrder[seasonA] !== seasonOrder[seasonB]) {
    return seasonOrder[seasonA] - seasonOrder[seasonB];
  }

  return parseInt(yearA, 10) - parseInt(yearB, 10);
};

function TabTeamInformation(props) {
  const { intl, fieldErrors, handleInputChange, customerTeam, teamTypes } =
    props;
  const teamType = () => customerTeam.get('team_type') || new TeamType();
  const seasonsError = fieldErrors.get('seasons', List()).join(',');

  const getSeasonsList = () => {
    let seasonsList = Set();
    const selectedSeasons = customerTeam.get('seasons', Set());
    const currentYear = new Date().getFullYear();
    const nextTwoYears = currentYear + 2;

    for (let year = currentYear; year <= nextTwoYears; year += 1) {
      seasonsList = seasonsList
        .add(t('.winter', intl, __filenamespace, { year }))
        .add(t('.spring', intl, __filenamespace, { year }))
        .add(t('.summer', intl, __filenamespace, { year }))
        .add(t('.fall', intl, __filenamespace, { year }));
    }

    const seasons = selectedSeasons
      .union(seasonsList)
      .sort(seasonsSortFn)
      .toJS()
      .map(season => ({ label: season, value: season }));

    return seasons;
  };

  return (
    <Grid sx={{ justifyContent: 'flex-start', maxWidth: '560px' }}>
      <Box>
        <Box sx={styles.group}>
          <TextField
            fullWidth
            label={t('.team_title', intl, __filenamespace)}
            placeholder={t('.team_placeholder', intl, __filenamespace)}
            errorText={
              fieldErrors.get('title')
                ? fieldErrors.get('title').join(',')
                : null
            }
            onChange={handleInputChange}
            name="title"
            value={customerTeam.get('title')}
          />
        </Box>
        <Box sx={styles.group}>
          <Box component="span" sx={styles.sportTypeLabelWrapper}>
            <Typography variant="caption">
              {t('.sport_type', intl, __filenamespace)}
            </Typography>
            <InfoIcon data-tip data-for="sport-type-info" />
          </Box>
          <Dropdown
            id="sport_type_dropdown"
            fullWidth
            items={sportTypes}
            name="sport_type"
            onChange={event => handleInputChange(event, event.target.value)}
            value={customerTeam.get('sport_type')}
            placeholder={t('.sport_type_placeholder', intl, __filenamespace)}
            errorText={fieldErrors.get('sport_type', List()).join(',')}
          />
          <ReactTooltip
            id="sport-type-info"
            effect="solid"
            className="events-locations__tooltip"
          >
            {t('.sport_type_tooltip', intl, __filenamespace)}
          </ReactTooltip>
        </Box>
        <Box sx={styles.group}>
          <TeamTypeEditor
            label={t('.team_type', intl, __filenamespace)}
            placeholder={t('.team_type_placeholder', intl, __filenamespace)}
            teamTypes={teamTypes}
            errorText={fieldErrors.get('team_type', List()).join(',')}
            onTeamTypeSelect={TeamActions.teamTypeSelected}
            onCreateOrUpdateSuccess={TeamActions.teamTypeSelected}
            selectedTeamTypeId={teamType()?.id}
          />
        </Box>
        <Box sx={styles.group}>
          <MultipleSelect
            fullWidth
            selectAll
            classes={{
              root: clsx(
                'teams-editing__seasons',
                seasonsError ? 'teams-editing__seasons-error' : ''
              ),
            }}
            name="seasons"
            rounded={false}
            label={t('.seasons', intl, __filenamespace)}
            items={getSeasonsList()}
            selectedItems={customerTeam.get('seasons', Set()).toJS()}
            onChange={value =>
              handleInputChange({
                target: { name: 'seasons', value: Set(value) },
              })
            }
          />
          {seasonsError && (
            <Typography variant="error">{seasonsError}</Typography>
          )}
        </Box>
        <Box sx={styles.group}>
          <FormControlLabel
            control={
              <Switch
                name="privacy"
                onChange={handleInputChange}
                checked={customerTeam.get('privacy')}
              />
            }
            label={t('.private_team', intl, __filenamespace)}
          />
        </Box>
      </Box>
    </Grid>
  );
}

export default compose(injectIntl, memo)(TabTeamInformation);
