import React from 'react';

import { injectIntl } from 'react-intl';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

function MonthlyRecurringRevenueHeader({ intl }) {
  return <PageHeader title={t('.header', intl, __filenamespace)} />;
}

export default injectIntl(MonthlyRecurringRevenueHeader);
