export default alt.generateActions(
  'addOwner',
  'removeOwner',
  'ownerUpdated',

  'answerIdentityQuestion',
  'finishLoading',
  'loadData',
  'submitForm',
  'updatePercentageOwnership'
);
