import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';
import PDRegistrationPackageStore from 'event_mgmt/display/stores/PurchaseDrawerRegistrationPackageStore.jsx';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import SingleSessionPurchasableContentActions from 'event_mgmt/display/actions/SingleSessionPurchasableContentActions.jsx';
import SingleSessionContentStoreInterface from 'shared/stores/SingleSessionContentStoreInterface.jsx';

class SingleSessionPurchasableContentStore extends SingleSessionContentStoreInterface {
  constructor() {
    super();

    this.bindListeners({
      setStoreStates: [
        CartActions.FETCH_SUCCESS,
        EventActions.FETCH_AVAILABILITY_SUCCESS,
        PDRegistrationPackageActions.ALL_AVAILABLE_SINGLE_SESSIONS_ADDED,
        PDRegistrationPackageActions.ALL_AVAILABLE_SINGLE_SESSIONS_REMOVED,
        PDRegistrationPackageActions.SINGLE_SESSION_ADDED,
        PDRegistrationPackageActions.SINGLE_SESSION_ATTENDEE_ADDED,
        PDRegistrationPackageActions.SINGLE_SESSION_ATTENDEE_REMOVED,
        PDRegistrationPackageActions.SINGLE_SESSION_ATTENDEE_REMOVED,
        PDRegistrationPackageActions.SINGLE_SESSION_PROFILE_CREATED,
        PDRegistrationPackageActions.SINGLE_SESSION_REMOVED,
        RegistrationActions.LIST_SUCCESS,
      ],
      onProfileSelect: [
        PDRegistrationPackageActions.SINGLE_SESSION_ATTENDEE_ADDED,
        PDRegistrationPackageActions.SINGLE_SESSION_PROFILE_CREATED,
      ],
      toggleExpand: SingleSessionPurchasableContentActions.EXPAND_TOGGLED,
    });
  }

  setStoreStates() {
    super.setStoreStates(PDRegistrationPackageStore);
  }
}

export default alt.createStore(
  SingleSessionPurchasableContentStore,
  'SingleSessionPurchasableContentStore'
);
