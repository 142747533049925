/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import {
  Grid,
  Typography,
  Tabs,
  TextField,
  Spinner,
} from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import Paginator from 'shared/components/Paginator.jsx';

import { t } from 'shared/utils/LocaleUtils';

import EmailCampaign from 'shared/records/EmailCampaign';

function ClientsList({
  searchText,
  event,
  clients,
  pagination,
  clientsLoading,
  onClientsPageSelect,
  onClientSearchTextChange,
}) {
  const intl = useIntl();
  const clientsArray = clients.toArray();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={clientsArray.length === 0 && !searchText}
          value={searchText}
          icon="search"
          iconPlacement="left"
          placeholder={t('.search_clients', intl, __filenamespace)}
          classes={{
            root: 'search-input',
            startAdornment: 'search-input__search-icon',
          }}
          onChange={e => onClientSearchTextChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {clientsLoading && (
          <div className="campaign-statistics-clients__loading-container">
            <Spinner />
          </div>
        )}
        {!clientsLoading && clientsArray.length === 0 && (
          <div className="campaign-statistics-clients__loading-container">
            <Typography variant="body2">
              {t('.no_clients_found', intl, __filenamespace)}
            </Typography>
          </div>
        )}
        {!clientsLoading &&
          clientsArray.map(client => (
            <Grid container spacing={2} key={client.get('id')}>
              <Grid item container xs={5} alignItems="center" wrap="nowrap">
                <UserAvatar user={client} />
                <Typography variant="body2">{client?.name()}</Typography>
              </Grid>
              <Grid item container xs={7} alignItems="center" wrap="nowrap">
                <Typography
                  variant="body2"
                  className="campaign-statistics-clients__email"
                >
                  <a href={`mailto:${client.get('email')}`}>
                    {client.get('email')}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
      {!searchText && !clientsLoading && (
        <Grid item xs={12}>
          <Paginator
            showInfo
            pageLimit={0}
            className="campaign-statistics-clients__paginator"
            currentPage={pagination.get('page')}
            perPage={pagination.get('perPage')}
            totalCount={pagination.get('totalCount')}
            onPageSelect={(page, _) => onClientsPageSelect([page, event])}
          />
        </Grid>
      )}
    </Grid>
  );
}

function CampaignStatisticsClients({
  campaign = new EmailCampaign(),
  clients = Map({
    sent: Map(),
    opened: Map(),
    unsubscribed: Map(),
  }),
  clientsPagination = Map({
    sent: Map(),
    opened: Map(),
    unsubscribed: Map(),
  }),
  activeTab = 'sent',
  searchText = '',
  clientsLoading = false,
  onTabChange = () => {},
  onClientsPageSelect = () => {},
  onClientSearchTextChange = () => {},
}) {
  const intl = useIntl();

  return (
    <Tabs
      classes={{ root: 'campaign-statistics-clients' }}
      value={activeTab}
      onChange={(_, value) => onTabChange(value)}
      tabItems={[
        {
          label: (
            <Typography variant="body1">
              {t('.sent', intl, __filenamespace, { n: campaign.sent_count })}
            </Typography>
          ),
          value: 'sent',
          key: 'sent',
          content: (
            <ClientsList
              searchText={searchText}
              event="sent"
              clients={clients.get('sent')}
              clientsLoading={clientsLoading}
              pagination={clientsPagination.get('sent')}
              onClientsPageSelect={onClientsPageSelect}
              onClientSearchTextChange={onClientSearchTextChange}
            />
          ),
        },
        {
          label: (
            <Typography variant="body1">
              {t('.opened', intl, __filenamespace, {
                n: campaign.opened_count,
              })}
            </Typography>
          ),
          value: 'opened',
          key: 'opened',
          content: (
            <ClientsList
              searchText={searchText}
              event="opened"
              clients={clients.get('opened')}
              clientsLoading={clientsLoading}
              pagination={clientsPagination.get('opened')}
              onClientsPageSelect={onClientsPageSelect}
              onClientSearchTextChange={onClientSearchTextChange}
            />
          ),
        },
        {
          label: (
            <Typography variant="body1">
              {t('.unsubscribed', intl, __filenamespace, {
                n: campaign.unsubscribed_count,
              })}
            </Typography>
          ),
          value: 'unsubscribed',
          key: 'unsubscribed',
          content: (
            <ClientsList
              searchText={searchText}
              event="unsubscribed"
              clients={clients.get('unsubscribed')}
              clientsLoading={clientsLoading}
              pagination={clientsPagination.get('unsubscribed')}
              onClientsPageSelect={onClientsPageSelect}
              onClientSearchTextChange={onClientSearchTextChange}
            />
          ),
        },
      ]}
    />
  );
}

CampaignStatisticsClients.propTypes = {
  campaign: PropTypes.instanceOf(EmailCampaign),
  searchText: PropTypes.string,
  clientsLoading: PropTypes.bool,
  clients: PropTypes.instanceOf(Map),
  clientsPagination: PropTypes.instanceOf(Map),
  activeTab: PropTypes.string,
  onTabChange: PropTypes.func,
  onClientsPageSelect: PropTypes.func,
  onClientSearchTextChange: PropTypes.func,
};

export default memo(CampaignStatisticsClients);
