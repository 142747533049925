import { List, Set } from 'immutable';
import ServiceFee, { CODE_TYPES } from 'shared/records/ServiceFee';
import ServiceFeeArchivingActions from 'shared/actions/service_fee/ServiceFeeArchivingActions';
import ServiceFeeCreationActions from 'shared/actions/service_fee/ServiceFeeCreationActions';
import ServiceFeeEditingActions from 'shared/actions/service_fee/ServiceFeeEditingActions';
import ServiceFeeListingActions from 'shared/actions/service_fee/ServiceFeeListingActions';
import ServiceFeeListingStore from 'shared/stores/service_fee/ServiceFeeListingStore';
import EventTypeEditingActions from 'shared/actions/EventTypeEditingActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import FeeRateCreationActions from 'shared/actions/fee_rates/FeeRateCreationActions';
import FeeRateEditingActions from 'shared/actions/fee_rates/FeeRateEditingActions';
import FeeRateListingStore from 'shared/stores/fee_rates/FeeRateListingStore';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import RetailCategoryEditingActions from 'shared/actions/RetailCategoryEditingActions.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import TeamTypeEditingActions from 'shared/actions/TeamTypeEditingActions.jsx';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';
import TeamTypeListingStore from '../TeamTypeListingStore.jsx';

const url = serviceFee =>
  serviceFee.id ? `accounting_codes/${serviceFee.id}` : 'accounting_codes';

export default class ServiceFeeEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();

    this.serviceFee = null;
    this.isSaving = false;

    this.eventTypes = List();
    this.retailCategories = List();
    this.codeTypes = CODE_TYPES;
    this.feeRates = List();
    this.teamTypes = List();

    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      updateServiceFee: this.actions.UPDATED,
      updateCodeType: this.actions.CODE_TYPE_UPDATED,

      addEventType: this.actions.EVENT_TYPE_ADDED,
      removeEventType: this.actions.EVENT_TYPE_REMOVED,

      addTeamType: this.actions.TEAM_TYPE_ADDED,
      removeTeamType: this.actions.TEAM_TYPE_REMOVED,

      addRetailCategory: this.actions.RETAIL_CATEGORY_ADDED,
      removeRetailCategory: this.actions.RETAIL_CATEGORY_REMOVED,

      addRate: this.actions.RATE_ADDED,
      removeRate: this.actions.RATE_REMOVED,

      beginEdit: this.actions.BEGIN,
      cancelEdit: this.actions.CANCEL,

      createOrUpdate: this.actions.SAVE,
      createOrUpdateSuccess: this.actions.SAVE_SUCCESS,
      createOrUpdateError: this.actions.SAVE_ERROR,

      setCodeTypes: [
        ServiceFeeArchivingActions.ARCHIVE_SUCCESS,
        ServiceFeeCreationActions.SAVE_SUCCESS,
        ServiceFeeEditingActions.SAVE_SUCCESS,
        ServiceFeeListingActions.LIST_SUCCESS,
      ],

      setEventTypes: [
        ServiceFeeArchivingActions.ARCHIVE_SUCCESS,
        ServiceFeeCreationActions.SAVE_SUCCESS,
        ServiceFeeEditingActions.SAVE_SUCCESS,
        ServiceFeeListingActions.LIST_SUCCESS,
        EventTypeEditingActions.CREATE_OR_UPDATE_SUCCESS,
        EventTypeListingActions.LIST_SUCCESS,
      ],

      setTeamTypes: [
        ServiceFeeArchivingActions.ARCHIVE_SUCCESS,
        ServiceFeeCreationActions.SAVE_SUCCESS,
        ServiceFeeEditingActions.SAVE_SUCCESS,
        ServiceFeeListingActions.LIST_SUCCESS,
        TeamTypeEditingActions.CREATE_OR_UPDATE_SUCCESS,
        TeamTypeListingActions.LIST_SUCCESS,
      ],
      setRetailCategories: [
        ServiceFeeArchivingActions.ARCHIVE_SUCCESS,
        ServiceFeeCreationActions.SAVE_SUCCESS,
        ServiceFeeEditingActions.SAVE_SUCCESS,
        ServiceFeeListingActions.LIST_SUCCESS,
        RetailCategoryEditingActions.CREATE_OR_UPDATE_SUCCESS,
        RetailCategoryListingActions.LIST_SUCCESS,
      ],

      setFeeRates: [
        FeeRateCreationActions.SAVE_SUCCESS,
        FeeRateEditingActions.SAVE_SUCCESS,
      ],
    });
  }

  updateServiceFee(updatedFields) {
    if (!this.serviceFee) {
      return;
    }

    this.serviceFee = this.serviceFee.merge(updatedFields);
  }

  updateCodeType(codeType) {
    if (!this.serviceFee) {
      return;
    }

    this.eventTypes = this.eventTypes
      .concat(this.serviceFee.event_types)
      .sortBy(t => t.name);

    this.teamTypes = this.teamTypes
      .concat(this.serviceFee.team_types)
      .sortBy(t => t.name);
    this.retailCategories = this.retailCategories
      .concat(this.serviceFee.retail_categories)
      .sortBy(c => c.name);

    this.serviceFee = this.serviceFee.merge({
      code_type: codeType,
      event_type_ids: Set(),
      event_types: List(),
      retail_category_ids: Set(),
      retail_categories: List(),
      team_type_ids: Set(),
      team_types: List(),
    });
  }

  addEventType(eventTypeId) {
    const [index, eventType] = this.eventTypes.findEntry(
      t => t.id === eventTypeId,
      null,
      [-1, undefined]
    );

    if (eventType) {
      this.serviceFee = this.serviceFee.addEventType(eventType);
      this.eventTypes = this.eventTypes.delete(index);
    }
  }

  removeEventType(eventTypeId) {
    const eventType = this.serviceFee.event_types.find(
      t => t.id === eventTypeId
    );

    if (eventType) {
      this.serviceFee = this.serviceFee.removeEventType(eventType);
      this.eventTypes = this.eventTypes.push(eventType).sortBy(t => t.name);
    }
  }

  addTeamType(teamTypeId) {
    const [index, teamType] = this.teamTypes.findEntry(
      t => t.id === teamTypeId,
      null,
      [-1, undefined]
    );

    if (teamType) {
      this.serviceFee = this.serviceFee.addTeamType(teamType);
      this.teamTypes = this.teamTypes.delete(index);
    }
  }

  removeTeamType(teamTypeId) {
    const teamType = this.serviceFee.team_types.find(t => t.id === teamTypeId);

    if (teamType) {
      this.serviceFee = this.serviceFee.removeTeamType(teamType);
      this.teamTypes = this.teamTypes.push(teamType).sortBy(t => t.name);
    }
  }

  addRetailCategory(retailCategoryId) {
    const [index, retailCategory] = this.retailCategories.findEntry(
      c => c.id === retailCategoryId,
      null,
      [-1, undefined]
    );

    if (retailCategory) {
      this.serviceFee = this.serviceFee.addRetailCategory(retailCategory);
      this.retailCategories = this.retailCategories.delete(index);
    }
  }

  removeRetailCategory(retailCategoryId) {
    const retailCategory = this.serviceFee.retail_categories.find(
      c => c.id === retailCategoryId
    );

    if (retailCategory) {
      this.serviceFee = this.serviceFee.removeRetailCategory(retailCategory);
      this.retailCategories = this.retailCategories
        .push(retailCategory)
        .sortBy(c => c.name);
    }
  }

  addRate(feeRateId) {
    const [index, feeRate] = this.feeRates.findEntry(
      r => r.id === feeRateId,
      null,
      [-1, undefined]
    );
    if (feeRate) {
      this.serviceFee = this.serviceFee.addFeeRate(feeRate);
      this.feeRates = this.feeRates.delete(index);
    }
  }

  removeRate(feeRateId) {
    const feeRate = this.serviceFee.fee_rates.find(r => r.id === feeRateId);

    if (feeRate) {
      this.serviceFee = this.serviceFee.removeFeeRate(feeRate);
      this.feeRates = this.feeRates.push(feeRate);
    }
  }

  beginEdit(serviceFee) {
    this.serviceFee = serviceFee || new ServiceFee();
    this.setCodeTypes();
    this.setFeeRates();
  }

  cancelEdit() {
    this.serviceFee = null;
  }

  createOrUpdate() {
    if (this.isSaving) {
      return;
    }

    this.serviceFee = this.serviceFee.validate();

    if (this.serviceFee.isValid()) {
      this.isSaving = true;
      const params = this.serviceFee.toServer();
      delete params.charge_type;
      const saveValue = {
        url: url(this.serviceFee),
        data: JSON.stringify({ attributes: params }),
        success: this.actions.saveSuccess,
        error: this.actions.saveError,
      };

      if (this.serviceFee.id) {
        uhApiClient.put(saveValue);
      } else {
        uhApiClient.post(saveValue);
      }
    }
  }

  createOrUpdateSuccess(_data) {
    this.serviceFee = null;
    this.isSaving = false;

    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('error updating serviceFee', args);
  }

  setEventTypes() {
    this.waitFor([EventTypeListingStore, ServiceFeeListingStore]);

    const { selectedEventTypeIds } = ServiceFeeListingStore.getState();
    const { eventTypes } = EventTypeListingStore.getState();

    this.eventTypes = eventTypes.filter(t => !selectedEventTypeIds.has(t.id));
  }

  setTeamTypes() {
    this.waitFor([TeamTypeListingStore, ServiceFeeListingStore]);

    const { selectedTeamTypeIds } = ServiceFeeListingStore.getState();
    const { teamTypes } = TeamTypeListingStore.getState();

    this.teamTypes = teamTypes.filter(t => !selectedTeamTypeIds.has(t.id));
  }

  setRetailCategories() {
    this.waitFor([RetailCategoryListingStore, ServiceFeeListingStore]);

    const { selectedRetailCategoryIds } = ServiceFeeListingStore.getState();
    const { retailCategories } = RetailCategoryListingStore.getState();

    this.retailCategories = retailCategories.filterNot(c =>
      selectedRetailCategoryIds.has(c.id)
    );
  }

  setFeeRates(data) {
    if (!this.serviceFee) {
      return;
    }

    this.waitFor([FeeRateListingStore]);
    const { feeRates } = FeeRateListingStore.getState();

    this.feeRates = feeRates
      .filter(
        t =>
          !this.serviceFee.service_fee_ids ||
          !this.serviceFee.service_fee_ids.includes(t.id)
      )
      .sortBy(t => t.name);

    if (data && data.id) {
      this.addRate(data.id);
    }

    this.serviceFee = this.serviceFee.set(
      'fee_rates',
      feeRates
        .filter(t => this.serviceFee.service_fee_ids.includes(t.id))
        .sortBy(t => t.name)
    );
  }

  setCodeTypes() {
    this.waitFor(ServiceFeeListingStore);

    const { selectedCodeTypes } = ServiceFeeListingStore.getState();
    const currentCodeType = this.serviceFee && this.serviceFee.code_type;

    // Only MEMBERSHIP_CODE_TYPE must be unique.
    this.codeTypes = CODE_TYPES.filterNot(
      type =>
        (type === CODE_TYPES.MEMBERSHIP_CODE_TYPE ||
          type === CODE_TYPES.CREDIT_PASS_CODE_TYPE) &&
        type !== currentCodeType &&
        selectedCodeTypes.has(type)
    );
  }
}
