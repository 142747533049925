import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import Date from 'shared/components/icons/Date.jsx';
import Key from 'shared/components/icons/Key.jsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ClockBack from 'shared/components/icons/ClockBack.jsx';
import CreditCard from 'shared/components/icons/CreditCard.jsx';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { REDEMPTION_LIMIT_STRATEGIES } from 'records/Coupon';
import { messageId, t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import CouponUsageLimit from '../../couponList/components/CouponUsageLimit.jsx';
import DiscountableItems from './DiscountableItems.jsx';

const iconStyle = {
  marginRight: 8,
  width: 16,
  height: 16,
};

function ViewCard({ coupon, intl }) {
  return (
    <Grid container className="coupon__row">
      <Grid container item className="coupon__view-item">
        <Typography variant="subtitle1" className="coupon__text-with-icon">
          <LoyaltyIcon
            style={{ ...{ color: 'var(--color-tab-gray)', ...iconStyle } }}
          />
          <FormattedMessage
            id={messageId('.value', __filenamespace)}
            values={{
              value: coupon.discount.isPercent() ? (
                <FormattedNumber
                  value={coupon.get('value') / 100}
                  // eslint-disable-next-line react/style-prop-object
                  style="percent"
                />
              ) : (
                <FormattedCurrency value={coupon.get('value')} fromCents />
              ),
            }}
          />
        </Typography>
      </Grid>
      <Grid container item className="coupon__view-item">
        <Typography
          variant="subtitle1"
          className="coupon__text-with-icon coupon__code"
        >
          <Key style={iconStyle} />
          {coupon.get('code')}
        </Typography>
      </Grid>
      <Grid container item className="coupon__view-item">
        <Typography variant="subtitle1" className="coupon__text-with-icon">
          <Date style={iconStyle} />
          {coupon.dateText()}
        </Typography>
      </Grid>
      <Grid container item className="coupon__view-item">
        <Typography variant="subtitle1" className="coupon__text-with-icon">
          <CreditCard style={iconStyle} />
          {t(`.${coupon.get('coupon_type')}`, intl, __filenamespace)}
        </Typography>
      </Grid>
      <Grid
        container
        item
        justify="space-between"
        alignItems="center"
        className="coupon__view-item"
      >
        <Grid item>
          <Typography variant="subtitle1" className="coupon__text-with-icon">
            <ClockBack style={iconStyle} />
            <CouponUsageLimit coupon={coupon} />
          </Typography>
        </Grid>
        {coupon.get('redemption_limit_strategy') ===
          REDEMPTION_LIMIT_STRATEGIES.global && (
          <Grid item>
            <Typography variant="subtitle1" className="coupon__type">
              <FormattedMessage
                id={messageId('.usage', __filenamespace)}
                values={{
                  redemption_limit: coupon.get('redemption_limit'),
                  redemption_count: coupon.get('redemption_count'),
                }}
              />
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item className="coupon__description">
        <Typography variant="body1">{coupon.get('description')}</Typography>
      </Grid>
      <DiscountableItems coupon={coupon} />
    </Grid>
  );
}

ViewCard.propTypes = {
  coupon: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default ViewCard;
