import ReactGA from 'react-ga';
import * as FullStory from '@fullstory/browser';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { isUpperHandTheme } from 'shared/utils/ThemeUtils';

/* eslint-disable */
const clarityInit = () => {
  if (window.clarity) return;

  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = `https://www.clarity.ms/tag/${i}`;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', 'erlhj39yvx');
};

// Pendo js init snipit
/* eslint-disable */
const pendoInit = pendoKey => {
  // make sure we want to run and have a key.
  // make sure we don't double render.
  if (window.pendo || !pendoKey) {
    return;
  }

  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + pendoKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
};
// /* eslint-enable */

const pendoSetContext = () => {
  const customer = currentCustomer();
  const user = currentUser();
  let visitor = {};
  let account = {};

  // make sure pendo has been init, it will only be init if we are running it in this env
  if (!window.pendo) {
    return;
  }

  if (customer && customer.uuid) {
    account = {
      id: customer.uuid,
      name: customer.name,
      creationDate: customer.created_at,
      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    };
  }

  if (user && user.id) {
    //we should switch user.id to this to user.uuid but users would have to go through all our guids again.
    visitor = {
      id: user.id,
      email: user.email,
      role: user.role,
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    };

    if (FullStory.getCurrentSessionURL) {
      visitor.mostRecentFullStorySession = FullStory.getCurrentSessionURL();
    }
  }
  pendo.initialize({
    visitor: visitor,
    account: account,
  });
};

export const init = () => {
  if (window.environment === 'production') {
    if (window.fsOrg) {
      FullStory.init({ orgId: window.fsOrg });
    }

    pendoInit(window.pendoKey);

    // Init clarity only for SMB instance
    if (isUpperHandTheme()) {
      clarityInit();
    }

    if (window.GAid) {
      ReactGA.initialize(window.GAid);
    }
  }
};

export const urlChange = location => {
  if (window.environment === 'production' && window.GAid) {
    ReactGA.set({
      page: location.pathname,
    });
    ReactGA.pageview(location.pathname);
    ReactGA.event({
      category: 'Test',
      action: 'Testing action',
    });
  }
};

export const actionFired = () => {
  throw new Error('not yet implemented');
  //   ReactGA.event({
  //     category: 'Sign Up',
  //     action: 'User pressed the big blue sign up button',
  //   });
};

export const setContext = () => {
  if (window.environment === 'production') {
    if (window.GAid) {
      if (currentCustomer()) {
        ReactGA.set({
          customerName: currentCustomer().name,
        });
      }

      const user = currentUser();
      if (user && user.id) {
        ReactGA.set({
          customerUserType: user.role,
        });
      }
    }

    // see if window.FS has been initiallized. the FullStory npm package still sets window.FS
    if (window.FS && user && user.id) {
      FullStory.identify(user.id.toString(), {
        displayName: user.name,
        email: user.email,
      });
    }

    // See if clarity has been initialized. Send data with friendly-name.
    if (window.clarity && user && user.id) {
      window.clarity('identify', user.email, null, null, user.name);
    }
  }

  pendoSetContext();
};
