import * as React from 'react';
import { injectIntl } from 'react-intl';
import {
  Dropdown,
  Typography,
  Button,
  Drawer,
  Grid,
} from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import FilterAccordion from 'components/FilterAccordion.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import eventSeasons from 'shared/constants/eventSeasons.js';

import BrowseEventsActions from '../Actions';

const styles = {
  root: {
    padding: '16px',
    width: '100%',
  },
  disableButtonStyles: {
    backgroundColor: uhColors.tunaBlack,
  },
  searchbarIconStyles: {
    marginRight: '10px',
    color: uhColors.lighterGrey,
    fontSize: '30px',
  },
  searchbarStyles: {
    fontSize: 16,
    flexGrow: 2,
    width: '100%',
    backgroundColor: uhColors.white,
    height: '42px',
    borderRadius: '42px',
    padding: '0 10px',
  },
  searchbarInputStyles: {
    color: '#000',
  },
  clearFiltersWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonContainer: {
    marginTop: '10px',
    marginLeft: '16px',
  },
};

const BrowseEventsCompact = React.memo(
  ({
    children,
    eventItems,
    eventTypeFilter,
    intl,
    isFiltersDrawerOpen,
    locationItems,
    locationFilter,
    staffItems,
    staffFilter,
    viewMode,
    viewOptions,
    staffAllSelected,
    sportTypeItems,
    sportTypeFilter,
    eventTypeAllSelected,
    locationAllSelected,
    sportTypeAllSelected,
    seasonFilter,
    seasonAllSelected,
    ageFilter,
    search,
  }) => {
    const showClearAgeFilter = ageFilter.some(f => Boolean(f));
    const disableApplyAge = ageFilter.every(f => !f);

    return (
      <Box sx={styles.root}>
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid container item spacing={1} xs={12}>
            <Grid item xs>
              <SearchBar
                useStartAdornment
                searchText={search}
                endAdornment={
                  search ? (
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() => BrowseEventsActions.clearFilters(true)}
                    >
                      <Close />
                    </IconButton>
                  ) : null
                }
                iconStyle={styles.searchbarIconStyles}
                style={styles.searchbarStyles}
                inputStyle={styles.searchbarInputStyles}
                onTypingEnd={value =>
                  BrowseEventsActions.searchFilterUpdated(value)
                }
              />
            </Grid>
            <Grid item>
              <Button
                onClick={BrowseEventsActions.openFiltersDrawer}
                type="tertiary"
                rounded
                icon="filter"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            {children}
          </Grid>
        </Grid>
        <Drawer
          open={isFiltersDrawerOpen}
          classes={{ root: 'browse-events-drawer' }}
          title={t('.filters', intl, __filenamespace)}
          onClose={BrowseEventsActions.closeFiltersDrawer}
          content={
            <>
              <FilterAccordion
                title="View Options"
                accordionNode={
                  <Dropdown
                    fullWidth
                    outline
                    items={viewOptions}
                    rounded={false}
                    onChange={BrowseEventsActions.toggleBrowseViewMode}
                    value={viewMode}
                  />
                }
              />
              <FilterAccordion
                showColorItem
                title={t('.category', intl, __filenamespace)}
                allTitle={t('.all_categories', intl, __filenamespace)}
                items={eventItems}
                filterValue={eventTypeFilter}
                allSelected={eventTypeAllSelected}
                onChange={BrowseEventsActions.eventTypeFilterUpdated}
              />
              <FilterAccordion
                title={t('.location', intl, __filenamespace)}
                allTitle={t('.all_locations', intl, __filenamespace)}
                items={locationItems}
                filterValue={locationFilter}
                allSelected={locationAllSelected}
                onChange={BrowseEventsActions.locationFilterUpdated}
              />
              <FilterAccordion
                title={t('.sport_type', intl, __filenamespace)}
                allTitle={t('.all_sport_types', intl, __filenamespace)}
                items={sportTypeItems}
                filterValue={sportTypeFilter}
                allSelected={sportTypeAllSelected}
                onChange={BrowseEventsActions.sportTypeFilterUpdated}
              />
              <FilterAccordion
                title={t('.season', intl, __filenamespace)}
                allTitle={t('.all_seasons', intl, __filenamespace)}
                items={eventSeasons}
                filterValue={seasonFilter}
                allSelected={seasonAllSelected}
                onChange={BrowseEventsActions.seasonFilterUpdated}
              />
              <FilterAccordion
                title={
                  <>
                    {t('.age', intl, __filenamespace)}
                    {showClearAgeFilter && (
                      <Button
                        type="tertiary"
                        classes={{
                          root: 'browse-events-drawer__clear-filters',
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          BrowseEventsActions.clearFilter('age');
                        }}
                      >
                        {t('.clear_filter', intl, __filenamespace)}
                      </Button>
                    )}
                  </>
                }
                accordionNode={
                  <Box sx={styles.inputGroup}>
                    <Box sx={styles.inputContainer}>
                      <InputLabel>
                        {t('.min', intl, __filenamespace)}
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        value={ageFilter[0] || ''}
                        onChange={event => {
                          BrowseEventsActions.ageFilterUpdated([
                            event.target.value,
                            ageFilter[1],
                          ]);
                        }}
                      />
                    </Box>
                    <Box sx={{ margin: '20px 5px 5px' }}>
                      <Typography variant="body1">—</Typography>
                    </Box>
                    <Box sx={styles.inputContainer}>
                      <InputLabel>
                        {t('.max', intl, __filenamespace)}
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        value={ageFilter[1] || ''}
                        onChange={event => {
                          BrowseEventsActions.ageFilterUpdated([
                            ageFilter[0],
                            event.target.value,
                          ]);
                        }}
                      />
                    </Box>
                    <Box sx={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={disableApplyAge}
                        onClick={BrowseEventsActions.applyFilters}
                      >
                        {t('.apply', intl, __filenamespace)}
                      </Button>
                    </Box>
                  </Box>
                }
                filterValue={ageFilter}
              />
              <FilterAccordion
                title={t('.staff', intl, __filenamespace)}
                allTitle={t('.all_staff', intl, __filenamespace)}
                items={staffItems}
                filterValue={staffFilter}
                allSelected={staffAllSelected}
                onChange={BrowseEventsActions.staffFilterUpdated}
              />
              <Box sx={styles.clearFiltersWrapper}>
                <Button
                  type="tertiary"
                  color="secondary"
                  classes={{ root: 'browse-events-drawer__clear-filters' }}
                  onClick={() => BrowseEventsActions.clearFilters()}
                >
                  {t('.clear_filters', intl, __filenamespace)}
                </Button>
              </Box>
            </>
          }
        />
      </Box>
    );
  }
);

export default injectIntl(BrowseEventsCompact);
