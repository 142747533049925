class CardReaderDropdownActions {
  constructor() {
    this.generateActions(
      'mounted',
      'selectCardReader',
      'listCardReadersSuccess',
      'listCardReadersError',
      'refresh',
      'syncSuccess',
      'syncError',
      'pingSuccess'
    );
  }
}

export default alt.createActions(CardReaderDropdownActions);
