import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FilterListIcon from '@mui/icons-material/FilterList';

import FilteredSelection from 'shared/records/FilteredSelection.js';

import ButtonMenu from 'shared/components/ButtonMenu.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  container: {
    width: 300,
    padding: '2px 0',
  },
  menuItem: {
    padding: '10px 16px',
  },
};

class FilterMenu extends React.PureComponent {
  handleClickAll = value => {
    const { onChange, filteredSelection } = this.props;
    onChange(filteredSelection.changeSelectionAll(value));
  };

  handleClick = (key, value) => {
    const { onChange, filteredSelection } = this.props;
    onChange(filteredSelection.changeSelectionItem(key, value));
  };

  render() {
    const { intl, disabled, filteredSelection } = this.props;

    return (
      <ButtonMenu
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        icon={<FilterListIcon sx={{ color: uhColors.grey }} />}
        disabled={disabled}
        buttonType="icon"
        menuStyle={styles.container}
      >
        <FormGroup key="all-categories">
          <FormControlLabel
            control={<Checkbox style={styles.menuItem} />}
            label={t('.all-categories', intl, __filenamespace)}
            checked={filteredSelection.isSelectedAll()}
            onChange={e => this.handleClickAll(e.target.checked)}
          />
        </FormGroup>
        <Divider />
        {filteredSelection.get('source').map(item => (
          <FormGroup key={item.key}>
            <FormControlLabel
              control={<Checkbox style={styles.menuItem} />}
              label={item.value}
              checked={filteredSelection.isSelectedItem(item.key)}
              onChange={e => this.handleClick(item.key, e.target.checked)}
            />
          </FormGroup>
        ))}
      </ButtonMenu>
    );
  }
}

FilterMenu.propTypes = {
  intl: PropTypes.object,
  filteredSelection: PropTypes.instanceOf(FilteredSelection).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FilterMenu.defaultProps = {
  intl: {},
  disabled: true,
  onChange: () => {},
};

export default injectIntl(FilterMenu);
