import { Record } from 'immutable';

import OWNER_TYPE from 'calendar/types/OwnerType.jsx';

class CalendarMeta extends Record({
  id: undefined,
  ownerType: OWNER_TYPE.STAFF,
  positionIndex: -1,
}) {
  constructor(props) {
    if (Object.values(OWNER_TYPE).indexOf(props.ownerType) < 0) {
      throw new Error('Invalid calendar owner type');
    }

    if (!props.id) {
      throw new Error('Invalid owner id');
    }

    super(props);
  }
}

export default CalendarMeta;
