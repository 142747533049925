import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { Set } from 'immutable';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import { EventSource } from 'sources';

import BrowseEventsBookDrawerActions from './Actions';

class BrowseEventsBookDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      openDrawer: BrowseEventsBookDrawerActions.openDrawer,
      closeDrawer: BrowseEventsBookDrawerActions.closeDrawer,
      scheduleSession: BrowseEventsBookDrawerActions.scheduleSession,
      sessionBooked: BrowseEventsBookDrawerActions.sessionBooked,
      fetchEventSuccess: BrowseEventsBookDrawerActions.fetchEventSuccess,
      fetchEventError: BrowseEventsBookDrawerActions.fetchEventError,
    });
  }

  reset() {
    this.clientId = null;
    this.eventId = null;
    this.sessionIds = Set();
    this.event = new CustomerEvent();
    this.isOpen = false;
    this.isLoading = false;
  }

  openDrawer({ eventId, sessionIds = Set() }) {
    this.eventId = eventId;
    this.sessionIds = sessionIds;
    this.isOpen = true;
    this.handleFetch();
  }

  closeDrawer() {
    this.reset();
  }

  handleFetch() {
    this.isLoading = true;

    EventSource.fetch({
      id: this.eventId,
      success: BrowseEventsBookDrawerActions.fetchEventSuccess,
      error: BrowseEventsBookDrawerActions.fetchEventError,
    });
  }

  scheduleSession(clientId) {
    this.clientId = clientId;
  }

  sessionBooked() {
    const message = new TranslatableMessage({
      id: 'containers.browseEventsBookDrawer.session_booked',
    });
    MessageWindowActions.addMessage.defer(message);
    this.closeDrawer();
  }

  fetchEventSuccess(event) {
    this.event = event;
  }

  fetchEventError() {
    this.isLoading = false;
  }
}

export default alt.createStore(
  BrowseEventsBookDrawerStore,
  'BrowseEventsBookDrawerStore'
);
