import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import SortableCalendars from 'calendar/components/customize_calendar_drawer/_SortableCalendars.jsx';
import AddCalendarsDropdown from 'calendar/components/customize_calendar_drawer/_AddCalendarsDropdown.jsx';
import ViewProperties from 'calendar/components/customize_calendar_drawer/_ViewProperties.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';

import CalendarStore from 'calendar/stores/CalendarStore.jsx';
import CalendarViewStore from 'calendar/stores/CalendarViewStore.jsx';
import CurrentViewSection from 'calendar/components/customize_calendar_drawer/_CurrentViewSection.jsx';
import ResourceListingStore from 'resources/stores/ResourceListingStore.js';
import StaffStore from 'shared/stores/StaffStore.jsx';

const styles = {
  Overlay: {
    opacity: 0,
    backgroundColor: 'transparent',
  },
  Header: isMobile => ({
    padding: !isMobile ? '8px 8px 8px 32px' : '8px 8px 8px 16px',
    backgroundColor: 'var(--color-old-gray)',
    color: '#fff',
  }),
  Content: isMobile => ({
    padding: !isMobile ? '16px 32px' : '16px',
  }),
  Instructions: {
    color: '#888c9d',
    lineHeight: '1.4em',
  },
  SortableWrapper: {
    margin: '16px 0',
    padding: '16px 0',
    borderTop: '1px solid #e5e6e5',
  },
  SaveButton: {
    marginRight: '16px',
  },
  RemoveButton: {
    margin: '0 0 0 auto',
  },
  ItemCloseIcon: {
    pointerEvents: 'none',
  },
  Literal: `
    .CustomizeCalendarDrawer .visibleStaff [draggable]{
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      -khtml-user-drag: element;
      -webkit-user-drag: element;
    }

    .sortableHelper {
      z-index: 1500;
    }
  `,
};

const createOrUpdateCalendar = (e, currentViewTitle) => {
  e.preventDefault();

  CalendarViewActions.createOrUpdate({ title: currentViewTitle });
};

const discardCalendarChanges = () => {
  CalendarViewActions.discardChanges();
};

const removeCalendar = id => {
  CalendarViewActions.delete(id);
};

const closeDrawer = e => {
  e.preventDefault();

  CalendarViewActions.toggleShowCustomizeDrawer(false);
};

const handleToggleShow = () => {
  CalendarViewActions.toggleShowCustomizeDrawer();
};

function Header({ isMobile, intl }) {
  return (
    <header style={styles.Header(isMobile)}>
      <FlexBoxJustify style={{ alignItems: 'center' }}>
        <span>{t('.customize_columns', intl, __filenamespace)}</span>
        <IconButton sx={{ color: 'common.white' }} onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </FlexBoxJustify>
    </header>
  );
}

function Instructions() {
  return (
    <div style={styles.Instructions}>
      <FormattedMessage
        id={messageId('.customize_columns_instructions', __filenamespace)}
      />
    </div>
  );
}

function ActionItems({
  currentCalendarView,
  currentViewDirty,
  currentViewTitle,
  onRequestDelete,
  intl,
}) {
  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        disabled={!currentViewDirty || currentViewTitle.trim() === ''}
        onClick={e => createOrUpdateCalendar(e, currentViewTitle)}
      >
        {t(
          currentCalendarView.id ? '.save' : '.save_new',
          intl,
          __filenamespace
        )}
      </Button>
      <Button
        variant="outlined"
        disabled={!currentViewDirty}
        onClick={discardCalendarChanges}
      >
        {t('.cancel', intl, __filenamespace)}
      </Button>
      {currentCalendarView.id >= 0 && (
        <Button
          variant="outlined"
          onClick={() => onRequestDelete(currentCalendarView.id)}
        >
          {t('.remove', intl, __filenamespace)}
        </Button>
      )}
    </Stack>
  );
}

const DrawerContents = injectIntl(props => {
  const {
    isMobile,
    intl,
    calendarViewStore,
    staffStore,
    resourceListingStore,
    onTitleChange,
    onRequestDelete,
    currentViewTitle,
  } = props;

  const { currentCalendarView, calendarViews, dirty, showAddCalendarsMenu } =
    calendarViewStore;
  const currentViewDirty =
    dirty || currentViewTitle !== currentCalendarView.title;
  const currentViewId = currentCalendarView.id;

  return (
    <div>
      <style>{styles.Literal}</style>
      <Header isMobile={isMobile} intl={intl} />
      <div style={styles.Content(isMobile)}>
        <Instructions />
        <CurrentViewSection
          currentCalendarView={currentCalendarView}
          calendarViews={calendarViews}
        />
        <ViewProperties
          calendarView={currentCalendarView}
          currentViewTitle={currentViewTitle}
          onTitleChange={onTitleChange}
        />
        {(!currentViewId || currentViewId >= 0) && (
          <AddCalendarsDropdown
            open={showAddCalendarsMenu}
            calendarView={currentCalendarView}
            staff={staffStore.allStaff}
            resources={resourceListingStore.resources}
          />
        )}
        <SortableCalendars
          calendarView={currentCalendarView}
          staff={staffStore.allStaff}
          resources={resourceListingStore.resources}
        />
        <ActionItems
          currentCalendarView={currentCalendarView}
          currentViewDirty={currentViewDirty}
          currentViewTitle={currentViewTitle}
          onRequestDelete={onRequestDelete}
          intl={intl}
        />
      </div>
    </div>
  );
});

function DrawerAltWrapper(props) {
  const { calendarViewStore } = props;
  const { showCustomizeDrawer } = calendarViewStore;
  const isMobile = smallScreen();

  return (
    <Drawer
      hideBackdrop
      disableEnforceFocus
      anchor="right"
      open={showCustomizeDrawer}
      PaperProps={{
        sx: {
          width: isMobile ? window.innerWidth * 0.9 : STANDARD_DRAWER_WIDTH,
        },
      }}
      onClose={handleToggleShow}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DrawerContents isMobile={isMobile} {...props} />
    </Drawer>
  );
}

class CustomizeCalendarDrawer extends React.Component {
  constructor(props) {
    super(props);

    const { calendarViewStore } = this.props;
    const { currentCalendarView } = calendarViewStore;

    this.state = {
      currentViewTitle: currentCalendarView.title,
      requestedDelete: false,
      viewToDelete: null,
    };

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onRequestDelete = this.onRequestDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.hideDeleteConfirmation = this.hideDeleteConfirmation.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { calendarViewStore: currentCalendarViewStore } = this.props;
    const { currentCalendarView } = currentCalendarViewStore;
    const { calendarViewStore: prevCalendarViewStore } = prevProps;
    const { currentCalendarView: prevCalendarView } = prevCalendarViewStore;

    if (currentCalendarView.id !== prevCalendarView.id) {
      this.setState({ currentViewTitle: currentCalendarView.title });
    }
  }

  onTitleChange(value) {
    this.setState({ currentViewTitle: value });
  }

  onRequestDelete(id) {
    this.setState({ requestedDelete: true, viewToDelete: id });
  }

  confirmDelete() {
    const { viewToDelete } = this.state;

    removeCalendar(viewToDelete);

    this.hideDeleteConfirmation();
  }

  hideDeleteConfirmation() {
    this.setState({ requestedDelete: false, viewToDelete: null });
  }

  render() {
    const { intl } = this.props;
    const { currentViewTitle: title, requestedDelete } = this.state;

    return (
      <AltContainer
        stores={{
          calendarStore: CalendarStore,
          calendarViewStore: CalendarViewStore,
          staffStore: StaffStore,
          resourceListingStore: ResourceListingStore,
        }}
      >
        <DrawerAltWrapper
          onTitleChange={this.onTitleChange}
          onRequestDelete={this.onRequestDelete}
          currentViewTitle={title}
        />
        <ConfirmationDialog
          awaitingConfirmation={requestedDelete}
          onCancel={this.hideDeleteConfirmation}
          onConfirm={this.confirmDelete}
          title={t('.delete_confirmation_title', intl, __filenamespace, {
            title,
          })}
        >
          <FormattedMessage
            id={messageId('.delete_message', __filenamespace)}
            values={{ title }}
          />
        </ConfirmationDialog>
      </AltContainer>
    );
  }
}

export default injectIntl(CustomizeCalendarDrawer);
