import * as React from 'react';
import Circle from 'shared/components/Circle.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';

function ProductInformation({
  primaryText,
  secondaryText,
  color,
  typeText,
  price,
  style,
}) {
  return (
    <div style={style}>
      <div style={{ fontSize: 16, marginBottom: 4 }}>{primaryText}</div>
      <div style={{ marginBottom: 15 }}>{secondaryText}</div>

      <FlexBoxJustify style={{ paddingBottom: 15 }}>
        <FlexBoxCenter>
          {color && (
            <Circle
              diameter={8}
              color={color}
              style={{ marginBottom: 2, marginRight: 8 }}
            />
          )}
          <div>{typeText}</div>
        </FlexBoxCenter>
        {price}
      </FlexBoxJustify>
    </div>
  );
}

export default ProductInformation;
