import PropTypes from 'prop-types';
import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ContentModal, Typography } from '@upperhand/playmaker';

import { t, messageId } from 'shared/utils/LocaleUtils.js';
import { mailTo } from 'shared/utils/SharedUtils.js';

import CustomerCreationActions from 'containers/accounts/createCustomer/Actions';

function DuplicateOrgNameModal({ customerName, intl, isOpen }) {
  return (
    <ContentModal
      open={isOpen}
      title={t('.title', intl, __filenamespace)}
      successLabel={t('.button_1', intl, __filenamespace)}
      cancelLabel={t('.button_2', intl, __filenamespace)}
      onSuccess={() =>
        mailTo({
          email: 'support@getupperhand.com',
          subject: t('.mail_subject', intl, __filenamespace),
        })
      }
      onAbort={CustomerCreationActions.closeDuplicateOrgNameModal}
    >
      <Typography variant="dialogContent">
        <FormattedMessage
          id={messageId('.text', __filenamespace)}
          values={{
            customerName: (
              <span style={{ fontWeight: 'bold' }}>{customerName}</span>
            ),
          }}
        />
      </Typography>
    </ContentModal>
  );
}

DuplicateOrgNameModal.propTypes = {
  customerName: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default injectIntl(DuplicateOrgNameModal);
