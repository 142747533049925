import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';

import ClockIcon from 'shared/components/icons/Clock4pm.jsx';

import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { formattedHourString } from 'calendar/utils/CalendarUtils.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

const timeRangeText = dateRange => {
  const formattedStart = formattedHourString(dateRange.start, {
    trailingZeros: true,
    truncateMeridiem: false,
  });
  const formattedEnd = formattedHourString(dateRange.end, {
    trailingZeros: true,
    truncateMeridiem: false,
  });

  return `${formattedStart}    -    ${formattedEnd}`;
};

const handleChangeTime = (e, dateRange, onRequestClose) => {
  e.preventDefault();

  QuickScheduleActions.updateDateRange(dateRange);

  onRequestClose();
};

function AvailableTimes({ availability, event, onRequestClose }) {
  return (
    <Paper>
      {availability.map(a => {
        const dateRange = a.toSessionRange(event, customerTZ());

        return (
          <MenuItem
            key={a.time}
            sx={{ fontSize: 16 }}
            onClick={e => handleChangeTime(e, dateRange, onRequestClose)}
          >
            {timeRangeText(dateRange)}
          </MenuItem>
        );
      })}
    </Paper>
  );
}

class TimeSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
    };

    this.handleButtonTap = this.handleButtonTap.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  // Material-UI < v1.0 doesn't support overlayStyle for PopOvers
  // so we have to set the style manually here. --joshuamanns
  componentDidUpdate() {
    // We need to bring this popover above
    // the popover used for the Scheduler.
    const el = document.querySelector('.timeSelectorPopover');

    if (el) {
      el.parentElement.style.zIndex = 2002;
    }
  }

  handleButtonTap(e) {
    e.preventDefault();

    this.setState({
      open: true,
      anchorEl: e.currentTarget,
    });
  }

  handleRequestClose() {
    this.setState({ open: false });
  }

  render() {
    const { open, anchorEl } = this.state;
    const { quickScheduleStore } = this.props;
    const { dateRange, scheduleAvailabilities, selectedEvent } =
      quickScheduleStore;

    return (
      <div style={{ flex: '0 0  50%', padding: '0 15px' }}>
        <FlexBox
          style={{
            borderBottom: '1px solid var(--color-divide)',
            alignItems: 'center',
            minHeight: 42,
          }}
        >
          <ClockIcon style={{ height: 16, width: 16 }} />
          <FlexBox
            style={{
              width: '100%',
              height: 28,
              lineHeight: '28px',
              cursor: 'pointer',
              justifyContent: 'space-evenly',
              padding: '0 15px',
            }}
            onClick={this.handleButtonTap}
          >
            <div>
              {formattedHourString(dateRange.start, {
                trailingZeros: true,
                truncateMeridiem: false,
              })}
            </div>
            <div>-</div>
            <div>
              {formattedHourString(dateRange.end, {
                trailingZeros: true,
                truncateMeridiem: false,
              })}
            </div>
          </FlexBox>
        </FlexBox>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          className="timeSelectorPopover"
          onClose={this.handleRequestClose}
          open={open}
        >
          <AvailableTimes
            availability={scheduleAvailabilities.get(selectedEvent?.id)}
            event={selectedEvent}
            onRequestClose={this.handleRequestClose}
          />
        </Popover>
      </div>
    );
  }
}

export default TimeSelector;
