import * as React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import { Spinner } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import EmptyState from 'containers/reports/ordersReport/components/OrdersReportTable/EmptyState.jsx';

import ClientProfileActions from 'containers/clientProfile/Actions';
import OrderSummaryDrawerActions from 'containers/reports/orderSummaryDrawer/Actions';

import { OrderDataStore } from 'dataStores';

import OrderItem from './OrderItem.jsx';

function Orders({
  actions,
  intl,
  loading,
  pagination,
  orderIds,
  isMobile,
  orderDataStore: { orders },
}) {
  React.useEffect(() => {
    actions.listOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginationOrders = pagination.get('orders');

  const handleMenuItemCLick = (itemType, order) => {
    if (itemType === 'details') {
      OrderSummaryDrawerActions.openDrawer(order.id);
    }
  };

  return (
    <div className="orders__list-container">
      {loading && (
        <FlexBoxJustifyCenter className="orders__loading">
          <Spinner className="orders__loading" />
        </FlexBoxJustifyCenter>
      )}
      {!loading &&
        (orderIds.size === 0 ? (
          <EmptyState intl={intl} />
        ) : (
          <>
            {orderIds.map(orderId => (
              <OrderItem
                key={orderId}
                isMobile={isMobile}
                intl={intl}
                orderId={orderId}
                orders={orders}
                onMenuItemClick={handleMenuItemCLick}
              />
            ))}
            <Paginator
              className="orders__pagination"
              currentPage={paginationOrders.page}
              perPage={paginationOrders.perPage}
              totalCount={paginationOrders.totalCount}
              onPageSelect={actions.listOrdersPrevNext}
              pageLimit={0}
              showInfo
            />
          </>
        ))}
    </div>
  );
}

Orders.propTypes = {
  orderIds: PropTypes.instanceOf(OrderedSet).isRequired,
  orderDataStore: PropTypes.object,
  isMobile: PropTypes.bool,
  actions: PropTypes.object,
};

Orders.defaultProps = {
  actions: ClientProfileActions,
  orderDataStore: {},
  isMobile: false,
};

export default altContainer({
  stores: { orderDataStore: OrderDataStore },
})(Orders);
