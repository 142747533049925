import Coupon from 'shared/records/Coupon.jsx';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import { OrderSource } from 'sources';

import {
  REVIEW,
  PAYMENT_SELECT,
  PROCESSING,
  SUMMARY,
} from 'shared/components/checkout/CheckoutStepper.jsx';

class POSCheckoutStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      switchToReview: POSCheckoutActions.RETURN_TO_REVIEW_CLICKED,
      switchToPaymentSelect: POSCheckoutActions.CONTINUE_TO_PAYMENT_CLICKED,
      switchToSummary: POSCheckoutActions.SWITCH_TO_SUMMARY,

      closeEmbedWarning: POSCheckoutActions.CLOSE_EMBED_WARNING,

      fetchOrderSuccess: POSCheckoutActions.FETCH_ORDER_SUCCESS,
      fetchOrderError: POSCheckoutActions.FETCH_ORDER_ERROR,

      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
        POSCheckoutActions.REVIEW_BACK_CLICKED,
      ],
    });
  }

  reset() {
    this.step = REVIEW;
    this.embedErrors = [];

    this.activeCoupon = null;
    this.isLoadingCoupon = false;
  }

  applyCoupon({ code }) {
    this.isLoadingCoupon = true;

    return uhApiClient.get({
      url: `coupons/codes/${code}`,
      success: POSCheckoutActions.fetchCouponSuccess,
      error: POSCheckoutActions.fetchCouponError,
    });
  }

  fetchCouponSuccess(data) {
    this.isLoadingCoupon = false;
    this.activeCoupon = new Coupon(data);
  }

  fetchCouponError(...args) {
    this.isLoadingCoupon = false;
    this.notifyError('error while fetching coupon', args);
  }

  // eslint-disable-next-line class-methods-use-this
  switchToSummary(orderId) {
    this.step = PROCESSING;
    OrderSource.fetch({
      id: orderId,
      params: { fields: ['order_items'] },
      success: POSCheckoutActions.fetchOrderSuccess,
      error: POSCheckoutActions.fetchOrderError,
    });
  }

  fetchOrderSuccess(order) {
    this.embedErrors = order.get('completion_details').embed_errors || [];
    this.step = SUMMARY;
  }

  fetchOrderError(...args) {
    this.notifyError('Error order fetch after payment complete', args);
  }

  switchToReview() {
    this.step = REVIEW;
  }

  switchToPaymentSelect() {
    this.step = PAYMENT_SELECT;
  }

  switchToProcessing() {
    this.step = PROCESSING;
  }

  handleCheckoutError() {
    this.step = PAYMENT_SELECT;
    this.notifyError('error while completing order');
  }

  handleCheckoutSuccess() {
    this.step = SUMMARY;
  }

  closeEmbedWarning() {
    this.embedErrors = [];
  }
}

export default alt.createStore(POSCheckoutStore, 'POSCheckoutStore');
