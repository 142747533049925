class ContactsPageActions {
  constructor() {
    this.generateActions(
      'mounted',
      'tabSelected',
      'archiveCountFetchSuccess',
      'archiveCountFetchError',
      'toggleFilterDrawer'
    );
  }
}

export default alt.createActions(ContactsPageActions);
