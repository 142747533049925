import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';
import { Drawer, Checkbox, Grid } from '@upperhand/playmaker';

import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import OrderReportActions from '../Actions';

function OrdersColumnDrawer({ columns, isOpen, intl }) {
  const handleChange = (column, value) =>
    OrderReportActions.changeColumnVisibility(column, value);

  return (
    <Drawer
      classes={{
        root: 'orders-report__column-drawer',
      }}
      open={isOpen}
      onClose={OrderReportActions.closeColumnDrawer}
      title={t('.title', intl, __filenamespace)}
      content={
        isLoggedIn() ? (
          <Grid container spacing={1} alignContent="center" justify="center">
            {columns
              .map((checked, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={key} item xs={12}>
                  <Checkbox
                    classes={{
                      root: 'checkbox',
                    }}
                    checked={checked}
                    label={t(`.${key}`, intl, __filenamespace)}
                    size="1x"
                    onChange={(_, value) => handleChange(key, value)}
                  />
                </Grid>
              ))
              .toList()}
          </Grid>
        ) : (
          <SignInPrompt />
        )
      }
    />
  );
}

OrdersColumnDrawer.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default injectIntl(OrdersColumnDrawer);
