import { Set } from 'immutable';
import CommissionRate from 'shared/records/CommissionRate.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import StaffDetailsDrawerStore from 'contacts/shared/stores/StaffDetailsDrawerStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { CommissionRateSource } from 'sources';
import CommissionRateListingStore from './CommissionRateListingStore';

const url = id => (id ? `commission_rates/${id}` : '/commission_rates');
const SPECIFIC_TYPE = 'specific_type';
const ALL_MEMBERSHIPS = 'all_memberships';
const ALL_RETAIL_CATEGORIES = 'all_retail_categories';
const MEMBERSHIP = 'membership';
const CATEGORY = 'category';

export default class CommissionRateEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();

    this.commissionRate = null;
    this.isSaving = false;
    this.actions = actions;
    this.successMessage = successMessage;
    this.selectedItemType = SPECIFIC_TYPE;

    this.bindListeners({
      updateCommissionRate: this.actions.UPDATED,
      commissionRateCommissionSelected:
        this.actions.COMMISSION_RATE_COMMISSION_SELECTED,
      commissionRateCommissionRemoved:
        this.actions.COMMISSION_RATE_COMMISSION_REMOVED,
      beginEdit: this.actions.BEGIN,
      cancelEdit: this.actions.CANCEL,
      createOrUpdate: this.actions.SAVE,
      createOrUpdateSuccess: this.actions.SAVE_SUCCESS,
      createOrUpdateError: this.actions.SAVE_ERROR,
      handleToggleItemType: this.actions.TOGGLE_ITEM_TYPE,
    });
  }

  // eslint-disable-next-line consistent-return
  updateCommissionRate(updatedFields) {
    if (!this.commissionRate) {
      return false;
    }
    this.commissionRate = this.commissionRate.merge(updatedFields);
  }

  commissionRateCommissionSelected(id) {
    this.commissionRate = this.commissionRate.updateIn(
      ['item_ids'],
      (set = Set()) => set.add(id)
    );
  }

  commissionRateCommissionRemoved(id) {
    this.commissionRate = this.commissionRate.updateIn(
      ['item_ids'],
      (set = Set()) => set.remove(id)
    );
  }

  beginEdit(commissionRate) {
    this.waitFor(StaffDetailsDrawerStore);
    const { id } = StaffDetailsDrawerStore.getState().staff;
    this.commissionRate =
      commissionRate || new CommissionRate({ customer_user_id: id });
    if (this.commissionRate.all_memberships) {
      this.selectedItemType = ALL_MEMBERSHIPS;
    } else if (this.commissionRate.all_retail_categories) {
      this.selectedItemType = ALL_RETAIL_CATEGORIES;
    } else {
      this.selectedItemType = SPECIFIC_TYPE;
    }
  }

  cancelEdit() {
    this.commissionRate = null;
  }

  // eslint-disable-next-line consistent-return
  createOrUpdate() {
    if (this.isSaving) {
      return false;
    }
    this.waitFor(CommissionRateListingStore);
    const { items } = CommissionRateListingStore.getState();
    const partitionedItems = items.groupBy(i => i.type);
    const allMembershipsIds = partitionedItems.get(MEMBERSHIP);
    const allCategoryIds = partitionedItems.get(CATEGORY);
    const membershipIds = this.commissionRate.item_ids.filter(
      id =>
        allMembershipsIds &&
        allMembershipsIds.size > 0 &&
        allMembershipsIds.find(mem => mem.id === id)
    );

    const categoryIds = this.commissionRate.item_ids.filter(
      id =>
        allCategoryIds &&
        allCategoryIds.size > 0 &&
        allCategoryIds.find(prod => prod.id === id)
    );
    this.commissionRate = this.commissionRate.merge({
      membership_ids: membershipIds,
      retail_category_ids: categoryIds,
    });
    this.commissionRate = this.commissionRate.validate();
    if (this.commissionRate.isValid()) {
      this.isSaving = true;
      CommissionRateSource.createOrUpdateCommission({
        id: this.commissionRate.id,
        url: url(this.commissionRate.id),
        params: { attributes: this.commissionRate.toServer() },
        success: this.actions.saveSuccess,
        error: this.actions.saveError,
      });
    }
  }

  createOrUpdateSuccess(_data) {
    this.commissionRate = null;
    this.isSaving = false;

    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('error updating commission rate', ...args);
  }

  handleToggleItemType(itemType) {
    this.selectedItemType = itemType;
    if (this.selectedItemType !== SPECIFIC_TYPE) {
      if (this.selectedItemType === ALL_MEMBERSHIPS) {
        this.commissionRate = this.commissionRate.merge({
          all_memberships: true,
          all_retail_categories: false,
        });
      } else if (this.selectedItemType === ALL_RETAIL_CATEGORIES) {
        this.commissionRate = this.commissionRate.merge({
          all_retail_categories: true,
          all_memberships: false,
        });
      }
    } else {
      this.commissionRate = this.commissionRate.merge({
        all_retail_categories: false,
        all_memberships: false,
      });
    }
  }
}
