import * as React from 'react';
import PerSessionPricingDetailsCard from 'automations/display/components/PerSessionPricingDetailsCard.jsx';
import RecurringPaymentPlanDetailsCard from 'automations/display/components/RecurringPaymentPlanDetailsCard.jsx';

const componentMap = {
  PerSessionPricingTemplate: PerSessionPricingDetailsCard,
  RecurringPaymentPlanTemplate: RecurringPaymentPlanDetailsCard,
};

function AutomationDetailsCard({ atd, ...restProps }) {
  const Component = componentMap[atd.template_type];
  return <div>{React.createElement(Component, { ...restProps, atd })}</div>;
}

export default AutomationDetailsCard;
