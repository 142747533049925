import * as React from 'react';
import clsx from 'clsx';
import { Grid } from '@upperhand/playmaker';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import '../styles.scss';

function Header({ intl, isMobile }) {
  return (
    <Grid container item spacing={2} alignContent="center" justify="center">
      <Grid
        item
        xs={12}
        className={clsx(
          'device-list__grid',
          'grid',
          isMobile && 'device-list__grid-mobile'
        )}
      >
        <PageHeader title={t('.header', intl, __filenamespace)} />
      </Grid>
    </Grid>
  );
}

export default Header;
