import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import PayoutsActions from 'shared/actions/PayoutsActions.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Root: {
    color: 'var(--color-old-gray)',
    fontSize: '15px',
  },
  Header: {
    padding: '16px 32px',
    backgroundColor: 'var(--color-old-gray)',
    color: 'white',
  },
  List: {
    padding: '16px 32px',
  },
  ListItem: {
    padding: '16px 0',
    borderBottom: `1px solid ${uhColors.dividerGrey}`,
  },
  ListItemRow: {
    padding: '4px 0',
  },
  Heavy: {
    fontWeight: 600,
  },
  Status: (status = '') => {
    const style = {
      textTransform: 'capitalize',
      color: uhColors.grey,
    };

    if (status.toLowerCase() === 'pending') {
      style.color = uhColors.darkRed;
    }

    return style;
  },
  Request: {
    fontWeight: 600,
  },
  LoadMore: {
    margin: '0 0 16px',
    textAlign: 'center',
  },
};

const handleRequestChange = () => {
  PayoutsActions.toggleHistoryDrawerOpen(false);
};

const handleLoadMore = e => {
  e.preventDefault();

  PayoutsActions.list();
};

function Payout({ payout }) {
  const payoutDate = stripeDate => {
    const msDate = new Date(stripeDate * 1000);
    return moment(msDate).format('MM-DD-YYYY');
  };

  return (
    <div style={styles.ListItem}>
      <FlexBoxJustify style={styles.ListItemRow}>
        <span style={styles.Heavy}>
          <FormattedMessage id={messageId('.withdrawal', __filenamespace)} />
        </span>
        <span style={styles.Status(payout.status)}>{payout.status}</span>
      </FlexBoxJustify>
      <FlexBoxJustify style={styles.ListItemRow}>
        <span style={styles.Heavy}>
          <FormattedMessage
            id={messageId('.request', __filenamespace)}
            values={{ payoutDate: payoutDate(payout.created) }}
          />
        </span>
        <span style={styles.Heavy}>
          <FormattedCurrency value={payout.amount} fromCents />
        </span>
      </FlexBoxJustify>
      <FlexBoxJustify style={styles.ListItemRow}>
        <span>
          <FormattedMessage
            id={messageId('.deposit', __filenamespace)}
            values={{ payoutDate: payoutDate(payout.arrival_date) }}
          />
        </span>
        <span>{`(...${payout.bank_account.last4})`}</span>
      </FlexBoxJustify>
    </div>
  );
}

function Header() {
  return (
    <header style={styles.Header}>
      <FormattedMessage id={messageId('.header', __filenamespace)} />
    </header>
  );
}

function PayoutsList({ payouts, intl }) {
  return (
    <div style={styles.List}>
      {payouts.map(payout => (
        <Payout key={payout.id} payout={payout} intl={intl} />
      ))}
    </div>
  );
}

function EndAction({ hasPayouts, payoutsLoaded, fetchedAll, intl }) {
  const loadMoreLabel = !fetchedAll ? '.load_more' : '.end_of_history';

  return (
    <div style={styles.LoadMore}>
      {!payoutsLoaded && <CircularProgress size={60} />}

      {payoutsLoaded && hasPayouts && (
        <Button onClick={handleLoadMore} disabled={fetchedAll}>
          {t(loadMoreLabel, intl, __filenamespace)}
        </Button>
      )}

      {payoutsLoaded && !hasPayouts && (
        <FormattedMessage id={messageId('.no_payouts', __filenamespace)} />
      )}
    </div>
  );
}

class PayoutsHistoryDrawer extends React.Component {
  componentDidMount() {
    PayoutsActions.list.defer();
  }

  render() {
    const { intl, payoutsStore } = this.props;

    return (
      <Drawer
        disableEnforceFocus
        open={payoutsStore.historyDrawerOpen}
        anchor="right"
        PaperProps={{
          sx: {
            width: smallScreen()
              ? window.innerWidth * 0.8
              : STANDARD_DRAWER_WIDTH,
          },
        }}
        onClose={handleRequestChange}
      >
        <Header />
        <PayoutsList payouts={payoutsStore.payouts} intl={intl} />
        <EndAction
          hasPayouts={!!payoutsStore.payouts.size}
          payoutsLoaded={payoutsStore.payoutsLoaded}
          fetchedAll={payoutsStore.fetchedAll}
          intl={intl}
        />
      </Drawer>
    );
  }
}

export default injectIntl(PayoutsHistoryDrawer);
