export default {
  name: 'Elite Sports Training Facility',
  siteURL: 'https://hybridjax.com',
  logo: {
    url: 'https://upperhand-app.s3.amazonaws.com/static/images/hybridjax_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#000',
    mobileSignInColor: '#000',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#000',
    accent1Color: '#fff',

    primary: {
      main: '#000',
    },
    secondary: {
      main: '#808080',
    },
  },
  sideNav: {
    selectedColor: '#000',
  },
  icons: {
    cart: '#000',
  },
  statusBar: {
    backgroundColor: '#000',
  },
};
