import camelCase from 'camelcase';
import deCamelize from 'decamelize';

export function isCollectionWithContents(e) {
  return !!e && (!!e.length || !!e.size);
}
export function isPlainObject(e) {
  return !!e && !e.asImmutable;
}
export function isPlainObjectAt(obj, e) {
  return !!obj && isPlainObject(obj[e]);
}
export function getValue(value, defaultValue) {
  if (value === null || value === undefined) {
    return defaultValue || '';
  }
  return value;
}
export function asPlainObject(data) {
  return data && data.toJS ? data.toJS() : data;
}
export function setAddressDataIn(data, json) {
  if ('address' in data) {
    const incomingAddressData = asPlainObject(getValue(data.address, {}));
    const incomingJsonAddress = asPlainObject(getValue(json.address, {}));
    const newAddressData = {
      ...incomingJsonAddress,
      ...incomingAddressData,
    };
    return {
      ...data,
      address: newAddressData,
    };
  }
  return data;
}
export function isEmpty(it) {
  return !it || (!!it.trim && !it.trim().length);
}
export function isPresent(it) {
  return !isEmpty(it) || it === 0;
}
export const asArray = obj => (Array.isArray(obj) ? obj : [obj]);

export const isObject = value =>
  !Array.isArray(value) && typeof value === 'object' && value !== null;

export const isString = value =>
  typeof value === 'string' || value instanceof String;

export const toCamelCase = src =>
  isString(src)
    ? camelCase(src)
    : Object.keys(src).reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [camelCase(currentValue)]: isObject(src[currentValue])
            ? toCamelCase(src[currentValue])
            : src[currentValue],
        }),
        {}
      );

export const toSnakeCase = src =>
  isString(src)
    ? deCamelize(src)
    : Object.keys(src).reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [deCamelize(currentValue)]: isObject(src[currentValue])
            ? toSnakeCase(src[currentValue])
            : src[currentValue],
        }),
        {}
      );

/**
 * Returns a new {Object} that is the result of shallow merging the two given {Objects}
 *
 * @param {...Object} objs The Array of Objects (Or Immutable key-value) to be merged.
 * @return {Object} New Object that is the result of shallow merging of given parameters.
 */
export const merge = (...objs) =>
  objs.reduce((root, current) => ({ ...root, ...asPlainObject(current) }), {});

export const arrayToObject = arr =>
  arr.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item] = true;
    return obj;
  }, {});
