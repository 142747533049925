import * as React from 'react';
import moment from 'moment-timezone';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HiddenInCheckoutIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import HiddenInPointOfSaleIcon from '@mui/icons-material/VisibilityOff';

import SearchBar from 'shared/components/SearchBar.jsx';
import CardMenu from 'shared/components/CardMenu.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import Expander from 'shared/components/Expander.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import PageHeader from 'shared/components/PageHeader.jsx';

import CreditPassCredits from 'credit_passes/components/_CreditPassCredits.jsx';
import CreditPassExpiration from 'credit_passes/components/_CreditPassExpiration.jsx';
import PurchaseDrawer from 'credit_passes/components/PurchaseDrawer.jsx';

import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';
import CreditPassPurchasingActions from 'credit_passes/actions/CreditPassPurchasingActions.jsx';
import CreditPassPurchasingStore from 'credit_passes/stores/CreditPassPurchasingStore.jsx';
import CreditPassSpecificEventsListStore from 'credit_passes/stores/CreditPassSpecificEventsListStore.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import POSCreditPassPackageStore from 'point_of_sale/stores/POSCreditPassPackageStore';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import SecondaryDrawerStore from 'shared/stores/SecondaryDrawerStore.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, smallScreen } from 'shared/utils/DOMUtils';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import '../styles.scss';

const styles = {
  actionButton: {
    height: 50,
    width: 244,
  },

  actionButtonLabel: {
    padding: 0,
  },

  expanderLabel: {
    color: 'inherit',
    fontSize: 15,
    fontWeight: 'bold',
    margin: '0 40px',
  },

  lockUnlockIcon: {
    marginBottom: -1,
    marginRight: 5,
  },

  creditsSummaryAndPriceContainer: {
    fontSize: 16,
    lineHeight: '20px',
    paddingTop: 10,
    marginBottom: 10,
    justifyContent: 'center',
    display: 'inline-block',
  },

  summaryContainer: {
    textAlign: 'center',
    color: uhColors.hintActive,
    width: '100%',
    paddingBottom: 25,
    marginBottom: -25,
    backgroundColor: uhColors.headerGrey,
  },

  summaryCreditPassName: {
    fontFamily: 'benton-sans-condensed, sans-serif',
    padding: '36px 5px 0',
    fontSize: 22,
    lineHeight: '25px',
    fontWeight: 'bold',
  },

  creditsContainer: {
    textAlign: 'left',
    padding: '20px 40px',
    margin: '-30px 0',
  },

  creditPassCardContainer: {
    width: 280,
    fontSize: 15,
    lineHeight: '24px',
    position: 'relative',
    paddingBottom: 20,
  },

  creditPassCardSubcontainer: {
    textAlign: 'center',
  },

  creditPassListContainer: sm => ({
    marginTop: LEFT_MARGIN_PIXELS,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: 30,
    justifyContent: sm ? 'center' : 'flex-start',
  }),

  newCreditPassLink: sm => ({
    textDecoration: 'none',
    width: sm ? '100%' : 'initial',
    marginBottom: sm ? LEFT_MARGIN_PIXELS : 0,
  }),

  newCreditPassButton: sm => ({
    backgroundColor: uhColors.aliceBlue,
    width: sm ? '100%' : 280,
    height: 55,
    fontSize: 18,
  }),

  viewButtonContainer: {
    padding: '0px',
    width: '100%',
  },

  creditPassCreditsContainer: {
    margin: '30px 0',
  },

  creditPassExpiration: {
    display: 'block',
    marginBottom: 15,
    fontSize: 13,
  },

  searchBar: sm => ({
    backgroundColor: 'var(--color-white)',
    borderRadius: '20px',
    padding: '0 15px',
    marginRight: sm ? 0 : '30px',
    width: '100%',
    maxWidth: sm ? '100%' : '300px',
    height: '40px',
  }),

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  hiddenIcons: {
    top: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    display: 'flex',
  },
};

const PageTitle = injectIntl(({ intl }) => (
  <PageHeader title={t('.header', intl, __filenamespace)} />
));

const NewButton = injectIntl(({ intl }) => (
  <ReactRouterLink
    to={customerScopedRoute('/credit_passes/new')}
    style={styles.newCreditPassLink(smallScreen())}
  >
    <Button
      style={styles.newCreditPassButton(smallScreen())}
      color="default"
      variant="contained"
      startIcon={<AddIcon sx={{ color: uhColors.activeBlue }} />}
    >
      {t('.create_new', intl, __filenamespace)}
    </Button>
  </ReactRouterLink>
));

function TotalCreditsCount({ creditPass }) {
  const totalCredits = creditPass.totalCredits();
  let countMessageId = null;

  if (totalCredits === 0) {
    countMessageId = '.no_credits';
  } else if (totalCredits === Infinity) {
    countMessageId = '.unlimited_credits';
  }

  return (
    <>
      {countMessageId ? (
        <FormattedMessage id={messageId(countMessageId, __filenamespace)} />
      ) : (
        totalCredits
      )}
      <FormattedMessage
        id={messageId('.credits_count', __filenamespace)}
        values={{ count: totalCredits }}
      />
    </>
  );
}

function CreditsSummaryAndPrice({ creditPass }) {
  return (
    <div
      style={styles.creditsSummaryAndPriceContainer}
      className="cp-summary-and-price"
    >
      <TotalCreditsCount creditPass={creditPass} />
      <FormattedMessage
        id={messageId('.credit_pass_price', __filenamespace)}
        values={{
          price: <FormattedCurrency value={creditPass.price} fromCents />,
        }}
      />
    </div>
  );
}

function Summary({ creditPass }) {
  return (
    <div style={styles.summaryContainer} className="cp-summary">
      <div style={styles.summaryCreditPassName} className="cp-summary-cp-name">
        {creditPass.name}
      </div>
      <CreditsSummaryAndPrice creditPass={creditPass} />
      <CreditPassExpiration
        creditPassId={creditPass.id}
        style={styles.creditPassExpiration}
      />
    </div>
  );
}

function ViewButton({ creditPass }) {
  return (
    <ReactRouterLink
      to={customerScopedRoute(`/credit_passes/${creditPass.id}`)}
      style={{ textDecoration: 'none' }}
    >
      <Button variant="contained" style={styles.actionButton}>
        <ViewButtonLabel creditPass={creditPass} />
      </Button>
    </ReactRouterLink>
  );
}

function ViewButtonLabel({ creditPass }) {
  return (
    <FlexBoxJustify style={styles.viewButtonContainer}>
      <FormattedMessage
        id={messageId('.clients_count', __filenamespace)}
        values={{ count: creditPass.active_client_count }}
      />
      <FormattedMessage id={messageId('.view', __filenamespace)} />
    </FlexBoxJustify>
  );
}

function Credits({ creditPass, eventTypes, events }) {
  return (
    <div style={styles.creditsContainer} className="cp-credits">
      <CreditPassCredits
        creditPass={creditPass}
        eventTypes={eventTypes}
        events={events}
        style={styles.creditPassCreditsContainer}
      />
    </div>
  );
}

function PurchaseButton({ creditPass }) {
  const handleClick = () => {
    CreditPassPurchasingActions.purchaseClicked({
      creditPass,
      buyerId: currentUser().customer_user_id,
    });
  };

  return (
    <Button
      variant="contained"
      style={styles.actionButton}
      onClick={handleClick}
    >
      <FormattedMessage id={messageId('actions.purchase', __filenamespace)} />
    </Button>
  );
}

function PrimaryActionButton({ creditPass, readOnly }) {
  return readOnly ? (
    <PurchaseButton creditPass={creditPass} />
  ) : (
    <ViewButton creditPass={creditPass} />
  );
}

function HiddenInCheckoutIndicator({ creditPass }) {
  if (!hasAdminPermission() || creditPass.published) return null;
  return (
    <Box>
      <IconButton data-tip data-for="hidden-in-checkout">
        <HiddenInCheckoutIcon sx={{ color: 'var(--color-old-gray)' }} />
      </IconButton>
      <ReactTooltip
        id="hidden-in-checkout"
        effect="solid"
        place="right"
        className="credit-pass-container__indicator-mobile-tooltip"
      >
        <FormattedMessage
          id={messageId('.hidden_in_checkout', __filenamespace)}
        />
      </ReactTooltip>
    </Box>
  );
}

function HiddenInPOSIndicator({ creditPass }) {
  if (!hasAdminPermission() || !creditPass.hide_in_pos) return null;
  return (
    <Box>
      <IconButton data-tip data-for="hidden-in-pos">
        <HiddenInPointOfSaleIcon sx={{ color: 'var(--color-old-gray)' }} />
      </IconButton>
      <ReactTooltip
        id="hidden-in-pos"
        effect="solid"
        place="right"
        className="credit-pass-container__indicator-mobile-tooltip"
      >
        <FormattedMessage id={messageId('.hidden_in_pos', __filenamespace)} />
      </ReactTooltip>
    </Box>
  );
}

const CreditPassCard = injectIntl(
  ({ eventTypes, events, intl, creditPass, readOnly }) => {
    const navigate = useNavigate();
    const historyLink = () => {
      navigate(customerScopedRoute(`/credit_passes/${creditPass.id}/edit`));
    };
    return (
      <Paper style={styles.creditPassCardContainer} className="cp-card">
        <Box sx={styles.hiddenIcons}>
          <HiddenInPOSIndicator creditPass={creditPass} />
          <HiddenInCheckoutIndicator creditPass={creditPass} />
        </Box>
        {!readOnly && (
          <CardMenu>
            <MenuItem onClick={historyLink}>{t('actions.edit', intl)}</MenuItem>
          </CardMenu>
        )}
        <div style={styles.creditPassCardSubcontainer}>
          <Summary creditPass={creditPass} />
          <PrimaryActionButton creditPass={creditPass} readOnly={readOnly} />
          {creditPass.credit_pass_credits.size > 0 && (
            <Expander
              label={t('.view_details', intl, __filenamespace)}
              labelContainerStyle={styles.expanderLabel}
            >
              <Credits
                creditPass={creditPass}
                eventTypes={eventTypes}
                events={events}
              />
            </Expander>
          )}
        </div>
      </Paper>
    );
  }
);

function CreditPassCards({ creditPasses, eventTypes, events, readOnly }) {
  return (
    <FlexBox
      style={styles.creditPassListContainer(smallScreen())}
      className="cp-list"
    >
      {creditPasses
        .filterNot(
          pass =>
            readOnly && pass.expires_at && pass.expires_at.isBefore(moment())
        )
        .sortBy(pass => -pass.price)
        .map(pass => (
          <CreditPassCard
            key={pass.id}
            creditPass={pass}
            events={events}
            eventTypes={eventTypes}
            readOnly={readOnly}
          />
        ))}
    </FlexBox>
  );
}

function Content({
  creditPassListingStore: {
    isLoading: isLoadingCreditPasses,
    records: creditPasses,
  },
  creditPassSpecificEventsListStore: { events, isLoading: isLoadingEventList },
  eventTypeListingStore: { eventTypes, isLoading: isLoadingEventType },
  readOnly,
}) {
  return (
    <SpinWhileLoading
      isLoading={
        isLoadingEventType || isLoadingEventList || isLoadingCreditPasses
      }
    >
      <CreditPassCards
        creditPasses={creditPasses}
        eventTypes={eventTypes}
        events={events}
        readOnly={readOnly}
      />
    </SpinWhileLoading>
  );
}

function CreditPassListing({ readOnly, intl }) {
  return (
    <div>
      {!readOnly && <PageTitle />}
      <div style={{ margin: LEFT_MARGIN_PIXELS }}>
        {!readOnly && (
          <Box style={styles.header}>
            <NewButton />
            <SearchBar
              onTypingEnd={value =>
                CreditPassListingActions.listRequested({ search: value })
              }
              style={styles.searchBar(smallScreen())}
            />
          </Box>
        )}
        <AltContainer
          stores={{
            drawer: SecondaryDrawerStore,
            creditPassListingStore: CreditPassListingStore,
            creditPassSpecificEventsListStore:
              CreditPassSpecificEventsListStore,
            CurrentContextStore,
            eventTypeListingStore: EventTypeListingStore,
            posCreditPassPackageStore: POSCreditPassPackageStore,
            creditPassPurchasingStore: CreditPassPurchasingStore,
            cartStore: CartStore,
          }}
          actions={SecondaryDrawerActions}
        >
          <Content readOnly={readOnly} />
          {/* Doing this because AltContainer doesn't seem to clone children which are booleans */}
          {readOnly ? (
            <PurchaseDrawer open title={t('.header', intl, __filenamespace)} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </AltContainer>
      </div>
    </div>
  );
}

export default injectIntl(CreditPassListing);
