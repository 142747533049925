import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Paper from '@mui/material/Paper';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  h1: {
    paddingTop: '16px',
    paddingBottom: '16px',
    fontSize: '16px',
    textAlign: 'center',
  },
};

function Unauthorized() {
  return (
    <Paper className="iphone-x-content" zDepth={1}>
      <h1 style={styles.h1}>
        <FormattedMessage id={messageId('.message', __filenamespace)} />
      </h1>
    </Paper>
  );
}

export default Unauthorized;
