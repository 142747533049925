/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { injectIntl } from 'react-intl';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconBButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Filters } from 'client_portal/components/Filters.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function FilterDrawer({ open, intl, ...props }) {
  return (
    <Drawer
      disableEnforceFocus
      open={open}
      anchor="right"
      onClose={() => props.actions.updateFilterDrawerOpen(false)}
      PaperProps={{ sx: { width: window.innerWidth * 0.9 } }}
    >
      <div
        className="iphone-x-content"
        style={{ margin: `0 ${LEFT_MARGIN_PIXELS}px` }}
      >
        <FlexBoxJustify style={{ alignItems: 'center', marginRight: -16 }}>
          <div style={{ fontSize: 20 }}>Refine your search.</div>
          <IconBButton
            onClick={() => props.actions.updateFilterDrawerOpen(false)}
          >
            <CloseIcon />
          </IconBButton>
        </FlexBoxJustify>
        {props.filterStore.anyFiltersEnabled() && (
          <Button
            sx={{ color: uhColors.blue, marginBottom: '10px' }}
            onClick={() => props.actions.filtersCleared()}
          >
            {t('.clear_filters', intl, __filenamespace)}
          </Button>
        )}

        <Filters {...props} style={{ width: '100%' }} />
      </div>
    </Drawer>
  );
}

export default injectIntl(FilterDrawer);
