import { List, Set } from 'immutable';
import Client from 'shared/records/Client.jsx';
import CreditGrantsActions from 'shared/actions/CreditGrantsActions';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSClientActions from 'point_of_sale/actions/POSClientActions.jsx';
import POSClientStore, {
  PROFILE_SELECTED,
  FETCHING_MANAGING_USER,
} from 'point_of_sale/stores/POSClientStore.jsx';
import POSCreditPassActions from 'point_of_sale/actions/POSCreditPassActions.js';
import POSCreditPassPackageActions from 'point_of_sale/actions/POSCreditPassPackageActions.js';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSMembershipActions from 'point_of_sale/actions/POSMembershipActions.jsx';
import POSMembershipRegistrationActions from 'point_of_sale/actions/POSMembershipRegistrationActions.jsx';
import POSProductActions from 'point_of_sale/actions/POSProductActions.js';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSProductListStore from 'point_of_sale/stores/POSProductListStore.jsx';
import POSPurchasedRegistrationActions from 'point_of_sale/actions/POSPurchasedRegistrationActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { PaymentStore } from 'containers/payments';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import POSCartStore from './POSCartStore.jsx';

export const CLIENT_LIST = 'clientList';
export const ADDING_CLIENT = 'addingClient';
export const PRODUCT_LIST = 'productList';
export const PRODUCT_VIEW = 'productView';
export const EVENT_SCHEDULING = 'eventScheduling';
export const MEMBERSHIP_REGISTERING = 'membershipRegistering';
export const CREDIT_PASS_REGISTERING = 'creditPassRegistering';
export const CART_SUMMARY = 'cartSummary';
export const CHECKOUT = 'checkout';
export const PRORATE = 'proRate';

class POSStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      openDrawer: POSActions.POS_CLICKED,
      closeDrawer: POSActions.DRAWER_DISMISSED,

      startAddClient: POSActions.ADD_CLIENT_CLICK,
      stopAddClient: POSActions.CANCEL_ADD_CLIENT_CLICK,

      clientAdded: POSClientActions.CREATE_OR_UPDATE_CLIENT_SUCCESS,

      useExistingAccount: [
        POSClientActions.EXISTING_ACCOUNT_CONTINUE,
        POSClientActions.CLIENT_UNARCHIVED_SUCCESS,
      ],
      cancelMoreInformation: POSClientActions.MORE_INFORMATION_CANCEL_CLICKED,

      selectClient: [
        POSActions.CLIENT_SELECTED,
        POSClientActions.FETCH_MANAGING_USER_SUCCESS,
      ],

      deselectClient: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCartActions.CANCEL_CART_CLICKED,
      ],

      selectEvent: [
        POSProductListActions.EVENT_SELECTED,
        POSEventActions.FETCH_EVENT_SUCCESS,
      ],

      switchToProductList: [
        POSCartActions.ADD_EVENT_CLICKED,
        POSEventActions.EVENT_SCHEDULING_CANCEL_CLICKED,
        POSCreditPassPackageActions.CANCEL_CLICKED,
        POSMembershipRegistrationActions.CANCEL_CLICKED,
        POSPurchasedRegistrationActions.COMPLETE_BUTTON_CLICKED,
        POSProductActions.CANCEL_CLICKED,
        POSProductListActions.LIST_BY_TYPE,
        POSSchedulingActions.classScheduleBookSuccess,
      ],

      selectMembership: [
        POSProductListActions.MEMBERSHIP_SELECTED,
        POSMembershipActions.FETCH_SUCCESS,
      ],

      checkProRate: POSActions.PRO_RATE_CHECK,

      selectCreditPass: [
        POSProductListActions.CREDIT_PASS_SELECTED,
        POSCreditPassActions.FETCH_SUCCESS,
      ],

      selectProduct: [
        POSProductListActions.PRODUCT_SELECTED,
        POSProductActions.FETCH_SUCCESS,
      ],

      handleCodeSearch: POSProductListActions.LIST_SUCCESS,

      listManagedProfiles: [
        POSMembershipRegistrationActions.PROFILE_CREATED,
        POSPurchasedRegistrationActions.PROFILE_CREATED,
        POSSchedulingActions.PROFILE_CREATED,
        POSSchedulingActions.PROFILE_CREATED_OPEN_BOOKING,
        POSSchedulingActions.SINGLE_SESSION_PROFILE_CREATED,
      ],

      listManagedProfilesSuccess: POSActions.MANAGED_PROFILES_LIST_SUCCESS,
      listManagedProfilesError: POSActions.MANAGED_PROFILES_LIST_ERROR,

      switchToCartSummary: [
        POSCartActions.ADD_SUCCESS,
        POSCartActions.UPDATE_SUCCESS,
        POSCheckoutActions.REVIEW_BACK_CLICKED,
        POSMembershipRegistrationActions.VIEW_IN_CART_CLICKED,
        POSProductListActions.CART_BUTTON_CLICKED,
        POSSchedulingActions.VIEW_IN_CART_CLICKED,
        POSProductActions.VIEW_IN_CART_CLICKED,
        POSCreditPassPackageActions.VIEW_IN_CART_CLICKED,
      ],

      switchToCheckout: [
        POSCartActions.FETCH_CART_CREDIT_PASSES_SUCCESS,
        POSActions.SWITCH_TO_CHECKOUT,
      ],

      reset: POSCheckoutActions.CLOSE_BUTTON_CLICKED,
      handleAgreementSignedSuccess: POSActions.onAgreementSignedSuccess,
    });
  }

  reset() {
    this.drawerOpen = false;
    this.step = CLIENT_LIST;
    this.selectedClient = null;
    this.originalClient = null;
    this.managedProfiles = List();
    this.allProfiles = List();
  }

  openDrawer() {
    this.drawerOpen = true;
  }

  closeDrawer() {
    if (
      !PaymentStore.getState().processingPayment &&
      !PaymentStore.getState().processingFields
    ) {
      this.drawerOpen = false;
    }
  }

  startAddClient() {
    this.step = ADDING_CLIENT;
  }

  stopAddClient() {
    this.step = CLIENT_LIST;
  }

  clientAdded() {
    this.waitFor(POSClientStore);

    this.step = PRODUCT_LIST;
    this.selectedClient = POSClientStore.getState().client;

    this.setAllProfiles();
    this.listManagedProfiles();
  }

  useExistingAccount() {
    this.waitFor(POSClientStore);

    const { currentStep, client } = POSClientStore.getState();

    if (currentStep === PROFILE_SELECTED) {
      this.step = PRODUCT_LIST;
      this.selectedClient = client;
      this.managedProfiles = List();

      this.setAllProfiles();
      this.listManagedProfiles();
    } else if (currentStep !== FETCHING_MANAGING_USER) {
      this.step = ADDING_CLIENT;
    }
  }

  cancelMoreInformation() {
    if (this.selectedClient) {
      this.selectedClient = null;
      this.step = CLIENT_LIST;
    }
  }

  selectClient() {
    this.waitFor(POSClientStore);

    const { currentStep, client, originalClient } = POSClientStore.getState();

    if (currentStep === PROFILE_SELECTED) {
      this.selectedClient = client;
      this.originalClient = originalClient;
      this.step = PRODUCT_LIST;
      this.managedProfiles = List();

      this.setAllProfiles();
      this.listManagedProfiles();
    } else if (currentStep !== FETCHING_MANAGING_USER) {
      this.selectedClient = client;
      this.step = ADDING_CLIENT;
    }
  }

  deselectClient() {
    this.waitFor(PaymentStore);
    if (!PaymentStore.getState().processingFields) {
      this.step = CLIENT_LIST;
      this.selectedClient = null;
      this.managedProfiles = List();
    }
  }

  listManagedProfiles() {
    if (!this.selectedClient.id) {
      return false;
    }

    return uhApiClient.get({
      url: `customer_users/${this.selectedClient.id}/managed_customer_users`,
      data: {
        per_page: 50,
        fields: [
          'active_membership_id',
          'active_membership_tier_id',
          'agreement_details',
        ],
      },
      success: POSActions.managedProfilesListSuccess,
      error: POSActions.managedProfilesListError,
    });
  }

  listManagedProfilesSuccess(data) {
    if (this.selectedClient) {
      this.managedProfiles = List(
        data.managed_customer_users
          .filter(c => c.type === 'Client')
          .map(c => new Client(c))
      );
    }
    this.setAllProfiles();
    if (this.allProfiles.size) {
      const allAthletesIds = this.allProfiles.reduce(
        (set, credit) => set.add(credit.id),
        Set()
      );

      if (allAthletesIds.size) {
        CreditGrantsActions.list.defer({
          clientIds: allAthletesIds,
        });
      }
    }
  }

  listManagedProfilesError(...args) {
    this.notifyError(
      `Error listing managed customer_users for Client ${this.client.id}`,
      args
    );
  }

  enableCartRedirect() {
    this.redirectToCartOnSuccess = true;
  }

  disableCartRedirect() {
    this.redirectToCartOnSuccess = false;
  }

  selectEvent() {
    this.step = EVENT_SCHEDULING;
  }

  switchToProductList() {
    this.step = PRODUCT_LIST;
  }

  selectMembership() {
    this.step = MEMBERSHIP_REGISTERING;
  }

  checkProRate(value) {
    if (value) {
      this.step = PRORATE;
    }
    if (!value) {
      this.step = CART_SUMMARY;
    }
  }

  selectCreditPass() {
    this.step = CREDIT_PASS_REGISTERING;
  }

  selectProduct() {
    this.step = PRODUCT_VIEW;
  }

  handleCodeSearch() {
    this.waitFor(POSProductListStore);

    const { variant } = POSProductListStore.getState();

    if (variant) {
      this.step = PRODUCT_VIEW;
    }
  }

  switchToCartSummary() {
    this.step = CART_SUMMARY;
  }

  switchToCheckout() {
    // HACK: somehow ended up doing this after complaining about it
    this.waitFor(POSCartStore);
    this.step = CHECKOUT;
  }

  setAllProfiles() {
    this.allProfiles =
      this.selectedClient.type === 'Client'
        ? this.managedProfiles.push(this.selectedClient)
        : this.managedProfiles;
  }

  handleAgreementSignedSuccess(data) {
    data.forEach(updatedData => {
      const index = this.allProfiles.findIndex(
        client => client.get('id') === updatedData.client_id
      );

      if (index !== -1) {
        const client = this.allProfiles.get(index).set('agreement_details', {
          agreement: updatedData,
          agreement_updated_at: currentCustomer().agreement_updated_at,
        });

        this.allProfiles = this.allProfiles.set(index, new Client(client));
      }
    });
  }
}

export default alt.createStore(POSStore, 'POSStore');
