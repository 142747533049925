import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RecurringPaymentPlanSummary from 'automations/display/components/RecurringPaymentPlanSummary.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { numberToCurrency } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { sumInt, multiplyList } from 'shared/utils/ImmutableUtils.js';

const styles = {
  expander: {
    position: 'relative',
    paddingRight: 20,
    cursor: 'pointer',
    marginBottom: 4,
  },

  icon: {
    position: 'absolute',
    top: -6,
    right: -4,
  },
};

const paymentPlanText = (plan, quantity, adjustedPayments) => {
  let payments;
  if (adjustedPayments) {
    payments = adjustedPayments;
  } else {
    payments = multiplyList(plan.payments, quantity);
  }

  const paymentsDueImmediately = payments.filter(p =>
    moment(p.get('date')).isSameOrBefore(moment())
  );

  const dueImmediatelyTotal = paymentsDueImmediately.reduce(
    sumInt('amount'),
    0
  );

  const displayedTotal =
    dueImmediatelyTotal === 0
      ? quantity * payments.first().get('amount')
      : dueImmediatelyTotal;

  return `(${paymentsDueImmediately.size} of ${
    payments.size
  }) ${numberToCurrency(displayedTotal, { fromCents: true })}`;
};

class ExpandablePaymentPlanSummary extends React.Component {
  static propTypes = {
    quantity: PropTypes.number,
  };

  static defaultProps = {
    quantity: 1,
  };

  constructor(props) {
    super(props);
    this.state = { expanded: true };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { plan, quantity, adjustedPayments, style } = this.props;

    return (
      <div style={style}>
        <div style={styles.expander} onClick={this.toggleExpanded}>
          <FlexBoxJustify>
            <div style={{ color: 'var(--color-secondary)' }}>Payment Plan</div>
            <div>{paymentPlanText(plan, quantity, adjustedPayments)}</div>
          </FlexBoxJustify>
          {this.state.expanded ? (
            <ExpandLessIcon sx={styles.icon} />
          ) : (
            <ExpandMoreIcon sx={styles.icon} />
          )}
        </div>
        {this.state.expanded && (
          <RecurringPaymentPlanSummary
            plan={plan}
            quantity={quantity}
            adjustedPayments={adjustedPayments}
            paymentsStyle={{ paddingRight: 20 }}
          />
        )}
      </div>
    );
  }
}

export default ExpandablePaymentPlanSummary;
