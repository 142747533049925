import * as React from 'react';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';

const styles = {
  Client: {
    margin: '20px 0',
  },
};

function CheckoutClientBox({ selectedClient, clientMembership }) {
  return (
    <div style={styles.Client}>
      <FlexBoxCenter>
        {selectedClient && (
          <UserAvatar user={selectedClient} style={{ flex: '0 0 auto' }} />
        )}
        <FlexBox
          style={{ flexDirection: 'column', color: 'var(--color-old-gray)' }}
        >
          <div style={{ fontWeight: '600' }}>
            {selectedClient && selectedClient.name()}
          </div>
          <div>
            {clientMembership && clientMembership.name}
            {selectedClient && selectedClient.active_membership_name}
          </div>
        </FlexBox>
      </FlexBoxCenter>
    </div>
  );
}

export default CheckoutClientBox;
