import React from 'react';
import { SvgIcon } from '@mui/material';

function CalendarLegendIcon({ color }) {
  return (
    <SvgIcon
      sx={{
        fill: color,
      }}
    >
      <path d="M4.58906 11.66L8.72564 13.2156L10.2813 17.3522H10.8116L15.2133 6.72792L4.58906 11.1297V11.66ZM3.89964 18.0416C2.92147 17.0635 2.18785 15.9586 1.69877 14.7271C1.20968 13.4955 0.965142 12.2434 0.965142 10.9706C0.965142 9.69777 1.20968 8.44561 1.69877 7.21406C2.18785 5.98252 2.92147 4.87766 3.89964 3.8995C4.8778 2.92133 5.98265 2.18771 7.2142 1.69863C8.44574 1.20955 9.69791 0.965005 10.9707 0.965005C12.2435 0.965005 13.4957 1.20955 14.7272 1.69863C15.9588 2.18771 17.0636 2.92133 18.0418 3.8995C19.0199 4.87766 19.7536 5.98252 20.2426 7.21406C20.7317 8.44561 20.9763 9.69777 20.9763 10.9706C20.9763 12.2434 20.7317 13.4955 20.2426 14.7271C19.7536 15.9586 19.0199 17.0635 18.0418 18.0416C17.0636 19.0198 15.9588 19.7534 14.7272 20.2425C13.4957 20.7316 12.2435 20.9761 10.9707 20.9761C9.69791 20.9761 8.44574 20.7316 7.2142 20.2425C5.98265 19.7534 4.8778 19.0198 3.89964 18.0416ZM5.31385 16.6274C6.89305 18.2066 8.77867 18.9962 10.9707 18.9962C13.1627 18.9962 15.0484 18.2066 16.6276 16.6274C18.2068 15.0482 18.9964 13.1626 18.9964 10.9706C18.9964 8.77853 18.2068 6.89292 16.6276 5.31371C15.0484 3.73451 13.1627 2.9449 10.9707 2.9449C8.77867 2.9449 6.89305 3.73451 5.31385 5.31371C3.73464 6.89292 2.94504 8.77853 2.94504 10.9706C2.94504 13.1626 3.73464 15.0482 5.31385 16.6274Z" />
    </SvgIcon>
  );
}

export default CalendarLegendIcon;
