import { Map, OrderedSet } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { AccountCreditsSource } from 'sources';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import AccountCreditActions from './Actions';

export const STEPS = {
  create: 'create',
  list: 'list',
  details: 'details',
  clear: 'clear',
};

export const CREDIT_TYPES = {
  store_credit: 'store_credit',
  gift_card: 'gift_card',
  clear: 'clear',
  apply: 'apply',
};

class AccountCreditStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      openDrawer: AccountCreditActions.open,
      closeDrawer: AccountCreditActions.close,

      toggleClear: AccountCreditActions.toggleClear,
      toggleDetails: AccountCreditActions.toggleDetails,
      changeStep: AccountCreditActions.changeStep,

      createCredit: AccountCreditActions.createCredit,
      createCreditSuccess: AccountCreditActions.createCreditSuccess,
      createCreditError: AccountCreditActions.createCreditError,

      listCredits: AccountCreditActions.listCredits,
      listMoreCredits: AccountCreditActions.listMoreCredits,
      listCreditsSuccess: AccountCreditActions.listCreditsSuccess,
      listCreditsError: AccountCreditActions.listCreditsError,

      handleFieldChange: AccountCreditActions.handleFieldChange,
    });
  }

  reset() {
    this.open = false;
    this.creditsLoading = false;
    this.hasMore = false;
    this.moreCreditsLoading = false;
    this.clientId = null;
    this.creditsAmount = 0;
    this.credit = null;
    this.step = STEPS.list;
    this.creditsIds = OrderedSet();
    this.formFields = Map({
      credit_type: CREDIT_TYPES.store_credit,
      amount: null,
      note: null,
    });
    this.pagination = Map({
      credits: Map({
        page: 1,
        per_page: 5,
      }),
    });
  }

  resetForm() {
    this.formFields = Map({
      credit_type: CREDIT_TYPES.store_credit,
      amount: null,
      note: null,
    });
  }

  openDrawer({ clientId, creditsAmount = 0 }) {
    this.open = true;
    this.clientId = clientId;
    this.creditsAmount = creditsAmount;
    this.listCredits();
  }

  closeDrawer() {
    this.reset();
  }

  changeStep(step) {
    this.step = step;
  }

  handleFieldChange({ field, value }) {
    this.formFields = this.formFields.set(field, value);
  }

  toggleClear() {
    if (this.step === STEPS.list) {
      this.step = STEPS.clear;
      this.formFields = this.formFields
        .set('amount', this.creditsAmount)
        .set('credit_type', CREDIT_TYPES.clear);
    } else {
      this.step = STEPS.list;
      this.resetForm();
    }
  }

  toggleDetails(credit) {
    if (this.step === STEPS.list) {
      this.step = STEPS.details;
      this.credit = credit;
    } else {
      this.step = STEPS.list;
      this.credit = null;
    }
  }

  createCredit() {
    AccountCreditsSource.create({
      accountCredit: {
        client_id: this.clientId,
        credit_type: this.formFields.get('credit_type'),
        note: this.formFields.get('note'),
        amount: this.formFields.get('amount'),
        card_number: this.formFields.get('card_number'),
      },
      success: AccountCreditActions.createCreditSuccess,
      error: AccountCreditActions.createCreditError,
    });
  }

  createCreditSuccess(credit) {
    const { credit_type: creditType } = credit;

    this.creditsIds = OrderedSet([credit.id]).merge(this.creditsIds);
    this.creditsAmount =
      creditType === CREDIT_TYPES.clear
        ? this.creditsAmount - credit.amount
        : this.creditsAmount + credit.amount;
    this.resetForm();

    if (this.step === STEPS.create) {
      MessageWindowActions.addMessage.defer(
        'Account credit added successfully'
      );
    } else {
      MessageWindowActions.addMessage.defer(
        'Account credit cleared successfully'
      );
    }

    this.changeStep(STEPS.list);
  }

  createCreditError(...args) {
    this.notifyError('Account credit create error', args);
  }

  listCredits() {
    if (!this.hasMore) {
      this.creditsLoading = true;
    }

    AccountCreditsSource.list({
      params: {
        client_id: this.clientId,
        page: this.pagination.getIn(['credits', 'page']),
        per_page: this.pagination.getIn(['credits', 'per_page']),
        fields: ['created_by'],
      },
      success: AccountCreditActions.listCreditsSuccess,
      error: AccountCreditActions.listCreditsError,
    });
  }

  listMoreCredits() {
    const page = this.pagination.getIn(['credits', 'page'], 1);

    this.pagination = this.pagination.setIn(['credits', 'page'], page + 1);
    this.moreCreditsLoading = true;
    this.listCredits();
  }

  listCreditsSuccess({
    page,
    perPage,
    totalCount,
    account_credits: accountCredits,
  }) {
    this.creditsIds = this.creditsIds.merge(accountCredits.map(c => c.id));
    this.creditsLoading = false;

    if (page * perPage < totalCount) {
      this.hasMore = true;
      this.moreCreditsLoading = false;
    } else {
      this.hasMore = false;
      this.moreCreditsLoading = false;
    }
  }

  listCreditsError(...args) {
    this.creditsLoading = false;
    this.notifyError('Account credits list error', args);
  }
}

export default alt.createStore(AccountCreditStore, 'AccountCreditStore');
