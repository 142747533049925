/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';

import Form from 'onboarding/components/AccountSetupPaysafe/forms/_Form.jsx';
import TextField from 'onboarding/components/AccountSetupPaysafe/fields/_TextField.jsx';
import Address from 'onboarding/components/AccountSetupPaysafe/fields/_AddressField.jsx';
import SelectField from 'onboarding/components/AccountSetupPaysafe/fields/_SelectField.jsx';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

function LegalEntityType({ intl, propsForField }) {
  return (
    <SelectField
      {...propsForField('LegalEntityType')}
      items={[
        { label: t('.type_corp', intl, __filenamespace), value: 'CORP' },
        { label: t('.type_llc', intl, __filenamespace), value: 'LLC' },
        { label: t('.type_llp', intl, __filenamespace), value: 'LLP' },
        {
          label: t('.type_partnership', intl, __filenamespace),
          value: 'PARTNERSHP',
        },

        { label: t('.type_npcorp', intl, __filenamespace), value: 'NPCORP' },
        {
          label: t('.type_soleprop', intl, __filenamespace),
          value: 'SOLEPROP',
        },
      ]}
    />
  );
}

function BusinessNameField({ propsForField }) {
  return (
    <div data-tip data-for="business_name">
      <TextField autoComplete="off" {...propsForField('BusinessName')} />
      <ReactTooltip
        id="business_name"
        effect="solid"
        className="charge-failure-tooltip uh-tooltip"
      >
        <FormattedMessage
          id={messageId('.business_name_tooltip', __filenamespace)}
        />
      </ReactTooltip>
    </div>
  );
}

function LegalEntityField({ propsForField }) {
  return (
    <div style={Styles.businessLegalName}>
      <TextField {...propsForField('BusinessLegalName')} />
      <InfoIcon data-for="legal_entity" data-tip style={Styles.infoIcon} />
      <ReactTooltip
        id="legal_entity"
        effect="solid"
        className="charge-failure-tooltip uh-tooltip"
      >
        <FormattedMessage
          id={messageId('.legal_entity_tooltip', __filenamespace)}
        />
      </ReactTooltip>
    </div>
  );
}

function BusinessForm(props) {
  return (
    <Form
      style={Styles.form}
      header={t('.header', props.intl, __filenamespace)}
    >
      <Grid container spacing={4} justify="center">
        <Grid container item direction="column" spacing={2} xs={6}>
          <Grid item>
            <h3 style={Styles.header3}>
              {t('.header', props.intl, __filenamespace)}
            </h3>
          </Grid>
          <Grid item>
            <BusinessNameField {...props} />
          </Grid>
          <Grid item>
            <LegalEntityField {...props} />
          </Grid>
          <Grid item>
            <LegalEntityType {...props} />
          </Grid>
          <Grid item>
            <TextField {...props.propsForField('BusinessPhone')} />
          </Grid>
          <Grid item className="fs-exclude">
            <div style={{ position: 'relative' }}>
              <TextField {...props.propsForField('EIN')} />
              <InfoIcon data-for="ein_ssn" data-tip style={Styles.infoIcon} />
              <ReactTooltip
                id="ein_ssn"
                effect="solid"
                className="charge-failure-tooltip uh-tooltip"
              >
                <FormattedMessage
                  id={messageId('.ein_ssn_tooltip', __filenamespace)}
                />
              </ReactTooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container item direction="column" spacing={2} xs={6}>
          <Address {...props} prefix="Business" />
        </Grid>
      </Grid>
    </Form>
  );
}

export default injectIntl(BusinessForm);
