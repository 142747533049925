import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

function Header() {
  return (
    <PageHeader
      title={<FormattedMessage id={messageId('.title', __filenamespace)} />}
    />
  );
}

export default Header;
