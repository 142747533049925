import Subscription from 'shared/records/Subscription.jsx';
import SubscriptionActions from 'shared/actions/SubscriptionActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

class SubscriptionStore extends UpperHandStore {
  constructor() {
    super();
    this.subscription = new Subscription();
    this.isLoading = false;

    this.bindListeners({
      handleFetch: SubscriptionActions.FETCH,
      handleFetchSuccess: SubscriptionActions.FETCH_SUCCESS,
      handleFetchError: SubscriptionActions.FETCH_ERROR,
    });
  }

  handleFetch() {
    this.isLoading = true;

    return uhApiClient.get({
      url: `customers/${currentCustomer().id}/subscription`,
      success: SubscriptionActions.fetchSuccess,
      error: SubscriptionActions.fetchError,
    });
  }

  handleFetchSuccess(data) {
    this.isLoading = false;
    this.subscription = new Subscription(data);
  }

  handleFetchError() {
    this.isLoading = false;
    this.notifyError('error while fetching subscription', arguments);
  }
}

export default alt.createStore(SubscriptionStore, 'SubscriptionStore');
