import * as React from 'react';

import uhTheme from '_uh_theme.jsx';
import {
  formatPhone,
  formatPhoneToE164,
} from 'shared/utils/FormattingUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';

function Tel({ number }) {
  return number ? (
    <a
      href={`tel:${formatPhoneToE164(number)}`}
      style={smallScreen() ? { color: uhTheme.palette.hyperlink } : {}}
    >
      {formatPhone(number)}
    </a>
  ) : null;
}

export default Tel;
