import React from 'react';
import { Chip, Confirmation, Grid, Typography } from '@upperhand/playmaker';

import CancelRegistrationModal from 'event_mgmt/shared/components/ParticipantClientEventList/CancelRegistrationModal.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import WarningIcon from 'shared/components/icons/WarningIcon.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import { SessionDataStore } from 'dataStores';

function ScheduleClientCancelRegistrationModal({
  intl,
  registration,
  onCancel,
  onConfirm,
  sessionDataStore: { sessions },
}) {
  const sessionId = registration?.get('session_id');
  const session = sessions?.get(sessionId);
  return (
    <Confirmation
      level="warning"
      cancelButtonLabel={t('.cancel_btn', intl, __filenamespace)}
      confirmButtonLabel={t('.confirm_btn', intl, __filenamespace)}
      open={!!registration}
      hasCheckbox={false}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={
        <Typography variant="dialogHeader">
          <WarningIcon width={20} height={18} />
          <span className="cancel-registration-modal__title">
            {t('.title', intl, __filenamespace)}
          </span>
        </Typography>
      }
    >
      <Grid container className="cancel-registration-modal__container">
        {t('.cancel_registration_confirmation', intl, __filenamespace)}
      </Grid>
      <Grid container className="cancel-registration-modal__container">
        <Chip label={session?.formattedTime()} />
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body2" component="strong">
            {t('.note_title', intl, __filenamespace)}
          </Typography>
          <Typography variant="body2" color="secondary" component="span">
            {t('.note_subtitle', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
    </Confirmation>
  );
}

function ScheduleCancelRegistrationModal(props) {
  const isStaff = currentUser().isStaff();

  if (!isStaff) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ScheduleClientCancelRegistrationModal {...props} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CancelRegistrationModal {...props} />;
}

export default altContainer({
  stores: {
    sessionDataStore: SessionDataStore,
  },
})(React.memo(ScheduleCancelRegistrationModal));
