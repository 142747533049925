import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@upperhand/playmaker';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  breadcrumbSpacing: {
    marginTop: '16px',
  },
  root: {
    fontSize: 20,
    padding: '25px 0 0 30px',
  },
};

function MembershipPageHeader({
  title,
  breadcrumbHref,
  className,
  style,
  actions,
}) {
  return (
    <div className={className} style={merge(styles.root, style)}>
      {breadcrumbHref && (
        <Typography variant="body1">
          <Link to={breadcrumbHref}>&lt; Back</Link>
        </Typography>
      )}
      <PageHeader title={title} actions={actions} />
    </div>
  );
}

MembershipPageHeader.propTypes = {
  breadcrumbHref: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  actions: PropTypes.node,
};
MembershipPageHeader.defaultProps = {
  breadcrumbHref: undefined,
  className: 'page-header',
  actions: undefined,
};

export default MembershipPageHeader;
