export default function wait(timeout) {
  return new Promise(resolve => {
    const id = setTimeout(() => {
      resolve();
      clearTimeout(id);
    }, timeout);
  });
}

/**
 * Wait until the check method returns true
 * @param  {()=>Bool} check        Method to call
 * @param  {Integer} timeout       Timeout interval in milliseconds. Use -1 for no timeout.
 * @param  {Integer} pollInterval  How often to run the check method. Dfaults to 100 milliseconds.
 * @param  {Integer} elapsed       Time already elapsed
 * @return {Promise}               Resolves when the check method returns true or on timeout.
 */
export async function waitUntil(
  check,
  timeout = -1,
  pollInterval = 100,
  elapsed = 0
) {
  const result = await check();
  const timedOut = timeout !== -1 && elapsed >= timeout;
  if (result || timedOut) {
    return;
  }
  await wait(pollInterval);
  await waitUntil(check, timeout, pollInterval, elapsed + pollInterval);
}
