import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import AltContainer from 'alt-container';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import EventTypeEditingStore from 'shared/stores/EventTypeEditingStore.jsx';
import EventTypeEditingActions from 'shared/actions/EventTypeEditingActions.jsx';
import EventTypeForm from 'shared/components/EventTypeForm.jsx';
import EventTypeSelectorUIStore from 'shared/stores/EventTypeSelectorUIStore.jsx';
import EventTypeSelectorUIActions from 'shared/actions/EventTypeSelectorUIActions.jsx';
import SelectWithNewItem from 'shared/components/_SelectWithNewItem.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const EventTypeDialog = injectIntl(
  ({ eventType, open, onClose, onSubmit, intl }) => {
    const isNew = eventType ? eventType.id === null : false;
    const dialogTitle = isNew
      ? t('.dialog_title_create_mode', intl, __filenamespace)
      : t('.dialog_title_edit_mode', intl, __filenamespace);

    return (
      <Confirmation
        hasCheckbox={false}
        title={dialogTitle}
        open={open}
        cancelButtonLabel={t('.dialog_cancel_button', intl, __filenamespace)}
        confirmButtonLabel={t('.dialog_submit_button', intl, __filenamespace)}
        onCancel={onClose}
        onConfirm={onSubmit}
      >
        <EventTypeForm eventType={eventType} />
      </Confirmation>
    );
  }
);

function EditableEventTypeSelect({
  selectedEventTypeId,
  componentId,
  errorText,
  onChange,
  menuItems,
  onCreateOrUpdateSuccess,
  eventTypeUIStore,
  intl,
  disableAddNewItem,
  ...props
}) {
  return (
    <div>
      <SelectWithNewItem
        value={selectedEventTypeId}
        itemName={t('.item_name', intl, __filenamespace)}
        newItemCallback={
          !disableAddNewItem
            ? () =>
                EventTypeSelectorUIActions.openDialog({
                  componentId,
                  newEventType: true,
                })
            : undefined
        }
        error={!!errorText}
        helperText={errorText}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: document.documentElement.clientHeight * 0.4,
            },
          },
        }}
        onChange={onChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {menuItems}
      </SelectWithNewItem>
      <AltContainer
        stores={{
          eventType: () => ({
            store: EventTypeEditingStore,
            value: EventTypeEditingStore.getState().record,
          }),
        }}
      >
        <EventTypeDialog
          open={eventTypeUIStore.shouldOpen(componentId)}
          onSubmit={() => {
            EventTypeEditingActions.createOrUpdate({
              callback: onCreateOrUpdateSuccess,
            });
            EventTypeSelectorUIActions.closeDialog();
          }}
          onClose={EventTypeSelectorUIActions.closeDialog}
        />
      </AltContainer>
      {selectedEventTypeId && (
        <div>
          <Button
            variant="contained"
            color="default"
            onClick={() =>
              EventTypeSelectorUIActions.openDialog({ componentId })
            }
          >
            {t('.edit_button', intl, __filenamespace)}
          </Button>
        </div>
      )}
    </div>
  );
}

const EventTypeSelect = injectIntl(
  ({
    eventTypeUIStore,
    selectedEventTypeId,
    eventTypes,
    onChange,
    errorText,
    onCreateOrUpdateSuccess,
    componentId,
    intl,
    ...props
  }) => {
    const menuItems = [];

    if (eventTypes.size) {
      eventTypes.forEach(eventType => {
        const icon = <FiberManualRecordIcon sx={{ color: eventType.color }} />;
        menuItems.push(
          <MenuItem key={eventType.id} value={eventType.id}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{eventType.name}</ListItemText>
          </MenuItem>
        );
      });
    }

    return (
      <EditableEventTypeSelect
        disableAddNewItem={
          currentCustomer().restrict_editing && !currentUser().isAdmin()
        }
        selectedEventTypeId={selectedEventTypeId}
        componentId={componentId}
        errorText={errorText}
        onChange={onChange}
        menuItems={menuItems}
        onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
        eventTypeUIStore={eventTypeUIStore}
        intl={intl}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
);

class EventTypeInlineEditor extends React.Component {
  constructor(props) {
    super(props);
    this.id = uuidv4();
  }

  componentDidUpdate(prevProps) {
    const { selectedEventTypeId } = this.props;
    const { selectedEventTypeId: prevTypeId } = prevProps;

    if (selectedEventTypeId && selectedEventTypeId !== prevTypeId) {
      EventTypeEditingActions.initialLoad.defer(selectedEventTypeId);
    }
  }

  render() {
    const {
      selectedEventTypeId,
      eventTypes,
      onEventTypeSelect,
      errorText,
      onCreateOrUpdateSuccess,
      style,
      ...props
    } = this.props;

    return (
      <div style={style}>
        <AltContainer
          stores={{
            eventTypeUIStore: EventTypeSelectorUIStore,
          }}
          style={style}
        >
          <EventTypeSelect
            selectedEventTypeId={selectedEventTypeId}
            eventTypes={eventTypes}
            onChange={onEventTypeSelect}
            onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
            errorText={errorText}
            componentId={this.id}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        </AltContainer>
      </div>
    );
  }
}

export default EventTypeInlineEditor;
