import * as React from 'react';
import { List } from 'immutable';

import EventCards from 'calendar/components/views/shared/_EventCards.jsx';
import HeaderCell from 'calendar/components/views/week/_HeaderCell.jsx';
import NoEventsOverlay from 'calendar/components/views/shared/_NoEventsOverlay.jsx';
import TimeBar from 'calendar/components/views/shared/_TimeBar.jsx';
import TimeCells from 'calendar/components/views/shared/_TimeCells.jsx';

import { weekRangeFromDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import * as CalendarUtils from 'calendar/utils/CalendarUtils.jsx';

const styles = {
  WeekColumns: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    flex: '1',
  },
  WeekColumn: {
    position: 'relative',
    flex: '1',
    minWidth: '60px',
  },
  TimeCells: {
    paddingTop: '42px',
  },
};

function WeekColumn({
  allStaffMap,
  clientsMap,
  eventsMap,
  membershipsMap,
  resourcesMap,
  eventTimes,
  weekday,
  showPaid,
  showUnpaid,
  showAllStaff,
  staffIds,
  sessions,
}) {
  const filteredEventTimes = CalendarUtils.filteredEventTimes(
    eventTimes,
    showPaid,
    showUnpaid,
    showAllStaff,
    staffIds
  );
  return (
    <div style={styles.WeekColumn}>
      <HeaderCell weekday={weekday} />
      <div style={styles.TimeCells}>
        <TimeCells date={weekday} />
      </div>
      {!filteredEventTimes.size && <NoEventsOverlay />}
      <EventCards
        allStaffMap={allStaffMap}
        calendarEventTimes={filteredEventTimes}
        clientsMap={clientsMap}
        eventsMap={eventsMap}
        membershipsMap={membershipsMap}
        resourcesMap={resourcesMap}
        sessions={sessions}
      />
    </div>
  );
}

function WeekColumns({
  allStaffMap,
  date,
  clientsMap,
  eventsMap,
  filteredEventTimesByWeekday,
  membershipsMap,
  resourcesMap,
  showPaid,
  showUnpaid,
  showAllStaff,
  staffIds,
  sessions,
}) {
  const weekRange = weekRangeFromDate(date);

  const weekdays = Array.from(weekRange.by('day'), weekday => weekday);

  return (
    <div style={styles.WeekColumns}>
      <TimeBar />
      {weekdays.map(weekday => (
        <WeekColumn
          key={`WeekColumn-${weekday}`}
          eventTimes={filteredEventTimesByWeekday.get(
            weekday.format('YYYY-MM-DD'),
            List()
          )}
          allStaffMap={allStaffMap}
          clientsMap={clientsMap}
          eventsMap={eventsMap}
          membershipsMap={membershipsMap}
          resourcesMap={resourcesMap}
          weekday={weekday}
          showPaid={showPaid}
          showUnpaid={showUnpaid}
          showAllStaff={showAllStaff}
          staffIds={staffIds}
          sessions={sessions}
        />
      ))}
    </div>
  );
}

export default WeekColumns;
