import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';
import { Button, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import PurchaseDrawerActions from 'containers/purchaseDrawer/Actions';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { STATUS } from 'records/compoundObjects/BrowseEvent.js';
import CustomerTeam from '../records/CustomerTeam.jsx';

function Title({ team, touchable }) {
  if (!team.get('title')) {
    return <FormattedMessage id={messageId('.team_title', __filenamespace)} />;
  }
  if (touchable) {
    return <Link to={team.url()}>{team.get('title')}</Link>;
  }
  return <Box component="span">{team.get('title')}</Box>;
}

function RegisterButton({ isAlreadyRegistered, team, handleClick, intl }) {
  if (!isAlreadyRegistered) {
    return (
      <Button
        variant="contained"
        disabled={team.status !== STATUS.active}
        onClick={handleClick}
        sx={{ fontWeight: 'bold', minWidth: '150px' }}
      >
        {t('.register_btn', intl, __filenamespace)}
      </Button>
    );
  }
  return (
    <Button
      variant="contained"
      onClick={handleClick}
      sx={{ fontWeight: 'bold', minWidth: '150px' }}
      endIcon={<DoneIcon sx={{ color: uhColors.green }} />}
    >
      {t('.registered_btn', intl, __filenamespace)}
    </Button>
  );
}

function TeamTitle({
  intl,
  team,
  style,
  titleStyle,
  secondaryTextStyle,
  touchable,
  hasRegisterBtn,
}) {
  const styles = {
    root: {
      lineHeight: '1.5em',
      position: 'relative',
      flex: '1',
      flexWrap: 'wrap',
      paddingLeft: '16px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    teamTypeBar: {
      width: '4px',
      backgroundColor: team.get('team_type')?.color,
      border: '2px',
      borderRadius: '2px',
      position: 'absolute',
      left: '0px',
      top: '3px',
      bottom: '3px',
    },

    primaryHeaderText: {
      fontWeight: 500,
      fontSize: '1.2em',
      width: '100%',
      flex: '1',
      flexWrap: 'wrap',
      ...titleStyle,
    },

    secondaryHeaderText: {
      fontWeight: 400,
      color: grey[600],
      width: '100%',
      ...secondaryTextStyle,
    },
    verticalDivider: {
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
      color: grey[400],
    },
    chip: {
      alignItems: 'center',
      border: `thin solid ${uhColors.primaryMain}`,
      borderRadius: 25,
      display: 'flex',
      padding: '1px 12px',
      marginTop: '.5rem',
      marginBottom: '.5rem',
      width: 100,
    },
  };
  const userId = currentUser().customer_user_id;
  const isAlreadyRegistered = team.isAlreadyRegistered(userId);
  const eventId = team.id;
  const handleClick = _e => {
    _e.preventDefault();
    if (isLoggedIn()) {
      CartActions.fetch({});
      EventDisplayActions.clientDisplayMounted({ eventId, isTeamsPage: true });
      AutomationTemplateDescriptionActions.list({
        eventId,
      });
      ClientCreditActions.list({ eventIds: eventId });
      EventActions.fetchAvailability({ id: eventId });
      RegistrationActions.list({ event_id: eventId });
    }
    PurchaseDrawerActions.openDrawer();
  };

  return (
    <Box sx={{ ...styles.root, ...style }}>
      <Box>
        <Box sx={styles.teamTypeBar}>&ensp;</Box>
        <Box
          sx={
            team.get('title') === ''
              ? {
                  color: grey[600],
                  fontWeight: 500,
                  fontSize: '1.2em',
                  width: '100%',
                }
              : styles.primaryHeaderText
          }
        >
          <Title team={team} touchable={touchable} />
        </Box>
        <Box sx={styles.secondaryHeaderText}>
          <Box component="span">
            {team.get('team_type')?.name === ''
              ? 'Team Type'
              : team.get('team_type')?.name}
          </Box>
        </Box>
        {team.privacy && (
          <Box sx={styles.chip}>
            <CheckIcon />
            {t('.private', intl, __filenamespace)}
          </Box>
        )}
      </Box>
      {hasRegisterBtn && (
        <RegisterButton
          intl={intl}
          team={team}
          isAlreadyRegistered={isAlreadyRegistered}
          handleClick={handleClick}
        />
      )}
    </Box>
  );
}

TeamTitle.propTypes = {
  team: PropTypes.instanceOf(CustomerTeam).isRequired,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  secondaryTextStyle: PropTypes.object,
  touchable: PropTypes.bool,
};

TeamTitle.defaultProps = {
  style: {},
  titleStyle: {},
  secondaryTextStyle: {},
  touchable: false,
};

export default injectIntl(TeamTitle);
