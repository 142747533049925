/* eslint-disable no-restricted-syntax */
const keyMappings = {
  commission_payroll_entry: ['membership_commission', 'retail_commission'],
  payroll_entry: ['SessionPayrollEntry', 'EventPayrollEntry'],
  time_log: 'TimeLog',
};

function mapToTargetKey(key) {
  for (const [targetKey, keys] of Object.entries(keyMappings)) {
    if (keys.includes(key)) {
      return targetKey;
    }
  }
  return key.replace(/_/g, '-');
}

function consolidatePayrollData(inputObject) {
  const mergedObject = {};
  for (const [key, value] of Object.entries(inputObject)) {
    const targetKey = mapToTargetKey(key);
    mergedObject[targetKey] = mergedObject[targetKey] || [];
    mergedObject[targetKey].push(...value);
  }
  return mergedObject;
}

export default consolidatePayrollData;
