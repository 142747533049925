import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
/*
 * This component was adapted for use with DecoratedTextField
 * from https://github.com/helderfarias/react-materialui-currency
 */
import DecoratedTextField from 'shared/components/DecoratedTextField.jsx';
import getSymbolFromCurrency from 'currency-symbol-map';

class CurrencyTextField extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.onInputType = this.onInputType.bind(this);
    this.formatRawValue = this.formatRawValue.bind(this);
    this.parseRawValue = this.parseRawValue.bind(this);
    this.defaultConverter = this.defaultConverter.bind(this);
    this.state = {
      rawValue: value,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setState({ rawValue: value });
    }
  }

  onInputType(event) {
    const input = event.target.value;

    let rawValue = this.parseRawValue(input);
    if (!rawValue) {
      rawValue = 0;
    }

    this.notifyParentWithRawValue(event, rawValue);

    this.setState({ rawValue });
  }

  notifyParentWithRawValue(event, rawValue) {
    const { converter, onChange } = this.props;
    const display = this.formatRawValue(rawValue);
    const converterFn = converter || this.defaultConverter;

    onChange(event, converterFn(rawValue), display);
  }

  // eslint-disable-next-line class-methods-use-this
  parseRawValue(displayedValue) {
    const value = displayedValue.replace(/[^0-9]/g, '').replace(',', '.');

    return parseFloat(value);
  }

  formatRawValue(rawValue) {
    const { intl, precision } = this.props;

    return intl.formatNumber(rawValue / 10 ** precision, {
      style: 'decimal',
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
      useGrouping: false,
    });
  }

  defaultConverter(val) {
    const { precision } = this.props;
    const raw = val.toString();

    if (Number.isNaN(parseFloat(raw))) {
      return 0;
    }

    if (!raw.length) {
      return parseFloat(raw);
    }

    if (precision >= raw.length) {
      return parseFloat(raw);
    }

    const prefix = raw.slice(0, raw.length - precision);
    const sufix = raw.slice(raw.length - precision, raw.length);
    return parseFloat(`${prefix}.${sufix}`);
  }

  render() {
    const {
      currency,
      symbolStyle,
      converter,
      delimiter,
      separator,
      precision,
      intl, // eslint-disable-line no-unused-vars
      ...otherProps
    } = this.props;
    const { rawValue } = this.state;
    return (
      <DecoratedTextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        type="text"
        decoration={getSymbolFromCurrency(currency)}
        decorationStyle={symbolStyle}
        onChange={this.onInputType}
        value={this.formatRawValue(rawValue)}
      />
    );
  }
}

CurrencyTextField.propTypes = {
  converter: PropTypes.func,
  currency: PropTypes.string,
  delimiter: PropTypes.string,
  intl: PropTypes.object.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  precision: PropTypes.number,
  separator: PropTypes.string,
  symbolStyle: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CurrencyTextField.defaultProps = {
  converter: () => {},
  currency: 'USD',
  name: '',
  delimiter: ',',
  onChange: () => {},
  position: 'left',
  precision: 2,
  separator: '.',
  symbolStyle: {},
  value: 0,
};

export default injectIntl(CurrencyTextField);
