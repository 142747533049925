/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import WrappableName from 'shared/components/WrappableName.jsx';
import {
  FlexBox,
  FlexBoxJustify,
  FlexBoxCenter,
} from 'shared/components/FlexBox.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';

const styles = {
  clientCard: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    padding: '12px',
    position: 'relative',
    justifyContent: 'space-between',
  },

  clientCardContainer: {
    alignItems: 'center',
    flex: '0 0 55%',
  },

  clientName: {
    cursor: 'pointer',
  },

  managingUserContainer: {
    marginTop: 4,
  },

  creditInfoContainer: {
    marginTop: 4,
    flexDirection: 'column',
  },

  managingUserIcon: {
    height: 12,
    width: 12,
    marginRight: 8,
  },

  price: {
    fontWeight: 'bold',
  },

  creditLength: {
    padding: '0 15px',
  },

  expiresMessage: {
    fontWeight: 'bold',
  },

  userInfoContainer: {
    cursor: 'pointer',
  },
};

function CreditLength({ expiresAt }) {
  if (!expiresAt) {
    return null;
  }

  return (
    <div style={styles.creditLength}>
      <span style={styles.expiresMessage}>
        <FormattedMessage id={messageId('.expires', __filenamespace)} />
      </span>
      <FormattedDate
        value={expiresAt}
        month="short"
        day="numeric"
        year="numeric"
      />
    </div>
  );
}

function CreditInfo({ creditsUsed, creditsTotal, unlimited }) {
  return (
    <FlexBoxCenter style={styles.creditInfoContainer}>
      {unlimited ? (
        <FormattedMessage id={messageId('.unlimited', __filenamespace)} />
      ) : (
        <FormattedMessage
          id={messageId('.n_of_m_credits', __filenamespace)}
          values={{ n: creditsUsed, m: creditsTotal }}
        />
      )}
    </FlexBoxCenter>
  );
}

function ContextMenu({ client, creditPassId, intl }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon color={uhColors.iconGrey} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            ClientProfileDrawerActions.mounted({ clientId: client.id });
          }}
        >
          {t('actions.view', intl)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            BalanceListDrawerActions.openDrawer({
              clientId: client.managing_customer_user?.id || client.id,
              creditPassGranteeIds: [client.id],
              creditPassIds: [creditPassId],
              balanceRemainingMax: null,
            });
          }}
        >
          {t('.balances', intl, __filenamespace)}
        </MenuItem>
      </Menu>
    </>
  );
}

function ManagingUserInfo({ client }) {
  if (!client.managing_customer_user) {
    return null;
  }

  return (
    <FlexBoxCenter style={styles.managingUserContainer}>
      <ManagingUserIcon style={styles.managingUserIcon} />
      <WrappableName
        nameable={client.managing_customer_user}
        className="cp-view-client-name"
        style={styles.clientName}
      />
    </FlexBoxCenter>
  );
}

function CreditPassClientLarge({ client, clientDetails, creditPassId, intl }) {
  return (
    <Paper className="cp-view-client" style={styles.clientCard}>
      <FlexBoxJustify style={styles.clientCardContainer}>
        <FlexBoxCenter
          className="cp-view-client-card-user-info"
          onClick={() =>
            ClientProfileDrawerActions.mounted({ clientId: client.id })
          }
          style={styles.userInfoContainer}
        >
          <UserAvatar user={client} />
          <div>
            <FlexBoxCenter>
              <WrappableName
                nameable={client}
                className="cp-view-client-name"
                style={styles.clientName}
              />
            </FlexBoxCenter>
            <ManagingUserInfo client={client} />
          </div>
        </FlexBoxCenter>
        <CreditLength expiresAt={clientDetails.get('expiresAt')} />
      </FlexBoxJustify>
      <CreditInfo
        creditsUsed={clientDetails.get('creditsUsed', 0)}
        creditsTotal={clientDetails.get('creditsTotal', 0)}
        unlimited={clientDetails.get('unlimited', false)}
      />
      <ContextMenu client={client} creditPassId={creditPassId} intl={intl} />
    </Paper>
  );
}

function CreditPassClientSmall({ client, clientDetails, creditPassId, intl }) {
  return (
    <Paper className="cp-view-client" style={styles.clientCard}>
      <FlexBox className="cp-view-client-card-user-info">
        <UserAvatar
          user={client}
          onClick={() =>
            ClientProfileDrawerActions.mounted({ clientId: client.id })
          }
        />
        <div>
          <WrappableName
            nameable={client}
            className="cp-view-client-name"
            style={styles.clientName}
            onClick={() =>
              ClientProfileDrawerActions.mounted({ clientId: client.id })
            }
          />
          <ManagingUserInfo client={client} />
        </div>
      </FlexBox>
      <CreditLength expiresAt={clientDetails.get('expiresAt')} />
      <ContextMenu client={client} creditPassId={creditPassId} intl={intl} />
    </Paper>
  );
}

function ClientCard(props) {
  return (
    <ResponsiveElement
      largeScreen={<CreditPassClientLarge {...props} />}
      smallScreen={<CreditPassClientSmall {...props} />}
    />
  );
}

export default injectIntl(ClientCard);
