import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

function OpenBookingClassEventCard({ clients, inner, orderItem, intl }) {
  return inner ? (
    <>
      <Grid container item xs={12}>
        <Typography className="order-item__name" align="left" variant="h4">
          {orderItem.get('primary_display_text')}
        </Typography>
      </Grid>
      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.client', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" align="right">
            {clients
              .get(orderItem.getIn(['orderable', 'client_ids']).first())
              .name()}
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid item>
        <Typography className="order-item__title" variant="subtitle2">
          <FormattedMessage
            id={messageId('.qty', __filenamespace)}
            values={{
              qty: orderItem.get('display_quantity'),
            }}
          />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" align="right">
          {orderItem.get('payment_method') === 'credit' ? (
            <FormattedMessage
              id={messageId('.credit_qty', __filenamespace)}
              values={{
                qty: orderItem.get('orderable').get('quantity'),
              }}
            />
          ) : (
            <FormattedCurrency value={orderItem.get('total') || 0} fromCents />
          )}
        </Typography>
      </Grid>
    </>
  );
}

OpenBookingClassEventCard.propTypes = {
  intl: PropTypes.object.isRequired,
  orderItem: PropTypes.object,
};

OpenBookingClassEventCard.defaultProps = {
  orderItem: {},
};

export default OpenBookingClassEventCard;
