import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@upperhand/playmaker';

import EmptyStateIcon from 'shared/components/icons/empty_states/PaymentMethods.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import PaymentMethodModalActions from 'containers/client/settings/paymentMethodModal/Actions';

function EmptyState({ intl, access }) {
  return (
    <div className="empty-state">
      <div className="empty-state__icon">
        <EmptyStateIcon />
      </div>
      <Typography className="empty-state__title" variant="h3">
        {access
          ? t('.title', intl, __filenamespace)
          : t('.title_no_access', intl, __filenamespace)}
      </Typography>
      <Typography className="empty-state__description" variant="body3">
        {access
          ? t('.description', intl, __filenamespace)
          : t('.description_no_access', intl, __filenamespace)}
      </Typography>
      {access && (
        <Button
          classes={{ root: 'empty-state__btn' }}
          type="primary"
          onClick={PaymentMethodModalActions.openModal}
        >
          {t('.btn', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

EmptyState.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default EmptyState;
