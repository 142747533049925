import React from 'react';
import { Spinner } from '@upperhand/playmaker';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import moment from 'moment-timezone';
import Checkbox from '@mui/material/Checkbox';
import BookingButton from 'shared/components/registration/BookingButton.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ActionDone from '@mui/icons-material/Done';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import {
  formatDate,
  formatClientTime,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import POSPurchasedRegistrationActions from 'point_of_sale/actions/POSPurchasedRegistrationActions.jsx';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const styles = {
  confilictScreenContainer: {
    sessionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      marginTop: '15px',
    },
    changeOptionContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      marginLeft: '-30px',
    },
    chnageSchudleText: {
      color: uhColors.activeBlue,
      fontSize: '17px',
      textAlign: 'center',
    },
    requestedSchudleCard: {
      with: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${uhColors.activeRepeatButton}`,
      boxShadow: '0 0 2px -1px rgba(0,0,0,0.6)',
      padding: '15px',
      borderRadius: '4px',
      marginTop: '10px',
    },
    requestedSchudleText: {
      color: uhColors.charcoalBlack,
      fontSize: '16px',
      fontWeight: '600',
    },
    repeatSchudleText: {
      color: uhColors.charcoalBlack,
      fontSize: '15px',
      fontWeight: '400',
    },
    dateBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: '10px',
    },
    dayText: {
      color: uhColors.charcoalBlack,
      fontSize: '16px',
      fontWeight: '600',
    },
    timeText: {
      color: uhColors.activeRepeatButton,
      fontSize: '14px',
      fontWeight: '400',
    },
    schudleConflict: {
      height: '80px',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      padding: '13px',
    },
    paymentOptionButton: {
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
      verticalAlign: 'middle',
      lineHeight: '90px',
      height: '90px',
      flexDirection: 'column',
    },
  },
  selectTimeSchudleContainer: {
    cardBox: {
      with: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${uhColors.activeRepeatButton}`,
      boxShadow: '0 0 2px -1px rgba(0,0,0,0.6)',
      padding: '13px',
      borderRadius: '4px',
      marginBottom: '10px',
    },
    cardLeftContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardRightContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  },
  loading: {
    root: {
      backgroundColor: uhColors.textWhite,
      height: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
    },
    loading: {
      padding: '16px',
    },
  },
};

// request card will be update after props available
function RequestScheduleCard({
  numberOfDays,
  startTimeRepeat,
  numberOfSessions,
  bookableSessions,
  duration,
}) {
  const viewDay = day => day.map(x => x.slice(0, 3)).join(',');

  const handleDuration = _duration => {
    const hour = _duration.split(' ', 1).toString().split('').includes('h');
    const result = _duration
      .split(' ', 1)
      .map(x => x.substring(0, 1))
      .toString();
    if (hour) {
      return result * 60;
    }
    return _duration
      .split(' ', 1)
      .map(x => x.substring(0, 2))
      .toString();
  };
  return (
    <div style={styles.confilictScreenContainer.requestedSchudleCard}>
      <div style={{ marginTop: '10px' }}>
        <span style={styles.confilictScreenContainer.requestedSchudleText}>
          Requested schedule
        </span>
      </div>
      <div style={{ marginTop: '15px' }}>
        <span style={styles.confilictScreenContainer.repeatSchudleText}>
          Repeating every week
        </span>
      </div>
      <div style={styles.confilictScreenContainer.dateBox}>
        <div>
          <span style={styles.confilictScreenContainer.dayText}>
            {viewDay(numberOfDays)}
          </span>
        </div>
        <div>
          <span style={styles.confilictScreenContainer.timeText}>
            {formatClientTime(startTimeRepeat, 'h:mm A')}M -{' '}
            {moment(startTimeRepeat, 'h:mm AM')
              .add(`${handleDuration(duration)}`, 'minutes')
              .format('h:mm A')}
            M
          </span>
        </div>
      </div>
      <div style={styles.confilictScreenContainer.sessionContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <ActionDone sx={{ color: uhColors.activeBlue }} />
          </div>
          <span>
            {bookableSessions}/{numberOfSessions} Sessions Available
          </span>
        </div>
        <IconButton onClick={() => {}}>
          <ChevronRight sx={{ color: uhColors.activeRepeatButton }} />
        </IconButton>
      </div>
    </div>
  );
}

function SchudleConflictTime({
  date,
  availabilityTime,
  unaAvailAbility,
  handlebookeable,
}) {
  return (
    <div style={styles.selectTimeSchudleContainer.cardBox}>
      <div style={styles.selectTimeSchudleContainer.cardLeftContent}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...label}
            onChange={handlebookeable}
            і
            disabled={!!(availabilityTime === '' || undefined || null)}
            style={{ width: '5px' }}
          />
          <div>
            <span
              style={{
                color:
                  availabilityTime === '' ? uhColors.activeRepeatButton : 'red',
              }}
            >
              {date}
            </span>
          </div>
        </div>
        <div>
          <span
            style={{
              color:
                availabilityTime === '' ? uhColors.activeRepeatButton : 'red',
            }}
          >
            {unaAvailAbility} (unavailable)
          </span>
        </div>
      </div>
      {availabilityTime ? (
        <div style={styles.selectTimeSchudleContainer.cardRightContainer}>
          <div>
            <span style={{ color: uhColors.tableGrey, marginTop: '10px' }}>
              Closest Time
            </span>
          </div>
          <div>
            <span>
              {moment(availabilityTime.start_time, 'HH:mm:ss').format(
                'hh:mm A'
              )}
            </span>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            marginTop: '10px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span> No times additional available</span>
        </div>
      )}
    </div>
  );
}

function ConflictSchedule({
  numberOfDays,
  startTimeRepeat,
  numberOfSessions,
  sessionConflicts,
  availableTimesRepeat,
  selectedClientId,
  athletes,
  duration,
  suggestedTimes,
  nonBookableSessions,
  bookableSessions,
  isLoading,
  handleBookable,
  isOrderItem,
  repeatBookingActions,
  registrationPackage,
}) {
  const selectedProfile = athletes?.find(a => a.id === selectedClientId);
  const timeForConflict = () => {
    const defaultTime = registrationPackage.tentative_details
      .get('1')
      .toServer();
    return defaultTime?.starts_at;
  };
  if (isLoading || isOrderItem) {
    return (
      <div style={{ ...styles.loading.root, ...styles.loading.loading }}>
        <Spinner />
      </div>
    );
  }

  const handleSessions = () => {
    POSPurchasedRegistrationActions.setRepeatBookingRegistrationPackage.defer(
      bookableSessions
    );
    repeatBookingActions.enableSummaryScreen();
  };
  return (
    <div>
      <div style={styles.confilictScreenContainer.changeOptionContainer}>
        <IconButton
          onClick={() => repeatBookingActions.disableSummaryOrConflictScreen()}
        >
          <ChevronLeft sx={{ color: uhColors.activeBlue }} />
        </IconButton>
        <div>
          <span style={styles.confilictScreenContainer.chnageSchudleText}>
            Change Schedule options
          </span>
        </div>
      </div>
      <FlexBoxCenter style={{ height: 43 }}>
        <UserAvatar user={selectedProfile} style={{ marginRight: '1rem' }} />
        {selectedProfile?.name()}
      </FlexBoxCenter>
      <div style={{ marginTop: '10px', width: '100%' }}>
        <span style={styles.availableCradit}>Requested schedule</span>
      </div>
      <RequestScheduleCard
        numberOfDays={numberOfDays}
        startTimeRepeat={startTimeRepeat}
        sessionConflicts={sessionConflicts}
        numberOfSessions={numberOfSessions}
        availableTimesRepeat={availableTimesRepeat}
        duration={duration}
        bookableSessions={bookableSessions?.length}
      />
      <div style={{ height: '10px' }} />
      {suggestedTimes?.length === 0 && nonBookableSessions?.length === 0 ? (
        <div
          style={merge(styles.confilictScreenContainer.schudleConflict, {
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <span style={styles.confilictScreenContainer.requestedSchudleText}>
            No Conflicts About Given Sessions
          </span>
        </div>
      ) : (
        ''
      )}
      {nonBookableSessions.length > 0 && suggestedTimes?.length === 0 ? (
        <div style={styles.confilictScreenContainer.schudleConflict}>
          <div>
            <span style={styles.confilictScreenContainer.requestedSchudleText}>
              {nonBookableSessions?.length} Schedule conflict(s)
            </span>
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span
              style={{ lineHeight: '1.25', color: uhColors.activeRepeatButton }}
            >
              Following dates are not available to book.
            </span>
          </div>
          <div style={{ marginTop: '10px' }}>
            {nonBookableSessions?.map((item, index) => (
              <SchudleConflictTime
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                date={formatDate(item?.date)}
                availabilityTime=""
                unaAvailAbility={item?.starts_at}
                handlebookeable={() => {}}
              />
            ))}
            <div style={{ height: '20px' }} />
            <div style={styles.confilictScreenContainer.paymentOptionButton}>
              {bookableSessions.length !== 0 ? (
                <BookingButton
                  title=" CONTINUE TO PAYMENT OPTIONS"
                  handleClicked={handleSessions}
                />
              ) : (
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <span
                    style={{
                      lineHeight: '1.25',
                      color: 'red',
                    }}
                  >
                    The following events cant be booked.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {suggestedTimes.length > 0 &&
      nonBookableSessions?.length > 0 &&
      bookableSessions.length > 0 ? (
        <div style={styles.confilictScreenContainer.schudleConflict}>
          <div>
            <span style={styles.confilictScreenContainer.requestedSchudleText}>
              {/* eslint-disable-next-line no-unsafe-optional-chaining */}
              {suggestedTimes?.length + nonBookableSessions?.length} Schedule
              conflict(s)
            </span>
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span
              style={{ lineHeight: '1.25', color: uhColors.activeRepeatButton }}
            >
              The following dates are not available. Confirm suggested time or
              continue to payment.
            </span>
          </div>
          <div style={{ marginTop: '10px' }}>
            {nonBookableSessions?.map((item, index) => (
              <SchudleConflictTime
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                date={formatDate(item?.date)}
                availabilityTime=""
                unaAvailAbility={item?.starts_at}
                handlebookeable={() => {}}
              />
            ))}
          </div>
          <div style={{ marginTop: '10px' }}>
            {suggestedTimes?.map((item, index) => {
              const time = moment(timeForConflict()).format('hh:mm a');
              if (
                (!Number(item?.resource_id) &&
                  bookableSessions[0]?.staff_ids === null) ||
                bookableSessions[0]?.staff_ids === undefined
              ) {
                return (
                  <SchudleConflictTime
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    availabilityTime=""
                    handlebookeable={() => {}}
                    date={formatDate(item?.conflicted_date)}
                    unaAvailAbility={item?.time_slot?.starts_at}
                  />
                );
              }
              return (
                <SchudleConflictTime
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  date={formatDate(item?.conflicted_date)}
                  availabilityTime={item?.time_slot}
                  unaAvailAbility={time}
                  handlebookeable={(event, values) =>
                    handleBookable(values, item)
                  }
                />
              );
            })}
          </div>
          <div style={{ height: '20px' }} />
          <div style={styles.confilictScreenContainer.paymentOptionButton}>
            <BookingButton
              title=" CONTINUE TO PAYMENT OPTIONS"
              handleClicked={handleSessions}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {suggestedTimes?.length > 0 &&
      nonBookableSessions?.length > 0 &&
      bookableSessions?.length === 0 ? (
        <div style={styles.confilictScreenContainer.schudleConflict}>
          <div>
            <span style={styles.confilictScreenContainer.requestedSchudleText}>
              {/* eslint-disable-next-line no-unsafe-optional-chaining */}
              {suggestedTimes?.length + nonBookableSessions?.length} Schedule
              conflict(s)
            </span>
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span
              style={{ lineHeight: '1.25', color: uhColors.activeRepeatButton }}
            >
              The following dates are not available. Confirm suggested time or
              continue to payment.
            </span>
          </div>
          <div style={{ marginTop: '10px' }}>
            {nonBookableSessions?.map((item, index) => (
              <SchudleConflictTime
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                date={formatDate(item?.date)}
                availabilityTime=""
                unaAvailAbility={item?.starts_at}
                handlebookeable={() => {}}
              />
            ))}
          </div>
          <div style={{ marginTop: '10px' }}>
            {bookableSessions.length > 0 ? (
              <div style={{ marginTop: '10px' }}>
                {suggestedTimes?.map((item, index) => {
                  const time = moment(timeForConflict()).format('hh:mm a');
                  if (
                    (!Number(item?.resource_id) &&
                      bookableSessions[0]?.staff_ids === null) ||
                    bookableSessions[0]?.staff_ids === undefined
                  ) {
                    return (
                      <SchudleConflictTime
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        availabilityTime=""
                        handlebookeable={() => {}}
                        date={formatDate(item?.conflicted_date)}
                        unaAvailAbility={item?.time_slot?.starts_at}
                      />
                    );
                  }
                  return (
                    <SchudleConflictTime
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      date={formatDate(item?.conflicted_date)}
                      availabilityTime={item?.time_slot}
                      unaAvailAbility={time}
                      handlebookeable={(event, values) =>
                        handleBookable(values, item)
                      }
                    />
                  );
                })}
                <div style={{ height: '20px' }} />
                <div
                  style={styles.confilictScreenContainer.paymentOptionButton}
                >
                  {bookableSessions.length !== 0 ? (
                    <BookingButton
                      title=" CONTINUE TO PAYMENT OPTIONS"
                      handleClicked={handleSessions}
                    />
                  ) : (
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <span
                        style={{
                          lineHeight: '1.25',
                          color: 'red',
                        }}
                      >
                        The following events cant be booked.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ marginTop: '10px' }}>
                {suggestedTimes?.map((item, index) => {
                  const time = moment(timeForConflict()).format('hh:mm a');
                  return (
                    <SchudleConflictTime
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      date={formatDate(item?.conflicted_date)}
                      availabilityTime={item?.time_slot}
                      unaAvailAbility={time}
                      handlebookeable={(event, values) =>
                        handleBookable(values, item)
                      }
                    />
                  );
                })}
                <div style={{ height: '20px' }} />
                <div
                  style={styles.confilictScreenContainer.paymentOptionButton}
                >
                  <BookingButton
                    title=" CONTINUE TO PAYMENT OPTIONS"
                    handleClicked={handleSessions}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {suggestedTimes?.length > 0 && nonBookableSessions?.length === 0 ? (
        <div style={styles.confilictScreenContainer.schudleConflict}>
          <div>
            <span style={styles.confilictScreenContainer.requestedSchudleText}>
              {suggestedTimes?.length} Schedule conflict(s)
            </span>
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span
              style={{ lineHeight: '1.25', color: uhColors.activeRepeatButton }}
            >
              The following dates are not available. Confirm suggested time or
              select new time below.
            </span>
          </div>
          {bookableSessions.length > 0 ? (
            <div style={{ marginTop: '10px' }}>
              {suggestedTimes?.map((item, index) => {
                const time = moment(timeForConflict()).format('hh:mm a');
                if (
                  (!Number(item?.resource_id) &&
                    bookableSessions[0]?.staff_ids === null) ||
                  bookableSessions[0]?.staff_ids === undefined
                ) {
                  return (
                    <SchudleConflictTime
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      availabilityTime=""
                      handlebookeable={() => {}}
                      date={formatDate(item?.conflicted_date)}
                      unaAvailAbility={item?.time_slot?.starts_at}
                    />
                  );
                }
                return (
                  <SchudleConflictTime
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    date={formatDate(item?.conflicted_date)}
                    availabilityTime={item?.time_slot}
                    unaAvailAbility={time}
                    handlebookeable={(event, values) =>
                      handleBookable(values, item)
                    }
                  />
                );
              })}
              <div style={{ height: '20px' }} />
              <div style={styles.confilictScreenContainer.paymentOptionButton}>
                {bookableSessions.length !== 0 ? (
                  <BookingButton
                    title=" CONTINUE TO PAYMENT OPTIONS"
                    handleClicked={handleSessions}
                  />
                ) : (
                  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <span
                      style={{
                        lineHeight: '1.25',
                        color: 'red',
                      }}
                    >
                      The following events cant be booked.
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{ marginTop: '10px' }}>
              {suggestedTimes?.map((item, index) => {
                const time = moment(timeForConflict()).format('hh:mm a');
                return (
                  <SchudleConflictTime
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    date={formatDate(item?.conflicted_date)}
                    availabilityTime={item?.time_slot}
                    unaAvailAbility={time}
                    handlebookeable={(event, values) =>
                      handleBookable(values, item)
                    }
                  />
                );
              })}
              <div style={{ height: '20px' }} />
              <div style={styles.confilictScreenContainer.paymentOptionButton}>
                <BookingButton
                  title=" CONTINUE TO PAYMENT OPTIONS"
                  handleClicked={handleSessions}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default ConflictSchedule;
