import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function Location({ location, style }) {
  if (!location || !location.id) {
    return null;
  }

  const { address } = location;

  return (
    <div style={style}>
      <SectionHeader style={{ marginBottom: 20 }}>
        <FormattedMessage id={messageId('.address', __filenamespace)} />
      </SectionHeader>

      <div style={{ marginBottom: 4 }}>{location.name}</div>
      <div>
        <div style={{ marginBottom: 4 }}>{address.line_1}</div>
        <div style={{ marginBottom: 4 }}>{address.line_2}</div>
        <div style={{ marginBottom: 4 }}>
          {address.city}, {address.state} {address.postal_code}
        </div>
      </div>
    </div>
  );
}

export default Location;
