/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

import Icon404 from './404.svg';
import LeftCarrot from './left-carrot.svg';
import './styles.scss';

function NotFound({ intl }) {
  const navigate = useNavigate();

  return (
    <div className="container-404">
      <div className="container-404__nav">
        <h3>
          <img src={LeftCarrot} onClick={() => navigate(-1)} alt="Back" />
          <span className="vl" />
          {t('.error', intl, __filenamespace)}
        </h3>
      </div>
      <div className="container-404__main-content">
        <img src={Icon404} alt="404" />
        <h3>{t('.heading', intl, __filenamespace)}</h3>
        <p>{t('.explanation', intl, __filenamespace)}</p>
        <Link to={customerScopedRoute('')}>
          {t('.not_found_btn', intl, __filenamespace)}
        </Link>
      </div>
    </div>
  );
}

export default injectIntl(NotFound);
