import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { PaymentMethodActions } from 'sources';

class PaymentMethodDataStore extends UpperHandStore {
  constructor() {
    super();

    this.paymentMethods = Map();

    this.bindListeners({
      createSuccess: PaymentMethodActions.createSuccess,
      createError: PaymentMethodActions.createError,
      listSuccess: PaymentMethodActions.listSuccess,
      listError: PaymentMethodActions.listError,
      updateSuccess: PaymentMethodActions.updateSuccess,
      updateError: PaymentMethodActions.updateError,
    });
  }

  createSuccess(paymentMethod) {
    this.paymentMethods = this.paymentMethods.set(
      paymentMethod.id,
      paymentMethod
    );
  }

  createError(...args) {
    this.notifyError('error creating payment method', args);
  }

  listSuccess(paymentMethods) {
    this.paymentMethods = this.paymentMethods.merge(paymentMethods);
  }

  listError(...args) {
    this.notifyError('error listing payment methods', args);
  }

  updateSuccess(paymentMethod) {
    this.paymentMethods = this.paymentMethods.set(
      paymentMethod.id,
      paymentMethod
    );
  }

  updateError(...args) {
    this.notifyError('error updating payment method', args);
  }
}

export default alt.createStore(
  PaymentMethodDataStore,
  'PaymentMethodDataStore'
);
