import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { Set } from 'immutable';
import { Grid, Typography, Drawer, Icon, Spinner } from '@upperhand/playmaker';
import Divider from '@mui/material/Divider';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import SubscriptionStatusText from 'containers/clientProfile/components/SubscriptionStatusText.jsx';
import MembershipAgreementIndicator from 'shared/components/icons/MembershipAgreemenIndicator.jsx';
import MembershipAgreementView from 'shared/components/icons/MembershipAgreementView.jsx';
import { MembershipViewingBenefits } from 'memberships/components/MembershipViewing.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import Client from 'shared/records/Client.jsx';
import MembershipTier from 'shared/records/MembershipTier';
import Membership from 'shared/records/Membership.jsx';

import {
  MembershipTierDataStore,
  ClientDataStore,
  EventTypeDataStore,
  CreditPassDataStore,
  RetailVendorsDataStore,
  MembershipDataStore,
} from 'dataStores';

import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';
import MembershipAgreementFormActions from 'shared/actions/MembershipAgreementFormActions';

import MembershipOverviewDrawerActions from './Actions';
import MembershipOverviewDrawerStore from './Store';

import './styles.scss';

const inlineStyles = {
  benefitWrapper: {
    padding: '10px 0px',
  },

  benefitItem: {
    marginLeft: -2,
  },
};

function MembershipOverviewDrawer({
  clientDataStore,
  membershipOverviewDrawerStore,
  membershipTierDataStore,
  eventTypeDataStore,
  creditPassDataStore,
  retailVendorsDataStore,
  membershipDataStore,
}) {
  const intl = useIntl();
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);

  const { clients } = clientDataStore;
  const { tiers } = membershipTierDataStore;
  const { eventTypes } = eventTypeDataStore;
  const { creditPasses } = creditPassDataStore;
  const { retailCategories } = retailVendorsDataStore;
  const { memberships } = membershipDataStore;
  const {
    isOpen,
    clientId,
    subscription,
    tierLoading,
    eventTypesLoading,
    creditPassesLoading,
    retailCategoriesLoading,
    clientLoading,
    membershipLoading,
  } = membershipOverviewDrawerStore;

  useEffect(
    () => () => {
      setDetailsExpanded(true);
      setDescriptionExpanded(true);
    },
    [clientId]
  );

  const mainInfoLoading = tierLoading || clientLoading || membershipLoading;
  const benefitsLoading =
    creditPassesLoading || retailCategoriesLoading || eventTypesLoading;
  const { membership_tier_id: membershipTierId } = subscription;
  const tier = tiers.get(membershipTierId, new MembershipTier());
  const client = clients.get(clientId, new Client());
  const membership = memberships.get(
    subscription.membership_id,
    new Membership()
  );
  const infoItem = membership.tiered ? tier : membership;
  const agreementContent = currentCustomer().membership_agreement;
  const hasUnSignedAgreement =
    subscription && client.hasUnSignedAgreement(subscription);
  const hasDownloadEnabled = agreementContent && !hasUnSignedAgreement;
  const signedDate = client.getAgreementSignedDate();
  const showViewAgreementBtn =
    agreementContent && subscription && !hasUnSignedAgreement;
  const showNoAgreementBtn =
    agreementContent && subscription && hasUnSignedAgreement;

  const handleToggleDetails = () => {
    setDetailsExpanded(!detailsExpanded);
  };

  const handleToggleDescription = () => {
    setDescriptionExpanded(!descriptionExpanded);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      classes={{
        root: 'membership-overview-drawer',
        content: 'membership-overview-drawer__content',
      }}
      title={t('.title', intl, __filenamespace)}
      onClose={() => MembershipOverviewDrawerActions.close()}
      content={
        isOpen ? (
          <>
            {mainInfoLoading && (
              <div className="membership-overview-drawer__loading">
                <Spinner />
              </div>
            )}
            {!mainInfoLoading && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className="membership-overview-drawer__column"
                >
                  <ClientDetails client={client} />
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="membership-overview-drawer__column"
                >
                  <Typography
                    variant="subtitle1"
                    className="membership-overview-drawer__membership-name"
                  >
                    {membership.name}
                  </Typography>
                  {membership.tiered && (
                    <Typography
                      variant="subtitle1"
                      className="membership-overview-drawer__tier-name"
                    >
                      {t('.tier_name', intl, __filenamespace, {
                        name: <span>{tier.name}</span>,
                      })}
                    </Typography>
                  )}
                  <button
                    type="button"
                    className="membership-overview-drawer__view-payment-history-button"
                    onClick={() =>
                      BalanceListDrawerActions.openDrawer({
                        clientId: client.managing_customer_user_id || client.id,
                        membershipSubscriberId: client.id,
                        membershipIds: [subscription.membership.id],
                        balanceRemainingMax: null,
                      })
                    }
                  >
                    {t('.view_history', intl, __filenamespace)}
                  </button>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className="membership-overview-drawer__column"
                >
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {t('.member_length', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="flex-end"
                    className="membership-overview-drawer__column-value"
                  >
                    <Typography variant="body1">
                      {moment(subscription.starts_at).fromNow()}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {t('.status', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="flex-end"
                    className="membership-overview-drawer__column-value"
                  >
                    <Typography variant="body1">
                      <SubscriptionStatusText
                        intl={intl}
                        membershipSubscription={subscription}
                        subscriptionStatus={subscription.status}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {t('.charge', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="flex-end"
                    className="membership-overview-drawer__column-value"
                  >
                    <Typography variant="body1">
                      <FormattedCurrency value={infoItem.price} fromCents />
                      {infoItem.intervalMonths && (
                        <span>/{infoItem.intervalMonths}</span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {t('.next_payment', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="flex-end"
                    className="membership-overview-drawer__column-value"
                  >
                    <Typography variant="body1">
                      {subscription.expires_at
                        ? moment(subscription.expires_at).format('MM/DD/YYYY')
                        : t('.n_a', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {t('.expiration_date', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="flex-end"
                    className="membership-overview-drawer__column-value"
                  >
                    <Typography variant="body1">
                      {subscription.commitment_ends_at &&
                      moment(subscription.commitment_ends_at).isAfter()
                        ? moment(subscription.commitment_ends_at).format(
                            'MM/DD/YYYY'
                          )
                        : t('.n_a', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="membership-overview-drawer__column"
                >
                  {showNoAgreementBtn && (
                    <button
                      type="button"
                      className="membership-overview-drawer__view-agreement"
                      onClick={() =>
                        MembershipAgreementFormActions.openAgreementDrawer({
                          clientIds: Set([subscription.client_id]),
                          clientName: client.name(),
                          membershipId: subscription.membership_id,
                          hasSignedAgreement: hasDownloadEnabled,
                          signedAt: signedDate,
                        })
                      }
                    >
                      <MembershipAgreementIndicator className="membership-overview-drawer__agreement-indicator" />
                      <Typography variant="body1">
                        {t('.no_membership_agreement', intl, __filenamespace)}
                      </Typography>
                    </button>
                  )}
                  {showViewAgreementBtn && (
                    <button
                      type="button"
                      className="membership-overview-drawer__view-agreement"
                      onClick={() =>
                        MembershipAgreementFormActions.openAgreementDrawer({
                          clientIds: Set([subscription.client_id]),
                          clientName: client.name(),
                          membershipId: subscription.membership_id,
                          hasSignedAgreement: hasDownloadEnabled,
                          signedAt: signedDate,
                        })
                      }
                    >
                      <MembershipAgreementView className="membership-overview-drawer__agreement-indicator" />
                      <Typography variant="body1">
                        {t('.view_membership_agreement', intl, __filenamespace)}
                      </Typography>
                    </button>
                  )}
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="membership-overview-drawer__column"
                >
                  <button
                    type="button"
                    className="membership-overview-drawer__details-expand"
                    onClick={handleToggleDetails}
                  >
                    {t('.details', intl, __filenamespace)}
                    <Icon name={detailsExpanded ? 'arrowUp' : 'arrowDown'} />
                  </button>
                  {detailsExpanded && benefitsLoading && (
                    <div className="membership-overview-drawer__loading">
                      <Spinner />
                    </div>
                  )}
                  {detailsExpanded && !benefitsLoading && (
                    <MembershipViewingBenefits
                      membership={infoItem}
                      eventTypes={eventTypes}
                      creditPasses={creditPasses}
                      retailCategories={retailCategories}
                      containerStyle={{ fontSize: 14 }}
                      benefitsWrapperStyle={inlineStyles.benefitWrapper}
                      discountsItemStyle={inlineStyles.benefitItem}
                      exclusivitiesItemStyle={inlineStyles.benefitItem}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="membership-overview-drawer__column"
                >
                  <button
                    type="button"
                    className="membership-overview-drawer__details-expand"
                    onClick={handleToggleDescription}
                  >
                    {t('.description', intl, __filenamespace)}
                    <Icon
                      name={descriptionExpanded ? 'arrowUp' : 'arrowDown'}
                    />
                  </button>
                  {descriptionExpanded && (
                    <Typography
                      variant="body1"
                      className="membership-overview-drawer__description"
                    >
                      {membership.description}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        ) : null
      }
    />
  );
}

MembershipOverviewDrawer.propTypes = {
  membershipOverviewDrawerStore: PropTypes.object,
  membershipTierDataStore: PropTypes.object,
  clientDataStore: PropTypes.object,
  eventTypeDataStore: PropTypes.object,
  creditPassDataStore: PropTypes.object,
  retailVendorsDataStore: PropTypes.object,
  membershipDataStore: PropTypes.object,
};

MembershipOverviewDrawer.defaultProps = {
  membershipOverviewDrawerStore: {},
  membershipTierDataStore: {},
  clientDataStore: {},
  eventTypeDataStore: {},
  creditPassDataStore: {},
  retailVendorsDataStore: {},
  membershipDataStore: {},
};

export default compose(
  memo,
  altContainer({
    stores: {
      membershipOverviewDrawerStore: MembershipOverviewDrawerStore,
      membershipTierDataStore: MembershipTierDataStore,
      clientDataStore: ClientDataStore,
      eventTypeDataStore: EventTypeDataStore,
      creditPassDataStore: CreditPassDataStore,
      retailVendorsDataStore: RetailVendorsDataStore,
      membershipDataStore: MembershipDataStore,
    },
  })
)(MembershipOverviewDrawer);
