import * as React from 'react';
import { injectIntl } from 'react-intl';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import Tabs from 'components/Tabs/Tabs.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';

import AccountingCodeListingActions from 'shared/actions/accounting_codes/AccountingCodeListingActions';
import AddressTypeActions from 'shared/actions/AddressTypeActions.jsx';
import AccountingTab from 'customers/settings/components/AccountingTab.jsx';
import AddressTypeStore from 'shared/stores/AddressTypeStore.jsx';
import AltContainer from 'alt-container';
import BillingTab from 'customers/settings/components/BillingTab.jsx';
import BusinessInfoTab from 'customers/settings/components/_BusinessInfoTab.jsx';
import CustomerStore from 'shared/stores/CustomerStore.jsx';
import DirectDepositTab from 'customers/settings/components/_DirectDepositTab.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import LocationsTab from 'customers/settings/components/LocationsTab.jsx';
import ManagementUrlActions from 'shared/actions/ManagementUrlActions.jsx';
import ManagementUrlStore from 'shared/stores/ManagementUrlStore.jsx';
import PageHeader from 'shared/components/PageHeader.jsx';
import PayoutsActions from 'shared/actions/PayoutsActions.jsx';
import PayoutsStore from 'shared/stores/PayoutsStore.jsx';
import PayoutsTab from 'customers/settings/components/PayoutsTab.jsx';
import PolicyTermsTab from 'customers/settings/components/PolicyTermsTab.jsx';
import PreferencesTab from 'customers/settings/components/PreferencesTab.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import SubscriptionActions from 'shared/actions/SubscriptionActions.jsx';
import SubscriptionStore from 'shared/stores/SubscriptionStore.jsx';
import SettingTabsActions from 'shared/actions/SettingTabsActions';
import SettingTabsStore, { SETTING_TABS } from 'shared/stores/SettingTabsStore';
import SwitchesIcon from 'shared/components/icons/Switches.jsx';
import TaskListingActions from 'shared/actions/TaskListingActions';
import TasksTab from 'customers/settings/components/TasksTab.jsx';
import Config from 'customers/settings/components/Config.jsx';
import TaxRateListingActions from 'shared/actions/tax_rates/TaxRateListingActions';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import ServiceFeeListingActions from 'shared/actions/service_fee/ServiceFeeListingActions';
import FeeRateListingActions from 'shared/actions/fee_rates/FeeRateListingActions';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';

const styles = {
  tab: {
    textTransform: 'capitalize',
    fontWeight: 600,
  },

  tabs: {
    backgroundColor: 'var(--color-bg-gray)',
    marginTop: 18,
  },

  icon: {
    width: '24px',
    height: '24px',
    color: uhColors.darkGrey,
  },
};

const getTabLabel = (tabKey, intl) => t(`.${tabKey}`, intl, __filenamespace);
const getTabIcon = tabKey => {
  const mobileLabels = {
    [SETTING_TABS.Accounting]: <FontDownloadIcon sx={styles.icon} />,
    [SETTING_TABS.Balance]: <AccountBalanceIcon sx={styles.icon} />,
    [SETTING_TABS.BusinessInfo]: <SettingsIcon sx={styles.icon} />,
    [SETTING_TABS.DirectDeposit]: <AttachMoneyIcon sx={styles.icon} />,
    [SETTING_TABS.Billing]: <CreditCardIcon sx={styles.icon} />,
    [SETTING_TABS.Locations]: <LocationOnIcon sx={styles.icon} />,
    [SETTING_TABS.TimeTracking]: <AssignmentIcon sx={styles.icon} />,
    [SETTING_TABS.PolicyTerms]: <DescriptionIcon sx={styles.icon} />,
    [SETTING_TABS.Preferences]: <SwitchesIcon color={uhColors.darkGrey} />,
    [SETTING_TABS.Config]: <SwitchesIcon color={uhColors.darkGrey} />,
  };
  return mobileLabels[tabKey];
};

const Content = injectIntl(
  ({
    addressTypes,
    customerStore,
    locations,
    managementUrl,
    payoutsStore,
    settingTabsStore: { activeTab },
    subscription,
    intl,
  }) => {
    const tabItems = [
      {
        tabLabel: getTabLabel(SETTING_TABS.Balance, intl),
        tabIcon: getTabIcon(SETTING_TABS.Balance),
        tabContent: <PayoutsTab payoutsStore={payoutsStore} />,
        tabIndex: SETTING_TABS.Balance,
        tabVisible: currentCustomer().use_stripe,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.BusinessInfo, intl),
        tabIcon: getTabIcon(SETTING_TABS.BusinessInfo),
        tabContent: customerStore.customer.legal_entity ? (
          <BusinessInfoTab customerStore={customerStore} />
        ) : null,
        tabIndex: SETTING_TABS.BusinessInfo,
        tabVisible: true,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.DirectDeposit, intl),
        tabIcon: getTabIcon(SETTING_TABS.DirectDeposit),
        tabContent: <DirectDepositTab customerStore={customerStore} />,
        tabIndex: SETTING_TABS.DirectDeposit,
        tabVisible: currentCustomer().use_stripe,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.Billing, intl),
        tabIcon: getTabIcon(SETTING_TABS.Billing),
        tabContent: (
          <BillingTab
            subscription={subscription}
            managementUrl={managementUrl}
          />
        ),
        tabIndex: SETTING_TABS.Billing,
        tabVisible: subscription.subscription.id,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.Locations, intl),
        tabIcon: getTabIcon(SETTING_TABS.Locations),
        tabContent: (
          <LocationsTab
            locations={locations}
            addressTypes={addressTypes}
            customerStore={customerStore}
          />
        ),
        tabIndex: SETTING_TABS.Locations,
        tabVisible: true,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.Accounting, intl),
        tabIcon: getTabIcon(SETTING_TABS.Accounting),
        tabContent: <AccountingTab />,
        tabIndex: SETTING_TABS.Accounting,
        tabVisible: currentCustomer().accounting_enabled,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.TimeTracking, intl),
        tabIcon: getTabIcon(SETTING_TABS.TimeTracking),
        tabContent: <TasksTab />,
        tabIndex: SETTING_TABS.TimeTracking,
        tabVisible: currentCustomer().time_tracking_enabled,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.PolicyTerms, intl),
        tabIcon: getTabIcon(SETTING_TABS.PolicyTerms),
        tabContent: <PolicyTermsTab customerStore={customerStore} />,
        tabIndex: SETTING_TABS.PolicyTerms,
        tabVisible: true,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.Preferences, intl),
        tabIcon: getTabIcon(SETTING_TABS.Preferences),
        tabContent: <PreferencesTab customerStore={customerStore} />,
        tabIndex: SETTING_TABS.Preferences,
        tabVisible: true,
      },
      {
        tabLabel: getTabLabel(SETTING_TABS.Config, intl),
        tabIcon: getTabIcon(SETTING_TABS.Config),
        tabContent: <Config customerStore={customerStore} />,
        tabIndex: SETTING_TABS.Config,
        tabVisible: currentUser().isAdmin(),
      },
    ];

    // TODO: In mobile view warning in console because tab label using as a key and in mobile view it's an object.
    return (
      <div>
        <PageHeader title={t('.header', intl, __filenamespace)} />
        <Tabs
          variant={smallScreen() ? 'scrollable' : 'fullWidth'}
          value={activeTab}
          tabItems={tabItems}
          handleChange={(e, tab) => SettingTabsActions.changeTab([tab])}
        />
      </div>
    );
  }
);

class Settings extends React.Component {
  componentDidMount() {
    SettingTabsActions.changeTab([window.location.hash.slice(1)]);

    AddressTypeActions.list();
    LocationActions.list();

    if (currentCustomer().use_stripe) {
      PayoutsActions.fetchBalance();
    }

    if (currentCustomer().accounting_enabled) {
      AccountingCodeListingActions.list();
      ServiceFeeListingActions.list();
      EventTypeListingActions.list();
      TeamTypeListingActions.list();
      RetailCategoryListingActions.list();
      TaxRateListingActions.list();
      FeeRateListingActions.list();
    }

    ManagementUrlActions.fetch();
    SubscriptionActions.fetch();

    if (currentCustomer().time_tracking_enabled) {
      TaskListingActions.list();
    }
  }

  render() {
    return (
      <AltContainer
        stores={{
          addressTypes: AddressTypeStore,
          customerStore: CustomerStore,
          locations: LocationStore,
          managementUrl: ManagementUrlStore,
          payoutsStore: PayoutsStore,
          subscription: SubscriptionStore,
          settingTabsStore: SettingTabsStore,
        }}
      >
        <Content />
      </AltContainer>
    );
  }
}

export default Settings;
