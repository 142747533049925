import { Map, List, Set } from 'immutable';

import CreditGrantEventActions from 'contacts/index/actions/CreditGrantEventActions';
import CreditGrantsActions from 'shared/actions/CreditGrantsActions';
import CreditGrantsStore from 'shared/stores/CreditGrantsStore';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class CreditGrantEventStore extends UpperHandStore {
  constructor() {
    super();

    this.events = Map();
    this.customerUserIds = Set();

    this.bindListeners({
      fetchEvents: CreditGrantsActions.LIST_SUCCESS,
      handleSuccess: CreditGrantEventActions.LIST_SUCCESS,
      handleError: CreditGrantEventActions.LIST_ERROR,
    });
  }

  fetchEvents([_data, customerUserId]) {
    this.waitFor(CreditGrantsStore);

    if (!this.customerUserIds.has(customerUserId)) {
      return false;
    }

    this.customerUserIds = this.customerUserIds.remove(customerUserId);

    const eventIds = CreditGrantsStore.getState()
      .creditGrants.get(customerUserId, List())
      .reduce((s, grant) => s.union(grant.event_ids), Set());

    if (eventIds.isEmpty()) {
      return false;
    }

    uhApiClient.get({
      url: '/events',
      data: { ids: eventIds.toJS(), per_page: 50 },
      success: CreditGrantEventActions.listSuccess,
      error: CreditGrantEventActions.listError,
    });
  }

  handleSuccess(data) {
    this.events = this.events.withMutations(events =>
      data.events.forEach(e => {
        events.set(e.id, new EventTranslator(e).toClient());
      })
    );
  }

  handleError(...args) {
    this.notifyError('error fetching events for CreditGrants', args);
  }
}

export default alt.createStore(CreditGrantEventStore, 'CreditGrantEventStore');
