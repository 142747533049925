import React from 'react';
import { Typography, Button } from '@upperhand/playmaker';

import CouponActions from 'containers/coupons/couponView/Actions';
import { t } from 'shared/utils/LocaleUtils.js';
import EmptyStateGraphic from './EmptyStateGraphic.jsx';

function EmptyState({ intl }) {
  return (
    <div className="empty-state">
      <EmptyStateGraphic />
      <Typography className="empty-state__title" variant="h3">
        {t('.title', intl, __filenamespace)}
      </Typography>
      <Typography className="empty-state__description" variant="body3">
        {t('.description', intl, __filenamespace)}
      </Typography>
      <div className="empty-state__actions">
        <Button onClick={CouponActions.openCreateModal}>
          {t('.button', intl, __filenamespace)}
        </Button>
      </div>
    </div>
  );
}

export default EmptyState;
