export default alt.generateActions(
  'repeatScreenEnabled',
  'summaryScreenEnabled',
  'conflictScreenEnabled',
  'cartScreenEnabled',
  'openBookingScreenEnabled',
  'enableSummaryScreen',
  'disableSummaryOrConflictScreen',
  'enableBookedScreen',
  'repeatButtonHandler',
  'setBookAbleSessions'
);
