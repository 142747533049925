import POSCartStore from 'point_of_sale/stores/POSCartStore.jsx';
import POSReceiptActions from 'point_of_sale/actions/POSReceiptActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import ZebraActions from 'shared/actions/ZebraActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class POSReceiptStore extends UpperHandStore {
  constructor() {
    super();

    this.bindListeners({
      receiptRequest: POSReceiptActions.RECEIPT_REQUEST,

      fetchReceipt: POSReceiptActions.PRINT_ZPL,
      fetchReceiptSuccess: POSReceiptActions.FETCH_RECEIPT_SUCCESS,
      fetchReceiptError: POSReceiptActions.FETCH_RECEIPT_ERROR,
    });
  }

  receiptRequest(orderId) {
    if (orderId) {
      this.fetchReceipt(orderId);
    } else {
      this.waitFor(POSCartStore);
      this.fetchReceipt(POSCartStore.getState().cart.id);
    }
  }

  fetchReceipt(cartID) {
    uhApiClient.get({
      url: `/orders/${cartID}/receipt`,
      data: { fields: ['order_items'] },
      success: POSReceiptActions.fetchReceiptSuccess,
      error: POSReceiptActions.fetchReceiptError,
    });
  }

  fetchReceiptSuccess({ data }) {
    ZebraActions.printReceipt.defer(data);
  }

  fetchReceiptError() {}
}
export default alt.createStore(POSReceiptStore, 'POSReceiptStore');
