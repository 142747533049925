import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import uhTheme from '_uh_theme.jsx';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import '../styles/alert.scss';

const styles = {
  title: {
    backgroundColor: '#ff2f49',
    color: 'white',
    height: '60px',
    fontFamily: uhTheme.fontFamily,
    fontSize: '16px',
    padding: '0 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleGroup1: {
    display: 'flex',
    alignItems: 'center',
  },
  titleClearIcon: {
    position: 'relative',
    right: '-12px',
  },
  body: {
    color: uhTheme.palette.textColor,
    padding: '10px 0px',
    alignItems: 'center',
    justifyContent: 'start',
  },
  cancelButton: {
    marginRight: 8,
    color: uhTheme.palette.primary1Color,
  },
};

function Title({ onCancel }) {
  return (
    <FlexBox style={styles.title}>
      <FlexBoxCenter>
        <ReportProblemIcon style={{ marginRight: 10 }} />
        <FormattedMessage id={messageId('.alert', __filenamespace)} />
      </FlexBoxCenter>
      <IconButton onClick={onCancel} style={styles.titleClearIcon}>
        <CloseIcon />
      </IconButton>
    </FlexBox>
  );
}
function Alert({ intl, showAlert, message, actionName, onCancel, onClick }) {
  return (
    <Confirmation
      classes={{
        root: 'dialog',
        title: 'dialog-title',
        actions: 'dialog-actions',
        content: 'dialog-content',
      }}
      title={<Title intl={intl} onCancel={onCancel} />}
      open={showAlert}
      onCancel={onCancel}
      onConfirm={onClick}
      cancelButtonLabel={t('actions.cancel', intl, __filenamespace)}
      confirmButtonLabel={actionName}
      hasCheckbox={false}
    >
      <FlexBox style={styles.body}>{message}</FlexBox>
    </Confirmation>
  );
}
export default injectIntl(Alert);
