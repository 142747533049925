import AccountingCodeEditingActions from 'shared/actions/accounting_codes/AccountingCodeEditingActions';
import AccountingCodeEditingStoreInterface from 'shared/stores/accounting_codes/AccountingCodeEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  AccountingCodeEditingStoreInterface,
  'AccountingCodeEditingStore',
  {
    actions: AccountingCodeEditingActions,
    successMessage: new TranslatableMessage({
      id: '.updated',
      filename: __filenamespace,
    }),
  }
);
