import { Map, Record } from 'immutable';

import EventType from 'event_mgmt/shared/records/EventType.jsx';
import Image from 'records/Image';

const DEFAULT_DESCRIPTION =
  'More class information to follow. Let us know if you have any questions in the meantime.';

const constructionModifierForImmutableObject = object => {
  object.set('event_type', new EventType(object.get('event_type', {})));
  object.set('image', new Image(object.get('image', {})));

  const description = object.get('description', DEFAULT_DESCRIPTION);

  if (typeof description === 'string') {
    object.set('description', description);
  }
};

const constructionModifierForPlainObject = object =>
  Map(object).withMutations(constructionModifierForImmutableObject);

class EventClass extends Record({
  id: null,
  title: null,
  status: 'draft',
  description: '',
  event_type: new EventType(),
  admin_path: null,
  path: null,
  image: new Image(),
  image_dimensions: {},
}) {
  constructor(obj = {}, options = {}) {
    let objRef;

    if (obj && obj.asImmutable) {
      objRef = obj.withMutations(constructionModifierForImmutableObject);
    } else {
      objRef = constructionModifierForPlainObject(obj);
    }

    super(objRef, options);
  }
}

export default EventClass;
