const CalendarViewActions = alt.generateActions(
  'list',
  'listSuccess',
  'listError',
  'createOrUpdate',
  'createOrUpdateSuccess',
  'createOrUpdateError',
  'delete',
  'deleteSuccess',
  'deleteError',
  'duplicate',
  'changeView',
  'swap',
  'updateCurrentViewAttribute',
  'addCalendar',
  'removeCalendar',
  'discardChanges',
  'toggleShowCustomizeDrawer',
  'toggleShowAddCalendarsMenu'
);

export default CalendarViewActions;
