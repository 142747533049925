import { isLoggedIn } from 'shared/utils/UserUtils.jsx';

import { ClientSource } from 'sources';

import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import EventClientsActions from 'event_mgmt/display/actions/EventClientsActions';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class EventClientsStore extends UpperHandStore {
  constructor() {
    super();

    this.eventId = null;
    this.page = 1;

    this.bindListeners({
      listClients: EventDisplayActions.clientDisplayMounted,
      listSuccess: EventClientsActions.listSuccess,
      listError: EventClientsActions.listError,
    });
  }

  listClients({ eventId, fetchAdditionalInfo = true }) {
    if (isLoggedIn() && fetchAdditionalInfo) {
      this.eventId = eventId;
      ClientSource.list({
        params: {
          event_ids: [this.eventId],
          page: this.page,
          per_page: 50,
          fields: ['agreement_details'],
        },
        success: EventClientsActions.listSuccess,
        error: EventClientsActions.listError,
      });
    }

    if (isLoggedIn() && !fetchAdditionalInfo) {
      this.eventId = eventId;
    }
  }

  listSuccess({ page, perPage, totalCount }) {
    // TODO: This hack is preventing DDOSing the server. Caused by fix (https://upperhand.atlassian.net/browse/UH-1779) and it needs to be fixed properly.
    return;
    // eslint-disable-next-line no-unreachable
    if (page * perPage < totalCount) {
      this.page = page + 1;
      this.listClients({ eventId: this.eventId });
    }
  }

  listError(...args) {
    this.notifyError('Error while listing event clients', args);
  }
}

export default alt.createStore(EventClientsStore, 'EventClientsStore');
