import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import MonthlyRecurringRevenueHeader from './MonthlyRecurringRevenueHeader.jsx';
// import MonthlyRecurringRevenueAnalytics from './MonthlyRecurringRevenueAnalytics.jsx';
import MonthlyRecurringRevenueFilters from './MonthlyRecurringRevenueFilters.jsx';
import MonthlyRecurringRevenueByPeriod from './MonthlyRecurringRevenueByPeriod.jsx';
import MonthlyRecurringRevenueMetrics from './MonthlyRecurringRevenueMetrics.jsx';
import MonthlyRecurringRevenueDetails from './MonthlyRecurringRevenueDetails.jsx';
import MonthlyRecurringRevenueReportActions from '../actions/MonthlyRecurringRevenueReportActions.jsx';

const ReportContainer = styled.div`
  background-color: var(--color-bg-gray);
  padding: 30px;
`;

(() => createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`)();

// Analytics removed until after initial version.
// Bring back when properly updated to new API.
class MonthlyRecurringRevenueReport extends React.Component {
  componentDidMount() {
    MonthlyRecurringRevenueReportActions.mounted();
  }

  render() {
    return (
      <ReportContainer>
        <MonthlyRecurringRevenueHeader />
        {/* <MonthlyRecurringRevenueAnalytics /> */}
        <MonthlyRecurringRevenueFilters />
        <MonthlyRecurringRevenueByPeriod />
        <MonthlyRecurringRevenueMetrics />
        <MonthlyRecurringRevenueDetails />
      </ReportContainer>
    );
  }
}

export default MonthlyRecurringRevenueReport;
