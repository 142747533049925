import FeeRate from 'shared/records/FeeRate';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = feeRate =>
  feeRate.id ? `service_fees/${feeRate.id}` : 'service_fees';

export default class TaxRateEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();

    this.feeRate = null;
    this.isSaving = false;

    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      updateFeeRate: this.actions.UPDATED,

      beginEdit: this.actions.BEGIN,
      cancelEdit: this.actions.CANCEL,

      createOrUpdate: this.actions.SAVE,
      createOrUpdateSuccess: this.actions.SAVE_SUCCESS,
      createOrUpdateError: this.actions.SAVE_ERROR,
    });
  }

  updateFeeRate(updatedFields) {
    if (!this.feeRate) {
      return false;
    }

    this.feeRate = this.feeRate.merge(updatedFields);
  }

  beginEdit(feeRate) {
    this.feeRate = feeRate || new FeeRate();
  }

  cancelEdit() {
    this.feeRate = null;
  }

  createOrUpdate() {
    if (this.isSaving) {
      return false;
    }

    this.feeRate = this.feeRate.validate();

    if (this.feeRate.isValid()) {
      this.isSaving = true;

      const saveValue = {
        url: url(this?.feeRate),
        data: JSON.stringify({ attributes: this.feeRate.toServer() }),
        success: this.actions.saveSuccess,
        error: this.actions.saveError,
      };

      if (this.feeRate?.id) {
        uhApiClient.put(saveValue);
      } else {
        uhApiClient.post(saveValue);
      }
    }
  }

  createOrUpdateSuccess(_data) {
    this.feeRate = null;
    this.isSaving = false;
    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError() {
    this.isSaving = false;
    this.notifyError('error updating accountingCode', arguments);
  }
}
