import * as React from 'react';
import Stack from '@mui/material/Stack';

import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { SetPassword } from 'user_management/shared/components/UserProfileFields.jsx';

import { handleChange } from 'shared/helpers/ChangeHandler.jsx';

import ResetPasswordActions from '../Actions';

function DesktopResetRequest({
  password,
  password_confirmation: passwordConfirmation,
  requestStatus,
  changePassword,
  validPasswords,
}) {
  return (
    <Stack direction="column" spacing={2} sx={{ width: '320px' }}>
      <SetPassword
        userProfile={{
          password,
          password_confirmation: passwordConfirmation,
        }}
        onChange={handleChange({ actions: ResetPasswordActions })}
      />
      <StateChangingButton
        secondary
        label="Change Password"
        labelInProgress="Changing password..."
        inProgress={requestStatus === 'sending'}
        disabled={requestStatus === 'sending' || !validPasswords()}
        onClick={changePassword}
        type="submit"
      />
    </Stack>
  );
}

export default DesktopResetRequest;
