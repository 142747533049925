import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Report({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 20 19" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-631.000000, -856.000000)" fill={color}>
          <g transform="translate(501.000000, 832.000000)">
            <path
              d="M149.09,27.636 L147.273,27.636 C146.77,27.636 146.363,28.043 146.363,28.545 L146.363,
               41.273 C146.363,41.775 146.77,42.182 147.273,42.182 L149.09,42.182 C149.593,42.182
               150,41.775 150,41.273 L150,28.545 C150,28.043 149.593,27.636 149.09,27.636 M143.636,
               34.909 L141.818,34.909 C141.316,34.909 140.908,35.316 140.908,35.818 L140.908,41.273
               C140.908,41.775 141.316,42.182 141.818,42.182 L143.636,42.182 C144.138,42.182
               144.545,41.775 144.545,41.273 L144.545,35.818 C144.545,35.316 144.138,34.909 143.636,
               34.909 M138.182,24 L136.363,24 C135.861,24 135.454,24.407 135.454,24.909 L135.454,
               41.273 C135.454,41.775 135.861,42.182 136.363,42.182 L138.182,42.182 C138.684,42.182
               139.09,41.775 139.09,41.273 L139.09,24.909 C139.09,24.407 138.684,24 138.182,24
               M132.727,31.273 L130.908,31.273 C130.406,31.273 130,31.679 130,32.182 L130,41.273
               C130,41.775 130.406,42.182 130.908,42.182 L132.727,42.182 C133.229,42.182 133.636,
               41.775 133.636,41.273 L133.636,32.182 C133.636,31.679 133.229,31.273 132.727,31.273"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

Report.defaultProps = {
  color: '#9A9EAD',
};

export default Report;
