import * as React from 'react';
import { injectIntl } from 'react-intl';

import RetailCategoryInlineEditor from 'shared/components/RetailCategoryInlineEditor.jsx';
import RetailCategoryList from 'customers/settings/components/accounting_tab/RetailCategoryList.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhFormElement } from 'shared/styles/uhStyles.jsx';

const RetailCategorySelector = injectIntl(
  ({
    accountingCode,
    retailCategories,
    onCreateOrUpdateSuccess,
    onRetailCategorySelect,
    onRetailCategoryRemoved,
    intl,
  }) => (
    <div>
      <RetailCategoryInlineEditor
        errorText={accountingCode.errors.getErrors('retail_category_ids', intl)}
        floatingLabelFixed
        floatingLabelStyle={uhFormElement.floatingLabel}
        floatingLabelText={t(
          '.assign_retail_categories',
          intl,
          __filenamespace
        )}
        fullWidth
        onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
        onRetailCategorySelect={onRetailCategorySelect}
        retailCategories={retailCategories}
      />

      <RetailCategoryList
        accountingCode={accountingCode}
        onRemove={onRetailCategoryRemoved}
      />
    </div>
  )
);

export default RetailCategorySelector;
