import React from 'react';

const EmptyStateGraphic = () => (
  <svg
    width="268"
    height="147"
    viewBox="0 0 268 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.65">
      <path
        d="M225.88 139.525H33.1931C32.5369 139.525 32 138.988 32 138.331C32 137.675 32.5369 137.138 33.1931 137.138H225.88C226.537 137.138 227.073 137.675 227.073 138.331C227.073 138.988 226.543 139.525 225.88 139.525Z"
        fill="#8E8EA0"
      />
      <path
        d="M241.618 139.525H234.459C233.803 139.525 233.266 138.988 233.266 138.331C233.266 137.675 233.803 137.138 234.459 137.138H241.618C242.274 137.138 242.811 137.675 242.811 138.331C242.811 138.988 242.274 139.525 241.618 139.525Z"
        fill="#8E8EA0"
      />
      <path
        d="M49.3379 108.973H42.1911C41.536 108.973 41 108.436 41 107.779C41 107.122 41.536 106.584 42.1911 106.584H49.3379C49.993 106.584 50.529 107.122 50.529 107.779C50.529 108.436 49.993 108.973 49.3379 108.973Z"
        fill="#8E8EA0"
      />
      <path
        d="M45.7645 112.557C45.1094 112.557 44.5734 112.02 44.5734 111.363V104.195C44.5734 103.538 45.1094 103 45.7645 103C46.4196 103 46.9556 103.538 46.9556 104.195V111.363C46.9556 112.02 46.4196 112.557 45.7645 112.557Z"
        fill="#8E8EA0"
      />
      <path
        d="M207.852 19.3358H200.705C200.05 19.3358 199.514 18.7982 199.514 18.1411C199.514 17.4841 200.05 16.9465 200.705 16.9465H207.852C208.507 16.9465 209.043 17.4841 209.043 18.1411C209.043 18.7982 208.507 19.3358 207.852 19.3358Z"
        fill="#8E8EA0"
      />
      <path
        d="M204.279 22.9197C203.623 22.9197 203.087 22.3821 203.087 21.7251V14.5572C203.087 13.9001 203.623 13.3625 204.279 13.3625C204.934 13.3625 205.47 13.9001 205.47 14.5572V21.7251C205.47 22.3821 204.934 22.9197 204.279 22.9197Z"
        fill="#8E8EA0"
      />
      <path
        d="M254.765 54.1813H251.191C250.536 54.1813 250 53.6437 250 52.9866C250 52.3296 250.536 51.792 251.191 51.792H254.765C255.42 51.792 255.956 52.3296 255.956 52.9866C255.956 53.6437 255.42 54.1813 254.765 54.1813Z"
        fill="#8E8EA0"
      />
      <path
        d="M252.978 55.9733C252.323 55.9733 251.787 55.4357 251.787 54.7786V51.1947C251.787 50.5376 252.323 50 252.978 50C253.633 50 254.169 50.5376 254.169 51.1947V54.7786C254.169 55.4357 253.633 55.9733 252.978 55.9733Z"
        fill="#8E8EA0"
      />
      <path
        d="M63.7645 56.1813H60.1911C59.536 56.1813 59 55.6437 59 54.9866C59 54.3296 59.536 53.792 60.1911 53.792H63.7645C64.4196 53.792 64.9556 54.3296 64.9556 54.9866C64.9556 55.6437 64.4196 56.1813 63.7645 56.1813Z"
        fill="#8E8EA0"
      />
      <path
        d="M61.9778 57.9732C61.3226 57.9732 60.7866 57.4357 60.7866 56.7786V53.1946C60.7866 52.5376 61.3226 52 61.9778 52C62.6329 52 63.1689 52.5376 63.1689 53.1946V56.7786C63.1689 57.4357 62.6329 57.9732 61.9778 57.9732Z"
        fill="#8E8EA0"
      />
      <path
        d="M211.239 70.4576C210.935 70.4576 210.631 70.3381 210.399 70.1052L205.348 65.0399C204.884 64.5739 204.884 63.8153 205.348 63.3494C205.813 62.8835 206.569 62.8835 207.034 63.3494L212.084 68.4147C212.549 68.8806 212.549 69.6393 212.084 70.1052C211.852 70.3441 211.542 70.4576 211.239 70.4576Z"
        fill="#8E8EA0"
      />
      <path
        d="M206.188 70.4576C205.884 70.4576 205.581 70.3381 205.348 70.1052C204.884 69.6393 204.884 68.8806 205.348 68.4147L210.399 63.3494C210.863 62.8835 211.62 62.8835 212.084 63.3494C212.549 63.8153 212.549 64.5739 212.084 65.0399L207.034 70.1052C206.796 70.3441 206.492 70.4576 206.188 70.4576Z"
        fill="#8E8EA0"
      />
      <path
        d="M17.2386 74.4576C16.9348 74.4576 16.6311 74.3381 16.3988 74.1052L11.3484 69.0399C10.8839 68.5739 10.8839 67.8153 11.3484 67.3494C11.813 66.8835 12.5693 66.8835 13.0339 67.3494L18.0843 72.4147C18.5488 72.8806 18.5488 73.6393 18.0843 74.1052C17.852 74.3441 17.5423 74.4576 17.2386 74.4576Z"
        fill="#8E8EA0"
      />
      <path
        d="M12.1882 74.4576C11.8844 74.4576 11.5807 74.3381 11.3484 74.1052C10.8839 73.6393 10.8839 72.8806 11.3484 72.4147L16.3988 67.3494C16.8634 66.8835 17.6197 66.8835 18.0843 67.3494C18.5488 67.8153 18.5488 68.5739 18.0843 69.0399L13.0339 74.1052C12.7956 74.3441 12.4919 74.4576 12.1882 74.4576Z"
        fill="#8E8EA0"
      />
      <path
        d="M103.565 20.4576C103.343 20.4576 103.121 20.3702 102.951 20.1997L99.255 16.4928C98.915 16.1518 98.915 15.5967 99.255 15.2557C99.5949 14.9148 100.148 14.9148 100.488 15.2557L104.184 18.9626C104.524 19.3036 104.524 19.8587 104.184 20.1997C104.014 20.3745 103.788 20.4576 103.565 20.4576Z"
        fill="#8E8EA0"
      />
      <path
        d="M99.8695 20.4576C99.6472 20.4576 99.4249 20.3702 99.255 20.1997C98.915 19.8587 98.915 19.3036 99.255 18.9626L102.951 15.2557C103.291 14.9148 103.844 14.9148 104.184 15.2557C104.524 15.5967 104.524 16.1518 104.184 16.4928L100.488 20.1997C100.314 20.3745 100.092 20.4576 99.8695 20.4576Z"
        fill="#8E8EA0"
      />
      <path
        d="M170.713 36.9249C170.41 36.9249 170.106 36.8055 169.874 36.5725L167.348 34.0399C166.884 33.5739 166.884 32.8153 167.348 32.3494C167.813 31.8835 168.569 31.8835 169.034 32.3494L171.559 34.8821C172.024 35.348 172.024 36.1066 171.559 36.5725C171.321 36.8114 171.017 36.9249 170.713 36.9249Z"
        fill="#8E8EA0"
      />
      <path
        d="M168.188 36.9249C167.884 36.9249 167.581 36.8055 167.348 36.5725C166.884 36.1066 166.884 35.348 167.348 34.8821L169.874 32.3494C170.338 31.8835 171.094 31.8835 171.559 32.3494C172.024 32.8153 172.024 33.5739 171.559 34.0399L169.034 36.5725C168.796 36.8114 168.492 36.9249 168.188 36.9249Z"
        fill="#8E8EA0"
      />
      <path
        d="M51.1689 24.3625C48.8701 24.3625 47 22.4869 47 20.1813C47 17.8756 48.8701 16 51.1689 16C53.4678 16 55.3379 17.8756 55.3379 20.1813C55.3379 22.4869 53.4678 24.3625 51.1689 24.3625ZM51.1689 18.3893C50.1863 18.3893 49.3823 19.1957 49.3823 20.1813C49.3823 21.1669 50.1863 21.9732 51.1689 21.9732C52.1516 21.9732 52.9556 21.1669 52.9556 20.1813C52.9556 19.1957 52.1516 18.3893 51.1689 18.3893Z"
        fill="#8E8EA0"
      />
      <path
        d="M227.169 115.363C224.87 115.363 223 113.487 223 111.181C223 108.876 224.87 107 227.169 107C229.468 107 231.338 108.876 231.338 111.181C231.338 113.487 229.468 115.363 227.169 115.363ZM227.169 109.389C226.186 109.389 225.382 110.196 225.382 111.181C225.382 112.167 226.186 112.973 227.169 112.973C228.152 112.973 228.956 112.167 228.956 111.181C228.956 110.196 228.152 109.389 227.169 109.389Z"
        fill="#8E8EA0"
      />
      <path
        d="M147.104 8.36254C144.805 8.36254 142.935 6.48694 142.935 4.18127C142.935 1.8756 144.805 0 147.104 0C149.403 0 151.273 1.8756 151.273 4.18127C151.273 6.48694 149.403 8.36254 147.104 8.36254ZM147.104 2.3893C146.122 2.3893 145.318 3.19569 145.318 4.18127C145.318 5.16686 146.122 5.97325 147.104 5.97325C148.087 5.97325 148.891 5.16686 148.891 4.18127C148.891 3.19569 148.087 2.3893 147.104 2.3893Z"
        fill="#8E8EA0"
      />
      <path
        d="M174.512 27.6984L174.506 25.3091H174.512V27.6984Z"
        fill="#8E8EA0"
      />
      <path
        d="M225.731 50.3967L225.725 48.0074H225.731V50.3967Z"
        fill="#8E8EA0"
      />
      <path
        d="M249.553 42.2555L249.547 39.8662H249.553V42.2555Z"
        fill="#8E8EA0"
      />
      <path
        d="M23.8347 58.7324L23.8287 56.3431H23.8347V58.7324Z"
        fill="#8E8EA0"
      />
      <path
        d="M63.1419 16.9464L63.136 14.5571H63.1419V16.9464Z"
        fill="#8E8EA0"
      />
      <path
        d="M118.529 53.6047L118.523 51.2154H118.529V53.6047Z"
        fill="#8E8EA0"
      />
      <path
        d="M183.888 81.0607L138.232 51.2722C136.189 49.9389 133.629 49.4025 131.059 49.9431L101.719 56.1137C96.5684 57.1971 93.2436 62.2929 94.3269 67.4439L100.498 96.7833C101.039 99.3573 102.563 101.482 104.654 102.847L150.31 132.635C152.354 133.968 154.913 134.505 157.484 133.964C160.053 133.424 162.182 131.902 163.509 129.811C163.51 129.809 163.511 129.807 163.512 129.805L186.666 94.3176C186.668 94.3157 186.669 94.3137 186.67 94.3117C188.05 92.2555 188.585 89.6932 188.045 87.124C187.503 84.5466 185.925 82.39 183.888 81.0607Z"
        fill="var(--color-primary)"
        stroke="#8E8EA0"
        strokeWidth="2"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="94"
        y="48"
        width="93"
        height="83"
      >
        <path
          d="M182.824 79.6733L137.168 49.8847C135.342 48.6932 133.052 48.2119 130.747 48.6967L101.407 54.8673C96.7969 55.837 93.818 60.4026 94.7877 65.0131L100.958 94.3525C101.443 96.6578 102.806 98.5595 104.683 99.7842L150.339 129.573C152.165 130.764 154.455 131.246 156.76 130.761C159.065 130.276 160.967 128.913 162.15 127.045L185.318 91.5348C186.552 89.6997 187.033 87.4101 186.548 85.1049C186.064 82.7996 184.65 80.8648 182.824 79.6733ZM112.102 74.5018C108.623 75.2335 105.339 77.3452 107.246 82.6282C109.921 88.3182 113.375 84.1811 116.854 83.4495C120.333 82.7178 126.08 78.6671 123.302 73.5673C124.034 77.0461 115.581 73.7702 112.102 74.5018ZM168.724 98.7888L154.591 120.45L132.93 106.317C130.639 104.786 128.918 102.434 128.31 99.5418C127.094 93.7578 127.584 109.613 133.368 108.397C136.26 107.789 153.767 128.808 154.591 120.45L155.272 123.684L181.002 88.4599C182.491 86.1771 154.65 83.0603 157.542 82.452C158.42 94.6018 169.007 84.9426 170.224 90.7266C170.832 93.6187 170.214 96.5061 168.724 98.7888Z"
          fill="var(--color-primary)"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M138.969 59.5215L138.952 59.5185L138.936 59.516L118.206 56.3672L118.048 56.3433L117.891 56.3695L108.053 58.0092C107.85 57.9711 107.648 57.9327 107.447 57.8946C106.587 57.7313 105.752 57.5728 104.985 57.4664C103.986 57.328 103.004 57.2616 102.108 57.4076C101.184 57.5581 100.331 57.9385 99.6526 58.694C98.9911 59.4306 98.5739 60.4377 98.3391 61.7015L92.8616 91.1782C92.3811 93.7642 92.9804 96.3098 94.3937 98.3682L125.25 143.309C126.631 145.321 128.793 146.792 131.375 147.272C133.956 147.752 136.505 147.156 138.529 145.728C138.53 145.727 138.532 145.726 138.534 145.724L173.466 121.741C173.468 121.739 173.47 121.738 173.472 121.737C175.531 120.361 177.003 118.197 177.482 115.615C177.963 113.026 177.327 110.431 175.95 108.425L145.094 63.4843C143.713 61.4727 141.551 60.0013 138.969 59.5215ZM135.682 103.07L135.687 103.078L135.692 103.085L138.202 106.688L138.77 107.503L139.589 106.941L143.234 104.438C145.269 103.041 147.873 102.418 150.51 102.908C155.778 103.887 159.26 108.957 158.281 114.226C157.791 116.862 156.274 119.069 154.239 120.466L133.741 134.54L119.674 114.051C119.673 114.049 119.672 114.048 119.671 114.046C118.273 111.96 117.649 109.395 118.137 106.766C119.116 101.498 124.187 98.0163 129.455 98.9953C132.091 99.4851 134.294 101 135.682 103.07ZM111.896 76.2726C108.944 75.724 107.005 72.9004 107.554 69.9483C108.102 66.9962 110.926 65.0575 113.878 65.606C116.83 66.1546 118.769 68.9783 118.22 71.9304C117.672 74.8825 114.848 76.8212 111.896 76.2726Z"
          fill="var(--color-old-gray)"
          stroke="var(--color-old-gray)"
          strokeWidth="2"
        />
      </g>
      <path
        d="M123.444 100.845L123.449 100.853L123.455 100.86L125.965 104.463L126.533 105.278L127.352 104.716L130.997 102.213C133.032 100.816 135.636 100.193 138.273 100.683C143.541 101.662 147.022 106.732 146.043 112.001C145.554 114.637 144.037 116.844 142.002 118.241L121.504 132.315L107.437 111.826C107.436 111.824 107.435 111.823 107.434 111.822C106.036 109.735 105.412 107.17 105.9 104.541C106.879 99.2728 111.95 95.7913 117.218 96.7703C119.854 97.2601 122.056 98.775 123.444 100.845ZM163.713 106.2L132.857 61.2593C131.475 59.2478 129.314 57.7764 126.731 57.2965L97.2545 51.8191C92.0795 50.8574 87.0634 54.3014 86.1017 59.4765L80.6243 88.9532C80.1438 91.5392 80.7431 94.0848 82.1564 96.1432L113.013 141.084C114.394 143.096 116.556 144.567 119.138 145.047C121.719 145.527 124.268 144.931 126.291 143.503C126.293 143.502 126.295 143.501 126.297 143.499L161.229 119.516C161.231 119.514 161.233 119.513 161.235 119.512C163.294 118.137 164.765 115.972 165.245 113.39C165.726 110.801 165.09 108.206 163.713 106.2ZM99.6585 74.0476C96.7064 73.4991 94.7677 70.6754 95.3163 67.7233C95.8648 64.7712 98.6885 62.8325 101.641 63.3811C104.593 63.9296 106.531 66.7533 105.983 69.7054C105.434 72.6575 102.611 74.5962 99.6585 74.0476Z"
        fill="white"
        stroke="#8E8EA0"
        strokeWidth="2"
      />
      <path
        d="M134.193 64.9914L84.0018 98.5468"
        stroke="#8E8EA0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
    </g>
  </svg>
);

export default EmptyStateGraphic;
