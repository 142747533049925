import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';

import { Box, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import RosterTransferAutocomplete from 'containers/events/admin/clients/components/RosterTransferDrawer/_TransferAutoComplete.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';

import useIsMobile from 'shared/utils/hooks/useIsMobile';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { STANDARD_DRAWER_WIDTH } from 'shared/utils/DOMUtils';
import RosterTransferDrawerActions from './Actions';

function Header({ onClose }) {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.darkGrey,
        color: theme.palette.white,
        padding: theme.spacing(0, 3.75),
        alignItems: 'center',
        minHeight: 56,
      })}
    >
      <Typography variant="subtitle1">
        <FormattedMessage id={messageId('.transfer_client', __filenamespace)} />
      </Typography>
      <IconButton onClick={onClose} sx={{ padding: 0 }}>
        <CloseIcon sx={{ color: theme => theme.palette.white }} />
      </IconButton>
    </Box>
  );
}

function Content({ team, intl, rosterTransferDrawerStore }) {
  const eventName = team ? 'team' : 'event';
  return (
    <Box sx={{ padding: theme => theme.spacing(0, 4), mt: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          whiteSpace: 'nowrap',
        }}
      >
        <Box>
          <Typography variant="subtitle2">
            <FormattedMessage
              id={messageId('.transfer_message', __filenamespace)}
              values={{
                eventName,
              }}
            />
          </Typography>
          <RosterTransferAutocomplete
            rosterTransferDrawerStore={rosterTransferDrawerStore}
            name="Roster_Transfer"
            onChange={(e, val) =>
              RosterTransferDrawerActions.setCurrentSelectedEvent(val)
            }
            placeholder={t('.search_placeholder', intl, __filenamespace, {
              eventName,
            })}
          />
        </Box>
      </Box>
    </Box>
  );
}

function Footer({ loading, intl, transferHandler, selectedEvent, isMobile }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mt: 2.5,
        padding: theme =>
          isMobile ? theme.spacing(0, 2) : theme.spacing(0, 4),
      }}
    >
      <StateChangingButton
        inProgress={loading}
        disabled={!selectedEvent}
        label={t('.save', intl, __filenamespace)}
        onClick={transferHandler}
      />
    </Box>
  );
}

const RosterTransferDrawer = injectIntl(
  ({
    open,
    drawerDismissed,
    intl,
    transferHandler,
    rosterTransferDrawerStore,
  }) => {
    const isMobile = useIsMobile();
    const { team, loading, selectedEvent } = rosterTransferDrawerStore;

    return (
      <Drawer
        disableEnforceFocus
        PaperProps={{
          sx: { width: isMobile ? '90vw' : STANDARD_DRAWER_WIDTH },
        }}
        anchor="right"
        onClose={drawerDismissed}
        open={open}
      >
        <Header onClose={drawerDismissed} />
        <Content
          intl={intl}
          team={team}
          rosterTransferDrawerStore={rosterTransferDrawerStore}
        />
        <Footer
          intl={intl}
          loading={loading}
          transferHandler={transferHandler}
          selectedEvent={selectedEvent}
          isMobile={isMobile}
        />
      </Drawer>
    );
  }
);

export default RosterTransferDrawer;
