import * as React from 'react';
import debounce from 'lodash.debounce';
import { injectIntl } from 'react-intl';

import { TextField } from '@upperhand/playmaker';

import CalendarStore from 'calendar/stores/CalendarStore.jsx';
import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  Search: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0',
    fontSize: '15px',
  },
  SearchIcon: {
    width: '20px',
    height: '20px',
  },
  SearchInput: {
    padding: '0 16px 0 0',
    fontSize: 'inherit',
  },
  SearchHint: {
    fontSize: 'inherit',
  },
  SearchTextField: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    margin: '0 0 0 8px',
    fontSize: 'inherit',
  },
  MobileSearchPopover: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
};

const hintText = (view, intl) => {
  switch (view) {
    case CALENDAR_TYPE.DAY: {
      const key = currentCustomer().resources_enabled
        ? '.search_staff_resources'
        : '.search_staff';
      return t(key, intl, __filenamespace);
    }
    case CALENDAR_TYPE.WEEK:
      return t('.search_events', intl, __filenamespace);
    default:
      return t('.search', intl, __filenamespace);
  }
};

class Search extends React.Component {
  state = {
    searchValue: CalendarStore.getState().fuzzySearch,
  };

  componentDidUpdate(prevProps) {
    const { view } = this.props;
    const { view: prevView } = prevProps;

    if (view !== prevView) {
      this.setState({ searchValue: '' });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateStoreSearchValue = (newValue = '') => {
    // requestAnimationFrame for UI performance when rapidly dispatching
    window.requestAnimationFrame(() => {
      CalendarActions.updateFuzzySearch(newValue);
    });
  };

  handleFuzzySearchOnChange = (_, newValue) => {
    this.setState(
      () => ({ searchValue: newValue }),
      debounce(
        () => {
          this.updateStoreSearchValue(newValue);
        },
        60,
        {
          leading: true,
          trailing: true,
        }
      )
    );
  };

  // eslint-disable-next-line class-methods-use-this
  handleFuzzySearchOnClick = e => {
    e.stopPropagation();
  };

  handleCancelTap = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ searchValue: '' }, this.updateStoreSearchValue);
  };

  render() {
    const { view, intl, ...rest } = this.props;
    const { searchValue } = this.state;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...rest} style={styles.Search}>
        <TextField
          icon="search"
          value={searchValue}
          placeholder={hintText(view, intl)}
          onChange={this.handleFuzzySearchOnChange}
          onClick={this.handleFuzzySearchOnClick}
          outline={false}
          showClearLength={1}
          onClear={this.handleCancelTap}
        />
      </div>
    );
  }
}

export default injectIntl(Search);
