/* eslint-disable no-unused-vars */
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import moment from 'moment-timezone';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import EditMembershipExpireActions from './EditMembershipExpireActions.js';

class EditMembershipExpireModalStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      closeModal: EditMembershipExpireActions.closeModal,
      openModal: EditMembershipExpireActions.openModal,
      setExpireDate: EditMembershipExpireActions.setExpireDate,
      addNewDate: EditMembershipExpireActions.addNewDate,
      updateDateSuccess: EditMembershipExpireActions.updateDateSuccess,
      updateDateError: EditMembershipExpireActions.updateDateError,
    });
  }

  reset() {
    this.isLoading = false;
    this.isOpen = false;
    this.newMembershipExpiryDate = new Date();
    this.oldMembershipExpiryDate = null;
    this.membershipId = null;
  }

  closeModal() {
    this.isOpen = false;
  }

  openModal(subscription) {
    const membershipsExpiresAt = subscription.get('commitment_ends_at');
    const membershipId = subscription.get('id');
    this.oldMembershipExpiryDate = membershipsExpiresAt;
    this.membershipId = membershipId;
    this.isOpen = true;
    const flag = moment(membershipsExpiresAt).isAfter();
    if (!flag) {
      this.isOpen = false;
      MessageWindowActions.addMessage.defer(
        'Membership subscription not editable.'
      );
    }
  }

  setExpireDate(expireDate) {
    const day = this.oldMembershipExpiryDate.getDate();
    this.newMembershipExpiryDate = expireDate;
    this.newMembershipExpiryDate.setDate(day);
  }

  validate() {
    const oldDay = this.oldMembershipExpiryDate.getDate();
    const newDay = this.newMembershipExpiryDate.getDate();
    return oldDay !== newDay;
  }

  addNewDate() {
    this.isLoading = true;
    const flag = this.validate();
    const params = {
      commitment_ends_at: this.newMembershipExpiryDate,
    };
    if (flag) {
      this.isLoading = false;
      MessageWindowActions.addMessage.defer(
        'Can not update date of month need to be same.'
      );
    }

    if (!flag) {
      uhApiClient.put({
        url: `membership_subscriptions/${this.membershipId}`,
        data: JSON.stringify({ attributes: params }),
        success: EditMembershipExpireActions.updateDateSuccess,
        error: EditMembershipExpireActions.updateDateError,
      });
    }
  }

  updateDateSuccess(data) {
    this.isLoading = false;
    this.closeModal();
  }

  updateDateError(...args) {
    this.isLoading = false;
    this.notifyError('error updating expiry date', args);
  }
}

export default alt.createStore(
  EditMembershipExpireModalStore,
  'EditMembershipExpireModalStore'
);
