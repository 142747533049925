import { List, Map } from 'immutable';
import LocationActions from 'shared/actions/LocationActions.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import Resource from 'shared/records/Resource.js';
import ResourceDeletionActions from 'resources/actions/ResourceDeletionActions.js';
import ResourceActions from 'resources/actions/ResourceActions.js';
import ResourceListingActions from 'resources/actions/ResourceListingActions.js';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class ResourceListingStore extends UpperHandStore {
  constructor() {
    super();

    this.resources = List();
    this.resourcesMap = Map();

    this.searchString = '';
    this.page = 1;
    this.perPage = 15;
    this.totalCount = 0;

    this.isLoading = false;

    this.bindListeners({
      list: [
        ResourceListingActions.LIST,
        ResourceActions.CREATE_OR_UPDATE_SUCCESS,
        ResourceDeletionActions.DELETE_SUCCESS,
      ],

      listSuccess: ResourceListingActions.LIST_SUCCESS,
      listError: ResourceListingActions.LIST_ERROR,

      updateSearchString: ResourceListingActions.SEARCH_STRING_UPDATED,
      clearSearchString: ResourceListingActions.CLEAR_SEARCH_CLICKED,
      updatePaginationOptions:
        ResourceListingActions.PAGINATION_OPTIONS_UPDATED,

      setResourceStaff: StaffActions.LIST_SUCCESS,
      setResourceLocations: LocationActions.LIST_SUCCESS,
    });
  }

  list() {
    this.isLoading = true;

    return uhApiClient.get({
      url: 'resources',
      data: {
        page: this.page,
        per_page: this.perPage,
        name: this.searchString,
      },
      success: ResourceListingActions.listSuccess,
      error: ResourceListingActions.listError,
    });
  }

  listSuccess({ resources, page, per_page: perPage, total_count: totalCount }) {
    this.resources = List(resources.map(r => new Resource(r)));
    this.resourcesMap = this.resources.groupBy(r => r.id).map(r => r.first());

    if (this.page === 1 || this.resources.size > 0) {
      this.page = page;
      this.perPage = perPage;
      this.totalCount = totalCount;

      this.isLoading = false;

      this.setResourceStaff();
      this.setResourceLocations();
    } else {
      // We fetched a page other than the first page and found no results. Reset
      // to page 1.
      this.page = 1;
      this.perPage = perPage;
      this.totalCount = totalCount;

      this.list();
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing resources', args);
  }

  updateSearchString(string) {
    this.searchString = string;

    this.list();
  }

  clearSearchString() {
    this.updateSearchString('');
  }

  updatePaginationOptions([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.list();
  }

  setResourceStaff() {
    this.waitFor(StaffStore);

    const staff = StaffStore.getState().allStaff;

    if (!staff.isEmpty()) {
      this.resources = this.resources.map(r => {
        if (r.preferring_staff_ids.isEmpty()) {
          return r;
        }
        return r.set(
          'preferring_staff',
          staff.filter(s => r.preferring_staff_ids.has(s.id))
        );
      });
    }
  }

  setResourceLocations() {
    this.waitFor(LocationStore);

    const { findById } = LocationStore.getState();

    this.resources = this.resources.map(r => {
      if (r.location_id) {
        return r.set('location', findById(r.location_id));
      }
      return r;
    });
  }
}

export default alt.createStore(ResourceListingStore, 'ResourceListingStore');
