import * as React from 'react';
import Paper from '@mui/material/Paper';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';

function Instructions({ text }) {
  if (text === '') {
    return null;
  }
  return <p>{text}</p>;
}

function Form({ header, children, instructions = '' }) {
  return (
    <FlexBox style={Styles.form}>
      <h2 style={Styles.header2}>{header}</h2>
      <Paper>
        <div style={Styles.instructions}>
          <Instructions text={instructions} />
        </div>
        <div style={Styles.formContainer}>{children}</div>
      </Paper>
    </FlexBox>
  );
}

export default Form;
