import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Grid, TextField, Checkbox, Drawer } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { TextField as MUITextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CloseIcon from '@mui/icons-material/Close';

import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils';

import { uhColors, uhContrast } from 'shared/styles/uhStyles.jsx';

import NewMessageDrawerActions from './Actions';
import NewMessageDrawerStore from './Store';

import './styles.scss';

const styles = {
  dateTimeInput: {
    width: '150px',
  },

  contentContainer: {
    fontSize: 15,
    padding: '30px',
  },

  groupName: {
    fontSize: 22,
  },

  header: {
    ...uhContrast.lightOnDark,
    alignItems: 'center',
    fontSize: 15,
    paddingLeft: 30,
    minHeight: 56,
  },

  label: {
    fontSize: 18,
  },

  zIndex: {
    zIndex: 1301,
  },
};

function NewMessageDrawer({
  intl,
  newMessageDrawerStore: { open, message, valid },
}) {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);

  if (!open) {
    return null;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <Drawer
      open={open}
      classes={{
        wrapper: 'new-message-drawer',
        header: 'new-message-drawer__title',
        actions: 'new-message-drawer__actions',
        content: 'new-message-drawer__content',
      }}
      title={
        <FlexBoxJustify style={styles.header}>
          <FormattedMessage
            id={messageId('.create_message', __filenamespace)}
          />
          <IconButton onClick={() => NewMessageDrawerActions.toggleDrawer({})}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </FlexBoxJustify>
      }
      onClose={() => NewMessageDrawerActions.toggleDrawer({})}
      content={
        <div style={styles.contentContainer}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs>
              <TextField
                fullWidth
                label={t('.subject', intl, __filenamespace)}
                placeholder={t('.subject', intl, __filenamespace)}
                onChange={(_, value) =>
                  NewMessageDrawerActions.updateAndValidateMessage({
                    key: 'subject',
                    value,
                  })
                }
                value={message.subject}
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label={t('.message', intl, __filenamespace)}
                placeholder={t('.type_a_message', intl, __filenamespace)}
                multiline
                onChange={(_, value) =>
                  NewMessageDrawerActions.updateAndValidateMessage({
                    key: 'body',
                    value,
                  })
                }
                value={message.body}
              />
            </Grid>
            <Grid item xs>
              <Checkbox
                label={t('.send_later', intl, __filenamespace)}
                onChange={(_, value) =>
                  NewMessageDrawerActions.updateAndValidateMessage({
                    key: 'sendLater',
                    value,
                  })
                }
                checked={message.sendLater}
              />
            </Grid>
          </Grid>

          {message?.sendLater && (
            <Grid container spacing={2} columns={12} justifyContent="evenly">
              <Grid item xs={6}>
                <DatePicker
                  open={openDatePicker}
                  onOpen={() => setOpenDatePicker(true)}
                  onClose={() => setOpenDatePicker(false)}
                  onChange={value =>
                    NewMessageDrawerActions.updateAndValidateMessage({
                      key: 'sendDate',
                      value: value ? value.toDate() : undefined,
                    })
                  }
                  shouldDisableDate={date => date < today}
                  sx={styles.dateTimeInput}
                  value={message.sendDate || ''}
                  renderInput={params => (
                    <MUITextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      style={{ marginTop: '5px' }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t('.choose_date', intl, __filenamespace),
                        style: {
                          ...params.inputProps.style,
                          cursor: 'pointer',
                          caretColor: 'transparent',
                        },
                      }}
                      onClick={() => setOpenDatePicker(true)}
                      onKeyDown={e => e.preventDefault()}
                    />
                  )}
                  DialogProps={{
                    disableEnforceFocus: true,
                    sx: styles.zIndex,
                  }}
                  PopperProps={{ sx: styles.zIndex }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  open={openTimePicker}
                  onOpen={() => setOpenTimePicker(true)}
                  onClose={() => setOpenTimePicker(false)}
                  onChange={value =>
                    NewMessageDrawerActions.updateAndValidateMessage({
                      key: 'sendTime',
                      value: value ? value.toDate() : undefined,
                    })
                  }
                  sx={styles.dateTimeInput}
                  value={message.sendTime || ''}
                  renderInput={params => (
                    <MUITextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      style={{ marginTop: '5px' }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t('.choose_time', intl, __filenamespace),
                        style: {
                          ...params.inputProps.style,
                          cursor: 'pointer',
                          caretColor: 'transparent',
                        },
                      }}
                      onClick={() => setOpenTimePicker(true)}
                      onKeyDown={e => e.preventDefault()}
                    />
                  )}
                  DialogProps={{ disableEnforceFocus: true, sx: styles.zIndex }}
                  PopperProps={{ sx: styles.zIndex }}
                />
              </Grid>
            </Grid>
          )}
          <FlexBox style={{ justifyContent: 'flex-end', marginTop: 20 }}>
            <Button
              sx={{ color: uhColors.blue }}
              onClick={() => NewMessageDrawerActions.toggleDrawer({})}
            >
              {t('actions.cancel', intl)}
            </Button>
            <StateChangingButton
              disabled={!valid}
              label={t('.send_email', intl, __filenamespace)}
              onClick={NewMessageDrawerActions.sendMessage}
              style={{ marginLeft: 8 }}
            />
          </FlexBox>
        </div>
      }
    />
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      newMessageDrawerStore: NewMessageDrawerStore,
    },
  })
)(NewMessageDrawer);
