import EventType from 'event_mgmt/shared/records/EventType.jsx';
import EventTypeEditingActions from 'shared/actions/EventTypeEditingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import EventTypeSelectorUIActions from 'shared/actions/EventTypeSelectorUIActions.jsx';
import UpperHandRecordStore from 'shared/stores/UpperHandRecordStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class EventTypeEditingStore extends UpperHandRecordStore {
  constructor() {
    super();

    this.bindListeners({
      handleUpdateStore: EventTypeEditingActions.UPDATE_STORE,
      handleColorChange: EventTypeEditingActions.colorChanged,
      handleCreateOrUpdate: EventTypeEditingActions.CREATE_OR_UPDATE,
      handleError: EventTypeEditingActions.ERROR,
      handleInitialLoad: EventTypeEditingActions.INITIAL_LOAD,
      handleOpenDialog: EventTypeSelectorUIActions.OPEN_DIALOG,
      handleSaveSuccess: EventTypeEditingActions.CREATE_OR_UPDATE_SUCCESS,
    });
  }

  newRecord(options) {
    return new EventType(options);
  }

  handleColorChange(color) {
    this.record = this.record.set('color', color);
  }

  handleUpdateStore(data) {
    super.handleUpdateStore(data);
  }

  handleCreateOrUpdate(opts) {
    const params = {
      url: this.url(),
      data: this.payload(),
      success: {
        action: EventTypeEditingActions.createOrUpdateSuccess,
        args: [opts || {}],
      },
      error: EventTypeEditingActions.error,
    };

    return this.record.id ? uhApiClient.put(params) : uhApiClient.post(params);
  }

  handleSaveSuccess([attributes, args]) {
    this.waitFor(EventTypeListingStore);
    this.reset();
    if (args && args.callback) {
      setTimeout(() => args.callback(attributes.id));
    }
  }

  handleError(args) {
    this.notifyError('error while creating or updating event type', args);
  }

  handleOpenDialog({ newEventType = false, eventTypeId = null }) {
    if (newEventType) {
      this.reset();
    } else if (eventTypeId) {
      this.load(eventTypeId);
    }
  }

  handleInitialLoad(eventTypeId) {
    this.load(eventTypeId);
  }

  /*
   * PRIVATE HELPERS
   */

  reset() {
    this.record = this.newRecord();
  }

  load(eventTypeId) {
    const eventType =
      EventTypeListingStore.getState().findById(eventTypeId) || new EventType();
    this.record = eventType;
  }

  payload() {
    const payload = this.record.toJSON();

    if (!payload.id) {
      delete payload.id;
    }
    delete payload.event_count;
    delete payload.created_at;
    delete payload.updated_at;

    return JSON.stringify({ attributes: payload });
  }

  url() {
    if (this.record.id) {
      return `event_types/${this.record.id}`;
    }
    return 'event_types';
  }
}

export default alt.createStore(EventTypeEditingStore, 'EventTypeEditingStore');
