import { Set, List } from 'immutable';

import MembershipCreditPassDiscount from 'shared/records/MembershipCreditPassDiscount.jsx';
import MembershipRetailDiscount from 'shared/records/MembershipRetailDiscount.jsx';
import MembershipEventDiscount from 'shared/records/MembershipEventDiscount.jsx';
import { MEMBERSHIP_DISCOUNTS_TYPES } from 'shared/records/Membership.jsx';
import { MEMBERSHIP_CREDIT_TYPES } from 'shared/records/MembershipEventCredit.jsx';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import SpecificEventsActions from 'event_mgmt/index/actions/SpecificEventsActions.jsx';

import UpperHandRecordStore from 'shared/stores/UpperHandRecordStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';

class BaseDiscountsStore extends UpperHandRecordStore {
  constructor({ actions }) {
    super();

    this.actions = actions;
    this.selectedEventTypeIds = Set();
    this.selectedEventIds = Set();
    this.unselectedEventTypes = List();
    this.exclusiveExpanded = false;

    this.bindListeners({
      addEventDiscount: this.actions.eventDiscountAdded,
      discountFieldChanged: this.actions.discountFieldChanged,
      discountRemoved: this.actions.discountRemoved,
      discountTypeChanged: this.actions.discountTypeChanged,

      addEventCredit: this.actions.eventCreditAdded,
      removeEventCredit: this.actions.eventCreditRemoved,
      updateEventCreditUnlimited: this.actions.eventCreditUnlimitedToggled,
      toggleFutureScheduling: this.actions.toggleFutureScheduling,
      updateGrantAhead: this.actions.eventGrantAheadChanged,
      eventCreditTypeChanged: this.actions.eventCreditTypeChanged,
      addEventCreditEventType: this.actions.eventCreditEventTypeAdded,
      removeEventCreditEventType: this.actions.eventCreditEventTypeRemoved,
      updateEventCredit: this.actions.eventCreditUpdated,
      addEventCreditEvent: this.actions.eventCreditEventAdded,
      removeEventCreditEvent: this.actions.eventCreditEventRemoved,
      toggleCreditRollover: this.actions.toggleCreditRollover,

      addExclusiveEventType: this.actions.exclusiveEventTypeAdded,
      removeExclusiveEventType: this.actions.exclusiveEventTypeRemoved,
      clearExclusiveEventTypes: this.actions.exclusiveEventTypesCleared,
    });
  }

  setDiscounts() {
    this.record = this.record
      .set(
        'membership_event_discounts',
        this.record.membership_all_discounts.filter(
          d => d instanceof MembershipEventDiscount
        )
      )
      .set(
        'membership_retail_discounts',
        this.record.membership_all_discounts.filter(
          d => d instanceof MembershipRetailDiscount
        )
      )
      .set(
        'membership_credit_pass_discounts',
        this.record.membership_all_discounts.filter(
          d => d instanceof MembershipCreditPassDiscount
        )
      );
  }

  addEventDiscount(addToAllList = false) {
    const existingAllEventsDiscount = addToAllList
      ? this.record.membership_all_discounts.some(
          d => d.all_events && d instanceof MembershipEventDiscount
        )
      : this.record.membership_event_discounts.some(d => d.all_events);

    this.record = this.record.addEventDiscount({
      addToAllList,
      all_events: !existingAllEventsDiscount,
    });
  }

  discountFieldChanged({ index, field, value }) {
    this.record = this.record.setIn(
      ['membership_all_discounts', index, field],
      value
    );
  }

  discountRemoved({ index }) {
    this.record = this.record.set(
      'membership_all_discounts',
      this.record.get('membership_all_discounts').remove(index)
    );
  }

  discountTypeChanged({ index, newType }) {
    let newDiscount = new MembershipEventDiscount();

    if (newType === MEMBERSHIP_DISCOUNTS_TYPES.EVENT) {
      const isAllChecked = this.record
        .get(newType)
        .some(d => d.get('all_events'));

      newDiscount = new MembershipEventDiscount({
        all_events: !isAllChecked,
      });
    }

    if (newType === MEMBERSHIP_DISCOUNTS_TYPES.RETAIL) {
      const isAllChecked = this.record
        .get(newType)
        .some(d => d.get('all_retail_products'));

      newDiscount = new MembershipRetailDiscount({
        all_retail_products: !isAllChecked,
      });
    }

    if (newType === MEMBERSHIP_DISCOUNTS_TYPES.CREDIT_PASS) {
      const isAllChecked = this.record
        .get(newType)
        .some(d => d.get('all_credit_passes'));

      newDiscount = new MembershipCreditPassDiscount({
        all_credit_passes: !isAllChecked,
      });
    }

    this.record = this.record.set(
      'membership_all_discounts',
      this.record.get('membership_all_discounts').set(index, newDiscount)
    );
  }

  addEventCredit() {
    this.setExistingAllEventsCredits();
    const isAllowedAllEvent =
      !this.existingAllEventsCredits &&
      !this.selectedEventTypeIds.size &&
      !this.selectedEventIds.size;
    this.record = this.record.addEventCredit({
      type: isAllowedAllEvent
        ? MEMBERSHIP_CREDIT_TYPES.ALL_EVENTS
        : MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES,
      all_events: isAllowedAllEvent,
      grant_ahead: 0,
    });
    this.updateCreditHelpers();
  }

  removeEventCredit({ index }) {
    this.record = this.record.removeEventCredit({ index });
    this.updateCreditHelpers();
  }

  setExistingAllEventsCredits() {
    this.existingAllEventsCredits = this.record.membership_event_credits.some(
      c => c.all_events
    );
  }

  setDisabledAddingNewCredit() {
    this.disabledAddingNewCredit = this.record.membership_event_credits.some(
      c =>
        (c.all_events && c.unlimited) ||
        (c.type === MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES &&
          !c.event_type_ids.size) ||
        (c.type === MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC && !c.event_ids.size)
    );
  }

  setSelectedEventIds() {
    this.selectedEventIds = this.record.membership_event_credits.reduce(
      (set, credit) => set.union(credit.event_ids),
      Set()
    );
  }

  updateEventCreditUnlimited([discountIndex, value]) {
    if (value) {
      this.record = this.record.mergeIn(
        ['membership_event_credits', discountIndex],
        { unlimited: value, quantity: 0, interval: 0 }
      );
    } else {
      this.record = this.record.mergeIn(
        ['membership_event_credits', discountIndex],
        { unlimited: value, quantity: 1, interval: 1 }
      );
    }
    this.setDisabledAddingNewCredit();
  }

  updateSelectedEventTypes() {
    this.waitFor(EventTypeListingStore);
    const { eventTypes } = EventTypeListingStore.getState();
    this.selectedEventTypeIds = this.record.membership_event_credits.reduce(
      (set, credit) => set.union(credit.event_type_ids),
      Set()
    );

    this.unselectedEventTypes = eventTypes.filter(
      t => !this.selectedEventTypeIds.has(t.id)
    );
  }

  toggleFutureScheduling(currentIndex) {
    this.record = this.record.updateIn(
      ['membership_event_credits', currentIndex, 'allow_future_scheduling'],
      currentVal => !currentVal
    );

    // Grant ahead is not available, so reset it
    if (
      this.record.getIn([
        'membership_event_credits',
        currentIndex,
        'allow_future_scheduling',
      ])
    ) {
      this.updateGrantAhead({ index: currentIndex, grantAhead: 0 });
    }
  }

  updateGrantAhead({ grantAhead, index }) {
    this.record = this.record.mergeIn(['membership_event_credits', index], {
      grant_ahead: grantAhead,
    });
  }

  updateCreditHelpers() {
    this.setSelectedEventIds();
    this.updateSelectedEventTypes();
    this.setExistingAllEventsCredits();
    this.setDisabledAddingNewCredit();
  }

  fetchEvents(initial) {
    EventActions.list.defer({
      fields: ['participant_count'],
      exceptIds: this.selectedEventIds.toArray(),
      exceptTypes: this.selectedEventTypeIds.toArray(),
    });
    if (initial && this.selectedEventIds.size) {
      SpecificEventsActions.list.defer({
        fields: ['participant_count'],
        ids: this.selectedEventIds.toArray(),
      });
    }
  }

  updateEventsStatus(initial = false) {
    this.updateCreditHelpers();
    this.fetchEvents(initial);
  }

  updateRecordForAllEventSelected(discountIndex) {
    this.record = this.record.mergeIn(
      ['membership_event_credits', discountIndex],
      {
        type: MEMBERSHIP_CREDIT_TYPES.ALL_EVENTS,
        all_events: true,
        event_type_ids: Set(),
        event_ids: Set(),
      }
    );
    this.updateCreditHelpers();
  }

  updateRecordForEventSpecificSelected(discountIndex) {
    this.record = this.record.mergeIn(
      ['membership_event_credits', discountIndex],
      {
        type: MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC,
        all_events: false,
        event_type_ids: Set(),
      }
    );
    this.updateEventsStatus();
  }

  updateRecordForEventTypesSelected(discountIndex) {
    this.record = this.record.mergeIn(
      ['membership_event_credits', discountIndex],
      {
        type: MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES,
        all_events: false,
        event_ids: Set(),
      }
    );
    this.updateCreditHelpers();
  }

  eventCreditTypeChanged([discountIndex, value]) {
    switch (value) {
      case MEMBERSHIP_CREDIT_TYPES.ALL_EVENTS: {
        this.updateRecordForAllEventSelected(discountIndex);
        break;
      }
      case MEMBERSHIP_CREDIT_TYPES.EVENT_TYPES: {
        this.updateRecordForEventTypesSelected(discountIndex);
        break;
      }
      case MEMBERSHIP_CREDIT_TYPES.EVENT_SPECIFIC: {
        this.updateRecordForEventSpecificSelected(discountIndex);
        break;
      }
      default:
        break;
    }
  }

  addEventCreditEventType([discountIndex, eventTypeId]) {
    this.record = this.record.updateIn(
      ['membership_event_credits', discountIndex, 'event_type_ids'],
      (set = Set()) => set.add(eventTypeId)
    );
    this.updateSelectedEventTypes();
    this.setDisabledAddingNewCredit();
  }

  removeEventCreditEventType([discountIndex, eventTypeId]) {
    this.record = this.record.updateIn(
      ['membership_event_credits', discountIndex, 'event_type_ids'],
      (set = Set()) => set.remove(eventTypeId)
    );
    this.updateSelectedEventTypes();
    this.setDisabledAddingNewCredit();
  }

  updateEventCredit([prop, discountIndex, value]) {
    this.record = this.record.setIn(
      ['membership_event_credits', discountIndex, prop],
      value
    );
  }

  addEventCreditEvent([discountIndex, eventId]) {
    this.record = this.record.updateIn(
      ['membership_event_credits', discountIndex, 'event_ids'],
      (set = Set()) => set.add(eventId)
    );
    this.setDisabledAddingNewCredit();
    this.updateEventsStatus();
  }

  removeEventCreditEvent([discountIndex, eventId]) {
    this.record = this.record.updateIn(
      ['membership_event_credits', discountIndex, 'event_ids'],
      (set = Set()) => set.remove(eventId)
    );
    this.setDisabledAddingNewCredit();
    this.updateEventsStatus();
  }

  toggleCreditRollover(currentIndex) {
    this.record = this.record.updateIn(
      ['membership_event_credits', currentIndex, 'rollover'],
      currentVal => !currentVal
    );

    // Grant ahead is not available, so reset it
    if (
      this.record.getIn(['membership_event_credits', currentIndex, 'rollover'])
    ) {
      this.updateGrantAhead({ index: currentIndex, grantAhead: 0 });
    }
  }

  addExclusiveEventType(eventTypeId) {
    this.record = this.record.addExclusiveEventType(eventTypeId);
  }

  removeExclusiveEventType(eventTypeId) {
    this.record = this.record.removeExclusiveEventType(eventTypeId);
  }

  clearExclusiveEventTypes() {
    this.record = this.record.set('membership_event_permissions', List());
    this.exclusiveExpanded = false;
  }
}

export default BaseDiscountsStore;
