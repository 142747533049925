import CommissionRateCreationActions from 'contacts/shared/actions/commission_rates/CommissionRateCreationActions';
import CommissionRateEditingStoreInterface from 'contacts/shared/stores/commission_rates/CommissionRateEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  CommissionRateEditingStoreInterface,
  'CommissionRateCreationStore',
  {
    actions: CommissionRateCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
