import { Map } from 'immutable';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import Membership from 'shared/records/Membership.jsx';
import MembershipSubscriptionPackage from 'shared/records/MembershipSubscriptionPackage.jsx';
import MembershipPurchasingActions from 'memberships/actions/MembershipPurchasingActions.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { MembershipDataStore } from 'dataStores';
import { MembershipSource } from 'sources';
import { removeHash, shouldOpenPurchaseDrawer } from 'shared/utils/RouteUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

class MembershipPurchasingStore extends UpperHandStore {
  constructor() {
    super();

    this.membership = new Membership();
    this.subscriptionPackage = new MembershipSubscriptionPackage();
    this.cartMembershipMap = Map();
    this.dirty = false;

    this.bindListeners({
      addProfile: [
        MembershipPurchasingActions.PROFILE_ADDED,
        MembershipPurchasingActions.PROFILE_CREATED,
      ],

      removeProfile: MembershipPurchasingActions.PROFILE_REMOVED,
      updatePackageFromCart: CartActions.FETCH_SUCCESS,
      purchase: MembershipPurchasingActions.PURCHASE_CLICKED,
      purchaseUrlCheck: MembershipPurchasingActions.purchaseUrlCheck,
      membershipTierSelected:
        MembershipPurchasingActions.membershipTierSelected,
    });
  }

  purchaseUrlCheck() {
    const shouldOpenDrawer =
      /memberships$/.test(window.location.pathname) &&
      shouldOpenPurchaseDrawer();

    if (shouldOpenDrawer) {
      const { memberships } = MembershipDataStore.getState();

      const membershipId = parseInt(
        window.location.hash.match(/pdo(\d+)/)[1],
        10
      );

      if (memberships.size > 0) {
        const membership = memberships.find(m => m.id === membershipId);

        removeHash();
        this.purchase({
          membership,
          buyerId: currentUser().customer_user_id,
        });
      } else {
        MembershipSource.list({
          params: {
            ids: [membershipId],
            fields: ['events', 'event_types'],
          },
          success: ({ memberships: membershipsList }) => {
            if (membershipsList.size > 0) {
              removeHash();
              this.purchase({
                membership: membershipsList.get(0),
                buyerId: currentUser().customer_user_id,
              });
            }
          },
        });
      }
    }
  }

  updatePackageFromCart() {
    if (!this.membership.id) {
      return;
    }
    this.getCart();
    this.subscriptionPackage =
      this.orderItemFromCart(this.membership.id)?.orderable ??
      new MembershipSubscriptionPackage();
  }

  orderItemFromCart(membershipId) {
    this.orderItem = this.cart.order_items.find(
      oi => oi.isMembershipItem() && oi.orderable.membership_id === membershipId
    );
    return this.orderItem;
  }

  getCart() {
    this.cart = CartStore.getState().cart;
  }

  purchase({ membership, buyerId }) {
    this.getCart();
    SecondaryDrawerActions.setActive.defer('MembershipPurchaseDrawer');
    if (this.membership.id !== membership.id) {
      const orderItemFromCart = this.orderItemFromCart(membership.id);

      if (this.cart.status === 'in_progress' && !!orderItemFromCart) {
        this.subscriptionPackage = orderItemFromCart.orderable;
      } else {
        this.subscriptionPackage = new MembershipSubscriptionPackage({
          membership_id: membership.id,
          buyer_id: buyerId,
        });
      }

      this.cartMembershipMap = this.cart.customerUserMembershipMap();
    }
    this.dirty = false;
    this.membership = membership;
  }

  updateDirty() {
    const orderItemFromCart = this.orderItemFromCart(this.membership.id);
    if (!orderItemFromCart) {
      return;
    }

    this.dirty = !this.subscriptionPackage.customer_user_ids.equals(
      orderItemFromCart.orderable.customer_user_ids
    );
  }

  addProfile(customerUserId) {
    this.subscriptionPackage =
      this.subscriptionPackage.addCustomerUser(customerUserId);
    this.updateDirty();
  }

  removeProfile(customerUserId) {
    this.subscriptionPackage =
      this.subscriptionPackage.removeCustomerUser(customerUserId);
    this.updateDirty();
  }

  membershipTierSelected(tierId) {
    this.subscriptionPackage = this.subscriptionPackage.set(
      'membership_tier_id',
      tierId
    );
    this.updateDirty();
  }
}

export default alt.createStore(
  MembershipPurchasingStore,
  'MembershipPurchasingStore'
);
