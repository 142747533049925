import * as React from 'react';
import ImageUpload from 'components/ImageUpload';

import {
  Address1,
  Address2,
  BusinessName,
  City,
  PostalCode,
  State,
  TimeZone,
} from 'user_management/shared/components/BusinessProfileFields.jsx';
import EditProfile from 'user_management/shared/components/_EditProfile.jsx';

import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import CustomerCreationActions from '../Actions';

const handleBusinessProfileChange = handleChange({
  actions: CustomerCreationActions,
  prefix: 'customer',
});
const handleBusinessProfileAddressChange = handleChange({
  actions: CustomerCreationActions,
  prefix: 'customer.address',
});

function BusinessProfileEditor({ customer, intl, errors }) {
  return (
    <EditProfile headerText="business information">
      <ImageUpload
        cropAspect={1}
        uploadContainerWidth="100%"
        previewContainerHeight={185}
        previewContainerWidth={185}
        onChange={value => {
          handleBusinessProfileChange(null, value, 'logo');
        }}
        image={customer.logo}
        style={{ margin: '8px 0 12px' }}
      />
      <BusinessName
        customer={customer}
        onChange={handleBusinessProfileChange}
        errorText={errors.getErrors('customer.name', intl)}
      />
      <Address1
        customer={customer}
        onChange={handleBusinessProfileAddressChange}
        errorText={errors.getErrors('customer.address.line_1')}
      />
      <Address2
        customer={customer}
        onChange={handleBusinessProfileAddressChange}
      />
      <City
        customer={customer}
        onChange={handleBusinessProfileAddressChange}
        errorText={errors.getErrors('customer.address.city')}
      />
      <div style={{ display: 'flex' }}>
        <State
          customer={customer}
          onChange={handleBusinessProfileAddressChange}
          style={{ flex: '1 0 auto', width: '30%' }}
          errorText={errors.getErrors('customer.address.state')}
        />
        <span style={{ flex: '1 0 auto', width: '10%' }}>&nbsp;</span>
        <PostalCode
          customer={customer}
          onChange={handleBusinessProfileAddressChange}
          style={{ flex: '1 0 auto', width: '60%' }}
          errorText={errors.getErrors('customer.address.postal_code')}
        />
      </div>
      <TimeZone
        customer={customer}
        onChange={handleBusinessProfileChange}
        errorText={errors.getErrors('customer.time_zone')}
      />
    </EditProfile>
  );
}

export default BusinessProfileEditor;
