import uhApiClient from 'shared/helpers/uhApiClient.jsx';

// eslint-disable-next-line import/prefer-default-export
export const create = ({ sourceId, amount, success, error }) => {
  uhApiClient.post({
    url: 'clients/forgive_debit',
    data: JSON.stringify({
      source_id: sourceId,
      amount,
    }),
    success,
    error,
  });
};
