class CreditListDrawerActions {
  constructor() {
    this.generateActions(
      'openDrawer',
      'closeDrawer',
      'onTabChange',
      'togglePackageAdjustmentModal',
      'updateRegistrationPackage',
      'updateRegistrationPackageSuccess',
      'updateRegistrationPackageError',
      'listRegistrationPackages',
      'listRegistrationPackagesSuccess',
      'listRegistrationPackagesError',
      'listCreditGrantsSuccess',
      'listCreditGrantsError',
      'listGrantsPrevNext',
      'listEventsSuccess',
      'listEventsError',
      'listEventTypesSuccess',
      'listEventTypesError',
      'openAdjustmentModal',
      'closeAdjustmentModal',
      'updateCreditGrant',
      'updateCreditGrantSuccess',
      'updateCreditGrantError',
      'listCreditCounts',
      'listCreditCountsSuccess',
      'listCreditCountsError',
      'listCountsPrevNext'
    );
  }
}

export default alt.createActions(CreditListDrawerActions);
