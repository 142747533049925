import { List, Record } from 'immutable';
import OptionValue from 'shared/records/OptionValue.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class OptionType extends Record({
  id: null,
  name: '',
  option_values: List(),
}) {
  constructor(obj = {}) {
    const optionValues = List(
      (obj.option_values || []).map(v => new OptionValue(v))
    ).sortBy(v => v.position);

    super(merge(obj, { option_values: optionValues }));
  }

  addOptionValue(optionValue) {
    return this.update('option_values', (ls = List()) =>
      ls.push(optionValue).map((v, i) => v.set('position', i))
    );
  }

  removeOptionValue(valueIndex) {
    return this.update('option_values', (ls = List()) =>
      ls.delete(valueIndex).map((v, i) => v.set('position', i))
    );
  }
}

export default OptionType;
