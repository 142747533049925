import { asPlainObject } from 'shared/utils/ObjectUtils.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class UpperHandRecordStore extends UpperHandStore {
  constructor() {
    super();
    this.record = this.newRecord();
  }

  newRecord() {
    throw new Error('subclass responsibility');
  }

  handleUpdateStore(state) {
    // Tested in MembershipEditingStoreSpec
    this.record = this.record.merge(asPlainObject(state));
  }

  handleError() {
    const recordType = this.record.__proto__.toString().split(' ')[0];
    const errorType = arguments[1].split('.')[1].match(/.*[\D]/)[0];
    this.notifyError(`${errorType} ${recordType}`, arguments);
  }
}

export default UpperHandRecordStore;
