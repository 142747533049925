import * as React from 'react';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import SearchBar from 'shared/components/SearchBar.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import VariantAdjustmentTable from 'retail/components/InventoryAdjustmentDialog/VariantAdjustmentTable.jsx';

import { uhColors, uhFormElement } from 'shared/styles/uhStyles.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import InventoryAdjustmentDialogActions from 'retail/actions/InventoryAdjustmentDialogActions.js';

import './styles.scss';

const styles = {
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  deleteIconColorPrimary: {
    color: 'white',
  },
  dialog: {
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 15,
      backgroundColor: uhColors.dialogTitle,
      padding: '0 10px 0 24px',
      color: 'var(--color-white)',
    },
    iconClose: {
      height: 20,
      width: 20,
      color: '#C3C3C3',
    },
    bodyStyle: {
      padding: 0,
      overflowY: 'hidden',
      borderTop: 'none',
    },
  },
  title: {
    container: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      backgroundColor: uhColors.dialogTitle,
      padding: '0 10px 10px 24px',
      color: 'var(--color-white)',
    },
    dateInputContainer: {
      marginLeft: 10,
      minHeight: 50,
    },
    numberInputContainer: {
      minHeight: 50,
    },
    inputRoot: {
      ...uhFormElement.field,
      color: 'var(--color-white)',
      marginRight: 14,
    },
    input: {
      paddingLeft: 27,
      color: 'var(--color-white)',
    },
    hint: {
      paddingLeft: 27,
      color: '#CACBCA',
    },
    icon: {
      height: 17,
      width: 17,
      margin: '2px 0 0 0',
      color: 'var(--color-white)',
    },
    label: {
      paddingRight: 5,
      whiteSpace: 'nowrap',
    },
    value: {
      fontWeight: 'bold',
    },
  },
  subTitle: {
    container: {
      height: 40,
      color: '#C3C3C3',
      fontSize: 13,
    },
    submitted: {
      width: '100%',
    },
    iconEdit: {
      padding: '0 12px',
    },
  },
  spinnerContainer: {
    paddingBottom: '3em',
    minHeight: 428,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 24px 10px 10px',
  },
  searchInput: {
    container: {
      marginLeft: 10,
      width: '100%',
      fontSize: 15,
    },
    icon: {
      height: 17,
      width: 17,
    },
  },
};

function InventoryAdjustmentTitle() {
  return (
    <div style={styles.dialog.title}>
      <h3>
        <FormattedMessage id={messageId('.title', __filenamespace)} />
      </h3>
      <IconButton onClick={InventoryAdjustmentDialogActions.closeDialog}>
        <ClearIcon style={styles.dialog.iconClose} />
      </IconButton>
    </div>
  );
}

function Container({ intl, inventoryAdjustmentDialogStore, isProductPage }) {
  const { filters } = inventoryAdjustmentDialogStore;
  return (
    <div style={styles.container}>
      <div style={styles.searchContainer}>
        <SearchBar
          hintText={t('.search-bar', intl, __filenamespace)}
          searchText={filters.get('search')}
          onTypingEnd={value =>
            InventoryAdjustmentDialogActions.updateFilter('search', value)
          }
          style={styles.searchInput.container}
          iconStyle={styles.searchInput.icon}
          inputStyle={styles.searchInput.input}
          underlineShow
          autoFocus
        />
      </div>
      <VariantAdjustmentTable
        inventoryAdjustmentDialogStore={inventoryAdjustmentDialogStore}
        isProductPage={isProductPage}
      />
    </div>
  );
}

class InventoryAdjustmentDialogStore extends React.PureComponent {
  render() {
    const { inventoryAdjustmentDialogStore, intl, isProductPage } = this.props;
    const { isOpen, isLoading, isChanged, isEditMode, variants } =
      inventoryAdjustmentDialogStore;

    return (
      <Confirmation
        hasCheckbox={false}
        title={<InventoryAdjustmentTitle />}
        open={isOpen}
        classes={{
          root: 'adjustments-dialog',
          content: 'adjustments-dialog-content',
          actions: clsx(
            'adjustments-dialog-actions',
            !isEditMode && 'hide-save-btn'
          ),
          title: 'adjustments-dialog-title',
        }}
        confirmationDisabled={!isChanged || !isEditMode}
        onCancel={InventoryAdjustmentDialogActions.closeDialog}
        onConfirm={() =>
          InventoryAdjustmentDialogActions.createInventoryAdjustments(variants)
        }
      >
        <SpinWhileLoading
          isLoading={isLoading}
          spinnerContainerStyle={styles.spinnerContainer}
        >
          <Container
            isProductPage={isProductPage}
            inventoryAdjustmentDialogStore={inventoryAdjustmentDialogStore}
            intl={intl}
          />
        </SpinWhileLoading>
      </Confirmation>
    );
  }
}

export default injectIntl(InventoryAdjustmentDialogStore);
