import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'shared/utils/LocaleUtils';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import OrderSummaryDrawerActions from 'containers/reports/orderSummaryDrawer/Actions';

function RowDesktop({ columns, intl, orders, orderId }) {
  const order = orders.get(orderId);
  const orderItem = order.getIn(['order_items', 0]);

  if (!orderItem) {
    return null;
  }

  const orderedAt = moment(orderItem.ordered_at);

  // Have to add one because date / time cannot be toggled.
  const activeItems = columns.filter(c => c).count() + 1;

  return (
    <Card
      classes={{
        root: 'orders-report__row',
        content: 'orders-report__row-content',
      }}
      contentDirection="row"
      onClick={() => OrderSummaryDrawerActions.openDrawer(order.id)}
    >
      <Grid container spacing={1} alignContent="center" justify="center">
        <Grid
          item
          className={`orders-report__row-item even-cols-${activeItems}`}
        >
          <Typography variant="body2">
            {orderedAt.isSame(moment(), 'day')
              ? t('.date_today', intl, __filenamespace, {
                  date: orderedAt.format('h:mma'),
                })
              : orderedAt.format('MM/DD/YYYY • h:mma')}
          </Typography>
        </Grid>
        {columns.get('orderNumber') && (
          <Grid
            item
            className={`orders-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">{order.order_number}</Typography>
          </Grid>
        )}
        {columns.get('buyer') && (
          <Grid
            item
            className={`orders-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">{orderItem.buyer_name}</Typography>
          </Grid>
        )}
        {columns.get('channel') && (
          <Grid
            item
            className={`orders-report__row-item even-cols-${activeItems}`}
          >
            {order.sale_source && (
              <Typography variant="body2">
                {t(`.order_method_${order.sale_source}`, intl, __filenamespace)}
              </Typography>
            )}
          </Grid>
        )}
        {columns.get('amount') && (
          <Grid
            item
            className={`orders-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              <FormattedCurrency value={order.total} fromCents />
            </Typography>
          </Grid>
        )}
        {columns.get('method') && (
          <Grid
            item
            className={`orders-report__row-item even-cols-${activeItems}`}
          >
            {orderItem.payment_method && (
              <Typography variant="body2">
                {t(
                  `.payment_method_${orderItem.payment_method}`,
                  intl,
                  __filenamespace
                )}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <div className="orders-report__row-item orders-report__row-action">
        <ChevronRightIcon />
      </div>
    </Card>
  );
}

RowDesktop.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
};

export default RowDesktop;
