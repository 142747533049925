export default alt.generateActions(
  'list',
  'error',
  'listReceived',
  'clearSearchClicked',
  'searchStringUpdated',
  'paginationOptionsUpdated',
  'togglePublishedUpdated',
  'filterUpdated'
);
