import { Map, List, Set } from 'immutable';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';

import AutoCompleteClientListStore from 'shared/stores/AutoCompleteClientListStore';
import DateRange, { THIS_MONTH } from 'shared/records/DateRange';
import PayrollEntry from 'shared/records/PayrollEntry';
import PayrollEntryEditingActions from 'reporting/payroll/actions/PayrollEntryEditingActions';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import TaskListingActions from 'shared/actions/TaskListingActions';
import TaskListingStore from 'shared/stores/TaskListingStore';
import TimeLog from 'shared/records/TimeLog';
import TimeLogCreationActions from 'reporting/payroll/actions/TimeLogCreationActions';
import TimeLogDeletionActions from 'reporting/payroll/actions/TimeLogDeletionActions';
import TimeLogEditingActions from 'reporting/payroll/actions/TimeLogEditingActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { convertDateToClientValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { sumInt } from 'shared/utils/ImmutableUtils';
import { downloadFile } from 'shared/utils/SharedUtils.js';
import AutoCompleteItemListStore from 'shared/stores/AutoCompleteItemListStore';
import CommissionsCreationActions from 'reporting/payroll/actions/Commissions/CommissionsCreationActions';
import CommissionsEditingActions from 'reporting/payroll/actions/Commissions/CommissionsEditingActions';
import { PayrollReportSource } from 'sources';
import consolidatePayrollData from 'reporting/payroll/components/utils';
import AutoCompleteAttendeeListStore from 'shared/stores/AutoCompleteAttendeeListStore';

const PAID = 'paid';
const UNPAID = 'unpaid';
const COMMISSIONS_PAYROLL = ['membership_commission', 'retail_commission'];

class PayrollReportStore extends UpperHandStore {
  constructor() {
    super();
    this.payrollEntries = Map();
    this.totals = Map();
    this.paginationOptions = Map({ page: 1, per_page: 250 });
    this.isPayrollListDownloading = false;
    this.isLoading = true;
    this.filterDrawerOpen = false;
    this.statusToChange = null;
    this.entries = null;
    this.resetFilters();

    this.debouncedReloadWithNewFilters = debounce(
      this.reloadWithNewFilters,
      100
    );

    this.bindListeners({
      mounted: PayrollReportActions.MOUNTED,
      downloadList: PayrollReportActions.downloadList,
      downloadListSuccess: PayrollReportActions.downloadListSuccess,
      downloadListError: PayrollReportActions.downloadListError,
      listPayrollEntries: PayrollReportActions.PAYROLL_ENTRIES_LIST,
      payrollEntriesListSuccess:
        PayrollReportActions.PAYROLL_ENTRIES_LIST_SUCCESS,
      payrollEntriesListError: PayrollReportActions.PAYROLL_ENTRIES_LIST_ERROR,
      updateFilters: PayrollReportActions.FILTER_UPDATED,
      combinedFilterUpdated: PayrollReportActions.COMBINED_FILTER_UPDATED,
      updateDateFilter: PayrollReportActions.DATE_FILTER_UPDATED,
      updatePaymentFilter: PayrollReportActions.PAYMENT_FILTER_UPDATED,
      updateAttendeeFilter: PayrollReportActions.ATTENDEE_FILTER_UPDATED,
      updateClientFilter: PayrollReportActions.CLIENT_FILTER_UPDATED,
      updateItemFilter: PayrollReportActions.ITEM_FILTER_UPDATED,
      handleFiltersReset: PayrollReportActions.FILTERS_CLEARED,
      timeLogCreated: TimeLogCreationActions.SUCCESS,
      timeLogUpdated: TimeLogEditingActions.SUCCESS,
      timeLogRemoved: TimeLogDeletionActions.SUCCESS,

      payrollEntryUpdated: PayrollEntryEditingActions.SUCCESS,

      setDefaultStaffFilter: StaffActions.LIST_SUCCESS,
      setDefaultTaskFilter: TaskListingActions.LIST_SUCCESS,
      setDefaultEventTypesFilter: EventTypeListingActions.LIST_SUCCESS,
      toggleFilterDrawer: PayrollReportActions.TOGGLE_FILTER_DRAWER,
      paymentStatusHandler: PayrollReportActions.CHANGE_PAYMENT_STATUS,
      paymentStatusSuccess: PayrollReportActions.PAYMENT_STATUS_SUCCESS,
      paymentStatusError: PayrollReportActions.PAYMENT_STATUS_ERROR,
    });
  }

  resetFilters() {
    this.isLoading = true;

    this.defaultStaffFilter = Set();
    this.defaultTaskFilter = Set();
    this.defaultCommissionFilter = Set(COMMISSIONS_PAYROLL);
    this.defaultPaymentStatusFilter = Set([PAID, UNPAID]);
    this.defaultEventTypesFilter = Set();
    this.isStaffListEmpty = false;
    this.isTaskListEmpty = false;
    this.isEventTypesListEmpty = false;
    this.selectedAttendee = null;

    this.isStaffFilterPopulated = false;
    this.isTasksFilterPopulated = false;
    this.isEventTypesFilterPopulated = false;
    this.areMultiSelectFiltersPopulated = false;

    this.filters = Map({
      dateRange: new DateRange({ value: THIS_MONTH }),
      staffIds: this.defaultStaffFilter,
      taskIds: this.defaultTaskFilter,
      commissionType: this.defaultCommissionFilter,
      eventTypeIds: this.defaultEventTypesFilter,
      paymentStatus: this.defaultPaymentStatusFilter,
      attendeeId: null,
    });

    this.filtersApplied = false;
    this.emptyFilter = true;
    this.selectedClient = null;
    this.selectedItem = null;
  }

  mounted() {
    this.resetFilters();
    this.listPayrollEntries();
  }

  listPayrollEntries() {
    if (this.emptyFilter || !this.areMultiSelectFiltersPopulated) {
      if (
        this.isStaffListEmpty ||
        (this.isTaskListEmpty && this.isEventTypesListEmpty)
      ) {
        this.isLoading = false;
      }
      return;
    }
    this.isLoading = true;

    const payload = this.getFilters();

    uhApiClient.get({
      url: 'analytics/payrolls',
      data: payload,
      success: PayrollReportActions.payrollEntriesListSuccess,
      error: PayrollReportActions.payrollEntriesListError,
    });
  }

  payrollEntriesListSuccess({
    records,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    if (page === 1) {
      this.payrollEntries = Map();
    }

    const newPayrollEntries = List(
      records.map(payrollEntry =>
        payrollEntry.object_type === 'TimeLog'
          ? new TimeLog(payrollEntry)
          : new PayrollEntry(payrollEntry)
      )
    ).groupBy(l => l.customer_user_id);

    this.payrollEntries = this.payrollEntries.mergeWith(
      (oldLogs, newLogs) => oldLogs.concat(newLogs),
      newPayrollEntries
    );

    if (totalCount > this.payrollEntries.reduce(sumInt('size'), 0)) {
      this.paginationOptions = Map({
        page: page + 1,
        per_page: perPage,
      });
      this.listPayrollEntries();
    } else {
      this.calculateTotals();
      this.sortByDate();
      this.isLoading = false;
    }
  }

  payrollEntriesListError(...args) {
    this.notifyError('error fetching payroll entries', args);
  }

  pushNewTimeLogToList(customerUserTimeLogs, newTimeLog) {
    const updatedCustomerUserTimeLogs = customerUserTimeLogs
      .push(newTimeLog)
      .sort((a, b) => a.start_time - b.start_time);
    this.payrollEntries = this.payrollEntries.set(
      newTimeLog.customer_user_id,
      updatedCustomerUserTimeLogs
    );
  }

  isMatchingDateRangeFilter(timeLogStartTime) {
    const { startTime, endTime } = this.filters.get('dateRange');
    return moment()
      .range(startTime, endTime)
      .contains(moment(convertDateToClientValue(timeLogStartTime)));
  }

  isNewTimeLogMatchingFiltration(data) {
    return (
      this.filters.get('taskIds').contains(data.task_id) &&
      this.filters.get('staffIds').contains(data.customer_user_id) &&
      this.isMatchingDateRangeFilter(data.start_time)
    );
  }

  removeTimeLogFromList(id, customerUserId) {
    let customerUserTimeLogs = this.payrollEntries.get(customerUserId);
    customerUserTimeLogs = customerUserTimeLogs.filter(l => l.id !== id);
    if (customerUserTimeLogs.size) {
      this.payrollEntries = this.payrollEntries.set(
        customerUserId,
        customerUserTimeLogs
      );
    } else {
      this.payrollEntries = this.payrollEntries.delete(customerUserId);
    }
  }

  timeLogCreated([data]) {
    if (this.isNewTimeLogMatchingFiltration(data)) {
      const newTimeLog = new TimeLog(data);
      const customerUserTimeLogs =
        this.payrollEntries.get(newTimeLog.customer_user_id) || List();
      this.pushNewTimeLogToList(customerUserTimeLogs, newTimeLog);
      this.calculateTotals();
    }
  }

  timeLogUpdated([data, oldCustomerId]) {
    const isMatchingFiltration = this.isNewTimeLogMatchingFiltration(data);
    this.removeTimeLogFromList(data.id, oldCustomerId || data.customer_user_id);
    if (isMatchingFiltration) {
      const newTimeLog = new TimeLog(data);
      const customerUserTimeLogs =
        this.payrollEntries.get(newTimeLog.customer_user_id) || List();
      this.pushNewTimeLogToList(customerUserTimeLogs, newTimeLog);
    }

    this.calculateTotals();
  }

  timeLogRemoved([_, id, customerUserId]) {
    this.removeTimeLogFromList(id, customerUserId);
    this.calculateTotals();
  }

  // eslint-disable-next-line camelcase
  payrollEntryUpdated({ rate_type, value, total, customer_user_id, id }) {
    const customerUserPayrollEntries =
      this.payrollEntries.get(customer_user_id) || List();
    const index = customerUserPayrollEntries.findIndex(
      entry => entry.id === id
    );

    this.payrollEntries = this.payrollEntries.mergeIn(
      // eslint-disable-next-line camelcase
      [customer_user_id, index],
      // eslint-disable-next-line camelcase
      { rate_type, value, total }
    );
    this.calculateTotals();
  }

  updateFilters([filter, value]) {
    this.filters = this.filters.set(filter, value);
    this.debouncedReloadWithNewFilters();
  }

  combinedFilterUpdated([key, value]) {
    this.filters = this.filters.set(key, value);
    this.debouncedReloadWithNewFilters();
  }

  updateDateFilter({ value, from, to }) {
    this.filters = this.filters.set(
      'dateRange',
      new DateRange({ value, startDate: from, endDate: to })
    );
    this.reloadWithNewFilters();
  }

  updatePaymentFilter([filter, isEnabled]) {
    this.filters = this.filters.update('paymentStatus', (s = Set()) =>
      isEnabled ? s.add(filter) : s.delete(filter)
    );

    this.debouncedReloadWithNewFilters();
  }

  updateAttendeeFilter(id) {
    this.filters = this.filters.set('attendeeId', id);
    this.waitFor(AutoCompleteAttendeeListStore);
    const { clients } = AutoCompleteAttendeeListStore.getState();
    this.selectedAttendee = clients.find(client => client.id === id);
    this.debouncedReloadWithNewFilters();
  }

  updateClientFilter({ id, editing }) {
    this.waitFor(AutoCompleteClientListStore);
    const { clients } = AutoCompleteClientListStore.getState();
    this.selectedClient = clients.find(client => client.id === id);
    if (editing) {
      CommissionsCreationActions.setClient.defer(this.selectedClient);
    } else {
      CommissionsEditingActions.setClient.defer(this.selectedClient);
    }
  }

  updateItemFilter({ id, editing }) {
    this.waitFor(AutoCompleteItemListStore);
    const { items } = AutoCompleteItemListStore.getState();
    this.selectedItem = items.find(i => i.id === id);
    if (editing) {
      CommissionsCreationActions.setItem.defer(this.selectedItem);
    } else {
      CommissionsEditingActions.setItem.defer(this.selectedItem);
    }
  }

  handleFiltersReset() {
    this.filters = this.filters.merge(
      Map({
        staffIds: this.defaultStaffFilter,
        taskIds: this.defaultTaskFilter,
        commissionType: this.defaultCommissionFilter,
        eventTypeIds: this.defaultEventTypesFilter,
        paymentStatus: this.defaultPaymentStatusFilter,
        attendeeId: null,
      })
    );
    this.selectedAttendee = null;
    this.reloadWithNewFilters();
  }

  setDefaultStaffFilter() {
    this.waitFor(StaffStore);
    const { allStaffMap } = StaffStore.getState();

    this.defaultStaffFilter = allStaffMap.size
      ? this.defaultStaffFilter.merge(allStaffMap.keySeq().toSet())
      : Set();

    this.isStaffListEmpty = this.defaultStaffFilter.isEmpty();
    this.isStaffFilterPopulated = true;
    this.updateFilters(['staffIds', this.defaultStaffFilter]);
  }

  setDefaultTaskFilter() {
    this.waitFor(TaskListingStore);
    const { isLoading, tasks } = TaskListingStore.getState();
    if (!isLoading) {
      this.defaultTaskFilter = tasks.size
        ? this.defaultTaskFilter.merge(tasks.keySeq().toSet())
        : Set();

      this.isTaskListEmpty = this.defaultTaskFilter.isEmpty();
      this.isTasksFilterPopulated = true;
      this.updateFilters(['taskIds', this.defaultTaskFilter]);
    }
  }

  setDefaultEventTypesFilter() {
    this.waitFor(EventTypeListingStore);
    const { isLoading, eventTypes } = EventTypeListingStore.getState();
    if (!isLoading) {
      this.defaultEventTypesFilter = eventTypes.size
        ? this.defaultEventTypesFilter.merge(
            eventTypes.map(eventType => eventType.id).toSet()
          )
        : Set();

      this.isEventTypesListEmpty = this.defaultEventTypesFilter.isEmpty();
      this.isEventTypesFilterPopulated = true;
      this.updateFilters(['eventTypeIds', this.defaultEventTypesFilter]);
    }
  }

  calculateTotals() {
    this.totals = this.payrollEntries.map(payrollEntries =>
      Map().withMutations(summary => {
        payrollEntries.forEach(payrollEntry => {
          summary.update('totalHours', (i = 0) => i + payrollEntry.duration);
          summary.update('totalUnpaid', (i = 0) =>
            payrollEntry.isUnpaid() ? i + payrollEntry.total : i
          );
          summary.update('totalPaid', (i = 0) =>
            payrollEntry.isPaid() ? i + payrollEntry.total : i
          );
          summary.update('totalEarned', (i = 0) => i + payrollEntry.total);
        });
      })
    );
  }

  getFilters() {
    const { startTime, endTime } = this.filters.get('dateRange');
    const selectedPaymentStatus = this.filters.get('paymentStatus');
    const paymentStatus =
      selectedPaymentStatus.size < 2
        ? selectedPaymentStatus.keySeq().toArray()[0]
        : 'all';
    const commissionType = this.filters.get('commissionType');
    const params = {
      ...this.paginationOptions.toJS(),
      start_time: startTime.format(),
      end_time: endTime.format(),
      customer_user_ids: this.filters.get('staffIds').toJS(),
      event_type_ids: this.filters.get('eventTypeIds').toJS(),
      task_ids: this.filters.get('taskIds').toJS(),
      attendee_id: this.filters.get('attendeeId'),
      payment_status: paymentStatus,
    };
    if (commissionType.size > 0) {
      params.commission_type = commissionType.toJS();
    }
    return params;
  }

  downloadList() {
    const payload = this.getFilters();
    this.isPayrollListDownloading = true;
    uhApiClient.get({
      url: `analytics/payroll_csv`,
      headers: {
        Accept: 'application/csv',
        'Content-Type': 'application/csv',
      },
      data: payload,
      success: PayrollReportActions.downloadListSuccess,
      error: PayrollReportActions.downloadListError,
    });
  }

  downloadListSuccess(data) {
    downloadFile({
      data,
      fileName: 'payroll-list.csv',
    });
    this.isPayrollListDownloading = false;
  }

  downloadListError() {
    this.isPayrollListDownloading = false;
  }

  sortByDate() {
    this.payrollEntries = this.payrollEntries.map(customerTimeLogs =>
      customerTimeLogs.sort((a, b) => a.start_time - b.start_time)
    );
  }

  reloadWithNewFilters() {
    this.filtersApplied =
      !this.filters.get('staffIds').equals(this.defaultStaffFilter) ||
      !this.filters.get('taskIds').equals(this.defaultTaskFilter) ||
      !this.filters.get('eventTypeIds').equals(this.defaultEventTypesFilter) ||
      !this.filters
        .get('commissionType')
        .equals(this.defaultCommissionFilter) ||
      !this.filters
        .get('paymentStatus')
        .equals(this.defaultPaymentStatusFilter) ||
      this.filters.get('attendeeId');

    this.emptyFilter =
      this.filters.get('staffIds').isEmpty() ||
      (this.filters.get('taskIds').isEmpty() &&
        this.filters.get('eventTypeIds').isEmpty() &&
        this.filters.get('commissionType').isEmpty() &&
        this.filters.get('paymentStatus').isEmpty());

    this.areMultiSelectFiltersPopulated =
      this.isEventTypesFilterPopulated &&
      this.isTasksFilterPopulated &&
      this.isStaffFilterPopulated;

    this.paginationOptions = Map({ page: 1, per_page: 250 });
    this.listPayrollEntries();

    // Manually emit a change here, so that the UI rerenders when using the
    // debounced function.
    this.emitChange();
  }

  toggleFilterDrawer() {
    this.filterDrawerOpen = !this.filterDrawerOpen;
  }

  paymentStatusHandler([entries, statusToChange]) {
    this.entries = entries;
    let payloadData = this.entries;
    if (List.isList(this.entries)) {
      this.statusToChange = statusToChange;
      payloadData = payloadData
        .groupBy(entry => entry.object_type)
        .map(each => each.map(entry => entry.id));
      payloadData = payloadData.toJS();
    } else {
      this.statusToChange = statusToChange;
      payloadData = { [payloadData.object_type]: [payloadData.id] };
    }
    PayrollReportSource.postPaidUnpaid({
      params: {
        attributes: {
          ...consolidatePayrollData(payloadData),
          status: this.statusToChange,
        },
      },
      success: PayrollReportActions.paymentStatusSuccess,
      error: PayrollReportActions.paymentStatusError,
    });
  }

  paymentStatusSuccess() {
    this.listPayrollEntries();
  }

  paymentStatusError(...args) {
    this.notifyError('error while toggling  payment statuses', args);
  }
}

export default alt.createStore(PayrollReportStore, 'PayrollReportStore');
