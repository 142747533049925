export default alt.generateActions(
  'acceptTerms',
  'create',
  'createError',
  'createSuccess',
  'fetch',
  'fetchError',
  'fetchSuccess',
  'list',
  'listError',
  'listSuccess',
  'staffSelected',
  'update',
  'updateStore',
  'updateError',
  'updateSuccess',
  'validateUser'
);
