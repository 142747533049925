import { Iterable, List, Record, Set, fromJS } from 'immutable';
import moment from 'moment-timezone';
import { formatDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import Discount from './Discount';
import DiscountableItem, { COUPON_DISCOUNTABLE } from './DiscountableItem';

const ONE_TIME = 'one_time';
const RECURRING = 'recurring';
const RECURRINGONCE = 'recurring_once';

export const REDEMPTION_LIMIT_STRATEGIES = {
  global: 'global',
  per_client: 'per_client',
};

class Coupon extends Record({
  id: null,
  all_items: false,
  code: '',
  coupon_type: ONE_TIME,
  description: '',
  discount_type: '',
  discountables: Set(),
  discountable_items: List(),
  value: 0.0,
  name: '',
  start_date: moment(),
  end_date: moment(),
  redemption_limit: null,
  redemption_limit_strategy: null,
  redemption_count: 0,
}) {
  constructor(obj = {}) {
    let newObj = obj || {};
    if (Iterable.isIterable(newObj)) {
      newObj = newObj.toJS();
    }

    if (newObj.start_date && typeof newObj.start_date === 'string') {
      newObj.start_date = moment(newObj.start_date);
    }

    if (newObj.end_date && typeof newObj.end_date === 'string') {
      newObj.end_date = moment(newObj.end_date);
    }
    newObj.discountable_items = fromJS(obj.discountable_items || []).map(
      di =>
        new DiscountableItem({
          item_id: di.get('item_id'),
          item_type: di.get('item_type'),
        })
    );

    newObj.discountables = Set(newObj.discountables);
    super({ ...newObj });
  }

  static get ONE_TIME() {
    return ONE_TIME;
  }

  static get RECURRING() {
    return RECURRING;
  }

  static get RECURRINGONCE() {
    return RECURRINGONCE;
  }

  applicable() {
    return this.discount.isValid();
  }

  dateText() {
    if (!this.start_date || !this.end_date) {
      return null;
    }
    return `${formatDate(this.start_date)} - ${formatDate(this.end_date)}`;
  }

  get discount() {
    return new Discount({ type: this.discount_type, value: this.value });
  }

  isOneTime() {
    return this.coupon_type === ONE_TIME;
  }

  isRecurring() {
    return this.coupon_type === RECURRING;
  }

  isDiscountableItemsSelected() {
    return !!this.discountables.size || !!this.discountable_items.size;
  }

  // This is more strict than it should be
  // For individual events and memberships there is a possibility to be both one-time and recurring
  canSwitchToType(type) {
    if (this.coupon_type === type || this.all_items) return true;
    let canSwitch = true;
    if (type === ONE_TIME) {
      canSwitch = this.discountable_items
        .filter(
          di =>
            di.item_type === COUPON_DISCOUNTABLE.Membership ||
            di.item_type === COUPON_DISCOUNTABLE.Event ||
            di.item_type === COUPON_DISCOUNTABLE.Team
        )
        .isEmpty();
    } else {
      canSwitch =
        Set([COUPON_DISCOUNTABLE.Variant, COUPON_DISCOUNTABLE.CreditPass])
          .intersect(this.discountables)
          .isEmpty() && this.discountable_items.isEmpty();
    }
    return canSwitch;
  }

  toServer() {
    if (this.redemption_limit === '') {
      return this.merge({
        redemption_limit: null,
        redemption_limit_strategy: null,
      }).toJS();
    }

    if (!this.redemption_limit_strategy && this.redemption_limit) {
      return this.set(
        'redemption_limit_strategy',
        REDEMPTION_LIMIT_STRATEGIES.per_client
      ).toJS();
    }

    return this.toJS();
  }
}

export default Coupon;
