class ContactsClientsActions {
  constructor() {
    this.generateActions(
      'mounted',
      'clientListSuccess',
      'clientListError',
      'pageSelected',
      'searchUpdated',
      'statusesUpdated',
      'clientArchived',
      'clientArchiveSuccess',
      'clientArchiveError',
      'updateContact',
      'downloadList',
      'downloadListSuccess',
      'downloadListError',
      'removeAppliedFilter'
    );
  }
}

export default alt.createActions(ContactsClientsActions);
