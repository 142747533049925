import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useNavigate, Navigate } from 'react-router-dom';
import { TextField } from '@upperhand/playmaker';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

import ThemeSidebar from 'client_theme/ThemeSidebar.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';

import { escapeStringRegexp } from 'shared/utils/StringUtils.jsx';
import { getRoles } from 'shared/utils/AuthUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { getCustomerRouteParts } from 'shared/utils/RouteUtils.js';

const styles = {
  smallScreen: {
    height: '100%',
    width: '100%',
    padding: '0 5rem',
    margin: '0 auto',
    backgroundColor: 'var(--color-white)',
  },

  largeScreen: {
    paddingLeft: '28rem',
    height: '100%',
    justifyContent: 'center',
    backgroundColor: 'var(--color-white)',
  },
};

class SelectFormWrapped extends React.Component {
  constructor(props) {
    super(props);
    const customers = getRoles().sortBy(o => o.get('customerName'));
    this.state = {
      filterString: '',
      visibleCustomers: customers,
      allCustomers: customers,
    };

    this.getListHeight = this.getListHeight.bind(this);
    this.handleCustomerSelection = this.handleCustomerSelection.bind(this);
    this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
  }

  handleFilterUpdate(_event, filterString) {
    const { allCustomers } = this.state;
    const regex = RegExp(escapeStringRegexp(filterString), 'i');

    const visibleCustomers = allCustomers.filter(c =>
      regex.test(c.get('customerName'))
    );

    this.setState({ filterString, visibleCustomers });
  }

  /* eslint-disable class-methods-use-this */
  // alt requires the use of a class method
  handleCustomerSelection(slug, roleType) {
    const { navigate, modalOpen } = this.props;

    if (modalOpen) {
      const { slug: currentSlug, url } = getCustomerRouteParts();
      const link = roleType === 'Client' ? 'my_profile' : 'dashboard';
      const newRoute = slug !== currentSlug ? link : url;

      window.location.href = `/customers/${slug}/${newRoute}`;
    } else {
      navigate(`/customers/${slug}/`);
    }
  }

  getListHeight() {
    const { allCustomers } = this.state;
    return Math.min(400, allCustomers.size * 48 + 16);
  }

  render() {
    const { allCustomers, filterString, visibleCustomers } = this.state;
    const { intl, modalOpen } = this.props;

    return (
      <div id="select-business" style={{ width: '100%' }}>
        <div style={{ fontSize: 20, margin: '20px 0' }}>
          <FormattedMessage
            id={messageId('.select_a_business', __filenamespace)}
          />
        </div>
        <Paper>
          {allCustomers.size > 8 && (
            <div>
              <TextField
                autoFocus
                fullWidth
                placeholder={t('.search', intl, __filenamespace)}
                value={filterString}
                outline={false}
                onChange={this.handleFilterUpdate}
              />
              <Divider />
            </div>
          )}

          <div
            style={{
              height: modalOpen ? '180px' : this.getListHeight(),
              overflowY: 'auto',
            }}
          >
            <List>
              {visibleCustomers.map(object => (
                <ListItem
                  disablePadding
                  key={object.get('customerId')}
                  value={object.get('slug')}
                  style={{ fontSize: 14 }}
                  onClick={() =>
                    this.handleCustomerSelection(
                      object.get('slug'),
                      object.get('roleType')
                    )
                  }
                >
                  <ListItemButton sx={{ padding: '15px' }}>
                    {object.get('customerName')}
                  </ListItemButton>
                </ListItem>
              ))}

              {visibleCustomers.isEmpty() && (
                <ListItem
                  disablePadding
                  disabled
                  disableKeyboardFocus
                  style={{ fontSize: 14 }}
                >
                  {t('.no_businesses_found', intl, __filenamespace)}
                </ListItem>
              )}
            </List>
          </div>
        </Paper>
      </div>
    );
  }
}

const SelectForm = injectIntl(SelectFormWrapped);

function LargeScreen({ children }) {
  return (
    <div style={{ height: '100%' }}>
      <ThemeSidebar />
      <FlexBoxCenter style={styles.largeScreen}>
        <div
          style={{
            margin: '0 20px',
            width: '100%',
            maxWidth: '28rem',
          }}
        >
          {children}
        </div>
      </FlexBoxCenter>
    </div>
  );
}

function CustomerAccountSelection(props) {
  let redirecting = false;
  const customers = getRoles();
  const count = customers.count();
  const navigate = useNavigate();
  const { redirect_to: redirectTo, onlySelectForm, modalOpen = false } = props;
  const { url, slug } = getCustomerRouteParts();

  if (redirectTo && !slug) {
    return <Navigate to={redirectTo} />;
  }

  if (count === 0 && !slug) {
    return <Navigate to={{ pathname: '/' }} />;
  }

  if (count === 1 && !slug) {
    return (
      <Navigate
        to={{ pathname: `/customers/${customers.first().get('slug')}/` }}
      />
    );
  }

  if (count === 1 && slug && customers.first().get('slug') === slug) {
    window.location.href = `/customers/${customers.first().get('slug')}/${url}`;
    redirecting = true;
  }

  if (count === 1 && slug && customers.first().get('slug') !== slug) {
    window.location.href = window.location.pathname;
    redirecting = true;
  }

  if (count > 1 && slug && !customers.some(c => c.get('slug') === slug)) {
    window.location.href = window.location.pathname;
    redirecting = true;
  }

  if (redirecting) {
    return (
      <Typography
        variant="body1"
        sx={{ display: 'inherit', textAlign: 'center' }}
      >
        Redirecting...
      </Typography>
    );
  }

  if (onlySelectForm) {
    return <SelectForm navigate={navigate} modalOpen={modalOpen} />;
  }

  return (
    <ResponsiveElement
      largeScreen={
        <LargeScreen>
          <SelectForm navigate={navigate} />
        </LargeScreen>
      }
      smallScreen={
        <FlexBoxCenter style={styles.smallScreen}>
          <SelectForm navigate={navigate} />
        </FlexBoxCenter>
      }
    />
  );
}

export default CustomerAccountSelection;
