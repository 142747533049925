import * as React from 'react';

import Button from '@mui/material/Button';

import Header from 'calendar/components/date_picker/_Header.jsx';
import Dates from 'calendar/components/date_picker/_Dates.jsx';
import DatePreview from 'calendar/components/date_picker/_DatePreview.jsx';

import { smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  PickerCalWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    width: '479px',
    backgroundColor: '#fff',
    fontSize: '15px',
  },
  PickerCalWrapperMobile: {
    display: 'block',
    width: '100vw',
    fontSize: '13px',
  },
  DatePreview: {
    backgroundColor: uhColors.activeBlue,
    color: '#fff',
    flexBasis: '34%',
    maxWidth: '34%',
    padding: '16px',
  },
  DatePreviewMobile: {
    maxWidth: 'initial',
    width: '100%',
  },
  DatePreviewYear: {
    fontSize: '1em',
    margin: '0 0 8px',
    opacity: '0.5',
  },
  DatePreviewText: {
    fontSize: '2.1333em',
  },
  DatePreviewTextMobile: {
    fontSize: '1.333em',
  },
  DatePreviewWeekTextMobile: {
    display: 'flex',
  },
  PickerCalendar: {
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: '65%',
    maxWidth: '65%',
    padding: '16px',
    textAlign: 'center',
  },
  PickerCalendarMobile: {
    width: '100%',
    maxWidth: 'initial',
  },
  CloseButton: {
    margin: '16px 0 0 auto',
  },
};

function CalendarContainer({
  intl,
  isMobile,
  currentDate,
  selectedDate,
  onSelectDate,
  onPreviousMonth,
  onNextMonth,
  handleRequestClose,
}) {
  return (
    <div
      style={merge(
        styles.PickerCalendar,
        isMobile ? styles.PickerCalendarMobile : {}
      )}
    >
      <Header
        currentDate={currentDate}
        onPreviousMonth={onPreviousMonth}
        onNextMonth={onNextMonth}
      />
      <Dates
        currentDate={currentDate}
        selectedDate={selectedDate}
        onSelectDate={onSelectDate}
      />
      <Button
        variant="text"
        onClick={handleRequestClose}
        style={styles.CloseButton}
      >
        {t('.close', intl, __filenamespace)}
      </Button>
    </div>
  );
}

class PickerCalendar extends React.Component {
  constructor(props) {
    super(props);

    const { currentDate } = this.props;

    this.state = { currentDate };
    this.nextMonth = this.nextMonth.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
  }

  nextMonth(e) {
    e.preventDefault();

    this.setState((prevState, _) => ({
      currentDate: prevState.currentDate.clone().add(1, 'month'),
    }));
  }

  previousMonth(e) {
    e.preventDefault();

    this.setState((prevState, _) => ({
      currentDate: prevState.currentDate.clone().subtract(1, 'month'),
    }));
  }

  render() {
    const { currentDate, intl, view, onSelectDate, handleRequestClose } =
      this.props;
    const { currentDate: stateCurrentDate } = this.state;

    return (
      <div
        className="PickerCalendar"
        style={merge(
          styles.PickerCalWrapper,
          smallScreen() ? styles.PickerCalWrapperMobile : {}
        )}
      >
        <DatePreview
          intl={intl}
          view={view}
          selectedDate={currentDate.clone()}
          handleRequestClose={handleRequestClose}
        />
        <CalendarContainer
          intl={intl}
          isMobile={smallScreen()}
          currentDate={stateCurrentDate}
          selectedDate={currentDate.clone()}
          onSelectDate={onSelectDate}
          onPreviousMonth={this.previousMonth}
          onNextMonth={this.nextMonth}
          handleRequestClose={handleRequestClose}
        />
      </div>
    );
  }
}

export default PickerCalendar;
