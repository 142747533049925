/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Divider from '@mui/material/Divider';

import EventHeader from 'event_mgmt/shared/components/_EventHeader.jsx';
import PurchaseButton from 'event_mgmt/display/components/_PurchaseButton.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  almostFull: {
    fontSize: 16,
    paddingBottom: 20,
  },

  largeHeader: {
    paddingBottom: 10,
  },

  largeHeaderContainer: {
    maxWidth: '50rem',
    margin: '0 30rem 0 3rem',
  },

  smallHeader: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: uhColors.headerGrey,
  },
};

function AlmostFull({ spotsRemaining, style }) {
  return (
    <div style={merge(styles.almostFull, style)}>
      <FormattedMessage
        id={messageId('.spaces_left', __filenamespace)}
        values={{ spots: spotsRemaining }}
      />
    </div>
  );
}

function LargeHeader({ event }) {
  return (
    <div>
      <EventHeader
        event={event}
        style={styles.largeHeader}
        containerStyle={styles.largeHeaderContainer}
      />
      <Divider />
    </div>
  );
}

function SmallHeader({
  almostFull,
  automations,
  event,
  isAvailableForPurchase,
  isAvailableForWaitlist,
  onPurchaseClick,
  onWaitlistClick,
  purchased,
  sessions,
  spotsRemaining,
}) {
  return (
    <div>
      <EventHeader event={event} />
      <div style={styles.smallHeader}>
        <PurchaseButton
          event={event}
          sessions={sessions}
          automations={automations}
          isAvailableForPurchase={isAvailableForPurchase}
          isAvailableForWaitlist={isAvailableForWaitlist}
          isPurchased={purchased}
          onPurchaseClick={onPurchaseClick}
          onWaitlistClick={onWaitlistClick}
          style={{ marginBottom: 10 }}
        />
        {almostFull && <AlmostFull spotsRemaining={spotsRemaining} />}
      </div>
    </div>
  );
}

function ClientEventHeader(props) {
  return (
    <ResponsiveElement
      largeScreen={<LargeHeader {...props} />}
      smallScreen={<SmallHeader {...props} />}
    />
  );
}

export default ClientEventHeader;
