import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStaffDrawerStore from 'event_mgmt/editing/stores/EventStaffDrawerStore.js';
import StaffIcon from 'shared/components/icons/Staff.jsx';
import uhTheme from '_uh_theme.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  subheader: {
    fontWeight: 'bold',
    fontSize: 15,
    color: uhTheme.palette.textColor,
    lineHeight: '36px',
    padding: '0 15px',
  },
};

function StaffPreferenceMenuContent({ intl, resource, selectedStaff }) {
  const [anchorEl, setAnchor] = React.useState(null);
  const resourcePreferringStaffIds = resource.preferring_staff
    .map(s => s.id)
    .toSet();

  return (
    <>
      <IconButton
        style={{ height: 24, width: 24, padding: 0 }}
        onClick={e => setAnchor(e.currentTarget)}
      >
        <StaffIcon
          className="StaffPreferenceButton"
          style={{ height: 18, width: 18, cursor: 'pointer' }}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchor()}>
        <Typography style={styles.subheader}>
          {t('.preferred_by', intl, __filenamespace)}
        </Typography>
        <Divider />
        <MenuItem
          onClick={() => EventActions.preferenceOverrideAllRemoved(resource.id)}
        >
          {t('.none', intl, __filenamespace)}
        </MenuItem>
        {selectedStaff
          .filter(staff => !resourcePreferringStaffIds.has(staff.id))
          .map(staff => (
            <MenuItem
              key={staff.id}
              onClick={() =>
                EventActions.preferenceOverrideAdded(resource.id, staff.id)
              }
            >
              {staff.name()}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

const StaffPreferenceMenu = injectIntl(props => (
  <AltContainer
    stores={{
      selectedStaff: () => ({
        store: EventStaffDrawerStore,
        value: EventStaffDrawerStore.getState().selectedStaff,
      }),
    }}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <StaffPreferenceMenuContent {...props} />
  </AltContainer>
));

export default StaffPreferenceMenu;
