import * as React from 'react';
import PropTypes from 'prop-types';

import altContainer from 'shared/hocs/altContainer.jsx';
import { getCurrentContext } from 'shared/utils/AuthUtils.js';

import ErrorDialogWithMessageWindow from 'shared/components/ErrorDialogWithMessageWindow.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import ClientActions from 'shared/actions/ClientActions.jsx';
import ClientStore from 'shared/stores/ClientStore.jsx';
import CustomerActions from 'shared/actions/CustomerActions.jsx';
import CustomerStore from 'shared/stores/CustomerStore.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import UserStore from 'shared/stores/UserStore.jsx';
import UserCreationActions from 'user_management/shared/actions/UserCreationActions.jsx';
import UserCreationStore from 'user_management/shared/stores/UserCreationStore.jsx';

import Content from './components/Content.jsx';

import CreateUserStore from './Store';

function CreateUser({
  currentContextStore: { isCustomerUserStoreLoading },
  clientStore,
  createUserStore: { isLoading, isCustomerUserLoading },
  customerStore,
  staffStore,
  userCreationStore,
  userStore,
  modalOpen,
}) {
  const { role, customerId, customerUserId } = getCurrentContext();

  const isClient = role === 'Client' || !role;
  // the default should be are we staff? if not then use client OR create new Client
  const customerUserActions = isClient ? ClientActions : StaffActions;

  React.useEffect(() => {
    if (customerUserId) {
      customerUserActions.fetch.defer(customerUserId);
    }
    CustomerActions.fetch.defer({
      id: customerId,
      fields: ['logo'],
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // If verified and customer user loaded => listManagedProfiles
  React.useEffect(() => {
    if (!isCustomerUserLoading) {
      customerUserActions.updateStore.defer({ login_enabled: true });
      UserCreationActions.listManagedProfiles.defer();
    }
  }, [isCustomerUserLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SpinWhileLoading
      contentContainerStyle={{ height: '100%' }}
      outerContainerStyle={{ height: '100%' }}
      isLoading={isLoading || isCustomerUserStoreLoading}
    >
      <Content
        customerStore={customerStore}
        customerUserActions={customerUserActions}
        isClient={isClient}
        customerUserStore={isClient ? clientStore : staffStore}
        userCreationStore={userCreationStore}
        userStore={userStore}
        modalOpen={modalOpen}
      />
      <ErrorDialogWithMessageWindow />
    </SpinWhileLoading>
  );
}

CreateUser.propTypes = {
  currentContextStore: PropTypes.shape({
    isCustomerUserStoreLoading: PropTypes.bool,
  }),
  clientStore: PropTypes.object,
  createUserStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    isCustomerUserLoading: PropTypes.bool,
  }),
  customerStore: PropTypes.object,
  staffStore: PropTypes.object,
  userCreationStore: PropTypes.object,
  userStore: PropTypes.object,
};

CreateUser.defaultProps = {
  currentContextStore: {},
  clientStore: {},
  createUserStore: {},
  customerStore: {},
  staffStore: {},
  userCreationStore: {},
  userStore: {},
};

export default altContainer({
  stores: {
    clientStore: ClientStore,
    createUserStore: CreateUserStore,
    customerStore: CustomerStore,
    staffStore: StaffStore,
    userCreationStore: UserCreationStore,
    userStore: UserStore,
  },
})(CreateUser);
