import { Record } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

class BalanceDetail extends Record({
  amount: null,
  effectiveAt: null,
  sourceId: null,
  type: null,
  dueDate: null,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({ ...parsedObject });
  }
}

export default BalanceDetail;
