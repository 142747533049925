import * as React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';
import { boldText } from 'shared/styles/uhStyles.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import TimeRangeList from 'shared/components/scheduling/TimeRangeList.jsx';

const styles = {
  legendContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toggle: {
    whiteSpace: 'nowrap',
  },
};

const weekdays = Map({
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
});

class AvailabilityTimePicker extends React.PureComponent {
  handleDayChangeTime = (keyPath, value) => {
    const { handleAvailabilityScheduleChange } = this.props;
    handleAvailabilityScheduleChange(['daytimes'].concat(keyPath), value);
  };

  handleUpdateDayTimes = (day, modifierCallback) => {
    const {
      schedule: { daytimesAreUnique, daytimes },
    } = this.props;

    if (daytimesAreUnique) {
      modifierCallback(daytimes.get(day), day, daytimes);
    } else {
      daytimes.forEach((times, weekday, _daytimes) => {
        modifierCallback(times, weekday, _daytimes);
      }, this);
    }
  };

  handleToggleDaySpecific = toggled => {
    const {
      handleAvailabilityScheduleChange,
      schedule: { daytimes },
    } = this.props;
    handleAvailabilityScheduleChange(['daytimesAreUnique'], toggled);

    if (!toggled) {
      this.handleDayChangeTime(
        [],
        daytimes.map(() => daytimes.first())
      );
    }
  };

  uniqueDayTimes = () => {
    const {
      schedule: { daytimesAreUnique, daytimes },
    } = this.props;

    return daytimesAreUnique ? daytimes : daytimes.toSet().toMap();
  };

  render() {
    const {
      allowAddTimeRange,
      fieldErrors,
      legend,
      legendStyle,
      schedule: { daytimesAreUnique },
      showDaySpecificToggle,
      style,
      toggleStyle,
    } = this.props;

    return (
      <div className="availability-time-picker" style={style}>
        <fieldset>
          <div style={styles.legendContainer}>
            <legend style={{ ...boldText, ...legendStyle }}>{legend}</legend>
            {showDaySpecificToggle && (
              <FormGroup>
                <FormControlLabel
                  control={<Switch style={merge(styles.toggle, toggleStyle)} />}
                  label={<Typography style={boldText}>Day specific</Typography>}
                  checked={daytimesAreUnique}
                  onChange={e => this.handleToggleDaySpecific(e.target.checked)}
                />
              </FormGroup>
            )}
          </div>
          {this.uniqueDayTimes()
            .sortBy((times, day) => day)
            .map((times, day) => (
              <TimeRangeList
                // eslint-disable-next-line react/no-array-index-key
                key={day}
                times={times}
                label={weekdays.get(day)}
                paperDepth={daytimesAreUnique ? 1 : 0}
                fieldErrors={fieldErrors}
                day={day}
                allowAddTimeRange={allowAddTimeRange}
                onUpdateDayTimes={this.handleUpdateDayTimes}
                onChangeDayTime={this.handleDayChangeTime}
              />
            ))
            .toIndexedSeq()}
        </fieldset>
      </div>
    );
  }
}

AvailabilityTimePicker.propTypes = {
  allowAddTimeRange: PropTypes.bool,
  fieldErrors: PropTypes.instanceOf(Map),
  handleAvailabilityScheduleChange: PropTypes.func,
  legend: PropTypes.string,
  legendStyle: PropTypes.object,
  schedule: PropTypes.instanceOf(AvailabilitySchedule).isRequired,
  showDaySpecificToggle: PropTypes.bool,
  style: PropTypes.object,
  toggleStyle: PropTypes.object,
};

AvailabilityTimePicker.defaultProps = {
  allowAddTimeRange: true,
  fieldErrors: Map(),
  handleAvailabilityScheduleChange: () => {},
  legend: '',
  legendStyle: {},
  showDaySpecificToggle: true,
  style: {},
  toggleStyle: {},
};

export default AvailabilityTimePicker;
