const CalendarActions = alt.generateActions(
  'checkIn',
  'checkOut',
  'checkAttendanceSuccess',
  'setCalendarDate',
  'setView',
  'list',
  'listSuccess',
  'listError',
  'toggleAvailableStaff',
  'toggleShowAvailability',
  'updateFuzzySearch',
  'clearFuzzySearch',
  'viewAttendeesClicked',
  'viewSessionClicked',
  'viewSessionClosed',
  'noShowHandler',
  'noShowSuccess',
  'filterDrawerOpen',
  'filterDrawerClose',
  'onFilterChange',
  'setStaffIds',
  'clearFilters',
  'listSessionsSuccess',
  'listSessionsError'
);

export default CalendarActions;
