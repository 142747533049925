import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Dropdown, Typography } from '@upperhand/playmaker';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { t } from 'shared/utils/LocaleUtils';

function MembershipTierSelector({
  fullWidth,
  disabled,
  classes,
  tiers,
  value,
  placeholder,
  label,
  onChange,
}) {
  const intl = useIntl();
  const tiersOptions = tiers.map(tier => ({
    label: (
      <div>
        <Typography variant="h6" className="tier-name">
          {tier.name}
        </Typography>
        <ul style={{ listStyleType: 'initial', paddingLeft: 20 }}>
          <li>
            <Typography>
              {tier.price === 0 ? (
                t('.free', intl, __filenamespace)
              ) : (
                <>
                  <FormattedCurrency fromCents value={tier.price} />
                  {tier.intervalMonths && <span>/{tier.intervalMonths}</span>}
                  {Boolean(tier.join_fee) && (
                    <span>
                      &nbsp;+&nbsp;
                      {t('.join_fee', intl, __filenamespace, {
                        fee: (
                          <FormattedCurrency fromCents value={tier.join_fee} />
                        ),
                      })}
                    </span>
                  )}
                </>
              )}
            </Typography>
          </li>
          <li>
            <Typography>
              {t('.commitment', intl, __filenamespace, {
                value: tier.commitmentMonths || 'No',
              })}
            </Typography>
          </li>
        </ul>
      </div>
    ),
    value: tier.id,
  }));

  return (
    <Dropdown
      fullWidth={fullWidth}
      disabled={disabled}
      classes={classes}
      label={label || t('.label', intl, __filenamespace)}
      items={tiersOptions}
      value={value}
      placeholder={placeholder || t('.placeholder', intl, __filenamespace)}
      onChange={onChange}
    />
  );
}

MembershipTierSelector.propTypes = {
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  tiers: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

MembershipTierSelector.defaultProps = {
  fullWidth: true,
  disabled: false,
  classes: {},
  tiers: [],
  value: '',
  placeholder: '',
  label: '',
  onChange: () => {},
};

export default memo(MembershipTierSelector);
