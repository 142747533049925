import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import AddCardIcon from '@mui/icons-material/AddCard';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { t } from 'shared/utils/LocaleUtils.js';

import CardIcon from 'containers/client/settings/billing/components/CardIcon.jsx';

import { FlexBox } from 'shared/components/FlexBox.jsx';

import PaymentActions from '../../Actions';
import { PaymentMethod } from '../../types';

const onSelectCard = cardMethod => {
  PaymentActions.changePaymentOption(cardMethod);
};

const cardDisplay = (card, intl) => {
  const maxWidth = card.default ? 150 : 200;

  return (
    <FlexBox>
      {card.default && (
        <span style={{ fontWeight: 'bold', marginRight: 5 }}>
          {t('.default', intl, __filenamespace)}
        </span>
      )}
      <span
        style={{
          display: 'inline-block',
          maxWidth,
          overflow: 'hidden',
          verticalAlign: 'middle',
          textOverflow: 'ellipsis',
          marginRight: 5,
          whiteSpace: 'nowrap',
        }}
      >
        {card.info.name}
      </span>
      {card.info.last4}
    </FlexBox>
  );
};

function CardSelectField({
  allowedPaymentMethods,
  currentPaymentMethod,
  paymentMethods,
  intl,
}) {
  const menuOptions = [];

  if (allowedPaymentMethods.has(PaymentMethod.CARD)) {
    menuOptions.push(
      <MenuItem key={PaymentMethod.CARD_NEW} value={PaymentMethod.CARD_NEW}>
        <ListItemIcon>
          <AddCardIcon />
        </ListItemIcon>
        <ListItemText>{t('.new_card', intl, __filenamespace)}</ListItemText>
      </MenuItem>
    );

    if (paymentMethods) {
      menuOptions.push(<Divider key="divider" />);

      paymentMethods.forEach(c => {
        menuOptions.push(
          <MenuItem key={c.id} value={c.id}>
            <ListItemIcon>
              <CardIcon brand={c.info.brand} />
            </ListItemIcon>
            <ListItemText>{cardDisplay(c, intl)}</ListItemText>
          </MenuItem>
        );
      });
    }
  }

  if (!allowedPaymentMethods.has(PaymentMethod.CARD)) {
    return (
      <FormattedMessage
        id={t('.card_method_is_not_allowed', intl, __filenamespace)}
      />
    );
  }

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel
        shrink
        style={{
          fontSize: 15,
          color: 'var(--color-old-gray)',
          fontWeight: 600,
        }}
      >
        {t('.choose_card', intl, __filenamespace)}
      </InputLabel>
      <Select
        MenuProps={{ disableEnforceFocus: true }}
        sx={{
          marginBottom: '10px',
          '.UH-MuiSelect-select': {
            display: 'flex',
            height: '30px',
            alignItems: 'center',
          },
        }}
        label={t('.choose_card', intl, __filenamespace)}
        onChange={e => onSelectCard(e.target.value)}
        value={
          currentPaymentMethod.id !== null
            ? currentPaymentMethod.id
            : PaymentMethod.CARD_NEW
        }
      >
        {menuOptions}
      </Select>
    </FormControl>
  );
}

export default injectIntl(CardSelectField);
