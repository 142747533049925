import React from 'react';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import BookingButton from 'shared/components/registration/BookingButton.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';

const styles = {
  bookedContainer: {
    height: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  bookedText: {
    fontSize: '18px',
    color: uhColors.charcoalBlack,
    fontWeight: '600',
  },
  selectedProfile: {
    width: '100%',
  },
  selectedProfileText: {
    color: uhColors.activeRepeatButton,
    fontSize: '15px',
  },
  profileMain: {
    width: '100%',
    height: '40px',
    marginTop: '8px',
    alignItems: 'center',
    display: 'flex',
  },
  profileContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '30px',
    height: '30px',
    background: uhColors.activeRepeatButton,
    borderRadius: '50%',
  },
  sessionDropdown: {
    marginTop: '20px',
    borderBottom: `1px solid ${uhColors.activeRepeatButton}`,
  },
  bottomBtn: {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    verticalAlign: 'middle',
    lineHeight: '90px',
    height: '90px',
  },
  profileName: {
    color: uhColors.textGrey,
    fontSize: '15px',
    alignSelf: 'center',
  },
};

function CompleteBooking({
  athletes,
  selectedClientId,
  bookableSessions,
  completeClicked,
}) {
  const selectedProfile = athletes.find(a => a.id === selectedClientId);
  const [sessionValue, setSessionValue] = React.useState(false);
  return (
    <div>
      <div style={styles.bookedContainer}>
        <span style={styles.bookedText}>You&apos;re Booked!</span>
      </div>
      <div style={styles.selectedProfile}>
        <span style={styles.selectedProfileText}>Selected Profile</span>
      </div>
      <FlexBoxCenter style={{ height: 43 }}>
        <UserAvatar user={selectedProfile} style={{ marginRight: '1rem' }} />
        {selectedProfile.name()}
      </FlexBoxCenter>
      <div style={{ marginTop: '20px' }}>
        <div
          role="presentation"
          onClick={() => setSessionValue(!sessionValue)}
          style={{
            borderBottom: `0.7px solid ${uhColors.activeRepeatButton}`,
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            {bookableSessions.length} Booked Sessions
          </span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '16px', fontWeight: '500' }}>Details</span>
            <IconButton onClick={() => setSessionValue(!sessionValue)}>
              <ChevronRightIcon sx={{ color: uhColors.activeBlue }} />
            </IconButton>
          </div>
        </div>
        {sessionValue &&
          bookableSessions?.map((data, i) => {
            const { starts_at: startAt } = data;
            const time = startAt.format('hh:mm a');
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex',
                  height: '40px',
                  padding: '10px',
                }}
              >
                {/* eslint-disable-next-line no-underscore-dangle */}
                <span>{startAt?._i}</span>
                <span>{time}</span>
              </div>
            );
          })}
        <div style={{ height: '20px' }} />
      </div>
      <div role="presentation" onClick={() => {}} style={styles.bottomBtn}>
        <BookingButton title="DONE!" handleClicked={completeClicked} />
      </div>
    </div>
  );
}

export default CompleteBooking;
