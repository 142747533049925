import moment from 'moment-timezone';
import { List, Record } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

const msInHour = 60 * 60 * 1000;

class TimeLog extends Record({
  id: null,
  attendee_names: List(),
  customer_user_id: null,
  event_type_name: null,
  event_name: null,
  location_id: null,
  task_id: null,
  start_time: null,
  end_time: null,
  duration: null,
  object_type: 'TimeLog',
  hourly_rate: 0,
  total: null,
  errors: new FieldErrors(),
  payment_status: null,
}) {
  constructor(obj = {}) {
    const startTime = obj.start_time
      ? moment.tz(obj.start_time, customerTZ())
      : undefined;
    const endTime = obj.end_time
      ? moment.tz(obj.end_time, customerTZ())
      : undefined;
    const attendeeNames = List(obj.attendee_names);
    // TODO: It seems like Immutable.Record transforms nested records into plain JS objects.
    // The possible workaround explicitly call record constructor
    // Looking for better idea how to handle this issue
    const { errors } = obj;

    super(
      merge(obj, {
        attendee_names: attendeeNames,
        start_time: startTime,
        end_time: endTime,
        errors: new FieldErrors(errors),
      })
    );
  }

  isPaid() {
    return this.payment_status === 'paid';
  }

  isUnpaid() {
    return this.payment_status === 'unpaid';
  }

  isValid() {
    return this.errors.isEmpty();
  }

  getDuration() {
    return this.end_time && this.start_time
      ? ((this.end_time - this.start_time) / msInHour).toFixed(1)
      : 0;
  }

  validate(managing = false) {
    let errors = new FieldErrors();
    let requiredFields = ['task_id', 'location_id'];
    if (managing) {
      requiredFields = requiredFields.concat([
        'customer_user_id',
        'start_time',
        'end_time',
      ]);
    }
    requiredFields.forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });
    if (
      this.get('start_time') &&
      this.get('end_time') &&
      moment(this.get('start_time')).isSameOrAfter(
        moment(this.get('end_time')),
        'seconds'
      )
    ) {
      errors = errors.add(
        'end_time',
        'records.errors.cant_be_before_start_date'
      );
    }
    return this.set('errors', errors);
  }

  toServer() {
    return {
      customer_user_id: this.customer_user_id,
      location_id: this.location_id,
      task_id: this.task_id,
      start_time: moment(this.start_time).format(),
      end_time: moment(this.end_time).format(),
      hourly_rate: this.hourly_rate,
    };
  }
}

export default TimeLog;
