import * as React from 'react';
import { Box, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  teamTypeBar: {
    width: '4px',
    border: '2px',
    borderRadius: '2px',
    position: 'absolute',
    left: '0px',
    top: '0px',
    bottom: '0px',
  },
};

function TeamTypeBar({ teamType }) {
  return (
    <Box sx={{ ...styles.teamTypeBar, backgroundColor: teamType.color }} />
  );
}

function SelectedTeamType({ teamType, onRemove, style }) {
  return (
    <Grid
      container
      justifyContent="start"
      sx={{
        ...style,
        position: 'relative',
        marginBottom: '10px',
        minHeight: '18px',
      }}
    >
      <TeamTypeBar teamType={teamType} />
      <Box sx={{ paddingLeft: '10px' }}>{teamType.name}</Box>
      {onRemove && (
        <IconButton
          iconStyle={{ height: '20px', width: '20px' }}
          onClick={() => onRemove(teamType.id)}
          sx={{
            padding: '0',
            height: '20px',
            width: '20px',
            position: 'absolute',
            right: '0px',
          }}
        >
          <ClearIcon sx={{ color: uhColors.lightGrey }} />
        </IconButton>
      )}
    </Grid>
  );
}

export default SelectedTeamType;
