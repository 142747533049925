import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Spinner } from '@upperhand/playmaker';
import clsx from 'clsx';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { TransactionsLedgerDataStore } from 'dataStores';
import BalanceDetailsDrawer from 'containers/reports/balanceDetailsDrawer/BalanceDetailsDrawer.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import PaymentPlanDetailsDrawer from 'containers/reports/paymentPlanDetailsDrawer/PaymentPlanDetailsDrawer.jsx';

import Header from './components/Header/Header.jsx';
import Table from './components/Table/Table.jsx';
import ColumnDrawer from './components/ColumnDrawer.jsx';
import FilterDrawer from './components/FilterDrawer.jsx';
import PaymentDetailsDrawer from '../paymentDetailsDrawer/PaymentDetailsDrawer.jsx';

import TransactionsLedgerReportActions from './Actions';
import TransactionsLedgerReportStore from './Store';

import './styles.scss';

function TransactionsLedgerReport({
  transactionsLedgerDataStore: { transactionsLedger },
  transactionsLedgerReportStore: {
    activeFilters,
    transactionLedgerIds,
    columns,
    defaultFilters,
    drawerFilters,
    filterErrors,
    isColumnDrawerOpen,
    isFilterDrawerChanged,
    isFilterDrawerOpen,
    isLoadingReport,
    isLoadingStatistics,
    page,
    perPage,
    statistics,
    totalCount,
  },
}) {
  const isMobile = smallScreen();

  React.useEffect(() => {
    TransactionsLedgerReportActions.mounted({ isMobile });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(
        'transactions-ledger-report',
        isMobile && 'transactions-ledger-report__mobile'
      )}
    >
      {isLoadingReport && isLoadingStatistics ? (
        <FlexBoxJustifyCenter>
          <Spinner />
        </FlexBoxJustifyCenter>
      ) : (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <Header
              defaultFilters={defaultFilters}
              filters={activeFilters}
              showHeaderTitle
              statistics={statistics}
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              filters={activeFilters}
              isLoadingReport={isLoadingReport}
              transactionLedgerIds={transactionLedgerIds}
              transactionsLedger={transactionsLedger}
              page={page}
              perPage={perPage}
              totalCount={totalCount}
              statistics={statistics}
              columns={columns}
            />
          </Grid>
        </Grid>
      )}
      <BalanceDetailsDrawer />
      <PaymentDetailsDrawer />
      <ColumnDrawer columns={columns} isOpen={isColumnDrawerOpen} />
      <FilterDrawer
        filters={drawerFilters}
        defaultFilters={defaultFilters}
        filterErrors={filterErrors}
        isOpen={isFilterDrawerOpen}
        isChanged={isFilterDrawerChanged}
      />
      <PaymentPlanDetailsDrawer withBackLink />
      <QuickpayModal />
    </div>
  );
}

TransactionsLedgerReport.propTypes = {
  transactionsLedgerDataStore: PropTypes.object,
  transactionsLedgerReportStore: PropTypes.object,
};

TransactionsLedgerReport.defaultProps = {
  transactionsLedgerDataStore: {},
  transactionsLedgerReportStore: {},
};

export default altContainer({
  stores: {
    transactionsLedgerDataStore: TransactionsLedgerDataStore,
    transactionsLedgerReportStore: TransactionsLedgerReportStore,
  },
})(TransactionsLedgerReport);
