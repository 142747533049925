import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import {
  LocationDataStore,
  ResourceDataStore,
  StaffDataStore,
} from 'dataStores';

import AltContainer from 'alt-container';

import FutureEventSchedulesActions from './Actions';
import FutureEventSchedulesStore from './Store';

import EventScheduleHeader from '../shared/EventScheduleHeader.jsx';
import TeamScheduleHeader from '../shared/TeamScheduleHeader.jsx';
import ScheduleList from './components/ScheduleList.jsx';

function EventSchedules({
  eventId,
  eventSchedulesStore: {
    filtersPresent,
    isLoaded,
    isLoading,
    locationFilter,
    page,
    perPage,
    resourceFilter,
    scheduleIds,
    staffFilter,
    staffIds,
    totalCount,
  },
  schedulesActions: {
    locationFilterUpdated,
    mounted,
    pageSelected,
    resourceFilterUpdated,
    staffFilterUpdated,
  },
  onToggleViewMode,
  intl,
  locationDataStore: { records: locationMap },
  resourceDataStore: { records: resourceMap },
  scheduleType,
  sessionRange,
  staffDataStore: { staff: staffMap },
  isTeamEvent,
}) {
  React.useEffect(() => {
    if (!isLoaded) {
      mounted({ eventId });
    }
  }, [eventId, isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const locationItems = locationMap
    .map(l => ({
      label: l.name || '',
      value: l.id,
    }))
    .toArray();

  const staffItems = staffIds.size
    ? staffIds
        .map(id => ({
          label: `Staff: ${staffMap.get(id, { name: () => '' }).name()}`,
          value: id,
        }))
        .toArray()
    : [];

  const resourceItems = resourceMap
    .map(r => ({
      label: `Resource: ${r.name || ''}`,
      value: r.id,
    }))
    .toArray();

  return (
    <Box
      className="Admin-Schedule-Tab"
      sx={{
        padding: '12px 0',
        overflow: 'hidden',
      }}
    >
      <Grid alignItems="center" container spacing={1}>
        {isTeamEvent ? (
          <TeamScheduleHeader
            intl={intl}
            eventId={eventId}
            locationFilter={locationFilter}
            locationFilterUpdated={locationFilterUpdated}
            locationItems={locationItems}
            resourceFilter={resourceFilter}
            resourceFilterUpdated={resourceFilterUpdated}
            resourceItems={resourceItems}
            staffFilter={staffFilter}
            staffFilterUpdated={staffFilterUpdated}
            staffItems={staffItems}
            onToggleViewMode={onToggleViewMode}
          />
        ) : (
          <EventScheduleHeader
            intl={intl}
            locationFilter={locationFilter}
            locationItems={locationItems}
            resourceFilter={resourceFilter}
            resourceItems={resourceItems}
            staffItems={staffItems}
            staffFilter={staffFilter}
            staffFilterUpdated={staffFilterUpdated}
            locationFilterUpdated={locationFilterUpdated}
            resourceFilterUpdated={resourceFilterUpdated}
            onToggleViewMode={onToggleViewMode}
          />
        )}
        <ScheduleList
          filtersPresent={filtersPresent}
          isLoading={isLoading}
          onPageSelect={pageSelected}
          page={page}
          perPage={perPage}
          scheduleIds={scheduleIds}
          scheduleType={scheduleType}
          sessionRange={sessionRange}
          totalCount={totalCount}
          isTeamEvent={isTeamEvent}
        />
      </Grid>
    </Box>
  );
}

const FutureEventSchedules = injectIntl(props => (
  <AltContainer
    stores={{
      eventSchedulesStore: FutureEventSchedulesStore,
      locationDataStore: LocationDataStore,
      staffDataStore: StaffDataStore,
      resourceDataStore: ResourceDataStore,
    }}
    actions={{
      schedulesActions: FutureEventSchedulesActions,
    }}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <EventSchedules {...props} />
  </AltContainer>
));

export default FutureEventSchedules;
