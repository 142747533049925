import * as React from 'react';

import { injectIntl } from 'react-intl';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { Typography } from '@mui/material';
import { isNative } from 'shared/utils/UserAgentUtils';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import Browser from 'shared/native/Browser';

const styles = {
  container: {
    marginTop: '10px',
    marginLeft: `${LEFT_MARGIN_PIXELS}px`,
  },
  cardContainer: {
    width: '300px',
    marginTop: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    padding: '36px 24px',
  },
  cardText: {
    marginBottom: '20px',
  },
  arVisionButton: {
    color: uhColors.textWhite,
    margin: '32px 0 10px 0',
  },
};

function ARVisionTab({ intl }) {
  const isOnDevice = isNative();

  return (
    <Grid container sx={styles.container}>
      <Grid item>
        <Paper sx={styles.cardContainer}>
          <Typography variant="h4" fontWeight="bold" style={styles.cardText}>
            {t('.header', intl, __filenamespace)}
          </Typography>
          <Typography variant="body1" fontSize={16} style={styles.cardText}>
            {t('.card_text', intl, __filenamespace)}
          </Typography>
          <Button
            style={styles.arVisionButton}
            fullWidth
            variant="contained"
            onClick={() => {
              if (isOnDevice) {
                Browser.open('pendo-ced707c0://');
              } else {
                window.open(
                  'https://apps.apple.com/us/app/athletic-republic-vision/id1541936144',
                  '_blank'
                );
              }
            }}
          >
            {t('.login', intl, __filenamespace)}
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default injectIntl(ARVisionTab);
