import * as React from 'react';
import moment from 'moment-timezone';

import Divider from '@mui/material/Divider';
import { Card, Typography } from '@upperhand/playmaker';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const expirationDate = (month, year) =>
  moment(`${month}-${year}`, 'M-YYYY').format('MM/YYYY');

// NOTE: semantically improper use of PM, trying to cut down on extra styles ATM
// TODO: Update to new designs both large and small.

function CardInfoCard({
  brand,
  cardholderName,
  expMonth,
  expYear,
  identifyingDigits,
}) {
  return (
    <div style={{ maxWidth: SINGLE_COLUMN_WIDTH, margin: '1em 0' }}>
      <Card borderPosition="left" borderColor={uhColors.green}>
        <FlexBoxJustify style={{ padding: '1em 0' }}>
          <Typography variant="h5">
            <b style={{ textTransform: 'capitalize' }}>{brand}</b>
            <Typography color="textSecondary" display="inline" variant="body2">
              &nbsp;ending in...
            </Typography>
          </Typography>
          <Typography variant="h5">{identifyingDigits}</Typography>
        </FlexBoxJustify>
        <Divider />
        <FlexBoxJustify style={{ padding: '1em 0' }}>
          <Typography variant="h5">{cardholderName}</Typography>
          <Typography variant="h5">
            {expirationDate(expMonth, expYear)}
          </Typography>
        </FlexBoxJustify>
      </Card>
    </div>
  );
}

export default CardInfoCard;
