// eslint-disable-next-line max-classes-per-file
import moment from 'moment-timezone';
import { List, Record } from 'immutable';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import { asArray, isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

class UBOAddress extends Record({
  city: '',
  state: '',
  street: '',
  street2: '',
  zip: '',
}) {}

class PaysafeUBO extends Record({
  address: new UBOAddress(),
  date_of_birth: '',
  first_name: '',
  job_title: '',
  last_name: '',
  percentage_ownership: '',
  phone: '',
  ssn: '',
  is_applicant: false,
  is_control_prong: false,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    super(merge(obj, { address: new UBOAddress(obj.address || {}) }));
  }

  validate() {
    let errors = new FieldErrors();

    const requiredFields = List([
      'date_of_birth',
      'first_name',
      'job_title',
      'last_name',
      'percentage_ownership',
      'phone',
      'ssn',
      ['address', 'city'],
      ['address', 'state'],
      ['address', 'street'],
      ['address', 'zip'],
    ]);

    requiredFields.forEach(keyPath => {
      if (!isPresent(this.getIn(asArray(keyPath)))) {
        errors = errors.add(keyPath, 'records.errors.required');
      }
    });

    if (this.is_applicant === null || this.is_applicant === undefined) {
      errors = errors.add('is_applicant', 'records.errors.required');
    }

    if (this.is_control_prong === null || this.is_control_prong === undefined) {
      errors = errors.add('is_control_prong', 'records.errors.required');
    }

    if (isPresent(this.ssn) && !this.ssn.match(/^\d{9}$/)) {
      errors = errors.add(
        'ssn',
        'records.PaysafeUBO.errors.invalid_ssn_format'
      );
    }

    if (
      isPresent(this.date_of_birth) &&
      !this.date_of_birth.match(/^\d{2}\/\d{2}\/\d{4}$/)
    ) {
      errors = errors.add(
        'date_of_birth',
        'records.PaysafeUBO.errors.invalid_dob_format'
      );
    }

    if (
      isPresent(this.address.zip) &&
      !this.address.get('zip').match(/^\d{5}(?:[-\s]\d{4})?$/)
    ) {
      errors = errors.add(
        ['address', 'zip'],
        'records.PaysafeUBO.errors.invalid_zip_format'
      );
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  toServer() {
    const obj = this.toJS();

    obj.date_of_birth = moment(obj.date_of_birth, 'MM/DD/YYYY').format(
      'YYYY-MM-DD'
    );

    delete obj.errors;

    return obj;
  }
}

export default PaysafeUBO;
