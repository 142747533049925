class CreditPassSpecificEventsListActions {
  constructor() {
    this.generateActions(
      'listSuccess',
      'listError',
      'addSpecificEvent',
      'removeSpecificEvent'
    );
  }
}

export default alt.createActions(CreditPassSpecificEventsListActions);
