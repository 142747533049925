import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Set } from 'immutable';

import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import CardMenu from 'shared/components/CardMenu.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import OpenBookingCardContent from 'shared/components/checkout/OpenBookingCardContent.jsx';
import FixedScheduleCardContent from 'shared/components/checkout/FixedScheduleCardContent.jsx';

import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxJustify,
} from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { redirectTo } from 'shared/utils/RouteUtils';
import { sumInt } from 'shared/utils/ImmutableUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const styles = {
  orderItemCard: {
    width: '30rem',
    position: 'relative',
  },
  header: {
    backgroundColor: '#3a3c44',
    color: 'white',
    padding: '1.5rem',
    fontSize: '12pt',
  },
  cardContent: {
    padding: '1.5rem',
    fontSize: '12pt',
  },
  deleteBtn: {
    top: 0,
    right: 0,
    color: 'var(--color-white)',
    margin: 'auto',
    position: 'absolute',
  },
};

function PaymentPlanText({ plan, quantity = 1 }) {
  const dueImmediately = plan.paymentsDueImmediately();
  const dueImmediatelyTotal = dueImmediately.reduce(sumInt('amount'), 0);
  const displayedTotal =
    dueImmediatelyTotal === 0
      ? quantity * plan.payments.first().get('amount')
      : quantity * dueImmediatelyTotal;

  return (
    <FormattedMessage
      id={messageId('.payment_plan_summary', __filenamespace)}
      values={{
        paymentsDueAtCheckout: (quantity * dueImmediately.size).toString(),
        totalPayments: (quantity * plan.payments.size).toString(),
        displayedTotal: <FormattedCurrency value={displayedTotal} fromCents />,
      }}
    />
  );
}

const getParticipatingProfiles = (orderItem, allProfiles) => {
  let participantIds = Set();

  if (orderItem.isEventItem()) {
    participantIds = orderItem.orderable.client_ids;
  } else if (orderItem.isMembershipItem()) {
    participantIds = orderItem.orderable.customer_user_ids;
  } else if (orderItem.isCreditPassItem()) {
    return allProfiles.filter(p => orderItem.orderable.client_ids.has(p.id));
  }

  return allProfiles.filter(p => participantIds.has(p.id));
};

const getPaymentPlanDescription = (orderItem, automations) => {
  const { orderable } = orderItem;

  if (
    !orderable.automation_template_description_id ||
    !orderable.automation_option_uuid
  ) {
    return null;
  }

  return automations.find(
    atd =>
      atd.isRecurringPaymentPlan() &&
      atd.id === orderable.automation_template_description_id
  );
};

const getPaymentPlan = (orderItem, paymentPlanDescription) => {
  if (paymentPlanDescription) {
    return paymentPlanDescription.getPlan(
      orderItem.orderable.automation_option_uuid
    );
  }
  return null;
};

function RegisterableItemTitle({ onView, viewHref, displayText }) {
  const linkstyle = {
    display: 'inline-block',
    marginBottom: '5px',
    color: 'white',
  };
  return viewHref ? (
    <Link to={viewHref} onClick={onView} style={linkstyle}>
      {displayText}
    </Link>
  ) : (
    <p
      role="presentation"
      onClick={onView}
      onKeyPress={() => null}
      style={{
        ...linkstyle,
        ...{ cursor: 'pointer' },
      }}
    >
      {displayText}
    </p>
  );
}

function RegisterableItemHeader({
  showProfiles,
  orderItem,
  onView,
  profiles,
  viewHref,
}) {
  const isTieredMembership = orderItem.getIn(
    ['orderable', 'membership_tier_id'],
    false
  );
  const itemName = isTieredMembership
    ? orderItem.getIn(['orderable', 'subscribable_name'], '')
    : orderItem.primary_display_text;

  return (
    <div style={styles.header}>
      <RegisterableItemTitle
        viewHref={viewHref}
        onView={onView}
        displayText={itemName}
      />
      <FlexBoxJustify style={{ marginBottom: showProfiles ? '1rem' : 0 }}>
        <div>{orderItem.secondary_display_text}</div>
        <div>
          {currentUser().isClient() && (
            <div style={{ fontWeight: 700 }}>
              <span>Qty</span> {orderItem.display_quantity}
            </div>
          )}
          {currentUser().isStaff() && (
            <div>
              <span>{orderItem.secondary_display_accent_text}</span>
            </div>
          )}
        </div>
      </FlexBoxJustify>

      {showProfiles && (
        <>
          <Divider
            style={{ backgroundColor: '#868686', marginBottom: '1rem' }}
          />

          {profiles.map(profile => (
            <FlexBoxCenter style={{ marginBottom: '0.5rem' }} key={profile.id}>
              <UserAvatar
                size={30}
                style={{ marginRight: '1rem' }}
                user={profile}
                darkOnLight
              />
              {profile.name()}
            </FlexBoxCenter>
          ))}
        </>
      )}
    </div>
  );
}

function RetailItemHeader({ orderItem }) {
  return (
    <div>
      <FlexBox style={{ padding: 15, fontSize: 16 }}>
        <img
          src={orderItem.orderable.image.getAlternative('thumb_large')}
          alt={orderItem.orderable.primary_display_text}
          height={90}
          width={90}
          style={{ marginRight: 15, objectFit: 'contain' }}
        />
        <div>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            {orderItem.primary_display_text}
          </div>
          <div style={{ color: uhColors.textGrey }}>
            {orderItem.secondary_display_text}
          </div>
        </div>
      </FlexBox>
      <Divider style={{ height: 2, marginLeft: 15, marginRight: 15 }} />
    </div>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
function CardHeader(props) {
  if (
    props.orderItem.isEventItem() ||
    props.orderItem.isMembershipItem() ||
    props.orderItem.isCreditPassItem()
  ) {
    return <RegisterableItemHeader {...props} />;
  }
  if (props.orderItem.isRetailItem()) {
    return <RetailItemHeader {...props} />;
  }
  return null;
}

function ItemCard({
  isBeforeCheckout,
  staff,
  allProfiles,
  automations,
  editHref,
  onEdit,
  onRemove,
  onView,
  orderItem,
  style,
  viewHref,
  onUpdate,
  membershipEventDiscounts,
  sessions,
  event,
}) {
  const profiles = getParticipatingProfiles(orderItem, allProfiles);
  const paymentPlanDescription = getPaymentPlanDescription(
    orderItem,
    automations
  );
  const paymentPlan = getPaymentPlan(orderItem, paymentPlanDescription);
  const atds = automations.filter(
    a => a && a.event_id === orderItem.orderable.event_id
  );
  const packagePricing = atds.find(atd => atd.isPackagePricing()) || null;
  const isOpenBooking = orderItem.isOpenBookingItem();
  const isFixedSchedule = orderItem.isFixedScheduleItem();

  return (
    <Paper style={merge(styles.orderItemCard, style)}>
      {!isBeforeCheckout && (
        <CardMenu>
          <MenuItem
            onClick={() => {
              if (onView) onView();
              if (viewHref) redirectTo({ path: viewHref });
            }}
          >
            View
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (onEdit) onEdit();
              if (editHref) redirectTo({ path: editHref });
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={onRemove}>Remove</MenuItem>
        </CardMenu>
      )}

      {isBeforeCheckout && (
        <IconButton sx={styles.deleteBtn} onClick={onRemove}>
          <Close />
        </IconButton>
      )}

      <CardHeader
        showProfiles={!isOpenBooking || !isBeforeCheckout}
        orderItem={orderItem}
        editHref={editHref}
        onEdit={onEdit}
        onRemove={onRemove}
        onView={onView}
        profiles={profiles}
        viewHref={viewHref}
      />

      {isOpenBooking && (
        <OpenBookingCardContent
          membershipEventDiscounts={membershipEventDiscounts}
          showEditingIfo={isBeforeCheckout}
          orderItem={orderItem}
          profiles={profiles}
          staff={staff}
          packagePricing={packagePricing}
          event={event}
          onUpdate={onUpdate}
        />
      )}

      {isFixedSchedule && (
        <FixedScheduleCardContent orderItem={orderItem} sessions={sessions} />
      )}

      <Divider />

      <FlexBoxJustify style={styles.cardContent}>
        <div>Total</div>
        <div style={{ fontWeight: 700 }}>
          {paymentPlan && (
            <PaymentPlanText
              plan={paymentPlan}
              quantity={orderItem.display_quantity}
            />
          )}
          <FormattedCurrency value={orderItem.total} fromCents />
        </div>
      </FlexBoxJustify>
    </Paper>
  );
}

export default ItemCard;
