export default alt.generateActions(
  'saveOrUpdateSuccess',
  'clearUI',
  'createOrUpdate',
  'error',
  'fetch',
  'productFetchSuccess',
  'productFetchError',
  'publishError',
  'updateStore',
  'updateUI',
  'validateAndSave',
  'retailVendorSelected',
  'retailCategorySelected',
  'newOptionType',
  'newOptionValue',
  'optionTypeNameUpdated',
  'optionTypeRemoved',
  'optionValueValueUpdated',
  'optionValueSaved',
  'optionValueStaged',
  'optionValueRemoved',
  'productReset',
  'publish'
);
