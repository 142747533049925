import * as React from 'react';
import AltContainer from 'alt-container';

export default ({ stores, store, actions }) =>
  WrappedComponent => {
    function Component(props) {
      return (
        <AltContainer stores={stores} store={store} actions={actions}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <WrappedComponent {...props} />
        </AltContainer>
      );
    }

    return Component;
  };
