import * as React from 'react';
import PropTypes from 'prop-types';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import PaymentMethodUsageField from 'containers/payments/components/fields/PaymentMethodUsageField.jsx';
// eslint-disable-next-line import/no-cycle
import NewCardForm from './NewCardForm.jsx';
import { CardSelectField } from '../fields';
import { PaymentMethod } from '../../types';
import { SavedCardMessage } from '../messages';

function CardNotPresentWrapper({
  allowedPaymentMethods,
  cardUsage,
  hasAccess,
  paymentCard,
  paymentMethod,
  paymentMethods,
}) {
  return (
    <>
      <CardSelectField
        allowedPaymentMethods={allowedPaymentMethods}
        currentPaymentMethod={paymentCard}
        paymentMethods={paymentMethods}
      />
      {paymentMethod === PaymentMethod.CARD_NEW && (
        <div>
          <NewCardForm
            cardRecord={paymentCard.info}
            style={{
              marginTop: 15,
              display: 'grid',
              gridRowGap: 15,
              gridAutoFlow: 'row',
            }}
          />
          <FlexBoxJustify style={{ flexWrap: 'wrap', margin: '0 -5px' }}>
            <PaymentMethodUsageField
              cardUsage={cardUsage}
              hasAccess={hasAccess}
            />
          </FlexBoxJustify>
        </div>
      )}
      {paymentMethod !== PaymentMethod.CARD_NEW && (
        <SavedCardMessage cardRecord={paymentCard} />
      )}
    </>
  );
}

CardNotPresentWrapper.propTypes = {
  allowedPaymentMethods: PropTypes.object.isRequired,
  cardUsage: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  paymentCard: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  paymentMethods: PropTypes.object.isRequired,
};

export default CardNotPresentWrapper;
