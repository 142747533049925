import { List } from 'immutable';
import { BrowseEvent } from 'records';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import BrowseEventActions from './BrowseEventActions';

const parserTo = {
  type: BrowseEvent,
  paginationKey: 'events',
};

const parser = new Parser(parserTo);

export const list = ({ type = 'list', params, success, error }) => {
  uhApiClient.get({
    url: `compound_objects/browse_events_${type}`,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([BrowseEventActions.listSuccess, success])
    ),
    error: onError(List([BrowseEventActions.listError, error])),
  });
};

export const listSportType = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'sport_types',
    data: params,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};
