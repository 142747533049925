import { List, Map, Set } from 'immutable';

import CreditGrant from 'shared/records/CreditGrant.js';
import CreditGrantsActions from 'shared/actions/CreditGrantsActions.js';
import EnrolledEventListActions from 'user_management/shared/actions/EnrolledEventListActions.jsx';
import Registration from 'shared/records/Registration';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class CreditGrantsStore extends UpperHandStore {
  constructor() {
    super();
    this.creditGrants = Map();
    this.loadingIds = List();
    this.isLoading = false;

    this.bindListeners({
      listGrants: CreditGrantsActions.LIST,
      handleListSuccess: CreditGrantsActions.LIST_SUCCESS,
      handleListError: CreditGrantsActions.LIST_ERROR,
      handleRegistrationScheduled: RegistrationActions.SCHEDULE_SUCCESS,
      handleRegistrationCancelled: [
        RegistrationActions.CANCEL_SUCCESS,
        EnrolledEventListActions.cancelRegistrationSuccess,
      ],
    });
  }

  listGrants({ clientIds, page = 1 }) {
    this.isLoading = true;

    return uhApiClient.get({
      url: `/credit_grants`,
      data: {
        client_ids: clientIds.toArray(),
        expired: false,
        page,
        per_page: 50,
      },
      success: {
        action: CreditGrantsActions.listSuccess,
        args: [clientIds],
      },
      error: CreditGrantsActions.listError,
    });
  }

  handleListSuccess([
    { page, per_page: perPage, total_count: totalCount, credit_grants: grants },
    clientIds,
  ]) {
    const fetchedGrants = List(grants.map(cg => new CreditGrant(cg)));

    this.creditGrants = this.creditGrants.mergeDeep(
      fetchedGrants.groupBy(grant => grant.client_id).toMap()
    );

    if (page * perPage < totalCount) {
      this.listGrants({ clientIds, page: page + 1 });
    } else {
      this.isLoading = false;
    }
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('Error while listing credit_grants ', args);
  }

  handleRegistrationScheduled(data) {
    const registrations = data
      .map(r => new Registration(r))
      .filter(r => r.isCreditBased());

    if (registrations.length > 0) {
      const clientIds = registrations.map(r => r.client_id);

      this.listGrants({ clientIds: Set(clientIds) });
    }
  }

  handleRegistrationCancelled([_, data]) {
    const registration = new Registration(data);

    if (!registration.isCreditBased()) {
      return;
    }

    this.listGrants({ clientIds: Set([registration.client_id]) });
  }
}

export default alt.createStore(CreditGrantsStore, 'CreditGrantsStore');
