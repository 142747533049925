import * as React from 'react';
import { v1 as uuidv1 } from 'uuid';
import moment from 'moment-timezone';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  PickerHeader: {
    width: '100%',
  },
  MonthSelect: {
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 0 8px',
    fontWeight: '600',
  },
  WeekdayLabels: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    margin: '0 0 8px',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  WeekdayLabel: {
    flex: '1',
    maxWidth: 'calc(100% / 7)',
  },
};

const weekdays = moment.weekdaysMin();

function PickerHeader({ currentDate, onPreviousMonth, onNextMonth }) {
  return (
    <header style={styles.PickerHeader}>
      <div style={styles.MonthSelect}>
        <IconButton
          sx={{ color: uhColors.activeBlue }}
          onClick={onPreviousMonth}
        >
          <ChevronLeftIcon />
        </IconButton>
        {currentDate.format('MMMM YYYY')}
        <IconButton sx={{ color: uhColors.activeBlue }} onClick={onNextMonth}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <div style={styles.WeekdayLabels}>
        {weekdays.map(weekday => (
          <div key={`WeekdayLabel-${uuidv1()}`} style={styles.WeekdayLabel}>
            {weekday}
          </div>
        ))}
      </div>
    </header>
  );
}

export default PickerHeader;
