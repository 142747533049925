import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function RetailCategoryList({ accountingCode, onRemove }) {
  return (
    <div>
      {accountingCode.retail_categories.map(retailCategory => (
        <div key={retailCategory.id}>
          <FlexBoxCenter
            style={merge({
              position: 'relative',
              marginBottom: 10,
              minHeight: 18,
            })}
          >
            <div style={{ fontWeight: 'bold' }}>{retailCategory.name}</div>
            {onRemove && (
              <IconButton
                onClick={() => onRemove(retailCategory.id)}
                style={{
                  padding: '0',
                  height: '20px',
                  width: '20px',
                  position: 'absolute',
                  right: 0,
                }}
              >
                <ClearIcon
                  sx={{
                    color: uhColors.lightGrey,
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>
            )}
          </FlexBoxCenter>
        </div>
      ))}
    </div>
  );
}

export default RetailCategoryList;
