class BrowseTeamsActions {
  constructor() {
    this.generateActions(
      'closeFiltersDrawer',
      'teamTypeFilterUpdated',
      'listTypeError',
      'listTeamTypeSuccess',
      'listTeamTypeError',
      'listTeams',
      'listTeamsError',
      'listTeamsSuccess',
      'listStaffError',
      'listStaffSuccess',
      'mounted',
      'openFiltersDrawer',
      'staffFilterUpdated',
      'searchFilterUpdated'
    );
  }
}

export default alt.createActions(BrowseTeamsActions);
