class MembershipOverviewDrawerActions {
  constructor() {
    this.generateActions(
      'open',
      'close',
      'fetchTier',
      'fetchTierSuccess',
      'fetchTierError',
      'listBenefitsInfo'
    );
  }
}

export default alt.createActions(MembershipOverviewDrawerActions);
