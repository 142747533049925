import { OrderedSet } from 'immutable';

import MembershipTier from 'shared/records/MembershipTier';

import { MembershipTierSource } from 'sources';

import MembershipTiersActions from 'memberships/actions/MembershipTiersActions';
import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import BaseDiscountsStore from './BaseDiscountsStore';

class MembershipTiersStore extends BaseDiscountsStore {
  constructor(args) {
    super(args);
    this.reset();
    this.bindListeners({
      mounted: MembershipTiersActions.mounted,

      addTier: MembershipTiersActions.addTier,
      editTier: MembershipTiersActions.editTier,

      listMembershipTiersSuccess:
        MembershipTiersActions.listMembershipTiersSuccess,
      listMembershipTiersError: MembershipTiersActions.listMembershipTiersError,

      cancelTier: MembershipTiersActions.cancelTier,
      saveTier: MembershipTiersActions.saveTier,
      saveTierSuccess: MembershipTiersActions.saveTierSuccess,
      saveTierError: MembershipTiersActions.saveTierError,

      fieldChanged: MembershipTiersActions.fieldChanged,

      exclusiveEventTypesExpanded:
        MembershipTiersActions.exclusiveEventTypesExpanded,

      toggleBestValue: MembershipTiersActions.toggleBestValue,
      toggleBestValueSuccess: MembershipTiersActions.toggleBestValueSuccess,
      toggleBestValueError: MembershipTiersActions.toggleBestValueError,

      showDeleteConfirmation: MembershipTiersActions.showDeleteConfirmation,
      deleteTier: MembershipTiersActions.deleteTier,
      deleteTierSuccess: MembershipTiersActions.deleteTierSuccess,
      deleteTierError: MembershipTiersActions.deleteTierError,

      cloneTier: MembershipTiersActions.cloneTier,

      toggleCreationDrawer: MembershipTiersActions.toggleCreationDrawer,

      toggleJoinFee: MembershipTiersActions.toggleJoinFee,
      membershipSaved: MembershipEditingActions.membershipLoaded,

      validate: MembershipTiersActions.validate,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  newRecord() {
    return null;
  }

  mounted = membershipId => {
    this.reset();
    this.membershipId = membershipId;
    this.tierIdToDelete = null;
    this.drawerOpened = false;
    this.joinFeeChecked = false;
    this.listMembershipTiers();
  };

  reset() {
    this.membershipId = null;
    this.record = null;
    this.membershipTiersIds = OrderedSet();
    this.isLoading = false;
    this.savingTier = false;
  }

  toggleCreationDrawer(tier) {
    this.record = tier ?? new MembershipTier();
    this.joinFeeChecked = Boolean(this.record?.join_fee);
    this.drawerOpened = !this.drawerOpened;
  }

  validate() {
    this.record = this.record.validate();
  }

  exclusiveEventTypesExpanded() {
    this.exclusiveExpanded = !this.exclusiveExpanded;
  }

  toggleJoinFee() {
    this.joinFeeChecked = !this.joinFeeChecked;
  }

  addTier() {
    this.record = new MembershipTier({ interval_months: 1 });
  }

  // eslint-disable-next-line class-methods-use-this
  getDiscounts(discounts) {
    return discounts.toJS().map(med => {
      const discount = med;

      delete discount.errors;
      delete discount.subscribable_id;
      delete discount.subscribable_type;
      delete discount.id;

      return discount;
    });
  }

  cloneTier(tier) {
    this.editTier(tier.clone());
  }

  cancelTier() {
    this.record = null;
  }

  editTier(tier) {
    this.record = tier;
    this.joinFeeChecked = Boolean(tier?.join_fee);
    this.updateEventsStatus(true);
    this.exclusiveExpanded =
      !this.record.membership_event_permissions.isEmpty();
  }

  fieldChanged({ field, value }) {
    if (Array.isArray(field)) {
      this.record = this.record.setIn(field, value);
      return;
    }

    this.record = this.record.set(field, value);
  }

  saveTier() {
    this.setDiscounts();
    this.record = this.record.validate();

    if (!this.record.isValid()) {
      return null;
    }

    this.savingTier = true;

    if (this.record.id) {
      return MembershipTierSource.update({
        tier: {
          ...this.record.toJS(),
          membership_id: this.membershipId,
        },
        fields: ['event_types', 'events'],
        success: MembershipTiersActions.saveTierSuccess,
        error: MembershipTiersActions.saveTierError,
      });
    }

    return MembershipTierSource.create({
      tier: {
        ...this.record.toJS(),
        membership_id: this.membershipId,
      },
      fields: ['event_types', 'events'],
      success: MembershipTiersActions.saveTierSuccess,
      error: MembershipTiersActions.saveTierError,
    });
  }

  saveTierSuccess(tier) {
    if (!this.membershipTiersIds.has(tier?.id)) {
      this.membershipTiersIds = this.membershipTiersIds.add(tier.id);
      MessageWindowActions.addMessage.defer(
        'Membership tier saved successfully'
      );
    } else {
      MessageWindowActions.addMessage.defer(
        'Membership tier updated successfully'
      );
    }

    this.savingTier = false;
    this.drawerOpened = false;
    this.record = null;
  }

  saveTierError() {
    this.savingTier = true;
  }

  listMembershipTiers() {
    if (this.membershipId) {
      this.isLoading = true;

      MembershipTierSource.list({
        params: {
          membership_id: this.membershipId,
          per_page: 50,
          fields: ['event_types', 'events'],
        },
        success: MembershipTiersActions.listMembershipTiersSuccess,
        error: MembershipTiersActions.listMembershipTiersError,
      });
    }
  }

  listMembershipTiersSuccess({ membership_tiers: membershipTiers }) {
    this.isLoading = false;
    this.membershipTiersIds = OrderedSet(membershipTiers.map(tier => tier.id));
  }

  listMembershipTiersError() {
    this.isLoading = false;
  }

  // eslint-disable-next-line class-methods-use-this
  toggleBestValue(tier) {
    const updatedTier = {
      ...tier.toJS(),
      best_value: !tier.best_value,
      membership_id: this.membershipId,
    };

    MembershipTierSource.update({
      tier: updatedTier,
      fields: ['event_types', 'events'],
      success: MembershipTiersActions.toggleBestValueSuccess,
      error: MembershipTiersActions.toggleBestValueError,
    });
  }

  toggleBestValueSuccess() {
    this.listMembershipTiers();
  }

  toggleBestValueError(...args) {
    this.notifyError('Error updating best value', args);
  }

  showDeleteConfirmation(tierId) {
    this.tierIdToDelete = tierId;
  }

  deleteTier() {
    if (this.tierIdToDelete) {
      MembershipTierSource.remove({
        tier: { id: this.tierIdToDelete },
        success: {
          action: MembershipTiersActions.deleteTierSuccess,
          args: [this.tierIdToDelete],
        },
        error: MembershipTiersActions.deleteTierError,
      });
    }
  }

  deleteTierSuccess([_, tierId]) {
    this.membershipTiersIds = this.membershipTiersIds.delete(tierId);
    this.tierIdToDelete = null;
  }

  deleteTierError(...args) {
    this.notifyError('Error deleting tier', args);
  }

  membershipSaved(membership) {
    const shouldSaveTier =
      this.record &&
      (this.record.hasBenefits ||
        this.record.membership_all_discounts.size > 0);

    if (shouldSaveTier) {
      this.membershipId = membership.id;
      this.saveTier();
      MembershipEditingActions.updateStore.defer({
        tiered: true,
      });
    }
  }
}

export default alt.createStore(MembershipTiersStore, 'MembershipTiersStore', {
  actions: MembershipTiersActions,
});
