import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  boldText: {
    fontWeight: 'bold',
    marginTop: '5px',
  },
};

function CashMessage() {
  return (
    <div style={{ margin: '2rem 0 1rem' }}>
      <FormattedMessage id={messageId('.payment_due', __filenamespace)} />
      <div style={styles.boldText}>
        <FormattedMessage id={messageId('.first_session', __filenamespace)} />
      </div>
    </div>
  );
}

export default injectIntl(CashMessage);
