import * as React from 'react';
import { injectIntl } from 'react-intl';
import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { Grid, TextField, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

import { CardReaderDataStore } from 'dataStores';

import CardReaderEditActions from './Actions';
import CardReaderEditStore from './Store.js';

import './styles.scss';

function CardReaderEdit({
  cardReaderEditStore: { cardReaderId, nickname },
  cardReaderDataStore: { cardReaders },
  intl,
}) {
  const cardReader = cardReaders.get(cardReaderId);

  return cardReader ? (
    // We should just be using spacing on the Grid, but modals don't work with
    // Grid content that has spacing.
    <Grid container className="CardReaderEdit">
      <Grid className="name-field" item xs={12}>
        <TextField
          label={t(`.device_name`, intl, __filenamespace)}
          fullWidth
          outline
          value={nickname}
          onChange={event =>
            CardReaderEditActions.setNickname(event.target.value)
          }
          autoFocus
        />
      </Grid>

      <Grid className="info-row" item container xs={12} justify="space-between">
        <Grid item>
          <div className="statusRow">
            <Typography variant="body2">
              {t(`.device_type`, intl, __filenamespace)}
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <Typography variant="body2">
            {t(`.type_${cardReader.get('deviceType')}`, intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>

      <Grid className="info-row" item container xs={12} justify="space-between">
        <Grid item>
          <Typography variant="body2">Serial #</Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2">
            {cardReader.get('serialNumber')}
          </Typography>
        </Grid>
      </Grid>

      <Grid className="infoRow" item container xs={12} justify="space-between">
        <Grid item>
          <Typography variant="body2">
            {t(`.activation_status`, intl, __filenamespace)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2">
            {t(
              `.activation_status_${cardReader.activationStatus}`,
              intl,
              __filenamespace
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : null; // null needed to ensure we can return nothing without error
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      cardReaderEditStore: CardReaderEditStore,
      cardReaderDataStore: CardReaderDataStore,
    },
  })
)(CardReaderEdit);
