import React, { memo, useState } from 'react';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import {
  Button,
  CurrencyTextField,
  TextField,
  Typography,
} from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

const STORE_KEY = 'pricing_tiers';

function SingleSessionPricingTiers({
  intl,
  singleSessionPrice,
  pricingTiers,
  handleInputChange,
  onSave,
}) {
  let tiers = pricingTiers;
  const [isDirty, setDirty] = useState(false);

  const getDividerText = (tier, index) => {
    const nextTierCount = tiers.getIn([index + 1, 'session_count']);
    const isOneByOne = nextTierCount - tier.get('session_count') === 1;

    if (isOneByOne) return null;
    if (index + 1 === tiers.size)
      return t('.divider_more', intl, __filenamespace);
    if (!isOneByOne)
      return t('.divider_to', intl, __filenamespace, {
        count: tiers.getIn([index + 1, 'session_count']) - 1,
      });

    return t('.divider_to', intl, __filenamespace, {
      count: tiers.getIn([index + 1, 'session_count']) - 1,
    });
  };

  const handleAddTier = e => {
    const sessionCount =
      tiers.size > 0 ? Number(tiers.last().get('session_count', 0)) + 1 : 2;

    tiers = tiers.push(
      Map({
        session_count: sessionCount,
        single_session_price: singleSessionPrice,
      })
    );

    handleInputChange(e, tiers, [STORE_KEY]);
    setDirty(true);
  };

  const handleFieldChange = (e, value, index, field) => {
    if (value > 0) {
      handleInputChange(e, value, [STORE_KEY, index, field]);
      setDirty(true);
    }
  };

  const handleDeleteTier = (e, index) => {
    tiers = tiers.delete(index);
    handleInputChange(e, tiers, [STORE_KEY]);
    setDirty(true);
  };

  const handleSave = () => {
    setDirty(false);
    onSave();
  };

  return (
    <div className="pricing-tiers">
      <div className="pricing-tiers__list">
        {pricingTiers.map((tier, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`tier-${index}`} className="pricing-tiers__list-column">
            <TextField
              fullWidth
              label={index === 0 ? t('.qty', intl, __filenamespace) : ''}
              name="session_count"
              type="number"
              classes={{ root: 'pricing-tiers__session-count' }}
              value={tier.get('session_count')}
              onChange={(e, value) =>
                handleFieldChange(e, value, index, 'session_count')
              }
            />
            <Typography className="pricing-tiers__divider">
              {getDividerText(tier, index) || <div />}
            </Typography>
            <CurrencyTextField
              fullWidth
              label={
                index === 0 ? t('.session_price', intl, __filenamespace) : ''
              }
              name="single_session_price"
              classes={{ root: 'pricing-tiers__session-price' }}
              value={tier.get('single_session_price')}
              onChange={(e, value) =>
                handleFieldChange(e, value, index, 'single_session_price')
              }
            />
            <div>
              <Button
                rounded
                icon="delete"
                type="tertiary"
                classes={{ root: 'pricing-tiers__delete-btn' }}
                onClick={e => handleDeleteTier(e, index)}
              />
            </div>
          </div>
        ))}
      </div>
      <Button
        icon="add"
        type="tertiary"
        classes={{ root: 'pricing-tiers__add-btn' }}
        onClick={handleAddTier}
      >
        {t('.add_tier', intl, __filenamespace)}
      </Button>
      {isDirty && (
        <Button
          classes={{ root: 'pricing-tiers__save-btn' }}
          onClick={handleSave}
        >
          {t('.save_tier', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

export default compose(memo, injectIntl)(SingleSessionPricingTiers);
