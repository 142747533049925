import * as React from 'react';

import uhTheme from '_uh_theme.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';

export const href = ({ email, subject, cc, bcc, body }) => {
  const properties = [];

  if (subject) {
    properties.push(`subject=${subject}`);
  }
  if (cc) {
    properties.push(`cc=${cc}`);
  }
  if (bcc) {
    properties.push(`bcc=${bcc}`);
  }
  if (body) {
    properties.push(`body=${body}`);
  }

  const queryString = properties.length > 0 ? `?${properties.join('&')}` : '';
  const mailToString = `${email}${queryString}`
    .replace(/ /g, '%20')
    .replace(/(\r\n|\n|\r)/g, '%0D%0A')
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D')
    .replace(/:/g, '%3A');

  return `mailto:${mailToString}`;
};

function MailTo({ children, ...props }) {
  if (children) {
    return (
      <div>
        {React.Children.map(children, child =>
          React.cloneElement(child, { href: href(props) })
        )}
      </div>
    );
  }
  return (
    <a
      href={href(props)}
      style={smallScreen() ? { color: uhTheme.palette.hyperlink } : {}}
    >
      {props.email}
    </a>
  );
}

export default MailTo;
