import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List } from 'immutable';
import FixedScheduleProfileList from 'shared/components/registration/FixedScheduleProfileList.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  totalContainer: {
    marginTop: 20,
    marginBottom: 15,
    fontSize: 16,
  },
};

function Total({ totalPrice }) {
  return (
    <FlexBoxJustify style={styles.totalContainer}>
      <FormattedMessage id={messageId('.total', __filenamespace)} />
      <span style={{ fontWeight: 'bold' }}>
        {totalPrice >= 0 ? (
          <FormattedCurrency value={totalPrice} fromCents />
        ) : (
          <span>&ndash;</span>
        )}
      </span>
    </FlexBoxJustify>
  );
}

const onProfileSelect = (_, __, id) =>
  PDRegistrationPackageActions.fixedScheduleAthleteAdded(id);
const onProfileRemove = id =>
  PDRegistrationPackageActions.fixedScheduleAthleteRemoved(id);

function SimpleFixedScheduleContent({
  intl,
  event,
  eventIsFull,
  membershipEventDiscounts,
  paymentPlanDescription,
  selectableAthletes,
  selectedAthletes,
  totalPrice,
}) {
  return (
    <div>
      <ProfileSelector
        afterProfileCreate={
          PDRegistrationPackageActions.fixedScheduleProfileCreated
        }
        athletes={selectableAthletes}
        disabled={eventIsFull}
        disabledText={t('.event_is_full', intl, __filenamespace)}
        header={t('actions.choose_attendees', intl)}
        onChange={onProfileSelect}
        preventProfileCreation={event.isMembershipExclusive()}
        value={null}
      />

      <FixedScheduleProfileList
        eventDiscounts={
          paymentPlanDescription ? List() : membershipEventDiscounts
        }
        eventPrice={event.price}
        profiles={selectedAthletes}
        onRemove={onProfileRemove}
      />

      <Total totalPrice={totalPrice} />
    </div>
  );
}

export default injectIntl(SimpleFixedScheduleContent);
