export default alt.generateActions(
  'cancelSave',
  'clearUI',
  'commitmentLengthToggled',
  'createOrUpdate',
  'error',
  'eventCreditAdded',
  'eventCreditEventAdded',
  'eventCreditEventRemoved',
  'eventCreditEventTypeAdded',
  'eventCreditEventTypeRemoved',
  'eventCreditRemoved',
  'eventCreditTypeChanged',
  'eventGrantAheadChanged',
  'eventCreditUnlimitedToggled',
  'eventCreditUpdated',
  'eventDiscountAdded',
  'eventDiscountAllEventsToggled',
  'eventDiscountEventTypeAdded',
  'eventDiscountEventTypeRemoved',
  'eventDiscountRemoved',
  'eventDiscountTypeUpdated',
  'eventDiscountValueUpdated',
  'exclusiveEventTypeAdded',
  'exclusiveEventTypeRemoved',
  'exclusiveEventTypesCleared',
  'exclusiveEventTypesExpanded',
  'fetch',
  'joinFeeToggled',
  'membershipLoaded',
  'preSaleToggled',
  'productDiscountAdded',
  'productDiscountAllProductsToggled',
  'productDiscountCategoryAdded',
  'productDiscountCategoryRemoved',
  'productDiscountRemoved',
  'productDiscountTypeUpdated',
  'productDiscountValueUpdated',
  'saveWithPrompt',
  'toggleCreditRollover',
  'toggleFutureScheduling',
  'updateStore',
  'updateUI',
  'commitmentChecked',
  'validateAndSave',
  'creditPassDiscountAdded',
  'creditPassDiscountRemoved',
  'creditPassDiscountAllPassesToggled',
  'creditPassDiscountTypeUpdated',
  'creditPassDiscountCreditPassAdded',
  'creditPassDiscountCreditPassRemoved',
  'creditPassDiscountValueUpdated',
  'discountFieldChanged',
  'discountRemoved',
  'discountTypeChanged'
);
