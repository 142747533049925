import * as React from 'react';
import moment from 'moment-timezone';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import RevenueReportStore from 'reporting/revenue/stores/_RevenueReportStore.jsx';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';

import {
  Colors,
  BorderColors,
  HoverColors,
} from 'reporting/index/components/_ColorPalette.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function formatCurrency(intl, value) {
  if (value !== null && value !== undefined) {
    return intl.formatNumber(value / 100.0, {
      style: 'currency',
      currency: 'USD',
    });
  }
  return null;
}

class RevenueYearOverYear extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { revenueReportStore: current } = this.props;
    const next = nextProps.revenueReportStore;
    const statusChanged =
      current.isTotalsByMonthLoading !== next.isTotalsByMonthLoading;
    const resultChanged =
      current.revenueYearOverYear !== next.revenueYearOverYear;
    return statusChanged || resultChanged;
  }

  // eslint-disable-next-line class-methods-use-this
  getLabels() {
    return moment.monthsShort();
  }

  getDatasets() {
    const { revenueReportStore: store } = this.props;
    return store.revenueYearOverYear.map((y, index) => ({
      label: y.year,
      backgroundColor: Colors[index % Colors.length],
      borderColor: BorderColors[index % BorderColors.length],
      borderWidth: 1,
      hoverBackgroundColor: HoverColors[index % HoverColors.length],
      hoverBorderColor: BorderColors[index % BorderColors.length],
      data: y.revenue,
    }));
  }

  getChartOptions() {
    const { intl } = this.props;
    return {
      scales: {
        y: {
          ticks: {
            callback(value) {
              return formatCurrency(intl, value);
            },
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label(context) {
              return formatCurrency(intl, context.parsed.y);
            },
          },
        },
      },
    };
  }

  render() {
    const { revenueReportStore: store, intl } = this.props;
    const data = {
      labels: this.getLabels(),
      datasets: this.getDatasets(),
    };
    const options = this.getChartOptions();

    return (
      <Widget
        title={t('.title', intl, __filenamespace)}
        style={{ width: '100%', overflowX: 'auto' }}
        isLoading={store.isTotalsByMonthLoading}
      >
        <div style={{ position: 'relative' }}>
          <Bar data={data} options={options} width={100} height={50} />
        </div>
      </Widget>
    );
  }
}

function RevenueYearOverYearWrapper({ intl }) {
  return (
    <AltContainer stores={{ revenueReportStore: RevenueReportStore }}>
      <RevenueYearOverYear intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(RevenueYearOverYearWrapper);
