import PropTypes from 'prop-types';
import * as React from 'react';
import Client from 'shared/records/Client.jsx';
import Staff from 'shared/records/Staff.jsx';
import User from 'event_mgmt/shared/records/User.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';

const AvatarWithName = ({
  user,
  name,
  letter,
  avatarOptions,
  src,
  ...props
}) => {
  const userObject = user || {
    first_name: letter,
    alternativeImage: () => src,
  };

  return (
    <FlexBoxCenter {...props}>
      <UserAvatar
        {...avatarOptions}
        user={userObject}
        style={{
          flex: '0 0 auto',
        }}
      />
      {user ? user.name() : name}
    </FlexBoxCenter>
  );
};

AvatarWithName.defaultProps = {
  /**
   * User object to use to build the Avatar and the name to its right. If none is provided the name
   * and letter props will be used instead.
   */
  user: null,

  /**
   * Name to display to the right of the Avatar. Not used if `user` prop is provided.
   */
  name: '',

  /**
   * Letter to display in the avatar Avatar. Not used if `user` prop is provided.
   */
  letter: '',

  /**
   * Image source to display in the avatar Avatar. Not used if `user` prop is provided.
   */
  src: undefined,

  /**
   * Override styles of the root element.
   */
  style: {},

  /**
   * Options to pass to the UserAvatar. Available options are `showTypeBadge`, `darkOnLight` and
   * `membershipColor`.
   */
  avatarOptions: {},
};

AvatarWithName.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.instanceOf(Client),
    PropTypes.instanceOf(Staff),
    PropTypes.instanceOf(User),
  ]),
  name: PropTypes.string,
  letter: PropTypes.string,
  avatarOptions: PropTypes.shape({
    showTypeBadge: PropTypes.bool,
    darkOnLight: PropTypes.bool,
    membershipColor: PropTypes.string,
  }),
  src: PropTypes.string,
  style: PropTypes.object,
};

export default AvatarWithName;
