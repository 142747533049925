import React from 'react';
import { injectIntl } from 'react-intl';
import { Alert, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

function EditConfirm({ intl, open, onClose, onConfirm, isTeamsPage }) {
  const getTitle = () =>
    isTeamsPage
      ? t('.team_title', intl, __filenamespace)
      : t('.title', intl, __filenamespace);
  return (
    <Alert
      open={open}
      type="warning"
      title={getTitle()}
      buttonText={t('.ok_btn', intl, __filenamespace)}
      secondaryButtonText={t('.cancel_btn', intl, __filenamespace)}
      classes={{
        root: 'confirm-edit',
        actions: 'confirm-edit__actions',
      }}
      onSecondaryButtonClick={onClose}
      onDismiss={onConfirm}
    >
      <Typography variant="body1">
        {t('.message', intl, __filenamespace)}
      </Typography>
    </Alert>
  );
}

export default injectIntl(EditConfirm);
