import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Tabs } from '@upperhand/playmaker';

import Balances from 'containers/reports/balancesReport/BalancesReport.jsx';
import PaymentPlans from 'containers/reports/paymentPlans/PaymentPlans.jsx';
import MessagingDrawer from 'shared/components/MessagingDrawer.jsx';
import MessagingDrawerStore from 'shared/stores/MessagingDrawerStore.jsx';
import Clients from 'containers/events/admin/clients/Clients.jsx';
import CancelScheduleModal from 'containers/eventScheduleCancellation/CancelScheduleModal.jsx';
import Schedule, {
  SessionRange,
} from 'containers/events/admin/schedule/Schedule.jsx';
import Sidebar, {
  Overview,
} from 'containers/events/admin/admin_page/components/Sidebar.jsx';
import EventMarketingStore from 'event_mgmt/display/stores/EventMarketingStore.js';
import EventMarketingWrapper from 'event_mgmt/display/components/_EventMarketingWrapper.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import PurchaseDrawer from 'containers/purchaseDrawer/PurchaseDrawer.jsx';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import AdminStaffActions from 'containers/events/admin/staff/actions';
import StaffStore from 'containers/events/admin/staff/store';
import EventResourceActions from 'event_mgmt/shared/actions/EventResourceActions';
import EventResourceStore from 'event_mgmt/shared/stores/EventResourceStore';
import ResourceListingActions from 'resources/actions/ResourceListingActions';
import StaffActions from 'shared/actions/StaffActions.jsx';
import SessionCreationDrawer from 'containers/eventSessionCreation/SessionCreationDrawer.jsx';
import DetailsStore from 'containers/events/admin/sessionSummaryDrawer/components/details/store';
import { isLoggedIn, currentUser } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { isTexasTwelveTheme } from 'shared/utils/ThemeUtils';
import AdminPageActions from './actions';
import AdminPageStore, { PAYMENTS_TAB, PAYMENT_PLANS_TAB } from './store';
import './style.scss';

const styles = {
  root: {
    height: '100%',
    backgroundColor: 'var(--color-bg-gray)',
    padding: 4,
  },

  tabContainer: {
    padding: '0 12px',
  },
};

const parseIntEventId = eventSegment =>
  eventSegment ? parseInt(eventSegment.split('-')[0], 10) : null;

function TeamDetailPage({
  adminPageStore: { selectedTab, showTab, revenue },
  eventSlug,
  eventStore: { customerEvent: event, isLoadingEvent },
  staffStore: { staffIds },
  eventResourceStore: { resources },
  eventMarketingStore,
  messagingDrawerStore,
  detailsStore: {
    resourcesExpandedTeam,
    staffExpandedTeam,
    descriptionExpanded,
  },
  isClient,
  intl,
}) {
  const eventId = parseIntEventId(eventSlug);
  const isManager = currentUser().isManager();
  const showPaymentsTab = isTexasTwelveTheme()
    ? !isClient && !isManager
    : !isClient;

  React.useEffect(() => {
    const fields = [
      'team_detail',
      'team_type',
      'schedule_resources',
      'schedule_staff',
      'registered_client_ids',
      'participant_ids',
    ];

    EventActions.fetchTeam(eventId, fields);
    AdminStaffActions.mounted(eventId);
    if (!isClient) {
      EventResourceActions.list({ eventId });
      ResourceListingActions.list();
    }
    StaffActions.list({});
  }, [eventSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    smallScreen() && {
      label: t('.overview', intl, __filenamespace),
      content: (
        <Overview
          event={event}
          revenue={revenue}
          staffIds={staffIds}
          resources={resources}
          resourcesExpanded={resourcesExpandedTeam}
          staffExpanded={staffExpandedTeam}
          descriptionExpanded={descriptionExpanded}
          isClient={isClient}
          intl={intl}
        />
      ),
    },

    {
      label: t(isClient ? '.registration' : '.roster', intl, __filenamespace),
      content: <Clients event={event} isClient={isClient} isTeamEvent />,
    },
    showPaymentsTab &&
      showTab[PAYMENTS_TAB] && {
        label: t('.payments', intl, __filenamespace),
        content: (
          <Balances eventId={eventId} revenue={revenue} reportMode={false} />
        ),
      },
    showPaymentsTab &&
      showTab[PAYMENT_PLANS_TAB] && {
        label: t('.paymentPlans', intl, __filenamespace),
        content: <PaymentPlans eventId={eventId} />,
      },
    {
      label: t('.schedule', intl, __filenamespace),
      content: (
        <Schedule
          eventId={eventId}
          scheduleType={event.schedule_type}
          isTeamEvent
          sessionRange={SessionRange.FUTURE}
        />
      ),
    },
    {
      label: t('.completed', intl, __filenamespace),
      content: (
        <Schedule
          eventId={eventId}
          scheduleType={event.schedule_type}
          isTeamEvent
          sessionRange={SessionRange.COMPLETED}
        />
      ),
    },
  ].filter(tab => !!tab);

  const handleTabChange = (_event, value) =>
    AdminPageActions.tabSelected(value);

  return (
    <div style={styles.root} className="admin-events">
      <SpinWhileLoading isLoading={!isClient && isLoadingEvent}>
        <SessionCreationDrawer isTeamsPage />
        <CancelScheduleModal isTeamEvent />
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <Sidebar
              event={event}
              revenue={revenue}
              staffIds={staffIds}
              resources={resources}
              resourcesExpanded={resourcesExpandedTeam}
              staffExpanded={staffExpandedTeam}
              descriptionExpanded={descriptionExpanded}
              isClient={isClient}
              isTeamEvent
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            {isLoggedIn() && (
              <div style={styles.tabContainer}>
                <Tabs
                  variant="scrollable"
                  eventId={eventId}
                  onChange={handleTabChange}
                  tabItems={tabs}
                  value={selectedTab}
                />
              </div>
            )}
          </Grid>
        </Grid>
        <PurchaseDrawer />
        {!isClient && (
          <>
            <MessagingDrawer messagingDrawerStore={messagingDrawerStore} />
            <EventMarketingWrapper
              event={event}
              eventMarketingStore={eventMarketingStore}
            />
          </>
        )}
      </SpinWhileLoading>
    </div>
  );
}

export default altContainer({
  stores: {
    adminPageStore: AdminPageStore,
    eventStore: EventStore,
    eventMarketingStore: alt.createStore(
      EventMarketingStore,
      'EventMarketingStore'
    ),
    messagingDrawerStore: MessagingDrawerStore,
    staffStore: StaffStore,
    eventResourceStore: EventResourceStore,
    detailsStore: DetailsStore,
  },
})(injectIntl(TeamDetailPage));
