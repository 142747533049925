import { List, Map } from 'immutable';

import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';

import StoreActions from 'shared/actions/StoreActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { list } from 'sources/VariantSource.js';
import Variant from 'shared/records/Variant.js';
import PurchaseOrder from 'retail/records/PurchaseOrder.js';
import PurchaseOrderItem from 'retail/records/PurchaseOrderItem.js';
import FilteredSelection from 'shared/records/FilteredSelection.js';
import CustomerUser from 'shared/records/Customer.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class InventoryDialogStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      reset: [StoreActions.PREPARE_FOR_REUSE, InventoryDialogActions.RESET],
      openDialog: InventoryDialogActions.OPEN_DIALOG,
      openDialogForOrder: InventoryDialogActions.OPEN_DIALOG_FOR_ORDER,
      closeDialog: InventoryDialogActions.CLOSE_DIALOG,
      variantsList: InventoryDialogActions.VARIANTS_LIST,
      variantsListSuccess: InventoryDialogActions.VARIANTS_LIST_SUCCESS,
      variantsListError: InventoryDialogActions.VARIANTS_LIST_ERROR,
      updateFilter: InventoryDialogActions.UPDATE_FILTER,
      localUpdatePurchaseOrder:
        InventoryDialogActions.LOCAL_UPDATE_PURCHASE_ORDER,
      localUpdatePurchaseOrderItem:
        InventoryDialogActions.LOCAL_UPDATE_PURCHASE_ORDER_ITEM,
      processPurchaseOrder: InventoryDialogActions.PROCESS_PURCHASE_ORDER,
      createPurchaseOrder: InventoryDialogActions.CREATE_PURCHASE_ORDER,
      createPurchaseOrderSuccess:
        InventoryDialogActions.CREATE_PURCHASE_ORDER_SUCCESS,
      createPurchaseOrderError:
        InventoryDialogActions.CREATE_PURCHASE_ORDER_ERROR,
      updatePurchaseOrder: InventoryDialogActions.UPDATE_PURCHASE_ORDER,
      updatePurchaseOrderSuccess:
        InventoryDialogActions.UPDATE_PURCHASE_ORDER_SUCCESS,
      updatePurchaseOrderError:
        InventoryDialogActions.UPDATE_PURCHASE_ORDER_ERROR,
      onProductClear: InventoryDialogActions.CLEAR_PRODUCT,
      purchaseOrderItemList: InventoryDialogActions.PURCHASE_ORDER_ITEM_LIST,
      purchaseOrderItemListSuccess:
        InventoryDialogActions.PURCHASE_ORDER_ITEM_LIST_SUCCESS,
      purchaseOrderItemListError:
        InventoryDialogActions.PURCHASE_ORDER_ITEM_LIST_ERROR,
      enableEditMode: InventoryDialogActions.ENABLE_EDIT_MODE,
      updatePurchaseOrderItems:
        InventoryDialogActions.UPDATE_PURCHASE_ORDER_ITEMS,
      updatePurchaseOrderItemsSuccess:
        InventoryDialogActions.UPDATE_PURCHASE_ORDER_ITEMS_SUCCESS,
      updatePurchaseOrderItemsError:
        InventoryDialogActions.UPDATE_PURCHASE_ORDER_ITEMS_ERROR,
    });
  }

  reset() {
    // Appears to be breaking the app from building. Commented it out because I'm not wh
    // this.waitFor(RetailCategoryListingStore);
    const { retailCategories } = RetailCategoryListingStore.getState();

    this.isOpen = false;
    this.isChanged = false;
    this.isLoading = false;

    this.page = 1;
    this.perPage = 50;
    this.totalCount = 0;

    this.filters = Map({
      search: '',
      retail_category_ids: new FilteredSelection({
        source: retailCategories.map(item => ({
          key: item.id,
          value: item.name,
        })),
      }),
      variant_ids: List(),
    });
    this.disable = true;
    this.productId = null;
    this.productName = null;

    this.variants = List();
    this.editedOrderItems = List();
    this.purchaseOrder = new PurchaseOrder();
    this.isInventory = true;
    this.isEditMode = true;
  }

  openDialog({ product = {} }) {
    this.reset();
    this.productId = product.id;
    this.productName = product.name;
    this.isOpen = true;
    this.isInventory = true;
    this.isEditMode = true;
    this.variantsList();
  }

  openDialogForOrder({
    purchaseOrder = new PurchaseOrder({}),
    customerUser = new CustomerUser({}),
  }) {
    this.reset();
    this.purchaseOrder = purchaseOrder;
    this.customerUser = customerUser;
    this.isOpen = true;
    this.isInventory = false;
    this.isEditMode = false;
    this.purchaseOrderItemList();
  }

  closeDialog() {
    this.isOpen = false;
  }

  onProductClear() {
    this.productId = null;
    this.productName = '';
    this.disable = false;
    this.updateFilter(['search', '']);

    this.variantsList();
  }

  variantsList(showLoading = true) {
    this.isLoading = showLoading;
    const params = {
      page: this.page,
      per_page: this.perPage,
      product_id: this.productId,
      search: this.filters.get('search').trim(),
      retail_category_ids: this.filters.get('retail_category_ids').toServer(),
    };

    list({
      params,
      success: InventoryDialogActions.variantsListSuccess,
      error: InventoryDialogActions.variantsListError,
    });
  }

  variantsListForPurchaseOrder() {
    if (this.filters.get('variant_ids').size > 0) {
      const params = {
        page: 1,
        per_page: this.perPage,
        ids: this.filters.get('variant_ids').toArray(),
      };

      list({
        params,
        success: InventoryDialogActions.variantsListSuccess,
        error: InventoryDialogActions.variantsListError,
      });
    } else {
      this.isLoading = false;
    }
  }

  variantsListSuccess({ variants, page, perPage, totalCount }) {
    // Merge with new variants and remove already edited items
    const fetchedVariants = variants
      .filter(item => this.variants.filter(variant => item.id === variant.id))
      .map(item => new Variant(item));

    this.variants =
      page === 1 ? fetchedVariants : this.variants.concat(fetchedVariants);

    if (this.isInventory) {
      this.page = page + 1;
      this.perPage = perPage;
      this.totalCount = totalCount;
    }

    this.isLoading = false;
  }

  variantsListError({ ...args }) {
    this.isLoading = false;
    this.notifyError('error listing variants', args);
  }

  updateFilter([filter, value]) {
    this.isLoading = true;
    this.filters = this.filters.set(filter, value);
    this.page = 1;
    this.totalCount = 0;
    if (this.isInventory) {
      this.variantsList();
    } else {
      this.purchaseOrder = this.purchaseOrder.set(
        'purchase_order_items',
        List()
      );
      this.purchaseOrderItemList();
    }
  }

  localUpdatePurchaseOrder([key, value]) {
    this.purchaseOrder = this.purchaseOrder.set(key, value);
    this.isChanged = true;
  }

  localUpdatePurchaseOrderItem([purchaseOrderItem, variant, key, value]) {
    const orderItem = purchaseOrderItem.set(key, value);
    const index = this.editedOrderItems.findIndex(
      item => item.id === orderItem.id
    );
    if (index >= 0) {
      this.editedOrderItems = this.editedOrderItems.delete(index);
    }
    this.editedOrderItems = this.editedOrderItems.push(orderItem);

    this.purchaseOrder = this.purchaseOrder.setItem({
      variant,
      key,
      value,
    });
    this.isChanged = this.isChanged || this.editedOrderItems.size > 0;
  }

  processPurchaseOrder() {
    if (this.isInventory) {
      this.createPurchaseOrder();
    } else {
      this.updatePurchaseOrder();
    }
  }

  createPurchaseOrder(showLoading = true) {
    this.purchaseOrder = this.purchaseOrder.validate();
    if (this.purchaseOrder.isValid()) {
      this.isLoading = showLoading;
      uhApiClient.post({
        url: 'purchase_orders',
        data: JSON.stringify({
          attributes: this.purchaseOrder.toServer(),
          immediate_receipt: true,
        }),
        success: InventoryDialogActions.createPurchaseOrderSuccess,
        error: InventoryDialogActions.createPurchaseOrderError,
      });
    }
  }

  createPurchaseOrderSuccess() {
    this.isLoading = false;
    MessageWindowActions.addMessage.defer('Inventory saved successfully!');
    this.closeDialog();
  }

  createPurchaseOrderError({ ...args }) {
    this.isLoading = false;
    this.notifyError('Error during saving inventory', args);
  }

  updatePurchaseOrder(showLoading = true) {
    this.purchaseOrder = this.purchaseOrder.validate();
    if (this.purchaseOrder.isValid()) {
      this.isLoading = showLoading;
      uhApiClient.patch({
        url: `purchase_orders/${this.purchaseOrder.get('id')}`,
        data: JSON.stringify({
          attributes: this.purchaseOrder.toServerForUpdate(),
          immediate_receipt: true,
        }),
        success: InventoryDialogActions.updatePurchaseOrderSuccess,
        error: InventoryDialogActions.updatePurchaseOrderError,
      });
    }
  }

  updatePurchaseOrderSuccess() {
    MessageWindowActions.addMessage.defer('Purchase order saved successfully!');
    this.updatePurchaseOrderItems();
    if (this.editedOrderItems.size === 0) {
      this.isLoading = false;
      this.isEditMode = false;
    }
  }

  updatePurchaseOrderError({ ...args }) {
    this.isLoading = false;
    this.notifyError('Error during updating purchase order', args);
  }

  purchaseOrderItemList(showLoading = true) {
    this.isLoading = showLoading;
    uhApiClient.get({
      url: 'purchase_order_items',
      data: {
        page: this.page,
        per_page: this.perPage,
        purchase_order_ids: this.purchaseOrder.get('id'),
        search: this.filters.get('search'),
        retail_category_ids: this.filters.get('retail_category_ids').toServer(),
      },
      success: InventoryDialogActions.purchaseOrderItemListSuccess,
      error: InventoryDialogActions.purchaseOrderItemListError,
    });
  }

  purchaseOrderItemListSuccess({
    purchase_order_items: serverPurchaseOrderItems,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.filters = this.filters.set(
      'variant_ids',
      List((serverPurchaseOrderItems || []).map(item => item.variant_id))
    );
    const purchaseOrderItems = this.purchaseOrder
      .get('purchase_order_items')
      .concat(
        List(
          (serverPurchaseOrderItems || []).map(item => {
            const index = this.editedOrderItems.findIndex(
              editedOrderItem => editedOrderItem.id === item.id
            );
            if (index >= 0) {
              return this.editedOrderItems.get(index);
            }
            return new PurchaseOrderItem(item);
          })
        )
      );

    this.purchaseOrder = this.purchaseOrder.set(
      'purchase_order_items',
      purchaseOrderItems
    );
    this.variantsListForPurchaseOrder();
    const editedOrderItemsIds = this.editedOrderItems.map(i => i.id).toSet();
    const editedItemsFromUpdatedList = purchaseOrderItems.filter(item =>
      editedOrderItemsIds.has(item.id)
    );
    // after listing purchase order items we want to check is there are edited item
    // yes = enable updating, no - dsiable updating
    this.isChanged = this.isChanged && editedItemsFromUpdatedList.size;
    this.page = page + 1;
    this.perPage = perPage;
    this.totalCount = totalCount;
  }

  purchaseOrderItemListError({ ...args }) {
    this.isLoading = false;
    this.notifyError('error listing purchase order items', args);
  }

  enableEditMode() {
    this.isEditMode = true;
  }

  updatePurchaseOrderItems(showLoading = true) {
    if (this.editedOrderItems.size > 0) {
      this.editedOrderItems = showLoading;

      uhApiClient.patch({
        url: 'purchase_order_items',
        data: JSON.stringify({
          purchase_order_items: this.purchaseOrder
            .get('purchase_order_items')
            .map(item => item.toServerForUpdate())
            .toJS(),
          immediate_receipt: true,
        }),
        success: InventoryDialogActions.updatePurchaseOrderItemsSuccess,
        error: InventoryDialogActions.updatePurchaseOrderItemsError,
      });
    }
  }

  updatePurchaseOrderItemsSuccess() {
    MessageWindowActions.addMessage.defer(
      'Purchase order items saved successfully!'
    );
    this.isLoading = false;
    this.editedOrderItems = List();
    this.isEditMode = false;
  }

  updatePurchaseOrderItemsError({ ...args }) {
    this.isLoading = false;
    this.notifyError('Error during updating purchase order items', args);
  }
}

export default alt.createStore(InventoryDialogStore, 'InventoryDialogStore');
