import * as React from 'react';

const getStyle = url => {
  if (url) {
    return {
      minHeight: 300,
      backgroundImage: `url(${url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    };
  }
  return {};
};

function EventImage({ event }) {
  return <div style={getStyle(event.image.getLeaderboard())} />;
}

export default EventImage;
