import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import { messageId } from 'shared/utils/LocaleUtils.js';

function EmptyState() {
  return (
    <Card>
      <Grid container justify="center">
        <Grid item>
          <Typography>
            <FormattedMessage id={messageId('.no_staff', __filenamespace)} />
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default EmptyState;
