import { List, Map } from 'immutable';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import FilterActions from 'team_mgmt/index/actions/FilterActions.jsx';
import FilterStoreImpl from 'team_mgmt/index/stores/_FilterStoreImplementation.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';

class FilterStore extends FilterStoreImpl {
  constructor() {
    super({
      toggleFilterDrawer: FilterActions.toggleFilterDrawer,
      updateFilterDrawerOpen: FilterActions.updateFilterDrawerOpen,
      updateStatusFilters: FilterActions.updateStatusFilters,
      updateTitleFilter: FilterActions.updateTitleFilter,
      updateTypeFilters: FilterActions.updateTypeFilters,
      resetFilters: StoreActions.PREPARE_FOR_REUSE,
    });

    this.statusFilters = Map({
      all: false,
      active: true,
      draft: true,
      completed: true,
      cancelled: false,
      deleted: false,
    });

    this.teamTypes = List();
    this.teamStatusFilters = [
      { name: 'All', filterKey: 'all' },
      { name: 'Active', filterKey: 'active' },
      { name: 'Draft', filterKey: 'draft' },
      { name: 'Completed', filterKey: 'completed' },
      { name: 'Cancelled', filterKey: 'cancelled' },
      { name: 'Deleted', filterKey: 'deleted' },
    ];
  }

  resetFilters() {
    super.resetFilters();
    this.resetTypeFilters();
  }

  resetTypeFilters() {
    if (this.teamTypes && !this.teamTypes.isEmpty()) {
      this.typeFilters = Map({ all: true }).withMutations(filterSet =>
        this.teamTypes.forEach(type => filterSet.set(type.id, true))
      );
    }
  }

  handleTeamTypeList() {
    this.waitFor(TeamTypeListingStore);
    const { isLoading, teamTypes } = TeamTypeListingStore.getState();
    if (!isLoading) {
      this.teamTypes = teamTypes;
      this.resetTypeFilters();
    }
  }
}

export default alt.createStore(FilterStore, 'FilterStore');
