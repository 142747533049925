import { List } from 'immutable';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';
import TeamType from 'records/TeamType.jsx';
import DataStoreActions from './TeamTypeActions';

const parserTo = { type: TeamType, paginationKey: 'team_types' };

const parser = new Parser(parserTo);

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error,
  });
};

export const createOrUpdateTeamType = ({ id, url, params, success, error }) => {
  const method = id ? 'put' : 'post';
  uhApiClient[method]({
    url,
    data: JSON.stringify(params),
    success: onSuccess(data => data, success),
    error: onError(List([error])),
  });
};
