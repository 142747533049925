import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  EventBox: {
    margin: '20px 0',
    borderBottom: '1px solid var(--color-divide)',
    paddingBottom: 16,
    fontSize: 16,
    color: 'var(--color-old-gray)',
  },
  PaidLabel: {
    backgroundColor: 'var(--color-green)',
    fontSize: '11px',
    padding: '5px 10px',
    cursor: 'pointer',
    color: 'white',
    fontWeight: 600,
  },
  UnPaidLabel: {
    backgroundColor: 'var(--color-unpaid-red)',
    fontSize: '11px',
    padding: '5px 10px',
    cursor: 'pointer',
    color: 'white',
    fontWeight: 600,
  },
};

class CheckoutEventBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedEventDetails: false,
    };

    this.handleToggleEventDetails = this.handleToggleEventDetails.bind(this);
  }

  handleToggleEventDetails() {
    const { expandedEventDetails } = this.state;
    this.setState({ expandedEventDetails: !expandedEventDetails });
  }

  render() {
    const { expandedEventDetails } = this.state;
    const { selectedEvent, dateRange, isOrderSuccess, order } = this.props;
    const { total } = order;
    const eventType = selectedEvent.get('event_type');
    const eventTypeName = eventType ? eventType.name : '';
    const formattedDate = dateRange.start.format('MMMM DD');
    const ExpandIcon = expandedEventDetails ? ExpandLessIcon : ExpandMoreIcon;

    // Set the input size explcitly for dynamic width
    // with a minimum of 5 (i.e, 00.00) --joshuamanns

    return (
      <div style={styles.EventBox}>
        <FlexBox
          style={{ borderLeft: `3px solid ${eventType.color}`, paddingLeft: 5 }}
        >
          <div style={{ flex: '0 0 91%', fontSize: 14 }}>
            <div style={{ fontWeight: 600 }}>{selectedEvent.title}</div>
            <div>{`${eventTypeName} | ${formattedDate}`}</div>
          </div>
          <div
            role="presentation"
            onClick={this.handleToggleEventDetails}
            style={{ flex: '0 0 9%', textAlign: 'right' }}
          >
            <ExpandIcon
              style={{
                height: 24,
                width: 24,
                cursor: 'pointer',
                color: uhColors.activeBlue,
              }}
            />
          </div>
        </FlexBox>
        {expandedEventDetails && (
          <FlexBoxJustify style={{ marginTop: 10 }}>
            {isOrderSuccess ? (
              <div style={styles.PaidLabel}>
                <FormattedMessage id={messageId('.paid', __filenamespace)} />
              </div>
            ) : (
              <div style={styles.UnPaidLabel}>
                <FormattedMessage id={messageId('.unpaid', __filenamespace)} />
              </div>
            )}
            <div style={styles.Price}>
              <FormattedCurrency value={total} fromCents />
            </div>
          </FlexBoxJustify>
        )}
      </div>
    );
  }
}

export default CheckoutEventBox;
