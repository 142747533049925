import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

// eslint-disable-next-line import/no-cycle
import { PaymentMethod } from 'containers/payments';
import { FlexBox } from 'shared/components/FlexBox.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

import PaymentActions from '../../Actions';

const styles = {
  PaymentMethodButton: {
    fontSize: '15px',
    flex: '1 1 33%',
  },
  SectionHeader: {
    fontSize: 16,
    margin: '20px 0',
    fontWeight: 'bold',
  },
  PaymentOptions: {
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '110px',
    justifyContent: 'space-between',
    margin: 0,
    gap: '5px',
  },
};

function PaymentMethodSegment({
  allowedPaymentMethods,
  currentPaymentMethod,
  disabled,
  showWaiveOption,
  intl,
  onWaive,
}) {
  // HACK: remove Handpoint Cloud since it will show up when "Card" is selected
  let shownMethods = allowedPaymentMethods.filter(
    m => m !== PaymentMethod.CARD_PRESENT && m !== PaymentMethod.HANDPOINT_CLOUD
  );

  if (!enabledCustomerFeatures(['ach'])) {
    shownMethods = shownMethods.filter(m => m !== PaymentMethod.ACH);
  }

  // Keep "Card" highlighted even as we switch to Handpoint cloud.
  const expandedCurrentPayment = [PaymentMethod.HANDPOINT_CLOUD].includes(
    currentPaymentMethod
  )
    ? [currentPaymentMethod, PaymentMethod.CARD]
    : currentPaymentMethod;

  styles.PaymentOptions.height =
    allowedPaymentMethods.size > 3 ? 'fit-content' : 'initial';
  styles.PaymentOptions.minHeight =
    allowedPaymentMethods.size > 3 ? '95px' : 'auto';

  return (
    <>
      <div style={styles.SectionHeader}>
        <FormattedMessage id={messageId('.payment_method', __filenamespace)} />
      </div>
      <FlexBox style={styles.PaymentOptions}>
        {shownMethods.map(paymentMethod => (
          <Button
            variant="contained"
            color={
              expandedCurrentPayment.includes(paymentMethod)
                ? 'primary'
                : 'default'
            }
            disabled={disabled}
            key={paymentMethod}
            onClick={() => PaymentActions.changePaymentMethod(paymentMethod)}
            style={styles.PaymentMethodButton}
          >
            {t(`.${paymentMethod}`, intl, __filenamespace)}
          </Button>
        ))}
        {showWaiveOption && (
          <Button
            style={styles.PaymentMethodButton}
            variant="contained"
            color="default"
            onClick={onWaive}
          >
            {t('.waive', intl, __filenamespace)}
          </Button>
        )}
      </FlexBox>
    </>
  );
}

export default injectIntl(PaymentMethodSegment);
