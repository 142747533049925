export default alt.generateActions(
  'clientSelected',
  'toggleReschedule',
  'drawerClosed',
  'setCurrentStep',
  'registrationAttendeeSelected',
  'registrationDateChanged',
  'registrationTimeChanged',
  'registrationStaffChanged',
  'registrationBooked',
  'fetchAvailabilities',
  'packageAddedToCart',
  'setClients',
  'registrationReschedule',
  'registrationRescheduleSuccess',
  'registrationRescheduleError'
);
