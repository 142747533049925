import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function ScheduleButton({
  customerUser,
  creditsRemaining,
  onClientSelect,
  style,
}) {
  let name;
  let userObj;

  if (customerUser) {
    name = customerUser.name();
    userObj = customerUser;
  } else {
    name = 'Unassigned';
    userObj = { first_name: '?', alternativeImage: () => null };
  }
  return (
    <Paper
      style={merge({ padding: 15, cursor: 'pointer' }, style)}
      onClick={() => onClientSelect(customerUser)}
    >
      <FlexBoxCenter>
        <UserAvatar user={userObj} style={{ marginRight: 15 }} />
        <div style={{ fontSize: 16 }}>
          <div>{name}</div>
          <div style={{ color: uhColors.activeBlue }}>
            <FormattedMessage
              id={messageId('.sessions_available', __filenamespace)}
              values={{
                count:
                  creditsRemaining === Infinity
                    ? 'Unlimited'
                    : creditsRemaining.toString(),
              }}
            />
          </div>
        </div>
      </FlexBoxCenter>
    </Paper>
  );
}

function ScheduleButtons({
  event,
  onClientSelect,
  profiles,
  clientCredits,
  style,
}) {
  return (
    <div style={style}>
      {profiles
        .sortBy(p => p.id)
        .map(profile => {
          const authorized = profiles.some(p => p.hasAccessTo(event));
          if (!authorized) {
            return null;
          }

          const clientCredit = clientCredits.get(profile.id);

          let creditCount;
          if (clientCredit) {
            creditCount = clientCredit.get('unlimited')
              ? Infinity
              : clientCredit.get('credits_remaining');
          }
          if (clientCredit !== undefined) {
            return (
              <ScheduleButton
                key={profile.id}
                customerUser={profile}
                creditsRemaining={creditCount}
                onClientSelect={onClientSelect}
                style={{ marginBottom: 15 }}
              />
            );
          }
          return null;
        })}
    </div>
  );
}

export default ScheduleButtons;
