import * as React from 'react';
import { Box } from '@mui/material';
import { Grid, Typography } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { t } from 'shared/utils/LocaleUtils';

const styles = {
  root: {
    border: '0.69px solid #E2E2E2',
    padding: '8px 42px 8px 16px',
  },
};

export const TableHeaderTeams = injectIntl(({ intl }) => (
  <Box sx={styles.root}>
    <Grid container direction="row" justify="flex-start">
      <Grid item xs={6}>
        <Typography variant="subtitle2">
          {t('.name', intl, __filenamespace)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2">
          {t('.managed_by', intl, __filenamespace)}
        </Typography>
      </Grid>
    </Grid>
  </Box>
));

function TableHeader() {
  return (
    <div style={styles.root}>
      <Grid container direction="row" justify="flex-start">
        <Grid item xs={5}>
          <Typography variant="subtitle2">Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">Gender</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">Age</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">Managed By</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">Phone</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default TableHeader;
