import React from 'react';
import StatusBar from 'shared/native/StatusBar';
import { isNative } from 'shared/utils/UserAgentUtils';

const styles = {
  statusBar: {
    top: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: 'var(--safe-area-inset-top)',
      backgroundColor: StatusBar.backgroundColor,
      zIndex: 1000,
    },

    left: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      width: 'var(--safe-area-inset-left)',
      backgroundColor: StatusBar.backgroundColor,
      zIndex: 1000,
    },
  },
};

function NativeStatusBar() {
  if (isNative()) {
    return (
      <>
        <div className="native-status-bar-top" style={styles.statusBar.top} />
        <div className="native-status-bar-left" style={styles.statusBar.left} />
      </>
    );
  }
}

export default NativeStatusBar;
