import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, Dropdown, Typography } from '@upperhand/playmaker';

import TimeZoneSelector from 'shared/components/TimeZoneSelector.jsx';

import { getValue } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import SportOfferedAutocomplete from 'shared/components/_SportOfferedAutocomplete.jsx';

import {
  Address1Field,
  Address2Field,
  CityField,
  StateField,
  PostalCodeField,
} from 'shared/components/AddressFields.jsx';
import {
  BusinessTypesOptions,
  SportsOfferedOptions,
} from 'user_management/constants';

export function Address1({
  classes,
  defaultValue,
  disabled,
  errorText,
  fieldName = 'line_1',
  onChange,
  customer,
}) {
  return (
    <Address1Field
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      name={fieldName}
      onChange={onChange}
      value={getValue(customer.address[fieldName], defaultValue)}
    />
  );
}

export function Address2({
  classes,
  defaultValue,
  disabled,
  errorText,
  fieldName = 'line_2',
  onChange,
  customer,
}) {
  return (
    <Address2Field
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      name={fieldName}
      onChange={onChange}
      value={getValue(customer.address[fieldName], defaultValue)}
    />
  );
}

export const BusinessName = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    label,
    onChange,
    customer,
    name,
  }) => (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label || 'Business Name'}
      name={name || 'name'}
      onChange={onChange}
      placeholder={t('.business_name', intl, __filenamespace)}
      value={getValue(customer[name || 'name'], defaultValue)}
    />
  )
);

export const BusinessType = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    onChange,
    customer,
  }) => (
    <Dropdown
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={t('.business_type', intl, __filenamespace)}
      placeholder={t('actions.select', intl, __filenamespace)}
      name="type"
      items={[
        {
          value: 'company',
          label: t('.business', intl, __filenamespace),
        },
        {
          value: 'individual',
          label: t('.personal', intl, __filenamespace),
        },
      ]}
      onChange={e => onChange(e, e.target.value, 'type')}
      value={getValue(customer.type, defaultValue)}
    />
  )
);

export function City({
  classes,
  defaultValue,
  disabled,
  errorText,
  onChange,
  customer,
}) {
  return (
    <CityField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      onChange={onChange}
      value={getValue(customer.address.city, defaultValue)}
    />
  );
}

export const DefaultCurrency = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    onChange,
    customer,
  }) => (
    <Dropdown
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={t('.default_currency', intl, __filenamespace)}
      placeholder={t('actions.select', intl, __filenamespace)}
      name="default_currency"
      items={[
        {
          value: 'usd',
          label: t('.usd', intl, __filenamespace),
        },
      ]}
      onChange={e => onChange(e, e.target.value, 'default_currency')}
      value={getValue(customer.default_currency, defaultValue)}
    />
  )
);

export function PostalCode({
  classes,
  defaultValue,
  disabled,
  errorText,
  onChange,
  customer,
}) {
  return (
    <PostalCodeField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      onChange={onChange}
      value={getValue(customer.address.postal_code, defaultValue)}
    />
  );
}

export function State({
  classes,
  defaultValue,
  disabled,
  errorText,
  onChange,
  customer,
}) {
  return (
    <StateField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      onChange={(e, value) => onChange(e, value, 'state')}
      useAbbreviations
      value={getValue(customer.address.state, defaultValue)}
    />
  );
}

export function TaxId({
  classes,
  defaultValue,
  disabled,
  errorText,
  onChange,
  customer,
}) {
  const isCompany = customer.isCompany();
  const label = customer.type
    ? `Tax ID ${isCompany ? '(e.g. EIN)' : '(e.g. last 4 of SSN)'}`
    : 'Tax ID';
  const fieldName = isCompany ? 'business_tax_id' : 'ssn_last_4';
  const idProvided = isCompany
    ? customer.business_tax_id_provided
    : customer.isIndividual()
    ? customer.ssn_last_4_provided || customer.personal_id_number_provided
    : false;
  const placeholder = idProvided
    ? 'ID provided, enter new ID to overwrite'
    : 'Tax ID';

  return (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={label}
      name={fieldName}
      onChange={onChange}
      placeholder={placeholder}
      value={getValue(customer[fieldName], defaultValue)}
    />
  );
}

export const TimeZone = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    onChange,
    customer,
  }) => (
    <TimeZoneSelector
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      label={t('.timezone', intl, __filenamespace)}
      name="time_zone"
      onChange={(e, value) => onChange(e, value, 'time_zone')}
      placeholder={t('.select_timezone', intl, __filenamespace)}
      value={getValue(customer.time_zone, defaultValue)}
    />
  )
);

export const WebAddress = injectIntl(
  ({
    classes,
    defaultValue,
    disabled,
    errorText,
    intl,
    onChange,
    customer,
    protocolRequired,
  }) => (
    <TextField
      classes={classes}
      disabled={disabled}
      errorText={errorText}
      fullWidth
      label={`Web Address${
        protocolRequired ? ' (include http:// or https://)' : ''
      }`}
      name="external_url"
      onChange={onChange}
      placeholder={t('.url', intl, __filenamespace)}
      value={getValue(customer.external_url, defaultValue)}
    />
  )
);
export const BusinessTypeInfo = injectIntl(({ intl, customer, onChange }) => (
  <Dropdown
    label={t('.business_type', intl, __filenamespace)}
    fullWidth
    placeholder={t('actions.select', intl)}
    name="business_type"
    value={customer.get('business_type')}
    onChange={e => onChange(e, e.target.value, 'business_type')}
    items={BusinessTypesOptions}
  />
));

export const SportsOffered = injectIntl(({ intl, onChange, sportsOffered }) => (
  <div>
    <Typography variant="caption">
      <FormattedMessage id={messageId('.sports_offered', __filenamespace)} />
    </Typography>
    <SportOfferedAutocomplete
      options={SportsOfferedOptions.sort()}
      name="sports_offered"
      value={sportsOffered}
      onChange={onChange}
      placeholder={t('actions.select', intl)}
    />
  </div>
));
