/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, TextField as MUITextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { TextField, Typography } from '@upperhand/playmaker';

import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import {
  STANDARD_DRAWER_WIDTH,
  parseHTML,
  smallScreen,
} from 'shared/utils/DOMUtils';
import { uhColors, uhContrast } from 'shared/styles/uhStyles.jsx';

import MembershipAgreementFormActions from 'shared/actions/MembershipAgreementFormActions';

import MembershipAgreementFormStore from 'shared/stores/MembershipAgreementFormStore.jsx';

const styles = {
  buttonContainer: {
    padding: '1em 2em',
    justifyContent: 'center',
  },
  drawer: {
    width: STANDARD_DRAWER_WIDTH,
  },
  header: merge(
    {
      width: '100%',
      padding: '.5em 1em .5em 2em',
    },
    uhContrast.lightOnDark
  ),
  scrollableContent: merge(
    {
      padding: '1em 1em',
    },
    uhContrast.darkOnLight
  ),
  content: merge(
    {
      padding: '1em 1em',
      overflowY: 'auto',
      lineHeight: '17px',
      fontSize: 15,
      borderBottom: '1px solid rgb(224, 224, 224)',
    },
    uhContrast.darkOnLight
  ),
  downloadButtonWrapper: {
    backgroundColor: 'var(--color-secondary)',
    color: 'var(--color-white)',
    width: '90%',
    height: '42px',
    borderRadius: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  section: {
    margin: '10 10 0 10',
    padding: '10 10 0 10',
    flexGrow: 1,
  },

  downloadButton: {
    color: 'var(--color-white)',
  },
};

function AgreementFormDrawer(props) {
  const {
    intl,
    membershipAgreementFormStore: {
      showAgreementDrawer,
      signedAt,
      name,
      clientIds,
      membershipId,
      hasSignedAgreement: hasDownloadEnabled,
    },
  } = props;

  if (!showAgreementDrawer) {
    return null;
  }
  const isMobile = smallScreen();

  const membershipAgreementContent = currentCustomer().membership_agreement;
  return (
    <Drawer
      disableEnforceFocus
      open={showAgreementDrawer}
      anchor="right"
      sx={{ zIndex: 1301 }}
      PaperProps={{ sx: styles.drawer }}
      onClose={MembershipAgreementFormActions.closeAgreementDrawer}
    >
      <Box sx={styles.header}>
        <FlexBoxJustify style={{ alignItems: 'center', fontSize: '16px' }}>
          <FormattedMessage
            id={messageId('.agreement_title', __filenamespace)}
          />
          <IconButton
            size="large"
            onClick={MembershipAgreementFormActions.closeAgreementDrawer}
          >
            <CloseIcon sx={{ color: uhColors.navIconGrey }} />
          </IconButton>
        </FlexBoxJustify>
      </Box>
      <Box sx={styles.scrollableContent}>
        <WysiwygEditor
          value={membershipAgreementContent}
          height={isMobile ? '47vh' : '61vh'}
          readOnly
        />
      </Box>

      <Box sx={styles.content}>
        <TextField
          label={t('.name_label', intl, __filenamespace)}
          fullWidth
          value={name}
          disabled={hasDownloadEnabled}
          onChange={e =>
            MembershipAgreementFormActions.handleNameChange(e.target.value)
          }
        />
        <Box sx={{ marginTop: '1rem' }}>
          <Typography variant="subtitle2">
            {t('.accept_date_label', intl, __filenamespace)}
          </Typography>
          <DatePicker
            disableMaskedInput
            inputFormat="MM/DD/YYYY"
            value={signedAt}
            disabled={hasDownloadEnabled}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <MUITextField
                fullWidth
                style={{ marginTop: '5px' }}
                {...params}
              />
            )}
            DialogProps={{ disableEnforceFocus: true }}
            onChange={date =>
              MembershipAgreementFormActions.handleDateChange(date)
            }
          />
        </Box>
      </Box>

      <Stack spacing={2} direction="row" sx={styles.buttonContainer}>
        {hasDownloadEnabled ? (
          <PDFDownloadLink
            style={styles.downloadButtonWrapper}
            document={
              <Document>
                <Page size="A4">
                  <View style={styles.section}>
                    <Text>{parseHTML(membershipAgreementContent)}</Text>
                    <Text style={{ marginTop: '20px' }}>
                      {t('.client_name', intl, __filenamespace, {
                        name,
                      })}
                    </Text>
                    <Text>
                      {t('.signed_at', intl, __filenamespace, {
                        date: signedAt && moment(signedAt).format('MM/DD/YYYY'),
                      })}
                    </Text>
                  </View>
                </Page>
              </Document>
            }
            fileName="membership_agreement.pdf"
          >
            <Button
              sx={styles.downloadButton}
              startIcon={<CloudDownloadIcon />}
            >
              {t('.download', intl, __filenamespace)}
            </Button>
          </PDFDownloadLink>
        ) : (
          <Button
            fullWidth
            variant="contained"
            onClick={() =>
              MembershipAgreementFormActions.handleSubmit({
                client_ids: clientIds,
                agreement: membershipAgreementContent,
                name,
                signed_at: signedAt,
                membership_id: membershipId,
              })
            }
          >
            {t('actions.submit', intl)}
          </Button>
        )}
      </Stack>
    </Drawer>
  );
}

export default altContainer({
  stores: {
    membershipAgreementFormStore: MembershipAgreementFormStore,
  },
})(injectIntl(AgreementFormDrawer));
