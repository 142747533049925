import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import uhTheme from '_uh_theme.jsx';

import { FlexBox, FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import Divider from '@mui/material/Divider';
import ClientHeaderText from './ClientHeaderText.jsx';
import StaffHeaderText from './StaffHeaderText.jsx';

const styles = {
  root: {
    flexDirection: 'column',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    marginTop: -25,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: '-45px',
    marginRight: '-45px',
  },
  column: {
    flex: '0 1 85.5rem',
    margin: `${LEFT_MARGIN_PIXELS}px 45px 0 45px`,
    maxWidth: '100%',
  },
  logoContainer: {
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'white',
    margin: '0px auto 0px',
    padding: '16px 16px 20px 16px',
    borderBottom: '1px solid var(--color-divide)',
  },
  staffHeaderTextContainer: {
    color: 'white',
    backgroundColor: uhTheme.palette.headerGrey,
    padding: '25px 0px',
    margin: '0 auto',
    textAlign: 'center',
  },
  modalHeaderTextContainer: {
    fontSize: 12,
    textAlign: 'center',
    margin: '0 50px',
    lineHeight: 1.3,
  },
};

function Header({
  customerStore: { customer },
  clientHeader,
  step,
  userCreationStore,
  modalOpen,
  customerUserStore,
}) {
  return (
    <>
      {!modalOpen && (
        <div>
          <div>
            <div style={styles.logoContainer}>
              <img
                style={{ maxHeight: 56 }}
                src={customer.logo.getAlternative('email')}
                alt={customer.name}
              />
            </div>
          </div>
          <div>
            {clientHeader ? (
              <FlexBoxJustifyCenter style={styles.root}>
                <FlexBox
                  style={merge(styles.row, { justifyContent: 'center' })}
                >
                  <div style={styles.column}>
                    <ClientHeaderText
                      step={step}
                      userCreationStore={userCreationStore}
                    />
                  </div>
                </FlexBox>
              </FlexBoxJustifyCenter>
            ) : (
              <div style={styles.staffHeaderTextContainer}>
                <StaffHeaderText
                  customer={customer}
                  customerUserStore={customerUserStore}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {modalOpen && (
        <div style={styles.modalHeaderTextContainer}>
          <FormattedMessage
            style={{ marginTop: 10 }}
            id={
              step === 'profileCreation'
                ? messageId('.header_modal_participant', __filenamespace)
                : messageId('.header_modal_manager', __filenamespace)
            }
          />
          <Divider style={{ marginTop: 12 }} />
        </div>
      )}
    </>
  );
}

Header.propTypes = {
  customerStore: PropTypes.object.isRequired,
  clientHeader: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,
  userCreationStore: PropTypes.object.isRequired,
};

export default Header;
