/* eslint-disable react/jsx-props-no-spreading */
// Disable: this is our routes file and is one of the few times we want to just spread props
import * as React from 'react';
import { Link, Route, Outlet } from 'react-router-dom';
import AltContainer from 'alt-container';
import { Button, Grid, Typography } from '@upperhand/playmaker';

import ListComponent from 'containers/devTools/test/ListComponent.jsx';
import Show from 'containers/devTools/test/Show.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';

import { isAdmin } from 'routes/helpers/routePermissions';

import * as ClientSource from 'sources/ClientSource';
import * as PaymentSource from 'sources/PaymentSource';
import * as PaymentPlanSource from 'sources/PaymentPlanSource';

import {
  ClientDataStore,
  PaymentDataStore,
  PaymentPlanDataStore,
} from 'dataStores';

// http://localhost:9000/customers/7-z-upper-hand/devTools

export default function devRoutes() {
  return (
    <Route
      path=":customer_id/devtools"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin]}>
          <div style={{ padding: 20 }}>
            <Typography variant="h1">Dev testing zone</Typography>
            <div style={{ margin: '20px 5px' }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Link to="clients">
                    <Button>Clients</Button>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link to="payments">
                    <Button>Payments</Button>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link to="payment_plans">
                    <Button>Payment Plans</Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
            <Outlet />
          </div>
        </AuthorizedPageWrapper>
      }
    >
      <Route
        path="clients"
        element={
          <AltContainer
            stores={{
              dataStore: ClientDataStore,
            }}
          >
            <ListComponent dataSource={ClientSource} paginationKey="clients" />
          </AltContainer>
        }
      />
      <Route
        path="clients/:entityId"
        element={
          <AltContainer
            stores={{
              dataStore: ClientDataStore,
            }}
          >
            <Show dataSource={ClientSource} paginationKey="clients" />
          </AltContainer>
        }
      />
      <Route
        path="payments"
        element={
          <AltContainer
            stores={{
              dataStore: PaymentDataStore,
            }}
          >
            <ListComponent
              dataSource={PaymentSource}
              paginationKey="payments"
            />
          </AltContainer>
        }
      />
      <Route
        path="payments/:entityId"
        element={
          <AltContainer
            stores={{
              dataStore: PaymentDataStore,
            }}
          >
            <Show dataSource={PaymentSource} paginationKey="payments" />
          </AltContainer>
        }
      />
      <Route
        path="payment_plans"
        element={
          <AltContainer
            stores={{
              dataStore: PaymentPlanDataStore,
            }}
          >
            <ListComponent
              dataSource={PaymentPlanSource}
              paginationKey="payment_plans"
            />
          </AltContainer>
        }
      />
      <Route
        path="payment_plans/:entityId"
        element={
          <AltContainer
            stores={{
              dataStore: PaymentPlanDataStore,
            }}
          >
            <Show
              dataSource={PaymentPlanSource}
              paginationKey="payment_plans"
            />
          </AltContainer>
        }
      />
    </Route>
  );
}
