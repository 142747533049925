import * as React from 'react';
import PropTypes from 'prop-types';

import { uhColors } from 'shared/styles/uhStyles.jsx';

function TermsTemplate({ terms }) {
  return (
    <div
      className="customer-terms"
      style={{
        fontSize: 15,
        lineHeight: '22px',
        color: uhColors.leftNavGrey,
      }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: terms }}
    />
  );
}

TermsTemplate.propTypes = {
  terms: PropTypes.string.isRequired,
};

export default TermsTemplate;
