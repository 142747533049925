class QuickpayModalActions {
  constructor() {
    this.generateActions(
      'checkoutError',
      'checkoutSuccess',
      'changePaymentAmount',
      'closeModal',
      'fetchClientError',
      'fetchClientSuccess',
      'toggleQuickpayModal',
      'toggleCalendarQuickpayModal',
      'togglePayAllQuickpayModal',
      'fetchBalanceSuccess',
      'fetchBalanceDetailsSuccess',
      'fetchBalanceDetailsError',
      'fetchBalanceError',
      'fetchOrderItemSuccess',
      'fetchOrderItemError',
      'toggleAddonProductModal',
      'listRetailProducts',
      'listRetailProductsSuccess',
      'listRetailProductsError',
      'selectRetailProduct',
      'selectOption',
      'setQuantity',
      'listOptionTypesSuccess',
      'listOptionTypesError',
      'listVariantsSuccess',
      'listVariantsError',
      'addRetailProduct',
      'addRetailProductSuccess',
      'addRetailProductError',
      'removeRetailProduct',
      'removeRetailProductSuccess',
      'removeRetailProductError',
      'fetchAddonSuccess',
      'fetchAddonError',
      'changeAccountCreditAmount'
    );
  }
}

export default alt.createActions(QuickpayModalActions);
