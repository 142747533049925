import React from 'react';
import moment from 'moment-timezone';
import altContainer from 'shared/hocs/altContainer.jsx';
import { injectIntl } from 'react-intl';
import { compose } from 'shared/utils/SharedUtils.js';

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ContentModal, Dropdown, Grid, Typography } from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';

import { t } from 'shared/utils/LocaleUtils.js';

import ExportModalActions from './Actions';
import ExportModalStore, { POSSIBLE_REPORTS } from './Store';

const DATE_PICKER_PLACEHOLDER = `\t/  / `;

const styles = {
  typography: {
    paddingBottom: 14,
  },
};

function ExportModal({
  exportModalStore: {
    isOpen,
    selectedReport,
    startDate,
    endDate,
    isLoadingReport,
  },
  intl,
  classes,
}) {
  const intlLabels = Object.keys(POSSIBLE_REPORTS).map(key => ({
    label: t(key, intl, __filenamespace),
    value: key,
  }));

  return (
    <ContentModal
      open={isOpen}
      onSuccess={ExportModalActions.download}
      onAbort={ExportModalActions.closeModal}
      title={t('.title', intl, __filenamespace)}
      successLabel={
        isLoadingReport
          ? t('.loading', intl, __filenamespace)
          : t('.download', intl, __filenamespace)
      }
      cancelLabel={t('.cancel', intl, __filenamespace)}
      size="2x"
    >
      <Typography className={classes.typography}>
        {t('.description', intl, __filenamespace)}
      </Typography>

      <Dropdown
        fullWidth
        value={selectedReport}
        items={intlLabels}
        onChange={e => ExportModalActions.selectReport(e.target.value)}
        onSuccess={ExportModalActions.download}
        disabled={isLoadingReport}
      />

      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            renderInput={params => (
              <TextField
                fullWidth
                variant="standard"
                placeholder={DATE_PICKER_PLACEHOLDER}
                style={{ marginTop: 15 }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
            inputFormat="MM-DD-YY"
            label={t('.start_date', intl, __filenamespace)}
            disabled={isLoadingReport}
            value={startDate}
            DialogProps={{ disableEnforceFocus: true }}
            onChange={value => ExportModalActions.setStartDate(moment(value))}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            renderInput={params => (
              <TextField
                fullWidth
                variant="standard"
                placeholder={DATE_PICKER_PLACEHOLDER}
                style={{ marginTop: 15 }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
            inputFormat="MM-DD-YY"
            label={t('.end_date', intl, __filenamespace)}
            disabled={isLoadingReport}
            value={endDate}
            DialogProps={{ disableEnforceFocus: true }}
            onChange={value => ExportModalActions.setEndDate(moment(value))}
          />
        </Grid>
      </Grid>
    </ContentModal>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      exportModalStore: ExportModalStore,
    },
  }),
  withStyles(styles)
)(ExportModal);
