import { fromJS, List, Map } from 'immutable';

import PayoutsActions from 'shared/actions/PayoutsActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

const payoutsURL = () => `customers/${currentCustomer().id}/payouts`;
const balanceURL = () => `customers/${currentCustomer().id}/balance`;

class PayoutsStore extends UpperHandStore {
  constructor() {
    super();

    this.payouts = List();
    this.balance = Map();
    this.fetchedAll = false;
    this.historyDrawerOpen = false;
    this.balanceLoaded = false;
    this.payoutsLoaded = false;

    this.bindListeners({
      handleToggleHistoryDrawerOpen: PayoutsActions.TOGGLE_HISTORY_DRAWER_OPEN,
      handleFetchBalance: PayoutsActions.FETCH_BALANCE,
      handleFetchBalanceSuccess: PayoutsActions.FETCH_BALANCE_SUCCESS,
      handleFetchBalanceError: PayoutsActions.FETCH_BALANCE_ERROR,
      handleList: PayoutsActions.LIST,
      handleListSuccess: PayoutsActions.LIST_SUCCESS,
      handleListError: PayoutsActions.LIST_ERROR,
    });
  }

  handleToggleHistoryDrawerOpen(open) {
    this.historyDrawerOpen =
      open !== undefined ? open : !this.historyDrawerOpen;
  }

  handleFetchBalance() {
    this.balanceLoaded = false;

    return uhApiClient.get({
      url: balanceURL(),
      success: PayoutsActions.fetchBalanceSuccess,
      error: PayoutsActions.fetchBalanceError,
    });
  }

  handleFetchBalanceSuccess(data) {
    this.balanceLoaded = true;

    this.balance = fromJS(data);
  }

  handleFetchBalanceError(...args) {
    this.notifyError('error while fetching balance', args);
  }

  handleList() {
    this.payoutsLoaded = false;

    const data = { per_page: 10 };

    if (this.payouts.size) {
      data.starting_after = this.payouts.last().id;
    }

    return uhApiClient.get({
      url: payoutsURL(),
      data,
      success: PayoutsActions.listSuccess,
      error: PayoutsActions.listError,
    });
  }

  handleListSuccess(data) {
    const { payouts, has_more: hasMore } = data;

    this.payouts = List(
      payouts
        .reduce((currentPayouts, result) => {
          if (currentPayouts.find(payout => payout.id === result.id)) {
            return currentPayouts;
          }
          return currentPayouts.push(result);
        }, this.payouts)
        .sort((a, b) => b.created - a.created)
    );

    if (!hasMore) {
      this.fetchedAll = true;
    }

    this.payoutsLoaded = true;
  }

  handleListError(...args) {
    this.notifyError('error while listing payouts', args);
  }
}

export default alt.createStore(PayoutsStore, 'PayoutsStore');
