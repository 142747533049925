import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Date(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99978 14.3999C4.46378 14.3999 1.59978 11.5359 1.59978 7.99994C1.59978 4.46394 4.46378 1.59994 7.99978 1.59994C11.5348 1.59994 14.3988 4.46394 14.3988 7.99994C14.3988 11.5359 11.5348 14.3999 7.99978 14.3999ZM7.991 0C3.575 0 0 3.584 0 8C0 12.416 3.575 16 7.991 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 7.991 0Z"
        fill="var(--color-tab-gray)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99976 3H6.99976V8.234L11.3148 10.754L11.7578 9.77L7.99976 7.634V3Z"
        fill="var(--color-tab-gray)"
        stroke="var(--color-tab-gray)"
        strokeWidth="0.364583"
      />
    </SvgIcon>
  );
}

export default Date;
