/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Divider from '@mui/material/Divider';

import CreditPassPackageSummary from 'cart/index/components/_CreditPassPackageSummary.jsx';
import RegistrationPackageSummary from 'cart/index/components/_RegistrationPackageSummary.jsx';
import MembershipSubscriptionPackageSummary from 'cart/index/components/_MembershipSubscriptionPackageSummary.jsx';
import VariantSummary from 'cart/index/components/_VariantSummary.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  divider: {
    marginTop: '15px',
    marginBottom: '20px',
    height: '2px',
    backgroundColor: '#CACBCA',
  },
};

function ItemSummary(props) {
  // eslint-disable-next-line react/destructuring-assignment
  switch (props.item.orderable_type) {
    case 'registration_package':
      return <RegistrationPackageSummary {...props} />;
    case 'membership_subscription_package':
      return <MembershipSubscriptionPackageSummary {...props} />;
    case 'credit_pass_package':
      return <CreditPassPackageSummary {...props} />;
    case 'variant':
      return <VariantSummary {...props} />;
    default:
      return <div />;
  }
}

function ItemSummaryList({ items, dividerStyle, ...props }) {
  return (
    <div>
      {items.map(item => (
        <div key={item.id}>
          <ItemSummary
            key={`${item.orderable_type}-${item.id}`}
            item={item}
            {...props}
          />
          <Divider sx={merge(styles.divider, dividerStyle)} />
        </div>
      ))}
    </div>
  );
}

export default ItemSummaryList;
