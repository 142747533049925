import * as React from 'react';
import AltContainer from 'alt-container';
import Divider from '@mui/material/Divider';
import { List, Map, Set } from 'immutable';
import { FormattedMessage } from 'react-intl';

import Circle from 'shared/components/Circle.jsx';
import { mapCreditGrantsToType } from 'shared/records/CreditGrant';
import CreditGrantEventStore from 'contacts/index/stores/CreditGrantEventStore';
import CreditGrantsActions from 'shared/actions/CreditGrantsActions';
import CreditGrantsStore from 'shared/stores/CreditGrantsStore';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import Expander from 'shared/components/Expander.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  expanderLabelStyle: {
    color: uhColors.primaryMain,
    fontSize: 16,
    padding: '0 10px 0 16px',
    marginTop: 10,
  },

  expanderStyle: {
    outlineStyle: 'solid',
    outlineColor: 'rgb(224, 224, 224)',
    outlineWidth: 2,
    fontSize: 14,
  },
};

function Count({ n }) {
  return n === Infinity ? (
    <FormattedMessage id={messageId('.unlimited', __filenamespace)} />
  ) : (
    <span>{n}</span>
  );
}

class CreditExpander extends React.PureComponent {
  componentDidMount() {
    const { clientId } = this.props;

    if (clientId) {
      CreditGrantsActions.list.defer({ clientIds: Set([clientId]) });
    }
  }

  render() {
    const { clientId, creditGrantsStore, eventStore, eventTypeStore } =
      this.props;

    const creditGrants = creditGrantsStore.creditGrants.get(clientId, List());

    if (creditGrants.isEmpty()) {
      return null;
    }

    const creditCountByType = mapCreditGrantsToType(creditGrants);
    const totalCredits = creditGrants.reduce(
      (sum, g) => sum + (g.unlimited ? Infinity : g.available),
      0
    );

    return (
      <Expander
        labelContainerStyle={styles.expanderLabelStyle}
        style={styles.expanderStyle}
        label={
          <FlexBoxJustify style={{ width: 250 }}>
            <FormattedMessage id={messageId('.credits', __filenamespace)} />
            <div style={{ alignItems: 'right' }}>
              <Count n={totalCredits} />
            </div>
          </FlexBoxJustify>
        }
      >
        <div style={{ padding: '0 16px 6px 16px' }}>
          <Divider style={{ marginBottom: 16, height: 2 }} />
          {creditCountByType.get('all_events') && (
            <FlexBoxJustify style={{ marginBottom: 10 }}>
              <FormattedMessage
                id={messageId('.all_events', __filenamespace)}
              />
              <Count n={creditCountByType.get('all_events')} />
            </FlexBoxJustify>
          )}

          {creditCountByType
            .get('event_types', Map())
            .map((count, id) => {
              const eventType = eventTypeStore.findById(id) || {
                name: '',
                color: 'var(--color-white)',
              };

              return (
                <FlexBoxJustify style={{ marginBottom: 10 }}>
                  <FlexBoxCenter>
                    <Circle
                      diameter={6}
                      color={eventType.color}
                      style={{ marginRight: 10 }}
                    />
                    <div>{eventType.name}</div>
                  </FlexBoxCenter>
                  <Count n={count} />
                </FlexBoxJustify>
              );
            })
            .toList()}

          {creditCountByType
            .get('events', Map())
            .map((count, id) => {
              const event = eventStore.events.get(id, {
                title: '',
                event_type: { color: 'var(--color-white)' },
              });

              return (
                <FlexBoxJustify style={{ marginBottom: 10 }}>
                  <FlexBoxCenter>
                    <Circle
                      diameter={6}
                      color={event.event_type.color}
                      style={{ marginRight: 10 }}
                    />
                    <div>{event.title}</div>
                  </FlexBoxCenter>
                  <Count n={count} />
                </FlexBoxJustify>
              );
            })
            .toList()}
        </div>
      </Expander>
    );
  }
}

function CreditExpanderAltWrapper(props) {
  return (
    <AltContainer
      stores={{
        creditGrantsStore: CreditGrantsStore,
        eventStore: CreditGrantEventStore,
        eventTypeStore: EventTypeListingStore,
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CreditExpander {...props} />
    </AltContainer>
  );
}

export default CreditExpanderAltWrapper;
