import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { STANDARD } from 'shared/utils/UserUtils.jsx';
import { snakeToTitleCase } from 'shared/utils/FormattingUtils.jsx';

const styles = {
  header: {
    fontSize: '1.5em',
  },
  name: {
    fontWeight: 600,
  },
  message: {
    marginTop: 15,
  },
};

function StaffHeaderText({ customer, customerUserStore }) {
  const { staffMember } = customerUserStore;
  const role =
    staffMember.role === STANDARD
      ? 'Staff'
      : snakeToTitleCase(staffMember.role);
  return (
    <div>
      <div style={styles.header}>
        <span>
          <FormattedMessage
            id={messageId('.staff_role', __filenamespace)}
            values={{ role }}
          />
        </span>
        <span style={styles.name}>{customer.name}</span>
      </div>
      <div style={styles.message}>
        <FormattedMessage id={messageId('.staff_message', __filenamespace)} />
      </div>
    </div>
  );
}

StaffHeaderText.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default StaffHeaderText;
