import * as React from 'react';
import { injectIntl } from 'react-intl';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import DeleteConfirmationModal from 'shared/components/DeleteConfirmationModal.jsx';
import DateSpecificDaytimePicker from 'shared/components/scheduling/DateSpecificDaytimePicker.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import LocationFormFields from 'shared/components/LocationFormFields.jsx';
import BlackoutDatePicker from 'shared/components/scheduling/BlackoutDatePicker.jsx';
import MiniCalendar from 'shared/components/calendar/MiniCalendar.jsx';

import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';

import { FlexBox } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import CustomerActions from 'shared/actions/CustomerActions.jsx';

const styles = {
  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '90%',
  },

  column: {
    flex: `0 1 25.5rem`,
    marginTop: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
  },

  link: {
    color: uhColors.resendLink,
    cursor: 'pointer',
  },

  spacer: {
    color: '#e5e6e5',
  },
};

function Address({ address, style }) {
  return (
    <div style={style}>
      <div style={{ marginTop: 4 }}>{address.line_1}</div>
      <div style={{ marginTop: 4 }}>{address.line_2}</div>
      <div style={{ marginTop: 4 }}>
        {address.city}, {address.state} {address.postal_code}
      </div>
    </div>
  );
}

function Location({ location }) {
  return (
    <div style={{ marginTop: 25 }}>
      <div style={{ fontWeight: 'bold', marginTop: 10 }}>{location.name}</div>
      <div style={{ marginTop: 10 }}>{location.address.address_type.name}</div>
      <Address address={location.address} style={{ marginTop: 10 }} />
      <div style={{ marginTop: 15 }}>
        <span
          role="presentation"
          style={styles.link}
          onClick={() => LocationActions.stageLocation(location.id)}
        >
          Edit
        </span>
        <span style={styles.spacer}>&emsp;|&emsp;</span>
        <span
          role="presentation"
          style={styles.link}
          onClick={() => LocationActions.deleteRequested(location.id)}
        >
          Remove
        </span>
      </div>
    </div>
  );
}

function LocationForm({
  addressTypes,
  location,
  errors,
  saveInProgress,
  style,
}) {
  return (
    <Paper style={merge({ padding: 16 }, style)}>
      <LocationFormFields
        addressTypes={addressTypes}
        errors={errors}
        location={location}
      />

      <Button
        variant="contained"
        disabled={saveInProgress}
        style={{ marginTop: 10, marginRight: 8 }}
        onClick={() =>
          LocationActions.createOrUpdate({
            clearOnSuccess: true,
            showSuccessMessage: true,
          })
        }
      >
        {location.id ? 'Save' : 'Add Location'}
      </Button>

      {location.id && (
        <Button
          variant="contained"
          color="default"
          disabled={saveInProgress}
          style={{ marginTop: 10, marginLeft: 8 }}
          onClick={() => LocationActions.clearLocation()}
        >
          Cancel
        </Button>
      )}
    </Paper>
  );
}

function LocationsTab({ locations, addressTypes, customerStore }) {
  const { activeSaveButton, customer, fieldErrors } = customerStore;
  const { date_specific_daytimes: dateSpecificTimes } = customer;

  const handleAvailabilityChange = (keyPath, value) => {
    CustomerActions.handleAvailabilityChange({ keyPath, value });
  };

  return (
    <FlexBox style={styles.root}>
      <div style={styles.column}>
        <h2>{locations.location.id ? 'Edit Location' : 'Add Location'}</h2>

        <LocationForm
          location={locations.location}
          errors={locations.errors}
          addressTypes={addressTypes.addressTypes}
          saveInProgress={locations.saveInProgress}
          style={{ marginTop: 25 }}
        />
      </div>
      <div style={{ width: 50 }} />
      <div style={styles.column}>
        <h2>Locations</h2>
        {locations.allLocations.map(location => (
          <Location key={location.id} location={location} />
        ))}
      </div>
      <div style={styles.column}>
        <h2>Business Availability</h2>
        <MiniCalendar
          firstDayOfWeek={0}
          initialDate={new Date()}
          locale="en-US"
          onSelectDate={(_event, date) => {
            CustomerActions.setBlackoutDates({ date, index: null });
          }}
        />

        <BlackoutDatePicker
          excludedDates={customer.get('blackout_dates')}
          onAddDate={() => {
            let date = new Date();
            if (customer.get('blackout_dates').length > 0) {
              date = new Date(customer.get('blackout_dates').at(-1));
              date = date.setDate(date.getDate() + 1);
            }

            CustomerActions.setBlackoutDates({
              date,
              index: null,
            });
          }}
          onChangeDate={(value, index) => {
            CustomerActions.setBlackoutDates({ date: value, index });
          }}
          onDeleteDate={index => {
            CustomerActions.deleteBlackoutDates(index);
          }}
        />
        <DateSpecificDaytimePicker
          addBtnLabel="Date Specific Availability"
          availabilitySchedule={
            new AvailabilitySchedule({
              start_date: new Date(),
              date_specific_daytimes: dateSpecificTimes,
            })
          }
          handleAvailabilityScheduleChange={handleAvailabilityChange}
          fieldErrors={fieldErrors?.errors}
        />
        <Button
          variant="contained"
          disabled={!activeSaveButton}
          style={{ marginTop: 10, marginRight: 8 }}
          onClick={() => {
            CustomerActions.saveBlackoutDates();
          }}
        >
          Save
        </Button>
      </div>

      <DeleteConfirmationModal
        awaitingConfirmation={locations.awaitingConfirmation}
        cancelDelete={LocationActions.deleteCancelled}
        doDelete={LocationActions.delete}
        name="location"
        title="Remove Location"
      />
    </FlexBox>
  );
}

export default injectIntl(LocationsTab);
