import { Map } from 'immutable';
import { RetailVendorsSource } from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import Actions from './Actions';

class RetailVendorsStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      mounted: Actions.mounted,
      listResult: Actions.listResult,
      openDialog: Actions.openDialog,
      closeDialog: Actions.closeDialog,
      changeModal: Actions.changeModal,
      create: Actions.create,
      createResult: Actions.createResult,
      update: Actions.update,
      updateResult: Actions.updateResult,
    });
  }

  reset() {
    this.loading = false;
    this.isModalOpen = false;
    this.modalData = Map();
  }

  mounted(productId) {
    this.reset();

    this.list(productId);
  }

  list() {
    const params = {
      per_page: 100,
    };

    this.loading = true;

    RetailVendorsSource.list({
      params,
      success: Actions.listResult,
      error: Actions.listResult,
    });
  }

  listResult() {
    this.loading = false;
  }

  openDialog(params) {
    this.isModalOpen = true;
    this.modalData = Map(params);
  }

  closeDialog() {
    this.isModalOpen = false;
    this.modalData = Map();
  }

  changeModal([key, value]) {
    this.modalData = this.modalData.set(key, value);
  }

  create() {
    const params = {
      name: this.modalData.get('name'),
    };

    RetailVendorsSource.post({
      params,
      success: Actions.createResult,
      error: Actions.createResult,
    });
  }

  createResult() {
    this.closeDialog();
  }

  update(name) {
    const params = {
      name: this.modalData.get('name'),
    };

    RetailVendorsSource.patch({
      id: this.modalData.get('id'),
      params,
      success: Actions.updateResult,
      error: Actions.updateResult,
    });
  }

  updateResult() {
    this.closeDialog();
  }
}

export default alt.createStore(RetailVendorsStore, 'RetailVendorsStore');
