import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';

import { Grid, Typography } from '@upperhand/playmaker';

import CartBar from 'point_of_sale/components/CartBar.jsx';
import CreditPass from 'shared/records/CreditPass.js';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import Header from 'point_of_sale/components/Header.jsx';
import Membership from 'shared/records/Membership.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import Product from 'shared/records/Product.js';
import SearchBar from 'shared/components/SearchBar.jsx';
import {
  EventCard,
  MembershipCard,
  CreditPassCard,
  RetailProductCard,
} from 'point_of_sale/components/products/list/cards';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, uhContrast } from 'shared/styles/uhStyles.jsx';

const styles = {
  eventCard: {
    cursor: 'pointer',
    position: 'relative',
    marginBottom: 6,
  },
  eventCardsContainer: {
    flex: '1 1 auto',
    padding: 10,
    overflowY: 'auto',
  },
  header: merge(uhContrast.lightOnDark, {
    height: 56,
    fontSize: 15,
  }),
  paginationContainer: {
    flex: '0 0 auto',
  },
  root: {
    backgroundColor: uhColors.headerGrey,
    flexDirection: 'column',
    height: '100%',
  },
  searchBar: {
    backgroundColor: 'white',
    fontSize: 16,
    height: 56,
    padding: '0 15px',
  },
  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },
};

const toProductCard = product => {
  switch (product.constructor) {
    case Membership:
      return (
        <MembershipCard
          key={`membership-card-${product.id}`}
          membership={product}
        />
      );
    case CustomerEvent:
      return <EventCard key={`event-card-${product.id}`} event={product} />;
    case Product:
      return (
        <RetailProductCard
          key={`retail-product-card-${product.id}`}
          product={product}
        />
      );
    case CreditPass:
      return (
        <CreditPassCard
          key={`credit-pass-card-${product.id}`}
          creditPass={product}
        />
      );
    default:
      return null;
  }
};

const clearFilters = () => POSProductListActions.clearFilters();

function ProductList({ posStore, posCartStore, posProductListStore, intl }) {
  return (
    <Grid container direction="column">
      <div className="product-list" style={{ flex: '0 0 auto' }}>
        <Header selectedClient={posStore.selectedClient} />
        <CartBar
          cart={posCartStore.cart}
          onCartButtonClick={POSProductListActions.cartButtonClicked}
        />

        <Divider style={{ height: 2 }} />

        <SearchBar
          autoFocus
          hintText={t('.search_hint_text', intl, __filenamespace)}
          hintStyle={{ color: uhColors.hint }}
          iconStyle={styles.searchBarIcon}
          onTypingEnd={POSProductListActions.searchStringUpdated}
          searchText={posProductListStore.searchString}
          style={styles.searchBar}
        />

        <Divider style={{ height: 2 }} />

        {!posProductListStore.productsTypes.isEmpty() && (
          <div
            role="presentation"
            style={{
              padding: '16px 10px',
              color: 'white',
              backgroundColor: uhColors.charcoalBlack,
              cursor: 'pointer',
            }}
            onClick={clearFilters}
          >
            <Typography variant="button">
              <FormattedMessage
                id={messageId('.clear_filters', __filenamespace)}
              />
            </Typography>
          </div>
        )}
      </div>

      <div id="product-list" style={styles.eventCardsContainer}>
        {posProductListStore.products.map(toProductCard).toList()}
      </div>

      <div style={styles.paginationContainer}>
        <Paginator
          compact
          currentPage={posProductListStore.page}
          perPage={posProductListStore.perPage}
          totalCount={posProductListStore.totalCount}
          onPageSelect={(page, _) => POSProductListActions.pageSelected(page)}
          style={styles.paginator}
        />
      </div>
    </Grid>
  );
}

export default injectIntl(ProductList);
