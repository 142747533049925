import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import WarningIcon from '@mui/icons-material/Warning';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';

import AccountSetupPaysafeStore from 'onboarding/stores/AccountSetupPaysafeStore.js';
import AccountSetupPaysafeActions from 'onboarding/actions/AccountSetupPaysafeActions.js';
import uhTheme from '_uh_theme.jsx';

import { FlexBox } from 'shared/components/FlexBox.jsx';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { href as mailLinkGenerator } from 'shared/components/MailTo.jsx';

const styles = {
  message: {
    backgroundColor: '#ff2f49',
    color: 'white',
    fontFamily: uhTheme.fontFamily,
    fontSize: '16px',
    padding: '20px 0',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  alertIcon: {
    marginRight: '8px',
  },
};

function AccountSetupIncompleteBanner({
  accountSetupPaysafeStore: { showBanner, accountStatus, migratingFromStripe },
}) {
  React.useEffect(() => {
    AccountSetupPaysafeActions.mounted.defer();
  }, []);

  const navigate = useNavigate();

  if (showBanner) {
    const navigateToCompleteAccount = () => {
      if (navigate) {
        navigate(customerScopedRoute('/complete_account'));
      } else {
        redirectTo({
          path: customerScopedRoute('complete_account'),
        });
      }
    };
    return (
      <FlexBox
        id="account-setup-incomplete-banner"
        style={styles.message}
        onClick={() => {
          const needToSubmitForm = ['UNSUBMITTED', 'PENDING'].includes(
            accountStatus
          );
          if (needToSubmitForm) {
            navigateToCompleteAccount();
          } else {
            window.location.href = mailLinkGenerator({
              email: 'support@getupperhand.com',
              subject: 'Credit Card Processing',
            });
          }
        }}
      >
        <div style={styles.alertIcon}>
          <WarningIcon />
        </div>
        <FormattedMessage
          id={messageId(`.${accountStatus}`, __filenamespace)}
          values={{ migratingFromStripe }}
        />
      </FlexBox>
    );
  }
  return null;
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      accountSetupPaysafeStore: AccountSetupPaysafeStore,
    },
  })
)(AccountSetupIncompleteBanner);
