import React from 'react';

import TeamTitle from 'team_mgmt/shared/components/_TeamTitle.jsx';
import TeamToolbar from 'team_mgmt/shared/components/_TeamToolbar.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';

import { Box } from '@mui/material';

const styles = {
  root: {
    fontSize: '16px',
  },

  container: {
    flex: '1',
    flexWrap: 'wrap',
    marginLeft: `${LEFT_MARGIN_PIXELS}px`,
  },

  membershipInfo: {
    marginLeft: `${LEFT_MARGIN_PIXELS}px`,
    paddingBottom: '10px',
  },

  rightContent: {
    flex: '0 0 60%',
    marginBottom: '10px',
  },

  titleAndDate: {
    flex: '1 0 auto',
    marginBottom: '10px',
  },
};

function TeamHeader({ team, actions, rightContent, style, containerStyle }) {
  return (
    <Box className="iphone-x-content" sx={{ ...styles.root, ...style }}>
      <TeamToolbar actions={actions} />
      <Box sx={{ ...styles.container, ...containerStyle }}>
        <TeamTitle team={team} sx={styles.titleAndDate} />
        <Box sx={styles.rightContent}>{rightContent}</Box>
      </Box>
    </Box>
  );
}

export default TeamHeader;
