import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card } from '@upperhand/playmaker';
import clsx from 'clsx';

import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';
import { BrowseEvent } from 'records';

import ClassScheduleCard from './ClassScheduleCard.jsx';
import FixedScheduleCard from './FixedScheduleCard.jsx';
import OpenBookingCard from './OpenBookingCard.jsx';

const BrowseEventCard = React.memo(({ browseEvent, viewMode, intl }) => {
  const { eventTypeColor, scheduleType, path } = browseEvent;
  const navigate = useNavigate();
  const hasDeadlineReached = browseEvent.hasDeadlineReached();

  let cardContent;
  switch (scheduleType) {
    case SCHEDULE_TYPE.openBooking:
      cardContent = <OpenBookingCard intl={intl} browseEvent={browseEvent} />;
      break;
    case SCHEDULE_TYPE.fixedSchedule:
      cardContent = (
        <FixedScheduleCard
          intl={intl}
          browseEvent={browseEvent}
          hasDeadlineReached={hasDeadlineReached}
        />
      );
      break;
    case SCHEDULE_TYPE.classSchedule:
      cardContent = (
        <ClassScheduleCard
          intl={intl}
          viewMode={viewMode}
          browseEvent={browseEvent}
          hasDeadlineReached={hasDeadlineReached}
        />
      );
      break;
    default:
      cardContent = null;
  }

  if (!cardContent) {
    return null;
  }

  return (
    <Card
      classes={{
        root: clsx(
          'browse-events__card',
          hasDeadlineReached && 'browse-events__card_deadline'
        ),
      }}
      borderColor={eventTypeColor}
      borderPosition="left"
      contentDirection="row"
      onClick={hasDeadlineReached ? undefined : () => navigate(path)}
    >
      {cardContent}
    </Card>
  );
});

BrowseEventCard.propTypes = {
  browseEvent: PropTypes.instanceOf(BrowseEvent).isRequired,
  intl: PropTypes.object.isRequired,
};

export default BrowseEventCard;
