import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import moment from 'moment-timezone';
import MembershipAgreementFormActions from 'shared/actions/MembershipAgreementFormActions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { MembershipSource } from 'sources';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import { Set } from 'immutable';

class MembershipAgreementFormStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      handleOpenAgreementDrawer:
        MembershipAgreementFormActions.openAgreementDrawer,
      handleCloseAgreementDrawer:
        MembershipAgreementFormActions.closeAgreementDrawer,
      handleSubmit: MembershipAgreementFormActions.handleSubmit,
      handleSuccess: MembershipAgreementFormActions.handleSuccess,
      handleError: MembershipAgreementFormActions.handleError,
      handleNameChange: MembershipAgreementFormActions.handleNameChange,
      handleDateChange: MembershipAgreementFormActions.handleDateChange,
    });
  }

  reset() {
    this.showAgreementDrawer = false;
    this.signedAt = moment();
    this.name = '';
    this.agreement = null;
    this.clientIds = Set();
    this.membershipId = null;
    this.hasSignedAgreement = false;
  }

  // eslint-disable-next-line class-methods-use-this
  handleSubmit(data) {
    const currentTime = moment.tz(customerTZ());
    const signedAt = data.signed_at;
    signedAt.set({
      hours: currentTime.hours(),
      minutes: currentTime.minutes(),
      seconds: currentTime.seconds(),
    });
    MembershipSource.postMembershipAgreement({
      params: { ...data, signed_at: signedAt },
      success: MembershipAgreementFormActions.handleSuccess,
      error: MembershipAgreementFormActions.handleError,
    });
  }

  handleOpenAgreementDrawer({
    clientIds,
    clientName,
    membershipId,
    hasSignedAgreement,
    signedAt,
  }) {
    this.showAgreementDrawer = true;
    this.clientIds = clientIds;
    this.membershipId = membershipId;
    this.hasSignedAgreement = hasSignedAgreement;
    this.signedAt = signedAt || moment();
    this.name = clientName;
  }

  handleCloseAgreementDrawer() {
    this.reset();
  }

  handleNameChange(name) {
    this.name = name;
  }

  handleDateChange(date) {
    this.signedAt = date;
  }

  handleSuccess(data) {
    MessageWindowActions.addMessage.defer(
      'Agreement form submitted successfully.'
    );
    if (currentUser().isClient()) {
      AthleteActions.onAgreementSignedSuccess.defer(data);
    } else {
      POSActions.onAgreementSignedSuccess.defer(data);
    }
    this.reset();
  }

  handleError(...args) {
    this.notifyError('error while submitting registration form', args);
    this.reset();
  }
}

export default alt.createStore(
  MembershipAgreementFormStore,
  'MembershipAgreementFormStore'
);
