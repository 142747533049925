import React, { memo, useEffect } from 'react';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Card,
} from '@upperhand/playmaker';

import Paginator from 'shared/components/Paginator.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import EmptyState from 'containers/marketing/components/EmptyState.jsx';
import FiltersDrawer from 'containers/marketing/components/FiltersDrawer.jsx';
import ActiveFilters from 'containers/marketing/components/ActiveFilters.jsx';
import CampaignStatisticsDrawer from 'containers/marketing/statistics/components/CampaignStatisticsDrawer.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import MarketingEmailStatisticsActions from 'containers/marketing/statistics/Actions';

import { EmailCampaignsDataStore } from 'dataStores';
import MarketingEmailStatisticsStore from 'containers/marketing/statistics/Store';

import './styles.scss';

const inlineStyles = {
  spinnerContainerStyle: {
    position: 'relative',
  },
  spinnerWrapperStyle: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
};

function MarketingStatistics({
  marketingEmailStatisticsStore,
  emailCampaignsDataStore,
}) {
  useEffect(() => {
    MarketingEmailStatisticsActions.listMarketingEmailStatistics();
  }, []);

  const intl = useIntl();
  const { campaigns } = emailCampaignsDataStore;
  const {
    search,
    emailsStatisticsIds,
    pagination,
    isLoading,
    filtersDrawerOpen,
    filters,
    appliedFilters,
    statisticsDrawerOpened,
    selectedCampaignId,
    activeTab,
    clients,
    clientSearchText,
    clientsLoading,
  } = marketingEmailStatisticsStore;
  const statisticsPagination = pagination.get('emailsStatistics');
  const clientsPagination = pagination.get('clients');

  const handleFilterChange = (field, value) => {
    MarketingEmailStatisticsActions.handleFilterChange({
      field,
      value,
    });
  };

  return (
    <div className="marketing-statistics">
      <div className="marketing-statistics__header">
        <TextField
          fullWidth
          rounded
          value={search}
          placeholder={t('.search_placeholder', intl, __filenamespace)}
          classes={{ root: 'marketing-statistics__search' }}
          onChange={e =>
            MarketingEmailStatisticsActions.searchMarketingEmailStatistics(
              e.target.value
            )
          }
        />
        <Button
          rounded
          icon="filter"
          iconPosition="right"
          type="secondary"
          classes={{
            root: 'marketing-statistics__filter-btn',
            label: 'marketing-statistics__btn-label',
          }}
          onClick={MarketingEmailStatisticsActions.toggleFiltersDrawer}
        >
          {t('.filters', intl, __filenamespace)}
        </Button>
      </div>
      <ActiveFilters
        appliedFilters={appliedFilters}
        onRemoveFilter={MarketingEmailStatisticsActions.removeFilter}
        onClearFilters={MarketingEmailStatisticsActions.resetFilters}
      />
      <div className="marketing-statistics__content">
        <SpinWhileLoading
          isLoading={isLoading}
          spinnerContainerStyle={inlineStyles.spinnerContainerStyle}
          spinnerWrapperStyle={inlineStyles.spinnerWrapperStyle}
        >
          {emailsStatisticsIds.size === 0 && (
            <EmptyState
              title={t('.title', intl, __filenamespace)}
              description={t('.description', intl, __filenamespace)}
            />
          )}
          {emailsStatisticsIds.size > 0 && (
            <>
              <div className="marketing-statistics__table-header">
                <div className="marketing-statistics__table-header-content">
                  <Grid container md={12} alignItems="center" spacing={1}>
                    <Grid item md={3}>
                      <Typography variant="fieldLabel">
                        {t('.table_name', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="fieldLabel">
                        {t('.table_email_template', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="fieldLabel">
                        {t('.table_date_sent', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <Typography variant="fieldLabel">
                        {t('.table_sent', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <Typography variant="fieldLabel">
                        {t('.table_opened', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <Typography variant="fieldLabel">
                        {t('.table_unsubscribed', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid container spacing={1}>
                {emailsStatisticsIds.map(id => {
                  const emailStatistics = campaigns.get(id);

                  return (
                    <Grid item key={id} xs={12}>
                      <Card
                        classes={{
                          root: 'marketing-statistics__statistic',
                          content: 'marketing-statistics__statistic-content',
                        }}
                        menuOptions={[
                          {
                            label: 'View',
                            onClick: () =>
                              MarketingEmailStatisticsActions.openStatisticsDrawer(
                                { campaignId: emailStatistics.id }
                              ),
                          },
                        ]}
                      >
                        <Grid
                          item
                          container
                          spacing={1}
                          xs={12}
                          className="marketing-statistics__wrapper"
                        >
                          <Grid item xs={3}>
                            <Typography variant="body2">
                              {emailStatistics.get('title')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2">
                              {emailStatistics.get('email_template').subject}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2">
                              {moment(
                                emailStatistics.get('scheduled_at')
                              ).format('YYYY/MM/DD h:mm a')}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body2">
                              {emailStatistics.get('sent_count')}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body2">
                              {emailStatistics.get('opened_count')}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body2">
                              {emailStatistics.get('unsubscribed_count')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
              <Paginator
                showInfo
                className="marketing-statistics__paginator"
                pageLimit={0}
                currentPage={statisticsPagination.get('page')}
                perPage={statisticsPagination.get('perPage')}
                totalCount={statisticsPagination.get('totalCount')}
                onPageSelect={MarketingEmailStatisticsActions.handlePageSelect}
              />
            </>
          )}
        </SpinWhileLoading>
      </div>
      <FiltersDrawer
        open={filtersDrawerOpen}
        filters={filters}
        appliedFilters={appliedFilters}
        filtersToShow={['created', 'sent']}
        onChange={handleFilterChange}
        onClose={MarketingEmailStatisticsActions.toggleFiltersDrawer}
        onApply={MarketingEmailStatisticsActions.applyFilters}
        onClearFilters={MarketingEmailStatisticsActions.resetFilters}
      />
      <CampaignStatisticsDrawer
        campaignId={selectedCampaignId}
        activeTab={activeTab}
        open={statisticsDrawerOpened}
        clients={clients}
        clientsPagination={clientsPagination}
        searchText={clientSearchText}
        clientsLoading={clientsLoading}
        onClose={MarketingEmailStatisticsActions.closeStatisticsDrawer}
        onTabChange={MarketingEmailStatisticsActions.setActiveTab}
        onClientsPageSelect={MarketingEmailStatisticsActions.clientsPageSelect}
        onClientSearchTextChange={
          MarketingEmailStatisticsActions.clientsSearchTextChange
        }
      />
    </div>
  );
}

export default compose(
  memo,
  altContainer({
    stores: {
      marketingEmailStatisticsStore: MarketingEmailStatisticsStore,
      emailCampaignsDataStore: EmailCampaignsDataStore,
    },
  })
)(MarketingStatistics);
