// eslint-disable-next-line import/prefer-default-export
export const DefaultSourceActions = [
  'createSuccess',
  'createError',
  'listSuccess',
  'listError',
  'fetchSuccess',
  'fetchError',
  'updateSuccess',
  'updateError',
  'deleteSuccess',
  'deleteError',
];
