import * as React from 'react';
import { Button, Grid, Icon, Typography } from '@upperhand/playmaker';
import ReactTooltip from 'react-tooltip';

import { Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/ModeEdit';

import AdditionalEmailsDialog from 'user_management/shared/components/AdditionalEmailsDialog.jsx';
import MailTo from 'shared/components/MailTo.jsx';

import { t } from 'shared/utils/LocaleUtils';

function Header({ onAddEmailClick, intl }) {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h4" color="secondary">
          {t('.header', intl, __filenamespace)}
        </Typography>
        <Box sx={{ marginLeft: '1rem' }}>
          <Box component="span" data-tip data-for="additional_emails_tooltip">
            <Icon name="info" />
          </Box>
          <ReactTooltip
            id="additional_emails_tooltip"
            effect="solid"
            place="right"
            style={{ maxWidth: 240 }}
            className="additional-emails-tooltip uh-tooltip"
          >
            {t('.additional_emails_tooltip', intl, __filenamespace)}
          </ReactTooltip>
        </Box>
      </Box>
      <Box className="additional-emails__add-button">
        <Button
          type="tertiary"
          rounded
          size="1x"
          icon="add"
          onClick={onAddEmailClick}
        >
          {t('.add_email', intl, __filenamespace)}
        </Button>
      </Box>
    </>
  );
}

function EmailCard({ record, actions }) {
  return (
    <Box sx={{ marginBottom: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
          width: '200px',
        }}
      >
        <Typography variant="subtitle1">
          {`${record.get('first_name') || ''} ${
            record.get('last_name') || ''
          }`.trim()}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CancelIcon
            sx={{ marginRight: '.5rem', cursor: 'pointer', width: '16px' }}
            onClick={() => {
              actions.deleteAdditionalEmail(record.get('id'));
            }}
          />
          <EditIcon
            sx={{ cursor: 'pointer', width: '16px' }}
            onClick={() => {
              actions.setAdditionalEmail(record);
            }}
          />
        </Box>
      </Box>
      <Box className="additional-emails__email">
        <MailTo email={record.get('email')} />
      </Box>
    </Box>
  );
}

function EmailsList({ actions, additionalEmailsList }) {
  return (
    <Grid item xs={12}>
      {additionalEmailsList.map(record => (
        <EmailCard key={record.id} record={record} actions={actions} />
      ))}
    </Grid>
  );
}

function AdditionalEmails({
  actions,
  currentEmailData,
  fieldErrors,
  additionalEmailsList,
  isModalOpen,
  intl,
}) {
  const onAddEmailClick = () => {
    actions.openModal();
  };
  const handleClose = () => {
    actions.closeModal();
  };

  return (
    <Grid className="additional-emails" direction="column" container xs={12}>
      <Header onAddEmailClick={onAddEmailClick} intl={intl} />
      <EmailsList
        additionalEmailsList={additionalEmailsList}
        actions={actions}
      />
      <AdditionalEmailsDialog
        actions={actions}
        closeDialog={handleClose}
        open={isModalOpen}
        currentEmailData={currentEmailData}
        errors={fieldErrors}
        intl={intl}
      />
    </Grid>
  );
}

export default AdditionalEmails;
