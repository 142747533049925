import { OrderedMap } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { EventTypeActions } from 'sources';

class EventTypeDataStore extends UpperHandStore {
  constructor() {
    super();

    this.eventTypes = OrderedMap();

    this.bindListeners({
      listSuccess: [EventTypeActions.listSuccess],
      listError: [EventTypeActions.listError],
    });
  }

  listSuccess({ event_types: eventTypes }) {
    const fetchedEventTypes = eventTypes.groupBy(s => s.id).map(s => s.first());

    this.eventTypes = this.eventTypes.merge(fetchedEventTypes);
  }

  listError(args) {
    this.notifyError('error fetching event Types', ...args);
  }
}

export default alt.createStore(EventTypeDataStore, 'EventTypeDataStore');
