import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { CouponActions } from 'sources';

class CouponDataStore extends UpperHandStore {
  constructor() {
    super();

    this.coupons = Map();

    this.bindListeners({
      listSuccess: CouponActions.listSuccess,
      listError: CouponActions.listError,
      updateSuccess: [CouponActions.updateSuccess, CouponActions.fetchSuccess],
      updateError: [CouponActions.updateError, CouponActions.fetchError],
    });
  }

  listSuccess({ coupons }) {
    const fetchedCoupons = coupons.groupBy(c => c.id).map(c => c.first());

    this.coupons = this.coupons.merge(fetchedCoupons);
  }

  listError(...args) {
    this.notifyError('error listing coupons', args);
  }

  updateError(...args) {
    this.notifyError('error coupon updating', args);
  }

  updateSuccess(coupon) {
    this.coupons = this.coupons.set(coupon.id, coupon);
  }
}

export default alt.createStore(CouponDataStore, 'CouponDataStore');
