class CreditPassEditingActions {
  constructor() {
    this.generateActions(
      'addCredit',
      'removeCredit',
      'updateCredit',
      'creditTypeChanged',

      'addEventTypeCredit',
      'removeEventTypeCredit',

      'addEventCredit',
      'removeEventCredit',

      'toggleUnlimitedCredits',

      'fetch',
      'fetchSuccess',
      'fetchError',

      'save',
      'saveSuccess',
      'saveError',

      'toggleExpiration',
      'togglePurchasable',
      'togglePOS',
      'updateField',

      'reset'
    );
  }
}

export default alt.createActions(CreditPassEditingActions);
