import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';
import FilteredTeamListStoreImpl from 'team_mgmt/index/stores/_FilteredTeamListStoreImplementation.jsx';
import FilterStore from 'team_mgmt/index/stores/FilterStore.jsx';

class TeamListStore extends FilteredTeamListStoreImpl {
  constructor() {
    super(TeamActions);
  }

  // eslint-disable-next-line class-methods-use-this
  filterStore() {
    return FilterStore;
  }
}

export default alt.createStore(TeamListStore, 'TeamListStore');
