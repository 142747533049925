import { List, Map } from 'immutable';

import RetailCategory from 'shared/records/RetailCategory.js';
// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { RetailVendorsActions } from 'sources';

class RetailVendorsDataStore extends UpperHandStore {
  constructor() {
    super();

    this.records = List();
    this.retailCategories = List();

    this.bindListeners({
      listSuccess: [RetailVendorsActions.listSuccess],
      listError: [RetailVendorsActions.listError],
      fetchSuccess: [RetailVendorsActions.fetchSuccess],
      fetchError: [RetailVendorsActions.fetchError],
      createSuccess: [RetailVendorsActions.createSuccess],
      createError: [RetailVendorsActions.createError],
      updateSuccess: [RetailVendorsActions.updateSuccess],
      updateError: [RetailVendorsActions.updateError],
      listCategoriesSuccess: [RetailVendorsActions.listCategoriesSuccess],
      listCategoriesError: [RetailVendorsActions.listCategoriesError],
    });
  }

  listCategoriesSuccess({ page, retail_categories: retailCategories }) {
    if (page === 1) {
      this.retailCategories = List(
        retailCategories.map(c => new RetailCategory(c))
      );
    } else {
      this.retailCategories = this.retailCategories.concat(
        retailCategories.map(c => new RetailCategory(c))
      );
    }
  }

  listCategoriesError(args) {
    this.notifyError('error fetching retail categories', ...args);
  }

  listSuccess({ retail_vendors: records, page }) {
    if (page === 1) {
      this.records = List(records);
    } else {
      this.records = this.records.concat(records);
    }
  }

  listError(args) {
    this.notifyError('error fetching retail vendors', ...args);
  }

  fetchSuccess(record) {
    this.record = Map(record);
  }

  fetchError(args) {
    this.notifyError('error fetching retail vendors', ...args);
  }

  createSuccess(record) {
    this.records = this.records.push(record);
  }

  createError(args) {
    this.notifyError('error creating retail vendors', ...args);
  }

  updateSuccess(record) {
    this.records = this.records.map(r => {
      if (r.get('id') === record.get('id')) {
        return record;
      }

      return r;
    });
  }

  updateError(args) {
    this.notifyError('error updating retail vendors', ...args);
  }
}

export default alt.createStore(
  RetailVendorsDataStore,
  'RetailVendorsDataStore'
);
