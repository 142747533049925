import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { BalancesActions } from 'sources';

class BalancesDataStore extends UpperHandStore {
  constructor() {
    super();

    this.balances = Map();

    this.bindListeners({
      listSuccess: BalancesActions.listSuccess,
      listError: BalancesActions.listError,
      detailsSuccess: BalancesActions.detailsSuccess,
      detailsError: BalancesActions.detailsError,
    });
  }

  listSuccess({ records }) {
    const balancesIds = records.groupBy(b => b.compoundId).map(b => b.first());

    this.balances = this.balances.merge(balancesIds);
  }

  listError(...args) {
    this.notifyError('error listing balances', args);
  }

  detailsSuccess({ compoundId, collection }) {
    this.balances = this.balances.set(
      compoundId,
      this.balances.get(compoundId).set('details', collection)
    );
  }

  detailsError(...args) {
    this.notifyError('error getting balance details', args);
  }
}

export default alt.createStore(BalancesDataStore, 'BalancesDataStore');
