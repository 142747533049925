import TaskCreationActions from 'shared/actions/TaskCreationActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import TaskEditingStoreInterface from 'shared/stores/TaskEditingStoreInterface';

export default alt.createStore(
  TaskEditingStoreInterface,
  'TaskCreationStore',
  TaskCreationActions,
  new TranslatableMessage({ id: '.created', filename: __filenamespace })
);
