export const MEMBERSHIP_COMMISSION = 'membership_commission';
export const RETAIL_COMMISSION = 'retail_commission';
export const COMMISSION_TYPE = 'commission_type';

export const COMMISSION_TYPES = [
  {
    label: 'Membership Commission',
    value: 'membership_commission',
  },
  { label: 'Retail Commission', value: 'retail_commission' },
];

export const COMMISSION_TYPES_ENTRY = [
  {
    name: 'Membership Commission',
    value: 'membership_commission',
  },
  { name: 'Retail Commission', value: 'retail_commission' },
];

export const PAYMENT_STATUSES = [
  { label: 'Paid', value: 'paid' },
  { label: 'UnPaid', value: 'unpaid' },
];
