import PropTypes from 'prop-types';
import React from 'react';
import { Map, OrderedSet } from 'immutable';
import { Grid, InfiniteScroller, Spinner } from '@upperhand/playmaker';

import Paginator from 'shared/components/Paginator.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import PaymentPlansActions from '../../Actions';

import EmptyState from './EmptyState.jsx';
import HeaderDesktop from './HeaderDesktop.jsx';
import RowDesktop from './RowDesktop.jsx';
import RowMobile from './RowMobile.jsx';

function Table({
  clients,
  intl,
  isLoading,
  paymentPlans,
  paymentPlanIds,
  page,
  perPage,
  totalCount,
}) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <ResponsiveElement
        largeScreen={
          isLoading ? (
            <FlexBoxJustifyCenter>
              <Spinner />
            </FlexBoxJustifyCenter>
          ) : (
            <>
              <Grid item xs={12}>
                <HeaderDesktop intl={intl} />
              </Grid>

              {paymentPlanIds.size === 0 ? (
                <Grid item xs={12}>
                  <EmptyState intl={intl} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    {paymentPlanIds.map(id => (
                      <RowDesktop
                        key={id}
                        paymentPlan={paymentPlans.get(id)}
                        client={clients.get(
                          paymentPlans.getIn([id, 'clientId'])
                        )}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Paginator
                      className="payment-plans__pagination"
                      currentPage={page}
                      perPage={perPage}
                      totalCount={totalCount}
                      onPageSelect={PaymentPlansActions.selectPage}
                      pageLimit={0}
                      showInfo
                    />
                  </Grid>
                </>
              )}
            </>
          )
        }
        smallScreen={
          <>
            <Grid item xs={12}>
              {/* <HeaderMobile
                intl={intl}
              /> */}
            </Grid>
            {paymentPlanIds.size === 0 ? (
              <Grid item xs={12}>
                <EmptyState intl={intl} />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <InfiniteScroller
                  hasMore={paymentPlanIds.size < totalCount}
                  onScroll={PaymentPlansActions.list.defer}
                  isLoading={isLoading}
                  showLoader
                >
                  {paymentPlanIds.map(id => (
                    <RowMobile
                      key={id}
                      paymentPlan={paymentPlans.get(id)}
                      client={clients.get(paymentPlans.getIn([id, 'clientId']))}
                    />
                  ))}
                </InfiniteScroller>
              </Grid>
            )}
          </>
        }
      />
    </Grid>
  );
}

Table.propTypes = {
  clients: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  paymentPlanIds: PropTypes.instanceOf(OrderedSet).isRequired,
  paymentPlans: PropTypes.instanceOf(Map).isRequired,
  perPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default Table;
