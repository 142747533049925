import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';
import {
  Drawer,
  Grid,
  MultipleSelect,
  CurrencyTextField,
  Button,
} from '@upperhand/playmaker';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import { Filters, FilterSourceTypes } from 'containers/reports/types';

import { hasFilterDrawerDefaults } from '../utils';
import TransactionsLedgerReportActions from '../Actions';

function FilterDrawer({
  defaultFilters,
  filterErrors,
  filters,
  intl,
  isChanged,
  isOpen,
}) {
  const handleSourceTypesChange = value =>
    TransactionsLedgerReportActions.updateFilter(Filters.SOURCE_TYPES, value);

  const handleAmountMinChange = (event, value) =>
    TransactionsLedgerReportActions.updateFilter(
      Filters.AMOUNT_MIN,
      parseFloat(value)
    );

  const handleAmountMaxChange = (event, value) =>
    TransactionsLedgerReportActions.updateFilter(
      Filters.AMOUNT_MAX,
      parseFloat(value)
    );

  const sourceTypeItems = Object.keys(FilterSourceTypes)
    .slice(1)
    .map(key => ({
      label: t(`.source_type_${FilterSourceTypes[key]}`, intl, __filenamespace),
      value: FilterSourceTypes[key],
    }));

  return (
    <Drawer
      classes={{
        root: 'transactions-ledger-report__filter-drawer',
        content: 'filter-drawer__content',
        footer: 'filter-drawer__footer',
      }}
      open={isOpen}
      onClose={TransactionsLedgerReportActions.closeFilterDrawer}
      title={t('.title', intl, __filenamespace)}
      content={
        isLoggedIn() ? (
          <Grid container xs={12} spacing={3} alignItems="center">
            {(isChanged || !hasFilterDrawerDefaults(filters)) && (
              <Grid justify="flex-end" container item xs={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <a
                  className="filter-drawer__clear"
                  onClick={TransactionsLedgerReportActions.clearFilters}
                >
                  {t('.clear', intl, __filenamespace)}
                </a>
              </Grid>
            )}
            <Grid item xs>
              <MultipleSelect
                items={sourceTypeItems}
                name={t('.source_types_placeholder', intl, __filenamespace)}
                label={t('.source_types', intl, __filenamespace)}
                fullWidth
                rounded={false}
                outline
                onChange={handleSourceTypesChange}
                selectedItems={
                  filters.get(Filters.SOURCE_TYPES) ||
                  defaultFilters.get(Filters.SOURCE_TYPES)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyTextField
                label={t('.amount_min', intl, __filenamespace)}
                placeholder={t(
                  '.amount_min_placeholder',
                  intl,
                  __filenamespace
                )}
                fullWidth
                rounded={false}
                outline
                onBlur={handleAmountMinChange}
                value={
                  filters.get(Filters.AMOUNT_MIN) ??
                  defaultFilters.get(Filters.AMOUNT_MIN) ??
                  ''
                }
                errorText={filterErrors.getErrors(Filters.AMOUNT_MIN, intl)}
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyTextField
                label={t('.amount_max', intl, __filenamespace)}
                placeholder={t(
                  '.amount_max_placeholder',
                  intl,
                  __filenamespace
                )}
                fullWidth
                rounded={false}
                outline
                onBlur={handleAmountMaxChange}
                value={
                  filters.get(Filters.AMOUNT_MAX) ??
                  defaultFilters.get(Filters.AMOUNT_MAX) ??
                  ''
                }
                errorText={filterErrors.getErrors(Filters.AMOUNT_MAX, intl)}
              />
            </Grid>
          </Grid>
        ) : (
          <SignInPrompt />
        )
      }
      footer={
        isLoggedIn() ? (
          <Button
            classes={{
              root: 'filter-drawer__footer-button',
              disabled: 'filter-drawer__footer-button-disabled',
              label: 'filter-drawer__footer-button-label',
            }}
            disabled={!isChanged}
            fullWidth
            onClick={TransactionsLedgerReportActions.applyFilters}
            size={40}
            type="primary"
          >
            {t('.btn_apply', intl, __filenamespace)}
          </Button>
        ) : null
      }
    />
  );
}

FilterDrawer.propTypes = {
  filterErrors: PropTypes.instanceOf(FieldErrors).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isChanged: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
};

FilterDrawer.defaultProps = {
  isOpen: false,
};

export default injectIntl(FilterDrawer);
