import TeamType from 'records/TeamType.jsx';
import TeamTypeEditingActions from 'shared/actions/TeamTypeEditingActions.jsx';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import TeamTypeSelectorUIActions from 'shared/actions/TeamTypeSelectorUIActions.jsx';
import UpperHandRecordStore from 'shared/stores/UpperHandRecordStore.jsx';
import { TeamTypeSource } from 'sources';

class TeamTypeEditingStore extends UpperHandRecordStore {
  constructor() {
    super();

    this.bindListeners({
      handleUpdateStore: TeamTypeEditingActions.UPDATE_STORE,
      handleColorChange: TeamTypeEditingActions.COLOR_CHANGED,
      handleCreateOrUpdate: TeamTypeEditingActions.CREATE_OR_UPDATE,
      handleError: TeamTypeEditingActions.ERROR,
      handleInitialLoad: TeamTypeEditingActions.INITIAL_LOAD,
      handleOpenDialog: TeamTypeSelectorUIActions.OPEN_DIALOG,
      handleSaveSuccess: TeamTypeEditingActions.CREATE_OR_UPDATE_SUCCESS,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  newRecord(options) {
    return new TeamType(options);
  }

  handleColorChange(color) {
    this.record = this.record.set('color', color);
  }

  handleUpdateStore(data) {
    super.handleUpdateStore(data);
  }

  handleCreateOrUpdate(opts) {
    TeamTypeSource.createOrUpdateTeamType({
      id: this.record.id,
      url: this.url(),
      params: this.payload(),
      success: {
        action: TeamTypeEditingActions.createOrUpdateSuccess,
        args: [opts || {}],
      },
      error: TeamTypeEditingActions.error,
    });
  }

  handleSaveSuccess([attributes, args]) {
    this.waitFor(TeamTypeListingStore);
    this.reset();
    if (args && args.callback) {
      setTimeout(() => args.callback(attributes.id));
    }
  }

  handleError(args) {
    this.notifyError('error while creating or updating event type', args);
  }

  handleOpenDialog({ newEventType = false, teamTypeId = null }) {
    if (newEventType) {
      this.reset();
    } else if (teamTypeId) {
      this.load(teamTypeId);
    }
  }

  handleInitialLoad(teamTypeId) {
    this.load(teamTypeId);
  }

  /*
   * PRIVATE HELPERS
   */

  reset() {
    this.record = this.newRecord();
  }

  load(teamTypeId) {
    const teamType =
      TeamTypeListingStore.getState().findById(teamTypeId) || new TeamType();
    this.record = teamType;
  }

  payload() {
    const payload = this.record.toJS();

    if (!payload.id) {
      delete payload.id;
    }
    delete payload.created_at;
    delete payload.updated_at;

    return { attributes: payload };
  }

  url() {
    if (this.record.id) {
      return `team_types/${this.record.id}`;
    }
    return 'team_types';
  }
}

export default alt.createStore(TeamTypeEditingStore, 'TeamTypeEditingStore');
