import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tabs } from '@upperhand/playmaker';

import PageHeader from 'components/PageHeader/PageHeader.jsx';
import MarketingEmails from 'containers/marketing/emails/MarketingEmails.jsx';
import MarketingCampaigns from 'containers/marketing/campaigns/MarketingCampaigns.jsx';
import MarketingStatistics from 'containers/marketing/statistics/MarketingStatistics.jsx';

import { t } from 'shared/utils/LocaleUtils';

import './styles.scss';

export const MARKETING_TABS = {
  EMAILS: 'emails',
  CAMPAIGNS: 'campaigns',
  STATISTICS: 'statistics',
};

function Marketing() {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(MARKETING_TABS.EMAILS);

  const tabItems = [
    {
      label: t('.emails', intl, __filenamespace),
      value: MARKETING_TABS.EMAILS,
      content: <MarketingEmails />,
    },
    {
      label: t('.campaigns', intl, __filenamespace),
      value: MARKETING_TABS.CAMPAIGNS,
      content: <MarketingCampaigns />,
    },
    {
      label: t('.statistics', intl, __filenamespace),
      value: MARKETING_TABS.STATISTICS,
      content: <MarketingStatistics />,
    },
  ];

  const handleTabChange = (_e, tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="marketing">
      <PageHeader
        title={t('.title', intl, __filenamespace)}
        wrapperStyles={{ marginBottom: 0 }}
      />
      <Tabs value={activeTab} tabItems={tabItems} onChange={handleTabChange} />
    </div>
  );
}

export default memo(Marketing);
