class ContactsGroupsActions {
  constructor() {
    this.generateActions(
      'mounted',
      'addContactSearchUpdated',
      'listAddContactSuccess',
      'listAddContactError',
      'selectContactGroup',
      'listContactListSuccess',
      'listContactListError',
      'contactListSearchUpdated',
      'contactListPageSelected',
      'addContact',
      'removeContacts',
      'removeSelectedContacts',
      'deleteSuccess',
      'fetchClientCountSuccess',
      'fetchClientCountError',
      'fetchAllStaffCountSuccess',
      'fetchAllStaffCountError'
    );
  }
}

export default alt.createActions(ContactsGroupsActions);
