import { Record } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

class TransactionLedger extends Record({
  entryId: null,
  amount: null,
  clientId: null,
  clientName: null,
  effectiveAt: null,
  sourceId: null,
  sourceType: null,
  transactionId: null,
  paymentMethod: null,
  last4: null,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super(parsedObject);
  }
}

export default TransactionLedger;
