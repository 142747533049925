/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const style = {
  width: 500,
  cursor: 'pointer',
  '& .UH-MuiChip-deleteIconColorDefault': {
    cursor: 'pointer',
  },
  '& .UH-MuiInputBase-root': {
    minHeight: '42px',
    flexWrap: 'wrap !important',
    display: 'flex',
    padding: '5px',
  },
  '& .MuiChip-label': {
    fontSize: '1.2rem',
  },
};
export default function CalendarStaffAutocomplete({
  options,
  onChange,
  value,
  placeholder,
}) {
  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option.name}
      value={value}
      limitTags={10}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          placeholder={placeholder}
          size="small"
        />
      )}
      sx={style}
    />
  );
}
