/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, memo } from 'react';
import { injectIntl } from 'react-intl';
import { Drawer, Button, Confirmation, Typography } from '@upperhand/playmaker';

import InitialStep from 'containers/contacts/contactsFilters/steps/InitialStep.jsx';
import CreationStep from 'containers/contacts/contactsFilters/steps/CreationStep.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import ContactsFiltersDrawerActions from './Actions';
import ContactsFiltersDrawerStore, { STEPS } from './Store';
import './styles.scss';

function ContactsFiltersDrawer({
  intl,
  appliedFilters,
  contactsFiltersDrawerStore: { open, step, ...storeProps },
}) {
  const [filterToDelete, setFilterToDelete] = useState(null);

  if (!open) return null;

  const handleClose = () => {
    ContactsFiltersDrawerActions[
      step === STEPS.CREATE ? 'changeStep' : 'closeDrawer'
    ]({ step: STEPS.INITIAL });
  };

  const deleteConfirmationPopup = () =>
    filterToDelete && (
      <Confirmation
        open={!!filterToDelete}
        hasCheckbox={false}
        title={t('.delete_confirmation_title', intl, __filenamespace)}
        onConfirm={() => {
          ContactsFiltersDrawerActions.deleteFilter(filterToDelete);
          setFilterToDelete(null);
        }}
        onCancel={() => setFilterToDelete(null)}
      >
        <Typography>
          {t('.delete_confirmation_message', intl, __filenamespace, {
            filter_name: storeProps.filter.get('name'),
          })}
        </Typography>
      </Confirmation>
    );

  return (
    <>
      <Drawer
        title={t('.title', intl, __filenamespace)}
        open={open}
        classes={{
          root: 'contacts-filters-drawer',
          footer: 'contacts-filters-drawer__footer',
        }}
        onClose={handleClose}
        content={
          <>
            {step === STEPS.INITIAL && <InitialStep {...storeProps} />}
            {step === STEPS.CREATE && (
              <CreationStep appliedFilters={appliedFilters} {...storeProps} />
            )}
          </>
        }
        footer={
          step === STEPS.CREATE ? (
            <>
              {storeProps.filter?.id && (
                <Button
                  type="tertiary"
                  classes={{ root: 'contacts-filters-drawer__footer-delete' }}
                  onClick={() => setFilterToDelete(storeProps.filter.get('id'))}
                >
                  {t('.delete_filter', intl, __filenamespace)}
                </Button>
              )}
              {!storeProps.filter?.id && (
                <Button
                  type="tertiary"
                  onClick={() =>
                    ContactsFiltersDrawerActions.applyFilter(storeProps.filter)
                  }
                >
                  {t('.apply_filter', intl, __filenamespace)}
                </Button>
              )}
              <Button
                classes={{ root: 'contacts-filters-drawer__footer-save' }}
                disabled={!storeProps.filterChanged}
                onClick={ContactsFiltersDrawerActions.saveFilter}
              >
                {t(
                  storeProps.filter?.id ? '.update_filter' : '.save_filter',
                  intl,
                  __filenamespace
                )}
              </Button>
            </>
          ) : (
            <Button
              classes={{
                root: 'contacts-filters-drawer__footer-create-btn',
                label: 'contacts-filters-drawer__footer-create-btn-label',
              }}
              onClick={() =>
                ContactsFiltersDrawerActions.changeStep({ step: STEPS.CREATE })
              }
            >
              {t('.create_filter', intl, __filenamespace)}
            </Button>
          )
        }
      />
      {deleteConfirmationPopup()}
    </>
  );
}

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: { contactsFiltersDrawerStore: ContactsFiltersDrawerStore },
  })
)(ContactsFiltersDrawer);
