import { List } from 'immutable';
import TaxRate from 'shared/records/TaxRate';
import TaxRateCreationActions from 'shared/actions/tax_rates/TaxRateCreationActions';
import TaxRateEditingActions from 'shared/actions/tax_rates/TaxRateEditingActions';
import TaxRateListingActions from 'shared/actions/tax_rates/TaxRateListingActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class TaxRateListingStore extends UpperHandStore {
  constructor() {
    super();

    this.taxRates = List();
    this.isLoading = false;

    this.bindListeners({
      list: TaxRateListingActions.LIST,
      listSuccess: TaxRateListingActions.LIST_SUCCESS,
      listError: TaxRateListingActions.LIST_ERROR,

      rateCreated: TaxRateCreationActions.SAVE_SUCCESS,
      rateUpdated: TaxRateEditingActions.SAVE_SUCCESS,
    });
  }

  list() {
    this.isLoading = true;

    uhApiClient.get({
      url: 'tax_rates',
      success: TaxRateListingActions.listSuccess,
      error: TaxRateListingActions.listError,
    });
  }

  listSuccess(data) {
    const { tax_rates: taxRates } = data;

    this.taxRates = List(taxRates.map(t => new TaxRate(t))).sortBy(t => t.name);
    this.isLoading = false;
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing tax codes', args);
  }

  rateCreated(data) {
    this.taxRates = this.taxRates.push(new TaxRate(data)).sortBy(t => t.name);
  }

  rateUpdated(data) {
    const index = this.taxRates.findIndex(c => c.id === data.id);

    this.taxRates = this.taxRates
      .set(index, new TaxRate(data))
      .sortBy(t => t.name);
  }
}

export default alt.createStore(TaxRateListingStore, 'TaxRateListingStore');
