import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function ScheduledSession({
  cancellationDeadline,
  event,
  index,
  onRegistrationCancel,
  profiles,
  client_id: clientId,
  starts_at: startsAt,
  staff_ids: staffIds,
  registrationId,
  staff,
  style,
}) {
  const profile = profiles.find(p => p.id === clientId);
  const selectedStaff = staff.filter(s => staffIds.includes(s.id));

  const minCancelDateTime = moment().add(cancellationDeadline, 'seconds');

  const canCancel =
    (!currentUser().isClient() || startsAt > minCancelDateTime) &&
    typeof onRegistrationCancel === 'function';

  return (
    <FlexBox
      style={merge({ padding: 10, border: '2px solid lightgrey' }, style)}
    >
      <DoneIcon
        style={{
          marginRight: 10,
          height: 16,
          width: 16,
          color: uhColors.green,
        }}
      />
      <div style={{ flex: '1 0 auto' }}>
        <FlexBoxJustify>
          <div style={{ fontWeight: 'bold' }}>
            {startsAt.format('ddd, MMM DD, YYYY')}
          </div>
          {canCancel && (
            <IconButton
              style={{
                marginRight: -8,
                marginTop: -8,
                padding: 0,
                height: 32,
                width: 32,
              }}
              onClick={() =>
                onRegistrationCancel({ id: registrationId, index })
              }
            >
              <ClearIcon sx={{ color: uhColors.lightGrey }} />
            </IconButton>
          )}
        </FlexBoxJustify>

        <div style={{ marginBottom: 9 }}>{startsAt.format('h:mm A')}</div>

        <div style={{ fontWeight: 'bold' }}>{profile && profile.name()}</div>

        {event.allow_staff_selection && (
          <div style={{ marginTop: 9 }}>
            {selectedStaff.size === 0 ? (
              <FormattedMessage
                id={messageId('.any_available', __filenamespace)}
              />
            ) : (
              <FormattedMessage
                id={messageId('.selected_staff', __filenamespace)}
                values={{
                  staff: selectedStaff.map(s => s.name()).join(', '),
                }}
              />
            )}
          </div>
        )}
      </div>
    </FlexBox>
  );
}

ScheduledSession.defaultProps = {
  cancellationDeadline: 0,
  profiles: List(),
  staff: List(),
};

export default ScheduledSession;
