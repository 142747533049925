import * as React from 'react';
import { injectIntl } from 'react-intl';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  UnpaidText: {
    color: 'var(--color-unpaid-red)',
  },
};

function UnpaidClients({ eventTime, intl }) {
  const count = eventTime.unpaidClients().size;

  return count < 1 ? null : (
    <span style={styles.UnpaidText}>
      {t('.n_unpaid', intl, __filenamespace, { n: count })}
    </span>
  );
}

export default injectIntl(UnpaidClients);
