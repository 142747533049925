/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { Drawer, Grid, Typography } from '@upperhand/playmaker';

import CampaignStatisticsClients from 'containers/marketing/statistics/components/CampaignStatisticsClients.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import { EmailCampaignsDataStore } from 'dataStores';

import './styles.scss';

function CampaignStatisticsDrawer({
  open = false,
  clientsLoading = false,
  campaignId = null,
  activeTab = 'sent',
  searchText = '',
  clients = Map(),
  clientsPagination = Map(),
  onClose = () => {},
  onTabChange = () => {},
  onClientsPageSelect = () => {},
  onClientSearchTextChange = () => {},
  emailCampaignsDataStore,
}) {
  const intl = useIntl();
  const { campaigns } = emailCampaignsDataStore;
  const campaign = campaigns.get(campaignId);

  if (!campaign) return null;

  return (
    <Drawer
      title={t('.campaign_statistics', intl, __filenamespace)}
      open={open}
      headerType="dark"
      classes={{
        root: 'campaign-statistics-drawer',
        content: 'campaign-statistics-drawer__content',
        header: 'campaign-statistics-drawer__header',
        footer: 'campaign-statistics-drawer__footer',
      }}
      onClose={onClose}
      content={
        <>
          <Typography variant="h5">{campaign.get('title')}</Typography>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="campaign-statistics-drawer__details-container"
          >
            <Grid item xs={4}>
              <Typography variant="body1">
                {t('.email_template', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography variant="subtitle2">
                {campaign.get('email_template')?.subject}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t('.status', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography
                variant="subtitle2"
                className="campaign-statistics-drawer__status"
              >
                {campaign.get('status')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t('.sent', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography variant="subtitle2">
                {moment(campaign.get('scheduled_at')).format(
                  'YYYY/MM/DD h:mm a'
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t('.created_by', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography variant="subtitle2">
                {campaign.get('created_by')?.name()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t('.contact_group', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography variant="subtitle2">
                {campaign.get('contact_group').name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t('.open_rate', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography
                variant="subtitle2"
                className="campaign-statistics-drawer__open-rate"
              >
                {campaign.openRate}
              </Typography>
            </Grid>
          </Grid>
          <CampaignStatisticsClients
            campaign={campaign}
            searchText={searchText}
            clients={clients}
            clientsPagination={clientsPagination}
            activeTab={activeTab}
            clientsLoading={clientsLoading}
            onTabChange={onTabChange}
            onClientsPageSelect={onClientsPageSelect}
            onClientSearchTextChange={onClientSearchTextChange}
          />
        </>
      }
    />
  );
}

CampaignStatisticsDrawer.propTypes = {
  open: PropTypes.bool,
  clientsLoading: PropTypes.bool,
  activeTab: PropTypes.string,
  searchText: PropTypes.string,
  campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clients: PropTypes.instanceOf(Map),
  clientsPagination: PropTypes.instanceOf(Map),
  onClose: PropTypes.func,
  onTabChange: PropTypes.func,
  onClientsPageSelect: PropTypes.func,
  emailCampaignsDataStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      emailCampaignsDataStore: EmailCampaignsDataStore,
    },
  })
)(CampaignStatisticsDrawer);
