class SessionSchedulingActions {
  constructor() {
    this.generateActions(
      'bookSessionError',
      'bookSessionSuccess',
      'bookSessions',
      'creditCountListError',
      'creditCountListSuccess',
      'filterStaff',
      'mounted',
      'pageSelected',
      'selectSession',
      'sessionListError',
      'sessionListSuccess',
      'sessionListSuccess',
      'sessionsLoadMore',
      'staffListError',
      'staffListSuccess',
      'locationListSuccess',
      'locationListError',
      'unmount',
      'updateSelectedDayOfWeek',
      'updateSelectedLocations'
    );
  }
}

export default alt.createActions(SessionSchedulingActions);
