import { List } from 'immutable';

import MembershipTier from 'shared/records/MembershipTier';

import Parser from 'sources/Parser';
import { onSuccess, onError } from 'sources/handlers';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import DataStoreActions from './MembershipTierActions';

const parserTo = {
  type: MembershipTier,
  paginationKey: 'membership_tiers',
};

const parser = new Parser(parserTo);

export const create = ({ tier, fields, success, error }) => {
  uhApiClient.post({
    url: parserTo.paginationKey,
    data: JSON.stringify({
      attributes: tier,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.createSuccess, success])
    ),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const update = ({ tier, fields, success, error }) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${tier.id}`,
    data: JSON.stringify({
      attributes: tier,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const remove = ({ tier, success, error }) => {
  uhApiClient.delete({
    url: `${parserTo.paginationKey}/${tier.id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.deleteSuccess, success])
    ),
    error: onError(List([DataStoreActions.deleteError, error])),
  });
};
