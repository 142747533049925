class ForgotPasswordActions {
  constructor() {
    this.generateActions(
      'resetRequestSuccess',
      'resetRequestError',
      'sendResetRequest',
      'tryAgain',
      'updateStore',
      'clickButton'
    );
  }
}

export default alt.createActions(ForgotPasswordActions);
