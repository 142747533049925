import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';
import { Dropdown, TextField, RadioButton } from '@upperhand/playmaker';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import CollapsedBenefitSelector from 'memberships/components/CollapsedBenefitSelector.jsx';
import CreditPassEditingActions from 'credit_passes/actions/CreditPassEditingActions.js';
import CreditPassSpecificEventsListActions from 'credit_passes/actions/CreditPassSpecificEventsListActions.js';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventAutoComplete from 'shared/components/_EventAutoComplete.jsx';
import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';
import SelectedEventOrType from 'shared/components/SelectedEventOrType.jsx';
import EventTypeEditor from 'components/EventTypeEditor';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { CREDIT_PASS_CREDIT_TYPES } from 'shared/records/CreditPassCredit.js';

const styles = {
  root: {
    marginBottom: 25,
  },
  header: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  hintText: {
    fontSize: 13,
    marginBottom: 12,
    marginTop: 12,
    color: '#7F7F7F',
    lineHeight: '18px',
  },
};

function UnlimitedSelector({ credit, isUnlimitedDisabled, index, intl }) {
  return (
    <RadioButton
      classes={{
        labelRoot: 'credit-pass-container__unlimitedSelector',
      }}
      name="unlimited"
      onChange={(_, value) =>
        CreditPassEditingActions.toggleUnlimitedCredits(index, value === 'true')
      }
      options={[
        {
          label: t('.unlimited', intl, __filenamespace),
          disabled: isUnlimitedDisabled,
          value: true,
        },
        {
          label: (
            <div className="credit-pass-container__unlimitedSelector-label">
              <div>{t('.qty', intl, __filenamespace)}</div>
              {!credit.unlimited && (
                <TextField
                  classes={{
                    root: 'credit-pass-container__unlimitedSelector-quantity',
                  }}
                  name="quantity"
                  type="number"
                  value={credit.quantity}
                  min={1}
                  onChange={(e, value) =>
                    CreditPassEditingActions.updateCredit(
                      e.target.name,
                      index,
                      value
                    )
                  }
                  errorText={credit.errors.getErrors('quantity', intl)}
                />
              )}
            </div>
          ),
          value: false,
        },
      ]}
      orientation="horizontal"
      selectedValue={credit.unlimited}
    />
  );
}

function EventTypeSelect({ index, credit, unselectedEventTypes, intl }) {
  return (
    <EventTypeEditor
      eventTypes={unselectedEventTypes}
      errorText={credit.errors.getErrors('event_types', intl)}
      onCreateOrUpdateSuccess={eventTypeId =>
        CreditPassEditingActions.addEventTypeCredit(index, eventTypeId)
      }
      onEventTypeSelect={value =>
        CreditPassEditingActions.addEventTypeCredit(index, value)
      }
      placeholder={t('.choose_event_types', intl, __filenamespace)}
    />
  );
}

function SelectedEventTypesList({ selectedEventTypes, index }) {
  return (
    <div className="credit-pass-container__selectedEventTypesList">
      {selectedEventTypes.map(eventType => (
        <SelectedEventOrType
          eventType={eventType}
          title={eventType.name}
          id={eventType.id}
          barColor={eventType.color}
          onRemove={id =>
            CreditPassEditingActions.removeEventTypeCredit(index, id)
          }
        />
      ))}
    </div>
  );
}

function SelectedEventsList({ selectedEvents, index }) {
  return (
    <div>
      {selectedEvents.map(event => (
        <SelectedEventOrType
          key={event.id}
          event={event}
          title={event.title}
          id={event.id}
          barColor={event.event_type.color}
          onRemove={id => {
            CreditPassEditingActions.removeEventCredit(index, id);
            CreditPassSpecificEventsListActions.removeSpecificEvent(id);
          }}
        />
      ))}
    </div>
  );
}

const creditTypeSelectHandler = (
  index,
  value,
  selectedEventIds,
  selectedEventTypeIds
) => {
  CreditPassEditingActions.creditTypeChanged(index, value);

  if (value === CREDIT_PASS_CREDIT_TYPES.event_specific) {
    FilterActions.updateTitleFilter('');
    FilterActions.updateScheduleTypeFilter(['open_booking', 'class_schedule']);
    EventActions.list({
      fields: [],
      exceptIds: selectedEventIds.toArray(),
      exceptTypes: selectedEventTypeIds.toArray(),
    });
  }
};

function CreditCard({
  credit,
  events,
  existingAllEventsCredits,
  selectedEventIds,
  selectedEventTypeIds,
  specificEvents,
  eventTypes,
  index,
  style,
  unselectedEventTypes,
  intl,
}) {
  const selectedEventTypes = eventTypes.filter(et =>
    credit.event_type_ids.has(et.id)
  );
  const selectedEvents = specificEvents.filter(e => credit.event_ids.has(e.id));

  const isUnlimitedDisabled =
    credit.all_events &&
    selectedEventTypeIds.size !== credit.event_type_ids.size;
  const isAllEventDisabled =
    (existingAllEventsCredits && !credit.all_events) ||
    (selectedEventTypeIds.size &&
      selectedEventTypeIds.size !== credit.event_type_ids.size &&
      credit.unlimited) ||
    (selectedEventIds.size &&
      selectedEventIds.size !== credit.event_ids.size &&
      credit.unlimited);

  const debouncedSearch = debounce(searchText => {
    FilterActions.updateTitleFilter(searchText);
    EventActions.list({
      fields: [],
      exceptIds: selectedEventIds.toArray(),
      exceptTypes: selectedEventTypeIds.toArray(),
    });
  }, 600);

  return (
    <Paper
      style={merge({ padding: '6px 16px 16px', position: 'relative' }, style)}
    >
      <IconButton
        onClick={() => CreditPassEditingActions.removeCredit({ index })}
        style={{ position: 'absolute', top: 0, right: 0 }}
      >
        <CloseIcon sx={{ color: uhColors.lightGrey }} />
      </IconButton>

      <div style={{ fontWeight: 'bold', fontSize: 13, margin: '12px 0 8px' }}>
        {t('.session_credits', intl, __filenamespace)}
      </div>

      <UnlimitedSelector
        credit={credit}
        isUnlimitedDisabled={isUnlimitedDisabled}
        index={index}
        intl={intl}
      />

      <div className="credit-pass-container__form-element">
        <Dropdown
          name="type"
          value={credit.type}
          onChange={e =>
            creditTypeSelectHandler(
              index,
              e.target.value,
              selectedEventIds,
              selectedEventTypeIds
            )
          }
          label={t('.how_should_this_be_applied', intl, __filenamespace)}
          fullWidth
          items={[
            ...(isAllEventDisabled
              ? []
              : [
                  {
                    value: CREDIT_PASS_CREDIT_TYPES.all_events,
                    label: t('.all_events', intl, __filenamespace),
                  },
                ]),
            {
              value: CREDIT_PASS_CREDIT_TYPES.event_types,
              label: t('.event_types', intl, __filenamespace),
            },
            {
              value: CREDIT_PASS_CREDIT_TYPES.event_specific,
              label: t('.event_specific', intl, __filenamespace),
            },
          ]}
        />
      </div>

      <div style={styles.hintText}>
        <FormattedMessage
          id={messageId(`.hint_text_${credit.type}`, __filenamespace)}
        />
      </div>

      {credit.type === CREDIT_PASS_CREDIT_TYPES.event_types && (
        <div>
          <EventTypeSelect
            index={index}
            credit={credit}
            unselectedEventTypes={unselectedEventTypes}
            intl={intl}
          />
          <SelectedEventTypesList
            selectedEventTypes={selectedEventTypes}
            index={index}
          />
        </div>
      )}

      {credit.type === CREDIT_PASS_CREDIT_TYPES.event_specific && (
        <div>
          <EventAutoComplete
            hintText={t('.search_event', intl, __filenamespace)}
            events={events}
            index={index}
            onChange={debouncedSearch}
            onEventSelect={eventId => {
              CreditPassEditingActions.addEventCredit(index, eventId);
              CreditPassSpecificEventsListActions.addSpecificEvent(eventId);
            }}
            onLeave={() => {
              FilterActions.updateTitleFilter('');
              EventActions.list({
                fields: [],
                exceptIds: selectedEventIds.toArray(),
                exceptTypes: selectedEventTypeIds.toArray(),
              });
            }}
            errorText={credit.errors.getErrors('event_specific', intl)}
            withIcon
            popoverProps={{ canAutoPosition: true }}
            maxSearchResults={5}
          />
          <SelectedEventsList selectedEvents={selectedEvents} index={index} />
        </div>
      )}
    </Paper>
  );
}

function ExpandedSelector({
  disabledAddingNewCredit,
  events,
  existingAllEventsCredits,
  selectedEventIds,
  selectedEventTypeIds,
  unselectedEventTypes,
  specificEvents,
  eventTypes,
  creditPass,
  intl,
}) {
  return (
    <div>
      {creditPass.credit_pass_credits.map((credit, index) => (
        <CreditCard
          credit={credit}
          existingAllEventsCredits={existingAllEventsCredits}
          eventTypes={eventTypes}
          events={events}
          selectedEventIds={selectedEventIds}
          selectedEventTypeIds={selectedEventTypeIds}
          specificEvents={specificEvents}
          index={index}
          key={credit.id}
          style={{ marginBottom: 15 }}
          unselectedEventTypes={unselectedEventTypes}
          intl={intl}
        />
      ))}
      {!disabledAddingNewCredit && (
        <Button
          startIcon={
            <AddIcon style={{ marginLeft: 0, color: uhColors.activeBlue }} />
          }
          style={{ marginBottom: 10, color: uhColors.charcoalBlack }}
          onClick={CreditPassEditingActions.addCredit}
        >
          {t('.additional_credits', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

function CreditSelector({
  disabledAddingNewCredit,
  events,
  existingAllEventsCredits,
  selectedEventIds,
  selectedEventTypeIds,
  unselectedEventTypes,
  specificEvents,
  eventTypes,
  creditPass,
  expanded,
  intl,
}) {
  return (
    <div style={styles.root}>
      <div style={styles.header}>
        <FormattedMessage id={messageId('.header_text', __filenamespace)} />
      </div>
      {expanded ? (
        <ExpandedSelector
          disabledAddingNewCredit={disabledAddingNewCredit}
          events={events}
          existingAllEventsCredits={existingAllEventsCredits}
          selectedEventIds={selectedEventIds}
          selectedEventTypeIds={selectedEventTypeIds}
          unselectedEventTypes={unselectedEventTypes}
          specificEvents={specificEvents}
          eventTypes={eventTypes}
          creditPass={creditPass}
          intl={intl}
        />
      ) : (
        <CollapsedBenefitSelector
          explanatoryText={t('.explanatory_text', intl, __filenamespace)}
          buttonText={t('.create_credits', intl, __filenamespace)}
          onExpand={CreditPassEditingActions.addCredit}
        />
      )}
    </div>
  );
}

export default CreditSelector;
