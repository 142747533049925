import ServiceFeeEditingActions from 'shared/actions/service_fee/ServiceFeeEditingActions';
import ServiceFeeEditingStoreInterface from 'shared/stores/service_fee/ServiceFeeEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  ServiceFeeEditingStoreInterface,
  'ServiceFeeEditingStore',
  {
    actions: ServiceFeeEditingActions,
    successMessage: new TranslatableMessage({
      id: '.updated',
      filename: __filenamespace,
    }),
  }
);
