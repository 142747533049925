import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ClockBack(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 20 18">
      <path
        d="M11 0.75C6.44417 0.75 2.75 4.44417 2.75 9H0L3.56583 12.5658L3.63 12.6942L7.33333 9H4.58333C4.58333 5.4525 7.4525 2.58333 11 2.58333C14.5475 2.58333 17.4167 5.4525 17.4167 9C17.4167 12.5475 14.5475 15.4167 11 15.4167C9.23083 15.4167 7.62667 14.6925 6.47167 13.5283L5.17 14.83C6.66417 16.3242 8.7175 17.25 11 17.25C15.5558 17.25 19.25 13.5558 19.25 9C19.25 4.44417 15.5558 0.75 11 0.75ZM10.0833 5.33333V9.91667L14.0067 12.245L14.6667 11.1358L11.4583 9.22917V5.33333H10.0833Z"
        fill="var(--color-ui-gray)"
      />
    </SvgIcon>
  );
}

export default ClockBack;
