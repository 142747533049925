import React from 'react';
import { ContentModal } from '@upperhand/playmaker';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import PDFUpload from 'components/PDFUpload/PDFUpload.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import ClientProfileActions from 'containers/clientProfile/Actions';
import ClientProfileStore from 'containers/clientProfile/Store';

function AttachmentsUploadModal({
  intl,
  clientProfileStore: { isFileUploadDrawer, document },
  client,
  onClientSave,
}) {
  const handlePdfChange = file => {
    ClientProfileActions.onPdfFileChange(file);
  };

  return (
    <ContentModal
      classes={{
        root: 'attachement-upload-modal',
      }}
      open={isFileUploadDrawer}
      title={t('.create_title', intl, __filenamespace)}
      successLabel={t('.upload_label', intl, __filenamespace)}
      onAbort={ClientProfileActions.toggleFileUploadDrawer}
      onSuccess={() => onClientSave(client, document)}
    >
      <div>
        <p className="class-creation-modal__label">
          {t('.image_label', intl, __filenamespace)}
        </p>
        <PDFUpload
          cropAspect={1.6}
          uploadContainerHeight={200}
          uploadContainerWidth="100%"
          onChange={handlePdfChange}
          onRemove={handlePdfChange}
        />
      </div>
    </ContentModal>
  );
}

AttachmentsUploadModal.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      clientProfileStore: ClientProfileStore,
    },
  })
)(AttachmentsUploadModal);
