import * as React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    marginBottom: 15,
    position: 'relative',
  },

  primaryText: {
    fontWeight: 'bold',
    marginBottom: 4,
    fontSize: 16,
  },

  secondaryText: {
    color: '#807F7F',
    fontWeight: 'bold',
  },
};

function ItemSummaryHeader({ item, purchased, onRemove, style }) {
  const isTieredMembership = item.getIn(
    ['orderable', 'membership_tier_id'],
    false
  );
  const itemName = isTieredMembership
    ? item.getIn(['orderable', 'subscribable_name'], '')
    : item.primary_display_text;

  return (
    <div style={merge(styles.root, style)}>
      <div style={styles.primaryText}>{itemName}</div>
      {(item.isEventItem() || item.isRetailItem()) && (
        <div style={styles.secondaryText}>{item.secondary_display_text}</div>
      )}

      {purchased ? (
        <DoneIcon
          style={{
            position: 'absolute',
            top: -4,
            right: -1,
            color: uhColors.green,
          }}
        />
      ) : (
        <IconButton
          onClick={() => onRemove(item)}
          style={{ position: 'absolute', top: -16, right: -16 }}
        >
          <ClearIcon sx={{ color: uhColors.iconLightGrey }} />
        </IconButton>
      )}
    </div>
  );
}

export default ItemSummaryHeader;
