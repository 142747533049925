import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import Client from 'shared/records/Client.jsx';
import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';

const inlineStyles = {
  boldText: {
    fontWeight: 'bold',
  },
  content: {
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};

function SuccessMessage({ client, subscription, option }) {
  const intl = useIntl();

  const { membership, membership_tier: membershipTier } = subscription;
  const isTiered = membership.get('tiered', false);
  const membershipTitle = isTiered
    ? membershipTier.get('name')
    : membership.get('name');

  return (
    <div style={inlineStyles.content}>
      <Typography variant="h4" align="center">
        {t('.title', intl, __filenamespace)}
      </Typography>
      <Typography variant="body1">
        {t('.description', intl, __filenamespace)}
      </Typography>
      <div>
        <Typography variant="body1">
          {t('.client_name', intl, __filenamespace, {
            name: <span style={inlineStyles.boldText}>{client.name()}</span>,
          })}
        </Typography>
        <Typography variant="body1">
          {t('.current_membership', intl, __filenamespace, {
            name: <span style={inlineStyles.boldText}>{membershipTitle}</span>,
          })}
        </Typography>
        <Typography variant="body1">
          {t('.request', intl, __filenamespace, {
            request: <span style={inlineStyles.boldText}>{option}</span>,
          })}
        </Typography>
      </div>
    </div>
  );
}

SuccessMessage.propTypes = {
  client: PropTypes.instanceOf(Client).isRequired,
  subscription: PropTypes.instanceOf(MembershipSubscription).isRequired,
  option: PropTypes.string.isRequired,
};

export default memo(SuccessMessage);
