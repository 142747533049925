import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import Customer from 'shared/records/Customer.jsx';
import Staff from 'shared/records/Staff.jsx';
import PasswordValidator from 'shared/utils/PasswordValidator.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { isEmpty } from 'shared/utils/ObjectUtils.jsx';
import CustomerCreationActions from './Actions';

const REQUIRED_FIELDS = {
  staffMember: ['first_name', 'last_name', 'email', 'date_of_birth'],
  customer: [
    'name',
    'time_zone',
    'address.line_1',
    'address.city',
    'address.state',
    'address.postal_code',
  ],
};

class CustomerCreationStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      handleVerifyToken: CustomerCreationActions.verifyToken,
      handleVerifyTokenSuccess: CustomerCreationActions.verifyTokenSuccess,
      handleVerifyTokenError: CustomerCreationActions.verifyTokenError,
      handleUpdateStore: CustomerCreationActions.updateStore,
      handleSignUp: CustomerCreationActions.signUp,
      handleSignUpSuccess: CustomerCreationActions.signUpSuccess,
      handleSignUpError: CustomerCreationActions.signUpError,
      handleCloseDuplicateOrgNameModal:
        CustomerCreationActions.closeDuplicateOrgNameModal,
    });
  }

  reset() {
    this.customer = new Customer();
    this.staffMember = new Staff();
    this.token = null;
    this.verificationError = null;
    this.isCustomerLoading = true;
    this.fieldErrors = new FieldErrors();
    this.isSigningUp = false;
    this.isDuplicateOrgNameModalOpen = false;
    this.isDuplicateOrgName = false;
    this.originalOrgName = false;
    this.isValid = false;
    this.success = false;
  }

  // eslint-disable-next-line class-methods-use-this
  handleVerifyToken(token) {
    uhApiClient.get({
      url: `/customers/${token}/onboarding_data_packet`,
      success: CustomerCreationActions.verifyTokenSuccess,
      error: CustomerCreationActions.verifyTokenError,
    });
  }

  handleVerifyTokenSuccess(data) {
    this.isDuplicateOrgName = data.duplicate_org_name;
    this.isDuplicateOrgNameModalOpen = this.isDuplicateOrgName;
    this.originalOrgName = this.isDuplicateOrgName
      ? data.business_name.substr(0, data.business_name.length - 5)
      : data.business_name;

    this.customer = new Customer({ ...data, name: this.originalOrgName });
    this.staffMember = new Staff(data.admin);
    this.token = data.random;
    this.isCustomerLoading = false;
  }

  // TODO: Display a different view if error occurs (not modal)
  handleVerifyTokenError(...args) {
    this.verificationError = JSON.parse(
      args[0].response.responseText
    ).response.message;
    this.isCustomerLoading = false;
    this.notifyError('error fetching customer', ...args);
  }

  handleUpdateStore(data) {
    const [key, value] = Object.entries(data)[0];
    const path = key.split('.');
    const field = path[0];
    const nestedPath = path.slice(1);
    this[field] = this[field].mergeIn(nestedPath, value);
    this.handleValidate();
  }

  handleValidate() {
    this.fieldErrors = this.fieldErrors.clear();

    const validator = new PasswordValidator(this.staffMember);
    const passwordValid =
      validator.passwordIsValid() && validator.passwordMatchesConfirmation();
    const dob = this.staffMember.date_of_birth;
    const isDobInvalid = dob && !dob.isValid();

    if (dob && isDobInvalid) {
      this.fieldErrors = this.fieldErrors.add(
        'staffMember.date_of_birth',
        'records.errors.date_invalid'
      );
    } else if (dob && this.staffMember.underThirteen()) {
      this.fieldErrors = this.fieldErrors.add(
        'staffMember.date_of_birth',
        'containers.accounts.createCustomer.validation.date_of_birth'
      );
    }

    if (
      this.staffMember.ssn_last_4 &&
      !this.staffMember.ssn_last_4.match(/^\d{4}$/)
    ) {
      this.fieldErrors = this.fieldErrors.add(
        'staffMember.ssn_last_4',
        'containers.accounts.createCustomer.validation.ssn_last_4'
      );
    }

    if (
      this.isDuplicateOrgName &&
      this.customer.name === this.originalOrgName
    ) {
      this.fieldErrors = this.fieldErrors.add(
        'customer.name',
        'containers.accounts.createCustomer.validation.duplicate_org_name'
      );
    }

    this.isValid = passwordValid && this.fieldErrors.isEmpty();
  }

  async handleSignUp() {
    Object.entries(REQUIRED_FIELDS).forEach(([entity, fields]) => {
      fields.forEach(field => {
        if (isEmpty(this[entity].getIn(field.split('.')))) {
          this.fieldErrors = this.fieldErrors.add(
            `${entity}.${field}`,
            'Required'
          );
        }
      });
    });

    if (!this.fieldErrors.isEmpty()) {
      this.isValid = false;
    }

    if (!this.isValid) return;

    this.isSigningUp = true;

    uhApiClient.put({
      url: `/customers/${this.customer.id}/signup`,
      data: await this.payload(),
      success: CustomerCreationActions.signUpSuccess,
      error: CustomerCreationActions.signUpError,
    });
  }

  async payload() {
    const payload = await this.customer.toServer();

    payload.name = payload.name.trim();
    payload.random = this.token;
    payload.admin = this.staffMember.toJSON();
    if (payload.admin.profile_image && !payload.admin.profile_image.file) {
      delete payload.admin.profile_image;
    } else {
      payload.admin.profile_image =
        await this.staffMember.profile_image.toServer();
    }

    return JSON.stringify({ attributes: payload });
  }

  handleSignUpSuccess() {
    this.success = true;
  }

  handleSignUpError(...args) {
    this.isSigningUp = false;
    this.notifyError('Error during customer sign up', args);

    const message =
      args[0].httpMessage ||
      args[0].response.data.response.message ||
      args[0].message ||
      'Error during customer sign up';

    if (message) {
      MessageWindowActions.addMessage.defer(message);
    }
  }

  handleCloseDuplicateOrgNameModal() {
    this.isDuplicateOrgNameModalOpen = false;
  }
}

export default alt.createStore(CustomerCreationStore, 'CustomerCreationStore');
