import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import ModalRegistrationActions from './Actions';

class ModalRegistrationStore extends UpperHandStore {
  constructor() {
    super();

    this.isSignUpModalOpened = false;
    this.isSignInModalOpened = false;
    this.redirectTo = null;
    this.redirectHash = null;

    this.bindListeners({
      toggleSignInModal: ModalRegistrationActions.toggleSignInModal,
      toggleSignUpModal: ModalRegistrationActions.toggleSignUpModal,
    });
  }

  resetRedirection() {
    this.redirectTo = null;
    this.redirectHash = null;
  }

  toggleSignInModal({ redirectTo, redirectHash }) {
    this.resetRedirection();
    this.isSignInModalOpened = !this.isSignInModalOpened;
    this.redirectTo = redirectTo;
    this.redirectHash = redirectHash;
  }

  toggleSignUpModal({ redirectTo, redirectHash }) {
    this.resetRedirection();
    this.isSignUpModalOpened = !this.isSignUpModalOpened;
    this.isSignInModalOpened = false;
    this.redirectTo = redirectTo;
    this.redirectHash = redirectHash;
  }
}

export default alt.createStore(
  ModalRegistrationStore,
  'ModalRegistrationStore'
);
