import * as React from 'react';
import PropTypes from 'prop-types';

import CompletedEventSchedule from './CompletedEventSchedule.jsx';
import FutureEventSchedule from './FutureEventSchedule.jsx';

export const SessionRange = {
  ALL: 0,
  COMPLETED: 1,
  FUTURE: 2,
};

function EventSchedule({ eventId, scheduleType, sessionRange, isTeamEvent }) {
  return sessionRange === SessionRange.COMPLETED ? (
    <CompletedEventSchedule
      eventId={eventId}
      scheduleType={scheduleType}
      isTeamEvent={isTeamEvent}
    />
  ) : (
    <FutureEventSchedule
      eventId={eventId}
      scheduleType={scheduleType}
      isTeamEvent={isTeamEvent}
    />
  );
}

EventSchedule.propTypes = {
  eventId: PropTypes.number.isRequired,
  scheduleType: PropTypes.string.isRequired,
  sessionRange: PropTypes.number.isRequired,
};

export default EventSchedule;
