import React from 'react';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import NoStaffIcon from 'shared/components/icons/empty_states/Staff.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function Avatar({ selectedUser }) {
  return selectedUser ? (
    <UserAvatar size={24} user={selectedUser} />
  ) : (
    <NoStaffIcon style={{ height: 24, width: 24, color: uhColors.tableGrey }} />
  );
}

export default class UsersAutoComplete extends React.PureComponent {
  handleUpdateInput = (e, value, reason) => {
    const { onChange } = this.props;

    if (onChange && reason === 'input') {
      onChange(value);
    }
  };

  handleSelect = selection => {
    const { onItemSelect, onChange } = this.props;

    onItemSelect(selection.value);
    onChange(selection.text);
  };

  render() {
    const {
      selectedUser,
      searchText,
      dataSource,
      onItemSelect,
      withAvatar,
      selectedItem,
      label,
      variant,
      helperText,
      placeholder,
      onLeave,
      ...restProps
    } = this.props;

    const getTextFieldParams = params => ({
      ...params,
      label,
      variant,
      placeholder,
      helperText,
      error: !!helperText,
      InputProps: {
        ...params.InputProps,
        startAdornment: withAvatar ? (
          <Avatar selectedUser={selectedUser} />
        ) : null,
      },
      InputLabelProps: {
        shrink: true,
      },
    });

    return (
      <Autocomplete
        options={dataSource}
        inputValue={searchText || ''}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={params => <TextField {...getTextFieldParams(params)} />}
        getOptionLabel={option => option.text}
        renderOption={(props, option) => (
          <MenuItem
            sx={{ justifyContent: 'space-between' }}
            key={option.value}
            onClick={() => this.handleSelect(option)}
          >
            {option.text}
          </MenuItem>
        )}
        onInputChange={this.handleUpdateInput}
        onClose={onLeave}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      />
    );
  }
}
