import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormHelperText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import UsersAutoComplete from 'reporting/payroll/components/UsersAutoComplete.jsx';
import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import AutoCompleteStaffListActions from 'shared/actions/AutoCompleteStaffListActions';
import AutoCompleteClientListActions from 'shared/actions/AutoCompleteClientListActions';
import AutoCompleteItemListActions from 'shared/actions/AutoCompleteItemListActions';
import AutoCompleteRetailListActions from 'shared/actions/AutoCompleteRetailListActions';
import {
  COMMISSION_TYPES_ENTRY,
  MEMBERSHIP_COMMISSION,
  RETAIL_COMMISSION,
} from 'reporting/payroll/components/constants';

const styles = {
  TimeInputLeft: {
    flex: 1,
    marginRight: 10,
  },
  TimeInputRight: {
    flex: 1,
    marginLeft: 10,
  },
  TextInputField: {
    width: '100%',
  },
  UnderlineStyle: {
    borderBottomWidth: 2,
  },
  SolidUnderlineStyle: {
    borderBottom: '2px solid var(--color-divide)',
    borderColor: 'var(--color-divide)',
  },
  TimeInputDialog: {
    minHeight: '380px',
  },
};

const onSearchStaff = searchText =>
  AutoCompleteStaffListActions.searchStringUpdated(searchText);

const onCloseAutocomplete = (selectedStaff, searchString) =>
  AutoCompleteStaffListActions.searchStringUpdated(
    selectedStaff ? selectedStaff.name() : searchString
  );

const onSearchClient = searchText =>
  AutoCompleteClientListActions.searchStringUpdated(searchText);

const onCloseAutocompleteUser = (selectedClient, searchString) =>
  AutoCompleteClientListActions.searchStringUpdated(
    selectedClient ? selectedClient.name() : searchString
  );

const onSearchItem = searchText =>
  AutoCompleteItemListActions.searchStringUpdated(searchText);

const onCloseAutocompleteItem = (selectedItem, searchString) =>
  AutoCompleteItemListActions.searchStringUpdated(
    selectedItem ? selectedItem.name : searchString
  );

const onSearchRetailCategory = searchText =>
  AutoCompleteRetailListActions.searchStringUpdated(searchText);

const onCloseAutocompleteProduct = (selectedRetailItem, searchString) => {
  AutoCompleteRetailListActions.searchStringUpdated(
    selectedRetailItem ? selectedRetailItem.name : searchString
  );
};

function PayrollComissionsEntryModal({
  actions: {
    closeModal,
    confirm,
    setStaff,
    setRetailCategory,
    setClient,
    setItem,
    update,
    updateDate,
  },
  open,
  editing,
  formattedStaff,
  commission,
  staffMember,
  staffSearchString,
  intl,
  clientSearchString,
  formattedClients,
  selectedClient,
  itemSearchString,
  formattedItems,
  retailCategorySearchString,
  formattedRetailCategories,
  selectedItem,
  selectedRetailItem,
}) {
  const getDatePickerParams = (params, placeholder) => ({
    ...params,
    ...{
      fullWidth: true,
      error: !!commission.errors.getErrors('due_date', intl),
      helperText: commission.errors.getErrors('due_date', intl),
      inputProps: {
        ...params.inputProps,
        placeholder,
      },
      InputLabelProps: { shrink: true },
    },
  });
  return (
    <Dialog disableEnforceFocus open={open} onClose={closeModal} fullWidth>
      <DialogTitle>
        {t(`${editing ? '.edit_entry' : '.add_entry'}`, intl, __filenamespace)}
      </DialogTitle>
      <DialogContent>
        {open && (
          <>
            <Stack spacing={1}>
              <UsersAutoComplete
                withAvatar
                dataSource={formattedStaff}
                selectedItem={staffMember}
                label={t('.search_staff', intl, __filenamespace)}
                onItemSelect={setStaff}
                onChange={onSearchStaff}
                onLeave={() =>
                  onCloseAutocomplete(staffMember, staffSearchString)
                }
                helperText={commission.errors.getErrors(
                  'customer_user_id',
                  intl
                )}
                searchText={staffSearchString}
              />
              <FormControl fullWidth>
                <DatePicker
                  name="sendDate"
                  value={commission.due_date || ''}
                  label={t('.date', intl, __filenamespace)}
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getDatePickerParams(
                        params,
                        t('.select_date', intl, __filenamespace)
                      )}
                    />
                  )}
                  DialogProps={{ disableEnforceFocus: true }}
                  onChange={date => updateDate(date)}
                />
              </FormControl>
              <FormControl
                fullWidth
                disabled={!commission.due_date}
                error={!!commission.errors.getErrors('commission_type', intl)}
              >
                <InputLabel shrink>
                  {t('.pay_type', intl, __filenamespace)}
                </InputLabel>
                <Select
                  notched
                  displayEmpty
                  label={t('.pay_type', intl, __filenamespace)}
                  value={commission.commission_type || ''}
                  onChange={e => {
                    if (e.target.value === MEMBERSHIP_COMMISSION) {
                      AutoCompleteItemListActions.searchStringUpdated('');
                    }
                    if (e.target.value === RETAIL_COMMISSION) {
                      AutoCompleteRetailListActions.searchStringUpdated('');
                    }
                    update('commission_type', e.target.value);
                  }}
                >
                  <MenuItem disabled value="">
                    <span
                      style={{
                        opacity: commission.start_time ? 0.4 : 1,
                      }}
                    >
                      {t('.choose_pay_type', intl, __filenamespace)}
                    </span>
                  </MenuItem>
                  {COMMISSION_TYPES_ENTRY.map(payType => (
                    <MenuItem key={payType.value} value={payType.value}>
                      {payType.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {commission.errors.getErrors('commission_type', intl)}
                </FormHelperText>
              </FormControl>

              {commission?.commission_type === MEMBERSHIP_COMMISSION ? (
                <UsersAutoComplete
                  searchText={itemSearchString}
                  dataSource={formattedItems}
                  label={t('.item', intl, __filenamespace)}
                  selectedItem={selectedItem}
                  placeholder={t('.choose_item', intl, __filenamespace)}
                  onChange={onSearchItem}
                  onItemSelect={setItem}
                  style={styles.filterSelectField}
                  disabled={!commission.commission_type}
                  helperText={commission.errors.getErrors('item_name', intl)}
                  onLeave={() =>
                    onCloseAutocompleteItem(selectedItem, itemSearchString)
                  }
                />
              ) : (
                <UsersAutoComplete
                  searchText={retailCategorySearchString}
                  dataSource={formattedRetailCategories}
                  label={t('.item', intl, __filenamespace)}
                  placeholder={t('.choose_item', intl, __filenamespace)}
                  onChange={onSearchRetailCategory}
                  selectedItem={selectedRetailItem}
                  onItemSelect={setRetailCategory}
                  style={styles.filterSelectField}
                  disabled={!commission.commission_type}
                  helperText={commission.errors.getErrors('item_name', intl)}
                  onLeave={() =>
                    onCloseAutocompleteProduct(
                      selectedRetailItem,
                      retailCategorySearchString
                    )
                  }
                />
              )}

              <UsersAutoComplete
                searchText={clientSearchString}
                dataSource={formattedClients}
                label={t('.client', intl, __filenamespace)}
                selectedItem={selectedClient}
                placeholder={t('.choose_client', intl, __filenamespace)}
                onChange={onSearchClient}
                onItemSelect={setClient}
                onLeave={() =>
                  onCloseAutocompleteUser(selectedClient, clientSearchString)
                }
                style={styles.filterSelectField}
                helperText={commission.errors.getErrors('client_id', intl)}
              />
            </Stack>
            <Stack direction="row" spacing={1} style={{ marginTop: 8 }}>
              <CurrencyTextField
                name="total"
                value={commission.total}
                onChange={(_, value) => update('total', value)}
                converter={e => parseInt(e, 10)}
                floatingLabelText={t('.rate', intl, __filenamespace)}
                symbolStyle={styles.currencyIcon}
                textFieldStyle={styles.TextInputField}
                helperText={commission.errors.getErrors('total', intl)}
              />
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          {t('.cancel', intl, __filenamespace)}
        </Button>
        <Button variant="contained" onClick={confirm}>
          {t(`${editing ? '.save' : '.add'}`, intl, __filenamespace)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PayrollComissionsEntryModal;
