import * as React from 'react';
import { injectIntl } from 'react-intl';

import Header from 'team_mgmt/editing/components/_Header.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import TabContainer from 'team_mgmt/editing/components/_TabsContainer.jsx';

import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';
import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';
import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import TeamStore from 'team_mgmt/shared/stores/TeamStore.jsx';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';

import './styles.scss';

function TeamMgmtEditing({
  teamSlug,
  intl,
  team,
  teamTypeListingStore: { teamTypes },
}) {
  const { fieldErrors, savedState, customerTeam, isLoadingTeam, isSavingTeam } =
    team;
  const isNewTeamPage = /\/teams\/new/.test(window.location.href);
  const init = () => {
    if (isNewTeamPage) {
      StoreActions.prepareForReuse();
    }
    const teamId = teamSlug ? parseInt(teamSlug.split('-')[0], 10) : undefined;
    if (teamId && !isNewTeamPage) {
      TeamActions.fetch(teamId, ['team_detail', 'team_type']);
      RegistrationFieldActions.list(teamId);
      AutomationTemplateDescriptionActions.list.defer({});
    } else {
      RegistrationFieldActions.list();
      TeamActions.fillPaymentMethods();
    }
    TeamTypeListingActions.list({});
  };
  React.useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSlug]);

  const updateEventAttribute = (e, value, path = []) => {
    let newValue = value;
    if (!newValue) {
      newValue =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    }
    TeamActions.updateTeamStore(
      path.length > 0 ? path : [e.target.name],
      newValue
    );
  };

  const updateTeamStoreParticipants = (keyPath, value) => {
    TeamActions.updateTeamStore(['team_detail'].concat(keyPath), value);
  };

  return (
    <main className="teams-editing">
      <Header
        savedState={savedState}
        customerTeam={customerTeam}
        isLoadingTeam={isLoadingTeam}
        isSavingTeam={isSavingTeam}
        editing={false}
        intl={intl}
      />
      <SpinWhileLoading
        spinWhile="isLoading"
        renderHiddenContent
        isLoading={isLoadingTeam}
      >
        <TabContainer
          className="iphone-x-content"
          customerTeam={customerTeam}
          fieldErrors={fieldErrors}
          teamTypes={teamTypes}
          handleEventInputChange={updateEventAttribute}
          handleParticipantChange={updateTeamStoreParticipants}
        />
      </SpinWhileLoading>
    </main>
  );
}

export default injectIntl(
  altContainer({
    stores: {
      team: TeamStore,
      teamTypeListingStore: TeamTypeListingStore,
    },
  })(TeamMgmtEditing)
);
