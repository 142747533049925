export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'D-BAT',
  siteURL: 'https://www.dbat.net',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-dev/static/images/DbatLogo.jpg',
    width: 325,
  },
  statusBar: {
    backgroundColor: '#4AD669',
  },
};
