import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { CREDIT_PASS_EXPIRATION_STRATEGIES } from 'shared/records/CreditPass.js';
import { messageId } from 'shared/utils/LocaleUtils.js';

function ExpirationMessage({ creditPass }) {
  switch (creditPass.expiration_strategy) {
    case CREDIT_PASS_EXPIRATION_STRATEGIES.never:
      return <FormattedMessage id={messageId('.never', __filenamespace)} />;
    case CREDIT_PASS_EXPIRATION_STRATEGIES.date:
      return (
        <>
          <FormattedMessage id={messageId('.expires_at', __filenamespace)} />
          <FormattedDate
            value={creditPass.expires_at}
            month="short"
            day="numeric"
            year="numeric"
          />
        </>
      );
    default:
      return (
        <>
          <FormattedMessage
            id={messageId('.expiration_days', __filenamespace)}
            values={{ days: creditPass.expiration_days }}
          />
          {CREDIT_PASS_EXPIRATION_STRATEGIES[
            creditPass.expiration_strategy
          ] && (
            <FormattedMessage
              id={messageId(
                `.${
                  CREDIT_PASS_EXPIRATION_STRATEGIES[
                    creditPass.expiration_strategy
                  ]
                }`,
                __filenamespace
              )}
            />
          )}
        </>
      );
  }
}

export default ExpirationMessage;
