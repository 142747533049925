import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import WaiversReportActions from 'reporting/waivers/actions/WaiversReportActions';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  waiversInfo: {
    textAlign: 'center',
    height: 220,
    flex: '0 1 33%',
    padding: '5px 10px',
    margin: 0,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-arround',
    position: 'relative',
  },
  downloadButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

function WaiversBlockHeader({ reportName }) {
  return (
    <IconButton
      style={styles.downloadButton}
      onClick={() => WaiversReportActions.downloadReport(reportName)}
    >
      <CloudDownloadIcon />
    </IconButton>
  );
}

function WaiversBlock({ reportName, value, style }) {
  return (
    <Paper style={{ ...styles.waiversInfo, ...style }}>
      <WaiversBlockHeader reportName={reportName} />
      <div style={{ fontSize: 80, margin: '20px 0' }}>{value}</div>
      <div style={{ fontSize: 30, marginBottom: 10 }}>
        <FormattedMessage id={messageId(`.${reportName}`, __filenamespace)} />
      </div>
    </Paper>
  );
}

export default WaiversBlock;
