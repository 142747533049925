import TimeLog from 'shared/records/TimeLog';
import TimeTrackingActions from 'shared/actions/TimeTrackingActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

class TimeTrackingStore extends UpperHandStore {
  constructor() {
    super();

    this.menuOpen = false;
    this.timeLog = new TimeLog();
    this.isClockingIn = false;
    this.isClockingOut = false;

    this.bindListeners({
      openMenu: TimeTrackingActions.MENU_OPENED,
      closeMenu: TimeTrackingActions.MENU_CLOSED,

      fetchtimeLog: TimeTrackingActions.MENU_MOUNTED,
      listSuccess: TimeTrackingActions.LIST_SUCCESS,
      listError: TimeTrackingActions.LIST_ERROR,

      selectTask: TimeTrackingActions.TASK_SELECTED,
      selectLocation: TimeTrackingActions.LOCATION_SELECTED,

      clockIn: TimeTrackingActions.CLOCK_IN,
      clockInSuccess: TimeTrackingActions.CLOCK_IN_SUCCESS,
      clockInError: TimeTrackingActions.CLOCK_IN_ERROR,

      clockOut: TimeTrackingActions.CLOCK_OUT,
      clockOutSuccess: TimeTrackingActions.CLOCK_OUT_SUCCESS,
      clockOutError: TimeTrackingActions.CLOCK_OUT_ERROR,
    });
  }

  openMenu() {
    this.menuOpen = true;
    this.fetchtimeLog();
  }

  closeMenu() {
    this.menuOpen = false;
  }

  selectTask(id) {
    this.timeLog = this.timeLog.set('task_id', id);
    this.timeLog = this.timeLog.validate();
  }

  selectLocation(id) {
    this.timeLog = this.timeLog.set('location_id', id);
    this.timeLog = this.timeLog.validate();
  }

  // eslint-disable-next-line class-methods-use-this
  fetchtimeLog() {
    uhApiClient.get({
      url: `customer_users/${currentUser().customer_user_id}/time_logs`,
      data: { status: 'open' },
      success: TimeTrackingActions.listSuccess,
      error: TimeTrackingActions.listError,
    });
  }

  listSuccess(data) {
    this.timeLog = new TimeLog(data.time_logs[0]).validate();
  }

  listError(...args) {
    this.notifyError('error listing time logs', args);
  }

  clockIn() {
    this.timeLog = this.timeLog.validate();

    if (this.timeLog.isValid()) {
      this.isClockingIn = true;

      uhApiClient.post({
        url: `customer_users/${currentUser().customer_user_id}/clock_in`,
        data: JSON.stringify({ attributes: this.timeLog.toServer() }),
        success: TimeTrackingActions.clockInSuccess,
        error: TimeTrackingActions.clockInError,
      });
    }
  }

  clockInSuccess(data) {
    this.timeLog = new TimeLog(data);
    this.isClockingIn = false;
  }

  clockInError(...args) {
    this.isClockingIn = false;
    this.notifyError('error clocking in', args);
  }

  clockOut() {
    this.timeLog = this.timeLog.validate();

    if (this.timeLog.isValid()) {
      this.isClockingOut = true;

      uhApiClient.post({
        url: `customer_users/${currentUser().customer_user_id}/clock_out`,
        data: JSON.stringify({ attributes: this.timeLog.toServer() }),
        success: TimeTrackingActions.clockOutSuccess,
        error: TimeTrackingActions.clockOutError,
      });
    }
  }

  clockOutSuccess(_data) {
    this.timeLog = new TimeLog().validate();
    this.isClockingOut = false;
  }

  clockOutError(...args) {
    this.isClockingOut = false;
    this.notifyError('error clocking in', args);
  }
}

export default alt.createStore(TimeTrackingStore, 'TimeTrackingStore');
