import * as React from 'react';
import TextField from '@mui/material/TextField';

import { getFieldStyles } from 'containers/payments/helpers/field';

function FormTextField({
  children,
  name,
  label,
  placeholder,
  fieldId,
  errorText,
  onChange,
  value,
}) {
  const { inputProps, textFiledStyle, inputLabelProps } = getFieldStyles(
    children,
    fieldId
  );

  return (
    <TextField
      data-fs-exclude
      fullWidth
      name={name}
      variant="standard"
      value={value}
      error={!!errorText}
      helperText={errorText}
      label={label}
      placeholder={placeholder}
      sx={textFiledStyle}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      onChange={e => onChange(e, e.target.value)}
    />
  );
}

export default FormTextField;
