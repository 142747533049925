import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { Grid, Typography, Button, DateTimeField } from '@upperhand/playmaker';
import { Map } from 'immutable';

import { t } from 'shared/utils/LocaleUtils';

import BalancesReportActions from '../../Actions';

function HeaderMobile({
  balances,
  filters,
  intl,
  statistics,
  reportMode,
  revenue,
}) {
  const isNow = moment().isSame(filters.get('period').from, 'day');
  const amount = reportMode ? statistics.get('outstanding_balance') : revenue;

  const selectPrevDay = () => {
    BalancesReportActions.selectDay(-1);
  };

  const selectNextDay = () => {
    BalancesReportActions.selectDay(1);
  };

  return (
    <Grid
      className="balances-report__header-mobile"
      container
      spacing={1}
      alignItems="center"
      alignContent="center"
      justify="center"
    >
      {reportMode && (
        <>
          <Grid item>
            <Button onClick={selectPrevDay} type="tertiary" icon="arrowLeft" />
          </Grid>
          <Grid item xs>
            <DateTimeField
              classes={{
                root: clsx(
                  'mobile-date-picker__container',
                  isNow && 'mobile-date-picker__container-hide-clear'
                ),
                input: 'mobile-date-picker__input',
              }}
              type="date"
              autoOk
              disableUnderline
              fullWidth
              onChange={BalancesReportActions.selectDay}
              outline={false}
              value={filters.get('period').from}
            />
          </Grid>
          <Grid item>
            <Button onClick={selectNextDay} type="tertiary" icon="arrowRight" />
          </Grid>
        </>
      )}
      {balances.size > 0 && statistics.size > 0 && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            className="balances-report__statistic-mobile"
          >
            {t('.statistic', intl, __filenamespace, {
              count: statistics.get('unique_client_count'),
              amount: intl.formatNumber(Math.abs(amount || 0) / 100, {
                style: 'currency',
                currency: 'USD',
              }),
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

HeaderMobile.propTypes = {
  balances: PropTypes.instanceOf(Map).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
  reportMode: PropTypes.bool.isRequired,
  revenue: PropTypes.number,
};

HeaderMobile.defaultProps = {
  revenue: null,
};

export default HeaderMobile;
