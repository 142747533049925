import { Map } from 'immutable';

import AccountSetupPaysafeActions from 'onboarding/actions/AccountSetupPaysafeActions.js';
import BaseStepStore, {
  makeFieldGetter,
} from 'onboarding/stores/BaseStepStore.js';
import BusinessStepActions from 'onboarding/actions/BusinessStepActions.js';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import OnboardAPI from 'onboarding/OnboardAPI.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';

function businessFieldsToJSON(fields) {
  const get = makeFieldGetter(fields);
  return {
    attributes: {
      name: get('BusinessName'),
      legal_entity: get('BusinessLegalName'),
      phone: get('BusinessPhone'),
      type: get('LegalEntityType'),
      federal_tax_number: get('EIN'),
      address: {
        street: get('BusinessStreet'),
        street2: get('BusinessStreet2'),
        city: get('BusinessCity'),
        state: get('BusinessState'),
        zip: get('BusinessZip'),
      },
    },
  };
}

class BusinessStepStore extends BaseStepStore {
  constructor() {
    super();

    this.isLoading = false;
    this.reset();

    this.bindListeners({
      handleUpdateForm: BusinessStepActions.updateForm,
      handleSubmit: BusinessStepActions.submitBusinessForm,
      reset: BusinessStepActions.mounted,
    });
  }

  reset() {
    const customer = currentCustomer();
    this.fields = Map({
      BusinessName: this.newField({
        value: customer.name || '',
      }),
      BusinessLegalName: this.newField(),
      LegalEntityType: this.newField(),
      BusinessPhone: this.newField(),
      EIN: this.newField({
        validate: value => !!value.match(/^\d{2}-?\d{7}$/),
        prepare: value => value.replace(/-/g, ''),
      }),
      BusinessStreet: this.newField(),
      BusinessStreet2: this.newField({ required: false }),
      BusinessCity: this.newField(),
      BusinessState: this.newField(),
      BusinessZip: this.newField({
        validate: value => !!value.match(/^\d{5}(?:[-\s]\d{4})?$/),
      }),
    });
  }

  async handleSubmit(...args) {
    this.isLoading = true;
    try {
      const data = JSON.stringify(businessFieldsToJSON(this.fields));
      await OnboardAPI.submitBusiness(data);
      AccountSetupPaysafeActions.nextStep.defer();
    } catch (error) {
      ApiErrorDialogActions.error.defer(error);
      this.notifyError('error submitting business step', args);
    }
    this.isLoading = false;
    this.emitChange();
  }
}

export default alt.createStore(BusinessStepStore, 'BusinessStepStore');
