import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { Map } from 'immutable';

class BaseStepStore extends UpperHandStore {
  constructor() {
    super();

    this.hasError = true;
    this.hasControllingInterest = false;
    this.fields = null;
  }

  reset() {
    throw new Error('Should be implemented in the derived class');
  }

  newField = ({
    required = true,
    value = '',
    error = '',
    validate = _ => true,
    prepare = v => v,
    formatDisplay = v => v,
  } = {}) =>
    Map({
      required,
      value,
      error,
      validate,
      prepare,
      formatDisplay,
    });

  validateField(name) {
    const field = this.fields.get(name);
    if (field.get('required') && field.get('value') === '') {
      this.hasError = true;
      this.fields = this.fields.setIn([name, 'error'], 'required');
    } else if (!field.get('validate')(field.get('value'))) {
      this.hasError = true;
      this.fields = this.fields.setIn([name, 'error'], 'invalid');
    } else {
      this.fields = this.fields.setIn([name, 'error'], '');
    }
  }

  validateFields() {
    this.hasError = false;
    this.fields.entrySeq().forEach(([key]) => {
      this.validateField(key);
    });
  }

  handleUpdateForm([field, value]) {
    this.fields = this.fields.setIn([field, 'value'], value);
    this.validateFields();
  }
}
export function makeFieldGetter(fields) {
  return name => {
    const field = fields.get(name);
    return field.get('prepare')(field.get('value'));
  };
}

export default BaseStepStore;
