import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CurrencyTextField, Typography } from '@upperhand/playmaker';

import { grey, green } from '@mui/material/colors';

import PricingUtils from 'event_mgmt/shared/utils/PricingUtils.jsx';
import USDIcon from 'shared/components/icons/USDIcon.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  group: {
    margin: '8px 0 16px',
  },
  processingFeeLabel: {
    display: 'flex',
  },
  processingFeeExtraInformation: {
    color: grey[500],
    fontSize: 12,
    paddingLeft: 8,
  },
  totalRevenueCurrency: {
    height: 23,
    width: 23,
  },
  totalEarningsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  totalEarningsValue: {
    display: 'flex',
    alignItem: 'center',
    paddingTop: 8,
  },
  totalEarnings: {
    fontSize: 24,
    color: uhColors.leftNavGrey,
    marginLeft: 4,
  },
};

function PriceFields({
  errorText,
  feePlan,
  fieldName,
  handleInputChange,
  intl,
  isFree,
  value,
  interchangePriceLabel,
  priceFieldLabel,
  showFieldLabel,
}) {
  const label = feePlan.interchange_plus
    ? interchangePriceLabel
    : priceFieldLabel;
  const getProcessingFee = () => PricingUtils.processingFee(value, feePlan);

  const getTotalEarnings = () =>
    PricingUtils.fixedDecimal(PricingUtils.totalEarnings(value, feePlan));

  const processingFeeExtraInformation = () => {
    if (!feePlan) {
      return '';
    }

    const { percentage } = feePlan;
    const dollarAmount = feePlan.dollar_amount.toFixed(2);

    return (
      <FormattedMessage
        id={messageId('.fee_info', __filenamespace)}
        values={{ n: percentage, m: dollarAmount }}
      />
    );
  };

  return (
    <>
      <div style={styles.group}>
        <CurrencyTextField
          label={showFieldLabel ? label : undefined}
          fullWidth
          disabled={isFree}
          name={fieldName}
          errorText={errorText}
          onBlur={(e, newValue) => handleInputChange(e, newValue)}
          value={value}
          onChange={(e, newValue) => handleInputChange(e, newValue)}
        />
      </div>
      {!feePlan.interchange_plus && currentCustomer().display_fees && (
        <>
          <div style={styles.group}>
            <CurrencyTextField
              label={
                <div style={styles.processingFeeLabel}>
                  <div>{t('.fee_field_label', intl, __filenamespace)}</div>
                  <div style={styles.processingFeeExtraInformation}>
                    {processingFeeExtraInformation()}
                  </div>
                </div>
              }
              fullWidth
              disabled
              name="processing_fee"
              value={getProcessingFee()}
            />
          </div>
          <div style={{ ...styles.totalEarningsContainer, ...styles.group }}>
            <Typography variant="caption">
              {t('.earnings_field_label', intl, __filenamespace)}
            </Typography>
            <div style={styles.totalEarningsValue}>
              <USDIcon color={green.A400} style={styles.totalRevenueCurrency} />
              <div style={styles.totalEarnings}>
                {PricingUtils.fixedDecimal(getTotalEarnings())}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

PriceFields.propTypes = {
  errorText: PropTypes.string,
  feePlan: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isFree: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showFieldLabel: PropTypes.bool,
  interchangePriceLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  priceFieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

PriceFields.defaultProps = {
  errorText: '',
  isFree: false,
  showFieldLabel: true,
  interchangePriceLabel: (
    <FormattedMessage
      id={messageId('.interchange_price_label', __filenamespace)}
    />
  ),
  priceFieldLabel: (
    <FormattedMessage id={messageId('.price_field_label', __filenamespace)} />
  ),
};

export default injectIntl(PriceFields);
