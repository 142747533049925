import * as React from 'react';
import { Route } from 'react-router-dom';

import ContactsReportWrapper from 'reporting/index/components/_ContactsReport.jsx';
import InventoryReport from 'reporting/inventory/components/InventoryReport.jsx';
import MonthlyRecurringRevenueReport from 'reporting/monthly_recurring_revenue/components/MonthlyRecurringRevenueReport.jsx';
import OrdersReport from 'containers/reports/ordersReport/OrdersReport.jsx';
import PayrollReport from 'reporting/payroll/components/PayrollReport.jsx';
import RevenueReport from 'reporting/revenue/components/_RevenueReport.jsx';
import SalesDetail from 'reporting/sales_detail/components/SalesDetail.jsx';
import TransactionsLedgerReport from 'containers/reports/transactionsLedgerReport/TransactionsLedgerReport.jsx';
import WaiversReport from 'reporting/waivers/components/WaiversReport.jsx';
import BalancesReport from 'containers/reports/balancesReport/BalancesReport.jsx';
import ModeReport from 'containers/reports/modeReport/ModeReport.jsx';
import DailyCloseOutReport from 'containers/reports/dailyCloseoutReport/DailyCloseOutReport.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';

import {
  isAdmin,
  isStaff,
  isStaffAdmin,
  isInstructor,
  isManager,
  transactionAllowedRoles,
} from 'routes/helpers/routePermissions';

const reportsRoutes = () => (
  <Route path=":customer_id/reports">
    <Route
      path="contacts_report"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <ContactsReportWrapper />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="payroll"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff, isInstructor]}>
          <PayrollReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="orders"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <OrdersReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="ledger"
      element={
        <AuthorizedPageWrapper allowedRoles={transactionAllowedRoles}>
          <TransactionsLedgerReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="sales_detail"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <SalesDetail />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="revenue_report"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <RevenueReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="monthly_recurring_revenue_report"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <MonthlyRecurringRevenueReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="waivers_report"
      element={
        <AuthorizedPageWrapper allowedRoles={transactionAllowedRoles}>
          <WaiversReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="inventory"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <InventoryReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="balances"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin, isStaff]}>
          <BalancesReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="intelligence"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <ModeReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="daily_closeout"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff, isManager]}>
          <DailyCloseOutReport />
        </AuthorizedPageWrapper>
      }
    />
  </Route>
);

export default reportsRoutes;
