/* eslint-disable react/require-default-props */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Set, Map } from 'immutable';
import { MultipleSelect } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import { LocationDataStore } from 'dataStores';

function LocationFilter({
  value = new Set(),
  locationIds = Set(),
  records: locations = Map(),
  onChange = () => null,
}) {
  const intl = useIntl();

  const locationItems = locationIds.map(locationId => ({
    label: locations.get(locationId)?.name,
    value: locationId,
  }));

  return (
    <MultipleSelect
      fullWidth
      outline
      rounded
      selectAll
      name="locations"
      label={t('.choose_location', intl, __filenamespace)}
      classes={{
        formControl: 'location-filter',
        select: 'location-filter__select',
        inputLabel: 'location-filter__label',
        menuItem: 'location-filter__menu-item',
        displayText: 'location-filter__display-text',
      }}
      items={locationItems.toJS()}
      selectedItems={value.toJS()}
      onChange={onChange}
    />
  );
}

LocationFilter.propTypes = {
  value: PropTypes.instanceOf(Set),
  locationIds: PropTypes.instanceOf(Set),
  records: PropTypes.instanceOf(Map),
  onChange: PropTypes.func,
};

export default altContainer({
  store: LocationDataStore,
})(LocationFilter);
