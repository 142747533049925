import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ContactsReportActions from 'reporting/index/actions/_ContactsReportActions.jsx';
import ContactsReportStore from 'reporting/index/stores/_ContactsReportStore.jsx';
import FranchiseLocationsStore from 'reporting/index/stores/_FranchiseLocationsStore.jsx';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

function formatDate(date, formatString = 'MMM D, YYYY') {
  if (date) {
    const tz = currentCustomer().tz_name || moment.tz.guess();
    return moment.tz(date, tz).format(formatString);
  }

  return null;
}

function Row({ contact }) {
  return (
    <TableRow>
      <TableCell>{contact.get('first_name')}</TableCell>
      <TableCell>{contact.get('last_name')}</TableCell>
      <TableCell>{contact.get('email')}</TableCell>
      <TableCell>{contact.get('phone')}</TableCell>
      <TableCell>
        {formatDate(contact.get('date_of_birth'), 'MM/DD/YYYY')}
      </TableCell>
      <TableCell>{formatDate(contact.get('last_login_at'))}</TableCell>
      <TableCell>{formatDate(contact.get('invite_sent_at'))}</TableCell>
    </TableRow>
  );
}

class ContactsList extends React.Component {
  shouldComponentUpdate({ contactsReportStore: nextStore }) {
    const { contactsReportStore: currentStore } = this.props;
    const statusChanged =
      currentStore.isContactsListLoading !== nextStore.isContactsListLoading;
    const resultChanged =
      currentStore.contactsListResult !== nextStore.contactsListResult;
    return statusChanged || resultChanged;
  }

  renderRows() {
    const {
      contactsReportStore: { contactsListResult = [] },
    } = this.props;
    return contactsListResult.map(c => <Row key={c.get('id')} contact={c} />);
  }

  render() {
    const {
      contactsReportStore: { isContactsListLoading },
      franchiseLocationsStore: { areFranchiseLocationsLoading },
      intl,
    } = this.props;
    return (
      <Widget
        title={t('.title', intl, __filenamespace)}
        isLoading={isContactsListLoading || areFranchiseLocationsLoading}
        actions={[
          {
            icon: 'cloud_download',
            onClick: ContactsReportActions.downloadContactsList,
          },
        ]}
      >
        <TableContainer sx={{ height: '300px' }}>
          <Table stickyHeader sx={{ maxHeight: '350px' }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('.first_name', intl, __filenamespace)}</TableCell>
                <TableCell>{t('.last_name', intl, __filenamespace)}</TableCell>
                <TableCell>{t('.email', intl, __filenamespace)}</TableCell>
                <TableCell>
                  {t('.phone_number', intl, __filenamespace)}
                </TableCell>
                <TableCell>
                  {t('.date_of_birth', intl, __filenamespace)}
                </TableCell>
                <TableCell>
                  {t('.last_login_date', intl, __filenamespace)}
                </TableCell>
                <TableCell>{t('.added_date', intl, __filenamespace)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderRows()}</TableBody>
          </Table>
        </TableContainer>
      </Widget>
    );
  }
}

function ContactsListWrapper({ intl }) {
  return (
    <AltContainer
      stores={{
        contactsReportStore: ContactsReportStore,
        franchiseLocationsStore: FranchiseLocationsStore,
      }}
    >
      <ContactsList intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(ContactsListWrapper);
