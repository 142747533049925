import { OrderedMap } from 'immutable';
import Client from 'shared/records/Client.jsx';
import * as ClientSource from 'sources/ClientSource';
import QSClientListActions from 'calendar/actions/QSClientListActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

export const FILTER_STATUS_UNCLAIMED = 'unclaimed';
export const FILTER_STATUS_ACTIVE = 'active';
export const FILTER_STATUS_INVITED = 'invited';
// TODO [zgoldman:2018-09-14]: Very similar to POSClientStore. DRY.
class QSClientListStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      // listClients:          POSActions.POS_CLICKED,
      listSuccess: QSClientListActions.LIST_SUCCESS,
      listError: QSClientListActions.LIST_ERROR,

      updateSearchString: QSClientListActions.SEARCH_STRING_UPDATED,
      requestPage: QSClientListActions.PAGE_SELECTED,

      // createOrUpdateClient: POSClientActions.CREATE_OR_UPDATE_CLIENT_SUCCESS,

      // reset:                POSCheckoutActions.CLOSE_BUTTON_CLICKED,
    });
  }

  reset() {
    this.clients = OrderedMap();

    this.searchString = '';
    this.page = 1;
    this.perPage = 15;
    this.totalCount = 0;

    this.shouldFetch = true;
    this.isLoading = false;
  }

  listClients() {
    if (this.shouldFetch) {
      this.isLoading = true;
      this.shouldFetch = false;

      const params = {
        page: this.page,
        per_page: this.perPage,
        statuses: [
          FILTER_STATUS_ACTIVE,
          FILTER_STATUS_INVITED,
          FILTER_STATUS_UNCLAIMED,
        ],
        fields: ['total_account_credit'],
      };

      if (this.searchString) {
        params.search = this.searchString;
      }

      ClientSource.list({
        params,
        success: QSClientListActions.listSuccess,
        error: QSClientListActions.listError,
      });
    }
  }

  listSuccess({ clients, page, perPage, totalCount }) {
    this.isLoading = false;
    this.page = page;
    this.perPage = perPage; // eslint-disable-line camelcase
    this.totalCount = totalCount; // eslint-disable-line camelcase

    this.clients = OrderedMap().withMutations(clientMap =>
      clients.forEach(c => clientMap.set(c.id, new Client(c)))
    );
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing clients', args);
  }

  updateSearchString(searchString) {
    if (!this.shouldFetch && this.searchString === searchString) {
      return;
    }

    this.searchString = searchString;
    this.page = 1;
    this.shouldFetch = true;

    this.listClients();
  }

  requestPage(page) {
    if (this.page === page) {
      return;
    }

    this.page = page;
    this.shouldFetch = true;

    this.listClients();
  }
}

export default alt.createStore(QSClientListStore, 'QSClientListStore');
