import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function CreditCard(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 15 12">
      <path
        d="M13.5 0H1.5C0.6675 0 0.00749999 0.6675 0.00749999 1.5L0 10.5C0 11.3325 0.6675 12 1.5 12H13.5C14.3325 12 15 11.3325 15 10.5V1.5C15 0.6675 14.3325 0 13.5 0ZM13.5 10.5H1.5V6H13.5V10.5ZM13.5 3H1.5V1.5H13.5V3Z"
        fill="var(--color-ui-gray)"
      />
    </SvgIcon>
  );
}

export default CreditCard;
