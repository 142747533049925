import * as React from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  MultipleSelect,
  Spinner,
  TextField,
} from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import StaffDetailsDrawer from 'contacts/index/components/StaffDetailsDrawer.jsx';
import { AvailabilityScheduleDataStore, StaffDataStore } from 'dataStores';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import { smallScreen } from 'shared/utils/DOMUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import StaffCard from './components/StaffCard.jsx';
import UpdateContact from '../updateContact/UpdateContact.jsx';
import RevokeAccessModal from './components/RevokeAccessModal.jsx';

import ContactsStaffActions from './Actions';
import ContactsStaffStore, {
  FILTER_STATUS_ALL,
  FILTER_STATUS_ACTIVE,
  FILTER_STATUS_INVITED,
} from './Store';

import { STAFF_TYPES_OPTIONS } from './constants.js';

const onSearchChange = (_event, value) =>
  ContactsStaffActions.searchUpdated(value);

const onStatusesChange = event =>
  ContactsStaffActions.statusesUpdated(event.target.value);

const onStaffTypesSelection = staffTypes =>
  ContactsStaffActions.staffTypesUpdated(staffTypes);

function ContactsStaff({
  staffDataStore: { staff: staffMap },
  availabilityScheduleDataStore: { availabilitySchedules },
  contactsStaffStore: {
    isRevokeAccessModalOpen,
    isRevokeDataLoading,
    revokeAccessStaff,
    revokeAccessSessionsCount,
    staffIds,
    page,
    perPage,
    totalCount,
    search,
    statuses,
    loading,
    updateContactId,
    staffTypes,
  },
  intl,
}) {
  const isMobile = smallScreen();
  const staffTypesOptions = [...STAFF_TYPES_OPTIONS];
  React.useEffect(() => {
    ContactsStaffActions.mounted();
  }, [updateContactId]);

  // after we have set and rendered the edit model we need to clear our state so store controls open state.
  if (updateContactId) {
    ContactsStaffActions.updateContact(null);
  }

  if (currentCustomer().features.coach) {
    staffTypesOptions.push({ label: 'Coach', value: 'coach' });
  }

  return (
    <div style={{ paddingTop: 16 }}>
      <Grid container spacing={1} alignItems="center">
        {!currentUser().isInstructor() && (
          <>
            <div className="contacts__add-button">
              <Button
                onClick={InviteDialogActions.openInviteDialog}
                rounded
                icon={<Icon name="add" className="contacts__add-button-icon" />}
                size="2x"
              />
            </div>
            <Grid item xs>
              <TextField
                fullWidth
                rounded
                outline={false}
                icon="search"
                placeholder="Search"
                value={search}
                onChange={onSearchChange}
              />
            </Grid>
            <Grid item className={isMobile && 'contacts__grid-item-mobile'}>
              <MultipleSelect
                fullWidth
                items={staffTypesOptions}
                name={t('.staff_types', intl, __filenamespace)}
                rounded
                outline={false}
                selectedItems={staffTypes}
                selectAll
                onChange={selectedValue => {
                  onStaffTypesSelection(selectedValue);
                }}
              />
            </Grid>
            <Grid
              item
              className={
                isMobile ? 'contacts__grid-item-mobile' : 'contacts__grid-item'
              }
            >
              <Dropdown
                rounded
                fullWidth
                outline={false}
                items={[
                  { label: 'Status: All', value: FILTER_STATUS_ALL },
                  {
                    label: 'Status: Active',
                    value: FILTER_STATUS_ACTIVE,
                  },
                  {
                    label: 'Status: Invited',
                    value: FILTER_STATUS_INVITED,
                  },
                ]}
                value={statuses}
                onChange={onStatusesChange}
              />
            </Grid>
          </>
        )}

        {loading ? (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', marginTop: 100 }}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <>
            <Grid item container spacing={1} className="contacts__staff-list">
              {staffIds.map(id => {
                const staff = staffMap.get(id);

                return (
                  <Grid item xs={12} key={staff}>
                    <StaffCard
                      staff={staff}
                      availabilitySchedule={availabilitySchedules.get(
                        staff.availability_schedule_id
                      )}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Grid item xs={12}>
              <Paginator
                className="contacts__pagination"
                currentPage={page}
                perPage={perPage}
                totalCount={totalCount}
                onPageSelect={ContactsStaffActions.pageSelected}
                pageLimit={0}
                showInfo
              />
            </Grid>
          </>
        )}
      </Grid>
      <UpdateContact
        contactId={updateContactId}
        contactType="staff_member"
        isStaffContact
      />
      <StaffDetailsDrawer />
      <RevokeAccessModal
        isOpen={isRevokeAccessModalOpen}
        isLoading={isRevokeDataLoading}
        staff={revokeAccessStaff}
        sessionsCount={revokeAccessSessionsCount}
      />
    </div>
  );
}

export default altContainer({
  stores: {
    availabilityScheduleDataStore: AvailabilityScheduleDataStore,
    staffDataStore: StaffDataStore,
    contactsStaffStore: ContactsStaffStore,
  },
})(injectIntl(ContactsStaff));
