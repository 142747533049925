import path from 'path';
//--------------
// If this changes, update application.css calculated-column-width-*
//--------------
export const LEFT_MARGIN_PIXELS = 30;
export const RIGHT_MARGIN_PIXELS = 30;
export const SINGLE_COLUMN_WIDTH = '27.5rem';
export const DOUBLE_COLUMN_WIDTH = '77rem';
export const STANDARD_DRAWER_WIDTH = 384;
//--------------

export const singleColumnWidthNumeric = () =>
  38.5 * parseFloat(window.getComputedStyle(document.documentElement).fontSize);

export const smallScreen = (breakpoint = 768) =>
  window.innerWidth <= breakpoint;

export const windowWidth = inner =>
  inner ? window.innerWidth : window.outerWidth;

export const generateHtmlId = (uniqueIdentifier, fileName = 'global') => {
  const reactUiRoot = 'src';

  return `${fileName
    .substr(fileName.indexOf(reactUiRoot) + reactUiRoot.length)
    .split(path.sep)
    .filter(e => !(!e || e === 'components'))
    .map(e => e.replace('.jsx', ''))
    .join('-')}-${uniqueIdentifier}`;
};

export const parseHTML = htmlString => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || '';
};
