import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { PaymentPlan } from 'records';
import Client from 'shared/records/Client.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import PaymentPlanDetailsDrawerActions from 'containers/reports/paymentPlanDetailsDrawer/Actions';

function RowDesktop({ paymentPlan, client }) {
  const activeItems = 6;

  if (!paymentPlan) return null;

  return (
    <Card
      classes={{
        root: 'payment-plans__row',
        content: 'payment-plans__row-content',
      }}
      contentDirection="row"
      onClick={() => PaymentPlanDetailsDrawerActions.openDrawer(paymentPlan.id)}
    >
      <Grid container spacing={1} alignContent="center" justify="center">
        <Grid
          item
          className={`payment-plans__row-item even-cols-${activeItems}`}
        >
          <UserAvatar user={client} size={32} showTypeBadge />
          <Typography variant="body2">
            {client ? client.name() : '-'}
          </Typography>
        </Grid>
        <Grid
          item
          className={`payment-plans__row-item even-cols-${activeItems}`}
        >
          <Typography variant="body2">
            <FormattedCurrency value={paymentPlan.amount} fromCents />
          </Typography>
        </Grid>
        <Grid
          item
          className={`payment-plans__row-item even-cols-${activeItems}`}
        >
          <Typography variant="body2">
            <FormattedCurrency value={paymentPlan.remainingAmount} fromCents />
          </Typography>
        </Grid>
        <Grid
          item
          className={`payment-plans__row-item even-cols-${activeItems}`}
        >
          <Typography variant="body2">
            {paymentPlan.startDate
              ? moment(paymentPlan.startDate).format('MM/DD/YYYY')
              : '-'}
          </Typography>
        </Grid>
        <Grid
          item
          className={`payment-plans__row-item even-cols-${activeItems}`}
        >
          <Typography variant="body2">
            {paymentPlan.endDate
              ? moment(paymentPlan.endDate).format('MM/DD/YYYY')
              : '-'}
          </Typography>
        </Grid>
        <Grid
          item
          className={`payment-plans__row-item even-cols-${activeItems}`}
        >
          {paymentPlan.nextPaymentDate
            ? moment(paymentPlan.nextPaymentDate).format('MM/DD/YYYY')
            : '-'}
        </Grid>
      </Grid>
      <div className="payment-plans__row-item payment-plans__row-action">
        <ChevronRightIcon />
      </div>
    </Card>
  );
}

RowDesktop.propTypes = {
  paymentPlan: PropTypes.instanceOf(PaymentPlan).isRequired,
  client: PropTypes.instanceOf(Client).isRequired,
};

export default RowDesktop;
