import React from 'react';
import { injectIntl } from 'react-intl';
import { Drawer } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils.js';

import Schedules from '../Schedule/Schedules.jsx';
import ClientInfo from './ClientInfo.jsx';

import ClientProfileDrawerActions from './ClientProfileDrawer/Actions';
import ClientProfileDrawerStore from './ClientProfileDrawer/Store';

function SchedulesDrawer({
  intl,
  clientProfileDrawerStore: {
    registrationIds,
    pastRegistrationIds,
    pagination,
    pastLoading,
    upcomingLoading,
    showSchedulesDrawer,
    clientId,
  },
  onSessionSummaryOpen,
}) {
  return (
    <Drawer
      classes={{ root: 'schedule-drawer' }}
      open={showSchedulesDrawer}
      title={t('.title', intl, __filenamespace)}
      onClose={ClientProfileDrawerActions.closeScheduleDrawer}
      content={
        <>
          <ClientInfo clientId={clientId} />
          <Schedules
            isMobile
            actions={ClientProfileDrawerActions}
            intl={intl}
            pastLoading={pastLoading}
            upcomingLoading={upcomingLoading}
            registrationIds={registrationIds}
            pastRegistrationIds={pastRegistrationIds}
            pagination={pagination}
            onSessionSummaryOpen={onSessionSummaryOpen}
          />
        </>
      }
    />
  );
}

export default compose(
  React.memo,
  injectIntl,
  altContainer({
    stores: { clientProfileDrawerStore: ClientProfileDrawerStore },
  })
)(SchedulesDrawer);
