import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { List, Map } from 'immutable';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import Paper from '@mui/material/Paper';
import {
  Button as PMButton,
  DateTimePicker,
  Typography,
} from '@upperhand/playmaker';

import { dateWithTime } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { compose } from 'shared/utils/SharedUtils';

const styles = {
  root: ({ showPaper }) => ({
    padding: showPaper ? 16 : 0,
    minWidth: 262,
    marginTop: showPaper ? 8 : 0,
  }),
  addAnother: {
    textTransform: 'capitalize',
  },
  dayLabel: {
    marginBottom: 8,
  },
  timeInputTextField: {
    width: 100,
  },
  timeRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 8,
  },
  deleteButton: {
    backgroundColor: 'transparent',
    marginLeft: 4,
  },
};

function TimePickerRow({
  day,
  time,
  index,
  intl,
  times,
  fieldErrors,
  classes,
  onUpdateDayTimes,
  onChangeDayTime,
}) {
  const handleDeleteTime = currentIndex => {
    onUpdateDayTimes(day, (_times, weekday, daytimes) => {
      onChangeDayTime([weekday], daytimes.get(weekday).delete(currentIndex));
    });
  };

  const handleChangeTime = (property, value, currentIndex, currentTimes) => {
    onUpdateDayTimes(day, (_times, weekday, _daytimes) => {
      onChangeDayTime([weekday, currentIndex, property], value);
    });

    const oppositeTimeProp =
      property === 'start_time' ? 'end_time' : 'start_time';
    const newTimes = currentTimes.set(property, value);
    // Check if opposite time value has to be changed
    if (
      !newTimes.get('end_time') ||
      !newTimes.get('start_time') ||
      dateWithTime(newTimes.get('end_time')) <=
        dateWithTime(newTimes.get('start_time'))
    ) {
      // Moment value for opposite time
      const oppositeTimeMoment = moment(value, 'HH:mm:ss');
      if (oppositeTimeProp === 'start_time') {
        oppositeTimeMoment.subtract(1, 'hour');
      } else {
        oppositeTimeMoment.add(1, 'hour');
      }
      const otherTimeValue = oppositeTimeMoment.format('HH:mm:ss');
      onUpdateDayTimes(day, (__times, weekday, _daytimes) => {
        onChangeDayTime(
          [weekday, currentIndex, oppositeTimeProp],
          otherTimeValue
        );
      });
    }
  };

  const startTime = dateWithTime(time.get('start_time'));
  const endTime = dateWithTime(time.get('end_time'));
  const timeInvalid = moment(startTime).isSameOrAfter(
    moment(endTime),
    'minutes'
  );

  let endTimeErrorText;
  if (endTime && timeInvalid) {
    endTimeErrorText = fieldErrors.get('times', []).join(' ,');
  } else if (!endTime) {
    endTimeErrorText = fieldErrors.get('end_time', []).join(' ,');
  }

  return (
    <div style={styles.timeRow}>
      <DateTimePicker
        classes={{
          root: classes.timeInputTextField,
        }}
        autoOk={false}
        ampm
        errorText={
          startTime ? '' : fieldErrors.get('start_time', []).join(' ,')
        }
        type="time"
        onChange={value =>
          handleChangeTime('start_time', value.format('HH:mm:ss'), index, time)
        }
        name={`start_time_${index}`}
        placeholder={t('.start_time', intl, __filenamespace)}
        value={startTime}
        format="h:mm a"
        showClearIcon={false}
        minutesStep={1}
        disablePast
      />
      <div style={{ alignSelf: 'center' }}>&ensp;&mdash;&ensp;</div>
      <DateTimePicker
        classes={{
          root: classes.timeInputTextField,
        }}
        autoOk={false}
        ampm
        errorText={endTimeErrorText}
        type="time"
        onChange={value =>
          handleChangeTime('end_time', value.format('HH:mm:ss'), index, time)
        }
        name={`end_time${index}`}
        placeholder={t('.end_time', intl, __filenamespace)}
        value={endTime}
        format="h:mm a"
        showClearIcon={false}
        minutesStep={1}
        disablePast
      />
      {times.size > 1 && (
        <PMButton
          classes={{
            root: classes.deleteButton,
          }}
          type="tertiary"
          icon="close"
          rounded
          onClick={() => handleDeleteTime(index)}
        />
      )}
    </div>
  );
}

const TimeRangeList = injectIntl(
  ({
    classes,
    intl,
    times,
    label,
    paperDepth,
    fieldErrors,
    day,
    onUpdateDayTimes,
    onChangeDayTime,
    allowAddTimeRange,
  }) => {
    const addEmptyTime = () => {
      onUpdateDayTimes(day, (_times, weekday, daytimes) => {
        onChangeDayTime([weekday], daytimes.get(weekday).push(Map()));
      });
    };

    return (
      <Paper zDepth={paperDepth} className={classes.root}>
        {typeof label === 'string' ? (
          <Typography variant="body1" noWrap className={classes.dayLabel}>
            {label}
          </Typography>
        ) : (
          label
        )}
        {times.map((time, index) => (
          <TimePickerRow
            // eslint-disable-next-line react/no-array-index-key
            key={`${day}-${index}`}
            times={times}
            time={time}
            index={index}
            classes={classes}
            fieldErrors={fieldErrors}
            intl={intl}
            onUpdateDayTimes={onUpdateDayTimes}
            onChangeDayTime={onChangeDayTime}
            day={day}
          />
        ))}
        {allowAddTimeRange && (
          <Button
            color="dark"
            startIcon={<AddIcon sx={{ color: uhColors.activeBlue }} />}
            style={styles.addAnother}
            onClick={addEmptyTime}
          >
            {t('.add_time_range', intl, __filenamespace)}
          </Button>
        )}
      </Paper>
    );
  }
);

TimeRangeList.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  times: PropTypes.instanceOf(List).isRequired,
  day: PropTypes.oneOfType([PropTypes.instanceOf(List), PropTypes.string])
    .isRequired,
  label: PropTypes.string,
  paperDepth: PropTypes.number,
  fieldErrors: PropTypes.instanceOf(Map),
  onUpdateDayTimes: PropTypes.func,
  onChangeDayTime: PropTypes.func,
  allowAddTimeRange: PropTypes.bool,
  showPaper: PropTypes.bool,
};

TimeRangeList.defaultProps = {
  label: '',
  paperDepth: 0,
  fieldErrors: Map(),
  onUpdateDayTimes: () => {},
  onChangeDayTime: () => {},
  allowAddTimeRange: true,
  showPaper: true,
};

export default compose(withStyles(styles), injectIntl)(TimeRangeList);
