import React from 'react';
import { FormattedMessage } from 'react-intl';

import ContactImportNativeActions from 'contacts/index/actions/ContactImportNativeActions.js';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import styles from 'contacts/index/components/ContactImportNative/styles';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const Contact = ({ contact, disabled, avatarStyle = {}, children }) => {
  const avatarUser = {
    first_name: contact.firstLetterOfName(),
    alternativeImage: () => contact.profilePictureURL,
  };
  return (
    <div
      style={styles.contactCard}
      onClick={() => {
        if (disabled) {
          return;
        }
        ContactImportNativeActions.toggleContactSelected(contact);
      }}
    >
      <FlexBoxCenter style={{ alignItems: 'center' }}>
        <div style={avatarStyle}>
          <UserAvatar user={avatarUser} />
        </div>
        <FlexBox style={{ flexDirection: 'column' }}>
          <div style={styles.label}>{contact.fullName()}</div>
          {!contact.canImport() ? (
            <div style={styles.error}>
              <FormattedMessage
                id={messageId(contact.importErrorMessage(), __filenamespace)}
              />
            </div>
          ) : (
            <div style={styles.label}>{contact.email}</div>
          )}
          {children}
        </FlexBox>
      </FlexBoxCenter>
    </div>
  );
};

export default Contact;
