/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
import * as React from 'react';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { Checkbox } from '@upperhand/playmaker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Header from 'point_of_sale/components/Header.jsx';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import POSActions from 'point_of_sale/actions/POSActions.jsx';
import PosCartActions from 'point_of_sale/actions/POSCartActions.jsx';

const styles = {
  closeButton: {
    height: 50,
  },
  closeButtonText: {
    fontSize: 16,
  },
  contentContainer: {
    padding: 20,
  },
  formWarning: {
    padding: 20,
    margin: '-20px -20px 15px -20px',
  },
  summaryItemDivider: {
    marginLeft: -20,
    marginRight: -20,
  },
  button: {
    height: 50,
    marginBottom: 10,
    fontSize: 16,
  },
  buttonLabel: {
    fontSize: 16,
  },
};

const closeButtonClicked = e => {
  e.preventDefault();
  POSActions.switchToCheckout();
};

const ProRateMembership = injectIntl(
  ({ intl, posStore, posCartStore, paymentStore }) => {
    const { cart } = posCartStore;
    const [checked, setChecked] = React.useState(true);
    const [memberships, setMemberships] = React.useState();
    const [date, setDate] = React.useState(moment().add(1, 'days').toDate());
    React.useEffect(() => {
      const membershipArray = cart?.order_items?.reduce((acc, curr) => {
        if (curr?.isMembershipItem() && curr !== undefined) {
          return [...acc, curr.toServer()];
        }
        return [...acc];
      }, []);
      setMemberships(membershipArray);
    }, [cart.order_items]);

    const totalPrice = memberships?.reduce((partialSum, curr) => {
      if (
        curr?.membership_subscription_package_attributes
          ?.membership_active_at === null ||
        !moment().isBefore(
          curr?.membership_subscription_package_attributes
            ?.membership_active_at,
          'days'
        )
      ) {
        return partialSum + curr?.subtotal;
      }
      return partialSum;
    }, 0);

    const updateProRatePrice = value => {
      const membershipArray = cart?.order_items?.reduce((acc, curr) => {
        if (curr?.isMembershipItem() && curr !== undefined) {
          return [...acc, curr?.toServer()];
        }
        return [...acc];
      }, []);
      const updatedMemberships = membershipArray?.map(item => {
        const newDate = moment(value);
        const todaysDate = new Date();
        if (
          Number(moment(newDate).format('MM')) ===
          Number(todaysDate.getMonth() + 1)
        ) {
          const numberOfDaysOfMonth = moment(todaysDate).daysInMonth();
          const priceForOneDay = item?.subtotal / numberOfDaysOfMonth;
          const difference = Math.abs(
            Math.round(todaysDate.getDate() - newDate.format('D'))
          );
          const proRatedPrice = Math.round(difference * priceForOneDay);
          item.subtotal = proRatedPrice;
          item.total = proRatedPrice + item.tax;
        }
        if (
          Number(moment(newDate).format('MM')) !==
          Number(todaysDate.getMonth() + 1)
        ) {
          const numberOfDaysOfMonth = moment(todaysDate).daysInMonth();
          const priceForOneDay = item?.subtotal / numberOfDaysOfMonth;
          let difference = Math.abs(numberOfDaysOfMonth - todaysDate.getDate());
          difference += Number(newDate.format('D'));
          const proRatedPrice = Math.round(difference * priceForOneDay);
          item.subtotal = proRatedPrice;
          item.total = proRatedPrice + item.tax;
        }
        return item;
      });
      setMemberships([...updatedMemberships]);
    };

    const handleContinueToCart = () => {
      if (checked) {
        const requestProRateMembershipArray = memberships.map(item => ({
          mem_id: item?.id,
          prorated_price: item.subtotal,
        }));

        PosCartActions.proRateUpdate({
          prorated_memberships: requestProRateMembershipArray,
          charge_now: checked,
          prorate_date: moment(date).format('YYYY-MM-DD HH:mm:ss Z'),
        });
      }

      if (!checked) {
        const requestProRateMembershipArray = memberships.map(item => ({
          mem_id: item?.id,
          prorated_price:
            item.membership_subscription_package_attributes
              .subscribable_join_fee ?? 0,
        }));

        PosCartActions.proRateUpdate({
          prorated_memberships: requestProRateMembershipArray,
          charge_now: checked,
          prorate_date: moment(date).format('YYYY-MM-DD HH:mm:ss Z'),
        });
      }
    };

    const maxDate = () => {
      const todaysDateMax = new Date();
      todaysDateMax.setMonth(
        todaysDateMax.getMonth() + 1,
        moment(todaysDateMax).daysInMonth() === 30 ? 31 : 30
      );
      return moment(todaysDateMax);
    };

    React.useEffect(() => {
      updateProRatePrice(date);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    return (
      <div>
        <Header
          selectedClient={posStore.selectedClient}
          onClickBack={closeButtonClicked}
          processingPayment={
            paymentStore.processingPayment || paymentStore.processingFields
          }
        />
        <div style={styles.contentContainer}>
          <div>
            <p style={{ fontWeight: 'bold' }}>
              {t('.memberships', intl, __filenamespace)}
            </p>
          </div>
          {cart?.order_items &&
            cart?.order_items?.map(item => {
              if (
                (item?.isMembershipItem() &&
                  item?.orderable?.membership_active_at === null) ||
                !moment().isBefore(
                  item?.orderable?.membership_active_at,
                  'days'
                )
              ) {
                return (
                  <FlexBoxJustify
                    style={{
                      marginBottom: '1rem',
                      alignItems: 'center',
                      marginTop: '1rem',
                    }}
                  >
                    <div style={{ flex: '0 0 25%' }}>
                      <p>{item?.primary_display_text}</p>
                    </div>
                    <div style={{ flex: '0 0 50%' }}>
                      <FormattedCurrency value={item?.subtotal} fromCents />
                    </div>
                  </FlexBoxJustify>
                );
              }
              return '';
            })}
          <FlexBoxJustify
            style={{
              marginBottom: '1rem',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <div style={{ flex: '0 0 25%' }}>
              <p style={{ fontWeight: 'bold' }}>
                {t('.bill_date', intl, __filenamespace)}
              </p>
            </div>
            <div style={{ flex: '0 0 50%' }}>
              <DatePicker
                value={date}
                minDate={moment().add(1, 'days')}
                maxDate={maxDate()}
                renderInput={params => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField variant="standard" {...params} />
                )}
                DialogProps={{ disableEnforceFocus: true }}
                onChange={value => {
                  const billDate = value.toDate();

                  billDate.setHours(moment().format('HH'));
                  billDate.setMinutes(moment().format('mm'));
                  billDate.setSeconds(moment().format('ss'));
                  setDate(billDate);
                }}
              />
            </div>
          </FlexBoxJustify>

          <FlexBoxJustify
            style={{
              marginBottom: '1rem',
              marginTop: '1rem',
            }}
          >
            <div style={{ flex: '0 0 25%' }}>
              <p style={{ fontWeight: 'bold' }}>
                {t('.due_today', intl, __filenamespace)}
              </p>
            </div>
            <div style={{ flex: '0 0 50%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormattedCurrency value={totalPrice} fromCents />
                <Checkbox
                  label={t('.chargeNow', intl, __filenamespace)}
                  onChange={(_, value) => {
                    setChecked(value);
                  }}
                  checked={checked}
                />
              </div>
            </div>
          </FlexBoxJustify>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            style={styles.button}
            onClick={handleContinueToCart}
          >
            {t('.continue_to_cart', intl, __filenamespace)}
          </Button>
        </div>
      </div>
    );
  }
);

export default ProRateMembership;
