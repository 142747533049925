import moment from 'moment-timezone';

function endDateFromRepeatDuration(
  startDate,
  interval,
  frequency,
  repeatDuration,
  weekdays
) {
  if (startDate && interval > 0 && repeatDuration > 0 && weekdays.max() >= 0) {
    let endDate;
    if (frequency === 'weekly') {
      endDate = moment(startDate)
        .startOf('week')
        .add(interval * (repeatDuration - 1) + 1, 'weeks')
        .subtract(7 - weekdays.max(), 'days');
    } else {
      endDate = moment(startDate)
        .add(interval * (repeatDuration - 1), 'days')
        .add(weekdays.max(), 'days');
    }

    return moment.max(endDate, moment(startDate)).toDate();
  }
  return '';
}

function endDateFromStopsBy(startDate, interval, frequency, weekdays, stopsBy) {
  if (startDate && weekdays.max() >= 0 && stopsBy) {
    const stopsByMoment = moment(stopsBy);

    if (frequency === 'weekly') {
      if (interval > 1) {
        const intervalCorrection =
          Math.trunc(
            moment
              .duration(stopsByMoment.diff(moment(startDate).startOf('week')))
              .asWeeks()
          ) % interval;
        if (intervalCorrection !== 0) {
          stopsByMoment.subtract(intervalCorrection, 'weeks').endOf('week');
        }
      }

      const correctEndDay = weekdays.maxBy(day => {
        const _day = parseInt(day, 10);
        return _day > stopsByMoment.day() ? _day - 7 : _day;
      });

      if (correctEndDay > stopsByMoment.day()) {
        stopsByMoment.subtract(
          stopsByMoment.day() - correctEndDay + 7 * interval,
          'days'
        );
      } else {
        stopsByMoment.subtract(stopsByMoment.day() - correctEndDay, 'days');
      }
    }

    return moment.max(stopsByMoment, moment(startDate)).toDate();
  }
  return '';
}

/**
* Calculates the end date of a repeating event based on start date, interval, and repeat duration
* assuming weekly repetition frequency.
*
* @param {Moment|Date|string} startDate - The start date of the event.
* @param {number} interval - The interval of repetition (every n weeks).
* @param {number} repeatDuration - The number of times to repeat the event.
* @param {number[]} weekdays - Array of weekdays (0-6) that the event occurs on.
* @param {Date} [stopsBy] - The date the event stops by. Takes precedence over repeat_duration
                            if present.
*
* @return {Date|string} Returns a Date object representing the end date if the event has a valid
*                       end date or an empty string if it does not.
*/
export function calculateEndDate(
  startDate,
  interval,
  repeatDuration,
  weekdays,
  stopsBy,
  frequency = 'weekly'
) {
  if (stopsBy) {
    return endDateFromStopsBy(
      startDate,
      interval,
      frequency,
      weekdays,
      stopsBy
    );
  }
  return endDateFromRepeatDuration(
    startDate,
    interval,
    frequency,
    repeatDuration,
    weekdays
  );
}
