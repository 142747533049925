/**
 * Helper to check for device ready with timeout
 */

let deviceReady = false;

// Can't use window.user_agent because it is not available immediately
// need to try shared/utils/UserAgentUtils.js see if we can use bowser for this?
function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
}

function _readyTimeoutInterval() {
  return isMobile() ? 5000 : 0;
}

function _scheduleWhenDeviceReady(fireMethod) {
  // Time out
  const timer = setTimeout(() => {
    deviceReady = true;
    fireMethod();
  }, _readyTimeoutInterval());
  // Fire after device ready
  document.addEventListener(
    'deviceready',
    () => {
      deviceReady = true;
      window.clearTimeout(timer);
      fireMethod();
    },
    false
  );
}

export default function onCordovaDeviceReady(success) {
  if (deviceReady) {
    success();
  } else {
    _scheduleWhenDeviceReady(success);
  }
}
