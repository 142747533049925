import * as React from 'react';
import { injectIntl } from 'react-intl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import RecurringPaymentPlanSummary from 'automations/display/components/RecurringPaymentPlanSummary.jsx';
import { boldText } from 'shared/styles/uhStyles.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function PaymentPlanPrimaryText({ intl, plan, showPrice }) {
  const payments = plan.get('payments');

  return (
    <div>
      <div style={merge(boldText)}>{plan.get('name')}</div>
      {showPrice && (
        <FlexBoxJustify>
          {t('.n_payments', intl, __filenamespace, { n: payments.size })}
          <FormattedCurrency intl={intl} value={plan.totalPrice()} fromCents />
        </FlexBoxJustify>
      )}
    </div>
  );
}

function PaymentPlanSelector({
  registrationPackage,
  paymentPlan,
  onChange,
  hideLabel,
  intl,
}) {
  const [isOpen, setOpen] = React.useState(false);
  const selectedOption = paymentPlan.getPlan(
    registrationPackage.get('automation_option_uuid')
  );

  return (
    <div>
      {!hideLabel && (
        <div style={boldText}>
          {t('.payment_schedule', intl, __filenamespace)}
        </div>
      )}

      <FormControl fullWidth variant="standard">
        <InputLabel>
          {t('.plan_selector_hint_text', intl, __filenamespace)}
        </InputLabel>
        <Select
          sx={{ marginBottom: '10px' }}
          label={t('.plan_selector_hint_text', intl, __filenamespace)}
          value={registrationPackage.get('automation_option_uuid')}
          onChange={e => onChange(e.target.value)}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          {paymentPlan
            .plans()
            .sortBy(plan => plan.totalPrice())
            .map(plan => (
              <MenuItem
                key={`pricingAutomation-${paymentPlan.id}-option-${plan.get(
                  'uuid'
                )}`}
                value={plan.get('uuid')}
                label={plan.get('name')}
              >
                <PaymentPlanPrimaryText
                  intl={intl}
                  plan={plan}
                  showPrice={isOpen}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {selectedOption && (
        <div>
          <div style={{ marginBottom: 6, fontSize: 15 }}>
            {t('.n_payments', intl, __filenamespace, {
              n: selectedOption.payments.size,
            })}
          </div>
          <RecurringPaymentPlanSummary
            plan={selectedOption}
            style={{ marginBottom: 20 }}
            showTotal
          />
        </div>
      )}
    </div>
  );
}

export default injectIntl(PaymentPlanSelector);
