import NativeContact from 'contacts/index/records/NativeContact.js';
import File from 'shared/native/File';

/**
 * Converts a contact from the device to a NativeContact Record
 */
class NativeContactMapper {
  constructor(injector = {}) {
    this.fileManager = injector.fileManager || File;
    this.URL = injector.URL || URL;
  }

  /**
   * Maps to native contact
   * @param  {Object}  contact Contact from Capacitor plugin
   * @return {Promise}         Resolves to NativeContact record
   */
  async convert(contact) {
    const birthday = contact.birthday || null;
    const email = this.getEmail(contact);
    const firstName = contact.name?.given;
    const lastName = contact.name?.family;
    const phone = this.getPhoneNumber(contact);
    const address = this.getAddress(contact);

    let profileBlob = null;
    let profilePictureURL = null;
    const validContact = !!email && !!firstName && !!lastName;
    if (validContact) {
      profileBlob = await this.getProfileBlob(contact);
      if (profileBlob) {
        profilePictureURL = this.URL.createObjectURL(profileBlob);
      }
    }

    return new NativeContact({
      id: contact.contactId,
      date_of_birth: birthday,
      email,
      first_name: firstName,
      gender: null,
      last_name: lastName,
      phone,
      profileBlob,
      profilePictureURL,
      street_address: address.street,
      city: address.city,
      state: address.region,
      postal_code: address.postcode,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getEmail(contact) {
    const emails = contact.emails || [];
    const primaryEmail = emails.find(email => email.isPrimary);
    const firstEmail = emails[0];
    const email = primaryEmail || firstEmail;
    return email ? email.address : null;
  }

  // eslint-disable-next-line class-methods-use-this
  async getProfileBlob(contact) {
    const photoBase64 = contact.image?.base64String;
    if (!photoBase64) {
      return null;
    }

    try {
      const base64Response = await fetch(photoBase64);
      return await base64Response.blob();
    } catch (_) {
      return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAddress(contact) {
    const addresses = contact.postalAddresses || [];
    const primaryAddress = addresses.find(address => address.isPrimary);
    const firstAddress = addresses[0];
    return primaryAddress || firstAddress || {};
  }

  // eslint-disable-next-line class-methods-use-this
  getPhoneNumber(contact) {
    const phoneNumbers = contact.phones || [];
    const primaryPhone = phoneNumbers.find(phone => phone.isPrimary);
    const firstPhone = phoneNumbers[0];
    const phone = primaryPhone || firstPhone;
    return phone ? phone.number : null;
  }
}

export default NativeContactMapper;
