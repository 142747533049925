/**
 * used to determine type of Payment
 * TODO: Remove items from a list after transaction refactoring
 * @type {{}}
 */
const PaymentType = {
  ORDER: 'order',
  BALANCE: 'balance',
  PAYMENT_PLAN: 'payment_plan',
  PAY_ALL_BALANCES: 'pay_all_balances',
};

export default PaymentType;
