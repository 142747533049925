import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

function HeaderDesktop({ titleIds, intl, isFromMembersList }) {
  return (
    <div className="table__header">
      {titleIds.map(titleId => (
        <Grid
          key={titleId}
          container
          spacing={1}
          alignContent="center"
          justify="flex-start"
          className={
            isFromMembersList && titleId === 'member_name'
              ? 'client-card-user-info'
              : ''
          }
        >
          <Grid item>
            <Typography variant="body3" className="table__header-item">
              {t(`.${titleId}`, intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <div className="table__row-action" />
    </div>
  );
}

HeaderDesktop.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default HeaderDesktop;
