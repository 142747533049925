import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField, Confirmation } from '@upperhand/playmaker';
import { Map } from 'immutable';
import { t } from 'shared/utils/LocaleUtils.js';

import RetailVendorsActions from '../Actions';

function ModalEdit({ modalData, isOpen, intl }) {
  const dialogTitle = modalData.get('id')
    ? t('.title_edit_mode', intl, __filenamespace)
    : t('.title_create_mode', intl, __filenamespace);

  return (
    <Confirmation
      title={dialogTitle}
      open={isOpen}
      hasCheckbox={false}
      onCancel={RetailVendorsActions.closeDialog}
      confirmButtonLabel={t('.submit_button', intl, __filenamespace)}
      cancelButtonLabel={t('.cancel_button', intl, __filenamespace)}
      onConfirm={() =>
        modalData.get('id')
          ? RetailVendorsActions.update()
          : RetailVendorsActions.create()
      }
    >
      <TextField
        label={t('.label', intl, __filenamespace)}
        value={modalData.get('name')}
        name="name"
        onChange={(_, value) => RetailVendorsActions.changeModal('name', value)}
      />
    </Confirmation>
  );
}

ModalEdit.propTypes = {
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalData: PropTypes.instanceOf(Map).isRequired,
};

export default React.memo(ModalEdit);
