import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import Client from 'shared/records/Client.jsx';
import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import EditMembershipCancellationActions from './Actions.js';

class EditMembershipCancellationStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      toggleModal: EditMembershipCancellationActions.toggleModal,
      toggleConfirmationModal:
        EditMembershipCancellationActions.toggleConfirmationModal,
      setCancellationDate:
        EditMembershipCancellationActions.setCancellationDate,
      setCancellationReason:
        EditMembershipCancellationActions.setCancellationReason,
      updateCancellationDate:
        EditMembershipCancellationActions.updateCancellationDate,
      updateCancellationDateSuccess:
        EditMembershipCancellationActions.updateCancellationDateSuccess,
      updateCancellationDateError:
        EditMembershipCancellationActions.updateCancellationDateError,
    });
  }

  reset = () => {
    this.isLoading = false;
    this.isOpen = false;
    this.isConfirmationModalOpen = false;
    this.membershipId = null;
    this.membershipName = null;
    this.cancellationDate = null;
    this.cancellationReason = null;
    this.client = new Client();
  };

  toggleModal = (subscription = new MembershipSubscription()) => {
    const isFutureCancelling = subscription?.get('future_cancelling', false);

    this.setState({
      isOpen: !this.isOpen,
      membershipId: subscription?.get('id'),
      cancellationDate: isFutureCancelling
        ? subscription?.get('commitment_ends_at')
        : subscription?.get('expires_at'),
      cancellationReason: subscription?.get('cancellation_reason'),
    });

    if (!this.isOpen) {
      this.reset();
    }
  };

  toggleConfirmationModal = ([
    client = new Client(),
    subscription = new MembershipSubscription(),
  ]) => {
    this.setState({
      client,
      isConfirmationModalOpen: !this.isConfirmationModalOpen,
      membershipId: subscription?.get('id'),
      membershipName: subscription?.getIn(['membership', 'name']),
    });

    if (!this.isConfirmationModalOpen) {
      this.reset();
    }
  };

  setCancellationDate = cancellationDate => {
    this.setState({ cancellationDate });
  };

  setCancellationReason = cancellationReason => {
    this.setState({ cancellationReason });
  };

  updateCancellationDate = () => {
    const params = {
      cancellation_date: this.cancellationDate,
      cancellation_reason: this.cancellationReason,
      fields: ['membership', 'has_embed_product'],
    };

    if (this.isConfirmationModalOpen) {
      delete params.cancellation_reason;
      params.cancellation_date = null;
    }

    this.setState({ isLoading: true });

    uhApiClient.post({
      url: `membership_subscriptions/${this.membershipId}/adjust_cancellation`,
      data: JSON.stringify(params),
      success: EditMembershipCancellationActions.updateCancellationDateSuccess,
      error: EditMembershipCancellationActions.updateCancellationDateError,
    });
  };

  updateCancellationDateSuccess = () => {
    this.setState({ isLoading: false });

    if (this.isConfirmationModalOpen) {
      MessageWindowActions.addMessage.defer('Membership cancellation removed.');
    } else {
      MessageWindowActions.addMessage.defer(
        'Membership cancellation date updated.'
      );
    }

    this.reset();
  };

  updateCancellationDateError = (...args) => {
    this.setState({ isLoading: false });
    this.notifyError('Failed to update membership cancellation date', ...args);
  };
}

export default alt.createStore(
  EditMembershipCancellationStore,
  'EditMembershipCancellationStore'
);
