class BalanceDetailsDrawerActions {
  constructor() {
    this.generateActions(
      'closeDrawer',
      'closeAllDrawers',
      'fetchBalanceDetailsError',
      'fetchBalanceSuccess',
      'fetchBalanceDetailsSuccess',
      'fetchClientResponse',
      'fetchLocationResponse',
      'openDrawer',
      'sendReceipt',
      'payBalance',
      'toggleDotsMenu',
      'planDetailsFetchSuccess',
      'planDetailsFetchError'
    );
  }
}

export default alt.createActions(BalanceDetailsDrawerActions);
