import React, { memo, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import CloseoutHeader from 'containers/reports/dailyCloseoutReport/components/Header/CloseoutHeader.jsx';
import Table from 'containers/reports/dailyCloseoutReport/components/Table/Table.jsx';
import CalculatorDrawer from 'containers/reports/dailyCloseoutReport/components/Calculator/CalculatorDrawer.jsx';
import FiltersDrawer from 'containers/reports/dailyCloseoutReport/components/Filters/FiltersDrawer.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';

import CloseoutReportActions from 'containers/reports/dailyCloseoutReport/Actions';
import CloseoutReportStore from 'containers/reports/dailyCloseoutReport/Store';

import './styles.scss';

function DailyCloseOutReport({ closeoutReportStore }) {
  useEffect(() => {
    CloseoutReportActions.mounted();
  }, []);

  const {
    filters,
    appliedFilters,
    orders,
    statistics,
    pagination,
    filtersChanged,
    ordersLoading,
    filtersDrawerState,
    isDownloadingReport,
    calculatorDrawerState,
  } = closeoutReportStore;

  return (
    <div className="closeout-report">
      <CloseoutHeader
        filters={filters.merge(appliedFilters)}
        statistics={statistics}
        isDownloadingReport={isDownloadingReport}
        onChange={CloseoutReportActions.handleFilterChange}
        onCalculatorClick={() => CloseoutReportActions.openCalculatorDrawer()}
        onFilterClick={() => CloseoutReportActions.openFiltersDrawer()}
        onExportClick={() => CloseoutReportActions.downloadCsvReport()}
        onDeleteFilter={CloseoutReportActions.deleteFilter}
      />
      <Table
        loading={ordersLoading}
        orders={orders}
        pagination={pagination}
        onPageSelect={CloseoutReportActions.pageSelect}
      />
      <CalculatorDrawer
        open={calculatorDrawerState.get('open', false)}
        cashIn={calculatorDrawerState.get('cashIn', 0)}
        cashToday={calculatorDrawerState.get('cashToday', 0)}
        onClose={CloseoutReportActions.closeCalculatorDrawer}
        onFieldChange={CloseoutReportActions.handleCalculatorFieldChange}
      />
      <FiltersDrawer
        open={filtersDrawerState.get('open', false)}
        filtersChanged={filtersChanged}
        totalMin={filters.get('total_min', 0)}
        totalMax={filters.get('total_max', 0)}
        onFieldChange={CloseoutReportActions.handleFilterChange}
        onClose={CloseoutReportActions.closeFiltersDrawer}
        onApply={CloseoutReportActions.applyFilters}
      />
    </div>
  );
}

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      closeoutReportStore: CloseoutReportStore,
    },
  })
)(DailyCloseOutReport);
