export default alt.generateActions(
  'mounted',
  'alertDismissed',
  'clientReportLoaded',
  'clientReportError',
  'eventReportLoaded',
  'eventReportError',
  'revenueReportLoaded',
  'revenueReportError',
  'participantsReportLoaded',
  'participantsReportError',
  'announcementTextUpdated',
  'announcementTextSaved',
  'announcementTextCleared',
  'saveAnnouncementTextSuccess',
  'saveAnnouncementTextError'
);
