/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import ACHSelectField from 'containers/payments/components/fields/ACHSelectField.jsx';
import PaymentMethodUsageField from 'containers/payments/components/fields/PaymentMethodUsageField.jsx';

import { t } from 'shared/utils/LocaleUtils';

import PaymentActions from 'containers/payments/Actions';

const inlineStyles = {
  container: {
    sx: {
      paddingTop: '15px',

      '& .card-usage-field': {
        marginTop: 0,
      },
    },
  },
  field: {
    sx: {
      marginBottom: '15px',
    },
    InputLabelProps: {
      shrink: true,
      sx: {
        fontSize: '16px',
      },
    },
  },
};

function PaymentACHForm({
  ach,
  showBankSelector,
  hasAccess,
  achUsage,
  allowedPaymentMethods,
  currentPaymentMethod,
  paymentMethods,
  onChange,
}) {
  const intl = useIntl();
  const accountHolderError = ach.errors?.getErrors('account_holder_name');
  const accountNumberError = ach.errors?.getErrors('account_number');
  const routingNumberError = ach.errors?.getErrors('routing_number');
  const cityError = ach.errors?.getErrors('city');
  const streetError = ach.errors?.getErrors('street');
  const zipError = ach.errors?.getErrors('zip');

  const handleChange = e => {
    if (onChange) {
      onChange(e.target.name, e.target.value);
    } else {
      PaymentActions.updateNewCardForm(e.target.name, e.target.value);
    }
  };

  return (
    <Box className="ach-form" {...inlineStyles.container}>
      {showBankSelector && (
        <ACHSelectField
          allowedPaymentMethods={allowedPaymentMethods}
          currentPaymentMethod={currentPaymentMethod}
          paymentMethods={paymentMethods}
        />
      )}
      {currentPaymentMethod?.id === null && (
        <>
          <TextField
            fullWidth
            error={Boolean(accountHolderError)}
            helperText={accountHolderError}
            label={t('.account_holder_name', intl, __filenamespace)}
            placeholder={t('.account_holder_name', intl, __filenamespace)}
            name="account_holder_name"
            value={ach.account_holder_name}
            variant="standard"
            onChange={handleChange}
            {...inlineStyles.field}
          >
            <div id="accountHolderName" />
          </TextField>
          <TextField
            fullWidth
            error={Boolean(accountNumberError)}
            helperText={accountNumberError}
            label={t('.account_number', intl, __filenamespace)}
            placeholder={t('.account_number', intl, __filenamespace)}
            name="account_number"
            value={ach.account_number}
            variant="standard"
            onChange={handleChange}
            {...inlineStyles.field}
          />
          <TextField
            fullWidth
            error={Boolean(routingNumberError)}
            helperText={routingNumberError}
            label={t('.routing_number', intl, __filenamespace)}
            placeholder={t('.routing_number', intl, __filenamespace)}
            name="routing_number"
            value={ach.routing_number}
            variant="standard"
            onChange={handleChange}
            {...inlineStyles.field}
          />
          <TextField
            fullWidth
            error={Boolean(cityError)}
            helperText={cityError}
            label={t('.city', intl, __filenamespace)}
            placeholder={t('.city', intl, __filenamespace)}
            name="city"
            value={ach.city}
            variant="standard"
            onChange={handleChange}
            {...inlineStyles.field}
          />
          <TextField
            fullWidth
            error={Boolean(streetError)}
            helperText={streetError}
            label={t('.street', intl, __filenamespace)}
            placeholder={t('.street', intl, __filenamespace)}
            name="street"
            value={ach.street}
            variant="standard"
            onChange={handleChange}
            {...inlineStyles.field}
          />
          <TextField
            fullWidth
            error={Boolean(zipError)}
            helperText={zipError}
            label={t('.zip', intl, __filenamespace)}
            placeholder={t('.zip', intl, __filenamespace)}
            name="zip"
            value={ach.zip}
            variant="standard"
            onChange={handleChange}
            {...inlineStyles.field}
          />
        </>
      )}
      {hasAccess && !currentPaymentMethod?.id && (
        <PaymentMethodUsageField
          isAch
          cardUsage={achUsage}
          hasAccess={hasAccess}
        />
      )}
    </Box>
  );
}

export default PaymentACHForm;
