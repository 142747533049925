import React from 'react';
import { FormattedMessage } from 'react-intl';
import { red } from '@mui/material/colors';

import ProfileCard from 'user_management/shared/components/ProfileCard.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

class StaffCard extends React.Component {
  constructor(props) {
    super(props);
    this.availabilityText = this.availabilityText.bind(this);
    this.cardEdgeColor = this.cardEdgeColor.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.isStaffAvailable = this.isStaffAvailable.bind(this);
    this.isStaffUnavailable = this.isStaffUnavailable.bind(this);
    this.isStaffBooked = this.isStaffBooked.bind(this);
  }

  shouldComponentUpdate(nextProps, _nextState) {
    const { staffMember, errors } = this.props;

    return staffMember !== nextProps.staffMember || errors !== nextProps.errors;
  }

  handleRemove() {
    const { onRemove, staffMember } = this.props;

    onRemove(staffMember.id);
  }

  availabilityText() {
    let text = (
      <FormattedMessage id={messageId('.available', __filenamespace)} />
    );

    if (this.isStaffBooked()) {
      text = (
        <span style={{ color: red[500] }}>
          <FormattedMessage
            id={messageId('.booked_unavailable', __filenamespace)}
          />
        </span>
      );
    } else if (this.isStaffUnavailable()) {
      text = (
        <span style={{ color: red[500] }}>
          <FormattedMessage
            id={messageId('.conflict_unavailable', __filenamespace)}
          />
        </span>
      );
    }
    return text;
  }

  cardEdgeColor(id) {
    return this.isStaffAvailable(id) ? uhColors.green : red[500];
  }

  isStaffAvailable() {
    return !this.isStaffUnavailable() && !this.isStaffBooked();
  }

  isStaffUnavailable() {
    const { staffMember, errors } = this.props;
    const unavailableStaff = errors.get('unavailable_staff');

    return unavailableStaff && unavailableStaff.contains(staffMember.id);
  }

  isStaffBooked() {
    const { staffMember, errors } = this.props;
    const bookedStaff = errors.get('booked_staff');

    return bookedStaff && bookedStaff.contains(staffMember.id);
  }

  render() {
    const { staffMember } = this.props;

    return (
      <ProfileCard
        isDraggable
        style={{
          borderLeftStyle: 'solid',
          borderLeftWidth: '2px',
          borderLeftColor: this.cardEdgeColor(staffMember.id),
          cursor: 'move',
          padding: 12,
        }}
        client={staffMember}
        subtext={this.availabilityText()}
        onClearClick={this.handleRemove}
      />
    );
  }
}

export default StaffCard;
