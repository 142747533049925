import React from 'react';
import Button from '@mui/material/Button';

import CreditPassEditingActions from 'credit_passes/actions/CreditPassEditingActions.js';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { redirectTo } from 'shared/utils/RouteUtils';
import { t } from 'shared/utils/LocaleUtils.js';

const cancel = () =>
  redirectTo({
    path: `/customers/${currentCustomer().id}/credit_passes`,
  });

const styles = {
  root: {
    marginTop: 50,
  },
};

function ActionButtons({ intl, record, isSaving }) {
  return (
    <div style={styles.root}>
      <Button color="default" variant="contained" onClick={cancel}>
        {t('actions.cancel', intl)}
      </Button>
      <StateChangingButton
        inProgress={isSaving}
        label={t(record.id ? 'actions.save' : 'actions.create', intl)}
        labelInProgress={t('actions.saving', intl)}
        onClick={CreditPassEditingActions.save}
        style={{ marginLeft: 16 }}
      />
    </div>
  );
}

export default ActionButtons;
