import PropTypes from 'prop-types';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import localeMessages from 'locales/messages.js';

// moving local info off window and out of rails. for now hard code, in future have js figure it out.
// make sure to change the main html file(application.html.slim) //cdn.polyfill.io/v2/polyfill.min.js?features=Intl.~locale.en
// make sure to change the main html file(application.html.slim) //npmcdn.com/react-intl@2.8.0/locale-data/en.js
const locale = 'en-US';

function UhIntl({ children }) {
  const messages = localeMessages[locale] || localeMessages['en-US'];

  return (
    <IntlProvider locale={locale} messages={messages} textComponent="span">
      {children}
    </IntlProvider>
  );
}

UhIntl.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UhIntl;
