import { Record, Set } from 'immutable';
import { merge } from 'shared/utils/ObjectUtils.jsx';

export default class CreditPassPackage extends Record({
  id: null,
  client_ids: null,
  credit_pass_id: null,
  price: null,
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        client_ids: Set(obj.client_ids || []),
      })
    );
  }
}
