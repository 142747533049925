import { Record } from 'immutable';
import { translateWithValues } from 'shared/utils/LocaleUtils.js';

class TranslatableMessage extends Record({
  id: null,
  filename: undefined,
  values: undefined,
  prefix: null,
  suffix: null,
}) {
  toString({ intl, filename = this.filename, values = this.values }) {
    const { id } = this;

    let translated = translateWithValues({ id, intl, filename, values });

    if (this.prefix) {
      translated = `${this.prefix.toString({
        intl,
        filename,
        values,
      })}${translated}`;
    }

    if (this.suffix) {
      translated = `${translated}${this.suffix.toString({
        intl,
        filename,
        values,
      })}`;
    }

    return translated;
  }

  withPrefix(prefix) {
    return this.set('prefix', prefix);
  }

  withSuffix(suffix) {
    return this.set('suffix', suffix);
  }

  withTranslatablePrefix(prefix) {
    return this.set('prefix', new TranslatableMessage({ id: prefix }));
  }

  withTranslatableSuffix(suffix) {
    return this.set('suffix', new TranslatableMessage({ id: suffix }));
  }
}

export default TranslatableMessage;
