import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { ContentModal } from '@upperhand/playmaker';
import { Range } from 'immutable';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions';
import QuickpayModalStore from 'quickpay/stores/QuickpayModalStore';

const styles = {
  autoComplete: {
    '& .UH-MuiInputBase-root': {
      paddingRight: '30px',
    },
    '& .UH-MuiAutocomplete-endAdornment': {
      right: '7px',
    },
  },
  inputLabel: {
    textTransform: 'capitalize',
  },
  actionButtonsContainer: {
    marginTop: '15px',
    marginBottom: '5px',
  },
};

function AddonProductsModal({ intl, quickpayModalStore }) {
  const {
    addonModalOpen,
    retailProducts,
    selectedRetailProduct,
    selectedOptions,
    selectedQuantity,
    retailProductOptionTypes,
    optionsLoading,
    selectedVariant,
    productsLoading,
    hasMoreProducts,
    pagination,
  } = quickpayModalStore;

  const handleInputChange = value =>
    QuickpayModalActions.listRetailProducts({ searchString: value });

  const handleScroll = e => {
    const node = e.currentTarget;
    const atBottom = node.scrollTop + node.clientHeight === node.scrollHeight;

    if (atBottom && hasMoreProducts) {
      const productsPagination = pagination.get('retailProducts');

      QuickpayModalActions.listRetailProducts({
        searchString: productsPagination.get('searchString'),
        loadMore: true,
      });
    }
  };

  const optimizedFn = debounce(handleInputChange, 500);

  return (
    <ContentModal
      open={addonModalOpen}
      onAbort={QuickpayModalActions.toggleAddonProductModal}
      showActions={false}
      title={t('.title', intl, __filenamespace)}
    >
      <Stack spacing={2}>
        <Autocomplete
          clearOnEscape
          loading={productsLoading}
          clearOnBlur={false}
          options={retailProducts.toJS()}
          filterOptions={options => options}
          getOptionLabel={option => option.name}
          value={selectedRetailProduct}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          sx={styles.autoComplete}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <MenuItem {...props} key={option.id}>
              {option.name}
            </MenuItem>
          )}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder={t(
                '.product_selector_placeholder',
                intl,
                __filenamespace
              )}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {productsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              variant="standard"
            />
          )}
          ListboxProps={{
            onScroll: handleScroll,
          }}
          onChange={(_e, value) =>
            QuickpayModalActions.selectRetailProduct(value)
          }
          onInputChange={(e, _value, reason) => {
            if (reason === 'input') {
              optimizedFn(e.target.value);
            }
            if (reason === 'clear') {
              optimizedFn('');
            }
          }}
        />
        {optionsLoading && (
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Stack>
        )}
        {selectedRetailProduct && !optionsLoading && (
          <>
            {retailProductOptionTypes.size > 0 && (
              <>
                {retailProductOptionTypes.map(option => (
                  <FormControl fullWidth key={option.id} size="small">
                    <InputLabel sx={styles.inputLabel}>
                      {option.name}
                    </InputLabel>
                    <Select
                      label={option.name}
                      value={selectedOptions.get(option.id, '')}
                      MenuProps={{ disableEnforceFocus: true }}
                      onChange={e =>
                        QuickpayModalActions.selectOption(
                          option.id,
                          e.target.value
                        )
                      }
                    >
                      {option.option_values.map(v => (
                        <MenuItem key={v.id} value={v.id}>
                          {v.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}
              </>
            )}
            <FormControl fullWidth size="small">
              <InputLabel sx={styles.inputLabel}>
                {t('.qty', intl, __filenamespace)}
              </InputLabel>
              <Select
                label={t('.qty', intl, __filenamespace)}
                value={selectedQuantity || ''}
                MenuProps={{ disableEnforceFocus: true }}
                onChange={e => QuickpayModalActions.setQuantity(e.target.value)}
              >
                {Range(1, 31).map(i => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" justifyContent="space-between">
              <p>{t('.amount', intl, __filenamespace)}</p>
              <FormattedCurrency
                fromCents
                value={(selectedVariant?.price || 0) * selectedQuantity}
              />
            </Stack>
          </>
        )}
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        sx={styles.actionButtonsContainer}
      >
        <Button
          variant="contained"
          color="default"
          onClick={QuickpayModalActions.toggleAddonProductModal}
        >
          {t('.cancel_btn', intl, __filenamespace)}
        </Button>
        <Button
          disabled={!selectedVariant}
          variant="contained"
          color="secondary"
          onClick={QuickpayModalActions.addRetailProduct}
        >
          {t('.add_btn', intl, __filenamespace)}
        </Button>
      </Stack>
    </ContentModal>
  );
}

AddonProductsModal.propTypes = {
  intl: PropTypes.object,
  quickpayModalStore: PropTypes.object,
};

AddonProductsModal.defaultProps = {
  intl: {},
  quickpayModalStore: {},
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      quickpayModalStore: QuickpayModalStore,
    },
  })
)(AddonProductsModal);
