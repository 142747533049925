import { List } from 'immutable';
import LocationActions from 'shared/actions/LocationActions.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import Resource from 'shared/records/Resource.js';
import ResourceActions from 'resources/actions/ResourceActions.js';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = id => (id ? `resources/${id}` : 'resources');

class ResourceEditingStore extends UpperHandStore {
  constructor() {
    super();

    this.resource = new Resource();
    this.allowStaffPreference = false;
    this.linkLocation = false;

    this.availableStaff = List();
    this.selectedStaff = List();

    this.isSaving = false;

    this.bindListeners({
      onCreate: ResourceActions.CREATE_RESOURCE_CLICKED,
      onEdit: ResourceActions.EDIT_RESOURCE_CLICKED,

      updateName: ResourceActions.NAME_UPDATED,
      updateArea: ResourceActions.AREA_UPDATED,

      toggleAllowStaffPreference: ResourceActions.STAFF_PREFERENCE_TOGGLED,
      addStaffPreferrence: ResourceActions.STAFF_PREFERRENCE_ADDED,
      removeStaffPreferrence: ResourceActions.STAFF_PREFERRENCE_REMOVED,

      toggleLinkLocation: ResourceActions.LINK_LOCATION_TOGGLED,
      updateLocation: ResourceActions.LOCATION_UPDATED,
      handleLocationCreate: LocationActions.CREATE_OR_UPDATE_SUCCESS,

      createOrUpdate: ResourceActions.RESOURCE_SAVED,
      createOrUpdateSuccess: ResourceActions.CREATE_OR_UPDATE_SUCCESS,
      createOrUpdateError: ResourceActions.CREATE_OR_UPDATE_ERROR,

      setStaff: StaffActions.LIST_SUCCESS,
    });
  }

  onCreate() {
    this.resource = new Resource();
    this.allowStaffPreference = false;
    this.linkLocation = false;
    this.isSaving = false;

    this.setStaff();
  }

  onEdit(resource) {
    this.resource = resource;
    this.allowStaffPreference = !resource.preferring_staff_ids.isEmpty();
    this.linkLocation = !!resource.location_id;
    this.isSaving = false;

    this.setStaff();
  }

  updateName(newName) {
    this.resource = this.resource.set('name', newName);
  }

  updateArea(newArea) {
    this.resource = this.resource.set('square_feet', newArea);
  }

  updateLocation(locationId) {
    this.resource = this.resource.set('location_id', locationId);
  }

  addStaffPreferrence(staffId) {
    this.resource = this.resource.addPreferringStaff(staffId);

    const [index, staff] = this.availableStaff.findEntry(s => s.id === staffId);

    if (index >= 0) {
      this.availableStaff = this.availableStaff.remove(index);
      this.selectedStaff = this.selectedStaff.push(staff).sortBy(s => s.name());
    }
  }

  removeStaffPreferrence(staffId) {
    this.resource = this.resource.removePreferringStaff(staffId);

    const [index, staff] = this.selectedStaff.findEntry(s => s.id === staffId);

    if (index >= 0) {
      this.selectedStaff = this.selectedStaff.remove(index);
      this.availableStaff = this.availableStaff
        .push(staff)
        .sortBy(s => s.name());
    }
  }

  toggleAllowStaffPreference(value) {
    this.allowStaffPreference = value;
  }

  toggleLinkLocation(value) {
    this.linkLocation = value;
  }

  createOrUpdate() {
    this.resource = this.resource.validate();

    if (this.resource.isValid()) {
      this.isSaving = true;
      this.successMessage = this.resource.id
        ? new TranslatableMessage({ id: '.updated', filename: __filenamespace })
        : new TranslatableMessage({
            id: '.created',
            filename: __filenamespace,
          });

      const method = this.resource.id ? 'put' : 'post';
      return uhApiClient[method]({
        url: url(this.resource.id),
        data: this.payload(),
        success: ResourceActions.createOrUpdateSuccess,
        error: ResourceActions.createOrUpdateError,
      });
    }
  }

  createOrUpdateSuccess(data) {
    this.resource = new Resource(data);
    this.isSaving = false;

    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('Error creating or updating resource.', args);
  }

  setStaff() {
    this.waitFor(StaffStore);

    const partition = StaffStore.getState().allStaff.groupBy(s =>
      this.resource.preferring_staff_ids.has(s.id)
    );

    this.availableStaff = partition.get(false, List());
    this.selectedStaff = partition.get(true, List());
  }

  handleLocationCreate([_data, parentObject]) {
    if (parentObject === 'resource') {
      this.waitFor(LocationStore);

      const { location } = LocationStore.getState();

      this.resource = this.resource.merge({
        location_id: location.id,
        location,
      });
    }
  }

  payload() {
    const payload = this.resource.toServer();

    if (!this.allowStaffPreference) {
      payload.preferring_staff_ids = [];
    }

    if (!this.linkLocation) {
      payload.location_id = null;
    }

    return JSON.stringify({ attributes: payload });
  }
}

export default alt.createStore(ResourceEditingStore, 'ResourceEditingStore');
