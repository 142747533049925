import { createIntl } from 'react-intl';
import { isJumpStartSportsTheme } from 'shared/utils/ThemeUtils';

import localeMessages from 'locales/messages.js';

const intl = createIntl({ locale: 'en-US', messages: localeMessages['en-US'] });

export const eventSeasonKeys = [
  'shared.constants.eventSeasons.spring',
  'shared.constants.eventSeasons.summer',
  'shared.constants.eventSeasons.fall',
  'shared.constants.eventSeasons.winter',
];

if (isJumpStartSportsTheme()) {
  eventSeasonKeys.splice(3, 0, 'shared.constants.eventSeasons.late_fall');
}

const eventSeasons = eventSeasonKeys.map(key => ({
  label: intl.formatMessage({ id: key }),
  value: intl.formatMessage({ id: key }),
}));

export const eventSeasonsValues = eventSeasonKeys.map(key =>
  intl.formatMessage({ id: key })
);

export default eventSeasons;
