import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import LocationEditor from 'components/LocationEditor';
import ResourceActions from 'resources/actions/ResourceActions.js';
import { t } from 'shared/utils/LocaleUtils.js';

const onLinkLocationChange = e =>
  ResourceActions.linkLocationToggled(e.target.checked);

function LocationSelector({ resource, linkLocation, intl }) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={linkLocation} />}
          label={t('.link_location', intl, __filenamespace)}
          onChange={onLinkLocationChange}
        />
      </FormGroup>

      {linkLocation && (
        <LocationEditor
          disableEdit
          selectedLocationId={resource.location_id}
          placeholder={t('.choose_location', intl, __filenamespace)}
          label={t('.resource_location', intl, __filenamespace)}
          onChange={ResourceActions.locationUpdated}
          parentObject="resource"
        />
      )}
    </div>
  );
}

export default LocationSelector;
