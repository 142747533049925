/* eslint-disable no-unused-vars */
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import CancellationDialogModalActions from './CancellationDialogModalActions';

class CancellationDialogModalStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      closeModal: CancellationDialogModalActions.closeModal,
      openModal: CancellationDialogModalActions.openModal,
      setFutureDate: CancellationDialogModalActions.setFutureDate,
    });
  }

  reset() {
    this.isOpen = false;
    this.futureDate = '';
  }

  closeModal() {
    this.isOpen = false;
  }

  openModal(expireDate) {
    if (expireDate) {
      this.futureDate = expireDate;
      this.isOpen = true;
    }

    if (!expireDate) {
      MessageWindowActions.addMessage.defer(
        'Previous cancellation date is being loaded please try again.'
      );
    }
  }

  setFutureDate(startDate) {
    if (startDate !== '') {
      this.futureDate = new Date(startDate);
      const today = new Date();
      const currentHour = today.getHours();
      const currentMinutes = today.getMinutes();
      this.futureDate.setHours(currentHour);
      this.futureDate.setMinutes(currentMinutes);
    }

    if (startDate === '') {
      this.futureDate = startDate;
    }
  }
}

export default alt.createStore(
  CancellationDialogModalStore,
  'CancellationDialogModalStore'
);
