import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox } from '@upperhand/playmaker';
import moment from 'moment-timezone';
import { t } from 'shared/utils/LocaleUtils.js';

function ProRateToggle({ style, intl, proRateCheckHandler, order }) {
  const [showProRate, setShowProRate] = React.useState(false);
  React.useEffect(() => {
    let allMemberships = order?.order_items.every(item =>
      item?.isMembershipItem()
    );
    if (order?.order_items?.size === 1 && allMemberships) {
      const orderItem = order?.order_items.get('0');
      if (orderItem.orderable.membership_active_at !== null) {
        allMemberships = !moment().isBefore(
          orderItem.orderable.membership_active_at,
          'days'
        );
      }
      if (orderItem.orderable.membership_active_at === null) {
        allMemberships = true;
      }
    }
    setShowProRate(allMemberships);
  }, [order.order_items]);
  if (showProRate) {
    return (
      <div style={style}>
        <Checkbox
          label={t('.proRate', intl, __filenamespace)}
          onChange={(_, checked) => proRateCheckHandler(checked)}
        />
      </div>
    );
  }
  return '';
}

export default injectIntl(ProRateToggle);
