import { Record } from 'immutable';
import moment from 'moment-timezone';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

export const PAYMENT_STATUS = {
  COMPLETED: 'completed',
  OUTSTANDING: 'outstanding',
  SCHEDULED: 'scheduled',
  CANCELLED: 'cancelled',
};

export default class ScheduledPayment extends Record({
  id: null,
  paymentPlanId: null,
  clientId: null,
  customerId: null,
  amount: 0,
  paidAmount: 0,
  unpaidAmount: 0,
  dueDate: null,
  paymentStatus: null,
  effectiveAt: null,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);
    super(parsedObject);
  }

  get isCompleted() {
    return !this.isCancelled && this.unpaidAmount === 0;
  }

  get isOutstanding() {
    return (
      this.unpaidAmount > 0 &&
      this.dueDate &&
      moment(this.dueDate).isSameOrBefore(moment())
    );
  }

  get isScheduled() {
    return this.dueDate && moment(this.dueDate).isAfter(moment());
  }

  get isCancelled() {
    return this.paymentStatus === PAYMENT_STATUS.CANCELLED;
  }

  get status() {
    if (this.isCompleted) return PAYMENT_STATUS.COMPLETED;
    if (this.isOutstanding) return PAYMENT_STATUS.OUTSTANDING;
    if (this.isCancelled) return PAYMENT_STATUS.CANCELLED;
    return PAYMENT_STATUS.SCHEDULED;
  }
}
