import React, { memo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import AmusementCreditRedemption from 'shared/records/AmusementCreditRedemption';

import './styles.scss';

function HistoryItem({ swing }) {
  const intl = useIntl();
  const {
    cage_id: cageId,
    credit_types: creditTypes,
    created_at: createdAt,
  } = swing;

  return (
    <div className="swing-history-item">
      <Grid container spacing={0}>
        <Grid item xs={7}>
          <Typography variant="caption">
            {t('.qr', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" className="swing-history-item__cage-id">
            {cageId}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="swing-history-item__credit-types">
            {creditTypes.join(' / ')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="swing-history-item__created-at">
            {moment(createdAt).format('MM/DD/YYYY • h:mma')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

HistoryItem.propTypes = {
  swing: PropTypes.instanceOf(AmusementCreditRedemption).isRequired,
};

export default memo(HistoryItem);
