import * as React from 'react';

import StandardCalendar from 'calendar/components/views/shared/_StandardCalendar.jsx';
import Columns from 'calendar/components/views/week/_Columns.jsx';

const styles = {
  StickyPlaceholder: {
    zIndex: 3,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  StickyInnerDiv: {
    position: 'absolute',
    width: '50px',
    height: '42px',
    borderRight: '1px solid #e3e3e3',
    borderBottom: '2px solid #e3e3e3',
    backgroundColor: 'white',
  },
  Columns: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
  },
};

function Week({
  allStaffMap,
  date,
  clientsMap,
  eventsMap,
  filteredEventTimesByWeekday,
  loading,
  membershipsMap,
  resourcesMap,
  showPaid,
  showUnpaid,
  showAllStaff,
  staffIds,
  sessions,
}) {
  return (
    <StandardCalendar loading={loading}>
      <div style={styles.StickyPlaceholder}>
        <div style={styles.StickyInnerDiv} />
      </div>
      <div style={styles.Columns}>
        <Columns
          allStaffMap={allStaffMap}
          date={date}
          clientsMap={clientsMap}
          eventsMap={eventsMap}
          filteredEventTimesByWeekday={filteredEventTimesByWeekday}
          membershipsMap={membershipsMap}
          resourcesMap={resourcesMap}
          showPaid={showPaid}
          showUnpaid={showUnpaid}
          showAllStaff={showAllStaff}
          staffIds={staffIds}
          sessions={sessions}
        />
      </div>
    </StandardCalendar>
  );
}

export default Week;
