import { SessionSource } from 'sources';
import { UpperHandStore } from 'dataStores';

import CancelSessionActions from './Actions';

export const creditOperations = {
  REFUND_CREDIT: 'refund_credit',
  REVOKE_CREDIT: 'revoke_credit',
};

class CancelSessionStore extends UpperHandStore {
  constructor() {
    super();
    this.bind();
    this.reset();
  }

  bind() {
    this.bindListeners({
      initCancellation: CancelSessionActions.initCancellation,
      reset: CancelSessionActions.reset,
      sessionCanceled: CancelSessionActions.sessionCanceled,
      sessionCanceledSuccess: CancelSessionActions.sessionCanceledSuccess,
      sessionCanceledError: CancelSessionActions.sessionCanceledError,
      setCreditOperation: CancelSessionActions.setCreditOperation,
      setWaive: CancelSessionActions.setWaive,
      toggleNotice: CancelSessionActions.toggleNotice,
      updateNote: CancelSessionActions.updateNote,
    });
  }

  reset() {
    this.sessionId = null;
    this.isCancelling = false;

    this.addNotice = false;
    this.cancellationNotice = '';

    this.creditOperation = undefined;
    this.waiveBalance = false;
  }

  initCancellation(sessionId) {
    this.sessionId = sessionId;
    this.isCancelling = true;
  }

  sessionCanceled() {
    SessionSource.cancel({
      id: this.sessionId,
      params: this.params(),
      success: CancelSessionActions.sessionCanceledSuccess,
      error: CancelSessionActions.sessionCanceledError,
    });
  }

  sessionCanceledSuccess(_session) {
    this.reset();
  }

  sessionCanceledError(...args) {
    this.reset();
    this.notifyError('error while cancelling Session', args);
  }

  setCreditOperation(operation) {
    this.creditOperation = operation;
  }

  toggleNotice() {
    this.addNotice = !this.addNotice;
  }

  updateNote(note) {
    this.cancellationNotice = note;
  }

  setWaive(checked) {
    this.waiveBalance = checked;
  }

  params() {
    const params = {};

    if (this.addNotice && this.cancellationNotice !== '') {
      params.cancellation_notice = this.cancellationNotice;
    }

    if (this.creditOperation === creditOperations.REVOKE_CREDIT) {
      params.waive_balance = this.waiveBalance;
      params.revoke_credit = true;
    }

    return params;
  }
}

export default alt.createStore(CancelSessionStore, 'CancelSessionStore');
