import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `team_events/${id}`,
    data: params,
    success: onSuccess(data => data, success),
    error,
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'team_events',
    data: params,
    success: onSuccess(data => data, success),
    error,
  });
};

export const postTeam = ({ params, success, error }) => {
  uhApiClient.post({
    url: 'team_events',
    data: params,
    success: onSuccess(data => data, success),
    error,
  });
};

export const updateTeam = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `team_events/${id}`,
    data: params,
    success: onSuccess(data => data, success),
    error,
  });
};

export const cloneTeam = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `events/${id}/clone`,
    data: params,
    success: onSuccess(data => data, success),
    error,
  });
};

export const removeClient = ({ params, success, error }) => {
  uhApiClient.post({
    url: 'team_events/remove_client',
    data: JSON.stringify(params),
    success: onSuccess(data => data, success),
    error,
  });
};
