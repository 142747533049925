import * as React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { compose } from 'shared/utils/SharedUtils';
import LocationActions from 'shared/actions/LocationActions.jsx';
import StateSelector from 'shared/components/StateSelector.jsx';
import { TextField, Dropdown } from '@upperhand/playmaker';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    '& > div': {
      marginBottom: '16px',
    },
  },
  stateContainer: {
    display: 'flex',
    '& > div:first-child': {
      marginRight: '8px',
      width: 120,
    },
  },
};

const LocationFormFields = injectIntl(
  ({ classes, intl, addressTypes, location, errors, style }) => (
    <div className={classes.root} style={style}>
      <TextField
        name="name"
        label={t('.location_name', intl, __filenamespace)}
        fullWidth
        placeholder={t('.location_name', intl, __filenamespace)}
        errorText={errors.get('name', []).join(' ')}
        onChange={(e, value) => LocationActions.updateStore(['name'], value)}
        value={location.name}
      />
      <Dropdown
        label={t('.address_type', intl, __filenamespace)}
        fullWidth
        placeholder={t('actions.select', intl)}
        errorText={errors.getIn(['address', 'address_type'], []).join(' ')}
        value={location.getIn(['address', 'address_type', 'id'])}
        onChange={e =>
          LocationActions.updateStore(
            ['address', 'address_type', 'id'],
            e.target.value
          )
        }
        items={addressTypes
          .map(type => ({
            value: type.id,
            label: type.name,
          }))
          .toArray()}
      />
      <TextField
        name="address[line_1]"
        label={t('.street_address', intl, __filenamespace)}
        fullWidth
        placeholder={t('.street_address', intl, __filenamespace)}
        errorText={errors.getIn(['address', 'line_1'], []).join(' ')}
        onChange={(e, value) =>
          LocationActions.updateStore(['address', 'line_1'], value)
        }
        value={location.getIn(['address', 'line_1'])}
      />
      <TextField
        name="address[line_2]"
        fullWidth
        placeholder={t('.address_two', intl, __filenamespace)}
        errorText={errors.getIn(['address', 'line_2'], []).join(' ')}
        onChange={(e, value) =>
          LocationActions.updateStore(['address', 'line_2'], value)
        }
        value={location.getIn(['address', 'line_2'])}
      />
      <TextField
        name="address[city]"
        label={t('.city', intl, __filenamespace)}
        fullWidth
        placeholder={t('.city', intl, __filenamespace)}
        errorText={errors.getIn(['address', 'city'], []).join(' ')}
        onChange={(e, value) =>
          LocationActions.updateStore(['address', 'city'], value)
        }
        value={location.getIn(['address', 'city'])}
      />
      <div className={classes.stateContainer}>
        <StateSelector
          label={t('.state', intl, __filenamespace)}
          placeholder={t('actions.select', intl)}
          useAbbreviations
          errorText={errors.getIn(['address', 'state'], []).join(' ')}
          value={location.getIn(['address', 'state'])}
          onChange={(e, value) =>
            LocationActions.updateStore(['address', 'state'], value)
          }
        />
        <TextField
          name="address[postal_code]"
          label={t('.zipcode', intl, __filenamespace)}
          placeholder={t('.zeros', intl, __filenamespace)}
          fullWidth
          maxLength={5}
          errorText={errors.getIn(['address', 'postal_code'], []).join(' ')}
          value={location.getIn(['address', 'postal_code'])}
          onChange={(e, value) =>
            LocationActions.updateStore(['address', 'postal_code'], value)
          }
        />
      </div>
    </div>
  )
);

export default compose(withStyles(styles), injectIntl)(LocationFormFields);
