import Native from 'shared/native/Native';
import LifeCycle from 'shared/native/LifeCycle';
import Contacts from 'shared/native/Contacts';
import File from 'shared/native/File';
import Browser from 'shared/native/Browser';
import BiometricAuthenticator from 'shared/native/BiometricAuthenticator';
import SplashScreen from 'shared/native/SplashScreen';
import NativeUpdater from 'shared/native/NativeUpdater';
import CordovaEndOfLife from 'shared/native/CordovaEndOfLife';

function registerPlugins() {
  Native.register('BiometricAuthenticator', BiometricAuthenticator);
  Native.register('Browser', Browser);
  Native.register('Contacts', Contacts);
  Native.register('File', File);
  Native.register('LifeCycle', LifeCycle);
  Native.register('SplashScreen', SplashScreen);
  Native.register('NativeUpdater', NativeUpdater);
}

async function initializeSplashScreen() {
  const splash = Native.resolve('SplashScreen');
  const isAvailable = await splash.isAvailable();
  if (isAvailable) {
    await splash.ready();
    await splash.hide();
  }
  return true;
}

async function initializeNativeUpdater() {
  const updater = Native.resolve('NativeUpdater');
  const isAvailable = await updater.isAvailable();
  if (isAvailable) {
    await updater.initializeNativeUpdates();
  }
  return true;
}

async function initializePlugins() {
  await initializeNativeUpdater();
  initializeSplashScreen();
}

CordovaEndOfLife.initialize();
registerPlugins();
initializePlugins();

export default Native;
