class CloseoutReportActions {
  constructor() {
    this.generateActions(
      'mounted',
      'applyFilters',
      'deleteFilter',
      'handleFilterChange',
      'handleCalculatorFieldChange',
      'pageSelect',
      'openCalculatorDrawer',
      'closeCalculatorDrawer',
      'openFiltersDrawer',
      'closeFiltersDrawer',
      'listOrders',
      'listOrdersSuccess',
      'listOrdersError',
      'fetchOrdersStatisticsSuccess',
      'fetchOrdersStatisticsError',
      'fetchEntriesStatisticsSuccess',
      'fetchEntriesStatisticsError',
      'downloadCsvReport',
      'downloadCsvReportSuccess',
      'downloadCsvReportError'
    );
  }
}

export default alt.createActions(CloseoutReportActions);
