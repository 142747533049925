export const currentCustomer = () => window.customer || {};

export const enabledCustomerFeatures = (features = []) =>
  features.every(feature => window.customer.features[feature]);

export const enabledCustomerPreferences = (
  preferences = [],
  subPreferences = []
) =>
  preferences.every(preference => {
    if (subPreferences.length) {
      return subPreferences.some(
        subPreference => window.customer.preferences[preference][subPreference]
      );
    }

    return window.customer.preferences[preference];
  });
