import * as React from 'react';
import { useParams, useLocation, useMatch } from 'react-router-dom';
import history from 'routes/History';

import { getAllUrlParameters } from 'shared/utils/UrlParameters.js';
/** This component should be used by routes for compoents to get params from the Url
 * this way no component has to know anything about url's allowing them to be reused more places.
 * this will also allow for strongly typed props on the components.
 */
function Page({ children }) {
  const params = useParams();
  const location = useLocation();
  const { pathname } = location;
  const match = useMatch({ path: pathname });

  // Take note, that parameters like key and ref can't be passed as props and have to be omitted
  const urlParams = getAllUrlParameters().toJS();

  const Kids = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...params,
      ...urlParams,
      ...{ location },
      ...{ match },
      ...{ history },
      ref: undefined,
      key: undefined,
    })
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{Kids}</>;
}

export default Page;
