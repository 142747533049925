/* eslint-disable no-unused-vars */
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import {
  BalancesSource,
  OrderSource,
  TransactionLedgerSource,
  CostOfGoods,
  NoShow,
} from 'sources';
import moment from 'moment-timezone';
import { downloadFile } from 'shared/utils/SharedUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ExportModalActions from './Actions';

export const POSSIBLE_REPORTS = {
  '.placeholder': {
    function: () =>
      MessageWindowActions.addMessage.defer('Must select a report to export.'),
  },
  '.payment_allocations_report': {
    filename: 'payment_allocations.csv',
    function: TransactionLedgerSource.downloadPaymentAllocations,
  },
  '.ledger_report': {
    filename: 'ledger_entries.csv',
    function: TransactionLedgerSource.downloadCsv,
  },
  '.order_report': {
    filename: 'order_list.csv',
    function: OrderSource.downloadCsv,
  },
  '.balance_report': {
    filename: 'balance_list.csv',
    function: BalancesSource.downloadCsv,
  },
  '.cost_of_goods': {
    filename: 'cost_of_goods.csv',
    function: CostOfGoods.downloadCsv,
  },
  '.no_show': {
    filename: 'no_show.csv',
    function: NoShow.downloadCsv,
  },
};
class ExportModalStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      closeModal: ExportModalActions.closeModal,
      openModal: ExportModalActions.openModal,
      setStartDate: ExportModalActions.setStartDate,
      setEndDate: ExportModalActions.setEndDate,
      download: ExportModalActions.download,
      selectReport: ExportModalActions.selectReport,
      downloadSuccess: ExportModalActions.downloadSuccess,
      downloadError: ExportModalActions.downloadError,
    });
  }

  // This is a dangerous function because if a download is currently
  // ongoing, this will allow starting a new download which can change
  // the downloaded filename.
  reset() {
    this.isOpen = false;

    this.selectedReport = '.placeholder';
    this.startDate = moment().endOf('day'); // set to end of day so that we do not jump days
    // in createParams() when we change value
    this.endDate = moment().endOf('day');

    this.isLoadingReport = false;
  }

  createParams() {
    const params = {};

    this.startDate = moment
      .tz(this.startDate, currentCustomer().tz_name)
      .startOf('day');
    this.endDate = moment
      .tz(this.endDate, currentCustomer().tz_name)
      .endOf('day');

    if (this.selectedReport === '.order_report') {
      // When Order Report
      params.status = 'completed';
      if (this.startDate) params.ordered_at_min = this.startDate.toISOString();

      if (this.endDate) params.ordered_at_max = this.endDate.toISOString();
    } else {
      if (this.startDate)
        params.effective_at_min = this.startDate.toISOString();

      if (this.endDate) params.effective_at_max = this.endDate.toISOString();
    }

    return params;
  }

  closeModal() {
    this.isOpen = false;
  }

  openModal() {
    this.isOpen = true;
  }

  selectReport(value) {
    this.selectedReport = value;
  }

  setStartDate(startDate) {
    this.startDate = startDate.endOf('day');
  }

  setEndDate(endDate) {
    this.endDate = endDate.endOf('day');
  }

  download() {
    if (!this.startDate && !this.endDate) {
      MessageWindowActions.addMessage.defer(
        'Must set a start date or end date.'
      );
      return;
    }

    if (this.isLoadingReport) return;

    if (this.selectedReport !== '.placeholder') this.isLoadingReport = true;

    POSSIBLE_REPORTS[this.selectedReport].function({
      params: this.createParams(),
      success: ExportModalActions.downloadSuccess,
      error: ExportModalActions.downloadError,
    });
  }

  downloadSuccess(data) {
    downloadFile({
      data,
      fileName: POSSIBLE_REPORTS[this.selectedReport].filename,
    });
    this.isLoadingReport = false;

    MessageWindowActions.addMessage.defer('Export success.');

    // Reset the date pickers in case that is nice.
    this.startDate = moment().startOf('day');
    this.endDate = moment().startOf('day');
  }

  downloadError(...args) {
    this.notifyError('error downloading report', args);
  }
}

export default alt.createStore(ExportModalStore, 'ExportModalStore');
