import * as React from 'react';
import { injectIntl } from 'react-intl';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  row: {
    padding: '5px 0 5px 25px',
    fontWeight: 'bold',
  },
};

const SessionRow = injectIntl(({ price, labelKey, intl }) => (
  <FlexBoxJustify style={styles.row}>
    <div>{t(labelKey, intl, __filenamespace)}</div>
    <FormattedCurrency value={price} fromCents />
  </FlexBoxJustify>
));

function SessionList({ registrations, fullPrice, singleSessionPrice }) {
  return (
    <div style={{ margin: '20px 0px' }}>
      {registrations.map(registration => (
        <SessionRow
          price={
            registration.isSingleSession() ? singleSessionPrice : fullPrice
          }
          labelKey={
            registration.isSingleSession() ? '.single_session' : '.all_days'
          }
        />
      ))}
    </div>
  );
}

export default SessionList;
