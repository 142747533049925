import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  NoEventsOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    zIndex: '0',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  NoEventsText: {
    position: 'relative',
    top: 'calc(50vh - 48px - (42px + 42px + 42px) * 0.5)',
  },
};

function NoEventsOverlay() {
  return (
    <div style={styles.NoEventsOverlay}>
      <h1 style={styles.NoEventsText}>
        <FormattedMessage id={messageId('.no_events', __filenamespace)} />
      </h1>
    </div>
  );
}

export default NoEventsOverlay;
