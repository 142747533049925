import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer, Menu } from '@upperhand/playmaker';

import PayAllBalances from 'containers/reports/payAllBalances/PayAllBalances.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { isLoggedIn, currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { ClientDataStore, BalancesDataStore } from 'dataStores';

import PayAllBalancesActions from 'containers/reports/payAllBalances/Actions';
import BalanceListDrawerActions from './Actions';
import BalanceListDrawerStore from './Store.js';

import Content from './components/Content.jsx';

import './styles.scss';

function BalanceListDrawer({
  balancesDataStore: { balances },
  balanceListDrawerStore: {
    balanceIds,
    clientId,
    isOpen,
    isLoadingClient,
    isLoadingStatistics,
    isLoading,
    totalCount,
    statistics,
  },
  clientDataStore: { clients },
  intl,
}) {
  const [anchor, setAnchor] = React.useState(null);
  const outstandingBalance =
    Math.abs(statistics.get('outstanding_balance')) || 0;
  const isStaff = currentUser().isStaff();
  const actions =
    outstandingBalance && isStaff
      ? [{ icon: 'threeDots', onClick: e => setAnchor(e.currentTarget) }]
      : undefined;

  return (
    <Drawer
      classes={{
        root: 'balance-list-drawer',
      }}
      open={isOpen}
      onClose={BalanceListDrawerActions.closeDrawer}
      actions={actions}
      headerType="light"
      title={t('.title', intl, __filenamespace)}
      content={
        isLoggedIn() ? (
          <>
            <Content
              balanceIds={balanceIds}
              balances={balances}
              intl={intl}
              isLoading={isLoading || isLoadingStatistics}
              isLoadingDependencies={isLoadingClient}
              client={clientId > 0 && clients.get(clientId)}
              totalCount={totalCount}
              statistics={statistics}
            />
            {isStaff && (
              <Menu
                anchor={anchor}
                items={[
                  {
                    label: 'Pay Balance',
                    onClick: () => PayAllBalancesActions.open({ clientId }),
                  },
                ]}
                onClose={() => setAnchor(null)}
              />
            )}
            <PayAllBalances />
          </>
        ) : (
          <SignInPrompt />
        )
      }
    />
  );
}

BalanceListDrawer.propTypes = {
  balancesDataStore: PropTypes.object,
  balanceListDrawerStore: PropTypes.object,
  clientDataStore: PropTypes.object,
};

BalanceListDrawer.defaultProps = {
  balancesDataStore: {},
  balanceListDrawerStore: {},
  clientDataStore: {},
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      balancesDataStore: BalancesDataStore,
      balanceListDrawerStore: BalanceListDrawerStore,
      clientDataStore: ClientDataStore,
    },
  })
)(BalanceListDrawer);
