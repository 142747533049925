import * as React from 'react';

import SideNavMenuItem from 'side_nav/_SideNavMenuItem.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

function HelpItem() {
  return (
    <SideNavMenuItem
      item={{
        icon: 'help',
        title: messageId('.help', __filenamespace),
        selected: false,
        subitems: [
          {
            title: messageId('.support', __filenamespace),
            link: currentCustomer().helpdesk_url,
            selected: false,
            target: '_blank',
            useHref: true,
          },
          {
            title: messageId('.privacy_policy', __filenamespace),
            link: 'https://www.upperhand.com/privacy-and-cookie-policy/',
            selected: false,
            target: '_blank',
            useHref: true,
          },
        ],
      }}
    />
  );
}

export default HelpItem;
