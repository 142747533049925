import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { TransactionLedgerActions } from 'sources';

class TransactionsLedgerDataStore extends UpperHandStore {
  constructor() {
    super();

    this.transactionsLedger = Map();

    this.bindListeners({
      listSuccess: TransactionLedgerActions.listSuccess,
      listError: TransactionLedgerActions.listError,
    });
  }

  listSuccess({ records }) {
    const ids = records.groupBy(b => b.entryId).map(b => b.first());

    this.transactionsLedger = this.transactionsLedger.merge(ids);
  }

  listError(...args) {
    this.notifyError('error listing balances', args);
  }
}

export default alt.createStore(
  TransactionsLedgerDataStore,
  'TransactionsLedgerDataStore'
);
