import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import DiscountValue from 'shared/components/DiscountValue.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  containerStyle: { marginBottom: 5, lineHeight: 1.4 },
};

function GenericDiscount({ discount, messageKey, children }) {
  return (
    <div style={styles.containerStyle}>
      <div style={{ fontWeight: 'bold' }}>
        <FormattedMessage
          id={messageId(messageKey, __filenamespace)}
          values={{ value: <DiscountValue discount={discount} /> }}
        />
      </div>
      {children}
    </div>
  );
}

GenericDiscount.propTypes = {
  discount: PropTypes.object.isRequired,
  messageKey: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default injectIntl(GenericDiscount);
