import { List, Map } from 'immutable';
import Membership from 'shared/records/Membership.jsx';
import MembershipSubscriptionPackage from 'shared/records/MembershipSubscriptionPackage.jsx';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCartStore from 'point_of_sale/stores/POSCartStore.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSMembershipActions from 'point_of_sale/actions/POSMembershipActions.jsx';
import POSMembershipRegistrationActions from 'point_of_sale/actions/POSMembershipRegistrationActions.jsx';
import POSMembershipStore from 'point_of_sale/stores/POSMembershipStore.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSStore from 'point_of_sale/stores/POSStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import FieldErrors from 'shared/records/FieldErrors.jsx';

class POSMembershipRegistrationStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      selectMembership: [
        POSProductListActions.MEMBERSHIP_SELECTED,
        POSMembershipActions.FETCH_SUCCESS,
      ],

      setTiers: POSMembershipActions.LIST_TIERS_SUCCESS,

      addProfile: [
        POSMembershipRegistrationActions.PROFILE_SELECTED,
        POSMembershipRegistrationActions.PROFILE_CREATED,
      ],

      removeProfile: POSMembershipRegistrationActions.PROFILE_REMOVED,
      getExistingDataFromCart: POSCartActions.FETCH_SUCCESS,
      setManagedProfiles: POSActions.MANAGED_PROFILES_LIST_SUCCESS,
      embedCardChange: POSMembershipRegistrationActions.EMBED_CARD_CHANGE,
      membershipTierSelected:
        POSMembershipRegistrationActions.membershipTierSelected,
      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCartActions.ADD_SUCCESS,
        POSCartActions.UPDATE_SUCCESS,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
        POSMembershipRegistrationActions.CANCEL_CLICKED,
        POSMembershipRegistrationActions.VIEW_IN_CART_CLICKED,
      ],
      validate: POSMembershipRegistrationActions.VALIDATE,
    });
  }

  reset() {
    this.embedErrors = new FieldErrors();
    this.membership = new Membership();
    this.subscriptionPackage = new MembershipSubscriptionPackage();
    this.packageIsValid = false;
    this.changed = false;
    this.client = null;
    this.managedProfiles = List();

    this.allProfiles = List();
    this.availableProfiles = List();
    this.selectedProfiles = List();

    this.cartChecked = false;
    this.orderItemId = null;
    this.cartMembershipMap = Map();

    this.membershipTiers = List();
  }

  selectMembership() {
    this.waitFor(POSStore);
    this.waitFor(POSMembershipStore);

    const posStoreState = POSStore.getState();
    const { membership } = POSMembershipStore.getState();

    this.membership = membership;
    this.client = posStoreState.selectedClient;
    this.managedProfiles = posStoreState.managedProfiles;

    this.getExistingDataFromCart();

    if (!this.subscriptionPackage.id) {
      this.subscriptionPackage = this.subscriptionPackage.merge({
        membership_id: this.membership.id,
        buyer_id: this.client.id,
      });

      this.setAvailableProfiles();
    }
  }

  setTiers() {
    this.waitFor(POSMembershipStore);

    const { membershipTiers } = POSMembershipStore.getState();

    this.membershipTiers = membershipTiers;
  }

  addProfile(customerUserId) {
    this.subscriptionPackage =
      this.subscriptionPackage.addCustomerUser(customerUserId);
    this.changed = true;

    this.setAvailableProfiles();
    this.validatePackage();
  }

  removeProfile(customerUserId) {
    this.subscriptionPackage =
      this.subscriptionPackage.removeCustomerUser(customerUserId);
    this.subscriptionPackage = this.subscriptionPackage.deleteIn([
      'embed_card_ids',
      customerUserId.toString(),
    ]);
    this.changed = true;

    this.setAvailableProfiles();
    this.validatePackage();
  }

  embedCardChange({ customerId, embedCardId }) {
    this.embedErrors = this.embedErrors.clear();
    // eslint-disable-next-line no-useless-escape
    const matchEmbedCard = embedCardId?.match(/\;(.*)\?/)?.pop();

    this.changed = true;
    this.subscriptionPackage = this.subscriptionPackage.setIn(
      ['embed_card_ids', customerId.toString()],
      matchEmbedCard || embedCardId
    );
  }

  membershipTierSelected(tierId) {
    this.subscriptionPackage = this.subscriptionPackage.set(
      'membership_tier_id',
      tierId
    );
    this.changed = true;
    this.validatePackage();
  }

  validate() {
    this.embedErrors = this.embedErrors.clear();

    const embedCardIds = this.subscriptionPackage.get('embed_card_ids');

    if (embedCardIds.size > 0) {
      embedCardIds.forEach(card => {
        if (card && card.length !== 19) {
          this.embedErrors = this.embedErrors.add(
            `${card}`,
            'shared._SelectedProfileList.embed_input_error'
          );
        }
      });
    }
  }

  setAvailableProfiles() {
    this.allProfiles = this.managedProfiles
      .push(this.client)
      .filter(c => c && c.type === 'Client');

    this.selectedProfiles = this.allProfiles.filter(profile =>
      this.subscriptionPackage.hasCustomerUser(profile)
    );

    this.availableProfiles = this.allProfiles.filterNot(
      profile =>
        profile.active_membership_id ||
        profile.archived ||
        this.subscriptionPackage.hasCustomerUser(profile)
    );
  }

  setManagedProfiles() {
    this.waitFor(POSStore);

    this.managedProfiles = POSStore.getState().managedProfiles;

    if (this.membership.id) {
      this.setAvailableProfiles();
    }
  }

  getExistingDataFromCart() {
    this.waitFor(POSCartStore);

    const { cart, cartLoaded } = POSCartStore.getState();

    if (!cartLoaded) {
      return;
    }

    if (this.membership.id && !this.cartChecked) {
      const cartItem =
        cart &&
        cart.order_items &&
        cart.order_items.find(
          oi => oi.getIn(['orderable', 'membership_id']) === this.membership.id
        );

      if (cartItem) {
        this.subscriptionPackage = cartItem.orderable;
        this.subscriptionPackage = this.subscriptionPackage.set(
          'embed_card_ids',
          Map(cartItem.orderable.embed_card_ids)
        );
        this.orderItemId = cartItem.id;
        this.setAvailableProfiles();
      }

      this.cartChecked = true;

      this.validatePackage();
    }

    this.cartMembershipMap = cart.customerUserMembershipMap();
  }

  validatePackage() {
    const isTieredMembership = this.membership.get('tiered', false);

    if (!isTieredMembership) {
      this.packageIsValid = this.subscriptionPackage.customer_user_ids.size > 0;
    } else {
      this.packageIsValid =
        this.subscriptionPackage.customer_user_ids.size > 0 &&
        this.subscriptionPackage.get('membership_tier_id', false);
    }
  }
}

export default alt.createStore(
  POSMembershipRegistrationStore,
  'POSMembershipRegistrationStore'
);
