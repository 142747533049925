import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';
import { Dropdown, TextField, Button, Grid, Chip } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

function ActiveFilters({ filters, onDeleteFilter }) {
  const orderedAt = filters.getIn(['ordered_at', 'effective_at_min']);
  const search = filters.get('search');
  const totalMin = filters.get('amount_min');
  const totalMax = filters.get('amount_max');

  return (
    <div className="closeout-report__active-filters">
      {orderedAt && (
        <Chip
          classes={{ root: 'filter-chip' }}
          label={orderedAt.format('MM/DD/YYYY')}
          onDelete={() => null}
        />
      )}
      {search && (
        <Chip
          label={search}
          classes={{ root: 'filter-chip' }}
          onDelete={() => onDeleteFilter('search')}
        />
      )}
      {totalMin > 0 && (
        <Chip
          label={<FormattedCurrency value={totalMin * 100} fromCents />}
          classes={{ root: 'filter-chip' }}
          onDelete={() => onDeleteFilter('amount_min')}
        />
      )}
      {totalMax > 0 && (
        <Chip
          label={<FormattedCurrency value={totalMax * 100} fromCents />}
          classes={{ root: 'filter-chip' }}
          onDelete={() => onDeleteFilter('amount_max')}
        />
      )}
    </div>
  );
}

ActiveFilters.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
};

function Filters({
  intl,
  filters,
  isDownloadingReport,
  onChange,
  onCalculatorClick,
  onFilterClick,
  onExportClick,
  onDeleteFilter,
}) {
  return (
    <>
      <Grid
        container
        className="closeout-report__filters"
        spacing={1}
        wrap="nowrap"
      >
        <Grid item xs={6} md={2}>
          <Dropdown
            fullWidth
            rounded
            name="ordered_at"
            value={filters.getIn(['ordered_at', 'when'])}
            items={[
              {
                label: t('.today', intl, __filenamespace),
                value: 'today',
              },
              {
                label: t('.yesterday', intl, __filenamespace),
                value: 'yesterday',
              },
            ]}
            classes={{ root: 'closeout-report__filters-ordered-at' }}
            onChange={e => onChange(e)}
          />
        </Grid>
        <Grid item xs={6} md={7}>
          <TextField
            fullWidth
            rounded
            icon="search"
            placeholder={t('.search', intl, __filenamespace)}
            name="search"
            classes={{ root: 'closeout-report__filters-search' }}
            value={filters.get('search')}
            onChange={e => onChange(e)}
          />
        </Grid>
        <Grid item xs={4} md>
          <Button rounded fullWidth type="tertiary" onClick={onCalculatorClick}>
            {t('.calculator', intl, __filenamespace)}
          </Button>
        </Grid>
        <Grid item xs={4} md>
          <Button
            rounded
            fullWidth
            icon="filter"
            iconPosition="right"
            labelAlign="space-between"
            onClick={onFilterClick}
            type="tertiary"
          >
            {t('.filters', intl, __filenamespace)}
          </Button>
        </Grid>
        <Grid item xs={4} md>
          <Button
            rounded
            fullWidth
            type="tertiary"
            icon="cloudDownload"
            disabled={isDownloadingReport}
            classes={{ root: 'closeout-report__filters-export' }}
            onClick={onExportClick}
          >
            {t('.export', intl, __filenamespace)}
          </Button>
        </Grid>
      </Grid>
      <ActiveFilters filters={filters} onDeleteFilter={onDeleteFilter} />
    </>
  );
}

Filters.propTypes = {
  intl: PropTypes.object.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  isDownloadingReport: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCalculatorClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
};

export default compose(memo, injectIntl)(Filters);
