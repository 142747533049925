import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from 'contacts/index/components/ContactImportNative/styles';

function Loading({ message }) {
  return (
    <div style={styles.loading}>
      <h2 style={styles.headerText}>{message}</h2>
      <CircularProgress size={45} />
    </div>
  );
}

export default Loading;
