import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';

import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

class FilterButton extends React.Component {
  handleToggleFilterDrawer = e => {
    const { filterActions } = this.props;

    e.preventDefault();
    filterActions.toggleFilterDrawer();
  };

  render() {
    const { large, style } = this.props;

    if (large) {
      return (
        <Button
          color="default"
          onClick={this.handleToggleFilterDrawer}
          style={merge({ color: uhColors.charcoalBlack }, style)}
          startIcon={<FilterListIcon sx={{ color: uhColors.iconGrey }} />}
        >
          Filter
        </Button>
      );
    }
    return (
      <IconButton style={style} onClick={this.handleToggleFilterDrawer}>
        <FilterListIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
    );
  }
}

FilterButton.propTypes = {
  filterActions: PropTypes.object,
  style: PropTypes.object,
  large: PropTypes.bool,
};

FilterButton.defaultProps = {
  filterActions: FilterActions,
  style: {},
  large: false,
};

export default FilterButton;
