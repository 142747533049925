class PayAllBalancesActions {
  constructor() {
    this.generateActions(
      'open',
      'close',
      'balanceList',
      'balanceListSuccess',
      'balanceListError',
      'fetchStatisticSuccess',
      'fetchStatisticError',
      'payAll',
      'payAllSuccess',
      'payAAllError'
    );
  }
}

export default alt.createActions(PayAllBalancesActions);
