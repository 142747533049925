import { Record } from 'immutable';
import moment from 'moment-timezone';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

class DateOfBirth extends Record({
  operator: null,
  start_date: null,
  end_date: null,
}) {
  constructor(object = {}) {
    let obj = object;
    if (obj.asImmutable) {
      obj = obj.toJS();
    }
    const startDate = obj?.start_date;
    const endDate = obj?.end_date;
    super({
      operator: obj?.operator,
      start_date: startDate ? moment.tz(startDate, customerTZ()) : null,
      end_date: endDate ? moment.tz(endDate, customerTZ()) : null,
    });
  }

  hasDobRestriction() {
    return this.operator !== null && this.start_date !== null;
  }

  isDobTypeBetween() {
    return this.operator === 'Is Between';
  }

  isDobTypeBefore() {
    return this.operator === 'Is Before';
  }

  isDobTypeAfter() {
    return this.operator === 'Is After';
  }

  canClearSelections() {
    return this.start_date !== null || this.end_date !== null;
  }

  hasDobTypeSelected() {
    return Boolean(this.operator);
  }
}

export default DateOfBirth;
