import * as React from 'react';
import { injectIntl } from 'react-intl';

import CartButton from 'shared/components/navigation/CartButton.jsx';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import altContainer from 'shared/hocs/altContainer.jsx';
import CardReaderDropdown from 'containers/cardReaderDropdown/CardReaderDropdown.jsx';
import CustomerStore from 'shared/stores/CustomerStore.jsx';
import Customer from 'shared/records/Customer.jsx';
import QuickAccessButton from 'shared/components/navigation/QuickAccessButton.jsx';
import NavigationMenu from '@mui/icons-material/Menu';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import SideNavActions from 'shared/actions/SideNavActions.jsx';
import TimeTrackingMenu from 'shared/components/TimeTrackingMenu.jsx';
import uhTheme from '_uh_theme.jsx';
import UserMenu from 'shared/components/navigation/UserMenu.jsx';
import QRDialog from 'shared/components/_QRDialog.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions.js';
import ModalRegistrationActions from 'shared/components/signUp/Actions';
import {
  currentUser,
  isLoggedIn,
  hasAdminPermission,
} from 'shared/utils/UserUtils.jsx';
import { humanizeUrl } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { smallScreen, generateHtmlId } from 'shared/utils/DOMUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import ClientProfileStore from 'containers/clientProfile/Store';
import ClientProfileDrawerStore from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Store';
import SearchBar from '../SearchBar.jsx';
import QrScannerIcon from './QrScannerIcon.jsx';

const styles = {
  root: {
    backgroundColor: 'white',
    position: 'fixed',
    width: '100%',
    zIndex: 1100,
  },
  toolbar: {
    backgroundColor: 'white',
    padding: '0 20px',
    minHeight: 56,
    justifyContent: 'space-between',
  },
  toolbarTitle: {
    color: uhTheme.palette.textColor,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  reloadBtn: {
    marginRight: '16px',
  },
  timeZone: {
    fontSize: 'small',
    verticalAlign: 'top',
  },
  createBtn: {
    color: uhColors.activeBlue,
    margin: '0 1em',
    fontSize: 15,
    fontWeight: 600,
  },
  searchBar: {
    padding: '0 20px',
    fontSize: 16,
    backgroundColor: 'white',
  },
};

const canViewQuickAddButton = () =>
  currentUser().isStaff() || currentUser().isCoach();

const canViewClockIn = () =>
  currentCustomer().time_tracking_enabled &&
  (currentUser().role === 'StaffAdmin' || currentUser().role === 'StaffMember');

const openSideNav = event => {
  event.preventDefault();
  SideNavActions.toggle([true, 'menuClick']);
};

const customerTimeZone = () => {
  if (
    currentUser().isAdmin() &&
    currentCustomer() &&
    currentCustomer().tz_shortname
  ) {
    return (
      <span style={styles.timeZone}>
        &nbsp;({currentCustomer().tz_shortname})
      </span>
    );
  }
  return null;
};

const handleLogoError = () => {
  const logo = document.getElementById('siteHeaderCustomerLogo');

  logo.style.display = 'none';
};

const CreateAccountButton = injectIntl(({ intl }) => (
  <Button
    style={styles.createBtn}
    onClick={() => ModalRegistrationActions.toggleSignUpModal({})}
  >
    {t('.create_account_button', intl, __filenamespace)}
  </Button>
));

const LoginButton = injectIntl(({ intl }) => (
  <Button
    color="secondary"
    variant="contained"
    onClick={() => ModalRegistrationActions.toggleSignInModal({})}
  >
    {t('.login_button', intl, __filenamespace)}
  </Button>
));

function SearchBarCustom({ qrCodeValue, hasQrIcon }) {
  if (!currentUser().isClient()) {
    if (hasQrIcon) {
      return (
        <IconButton
          onClick={ClientProfileDrawerActions.toggleHasQrIcon}
          style={{ margin: 0 }}
        >
          <QrScannerIcon />
        </IconButton>
      );
    }
    return (
      <SearchBar
        searchText={qrCodeValue}
        onTypingEnd={val => {
          ClientProfileDrawerActions.mounted({
            clientId: val,
            eventId: null,
          });
        }}
        style={styles.searchBar}
        autoFocus={!hasQrIcon}
        onBlur={ClientProfileDrawerActions.toggleHasQrIcon.defer}
      />
    );
  }
  return null;
}

function SmallScreen({ customer, qrCodeValue, hasQrIcon }) {
  return (
    <Toolbar style={styles.toolbar}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          style={{ alignSelf: 'center', padding: 0 }}
          onClick={openSideNav}
        >
          <NavigationMenu />
        </IconButton>
        <Typography
          variant="h5"
          style={{
            ...styles.toolbarTitle,
            ...{ maxWidth: window.innerWidth - 240 },
          }}
        >
          {customer.name}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {isLoggedIn() ? (
          <>
            <SearchBarCustom qrCodeValue={qrCodeValue} hasQrIcon={hasQrIcon} />
            <QRDialog />
            {currentCustomer().handpoint_cloud_enabled &&
              (hasAdminPermission() || currentUser().isStaffMember()) && (
                <span
                  id={generateHtmlId('CardReaderDropdown', __filenamespace)}
                >
                  <CardReaderDropdown isMobile />
                </span>
              )}
            <CartButton compact />
            {canViewClockIn() && <TimeTrackingMenu />}
            {canViewQuickAddButton() && (
              <span id={generateHtmlId('QuickAccessButton', __filenamespace)}>
                <QuickAccessButton />
              </span>
            )}
          </>
        ) : (
          <LoginButton />
        )}
      </Stack>
    </Toolbar>
  );
}

function LargeScreen({ customer, qrCodeValue, hasQrIcon }) {
  return (
    <Toolbar style={styles.toolbar}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 24,
          }}
        >
          <img
            id="siteHeaderCustomerLogo"
            src={customer.logo.getSrc()}
            alt={customer.name}
            style={{ height: 40 }}
            crossOrigin="anonymous"
            onError={handleLogoError}
          />
        </div>
        <div style={{ alignSelf: 'center' }}>
          <div style={{ fontSize: 20 }}>
            {customer.name}
            {customerTimeZone()}
          </div>
          <a
            href={customer.external_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: uhTheme.palette.hyperlink }}
          >
            {humanizeUrl(customer.external_url)}
          </a>
        </div>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {isLoggedIn() ? (
          <>
            <SearchBarCustom qrCodeValue={qrCodeValue} hasQrIcon={hasQrIcon} />
            <QRDialog />
            {currentCustomer().handpoint_cloud_enabled &&
              (hasAdminPermission() || currentUser().isStaffMember()) && (
                <span
                  id={generateHtmlId('CardReaderDropdown', __filenamespace)}
                >
                  <CardReaderDropdown />
                </span>
              )}
            <CartButton />
            {canViewClockIn() && <TimeTrackingMenu />}
            {canViewQuickAddButton() && (
              <span id={generateHtmlId('QuickAccessButton', __filenamespace)}>
                <QuickAccessButton />
              </span>
            )}
            <span id={generateHtmlId('UserMenu', __filenamespace)}>
              <UserMenu />
            </span>
          </>
        ) : (
          <>
            <CreateAccountButton />
            <LoginButton />
          </>
        )}
      </Stack>
    </Toolbar>
  );
}

function SiteHeader({
  customerStore: { customer },
  clientProfileStore: { qrCodeValue },
  clientProfileDrawerStore: { hasQrIcon },
}) {
  const currentCust = customer.id ? customer : new Customer(currentCustomer());

  React.useEffect(() => {
    if (smallScreen()) {
      SideNavActions.close();
    }
  }, []);
  return (
    <Paper id="site-header" style={styles.root}>
      <ResponsiveElement
        largeScreen={
          <LargeScreen
            customer={currentCust}
            qrCodeValue={qrCodeValue}
            hasQrIcon={hasQrIcon}
          />
        }
        smallScreen={
          <SmallScreen
            customer={currentCust}
            qrCodeValue={qrCodeValue}
            hasQrIcon={hasQrIcon}
          />
        }
      />
    </Paper>
  );
}

export default altContainer({
  stores: {
    customerStore: CustomerStore,
    clientProfileStore: ClientProfileStore,
    clientProfileDrawerStore: ClientProfileDrawerStore,
  },
})(SiteHeader);
