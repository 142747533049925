import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { TextField } from '@upperhand/playmaker';
import { grey, red } from '@mui/material/colors';
import AltContainer from 'alt-container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import FaceIDIcon from 'shared/components/icons/FaceID.jsx';
import TouchIDIcon from 'shared/components/icons/TouchID.jsx';
import ResendVerificationLink from 'shared/components/login/_ResendVerificationLink.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import NativeBiometric from 'shared/native/BiometricAuthenticator.js';
import { Content as SendVerificationSuccess } from 'containers/accounts/verification_sent/VerificationSent.jsx';
import { FlexBoxJustify, FlexBoxCenter } from 'shared/components/FlexBox.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import uhTheme from '_uh_theme.jsx';
import { boldText } from 'shared/styles/uhStyles.jsx';
import { handleOnEnterKeyDown } from 'shared/components/Form.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { isNative } from 'shared/utils/UserAgentUtils.js';

import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import ModalRegistrationActions from 'shared/components/signUp/Actions';
import ForgotPasswordActions from 'containers/accounts/forgotPassword/Actions.js';
import AuthActions from 'shared/actions/AuthActions.jsx';

import AuthStore from 'shared/stores/AuthStore.jsx';
import ResendAccountVerificationStore from 'shared/stores/ResendAccountVerificationStore.jsx';

import CustomerAccountSelection from './CustomerAccountSelection.jsx';
import LandingTemplate from './_LandingTemplate.jsx';

const onChange = handleChange({ actions: AuthActions });
const autofocus = !isNative();

const ExistingAccountNote = injectIntl(({ style, intl }) => (
  <div
    style={merge(
      {
        fontSize: '19px',
        lineHeight: '24px',
        paddingBottom: '2em',
        paddingTop: '1em',
      },
      style
    )}
  >
    <FormattedMessage
      id={messageId('.account_already_setup', __filenamespace)}
      values={{
        sign_in: (
          <span style={boldText}>
            {t('actions.sign_in', intl, __filenamespace)}
          </span>
        ),
        reset_password: (
          <span style={boldText}>
            {t('._reset_password', intl, __filenamespace)}
          </span>
        ),
      }}
    />
  </div>
));

const ErrorText = injectIntl(({ display, text, style, action, intl }) => (
  <div style={style}>
    {display && text ? (
      <span>
        {t(text, intl, __filenamespace)}&nbsp;{action}
      </span>
    ) : (
      <span style={{ color: 'white' }}>.</span>
    )}
  </div>
));

const SignInButton = injectIntl(({ intl, ...props }) => (
  <StateChangingButton
    label={
      <span style={{ textTransform: 'capitalize' }}>
        <FormattedMessage id={messageId('actions.sign_in')} />
      </span>
    }
    labelInProgress={t('.authenticating', intl, __filenamespace)}
    type="submit"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

function BiometricButton({ visible, type, onClick }) {
  const style = {
    button: {
      position: 'absolute',
      top: '16px',
      right: '-7px',
      width: '51px',
      height: '51px',
      padding: '8px',
    },
    iconStyle: {
      width: '35px',
      height: '35px',
    },
  };
  if (visible) {
    const Icon = type === NativeBiometric.FACE ? FaceIDIcon : TouchIDIcon;
    return (
      <IconButton
        onClick={onClick}
        style={style.button}
        iconStyle={style.iconStyle}
      >
        <Icon />
      </IconButton>
    );
  }
  return null;
}

class LoginForm extends React.Component {
  componentDidMount() {
    AuthActions.attemptAutomaticLogin();
  }

  // eslint-disable-next-line class-methods-use-this
  handleLogin = _event => {
    const { modalOpen } = this.props;
    // Clean all previous errors. In a normal situation, we have to use ErrorDialogWithMessageWindow component, which is handling this process
    ApiErrorDialogActions.ackError();
    AuthActions.authenticate({ redirectAfterLogin: !modalOpen });
  };

  displayErrorText() {
    const {
      authStore: { authenticated, authenticating, authenticationAttempted },
    } = this.props;
    return authenticationAttempted && !authenticating && !authenticated;
  }

  render() {
    const {
      authStore: {
        authenticated,
        authenticating,
        loginErrorAction,
        loginErrorText,
        showCustomerStep,
        biometricMethod,
        showBiometricOption,
        credentials,
        showResetLink,
      },
      resendAccountVerificationStore: { showVerificationConfirm, email },
      mobile,
      fromRegistration,
      intl,
      modalOpen,
    } = this.props;

    const forgotPassword = showResetLink ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: modalOpen && '25px',
        }}
      >
        <ResendVerificationLink redirect={!modalOpen} modalOpen={modalOpen} />
      </div>
    ) : (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {modalOpen ? (
          <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{
              color: grey[400],
              alignSelf: 'flex-start',
              fontWeight: 600,
              backgroundColor: 'transparent',
            }}
            onClick={ForgotPasswordActions.clickButton}
          >
            {t('.forgot_password', intl, __filenamespace)}
          </Button>
        ) : (
          <Link
            to="/accounts/forgot_password"
            style={{ textDecoration: 'none' }}
          >
            <Button
              disableRipple
              disableFocusRipple
              disableTouchRipple
              style={{
                color: grey[400],
                alignSelf: 'flex-start',
                fontWeight: 600,
                backgroundColor: 'transparent',
              }}
            >
              {t('.forgot_password', intl, __filenamespace)}
            </Button>
          </Link>
        )}
      </div>
    );

    if (modalOpen && showVerificationConfirm) {
      return (
        <div style={{ width: '100%' }}>
          <SendVerificationSuccess
            email={email || 'sergey.yemelyanov@swanlogic.com'}
            rootWidth="inherit"
            width={400}
            modalOpen={modalOpen}
          />
        </div>
      );
    }

    if (modalOpen && showCustomerStep) {
      return (
        <div style={{ width: '100%', padding: '15px' }}>
          <CustomerAccountSelection onlySelectForm modalOpen={modalOpen} />
        </div>
      );
    }

    if (!mobile) {
      return (
        <div id="login-form" style={{ width: '100%', maxWidth: 325 }}>
          {fromRegistration && <ExistingAccountNote />}
          <TextField
            autoFocus={autofocus}
            label={t('.email_address', intl, __filenamespace)}
            name="email"
            type="email"
            onChange={onChange}
            onKeyDown={e => handleOnEnterKeyDown(e, this.handleLogin)}
            value={credentials.get('email')}
          />
          <div
            style={
              modalOpen
                ? { marginTop: '2rem' }
                : { marginTop: '2rem', marginBottom: '2.5rem' }
            }
          >
            <TextField
              label={t('.password', intl, __filenamespace)}
              name="password"
              type="password"
              onChange={onChange}
              onKeyDown={e => handleOnEnterKeyDown(e, this.handleLogin)}
              value={credentials.get('password')}
            />
          </div>
          {modalOpen && (
            <ErrorText
              display={this.displayErrorText()}
              style={{
                marginTop: '15px',
                marginBottom: '15px',
                color: red[500],
                fontSize: '12px',
                lineHeight: 1.3,
              }}
              text={loginErrorText}
              action={loginErrorAction}
            />
          )}
          {modalOpen && forgotPassword}

          <FlexBoxJustify style={{ marginTop: '2rem' }}>
            {modalOpen && (
              <Button
                color="dark"
                variant="contained"
                sx={{ width: '145px', height: '45px' }}
                onClick={() => ModalRegistrationActions.toggleSignUpModal({})}
              >
                Create Account
              </Button>
            )}
            <SignInButton
              inProgress={authenticating || authenticated}
              disabled={authenticating}
              onClick={this.handleLogin}
              color="secondary"
              style={
                modalOpen
                  ? { width: '145px', height: '45px' }
                  : { width: 'auto' }
              }
            />
            {!modalOpen && forgotPassword}
          </FlexBoxJustify>
          {!modalOpen && (
            <ErrorText
              display={this.displayErrorText()}
              style={{
                marginTop: '5rem',
                color: red[500],
                fontWeight: 'bold',
                lineHeight: 1.3,
              }}
              text={loginErrorText}
              action={loginErrorAction}
            />
          )}
        </div>
      );
    }

    return (
      <FlexBoxCenter style={{ width: '100%', flexDirection: 'column' }}>
        <FlexBoxCenter style={{ flex: '1 1 auto', flexDirection: 'column' }}>
          {fromRegistration && (
            <ExistingAccountNote style={{ width: '256px' }} />
          )}
          <TextField
            autoFocus={autofocus}
            label={t('.email_address', intl, __filenamespace)}
            name="email"
            type="email"
            onChange={onChange}
            onKeyDown={e => handleOnEnterKeyDown(e, this.handleLogin)}
            value={credentials.get('email')}
          />
          <div style={{ position: 'relative', marginTop: '1rem' }}>
            <TextField
              label={t('.password', intl, __filenamespace)}
              name="password"
              type="password"
              onChange={onChange}
              onKeyDown={e => handleOnEnterKeyDown(e, this.handleLogin)}
              value={credentials.get('password')}
            />
            <BiometricButton
              visible={showBiometricOption}
              type={biometricMethod}
              onClick={() => AuthActions.attemptAutomaticLogin()}
            />
          </div>
          {!modalOpen && forgotPassword}
          <ErrorText
            display={this.displayErrorText()}
            style={{
              margin: '0.5rem auto',
              color: red[500],
              fontWeight: 'bold',
              height: 45, // needs to be 2 lines for verify account error
              lineHeight: 1.3,
            }}
            text={loginErrorText}
            action={loginErrorAction}
          />
        </FlexBoxCenter>
        <FlexBoxCenter
          id="sign-in-button-container"
          style={{
            width: '100%',
            flex: '0 1 80px',
            backgroundColor: uhTheme.login.mobileSignInColor,
          }}
        >
          <SignInButton
            id="sign-in-button"
            color="secondary"
            inProgress={authenticating || authenticated}
            disabled={authenticating}
            onClick={this.handleLogin}
            style={{
              width: '100%',
              height: 80,
              color: 'white',
              fontSize: '22px',
              fontWeight: 600,
            }}
          />
        </FlexBoxCenter>
      </FlexBoxCenter>
    );
  }
}

export const LoginFormWrapper = injectIntl(
  ({ mobile, fromRegistration, intl, modalOpen }) => (
    <AltContainer
      stores={{
        authStore: AuthStore,
        resendAccountVerificationStore: ResendAccountVerificationStore,
      }}
    >
      <LoginForm
        mobile={mobile}
        fromRegistration={fromRegistration}
        intl={intl}
        modalOpen={modalOpen}
      />
    </AltContainer>
  )
);

function UserLogin({ modalOpen, ...props }) {
  return (
    <LandingTemplate
      // eslint-disable-next-line react/jsx-props-no-spreading
      largeScreen={<LoginFormWrapper {...props} modalOpen={modalOpen} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      smallScreen={<LoginFormWrapper mobile {...props} modalOpen={modalOpen} />}
      modalOpen={modalOpen}
    />
  );
}

export default UserLogin;
