import { List, fromJS } from 'immutable';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import initATD from 'shared/utils/ATDInitializationUtils.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class POSEventStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      selectEvent: POSProductListActions.EVENT_SELECTED,

      setEventAutomation: POSEventActions.AUTOMATION_LIST_SUCCESS,
      automationListError: POSEventActions.AUTOMATION_LIST_ERROR,

      setEventAvailability: POSEventActions.FETCH_AVAILABILITY_SUCCESS,
      fetchAvailabilityError: POSEventActions.FETCH_AVAILABILITY_ERROR,

      loadOrderItemEvent: [
        POSCartActions.ITEM_EDIT_SELECTED,
        POSCartActions.ITEM_VIEW_SELECTED,
      ],

      fetchEventSuccess: POSEventActions.FETCH_EVENT_SUCCESS,
      fetchEventError: POSEventActions.FETCH_EVENT_ERROR,

      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCartActions.ADD_SUCCESS,
        POSCartActions.UPDATE_SUCCESS,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
        POSEventActions.EVENT_SCHEDULING_CANCEL_CLICKED,
        POSSchedulingActions.VIEW_IN_CART_CLICKED,
      ],
    });
  }

  reset() {
    // Event
    this.event = new CustomerEvent();
    this.customerEvent = this.event; // Conform with RegistrationScheduler interface.
    this.individualSessions = List();

    // Automations
    this.paymentPlanDescription = null;
    this.packagePricingDescription = null;
    this.automationsLoaded = false;

    // Availability
    this.spotsRemaining = null;
    this.availableTimes = List();
    this.isLoadingAvailability = false;
  }

  selectEvent(selectedEvent) {
    this.event = selectedEvent;
    this.customerEvent = this.event; // Conform with RegistrationScheduler interface.

    this.listEventAutomations();

    // If this is a fixed schedule event we can fetch availability right away,
    // otherwise, if this is open booking we need to wait for the user to
    // select a date.
    if (this.event.isFixedSchedule() || this.event.isTeamEvent()) {
      this.fetchEventAvailability();
    }
  }

  listEventAutomations() {
    return uhApiClient.get({
      url: 'automation_template_descriptions',
      data: { event_id: this.event.id },
      success: POSEventActions.automationListSuccess,
      error: POSEventActions.automationListError,
    });
  }

  setEventAutomation(data) {
    const atds = data.automation_template_descriptions
      .map(atd => initATD(atd))
      .filter(atd => atd.event_id === this.event.id);

    this.paymentPlanDescription =
      atds.find(atd => atd.isRecurringPaymentPlan()) || null;
    this.packagePricingDescription =
      atds.find(atd => atd.isPackagePricing()) || null;

    this.automationsLoaded = true;
  }

  automationListError(...args) {
    this.notifyError(
      `error while listing ATDs for event ${this.event.id}`,
      args
    );
  }

  fetchEventAvailability(date) {
    this.isLoadingAvailability = true;

    return uhApiClient.get({
      url: `events/${this.event.id}/availability`,
      data: { date },
      success: POSEventActions.fetchAvailabilitySuccess,
      error: POSEventActions.fetchAvailabilityError,
    });
  }

  setEventAvailability(data) {
    this.spotsRemaining = data.spots_remaining;
    this.availableTimes = fromJS(data.available_times);
    this.individualSessions = fromJS(data.fixed_schedule_sessions);
    this.isLoadingAvailability = false;
  }

  fetchAvailabilityError(...args) {
    this.notifyError(
      `error while fetching availability for event ${this.event.id}`,
      args
    );
  }

  loadOrderItemEvent(orderItem) {
    if (orderItem.isEventItem()) {
      this.fetchEvent(orderItem.orderable.event_id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  fetchEvent(id) {
    return uhApiClient.get({
      url: `events/${id}`,
      data: { fields: ['exclusive_membership_ids'] },
      success: POSEventActions.fetchEventSuccess,
      error: POSEventActions.fetchEventError,
    });
  }

  fetchEventSuccess(data) {
    this.selectEvent(new EventTranslator(data).toClient());
  }

  fetchEventError(...args) {
    this.notifyError('error fetching event', args);
  }
}

export default alt.createStore(POSEventStore, 'POSEventStore');
