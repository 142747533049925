import * as React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';
import { Typography } from '@upperhand/playmaker';
import { useParams } from 'react-router';
import DevTestActions from './Actions';
import DevTestStore from './Store';
import RecordCard from './components/RecordCard.jsx';

const showRecord = (entityId, dataStore, paginationKey) => {
  // some of our DataStores use the paginationkey "string" instead of a generic property "records"
  const records = dataStore.records || dataStore[paginationKey];

  let record = records.get(entityId);

  // sometimes our ids are numbers aka id sometimes there strings aka GUID's
  // FIXME: we should really make all of the keys stings, as we want to move to GUIDS in the future
  if (!record) {
    // eslint-disable-next-line radix
    record = records.get(parseInt(entityId));
  }

  if (record) {
    return <RecordCard key={record.id} record={record} />;
  }
  return <div> record not loaded/ does not exist</div>;
};

function Show({
  devTestStore: { fetchId },
  dataSource,
  dataStore,
  paginationKey,
}) {
  // normally i would use the page component, but because we our dynamicly passing alt stores in and this is a dev component, this will have to do.
  const { entityId } = useParams();
  React.useEffect(() => {
    DevTestActions.showMounted({ dataSource, paginationKey, entityId });
  }, [dataSource, entityId, paginationKey]);

  return (
    <div>
      <Typography variant="h2">Show {paginationKey}</Typography>
      {showRecord(fetchId, dataStore, paginationKey)}
    </div>
  );
}

export default altContainer({
  stores: {
    devTestStore: DevTestStore,
  },
})(Show);
