import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { injectIntl } from 'react-intl';
import { ContentModal, TextField } from '@upperhand/playmaker';

import WarningIcon from 'shared/components/icons/WarningIcon.jsx';
import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';
import EventTypeEditor from 'components/EventTypeEditor';
import ImageUpload from 'components/ImageUpload';

import Image from 'records/Image';
import EventClass from 'shared/records/EventClass';
import FieldErrors from 'shared/records/FieldErrors.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { EventTypeDataStore } from 'dataStores';

import ClassCreationActions from './Actions';
import ClassCreationStore from './Store';

import './styles.scss';

function ClassCreateModal({
  intl,
  creatingMode,
  eventTypeDataStore: { eventTypes },
  classCreationStore: {
    showCreationModal,
    classForm,
    eventTypesIds,
    formErrors,
    creatingClass,
    updatingClass,
  },
}) {
  const types = eventTypesIds.map(id => eventTypes?.get(id)).toList();
  const handleImageChange = image =>
    ClassCreationActions.handleFieldChange({
      field: 'image',
      value: new Image(image),
    });
  const loading = creatingClass || updatingClass;
  const updateId = updatingClass ? '.update_label_loading' : '.update_label';
  const createId = creatingClass ? '.create_label_loading' : '.create_label';

  return (
    <ContentModal
      classes={{
        root: 'class-creation-modal',
        actions: clsx(loading && 'class-creation-modal__actions-disabled'),
      }}
      open={showCreationModal}
      title={
        creatingMode
          ? t('.create_title', intl, __filenamespace)
          : t('.update_title', intl, __filenamespace)
      }
      successLabel={
        creatingMode
          ? t(createId, intl, __filenamespace)
          : t(updateId, intl, __filenamespace)
      }
      onAbort={ClassCreationActions.toggleCreationModal}
      onSuccess={
        creatingMode
          ? ClassCreationActions.createClass
          : ClassCreationActions.updateClass
      }
    >
      <TextField
        fullWidth
        label={t('.title_label', intl, __filenamespace)}
        placeholder={t('.title_placeholder', intl, __filenamespace)}
        value={classForm.get('title')}
        errorText={formErrors.getErrors('title', intl)}
        classes={{ root: 'class-creation-modal__form-filed' }}
        onChange={e =>
          ClassCreationActions.handleFieldChange({
            field: 'title',
            value: e.target.value,
          })
        }
      />
      <EventTypeEditor
        label={t('.event_type_label', intl, __filenamespace)}
        eventTypes={types}
        errorText={formErrors.getErrors('event_type_id', intl)}
        onEventTypeSelect={eventTypeId =>
          ClassCreationActions.handleFieldChange({
            field: ['event_type', 'id'],
            value: eventTypeId,
          })
        }
        onCreateOrUpdateSuccess={ClassCreationActions.createEventTypeSuccess}
        selectedEventTypeId={classForm.getIn(['event_type', 'id'])}
      />
      <div>
        <p className="class-creation-modal__label">
          {t('.description_label', intl, __filenamespace)}
        </p>
        <WysiwygEditor
          useBase64={false}
          value={classForm.get('description')}
          onChange={value => {
            ClassCreationActions.handleFieldChange({
              field: 'description',
              value,
            });
          }}
        />
        <p className="class-creation-modal__error-description">
          {formErrors.getErrors('description', intl)}
        </p>
      </div>
      <div>
        <p className="class-creation-modal__label">
          {t('.image_label', intl, __filenamespace)}
        </p>
        <ImageUpload
          cropAspect={1.6}
          uploadContainerHeight={200}
          uploadContainerWidth="100%"
          onChange={handleImageChange}
          onRemove={handleImageChange}
          image={classForm.get('image')}
        />
      </div>
      {classForm.get('status') === 'draft' && (
        <div className="class-creation-modal__warning-message">
          <WarningIcon />
          <p>{t('.warning_message', intl, __filenamespace)}</p>
        </div>
      )}
    </ContentModal>
  );
}

ClassCreateModal.propTypes = {
  intl: PropTypes.object.isRequired,
  creatingMode: PropTypes.bool,
  eventTypeDataStore: PropTypes.shape({
    eventTypes: PropTypes.instanceOf(Map),
  }),
  classCreationStore: PropTypes.shape({
    showCreationModal: PropTypes.bool,
    classForm: PropTypes.instanceOf(Map),
    eventTypesIds: PropTypes.instanceOf(OrderedSet),
    formErrors: PropTypes.instanceOf(FieldErrors),
    creatingClass: PropTypes.bool,
    updatingClass: PropTypes.bool,
  }),
};

ClassCreateModal.defaultProps = {
  creatingMode: true,
  eventTypeDataStore: {
    eventTypes: Map(),
  },
  classCreationStore: {
    showCreationModal: false,
    classForm: Map(new EventClass()),
    eventTypeIds: OrderedSet(),
    formErrors: new FieldErrors({}),
    creatingClass: false,
    updatingClass: false,
  },
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      classCreationStore: ClassCreationStore,
      eventTypeDataStore: EventTypeDataStore,
    },
  })
)(ClassCreateModal);
