import * as React from 'react';
import AltContainer from 'alt-container';

import SideNav from 'side_nav/SideNav.jsx';
import SideNavActions from 'shared/actions/SideNavActions.jsx';
import SideNavStore from 'shared/stores/SideNavStore.jsx';
import AccountSetupIncompleteBanner from 'onboarding/components/AccountSetupIncompleteBanner.jsx';
import AccountSetupPaysafeStore from 'onboarding/stores/AccountSetupPaysafeStore.js';
import CustomerStore from 'shared/stores/CustomerStore.jsx';

function ContentLayoutSmall({ children }) {
  return (
    <div>
      <AltContainer
        stores={{
          sideNavStore: SideNavStore,
          customerStore: CustomerStore,
        }}
      >
        <SideNav onRequestChange={SideNavActions.toggle} />
      </AltContainer>
      <main
        id="main-content-wrapper"
        style={{ paddingLeft: 0, marginBottom: 75 }}
      >
        <AltContainer store={AccountSetupPaysafeStore}>
          <AccountSetupIncompleteBanner />
        </AltContainer>
        {children}
      </main>
    </div>
  );
}

export default ContentLayoutSmall;
