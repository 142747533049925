import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, Checkbox, Typography } from '@upperhand/playmaker';
import { Link as ReactRouterLink } from 'react-router-dom';
import AltContainer from 'alt-container';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';

import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import ColumnarForm from 'shared/components/ColumnarForm.jsx';
import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import Form from 'shared/components/Form.jsx';
import LabelPrintActions from 'retail/actions/LabelPrintActions';
import LabelPrintDialog from 'retail/components/LabelPrintDialog.jsx';
import ProductImageEditing from 'retail/components/_ProductImageEditing.jsx';
import ProductImageStore from 'retail/stores/ProductImageStore.js';
import RetailCategoryInlineEditor from 'shared/components/RetailCategoryInlineEditor.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import RetailEditingActions from 'retail/actions/RetailEditingActions.js';
import RetailEditingStore from 'retail/stores/RetailEditingStore.js';
import RetailVendors from 'containers/retail/retailVendors/RetailVendors.jsx';
import VariantEditing from 'retail/components/_VariantEditing.jsx';
import VariantEditingStore from 'retail/stores/VariantEditingStore.js';
import ZebraStore from 'shared/stores/ZebraStore.js';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, uhFormElement } from 'shared/styles/uhStyles.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

const styles = {
  currencyInput: {
    width: 180,
  },

  explanatoryText: {
    marginTop: 10,
  },

  header: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: `0 ${LEFT_MARGIN_PIXELS}px`,
    borderBottom: `1px solid ${uhColors.dividerGrey}`,
    padding: '20px 0',
  },

  container: {
    margin: '0 45px',
  },
  swingFeature: {
    marginBottom: 20,
  },
};

const onChange = handleChange({ actions: RetailEditingActions });

const onPrintClick = () => LabelPrintActions.dialogOpened();

const TabHeader = injectIntl(
  ({
    intl,
    retailEditingStore: { product },
    zebraStore: { printing, defaultPrinter },
  }) => (
    <FlexBoxJustify style={styles.header}>
      <div>
        <div style={{ padding: 9 }}>
          {product.id ? (
            <FormattedMessage id={messageId('.header_edit', __filenamespace)} />
          ) : (
            <FormattedMessage
              id={messageId('.header_create', __filenamespace)}
            />
          )}
        </div>
        {defaultPrinter && (
          <Button
            startIcon={<PrintIcon />}
            onClick={onPrintClick}
            disabled={printing}
          >
            {printing
              ? t('.printing', intl, __filenamespace)
              : t('.print_labels', intl, __filenamespace)}
          </Button>
        )}
      </div>
      <ActionButtons product={product} intl={intl} />
    </FlexBoxJustify>
  )
);

const save = () => RetailEditingActions.validateAndSave();

const publish = () => RetailEditingActions.publish();

const ActionButtons = injectIntl(({ intl, product }) => (
  <div>
    <ReactRouterLink
      to={customerScopedRoute('/retail')}
      style={{ textDecoration: 'none' }}
    >
      <Button variant="contained" color="default">
        {t('actions.cancel', intl)}
      </Button>
    </ReactRouterLink>
    <StateChangingButton
      label={t(product.id ? 'actions.save' : 'actions.create', intl)}
      labelInProgress={t('actions.saving', intl)}
      onClick={save}
      style={{ marginLeft: 16 }}
    />
    <StateChangingButton
      label={t('actions.publish', intl)}
      labelInProgress={t('actions.publishing', intl)}
      onClick={publish}
      style={{ marginLeft: 16 }}
    />
  </div>
));

const CurrencyFields = injectIntl(({ intl, product }) => (
  <FlexBoxJustify style={{ marginBottom: 20 }}>
    <div style={{ flex: '0 1 50%' }}>
      <CurrencyTextField
        name="base_price"
        value={product.base_price}
        onChange={(e, value) => onChange(e, value, 'base_price')}
        converter={e => parseInt(e, 10)}
        errorText={product.errors.getErrors('base_price', intl)}
        floatingLabelText={t('.price_floating', intl, __filenamespace)}
        symbolStyle={styles.currencyIcon}
        style={{ marginRight: 10 }}
        textFieldStyle={styles.currencyInput}
      />
    </div>
    <div style={{ flex: '0 1 50%' }}>
      <CurrencyTextField
        name="base_cost"
        value={product.base_cost}
        onChange={(e, value) => onChange(e, value, 'base_cost')}
        converter={e => parseInt(e, 10)}
        errorText={product.errors.getErrors('base_cost', intl)}
        floatingLabelText={t('.cost_floating', intl, __filenamespace)}
        symbolStyle={styles.currencyIcon}
        style={{ marginLeft: 10 }}
        textFieldStyle={styles.currencyInput}
      />
    </div>
  </FlexBoxJustify>
));

const EditingForm = injectIntl(props => {
  const {
    intl,
    retailCategoriesListingStore: { retailCategories },
    retailEditingStore: { product },
  } = props;
  const categoryId = product.retail_category
    ? product.retail_category.id
    : null;

  const { features } = currentCustomer();
  const { embed_api: embedApiEnabled, ap_credits: apCreditsEnabled } = features;

  return (
    <div>
      <TextField
        onChange={onChange}
        errorText={product.errors.getErrors('name', intl)}
        name="name"
        fullWidth
        value={product.name}
        label={t('.name_floating', intl, __filenamespace)}
        placeholder={t('.name_hint', intl, __filenamespace)}
      />
      <RetailVendors
        value={product.retail_vendor_id}
        errorText={product.errors.getErrors('retail_vendor', intl)}
        onChange={RetailEditingActions.retailVendorSelected}
      />
      <CurrencyFields intl={intl} product={product} />
      {(embedApiEnabled || apCreditsEnabled) && (
        <div style={styles.swingFeature}>
          <Checkbox
            label={t('.swing_card', intl, __filenamespace)}
            value={product.get('swing_card')}
            checked={product.get('swing_card')}
            onChange={(e, value) => onChange(e, value, 'swing_card')}
          />
        </div>
      )}
      <TextField
        onChange={onChange}
        errorText={product.errors.getErrors('description', intl)}
        name="description"
        value={product.description}
        label={t('.description_floating', intl, __filenamespace)}
        placeholder={t('.description_hint', intl, __filenamespace)}
        multiline
        fullWidth
        rows={10}
      />
      <RetailCategoryInlineEditor
        retailCategories={retailCategories}
        errorText={product.errors.getErrors('retail_category', intl)}
        onRetailCategorySelect={e =>
          RetailEditingActions.retailCategorySelected(e.target.value)
        }
        onCreateOrUpdateSuccess={id =>
          RetailEditingActions.retailCategorySelected.defer(id)
        }
        selectedRetailCategoryId={categoryId}
        style={{ margin: '1.5em 0' }}
      />
    </div>
  );
});

const OptionTypeCard = injectIntl(
  ({ intl, optionType, stagedOptionValue, index }) => {
    const handleOptionValueSave = () => {
      RetailEditingActions.optionValueSaved(index);
    };

    return (
      <Paper
        style={{
          padding: 12,
          fontSize: 15,
          position: 'relative',
          marginBottom: 10,
        }}
      >
        <div style={{ position: 'relative', margin: '10px 0' }}>
          <TextField
            onChange={event =>
              RetailEditingActions.optionTypeNameUpdated(
                index,
                event.target.value
              )
            }
            errorText=""
            value={optionType.name}
            placeholder={t('.option_type_hint', intl, __filenamespace)}
            style={uhFormElement.field}
            autoFocus={!optionType.name}
          />
          <IconButton
            onClick={() => RetailEditingActions.optionTypeRemoved(index)}
            style={{
              position: 'absolute',
              height: 36,
              width: 36,
              padding: 0,
              top: 0,
              right: -10,
            }}
          >
            <ClearIcon sx={{ color: uhColors.iconLightGrey }} />
          </IconButton>
        </div>
        <div style={{ position: 'relative', margin: '10px 0' }}>
          <Form onSubmit={handleOptionValueSave}>
            <TextField
              onChange={(_, value) =>
                RetailEditingActions.optionValueValueUpdated(index, value)
              }
              errorText=""
              value={stagedOptionValue.getIn(['optionValue', 'value'], '')}
              placeholder={t('.option_value_hint', intl, __filenamespace)}
            />
          </Form>
          {stagedOptionValue.getIn(['optionValue', 'value'], '').length > 0 && (
            <Button
              style={{
                backgroundColor: 'transparent',
                position: 'absolute',
                color: uhColors.activeBlue,
                textDecoration: 'none',
                fontWeight: 'bold',
                right: stagedOptionValue.get('index') === null ? -20 : 35,
                top: 3,
              }}
              onClick={handleOptionValueSave}
            >
              {stagedOptionValue.get('index') === null ? (
                <FormattedMessage
                  id={messageId('actions.add', __filenamespace)}
                />
              ) : (
                <FormattedMessage
                  id={messageId('actions.save', __filenamespace)}
                />
              )}
            </Button>
          )}
          {stagedOptionValue.get('index') !== null && (
            <IconButton
              onClick={() => RetailEditingActions.optionValueRemoved(index)}
              style={{
                position: 'absolute',
                height: 36,
                width: 36,
                padding: 0,
                top: 2,
                right: -10,
              }}
            >
              <DeleteIcon sx={{ color: uhColors.iconLightGrey }} />
            </IconButton>
          )}
        </div>
        <FlexBox style={{ margin: -4, flexWrap: 'wrap' }}>
          {optionType.option_values.map((v, valueIndex) => (
            <Chip
              key={v.id || valueIndex}
              style={{
                margin: 4,
                backgroundColor:
                  stagedOptionValue.get('index') === valueIndex
                    ? uhColors.charcoalBlack
                    : uhColors.activeBlue,
                color: 'white',
              }}
              onClick={() =>
                RetailEditingActions.optionValueStaged(index, valueIndex)
              }
              label={v.value}
            />
          ))}
        </FlexBox>
      </Paper>
    );
  }
);

function OptionCards({ product, stagedOptionValues }) {
  return (
    <div>
      {product.option_types.map((optionType, index) => (
        <OptionTypeCard
          key={optionType.id || index}
          optionType={optionType}
          stagedOptionValue={stagedOptionValues.get(index)}
          index={index}
        />
      ))}
    </div>
  );
}

const newOption = () => RetailEditingActions.newOptionType();

const OptionForm = injectIntl(
  ({ retailEditingStore: { product, stagedOptionValues }, intl }) => {
    const error = product.errors.getErrors('option_types', intl);
    return (
      <div>
        <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 15 }}>
          <FormattedMessage
            id={messageId('.product_options', __filenamespace)}
          />
        </div>
        <OptionCards
          product={product}
          stagedOptionValues={stagedOptionValues}
        />
        {error && <Typography variant="error">{error}</Typography>}
        <Button
          color="dark"
          startIcon={<AddIcon sx={{ color: uhColors.activeBlue }} />}
          onClick={newOption}
        >
          {t('.add_option', intl, __filenamespace)}
        </Button>
      </div>
    );
  }
);

class TabProduct extends React.PureComponent {
  componentDidMount() {
    const { productId } = this.props;
    if (productId) {
      RetailEditingActions.fetch(productId);
    }
  }

  componentWillUnmount() {
    RetailEditingActions.productReset();
  }

  render() {
    return (
      <AltContainer
        stores={{
          productImageStore: ProductImageStore,
          retailCategoriesListingStore: RetailCategoryListingStore,
          retailEditingStore: RetailEditingStore,
          variantEditingStore: VariantEditingStore,
          zebraStore: ZebraStore,
        }}
      >
        <TabHeader />
        <ColumnarForm
          className="iphone-x-content"
          columns={[<EditingForm />, <OptionForm />]}
        />
        <ProductImageEditing />
        <VariantEditing />
        <LabelPrintDialog />
      </AltContainer>
    );
  }
}
export default TabProduct;
