/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';

import PaymentCardForm from './PaymentCardForm.jsx';
import PaymentCashForm from './PaymentCashForm.jsx';
import PaymentACHForm from './PaymentACHForm.jsx';

import { CheckMessage, PayLaterMessage } from '../messages';
import { PaymentMethod } from '../../types';

function PaymentMethodWrapper({
  disableAmountField,
  allowedPaymentMethods,
  cardUsage,
  hasAccess,
  hasFutureBillableItem,
  hasPreSaleItem,
  paymentCard,
  paymentMethod,
  paymentMethods,
  processingFields,
  total,
  order,
}) {
  if (
    total === 0 &&
    !(hasPreSaleItem || hasFutureBillableItem) &&
    order?.get('prorate_date') === null &&
    !order?.couponSingleTimeAndMembership()
  ) {
    return <div />;
  }
  switch (paymentMethod) {
    case PaymentMethod.CARD:
    case PaymentMethod.CARD_NEW:
    case PaymentMethod.CARD_NOT_PRESENT:
    case PaymentMethod.CARD_SAVED:
    case PaymentMethod.HANDPOINT_CLOUD:
      return (
        <PaymentCardForm
          allowedPaymentMethods={allowedPaymentMethods}
          cardUsage={cardUsage}
          hasAccess={hasAccess}
          paymentCard={paymentCard}
          paymentMethod={paymentMethod}
          paymentMethods={paymentMethods.filter(
            m => m.type !== PaymentMethod.ACH
          )}
          processingFields={processingFields}
        />
      );

    case PaymentMethod.ACH:
    case PaymentMethod.ACH_NEW:
      return (
        <PaymentACHForm
          showBankSelector
          ach={paymentCard.info}
          hasAccess={hasAccess}
          achUsage={cardUsage}
          allowedPaymentMethods={allowedPaymentMethods}
          currentPaymentMethod={paymentCard}
          paymentMethods={paymentMethods.filter(
            m => m.type === PaymentMethod.ACH
          )}
        />
      );

    case PaymentMethod.CASH:
      return (
        <PaymentCashForm
          disableAmountField={disableAmountField}
          total={total}
        />
      );

    case PaymentMethod.CHECK:
      return <CheckMessage payableTo={currentCustomer().name} />;

    case PaymentMethod.PAY_LATER:
      return <PayLaterMessage />;

    default:
      return <div />;
  }
}

PaymentMethodWrapper.propTypes = {
  allowedPaymentMethods: PropTypes.object.isRequired,
  cardUsage: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  paymentCard: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  processingFields: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

export default PaymentMethodWrapper;
