import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Spinner } from '@upperhand/playmaker';
import { OrderedSet, Map } from 'immutable';

import EmptyState from 'containers/creditListDrawer/components/EmptyState.jsx';
import CreditGrantCard from 'containers/creditListDrawer/components/CreditGrantCard.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';

import CreditListDrawerStore from 'containers/creditListDrawer/Store';
import { CreditGrantDataStore } from 'dataStores';

function CreditGrantsTab({
  intl,
  creditListDrawerStore: {
    creditGrantIds,
    isLoadingGrants,
    isLoadingEvents,
    isLoadingEventTypes,
  },
  creditGrantDataStore: { creditGrants },
}) {
  const clientCreditGrants = creditGrantIds.reduce((grants, id) => {
    const creditGrant = creditGrants.get(id);
    return creditGrant ? grants.add(creditGrant) : grants;
  }, OrderedSet());
  const isLoading = isLoadingGrants || isLoadingEvents || isLoadingEventTypes;

  return (
    <>
      {isLoading && (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      )}
      {!isLoading && creditGrants.isEmpty() && (
        <Grid container xs={12} alignItems="center" justify="center">
          <EmptyState intl={intl} />
        </Grid>
      )}
      {!isLoading && creditGrants.size > 0 && (
        <>
          {clientCreditGrants.map(creditGrant => (
            <CreditGrantCard
              key={creditGrant.id}
              creditGrant={creditGrant}
              intl={intl}
            />
          ))}
        </>
      )}
    </>
  );
}

CreditGrantsTab.propTypes = {
  intl: PropTypes.object.isRequired,
  creditListDrawerStore: PropTypes.object,
  creditGrantDataStore: PropTypes.object,
};

CreditGrantsTab.defaultProps = {
  creditListDrawerStore: {
    creditGrantIds: OrderedSet(),
    isLoadingGrants: false,
    isLoadingEvents: false,
    isLoadingEventTypes: false,
  },
  creditGrantDataStore: {
    creditGrants: Map(),
  },
};

export default compose(
  injectIntl,
  React.memo,
  altContainer({
    stores: {
      creditListDrawerStore: CreditListDrawerStore,
      creditGrantDataStore: CreditGrantDataStore,
    },
  })
)(CreditGrantsTab);
