import { List } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { CardReaderSource } from 'sources';

import { currentCustomer } from 'shared/utils/CustomerUtils';

import DeviceListActions from 'containers/deviceList/Actions';
import CardReaderDropdownActions from './Actions';

const PLACEHOLDER_VALUE = 'placeholder';
const REFRESH_ID = 'refresh';
const ALLOWED_DEVICE_TYPES = ['paxa920', 'paxa920pro', 'paxa60'];

class CardReaderDropdownStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      loadData: CardReaderDropdownActions.mounted,
      selectCardReader: CardReaderDropdownActions.selectCardReader,
      listCardReadersSuccess: CardReaderDropdownActions.listCardReadersSuccess,
      listCardReadersError: CardReaderDropdownActions.listCardReadersError,
      updateCardReaderSuccess: DeviceListActions.updateCardReaderSuccess,
      listCardReaders: CardReaderDropdownActions.syncSuccess,
      refresh: CardReaderDropdownActions.refresh,
      syncError: CardReaderDropdownActions.syncError,
      pingSuccess: CardReaderDropdownActions.pingSuccess,
    });
  }

  reset() {
    this.isMounted = false;
    this.isPinging = false;
    this.cardReaderIds = List();
    this.enabledCardReaderIds = List();
    this.placeholderValue = PLACEHOLDER_VALUE;
    this.refreshValue = REFRESH_ID;

    // Will either be our stored value or null.
    this.cardReaderId = window.localStorage.getItem('cardReaderId');
  }

  loadData() {
    if (!this.isMounted) {
      this.isMounted = true;

      // Refresh will load card readers after the sync.
      this.refresh();
    }
  }

  listCardReaders() {
    CardReaderSource.list({
      params: {
        device_types: ALLOWED_DEVICE_TYPES,
        enabled: true,
      },
      success: CardReaderDropdownActions.listCardReadersSuccess,
      error: CardReaderDropdownActions.listCardReadersError,
    });
  }

  selectCardReader(id) {
    this.cardReaderId = id;
    this.storeCardReader(id);

    // We want to ping immediately to make sure that this device can be used.
    this.isPinging = true;
    CardReaderSource.ping({
      id,
      success: CardReaderDropdownActions.pingSuccess,
    });
  }

  listCardReadersSuccess({ card_readers: cardReaders }) {
    this.cardReaderIds = cardReaders.map(cr => cr.get('id'));

    if (this.cardReaderId && !this.cardReaderIds.includes(this.cardReaderId)) {
      this.storeCardReader(null);
      this.cardReaderId = null;
    }

    // We will want to change icon if the current card reader goes offline.
    CardReaderSource.subscribeToChanges({
      customerIds: cardReaders.map(cr => cr.get('customerId')).toSet(),
    });
  }

  listCardReadersError(...args) {
    this.notifyError('error listing card readers', args);
  }

  updateCardReaderSuccess(cardReader) {
    const updatedId = cardReader.get('id');

    if (
      this.cardReaderIds.includes(updatedId) &&
      cardReader.get('enabled') === false
    ) {
      this.cardReaderIds = this.cardReaderIds.delete(
        this.cardReaderIds.indexOf(updatedId)
      );

      if (this.cardReaderId === updatedId) {
        this.storeCardReader(null);
        this.cardReaderId = null;
      }
    } else if (
      ALLOWED_DEVICE_TYPES.indexOf(cardReader.get('deviceType')) > -1 &&
      cardReader.get('enabled') === true
    ) {
      this.cardReaderIds = this.cardReaderIds.push(updatedId);
    }
  }

  refresh() {
    if (this.cardReaderId) {
      this.isPinging = true;
      CardReaderSource.ping({
        id: this.cardReaderId,
        success: CardReaderDropdownActions.pingSuccess,
      });
    }

    CardReaderSource.sync({
      customerIds: [currentCustomer().id],
      success: CardReaderDropdownActions.syncSuccess,
      error: CardReaderDropdownActions.syncError,
    });
  }

  syncError(...args) {
    this.notifyError('error syncing card readers', args);
  }

  pingSuccess(cardReader) {
    // To be certain that we have not started a new ping while another was ongoing,
    // we will confirm the IDs line up.
    if (cardReader.get('id') === this.cardReaderId) this.isPinging = false;
  }

  storeCardReader(id) {
    if (id === null) {
      window.localStorage.removeItem('cardReaderId');
    } else {
      window.localStorage.setItem('cardReaderId', id);
    }
  }
}

export default alt.createStore(
  CardReaderDropdownStore,
  'CardReaderDropdownStore'
);
