import { uhColors } from 'shared/styles/uhStyles.jsx';
import UHTheme from '_uh_theme.jsx';

export default {
  selectContacts: {
    padding: '0 16px 160px 16px',
    height: 'calc(100% - 56px)',
  },
  loading: {
    textAlign: 'center',
    margin: '80px auto 0 auto',
  },
  message: {
    flexDirection: 'column',
    textAlign: 'center',
    margin: '80px auto 0 auto',
    padding: '0 16px',
  },
  divider: {
    height: 1,
    width: '100%',
    marginLeft: '-16px',
    marginRight: '-16px',
  },
  headerText: {
    fontSize: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },
  contact: {
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
  },
  contactCard: {
    marginTop: '0',
    marginBottom: '0',
    marginRight: '16px',
    borderLeftStyle: 'solid',
    padding: '16px 0px 16px',
    flexGrow: '1',
    overflow: 'hidden',
  },
  error: {
    color: uhColors.errorColor,
    textOverflow: 'ellipsis',
  },
  label: {
    textOverflow: 'ellipsis',
    lineHeight: 1.4,
  },
  contactList: buttonVisible => ({
    position: 'fixed',
    paddingTop: 16,
    height: buttonVisible ? 'calc(100% - 160px)' : 'calc(100% - 100px)',
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  }),
  buttonContainer: visible => ({
    position: 'fixed',
    justifyContent: 'center',
    width: '100%',
    height: '60px',
    left: 0,
    bottom: visible ? '0' : '-80px',
    backgroundColor: UHTheme.palette.primary1Color,
    transition: 'all 0.5s ease 0s',
    boxShadow: '0 -10px 20px rgba(0,0,0,0.19), 0 -6px 6px rgba(0,0,0,0.23)',
  }),
  button: {
    width: '100%',
    height: 60,
    boxShadow: 'none',
  },
  showMoreButton: {
    width: 'calc(100% - 30px)',
    marginBottom: 16,
  },
  closeButton: {
    width: 220,
  },
};
