/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
import onCordovaDeviceReady from 'shared/native/helpers/DeviceReady';
import { get } from 'lodash';
import { logError } from 'ErrorLogger.js';

/**
 * Class for interacting with native code
 */
class NativePlugin {
  constructor(path = 'cordova') {
    this._pluginPath = path;
    document.addEventListener('deviceready', this._onDeviceReady.bind(this));
  }

  /**
   * Internal hook when cordova reports setup is complete and plugins are available
   */
  _onDeviceReady() {
    document.addEventListener('pause', this._onPause.bind(this));
    document.addEventListener('resume', this._onResume.bind(this));

    if (!this._pluginAvailable()) {
      return;
    }
    this.onDeviceReady();
  }

  /**
   * Internal hook when cordova reports moving from foreground to background
   */
  _onPause() {
    if (!this._pluginAvailable()) {
      return;
    }
    this.onPause();
  }

  /**
   * Internal hook when cordova reports moving from background to foreground
   */
  _onResume() {
    if (!this._pluginAvailable()) {
      return;
    }
    this.onResume();
  }

  /**
   * Hook for when cordova is ready
   */
  onDeviceReady() {
    /* Intentially empty. Intended for subclasses to optionally override */
  }

  /**
   * Hook for when app is moved from foreground to background
   */
  onPause() {
    /* Intentially empty. Intended for subclasses to optionally override */
  }

  /**
   * Hook for when app is moved from background to foreground
   */
  onResume() {
    /* Intentially empty. Intended for subclasses to optionally override */
  }

  _pluginAvailable() {
    return this.plugin() != null;
  }

  /**
   * Returns the plugin if available. This method is intended to be overridden.
   */
  plugin() {
    return get(window, this._pluginPath, null);
  }

  notifyError(error) {
    const errorHandler = logError(`${this.constructor.name}.NativePluginError`);
    if (error && error.message) {
      errorHandler(error);
    } else if (['string', 'number', 'boolean'].includes(typeof error)) {
      errorHandler(new Error(error));
    } else {
      errorHandler('Unknown error');
    }
  }

  /**
   * Whether the plugin is available or not.
   * @return {Promise}
   */
  isAvailable() {
    return this.Promise(resolve => {
      resolve(true);
    }, false);
  }

  /**
   * A simple promise wrapper. If the plugin is not compatible, the promise
   * will immediately reject/resolve to the notCompatibleResult.
   * @param   {function} promiseHandler    Normal promise handling method
   * @param   {undefined|Error|other}
   *          notCompatibleResult
   *              - null: rejects the promise with a default error
   *              - Error: rejects with the custom Error object
   *              - Other: resolves successfully with the object
   * @constructor
   */
  Promise(promiseHandler, notCompatibleResult) {
    return new Promise((resolve, reject) => {
      // Actual work to do
      const fireMethod = () => {
        if (this._pluginAvailable()) {
          promiseHandler(resolve, error => {
            this.notifyError(error);
            reject();
          });
        } else if (typeof notCompatibleResult === 'undefined') {
          reject(new Error('Plugin not found'));
        } else if (notCompatibleResult instanceof Error) {
          reject(notCompatibleResult);
        } else {
          resolve(notCompatibleResult);
        }
      };

      onCordovaDeviceReady(fireMethod);
    });
  }
}

export default NativePlugin;
