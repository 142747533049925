import { List, Map } from 'immutable';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import PasswordValidator from 'shared/utils/PasswordValidator.jsx';
import { isPhone } from 'shared/utils/FormattingUtils.jsx';
import { currentUser, isEmailValid } from 'shared/utils/UserUtils.jsx';

class UserValidator {
  constructor(user) {
    this.user = typeof user.get === 'function' ? user : Map(user);
    this.errors = new FieldErrors();
  }

  getErrors(...args) {
    if (args.length === 0) {
      return this.errors;
    }
    return this.errors.getErrors(...args);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  // FIXME: neither of these are set during User Account Creation flow
  //        which triggers `#isAgeInvalid` when creating Profiles
  // eslint-disable-next-line class-methods-use-this
  isUpdatingSelf() {
    // return this.user.get('id') === currentUser().id;
    return false;
  }

  isUpdatingClientByCustomer() {
    const theCurrentUser = currentUser();
    return (
      theCurrentUser.id &&
      !theCurrentUser.isClient() &&
      this.user.get('id') !== theCurrentUser.id
    );
  }

  isAgeInvalid() {
    return (
      this.user.get('date_of_birth') &&
      this.user.underThirteen() &&
      (this.user.get('login_enabled') || this.isUpdatingSelf())
    );
  }

  // TODO add image size validation, requires adding errorText
  // to ImageUploader component
  // https://www.pivotaltracker.com/story/show/136066563

  validate(requiredFields = List(), requireAddress = true) {
    if (!this.user.get('first_name')) {
      this.errors = this.errors.add('first_name', 'records.errors.required');
    }

    if (!this.user.get('last_name')) {
      this.errors = this.errors.add('last_name', 'records.errors.required');
    }

    const dob = this.user.get('date_of_birth');
    const isDobInvalid = dob && !dob.isValid();

    if (requiredFields.includes('date_of_birth') && !dob) {
      this.errors = this.errors.add('date_of_birth', 'records.errors.required');
    } else if (isDobInvalid) {
      this.errors = this.errors.add(
        'date_of_birth',
        'records.errors.date_invalid'
      );
    } else if (this.isAgeInvalid()) {
      if (this.isUpdatingClientByCustomer() || this.isUpdatingSelf()) {
        this.errors = this.errors.add(
          'date_of_birth',
          'records.errors.buyer_invalid_age'
        );
      } else {
        this.errors = this.errors.add(
          'date_of_birth',
          'records.errors.account_creation_invalid_age'
        );
      }
    } else if (dob && dob.isSameOrAfter(new Date())) {
      this.errors = this.errors.add(
        'date_of_birth',
        'records.errors.date_in_future'
      );
    }

    if (requiredFields.includes('gender') && !this.user.get('gender')) {
      this.errors = this.errors.add('gender', 'records.errors.required');
    }

    if (
      (requiredFields.includes('email') || this.user.get('login_enabled')) &&
      !this.user.get('email')
    ) {
      this.errors = this.errors.add('email', 'records.errors.required');
    }
    if (this.user.get('email') && !isEmailValid(this.user.get('email'))) {
      this.errors = this.errors.add('email', 'validation.email.invalid');
    }

    const pwValidator = new PasswordValidator(this.user);

    if (requiredFields.includes('password') && !this.user.get('password')) {
      pwValidator.errors = pwValidator.errors.add(
        'password',
        'records.errors.required'
      );
    }

    if (
      requiredFields.includes('password') &&
      !this.user.get('password_confirmation')
    ) {
      pwValidator.errors = pwValidator.errors.add(
        'password_confirmation',
        'records.errors.required'
      );
    }

    if (this.user.get('password')) {
      if (
        !this.user.get('current_password') &&
        this.user.get('last_login_at')
      ) {
        pwValidator.errors = pwValidator.errors.add(
          'current_password',
          'records.errors.required'
        );
      }
      pwValidator.validate();
    }
    this.errors = this.errors.merge(pwValidator.errors);

    const requiredAddressFields = [
      ['address', 'line_1'],
      ['address', 'city'],
      ['address', 'state'],
      ['address', 'postal_code'],
    ];
    if (
      requireAddress &&
      (this.user.get('address') === null || this.user.get('address')?.isBlank())
    ) {
      requiredAddressFields.forEach(field => {
        if (!this.user.getIn(field)) {
          this.errors = this.errors.add(field, 'records.errors.required');
        }
      });
    }

    if (requiredFields.includes('phone') && !isPhone(this.user.get('phone'))) {
      this.errors = this.errors.add('phone', 'records.errors.required');
    }

    return this;
  }
}

export default UserValidator;
