import * as React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Icon,
} from '@upperhand/playmaker';

import DateRangeDropdown from 'components/DateRangeDropdown';
import ActiveFilters from 'containers/reports/ordersReport/components/OrdersReportHeader/ActiveFilters.jsx';
import OrderStatistics from 'containers/reports/ordersReport/components/OrdersReportHeader/OrderStatistics.jsx';
import { Filters } from 'containers/reports/types';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { t } from 'shared/utils/LocaleUtils';

import OrderReportActions from 'containers/reports/ordersReport/Actions';
import { hasFilterDrawerDefaults } from 'containers/reports/ordersReport/utils';

function OrdersReportHeader({ defaultFilters, filters, intl, statistics }) {
  return (
    <Grid container item spacing={2} alignContent="center" justify="center">
      <Grid item xs={12}>
        <Typography className="orders-report__title" variant="h4">
          {t('.header', intl, __filenamespace)}
          <div
            data-tip={t('.tooltip', intl, __filenamespace)}
            data-for="header-tooltip"
            className="orders-report__tooltip"
            data-html
          >
            <Icon name="info" className="orders-report__tooltip_icon" />
          </div>
        </Typography>
        <ReactTooltip
          id="header-tooltip"
          className="uh-tooltip"
          effect="solid"
          place="bottom"
          multiline
          html
        />
      </Grid>

      <ResponsiveElement
        largeScreen={
          <>
            <Grid item xs={12}>
              <Grid container item spacing={2}>
                <Grid item xs={2}>
                  <DateRangeDropdown
                    label={false}
                    onChange={value =>
                      OrderReportActions.updateFilter(Filters.PERIOD, value)
                    }
                    value={
                      filters.get('period')
                        ? filters.get('period').value
                        : defaultFilters.get('period').value
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    classes={{
                      root: 'orders-report__search-field',
                    }}
                    fullWidth
                    icon="search"
                    onChange={event =>
                      OrderReportActions.updateFilter(
                        Filters.SEARCH,
                        event.target.value
                      )
                    }
                    placeholder={t('.search', intl, __filenamespace)}
                    rounded
                    value={
                      filters.get('search') || defaultFilters.get('search')
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    classes={{
                      root: 'orders-report__filter-button',
                      label: 'orders-report__header-button-label',
                    }}
                    fullWidth
                    icon="filter"
                    iconPosition="right"
                    labelAlign="space-between"
                    onClick={OrderReportActions.openFilterDrawer}
                    type="tertiary"
                    rounded
                  >
                    {t('.btn_filter', intl, __filenamespace)}
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    icon={
                      <Icon
                        name="cloudDownload"
                        className="orders-report__export-button-icon"
                      />
                    }
                    onClick={OrderReportActions.downloadList}
                    type="tertiary"
                    rounded
                    classes={{
                      root: 'orders-report__export-button',
                      label: 'orders-report__header-button-label',
                    }}
                  >
                    {t('.btn_export', intl, __filenamespace)}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {filters.size > 0 && (
              <Grid item xs={12}>
                <ActiveFilters
                  activeFilters={filters}
                  defaultFilters={defaultFilters}
                  onRemoveFilterChips={OrderReportActions.removeFilter}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <OrderStatistics statistics={statistics} />
            </Grid>
          </>
        }
        smallScreen={
          <Grid item xs={12}>
            <Grid container item spacing={1}>
              <Grid item xs>
                <TextField
                  classes={{
                    root: 'orders-report__search-field',
                  }}
                  fullWidth
                  icon="search"
                  onChange={event =>
                    OrderReportActions.updateFilter(
                      'search',
                      event.target.value
                    )
                  }
                  placeholder={t('.search', intl, __filenamespace)}
                  rounded
                  value={filters.get('search')}
                />
              </Grid>
              <Grid item>
                <Button
                  type={
                    hasFilterDrawerDefaults(filters) ? 'tertiary' : 'primary'
                  }
                  fullWidth
                  icon="filter"
                  rounded
                  onClick={OrderReportActions.openFilterDrawer}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
}

OrdersReportHeader.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
};

export default injectIntl(OrdersReportHeader);
