import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { grey } from '@mui/material/colors';

import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { hasCustomer, currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    zIndex: 500,
    backgroundColor: 'var(--color-bg-gray)',
    height: 'inherit',
    padding: '10px 36px 0',
    fontSize: '16px',
    color: grey[800],
    flexWrap: 'wrap',
    minHeight: 24,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  eventIndexLink: {
    fontWeight: 400,
    fontSize: 15,
    textTransform: 'none',
    paddingLeft: 'none',
    color: grey[800],
  },
};

class EventToolbar extends React.PureComponent {
  render() {
    const { actions: propsActions, style, intl } = this.props;

    const actions = propsActions.map((action, i) =>
      // eslint-disable-next-line react/no-array-index-key
      action ? React.cloneElement(action, { key: `action-${i}` }) : action
    );

    return (
      <Stack direction="row" style={{ ...styles.root, ...style }}>
        {hasCustomer() && (
          <ReactRouterLink
            id="event-link"
            to={customerScopedRoute('/events')}
            style={{ textDecoration: 'none' }}
          >
            <Button
              disableRipple
              startIcon={
                <ChevronLeftIcon sx={{ color: uhColors.activeBlue }} />
              }
              style={styles.eventIndexLink}
              sx={{
                padding: 0,
                '.MuiButton-startIcon': { marginRight: '4px' },
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              {t(
                currentUser().isClient() ? '.browse_events' : '.events',
                intl,
                __filenamespace
              )}
            </Button>
          </ReactRouterLink>
        )}
        <div style={{ display: 'flex' }}>{actions}</div>
      </Stack>
    );
  }
}

EventToolbar.propTypes = {
  actions: PropTypes.array,
};

EventToolbar.defaultProps = {
  actions: [],
};

export default injectIntl(EventToolbar);
