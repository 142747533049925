class StaffActions {
  constructor() {
    this.generateActions(
      'mounted',
      'listStaffSuccess',
      'listStaffError',
      'pageSelected',
      'searchUpdated'
    );
  }
}

export default alt.createActions(StaffActions);
