import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const Styles = {
  lineItemsContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  LineItem: {
    container: {
      marginTop: '1rem',
    },
    amount: {
      fontWeight: 'bold',
    },
  },
};

function LineItem({ message, amount, hideIfZero = false, suffix = '' }) {
  if (hideIfZero && amount === 0) {
    return null;
  }
  return (
    <FlexBoxJustify style={Styles.LineItem.container}>
      <span>{message}</span>
      <span style={Styles.LineItem.amount}>
        <FormattedCurrency value={amount} fromCents />
        {suffix}
      </span>
    </FlexBoxJustify>
  );
}

function MembershipLineItems({ items, intl }) {
  const lineItems = items.map(item => {
    const {
      subscribable_name: name,
      subscribable_interval_months: interval,
      subscribable_base_price: price,
    } = item.orderable;
    const messageID =
      interval === 1 ? '.membership_monthly' : '.membership_yearly';
    return (
      <LineItem
        message={name}
        amount={price}
        suffix={t(messageID, intl, __filenamespace)}
      />
    );
  });
  return <div>{lineItems}</div>;
}

function WillTokenizeMessage({ intl, visible, order }) {
  if (!visible || typeof order === 'undefined') return null;

  return (
    <div style={Styles.root}>
      <FormattedMessage id={messageId('.will_tokenize', __filenamespace)} />
      <div style={Styles.lineItemsContainer}>
        <LineItem
          message={t('.charged_now', intl, __filenamespace)}
          amount={order.chargedNow()}
        />
        <LineItem
          message={t('.charged_later_today', intl, __filenamespace)}
          amount={order.billedLaterToday()}
          hideIfZero
        />
        <MembershipLineItems items={order.recurringMemberships()} />
      </div>
    </div>
  );
}

WillTokenizeMessage.propTypes = {
  visible: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(WillTokenizeMessage);
