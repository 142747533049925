import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Alert from 'shared/components/Alert.jsx';
import PaysafeSignupActions from 'onboarding/actions/AccountSetupPaysafeActions.js';
import altContainer from 'shared/hocs/altContainer.jsx';
import AccountSetupPaysafeStore from 'onboarding/stores/AccountSetupPaysafeStore.js';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils';

function AccountSetupIncompleteAlert({ intl, showAlert, migratingFromStripe }) {
  const navigate = useNavigate();
  return (
    <Alert
      showAlert={showAlert}
      intl={intl}
      message={
        <FormattedMessage
          id={messageId('.message', __filenamespace)}
          values={{ migratingFromStripe }}
        />
      }
      actionName={
        <FormattedMessage
          id={messageId('.complete_account', __filenamespace)}
        />
      }
      onCancel={PaysafeSignupActions.closeAlert}
      onClick={() => {
        if (navigate) {
          navigate(customerScopedRoute('/complete_account'));
          PaysafeSignupActions.closeAlert();
        } else {
          redirectTo({
            path: customerScopedRoute('complete_account'),
          });
        }
      }}
    />
  );
}

export default compose(
  injectIntl,
  altContainer({
    store: AccountSetupPaysafeStore,
  })
)(AccountSetupIncompleteAlert);
