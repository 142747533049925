class WaitlistActions {
  constructor() {
    this.generateActions(
      'clientListError',
      'clientListSuccess',
      'mounted',
      'waitlistFetchError',
      'waitlistFetchSuccess',
      'waitlistInvite',
      'waitlistInviteError',
      'waitlistInviteSuccess'
    );
  }
}

export default alt.createActions(WaitlistActions);
