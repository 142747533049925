/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { redirectTo } from 'shared/utils/RouteUtils';
import ChargifySignUpActions from './Actions';

class ChargifySignUpStore extends UpperHandStore {
  constructor() {
    super();

    this.isLoading = true;
    this.errorMessage = '';

    this.bindListeners({
      handleChargifySignUp: ChargifySignUpActions.signUp,
      handleChargifySignUpSuccess: ChargifySignUpActions.signUpSuccess,
      handleChargifySignUpError: ChargifySignUpActions.signUpError,
    });
  }

  handleChargifySignUp([
    chargify_subscription_id,
    chargify_customer_id,
    chargify_product_id,
  ]) {
    this.isLoading = true;

    uhApiClient.post({
      url: 'customers/chargify_signup',
      data: JSON.stringify({
        chargify_subscription_id,
        chargify_customer_id,
        chargify_product_id,
      }),
      success: ChargifySignUpActions.signUpSuccess,
      error: ChargifySignUpActions.signUpError,
    });
  }

  handleChargifySignUpSuccess({ random }) {
    redirectTo({ path: `/accounts/new?token=${random}` });
  }

  handleChargifySignUpError(...args) {
    this.isLoading = false;
    this.errorMessage = JSON.parse(
      args[0].response.responseText
    ).response.message;
    this.notifyError('Error creating chargify customer: ', args);
  }
}
export default alt.createStore(ChargifySignUpStore, 'ChargifySignUpStore');
