export default alt.generateActions(
  'closePrintSettings',
  'closeConfirmation',
  'changeView',
  'error',
  'fetch',
  'listFinished',
  'loadPrinters',
  'openPrintSettings',
  'openConfirmation',
  'printFinished',
  'printLabel',
  'printReceipt',
  'printTestLabel',
  'printTestReceipt',
  'readPrinterStatus',
  'setDefaultPrinter',
  'setupWifi',
  'statusFinished',
  'successFetch',
  'update',
  'updatePrinterSN',
  'updatePrinterSettings',
  'updateWifiSettings'
);
