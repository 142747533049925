import React from 'react';
import { Button, Grid, Icon, Spinner, TextField } from '@upperhand/playmaker';

import StaffCard from 'containers/contacts/contactsStaff/components/StaffCard.jsx';
import StaffDetailsDrawer from 'contacts/index/components/StaffDetailsDrawer.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import Paginator from 'shared/components/Paginator.jsx';

import { StaffDataStore, AvailabilityScheduleDataStore } from 'dataStores';
import ContactsArchivedStaffActions from './Actions';
import StaffArchivedStore from './Store';

const onSearchChange = (_event, value) =>
  ContactsArchivedStaffActions.searchUpdated(value);

function ContactsArchivedStaff({
  staffDataStore: { staff: staffMap },
  availabilityScheduleDataStore: { availabilitySchedules },
  staffArchivedStore: { staffIds, page, perPage, totalCount, search, loading },
}) {
  React.useEffect(() => {
    ContactsArchivedStaffActions.mounted();
  }, []);

  return (
    <div style={{ paddingTop: 16 }}>
      <Grid container spacing={1} alignItems="center">
        <div className="contacts__add-button">
          <Button
            onClick={InviteDialogActions.openInviteDialog}
            rounded
            icon={<Icon name="add" className="contacts__add-button-icon" />}
            size="2x"
          />
        </div>
        <Grid item xs>
          <TextField
            fullWidth
            rounded
            outline={false}
            icon="search"
            placeholder="Search"
            value={search}
            onChange={onSearchChange}
          />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', marginTop: 100 }}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <>
            <Grid item container spacing={1} className="contacts__staff-list">
              {staffIds.map(id => {
                const staff = staffMap.get(id);

                return (
                  <Grid item xs={12} key={staff}>
                    <StaffCard
                      staff={staff}
                      availabilitySchedule={availabilitySchedules.get(
                        staff.availability_schedule_id
                      )}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Grid item xs={12}>
              <Paginator
                className="contacts__pagination"
                currentPage={page}
                perPage={perPage}
                totalCount={totalCount}
                onPageSelect={ContactsArchivedStaffActions.pageSelected}
                pageLimit={0}
                showInfo
              />
            </Grid>
          </>
        )}
      </Grid>
      <StaffDetailsDrawer />
    </div>
  );
}

export default altContainer({
  stores: {
    staffDataStore: StaffDataStore,
    staffArchivedStore: StaffArchivedStore,
    availabilityScheduleDataStore: AvailabilityScheduleDataStore,
  },
})(ContactsArchivedStaff);
