import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { grey, green } from '@mui/material/colors';

import CurrencySymbol from 'shared/components/CurrencySymbol.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { boldText } from 'shared/styles/uhStyles.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  optionRow: {
    padding: '8px 10px',
  },

  dark: {
    backgroundColor: grey[100],
  },

  planCard: {
    border: '2px solid #CACBCA',
    padding: '15px 10px',
  },
};

function PlanCard({ plan, feePlan, style, intl }) {
  let planItem = plan;

  if (!planItem.fee) {
    planItem = planItem.calculateFees(feePlan);
  }

  return (
    <div style={merge(styles.planCard, style)}>
      <div
        style={merge(boldText, {
          marginBottom: 15,
          marginLeft: 8,
          fontSize: 15,
        })}
      >
        {planItem.get('name')}
      </div>

      {planItem.get('payments').map(payment => (
        <div style={{ fontSize: 14 }}>
          <FlexBoxJustify style={styles.optionRow}>
            <div style={boldText}>
              {payment.get('date')
                ? moment
                    .tz(payment.get('date'), customerTZ())
                    .format('MM-DD-YY')
                : null}
            </div>
            <FormattedCurrency value={payment.get('amount')} fromCents />
          </FlexBoxJustify>
          {!feePlan.interchange_plus && currentCustomer().display_fees && (
            <FlexBoxJustify style={merge(styles.optionRow, styles.dark)}>
              <FormattedMessage
                id={messageId('.processing_fee', __filenamespace)}
                values={{
                  amount: intl.formatNumber(payment.get('fee') / 100, {
                    style: 'currency',
                    currency: 'USD',
                  }),
                }}
              />
              <div style={boldText}>
                <FormattedCurrency
                  value={payment.get('amountLessFees')}
                  fromCents
                />
              </div>
            </FlexBoxJustify>
          )}
        </div>
      ))}
      {currentCustomer().display_fees && (
        <FlexBoxJustify
          style={merge(boldText, { margin: '15px 8px 0 8px', fontSize: 15 })}
        >
          {feePlan.interchange_plus ? (
            <FormattedMessage
              id={messageId('.interchange_total', __filenamespace)}
            />
          ) : (
            <FormattedMessage
              id={messageId('.total_earnings', __filenamespace)}
            />
          )}
          <div>
            <span style={{ color: green.A400 }}>
              <CurrencySymbol currency="USD" />
              &nbsp;
            </span>
            <FormattedCurrency
              value={planItem.get('amountLessFees')}
              fromCents
              showSymbol={false}
            />
          </div>
        </FlexBoxJustify>
      )}
    </div>
  );
}

function RecurringPaymentPlanDetailsCard({ atd, feePlan, intl }) {
  return (
    <div>
      <div style={merge(boldText, { marginBottom: 15, fontSize: 16 })}>
        <FormattedMessage
          id={messageId('.recurring_payments', __filenamespace)}
        />
      </div>

      {atd.template_parameters
        .map(plan => plan.calculateFees(feePlan))
        .sortBy(plan => plan.get('amountLessFees'))
        .map(plan => (
          <PlanCard
            plan={plan}
            feePlan={feePlan}
            style={{ marginBottom: 10 }}
            intl={intl}
          />
        ))}
    </div>
  );
}

export default injectIntl(RecurringPaymentPlanDetailsCard);
