import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import Task from 'shared/records/Task';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = task => (task.id ? `tasks/${task.id}` : 'tasks');

export default class TaskEditingStoreInterface extends UpperHandStore {
  constructor(actions, successMessage) {
    super();

    this.task = null;
    this.isSaving = false;

    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      updateTask: this.actions.TASK_UPDATED,

      beginEdit: this.actions.BEGIN,
      cancelEdit: this.actions.CANCEL,

      createOrUpdate: this.actions.SAVE,
      createOrUpdateSuccess: this.actions.SAVE_SUCCESS,
      createOrUpdateError: this.actions.SAVE_ERROR,
    });
  }

  updateTask(updatedFields) {
    if (!this.task) {
      return false;
    }

    this.task = this.task.merge(updatedFields);
  }

  beginEdit(task) {
    this.task = task || new Task();
  }

  cancelEdit() {
    this.task = null;
  }

  createOrUpdate() {
    if (this.isSaving) {
      return false;
    }

    this.task = this.task.validate();

    if (this.task.isValid()) {
      this.isSaving = true;

      const saveValue = {
        url: url(this.task),
        data: JSON.stringify({ attributes: this.task.toServer() }),
        success: this.actions.saveSuccess,
        error: this.actions.saveError,
      };

      if (this.task.id) {
        uhApiClient.put(saveValue);
      } else {
        uhApiClient.post(saveValue);
      }
    }
  }

  createOrUpdateSuccess(_data) {
    this.task = null;
    this.isSaving = false;

    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError() {
    this.isSaving = false;
    this.notifyError('error updating task', arguments);
  }
}
