import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  button: {
    backgroundColor: uhColors.aliceBlue,
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    fontSize: 15,
  },

  label: {
    fontSize: 15,
  },
};

function AddButton({ label, style, ...rest }) {
  return (
    <Button
      style={merge(styles.button, styles.label, style)}
      startIcon={<AddIcon sx={{ color: uhColors.activeBlue }} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {label}
    </Button>
  );
}

export default AddButton;
