import CommissionsEditingActions from 'reporting/payroll/actions/Commissions/CommissionsEditingActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import CommissionsEditingStoreInterface from 'reporting/payroll/stores/Commissions/CommissionsEditingStoreInterface';

export default alt.createStore(
  CommissionsEditingStoreInterface,
  'CommissionsEditingStore',
  {
    actions: CommissionsEditingActions,
    successMessage: new TranslatableMessage({
      id: '.updated',
      filename: __filenamespace,
    }),
  }
);
