import * as React from 'react';
import PropTypes from 'prop-types';
import Header from 'calendar/components/date_picker/_Header.jsx';
import Dates from 'calendar/components/date_picker/_Dates.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

import { smallScreen } from 'shared/utils/DOMUtils';

const styles = {
  PickerCalWrapper: {
    alignItems: 'stretch',
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: '15px',
    width: '479px',
  },
  PickerCalWrapperMobile: {
    display: 'block',
    fontSize: '13px',
    width: '100vw',
  },
  PickerCalendar: {
    backgroundColor: '#F6F7F9',
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: '65%',
    maxWidth: '300px',
    padding: '16px',
    textAlign: 'center',
  },
  PickerCalMobile: {
    width: '100%',
    maxWidth: 'initial',
  },
};

class PickerCalendar extends React.PureComponent {
  static propTypes = {
    currentDate: PropTypes.object.isRequired,
    dayTooltip: PropTypes.func.isRequired,
    onSelectDate: PropTypes.func.isRequired,
    shouldSelectDate: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line
  state = { currentStateDate: this.props.currentDate };

  nextMonth(e) {
    e.preventDefault();
    this.setState((prevState, _) => ({
      currentStateDate: prevState.currentStateDate.clone().add(1, 'month'),
    }));
  }

  previousMonth(e) {
    e.preventDefault();
    this.setState((prevState, _) => ({
      currentStateDate: prevState.currentStateDate.clone().subtract(1, 'month'),
    }));
  }

  render() {
    const {
      currentDate,
      dayTooltip,
      onSelectDate,
      shouldSelectDate,
    } = this.props;
    const { currentStateDate } = this.state;
    const isMobile = smallScreen();

    return (
      <div
        className="PickerCalendar"
        style={merge(
          styles.PickerCalWrapper,
          isMobile ? styles.PickerCalWrapperMobile : {}
        )}
      >
        <div
          style={merge(
            styles.PickerCalendar,
            isMobile ? styles.PickerCalMobile : {}
          )}
        >
          <Header
            currentDate={currentStateDate}
            onPreviousMonth={e => {
              this.previousMonth(e);
            }}
            onNextMonth={e => {
              this.nextMonth(e);
            }}
          />
          <Dates
            currentDate={currentStateDate}
            onSelectDate={onSelectDate}
            selectedDate={currentDate.clone()}
            shouldSelectDate={shouldSelectDate}
            dayTooltip={dayTooltip}
          />
        </div>
      </div>
    );
  }
}

export default PickerCalendar;
