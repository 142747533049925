import { DefaultSourceActions } from './DefaultSourceActionList';

class PaymentPlanActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'paymentSuccess',
      'paymentError',
      'waiveSuccess',
      'waiveError'
    );
  }
}

export default alt.createActions(PaymentPlanActions);
