import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import Discount from 'shared/records/Discount';

import altContainer from 'shared/hocs/altContainer.jsx';
import AccountCreditsForm from 'shared/components/checkout/AccountCreditsForm.jsx';
import AddRemoveQuickDiscount from 'shared/components/checkout/_AddRemoveQuickDiscount.jsx';
import CheckoutClientBox from 'calendar/components/quick_schedule/CheckoutClientBox.jsx';
import CheckoutEventBox from 'calendar/components/quick_schedule/CheckoutEventBox.jsx';
import CheckoutSummary from 'calendar/components/quick_schedule/CheckoutSummary.jsx';
import CouponSummary from 'shared/components/checkout/CouponSummary.jsx';
import DialogHeader from 'shared/components/_DialogHeader.jsx';
import OrderTotals from 'shared/components/checkout/OrderTotals.jsx';
import TaxExemptToggle from 'shared/components/checkout/TaxExemptToggle.jsx';
import ServiceFeeExemptToggle from 'shared/components/checkout/ServiceFeeExemptToggle.jsx';
import QuickDiscountForm from 'shared/components/checkout/QuickDiscountForm.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { sumInt } from 'shared/utils/ImmutableUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';

import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';

import {
  Payment,
  PaymentActions,
  PaymentStore,
  PaymentType,
} from 'containers/payments';

const styles = {
  Body: {
    padding: '0 24px 24px 24px',
    maxHeight: '90%',
  },
  Divider: {
    width: '100%',
    height: '1px',
    margin: '8px 0',
    marginTop: 12,
    backgroundColor: uhColors.lighterGrey,
  },
  Title: {
    fontSize: '1.1625em',
    display: 'inline',
    textTransform: 'capitalize',
  },
  Toolbar: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    borderRadius: '5px 5px 0 0',
  },
  Discounts: {
    margin: '16px 0',
    gap: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  DiscountToggler: {
    justifyContent: 'flex-end',
  },
};

const closeScheduler = () => {
  QuickScheduleActions.deleteOrderItem();
  PaymentActions.reset();
};

const closeSchedulerOnCompletion = () => {
  QuickScheduleActions.close.defer();
  PaymentActions.reset.defer();
};

const onApplyDiscount = () => {
  QuickScheduleActions.manageDiscount(true);
};

const onRemoveDiscount = () => {
  QuickScheduleActions.manageDiscount();
};

const onDiscountValueChange = (discountValue, type) => {
  const value = parseFloat(discountValue);
  if (type === 'percent' && (value > 100 || value < 0)) {
    return;
  }
  QuickScheduleActions.updateManualDiscount(
    new Discount({ value: value || 0, type })
  );
};

const showDiscountForm = () => {
  QuickScheduleActions.updateManualDiscount(new Discount());
};

const hideDiscountForm = () => {
  QuickScheduleActions.updateManualDiscount();
};

const onDiscountTypeChange = type => {
  QuickScheduleActions.updateManualDiscount(new Discount({ value: 0, type }));
};

function Header({ onCloseScheduler, processingPayment }) {
  return (
    <DialogHeader
      closeDialog={onCloseScheduler}
      toolbarStyle={styles.Toolbar}
      title={
        <div style={styles.Title}>
          <FormattedMessage id={messageId('.pay_balance', __filenamespace)} />
        </div>
      }
      disabled={processingPayment}
    />
  );
}

function WrappedCheckout({ intl, quickScheduleStore, paymentStore }) {
  const {
    clientMembership,
    couponIsLoading,
    dateRange,
    isOrderSuccess,
    managingCustomerUserId,
    managingCustomerUserVault,
    order,
    orderId,
    quickSchedulingStep,
    selectedClient,
    selectedEvent,
    taxExempt,
    orderItem,
    isQuickDiscountApplied,
  } = quickScheduleStore;
  const { processingFields, processingPayment, paymentMethod } = paymentStore;
  const isSummaryStep = quickSchedulingStep === 'summary';
  const allAdjustments = order.order_items.flatMap(i => i.applied_adjustments);

  const appliedAccountCredits = allAdjustments
    .filter(a => a.isAccountCredit())
    .reduce(sumInt('amount'), 0);

  return (
    <div>
      <Header
        onCloseScheduler={() => {
          if (processingPayment || processingFields) {
            // do nothing
          } else if (isSummaryStep) {
            closeSchedulerOnCompletion();
          } else {
            closeScheduler();
          }
        }}
        processingPayment={processingPayment}
      />
      <div style={styles.Body}>
        <CheckoutClientBox
          origin="quickschedule"
          selectedClient={selectedClient}
          clientMembership={clientMembership}
        />
        <CheckoutEventBox
          selectedEvent={selectedEvent}
          dateRange={dateRange}
          isOrderSuccess={isOrderSuccess}
          order={order}
        />
        {isSummaryStep ? (
          <CheckoutSummary
            onCloseScheduler={closeSchedulerOnCompletion}
            selectedClient={selectedClient}
            paymentMethod={paymentMethod}
            orderId={orderId}
          />
        ) : (
          <div>
            <div style={{ margin: 0 }}>
              <CouponSummary
                order={order}
                onCouponApply={QuickScheduleActions.applyCoupon}
                loading={couponIsLoading}
              />
              <div style={styles.Discounts}>
                {(orderItem.get('manual_discount') ||
                  isQuickDiscountApplied) && (
                  <QuickDiscountForm
                    onApplyDiscount={onApplyDiscount}
                    onDiscountTypeChange={type => onDiscountTypeChange(type)}
                    onDiscountValueChange={(_, value) =>
                      onDiscountValueChange(
                        value,
                        orderItem.get('manual_discount').type
                      )
                    }
                    item={orderItem}
                    isQuickDiscountApplied={isQuickDiscountApplied}
                  />
                )}
                <FlexBox style={styles.DiscountToggler}>
                  <AddRemoveQuickDiscount
                    item={orderItem}
                    expanded={Boolean(
                      orderItem.get('manual_discount') || isQuickDiscountApplied
                    )}
                    onAdd={showDiscountForm}
                    onRemove={
                      isQuickDiscountApplied
                        ? onRemoveDiscount
                        : hideDiscountForm
                    }
                  />
                </FlexBox>
                {orderItem.get('account_credit_amount') !== null && (
                  <AccountCreditsForm
                    item={orderItem}
                    appliedAccountCredits={appliedAccountCredits}
                    onApply={QuickScheduleActions.applyAccountCredits}
                  />
                )}
                <FlexBox style={styles.DiscountToggler}>
                  <AddRemoveQuickDiscount
                    item={orderItem}
                    expanded={orderItem.get('account_credit_amount') !== null}
                    addLabel={t('.apply_account_credit', intl, __filenamespace)}
                    removeLabel={t(
                      '.remove_account_credit',
                      intl,
                      __filenamespace
                    )}
                    onAdd={() => QuickScheduleActions.accountCreditsChanged(0)}
                    onRemove={() =>
                      QuickScheduleActions.accountCreditsChanged(null)
                    }
                  />
                </FlexBox>
              </div>
              <OrderTotals order={order} style={{ marginBottom: 20 }} />
            </div>
            <TaxExemptToggle
              order={{ tax_exempt: taxExempt }}
              style={{ margin: 0 }}
              onTaxExemptToggle={QuickScheduleActions.toggleTaxExemption}
              onTaxIdChange={QuickScheduleActions.taxIdUpdated}
              disabled={processingFields}
            />
            <ServiceFeeExemptToggle
              order={order}
              onFeeExemptToggle={QuickScheduleActions.toggleFeeExemption}
            />
            <Divider style={styles.Divider} />
            <Payment
              itemId={orderId}
              total={Number(order.total) > 0 ? order.total : 0}
              order={order}
              paymentType={PaymentType.ORDER}
              hasAccess={
                selectedClient.managed_by_id
                  ? managingCustomerUserVault
                  : selectedClient.access_to_vault
              }
              purchaserId={managingCustomerUserId}
              purchasingUserId={
                selectedClient.managed_by_id
                  ? selectedClient.managed_by_id
                  : selectedClient.user_id
              }
              successAction={QuickScheduleActions.checkoutSuccess.defer}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      paymentStore: PaymentStore,
    },
  })
)(WrappedCheckout);
