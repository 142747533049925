import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { List } from 'immutable';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import altContainer from 'shared/hocs/altContainer.jsx';
import { MembershipDataStore } from 'dataStores';

import ExpandablePaymentPlanSummary from 'cart/index/components/ExpandablePaymentPlanSummary.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ItemSummaryHeader from 'shared/components/checkout/_ItemSummaryHeader.jsx';
import MembershipDiscount from 'cart/index/components/_MembershipDiscount.jsx';
import OrderItem from 'shared/records/OrderItem';
import RegistrationFormDrawer from 'cart/index/components/_RegistrationFormDrawer.jsx';
import SessionList from 'cart/index/components/_SessionList.jsx';
import ManageOrderItem from 'shared/components/checkout/_ManageOrderItem.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';

const styles = {
  button: {
    margin: '0 0 1.5rem 0',
    height: 50, // MUI hardcodes a PX unit for height. See https://github.com/callemall/material-ui/issues/4473
  },

  buttonLabel: {
    fontSize: 16,
  },

  boldText: {
    fontWeight: 'bold',
  },

  divider: {
    height: '2px',
    marginBottom: '15px',
  },

  link: {
    color: uhColors.activeBlue,
  },

  primaryText: {
    fontWeight: 'bold',
    marginBottom: 4,
    fontSize: 16,
  },

  quantityAndTotal: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },

  registrationButton: {
    marginBottom: '15px',
    minHeight: 30,
    fontSize: 13,
  },

  registrationCompletedButton: {
    backgroundColor: uhColors.fadedBlue,
  },

  registrationButtonLabel: {
    fontSize: 13,
  },

  row: {
    padding: '5px 0 5px 25px',
    fontWeight: 'bold',
  },

  secondaryText: {
    color: '#807F7F',
    fontWeight: 'bold',
    marginBottom: 15,
  },

  strikethrough: {
    textDecoration: 'line-through',
  },
};

const RegistrationButton = injectIntl(
  ({
    registrationId,
    eventId,
    intl,
    registrationFormActions,
    registrationForms,
  }) => {
    const eventRegistrationExists = id => {
      const fields = registrationForms.selectedFields.get(id);
      return !!fields && fields.size > 0;
    };

    if (eventRegistrationExists(eventId)) {
      const showCompleted =
        registrationForms.shouldShowCompleted(registrationId);
      const buttonLabel = showCompleted
        ? t('.form_complete', intl, __filenamespace)
        : t('.fill_out_event_form', intl, __filenamespace);

      return (
        <Button
          fullWidth
          variant={showCompleted ? 'outlined' : 'contained'}
          sx={styles.registrationButton}
          startIcon={
            showCompleted && <CheckCircleIcon sx={{ color: uhColors.green }} />
          }
          onClick={() => registrationFormActions.start(eventId, registrationId)}
        >
          {buttonLabel}
        </Button>
      );
    }
    return null;
  }
);

function MembershipDiscountInfo({ adjustment, customerUsers, memberships }) {
  return (
    <div>
      {adjustment.details.map(details => {
        const customerUser = customerUsers.find(
          c => c.id === details.get('customer_user_id')
        );

        if (!customerUser) {
          return null;
        }

        const membership = memberships.get(customerUser.active_membership_id);

        if (!membership) {
          return null;
        }

        return (
          <MembershipDiscount
            key={customerUser.id}
            membership={membership}
            customerUser={customerUser}
            discount={details.get('membership_event_discount').discount}
            discountAmount={details.get('discount_amount')}
          />
        );
      })}
    </div>
  );
}

function CouponDiscountInfo({ adjustment }) {
  return (
    <FlexBoxJustify style={{ marginBottom: 15, fontWeight: 'bold' }}>
      <div>{adjustment.details.name}</div>
      <FormattedCurrency value={adjustment.amount} fromCents />
    </FlexBoxJustify>
  );
}

function DiscountInfo({ adjustment, customerUsers, memberships }) {
  if (adjustment.isCoupon()) {
    return <CouponDiscountInfo adjustment={adjustment} />;
  }
  if (adjustment.isMembershipEventDiscount()) {
    return (
      <MembershipDiscountInfo
        adjustment={adjustment}
        customerUsers={customerUsers}
        memberships={memberships}
      />
    );
  }
  return null;
}

function ProrateInfo({ fullPrice, prorate }) {
  return (
    <div style={{ marginBottom: 15 }}>
      <FlexBoxJustify style={styles.row}>
        <FormattedMessage id={messageId('.full_price', __filenamespace)} />
        <div style={styles.strikethrough}>
          <FormattedCurrency value={fullPrice} fromCents />
        </div>
      </FlexBoxJustify>
      <FlexBoxJustify style={styles.row}>
        <FormattedMessage id={messageId('.prorate', __filenamespace)} />
        <FormattedCurrency value={prorate} fromCents />
      </FlexBoxJustify>
    </div>
  );
}

const OpenBookingSummary = injectIntl(
  ({
    appliedAccountCredits,
    athletes,
    item,
    memberships,
    onEdit,
    onRemove,
    onView,
    purchased,
    registrationFormActions,
    registrationForms,
    showScheduleButton,
    intl,
  }) => {
    const scheduledQty = item.getIn(['orderable', 'tentative_details']).size;
    const totalQty = item.getIn(['orderable', 'quantity']);

    const shouldShowSchedulingInfo =
      purchased &&
      (!currentUser().isClient() ||
        !currentCustomer().disable_client_scheduling);

    return (
      <div key={`checkout-${item.id}`} style={{ marginBottom: '1rem' }}>
        <ItemSummaryHeader
          item={item}
          purchased={purchased}
          onRemove={onRemove}
        />

        {item.applied_adjustments.map(adjustment => (
          <DiscountInfo
            key={adjustment.type}
            adjustment={adjustment}
            customerUsers={athletes}
            memberships={memberships}
          />
        ))}

        <Divider sx={styles.divider} />

        <FlexBoxJustify style={styles.quantityAndTotal}>
          <FormattedMessage
            id={messageId('.qty', __filenamespace)}
            values={{ n: item.display_quantity }}
          />
          <FormattedCurrency
            value={item.totalBeforeManualDiscount()}
            fromCents
          />
        </FlexBoxJustify>

        {shouldShowSchedulingInfo && (
          <FlexBoxJustify style={{ marginBottom: '1rem' }}>
            <div style={styles.boldText}>
              <FormattedMessage id={messageId('.scheduled', __filenamespace)} />
            </div>
            <div>{`${scheduledQty} of ${totalQty}`}</div>
          </FlexBoxJustify>
        )}

        {scheduledQty < totalQty && purchased && showScheduleButton && (
          <Button
            fullWidth
            href={`${item.show_path}#sdo`}
            variant="contained"
            sx={styles.button}
          >
            {t('.schedule_sessions', intl, __filenamespace)}
          </Button>
        )}

        <RegistrationButton
          registrationId={item.orderable.id}
          eventId={item.orderable.event_id}
          registrationForms={registrationForms}
          registrationFormActions={registrationFormActions}
        />
        <ManageOrderItem
          item={item}
          purchased={purchased}
          appliedAccountCredits={appliedAccountCredits}
          onEdit={onEdit}
          onView={onView}
        />
      </div>
    );
  }
);

function FixedScheduleSummary({
  appliedAccountCredits,
  athletes,
  automation,
  item,
  memberships,
  onEdit,
  onRemove,
  onView,
  optionUuid,
  purchased,
  registrationFormActions,
  registrationForms,
}) {
  const isRecurringPayment =
    automation && automation.template_type === 'RecurringPaymentPlanTemplate';
  const listPrice =
    item.orderable.full_price / item.orderable.quantity_remaining;
  const eventStartDate = item.secondary_display_accent_text.split(' ')[0];
  const showProrateInfo =
    item.orderable.full_price !== item.subtotal &&
    moment().isSameOrAfter(moment(eventStartDate));

  return (
    <div key={`checkout-${item.id}`} style={{ marginBottom: '1rem' }}>
      <ItemSummaryHeader
        item={item}
        purchased={purchased}
        onRemove={onRemove}
      />

      {item.applied_adjustments.map(adjustment => (
        <DiscountInfo
          key={adjustment.type}
          adjustment={adjustment}
          customerUsers={athletes}
          memberships={memberships}
        />
      ))}

      {showProrateInfo && (
        <ProrateInfo fullPrice={listPrice} prorate={item.total} />
      )}

      {item.orderable.single_session_price && item.orderable.registrations && (
        <SessionList
          registrations={item.orderable.registrations}
          fullPrice={item.orderable.all_days_price}
          singleSessionPrice={item.orderable.single_session_price}
        />
      )}

      {isRecurringPayment && (
        <ExpandablePaymentPlanSummary
          plan={automation.getPlan(optionUuid)}
          quantity={item.display_quantity}
          adjustedPayments={item.adjusted_payments}
          style={{ marginBottom: 15 }}
        />
      )}

      <Divider sx={styles.divider} />

      <FlexBoxJustify style={styles.quantityAndTotal}>
        <FormattedMessage
          id={messageId('.qty', __filenamespace)}
          values={{ n: item.display_quantity }}
        />
        <FormattedCurrency value={item.totalBeforeManualDiscount()} fromCents />
      </FlexBoxJustify>

      <RegistrationButton
        registrationId={item.orderable.id}
        eventId={item.orderable.event_id}
        registrationForms={registrationForms}
        registrationFormActions={registrationFormActions}
      />
      <ManageOrderItem
        item={item}
        purchased={purchased}
        appliedAccountCredits={appliedAccountCredits}
        onEdit={onEdit}
        onView={onView}
      />
    </div>
  );
}

class ItemSummaryListItem extends React.Component {
  componentDidMount() {
    const {
      item: { orderable },
      registrationFormActions,
    } = this.props;
    // This is dangerous. If we fail to mount, we spiral into an infinite loop
    // requesting registrations until we crash the tab.
    registrationFormActions.stage.defer(
      orderable.event_id,
      orderable.client_ids,
      orderable.id
    );
  }

  componentDidUpdate({ item: prevItem }) {
    const { item, registrationFormActions } = this.props;
    const { account_credit_amount: prevAmount } = prevItem;
    const { account_credit_amount: amount } = item;
    const shouldRefetch =
      (prevAmount !== amount && amount) || (amount === null && prevAmount);

    if (shouldRefetch) {
      const { orderable } = item;

      registrationFormActions.stage.defer(
        orderable.event_id,
        orderable.client_ids,
        orderable.id
      );
    }
  }

  render() {
    const { item } = this.props;

    if (
      item.orderable.schedule_type === SCHEDULE_TYPE.fixedSchedule ||
      item.orderable.schedule_type === SCHEDULE_TYPE.teamSchedule
    ) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <FixedScheduleSummary {...this.props} />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <OpenBookingSummary {...this.props} />;
  }
}

ItemSummaryListItem.propTypes = {
  showScheduleButtons: PropTypes.bool,
  item: PropTypes.instanceOf(OrderItem).isRequired,
  registrationFormActions: PropTypes.object.isRequired,
};

ItemSummaryListItem.defaultProps = {
  showScheduleButtons: true,
};

// TODO: Only traverse the automation list once
function RegistrationPackageSummary({
  appliedAccountCredits,
  athletes,
  automations,
  item,
  membershipDataStore: { memberships },
  onEdit,
  onRemove,
  onView,
  purchased,
  registrationFormActions,
  registrationFormDrawerDirection,
  registrationForms,
  showScheduleButtons,
}) {
  const registrantIds = item
    .getIn(['orderable', 'registrations'], List())
    .map(cr => cr.customer_user_id)
    .filter(id => !!id);

  return (
    <div key={item.id}>
      <ItemSummaryListItem
        key={item.id}
        athletes={athletes}
        item={item}
        memberships={memberships}
        onEdit={onEdit}
        onRemove={onRemove}
        onView={onView}
        purchased={purchased}
        registrationForms={registrationForms}
        registrationFormActions={registrationFormActions}
        showScheduleButton={showScheduleButtons}
        automation={automations.find(
          a => a.id === item.orderable.automation_template_description_id
        )}
        optionUuid={item.orderable.automation_option_uuid}
        appliedAccountCredits={appliedAccountCredits}
      />
      <RegistrationFormDrawer
        athletes={athletes}
        eventId={item.orderable.event_id}
        registrantIds={registrantIds}
        title={item.primary_display_text}
        registrationFormActions={registrationFormActions}
        direction={registrationFormDrawerDirection}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...registrationForms}
      />
    </div>
  );
}

export default altContainer({
  stores: {
    membershipDataStore: MembershipDataStore,
  },
})(RegistrationPackageSummary);
