import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';

import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils';

function FilterButton({ intl, large, style, filterActions }) {
  const handleToggleFilterDrawer = e => {
    e.preventDefault();
    filterActions.toggleFilterDrawer();
  };
  if (large) {
    return (
      <Button
        color="default"
        onClick={handleToggleFilterDrawer}
        sx={{ color: uhColors.charcoalBlack, style }}
        startIcon={<FilterListIcon sx={{ color: uhColors.iconGrey }} />}
      >
        {t('.filter', intl, __filenamespace)}
      </Button>
    );
  }
  return (
    <IconButton sx={style} onClick={handleToggleFilterDrawer}>
      <FilterListIcon sx={{ color: uhColors.iconGrey }} />
    </IconButton>
  );
}

FilterButton.propTypes = {
  filterActions: PropTypes.object,
  style: PropTypes.object,
  large: PropTypes.bool,
};

FilterButton.defaultProps = {
  filterActions: FilterActions,
  style: {},
  large: false,
};

export default FilterButton;
