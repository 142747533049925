import * as React from 'react';
import AltContainer from 'alt-container';
import ReactTooltip from 'react-tooltip';
import { Map } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';

import AvailableStaffStore from 'event_mgmt/editing/stores/AvailableStaffStore.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import EventResourceDrawer from 'event_mgmt/editing/components/_EventResourceDrawer.jsx';
import EventResourceStore from 'event_mgmt/shared/stores/EventResourceStore.js';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import Schedule from 'event_mgmt/shared/records/Schedule.jsx';
import StaffPreferenceMenu from 'event_mgmt/editing/components/_StaffPreferenceMenu.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import StaffIcon from 'shared/components/icons/Staff.jsx';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxJustify,
} from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  literal: `
    .ResourceColumn .ResourceCard .StaffPreferenceButton {
      visibility: hidden !important;
    }

    .ResourceColumn .ResourceCard:hover .StaffPreferenceButton {
      visibility: visible !important;
    }

    .sortableHelper {
      z-index: 1500;
    }
  `,
};

function ResourceCard({ resource, available, style, id, index, onSortEnd }) {
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (dragIndex !== hoverIndex) {
        // eslint-disable-next-line no-param-reassign
        item.index = hoverIndex;
        onSortEnd({ oldIndex: dragIndex, newIndex: hoverIndex });
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!resource) {
    return <li />;
  }

  const opacity = isDragging ? 0.3 : 1;

  drag(drop(ref));

  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      style={{ listStyle: 'none', cursor: 'move', opacity }}
    >
      <Paper
        className="ResourceCard"
        style={merge(
          {
            borderLeft: `2px solid ${
              available ? uhColors.green : uhColors.darkRed
            }`,
            padding: 12,
          },
          style
        )}
      >
        <FlexBox>
          <DragHandleIcon
            style={{ flex: '0 0 auto', color: uhColors.iconGrey }}
          />
          <div style={{ marginLeft: 10, flex: '1 1 auto', fontSize: 15 }}>
            <FlexBoxJustify style={{ alignItems: 'center' }}>
              <FlexBoxCenter>
                <ResourceIcon
                  color={uhColors.iconLightGrey}
                  style={{ height: 18, width: 18 }}
                />
                <div style={{ fontWeight: 'bold', marginLeft: 10 }}>
                  {resource.name}
                </div>
              </FlexBoxCenter>

              <FlexBoxCenter>
                <StaffPreferenceMenu resource={resource} />
                <IconButton
                  onClick={() => EventActions.resourceRemoved(resource)}
                  style={{ padding: '0', height: 24, width: 24 }}
                >
                  <ClearIcon sx={{ color: uhColors.iconLightGrey }} />
                </IconButton>
              </FlexBoxCenter>
            </FlexBoxJustify>

            {!available && (
              <div style={{ color: uhColors.darkRed, marginLeft: 28 }}>
                <FormattedMessage
                  id={messageId('.unavailable', __filenamespace)}
                />
              </div>
            )}

            {resource.preferring_staff.map(s => (
              <FlexBoxJustify
                key={s.id}
                style={{ alignItems: 'center', marginTop: 15 }}
              >
                <FlexBoxCenter>
                  <StaffIcon style={{ height: 18, width: 18 }} />
                  <div style={{ marginLeft: 10 }}>{s.name()}</div>
                </FlexBoxCenter>

                <IconButton
                  onClick={() =>
                    EventActions.preferenceOverrideRemoved(resource.id, s.id)
                  }
                  style={{ padding: '0', height: 24, width: 24 }}
                >
                  <DeleteIcon sx={{ color: uhColors.iconLightGrey }} />
                </IconButton>
              </FlexBoxJustify>
            ))}
          </div>
        </FlexBox>
      </Paper>
    </li>
  );
}

function SortableResourceCards({
  event,
  schedule,
  eventResourceStore,
  availableResourceIds,
  onSortEnd,
}) {
  return (
    <DndProvider backend={HTML5Backend}>
      <ul>
        {schedule.schedule_resources.map(sr => (
          <ResourceCard
            key={sr.resource_id}
            resource={eventResourceStore.resourceMap.get(sr.resource_id)}
            available={
              event.isOpenBooking() || availableResourceIds.has(sr.resource_id)
            }
            index={sr.position}
            id={sr.position}
            style={{ marginBottom: 10 }}
            onSortEnd={onSortEnd}
          />
        ))}
      </ul>
    </DndProvider>
  );
}

const onModeSelect = e => {
  EventActions.updateEventStore(
    ['schedules', 0, 'resource_usage_mode'],
    e.target.value
  );
};

const UsageSelector = injectIntl(({ schedule, intl }) => (
  <div>
    <Divider style={{ height: 2, marginBottom: 20 }} />
    <FlexBoxJustify style={{ alignItems: 'center', marginBottom: 20 }}>
      <div style={{ fontSize: 15, fontWeight: 'bold' }}>
        <FormattedMessage id={messageId('.usage', __filenamespace)} />
      </div>
      <FlexBoxCenter>
        <Select
          fullWidth
          variant="standard"
          value={schedule.resource_usage_mode}
          onChange={onModeSelect}
        >
          <MenuItem value={Schedule.RESOURCE_USAGE_ALL}>
            {t('.all', intl, __filenamespace)}
          </MenuItem>
          <MenuItem value={Schedule.RESOURCE_USAGE_ONE}>
            {t('.single', intl, __filenamespace)}
          </MenuItem>
        </Select>
        <div style={{ height: 20, width: 20 }}>
          <InfoIcon
            data-tip
            data-for="usage-info"
            style={{ height: 20, width: 20 }}
          />
          <ReactTooltip
            id="usage-info"
            effect="solid"
            style={{ maxWidth: 240 }}
            className="charge-failure-tooltip uh-tooltip"
          >
            <div style={{ lineHeight: 1.4 }}>
              {schedule.resource_usage_mode === Schedule.RESOURCE_USAGE_ALL ? (
                <FormattedMessage
                  id={messageId('.usage_information_all', __filenamespace)}
                />
              ) : (
                <FormattedMessage
                  id={messageId('.usage_information_one', __filenamespace)}
                />
              )}
            </div>
          </ReactTooltip>
        </div>
      </FlexBoxCenter>
    </FlexBoxJustify>
  </div>
));

const handleOnSortEnd = ({ oldIndex, newIndex }) => {
  EventActions.listSwap({ oldIndex, newIndex, key: 'schedule_resources' });
};

function ResourceList({
  availableResourceIds,
  eventResourceStore,
  isCheckingConflicts,
  event,
  schedule,
}) {
  if (eventResourceStore.isLoading || isCheckingConflicts) {
    return (
      <div>
        <Divider style={{ height: 2, marginBottom: 20 }} />
        <div style={{ color: uhColors.hint, fontSize: 15, marginBottom: 20 }}>
          <FormattedMessage
            id={messageId('.loading_resources', __filenamespace)}
          />
        </div>
      </div>
    );
  }
  if (schedule.schedule_resources.isEmpty()) {
    return (
      <div>
        <Divider style={{ height: 2, marginBottom: 20 }} />
        <div style={{ color: uhColors.hint, fontSize: 15, marginBottom: 20 }}>
          <FormattedMessage
            id={messageId('.no_resources_assigned', __filenamespace)}
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      {event.isOpenBooking() && <UsageSelector schedule={schedule} />}
      <SortableResourceCards
        event={event}
        schedule={schedule}
        eventResourceStore={eventResourceStore}
        availableResourceIds={availableResourceIds}
        onSortEnd={handleOnSortEnd}
        helperClass="sortableHelper"
      />
    </div>
  );
}

const onDrawerOpen = () =>
  SecondaryDrawerActions.setActive('EventResourceDrawer');

const ResourceColumnContent = injectIntl(
  ({
    availableResourceIds,
    eventResourceStore,
    isCheckingConflicts,
    event,
    schedule,
    intl,
  }) => (
    <div className="ResourceColumn">
      <style
        type="text/css"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: styles.literal }}
      />
      <FlexBoxJustify
        style={{ alignItems: 'center', fontSize: 18, marginBottom: 10 }}
      >
        <FlexBoxCenter>
          <div
            data-tip
            data-for="resource-information"
            style={{ marginRight: 15 }}
          >
            <Fab
              size="small"
              color="primary"
              onClick={onDrawerOpen}
              disabled={!currentCustomer().resources_enabled}
              sx={{
                width: '32px',
                height: '32px',
                minHeight: '32px',
                boxShadow: 'none',
              }}
            >
              <AddIcon />
            </Fab>
            <ReactTooltip
              id="resource-information"
              disable={currentCustomer().resources_enabled}
              effect="solid"
              style={{ maxWidth: 240 }}
              className="uh-tooltip"
            >
              <FormattedMessage
                id={messageId(
                  'feature_wall.resources.resource_information',
                  intl
                )}
              />
            </ReactTooltip>
          </div>
          <FormattedMessage id={messageId('.resources', __filenamespace)} />
        </FlexBoxCenter>

        {schedule.schedule_resources.size > 0 && (
          <div style={{ marginRight: 8 }}>
            {schedule.schedule_resources.size}
          </div>
        )}
      </FlexBoxJustify>

      <ResourceList
        eventResourceStore={eventResourceStore}
        event={event}
        schedule={schedule}
        availableResourceIds={availableResourceIds}
        isCheckingConflicts={isCheckingConflicts}
      />

      {currentCustomer().resources_enabled && <EventResourceDrawer />}
    </div>
  )
);

function ResourceColumn() {
  return (
    <AltContainer
      stores={{
        event: () => ({
          store: EventStore,
          value: EventStore.getState().customerEvent,
        }),
        schedule: () => ({
          store: EventStore,
          value: EventStore.getState().customerEvent
            ? EventStore.getState().customerEvent.get('schedules').get(0)
            : Map(),
        }),
        availableResourceIds: () => ({
          store: AvailableStaffStore,
          value: AvailableStaffStore.getState().resourceIds,
        }),
        isCheckingConflicts: () => ({
          store: EventStore,
          value: EventStore.getState().isCheckingConflicts,
        }),
        eventResourceStore: EventResourceStore,
      }}
    >
      <ResourceColumnContent />
    </AltContainer>
  );
}

export default ResourceColumn;
