import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function TouchID(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 70 70" role="img" {...props}>
      <title>Touch ID icon</title>
      <g
        id="Splash"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile---Touch-ID"
          transform="translate(-594.000000, -904.000000)"
          fill="var(--color-secondary)"
          fillRule="nonzero"
        >
          <g id="Group-2" transform="translate(594.000000, 904.000000)">
            <path
              d="M15,0 L3,0 C1.344,0 0,1.344 0,3 L0,15 C0,16.656 1.344,18 3,18 C4.656,18 6,16.656 6,15 L6,6 L15,6 C16.656,6 18,4.656 18,3 C18,1.344 16.656,0 15,0 Z"
              id="Shape"
            />
            <path
              d="M35,21 C26.7295625,21 20,27.5256364 20,35.5454545 L20,58.1542969 C20,59.7622969 21.34325,61.0633878 23,61.0633878 C24.65675,61.0633878 26,59.7622969 26,58.1542969 L26,35.5454545 C26,30.7329091 30.0370625,26.8181818 35,26.8181818 C39.9629375,26.8181818 44,30.7329091 44,35.5454545 C44,37.152 45.34325,38.4545455 47,38.4545455 C48.65675,38.4545455 50,37.152 50,35.5454545 C50,27.5256364 43.2704375,21 35,21 Z"
              id="Shape"
            />
            <path
              d="M34,34 C32.34325,34 31,35.3025455 31,36.9090909 L31,63.0909091 C31,64.6974545 32.34325,66 34,66 C35.65675,66 37,64.6974545 37,63.0909091 L37,36.9090909 C37,35.3025455 35.65675,34 34,34 Z"
              id="Shape"
            />
            <path
              d="M47,43.1464844 C45.34325,43.1464844 44,44.4257701 44,46.0036272 L44,58.21024 C44,59.7895257 45.34325,61.0673828 47,61.0673828 C48.65675,61.0673828 50,59.7895257 50,58.21024 L50,46.0036272 C50,44.4257701 48.65675,43.1464844 47,43.1464844 Z"
              id="Shape"
            />
            <path
              d="M55.6962709,24.2107489 C54.3071546,24.8102681 53.6370903,26.4934364 54.1985001,27.9732737 C55.1123165,30.3785737 55.5757508,32.9279751 55.5757508,35.5505105 L55.5757508,42.098877 C55.5757508,43.6959095 56.7901046,44.9881262 58.2878754,44.9881262 C59.7856462,44.9881262 61,43.6959095 61,42.098877 L61,35.5505105 C61,32.1816459 60.4040106,28.9028897 59.2281351,25.8063368 C58.6665558,24.3279441 57.093354,23.6139384 55.6962709,24.2107489 Z"
              id="Shape"
            />
            <path
              d="M35.3913317,10 C20.8398112,10 9,21.7764063 9,36.25 L9,42.0833333 C9,43.6940625 10.3129688,45 11.9323702,45 C13.5517716,45 14.8647404,43.6940625 14.8647404,42.0833333 L14.8647404,36.25 C14.8647404,24.9920312 24.0727493,15.8333333 35.3913317,15.8333333 C40.1177626,15.8333333 44.5564547,17.3842708 48.2305313,20.3208073 C49.4976817,21.3276042 51.3390269,21.1298177 52.3541768,19.8736458 C53.3678606,18.617474 53.1660768,16.7817969 51.9046078,15.7720833 C47.2454382,12.0493229 41.3806978,10 35.3913317,10 Z"
              id="Shape"
            />
            <path
              d="M67,52 C65.344,52 64,53.344 64,55 L64,64 L55,64 C53.344,64 52,65.344 52,67 C52,68.656 53.344,70 55,70 L67,70 C68.656,70 70,68.656 70,67 L70,55 C70,53.344 68.656,52 67,52 Z"
              id="Shape"
            />
            <path
              d="M67,0 L55,0 C53.344,0 52,1.344 52,3 C52,4.656 53.344,6 55,6 L64,6 L64,15 C64,16.656 65.344,18 67,18 C68.656,18 70,16.656 70,15 L70,3 C70,1.344 68.656,0 67,0 Z"
              id="Shape"
            />
            <path
              d="M15,64 L6,64 L6,55 C6,53.344 4.656,52 3,52 C1.344,52 0,53.344 0,55 L0,67 C0,68.656 1.344,70 3,70 L15,70 C16.656,70 18,68.656 18,67 C18,65.344 16.656,64 15,64 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default TouchID;
