import CapacitorPlugin from 'shared/native/helpers/CapacitorPlugin';
import { Browser as CapacitorBrowser } from '@capacitor/browser';

// Wrapper for https://capacitorjs.com/docs/apis/browser
class Browser extends CapacitorPlugin {
  pluginName = 'Browser';

  /**
   * Opens the specified URL in the system browser
   * @param  {String} url
   * @return {Promise}
   */
  async open(url) {
    await this.ready();
    return CapacitorBrowser.open({ url });
  }
}

export default new Browser();
