/*
 * TODO: [JROGERS:2016-08-31] Do we still need the updated_at and created_at?
 */
import { Record } from 'immutable';

class EventType extends Record({
  id: null,
  name: '',
  color: '#800080',
  event_count: null,
  created_at: null,
  updated_at: null,
}) {}

export default EventType;
