import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import Resource from 'shared/records/Resource.js';
import ResourceDeletionActions from 'resources/actions/ResourceDeletionActions.js';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class ResourceDeletionStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      askForConfirmation: ResourceDeletionActions.DELETE_REQUESTED,
      deleteResource: ResourceDeletionActions.DELETE_CONFIRMED,
      abortDeletion: ResourceDeletionActions.DELETE_ABORTED,
      deleteSuccess: ResourceDeletionActions.DELETE_SUCCESS,
      deleteError: ResourceDeletionActions.DELETE_ERROR,
    });
  }

  reset() {
    this.confirmationOpen = false;
    this.resource = new Resource();
  }

  askForConfirmation(resource) {
    this.resource = resource;
    this.confirmationOpen = true;
  }

  deleteResource() {
    if (!this.resource.id) {
      return false;
    }

    return uhApiClient.delete({
      url: `resources/${this.resource.id}`,
      success: {
        action: ResourceDeletionActions.deleteSuccess,
        args: [this.resource.id],
      },
      error: ResourceDeletionActions.deleteError,
    });
  }

  abortDeletion() {
    this.reset();
  }

  deleteSuccess([_, _resourceId]) {
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({ id: '.deleted', filename: __filenamespace })
    );

    this.reset();
  }

  deleteError() {
    this.notifyError('error deleting resource', arguments);
  }
}

export default alt.createStore(ResourceDeletionStore, 'ResourceDeletionStore');
