class ClassCancellationActions {
  constructor() {
    this.generateActions(
      'toggleCancelModal',
      'handleChange',
      'classCancel',
      'classCancelSuccess',
      'classCancelError'
    );
  }
}

export default alt.createActions(ClassCancellationActions);
