import * as React from 'react';
import AltContainer from 'alt-container';
import clsx from 'clsx';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button as PMButton,
  Grid,
  Typography,
  TextField,
  Dropdown,
} from '@upperhand/playmaker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import ColorSelector from 'memberships/components/_ColorSelector.jsx';
import ColumnarForm from 'shared/components/ColumnarForm.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import EventCreditSelector from 'memberships/components/EventCreditSelector.jsx';

import ExclusiveEventTypeSelector from 'memberships/components/ExclusiveEventTypeSelector.jsx';
import PreSale from 'memberships/components/_PreSale.jsx';
import Pricing from 'memberships/components/_Pricing.jsx';
import MembershipTiers from 'memberships/components/MembershipTiers/MembershipTiers.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import DiscountsSelector from 'memberships/components/DiscountsSelector/DiscountsSelector.jsx';

import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { redirectTo, customerScopedRoute } from 'shared/utils/RouteUtils';
import { LEFT_MARGIN_PIXELS, smallScreen } from 'shared/utils/DOMUtils';

import { BENEFIT_TYPES } from 'shared/records/Membership.jsx';

import StoreActions from 'shared/actions/StoreActions.jsx';
import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import SpecificEventsListStore from 'shared/stores/SpecificEventsListStore.jsx';
import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';
import MembershipEditingStore from 'memberships/stores/MembershipEditingStore.jsx';
import MembershipEditingUIStore from 'memberships/stores/MembershipEditingUIStore.jsx';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import MembershipTiersStore from 'memberships/stores/MembershipTiersStore';

import '../styles.scss';

const styles = {
  columnarForm: {
    root: {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
    column: {
      margin: `${LEFT_MARGIN_PIXELS}px 19px 0px 19px`,
    },
    column0: {
      flex: '1 1 30%',
    },
    column1: {
      flex: '1 1 60%',
    },
    contentRow: {
      marginLeft: '-19px',
      marginRight: '-19px',
    },
    actionRow: {
      marginLeft: '-19px',
      marginRight: '-19px',
    },
    infoIcon: {
      svg: {
        fill: 'var(--color-primary)',
      },
    },
    checkBoxChecked: {
      checked: {
        color: 'var(--color-secondary) !important',
      },
    },
  },
};

const onChange = handleChange({ actions: MembershipEditingActions });
const togglePreSale = () => MembershipEditingActions.preSaleToggled();
// eslint-disable-next-line camelcase
const updatePreSale = active_at =>
  // eslint-disable-next-line camelcase
  MembershipEditingActions.updateStore({ active_at });

const SaveDialog = injectIntl(({ intl, prompted }) => (
  <ConfirmationDialog
    awaitingConfirmation={prompted}
    onCancel={MembershipEditingActions.cancelSave}
    onConfirm={MembershipEditingActions.validateAndSave}
    title={t('.save_membership', intl, __filenamespace)}
    submitText={t('.confirm_save', intl, __filenamespace)}
    cancelText={t('.cancel_save', intl, __filenamespace)}
  >
    <div style={{ marginBottom: 20 }}>
      <FormattedMessage id={messageId('.warning', __filenamespace)} />
    </div>
  </ConfirmationDialog>
));

const EditingForm = injectIntl(
  ({ intl, ui, membershipStore, membershipTiersStore }) => {
    const { record } = membershipStore;
    const { membershipTiersIds } = membershipTiersStore;
    const { features } = currentCustomer();
    const disableTiers =
      (record?.id && record?.tiered && membershipTiersIds.size > 0) ||
      record.hasBenefits ||
      record.active_client_count > 0;

    return (
      <div>
        <TextField
          onChange={onChange}
          errorText={record.errors.getErrors('name', intl)}
          name="name"
          value={record.name}
          label={t('.name_floating', intl, __filenamespace)}
          placehoder={t('.name_hint', intl, __filenamespace)}
          fullWidth
        />
        <div className="membership__form-element">
          <TextField
            onChange={onChange}
            errorText={record.errors.getErrors('description', intl)}
            name="description"
            value={record.description}
            label={t('.description', intl, __filenamespace)}
            placehoder={t('.description', intl, __filenamespace)}
            multiline
            fullWidth
          />
        </div>
        <FormGroup>
          <FormControlLabel
            name="invite_only"
            label={t('.invite_only', intl, __filenamespace)}
            control={<Checkbox />}
            onChange={onChange}
            checked={record.invite_only}
          />
          <FormControlLabel
            name="hidden"
            label={t('.hide_in_client_checkout', intl, __filenamespace)}
            control={<Checkbox />}
            onChange={onChange}
            checked={record.hidden}
          />
          <FormControlLabel
            name="hide_in_pos"
            label={t('.hide_in_point_of_sale', intl, __filenamespace)}
            control={<Checkbox />}
            onChange={onChange}
            checked={record.hide_in_pos}
          />
        </FormGroup>
        {(features.embed_api || features.ap_credits) && (
          <Dropdown
            fullWidth
            classes={{
              menuLabel: 'benefit-menu-label',
              item: 'benefit-menu-label',
            }}
            name="benefit_type"
            label={t('.benefit_type', intl, __filenamespace)}
            value={record.benefit_type}
            items={Object.values(BENEFIT_TYPES).map(benefit => ({
              label: benefit,
              value: benefit,
            }))}
            onChange={e =>
              MembershipEditingActions.updateStore({
                [e.target.name]: e.target.value,
              })
            }
          />
        )}

        <div className="membership__form-element">
          <Pricing
            commitmentMonths={record.commitment_months}
            commitmentLengthChecked={ui.commitmentLengthChecked}
            disableEditInterval={record.active_client_count > 0}
            interval={record.interval_months}
            intervalErrorText={record.errors.getErrors('interval_months', intl)}
            joinFee={record.join_fee}
            joinFeeChecked={ui.joinFeeChecked}
            onChange={onChange}
            price={record.price}
            tiered={record.tiered}
            disableTiers={disableTiers}
            priceErrorText={record.errors.getErrors('price', intl)}
          />
        </div>
        {record.commitment_months ? (
          <FormControlLabel
            name="commitment_checked"
            label={t('.commitmentChecked', intl, __filenamespace)}
            control={<Checkbox />}
            onChange={onChange}
            checked={record?.commitment_checked}
          />
        ) : null}
        <PreSale
          membership={record}
          checked={ui.preSaleChecked}
          onCheck={togglePreSale}
          onDateChange={updatePreSale}
        />
        <ColorSelector ui={ui} color={record.color} onChange={onChange} />
      </div>
    );
  }
);

function BenefitsForm(props) {
  const {
    ui,
    events,
    specificEvents,
    eventTypes,
    retailCategories,
    membershipStore: {
      existingAllEventsCredits,
      disabledAddingNewCredit,
      record,
      selectedEventIds,
      selectedEventTypeIds,
      unselectedEventTypes,
    },
    creditPassListingStore: { records: creditPasses },
  } = props;

  if (record.tiered) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <MembershipTiers
        membershipId={record.id}
        isTieredMembership={record.tiered}
        events={events}
        specificEvents={specificEvents}
        eventTypes={eventTypes}
        creditPasses={creditPasses}
        retailCategories={retailCategories}
      />
    );
  }

  return (
    <div>
      <DiscountsSelector
        actions={MembershipEditingActions}
        membership={record}
        eventTypes={eventTypes}
        creditPasses={creditPasses}
        retailCategories={retailCategories}
      />
      {currentCustomer().membership_credits_enabled && (
        <EventCreditSelector
          actions={MembershipEditingActions}
          disabledAddingNewCredit={disabledAddingNewCredit}
          specificEvents={specificEvents}
          events={events}
          selectedEventIds={selectedEventIds}
          selectedEventTypeIds={selectedEventTypeIds}
          unselectedEventTypes={unselectedEventTypes}
          existingAllEventsCredits={existingAllEventsCredits}
          eventTypes={eventTypes}
          membership={record}
          expanded={!record.membership_event_credits.isEmpty()}
        />
      )}
      <ExclusiveEventTypeSelector
        actions={MembershipEditingActions}
        eventTypes={eventTypes}
        membership={record}
        expanded={ui.exclusiveEventTypesExpanded}
      />
    </div>
  );
}

const save = () => MembershipEditingActions.validateAndSave();
const saveWithPrompt = () => MembershipEditingActions.saveWithPrompt();

const cancel = () =>
  redirectTo({
    path: `/customers/${currentCustomer().id}/memberships`,
  });

const ActionButtons = injectIntl(
  ({ intl, membershipStore: { record }, ui }) => (
    <div>
      <Button variant="contained" color="default" onClick={cancel}>
        {t('actions.cancel', intl)}
      </Button>
      <StateChangingButton
        inProgress={ui.saving}
        label={t(record.id ? 'actions.save' : 'actions.create', intl)}
        labelInProgress={t('actions.saving', intl)}
        onClick={ui.priceChanged && record.id !== null ? saveWithPrompt : save}
        style={{ marginLeft: 16 }}
      />
    </div>
  )
);

const PageTitle = injectIntl(({ record, intl }) => (
  <Grid container item className="membership__header">
    <Grid container item xs>
      <ReactRouterLink to={customerScopedRoute('memberships')}>
        <PMButton
          type="tertiary"
          disableRipple
          classes={{ root: 'membership__back-btn' }}
          icon="arrowLeft"
        />
      </ReactRouterLink>
      <Typography
        variant="h4"
        className={clsx(
          'membership__name',
          smallScreen() && 'membership__mobile-name'
        )}
      >
        {t(
          record.id ? '.edit_header' : '.create_header',
          intl,
          __filenamespace
        )}
      </Typography>
    </Grid>
  </Grid>
));

const showSpinner = () => {
  const { isLoading } = EventTypeListingStore.getState();
  const { loading } = MembershipEditingUIStore.getState();
  return isLoading || loading;
};

function Content(props) {
  const {
    membershipStore: { record },
    ui: { prompted },
  } = props;
  return (
    <SpinWhileLoading isLoading={showSpinner()}>
      <PageTitle record={record} />
      <ColumnarForm
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className="iphone-x-content"
        actions={<ActionButtons />}
        columns={[<EditingForm />, <BenefitsForm />]}
        style={styles.columnarForm}
      />
      <SaveDialog prompted={prompted} />
    </SpinWhileLoading>
  );
}

class MembershipEditing extends React.Component {
  componentDidMount() {
    const { membershipId } = this.props;
    StoreActions.prepareForReuse();
    if (membershipId) {
      MembershipEditingActions.fetch(membershipId);
    }
    CreditPassListingActions.listRequested({});
    EventTypeListingActions.list();
    RetailCategoryListingActions.list();
  }

  componentWillUnmount() {
    StoreActions.prepareForReuse();
  }

  render() {
    return (
      <div className="membership">
        <AltContainer
          stores={{
            eventTypes: () => ({
              store: EventTypeListingStore,
              value: EventTypeListingStore.getState().eventTypes,
            }),
            events: () => ({
              store: EventListStore,
              value: EventListStore.getState().events,
            }),
            specificEvents: () => ({
              store: SpecificEventsListStore,
              value: SpecificEventsListStore.getState().events,
            }),
            retailCategories: () => ({
              store: RetailCategoryListingStore,
              value: RetailCategoryListingStore.getState().retailCategories,
            }),
            creditPassListingStore: CreditPassListingStore,
            membershipStore: MembershipEditingStore,
            membershipTiersStore: MembershipTiersStore,
            ui: MembershipEditingUIStore,
          }}
        >
          <Content />
        </AltContainer>
      </div>
    );
  }
}

export default MembershipEditing;
