import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import moment from 'moment-timezone';
import clsx from 'clsx';

import PaymentPlanDetailsDrawerActions from 'containers/reports/paymentPlanDetailsDrawer/Actions';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import DetailItemContainer from 'containers/reports/balanceDetailsDrawer/components/DetailItemContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import BalanceDetailsDrawerActions from '../Actions';

function DateTimeRenderer({
  detail,
  intl,
  remainingScheduledPayments,
  isPaymentPlan,
}) {
  if (isPaymentPlan) {
    if (remainingScheduledPayments === 0) {
      return null;
    }
    return (
      <Typography variant="body2" align="right">
        {t('.remaining_scheduled_payments', intl, __filenamespace, {
          count: remainingScheduledPayments,
        })}
      </Typography>
    );
  }
  if (detail.get('type') === 'ScheduledPayment') {
    return (
      <Typography variant="body2" align="right">
        {t('.date', intl, __filenamespace, {
          date: detail.get('dueDate')
            ? moment(detail.get('dueDate')).format('MMM DD, YYYY')
            : '-',
        })}
      </Typography>
    );
  }
  return (
    <Typography variant="body2" align="right">
      {t('.date_time', intl, __filenamespace, {
        date: detail.get('effectiveAt')
          ? moment(detail.get('effectiveAt')).format('MMM DD, YYYY')
          : '-',
        time: detail.get('effectiveAt')
          ? moment(detail.get('effectiveAt')).format('h:mm a')
          : '-',
      })}
    </Typography>
  );
}
function DetailItem({ detail, intl, remainingScheduledPayments }) {
  const isPaymentPlan = detail.get('type') === 'PaymentPlan';
  return (
    <div className="details__card">
      <DetailItemContainer
        onClick={
          detail.get('type') === 'PaymentPlan'
            ? () => {
                PaymentPlanDetailsDrawerActions.openDrawer(
                  detail.get('sourceId'),
                  [BalanceDetailsDrawerActions.closeDrawer.defer]
                );
              }
            : null
        }
      >
        <Grid container xs={12} spacing={1}>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="subtitle2" className="details__title">
                {t(
                  `.${
                    detail.get('type') === 'Payment' &&
                    detail.get('amount') === 0
                      ? 'failed_payment'
                      : detail.get('type')
                  }`,
                  intl,
                  __filenamespace
                )}
              </Typography>
            </Grid>
            {!isPaymentPlan ? (
              <Grid item>
                <Typography
                  variant="subtitle2"
                  align="right"
                  className={clsx(
                    detail.get('amount') < 0 && 'details__negative_balance'
                  )}
                >
                  <FormattedCurrency
                    value={detail.get('amount') || 0}
                    fromCents
                  />
                </Typography>
              </Grid>
            ) : (
              <Grid item>
                <Typography
                  variant="subtitle2"
                  align="right"
                  className="details__view-plan"
                >
                  {t('.view', intl, __filenamespace)}
                  <ChevronRightIcon />
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <DateTimeRenderer
                detail={detail}
                intl={intl}
                remainingScheduledPayments={remainingScheduledPayments}
                isPaymentPlan={isPaymentPlan}
              />
            </Grid>
          </Grid>
        </Grid>
      </DetailItemContainer>
    </div>
  );
}

DetailItem.propTypes = {
  detail: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default DetailItem;
