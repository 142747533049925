import { BalancesDataStore } from 'dataStores';
import { BalancesSource, ClientSource, LocationSource } from 'sources';
import { isArray } from 'shared/utils/SharedUtils.js';
import PaymentDetailsDrawerActions from './Actions';

class PaymentDetailsDrawerStore {
  constructor() {
    this.reset();
    this.bindListeners({
      fetchBalanceSuccess: PaymentDetailsDrawerActions.fetchBalanceSuccess,
      fetchBalanceDetailsSuccess:
        PaymentDetailsDrawerActions.fetchBalanceDetailsSuccess,
      fetchBalanceDetailsError:
        PaymentDetailsDrawerActions.fetchBalanceDetailsError,
      openDrawer: PaymentDetailsDrawerActions.openDrawer,

      closeDrawer: PaymentDetailsDrawerActions.closeDrawer,
      fetchClientResponse: PaymentDetailsDrawerActions.fetchClientResponse,
      fetchLocationResponse: PaymentDetailsDrawerActions.fetchLocationResponse,
      sendReceipt: PaymentDetailsDrawerActions.sendReceipt,
      payBalance: PaymentDetailsDrawerActions.payBalance,
      toggleDotsMenu: PaymentDetailsDrawerActions.toggleDotsMenu,
    });
  }

  reset() {
    this.balanceCompoundId = null;
    this.balanceProductId = null;
    this.balanceLocationId = null;
    this.balanceClientId = null;
    this.isLoadingBalanceDetails = false;
    this.isLoadingClient = false;
    this.isLoadingLocation = false;
    this.isLoadingBalance = false;
    this.dotsMenuAnchorEl = null;
    this.closeOtherDrawersActions = [];
    this.paymentPlanId = null;
    this.remainingScheduledPayments = 0;
  }

  openDrawer(args) {
    const [compoundId, closeOtherDrawersActions = [], orderItemId] = isArray(
      args
    )
      ? args
      : [args];

    this.closeOtherDrawersActions = closeOtherDrawersActions;

    if (compoundId) {
      this.setBalanceData(compoundId);
      this.isLoadingBalanceDetails = true;
      this.fetchBalanceDetails();
      this.fetchDependencies();
    } else {
      this.isLoadingBalance = true;
      this.balanceProductId = orderItemId;
      this.balanceProductId = orderItemId;
      this.fetchBalance();
    }
  }

  setBalanceData(compoundId) {
    const { balances } = BalancesDataStore.getState();
    const balance = balances.get(compoundId);

    this.balanceCompoundId = compoundId;
    this.balanceProductId = balance.get('productId');
    this.balanceLocationId = balance.get('locationId');
    this.balanceClientId = balance.get('clientId');
  }

  fetchBalance() {
    BalancesSource.list({
      params: {
        product_ids: this.balanceProductId,
      },
      success: PaymentDetailsDrawerActions.fetchBalanceSuccess,
    });
  }

  fetchBalanceSuccess({ records }) {
    const balance = records.first();

    if (!balance) return;

    this.balanceCompoundId = balance.get('compoundId');
    this.balanceProductId = balance.get('productId');
    this.balanceLocationId = balance.get('locationId');
    this.balanceClientId = balance.get('clientId');

    this.isLoadingBalance = false;
    this.isLoadingBalanceDetails = true;

    this.fetchBalanceDetails();
    this.fetchDependencies();
  }

  fetchBalanceDetails() {
    BalancesSource.details({
      productId: this.balanceProductId,
      compoundId: this.balanceCompoundId,
      success: PaymentDetailsDrawerActions.fetchBalanceDetailsSuccess,
      error: PaymentDetailsDrawerActions.fetchBalanceDetailsError,
    });
  }

  fetchBalanceDetailsSuccess() {
    this.isLoadingBalanceDetails = false;
  }

  fetchBalanceDetailsError(...args) {
    this.isLoadingBalanceDetails = false;
    this.notifyError('error getting balance detail', args);
  }

  fetchDependencies() {
    if (this.balanceClientId) {
      this.isLoadingClient = true;
      ClientSource.fetch({
        id: this.balanceClientId,
        params: {
          fields: [
            'total_account_credit',
            'agreement_details',
            'total_credits',
          ],
        },
        success: PaymentDetailsDrawerActions.fetchClientResponse,
        error: PaymentDetailsDrawerActions.fetchClientResponse,
      });
    }

    if (this.balanceLocationId) {
      this.isLoadingLocation = true;
      LocationSource.fetch({
        id: this.balanceLocationId,
        success: PaymentDetailsDrawerActions.fetchLocationResponse,
        error: PaymentDetailsDrawerActions.fetchLocationResponse,
      });
    }
  }

  fetchClientResponse() {
    this.isLoadingClient = false;
  }

  fetchLocationResponse() {
    this.isLoadingLocation = false;
  }

  closeDrawer() {
    this.reset();
  }

  // eslint-disable-next-line class-methods-use-this
  sendReceipt() {
    // TODO?
  }

  // eslint-disable-next-line class-methods-use-this
  payBalance() {
    // TODO
  }

  toggleDotsMenu(event) {
    this.dotsMenuAnchorEl = event && event.currentTarget;
  }
}

export default alt.createStore(
  PaymentDetailsDrawerStore,
  'PaymentDetailsDrawerStore'
);
