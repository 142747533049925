import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import { List, Set } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Grid, Spinner, Typography } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import ScheduleTableHeader from 'containers/events/admin/schedule/shared/ScheduleTableHeader.jsx';
import ScheduleDrawerActions from 'event_mgmt/display/actions/ScheduleDrawerActions.js';

import altContainer from 'shared/hocs/altContainer.jsx';
import { StaffDataStore, ScheduleDataStore } from 'dataStores';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { weekdayLabels } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import EventScheduleCancelActions from 'containers/eventScheduleCancellation/Actions';
import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';
import WarningIcon from 'containers/classes/classesList/components/Sessions/WarningIcon.jsx';
import EmptyState from './EmptyState.jsx';
import StaffSection from './StaffSection.jsx';
import CancelCompleteScheduleActions from '../../completeScheduleModal/Actions.js';
import CompleteScheduleModal from '../../completeScheduleModal/CompleteScheduleModal.jsx';

const styles = {
  cancelledIcon: {
    display: 'inline-block',
    marginRight: 10,
    marginTop: 5,
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
};

function StartAtTime({ daytimes }) {
  const differentStartAtTimes = List(Object.values(daytimes))
    .flatMap(times => times.map(time => time.start_time))
    .toSet();
  return differentStartAtTimes.size === 1 ? (
    <FormattedMessage
      id={messageId('.start_at', __filenamespace)}
      values={{
        startsAt: moment(differentStartAtTimes.first(), 'HH:mm').format(
          'h:mma'
        ),
      }}
    />
  ) : (
    <FormattedMessage id={messageId('.multiple_start_at', __filenamespace)} />
  );
}

function ScheduleRepeatDaily({ interval }) {
  return interval === 1 ? (
    <FormattedMessage id={messageId('.every_day_repeat', __filenamespace)} />
  ) : (
    <FormattedMessage
      id={messageId('.daily_repeat', __filenamespace)}
      values={{ interval }}
    />
  );
}

function ScheduleRepeat({ daytimes, frequency, interval }) {
  if (frequency === 'daily') {
    return <ScheduleRepeatDaily interval={interval} />;
  }
  if (Object.keys(daytimes).length === 7) {
    return <FormattedMessage id={messageId('.daily', __filenamespace)} />;
  }
  return Object.keys(daytimes)
    .map(key => weekdayLabels.get(key))
    .join(', ');
}

function ScheduleDate({ startDate, endDate }) {
  if (startDate === endDate) {
    return moment(startDate).format('MMM DD');
  }
  return (
    <FormattedMessage
      id={messageId('.dates', __filenamespace)}
      values={{
        start: moment(startDate).format('MMM DD'),
        end: endDate ? moment(endDate).format('MMM DD') : 'Indefinite',
      }}
    />
  );
}

const ScheduleCard = injectIntl(
  ({
    staff,
    schedule,
    scheduleType,
    intl,
    onCompleteScheduleSelect,
    onCancelScheduleSelect,
    isTeamEvent,
  }) => {
    const isClient = currentUser().isClient();
    const menuOptions = [
      {
        label: t('.edit_schedule', intl, __filenamespace),
        onClick: () => ScheduleDrawerActions.editSchedule(schedule),
        visible: scheduleType === SCHEDULE_TYPE.classSchedule,
      },
      {
        label: 'Cancel Schedule',
        onClick: onCancelScheduleSelect,
        visible:
          scheduleType === SCHEDULE_TYPE.classSchedule &&
          schedule.total_registration_count === 0,
      },
      {
        label: 'Complete Schedule',
        onClick: onCompleteScheduleSelect,
        visible:
          scheduleType === SCHEDULE_TYPE.classSchedule &&
          schedule.total_registration_count > 0,
      },
    ]
      .filter(item => item.visible)
      .map(({ visible, ...rest }) => rest);

    const menuOptionsTeams = [
      {
        label: t('.edit_schedule', intl, __filenamespace),
        onClick: () => EventSessionCreationActions.editSchedule({ schedule }),
        visible: true,
      },
      {
        label: t('.cancel_schedule', intl, __filenamespace),
        onClick: () =>
          EventScheduleCancelActions.toggleCancelModal({
            scheduleItem: schedule,
          }),
        visible: schedule.status !== 'cancelled',
      },
    ].filter(item => item.visible);

    const {
      start_date: startDate,
      end_date: endDate,
      daytimes,
      frequency,
      interval,
    } = schedule.availability_schedule;

    if (smallScreen() && isTeamEvent) {
      return (
        <Card
          contentDirection="row"
          menuOptions={!isClient ? menuOptionsTeams : null}
        >
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              {schedule.status === 'cancelled' && (
                <>
                  <span data-tip style={styles.cancelledIcon}>
                    <WarningIcon height={17} width={20} />
                  </span>
                  <ReactTooltip className="uh-tooltip" effect="solid">
                    {t('.status_cancelled', intl, __filenamespace)}
                  </ReactTooltip>
                </>
              )}
              <Typography variant="subtitle2" display="inline">
                {schedule.get('label')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item>
                  <Typography variant="subtitle2" display="inline">
                    <ScheduleDate startDate={startDate} endDate={endDate} />
                  </Typography>
                </Grid>
                {schedule.location && (
                  <Grid item>
                    <Typography>
                      {schedule.location ? schedule.location.name : ''}
                    </Typography>
                  </Grid>
                )}

                {!isClient && (
                  <Grid item>
                    <StaffSection
                      intl={intl}
                      staffIds={Set(schedule.customer_user_ids)}
                      staff={staff}
                    />
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item>
                  <StartAtTime daytimes={daytimes} />
                </Grid>
                <Grid item>
                  <Typography>
                    <ScheduleRepeat
                      daytimes={daytimes}
                      frequency={frequency}
                      interval={interval}
                    />
                  </Typography>
                </Grid>
                {!isClient && (
                  <Grid item>
                    <Box sx={{ display: 'flex' }}>
                      <ResourceIcon style={styles.icon} />
                      <Typography>{schedule.resource_ids.length}</Typography>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Card>
      );
    }

    if (isClient && isTeamEvent) {
      return (
        <Card contentDirection="row" menuOptions={null}>
          <Grid container alignItems="center" xs={12} spacing={1}>
            <Grid item alignItems="center" xs={6} md={2} spacing={1}>
              <FlexBoxCenter>
                {schedule.status === 'cancelled' && (
                  <>
                    <span data-tip style={styles.cancelledIcon}>
                      <WarningIcon height={17} width={20} />
                    </span>
                    <ReactTooltip className="uh-tooltip" effect="solid">
                      {t('.status_cancelled', intl, __filenamespace)}
                    </ReactTooltip>
                  </>
                )}
                <Typography variant="subtitle2" display="inline">
                  {schedule.get('label')}
                </Typography>
              </FlexBoxCenter>
            </Grid>
            <Grid item alignItems="center" xs={6} md={2} spacing={1}>
              <FlexBoxCenter>
                <Typography variant="subtitle2" display="inline">
                  <ScheduleDate startDate={startDate} endDate={endDate} />
                </Typography>
              </FlexBoxCenter>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>
                <ScheduleRepeat
                  daytimes={daytimes}
                  frequency={frequency}
                  interval={interval}
                />
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <StartAtTime daytimes={daytimes} />
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography>
                {schedule.location ? schedule.location.name : ''}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      );
    }

    if (isTeamEvent) {
      return (
        <Card contentDirection="row" menuOptions={menuOptionsTeams}>
          <Grid container alignItems="center" xs={12} spacing={1}>
            <Grid item alignItems="center" xs={6} md={2} spacing={1}>
              <FlexBoxCenter>
                {schedule.status === 'cancelled' && (
                  <>
                    <span data-tip style={styles.cancelledIcon}>
                      <WarningIcon height={17} width={20} />
                    </span>
                    <ReactTooltip className="uh-tooltip" effect="solid">
                      {t('.status_cancelled', intl, __filenamespace)}
                    </ReactTooltip>
                  </>
                )}
                <Typography variant="subtitle2" display="inline">
                  {schedule.get('label')}
                </Typography>
              </FlexBoxCenter>
            </Grid>
            <Grid item alignItems="center" xs={6} md={2} spacing={1}>
              <FlexBoxCenter>
                <Typography variant="subtitle2" display="inline">
                  <ScheduleDate startDate={startDate} endDate={endDate} />
                </Typography>
              </FlexBoxCenter>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>
                <ScheduleRepeat
                  daytimes={daytimes}
                  frequency={frequency}
                  interval={interval}
                />
              </Typography>
            </Grid>
            <Grid item xs={6} md={1}>
              <StartAtTime daytimes={daytimes} />
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography>
                {schedule.location ? schedule.location.name : ''}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <StaffSection
                intl={intl}
                staffIds={Set(schedule.customer_user_ids)}
                staff={staff}
              />
            </Grid>
            <Grid item xs={12} md={1} alignItems="center">
              <FlexBoxCenter>
                <ResourceIcon style={styles.icon} />
                <Typography>{schedule.resource_ids.length}</Typography>
              </FlexBoxCenter>
            </Grid>
          </Grid>
        </Card>
      );
    }

    return (
      <Card
        contentDirection="row"
        menuOptions={menuOptions.length === 0 ? null : menuOptions}
      >
        <Grid container alignItems="center" xs={12} spacing={1}>
          <Grid item alignItems="center" xs={6} md={2} spacing={1}>
            <FlexBoxCenter>
              <Typography variant="subtitle2" display="inline">
                <ScheduleDate startDate={startDate} endDate={endDate} />
              </Typography>
            </FlexBoxCenter>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>
              <ScheduleRepeat
                daytimes={daytimes}
                frequency={frequency}
                interval={interval}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} md={1}>
            <StartAtTime daytimes={daytimes} />
          </Grid>
          {schedule.location && (
            <Grid item xs={12} md={2}>
              <Typography>
                {schedule.location ? schedule.location.name : ''}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <StaffSection
              intl={intl}
              staffIds={Set(schedule.customer_user_ids)}
              staff={staff}
            />
          </Grid>
          <Grid item xs={12} md={1} alignItems="center" spacing={1}>
            <FlexBoxCenter>
              <ResourceIcon style={styles.icon} />
              <Typography>{schedule.resource_ids.length}</Typography>
            </FlexBoxCenter>
          </Grid>
        </Grid>
      </Card>
    );
  }
);

const { cancelScheduleSelected, completeScheduleSelected } =
  CancelCompleteScheduleActions;

function ScheduleList({
  filtersPresent,
  isLoading,
  onPageSelect,
  page,
  perPage,
  scheduleDataStore: { schedules },
  scheduleIds,
  scheduleType,
  staffDataStore: { staff },
  totalCount,
  isTeamEvent,
}) {
  return isLoading ? (
    <Grid item xs={12}>
      <div style={{ textAlign: 'center' }}>
        <Spinner type="indeterminate" />
      </div>
    </Grid>
  ) : (
    <>
      <Grid item container spacing={1}>
        {/* Add Hidden from MUI 4.0 to Playmaker and use it instead. */}
        {/* 960 is equivilent to the md breakpoint in PM. */}
        {!smallScreen(959) && (
          <Grid item md={12}>
            <ScheduleTableHeader isTeamEvent={isTeamEvent} />
          </Grid>
        )}
        {scheduleIds.map(scheduleId => (
          <Grid item key={scheduleId} xs={12}>
            <ScheduleCard
              isTeamEvent={isTeamEvent}
              scheduleId={scheduleId}
              schedule={schedules.get(scheduleId)}
              scheduleType={scheduleType}
              staff={staff}
              onCompleteScheduleSelect={() =>
                completeScheduleSelected(
                  scheduleId,
                  schedules.get(scheduleId).future_registration_count || 0
                )
              }
              onCancelScheduleSelect={() => cancelScheduleSelected(scheduleId)}
            />
          </Grid>
        ))}
      </Grid>
      {scheduleIds.isEmpty() && (
        <Grid item xs={12}>
          <EmptyState
            filtersPresent={filtersPresent}
            scheduleViewMode
            isTeamEvent={isTeamEvent}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paginator
          currentPage={page}
          onPageSelect={onPageSelect}
          perPage={perPage}
          totalCount={totalCount}
        />
      </Grid>
      <CompleteScheduleModal />
    </>
  );
}

export default altContainer({
  stores: {
    staffDataStore: StaffDataStore,
    scheduleDataStore: ScheduleDataStore,
  },
})(ScheduleList);
