import * as React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import EventType from 'event_mgmt/shared/records/EventType.jsx';
import EventTypeDiscount from 'memberships/components/_DiscountBenefits/_EventTypeDiscount.jsx';
import mapDiscounts from 'memberships/components/_DiscountBenefits/_mapDiscounts.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

class EventTypeDiscountBenefits extends React.Component {
  eventBenefits() {
    const { membership, eventTypes, intl } = this.props;

    return mapDiscounts(
      membership.membership_event_discounts,
      eventDiscount => {
        if (eventDiscount.all_events) {
          const all = new EventType({
            id: 'event-type-all',
            color: uhColors.primaryMain,
            name: t('.all_events', intl, __filenamespace),
          });
          return Set([all]);
        }
        if (eventTypes.isEmpty()) {
          return Set([]);
        }
        return eventDiscount.event_type_ids.map(id =>
          eventTypes.find(et => et.id === id)
        );
      }
    );
  }

  render() {
    const eventBenefitsList = this.eventBenefits();
    const { containerStyles, itemStyle, textStyle } = this.props;

    if (eventBenefitsList.isEmpty()) {
      return null;
    }
    return (
      <div style={containerStyles}>
        {eventBenefitsList
          .map((types, discount) => (
            <EventTypeDiscount
              key={`${discount.type}-${discount.value}`}
              discount={discount}
              eventTypes={types}
              itemStyle={itemStyle}
              textStyle={textStyle}
            />
          ))
          .toList()}
      </div>
    );
  }
}

EventTypeDiscountBenefits.propTypes = {
  membership: PropTypes.object.isRequired,
  eventTypes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  containerStyles: PropTypes.object,
};

EventTypeDiscountBenefits.defaultProps = {
  containerStyles: {},
};

export default injectIntl(EventTypeDiscountBenefits);
