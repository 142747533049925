import * as React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import ResendAccountVerificationActions from 'shared/actions/ResendAccountVerificationActions.jsx';
// dont remove this import. doing so breaks the onClick event below. guessing this is the first time it is loaded.
// eslint-disable-next-line no-unused-vars
import ResendAccountVerificationStore from 'shared/stores/ResendAccountVerificationStore.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import uhTheme from '_uh_theme.jsx';

function ResendVerificationLink({ intl, redirect, modalOpen }) {
  const handleClick = () => {
    ResendAccountVerificationActions.send({ redirect });
  };

  const borderDark = `1px solid${uhTheme.palette.dark.main}`;

  return (
    <Button
      style={{
        color: uhColors.resendLink,
        fontWeight: modalOpen ? 'normal' : 600,
        padding: modalOpen ? '8px 12px' : 0,
        border: modalOpen && borderDark,
        fontSize: modalOpen && '12px',
        borderRadius: modalOpen && '2px',
      }}
      onClick={handleClick}
    >
      {t('.resend_verification', intl, __filenamespace)}
    </Button>
  );
}

export default injectIntl(ResendVerificationLink);
