import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import EventClass from 'shared/records/EventClass';

import WarningIcon from 'containers/classes/classesList/components/Sessions/WarningIcon.jsx';

import { t } from 'shared/utils/LocaleUtils';

function ClassCard({ intl, classItem, isClassSelected, onClick }) {
  const eventType = classItem.get('event_type');
  const status = classItem.get('status');
  const eventTypeName = eventType.get('name');
  const color = eventType.get('color');
  const showWarning = status === 'cancelled' || status === 'draft';

  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx('class-item', isClassSelected && 'class-item-selected')}
      onClick={onClick}
      onKeyDown={() => null}
    >
      <Grid container>
        <Grid item xs={1}>
          <div
            className="class-item__event-type-color"
            style={{ backgroundColor: color }}
          />
        </Grid>
        <Grid item container xs={11} alignItems="center">
          <Grid item container xs={12} alignItems="center">
            <Grid item container xs={showWarning ? 9 : 11}>
              <Grid item xs={11}>
                <Typography className="class-item__title">
                  {classItem.title || t('.no_title', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography className="class-item__info">
                  {eventTypeName} | Status: {status}
                </Typography>
              </Grid>
            </Grid>
            {showWarning && (
              <Grid item container xs={2} alignItems="center" justify="center">
                <div className="class-item__warning-icon">
                  <WarningIcon />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

ClassCard.propTypes = {
  intl: PropTypes.object,
  classItem: PropTypes.instanceOf(EventClass),
  isClassSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

ClassCard.defaultProps = {
  intl: {},
  classItem: new EventClass(),
  isClassSelected: false,
  onClick: () => null,
};

export default injectIntl(ClassCard);
