import * as React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { Grid, InfiniteScroller, Spinner } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import Paginator from 'shared/components/Paginator.jsx';

import BalancesReportActions from '../../Actions';
import EmptyState from './EmptyState.jsx';
import HeaderDesktop from './HeaderDesktop.jsx';
import HeaderMobile from './HeaderMobile.jsx';
import RowDesktop from './RowDesktop.jsx';
import RowMobile from './RowMobile.jsx';

function Table({
  clients,
  columns,
  filters,
  intl,
  isLoadingReport,
  balanceIds,
  balances,
  page,
  perPage,
  reportMode,
  statistics,
  totalCount,
  revenue,
  scheduledPayments,
}) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <ResponsiveElement
        largeScreen={
          isLoadingReport ? (
            <FlexBoxJustifyCenter>
              <Spinner />
            </FlexBoxJustifyCenter>
          ) : (
            <>
              <Grid item xs={12}>
                <HeaderDesktop
                  columns={columns}
                  intl={intl}
                  reportMode={reportMode}
                />
              </Grid>
              {balanceIds.size === 0 ? (
                <Grid item xs={12}>
                  <EmptyState intl={intl} reportMode={reportMode} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    {balanceIds.map(compoundId => {
                      const balance = balances.get(compoundId);
                      const scheduledPayment = scheduledPayments.find(
                        sp => sp.id === balance.get('productId')
                      );

                      return (
                        <RowDesktop
                          key={compoundId}
                          intl={intl}
                          balance={balance}
                          columns={columns}
                          client={clients.get(balance.get('clientId'))}
                          scheduledPayment={scheduledPayment}
                        />
                      );
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    <Paginator
                      className="balances-report__pagination"
                      currentPage={page}
                      perPage={perPage}
                      totalCount={totalCount}
                      onPageSelect={BalancesReportActions.selectPage}
                      pageLimit={0}
                      showInfo
                    />
                  </Grid>
                </>
              )}
            </>
          )
        }
        smallScreen={
          <>
            <Grid item xs={12}>
              <HeaderMobile
                intl={intl}
                filters={filters}
                statistics={statistics}
                balances={balances}
                reportMode={reportMode}
                revenue={revenue}
              />
            </Grid>
            {balanceIds.size === 0 ? (
              <Grid item xs={12}>
                <EmptyState intl={intl} reportMode={reportMode} />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <InfiniteScroller
                  hasMore={balanceIds.size < totalCount}
                  onScroll={BalancesReportActions.list.defer}
                  isLoading={isLoadingReport}
                  showLoader
                >
                  {balanceIds.map(compoundId => {
                    const balance = balances.get(compoundId);
                    const scheduledPayment = scheduledPayments.find(
                      sp => sp.id === balance.get('productId')
                    );

                    return (
                      <RowMobile
                        key={compoundId}
                        intl={intl}
                        balance={balance}
                        reportMode={reportMode}
                        client={clients.get(balance.get('clientId'))}
                        scheduledPayment={scheduledPayment}
                      />
                    );
                  })}
                </InfiniteScroller>
              </Grid>
            )}
          </>
        }
      />
    </Grid>
  );
}

Table.propTypes = {
  clients: PropTypes.instanceOf(Map).isRequired,
  columns: PropTypes.instanceOf(Map).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isLoadingReport: PropTypes.bool.isRequired,
  balanceIds: PropTypes.instanceOf(OrderedSet).isRequired,
  balances: PropTypes.instanceOf(Map).isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
  totalCount: PropTypes.number.isRequired,
  revenue: PropTypes.number,
};

Table.defaultProps = {
  revenue: null,
};

export default injectIntl(Table);
