import { List } from 'immutable';
import POSMembershipListingActions from 'point_of_sale/actions/POSMembershipListingActions.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { MembershipSource } from 'sources';

class POSMembershipListingStore extends UpperHandStore {
  constructor() {
    super();

    this.memberships = List();

    this.bindListeners({
      requestList: POSActions.POS_CLICKED,
      updateList: POSMembershipListingActions.LIST_SUCCESS,
      handleError: POSMembershipListingActions.LIST_ERROR,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  requestList() {
    MembershipSource.list({
      params: { fields: ['has_embed_product'] },
      success: POSMembershipListingActions.listSuccess,
      error: POSMembershipListingActions.listError,
    });
  }

  updateList({ memberships }) {
    this.membershipIds = memberships.map(m => m.get('id'));
  }
}

export default alt.createStore(
  POSMembershipListingStore,
  'POSMembershipListingStore'
);
