import { fromJS, Iterable, Map, Record } from 'immutable';
import { windowWidth } from 'shared/utils/DOMUtils';
import { isUrl } from 'shared/utils/SharedUtils.js';
import { upload } from 'shared/utils/S3Utils';

class Image extends Record({
  file: null,
  url: '',
  name: '',
  alternatives: Map({}),
  originalData: '', // Is used for saving uncropped image
  public_file: false,
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      // eslint-disable-next-line no-param-reassign
      obj = obj.toJS();
    }

    super({
      ...obj,
      alternatives: fromJS(obj.alternatives),
      name: obj.name || obj.file?.name || '',
      // Prevent Chrome caching and CORS errors
      // https://stackoverflow.com/questions/49503171/the-image-tag-with-crossorigin-anonymous-cant-load-success-from-s3
      url: isUrl(obj.url) ? `${obj.url}#${Date.now()}` : obj.url,
    });
  }

  getSrc() {
    return this.url || (this.file && URL.createObjectURL(this.file)) || null;
  }

  getSrcForCropping() {
    return this.originalData || this.getSrc();
  }

  getAlternative(name) {
    if (!this.alternatives || this.alternatives.isEmpty()) {
      return this.getSrc();
    }
    const alternative = this.alternatives.get(name);
    if (alternative && (alternative.url || alternative.get('url'))) {
      return alternative.url || alternative.get('url');
    }
    return this.getSrc();
  }

  getLeaderboard() {
    if (!this.alternatives || this.alternatives.isEmpty()) {
      return this.getSrc();
    }
    let { url } = this;
    const currentWidth = windowWidth();
    if (
      currentWidth < 768 &&
      this.alternatives.get('medium_square') &&
      this.alternatives.get('medium_square').get('url')
    ) {
      url = this.alternatives.get('medium_square').get('url');
    } else if (
      currentWidth >= 768 &&
      currentWidth < 1024 &&
      this.alternatives.get('mega_leaderboard') &&
      this.alternatives.get('mega_leaderboard').get('url')
    ) {
      url = this.alternatives.get('mega_leaderboard').get('url');
    }
    return url;
  }

  isBlank() {
    return !(this.url || this.file);
  }

  async toServer() {
    try {
      if (this.file) {
        return await upload(this.file, this.public_file);
      }

      return null;
    } catch (e) {
      return null;
    }
  }
}

export default Image;
