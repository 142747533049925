class BalanceListDrawerActions {
  constructor() {
    this.generateActions(
      'closeDrawer',
      'fetchClientResponse',
      'fetchStatistics',
      'fetchStatisticsError',
      'fetchStatisticsSuccess',
      'listBalances',
      'listBalancesError',
      'listBalancesSuccess',
      'openDrawer'
    );
  }
}

export default alt.createActions(BalanceListDrawerActions);
