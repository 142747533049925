import * as React from 'react';
import { injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import ContactAutocomplete from 'shared/components/ContactAutocomplete.jsx';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import ContactsGroupsActions from '../Actions';
import ContactGroupToolbar from './ContactGroupToolbar.jsx';

const styles = {
  root: {
    large: {
      margin: `0 ${LEFT_MARGIN_PIXELS}px`,
      overflow: 'hidden',
    },

    small: {
      backgroundColor: '#F9F9F9',
    },
  },

  cancelGroupButton: {
    height: 50,
    marginRight: 8,
    flex: '1 1 50%',
  },

  contactCard: {
    borderLeftStyle: 'solid',
    marginBottom: '1em',
    marginTop: '1em',
    padding: '15px',
    position: 'relative',
  },

  saveGroupButton: {
    height: 50,
    marginLeft: 8,
    flex: '1 1 50%',
  },
};

function ContactCard({ contact, onClick, deletable, intl }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper style={styles.contactCard}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <FlexBoxCenter style={{ fontSize: 15 }} onClick={onClick}>
          <UserAvatar user={contact} style={{ flex: '0 0 auto' }} />
          <div style={{ flex: '1 0 auto' }}>
            <div>{contact.name()}</div>
            <div style={{ marginTop: 2 }}>{contact.phone}</div>
          </div>
        </FlexBoxCenter>
        {deletable && (
          <>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() =>
                  ContactsGroupsActions.removeContacts([contact.id])
                }
              >
                {t('.remove', intl, __filenamespace)}
              </MenuItem>
            </Menu>
          </>
        )}
      </Stack>
    </Paper>
  );
}

function SearchAndAddBar({ addContactContactList, isAddingContact }) {
  return isAddingContact ? (
    <FlexBoxCenter style={{ backgroundColor: 'white', height: 56 }}>
      <ContactAutocomplete
        contacts={addContactContactList}
        onSelect={contactId => ContactsGroupsActions.addContact(contactId)}
        onUpdateInput={ContactsGroupsActions.addContactSearchUpdated}
        style={{ width: '100%', padding: '0 10px', fontSize: 18 }}
      />
      <IconButton
        style={{ alignSelf: 'center' }}
        onClick={() => ContactGroupActions.toggleAddContact()}
      >
        <SearchIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
    </FlexBoxCenter>
  ) : (
    <FlexBoxCenter style={{ backgroundColor: 'white', height: 56 }}>
      <SearchBar
        onTypingEnd={ContactsGroupsActions.contactListSearchUpdated}
        style={{
          padding: '10px 20px',
          fontSize: 18,
          flex: '1 1 auto',
          color: uhColors.hint,
        }}
        iconStyle={{ height: 20, width: 20, marginRight: 31 }}
      />
      <IconButton
        style={{ alignSelf: 'center' }}
        onClick={() => ContactGroupActions.toggleAddContact()}
      >
        <PersonAddIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
    </FlexBoxCenter>
  );
}

function GroupsTabSmall({
  addContactContactList,
  allContactGroups,
  allContactsCount,
  contactGroup,
  contacts,
  currentPage,
  isAddingContact,
  isSaving,
  perPage,
  selectedContacts,
  totalCount,
  intl,
}) {
  const isAddingGroup = contactGroup.id === null;
  const deletable = contactGroup.id !== -1 && !contactGroup.contact_filter_id;

  return (
    <div>
      <Divider />
      <ContactGroupToolbar
        allContactsCount={allContactsCount}
        contactGroups={allContactGroups}
        isSaving={isSaving}
        selectedContacts={selectedContacts}
        selectedGroup={contactGroup}
        smallScreen
      />
      <Divider />
      {isAddingGroup && (
        <FlexBoxJustify style={{ margin: '16px auto 0 auto', width: '100%' }}>
          <Button
            color="default"
            variant="contained"
            disabled={isSaving}
            key="cancel_add_group"
            style={styles.cancelGroupButton}
            onClick={() => ContactGroupActions.resetContactGroup()}
          >
            {t('.cancel', intl, __filenamespace)}
          </Button>
          <Button
            variant="contained"
            disabled={!contactGroup.name || isSaving}
            key="save_group"
            style={styles.saveGroupButton}
            onClick={() => ContactGroupActions.create({})}
          >
            {t('.save', intl, __filenamespace)}
          </Button>
        </FlexBoxJustify>
      )}

      {!isAddingGroup && (
        <div>
          <SearchAndAddBar
            addContactContactList={addContactContactList}
            isAddingContact={isAddingContact}
            isAddingGroup={isAddingGroup}
          />
          <Divider />
          <div style={{ width: '100%', margin: 'auto' }}>
            {contacts.map(contact => (
              <ContactCard
                key={contact.id}
                deletable={deletable}
                contact={contact}
                intl={intl}
              />
            ))}
          </div>
          <Paginator
            perPage={perPage}
            currentPage={currentPage}
            totalCount={totalCount}
            onPageSelect={ContactsGroupsActions.contactListPageSelected}
          />
        </div>
      )}
    </div>
  );
}

export default injectIntl(GroupsTabSmall);
