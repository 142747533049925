import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  rowTopText: {
    margin: 0,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 3,
  },
};

function EventLink({ event, showDeletedEvents }) {
  const eventLink = currentUser().isClient() ? event.path : event.admin_path;
  return event.status !== 'deleted' ||
    (showDeletedEvents && event.status === 'deleted') ? (
    <ReactRouterLink to={eventLink}>{event.title}</ReactRouterLink>
  ) : (
    <span style={merge(styles.rowTopText)}>{event.title}</span>
  );
}

export default EventLink;
