import { List, Map } from 'immutable';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'additional_emails',
    data: params,
    success: onSuccess(
      data => List(data.additional_emails).map(d => Map(d)),
      success
    ),
    error,
  });
};

export const createEmail = ({ params, success, error }) => {
  uhApiClient.post({
    url: 'additional_emails',
    data: JSON.stringify(params),
    success: onSuccess(data => Map(data), success),
    error,
  });
};

export const updateEmail = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `additional_emails/${id}`,
    data: JSON.stringify(params),
    success: onSuccess(data => Map(data), success),
    error,
  });
};

export const deleteEmail = ({ id, success, error }) => {
  uhApiClient.delete({
    url: `additional_emails/${id}`,
    success: onSuccess(data => Map(data), success),
    error,
  });
};
