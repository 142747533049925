import moment from 'moment-timezone';
import debounce from 'lodash.debounce';
import { OrderedSet, Map, Set } from 'immutable';

import EventClass from 'shared/records/EventClass';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import {
  ClassesSource,
  SessionSource,
  ScheduleSource,
  StaffSource,
  ResourceSource,
  LocationSource,
  EventSource,
  ClientSource,
  ClassesActions,
} from 'sources';

import { EventDataStore } from 'dataStores';

import ClassesListActions from './Actions';

export const VIEW_MODES = {
  SESSIONS: 'sessions',
  SCHEDULES: 'schedules',
};

export const CLASS_TABS = {
  SCHEDULE: 'schedule',
  ROSTER: 'roster',
  COMPLETED: 'completed',
  STAFF: 'staff',
};

class ClassesListStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      mounted: ClassesListActions.mounted,
      switchMode: ClassesListActions.switchMode,
      listClasses: ClassesListActions.listClasses,
      listClassesSuccess: ClassesListActions.listClassesSuccess,
      listSessions: ClassesListActions.listSessions,
      listSessionsWithStatus: ClassesListActions.listSessionsWithStatus,
      listSessionsSuccess: ClassesListActions.listSessionsSuccess,
      selectClass: ClassesListActions.selectClass,
      sessionsPageChange: ClassesListActions.sessionsPageChange,
      schedulePageChange: ClassesListActions.schedulePageChange,
      listSchedules: ClassesListActions.listSchedules,
      listSchedulesSuccess: ClassesListActions.listSchedulesSuccess,
      listSchedulesError: ClassesListActions.listSchedulesError,
      listStaff: ClassesListActions.listStaff,
      listStaffSuccess: ClassesListActions.listStaffSuccess,
      listStaffError: ClassesListActions.listStaffError,
      listResources: ClassesListActions.listResources,
      listResourcesSuccess: ClassesListActions.listResourcesSuccess,
      listResourcesError: ClassesListActions.listResourcesError,
      listLocations: ClassesListActions.listLocations,
      listLocationsSuccess: ClassesListActions.listLocationsSuccess,
      listLocationsError: ClassesListActions.listLocationsError,
      toggleSessionSummaryDrawer: ClassesListActions.toggleSessionSummaryDrawer,
      searchSessions: ClassesListActions.searchSessions,
      statusFilterChange: ClassesListActions.statusFilterChange,
      staffFilterChange: ClassesListActions.staffFilterChange,
      resourceFilterChange: ClassesListActions.resourceFilterChange,
      locationFilterChange: ClassesListActions.locationFilterChange,
      fetchEvent: ClassesListActions.fetchEvent,
      fetchEventSuccess: ClassesListActions.fetchEventSuccess,
      fetchEventError: ClassesListActions.fetchEventError,
      handleTabChange: ClassesListActions.handleTabChange,
      listClassRoster: ClassesListActions.listClassRoster,
      listClassRosterSuccess: ClassesListActions.listClassRosterSuccess,
      listClassRosterError: ClassesListActions.listClassRosterError,
      rosterPageChange: ClassesListActions.rosterPageChange,
      searchRoster: ClassesListActions.searchRoster,
      staffPageChange: ClassesListActions.staffPageChange,
      searchStaff: ClassesListActions.searchStaff,
      searchClasses: ClassesListActions.searchClasses,
      setParams: ClassesListActions.setParams,
    });
  }

  reset() {
    this.listUpcoming = true;
    this.viewMode = VIEW_MODES.SESSIONS;
    this.loadingEvent = false;
    this.loadingClasses = false;
    this.loadingSessions = false;
    this.loadingSchedules = false;
    this.loadingStaff = false;
    this.loadingResources = false;
    this.loadingLocations = false;
    this.loadingRoster = false;
    this.hasMoreClasses = false;
    this.classesIds = OrderedSet();
    this.sessionsIds = OrderedSet();
    this.schedulesIds = OrderedSet();
    this.viewSchedulesIds = OrderedSet();
    this.staffIds = OrderedSet();
    this.resourcesIds = OrderedSet();
    this.locationsIds = OrderedSet();
    this.rosterClientIds = OrderedSet();
    this.selectedClass = null;
    this.selectedSessionId = null;
    this.selectedEventId = null;
    this.searchText = '';
    this.searchRosterText = '';
    this.searchStaffText = '';
    this.searchClassText = '';
    this.statusFilter = 'active';
    this.staffFilter = 'all';
    this.resourceFilter = 'all';
    this.locationFilter = 'all';
    this.selectedTab = CLASS_TABS.ROSTER;
    this.defaultSelectedTab = 'details';
    this.sessionsParams = Map({
      statuses: Set(['standard', 'conflicting', 'non_conforming']),
      fields: Set(['note', 'client_note']),
    });
    this.debouncedSessionsList = debounce(this.listSessions, 800);
    this.debouncedListRoster = debounce(this.listClassRoster, 800);
    this.debouncedListClasses = debounce(this.listClasses, 800);
    this.debouncedListStaff = debounce(
      () => this.listStaff({ listAll: false }),
      800
    );
    this.pagination = Map({
      classes: {
        page: 0,
        totalCount: 0,
        perPage: 15,
      },
      sessions: {
        page: 1,
        totalCount: 0,
        perPage: 15,
      },
      roster: {
        page: 1,
        totalCount: 0,
        perPage: 15,
      },
      schedules: {
        page: 1,
        totalCount: 0,
        perPage: 50,
      },
      staff: {
        page: 1,
        totalCount: 0,
        perPage: 50,
      },
      resources: {
        page: 1,
        totalCount: 0,
        perPage: 50,
      },
      locations: {
        page: 1,
        totalCount: 0,
        perPage: 50,
      },
    });
  }

  mounted(classSlug) {
    this.reset();
    this.mountedClassSelect(classSlug);
    this.listClasses();
    this.preselectedClassInit();
  }

  mountedClassSelect(classSlug) {
    const classId = parseInt(classSlug?.split('-')[0], 10) || null;

    if (classId) {
      this.selectClass({
        classItem: new EventClass({ id: classId }),
        isPreselect: true,
      });
    }
  }

  setParams({ paramKey, valueKey, value }) {
    this[paramKey] = this[paramKey].set(valueKey, value);
  }

  setPagination({ key, page = 0, perPage = 25, totalCount = 0 }) {
    this.pagination = this.pagination.set(key, {
      page,
      perPage,
      totalCount,
    });
  }

  switchMode(mode) {
    this.viewMode = mode;
    this.viewSchedulesIds = OrderedSet();

    if (mode === VIEW_MODES.SCHEDULES) {
      this.setPagination({
        key: 'schedules',
        page: 1,
        perPage: 15,
        totalCount: 0,
      });
      this.listSchedules({
        eventId: this.selectedClass.id,
        listUpcoming: this.listUpcoming,
      });
    } else {
      this.resetPagination();
      this.listSessions({ listUpcoming: this.listUpcoming });
    }
  }

  preselectedClassInit() {
    if (this.selectedClass) {
      this.loadingEvent = true;
      EventSource.fetch({
        id: this.selectedClass.id,
        success: ClassesListActions.fetchEventSuccess,
        error: ClassesListActions.fetchEventError,
      });
      this.listClassRoster();
    }
  }

  selectClass({ classItem, isPreselect = false }) {
    this.selectedClass = classItem;
    this.sessionsIds = OrderedSet();
    this.staffIds = OrderedSet();
    this.resourcesIds = OrderedSet();
    this.rosterClientIds = OrderedSet();
    this.resourceFilter = 'all';
    this.staffFilter = 'all';
    this.locationFilter = 'all';
    this.searchStaffText = '';
    this.searchRosterText = '';
    this.searchText = '';
    this.viewMode = VIEW_MODES.SESSIONS;
    this.selectedTab = CLASS_TABS.ROSTER;
    this.resetPagination();
    this.setParams({
      paramKey: 'sessionsParams',
      valueKey: 'statuses',
      value: Set(['standard', 'conflicting', 'non_conforming']),
    });

    if (!isPreselect) {
      this.fetchEvent();
      this.listClassRoster();
    }
  }

  handleTabChange(tab) {
    this.viewMode = VIEW_MODES.SESSIONS;
    this.resetPagination();
    this.selectedTab = tab;
  }

  /* Event handlers */

  fetchEvent() {
    const { events } = EventDataStore.getState();
    const event = events.get(this.selectedClass.id, false);

    if (!event) {
      this.loadingEvent = true;
      EventSource.fetch({
        id: this.selectedClass.id,
        success: ClassesListActions.fetchEventSuccess,
        error: ClassesListActions.fetchEventError,
      });
    }
  }

  fetchEventSuccess(event) {
    ClassesActions.fetchSuccess.defer(new EventClass(event.toJS()));
    this.loadingEvent = false;
  }

  fetchEventError() {
    this.loadingEvent = false;
  }

  /* Classes handlers */
  listClasses() {
    const pagination = this.pagination.get('classes');
    const params = {
      per_page: pagination.perPage,
      page: pagination.page + 1,
      title: this.searchClassText,
    };

    if (this.statusFilter !== 'all') {
      params.status = this.statusFilter;
    }

    this.loadingClasses = true;
    ClassesSource.list({
      params,
      success: ClassesListActions.listClassesSuccess,
      error: ClassesListActions.listClassesError,
    });
  }

  listClassesSuccess({ page, perPage, totalCount, class_events: classes }) {
    if (page !== 1) {
      this.classesIds = this.classesIds.concat(
        classes.map(item => item.id).toOrderedSet()
      );
    } else {
      this.classesIds = classes.map(item => item.id).toOrderedSet();
    }

    this.loadingClasses = false;
    this.hasMoreClasses = this.classesIds.size < totalCount;
    this.setPagination({ key: 'classes', page, perPage, totalCount });
  }

  listClassesError() {
    this.loadingClasses = false;
  }

  /* Sessions handlers */
  listSessions({ listUpcoming = true }) {
    if (!this.selectedClass) {
      return;
    }
    this.listUpcoming = listUpcoming;

    const pagination = this.pagination.get('sessions');
    const params = {
      event_ids: [this.selectedClass.id],
      page: pagination.page,
      per_page: pagination.perPage,
      search: this.searchText,
      ...this.sessionsParams.toJS(),
    };

    if (this.listUpcoming) {
      params.start_time = moment().toISOString();
    } else {
      params.end_time = moment().toISOString();
      params.order_direction = 'desc';
    }

    if (this.staffFilter !== 'all') {
      params.staff_ids = [this.staffFilter];
    }

    if (this.resourceFilter !== 'all') {
      params.resource_ids = [this.resourceFilter];
    }

    this.loadingSessions = true;

    SessionSource.list({
      params,
      success: ClassesListActions.listSessionsSuccess,
      error: ClassesListActions.listSessionsError,
    });
  }

  listSessionsWithStatus({ statuses }) {
    const sessionStatuses = this.sessionsParams.get('statuses').toJS();
    const newStatuses = statuses
      ? [...sessionStatuses, ...statuses]
      : ['standard', 'conflicting', 'non_conforming'];

    this.setParams({
      paramKey: 'sessionsParams',
      valueKey: 'statuses',
      value: Set(newStatuses),
    });
    this.setPagination({ key: 'sessions', page: 1, perPage: 15 });
    this.listSessions({ listUpcoming: this.listUpcoming });
  }

  listSessionsSuccess({ page, perPage, totalCount, sessions }) {
    this.sessionsIds = sessions.map(session => session.id).toOrderedSet();
    this.schedulesIds = sessions
      .map(session => session.schedule_id)
      .toOrderedSet();

    if (this.schedulesIds.size > 0) {
      this.listSchedules({});
    }

    this.loadingSessions = false;
    this.setPagination({ key: 'sessions', page, perPage, totalCount });
  }

  listSessionsError() {
    this.loadingSessions = false;
  }

  sessionsPageChange([page, perPage]) {
    this.setPagination({ key: 'sessions', page, perPage });
    this.listSessions({ listUpcoming: this.listUpcoming });
  }

  schedulePageChange([page, perPage]) {
    this.setPagination({ key: 'schedules', page, perPage });
    this.listSchedules({ eventId: this.selectedClass.id });
  }

  toggleSessionSummaryDrawer({ sessionId, eventId, defaultSelectedTab = 0 }) {
    this.selectedSessionId = sessionId;
    this.selectedEventId = eventId;
    this.defaultSelectedTab = defaultSelectedTab;
  }

  /* Schedules handlers */
  listSchedules({ eventId = null }) {
    const pagination = this.pagination.get('schedules');
    const params = {
      fields: [
        'schedule_resources',
        'schedule_staff',
        'future_registration_count',
        'total_registration_count',
      ],
      page: pagination.page,
      per_page: pagination.perPage,
    };

    if (!eventId) {
      params.ids = this.schedulesIds.toArray();
    } else {
      params.event_ids = [eventId];
    }

    if (!this.listUpcoming) {
      params.end_date_max = moment().toISOString();
    } else {
      params.end_date_min = moment().toISOString();
    }

    if (this.staffFilter !== 'all' && eventId) {
      params.staff_ids = [this.staffFilter];
    }

    if (this.resourceFilter !== 'all' && eventId) {
      params.resource_ids = [this.resourceFilter];
    }

    if (this.locationFilter !== 'all' && eventId) {
      params.location_ids = [this.locationFilter];
    }

    this.loadingSchedules = true;
    ScheduleSource.list({
      params,
      success: {
        action: ClassesListActions.listSchedulesSuccess,
        args: [eventId],
      },
      error: ClassesListActions.listSchedulesError,
    });
  }

  listSchedulesSuccess([{ page, perPage, totalCount, schedules }, eventId]) {
    if (eventId) {
      this.viewSchedulesIds = schedules.map(s => s.id).toOrderedSet();
      this.setPagination({ key: 'schedules', page, perPage, totalCount });
      this.loadingSchedules = false;
      this.listLocations();
      return;
    }

    if (page * perPage < totalCount) {
      this.setPagination({ key: 'schedules', page: page + 1, perPage });
      this.listSchedules({});
    } else {
      this.loadingSchedules = false;
    }
  }

  listSchedulesError() {
    this.setPagination({ key: 'schedules', page: 1, perPage: 50 });
    this.loadingSchedules = false;
  }

  /* Staff handlers */

  listStaff({ listAll = true }) {
    let pagination = this.pagination.get('staff');

    if (!listAll && pagination.page === 1) {
      this.setPagination({ key: 'staff', page: 1, perPage: 15 });
      pagination = this.pagination.get('staff');
    }

    if (listAll && pagination.page === 1) {
      this.setPagination({ key: 'staff', page: 1, perPage: 50 });
      pagination = this.pagination.get('staff');
    }

    this.loadingStaff = true;
    StaffSource.list({
      params: {
        event_ids: [this.selectedClass.id],
        page: pagination.page,
        per_page: pagination.perPage,
        search: this.searchStaffText,
      },
      success: {
        action: ClassesListActions.listStaffSuccess,
        args: [listAll],
      },
      error: ClassesListActions.listStaffError,
    });
  }

  listStaffSuccess([{ staff, page, perPage, totalCount }, listAll]) {
    if (listAll) {
      this.staffIds = this.staffIds.merge(staff.map(s => s.id).toOrderedSet());

      if (page * perPage < totalCount) {
        this.setPagination({ key: 'staff', page: page + 1, perPage });
        this.listStaff({});
      } else {
        this.loadingStaff = false;
      }
    } else {
      this.staffIds = staff.map(s => s.id).toOrderedSet();
      this.setPagination({ key: 'staff', page, perPage, totalCount });
      this.loadingStaff = false;
    }
  }

  listStaffError() {
    this.setPagination({ key: 'staff', page: 1, perPage: 50 });
    this.loadingStaff = false;
  }

  staffPageChange([page, perPage]) {
    this.setPagination({ key: 'staff', page, perPage });
    this.listStaff({ listAll: false });
  }

  /* Resources handlers */
  listResources() {
    const pagination = this.pagination.get('resources');

    this.loadingResources = true;
    ResourceSource.list({
      params: {
        event_ids: [this.selectedClass.id],
        page: pagination.page,
        per_page: pagination.perPage,
      },
      success: ClassesListActions.listResourcesSuccess,
    });
  }

  listResourcesSuccess({ resources, page, perPage, totalCount }) {
    this.resourcesIds = this.resourcesIds.merge(
      resources.map(r => r.id).toOrderedSet()
    );
    if (page * perPage < totalCount) {
      this.setPagination({ key: 'resources', page: page + 1, perPage });
      this.listResources();
    } else {
      this.loadingResources = false;
    }
  }

  listResourcesError() {
    this.loadingResources = false;
  }

  /* Locations handlers */
  listLocations() {
    const pagination = this.pagination.get('locations');

    this.loadingLocations = true;
    LocationSource.list({
      params: {
        page: pagination.page,
        per_page: pagination.perPage,
      },
      success: ClassesListActions.listLocationsSuccess,
      error: ClassesListActions.listLocationsError,
    });
  }

  listLocationsSuccess({ locations, page, perPage, totalCount }) {
    this.locationsIds = this.locationsIds.merge(
      locations.map(l => l.id).toOrderedSet()
    );
    if (page * perPage < totalCount) {
      this.setPagination({ key: 'locations', page: page + 1, perPage });
      this.listLocations();
    } else {
      this.loadingLocations = false;
    }
  }

  listLocationsError() {
    this.loadingLocations = false;
  }

  /* Roster handlers */
  listClassRoster() {
    const pagination = this.pagination.get('roster');

    this.loadingRoster = true;
    ClientSource.list({
      params: {
        event_ids: [this.selectedClass.id],
        page: pagination.page,
        per_page: pagination.perPage,
        search: this.searchRosterText,
      },
      success: ClassesListActions.listClassRosterSuccess,
      error: ClassesListActions.listClassRosterError,
    });
  }

  listClassRosterSuccess({ clients, page, perPage, totalCount }) {
    this.rosterClientIds = clients.map(c => c.id).toOrderedSet();
    this.setPagination({ key: 'roster', page, perPage, totalCount });
    this.loadingRoster = false;
  }

  listClassRosterError() {
    this.loadingRoster = false;
  }

  rosterPageChange([page, perPage]) {
    this.setPagination({ key: 'roster', page, perPage });
    this.listClassRoster();
  }

  /* Search handlers */
  searchSessions(searchText) {
    this.searchText = searchText;
    this.setPagination({ key: 'sessions', page: 1, perPage: 15 });
    this.setPagination({ key: 'schedules', page: 1, perPage: 50 });
    this.debouncedSessionsList({ listUpcoming: this.listUpcoming });
  }

  searchRoster(searchText) {
    this.searchRosterText = searchText;
    this.setPagination({ key: 'roster', page: 1, perPage: 15 });
    this.debouncedListRoster();
  }

  searchStaff(searchText) {
    this.searchStaffText = searchText;
    this.setPagination({ key: 'staff', page: 1, perPage: 50 });
    this.debouncedListStaff();
  }

  searchClasses(searchText) {
    this.searchClassText = searchText;
    this.setPagination({ key: 'classes', page: 0, perPage: 15 });
    this.debouncedListClasses();
  }

  /* Filter classes by status */
  statusFilterChange(status) {
    this.statusFilter = status;
    this.classesIds = OrderedSet();
    this.selectedClass = null;
    this.setPagination({ key: 'classes', page: 0, perPage: 15 });
    this.resetPagination();
    this.listClasses();
  }

  /* Filter sessions by staff */
  staffFilterChange(staffId) {
    this.staffFilter = staffId;

    if (this.viewMode === VIEW_MODES.SESSIONS) {
      this.listSessions({ listUpcoming: this.listUpcoming });
    } else {
      this.listSchedules({ eventId: this.selectedClass.id });
    }
  }

  /* Filter sessions by resource */
  resourceFilterChange(resourceId) {
    this.resourceFilter = resourceId;

    if (this.viewMode === VIEW_MODES.SESSIONS) {
      this.listSessions({ listUpcoming: this.listUpcoming });
    } else {
      this.listSchedules({ eventId: this.selectedClass.id });
    }
  }

  /* Filter schedules by location */
  locationFilterChange(locationId) {
    this.locationFilter = locationId;
    this.listSchedules({ eventId: this.selectedClass.id });
  }

  resetPagination() {
    this.setPagination({ key: 'sessions', page: 1, perPage: 15 });
    this.setPagination({ key: 'roster', page: 1, perPage: 15 });
    this.setPagination({ key: 'staff', page: 1, perPage: 50 });
    this.setPagination({ key: 'resources', page: 1, perPage: 50 });
    this.setPagination({ key: 'schedules', page: 1, perPage: 50 });
  }
}

export default alt.createStore(ClassesListStore, 'ClassesListStore');
