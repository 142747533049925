import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { messageId, t } from 'shared/utils/LocaleUtils';

function SubscriptionStatusText({
  subscriptionStatus,
  membershipSubscription,
  intl,
}) {
  if (subscriptionStatus === 'cancelled_at') {
    return (
      <FormattedMessage
        id={messageId(`.${subscriptionStatus}`, __filenamespace)}
        values={{
          date: membershipSubscription.futureCancellingDateToShow(),
        }}
      />
    );
  }
  if (
    subscriptionStatus === 'suspending' ||
    subscriptionStatus === 'suspended_until'
  ) {
    const suspendedDate = membershipSubscription.is_suspended
      ? moment(membershipSubscription.reactivate_on).utc()
      : moment(membershipSubscription.suspended_at);

    return (
      <FormattedMessage
        id={messageId(`.${subscriptionStatus}`, __filenamespace)}
        values={{
          date: suspendedDate.format('MM/DD/YYYY'),
        }}
      />
    );
  }
  return t(`.${subscriptionStatus}`, intl, __filenamespace);
}

export default SubscriptionStatusText;
