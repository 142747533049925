import * as React from 'react';
import Popover from '@mui/material/Popover';

import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';

import OWNER_TYPE from 'calendar/types/OwnerType.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Sticky: {
    top: 0,
    zIndex: 1,
    position: 'sticky',
  },
  StickyInnerDiv: {
    position: 'absolute',
    alignItems: 'center',
    width: '100%',
    height: '48px',
    padding: '16px',
    backgroundColor: 'white',
    borderBottom: '2px solid #e3e3e3',
    borderRight: '1px solid #e3e3e3',
  },
  EventIcon: {
    margin: '0 6px 0 0',
    width: '15px',
    height: '15px',
    color: uhColors.iconGrey,
  },
  PersonIcon: {
    margin: '0 6px 0 16px',
    width: '15px',
    height: '15px',
    color: uhColors.iconGrey,
  },
  OwnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  OwnerName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'default',
  },
  OwnerTimesContainer: {
    display: 'flex',
    marginTop: '4px',
  },
  OwnerPopover: {
    height: 48,
    minWidth: 150,
    left: '-31415px',
  },
  OwnerPopoverContent: {
    height: 48,
    padding: 10,
  },
};

function FooterCell({ calendar, eventTimes, owner, smallCellMode }) {
  const [anchorEl, setAnchor] = React.useState(null);

  const handleMouseOver = e => {
    if (e.currentTarget !== anchorEl) {
      const nameEl = document.getElementById(owner.id);
      const isOverflowed = nameEl?.scrollWidth > nameEl?.clientWidth;

      if (isOverflowed) setAnchor(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchor(null);
  };

  switch (calendar.ownerType) {
    case OWNER_TYPE.STAFF:
      return (
        <>
          <div style={styles.Sticky}>
            <FlexBoxCenter
              className="day-footer-cell"
              style={styles.StickyInnerDiv}
              onMouseOver={handleMouseOver}
              onMouseMove={handleMouseOver}
            >
              {owner && !smallCellMode && <UserAvatar user={owner} size={24} />}
              <div style={styles.OwnerContainer}>
                <p style={styles.OwnerName} id={owner.id}>
                  {owner.name()}
                </p>
                <div style={styles.OwnerTimesContainer}>
                  <EventIcon sx={styles.EventIcon} />
                  {eventTimes.size}
                  <PersonIcon sx={styles.PersonIcon} />
                  {eventTimes.flatMap(e => e.clientIds()).size}
                </div>
              </div>
            </FlexBoxCenter>
          </div>
          {smallCellMode && (
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              onClose={handleClose}
              open={!!anchorEl}
              style={styles.OwnerPopover}
            >
              <FlexBoxCenter
                style={styles.OwnerPopoverContent}
                onMouseLeave={handleClose}
              >
                {owner && <UserAvatar user={owner} size={24} />}
                <div style={styles.OwnerContainer}>
                  <p style={styles.OwnerName}>{owner.name()}</p>
                  <div style={styles.OwnerTimesContainer}>
                    <EventIcon sx={styles.EventIcon} />
                    {eventTimes.size}
                    <PersonIcon sx={styles.PersonIcon} />
                    {eventTimes.flatMap(e => e.clientIds()).size}
                  </div>
                </div>
              </FlexBoxCenter>
            </Popover>
          )}
        </>
      );
    default:
      return null;
  }
}

export default FooterCell;
