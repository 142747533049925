import * as React from 'react';
import { injectIntl } from 'react-intl';
import { TextField as PMTextField } from '@upperhand/playmaker';
import { t } from 'shared/utils/LocaleUtils.js';

// TextField configuration options:
//  - AccountSetupPaysafeStore.fields.{fieldName}.value
//  - AccountSetupPaysafeStore.fields.{fieldName}.error
//  - __filenamespace.{fieldName}_label
//  - __filenamespace.{fieldName}_hint are in intl
function TextField({
  intl,
  fieldName,
  errorKey,
  value,
  type = 'text',
  onChange,
  autoComplete,
}) {
  let errorText = '';
  if (errorKey === 'required') {
    errorText = t('.required', intl, __filenamespace);
  } else if (errorKey !== '') {
    errorText = t(`.${fieldName}_${errorKey}`, intl, __filenamespace);
  }

  return (
    <PMTextField
      name={fieldName}
      type={type}
      autoComplete={autoComplete}
      onChange={e => onChange(fieldName, e.target.value)}
      value={value}
      fullWidth
      label={t(`.${fieldName}_label`, intl, __filenamespace)}
      placeholder={t(`.${fieldName}_hint`, intl, __filenamespace)}
      errorText={errorText}
    />
  );
}

export default injectIntl(TextField);
