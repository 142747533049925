/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import clsx from 'clsx';
import AltContainer from 'alt-container';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Map, Set } from 'immutable';
import { Button, Grid, TextField, Typography } from '@upperhand/playmaker';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HiddenInCheckoutIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import HiddenInPointOfSaleIcon from '@mui/icons-material/VisibilityOff';

import BalanceDetailsDrawer from 'containers/reports/balanceDetailsDrawer/BalanceDetailsDrawer.jsx';
import BalanceListDrawer from 'containers/reports/balanceListDrawer/BalanceListDrawer.jsx';
import ClientCard from 'credit_passes/components/_ClientCard.jsx';
import PaymentDetailsDrawer from 'containers/reports/paymentDetailsDrawer/PaymentDetailsDrawer.jsx';
import ColumnarForm from 'shared/components/ColumnarForm.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import CreditPassActions from 'shared/actions/CreditPassActions.js';
import CreditPassCredits from 'credit_passes/components/_CreditPassCredits.jsx';
import CreditPassExpiration from 'credit_passes/components/_CreditPassExpiration.jsx';
import CreditPassViewingActions from 'credit_passes/actions/CreditPassViewingActions.js';
import CreditPassViewingStore from 'credit_passes/stores/CreditPassViewingStore.jsx';
import CreditPassViewingUIStore from 'credit_passes/stores/CreditPassViewingUIStore.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import NoClientsIcon from 'shared/components/icons/empty_states/CreditPassClients.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import AlignedBox from 'shared/components/AlignedBox.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, smallScreen } from 'shared/utils/DOMUtils';
import { copyTextToClipboard } from 'shared/utils/ClipboardUtils.js';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { shortHumanUrl } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';

import { CreditPassDataStore } from 'dataStores';
import '../styles.scss';

const styles = {
  clientCountContent: {
    color: uhColors.leftNavGrey,
    fontSize: 22,
    lineHeight: '26px',
  },
  clientsHeader: {
    color: uhColors.iconGrey,
    fontSize: 12,
    height: 15,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  clientsWrapper: {
    flex: '1 0 auto',
    margin: '0px 10px',
    marginBottom: 5,
  },
  columnarForm: {
    root: {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
    column: {
      margin: `${LEFT_MARGIN_PIXELS}px 19px 0px 19px`,
    },
    column0: {
      flex: '1 1 30%',
    },
    column1: {
      flex: '1 1 60%',
    },
    contentRow: {
      marginLeft: '-19px',
      marginRight: '-19px',
    },
  },
  contactSearch: {
    fontSize: 18,
    paddingLeft: 20,
  },
  description: {
    lineHeight: '22px',
  },
  creditContainer: {
    marginLeft: -4,
  },
  freeSessions: {
    lineHeight: '22px',
  },
  header: {
    color: uhColors.leftNavGrey,
    display: 'inline',
    fontSize: 22,
    fontFamily: 'benton-sans-condensed, sans-serif',
    lineHeight: '25px',
    fontWeight: 'bold',
  },
  lockIcon: {
    marginRight: 13,
    marginLeft: 2,
  },
  lockIconContainer: {
    marginTop: 4,
  },
  creditPassClientCard: {
    fontSize: 14,
  },
  openInNewIcon: {
    width: 15,
    height: 15,
    color: uhColors.activeBlue,
  },
  paginator: {
    marginBottom: 10,
    bottom: 0,
  },
  pricing: {
    display: 'inline',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '20px',
    marginLeft: 8,
    position: 'relative',
    top: -3,
  },
  max_puchases: {
    color: uhColors.leftNavGrey,
    display: 'inline',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '16px',
    marginLeft: 8,
    position: 'relative',
    top: -3,
  },
  revenueAndClientsWrapper: {
    flexWrap: 'wrap',
    margin: '10px -10px -10px',
  },
  revenueContent: {
    color: uhColors.leftNavGrey,
    fontSize: 22,
    lineHeight: '26px',
  },
  revenueHeader: {
    color: uhColors.iconGrey,
    fontSize: 12,
    height: 15,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  revenueWrapper: {
    flex: '1 0 auto',
    margin: '0px 10px',
    marginBottom: 5,
  },
  searchBar: {
    padding: '0 20px',
    fontSize: 18,
    flex: '1 1 auto',
  },
  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },
  sectionSpacing: {
    margin: '20px 0px',
  },
  separationAtTop: {
    marginTop: 10,
  },
  copyButton: {
    cursor: 'pointer',
  },
  spacerLine: {
    border: `1px solid ${uhColors.lighterGrey}`,
    width: '100%',
  },
  url: {
    color: uhColors.iconGrey,
    display: 'inline',
    fontSize: 15,
    lineHeight: '18px',
    marginLeft: 11,
    position: 'relative',
    top: -3,
  },
  hiddenInCheckoutIcon: {
    height: 16,
    width: 16,
    marginRight: '10px',
  },
  editIcon: {
    width: 18,
    height: 18,
    color: uhColors.activeBlue,
  },
  deleteCreditPassContainer: {
    marginTop: 10,
    cursor: 'pointer',
  },
  trashIcon: {
    height: 20,
    width: 20,
    position: 'relative',
    left: -2,
    color: uhColors.darkRed,
  },
  removeText: {
    marginLeft: 6,
    fontWeight: 'bold',
  },
  creditPassExpiration: {
    display: 'inline',
    position: 'relative',
    top: -3,
  },
};

const editUrl = cp => customerScopedRoute(`/credit_passes/${cp.id}/edit`);

function Header({ creditPass }) {
  return (
    <div className="cp-view-header">
      <Grid container>
        <Grid item sm={12} md={10}>
          <div className="cp-view-name" style={styles.header}>
            {creditPass.name}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function PricingAndExpiration({ creditPass }) {
  return (
    <div className="cp-view-pricing-expiration" style={styles.separationAtTop}>
      <ReactRouterLink to={editUrl(creditPass)}>
        <EditIcon style={styles.editIcon} />
      </ReactRouterLink>
      <div className="cp-view-price" style={styles.pricing}>
        {creditPass.price ? (
          <FormattedCurrency value={creditPass.price} fromCents />
        ) : (
          <FormattedMessage id={messageId('.free', __filenamespace)} />
        )}
      </div>
      <span>&ensp; | &ensp;</span>
      <div style={styles.max_puchases}>
        {creditPass.max_num_of_purchases}
        <FormattedMessage id={messageId('.maxPurchases', __filenamespace)} />
      </div>
      <CreditPassExpiration
        creditPassId={creditPass.id}
        leftBorder
        style={styles.creditPassExpiration}
      />
    </div>
  );
}

function PageTitle({ creditPassStore: { record } }) {
  return (
    <Grid container item className="credit-pass-container__header">
      <Grid container item xs>
        <ReactRouterLink to={customerScopedRoute('credit_passes')}>
          <Button
            type="tertiary"
            disableRipple
            classes={{ root: 'credit-pass-container__back-btn' }}
            icon="arrowLeft"
          />
        </ReactRouterLink>
        <Typography
          variant="h4"
          className={clsx(
            'credit-pass-container__name',
            smallScreen() && 'credit-pass-container__mobile-name'
          )}
        >
          {record.name || 'new'}
        </Typography>
      </Grid>
    </Grid>
  );
}

function Url({ creditPass, intl }) {
  return (
    <CopyToClipboard
      onCopy={() =>
        MessageWindowActions.addMessage(t('.url_copied', intl, __filenamespace))
      }
      style={merge(styles.separationAtTop, styles.copyButton)}
      text={creditPass.url()}
    >
      <FlexBox className="cp-view-signup-url">
        <OpenInNewIcon style={styles.openInNewIcon} />
        <div style={styles.url}>{shortHumanUrl(creditPass.url(), 48)}</div>
      </FlexBox>
    </CopyToClipboard>
  );
}

function DeleteCreditPass() {
  return (
    <FlexBoxCenter
      style={styles.deleteCreditPassContainer}
      onClick={CreditPassViewingActions.removeRequested}
      className="cp-delete"
    >
      <DeleteIcon style={styles.trashIcon} />
      <span style={styles.removeText}>
        <FormattedMessage
          id={messageId('.remove_credit_pass', __filenamespace)}
        />
      </span>
    </FlexBoxCenter>
  );
}

function SpacerLine() {
  return (
    <div style={styles.sectionSpacing}>
      <hr style={styles.spacerLine} />
    </div>
  );
}

function RevenueAndClientCount({ creditPass }) {
  return (
    <FlexBox
      className="cp-view-revenue-client-count"
      style={styles.revenueAndClientsWrapper}
    >
      <div className="cp-view-revenue" style={styles.revenueWrapper}>
        <div style={styles.revenueHeader}>
          <FormattedMessage id={messageId('.total_revenue', __filenamespace)} />
        </div>
        <div style={styles.revenueContent}>
          <FormattedCurrency value={creditPass.revenue()} fromCents />
        </div>
      </div>
      <div className="cp-view-client-count" style={styles.clientsWrapper}>
        <div style={styles.clientsHeader}>
          <FormattedMessage id={messageId('.client_count', __filenamespace)} />
        </div>
        <div style={styles.clientCountContent}>
          {creditPass.active_client_count}
        </div>
      </div>
    </FlexBox>
  );
}

function Description({ creditPass, style }) {
  return <div style={style}>{creditPass.description}</div>;
}

function CreditPassClients({
  creditPassStore: {
    creditPassId,
    clientDetails,
    clients,
    clientsPage,
    clientsPerPage,
    record,
    searchString,
    totalClientsCount,
  },
  intl,
}) {
  return (
    <div className="cp-view-clients" style={styles.creditPassClientCard}>
      <Grid container item spacing={1}>
        <Grid item xs>
          <TextField
            classes={{
              root: 'credit-pass-container__search-field',
            }}
            fullWidth
            icon="search"
            onChange={event =>
              CreditPassViewingActions.searchStringUpdated(event.target.value)
            }
            placeholder={t('.search', intl, __filenamespace)}
            rounded
            value={searchString}
          />
        </Grid>
      </Grid>
      {clients.size ? (
        clients.map(client => (
          <ClientCard
            key={client.id}
            client={client}
            creditPassId={creditPassId}
            clientDetails={clientDetails.get(client.id, Map())}
          />
        ))
      ) : (
        <EmptyState
          image={<NoClientsIcon />}
          headerText={t('.empty_state_header', intl, __filenamespace)}
          messageText={t('.empty_state_message', intl, __filenamespace)}
          primaryAction={{
            label: t('.copy_url', intl, __filenamespace),
            action: () =>
              copyTextToClipboard(record.url(), () =>
                MessageWindowActions.addMessage(
                  t('.url_copied', intl, __filenamespace)
                )
              ),
          }}
        />
      )}
      <Paginator
        compact
        currentPage={clientsPage || 1}
        onPageSelect={(page, _) =>
          CreditPassViewingActions.requestClientsPage(page)
        }
        perPage={clientsPerPage || 10}
        style={styles.paginator}
        totalCount={totalClientsCount}
      />
    </div>
  );
}
function HiddenInCheckoutIndicator({ creditPass }) {
  if (!hasAdminPermission() || creditPass.published) return null;

  return (
    <AlignedBox>
      <HiddenInCheckoutIcon
        sx={{ ...styles.hiddenInCheckoutIcon, color: 'var(--color-old-gray)' }}
      />
      <FormattedMessage
        id={messageId('.hidden_in_checkout', __filenamespace)}
      />
    </AlignedBox>
  );
}
function HiddenInPosIndicator({ creditPass }) {
  if (!hasAdminPermission() || !creditPass.hide_in_pos) return null;

  return (
    <AlignedBox>
      <HiddenInPointOfSaleIcon
        sx={{ ...styles.hiddenInCheckoutIcon, color: 'var(--color-old-gray)' }}
      />
      <FormattedMessage id={messageId('.hidden_in_pos', __filenamespace)} />
    </AlignedBox>
  );
}
function CreditPassOverview({
  creditPassStore: { record },
  eventTypes,
  events,
  ui,
  intl,
}) {
  return (
    <div>
      <Header creditPass={record} />
      <PricingAndExpiration creditPass={record} />
      <Url creditPass={record} intl={intl} />
      <HiddenInPosIndicator creditPass={record} />
      <HiddenInCheckoutIndicator creditPass={record} />
      {record.active_client_count === 0 && <DeleteCreditPass />}
      <SpacerLine />
      <RevenueAndClientCount creditPass={record} />
      <SpacerLine />
      <Description
        creditPass={record}
        style={merge(styles.sectionSpacing, styles.description)}
      />
      {record.credit_pass_credits.size > 0 && (
        <CreditPassCredits
          creditPass={record}
          eventTypes={eventTypes}
          events={events}
          style={styles.sectionSpacing}
        />
      )}
      <ConfirmationDialog
        awaitingConfirmation={ui.awaitingRemoveConfirmation}
        onCancel={CreditPassViewingActions.removeAborted}
        onConfirm={CreditPassViewingActions.removeConfirmed}
        submitText={t('.remove', intl, __filenamespace)}
        title={t('.remove_credit_pass', intl, __filenamespace)}
      >
        <FormattedMessage
          id={messageId('.remove_credit_pass', __filenamespace)}
        />
      </ConfirmationDialog>
    </div>
  );
}

const isLoading = () => {
  const { isLoading: isLoadingEventType } = EventTypeListingStore.getState();
  const { isLoading: isLoadingEventList } = EventListStore.getState();
  const { isLoading: isLoadingCreditPass } = CreditPassViewingStore.getState();
  const { isLoading: isLoadingCreditPassUI } =
    CreditPassViewingUIStore.getState();

  return (
    isLoadingEventType ||
    isLoadingEventList ||
    isLoadingCreditPass ||
    isLoadingCreditPassUI
  );
};

function Content(props) {
  return (
    <SpinWhileLoading isLoading={isLoading()}>
      <PageTitle {...props} />
      <ColumnarForm
        {...props}
        className="iphone-x-content"
        columns={[<CreditPassOverview />, <CreditPassClients />]}
        style={styles.columnarForm}
      />
    </SpinWhileLoading>
  );
}

class CreditPassViewing extends React.PureComponent {
  componentDidMount() {
    const { creditPassId: id } = this.props;

    if (id) {
      EventTypeListingActions.list();
      CreditPassActions.fetch({ id });
      CreditPassViewingActions.listClients({ id });
    }
  }

  componentDidUpdate(prevProps) {
    const { creditPassId, creditPassStore } = this.props;
    const creditPassEventIds = this.getCreditPassEventIds(creditPassId);

    if (
      creditPassStore.record.id !== null &&
      creditPassStore.record.id !== prevProps.creditPassStore.record.id &&
      creditPassEventIds.length > 0
    ) {
      EventActions.list.defer({
        fields: ['participant_count'],
        ids: creditPassEventIds,
      });
    }
  }

  getCreditPassEventIds(creditPassId) {
    const { creditPassDataStore } = this.props;
    const { creditPasses } = creditPassDataStore;
    const creditPass = creditPasses.get(creditPassId);
    const creditPassCredits = creditPass.get('credit_pass_credits', []);
    const eventCredits = creditPassCredits.filter(c => c.event_ids.size > 0);
    const creditPassEventIds =
      eventCredits.size === 0
        ? []
        : eventCredits.first().get('event_ids', Set()).toJS();
    return creditPassEventIds;
  }

  render() {
    const { intl } = this.props;

    return (
      <div className="credit-pass-container">
        <AltContainer
          stores={{
            creditPassStore: CreditPassViewingStore,
            eventTypes: () => ({
              store: EventTypeListingStore,
              value: EventTypeListingStore.getState().eventTypes,
            }),
            events: () => ({
              store: EventListStore,
              value: EventListStore.getState().events,
            }),
            ui: CreditPassViewingUIStore,
          }}
        >
          <Content intl={intl} />
          <BalanceListDrawer />
          <BalanceDetailsDrawer />
          <PaymentDetailsDrawer />
          <QuickpayModal />
          <RefundModal />
        </AltContainer>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      creditPassDataStore: CreditPassDataStore,
      creditPassStore: CreditPassViewingStore,
    },
  })
)(CreditPassViewing);
