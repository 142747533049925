import CommissionsCreationActions from 'reporting/payroll/actions/Commissions/CommissionsCreationActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import CommissionsEditingStoreInterface from 'reporting/payroll/stores/Commissions/CommissionsEditingStoreInterface';

export default alt.createStore(
  CommissionsEditingStoreInterface,
  'CommissionsCreationStore',
  {
    actions: CommissionsCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
