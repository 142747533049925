class CardReaderEditActions {
  constructor() {
    this.generateActions(
      'mounted',
      'fetchCardReaderSuccess',
      'fetchCardReaderError',
      'openModal',
      'closeModal',
      'submitEdit',
      'updateCardReaderSuccess',
      'updateCardReaderError',
      'setNickname'
    );
  }
}

export default alt.createActions(CardReaderEditActions);
