class POSProductListActions {
  constructor() {
    this.generateActions(
      'cartButtonClicked',
      'creditPassSelected',
      'clearFilters',
      'eventSelected',
      'listByType',
      'listSuccess',
      'listError',
      'membershipSelected',
      'pageSelected',
      'productSelected',
      'searchStringUpdated'
    );
  }
}

export default alt.createActions(POSProductListActions);
