const QuickScheduleActions = alt.generateActions(
  'applyCoupon',
  'applyCouponSuccess',
  'applyCouponError',
  'toggleFeeExemption',
  'toggleFeeExemptionSuccess',
  'toggleFeeExemptionError',
  'toggleTaxExemption',
  'updateTaxExemptSuccess',
  'updateTaxExemptError',
  'taxIdUpdated',
  'updateTaxIdSuccess',
  'updateTaxIdError',
  'fetchCartSuccess',
  'fetchCartError',
  'open',
  'close',
  'updateDateRange',
  'listAvailableEvents',
  'listAvailableEventsSuccess',
  'listAvailableEventsError',
  'listAutomationsSuccess',
  'listAutomationsError',
  'listRegistrationsSuccess',
  'listRegistrationsError',
  'updateRegistration',
  'updateRegistrationSuccess',
  'updateRegistrationError',
  'selectEvent',
  'selectClient',
  'removeClient',
  'selectStaff',
  'removeStaff',
  'selectResource',
  'removeResource',
  'selectAutomationPackage',
  'selectDefaultPackage',
  'saveAndCharge',
  'saveCellWidth',
  'createOrderItemSuccess',
  'createOrderItemError',
  'deleteOrderItem',
  'deleteOrderItemsSuccess',
  'deleteOrderItemsError',
  'deleteOrderSuccess',
  'deleteOrderError',
  'cancelOrderSuccess',
  'cancelOrderError',
  'checkoutSuccess',
  'updateManualDiscount',
  'manageDiscount',
  'updateSearchTerm',
  'manageDiscountSuccess',
  'manageDiscountError',
  'accountCreditsChanged',
  'applyAccountCredits',
  'applyAccountCreditsSuccess',
  'applyAccountCreditsError'
);

export default QuickScheduleActions;
