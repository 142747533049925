class EventScheduleCancelActions {
  constructor() {
    this.generateActions(
      'toggleCancelModal',
      'handleChange',
      'scheduleCancel',
      'scheduleCancelSuccess',
      'scheduleCancelError'
    );
  }
}

export default alt.createActions(EventScheduleCancelActions);
