import { List } from 'immutable';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import Membership from 'shared/records/Membership.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import debounce from 'lodash.debounce';
import { MembershipTierSource } from 'sources';

class MembershipListingStore extends UpperHandStore {
  constructor() {
    super();
    this.page = 1;
    this.perPage = 16;
    this.totalCount = 0;
    this.records = List();
    this.search = '';
    this.isLoading = false;
    this.tiersLoading = false;
    this.membershipTiers = List();

    this.bindListeners({
      handleError: MembershipListingActions.ERROR,
      requestList: MembershipListingActions.LIST_REQUESTED,
      updateList: MembershipListingActions.LIST_RECEIVED,
      handleFetchMembershipByEventBenefits: EventActions.FETCH_SUCCESS,
      pageSelected: MembershipListingActions.pageSelected,
      membershipSearchHandler: MembershipListingActions.membershipSearchHandler,

      listMembershipTiers: MembershipListingActions.listMembershipTiers,
      listMembershipTiersSuccess:
        MembershipListingActions.listMembershipTiersSuccess,
      listMembershipTiersError:
        MembershipListingActions.listMembershipTiersError,
    });
    this.searchBarHandler = debounce(() => {
      this.requestList();
    }, 800);
  }

  membershipSearchHandler(value) {
    this.search = value;
    this.page = 1;
    this.searchBarHandler();
  }

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;
    this.requestList();
  }

  requestList() {
    this.isLoading = true;

    uhApiClient.get({
      url: 'memberships',
      data: {
        fields: ['events', 'event_types'],
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      },
      success: MembershipListingActions.listReceived,
      error: MembershipListingActions.error,
    });
  }

  handleFetchMembershipByEventBenefits({ schedule_type: scheduleType, id }) {
    if (scheduleType === 'class_schedule') {
      this.isLoading = true;

      uhApiClient.get({
        url: 'memberships',
        data: { fields: ['events', 'event_types'], event_ids: [id] },
        success: MembershipListingActions.listReceived,
        error: MembershipListingActions.error,
      });
    }
  }

  updateList(data) {
    this.isLoading = false;
    this.totalCount = data.total_count;
    this.records = List(data.memberships).map(m => new Membership(m));
  }

  listMembershipTiers(membershipId) {
    this.tiersLoading = true;

    MembershipTierSource.list({
      params: {
        membership_id: membershipId,
        per_page: 50,
        fields: ['events', 'event_types'],
      },
      success: MembershipListingActions.listMembershipTiersSuccess,
      error: MembershipListingActions.listMembershipTiersError,
    });
  }

  listMembershipTiersSuccess({ membership_tiers: membershipTiers }) {
    this.membershipTiers = membershipTiers;
    this.tiersLoading = false;
  }

  listMembershipTiersError(...args) {
    this.tiersLoading = false;
    this.notifyError('Failed to load membership tiers', args);
  }
}

export default alt.createStore(
  MembershipListingStore,
  'MembershipListingStore'
);
