import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';
import TermsBanner from 'shared/components/_TermsBanner.jsx';

function CustomerTermsBanner() {
  return (
    <TermsBanner
      name={window.customer.name}
      style={{
        height: 148,
        paddingTop: 46,
        paddingBottom: 46,
      }}
    >
      <FormattedMessage id={messageId('.please_read', __filenamespace)} />
    </TermsBanner>
  );
}

export default CustomerTermsBanner;
