import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { PaymentPlan } from 'records';
import Client from 'shared/records/Client.jsx';
import PaymentPlanDetailsDrawerActions from 'containers/reports/paymentPlanDetailsDrawer/Actions';

function RowMobile({ intl, paymentPlan, client }) {
  const start = moment(paymentPlan.startDate);

  return (
    <Card
      classes={{
        root: 'payment-plans__row-mobile',
        content: 'payment-plans__row-mobile-content',
      }}
      contentDirection="row"
      onClick={() => PaymentPlanDetailsDrawerActions.openDrawer(paymentPlan.id)}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        alignContent="center"
        justify="space-between"
      >
        <Grid item xs={7}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">
                {client ? client.name() : '-'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                className="payment-plans__row-item_muted"
              >
                {start.isSame(moment(), 'day')
                  ? t('.date_today', intl, __filenamespace, {
                      date: start.format('h:mma'),
                    })
                  : start.format('MM/DD/YYYY • h:mma')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                className="payment-plans__row-total"
              >
                <FormattedCurrency value={paymentPlan.amount} fromCents />
                <ChevronRightIcon />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

RowMobile.propTypes = {
  intl: PropTypes.object.isRequired,
  paymentPlan: PropTypes.instanceOf(PaymentPlan).isRequired,
  client: PropTypes.instanceOf(Client).isRequired,
};

export default RowMobile;
