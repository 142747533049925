import { List } from 'immutable';
import CreditGrant from 'shared/records/CreditGrant.js';
import Parser from 'sources/Parser';
import { onSuccess } from 'sources/handlers';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import CreditGrantActions from './CreditGrantActions';

const parserTo = { type: CreditGrant, paginationKey: 'credit_grants' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([CreditGrantActions.fetchSuccess, success])
    ),
    error,
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([CreditGrantActions.listSuccess, success])
    ),
    error,
  });
};

export const update = ({ id, attributes, success, error }) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify({
      attributes,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([CreditGrantActions.updateSuccess, success])
    ),
    error,
  });
};
