import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Typography, Button, Icon, Menu } from '@upperhand/playmaker';
import { useLocation } from 'react-router-dom';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import './styles.scss';

function ModeReport({ intl }) {
  const [anchor, setAnchor] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [reportUrl, setReportUrl] = useState(null);
  const [csvLink, setCsvLink] = useState(null);
  const [pdfLink, setPdfLink] = useState(null);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const label = urlParams.get('label');

  useEffect(() => {
    const customerId = currentCustomer().id;

    setLoading(true);
    uhApiClient
      .asyncGet(`customers/${customerId}/embedded_mode_report`, { label })
      .then(({ data = { url: null } }) => {
        const { url } = data;

        setReportUrl(url);
        setLoading(false);
      })
      .catch(_e => {
        setReportUrl(null);
        setLoading(false);
      });
  }, [label]);

  const setUrls = e => {
    if (e.origin === 'https://app.mode.com') {
      if (e.data.type === 'reportExportPaths') {
        const modeBaseUrl = e.origin;
        const csvExportUrl = e.data.report_csv_export_path;
        const pdfExportUrl = e.data.create_report_pdf_export_path.replace(
          '.pdf',
          '/download'
        );

        if (pdfExportUrl && modeBaseUrl) {
          setPdfLink(modeBaseUrl + pdfExportUrl);

          // We need to do initial fetch to generate pdf report so when user will click PDF export button he will be able to download the report.
          const xhr = new XMLHttpRequest();
          xhr.open('GET', modeBaseUrl + pdfExportUrl);
          xhr.send();
        }

        if (csvExportUrl && modeBaseUrl) {
          setCsvLink(modeBaseUrl + csvExportUrl);
        }
      }
    }
  };

  const handleExportPdf = () => {
    if (pdfLink) {
      window.open(pdfLink, '__blank');
    }
  };

  const handleExportCsv = () => {
    if (csvLink) {
      window.open(csvLink, '__blank');
    }
  };

  useEffect(() => {
    window.addEventListener('message', setUrls);
    return () => {
      window.removeEventListener('message', setUrls);
      setCsvLink(null);
      setPdfLink(null);
    };
  }, [label]);

  return (
    <SpinWhileLoading
      isLoading={isLoading}
      contentContainerStyle={{ height: '100%' }}
      outerContainerStyle={{ height: '100%' }}
    >
      <div className="mode-report">
        {(csvLink || pdfLink) && (
          <div className="mode-report__header">
            <Button
              rounded
              disabled={!csvLink}
              icon={<Icon name="cloudDownload" />}
              type="tertiary"
              onClick={e => setAnchor(e.currentTarget)}
            >
              {t('.export_btn', intl, __filenamespace)}
            </Button>
          </div>
        )}
        {reportUrl ? (
          <iframe
            className="mode-report__iframe-report"
            title={t('.iframe_title', intl, __filenamespace)}
            src={reportUrl}
          />
        ) : (
          <div className="mode-report__error">
            <Typography align="center">
              {t('.error', intl, __filenamespace)}
            </Typography>
          </div>
        )}
        <Menu
          anchor={anchor}
          onClose={() => setAnchor(null)}
          items={[
            {
              label: t('.export_csv', intl, __filenamespace),
              onClick: handleExportCsv,
            },
            {
              label: t('.export_pdf', intl, __filenamespace),
              onClick: handleExportPdf,
            },
          ]}
        />
      </div>
    </SpinWhileLoading>
  );
}

export default injectIntl(ModeReport);
