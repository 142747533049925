import { List, Map, Set } from 'immutable';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventResourceActions from 'event_mgmt/shared/actions/EventResourceActions.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import Resource from 'shared/records/Resource.js';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class EventResourceStore extends UpperHandStore {
  constructor() {
    super();

    this.eventId = null;
    this.resourceMap = Map();
    this.resources = List();

    this.bindListeners({
      list: EventResourceActions.LIST,
      listSuccess: EventResourceActions.LIST_SUCCESS,
      listError: EventResourceActions.LIST_ERROR,

      addResource: EventActions.RESOURCE_ADDED,

      setResourceStaff: [
        EventActions.FETCH_SUCCESS,
        EventActions.UPDATE_SUCCESS,
        EventActions.CREATE_SUCCESS,
        EventActions.STAFF_ADDED,
        EventActions.STAFF_REMOVED,
        EventActions.PREFERENCE_OVERRIDE_ADDED,
        EventActions.PREFERENCE_OVERRIDE_REMOVED,
        EventActions.PREFERENCE_OVERRIDE_ALL_REMOVED,
        StaffActions.LIST_SUCCESS,
      ],
    });
  }

  list({ eventId, page = 1, perPage = 100 }) {
    this.isLoading = true;
    this.eventId = eventId;

    uhApiClient.get({
      url: 'resources',
      data: { event_id: eventId, page, per_page: perPage },
      success: EventResourceActions.listSuccess,
      error: EventResourceActions.listError,
    });
  }

  listSuccess(data) {
    this.resourceMap = this.resourceMap.withMutations(rm =>
      data.resources.forEach(r => rm.set(r.id, new Resource(r)))
    );

    if (data.totalCount > this.resourceMap.size) {
      this.list({
        eventId: this.eventId,
        page: data.page + 1,
        perPage: data.per_page,
      });
    } else {
      this.resources = this.resourceMap.toList();
      this.isLoading = false;

      this.setResourceStaff();
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing event resources', args);
  }

  addResource(resource) {
    this.resourceMap = this.resourceMap.set(resource.id, resource);
    this.setResourceStaff();
  }

  setResourceStaff() {
    if (this.isLoading) {
      return;
    }

    this.waitFor([EventStore, StaffStore]);

    const event = EventStore.getState().customerEvent;
    const staff = StaffStore.getState().allStaff;

    const selectedStaffIds = event.getIn(
      ['schedules', 0, 'customer_user_ids'],
      List()
    );
    const selectedResourceIds = event
      .getIn(['schedules', 0, 'schedule_resources'], List())
      .map(sr => sr.resource_id);

    const preferringStaffOverrides = event.getIn(
      ['schedules', 0, 'resource_preferring_staff'],
      Map()
    );

    this.resourceMap = this.resourceMap.withMutations(rm =>
      selectedResourceIds.forEach(id =>
        rm.update(id, existingResource => {
          const overrides = preferringStaffOverrides.get(id.toString());

          let preferringStaffIds = Set();
          if (overrides) {
            preferringStaffIds = Set(overrides);
          } else {
            preferringStaffIds = existingResource.preferring_staff_ids;
          }

          return existingResource.set(
            'preferring_staff',
            staff.filter(
              s =>
                preferringStaffIds.has(s.id) && selectedStaffIds.includes(s.id)
            )
          );
        })
      )
    );
  }
}

export default alt.createStore(EventResourceStore, 'EventResourceStore');
