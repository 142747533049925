import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import POSSchedulingStore from 'point_of_sale/stores/POSSchedulingStore.jsx';
import POSSingleSessionContentActions from 'point_of_sale/actions/POSSingleSessionContentActions.js';
import SingleSessionContentStoreInterface from 'shared/stores/SingleSessionContentStoreInterface.jsx';

class POSSingleSessionContentStore extends SingleSessionContentStoreInterface {
  constructor() {
    super();

    this.bindListeners({
      setStoreStates: [
        POSCartActions.FETCH_SUCCESS,
        POSEventActions.FETCH_AVAILABILITY_SUCCESS,
        POSSchedulingActions.ALL_AVAILABLE_SINGLE_SESSIONS_ADDED,
        POSSchedulingActions.ALL_AVAILABLE_SINGLE_SESSIONS_REMOVED,
        POSSchedulingActions.LIST_PURCHASED_REGISTRATIONS_SUCCESS,
        POSSchedulingActions.SINGLE_SESSION_ADDED,
        POSSchedulingActions.SINGLE_SESSION_ATTENDEE_ADDED,
        POSSchedulingActions.SINGLE_SESSION_ATTENDEE_REMOVED,
        POSSchedulingActions.SINGLE_SESSION_ATTENDEE_REMOVED,
        POSSchedulingActions.SINGLE_SESSION_PROFILE_CREATED,
        POSSchedulingActions.SINGLE_SESSION_REMOVED,
      ],
      onProfileSelect: [
        POSSchedulingActions.SINGLE_SESSION_ATTENDEE_ADDED,
        POSSchedulingActions.SINGLE_SESSION_PROFILE_CREATED,
      ],
      toggleExpand: POSSingleSessionContentActions.EXPAND_TOGGLED,
    });
  }

  setStoreStates() {
    super.setStoreStates(POSSchedulingStore);
  }
}

export default alt.createStore(
  POSSingleSessionContentStore,
  'POSSingleSessionContentStore'
);
