import { Record, Set, Map } from 'immutable';
import moment from 'moment-timezone';
import { merge } from 'shared/utils/ObjectUtils.jsx';

export const mapCreditGrantsToType = creditGrants => {
  let creditCounts = Map();

  creditGrants.forEach(g => {
    if (g.all_events) {
      creditCounts = creditCounts.update(
        'all_events',
        (n = 0) => n + (g.unlimited ? Infinity : g.available)
      );
    }

    g.event_type_ids.forEach(id => {
      creditCounts = creditCounts.updateIn(
        ['event_types', id],
        (n = 0) => n + (g.unlimited ? Infinity : g.available)
      );
    });

    g.event_ids.forEach(id => {
      creditCounts = creditCounts.updateIn(
        ['events', id],
        (n = 0) => n + (g.unlimited ? Infinity : g.available)
      );
    });
  });

  return creditCounts;
};

class CreditGrant extends Record({
  id: null,
  all_events: false,
  available: 0,
  client_id: null,
  definition_id: null,
  definition_type: null,
  event_ids: Set(),
  event_type_ids: Set(),
  expires_at: null,
  source_id: null,
  source_type: null,
  source_name: null,
  quantity: 0,
  unlimited: false,
  created_at: null,
  updated_at: null,
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        event_ids: Set(obj.event_ids),
        event_type_ids: Set(obj.event_type_ids),
        expires_at: obj.expires_at ? moment(obj.expires_at) : null,
      })
    );
  }

  applicableTo(event) {
    return (
      this.all_events ||
      this.event_ids.includes(event.id) ||
      this.event_type_ids.includes(event.event_type.id)
    );
  }

  isExpired() {
    return !!this.expires_at?.isSameOrBefore(moment());
  }

  isCreditPassBased() {
    return this.source_type === 'CreditPassPackage';
  }

  isMembershipBased() {
    return this.source_type === 'MembershipSubscription';
  }

  get total() {
    return this.unlimited ? Infinity : this.quantity;
  }

  get usable() {
    return this.unlimited ? Infinity : this.available;
  }
}

export default CreditGrant;
