import * as React from 'react';
import { injectIntl } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import User from 'event_mgmt/shared/records/User.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import uhTheme from '_uh_theme.jsx';

import SideNavActions from 'shared/actions/SideNavActions.jsx';
import history from 'routes/History';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  ListItem: {
    fontSize: 'inherit',
    color: 'inherit',
    '& a': {
      textDecoration: 'none',
      color: uhTheme.palette.lightGrey,
    },
  },
  UserAvatar: {
    marginRight: 0,
    marginLeft: -4,
    marginTop: 4,
  },
};

const ToggleDrawersAction = () => {
  SideNavActions.toggle([false]);
  history.push(customerScopedRoute('/my_profile'));
};

function UserMenuItem({ intl }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const rootPrimaryText = <span>{currentUser().name}</span>;
  const subitems = [];

  // Looks like we're not showing sidenav for clients at all. Safe to remove?
  if (currentUser().isClient()) {
    subitems.push(
      <ListItem disablePadding key="ListItem-profile" sx={styles.ListItem}>
        <ListItemButton onClick={ToggleDrawersAction}>
          <ListItemText>{t('.profile', intl, __filenamespace)}</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }

  subitems.push(
    <ListItem disablePadding key="ListItem-settings" sx={styles.ListItem}>
      <ListItemButton href={customerScopedRoute('/users/settings')}>
        <ListItemText>{t('.settings', intl, __filenamespace)}</ListItemText>
      </ListItemButton>
    </ListItem>
  );

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <UserAvatar
              user={new User(currentUser())}
              size={32}
              backgroundColor={uhTheme.palette.lightGrey}
              style={styles.UserAvatar}
            />
          </ListItemIcon>
          <ListItemText>{rootPrimaryText}</ListItemText>
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {subitems}
      </Collapse>
    </>
  );
}

export default injectIntl(UserMenuItem);
