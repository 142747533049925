import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { blue, grey } from '@mui/material/colors';

import Form from 'shared/components/Form.jsx';
import IdentityQuestionsForm from 'onboarding/components/AccountSetupPaysafe/forms/_IdentityQuestionsForm.jsx';
import IdentityStepActions from 'onboarding/actions/IdentityStepActions.js';
import OwnerForm from 'onboarding/components/AccountSetupPaysafe/forms/_OwnerForm.jsx';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';
import WarningIcon from 'shared/components/icons/WarningIcon.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import {
  Step,
  NextAction,
  ErrorMessage,
} from 'onboarding/components/AccountSetupPaysafe/steps/_Helpers.jsx';
import { DOUBLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const REQUIRED_PERCENTAGE = 76;
class IdentityStep extends React.PureComponent {
  render() {
    const { identityVerification, intl, isLoading, owners } = this.props;
    const questions = identityVerification.get('questions');
    const identityQuestionsEnabled = questions && !questions.isEmpty();
    const hasError = !owners.every(o => o.isValid());
    const lowPercentage =
      owners.reduce((total, owner) => {
        const percentage = parseInt(owner.percentage_ownership, 10);
        return total + (Number.isNaN(percentage) ? 0 : percentage);
      }, 0) < REQUIRED_PERCENTAGE;

    const initialOwner = owners.get(0);
    return (
      <Step>
        <Form style={Styles.form}>
          <h2 style={Styles.header2}>{t('.header', intl, __filenamespace)}</h2>
          <Paper style={{ marginTop: '20px', padding: '20px' }}>
            <Typography align="center" variant="body1">
              <WarningIcon />
              {t('.warning', intl, __filenamespace)}
            </Typography>
            <div style={Styles.instructions}>
              <p>{t('.instructions', intl, __filenamespace)}</p>
            </div>
            <div style={{ display: 'flex', flex: '0 0 100%' }}>
              {initialOwner && (
                <OwnerForm
                  action={IdentityStepActions.ownerUpdated}
                  index={0}
                  intl={intl}
                  owner={initialOwner}
                />
              )}
            </div>
          </Paper>
          {owners.size > 1 && (
            <div>
              <h2 style={merge(Styles.header2, { marginTop: 40 })}>
                {t('.additional_header', intl, __filenamespace)}
              </h2>
              {owners.map((o, index) => {
                const { ownerUpdated, removeOwner } = IdentityStepActions;
                if (index > 0) {
                  return (
                    <div style={{ display: 'flex', flex: '0 0 100%' }}>
                      <Paper
                        style={{
                          marginTop: 20,
                          padding: 20,
                          position: 'relative',
                        }}
                      >
                        <IconButton
                          onClick={() => removeOwner(index)}
                          style={{
                            marginTop: -12,
                            marginRight: -12,
                            position: 'absolute',
                            right: 12,
                          }}
                        >
                          <ClearIcon sx={{ color: grey[300] }} />
                        </IconButton>
                        <OwnerForm
                          action={ownerUpdated}
                          intl={intl}
                          owner={o}
                          index={index}
                        />
                      </Paper>
                    </div>
                  );
                }
                return <div />;
              })}
            </div>
          )}
          {identityQuestionsEnabled && (
            <IdentityQuestionsForm
              questions={questions}
              onChange={IdentityStepActions.answerIdentityQuestion}
            />
          )}
        </Form>
        <ErrorMessage show={hasError} />
        {!isLoading && (
          <div style={{ marginBottom: 20 }}>
            <FormattedMessage
              id={messageId('.ubos_with_25_percent', __filenamespace)}
            />
          </div>
        )}
        <div style={{ width: DOUBLE_COLUMN_WIDTH }}>
          <Typography component="strong" variant="subtitle1">
            {t('shared.important_note', intl, __filenamespace)}
          </Typography>
          <Typography component="span" variant="body1">
            {t('.note', intl, __filenamespace)}
          </Typography>
        </div>
        <FlexBoxJustifyCenter>
          {!isLoading && (
            <Button
              startIcon={<AddIcon sx={{ color: blue[500] }} />}
              disabled={owners.size > 3}
              onClick={IdentityStepActions.addOwner}
            >
              {t('.add_owner', intl, __filenamespace)}
            </Button>
          )}
          <NextAction
            action={IdentityStepActions.submitForm}
            isLoading={isLoading}
            disabled={
              lowPercentage ||
              !owners.getIn([0, 'is_applicant'], false) ||
              !owners.getIn([0, 'is_control_prong'], false) ||
              hasError ||
              isLoading
            }
          />
        </FlexBoxJustifyCenter>
      </Step>
    );
  }
}

export default injectIntl(IdentityStep);
