import ManagementUrlActions from 'shared/actions/ManagementUrlActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

class ManagementUrlStore extends UpperHandStore {
  constructor() {
    super();
    this.url = null;
    this.isLoading = false;

    this.bindListeners({
      handleFetch: ManagementUrlActions.FETCH,
      handleFetchSuccess: ManagementUrlActions.FETCH_SUCCESS,
      handleFetchError: ManagementUrlActions.FETCH_ERROR,
    });
  }

  handleFetch() {
    this.isLoading = true;

    return uhApiClient.get({
      url: `customers/${currentCustomer().id}/management_url`,
      success: ManagementUrlActions.fetchSuccess,
      error: ManagementUrlActions.fetchError,
    });
  }

  handleFetchSuccess(data) {
    this.isLoading = false;
    // sometimes data will come back null. aka for enterprise customers so use safe operator
    this.url = data?.management_url;
  }

  handleFetchError(...args) {
    this.isLoading = false;
    this.notifyError('error while fetching managment url', args);
  }
}

export default alt.createStore(ManagementUrlStore, 'ManagementUrlStore');
