import CreditPassPurchasingActions from 'credit_passes/actions/CreditPassPurchasingActions.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';

class SecondaryDrawerStore {
  constructor() {
    this.activeDrawer = null;
    this.bindListeners({
      setActive: SecondaryDrawerActions.SET_ACTIVE,
      setPassPurchaseDrawerActive: CreditPassPurchasingActions.PURCHASE_CLICKED,
      close: SecondaryDrawerActions.CLOSE,
    });
  }

  setActive(drawer) {
    this.activeDrawer = drawer;
  }

  setPassPurchaseDrawerActive() {
    this.activeDrawer = 'CreditPassPurchaseDrawer';
  }

  close() {
    this.activeDrawer = null;
  }
}

export default alt.createStore(SecondaryDrawerStore, 'SecondaryDrawerStore');
