import * as React from 'react';
import Paper from '@mui/material/Paper';
import {
  FirstName,
  LastName,
  Email,
  EnableLogin,
  DateOfBirth,
  Gender,
} from 'user_management/shared/components/UserProfileFields.jsx';
import ImageUpload, { AvatarPreview } from 'components/ImageUpload';

const styles = {
  root: {
    padding: '16px',
    margin: '25px 3px 3px 3px',
    '& > div': {
      marginBottom: '1rem',
    },
  },
  flatRoot: {
    padding: 0,
    margin: 0,
  },
};

function ManagedProfileEditor({
  errors,
  intl,
  actionButtons,
  flat,
  onChange,
  userProfile,
}) {
  const errorsFor = field => errors.getErrors(field, intl);

  return (
    <Paper
      elevation={flat ? 0 : 1}
      sx={{ ...styles.root, ...(flat && styles.flatRoot) }}
    >
      <ImageUpload
        circularCrop
        cropAspect={1}
        uploadContainerWidth="100%"
        onChange={value => {
          onChange(null, value, 'profile_image');
        }}
        image={userProfile.profile_image}
        preview={<AvatarPreview user={userProfile} />}
      />
      <FirstName
        userProfile={userProfile}
        onChange={onChange}
        errorText={errorsFor('first_name')}
      />
      <LastName
        userProfile={userProfile}
        onChange={onChange}
        errorText={errorsFor('last_name')}
      />
      <DateOfBirth
        userProfile={userProfile}
        onChange={onChange}
        errorText={errorsFor('date_of_birth')}
      />
      <Gender
        userProfile={userProfile}
        onChange={onChange}
        errorText={errorsFor('gender')}
      />
      <EnableLogin userProfile={userProfile} onChange={onChange} />
      {userProfile.login_enabled && (
        <Email
          userProfile={userProfile}
          onChange={onChange}
          errorText={errorsFor('email')}
        />
      )}
      {actionButtons}
    </Paper>
  );
}

export default ManagedProfileEditor;
