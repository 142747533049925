/* eslint-disable react/no-danger */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Box } from '@mui/material';

import ImageUpload from 'components/ImageUpload';
import CopyHref from 'shared/components/CopyHref.jsx';
import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';

import uhTheme from '_uh_theme.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { shortHumanUrl } from 'shared/utils/FormattingUtils.jsx';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';

const styles = {
  labelStyle: {
    color: uhColors.darkGrey,
    paddingBottom: '1em',
    display: 'block',
    ...boldText,
  },
};

const stylesheet = `
.description-editor { min-height: 300px; font-family: ${uhTheme.fontFamily}; }
.public-DraftEditor-content { min-height: 250px; }
a { color: ${uhColors.lightBlue}; }
`;

function TabMarketing({ intl, customerTeam, handleInputChange }) {
  const getTeamMarketingUrl = () =>
    `${window.app_host || window.location.origin}${customerTeam.path}`;

  const handleDescriptionChange = value => {
    handleInputChange({
      target: {
        type: 'text',
        name: 'description',
        value,
      },
    });
  };

  const handleImageChange = image => {
    handleInputChange({
      target: {
        type: 'image',
        name: 'image',
        value: image,
      },
    });
  };

  const teamLink = () => {
    if (customerTeam.path) {
      return (
        <CopyHref
          href={getTeamMarketingUrl()}
          label={t('.team_url', intl, __filenamespace)}
          labelStyle={styles.labelStyle}
          text={shortHumanUrl(getTeamMarketingUrl(), 70)}
        />
      );
    }
    return (
      <Box style={{ paddingTop: 10 }}>
        <FormattedMessage id={messageId('.team_title', __filenamespace)} />
      </Box>
    );
  };

  return (
    <Box>
      <style type="text/css" dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <Grid
        sx={{ justifyContent: 'space-between', maxWidth: '1200px' }}
        container
      >
        <Box sx={{ paddingBottom: '1em' }}>
          <Box
            sx={{
              maxWidth: 540,
              minWidth: 460,
              width: '90%',
              paddingBottom: '1.5em',
            }}
          >
            {teamLink()}
          </Box>
          <Box>
            <Box sx={styles.labelStyle}>
              <FormattedMessage
                id={messageId('.team_description', __filenamespace)}
              />
            </Box>
            <Box sx={{ maxWidth: 540, minWidth: 460, width: '90%' }}>
              <WysiwygEditor
                useBase64={false}
                value={customerTeam.description}
                onChange={handleDescriptionChange}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: '1em' }}>
          <Box sx={styles.labelStyle}>
            <FormattedMessage id={messageId('.team_image', __filenamespace)} />
          </Box>
          <ImageUpload
            cropAspect={1.6}
            uploadContainerHeight={300}
            uploadContainerWidth={500}
            onChange={handleImageChange}
            onRemove={handleImageChange}
            image={customerTeam.image}
          />
        </Box>
      </Grid>
    </Box>
  );
}

export default injectIntl(TabMarketing);
