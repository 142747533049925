import * as React from 'react';
import { Box } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

function TableHeader({ isTeamEvent }) {
  const isClient = currentUser().isClient();
  if (isClient && isTeamEvent) {
    return (
      <Box
        sx={{
          border: '0.69px solid #E2E2E2',
          padding: '8px 0px 8px 8px',
        }}
      >
        <Grid container direction="row" justify="flex-start">
          {isTeamEvent && (
            <Grid item alignItems="center" xs={2} spacing={1}>
              <Typography variant="subtitle2">
                <FormattedMessage
                  id={messageId('.schedule', __filenamespace)}
                />
              </Typography>
            </Grid>
          )}
          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.date', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.days', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.time', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.location', __filenamespace)} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (isTeamEvent) {
    return (
      <Box
        sx={{
          border: '0.69px solid #E2E2E2',
          padding: '8px 42px 8px 8px',
        }}
      >
        <Grid container direction="row" justify="flex-start">
          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.schedule', __filenamespace)} />
            </Typography>
          </Grid>

          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.date', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.days', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.time', __filenamespace)} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.location', __filenamespace)} />
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.staff', __filenamespace)} />
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.resource', __filenamespace)} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        border: '0.69px solid #E2E2E2',
        padding: '8px 8px 8px 8px',
      }}
    >
      <Grid container direction="row" justify="flex-start">
        {isTeamEvent && (
          <Grid item alignItems="center" xs={2} spacing={1}>
            <Typography variant="subtitle2">
              <FormattedMessage id={messageId('.schedule', __filenamespace)} />
            </Typography>
          </Grid>
        )}
        <Grid item alignItems="center" xs={2} spacing={1}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.date', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.days', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.time', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.location', __filenamespace)} />
          </Typography>
        </Grid>

        <Grid item xs={isTeamEvent ? 2 : 4}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.staff', __filenamespace)} />
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.resource', __filenamespace)} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TableHeader;
