import { Record, List } from 'immutable';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

class AvailableTime extends Record({
  date: undefined,
  start_time: undefined,
  spots_remaining: 0,
  staff: List(),
  resources: List(),
}) {
  constructor(obj = {}) {
    super({ ...obj, resources: List(obj.resources), staff: List(obj.staff) });
  }

  toDate() {
    return moment(`${this.date} ${this.start_time}`, 'YYYY-MM-DD HH:mm:ss');
  }

  toSessionRange(event, timezone) {
    const startDate = this.toDate().tz(timezone, true);

    return moment.range(
      startDate,
      startDate.clone().add(event.getSchedule().duration, 'seconds')
    );
  }
}

export default AvailableTime;
