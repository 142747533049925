import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

class TextAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    const { selectedEventTitle } = props;

    this.state = {
      searchText: selectedEventTitle || '',
    };
  }

  handleUpdateInput = value => {
    const { onChange } = this.props;
    onChange(value);
    this.setState({ searchText: value });
  };

  handleSelect = selection => {
    const { onSelect, clearOnSelect } = this.props;

    onSelect(selection.value);
    this.setState({
      searchText: clearOnSelect ? '' : selection.text,
    });
  };

  onBlur = () => {
    const { onLeave, clearOnSelect, selectedEventTitle } = this.props;
    const { searchText } = this.state;
    if (clearOnSelect) {
      this.setState({ searchText: '' });
    } else if (selectedEventTitle && selectedEventTitle !== searchText) {
      this.setState({ searchText: selectedEventTitle });
    }

    if (onLeave) {
      // onSelect is triggered after onLeave and this may lead
      // to potential race condition with dataSource resets
      // We need to wait some time before reset may be dispatched
      setTimeout(onLeave, 200);
    }
  };

  render() {
    const { searchText } = this.state;
    const { dataSource, hintText } = this.props;
    return (
      <Autocomplete
        clearOnBlur
        inputValue={searchText || ''}
        sx={{ margin: '5px 0' }}
        options={dataSource}
        noOptionsText="There are no items to select."
        getOptionLabel={option => option.text}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={hintText}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            sx={{ justifyContent: 'space-between' }}
            key={option.value}
            onClick={() => this.handleSelect(option)}
          >
            {option.text}
          </MenuItem>
        )}
        onInputChange={(_e, value) => this.handleUpdateInput(value)}
        onClose={this.onBlur}
      />
    );
  }
}

export default TextAutoComplete;
