import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  TextField,
  Grid,
  CurrencyTextField,
  Dropdown,
  Checkbox,
  Typography,
} from '@upperhand/playmaker';
import Paper from '@mui/material/Paper';

import { t } from 'shared/utils/LocaleUtils';

import MembershipTier from 'shared/records/MembershipTier';

function MembershipTierConfig({
  tier,
  joinFeeChecked,
  onFieldChange,
  onJoinFeeToggle,
}) {
  const intl = useIntl();
  const intervalMonthsOptions = [
    {
      value: '1',
      label: 'Monthly',
    },
    {
      value: '12',
      label: 'Yearly',
    },
    {
      value: '0',
      label: 'Once',
    },
  ];

  const commitmentMonthsOptions = [
    {
      value: 1,
      label: '1 Month',
    },
    {
      value: 3,
      label: '3 Months',
    },
    {
      value: 6,
      label: '6 Months',
    },
    {
      value: 12,
      label: '1 Year',
    },
  ];

  return (
    <Paper className="membership-tiers__config-container">
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item container spacing={1} md={6} xs={12}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('.tier_name', intl, __filenamespace)}
              value={tier.name}
              errorText={tier.errors.getErrors('name', intl)}
              onChange={e =>
                onFieldChange({ field: 'name', value: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyTextField
              fullWidth
              label={t('.tier_pricing', intl, __filenamespace)}
              value={tier.price / 100}
              errorText={tier.errors.getErrors('price', intl)}
              onChange={(e, value) =>
                onFieldChange({
                  field: 'price',
                  value: Math.round(value * 100),
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              fullWidth
              label="&nbsp;"
              classes={{
                root: 'membership__price-interval',
              }}
              name="interval_months"
              value={tier.interval_months.toString()}
              onChange={e =>
                onFieldChange({
                  field: 'interval_months',
                  value: parseInt(e.target.value, 10),
                })
              }
              errorText={tier.errors.getErrors('interval_months', intl)}
              disabled={tier.active_client_count > 0}
              items={intervalMonthsOptions}
            />
          </Grid>
        </Grid>
        <Grid item container md={6} xs={12}>
          <Grid item xs={12}>
            <Checkbox
              label={t('.tier_join_fee', intl, __filenamespace)}
              checked={joinFeeChecked}
              onChange={(_, checked) => {
                onJoinFeeToggle();
                onFieldChange({
                  field: 'join_fee',
                  value: checked ? 100 : 0,
                });
              }}
            />
          </Grid>
          {joinFeeChecked && (
            <>
              <Grid item xs={12}>
                <Typography>
                  {t('.tier_join_fee_info', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item container xs={6} alignItems="center">
                <Typography variant="body1">
                  {t('.tier_join_fee_label', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CurrencyTextField
                  fullWidth
                  value={tier.join_fee / 100}
                  onChange={(e, value) =>
                    onFieldChange({
                      field: 'join_fee',
                      value: Math.round(value * 100),
                    })
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Checkbox
              label={t('.tier_require_commitment', intl, __filenamespace)}
              disabled={tier.interval_months === 0}
              checked={Boolean(tier.commitment_months)}
              onChange={(_, checked) => {
                onFieldChange({
                  field: 'commitment_months',
                  value: checked ? 1 : 0,
                });
              }}
            />
          </Grid>
          {Boolean(tier.commitment_months) && (
            <>
              <Grid item xs={12}>
                <Typography>
                  {t('.tier_commitment_length_info', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item container xs={6} alignItems="center">
                <Typography variant="body1">
                  {t('.tier_commitment_length', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Dropdown
                  fullWidth
                  value={tier.commitment_months}
                  onChange={e =>
                    onFieldChange({
                      field: 'commitment_months',
                      value: e.target.value,
                    })
                  }
                  items={commitmentMonthsOptions}
                />
              </Grid>
              {Boolean(tier.commitment_months) && (
                <Grid item xs={12}>
                  <Checkbox
                    label={t(
                      '.tier_cancel_at_end_commitment',
                      intl,
                      __filenamespace
                    )}
                    checked={Boolean(tier.commitment_checked)}
                    onChange={(_, checked) => {
                      onFieldChange({
                        field: 'commitment_checked',
                        value: checked,
                      });
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

MembershipTierConfig.propTypes = {
  tier: PropTypes.instanceOf(MembershipTier),
  joinFeeChecked: PropTypes.bool,
  onJoinFeeToggle: PropTypes.func,
  onFieldChange: PropTypes.func,
};

MembershipTierConfig.defaultProps = {
  tier: new MembershipTier(),
  joinFeeChecked: false,
  onJoinFeeToggle: () => {},
  onFieldChange: () => {},
};

export default memo(MembershipTierConfig);
