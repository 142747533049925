export default alt.generateActions(
  'answer',
  'listAnswers',
  'listAnswersSuccess',
  'listAnswersError',
  'setCompleted',
  'setCompletedError',
  'setCompletedSuccess',
  'stage',
  'start',
  'stop',
  'nextRegistrant',
  'previousRegistrant'
);
