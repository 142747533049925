import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import { ScheduleSource } from 'sources';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import EventScheduleCancelActions from './Actions';

export const creditOperations = {
  REFUND_CREDIT: 'refund_credit',
  REVOKE_CREDIT: 'revoke_credit',
};

class EventScheduleCancelStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      handleChange: EventScheduleCancelActions.handleChange,
      toggleCancelModal: EventScheduleCancelActions.toggleCancelModal,
      scheduleCancel: EventScheduleCancelActions.scheduleCancel,
      scheduleCancelSuccess: EventScheduleCancelActions.scheduleCancelSuccess,
      scheduleCancelError: EventScheduleCancelActions.scheduleCancelError,
    });
  }

  reset() {
    this.schedule = null;
    this.creditOperation = creditOperations.REFUND_CREDIT;
    this.loading = false;
  }

  toggleCancelModal({ scheduleItem = null }) {
    this.reset();
    this.schedule = scheduleItem;
  }

  handleChange(creditOperation) {
    this.creditOperation = creditOperation;
  }

  scheduleCancel() {
    const params = {
      revoke_credit: false,
    };

    if (this.creditOperation === creditOperations.REVOKE_CREDIT) {
      params.revoke_credit = true;
    }

    this.loading = true;
    ScheduleSource.cancel({
      id: this.schedule.id,
      params,
      success: EventScheduleCancelActions.scheduleCancelSuccess,
      error: EventScheduleCancelActions.scheduleCancelError,
    });
  }

  scheduleCancelSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.eventScheduleCancellation.CancelScheduleModal.success_message',
    });

    this.loading = false;
    this.toggleCancelModal({});
    MessageWindowActions.addMessage.defer(message);
  }

  scheduleCancelError() {
    this.loading = false;
  }
}

export default alt.createStore(
  EventScheduleCancelStore,
  'EventScheduleCancelStore'
);
