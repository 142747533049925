import isEqual from 'lodash.isequal';

import { FilterPeriod, Filters } from 'containers/reports/types';

// Check if FilterDrawer has default values
// eslint-disable-next-line import/prefer-default-export
export const hasFilterDrawerDefaults = (filters, reportMode = true) => {
  if (reportMode) {
    return (
      (isEqual(filters.get(Filters.PAYMENT_TYPES), []) ||
        filters.get(Filters.PAYMENT_TYPES) === undefined) &&
      (isEqual(filters.get(Filters.LOCATION), []) ||
        filters.get(Filters.LOCATION) === undefined) &&
      !filters.get(Filters.PRODUCT_TYPE) &&
      !filters.get(Filters.PRODUCT) &&
      filters.get(Filters.BALANCE_REMAINING_MIN) === 0 &&
      filters.get(Filters.BALANCE_REMAINING_MAX) === undefined
    );
  }

  return (
    filters.get(Filters.PERIOD) &&
    filters.get(Filters.PERIOD).value === FilterPeriod.all_time &&
    (isEqual(filters.get(Filters.PAYMENT_TYPES), []) ||
      filters.get(Filters.PAYMENT_TYPES) === undefined) &&
    (isEqual(filters.get(Filters.LOCATION), []) ||
      filters.get(Filters.LOCATION) === undefined) &&
    filters.get(Filters.BALANCE_REMAINING_MIN) === undefined &&
    filters.get(Filters.BALANCE_REMAINING_MAX) === undefined
  );
};
