import React from 'react';
import Button from '@mui/material/Button';

import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';
import TeamHeader from 'team_mgmt/shared/components/_TeamHeader.jsx';
import { Link as ReactRouterLink } from 'react-router-dom';
import { t } from 'shared/utils/LocaleUtils';

const buttonStyle = {
  margin: '0px 8px 0px 8px',
};

function Header(props) {
  const { savedState, customerTeam, isLoadingTeam, isSavingTeam, intl } = props;
  const actions = [
    customerTeam.id && (
      <ReactRouterLink
        to={customerTeam.path}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <Button
          variant="contained"
          disabled={isLoadingTeam || isSavingTeam}
          color="default"
          sx={buttonStyle}
        >
          {t('.preview', intl, __filenamespace)}
        </Button>
      </ReactRouterLink>
    ),
    <Button
      disabled={isLoadingTeam || isSavingTeam}
      onClick={() => TeamActions.createOrUpdateServer()}
      color="default"
      variant="contained"
      sx={buttonStyle}
    >
      {savedState}
    </Button>,
    <Button
      disabled={isLoadingTeam || isSavingTeam}
      onClick={() => TeamActions.postTeam()}
      color="secondary"
      variant="contained"
      sx={buttonStyle}
    >
      {t('.post', intl, __filenamespace)}
    </Button>,
  ];

  return <TeamHeader actions={actions} team={customerTeam} />;
}

export default Header;
