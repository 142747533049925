import { Record, List } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';
import { toSlug } from 'shared/utils/StringUtils.jsx';

class Balance extends Record({
  compoundId: null,
  balanceRemaining: null,
  clientId: null,
  clientName: null,
  effectiveAt: null,
  locationId: null,
  paid: null,
  paymentType: null,
  product: null,
  productId: null,
  productType: null,
  details: List(),
  quantity: null,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({
      ...parsedObject,
      compoundId: `${parsedObject.productId}.${toSlug(
        parsedObject.productType
      )}.${parsedObject.clientId}.${parsedObject.effectiveAt}`,
    });
  }

  get outstanding() {
    return Math.abs(this.balanceRemaining);
  }

  get productTypeNeedsIntl() {
    return (
      this.productType === 'credit_pass' ||
      this.productType === 'membership' ||
      this.productType === 'membership_renewal'
    );
  }

  hasDetailsOfType(type) {
    return (
      this.details.filter(detail => detail.get('type') === type).count() > 0
    );
  }
}

export default Balance;
