import React, { memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Typography,
  Confirmation,
  Button,
  Dropdown,
  TextField,
} from '@upperhand/playmaker';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import TerminationFields from 'containers/clientProfile/components/MembershipInfo/ManagementRequestModal/fields/TerminationFields.jsx';
import SuspensionFields from 'containers/clientProfile/components/MembershipInfo/ManagementRequestModal/fields/SuspensionFields.jsx';
import UpdateFields from 'containers/clientProfile/components/MembershipInfo/ManagementRequestModal/fields/UpdateFields.jsx';
import SuccessMessage from 'containers/clientProfile/components/MembershipInfo/ManagementRequestModal/components/SuccessMessage.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';

import MembershipManagementActions from './Actions';
import MembershipManagementStore, { MANAGEMENT_OPTIONS } from './Store';

import './styles.scss';

function ManagementRequestModal({
  clientDataStore,
  membershipManagementStore,
}) {
  const intl = useIntl();
  const {
    errors,
    openModal,
    requestSent,
    sendingRequest,
    subscription,
    managementForm,
    membershipIds,
    membershipTierIds,
    membershipsLoading,
    membershipTiersLoading,
  } = membershipManagementStore;

  if (!openModal || !subscription) return null;

  const {
    client_id: clientId,
    membership_tier: membershipTier,
    membership,
  } = subscription;
  const isTiered = membership.get('tiered', false);
  const membershipTitle = isTiered
    ? membershipTier.get('name')
    : membership.get('name');
  const { clients } = clientDataStore;
  const client = clients.get(clientId);
  const selectedOption = managementForm.get('option');
  const managementOptions = Object.values(MANAGEMENT_OPTIONS).map(option => ({
    label: t(`.request_type_${option}`, intl, __filenamespace),
    value: option,
  }));

  return (
    <Confirmation
      open={openModal}
      hasCheckbox={false}
      confirmationDisabled={sendingRequest}
      onCancel={MembershipManagementActions.closeModal}
      onConfirm={
        !requestSent
          ? MembershipManagementActions.sendManagementRequest
          : MembershipManagementActions.closeModal
      }
      level={requestSent ? 'trivial' : 'warning'}
      classes={{
        root: 'management-request-modal',
        content: 'management-request-modal__content',
        actions: clsx(
          'management-request-modal__actions',
          requestSent && 'management-request-modal__actions-success'
        ),
      }}
      confirmButtonLabel={
        !requestSent
          ? t('.submit_label', intl, __filenamespace)
          : t('.cancel_label', intl, __filenamespace)
      }
      title={
        <div className="management-request-modal__title">
          <Typography variant="h5">
            {t('.title', intl, __filenamespace)}
          </Typography>
          <Button
            rounded
            icon="close"
            type="tertiary"
            onClick={MembershipManagementActions.closeModal}
          />
        </div>
      }
    >
      {requestSent && (
        <SuccessMessage
          client={client}
          subscription={subscription}
          option={t(
            `.request_type_${selectedOption || 'suspend'}`,
            intl,
            __filenamespace
          )}
        />
      )}
      {!requestSent && (
        <>
          <Typography variant="body1">
            {t('.description', intl, __filenamespace)}
          </Typography>
          <div className="divider" />
          <div className="management-request-modal__member-details">
            <Typography variant="fieldLabel">
              {t('.member_details', intl, __filenamespace)}
            </Typography>
            <ClientDetails client={client.set('email', membershipTitle)} />
          </div>
          <div className="management-request-modal__form-item">
            <Dropdown
              fullWidth
              label={t('.request_type', intl, __filenamespace)}
              placeholder={t(
                '.request_type_placeholder',
                intl,
                __filenamespace
              )}
              errorText={errors.getErrors('option')}
              items={managementOptions}
              value={selectedOption}
              onChange={e =>
                MembershipManagementActions.updateForm({
                  key: 'option',
                  value: e.target.value,
                })
              }
            />
          </div>
          {selectedOption === MANAGEMENT_OPTIONS.TERMINATE && (
            <div className="management-request-modal__form-item">
              <TerminationFields
                errors={errors}
                termination={managementForm.get('termination')}
                onChange={(key, value) =>
                  MembershipManagementActions.updateForm({ key, value })
                }
              />
            </div>
          )}
          {selectedOption === MANAGEMENT_OPTIONS.SUSPEND && (
            <div className="management-request-modal__form-item">
              <SuspensionFields
                errors={errors}
                suspensionLength={managementForm.get('suspension_length')}
                suspensionStartDate={managementForm.get(
                  'suspension_start_date'
                )}
                onChange={(key, value) =>
                  MembershipManagementActions.updateForm({ key, value })
                }
              />
            </div>
          )}
          {(selectedOption === MANAGEMENT_OPTIONS.UPGRADE ||
            selectedOption === MANAGEMENT_OPTIONS.DOWNGRADE) && (
            <div className="management-request-modal__form-item">
              <UpdateFields
                errors={errors}
                membershipsLoading={membershipsLoading}
                membershipTiersLoading={membershipTiersLoading}
                membershipIds={membershipIds}
                membershipTierIds={membershipTierIds}
                newMembershipId={managementForm.get('new_membership_id')}
                newMembershipTierId={managementForm.get(
                  'new_membership_tier_id'
                )}
                onChange={(key, value) =>
                  MembershipManagementActions.updateForm({ key, value })
                }
              />
            </div>
          )}
          <div className="management-request-modal__form-item">
            <Typography variant="body1">
              {t('.required_message', intl, __filenamespace)}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              errorText={errors.getErrors('reason')}
              value={managementForm.get('reason')}
              onChange={e =>
                MembershipManagementActions.updateForm({
                  key: 'reason',
                  value: e.target.value,
                })
              }
            />
          </div>
        </>
      )}
    </Confirmation>
  );
}

ManagementRequestModal.propTypes = {
  clientDataStore: PropTypes.instanceOf(ClientDataStore).isRequired,
  membershipManagementStore: PropTypes.instanceOf(MembershipManagementStore)
    .isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      membershipManagementStore: MembershipManagementStore,
      clientDataStore: ClientDataStore,
    },
  })
)(ManagementRequestModal);
