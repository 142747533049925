import EventRateCreationActions from 'contacts/shared/actions/event_rates/EventRateCreationActions';
import EventRateEditingStoreInterface from 'contacts/shared/stores/event_rates/EventRateEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  EventRateEditingStoreInterface,
  'EventRateCreationStore',
  {
    actions: EventRateCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
