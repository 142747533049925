import React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import DecoratedTextField from 'shared/components/DecoratedTextField.jsx';
import Form from 'shared/components/Form.jsx';
import SelectedItemOrMembership from 'shared/components/SelectedEventOrType.jsx';
import RetailAndMembershipsAutocomplete from 'shared/components/_RetailsAndMembershipsAutocomplete.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';

import { RadioButton } from '@upperhand/playmaker';
import CommissionRateListingActions from 'contacts/shared/actions/commission_rates/CommissionRateListingActions';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { ABSOLUTE, PERCENT } from 'shared/records/CommissionRate.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const SPECIFIC_TYPE = 'specific_type';

const styles = {
  header: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#9A9EAD',
  },
  tooltip: {
    lineHeight: 1.4,
    fontWeight: 500,
    paddingRight: 25,
  },
  typeIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
    position: 'absolute',
    right: 0,
  },
  formLabel: {
    fontSize: '17px',
    fontWeight: 600,
  },
};

function FormHeader({ commissionRate }) {
  return (
    <div style={styles.header}>
      {commissionRate && commissionRate.id ? (
        <FormattedMessage
          id={messageId('.edit_commission_rate', __filenamespace)}
        />
      ) : (
        <div>
          <FormattedMessage
            id={messageId('.add_commission_rate', __filenamespace)}
          />
        </div>
      )}
    </div>
  );
}

function CheckboxLabel() {
  return (
    <FlexBoxCenter style={{ flex: '1 1 90%' }}>
      <FormattedMessage
        id={messageId('.discount_sensitive_rate', __filenamespace)}
      />
      <InfoIcon
        data-tip
        data-for="usage-info"
        style={{ height: 20, width: 20, marginLeft: 5 }}
      />
      <ReactTooltip
        id="usage-info"
        effect="solid"
        style={{ maxWidth: 240 }}
        className="charge-failure-tooltip uh-tooltip"
      >
        <div style={{ lineHeight: 1.4 }}>
          <FormattedMessage
            id={messageId('.sensitive_rate', __filenamespace)}
          />
        </div>
      </ReactTooltip>
    </FlexBoxCenter>
  );
}

function SelectedItemsList({ selectedItems, onRemove }) {
  return (
    <div style={{ marginTop: 5 }}>
      {selectedItems.map(item => (
        <SelectedItemOrMembership
          key={item.id}
          event={item}
          title={item.name}
          id={item.id}
          barColor={item?.color}
          onRemove={id => {
            onRemove(id);
            CommissionRateListingActions.handleSearch({ searchText: '' });
          }}
        />
      ))}
    </div>
  );
}

function CommissionRateForm({
  items,
  selectedItems,
  commissionRateStore: {
    commissionRate,
    isSaving,
    selectedItemType,
    actions: {
      save,
      cancel,
      updated,
      commissionRateCommissionSelected,
      commissionRateCommissionRemoved,
      toggleItemType,
    },
  },
  selectedItemIds,
  intl,
  style,
}) {
  return (
    <Paper style={merge({ padding: 16, position: 'relative' }, style)}>
      <FormHeader commissionRate={commissionRate} />

      <Form onSubmit={save}>
        <RadioButton
          name="commission_item_type"
          orientation="horizontal"
          selectedValue={selectedItemType}
          onChange={(_, value) => toggleItemType(value)}
          options={[
            {
              label: t('.all_retail_categories', intl, __filenamespace),
              value: 'all_retail_categories',
            },
            {
              label: t('.all_memberships', intl, __filenamespace),
              value: 'all_memberships',
            },
            {
              label: t('.specific_retail_memberships', intl, __filenamespace),
              value: 'specific_type',
            },
          ]}
        />
        {selectedItemType === SPECIFIC_TYPE && (
          <RetailAndMembershipsAutocomplete
            items={items.filter(i => !selectedItemIds.has(i.id))}
            hintText={t('.search_items', intl, __filenamespace)}
            errorText={commissionRate.errors.getErrors('commission', intl)}
            onChange={searchText => {
              CommissionRateListingActions.handleSearch({ searchText });
            }}
            onItemSelect={itemId => {
              CommissionRateListingActions.addSpecificItem(itemId);
              commissionRateCommissionSelected(itemId);
            }}
            onLeave={() => {
              CommissionRateListingActions.listItems();
            }}
            withIcon={false}
          />
        )}

        {selectedItems && (
          <SelectedItemsList
            selectedItems={selectedItems}
            selectedItemIds={selectedItemIds}
            onRemove={commissionRateCommissionRemoved}
          />
        )}
        <FlexBox style={{ marginTop: '1rem' }}>
          <FormControl fullWidth variant="standard">
            <InputLabel sx={styles.formLabel}>
              {t('.type', intl, __filenamespace)}
            </InputLabel>
            <Select
              label={t('.type', intl, __filenamespace)}
              sx={{ fontSize: '14px' }}
              value={commissionRate.rate_type || ''}
              onChange={e => updated({ rate_type: e.target.value, value: 0 })}
            >
              <MenuItem
                key="amount-discount"
                value={ABSOLUTE}
                sx={{ fontSize: '14px' }}
              >
                {t('.amount', intl, __filenamespace)}
              </MenuItem>
              <MenuItem
                key="percent-discount"
                value={PERCENT}
                sx={{ fontSize: '14px' }}
              >
                {t('.percent', intl, __filenamespace)}
              </MenuItem>
            </Select>
          </FormControl>
          {commissionRate.rate_type === ABSOLUTE ? (
            <CurrencyTextField
              variant="standard"
              name="value"
              floatingLabelText={t('.value', intl, __filenamespace)}
              onChange={(_, v) => updated({ value: v })}
              value={commissionRate.value}
              errorText={commissionRate.errors.getErrors('value', intl)}
              style={{ marginLeft: 10 }}
              converter={e => parseInt(e, 10)}
              inputLabelProps={{ sx: styles.formLabel }}
            />
          ) : (
            <DecoratedTextField
              variant="standard"
              name="value"
              floatingLabelText={t('.value', intl, __filenamespace)}
              fullWidth
              onChange={(_, v) => updated({ value: v })}
              value={commissionRate.value}
              decoration="%"
              inputProps={{ min: 0, max: 100 }}
              errorText={commissionRate.errors.getErrors('value', intl)}
              style={{ marginLeft: 10 }}
              inputLabelProps={{ sx: styles.formLabel }}
            />
          )}
        </FlexBox>
        <FlexBoxCenter style={{ marginTop: '1rem' }}>
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': { fontSize: 'initial' },
            }}
            control={
              <Checkbox
                checked={commissionRate.discount_sensitive}
                onChange={e =>
                  updated({ discount_sensitive: e.target.checked })
                }
              />
            }
            label={<CheckboxLabel />}
          />
        </FlexBoxCenter>
        <FlexBox style={{ justifyContent: 'flex-end', marginTop: 10 }}>
          <Button
            style={{ marginRight: 14 }}
            onClick={() => {
              cancel();
              CommissionRateListingActions.handleSearch({ searchText: '' });
            }}
          >
            {t('.cancel', intl, __filenamespace)}
          </Button>
          <StateChangingButton
            label={t('.save', intl, __filenamespace)}
            inProgress={isSaving}
            onClick={save}
          />
        </FlexBox>
      </Form>
    </Paper>
  );
}

function CommissionRateFormWrapper(props) {
  return (
    <AltContainer>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CommissionRateForm {...props} />
    </AltContainer>
  );
}

export default injectIntl(CommissionRateFormWrapper);
