import { List } from 'immutable';

import { onError, onSuccess } from 'sources/handlers';
import Coupon from 'records/Coupon';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import DataStoreActions from './CouponActions';

const parserTo = { type: Coupon, paginationKey: 'coupons' };

const parser = new Parser(parserTo);

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const create = ({ params, success, error }) => {
  uhApiClient.post({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const update = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateSuccess, error])),
  });
};

export const destroy = ({ id, success, error }) => {
  uhApiClient.delete({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.removeSuccess, success])
    ),
    error: onError(List([DataStoreActions.removeError, error])),
  });
};
