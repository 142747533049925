import React, { useState } from 'react';
import AddOns from 'customers/AddOns/AddOns.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import Tabs from 'components/Tabs/Tabs.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { injectIntl } from 'react-intl';
import AddOnsIcon from 'shared/components/icons/AddOnsIcon.jsx';
import { styled } from '@mui/material/styles';

const CustomTabs = styled(Tabs)({
  '& .UH-MuiTabs-flexContainer': {
    display: 'inline',
  },
  '& .UH-MuiButtonBase-root': {
    color: '#454952 !important',
    fontWeight: 'bold',
    fontSize: '20px',
    padding: '20px',
    textTransform: 'capitalize',
  },
});
const ADD_ONS_TABS = {
  AddOns: 'AddOns',
};
function AddOnsTabs({ intl }) {
  const [activeTab, setActiveTab] = useState(ADD_ONS_TABS.AddOns);
  const tabItems = [
    {
      tabLabel: t('.add_ons', intl, __filenamespace),
      tabIcon: <AddOnsIcon />,
      tabContent: <AddOns />,
      tabIndex: ADD_ONS_TABS.AddOns,
      tabVisible: true,
    },
  ];

  return (
    <CustomTabs
      variant={smallScreen() ? 'scrollable' : 'fullWidth'}
      value={activeTab}
      tabItems={tabItems}
      handleChange={(e, tab) => setActiveTab(tab)}
    />
  );
}
export default injectIntl(AddOnsTabs);
