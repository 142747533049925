import { Map, Record } from 'immutable';

class BankAccount extends Record({
  id: null,
  currency: null,
  account_number: '',
  account_holder_name: '',
  account_holder_type: '',
  bank_name: '',
  default_for_currency: false,
  last4: '',
  metadata: Map(),
  routing_number: '',
  status: '',
}) {
  errorTextFor(name) {
    if (name === 'bank_name' || name === 'last4' || name === 'status') {
      return null;
    }
    return this[name] ? null : 'Required';
  }
}

export default BankAccount;
