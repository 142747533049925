import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Map, Set } from 'immutable';
import { DateTimePicker } from '@upperhand/playmaker';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import AvailabilityTimePicker from 'shared/components/scheduling/AvailabilityTimePicker.jsx';
import BlackoutDatePicker from 'shared/components/scheduling/BlackoutDatePicker.jsx';
import DateSpecificDaytimePicker from 'shared/components/scheduling/DateSpecificDaytimePicker.jsx';
import StaffAvailabilityActions from 'shared/actions/StaffAvailabilityActions.jsx';
import StaffAvailabilityStore from 'shared/stores/StaffAvailabilityStore.jsx';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';
import WeekdayPicker from 'shared/components/scheduling/WeekdayPicker.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  datePicker: {
    textAlign: 'right',
    color: uhColors.leftNavGrey,
  },

  datePickerHint: {
    opacity: 1,
    color: uhColors.hint,
  },

  label: {
    color: uhColors.leftNavGrey,
    fontWeight: 'bold',
    marginTop: 25,
    marginBottom: 15,
  },
};

const saveAvailability = () => {
  StaffAvailabilityActions.createOrUpdate();
  if (StaffAvailabilityStore.getState().errors.isEmpty()) {
    StaffDetailsDrawerActions.toggleInfoEditMode();
  }
};

const cancelEdit = () => StaffDetailsDrawerActions.toggleInfoEditMode();

function StaffDetailsDrawerEditing({ staffAvailability, intl }) {
  const { availabilitySchedule } = staffAvailability;

  const startDate = availabilitySchedule.start_date;
  const endDate = availabilitySchedule.end_date;
  const startDateSpecific = moment(startDate).isBefore()
    ? new Date()
    : startDate;
  const { exclusions } = availabilitySchedule;

  const handleWeekdayChange = weekdays => {
    const { daytimes } = availabilitySchedule;
    const times =
      daytimes.first() || List([Map({ start_time: '', end_time: '' })]);

    const updatedDaytimes = (weekdays.size > 0 ? weekdays : Set(['none']))
      .toMap()
      .map(weekday => daytimes.get(weekday) || times);

    StaffAvailabilityActions.updateStore(['daytimes'], updatedDaytimes);
  };

  const handleIndefiniteChange = (e, value) => {
    if (value) {
      StaffAvailabilityActions.updateStore(['end_date'], null);
    } else if (availabilitySchedule.start_date) {
      const endOfMonth = moment(availabilitySchedule.start_date)
        .endOf('month')
        .toDate();
      StaffAvailabilityActions.updateStore(['end_date'], endOfMonth);
    }

    StaffAvailabilityActions.updateStore(['indefinite'], value);
  };

  return (
    <div>
      <Stack spacing={3}>
        <DateTimePicker
          autoOk
          fullWidth
          showClearIcon={false}
          type="date"
          format="MMM D, YYYY"
          label={t('.start', intl, __filenamespace)}
          value={startDate}
          errorText={staffAvailability.errors.get('start_date', []).join(' ')}
          onChange={value =>
            StaffAvailabilityActions.updateStore(['start_date'], value)
          }
        />
        {!availabilitySchedule.indefinite && (
          <DateTimePicker
            autoOk
            fullWidth
            showClearIcon={false}
            type="date"
            format="MMM D, YYYY"
            label={t('.end', intl, __filenamespace)}
            value={endDate}
            minDate={startDate}
            errorText={staffAvailability.errors.get('end_date', []).join(' ')}
            onChange={value =>
              StaffAvailabilityActions.updateStore(['end_date'], value)
            }
          />
        )}
      </Stack>

      <FormControlLabel
        sx={{
          '& .MuiFormControlLabel-label': { fontSize: 'initial' },
          marginTop: '1rem',
        }}
        label={t('.indefinite', intl, __filenamespace)}
        control={
          <Checkbox
            onChange={handleIndefiniteChange}
            checked={availabilitySchedule.indefinite}
          />
        }
      />

      <div style={styles.label}>
        <FormattedMessage id={messageId('.week', __filenamespace)} />
      </div>
      <WeekdayPicker
        onChangeWeekdays={handleWeekdayChange}
        selectedDays={availabilitySchedule.repeatingWeekdays()}
        errorText={staffAvailability.errors.get('daytimes', []).join(' ')}
      />

      <AvailabilityTimePicker
        style={{ marginTop: 20 }}
        legend={t('.availability_legend', intl, __filenamespace)}
        schedule={availabilitySchedule}
        handleAvailabilityScheduleChange={StaffAvailabilityActions.updateStore}
        fieldErrors={staffAvailability.errors}
      />

      <div style={styles.formElementWrapper}>
        <DateSpecificDaytimePicker
          legend={t('.date_specific_availability', intl, __filenamespace)}
          availabilitySchedule={availabilitySchedule.set(
            'start_date',
            startDateSpecific
          )}
          handleAvailabilityScheduleChange={
            StaffAvailabilityActions.updateStore
          }
          fieldErrors={staffAvailability.errors}
          clientDrawer
        />
      </div>

      <div style={styles.label}>
        <FormattedMessage id={messageId('.blackout', __filenamespace)} />
      </div>
      <BlackoutDatePicker
        onAddDate={() =>
          StaffAvailabilityActions.updateStore(
            ['exclusions'],
            exclusions.push(new Date())
          )
        }
        onChangeDate={(value, index) =>
          StaffAvailabilityActions.updateStore(
            ['exclusions'],
            exclusions.set(index, value)
          )
        }
        onDeleteDate={index =>
          StaffAvailabilityActions.updateStore(
            ['exclusions'],
            exclusions.delete(index)
          )
        }
        excludedDates={availabilitySchedule.exclusions}
        showLabel={false}
      />

      <Button
        fullWidth
        variant="contained"
        sx={{ marginTop: '20px', marginBottom: '10px', height: '50px' }}
        onClick={saveAvailability}
      >
        {t('.save', intl, __filenamespace)}
      </Button>
      <Button
        fullWidth
        variant="outlined"
        sx={{ marginBottom: '20px', height: '50px' }}
        onClick={cancelEdit}
      >
        {t('actions.cancel', intl, __filenamespace)}
      </Button>
    </div>
  );
}

export default injectIntl(StaffDetailsDrawerEditing);
