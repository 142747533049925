import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import ColoredEventType from 'memberships/components/_ColoredEventType.jsx';
import ColoredItem from 'memberships/components/_ColoredItem.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const customStyles = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: -4,
};
const customAdmin = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  marginLeft: -2,
};
const customModal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};
function BenefitHeader({ membershipEventCredit }) {
  if (membershipEventCredit.unlimited) {
    return (
      <span style={{ fontWeight: 'bold' }}>
        <FormattedMessage
          id={messageId('.unlimited_sessions', __filenamespace)}
        />
      </span>
    );
  }

  return (
    <FormattedMessage
      id={messageId('.sessions_per_frequency', __filenamespace)}
      values={{
        frequency: (
          <FormattedMessage
            id={messageId(
              `.${membershipEventCredit.frequency}`,
              __filenamespace
            )}
          />
        ),
        sessions: (
          <span style={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id={messageId('.n_sessions', __filenamespace)}
              values={{ n: membershipEventCredit.quantity }}
            />
          </span>
        ),
      }}
    />
  );
}

const EventCreditBenefit = injectIntl(
  ({
    membershipEventCredit,
    leftStyles,
    styles,
    mod,
    itemStyle,
    textStyle,
  }) => {
    let allEventsStyles;

    if (mod) {
      allEventsStyles = customModal;
    } else if (leftStyles) {
      allEventsStyles = customStyles;
    } else {
      allEventsStyles = customAdmin;
    }
    return (
      <div style={styles}>
        <BenefitHeader membershipEventCredit={membershipEventCredit} />

        {membershipEventCredit.all_events && (
          <div>
            <FormattedMessage id={messageId('.any_event', __filenamespace)} />
          </div>
        )}

        {!membershipEventCredit.all_events && (
          <div style={allEventsStyles}>
            {membershipEventCredit.event_types.map(t => (
              <ColoredEventType
                key={t.id}
                eventType={t}
                leftStyles={leftStyles}
                mod={mod}
                style={itemStyle}
                textStyle={textStyle}
              />
            ))}
            {membershipEventCredit.events.map(event => (
              <ColoredItem
                key={event.id}
                color={event.event_type.color}
                text={event.title}
                leftStyles={leftStyles}
                style={itemStyle}
                textStyle={textStyle}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
);

function CreditBenefits({
  membership,
  creditBenefitsStyle,
  leftStyles,
  styles,
  mod,
  itemStyle,
  textStyle,
}) {
  return (
    <div style={creditBenefitsStyle}>
      {membership.membership_event_credits.map(mec => (
        <EventCreditBenefit
          membershipEventCredit={mec}
          key={mec.id}
          leftStyles={leftStyles}
          styles={styles}
          mod={mod}
          itemStyle={itemStyle}
          textStyle={textStyle}
        />
      ))}
    </div>
  );
}

CreditBenefits.propTypes = {
  membership: PropTypes.object.isRequired,
};

export default CreditBenefits;
