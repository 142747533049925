import { Map, List } from 'immutable';

import { ClientSource } from 'sources';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import AmusementCreditRedemption from 'shared/records/AmusementCreditRedemption';
import OrderItem from 'shared/records/OrderItem';
import Order from 'event_mgmt/shared/records/Order.jsx';

import { ClientDataStore } from 'dataStores';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import SwingCreditsDrawerActions from './Actions.js';

export const SWING_DRAWER_TABS = {
  MEMBERSHIP: 'membership',
  SWING_HISTORY: 'swingHistory',
};

class SwingCreditsDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.resetTopUpModal();
    this.bindListeners({
      mounted: SwingCreditsDrawerActions.mounted,
      swingsPageSelected: SwingCreditsDrawerActions.swingsPageSelected,

      fetchClientSuccess: SwingCreditsDrawerActions.fetchClientSuccess,
      fetchClientError: SwingCreditsDrawerActions.fetchClientError,

      listSwings: SwingCreditsDrawerActions.listSwings,
      listSwingsSuccess: SwingCreditsDrawerActions.listSwingsSuccess,
      listSwingsError: SwingCreditsDrawerActions.listSwingsError,

      toggleTopUpModal: SwingCreditsDrawerActions.toggleTopUpModal,
      variantChange: SwingCreditsDrawerActions.variantChange,
      listVariantsSuccess: SwingCreditsDrawerActions.listVariantsSuccess,
      listVariantsError: SwingCreditsDrawerActions.listVariantsError,

      purchaseSuccess: SwingCreditsDrawerActions.purchaseSuccess,
      createOrderItemSuccess: SwingCreditsDrawerActions.createOrderItemSuccess,
      createOrderItemError: SwingCreditsDrawerActions.createOrderItemError,
      fetchOrderSuccess: SwingCreditsDrawerActions.fetchOrderSuccess,
      fetchOrderError: SwingCreditsDrawerActions.fetchOrderError,

      accountCreditChanged: SwingCreditsDrawerActions.accountCreditChanged,
    });
  }

  reset() {
    this.clientLoading = false;
    this.swingsLoading = false;
    this.clientId = null;
    this.swings = [];
    this.pagination = Map({
      swingsHistory: Map({
        page: 1,
        perPage: 15,
        totalCount: 0,
      }),
    });
  }

  mounted({ clientId }) {
    this.clientId = clientId;

    if (!clientId) {
      this.reset();
      this.resetTopUpModal();
      return;
    }

    const { clients } = ClientDataStore.getState();
    const client = clients.get(clientId);

    if (!client) {
      this.clientLoading = true;
      this.fetchClient();
    } else {
      this.swingsLoading = true;
      this.listSwings();
    }
  }

  resetTopUpModal() {
    this.topUpModalOpen = false;
    this.loadingVariants = false;
    this.variants = [];
    this.creatingOrderItem = false;
    this.purchasing = false;
    this.fetchingOrder = false;
    this.selectedVariantId = null;
    this.order = new Order();
    this.accountCreditAmount = null;
    this.paymentAmount = null;
  }

  toggleTopUpModal() {
    this.topUpModalOpen = !this.topUpModalOpen;

    if (this.topUpModalOpen) {
      this.listVariants({});
    } else {
      this.resetTopUpModal();
    }
  }

  accountCreditChanged(amount) {
    const orderItem = this.order.get('order_items', List()).first();

    this.accountCreditAmount =
      amount < this.paymentAmount ? amount : this.paymentAmount;

    if (amount !== 0) {
      this.updateOrderItem(
        orderItem.set('account_credit_amount', this.accountCreditAmount)
      );
    }
  }

  variantChange({ variantId }) {
    this.selectedVariantId = variantId;

    if (this.order.id === null) {
      this.createOrderItem();
    } else {
      const orderItem = this.order.get('order_items', List()).first();

      this.updateOrderItem(orderItem);
    }
  }

  listVariants({ page = 1 } = {}) {
    this.loadingVariants = true;
    uhApiClient.get({
      url: `/variants`,
      data: {
        page,
        per_page: 50,
      },
      success: SwingCreditsDrawerActions.listVariantsSuccess,
      error: SwingCreditsDrawerActions.listVariantsError,
    });
  }

  listVariantsSuccess({
    variants,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.variants = [...this.variants, ...variants];

    if (page * perPage >= totalCount) {
      this.loadingVariants = false;
      return;
    }

    this.listVariants({ page: page + 1 });
  }

  listVariantsError(...args) {
    this.loadingVariants = false;
    this.notifyError('Error loading products', args);
  }

  swingsPageSelected([page]) {
    this.pagination = this.pagination.setIn(['swingsHistory', 'page'], page);
    this.listSwings();
  }

  fetchClient() {
    this.clientLoading = true;

    ClientSource.fetch({
      id: this.clientId,
      params: {
        fields: [
          'total_credits',
          'agreement_details',
          'total_cash_credits',
          'total_account_credit',
        ],
      },
      success: SwingCreditsDrawerActions.fetchClientSuccess,
      error: SwingCreditsDrawerActions.fetchClientError,
    });
  }

  fetchClientSuccess() {
    this.clientLoading = false;
    this.listSwings();
  }

  fetchClientError() {
    this.clientLoading = false;
  }

  listSwings() {
    this.swingsLoading = true;

    uhApiClient.get({
      url: 'amusement/credit_redemptions',
      data: {
        client_id: this.clientId,
        page: this.pagination.getIn(['swingsHistory', 'page']),
        per_page: this.pagination.getIn(['swingsHistory', 'perPage']),
      },
      success: SwingCreditsDrawerActions.listSwingsSuccess,
      error: SwingCreditsDrawerActions.listSwingsError,
    });
  }

  listSwingsSuccess({
    records,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.swings = records.map(record => new AmusementCreditRedemption(record));
    this.pagination = this.pagination
      .setIn(['swingsHistory', 'page'], page)
      .setIn(['swingsHistory', 'perPage'], perPage)
      .setIn(['swingsHistory', 'totalCount'], totalCount);
    this.swingsLoading = false;
  }

  listSwingsError(...args) {
    this.swingsLoading = false;
    this.notifyError('Error loading swings history', args);
  }

  purchaseSuccess() {
    MessageWindowActions.addMessage.defer('Swings purchased successfully');
    this.fetchClient();
    this.resetTopUpModal();
  }

  createOrderItem() {
    const newItem = new OrderItem({
      order_id: null,
      orderable_id: this.selectedVariantId,
      orderable_type: 'variant',
      cash_credit_destination_id: this.clientId,
      quantity: 1,
    });
    const { clients } = ClientDataStore.getState();
    const client = clients.get(this.clientId);

    this.creatingOrderItem = true;
    uhApiClient.post({
      url: 'order_items',
      data: JSON.stringify({
        attributes: newItem.toServer(),
        customer_user_id: client.customerUserId,
      }),
      success: SwingCreditsDrawerActions.createOrderItemSuccess,
      error: SwingCreditsDrawerActions.createOrderItemError,
    });
  }

  updateOrderItem(orderItem) {
    this.creatingOrderItem = true;
    uhApiClient.put({
      url: `order_items/${orderItem.id}`,
      data: JSON.stringify({
        attributes: {
          orderable_id: this.selectedVariantId,
          orderable_type: orderItem.orderable_type,
          cash_credit_destination_id: orderItem.cash_credit_destination_id,
          account_credit_amount: this.accountCreditAmount,
          quantity: orderItem.quantity,
        },
      }),
      success: SwingCreditsDrawerActions.createOrderItemSuccess,
      error: SwingCreditsDrawerActions.createOrderItemError,
    });
  }

  createOrderItemSuccess(orderItem) {
    this.creatingOrderItem = false;
    this.fetchOrder(orderItem.order_id);
  }

  createOrderItemError(...args) {
    this.creatingOrderItem = false;
    this.notifyError('Error creating order item', args);
  }

  fetchOrder(orderId) {
    this.fetchingOrder = true;
    uhApiClient.get({
      url: `orders/${orderId}`,
      data: {
        fields: ['order_items'],
      },
      success: SwingCreditsDrawerActions.fetchOrderSuccess,
      error: SwingCreditsDrawerActions.fetchOrderError,
    });
  }

  fetchOrderSuccess(order) {
    this.fetchingOrder = false;
    this.order = new Order(order);
    this.paymentAmount = order.total;
  }

  fetchOrderError(...args) {
    this.fetchingOrder = false;
    this.notifyError('Error fetching order', args);
  }
}

export default alt.createStore(
  SwingCreditsDrawerStore,
  'SwingCreditsDrawerStore'
);
