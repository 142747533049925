import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button as PMButton,
  DateTimePicker,
  Typography,
} from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';

const styles = {
  addAnother: {
    textTransform: 'capitalize',
  },
  title: {
    marginBottom: 4,
  },
  datePickerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    maxWidth: SINGLE_COLUMN_WIDTH,
  },
  deleteButton: {
    backgroundColor: 'transparent',
    marginLeft: 4,
  },
};

function BlackoutDatePicker({
  classes,
  onAddDate,
  onChangeDate,
  onDeleteDate,
  excludedDates,
  shouldDisableDate,
  showLabel,
}) {
  return (
    <div>
      {showLabel && excludedDates.size > 0 && (
        <Typography variant="body1" className={classes.title}>
          Blackout Dates
        </Typography>
      )}
      {excludedDates.map((exclusion, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`exclusion_date_picker_wrapper_${i}`}
          style={styles.datePickerWrapper}
        >
          <DateTimePicker
            autoOk
            fullWidth
            type="date"
            onChange={value => onChangeDate(value.format('YYYY-MM-DD'), i)}
            name={`exclusion_${i}`}
            placeholder="MMM D, YYYY"
            value={exclusion}
            format="MMM D, YYYY"
            showClearIcon={false}
            disabled={shouldDisableDate(exclusion && new Date(exclusion))}
          />
          <PMButton
            classes={{
              root: classes.deleteButton,
            }}
            type="tertiary"
            icon="close"
            rounded
            onClick={() => onDeleteDate(i)}
          />
        </div>
      ))}
      <Button
        color="dark"
        startIcon={<AddIcon sx={{ color: uhColors.activeBlue }} />}
        style={styles.addAnother}
        onClick={onAddDate}
      >
        Add Blackout Day
      </Button>
    </div>
  );
}

BlackoutDatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  excludedDates: PropTypes.object.isRequired,
  onAddDate: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onDeleteDate: PropTypes.func.isRequired,
  shouldDisableDate: PropTypes.func,
  showLabel: PropTypes.bool,
};

BlackoutDatePicker.defaultProps = {
  showLabel: true,
  shouldDisableDate: () => false,
};

export default withStyles(styles)(BlackoutDatePicker);
