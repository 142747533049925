export const cleanPhoneFormat = (value, code = '+1') =>
  value.replace(code, '').replace(/\D/g, '').slice(0, 10);

export const formatPhoneToE164 = (value, code = '+1') =>
  `${code}${value.replace(code, '').replace(/\D/g, '').slice(0, 10)}`;

export const formatPhone = (value, code = '+1') => {
  const number = value.replace(code, '').replace(/\D/g, '');

  const areaCode = number.slice(0, 3);
  const exchangeCode = number.slice(3, 6);
  const subscriberNumber = number.slice(6, 10);

  return [areaCode, exchangeCode, subscriberNumber].filter(e => e).join('-');
};

export const isPhone = (value, code = '+1') =>
  value.match(/^(\d{3})-(\d{3})-(\d{4})$/g) ||
  value.replace(code, '').match(/^\d{10}$/g);

// https://stackoverflow.com/a/2901298
const numberWithCommas = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatCurrency = ({
  value,
  fromCents = false,
  precision = 2,
  symbol = '$',
}) => {
  let formattedValue = value;
  if (!formattedValue) {
    formattedValue = 0.0;
  }

  if (!formattedValue.toFixed) {
    formattedValue = parseFloat(formattedValue, 10);
  }

  if (fromCents) {
    formattedValue /= 100;
  }

  formattedValue = formattedValue.toFixed(precision);

  return `${symbol}${numberWithCommas(formattedValue)}`;
};

/**
 * Removes protocol and www subdomain from the given url. Subdomains other than
 * www are not removed.
 *
 * @param {string} url The url to humanize.
 * @return {string} New humanized url string.
 */
export function humanizeUrl(url) {
  if (url) {
    // eslint-disable-line no-extra-boolean-cast
    return url.replace(/^.*:\/\//, '').replace(/^www./, '');
  }
  return null;
}

export function shortenedUrl(url, maxLength = '35') {
  if (!url) return '';
  const urlLength = url.length;
  const firstLength = maxLength * 0.45;
  return urlLength < maxLength
    ? url
    : `${url.substring(0, firstLength)}...${url.substring(
        urlLength - 20,
        urlLength
      )}`;
}

export function shortHumanUrl(url, maxLength = '35') {
  if (!url) return '';
  return shortenedUrl(humanizeUrl(url), maxLength);
}

/**
 * Convert string form snake_case to Title Case
 *
 * @param {string} string The string to format.
 */

export const snakeToTitleCase = string =>
  string
    .replace(
      /([a-z])([A-Z])/g,
      (allMatches, firstMatch, secondMatch) => `${firstMatch}${secondMatch}`
    )
    .toLowerCase()
    .replace(
      /([ -_]|^)(.)/g,
      (allMatches, firstMatch, secondMatch) =>
        `${firstMatch ? ' ' : ''}${secondMatch.toUpperCase()}`
    );
