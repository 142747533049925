import { List, Map, Set } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { isFunction, isArray } from 'shared/utils/SharedUtils.js';
import {
  BalancesSource,
  ClientSource,
  EventSource,
  EventTypeSource,
  OrderItemSource,
  PaymentPlanSource,
  ScheduledPaymentSource,
} from 'sources';
import PaymentPlanDetailsDrawerActions from './Actions';

class PaymentPlanDetailsDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      openDrawer: PaymentPlanDetailsDrawerActions.openDrawer,
      closeDrawer: PaymentPlanDetailsDrawerActions.closeDrawer,
      closeAllDrawers: PaymentPlanDetailsDrawerActions.closeAllDrawers,
      fetchError: PaymentPlanDetailsDrawerActions.fetchError,
      fetchSuccess: PaymentPlanDetailsDrawerActions.fetchSuccess,
      fetchClientSuccess: PaymentPlanDetailsDrawerActions.fetchClientSuccess,
      fetchClientError: PaymentPlanDetailsDrawerActions.fetchClientError,
      listScheduledPaymentsSuccess:
        PaymentPlanDetailsDrawerActions.listScheduledPaymentsSuccess,
      listScheduledPaymentsError:
        PaymentPlanDetailsDrawerActions.listScheduledPaymentsError,
      toggleDotsMenu: PaymentPlanDetailsDrawerActions.toggleDotsMenu,
      listOrderItemsSuccess:
        PaymentPlanDetailsDrawerActions.listOrderItemsSuccess,
      listOrderItemsError: PaymentPlanDetailsDrawerActions.listOrderItemsError,
      listEventsSuccess: PaymentPlanDetailsDrawerActions.listEventsSuccess,
      listEventsError: PaymentPlanDetailsDrawerActions.listEventsError,
      listEventTypesSuccess:
        PaymentPlanDetailsDrawerActions.listEventTypesSuccess,
      listEventTypesError: PaymentPlanDetailsDrawerActions.listEventTypesError,
      listBalancesSuccess: PaymentPlanDetailsDrawerActions.listBalancesSuccess,
      listBalancesError: PaymentPlanDetailsDrawerActions.listBalancesError,
      fetchBalanceDetailsSuccess:
        PaymentPlanDetailsDrawerActions.fetchBalanceDetailsSuccess,
      fetchBalanceDetailsError:
        PaymentPlanDetailsDrawerActions.fetchBalanceDetailsError,
      toggleCancelAllPayments:
        PaymentPlanDetailsDrawerActions.toggleCancelAllPayments,
      toggleEditPayments: PaymentPlanDetailsDrawerActions.toggleEditPayments,
      updateScheduledPayments:
        PaymentPlanDetailsDrawerActions.updateScheduledPayments,
      updateScheduledPaymentsSuccess:
        PaymentPlanDetailsDrawerActions.updateScheduledPaymentsSuccess,
      updateScheduledPaymentsError:
        PaymentPlanDetailsDrawerActions.updateScheduledPaymentsError,
    });
  }

  reset() {
    this.isLoading = false;
    this.isClientLoading = false;
    this.isPaymentPlanLoading = false;
    this.areScheduledPaymentsLoading = false;
    this.isLoadingScheduledPaymentDetails = Map();
    this.balanceIds = Map();
    this.isLoadingOrderItems = false;
    this.isLoadingEvents = false;
    this.isLoadingEventTypes = false;
    this.paymentPlanId = null;
    this.scheduledPaymentIds = List();
    this.orderItemIds = Set();
    this.eventIds = Set();
    this.eventTypeIds = Set();
    this.dotsMenuAnchorEl = null;
    this.clientId = null;
    this.closeOtherDrawersActions = [];
    this.isCancelAllPaymentsModalOpen = false;
    this.isEditPaymentsModalOpen = false;
  }

  openDrawer(args) {
    const [paymentPlanId, closeOtherDrawersActions = []] = isArray(args)
      ? args
      : [args];

    this.paymentPlanId = paymentPlanId;
    this.isPaymentPlanLoading = true;
    this.closeOtherDrawersActions = closeOtherDrawersActions;
    this.setLoading();

    PaymentPlanSource.fetch({
      id: paymentPlanId,
      params: { fields: ['allocations'] },
      success: PaymentPlanDetailsDrawerActions.fetchSuccess,
      error: PaymentPlanDetailsDrawerActions.fetchError,
    });
  }

  fetchError(...args) {
    this.errorAndReset('payment plan', args);
  }

  fetchSuccess(paymentPlan) {
    this.clientId = paymentPlan.get('clientId');
    this.orderItemIds = paymentPlan.get('allocations').map(a => a.source_id);
    this.isPaymentPlanLoading = false;
    this.fetchDependencies();
    this.setLoading();
  }

  fetchDependencies() {
    this.areScheduledPaymentsLoading = true;
    this.isClientLoading = true;
    this.isLoadingOrderItems = true;
    this.setLoading();

    ScheduledPaymentSource.list({
      params: { payment_plan_ids: [this.paymentPlanId], per_page: 50 },
      success: PaymentPlanDetailsDrawerActions.listScheduledPaymentsSuccess,
      error: PaymentPlanDetailsDrawerActions.listScheduledPaymentsError,
    });

    ClientSource.fetch({
      id: this.clientId,
      success: PaymentPlanDetailsDrawerActions.fetchClientSuccess,
      error: PaymentPlanDetailsDrawerActions.fetchClientError,
    });

    OrderItemSource.list({
      params: { ids: this.orderItemIds.toJS() },
      success: PaymentPlanDetailsDrawerActions.listOrderItemsSuccess,
      error: PaymentPlanDetailsDrawerActions.listOrderItemsError,
    });
  }

  fetchClientSuccess(_client) {
    this.isClientLoading = false;
    this.setLoading();
  }

  fetchClientError(...args) {
    this.errorAndReset('client', args);
  }

  listScheduledPaymentsSuccess({ scheduled_payments: scheduledPayments }) {
    // using a List because it will maintain sort order, unlike Set
    this.scheduledPaymentIds = List(scheduledPayments.map(sp => sp.id));

    this.isLoadingBalances = true;
    BalancesSource.list({
      params: {
        product_ids: this.scheduledPaymentIds.toJS(),
      },
      success: PaymentPlanDetailsDrawerActions.listBalancesSuccess,
      error: PaymentPlanDetailsDrawerActions.listBalancesError,
    });

    this.areScheduledPaymentsLoading = false;
    this.setLoading();
  }

  listScheduledPaymentsError(...args) {
    this.errorAndReset('scheduled payments', args);
  }

  listOrderItemsSuccess({ order_items: orderItems }) {
    const isTeamSchedule = orderItems.first().get('orderable').isTeamSchedule();
    this.isLoadingOrderItems = false;

    this.isLoadingEvents = true;
    this.isLoadingEventTypes = true;

    this.setLoading();

    // assumes orderable is RegistrationPackage
    this.eventIds = orderItems.map(oi => oi.get('orderable').get('event_id'));
    this.eventTypeIds = orderItems.map(oi =>
      oi.get('orderable').get('event_type_id')
    );
    if (isTeamSchedule) {
      this.isLoadingEventTypes = false;
      EventSource.list({
        params: { ids: this.eventIds.toJS(), fields: ['team_type'] },
        success: PaymentPlanDetailsDrawerActions.listEventsSuccess,
        error: PaymentPlanDetailsDrawerActions.listEventsError,
      });
    } else {
      EventSource.list({
        params: { ids: this.eventIds.toJS() },
        success: PaymentPlanDetailsDrawerActions.listEventsSuccess,
        error: PaymentPlanDetailsDrawerActions.listEventsError,
      });

      EventTypeSource.list({
        params: { ids: this.eventTypeIds.toJS() },
        success: PaymentPlanDetailsDrawerActions.listEventTypesSuccess,
        error: PaymentPlanDetailsDrawerActions.listEventTypesError,
      });
    }
  }

  listOrderItemsError(...args) {
    this.errorAndReset('order items', args);
  }

  listEventsSuccess() {
    this.isLoadingEvents = false;
    this.setLoading();
  }

  listEventsError(...args) {
    this.errorAndReset('events', args);
  }

  listEventTypesSuccess() {
    this.isLoadingEventTypes = false;
    this.setLoading();
  }

  listEventTypesError(...args) {
    this.errorAndReset('event types', args);
  }

  listBalancesSuccess({ records }) {
    this.balanceIds = Map(
      records.map(balance => [
        balance.get('productId'),
        balance.get('compoundId'),
      ])
    );

    this.isLoadingBalances = false;
    records.forEach(balance => {
      this.isLoadingScheduledPaymentDetails =
        this.isLoadingScheduledPaymentDetails.set(
          balance.get('compoundId'),
          true
        );

      BalancesSource.details({
        productId: balance.get('productId'),
        compoundId: balance.get('compoundId'),
        success: PaymentPlanDetailsDrawerActions.fetchBalanceDetailsSuccess,
        error: PaymentPlanDetailsDrawerActions.fetchBalanceDetailsError,
      });
    });
    this.setLoading();
  }

  listBalancesError(...args) {
    this.errorAndReset('balances', args);
  }

  fetchBalanceDetailsSuccess({ compoundId }) {
    this.isLoadingScheduledPaymentDetails =
      this.isLoadingScheduledPaymentDetails.set(compoundId, false);
    this.setLoading();
  }

  fetchBalanceDetailsError(...args) {
    this.errorAndReset('balance details', args);
  }

  closeDrawer() {
    this.reset();
  }

  closeAllDrawers() {
    this.closeOtherDrawersActions.forEach(closeAction => {
      if (isFunction(closeAction)) {
        closeAction();
      }
    });
    this.reset();
  }

  toggleDotsMenu(event) {
    this.dotsMenuAnchorEl = event && event.currentTarget;
  }

  toggleCancelAllPayments() {
    this.isCancelAllPaymentsModalOpen = !this.isCancelAllPaymentsModalOpen;
  }

  toggleEditPayments() {
    this.isEditPaymentsModalOpen = !this.isEditPaymentsModalOpen;
  }

  // eslint-disable-next-line class-methods-use-this
  updateScheduledPayments(params) {
    ScheduledPaymentSource.put({
      params,
      success: PaymentPlanDetailsDrawerActions.updateScheduledPaymentsSuccess,
      error: PaymentPlanDetailsDrawerActions.updateScheduledPaymentsError,
    });
  }

  updateScheduledPaymentsSuccess() {
    this.isEditPaymentsModalOpen = false;
    this.isCancelAllPaymentsModalOpen = false;
    PaymentPlanSource.fetch({
      id: this.paymentPlanId,
      params: { fields: ['allocations'] },
      success: PaymentPlanDetailsDrawerActions.fetchSuccess,
      error: PaymentPlanDetailsDrawerActions.fetchError,
    });
  }

  updateScheduledPaymentsError(...args) {
    this.isEditPaymentsModalOpen = false;
    this.isCancelAllPaymentsModalOpen = false;
    this.notifyError('error while updating scheduled payments', args);
  }

  setLoading() {
    this.isLoading =
      this.isPaymentPlanLoading ||
      this.isClientLoading ||
      this.areScheduledPaymentsLoading ||
      this.isLoadingOrderItems ||
      this.isLoadingEvents ||
      this.isLoadingEventTypes ||
      this.isLoadingBalances ||
      (!this.isLoadingScheduledPaymentDetails.isEmpty() &&
        this.isLoadingScheduledPaymentDetails.every(val => val));
  }

  errorAndReset(entityName, ...args) {
    this.notifyError(`Error while getting ${entityName}`, args);
    this.reset();
  }
}

export default alt.createStore(
  PaymentPlanDetailsDrawerStore,
  'PaymentPlanDetailsDrawerStore'
);
