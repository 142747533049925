import { List } from 'immutable';
import BrowseEventListActions from 'client_portal/actions/BrowseEventListActions.jsx';
import BrowseEventsFilterActions from 'client_portal/actions/BrowseEventsFilterActions.jsx';
import FilteredEventListStoreImpl from 'event_mgmt/index/stores/_FilteredEventListStoreImplementation.jsx';
import BrowseEventsFilterStore from 'client_portal/stores/BrowseEventsFilterStore.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';

class BrowseEventListStore extends FilteredEventListStoreImpl {
  constructor() {
    super(BrowseEventListActions, {
      handleFilterUpdate: [
        BrowseEventsFilterActions.UPDATE_ATTENDEE_FILTERS,
        BrowseEventsFilterActions.UPDATE_LOCATION_FILTERS,
        BrowseEventsFilterActions.UPDATE_STATUS_FILTERS,
        BrowseEventsFilterActions.UPDATE_TITLE_FILTER,
        BrowseEventsFilterActions.UPDATE_TYPE_FILTERS,
        BrowseEventsFilterActions.MAX_AGE_UPDATED,
        BrowseEventsFilterActions.MIN_AGE_UPDATED,
        BrowseEventsFilterActions.AGE_TOGGLED,
        BrowseEventsFilterActions.FILTERS_CLEARED,
      ],
      loadNextPage: BrowseEventListActions.MORE_CLICKED,
    });

    this.isLastPage = true;
  }

  // Used by superclass
  // eslint-disable-next-line class-methods-use-this
  filterStore() {
    return BrowseEventsFilterStore;
  }

  loadNextPage() {
    this.handleListEvents({ page: this.page + 1 });
  }

  handleListEventsError() {
    super.handleListEventsError();
  }

  handleFilterUpdate() {
    this.handleListEvents({ page: 1, perPage: this.perPage });
  }

  handleListEvents({ fields, perPage, page }) {
    this.waitFor(BrowseEventsFilterStore);

    super.handleListEvents({ fields, page, perPage });
  }

  handleListEventsSuccess(data) {
    this.page = data.page;
    this.perPage = data.per_page;
    this.totalCount = data.total_count;
    this.isLoading = false;
    this.isLastPage = Math.ceil(data.total_count / data.per_page) <= data.page;

    const newEvents = List(
      data.events.map(event => new EventTranslator(event).toClient())
    );

    this.events = this.page === 1 ? newEvents : this.events.concat(newEvents);
  }
}

export default alt.createStore(BrowseEventListStore, 'BrowseEventListStore');
