class CompletedEventSchedulesActions {
  constructor() {
    this.generateActions(
      'locationFilterUpdated',
      'locationListResult',
      'mounted',
      'pageSelected',
      'resourceFilterUpdated',
      'resourceListResult',
      'scheduleListError',
      'scheduleListSuccess',
      'scheduleSelected',
      'staffFilterUpdated',
      'staffListResult',
      'staffListSuccess'
    );
  }
}

export default alt.createActions(CompletedEventSchedulesActions);
