import moment from 'moment-timezone';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import PayrollCommissions from 'shared/records/PayrollCommissions';
import AutoCompleteClientListStore from 'shared/stores/AutoCompleteClientListStore';
import AutoCompleteItemListStore from 'shared/stores/AutoCompleteItemListStore';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import AutoCompleteItemListActions from 'shared/actions/AutoCompleteItemListActions';
import AutoCompleteClientListActions from 'shared/actions/AutoCompleteClientListActions';
import AutoCompleteStaffListActions from 'shared/actions/AutoCompleteStaffListActions';
import AutoCompleteRetailListStore from 'shared/stores/AutoCompleteRetailListStore';
import AutoCompleteRetailListActions from 'shared/actions/AutoCompleteRetailListActions';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import {
  COMMISSION_TYPE,
  MEMBERSHIP_COMMISSION,
} from 'reporting/payroll/components/constants';
import { CommissionRateSource } from 'sources';

export default class CommissionsEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();
    this.reset();

    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      handleLoad: this.actions.LOAD,
      handleLoadSuccess: this.actions.LOAD_SUCCESS,
      handleBegin: this.actions.BEGIN,
      handleUpdateDate: this.actions.UPDATE_DATE,
      handleUpdate: this.actions.UPDATE,
      handleSetStaff: this.actions.SET_STAFF,
      handleSetClient: this.actions.SET_CLIENT,
      handleSetItem: this.actions.SET_ITEM,
      handleSetRetailCategory: this.actions.SET_RETAIL_CATEGORY,
      handleCloseModal: this.actions.CLOSE_MODAL,
      handleConfirm: this.actions.CONFIRM,
      handleSuccess: this.actions.SUCCESS,
      handleError: this.actions.ERROR,
    });
  }

  reset() {
    this.commissions = null;
    this.commissionsToRemove = null;
    this.staffMember = null;
    this.oldStaffMemberId = null;
    this.selectedClient = null;
    this.selectedItem = null;
    this.selectedRetailItem = null;
    this.payrollEntry = null;
  }

  validateCommissions() {
    this.commissions = this.commissions.validate(true);
  }

  handleLoad({ payrollEntry, staffMember }) {
    this.payrollEntry = payrollEntry;
    this.staffMember = staffMember;
    CommissionRateSource.fetchCommissions({
      url: `commission_payrolls/${payrollEntry.id}`,
      success: this.actions.loadSuccess,
      error: this.actions.loadError,
    });
  }

  // eslint-disable-next-line camelcase
  handleLoadSuccess(data) {
    AutoCompleteStaffListActions.searchStringUpdated.defer(
      this.staffMember.name()
    );
    if (this.payrollEntry.object_type === MEMBERSHIP_COMMISSION) {
      AutoCompleteItemListActions.searchStringUpdated.defer(
        this.payrollEntry.event_name
      );
    } else {
      AutoCompleteRetailListActions.searchStringUpdated.defer(
        this.payrollEntry.event_name
      );
    }
    AutoCompleteClientListActions.searchStringUpdated.defer(
      this.payrollEntry.attendee_names.getIn(['0'])
    );
    const commissionPayroll = new PayrollCommissions(data);
    this.handleBegin({
      commissions: commissionPayroll,
      staffMember: this.staffMember,
    });
  }

  handleLoadError(...args) {
    this.notifyError('error loading event payroll entry', args);
    this.reset();
  }

  handleBegin({ commissions, staffMember }) {
    this.commissions = commissions || new PayrollCommissions();
    if (staffMember) {
      this.commissions = this.commissions.set(
        'customer_user_id',
        staffMember.id
      );
      this.staffMember = staffMember;
      this.oldStaffMemberId = staffMember.id;

      if (!this.commissions.get('total') && staffMember.rate) {
        this.commissions = this.commissions.set('total', staffMember.rate);
      }
    }
  }

  handleUpdateDate(date) {
    this.commissions = this.commissions.set(
      'due_date',
      date ? moment.tz(date, customerTZ()) : null
    );
    if (this.commissions.errors.get('errors').size) {
      this.validateCommissions();
    }
  }

  handleUpdate([prop, value]) {
    if (prop === COMMISSION_TYPE) {
      this.selectedItem = null;
      this.selectedRetailItem = null;
      this.commissions = this.commissions
        .set('item_id', null)
        .set('item_name', null);
    }
    this.commissions = this.commissions.set(prop, value);
    if (this.commissions.errors.get('errors').size) {
      this.validateCommissions();
    }
  }

  handleSetStaff(id) {
    const { allStaffMap } = StaffStore.getState();

    this.staffMember = allStaffMap.get(id);
    this.commissions = this.commissions
      .set('customer_user_id', id)
      .set('total', this.staffMember.rate);

    if (this.commissions.errors.get('errors').size) {
      this.validateCommissions();
    }
  }

  handleSetClient(id) {
    this.waitFor(AutoCompleteClientListStore);
    const { clients } = AutoCompleteClientListStore.getState();
    this.selectedClient = clients.find(client => client.id === id);
    this.commissions = this.commissions.set('client_id', id);
    if (this.commissions.errors.get('errors').size) {
      this.validateCommissions();
    }
  }

  handleSetItem(id) {
    this.waitFor(AutoCompleteItemListStore);
    const { items } = AutoCompleteItemListStore.getState();
    this.selectedItem = items.find(i => i.id === id);
    this.commissions = this.commissions
      .set('item_id', this.selectedItem.id)
      .set('item_name', this.selectedItem.name);

    if (this.commissions.errors.get('errors').size) {
      this.validateCommissions();
    }
  }

  handleSetRetailCategory(id) {
    this.waitFor(AutoCompleteRetailListStore);
    const { retailCategories } = AutoCompleteRetailListStore.getState();
    this.selectedRetailItem = retailCategories.find(i => i.id === id);
    this.commissions = this.commissions
      .set('item_id', this.selectedRetailItem.id)
      .set('item_name', this.selectedRetailItem.name);

    if (this.commissions.errors.get('errors').size) {
      this.validateCommissions();
    }
  }

  handleConfirm() {
    this.validateCommissions();
    if (this.commissions.isValid()) {
      const url = this.commissions.id
        ? `commission_payrolls/${this.commissions.id}`
        : 'commission_payrolls';
      CommissionRateSource.createOrUpdateCommission({
        id: this.commissions.id,
        url,
        params: { attributes: this.commissions.toServer() },
        success: {
          action: this.actions.success,
          args: [
            this.oldStaffMemberId !==
              this.commissions.get('customer_user_id') && this.commissions.id
              ? this.oldStaffMemberId
              : null,
          ],
        },
        error: this.actions.error,
      });
    }
  }

  handleSuccess() {
    this.reset();
    AutoCompleteClientListActions.searchStringUpdated.defer('');
    AutoCompleteStaffListActions.searchStringUpdated.defer('');
    PayrollReportActions.payrollEntriesList.defer();
    return MessageWindowActions.addMessage.defer(this.successMessage);
  }

  handleError(...args) {
    this.notifyError('error managing time log', args);
    this.reset();
  }

  handleCloseModal() {
    this.reset();
    AutoCompleteClientListActions.searchStringUpdated.defer('');
    AutoCompleteStaffListActions.searchStringUpdated.defer('');
  }
}
