class OrderSummaryDrawerActions {
  constructor() {
    this.generateActions(
      'balanceListSuccess',
      'balanceListError',
      'clientListResponse',
      'creditPassSuccess',
      'creditPassError',
      'eventListResponse',
      'eventTypeListResponse',
      'openDrawer',
      'closeDrawer',
      'sendReceipt',
      'sendReceiptSuccess',
      'sendReceiptError',
      'toggleDotsMenu',
      'detailsSuccess',
      'detailsError',
      'paymentPlanSuccess',
      'paymentPlanError'
    );
  }
}

export default alt.createActions(OrderSummaryDrawerActions);
