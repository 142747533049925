import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import RateForm from './RateForm.jsx';

const styles = {
  removeButton: {
    width: 24,
    height: 24,
    padding: 0,
    marginLeft: 5,
  },
};

function SelectedRates({
  accountingData,
  rateEditingStore,
  onEdit,
  onRemove,
  intl,
  isFeeRate,
}) {
  const rate = isFeeRate
    ? rateEditingStore?.feeRate
    : rateEditingStore?.taxRate;
  const ratesList = isFeeRate
    ? accountingData?.fee_rates
    : accountingData?.tax_rates;
  return (
    <div>
      {ratesList.map(r =>
        rate && r.id === rate.id ? (
          <RateForm
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rateEditingStore}
            style={{ marginBottom: 20 }}
            intl={intl}
            key={r.id}
            isFeeRate={isFeeRate}
          />
        ) : (
          <Paper style={{ marginBottom: 7 }} key={r.id}>
            <FlexBoxCenter style={{ padding: 7 }}>
              <div style={{ flex: '1 0 auto' }}>
                <FormattedMessage
                  id={messageId('.name_and_rate', __filenamespace)}
                  values={{
                    name: r.name,
                    rate: intl.formatNumber(r.rate / 100, {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    }),
                  }}
                />
              </div>
              <div style={{ flex: '0 0 auto', textAlign: 'end' }}>
                <IconButton
                  style={styles.removeButton}
                  onClick={() => onEdit(r)}
                >
                  <EditIcon sx={{ color: uhColors.iconLightGrey }} />
                </IconButton>
                <IconButton
                  style={styles.removeButton}
                  onClick={() => onRemove(r.id)}
                >
                  <ClearIcon sx={{ color: uhColors.iconLightGrey }} />
                </IconButton>
              </div>
            </FlexBoxCenter>
          </Paper>
        )
      )}
    </div>
  );
}

export default SelectedRates;
