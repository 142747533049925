class VerifyActions {
  constructor() {
    this.generateActions(
      'fetchUserError',
      'fetchUserSuccess',
      'mounted',
      'roleListSuccess',
      'roleListError',
      'verifyAccountError',
      'verifyAccountSuccess'
    );
  }
}

export default alt.createActions(VerifyActions);
