/**
 * used to match payment methods through out the app
 * add new key when new methods are added, e.g. "Gift Card"
 * NOTE: these do not yet match 1:1 with API
 */
const PaymentMethod = {
  CARD: 'card',
  CARD_NEW: 'new_card',
  CARD_NOT_PRESENT: 'card_not_present',
  CARD_SAVED: 'saved_card',
  ACH: 'bank',
  ACH_NEW: 'bank_new',
  ACH_SAVED: 'bank_saved',
  CASH: 'cash',
  CHECK: 'check',
  PAY_LATER: 'pay_later',
  NONE: 'none',
  HANDPOINT_CLOUD: 'handpoint_cloud',
};

export const StaffPaymentMethods = new Set([
  PaymentMethod.CARD,
  PaymentMethod.PAY_LATER,
  PaymentMethod.CASH,
  PaymentMethod.CHECK,
  PaymentMethod.ACH,
]);

export default PaymentMethod;
