import * as React from 'react';
import clsx from 'clsx';
import { Card, Grid, Typography, Button } from '@upperhand/playmaker';
import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { ClientDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils';

function BalanceInfo({
  intl,
  clientId,
  outstandingBalance,
  clientDataStore: { clients },
}) {
  const client = clients.get(clientId);

  if (!client) return null;

  const balanceOwed = outstandingBalance > 0;

  return (
    <Card
      classes={{
        root: clsx('balance-info', balanceOwed && 'balance-info_owed'),
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" className="balance-info__header">
            {t('.header', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className="balance-info__value"
            variant="h3"
            color={balanceOwed ? 'error' : 'secondary'}
          >
            <FormattedCurrency value={outstandingBalance} fromCents />
          </Typography>
        </Grid>
        <div className="balance-info__open-drawer-btn">
          <Button
            size="1x"
            type="tertiary"
            rounded
            onClick={() =>
              BalanceListDrawerActions.openDrawer({
                clientId: client.managing_customer_user_id || client.id,
                balanceRemainingMax: -1, // 1 cent owed
              })
            }
          >
            {t('.view', intl, __filenamespace)}
          </Button>
        </div>
      </Grid>
    </Card>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(BalanceInfo);
