import React from 'react';
import { injectIntl } from 'react-intl';
import {
  STEP_LOADING,
  STEP_SELECT,
  STEP_REVIEW,
  STEP_PROCESSING,
  STEP_SUCCESS,
  STEP_ERROR,
} from 'contacts/index/stores/ContactImportNativeStore.js';
import Loading from 'contacts/index/components/ContactImportNative/_Loading.jsx';
import Message from 'contacts/index/components/ContactImportNative/_Message.jsx';
import StepSelect from 'contacts/index/components/ContactImportNative/_StepSelect.jsx';
import StepReview from 'contacts/index/components/ContactImportNative/_StepReview.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const Content = injectIntl(props => {
  const {
    filterString,
    filteredContacts,
    selectedContacts,
    hasMoreToShow,
    hideIneligible,
    intl,
    step,
    error,
  } = props;

  switch (step) {
    case STEP_LOADING:
      return (
        <Loading message={t('.loading_contacts', intl, __filenamespace)} />
      );
    case STEP_SELECT:
      return (
        <StepSelect
          filterString={filterString}
          filteredContacts={filteredContacts}
          selectedContacts={selectedContacts}
          hideIneligible={hideIneligible}
          hasMoreToShow={hasMoreToShow}
        />
      );
    case STEP_REVIEW:
      return <StepReview selectedContacts={selectedContacts} />;
    case STEP_PROCESSING:
      return (
        <Loading
          message={t('.inviting', intl, __filenamespace, {
            count: selectedContacts.size,
          })}
        />
      );
    case STEP_SUCCESS:
      return (
        <Message
          message={t('.success', intl, __filenamespace, {
            count: selectedContacts.size,
          })}
        />
      );
    case STEP_ERROR:
      return <Message message={error} />;
    default:
      return null;
  }
});

export default Content;
