class ClientsActions {
  constructor() {
    this.generateActions(
      'mounted',
      'clientListSuccess',
      'clientListError',
      'pageSelected',
      'searchUpdated',
      'removeClient',
      'removeClientSuccess',
      'removeClientError'
    );
  }
}

export default alt.createActions(ClientsActions);
