import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { CreditGrantActions as Actions } from 'sources';

class CreditGrantDataStore extends UpperHandStore {
  constructor() {
    super();

    this.creditGrants = Map();

    this.bindListeners({
      fetchSuccess: Actions.fetchSuccess,
      listSuccess: Actions.listSuccess,
      fetchError: Actions.fetchError,
      listError: Actions.listError,
      updateSuccess: Actions.updateSuccess,
    });
  }

  listSuccess({ credit_grants: creditGrants }) {
    const fetchedRecords = creditGrants.groupBy(r => r.id).map(r => r.first());

    this.creditGrants = this.creditGrants.merge(fetchedRecords);
  }

  listError(...args) {
    this.notifyError('error fetching credit grants', args);
  }

  fetchSuccess(creditGrant) {
    this.creditGrants = this.creditGrants.set(creditGrant.id, creditGrant);
  }

  fetchError(...args) {
    this.notifyError('error fetching credit grant', args);
  }

  updateSuccess(creditGrant) {
    this.fetchSuccess(creditGrant);
  }
}

export default alt.createStore(CreditGrantDataStore, 'CreditGrantDataStore');
