import * as React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  eventBar: {
    width: 3,
    border: 2,
    borderRadius: 2,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
  },
  iconStyle: {
    padding: '0',
    height: '20px',
    width: '20px',
    position: 'absolute',
    right: 0,
  },
};

function SelectedEventOrType({ title, barColor, id, onRemove, style = {} }) {
  return (
    <FlexBoxCenter style={{ position: 'relative', minHeight: 21, ...style }}>
      <span style={{ ...styles.eventBar, backgroundColor: barColor }} />
      <div style={{ paddingLeft: 10 }}>{title}</div>
      {onRemove && (
        <IconButton
          iconStyle={{ height: '20px', width: '20px' }}
          onClick={() => onRemove(id)}
          style={styles.iconStyle}
        >
          <ClearIcon sx={{ color: uhColors.lightGrey }} />
        </IconButton>
      )}
    </FlexBoxCenter>
  );
}

export default SelectedEventOrType;
