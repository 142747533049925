import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import CardReaderActions from 'sources/CardReaderActions.js';

class CardReaderDataStore extends UpperHandStore {
  constructor() {
    super();

    this.cardReaders = Map();

    this.bindListeners({
      fetchSuccess: [CardReaderActions.fetchSuccess],
      listSuccess: [
        CardReaderActions.listSuccess,
        CardReaderActions.syncSuccess,
      ],
      updateSuccess: [CardReaderActions.updateSuccess],
      error: [
        CardReaderActions.listError,
        CardReaderActions.fetchError,
        CardReaderActions.updateError,
        CardReaderActions.syncError,
      ],
    });
  }

  fetchSuccess(cardReader) {
    this.cardReaders = this.cardReaders.set(cardReader.id, cardReader);
  }

  listSuccess({ card_readers: cardReaders }) {
    const fetchedCardReaders = cardReaders
      .groupBy(cr => cr.id)
      .map(cr => cr.first());

    this.cardReaders = this.cardReaders.merge(fetchedCardReaders);
  }

  updateSuccess(cardReader) {
    this.cardReaders = this.cardReaders.set(cardReader.id, cardReader);
  }

  error(...args) {
    this.notifyError('error with card readers', args);
  }
}

export default alt.createStore(CardReaderDataStore, 'CardReaderDataStore');
