import { List, Map } from 'immutable';
import Order from 'shared/records/Order';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { subscribe, unsubscribe } from 'cable.js';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './OrderActions';

const parserTo = { type: Order, paginationKey: 'orders' };

const parser = new Parser(parserTo);

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const sendReceipt = ({ id, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/${id}/send_receipt`,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

export const downloadCsv = ({ params, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/csv`,
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

let subscribeSuccessFunctions = Map();

export const subscribeToChanges = ({ id, success }) => {
  if (success) {
    subscribeSuccessFunctions = subscribeSuccessFunctions.set(id, success);
  }

  return subscribe(
    {
      channel: 'OrderChannel',
      order_id: id,
      customer_id: currentCustomer().id,
    },
    onSuccess(
      data => parser.parseSingle(data),
      subscribeSuccessFunctions.toList().concat(DataStoreActions.fetchSuccess)
    )
  );
};

export const unsubscribeToChanges = ({ id }) => {
  subscribeSuccessFunctions = subscribeSuccessFunctions.delete(id);

  unsubscribe({
    channel: 'OrderChannel',
    order_id: id,
    customer_id: currentCustomer().id,
  });
};
