import { List, fromJS } from 'immutable';
import history from 'routes/History.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

export const customerScopedRoute = route => {
  const slash = route[0] === '/' ? '' : '/';

  const urlSlug = window.location.pathname
    .split('/customers/')?.[1]
    ?.split('/')?.[0];

  const customer = currentCustomer();

  if (customer.id) {
    // try to get the url from the url first, so if we are switching customers our context changes.
    const customerSlug = urlSlug || currentCustomer().slug;
    return customerSlug ? `/customers/${customerSlug}${slash}${route}` : '/';
  }
  return '/';
};

export const urlCustomerId = () => {
  const split = window.location.pathname.split('/customers/')[1];
  return split ? split.match(/\d+/)[0] : null;
};

const combineQueryString = queryStrings => {
  const combinedString = List(queryStrings)
    .filter(q => !!q)
    .map(q => q.replace(/^\?/, ''))
    .join('&');
  return combinedString.length ? `?${combinedString}` : '';
};

const sanitizePath = (path, additionalQueryString) => {
  const parser = document.createElement('a');
  parser.href = path;
  const queryString = combineQueryString([
    parser.search,
    additionalQueryString,
  ]);

  return `${parser.pathname}${queryString}${parser.hash || ''}`;
};

export const redirectTo = ({ path, query, refreshPage = false }) => {
  const queryString = fromJS(query || {})
    .map((value, key) => `${key}=${value}`)
    .join('&');

  history.push(sanitizePath(path, queryString));

  if (refreshPage) {
    history.go(0);
  }
};

export const shouldOpenPurchaseDrawer = () => /pdo/.test(window.location.hash);

export const removeHash = () => {
  const loc = window.location;
  if ('pushState' in window.history) {
    window.history.replaceState('', document.title, loc.pathname + loc.search);
  } else {
    // Prevent scrolling by storing the page's current scroll offset
    const scrollV = document.body.scrollTop;
    const scrollH = document.body.scrollLeft;

    loc.hash = '';

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
};

export const getCustomerRouteParts = () => {
  const slug =
    window.location.pathname.split('/customers/')?.[1]?.split('/')?.[0] || '';
  const url = window.location.pathname.split(`/customers/${slug}/`)?.[1] || '';
  const route = url.split('/')?.[0] || '';

  return { slug, url, route };
};
