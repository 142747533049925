import { Map, List } from 'immutable';
import initATD from 'shared/utils/ATDInitializationUtils.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSClientActions from 'point_of_sale/actions/POSClientActions.jsx';
import POSAutomationsActions from 'point_of_sale/actions/POSAutomationsActions.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class POSAutomationsStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      listAutomations: [
        POSCartActions.ADD_SUCCESS,
        POSCartActions.UPDATE_SUCCESS,
        POSProductListActions.CART_BUTTON_CLICKED,
        POSSchedulingActions.VIEW_IN_CART_CLICKED,
        POSActions.CLIENT_SELECTED,
        POSClientActions.CREATE_OR_UPDATE_CLIENT_SUCCESS,
        POSClientActions.EXISTING_ACCOUNT_CONTINUE,
        POSClientActions.FETCH_MANAGING_USER_SUCCESS,
        POSClientActions.CLIENT_UNARCHIVED_SUCCESS,
      ],
      listSuccess: POSAutomationsActions.LIST_SUCCESS,
      listError: POSAutomationsActions.LIST_ERROR,

      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
      ],
    });
  }

  reset() {
    this.descriptionMap = Map();
    this.descriptions = List();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.isLoading = false;
  }

  listAutomations() {
    return uhApiClient.get({
      url: 'automation_template_descriptions',
      data: this.queryParams(),
      success: POSAutomationsActions.listSuccess,
      error: POSAutomationsActions.listError,
    });
  }

  listSuccess({
    automation_template_descriptions,
    page,
    per_page,
    total_count,
  }) {
    this.page = page;
    this.perPage = per_page; // eslint-disable-line camelcase
    this.totalCount = total_count; // eslint-disable-line camelcase

    this.descriptionMap = this.descriptionMap.withMutations(m =>
      automation_template_descriptions.forEach(atd =>
        m.set(atd.id, initATD(atd))
      )
    );

    this.descriptions = this.descriptionMap.toList();

    if (this.totalCount > this.descriptions.size) {
      this.page += 1;
      this.listAutomations();
    } else {
      this.isLoading = false;
    }
  }

  listError() {
    this.isLoading = false;
    this.notifyError('error listing automations', arguments);
  }

  queryParams() {
    return {
      page: this.page,
      per_page: this.perPage,
    };
  }
}

export default alt.createStore(POSAutomationsStore, 'POSAutomationsStore');
