class EventSessionCreationActions {
  constructor() {
    this.generateActions(
      'toggleEditConfirm',
      'toggleDrawer',
      'setCurrentStep',
      'handleFieldChange',
      'setRepeating',
      'setWeekdays',
      'setDayTimes',
      'addDayTime',
      'removeDayTime',
      'setRepeatMode',
      'setRepeatDuration',
      'setDailyTime',
      'updateDayTime',
      'addBlackoutDate',
      'removeBlackoutDate',
      'updateBlackoutDate',
      'setScheduleDeadlineUnit',
      'setScheduleDeadline',
      'calculateDeadline',
      'setCancellationDeadlineUnit',
      'setCancellationDeadline',
      'listLocations',
      'listLocationsSuccess',
      'listLocationsError',
      'listStaffAndResources',
      'listStaffAndResourcesSuccess',
      'listStaffAndResourcesError',
      'listStaff',
      'listStaffSuccess',
      'listStaffError',
      'listResources',
      'listResourcesSuccess',
      'listResourcesError',
      'toggleStaffDrawer',
      'toggleResourceDrawer',
      'searchStaff',
      'searchResources',
      'addToList',
      'removeFromList',
      'saveList',
      'searchInList',
      'createSchedule',
      'createScheduleSuccess',
      'createScheduleError',
      'editSchedule',
      'updateSchedule',
      'updateScheduleSuccess',
      'updateScheduleError'
    );
  }
}

export default alt.createActions(EventSessionCreationActions);
