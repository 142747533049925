import { List } from 'immutable';

import EmailTemplate from 'shared/records/EmailTemplate';

import Parser from 'sources/Parser';
import { onSuccess, onError } from 'sources/handlers';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import DataStoreActions from './EmailTemplatesActions';

const parserTo = { type: EmailTemplate, paginationKey: 'email_templates' };

const parser = new Parser(parserTo);

export const create = ({ template, fields, success, error }) => {
  uhApiClient.post({
    url: `marketing/${parserTo.paginationKey}`,
    data: JSON.stringify({
      attributes: template,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.createSuccess, success])
    ),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const update = ({ template, fields, success, error }) => {
  uhApiClient.put({
    url: `marketing/${parserTo.paginationKey}/${template.id}`,
    data: JSON.stringify({
      attributes: template,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const remove = ({ templateId, success, error }) => {
  uhApiClient.delete({
    url: `marketing/${parserTo.paginationKey}/${templateId}`,
    success: onSuccess(
      () => parser.parseSingle({ id: templateId }),
      List([DataStoreActions.deleteSuccess, success])
    ),
    error: onError(List([DataStoreActions.deleteError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: `marketing/${parserTo.paginationKey}`,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const fetchTemplatePreview = ({ success, error }) => {
  uhApiClient.get({
    url: `email_templates/message_contacts`,
    success: onSuccess(data => parser.parseNone(data), success),
    error: onError(error),
  });
};
