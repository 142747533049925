import Message from 'shared/records/Message';

import { prettyFormat } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import { ClientSource, SessionSource } from 'sources';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import NewMessageDrawerActions from './Actions';

class NewMessageDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      toggleDrawer: NewMessageDrawerActions.toggleDrawer,

      updateAndValidateMessage:
        NewMessageDrawerActions.updateAndValidateMessage,

      sendMessage: NewMessageDrawerActions.sendMessage,
      sendMessageSuccess: NewMessageDrawerActions.sendMessageSuccess,
      sendMessageError: NewMessageDrawerActions.sendMessageError,
    });
  }

  reset() {
    this.message = new Message();
    this.valid = this.message.isValid();
    this.open = false;
    this.clientId = null;
    this.sessionId = null;
  }

  toggleDrawer({ clientId = null, sessionId = null }) {
    if (!this.open && clientId) {
      this.clientId = clientId;
      this.open = true;
      return;
    }

    if (!this.open && sessionId) {
      this.sessionId = sessionId;
      this.open = true;
      return;
    }

    this.reset();
  }

  updateAndValidateMessage({ key, value }) {
    this.message = this.message.set(key, value);
    this.valid = this.message.isValid();
  }

  successMessage() {
    const sendAt = this.message.sendAt();

    if (sendAt) {
      const formattedDate = prettyFormat(sendAt, {
        withTime: true,
        withTimezone: true,
        timeSeparator: ' \\at ',
      });

      return `Scheduled email for ${formattedDate}.`;
    }
    return 'Successfully send email';
  }

  sendMessage() {
    if (this.clientId) {
      ClientSource.sendMessage({
        id: this.clientId,
        params: this.message.toServer(),
        success: NewMessageDrawerActions.sendMessageSuccess,
        error: NewMessageDrawerActions.sendMessageError,
      });
    }

    if (this.sessionId) {
      SessionSource.sendMessage({
        id: this.sessionId,
        params: this.message.toServer(),
        success: NewMessageDrawerActions.sendMessageSuccess,
        error: NewMessageDrawerActions.sendMessageError,
      });
    }
  }

  sendMessageSuccess() {
    MessageWindowActions.addMessage.defer(this.successMessage());
    this.reset();
  }

  // eslint-disable-next-line class-methods-use-this
  sendMessageError() {
    MessageWindowActions.addMessage.defer('Sending email failed');
  }
}

export default alt.createStore(NewMessageDrawerStore, 'NewMessageDrawerStore');
