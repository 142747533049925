import moment from 'moment-timezone';
import BackendAPI from 'shared/services/BackendAPI';

export const downloadFile = ({
  data,
  fileName = moment().toISOString(),
  mimeType = 'application/csv',
}) => {
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadFileFromURL = async ({
  url,
  fileName = moment().toISOString(),
  mimeType = 'application/csv',
}) => {
  const data = await BackendAPI.get(url);
  downloadFile({
    data,
    fileName,
    mimeType,
  });
};

export const compose = (...funcs) =>
  funcs.reduceRight(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    arg => arg
  );

export const mailTo = ({ email, subject, cc, bcc, body }) => {
  const properties = [];

  if (subject) {
    properties.push(`subject=${subject}`);
  }
  if (cc) {
    properties.push(`cc=${cc}`);
  }
  if (bcc) {
    properties.push(`bcc=${bcc}`);
  }
  if (body) {
    properties.push(`body=${body}`);
  }

  const queryString = properties.length > 0 ? `?${properties.join('&')}` : '';
  const mailToString = `${email}${queryString}`
    .replace(/ /g, '%20')
    .replace(/(\r\n|\n|\r)/g, '%0D%0A')
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D')
    .replace(/:/g, '%3A');

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = `mailto:${mailToString}`;
  a.click();
  a.remove();
};

export const isUrl = value => {
  try {
    if (value.indexOf('http') !== 0) {
      return false;
    }

    const url = new URL(value);
    return !!url;
  } catch (e) {
    return false;
  }
};

export const isEmpty = value => {
  try {
    return (
      value === undefined ||
      value === '' ||
      value === null ||
      JSON.stringify(value) === JSON.stringify({}) ||
      value.length === 0
    );
  } catch (e) {
    return false;
  }
};

export const isArray = value => Array.isArray(value);

export const isFunction = value =>
  value && {}.toString.call(value) === '[object Function]';

export const getFileInfo = value => {
  let fileName = value.name || value;
  if (isUrl(value)) {
    const parts = fileName.split('/');
    fileName = parts.pop();
  }

  const getName = () => {
    const parts = fileName.split('.');
    parts.pop();

    return parts.join('.');
  };

  const getExtension = () => {
    const parts = fileName.split('.').pop();

    // if file is an URL, then we have to delete all URL params
    return parts.split('?')[0].toLowerCase();
  };

  const getFullName = () => `${getName()}.${getExtension()}`;

  return {
    getName,
    getExtension,
    getFullName,
  };
};
