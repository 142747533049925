import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Popover, Switch } from '@upperhand/playmaker';
import ReactTooltip from 'react-tooltip';

import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';

import { compose } from 'shared/utils/SharedUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';
import CalendarViewStore from 'calendar/stores/CalendarViewStore.jsx';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';

import uhThemes from '_uh_theme.jsx';

import './styles.scss';

const styles = {
  menuItem: disabled => ({
    fontSize: '14px',
    color: !disabled ? 'inherit' : uhThemes.palette.primary,
  }),
};

function CalendarViewMenu({
  anchorEl,
  availableStaffOnly,
  footer,
  intl,
  onClose,
  showAvailability,
  showSettings,
}) {
  const { currentCalendarView, calendarViews } = CalendarViewStore.getState();
  const changeView = view => {
    CalendarViewActions.changeView(view.id);
  };

  return (
    <Popover
      anchor={anchorEl}
      onClose={onClose}
      transform="right"
      classes={{
        root: 'customize-button-popover',
        content: 'customize-button-popover__content',
      }}
    >
      {showSettings && (
        <>
          <div className="customize-button-popover__title">
            {t('.title.calendar_settings', intl, __filenamespace)}
          </div>
          {/* view === CALENDAR_TYPE.DAY */}
          <div className="customize-button-popover__settings-container">
            <Switch
              classes={{
                switchBase: clsx(
                  availableStaffOnly && 'customize-button-popover__switch-base'
                ),
                track: clsx(
                  availableStaffOnly && 'customize-button-popover__switch-track'
                ),
              }}
              checked={availableStaffOnly}
              labelValue={t(
                '.labels.available_staff_only',
                intl,
                __filenamespace
              )}
              onChange={CalendarActions.toggleAvailableStaff}
            />
            <Switch
              classes={{
                switchBase: clsx(
                  showAvailability && 'customize-button-popover__switch-base'
                ),
                track: clsx(
                  showAvailability && 'customize-button-popover__switch-track'
                ),
              }}
              checked={showAvailability}
              labelValue={t('.labels.show_availability', intl, __filenamespace)}
              onChange={CalendarActions.toggleShowAvailability}
            />
          </div>
          <Divider light />
          <div className="customize-button-popover__title">
            {t('.title.calendar_views', intl, __filenamespace)}
          </div>
        </>
      )}
      <>
        {calendarViews.map(view => {
          const isResourceView = view.isAllResources();
          const isCurrent = view.id === currentCalendarView.id;

          return (
            <MenuItem
              data-tip={isResourceView}
              data-for={isResourceView ? 'resource-information' : null}
              data-place="right"
              key={view.id}
              onClick={() => {
                changeView(view);
                onClose();
              }}
              style={styles.menuItem(isCurrent)}
              disabled={
                (isResourceView && !currentCustomer().resources_enabled) ||
                isCurrent
              }
            >
              {!view.default_view
                ? view.title
                : `${view.title} ${t('.default_label', intl, __filenamespace)}`}
            </MenuItem>
          );
        })}
      </>
      {footer && (
        <>
          <Divider light />
          <div>{footer}</div>
        </>
      )}
      <ReactTooltip
        id="resource-information"
        disable={currentCustomer().resources_enabled}
        effect="solid"
        style={{ maxWidth: 240 }}
        className="uh-tooltip"
      >
        <FormattedMessage
          id={messageId(
            'feature_wall.resources.resource_information',
            __filenamespace
          )}
        />
      </ReactTooltip>
    </Popover>
  );
}

CalendarViewMenu.propTypes = {
  anchorEl: PropTypes.object,
  availableStaffOnly: PropTypes.bool,
  footer: PropTypes.node,
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  showAvailability: PropTypes.bool,
  showSettings: PropTypes.bool,
};

CalendarViewMenu.defaultProps = {
  anchorEl: null,
  availableStaffOnly: false,
  footer: null,
  onClose: () => {},
  showAvailability: false,
  showSettings: true,
};

export default compose(injectIntl)(CalendarViewMenu);
