import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils';

import { REDEMPTION_LIMIT_STRATEGIES } from 'records/Coupon';

function CouponUsageLimit({ coupon }) {
  if (!coupon.get('redemption_limit')) {
    return <FormattedMessage id={messageId('.unlimited', __filenamespace)} />;
  }

  const id =
    coupon.get('redemption_limit_strategy') ===
    REDEMPTION_LIMIT_STRATEGIES.per_client
      ? '.per_client'
      : '.global';

  return (
    <FormattedMessage
      id={messageId(id, __filenamespace)}
      values={{ limit: coupon.get('redemption_limit') }}
    />
  );
}

export default CouponUsageLimit;
