export default alt.generateActions(
  'begin',
  'updateDate',
  'update',
  'setStaff',
  'confirm',
  'success',
  'addSuccess',
  'editSuccess',
  'error',
  'closeModal'
);
