import React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import ContactImportActions from 'contacts/index/actions/ContactImportActions.js';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import styles from 'contacts/index/components/ContactImportNative/styles';
import { t } from 'shared/utils/LocaleUtils.js';

const Message = injectIntl(({ message, intl }) => (
  <FlexBoxCenter style={styles.message}>
    <div style={{ margin: '16px 0' }}>{message}</div>
    <Button
      variant="contained"
      onClick={ContactImportActions.drawerClosed}
      style={styles.closeButton}
    >
      {t('.close', intl, __filenamespace)}
    </Button>
  </FlexBoxCenter>
));

export default Message;
