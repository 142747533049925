export default alt.generateActions(
  'accountCreationSave',
  'addProfile',
  'addProfileClick',
  'cancelAddProfileClick',
  'cancelAddProfiles',
  'clientUnarchivedSuccess',
  'clientUnarchivedError',
  'createClientError',
  'createOrUpdateClientSuccess',
  'emailBlurred',
  'emailCheckError',
  'emailCheckSuccess',
  'existingAccountCancel',
  'existingAccountContinue',
  'fetchManagingUserSuccess',
  'fetchManagingUserError',
  'formUpdated',
  'managedProfileFormUpdated',
  'moreInformationCancelClicked',
  'moreInformationSaveClicked',
  'profileSelected',
  'removeProfile',
  'saveClient',
  'updateClientError',
  'validateUser',
  'unarchiveClient'
);
