import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TimeLogDeletionActions from 'reporting/payroll/actions/TimeLogDeletionActions';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class TimeLogDeletionStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      handleRemoveRequest: TimeLogDeletionActions.REMOVE,
      handleRemove: TimeLogDeletionActions.REMOVE_CONFIRMED,
      handleRemoveAborted: TimeLogDeletionActions.REMOVE_ABORTED,
      handleRemoveSuccess: TimeLogDeletionActions.SUCCESS,
      handleRemoveError: TimeLogDeletionActions.ERROR,
    });
  }

  reset() {
    this.timeLogToRemove = null;
  }

  handleRemoveRequest(timeLog) {
    this.timeLogToRemove = timeLog;
  }

  handleRemove() {
    if (!this.timeLogToRemove.id) {
      return;
    }
    uhApiClient.delete({
      url: `time_logs/${this.timeLogToRemove.id}`,
      success: {
        action: TimeLogDeletionActions.success,
        args: [this.timeLogToRemove.id, this.timeLogToRemove.customer_user_id],
      },
      error: TimeLogDeletionActions.error,
    });
  }

  handleRemoveAborted() {
    this.reset();
  }

  handleRemoveSuccess() {
    const message = new TranslatableMessage({
      id: '.deleted',
      filename: __filenamespace,
    });
    this.reset();
    return MessageWindowActions.addMessage.defer(message);
  }

  handleRemoveError() {
    this.reset();
    this.notifyError('error removing time log', arguments);
  }
}

export default alt.createStore(TimeLogDeletionStore, 'TimeLogDeletionStore');
