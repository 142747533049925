import * as React from 'react';
import { injectIntl } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import MenuDivider from 'side_nav/_MenuDivider.jsx';
import SignOutIcon from 'shared/components/icons/SignOut.jsx';
import { logout } from 'shared/utils/AuthUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  ListItem: {
    fontSize: 'inherit',
    color: 'inherit',
  },
};

function SignOutItem({ intl }) {
  return (
    <>
      <MenuDivider />
      <ListItem disablePadding style={styles.ListItem}>
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText>{t('.sign_out', intl, __filenamespace)}</ListItemText>
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default injectIntl(SignOutItem);
