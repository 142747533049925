import * as React from 'react';
import { injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';

import { t } from 'shared/utils/LocaleUtils.js';

function CvcField({
  fieldId,
  children,
  errorText,
  hideHint,
  intl,
  onChangeCvc,
  value,
}) {
  const inputProps = children
    ? {
        startAdornment: children,
        sx: {
          height: '32px',
        },
      }
    : {};
  const textFiledStyle = {
    marginBottom: '10px',
    input: {
      display: children ? 'none' : 'initial',
      fontSize: '16px',
      '&::placeholder': {
        color: 'var(--color-old-gray)',
        fontWeight: 'lighter',
        opacity: 0.7,
      },
    },
    [`#${fieldId}`]: {
      height: children ? '32px' : 'initial',
    },
  };
  const inputLabelProps = {
    shrink: true,
    sx: {
      fontSize: '16px',
    },
  };

  return (
    <TextField
      data-fs-exclude
      fullWidth
      name="cvc"
      variant="standard"
      value={value}
      error={!!errorText}
      helperText={errorText}
      label={t('.cvc', intl, __filenamespace)}
      placeholder={!hideHint ? t('.zero', intl, __filenamespace) : ''}
      sx={textFiledStyle}
      inputProps={{ maxLength: 19 }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      onChange={e => onChangeCvc(e, e.target.value)}
    />
  );
}

export default injectIntl(CvcField);
