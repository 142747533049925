import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const defaultStyle = {
  height: 178,
  width: 268,
};

function OrdersReport({ style }) {
  return (
    <SvgIcon viewBox="0 0 268 180" style={{ ...defaultStyle, ...style }}>
      <g opacity="0.65">
        <path
          d="M225.88 173.525H33.1931C32.5369 173.525 32 172.988 32 172.331C32 171.675 32.5369 171.138 33.1931 171.138H225.88C226.537 171.138 227.073 171.675 227.073 172.331C227.073 172.988 226.543 173.525 225.88 173.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M241.618 173.525H234.459C233.803 173.525 233.266 172.988 233.266 172.331C233.266 171.675 233.803 171.138 234.459 171.138H241.618C242.274 171.138 242.811 171.675 242.811 172.331C242.811 172.988 242.274 173.525 241.618 173.525Z"
          fill="#8E8EA0"
        />
        <path
          d="M63.3379 101.973H56.1911C55.536 101.973 55 101.436 55 100.779C55 100.122 55.536 99.584 56.1911 99.584H63.3379C63.993 99.584 64.529 100.122 64.529 100.779C64.529 101.436 63.993 101.973 63.3379 101.973Z"
          fill="#8E8EA0"
        />
        <path
          d="M59.7645 105.557C59.1094 105.557 58.5734 105.02 58.5734 104.363V97.1946C58.5734 96.5376 59.1094 96 59.7645 96C60.4196 96 60.9556 96.5376 60.9556 97.1946V104.363C60.9556 105.02 60.4196 105.557 59.7645 105.557Z"
          fill="#8E8EA0"
        />
        <path
          d="M207.852 14.3357H200.705C200.05 14.3357 199.514 13.7981 199.514 13.1411C199.514 12.484 200.05 11.9464 200.705 11.9464H207.852C208.507 11.9464 209.043 12.484 209.043 13.1411C209.043 13.7981 208.507 14.3357 207.852 14.3357Z"
          fill="#8E8EA0"
        />
        <path
          d="M204.279 17.9197C203.623 17.9197 203.087 17.3821 203.087 16.7251V9.55717C203.087 8.90011 203.623 8.36252 204.279 8.36252C204.934 8.36252 205.47 8.90011 205.47 9.55717V16.7251C205.47 17.3821 204.934 17.9197 204.279 17.9197Z"
          fill="#8E8EA0"
        />
        <path
          d="M254.765 49.1813H251.191C250.536 49.1813 250 48.6437 250 47.9866C250 47.3296 250.536 46.792 251.191 46.792H254.765C255.42 46.792 255.956 47.3296 255.956 47.9866C255.956 48.6437 255.42 49.1813 254.765 49.1813Z"
          fill="#8E8EA0"
        />
        <path
          d="M252.978 50.9733C252.323 50.9733 251.787 50.4357 251.787 49.7786V46.1947C251.787 45.5376 252.323 45 252.978 45C253.633 45 254.169 45.5376 254.169 46.1947V49.7786C254.169 50.4357 253.633 50.9733 252.978 50.9733Z"
          fill="#8E8EA0"
        />
        <path
          d="M73.7645 53.1813H70.1911C69.536 53.1813 69 52.6437 69 51.9866C69 51.3296 69.536 50.792 70.1911 50.792H73.7645C74.4196 50.792 74.9556 51.3296 74.9556 51.9866C74.9556 52.6437 74.4196 53.1813 73.7645 53.1813Z"
          fill="#8E8EA0"
        />
        <path
          d="M71.9778 54.9732C71.3227 54.9732 70.7867 54.4357 70.7867 53.7786V50.1946C70.7867 49.5376 71.3227 49 71.9778 49C72.6329 49 73.1689 49.5376 73.1689 50.1946V53.7786C73.1689 54.4357 72.6329 54.9732 71.9778 54.9732Z"
          fill="#8E8EA0"
        />
        <path
          d="M225.239 67.0438C224.935 67.0438 224.631 66.9243 224.399 66.6913L219.348 61.626C218.884 61.1601 218.884 60.4015 219.348 59.9356C219.813 59.4697 220.569 59.4697 221.034 59.9356L226.084 65.0009C226.549 65.4668 226.549 66.2254 226.084 66.6913C225.852 66.9303 225.542 67.0438 225.239 67.0438Z"
          fill="#8E8EA0"
        />
        <path
          d="M220.188 67.0438C219.884 67.0438 219.581 66.9243 219.348 66.6913C218.884 66.2254 218.884 65.4668 219.348 65.0009L224.399 59.9356C224.863 59.4697 225.62 59.4697 226.084 59.9356C226.549 60.4015 226.549 61.1601 226.084 61.626L221.034 66.6913C220.796 66.9303 220.492 67.0438 220.188 67.0438Z"
          fill="#8E8EA0"
        />
        <path
          d="M17.2386 64.4576C16.9348 64.4576 16.6311 64.3381 16.3988 64.1052L11.3484 59.0399C10.8839 58.5739 10.8839 57.8153 11.3484 57.3494C11.813 56.8835 12.5693 56.8835 13.0339 57.3494L18.0843 62.4147C18.5488 62.8806 18.5488 63.6393 18.0843 64.1052C17.852 64.3441 17.5423 64.4576 17.2386 64.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M12.1882 64.4576C11.8844 64.4576 11.5807 64.3381 11.3484 64.1052C10.8839 63.6393 10.8839 62.8806 11.3484 62.4147L16.3988 57.3494C16.8634 56.8835 17.6197 56.8835 18.0843 57.3494C18.5488 57.8153 18.5488 58.5739 18.0843 59.0399L13.0339 64.1052C12.7956 64.3441 12.4919 64.4576 12.1882 64.4576Z"
          fill="#8E8EA0"
        />
        <path
          d="M113.3 27.6605C112.997 27.6605 112.693 27.5411 112.461 27.3081L107.41 22.2428C106.946 21.7769 106.946 21.0183 107.41 20.5524C107.875 20.0865 108.631 20.0865 109.096 20.5524L114.146 25.6177C114.611 26.0836 114.611 26.8422 114.146 27.3081C113.914 27.547 113.604 27.6605 113.3 27.6605Z"
          fill="#8E8EA0"
        />
        <path
          d="M108.25 27.6605C107.946 27.6605 107.643 27.5411 107.41 27.3081C106.946 26.8422 106.946 26.0836 107.41 25.6177L112.461 20.5524C112.925 20.0865 113.682 20.0865 114.146 20.5524C114.611 21.0183 114.611 21.7769 114.146 22.2428L109.096 27.3081C108.857 27.547 108.554 27.6605 108.25 27.6605Z"
          fill="#8E8EA0"
        />
        <path
          d="M170.927 40.7613C170.623 40.7613 170.32 40.6419 170.087 40.4089L167.562 37.8763C167.098 37.4103 167.098 36.6517 167.562 36.1858C168.027 35.7199 168.783 35.7199 169.248 36.1858L171.773 38.7185C172.237 39.1844 172.237 39.943 171.773 40.4089C171.535 40.6478 171.231 40.7613 170.927 40.7613Z"
          fill="#8E8EA0"
        />
        <path
          d="M168.402 40.7613C168.098 40.7613 167.795 40.6419 167.562 40.4089C167.098 39.943 167.098 39.1844 167.562 38.7185L170.087 36.1858C170.552 35.7199 171.308 35.7199 171.773 36.1858C172.237 36.6517 172.237 37.4103 171.773 37.8763L169.248 40.4089C169.009 40.6478 168.706 40.7613 168.402 40.7613Z"
          fill="#8E8EA0"
        />
        <path
          d="M51.1689 19.3625C48.8701 19.3625 47 17.4869 47 15.1813C47 12.8756 48.8701 11 51.1689 11C53.4678 11 55.3379 12.8756 55.3379 15.1813C55.3379 17.4869 53.4678 19.3625 51.1689 19.3625ZM51.1689 13.3893C50.1863 13.3893 49.3823 14.1957 49.3823 15.1813C49.3823 16.1669 50.1863 16.9732 51.1689 16.9732C52.1516 16.9732 52.9556 16.1669 52.9556 15.1813C52.9556 14.1957 52.1516 13.3893 51.1689 13.3893Z"
          fill="#8E8EA0"
        />
        <path
          d="M199.169 106.363C196.87 106.363 195 104.487 195 102.181C195 99.8756 196.87 98 199.169 98C201.468 98 203.338 99.8756 203.338 102.181C203.338 104.487 201.468 106.363 199.169 106.363ZM199.169 100.389C198.186 100.389 197.382 101.196 197.382 102.181C197.382 103.167 198.186 103.973 199.169 103.973C200.152 103.973 200.956 103.167 200.956 102.181C200.956 101.196 200.152 100.389 199.169 100.389Z"
          fill="#8E8EA0"
        />
        <path
          d="M147.104 8.36254C144.805 8.36254 142.935 6.48694 142.935 4.18127C142.935 1.8756 144.805 0 147.104 0C149.403 0 151.273 1.8756 151.273 4.18127C151.273 6.48694 149.403 8.36254 147.104 8.36254ZM147.104 2.3893C146.122 2.3893 145.318 3.19569 145.318 4.18127C145.318 5.16686 146.122 5.97325 147.104 5.97325C148.087 5.97325 148.891 5.16686 148.891 4.18127C148.891 3.19569 148.087 2.3893 147.104 2.3893Z"
          fill="#8E8EA0"
        />
        <path
          d="M174.512 22.6984L174.506 20.3091H174.512V22.6984Z"
          fill="#8E8EA0"
        />
        <path
          d="M225.731 45.3967L225.725 43.0074H225.731V45.3967Z"
          fill="#8E8EA0"
        />
        <path
          d="M249.553 32.2555L249.547 29.8662H249.553V32.2555Z"
          fill="#8E8EA0"
        />
        <path
          d="M23.8347 59.7324L23.8288 57.3431H23.8347V59.7324Z"
          fill="#8E8EA0"
        />
        <path
          d="M63.1419 11.9465L63.136 9.55716H63.1419V11.9465Z"
          fill="#8E8EA0"
        />
        <path
          d="M118.529 43.6047L118.523 41.2154H118.529V43.6047Z"
          fill="#8E8EA0"
        />
        <rect
          x="96"
          y="55"
          width="75"
          height="123.858"
          rx="4.93595"
          fill="white"
          stroke="#8E8EA0"
          strokeWidth="2"
        />
        <rect
          x="105"
          y="64"
          width="57"
          height="106"
          rx="1.09917"
          fill="#454952"
          stroke="#454952"
          strokeWidth="2"
        />
        <g opacity="0.2">
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="106"
            y="65"
            width="55"
            height="104"
          >
            <rect
              x="107.497"
              y="66.0983"
              width="52.0064"
              height="101.8"
              rx="1.09917"
              fill="#454952"
              stroke="#454952"
              strokeWidth="2"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              width="8.15711"
              height="131.122"
              transform="matrix(0.903424 -0.428748 0.393227 0.919441 90.8834 60.9043)"
              fill="#7E899C"
            />
            <rect
              width="17.2442"
              height="131.122"
              transform="matrix(0.903424 -0.428748 0.393227 0.919441 108.171 59.5478)"
              fill="#7E899C"
            />
          </g>
        </g>
        <path
          d="M139.116 119.182C139.424 118.438 139.6 117.62 139.6 116.769C139.6 113.205 136.641 110.308 133 110.308C132.131 110.308 131.295 110.48 130.536 110.782L132.318 112.526C132.538 112.494 132.769 112.462 133 112.462C135.431 112.462 137.4 114.389 137.4 116.769C137.4 116.995 137.378 117.222 137.345 117.448L139.116 119.182ZM133 108.154C137.862 108.154 141.8 112.009 141.8 116.769C141.8 118.223 141.415 119.591 140.755 120.797L142.372 122.38C143.406 120.743 144 118.826 144 116.769C144 110.825 139.072 106 133 106C130.899 106 128.941 106.592 127.269 107.583L128.875 109.155C130.107 108.52 131.515 108.154 133 108.154ZM123.397 106.538L122 107.906L124.31 110.168C122.869 111.998 122 114.282 122 116.769C122 120.754 124.211 124.222 127.489 126.085L128.589 124.222C125.971 122.725 124.2 119.957 124.2 116.769C124.2 114.874 124.827 113.129 125.883 111.718L127.456 113.269C126.796 114.271 126.4 115.477 126.4 116.769C126.4 119.16 127.731 121.238 129.7 122.358L130.8 120.485C129.491 119.731 128.6 118.363 128.6 116.769C128.6 116.069 128.787 115.423 129.084 114.842L130.822 116.543L130.8 116.769C130.8 117.954 131.79 118.923 133 118.923L133.231 118.902L133.242 118.912L141.503 127L142.9 125.632L124.497 107.615L123.397 106.538Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

export default OrdersReport;
