import * as React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';

import ColumnsMenu from 'reporting/inventory/components/ColumnsMenu.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import InventoryReportActions from 'reporting/inventory/actions/InventoryReportActions.js';
import FilteredSelection from 'shared/records/FilteredSelection.js';
import Paginator from 'shared/components/Paginator.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import InventoryReportAdjustmentModalActions from '../actions/InventoryReportAdjustmentModalActions.js';

const styles = {
  header: {
    fontSize: 15,
    textAlign: 'center',
  },
  row: {
    backgroundColor: 'var(--color-white)',
  },
  rowOdd: {
    backgroundColor: '#F9F9F9',
  },
  rowText: {
    margin: 0,
    paddingTop: 3,
    fontSize: 13,
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'var(--color-old-gray)',
  },
  columnsMenu: {
    buttonStyle: {
      margin: 0,
    },
    labelStyle: {
      fontSize: 15,
    },
  },
  headerProduct: {
    padding: 0,
    textAlign: 'left',
  },
  cell: {
    textAlign: 'center',
  },
  cellProduct: {
    fontWeight: 600,
    textAlign: 'left',
  },
  cellPrice: {
    textAlign: 'right',
  },
  cellStatus: {
    outOfStock: {
      color: 'var(--color-unpaid-red)',
    },
    lowStock: {
      color: '#9013FE',
    },
  },
  cellReturned: {
    color: 'var(--color-unpaid-red)',
  },
  cellInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  cellInfoSvg: {
    alignSelf: 'flex-end',
    marginRight: '10px',
    marginLeft: '5px',
  },
};

class ContentTable extends React.PureComponent {
  state = {
    anchorElement: null,
    selectedProductItem: null,
  };

  handleOpenContextMenu = (anchorElement, selectedProductItem) =>
    this.setState({ anchorElement, selectedProductItem });

  getColumnTitle = key => {
    const { columns, intl } = this.props;
    const column = columns.getItem(key);
    if (column) {
      return column.value.toString({ intl });
    }
    return '';
  };

  // eslint-disable-next-line class-methods-use-this
  getCellStatus = item => {
    if (item.get('live_qty') <= 0) {
      return (
        <p style={{ ...styles.rowText, ...styles.cellStatus.outOfStock }}>
          <FormattedMessage
            id={messageId('.status.out_of_stock', __filenamespace)}
          />
        </p>
      );
    }
    if (item.get('live_qty') <= item.get('trigger_qty')) {
      return (
        <p style={{ ...styles.rowText, ...styles.cellStatus.lowStock }}>
          <FormattedMessage
            id={messageId('.status.low_stock', __filenamespace)}
          />
        </p>
      );
    }
    return (
      <p style={styles.rowText}>
        <FormattedMessage id={messageId('.status.in_stock', __filenamespace)} />
      </p>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  getCellReturned = item => {
    const value = item.get('returned');

    return (
      <p style={{ ...styles.rowText, ...(value ? styles.cellReturned : {}) }}>
        {value || '-'}
      </p>
    );
  };

  render() {
    const { inventory, columns, intl, perPage, page, totalCount } = this.props;
    const { anchorElement, selectedProductItem } = this.state;
    const Reasons = { 0: 'damaged', 1: 'stolen', 2: 'input error' };

    return (
      <>
        <table className="responsive-table ReportTable TimeLogTable">
          <thead>
            <tr>
              <th style={{ ...styles.header, ...styles.headerProduct }}>
                <ColumnsMenu
                  intl={intl}
                  filteredSelection={columns}
                  onChange={value =>
                    InventoryReportActions.updateFilter('columns', value)
                  }
                  buttonStyle={styles.columnsMenu.buttonStyle}
                  labelStyle={styles.columnsMenu.labelStyle}
                />
              </th>
              {columns.isSelectedItem('vendor_name') && (
                <th style={styles.header}>
                  {this.getColumnTitle('vendor_name')}
                </th>
              )}
              {columns.isSelectedItem('sku') && (
                <th style={styles.header}>{this.getColumnTitle('sku')}</th>
              )}
              {columns.isSelectedItem('barcode') && (
                <th style={styles.header}>{this.getColumnTitle('barcode')}</th>
              )}
              {columns.isSelectedItem('live_qty') && (
                <th style={styles.header} data-tip data-for="tooltip-live-qty">
                  <Box sx={styles.cellInfo}>
                    <p>{this.getColumnTitle('live_qty')}</p>
                    <InfoIcon sx={styles.cellInfoSvg} />
                  </Box>
                  <ReactTooltip
                    id="tooltip-live-qty"
                    effect="solid"
                    className="uh-tooltip"
                  >
                    <FormattedMessage
                      id={messageId('.tooltip_live_qty', __filenamespace)}
                    />
                  </ReactTooltip>
                </th>
              )}
              {columns.isSelectedItem('status') && (
                <th style={styles.header}>{this.getColumnTitle('status')}</th>
              )}
              {columns.isSelectedItem('received') && (
                <th style={styles.header}>{this.getColumnTitle('received')}</th>
              )}
              {columns.isSelectedItem('sold') && (
                <th style={styles.header}>{this.getColumnTitle('sold')}</th>
              )}
              {columns.isSelectedItem('returned') && (
                <th style={styles.header}>{this.getColumnTitle('returned')}</th>
              )}
              {columns.isSelectedItem('avg_cost') && (
                <th style={{ ...styles.header, ...styles.cellPrice }}>
                  {this.getColumnTitle('avg_cost')}
                </th>
              )}
              {columns.isSelectedItem('avg_price') && (
                <th style={{ ...styles.header, ...styles.cellPrice }}>
                  {this.getColumnTitle('avg_price')}
                </th>
              )}
              {columns.isSelectedItem('adjustment') && (
                <th style={styles.header}>
                  {this.getColumnTitle('adjustment')}
                </th>
              )}
              {columns.isSelectedItem('adjustment_reasons') && (
                <th style={styles.header}>
                  {this.getColumnTitle('adjustment_reasons')}
                </th>
              )}
              {columns.isSelectedItem('cost') && (
                <th style={styles.header}>{this.getColumnTitle('cost')}</th>
              )}
              {columns.isSelectedItem('price') && (
                <th style={styles.header}>{this.getColumnTitle('price')}</th>
              )}
              {columns.isSelectedItem('total_profit') && (
                <th
                  style={{ ...styles.header, ...styles.cellPrice }}
                  data-tip
                  data-for="tooltip-total"
                >
                  <Box sx={styles.cellInfo}>
                    <p>{this.getColumnTitle('total_profit')}</p>
                    <InfoIcon sx={styles.cellInfoSvg} />
                  </Box>
                  <ReactTooltip
                    id="tooltip-total"
                    effect="solid"
                    className="uh-tooltip"
                  >
                    <FormattedMessage
                      id={messageId('.tooltip_total', __filenamespace)}
                    />
                  </ReactTooltip>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {inventory.map((item, index) => (
              <tr
                key={item.id}
                style={index % 2 === 0 ? styles.rowOdd : styles.row}
                className={index % 2 === 0 ? 'odd' : ''}
              >
                <td
                  style={{ ...styles.cell, ...styles.cellProduct }}
                  data-title={this.getColumnTitle('product')}
                >
                  <p style={styles.rowText}>{item.get('product')}</p>
                </td>
                {columns.isSelectedItem('vendor_name') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('vendor_name')}
                  >
                    <p style={styles.rowText}>{item.get('vendor_name')}</p>
                  </td>
                )}
                {columns.isSelectedItem('sku') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('sku')}
                  >
                    <p style={styles.rowText}>{item.get('sku')}</p>
                  </td>
                )}
                {columns.isSelectedItem('barcode') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('barcode')}
                  >
                    <p style={styles.rowText}>{item.get('gtin')}</p>
                  </td>
                )}
                {columns.isSelectedItem('live_qty') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('product')}
                  >
                    <p style={styles.rowText}>{item.get('live_qty')}</p>
                  </td>
                )}
                {columns.isSelectedItem('status') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('status')}
                  >
                    {this.getCellStatus(item)}
                  </td>
                )}
                {columns.isSelectedItem('received') && (
                  <td data-title={this.getColumnTitle('received')}>
                    <p style={styles.rowText}>{item.get('received') || '-'}</p>
                  </td>
                )}
                {columns.isSelectedItem('sold') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('sold')}
                  >
                    <p style={styles.rowText}>{item.get('sold') || '-'}</p>
                  </td>
                )}
                {columns.isSelectedItem('returned') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('returned')}
                  >
                    {this.getCellReturned(item)}
                  </td>
                )}
                {columns.isSelectedItem('avg_cost') && (
                  <td
                    style={styles.cellPrice}
                    data-title={this.getColumnTitle('avg_cost')}
                  >
                    <p style={styles.rowText}>
                      <FormattedCurrency
                        value={item.get('avg_cost')}
                        fromCents
                      />
                    </p>
                  </td>
                )}
                {columns.isSelectedItem('avg_price') && (
                  <td
                    style={styles.cellPrice}
                    data-title={this.getColumnTitle('avg_price')}
                  >
                    <p style={styles.rowText}>
                      <FormattedCurrency
                        value={item.get('avg_price')}
                        fromCents
                      />
                    </p>
                  </td>
                )}
                {columns.isSelectedItem('adjustment') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('adjustment')}
                  >
                    <p style={styles.rowText}>
                      {item.get('adjustment') || '-'}
                    </p>
                  </td>
                )}
                {columns.isSelectedItem('adjustment_reasons') && (
                  <td
                    style={styles.cell}
                    data-title={this.getColumnTitle('adjustment_reasons')}
                  >
                    <p style={styles.rowText}>
                      {Reasons[item.get('adjustment_reason')]}
                    </p>
                  </td>
                )}
                {columns.isSelectedItem('cost') && (
                  <td
                    style={styles.cellPrice}
                    data-title={this.getColumnTitle('cost')}
                  >
                    <p style={styles.rowText}>
                      <FormattedCurrency value={item.get('cost')} fromCents />
                    </p>
                  </td>
                )}
                {columns.isSelectedItem('price') && (
                  <td
                    style={styles.cellPrice}
                    data-title={this.getColumnTitle('price')}
                  >
                    <p style={styles.rowText}>
                      <FormattedCurrency value={item.get('price')} fromCents />
                    </p>
                  </td>
                )}
                {columns.isSelectedItem('total_profit') && (
                  <td
                    style={styles.cellPrice}
                    data-title={this.getColumnTitle('total_profit')}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                      }}
                    >
                      <p style={styles.rowText}>
                        <FormattedCurrency
                          value={item.get('total_profit')}
                          fromCents
                        />
                      </p>
                      <IconButton
                        onClick={e =>
                          this.handleOpenContextMenu(e.currentTarget, item)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <Menu
          anchorEl={anchorElement}
          open={!!anchorElement}
          onClose={() => this.handleOpenContextMenu(null, null)}
        >
          <MenuItem
            onClick={() => {
              InventoryReportAdjustmentModalActions.openModal({
                product: selectedProductItem,
              });
              this.handleOpenContextMenu(null, null);
            }}
          >
            {t('.adjustInventory', intl, __filenamespace)}
          </MenuItem>
        </Menu>
        <Paginator
          perPage={perPage}
          currentPage={page}
          totalCount={totalCount}
          onPageSelect={InventoryReportActions.pageSelected}
        />
      </>
    );
  }
}

ContentTable.propTypes = {
  intl: PropTypes.object.isRequired,
  inventory: PropTypes.instanceOf(List).isRequired,
  columns: PropTypes.instanceOf(FilteredSelection).isRequired,
};

export default ContentTable;
