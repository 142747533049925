import { List, Map } from 'immutable';

const convertToNumber = value =>
  // useing Number.isNaN breaks this... dont change till we figure out why
  // eslint-disable-next-line no-restricted-globals
  isNaN(value) || value === '' ? value : parseFloat(value, 10);
// Get all query elements off url
export function getAllUrlParameters() {
  let name;
  let urlParams = Map();

  const pageUrl = window.location.search.substring(1);

  if (pageUrl === '') {
    return urlParams;
  }

  const urlvars = pageUrl.split('&');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < urlvars.length; i++) {
    const equalSignIndex = urlvars[i].indexOf('=');
    // eslint-disable-next-line no-continue
    if (equalSignIndex === -1) continue; // Skip if there's no '=' character

    name = decodeURIComponent(urlvars[i].substring(0, equalSignIndex));
    const encodedValue = urlvars[i].substring(equalSignIndex + 1);

    // Decode the value portion again to handle encoded characters within the value
    const decodedValue = decodeURIComponent(encodedValue);

    if (name.endsWith('[]')) {
      name = name.substring(0, name.length - 2);
      urlParams = urlParams.update(name, (ls = List()) =>
        ls.push(convertToNumber(decodeURIComponent(decodedValue)))
      );
    } else {
      urlParams = urlParams.set(name, decodedValue);
    }
  }

  return urlParams;
}

// Get a specific query element from the URL
export function getUrlParameter(param) {
  return getAllUrlParameters().get(param);
}

export function jsonToQueryString(json) {
  const queryParams = Object.keys(json).flatMap(key => {
    const value = json[key];

    if (value instanceof Array) {
      const queryKey = `${key}[]`;
      return value.map(
        v => `${encodeURIComponent(queryKey)}=${encodeURIComponent(v)}`
      );
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });

  return queryParams.length === 0 ? '' : `?${queryParams.join('&')}`;
}

export function resetUrl() {
  window.history.replaceState('', '', window.location.pathname);
}

export function setUrlParameter(name, value, url = window.location.pathname) {
  let urlParams = getAllUrlParameters();

  let encodedValue;

  if (value.length === 0) {
    urlParams = urlParams.remove(name);
  } else {
    // If value is an array, encode each element
    if (Array.isArray(value)) {
      encodedValue = value.map(v => encodeURIComponent(v));
    } else {
      encodedValue = encodeURIComponent(value);
    }
    urlParams = urlParams.set(name, encodedValue);
  }

  const flattenParams = jsonToQueryString(urlParams.toJSON());

  window.history.replaceState('', '', `${url}${flattenParams}`);
}
