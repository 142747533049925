class MembershipEmbedActions {
  constructor() {
    this.generateActions(
      'closeModal',
      'openModal',
      'changeEmbedCard',
      'recycleEmbedCard',
      'recycleEmbedCardSuccess',
      'recycleEmbedCardError',
      'redeemSuccess',
      'redeemError',
      'topUp',
      'topUpSuccess',
      'topUpError',
      'validate'
    );
  }
}

export default alt.createActions(MembershipEmbedActions);
