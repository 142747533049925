import ValidateMicroDepositActions from 'shared/actions/ValidateMicroDepositActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

class ValidateMicroDepositStore extends UpperHandStore {
  constructor() {
    super();
    this.microdeposit = '';
    this.confirmation = '';
    this.submitting = false;
    this.valid = false;
    this.hasConfirmationError = false;

    this.bindListeners({
      handleUpdate: ValidateMicroDepositActions.update,
      handleSubmit: ValidateMicroDepositActions.submit,
      handleSubmitSuccess: ValidateMicroDepositActions.submitSuccess,
      handleSubmitError: ValidateMicroDepositActions.submitError,
    });
  }

  handleUpdate([event, value]) {
    if (event.target.name === 'micro_deposit') {
      this.microdeposit = value;
    } else {
      this.confirmation = value;
    }
    this.hasConfirmationError = this.microdeposit !== this.confirmation;
    this.valid = this.microdeposit !== '' && !this.hasConfirmationError;
  }

  handleSubmit() {
    this.submitting = true;
    return uhApiClient.put({
      url: `/customers/${currentCustomer().id}/bank_accounts/0/validate`,
      data: JSON.stringify({ attributes: { amount: this.microdeposit } }),
      success: ValidateMicroDepositActions.submitSuccess,
      error: ValidateMicroDepositActions.submitError,
    });
  }

  handleSubmitSuccess() {
    window.location.reload();
  }

  handleSubmitError() {
    this.submitting = false;
    this.notifyError('error sending microdeposit amount', arguments);
  }
}

export default alt.createStore(
  ValidateMicroDepositStore,
  'ValidateMicroDepositStore'
);
