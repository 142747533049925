import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function SignedWaiverIcon() {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <g clipPath="url(#clip0_171_34)">
        <path
          d="M22.6327 4.95914C20.8504 3.43489 17.988 3.7667 16.2483 5.69534L8.23587 14.592C8.08158 14.763 8.00177 14.986 8.01241 15.2141L8.0337 15.6651L8.07626 16.5413L8.11882 17.4175L8.25183 20.1653C8.26779 20.409 8.37952 20.6371 8.57105 20.7926C8.75194 20.9378 8.98603 21.0104 9.22013 20.9948L9.25737 20.9896L14.1202 20.409C14.3383 20.3779 14.5405 20.2794 14.6841 20.1186L22.7018 11.222C23.5318 10.3044 23.984 9.15859 23.9894 7.99726C24 6.78927 23.5159 5.71089 22.6327 4.95914ZM22.2017 7.99207C22.2017 8.72827 21.9038 9.46447 21.3664 10.0607L13.5668 18.7084L10.0075 19.1336L9.91177 17.2775L9.86921 16.4013L9.82665 15.5252L9.82133 15.4785L17.605 6.8463C18.685 5.64349 20.4141 5.38945 21.4462 6.276C21.9304 6.69076 22.1964 7.30253 22.2017 7.99207Z"
          fill="#929BB2"
        />
        <path
          d="M16.6403 6.81453L15.4536 8.13126L20.9584 12.8423L22.1451 11.5256L16.6403 6.81453Z"
          fill="#929BB2"
        />
        <path
          d="M9.59255 14.9134L9.56063 15.7896L9.53403 16.6658H0.899135C0.404345 16.6709 0 16.2769 0 15.7948C0 15.3074 0.404345 14.9186 0.899135 14.9186H9.59255V14.9134Z"
          fill="#929BB2"
        />
        <path
          d="M4.39644 12.3107L1.14644 9.06066C0.951187 8.86541 0.951187 8.54882 1.14644 8.35355L1.85353 7.64644C2.04878 7.45117 2.36539 7.45117 2.56064 7.64644L4.75 9.83578L9.43935 5.14644C9.63461 4.95119 9.95121 4.95119 10.1465 5.14644L10.8536 5.85355C11.0488 6.0488 11.0488 6.36539 10.8536 6.56066L5.10355 12.3107C4.90828 12.5059 4.5917 12.5059 4.39644 12.3107Z"
          fill="#4AD669"
        />
      </g>
      <defs>
        <clipPath id="clip0_171_34">
          <rect
            width="24"
            height="17"
            fill="white"
            transform="translate(0 4)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default SignedWaiverIcon;
