import * as React from 'react';
import { injectIntl } from 'react-intl';
import {
  Step,
  Action,
} from 'onboarding/components/AccountSetupPaysafe/steps/_Helpers.jsx';
import PaysafeTOSForm from 'onboarding/components/AccountSetupPaysafe/forms/_PaysafeTOSForm.jsx';
import TOSStepActions from 'onboarding/actions/TOSStepActions.js';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

// This needs to be a class for React.createElement to work properly
class TOSStep extends React.Component {
  componentDidMount() {
    TOSStepActions.loadTOS.defer();
  }

  render() {
    const { intl, tos, tosAccepted, isLoading } = this.props;

    return (
      <SpinWhileLoading isProcessing={isLoading} spinWhile="isProcessing">
        <Step>
          <PaysafeTOSForm
            intl={intl}
            terms={tos.get('terms')}
            accepted={tosAccepted}
            onAcceptedChanged={accepted => TOSStepActions.acceptTOS(accepted)}
          />
          <Action
            label={t(
              'onboarding.AccountSetupPaysafe._Stepper.finish',
              intl,
              __filenamespace
            )}
            action={TOSStepActions.submitTOSForm}
            disabled={!tosAccepted}
          />
        </Step>
      </SpinWhileLoading>
    );
  }
}

export default injectIntl(TOSStep);
