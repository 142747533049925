import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import UserCreationActions from 'user_management/shared/actions/UserCreationActions.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  radioButton: {
    margin: '15px 0',
  },
  radioLabel: {
    fontSize: '16px',
    paddingLeft: '15px',
  },
};

const handleOnChange = (_, value) => {
  UserCreationActions.accountType(value);
};

function AccountType({ userCreationStore }) {
  return (
    <RadioGroup
      defaultValue="female"
      name="account_type"
      value={userCreationStore.accountType}
      onChange={handleOnChange}
    >
      <FormControlLabel
        style={styles.radioButton}
        value="manager"
        control={<Radio />}
        label={
          <div style={styles.radioLabel}>
            <FormattedMessage
              id={messageId('.parent_label', __filenamespace)}
            />
            <div style={{ fontStyle: 'italic' }}>
              <FormattedMessage
                id={messageId('.parent_span', __filenamespace)}
              />
            </div>
          </div>
        }
      />
      <FormControlLabel
        style={styles.radioButton}
        value="participant"
        control={<Radio />}
        label={
          <div style={styles.radioLabel}>
            <FormattedMessage
              id={messageId('.participant_label', __filenamespace)}
            />
            <div style={{ fontStyle: 'italic' }}>
              <FormattedMessage
                id={messageId('.participant_span', __filenamespace)}
              />
            </div>
          </div>
        }
      />
    </RadioGroup>
  );
}

AccountType.propTypes = {
  userCreationStore: PropTypes.object.isRequired,
};

export default AccountType;
