import * as React from 'react';
import clsx from 'clsx';
import { Tabs, Typography } from '@upperhand/playmaker';

import CheckIn from 'containers/events/admin/sessionSummaryDrawer/components/checkIn/CheckIn.jsx';
import Details from 'containers/events/admin/sessionSummaryDrawer/components/details/Details.jsx';
import Notes from 'containers/events/admin/sessionSummaryDrawer/components/notes/Notes.jsx';
import WaitList from 'containers/events/admin/sessionSummaryDrawer/components/waitlist/Waitlist.jsx';
import RSVP from 'containers/events/admin/sessionSummaryDrawer/components/rsvp/index.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import SessionSummaryDrawerActions from '../Actions';

const SHOW_WAITLIST = false;

// Kind of a useless variable, but lets you change all tab fonts at once.
const TAB_TYPOGRAPHY_VARIANT = 'subtitle2';

function SessionTabs({
  total,
  intl,
  isLoading,
  selectedTab,
  sessionId,
  eventId,
  isTeamEvent,
  rsvpEnabled,
}) {
  const isStaff = currentUser().isStaff();
  const isCoach = currentUser().isCoach();
  const isClient = currentUser().isClient();

  const handleChangeTab = (_, value) =>
    SessionSummaryDrawerActions.selectTab.defer(value);

  return (
    <Tabs
      value={selectedTab}
      variant="standard"
      classes={{
        root: 'ssd-tabs',

        tabRoot: clsx(
          'ssd-tabs__root',
          currentUser().isClient() && 'ssd-tabs__root--client'
        ),
      }}
      onChange={handleChangeTab}
      tabItems={[
        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.details', intl, __filenamespace)}
            </Typography>
          ),
          content: (
            <Details
              intl={intl}
              sessionId={sessionId}
              eventId={eventId}
              loadingSession={isLoading}
              isTeamEvent={isTeamEvent}
            />
          ),
          value: 'details',
          key: 'details',
          visible: isStaff || isCoach,
        },

        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.checkIn', intl, __filenamespace, { total })}
            </Typography>
          ),
          content: <CheckIn intl={intl} sessionId={sessionId} />,
          value: 'check-in',
          key: 'check-in',
          visible: isStaff || isCoach,
        },
        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.notes', intl, __filenamespace)}
            </Typography>
          ),
          content: <Notes intl={intl} sessionId={sessionId} />,
          value: 'notes',
          key: 'notes',
          visible: isStaff || isCoach || isClient,
        },
        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.rsvp', intl, __filenamespace)}
            </Typography>
          ),
          content: <RSVP intl={intl} sessionId={sessionId} />,
          value: 'rsvp',
          key: 'rsvp',
          visible: rsvpEnabled,
        },
        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.waitList', intl, __filenamespace, {
                count: 0,
              })}
            </Typography>
          ),
          content: <WaitList intl={intl} sessionId={sessionId} />,
          value: 'wait-list',
          key: 'wait-list',
          visible: SHOW_WAITLIST,
        },
      ].filter(x => !!x && x.visible)}
    />
  );
}

export default SessionTabs;
