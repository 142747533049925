import * as React from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { generateHtmlId } from 'shared/utils/DOMUtils.js';

import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSClientListActions from 'point_of_sale/actions/POSClientListActions.jsx';

const styles = {
  root: {
    height: '100%',
    flexDirection: 'column',
  },

  paginator: {
    marginBottom: 10,
    bottom: 0,
  },
};

function SecondaryText({ client }) {
  if (!client.managed_by_id) {
    return null;
  }

  return (
    <FlexBoxCenter>
      <ManagingUserIcon style={{ height: 12, width: 12, marginRight: 8 }} />
      <span>{client.managing_customer_user_name}</span>
    </FlexBoxCenter>
  );
}

const addClientClick = e => {
  POSActions.addClientClick();
  e.preventDefault();
};

function ClientList({ posClientListStore }) {
  return (
    <FlexBox style={styles.root}>
      <FlexBoxCenter
        id={generateHtmlId('SearchBar', __filenamespace)}
        style={{ backgroundColor: 'white', height: 56 }}
      >
        <SearchBar
          onTypingEnd={POSClientListActions.searchStringUpdated}
          style={{ padding: '0 20px', fontSize: 18, flex: '1 1 auto' }}
          iconStyle={{ height: 20, width: 20, marginRight: 16 }}
          searchText={posClientListStore.searchString}
        />
        <IconButton
          style={{ alignSelf: 'center', marginRight: 8 }}
          onClick={addClientClick}
        >
          <PersonAddIcon sx={{ color: uhColors.activeBlue }} />
        </IconButton>
      </FlexBoxCenter>
      <Divider style={{ height: 2 }} />
      <List style={{ flex: '1 0 0', overflowY: 'auto' }}>
        {posClientListStore.clients
          .map(client => (
            <ListItem
              disablePadding
              key={client.id}
              onClick={e => {
                POSActions.clientSelected(client);
                e.preventDefault();
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <UserAvatar user={client} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography>{client.name()}</Typography>
                      <Typography variant="body2">
                        {client.phoneNumber()}
                      </Typography>
                    </Stack>
                  }
                  secondary={
                    client.managed_by_id ? (
                      <SecondaryText client={client} />
                    ) : null
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                  primaryTypographyProps={{ component: 'div' }}
                />
              </ListItemButton>
            </ListItem>
          ))
          .toList()}
      </List>
      <Paginator
        compact
        currentPage={posClientListStore.page}
        perPage={posClientListStore.perPage}
        totalCount={posClientListStore.totalCount}
        onPageSelect={(page, _) => POSClientListActions.pageSelected(page)}
        style={styles.paginator}
      />
    </FlexBox>
  );
}

export default ClientList;
