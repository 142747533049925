import React from 'react';
import clsx from 'clsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  Confirmation,
  Grid,
  Typography,
  RadioButton,
  Checkbox,
  TextField,
} from '@upperhand/playmaker';

import { compose } from 'shared/utils/SharedUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils';

import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';
import WarningIcon from 'shared/components/icons/WarningIcon.jsx';

import { SessionDataStore } from 'dataStores';
import CancelSessionActions from './Actions';
import CancelSessionStore, { creditOperations } from './Store';
import './styles.scss';

function CancelSessionModal({
  cancelSessionStore: {
    isCancelling,
    cancellationNotice,
    addNotice,
    creditOperation,
    waiveBalance,
    sessionId,
  },
  intl,
  scheduleType,
  sessionDataStore: { sessions },
}) {
  const session = sessions.get(sessionId);
  if (!session) return null;
  const clientsCount = session.client_ids.count();

  return (
    <Confirmation
      level="warning"
      cancelButtonLabel={t('.cancel_btn', intl, __filenamespace)}
      confirmButtonLabel={t('.confirm_btn', intl, __filenamespace)}
      open={isCancelling}
      confirmationDisabled={creditOperation === undefined && clientsCount !== 0}
      onCancel={CancelSessionActions.reset}
      onConfirm={CancelSessionActions.sessionCanceled}
      title={
        <Typography variant="dialogHeader">
          <WarningIcon width={20} height={18} />
          <span className="session-cancellation-modal__title">
            {t('.title', intl, __filenamespace)}
          </span>
        </Typography>
      }
      hasCheckbox
      checkboxText={t('.add_note', intl, __filenamespace)}
      checkboxChecked={addNotice}
      onCheckboxToggle={CancelSessionActions.toggleNotice}
    >
      <Grid
        container
        className={clsx(
          clientsCount !== 0 && 'session-cancellation-modal__container'
        )}
      >
        <FormattedMessage
          id={messageId('.unschedule_warning', __filenamespace)}
          values={{ clientsCount }}
        />
      </Grid>
      {clientsCount !== 0 && (
        <>
          <Grid container className="session-cancellation-modal__options">
            <Typography variant="subtitle2">
              {t('.options', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            className="session-cancellation-modal__container"
          >
            <RadioButton
              size="4x"
              selectedValue={creditOperation}
              onChange={(_, value) =>
                CancelSessionActions.setCreditOperation(value)
              }
              orientation="vertical"
              options={[
                {
                  label: (
                    <Grid container direction="column">
                      {t('.refund_title', intl, __filenamespace)}
                      <Typography variant="body2" color="secondary">
                        {t('.refund_subtitle', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                  ),
                  value: creditOperations.REFUND_CREDIT,
                  disabled: scheduleType === SCHEDULE_TYPE.fixedSchedule,
                },
                {
                  label: (
                    <Grid container direction="column">
                      {t('.revoke_title', intl, __filenamespace)}
                      <Typography variant="body2" color="secondary">
                        {t('.revoke_subtitle', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                  ),
                  value: creditOperations.REVOKE_CREDIT,
                },
              ]}
            />
            {creditOperation === creditOperations.REVOKE_CREDIT && (
              <Grid item className="session-cancellation-modal__radio-buttons">
                <Checkbox
                  onChange={(_, checked) =>
                    CancelSessionActions.setWaive(checked)
                  }
                  checked={waiveBalance}
                  label={t('.waive_checkbox', intl, __filenamespace)}
                />
                <Typography
                  variant="body2"
                  color="secondary"
                  className="session-cancellation-modal__waive-notice"
                >
                  {t('.credits_waive_notice', intl, __filenamespace)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Grid container direction="column">
        <Grid
          item
          className={clsx(addNotice && 'session-cancellation-modal__container')}
        >
          {clientsCount !== 0 && (
            <>
              <Typography variant="body2" component="strong">
                {t('.note_title', intl, __filenamespace)}
              </Typography>
              <Typography variant="body2" color="secondary" component="span">
                {t('.note_subtitle', intl, __filenamespace)}
              </Typography>
            </>
          )}
        </Grid>
        {addNotice && (
          <Grid item>
            <TextField
              placeholder={t('.notice_placeholder', intl, __filenamespace)}
              fullWidth
              onChange={(_, value) => CancelSessionActions.updateNote(value)}
              value={cancellationNotice}
            />
          </Grid>
        )}
      </Grid>
    </Confirmation>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      cancelSessionStore: CancelSessionStore,
      sessionDataStore: SessionDataStore,
    },
  })
)(CancelSessionModal);
