import { Record } from 'immutable';

class ChargifyProduct extends Record({
  id: null,
  name: '',
  price_in_cents: 0,
  interval: '',
  interval_unit: '',
}) {}

export default ChargifyProduct;
