import * as React from 'react';
import moment from 'moment-timezone';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import { uhColors } from 'shared/styles/uhStyles.jsx';

function InlineAvailability({ availabilitySchedule }) {
  const style = available =>
    available
      ? {
          backgroundColor: uhColors.primaryMain,
          color: 'white',
          margin: '0 2px',
        }
      : {
          backgroundColor: 'rgba(255,255,255,0) ',
          color: uhColors.primaryMain,
          margin: '0 2px',
        };

  const includeWeekday = weekdayOffset =>
    availabilitySchedule !== undefined &&
    availabilitySchedule !== null &&
    availabilitySchedule.includeDate(
      moment().startOf('week').add(weekdayOffset, 'days').toDate(),
      true
    );

  return (
    <Stack direction="row" spacing={1}>
      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((weekday, i) => (
        <Avatar
          // eslint-disable-next-line react/no-array-index-key
          key={`${weekday}-${i}`}
          style={style(includeWeekday(i))}
          sx={{
            width: '24px',
            height: '24px',
            paddingTop: '2px',
            fontSize: '1rem',
          }}
        >
          {weekday}
        </Avatar>
      ))}
    </Stack>
  );
}

export default InlineAvailability;
