/**
 * used to determine type of PaymentMethodManagementService response
 * @type {{}}
 */
const PaymentServiceResponse = {
  CREATE_SUCCESS: 'createSuccess',
  CREATE_ERROR: 'createError',
  TOKEN_SUCCESS: 'tokenSuccess',
  TOKEN_ERROR: 'tokenError',
};

export default PaymentServiceResponse;
