import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { t } from 'shared/utils/LocaleUtils';

import { PaymentMethod } from '../../types';
import PaymentActions from '../../Actions';

const styles = {
  cardName: {
    display: 'inline-block',
    maxWidth: '200px',
    overflow: 'hidden',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dropdownLabel: {
    fontSize: '15px',
    color: 'var(--color-old-gray)',
    fontWeight: 600,
  },
  dropdown: {
    marginBottom: '10px',
    '.UH-MuiSelect-select': {
      display: 'flex',
      height: '30px',
      alignItems: 'center',
    },
  },
  selectedMethod: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
};

const cardDisplay = card => (
  <Box>
    <Box style={styles.cardName}>{card.info.name}&nbsp;</Box>
    {card.info.last2}
  </Box>
);

function ACHSelectField({
  allowedPaymentMethods,
  currentPaymentMethod,
  paymentMethods,
}) {
  const intl = useIntl();
  const menuOptions = [];

  if (allowedPaymentMethods.has(PaymentMethod.ACH)) {
    menuOptions.push(
      <MenuItem key={PaymentMethod.ACH_NEW} value={PaymentMethod.ACH_NEW}>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText>{t('.new', intl, __filenamespace)}</ListItemText>
      </MenuItem>
    );
  }

  if (paymentMethods) {
    menuOptions.push(<Divider key="divider" />);

    paymentMethods.forEach(c => {
      menuOptions.push(
        <MenuItem key={c.id} value={c.id}>
          <ListItemIcon>
            <AccountBalanceIcon brand={c.info.brand} />
          </ListItemIcon>
          <ListItemText>{cardDisplay(c)}</ListItemText>
        </MenuItem>
      );
    });
  }

  return (
    <>
      <FormControl fullWidth variant="standard">
        <InputLabel shrink sx={styles.dropdownLabel}>
          {t('.choose', intl, __filenamespace)}
        </InputLabel>
        <Select
          MenuProps={{ disableEnforceFocus: true }}
          sx={styles.dropdown}
          label={t('.choose', intl, __filenamespace)}
          onChange={e => PaymentActions.changeAchPaymentOption(e.target.value)}
          value={
            currentPaymentMethod.id !== null
              ? currentPaymentMethod.id
              : PaymentMethod.ACH_NEW
          }
        >
          {menuOptions}
        </Select>
      </FormControl>
      {currentPaymentMethod.id && (
        <Box sx={styles.selectedMethod}>
          <AccountBalanceIcon />
          {currentPaymentMethod.info.name}&nbsp;
          {currentPaymentMethod.info.last2}
        </Box>
      )}
    </>
  );
}

export default memo(ACHSelectField);
