import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';

function Header({ children }) {
  if (!children) {
    return null;
  }

  return (
    <Grid container item spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <Typography variant="h4" className="table_title">
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  children: PropTypes.any,
};

Header.defaultProps = {
  children: '',
};

export default Header;
