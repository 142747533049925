/* eslint-disable class-methods-use-this */
import { Map } from 'immutable';
import {
  getAllUrlParameters,
  resetUrl,
  setUrlParameter,
} from 'shared/utils/UrlParameters.js';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import { arrayToObject, merge } from 'shared/utils/ObjectUtils.jsx';

class FilterStoreImpl {
  constructor(actions) {
    this.filterDrawerOpen = false;
    this.resetFilters();
    this.statusFilters = Map();
    this.eventStatusFilters = [];

    this.bindListeners(
      merge(actions, {
        handleTeamTypeList: TeamTypeListingActions.LIST_SUCCESS,
      })
    );
  }

  resetFilters() {
    const urlParams = getAllUrlParameters() || Map();
    this.titleFilter = '';
    if (typeof urlParams.get === 'function') {
      this.typeFilters =
        typeof urlParams.get('type') !== 'undefined'
          ? Map(arrayToObject(urlParams.get('type').toJS()))
          : Map({ all: true });
    } else {
      resetUrl();
      this.typeFilters = Map({ all: true });
      this.ageFilters = Map({ all: true });
    }
  }

  updateFilterDrawerOpen(open) {
    this.filterDrawerOpen = open;
  }

  toggleFilterDrawer() {
    this.filterDrawerOpen = !this.filterDrawerOpen;
  }

  updateStatusFilters(args) {
    this.statusFilters = this.updateFilterSet(
      this.statusFilters,
      args[0],
      args[1],
      'status'
    );
  }

  updateTypeFilters(args) {
    this.typeFilters = this.updateFilterSet(
      this.typeFilters,
      args[0],
      args[1],
      'type'
    );
  }

  updateFilterSet(filterSet, key, value, filterName) {
    if (key === 'all') {
      return this.setAllFilters(filterSet, value);
    }
    const arr = [];
    const newFilterSet = filterSet.withMutations(set => {
      set.set(key, value);
      set.delete('all', value && this.areAllFiltersEnabled(set));
    });

    newFilterSet.forEach((val, filter) => {
      if (val) {
        arr.push(filter);
      }
    });

    setUrlParameter(filterName, arr, window.location.pathname);
    return newFilterSet;
  }

  setAllFilters(filterSet, value) {
    return filterSet.withMutations(set => {
      set.forEach((_, key) => set.set(key, value));
    });
  }

  areAllFiltersEnabled(filterSet) {
    return filterSet.every((value, key) => key === 'all' || value);
  }

  updateTitleFilter(title) {
    this.titleFilter = title;
  }

  handleTeamTypeList() {
    this.waitFor(TeamTypeListingStore);
    const { isLoading, teamTypes } = TeamTypeListingStore.getState();
    if (!isLoading) {
      const typeSetup = Map({ all: true }).withMutations(filterSet =>
        teamTypes.forEach(type => {
          if (!this.typeFilters.has(type.id.toString())) {
            filterSet.set(type.id.toString(), false);
          }
        })
      );

      this.typeFilters = this.typeFilters.merge(typeSetup);
    }
  }
}

export default FilterStoreImpl;
