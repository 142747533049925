import * as React from 'react';
import PropTypes from 'prop-types';

import SquareIcon from '@mui/icons-material/Square';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 1,
  },
  customRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: 1,
  },

  colorSquare: {
    height: 20,
    width: 20,
    margin: '2px 0',
    flex: '0 0 auto',
  },

  eventTypeName: {
    flex: '1 1 auto',
  },
};

function ColoredItem({ style, color, text, leftStyles, mod, textStyle }) {
  return (
    <FlexBox
      style={merge(leftStyles || mod ? styles.root : styles.customRoot, style)}
    >
      <SquareIcon style={styles.colorSquare} sx={{ color }} />
      <div style={{ marginLeft: textStyle ? '1rem' : 4, ...textStyle }}>
        {text}
      </div>
    </FlexBox>
  );
}

ColoredItem.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
};

ColoredItem.defaultProps = {
  color: uhColors.primaryMain,
  style: {},
};

export default ColoredItem;
