export default alt.generateActions(
  'contactGroupDrawerOpened',
  'eventDrawerOpened',
  'drawerClosed',
  'subjectUpdated',
  'messageUpdated',
  'cancelClicked',
  'sendClicked',
  'sendSuccess',
  'sendError',
  'sendLaterUpdated',
  'sendDateUpdated',
  'sendTimeUpdated',
  'fetchTemplate',
  'fetchTemplateSuccess',
  'fetchTemplateError',
  'changeStep'
);
