import { List, Set } from 'immutable';
import AccountingCode from 'shared/records/AccountingCode';
import AccountingCodeArchivingActions from 'shared/actions/accounting_codes/AccountingCodeArchivingActions';
import AccountingCodeCreationActions from 'shared/actions/accounting_codes/AccountingCodeCreationActions';
import AccountingCodeEditingActions from 'shared/actions/accounting_codes/AccountingCodeEditingActions';
import AccountingCodeListingActions from 'shared/actions/accounting_codes/AccountingCodeListingActions';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import TaxRateCreationActions from 'shared/actions/tax_rates/TaxRateCreationActions';
import TaxRateEditingActions from 'shared/actions/tax_rates/TaxRateEditingActions';
import TaxRateListingActions from 'shared/actions/tax_rates/TaxRateListingActions';
import TaxRateListingStore from 'shared/stores/tax_rates/TaxRateListingStore';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';

class AccountingCodeListingStore extends UpperHandStore {
  constructor() {
    super();

    this.accountingCodes = List();
    this.archivedCodes = List();
    this.isLoading = false;

    this.selectedCodeTypes = Set();
    this.selectedEventTypeIds = Set();
    this.selectedRetailCategoryIds = Set();
    this.selectedTaxRateIds = Set();
    this.selectedTeamTypeIds = Set();

    this.bindListeners({
      list: AccountingCodeListingActions.LIST,
      listSuccess: AccountingCodeListingActions.LIST_SUCCESS,
      listError: AccountingCodeListingActions.LIST_ERROR,

      codeCreated: AccountingCodeCreationActions.SAVE_SUCCESS,
      codeUpdated: AccountingCodeEditingActions.SAVE_SUCCESS,
      codeArchived: AccountingCodeArchivingActions.ARCHIVE_SUCCESS,

      setAccountingCodeEventTypes: EventTypeListingActions.LIST_SUCCESS,
      setAccountingCodeRetailCategories:
        RetailCategoryListingActions.LIST_SUCCESS,

      setAccountingCodeTaxRates: [
        TaxRateListingActions.LIST_SUCCESS,
        TaxRateEditingActions.SAVE_SUCCESS,
        TaxRateCreationActions.SAVE_SUCCESS,
      ],
      setAccountingCodeTeamTypes: TeamTypeListingActions.LIST_SUCCESS,
    });
  }

  list(options) {
    const { page = 1 } = options || {};

    this.isLoading = true;

    uhApiClient.get({
      url: 'accounting_codes',
      data: { page, per_page: 100 },
      success: AccountingCodeListingActions.listSuccess,
      error: AccountingCodeListingActions.listError,
    });
  }

  listSuccess(data) {
    const {
      accounting_codes: accountingCodes,
      page,
      per_page: perPage,
      total_count: totalCount,
    } = data;
    const partitionedCodes = List(
      accountingCodes.map(c => new AccountingCode(c))
    )
      .groupBy(c => c?.charge_type === 'Tax')
      .get(true, List())
      .groupBy(c => c?.archived);
    this.accountingCodes = partitionedCodes.get(false, List());
    this.archivedCodes = partitionedCodes.get(true, List());

    const codeCount = this.accountingCodes.size + this.archivedCodes.size;

    if (accountingCodes.length === perPage && totalCount > codeCount) {
      this.list({ page: page + 1 });
    } else {
      this.isLoading = false;

      this.setAccountingCodeEventTypes();
      this.setAccountingCodeTeamTypes();
      this.setAccountingCodeRetailCategories();
      this.setAccountingCodeTaxRates();
      this.setSelectedCodeTypes();
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing accounting codes', args);
  }

  codeCreated(data) {
    this.accountingCodes = this.accountingCodes
      .push(new AccountingCode(data))
      .sort(this.sortByTypeAndName);

    this.setAccountingCodeEventTypes();
    this.setAccountingCodeTeamTypes();
    this.setAccountingCodeRetailCategories();
    this.setAccountingCodeTaxRates();
    this.setSelectedCodeTypes();
  }

  codeUpdated(data) {
    const index = this.accountingCodes.findIndex(c => c.id === data.id);

    this.accountingCodes = this.accountingCodes
      .set(index, new AccountingCode(data))
      .sort(this.sortByTypeAndName);

    this.setAccountingCodeEventTypes();
    this.setAccountingCodeTeamTypes();
    this.setAccountingCodeRetailCategories();
    this.setAccountingCodeTaxRates();
    this.setSelectedCodeTypes();
  }

  codeArchived(data) {
    const [index, code] = this.accountingCodes.findEntry(
      c => c.id === data.id,
      null,
      [-1, null]
    );

    if (index >= 0) {
      this.accountingCodes = this.accountingCodes.delete(index);
      this.archivedCodes = this.archivedCodes
        .push(code.merge(new AccountingCode(data)))
        .sort(this.sortByTypeAndName);

      this.setAccountingCodeEventTypes();
      this.setAccountingCodeRetailCategories();
      this.setAccountingCodeTaxRates();
      this.setSelectedCodeTypes();
    }
  }

  setSelectedCodeTypes() {
    this.selectedCodeTypes = this.accountingCodes.reduce(
      (codeTypes, code) => codeTypes.add(code.code_type),
      Set()
    );
  }

  setAccountingCodeEventTypes() {
    this.waitFor(EventTypeListingStore);
    const { isLoading, eventTypes } = EventTypeListingStore.getState();
    if (!isLoading) {
      this.accountingCodes = this.accountingCodes.map(c =>
        c.set(
          'event_types',
          eventTypes.filter(t => c.event_type_ids.has(t.id))
        )
      );

      this.archivedCodes = this.archivedCodes.map(c =>
        c.set(
          'event_types',
          eventTypes.filter(t => c.event_type_ids.has(t.id))
        )
      );

      this.selectedEventTypeIds = this.accountingCodes.reduce(
        (selectedIds, code) => code.event_type_ids.union(selectedIds),
        Set()
      );
    }
  }

  setAccountingCodeTeamTypes() {
    this.waitFor(TeamTypeListingStore);
    const { isLoading, teamTypes } = TeamTypeListingStore.getState();
    if (!isLoading) {
      this.accountingCodes = this.accountingCodes.map(c =>
        c.set(
          'team_types',
          teamTypes.filter(t => c.team_type_ids.has(t.id))
        )
      );

      this.archivedCodes = this.archivedCodes.map(c =>
        c.set(
          'team_types',
          teamTypes.filter(t => c.team_type_ids.has(t.id))
        )
      );

      this.selectedTeamTypeIds = this.accountingCodes.reduce(
        (selectedIds, code) => code.team_type_ids.union(selectedIds),
        Set()
      );
    }
  }

  setAccountingCodeRetailCategories() {
    this.waitFor(RetailCategoryListingStore);

    const { retailCategories } = RetailCategoryListingStore.getState();

    this.accountingCodes = this.accountingCodes.map(c =>
      c.set(
        'retail_categories',
        retailCategories.filter(t => c.retail_category_ids.has(t.id))
      )
    );

    this.archivedCodes = this.archivedCodes.map(c =>
      c.set(
        'retail_categories',
        retailCategories.filter(t => c.retail_category_ids.has(t.id))
      )
    );

    this.selectedRetailCategoryIds = this.accountingCodes.reduce(
      (selectedIds, code) => code.retail_category_ids.union(selectedIds),
      Set()
    );
  }

  setAccountingCodeTaxRates() {
    this.waitFor(TaxRateListingStore);
    const { taxRates } = TaxRateListingStore.getState();

    this.accountingCodes = this.accountingCodes.map(c =>
      c.set(
        'tax_rates',
        taxRates.filter(t => c.tax_rate_ids.includes(t.id))
      )
    );

    this.archivedCodes = this.archivedCodes.map(c =>
      c.set(
        'tax_rates',
        taxRates.filter(t => c.tax_rate_ids.includes(t.id))
      )
    );
  }

  // eslint-disable-next-line class-methods-use-this
  sortByTypeAndName(a, b) {
    if (a.code_type < b.code_type) {
      return -1;
    }
    if (b.code_type < a.code_type) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (b.name < a.name) {
      return 1;
    }
    return 0;
  }
}

export default alt.createStore(
  AccountingCodeListingStore,
  'AccountingCodeListingStore'
);
