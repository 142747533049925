/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown, TextField } from '@upperhand/playmaker';

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Checkbox, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import RegistrationFieldStore from 'shared/stores/RegistrationFieldStore.jsx';
import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
    display: 'flex',
  },
  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    marginTop: '15px',
    maxWidth: '100%',
  },
  columnHeader: {
    marginBottom: '15px',
  },
  fieldDirection: {
    lineHeight: '1.5em',
    marginBottom: '15px',
    color: uhColors.tabGrey,
  },
  fieldHeader: {
    color: uhColors.leftNavGrey,
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  spacer: {
    color: '#e5e6e5',
  },
  categorySelection: {
    marginBottom: '8px',
  },
  optionSelectionTextFiled: {
    marginTop: '16px',
  },
};

const whileStaged = (id, commit) => {
  RegistrationFieldActions.stageRegistrationField({
    metaRegistrationFieldId: id,
  });
  commit();
  RegistrationFieldActions.clearRegistrationField();
  RegistrationFieldActions.validateSelectedFields();
};

const addField = (id, ordinal) =>
  whileStaged(id, () => {
    RegistrationFieldActions.updateStore([
      ['selected', true],
      ['ordinal', ordinal],
    ]);
  });

const removeField = id =>
  whileStaged(id, () =>
    RegistrationFieldActions.updateStore([['selected', false]])
  );

const setOptions = (id, value) =>
  whileStaged(id, () =>
    RegistrationFieldActions.updateStore([['options', value]])
  );

const setRequired = (id, required) =>
  whileStaged(id, () =>
    RegistrationFieldActions.updateStore([['required', required]])
  );

function CategorySelection({ category, unselectedFields, selectedFields }) {
  return (
    <Box sx={styles.categorySelection}>
      <Dropdown
        classes={{
          select: 'calculated-column-width-20',
        }}
        placeholder={category}
        onChange={e => addField(e.target.value, selectedFields.size)}
        items={unselectedFields
          .filter(f => f.category === category)
          .map(field => ({
            value: field.meta_registration_field_id,
            label: field.name,
          }))
          .toArray()}
      />
    </Box>
  );
}

function Fields(props) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.column}>
        <Typography variant="subtitle1" sx={styles.fieldHeader}>
          <FormattedMessage
            id={messageId('.field_categories', __filenamespace)}
          />
        </Typography>
        <Typography sx={styles.fieldDirection}>
          <FormattedMessage
            id={messageId('.add_fields_directions', __filenamespace)}
          />
        </Typography>
        <UnselectedFields {...props} />
      </Box>
      <Box sx={{ width: 40 }} />
      <Box sx={styles.column}>
        <Typography variant="subtitle1" sx={styles.columnHeader}>
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id={messageId('.team_form_fields', __filenamespace)}
            />
          </Box>
          <FormattedMessage
            id={messageId('.team_form_fields_check', __filenamespace)}
          />
        </Typography>
        <Typography sx={styles.fieldDirection}>
          <FormattedMessage
            id={messageId('.team_form_directions', __filenamespace)}
          />
        </Typography>
        <SelectedFields {...props} />
      </Box>
    </Box>
  );
}

function SelectedFields({ intl, selectedFields }) {
  return (
    <Box>
      {selectedFields.map(field => {
        const display = field.isListable() ? (
          <OptionSelection field={field} intl={intl} />
        ) : (
          <TextSelection field={field} />
        );
        return <Box key={field.meta_registration_field_id}>{display}</Box>;
      })}
    </Box>
  );
}

function OptionSelection({ field, intl }) {
  return (
    <Box>
      <TextSelection field={field} />
      <Box sx={styles.optionSelectionTextFiled}>
        <TextField
          key={`options-${field.meta_registration_field_id}`}
          onChange={(_, value) =>
            setOptions(field.meta_registration_field_id, value)
          }
          label={t('.field_options', intl, __filenamespace)}
          fullWidth
          value={field.options}
          errorText={field.errors.getErrors('options')}
        />
      </Box>
    </Box>
  );
}

function TextSelection({ field }) {
  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Box>
          <Checkbox
            onChange={e =>
              setRequired(field.meta_registration_field_id, e.target.checked)
            }
            checked={field.isRequired()}
          />
        </Box>
        <Box>{field.name}</Box>
      </Box>
      <ClearIcon
        sx={{ height: 18, width: 18, color: grey[500] }}
        onClick={() => removeField(field.meta_registration_field_id)}
      />
    </Box>
  );
}

function UnselectedFields(props) {
  const { allRegistrationFieldCategories } = props;

  return (
    <Box>
      {allRegistrationFieldCategories.map(category => (
        <CategorySelection key={category} {...props} category={category} />
      ))}
    </Box>
  );
}

function TabRegistrationForm(props) {
  return (
    <AltContainer store={RegistrationFieldStore}>
      <Fields {...props} />
    </AltContainer>
  );
}

export default injectIntl(TabRegistrationForm);
