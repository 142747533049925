import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Typography } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import Location from 'shared/records/Location.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';

const styles = {
  locationIconContainer: {
    height: '24px',
    width: '32px',
    textAlign: 'center',
  },
  locationIcon: {
    color: uhColors.pmIconGray,
  },
};

function LocationDisplay({
  locationStore: { allLocations: locations },
  locationId,
}) {
  const location = new Location(locations.find(loc => loc.id === locationId));
  return (
    <FlexBoxCenter>
      <div style={styles.locationIconContainer}>
        <LocationOnIcon style={styles.locationIcon} />
      </div>

      <Typography variant="drawerTitle">
        {location.name ? (
          location.name
        ) : (
          <FormattedMessage id={messageId('.tbd_location', __filenamespace)} />
        )}
      </Typography>
    </FlexBoxCenter>
  );
}

export default altContainer({
  stores: {
    locationStore: LocationStore,
  },
})(LocationDisplay);
