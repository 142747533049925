class PaymentMethodModalActions {
  constructor() {
    this.generateActions(
      'mounted',
      'paymentMethodResponse',
      'updatePaymentMethodRecord',
      'updatePaymentMethod',
      'createPaymentMethod',
      'createPaymentMethodSuccess',
      'createPaymentMethodError',
      'updatePaymentMethodSuccess',
      'updatePaymentMethodError',
      'changePaymentMethodType',
      'openModal',
      'closeModal',
      'openEditModal'
    );
  }
}

export default alt.createActions(PaymentMethodModalActions);
