import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import Registration from 'shared/records/Registration';
import Session from 'shared/records/Session';

function AttendeeResponse({ session, registration, onRsvpChanged }) {
  const intl = useIntl();

  if (!registration) return null;

  const rsvpStatus = registration.get('rsvp_status');
  const startsAt = session.get('starts_at');
  const isPast = moment().isAfter(startsAt);

  return (
    <div className="attendee-response">
      <div className="attendee-response__title">
        <Typography variant="body1" className="attendee-response__message">
          {t('.attending', intl, __filenamespace)}
        </Typography>
      </div>
      <div
        className={clsx(
          'attendee-response__actions',
          isPast && 'attendee-response__actions--disabled'
        )}
      >
        <Button
          rounded
          type="secondary"
          disabled={rsvpStatus === 'attending'}
          classes={{ root: 'attendee-response__actions-yes' }}
          onClick={() => onRsvpChanged(registration, 'attending')}
        >
          {t('.yes', intl, __filenamespace)}
        </Button>
        <Button
          rounded
          type="secondary"
          disabled={rsvpStatus === 'skipping'}
          classes={{ root: 'attendee-response__actions-no' }}
          onClick={() => onRsvpChanged(registration, 'skipping')}
        >
          {t('.no', intl, __filenamespace)}
        </Button>
      </div>
    </div>
  );
}

AttendeeResponse.propTypes = {
  registration: PropTypes.instanceOf(Registration).isRequired,
  session: PropTypes.instanceOf(Session).isRequired,
  onRsvpChanged: PropTypes.func.isRequired,
};

export default memo(AttendeeResponse);
