import { List } from 'immutable';
import { PaymentPlan } from 'records';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './PaymentPlanActions';

const parserTo = { type: PaymentPlan, paginationKey: 'payment_plans' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error, params = {} }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

/**
 * amount: the total amount to refund
 * allocation: a map of {"debit_ids": "amount"}
 * ex: payment({id: 'dc01d330-a834-4a66-a13f-fd50df8f430c', amount: 100, allocation: {"e7d22114-e1ec-4de2-bdff-0740c88f44ee": 50}})
 */
export const payment = ({ id, amount, allocation, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/${id}/payment`,
    data: JSON.stringify({
      amount,
      allocation,
    }),
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.paymentSuccess, success])
    ),
    error: onError(List([DataStoreActions.paymentError, error])),
  });
};

export const waive = ({ id, amount, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/${id}/forgiveness`,
    data: JSON.stringify({
      amount,
    }),
    success: onSuccess(
      data => parser.parseNone(data),
      List([DataStoreActions.waiveSuccess, success])
    ),
    error: onError(List([DataStoreActions.waiveError, error])),
  });
};
