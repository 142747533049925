import { List, Record } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { convertDateToClientValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

export const ABSOLUTE = 'amount';
export const PERCENT = 'percent';
const COMMISSION_TYPES = ['membership_commission', 'retail_commission'];

class PayrollEntry extends Record({
  id: null,
  attendee_names: List(),
  customer_user_id: null,
  duration: null,
  end_time: null,
  event_id: null,
  event_name: null,
  event_type_id: null,
  event_type_name: null,
  hourly_rate: 0,
  location_id: null,
  object_type: null,
  rate_type: null,
  start_time: null,
  task_id: null,
  total: null,
  value: null,
  errors: new FieldErrors(),
  payment_status: null,
  payment_method: null,
}) {
  constructor(obj = {}) {
    const startTime = convertDateToClientValue(obj.start_time);
    const endTime = convertDateToClientValue(obj.end_time);
    const attendeeNames = List(obj.attendee_names);
    const { errors } = obj;

    super(
      merge(obj, {
        attendee_names: attendeeNames,
        start_time: startTime,
        end_time: endTime,
        errors: new FieldErrors(errors),
      })
    );
  }

  isPaid() {
    return this.payment_status === 'paid';
  }

  isUnpaid() {
    return this.payment_status === 'unpaid';
  }

  isValid() {
    return this.errors.isEmpty();
  }

  validate() {
    let errors = new FieldErrors();
    if (
      this.rate_type === PERCENT &&
      isPresent(this.value) &&
      (this.value <= 0.0 || this.value > 100.0)
    ) {
      errors = errors.add(
        'value',
        'records.PayrollEntry.errors.value_out_of_range'
      );
    }

    if (
      this.rate_type === ABSOLUTE &&
      isPresent(this.value) &&
      this.value <= 0
    ) {
      errors = errors.add(
        'value',
        'records.PayrollEntry.errors.more_than_zero'
      );
    }
    return this.set('errors', errors);
  }

  hasPayrollCommission() {
    return COMMISSION_TYPES.includes(this.object_type);
  }

  toServer() {
    return {
      rate_type: this.rate_type,
      value: this.value,
    };
  }
}

export default PayrollEntry;
