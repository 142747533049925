import moment from 'moment-timezone';
import { Record } from 'immutable';
import {
  dateWithTime,
  hmsStringFromDate,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

class Message extends Record({
  subject: '',
  body: '',
  sendLater: false,
  sendDate: undefined,
  sendTime: undefined,
}) {
  isValid() {
    return (
      !!this.subject &&
      !!this.body &&
      (!this.sendLater || (!!this.sendDate && !!this.sendTime))
    );
  }

  sendAt() {
    if (!this.sendLater) {
      return null;
    }

    const hmsString = hmsStringFromDate(this.sendTime);

    return moment.tz(
      moment(dateWithTime(hmsString, this.sendDate)).format(
        'YYYY-MM-DDTHH:mm:ss'
      ),
      currentCustomer().tz_name
    );
  }

  toServer() {
    return {
      message_subject: this.subject,
      message_body: this.body,
      send_at: this.sendAt(),
    };
  }
}

export default Message;
