import * as React from 'react';
import { injectIntl } from 'react-intl';
import { bool, string } from 'prop-types';

import Button from '@mui/material/Button';

import { FlexBox } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import PaymentActions from '../../Actions';
import { PaymentMethod } from '../../types';

const styles = {
  ActiveBackgroundColor: uhColors.hintActive,
  DefaultBackgroundColor: uhColors.white,
  ActiveColor: uhColors.white,
  DefaultColor: uhColors.hintActive,
  CardTypeButton: {
    fontSize: '13px',
    borderRadius: '2px',
    flex: '1 1 50%',
  },
};

function CardTypeSegment({ selectedCardType, disabled, intl }) {
  const readerMethods = [PaymentMethod.HANDPOINT_CLOUD];

  return (
    <FlexBox style={{ marginTop: '20px', justifyContent: 'space-between' }}>
      <Button
        color="default"
        variant="contained"
        disabled={disabled}
        sx={{
          ...styles.CardTypeButton,
          backgroundColor: readerMethods.includes(selectedCardType)
            ? styles.ActiveBackgroundColor
            : styles.DefaultBackgroundColor,
          color: readerMethods.includes(selectedCardType)
            ? styles.ActiveColor
            : styles.DefaultColor,
        }}
        onClick={() =>
          PaymentActions.changePaymentMethod(PaymentMethod.HANDPOINT_CLOUD)
        }
      >
        {t('.reader', intl, __filenamespace)}
      </Button>
      <Button
        color="default"
        variant="contained"
        disabled={disabled}
        sx={{
          ...styles.CardTypeButton,
          backgroundColor: !readerMethods.includes(selectedCardType)
            ? styles.ActiveBackgroundColor
            : styles.DefaultBackgroundColor,
          color: !readerMethods.includes(selectedCardType)
            ? styles.ActiveColor
            : styles.DefaultColor,
        }}
        onClick={() =>
          PaymentActions.changePaymentMethod(PaymentMethod.CARD_NOT_PRESENT)
        }
      >
        {t('.manual', intl, __filenamespace)}
      </Button>
    </FlexBox>
  );
}

CardTypeSegment.propTypes = {
  selectedCardType: string.isRequired,
  disabled: bool.isRequired,
};

export default injectIntl(CardTypeSegment);
