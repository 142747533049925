import FeeRateEditingActions from 'shared/actions/fee_rates/FeeRateEditingActions';
import FeeRateEditingStoreInterface from 'shared/stores/fee_rates/FeeRateEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  FeeRateEditingStoreInterface,
  'FeeRateEditingStore',
  {
    actions: FeeRateEditingActions,
    successMessage: new TranslatableMessage({
      id: '.updated',
      filename: __filenamespace,
    }),
  }
);
