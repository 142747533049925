import ContactGroupStore from 'shared/stores/ContactGroupStore.jsx';
import Message from 'shared/records/Message.js';
import MessagingDrawerActions from 'shared/actions/MessagingDrawerActions.js';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { prettyFormat } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

export const STEPS = { messaging: 'messaging', preview: 'preview' };

class MessagingDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      openEventDrawer: MessagingDrawerActions.EVENT_DRAWER_OPENED,
      openContactGroupDrawer:
        MessagingDrawerActions.CONTACT_GROUP_DRAWER_OPENED,
      closeDrawer: MessagingDrawerActions.DRAWER_CLOSED,

      updateSubject: MessagingDrawerActions.SUBJECT_UPDATED,
      updateMessage: MessagingDrawerActions.MESSAGE_UPDATED,
      updateSendLater: MessagingDrawerActions.SEND_LATER_UPDATED,
      updateSendDate: MessagingDrawerActions.SEND_DATE_UPDATED,
      updateSendTime: MessagingDrawerActions.SEND_TIME_UPDATED,

      sendMessage: MessagingDrawerActions.SEND_CLICKED,
      sendMessageSuccess: MessagingDrawerActions.SEND_SUCCESS,
      sendMessageError: MessagingDrawerActions.SEND_ERROR,

      fetchTemplate: MessagingDrawerActions.FETCH_TEMPLATE,
      fetchTemplateSuccess: MessagingDrawerActions.FETCH_TEMPLATE_SUCCESS,
      fetchTemplateError: MessagingDrawerActions.FETCH_TEMPLATE_ERROR,

      changeStep: MessagingDrawerActions.CHANGE_STEP,

      reset: MessagingDrawerActions.CANCEL_CLICKED,
    });
  }

  reset() {
    this.open = false;
    this.processing = false;
    this.emailTemplate = '';
    this.templateLoading = false;
    this.step = STEPS.messaging;

    this.groupType = null;
    this.groupId = null;
    this.groupName = null;

    this.message = new Message();
    this.valid = this.message.isValid();
  }

  openEventDrawer(curEvent) {
    const event = curEvent;
    this.groupId = event.id;
    this.groupName = event.title;
    this.groupType = 'event';

    this.open = true;
  }

  openContactGroupDrawer() {
    const { contactGroup } = ContactGroupStore.getState();

    this.groupId = contactGroup.id;
    this.groupName = contactGroup.name;
    this.groupType = 'contact_group';

    this.open = true;

    this.fetchTemplate('message_contacts');
  }

  closeDrawer() {
    this.open = false;
    this.emailTemplate = '';
    this.templateLoading = false;
    this.step = STEPS.messaging;
  }

  updateSubject(newValue) {
    this.updateAndValidateMessage('subject', newValue);
  }

  updateMessage(newValue) {
    this.updateAndValidateMessage('body', newValue);
  }

  updateSendLater(newValue) {
    this.updateAndValidateMessage('sendLater', !!newValue);

    if (this.message.get('sendLater')) {
      this.message = this.message
        .set('sendDate', new Date())
        .set('sendTime', new Date());
    } else {
      this.message = this.message.set('sendDate', null).set('sendTime', null);
    }
  }

  updateSendDate(newValue) {
    this.updateAndValidateMessage('sendDate', newValue);
  }

  updateSendTime(newValue) {
    this.updateAndValidateMessage('sendTime', newValue);
  }

  updateAndValidateMessage(key, value) {
    this.message = this.message.set(key, value);
    this.valid = this.message.isValid();
  }

  sendMessage() {
    this.processing = true;

    return uhApiClient.post({
      url: this.url(),
      data: JSON.stringify(this.message.toServer()),
      success: MessagingDrawerActions.sendSuccess,
      error: MessagingDrawerActions.sendError,
    });
  }

  sendMessageSuccess() {
    MessageWindowActions.addMessage.defer(this.successMessage());
    this.reset();
  }

  sendMessageError(...args) {
    this.processing = false;

    this.notifyError(
      `error sending message for ${this.groupType} with id ${this.groupId}`,
      args
    );
  }

  url() {
    return `${this.groupType}s/${this.groupId}/message`;
  }

  successMessage() {
    const sendAt = this.message.sendAt();

    if (sendAt) {
      const formattedDate = prettyFormat(sendAt, {
        withTime: true,
        withTimezone: true,
        timeSeparator: ' \\at ',
      });

      return `Scheduled email for ${formattedDate}.`;
    }
    return 'Email sent.';
  }

  fetchTemplate(name) {
    this.templateLoading = true;

    uhApiClient.get({
      url: `email_templates/${name}`,
      success: MessagingDrawerActions.fetchTemplateSuccess,
      error: MessagingDrawerActions.fetchTemplateError,
    });
  }

  fetchTemplateSuccess(template) {
    this.emailTemplate = template;
    this.templateLoading = false;
  }

  fetchTemplateError() {
    this.templateLoading = false;
  }

  changeStep(step = STEPS.messaging) {
    this.step = step;
  }
}

export default alt.createStore(MessagingDrawerStore, 'MessagingDrawerStore');
