import { List } from 'immutable';

import { onError, onSuccess } from 'sources/handlers';
import Parser from 'sources/Parser';
import EventClass from 'shared/records/EventClass';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import DataStoreActions from './ClassesActions';

const parserTo = { type: EventClass, paginationKey: 'class_events' };

const parser = new Parser(parserTo);

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const create = ({ params, success, error }) => {
  uhApiClient.post({
    url: parserTo.paginationKey,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.createSuccess, success])
    ),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const update = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const destroy = ({ id, params, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/${id}/cancel`,
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};
