import * as React from 'react';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';

const styles = {
  client: {
    margin: '20px 0',
  },
};

function ClientBox({ client }) {
  return (
    <div style={styles.client}>
      <FlexBoxCenter>
        {client && <UserAvatar user={client} style={{ flex: '0 0 auto' }} />}
        <FlexBox
          style={{ flexDirection: 'column', color: 'var(--color-old-gray)' }}
        >
          <div style={{ fontWeight: '600' }}>{client && client.name()}</div>
        </FlexBox>
      </FlexBoxCenter>
    </div>
  );
}

export default ClientBox;
