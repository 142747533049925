import { Record } from 'immutable';
import { toCamelCase, toSnakeCase } from 'shared/utils/ObjectUtils.jsx';
import PaymentCard from 'containers/payments/records/PaymentCard.js';
import PaymentAch from 'containers/payments/records/PaymentAch.js';

import PaymentMethodTypes from 'containers/payments/types/PaymentMethod';

class PaymentMethod extends Record({
  id: null,
  userId: null,
  default: false,
  type: PaymentMethodTypes.CARD,
  info: new PaymentCard(),
}) {
  constructor(rawObj, userId) {
    const parsedObject = toCamelCase(rawObj || {});

    parsedObject.info =
      parsedObject.type === PaymentMethodTypes.ACH
        ? new PaymentAch(toSnakeCase(parsedObject?.info || {}))
        : new PaymentCard(parsedObject.info);

    parsedObject.userId = userId;

    super(parsedObject);
  }

  toServer() {
    return toSnakeCase(this.toJS());
  }
}

export default PaymentMethod;
