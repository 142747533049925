import * as React from 'react';
import { injectIntl } from 'react-intl';
import { TextField, Checkbox } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

function TaxExemptToggle({
  order,
  onTaxExemptToggle,
  onTaxIdChange,
  disabled,
  style,
  intl,
}) {
  return (
    <div style={style}>
      <Checkbox
        label={t('.remove_sales_tax', intl, __filenamespace)}
        checked={order.tax_exempt}
        onChange={(_, checked) => onTaxExemptToggle(checked)}
        disabled={disabled}
      />
      {order.tax_exempt && (
        <TextField
          fullWidth
          label={t('.tax_id', intl, __filenamespace)}
          placeholder={t('.enter_tax_id', intl, __filenamespace)}
          value={order.tax_id}
          onChange={(_, value) => onTaxIdChange(value)}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default injectIntl(TaxExemptToggle);
