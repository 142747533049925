import * as React from 'react';
import clsx from 'clsx';
import { Set } from 'immutable';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import UserActions from 'shared/actions/UserActions.jsx';
import UserPresentation from 'onboarding/components/UserPresentation.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import './styles.scss';

const onChange = (e, value, name) => {
  UserActions.updateStore([e?.target.name || name], value);
};

const onAddressChange = (e, value, name) => {
  UserActions.updateStore(['address', e?.target.name || name], value);
};

function AccountTab({ user, isLoading, isSaving, errors, info }) {
  const requiredDataIsMissing =
    !user.get('current_password') ||
    !user.get('password') ||
    !user.get('password_confirmation');

  const isPasswordValid =
    user.get('password') && errors.getErrors('password').length === 0;
  const isPasswordInValid =
    user.get('password') && errors.getErrors('password').length > 0;
  const isNewPasswordValid =
    user.get('password_confirmation') &&
    errors.getErrors('password_confirmation').length === 0;
  const isNewPasswordInValid =
    user.get('password_confirmation') &&
    errors.getErrors('password_confirmation').length > 0;

  return (
    <SpinWhileLoading
      className={clsx(
        'accounting-tab',
        isPasswordValid && 'accounting-tab__valid-password',
        isPasswordInValid && 'accounting-tab__invalid-password',
        isNewPasswordValid && 'accounting-tab__valid-new-password',
        isNewPasswordInValid && 'accounting-tab__invalid-new-password'
      )}
      isLoading={isLoading}
      spinWhile="isLoading"
    >
      <UserPresentation
        handleAddressChange={onAddressChange}
        handleUserProfileChange={onChange}
        errors={info}
        userProfile={user}
        positionActionsRight
        hideFields={
          currentUser().isClient()
            ? Set([
                'ImageUpload',
                'FirstName',
                'LastName',
                'DateOfBirth',
                'Address',
                'PhoneNumber',
              ])
            : Set()
        }
        actions={
          <StateChangingButton
            disabled={!errors.isEmpty() || requiredDataIsMissing}
            inProgress={isSaving}
            onClick={() => UserActions.update()}
          />
        }
        justifyForm="flex-start"
      />
    </SpinWhileLoading>
  );
}

export default AccountTab;
