import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Grid, Typography } from '@upperhand/playmaker';
import Button from '@mui/material/Button';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { Balance } from 'records';
import Client from 'shared/records/Client.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';

function BalanceInfo({ balance, client, intl, total, paid }) {
  return (
    <div className="balance-info">
      <Grid className="balance-info__header" container spacing={1}>
        <Grid item xs={12}>
          <div className="balance-info__title">
            <Typography align="center" variant="body1">
              {t('.header', intl, __filenamespace)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            <FormattedCurrency
              value={Math.abs(balance.get('balanceRemaining') || 0)}
              fromCents
            />
          </Typography>
        </Grid>
      </Grid>
      <Button
        fullWidth
        disableRipple
        disableElevation
        disableFocusRipple
        className="balance-info__client-details"
        color="dark"
        onClick={() => {
          BalanceListDrawerActions.closeDrawer();
          ClientProfileDrawerActions.mounted({ clientId: client.id });
        }}
      >
        <ClientDetails client={client} />
      </Button>

      <div className="info__card">
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Typography className="info__title" variant="subtitle2">
              {t('.title', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_date', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {t('.date', intl, __filenamespace, {
                  date: balance.get('effectiveAt')
                    ? moment(balance.get('effectiveAt')).format('MMM DD, YYYY')
                    : '-',
                  time: balance.get('effectiveAt')
                    ? moment(balance.get('effectiveAt')).format('h:mm a')
                    : '-',
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_total', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                <FormattedCurrency value={total} fromCents />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.title_paid', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                <FormattedCurrency value={paid || 0} fromCents />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.quantity', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {balance.get('quantity')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

BalanceInfo.propTypes = {
  balance: PropTypes.instanceOf(Balance),
  client: PropTypes.instanceOf(Client),
  intl: PropTypes.object.isRequired,
};

BalanceInfo.defaultProps = {
  balance: null,
  client: null,
};

export default BalanceInfo;
