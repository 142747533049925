import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, InfiniteScroller } from '@upperhand/playmaker';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import altContainer from 'shared/hocs/altContainer.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import {
  dateRange,
  formatClientTime,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { l } from 'shared/utils/LocaleUtils.js';
import { SessionDataStore } from 'dataStores';
import { currentCustomer } from 'shared/utils/CustomerUtils';

const styles = {
  root: {
    marginBottom: '1rem',
    cursor: 'pointer',
  },
  icon: {
    height: 20,
    width: 20,
  },
  header: {
    marginBottom: 4,
    fontWeight: 'bold',
  },
  divider: {
    height: 2,
  },
};

function DaytimesExpander({
  sessionIds,
  sessionsHasMore,
  sessionsLoading,
  sessionDataStore: { sessions, pagination },
  intl,
  style,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const toggle = () => setExpanded(prevExpanded => !prevExpanded);

  if (!sessionIds || !sessionIds.size) {
    return null;
  }

  const sortedSessions = sessionIds
    .map(id => sessions.get(id))
    .filter(s => s && s.starts_at)
    .sortBy(s => s.starts_at);

  if (sortedSessions.isEmpty()) {
    return null;
  }

  const sessionsCount = pagination.get('totalCount', sessionIds.size);

  return (
    <div
      role="presentation"
      onClick={toggle}
      style={{ ...styles.root, ...style }}
    >
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <div style={styles.header}>
              {dateRange(
                sortedSessions.first().starts_at,
                sortedSessions.last().starts_at
              )}
            </div>
            <div>{sessionsCount} Sessions</div>
          </Grid>
          <Grid item>
            <IconButton iconStyle={styles.icon} style={styles.icon}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider style={styles.divider} />
        </Grid>

        {expanded && (
          <InfiniteScroller
            showLoader
            hasMore={sessionsHasMore}
            onScroll={() =>
              EventActions.loadMoreSessions.defer({
                start_time: new Date().toISOString(),
              })
            }
            isLoading={sessionsLoading}
          >
            {sortedSessions.map(session => (
              <Grid container spacing={2} key={session.id}>
                <Grid container item xs={12} justify="space-between">
                  <Grid item>{l(session.starts_at, 'dates.medium', intl)}</Grid>
                  <Grid item>
                    {formatClientTime(
                      session.starts_at,
                      'h:mma',
                      currentCustomer().tz_name
                    )}
                    -
                    {formatClientTime(
                      session.ends_at,
                      'h:mma z',
                      currentCustomer().tz_name
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={styles.divider} />
                </Grid>
              </Grid>
            ))}
          </InfiniteScroller>
        )}
      </Grid>
    </div>
  );
}

export default altContainer({
  stores: {
    sessionDataStore: SessionDataStore,
  },
})(injectIntl(DaytimesExpander));
