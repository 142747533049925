import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Typography, Button } from '@upperhand/playmaker';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';

import './styles.scss';

function AccountCreditsForm({
  intl,
  item,
  appliedAccountCredits,
  clientDataStore,
  onApply,
}) {
  const [amount, setAmount] = useState(0);

  const { account_credit_amount: appliedCreditsAmount, buyer_id: clientId } =
    item;
  const { clients } = clientDataStore;
  const client = clients.get(clientId);
  const availableAmount =
    (client?.get('total_account_credit') || 0) + appliedAccountCredits;
  const disableApplying = amount > item.total || amount > availableAmount;

  const handleAmountChange = (e, value) => {
    e.preventDefault();
    setAmount(value > 0 ? value : 0);
  };

  const handleApply = () => {
    const updatedItem = item.set('account_credit_amount', amount);

    onApply({ item: updatedItem });
  };

  if (appliedCreditsAmount > 0) {
    return (
      <div className="account-credit-applied">
        <div className="account-credit-applied__amount">
          <FormattedCurrency fromCents value={appliedCreditsAmount} />
          &nbsp;
          {t('.account_credit', intl, __filenamespace)}
        </div>
        <div className="account-credit-applied__price">
          <FormattedCurrency fromCents value={item.total} />
        </div>
      </div>
    );
  }

  return (
    <Grid container className="account-credit-form">
      <Grid item container xs={12} justify="space-between">
        <Grid item container xs={8} alignItems="flex-end">
          <Typography variant="body1">
            {t('.account_credit', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CurrencyTextField
            fullWidth
            variant="standard"
            converter={e => parseInt(e, 10)}
            disabled={!availableAmount}
            value={amount}
            onChange={handleAmountChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">
          {t('.amount_available', intl, __filenamespace)}
          &nbsp;
          <FormattedCurrency fromCents value={availableAmount} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          classes={{ root: 'account-credit-form__apply-btn' }}
          disabled={disableApplying}
          onClick={handleApply}
        >
          {t('.apply_credit', intl, __filenamespace)}
        </Button>
      </Grid>
    </Grid>
  );
}

AccountCreditsForm.propTypes = {
  intl: PropTypes.object,
  item: PropTypes.object,
  appliedAccountCredits: PropTypes.number,
  clientDataStore: PropTypes.object,
  onApply: PropTypes.func,
};

AccountCreditsForm.defaultProps = {
  intl: {},
  item: {
    account_credit_amount: 0,
  },
  appliedAccountCredits: 0,
  clientDataStore: {},
  onApply: () => null,
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
    },
  })
)(AccountCreditsForm);
