import { createIntl } from 'react-intl';

import localeMessages from 'locales/messages.js';
import { isTexasTwelveTheme } from 'shared/utils/ThemeUtils';

const intl = createIntl({ locale: 'en-US', messages: localeMessages['en-US'] });

const sportTypeKeys = [
  'shared.constants.sportTypes.after_school_sports',
  'shared.constants.sportTypes.archery',
  'shared.constants.sportTypes.badminton',
  'shared.constants.sportTypes.baseball',
  'shared.constants.sportTypes.baseball_t_ball',
  'shared.constants.sportTypes.basketball',
  'shared.constants.sportTypes.bowling',
  'shared.constants.sportTypes.cheerleading',
  'shared.constants.sportTypes.crew_rowing',
  'shared.constants.sportTypes.cross_country',
  'shared.constants.sportTypes.curling',
  'shared.constants.sportTypes.cricket',
  'shared.constants.sportTypes.dance',
  'shared.constants.sportTypes.diving',
  'shared.constants.sportTypes.fencing',
  'shared.constants.sportTypes.field_hockey',
  'shared.constants.sportTypes.figure_skating',
  'shared.constants.sportTypes.flag_football',
  'shared.constants.sportTypes.floor_hockey_deck_hockey',
  'shared.constants.sportTypes.football',
  'shared.constants.sportTypes.golf',
  'shared.constants.sportTypes.gymnastics',
  'shared.constants.sportTypes.hockey',
  'shared.constants.sportTypes.horseback_riding',
  'shared.constants.sportTypes.lacrosse',
  'shared.constants.sportTypes.martial_arts',
  'shared.constants.sportTypes.multi_sport',
  'shared.constants.sportTypes.pickleball',
  'shared.constants.sportTypes.polo',
  'shared.constants.sportTypes.preschool_sports',
  'shared.constants.sportTypes.rugby',
  'shared.constants.sportTypes.sailing',
  'shared.constants.sportTypes.ski_club',
  'shared.constants.sportTypes.soccer',
  'shared.constants.sportTypes.softball',
  isTexasTwelveTheme() &&
    'shared.constants.sportTypes.strength_and_conditioning',
  'shared.constants.sportTypes.swimming',
  'shared.constants.sportTypes.tennis',
  'shared.constants.sportTypes.track_and_field',
  'shared.constants.sportTypes.volleyball',
  'shared.constants.sportTypes.weightlifting',
  'shared.constants.sportTypes.wrestling',
  'shared.constants.sportTypes.other',
];

const sportTypes = sportTypeKeys.reduce((acc, key) => {
  if (key) {
    acc.push({
      label: intl.formatMessage({ id: key }),
      value: intl.formatMessage({ id: key }),
    });
  }
  return acc;
}, []);

export default sportTypes;
