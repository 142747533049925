import { List } from 'immutable';
import Membership from 'shared/records/Membership.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSMembershipActions from 'point_of_sale/actions/POSMembershipActions.jsx';
import POSMembershipRegistrationActions from 'point_of_sale/actions/POSMembershipRegistrationActions.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { MembershipTierSource } from 'sources';

class POSMembershipStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      selectMembership: POSProductListActions.MEMBERSHIP_SELECTED,

      loadOrderItemMembership: [
        POSCartActions.ITEM_EDIT_SELECTED,
        POSCartActions.ITEM_VIEW_SELECTED,
      ],

      fetchMembershipSuccess: POSMembershipActions.FETCH_SUCCESS,
      fetchMembershipError: POSMembershipActions.FETCH_ERROR,

      listTiersSuccess: POSMembershipActions.listTiersSuccess,
      listTiersError: POSMembershipActions.listTiersError,

      reset: [
        POSMembershipRegistrationActions.CANCEL_CLICKED,
        POSMembershipRegistrationActions.VIEW_IN_CART_CLICKED,
      ],
    });
  }

  reset() {
    this.membership = new Membership();
    this.membershipTiers = List();
  }

  selectMembership(membership) {
    this.membership = membership;

    if (membership.tiered) {
      MembershipTierSource.list({
        params: {
          membership_id: membership.id,
          per_page: 50,
          fields: ['events', 'event_types'],
        },
        success: POSMembershipActions.listTiersSuccess,
        error: POSMembershipActions.listTiersError,
      });
    }
  }

  loadOrderItemMembership(orderItem) {
    if (orderItem.isMembershipItem()) {
      this.fetchMembership(orderItem.orderable.membership_id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  fetchMembership(id) {
    uhApiClient.get({
      url: `memberships/${id}`,
      data: { fields: ['events', 'event_types', 'has_embed_product'] },
      success: POSMembershipActions.fetchSuccess,
      error: POSMembershipActions.fetchError,
    });
  }

  fetchMembershipSuccess(data) {
    this.selectMembership(new Membership(data));
  }

  fetchMembershipError(args) {
    this.notifyError('error fetching membership', ...args);
  }

  listTiersSuccess({ membership_tiers: tiers }) {
    this.membershipTiers = List(tiers);
  }

  listTiersError(args) {
    this.notifyError('error listing membership tiers', ...args);
  }
}

export default alt.createStore(POSMembershipStore, 'POSMembershipStore');
