import * as React from 'react';

import PurchaseDrawer from 'containers/purchaseDrawer/PurchaseDrawer.jsx';
import SchedulingDrawer from 'shared/components/SchedulingDrawer.jsx';
import WaitlistDrawer from 'containers/waitlistDrawer/WaitlistDrawer.jsx';

import BrowseEventsWrapped from './BrowseEventsWrapped.jsx';

import './styles.scss';

function BrowseEvents() {
  return (
    <>
      <BrowseEventsWrapped />
      <PurchaseDrawer />
      <SchedulingDrawer />
      <WaitlistDrawer />
    </>
  );
}

export default BrowseEvents;
