import * as React from 'react';
import { Grid } from '@upperhand/playmaker';

import SessionCard from './SessionCard.jsx';

function SessionListing({ clientId, sessionIds, selectedSessions, onSelect }) {
  const sessionCards = sessionIds
    .map(id => (
      <Grid key={id} item xs={6}>
        <SessionCard
          sessionId={id}
          selectedSessions={selectedSessions}
          clientId={clientId}
          onSelect={onSelect}
        />
      </Grid>
    ))
    .toList();

  if (sessionCards.size < 1) {
    return null;
  }

  return (
    <Grid container alignItems="center" xs={12} spacing={1}>
      {sessionCards}
    </Grid>
  );
}

export default SessionListing;
