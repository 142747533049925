import { Record } from 'immutable';

const ABSOLUTE = 'amount';
const PERCENT = 'percent';
const VALID_TYPES = [ABSOLUTE, PERCENT];

class Discount extends Record({
  type: 'percent',
  value: 0,
}) {
  static get ABSOLUTE() {
    return ABSOLUTE;
  }

  static get PERCENT() {
    return PERCENT;
  }

  applyTo(amount) {
    if (this.isAbsolute()) {
      return Math.max(amount - this.value, 0);
    }

    if (this.isPercent()) {
      return Math.max(amount - this.percentOff(amount), 0);
    }

    return amount;
  }

  discountFor(amount) {
    if (this.isAbsolute()) {
      return Math.min(this.value, amount);
    }

    if (this.isPercent()) {
      return Math.min(this.percentOff(amount), amount);
    }

    return 0;
  }

  isAbsolute() {
    return this.type === ABSOLUTE;
  }

  isPercent() {
    return this.type === PERCENT;
  }

  isValid() {
    // TODO: Should we check (value > 0) here as well?
    return VALID_TYPES.includes(this.type);
  }

  percentOff(price) {
    return price * (this.value / 100);
  }
}

export default Discount;
