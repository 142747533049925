import * as React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import UnsignedWaiverIcon from 'shared/components/icons/UnsignedWaiver.jsx';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors, uhContrast } from 'shared/styles/uhStyles.jsx';

import POSActions from 'point_of_sale/actions/POSActions.jsx';

const styles = {
  header: merge(uhContrast.lightOnDark, {
    fontSize: 15,
  }),
  phoneNumber: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  waiveIcon: {
    fontSize: '1.1rem',
  },
};

const drawerDismissed = () => POSActions.drawerDismissed();

function Header({
  children,
  onClickBack,
  processingPayment = false,
  selectedClient,
}) {
  const showWaiver =
    !selectedClient.has_accepted_terms && selectedClient.login_enabled;

  return (
    <div style={styles.header}>
      <FlexBoxCenter style={{ height: 56 }}>
        <FlexBoxCenter>
          <IconButton
            // TODO: make sure this can't dismiss early
            disabled={processingPayment}
            onClick={e =>
              onClickBack ? onClickBack(e) : POSActions.clientBackClicked()
            }
          >
            <ChevronLeftIcon sx={{ color: uhColors.activeBlue }} />
          </IconButton>

          <UserAvatar user={selectedClient} darkOnLight />
          <Stack>
            <Typography>{selectedClient.name()}</Typography>
            <Typography variant="body2" component="div" sx={styles.phoneNumber}>
              {selectedClient.phoneNumber()}
              {showWaiver && (
                <UnsignedWaiverIcon
                  fillColor="var(--color-unpaid-red)"
                  style={styles.waiveIcon}
                />
              )}
            </Typography>
          </Stack>
        </FlexBoxCenter>
        <IconButton
          id="pos-dismiss-button"
          onClick={drawerDismissed}
          style={{ marginLeft: 'auto', padding: 15 }}
        >
          <CloseIcon sx={{ color: '#5B6A72' }} />
        </IconButton>
      </FlexBoxCenter>
      <div id="pos-header-container" style={{ padding: '0 20px' }}>
        {children}
      </div>
    </div>
  );
}

export default Header;
