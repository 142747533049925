import * as React from 'react';
import { singleColumnWidthNumeric, windowWidth } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';

export const FlexBox = ({ children, style, ...otherProps }) => (
  <div {...otherProps} style={merge(style, { display: 'flex' })}>
    {children}
  </div>
);

export const FlexBoxCenter = ({ children, style, ...otherProps }) => (
  <FlexBox {...otherProps} style={merge(style, { alignItems: 'center' })}>
    {children}
  </FlexBox>
);

export const FlexBoxJustify = ({ children, style, ...otherProps }) => (
  <FlexBox
    {...otherProps}
    style={merge(style, { justifyContent: 'space-between' })}
  >
    {children}
  </FlexBox>
);

export const FlexBoxJustifyCenter = ({ children, style, ...otherProps }) => (
  <FlexBox {...otherProps} style={merge(style, { justifyContent: 'center' })}>
    {children}
  </FlexBox>
);

/*
 * Note: if we want to get more flexible with columns, min/max widths,
 *       we should use a Grid system (preferably one based on Flexbox,
 *       like http://flexboxgrid.com).
 */
export const FlexBoxColumnLayout = props => {
  let { children, style, childrenStyle, ...otherProps } = props;
  const parentStyle = merge(
    { justifyContent: 'center', flexWrap: 'wrap' },
    style
  );
  const childrenStyleWithDefaults = merge(
    {
      minWidth: Math.min(singleColumnWidthNumeric(), windowWidth()),
      margin: '0px 12.5px',
      flex: '1 0 auto',
    },
    childrenStyle
  );
  if (!children.map) {
    /* This happens if a single element is passed as the child/children */
    children = [children];
  }
  return (
    <FlexBox {...otherProps} style={parentStyle}>
      {children.map((child, index) => {
        const childStyle = child && child.props && child.props.style;
        const childStyleProps = merge(childrenStyleWithDefaults, childStyle);
        return React.cloneElement(child, {
          key: `{fbcl-${index}`,
          style: childStyleProps,
        });
      })}
    </FlexBox>
  );
};

export default FlexBox;
