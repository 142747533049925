import React from 'react';
import { injectIntl } from 'react-intl';
import { Drawer, Button } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import {
  BalancesDataStore,
  ClientDataStore,
  EventDataStore,
  EventTypeDataStore,
  PaymentPlanDataStore,
  ScheduledPaymentDataStore,
} from 'dataStores';
import { Balance } from 'records';

import Content from './components/Content.jsx';
import PaymentPlanDetailsDrawerActions from './Actions';
import PaymentPlanDetailsDrawerStore from './Store.js';

import './styles.scss';

function PaymentPlanDetailsDrawer({
  paymentPlanDetailsDrawerStore: {
    paymentPlanId,
    dotsMenuAnchorEl,
    isLoading,
    scheduledPaymentIds,
    eventIds,
    eventTypeIds,
    balanceIds,
  },
  paymentPlanDataStore: { records },
  scheduledPaymentDataStore: { scheduledPayments: scheduledPaymentsData },
  balancesDataStore: { balances: balancesData },
  clientDataStore: { clients },
  eventDataStore: { events: eventsData },
  eventTypeDataStore: { eventTypes: eventTypesData },
  intl,
  withBackLink,
}) {
  const paymentPlan = records.get(paymentPlanId);
  const showDotMenu = paymentPlan?.remainingAmount !== 0;
  const client = clients.get(paymentPlan?.clientId);
  const scheduledPayments = scheduledPaymentIds.map(id =>
    scheduledPaymentsData.get(id)
  );
  const allPaymentsAreCancelled = scheduledPayments
    .filter(payment => !payment.isCompleted)
    .every(payment => payment.isCancelled);
  const events = eventIds.map(id => eventsData.get(id));
  const eventTypes = eventTypeIds.map(id => eventTypesData.get(id));
  const balances = balanceIds.map(compoundId => balancesData.get(compoundId));
  const title = withBackLink ? (
    <div className="back-btn">
      <Button
        onClick={PaymentPlanDetailsDrawerActions.closeDrawer}
        icon="arrowLeft"
        type="tertiary"
        rounded
        classes={{ root: 'back-btn__btn', label: 'back-btn__label' }}
      />
      <div className="back-btn__label">
        {t('.title', intl, __filenamespace)}
      </div>
    </div>
  ) : (
    t('.title', intl, __filenamespace)
  );

  const pseudoBalance = new Balance(
    isLoading
      ? {}
      : {
          clientId: client?.id,
          client: client?.name(),
          effectiveAt: paymentPlan?.effectiveAt,
          balanceRemaining: paymentPlan?.remainingAmount,
          paid:
            (paymentPlan?.amount ?? 0) - (paymentPlan?.remainingAmount ?? 0),
          paymentType: 'payment_plan',
          product: 'Event',
          productId: paymentPlan?.id,
          productType: '',
        }
  );
  return (
    <Drawer
      classes={{
        root: 'payment-plan-details-drawer',
      }}
      open={!!paymentPlanId}
      onClose={PaymentPlanDetailsDrawerActions.closeAllDrawers}
      headerType="light"
      title={title}
      actions={
        showDotMenu
          ? [
              {
                icon: 'threeDots',
                onClick: PaymentPlanDetailsDrawerActions.toggleDotsMenu,
              },
            ]
          : []
      }
      content={
        <Content
          isLoading={isLoading}
          paymentPlan={paymentPlan}
          client={client}
          scheduledPayments={scheduledPayments}
          events={events}
          eventTypes={eventTypes}
          intl={intl}
          dotsMenuAnchorEl={dotsMenuAnchorEl}
          pseudoBalance={pseudoBalance}
          balances={balances}
          allPaymentsAreCancelled={allPaymentsAreCancelled}
        />
      }
    />
  );
}

export default compose(
  altContainer({
    stores: {
      balancesDataStore: BalancesDataStore,
      clientDataStore: ClientDataStore,
      paymentPlanDetailsDrawerStore: PaymentPlanDetailsDrawerStore,
      paymentPlanDataStore: PaymentPlanDataStore,
      scheduledPaymentDataStore: ScheduledPaymentDataStore,
      eventDataStore: EventDataStore,
      eventTypeDataStore: EventTypeDataStore,
    },
  }),
  injectIntl
)(PaymentPlanDetailsDrawer);
