import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Confirmation, Grid, Typography } from '@upperhand/playmaker';
import ReactTooltip from 'react-tooltip';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { grey } from '@mui/material/colors';

import AutomationDetailsCard from 'automations/display/components/AutomationDetailsCard.jsx';
import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import FeePlan from 'event_mgmt/shared/records/FeePlan.jsx';
import FeePlanStore from 'event_mgmt/shared/stores/FeePlanStore.jsx';
import FeePlanActions from 'event_mgmt/shared/actions/FeePlanActions.jsx';

import PriceFields from 'event_mgmt/shared/components/_PricingFields.jsx';
import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';
import PricingUtils from 'event_mgmt/shared/utils/PricingUtils.jsx';
import AutomationEditDrawer from 'automations/editing/components/AutomationEditDrawer.jsx';
import AutomationListDrawer from 'event_mgmt/editing/components/AutomationListDrawer.jsx';
import SingleSessionPurchaseSection from 'event_mgmt/editing/components/_SingleSessionPurchaseSection.jsx';
import PricingScheduleSection from 'event_mgmt/editing/components/_PricingScheduleSection.jsx';
import WarningIcon from 'shared/components/icons/WarningIcon.jsx';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { SINGLE_COLUMN_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import uhTheme from '_uh_theme.jsx';

import './TabPricing.scss';

const styles = {
  automationDetailsNameHeader: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: uhTheme.palette.charcoalBlack,
    marginTop: '0.5em',
    marginBottom: '1.5em',
    paddingLeft: '0.5em',
  },

  automationDetailsOptionRow: {
    display: 'flex',
    padding: '0.25em 0.5em',
    flex: 'auto',
    justifyContent: 'space-between',
    fontWeight: 500,
  },

  automationDetailsOptionRowWithBackground: {
    backgroundColor: grey[100],
  },

  automationDetailsOptionRowBold: {
    color: uhTheme.palette.charcoalBlack,
    fontWeight: 600,
  },

  automationDetailsProcessingFeeNote: {
    color: grey[500],
    fontWeight: 500,
    fontSize: '1.2rem',
    marginTop: '1.5em',
  },

  automationDetailsSeparator: {
    width: '100%',
    margin: '',
    marginTop: '1em',
    marginBottom: '1.5em',
    marginLeft: '0px',
    marginRight: '0px',
  },

  automationInterface: {
    width: '34.5rem',
    backgroundColor: 'white',
    padding: '0.5em',
  },

  automationTemplateDescriptionButtonContainer: {
    alignItems: 'center',
    backgroundColor: uhTheme.palette.headerGrey,
    color: 'white',
    cursor: 'pointer',
    fontWeight: 600,
    height: 46,
    marginBottom: 8,
    padding: '0 3px 0 15px',
    width: 220,
  },

  automationTemplateDescriptionDeleteButton: {
    order: 2,
    padding: '0.25em 0em',
    marginTop: '0.5em',
    marginRight: 7,
    width: 20,
  },

  automationTemplateDescriptionEditButton: {
    order: 1,
    padding: '0.5em 1.5em',
    borderRight: '1px solid #4D5863',
    width: 185,
  },
  containerStyle: {
    flexWrap: smallScreen() ? 'wrap-reverse' : 'nowrap',
    maxWidth: '90%',
    margin: '0 -30px',
  },
  columnStyle: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    margin: '0 30px',
  },
  columnHeader: {
    fontWeight: 600,
    marginBottom: 4,
  },
  defaultPaymentContainer: {
    margin: '5px 0',
    color: uhColors.textGrey,
    width: 285,
    lineHeight: 1.5,
    fontSize: 15,
  },
};

class TabPricing extends React.PureComponent {
  state = {
    feePlan: new FeePlan(),
    automationListDrawerOpen: false,
    selectableAutomationTemplateDescriptions: List(),
    automationTemplateDescriptionsOnThisEvent: List(),
    allAutomationTemplateDescriptions: List(),
    priceFieldFocused: false,
  };

  /*
   * Life Cycle
   */
  componentDidMount() {
    // TODO[ZGOLDMAN:2017-05-10]: Remove manual store listeners.
    FeePlanStore.listen(this.onFeePlanStoreChange);
    AutomationTemplateDescriptionStore.listen(
      this.onAutomationTemplateDescriptionStoreChange
    );
    // TODO[ZGOLDMAN:2017-05-10]: Move to EventMgmtEditing, these can cause
    // double dispatch violations.
    FeePlanActions.fetch.defer({});
    AutomationTemplateDescriptionActions.list.defer({});
  }

  componentDidUpdate(prevProps, prevState) {
    const { customerEvent } = prevProps;
    const { customerEvent: nextCustomerEvent } = this.props;
    const {
      allAutomationTemplateDescriptions,
      saveAutomationAfterEventIsSaved,
    } = this.state;

    if (
      nextCustomerEvent.id &&
      customerEvent.id &&
      nextCustomerEvent.id !== customerEvent.id
    ) {
      AutomationTemplateDescriptionActions.list.defer({
        eventId: nextCustomerEvent.id,
      });
    }

    // TODO: We should probably be able to get rid of this and a lot of other complexity by removing
    //       state from this component and using stores and alt containers.
    if (customerEvent.isOpenBooking !== nextCustomerEvent.isOpenBooking()) {
      const eventType = customerEvent.get('schedule_type');
      const onThisEvent = allAutomationTemplateDescriptions.filter(
        atd =>
          atd.event_id !== null &&
          atd.event_id === customerEvent.id &&
          atd.supported_event_type === eventType
      );
      const selectedTemplateTypes = onThisEvent.map(atd => atd.template_type);
      const selectableForThisEvent = allAutomationTemplateDescriptions.filter(
        atd =>
          atd.supported_event_type === eventType &&
          atd.cloneable &&
          !selectedTemplateTypes.includes(atd.template_type) &&
          (atd.event_id !== customerEvent.id /* can't reselect it */ ||
            atd.event_id === null) /* it's not on any event */
      );
      const {
        selectableAutomationTemplateDescriptions: prevAutomations,
        automationTemplateDescriptionsOnThisEvent: prevOnThisEvent,
      } = prevState;

      if (selectableForThisEvent.size !== prevAutomations.size) {
        this.setState({
          selectableAutomationTemplateDescriptions: selectableForThisEvent,
        });
      }

      if (prevOnThisEvent.size !== onThisEvent.size) {
        this.setState({
          automationTemplateDescriptionsOnThisEvent: onThisEvent,
        });
      }
    }

    if (saveAutomationAfterEventIsSaved && !!nextCustomerEvent.id) {
      new Promise((resolve, _reject) => {
        resolve();
      }).then(() => this.handleDelayedAutomationSave(nextCustomerEvent.id));
    }
  }

  componentWillUnmount() {
    FeePlanStore.unlisten(this.onFeePlanStoreChange);
    AutomationTemplateDescriptionStore.unlisten(
      this.onAutomationTemplateDescriptionStoreChange
    );
  }

  /*
   * Helpers & Callbacks
   */

  onAutomationTemplateDescriptionStoreChange = store => {
    const { customerEvent } = this.props;
    const eventType = customerEvent.get('schedule_type');

    const onThisEvent = store.descriptions.filter(
      atd =>
        atd.event_id !== null &&
        atd.event_id === customerEvent.id &&
        atd.supported_event_type === eventType
    );

    const selectedTemplateTypes = onThisEvent.map(atd => atd.template_type);

    const selectableForThisEvent = store.descriptions.filter(
      atd =>
        atd.supported_event_type === eventType &&
        atd.cloneable &&
        !selectedTemplateTypes.includes(atd.template_type) &&
        (atd.event_id !== customerEvent.id /* can't reselect it */ ||
          atd.event_id === null) /* it's not on any event */
    );

    this.setState({
      selectableAutomationTemplateDescriptions: selectableForThisEvent,
      automationTemplateDescriptionsOnThisEvent: onThisEvent,
      allAutomationTemplateDescriptions: store.descriptions,
      selectedAutomationTemplateDescription: store.description,
    });
  };

  onFeePlanStoreChange = store => {
    this.setState({ feePlan: store.feePlan });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  getEventSchedule = () => {
    const { customerEvent } = this.props;

    return customerEvent.getIn(['schedules', 0]);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  getPrice = () => {
    const { customerEvent } = this.props;
    const { priceFieldFocused } = this.state;

    return priceFieldFocused
      ? customerEvent.price
      : PricingUtils.fixedDecimal(customerEvent.price);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  getProcessingFee = () => {
    const { customerEvent } = this.props;
    const { feePlan } = this.state;

    return PricingUtils.processingFee(customerEvent.price, feePlan);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  getTotalEarnings = () => {
    const { customerEvent } = this.props;
    const { feePlan } = this.state;

    return PricingUtils.fixedDecimal(
      PricingUtils.totalEarnings(customerEvent.price, feePlan)
    );
  };

  automations = () => {
    const { automationTemplateDescriptionsOnThisEvent } = this.state;
    return automationTemplateDescriptionsOnThisEvent;
  };

  doAutomationSave = (atd, eventId) => {
    if (!atd.event_id) {
      // eslint-disable-next-line no-param-reassign
      atd = atd.set('event_id', eventId);
      AutomationTemplateDescriptionActions.updateStoreKeypath(
        ['event_id'],
        eventId
      );
    }

    const { handleInputChange } = this.props;
    const pac = PricingAutomationCollection.create([atd]);
    handleInputChange({
      target: { value: false, name: 'freeEvent' },
    });
    handleInputChange({
      target: { value: pac.getMinimumPrice() / 100, name: 'price' },
    });

    if (atd.id) {
      AutomationTemplateDescriptionActions.update(atd.id);
    } else {
      AutomationTemplateDescriptionActions.create({});
    }

    AutomationTemplateDescriptionActions.list({ eventId });
  };

  handleAddAutomation = () => {
    this.setState({
      automationListDrawerOpen: true,
    });
  };

  handleAutomationCancel = () => {
    const { customerEvent } = this.props;

    this.setState({
      selectedAutomationTemplateDescription: false,
    });
    AutomationTemplateDescriptionActions.deselectCurrent({});
    AutomationTemplateDescriptionActions.list({
      eventId: customerEvent.id,
    });
  };

  handleAutomationSave = () => {
    const {
      saveAutomationAfterEventIsSaved,
      selectedAutomationTemplateDescription,
    } = this.state;
    const { customerEvent } = this.props;

    if (saveAutomationAfterEventIsSaved) {
      return; // wait to do this work until after event ID is set
    }

    let atd;
    if (selectedAutomationTemplateDescription.cloneable) {
      atd = selectedAutomationTemplateDescription
        .clone()
        .set('cloneable', false);
      AutomationTemplateDescriptionActions.updateStoreKeypath([], atd);
    } else {
      atd = selectedAutomationTemplateDescription;
    }

    if (!customerEvent.id) {
      this.setState({
        saveAutomationAfterEventIsSaved: true,
        preservedAutomation: atd,
      });
      EventActions.createOrUpdateServer();
    } else {
      this.doAutomationSave(atd, customerEvent.id);
    }
  };

  handleAutomationListDrawerChange = (isOpen, _reason) => {
    this.setState({
      automationListDrawerOpen: isOpen,
      selectedAutomationTemplateDescription: null,
    });
  };

  handleAutomationSelect = automationTemplateDescription => {
    this.setState({
      selectedAutomationTemplateDescription: automationTemplateDescription,
    });
    AutomationTemplateDescriptionActions.updateStore(
      automationTemplateDescription
    );
  };

  handleDelayedAutomationSave = eventId => {
    const { preservedAutomation } = this.state;

    const atd = preservedAutomation;
    this.setState({
      selectedAutomationTemplateDescription: atd,
      preservedAutomation: null,
      saveAutomationAfterEventIsSaved: false,
    });
    this.doAutomationSave(atd, eventId);
  };

  handleFreeEventCheck = event => {
    const { handleInputChange } = this.props;
    const { checked } = event.target;

    handleInputChange(event);

    if (checked) {
      handleInputChange({ target: { value: '0', name: 'price' } });
      handleInputChange({
        target: { value: '0', name: 'single_session_price' },
      });
      handleInputChange({ target: { value: '0', name: 'base_price' } });
    } else {
      handleInputChange({ target: { value: '', name: 'price' } });
      handleInputChange({
        target: { value: '', name: 'single_session_price' },
      });
      handleInputChange({ target: { value: '', name: 'base_price' } });
    }
  };

  isFreeEvent = () => {
    const { customerEvent } = this.props;

    return customerEvent.freeEvent;
  };

  removeAutomationTemplateDescription = id => {
    this.setState({
      automationListDrawerOpen: false,
      selectedAutomationTemplateDescription: null,
    });
    AutomationTemplateDescriptionActions.delete(id);
  };

  /*
   * Rendering
   */
  automationEditSection = () => {
    const { customerEvent, intl } = this.props;
    const {
      automationTemplateDescriptionsOnThisEvent,
      selectableAutomationTemplateDescriptions,
      automationListDrawerOpen,
      selectedAutomationTemplateDescription,
    } = this.state;
    const eventTitle = customerEvent?.title || 'Event Title';

    const buttonLabelStyle =
      customerEvent.allow_single_session_purchase ||
      customerEvent.enable_dynamic_pricing
        ? { color: uhColors.disabledGrey, fontWeight: 600 }
        : { color: uhTheme.palette.primary.main, fontWeight: 600 };

    return (
      <div>
        <p style={styles.columnHeader}>
          <FormattedMessage id={messageId('.automation', __filenamespace)} />
        </p>
        {!customerEvent.id && (
          <p style={{ marginBottom: 4, color: uhColors.warning }}>
            <FormattedMessage
              id={messageId('.add_warning', __filenamespace)}
              values={{ eventTitle }}
            />
          </p>
        )}
        <div>
          {automationTemplateDescriptionsOnThisEvent.map(
            this.automationPlacard
          )}
        </div>
        <div>
          <Button
            color="default"
            variant="contained"
            style={{
              width: 220,
              height: 46,
              marginBottom: 20,
              fontWeight: 600,
              ...buttonLabelStyle,
            }}
            onClick={this.handleAddAutomation}
            disabled={
              !selectableAutomationTemplateDescriptions.size ||
              customerEvent.allow_single_session_purchase ||
              customerEvent.enable_dynamic_pricing ||
              !customerEvent.id ||
              customerEvent.hasPriceScheduleChecked()
            }
          >
            {t('.add_automation', intl, __filenamespace)}
          </Button>
        </div>
        {customerEvent.allow_single_session_purchase && (
          <div
            style={{
              color: uhColors.grey,
              fontSize: 15,
              lineHeight: 1.5,
              width: 285,
            }}
          >
            {t('.single_session_automation_help_text', intl, __filenamespace)}
          </div>
        )}
        <AutomationListDrawer
          open={automationListDrawerOpen}
          onRequestChange={this.handleAutomationListDrawerChange}
          automationTemplateDescriptions={
            selectableAutomationTemplateDescriptions
          }
          selectedAutomationTemplateDescription={
            selectedAutomationTemplateDescription
          }
          onAutomationSelect={this.handleAutomationSelect}
          customerEvent={customerEvent}
        />
        {selectedAutomationTemplateDescription && (
          <AutomationEditDrawer
            open={!!selectedAutomationTemplateDescription}
            onListDrawerRequest={this.handleAutomationListDrawerChange}
            automationTemplateDescription={
              selectedAutomationTemplateDescription
            }
            onSave={this.handleAutomationSave}
            onCancel={this.handleAutomationCancel}
            canEditMore={!selectableAutomationTemplateDescriptions.isEmpty()}
          />
        )}
      </div>
    );
  };

  automationInterface = () => {
    const { feePlan } = this.state;
    const automations = this.automations().isEmpty()
      ? this.selectedAtdList()
      : this.automations();

    return (
      <div style={styles.columnStyle}>
        {automations.map(atd => (
          <AutomationDetailsCard atd={atd} feePlan={feePlan} />
        ))}
      </div>
    );
  };

  automationPlacard = automation => {
    const { allAutomationTemplateDescriptions } = this.state;

    const onNameClick = () => {
      const atd = allAutomationTemplateDescriptions.find(
        each => each.id === automation.id
      );
      AutomationTemplateDescriptionActions.updateStoreKeypath([], atd);
    };

    const onClearClick = event => {
      // Don't let the event bubble up to the FlexBox, which also has a click handler.
      event.stopPropagation();
      this.removeAutomationTemplateDescription(automation.id);
    };

    return (
      <FlexBoxJustify
        style={styles.automationTemplateDescriptionButtonContainer}
        onClick={onNameClick}
      >
        <div>{automation.name}</div>
        <IconButton
          style={{
            width: 36,
            height: 36,
            padding: 9,
          }}
          onClick={onClearClick}
        >
          <CloseIcon style={{ height: 18, width: 18, color: grey[500] }} />
        </IconButton>
      </FlexBoxJustify>
    );
  };

  pricingInterface = () => {
    if (!this.automations().isEmpty()) {
      return this.automationInterface();
    }
    return this.simplePricingInterface();
  };

  simplePricingInterface = () => {
    const { intl, customerEvent, fieldErrors, handleInputChange } = this.props;
    const { feePlan } = this.state;

    return (
      <div style={styles.columnStyle}>
        <FormControlLabel
          name="freeEvent"
          control={<Checkbox />}
          label={t('.free_event', intl, __filenamespace)}
          checked={this.isFreeEvent()}
          onChange={this.handleFreeEventCheck}
        />
        <PriceFields
          isFree={this.isFreeEvent()}
          fieldName="base_price"
          value={customerEvent.get('base_price')}
          feePlan={feePlan}
          errorText={
            !this.isFreeEvent()
              ? fieldErrors.get('base_price', []).join(',')
              : ''
          }
          handleInputChange={handleInputChange}
        />
        {customerEvent.isFixedSchedule() &&
          !customerEvent.allow_waitlist &&
          !customerEvent.enable_dynamic_pricing && (
            <SingleSessionPurchaseSection
              customerEvent={customerEvent}
              withConfirmation={!!customerEvent.id}
              feePlan={feePlan}
              fieldErrors={fieldErrors}
              handleInputChange={handleInputChange}
              isFree={this.isFreeEvent()}
            />
          )}
        {customerEvent.isFixedSchedule() &&
          !customerEvent.allow_single_session_purchase && (
            <div style={{ marginTop: '10px' }}>
              <ListItem style={{ padding: 0 }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <div>
                      <FormattedMessage
                        id={messageId(
                          '.enable_dynamic_pricing',
                          __filenamespace
                        )}
                      />
                      <InfoIcon
                        data-tip
                        data-for="dynamic-pricing-help"
                        style={{
                          height: 20,
                          width: 20,
                          position: 'absolute',
                          right: 0,
                          zIndex: 999,
                        }}
                      />
                      <ReactTooltip
                        id="dynamic-pricing-help"
                        effect="solid"
                        style={{ maxWidth: 240 }}
                        className="charge-failure-tooltip uh-tooltip"
                      >
                        <FormattedMessage
                          id={messageId(
                            '.enable_dynamic_pricing_help_text',
                            __filenamespace
                          )}
                        />
                      </ReactTooltip>
                    </div>
                  }
                  name="enable_dynamic_pricing"
                  disabled={
                    customerEvent.schedules.first()?.registration_deadline ||
                    customerEvent.hasPriceScheduleChecked()
                  }
                  checked={customerEvent.get('enable_dynamic_pricing')}
                  onChange={handleInputChange}
                />
              </ListItem>
            </div>
          )}
      </div>
    );
  };

  pricingInterfaceSchedule = () => {
    const { automationTemplateDescriptionsOnThisEvent } = this.state;
    const { customerEvent, fieldErrors, handleInputChange } = this.props;
    const { feePlan } = this.state;

    return (
      <PricingScheduleSection
        customerEvent={customerEvent}
        withConfirmation={!!customerEvent.id}
        feePlan={feePlan}
        fieldErrors={fieldErrors}
        handleInputChange={handleInputChange}
        isFree={this.isFreeEvent()}
        hasAutomation={automationTemplateDescriptionsOnThisEvent.size > 0}
      />
    );
  };

  defaultPaymentMethod = () => {
    const { intl, customerEvent, handleInputChange, fieldErrors } = this.props;
    const paymentMethods = customerEvent.get('payment_methods');
    const methods = paymentMethods
      .map((m, index) => ({
        type: index,
        isEnabled: m,
      }))
      .toArray();

    const handleChange = (e, checked) => {
      handleInputChange(e, checked, ['payment_methods', e.target.name]);
    };

    return (
      <div style={{ margin: '10px 0' }}>
        <p style={styles.columnHeader}>
          {t('.payment_methods', intl, __filenamespace)}
        </p>
        <p style={styles.defaultPaymentContainer}>
          {t('.info', intl, __filenamespace)}
        </p>
        <Stack>
          {methods.map(method => (
            <FormControlLabel
              key={method.type}
              name={method.type}
              label={t(`.${method.type}`, intl, __filenamespace)}
              checked={method.isEnabled}
              control={<Checkbox />}
              onChange={handleChange}
            />
          ))}
        </Stack>
        <Typography variant="error">
          {fieldErrors.get('payment_methods')}
        </Typography>
      </div>
    );
  };

  selectedAtdList() {
    const { selectedAutomationTemplateDescription } = this.state;
    return selectedAutomationTemplateDescription
      ? List([selectedAutomationTemplateDescription])
      : List();
  }

  render() {
    const { intl, customerEvent, confirmSSPDialogOpened } = this.props;

    return (
      <>
        <FlexBox style={styles.containerStyle}>
          {this.pricingInterface()}
          <div style={styles.columnStyle}>
            {this.automationEditSection()}
            {this.defaultPaymentMethod()}
          </div>
          <div style={styles.columnStyle}>
            {!customerEvent.isOpenBooking() && this.pricingInterfaceSchedule()}
          </div>
        </FlexBox>
        {customerEvent.id && customerEvent.isFixedSchedule() && (
          <Confirmation
            onConfirm={() => {
              EventActions.toggleSSPConfirmation(false);
              EventActions.updateEventStore(
                ['allow_single_session_purchase'],
                false
              );
            }}
            open={confirmSSPDialogOpened}
            level="warning"
            onCancel={() => EventActions.toggleSSPConfirmation(false)}
            cancelButtonLabel={t('.ssp_warning_no', intl, __filenamespace)}
            confirmButtonLabel={t('.ssp_warning_yes', intl, __filenamespace)}
            title={
              <Typography variant="dialogHeader">
                <WarningIcon width={20} height={18} />
                <span className="ssp-confirmation-modal__title">
                  {t('.ssp_warning_title', intl, __filenamespace)}
                </span>
              </Typography>
            }
            hasCheckbox={false}
          >
            <Grid container className="ssp-confirmation-modal__container">
              {t('.ssp_warning_text_1', intl, __filenamespace)}
            </Grid>
            <Grid container className="ssp-confirmation-modal__container">
              {t('.ssp_warning_text_2', intl, __filenamespace)}
            </Grid>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body2" component="strong">
                  {t('.ssp_warning_note_title', intl, __filenamespace)}
                </Typography>
                <Typography variant="body2" color="secondary" component="span">
                  {t('.ssp_warning_note_subtitle', intl, __filenamespace)}
                </Typography>
              </Grid>
            </Grid>
          </Confirmation>
        )}
      </>
    );
  }
}

export default injectIntl(TabPricing);
