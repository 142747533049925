import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import RevenueReportStore from 'reporting/revenue/stores/_RevenueReportStore.jsx';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { l, t } from 'shared/utils/LocaleUtils.js';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

function formatDate(intl, value) {
  const year = Math.floor(value / 12);
  const month = value % 12;
  const date = new Date(year, month - 1, 10);
  return l(date, '.dateFormat', intl, __filenamespace);
}

class TransactionsByMonth extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { revenueReportStore } = this.props;
    const current = revenueReportStore;
    const next = nextProps.revenueReportStore;
    const statusChanged =
      current.isTotalsByMonthLoading !== next.isTotalsByMonthLoading;
    const resultChanged =
      current.transactionCountsByMonth !== next.transactionCountsByMonth;
    return statusChanged || resultChanged;
  }

  getDatasets() {
    const { revenueReportStore: store } = this.props;
    return [
      {
        fill: false,
        lineTension: 0.1,
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: 'rgba(75,192,192,0.4)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: store.transactionCountsByMonth,
      },
    ];
  }

  getChartOptions() {
    const { intl } = this.props;
    return {
      scales: {
        x: {
          ticks: {
            // Include a dollar sign in the ticks
            callback(value) {
              return formatDate(intl, value);
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: context =>
              `${formatDate(intl, context.parsed.x)}: ${context.parsed.y}`,
          },
        },
      },
    };
  }

  render() {
    const { revenueReportStore: store, intl } = this.props;
    const data = { datasets: this.getDatasets() };
    const options = this.getChartOptions();

    return (
      <Widget
        title={t('.title', intl, __filenamespace)}
        style={{ width: '100%', overflowX: 'auto' }}
        isLoading={store.isTotalsByMonthLoading}
      >
        <div style={{ position: 'relative' }}>
          <Bubble data={data} options={options} width={100} height={50} />
        </div>
      </Widget>
    );
  }
}

function TransactionsByMonthWrapper({ intl }) {
  return (
    <AltContainer stores={{ revenueReportStore: RevenueReportStore }}>
      <TransactionsByMonth intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(TransactionsByMonthWrapper);
