import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  StatusBar: statusColor => ({
    padding: '0 8px',
    backgroundColor: statusColor,
    color: 'white',
    fontWeight: 600,
    fontSize: '13px',
    transition: 'all 240ms ease-in-out',
    transitionProperty: 'height, color',
  }),
};

function StatusBar({
  eventTime,
  clients,
  statusColor,
  interacting,
  isEventCancelled,
}) {
  const allPaid = eventTime.isAllPaid(clients);
  const allCheckedIn = eventTime.isAllCheckedIn(clients);

  let height = '0px';
  let sanitizedStatusColor = statusColor;

  if (allCheckedIn && interacting) {
    height = '22px';
  } else if (allCheckedIn) {
    height = '3px';
  }

  if (!allCheckedIn) sanitizedStatusColor = uhColors.hint;
  if (isEventCancelled) {
    sanitizedStatusColor = uhColors.warning;
    height = 3;
  }

  return (
    <FlexBoxCenter
      style={{
        height,
        ...styles.StatusBar(sanitizedStatusColor),
      }}
    >
      {allCheckedIn && interacting ? (
        <FormattedMessage
          id={messageId(
            allPaid ? '.all_paid' : '.n_unpaid_attendees',
            __filenamespace
          )}
          values={{ n: eventTime.unpaidClients().size }}
        />
      ) : // FlexBoxCenter needs children or it renders a zero
      // so return null here --joshuamanns
      null}
    </FlexBoxCenter>
  );
}

export default StatusBar;
