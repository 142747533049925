import PropTypes from 'prop-types';
import * as React from 'react';
import Client from 'shared/records/Client.jsx';
import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import Staff from 'shared/records/Staff.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import WrappableName from 'shared/components/WrappableName.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhContrast } from 'shared/styles/uhStyles.jsx';

const styles = {
  managingUserContainer: {
    marginTop: 4,
  },
  managingUserIcon: {
    height: 14,
    width: 14,
    marginRight: 8,
  },
};

function UserDetailsDrawerAvatar({ user }) {
  return (
    <FlexBoxCenter
      style={merge(uhContrast.lightOnDark, { padding: '24px 29px 24px 29px' })}
    >
      <UserAvatar user={user} style={{ marginRight: '0.75em' }} />
      <div style={uhContrast.lightOnDark}>
        {user.name()}
        {user.managing_customer_user && (
          <FlexBoxCenter style={styles.managingUserContainer}>
            <ManagingUserIcon style={styles.managingUserIcon} />
            <WrappableName nameable={user.managing_customer_user} />
          </FlexBoxCenter>
        )}
      </div>
    </FlexBoxCenter>
  );
}

UserDetailsDrawerAvatar.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.instanceOf(Client),
    PropTypes.instanceOf(Staff),
  ]).isRequired,
};

export default UserDetailsDrawerAvatar;
