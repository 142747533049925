/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function SportOfferedAutocomplete({ options, onChange, value, placeholder }) {
  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option}
      value={value}
      limitTags={3}
      isOptionEqualToValue={(option, val) => option === val}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option}
        </li>
      )}
      onChange={onChange}
      renderInput={params => (
        <TextField {...params} placeholder={placeholder} size="small" />
      )}
      sx={{
        cursor: 'pointer',
        '& .UH-MuiChip-deleteIconColorDefault': {
          cursor: 'pointer',
        },
        '& .UH-MuiInputBase-root': {
          background: 'white',
          minHeight: '42px',
        },
        '& .Mui-focused': {
          flexWrap: 'wrap !important',
          display: 'flex',
          padding: '5px',
        },
      }}
    />
  );
}

export default SportOfferedAutocomplete;
