class MarketingEmailTemplatesActions {
  constructor() {
    this.generateActions(
      'handleFieldChange',
      'handlePageSelect',
      'searchMarketingEmailTemplates',
      'openMarketingEmailTemplateDrawer',
      'closeMarketingEmailTemplateDrawer',
      'toggleMarketingEmailPreview',
      'listMarketingEmailTemplates',
      'listMarketingEmailTemplatesSuccess',
      'listMarketingEmailTemplatesError',
      'createMarketingEmailTemplate',
      'createMarketingEmailTemplateSuccess',
      'createMarketingEmailTemplateError',
      'deleteMarketingEmailTemplate',
      'deleteMarketingEmailTemplateSuccess',
      'deleteMarketingEmailTemplateError',
      'fetchTemplatePreviewSuccess',
      'fetchTemplatePreviewError'
    );
  }
}

export default alt.createActions(MarketingEmailTemplatesActions);
