import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  link: {
    color: uhColors.activeBlue,
    cursor: 'pointer',
  },
};

function AddRemoveQuickDiscount({
  item,
  expanded,
  removeLabel,
  addLabel,
  onAdd,
  onRemove,
}) {
  return expanded ? (
    <span
      role="presentation"
      style={styles.link}
      onClick={() => onRemove(item)}
    >
      {removeLabel}
    </span>
  ) : (
    <span role="presentation" style={styles.link} onClick={() => onAdd(item)}>
      {addLabel}
    </span>
  );
}

AddRemoveQuickDiscount.propTypes = {
  item: PropTypes.object,
  expanded: PropTypes.bool,
  removeLabel: PropTypes.node,
  addLabel: PropTypes.node,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

AddRemoveQuickDiscount.defaultProps = {
  item: {},
  expanded: false,
  removeLabel: (
    <FormattedMessage id={messageId('.remove_discount', __filenamespace)} />
  ),
  addLabel: (
    <FormattedMessage id={messageId('.add_discount', __filenamespace)} />
  ),
  onAdd: () => null,
  onRemove: () => null,
};

export default AddRemoveQuickDiscount;
