import { Set } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class UIStore extends UpperHandStore {
  constructor() {
    super();
    this.loadingSet = Set();
    this.savingSet = Set();
  }

  handleDoneWaiting({ storeName = 'ui' } = {}) {
    this.loadingSet = this.loadingSet.delete(storeName);
    this.savingSet = this.savingSet.delete(storeName);
    this.saving = !this.savingSet.isEmpty();
    this.loading = !this.loadingSet.isEmpty();
  }

  handleLoading({ storeName = 'ui' } = {}) {
    this.loadingSet = this.loadingSet.add(storeName);
    this.loading = !this.loadingSet.isEmpty();
  }

  handleSaving({ storeName = 'ui' } = {}) {
    this.savingSet = this.savingSet.add(storeName);
    this.saving = !this.savingSet.isEmpty();
  }

  handleClear(keyOrKeys) {
    let keys;
    if (keyOrKeys) {
      keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys];
    } else {
      keys = Object.keys(this);
    }
    keys.forEach(k => delete this[k]);
  }
}

export default UIStore;
