import * as React from 'react';
import { injectIntl } from 'react-intl';
import AltContainer from 'alt-container';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';

import ClockIcon from 'shared/components/icons/Clock.jsx';
import ClockOutIcon from 'shared/components/icons/ClockOut.jsx';
import ClockInIcon from 'shared/components/icons/ClockIn.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import TaskListingStore from 'shared/stores/TaskListingStore';
import TimeTrackingActions from 'shared/actions/TimeTrackingActions';
import TimeTrackingStore from 'shared/stores/TimeTrackingStore';
import Timer from 'shared/components/Timer.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors, uhFormElement } from 'shared/styles/uhStyles.jsx';

const onTaskSelect = e => TimeTrackingActions.taskSelected(e.target.value);
const onLocationSelect = e =>
  TimeTrackingActions.locationSelected(e.target.value);

const onClockIn = () => TimeTrackingActions.clockIn();
const onClockOut = () => TimeTrackingActions.clockOut();

function ClockInButton({ intl, timeLog, isClockingIn }) {
  return (
    <FlexBoxCenter style={{ alignItems: 'center' }}>
      <IconButton
        disabled={!timeLog.isValid() || isClockingIn}
        onClick={onClockIn}
        iconStyle={{ height: 40, width: 40 }}
        style={{
          height: 40,
          width: 40,
          padding: 0,
          marginRight: 10,
          marginLeft: -10,
        }}
      >
        <ClockInIcon color={uhColors.green} />
      </IconButton>
      {isClockingIn
        ? t('.clocking_in', intl, __filenamespace)
        : t('.clock_in', intl, __filenamespace)}
    </FlexBoxCenter>
  );
}

function ClockOutButton({ intl, timeLog, isClockingOut }) {
  return (
    <FlexBoxJustify style={{ alignItems: 'center' }}>
      <FlexBoxCenter>
        <IconButton
          disabled={isClockingOut}
          onClick={onClockOut}
          iconStyle={{ height: 40, width: 40 }}
          style={{
            height: 40,
            width: 40,
            padding: 0,
            marginRight: 10,
            marginLeft: -10,
          }}
        >
          <ClockOutIcon color={uhColors.darkRed} />
        </IconButton>
        {isClockingOut
          ? t('.clocking_out', intl, __filenamespace)
          : t('.clock_out', intl, __filenamespace)}
      </FlexBoxCenter>
      <Timer start={timeLog.start_time} />
    </FlexBoxJustify>
  );
}

function TimeTracking({
  taskListingStore: { tasks },
  timeTrackingStore: { timeLog, isClockingIn, isClockingOut },
  locationStore: { allLocations: locations },
  intl,
}) {
  return (
    <div style={{ padding: 20, width: Math.min(300, window.innerWidth) }}>
      <Stack spacing={2}>
        <div style={{ fontSize: 16, fontWeight: 'bold', color: uhColors.hint }}>
          {t('.clock_in', intl, __filenamespace)}
        </div>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink style={uhFormElement.floatingLabel}>
            {t('.task', intl, __filenamespace)}
          </InputLabel>
          <Select
            displayEmpty
            label={t('.task', intl, __filenamespace)}
            disabled={!!timeLog.start_time}
            value={timeLog.task_id || ''}
            renderValue={v =>
              tasks.get(v, {}).name || (
                <span style={uhFormElement.hint}>
                  {t('.choose_task', intl, __filenamespace)}
                </span>
              )
            }
            onChange={onTaskSelect}
          >
            {tasks.valueSeq().map(task => {
              if (task.removed) return null;
              return (
                <MenuItem key={task.id} value={task.id}>
                  {task.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink style={uhFormElement.floatingLabel}>
            {t('.location', intl, __filenamespace)}
          </InputLabel>
          <Select
            displayEmpty
            label={t('.location', intl, __filenamespace)}
            disabled={!!timeLog.start_time}
            value={timeLog.location_id || ''}
            renderValue={v =>
              locations.find(l => l.id === v)?.get('name', false) || (
                <span style={uhFormElement.hint}>
                  {t('.choose_location', intl, __filenamespace)}
                </span>
              )
            }
            onChange={onLocationSelect}
          >
            {locations.map(l => (
              <MenuItem key={l.id} value={l.id}>
                {l.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {timeLog.start_time ? (
          <ClockOutButton
            intl={intl}
            timeLog={timeLog}
            isClockingOut={isClockingOut}
          />
        ) : (
          <ClockInButton
            intl={intl}
            timeLog={timeLog}
            isClockingIn={isClockingIn}
          />
        )}
      </Stack>
    </div>
  );
}

class TimeTrackingMenu extends React.Component {
  state = { anchorEl: undefined };

  componentDidMount() {
    TimeTrackingActions.menuMounted();
  }

  getIconColor() {
    const {
      timeTrackingStore: { menuOpen, timeLog },
    } = this.props;

    if (timeLog.start_time) {
      return uhColors.green;
    }
    if (menuOpen) {
      return uhColors.activeBlue;
    }
    return uhColors.iconGrey;
  }

  handleClick = event => {
    // Prevent ghost click.
    event.preventDefault();

    this.setState({ anchorEl: event.currentTarget });
    TimeTrackingActions.menuOpened();
  };

  render() {
    const { anchorEl } = this.state;
    const {
      intl,
      timeTrackingStore: { menuOpen },
    } = this.props;

    return (
      <div>
        <IconButton aria-describedby="time-tracking" onClick={this.handleClick}>
          <ClockIcon color={this.getIconColor()} />
        </IconButton>
        <Popover
          id="time-tracking"
          open={menuOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={TimeTrackingActions.menuClosed}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <TimeTracking intl={intl} {...this.props} />
        </Popover>
      </div>
    );
  }
}

function TimeTrackingMenuWrapper({ intl }) {
  return (
    <AltContainer
      stores={{
        timeTrackingStore: TimeTrackingStore,
        taskListingStore: TaskListingStore,
        locationStore: LocationStore,
      }}
    >
      <TimeTrackingMenu intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(TimeTrackingMenuWrapper);
