import * as React from 'react';
import PropTypes from 'prop-types';
import { ContentModal, Typography } from '@upperhand/playmaker';
import { injectIntl, FormattedMessage } from 'react-intl';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils.js';
import ContactsStaffActions from 'containers/contacts/contactsStaff/Actions.js';
import Staff from 'shared/records/Staff.jsx';

const styles = {
  text: {
    whiteSpace: 'break-spaces',
  },
  highlightWord: {
    fontWeight: 'bold',
  },
};

function RevokeAccessModal({ staff, sessionsCount, intl, isOpen, isLoading }) {
  return (
    <ContentModal
      open={isOpen}
      title={t('.title', intl, __filenamespace)}
      successLabel={t('.btn_confirm', intl, __filenamespace)}
      cancelLabel={t('.btn_cancel', intl, __filenamespace)}
      onSuccess={() => !isLoading && ContactsStaffActions.revokeAccess()}
      onAbort={ContactsStaffActions.closeRevokeAccessModal}
    >
      <SpinWhileLoading isLoading={isLoading} spinWhile="isLoading">
        <Typography variant="dialogContent">
          <pre style={styles.text}>
            <FormattedMessage
              id={messageId('.text', __filenamespace)}
              values={{
                staffName: staff && (
                  <span style={styles.highlightWord}>
                    {staff.first_name} {staff.last_name}
                  </span>
                ),
                sessions: (
                  <span style={styles.highlightWord}>
                    {sessionsCount} future sessions
                  </span>
                ),
                important: (
                  <span style={styles.highlightWord}>
                    {t('.important', intl, __filenamespace)}
                  </span>
                ),
                confirm: (
                  <span style={styles.highlightWord}>
                    {t('.btn_confirm', intl, __filenamespace)}
                  </span>
                ),
                undone: (
                  <span style={styles.highlightWord}>
                    {t('.undone_text', intl, __filenamespace)}
                  </span>
                ),
              }}
            />
          </pre>
        </Typography>
      </SpinWhileLoading>
    </ContentModal>
  );
}

RevokeAccessModal.propTypes = {
  staff: PropTypes.instanceOf(Staff),
  sessionsCount: PropTypes.number,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

RevokeAccessModal.defaultProps = {
  staff: null,
  sessionsCount: null,
};

export default injectIntl(RevokeAccessModal);
