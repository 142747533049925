import FeePlan from 'event_mgmt/shared/records/FeePlan.jsx';
import FeePlanActions from 'event_mgmt/shared/actions/FeePlanActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class FeePlanStore extends UpperHandStore {
  constructor() {
    super();

    this.feePlan = new FeePlan();

    this.bindListeners({
      handleFetch: FeePlanActions.fetch,
      handleFetchSuccess: FeePlanActions.fetchSuccess,
      handleFetchError: FeePlanActions.fetchError,
      handleUpdateStore: FeePlanActions.updateStore,
    });
  }

  handleUpdateStore(data) {
    const newAttributes = data.toJSON ? data.toJSON() : data;
    const currentAttributes = this.feePlan.toJSON
      ? this.feePlan.toJSON()
      : this.feePlan;
    this.feePlan = new FeePlan({
      ...currentAttributes,
      ...newAttributes,
      updated_at: new Date(),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetch() {
    return uhApiClient.get({
      url: 'fee_plan',
      success: FeePlanActions.fetchSuccess,
      error: FeePlanActions.fetchError,
    });
  }

  handleFetchSuccess(attributes) {
    this.feePlan = new FeePlan(attributes);
  }

  handleFetchError(...args) {
    this.notifyError('error while fetching fee plan', args);
  }
}

export default alt.createStore(FeePlanStore, 'FeePlanStore');
