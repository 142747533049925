import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Dropdown, DateTimePicker } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import FieldErrors from 'shared/records/FieldErrors.jsx';

import { SUSPENSION_OPTIONS } from '../Store';

function SuspensionFields({
  errors,
  suspensionLength,
  suspensionStartDate,
  onChange,
}) {
  const intl = useIntl();
  const suspensionItems = Object.values(SUSPENSION_OPTIONS).map(option => ({
    label: option.replace(/_/g, ' '),
    value: option,
  }));

  return (
    <>
      <Dropdown
        fullWidth
        label={t('.suspension_length', intl, __filenamespace)}
        errorText={errors.getErrors('suspension_length')}
        value={suspensionLength}
        items={suspensionItems}
        onChange={e => onChange('suspension_length', e.target.value)}
      />
      <DateTimePicker
        fullWidth
        disablePast
        type="date"
        views={['date']}
        errorText={errors.getErrors('suspension_start_date')}
        label={t('.suspension_start_date', intl, __filenamespace)}
        value={suspensionStartDate}
        onChange={date => onChange('suspension_start_date', date)}
      />
    </>
  );
}

SuspensionFields.propTypes = {
  errors: PropTypes.instanceOf(FieldErrors).isRequired,
  suspensionLength: PropTypes.string.isRequired,
  suspensionStartDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(SuspensionFields);
