import { Iterable, Record } from 'immutable';

class ContactGroup extends Record({
  id: null,
  name: '',
  contact_filter_id: null,
  customer_users_count: 0,
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      // eslint-disable-next-line no-param-reassign
      obj = obj.toJS();
    }

    super(obj);
  }

  toServer() {
    return this.toJS();
  }
}

export default ContactGroup;
