import { Record } from 'immutable';

class FeePlan extends Record({
  id: null,
  percentage: 0,
  dollar_amount: 0,
  interchange_plus: false,
}) {
  breakEvenPrice() {
    return this.dollar_amount / (1 - this.percentage / 100);
  }
}

export default FeePlan;
