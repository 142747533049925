import * as React from 'react';
import { grey } from '@mui/material/colors';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    color: grey[400],
  },
};

const getNonTextStyle = (color, height, width, style) => {
  let w = 0;

  if (typeof width === 'number') {
    w = `${width}px`;
  }

  return merge(
    {
      display: 'inline-block',
      margin: 'auto',
      borderRight: `${w} solid ${color}`,
      height,
    },
    style
  );
};

function VerticalDivider({ style }) {
  return <span style={merge(styles.root, style)}>|</span>;
}

export function NonTextVerticalDivider({ color, height, width, style }) {
  return (
    <div
      style={getNonTextStyle(
        color || styles.root.color,
        height || 10,
        width || 1,
        style
      )}
    >
      &nbsp;
    </div>
  );
}

export default VerticalDivider;
