import { List, Map } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import InviteActions from 'user_management/shared/actions/InviteActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import Staff from 'shared/records/Staff.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffAvailabilityActions from 'shared/actions/StaffAvailabilityActions.jsx';
import StaffAvailabilityStore from 'shared/stores/StaffAvailabilityStore.jsx';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import UserValidator from 'shared/utils/UserValidator.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { setAddressDataIn, merge } from 'shared/utils/ObjectUtils.jsx';
import { StaffSource } from 'sources';

const url = id => {
  if (id) {
    return `customer_users/${id}`;
  }
  return 'customer_users?types[]=staff_admin&types[]=staff_member';
};

// TODO: Cleanup unused actions
class StaffStore extends UpperHandStore {
  constructor() {
    super();
    this.staffMember = new Staff(); // for fetching, creating, updating, selecting
    this.allStaff = List(); // for listing
    this.allStaffMap = Map();

    this.fieldErrors = new FieldErrors();
    this.requiredFields = List(['email']);

    this.isLoading = false;
    this.isSaving = false;
    this.isValid = true;

    this.findById = id => this.allStaff.find(staff => staff.id === id);

    this.staffMemberIsPresent = c =>
      this.staffMember && this.staffMember.isPresent(c);

    this.bindListeners({
      handleAcceptTerms: StaffActions.acceptTerms,

      handleList: [StaffActions.list, PayrollReportActions.MOUNTED],
      handleListSuccess: StaffActions.listSuccess,
      handleListError: StaffActions.listError,

      // @mihilbabin: Looks like some these actions aren't used
      // --
      handleFetch: StaffActions.fetch,
      handleFetchSuccess: StaffActions.fetchSuccess,
      handleFetchError: StaffActions.fetchError,

      handleCreate: StaffActions.create,
      handleCreateSuccess: StaffActions.createSuccess,
      handleCreateError: StaffActions.createError,

      handleUpdate: StaffActions.update,
      handleUpdateSuccess: [
        StaffActions.updateSuccess,
        StaffDetailsDrawerActions.HOURLY_RATE_SAVE_SUCCESS,
      ],
      handleUpdateError: StaffActions.updateError,
      handleUpdateStore: StaffActions.updateStore,

      handleUpdateAvailability: StaffAvailabilityActions.createOrUpdateSuccess,

      handleInviteRequest: [
        InviteActions.SEND_SUCCESS,
        InviteActions.DELETE_SUCCESS,
      ],

      handleStaffSelected: StaffActions.staffSelected,

      validateUser: StaffActions.validateUser,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleAcceptTerms(id) {
    uhApiClient.put({
      url: `customer_users/${id}/accept_terms`,
      success: StaffActions.updateSuccess,
      error: StaffActions.updateError,
    });
  }

  handleInviteRequest() {
    this.handleList();
  }

  handleStaffSelected(staffMember) {
    this.staffMember = staffMember;
  }

  // Used on Account verification page
  handleUpdateStore(data) {
    /*
     * This stupidity is because the staffMember in specs is just an object
     * TODO: figure out how to force the attribute to be a record!
     */
    const staffMemberJson = this.staffMember.toJSON
      ? this.staffMember.toJSON()
      : this.staffMember;
    const newData = setAddressDataIn(data, staffMemberJson);
    const newAttributes = merge(staffMemberJson, newData, {
      id: this.staffMember.id,
    });
    this.handleStaffSelected(new Staff(newAttributes));
    this.validateUser();
  }

  handleList({ page = 1, accessRevoked = false, getAll = false }) {
    const params = { page, access_revoked: accessRevoked, per_page: 50 };

    this.isLoading = true;

    if (page === 1) {
      this.allStaffMap = Map();
    }

    if (getAll) {
      delete params.access_revoked;
    }

    StaffSource.list({
      params,
      success: {
        action: StaffActions.listSuccess,
        args: [{ accessRevoked, getAll }],
      },
      error: StaffActions.listError,
    });
  }

  handleListSuccess([
    { staff, page, perPage, totalCount },
    { accessRevoked, getAll },
  ]) {
    this.allStaffMap = this.allStaffMap.withMutations(m =>
      staff.map(s => m.set(s.id, new Staff(s)))
    );

    if (page * perPage < totalCount) {
      this.handleList({ page: page + 1, accessRevoked, getAll });
      return;
    }

    this.allStaff = this.sorted(this.allStaffMap.toList());
    this.isLoading = false;
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing staff', args);
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetch(id) {
    StaffSource.fetch({
      id,
      success: StaffActions.fetchSuccess,
      error: StaffActions.fetchError,
    });
  }

  handleFetchSuccess(data) {
    this.staffMember = new Staff(data);
    this.validateUser();
  }

  handleFetchError(...args) {
    this.notifyError('error while fetching staff member', args);
  }

  async handleCreate({ afterCreate, showSuccessMessage }) {
    this.afterCreate = afterCreate;
    this.showSuccessMessage = !!showSuccessMessage;
    this.successMessage = 'Invite Sent.';
    const payload = await this.payload(true);

    return uhApiClient.post({
      url: url(),
      data: payload,
      success: StaffActions.createSuccess,
      error: StaffActions.createError,
    });
  }

  handleCreateSuccess(data) {
    this.staffMember = new Staff(data);
    this.allStaff = this.allStaff.push(this.staffMember);
    this.allStaff = this.sorted(this.allStaff);

    if (this.afterCreate) this.afterCreate(data);
    if (this.showSuccessMessage) {
      MessageWindowActions.addMessage.defer(this.successMessage);
    }
  }

  handleCreateError(...args) {
    this.notifyError('error while creating staff member', args);
  }

  async handleUpdate(id) {
    this.isSaving = true;
    const payload = await this.payload(false);

    uhApiClient.put({
      url: url(id),
      data: payload,
      success: StaffActions.updateSuccess,
      error: StaffActions.updateError,
    });
  }

  handleUpdateSuccess(data) {
    this.staffMember = new Staff(data);

    const newStaffMember = this.staffMember;
    const index = this.allStaff.findIndex(
      theStaffMember => theStaffMember.id === data.id
    );

    this.allStaff = this.allStaff.updateIn([index], () => newStaffMember);
    this.isSaving = false;
  }

  handleUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('error while updating staff member', args);
  }

  handleUpdateAvailability() {
    this.waitFor(StaffAvailabilityStore);
    const { availabilitySchedule } = StaffAvailabilityStore.getState();

    // Contacts staff page doesnt preload allstaff, need to ensure we have entries before we update them.
    if (this.allStaff.size) {
      const [index, staff] = this.allStaff.findEntry(
        staffMember => staffMember.id === availabilitySchedule.owner_id
      );

      this.allStaff = this.allStaff.set(
        index,
        staff.set('availability_schedule_id', availabilitySchedule.id)
      );
    }
  }

  async payload(create = false) {
    const payload = await this.staffMember.toServer(create);

    return JSON.stringify({ attributes: payload });
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(list) {
    return list.sortBy(staff => staff.name());
  }

  validateUser({ requiredFields = [] } = {}) {
    if (requiredFields) {
      this.requiredFields = List(requiredFields);
    }

    const userValidator = new UserValidator(this.staffMember).validate(
      this.requiredFields
    );
    this.fieldErrors = userValidator.errors;
    this.isValid = !this.fieldErrors.hasErrors();
  }
}

export default alt.createStore(StaffStore, 'StaffStore');
