import moment from 'moment-timezone';
import { ScheduleSource } from 'sources';
import { UpperHandStore } from 'dataStores';
import CancelCompleteScheduleActions from './Actions';

export const FILTER_LOCATION_ALL = 'all';
export const FILTER_STAFF_ALL = 'all';
export const FILTER_RESOURCE_ALL = 'all';
export const FILTER_SCHEDULE_NAME_ALL = 'all';

class CancelCompleteScheduleModalStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      completeScheduleNoteUpdated:
        CancelCompleteScheduleActions.completeScheduleNoteUpdated,
      completeScheduleSelected:
        CancelCompleteScheduleActions.completeScheduleSelected,
      cancelScheduleSelected:
        CancelCompleteScheduleActions.cancelScheduleSelected,
      completeScheduleModalCancelClicked:
        CancelCompleteScheduleActions.completeScheduleModalCancelClicked,
      completeScheduleModalConfirmClicked:
        CancelCompleteScheduleActions.completeScheduleModalConfirmClicked,
    });
  }

  reset() {
    this.completeScheduleModalOpen = false;
    this.completeScheduleModalNote = '';
    this.scheduleId = null;
    this.futureRegistrationCount = null;
  }

  completeScheduleNoteUpdated(text) {
    this.completeScheduleModalNote = text;
  }

  completeScheduleSelected([scheduleId, futureRegistrationCount]) {
    this.scheduleId = scheduleId;
    this.futureRegistrationCount = futureRegistrationCount;
    this.completeScheduleModalOpen = true;
  }

  cancelScheduleSelected(id) {
    ScheduleSource.destroy({
      id,
      success: CancelCompleteScheduleActions.scheduleCancelSuccess,
    });

    this.reset();
  }

  completeScheduleModalCancelClicked() {
    this.reset();
  }

  completeScheduleModalConfirmClicked() {
    ScheduleSource.patch({
      id: this.scheduleId,
      params: {
        reason: this.completeScheduleModalNote,
        attributes: {
          availability_schedule: { end_date: moment().format('LLL') },
        },
      },
      success: CancelCompleteScheduleActions.scheduleCompleteSuccess,
    });
    this.reset();
  }
}

export default alt.createStore(
  CancelCompleteScheduleModalStore,
  'CancelCompleteScheduleModalStore'
);
