import debounce from 'lodash.debounce';
import { List } from 'immutable';

import { StaffSource } from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import StaffActions from './actions';

class EventAdminStaffStore extends UpperHandStore {
  constructor() {
    super();

    this.eventId = null;
    this.staffIds = List();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.search = '';
    this.filtersPresent = false;
    this.loading = false;

    this.debouncedListStaff = debounce(() => {
      this.listStaff();
      this.emitChange();
    }, 600);

    this.bindListeners({
      mounted: StaffActions.mounted,
      listStaffSuccess: StaffActions.listStaffSuccess,
      listStaffError: StaffActions.listStaffError,
      pageSelected: StaffActions.pageSelected,
      searchUpdated: StaffActions.searchUpdated,
    });
  }

  mounted(eventId) {
    this.eventId = eventId;
    this.listStaff();
  }

  listStaff() {
    const params = {
      page: this.page,
      per_page: this.perPage,
      event_ids: [this.eventId],
      access_revoked: false,
    };

    if (this.search) {
      params.search = this.search;
    }

    this.loading = true;

    StaffSource.list({
      params,
      success: StaffActions.listStaffSuccess,
      error: StaffActions.listStaffError,
    });
  }

  listStaffSuccess({ staff, totalCount }) {
    this.staffIds = staff.map(c => c.id);
    this.totalCount = totalCount;
    this.loading = false;
    this.filtersPresent = this.search !== '';
  }

  listStaffError(...args) {
    this.loading = false;
    this.notifyError('error listing staff', args);
  }

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listStaff();
  }

  searchUpdated(newSearch) {
    this.search = newSearch;
    this.page = 1;

    this.debouncedListStaff();
  }
}

export default alt.createStore(EventAdminStaffStore, 'EventAdminStaffStore');
