import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import PageHeader from 'components/PageHeader/PageHeader.jsx';
import RevenueFilters from 'reporting/revenue/components/_RevenueFilters.jsx';
import TransactionsByMonth from 'reporting/revenue/components/_TransactionsByMonth.jsx';
import CumulativeTransactionsByMonth from 'reporting/revenue/components/_CumulativeTransactionsByMonth.jsx';
import RevenueByMonth from 'reporting/revenue/components/_RevenueByMonth.jsx';
import CumulativeRevenueByMonth from 'reporting/revenue/components/_CumulativeRevenueByMonth.jsx';
import TransactionsYearOverYear from 'reporting/revenue/components/_TransactionsYearOverYear.jsx';
import RevenueYearOverYear from 'reporting/revenue/components/_RevenueYearOverYear.jsx';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import RevenueReportActions from 'reporting/revenue/actions/_RevenueReportActions.jsx';

class RevenueReport extends React.Component {
  componentDidMount() {
    RevenueReportActions.mounted();
  }

  render() {
    return (
      <FlexBoxJustify
        style={{ flexDirection: 'column', padding: '25px 30px 30px 30px' }}
      >
        <PageHeader
          title={
            <FormattedMessage id={messageId('.header', __filenamespace)} />
          }
        />
        <div>
          <RevenueFilters />
          <FlexBox>
            <div style={{ flex: '0 0 50%', paddingRight: '10px' }}>
              <TransactionsByMonth />
            </div>
            <div style={{ flex: '0 0 50%', paddingLeft: '10px' }}>
              <CumulativeTransactionsByMonth />
            </div>
          </FlexBox>
          <FlexBox>
            <div style={{ flex: '0 0 50%', paddingRight: '10px' }}>
              <RevenueByMonth />
            </div>
            <div style={{ flex: '0 0 50%', paddingLeft: '10px' }}>
              <CumulativeRevenueByMonth />
            </div>
          </FlexBox>
          <FlexBox>
            <div style={{ flex: '0 0 50%', paddingRight: '10px' }}>
              <TransactionsYearOverYear />
            </div>
            <div style={{ flex: '0 0 50%', paddingLeft: '10px' }}>
              <RevenueYearOverYear />
            </div>
          </FlexBox>
        </div>
      </FlexBoxJustify>
    );
  }
}

export default RevenueReport;
