/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import FutureEventSchedules from './future_event_schedules/FutureEventSchedules.jsx';
import FutureEventSessions from './future_event_sessions/FutureEventSessions.jsx';

function FutureEventSchedule(props) {
  const [scheduleViewMode, toggleViewMode] = React.useState(false);

  const onToggleViewMode = () => {
    toggleViewMode(mode => !mode);
  };

  return scheduleViewMode ? (
    <FutureEventSchedules onToggleViewMode={onToggleViewMode} {...props} />
  ) : (
    <FutureEventSessions onToggleViewMode={onToggleViewMode} {...props} />
  );
}

export default FutureEventSchedule;
