import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSStore from 'point_of_sale/stores/POSStore.jsx';
import RegistrationFormStoreInterface from 'shared/stores/RegistrationFormStoreInterface.jsx';
import POSRegistrationFormActions from 'point_of_sale/actions/POSRegistrationFormActions.jsx';
import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';

class POSRegistrationFormStore extends RegistrationFormStoreInterface {
  constructor() {
    super();

    this.registrationFormActions = POSRegistrationFormActions;

    this.bindListeners({
      handleAnswer: POSRegistrationFormActions.ANSWER,
      handleStage: POSRegistrationFormActions.STAGE,
      handleStart: POSRegistrationFormActions.START,
      handleStop: POSRegistrationFormActions.STOP,
      handlePreviousRegistrant: POSRegistrationFormActions.PREVIOUS_REGISTRANT,
      handleNextRegistrant: POSRegistrationFormActions.NEXT_REGISTRANT,

      loadFieldData: RegistrationFieldActions.LIST_SUCCESS,

      listAnswers: POSRegistrationFormActions.LIST_ANSWERS,
      loadAnswerData: POSRegistrationFormActions.LIST_ANSWERS_SUCCESS,
      listAnswersError: POSRegistrationFormActions.LIST_ANSWERS_ERROR,

      handleComplete: POSRegistrationFormActions.SET_COMPLETED,
      handleCompleteSuccess: POSRegistrationFormActions.SET_COMPLETED_SUCCESS,
      handleCompleteError: POSRegistrationFormActions.SET_COMPLETED_ERROR,

      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
      ],
    });
  }

  handleStage([eventId, clientIds, registrationId]) {
    this.waitFor(POSStore);

    this.defaulRegistrationId = POSStore.getState().selectedClient.id;

    super.handleStage([eventId, clientIds, registrationId]);
  }
}

export default alt.createStore(
  POSRegistrationFormStore,
  'POSRegistrationFormStore'
);
