import * as React from 'react';
import { injectIntl } from 'react-intl';

import EventRateCard from 'contacts/index/components/event_rates/EventRateCard.jsx';
import EventRateForm from 'contacts/index/components/event_rates/EventRateForm.jsx';

const EventRateList = injectIntl(
  ({
    specificEventsListStore: { events },
    eventRateCreationStore: { eventRate },
    eventRateEditingStore,
    eventRateListingStore: { eventRates, selectedEventIds },
    intl,
  }) => (
    <div>
      {eventRates.map(er => {
        if (
          eventRateEditingStore.eventRate &&
          eventRateEditingStore.eventRate.id === er.id
        ) {
          return (
            <EventRateForm
              selectedEvents={events.filter(e =>
                eventRateEditingStore.eventRate.event_ids.has(e.id)
              )}
              eventRateStore={eventRateEditingStore}
              key={er.id}
              selectedEventIds={selectedEventIds}
              style={{ marginBottom: 10 }}
              intl={intl}
            />
          );
        }
        return (
          <EventRateCard
            selectedEvents={events.filter(e => er.event_ids.has(e.id))}
            disabledEditing={eventRate}
            eventRate={er}
            key={er.id}
            selectedEventIds={selectedEventIds}
            style={{ marginBottom: 10 }}
            intl={intl}
          />
        );
      })}
    </div>
  )
);

export default EventRateList;
