import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import altContainer from 'shared/hocs/altContainer.jsx';
import CreditPass, {
  CREDIT_PASS_EXPIRATION_STRATEGIES,
} from 'shared/records/CreditPass.js';
import { CreditPassDataStore } from 'dataStores';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  common: {
    color: uhColors.leftNavGrey,
    fontWeight: 400,
    lineHeight: '16px',
  },
};

function ExpirationMessage({ creditPass }) {
  switch (creditPass.expiration_strategy) {
    case CREDIT_PASS_EXPIRATION_STRATEGIES.never:
      return <FormattedMessage id={messageId('.never', __filenamespace)} />;
    case CREDIT_PASS_EXPIRATION_STRATEGIES.date:
      return (
        <>
          <FormattedMessage id={messageId('.expires_at', __filenamespace)} />
          <FormattedDate
            value={creditPass.expires_at}
            month="short"
            day="numeric"
            year="numeric"
          />
        </>
      );
    default:
      return (
        <>
          <FormattedMessage
            id={messageId('.expiration_days', __filenamespace)}
            values={{ days: creditPass.expiration_days }}
          />
          {CREDIT_PASS_EXPIRATION_STRATEGIES[
            creditPass.expiration_strategy
          ] && (
            <FormattedMessage
              id={messageId(
                `.${
                  CREDIT_PASS_EXPIRATION_STRATEGIES[
                    creditPass.expiration_strategy
                  ]
                }`,
                __filenamespace
              )}
            />
          )}
        </>
      );
  }
}

function CreditPassExpiration({
  creditPassDataStore: { creditPasses },
  creditPassId,
  leftBorder,
  style,
}) {
  const creditPass = creditPasses.get(creditPassId, new CreditPass());

  return (
    <div className="cp-view-expiration" style={merge(styles.common, style)}>
      {leftBorder && (
        <span className="cp-expiration-left-border">&ensp; | &ensp;</span>
      )}
      <ExpirationMessage creditPass={creditPass} />
    </div>
  );
}

export default altContainer({
  stores: {
    creditPassDataStore: CreditPassDataStore,
  },
})(CreditPassExpiration);
