import MembershipEditingStore from 'memberships/stores/MembershipEditingStore.jsx';
import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UIStore from 'shared/stores/UIStore.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';

class MembershipEditingUIStore extends UIStore {
  constructor() {
    super();

    this.handleReset();

    this.bindListeners({
      handleReset: StoreActions.prepareForReuse,

      collapseExclusiveEventTypes:
        MembershipEditingActions.EXCLUSIVE_EVENT_TYPES_CLEARED,
      expandExclusiveEventTypes:
        MembershipEditingActions.EXCLUSIVE_EVENT_TYPES_EXPANDED,

      toggleJoinFee: MembershipEditingActions.JOIN_FEE_TOGGLED,
      toggleCommitmentLength:
        MembershipEditingActions.COMMITMENT_LENGTH_TOGGLED,
      handleUpdateUI: MembershipEditingActions.UPDATE_STORE,

      togglePreSale: MembershipEditingActions.PRE_SALE_TOGGLED,

      handleUpdateStore: MembershipEditingActions.UPDATE_UI,
      handleClear: MembershipEditingActions.CLEAR_UI,

      handleLoading: MembershipEditingActions.FETCH,
      handleSaving: MembershipEditingActions.VALIDATE_AND_SAVE,

      handleMembershipLoaded: MembershipEditingActions.MEMBERSHIP_LOADED,

      handleDoneWaiting: MembershipEditingActions.ERROR,
      handleSaveWithPrompt: MembershipEditingActions.SAVE_WITH_PROMPT,
      handleSaveCancellation: MembershipEditingActions.CANCEL_SAVE,
      notifyAfterSave: MembershipEditingActions.MEMBERSHIP_LOADED,
    });
  }

  handleReset() {
    this.commitmentLengthChecked = false;
    this.exclusiveEventTypesExpanded = false;
    this.joinFeeChecked = false;
    this.preSaleChecked = false;
    this.commitment_checked = false;
    this.priceChanged = false;
    this.prompted = false;
    this.saveRequested = false;
  }

  handleMembershipLoaded() {
    this.waitFor(MembershipEditingStore);

    const membership = MembershipEditingStore.getState().record;

    if (membership.membership_event_permissions.size > 0) {
      this.expandExclusiveEventTypes();
    }

    if (membership.join_fee) {
      this.joinFeeChecked = true;
    }

    if (membership.commitment_checked) {
      this.commitment_checked = true;
    }

    if (membership.commitment_months) {
      this.commitmentLengthChecked = true;
    }

    if (membership.active_at) {
      this.preSaleChecked = true;
    }

    this.handleDoneWaiting();
  }

  toggleJoinFee() {
    this.joinFeeChecked = !this.joinFeeChecked;
  }

  toggleCommitmentLength() {
    this.commitmentLengthChecked = !this.commitmentLengthChecked;
  }

  togglePreSale() {
    this.preSaleChecked = !this.preSaleChecked;
  }

  handleUpdateUI(state) {
    if ('interval_months' in state && state.interval_months === 0) {
      this.commitmentLengthChecked = false;
    }
    if ('price' in state) {
      this.priceChanged = true;
    }
    if ('commitment_checked' in state) {
      this.commitment_checked = true;
    }
  }

  handleSaveWithPrompt() {
    this.prompted = true;
  }

  handleSaveCancellation() {
    this.prompted = false;
  }

  handleSaving() {
    // TODO: This is duplicated logic from the MembershipEditingStore. We should
    // remove this from here and handle it in the MembershipEditingStore.
    this.waitFor(MembershipEditingStore);

    const membership = MembershipEditingStore.getState().record;

    if (membership.validate().isValid()) {
      this.saveRequested = true;
      super.handleSaving();
      this.prompted = false;
      this.priceChanged = false;
    }
  }

  collapseExclusiveEventTypes() {
    this.exclusiveEventTypesExpanded = false;
  }

  expandExclusiveEventTypes() {
    this.exclusiveEventTypesExpanded = true;
  }

  notifyAfterSave() {
    if (this.saveRequested) {
      MessageWindowActions.addMessage.defer(
        new TranslatableMessage({
          id: 'memberships.MembershipEditing.success',
          suffix: '.',
        })
      );
      this.saveRequested = false;
    }
  }
}

export default alt.createStore(
  MembershipEditingUIStore,
  'MembershipEditingUIStore'
);
