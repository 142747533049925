import { List, Set, fromJS } from 'immutable';
import ContactsReportActions from 'reporting/index/actions/_ContactsReportActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const filterProperties = [
  'neverLoggedIn',
  'lastLoginAtBefore',
  'lastLoginAtAfter',
  'customerIds',
];

class ContactsReportStore {
  constructor() {
    this.contactsMapResult = List();
    this.contactsListResult = List();
    this.locationsMapResult = List();
    this.isContactsMapLoading = false;
    this.isContactsListLoading = false;
    this.isLocationsMapLoading = false;
    this.defaultCustomerIds = Set();

    this.initializeFilters();
    this.updateStates();

    this.bindListeners({
      loadData: ContactsReportActions.MOUNTED,
      setContactsMapResult: ContactsReportActions.CONTACTS_MAP_RESULT_LOADED,
      setContactsMapError: ContactsReportActions.CONTACTS_MAP_RESULT_ERROR,
      setContactsListResult: ContactsReportActions.CONTACTS_LIST_RESULT_LOADED,
      setContactsListError: ContactsReportActions.CONTACTS_LIST_RESULT_ERROR,
      setLocationsMapResult: ContactsReportActions.LOCATIONS_MAP_RESULT_LOADED,
      setLocationsMapError: ContactsReportActions.LOCATIONS_MAP_RESULT_ERROR,
      updateFilters: ContactsReportActions.FILTER_UPDATED,
      applyFilters: ContactsReportActions.APPLY_FILTERS,
      clearFilters: ContactsReportActions.CLEAR_FILTERS,
      downloadContactsList: ContactsReportActions.DOWNLOAD_CONTACTS_LIST,
      downloadContactsListSuccess:
        ContactsReportActions.DOWNLOAD_CONTACTS_LIST_SUCCESS,
      downloadContactsListError:
        ContactsReportActions.DOWNLOAD_CONTACTS_LIST_ERROR,
    });
  }

  initializeFilters() {
    this.isApplyingFilter = false;
    // filters
    this.neverLoggedIn = false;
    // set the last login filter to the past 90 days
    this.lastLoginAtBefore = new Date();
    this.lastLoginAtAfter = new Date(
      new Date().setDate(new Date().getDate() - 90)
    );
    this.customerIds = this.defaultCustomerIds;
    this.appliedFilters = {};
    this.isFilterDirty = false;
  }

  loadData() {
    if (this.defaultCustomerIds.isEmpty()) {
      this.setDefaultCustomerIds();
    }
    this.applyFilters();
  }

  fetchContacts() {
    this.fetchContactsMap();
    this.fetchLocationsMap();
    this.fetchContactsList();
    this.updateStates();
  }

  applyFilters() {
    if (currentUser().isAdmin() && !this.customerIds.size) {
      this.contactsMapResult = List();
      this.contactsListResult = List();
      this.locationsMapResult = List();
      this.canApplyFilter = false;
      return;
    }
    this.appliedFilters = {};
    filterProperties.forEach(p => {
      this.appliedFilters[p] = this[p];
    });
    this.updateStates();
    this.fetchContacts();
  }

  clearFilters() {
    this.neverLoggedIn = false;
    this.lastLoginAtBefore = null;
    this.lastLoginAtAfter = null;
    this.customerIds = this.defaultCustomerIds;
    this.applyFilters();
  }

  fetchContactsMap() {
    this.isContactsMapLoading = true;
    uhApiClient.get({
      url: 'analytics/contacts_map',
      data: this.getFilterParams(),
      success: ContactsReportActions.contactsMapResultLoaded,
      error: ContactsReportActions.contactsMapResultError,
    });
  }

  fetchLocationsMap() {
    this.isLocationsMapLoading = true;
    uhApiClient.get({
      url: 'analytics/locations_map',
      success: ContactsReportActions.locationsMapResultLoaded,
      error: ContactsReportActions.locationsMapResultError,
    });
  }

  fetchContactsList() {
    this.isContactsListLoading = true;

    return uhApiClient.get({
      url: 'analytics/contacts_list',
      data: this.getFilterParams(),
      success: ContactsReportActions.contactsListResultLoaded,
      error: ContactsReportActions.contactsListResultError,
    });
  }

  getFilterParams() {
    const filters = this.appliedFilters;
    const result = {};

    if (filters.neverLoggedIn) {
      result.never_logged_in = true;
    }

    if (!filters.neverLoggedIn && filters.lastLoginAtBefore) {
      result.last_login_at_before = filters.lastLoginAtBefore.toISOString();
    }

    if (!filters.neverLoggedIn && filters.lastLoginAtAfter) {
      result.last_login_at_after = filters.lastLoginAtAfter.toISOString();
    }

    if (currentUser().isAdmin() && filters.customerIds.size) {
      result.customer_ids = filters.customerIds.toJS();
    }

    return result;
  }

  setContactsMapResult(data) {
    this.contactsMapResult = fromJS(data.records);
    this.isContactsMapLoading = false;
    this.updateStates();
  }

  setLocationsMapResult(data) {
    this.locationsMapResult = fromJS(data.records);
    this.isLocationsMapLoading = false;
    this.updateStates();
  }

  setContactsListResult(data) {
    this.contactsListResult = fromJS(data.records);
    this.isContactsListLoading = false;
    this.updateStates();
  }

  setContactsMapError() {
    this.isContactsMapLoading = false;
    this.updateStates();
  }

  setLocationsMapError() {
    this.isLocationsMapLoading = false;
    this.updateStates();
  }

  setContactsListError() {
    this.isContactsListLoading = false;
    this.updateStates();
  }

  updateFilters([filter, value]) {
    this[filter] = value;
    this.updateStates();
  }

  updateStates() {
    this.isFilterDirty =
      filterProperties.some(p => this.appliedFilters[p] !== this[p]) ||
      !this.appliedFilters.customerIds.equals(this.customerIds);
    const isMapLoading =
      this.isContactsMapLoading || this.isLocationsMapLoading;
    this.isApplyingFilter = isMapLoading || this.isContactsListLoading;
    this.canAdjustFilter = !this.isApplyingFilter;
    this.canApplyFilter = this.canAdjustFilter && this.isFilterDirty;
    const hasFilter =
      !!this.lastLoginAtAfter || !!this.lastLoginAtBefore || this.neverLoggedIn;
    this.canClearFilter = this.canAdjustFilter && hasFilter;
  }

  downloadContactsList() {
    return uhApiClient.get({
      url: 'analytics/contacts_list_csv',
      headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
      data: this.getFilterParams(),
      success: ContactsReportActions.downloadContactsListSuccess,
      error: ContactsReportActions.downloadContactsListError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  downloadContactsListSuccess(data) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const blob = new Blob([data], { type: 'application/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'contacts_list.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  // eslint-disable-next-line class-methods-use-this
  downloadContactsListError() {}

  setDefaultCustomerIds() {
    this.defaultCustomerIds = this.defaultCustomerIds.add(currentCustomer().id);
    this.customerIds = this.defaultCustomerIds;
    this.appliedFilters.customerIds = this.customerIds;
  }
}

export default alt.createStore(ContactsReportStore, 'ContactsReportStore');
