import * as React from 'react';
import { injectIntl } from 'react-intl';
import { TextField, Checkbox } from '@upperhand/playmaker';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Expander from 'shared/components/Expander.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

export const AGE = 'age';
export const ATTENDEE = 'attendee';
export const EVENT_TYPE = 'event_type';
export const LOCATION = 'location';

function EventTypeFilter({ actions, eventTypes, intl, typeFilters }) {
  return (
    <Expander
      label={t('.event_type', intl, __filenamespace)}
      contentContainerStyle={{ marginBottom: 20 }}
      startsExpanded={!smallScreen()}
    >
      {eventTypes.map(eventType => (
        <Checkbox
          checked={Boolean(typeFilters.get(eventType.id.toString()))}
          key={eventType.id}
          label={eventType.name}
          onChange={(_, checked) =>
            actions.updateTypeFilters(eventType.id.toString(), checked)
          }
          style={{ marginBottom: 10 }}
        />
      ))}
    </Expander>
  );
}

function LocationLabel({ location }) {
  const {
    line_1: lineOne,
    line_2: lineTwo,
    city,
    state,
    postal_cod: postalCode,
  } = location.address;

  return (
    <div style={{ lineHeight: '20px' }}>
      <div style={{ fontWeight: 'bold' }}>{location.name}</div>
      <div>{lineOne}</div>
      <div>{lineTwo}</div>
      <div>
        {city}, {state} {postalCode}
      </div>
    </div>
  );
}

function LocationFilter({ locations, locationFilters, actions, intl }) {
  return (
    <Expander
      label={t('.location', intl, __filenamespace)}
      contentContainerStyle={{ marginBottom: 20 }}
      startsExpanded={!smallScreen()}
    >
      {locations.map(location => (
        <Checkbox
          checked={locationFilters.get(location.id.toString())}
          key={location.id}
          label={<LocationLabel location={location} />}
          onChange={(_, checked) =>
            actions.updateLocationFilters(location.id.toString(), checked)
          }
          style={{ marginBottom: 10 }}
        />
      ))}
    </Expander>
  );
}

function AgeFilter({ ageFilters, actions, intl }) {
  return (
    <Expander
      label={t('.age', intl, __filenamespace)}
      contentContainerStyle={{ marginBottom: 20 }}
      startsExpanded={!smallScreen()}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Checkbox
          checked={ageFilters.get('enabled', false)}
          onChange={(_, isChecked) => actions.ageToggled(isChecked)}
        />
        <TextField
          fullWidth
          placeholder={t('.min', intl, __filenamespace)}
          onChange={(_, age) => actions.minAgeUpdated(age)}
          type="number"
          value={(ageFilters.get('min') || '').toString()}
        />
        <span style={{ color: uhColors.lightGrey }}>&mdash;</span>
        <TextField
          fullWidth
          placeholder={t('.max', intl, __filenamespace)}
          onChange={(_, age) => actions.maxAgeUpdated(age)}
          type="number"
          value={(ageFilters.get('max') || '').toString()}
        />
      </Stack>
    </Expander>
  );
}

function AttendeeFilter({ actions, profiles, attendeeFilters, intl }) {
  return (
    <Expander
      label={t('.attendee', intl, __filenamespace)}
      contentContainerStyle={{ marginBottom: 20 }}
    >
      {profiles.map(profile => (
        <Checkbox
          checked={attendeeFilters.get(profile.id.toString(), false)}
          key={profile.id}
          label={profile.name()}
          onChange={(_, checked) =>
            actions.updateAttendeeFilters(profile.id.toString(), checked)
          }
          style={{ marginBottom: 10 }}
        />
      ))}
    </Expander>
  );
}

function Filter({
  actions,
  intl,
  eventTypes,
  filterStore,
  history,
  location,
  locations,
  match,
  profiles,
  type,
}) {
  switch (type) {
    case AGE:
      return (
        <>
          <AgeFilter
            ageFilters={filterStore.ageFilters}
            actions={actions}
            intl={intl}
          />
          <Divider />
        </>
      );
    case ATTENDEE:
      return (
        <>
          <AttendeeFilter
            profiles={profiles}
            attendeeFilters={filterStore.attendeeFilters}
            actions={actions}
            intl={intl}
          />
          <Divider />
        </>
      );
    case EVENT_TYPE:
      return (
        <>
          <EventTypeFilter
            actions={actions}
            eventTypes={eventTypes}
            intl={intl}
            history={history}
            location={location}
            match={match}
            typeFilters={filterStore.typeFilters}
          />
          <Divider />
        </>
      );
    case LOCATION:
      return locations.size > 1 ? (
        <>
          <LocationFilter
            locations={locations}
            locationFilters={filterStore.locationFilters}
            actions={actions}
            intl={intl}
          />
          <Divider />
        </>
      ) : null;
    default:
      return null;
  }
}

export const Filters = injectIntl(
  ({ filters, headerText, style, ...props }) => (
    <div style={{ width: 300, ...style }}>
      <Divider />
      {filters.map(type => (
        <div key={type}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Filter type={type} {...props} />
        </div>
      ))}
    </div>
  )
);

Filters.defaultProps = {
  filters: [],
};

export default Filters;
