import { Map } from 'immutable';
import moment from 'moment-timezone';

import { t, translateWithValues } from 'shared/utils/LocaleUtils.js';

export function lastN(string, count, prefix = '...') {
  if (!string || !string.length) return string;

  if (string.length < count) return string;

  return `${prefix}${string.substr(string.length - count)}`;
}

// TODO: Have I18n library handle this.
export const numberToCurrency = (number = 0, options = {}) => {
  let result = number;
  if (!number.toFixed) {
    result = parseFloat(result, 10);
  }

  const { precision = 2, currency = '$', fromCents = false } = options;

  if (fromCents) {
    result /= 100;
  }

  return `${currency}${result.toFixed(precision)}`;
};

export const numberToPercent = (number = 0, options = {}) => {
  const { precision = 0 } = options;
  return `${number.toFixed(precision)}%`;
};

// TODO: Use moment.duration.format() for this when
// https://github.com/moment/moment/issues/1048 is done.
export function humanizeDuration(dur, unit = 'seconds') {
  let duration;
  if (moment.isDuration(dur)) {
    duration = dur;
  } else {
    duration = moment.duration(dur, unit);
  }

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const hoursText = !!hours ? `${hours}h` : ''; // eslint-disable-line no-extra-boolean-cast
  const minutesText = !!minutes ? `${minutes}m` : ''; // eslint-disable-line no-extra-boolean-cast

  return `${hoursText} ${minutesText}`.trim();
}

/*
 * @param {object} duration a Moment duration
 *
 * @param {string} precision one of years\months|days;
 *        specifies how precisely the duration should be rendered. If days or months is passed and
 *        the value of either is 0 (zero), that component of the resulting string will be left off.
 *
 * @param {string} style one of short|medium|long;
 *        the style to use when internationalizing the resulting string, assumes intl is also passed
 *
 * @param {object} intl the I18n object used to translate the resulting string
 *
 * @return {string} the duration in terms of years, months, & days
 */
export function humanizeDateDuration({ duration, precision, styles, intl }) {
  const days = 1;
  const months = 2;
  const years = 3;
  const daysDiff = duration.days();
  const monthsDiff = duration.months();
  const yearsDiff = duration.years();
  let downTo = { days, months, years }[precision] || months;

  const translated = (value, key) =>
    translateWithValues({
      id: `durations.${key}.${styles[key] || styles.default || styles.medium}`,
      intl,
      values: { n: value },
    });

  if (monthsDiff + yearsDiff === 0) {
    if (daysDiff === 0) {
      return intl ? t('durations.zero', intl) : 'today';
    }
    return intl ? translated(daysDiff, 'days') : `${daysDiff} d.`;
  }

  const valuesMap = Map().withMutations(map => {
    if (intl) {
      map.set(translated(yearsDiff, 'years'), [yearsDiff, years]);
      map.set(translated(monthsDiff, 'months'), [monthsDiff, months]);
      map.set(translated(daysDiff, 'days'), [daysDiff, days]);
    } else {
      map.set(`${yearsDiff} yr.`, [yearsDiff, years]);
      map.set(`${monthsDiff} mo.`, [monthsDiff, months]);
      map.set(`${daysDiff} d.`, [daysDiff, days]);
    }
  });

  const valueResolver = () =>
    valuesMap
      .reduce((acc, v, k) => {
        if (v[0] > 0 && v[1] >= downTo) {
          return `${acc} ${k}`;
        }
        return acc;
      }, '')
      .trim();

  let value = valueResolver();

  if (value.length > 0) {
    return value;
  }
  while (downTo > 0) {
    // we can't resolve to the specificity requested, so try to resolve as closely as possible
    downTo -= 1;
    value = valueResolver();
    if (value.length > 0) {
      return value;
    }
  }
  return `0 ${precision}`;
}

/**
 * Removes protocol and www subdomain from the given url. Subdomains other than
 * www are not removed.
 *
 * @param {string} url The url to humanize.
 * @return {string} New humanized url string.
 */
export function humanizeUrl(url) {
  if (url) {
    // eslint-disable-line no-extra-boolean-cast
    return url.replace(/^.*:\/\//, '').replace(/^www./, '');
  }
  return null;
}

export function shortenedUrl(url, maxLength = '35') {
  if (!url) return '';
  const urlLength = url.length;
  const firstLength = maxLength * 0.45;
  return urlLength < maxLength
    ? url
    : `${url.substring(0, firstLength)}...${url.substring(
        urlLength - 20,
        urlLength
      )}`;
}

export function shortHumanUrl(url, maxLength = '35') {
  if (!url) return '';
  return shortenedUrl(humanizeUrl(url), maxLength);
}

export const unwrappableString = s => {
  if (s && s.length) {
    return s.replace(/ /g, '\u00a0');
  }
  return s;
};

/**
 * Converts a byte count into a human readable string.
 *
 * @param {number} byteCount
 * @param {number} precision
 */
export const humanizeBytes = (byteCount, precision = 1) => {
  const i = Math.floor(Math.log(byteCount) / Math.log(1024));
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i];

  // eslint-disable-next-line no-restricted-properties
  return `${(byteCount / 1024 ** i).toFixed(precision)} ${unit}`;
};

export const padStart = (string, size, padWith = '0') => {
  const paddingNeeded = size - string.length;

  if (paddingNeeded > 0) {
    return padWith.repeat(paddingNeeded) + string;
  }
  return string;
};

/**
 * Convert string form snake_case to Title Case
 *
 * @param {string} string The string to format.
 */

export const snakeToTitleCase = string =>
  string
    .replace(
      /([a-z])([A-Z])/g,
      (allMatches, firstMatch, secondMatch) => `${firstMatch}${secondMatch}`
    )
    .toLowerCase()
    .replace(
      /([ -_]|^)(.)/g,
      (allMatches, firstMatch, secondMatch) =>
        `${firstMatch ? ' ' : ''}${secondMatch.toUpperCase()}`
    );
