import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { uhColors } from 'shared/styles/uhStyles.jsx';

function SignIn({ ...props }) {
  return (
    <SvgIcon
      viewBox="0 0 235.7 167.3"
      style={{ enableBackground: 'new 0 0 235.7 167.3' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g fill={uhColors.white}>
        <path
          className="st0"
          d="M1.8,15.6c1.4,1.5,3.6,2.9,6.5,2.9c3.3,0,4.5-1.7,4.5-3.2c0-2.2-2.3-2.8-5-3.5c-3.3-0.9-7.1-1.8-7.1-6
    		c0-3.4,3-5.9,7.3-5.9c3.2,0,5.7,1,7.5,2.9l-1.8,2.3c-1.6-1.7-3.7-2.4-6-2.4c-2.3,0-3.9,1.2-3.9,2.9c0,1.9,2.2,2.4,4.8,3.1
    		c3.4,0.9,7.3,1.9,7.3,6.3c0,3.2-2.2,6.2-7.8,6.2c-3.7,0-6.4-1.3-8.1-3.3L1.8,15.6z"
        />
        <path
          className="st0"
          d="M19,1.9c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8C19.8,3.6,19,2.8,19,1.9z M19.4,6h2.8v14.9
    		h-2.8V6z"
        />
        <path
          className="st0"
          d="M27.8,22.7c1.2,1.4,2.7,2,4.8,2c2.3,0,4.6-1.1,4.6-4.3v-1.9c-1.1,1.5-2.9,2.6-4.9,2.6c-3.9,0-6.6-2.8-6.6-7.7
    		c0-4.8,2.8-7.7,6.6-7.7c1.9,0,3.7,0.9,4.9,2.5V6H40v14.3c0,5.1-3.7,6.6-7.4,6.6c-2.5,0-4.3-0.5-6.1-2.2L27.8,22.7z M37.2,10.3
    		c-0.8-1.2-2.5-2.2-4.1-2.2c-2.8,0-4.5,2.2-4.5,5.2c0,3.1,1.7,5.2,4.5,5.2c1.7,0,3.4-1,4.1-2.2V10.3z"
        />
        <path
          className="st0"
          d="M54.5,11.4c0-2.5-1.3-3.3-3.2-3.3c-1.7,0-3.3,1-4.1,2.2v10.6h-2.8V6h2.8v2.1c1-1.2,3-2.5,5.3-2.5
    		c3.1,0,4.8,1.7,4.8,4.8v10.5h-2.8V11.4z"
        />
        <path
          className="st0"
          d="M69.2,1.9c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S72,3.6,71,3.6C70,3.6,69.2,2.8,69.2,1.9z M69.6,6h2.8v14.9
    		h-2.8V6z"
        />
        <path
          className="st0"
          d="M86.9,11.4c0-2.5-1.3-3.3-3.2-3.3c-1.7,0-3.3,1-4.1,2.2v10.6h-2.8V6h2.8v2.1c1-1.2,3-2.5,5.3-2.5
    		c3.1,0,4.8,1.7,4.8,4.8v10.5h-2.8V11.4z"
        />
        <path
          className="st0"
          d="M94.7,17.6V8.4h-2.5V6h2.5V1.9h2.8V6h3v2.4h-3V17c0,1,0.5,1.8,1.5,1.8c0.6,0,1.2-0.3,1.5-0.6l0.7,2.1
    		c-0.6,0.6-1.5,1-2.9,1C95.9,21.3,94.7,19.9,94.7,17.6z"
        />
        <path
          className="st0"
          d="M102.6,13.4c0-4.3,2.9-7.8,7.5-7.8c4.7,0,7.5,3.5,7.5,7.8c0,4.3-2.9,7.8-7.5,7.8
    		C105.5,21.3,102.6,17.7,102.6,13.4z M114.8,13.4c0-2.8-1.6-5.3-4.6-5.3c-3,0-4.6,2.5-4.6,5.3c0,2.8,1.7,5.4,4.6,5.4
    		C113.1,18.8,114.8,16.3,114.8,13.4z"
        />
      </g>
      <g fill={uhColors.white}>
        <path
          className="st1"
          d="M31.8,89.7v-3.9c-5.1,3-10.6,5.2-17,5.2C1.5,90.9,0,81.7,0,71.9V41.1h14.8v30.7c0,5.9,1,7.9,5.8,7.9
    		c2.8,0,6.4-1.1,10.4-2.7V41.1h14.8v48.5H31.8z"
        />
        <path
          className="st1"
          d="M76.4,90.9c-2.8,0-6.5-0.3-8.5-0.8v16.9H53.1V41.1h13.7v3.1c4.5-3.1,9.6-4.3,14.4-4.3c10.9,0,17.8,6,17.8,23.1
    		v4.3C99,84.9,90.3,90.9,76.4,90.9z M84.2,62.9c0-9.3-2.3-11.7-7.9-11.7c-2.5,0-4.9,0.4-8.5,2V79c2.6,0.4,5,0.8,7.3,0.8
    		c6,0,9.1-2,9.1-12.5V62.9z"
        />
        <path
          className="st1"
          d="M127.8,90.9c-2.8,0-6.5-0.3-8.5-0.8v16.9h-14.8V41.1h13.7v3.1c4.5-3.1,9.6-4.3,14.4-4.3
    		c10.9,0,17.8,6,17.8,23.1v4.3C150.4,84.9,141.7,90.9,127.8,90.9z M135.6,62.9c0-9.3-2.3-11.7-7.9-11.7c-2.5,0-4.9,0.4-8.5,2V79
    		c2.6,0.4,5,0.8,7.3,0.8c6,0,9.1-2,9.1-12.5V62.9z"
        />
        <path
          className="st1"
          d="M168.8,69.4v0.7c0,6.4,0.9,9.9,11.3,9.9c4.9,0,10-0.3,18.2-0.9v9.8c-3.7,0.9-10.6,2.1-18.9,2.1
    		c-17.8,0-25.3-6.2-25.3-24v-2.6c0-16.9,8.1-24.4,23.4-24.4c18.2,0,23.2,11.6,23.2,25.5v4H168.8z M186.1,60.6
    		c0-5.4-1.1-10.2-8.7-10.2c-7.3,0-8.6,4.9-8.6,10.2v0.7h17.3V60.6z"
        />
        <path
          className="st1"
          d="M220.7,54.5v35.1h-14.8V41.1h13.8v4c4.8-4.3,10.7-5.3,16-5.3v12.4C230.3,52.3,224.5,53.2,220.7,54.5z"
        />
        <path
          className="st1"
          d="M31.1,166v-30.6c0-6.3-1.6-8.1-6.4-8.1c-3.7,0-7.6,1.3-9.8,2.3V166H0.1V99.7h14.8v19.9
    		c3.7-1.9,9.1-3.4,14.6-3.4c13.4,0,16.4,7.8,16.4,19.1V166H31.1z"
        />
        <path
          className="st1"
          d="M80.3,166v-3.1c-3.2,2.1-8.8,4.4-15.2,4.4c-9.2,0-14.2-4.7-14.2-13.9v-2c0-12.9,9.9-14.5,23.7-14.5h4.5v-2.6
    		c0-6-1.3-7.1-8.9-7.1c-4.3,0-10.2,0.3-15.7,0.9v-8.9c2.7-1,10.6-3,18.7-3c15.1,0,20.7,4.9,20.7,18.1V166H80.3z M79.6,144.7h-4.9
    		c-6.7,0-9.6,0.8-9.6,6.3v2c0,3.8,2.3,4.9,5.5,4.9c3.8,0,7.6-1.4,9-2.3V144.7z"
        />
        <path
          className="st1"
          d="M131.7,166v-30.7c0-5.9-1.1-7.9-5.8-7.9c-2.7,0-6.5,1.1-10.4,2.7V166h-14.8v-48.5h14v3.9
    		c4.9-3.1,10.4-5.2,15.9-5.2c14.6,0,15.9,8.5,15.9,19.1V166H131.7z"
        />
        <path
          className="st1"
          d="M183.8,166v-2.5c-3.3,2-7.9,3.7-13.7,3.7c-11.6,0-18.4-7.1-18.4-22.3v-6c0-17,8.4-22.8,22-22.8
    		c3.5,0,6.2,0.3,8.9,0.8V99.7h14.8V166H183.8z M182.9,128.1c-2.6-0.4-5.4-0.7-7.3-0.7c-6,0-9,1.8-9,11.7v6c0,8.2,2.1,11.1,8.3,11.1
    		c2.8,0,5.5-0.7,8-1.7V128.1z"
        />
      </g>
    </SvgIcon>
  );
}

export default SignIn;
