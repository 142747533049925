import { List, fromJS } from 'immutable';
import WaitlistActions from 'event_mgmt/shared/actions/WaitlistActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class WaitlistStore {
  constructor() {
    this.eventId = null;
    this.waitlist = List();
    this.isHydrated = false;
    this.isUpdating = false;

    this.bindListeners({
      handleFetch: WaitlistActions.fetch,
      handleFetchSuccess: WaitlistActions.fetchSuccess,
      handleAppend: WaitlistActions.append,
      handleAppendSuccess: WaitlistActions.appendSuccess,
      handleInvite: WaitlistActions.invite,
      handleInviteSuccess: WaitlistActions.inviteSuccess,
      handleDelete: WaitlistActions.delete,
      handleDeleteSuccess: WaitlistActions.deleteSuccess,
      handleError: WaitlistActions.error,
    });
  }

  handleFetch(eventId) {
    this.eventId = eventId;

    if (!this.eventId) {
      return false;
    }

    this.isUpdating = true;

    return uhApiClient.get({
      url: `events/${this.eventId}/waitlist`,
      success: WaitlistActions.fetchSuccess,
      error: WaitlistActions.error,
      requireToken: false,
    });
  }

  handleFetchSuccess(data) {
    this.waitlist = fromJS(data.waitlist_entries);
    this.spotsRemaining = data.spots_remaining;
    this.isHydrated = true;
    this.isUpdating = false;
  }

  handleAppend([eventId, customerUserIds]) {
    this.eventId = eventId;

    if (!this.eventId) {
      return false;
    }

    this.isUpdating = true;

    return uhApiClient.patch({
      url: `events/${this.eventId}/waitlist`,
      data: JSON.stringify({
        attributes: { customer_user_ids: customerUserIds },
      }),
      success: WaitlistActions.appendSuccess,
      error: WaitlistActions.error,
      requireToken: false,
    });
  }

  handleAppendSuccess(data) {
    this.handleFetchSuccess(data);
    MessageWindowActions.addMessage.defer('Attendee(s) added to Waiting list.');
  }

  handleInvite(customerUserIds) {
    if (!this.eventId) {
      return false;
    }

    this.isUpdating = true;

    return uhApiClient.patch({
      url: `events/${this.eventId}/waitlist/invite`,
      data: JSON.stringify({
        attributes: { customer_user_ids: customerUserIds },
      }),
      success: WaitlistActions.inviteSuccess,
      error: WaitlistActions.error,
      requireToken: false,
    });
  }

  handleInviteSuccess(data) {
    this.handleFetchSuccess(data);
  }

  handleDelete(customerUserIds) {
    if (!this.eventId) {
      return false;
    }

    this.isUpdating = true;

    return uhApiClient.delete({
      url: `events/${this.eventId}/waitlist`,
      data: JSON.stringify({
        attributes: { customer_user_ids: customerUserIds },
      }),
      success: WaitlistActions.deleteSuccess,
      error: WaitlistActions.error,
      requireToken: false,
    });
  }

  handleDeleteSuccess(data) {
    this.handleFetchSuccess(data);
  }

  handleError(_err) {
    this.isHydrated = false;
    this.isUpdating = false;
  }
}

export default alt.createStore(WaitlistStore, 'WaitlistStore');
