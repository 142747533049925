/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { Drawer, Dropdown, Button, Grid } from '@upperhand/playmaker';
import PropTypes from 'prop-types';

import DateRangeDropdown from 'components/DateRangeDropdown';

import { capitalizeString } from 'shared/utils/StringUtils.jsx';

import { CAMPAIGN_STATUSES } from 'shared/records/EmailCampaign';

import { t } from 'shared/utils/LocaleUtils';

function FiltersDrawer({
  filters = Map(),
  appliedFilters = Map(),
  open = false,
  filtersToShow = ['created', 'sent', 'status'],
  onClose = () => null,
  onChange = () => null,
  onApply = () => null,
  onClearFilters = () => null,
}) {
  const intl = useIntl();
  const statuses = Object.values(CAMPAIGN_STATUSES).map(status => ({
    label: capitalizeString(status),
    value: status,
  }));

  return (
    <Drawer
      title={t('.filters', intl, __filenamespace)}
      open={open}
      classes={{
        root: 'marketing-filters-drawer',
        content: 'marketing-filters-drawer__content',
        footer: 'marketing-filters-drawer__footer',
      }}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          {appliedFilters.size > 0 && (
            <Grid item xs={12}>
              <div
                role="presentation"
                className="marketing-filters-drawer__clear"
                onClick={onClearFilters}
              >
                {t('.clear', intl, __filenamespace)}
              </div>
            </Grid>
          )}
          {filtersToShow.includes('created') && (
            <Grid item xs={12}>
              <DateRangeDropdown
                label={t('.date_created', intl, __filenamespace)}
                className="marketing-filters-drawer__date-created"
                value={filters.get('date_created')?.value}
                onChange={v => onChange('date_created', v)}
              />
            </Grid>
          )}
          {filtersToShow.includes('sent') && (
            <Grid item xs={12}>
              <DateRangeDropdown
                label={t('.date_sent', intl, __filenamespace)}
                className="marketing-filters-drawer__date-sent"
                value={filters.get('date_sent')?.value}
                onChange={v => onChange('date_sent', v)}
              />
            </Grid>
          )}
          {filtersToShow.includes('status') && (
            <Grid item xs={12}>
              <Dropdown
                fullWidth
                name="status"
                label={t('.status', intl, __filenamespace)}
                value={filters.get('status')}
                onChange={e => onChange('status', e.target.value)}
                items={statuses}
              />
            </Grid>
          )}
        </Grid>
      }
      footer={
        <Button fullWidth type="primary" onClick={onApply}>
          {t('.apply', intl, __filenamespace)}
        </Button>
      }
    />
  );
}

FiltersDrawer.propTypes = {
  filters: PropTypes.instanceOf(Map),
  appliedFilters: PropTypes.instanceOf(Map),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  onClearFilters: PropTypes.func,
};

export default memo(FiltersDrawer);
