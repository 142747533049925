import { List, Set } from 'immutable';
import AccountingCode, { CODE_TYPES } from 'shared/records/AccountingCode';
import AccountingCodeArchivingActions from 'shared/actions/accounting_codes/AccountingCodeArchivingActions';
import AccountingCodeCreationActions from 'shared/actions/accounting_codes/AccountingCodeCreationActions';
import AccountingCodeEditingActions from 'shared/actions/accounting_codes/AccountingCodeEditingActions';
import AccountingCodeListingActions from 'shared/actions/accounting_codes/AccountingCodeListingActions';
import AccountingCodeListingStore from 'shared/stores/accounting_codes/AccountingCodeListingStore';
import EventTypeEditingActions from 'shared/actions/EventTypeEditingActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import TaxRateCreationActions from 'shared/actions/tax_rates/TaxRateCreationActions';
import TaxRateEditingActions from 'shared/actions/tax_rates/TaxRateEditingActions';
import TaxRateListingStore from 'shared/stores/tax_rates/TaxRateListingStore';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import RetailCategoryEditingActions from 'shared/actions/RetailCategoryEditingActions.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import TeamTypeEditingActions from 'shared/actions/TeamTypeEditingActions.jsx';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';
import TeamTypeListingStore from '../TeamTypeListingStore.jsx';

const url = accountingCode =>
  accountingCode.id
    ? `accounting_codes/${accountingCode.id}`
    : 'accounting_codes';

export default class AccountingCodeEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();

    this.accountingCode = null;
    this.isSaving = false;

    this.eventTypes = List();
    this.retailCategories = List();
    this.codeTypes = CODE_TYPES;
    this.taxRates = List();
    this.teamTypes = List();

    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      updateAccountingCode: this.actions.UPDATED,
      updateCodeType: this.actions.CODE_TYPE_UPDATED,

      addEventType: this.actions.EVENT_TYPE_ADDED,
      removeEventType: this.actions.EVENT_TYPE_REMOVED,

      addTeamType: this.actions.TEAM_TYPE_ADDED,
      removeTeamType: this.actions.TEAM_TYPE_REMOVED,

      addRetailCategory: this.actions.RETAIL_CATEGORY_ADDED,
      removeRetailCategory: this.actions.RETAIL_CATEGORY_REMOVED,

      addRate: this.actions.RATE_ADDED,
      removeRate: this.actions.RATE_REMOVED,

      beginEdit: this.actions.BEGIN,
      cancelEdit: this.actions.CANCEL,

      createOrUpdate: this.actions.SAVE,
      createOrUpdateSuccess: this.actions.SAVE_SUCCESS,
      createOrUpdateError: this.actions.SAVE_ERROR,

      setCodeTypes: [
        AccountingCodeArchivingActions.ARCHIVE_SUCCESS,
        AccountingCodeCreationActions.SAVE_SUCCESS,
        AccountingCodeEditingActions.SAVE_SUCCESS,
        AccountingCodeListingActions.LIST_SUCCESS,
      ],

      setEventTypes: [
        AccountingCodeArchivingActions.ARCHIVE_SUCCESS,
        AccountingCodeCreationActions.SAVE_SUCCESS,
        AccountingCodeEditingActions.SAVE_SUCCESS,
        AccountingCodeListingActions.LIST_SUCCESS,
        EventTypeEditingActions.CREATE_OR_UPDATE_SUCCESS,
        EventTypeListingActions.LIST_SUCCESS,
      ],

      setTeamTypes: [
        AccountingCodeArchivingActions.ARCHIVE_SUCCESS,
        AccountingCodeCreationActions.SAVE_SUCCESS,
        AccountingCodeEditingActions.SAVE_SUCCESS,
        AccountingCodeListingActions.LIST_SUCCESS,
        TeamTypeEditingActions.CREATE_OR_UPDATE_SUCCESS,
        TeamTypeListingActions.LIST_SUCCESS,
      ],

      setRetailCategories: [
        AccountingCodeArchivingActions.ARCHIVE_SUCCESS,
        AccountingCodeCreationActions.SAVE_SUCCESS,
        AccountingCodeEditingActions.SAVE_SUCCESS,
        AccountingCodeListingActions.LIST_SUCCESS,
        RetailCategoryEditingActions.CREATE_OR_UPDATE_SUCCESS,
        RetailCategoryListingActions.LIST_SUCCESS,
      ],

      setTaxRates: [
        TaxRateCreationActions.SAVE_SUCCESS,
        TaxRateEditingActions.SAVE_SUCCESS,
      ],
    });
  }

  updateAccountingCode(updatedFields) {
    if (!this.accountingCode) {
      return;
    }

    this.accountingCode = this.accountingCode.merge(updatedFields);
  }

  updateCodeType(codeType) {
    if (!this.accountingCode) {
      return;
    }

    this.eventTypes = this.eventTypes
      .concat(this.accountingCode.event_types)
      .sortBy(t => t.name);

    this.teamTypes = this.teamTypes
      .concat(this.accountingCode.team_types)
      .sortBy(t => t.name);

    this.retailCategories = this.retailCategories
      .concat(this.accountingCode.retail_categories)
      .sortBy(c => c.name);

    this.accountingCode = this.accountingCode.merge({
      code_type: codeType,
      event_type_ids: Set(),
      event_types: List(),
      retail_category_ids: Set(),
      retail_categories: List(),
      team_type_ids: Set(),
      team_types: List(),
    });
  }

  addEventType(eventTypeId) {
    const [index, eventType] = this.eventTypes.findEntry(
      t => t.id === eventTypeId,
      null,
      [-1, undefined]
    );

    if (eventType) {
      this.accountingCode = this.accountingCode.addEventType(eventType);
      this.eventTypes = this.eventTypes.delete(index);
    }
  }

  removeEventType(eventTypeId) {
    const eventType = this.accountingCode.event_types.find(
      t => t.id === eventTypeId
    );

    if (eventType) {
      this.accountingCode = this.accountingCode.removeEventType(eventType);
      this.eventTypes = this.eventTypes.push(eventType).sortBy(t => t.name);
    }
  }

  addTeamType(teamTypeId) {
    const [index, teamType] = this.teamTypes.findEntry(
      t => t.id === teamTypeId,
      null,
      [-1, undefined]
    );

    if (teamType) {
      this.accountingCode = this.accountingCode.addTeamType(teamType);
      this.teamTypes = this.teamTypes.delete(index);
    }
  }

  removeTeamType(teamTypeId) {
    const teamType = this.accountingCode.team_types.find(
      t => t.id === teamTypeId
    );

    if (teamType) {
      this.accountingCode = this.accountingCode.removeTeamType(teamType);
      this.teamTypes = this.teamTypes.push(teamType).sortBy(t => t.name);
    }
  }

  addRetailCategory(retailCategoryId) {
    const [index, retailCategory] = this.retailCategories.findEntry(
      c => c.id === retailCategoryId,
      null,
      [-1, undefined]
    );

    if (retailCategory) {
      this.accountingCode =
        this.accountingCode.addRetailCategory(retailCategory);
      this.retailCategories = this.retailCategories.delete(index);
    }
  }

  removeRetailCategory(retailCategoryId) {
    const retailCategory = this.accountingCode.retail_categories.find(
      c => c.id === retailCategoryId
    );

    if (retailCategory) {
      this.accountingCode =
        this.accountingCode.removeRetailCategory(retailCategory);
      this.retailCategories = this.retailCategories
        .push(retailCategory)
        .sortBy(c => c.name);
    }
  }

  addRate(taxRateId) {
    const [index, taxRate] = this.taxRates.findEntry(
      r => r.id === taxRateId,
      null,
      [-1, undefined]
    );

    if (taxRate) {
      this.accountingCode = this.accountingCode.addTaxRate(taxRate);
      this.taxRates = this.taxRates.delete(index);
    }
  }

  removeRate(taxRateId) {
    const taxRate = this.accountingCode.tax_rates.find(r => r.id === taxRateId);

    if (taxRate) {
      this.accountingCode = this.accountingCode.removeTaxRate(taxRate);
      this.taxRates = this.taxRates.push(taxRate);
    }
  }

  beginEdit(accountingCode) {
    this.accountingCode = accountingCode || new AccountingCode();
    this.setCodeTypes();
    this.setTaxRates();
  }

  cancelEdit() {
    this.accountingCode = null;
  }

  createOrUpdate() {
    if (this.isSaving) {
      return;
    }

    this.accountingCode = this.accountingCode.validate();

    if (this.accountingCode.isValid()) {
      this.isSaving = true;
      const params = this.accountingCode.toServer();
      delete params.charge_type;

      const saveValue = {
        url: url(this.accountingCode),
        data: JSON.stringify({ attributes: params }),
        success: this.actions.saveSuccess,
        error: this.actions.saveError,
      };

      if (this.accountingCode.id) {
        uhApiClient.put(saveValue);
      } else {
        uhApiClient.post(saveValue);
      }
    }
  }

  createOrUpdateSuccess(_data) {
    this.accountingCode = null;
    this.isSaving = false;
    MessageWindowActions.addMessage.defer(this.successMessage);
  }

  createOrUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('error updating accountingCode', args);
  }

  setEventTypes() {
    this.waitFor([EventTypeListingStore, AccountingCodeListingStore]);

    const { selectedEventTypeIds } = AccountingCodeListingStore.getState();
    const { eventTypes } = EventTypeListingStore.getState();

    this.eventTypes = eventTypes.filter(t => !selectedEventTypeIds.has(t.id));
  }

  setTeamTypes() {
    this.waitFor([TeamTypeListingStore, AccountingCodeListingStore]);

    const { selectedTeamTypeIds } = AccountingCodeListingStore.getState();
    const { teamTypes } = TeamTypeListingStore.getState();

    this.teamTypes = teamTypes.filter(t => !selectedTeamTypeIds.has(t.id));
  }

  setRetailCategories() {
    this.waitFor([RetailCategoryListingStore, AccountingCodeListingStore]);

    const { selectedRetailCategoryIds } = AccountingCodeListingStore.getState();
    const { retailCategories } = RetailCategoryListingStore.getState();

    this.retailCategories = retailCategories.filterNot(c =>
      selectedRetailCategoryIds.has(c.id)
    );
  }

  setTaxRates(data) {
    if (!this.accountingCode) {
      return;
    }

    this.waitFor([TaxRateListingStore]);
    const { taxRates } = TaxRateListingStore.getState();

    this.taxRates = taxRates
      .filter(
        t =>
          !this.accountingCode.tax_rate_ids ||
          !this.accountingCode.tax_rate_ids.includes(t.id)
      )
      .sortBy(t => t.name);

    if (data && data.id) {
      this.addRate(data.id);
    }

    this.accountingCode = this.accountingCode.set(
      'tax_rates',
      taxRates
        .filter(t => this.accountingCode.tax_rate_ids.includes(t.id))
        .sortBy(t => t.name)
    );
  }

  setCodeTypes() {
    this.waitFor(AccountingCodeListingStore);

    const { selectedCodeTypes } = AccountingCodeListingStore.getState();
    const currentCodeType =
      this.accountingCode && this.accountingCode.code_type;

    // Only MEMBERSHIP_CODE_TYPE must be unique.
    this.codeTypes = CODE_TYPES.filterNot(
      type =>
        (type === CODE_TYPES.MEMBERSHIP_CODE_TYPE ||
          type === CODE_TYPES.CREDIT_PASS_CODE_TYPE) &&
        type !== currentCodeType &&
        selectedCodeTypes.has(type)
    );
  }
}
