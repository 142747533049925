import * as React from 'react';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Typography } from '@upperhand/playmaker';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import WrappableName from 'shared/components/WrappableName.jsx';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { humanizeDateDuration } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import EditMembershipExpireActions from 'containers/clientProfile/components/EditMembershipExpireModal/EditMembershipExpireActions.js';
import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions.js';
import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions.js';
import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';

const ACTIVE = 'active';
const SUSPENDED = 'suspended';
const CANCELLED = 'cancelled';
const INVITED = 'invited';
const ALL = 'all';

const styles = {
  clientCard: {
    marginTop: 10,
    padding: '12px 24px',
    position: 'relative',
  },

  clientCardContainer: {
    alignItems: 'center',
    flex: '0 0 55%',
  },

  clientName: {
    cursor: 'pointer',
  },

  managingUserContainer: {
    marginTop: 4,
  },

  managingUserIcon: {
    height: 12,
    width: 12,
    marginRight: 8,
  },

  menu: {
    top: 6,
    bottom: 0,
    right: 0,
    margin: 'auto',
    position: 'absolute',
    color: 'white',
  },

  transactionInfoLarge: {
    textAlign: 'right',
    flex: '1 0 auto',
  },

  transactionInfoSmall: {
    textAlign: 'left',
    flex: '1 0 auto',
  },
  invite: {
    color: uhColors.lightBlue,
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'inline',
  },
};

const getCurrentSubscription = (subscriptions, activeFilter) => {
  // if no subscriptions
  if (!subscriptions.size) {
    return Map();
  }
  // Fetches the subscription with a status matching the active filter
  const currentSubscriptionWithFilter = subscriptions.find(
    s => s.status === activeFilter
  );
  if (currentSubscriptionWithFilter) {
    return currentSubscriptionWithFilter;
  }
  // check if current subscription is lifetime
  const clientRecentSubscription =
    subscriptions.first().get('expires_at') === null
      ? subscriptions.first()
      : subscriptions.last();

  return subscriptions.find(s => !s.is_cancelled) || clientRecentSubscription;
};

const ContextMenu = injectIntl(
  ({
    client,
    membership,
    isCancelled,
    isExpired,
    isPreSale,
    isSuspended,
    isInvite,
    suspendedAt,
    isLifeTime,
    intl,
    subscription,
  }) => {
    const [anchorEl, setAnchorEl] = React.useState();
    const open = Boolean(anchorEl);
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              ClientProfileDrawerActions.mounted({ clientId: client.id });
            }}
          >
            {t('actions.view', intl)}
          </MenuItem>
          {!isInvite && (
            <MenuItem
              onClick={() => {
                handleClose();
                BalanceListDrawerActions.openDrawer({
                  clientId: client.managing_customer_user?.id || client.id,
                  membershipSubscriberId: client.id,
                  membershipIds: [membership.id],
                  balanceRemainingMax: null,
                });
              }}
            >
              {t('.balances', intl, __filenamespace)}
            </MenuItem>
          )}
          {!isCancelled &&
            !isExpired &&
            !suspendedAt &&
            !isLifeTime &&
            !isPreSale &&
            !isInvite && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  MembershipSuspensionActions.suspensionRequested(
                    client,
                    membership
                  );
                }}
              >
                {t('.suspend_membership', intl, __filenamespace)}
              </MenuItem>
            )}
          {suspendedAt && !isSuspended && (
            <MenuItem
              onClick={() => {
                handleClose();
                MembershipSuspensionActions.reactivationRequested(
                  client,
                  membership,
                  true
                );
              }}
            >
              {t('.remove_suspension', intl, __filenamespace)}
            </MenuItem>
          )}
          {isSuspended && (
            <MenuItem
              onClick={() => {
                handleClose();
                MembershipSuspensionActions.reactivationRequested(
                  client,
                  membership,
                  false
                );
              }}
            >
              {t('.reactivate_membership', intl, __filenamespace)}
            </MenuItem>
          )}
          {!isCancelled && !isInvite && !isExpired && !isSuspended && (
            <MenuItem
              onClick={() => {
                handleClose();
                MembershipCancellationActions.cancellationRequested(
                  client,
                  membership
                );
              }}
            >
              {t('.cancel_membership', intl, __filenamespace)}
            </MenuItem>
          )}
          {!isCancelled && !isInvite && !isExpired && !isSuspended && (
            <MenuItem
              onClick={() => {
                handleClose();
                EditMembershipExpireActions.openModal(subscription);
              }}
            >
              {t('.edit', intl, __filenamespace)}
            </MenuItem>
          )}
        </Menu>
      </>
    );
  }
);

function ManagingUserInfo({ client }) {
  if (!client.managing_customer_user) {
    return null;
  }

  return (
    <FlexBoxCenter style={styles.managingUserContainer}>
      <ManagingUserIcon style={styles.managingUserIcon} />
      <WrappableName
        nameable={client.managing_customer_user}
        className="mem-view-client-name"
        style={styles.clientName}
      />
    </FlexBoxCenter>
  );
}

function SuspendedIndicator({ isSuspended, suspendedAt, reactivationDate }) {
  return isSuspended && !reactivationDate ? (
    <FlexBox style={{ flexDirection: 'column' }}>
      <span style={{ fontSize: 'smaller', color: 'red', marginBottom: 2 }}>
        <FormattedMessage id={messageId('.suspended', __filenamespace)} />
      </span>
    </FlexBox>
  ) : (
    <FlexBox style={{ flexDirection: 'column' }}>
      <span style={{ fontSize: 'smaller', color: 'red', marginBottom: 2 }}>
        <FormattedMessage
          id={messageId(
            `${isSuspended ? '.suspended_until' : '.suspending'}`,
            __filenamespace
          )}
        />
      </span>
      <span style={{ fontSize: 'smaller', marginTop: 2 }}>
        {isSuspended
          ? moment(reactivationDate).format('MM.DD.YYYY')
          : moment(suspendedAt).format('MM.DD.YYYY')}
      </span>
    </FlexBox>
  );
}

const MemberLength = injectIntl(({ activeSubscriptionStartDate, intl }) =>
  activeSubscriptionStartDate > new Date() ? (
    <FormattedMessage id={messageId('.pre_sale', __filenamespace)} />
  ) : (
    <div>
      {activeSubscriptionStartDate &&
        humanizeDateDuration({
          duration: moment.duration(
            new Date() - activeSubscriptionStartDate,
            'milliseconds'
          ),
          styles: { default: 'medium', years: 'long' },
          intl,
          precision: 'months',
        })}
    </div>
  )
);

function MembershipStatus({
  isCancelled,
  isSuspended,
  cancelledAt,
  isPreSale,
  isExpired,
  reactivationDate,
  dateToShow,
  suspendedAt,
  isInvite,
  client,
  isActive,
}) {
  return (
    <Typography variant="body2">
      {isActive && (
        <span
          style={{
            fontSize: 'smaller',
            color: 'rgb(0, 230, 118)',
          }}
        >
          <FormattedMessage id={messageId('.active', __filenamespace)} />
        </span>
      )}
      {isCancelled && !isSuspended && !cancelledAt && (
        <span
          style={{
            fontSize: 'smaller',
            color: 'red',
          }}
        >
          <FormattedMessage id={messageId('.cancelled', __filenamespace)} />
        </span>
      )}
      {cancelledAt && (
        <span style={{ fontSize: 'smaller', color: 'red' }}>
          <FormattedMessage
            id={messageId('.cancelled_at', __filenamespace)}
            values={{
              date: dateToShow,
            }}
          />
        </span>
      )}
      {!isCancelled &&
        !isPreSale &&
        !!isExpired &&
        !suspendedAt &&
        !cancelledAt && (
          <span
            style={{
              fontSize: 'smaller',
              color: 'red',
            }}
          >
            <FormattedMessage id={messageId('.expired', __filenamespace)} />
          </span>
        )}
      {suspendedAt && (
        <SuspendedIndicator
          isSuspended={isSuspended}
          suspendedAt={suspendedAt}
          reactivationDate={reactivationDate}
        />
      )}
      {isInvite && <MembershipClientInviteControl client={client} />}
    </Typography>
  );
}
const MembershipClientLarge = injectIntl(
  ({
    subscription,
    activeSubscriptionStartDate,
    client,
    isCancelled,
    isExpired,
    isInvite,
    isLifeTime,
    isPreSale,
    isSuspended,
    membership,
    reactivationDate,
    suspendedAt,
    cancelledAt,
    dateToShow,
    activeFilter,
    expireAt,
    reactivateOn,
    cancelledDateTime,
    invitedAt,
    isActive,
    membershipTier,
  }) => {
    const commitment = membershipTier
      ? membershipTier.commitment_months
      : membership.commitment_months;

    let commitmentLabel = '';
    if (commitment === 0) {
      commitmentLabel = 'N/A';
    } else if (commitment < 12) {
      commitmentLabel = `${commitment} mo.`;
    } else {
      commitmentLabel = `${Math.floor(commitment / 12)} year`;
    }
    return (
      <Paper className="mem-view-client" style={styles.clientCard}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={e => {
            e.preventDefault();
          }}
        >
          <Grid
            container
            alignItems="center"
            justify="flex-start"
            className="client-card-user-info"
          >
            <Grid item className="member_card__row-item ">
              <Typography variant="body2">
                {' '}
                <FlexBoxCenter
                  className="mem-view-client-card-user-info"
                  onClick={() =>
                    ClientProfileDrawerActions.mounted({
                      clientId: client.id,
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <UserAvatar user={client} />
                  <div>
                    <FlexBoxCenter>
                      <WrappableName
                        nameable={client}
                        className="mem-view-client-name"
                        style={styles.clientName}
                      />
                    </FlexBoxCenter>
                    <ManagingUserInfo client={client} />
                  </div>
                </FlexBoxCenter>
              </Typography>
            </Grid>
          </Grid>

          {membershipTier && (
            <Grid container alignItems="center" justify="flex-start">
              <Typography variant="body2">
                {client.active_membership_tier_name}
              </Typography>
            </Grid>
          )}

          {activeFilter === ALL && (
            <Grid container alignItems="center" justify="flex-start">
              <Grid item className="member_card__row-item ">
                <Typography variant="body2">
                  <MembershipStatus
                    isCancelled={isCancelled}
                    isSuspended={isSuspended}
                    cancelledAt={cancelledAt}
                    isPreSale={isPreSale}
                    isExpired={isExpired}
                    reactivationDate={reactivationDate}
                    dateToShow={dateToShow}
                    suspendedAt={suspendedAt}
                    isInvite={isInvite}
                    client={client}
                    isActive={isActive}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}

          {(activeFilter === ACTIVE || activeFilter === ALL) && (
            <Grid container alignItems="center" justify="flex-start">
              <Grid item className="member_card__row-item">
                <Typography variant="body2">
                  <MemberLength
                    activeSubscriptionStartDate={activeSubscriptionStartDate}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}

          {activeFilter === ACTIVE && (
            <Grid container alignItems="center" justify="flex-start">
              <Grid item className="member_card__row-item">
                <Typography variant="body2">
                  <FormattedMessage
                    id={messageId('.expires_at', __filenamespace)}
                    values={{
                      expireAt,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
          {activeFilter === CANCELLED && (
            <Grid container alignItems="center" justify="flex-start">
              <Grid item className="member_card__row-item">
                <Typography variant="body2">
                  <FormattedMessage
                    id={messageId('.cancelled_at', __filenamespace)}
                    values={{
                      dateTime: cancelledDateTime,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
          {activeFilter === SUSPENDED && (
            <>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item className="member_card__row-item">
                  <Typography variant="body2">
                    <FormattedMessage
                      id={messageId('.suspend_at', __filenamespace)}
                      values={{
                        suspendedAt: moment(suspendedAt).format('MM/DD/YYYY'),
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item className="member_card__row-item">
                  <Typography variant="body2">
                    <FormattedMessage
                      id={messageId('.reactivate_on', __filenamespace)}
                      values={{
                        reactivateOn: reactivateOn
                          ? moment(reactivateOn).utc().format('MM/DD/YYYY')
                          : null,
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {activeFilter !== INVITED && (
            <>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item className="member_card__row-item">
                  <Typography variant="body2">
                    <FormattedMessage
                      id={messageId('.commitment_length', __filenamespace)}
                      values={{
                        commitment: commitmentLabel,
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item className="member_card__row-item">
                  <Typography variant="body2">
                    <FormattedCurrency
                      value={
                        membershipTier
                          ? membershipTier.price
                          : membership?.price
                      }
                      fromCents
                    />
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {activeFilter === INVITED && (
            <Grid container alignItems="center" justify="flex-start">
              <Grid item className="member_card__row-item">
                <Typography variant="body2">
                  <FormattedMessage
                    id={messageId('.invited_at', __filenamespace)}
                    values={{
                      invitedAt,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}

          {activeFilter === INVITED && (
            <Grid container alignItems="center" justify="flex-start">
              <Grid item className="member_card__row-item">
                <MembershipClientInviteControl client={client} />
              </Grid>
            </Grid>
          )}
          <div className="balances-report__row-item balances-report__row-action">
            <ContextMenu
              subscription={subscription}
              client={client}
              membership={membership}
              isCancelled={isCancelled}
              isExpired={isExpired}
              isInvite={isInvite}
              isLifeTime={isLifeTime}
              isPreSale={isPreSale}
              isSuspended={isSuspended}
              suspendedAt={suspendedAt}
            />
          </div>
        </Stack>
      </Paper>
    );
  }
);

function MembershipClientSmall({
  client,
  isCancelled,
  isExpired,
  isInvite,
  isLifeTime,
  isPreSale,
  isSuspended,
  membership,
  reactivationDate,
  suspendedAt,
  cancelledAt,
  dateToShow,
  isActive,
}) {
  return (
    <Paper className="mem-view-client" style={styles.clientCard}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="flex-start"
          className="client-card-user-info-mobile"
        >
          <Grid item className="member_card__row-item ">
            <Typography variant="body2">
              {' '}
              <FlexBoxCenter
                className="mem-view-client-card-user-info"
                onClick={() =>
                  ClientProfileDrawerActions.mounted({ clientId: client.id })
                }
                style={{ cursor: 'pointer' }}
              >
                <UserAvatar user={client} />
                <div>
                  <FlexBoxCenter>
                    <WrappableName
                      nameable={client}
                      className="mem-view-client-name"
                      style={styles.clientName}
                    />
                  </FlexBoxCenter>
                  <ManagingUserInfo client={client} />
                </div>
              </FlexBoxCenter>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ justifyContent: 'center' }}
        >
          <Grid item className="member_card__row-item ">
            <MembershipStatus
              isCancelled={isCancelled}
              isSuspended={isSuspended}
              cancelledAt={cancelledAt}
              isPreSale={isPreSale}
              isExpired={isExpired}
              reactivationDate={reactivationDate}
              dateToShow={dateToShow}
              suspendedAt={suspendedAt}
              isInvite={isInvite}
              client={client}
              isActive={isActive}
            />
          </Grid>
        </Grid>

        <ContextMenu
          client={client}
          membership={membership}
          isCancelled={isCancelled}
          isExpired={isExpired}
          isInvite={isInvite}
          isLifeTime={isLifeTime}
          isPreSale={isPreSale}
          isSuspended={isSuspended}
          suspendedAt={suspendedAt}
        />
      </Stack>
    </Paper>
  );
}

function MembershipClientInviteControl({ small, client }) {
  return (
    <div
      style={small ? styles.transactionInfoSmall : styles.transactionInfoLarge}
    >
      <span>
        <FormattedMessage id={messageId('.invited', __filenamespace)} />
        &ensp;|&ensp;
      </span>
      <div
        style={styles.invite}
        onClick={() => MembershipViewingActions.remindClicked(client.id)}
        role="presentation"
      >
        <FormattedMessage id={messageId('.remind', __filenamespace)} />
      </div>
    </div>
  );
}

function MemberCard(props) {
  const {
    client,
    membership,
    subscriptionsStore,
    activeFilter,
    membershipTierDataStore,
  } = props;
  const { tiers } = membershipTierDataStore;
  const membershipTier = tiers.get(client.active_membership_tier_id, null);

  const activeSubscriptionStartDate = subscriptionsStore.activeDateForClient(
    client.id
  );
  const subscriptions = subscriptionsStore
    .subscriptionsForClient(client.id)
    .sortBy(s => new Date(s.expires_at));
  const currentSubscription = getCurrentSubscription(
    subscriptions,
    activeFilter
  );
  const suspendedAt = currentSubscription.get('suspended_at');
  const reactivationDate = currentSubscription.get('reactivate_on');
  const isCancelled = currentSubscription.get('is_cancelled');
  const isExpired = currentSubscription.size && !currentSubscription.isActive();
  const isSuspended = currentSubscription.get('is_suspended');
  const isPreSale = currentSubscription.get('billing_status') === 'pre_sale';
  const isInvite =
    subscriptions.isEmpty() &&
    client.get('permitted_membership_ids').has(membership.id);
  const cancelledUntil = currentSubscription.status === 'cancelled_until';
  const expireAt = currentSubscription.expires_at
    ? moment(currentSubscription.expires_at).format('MM/DD/YYYY')
    : 'NA';
  const cancelledDateTime = moment(currentSubscription.expires_at).format(
    'MM/DD/YYYY h:mm A'
  );
  const invitedAt = client.get('mem_invite_date')
    ? moment(client.get('mem_invite_date')).format('MM/DD/YYYY')
    : null;
  const reactivateOn = currentSubscription.get('reactivate_on');
  const isActive =
    currentSubscription.size > 0 && currentSubscription.status === ACTIVE;
  return (
    <ResponsiveElement
      largeScreen={
        <MembershipClientLarge
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          activeSubscriptionStartDate={
            isInvite ? '' : activeSubscriptionStartDate
          }
          isCancelled={isCancelled}
          isExpired={isExpired}
          isInvite={isInvite}
          isLifeTime={membership.isLifetime()}
          isPreSale={isPreSale}
          isSuspended={isSuspended}
          reactivationDate={reactivationDate}
          suspendedAt={suspendedAt}
          subscription={currentSubscription}
          cancelledUntil={cancelledUntil}
          expireAt={expireAt}
          activeFilter={activeFilter}
          reactivateOn={reactivateOn}
          cancelledDateTime={cancelledDateTime}
          invitedAt={invitedAt}
          isActive={isActive}
          membershipTier={membershipTier}
        />
      }
      smallScreen={
        <MembershipClientSmall
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          isInvite={isInvite}
          isCancelled={isCancelled}
          isExpired={isExpired}
          isPreSale={isPreSale}
          isSuspended={isSuspended}
          suspendedAt={suspendedAt}
          reactivationDate={reactivationDate}
          isLifeTime={membership.isLifetime()}
          subscription={currentSubscription}
          cancelledUntil={cancelledUntil}
          expireAt={expireAt}
          isActive={isActive}
          membershipTier={membershipTier}
        />
      }
    />
  );
}

export default MemberCard;
