import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map, OrderedSet } from 'immutable';
import { Grid, Spinner, Typography } from '@upperhand/playmaker';

import EmptyState from 'containers/classes/classesList/components/Schedules/EmptyState.jsx';
import ScheduleCard from 'containers/classes/classesList/components/Schedules/ScheduleCard.jsx';

import Paginator from 'shared/components/Paginator.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ScheduleDataStore, StaffDataStore } from 'dataStores';

function ScheduleList({
  intl,
  isLoading,
  listUpcoming,
  onPageSelect,
  page,
  perPage,
  scheduleDataStore: { schedules },
  scheduleIds,
  staffDataStore: { staff },
  totalCount,
}) {
  return isLoading ? (
    <Grid item xs={12}>
      <div style={{ textAlign: 'center' }}>
        <Spinner type="indeterminate" />
      </div>
    </Grid>
  ) : (
    <Grid item container spacing={1} className="class-schedules">
      <Grid item xs={12} className="class-schedules__table-header">
        <div className="class-schedules__table-header-content">
          <Grid container xs={12} alignItems="center" spacing={1}>
            <Grid item xs={6} md={2}>
              <Typography variant="fieldLabel">
                {t('.date', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="fieldLabel">
                {t('.days', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={1}>
              <Typography variant="fieldLabel">
                {t('.time', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="fieldLabel">
                {t('.location', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="fieldLabel">
                {t('.staff', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography variant="fieldLabel">
                {t('.resources', intl, __filenamespace)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {scheduleIds.map(scheduleId => (
        <Grid item key={scheduleId} xs={12}>
          <ScheduleCard
            scheduleId={scheduleId}
            schedule={schedules.get(scheduleId)}
            staff={staff}
          />
        </Grid>
      ))}
      {scheduleIds.isEmpty() && (
        <Grid item xs={12}>
          <EmptyState listUpcoming={listUpcoming} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paginator
          currentPage={page}
          onPageSelect={onPageSelect}
          perPage={perPage}
          totalCount={totalCount}
        />
      </Grid>
    </Grid>
  );
}

ScheduleList.propTypes = {
  isLoading: PropTypes.bool,
  listUpcoming: PropTypes.bool,
  page: PropTypes.number,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  scheduleDataStore: PropTypes.shape({ schedules: PropTypes.instanceOf(Map) }),
  scheduleIds: PropTypes.instanceOf(OrderedSet),
  staffDataStore: PropTypes.shape({ staff: PropTypes.instanceOf(Map) }),
  onPageSelect: PropTypes.func,
};

ScheduleList.defaultProps = {
  isLoading: false,
  listUpcoming: true,
  page: 1,
  perPage: 15,
  totalCount: 0,
  scheduleDataStore: {
    schedules: Map(),
  },
  scheduleIds: OrderedSet(),
  staffDataStore: {
    staff: Map(),
  },
  onPageSelect: () => null,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      staffDataStore: StaffDataStore,
      scheduleDataStore: ScheduleDataStore,
    },
  })
)(ScheduleList);
