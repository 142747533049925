import { Map } from 'immutable';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import SpecificEventsFilterActions from 'shared/actions/SpecificEventsFilterActions.jsx';
import FilterStoreImpl from 'event_mgmt/index/stores/_FilterStoreImplementation.jsx';

class SpecificEventsFilterStore extends FilterStoreImpl {
  constructor() {
    super({
      toggleFilterDrawer: SpecificEventsFilterActions.toggleFilterDrawer,
      updateFilterDrawerOpen:
        SpecificEventsFilterActions.updateFilterDrawerOpen,
      updateStatusFilters: SpecificEventsFilterActions.updateStatusFilters,
      updateTitleFilter: SpecificEventsFilterActions.updateTitleFilter,
      updateTypeFilters: SpecificEventsFilterActions.updateTypeFilters,
    });

    this.statusFilters = Map({
      all: false,
      active: true,
      draft: true,
      completed: true,
      cancelled: true,
      deleted: true,
    });

    this.eventStatusFilters = [
      { name: 'All', filterKey: 'all' },
      { name: 'Active', filterKey: 'active' },
      { name: 'Draft', filterKey: 'draft' },
      { name: 'Completed', filterKey: 'completed' },
      { name: 'Cancelled', filterKey: 'cancelled' },
      { name: 'Deleted', filterKey: 'deleted' },
    ];
  }

  handleEventTypeList() {
    this.waitFor(EventTypeListingStore);
    const { isLoading, eventTypes } = EventTypeListingStore.getState();
    if (!isLoading) {
      this.typeFilters = Map({ all: true }).withMutations(filterSet =>
        eventTypes.forEach(type => filterSet.set(type.id, true))
      );
    }
  }
}

export default alt.createStore(
  SpecificEventsFilterStore,
  'SpecificEventsFilterStore'
);
