class SwingCreditsDrawerActions {
  constructor() {
    this.generateActions(
      'mounted',
      'fetchClientSuccess',
      'fetchClientError',
      'listSwings',
      'listSwingsSuccess',
      'listSwingsError',
      'swingsPageSelected',
      'toggleTopUpModal',
      'variantChange',
      'listVariantsSuccess',
      'listVariantsError',
      'createOrderItemSuccess',
      'createOrderItemError',
      'fetchOrderSuccess',
      'fetchOrderError',
      'purchaseSuccess',
      'accountCreditChanged'
    );
  }
}

export default alt.createActions(SwingCreditsDrawerActions);
