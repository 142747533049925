import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import { List } from 'immutable';
import { AvailabilityScheduleSource, StaffSource } from 'sources';
import { AvailabilityScheduleDataStore } from 'dataStores';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import ContactsArchivedStaffActions from './Actions';

class StaffArchivedStore extends UpperHandStore {
  constructor() {
    super();

    this.staffIds = List();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.search = '';
    this.loading = false;
    this.loadedAt = null;

    this.debouncedListStaff = debounce(this.listArchivedStaff, 600);

    this.bindListeners({
      mounted: ContactsArchivedStaffActions.mounted,
      pageSelected: ContactsArchivedStaffActions.pageSelected,
      searchUpdated: ContactsArchivedStaffActions.searchUpdated,

      listArchivedStaffSuccess: ContactsArchivedStaffActions.staffListSuccess,
      listArchivedStaffError: ContactsArchivedStaffActions.staffListError,

      listStaffAvailabilitySuccess:
        ContactsArchivedStaffActions.listStaffAvailabilitySuccess,
      listStaffAvailabilityError:
        ContactsArchivedStaffActions.listStaffAvailabilityError,
    });
  }

  mounted() {
    if (
      !this.loadedAt ||
      this.loadedAt.isBefore(moment().subtract(5, 'minutes'))
    ) {
      this.listArchivedStaff();
      this.listStaffAvailability();
    }
  }

  listArchivedStaff() {
    const params = {
      access_revoked: true,
      page: this.page,
      per_page: this.perPage,
    };

    if (this.search) {
      params.search = this.search;
    }

    this.loading = true;

    StaffSource.list({
      params,
      success: ContactsArchivedStaffActions.staffListSuccess,
      error: ContactsArchivedStaffActions.staffListError,
    });
  }

  listArchivedStaffSuccess({ staff, totalCount }) {
    this.staffIds = staff.map(s => s.id);
    this.totalCount = totalCount;
    this.loading = false;
    this.loadedAt = moment();
  }

  listArchivedStaffError(...args) {
    this.loading = false;
    this.notifyError('error listing client contacts', args);
  }

  listStaffAvailability() {
    this.waitFor(AvailabilityScheduleDataStore);

    const { availabilitySchedules } = AvailabilityScheduleDataStore.getState();

    if (availabilitySchedules.size === 0) {
      this.loading = true;
      AvailabilityScheduleSource.list({
        params: { per_page: 100 },
        success: ContactsArchivedStaffActions.listStaffAvailabilitySuccess,
        error: ContactsArchivedStaffActions.listStaffAvailabilityError,
      });
    }
  }

  listStaffAvailabilitySuccess({ page, perPage, totalCount }) {
    if (page * perPage < totalCount) {
      AvailabilityScheduleSource.list({
        params: { per_page: 100, page: page + 1 },
        success: ContactsArchivedStaffActions.listStaffAvailabilitySuccess,
        error: ContactsArchivedStaffActions.listStaffAvailabilityError,
      });
    } else {
      this.loading = false;
    }
  }

  listStaffAvailabilityError(...args) {
    this.notifyError('error listing staff availability', args);
  }

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listArchivedStaff();
  }

  searchUpdated(newSearch) {
    this.search = newSearch;
    this.page = 1;

    this.debouncedListStaff();
  }
}

export default alt.createStore(StaffArchivedStore, 'StaffArchivedStore');
