import * as React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { compose } from 'shared/utils/SharedUtils';
import {
  PhoneNumber,
  Address1,
  Address2,
  City,
  State,
  PostalCode,
} from 'user_management/shared/components/UserProfileFields.jsx';

import Client from 'shared/records/Client.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import Staff from 'shared/records/Staff.jsx';
import User from 'event_mgmt/shared/records/User.jsx';

const styles = {
  root: {
    '& > div': {
      marginBottom: '0.5rem',
    },
  },
  stateContainer: {
    display: 'flex',
    '& > div:first-child': {
      marginRight: '8px',
      width: 120,
    },
  },
};

function ContactInformationEditor({
  classes,
  errors,
  intl,
  onAddressChange,
  onChange,
  hideFields,
  userProfile,
}) {
  return (
    <div className={classes.root}>
      {!hideFields.includes('Address') && (
        <>
          <Address1
            userProfile={userProfile}
            onChange={onAddressChange}
            errorText={errors.getErrors(['address', 'line_1'], intl)}
          />
          <Address2
            userProfile={userProfile}
            onChange={onAddressChange}
            errorText={errors.getErrors(['address', 'line_2'], intl)}
          />
          <City
            userProfile={userProfile}
            onChange={onAddressChange}
            errorText={errors.getErrors(['address', 'city'], intl)}
          />
          <div className={classes.stateContainer}>
            <State
              userProfile={userProfile}
              onChange={onAddressChange}
              errorText={errors.getErrors(['address', 'state'], intl)}
            />
            <PostalCode
              userProfile={userProfile}
              onChange={onAddressChange}
              errorText={errors.getErrors(['address', 'postal_code'], intl)}
            />
          </div>
        </>
      )}
      {!hideFields.includes('PhoneNumber') && (
        <PhoneNumber
          userProfile={userProfile}
          onChange={onChange}
          errorText={errors.getErrors('phone', intl)}
        />
      )}
    </div>
  );
}

ContactInformationEditor.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.instanceOf(FieldErrors).isRequired,
  intl: PropTypes.object.isRequired,
  hideFields: PropTypes.instanceOf(Set),
  onAddressChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  userProfile: PropTypes.oneOfType([
    PropTypes.instanceOf(Client),
    PropTypes.instanceOf(Staff),
    PropTypes.instanceOf(User),
  ]).isRequired,
};

ContactInformationEditor.defaultProps = {
  hideFields: Set(),
  classes: {},
};

export default compose(
  withStyles(styles),
  injectIntl
)(ContactInformationEditor);
