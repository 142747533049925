import { List } from 'immutable';

import ClientStore from 'shared/stores/ClientStore.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UserValidator from 'shared/utils/UserValidator.jsx';
import UserActions from 'shared/actions/UserActions.jsx';
import UserCreationStore from 'user_management/shared/stores/UserCreationStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import User from 'event_mgmt/shared/records/User.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { redirectTo } from 'shared/utils/RouteUtils.js';
import { getCurrentContext } from 'shared/utils/AuthUtils';

const REQUIRED_FIELDS_CLIENT = ['phone', 'email', 'date_of_birth', 'gender'];
const REQUIRED_FIELDS_STAFF = ['phone', 'email', 'date_of_birth'];

const url = id => {
  if (id) {
    return `users/${id}?fields[]=profile_image`;
  }
  return 'users?fields[]=profile_image';
};

class UserStore extends UpperHandStore {
  constructor() {
    super();

    this.user = new User();
    this.isLoading = false;
    this.isSaving = false;
    this.errors = new FieldErrors();
    this.info = new FieldErrors({
      errors: {
        password: [
          'validation.account.settings.password.complexity',
          'validation.password.length',
        ],
        password_confirmation: [
          'validation.account.settings.password.confirmation',
        ],
        current_password: ['validation.account.settings.current_password'],
      },
    });

    this.bindListeners({
      handleUpdateStore: UserActions.UPDATE_STORE,

      handleFetch: UserActions.FETCH,
      handleFetchSuccess: UserActions.FETCH_SUCCESS,
      handleFetchError: UserActions.FETCH_ERROR,

      handleUpdate: UserActions.UPDATE,
      handleUpdateSuccess: UserActions.UPDATE_SUCCESS,
      handleUpdateError: UserActions.UPDATE_ERROR,

      handleRegister: UserActions.REGISTER,
      handleRegisterSuccess: UserActions.REGISTER_SUCCESS,
      handleRegisterError: UserActions.REGISTER_ERROR,

      handleCreateError: UserActions.CREATE_ERROR,
      handleCreateSuccess: UserActions.CREATE_SUCCESS,
    });
  }

  handleUpdateStore([keypath, value]) {
    this.user = this.user.setIn(keypath, value);
    this.isDirty = true;
    this.validateUser();
  }

  handleFetch() {
    this.isLoading = true;

    return uhApiClient.get({
      url: url(currentUser().id),
      success: UserActions.fetchSuccess,
      error: UserActions.fetchError,
    });
  }

  handleFetchSuccess(data) {
    this.isLoading = false;
    this.user = User.fromServer(data);
  }

  handleFetchError(...args) {
    this.isLoading = false;
    this.notifyError('error while fetching user', args);
  }

  async handleUpdate(requireAddress = true) {
    this.validateUser(requireAddress);

    if (this.errors.isEmpty()) {
      this.isSaving = true;
      const payload = await this.user.toServer();

      uhApiClient.put({
        url: url(currentUser().id),
        data: JSON.stringify({ attributes: payload }),
        success: UserActions.updateSuccess,
        error: UserActions.updateError,
      });
    } else {
      this.notifyError(`Cannot update user due to ${this.errors.join(', ')}`);
    }
  }

  handleUpdateSuccess(data) {
    this.isSaving = false;
    this.user = User.fromServer(data);
    this.updateCurrentUser(data);

    MessageWindowActions.addMessage.defer('Account updated successfully.');
  }

  updateCurrentUser(attributes) {
    Object.assign(window.user, { ...attributes, name: this.user.name() });
  }

  handleUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('Error while updating user', args);
  }

  validateUser(requireAddress = true) {
    const { role } = getCurrentContext();
    const isClient = role === 'Client';

    const userValidator = new UserValidator(this.user).validate(
      List(isClient ? REQUIRED_FIELDS_CLIENT : REQUIRED_FIELDS_STAFF),
      requireAddress
    );
    this.errors = userValidator.errors;

    return userValidator.isValid();
  }

  async handleRegister({ skipProfiles, redirectAfterLogin, redirectPath }) {
    this.waitFor(UserCreationStore);

    if (
      !this.isSaving &&
      ClientStore.getState().isValid &&
      StaffStore.getState().isValid
    ) {
      this.isSaving = true;

      const { newUser, managedProfiles } = UserCreationStore.getState();

      const userPayload = await newUser.toServer();
      const managedProfilesPayload = skipProfiles
        ? null
        : await Promise.all(managedProfiles.map(u => u.toServer()).toJS());

      userPayload.managed_users_attributes = managedProfilesPayload;

      // If there is a logged in user then they are accepting an invite and should have their record
      // updated. If there is no logged in user then they are creating their account from the logged
      // out cart so we need to create a new user.
      const currentUserId = currentUser()?.id;

      if (isLoggedIn() && currentUserId) {
        uhApiClient.put({
          url: `/users/${currentUserId}`,
          data: JSON.stringify({ attributes: userPayload }),
          success: UserActions.registerSuccess,
          error: UserActions.registerError,
        });
      } else
        uhApiClient.post({
          url: '/users/',
          data: JSON.stringify({
            attributes: userPayload,
            customer_ids: [currentCustomer().id],
          }),
          success: {
            action: UserActions.createSuccess,
            args: [{ redirectAfterLogin, redirectPath }],
          },
          error: UserActions.createError,
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleRegisterSuccess() {
    // This fixing uncaught Error: Invariant Violation: Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch
    setTimeout(() => {
      redirectTo({ path: '/' });
    }, 0);
  }

  handleRegisterError(...args) {
    this.isSaving = false;
    this.notifyError('Error while registering user', args);
  }

  handleCreateError(...args) {
    this.isSaving = false;
    this.notifyError('Error while creating user', args);
  }

  handleCreateSuccess() {
    this.isSaving = false;
  }
}

export default alt.createStore(UserStore, 'UserStore');
