class EditMembershipExpireActions {
  constructor() {
    this.generateActions(
      'closeModal',
      'openModal',
      'setExpireDate',
      'addNewDate',
      'updateDateSuccess',
      'updateDateError'
    );
  }
}

export default alt.createActions(EditMembershipExpireActions);
