import Staff from 'shared/records/Staff.jsx';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';

export const INFO_TAB = 'info';
export const PAYROLL_TAB = 'payroll';

class StaffDetailsDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      toggleInfoEdit: StaffDetailsDrawerActions.TOGGLE_INFO_EDIT_MODE,
      togglePayrollEdit: StaffDetailsDrawerActions.TOGGLE_PAYROLL_EDIT_MODE,
      selectStaff: StaffDetailsDrawerActions.STAFF_SELECTED,
      updateActiveTab: StaffDetailsDrawerActions.TAB_SELECTED,
      updateHourlyRate: StaffDetailsDrawerActions.HOURLY_RATE_UPDATED,

      saveHourlyRate: StaffDetailsDrawerActions.HOURLY_RATE_SAVED,
      saveHourlyRateSuccess: StaffDetailsDrawerActions.HOURLY_RATE_SAVE_SUCCESS,
      saveHourlyRateError: StaffDetailsDrawerActions.HOURLY_RATE_SAVE_ERROR,
    });
  }

  reset() {
    this.staff = new Staff();
    this.activeTab = INFO_TAB;
    this.infoEditMode = false;
    this.payrollEditMode = false;

    this.hourlyRate = 0;
    this.isSavingHourlyRate = false;

    this.canEditInfo = false;
    this.canViewPayroll = false;
    this.canEditPayroll = false;
  }

  toggleInfoEdit() {
    this.infoEditMode = !this.infoEditMode;
  }

  togglePayrollEdit() {
    this.payrollEditMode = !this.payrollEditMode;
    this.hourlyRate = this.payrollEditMode ? this.staff.hourly_rate : 0;
  }

  selectStaff(newStaff) {
    this.reset();

    this.staff = newStaff;

    this.canEditInfo =
      hasAdminPermission() || currentUser().customer_user_id === this.staff.id;

    this.canViewPayroll =
      currentCustomer().payroll_enabled &&
      currentCustomer().time_tracking_enabled &&
      (currentUser().isAdmin() ||
        currentUser().isStaffAdmin() ||
        currentUser().customer_user_id === this.staff.id);
    this.canEditPayroll =
      currentCustomer().payroll_enabled &&
      currentCustomer().time_tracking_enabled &&
      (currentUser().isAdmin() || currentUser().isStaffAdmin());
  }

  updateActiveTab(newActiveTab) {
    this.activeTab = newActiveTab;
  }

  updateHourlyRate(newRate) {
    this.hourlyRate = newRate;
  }

  saveHourlyRate() {
    this.isSavingHourlyRate = true;

    uhApiClient.patch({
      url: `customer_users/${this.staff.id}`,
      data: JSON.stringify({ attributes: { hourly_rate: this.hourlyRate } }),
      success: StaffDetailsDrawerActions.hourlyRateSaveSuccess,
      error: StaffDetailsDrawerActions.hourlyRateSaveError,
    });
  }

  saveHourlyRateSuccess(data) {
    this.staff = this.staff.set('hourly_rate', data.hourly_rate);
    this.isSavingHourlyRate = false;
    this.payrollEditMode = false;
    this.hourlyRate = 0;
  }

  saveHourlyRateError(...args) {
    this.isSavingHourlyRate = false;
    this.notifyError('error saving hourly rate', args);
  }
}

export default alt.createStore(
  StaffDetailsDrawerStore,
  'StaffDetailsDrawerStore'
);
