import * as React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';

import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

class AppContext extends React.Component {
  componentDidMount() {
    CurrentContextActions.fetch({
      fields: ['permitted_membership_ids'],
    });
  }

  render() {
    const {
      children,
      CurrentContextStore: { isCustomerUserStoreLoading },
    } = this.props;

    return (
      <SpinWhileLoading
        contentContainerStyle={{ height: '100%' }}
        outerContainerStyle={{ height: '100%' }}
        isLoading={isCustomerUserStoreLoading}
      >
        {children}
      </SpinWhileLoading>
    );
  }
}

export default altContainer({
  stores: {
    CurrentContextStore,
  },
})(AppContext);
