export default alt.generateActions(
  'prevStep',
  'nextStep',
  'addProfile',
  'removeProfile',
  'updateNewUser',
  'listManagedProfiles',
  'listManagedProfilesSuccess',
  'listManagedProfilesError',
  'accountType'
);
