import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import { messageId } from 'shared/utils/LocaleUtils.js';

function EmptyState({ listUpcoming }) {
  return (
    <Card>
      <Grid container justify="center">
        <Grid item>
          <Typography>
            <FormattedMessage
              id={
                listUpcoming
                  ? messageId('.no_upcoming_schedules', __filenamespace)
                  : messageId('.no_past_schedules', __filenamespace)
              }
            />
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default EmptyState;
