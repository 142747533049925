/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Map } from 'immutable';
import { TextField } from '@upperhand/playmaker';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { handleOnEnterKeyDown } from 'shared/components/Form.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, uhContrast } from 'shared/styles/uhStyles.jsx';

const styles = {
  button: {
    height: 40,
    width: '50%',
    margin: '0 10px',
  },

  buttonContainer: {
    padding: '1em 2em',
    margin: '0 -10px',
  },

  buttonLabel: {
    fontSize: 16,
    verticalAlign: 'middle',
  },

  drawer: {
    width: STANDARD_DRAWER_WIDTH,
  },

  drawerContainerStyle: {
    display: 'flex, -webkit-flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    WebkitFlexDirection: 'column',
  },

  header: merge(
    {
      width: '100%',
      padding: '2em 2em 1em 2em',
    },
    uhContrast.lightOnDark
  ),

  questionElement: {
    marginBottom: 10,
  },

  questions: merge(
    {
      padding: '2em 2em',
      overflowY: 'auto',
      lineHeight: '17px',
      fontSize: 15,
      borderBottom: '1px solid rgb(224, 224, 224)',
      flex: '1 0 0',
    },
    uhContrast.darkOnLight
  ),

  titleText: {
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

function QuestionElement({ question, ...props }) {
  return (
    <div key={`${props.eventId}-${question.id}`} style={styles.questionElement}>
      <div>{question.text}</div>
      <div>
        <QuestionResponseFields question={question} {...props} />
      </div>
    </div>
  );
}

function QuestionResponseFields({
  isAnswered,
  question,
  registrationFormActions,
  valueFor,
  ...props
}) {
  const isRequired = question.isRequired() && !isAnswered(question.id);

  return (
    <div>
      {question.isListable() && (
        <FormControl fullWidth error={isRequired}>
          <Select
            fullWidth
            sx={{ fontSize: '14px' }}
            inputProps={{ sx: { padding: '12px' } }}
            onChange={e =>
              registrationFormActions.answer(question.id, e.target.value)
            }
            value={valueFor(question.id) || ''}
          >
            {question.optionsList().map(option => (
              <MenuItem
                key={`${question.id}-${option}`}
                value={option}
                sx={{ fontSize: '14px' }}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
          {isRequired && (
            <FormHelperText sx={{ fontSize: '12px', margin: 0 }}>
              Required
            </FormHelperText>
          )}
        </FormControl>
      )}
      {!question.isListable() && (
        <TextField
          errorText={
            question.isRequired() && !isAnswered(question.id)
              ? 'Required.'
              : undefined
          }
          fullWidth
          name={question.name}
          onChange={e =>
            registrationFormActions.answer(question.id, e.target.value)
          }
          onKeyDown={e =>
            handleOnEnterKeyDown(e, () =>
              registrationFormActions.setCompleted(props.eventId)
            )
          }
          value={valueFor(question.id)}
        />
      )}
    </div>
  );
}

const PreviousButton = injectIntl(
  ({ currentRegistrantIndex, registrationFormActions, intl }) => (
    <Button
      fullWidth
      variant="contained"
      disabled={currentRegistrantIndex === 0}
      onClick={() => registrationFormActions.previousRegistrant()}
    >
      {t('actions.previous', intl)}
    </Button>
  )
);

const NextButton = injectIntl(
  ({
    currentRegistrantIndex,
    registrantIds,
    registrationFormActions,
    eventId,
    intl,
  }) => {
    if (currentRegistrantIndex === registrantIds.size - 1) {
      return (
        <Button
          fullWidth
          variant="contained"
          onClick={() => registrationFormActions.setCompleted(eventId)}
        >
          {t('actions.submit', intl)}
        </Button>
      );
    }
    return (
      <Button
        fullWidth
        variant="contained"
        onClick={() => registrationFormActions.nextRegistrant()}
      >
        {t('actions.next', intl)}
      </Button>
    );
  }
);

function RegistrationFormDrawer(props) {
  const {
    athletes,
    currentRegistrantId,
    currentRegistrantIndex,
    direction,
    eventId,
    isCompletingRegistrationFor,
    registrantIds,
    registrationFormActions,
    selectedFields,
    title,
  } = props;

  const currentProfile = athletes.find(
    athlete => athlete.id === currentRegistrantId()
  );

  return (
    <Drawer
      disableEnforceFocus
      onClose={() => registrationFormActions.stop(eventId)}
      open={isCompletingRegistrationFor(eventId)}
      PaperProps={{ sx: styles.drawer }}
      anchor={direction === 'rightToLeft' ? 'right' : 'left'}
    >
      <div style={styles.header}>
        {currentProfile && (
          <FlexBoxJustify style={{ fontSize: 16, marginBottom: 4 }}>
            <div>
              <FormattedMessage
                id={messageId('.register_name', __filenamespace)}
                values={{ name: currentProfile.name() }}
              />
            </div>
            <div style={{ position: 'absolute', right: '13px', top: '6px' }}>
              <IconButton
                size="large"
                onClick={() => registrationFormActions.stop(eventId)}
              >
                <CloseIcon sx={{ color: uhColors.navIconGrey }} />
              </IconButton>
            </div>
          </FlexBoxJustify>
        )}
        <FlexBoxJustify style={{ marginBottom: 4 }}>
          <div style={styles.titleText}>{title}</div>
          <div>
            <FormattedMessage
              id={messageId('.of_text', __filenamespace)}
              values={{
                index: currentRegistrantIndex + 1,
                ids: registrantIds.size,
              }}
            />
          </div>
        </FlexBoxJustify>
      </div>

      <div style={styles.questions}>
        {selectedFields.get(eventId, []).map((question, index) => (
          <QuestionElement
            tabIndex={index}
            key={`rf-qe-${question.id}-${currentProfile && currentProfile.id}`}
            question={question}
            {...props}
          />
        ))}
      </div>
      <Stack spacing={2} direction="row" sx={styles.buttonContainer}>
        <PreviousButton {...props} />
        <NextButton {...props} />
      </Stack>
    </Drawer>
  );
}

RegistrationFormDrawer.propTypes = {
  athletes: PropTypes.instanceOf(List),
  currentRegistrantId: PropTypes.func,
  currentRegistrantIndex: PropTypes.number,
  direction: PropTypes.string,
  eventId: PropTypes.number,
  isCompletingRegistrationFor: PropTypes.func,
  registrantIds: PropTypes.instanceOf(List),
  registrationFormActions: PropTypes.object,
  selectedFields: PropTypes.instanceOf(Map),
  title: PropTypes.string,
};

RegistrationFormDrawer.defaultProps = {
  athletes: List(),
  currentRegistrantId: () => null,
  currentRegistrantIndex: null,
  direction: '',
  eventId: null,
  isCompletingRegistrationFor: () => null,
  registrantIds: List(),
  registrationFormActions: {},
  selectedFields: Map(),
  title: '',
};

export default RegistrationFormDrawer;
