import { List, Map } from 'immutable';
import LabelPrintActions from 'retail/actions/LabelPrintActions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import Product from 'shared/records/Product';
import RetailEditingActions from 'retail/actions/RetailEditingActions.js';
import RetailEditingStore from 'retail/stores/RetailEditingStore.js';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import VariantEditingActions from 'retail/actions/VariantEditingActions.js';
import VariantEditingStore from 'retail/stores/VariantEditingStore';
import ZebraActions from 'shared/actions/ZebraActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class LabelPrintingStore extends UpperHandStore {
  constructor() {
    super();

    this.dialogOpen = false;
    this.product = new Product();
    this.variants = List();
    this.quantities = Map();

    this.bindListeners({
      setProduct: [
        RetailEditingActions.PRODUCT_FETCH_SUCCESS,
        RetailEditingActions.SAVE_OR_UPDATE_SUCCESS,
      ],
      setVariants: VariantEditingActions.VARIANT_LIST_SUCCESS,

      openDialog: LabelPrintActions.DIALOG_OPENED,
      closeDialog: LabelPrintActions.PRINT_ABORTED,

      updateQuantity: LabelPrintActions.QUANTITY_UPDATED,

      requestLabels: LabelPrintActions.PRINT_REQUESTED,
      labelFetchSuccess: LabelPrintActions.LABEL_FETCH_SUCCESS,
      labelFetchError: LabelPrintActions.LABEL_FETCH_ERROR,
    });
  }

  setProduct() {
    this.waitFor(RetailEditingStore);

    this.product = RetailEditingStore.getState().product;
  }

  setVariants() {
    this.waitFor(VariantEditingStore);

    this.variants = VariantEditingStore.getState().variantMap.toList();
    this.quantities = Map().withMutations(m =>
      this.variants.forEach(v => m.set(v.id, 0))
    );
  }

  openDialog() {
    this.dialogOpen = true;
  }

  closeDialog() {
    this.dialogOpen = false;
    this.quantities = this.quantities.map(() => 0);
  }

  updateQuantity([variantId, quantity]) {
    this.quantities = this.quantities.set(variantId, Math.max(quantity, 0));
  }

  requestLabels() {
    const payload = JSON.stringify({
      quantities: this.quantities.filter(q => q > 0).toJS(),
    });

    uhApiClient.post({
      url: `products/${this.product.id}/labels`,
      data: payload,
      success: LabelPrintActions.labelFetchSuccess,
      error: LabelPrintActions.labelFetchError,
    });
  }

  labelFetchSuccess({ data }) {
    const message = new TranslatableMessage({
      id: '.printing_n_labels',
      filename: __filenamespace,
      values: { n: this.quantities.reduce((sum, q) => sum + q) },
    });

    MessageWindowActions.addMessage.defer(message);
    ZebraActions.printLabel.defer(data);
    this.closeDialog();
  }

  labelFetchError(...args) {
    this.notifyError('Error fetching labels', args);
  }
}

export default alt.createStore(LabelPrintingStore, 'LabelPrintingStore');
