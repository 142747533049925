import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { t } from 'shared/utils/LocaleUtils.js';
import { Drawer, Grid } from '@upperhand/playmaker';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import CalendarStaffAutocomplete from './_CalendarStaffAutocomplete.jsx';

const styles = {
  header: {
    padding: 15,
  },

  formGroup: {
    padding: '0 15px 15px',
    width: '100%',
  },

  formLabel: {
    marginLeft: 0,
    justifyContent: 'space-between',
  },

  toggle: {
    top: 3,
  },

  overlayStyle: {
    opacity: 0,
    backgroundColor: 'transparent',
  },
  clearButton: {
    border: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
};

function CalendarFilterDrawer({ intl, calendarStore, allStaff }) {
  const { staffIds, calendarFilters } = calendarStore;
  const isCoach = currentUser().isCoach();
  const showAllStaff = calendarFilters.get('showAllStaff');
  const allFilteredStaff = allStaff.map(staffMember => ({
    name: `${staffMember.first_name} ${staffMember.last_name}`,
    id: staffMember.id,
  }));
  const onToggle = e => CalendarActions.onFilterChange(e.target.name);
  return (
    <Drawer
      open={calendarStore.isFilterDrawerOpen}
      onClose={CalendarActions.filterDrawerClose}
      title={t('.title', intl, __filenamespace)}
      content={
        <Grid container xs={12} spacing={3} alignItems="center">
          <Grid justify="flex-end" container item xs={12}>
            <button
              className="filter-drawer__clear"
              onClick={CalendarActions.clearFilters}
              type="button"
              style={styles.clearButton}
            >
              {t('.clear', intl, __filenamespace)}
            </button>
          </Grid>

          <FormGroup
            key="status-filter-list"
            className="status-filter-list"
            style={styles.formGroup}
          >
            <FormControlLabel
              name="showPaid"
              label={<span>{t('.paid', intl, __filenamespace)}</span>}
              labelPlacement="start"
              onChange={onToggle}
              checked={calendarFilters.get('showPaid')}
              control={<Switch disabled={showAllStaff} />}
              style={styles.formLabel}
            />
            <FormControlLabel
              name="showUnpaid"
              label={<span>{t('.un_paid', intl, __filenamespace)}</span>}
              labelPlacement="start"
              onChange={onToggle}
              checked={calendarFilters.get('showUnpaid')}
              control={<Switch disabled={showAllStaff} />}
              style={styles.formLabel}
            />
            <FormControlLabel
              name="showAllStaff"
              label={<span>{t('.all_staff', intl, __filenamespace)}</span>}
              labelPlacement="start"
              onChange={onToggle}
              checked={showAllStaff}
              control={<Switch />}
              style={styles.formLabel}
              disabled={isCoach}
            />
          </FormGroup>
          {showAllStaff && (
            <CalendarStaffAutocomplete
              placeholder={t('.select_staff', intl, __filenamespace)}
              options={allFilteredStaff.toArray()}
              value={staffIds}
              onChange={(e, val) => CalendarActions.setStaffIds(val)}
            />
          )}
        </Grid>
      }
    />
  );
}

CalendarFilterDrawer.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CalendarFilterDrawer);
