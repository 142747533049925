import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox, TextField, Grid } from '@upperhand/playmaker';
import Styles from 'onboarding/components/AccountSetupPaysafe/_Styles';
import { IdentityAddressField } from 'onboarding/components/AccountSetupPaysafe/fields/_AddressField.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function OwnerForm(props) {
  const { action, index, intl, owner } = props;
  return (
    <Grid container spacing={4} justify="center" className="owner-form">
      <Grid container item direction="column" spacing={2} xs={6}>
        <Grid item>
          {index === 0 && (
            <h3 style={Styles.header3}>
              {t('.header', intl, __filenamespace)}
            </h3>
          )}
          {index > 0 && (
            <h3 style={Styles.header3}>
              {t('.additional_header', intl, __filenamespace)}
            </h3>
          )}
        </Grid>
        <Grid item>
          <TextField
            errorText={owner.errors.getErrors('first_name', intl)}
            label={t('.first_name_label', intl, __filenamespace)}
            fullWidth
            name="first_name"
            onChange={(_, value) =>
              action({ keyPath: 'first_name', value, index })
            }
            value={owner.first_name}
          />
        </Grid>
        <Grid item>
          <TextField
            errorText={owner.errors.getErrors('last_name', intl)}
            label={t('.last_name_label', intl, __filenamespace)}
            fullWidth
            name="last_name"
            onChange={(_, value) =>
              action({ keyPath: 'last_name', value, index })
            }
            value={owner.last_name}
          />
        </Grid>
        <Grid item>
          <TextField
            errorText={owner.errors.getErrors('job_title', intl)}
            fullWidth
            label={t('.job_title_label', intl, __filenamespace)}
            name="job_title"
            onChange={(_, value) =>
              action({ keyPath: 'job_title', value, index })
            }
            value={owner.job_title}
          />
        </Grid>
        <Grid item>
          <TextField
            errorText={owner.errors.getErrors('phone', intl)}
            label={t('.phone_label', intl, __filenamespace)}
            fullWidth
            name="phone"
            onChange={(_, value) => action({ keyPath: 'phone', value, index })}
            value={owner.phone}
          />
        </Grid>
        <Grid item className="fs-exclude">
          <TextField
            errorText={owner.errors.getErrors('ssn', intl)}
            label={t('.ssn_label', intl, __filenamespace)}
            fullWidth
            name="SSN"
            onChange={(_, value) => action({ keyPath: 'ssn', value, index })}
            type="password"
            value={owner.ssn}
          />
        </Grid>
        <Grid item>
          <TextField
            errorText={owner.errors.getErrors('date_of_birth', intl)}
            label={t('.date_of_birth_label', intl, __filenamespace)}
            fullWidth
            name="date_of_birth"
            onChange={(_, value) =>
              action({ keyPath: 'date_of_birth', value, index })
            }
            value={owner.date_of_birth}
          />
        </Grid>
        <Grid item>
          <TextField
            errorText={owner.errors.getErrors('percentage_ownership', intl)}
            label={t('.percent_ownership_label', intl, __filenamespace)}
            fullWidth
            name="percent_ownership"
            type="number"
            numberInputProps={{
              min: 0,
              max: 100,
              step: 0.1,
            }}
            onChange={(_, value) =>
              action({
                keyPath: 'percentage_ownership',
                value,
                index,
              })
            }
            value={owner.percentage_ownership}
          />
        </Grid>
      </Grid>
      <Grid container item direction="column" spacing={2} xs={6}>
        <IdentityAddressField
          action={action}
          index={index}
          intl={intl}
          owner={owner}
          prefix="Owner"
        />
      </Grid>
      {index === 0 && (
        <Grid item className="owner-form__acceptance-box">
          <Checkbox
            label={t('.controlling_interest', intl, __filenamespace)}
            onChange={(_, checked) =>
              action({ keyPath: 'is_applicant', value: checked, index })
            }
          />
          <Checkbox
            label={t('.control_prong', intl, __filenamespace)}
            onChange={(_, checked) =>
              action({ keyPath: 'is_control_prong', value: checked, index })
            }
          />
        </Grid>
      )}
    </Grid>
  );
}

export default injectIntl(OwnerForm);
