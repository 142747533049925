import { OrderedMap } from 'immutable';
import Task from 'shared/records/Task';
import TaskCreationActions from 'shared/actions/TaskCreationActions';
import TaskDeletionActions from 'shared/actions/TaskDeletionActions';
import TaskEditingActions from 'shared/actions/TaskEditingActions';
import TaskListingActions from 'shared/actions/TaskListingActions';
import TimeTrackingActions from 'shared/actions/TimeTrackingActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class TaskListingStore extends UpperHandStore {
  constructor() {
    super();

    this.tasks = OrderedMap();
    this.isLoading = false;
    this.withRemoved = false;

    this.bindListeners({
      initTasks: [TaskListingActions.LIST, TimeTrackingActions.MENU_OPENED],
      listSuccess: TaskListingActions.LIST_SUCCESS,
      listError: TaskListingActions.LIST_ERROR,
      newTask: TaskCreationActions.SAVE_SUCCESS,
      updateTask: TaskEditingActions.SAVE_SUCCESS,
      deleteTask: TaskDeletionActions.DELETE_SUCCESS,
    });
  }

  initTasks(options) {
    this.tasks = OrderedMap();
    this.listTasks(options);
  }

  listTasks(options) {
    const { page = 1, withRemoved = false } = options || {};
    this.withRemoved = withRemoved;
    const payload = { page, per_page: 50, with_removed: this.withRemoved };

    this.isLoading = true;

    return uhApiClient.get({
      url: 'tasks',
      data: payload,
      success: TaskListingActions.listSuccess,
      error: TaskListingActions.listError,
    });
  }

  listSuccess({ tasks, page, total_count: totalCount }) {
    const tasksLength = tasks.length;
    if (tasksLength === 0) {
      this.isLoading = false;
      return;
    }

    this.tasks = this.tasks.withMutations(m =>
      tasks.forEach(t => m.set(t.id, new Task(t)))
    );

    if (totalCount > this.tasks.size) {
      this.listTasks({ page: page + 1, withRemoved: this.withRemoved });
    } else {
      this.isLoading = false;
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing tasks', args);
  }

  newTask(data) {
    this.tasks = this.tasks.set(data.id, new Task(data)).sortBy(t => t.name);
  }

  updateTask(data) {
    this.tasks = this.tasks.set(data.id, new Task(data)).sortBy(t => t.name);
  }

  deleteTask([_, taskId]) {
    this.tasks = this.tasks.delete(taskId);
  }
}

export default alt.createStore(TaskListingStore, 'TaskListingStore');
