/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Set } from 'immutable';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { TextField, CurrencyTextField } from '@upperhand/playmaker';
import ReactTooltip from 'react-tooltip';

import Paginator from 'shared/components/Paginator.jsx';
import RetailEditingActions from 'retail/actions/RetailEditingActions.js';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import VariantEditingActions from 'retail/actions/VariantEditingActions.js';
import VariantImageSelector from 'retail/components/_VariantImageSelector.jsx';
import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  container: {
    padding: '0 30px',
  },

  currencyIcon: {
    top: '50%',
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },

  tableCell: {
    padding: '0 20px',
  },

  fieldHeader: {
    color: uhColors.hint,
    fontWeigh: 'bold',
    padding: '0 20px',
  },

  // Mimic MUI Paper
  tableRow: {
    boxSizing: 'border-box',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    borderRadius: 2,
    verticalAlign: 'midddle',
  },
  inventoryLink: {
    color: uhColors.blue,
    padding: 0,
  },
  qtyFirstLetter: {
    color: uhColors.tableGrey,
    paddingRight: 2,
  },
};

const onPageSelect = (page, perPage) => {
  VariantEditingActions.paginationOptionsUpdated(page, perPage);
};

function VariantTable({
  product,
  variantEditingStore: { variantMap, page, perPage, totalCount },
  productImageStore: { productImages },
  style,
  intl,
}) {
  return (
    <div style={merge(styles.container, style)}>
      <table style={styles.table}>
        <thead>
          <tr>
            <td colSpan="2" style={{ fontSize: 15, fontWeight: 'bold' }}>
              <FormattedMessage
                id={messageId('.product_variants', __filenamespace)}
              />
            </td>
            <td style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.qty', __filenamespace)} />
            </td>
            <td
              style={styles.fieldHeader}
              data-tip
              data-for="tooltip-trigger-qty"
            >
              <FormattedMessage
                id={messageId('.trigger_qty', __filenamespace)}
              />
              <ReactTooltip
                id="tooltip-trigger-qty"
                effect="solid"
                className="charge-failure-tooltip uh-tooltip"
              >
                <FormattedMessage
                  id={messageId('.trigger_qty_tooltip', __filenamespace)}
                />
              </ReactTooltip>
            </td>
            <td style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.price', __filenamespace)} />
            </td>
            <td style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.cost', __filenamespace)} />
            </td>
            <td style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.sku', __filenamespace)} />
            </td>
            <td style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.barcode', __filenamespace)} />
            </td>
          </tr>
        </thead>
        <tbody>
          {variantMap
            .map(variant => (
              <tr key={variant.id} style={styles.tableRow}>
                <td style={{ width: 48, padding: '0 8px' }}>
                  <VariantImageSelector
                    variant={variant}
                    productImages={productImages}
                    onImageSelect={imageId => {
                      VariantEditingActions.variantFieldUpdated(
                        variant.id,
                        'product_image_ids',
                        Set([imageId])
                      );
                    }}
                  />
                </td>
                <td>{variant.option_values.map(v => v.value).join(' · ')}</td>
                <td style={merge(styles.tableCell, { width: '10em' })}>
                  {variant?.live_qty > 0 ? (
                    <>
                      <span style={styles.qtyFirstLetter}>
                        <FormattedMessage
                          id={messageId(
                            '.qty_on_hand_character',
                            __filenamespace
                          )}
                        />
                      </span>
                      <span>{variant?.live_qty}</span>
                    </>
                  ) : (
                    <Button
                      fullWidth
                      style={styles.inventoryLink}
                      onClick={() =>
                        InventoryDialogActions.openDialog({
                          product,
                        })
                      }
                    >
                      <FormattedMessage
                        id={messageId('.inventory_link', __filenamespace)}
                      />
                    </Button>
                  )}
                </td>
                <td style={merge(styles.tableCell, { width: '10em' })}>
                  <TextField
                    name="trigger_qty"
                    fullWidth
                    onChange={(_, value) => {
                      VariantEditingActions.variantFieldUpdated(
                        variant.id,
                        'trigger_qty',
                        value
                      );
                    }}
                    outline={false}
                    errorText={variant.errors.getErrors('trigger_qty', intl)}
                    type="number"
                    value={variant.trigger_qty || ''}
                  />
                </td>
                <td style={merge(styles.tableCell, { width: '10em' })}>
                  <CurrencyTextField
                    name="price"
                    fullWidth
                    outline={false}
                    onChange={(_, value) => {
                      VariantEditingActions.variantFieldUpdated(
                        variant.id,
                        'price',
                        // Value can be inaccurate which can lead to wrong cents rounding
                        Math.round((value + Number.EPSILON) * 100)
                      );
                    }}
                    errorText={variant.errors.getErrors('price', intl)}
                    placeholder={t('.price', intl, __filenamespace)}
                    value={variant.price / 100}
                  />
                </td>
                <td style={merge(styles.tableCell, { width: '10em' })}>
                  <CurrencyTextField
                    name="cost"
                    fullWidth
                    outline={false}
                    onChange={(_, value) => {
                      VariantEditingActions.variantFieldUpdated(
                        variant.id,
                        'cost',
                        // Value can be inaccurate which can lead to wrong cents rounding
                        Math.round((value + Number.EPSILON) * 100)
                      );
                    }}
                    errorText={variant.errors.getErrors('cost', intl)}
                    placeholder={t('.cost', intl, __filenamespace)}
                    value={variant.cost / 100}
                  />
                </td>
                <td style={merge(styles.tableCell, { width: '10em' })}>
                  <TextField
                    name="sku"
                    onChange={(_, value) => {
                      VariantEditingActions.variantFieldUpdated(
                        variant.id,
                        'sku',
                        value
                      );
                    }}
                    outline={false}
                    value={variant.sku || ''}
                    placeholder={t('.sku', intl, __filenamespace)}
                    fullWidth
                  />
                </td>
                <td style={merge(styles.tableCell, { width: '10em' })}>
                  <TextField
                    name="gtin"
                    onChange={(_, value) => {
                      VariantEditingActions.variantFieldUpdated(
                        variant.id,
                        'gtin',
                        value
                      );
                    }}
                    outline={false}
                    errorText={variant.errors.getErrors('gtin', intl)}
                    placeholder={t('.barcode', intl, __filenamespace)}
                    value={variant.gtin || ''}
                    fullWidth
                  />
                </td>
              </tr>
            ))
            .toList()}
        </tbody>
      </table>

      <Paginator
        perPage={perPage}
        currentPage={page}
        totalCount={totalCount}
        onPageSelect={onPageSelect}
      />
    </div>
  );
}

const onSave = () => RetailEditingActions.validateAndSave();

function DirtyWarning({ intl }) {
  return (
    <div style={styles.container}>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 15 }}>
        <FormattedMessage
          id={messageId('.product_variants', __filenamespace)}
        />
      </div>
      <Paper
        style={{ padding: '6px 12px', display: 'flex', alignItems: 'center' }}
      >
        <ErrorIcon sx={{ color: uhColors.darkRed }} />
        <div style={{ marginLeft: 20 }}>
          <FormattedMessage
            id={messageId('.dirty_option_type_warning', __filenamespace)}
          />
        </div>
        <Button onClick={onSave}>
          {t('actions.save', intl, __filenamespace)}
        </Button>
      </Paper>
    </div>
  );
}

function LoadingIndicator() {
  return (
    <div style={styles.container}>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 15 }}>
        <FormattedMessage
          id={messageId('.product_variants', __filenamespace)}
        />
      </div>
      <SpinWhileLoading isLoading />
    </div>
  );
}

function VariantEditing({
  intl,
  retailEditingStore,
  variantEditingStore,
  ...props
}) {
  if (!retailEditingStore.product.id) {
    return null;
  }
  if (retailEditingStore.optionTypesDirty) {
    return <DirtyWarning intl={intl} />;
  }
  if (variantEditingStore.isLoading) {
    return <LoadingIndicator />;
  }
  return (
    <VariantTable
      intl={intl}
      product={retailEditingStore.product}
      variantEditingStore={variantEditingStore}
      {...props}
    />
  );
}

export default injectIntl(VariantEditing);
