import React from 'react';
import AltContainer from 'alt-container';
import Content from 'contacts/index/components/ContactImportNative/_Content.jsx';
import ContactImportNativeStore from 'contacts/index/stores/ContactImportNativeStore.js';
import styles from 'contacts/index/components/ContactImportNative/styles';

function ContactImportNative() {
  return (
    <div style={styles.root}>
      <AltContainer store={ContactImportNativeStore}>
        <Content />
      </AltContainer>
    </div>
  );
}

export default ContactImportNative;
