import { List, Record, Set } from 'immutable';
import EventType from 'event_mgmt/shared/records/EventType.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import RetailCategory from 'shared/records/RetailCategory';
import FeeRate from 'shared/records/FeeRate';
import { merge, isPresent } from 'shared/utils/ObjectUtils.jsx';
import TeamType from 'records/TeamType.jsx';

const EVENT_CODE_TYPE = 'event';
const TEAM_CODE_TYPE = 'team';
const MEMBERSHIP_CODE_TYPE = 'membership';
const RETAIL_CODE_TYPE = 'retail';
const CREDIT_PASS_CODE_TYPE = 'credit_pass';

export const CODE_TYPES = List([
  EVENT_CODE_TYPE,
  MEMBERSHIP_CODE_TYPE,
  RETAIL_CODE_TYPE,
  CREDIT_PASS_CODE_TYPE,
  TEAM_CODE_TYPE,
]);

class ServiceFee extends Record({
  id: null,
  archived: false,
  archived_at: null,
  code: '',
  code_type: null,
  customer_id: null,
  description: '',
  has_associated_transactions: false,
  fee_rate: '',
  event_type_ids: Set(),
  retail_category_ids: Set(),
  service_fee_ids: Set(),
  errors: new FieldErrors(),
  charge_type: '',
  event_types: List(),
  retail_categories: List(),
  fee_rates: List(),
  team_types: List(),
  team_type_ids: Set(),
}) {
  constructor(object = {}) {
    const eventTypeIds = Set(object.event_type_ids);
    const eventTypes = List(
      (object.event_types || []).map(t => new EventType(t))
    );
    const teamTypeIds = Set(object.team_type_ids);
    const teamTypes = List((object.team_types || []).map(t => new TeamType(t)));
    const retailCategoryIds = Set(object.retail_category_ids);
    const retailCategories = List(
      (object.retail_categories || []).map(t => new RetailCategory(t))
    );

    const feeRateIds = Set(object.service_fee_ids);
    const feeRates = List((object.fee_rates || []).map(r => new FeeRate(r)));

    super(
      merge(object, {
        event_type_ids: eventTypeIds,
        service_fee_ids: feeRateIds,
        event_types: eventTypes,
        fee_rates: feeRates,
        retail_category_ids: retailCategoryIds,
        retail_categories: retailCategories,
        team_types: teamTypes,
        team_type_ids: teamTypeIds,
      })
    );
  }

  addEventType(eventType) {
    return this.withMutations(r => {
      r.set('event_type_ids', r.event_type_ids.add(eventType.id));

      if (!r.event_types.find(t => t.id === eventType.id)) {
        r.set(
          'event_types',
          r.event_types.push(eventType).sortBy(t => t.name)
        );
      }
    });
  }

  removeEventType(eventType) {
    return this.withMutations(r => {
      r.set('event_type_ids', r.event_type_ids.delete(eventType.id));

      const index = r.event_types.findIndex(t => t.id === eventType.id);

      if (index >= 0) {
        r.set('event_types', r.event_types.delete(index));
      }
    });
  }

  addTeamType(teamType) {
    return this.withMutations(r => {
      r.set('team_type_ids', r.team_type_ids.add(teamType.id));

      if (!r.team_types.find(t => t.id === teamType.id)) {
        r.set(
          'team_types',
          r.team_types.push(teamType).sortBy(t => t.name)
        );
      }
    });
  }

  removeTeamType(teamType) {
    return this.withMutations(r => {
      r.set('team_type_ids', r.team_type_ids.delete(teamType.id));

      const index = r.team_types.findIndex(t => t.id === teamType.id);

      if (index >= 0) {
        r.set('team_types', r.team_types.delete(index));
      }
    });
  }

  addRetailCategory(retailCategory) {
    return this.withMutations(r => {
      r.set(
        'retail_category_ids',
        r.retail_category_ids.add(retailCategory.id)
      );

      if (!r.retail_categories.find(c => c.id === retailCategory.id)) {
        r.set(
          'retail_categories',
          r.retail_categories.push(retailCategory).sortBy(c => c.name)
        );
      }
    });
  }

  removeRetailCategory(retailCategory) {
    return this.withMutations(r => {
      r.set(
        'retail_category_ids',
        r.retail_category_ids.delete(retailCategory.id)
      );

      const index = r.retail_categories.findIndex(
        c => c.id === retailCategory.id
      );

      if (index >= 0) {
        r.set('retail_categories', r.retail_categories.delete(index));
      }
    });
  }

  addFeeRate(feeRate) {
    return this.withMutations(r => {
      r.set('service_fee_ids', r.service_fee_ids.add(feeRate.id));

      if (!r.fee_rates.find(t => t.id === feeRate.id)) {
        r.set(
          'fee_rates',
          r.fee_rates.push(feeRate).sortBy(t => t.name)
        );
      }
    });
  }

  updateFeeRate(feeRate) {
    return this.withMutations(r => {
      const index = r.fee_rates.findIndex(t => t.id === feeRate.id);

      if (index >= 0) {
        r.set('fee_rates', r.fee_rates.set(index, feeRate));
      }
    });
  }

  removeFeeRate(feeRate) {
    return this.withMutations(r => {
      r.set('service_fee_ids', r.service_fee_ids.delete(feeRate.id));

      const index = r.fee_rates.findIndex(t => t.id === feeRate.id);

      if (index >= 0) {
        r.set('fee_rates', r.fee_rates.delete(index));
      }
    });
  }

  isEventCodeType() {
    return this.code_type === EVENT_CODE_TYPE;
  }

  isTeamCodeType() {
    return this.code_type === TEAM_CODE_TYPE;
  }

  isMembershipCodeType() {
    return this.code_type === MEMBERSHIP_CODE_TYPE;
  }

  isRetailCodeType() {
    return this.code_type === RETAIL_CODE_TYPE;
  }

  isCreditPassCodeType() {
    return this.code_type === CREDIT_PASS_CODE_TYPE;
  }

  validate() {
    let errors = this.errors.clear();

    ['code', 'code_type'].forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });

    // Event types have to be present for event codes, but cannot be present
    // for membership, credit pass, or retail codes.
    if (this.code_type === EVENT_CODE_TYPE && this.event_type_ids.isEmpty()) {
      errors = errors.add('event_type_ids', 'records.errors.required');
    } else if (
      this.code_type !== EVENT_CODE_TYPE &&
      !this.event_type_ids.isEmpty()
    ) {
      errors = errors.add(
        'event_type_ids',
        'records.errors.AccountingCode.event_type_ids_present_with_invalid_type'
      );
    }

    if (this.code_type === TEAM_CODE_TYPE && this.team_type_ids.isEmpty()) {
      errors = errors.add('team_type_ids', 'records.errors.required');
    } else if (
      this.code_type !== TEAM_CODE_TYPE &&
      !this.team_type_ids.isEmpty()
    ) {
      errors = errors.add(
        'team_type_ids',
        'records.errors.AccountingCode.team_type_ids_present_with_invalid_type'
      );
    }
    // Retail categories have to be present for retails codes, but cannot be present
    // for membership, credit pass or event codes.
    if (
      this.code_type === RETAIL_CODE_TYPE &&
      this.retail_category_ids.isEmpty()
    ) {
      errors = errors.add('retail_category_ids', 'records.errors.required');
    } else if (
      this.code_type !== RETAIL_CODE_TYPE &&
      !this.retail_category_ids.isEmpty()
    ) {
      errors = errors.add(
        'retail_category_ids',
        'records.errors.AccountingCode.retail_category_ids_present_with_invalid_type'
      );
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  toServer() {
    const obj = this.update('fee_rate', rate => rate || 0.0).toJS();

    delete obj.has_associated_transactions;
    delete obj.errors;
    delete obj.event_types;
    delete obj.retail_categories;
    delete obj.fee_rates;
    delete obj.team_types;

    return obj;
  }
}

export default ServiceFee;
