import { Record, Set, List } from 'immutable';
import { toCamelCase } from 'shared/utils/ObjectUtils.jsx';

import PaymentPlanAllocation from './PaymentPlanAllocation';

class PaymentPlan extends Record({
  id: null,
  clientId: null,
  allocations: List(),
  debitEntryIds: Set(),
  scheduledPaymentIds: Set(),
  remainingScheduledPayments: List(),
  amount: 0,
  remainingAmount: 0,
  startDate: null,
  endDate: null,
  nextPaymentDate: null,
  effectiveAt: null,
  planPaidAmount: null,
  planWaivedAmount: null,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super({
      ...parsedObject,
      scheduledPaymentIds: Set(parsedObject.scheduledPaymentIds),
      remainingScheduledPayments: List(parsedObject.remainingScheduledPayments),
      debitEntryIds: Set(parsedObject.debitEntryIds),
      allocations: List(
        parsedObject.allocations?.map(
          allocation => new PaymentPlanAllocation(allocation)
        )
      ),
    });
  }
}

export default PaymentPlan;
