import * as React from 'react';
import PropTypes from 'prop-types';

import { isLoggedIn } from 'shared/utils/UserUtils.jsx';

function WhenSignedIn({ children, notSignedIn, style }) {
  if (isLoggedIn()) {
    return <div style={style}>{children}</div>;
  }
  return notSignedIn;
}

WhenSignedIn.defaultProps = {
  notSignedIn: <div />,
  style: {},
};

WhenSignedIn.propTypes = {
  /**
   * Node to be displayed when the user is not logged in.
   */
  notSignedIn: PropTypes.node,
  style: PropTypes.object,
};

export default WhenSignedIn;
