import * as React from 'react';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { Set } from 'immutable';
import { Confirmation } from '@upperhand/playmaker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

import ContactActions from 'event_mgmt/shared/actions/ContactActions.jsx';

import { dateWithTime } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

const styles = {
  formInputWidths: {
    width: '100%',
    maxWidth: '100%',
  },
  chipWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

let typingTimeout;

class Form extends React.Component {
  state = {
    contactSearchText: '',
    contactGroupSearchText: '',
  };

  handleContactSelection = (selection, index) => {
    if (index !== -1) {
      const { onContactSelection } = this.props;
      onContactSelection(selection);
      this.setState({
        // this handles the case where
        contactSearchText: selection.text, // the user selects from the drop
      }); // drop-down without typing
      this.setState({
        contactSearchText: '',
      });
    }
  };

  handleContactGroupSelection = (selection, index) => {
    if (index !== -1) {
      const { onContactGroupSelection } = this.props;
      onContactGroupSelection(selection);
      this.setState({
        // this handles the case where
        contactGroupSearchText: selection.text, // the user selects from the drop
      }); // drop-down without typing
      this.setState({
        contactGroupSearchText: '',
      });
    }
  };

  handleUpdateContactInput = search => {
    this.setState({
      contactSearchText: search,
    });

    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      if (search.length && search.length > 2) {
        ContactActions.list({ search_string: search, archived: false });
      }
    }, 400);
  };

  handleUpdateContactGroupInput = search => {
    this.setState({
      contactGroupSearchText: search,
    });
  };

  selectedContactsChips() {
    const { onContactDeselection, selectedContacts } = this.props;
    return selectedContacts.map(contact => (
      <Chip
        key={contact.id}
        label={contact.name}
        onDelete={() => onContactDeselection(contact)}
        style={{ margin: 4 }}
      />
    ));
  }

  selectedContactGroupsChips() {
    const { onContactGroupDeselection, selectedContactGroups } = this.props;
    return selectedContactGroups.map(cg => (
      <Chip
        key={cg.id}
        label={cg.name}
        onDelete={() => onContactGroupDeselection(cg)}
        style={{ margin: 4 }}
      />
    ));
  }

  sendTime() {
    const { sendTime, sendDate } = this.props;

    return dateWithTime(sendTime, sendDate.toDate());
  }

  sendTimeErrorText() {
    const { tz_name: customerTz } = currentCustomer();
    const { sendTimeErrorText, intl, sendDate } = this.props;

    if (sendTimeErrorText) {
      return sendTimeErrorText;
    }
    if (sendDate.isBefore(moment().tz(customerTz), 'minute')) {
      return t('.not_in_past', intl, __filenamespace);
    }
    return null;
  }

  contacts() {
    const { allContacts, selectedContacts } = this.props;

    return allContacts
      .subtract(selectedContacts)
      .map(c => ({
        label: `${c.name} ${c.email || ''}`,
        contact: c,
        value: c.id,
      }))
      .toJS();
  }

  contactGroups() {
    const { allContactGroups, selectedContactGroups } = this.props;
    return allContactGroups
      .subtract(selectedContactGroups)
      .map(cg => ({
        label: cg.name,
        contactGroup: cg,
        value: cg.id,
      }))
      .toJS();
  }

  render() {
    const { contactGroupSearchText, contactSearchText } = this.state;
    const {
      intl,
      sendDate,
      sendDateErrorText,
      onSendDateChange,
      onSendTimeChange,
      selectedContactGroups,
    } = this.props;

    const { tz_name: customerTz } = currentCustomer();
    const groupsDisabled = selectedContactGroups.map(c => c.id).includes(-1);
    const startOfDay = moment.tz(moment().startOf('day'), customerTz);
    const dateInPast = date => moment.tz(date, customerTz).isBefore(startOfDay);

    return (
      <section style={{ padding: '0 15px' }}>
        <DatePicker
          disableMaskedInput
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="standard"
              style={{ marginBottom: 10 }}
              error={!!sendDateErrorText}
              helperText={sendDateErrorText}
            />
          )}
          value={sendDate}
          shouldDisableDate={dateInPast}
          inputFormat="MMM D, YYYY"
          label={t('.select_date', intl, __filenamespace)}
          onChange={onSendDateChange}
        />
        <TimePicker
          label={t('.select_time', intl, __filenamespace)}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="standard"
              style={{ marginBottom: 10 }}
              error={!!this.sendTimeErrorText()}
              helperText={this.sendTimeErrorText()}
            />
          )}
          value={this.sendTime()}
          onChange={value => {
            onSendTimeChange(value);
          }}
        />
        <div>
          <Autocomplete
            disableClearable
            disabled={groupsDisabled}
            options={this.contactGroups()}
            renderInput={params => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={t('.contact_groups', intl, __filenamespace)}
                variant="standard"
                style={{ marginBottom: 10 }}
              />
            )}
            inputValue={contactGroupSearchText}
            renderOption={(props, option) => (
              <MenuItem
                key={option.contactGroup.id}
                onClick={() =>
                  this.handleContactGroupSelection(
                    option,
                    props['data-option-index']
                  )
                }
              >
                {option.label}
              </MenuItem>
            )}
            onInputChange={(e, value, reason) => {
              if (reason === 'input') {
                this.handleUpdateContactGroupInput(value);
              }
            }}
          />
        </div>
        <div style={{ ...styles.chipWrapper, ...styles.formInputWidths }}>
          {this.selectedContactGroupsChips()}
        </div>
        <div>
          <Autocomplete
            disableClearable
            options={this.contacts()}
            renderInput={params => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={t('.contacts', intl, __filenamespace)}
                variant="standard"
              />
            )}
            inputValue={contactSearchText}
            renderOption={(props, option) => (
              <MenuItem
                key={option.contact.id}
                onClick={() =>
                  this.handleContactSelection(
                    option,
                    props['data-option-index']
                  )
                }
              >
                <Stack>
                  <Typography variant="subtitle1">
                    {option.contact.name}
                  </Typography>
                  {option.contact.email && (
                    <Typography variant="subtitle2">
                      {option.contact.email}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            )}
            onInputChange={(e, value, reason) => {
              if (reason === 'input') {
                this.handleUpdateContactInput(value);
              }
            }}
          />
        </div>
        <div style={{ ...styles.chipWrapper, ...styles.formInputWidths }}>
          {this.selectedContactsChips()}
        </div>
      </section>
    );
  }
}

function EventMarketingForm({
  onFormClose,
  intl,
  open,
  sendDate,
  sendDateErrorText,
  onSendDateChange,
  sendTime,
  sendTimeErrorText,
  onSendTimeChange,
  allContacts,
  selectedContacts,
  onContactSelection,
  onContactDeselection,
  allContactGroups,
  selectedContactGroups,
  onContactGroupDeselection,
  onContactGroupSelection,
}) {
  return (
    <Confirmation
      hasCheckbox={false}
      title="Market Event"
      open={open}
      cancelButtonLabel={t('.cancel', intl, __filenamespace)}
      confirmButtonLabel={t('.submit', intl, __filenamespace)}
      onCancel={() => onFormClose(false)}
      onConfirm={() => onFormClose(true)}
    >
      <Form
        intl={intl}
        sendDate={sendDate}
        sendDateErrorText={sendDateErrorText}
        onSendDateChange={onSendDateChange}
        sendTime={sendTime}
        sendTimeErrorText={sendTimeErrorText}
        onSendTimeChange={onSendTimeChange}
        allContacts={allContacts}
        selectedContacts={selectedContacts}
        onContactSelection={onContactSelection}
        onContactDeselection={onContactDeselection}
        allContactGroups={Set(allContactGroups)}
        selectedContactGroups={selectedContactGroups}
        onContactGroupSelection={onContactGroupSelection}
        onContactGroupDeselection={onContactGroupDeselection}
      />
    </Confirmation>
  );
}

export default injectIntl(EventMarketingForm);
