/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Set } from 'immutable';
import Membership from 'shared/records/Membership.jsx';
import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

import BaseDiscountsStore from './BaseDiscountsStore';

class MembershipEditingStore extends BaseDiscountsStore {
  constructor(args) {
    super(args);

    this.selectedEventTypeIds = Set();
    this.selectedEventIds = Set();

    this.bindListeners({
      handleReset: StoreActions.prepareForReuse,
      handleUpdateStore: MembershipEditingActions.UPDATE_STORE,

      removeEventDiscount: MembershipEditingActions.EVENT_DISCOUNT_REMOVED,
      addEventDiscountEventType:
        MembershipEditingActions.EVENT_DISCOUNT_EVENT_TYPE_ADDED,
      removeEventDiscountEventType:
        MembershipEditingActions.EVENT_DISCOUNT_EVENT_TYPE_REMOVED,
      updateEventDiscountAllEvents:
        MembershipEditingActions.EVENT_DISCOUNT_ALL_EVENTS_TOGGLED,
      updateEventDiscountType:
        MembershipEditingActions.EVENT_DISCOUNT_TYPE_UPDATED,
      updateEventDiscountValue:
        MembershipEditingActions.EVENT_DISCOUNT_VALUE_UPDATED,

      addProductDiscount: MembershipEditingActions.PRODUCT_DISCOUNT_ADDED,
      removeProductDiscount: MembershipEditingActions.PRODUCT_DISCOUNT_REMOVED,
      addProductDiscountCategory:
        MembershipEditingActions.PRODUCT_DISCOUNT_CATEGORY_ADDED,
      removeProductDiscountCategory:
        MembershipEditingActions.PRODUCT_DISCOUNT_CATEGORY_REMOVED,
      updateProductDiscountAllProducts:
        MembershipEditingActions.PRODUCT_DISCOUNT_ALL_PRODUCTS_TOGGLED,
      updateProductDiscountValue:
        MembershipEditingActions.PRODUCT_DISCOUNT_VALUE_UPDATED,

      handleCreateOrUpdate: MembershipEditingActions.CREATE_OR_UPDATE,
      handleError: MembershipEditingActions.ERROR,
      handleFetch: MembershipEditingActions.FETCH,
      handleResetCommitmentLength:
        MembershipEditingActions.COMMITMENT_LENGTH_TOGGLED,
      handleCommitmentToggle: MembershipEditingActions.COMMITMENT_CHECKED,
      handleResetJoinFee: MembershipEditingActions.JOIN_FEE_TOGGLED,
      handleResetPreSale: MembershipEditingActions.PRE_SALE_TOGGLED,
      handleSaveSuccess: MembershipEditingActions.MEMBERSHIP_LOADED,
      handleValidateAndSave: MembershipEditingActions.VALIDATE_AND_SAVE,
      addCreditPassDiscount:
        MembershipEditingActions.CREDIT_PASS_DISCOUNT_ADDED,
      removeCreditPassDiscount:
        MembershipEditingActions.CREDIT_PASS_DISCOUNT_REMOVED,
      updateCreditPassDiscountAllPasses:
        MembershipEditingActions.CREDIT_PASS_DISCOUNT_ALL_PASSES_TOGGLED,
      updateCreditPassDiscountType:
        MembershipEditingActions.CREDIT_PASS_DISCOUNT_TYPE_UPDATED,
      addCreditPassDiscountCreditPass:
        MembershipEditingActions.CREDIT_PASS_DISCOUNT_CREDIT_PASS_ADDED,
      removeCreditPassDiscountCreditPass:
        MembershipEditingActions.CREDIT_PASS_DISCOUNT_CREDIT_PASS_REMOVED,
      updateCreditPassDiscountValue:
        MembershipEditingActions.CREDIT_PASS_DISCOUNT_VALUE_UPDATED,
    });
  }

  handleReset() {
    this.record = this.newRecord();
  }

  newRecord(options) {
    return new Membership(options);
  }

  handleUpdateStore(state) {
    let newState = state;

    if ('interval_months' in state && state.interval_months === 0) {
      newState = merge(state, {
        commitment_months: 0,
        commitment_checked: false,
      });
    }

    if ('invite_only' in state && state.invite_only === false) {
      newState = merge(state, { hidden: false });
    }

    if ('hide_in_client_checkout' in state && state.hidden === false) {
      newState = merge(state, { hide_in_pos: false });
    }

    super.handleUpdateStore(newState);
  }

  removeEventDiscount({ index }) {
    this.record = this.record.removeEventDiscount({ index });
  }

  addEventDiscountEventType([discountIndex, eventTypeId]) {
    this.record = this.record.updateIn(
      ['membership_event_discounts', discountIndex, 'event_type_ids'],
      (set = Set()) => set.add(eventTypeId)
    );
  }

  removeEventDiscountEventType([discountIndex, eventTypeId]) {
    this.record = this.record.updateIn(
      ['membership_event_discounts', discountIndex, 'event_type_ids'],
      (set = Set()) => set.remove(eventTypeId)
    );
  }

  updateEventDiscountAllEvents([discountIndex, newValue]) {
    if (newValue === 'true' || newValue === true) {
      this.record = this.record.mergeIn(
        ['membership_event_discounts', discountIndex],
        { all_events: true, event_type_ids: Set() }
      );
    } else {
      this.record = this.record.setIn(
        ['membership_event_discounts', discountIndex, 'all_events'],
        false
      );
    }
  }

  updateEventDiscountValue([discountIndex, value]) {
    this.record = this.record.setIn(
      ['membership_event_discounts', discountIndex, 'value'],
      value
    );
  }

  updateEventDiscountType([discountIndex, value]) {
    this.record = this.record.setIn(
      ['membership_event_discounts', discountIndex, 'discount_type'],
      value
    );
  }

  addCreditPassDiscount() {
    const existingAllPassesDiscount =
      this.record.membership_credit_pass_discounts.some(
        d => d.all_credit_passes
      );

    this.record = this.record.addCreditPassDiscount({
      all_credit_passes: !existingAllPassesDiscount,
    });
  }

  removeCreditPassDiscount({ index }) {
    this.record = this.record.removeCreditPassDiscount({ index });
  }

  updateCreditPassDiscountAllPasses([discountIndex, newValue]) {
    if (newValue === 'true' || newValue === true) {
      this.record = this.record.mergeIn(
        ['membership_credit_pass_discounts', discountIndex],
        { all_credit_passes: true, credit_pass_ids: Set() }
      );
    } else {
      this.record = this.record.setIn(
        [
          'membership_credit_pass_discounts',
          discountIndex,
          'all_credit_passes',
        ],
        false
      );
    }
  }

  updateCreditPassDiscountType([discountIndex, value]) {
    this.record = this.record.setIn(
      ['membership_credit_pass_discounts', discountIndex, 'discount_type'],
      value
    );
  }

  addCreditPassDiscountCreditPass([discountIndex, creditPassId]) {
    this.record = this.record.updateIn(
      ['membership_credit_pass_discounts', discountIndex, 'credit_pass_ids'],
      (set = Set()) => set.add(creditPassId)
    );
  }

  removeCreditPassDiscountCreditPass([discountIndex, creditPassId]) {
    this.record = this.record.updateIn(
      ['membership_credit_pass_discounts', discountIndex, 'credit_pass_ids'],
      (set = Set()) => set.remove(creditPassId)
    );
  }

  updateCreditPassDiscountValue([discountIndex, value]) {
    this.record = this.record.setIn(
      ['membership_credit_pass_discounts', discountIndex, 'value'],
      value
    );
  }

  addProductDiscount() {
    this.record = this.record.addRetailDiscount({
      all_retail_products: !this.existingAllProductsDiscount,
    });
    this.updateCategoriesStatus();
  }

  removeProductDiscount({ index }) {
    this.record = this.record.removeRetailDiscount({ index });
    this.updateCategoriesStatus();
  }

  handleCommitmentToggle() {
    this.record = this.record.set(
      'commitment_checked',
      !this.record.commitment_checked
    );
  }

  addProductDiscountCategory([discountIndex, retailCategoryId]) {
    this.record = this.record.updateIn(
      ['membership_retail_discounts', discountIndex, 'retail_category_ids'],
      (set = Set()) => set.add(retailCategoryId)
    );
    this.updateCategoriesStatus();
  }

  removeProductDiscountCategory([discountIndex, retailCategoryId]) {
    this.record = this.record.updateIn(
      ['membership_retail_discounts', discountIndex, 'retail_category_ids'],
      (set = Set()) => set.remove(retailCategoryId)
    );
    this.updateCategoriesStatus();
  }

  updateProductDiscountAllProducts([discountIndex, newValue]) {
    if (newValue === 'true' || newValue === true) {
      this.record = this.record.mergeIn(
        ['membership_retail_discounts', discountIndex],
        { all_retail_products: true, retail_category_ids: Set() }
      );
    } else {
      this.record = this.record.setIn(
        ['membership_retail_discounts', discountIndex, 'all_retail_products'],
        false
      );
    }
    this.updateCategoriesStatus();
  }

  updateProductDiscountValue([discountIndex, value]) {
    this.record = this.record.setIn(
      ['membership_retail_discounts', discountIndex, 'value'],
      value
    );
  }

  handleSaveSuccess(result) {
    if (!this.record.id) {
      window.history.pushState(
        null,
        document.title,
        window.location.pathname.replace('/new', `/${result.id}/edit`)
      );
    }
    this.record = new Membership(result);
    this.updateCategoriesStatus();
    this.updateEventsStatus(true);
  }

  updateCategoriesStatus() {
    this.waitFor(RetailCategoryListingStore);

    const { retailCategories } = RetailCategoryListingStore.getState();
    this.existingAllProductsDiscount =
      this.record.membership_retail_discounts.some(d => d.all_retail_products);
    const selectedRetailCategoryIds =
      this.record.membership_retail_discounts.reduce(
        (set, discount) => set.union(discount.retail_category_ids),
        Set()
      );

    this.unselectedRetailCategoryIds = retailCategories.filter(
      c => !selectedRetailCategoryIds.has(c.id)
    );

    this.selectedRetailCategories = this.record.membership_retail_discounts
      .map(mrd => mrd.retail_category_ids)
      .map(ids => retailCategories.filter(c => ids.includes(c.id)));
  }

  handleCreateOrUpdate() {
    return this.record.id
      ? uhApiClient.put(this.apiArgs())
      : uhApiClient.post(this.apiArgs());
  }

  handleValidateAndSave() {
    this.record = this.record.validate();
    if (this.record.isValid()) {
      this.handleCreateOrUpdate();
    }
  }

  handleFetch(id) {
    uhApiClient.get({
      url: this.url(id),
      success: MembershipEditingActions.membershipLoaded,
      error: MembershipEditingActions.error,
    });
  }

  handleResetJoinFee() {
    this.record = this.record.set('join_fee', 0);
  }

  handleResetCommitmentLength() {
    this.record = this.record.set('commitment_months', 0);
  }

  handleResetPreSale() {
    this.record = this.record.set('active_at', null);
  }

  apiArgs() {
    this.setDiscounts();

    return {
      url: this.url(),
      data: JSON.stringify({
        attributes: this.record,
      }),
      success: MembershipEditingActions.membershipLoaded,
      error: MembershipEditingActions.error,
    };
  }

  url(id = this.record.id) {
    return id ? `memberships/${id}` : 'memberships';
  }
}

export default alt.createStore(
  MembershipEditingStore,
  'MembershipEditingStore',
  { actions: MembershipEditingActions }
);
