import * as React from 'react';
import Paper from '@mui/material/Paper';
import { TextField } from '@upperhand/playmaker';
import { getValue } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';

function FirstName({
  defaultValue,
  errorText,
  intl,
  label,
  onChange,
  placeholder,
  emailData,
}) {
  return (
    <TextField
      errorText={errorText}
      fullWidth
      label={label || t('.first_name', intl, __filenamespace)}
      name="first_name"
      onChange={onChange}
      placeholder={placeholder || t('.first_name', intl, __filenamespace)}
      value={getValue(emailData.get('first_name'), defaultValue)}
    />
  );
}
function LastName({
  defaultValue,
  errorText,
  intl,
  label,
  onChange,
  placeholder,
  emailData,
}) {
  return (
    <TextField
      errorText={errorText}
      fullWidth
      label={label || t('.last_name', intl, __filenamespace)}
      name="last_name"
      onChange={onChange}
      placeholder={placeholder || t('.last_name', intl, __filenamespace)}
      value={getValue(emailData.get('last_name'), defaultValue)}
    />
  );
}

export function Email({
  defaultValue,
  errorText,
  intl,
  label,
  onChange,
  emailData,
}) {
  return (
    <TextField
      errorText={errorText}
      fullWidth
      label={label || t('.email_address', intl, __filenamespace)}
      name="email"
      onChange={onChange}
      placeholder={t('.example_email', intl, __filenamespace)}
      type="email"
      value={getValue(emailData.get('email'), defaultValue)}
    />
  );
}
function AdditionalEmailsEditor({
  actionButtons,
  onChange,
  emailData,
  errors,
  intl,
}) {
  const errorsFor = field => errors.getErrors(field, intl);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '16px',
        margin: '25px 3px 3px 3px',
        '& > div': {
          marginBottom: '1rem',
        },
      }}
    >
      <FirstName
        emailData={emailData}
        onChange={onChange}
        intl={intl}
        errorText={errorsFor('first_name')}
      />
      <LastName
        emailData={emailData}
        onChange={onChange}
        intl={intl}
        errorText={errorsFor('last_name')}
      />
      <Email
        emailData={emailData}
        onChange={onChange}
        intl={intl}
        errorText={errorsFor('email')}
      />

      {actionButtons}
    </Paper>
  );
}

export default AdditionalEmailsEditor;
