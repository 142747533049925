/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown, Grid } from '@upperhand/playmaker';

import { COUPON_DISCOUNTABLE_TYPES } from 'records/DiscountableItem';
import { t } from 'shared/utils/LocaleUtils.js';

import TextAutoComplete from './TextAutoComplete.jsx';

const DiscountableCard = injectIntl(
  ({ actions, coupon, searchableDataSource, type, intl }) => (
    <Grid container className="coupon__discountable-card">
      <Dropdown
        id={`discountable_${type.toLowerCase()}`}
        name={`discountable_${type.toLowerCase()}`}
        fullWidth
        items={[
          {
            label: t(`.all_${type.toLowerCase()}`, intl, __filenamespace),
            value: COUPON_DISCOUNTABLE_TYPES.all,
          },
          {
            label: t(`.specific_${type.toLowerCase()}`, intl, __filenamespace),
            value: COUPON_DISCOUNTABLE_TYPES.specific,
          },
        ]}
        onChange={event =>
          actions.discountableTypeChanged(type, event.target.value)
        }
        value={
          coupon.get('discountables').includes(type)
            ? COUPON_DISCOUNTABLE_TYPES.all
            : COUPON_DISCOUNTABLE_TYPES.specific
        }
      />
      {!coupon.get('discountables').includes(type) && (
        <Grid item xs={12} className="coupon__autocomplete">
          <TextAutoComplete
            hintText={t(`.search_${type.toLowerCase()}`, intl, __filenamespace)}
            clearOnSelect
            dataSource={searchableDataSource}
            onChange={s => actions.search(type, s)}
            onSelect={id => actions.addDiscountableItem(type, id)}
            onLeave={() => actions.search(type, '')}
            withIcon
            popoverProps={{ canAutoPosition: true }}
            maxSearchResults={5}
          />
        </Grid>
      )}
    </Grid>
  )
);

export default DiscountableCard;
