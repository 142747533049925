import * as React from 'react';
import { List, OrderedSet, Set } from 'immutable';
import moment from 'moment-timezone';
import Snackbar from '@mui/material/Snackbar';

import EventMarketing from 'event_mgmt/display/components/_EventMarketing.jsx';
import ContactActions from 'event_mgmt/shared/actions/ContactActions.jsx';
import ContactStore from 'event_mgmt/shared/stores/ContactStore.jsx';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import ContactGroupStore from 'shared/stores/ContactGroupStore.jsx';
import MarketingActions from 'event_mgmt/shared/actions/MarketingActions.jsx';
import MarketingStore from 'event_mgmt/shared/stores/MarketingStore.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { prettyFormat } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

class EventMarketingWrapper extends React.PureComponent {
  state = {
    sendDate: moment.tz(moment(), currentCustomer().tz_name),
    snackbarMessage: '',
    allContacts: OrderedSet(),
    allContactGroups: List(),
    selectedContacts: Set(),
    selectedContactGroups: Set(),
  };

  componentDidMount() {
    ContactGroupActions.list({});
    ContactStore.listen(this.onContactStoreChange);
    ContactGroupStore.listen(this.onContactGroupStoreChange);
    MarketingStore.listen(this.onMarketingStoreChange);
  }

  componentWillUnmount() {
    ContactStore.unlisten(this.onContactStoreChange);
    ContactGroupStore.unlisten(this.onContactGroupStoreChange);
    MarketingStore.unlisten(this.onMarketingStoreChange);
  }

  handleContactDeselection = contact => {
    const { selectedContacts } = this.state;
    const newSelections = selectedContacts.delete(contact);
    this.setState({
      selectedContacts: newSelections,
    });
    ContactActions.selection(newSelections);
  };

  handleContactSelection = menuSelection => {
    const { selectedContacts } = this.state;
    const newSelections = selectedContacts.add(menuSelection.contact);
    this.setState({
      selectedContacts: newSelections,
    });
    ContactActions.selection(newSelections);
  };

  handleContactGroupDeselection = contactGroup => {
    const { selectedContactGroups } = this.state;
    const newSelections = selectedContactGroups.delete(contactGroup);
    this.setState({
      selectedContactGroups: newSelections,
    });
    ContactGroupActions.selection(newSelections);
  };

  handleContactGroupSelection = menuSelection => {
    const { selectedContactGroups } = this.state;
    const newSelections =
      menuSelection.contactGroup.id === -1
        ? Set([menuSelection.contactGroup])
        : selectedContactGroups.add(menuSelection.contactGroup);
    this.setState({
      selectedContactGroups: newSelections,
    });

    ContactGroupActions.selection(newSelections);
  };

  onContactStoreChange = store => {
    this.setState({
      allContacts: store.allContacts,
      selectedContacts: store.selectedContacts,
    });
  };

  onContactGroupStoreChange = store => {
    this.setState({
      allContactGroups: store.allContactGroups,
      selectedContactGroups: store.selectedContactGroups,
    });
  };

  onMarketingStoreChange = store => {
    if (!store.posting) {
      if (store.success) {
        this.marketingOnSuccess();
      } else {
        this.marketingOnFailure();
      }
    }
  };

  onSave = () => {
    const { event } = this.props;
    const { sendDate, selectedContacts, selectedContactGroups } = this.state;
    if (sendDate) {
      const marketingData = {
        eventId: event.id,
        eventTitle: event.title,
        params: {
          send_at: sendDate,
          customer_user_ids: selectedContacts.map(c => c.id).toJS(),
          contact_group_ids: selectedContactGroups.map(cg => cg.id).toJS(),
        },
      };
      MarketingActions.post(marketingData);
    } else {
      this.setState({
        snackbarMessage: 'Date required for marketing email',
      });
    }
  };

  marketingOnSuccess = () => {
    const { sendDate } = this.state;
    const formattedDate = prettyFormat(sendDate, {
      withTime: true,
      withTimezone: true,
      timeSeparator: ' \\at ',
    });
    this.setState({
      snackbarMessage: `Scheduled marketing email for ${formattedDate}`,
    });
  };

  marketingOnFailure = () => {
    this.setState({
      snackbarMessage: 'Failed to schedule marketing email',
    });
  };

  sendTime = () => {
    const { sendDate } = this.state;

    return sendDate.format('HH:mm:00');
  };

  render() {
    const {
      eventMarketingStore: { formOpen },
    } = this.props;
    const {
      allContacts,
      selectedContacts,
      allContactGroups,
      selectedContactGroups,
      sendDate,
      snackbarMessage,
    } = this.state;

    return (
      <section style={{ display: 'inline' }}>
        <EventMarketing
          formOpen={formOpen}
          onSave={this.onSave}
          onChange={state => {
            this.setState(state);
          }}
          allContacts={allContacts}
          selectedContacts={selectedContacts}
          onContactSelection={this.handleContactSelection}
          onContactDeselection={this.handleContactDeselection}
          allContactGroups={allContactGroups}
          selectedContactGroups={selectedContactGroups}
          onContactGroupSelection={this.handleContactGroupSelection}
          onContactGroupDeselection={this.handleContactGroupDeselection}
          sendDate={sendDate}
          sendTime={this.sendTime()}
        />
        <Snackbar
          open={snackbarMessage !== ''}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={snackbarMessage}
          autoHideDuration={5000}
          onClose={() => this.setState({ snackbarMessage: '' })}
        />
      </section>
    );
  }
}

export default EventMarketingWrapper;
