import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { ResourceActions } from 'sources';

class ResourceDataStore extends UpperHandStore {
  constructor() {
    super();

    this.records = Map();

    this.bindListeners({
      fetchSuccess: [ResourceActions.fetchSuccess],
      fetchError: [ResourceActions.fetchError],
      listSuccess: [ResourceActions.listSuccess],
      listError: [ResourceActions.listError],
    });
  }

  fetchSuccess(resource) {
    this.records = this.records.set(resource.id, resource);
  }

  fetchError(...args) {
    this.notifyError('error fetching resource', args);
  }

  listSuccess({ resources }) {
    const fetchedRecords = resources.groupBy(s => s.id).map(s => s.first());

    this.records = this.records.merge(fetchedRecords);
  }

  listError(...args) {
    this.notifyError('error fetching resources', args);
  }
}

export default alt.createStore(ResourceDataStore, 'ResourceDataStore');
