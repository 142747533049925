import * as React from 'react';
import { Route } from 'react-router-dom';

import Coupons from 'containers/coupons/couponList/Coupons.jsx';
import Coupon from 'containers/coupons/couponView/Coupon.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';
import Page from 'routes/Page.jsx';

import {
  isAdmin,
  isStaffAdmin,
  isManager,
} from 'routes/helpers/routePermissions';
import { isTexasTwelveTheme } from 'shared/utils/ThemeUtils';

const couponsPermissions = isTexasTwelveTheme()
  ? [isAdmin, isStaffAdmin]
  : [isAdmin, isStaffAdmin, isManager];

const couponsRoutes = () => (
  <Route path=":customer_id">
    <Route
      path="coupons"
      element={
        <AuthorizedPageWrapper allowedRoles={couponsPermissions}>
          <Page>
            <Coupons />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="coupons/new"
      element={
        <AuthorizedPageWrapper allowedRoles={couponsPermissions}>
          <Page>
            <Coupon createRoute editRoute />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="coupons/:couponID"
      element={
        <AuthorizedPageWrapper allowedRoles={couponsPermissions}>
          <Page>
            <Coupon />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="coupons/:couponID/edit"
      element={
        <AuthorizedPageWrapper allowedRoles={couponsPermissions}>
          <Page>
            <Coupon editRoute />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
  </Route>
);

export default couponsRoutes;
