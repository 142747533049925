import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import GearIcon from 'shared/components/icons/Gear.jsx';
import FilteredSelection from 'shared/records/FilteredSelection.js';

import ButtonMenu from 'shared/components/ButtonMenu.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  buttonStyle: {
    margin: '10px 0',
    minWidth: 170,
  },
  labelStyle: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  iconStyle: {
    height: 16,
    width: 16,
  },
  menuItem: {
    padding: 5,
    fontSize: 15,
    margin: 0,
  },
  menuItemLabel: {
    fontSize: 15,
  },
};

class ColumnsMenu extends React.PureComponent {
  handleClickAll = value => {
    const { onChange, filteredSelection } = this.props;
    onChange(filteredSelection.changeSelectionAll(value));
  };

  handleClick = (key, value) => {
    const { onChange, filteredSelection } = this.props;
    onChange(filteredSelection.changeSelectionItem(key, value));
  };

  render() {
    const { intl, filteredSelection, buttonStyle, labelStyle } = this.props;

    return (
      <ButtonMenu
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        label={t('.label', intl, __filenamespace)}
        labelPosition="after"
        labelStyle={{ ...styles.labelStyle, ...labelStyle }}
        icon={<GearIcon color={uhColors.grey} style={styles.iconStyle} />}
        buttonStyle={{ ...styles.buttonStyle, ...buttonStyle }}
      >
        <FormGroup>
          <FormControlLabel
            key="all"
            control={<Checkbox />}
            label={t('.all', intl, __filenamespace)}
            checked={filteredSelection.isSelectedAll()}
            onChange={e => this.handleClickAll(e.target.checked)}
            style={styles.menuItem}
          />
          <Divider />
          {filteredSelection.get('source').map(item => (
            <FormControlLabel
              key={item.key}
              control={<Checkbox />}
              label={item.value.toString({ intl })}
              checked={filteredSelection.isSelectedItem(item.key)}
              onChange={e => this.handleClick(item.key, e.target.checked)}
              style={styles.menuItem}
            />
          ))}
        </FormGroup>
      </ButtonMenu>
    );
  }
}

ColumnsMenu.propTypes = {
  intl: PropTypes.object,
  filteredSelection: PropTypes.instanceOf(FilteredSelection).isRequired,
  onChange: PropTypes.func,
  buttonStyle: PropTypes.object,
  labelStyle: PropTypes.object,
};

ColumnsMenu.defaultProps = {
  intl: {},
  onChange: () => {},
  buttonStyle: {},
  labelStyle: {},
};

export default injectIntl(ColumnsMenu);
