import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import {
  formatClientTime,
  formatDate,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const SESSION_AVAILABLE = 'available';
const FULL = 'Full';
const PURCHASED = 'purchased';

const styles = {
  allDay: {
    width: '100%',
    fontWeight: 'bold',
  },
  fullWidth: {
    width: '100%',
  },
  labelItem: {
    marginBottom: 10,
    width: '100%',
  },
};

function AllDaysLabel({ purchased, price }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={1}
      style={styles.allDay}
    >
      <FormattedMessage id={messageId('.all_day_checkbox', __filenamespace)} />
      <div>
        {purchased ? (
          <PurchasedIndicator />
        ) : (
          <FormattedCurrency value={price} />
        )}
      </div>
    </Stack>
  );
}

function PurchasedIndicator() {
  return (
    <div style={{ color: uhColors.green }}>
      <FormattedMessage id={messageId('.purchased', __filenamespace)} />
    </div>
  );
}

function SessionCheckboxLabel({ session, status, price }) {
  let sessionInfo;

  if (status === PURCHASED) {
    sessionInfo = <PurchasedIndicator />;
  } else if (status === FULL) {
    sessionInfo = <FormattedMessage id={messageId('.full', __filenamespace)} />;
  } else {
    sessionInfo = <FormattedCurrency value={price} />;
  }

  return (
    <Stack alignItems="flex-start" spacing={1} style={styles.fullWidth}>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ ...boldText, ...styles.fullWidth }}
      >
        <div>{formatDate(session.get('starts_at'))}</div>
        {sessionInfo}
      </Stack>
      <div>
        {formatClientTime(session.get('starts_at'), 'h:mma')}
        &nbsp;-&nbsp;
        {formatClientTime(session.get('ends_at'), 'h:mma')}
      </div>
    </Stack>
  );
}

const getSessionStatus = (session, spotsRemaining, athleteSessions) => {
  let status = SESSION_AVAILABLE;
  if (
    athleteSessions.some(
      s => s.get('session_id') === session.id && s.get('purchased')
    )
  ) {
    status = PURCHASED;
  }

  if (spotsRemaining <= 0 && status === SESSION_AVAILABLE) {
    status = FULL;
  }

  return status;
};

const onCheck = (isChecked, athleteId, sessionId, schedulingActions) => {
  if (isChecked) {
    schedulingActions.singleSessionAdded(athleteId, sessionId);
  } else {
    schedulingActions.singleSessionRemoved(athleteId, sessionId);
  }
};

function Session({
  checked,
  clientId,
  schedulingActions,
  session,
  status,
  price,
}) {
  const disabled = status === PURCHASED || (status === FULL && !checked);

  return (
    <FormControlLabel
      disableTypography
      control={<Checkbox />}
      checked={checked}
      disabled={disabled}
      key={`${clientId}-${session.id}`}
      name={`${clientId}-${session.id}`}
      label={
        <SessionCheckboxLabel session={session} status={status} price={price} />
      }
      onChange={e => {
        onCheck(e.target.checked, clientId, session.id, schedulingActions);
      }}
      style={styles.labelItem}
    />
  );
}

const onCheckAllDays = (athleteId, isChecked, schedulingActions) => {
  if (isChecked) {
    schedulingActions.allAvailableSingleSessionsAdded(athleteId);
  } else {
    schedulingActions.allAvailableSingleSessionsRemoved(athleteId);
  }
};

function SessionCheckBoxList({
  allDayAvailable,
  allDayChecked,
  athleteId,
  athleteSessions,
  fullPrice,
  sessions,
  singleSessionPrice,
  schedulingActions,
  sessionAvailability,
}) {
  const allDayPurchased =
    allDayChecked && athleteSessions.every(s => s.get('purchased'));

  return (
    <FormControl fullWidth>
      <FormGroup>
        <FormControlLabel
          disableTypography
          disabled={allDayPurchased || (!allDayAvailable && !allDayChecked)}
          checked={allDayChecked}
          label={<AllDaysLabel purchased={allDayPurchased} price={fullPrice} />}
          labelStyle={styles.fullWidth}
          onChange={e =>
            onCheckAllDays(athleteId, e.target.checked, schedulingActions)
          }
          style={styles.labelItem}
          control={<Checkbox />}
        />
      </FormGroup>
      <FormGroup>
        <Divider style={{ height: 2, marginBottom: 10, marginRight: -8 }} />
        {sessionAvailability
          .map((spotsRemaining, sessionId) => {
            const session = sessions.get(sessionId);

            if (!session || session.isCancelled()) {
              return;
            }

            const status = getSessionStatus(
              session,
              spotsRemaining,
              athleteSessions
            );

            // eslint-disable-next-line consistent-return
            return (
              <Session
                clientId={athleteId}
                checked={athleteSessions.some(
                  s => s.get('session_id') === sessionId
                )}
                schedulingActions={schedulingActions}
                session={session}
                status={status}
                price={singleSessionPrice}
                // eslint-disable-next-line react/no-array-index-key
                key={`${athleteId}-${sessionId}`}
              />
            );
          })
          .toList()}
      </FormGroup>
    </FormControl>
  );
}

export default injectIntl(SessionCheckBoxList);
