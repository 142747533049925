import * as React from 'react';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';

import { t } from 'shared/utils/LocaleUtils.js';
import { getFieldStyles } from 'containers/payments/helpers/field';

function ZipCodeField({
  children,
  fieldId,
  errorText,
  intl,
  onChangeZipCode,
  value,
  ...props
}) {
  const { inputProps, textFiledStyle, inputLabelProps } = getFieldStyles(
    children,
    fieldId
  );
  const {
    placeholder = t('.zeros', intl, __filenamespace),
    label = t('.zipcode', intl, __filenamespace),
    maxLength = 5,
  } = props;

  return (
    <TextField
      data-fs-exclude
      fullWidth
      name="zip"
      variant="standard"
      value={value}
      error={!!errorText}
      helperText={errorText}
      label={label}
      placeholder={placeholder}
      sx={textFiledStyle}
      inputProps={{ maxLength }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      onChange={e => onChangeZipCode(e, e.target.value)}
    />
  );
}

export default injectIntl(ZipCodeField);
