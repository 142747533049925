import CapacitorPlugin from 'shared/native/helpers/CapacitorPlugin';
import { SplashScreen as CapacitorSplashScreen } from '@capacitor/splash-screen';

// Wrapper for https://capacitorjs.com/docs/apis/splash-screen
class SplashScreen extends CapacitorPlugin {
  pluginName = 'SplashScreen';

  /**
   * Implements a counter so that multiple calls to show()
   * will need to be matched by an equal number of calls to hide().
   * Since the plugin shows the splash screen immediately on launch
   * and does not automatically hide, set the initial value to 1.
   */
  showCounter = 1;

  /**
   * Show the splash screen.
   * @return {Promise}
   */
  async show() {
    this.showCounter += 1;
    return CapacitorSplashScreen.show({ autoHide: false });
  }

  /**
   * Hide the splash screen.
   * @return {Promise}
   */
  async hide() {
    if (this.showCounter > 0) {
      this.showCounter -= 1;
    }
    if (this.showCounter === 0) {
      return CapacitorSplashScreen.hide();
    }
    return false;
  }
}

export default new SplashScreen();
