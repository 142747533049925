import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import {
  DateTimePicker,
  Dropdown,
  TextField,
  Typography,
} from '@upperhand/playmaker';
import { List, Map, Set } from 'immutable';
import ReactTooltip from 'react-tooltip';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';

import AvailabilityTimePicker from 'shared/components/scheduling/AvailabilityTimePicker.jsx';
import BlackoutDatePicker from 'shared/components/scheduling/BlackoutDatePicker.jsx';
import CancellationDeadline from 'event_mgmt/editing/components/_CancellationDeadline.jsx';
import DateSpecificDaytimePicker from 'shared/components/scheduling/DateSpecificDaytimePicker.jsx';
import MiniCalendar from 'shared/components/calendar/MiniCalendar.jsx';
import SchedulingDeadline from 'event_mgmt/editing/components/_SchedulingDeadline.jsx';
import SchedulingTimeFrame from 'event_mgmt/editing/components/_SchedulingTimeFrame.jsx';
import SchedulingInterval from 'event_mgmt/editing/components/_SchedulingInterval.jsx';
import WeekdayPicker from 'shared/components/scheduling/WeekdayPicker.jsx';
import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { calculateEndDate } from 'event_mgmt/shared/utils/ScheduleUtils.jsx';
import {
  convertDateToClientValue,
  convertToDate,
  customerTZ,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { compose } from 'shared/utils/SharedUtils';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';

import './TabSchedule.scss';

const styles = {
  datePicker: {
    color: uhColors.leftNavGrey,
    textAlign: 'right',
  },

  datePickerHint: {
    color: uhColors.hint,
    opacity: 1,
  },

  sectionLabel: {
    fontWeight: 600,
    lineHeight: '52px',
  },

  intervalContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  intervalNumber: {
    width: 50,
    marginLeft: 8,
    marginRight: 8,
  },

  repeatEndPicker: {
    marginTop: 8,
  },

  repeatDurationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },

  repeatDurationNumber: {
    width: 50,
    marginLeft: 8,
    marginRight: 8,
  },

  durationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  durationValues: {
    display: 'flex',
    alignItems: 'center',
  },

  durationNumber: {
    width: '5em',
    marginLeft: 4,
    marginRight: 4,
  },

  durationUnit: {
    width: '5em',
  },

  flexBox: {
    display: 'flex',
  },

  flexBoxJustify: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  formWrapper: {
    marginRight: '5rem',
    maxWidth: SINGLE_COLUMN_WIDTH,
    width: '100%',
  },

  formElementWrapper: {
    marginBottom: '1em',
  },

  formElementLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0 5px',
  },

  numberField: {
    marginLeft: '1rem',
    marginRight: '1rem',
    maxWidth: '5rem',
  },

  numberFieldInput: {
    color: uhColors.leftNavGrey,
    textAlign: 'center',
  },

  inlineFormTextSpan: {
    color: grey[600],
    fontSize: '1.6rem',
    fontWeight: 400,
  },

  repeatModeSpan: {
    alignSelf: 'center',
    fontSize: '1.6rem',
    marginRight: '1rem',
  },

  weekdayPickerWrapper: {
    marginBottom: '1em',
    display: 'block',
  },

  gridList: {
    width: '100%',
    minHeight: 500,
    overflowY: 'auto',
    marginBottom: 24,
  },
};

class TabSchedule extends React.PureComponent {
  setNewEndDate = (options = {}) => {
    const { handleScheduleChange } = this.props;
    const startDate =
      options.startDate || this.availabilitySchedule().start_date;
    const interval = options.interval || this.availabilitySchedule().interval;
    const repeatDuration =
      options.repeatDuration || this.availabilitySchedule().repeat_duration;
    const weekdays =
      options.weekdays || this.availabilitySchedule().repeatingWeekdays();
    const repeatMode =
      options.repeatMode || this.availabilitySchedule().repeat_mode;
    const stopsBy =
      repeatMode === 'until' &&
      (options.stopsBy || this.availabilitySchedule().stops_by_date);
    const newEndDate = calculateEndDate(
      startDate,
      interval,
      repeatDuration,
      weekdays,
      stopsBy
    );
    handleScheduleChange(['availability_schedule', 'end_date'], newEndDate);
    this.removeBlackoutDaysAfterEndDate(newEndDate);
    this.limitDateSpecificDaytimes('before', newEndDate);
  };

  setRepeatingWeekdays = weekdays => {
    const { daytimes } = this.availabilitySchedule();
    const defaultTimes =
      daytimes.first() || List([Map({ start_time: '', end_time: '' })]);
    const newDaytimes = (weekdays.count() > 0 ? weekdays : Set(['none']))
      .toMap()
      .map(weekday => daytimes.get(weekday, defaultTimes));

    this.handleAvailabilityScheduleChange(['daytimes'], newDaytimes);

    const { frequency } = this.availabilitySchedule();
    if (frequency !== null) this.setNewEndDate({ weekdays });
  };

  dayTooltip = date => {
    const { intl } = this.props;
    return this.availabilitySchedule().isDateSpecific(date)
      ? t('.cannot_blackout_dsd', intl, __filenamespace)
      : null;
  };

  handleAvailabilityScheduleChange = (keyPath, value) => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['availability_schedule'].concat(keyPath), value);
  };

  handleIntervalChange = (_, value) => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['availability_schedule', 'interval'], value);
    this.setNewEndDate({ interval: value });
  };

  handleIndefiniteChange = value => {
    const { handleScheduleChange } = this.props;
    const { start_date: startDate } = this.availabilitySchedule();
    if (startDate) {
      const endOfMonth = moment(startDate).endOf('month').toDate();
      const endDate = value ? '' : endOfMonth;
      handleScheduleChange(['availability_schedule', 'end_date'], endDate);
    } else if (value) {
      handleScheduleChange(['availability_schedule', 'end_date'], '');
    }
    handleScheduleChange(['availability_schedule', 'indefinite'], value);
  };

  // TODO this should be done to the Calender component below
  // but MUI won't update the hover state of disabled days
  // so clicking the day disables it then its stuck with the hover
  // https://github.com/callemall/material-ui/issues/6594
  shouldDisableBlackoutDate = date =>
    this.availabilitySchedule().isDateSpecific(date) ||
    this.availabilitySchedule().isDateExcluded(date);

  handleRepeatWeeklyToggle = (_, value) => {
    const { handleScheduleChange } = this.props;
    if (value) {
      handleScheduleChange(['availability_schedule', 'frequency'], 'weekly');
      const { repeat_mode: repeatMode } = this.availabilitySchedule();
      if (repeatMode === 'occurrences') this.setNewEndDate();
      this.setRepeatingWeekdays(this.scheduledWeekdaysInCalendarRange());
    } else {
      handleScheduleChange(['availability_schedule', 'frequency'], null);
      this.setRepeatingWeekdays(this.weekdaysInCalendarRange());
    }
  };

  handleRepeatModeChange = repeatMode => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['availability_schedule', 'repeat_mode'], repeatMode);
    this.setNewEndDate({ repeatMode });
  };

  handleRepeatDurationChange = (_, value) => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['availability_schedule', 'repeat_duration'], value);
    this.setNewEndDate({ repeatDuration: value });
  };

  handleStopsByDateChange = value => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['availability_schedule', 'stops_by_date'], value);
    this.setNewEndDate({ stopsBy: value });
  };

  handleAddBlackoutDay = () => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(
      ['availability_schedule', 'exclusions'],
      this.availabilitySchedule().exclusions.push(new Date())
    );
  };

  handleBlackoutDayToggle = (_event, date) => {
    const { handleScheduleChange } = this.props;
    const { exclusions } = this.availabilitySchedule();
    if (exclusions.contains(date)) {
      handleScheduleChange(
        ['availability_schedule', 'exclusions'],
        exclusions.remove(date).sort()
      );
    } else {
      handleScheduleChange(
        ['availability_schedule', 'exclusions'],
        this.availabilitySchedule().exclusions.push(date).sort()
      );
    }
  };

  handleDeleteExclusion = index => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(
      ['availability_schedule', 'exclusions'],
      this.availabilitySchedule().exclusions.delete(index)
    );
  };

  handleExclusionDateChange = (value, index) => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['availability_schedule', 'exclusions', index], value);
  };

  handleDurationUnitsChange = value => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['duration_units'], value);
  };

  handleDurationChange = value => {
    const { handleScheduleChange } = this.props;
    handleScheduleChange(['duration'], value * this.durationUnitScaler());
  };

  repeatEndSelector = () => {
    const { repeat_mode: repeatMode } = this.availabilitySchedule();
    const { intl, classes } = this.props;

    if (repeatMode === 'until') {
      return (
        <DateTimePicker
          classes={{
            root: classes.repeatEndPicker,
          }}
          autoOk
          errorText={this.errorText('end_date')}
          fullWidth
          type="date"
          onChange={this.handleStopsByDateChange}
          name="stop_by_date"
          placeholder={t('.date', intl, __filenamespace)}
          value={this.availabilitySchedule().stops_by_date}
          showClearIcon={false}
        />
      );
    }
    const { repeat_duration: repeatDuration } = this.availabilitySchedule();

    return (
      <div style={styles.repeatDurationContainer}>
        <span style={styles.inlineFormTextSpan}>
          <FormattedMessage id={messageId('.after', __filenamespace)} />
        </span>
        <TextField
          classes={{
            root: classes.repeatDurationNumber,
          }}
          min="1"
          name="repeat_duration"
          type="number"
          value={repeatDuration}
          onChange={this.handleRepeatDurationChange}
        />
        <span style={styles.inlineFormTextSpan}>
          {`time${repeatDuration > 1 ? 's' : ''}.`}
        </span>
        <Divider />
      </div>
    );
  };

  durationValue = () => {
    const { schedules } = this.props;
    return schedules.getIn([0, 'duration']) / this.durationUnitScaler();
  };

  shouldSelectBlackoutDate = date =>
    this.availabilitySchedule().isDateExcluded(date)
      ? false
      : this.availabilitySchedule().includeDate(date, this.isOpenBooking());

  limitDateSpecificDaytimes = (direction, limitingDate) => {
    const { handleScheduleChange } = this.props;
    const { date_specific_daytimes: dateSpecificDaytimes } =
      this.availabilitySchedule();
    handleScheduleChange(
      ['availability_schedule', 'date_specific_daytimes'],
      dateSpecificDaytimes
        .filter((_times, date) => {
          if (direction === 'before') {
            return convertDateToClientValue(date) < limitingDate;
          }
          if (direction === 'after') {
            return convertDateToClientValue(date) > limitingDate;
          }
          return false;
        })
        .sort()
    );
  };

  availabilitySchedule = () => {
    const { schedules } = this.props;
    return schedules.getIn([0, 'availability_schedule']);
  };

  errorText = field => {
    const { fieldErrors } = this.props;
    return fieldErrors.get(field) ? fieldErrors.get(field).join(',') : null;
  };

  weekdaysInCalendarRange = () =>
    this.availabilitySchedule()
      .daysInScheduleRange()
      .toSet()
      .map(day => new Date(day).getDay())
      .map(d => String(d));

  scheduledWeekdaysInCalendarRange = () => {
    const weekdaysInCalendarRange = this.weekdaysInCalendarRange();
    const repeatingWeekdays = this.availabilitySchedule().repeatingWeekdays();
    const overlap = weekdaysInCalendarRange.intersect(repeatingWeekdays);

    if (overlap.size === 0) {
      return weekdaysInCalendarRange;
    }

    return overlap;
  };

  durationUnitScaler = () => {
    const { schedules } = this.props;
    const units = schedules.getIn([0, 'duration_units']);
    return units === 'minutes' ? 60 : 3600;
  };

  indefiniteElement = () => {
    if (!this.isOpenBooking()) return '';
    const { intl } = this.props;
    return (
      <div style={styles.formElementWrapper}>
        <FormControlLabel
          control={<Checkbox />}
          label={t('.indefinite', intl, __filenamespace)}
          name="indefinite"
          style={{ marginTop: '1rem' }}
          onChange={e => this.handleIndefiniteChange(e.target.checked)}
          checked={this.availabilitySchedule().indefinite}
        />
      </div>
    );
  };

  dateRangeEndDateError = () => {
    if (!this.availabilitySchedule().frequency) {
      return this.errorText('end_date');
    }
    return null;
  };

  dateRangeElement = (display, title) => {
    if (!display) return '';
    const { intl, classes } = this.props;
    const startDate = this.availabilitySchedule().start_date;
    let endDate = this.availabilitySchedule().end_date;

    if (endDate && endDate - startDate < 0) {
      endDate = startDate;
      EventActions.endDateUpdated(endDate);
    }

    return (
      <div key="date-range-element" style={styles.formElementWrapper}>
        <div style={styles.sectionLabel}>{title}</div>
        <DateTimePicker
          classes={{
            root: classes.formElementWrapper,
          }}
          autoOk
          errorText={this.errorText('start_date')}
          fullWidth
          type="date"
          label={t('.start_date', intl, __filenamespace)}
          onChange={value =>
            EventActions.startDateUpdated(value.tz(customerTZ(), true))
          }
          name="start_date"
          placeholder="MM/DD/YYYY"
          value={startDate}
          showClearIcon={false}
        />

        {(this.isFixedSchedule() ||
          !this.availabilitySchedule().indefinite) && (
          <DateTimePicker
            autoOk
            disabled={!!this.availabilitySchedule().frequency}
            errorText={this.dateRangeEndDateError()}
            fullWidth
            type="date"
            label={t('.end_date', intl, __filenamespace)}
            onChange={value =>
              EventActions.endDateUpdated(value.tz(customerTZ(), true))
            }
            name="end_date"
            placeholder="MM/DD/YYYY"
            showClearIcon={false}
            minDate={startDate || undefined}
            value={endDate}
          />
        )}
        {this.indefiniteElement()}
      </div>
    );
  };

  isOpenBooking = () => {
    const { customerEvent } = this.props;
    return customerEvent.isOpenBooking();
  };

  isFixedSchedule = () => {
    const { customerEvent } = this.props;
    return customerEvent.isFixedSchedule();
  };

  isClassSchedule = () => {
    const { customerEvent } = this.props;
    return customerEvent.isClassSchedule();
  };

  durationElement = () => {
    if (!this.isOpenBooking()) return '';
    const { schedules, intl, classes } = this.props;

    return (
      <div style={{ ...styles.formElementWrapper, ...styles.flexBoxJustify }}>
        <span style={styles.sectionLabel}>
          <FormattedMessage
            id={messageId('.session_duration', __filenamespace)}
          />
        </span>
        <div style={styles.durationContainer}>
          <div style={styles.inlineFormTextSpan}>
            <FormattedMessage id={messageId('.for', __filenamespace)} />
          </div>

          <div style={styles.durationValues}>
            <TextField
              classes={{
                root: classes.durationNumber,
              }}
              min="1"
              name="duration"
              type="number"
              value={this.durationValue()}
              onChange={(_, value) => this.handleDurationChange(value)}
            />
            <Dropdown
              classes={{
                select: classes.durationUnit,
              }}
              value={schedules.getIn([0, 'duration_units'])}
              onChange={e => this.handleDurationUnitsChange(e.target.value)}
              items={[
                {
                  value: 'minutes',
                  label: t('.min', intl, __filenamespace),
                },
                {
                  value: 'hours',
                  label: t('.hour', intl, __filenamespace),
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  };

  repeatCheckboxElement = display => {
    if (!display) return '';
    const { intl } = this.props;

    return (
      <div key="repeat-checkbox-element" style={styles.formElementWrapper}>
        <FormControlLabel
          control={<Checkbox />}
          label={t('.repeat_weekly', intl, __filenamespace)}
          labelPosition="right"
          name="frequency"
          checked={!!this.availabilitySchedule().frequency}
          onChange={e => this.handleRepeatWeeklyToggle(e, e.target.checked)}
        />
      </div>
    );
  };

  removeBlackoutDaysAfterEndDate = endDate => {
    const { handleScheduleChange } = this.props;
    const { exclusions: blackoutDates } = this.availabilitySchedule();
    handleScheduleChange(
      ['availability_schedule', 'exclusions'],
      blackoutDates.filter(date => date < endDate).sort()
    );
  };

  weekdayPickerElement = () => {
    const { frequency } = this.availabilitySchedule();
    if (!!frequency || this.isOpenBooking()) {
      return (
        <div key="weekday-picker-element" style={styles.formElementWrapper}>
          <div style={styles.sectionLabel}>
            {this.isOpenBooking() && (
              <FormattedMessage
                id={messageId('.weekly_availability', __filenamespace)}
              />
            )}
          </div>
          <WeekdayPicker
            onChangeWeekdays={this.setRepeatingWeekdays}
            selectedDays={this.availabilitySchedule().repeatingWeekdays()}
          />
        </div>
      );
    }
    return '';
  };

  commonElements = () => {
    if (this.isOpenBooking()) {
      return [
        this.dateRangeElement(
          true,
          <FormattedMessage
            id={messageId('.dates_of_availability', __filenamespace)}
          />
        ),
        this.weekdayPickerElement(),
      ];
    }
    return [
      this.dateRangeElement(true),
      this.repeatCheckboxElement(true),
      this.weekdayPickerElement(),
    ];
  };

  render() {
    const {
      fieldErrors,
      handleScheduleChange,
      customerEvent,
      intl,
      schedules,
      classes,
    } = this.props;

    return (
      <section>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div
            style={{
              ...styles.formWrapper,
              ...{ flex: `1 0 ${SINGLE_COLUMN_WIDTH}` },
            }}
          >
            {!this.isClassSchedule() && (
              <>
                {this.commonElements()}
                <div
                  style={{
                    ...styles.weekdayPickerWrapper,
                    display: schedules.getIn([
                      0,
                      'availability_schedule',
                      'frequency',
                    ])
                      ? 'block'
                      : 'none',
                  }}
                >
                  <div style={styles.flexBoxJustify}>
                    <div style={styles.intervalContainer}>
                      <span style={styles.inlineFormTextSpan}>
                        <FormattedMessage
                          id={messageId('.every', __filenamespace)}
                        />
                      </span>
                      <TextField
                        classes={{
                          root: classes.intervalNumber,
                        }}
                        min="1"
                        name="interval"
                        type="number"
                        value={this.availabilitySchedule().interval}
                        onChange={this.handleIntervalChange}
                      />
                      <span style={styles.inlineFormTextSpan}>
                        {`week${
                          this.availabilitySchedule().interval > 1 ? 's' : ''
                        }`}
                      </span>
                    </div>
                    <div style={styles.intervalContainer}>
                      <div
                        style={{
                          ...styles.repeatModeSpan,
                          ...styles.inlineFormTextSpan,
                        }}
                      >
                        <FormattedMessage
                          id={messageId('.end', __filenamespace)}
                        />
                      </div>
                      <Dropdown
                        classes={{
                          select: classes.repeatModeDropdown,
                        }}
                        fullWidth
                        value={this.availabilitySchedule().repeat_mode}
                        onChange={e =>
                          this.handleRepeatModeChange(e.target.value)
                        }
                        items={[
                          {
                            value: 'until',
                            label: t('.on_a_date', intl, __filenamespace),
                          },
                          {
                            value: 'occurrences',
                            label: t('.num_of_times', intl, __filenamespace),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {this.repeatEndSelector()}
                </div>

                {this.durationElement()}

                <div style={styles.formElementWrapper}>
                  <AvailabilityTimePicker
                    legend="Daily Time"
                    schedule={this.availabilitySchedule()}
                    handleAvailabilityScheduleChange={
                      this.handleAvailabilityScheduleChange
                    }
                    fieldErrors={fieldErrors}
                  />
                </div>
                <Divider style={styles.formElementWrapper} />
                <div style={styles.formElementWrapper}>
                  <DateSpecificDaytimePicker
                    legend={t('.date_specific_legend', intl, __filenamespace)}
                    availabilitySchedule={this.availabilitySchedule()}
                    handleAvailabilityScheduleChange={
                      this.handleAvailabilityScheduleChange
                    }
                    fieldErrors={fieldErrors}
                  />
                </div>
              </>
            )}
            {!this.isFixedSchedule() && (
              <>
                {!this.isClassSchedule() && (
                  <Divider style={styles.formElementWrapper} />
                )}
                <div style={styles.formElementWrapper}>
                  <SchedulingDeadline
                    schedule={schedules.first()}
                    handleScheduleChange={handleScheduleChange}
                    styles={styles}
                    classes={classes}
                  />
                </div>
              </>
            )}
            {this.isOpenBooking() && (
              <>
                <Divider style={styles.formElementWrapper} />
                <div style={styles.formElementWrapper}>
                  <SchedulingInterval
                    schedule={schedules.first()}
                    handleScheduleChange={handleScheduleChange}
                  />
                </div>
              </>
            )}
            {this.isOpenBooking() && (
              <>
                <Divider style={styles.formElementWrapper} />
                <div style={styles.formElementWrapper}>
                  <SchedulingTimeFrame
                    explanationVariant="body1"
                    schedule={schedules.first()}
                    handleScheduleChange={handleScheduleChange}
                  />
                </div>
              </>
            )}
            {!this.isFixedSchedule() && (
              <>
                <Divider style={styles.formElementWrapper} />
                <div style={styles.formElementWrapper}>
                  <CancellationDeadline
                    schedule={schedules.first()}
                    handleScheduleChange={handleScheduleChange}
                    styles={styles}
                    classes={classes}
                  />
                </div>
              </>
            )}
            {this.isFixedSchedule() && (
              <>
                <Divider style={styles.formElementWrapper} />
                <div style={styles.formElementWrapper}>
                  <div style={styles.formElementLabel}>
                    <Typography variant="caption">
                      {t('.registration_deadline', intl, __filenamespace)}
                    </Typography>
                    <InfoIcon data-tip data-for="registration-deadline-info" />
                  </div>
                  <DateTimePicker
                    autoOk
                    fullWidth
                    type="date"
                    onChange={value =>
                      handleScheduleChange(['registration_deadline'], value)
                    }
                    name="registration_deadline"
                    placeholder={t('.date', intl, __filenamespace)}
                    value={schedules.first()?.registration_deadline}
                    disabled={customerEvent.get('enable_dynamic_pricing')}
                    showClearIcon
                  />
                  <ReactTooltip id="registration-deadline-info" effect="solid">
                    {t('.registration_deadline_info', intl, __filenamespace)}
                  </ReactTooltip>
                </div>
              </>
            )}
          </div>
          {!this.isClassSchedule() && (
            <div style={{ flex: '1 0 auto' }}>
              <div style={styles.formElementWrapper}>
                <MiniCalendar
                  dayTooltip={this.dayTooltip}
                  firstDayOfWeek={0}
                  initialDate={convertToDate(
                    this.availabilitySchedule().start_date
                  )}
                  locale="en-US"
                  onSelectDate={this.handleBlackoutDayToggle}
                  shouldDisableDate={date =>
                    this.availabilitySchedule().isDateSpecific(date)
                  }
                  shouldSelectDate={date => this.shouldSelectBlackoutDate(date)}
                />
              </div>

              <div style={styles.formElementWrapper}>
                <BlackoutDatePicker
                  excludedDates={this.availabilitySchedule().exclusions}
                  onAddDate={this.handleAddBlackoutDay}
                  onChangeDate={this.handleExclusionDateChange}
                  onDeleteDate={this.handleDeleteExclusion}
                  shouldDisableDate={this.shouldDisableBlackoutDate}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default compose(withStyles(styles), injectIntl)(TabSchedule);
