import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import moment from 'moment-timezone';

import EventRateListingActions from 'contacts/shared/actions/event_rates/EventRateListingActions';
import InviteActions from 'user_management/shared/actions/InviteActions.jsx';
import MailTo from 'shared/components/MailTo.jsx';
import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';
import Tel from 'shared/components/Tel.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils.js';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import ContactsStaffActions from '../Actions';
import InlineAvailability from './InlineAvailability.jsx';

const styles = {
  managingUserIcon: {
    height: 12,
    width: 12,
  },
  noMenuCard: {
    paddingRight: 36,
  },
};

// TODO: This should not be 3 actions. This should be one action.
const onDetailsClick = staff => {
  StaffDetailsDrawerActions.staffSelected(staff);
  EventRateListingActions.list({
    customerUserId: staff.id,
    isStaffDrawer: true,
  });
  SecondaryDrawerActions.setActive('StaffDetailsDrawer');
};

function StaffCard({ staff, availabilitySchedule, intl }) {
  let menuOptions;
  // Checking permissions and disable menu for current StaffAdmin
  if (hasAdminPermission() && currentUser().user_id !== staff.get('user_id')) {
    if (staff.isInvitePending()) {
      menuOptions = [
        {
          label: t('.archive', intl, __filenamespace),
          onClick: () => InviteActions.delete(staff.id),
        },
        {
          label: t('.remind', intl, __filenamespace),
          onClick: () => InviteActions.send(staff.id),
        },
        {
          label: t('.updateEmail', intl, __filenamespace),
          onClick: () => ContactsStaffActions.updateContact(staff.id),
        },
      ];
    } else if (!staff.get('access_revoked_at')) {
      menuOptions = [
        {
          label: t('.edit', intl, __filenamespace),
          onClick: () => ContactsStaffActions.updateContact(staff.id),
        },
        {
          label: t('.archive', intl, __filenamespace),
          onClick: () => ContactsStaffActions.openRevokeAccessModal(staff),
        },
      ];
    }
  }

  return (
    <Card
      menuOptions={menuOptions}
      contentDirection="row"
      rootStyle={menuOptions ? {} : styles.noMenuCard}
      classes={{ content: 'card-content' }}
    >
      <Grid container alignItems="center" xs={12} spacing={1}>
        <Grid item container xs={12} md={3} alignItems="center">
          <Grid item>
            <UserAvatar user={staff} showTypeBadge />
          </Grid>
          <Grid item>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => onDetailsClick(staff)}
              role="button"
              tabIndex="0"
            >
              <Typography>{staff.name()}</Typography>
            </div>

            {staff.managing_customer_user_name && (
              <div>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <ManagingUserIcon style={styles.managingUserIcon} />
                  </Grid>
                  <Grid item>
                    <Typography>{staff.managing_customer_user_name}</Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={1}>
          {staff.get('access_revoked_at') && (
            <FormattedMessage id={messageId('.removed', __filenamespace)} />
          )}
        </Grid>

        <Grid item xs={12} md={1}>
          <Tel number={staff.phone} />
        </Grid>

        <Grid item xs={12} md={3} className="staff-email">
          <MailTo email={staff.email} />
        </Grid>

        <Grid item xs={12} md={2}>
          <InlineAvailability availabilitySchedule={availabilitySchedule} />
        </Grid>

        <Grid item xs={12} md={2}>
          {staff.isInvitePending() && (
            <FormattedMessage
              id={messageId('.invited_time', __filenamespace)}
              values={{ time: moment(staff.invite_sent_at).fromNow() }}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default injectIntl(StaffCard);
