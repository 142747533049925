import React from 'react';

function MembershipAgreementIndicator({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      fill="none"
      viewBox="0 0 22 23"
      className={className}
      onClick={onClick}
    >
      <rect
        width="20.6"
        height="21.6"
        x="0.7"
        y="0.7"
        stroke="#9B9EAC"
        strokeWidth="1.4"
        rx="4.3"
      />
      <path stroke="#9B9EAC" strokeLinecap="round" d="M3 5h16M3 8h16M3 11h9" />
      <path stroke="#EC4650" strokeWidth="1.5" d="M18 19l-4-4M14 19l4-4" />
    </svg>
  );
}

export default MembershipAgreementIndicator;
