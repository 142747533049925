import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { Dropdown } from '@upperhand/playmaker';

import { FlexBoxJustify, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';
import { compose } from 'shared/utils/SharedUtils';

const styles = {
  dropdown: {
    width: '5em',
  },
};

function SchedulingInterval({ classes, schedule, handleScheduleChange, intl }) {
  return (
    <div>
      <FlexBoxJustify style={{ alignItems: 'center' }}>
        <div style={boldText}>
          <FormattedMessage
            id={messageId('.scheduling_interval', __filenamespace)}
          />
        </div>
        <FlexBoxCenter>
          <span style={{ color: uhColors.leftNavGrey }}>
            <FormattedMessage id={messageId('.every', __filenamespace)} />
          </span>
          &emsp;
          <Dropdown
            classes={{
              select: classes.dropdown,
            }}
            value={schedule.get('start_time_interval')}
            onChange={e =>
              handleScheduleChange(['start_time_interval'], e.target.value)
            }
            items={[
              {
                value: 15 * 60,
                label: t('.15_min', intl, __filenamespace),
              },
              {
                value: 30 * 60,
                label: t('.30_min', intl, __filenamespace),
              },
              {
                value: 60 * 60,
                label: t('.1_hour', intl, __filenamespace),
              },
            ]}
          />
        </FlexBoxCenter>
      </FlexBoxJustify>
      <div
        style={{
          color: uhColors.tabGrey,
          lineHeight: '1.2em',
          fontSize: '15px',
        }}
      >
        <FormattedMessage id={messageId('.explanation', __filenamespace)} />
      </div>
    </div>
  );
}

export default compose(withStyles(styles), injectIntl)(SchedulingInterval);
