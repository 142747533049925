import * as React from 'react';
import { injectIntl } from 'react-intl';

import PropTypes from 'prop-types';

import EmptyState from 'shared/components/EmptyState.jsx';
import NoEventsIcon from 'shared/components/icons/empty_states/Events.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import ContentTable from 'reporting/inventory/components/ContentTable.jsx';
import ContentFilters from 'reporting/inventory/components/ContentFilters.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import InventoryReportStore from 'reporting/inventory/stores/InventoryReportStore.js';
import InventoryAdjustmentModal from 'reporting/inventory/components/InventoryAdjustmentModal.jsx';

class Content extends React.PureComponent {
  get noData() {
    const { intl } = this.props;

    return (
      <EmptyState
        image={<NoEventsIcon />}
        headerText={t('.empty_header', intl, __filenamespace)}
        messageText={t('.empty_message', intl, __filenamespace)}
      />
    );
  }

  render() {
    const {
      intl,
      inventoryReportStore: {
        isLoading,
        inventory,
        filters,
        exportColumns,
        page,
        perPage,
        totalCount,
      },
    } = this.props;
    return (
      <div className="iphone-x-content">
        <ContentFilters filters={filters} exportColumns={exportColumns} />
        <SpinWhileLoading isLoading={isLoading} spinWhile="isLoading">
          {inventory.size > 0 ? (
            <ContentTable
              intl={intl}
              inventory={inventory}
              columns={filters.get('columns')}
              page={page}
              perPage={perPage}
              totalCount={totalCount}
            />
          ) : (
            this.noData
          )}
        </SpinWhileLoading>
        <InventoryAdjustmentModal />
      </div>
    );
  }
}

Content.propTypes = {
  intl: PropTypes.object.isRequired,
  inventoryReportStore: PropTypes.object,
};

Content.defaultProps = {
  inventoryReportStore: {},
};

export default altContainer({
  stores: {
    inventoryReportStore: InventoryReportStore,
  },
})(injectIntl(Content));
