import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Confirmation, Typography } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import EditMembershipCancellationActions from './Actions';
import EditMembershipCancellationStore from './Store';

function RemoveMembershipCancellationModal({
  intl,
  editMembershipCancellationStore,
}) {
  const { isConfirmationModalOpen, isLoading, client, membershipName } =
    editMembershipCancellationStore;

  if (!isConfirmationModalOpen) return null;

  return (
    <Confirmation
      title={t('.title', intl, __filenamespace)}
      open={isConfirmationModalOpen}
      hasCheckbox={false}
      confirmationDisabled={isLoading}
      classes={{
        root: 'remove-cancellation-dialog',
        content: 'remove-cancellation-dialog__content',
      }}
      onCancel={() =>
        EditMembershipCancellationActions.toggleConfirmationModal([])
      }
      onConfirm={() =>
        EditMembershipCancellationActions.updateCancellationDate()
      }
    >
      <Typography>
        {t('.message', intl, __filenamespace, {
          clientName: client.name(),
          membershipName,
        })}
      </Typography>
    </Confirmation>
  );
}

RemoveMembershipCancellationModal.propTypes = {
  intl: PropTypes.object.isRequired,
  editMembershipCancellationStore: PropTypes.shape({
    isConfirmationModalOpen: PropTypes.bool.isRequired,
    client: PropTypes.shape({
      name: PropTypes.func.isRequired,
    }).isRequired,
    membershipName: PropTypes.string,
  }),
};

RemoveMembershipCancellationModal.defaultProps = {
  editMembershipCancellationStore: {
    isConfirmationModalOpen: false,
    client: {
      name: () => '',
    },
    membershipName: '',
  },
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      editMembershipCancellationStore: EditMembershipCancellationStore,
    },
  })
)(RemoveMembershipCancellationModal);
