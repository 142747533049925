import isEqual from 'lodash.isequal';

import { Filters } from 'containers/reports/types';

// Check if FilterDrawer has default values
// eslint-disable-next-line import/prefer-default-export
export const hasFilterDrawerDefaults = filters =>
  (isEqual(filters.get(Filters.SOURCE_TYPES), []) ||
    filters.get(Filters.SOURCE_TYPES) === undefined) &&
  filters.get(Filters.AMOUNT_MIN) === undefined &&
  filters.get(Filters.AMOUNT_MAX) === undefined;
