import path from 'path';
import moment from 'moment-timezone';

export const messageId = (id, filename) => {
  if (id[0] !== '.') {
    return id;
  }
  const reactUiRoot = 'src';

  return (
    filename
      .substr(filename.indexOf(reactUiRoot) + reactUiRoot.length)
      .split(path.sep)
      .filter(e => !(!e || e === 'components'))
      .map(e => e.replace('.jsx', ''))
      .join('.') + id
  );
};

export const translateWithValues = ({ id, intl, filename, values }) =>
  intl.formatMessage({ id: messageId(id, filename) }, values);

export const translate = (id, intl, filename, values) =>
  translateWithValues({ id, intl, filename, values });
export const t = translate;

export const localize = (date, formatId, intl, filename) =>
  moment(date).format(t(formatId, intl, filename));
export const l = localize;
