import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import {
  TextField,
  Button,
  Typography,
  Confirmation,
} from '@upperhand/playmaker';

import NoteItem from 'containers/events/admin/sessionSummaryDrawer/components/notes/NoteItem.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';
import { capitalizeSentence } from 'shared/utils/StringUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import { SessionDataStore } from 'dataStores';

import NotesActions from './actions';
import NotesStore from './store';

import './styles.scss';

function Notes({ intl, sessionId, notesStore, sessionDataStore }) {
  useEffect(() => {
    NotesActions.mounted.defer(sessionId);
  }, [sessionId]);

  const [noteTypeToDelete, setNoteTypeToDelete] = useState(null);
  const { notes, editModes } = notesStore;
  const { sessions } = sessionDataStore;
  const session = sessions.get(sessionId);
  const isStaff = currentUser().isStaff();
  const isCoach = currentUser().isCoach();
  const adminNote = notes.get('admin_note', '');
  const clientNote = notes.get('client_note', '');
  const adminEditMode = editModes.get('admin_note', false);
  const clientEditMode = editModes.get('client_note', false);
  const showAdminBtn = !adminNote && !adminEditMode && (isStaff || isCoach);
  const showClientBtn = !clientNote && !clientEditMode && (isStaff || isCoach);

  return (
    <div className="session-note__container">
      {showAdminBtn && (
        <Button
          fullWidth
          icon="add"
          iconPosition="left"
          size="1x"
          type="tertiary"
          classes={{
            root: clsx('action-btn', 'action-btn__add-admin-note'),
            label: 'action-btn__label',
          }}
          onClick={() => NotesActions.toggleEditMode('admin_note')}
        >
          {t('.add_admin_note', intl, __filenamespace)}
        </Button>
      )}
      {showClientBtn && (
        <Button
          fullWidth
          icon="add"
          iconPosition="left"
          size="1x"
          type="tertiary"
          classes={{
            root: clsx('action-btn', 'action-btn__add-client-note'),
            label: 'action-btn__label',
          }}
          onClick={() => NotesActions.toggleEditMode('client_note')}
        >
          {t('.add_client_note', intl, __filenamespace)}
        </Button>
      )}
      {adminEditMode && (
        <>
          <Typography className="edit-title">
            {t('.admin_session_note', intl, __filenamespace)}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={adminNote}
            classes={{ root: 'session-note__field' }}
            onChange={(_e, value) =>
              NotesActions.handleFieldChange({ field: 'admin_note', value })
            }
          />
        </>
      )}
      {adminNote && !adminEditMode && (
        <NoteItem
          title={t('.admin_session_note', intl, __filenamespace)}
          note={adminNote}
          onEdit={() => NotesActions.toggleEditMode('admin_note')}
          onDelete={() => setNoteTypeToDelete('admin')}
        />
      )}
      {clientEditMode && (
        <>
          <Typography className="edit-title">
            {t('.client_session_note', intl, __filenamespace)}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={clientNote}
            classes={{ root: 'session-note__field' }}
            onChange={(_e, value) =>
              NotesActions.handleFieldChange({ field: 'client_note', value })
            }
          />
        </>
      )}
      {clientNote && !clientEditMode && (
        <NoteItem
          title={
            <Typography className="client-note-title">
              <p>
                <span>{t('.client_session_note', intl, __filenamespace)}</span>
                &nbsp;|&nbsp;
                {moment(session.get('client_note')?.updated_at).format(
                  'MM/DD/YYYY h:mma'
                )}
              </p>
              <p>
                {t('.added_by', intl, __filenamespace, {
                  name: session.get('client_note')?.created_by?.name(),
                })}
              </p>
            </Typography>
          }
          note={clientNote}
          onEdit={() => NotesActions.toggleEditMode('client_note')}
          onDelete={() => setNoteTypeToDelete('client')}
        />
      )}
      <Confirmation
        title={t('.note_delete_confirmation', intl, __filenamespace, {
          type: capitalizeSentence(noteTypeToDelete || ''),
        })}
        confirmButtonLabel={t('.delete', intl, __filenamespace)}
        cancelButtonLabel={t('.cancel', intl, __filenamespace)}
        open={Boolean(noteTypeToDelete)}
        onCancel={() => setNoteTypeToDelete(null)}
        onConfirm={() => {
          NotesActions.deleteSessionNote(noteTypeToDelete);
          setNoteTypeToDelete(null);
        }}
      >
        <Typography variant="body1">
          {t('.note_delete_text', intl, __filenamespace)}
        </Typography>
        {noteTypeToDelete === 'client' && (
          <div className="client-note-info">
            <Typography variant="body1">
              {t('.added_by', intl, __filenamespace, {
                name: session.get('client_note')?.created_by?.name(),
              })}
            </Typography>
            <Typography variant="body1">
              {moment(session.get('client_note')?.updated_at).format(
                'MM/DD/YYYY h:mma'
              )}
            </Typography>
          </div>
        )}
      </Confirmation>
    </div>
  );
}

Notes.propTypes = {
  intl: PropTypes.object,
  sessionId: PropTypes.string,
  notesStore: PropTypes.object,
};

Notes.defaultProps = {
  intl: {},
  sessionId: '',
  notesStore: {},
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      notesStore: NotesStore,
      sessionDataStore: SessionDataStore,
    },
  })
)(Notes);
