import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid var(--color-nav-icon-gray)',
    borderRadius: 2,
    cursor: 'pointer',
    overflow: 'hidden',
    padding: 8,
    minHeight: 56,

    '&:hover': {
      border: '1px solid #1ca0fb',
      borderRadius: 2,
      backgroundColor: 'var(--color-bg-gray)',
    },
  },
  selected: {
    color: 'var(--color-white)',
    backgroundColor: '#454951',
    border: '1px solid #454951',
    borderRadius: 2,

    '&:hover': {
      backgroundColor: '#454951',
      border: '1px solid #454951',
      borderRadius: 2,
    },
  },
  disabled: {
    backgroundColor: 'var(--color-bg-gray)',
    borderWidth: 0,
    cursor: 'default',
    borderRadius: 2,

    '&:hover': {
      borderWidth: 0,
      borderRadius: 2,
    },
  },
};

const SelectableContainer = ({
  classes,
  selected,
  disabled,
  onClick,
  children,
}) => (
  // eslint-disable-next-line
  <div
    className={`${classes.container} ${
      selected && !disabled ? classes.selected : ''
    } ${disabled ? classes.disabled : ''}`}
    onClick={disabled ? () => {} : onClick}
  >
    {children}
  </div>
);

SelectableContainer.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

SelectableContainer.defaultProps = {
  children: null,
  disabled: false,
  onClick: () => {},
  selected: false,
};

export default withStyles(styles)(SelectableContainer);
