class CordovaEndOfLife {
  initialize() {
    document.addEventListener('deviceready', () => {
      const hasCordova = !!window.cordova;
      if (hasCordova) {
        const isCordovaApp = !!window.navigator.splashscreen;
        if (isCordovaApp) {
          this.showEndOfLifeAlert();
        }
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showEndOfLifeAlert() {
    // create a simple full-screen div overlay, letting the rest of the app initialize normally
    const html = `<div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 100000; display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: #000; color: #fff; padding: 2em;">
        <h4 style="font-size: 1.3em; font-weight: bold; color: #fff; margin-bottom: 1em">Update Required</h4>
        <p style="font-size: 1.1em; color: #fff; text-align: center;">This mobile application requires an updated version to continue.  Please install the latest version and try again.</p>
      </div>`;
    const container = document.createElement('div');
    container.innerHTML = html;
    document.body.appendChild(container);
    // hide the old Cordova splashscreen if available
    if (window.navigator.splashscreen) {
      window.navigator.splashscreen.hide();
    }
  }
}

export default new CordovaEndOfLife();
