import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Grid,
  InfiniteScroller,
  TemporalPicker,
  Typography,
  Spinner,
} from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import BrowseEventsBookDrawer from 'containers/browseEventsBookDrawer/BrowseEventsBookDrawer.jsx';

import {
  EventTypeDataStore,
  LocationDataStore,
  StaffDataStore,
} from 'dataStores';

import BrowseEventsActions from './Actions';
import BrowseEventsStore, { VIEW_MODE } from './Store';

import BrowseEventsCompact from './components/BrowseEventsCompact.jsx';
import BrowseEventsRoomy from './components/BrowseEventsRoomy.jsx';
import BrowseEventCard from './components/BrowseEventCard.jsx';

const styles = {
  root: {
    backgroundColor: uhColors.backgroundGray,
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  loading: {
    padding: '16px',
  },
};

const getRecordLabelAndValue = (recordList, showAddress = false) =>
  recordList
    .map(r => {
      // Our record types are inconsistent in how "name" is accessed.
      const recordName = typeof r.name === 'function' ? r.name() : r.name;

      return {
        label: recordName || '',
        value: r.id,
        color: r.color || null,
        address: showAddress ? r.address : null,
      };
    })
    .toArray();

const BrowseEvents = React.memo(props => {
  const {
    browseEventsStore: {
      browseEvents,
      eventTypeFilter,
      eventTypeLoading,
      isFiltersDrawerOpen,
      isMounted,
      isLoading,
      locationFilter,
      locationLoading,
      selectedDate,
      staffFilter,
      staffLoading,
      startDate,
      totalCount,
      viewMode,
      sportTypes,
      sportTypeFilter,
      seasonFilter,
      eventTypeAllSelected,
      locationAllSelected,
      staffAllSelected,
      sportTypeAllSelected,
      seasonAllSelected,
      search,
      ageFilter,
    },
    eventTypeDataStore: { eventTypes },
    intl,
    locationDataStore: { records: locations },
    staffDataStore: { staff },
  } = props;

  React.useEffect(() => {
    if (!isMounted) {
      BrowseEventsActions.mounted();
    }
  }, [isMounted]);

  // TemporalPicker has a minimum width
  // If screen size would be less, than 880, it would break a design
  const BrowseEventsView = smallScreen(880)
    ? BrowseEventsCompact
    : BrowseEventsRoomy;

  // Because of temporal picker UI, don't display the loader when the only thing we're still waiting on is the event list
  const shouldDisplayLoader =
    eventTypeLoading || locationLoading || staffLoading;

  if (shouldDisplayLoader) {
    return (
      <div style={{ ...styles.root, ...styles.loading }}>
        <Spinner />
      </div>
    );
  }

  const eventItems = getRecordLabelAndValue(eventTypes);
  const staffItems = getRecordLabelAndValue(staff).sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const locationItems = getRecordLabelAndValue(locations, true).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const viewOptions = [
    {
      value: VIEW_MODE.list,
      label: t(`.view_${VIEW_MODE.list}`, intl, __filenamespace),
    },
    {
      value: VIEW_MODE.week,
      label: t(`.view_${VIEW_MODE.week}`, intl, __filenamespace),
    },
  ];
  return (
    <div className="browse-events" style={styles.root}>
      <BrowseEventsView
        eventItems={eventItems}
        eventTypeFilter={eventTypeFilter}
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        locationFilter={locationFilter}
        locationItems={locationItems}
        staffItems={staffItems}
        staffFilter={staffFilter}
        viewMode={viewMode}
        viewOptions={viewOptions}
        sportTypeItems={sportTypes}
        sportTypeFilter={sportTypeFilter}
        seasonFilter={seasonFilter}
        eventTypeAllSelected={eventTypeAllSelected}
        locationAllSelected={locationAllSelected}
        staffAllSelected={staffAllSelected}
        sportTypeAllSelected={sportTypeAllSelected}
        seasonAllSelected={seasonAllSelected}
        search={search}
        ageFilter={ageFilter}
      >
        {viewMode === VIEW_MODE.week && (
          <Grid item xs={12}>
            <TemporalPicker
              onDateSelect={BrowseEventsActions.dateSelect}
              onStartChange={BrowseEventsActions.startDateChange}
              startDate={startDate.format('YYYY-MM-DD')}
              selectedDate={selectedDate.format('YYYY-MM-DD')}
              displayCount={7}
            />
          </Grid>
        )}

        {!browseEvents.size && !isLoading ? (
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              {t('.no_results', intl, __filenamespace)}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InfiniteScroller
              hasMore={browseEvents.size < totalCount}
              onScroll={BrowseEventsActions.listEvents.defer}
              isLoading={isLoading}
              showLoader
            >
              {browseEvents
                .map(
                  (browseEvent, eventSessionId) => (
                    // The new should not listen to the smart component store directly.
                    // That's why we're passing browseEvent here
                    /* eslint-disable react/no-array-index-key */
                    // this is not an array index its a maps key and it is a combination of event and session, it will be uniq
                    <BrowseEventCard
                      intl={intl}
                      viewMode={viewMode}
                      key={eventSessionId}
                      browseEvent={browseEvent}
                    />
                  )
                  /* eslint-enable react/no-array-index-key */
                )
                .toList()}
            </InfiniteScroller>
          </Grid>
        )}
      </BrowseEventsView>
      <BrowseEventsBookDrawer />
    </div>
  );
});

BrowseEvents.propTypes = {
  browseEventsStore: PropTypes.object,
  eventTypeDataStore: PropTypes.object,
  intl: PropTypes.object.isRequired,
  locationDataStore: PropTypes.object,
};

BrowseEvents.defaultProps = {
  browseEventsStore: {},
  eventTypeDataStore: {},
  locationDataStore: {},
};
const MemorizedBrowseEvents = React.memo(BrowseEvents);

export default compose(
  injectIntl,
  altContainer({
    stores: {
      browseEventsStore: BrowseEventsStore,
      eventTypeDataStore: EventTypeDataStore,
      locationDataStore: LocationDataStore,
      staffDataStore: StaffDataStore,
    },
  })
)(MemorizedBrowseEvents);
