import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { List } from 'immutable';
import { Grid, Button } from '@upperhand/playmaker';

import Demographics from 'containers/contacts/contactsFilters/steps/filterBlocks/Demographics.jsx';
import Activity from 'containers/contacts/contactsFilters/steps/filterBlocks/Activity.jsx';
import Name from 'containers/contacts/contactsFilters/steps/filterBlocks/Name.jsx';
import SavedFilters from 'containers/contacts/contactsFilters/steps/filterBlocks/SavedFilters.jsx';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import ContactsFiltersDrawerActions from 'containers/contacts/contactsFilters/Actions';

function CreationStep({
  appliedFilters,
  filter,
  products,
  productTypes,
  contactFilters,
  pagination,
}) {
  const intl = useIntl();

  useEffect(() => {
    ContactsFiltersDrawerActions.listProducts();
    ContactsFiltersDrawerActions.listProductTypes();
  }, []);

  return (
    <>
      {appliedFilters.get('id') && (
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <Button
              type="tertiary"
              classes={{ root: 'clear-filters', label: 'clear-filters__label' }}
              onClick={ContactsFiltersDrawerActions.clearFilters}
            >
              {t('.clear_filters', intl, __filenamespace)}
            </Button>
          </Grid>
        </Grid>
      )}
      <SavedFilters
        filter={filter}
        filters={contactFilters}
        onFilterSelect={ContactsFiltersDrawerActions.selectSavedFilter}
      />
      <hr className="filter-divider" />
      <Demographics
        filter={filter}
        onChange={ContactsFiltersDrawerActions.filterValueChange}
      />
      <Activity
        filter={filter}
        products={products}
        productTypes={productTypes}
        pagination={pagination}
        onChange={ContactsFiltersDrawerActions.filterValueChange}
        onProductSearch={ContactsFiltersDrawerActions.searchProducts}
        onProductTypeSearch={ContactsFiltersDrawerActions.searchProductTypes}
      />
      <Name
        filter={filter}
        onChange={ContactsFiltersDrawerActions.filterValueChange}
      />
    </>
  );
}

CreationStep.propTypes = {
  filter: PropTypes.instanceOf(ContactFilter),
  appliedFilters: PropTypes.instanceOf(ContactFilter),
  products: PropTypes.array,
  productTypes: PropTypes.array,
  contactFilters: PropTypes.instanceOf(List),
};

CreationStep.defaultProps = {
  filter: new ContactFilter(),
  appliedFilters: new ContactFilter(),
  products: [],
  productTypes: [],
  contactFilters: List(),
};

export default compose(memo)(CreationStep);
