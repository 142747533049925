import * as React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

import StatisticItem from './StatisticItem.jsx';

function OrderStatistics({ intl, statistics }) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <Grid item xs={3}>
        <StatisticItem
          title={t('.orders', intl, __filenamespace)}
          tooltip={t('.orders_tooltip', intl, __filenamespace)}
          value={statistics.get('count')}
        />
      </Grid>
      <Grid item xs={3}>
        <StatisticItem
          title={t('.amount', intl, __filenamespace)}
          tooltip={t('.amount_tooltip', intl, __filenamespace)}
          value={intl.formatNumber(statistics.get('total') / 100, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </Grid>
      <Grid item xs={3}>
        <StatisticItem
          title={t('.in_store', intl, __filenamespace)}
          tooltip={t('.in_store_tooltip', intl, __filenamespace)}
          value={statistics.get('in_store')}
        />
      </Grid>
      <Grid item xs={3}>
        <StatisticItem
          title={t('.online', intl, __filenamespace)}
          tooltip={t('.online_tooltip', intl, __filenamespace)}
          value={statistics.get('online')}
        />
      </Grid>
    </Grid>
  );
}

OrderStatistics.propTypes = {
  statistics: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(OrderStatistics);
