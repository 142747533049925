const BrowseEventsFilterActions = alt.generateActions(
  'fetch',
  'fetchSuccess',
  'list',
  'listSuccess',
  'listError',
  'error',
  'moreClicked'
);

export default BrowseEventsFilterActions;
