import React from 'react';
import { Box } from '@mui/material';

export default function AlignedBox({ children, sx }) {
  return (
    <Box
      sx={{
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
