export default alt.generateActions(
  'begin',
  'cancel',
  'updated',
  'commissionRateCommissionSelected',
  'commissionRateCommissionRemoved',
  'save',
  'saveSuccess',
  'saveError',
  'toggleItemType'
);
