import * as React from 'react';
import PropTypes from 'prop-types';

import ColoredItem from 'memberships/components/_ColoredItem.jsx';
import EventType from 'event_mgmt/shared/records/EventType.jsx';
import RecordsEventType from 'records/EventType';

function ColoredEventType({ eventType, style, leftStyles, mod, textStyle }) {
  return (
    <ColoredItem
      className="ColoredItem"
      text={eventType.name}
      color={eventType.color}
      style={style}
      leftStyles={leftStyles}
      mod={mod}
      textStyle={textStyle}
    />
  );
}

ColoredEventType.propTypes = {
  eventType: PropTypes.oneOfType([
    PropTypes.instanceOf(EventType),
    PropTypes.instanceOf(RecordsEventType),
  ]),
  style: PropTypes.object,
};

ColoredEventType.defaultProps = {
  eventType: new EventType(),
  style: {},
};

export default ColoredEventType;
