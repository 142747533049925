import * as React from 'react';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import CardReaderDropdown from 'containers/cardReaderDropdown/CardReaderDropdown.jsx';
import PaymentMethodUsageField from 'containers/payments/components/fields/PaymentMethodUsageField.jsx';

const styles = {
  container: {
    marginTop: '1rem',
  },
};

function HandpointCloudWrapper({ cardUsage, hasAccess }) {
  return (
    <div style={styles.container}>
      <CardReaderDropdown fullWidth />

      <FlexBoxJustify style={{ flexWrap: 'wrap' }}>
        <PaymentMethodUsageField cardUsage={cardUsage} hasAccess={hasAccess} />
      </FlexBoxJustify>
    </div>
  );
}

export default HandpointCloudWrapper;
