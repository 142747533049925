/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import AltContainer from 'alt-container';
import clsx from 'clsx';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Set } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Button,
  Grid,
  Tabs,
  Typography,
  Spinner,
  Icon,
} from '@upperhand/playmaker';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import SquareIcon from '@mui/icons-material/Square';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import HiddenInPOSIcon from '@mui/icons-material/VisibilityOff';
import HiddenInCheckoutIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import StarIcon from '@mui/icons-material/Star';

import BalanceDetailsDrawer from 'containers/reports/balanceDetailsDrawer/BalanceDetailsDrawer.jsx';
import BalanceListDrawer from 'containers/reports/balanceListDrawer/BalanceListDrawer.jsx';
import CancellationDialog from 'memberships/components/_CancellationDialog.jsx';
import PaymentDetailsDrawer from 'containers/reports/paymentDetailsDrawer/PaymentDetailsDrawer.jsx';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import AlignedBox from 'shared/components/AlignedBox.jsx';
import ColumnarForm from 'shared/components/ColumnarForm.jsx';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import CreditBenefits from 'memberships/components/_CreditBenefits.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Description from 'memberships/components/_Description.jsx';
import DiscountBenefits from 'memberships/components/_DiscountBenefits/_DiscountBenefits.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import LockIcon from 'shared/components/icons/LockIcon.jsx';
import MembershipActions from 'shared/actions/MembershipActions.jsx';
import MembershipCancellationStore from 'memberships/stores/MembershipCancellationStore.jsx';
import MembershipSubscriptionActions from 'memberships/actions/MembershipSubscriptionActions.jsx';
import MembershipSubscriptionStore from 'memberships/stores/MembershipSubscriptionStore.jsx';
import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';
import MembershipViewingStore from 'memberships/stores/MembershipViewingStore.jsx';
import MembershipViewingUIStore from 'memberships/stores/MembershipViewingUIStore.jsx';
import { MembershipTierDataStore } from 'dataStores';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import SuspensionDialog from 'memberships/components/_SuspensionDialog.jsx';
import ReactivationDialog from 'memberships/components/_ReactivationDialog.jsx';
import MembershipViewingTiers from 'memberships/components/MembershipTiers/MembershipViewingTiers.jsx';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxJustifyCenter,
} from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, smallScreen } from 'shared/utils/DOMUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { shortHumanUrl } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import EditMembershipExpireModal from 'containers/clientProfile/components/EditMembershipExpireModal/index.jsx';
import { equalWidth } from './ModalComponentMemberhips.jsx';
import MembershipViewFilterDrawer from './MembershipViewFilterDrawer.jsx';
import '../styles.scss';
import MembersList from './MembershipMembers/MembersList.jsx';

const creditBenefitsStyle = {
  lineHeight: 1.2,
  fontSize: 15,
};
const customWrapper = {
  lineHeight: 1.2,
  padding: '10px 42px 10px 42px',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
};
const styles = {
  benefitsItem: {
    minWidth: 140,
  },
  clientsContent: {
    color: uhColors.leftNavGrey,
    fontSize: 22,
    lineHeight: '26px',
  },
  descStyles: {
    padding: '10px 42px 10px 42px',
    textAlign: 'left',
    fontSize: 15,
    lineHeight: 1.2,
  },
  clientsHeader: {
    color: uhColors.iconGrey,
    fontSize: 12,
    height: 15,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 550,
    marginBottom: '.5rem',
  },
  clientsWrapper: {
    flex: '1 0 auto',
    margin: '0px 10px',
    marginBottom: 5,
    paddingBottom: '.5rem',
  },
  colorSquare: {
    width: 20,
    height: 20,
    margin: '2px 0',
    flex: '0 0 auto',
  },
  columnarForm: {
    root: {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
    column: {
      margin: `${LEFT_MARGIN_PIXELS}px 19px 0px 19px`,
    },
    column0: {
      flex: '1 1 30%',
    },
    column1: {
      flex: '1 1 60%',
    },
    contentRow: {
      marginLeft: '-19px',
      marginRight: '-19px',
    },
  },
  description: {
    lineHeight: 1.2,
  },
  eventExclusivities: {
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
  },
  eventExclusivityContainer: {
    marginLeft: -5,
  },
  eventTypeExclusivities: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  eventTypeExclusivityContainer: {
    padding: 1,
    marginLeft: -4,
  },
  header: {
    color: uhColors.leftNavGrey,
    display: 'inline',
    fontSize: 22,
    fontFamily: 'benton-sans-condensed, sans-serif',
    lineHeight: '25px',
    fontWeight: 'bold',
  },
  lockIcon: {
    marginRight: 13,
    marginLeft: 2,
  },
  lockIconContainer: {
    padding: '10px 10px 0px 0px',
  },
  membershipClientCard: {
    fontSize: 14,
  },
  openInNewIcon: {
    width: 15,
    height: 15,
    color: uhColors.activeBlue,
  },
  pricing: {
    fontSize: 16,
    padding: '10px 10px 10px 0px',
  },
  restrictionName: {
    marginLeft: '1rem',
  },
  revenueAndClientsWrapper: {
    flexWrap: 'wrap',
    margin: '10px -10px -10px',
  },
  revenueContent: {
    color: uhColors.leftNavGrey,
    fontSize: 22,
    lineHeight: '26px',
  },
  revenueHeader: {
    color: uhColors.iconGrey,
    fontSize: 12,
    height: 15,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 550,
    marginBottom: '.5rem',
  },
  revenueWrapper: {
    flex: '1 0 auto',
    margin: '0px 10px',
    marginBottom: 5,
    paddingBottom: '.5rem',
  },
  sectionSpacing: {
    margin: '1rem 0px',
    backgroundColor: '#fff',
    minHeight: '150px',
    padding: '1rem',
  },
  sectionSpaceLine: {
    margin: '2px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  separationAtTop: {
    marginTop: 10,
  },
  spacerLine: {
    width: '100%',
  },
  url: {
    color: uhColors.iconGrey,
    display: 'inline',
    fontSize: 15,
    lineHeight: '18px',
    marginLeft: 11,
    position: 'relative',
    top: -3,
  },
  hiddenIcon: {
    height: 16,
    width: 16,
    marginRight: '10px',
  },
  wrapper: membershipColor => ({
    padding: '24px 42px 12px 18px',
    backgroundColor: '#E5E5E5',
    textAlign: 'left',
    borderLeft: `24px solid ${membershipColor}`,
  }),
  itemStyle: {
    padding: 0,
  },
  textStyle: {
    marginLeft: '1rem',
  },
  memViewHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  editLink: {
    position: 'absolute',
    top: 7,
    right: 35,
  },
  editBtn: {
    padding: '5px',
  },
  editIcon: {
    width: '17px',
    height: '17px',
    color: uhColors.activeBlue,
  },
  deleteIcon: {
    height: '20px',
    width: '20',
    position: 'relative',
    left: '-2px',
    color: uhColors.darkRed,
  },
};

const isMobile = smallScreen();
const editUrl = m => customerScopedRoute(`/memberships/${m.id}/edit`);

function Header({
  membership,
  showIcon = false,
  style = {},
  editLinkStyle = {},
}) {
  return (
    <div
      className="mem-view-header"
      style={{ ...styles.memViewHeader, ...style }}
    >
      {showIcon && <CircleIcon sx={{ color: membership.color }} />}
      <div className="mem-view-name" style={styles.header}>
        {membership.name}
      </div>
      <ReactRouterLink
        to={editUrl(membership)}
        style={{ ...styles.editLink, ...editLinkStyle }}
      >
        <IconButton sx={styles.editBtn}>
          <EditIcon sx={styles.editIcon} />
        </IconButton>
      </ReactRouterLink>
    </div>
  );
}

function PricingAndCommitment({ membership }) {
  return (
    <div className="mem-view-pricing-commitment" style={styles.separationAtTop}>
      <div className="mem-view-price" style={styles.pricing}>
        <span>
          {!!membership.price && !membership.tiered && (
            <FormattedCurrency value={membership.price} fromCents />
          )}
          {!membership.price && !membership.tiered && (
            <FormattedMessage id={messageId('.free', __filenamespace)} />
          )}
          {!!membership.commitment_months && (
            <span>
              &ensp; | &ensp;
              <FormattedMessage
                id={messageId('.commitment_months', __filenamespace)}
                values={{ months: membership.commitment_months }}
              />
            </span>
          )}
        </span>
      </div>
    </div>
  );
}

function Exclusivity({ style, color, name }) {
  return (
    <FlexBoxCenter style={style}>
      <SquareIcon style={{ ...styles.colorSquare, ...{ color } }} />
      <span style={styles.restrictionName}>{name}</span>
    </FlexBoxCenter>
  );
}

export function Exclusivities({
  membership,
  eventTypes,
  styleCustom,
  itemStyle,
}) {
  const eventTypeExclusivities = membership.membership_event_permissions.filter(
    mep => !!mep.event_type_id
  );

  const eventExclusivities = membership.membership_event_permissions.filter(
    mep => !!mep.event
  );

  return (
    <div className="mem-view-exclusivities" style={styleCustom}>
      {!!eventTypeExclusivities.size && (
        <div
          className="mem-view-event-type-exclusivities"
          style={styles.eventTypeExclusivities}
        >
          <FormattedMessage
            id={messageId('.event_type_exclusivities', __filenamespace)}
          />
        </div>
      )}
      {!!eventTypeExclusivities.size &&
        eventTypeExclusivities.map(mep => {
          const eventType = eventTypes.find(et => et.id === mep.event_type_id);

          return (
            <Exclusivity
              key={`restr-event-type${eventType.id}`}
              style={{ ...styles.eventTypeExclusivityContainer, ...itemStyle }}
              color={eventType.color}
              name={eventType.name}
            />
          );
        })}

      {!!eventExclusivities.size && (
        <div
          className="mem-view-event-exclusivities"
          style={styles.eventExclusivities}
        >
          <FormattedMessage
            id={messageId('.event_exclusivities', __filenamespace)}
          />
        </div>
      )}
      {!!eventExclusivities.size &&
        eventExclusivities.map(mep => (
          <Exclusivity
            key={`restr-event-${mep.event.id}`}
            style={styles.eventExclusivityContainer}
            color={mep.event.color}
            name={mep.event.title}
          />
        ))}
    </div>
  );
}

function InviteOnly() {
  return (
    <div style={styles.lockIconContainer}>
      <LockIcon style={styles.lockIcon} />
      <FormattedMessage id={messageId('.invite_only', __filenamespace)} />
    </div>
  );
}

function DeleteMembership() {
  return (
    <FlexBoxCenter
      style={{ marginTop: 10, cursor: 'pointer' }}
      onClick={() => MembershipViewingActions.removeRequested()}
    >
      <DeleteIcon sx={styles.deleteIcon} />
      <span style={{ marginLeft: 6, fontWeight: 'bold' }}>
        <FormattedMessage
          id={messageId('.remove_membership', __filenamespace)}
        />
      </span>
    </FlexBoxCenter>
  );
}

function RevenueAndClients({ membershipStore }) {
  return (
    <FlexBox
      className="mem-view-revenue-clients"
      style={styles.revenueAndClientsWrapper}
    >
      <div className="mem-view-revenue" style={styles.revenueWrapper}>
        <div style={styles.revenueHeader}>
          {membershipStore.record.isLifetime() ? (
            <FormattedMessage
              id={messageId('.total_revenue', __filenamespace)}
            />
          ) : (
            <FormattedMessage
              id={messageId('.revenue_per_month', __filenamespace)}
            />
          )}
        </div>
        <div style={styles.revenueContent}>
          {!!membershipStore.record.revenue && (
            <FormattedCurrency
              value={
                membershipStore.record.isLifetime()
                  ? 0
                  : membershipStore.record.revenue
              }
              fromCents
            />
          )}
          {!membershipStore.record.revenue && (
            <FormattedMessage id={messageId('.no_revenue', __filenamespace)} />
          )}
        </div>
      </div>
      <div className="mem-view-clients" style={styles.clientsWrapper}>
        <div style={styles.clientsHeader}>
          <FormattedMessage id={messageId('.clients', __filenamespace)} />
        </div>
        <div style={styles.clientsContent}>
          {membershipStore.record.active_client_count}
        </div>
      </div>
    </FlexBox>
  );
}

function SpacerLine() {
  return (
    <div style={styles.sectionSpaceLine}>
      <hr style={styles.spacerLine} />
    </div>
  );
}

const Url = injectIntl(({ membership, intl }) => (
  <CopyToClipboard
    onCopy={() =>
      MessageWindowActions.addMessage(t('.url_copied', intl, __filenamespace))
    }
    style={merge(styles.separationAtTop, { cursor: 'pointer' })}
    text={membership.url()}
  >
    <div className="mem-view-signup-url">
      <OpenInNewIcon style={styles.openInNewIcon} />
      <div style={styles.url}>{shortHumanUrl(membership.url(), 48)}</div>
    </div>
  </CopyToClipboard>
));

const MembershipClients = injectIntl(
  ({
    membershipCancellationStore,
    membershipStore,
    membershipTierDataStore,
    subscriptionsStore,
    intl,
  }) => (
    <div className="mem-view-clients" style={styles.membershipClientCard}>
      <MembersList
        membershipCancellationStore={membershipCancellationStore}
        membershipStore={membershipStore}
        membershipTierDataStore={membershipTierDataStore}
        subscriptionsStore={subscriptionsStore}
        intl={intl}
      />
    </div>
  )
);

export function ModalContent({ children, withBorder }) {
  return (
    <div
      className={clsx(
        'mem-view__content',
        withBorder && 'mem-view__content-border'
      )}
    >
      <div className="mem-view__content-container">
        <div className="mem-view__content-wrapper">{children}</div>
      </div>
    </div>
  );
}

export function MembershipViewingBenefits({
  membership,
  eventTypes,
  creditPasses,
  retailCategories,
  leftStyles,
  containerStyle,
  benefitsWrapperStyle,
  discountsItemStyle,
  creditItemStyle,
  exclusivitiesItemStyle,
}) {
  const creditStyle = leftStyles
    ? styles.benefitsItem
    : { ...styles.benefitsItem, padding: '10px 42px 10px 42px' };
  const discountStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: !leftStyles ? 15 : 0,
  };
  const wrapper = !leftStyles ? customWrapper : {};
  const exclusivitiesStyles = !leftStyles
    ? { padding: '10px 42px 10px 42px' }
    : {};

  return (
    <div style={containerStyle}>
      <CreditBenefits
        leftStyles={leftStyles}
        membership={membership}
        creditBenefitsStyle={creditBenefitsStyle}
        styles={{ ...creditStyle, ...benefitsWrapperStyle }}
        itemStyle={{ ...styles.itemStyle, ...creditItemStyle }}
        textStyle={styles.textStyle}
      />
      <DiscountBenefits
        membership={membership}
        eventTypes={eventTypes}
        creditPasses={creditPasses}
        retailCategories={retailCategories}
        customWrapper={{ ...wrapper, ...benefitsWrapperStyle }}
        containerStyles={discountStyles}
        itemStyle={{ ...styles.itemStyle, ...discountsItemStyle }}
        textStyle={styles.textStyle}
      />
      <Exclusivities
        membership={membership}
        eventTypes={eventTypes}
        styleCustom={{ ...exclusivitiesStyles, ...benefitsWrapperStyle }}
        itemStyle={exclusivitiesItemStyle}
      />
    </div>
  );
}

export function MembershipViewingTiersBenefits({
  membershipTiers,
  eventTypes,
  creditPasses,
  retailCategories,
  membershipTiersLoading,
  containerStyles = {},
  wrapperStyles = {},
  expanderBtnStyles = {},
  benefitsListStyles = {},
  benefitsWrapperStyle = {},
  creditItemStyle = {},
  discountsItemStyle = {},
  exclusivitiesItemStyle = {},
}) {
  const [expandedIds, setExpandedId] = useState(Set());

  const handleExpand = id => {
    if (expandedIds.has(id)) {
      setExpandedId(expandedIds.delete(id));
    } else {
      setExpandedId(expandedIds.add(id));
    }
  };

  if (membershipTiersLoading)
    return (
      <FlexBoxJustifyCenter>
        <Spinner />
      </FlexBoxJustifyCenter>
    );

  return (
    <>
      {membershipTiers.map(tier => (
        <div key={tier.id} style={{ marginBottom: 15, ...containerStyles }}>
          <div
            style={{
              ...customWrapper,
              lineHeight: 'initial',
              ...wrapperStyles,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="h5">{tier.name}</Typography>
              {tier.best_value && (
                <StarIcon sx={{ color: 'var(--color-warning-yellow)' }} />
              )}
            </Box>
            <ul style={{ listStyleType: 'initial', marginLeft: 30 }}>
              <li>
                <FormattedCurrency value={tier.price} fromCents />
                {tier.intervalMonths && <span>/{tier.intervalMonths}</span>}
                {Boolean(tier.join_fee) && (
                  <FormattedMessage
                    id={messageId('.join_fee', __filenamespace)}
                    values={{
                      fee: (
                        <span>
                          &nbsp;+&nbsp;
                          <FormattedCurrency value={tier.join_fee} fromCents />
                        </span>
                      ),
                    }}
                  />
                )}
              </li>
              <li>{tier.commitmentMonths || 'No'}&nbsp;Commitment</li>
            </ul>
            <div
              role="presentation"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'var(--color-secondary)',
                cursor: 'pointer',
                ...expanderBtnStyles,
              }}
              onClick={() => handleExpand(tier.id)}
            >
              <Typography>
                <FormattedMessage
                  id={messageId('.view_tier_benefits', __filenamespace)}
                  values={{ tier_name: tier.name }}
                />
              </Typography>
              <Icon name={expandedIds.has(tier.id) ? 'arrowUp' : 'arrowDown'} />
            </div>
          </div>
          {expandedIds.has(tier.id) && (
            <MembershipViewingBenefits
              membership={tier}
              eventTypes={eventTypes}
              creditPasses={creditPasses}
              retailCategories={retailCategories}
              containerStyle={benefitsListStyles}
              benefitsWrapperStyle={benefitsWrapperStyle}
              creditItemStyle={creditItemStyle}
              discountsItemStyle={discountsItemStyle}
              exclusivitiesItemStyle={exclusivitiesItemStyle}
            />
          )}
        </div>
      ))}
    </>
  );
}

export function MembershipViewingAdminEventsModal({
  membership,
  membershipTiers,
  membershipTiersLoading,
  retailCategories,
  eventTypes,
  creditPasses,
  leftStyles,
}) {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      equalWidth();
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="mem-view">
      {!leftStyles && (
        <div style={styles.wrapper(membership.color)}>
          <Header membership={membership} />
          {!membership.tiered && (
            <div className="mem-view-price" style={styles.pricing}>
              {!!membership?.price && (
                <FormattedCurrency value={membership?.price} fromCents />
              )}
              {!membership?.price && (
                <FormattedMessage id={messageId('.free', __filenamespace)} />
              )}
            </div>
          )}
          {membership.tiered && (
            <div className="mem-view-price" style={styles.pricing}>
              <FormattedMessage
                id={messageId('.tiers_count', __filenamespace)}
                values={{ count: membership.membership_tier_count }}
              />
            </div>
          )}
        </div>
      )}
      <div
        className="mem-view__benefits"
        style={{
          overflow: 'auto',
          padding: '10px 0',
          fontSize: 15,
        }}
      >
        {leftStyles && !isMobile && (
          <Description
            membership={membership}
            style={merge(styles.sectionSpacing, styles.description)}
          />
        )}

        {!membership.tiered && (
          <MembershipViewingBenefits
            membership={membership}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
            leftStyles={leftStyles}
          />
        )}

        {membership.tiered && (
          <MembershipViewingTiersBenefits
            membershipTiers={membershipTiers}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
            leftStyles={leftStyles}
            membershipTiersLoading={membershipTiersLoading}
            discountsItemStyle={{ marginLeft: '-2px' }}
          />
        )}

        {!leftStyles && (
          <>
            <Divider sx={{ margin: '5px 0' }} />
            <Description membership={membership} style={styles.descStyles} />
          </>
        )}
        {leftStyles && isMobile && (
          <Description
            membership={membership}
            style={merge(styles.sectionSpacing, styles.description)}
          />
        )}
      </div>
    </div>
  );
}
function HiddenInPOSIndicator({ hideInPos }) {
  if (!hasAdminPermission() || !hideInPos) return null;
  return (
    <AlignedBox>
      <HiddenInPOSIcon
        sx={{ ...styles.hiddenIcon, color: 'var(--color-old-gray)' }}
      />
      <FormattedMessage id={messageId('.hidden_in_pos', __filenamespace)} />
    </AlignedBox>
  );
}

function HiddenInCheckoutIndicator({ hideInCheckout }) {
  if (!hasAdminPermission() || !hideInCheckout) return null;
  return (
    <AlignedBox>
      <HiddenInCheckoutIcon
        sx={{ ...styles.hiddenIcon, color: 'var(--color-old-gray)' }}
      />
      <FormattedMessage
        id={messageId('.hidden_in_checkout', __filenamespace)}
      />
    </AlignedBox>
  );
}

const MembershipOverview = injectIntl(
  ({
    eventTypes,
    creditPassListingStore: { records: creditPasses },
    retailCategories,
    membershipStore,
    subscriptionsStore,
    ui,
    intl,
  }) => {
    const hasSuspendedClients = !!subscriptionsStore?.subscriptions.find(
      subscriptions =>
        !!subscriptions.find(subscription => subscription.get('is_suspended'))
    );
    const { record, tiersIds } = membershipStore;

    return (
      <div>
        {!isMobile && (
          <Header
            showIcon
            membership={record}
            editLinkStyle={{ position: 'initial' }}
            style={{ gap: 10 }}
          />
        )}
        <PricingAndCommitment membership={record} />

        <Url membership={record} />
        {record.invite_only && <InviteOnly />}
        <HiddenInPOSIndicator hideInPos={record.hide_in_pos} />
        <HiddenInCheckoutIndicator hideInCheckout={record.hidden} />
        {!hasSuspendedClients && record.active_client_count === 0 && (
          <DeleteMembership />
        )}
        <SpacerLine />
        <RevenueAndClients
          membershipStore={membershipStore}
          subscriptionsStore={subscriptionsStore}
        />
        <SpacerLine />
        {record.tiered && (
          <>
            <Description
              membership={record}
              style={merge(styles.sectionSpacing, styles.description)}
            />
            <MembershipViewingTiers
              title={t('.tiered_membership_pricing', intl, __filenamespace)}
              membership={record}
              tiersIds={tiersIds}
              eventTypes={eventTypes}
              creditPasses={creditPasses}
              retailCategories={retailCategories}
            />
          </>
        )}
        {!record.tiered && (
          <MembershipViewingAdminEventsModal
            membership={record}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
            leftStyles
          />
        )}
        <ConfirmationDialog
          awaitingConfirmation={ui.awaitingRemoveConfirmation}
          onCancel={MembershipViewingActions.removeAborted}
          onConfirm={MembershipViewingActions.removeConfirmed}
          submitText={t('.remove', intl, __filenamespace)}
          title={t('.remove_membership', intl, __filenamespace)}
        >
          <FormattedMessage
            id={messageId('.remove_message', __filenamespace)}
          />
        </ConfirmationDialog>
      </div>
    );
  }
);

function PageTitle({ name }) {
  return (
    <Grid container item className="membership__header">
      <Grid container item xs>
        <ReactRouterLink to={customerScopedRoute('memberships')}>
          <Button
            type="tertiary"
            disableRipple
            classes={{ root: 'membership__back-btn' }}
            icon="arrowLeft"
          />
        </ReactRouterLink>
        <Typography
          variant="h4"
          className={clsx(
            'membership__name',
            isMobile && 'membership__mobile-name'
          )}
        >
          {name || 'new'}
        </Typography>
      </Grid>
    </Grid>
  );
}

const showSpinner = () => {
  const { isLoading } = EventTypeListingStore.getState();
  const { loading } = MembershipViewingUIStore.getState();
  return isLoading || loading;
};

function Content(props) {
  const {
    membershipStore: { record },
  } = props;
  const [tabSelected, setTabSelected] = useState(0);
  const tabs = [
    {
      label: 'Details',
      content: <MembershipOverview {...props} />,
    },
    {
      label: 'Clients',
      content: <MembershipClients {...props} />,
    },
  ];
  const onTabChange = (_event, value) => setTabSelected(value);
  return (
    <SpinWhileLoading isLoading={showSpinner()}>
      <PageTitle name={record.name} />
      {isMobile && (
        <Header
          showIcon
          membership={record}
          editLinkStyle={{ position: 'initial' }}
          style={{ gap: 10, padding: '15px 0' }}
        />
      )}
      {isMobile ? (
        <Tabs
          variant="fullWidth"
          tabItems={tabs}
          onChange={onTabChange}
          value={tabSelected}
          classes={{ root: 'membership-view-tabs' }}
          labelVariant="h5"
        />
      ) : (
        <ColumnarForm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          className="iphone-x-content"
          columns={[<MembershipOverview />, <MembershipClients />]}
          style={styles.columnarForm}
        />
      )}
    </SpinWhileLoading>
  );
}

function MembershipViewing({ membershipID }) {
  useEffect(() => {
    if (membershipID) {
      EventTypeListingActions.list();
      RetailCategoryListingActions.list();
      MembershipActions.fetch({ id: membershipID });
      MembershipViewingActions.listClients({ id: membershipID });
      MembershipSubscriptionActions.list({ membershipId: membershipID });
      ClientProfileDrawerActions.closeDrawer();
    }
  }, [membershipID]);

  return (
    <div className="membership">
      <AltContainer
        stores={{
          eventTypes: () => ({
            store: EventTypeListingStore,
            value: EventTypeListingStore.getState().eventTypes,
          }),
          retailCategories: () => ({
            store: RetailCategoryListingStore,
            value: RetailCategoryListingStore.getState().retailCategories,
          }),
          membershipStore: MembershipViewingStore,
          membershipCancellationStore: MembershipCancellationStore,
          membershipTierDataStore: MembershipTierDataStore,
          subscriptionsStore: MembershipSubscriptionStore,
          ui: MembershipViewingUIStore,
          creditPassListingStore: CreditPassListingStore,
        }}
      >
        <Content />
        <EditMembershipExpireModal />
        {/* <MembshipData /> */}
        <SuspensionDialog />
        <ReactivationDialog />
        <CancellationDialog />
        <BalanceListDrawer />
        <BalanceDetailsDrawer withBackLink />
        <PaymentDetailsDrawer />
        <QuickpayModal />
        <RefundModal />
        <MembershipViewFilterDrawer />
      </AltContainer>
    </div>
  );
}

export default MembershipViewing;
