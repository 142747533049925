import React from 'react';
import { injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { t } from 'shared/utils/LocaleUtils.js';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import DecoratedTextField from 'shared/components/DecoratedTextField.jsx';

import { ABSOLUTE, PERCENT } from 'shared/records/PayrollEntry';

function PayrollEntryModal({
  actions: { closeModal, confirm, update },
  payrollEntry,
  open,
  staffMember,
  intl,
}) {
  return (
    <Dialog disableEnforceFocus open={open} onClose={closeModal}>
      <DialogTitle>{t('.edit_entry', intl, __filenamespace)}</DialogTitle>
      <DialogContent>
        {open && (
          <div>
            <AvatarWithName
              user={staffMember}
              avatarOptions={{ size: 24 }}
              style={{ marginBottom: 10 }}
            />
            <TextField
              fullWidth
              name="event_name"
              disabled
              value={payrollEntry.event_name}
              label={t('.event_name', intl, __filenamespace)}
              style={{ marginBottom: 10 }}
              onChange={() => null}
            />
            <TextField
              fullWidth
              name="event_type_name"
              disabled
              value={payrollEntry.event_type_name}
              label={t('.event_type_name', intl, __filenamespace)}
              style={{ marginBottom: 10 }}
              onChange={() => null}
            />
            <Stack direction="row" spacing={1} style={{ marginBottom: 10 }}>
              <FormControl fullWidth>
                <InputLabel id="value-type-label">
                  {t('.type', intl, __filenamespace)}
                </InputLabel>
                <Select
                  label={t('.type', intl, __filenamespace)}
                  labelId="value-type-label"
                  value={payrollEntry.rate_type}
                  onChange={e =>
                    update({ rate_type: e.target.value, value: 0 })
                  }
                >
                  <MenuItem key="amount-discount" value={ABSOLUTE}>
                    {t('.amount', intl, __filenamespace)}
                  </MenuItem>
                  <MenuItem key="percent-discount" value={PERCENT}>
                    {t('.percent', intl, __filenamespace)}
                  </MenuItem>
                </Select>
              </FormControl>
              {payrollEntry.rate_type === ABSOLUTE ? (
                <CurrencyTextField
                  fullWidth
                  name="value"
                  floatingLabelText={t('.value', intl, __filenamespace)}
                  onChange={(_, v) => update({ value: v })}
                  value={payrollEntry.value}
                  symbolStyle={{ top: '70%' }}
                  textFieldStyle={{ width: 'inherit' }}
                  errorText={payrollEntry.errors.getErrors('value', intl)}
                  style={{ width: '100%' }}
                  converter={e => parseInt(e, 10)}
                />
              ) : (
                <DecoratedTextField
                  name="value"
                  floatingLabelText={t('.value', intl, __filenamespace)}
                  fullWidth
                  onChange={(_, v) => update({ value: v })}
                  value={payrollEntry.value}
                  decoration="%"
                  max={100.0}
                  min={0.0}
                  errorText={payrollEntry.errors.getErrors('value', intl)}
                  position="left"
                  symbolStyle={{ top: '52%' }}
                  textFieldStyle={{ width: 'inherit' }}
                  style={{ width: '100%' }}
                  converter={e => parseInt(e, 10)}
                />
              )}
            </Stack>
            <Stack spacing={1} direction="row">
              <TimePicker
                disabled
                label={t('.start_time', intl, __filenamespace)}
                name="start_time"
                value={payrollEntry.start_time}
                renderInput={params => (
                  <TextField
                    fullWidth
                    error={!!payrollEntry.errors.getErrors('start_time', intl)}
                    helperText={payrollEntry.errors.getErrors(
                      'start_time',
                      intl
                    )}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                  />
                )}
                onChange={() => null}
                DialogProps={{ disableEnforceFocus: true }}
              />
              <TextField
                fullWidth
                name="duration"
                disabled
                value={`${payrollEntry.duration} h`}
                label={t('.duration', intl, __filenamespace)}
                onChange={() => null}
              />
            </Stack>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          {t('.cancel', intl, __filenamespace)}
        </Button>
        <Button variant="contained" onClick={confirm}>
          {t('.save', intl, __filenamespace)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default injectIntl(PayrollEntryModal);
