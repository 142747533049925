import { List, Map } from 'immutable';
import RetailCategory from 'shared/records/RetailCategory.js';
import RetailCategoryEditingActions from 'shared/actions/RetailCategoryEditingActions.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';

class RetailCategoryListingStore extends UpperHandStore {
  constructor() {
    super();

    this.retailCategories = List();
    this.retailCategoryMap = Map();
    this.shouldLoad = true;
    this.findById = this.findById.bind(this);

    this.bindListeners({
      handleList: RetailCategoryListingActions.list,

      handleListSuccess: RetailCategoryListingActions.listSuccess,
      handleListError: RetailCategoryListingActions.listError,

      handleCreateOrUpdateSuccess:
        RetailCategoryEditingActions.createOrUpdateSuccess,
    });
  }

  handleList() {
    if (!this.shouldLoad || !currentCustomer().retail_enabled) {
      return false;
    }

    this.shouldLoad = false;
    return uhApiClient.get({
      url: 'retail_categories',
      data: { per_page: 100 },
      success: RetailCategoryListingActions.listSuccess,
      error: RetailCategoryListingActions.listError,
    });
  }

  handleListSuccess(data) {
    this.retailCategoryMap = this.retailCategoryMap.withMutations(m =>
      data.retail_categories.forEach(category =>
        m.set(category.id, new RetailCategory(category))
      )
    );

    this.retailCategories = this.sorted(this.retailCategoryMap.toList());
  }

  handleListError(...args) {
    this.shouldLoad = true;

    this.notifyError('error while listing retail categories', args);
  }

  handleCreateOrUpdateSuccess([attributes]) {
    this.insertOrUpdateRetailCategory(attributes);
  }

  /*
   * PRIVATE HELPERS
   */
  findById(id) {
    return this.retailCategoryMap.get(id);
  }

  insertOrUpdateRetailCategory(attributes) {
    const retailCategory = new RetailCategory(attributes);
    const index = this.retailCategories.findIndex(
      category => category.id === attributes.id
    );

    this.retailCategoryMap = this.retailCategoryMap.set(
      retailCategory.id,
      retailCategory
    );

    if (index >= 0) {
      this.retailCategories = this.sorted(
        this.retailCategories.setIn([index], retailCategory)
      );
    } else {
      this.retailCategories = this.sorted(
        this.retailCategories.push(retailCategory)
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(retailCategories) {
    return retailCategories.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return a.name > b.name ? 1 : 0;
    });
  }
}

export default alt.createStore(
  RetailCategoryListingStore,
  'RetailCategoryListingStore'
);
