import * as React from 'react';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import DatePicker from 'calendar/components/date_picker/DatePicker.jsx';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  DateNavigator: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 16px',
  },
  DateNavigatorMobile: {
    flex: '1',
  },
  TodayButton: {
    margin: '0 16px 0 0',
  },
  TodayButtonWrap: {
    borderRight: '1px solid #E5E6E5',
  },
};

const navigate = ({
  direction,
  toDate = moment(),
  currentDate = moment(),
  view,
}) => {
  let date;

  // ...direction gets priority vs date
  switch (direction) {
    case 'previous':
      date =
        view === CALENDAR_TYPE.DAY
          ? currentDate.subtract(1, 'days')
          : currentDate.subtract(1, 'week');
      break;
    case 'next':
      date =
        view === CALENDAR_TYPE.DAY
          ? currentDate.clone().add(1, 'days')
          : currentDate.clone().add(1, 'week');
      break;
    default:
      date = toDate;
      break;
  }

  CalendarActions.setCalendarDate(date);
};

function DateNavigator({ date, view, intl }) {
  const isMobile = smallScreen();
  let presentTimeText = '';

  switch (view) {
    case CALENDAR_TYPE.DAY:
      presentTimeText = t('.today', intl, __filenamespace);
      break;

    case CALENDAR_TYPE.WEEK:
      presentTimeText = t('.thisWeek', intl, __filenamespace);
      break;
    default:
      break;
  }

  return (
    <div
      className="DateNavigator"
      style={merge(
        styles.DateNavigator,
        isMobile ? styles.DateNavigatorMobile : {}
      )}
    >
      <IconButton
        onClick={() =>
          navigate({ direction: 'previous', currentDate: date, view })
        }
      >
        <ChevronLeftIcon sx={{ color: uhColors.activeBlue }} />
      </IconButton>

      <DatePicker currentDate={date} style={{ left: '-31415px' }} view={view} />

      <IconButton
        onClick={() => navigate({ direction: 'next', currentDate: date, view })}
      >
        <ChevronRightIcon sx={{ color: uhColors.activeBlue }} />
      </IconButton>

      {!isMobile && (
        <div style={styles.TodayButtonWrap}>
          <Button
            onClick={() => navigate({ toDate: moment() })}
            disabled={date.isSame(moment(), 'day')}
            style={styles.TodayButton}
          >
            {presentTimeText}
          </Button>
        </div>
      )}
    </div>
  );
}

export default injectIntl(DateNavigator);
