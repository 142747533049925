import React from 'react';
import { injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import Contact from 'contacts/index/components/ContactImportNative/_Contact.jsx';
import ContactImportNativeActions from 'contacts/index/actions/ContactImportNativeActions.js';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import styles from 'contacts/index/components/ContactImportNative/styles';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const ContactReview = injectIntl(({ contact, intl }) => {
  const showToggle = hasAdminPermission();
  return (
    <FlexBoxCenter style={{ flexDirection: 'column' }}>
      <Divider style={styles.divider} />
      <FlexBoxJustify style={styles.contact}>
        <Contact
          contact={contact}
          disabled
          avatarStyle={{ alignSelf: 'flex-start', marginTop: 4 }}
        >
          {showToggle && (
            <FormControlLabel
              label={t('.staff', intl, __filenamespace)}
              control={
                <Switch
                  labelPlacement="right"
                  checked={contact.isStaff()}
                  onChange={() =>
                    ContactImportNativeActions.toggleType(contact)
                  }
                />
              }
            />
          )}
        </Contact>
      </FlexBoxJustify>
    </FlexBoxCenter>
  );
});

export default ContactReview;
