import { List } from 'immutable';

import CreditCount from 'shared/records/CreditCount';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';

import CreditCountActions from './CreditCountActions';

const parserTo = { type: CreditCount, paginationKey: 'credit_counts' };
const parser = new Parser(parserTo);

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([CreditCountActions.listSuccess, success])
    ),
    error: onError(List([CreditCountActions.listError, error])),
  });
};
