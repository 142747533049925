import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { AvailabilityScheduleActions } from 'sources';

class AvailabilityScheduleDataStore extends UpperHandStore {
  constructor() {
    super();

    this.availabilitySchedules = Map();

    this.bindListeners({
      fetchSuccess: AvailabilityScheduleActions.fetchSuccess,
      listSuccess: [AvailabilityScheduleActions.listSuccess],
      listError: AvailabilityScheduleActions.listError,
    });
  }

  fetchSuccess(availabilitySchedule) {
    this.availabilitySchedules = this.availabilitySchedules.set(
      availabilitySchedule.id,
      availabilitySchedule
    );
  }

  listSuccess({ availability_schedules }) {
    const fetchedAvailabilitySchedule = availability_schedules
      .groupBy(a => a.id)
      .map(a => a.first());

    this.availabilitySchedules = this.availabilitySchedules.merge(
      fetchedAvailabilitySchedule
    );
  }

  listError() {
    this.notifyError('error fetching availability schedule', arguments);
  }
}

export default alt.createStore(
  AvailabilityScheduleDataStore,
  'AvailabilityScheduleDataStore'
);
