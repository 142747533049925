class ContactsArchivedStaffActions {
  constructor() {
    this.generateActions(
      'mounted',
      'pageSelected',
      'searchUpdated',
      'staffListError',
      'staffListSuccess',
      'listStaffAvailabilitySuccess',
      'listStaffAvailabilityError'
    );
  }
}

export default alt.createActions(ContactsArchivedStaffActions);
