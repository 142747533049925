import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';
import OrderSummaryDrawerActions from '../Actions';

function DotsMenu({ anchorEl, intl }) {
  const handleMenuItemClick = () => {
    OrderSummaryDrawerActions.sendReceipt();
  };

  const menuItems = [
    {
      label: t('.send_receipt', intl, __filenamespace),
      onClick: handleMenuItemClick,
    },
  ];

  return (
    <Menu
      anchor={anchorEl}
      items={menuItems}
      onClose={OrderSummaryDrawerActions.toggleDotsMenu}
    />
  );
}

DotsMenu.propTypes = {
  anchorEl: PropTypes.any,
  intl: PropTypes.object.isRequired,
};

DotsMenu.defaultProps = {
  anchorEl: null,
};

export default React.memo(DotsMenu);
