import React, { memo, useState } from 'react';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import { Grid, Card, Typography, Menu, Button } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import { MembershipSubscriptionDataStore } from 'dataStores';

import MembershipEmbedActions from './MembershipEmbedActions';

import './styles.scss';

function MembershipEmbedInfo({
  intl,
  subscriptionId,
  membershipSubscriptionDataStore,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { membershipSubscriptions } = membershipSubscriptionDataStore;
  const membershipSubscription = membershipSubscriptions.get(subscriptionId);

  if (!membershipSubscription || !currentUser().isStaff()) {
    return null;
  }

  const {
    id,
    embed_card_id: embedCardId,
    embed_card_status: embedCardStatus,
    embed_card_error: embedCardError,
    embed_platinum: embedPlatinum,
    membership = {},
  } = membershipSubscription;
  const { features } = currentCustomer();
  const { embed_api: embedApiEnabled } = features;
  const { has_embed_product: hasEmbedProduct } = membership;

  if (!embedApiEnabled || !hasEmbedProduct) {
    return null;
  }

  const menuOptions = [
    {
      label: t('.top_up', intl, __filenamespace),
      visible:
        !!embedCardId && embedCardStatus === 'card_active' && embedPlatinum,
      onClick: () => MembershipEmbedActions.topUp(id),
    },
    {
      label: t('.activate', intl, __filenamespace),
      visible: embedCardStatus === 'card_pending',
      onClick: () =>
        MembershipEmbedActions.openModal({
          subscription: membershipSubscription,
        }),
    },
    {
      label: t('.reactivate', intl, __filenamespace),
      visible:
        (embedCardStatus === 'card_suspended' ||
          embedCardStatus === 'card_cancelled') &&
        membershipSubscription.status === 'active',
      onClick: () =>
        MembershipEmbedActions.recycleEmbedCard({
          subscriptionId: id,
          embedCardId,
        }),
    },
    {
      label: t('.edit', intl, __filenamespace),
      visible: embedCardStatus !== 'card_pending',
      onClick: () =>
        MembershipEmbedActions.openModal({
          subscription: membershipSubscription,
        }),
    },
    {
      label: t('.cancel', intl, __filenamespace),
      visible: !!embedCardId && embedCardStatus === 'card_active',
      onClick: () =>
        MembershipEmbedActions.recycleEmbedCard({
          cancelCard: true,
          subscriptionId: id,
        }),
    },
  ].filter(i => i.visible);

  const toggleMenu = event =>
    setAnchorEl(!anchorEl ? event.currentTarget : null);

  return (
    <Card
      borderPosition="left"
      borderColor={membership.color}
      header={
        <>
          <Typography
            variant="h6"
            className="membership-embed-info__header-title"
          >
            {t('.embed_card', intl, __filenamespace)}
          </Typography>
          {currentUser().isStaff() && (
            <>
              <Button
                rounded
                type="tertiary"
                icon="moreVert"
                onClick={toggleMenu}
              />
              <Menu
                anchor={anchorEl}
                items={menuOptions}
                onClose={toggleMenu}
              />
            </>
          )}
        </>
      }
      menuOptions={currentUser().isStaff() ? menuOptions : undefined}
      classes={{
        root: 'membership-embed-info',
        content: 'membership-embed-info__content',
        header: 'membership-embed-info__header',
      }}
    >
      <Grid container direction="column" spacing={0}>
        <Grid container item alignItems="center">
          <Grid item xs={12}>
            <Typography className="membership-embed-info__embed-card">
              {embedCardId || '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="membership-embed-info__embed-card-info">
          {embedCardId ? (
            <Typography
              className={clsx(
                'membership-embed-info__embed-card-status',
                embedCardStatus === 'card_active' ? 'active' : 'inactive'
              )}
            >
              {embedCardStatus === 'card_active'
                ? t('.card_active', intl, __filenamespace)
                : t('.card_inactive', intl, __filenamespace)}
            </Typography>
          ) : null}
          {embedCardError ? (
            <Typography
              className={clsx(
                'membership-embed-info__embed-card-status',
                'card-error'
              )}
            >
              {embedCardError}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
}

export default altContainer({
  stores: {
    membershipSubscriptionDataStore: MembershipSubscriptionDataStore,
  },
})(injectIntl(memo(MembershipEmbedInfo)));
