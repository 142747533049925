// Javascript's `%` operator returns negative numbers when n is negative. This
// function performs modulo as expected.
//
// See: https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers/4467559
export const mod = (n, m) => ((n % m) + m) % m;

export const isNumber = value => {
  const result =
    !Number.isNaN(parseFloat(value)) && Number.isFinite(parseFloat(value));
  if (result) {
    return String(parseFloat(value)).length === String(value).length;
  }

  return false;
};

export const transformToInt = (value, defaultValue = 0) => {
  if (!value) {
    return defaultValue;
  }
  const str = value.replace(/[^\d]/g, '');
  return str === '' ? defaultValue : parseInt(str);
};
