import * as React from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';
import { injectIntl } from 'react-intl';

import ListComponent from 'components/list/index.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { CouponDataStore } from 'dataStores';
import { compose } from 'shared/utils/SharedUtils.js';

import ModalCreate from 'containers/coupons/couponView/components/ModalCreate.jsx';
import EmptyState from './components/EmptyState.jsx';
import Header from './components/Header.jsx';
import DesktopCard from './components/DesktopCard.jsx';
import MobileCard from './components/MobileCard.jsx';

import CouponsActions from './Actions';
import CouponsStore from './Store';

import './styles.scss';

function Coupons({
  couponDataStore: { coupons },
  couponsStore: {
    couponIds,
    isLoading,
    page,
    perPage,
    search,
    totalCount,
    isMobile,
  },
  settingTab,
  intl,
}) {
  /**
   * We need keep page scroll at top each time when user change orientation to prevent page flickering.
   * Page flickering because when user in portrait mode will fetch full coupons list then go to landscape
   * and then go to portrait again all pages will be fetched one by one and it's will cause flickering.
   */
  const handleOrientationChange = e => {
    window.scrollTo({ top: 0 });

    /**
     * Also we need to reset store each time when user goes too portrait mode and InfiniteScroller component is rendered.
     * If we don't do that then we will have multiple requests and pagination will be broken.
     */
    if (e.matches && smallScreen()) {
      CouponsActions.reset();
    }
  };

  React.useEffect(() => {
    const portrait = window.matchMedia('(orientation: portrait)');
    portrait.addEventListener('change', handleOrientationChange);

    CouponsActions.mounted.defer(smallScreen());

    return () =>
      portrait.removeEventListener('change', handleOrientationChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RecordCard = isMobile ? MobileCard : DesktopCard;

  const getRecordCard = couponId => (
    <RecordCard
      coupon={coupons.get(couponId)}
      couponId={couponId}
      key={couponId}
      intl={intl}
    />
  );

  return (
    <div className="coupons">
      <ListComponent
        actions={CouponsActions}
        header={
          <Header
            actions={CouponsActions}
            search={search}
            settingTab={settingTab}
            intl={intl}
          />
        }
        ids={couponIds}
        isLoading={isLoading}
        recordCard={getRecordCard}
        page={page}
        perPage={perPage}
        titleIds={Set([
          'name',
          'code',
          'type',
          'discount',
          'start_date',
          'end_date',
          'usage_limit',
        ])}
        totalCount={totalCount}
        emptyState={<EmptyState intl={intl} />}
      />
      <ModalCreate />
    </div>
  );
}

Coupons.propTypes = {
  couponsStore: PropTypes.object,
};

Coupons.defaultProps = {
  couponsStore: {},
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      couponDataStore: CouponDataStore,
      couponsStore: CouponsStore,
    },
  })
)(Coupons);
