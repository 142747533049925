import * as React from 'react';
import { injectIntl } from 'react-intl';
import AltContainer from 'alt-container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import User from 'event_mgmt/shared/records/User.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import UserStore from 'shared/stores/UserStore.jsx';
import uhTheme from '_uh_theme.jsx';
import {
  currentCustomer,
  enabledCustomerFeatures,
} from 'shared/utils/CustomerUtils';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils';
import { logout } from 'shared/utils/AuthUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { isDBatTheme } from 'shared/utils/ThemeUtils';

import ZebraActions from 'shared/actions/ZebraActions.jsx';
import PrintSettingsDrawer from 'shared/components/zebra/PrintSettingsDrawer.jsx';
import MachineSettingsDrawerActions from 'customers/settings/MachineSettingsDrawer/Actions.js';

class _UserMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { isSaving } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      (isSaving && !nextProps.isSaving) ||
      (!open && Boolean(nextState.anchorEl)) ||
      (open && !nextState.anchorEl)
    );
  }

  handleOpenMenu = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { intl } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        <IconButton disableTouchRipple onClick={this.handleOpenMenu}>
          <UserAvatar
            user={new User(currentUser())}
            style={{ marginRight: 0 }}
          />
        </IconButton>
        <Menu open={open} anchorEl={anchorEl} onClick={this.handleCloseMenu}>
          <MenuItem disabled>
            <Typography
              style={{ fontSize: 16, color: uhTheme.palette.textColor }}
            >
              {currentUser().name}
            </Typography>
          </MenuItem>
          <Divider />
          {currentUser().isClient() && (
            <MenuItem
              onClick={e => {
                e.preventDefault();
                redirectTo({
                  path: customerScopedRoute('/my_profile'),
                });
              }}
            >
              {t('.profile', intl, __filenamespace)}
            </MenuItem>
          )}
          <MenuItem
            onClick={e => {
              redirectTo({
                path: customerScopedRoute('/users/settings'),
              });
              e.preventDefault();
            }}
          >
            {t('.account_settings', intl, __filenamespace)}
          </MenuItem>
          {currentCustomer().handpoint_cloud_enabled &&
            (hasAdminPermission() || currentUser().isStaffMember()) && (
              <MenuItem
                onClick={() =>
                  redirectTo({
                    path: customerScopedRoute('devices'),
                  })
                }
              >
                {t('.device_settings', intl, __filenamespace)}
              </MenuItem>
            )}
          {currentUser().isStaff() &&
            enabledCustomerFeatures(['ap_credits']) &&
            isDBatTheme() && (
              <MenuItem onClick={MachineSettingsDrawerActions.toggleDrawer}>
                Machine Settings
              </MenuItem>
            )}
          <MenuItem
            component="a"
            href="/help"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('.support', intl, __filenamespace)}
          </MenuItem>
          <MenuItem
            component="a"
            href="https://portal.productboard.com/khrjhntpr2dizawse7qtmkyp/tabs/3-launched/submit-idea"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('.submit_idea', intl, __filenamespace)}
          </MenuItem>
          {!currentUser().isClient() && (
            <MenuItem onClick={() => ZebraActions.openPrintSettings()}>
              {t('.printer_settings', intl, __filenamespace)}
            </MenuItem>
          )}
          <MenuItem
            component="a"
            href="https://www.upperhand.com/privacy-and-cookie-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('.privacy_policy', intl, __filenamespace)}
          </MenuItem>
          {/* Intention hard refresh for this item because the admin ui is
            handled by Rails and not Javascript. */}
          {currentUser().isAdmin() && (
            <MenuItem href="/uh_admin">
              {t('.admin_ui', intl, __filenamespace)}
            </MenuItem>
          )}
          {currentUser().isAdmin() && (
            <MenuItem
              onClick={e => {
                redirectTo({ path: '/accounts/choose' });
                e.preventDefault();
              }}
            >
              {t('.customer_selection', intl, __filenamespace)}
            </MenuItem>
          )}
          {currentUser().isAdmin() && (
            <MenuItem
              onClick={e => {
                redirectTo({
                  path: customerScopedRoute('/events'),
                });
                e.preventDefault();
              }}
            >
              {t('.customer_ui', intl, __filenamespace)}
            </MenuItem>
          )}
          <MenuItem
            onClick={e => {
              logout();
              e.preventDefault();
            }}
          >
            {t('.sign_out', intl, __filenamespace)}
          </MenuItem>
        </Menu>
      </>
    );
  }
}
const UserMenu = injectIntl(_UserMenu);

function AltWrappedUserMenu() {
  return (
    <div>
      <AltContainer store={UserStore}>
        <UserMenu />
      </AltContainer>
      <PrintSettingsDrawer />
    </div>
  );
}

export default AltWrappedUserMenu;
