import { List } from 'immutable';

import CalendarMeta from 'calendar/records/CalendarMeta.jsx';
import CalendarView from 'calendar/records/CalendarView.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ResourceListingStore from 'resources/stores/ResourceListingStore.js';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import OWNER_TYPE from 'calendar/types/OwnerType.jsx';
import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

export const defaultViews = {
  ALL_STAFF: -1,
  ALL_RESOURCES: -2,
  MY_CALENDAR: -3,
};

const allStaffView = new CalendarView({
  id: defaultViews.ALL_STAFF,
  title: 'All Staff',
  calendar_type: CALENDAR_TYPE.DAY,
  settings: {},
});

const allResourcesView = new CalendarView({
  id: defaultViews.ALL_RESOURCES,
  title: 'All Resources',
  calendar_type: CALENDAR_TYPE.DAY,
  settings: {},
});

const currentStaffView = new CalendarView({
  id: defaultViews.MY_CALENDAR,
  title: 'My Calendar',
  calendar_type: CALENDAR_TYPE.DAY,
  settings: {},
});

class CalendarViewStore extends UpperHandStore {
  constructor() {
    super();

    this.calendarViews = List();
    this.currentCalendarView = new CalendarView();
    this.showCustomizeDrawer = false;
    this.showAddCalendarsMenu = false;
    this.dirty = false;
    this.didDelete = true;

    this.bindListeners({
      handleList: [
        CalendarViewActions.LIST,
        CalendarViewActions.CREATE_OR_UPDATE_SUCCESS,
        CalendarViewActions.DELETE_SUCCESS,
      ],
      handleListSuccess: CalendarViewActions.LIST_SUCCESS,
      handleListError: CalendarViewActions.LIST_ERROR,
      handleCreateOrUpdate: CalendarViewActions.CREATE_OR_UPDATE,
      handleCreateOrUpdateSuccess: CalendarViewActions.CREATE_OR_UPDATE_SUCCESS,
      handleCreateOrUpdateError: CalendarViewActions.CREATE_OR_UPDATE_ERROR,
      handleDelete: CalendarViewActions.DELETE,
      handleDeleteSuccess: CalendarViewActions.DELETE_SUCCESS,
      handleDeleteError: CalendarViewActions.DELETE_ERROR,
      handleDuplicate: CalendarViewActions.DUPLICATE,
      handleChangeView: CalendarViewActions.CHANGE_VIEW,
      handleSwap: CalendarViewActions.SWAP,
      handleUpdateCurrentViewAttribute:
        CalendarViewActions.UPDATE_CURRENT_VIEW_ATTRIBUTE,
      handleAddCalendar: CalendarViewActions.ADD_CALENDAR,
      handleRemoveCalendar: CalendarViewActions.REMOVE_CALENDAR,
      handleDiscardChanges: CalendarViewActions.DISCARD_CHANGES,
      inferCalendars: StaffActions.LIST_SUCCESS,
      toggleShowCustomizeDrawer:
        CalendarViewActions.TOGGLE_SHOW_CUSTOMIZE_DRAWER,
      toggleShowAddCalendarsMenu:
        CalendarViewActions.TOGGLE_SHOW_ADD_CALENDARS_MENU,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  defaultViews() {
    const defaults = List([allResourcesView, allStaffView]);
    if (currentUser().isAdmin()) {
      return defaults;
    }
    return defaults.unshift(currentStaffView);
  }

  // eslint-disable-next-line class-methods-use-this
  handleList() {
    return uhApiClient.get({
      url: 'calendar/calendar_views',
      success: CalendarViewActions.listSuccess,
      error: CalendarViewActions.listError,
    });
  }

  handleListSuccess(data) {
    const initialLoad = !this.calendarViews.size;

    this.calendarViews = List(data.calendar_views)
      .map(view => new CalendarView(view))
      .concat(this.defaultViews())
      .map(v => v.set('default_view', v.id === data.default_view));

    if (initialLoad || this.didDelete) {
      this.didDelete = false;
      this.loadDefaultView();
    }
  }

  handleListError(...args) {
    this.notifyError('Error listing calendar views.', args);
  }

  handleCreateOrUpdate({ title }) {
    this.handleUpdateCurrentViewAttribute({
      attributeKey: 'title',
      value: title,
    });

    const method = !this.currentCalendarView.id ? 'post' : 'put';

    const url =
      method === 'post'
        ? 'calendar/calendar_views'
        : `calendar/calendar_views/${this.currentCalendarView.id}`;

    return uhApiClient[method]({
      url,
      data: JSON.stringify({ attributes: this.currentCalendarView }),
      success: CalendarViewActions.createOrUpdateSuccess,
      error: CalendarViewActions.createOrUpdateError,
    });
  }

  handleCreateOrUpdateSuccess(data) {
    const message = new TranslatableMessage({
      id: 'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.save_success',
    });

    MessageWindowActions.addMessage.defer(message);

    if (!this.currentCalendarView.id) {
      this.handleUpdateCurrentViewAttribute({
        attributeKey: 'id',
        value: data.id,
      });
    }

    this.dirty = false;
  }

  handleCreateOrUpdateError(...args) {
    this.notifyError('Error creating calendar view.', args);
  }

  // eslint-disable-next-line class-methods-use-this
  handleDelete(id) {
    uhApiClient.delete({
      url: `calendar/calendar_views/${id}`,
      success: CalendarViewActions.deleteSuccess,
      error: CalendarViewActions.deleteError,
    });
  }

  handleDeleteSuccess() {
    this.didDelete = true;

    const message = new TranslatableMessage({
      id: 'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.delete_success',
    });

    MessageWindowActions.addMessage.defer(message);
  }

  handleDeleteError(...args) {
    this.notifyError('Error deleting calendar view.', args);
  }

  handleDuplicate() {
    this.currentCalendarView = this.currentCalendarView.merge({
      id: undefined,
      title: `Copy of ${this.currentCalendarView.title}`,
      default_view: false,
    });

    this.dirty = true;
  }

  handleChangeView(id) {
    this.currentCalendarView =
      this.calendarViews.find(view => view.id === id) || new CalendarView();

    this.inferCalendars();
    this.dirty = false;
  }

  handleSwap({ oldIndex, newIndex }) {
    if (this.currentCalendarView.id < 0) {
      this.handleDuplicate();
    }

    const calendars = this.currentCalendarView.calendars();
    const calendarToMove = calendars.get(oldIndex);

    const updatedCalendars = calendars
      .splice(oldIndex, 1)
      .insert(newIndex, calendarToMove)
      .map((c, index) => c.set('positionIndex', index));

    this.currentCalendarView =
      this.currentCalendarView.setCalendars(updatedCalendars);
    this.dirty = true;
  }

  handleUpdateCurrentViewAttribute({ attributeKey, value }) {
    this.currentCalendarView = this.currentCalendarView.update(
      attributeKey,
      () => value
    );

    this.dirty = true;
  }

  handleAddCalendar({ id, ownerType }) {
    const calendars = this.currentCalendarView.calendars();
    const newCalendar = new CalendarMeta({
      id,
      ownerType,
    });

    const updatedCalendars = calendars
      .unshift(newCalendar)
      .map((c, index) => c.merge({ positionIndex: index }));

    this.currentCalendarView =
      this.currentCalendarView.setCalendars(updatedCalendars);

    this.dirty = true;
  }

  handleRemoveCalendar({ id, ownerType }) {
    const calendars = this.currentCalendarView.calendars();

    if (calendars.size <= 1) {
      const message = new TranslatableMessage({
        id: 'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.minimum_error',
      });

      MessageWindowActions.addMessage.defer(message);
      return;
    }

    const remove = calendars.findIndex(
      c => c.id === id && c.ownerType === ownerType
    );

    if (remove > -1) {
      const updatedCalendars = calendars.delete(remove);

      this.currentCalendarView =
        this.currentCalendarView.setCalendars(updatedCalendars);

      this.dirty = true;
    }
  }

  handleDiscardChanges() {
    const currentId = this.currentCalendarView.id;

    if (currentId) {
      this.handleChangeView(currentId);
    } else {
      this.loadDefaultView();
    }
  }

  inferCalendars() {
    if (this.currentCalendarView.id >= 0) return;

    this.waitFor([StaffStore, ResourceListingStore]);

    const calendars = this.currentCalendarView.calendars();
    let inferredCalendars = List();
    if (this.currentCalendarView.isCurrentStaff()) {
      const currentStaffMember = StaffStore.getState().allStaff.find(
        staffMember => currentUser().id === staffMember.user_id
      );
      if (currentStaffMember) {
        inferredCalendars = inferredCalendars.push(
          new CalendarMeta({
            id: currentStaffMember.id,
            ownerType: OWNER_TYPE.STAFF,
            positionIndex: 0,
          })
        );
      }
    }

    if (this.currentCalendarView.isAllStaff()) {
      const implicitStaffCalendars = StaffStore.getState()
        .allStaff.filter(
          staffMember =>
            !calendars.find(calendar => staffMember.id === calendar.id)
        )
        .map(
          (staffMember, index) =>
            new CalendarMeta({
              id: staffMember.id,
              ownerType: OWNER_TYPE.STAFF,
              positionIndex: calendars.size + index,
            })
        );

      inferredCalendars = calendars.concat(implicitStaffCalendars);
    }

    if (this.currentCalendarView.isAllResources()) {
      const implicitResourceCalendars = ResourceListingStore.getState()
        .resources.filter(
          resource => !calendars.find(calendar => resource.id === calendar.id)
        )
        .map(
          (resource, index) =>
            new CalendarMeta({
              id: resource.id,
              ownerType: OWNER_TYPE.RESOURCE,
              positionIndex: calendars.size + index,
            })
        );

      inferredCalendars = calendars.concat(implicitResourceCalendars);
    }

    this.currentCalendarView =
      this.currentCalendarView.setCalendars(inferredCalendars);
  }

  loadDefaultView() {
    this.currentCalendarView =
      this.calendarViews.find(view => view.default_view) || allStaffView;

    this.inferCalendars();

    this.dirty = false;
  }

  toggleShowCustomizeDrawer(show) {
    this.showCustomizeDrawer = show ? true : !this.showCustomizeDrawer;
  }

  toggleShowAddCalendarsMenu(show) {
    this.showAddCalendarsMenu = show ? true : !this.showAddCalendarsMenu;
  }
}

export default alt.createStore(CalendarViewStore, 'CalendarViewStore');
