import * as React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@upperhand/playmaker';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';

import { CreditGrantDataStore } from 'dataStores';

import CreditGrantsTab from 'containers/creditListDrawer/components/Tabs/CreditGrantsTab.jsx';
import RegistrationsPackageTab from 'containers/creditListDrawer/components/Tabs/RegistrationsPackageTab.jsx';

import CreditListDrawerActions from 'containers/creditListDrawer/Actions';

import CreditListDrawerStore, { TABS } from 'containers/creditListDrawer/Store';

function Content({ client, creditListDrawerStore: { activeTab } }) {
  const tabs = [
    {
      label: 'Memberships & Passes',
      content: <CreditGrantsTab />,
      value: TABS.CREDIT_GRANTS,
    },
    {
      label: 'Events',
      content: <RegistrationsPackageTab clientId={client.id} />,
      value: TABS.REGISTRATION_PACKAGE_CREDITS,
    },
  ];

  return (
    <div className="credit-list-drawer__content">
      <ClientDetails client={client} />
      <Tabs
        classes={{
          tabsRoot: 'credit-list-drawer__tab-root',
        }}
        variant="fullWidth"
        labelVariant="subtitle2"
        tabItems={tabs}
        onChange={(_, tab) => CreditListDrawerActions.onTabChange(tab)}
        value={activeTab}
      />
    </div>
  );
}

Content.propTypes = {
  client: PropTypes.object,
  creditListDrawerStore: PropTypes.object,
};

Content.defaultProps = {
  client: {},
  creditListDrawerStore: {
    activeTab: TABS.CREDIT_GRANTS,
  },
};

export default compose(
  altContainer({
    stores: {
      creditListDrawerStore: CreditListDrawerStore,
      creditGrantDataStore: CreditGrantDataStore,
    },
  }),
  React.memo
)(Content);
