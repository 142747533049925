import React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import ContactImportNativeActions from 'contacts/index/actions/ContactImportNativeActions.js';
import styles from 'contacts/index/components/ContactImportNative/styles';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const ShowMoreButton = injectIntl(({ intl }) => (
  <Button
    className="import-contacts-primary-action"
    style={merge(styles.button, styles.showMoreButton)}
    onClick={ContactImportNativeActions.showMore}
  >
    {t('.show_more', intl, __filenamespace)}
  </Button>
));

export default ShowMoreButton;
