export const Filters = {
  SEARCH: 'search',
  PERIOD: 'period',
  ORDER_METHOD: 'orderMethod',
  PAYMENT_METHOD: 'paymentMethod',
  PAYMENT_TYPES: 'paymentTypes',
  PRODUCT: 'product',
  PRODUCT_TYPE: 'productType',
  SOURCE_TYPES: 'sourceTypes',
  LOCATION: 'location',
  BALANCE_REMAINING_MIN: 'balanceRemainingMin',
  BALANCE_REMAINING_MAX: 'balanceRemainingMax',
  AMOUNT_MIN: 'amountMin',
  AMOUNT_MAX: 'amountMax',
};

export const OrderableTypes = {
  registration_package: 'registration_package',
  membership_subscription_package: 'membership_subscription_package',
  credit_pass_package: 'credit_pass_package',
  variant: 'variant',
};

export const FilterPeriod = {
  today: 'today',
  yesterday: 'yesterday',
  this_week: 'this_week',
  last_week: 'last_week',
  this_month: 'this_month',
  last_month: 'last_month',
  this_quarter: 'this_quarter',
  last_quarter: 'last_quarter',
  this_year: 'this_year',
  last_year: 'last_year',
  custom: 'custom',
  all_time: 'all_time',
};

export const FilterOrderMethod = {
  ALL: 'all',
  ONLINE: 'online',
  IN_STORE: 'in_store',
};

export const FilterPaymentMethod = {
  ALL: 'all',
  BANK: 'bank',
  CARD: 'card',
  CASH: 'cash',
  CHECK: 'check',
  CREDIT: 'credit',
  PAY_LATER: 'pay_later',
};

export const FilterPaymentType = {
  ALL: 'all',
  BANK: 'bank',
  CARD: 'card',
  CASH: 'cash',
  CHECK: 'check',
  PAYMENT_PLAN: 'payment_plan',
  PAY_LATER: 'pay_later',
};

export const FilterSourceTypes = {
  ALL: 'all',
  PAYMENT: 'Payment',
  REFUND: 'Refund',
  PAYMENT_PLAN: 'PaymentPlan',
  ORDER_ITEM: 'OrderItem',
  MEMBERSHIP_RENEWAL: 'MembershipRenewal',
  SCHEDULED_PAYMENT: 'ScheduledPayment',
  DEBIT_FORGIVENESS: 'DebitForgiveness',
  ORDER_FAILURE: 'OrderFailure',
};
