import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Editor } from '@tinymce/tinymce-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { upload } from 'shared/utils/S3Utils';
import { t } from 'shared/utils/LocaleUtils';

const getEditorConfig = ({ useBase64, intl, height, readOnly }) => ({
  height,
  menubar: !readOnly,
  statusbar: !readOnly,
  license_key: 'gpl',
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'preview',
    'help',
    'wordcount',
    'emoticons',
  ],
  toolbar: readOnly
    ? false
    : 'undo redo | blocks | ' +
      'bold italic forecolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat | help',
  content_style: 'body { font-family:benton-sans,sans-serif;}',
  file_picker_types: 'image',
  file_picker_callback(cb, _value, _meta) {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/png, image/jpeg');
    input.onchange = async e => {
      const file = e.target.files[0];

      if (file.size > 2097152) {
        const message = t(
          'shared.WysiwygEditor.file_size_alert',
          intl,
          __filenamespace
        );

        window.tinymce.activeEditor.windowManager.alert(message);
        return;
      }

      if (useBase64) {
        const reader = new FileReader();

        reader.onload = () => {
          const id = `blobid${new Date().getTime()}`;
          const { blobCache } = window.tinymce.activeEditor.editorUpload;
          const base64 = reader.result.split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);

          blobCache.add(blobInfo);
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      } else {
        window.tinymce.activeEditor.ui.setEnabled(false);

        const image = await upload(file, true, true);

        window.tinymce.activeEditor.ui.setEnabled(true);
        cb(image.url, { title: file.name });
      }
    };
    input.click();
  },
  paste_data_images: false,
  relative_urls: false,
  remove_script_host: false,
});

const StyledWrapper = styled(Box)(() => ({
  '.tox-promotion': {
    display: 'none !important',
  },
  '.tox-statusbar__branding': {
    display: 'none !important',
  },
  '.tox-tinymce': {
    borderRadius: '0 !important',
    border: '1px solid rgb(181, 186, 206) !important',
  },
}));
function WysiwygEditor({
  intl,
  value,
  height,
  useBase64,
  onChange,
  readOnly = false,
}) {
  const handleChange = (_evt, editor) => {
    if (editor) {
      onChange(editor.getContent());
    }
  };

  const handleNodeChange = e => {
    if (e && e.element.nodeName.toLowerCase() === 'img') {
      let { width: imageWidth } = e.element;
      let { height: imageHeight } = e.element;

      if (imageWidth > 520) {
        imageHeight /= imageWidth / 520;
        imageWidth = 520;
      }

      window.tinymce.activeEditor.dom.setAttribs(e.element, {
        width: imageWidth,
        height: imageHeight,
      });
    }
  };

  return (
    <StyledWrapper>
      <Editor
        tinymceScriptSrc={`${window.location.origin}/tinymce.min.js`}
        init={getEditorConfig({ useBase64, intl, height, readOnly })}
        initialValue={value}
        onBlur={handleChange}
        onNodeChange={handleNodeChange}
        disabled={readOnly}
      />
    </StyledWrapper>
  );
}

WysiwygEditor.propTypes = {
  useBase64: PropTypes.bool,
  value: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

WysiwygEditor.defaultProps = {
  value: '',
  height: 400,
  useBase64: true,
  onChange: () => null,
};

export default React.memo(injectIntl(WysiwygEditor));
