import { List } from 'immutable';
import AddressTypeActions from 'shared/actions/AddressTypeActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class AddressTypeStore extends UpperHandStore {
  constructor() {
    super();
    this.addressTypes = List();
    this.reset();
    this.bindListeners({
      handleUpdateStore: AddressTypeActions.updateStore,
      handleList: AddressTypeActions.list,
      handleListSuccess: AddressTypeActions.listSuccess,
      handleListError: AddressTypeActions.listError,
    });
  }

  handleUpdateStore(args) {
    let updatedArgs = args;
    if (!updatedArgs) {
      return;
    }
    if (updatedArgs === 'reset') {
      this.reset();
      return;
    }
    if (updatedArgs.toString() !== '[object Object]') {
      updatedArgs = { [updatedArgs[0]]: updatedArgs[1] };
    }
    this.addressType = { ...this.addressType, ...updatedArgs };
  }

  // eslint-disable-next-line class-methods-use-this
  handleList() {
    return uhApiClient.get({
      url: 'address_types',
      data: { per_page: 100 },
      success: AddressTypeActions.listSuccess,
      error: AddressTypeActions.listError,
    });
  }

  handleListSuccess(data) {
    this.addressTypes = this.sorted(List(data.address_types));
  }

  handleListError(...args) {
    this.notifyError('error while listing address types', args);
  }

  reset() {
    this.addressType = {
      id: null,
      name: '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(types) {
    return types.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }
}

export default alt.createStore(AddressTypeStore, 'AddressTypeStore');
