import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

function SearchField({ onChange, value, intl }) {
  return (
    <TextField
      classes={{
        root: 'coupons__search-field',
      }}
      fullWidth
      icon="search"
      onChange={onChange}
      placeholder={t('.search', intl, __filenamespace)}
      rounded
      value={value}
    />
  );
}

SearchField.propTypes = {
  onChange: PropTypes.func,
  intl: PropTypes.object.isRequired,
};

SearchField.defaultProps = {
  onChange: () => {},
};

export default SearchField;
