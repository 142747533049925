import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box } from '@mui/material';
import MuiLink from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';

import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';
import uhTheme from '_uh_theme.jsx';

import CustomerActions from 'shared/actions/CustomerActions.jsx';

const styles = {
  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '90%',
  },

  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    marginTop: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
    marginRight: 20,
  },
  membershipColumn: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    marginTop: LEFT_MARGIN_PIXELS * 3,
    maxWidth: '100%',
    marginRight: 20,
  },
  toolbarConfig: {
    display: [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
    ],

    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  },
  editorStyle: { maxWidth: 540, width: '100%' },
  agreementHeading: { marginBottom: '1em' },
};

const stylesheet = `
  .rich-text-container { font-family: ${uhTheme.fontFamily}; }
  .public-DraftEditor-content { min-height: 250px; }
  a { color: ${uhColors.lightBlue}; }
`;

const onChange = ({ value, key }) => {
  handleChange({ actions: CustomerActions })(null, value, key);
};

const handleSave = id =>
  handleChange({ actions: CustomerActions, namedAction: 'update' })(
    null,
    id,
    'id'
  );

function PolicyTermsTab({ customerStore, intl }) {
  return (
    <section>
      {/* eslint-disable-next-line react/no-danger */}
      <style type="text/css" dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <FlexBox style={styles.root}>
        <Box style={styles.column}>
          <FlexBoxJustify>
            <Box>
              <Box component="span" sx={boldText}>
                <FormattedMessage id={messageId('theme_vendor')} />
              </Box>
              &nbsp;
              <FormattedMessage
                id={messageId('.default_waiver', __filenamespace)}
              />
            </Box>
            <MuiLink href={window.upperHandTermsUrl} target="blank">
              <FormattedMessage id={messageId('actions.view')} />
            </MuiLink>
          </FlexBoxJustify>
          <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
          <FlexBoxJustify>
            <Box sx={styles.agreementHeading}>
              <Box component="span" sx={boldText}>
                <FormattedMessage
                  id={messageId('.custom_waiver', __filenamespace)}
                />
              </Box>
            </Box>
            <Link to={customerScopedRoute('terms')}>
              <FormattedMessage id={messageId('actions.view')} />
            </Link>
          </FlexBoxJustify>
          <Box sx={styles.editorStyle}>
            <WysiwygEditor
              value={customerStore.customer.policy_and_terms}
              onChange={value => onChange({ value, key: 'policy_and_terms' })}
              placeholder={t('.custom_waiver', intl, __filenamespace)}
            />
          </Box>
        </Box>
        <Box style={styles.membershipColumn} mt={3}>
          <FlexBoxJustify>
            <Box sx={styles.agreementHeading}>
              <Box component="span" sx={boldText}>
                <FormattedMessage
                  id={messageId('.membership_agreement', __filenamespace)}
                />
              </Box>
            </Box>
            <Link to={customerScopedRoute('membership-agreement')}>
              <FormattedMessage id={messageId('actions.view')} />
            </Link>
          </FlexBoxJustify>
          <Box sx={styles.editorStyle}>
            <WysiwygEditor
              value={customerStore.customer.membership_agreement}
              onChange={value =>
                onChange({ value, key: 'membership_agreement' })
              }
              placeholder={t('.membership_agreement', intl, __filenamespace)}
            />
          </Box>
        </Box>
      </FlexBox>
      <FlexBox style={styles.root}>
        <StateChangingButton
          onClick={() => handleSave(customerStore.customer.get('id'))}
          inProgress={customerStore.isSaving}
        />
      </FlexBox>
    </section>
  );
}

export default injectIntl(PolicyTermsTab);
