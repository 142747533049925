import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Set } from 'immutable';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ItemSummaryHeader from 'shared/components/checkout/_ItemSummaryHeader.jsx';
import ManageOrderItem from 'shared/components/checkout/_ManageOrderItem.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { l, messageId, t } from 'shared/utils/LocaleUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import {
  hasMembershipAgreementAccepted,
  getAgreementSignedDate,
} from 'shared/components/checkout/helper';

import MembershipAgreementFormActions from 'shared/actions/MembershipAgreementFormActions';

const styles = {
  commitment: {
    color: '#807F7F',
    marginBottom: 10,
  },
  details: {
    fontWeight: 'bold',
    paddingLeft: 25,
    position: 'relative',
    marginBottom: 15,
  },
  nameAndCommitment: {
    marginBottom: 20,
  },
  quantity: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  agreementButton: {
    marginBottom: '15px',
    minHeight: 30,
    fontSize: 13,
  },
};

function DiscountedAmount({ value, discount }) {
  return (
    <div>
      <span style={{ marginRight: 5 }}>
        <FormattedCurrency value={value - discount} fromCents />
      </span>
      <span style={{ textDecoration: 'line-through' }}>
        <FormattedCurrency value={value} fromCents />
      </span>
    </div>
  );
}

function NameAndCommitment({ item }) {
  const adjustment = item.applied_adjustments.find(
    a => a.isCoupon() && a.details.isRecurring()
  );
  return (
    <div style={styles.nameAndCommitment}>
      <FlexBoxJustify style={{ marginBottom: 8 }}>
        <FormattedMessage id={messageId('.membership', __filenamespace)} />
        {adjustment ? (
          <DiscountedAmount
            value={item.orderable.subscribable_base_price}
            discount={adjustment.details.discount.discountFor(
              item.orderable.subscribable_base_price
            )}
          />
        ) : (
          <FormattedCurrency
            value={item.orderable.subscribable_base_price}
            fromCents
          />
        )}
      </FlexBoxJustify>

      {item.orderable.subscribable_commitment_months > 0 && (
        <div style={styles.commitment}>
          <FormattedMessage
            id={messageId('.commitment', __filenamespace)}
            values={{ months: item.orderable.subscribable_commitment_months }}
          />
        </div>
      )}
    </div>
  );
}

function BillingInterval({ item }) {
  return (
    <FlexBoxJustify style={{ marginBottom: 8 }}>
      <FormattedMessage id={messageId('.billed', __filenamespace)} />
      <FormattedMessage
        id={messageId('.interval', __filenamespace)}
        values={{ months: item.orderable.subscribable_interval_months }}
      />
    </FlexBoxJustify>
  );
}

const PreSaleInformation = injectIntl(({ item, intl }) => {
  if (!item.orderable.isPreSale()) {
    return null;
  }
  const adjustment = item.applied_adjustments.find(
    a => a.isCoupon() && a.details.isRecurring()
  );
  const chargeAmount = adjustment
    ? adjustment.details.discount.applyTo(
        item.orderable.subscribable_base_price
      )
    : item.orderable.subscribable_base_price;
  return (
    <FlexBoxJustify style={{ marginBottom: 8, fontWeight: 'normal' }}>
      <FormattedMessage id={messageId('.charge_date', __filenamespace)} />
      <FormattedMessage
        id={messageId('.charge_and_date', __filenamespace)}
        values={{
          charge: <FormattedCurrency value={chargeAmount} fromCents />,
          date: l(
            item.orderable.membership_active_at,
            'dates.shortNumeric',
            intl,
            __filenamespace
          ),
        }}
      />
    </FlexBoxJustify>
  );
});

function JoinFee({ item }) {
  const adjustment = item.applied_adjustments.find(
    a => a.isCoupon() && a.details.isOneTime()
  );

  return (
    <FlexBoxJustify style={{ marginBottom: 8 }}>
      <FormattedMessage id={messageId('.join_fee', __filenamespace)} />
      {adjustment ? (
        <DiscountedAmount
          value={item.orderable.subscribable_join_fee}
          discount={-adjustment.get('amount')}
        />
      ) : (
        <FormattedCurrency
          value={item.orderable.subscribable_join_fee}
          fromCents
        />
      )}
    </FlexBoxJustify>
  );
}

function Quantity({ item }) {
  return (
    <FlexBoxJustify style={styles.quantity}>
      <div>
        <FormattedMessage id={messageId('.quantity', __filenamespace)} />
        &nbsp;
        {item.orderable.customer_user_ids.size}
      </div>
      <FormattedCurrency value={item.totalBeforeManualDiscount()} fromCents />
    </FlexBoxJustify>
  );
}

const AgreementButton = injectIntl(
  ({
    intl,
    clientIds,
    hasSignedAgreement,
    clientName,
    membershipId,
    signedAt,
  }) => {
    const showCompleted = hasSignedAgreement;
    const buttonLabel = showCompleted
      ? t('.agreement_complete', intl, __filenamespace)
      : t('.fill_agreement', intl, __filenamespace);

    return (
      <Button
        fullWidth
        variant={showCompleted ? 'outlined' : 'contained'}
        sx={styles.agreementButton}
        startIcon={
          showCompleted && <CheckCircleIcon sx={{ color: uhColors.green }} />
        }
        onClick={() =>
          MembershipAgreementFormActions.openAgreementDrawer({
            clientIds,
            clientName,
            membershipId,
            hasSignedAgreement,
            signedAt,
          })
        }
      >
        {buttonLabel}
      </Button>
    );
  }
);

const getParticipatingProfiles = (orderItem, allProfiles) => {
  let participantIds = Set();

  if (orderItem.isEventItem()) {
    participantIds = orderItem.orderable.client_ids;
  } else if (orderItem.isMembershipItem()) {
    participantIds = orderItem.orderable.customer_user_ids;
  } else if (orderItem.isCreditPassItem()) {
    return allProfiles.filter(p => p.id === orderItem.orderable.client_id);
  }

  return allProfiles.filter(p => participantIds.has(p.id));
};

function MembershipSubscriptionPackageSummary({
  item,
  purchased,
  appliedAccountCredits,
  onRemove,
  onEdit,
  onView,
  athletes,
}) {
  const currentProfile = athletes.find(athlete => athlete.id === item.buyer_id);
  const profiles = getParticipatingProfiles(item, athletes);
  const membershipAgreementContent = currentCustomer().membership_agreement;
  const hasSignedAgreement = hasMembershipAgreementAccepted(
    item.orderable.customer_user_ids,
    profiles,
    membershipAgreementContent
  );
  const signedAt = getAgreementSignedDate(
    item.orderable.customer_user_ids,
    profiles,
    membershipAgreementContent
  );

  return (
    <div>
      <ItemSummaryHeader
        item={item}
        onRemove={onRemove}
        purchased={purchased}
      />
      <div style={styles.details}>
        <MembershipIcon
          style={{ height: 16, width: 16, position: 'absolute', left: 0 }}
          color={item.orderable.membership_color}
        />

        <NameAndCommitment item={item} adjustments={item.applied_adjustments} />
        <BillingInterval item={item} />
        <PreSaleInformation item={item} />
        <JoinFee item={item} />
      </div>
      <Divider sx={{ height: '2px', marginBottom: '15px' }} />
      <Quantity item={item} />
      {membershipAgreementContent && (
        <AgreementButton
          hasSignedAgreement={hasSignedAgreement}
          clientIds={item.orderable.customer_user_ids}
          membershipId={item.orderable.membership_id}
          signedAt={signedAt}
          clientName={currentProfile?.name()}
        />
      )}
      <ManageOrderItem
        item={item}
        purchased={purchased}
        appliedAccountCredits={appliedAccountCredits}
        onEdit={onEdit}
        onView={onView}
      />
    </div>
  );
}

export default injectIntl(MembershipSubscriptionPackageSummary);
