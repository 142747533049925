import * as React from 'react';
import { injectIntl, useIntl } from 'react-intl';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { t } from 'shared/utils/LocaleUtils.js';
import uhTheme from '_uh_theme.jsx';

function SelectWithNewItem({
  children,
  onChange,
  newItemCallback,
  itemName,
  value,
  label,
  formControlStyle,
  fullWidth,
  errorText,
  error,
  disableNewItemBtn,
  ...restProps
}) {
  const intl = useIntl();

  const handleChange = e => {
    if (e.target.value === 0) {
      newItemCallback();
    } else {
      onChange(e);
    }
  };

  const getPlaceholder = () =>
    !value
      ? () => (
          <span style={{ color: uhTheme.palette.lightGrey }}>
            {restProps.hintText ||
              t('.select_item_hint', intl, __filenamespace, {
                item_name: itemName,
              })}
          </span>
        )
      : undefined;

  return (
    <FormControl
      variant="standard"
      fullWidth={fullWidth}
      error={error}
      sx={{ minWidth: 120, ...formControlStyle }}
    >
      <InputLabel
        shrink
        id="select-label"
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          color: uhTheme.palette.charcoalBlack,
        }}
      >
        {itemName}
      </InputLabel>
      <Select
        labelId="select-label"
        displayEmpty={!value}
        renderValue={getPlaceholder()}
        value={value || ''}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      >
        {newItemCallback && (
          <MenuItem value={0}>
            {t('.new_item_text', intl, __filenamespace, {
              item_name: itemName,
            })}
          </MenuItem>
        )}
        {newItemCallback && <Divider />}
        {children}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
}

export default injectIntl(SelectWithNewItem);
