import { List } from 'immutable';
import Waitlist from 'shared/records/Waitlist.js';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';
import DataStoreActions from './WaitlistActions';

const parserTo = { type: Waitlist };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `events/${id}/waitlist`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const invite = ({ id, customer_user_ids, success, error }) => {
  uhApiClient.patch({
    url: `events/${id}/waitlist/invite`,
    data: JSON.stringify({
      attributes: { customer_user_ids },
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error,
    requireToken: false,
  });
};
