/* eslint-disable class-methods-use-this */
import Moment from 'moment-timezone';
import EventListStoreImpl from 'shared/stores/_EventListStoreImpl.jsx';
import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class FilteredEventListStoreImpl extends EventListStoreImpl {
  constructor(eventActions, listeners) {
    super(
      eventActions,
      merge(
        {
          handleReset: [EventListActions.reset, StoreActions.prepareForReuse],
          handleUpdateRequestedDate: [
            EventListActions.updateRequestedDate,
            CalendarActions.setCalendarDate,
          ],
        },
        listeners
      )
    );

    this.date = null;
    this.eventUrl = 'events';
  }

  handleReset() {
    this.date = null;
  }

  handleListEvents({
    fields,
    perPage,
    page,
    dateRange,
    ids,
    exceptIds,
    exceptTypes,
    scheduleType,
    isStaffDrawer,
  }) {
    this.waitFor(this.filterStore());
    const filterStoreState = this.filterStore().getState();
    const data = {};

    data.statuses = this.statusesQueryParam(filterStoreState);
    data.types = this.typesQueryParam(filterStoreState);
    data.locations = this.locationQueryParams(filterStoreState);
    data.attendees = this.attendeesQueryParams(filterStoreState);
    data.min_age = this.minAgeQueryParam(filterStoreState);
    data.max_age = this.maxAgeQueryParam(filterStoreState);

    if (ids && ids.length) {
      data.ids = ids;
    }

    if (exceptIds && exceptIds.length) {
      data.except_ids = exceptIds;
    }

    if (exceptTypes && exceptTypes.length) {
      data.except_types = exceptTypes;
    }

    if (dateRange) {
      data.start_date = dateRange.start;
      data.end_date = dateRange.end;
    } else if (this.date) {
      data.date = isStaffDrawer ? null : this.date;
    }

    if (scheduleType) {
      data.schedule_type = scheduleType;
    }

    if (this.filterStore().getState().bookedEventsOnly) {
      data.only_booked = this.filterStore().getState().bookedEventsOnly;
    }

    if (this.filterStore().getState().titleFilter) {
      data.title = this.filterStore().getState().titleFilter;
    }

    if (this.filterStore().getState().scheduleType && !scheduleType) {
      data.schedule_type = this.filterStore().getState().scheduleType;
    }

    super.handleListEvents({ fields, data, page, perPage });
  }

  statusesQueryParam(filterStoreState) {
    const statusFilters = [];
    filterStoreState.statusFilters.forEach((isEnabled, filter) => {
      if (filter !== 'all' && isEnabled) {
        statusFilters.push(filter);
      }
    });
    return statusFilters.length ? statusFilters : 'none';
  }

  typesQueryParam(filterStoreState) {
    // TODO: Don't use an honorAll property.
    const { honorAll, typeFilters } = filterStoreState;
    const typeFilterParams = [];

    if (honorAll && typeFilters.get('all')) {
      typeFilterParams.push('all');
    } else {
      typeFilters.forEach((isEnabled, filter) => {
        if (filter !== 'all' && isEnabled) {
          typeFilterParams.push(filter);
        }
      });
    }

    const emptyValue = honorAll ? 'none' : ['all'];
    return typeFilterParams.length ? typeFilterParams : emptyValue;
  }

  locationQueryParams({ locationFilters }) {
    const enabledFilters = locationFilters
      .filter((v, _) => v)
      .keySeq()
      .toList();

    return enabledFilters.size ? enabledFilters.toJS() : ['all'];
  }

  attendeesQueryParams({ attendeeFilters }) {
    const enabledFilters = attendeeFilters
      .filter((v, _) => v)
      .keySeq()
      .toList();

    return enabledFilters.size ? enabledFilters.toJS() : ['all'];
  }

  minAgeQueryParam({ ageFilters }) {
    return ageFilters.get('enabled') ? ageFilters.get('min') : null;
  }

  maxAgeQueryParam({ ageFilters }) {
    return ageFilters.get('enabled') ? ageFilters.get('max') : null;
  }

  handleUpdateRequestedDate(date) {
    this.date = Moment(date).toDate();
  }
}

export default FilteredEventListStoreImpl;
