export const REQUIRED_MANAGED_ACCOUNT_FIELDS = ['date_of_birth', 'gender'];
export const REQUIRED_PRIMARY_ACCOUNT_FIELDS_CLIENT = [
  'password',
  'phone',
  'email',
  'date_of_birth',
  'gender',
  'address',
];
export const REQUIRED_PRIMARY_ACCOUNT_FIELDS_STAFF = [
  'password',
  'phone',
  'email',
  'date_of_birth',
  'address',
];
