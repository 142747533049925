import React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import {
  TextField,
  Grid,
  MultipleSelect,
  DateTimeField,
} from '@upperhand/playmaker';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import FranchiseLocationsStore from 'reporting/index/stores/_FranchiseLocationsStore.jsx';
import RevenueReportActions from 'reporting/revenue/actions/_RevenueReportActions.jsx';
import RevenueReportStore from 'reporting/revenue/stores/_RevenueReportStore.jsx';

import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const buyerChanged = (_, value) =>
  RevenueReportActions.filterUpdated('buyer', value);
const clientChanged = (_, value) =>
  RevenueReportActions.filterUpdated('client', value);
const productChanged = (_, value) =>
  RevenueReportActions.filterUpdated('product', value);
const productTypeChanged = (_, value) =>
  RevenueReportActions.filterUpdated('productType', value);
const startDateChanged = value =>
  RevenueReportActions.filterUpdated('startDate', value);
const endDateChanged = value =>
  RevenueReportActions.filterUpdated('endDate', value);
const franchiseLocationUpdated = value => {
  RevenueReportActions.filterUpdated('customerIds', value);
};

function RevenueFilters({
  revenueReportStore: {
    buyer,
    canAdjustFilter,
    canApplyFilter,
    canClearFilter,
    client,
    endDate,
    isApplyingFilter,
    customerIds,
    product,
    productType,
    startDate,
  },
  franchiseLocationsStore: { franchiseLocations },
  intl,
}) {
  const selectItems = franchiseLocations
    .valueSeq()
    .toArray()
    .map(s => ({ value: s.id, label: s.name }));

  return (
    <Widget title={t('.title', intl, __filenamespace)} style={{ marginTop: 0 }}>
      <Grid container spacing={2} direction="row">
        {currentUser().isAdmin() && (
          <Grid item xs={4}>
            <MultipleSelect
              selectAll
              items={selectItems}
              selectedItems={customerIds.toArray()}
              outline
              rounded={false}
              name="locations"
              onChange={franchiseLocationUpdated}
              fullWidth
              label={t('.franchise_location', intl, __filenamespace)}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <TextField
            value={buyer || ''}
            fullWidth
            label={t('.buyer', intl, __filenamespace)}
            disabled={!canAdjustFilter}
            onChange={buyerChanged}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={client || ''}
            fullWidth
            label={t('.client', intl, __filenamespace)}
            disabled={!canAdjustFilter}
            onChange={clientChanged}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={product || ''}
            fullWidth
            label={t('.product', intl, __filenamespace)}
            disabled={!canAdjustFilter}
            onChange={productChanged}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={productType || ''}
            fullWidth
            label={t('.productType', intl, __filenamespace)}
            disabled={!canAdjustFilter}
            onChange={productTypeChanged}
          />
        </Grid>
        <Grid item xs={4}>
          <DateTimeField
            autoOk
            type="date"
            disabled={!canAdjustFilter}
            label={t('.startDate', intl, __filenamespace)}
            fullWidth
            placeholder="MM/DD/YYYY"
            name="start_date"
            onChange={startDateChanged}
            shouldDisableDate={date => endDate && date > endDate}
            value={startDate}
          />
        </Grid>

        <Grid item xs={4}>
          <DateTimeField
            autoOk
            type="date"
            disabled={!canAdjustFilter}
            label={t('.endDate', intl, __filenamespace)}
            fullWidth
            placeholder="MM/DD/YYYY"
            name="end_date"
            onChange={endDateChanged}
            shouldDisableDate={date => startDate && date < startDate}
            value={endDate}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: 20 }}>
        <Stack direction="row" spacing={1}>
          <Button
            primary
            disabled={!canApplyFilter}
            variant="contained"
            onClick={RevenueReportActions.applyFilters}
          >
            {t(
              isApplyingFilter ? '.applying' : '.apply',
              intl,
              __filenamespace
            )}
          </Button>
          <Button
            primary
            disabled={!canClearFilter}
            onClick={RevenueReportActions.clearFilters}
          >
            {t('.clear', intl, __filenamespace)}
          </Button>
        </Stack>
      </div>
    </Widget>
  );
}

function RevenueFiltersWrapper({ intl }) {
  return (
    <AltContainer
      stores={{
        franchiseLocationsStore: FranchiseLocationsStore,
        revenueReportStore: RevenueReportStore,
      }}
    >
      <RevenueFilters intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(RevenueFiltersWrapper);
