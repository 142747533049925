import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Dropdown } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import FieldErrors from 'shared/records/FieldErrors.jsx';

import { TERMINATION_OPTIONS } from '../Store';

function TerminationFields({ errors, termination, onChange }) {
  const intl = useIntl();
  const terminationReasons = Object.values(TERMINATION_OPTIONS).map(option => ({
    label: t(`.${option}`, intl, __filenamespace),
    value: option,
  }));

  return (
    <Dropdown
      fullWidth
      label={t('.termination_date', intl, __filenamespace)}
      errorText={errors.getErrors('termination')}
      value={termination}
      items={terminationReasons}
      onChange={e => onChange('termination', e.target.value)}
    />
  );
}

TerminationFields.propTypes = {
  errors: PropTypes.instanceOf(FieldErrors).isRequired,
  termination: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(TerminationFields);
