import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Map, Set } from 'immutable';
import ActionDone from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { red } from '@mui/material/colors';

import uhTheme from '_uh_theme.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    margin: '0 -0.5rem',
  },

  enhancedButton: {
    padding: '6px',
    minWidth: 'initial',
  },

  errorText: {
    color: red[500],
    fontSize: 12,
  },

  paperUnselected: {
    paddingTop: '8px',
    width: '2.5rem',
  },

  paperSelected: {
    paddingTop: '8px',
    backgroundColor: uhTheme.palette.primary1Color,
    color: 'white',
    width: '2.5rem',
  },
};

const getWeekdays = intl =>
  Map({
    0: t('.sunday', intl, __filenamespace),
    1: t('.monday', intl, __filenamespace),
    2: t('.tuesday', intl, __filenamespace),
    3: t('.wednesday', intl, __filenamespace),
    4: t('.thursday', intl, __filenamespace),
    5: t('.friday', intl, __filenamespace),
    6: t('.saturday', intl, __filenamespace),
  });

class WeekdayPicker extends React.PureComponent {
  isDaySelected = day => {
    const { selectedDays } = this.props;
    return selectedDays.has(day);
  };

  selectWeekday = day => {
    const { onChangeWeekdays, selectedDays } = this.props;
    if (this.isDaySelected(day)) {
      onChangeWeekdays(selectedDays.delete(day));
    } else {
      onChangeWeekdays(selectedDays.add(day));
    }
  };

  render() {
    const { intl, errorText, styles: propStyles, buttonStyle } = this.props;
    const weekdays = getWeekdays(intl);
    return (
      <div>
        <FlexBoxJustify style={merge(styles.root, propStyles)}>
          {weekdays
            .map((label, key) => (
              <Button
                style={merge(styles.enhancedButton, buttonStyle)}
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                onClick={() => this.selectWeekday(key)}
              >
                <Paper
                  zDepth={1}
                  style={
                    this.isDaySelected(key)
                      ? styles.paperSelected
                      : styles.paperUnselected
                  }
                >
                  <div>{label}</div>
                  <div>
                    <ActionDone sx={{ color: 'white' }} />
                  </div>
                </Paper>
              </Button>
            ))
            .toIndexedSeq()}
        </FlexBoxJustify>
        {errorText && <p style={styles.errorText}>{errorText}</p>}
      </div>
    );
  }
}

WeekdayPicker.propTypes = {
  buttonStyle: PropTypes.object,
  errorText: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onChangeWeekdays: PropTypes.func,
  selectedDays: PropTypes.instanceOf(Set),
  styles: PropTypes.object,
};

WeekdayPicker.defaultProps = {
  buttonStyle: {},
  errorText: null,
  onChangeWeekdays: () => {},
  selectedDays: Set(),
  styles: {},
};

export default injectIntl(WeekdayPicker);
