import * as React from 'react';
import Divider from '@mui/material/Divider';

import uhTheme from '_uh_theme.jsx';

const styles = {
  Divider: {
    height: 2,
    marginTop: 0,
    marginLeft: 16,
    marginRight: 16,
    backgroundColor: uhTheme.palette.lightGrey,
  },
};

function MenuDivider() {
  return <Divider style={styles.Divider} />;
}

export default MenuDivider;
