import * as React from 'react';
import { Button, Icon, Typography, Card, Menu } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

import CardReaderDropdownActions from 'containers/cardReaderDropdown/Actions.js';

import DeviceListActions from '../Actions';

const ALLOWED_DEVICE_TYPES = ['paxa920', 'paxa920pro', 'paxa60'];

function DeviceInfo({ cardReader, intl }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        classes={{
          root: `device-info ${cardReader.get('enabled') ? '' : 'disabled'}`,
          content: 'device-info__wrapper',
        }}
      >
        <div className="device-info__title">
          <div className="device-info__icon">
            <Icon name={cardReader.statusIconName} />
          </div>
          <Typography variant="h5" className="device-info__title-text">
            {cardReader.get('nickname')}
          </Typography>
          {cardReader.activationStatus === 'default' && (
            <div className="device-info__default-label">
              {t('.label_default', intl, __filenamespace)}
            </div>
          )}
          <Button
            classes={{
              root: `device-info__menu ${
                cardReader.get('enabled') ? '' : 'disabled'
              }`,
            }}
            type="tertiary"
            icon="moreVert"
            rounded
            onClick={handleMenuOpen}
          />
        </div>
        <div className="device-info__content">
          <div className="device-info__row">
            <span className="device-info__row-header">
              {t('.type', intl, __filenamespace)}
            </span>
            {cardReader.get('deviceType')}
          </div>
        </div>
        <div className="device-info__content">
          <div className="device-info__row">
            <span className="device-info__row-header">
              {t('.serial_number', intl, __filenamespace)}
            </span>
            {cardReader.get('serialNumber')}
          </div>
        </div>
        <div className="device-info__content">
          <div className="device-info__row">
            <span className="device-info__row-header">
              {t('.status', intl, __filenamespace)}
            </span>
            {t(
              `.activation_status_${cardReader.enabledString}`,
              intl,
              __filenamespace
            )}
          </div>
        </div>
      </Card>
      <Menu
        anchor={anchorEl}
        onClose={handleMenuClose}
        items={[
          ...(ALLOWED_DEVICE_TYPES.indexOf(cardReader.get('deviceType')) > -1 &&
          cardReader.activationStatus !== 'default' &&
          cardReader.get('enabled')
            ? [
                {
                  label: t('.set_default', intl, __filenamespace),
                  onClick: () =>
                    CardReaderDropdownActions.selectCardReader(
                      cardReader.get('id')
                    ),
                },
              ]
            : []),
          {
            label: t(
              `.${cardReader.get('enabled') ? 'disable' : 'enable'}`,
              intl,
              __filenamespace
            ),
            onClick: () => DeviceListActions.toggleEnabled(cardReader),
          },
          {
            label: t('.edit', intl, __filenamespace),
            onClick: () =>
              DeviceListActions.openEditModal(cardReader.get('id')),
          },
        ]}
      />
    </>
  );
}

export default DeviceInfo;
