import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { getCurrentContext } from 'shared/utils/AuthUtils';

import ClientActions from 'shared/actions/ClientActions.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import CustomerActions from 'shared/actions/CustomerActions.jsx';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';

class CreateUserStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      fetchCustomerResult: [
        CustomerActions.fetchSuccess,
        CustomerActions.fetchError,
      ],
      fetchCustomerUserResult: [
        StaffActions.fetchSuccess,
        ClientActions.fetchSuccess,
        StaffActions.fetchError,
        ClientActions.fetchError,
      ],
    });
  }

  reset() {
    this.isLoading = true;
    this.isCustomerLoading = true;
    const { customerUserId } = getCurrentContext();
    this.isCustomerUserLoading =
      isLoggedIn() && currentUser().customer_user_id === customerUserId;
  }

  updateLoading() {
    const { customerUserId } = getCurrentContext();
    // We have use condition with customerUserId because of shared/stores/ClientStoreInterface.jsx:226
    this.isLoading =
      this.isCustomerLoading ||
      (currentUser().customer_user_id !== customerUserId &&
        this.isCustomerUserLoading);
  }

  fetchCustomerResult() {
    this.isCustomerLoading = false;
    this.updateLoading();
  }

  fetchCustomerUserResult() {
    this.isCustomerUserLoading = false;
    this.updateLoading();
  }
}

export default alt.createStore(CreateUserStore, 'CreateUserStore');
