import AvailableTimesStore from 'event_mgmt/shared/stores/AvailableTimesStore.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSEventStore from 'point_of_sale/stores/POSEventStore.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSPurchasedRegistrationActions from 'point_of_sale/actions/POSPurchasedRegistrationActions.jsx';
import POSSchedulingStore from 'point_of_sale/stores/POSSchedulingStore.jsx';

export default alt.createStore(
  AvailableTimesStore,
  'POSRegistrationAvailableTimesStore',
  {
    setEvent: [
      POSProductListActions.EVENT_SELECTED,
      POSEventActions.FETCH_EVENT_SUCCESS,
    ],

    clearSelections: [
      POSPurchasedRegistrationActions.REGISTRATION_BOOKED,
      POSPurchasedRegistrationActions.BACK_BUTTON_CLICKED,
      POSActions.DRAWER_DISMISSED,
    ],

    handleStaffChange:
      POSPurchasedRegistrationActions.REGISTRATION_STAFF_CHANGED,
    handleDateChange: POSPurchasedRegistrationActions.REGISTRATION_DATE_CHANGED,
    handleTimeChange: POSPurchasedRegistrationActions.REGISTRATION_TIME_CHANGED,
    handleFetchAvailabilities:
      POSPurchasedRegistrationActions.FETCH_AVAILABILITIES,
  },
  POSEventStore.displayName,
  POSSchedulingStore.displayName
);
