import PropTypes from 'prop-types';
import * as React from 'react';
import { Button, Typography } from '@upperhand/playmaker';

import uhTheme from '_uh_theme.jsx';
import history from 'routes/History.js';

import SignupResults from '../SignupResults.jsx';

const styles = {
  link: {
    color: uhTheme.palette.upperHandGreen,
    fontWeight: 'bold',
  },
};

function SignupSuccess({ email }) {
  const directToLogin = () => {
    history.push('/');
  };

  const mailTo = `mailto:${email}`;

  const description = (
    <Typography variant="body1" color="primary">
      We&#39;ve sent a verification email to{' '}
      <a href={mailTo} style={styles.link}>
        {email}
      </a>
    </Typography>
  );

  const signInBtn = <Button onClick={directToLogin}>Sign in</Button>;

  return (
    <SignupResults
      headerText="Up and to the right!"
      description={description}
      callToAction={signInBtn}
    />
  );
}

SignupSuccess.propTypes = {
  email: PropTypes.string,
};

SignupSuccess.defaultProps = {
  email: '',
};

export default SignupSuccess;
