import moment from 'moment-timezone';
import debounce from 'lodash.debounce';
import { Map, List, fromJS } from 'immutable';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { downloadFile } from 'shared/utils/SharedUtils.js';

import CloseoutReportActions from 'containers/reports/dailyCloseoutReport/Actions.js';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class CloseoutReportStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.debouncedListOrders = debounce(() => this.listOrders(), 600);
    this.bindListeners({
      mounted: CloseoutReportActions.mounted,
      pageSelect: CloseoutReportActions.pageSelect,
      applyFilters: CloseoutReportActions.applyFilters,
      deleteFilter: CloseoutReportActions.deleteFilter,
      handleFilterChange: CloseoutReportActions.handleFilterChange,

      openCalculatorDrawer: CloseoutReportActions.openCalculatorDrawer,
      closeCalculatorDrawer: CloseoutReportActions.closeCalculatorDrawer,
      handleCalculatorFieldChange:
        CloseoutReportActions.handleCalculatorFieldChange,

      openFiltersDrawer: CloseoutReportActions.openFiltersDrawer,
      closeFiltersDrawer: CloseoutReportActions.closeFiltersDrawer,

      listOrders: CloseoutReportActions.listOrders,
      listOrdersSuccess: CloseoutReportActions.listOrdersSuccess,
      listOrdersError: CloseoutReportActions.listOrdersError,

      downloadCsvReport: CloseoutReportActions.downloadCsvReport,
      downloadCsvReportSuccess: CloseoutReportActions.downloadCsvReportSuccess,
      downloadCsvReportError: CloseoutReportActions.downloadCsvReportError,
    });
  }

  reset() {
    this.orders = List();
    this.ordersLoading = false;
    this.statisticsLoading = false;
    this.entriesStatisticsLoading = false;
    this.filtersChanged = false;
    this.isDownloadingReport = false;

    this.statistics = Map({
      total: 0,
      transactions: 0,
      unique_buyers: 0,
    });

    this.filters = Map({
      search: '',
      ordered_at: Map({
        effective_at_max: moment().endOf('day'),
        effective_at_min: moment().startOf('day'),
        when: 'today',
      }),
      amount_min: null,
      amount_max: null,
      payment_method: 'cash',
    });

    this.appliedFilters = Map({
      search: '',
      amount_min: null,
      amount_mam: null,
      effective_at_max: moment().endOf('day'),
      effective_at_min: moment().startOf('day'),
    });

    this.resetDrawerState();
    this.resetFiltersDrawerState();
    this.resetPagination();
  }

  resetPagination() {
    this.pagination = Map({
      page: 1,
      perPage: 10,
      totalCount: 0,
    });
  }

  resetDrawerState() {
    this.calculatorDrawerState = Map({
      open: false,
      cashIn: null,
      cashToday: null,
    });
  }

  resetFiltersDrawerState() {
    this.filtersDrawerState = Map({ open: false });
  }

  openFiltersDrawer() {
    this.filtersDrawerState = this.filtersDrawerState.set('open', true);
  }

  closeFiltersDrawer() {
    this.resetFiltersDrawerState();
  }

  openCalculatorDrawer() {
    const total = this.statistics.get('total', 0);

    this.calculatorDrawerState = this.calculatorDrawerState
      .set('open', true)
      .set('cashToday', total > 0 ? total / 100 : 0);
  }

  closeCalculatorDrawer() {
    this.resetDrawerState();
  }

  handleCalculatorFieldChange(e) {
    this.calculatorDrawerState = this.calculatorDrawerState.set(
      e.target.name,
      Number(e.target.value)
    );
  }

  getParams() {
    const params = {
      ...this.appliedFilters.toJS(),
      effective_at_max: this.appliedFilters.get('effective_at_max').format(),
      effective_at_min: this.appliedFilters.get('effective_at_min').format(),
      page: this.pagination.get('page'),
      per_page: this.pagination.get('perPage'),
    };

    const totalMin = this.filters.get('amount_min', 0);
    const totalMax = this.filters.get('amount_max', 0);

    if (totalMin > 0) {
      params.amount_min = Number(totalMin) * 100;
    } else {
      delete params.amount_min;
    }

    if (totalMax > 0) {
      params.amount_max = Number(totalMax) * 100;
    } else {
      delete params.amount_max;
    }

    delete params.ordered_at;

    return params;
  }

  mounted() {
    this.listOrders();
  }

  pageSelect([page]) {
    this.ordersLoading = true;
    this.pagination = this.pagination.set('page', page);

    this.debouncedListOrders();
  }

  applyFilters() {
    this.ordersLoading = true;
    this.filtersChanged = false;
    this.appliedFilters = this.appliedFilters
      .set('amount_min', this.filters.get('amount_min'))
      .set('amount_max', this.filters.get('amount_max'))
      .set('search', this.filters.get('search'))
      .set('payment_method', this.filters.get('payment_method'))
      .set(
        'effective_at_min',
        this.filters.getIn(['ordered_at', 'effective_at_min'])
      )
      .set(
        'effective_at_max',
        this.filters.getIn(['ordered_at', 'effective_at_max'])
      );
    this.debouncedListOrders();
  }

  deleteFilter(filter) {
    this.filters = this.filters.set(filter, null);
    this.applyFilters();
  }

  handleFilterChange(e) {
    this.filtersChanged = true;

    if (e.target.name === 'ordered_at' && e.target.value === 'today') {
      this.ordersLoading = true;
      this.filters = this.filters
        .setIn([e.target.name, 'effective_at_max'], moment().endOf('day'))
        .setIn([e.target.name, 'effective_at_min'], moment().startOf('day'))
        .setIn([e.target.name, 'when'], e.target.value);

      this.resetPagination();
      this.applyFilters();
      return;
    }

    if (e.target.name === 'ordered_at' && e.target.value === 'yesterday') {
      this.ordersLoading = true;
      this.filters = this.filters
        .setIn(
          [e.target.name, 'effective_at_max'],
          moment().endOf('day').subtract(1, 'days')
        )
        .setIn(
          [e.target.name, 'effective_at_min'],
          moment().startOf('day').subtract(1, 'days')
        )
        .setIn([e.target.name, 'when'], e.target.value);

      this.resetPagination();
      this.applyFilters();
      return;
    }

    if (e.target.name === 'amount_min' || e.target.name === 'amount_max') {
      this.filters = this.filters.set(e.target.name, Number(e.target.value));
      this.filtersChanged = true;
      return;
    }

    this.filters = this.filters.set(e.target.name, e.target.value);

    this.applyFilters();
  }

  listOrders() {
    this.ordersLoading = true;
    uhApiClient.get({
      url: 'analytics/cash_closeout',
      data: this.getParams(),
      success: CloseoutReportActions.listOrdersSuccess,
      error: CloseoutReportActions.listOrdersError,
    });
  }

  listOrdersSuccess({
    records,
    page,
    per_page: perPage,
    total_amount: totalAmount,
    total_count: totalTransactions,
    unique_buyers: uniqueBuyers,
  }) {
    this.statistics = this.statistics
      .set('total', totalAmount)
      .set('transactions', totalTransactions)
      .set('unique_buyers', uniqueBuyers);
    this.orders = fromJS(records);
    this.pagination = this.pagination
      .set('page', page)
      .set('perPage', perPage)
      .set('totalCount', totalTransactions);
    this.ordersLoading = false;
  }

  listOrdersError(...args) {
    this.ordersLoading = false;

    this.notifyError('Failed to load orders', ...args);
  }

  downloadCsvReport() {
    const params = this.getParams();

    delete params.fields;
    delete params.page;
    delete params.per_page;
    delete params.ordered_at;

    this.isDownloadingReport = true;
    uhApiClient.get({
      url: 'analytics/cash_closeout_csv',
      data: params,
      success: CloseoutReportActions.downloadCsvReportSuccess,
      error: CloseoutReportActions.downloadCsvReportError,
    });
  }

  downloadCsvReportSuccess(data) {
    downloadFile({
      data,
      fileName: 'order_list.csv',
    });
    this.isDownloadingReport = false;
  }

  downloadCsvReportError(...args) {
    this.notifyError('Failed to download report', ...args);
    this.isDownloadingReport = false;
  }
}

export default alt.createStore(CloseoutReportStore, 'CloseoutReportStore');
