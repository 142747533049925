import { List } from 'immutable';

const handleCallback = (parsedData, success) => {
  if (typeof success === 'function' && success.defer) {
    success.defer(parsedData);
  } else if (typeof success === 'function') {
    success(parsedData);
  } else if (success.action) {
    success.action.defer([parsedData, ...success.args]);
  }
};

export const onSuccess = (parser, success) => data => {
  const parsedData = parser(data);

  if (List.isList(success)) {
    success.forEach(x => handleCallback(parsedData, x));
  } else {
    handleCallback(parsedData, success);
  }
};

export const onError = error => data => {
  if (List.isList(error)) {
    error.forEach(x => handleCallback(data, x));
  } else {
    handleCallback(data);
  }
};
