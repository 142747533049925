import { List, Record } from 'immutable';
import { numberToCurrency } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const PRICING_TEMPLATE_TYPES = [
  'RecurringPaymentPlanTemplate',
  'PerSessionPricingTemplate',
];

class PricingAutomationCollection extends Record({
  automations: List(),
}) {
  constructor(obj = {}) {
    const automations = List(obj.automations);

    super(merge(obj, { automations }));
  }

  asText() {
    const minPrice = this.getMinimumPrice();
    if (minPrice === -1) {
      return null;
    }

    if (this.getNumberOfPricingOptions() > 1) {
      return `${numberToCurrency(minPrice, { fromCents: true })}+`;
    }
    return numberToCurrency(minPrice, { fromCents: true });
  }

  isEmpty() {
    return this.automations.isEmpty();
  }

  /*
   * Finds the minimum price from all options in this automation
   * (if the automation has a price option at all) and returns
   * that. If there are no pricing options, return -1 so that
   * comparisons can always be done against a number, without
   * having to consider null or undefined.
   */
  getMinimumPrice() {
    return this.automations.map(a => a.getMinimumPrice()).min();
  }

  getNumberOfPricingOptions() {
    return this.automations
      .filter(a => PRICING_TEMPLATE_TYPES.includes(a.template_type))
      .reduce((sum, a) => sum + a.template_parameters.size, 0);
  }
}

PricingAutomationCollection.create = automations =>
  new PricingAutomationCollection({ automations });

export default PricingAutomationCollection;
