import * as React from 'react';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/styles';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { Dropdown, Icon, Chip } from '@upperhand/playmaker';

import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';

import Attribute from 'contacts/index/components/_UserDetailAttribute.jsx';
import CardIcon from 'containers/client/settings/billing/components/CardIcon.jsx';
import PaymentMethodModalActions from 'containers/client/settings/paymentMethodModal/Actions';
import ClientBillingSettingsActions from 'containers/client/settings/billing/Actions';
import CreditExpander from 'contacts/index/components/CreditExpander.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import MembershipDescription from 'memberships/components/_Description.jsx';
import MembershipDiscountBenefits from 'memberships/components/_DiscountBenefits/_DiscountBenefits.jsx';
import MembershipExclusiveEventBenefits from 'memberships/components/_ExclusiveEventBenefits.jsx';
import MembershipFreeSessions from 'memberships/components/_FreeSessions.jsx';
import MembershipPricingInterval from 'shared/helpers/MembershipPricingInterval.jsx';
import MembershipViewingActions from 'memberships/actions/MembershipViewingActions.jsx';
import MailTo from 'shared/components/MailTo.jsx';
import Tel from 'shared/components/Tel.jsx';
import UnsignedWaiverIcon from 'shared/components/icons/UnsignedWaiver.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxJustify,
} from 'shared/components/FlexBox.jsx';
import { l, t, messageId } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

import { boldText, uhColors, uhLink } from 'shared/styles/uhStyles.jsx';

const styles = {
  expandMembershipDetails: {
    cursor: 'pointer',
    marginTop: 20,
    marginRight: -20,
  },
  unsignedWaiver: {
    marginTop: 17,
    marginBottom: 17,
  },
  signWaiver: {
    color: uhColors.blue,
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 600,
  },
  unsignedWaiverLabel: {
    color: uhColors.dialogTitle,
    fontSize: 15,
    marginLeft: 10,
    fontWeight: 600,
  },
  expandMembershipDetailsIconButton: {
    paddingTop: 0,
    marginTop: '-8px',
  },
  linkedUser: m =>
    m ? merge({ marginBottom: 5 }, uhLink) : { marginBottom: 5 },
  membershipText: {
    fontSize: 14,
    lineHeight: '22px',
  },
  expanderStyle: {
    outlineStyle: 'solid',
    outlineColor: 'rgb(224, 224, 224)',
    outlineWidth: 2,
    fontSize: 14,
  },
  link: {
    color: uhColors.blue,
    size: 13,
  },
  addPaymentMethodItem: {
    color: uhColors.blue,
  },
  defaultPaymentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  defaultPaymentTitle: {
    paddingRight: 8,
  },
};

const expandRotation = membershipUI =>
  membershipUI.detailsExpanded ? -90 : 90;

function AddressLine({ line }) {
  return line ? <div>{line}</div> : null;
}

function SubscriptionInfoActive({ membership, membershipSubscription, intl }) {
  return (
    <div>
      <FlexBoxJustify>
        <FormattedMessage
          id={messageId('.membership_active', __filenamespace)}
        />
        {!!membership.price && (
          <div>
            <FormattedCurrency value={membership.price} fromCents />
            <FormattedMessage
              id={messageId(
                MembershipPricingInterval[membership.interval_months],
                __filenamespace
              )}
            />
          </div>
        )}
      </FlexBoxJustify>
      {membership.interval_months > 0 && (
        <FlexBoxJustify>
          <FormattedMessage
            id={messageId('.membership_next_payment', __filenamespace)}
          />
          <div>
            {membershipSubscription.expires_at &&
              l(
                membershipSubscription.expires_at,
                'dates.shortNumericFullYear',
                intl
              )}
          </div>
        </FlexBoxJustify>
      )}
    </div>
  );
}

function SubscriptionInfoCancelled({ membershipSubscription, intl }) {
  return (
    <div>
      <FormattedMessage
        id={messageId('.membership_cancelled', __filenamespace)}
      />
      <FlexBoxJustify>
        <FormattedMessage
          id={messageId('.membership_expires', __filenamespace)}
        />
        <div>
          {l(
            membershipSubscription.expires_at,
            'dates.shortNumericFullYear',
            intl
          )}
        </div>
      </FlexBoxJustify>
    </div>
  );
}

function SubscriptionInfoSuspended({ membershipSubscription }) {
  return (
    <div>
      <span style={{ color: 'red' }}>
        <FormattedMessage
          id={messageId('.membership_suspended', __filenamespace)}
        />
      </span>
      <FlexBoxJustify>
        <FormattedMessage
          id={messageId(
            `${
              membershipSubscription.is_suspended
                ? '.membership_suspended'
                : '.membership_suspends'
            }`,
            __filenamespace
          )}
        />
        <div>
          {moment(membershipSubscription.suspended_at).format('MM/DD/YYYY')}
        </div>
      </FlexBoxJustify>
      {membershipSubscription.reactivate_on && (
        <FlexBoxJustify>
          <span style={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id={messageId('.membership_reactivates', __filenamespace)}
            />
          </span>
          <div>
            {moment(membershipSubscription.reactivate_on).format('MM/DD/YYYY')}
          </div>
        </FlexBoxJustify>
      )}
    </div>
  );
}

function SubscriptionInfoSuspendedOrCancelled({
  membershipSubscription,
  intl,
}) {
  return membershipSubscription.is_suspended ? (
    <SubscriptionInfoSuspended
      membershipSubscription={membershipSubscription}
      intl={intl}
    />
  ) : (
    <SubscriptionInfoCancelled
      membershipSubscription={membershipSubscription}
      intl={intl}
    />
  );
}

const useChipStyles = makeStyles({
  root: {
    backgroundColor: 'var(--color-error-red)',
    color: 'var(--color-white)',
    letterSpacing: 1,
  },
});

function UnpaidChip({ intl }) {
  const classes = useChipStyles();

  return (
    <Chip
      classes={classes}
      size="small"
      label={t('.unpaid', intl, __filenamespace)}
    />
  );
}

function MembershipDetails({
  intl,
  eventTypes,
  unpaid,
  retailCategories,
  membership,
  membershipSubscription,
  membershipUI,
}) {
  return (
    <Attribute
      style={styles.membershipText}
      label={t('.membership', intl, __filenamespace)}
    >
      <div
        className="membership-name"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div style={boldText}>{membership.name}</div>
        {unpaid && <UnpaidChip intl={intl} />}
      </div>
      {membershipSubscription.is_cancelled ||
      membershipSubscription.is_suspended ? (
        <SubscriptionInfoSuspendedOrCancelled
          membershipSubscription={membershipSubscription}
          intl={intl}
        />
      ) : (
        <SubscriptionInfoActive
          membership={membership}
          membershipSubscription={membershipSubscription}
          intl={intl}
        />
      )}
      <FlexBoxJustify
        style={styles.expandMembershipDetails}
        onClick={MembershipViewingActions.toggleExpanded}
      >
        <FormattedMessage
          id={messageId('.membership_details', __filenamespace)}
        />
        <IconButton
          style={styles.expandMembershipDetailsIconButton}
          disableRipple
        >
          <ChevronLeftIcon
            sx={{
              color: uhColors.blue,
              transform: `rotate(${expandRotation(membershipUI)}deg)`,
            }}
          />
        </IconButton>
      </FlexBoxJustify>
      {membershipUI.detailsExpanded && (
        <div style={{ marginBottom: 20 }}>
          <MembershipDescription
            membership={membership}
            style={{ marginBottom: 24 }}
          />
          <MembershipDiscountBenefits
            membership={membership}
            eventTypes={eventTypes}
            retailCategories={retailCategories}
          />
          <MembershipExclusiveEventBenefits
            membership={membership}
            eventTypes={eventTypes}
          />
          <MembershipFreeSessions
            membership={membership}
            style={merge(styles.sectionSpacing, styles.freeSessions)}
          />
        </div>
      )}
      <Divider />
    </Attribute>
  );
}

function UnsignedWaiver() {
  return (
    <>
      <FlexBoxJustify style={styles.unsignedWaiver}>
        <FlexBoxCenter>
          <UnsignedWaiverIcon />
          <span style={styles.unsignedWaiverLabel}>
            <FormattedMessage
              id={messageId('.no_signed_waiver', __filenamespace)}
            />
          </span>
        </FlexBoxCenter>
        <FlexBoxCenter>
          <span style={styles.signWaiver}>
            {/* <FormattedMessage id={messageId('.sign_waiver', __filenamespace)} /> */}
          </span>
        </FlexBoxCenter>
      </FlexBoxJustify>
      <Divider />
    </>
  );
}

function UserDetails({
  eventTypes,
  defaultPaymentMethod,
  retailCategories,
  membership,
  membershipSubscription,
  membershipUI,
  onManageMethods,
  paymentMethods,
  user,
  intl,
}) {
  const handleDefaultPaymentMethodChange = e => {
    const { value } = e.target;
    if (value === 'new') {
      PaymentMethodModalActions.openModal();
    } else {
      ClientBillingSettingsActions.setDefault(value);
    }
  };

  return (
    <div>
      {user.isClient() && <CreditExpander clientId={user.id} />}

      {!user.get('has_accepted_terms') && !user.get('managed_by_id') && (
        <UnsignedWaiver />
      )}

      {membership && membership.id && (
        <MembershipDetails
          unpaid={user.has_subscription_balance_owed}
          intl={intl}
          eventTypes={eventTypes}
          retailCategories={retailCategories}
          membership={membership}
          membershipSubscription={membershipSubscription}
          membershipUI={membershipUI}
        />
      )}
      {user.isClient() && user.get('login_enabled') && currentUser().isStaff() && (
        <Attribute
          label={
            <div
              data-tip
              data-for="dropdown_icon"
              style={styles.defaultPaymentContainer}
            >
              <span style={styles.defaultPaymentTitle}>
                {t('.default_payment', intl, __filenamespace)}
              </span>
              <Icon size="small" name="info" />
            </div>
          }
        >
          <ReactTooltip
            id="dropdown_icon"
            effect="solid"
            className="uh-tooltip"
          >
            <div style={{ maxWidth: 250, marginLeft: 75 }}>
              {t('.default_payment_info', intl, __filenamespace)}
            </div>
          </ReactTooltip>
          <Dropdown
            rounded={false}
            fullWidth
            outline
            items={[
              ...(paymentMethods.size >= 1
                ? paymentMethods
                    .map(pm => ({
                      label: (
                        <FlexBox>
                          <CardIcon
                            brand={pm.get('info').brand}
                            style={{
                              verticalAlign: 'middle',
                              marginRight: 5,
                            }}
                          />
                          <span
                            style={{
                              display: 'inline-block',
                              maxWidth: 200,
                              overflow: 'hidden',
                              verticalAlign: 'middle',
                              textOverflow: 'ellipsis',
                              marginRight: 5,
                              marginTop: 2,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {pm.get('info').name}
                          </span>
                          <span style={{ marginTop: 2 }}>
                            {pm.get('info').last4}
                          </span>
                        </FlexBox>
                      ),
                      value: pm.get('id'),
                    }))
                    .toArray()
                : [
                    {
                      label: user.access_to_vault
                        ? t('.no_cards', intl, __filenamespace)
                        : t('.access_denied', intl, __filenamespace),
                      value: 'empty',
                    },
                  ]),
              {
                label: (
                  <span style={styles.addPaymentMethodItem}>
                    {t('.add_method', intl, __filenamespace)}
                  </span>
                ),
                value: 'new',
              },
            ]}
            value={
              defaultPaymentMethod ? defaultPaymentMethod.get('id') : 'empty'
            }
            onChange={handleDefaultPaymentMethodChange}
            placeholder={t('.select_method', intl, __filenamespace)}
            disabled={!user.access_to_vault}
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            style={merge(styles.link, {
              marginTop: 8,
              display: 'inline-block',
            })}
            onClick={onManageMethods}
          >
            <FormattedMessage
              id={messageId('.manage_methods', __filenamespace)}
            />
          </a>
        </Attribute>
      )}
      <Attribute label={t('.phone', intl, __filenamespace)}>
        {user.phone && <Tel number={user.phone} />}
      </Attribute>
      <Attribute label={t('.email', intl, __filenamespace)}>
        {user.email && <MailTo email={user.email} />}
      </Attribute>
      <Attribute label={t('.address', intl, __filenamespace)}>
        {user.address && (
          <div>
            <AddressLine line={user.address.line_1} />
            <AddressLine line={user.address.line_2} />
            <AddressLine line={user.address.line_3} />
            {user.address.city}, {user.address.state}&nbsp;
            {user.address.postal_code}
          </div>
        )}
      </Attribute>
      <Attribute label={t('.age_birthday', intl, __filenamespace)}>
        {user.age && user.date_of_birth && (
          <div>
            {user.age()}
            &nbsp;&middot;&nbsp;
            <FormattedDate
              value={user.date_of_birth}
              month="long"
              day="numeric"
              year="numeric"
            />
          </div>
        )}
      </Attribute>
    </div>
  );
}

export default injectIntl(UserDetails);
