import * as React from 'react';
import { Grid } from '@upperhand/playmaker';

import { smallScreen } from 'shared/utils/DOMUtils';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import EmptyState from './EmptyState.jsx';
import TableHeader, { TableHeaderTeams } from './TableHeader.jsx';
import ClientCard from './ClientCard.jsx';

function Table({ clientIds, eventId, filtersPresent, isTeamEvent }) {
  const isClient = currentUser().isClient();
  return (
    <div>
      <Grid item container spacing={1}>
        {/* Add Hidden from MUI 4.0 to Playmaker and use it instead. */}
        {/* 960 is equivilent to the md breakpoint in PM. */}
        {!isClient && !smallScreen(959) && (
          <Grid item md={12}>
            <TableHeader />
          </Grid>
        )}
        {isClient && isTeamEvent && (
          <Grid item xs={12}>
            <TableHeaderTeams />
          </Grid>
        )}
        {clientIds.map(id => (
          <Grid item key={id} xs={12}>
            <ClientCard
              clientId={id}
              eventId={eventId}
              isTeamEvent={isTeamEvent}
            />
          </Grid>
        ))}
        {clientIds.isEmpty() && (
          <Grid item xs={12}>
            <EmptyState
              filtersPresent={filtersPresent}
              isTeamEvent={isTeamEvent}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Table;
