import AccountingCodeCreationActions from 'shared/actions/accounting_codes/AccountingCodeCreationActions';
import AccountingCodeEditingStoreInterface from 'shared/stores/accounting_codes/AccountingCodeEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  AccountingCodeEditingStoreInterface,
  'AccountingCodeCreationStore',
  {
    actions: AccountingCodeCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
