import { Map, Set, List } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { ClientSource, SessionSource } from 'sources';

import { SessionDataStore } from 'dataStores';
import { byFields } from 'shared/utils/ImmutableUtils.js';
import CheckInActions from './actions';

class CheckInStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      allSelected: CheckInActions.allSelected,
      attendeeChecked: CheckInActions.attendeeChecked,
      searchAttendees: CheckInActions.filterApplied,
      checkInSuccess: CheckInActions.checkInSuccess,
      checkInError: CheckInActions.checkInError,
      checkOutSuccess: CheckInActions.checkOutSuccess,
      checkOutError: CheckInActions.checkOutError,
      clientListSuccess: CheckInActions.clientListSuccess,
      clientListError: CheckInActions.clientListError,
      confirmChanges: CheckInActions.confirmChanges,
      mounted: CheckInActions.mounted,
    });
  }

  checkIsLoadingData() {
    return this.clientsLoading || this.checkInUpdate || this.checkOutUpdate;
  }

  reset() {
    this.session = Map();
    this.clientIds = Set();
    this.registrationIds = Set();
    // variable where we store originally sorted by first name client ids
    this.initialClientsIds = Set();
    this.checkedInClientIds = Set();
    this.clientsToCheckIn = Set();
    this.clientsToCheckOut = Set();
    this.clients = List();
    this.clearFilters();
    this.dirty = false;
    this.checkInUpdate = false;
    this.checkOutUpdate = false;
  }

  mounted(sessionId) {
    this.reset();
    if (sessionId) {
      const { sessions } = SessionDataStore.getState();

      this.session = sessions.get(sessionId);
      this.clientIds = this.session.get('client_ids');
      // sorting registrationIds will prevent attendees list from reordering
      this.registrationIds = this.session.get('registration_ids').sort();
      this.checkedInClientIds = this.session.getIn([
        'attendance_map',
        'checked_in',
      ]);
    }
    this.applyFilters();
    if (this.clientIds.size) {
      this.listClients();
    }
  }

  listClients(page = 1) {
    this.clientsLoading = true;
    this.isLoading = this.checkIsLoadingData();
    ClientSource.list({
      params: { ids: this.clientIds.toJS(), per_page: 50, page },
      success: CheckInActions.clientListSuccess,
      error: CheckInActions.clientListError,
    });
  }

  updated(session) {
    this.session = session;
    const sessionClientIds = this.session.get('client_ids');
    this.clientIds = this.initialClientsIds.filter(id =>
      sessionClientIds.includes(id)
    );
    this.checkedInClientIds = this.session.getIn([
      'attendance_map',
      'checked_in',
    ]);
    this.applyFilters();
    this.markDirty();
  }

  checkInSuccess(session) {
    this.clientsToCheckIn = Set();
    this.updated(session);
    this.checkInUpdate = false;
    this.isLoading = this.checkIsLoadingData();
  }

  checkInError() {
    this.checkInUpdate = false;
    this.isLoading = this.checkIsLoadingData();
  }

  checkOutSuccess(session) {
    this.clientsToCheckOut = Set();
    this.updated(session);
    this.checkOutUpdate = false;
    this.isLoading = this.checkIsLoadingData();
  }

  checkOutError() {
    this.checkOutUpdate = false;
    this.isLoading = this.checkIsLoadingData();
  }

  clientListSuccess({ clients, page, totalCount }) {
    this.initialClientsIds = this.initialClientsIds.union(
      clients.map(client => client.id)
    );
    this.clientIds = this.clientIds.union(this.initialClientsIds);
    this.clients = this.clients.concat(clients);

    if (totalCount > this.clients.size) {
      this.listClients(page + 1);
      return;
    }
    this.clientsLoading = false;
    this.isLoading = this.checkIsLoadingData();
  }

  clientListError() {
    this.isLoading = false;
  }

  searchAttendees(string) {
    let filteredClients = this.clients;

    if (string) {
      const qr = string.match(
        /^client_id=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/i
      );
      const uuid = qr && qr[1];
      filteredClients = uuid
        ? this.clients.filter(byFields(uuid, ['uuid']))
        : this.clients.filter(byFields(string, ['first_name', 'last_name']));
    }
    this.clientIds = filteredClients.map(r => r.id).toSet();
    this.applyFilters();
    this.filterString = string;
  }

  applyFilters() {
    this.filteredCheckedInClientIds = this.checkedInClientIds.filter(
      id => this.clientIds.includes(id),
      Set()
    );
    this.filteredClientsToCheckIn = this.clientsToCheckIn.filter(
      id => this.clientIds.includes(id),
      Set()
    );
    this.filteredClientsToCheckOut = this.clientsToCheckOut.filter(
      id => this.clientIds.includes(id),
      Set()
    );
  }

  clearFilters() {
    this.filterString = '';
    this.filteredCheckedInClientIds = Set();
    this.filteredClientsToCheckIn = Set();
    this.filteredClientsToCheckOut = Set();
  }

  markDirty() {
    this.dirty =
      !this.clientsToCheckIn.isEmpty() || !this.clientsToCheckOut.isEmpty();
  }

  attendeeChecked(id) {
    const booked = this.session.getIn(['attendance_map', 'booked']);
    const noShow = this.session.getIn(['attendance_map', 'no_show']);

    if (booked.includes(id)) {
      if (this.checkedInClientIds.includes(id)) {
        this.checkedInClientIds = this.checkedInClientIds.delete(id);
        this.clientsToCheckIn = this.clientsToCheckIn.delete(id);
      } else {
        this.checkedInClientIds = this.checkedInClientIds.add(id);
        this.clientsToCheckIn = this.clientsToCheckIn.add(id);
      }
    } else if (noShow.includes(id)) {
      if (this.checkedInClientIds.includes(id)) {
        this.checkedInClientIds = this.checkedInClientIds.delete(id);
        this.clientsToCheckIn = this.clientsToCheckIn.delete(id);
      } else {
        this.checkedInClientIds = this.checkedInClientIds.add(id);
        this.clientsToCheckIn = this.clientsToCheckIn.add(id);
      }
    } else if (this.checkedInClientIds.includes(id)) {
      this.checkedInClientIds = this.checkedInClientIds.delete(id);
      this.clientsToCheckOut = this.clientsToCheckOut.add(id);
    } else {
      this.checkedInClientIds = this.checkedInClientIds.add(id);
      this.clientsToCheckOut = this.clientsToCheckOut.delete(id);
    }

    this.filteredCheckedInClientIds = this.checkedInClientIds;
    this.applyFilters();
    this.markDirty();
  }

  allSelected() {
    const booked = this.session.getIn(['attendance_map', 'booked']);
    const checkedIn = this.session.getIn(['attendance_map', 'checked_in']);

    if (this.clientIds.size === this.checkedInClientIds.size) {
      this.checkedInClientIds = checkedIn;
      this.clientsToCheckIn = Set();
    } else {
      this.checkedInClientIds = checkedIn.union(booked);
      this.clientsToCheckIn = booked;
      this.clientsToCheckOut = Set();
    }
    this.applyFilters();
    this.markDirty();
  }

  confirmChanges() {
    if (this.filteredClientsToCheckIn.size) {
      this.checkInUpdate = true;
      this.isLoading = this.checkIsLoadingData();
      SessionSource.checkIn({
        id: this.session.id,
        params: {
          client_ids: this.filteredClientsToCheckIn.toJS(),
        },
        success: CheckInActions.checkInSuccess,
        error: CheckInActions.checkInError,
      });
    }
    if (this.filteredClientsToCheckOut.size) {
      this.checkOutUpdate = true;
      this.isLoading = this.checkIsLoadingData();
      SessionSource.checkOut({
        id: this.session.id,
        params: {
          client_ids: this.filteredClientsToCheckOut.toJS(),
        },
        success: CheckInActions.checkOutSuccess,
        error: CheckInActions.checkOutError,
      });
    }
  }
}

export default alt.createStore(CheckInStore, 'CheckInStore');
