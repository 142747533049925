import InventoryAdjustmentDialogActions from 'retail/actions/InventoryAdjustmentDialogActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import FilteredSelection from 'shared/records/FilteredSelection.js';
import StoreActions from 'shared/actions/StoreActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import Variant from 'shared/records/Variant.js';
import PurchaseOrder from 'retail/records/PurchaseOrder.js';
import PurchaseOrderItem from 'retail/records/PurchaseOrderItem.js';
import CustomerUser from 'shared/records/Customer.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { List, Map } from 'immutable';
import { list, post, patch } from 'sources/VariantSource.js';

export const ADJUSTMENT_REASONS = ['.damaged', '.stolen', '.input_error'];

class InventoryAdjustmentDialogStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      openDialog: InventoryAdjustmentDialogActions.openDialog,
      closeDialog: InventoryAdjustmentDialogActions.closeDialog,
      reset: [
        StoreActions.prepareForReuse,
        InventoryAdjustmentDialogActions.reset,
      ],
      openDialogForOrder: InventoryAdjustmentDialogActions.openDialogForOrder,
      variantsList: InventoryAdjustmentDialogActions.variantsList,
      variantsListSuccess: InventoryAdjustmentDialogActions.variantsListSuccess,
      variantsListError: InventoryAdjustmentDialogActions.variantsListError,
      updateFilter: InventoryAdjustmentDialogActions.updateFilter,
      localUpdatePurchaseOrder:
        InventoryAdjustmentDialogActions.localUpdatePurchaseOrder,
      localUpdatePurchaseOrderItem:
        InventoryAdjustmentDialogActions.localUpdatePurchaseOrderItem,
      processPurchaseOrder:
        InventoryAdjustmentDialogActions.processPurchaseOrder,
      createPurchaseOrder: InventoryAdjustmentDialogActions.createPurchaseOrder,
      createPurchaseOrderSuccess:
        InventoryAdjustmentDialogActions.createPurchaseOrderSuccess,
      createPurchaseOrderError:
        InventoryAdjustmentDialogActions.createPurchaseOrderError,
      updatePurchaseOrder: InventoryAdjustmentDialogActions.updatePurchaseOrder,
      updatePurchaseOrderSuccess:
        InventoryAdjustmentDialogActions.updatePurchaseOrderSuccess,
      updatePurchaseOrderError:
        InventoryAdjustmentDialogActions.updatePurchaseOrderError,
      onProductClear: InventoryAdjustmentDialogActions.onProductClear,
      purchaseOrderItemList:
        InventoryAdjustmentDialogActions.purchaseOrderItemList,
      purchaseOrderItemListSuccess:
        InventoryAdjustmentDialogActions.purchaseOrderItemListSuccess,
      purchaseOrderItemListError:
        InventoryAdjustmentDialogActions.purchaseOrderItemListError,
      enableEditMode: InventoryAdjustmentDialogActions.enableEditMode,
      updatePurchaseOrderItems:
        InventoryAdjustmentDialogActions.updatePurchaseOrderItems,
      updatePurchaseOrderItemsSuccess:
        InventoryAdjustmentDialogActions.updatePurchaseOrderItemsSuccess,
      updatePurchaseOrderItemsError:
        InventoryAdjustmentDialogActions.updatePurchaseOrderItemsError,
      createInventoryAdjustments:
        InventoryAdjustmentDialogActions.createInventoryAdjustments,
    });
  }

  reset() {
    // Appears to be breaking the app from building. Commented it out because I'm not wh
    // this.waitFor(RetailCategoryListingStore);
    const { retailCategories } = RetailCategoryListingStore.getState();

    this.isOpen = false;
    this.isChanged = false;
    this.isLoading = false;

    this.page = 1;
    this.perPage = 50;
    this.totalCount = 0;

    this.filters = Map({
      search: '',
      retail_category_ids: new FilteredSelection({
        source: retailCategories.map(item => ({
          key: item.id,
          value: item.name,
        })),
      }),
      variant_ids: List(),
    });
    this.disable = true;
    this.productId = null;
    this.productName = null;

    this.variants = List();
    this.editedOrderItems = List();
    this.purchaseOrder = new PurchaseOrder();
    this.isInventory = true;
    this.isEditMode = true;
  }

  openDialog({ product = {} }) {
    this.productId = product.id;
    this.productName = product.name;
    this.isOpen = true;
    this.isInventory = true;
    this.isEditMode = true;
    this.variantsList();
  }

  openDialogForOrder({
    purchaseOrder = new PurchaseOrder({}),
    customerUser = new CustomerUser({}),
  }) {
    this.reset();
    this.purchaseOrder = purchaseOrder;
    this.customerUser = customerUser;
    this.isOpen = true;
    this.isInventory = false;
    this.isEditMode = false;
    this.purchaseOrderItemList();
  }

  closeDialog() {
    this.isOpen = false;
    this.reset();
  }

  onProductClear() {
    this.productId = null;
    this.productName = '';
    this.disable = false;
    this.updateFilter(['search', '']);

    this.variantsList();
  }

  variantsList(showLoading = true) {
    this.isLoading = showLoading;
    const params = {
      page: this.page,
      per_page: this.perPage,
      product_id: this.productId,
      search: this.filters.get('search').trim(),
      retail_category_ids: this.filters.get('retail_category_ids').toServer(),
    };

    list({
      params,
      success: InventoryAdjustmentDialogActions.variantsListSuccess,
      error: InventoryAdjustmentDialogActions.variantsListError,
    });
  }

  variantsListForPurchaseOrder() {
    if (this.filters.get('variant_ids').size > 0) {
      const params = {
        page: 1,
        per_page: this.perPage,
        ids: this.filters.get('variant_ids').toArray(),
      };

      list({
        params,
        success: InventoryAdjustmentDialogActions.variantsListSuccess,
        error: InventoryAdjustmentDialogActions.variantsListError,
      });
    } else {
      this.isLoading = false;
    }
  }

  variantsListSuccess({ variants, page, perPage, totalCount }) {
    // Merge with new variants and remove already edited items
    const fetchedVariants = variants
      .filter(item => this.variants.filter(variant => item.id === variant.id))
      .map(item => new Variant(item));

    this.variants =
      page === 1 ? fetchedVariants : this.variants.concat(fetchedVariants);

    if (this.isInventory) {
      this.page = page + 1;
      this.perPage = perPage;
      this.totalCount = totalCount;
    }

    this.isLoading = false;
  }

  variantsListError({ ...args }) {
    this.isLoading = false;
    this.notifyError('error listing variants', args);
  }

  updateFilter([filter, value]) {
    this.isLoading = true;
    this.filters = this.filters.set(filter, value);
    this.page = 1;
    this.totalCount = 0;
    if (this.isInventory) {
      this.variantsList();
    } else {
      this.purchaseOrder = this.purchaseOrder.set(
        'purchase_order_items',
        List()
      );
      this.purchaseOrderItemList();
    }
  }

  createInventoryAdjustments(variants) {
    let newOrderItems = this.purchaseOrder.purchase_order_items.map(item => {
      const selectedVariant = variants.find(
        varaint => varaint.id === item?.toServer()?.variant_id
      );
      return {
        variant_id: item?.toServer()?.variant_id,
        expected_quantity: Number(selectedVariant.live_qty) || 0,
        actual_quantity: Number(item?.toServer()?.actual_quantity),
        adjustment_reason:
          item?.toServer()?.adjusted_reason === 'input error'
            ? 'input_error'
            : item?.toServer()?.adjusted_reason,
      };
    });
    newOrderItems = newOrderItems.toArray();
    post({
      newOrderItems,
      success: InventoryAdjustmentDialogActions.createPurchaseOrderSuccess,
      error: InventoryAdjustmentDialogActions.createPurchaseOrderError,
    });
  }

  localUpdatePurchaseOrder([key, value]) {
    this.purchaseOrder = this.purchaseOrder.set(key, value);
    this.isChanged = true;
  }

  localUpdatePurchaseOrderItem([purchaseOrderItem, variant, key, value]) {
    const orderItem = purchaseOrderItem.set(key, value);
    const index = this.editedOrderItems.findIndex(
      item => item.id === orderItem.id
    );
    if (index >= 0) {
      this.editedOrderItems = this.editedOrderItems.delete(index);
    }
    this.editedOrderItems = this.editedOrderItems.push(orderItem);
    this.purchaseOrder = this.purchaseOrder.setItem({
      variant,
      key,
      value,
    });
    this.isChanged = this.isChanged || this.editedOrderItems.size > 0;
  }

  processPurchaseOrder() {
    if (this.isInventory) {
      this.createPurchaseOrder();
    } else {
      this.updatePurchaseOrder();
    }
  }

  createPurchaseOrder(showLoading = true) {
    this.purchaseOrder = this.purchaseOrder.validate();
    if (this.purchaseOrder.isValid()) {
      this.isLoading = showLoading;
      uhApiClient.post({
        url: 'purchase_orders',
        data: JSON.stringify({
          attributes: this.purchaseOrder.toServer(),
          immediate_receipt: true,
        }),
        success: InventoryAdjustmentDialogActions.createPurchaseOrderSuccess,
        error: InventoryAdjustmentDialogActions.createPurchaseOrderError,
      });
    }
  }

  createPurchaseOrderSuccess() {
    this.isLoading = false;
    MessageWindowActions.addMessage.defer('Inventory saved successfully!');
    this.closeDialog();
  }

  createPurchaseOrderError({ ...args }) {
    this.isLoading = false;
    this.notifyError('Error during saving inventory', args);
  }

  updatePurchaseOrder(showLoading = true) {
    this.purchaseOrder = this.purchaseOrder.validate();
    if (this.purchaseOrder.isValid()) {
      this.isLoading = showLoading;
      patch({
        id: this.purchaseOrder.get('id'),
        params: {
          attributes: this.purchaseOrder.toServerForUpdate(),
          immediate_receipt: true,
        },
        success: InventoryAdjustmentDialogActions.updatePurchaseOrderSuccess,
        error: InventoryAdjustmentDialogActions.updatePurchaseOrderError,
      });
    }
  }

  updatePurchaseOrderSuccess() {
    MessageWindowActions.addMessage.defer('Purchase order saved successfully!');
    this.updatePurchaseOrderItems();
    if (this.editedOrderItems.size === 0) {
      this.isLoading = false;
      this.isEditMode = false;
    }
  }

  updatePurchaseOrderError({ ...args }) {
    this.isLoading = false;
    this.notifyError('Error during updating purchase order', args);
  }

  purchaseOrderItemList(showLoading = true) {
    this.isLoading = showLoading;
    const params = {
      page: this.page,
      per_page: this.perPage,
      purchase_order_ids: this.purchaseOrder.get('id'),
      search: this.filters.get('search'),
      retail_category_ids: this.filters.get('retail_category_ids').toServer(),
    };
    list({
      params,
      success: InventoryAdjustmentDialogActions.purchaseOrderItemListSuccess,
      error: InventoryAdjustmentDialogActions.purchaseOrderItemListError,
    });
  }

  purchaseOrderItemListSuccess({
    purchase_order_items: serverPurchaseOrderItems,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.filters = this.filters.set(
      'variant_ids',
      List((serverPurchaseOrderItems || []).map(item => item.variant_id))
    );
    const purchaseOrderItems = this.purchaseOrder
      .get('purchase_order_items')
      .concat(
        List(
          (serverPurchaseOrderItems || []).map(item => {
            const index = this.editedOrderItems.findIndex(
              editedOrderItem => editedOrderItem.id === item.id
            );
            if (index >= 0) {
              return this.editedOrderItems.get(index);
            }
            return new PurchaseOrderItem(item);
          })
        )
      );

    this.purchaseOrder = this.purchaseOrder.set(
      'purchase_order_items',
      purchaseOrderItems
    );
    this.variantsListForPurchaseOrder();
    const editedOrderItemsIds = this.editedOrderItems.map(i => i.id).toSet();
    const editedItemsFromUpdatedList = purchaseOrderItems.filter(item =>
      editedOrderItemsIds.has(item.id)
    );
    // after listing purchase order items we want to check is there are edited item
    // yes = enable updating, no - dsiable updating
    this.isChanged = this.isChanged && editedItemsFromUpdatedList.size;
    this.page = page + 1;
    this.perPage = perPage;
    this.totalCount = totalCount;
  }

  purchaseOrderItemListError({ ...args }) {
    this.isLoading = false;
    this.notifyError('error listing purchase order items', args);
  }

  enableEditMode() {
    this.isEditMode = true;
  }

  updatePurchaseOrderItems(showLoading = true) {
    if (this.editedOrderItems.size > 0) {
      this.editedOrderItems = showLoading;

      uhApiClient.patch({
        url: 'purchase_order_items',
        data: JSON.stringify({
          purchase_order_items: this.purchaseOrder
            .get('purchase_order_items')
            .map(item => item.toServerForUpdate())
            .toArray(),
          immediate_receipt: true,
        }),
        success:
          InventoryAdjustmentDialogActions.updatePurchaseOrderItemsSuccess,
        error: InventoryAdjustmentDialogActions.updatePurchaseOrderItemsError,
      });
    }
  }

  updatePurchaseOrderItemsSuccess() {
    MessageWindowActions.addMessage.defer(
      'Purchase order items saved successfully!'
    );
    this.isLoading = false;
    this.editedOrderItems = List();
    this.isEditMode = false;
  }

  updatePurchaseOrderItemsError({ ...args }) {
    this.isLoading = false;
    this.notifyError('Error during updating purchase order items', args);
  }
}

export default alt.createStore(
  InventoryAdjustmentDialogStore,
  'InventoryAdjustmentDialogStore'
);
