import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function TriangleCheckmark({ color, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <g
        id="Memberships"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Memberships-Page---Drawer"
          transform="translate(-401.000000, -163.000000)"
        >
          <g id="Group" transform="translate(401.000000, 159.000000)">
            <g id="Group-2" transform="translate(0.000000, 4.000000)">
              <polygon id="Rectangle-3" fill={color} points="0 0 30 0 0 30" />
              <path
                d="M9.69084821,13.2868304 L15.8582589,7.11941964 C15.9854917,6.99218686 16.0491071,
                 6.84151873 16.0491071,6.66741071 C16.0491071,6.4933027 15.9854917,6.34263456
                 15.8582589,6.21540179 L14.8337054,5.19084821 C14.7064726,5.06361544 14.5558044,5
                 14.3816964,5 C14.2075884,5 14.0569203,5.06361544 13.9296875,5.19084821 L9.23883929,
                 9.88169643 L7.11941964,7.76227679 C6.99218686,7.63504401 6.84151873,7.57142857
                 6.66741071,7.57142857 C6.4933027,7.57142857 6.34263456,7.63504401 6.21540179,
                 7.76227679 L5.19084821,8.78683036 C5.06361544,8.91406314 5,9.06473127 5,9.23883929
                 C5,9.4129473 5.06361544,9.56361544 5.19084821,9.69084821 L8.78683036,13.2868304
                 C8.91406314,13.4140631 9.06473127,13.4776786 9.23883929,13.4776786 C9.4129473,
                 13.4776786 9.56361544,13.4140631 9.69084821,13.2868304 Z"
                id="Path"
                fill="var(--color-white)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

TriangleCheckmark.defaultProps = {
  color: 'var(--color-green)',
};
export default TriangleCheckmark;
