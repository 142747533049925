export default alt.generateActions(
  'updateStore',
  'fetch',
  'fetchSuccess',
  'fetchError',
  'update',
  'updateSuccess',
  'updateError',
  'register',
  'registerSuccess',
  'registerError',
  'createSuccess',
  'createError'
);
