import * as React from 'react';
import { Card, Grid, Typography, Button } from '@upperhand/playmaker';
import CreditListDrawerActions from 'containers/creditListDrawer/Actions';
import altContainer from 'shared/hocs/altContainer.jsx';
import { ClientDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils';

function CreditInfo({
  intl,
  clientId,
  totalCredits,
  clientDataStore: { clients },
}) {
  const client = clients.get(clientId);

  if (!client) return null;

  return (
    <Card
      classes={{
        root: 'credit-info',
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" className="credit-info__header">
            {t('.header', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3" className="credit-info__value">
            {totalCredits === Infinity
              ? t('.unlimited', intl, __filenamespace)
              : totalCredits}
          </Typography>
        </Grid>
        <div className="credit-info__open-drawer-btn">
          <Button
            size="1x"
            type="tertiary"
            rounded
            onClick={() => CreditListDrawerActions.openDrawer({ clientId })}
          >
            {t('.view', intl, __filenamespace)}
          </Button>
        </div>
      </Grid>
    </Card>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(CreditInfo);
