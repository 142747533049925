import { Record } from 'immutable';
import moment from 'moment-timezone';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import { getValue, merge } from 'shared/utils/ObjectUtils.jsx';

const prepareValue = (key, value) => {
  const tmpValue = getValue(value);
  if (key === 'quantity') {
    return parseInt(tmpValue || 0, 0);
  }
  if (key === 'unit_cost') {
    return parseFloat(tmpValue || 0);
  }
  if (key === 'actual_quantity') {
    return parseFloat(tmpValue || 0);
  }
  if (key === 'expected_quantity') {
    return parseFloat(tmpValue || 0);
  }
  return tmpValue;
};

class PurchaseOrderItem extends Record({
  id: null,
  purchase_order_id: null,
  variant_id: 0,
  quantity: 0,
  adjusted_reason: '',
  actual_quantity: 0,
  expected_quantity: 0,
  unit_cost: 0,
  created_at: null,
  updated_at: null,
  created_by_id: null,
  updated_by_id: null,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    const createdAt = obj.created_at ? moment(obj.created_at) : null;
    const updatedAt = obj.updated_at ? moment(obj.updated_at) : null;

    super(
      merge(obj, {
        quantity: prepareValue('quantity', obj.quantity),
        unit_cost: prepareValue('unit_cost', obj.unit_cost),
        adjusted_reason: prepareValue('adjusted_reason', obj.adjusted_reason),
        actual_quantity: prepareValue('actual_quantity', obj.actual_quantity),
        expected_quantity: prepareValue(
          'expected_quantity',
          obj.expected_quantity
        ),
        created_at: createdAt,
        updated_at: updatedAt,
      })
    );
  }

  set(key, value) {
    return super.set(key, prepareValue(key, value));
  }

  isEmpty() {
    return (
      this.quantity === 0 &&
      this.unit_cost === 0 &&
      this.expected_quantity === 0 &&
      this.actual_quantity === 0 &&
      this.adjusted_reason === ''
    );
  }

  isValid() {
    return this.errors.isEmpty();
  }

  validate() {
    let errors = new FieldErrors();

    if (this.quantity <= 0 && this.unit_cost > 0) {
      errors = errors.add(
        'quantity',
        'records.PurchaseOrderItem.errors.notEmpty'
      );
    }
    if (this.unit_cost <= 0 && this.quantity > 0) {
      errors = errors.add(
        'unit_cost',
        'records.PurchaseOrderItem.errors.notEmpty'
      );
    }

    return this.set('errors', errors);
  }

  toServer() {
    const data = this.toJS();

    delete data.errors;
    return data;
  }

  toServerForUpdate() {
    const data = this.toJS();
    return {
      id: data.id,
      quantity: data.quantity,
      expected_quantity: data.expected_quantity,
      actual_quantity: data.actual_quantity,
      adjusted_reason: data.adjusted_reason,
      unit_cost: data.unit_cost,
    };
  }
}

export default PurchaseOrderItem;
