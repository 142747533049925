import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';
import CardIcon from 'containers/client/settings/billing/components/CardIcon.jsx';

import { FlexBox } from 'shared/components/FlexBox.jsx';

function SavedCardMessage({ cardRecord }) {
  return (
    <FlexBox style={{ margin: '10px 0px' }}>
      <span style={{ fontWeight: 'bold' }}>
        <CardIcon
          brand={cardRecord.info.brand}
          style={{
            height: 32,
            marginTop: -2,
            verticalAlign: 'middle',
            width: 48,
            marginRight: 10,
          }}
        />
        {cardRecord.default && (
          <span>
            <FormattedMessage
              id={messageId('.default_card', __filenamespace)}
            />
            <span>&nbsp;&ndash;&nbsp;</span>
          </span>
        )}
      </span>
      <span
        style={{
          marginTop: 7,
          display: 'inline-block',
          maxWidth: 150,
          overflow: 'hidden',
          verticalAlign: 'middle',
          textOverflow: 'ellipsis',
          marginRight: 5,
          whiteSpace: 'nowrap',
        }}
      >
        {cardRecord.info.name}
      </span>
      <span style={{ marginTop: 7 }}>{cardRecord.info.last4}</span>
    </FlexBox>
  );
}

export default SavedCardMessage;
