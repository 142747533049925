import TeamDetailPage from 'containers/events/admin/admin_page/TeamDetailPage.jsx';
import * as React from 'react';
import { Route } from 'react-router-dom';

import Page from 'routes/Page.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';

import {
  isAdmin,
  isStaffAdmin,
  isClient,
  isStaff,
  isUnauthorized,
  isManager,
  isCoach,
} from 'routes/helpers/routePermissions';
import TeamMgmtEditing from 'team_mgmt/editing/components/TeamMgmtEditing.jsx';

const teamsRoutes = () => (
  <>
    <Route
      path=":customer_id/teams/new"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <TeamMgmtEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/teams/:teamSlug/edit"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isManager]}
        >
          <Page>
            <TeamMgmtEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/teams/:eventSlug"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaff, isCoach, isClient, isUnauthorized]}
        >
          <Page>
            <TeamDetailPage isClient />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/teams/:eventSlug/admin"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaff, isCoach]}>
          <Page>
            <TeamDetailPage />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
  </>
);

export default teamsRoutes;
