import { Record, Set } from 'immutable';
import Discount from 'shared/records/Discount.js';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

class MembershipRetailDiscount extends Record({
  id: null,
  all_retail_products: true,
  retail_category_ids: Set(),
  value: 0,
  errors: new FieldErrors(),
  subscribable_id: null,
  subscribable_color: null,
  subscribable_name: null,
  subscribable_type: null,
}) {
  constructor(object = {}, _options = {}) {
    const retailCategoryIds = {
      retail_category_ids: Set(object.retail_category_ids),
    };
    super(merge(object, retailCategoryIds));
  }

  validate() {
    let errors = new FieldErrors();

    if (!isPresent(this.value)) {
      errors = errors.add('value', 'records.errors.required');
    }

    if (isPresent(this.value) && (this.value <= 0.0 || this.value > 100.0)) {
      errors = errors.add(
        'value',
        'records.MembershipRetailDiscount.errors.value_out_of_range'
      );
    }

    if (
      this.all_retail_products &&
      isPresent(this.retail_category_ids) &&
      !this.retail_category_ids.isEmpty()
    ) {
      errors = errors.add(
        'all_retail_products',
        'records.MembershipRetailDiscount.errors.no_retail_categories_when_all_products'
      );
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  // eslint-disable-next-line class-methods-use-this
  isPercentDiscount() {
    return Discount.PERCENT;
  }

  get discount() {
    return new Discount({ type: Discount.PERCENT, value: this.value });
  }
}

export default MembershipRetailDiscount;
