import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  eventTypeBar: {
    width: 3,
    border: 2,
    borderRadius: 2,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
  },
};

function EventTypeBar({ eventType }) {
  return (
    <span
      style={{ ...styles.eventTypeBar, backgroundColor: eventType.color }}
    />
  );
}

function SelectedEventType({ eventType, onRemove, style }) {
  return (
    <FlexBoxCenter style={{ position: 'relative', minHeight: 21, ...style }}>
      <EventTypeBar eventType={eventType} />
      <div style={{ paddingLeft: 10 }}>{eventType.name}</div>
      {onRemove && (
        <IconButton
          iconStyle={{ height: '20px', width: '20px' }}
          onClick={() => onRemove(eventType.id)}
          style={{
            padding: '0',
            height: '20px',
            width: '20px',
            position: 'absolute',
            right: 0,
          }}
        >
          <ClearIcon sx={{ color: uhColors.lightGrey }} />
        </IconButton>
      )}
    </FlexBoxCenter>
  );
}

export default SelectedEventType;
