export default alt.generateActions(
  'mounted',
  'listMembershipTiers',
  'listMembershipTiersSuccess',
  'listMembershipTiersError',
  'addTier',
  'editTier',
  'cancelTier',
  'saveTier',
  'saveTierSuccess',
  'saveTierError',
  'eventDiscountAdded',
  'discountFieldChanged',
  'discountRemoved',
  'discountTypeChanged',
  'fieldChanged',
  'eventCreditAdded',
  'eventCreditEventAdded',
  'eventCreditEventRemoved',
  'eventCreditEventTypeAdded',
  'eventCreditEventTypeRemoved',
  'eventCreditRemoved',
  'eventCreditTypeChanged',
  'eventGrantAheadChanged',
  'eventCreditUnlimitedToggled',
  'eventCreditUpdated',
  'toggleFutureScheduling',
  'updateGrantAhead',
  'toggleCreditRollover',
  'exclusiveEventTypeAdded',
  'exclusiveEventTypeRemoved',
  'exclusiveEventTypesCleared',
  'exclusiveEventTypesExpanded',
  'toggleBestValue',
  'toggleBestValueSuccess',
  'toggleBestValueError',
  'showDeleteConfirmation',
  'deleteTier',
  'deleteTierSuccess',
  'deleteTierError',
  'cloneTier',
  'toggleCreationDrawer',
  'toggleJoinFee',
  'validate'
);
