import * as React from 'react';
import { grey } from '@mui/material/colors';

const styles = {
  root: {
    backgroundColor: grey[800],
    borderRadius: '.25em',
    color: 'var(--color-white)',
    fontSize: '85%',
    lineHeight: '1',
    padding: '.2em .6em .3em',
    textAlign: 'center',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
  },
};

function Label({ children, style }) {
  return <span style={{ ...styles.root, ...style }}>{children}</span>;
}

export default Label;
