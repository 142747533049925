import { Grid } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import UppperHandIcon from 'shared/components/icons/UpperHandIcon.jsx';
import WebkitAddOnsIcon from 'shared/components/icons/WebkitAddOnsIcon.jsx';
import ExplanatoryText from 'shared/components/_ExplanatoryText.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import React from 'react';
import { Link, Box, Typography } from '@mui/material';

const styles = {
  root: {
    margin: 3,
  },

  header: {
    fontSize: 22,
    marginBottom: 2,
  },
  itemTile: {
    marginTop: 2,
    color: uhColors.fadedGreen,
    marginBottom: '1rem',
  },
  learnMoreLink: {
    backgroundColor: '#2196f3',
    color: '#ffffff',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '12px',
  },
  explanatoryStyle: { textAlign: 'center' },
  heading: { marginBottom: 40, width: smallScreen() ? '100' : '50%' },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  logoTitleWrapper: { textAlign: 'center' },
  addOnsItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
};

const addOnsItems = [
  {
    title: '.uh_title',
    description: '.uh_explain',
    link: 'https://upperhand.com/ai/',
    icon: <UppperHandIcon />,
  },
  {
    title: '.wk_title',
    description: '.wk_explain',
    link: 'https://upperhand.com/custom-sports-websites/ ',
    icon: <WebkitAddOnsIcon />,
  },
];

function AddOns({ intl }) {
  return (
    <Box sx={styles.root}>
      <Typography variant="h4" sx={styles.header}>
        {t('.heading', intl, __filenamespace)}
      </Typography>

      <ExplanatoryText
        text={t('.explanation', intl, __filenamespace)}
        style={styles.heading}
      />
      <Grid container spacing={5}>
        {addOnsItems.map(item => (
          <Grid
            item
            xs={12}
            sm={3}
            key={item.link}
            style={styles.addOnsItemWrapper}
          >
            <Box sx={styles.logoTitleWrapper}>
              {item.icon}
              <Typography variant="h5" sx={styles.itemTile}>
                {t(item.title, intl, __filenamespace)}
              </Typography>
            </Box>

            <ExplanatoryText
              text={t(item.description, intl, __filenamespace)}
              style={styles.explanatoryStyle}
            />

            <Box sx={styles.linkWrapper}>
              <Link href={item.link} sx={styles.learnMoreLink} target="_blank">
                {t('.learn_more', intl, __filenamespace)}
              </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default injectIntl(AddOns);
