class POSCreditPassPackageActions {
  constructor() {
    this.generateActions(
      'addToCartClick',
      'cancelClicked',
      'listingCreditPassPurchasesSuccess',
      'listingCreditPassPurchasesError',
      'selectProfile',
      'updateCartClick',
      'viewInCartClicked'
    );
  }
}

export default alt.createActions(POSCreditPassPackageActions);
