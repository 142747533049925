import * as React from 'react';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const defaultStyle = {
  lineHeight: 1.2,
  color: uhColors.iconGrey,
};

function ExplanatoryText({ text, style }) {
  return <div style={merge(defaultStyle, style)}>{text}</div>;
}

export default ExplanatoryText;
