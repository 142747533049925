import { DefaultSourceActions } from './DefaultSourceActionList';

class BalancesActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'detailsSuccess',
      'detailsError'
    );
  }
}

export default alt.createActions(BalancesActions);
