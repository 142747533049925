import * as React from 'react';
import { injectIntl } from 'react-intl';

import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

import PickerCalendar from 'calendar/components/date_picker/_PickerCalendar.jsx';

import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import uhTheme from '_uh_theme.jsx';

import { smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { weekRangeFromDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

const styles = {
  DatePicker: {
    flex: '1',
    textAlign: 'center',
  },
  PickerButtonLabel: {
    fontSize: '15px',
  },
  PickerButtonLabelMobile: {
    fontSize: '13px',
    padding: '0 8px',
    whiteSpace: 'nowrap',
  },
};

const stylesLiteral = view => `
      .PickerCalendar .Week:hover::before {
        ${view === CALENDAR_TYPE.WEEK ? "content: '';" : ''}
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${uhTheme.datePicker.headerColor};
        border: 1px solid ${uhTheme.datePicker.headerColor};
      }
    `;

const dateText = (view, currentDate, isMobile) => {
  switch (view) {
    case CALENDAR_TYPE.DAY: {
      const format = isMobile ? 'MMM D, YYYY' : 'dddd, MMM. D, YYYY';

      return currentDate.format(format);
    }
    case CALENDAR_TYPE.WEEK: {
      const weekRange = weekRangeFromDate(currentDate);
      const format = isMobile ? 'M/D' : 'ddd MMM D';

      return `${weekRange.start.format(format)} - ${weekRange.end.format(
        format
      )}`;
    }
    default:
      return currentDate.format();
  }
};

function PickerButton({ view, currentDate, onClick }) {
  const isMobile = smallScreen();
  const labelText = dateText(view, currentDate, isMobile);

  return (
    <Button sx={{ color: 'var(--color-old-gray)' }} onClick={onClick}>
      <Typography
        style={merge(
          styles.PickerButtonLabel,
          isMobile ? styles.PickerButtonLabelMobile : {}
        )}
      >
        {labelText}
      </Typography>
    </Button>
  );
}

class DatePicker extends React.Component {
  state = {
    open: false,
    anchorEl: null,
  };

  handleButtonClick = e => {
    e.preventDefault();

    this.setState({
      open: true,
      anchorEl: e.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDateSelect = (_, date) => {
    CalendarActions.setCalendarDate(date);
    setTimeout(this.handleRequestClose, 300);
  };

  render() {
    const { currentDate, view, intl } = this.props;
    const { anchorEl, open } = this.state;

    return (
      <div style={styles.DatePicker}>
        <style
          type="text/css"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: stylesLiteral(view) }}
        />
        <PickerButton
          view={view}
          currentDate={currentDate}
          onClick={this.handleButtonClick}
        />
        <Popover
          marginThreshold={5}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={open}
          onClose={this.handleRequestClose}
          PaperProps={{
            sx: { maxWidth: 'calc(100% - 10px)' },
          }}
        >
          <PickerCalendar
            intl={intl}
            view={view}
            currentDate={currentDate}
            onSelectDate={this.handleDateSelect}
            handleRequestClose={this.handleRequestClose}
          />
        </Popover>
      </div>
    );
  }
}

export default injectIntl(DatePicker);
