import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import { uhFlexbox } from 'shared/styles/uhStyles.jsx';

function DialogHeader({
  title,
  closeDialog,
  children,
  toolbarStyle,
  disabled = false,
}) {
  return (
    <Toolbar
      style={{
        ...{
          backgroundColor: '#2f313a',
          height: '100%',
          padding: '0 0 1.5em 2em',
        },
        ...toolbarStyle,
      }}
    >
      <div
        style={{ ...{ color: grey[300], flex: '1 100%' }, ...uhFlexbox.column }}
      >
        <div style={uhFlexbox.row}>
          {title}
          <IconButton
            disabled={disabled}
            onClick={closeDialog}
            style={{ marginLeft: 'auto' }}
          >
            <CloseIcon sx={{ color: grey[600] }} />
          </IconButton>
        </div>
        {!!children && <div style={uhFlexbox.row}>{children}</div>}
      </div>
    </Toolbar>
  );
}

export default DialogHeader;
