import { List } from 'immutable';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';

export const listItems = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'commission_rates/searching',
    data: params,
    success: onSuccess(data => data, List([success])),
    error: onError(List([error])),
  });
};

export const fetchCommissions = ({ url, params, success, error }) => {
  uhApiClient.get({
    url,
    data: params,
    success: onSuccess(data => data, List([success])),
    error: onError(List([error])),
  });
};

export const createOrUpdateCommission = ({
  id,
  url,
  params,
  success,
  error,
}) => {
  const method = id ? 'put' : 'post';
  uhApiClient[method]({
    url,
    data: JSON.stringify(params),
    success: onSuccess(data => data, success),
    error: onError(List([error])),
  });
};

export const deleteCommission = ({ id, success, error }) => {
  uhApiClient.delete({
    url: `commission_rates/${id}`,
    success: onSuccess(data => data, success),
    error: onError(List([error])),
  });
};
