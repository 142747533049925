import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Paper from '@mui/material/Paper';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  margin: {
    marginBottom: 4,
  },
  productCard: {
    cursor: 'pointer',
    position: 'relative',
    marginBottom: 6,
    padding: '12px 17px',
  },
  thumbnail: {
    marginRight: 20,
    objectFit: 'contain',
  },
};

function RetailProductCard({ product }) {
  return (
    <Paper
      onClick={() => POSProductListActions.productSelected(product)}
      style={styles.productCard}
    >
      <FlexBox>
        <img
          alt={product.name}
          height={50}
          src={product.featured_image.getAlternative('thumb_small')}
          style={styles.thumbnail}
          width={50}
        />
        <div>
          <div style={styles.margin}>{product.name}</div>
          <div style={styles.margin}>{product.retail_category.name}</div>

          <div>
            {product.max_price === product.min_price ? (
              <FormattedCurrency value={product.max_price} fromCents />
            ) : (
              <FormattedMessage
                id={messageId('.price_range', __filenamespace)}
                values={{
                  min: (
                    <FormattedCurrency value={product.min_price} fromCents />
                  ),
                  max: (
                    <FormattedCurrency value={product.max_price} fromCents />
                  ),
                }}
              />
            )}
          </div>
        </div>
      </FlexBox>
    </Paper>
  );
}

export default RetailProductCard;
