import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import clsx from 'clsx';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { Balance } from 'records';
import BalanceDetailsDrawerActions from 'containers/reports/balanceDetailsDrawer/Actions';
import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';

function DetailItem({ intl, balance }) {
  const effectiveAt = moment(balance.get('effectiveAt'));

  return (
    <Card
      classes={{
        root: 'details__card',
      }}
      contentDirection="column"
      onClick={() =>
        BalanceDetailsDrawerActions.openDrawer(balance.get('compoundId'), [
          BalanceListDrawerActions.closeDrawer.defer,
        ])
      }
    >
      <Grid container xs={12} spacing={1}>
        <Grid container item xs={12} justify="space-between">
          <Grid item>
            <Typography variant="subtitle2" className="details__title">
              {balance.get('product')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              align="right"
              className={clsx(
                'details__action',
                balance.get('balanceRemaining') < 0 &&
                  'details__negative_balance'
              )}
            >
              <FormattedCurrency
                value={Math.abs(balance.get('balanceRemaining'))}
                fromCents
              />
              <ChevronRightIcon />
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-between">
          <Grid item>
            {effectiveAt.isSame(moment(), 'day')
              ? t('.date_today', intl, __filenamespace, {
                  date: effectiveAt.format('h:mma'),
                })
              : effectiveAt.format('MM/DD/YYYY • h:mma')}
          </Grid>
          <Grid item>
            {balance.get('paymentType') && (
              <Typography variant="body2">
                {t(
                  `.payment_method_${
                    balance.get('paymentType')?.split(',')[0]
                  }`,
                  intl,
                  __filenamespace
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

DetailItem.propTypes = {
  intl: PropTypes.object.isRequired,
  balance: PropTypes.instanceOf(Balance).isRequired,
};

export default DetailItem;
