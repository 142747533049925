class AccountSetupPaysafeActions {
  constructor() {
    this.generateActions(
      'closeAlert',
      'fetchStatus',
      'loadTosAndIdentity',
      'mounted',
      'nextStep',
      'stepperMounted',
      'submitConfirmation',
      'submitTosAndIdentity',
      'successfullyCompleted'
    );
  }
}

export default alt.createActions(AccountSetupPaysafeActions);
