import { Record } from 'immutable';

class InventoryItem extends Record({
  variant_id: null,
  product: '',
  vendor_name: '',
  sku: '',
  adjustment: '',
  adjustment_reason: '',
  gtin: '',
  live_qty: 0,
  trigger_qty: 0,
  received: 0,
  sold: 0,
  returned: 0,
  avg_cost: 0,
  avg_price: 0,
  total_profit: 0,
  price: 0,
  cost: 0,
}) {
  toServer() {
    return this.toJS();
  }
}

export default InventoryItem;
