import * as React from 'react';
import { Button, Typography } from '@upperhand/playmaker';

import EmptyStateIcon from 'shared/components/icons/empty_states/DeviceList.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import DeviceListActions from '../Actions';

function EmptyState({ intl }) {
  return (
    <div className="empty-state">
      <div className="empty-state__icon">
        <EmptyStateIcon />
      </div>
      <Typography className="empty-state__title" variant="h3">
        {t('.title', intl, __filenamespace)}
      </Typography>
      <Typography className="empty-state__description" variant="body3">
        {t('.description', intl, __filenamespace)}
      </Typography>
      <Button
        classes={{ root: 'empty-state__btn' }}
        type="primary"
        onClick={DeviceListActions.refresh}
        icon="refresh"
      >
        {t('.btn', intl, __filenamespace)}
      </Button>
    </div>
  );
}

export default EmptyState;
