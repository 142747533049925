import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { SingleAttendee } from 'calendar/components/views/shared/event_card/_SingleAttendee.jsx';
import MultipleAttendees from 'calendar/components/views/shared/event_card/_MultipleAttendees.jsx';
import AssignedResources from 'calendar/components/views/shared/event_card/_AssignedResources.jsx';
import EventTimeNote from 'calendar/components/views/shared/event_card/_EventTimeNote.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import DollarIcon from 'shared/components/icons/Dollar.jsx';

const styles = {
  EventTitle: {
    alignItems: 'flex-start',
    margin: '5px 0 5px',
    lineHeight: '1.3em',
  },
  UnpaidIcon: {
    borderRadius: '2px',
    backgroundColor: 'var(--color-unpaid-red)',
    width: '14px',
    height: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '4px',
  },
  DollarIcon: {
    width: '6px',
    height: 'auto',
  },
};

function Title({ eventTime, title }) {
  return (
    <div>
      {title}
      {!eventTime.isEventStandard() && (
        <span style={{ fontStyle: 'italic' }}>
          <FormattedMessage
            id={messageId(eventTime.getStatusIntl(), __filenamespace)}
          />
        </span>
      )}
    </div>
  );
}

function EventTitle({
  eventTime,
  clients,
  hovering,
  intl,
  resources,
  event,
  memberships,
  onClickViewNotes,
  onCloseNotes,
  scheduleType,
  handleAttendeesClick,
  hasNotesPermission,
  readOnly,
}) {
  const count = eventTime.unpaidClients().size;
  return (
    <div>
      {scheduleType ? (
        <div>
          {clients.size === 1 && (
            <>
              <FlexBox
                style={{ justifyContent: 'start-end', alignItems: 'center' }}
              >
                <SingleAttendee
                  eventTime={eventTime}
                  client={clients.first()}
                  membership={memberships.get(
                    clients.first().active_membership_id
                  )}
                  hovering={hovering}
                  handleAttendeesClick={handleAttendeesClick}
                />
                {hasNotesPermission && (
                  <EventTimeNote
                    event={event}
                    eventTime={eventTime}
                    clients={clients}
                    onClickViewNotes={onClickViewNotes}
                    onCloseNotes={onCloseNotes}
                    readOnly={readOnly}
                  />
                )}
                {count > 0 && (
                  <div style={styles.UnpaidIcon}>
                    <DollarIcon style={styles.DollarIcon} color="white" />
                  </div>
                )}
              </FlexBox>
              <AssignedResources resources={resources} intl={intl} />
            </>
          )}
          {clients.size > 1 && (
            <>
              <FlexBox
                style={{ justifyContent: 'start-end', alignItems: 'center' }}
              >
                <MultipleAttendees
                  eventTime={eventTime}
                  clients={clients}
                  handleAttendeesClick={handleAttendeesClick}
                  intl={intl}
                />
                {hasNotesPermission && (
                  <EventTimeNote
                    event={event}
                    eventTime={eventTime}
                    clients={clients}
                    onClickViewNotes={onClickViewNotes}
                    onCloseNotes={onCloseNotes}
                    readOnly={readOnly}
                  />
                )}
              </FlexBox>
              <AssignedResources resources={resources} intl={intl} />
            </>
          )}
          {clients.size === 0 && hasNotesPermission && (
            <EventTimeNote
              event={event}
              eventTime={eventTime}
              clients={clients}
              onClickViewNotes={onClickViewNotes}
              onCloseNotes={onCloseNotes}
              readOnly={readOnly}
            />
          )}
        </div>
      ) : (
        <>
          <FlexBox style={styles.EventTitle}>
            {count > 0 && (
              <div style={styles.UnpaidIcon}>
                <DollarIcon style={styles.DollarIcon} color="white" />
              </div>
            )}
            <Title eventTime={eventTime} title={event.title} />
          </FlexBox>
          <AssignedResources resources={resources} intl={intl} />
        </>
      )}
    </div>
  );
}

export default injectIntl(EventTitle);
