import * as React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CreditPass from 'shared/records/CreditPass.js';
import mapDiscounts from 'memberships/components/_DiscountBenefits/_mapDiscounts.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

import MembershipCreditPassDiscount from 'shared/records/MembershipCreditPassDiscount.jsx';
import CreditPassDiscount from './_CreditPassDiscount.jsx';

class CreditPassDiscountBenefits extends React.Component {
  eventBenefits() {
    const { membership, creditPasses, intl } = this.props;
    const publishedMembershipCreditPasses =
      membership.membership_credit_pass_discounts
        .map(mcp => {
          const validIds = mcp.credit_pass_ids.filter(id =>
            creditPasses.find(cp => cp.id === id)
          );
          return new MembershipCreditPassDiscount(
            merge(mcp, {
              credit_pass_ids: validIds,
            })
          );
        })
        .filter(mcp => mcp.all_credit_passes || mcp.credit_pass_ids.size > 0);
    return mapDiscounts(publishedMembershipCreditPasses, creditPass => {
      if (creditPass.all_credit_passes) {
        const all = new CreditPass({
          id: 'credit-pass-all',
          name: t('.all_passes', intl, __filenamespace),
        });
        return Set([all]);
      }
      if (creditPasses.isEmpty()) {
        return Set([]);
      }
      return creditPass.credit_pass_ids.map(id =>
        creditPasses.find(et => et.id === id)
      );
    });
  }

  render() {
    const eventBenefitsList = this.eventBenefits();
    const { containerStyles, itemStyle, textStyle } = this.props;

    if (eventBenefitsList.isEmpty()) {
      return null;
    }
    return (
      <div style={containerStyles}>
        {eventBenefitsList
          .map((passes, discount) => (
            <CreditPassDiscount
              key={`${discount.type}-${discount.value}`}
              discount={discount}
              creditPasses={passes}
              itemStyle={itemStyle}
              textStyle={textStyle}
            />
          ))
          .toList()}
      </div>
    );
  }
}

CreditPassDiscountBenefits.propTypes = {
  membership: PropTypes.object.isRequired,
  creditPasses: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  containerStyles: PropTypes.object,
};

CreditPassDiscountBenefits.defaultProps = {
  containerStyles: {},
};

export default injectIntl(CreditPassDiscountBenefits);
