import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Typography } from '@upperhand/playmaker';

import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Stack,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';

import AutomationDetailsCard from 'automations/display/components/AutomationDetailsCard.jsx';
import PriceFields from 'shared/components/pricing/_PricingFields.jsx';
import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';
import AutomationEditDrawer from 'automations/editing/components/AutomationEditDrawer.jsx';
import AutomationListDrawer from 'shared/components/AutomationListDrawer/_AutomationListDrawer.jsx';

import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';
import FeePlan from 'event_mgmt/shared/records/FeePlan.jsx';
import FeePlanStore from 'event_mgmt/shared/stores/FeePlanStore.jsx';
import FeePlanActions from 'event_mgmt/shared/actions/FeePlanActions.jsx';

import { SINGLE_COLUMN_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import uhTheme from '_uh_theme.jsx';

import './TabPricing.scss';

const styles = {
  automationTemplateDescriptionButtonContainer: {
    alignItems: 'center',
    backgroundColor: uhTheme.palette.headerGrey,
    color: 'white',
    cursor: 'pointer',
    fontWeight: 600,
    height: 46,
    marginBottom: '8px',
    padding: '0 3px 0 15px',
    width: 220,
  },
  containerStyle: {
    flexWrap: smallScreen() ? 'wrap-reverse' : 'nowrap',
    maxWidth: '90%',
    margin: '0 -30px',
  },
  columnStyle: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    margin: '0 30px',
  },
  columnHeader: {
    fontWeight: 600,
    marginBottom: '4px',
  },
  defaultPaymentContainer: {
    margin: '5px 0',
    color: uhColors.textGrey,
    width: 285,
    lineHeight: 1.5,
    fontSize: 15,
  },
};

function AutomationPlacard({
  automation,
  allAutomationTemplateDescriptions,
  removeAutomationTemplateDescription,
}) {
  const onNameClick = () => {
    const atd = allAutomationTemplateDescriptions.find(
      each => each.id === automation.id
    );
    AutomationTemplateDescriptionActions.updateStoreKeypath([], atd);
  };

  const onClearClick = event => {
    event.stopPropagation();
    removeAutomationTemplateDescription(automation.id);
  };

  return (
    <Grid
      sx={styles.automationTemplateDescriptionButtonContainer}
      onClick={onNameClick}
      container
      justifyContent="space-between"
    >
      <Box>{automation.name}</Box>
      <IconButton
        sx={{
          width: 36,
          height: 36,
          padding: '9px',
        }}
        onClick={onClearClick}
      >
        <CloseIcon sx={{ height: 18, width: 18, color: grey[500] }} />
      </IconButton>
    </Grid>
  );
}

function AutomationEditSection({
  customerTeam,
  automationTemplateDescriptionsOnThisEvent,
  allAutomationTemplateDescriptions,
  removeAutomationTemplateDescription,
  handleAddAutomation,
  selectableAutomationTemplateDescriptions,
  automationListDrawerOpen,
  handleAutomationListDrawerChange,
  selectedAutomationTemplateDescription,
  handleAutomationSelect,
  handleAutomationSave,
  handleAutomationCancel,
  intl,
}) {
  const eventTitle = customerTeam?.title || 'Team Title';
  const buttonLabelStyle = {
    color: uhTheme.palette.primary.main,
    fontWeight: 600,
  };

  return (
    <Box>
      <Box component="p" sx={styles.columnHeader}>
        <FormattedMessage id={messageId('.automation', __filenamespace)} />
      </Box>
      {!customerTeam.id && (
        <Box
          component="p"
          sx={{ marginBottom: '4px', color: uhColors.warning }}
        >
          <FormattedMessage
            id={messageId('.add_warning', __filenamespace)}
            values={{ eventTitle }}
          />
        </Box>
      )}
      <Box>
        {automationTemplateDescriptionsOnThisEvent.map(automation => (
          <AutomationPlacard
            automation={automation}
            allAutomationTemplateDescriptions={
              allAutomationTemplateDescriptions
            }
            removeAutomationTemplateDescription={
              removeAutomationTemplateDescription
            }
          />
        ))}
      </Box>
      <Box>
        <Button
          color="default"
          variant="contained"
          sx={{
            width: 220,
            height: 46,
            marginBottom: '20px',
            fontWeight: 600,
            ...buttonLabelStyle,
          }}
          onClick={handleAddAutomation}
          disabled={
            !selectableAutomationTemplateDescriptions?.size || !customerTeam.id
          }
        >
          {t('.add_automation', intl, __filenamespace)}
        </Button>
      </Box>
      {automationListDrawerOpen && (
        <AutomationListDrawer
          open={automationListDrawerOpen}
          onRequestChange={handleAutomationListDrawerChange}
          automationTemplateDescriptions={
            selectableAutomationTemplateDescriptions
          }
          selectedAutomationTemplateDescription={
            selectedAutomationTemplateDescription
          }
          onAutomationSelect={handleAutomationSelect}
          intl={intl}
          isTeamEvent
        />
      )}
      {selectedAutomationTemplateDescription && (
        <AutomationEditDrawer
          open={!!selectedAutomationTemplateDescription}
          onListDrawerRequest={handleAutomationListDrawerChange}
          automationTemplateDescription={selectedAutomationTemplateDescription}
          onSave={handleAutomationSave}
          onCancel={handleAutomationCancel}
          canEditMore={!selectableAutomationTemplateDescriptions.isEmpty()}
        />
      )}
    </Box>
  );
}

function PaymentMethods({
  customerTeam,
  handleInputChange,
  intl,
  fieldErrors,
}) {
  const paymentMethods = customerTeam.get('payment_methods');
  const methods = paymentMethods
    .map((m, index) => ({
      type: index,
      isEnabled: m,
    }))
    .toArray();

  const handleChange = (e, checked) => {
    handleInputChange(e, checked, ['payment_methods', e.target.name]);
  };

  return (
    <Box sx={{ margin: '10px 0' }}>
      <Box component="p" sx={styles.columnHeader}>
        {t('.payment_methods', intl, __filenamespace)}
      </Box>
      <Box sx={styles.defaultPaymentContainer}>
        {t('.info', intl, __filenamespace)}
      </Box>
      <Stack>
        {methods.map(method => (
          <FormControlLabel
            key={method.type}
            name={method.type}
            label={t(`.${method.type}`, intl, __filenamespace)}
            checked={method.isEnabled}
            control={<Checkbox />}
            onChange={handleChange}
          />
        ))}
      </Stack>
      <Typography variant="error">
        {fieldErrors.get('payment_methods')}
      </Typography>
    </Box>
  );
}

function PricingInterface({
  automations,
  customerTeam,
  selectedAtdList,
  feePlan,
  isFreeEvent,
  fieldErrors,
  handleInputChange,
  handleFreeEventCheck,
  intl,
}) {
  if (!automations().isEmpty()) {
    const atms = automations().isEmpty() ? selectedAtdList() : automations();
    return (
      <Box sx={styles.columnStyle}>
        {atms.map(atd => (
          <AutomationDetailsCard atd={atd} feePlan={feePlan} />
        ))}
      </Box>
    );
  }
  return (
    <Box sx={styles.columnStyle}>
      <FormControlLabel
        name="freeEvent"
        control={<Checkbox />}
        label={t('.free_team', intl, __filenamespace)}
        checked={isFreeEvent}
        onChange={handleFreeEventCheck}
      />
      <PriceFields
        isFree={isFreeEvent}
        fieldName="base_price"
        value={customerTeam.get('base_price')}
        feePlan={feePlan}
        errorText={
          !isFreeEvent ? fieldErrors.get('base_price', []).join(',') : ''
        }
        handleInputChange={handleInputChange}
      />
    </Box>
  );
}

function TabPricing(props) {
  const { customerTeam, handleInputChange, intl, fieldErrors } = props;

  const templateDescriptions =
    AutomationTemplateDescriptionStore.getState().descriptions;
  const [feePlan, setFeePlan] = React.useState(new FeePlan());
  const [automationListDrawerOpen, setAutomationListDrawerOpen] =
    React.useState(false);
  const [
    selectableAutomationTemplateDescriptions,
    setSelectableAutomationTemplateDescriptions,
  ] = React.useState(List());
  const [
    automationTemplateDescriptionsOnThisEvent,
    setAutomationTemplateDescriptionsOnThisEvent,
  ] = React.useState(List());
  const [
    allAutomationTemplateDescriptions,
    setAllAutomationTemplateDescriptions,
  ] = React.useState(List());
  const [saveAutomationAfterEventIsSaved, setSaveAutomationAfterEventIsSaved] =
    React.useState(null);
  const [
    selectedAutomationTemplateDescription,
    setSelectedAutomationTemplateDescription,
  ] = React.useState(null);

  const onAutomationTemplateDescriptionStoreChange = store => {
    const eventType = 'fixed_schedule';

    const onThisEvent = store.descriptions.filter(
      atd =>
        atd.event_id !== null &&
        atd.event_id === customerTeam.id &&
        atd.supported_event_type === eventType
    );

    const selectedTemplateTypes = onThisEvent.map(atd => atd.template_type);

    const selectableForThisEvent = store.descriptions.filter(
      atd =>
        atd.supported_event_type === eventType &&
        atd.cloneable &&
        !selectedTemplateTypes.includes(atd.template_type) &&
        (atd.event_id !== customerTeam.id /* can't reselect it */ ||
          atd.event_id === null) /* it's not on any event */
    );
    setSelectableAutomationTemplateDescriptions(selectableForThisEvent);
    setAutomationTemplateDescriptionsOnThisEvent(onThisEvent);
    setAllAutomationTemplateDescriptions(store.descriptions);
    setSelectedAutomationTemplateDescription(store.description);
  };

  const onFeePlanStoreChange = store => {
    setFeePlan(store.feePlan);
  };

  const automations = () => automationTemplateDescriptionsOnThisEvent;

  const doAutomationSave = (atd, eventId) => {
    if (!atd.event_id) {
      // eslint-disable-next-line no-param-reassign
      atd = atd.set('event_id', eventId);
      AutomationTemplateDescriptionActions.updateStoreKeypath(
        ['event_id'],
        eventId
      );
    }
    const pac = PricingAutomationCollection.create([atd]);
    handleInputChange({
      target: { value: false, name: 'freeEvent' },
    });
    handleInputChange({
      target: { value: pac.getMinimumPrice() / 100, name: 'price' },
    });

    if (atd.id) {
      AutomationTemplateDescriptionActions.update(atd.id);
    } else {
      AutomationTemplateDescriptionActions.create({});
    }

    AutomationTemplateDescriptionActions.list({ eventId });
  };

  const handleAddAutomation = () => {
    setAutomationListDrawerOpen(true);
  };

  const handleAutomationCancel = () => {
    setSelectableAutomationTemplateDescriptions(List());
    AutomationTemplateDescriptionActions.deselectCurrent({});
    AutomationTemplateDescriptionActions.list({
      eventId: customerTeam.id,
    });
  };

  const handleAutomationSave = () => {
    if (saveAutomationAfterEventIsSaved) {
      return;
    }

    let atd;
    if (selectedAutomationTemplateDescription.cloneable) {
      atd = selectedAutomationTemplateDescription
        .clone()
        .set('cloneable', false);
      AutomationTemplateDescriptionActions.updateStoreKeypath([], atd);
    } else {
      atd = selectedAutomationTemplateDescription;
    }

    if (!customerTeam.id) {
      setSaveAutomationAfterEventIsSaved(true);
      TeamActions.createOrUpdateServer();
    } else {
      doAutomationSave(atd, customerTeam.id);
    }
  };

  const handleAutomationListDrawerChange = (isOpen, _reason) => {
    setAutomationListDrawerOpen(isOpen);
    setSelectedAutomationTemplateDescription(null);
  };

  const handleAutomationSelect = automationTemplateDescription => {
    setSelectedAutomationTemplateDescription(automationTemplateDescription);
    AutomationTemplateDescriptionActions.updateStore(
      automationTemplateDescription
    );
  };

  const handleFreeEventCheck = event => {
    const { checked } = event.target;

    handleInputChange(event);

    if (checked) {
      handleInputChange({ target: { value: '0', name: 'price' } });
      handleInputChange({ target: { value: '0', name: 'base_price' } });
    } else {
      handleInputChange({ target: { value: '', name: 'price' } });
      handleInputChange({ target: { value: '', name: 'base_price' } });
    }
  };

  const removeAutomationTemplateDescription = id => {
    setAutomationListDrawerOpen(false);
    setSelectableAutomationTemplateDescriptions(null);
    AutomationTemplateDescriptionActions.delete(id);
  };

  const selectedAtdList = () =>
    selectedAutomationTemplateDescription
      ? List([selectedAutomationTemplateDescription])
      : List();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    FeePlanStore.listen(onFeePlanStoreChange);
    AutomationTemplateDescriptionStore.listen(
      onAutomationTemplateDescriptionStoreChange
    );
    FeePlanActions.fetch.defer({});
    return () => {
      FeePlanStore.unlisten(onFeePlanStoreChange);
      AutomationTemplateDescriptionStore.unlisten(
        onAutomationTemplateDescriptionStoreChange
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (templateDescriptions.size !== 0) {
      AutomationTemplateDescriptionStore.listen(
        onAutomationTemplateDescriptionStoreChange
      );
    }
    if (customerTeam.id) {
      AutomationTemplateDescriptionActions.list.defer({
        eventId: customerTeam.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTeam.id, templateDescriptions.size]);
  return (
    <Grid sx={styles.containerStyle} container>
      <PricingInterface
        automations={automations}
        customerTeam={customerTeam}
        feePlan={feePlan}
        selectedAtdList={selectedAtdList}
        isFreeEvent={customerTeam.freeEvent}
        fieldErrors={fieldErrors}
        handleFreeEventCheck={handleFreeEventCheck}
        handleInputChange={handleInputChange}
        intl={intl}
      />
      <Box style={styles.columnStyle}>
        <AutomationEditSection
          customerTeam={customerTeam}
          allAutomationTemplateDescriptions={allAutomationTemplateDescriptions}
          automationListDrawerOpen={automationListDrawerOpen}
          automationTemplateDescriptionsOnThisEvent={
            automationTemplateDescriptionsOnThisEvent
          }
          selectedAutomationTemplateDescription={
            selectedAutomationTemplateDescription
          }
          selectableAutomationTemplateDescriptions={
            selectableAutomationTemplateDescriptions
          }
          handleAddAutomation={handleAddAutomation}
          handleAutomationCancel={handleAutomationCancel}
          handleAutomationListDrawerChange={handleAutomationListDrawerChange}
          removeAutomationTemplateDescription={
            removeAutomationTemplateDescription
          }
          handleAutomationSave={handleAutomationSave}
          handleAutomationSelect={handleAutomationSelect}
          intl={intl}
        />
        <PaymentMethods
          customerTeam={customerTeam}
          handleInputChange={handleInputChange}
          fieldErrors={fieldErrors}
          intl={intl}
        />
      </Box>
    </Grid>
  );
}

export default injectIntl(TabPricing);
