import { grey, blue, green } from '@mui/material/colors';
import deepmerge from 'deepmerge';
import clientTheme from './client_theme/theme.js';

const theme = {
  button: {
    textTransform: 'capitalize',
  },
  // spacing, // where should we use this???
  fontFamily: 'benton-sans, sans-serif',
  palette: {
    /* commented colors are default. keeping them for reference */
    primary1Color: blue[500],
    activeBlue: 'var(--color-secondary)',
    hyperlink: blue[500],
    // primary2Color: cyan700,
    // primary3Color: grey400,
    accent1Color: 'var(--color-green)',
    // accent2Color: grey100,
    // accent3Color: grey500,
    textColor: 'var(--color-old-gray)',
    inactiveTabTextColor: '#7F7F7F',
    // alternateTextColor: white,
    // canvasColor: white,
    // borderColor: grey300,
    // disabledColor: fade(darkBlack, 0.3),
    // pickerHeaderColor: cyan500,
    // clockCircleColor: fade(darkBlack, 0.07),
    // shadowColor: fullBlack,
    charcoalBlack: '#2D333A',
    darkGrey: 'var(--color-old-gray)',
    lightGrey: '#9A9EAD',
    upperHandGreen: 'var(--color-green)',
    headerGrey: 'var(--color-old-gray)',
    white: 'var(--color-white)',
    hoverColor: '#b2e2f4',

    primary: {
      main: blue[500],
      contrastText: '#fff',
    },
    secondary: {
      main: green.A400,
      contrastText: '#fff',
    },
    default: {
      main: '#fff',
    },
    dark: {
      main: '#454952',
      contrastText: '#fff',
    },
  },
  mainInstance: false,
  siteURL: 'https://www.getupperhand.com/',
  /* TODO get the hex colors into palette, if possible */
  tableHeaderColumn: {
    textColor: grey[500],
  },
  tableRow: {
    stripeColor: grey[100],
  },
  tabs: {
    backgroundColor: '#EEF1F5',
    textColor: '#7F7F7F',
    selectedTextColor: '#2D333A',
  },
  datePicker: {
    color: blue[500],
    headerColor: blue[500],
    selectColor: blue[500],
  },
  textField: {
    floatingLabelColor: 'var(--color-old-gray)',
    floatingLabelFontWeight: 600,
    floatingLabelFontSize: '17px',
  },
  inkBar: {
    backgroundColor: blue[500],
  },
  sideNav: {
    selectedColor: 'var(--color-green)',
  },
  login: {
    desktopSignInColor: '#181B2B',
    mobileSignInColor: 'var(--color-green)',
  },
  icons: {
    cart: 'var(--color-green)',
  },
  statusBar: {
    backgroundColor: 'var(--color-green)',
  },

  components: {
    // Override button sizes
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: 13,
          }),
          ...(ownerState.size === 'medium' && {
            fontSize: 15,
          }),
          ...(ownerState.size === 'large' && {
            fontSize: 17,
          }),
          textTransform: 'capitalize',
        }),
      },
    },
    // Menu item text size
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    // We need to override height with important to fix an issue with content cut off on mobile browsers
    MuiDrawer: {
      styleOverrides: {
        paper: {
          height: '100%!important',
        },
      },
    },
  },

  typography: {
    fontFamily: 'benton-sans, sans-serif',
  },
};

export default deepmerge(theme, clientTheme);
