import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { Button, Typography, Card, Menu } from '@upperhand/playmaker';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { t } from 'shared/utils/LocaleUtils.js';
import PaymentMethod from 'records/PaymentMethod';
import PaymentMethodTypes from 'containers/payments/types/PaymentMethod';

import CardIcon from 'containers/client/settings/billing/components/CardIcon.jsx';
import NoAddressOnFile from 'shared/components/icons/NoAddressOnFile.jsx';

import PaymentMethodModalActions from 'containers/client/settings/paymentMethodModal/Actions';
import ClientBillingSettingsActions from '../Actions';

function PaymentMethodInfo({ paymentMethod, intl }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isCardMethod = paymentMethod.type === PaymentMethodTypes.CARD;
  const isNoAddress = paymentMethod.info?.billingAddress?.noAddressOnFile;

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        classes={{
          root: 'card-info',
          content: 'card-info__wrapper',
        }}
      >
        <div className="card-info__title">
          <Typography variant="h5" className="card-info__title-text">
            {t(isCardMethod ? '.credit_card' : '.bank', intl, __filenamespace)}
          </Typography>
          {isNoAddress && (
            <>
              <div
                data-tip
                data-for="no-address-on-file"
                className="card-info__no-address"
              >
                <NoAddressOnFile />
              </div>
              <ReactTooltip
                id="no-address-on-file"
                className="no-address-on-file-tooltip"
                effect="solid"
              >
                {t('.no_address_on_file', intl, __filenamespace)}
              </ReactTooltip>
            </>
          )}
          {paymentMethod.default && (
            <div className="card-info__default-label">
              {t('.label_default', intl, __filenamespace)}
            </div>
          )}
          <Button
            classes={{ root: 'card-info__menu' }}
            type="tertiary"
            icon="moreVert"
            rounded
            onClick={handleMenuOpen}
          />
        </div>
        <div className="card-info__content">
          <div className="card-info__number">
            {isCardMethod ? (
              <>
                <span>{paymentMethod.info.last4.padStart(19, '•••• ')}</span>
                {paymentMethod.info.brand && (
                  <CardIcon brand={paymentMethod.brand} />
                )}
              </>
            ) : (
              <>
                <span>{paymentMethod.info.last2.padStart(12, '••• ')}</span>
                <AccountBalanceIcon />
              </>
            )}
          </div>
          <div className="card-info__info">
            <span className="card-info__cardholder">
              {paymentMethod.info.name}
            </span>
            {isCardMethod && (
              <span className="card-info__expiration">
                {moment(
                  `${paymentMethod.info.expMonth}-${paymentMethod.info.expYear}`,
                  'M-YYYY'
                ).format('MM/YYYY')}
              </span>
            )}
          </div>
        </div>
      </Card>
      <Menu
        anchor={anchorEl}
        onClose={handleMenuClose}
        items={[
          {
            label: t('.menu.set_default', intl, __filenamespace),
            visible: !paymentMethod.default,
            onClick: () =>
              ClientBillingSettingsActions.setDefault(paymentMethod.id),
          },
          {
            label: t('.menu.edit', intl, __filenamespace),
            visible: isCardMethod,
            onClick: () =>
              PaymentMethodModalActions.openEditModal({
                paymentMethod,
              }),
          },
          {
            label: t('.menu.delete', intl, __filenamespace),
            visible: true,
            onClick: () =>
              ClientBillingSettingsActions.deletePaymentMethod(
                paymentMethod.id
              ),
          },
        ].filter(item => item.visible)}
      />
    </>
  );
}

PaymentMethodInfo.propTypes = {
  paymentMethod: PropTypes.instanceOf(PaymentMethod),
  intl: PropTypes.object.isRequired,
};

PaymentMethodInfo.defaultProps = {
  paymentMethod: new PaymentMethod(),
};

export default React.memo(PaymentMethodInfo);
