import * as React from 'react';
import PropTypes from 'prop-types';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { getAllUrlParameters } from 'shared/utils/UrlParameters';

import SignupError from '../signupResults/components/SignupError.jsx';

import ChargifySignUpActions from './Actions';
import ChargifySignUpStore from './Store';

function ChargifySignUp({ chargifySignUpStore: { errorMessage, isLoading } }) {
  // The url parameter `ref` can't be passed as a props
  const ref = getAllUrlParameters().get('ref');

  React.useEffect(() => {
    ChargifySignUpActions.signUp(ref ? ref.split(' ') : '');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SpinWhileLoading
      contentContainerStyle={{ height: '100%' }}
      outerContainerStyle={{ height: '100%' }}
      isLoading={isLoading}
    >
      <SignupError
        emailSubject="Error occurred signing up with Chargify"
        errorMessage={errorMessage}
      />
    </SpinWhileLoading>
  );
}

ChargifySignUp.propTypes = {
  chargifySignUpStore: PropTypes.shape({
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
};

ChargifySignUp.defaultProps = {
  chargifySignUpStore: {},
};

export default altContainer({
  stores: { chargifySignUpStore: ChargifySignUpStore },
})(ChargifySignUp);
