import { Record } from 'immutable';

class MembershipEventPermission extends Record({
  id: null,
  event: null,
  event_id: null,
  event_type_id: null,
  event_type: null,
}) {}

export default MembershipEventPermission;
