import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

import CalendarViewMenu from 'calendar/components/views/shared/_CalendarViewMenu.jsx';

import CalendarStore from 'calendar/stores/CalendarStore.jsx';

import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';

const styles = {
  Header: {
    margin: '0',
    padding: '12px',
    fontWeight: 600,
  },
  CustomizeButton: {
    width: '100%',
    margin: '0 0',
    textAlign: 'left',
  },
};

class CustomizeButton extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  handleCustomizeOnClick = e => {
    e.preventDefault();

    this.handleClose();
    CalendarViewActions.toggleShowCustomizeDrawer(true);
  };

  handleClick = e => {
    e.preventDefault();

    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {
      intl,
      calendarStore: { availableStaffOnly, showAvailability },
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <Tooltip
          title={
            <Typography>{t('.customize', intl, __filenamespace)}</Typography>
          }
        >
          <IconButton
            onClick={this.handleClick}
            iconStyle={{
              width: 20,
              height: 20,
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <CalendarViewMenu
          anchorEl={anchorEl}
          onClose={this.handleClose}
          availableStaffOnly={availableStaffOnly}
          showAvailability={showAvailability}
          footer={
            <Button
              variant="text"
              onClick={this.handleCustomizeOnClick}
              style={styles.CustomizeButton}
            >
              {t('.customize', intl, __filenamespace)}
            </Button>
          }
        />
      </div>
    );
  }
}

CustomizeButton.propTypes = {
  intl: PropTypes.object.isRequired,
  calendarStore: PropTypes.object,
};

CustomizeButton.defaultProps = {
  calendarStore: {},
};

export default compose(
  altContainer({
    stores: {
      calendarStore: CalendarStore,
    },
  }),
  injectIntl
)(CustomizeButton);
