import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import ZebraActions from 'shared/actions/ZebraActions.jsx';

import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { DEFAULT, NETWORK_VIEW } from 'shared/stores/ZebraStore.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  button: {
    height: 50,
  },
  buttonLabel: {
    fontSize: 16,
  },
  menuButton: {
    height: 40,
    width: 100,
  },
};

const inputStyles = {
  root: {
    marginBottom: 20,
  },
};

const onChangePrinter = handleChange({
  actions: ZebraActions,
  namedAction: 'updatePrinterSettings',
});
const onChangeWifi = handleChange({
  actions: ZebraActions,
  namedAction: 'updateWifiSettings',
});

const handleChangeView = () => ZebraActions.changeView(NETWORK_VIEW);
const handleCloseView = () => ZebraActions.changeView(DEFAULT);
const handleOpenConfirmation = () => ZebraActions.openConfirmation();
const handleCloseConfirmation = () => ZebraActions.closeConfirmation();
const handleContinueReset = () => ZebraActions.setupWifi();

const SetupDrawer = withStyles(inputStyles)(
  ({ classes, wifiSettings, zebraSettings, intl, networkOpen }) => (
    <Drawer
      disableEnforceFocus
      hideBackdrop
      open={networkOpen}
      className="zebra-wifi-setup-drawer"
      anchor="right"
      PaperProps={{
        sx: {
          width: smallScreen(420)
            ? window.innerWidth * 0.9
            : STANDARD_DRAWER_WIDTH,
        },
      }}
      onClose={handleCloseView}
    >
      <FlexBoxCenter className="drawer-header">
        <IconButton onClick={handleCloseView}>
          <ChevronLeftIcon sx={{ color: uhColors.activeBlue }} />
        </IconButton>
        <h2 className="drawer-header__text">
          <FormattedMessage id={messageId('.network_setup', __filenamespace)} />
        </h2>
      </FlexBoxCenter>
      <div className="drawer-body">
        <TextField
          fullWidth
          label={t('.printer_name', intl, __filenamespace)}
          onChange={onChangePrinter}
          value={zebraSettings.name}
          name="name"
          classes={{ root: classes.root }}
        />
        <TextField
          fullWidth
          label={t('.ssid', intl, __filenamespace)}
          onChange={onChangeWifi}
          value={wifiSettings.get('ssid')}
          name="ssid"
          classes={{ root: classes.root }}
        />
        <TextField
          fullWidth
          label={t('.password', intl, __filenamespace)}
          onChange={onChangeWifi}
          value={wifiSettings.get('password')}
          name="password"
          classes={{ root: classes.root }}
        />
        <Button
          fullWidth
          variant="contained"
          style={{ ...styles.button, marginBottom: 10 }}
          onClick={handleOpenConfirmation}
        >
          {t('actions.continue', intl, __filenamespace)}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="default"
          onClick={handleCloseView}
          style={styles.button}
        >
          {t('actions.cancel', intl, __filenamespace)}
        </Button>
      </div>
    </Drawer>
  )
);

function ZebraWifiSetup({
  confirmationOpen,
  wifiSettings,
  zebraSettings,
  intl,
  networkOpen,
}) {
  return (
    <div>
      <Button
        variant="contained"
        color="default"
        style={styles.menuButton}
        onClick={handleChangeView}
      >
        {t('.network', intl, __filenamespace)}
      </Button>
      <SetupDrawer
        wifiSettings={wifiSettings}
        zebraSettings={zebraSettings}
        closeSetup={handleCloseView}
        networkOpen={networkOpen}
        intl={intl}
      />
      <ConfirmationDialog
        awaitingConfirmation={confirmationOpen}
        onCancel={handleCloseConfirmation}
        onConfirm={handleContinueReset}
        submitText={t('.reset', intl, __filenamespace)}
        title={t('.network_setup', intl, __filenamespace)}
      >
        <div style={{ marginBottom: 20 }}>
          <FormattedMessage id={messageId('.reset_warning', __filenamespace)} />
        </div>
      </ConfirmationDialog>
    </div>
  );
}

export default ZebraWifiSetup;
