import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Card, Grid, Typography } from '@upperhand/playmaker';
import { t } from 'shared/utils/LocaleUtils.js';

import InviteActions from 'user_management/shared/actions/InviteActions.jsx';
import MailTo from 'shared/components/MailTo.jsx';
import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import Tel from 'shared/components/Tel.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { unwrappableString } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import Gift from 'shared/components/icons/Gift.jsx';
import AccountStatus from './AccountStatus.jsx';
import ContactsClientsActions from '../Actions';

const styles = {
  managingUserIcon: {
    height: 12,
    width: 12,
  },
};

function ClientCard({ client, intl }) {
  const menuOptions = client.isInvitePending()
    ? [
        {
          label: t('.delete', intl, __filenamespace),
          onClick: () => InviteActions.delete(client.id),
        },
      ]
    : [
        {
          label: t('.archive', intl, __filenamespace),
          onClick: () => ContactsClientsActions.clientArchived(client.id),
        },
      ];

  if (
    hasAdminPermission() &&
    (client.isInvitePending() ||
      (!client.last_login_at && client.login_enabled))
  ) {
    menuOptions.push({
      label: t('.remind', intl, __filenamespace),
      onClick: () => InviteActions.send(client.id),
    });
  }

  if (
    (['Admin', 'StaffAdmin', 'StaffMember'].includes(currentUser().role) ||
      currentUser().isManager()) &&
    (client.isInvitePending() ||
      (!client.last_login_at && client.login_enabled))
  ) {
    menuOptions.push({
      label: t('.updateEmail', intl, __filenamespace),
      onClick: () => ContactsClientsActions.updateContact(client.id),
    });
  }

  const handleClientClick = (e, clientId) => {
    if (smallScreen()) {
      e.preventDefault();
      ClientProfileDrawerActions.mounted({ clientId });
    }
  };

  return (
    <Card
      menuOptions={menuOptions}
      contentDirection="row"
      classes={{ content: 'card-content' }}
    >
      <Grid container alignItems="center" xs={12} spacing={1}>
        <Grid item container xs={12} md={3} alignItems="center">
          <Grid item>
            <UserAvatar user={client} />
          </Grid>
          <Grid item>
            <Link
              to={customerScopedRoute(`clients/${client.id}`)}
              style={{ textDecoration: 'none' }}
              onClick={e => handleClientClick(e, client.id)}
            >
              <Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ margin: '0 2px' }}>{client.name()} </p>
                  {client.isTodayBirthDay() && (
                    <Gift color="#4AD669" style={{ margin: '0 5px' }} />
                  )}
                </div>
              </Typography>
            </Link>
            {client.managing_customer_user_name && (
              <div>
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                  <Grid item>
                    <ManagingUserIcon style={styles.managingUserIcon} />
                  </Grid>
                  <Grid item>
                    <Link
                      to={customerScopedRoute(
                        `clients/${client.managing_customer_user_id}`
                      )}
                      style={{ textDecoration: 'none' }}
                      onClick={e =>
                        handleClientClick(e, client.managing_customer_user_id)
                      }
                    >
                      <Typography>
                        {unwrappableString(client.managing_customer_user_name)}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={1}>
          <Tel number={client.phone} />
        </Grid>

        <Grid item xs={12} md={4} className="client-email">
          <MailTo email={client.email} />
        </Grid>

        <Grid item xs={12} md={3}>
          <AccountStatus client={client} />
        </Grid>
      </Grid>
    </Card>
  );
}

export default injectIntl(ClientCard);
