import { List } from 'immutable';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { CreditPassSource } from 'sources';
import { currentUser } from 'shared/utils/UserUtils.jsx';

class CreditPassListingStore extends UpperHandStore {
  constructor() {
    super();
    this.records = List();
    this.isLoading = false;

    this.bindListeners({
      requestList: CreditPassListingActions.LIST_REQUESTED,
      updateList: CreditPassListingActions.LIST_RECEIVED,
      handleError: CreditPassListingActions.ERROR,
      handleFetchPassesByEventBenefits: EventActions.FETCH_SUCCESS,
    });
  }

  requestList({ search = '' }) {
    this.isLoading = true;

    const params = {
      search,
      fields: ['events', 'event_types'],
      per_page: 100,
    };

    if (!currentUser().isStaff()) {
      params.published = true;
    }

    CreditPassSource.list({
      params,
      success: CreditPassListingActions.listReceived,
      error: CreditPassListingActions.error,
    });
  }

  handleFetchPassesByEventBenefits({ schedule_type: scheduleType, id }) {
    if (scheduleType === 'class_schedule') {
      this.isLoading = true;

      CreditPassSource.list({
        params: {
          fields: ['events', 'event_types'],
          event_ids: [id],
        },
        success: CreditPassListingActions.listReceived,
        error: CreditPassListingActions.error,
      });
    }
  }

  updateList({ credit_passes: creditPasses }) {
    this.isLoading = false;
    this.records = creditPasses;
  }

  handleError(...args) {
    this.isLoading = false;
    this.notifyError('error listing credit passes', ...args);
  }
}

export default alt.createStore(
  CreditPassListingStore,
  'CreditPassListingStore'
);
