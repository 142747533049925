import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ClockIn({ color, style, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-135.000000, -342.000000)">
          <g transform="translate(103.000000, 158.000000)">
            <g transform="translate(32.000000, 184.000000)">
              <g>
                <path
                  d="M39.234,19.617 C39.234,8.783 30.451,0 19.617,0 C8.783,0 0,8.783 0,19.617 C0,
                   30.451 8.783,39.234 19.617,39.234 C30.451,39.234 39.234,30.451 39.234,19.617"
                  fill={(style && style.color) || color}
                />
                <path
                  d="M27.5848214,19.757934 L16.46875,25.9353894 C16.3404011,26.0079344 16.2301902,
                   26.0163049 16.1381138,25.960501 C16.0460375,25.9046972 16,25.8042517 16,
                  25.6591617 L16,13.3377332 C16,13.1926431 16.0460375,13.0921977 16.1381138,
                  13.0363939 C16.2301902,12.98059 16.3404011,12.9889605 16.46875,
                  13.0615055 L27.5848214,19.2389608 C27.7131703,19.3115058 27.7773438,
                  19.3980005 27.7773438,19.4984474 C27.7773438,19.5988944 27.7131703,
                  19.685389 27.5848214,19.757934 Z"
                  fill="var(--color-white)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

ClockIn.defaultProps = {
  color: '#9A9EAD',
};

export default ClockIn;
