import { Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { ScheduledPaymentActions } from 'sources';

class ScheduledPaymentDataStore extends UpperHandStore {
  constructor() {
    super();

    this.scheduledPayments = Map();

    this.bindListeners({
      fetchSuccess: ScheduledPaymentActions.fetchSuccess,
      fetchError: ScheduledPaymentActions.fetchError,
      listSuccess: ScheduledPaymentActions.listSuccess,
      listError: ScheduledPaymentActions.listError,
    });
  }

  fetchSuccess(scheduledPayment) {
    this.scheduledPayments = this.scheduledPayments.set(
      scheduledPayment.id,
      scheduledPayment
    );
  }

  fetchError(...args) {
    this.notifyError('error fetching scheduledPayment', args);
  }

  listSuccess({ scheduled_payments: scheduledPayments }) {
    this.scheduledPayments = this.scheduledPayments.merge(
      scheduledPayments.groupBy(s => s.id).map(s => s.first())
    );
  }

  listError(...args) {
    this.notifyError('error listing scheduledPayments', args);
  }
}

export default alt.createStore(
  ScheduledPaymentDataStore,
  'ScheduledPaymentDataStore'
);
