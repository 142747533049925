import * as React from 'react';

import CreditPassListing from 'credit_passes/components/CreditPassListing.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';

class StaffCreditPassListing extends React.PureComponent {
  componentDidMount() {
    CreditPassListingActions.listRequested({});
    EventTypeListingActions.list();
    EventActions.list({ fields: ['participant_count'] });
  }

  render() {
    const { readOnly } = this.props;

    return (
      <CreditPassListing className="iphone-x-content" readOnly={readOnly} />
    );
  }
}

export default StaffCreditPassListing;
