import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { Link } from 'react-router-dom';

const styles = {
  link: {
    color: uhColors.activeBlue,
  },
};

const ViewEditLink = ({ item, purchased, onView, onEdit }) => {
  const message = messageId(
    purchased ? '.view_item' : '.edit_item',
    __filenamespace
  );
  const onClick = purchased ? onView : onEdit;
  if (onClick) {
    return (
      <a style={styles.link} onClick={() => onClick(item)}>
        <FormattedMessage id={message} />
      </a>
    );
  }
  return (
    <Link to={purchased ? item.editPath() : item.show_path} style={styles.link}>
      <FormattedMessage id={message} />
    </Link>
  );
};

export default ViewEditLink;
