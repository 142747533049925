import ServiceFeeCreationActions from 'shared/actions/service_fee/ServiceFeeCreationActions';
import ServiceFeeEditingStoreInterface from 'shared/stores/service_fee/ServiceFeeEditingStoreInterface';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

export default alt.createStore(
  ServiceFeeEditingStoreInterface,
  'ServiceFeeCreationStore',
  {
    actions: ServiceFeeCreationActions,
    successMessage: new TranslatableMessage({
      id: '.created',
      filename: __filenamespace,
    }),
  }
);
