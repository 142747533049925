import { Set } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import {
  ClientSource,
  MembershipTierSource,
  EventTypeSource,
  CreditPassSource,
  RetailVendorsSource,
  MembershipSource,
} from 'sources';

import { ClientDataStore } from 'dataStores';

import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';

import MembershipOverviewDrawerActions from './Actions.js';

class MembershipOverviewDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      open: MembershipOverviewDrawerActions.open,
      close: MembershipOverviewDrawerActions.close,
      listBenefitsInfo: MembershipOverviewDrawerActions.listBenefitsInfo,
    });
  }

  reset() {
    this.isOpen = false;
    this.clientId = null;
    this.subscription = new MembershipSubscription();
    this.eventTypeIds = Set();
    this.creditPassIds = Set();
    this.retailCategoryIds = Set();
    this.clientLoading = false;
    this.eventTypesLoading = false;
    this.creditPassesLoading = false;
    this.retailCategoriesLoading = false;
    this.tierLoading = false;
    this.membershipLoading = false;
  }

  open({ clientId, subscription }) {
    this.isOpen = true;
    this.clientId = clientId;
    this.subscription = subscription;

    const { clients } = ClientDataStore.getState();
    const client = clients.get(clientId);

    if (!client) {
      this.clientLoading = true;
      ClientSource.fetch({
        id: clientId,
        success: this.fetchClientSuccess,
        error: this.fetchClientError,
      });
    }

    this.membershipLoading = true;
    MembershipSource.fetch({
      id: subscription.membership_id,
      params: {
        fields: ['events', 'event_types'],
      },
      success: this.fetchMembershipSuccess,
      error: this.fetchMembershipError,
    });

    if (subscription.membership_tier_id) {
      this.tierLoading = true;
      MembershipTierSource.fetch({
        id: subscription.membership_tier_id,
        params: {
          fields: ['events', 'event_types'],
        },
        success: this.fetchTierSuccess,
        error: this.fetchTierError,
      });
    }
  }

  fetchMembershipSuccess = membership => {
    if (!membership.tiered) {
      this.listBenefitsInfo({ infoItem: membership });
    }

    this.membershipLoading = false;
  };

  fetchMembershipError = args => {
    this.membershipLoading = false;
    this.notifyError('Failed to load membership', ...args);
  };

  fetchClientSuccess = () => {
    this.clientLoading = false;
  };

  fetchClientError = args => {
    this.clientLoading = false;
    this.notifyError('Failed to load client', ...args);
  };

  fetchTierSuccess = tier => {
    this.listBenefitsInfo({ infoItem: tier });
    this.tierLoading = false;
  };

  fetchTierError = args => {
    this.notifyError('Failed to load membership tier', ...args);
    this.tierLoading = false;
  };

  listBenefitsInfo({ infoItem }) {
    this.eventTypeIds = infoItem.eventTypeIds;
    this.listEventTypes({});

    this.creditPassIds = infoItem.membership_all_discounts.reduce(
      (ids, discount) => ids.union(Set(discount.get('credit_pass_ids', []))),
      Set()
    );
    this.listCreditPasses({});

    this.retailCategoryIds = infoItem.membership_all_discounts.reduce(
      (ids, discount) =>
        ids.union(Set(discount.get('retail_category_ids', []))),
      Set()
    );
    this.listRetailCategories({});
  }

  listEventTypes({ page = 1, perPage = 50 }) {
    if (this.eventTypeIds.size > 0) {
      this.eventTypesLoading = true;

      EventTypeSource.list({
        params: {
          page,
          ids: this.eventTypeIds.toJS(),
          per_page: perPage,
        },
        success: this.listEventTypesSuccess,
        error: this.listEventTypesError,
      });
    }
  }

  listEventTypesSuccess = ({ page, perPage, totalCount }) => {
    if (page * perPage < totalCount) {
      this.listEventTypes({ page: page + 1, perPage });
    } else {
      this.eventTypesLoading = false;
    }
  };

  listEventTypesError = args => {
    this.eventTypesLoading = false;
    this.notifyError('Failed to load event types', ...args);
  };

  listCreditPasses({ page = 1, perPage = 50 }) {
    if (this.creditPassIds.size > 0) {
      this.creditPassesLoading = true;

      CreditPassSource.list({
        params: {
          page,
          ids: this.creditPassIds.toJS(),
          per_page: perPage,
        },
        success: this.listCreditPassesSuccess,
        error: this.listCreditPassesError,
      });
    }
  }

  listCreditPassesSuccess = ({ page, perPage, totalCount }) => {
    if (page * perPage < totalCount) {
      this.listCreditPasses({ page: page + 1, perPage });
    } else {
      this.creditPassesLoading = false;
    }
  };

  listCreditPassesError = args => {
    this.creditPassesLoading = false;
    this.notifyError('Failed to load credit passes', ...args);
  };

  listRetailCategories({ page = 1, perPage = 50 }) {
    if (this.retailCategoryIds.size > 0) {
      this.retailCategoriesLoading = true;
      RetailVendorsSource.getRetailCategoryList({
        params: {
          page,
          ids: this.retailCategoryIds.toJS(),
          per_page: perPage,
        },
        success: this.listRetailCategoriesSuccess,
        error: this.listRetailCategoriesError,
      });
    }
  }

  listRetailCategoriesSuccess = ({
    page,
    per_page: perPage,
    total_count: totalCount,
  }) => {
    if (page * perPage < totalCount) {
      this.listRetailCategories({ page: page + 1, perPage });
    } else {
      this.retailCategoriesLoading = false;
    }
  };

  listRetailCategoriesError = args => {
    this.retailCategoriesLoading = false;
    this.notifyError('Failed to load retail categories', ...args);
  };

  close() {
    this.reset();
  }
}

export default alt.createStore(
  MembershipOverviewDrawerStore,
  'MembershipOverviewDrawerStore'
);
