import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Divider from '@mui/material/Divider';

import CreditPassExpiration from 'credit_passes/components/_CreditPassExpiration.jsx';
import CreditPassIcon from 'shared/components/icons/CreditPass.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ItemSummaryHeader from 'shared/components/checkout/_ItemSummaryHeader.jsx';
import ManageOrderItem from 'shared/components/checkout/_ManageOrderItem.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  commitment: {
    color: '#807F7F',
    marginBottom: 10,
  },
  details: {
    fontWeight: 'bold',
    paddingLeft: 25,
    position: 'relative',
    marginBottom: 15,
  },
  divider: {
    height: '2px',
    marginBottom: '15px',
  },
  icon: {
    height: 16,
    width: 16,
    position: 'absolute',
    left: 0,
    bottom: 18,
  },
  indented: {
    marginRight: 5,
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  NameAndPricing: {
    marginBottom: 20,
  },
  pricing: {
    marginBottom: 8,
  },
  quantity: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  creditPassExpiration: {
    marginTop: 4,
    color: '#807F7F',
    display: 'block',
    fontWeight: 'bold',
  },
};

function DiscountedAmount({ value, discount }) {
  return (
    <div>
      <span style={styles.indented}>
        <FormattedCurrency value={value - discount} fromCents />
      </span>
      <span style={styles.lineThrough}>
        <FormattedCurrency value={value} fromCents />
      </span>
    </div>
  );
}

function NameAndPricing({ item }) {
  const adjustment = item.applied_adjustments.find(
    adj => adj.isCoupon() && adj.details.isRecurring()
  );
  return (
    <div className="credit-pass-pricing" style={styles.NameAndPricing}>
      <FlexBoxJustify style={styles.pricing}>
        <div>
          <div>
            <CreditPassIcon
              color="var(--color-old-gray)"
              secondaryColor="white"
              style={styles.icon}
            />
            <FormattedMessage id={messageId('.credit_pass', __filenamespace)} />
          </div>
          <CreditPassExpiration
            creditPassId={item.orderable.credit_pass_id}
            style={styles.creditPassExpiration}
          />
        </div>
        {adjustment ? (
          <DiscountedAmount
            discount={adjustment.details.discount.discountFor(
              item.orderable.price
            )}
            value={item.orderable.price}
          />
        ) : (
          <FormattedCurrency value={item.orderable.price} fromCents />
        )}
      </FlexBoxJustify>
    </div>
  );
}

function CreditPassPackageSummary({
  item,
  purchased,
  appliedAccountCredits,
  onRemove,
  onEdit,
  onView,
}) {
  return (
    <div>
      <ItemSummaryHeader
        item={item}
        onRemove={onRemove}
        purchased={purchased}
      />

      <div style={styles.details}>
        <NameAndPricing item={item} />
      </div>

      <Divider sx={styles.divider} />

      <FlexBoxJustify style={styles.quantity}>
        <div>
          <FormattedMessage
            id={messageId('.quantity', __filenamespace)}
            values={{ quantity: 1 }}
          />
        </div>
        <FormattedCurrency value={item.totalBeforeManualDiscount()} fromCents />
      </FlexBoxJustify>
      <ManageOrderItem
        item={item}
        purchased={purchased}
        appliedAccountCredits={appliedAccountCredits}
        onEdit={onEdit}
        onView={onView}
      />
    </div>
  );
}

export default injectIntl(CreditPassPackageSummary);
