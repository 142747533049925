class RetailVendorsActions {
  constructor() {
    this.generateActions(
      'changeModal',
      'closeDialog',
      'create',
      'createResult',
      'listResult',
      'mounted',
      'openDialog',
      'update',
      'updateResult'
    );
  }
}

export default alt.createActions(RetailVendorsActions);
