/* eslint-disable no-underscore-dangle */
import moment from 'moment-timezone';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import TimeLog from 'shared/records/TimeLog';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import AutoCompleteStaffListActions from 'shared/actions/AutoCompleteStaffListActions';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

export default class TimeLogEditingStore extends UpperHandStore {
  constructor({ actions, successMessage }) {
    super();
    this._reset();

    this.actions = actions;
    this.successMessage = successMessage;

    this.bindListeners({
      handleBegin: this.actions.BEGIN,
      handleUpdateDate: this.actions.UPDATE_DATE,
      handleUpdate: this.actions.UPDATE,
      handleSetStaff: this.actions.SET_STAFF,
      handleCloseModal: this.actions.CLOSE_MODAL,
      handleConfirm: this.actions.CONFIRM,
      handleSuccess: this.actions.SUCCESS,
      handleError: this.actions.ERROR,
    });
  }

  _reset() {
    this.timeLog = null;
    this.timeLogToRemove = null;
    this.staffMember = null;
    this.oldStaffMemberId = null;
  }

  _updateEndTime() {
    if (
      moment(this.timeLog.get('start_time')).isSameOrAfter(
        moment(this.timeLog.get('end_time')),
        'seconds'
      )
    ) {
      this.timeLog = this.timeLog.set(
        'end_time',
        this.timeLog.get('start_time')
      );
      this.emitChange();
    }
  }

  _validateTimeLog() {
    this.timeLog = this.timeLog.validate(true);
  }

  handleBegin({ timeLog, staffMember }) {
    this.timeLog = timeLog || new TimeLog();

    if (staffMember) {
      this.timeLog = this.timeLog.set('customer_user_id', staffMember.id);
      this.staffMember = staffMember;
      this.oldStaffMemberId = staffMember.id;

      if (!this.timeLog.get('hourly_rate') && staffMember.hourly_rate) {
        this.timeLog = this.timeLog.set('hourly_rate', staffMember.hourly_rate);
      }
    }
  }

  handleUpdateDate(date) {
    this.timeLog = this.timeLog.set(
      'start_time',
      date ? moment.tz(date, customerTZ()) : null
    );
    this.timeLog = this.timeLog.set(
      'end_time',
      date ? moment.tz(date, customerTZ()) : null
    );

    if (this.timeLog.errors.get('errors').size) {
      this._validateTimeLog();
    }
  }

  handleUpdate([prop, value]) {
    this.timeLog = this.timeLog.set(prop, value);
    if (prop === 'start_time' || prop === 'end_time') {
      this._updateEndTime();
    }
    if (this.timeLog.errors.get('errors').size) {
      this._validateTimeLog();
    }
  }

  handleSetStaff(id) {
    const { allStaffMap } = StaffStore.getState();

    this.staffMember = allStaffMap.get(id);

    this.timeLog = this.timeLog
      .set('customer_user_id', id)
      .set('hourly_rate', this.staffMember.hourly_rate);

    if (this.timeLog.errors.get('errors').size) {
      this._validateTimeLog();
    }
  }

  handleConfirm() {
    this._validateTimeLog();

    if (this.timeLog.isValid()) {
      const url = this.timeLog.id
        ? `time_logs/${this.timeLog.id}`
        : 'time_logs';

      const saveValue = {
        url,
        data: JSON.stringify({ attributes: this.timeLog.toServer() }),
        success: {
          action: this.actions.success,
          args: [
            this.oldStaffMemberId !== this.timeLog.get('customer_user_id') &&
            this.timeLog.id
              ? this.oldStaffMemberId
              : null,
          ],
        },
        error: this.actions.error,
      };

      if (this.timeLog.id) {
        uhApiClient.patch(saveValue);
      } else {
        uhApiClient.post(saveValue);
      }
    }
  }

  handleSuccess() {
    this._reset();
    AutoCompleteStaffListActions.searchStringUpdated.defer('');
    return MessageWindowActions.addMessage.defer(this.successMessage);
  }

  handleError() {
    // eslint-disable-next-line prefer-rest-params
    this.notifyError('error managing time log', arguments);
    this._reset();
    AutoCompleteStaffListActions.searchStringUpdated.defer('');
  }

  handleCloseModal() {
    this._reset();
    AutoCompleteStaffListActions.searchStringUpdated.defer('');
  }
}
