import React from 'react';
import { FormattedMessage } from 'react-intl';
import AltContainer from 'alt-container';

import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import DollarIcon from 'shared/components/icons/Dollar.jsx';
import ReceiptDialog from 'point_of_sale/components/ReceiptDialog.jsx';
import ZebraStore from 'shared/stores/ZebraStore.js';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { PaymentMethod } from 'containers/payments';

const styles = {
  Divider: {
    backgroundColor: uhColors.lighterGrey,
    height: '1px',
    margin: '8px 0',
    marginTop: 12,
    width: '100%',
  },
  DollarIcon: {
    height: 'auto',
    margin: '1px 0 -2px',
    width: '6px',
  },
  successLabel: {
    fontSize: 16,
    justifyContent: 'center',
  },
  SummaryButton: {
    fontWeight: 'bold',
    fontSize: 16,
    color: uhColors.white,
    margin: '10px 0px',
    height: 50,
  },
  UnpaidIcon: {
    alignItems: 'center',
    backgroundColor: uhColors.darkRed,
    borderRadius: '2px',
    height: '20px',
    justifyContent: 'center',
    margin: '0 8px 0 0',
    padding: '0 4px',
    width: '20px',
  },
  ValueBold: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'right',
    marginRight: 5,
  },
  confirmationEmail: {
    fontWeight: '600',
    wordBreak: 'break-all',
    padding: '1rem 0',
  },
};

function Summary({ paymentMethod, onCloseScheduler, selectedClient, orderId }) {
  return (
    <div>
      <FlexBoxCenter style={{ justifyContent: 'center' }}>
        {paymentMethod !== PaymentMethod.PAY_LATER && (
          <FlexBoxCenter style={styles.successLabel}>
            <CheckIcon sx={{ color: uhColors.green, marginRight: 5 }} />
            <span style={styles.ValueBold}>
              <FormattedMessage
                id={messageId(`.${paymentMethod}`, __filenamespace)}
              />
            </span>
            <FormattedMessage
              id={messageId('.payment_complete', __filenamespace)}
            />
          </FlexBoxCenter>
        )}
        {paymentMethod === PaymentMethod.PAY_LATER && (
          <FlexBoxCenter style={styles.successLabel}>
            <FlexBoxCenter style={styles.UnpaidIcon}>
              <DollarIcon style={styles.DollarIcon} color={uhColors.white} />
            </FlexBoxCenter>
            <FormattedMessage
              id={messageId('.paying_later', __filenamespace)}
            />
          </FlexBoxCenter>
        )}
      </FlexBoxCenter>
      <Divider style={styles.Divider} />
      <FlexBox
        style={{
          flexDirection: 'column',
          color: uhColors.dialogTitle,
          fontSize: 14,
          margin: '10px 31px',
          textAlign: 'center',
        }}
      >
        <div>
          <FormattedMessage
            id={messageId('.confirmation_sent_to_email', __filenamespace)}
          />
        </div>
        <div style={styles.confirmationEmail}>{selectedClient.getEmail()}</div>
      </FlexBox>
      <FlexBox
        style={{
          flexDirection: 'column',
          textAlign: 'center',
          margin: '10px 31px',
        }}
      >
        <ReceiptDialog orderId={orderId} />
        <Button
          variant="contained"
          sx={styles.SummaryButton}
          onClick={onCloseScheduler}
        >
          Close
        </Button>
      </FlexBox>
    </div>
  );
}

function CheckoutSummary({
  onCloseScheduler,
  selectedClient,
  paymentMethod,
  orderId,
}) {
  return (
    <AltContainer
      stores={{
        zebraStore: ZebraStore,
      }}
    >
      <Summary
        onCloseScheduler={onCloseScheduler}
        selectedClient={selectedClient}
        paymentMethod={paymentMethod}
        orderId={orderId}
      />
    </AltContainer>
  );
}
export default CheckoutSummary;
