import * as React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import {
  Grid,
  InfiniteScroller,
  Spinner,
  Typography,
  Button,
} from '@upperhand/playmaker';

import Client from 'shared/records/Client.jsx';
import { t } from 'shared/utils/LocaleUtils';

import BalanceListDrawerActions from '../Actions';
import Info from './Info.jsx';
import EmptyState from './EmptyState.jsx';
import DetailItem from './DetailItem.jsx';

const Content = ({
  balanceIds,
  balances,
  client,
  intl,
  isLoading,
  isLoadingDependencies,
  totalCount,
  statistics,
}) => {
  const loading = isLoadingDependencies || isLoading;

  return (
    <div className="balance-list-drawer__content">
      {loading && (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      )}

      {!loading && balanceIds.size === 0 && (
        <Grid container xs={12} alignItems="center" justify="center">
          <EmptyState intl={intl} />
        </Grid>
      )}

      {!loading && balanceIds.size > 0 && (
        <>
          <Info
            client={client}
            intl={intl}
            statistics={statistics}
            totalCount={totalCount}
          />
          <Typography className="details__header" variant="subtitle1">
            {t('.details_title', intl, __filenamespace)}
          </Typography>

          {balanceIds.map(compoundId => (
            <DetailItem
              key={compoundId}
              intl={intl}
              balance={balances.get(compoundId)}
            />
          ))}

          {balanceIds.size < totalCount && (
            <Button
              onClick={BalanceListDrawerActions.listBalances.defer}
              type="tertiary"
              rounded
            >
              Load More
            </Button>
          )}

          {/*
          <InfiniteScroller
            hasMore={balanceIds.size < totalCount}
            onScroll={BalanceListDrawerActions.listBalances.defer}
            isLoading={isLoading}
            showLoader
          >
            {balanceIds.map(compoundId => (
              <DetailItem
                key={compoundId}
                intl={intl}
                balance={balances.get(compoundId)}
              />
            ))}
          </InfiniteScroller>
          */}
        </>
      )}
    </div>
  );
};

Content.propTypes = {
  balanceIds: PropTypes.instanceOf(OrderedSet).isRequired,
  balances: PropTypes.instanceOf(Map).isRequired,
  client: PropTypes.instanceOf(Client),
  intl: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
};

Content.defaultProps = {
  client: null,
};

export default Content;
