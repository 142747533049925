import NativePlugin from 'shared/native/helpers/NativePlugin';

class Bluetooth extends NativePlugin {
  constructor() {
    super('cordova.plugins.zebraPrinter.bluetooth');
  }

  /**
   * Discovers local devices over BLE.
   *
   * @param scanTime Length of time to search for devices
   *
   * @return Promise<[Device]> List of available printer devices
   */
  discover(scanTime = 10) {
    return this.Promise((resolve, reject) => {
      this.plugin().discover(scanTime, resolve, reject);
    });
  }

  /**
   * Determine current printer connectivity status.
   *
   * @return Promise<Bool> True if printer is connected, false otherwise.
   */
  isConnected() {
    return this.Promise((resolve, reject) => {
      this.plugin().isConnected(resolve, reject);
    });
  }

  /**
   * Connects to a device.
   *
   * @param device Printer device
   *
   * @return Promise<nil> resolves if successfully connected to printer
   */
  connect(device) {
    return this.Promise((resolve, reject) => {
      this.isConnected()
        .then(connected => {
          if (connected) {
            resolve();
          } else {
            this.plugin().connect(device, resolve, reject);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Disconnects
   *
   * @return Promise<nil> Resolves if successfully disconnected from the printer.
   */
  disconnect() {
    return this.Promise((resolve, reject) => {
      this.plugin().disconnect(resolve, reject);
    });
  }

  /**
   * Sends data to the currently connected device.
   *
   * @param zpl ZPL command string
   *
   * @return Promise<nil> resolves if printer received the command
   */
  send(zpl) {
    return this.Promise((resolve, reject) => {
      this.plugin().send(zpl, resolve, reject);
    });
  }
}

export default new Bluetooth();
