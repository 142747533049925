import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import * as ClientSource from 'sources/ClientSource';

import ContactsPageActions from './Actions';

export const CLIENTS_TAB = 0;
export const STAFF_TAB = 1;
export const GROUPS_TAB = 2;
export const ARCHIVED_TAB = 3;
export const ARCHIVED_STAFF_TAB = 4;

const ALL_TABS = [
  CLIENTS_TAB,
  STAFF_TAB,
  GROUPS_TAB,
  ARCHIVED_TAB,
  ARCHIVED_STAFF_TAB,
];

const HASH_TO_TAB = {
  '#clients': CLIENTS_TAB,
  '#staff': STAFF_TAB,
  '#groups': GROUPS_TAB,
  '#archived': ARCHIVED_TAB,
  '#archived-staff': ARCHIVED_STAFF_TAB,
};

const TAB_TO_HASH = {
  [CLIENTS_TAB]: '#clients',
  [STAFF_TAB]: '#staff',
  [GROUPS_TAB]: '#groups',
  [ARCHIVED_TAB]: '#archived',
  [ARCHIVED_STAFF_TAB]: '#archived-staff',
};

class ContactsPageStore extends UpperHandStore {
  constructor() {
    super();

    this.selectedTab = HASH_TO_TAB[window.location.hash];
    this.archivedCount = 0;
    this.filterDrawerOpen = false;

    this.activeTab = this.bindListeners({
      mounted: ContactsPageActions.mounted,
      tabSelected: ContactsPageActions.tabSelected,

      archiveCountFetchSuccess: ContactsPageActions.archiveCountFetchSuccess,
      archiveCountFetchError: ContactsPageActions.archiveCountFetchError,

      toggleFilterDrawer: ContactsPageActions.toggleFilterDrawer,
    });
  }

  mounted() {
    this.selectedTab = HASH_TO_TAB[window.location.hash] || CLIENTS_TAB;
    this.fetchArchivedCount();
  }

  tabSelected(newTab) {
    if (!ALL_TABS.includes(newTab)) {
      return;
    }

    this.selectedTab = newTab;
    window.location.hash = TAB_TO_HASH[this.selectedTab];
  }

  // eslint-disable-next-line class-methods-use-this
  fetchArchivedCount() {
    ClientSource.list({
      params: { statuses: ['archived'], page: 1, per_page: 1 },
      success: ContactsPageActions.archiveCountFetchSuccess,
      error: ContactsPageActions.archiveCountFetchError,
    });
  }

  archiveCountFetchSuccess({ totalCount }) {
    this.archivedCount = totalCount;
  }

  archiveCountFetchError(...args) {
    this.notifyError('error fetching archived clients', args);
  }

  toggleFilterDrawer() {
    this.filterDrawerOpen = !this.filterDrawerOpen;
  }
}

export default alt.createStore(ContactsPageStore, 'ContactsPageStore');
