import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { Box } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Set } from 'immutable';
import moment from 'moment-timezone';

import { Card, Grid, Spinner, Typography } from '@upperhand/playmaker';

import Paginator from 'shared/components/Paginator.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import { smallScreen } from 'shared/utils/DOMUtils.js';
import { StaffDataStore, ScheduleDataStore } from 'dataStores';
import { weekdayLabels } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import ScheduleTableHeader from 'containers/events/admin/schedule/shared/ScheduleTableHeader.jsx';
import EventScheduleCancelActions from 'containers/eventScheduleCancellation/Actions';
import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';
import WarningIcon from 'containers/classes/classesList/components/Sessions/WarningIcon.jsx';
import EmptyState from './EmptyState.jsx';
import StaffSection from './StaffSection.jsx';

const styles = {
  cancelledIcon: {
    display: 'inline-block',
    marginRight: 10,
    marginTop: 5,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
};

function StartAtTime({ daytimes }) {
  const differentStartAtTimes = List(Object.values(daytimes))
    .flatMap(times => times.map(time => time.start_time))
    .toSet();
  return differentStartAtTimes.size === 1 ? (
    <FormattedMessage
      id={messageId('.start_at', __filenamespace)}
      values={{
        startsAt: moment(differentStartAtTimes.first(), 'HH:mm').format(
          'h:mma'
        ),
      }}
    />
  ) : (
    <FormattedMessage id={messageId('.multiple_start_at', __filenamespace)} />
  );
}

function ScheduleRepeatDaily({ interval }) {
  return interval === 1 ? (
    <FormattedMessage id={messageId('.every_day_repeat', __filenamespace)} />
  ) : (
    <FormattedMessage
      id={messageId('.daily_repeat', __filenamespace)}
      values={{ interval }}
    />
  );
}

function ScheduleRepeat({ daytimes, frequency, interval }) {
  if (frequency === 'daily') {
    return <ScheduleRepeatDaily interval={interval} />;
  }
  if (Object.keys(daytimes).length === 7) {
    return <FormattedMessage id={messageId('.daily', __filenamespace)} />;
  }
  return Object.keys(daytimes)
    .map(key => weekdayLabels.get(key))
    .join(', ');
}

function ScheduleDate({ startDate, endDate }) {
  if (startDate === endDate) {
    return moment(startDate).format('MMM DD');
  }
  return (
    <FormattedMessage
      id={messageId('.dates', __filenamespace)}
      values={{
        start: moment(startDate).format('MMM DD'),
        end: endDate ? moment(endDate).format('MMM DD') : 'Indefinite',
      }}
    />
  );
}

const ScheduleCard = injectIntl(({ staff, schedule, intl, isTeamEvent }) => {
  const {
    start_date: startDate,
    end_date: endDate,
    daytimes,
  } = schedule.availability_schedule;
  const isClient = currentUser().isClient();
  const menuOptions = [
    {
      label: t('.edit_schedule', intl, __filenamespace),
      onClick: () => EventSessionCreationActions.editSchedule({ schedule }),
      visible: true,
    },
    {
      label: t('.cancel_schedule', intl, __filenamespace),
      onClick: () =>
        EventScheduleCancelActions.toggleCancelModal({
          scheduleItem: schedule,
        }),
      visible: schedule.status !== 'cancelled',
    },
  ].filter(item => item.visible);

  if (smallScreen() && isTeamEvent) {
    return (
      <Card contentDirection="row" menuOptions={!isClient ? menuOptions : null}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            {schedule.status === 'cancelled' && (
              <>
                <span data-tip style={styles.cancelledIcon}>
                  <WarningIcon height={17} width={20} />
                </span>
                <ReactTooltip className="uh-tooltip" effect="solid">
                  {t('.status_cancelled', intl, __filenamespace)}
                </ReactTooltip>
              </>
            )}
            <Typography variant="subtitle2" display="inline">
              {schedule.get('label')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item>
                <Typography variant="subtitle2" display="inline">
                  <ScheduleDate startDate={startDate} endDate={endDate} />
                </Typography>
              </Grid>
              {schedule.location && (
                <Grid item>
                  <Typography>
                    {schedule.location ? schedule.location.name : ''}
                  </Typography>
                </Grid>
              )}

              {!isClient && (
                <Grid item>
                  <StaffSection
                    intl={intl}
                    staffIds={Set(schedule.customer_user_ids)}
                    staff={staff}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item>
                <StartAtTime daytimes={daytimes} />
              </Grid>
              <Grid item>
                <Typography>
                  <ScheduleRepeat
                    daytimes={schedule.availability_schedule.daytimes}
                    frequency={schedule.availability_schedule.frequency}
                    interval={schedule.availability_schedule.interval}
                  />
                </Typography>
              </Grid>
              {!isClient && (
                <Grid item>
                  <Box sx={{ display: 'flex' }}>
                    <ResourceIcon style={styles.icon} />
                    <Typography>{schedule.resource_ids.length}</Typography>
                  </Box>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
    );
  }
  if (isClient && isTeamEvent) {
    return (
      <Card contentDirection="row">
        <Grid container alignItems="center" xs={12} spacing={1}>
          <Grid item alignItems="center" xs={6} md={2} spacing={1}>
            <div style={styles.flexCenter}>
              {schedule.status === 'cancelled' && (
                <>
                  <span data-tip style={styles.cancelledIcon}>
                    <WarningIcon height={17} width={20} />
                  </span>
                  <ReactTooltip className="uh-tooltip" effect="solid">
                    {t('.status_cancelled', intl, __filenamespace)}
                  </ReactTooltip>
                </>
              )}
              <Typography variant="subtitle2" display="inline">
                {schedule.get('label')}
              </Typography>
            </div>
          </Grid>
          <Grid item alignItems="center" xs={6} md={2} spacing={1}>
            <div style={styles.flexCenter}>
              <Typography variant="subtitle2" display="inline">
                {schedule.availability_schedule.start_date ===
                schedule.availability_schedule.end_date ? (
                  moment(schedule.availability_schedule.start_date).format(
                    'MMM DD'
                  )
                ) : (
                  <FormattedMessage
                    id={messageId('.dates', __filenamespace)}
                    values={{
                      start: moment(
                        schedule.availability_schedule.start_date
                      ).format('MMM DD'),
                      end: schedule.availability_schedule.end_date
                        ? moment(
                            schedule.availability_schedule.end_date
                          ).format('MMM DD')
                        : 'Indefinite',
                    }}
                  />
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>
              <ScheduleRepeat
                daytimes={schedule.availability_schedule.daytimes}
                frequency={schedule.availability_schedule.frequency}
                interval={schedule.availability_schedule.interval}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography>
              <StartAtTime daytimes={schedule.availability_schedule.daytimes} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>
              {schedule.location ? schedule.location.name : ''}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }
  if (isTeamEvent) {
    return (
      <Card contentDirection="row" menuOptions={menuOptions}>
        <Grid container alignItems="center" xs={12} spacing={1}>
          <Grid item alignItems="center" xs={6} md={2} spacing={1}>
            <div style={styles.flexCenter}>
              {schedule.status === 'cancelled' && (
                <>
                  <span data-tip style={styles.cancelledIcon}>
                    <WarningIcon height={17} width={20} />
                  </span>
                  <ReactTooltip className="uh-tooltip" effect="solid">
                    {t('.status_cancelled', intl, __filenamespace)}
                  </ReactTooltip>
                </>
              )}
              <Typography variant="subtitle2" display="inline">
                {schedule.get('label')}
              </Typography>
            </div>
          </Grid>
          <Grid item alignItems="center" xs={6} md={2} spacing={1}>
            <div style={styles.flexCenter}>
              <Typography variant="subtitle2" display="inline">
                {schedule.availability_schedule.start_date ===
                schedule.availability_schedule.end_date ? (
                  moment(schedule.availability_schedule.start_date).format(
                    'MMM DD'
                  )
                ) : (
                  <FormattedMessage
                    id={messageId('.dates', __filenamespace)}
                    values={{
                      start: moment(
                        schedule.availability_schedule.start_date
                      ).format('MMM DD'),
                      end: schedule.availability_schedule.end_date
                        ? moment(
                            schedule.availability_schedule.end_date
                          ).format('MMM DD')
                        : 'Indefinite',
                    }}
                  />
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>
              <ScheduleRepeat
                daytimes={schedule.availability_schedule.daytimes}
                frequency={schedule.availability_schedule.frequency}
                interval={schedule.availability_schedule.interval}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} md={1}>
            <Typography>
              <StartAtTime daytimes={schedule.availability_schedule.daytimes} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>
              {schedule.location ? schedule.location.name : ''}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <StaffSection
              intl={intl}
              staffIds={Set(schedule.customer_user_ids)}
              staff={staff}
            />
          </Grid>

          <Grid item xs={12} md={1} alignItems="center" spacing={1}>
            <div style={styles.flexCenter}>
              <ResourceIcon style={styles.icon} />
              <Typography>{schedule.resource_ids.length}</Typography>
            </div>
          </Grid>
        </Grid>
      </Card>
    );
  }
  return (
    <Card contentDirection="row">
      <Grid container alignItems="center" xs={12} spacing={1}>
        <Grid item alignItems="center" xs={6} md={2} spacing={1}>
          <div style={styles.flexCenter}>
            <Typography variant="subtitle2" display="inline">
              {schedule.availability_schedule.start_date ===
              schedule.availability_schedule.end_date ? (
                moment(schedule.availability_schedule.start_date).format(
                  'MMM DD'
                )
              ) : (
                <FormattedMessage
                  id={messageId('.dates', __filenamespace)}
                  values={{
                    start: moment(
                      schedule.availability_schedule.start_date
                    ).format('MMM DD'),
                    end: schedule.availability_schedule.end_date
                      ? moment(schedule.availability_schedule.end_date).format(
                          'MMM DD'
                        )
                      : 'Indefinite',
                  }}
                />
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={2}>
          {schedule.availability_schedule.frequency && (
            <Typography>
              <ScheduleRepeat
                daytimes={schedule.availability_schedule.daytimes}
                frequency={schedule.availability_schedule.frequency}
                interval={schedule.availability_schedule.interval}
              />
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} md={1}>
          <Typography>
            <StartAtTime daytimes={schedule.availability_schedule.daytimes} />
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography>
            {schedule.location ? schedule.location.name : ''}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StaffSection
            intl={intl}
            staffIds={Set(schedule.customer_user_ids)}
            staff={staff}
          />
        </Grid>
        <Grid item xs={12} md={1} alignItems="center" spacing={1}>
          <div style={styles.flexCenter}>
            <ResourceIcon style={styles.icon} />
            <Typography>{schedule.resource_ids.length}</Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
});

function ScheduleList({
  filtersPresent,
  isLoading,
  scheduleIds,
  scheduleDataStore: { schedules },
  staffDataStore: { staff },
  page,
  onPageSelect,
  perPage,
  totalCount,
  isTeamEvent,
}) {
  return isLoading ? (
    <Grid item xs={12}>
      <div style={{ textAlign: 'center' }}>
        <Spinner type="indeterminate" />
      </div>
    </Grid>
  ) : (
    <>
      <Grid item container spacing={1}>
        {!smallScreen(959) && (
          <Grid item md={12}>
            <ScheduleTableHeader isTeamEvent={isTeamEvent} />
          </Grid>
        )}
        {scheduleIds.map(scheduleId => (
          <Grid item key={scheduleId} xs={12}>
            <ScheduleCard
              scheduleId={scheduleId}
              schedule={schedules.get(scheduleId)}
              staff={staff}
              isTeamEvent={isTeamEvent}
            />
          </Grid>
        ))}
      </Grid>
      {scheduleIds.isEmpty() && (
        <Grid item xs={12}>
          <EmptyState
            filtersPresent={filtersPresent}
            scheduleViewMode
            isTeamEvent={isTeamEvent}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paginator
          currentPage={page}
          onPageSelect={onPageSelect}
          perPage={perPage}
          totalCount={totalCount}
        />
      </Grid>
    </>
  );
}

export default altContainer({
  stores: {
    staffDataStore: StaffDataStore,
    scheduleDataStore: ScheduleDataStore,
  },
})(ScheduleList);
