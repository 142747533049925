import * as React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

import StatisticItem from './StatisticItem.jsx';

function Statistics({ intl, statistics }) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <Grid item xs>
        <StatisticItem
          title={t('.buyers', intl, __filenamespace)}
          tooltip={t('.buyers_tooltip', intl, __filenamespace)}
          value={statistics.get('unique_client_count') || 0}
        />
      </Grid>
      <Grid item xs>
        <StatisticItem
          title={t('.total_paid', intl, __filenamespace)}
          tooltip={t('.total_paid_tooltip', intl, __filenamespace)}
          value={intl.formatNumber((statistics.get('total_paid') || 0) / 100, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </Grid>
      <Grid item xs>
        <StatisticItem
          title={t('.outstanding_balance', intl, __filenamespace)}
          tooltip={t('.outstanding_balance_tooltip', intl, __filenamespace)}
          value={intl.formatNumber(
            Math.abs(statistics.get('outstanding_balance') || 0) / 100,
            {
              style: 'currency',
              currency: 'USD',
            }
          )}
        />
      </Grid>
    </Grid>
  );
}

Statistics.propTypes = {
  statistics: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Statistics);
