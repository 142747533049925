import * as React from 'react';
import { injectIntl } from 'react-intl';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import ResourceIcon from 'shared/components/icons/Resource.jsx';
import Selectbox from 'calendar/components/quick_schedule/Selectbox.jsx';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Resource: {
    position: 'relative',
    width: '100%',
  },
  MenuItem: {
    width: '100%',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Loading: {
    textAlign: 'center',
  },
  ResourceIcon: {
    margin: '0 8px 0 0',
    width: 18,
    height: 18,
  },
  RemoveButton: {
    cursor: 'pointer',
    padding: '3px',
  },
  ReourceItem: {
    paddingLeft: '16px',
  },
};

const handleSelect = resource => {
  QuickScheduleActions.selectResource(resource);
};

const handleOnRemoveResource = resource => {
  QuickScheduleActions.removeResource(resource);
};

const menuItems = ({
  filteredResources,
  resourceListingStore,
  useAllResource,
}) => {
  if (!resourceListingStore.isLoading) {
    return filteredResources.map(resource => (
      <MenuItem
        key={resource.id}
        onClick={() => {
          if (!useAllResource) {
            handleSelect(resource);
          }
        }}
        style={styles.MenuItem}
      >
        <ResourceWithName resource={resource} />
      </MenuItem>
    ));
  }
  return (
    <div style={styles.Loading}>
      <CircularProgress size={45} />
    </div>
  );
};

function ResourceWithName({ resource }) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <ResourceIcon color={uhColors.leftNavGrey} style={styles.ResourceIcon} />
      <div>{resource.name}</div>
    </Stack>
  );
}

function SelectedResource({ resource, removable }) {
  return (
    <div style={{ padding: '3px 0' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.ReourceItem}
      >
        <ResourceWithName resource={resource} />
        {removable && (
          <IconButton
            sx={styles.RemoveButton}
            onClick={() => handleOnRemoveResource(resource)}
          >
            <CloseIcon
              sx={{ height: 21, width: 21, color: uhColors.iconLightGrey }}
            />
          </IconButton>
        )}
      </Stack>
    </div>
  );
}

function Resource({ intl, quickScheduleStore, resourceListingStore }) {
  const {
    selectedResources,
    filteredResources,
    selectedEvent,
    lockResourcePicker,
  } = quickScheduleStore;
  const useAllResource =
    selectedEvent.getSchedule().resource_usage_mode === 'use_all';

  return (
    <div style={{ marginBottom: 10 }}>
      <Selectbox
        icon={
          <ResourceIcon
            style={{ height: 18, width: 18 }}
            color={uhColors.tableGrey}
          />
        }
        locked={useAllResource || lockResourcePicker}
        menuItems={menuItems({
          filteredResources,
          resourceListingStore,
          useAllResource,
        })}
        hintText={t('.choose_resource', intl, __filenamespace)}
      />
      {selectedResources.map(resource => (
        <SelectedResource
          key={resource.id}
          resource={resource}
          removable={!useAllResource && !lockResourcePicker}
        />
      ))}
    </div>
  );
}

export default injectIntl(Resource);
