import React, { memo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { List } from 'immutable';
import { Card, Typography, Icon, Grid } from '@upperhand/playmaker';
import Star from '@mui/icons-material/Star';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import DiscountBenefits from 'memberships/components/_DiscountBenefits/_DiscountBenefits.jsx';
import CreditBenefits from 'memberships/components/_CreditBenefits.jsx';
import ExclusiveEventBenefits from 'memberships/components/_ExclusiveEventBenefits.jsx';

import { isSimilarColor } from 'shared/utils/StyleUtils';

import Membership from 'shared/records/Membership.jsx';
import MembershipTier from 'shared/records/MembershipTier';

import { t } from 'shared/utils/LocaleUtils';

import './styles.scss';

const inlineStyles = {
  benefitsContainer: {
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  benefitItem: {
    marginLeft: -2,
  },
  benefitItemText: {
    fontSize: 12,
    marginLeft: 4,
  },
};

function MembershipTierCard({
  hideExpand,
  expanded,
  tier,
  membership,
  eventTypes,
  creditPasses,
  retailCategories,
}) {
  const intl = useIntl();
  const [benefitsExpanded, setBenefitsExpanded] = useState(expanded);

  if (!tier) return null;

  return (
    <Card
      classes={{
        root: clsx(
          'mem-vier-tier',
          tier.best_value && 'mem-vier-tier__best-value'
        ),
        content: 'mem-vier-tier__content',
      }}
    >
      <div
        className="mem-vier-tier__info"
        style={{
          borderLeftColor: isSimilarColor(membership.color, '#393c44', 50)
            ? '#fff'
            : membership.color,
        }}
      >
        <Typography
          variant="body1"
          className={clsx(
            'mem-vier-tier__info-title',
            tier.best_value && 'mem-vier-tier__info-title-best'
          )}
        >
          {tier.name}
        </Typography>
        {tier.best_value && (
          <div className="mem-vier-tier__info-title-best-label">
            <Star />
            <span>{t('.best_value', intl, __filenamespace)}</span>
          </div>
        )}
        <Typography className="mem-vier-tier__pricing">
          <Typography variant="body1">
            <FormattedCurrency fromCents value={tier.price} />
            {tier.intervalMonths && <span>/{tier.intervalMonths}</span>}
          </Typography>
          <Typography className="mem-vier-tier__pricing-info">
            {t('.pricing_info', intl, __filenamespace)}
          </Typography>
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="subtitle2" className="mem-vier-tier__label">
              {t('.join_fee', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className="mem-vier-tier__value">
              {tier.join_fee ? (
                <FormattedCurrency fromCents value={tier.join_fee} />
              ) : (
                t('.no_join_fee', intl, __filenamespace)
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" className="mem-vier-tier__label">
              {t('.commitment', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className="mem-vier-tier__value">
              {tier.commitmentMonths ||
                t('.no_commitment', intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="mem-vier-tier__expander-container">
        {!hideExpand && (
          <button
            type="button"
            className="mem-vier-tier__expander-btn"
            onClick={() => setBenefitsExpanded(!benefitsExpanded)}
          >
            <Typography>
              {t('.view_benefits', intl, __filenamespace)}
            </Typography>
            <Icon name={benefitsExpanded ? 'arrowUp' : 'arrowDown'} />
          </button>
        )}
        {benefitsExpanded && (
          <div className="mem-vier-tier__benefits-list">
            {tier.hasBenefits && (
              <>
                <CreditBenefits
                  membership={tier}
                  creditBenefitsStyle={inlineStyles.benefitsContainer}
                />
                <DiscountBenefits
                  membership={tier}
                  eventTypes={eventTypes}
                  creditPasses={creditPasses}
                  retailCategories={retailCategories}
                  itemStyle={inlineStyles.benefitItem}
                  textStyle={inlineStyles.benefitItemText}
                  customWrapper={inlineStyles.benefitsContainer}
                />
                <ExclusiveEventBenefits
                  membership={tier}
                  eventTypes={eventTypes}
                />
              </>
            )}
            {!tier.hasBenefits && (
              <Typography>
                {t('.no_benefits', intl, __filenamespace)}
              </Typography>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

MembershipTierCard.propTypes = {
  expanded: PropTypes.bool,
  hideExpand: PropTypes.bool,
  tier: PropTypes.oneOfType([
    PropTypes.instanceOf(MembershipTier),
    PropTypes.instanceOf(Object),
  ]),
  membership: PropTypes.instanceOf(Membership),
  eventTypes: PropTypes.instanceOf(List),
  creditPasses: PropTypes.instanceOf(List),
  retailCategories: PropTypes.instanceOf(List),
};

MembershipTierCard.defaultProps = {
  expanded: false,
  hideExpand: false,
  tier: new MembershipTier(),
  membership: new Membership(),
  eventTypes: List(),
  creditPasses: List(),
  retailCategories: List(),
};

export default memo(MembershipTierCard);
