import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Confirmation, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

function MembershipTierDeleteConfirmation({
  membershipTierId,
  onConfirm,
  onCancel,
}) {
  const intl = useIntl();

  if (!membershipTierId) return null;

  return (
    <Confirmation
      classes={{
        root: 'delete-modal',
        content: 'delete-modal__content',
      }}
      title={
        <Typography variant="h5">
          {t('.info', intl, __filenamespace)}
        </Typography>
      }
      confirmButtonLabel={t('.confirm', intl, __filenamespace)}
      cancelButtonLabel={t('.cancel', intl, __filenamespace)}
      open={Boolean(membershipTierId)}
      hasCheckbox={false}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Typography>&nbsp;</Typography>
    </Confirmation>
  );
}

MembershipTierDeleteConfirmation.propTypes = {
  membershipTierId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

MembershipTierDeleteConfirmation.defaultProps = {
  membershipTierId: null,
  onConfirm: () => {},
  onCancel: () => {},
};

export default memo(MembershipTierDeleteConfirmation);
