import NativePlugin from 'shared/native/helpers/NativePlugin';
import wait from 'shared/utils/Promise';

class Wifi extends NativePlugin {
  constructor() {
    super('cordova.plugins.zebraPrinter.wifi');
  }

  /**
   * Discovers local devices over WiFi. It may take a few seconds to resolve.
   *
   * @param responsesTimeout Length of time to scan each IP Address
   * @param retries Number of attempts before failing
   * @param promise A promise to pass the previous attempt to the next one
   *
   * @return Promise<[Device]> List of available printer devices
   */
  discover(responsesTimeout = 10, retries = 3, resolve = null, reject = null) {
    if (resolve === null && reject === null) {
      return this.Promise((originalResolve, originalReject) => {
        this.plugin().discover(responsesTimeout, originalResolve, () =>
          this.discover(
            responsesTimeout + 5,
            retries - 1,
            originalResolve,
            originalReject
          )
        );
      });
    }
    if (retries > 0) {
      this.plugin().discover(responsesTimeout, resolve, () =>
        this.discover(responsesTimeout + 5, retries - 1, resolve, reject)
      );
    } else {
      reject();
    }
  }

  /**
   * Determine current printer connectivity status.
   *
   * @return Promise<Bool> True if printer is connected, false otherwise.
   */
  isConnected() {
    return this.Promise((resolve, reject) => {
      this.plugin().isConnected(resolve, reject);
    });
  }

  /**
   * Connects to a device.
   *
   * @param device Printer device
   * @param port   (Optional) Network port to use
   *
   * @return Promise<nil> resolves if successfully connected to printer
   */
  connect(device, port = 6101, timeout = 10000) {
    return this.Promise((resolve, reject) => {
      this.isConnected()
        .then(connected => {
          if (connected) {
            resolve();
          } else {
            this.plugin().connect(device, port, resolve, reject);
            setTimeout(() => {
              reject();
            }, timeout);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Disconnects
   *
   * @return Promise<nil> Resolves if successfully disconnected from the printer.
   */
  disconnect() {
    return this.Promise((resolve, reject) => {
      this.plugin().disconnect(resolve, reject);
    });
  }

  /**
   * Sends data to the currently connected device.
   *
   * @param zpl ZPL command string
   *
   * @return Promise<nil> resolves if printer received the command
   */
  send(zpl) {
    return this.Promise((resolve, reject) => {
      this.plugin().send(zpl, resolve, reject);
    });
  }

  /**
   * Prints to the currently connected device.
   *
   * @param zpl ZPL command string
   *
   * @return Promise<nil> resolves if printer received the command
   */
  print(zpl) {
    return this.Promise((resolve, reject) => {
      this.plugin().print(zpl, resolve, reject);
    });
  }

  /**
   * Read data from the printer
   *
   * If you need to send and read, use the sendThenRead method instead.
   * There needs to be a slight delay between sending and reading.
   *
   * @return Promise<String> Printer response
   */
  read() {
    return this.Promise((resolve, reject) => {
      this.plugin().read(resolve, reject);
    });
  }

  /**
   * Send and read at once
   *
   * @return Promise<String> Printer response
   */
  async sendThenReadZPL(zpl) {
    await this.send(zpl);

    // Allow the printer to finish gathering all the information before reading.
    // Without a delay you get nothing or only a partial message from the printer.
    await wait(200);

    return this.read();
  }
}

export default new Wifi();
