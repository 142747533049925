import { Record, Set } from 'immutable';
import Discount from 'shared/records/Discount.js';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

class MembershipCreditPassDiscount extends Record({
  id: null,
  all_credit_passes: true,
  credit_pass_ids: Set(),
  value: 0,
  errors: new FieldErrors(),
  discount_type: 'percent',
  subscribable_id: null,
  subscribable_color: null,
  subscribable_name: null,
  subscribable_type: null,
}) {
  constructor(object = {}, _options = {}) {
    super(
      merge(object, {
        credit_pass_ids: Set(object.credit_pass_ids),
      })
    );
  }

  validate() {
    let errors = new FieldErrors();

    if (!isPresent(this.value)) {
      errors = errors.add('value', 'records.errors.required');
    }

    if (
      isPresent(this.value) &&
      this.isPercentDiscount() &&
      (this.value <= 0.0 || this.value > 100.0)
    ) {
      errors = errors.add(
        'value',
        'records.MembershipCreditPassDiscount.errors.value_out_of_range'
      );
    }

    if (isPresent(this.value) && !this.isPercentDiscount() && this.value <= 0) {
      errors = errors.add(
        'value',
        'records.MembershipCreditPassDiscount.errors.membership_price_validation'
      );
    }
    if (
      this.all_credit_passes &&
      isPresent(this.credit_pass_ids) &&
      !this.credit_pass_ids.isEmpty()
    ) {
      errors = errors.add(
        'all_credit_passes',
        'records.MembershipCreditPassDiscount.errors.no_credit_passes_when_all_credit_passes'
      );
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  isPercentDiscount() {
    return this.discount_type === 'percent';
  }

  get discount() {
    return new Discount({
      type: this.discount_type,
      value: this.value,
    });
  }
}

export default MembershipCreditPassDiscount;
