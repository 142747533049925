import React from 'react';
import { Grid, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

function HeaderDesktop({ intl }) {
  const activeItems = 6;

  return (
    <div className="payment-plans__header">
      <Grid container spacing={1} alignContent="center" justify="center">
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography variant="body3" className="payment-plans__header-item">
            {t('.client', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography variant="body3" className="payment-plans__header-item">
            {t('.initial_amount', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography variant="body3" className="payment-plans__header-item">
            {t('.remaining_amount', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography variant="body3" className="payment-plans__header-item">
            {t('.start_date', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography variant="body3" className="payment-plans__header-item">
            {t('.end_date', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item className={`even-cols-${activeItems}`}>
          <Typography variant="body3" className="payment-plans__header-item">
            {t('.next_payment_date', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
      <div className="payment-plans__row-action">
        {/* Making space for the gear icon. */}
      </div>
    </div>
  );
}

export default HeaderDesktop;
