import * as React from 'react';

import CustomerTermsBanner from 'shared/components/_CustomerTermsBanner.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import TermsTemplate from 'shared/components/_TermsTemplate.jsx';

function Terms() {
  return (
    <div style={{ marginBottom: 54 }}>
      <FlexBoxJustifyCenter style={{ height: 116 }}>
        <img
          alt="logo"
          src={window.customer.logo.url}
          style={{ paddingTop: 25, paddingBottom: 25 }}
        />
      </FlexBoxJustifyCenter>
      <CustomerTermsBanner />
      <FlexBoxJustifyCenter
        style={{
          marginTop: '61px',
          overflowY: 'scroll',
        }}
      >
        <TermsTemplate terms={window.customer.policy_and_terms} />
      </FlexBoxJustifyCenter>
    </div>
  );
}

export default Terms;
