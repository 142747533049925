import { Map } from 'immutable';
import { UpperHandStore } from 'dataStores';
import { CreditCountActions } from 'sources';

class CreditCountDataStore extends UpperHandStore {
  constructor() {
    super();

    // Usage - eventClients.getIn([eventID,clientId])
    this.eventClients = Map();
    // Usage - clientEvents.getIn([clientId,eventID])
    this.clientEvents = Map();

    this.bindListeners({
      listError: [CreditCountActions.listError],
      listSuccess: [CreditCountActions.listSuccess],
    });
  }

  listError(...args) {
    this.notifyError('error listing credit counts', args);
  }

  listSuccess({ credit_counts: creditCounts }) {
    const eClient = creditCounts
      .groupBy(e => e.event_id)
      .map(e => e.groupBy(c => c.client_id).map(x => x.first()));

    const cEvent = creditCounts
      .groupBy(c => c.client_id)
      .map(c => c.groupBy(e => e.event_id).map(x => x.first()));

    this.eventClients = this.eventClients.merge(eClient);
    this.clientEvents = this.clientEvents.merge(cEvent);
  }
}

export default alt.createStore(CreditCountDataStore, 'CreditCountDataStore');
