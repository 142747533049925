import React from 'react';
import { injectIntl } from 'react-intl';
import { grey, blue } from '@mui/material/colors';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import ButtonGroup from '@mui/material/ButtonGroup';
import Drawer from '@mui/material/Drawer';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ClearIcon from '@mui/icons-material/Clear';

import AutomationEditor from 'automations/editing/components/AutomationEditor.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import uhTheme from '_uh_theme.jsx';

import './styles.scss';

const styles = {
  automationsButtonContainerStyle: {
    width: '100%',
    alignItems: 'flex-start',
    marginLeft: 10,
  },

  automationsButtonStyle: {
    backgroundColor: 'transparent',
    fontSize: 16,
    marginLeft: 0,
    marginRight: 0,
  },

  drawerContent: {
    padding: '1.5rem',
    fontSize: '0.95em',
    margin: '1rem',
  },

  toolbar: {
    padding: '0 0 0 0',
    color: 'white',
    backgroundColor: uhTheme.palette.charcoalBlack,
  },

  toolbarGroupStyle: {
    flex: '1 100%',
  },
};

class AutomationEditDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.handleListDrawerButtonAction =
      this.handleListDrawerButtonAction.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  shouldComponentUpdate(nextProps, _nextState) {
    const { automationTemplateDescription } = this.props;

    return (
      automationTemplateDescription !== nextProps.automationTemplateDescription
    );
  }

  handleListDrawerButtonAction() {
    const { onListDrawerRequest } = this.props;

    if (onListDrawerRequest) {
      onListDrawerRequest(true, 'user initiated back to automations');
    }
  }

  handleSave() {
    const { onSave } = this.props;

    if (onSave) {
      onSave();
    }
  }

  render() {
    const { intl, canEditMore, automationTemplateDescription, open, onCancel } =
      this.props;
    const buttonStyles = {
      button: Object.assign(styles.automationsButtonStyle, {
        color: canEditMore ? 'white' : grey[300],
      }),
      icon: {
        color: canEditMore ? uhTheme.palette.primary1Color : blue[100],
      },
    };

    return (
      <Drawer
        disableEnforceFocus
        open={open}
        anchor="right"
        PaperProps={{ classes: { root: 'automation-edit-drawer' } }}
        onClose={onCancel}
      >
        <Toolbar
          style={styles.toolbar}
          classes={{ root: 'automation-edit-drawer__toolbar' }}
        >
          <ButtonGroup fullWidth style={styles.toolbarGroupStyle}>
            <div style={styles.automationsButtonContainerStyle}>
              <Button
                variant="text"
                disabled={!canEditMore}
                size="medium"
                classes={{ root: 'automation-edit-drawer__toolbar-title' }}
                onClick={this.handleListDrawerButtonAction}
                startIcon={<ChevronLeftIcon color={buttonStyles.icon.color} />}
              >
                {t('.automations', intl, __filenamespace)}
              </Button>
            </div>
            <IconButton
              classes={{ root: 'automation-edit-drawer__close-icon' }}
              onClick={onCancel}
            >
              <ClearIcon color="#5B6A72" />
            </IconButton>
          </ButtonGroup>
        </Toolbar>
        <div style={styles.drawerContent}>
          <AutomationEditor
            template={automationTemplateDescription}
            onSave={this.handleSave}
          />
        </div>
      </Drawer>
    );
  }
}

export default injectIntl(AutomationEditDrawer);
