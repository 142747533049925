import * as React from 'react';
import moment from 'moment-timezone';

import EventMarketingActions from 'event_mgmt/display/actions/EventMarketingActions';
import EventMarketingForm from 'event_mgmt/display/components/_EventMarketingForm.jsx';

class EventMarketing extends React.Component {
  handleFormClose = shouldSave => {
    if (shouldSave) {
      const { onSave } = this.props;

      onSave();
    }

    EventMarketingActions.formClosed();
  };

  handleSendDateChange = newDate => {
    const { sendTime, sendDate, onChange } = this.props;
    const time = moment(sendTime, 'HH:mm');
    const sendHours = time.get('hours');
    const sendMinutes = time.get('minutes');

    const year = newDate.year();
    const month = newDate.month();
    const date = newDate.date();
    const changedDate = sendDate
      .clone()
      .set('year', year)
      .set('month', month)
      .set('date', date)
      .set('hour', sendHours)
      .set('minute', sendMinutes);

    onChange({ sendDate: changedDate });
  };

  handleSendTimeChange = time => {
    const { sendDate, onChange } = this.props;
    const newTime = moment(time);
    const sendHours = newTime.get('hours');
    const sendMinutes = newTime.get('minutes');
    const newDate = sendDate
      .clone()
      .set('hour', sendHours)
      .set('minute', sendMinutes);

    onChange({ sendDate: newDate });
  };

  render() {
    const {
      formOpen,
      sendDate,
      sendTime,
      allContacts,
      selectedContacts,
      allContactGroups,
      selectedContactGroups,
      onContactSelection,
      onContactDeselection,
      onContactGroupSelection,
      onContactGroupDeselection,
    } = this.props;

    return (
      <EventMarketingForm
        open={formOpen}
        onFormClose={this.handleFormClose}
        sendDate={sendDate}
        sendDateErrorText={sendDate ? null : 'required'}
        onSendDateChange={this.handleSendDateChange}
        sendTime={sendTime}
        sendTimeErrorText={sendTime ? null : 'required'}
        onSendTimeChange={this.handleSendTimeChange}
        allContacts={allContacts}
        selectedContacts={selectedContacts}
        onContactSelection={onContactSelection}
        onContactDeselection={onContactDeselection}
        allContactGroups={allContactGroups}
        selectedContactGroups={selectedContactGroups}
        onContactGroupSelection={onContactGroupSelection}
        onContactGroupDeselection={onContactGroupDeselection}
      />
    );
  }
}

export default EventMarketing;
