import MembershipChargeDateActions from 'memberships/actions/MembershipChargeDateActions.js';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { MembershipSubscriptionSource } from 'sources';

class MembershipChargeDateStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      toggleShowChargeDate: MembershipChargeDateActions.toggleShowChargeDate,
      setChargeDate: MembershipChargeDateActions.setChargeDate,
      handleChargeDateChange:
        MembershipChargeDateActions.handleChargeDateChange,
      handleChargeDateChangeSuccess:
        MembershipChargeDateActions.handleChargeDateChangeSuccess,
      handleChargeDateChangeError:
        MembershipChargeDateActions.handleChargeDateChangeError,
    });
  }

  reset() {
    this.showChargeDate = false;
    this.chargeDate = null;
    this.membershipId = null;
  }

  toggleShowChargeDate(membershipId) {
    if (membershipId) {
      this.membershipId = membershipId;
      this.showChargeDate = true;
    } else {
      this.reset();
    }
  }

  setChargeDate(date) {
    this.chargeDate = date;
  }

  handleChargeDateChange() {
    const params = { attributes: { expires_at: this.chargeDate } };
    MembershipSubscriptionSource.putChargeDate({
      membershipId: this.membershipId,
      params,
      success: MembershipChargeDateActions.handleChargeDateChangeSuccess,
      error: MembershipChargeDateActions.handleChargeDateChangeError,
    });
  }

  handleChargeDateChangeSuccess() {
    this.reset();
    MessageWindowActions.addMessage.defer('Charge date changed successfully!');
  }

  handleChargeDateChangeError(...args) {
    this.notifyError('error changing subscription charge date', args);
  }
}

export default alt.createStore(
  MembershipChargeDateStore,
  'MembershipChargeDateStore'
);
