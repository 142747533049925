import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import { ClientDataStore } from 'dataStores';

const styles = {
  content: {
    paddingLeft: 8,
  },
};

const showClient = (client, eventId) => {
  ClientProfileDrawerActions.mounted({ clientId: client.id, eventId });
};

const removeClient = (client, eventId) => {
  ClientProfileDrawerActions.mounted({
    clientId: client.id,
    eventId,
    openDrawer: false,
  });
  ClientProfileDrawerActions.openScheduleDrawer({ clientId: client.id });
};

const ClientCard = injectIntl(
  ({ clientId, eventId, clientDataStore: { clients }, intl }) => {
    const client = clients.get(clientId);

    if (!client) {
      return null;
    }

    const menuOptions = [
      {
        label: t('.view', intl, __filenamespace),
        onClick: () => showClient(client, eventId),
      },
      {
        label: t('.remove', intl, __filenamespace),
        onClick: () => removeClient(client, eventId),
      },
    ];

    return (
      <Card contentDirection="row" menuOptions={menuOptions}>
        <div style={styles.content}>
          <Grid container alignItems="center" xs={12} spacing={1}>
            <Grid container item alignItems="center" xs={12} md={5}>
              <Grid item>
                <UserAvatar user={client} />
              </Grid>
              <Grid item>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => showClient(client, eventId)}
                  role="presentation"
                >
                  <Typography>{client.name()}</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Typography>
                {client.gender !== null && (
                  <FormattedMessage
                    id={messageId(`.${client.gender}`, __filenamespace)}
                  />
                )}
                {client.gender === null && <span>&mdash;</span>}
              </Typography>
            </Grid>
            <Grid item xs={11} md={2}>
              {client.age()} &middot;{' '}
              {moment(client.date_of_birth).format(
                t('dates.full', intl, __filenamespace)
              )}
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>{client.managing_customer_user_name}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>{client.phone}</Typography>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  }
);

ClientCard.propTypes = {
  clientId: PropTypes.number,
  eventId: PropTypes.number,
  clientDataStore: PropTypes.object,
  intl: PropTypes.object,
};

ClientCard.defaultProps = {
  clientId: null,
  eventId: null,
  clientDataStore: {},
  intl: {},
};

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(ClientCard);
