/* eslint-disable max-classes-per-file */
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import AltContainer from 'alt-container';
import moment from 'moment-timezone';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import { Box, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MUIButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import AttendeeIcon from 'shared/components/icons/Attendee.jsx';
import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import DateRange from 'shared/components/DateRange.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import EmptyPayrollReport from 'shared/components/icons/empty_states/PayrollReport.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import PayrollReportNoStaffOrTasks from 'shared/components/icons/empty_states/PayrollReportNoStaffOrTasks.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import TimeLogModal from 'reporting/payroll/components/TimeLogModal.jsx';
import PayrollEntryModal from 'reporting/payroll/components/PayrollEntryModal.jsx';
import Filters from 'reporting/payroll/components/_Filters.jsx';
import PayrollFilterDrawer from 'reporting/payroll/components/_PayrollFilterDrawer.jsx';
import PayrollComissionsEntryModal from 'reporting/payroll/components/PayrollCommissionsEntryModal.jsx';
import PageHeader from 'components/PageHeader/PageHeader.jsx';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { snakeToTitleCase } from 'event_mgmt/shared/utils/FormattingUtils.jsx';

import Staff from 'shared/records/Staff.jsx';
import Task from 'shared/records/Task';
import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import TaskListingActions from 'shared/actions/TaskListingActions';
import TimeLogCreationActions from 'reporting/payroll/actions/TimeLogCreationActions';
import TimeLogEditingActions from 'reporting/payroll/actions/TimeLogEditingActions';
import TimeLogDeletionActions from 'reporting/payroll/actions/TimeLogDeletionActions';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import CommissionsCreationActions from 'reporting/payroll/actions/Commissions/CommissionsCreationActions';
import CommissionsEditingActions from 'reporting/payroll/actions/Commissions/CommissionsEditingActions';
import TaskListingStore from 'shared/stores/TaskListingStore';
import AutoCompleteClientListStore from 'shared/stores/AutoCompleteClientListStore';
import AutoCompleteStaffListStore from 'shared/stores/AutoCompleteStaffListStore';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import PayrollReportStore from 'reporting/payroll/stores/PayrollReportStore';
import TimeLogCreationStore from 'reporting/payroll/stores/TimeLogCreationStore';
import TimeLogEditingStore from 'reporting/payroll/stores/TimeLogEditingStore';
import CommissionsCreationStore from 'reporting/payroll/stores/Commissions/CommissionsCreationStore';
import CommissionsEditingStore from 'reporting/payroll/stores/Commissions/CommissionsEditingStore';
import PayrollEntryEditingActions from 'reporting/payroll/actions/PayrollEntryEditingActions';
import PayrollEntryEditingStore from 'reporting/payroll/stores/PayrollEntryEditingStore';
import TimeLogDeletionStore from 'reporting/payroll/stores/TimeLogDeletionStore';
import CommissionRateListingStore from 'contacts/shared/stores/commission_rates/CommissionRateListingStore';
import AutoCompleteItemListStore from 'shared/stores/AutoCompleteItemListStore';
import AutoCompleteRetailListStore from 'shared/stores/AutoCompleteRetailListStore';
import AutoCompleteAttendeeListStore from 'shared/stores/AutoCompleteAttendeeListStore';

import '../styles.scss';

const UNDEFINED = 'undefined';
const styles = {
  paidUnpaidTotal: {
    fontWeight: 'normal',
  },

  dayOfWeek: {
    fontWeight: 'bold',
    paddingRight: 0,
    textTransform: 'uppercase',
    width: '3em',
  },

  attendees: {
    width: '12em',
  },

  date: {
    width: '7em',
  },

  period: {
    width: '10em',
  },

  task: {
    fontWeight: 'bold',
    maxWidth: 500,
    width: '12em',
  },

  clockAction: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginRight: 5,
  },

  clockInOutTime: {
    width: '24em',
  },

  eventName: {
    width: '24em',
    fontWeight: 'bold',
  },

  hours: {
    fontWeight: 'bold',
    width: '7em',
  },

  hourly_rate: {
    width: '10em',
  },

  totalEarnings: {
    width: '10em',
  },

  contextMenu: {
    padding: 0,
    width: '3em',
  },
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function StaffInformation({ staffMember }) {
  return (
    <FlexBoxCenter>
      <AvatarWithName
        user={staffMember}
        avatarOptions={{ size: 24 }}
        style={{ fontWeight: 'bold' }}
      />
      <div style={{ marginLeft: 10, fontWeight: 'normal', fontSize: 13 }}>
        <FormattedMessage
          id={messageId('.staff_hourly_rate', __filenamespace)}
          values={{
            rate: (
              <FormattedCurrency value={staffMember.hourly_rate} fromCents />
            ),
          }}
        />
      </div>
    </FlexBoxCenter>
  );
}

function PaidUnpaidTotal({ totals }) {
  return (
    <FormattedMessage
      id={messageId('.paid_and_total', __filenamespace)}
      values={{
        paid: (
          <span style={{ fontWeight: 'bold' }}>
            <FormattedCurrency value={totals.get('totalPaid')} fromCents />
          </span>
        ),
        total: (
          <span style={{ fontWeight: 'bold' }}>
            <FormattedCurrency value={totals.get('totalEarned')} fromCents />
          </span>
        ),
      }}
    />
  );
}

function ClockIn({ time }) {
  return (
    <span>
      <span style={styles.clockAction}>
        <FormattedMessage id={messageId('.in', __filenamespace)} />
      </span>
      {moment(time).format('hh:mm:ss a')}
    </span>
  );
}

function ClockOut({ time }) {
  if (time) {
    return (
      <span>
        <span style={styles.clockAction}>
          <FormattedMessage id={messageId('.out', __filenamespace)} />
        </span>
        {moment(time).format('hh:mm:ss a')}
      </span>
    );
  }
  return (
    <span style={merge(styles.clockAction, { color: uhColors.green })}>
      <FormattedMessage id={messageId('.active', __filenamespace)} />
    </span>
  );
}

function Hours({ hours }) {
  if (hours === null) {
    return <FormattedMessage id={messageId('.dash', __filenamespace)} />;
  }

  return (
    <FormattedMessage
      id={messageId('.n_hours', __filenamespace)}
      values={{
        n: (
          <FormattedNumber
            value={hours}
            minimumFractionDigits={1}
            maximumFractionDigits={1}
          />
        ),
      }}
    />
  );
}

function Attendees({ attendees, id }) {
  return (
    <FlexBoxCenter>
      <AttendeeIcon
        data-tip
        data-for={`attendees-info-${id}`}
        color={uhColors.tableGrey}
        style={{ height: 20, width: 20, marginLeft: 5 }}
      />
      {attendees.size > 1 ? (
        <>
          <div
            style={{ fontSize: 13, color: uhColors.hintActive, marginLeft: 3 }}
          >
            <FormattedMessage
              id={messageId('.attendees', __filenamespace)}
              values={{ n: attendees.size }}
            />
          </div>
          <ReactTooltip
            id={`attendees-info-${id}`}
            effect="solid"
            style={{
              maxWidth: 240,
              fontWeight: 'bold',
              color: 'var(--color-white)',
              fontSize: 12,
            }}
            className="charge-failure-tooltip uh-tooltip"
          >
            {attendees.map((attendee, i) => (
              /* eslint-disable react/no-array-index-key */
              <div key={i} style={{ margin: '1px 0' }}>
                {attendee}
              </div>
              /* eslint-enable react/no-array-index-key */
            ))}
          </ReactTooltip>
        </>
      ) : (
        <div
          style={{ fontSize: 13, color: uhColors.hintActive, marginLeft: 3 }}
        >
          {attendees.first()}
        </div>
      )}
    </FlexBoxCenter>
  );
}

function DeletionConfirmation({ timeLogToRemove, intl }) {
  return (
    <Confirmation
      hasCheckbox={false}
      open={!!timeLogToRemove}
      onCancel={TimeLogDeletionActions.removeAborted}
      onConfirm={TimeLogDeletionActions.removeConfirmed}
      title={t('.remove_timelog', intl, __filenamespace)}
    >
      <div>
        <FormattedMessage id={messageId('.are_you_sure', __filenamespace)} />
      </div>
    </Confirmation>
  );
}

function ContextMenu({ children }) {
  const [anchorEl, setAnchorEl] = React.useState();

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
}

const StaffContextMenu = injectIntl(({ staffMember, payrollEntries, intl }) => (
  <ContextMenu>
    <MenuItem onClick={() => TimeLogCreationActions.begin({ staffMember })}>
      {t('.add_task', intl, __filenamespace)}
    </MenuItem>

    <>
      <MenuItem
        onClick={() => CommissionsCreationActions.begin({ staffMember })}
      >
        {t('.add_commission', intl, __filenamespace)}
      </MenuItem>
      <MenuItem
        onClick={() =>
          PayrollReportActions.changePaymentStatus(payrollEntries, 'paid')
        }
      >
        {t('actions.mark_all_paid', intl)}
      </MenuItem>
    </>
  </ContextMenu>
));

const ItemContextMenu = injectIntl(({ staffMember, timeLog, intl }) => (
  <ContextMenu>
    <MenuItem
      onClick={() => TimeLogEditingActions.begin({ timeLog, staffMember })}
    >
      {t('actions.edit', intl)}
    </MenuItem>
    <MenuItem onClick={() => TimeLogDeletionActions.remove(timeLog)}>
      {t('actions.remove', intl)}
    </MenuItem>
  </ContextMenu>
));

const ItemContextMenuPayrollCommissions = injectIntl(
  ({ staffMember, payrollEntry, intl }) => (
    <ContextMenu>
      <MenuItem
        onClick={() =>
          CommissionsEditingActions.load({ payrollEntry, staffMember })
        }
      >
        {t('actions.edit', intl)}
      </MenuItem>
    </ContextMenu>
  )
);

const PayrollEntryContextMenu = injectIntl(
  ({ payrollEntry, staffMember, intl }) => (
    <ContextMenu>
      <MenuItem
        onClick={() =>
          PayrollEntryEditingActions.load({
            payrollEntry,
            staffMember,
          })
        }
      >
        {t('actions.edit', intl)}
      </MenuItem>
    </ContextMenu>
  )
);

const PaymentStatusDropDown = injectIntl(
  ({ entry, intl, selfPayrollReportPermission }) => {
    const paymentStatus = entry.payment_status;
    const labelColor = entry.isPaid() ? '#00E676' : '';
    if (!hasAdminPermission() || selfPayrollReportPermission) {
      if (paymentStatus === UNDEFINED) {
        return null;
      }
      return (
        <Box component="span" sx={{ color: labelColor }}>
          {t(`.${paymentStatus}`, intl, __filenamespace)}
        </Box>
      );
    }
    return (
      <Select
        sx={{
          '.UH-MuiSelect-select': { color: labelColor },
        }}
        value={paymentStatus}
        disableUnderline
        variant="standard"
        onChange={e =>
          PayrollReportActions.changePaymentStatus(entry, e.target.value)
        }
      >
        {paymentStatus === UNDEFINED && (
          <MenuItem value="undefined">
            {t('.select', intl, __filenamespace)}
          </MenuItem>
        )}
        <MenuItem value="paid">{t('.paid', intl, __filenamespace)}</MenuItem>
        <MenuItem value="unpaid">
          {t('.unpaid', intl, __filenamespace)}
        </MenuItem>
      </Select>
    );
  }
);

function TimeLog({ entry, staffMember, tasks, selfPayrollReportPermission }) {
  return (
    <StyledTableRow>
      <TableCell style={styles.dayOfWeek}>
        {moment(entry.start_time).format('ddd')}
      </TableCell>
      <TableCell style={styles.date}>
        {moment(entry.start_time).format('MM-DD-YY')}
      </TableCell>
      <TableCell style={styles.task}>
        {tasks.get(entry.task_id, new Task()).name}
      </TableCell>
      <TableCell style={styles.clockInOutTime}>
        <span style={{ marginRight: 10 }}>
          <ClockIn time={entry.start_time} />
        </span>
        <ClockOut time={entry.end_time} />
      </TableCell>
      <TableCell style={styles.attendees}>&nbsp;</TableCell>
      <TableCell style={styles.hours}>
        <Hours hours={entry.duration} />
      </TableCell>
      <TableCell style={styles.hourly_rate}>
        <FormattedMessage
          id={messageId('.log_hourly_rate', __filenamespace)}
          values={{
            rate: <FormattedCurrency value={entry.hourly_rate} fromCents />,
          }}
        />
      </TableCell>
      <TableCell style={styles.totalEarnings}>
        {entry.total === null ? (
          <FormattedMessage id={messageId('.dash', __filenamespace)} />
        ) : (
          <FormattedCurrency value={entry.total} fromCents />
        )}
      </TableCell>
      <TableCell colSpan="1" style={styles.totalEarnings}>
        <PaymentStatusDropDown
          entry={entry}
          selfPayrollReportPermission={selfPayrollReportPermission}
        />
      </TableCell>
      {!selfPayrollReportPermission && hasAdminPermission() && (
        <TableCell style={styles.contextMenu} colSpan="1">
          <ItemContextMenu timeLog={entry} staffMember={staffMember} />
        </TableCell>
      )}
    </StyledTableRow>
  );
}

function PayrollEntry({ entry, staffMember, selfPayrollReportPermission }) {
  const paymentMethod = entry.payment_method
    ? snakeToTitleCase(entry.payment_method)
    : '-';
  return (
    <StyledTableRow>
      <TableCell style={styles.dayOfWeek}>
        {moment(entry.start_time).format('ddd')}
      </TableCell>
      <TableCell style={styles.date}>
        {moment(entry.start_time).format('MM-DD-YY')}
      </TableCell>
      <TableCell style={styles.task}>
        {entry?.hasPayrollCommission()
          ? snakeToTitleCase(entry.event_type_name)
          : entry.event_type_name}
      </TableCell>
      <TableCell style={styles.eventName}>{entry.event_name}</TableCell>
      <TableCell style={styles.attendees}>
        {!!entry.attendee_names.size && (
          <Attendees attendees={entry.attendee_names} id={entry.id} />
        )}
      </TableCell>
      <TableCell style={styles.hours}>
        <Hours hours={entry.duration} />
      </TableCell>
      <TableCell style={styles.period}>
        {entry?.hasPayrollCommission()
          ? paymentMethod
          : `${moment(entry.start_time).format('hh:mm a')} - ${moment(
              entry.end_time
            ).format('hh:mm a')}`}
      </TableCell>
      <TableCell style={styles.totalEarnings}>
        {entry.total === null ? (
          <FormattedMessage id={messageId('.dash', __filenamespace)} />
        ) : (
          <FormattedCurrency value={entry.total} fromCents />
        )}
      </TableCell>
      <TableCell colSpan="1" style={styles.totalEarnings}>
        <PaymentStatusDropDown
          entry={entry}
          selfPayrollReportPermission={selfPayrollReportPermission}
        />
      </TableCell>
      {!selfPayrollReportPermission && hasAdminPermission() && (
        <TableCell colSpan="1" style={styles.contextMenu}>
          {entry?.hasPayrollCommission() ? (
            <ItemContextMenuPayrollCommissions
              payrollEntry={entry}
              staffMember={staffMember}
            />
          ) : (
            <PayrollEntryContextMenu
              payrollEntry={entry}
              staffMember={staffMember}
            />
          )}
        </TableCell>
      )}
    </StyledTableRow>
  );
}

// Using a React.memo rather than a SFC because these tables can be quite large
// and avoiding the rerenders results in a significant performance improvement.
const PayrollTable = React.memo(
  ({
    payrollEntries,
    tasks,
    staffMember,
    dateRange,
    totals,
    selfPayrollReportPermission,
  }) => (
    <TableContainer component={Paper} sx={{ marginBottom: '15px' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>
              <StaffInformation staffMember={staffMember} />
            </TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
              <DateRange
                startTime={dateRange.startTime}
                endTime={dateRange.endTime}
              />
            </TableCell>
            <TableCell colSpan={1}>&nbsp;</TableCell>
            <TableCell colSpan={2}>
              <PaidUnpaidTotal totals={totals} />
            </TableCell>
            <TableCell colSpan="1" style={styles.totalEarnings} />
            {!selfPayrollReportPermission && hasAdminPermission() && (
              <TableCell colSpan="1" style={styles.contextMenu}>
                <StaffContextMenu
                  staffMember={staffMember}
                  payrollEntries={payrollEntries}
                />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {payrollEntries.map(payrollEntry =>
            payrollEntry.object_type === 'TimeLog' ? (
              <TimeLog
                key={payrollEntry.id}
                entry={payrollEntry}
                tasks={tasks}
                staffMember={staffMember}
                selfPayrollReportPermission={selfPayrollReportPermission}
              />
            ) : (
              <PayrollEntry
                key={payrollEntry.id}
                entry={payrollEntry}
                staffMember={staffMember}
                selfPayrollReportPermission={selfPayrollReportPermission}
              />
            )
          )}
          <TableRow>
            <TableCell colSpan="3">&nbsp;</TableCell>
            <TableCell colSpan="2" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage
                id={messageId('.total_estimated_payment', __filenamespace)}
              />
            </TableCell>
            <TableCell colSpan="2" sx={{ fontWeight: 'bold' }}>
              <Hours hours={totals.get('totalHours')} />
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              <FormattedCurrency value={totals.get('totalUnpaid')} fromCents />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
);

const PayrollEmptyState = injectIntl(
  ({ isStaffListEmpty, isTaskListEmpty, intl }) => {
    const actions = [];

    if (isStaffListEmpty) {
      actions.push({
        label: t('.add_staff', intl, __filenamespace),
        action: InviteDialogActions.openStaffInviteDialog,
      });
    }

    if (isTaskListEmpty) {
      actions.push({
        label: t('.add_task', intl, __filenamespace),
        href: customerScopedRoute('/settings'),
      });
    }

    let image;
    let headerText;
    let messageText;

    if (isStaffListEmpty || isTaskListEmpty) {
      image = <PayrollReportNoStaffOrTasks />;
      headerText = '.missing_data_header';
      messageText = '.missing_data_message';
    } else {
      image = <EmptyPayrollReport />;
      headerText = '.empty_state_header';
      messageText = '.empty_state_message';
    }

    return (
      <EmptyState
        image={image}
        headerText={t(headerText, intl, __filenamespace)}
        messageText={t(messageText, intl, __filenamespace)}
        primaryAction={actions[0]}
        secondaryAction={actions[1]}
      />
    );
  }
);

function PayrollReport({
  attendeeListStore: {
    formattedClients: formattedAttendee,
    searchString: attendeeSearchString,
  },
  clientListStore: { formattedClients, searchString },
  itemListStore: { formattedItems, itemSearchString },
  retailListStore: { formattedRetailCategories, retailCategorySearchString },
  payrollEntryEditingStore: {
    payrollEntry,
    staffMember: PEStaffMember,
    isLoading: isLoadingPE,
  },
  eventTypeListingStore: { eventTypes },
  locationStore: { allLocations: locations },
  timeLogCreationStore: { timeLog: addTimeLog, staffMember: staffMemberForAdd },
  timeLogDeletionStore: { timeLogToRemove },
  timeLogEditingStore: {
    timeLog: editTimeLog,
    staffMember: staffMemberForEdit,
  },
  commissionsCreationStore: {
    commissions: addCommissions,
    staffMember: commissionsStaffMemberForAdd,
    selectedClient: selectedClientAdd,
    selectedItem: selectedItemAdd,
    selectedRetailItem: selectedRetailAdd,
  },
  commissionsEditingStore: {
    commissions: editCommissions,
    staffMember: commissionsStaffMemberForEdit,
    selectedClient: selectedClientEdit,
    selectedItem: selectedItemEdit,
    selectedRetailItem: selectedRetailEdit,
  },
  payrollReportStore: {
    payrollEntries,
    filters,
    totals,
    isLoading,
    filtersApplied,
    emptyFilter,
    isStaffListEmpty,
    isTaskListEmpty,
    isEventTypesListEmpty,
    selectedAttendee,
    filterDrawerOpen,
  },
  staffStore: { allStaffMap: staff },
  staffListStore: { formattedStaff, searchString: staffSearchString },
  taskListingStore: { tasks },
  intl,
}) {
  const selfPayrollReportPermission = currentUser().isManager();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTimeLogEntry = () => {
    setAnchorEl(null);
    TimeLogCreationActions.begin({});
  };
  const handleCommissionsEntry = () => {
    setAnchorEl(null);
    CommissionsCreationActions.begin({});
  };
  return (
    <>
      <PayrollFilterDrawer
        isOpen={filterDrawerOpen}
        intl={intl}
        staff={staff}
        tasks={tasks}
        eventTypes={eventTypes}
        filters={filters}
        disabled={
          isStaffListEmpty || (isTaskListEmpty && isEventTypesListEmpty)
        }
        filtersApplied={filtersApplied}
      />
      <div
        className="payroll-report"
        style={{
          padding: '25px 30px 30px',
          height: '100%',
        }}
      >
        <PageHeader
          title={
            <FormattedMessage id={messageId('.payroll', __filenamespace)} />
          }
          actions={
            <div>
              {!selfPayrollReportPermission &&
                hasAdminPermission() &&
                !isStaffListEmpty &&
                !isTaskListEmpty && (
                  <>
                    <MUIButton
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      style={{ width: 120, height: 42 }}
                      startIcon={
                        <AddIcon sx={{ color: uhColors.activeBlue }} />
                      }
                    >
                      {t('.add_entry', intl, __filenamespace)}
                    </MUIButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleTimeLogEntry}>
                        {t('.task', intl, __filenamespace)}
                      </MenuItem>
                      <MenuItem onClick={handleCommissionsEntry}>
                        {t('.commission', intl, __filenamespace)}
                      </MenuItem>
                    </Menu>
                  </>
                )}
            </div>
          }
        />

        <Filters
          attendeeSearchString={attendeeSearchString}
          formattedClients={formattedAttendee}
          eventTypes={eventTypes}
          selectedAttendee={selectedAttendee}
          filters={filters}
          filtersApplied={filtersApplied}
          staff={staff}
          tasks={tasks}
          selfPayrollReportPermission={selfPayrollReportPermission}
          disabled={
            isStaffListEmpty || (isTaskListEmpty && isEventTypesListEmpty)
          }
        />

        <SpinWhileLoading
          absolute={false}
          isLoading={isLoading}
          contentContainerStyle={{ width: '100%' }}
          outerContainerStyle={{ width: '100%' }}
        >
          {!emptyFilter &&
            payrollEntries
              .map((entries, customerUserId) => (
                /* eslint-disable react/no-array-index-key */
                <PayrollTable
                  key={customerUserId}
                  customerUserId={customerUserId}
                  dateRange={filters.get('dateRange')}
                  staffMember={staff.get(customerUserId, new Staff())}
                  tasks={tasks}
                  payrollEntries={entries}
                  totals={totals.get(customerUserId)}
                  selfPayrollReportPermission={selfPayrollReportPermission}
                />
                /* eslint-enable react/no-array-index-key */
              ))
              .valueSeq()}

          <TimeLogModal
            open={!!addTimeLog || !!editTimeLog}
            actions={
              addTimeLog ? TimeLogCreationActions : TimeLogEditingActions
            }
            timeLog={addTimeLog || editTimeLog}
            editing={!!editTimeLog}
            formattedStaff={formattedStaff}
            staffSearchString={staffSearchString}
            staffMember={staffMemberForAdd || staffMemberForEdit}
            tasks={tasks}
            locations={locations}
            intl={intl}
          />
          <PayrollComissionsEntryModal
            open={!!addCommissions || !!editCommissions}
            actions={
              addCommissions
                ? CommissionsCreationActions
                : CommissionsEditingActions
            }
            commission={addCommissions || editCommissions}
            editing={!!editCommissions}
            formattedStaff={formattedStaff}
            staffSearchString={staffSearchString}
            staffMember={
              commissionsStaffMemberForAdd || commissionsStaffMemberForEdit
            }
            tasks={tasks}
            locations={locations}
            intl={intl}
            clientSearchString={searchString}
            formattedClients={formattedClients}
            selectedClient={selectedClientAdd || selectedClientEdit}
            itemSearchString={itemSearchString}
            formattedItems={formattedItems}
            selectedItem={selectedItemAdd || selectedItemEdit}
            retailCategorySearchString={retailCategorySearchString}
            formattedRetailCategories={formattedRetailCategories}
            selectedRetailItem={selectedRetailAdd || selectedRetailEdit}
          />
          <PayrollEntryModal
            actions={PayrollEntryEditingActions}
            payrollEntry={payrollEntry}
            open={!!payrollEntry && !isLoadingPE}
            staffMember={PEStaffMember}
          />

          {(isStaffListEmpty ||
            (isTaskListEmpty && isEventTypesListEmpty) ||
            payrollEntries.isEmpty() ||
            emptyFilter) && (
            <PayrollEmptyState
              isStaffListEmpty={isStaffListEmpty}
              isTaskListEmpty={isTaskListEmpty}
            />
          )}
        </SpinWhileLoading>
        <DeletionConfirmation timeLogToRemove={timeLogToRemove} intl={intl} />
      </div>
    </>
  );
}
// eslint-disable-next-line
class PayrollReportContainer extends React.PureComponent {
  componentDidMount() {
    PayrollReportActions.mounted({ getAll: true });
    TaskListingActions.list({ withRemoved: true });
  }

  render() {
    return (
      <AltContainer
        stores={{
          clientListStore: AutoCompleteClientListStore,
          attendeeListStore: AutoCompleteAttendeeListStore,
          itemListStore: AutoCompleteItemListStore,
          retailListStore: AutoCompleteRetailListStore,
          staffListStore: AutoCompleteStaffListStore,
          payrollEntryEditingStore: PayrollEntryEditingStore,
          eventTypeListingStore: EventTypeListingStore,
          locationStore: LocationStore,
          timeLogCreationStore: TimeLogCreationStore,
          timeLogDeletionStore: TimeLogDeletionStore,
          timeLogEditingStore: TimeLogEditingStore,
          payrollReportStore: PayrollReportStore,
          staffStore: StaffStore,
          taskListingStore: TaskListingStore,
          commissionsCreationStore: CommissionsCreationStore,
          commissionsEditingStore: CommissionsEditingStore,
          commissionRateListingStore: CommissionRateListingStore,
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PayrollReport {...this.props} />
      </AltContainer>
    );
  }
}

export default injectIntl(PayrollReportContainer);
