class UpdateContactActions {
  constructor() {
    this.generateActions(
      'clientFetchError',
      'clientFetchSuccess',
      'mounted',
      'reset',
      'saveContact',
      'updateContactError',
      'updateContactFields',
      'updateContactSuccess'
    );
  }
}

export default alt.createActions(UpdateContactActions);
