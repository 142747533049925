import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Drawer, Dropdown, Grid } from '@upperhand/playmaker';

import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import {
  Filters,
  FilterOrderMethod,
  FilterPaymentMethod,
} from 'containers/reports/types';

import OrderReportActions from '../Actions';
import { hasFilterDrawerDefaults } from '../utils';

function OrdersFilterDrawer({
  filters,
  defaultFilters,
  isChanged,
  isOpen,
  intl,
}) {
  const handleOrderMethodChange = event =>
    OrderReportActions.updateFilter(Filters.ORDER_METHOD, event.target.value);

  const handlePaymentMethodChange = event =>
    OrderReportActions.updateFilter(Filters.PAYMENT_METHOD, event.target.value);

  return (
    <Drawer
      classes={{
        root: 'orders-report__filter-drawer',
        content: 'filter-drawer__content',
        footer: 'filter-drawer__footer',
      }}
      open={isOpen}
      onClose={OrderReportActions.closeFilterDrawer}
      title={t('.title', intl, __filenamespace)}
      content={
        isLoggedIn() ? (
          <Grid container xs={12} spacing={3} alignItems="center">
            {(isChanged || !hasFilterDrawerDefaults(filters)) && (
              <Grid justify="flex-end" container item xs={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <a
                  className="filter-drawer__clear"
                  onClick={OrderReportActions.clearFilters}
                >
                  {t('.clear', intl, __filenamespace)}
                </a>
              </Grid>
            )}
            <Grid item xs={12}>
              <Dropdown
                items={Object.keys(FilterOrderMethod).map(key => ({
                  label: t(
                    `.order_method_${FilterOrderMethod[key]}`,
                    intl,
                    __filenamespace
                  ),
                  value: FilterOrderMethod[key],
                }))}
                label={t('.order_method', intl, __filenamespace)}
                fullWidth
                rounded={false}
                outline
                onChange={handleOrderMethodChange}
                value={
                  filters.get('orderMethod') ||
                  defaultFilters.get('orderMethod')
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                items={Object.keys(FilterPaymentMethod).map(key => ({
                  label: t(
                    `.payment_method_${FilterPaymentMethod[key]}`,
                    intl,
                    __filenamespace
                  ),
                  value: FilterPaymentMethod[key],
                }))}
                label={t('.payment_method', intl, __filenamespace)}
                fullWidth
                rounded={false}
                outline
                onChange={handlePaymentMethodChange}
                value={
                  filters.get('paymentMethod') ||
                  defaultFilters.get('paymentMethod')
                }
              />
            </Grid>
          </Grid>
        ) : (
          <SignInPrompt />
        )
      }
      footer={
        isLoggedIn() ? (
          <Button
            classes={{
              root: 'filter-drawer__footer-button',
              disabled: 'filter-drawer__footer-button-disabled',
              label: 'filter-drawer__footer-button-label',
            }}
            disabled={!isChanged}
            fullWidth
            onClick={OrderReportActions.applyFilters}
            size={40}
            type="primary"
          >
            {t('.btn_apply', intl, __filenamespace)}
          </Button>
        ) : null
      }
    />
  );
}

OrdersFilterDrawer.propTypes = {
  filters: PropTypes.object,
  intl: PropTypes.object.isRequired,
  isChanged: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
};

OrdersFilterDrawer.defaultProps = {
  isOpen: false,
  filters: {},
};

export default injectIntl(OrdersFilterDrawer);
