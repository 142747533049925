import { List, Set } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import {
  getAllUrlParameters,
  setUrlParameter,
} from 'shared/utils/UrlParameters.js';
import { StaffSource, TeamSource, TeamTypeSource } from 'sources';

import CustomerTeam from 'team_mgmt/shared/records/CustomerTeam.jsx';
import BrowseTeamsActions from './Actions';

export const FILTER_ALL = 'all';

class BrowseTeamsStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      closeFiltersDrawer: BrowseTeamsActions.closeFiltersDrawer,
      teamTypeFilterUpdated: BrowseTeamsActions.teamTypeFilterUpdated,
      listTeamTypeError: BrowseTeamsActions.listTeamTypeError,
      listTeamTypeSuccess: BrowseTeamsActions.listTeamTypeSuccess,
      listTeams: BrowseTeamsActions.listTeams,
      listTeamsError: BrowseTeamsActions.listTeamsError,
      listTeamsSuccess: BrowseTeamsActions.listTeamsSuccess,
      listStaffError: BrowseTeamsActions.listStaffError,
      listStaffSuccess: BrowseTeamsActions.listStaffSuccess,
      mounted: BrowseTeamsActions.mounted,
      openFiltersDrawer: BrowseTeamsActions.openFiltersDrawer,
      staffFilterUpdated: BrowseTeamsActions.staffFilterUpdated,
      searchFilterUpdated: BrowseTeamsActions.searchFilterUpdated,
    });
  }

  reset() {
    this.urlParams = getAllUrlParameters() || Map();
    this.isLoading = false;
    this.isMounted = false;
    this.teamLoading = false;
    this.teamTypes = Set();
    this.teamTypeLoading = false;
    this.staffLoading = false;
    this.isFiltersDrawerOpen = false;

    this.teamTypeFilter = [];
    this.teamTypeAllSelected = true;
    this.staff = Set();
    this.staffFilter = [];
    this.staffAllSelected = true;

    this.page = 1;
    this.perPage = 20;
    this.totalCount = 0;

    this.browseTeams = List();
    this.search = '';
    this.waitlistDrawerOpen = false;
  }

  mounted() {
    this.reset();

    this.isLoading = true;
    this.isMounted = true;

    this.applyTeamTypeFilters();
    this.applyStaffFilters();

    this.listTeamTypes();
    this.listStaff();
  }

  updateIsLoading() {
    this.isLoading =
      this.teamLoading || this.teamTypeLoading || this.staffLoading;
  }

  applyTeamTypeFilters() {
    const urlTeamTypeFilter = this.urlParams.get('teamTypeFilter');

    if (urlTeamTypeFilter) {
      this.teamTypeFilter = urlTeamTypeFilter.toJS();
      this.teamTypeAllSelected = false;
    } else {
      this.teamTypeFilter = this.teamTypes.toArray();
      this.teamTypeAllSelected = true;
    }
  }

  applyStaffFilters() {
    const urlStaffFilter = this.urlParams.get('staffFilter');

    if (urlStaffFilter) {
      this.staffFilter = urlStaffFilter.toJS();
      this.staffAllSelected = false;
    } else {
      this.staffFilter = this.staff.toArray();
      this.staffAllSelected = true;
    }
  }

  listTeams() {
    this.isLoading = true;
    this.teamLoading = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      fields: ['team_type', 'participant_ids', 'registered_client_ids'],
      statuses: ['active', 'draft', 'completed'],
    };

    if (this.search) {
      params.title = this.search;
    }

    if (!this.teamTypeAllSelected) {
      params.types = this.teamTypeFilter;
    }

    if (!this.staffAllSelected) {
      params.staff_ids = this.staffFilter;
    }
    TeamSource.list({
      params,
      success: BrowseTeamsActions.listTeamsSuccess,
      error: BrowseTeamsActions.listTeamsError,
    });
  }

  listStaff(page = 1) {
    this.isLoading = true;
    this.staffLoading = true;

    StaffSource.list({
      params: {
        access_revoked: false,
        event_statuses: ['active'],
        page,
        per_page: 50,
      },
      success: BrowseTeamsActions.listStaffSuccess,
      error: BrowseTeamsActions.listStaffError,
    });
  }

  listTeamTypes(page = 1) {
    this.isLoading = true;
    this.teamTypeLoading = true;

    TeamTypeSource.list({
      params: {
        page,
        per_page: 50,
      },
      success: BrowseTeamsActions.listTeamTypeSuccess,
      error: BrowseTeamsActions.listTeamTypeError,
    });
  }

  listTeamsSuccess({ page, perPage, totalCount, team_events: teams }) {
    this.teamLoading = false;
    this.page = page + 1;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.browseTeams = this.browseTeams.merge(
      teams.map(t => new CustomerTeam(t))
    );
    this.updateIsLoading();
  }

  listTeamsError(...args) {
    this.teamLoading = false;
    this.updateIsLoading();
    this.notifyError('error listing events', args);
  }

  listTeamTypeSuccess({ team_types: teamTypes, page, totalCount }) {
    this.teamTypes = this.teamTypes.union(teamTypes.map(e => e.id).toSet());

    if (totalCount > this.teamTypes.count()) {
      this.listTeamTypes(page + 1);
      return;
    }

    this.applyTeamTypeFilters();

    this.teamTypeLoading = false;
    this.updateIsLoading();
  }

  listTeamTypeError() {
    this.teamTypeLoading = false;
    this.updateIsLoading();
  }

  listStaffSuccess({ page, staff, totalCount }) {
    this.staff = this.staff.union(staff.map(s => s.id).toSet());

    if (totalCount > this.staff.count()) {
      this.listStaff(page + 1);
      return;
    }

    this.listTeams();
    this.applyStaffFilters();

    this.staffLoading = false;
    this.updateIsLoading();
  }

  listStaffError() {
    this.staffLoading = false;
    this.updateIsLoading();
  }

  filterUpdated(filterName, newFilter, allSelected) {
    this[`${filterName}Filter`] = newFilter;
    this[`${filterName}AllSelected`] = allSelected;
    setUrlParameter(`${filterName}Filter`, allSelected ? [] : newFilter);
    this.page = 1;
    this.totalCount = 0;
    this.browseTeams = List();

    if (
      (this.staffFilter.length || !this.staff.count()) &&
      (this.teamTypeFilter.length || !this.teamTypes.count())
    ) {
      this.listTeams();
    }
  }

  searchFilterUpdated(value) {
    this.search = value;
    this.page = 1;
    this.totalCount = 0;
    this.browseTeams = List();
    this.listTeams();
  }

  teamTypeFilterUpdated([newTeamTypeFilter, allTeamTypeSelected]) {
    this.filterUpdated('teamType', newTeamTypeFilter, allTeamTypeSelected);
  }

  staffFilterUpdated([newStaffFilter, allStaffSelected]) {
    this.filterUpdated('staff', newStaffFilter, allStaffSelected);
  }

  closeFiltersDrawer() {
    this.isFiltersDrawerOpen = false;
  }

  openFiltersDrawer() {
    this.isFiltersDrawerOpen = true;
  }
}

export default alt.createStore(BrowseTeamsStore, 'BrowseTeamsStore');
