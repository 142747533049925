import React from 'react';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';

import Contact from 'contacts/index/components/ContactImportNative/_Contact.jsx';
import ContactImportNativeActions from 'contacts/index/actions/ContactImportNativeActions.js';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import styles from 'contacts/index/components/ContactImportNative/styles';

function ContactSelect({ contact }) {
  return (
    <FlexBoxCenter style={{ flexDirection: 'column' }}>
      <Divider style={styles.divider} />
      <FlexBoxJustify style={styles.contact}>
        <Checkbox
          style={{ width: 'auto' }}
          onChange={() =>
            ContactImportNativeActions.toggleContactSelected(contact)
          }
          checked={contact.selected}
          disabled={!contact.canImport()}
        />
        <Contact contact={contact} />
      </FlexBoxJustify>
    </FlexBoxCenter>
  );
}

export default ContactSelect;
