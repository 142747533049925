class BalancesReportActions {
  constructor() {
    this.generateActions(
      'applyFilters',
      'changeColumnVisibility',
      'clearFilters',
      'closeColumnDrawer',
      'closeFilterDrawer',
      'downloadList',
      'downloadListSuccess',
      'downloadListError',
      'fetch',
      'fetchError',
      'fetchStatistics',
      'fetchStatisticsError',
      'fetchStatisticsSuccess',
      'fetchSuccess',
      'list',
      'listError',
      'listSuccess',
      'listLocationSuccess',
      'listLocationError',
      'listClientsSuccess',
      'listClientsError',
      'mounted',
      'openColumnDrawer',
      'openFilterDrawer',
      'purchaserChanged',
      'removeFilter',
      'selectDay',
      'selectPage',
      'updateFilter',
      'listScheduledPaymentsSuccess'
    );
  }
}

export default alt.createActions(BalancesReportActions);
