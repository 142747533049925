import React from 'react';
import { injectIntl } from 'react-intl';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';

import ContentTable from 'event_mgmt/index/components/_ContentTable.jsx';
import ContentToolbar from 'event_mgmt/index/components/_ContentToolbar.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';
import FilterDrawer from 'event_mgmt/index/components/_FilterDrawer.jsx';
import NoEventsIcon from 'shared/components/icons/empty_states/Events.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { ContentCards } from 'event_mgmt/index/components/_ContentCards.jsx';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';

const styles = {
  smallLayoutContainer: { margin: '0 auto 0 auto' },
};

const NoEvents = injectIntl(({ intl }) => {
  let headerText;
  let messageText;
  let primaryAction;

  if (currentUser().role === 'StaffAdmin') {
    headerText = t('.staff_admin_header', intl, __filenamespace);
    messageText = t('.staff_admin_message', intl, __filenamespace);
    primaryAction = {
      label: t('.create_event', intl, __filenamespace),
      href: customerScopedRoute('/events/new'),
    };
  } else {
    headerText = t('.staff_member_header', intl, __filenamespace);
    messageText = t('.staff_member_message', intl, __filenamespace);
    primaryAction = null;
  }

  return (
    <EmptyState
      image={<NoEventsIcon />}
      headerText={headerText}
      messageText={messageText}
      primaryAction={primaryAction}
    />
  );
});

class Content extends React.Component {
  componentDidMount() {
    EventListActions.reset();
    EventActions.list({
      fields: ['participant_count'],
      scheduleType: [SCHEDULE_TYPE.openBooking, SCHEDULE_TYPE.fixedSchedule],
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handlePageSelect = (page, perPage) => {
    EventListActions.updatePageParams({ page, perPage });
    EventActions.list({
      fields: ['participant_count'],
      scheduleType: [SCHEDULE_TYPE.openBooking, SCHEDULE_TYPE.fixedSchedule],
    });
  };

  // eslint-disable-next-line class-methods-use-this
  verifyAdminRole() {
    return hasAdminPermission();
  }

  render() {
    const {
      readOnly,
      selectedEvents,
      eventList,
      automations,
      filter,
      eventTypes,
    } = this.props;
    const { filterDrawerOpen, eventStatusFilters, statusFilters, typeFilters } =
      filter;
    const { isLoading, events, page, perPage, totalCount } = eventList;
    const { eventTypes: eventTypesList } = eventTypes;
    const { selectedEvents: selectedEventsList } = selectedEvents;

    return (
      <div className="iphone-x-content">
        <ContentToolbar
          readOnly={readOnly}
          selectedEvents={selectedEventsList}
          allowDeleteEvents={this.verifyAdminRole()}
        />
        <Divider />
        <SpinWhileLoading isLoading={isLoading} spinWhile="isLoading">
          {events.size > 0 ? (
            <ResponsiveElement
              largeScreen={
                <ContentTable
                  events={events}
                  selectedEvents={selectedEventsList}
                  automations={automations}
                  allowDeleteEvents={this.verifyAdminRole()}
                />
              }
              smallScreen={
                <div style={styles.smallLayoutContainer}>
                  <ContentCards
                    events={events}
                    selectedEvents={selectedEventsList}
                    automations={automations}
                    allowDeleteEvents={this.verifyAdminRole()}
                  />
                </div>
              }
            />
          ) : (
            <NoEvents />
          )}
        </SpinWhileLoading>
        <Paginator
          className="events__pagination"
          currentPage={page}
          perPage={perPage}
          totalCount={totalCount}
          onPageSelect={this.handlePageSelect}
          pageLimit={0}
          showInfo
        />
        <FilterDrawer
          showStatusesInFilter
          filterDrawerOpen={filterDrawerOpen}
          eventStatusFilters={eventStatusFilters}
          statusFilters={statusFilters}
          typeFilters={typeFilters}
          eventTypes={eventTypesList}
        />
      </div>
    );
  }
}

Content.propTypes = {
  readOnly: PropTypes.bool,
  selectedEvents: PropTypes.object,
  eventList: PropTypes.object,
  filter: PropTypes.object,
  eventTypes: PropTypes.object,
  automations: PropTypes.object,
};

Content.defaultProps = {
  readOnly: false,
  selectedEvents: {
    selectedEvents: [],
  },
  eventList: {
    events: List(),
    isLoading: false,
    page: 0,
    perPage: 20,
    totalCount: 0,
  },
  filter: {
    eventStatusFilters: [],
    statusFilters: Map(),
    typeFilters: Map(),
    filterDrawerOpen: false,
  },
  eventTypes: {
    eventTypes: List(),
  },
  automations: {
    descriptions: List(),
  },
};

export default Content;
