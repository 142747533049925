import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  root: {
    border: '0.69px solid #E2E2E2',
    padding: '8px 42px 8px 8px',
  },
};

function TableHeader() {
  return (
    <div style={styles.root}>
      <Grid container direction="row" justify="flex-start">
        <Grid item xs={5}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.name', __filenamespace)} />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            <FormattedMessage id={messageId('.email', __filenamespace)} />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default TableHeader;
