import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Drawer } from '@upperhand/playmaker';

import { Box } from '@mui/material';

import altContainer from 'shared/hocs/altContainer.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import {
  BalancesDataStore,
  ClientDataStore,
  LocationDataStore,
  ScheduledPaymentDataStore,
} from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';

import BalanceDetailsDrawerActions from './Actions';
import BalanceDetailsDrawerStore from './Store.js';

import Content from './components/Content.jsx';

import './styles.scss';

function BackButton({ intl, onBackClick }) {
  return (
    <Box className="back-btn">
      <Button
        onClick={onBackClick}
        icon="arrowLeft"
        type="tertiary"
        rounded
        classes={{ root: 'back-btn__btn', label: 'back-btn__label' }}
      />
      <Box className="back-btn__label">
        {t('.title', intl, __filenamespace)}
      </Box>
    </Box>
  );
}

function BalanceDetailsDrawer({
  balanceDetailsDrawerStore: {
    balanceCompoundId,
    isLoadingBalanceDetails,
    isLoadingBalance,
    isLoadingClient,
    isLoadingLocation,
    dotsMenuAnchorEl,
    remainingScheduledPayments,
  },
  balancesDataStore: { balances },
  clientDataStore: { clients },
  intl,
  locationDataStore: { locations },
  scheduledPaymentDataStore: { scheduledPayments: scheduledPaymentsData },
  withBackLink,
}) {
  const balance = balances.get(balanceCompoundId);
  const scheduledPayment = scheduledPaymentsData.get(balance?.productId);
  const threeDotsEnabled =
    !isLoadingBalance &&
    balance &&
    (balance.outstanding > 0 || balance.get('paid') > 0);

  const title = withBackLink ? (
    <BackButton
      intl={intl}
      onBackClick={BalanceDetailsDrawerActions.closeDrawer}
    />
  ) : (
    t('.title', intl, __filenamespace)
  );
  return (
    <Drawer
      classes={{
        root: 'balance-details-drawer',
      }}
      open={isLoadingBalance || !!balance}
      onClose={BalanceDetailsDrawerActions.closeAllDrawers}
      headerType="light"
      title={title}
      actions={
        threeDotsEnabled
          ? [
              {
                icon: 'threeDots',
                onClick: BalanceDetailsDrawerActions.toggleDotsMenu,
              },
            ]
          : []
      }
      content={
        isLoggedIn() ? (
          <Content
            intl={intl}
            isLoading={
              isLoadingBalance ||
              isLoadingBalanceDetails ||
              isLoadingClient ||
              isLoadingLocation
            }
            balance={balance}
            balanceDetails={balance?.get('details')}
            location={
              locations && balance && locations.get(balance.get('locationId'))
            }
            client={clients && balance && clients.get(balance.get('clientId'))}
            dotsMenuAnchorEl={dotsMenuAnchorEl}
            scheduledPayment={scheduledPayment}
            remainingScheduledPayments={remainingScheduledPayments}
          />
        ) : (
          <SignInPrompt />
        )
      }
    />
  );
}

BalanceDetailsDrawer.propTypes = {
  clientDataStore: PropTypes.object,
  locationDataStore: PropTypes.object,
  withBackLink: PropTypes.bool,
  balanceDetailsDrawerStore: PropTypes.shape({
    balanceCompoundId: PropTypes.string,
    isLoadingBalanceDetails: PropTypes.bool.isRequired,
    isLoadingBalance: PropTypes.bool.isRequired,
    isLoadingClient: PropTypes.bool.isRequired,
    isLoadingLocation: PropTypes.bool.isRequired,
    dotsMenuAnchorEl: PropTypes.object,
    remainingScheduledPayments: PropTypes.number,
  }).isRequired,
};

BalanceDetailsDrawer.defaultProps = {
  clientDataStore: {},
  locationDataStore: {},
  withBackLink: false,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      balanceDetailsDrawerStore: BalanceDetailsDrawerStore,
      balancesDataStore: BalancesDataStore,
      clientDataStore: ClientDataStore,
      locationDataStore: LocationDataStore,
      scheduledPaymentDataStore: ScheduledPaymentDataStore,
    },
  })
)(BalanceDetailsDrawer);
