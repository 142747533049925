import * as React from 'react';
import { Dropdown, Button, Icon } from '@upperhand/playmaker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import ResourceActions from 'resources/actions/ResourceActions.js';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  avatar: {
    height: 42,
  },
  staffContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  staffName: {
    flexGrow: 1,
    paddingLeft: 8,
  },
};

const onStaffPreferenceChange = e =>
  ResourceActions.staffPreferenceToggled(e.target.checked);
const onStaffAdd = event =>
  ResourceActions.staffPreferrenceAdded(event.target.value);

function PreferredStaffSelector({
  allowStaffPreference,
  availableStaff,
  selectedStaff,
  intl,
}) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={allowStaffPreference} />}
          label={t('.allow_staff_preference', intl, __filenamespace)}
          onChange={onStaffPreferenceChange}
        />
      </FormGroup>
      {allowStaffPreference && (
        <Dropdown
          label={t('.preferred_by', intl, __filenamespace)}
          placeholder={t('.choose_staff', intl, __filenamespace)}
          fullWidth
          onChange={onStaffAdd}
          items={availableStaff
            .map(s => ({
              value: s.id,
              label: <AvatarWithName user={s} style={styles.avatar} />,
            }))
            .toArray()}
        />
      )}

      {allowStaffPreference &&
        selectedStaff.map(s => (
          <div key={s.id} style={styles.staffContainer}>
            <Icon name="staff" />
            <div style={styles.staffName}>{s.name()}</div>
            <Button
              icon="delete"
              onClick={() => ResourceActions.staffPreferrenceRemoved(s.id)}
              rounded
              type="tertiary"
            />
          </div>
        ))}
    </div>
  );
}

export default PreferredStaffSelector;
