import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function FaceID(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 70 70" role="img" {...props}>
      <title>Face ID icon</title>
      <g
        id="Splash"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile---Face-ID"
          transform="translate(-594.000000, -904.000000)"
          fill="var(--color-secondary)"
          fillRule="nonzero"
        >
          <g sid="barcode" transform="translate(594.000000, 904.000000)">
            <g id="Group">
              <path
                d="M15,0 L3,0 C1.344,0 0,1.344 0,3 L0,15 C0,16.656 1.344,18 3,18 C4.656,18 6,16.656 6,15 L6,6 L15,6 C16.656,6 18,4.656 18,3 C18,1.344 16.656,0 15,0 Z"
                id="Shape"
              />
              <path
                d="M67,52 C65.344,52 64,53.344 64,55 L64,64 L55,64 C53.344,64 52,65.344 52,67 C52,68.656 53.344,70 55,70 L67,70 C68.656,70 70,68.656 70,67 L70,55 C70,53.344 68.656,52 67,52 Z"
                id="Shape"
              />
              <path
                d="M67,0 L55,0 C53.344,0 52,1.344 52,3 C52,4.656 53.344,6 55,6 L64,6 L64,15 C64,16.656 65.344,18 67,18 C68.656,18 70,16.656 70,15 L70,3 C70,1.344 68.656,0 67,0 Z"
                id="Shape"
              />
              <path
                d="M15,64 L6,64 L6,55 C6,53.344 4.656,52 3,52 C1.344,52 0,53.344 0,55 L0,67 C0,68.656 1.344,70 3,70 L15,70 C16.656,70 18,68.656 18,67 C18,65.344 16.656,64 15,64 Z"
                id="Shape"
              />
              <path
                d="M17,15 C15.3428608,15 14,16.4568484 14,18.2546526 L14,27.7453474 C14,29.5431516 15.3428608,31 17,31 C18.6571392,31 20,29.5431516 20,27.7453474 L20,18.2546526 C20,16.4568484 18.6571392,15 17,15 Z"
                id="Shape"
              />
              <path
                d="M53,15 C51.3428608,15 50,16.4568484 50,18.2546526 L50,27.7453474 C50,29.5431516 51.3428608,31 53,31 C54.6571392,31 56,29.5431516 56,27.7453474 L56,18.2546526 C56,16.4568484 54.6567564,15 53,15 Z"
                id="Shape"
              />
              <path
                d="M46.782825,43 C45.0052693,43 43.5648291,44.4037241 43.5648291,46.1359708 C43.5648291,49.219522 40.924159,51.7284586 37.6778306,51.7284586 L32.3229902,51.7284586 C29.0770725,51.7284586 26.4359918,49.2199221 26.4359918,46.1359708 C26.4359918,44.4037241 24.9955516,43 23.2179959,43 C21.4404402,43 20,44.4037241 20,46.1359708 C20,52.677613 25.5277097,58 32.3225796,58 L37.67742,58 C44.47229,58 49.9999996,52.677613 49.9999996,46.1359708 C50.0008209,44.4037241 48.5599701,43 46.782825,43 Z"
                id="Shape"
              />
              <path
                d="M36.9155126,30.5540073 L35.169762,30.5540073 L35.169762,23.2229963 C35.169762,21.4426785 33.7889068,20 32.084881,20 C30.3808553,20 29,21.4426785 29,23.2229963 L29,33.7770037 C29,35.5573215 30.3808553,37 32.084881,37 L36.915119,37 C38.6191447,37 40,35.5573215 40,33.7770037 C40,31.9966858 38.6195384,30.5540073 36.9155126,30.5540073 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default FaceID;
