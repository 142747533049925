import * as React from 'react';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Chip,
  Confirmation,
  Grid,
  Typography,
  Expander,
  Button,
} from '@upperhand/playmaker';

import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import WarningIcon from 'shared/components/icons/WarningIcon.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { l, messageId, t } from 'shared/utils/LocaleUtils.js';

import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';

const formattedTime = time => {
  const timeString = time.format('h:mma');
  return timeString.substring(0, timeString.length - 1);
};

const SessionCard = injectIntl(
  ({
    session,
    client,
    staff,
    event,
    registration,
    cancellable,
    onCancelRegistration,
    onRescheduleRegistration,
  }) => {
    if (!session) {
      return null;
    }

    const startTime = session.starts_at.tz(customerTZ());
    const endTime = session.ends_at.tz(customerTZ());
    const allowStaffSelection = event.allow_staff_selection;
    const canReschedule =
      typeof onRescheduleRegistration === 'function' && event?.isOpenBooking();
    const hasActions =
      cancellable || (canReschedule && currentUser().isClient());

    return (
      <Grid container className="session-card">
        <Grid item container alignItems="center" xs={hasActions ? 10 : 12}>
          <Grid item xs={12}>
            {`${formattedTime(startTime)} - ${formattedTime(endTime)}`}
          </Grid>
          {client && (
            <Grid item xs={12}>
              <Typography variant="fieldLabel">{client.name()}</Typography>
            </Grid>
          )}
          {allowStaffSelection && (
            <Grid item xs={12}>
              <FormattedMessage
                id={messageId('.staff', __filenamespace)}
                values={{
                  staff: staff
                    .filter(s => session.staff_ids.includes(s.id))
                    .map(s => s.name())
                    .join(', '),
                }}
              />
            </Grid>
          )}
        </Grid>
        {cancellable && (
          <Grid item container xs={1} justify="center" alignItems="center">
            <Button
              rounded
              type="tertiary"
              icon="delete"
              onClick={() => onCancelRegistration(registration.id)}
            />
          </Grid>
        )}
        {canReschedule && (
          <Grid item container xs={1} justify="center" alignItems="center">
            <Button
              rounded
              type="tertiary"
              icon="edit"
              onClick={() =>
                onRescheduleRegistration({ registration, session })
              }
            />
          </Grid>
        )}
      </Grid>
    );
  }
);

class EventSchedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      registrationToCancel: null,
    };
  }

  handleCancelRegistration = id => {
    const { registrations } = this.props;
    const registrationToCancel = registrations.find(cr => cr.id === id);

    this.setState({
      dialogOpen: true,
      registrationToCancel,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  handleRescheduleRegistration = ({ registration, session }) => {
    SchedulingDrawerActions.toggleReschedule({ registration, session });
  };

  handleClose = confirmed => {
    const { registrationToCancel } = this.state;
    if (confirmed) {
      RegistrationActions.cancel(registrationToCancel);
    }

    this.setState({
      dialogOpen: false,
      registrationToCancel: null,
    });
  };

  confirmationDialog() {
    const { dialogOpen, registrationToCancel } = this.state;

    if (!dialogOpen) return null;

    const { intl, sessions } = this.props;
    const session = sessions.get(registrationToCancel.session_id);
    const isStaff = currentUser().isStaff();
    const comfirmMessage = isStaff
      ? t('.cancel_registration_confirmation_admin', intl, __filenamespace)
      : t('.cancel_registration_confirmation', intl, __filenamespace);

    return (
      <Confirmation
        onConfirm={() => this.handleClose(true)}
        open={dialogOpen}
        level="warning"
        onCancel={() => this.handleClose(false)}
        cancelButtonLabel={t('.cancel_btn', intl, __filenamespace)}
        confirmButtonLabel={t('.confirm_btn', intl, __filenamespace)}
        title={
          <Typography variant="dialogHeader">
            <WarningIcon width={20} height={18} />
            <span className="session-cancellation-modal__title">
              {t('.cancel_registration', intl, __filenamespace)}
            </span>
          </Typography>
        }
        hasCheckbox={false}
      >
        <Grid container className="cancel-registration-modal__container">
          {comfirmMessage}
        </Grid>
        <Grid container className="cancel-registration-modal__container">
          <Chip label={session?.formattedTime()} />
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" component="strong">
              {t('.note_title', intl, __filenamespace)}
            </Typography>
            <Typography variant="body2" color="secondary" component="span">
              {t('.note_subtitle', intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
      </Confirmation>
    );
  }

  render() {
    const {
      intl,
      athletes,
      clients,
      event,
      registrations,
      sessions,
      staff,
      style,
    } = this.props;

    const cancellationDeadline = event.getIn([
      'schedules',
      0,
      'cancellation_deadline',
    ]);

    const cancellable =
      !event.isFixedSchedule() && !currentCustomer().disable_client_scheduling;
    const minCancelDateTime = moment().add(
      cancellationDeadline || 0,
      'seconds'
    );

    const groupedBySessionDate = registrations
      .groupBy(r => sessions?.get(r.session_id)?.get('starts_at'))
      .toList();

    if (groupedBySessionDate.isEmpty()) {
      return null;
    }

    return (
      <div style={style}>
        <SectionHeader style={{ marginBottom: 20 }}>
          <FormattedMessage
            id={messageId('.schedule_header', __filenamespace)}
          />
        </SectionHeader>
        {groupedBySessionDate.map(reg => {
          const session = sessions.get(reg.first().session_id);

          if (!session) return null;

          const startTime = session.starts_at.tz(customerTZ());

          return (
            <Expander
              classes={{
                expanderRoot: 'session-expander',
                content: 'session-expander__content',
              }}
              key={uuidv4()}
              header={
                <Typography variant="subtitle1">
                  {l(startTime, 'dates.medium', intl)}
                </Typography>
              }
              contentOffset
            >
              {reg.map(r => {
                const regSession = sessions.get(r.session_id);
                const client = currentUser().isStaff()
                  ? clients.get(r.client_id)
                  : athletes.findById(r.client_id);

                return (
                  <SessionCard
                    key={r.id}
                    session={regSession}
                    client={client}
                    event={event}
                    registration={r}
                    cancellable={
                      cancellable &&
                      session &&
                      session.starts_at > minCancelDateTime
                    }
                    staff={staff}
                    onCancelRegistration={this.handleCancelRegistration}
                    onRescheduleRegistration={this.handleRescheduleRegistration}
                  />
                );
              })}
            </Expander>
          );
        })}

        {this.confirmationDialog()}
      </div>
    );
  }
}

export default injectIntl(EventSchedule);
