import React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';
import { injectIntl } from 'react-intl';
import { compose } from 'shared/utils/SharedUtils.js';
import {
  TextField,
  ContentModal,
  Dropdown,
  Grid,
  Typography,
} from '@upperhand/playmaker';
import Divider from '@mui/material/Divider';
import { withStyles } from '@material-ui/styles';

import { t } from 'shared/utils/LocaleUtils.js';

import InventoryReportAdjustmentModalActions from '../actions/InventoryReportAdjustmentModalActions.js';
import InventoryReportAdjustmentModalStore, {
  ADJUSTMENT_REASONS,
} from '../stores/InventoryReportAdjustmentModalStore.js';

const styles = {
  typography: {
    paddingBottom: 14,
  },
};

function InventoryAdjustmentModal({
  inventoryReportAdjustmentModalStore: {
    isOpen,
    product,
    isLoading,
    amount,
    reason,
  },
  intl,
}) {
  const intlLabels = ADJUSTMENT_REASONS.map(key => ({
    label: t(key, intl, __filenamespace),
    value: key,
  }));

  const handleInputChange = (e, value) => {
    InventoryReportAdjustmentModalActions.setAmount(value);
  };
  return (
    <ContentModal
      open={isOpen}
      onSuccess={() =>
        InventoryReportAdjustmentModalActions.saveAdjustment(product)
      }
      onAbort={InventoryReportAdjustmentModalActions.closeModal}
      title={t('.title', intl, __filenamespace)}
      successLabel={t('.confirm', intl, __filenamespace)}
      cancelLabel={t('.cancel', intl, __filenamespace)}
      size="2x"
    >
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <Typography>
            {t('.expected_quantity', intl, __filenamespace)}
          </Typography>{' '}
          <p style={{ fontSize: '1rem', padding: '1rem 0' }}>
            {product?.get('live_qty')}
          </p>
        </Grid>
        <Grid item xs={6}>
          <Typography>{t('.product', intl, __filenamespace)}</Typography>{' '}
          <p style={{ fontSize: '1rem', padding: '1rem 0' }}>
            {product?.get('product')}
          </p>
        </Grid>
        <Divider />
        <Grid item xs={6}>
          <Typography mt={2}>
            {t('.adjustmentInventory', intl, __filenamespace)}
          </Typography>{' '}
          <TextField
            onChange={(e, newValue) => handleInputChange(e, newValue)}
            placeholder={t('.amount', intl, __filenamespace)}
            type="number"
            value={amount}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mt={2}>
            {t('.adjustmentReason', intl, __filenamespace)}
          </Typography>{' '}
          <Dropdown
            fullWidth
            placeholder={t('.placeholder', intl, __filenamespace)}
            value={reason}
            items={intlLabels}
            onChange={e =>
              InventoryReportAdjustmentModalActions.selectReason(e.target.value)
            }
            disabled={isLoading}
          />
        </Grid>
      </Grid>
    </ContentModal>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      inventoryReportAdjustmentModalStore: InventoryReportAdjustmentModalStore,
    },
  }),
  withStyles(styles)
)(InventoryAdjustmentModal);
