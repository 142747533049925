class PaymentActions {
  constructor() {
    this.generateActions(
      'changePaymentMethod',
      'changePaymentOption',
      'changeAchPaymentOption',
      'listCardsError',
      'listCardsSuccess',
      'mounted',
      'paymentServiceResponse',
      'purchase',
      'reset',
      'updateCardUsage',
      'updateNewCardForm',
      'setPaymentMethods'
    );
  }
}

export default alt.createActions(PaymentActions);
