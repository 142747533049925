/* eslint-disable max-classes-per-file */

import CapacitorPlugin from 'shared/native/helpers/CapacitorPlugin';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const debug = true;

function log(...args) {
  if (debug) {
    // eslint-disable-next-line no-console
    console.log('NativeUpdater', args);
  }
}

class NativeAppVersion {
  bundleUrl = null;

  version = null;

  host = null;

  constructor({ bundleUrl, host, version }) {
    this.bundleUrl = bundleUrl;
    this.host = host;
    this.version = version;
  }
}

// Wrapper for https://github.com/Cap-go/capacitor-updater
class NativeUpdater extends CapacitorPlugin {
  pluginName = 'CapacitorUpdater';

  async initializeNativeUpdates() {
    if (this.isNativeApp()) {
      await this.ready();
      await this.initializeBuiltInVersion();
      this.notifyAppReady();
      return this.checkForUpdates();
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  notifyAppReady() {
    CapacitorUpdater.notifyAppReady();
  }

  async checkForUpdates() {
    try {
      const currentVersion = await this.getCurrentVersion();
      const versionToUse = await this.getVersionToUse();

      if (versionToUse.version !== currentVersion.version) {
        log(
          `new version to install: ${versionToUse.version} (was ${currentVersion.version})`
        );
        let download = await this.findDownloadedVersion(versionToUse);
        if (download) {
          log(`latest version (${versionToUse.version}) already downloaded`);
        } else {
          download = await this.downloadVersion(versionToUse);
        }
        await this.useDownload(download);
      } else {
        log(`version ${versionToUse.version} already installed and in use`);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isNativeApp() {
    return Capacitor.isNativePlatform();
  }

  // eslint-disable-next-line class-methods-use-this
  async getVersionToUse() {
    const currentVersion = await this.getCurrentVersion();
    const latestVersion = await this.getLatestVersion();

    if (!latestVersion) {
      return currentVersion;
    }

    // if the latest version on the server is older than the installed version,
    // one of two things has happened: (a) a rollback, or (b) the new version was
    // just deployed, and both versions are still serving requests.  We want to
    // support rollbacks, but we do not want thrashing back and forth between versions
    // during a warm deploy. So, we will only rollback if the newer version has been
    // installed for 10 minutes.
    if (latestVersion.version < currentVersion.version) {
      const { lastInstalledAt } = this;
      if (lastInstalledAt) {
        const tenMinutesAgo = new Date();
        tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);
        if (lastInstalledAt > tenMinutesAgo) {
          log(
            `latest version (${latestVersion.version}) is older than installed version (${currentVersion.version}), but installed version is less than 10 minutes old.  Not rolling back.`
          );
          return currentVersion;
        }
      }

      // also allow the current version if it is the built-in version (mostly for testing)
      const builtInVersion = await this.getBuiltInVersion();
      if (currentVersion.version === builtInVersion.version) {
        log(
          `latest version (${latestVersion.version}) is older than installed version (${currentVersion.version}), but installed version is the built-in version.  Not rolling back.`
        );
        return currentVersion;
      }
    }

    // in any other case, return the latest version specified by the server
    return latestVersion;
  }

  // eslint-disable-next-line class-methods-use-this
  get lastInstalledAt() {
    const value = window.localStorage.getItem('nativeAppLastInstalledAt');
    return value ? new Date(value) : null;
  }

  // eslint-disable-next-line class-methods-use-this
  updateLastInstalledAt() {
    const value = new Date().toISOString();
    window.localStorage.setItem('nativeAppLastInstalledAt', value);
  }

  // eslint-disable-next-line class-methods-use-this
  async getCurrentVersion() {
    if (!this.currentVersion) {
      // this info.json file is generated by the native app build process --
      // the installed version is included in the bundle, and the latest version
      // is available on the server
      const versionInfoUrl = `./native/info.json?${new Date().getTime()}}`;
      const response = await fetch(versionInfoUrl);
      if (!response.ok) {
        throw new Error(
          `Error fetching current version info: ${response.statusText}`
        );
      }
      const { version, host, bundleUrl } = await response.json();
      this.currentVersion = new NativeAppVersion({ version, host, bundleUrl });
    }
    return this.currentVersion;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLatestVersion() {
    try {
      const currentVersion = await this.getCurrentVersion();
      const currentHost = currentVersion.host;
      // use CapacitorHttp to bypass CORS restrictions
      const infoPath = `/native/info.json?${new Date().getTime()}}`;
      const url = await this.urlFor(currentHost, infoPath);
      log('fetching info.json', url);

      const versionInfo = await CapacitorHttp.get({
        url,
        responseType: 'json',
      });

      if (versionInfo.status === 200) {
        const { version, host, bundleUrl } = versionInfo.data;
        const isValidResponse = !!version;
        if (isValidResponse) {
          return new NativeAppVersion({ version, host, bundleUrl });
        }
        return null;
      }
      throw new Error(
        `The server returned a status code of ${versionInfo.status}`
      );
    } catch (e) {
      throw new Error(`Error fetching version info: ${e}`);
    }
  }

  downloadedVersions = null;

  async getDownloadedVersions() {
    if (!this.downloadedVersions) {
      this.downloadedVersions = await CapacitorUpdater.list();
    }
    return this.downloadedVersions;
  }

  async findDownloadedVersion(appVersion) {
    log(`detecting if version ${appVersion.version} is already installed...`);
    const downloadedVersions = await this.getDownloadedVersions();
    return downloadedVersions.bundles.find(
      v => v.version === appVersion.version
    );
  }

  // eslint-disable-next-line class-methods-use-this
  async downloadVersion(appVersion) {
    log(`downloading version ${appVersion.version}...`);
    const url = await this.urlFor(appVersion.host, appVersion.bundleUrl);
    const { version } = appVersion;
    return CapacitorUpdater.download({ url, version });
  }

  // eslint-disable-next-line class-methods-use-this
  async useDownload(download) {
    const current = await CapacitorUpdater.current();
    if (current && current.version === download.version) {
      log(`version ${download.version} already in use`);
      return;
    }

    log(`installing version ${download.version}...`);
    this.updateLastInstalledAt();
    await CapacitorUpdater.set(download);
  }

  // stores the built in version in preferences so that it can be used after subsequent updates
  async initializeBuiltInVersion() {
    const arePreferencesStored = await this.getPreference('NativeUpdater.host');
    if (!arePreferencesStored) {
      const { host, version } = await this.getCurrentVersion();
      await Preferences.set({ key: 'NativeUpdater.host', value: host });
      await Preferences.set({ key: 'NativeUpdater.version', value: version });
    }
  }

  async getBuiltInVersion() {
    if (!this.builtInVersion) {
      const host = await this.getPreference('NativeUpdater.host');
      const version = await this.getPreference('NativeUpdater.version');
      this.builtInVersion = new NativeAppVersion({ host, version });
    }
    return this.builtInVersion;
  }

  // eslint-disable-next-line class-methods-use-this
  async getPreference(key) {
    const { value } = await Preferences.get({ key });
    return value;
  }

  async urlFor(host, path) {
    let url = host;
    if (!url) {
      const builtInVersion = await this.getBuiltInVersion();
      url = builtInVersion.host;
    }
    url += path;
    return url;
  }
}

export default new NativeUpdater();
