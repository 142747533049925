import { List, fromJS } from 'immutable';
import moment from 'moment-timezone';
import jwtDecode from 'jwt-decode';

import { isNative } from 'shared/utils/UserAgentUtils.js';
// eslint-disable-next-line import/no-cycle
import NativeBiometric from 'shared/native/BiometricAuthenticator.js';
import Cookie from 'shared/utils/MDNSimpleCookie';
import { urlCustomerId } from 'shared/utils/RouteUtils';

/**
 * CookieStore is used for web, LocalStorageStore is used for native.
 */
const COOKIE_STORE = {
  getItem: key => Cookie.getItem(key),

  setItem: (key, value, expiresAt) => {
    document.cookie = `${key}=${value};expires=${expiresAt};path=/`;
  },

  deleteItem: key => {
    const expUTC = moment.utc().add(-1, 'days').toString();
    document.cookie = `${key}=;expires=${expUTC};path=/`;
  },
};

const LOCAL_STORAGE_STORE = {
  getItem: key => window.localStorage.getItem(key),

  setItem: (key, value, expiresAt) => {
    if (value !== null && value !== undefined) {
      window.localStorage.setItem(key, value);
      if (expiresAt) {
        window.localStorage.setItem(`${key}_expires`, expiresAt);
      } else {
        window.localStorage.removeItem(`${key}_expires`);
      }
    } else {
      LOCAL_STORAGE_STORE.deleteItem(key);
    }
  },

  deleteItem: key => {
    window.localStorage.removeItem(key);
    window.localStorage.removeItem(`${key}_expires`);
  },
};

const tokenStore = isNative() ? LOCAL_STORAGE_STORE : COOKIE_STORE;

const clearActiveToken = () => {
  tokenStore.deleteItem('token');
  tokenStore.deleteItem('customerMeta');
};

/**
 * Remove Active token and full roles list
 */
export const clearStorage = () => {
  clearActiveToken();
  window.localStorage.removeItem('announcementText');
  window.localStorage.removeItem('announcementDismissed');
  // clear legacy "tokens" storage, this can be removed in the future.
  window.localStorage.removeItem('tokens');
  window.localStorage.removeItem('roles');
};

/**
 * Return the current active token.
 */
export const getActiveToken = () => tokenStore.getItem('token') || null;

/**
 * Set the Active token used for API requests
 */
export const setActiveToken = token => {
  if (token) {
    const data = jwtDecode(token);
    const tokenExp = moment.utc(data.exp, 'X').toString();
    tokenStore.setItem('token', token, tokenExp);
  }
};

/**
 * Set the tokens stored to switch between customers, Should only be used by the RoleDataStore!
 *
 * Future refactor we should not need to store this in SessionStorage, we can use the
 *    RolesSource and dataStore just need to populate for customer routes and accounts/choose.
 */
export const setRoles = roles => {
  window.localStorage.setItem('roles', JSON.stringify(roles));
};

/** do not use this to get current role, RolesDataStore when possible use getCurrentContext() as a fallback */
export const getRoles = () => {
  // If no active token, then we should not have any roles. make sure we clear them
  if (!getActiveToken()) {
    clearStorage();
    return List();
  }

  const roles = fromJS(JSON.parse(window.localStorage.getItem('roles')));
  if (!roles || roles.count() === 0) {
    // no tokens currently
    return List();
  }

  return roles;
};

/**
 * removes Current token and token list, and redirects to login.
 * removes Biometric login storage
 */
export function logout() {
  const redirectToLogin = () => {
    clearStorage();

    // redirectTo({
    //   path: '/accounts/login',
    // });
    // Hard Refresh to make sure window state(currentUser) gets reset correctly
    window.location.href = '/accounts/login';
  };
  // This was an explicit logout request. Delete the credentials.
  NativeBiometric.deleteCredentials()
    .then(redirectToLogin)
    .catch(redirectToLogin);
}

/** returns the current context the app is in. aka user & customer & role
 * Try to get this info from the currentContextStore instead. Using this
 * is better then getting currentUser, or CurrentCustomer from window.
 */
export const getCurrentContext = () => {
  const token = getActiveToken();
  const data = token ? jwtDecode(token) : null;

  // Temp while we switch over to new token type. Can remove after a 10 days
  if (data && data.customer_id) {
    logout();
  }
  // end Temp while we switch over to new token type

  const urlCustId = urlCustomerId();

  // get the role with a matching customer_id
  const customerContext = getRoles().find(
    t => t.get('customerId') === Number(urlCustId)
  );

  return {
    userId: data?.sub,
    customerUserId: customerContext?.get('roleId'),
    customerId: urlCustId,
    role: customerContext?.get('roleType'),
    userRole: customerContext?.get('userRole'),
  };
};

/**  How often should we check for expired token. we double this time to expire tokens
 * example:
 */
const tokenCheckIntervalMilliseconds = 300000;

/** Check if our token will expire soon, and force re-login before it does */
const tokenExpCheck = () => {
  const token = getActiveToken();

  if (token) {
    // double our token check time and add 1 so we never miss a token expiration(convert to seconds)
    const tokenTimeout = (tokenCheckIntervalMilliseconds / 1000) * 2 + 1;
    const nowWithOffset = moment().utc().add(tokenTimeout, 'seconds');
    const tokenExp = moment.utc(jwtDecode(token).exp, 'X');

    if (nowWithOffset.isAfter(tokenExp)) {
      clearStorage();

      // Hard Refresh to make sure window state(currentUser) gets reset correctly (token only expires every 24hours)
      window.location.href = `/accounts/login?redirect_to=${window.location.pathname}`;
    }
  }
};

// call our Interval when file is loaded to check for token expiration.
window.setInterval(tokenExpCheck, tokenCheckIntervalMilliseconds);
