import * as React from 'react';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

function FormattedData({ data }) {
  return !data || data === 0.0 ? (
    <span>-</span>
  ) : (
    <FormattedCurrency value={data} fromCents />
  );
}

function Row({ data }) {
  return (
    <tr>
      <td style={{ fontWeight: 'bold' }}>{data.name}</td>
      <td>
        <FormattedData data={data.amount} />
      </td>
      <td>
        <FormattedData data={data.tax} />
      </td>
      <td
        style={
          data.refunded_amount === 0 ? { color: 'inherit' } : { color: 'red' }
        }
      >
        <FormattedData data={data.refunded_amount} />
      </td>
      <td
        style={
          data.refunded_tax === 0 ? { color: 'inherit' } : { color: 'red' }
        }
      >
        <FormattedData data={data.refunded_tax} />
      </td>
      <td>
        <FormattedData data={data.total} />
      </td>
    </tr>
  );
}

function RowExtended({ data }) {
  return (
    <tr>
      <td style={{ fontWeight: 'bold' }}>{data.name}</td>
      <td>
        <FormattedData data={data.amount} />
      </td>
      <td>
        <FormattedData data={data.tax} />
      </td>
      <td>{data.quantity}</td>
      <td
        style={
          data.refunded_amount === 0 ? { color: 'inherit' } : { color: 'red' }
        }
      >
        <FormattedData data={data.refunded_amount} />
      </td>
      <td
        style={
          data.refunded_tax === 0 ? { color: 'inherit' } : { color: 'red' }
        }
      >
        <FormattedData data={data.refunded_tax} />
      </td>
      <td>{data.refunded_quantity}</td>
      <td>
        <FormattedData data={data.total} />
      </td>
    </tr>
  );
}

export { Row, RowExtended, FormattedData };
