import { DefaultSourceActions } from './DefaultSourceActionList';

class CouponActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'removeSuccess',
      'removeError'
    );
  }
}

export default alt.createActions(CouponActions);
