import * as React from 'react';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import ContactAutocomplete from 'shared/components/ContactAutocomplete.jsx';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import MailTo from 'shared/components/MailTo.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import Tel from 'shared/components/Tel.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import ContactsGroupsActions from '../Actions';
import ContactGroupToolbar from './ContactGroupToolbar.jsx';

const styles = {
  root: {
    margin: 0,
    overflow: 'hidden',
  },
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'var(--color-white)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ContactTable({
  selectedGroup,
  contacts,
  selectedContacts,
  perPage,
  currentPage,
  totalCount,
}) {
  const handleContactSelection = selected => {
    if (selectedGroup.id !== -1) {
      const selectedSet = selectedContacts.toSet();
      const isSelected = selectedSet.includes(selected.id);
      const newSelected = !isSelected
        ? selectedSet
            .union(
              contacts
                .filter(c => c.id === selected.id)
                .map(c => c.id)
                .toSet()
            )
            .toList()
        : selectedSet.delete(selected.id);

      ContactGroupActions.contactsSelected(newSelected);
    }
  };

  return (
    <div style={{ margin: 0 }}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {contacts.map(contact => (
              <StyledTableRow
                key={contact.id}
                onClick={() => handleContactSelection(contact)}
              >
                {selectedGroup.id !== -1 &&
                  !selectedGroup.contact_filter_id && (
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selectedContacts.includes(contact.id)}
                      />
                    </StyledTableCell>
                  )}
                <StyledTableCell component="th" scope="row">
                  <FlexBoxCenter>
                    <UserAvatar user={contact} />
                    <p>{contact.name()}</p>
                  </FlexBoxCenter>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tel number={contact.phone} />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <MailTo email={contact.email} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginator
        perPage={perPage}
        currentPage={currentPage}
        totalCount={totalCount || 0}
        onPageSelect={ContactsGroupsActions.contactListPageSelected}
      />
    </div>
  );
}

function GroupsTabLarge({
  addContactContactList,
  allContactGroups,
  allContactsCount,
  contactGroup,
  isSaving,
  selectedContacts,
  contacts,
  perPage,
  currentPage,
  totalCount,
}) {
  const isAddingGroup = contactGroup.id === null;

  return (
    <div style={styles.root}>
      <ContactGroupToolbar
        allContactsCount={allContactsCount}
        contactGroups={allContactGroups}
        isSaving={isSaving}
        selectedContacts={selectedContacts}
        selectedGroup={contactGroup}
      />
      <Divider />
      {!isAddingGroup && (
        <div>
          <ContactAutocomplete
            contacts={addContactContactList}
            onSelect={contactId => ContactsGroupsActions.addContact(contactId)}
            onUpdateInput={ContactsGroupsActions.addContactSearchUpdated}
            style={{ backgroundColor: 'white' }}
          />
          <Divider />
          <SearchBar
            onTypingEnd={ContactsGroupsActions.contactListSearchUpdated}
            style={{
              padding: '10px 20px',
              fontSize: 16,
              backgroundColor: 'white',
            }}
          />
        </div>
      )}
      <ContactTable
        selectedGroup={contactGroup}
        contacts={contacts}
        selectedContacts={selectedContacts}
        perPage={perPage}
        currentPage={currentPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default GroupsTabLarge;
