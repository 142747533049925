import { Record } from 'immutable';
import File from 'shared/native/File';
import { convertDateToServerValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

class NativeContact extends Record({
  id: null,
  date_of_birth: null,
  email: null,
  first_name: null,
  gender: null,
  last_name: null,
  phone: null,
  profileBlob: null,
  profilePictureURL: null,
  selected: false,
  type: 'client',
  role: 'standard',
  street_address: null,
  city: null,
  state: null,
  postal_code: null,
  alreadyExists: false,
}) {
  async toServer() {
    let profileImage = null;
    if (this.profileBlob && this.profilePictureURL) {
      const dataURL = await File.convertToDataURL(this.profileBlob);
      const parts = dataURL.match('data:(.*),(.*)');
      const [type, base64Data] = [parts[1].split(';')[0], parts[2]];

      profileImage = {
        data: base64Data,
        file_name: this.profilePictureURL.split('/').pop(),
        // Provide a default if all else fails as the server expects a content type.
        content_type: type || this.profileBlob.type || 'image/jpg',
      };
    }

    let address = null;
    if (this.street_address && this.city && this.state && this.postal_code) {
      address = {
        line_1: this.street_address,
        city: this.city,
        state: this.state,
        postal_code: this.postal_code,
      };
    }

    return {
      ...(address && { address }),
      date_of_birth: convertDateToServerValue(this.date_of_birth),
      email: this.email,
      first_name: this.first_name,
      gender: this.gender,
      last_name: this.last_name,
      login_enabled: true,
      phone: this.phone,
      ...(profileImage && { profile_image: profileImage }),
      type: this.type,
    };
  }

  fullName() {
    if (this.first_name && this.last_name) {
      return `${this.first_name} ${this.last_name}`.trim();
    }
    return `${this.first_name || ''}${this.last_name || ''}`.trim();
  }

  firstLetterOfName() {
    const name = this.fullName();
    if (name && name.length && name.length > 0) {
      return name[0].toUpperCase();
    }
    return '';
  }

  canImport() {
    return this.importErrorMessage() === '';
  }

  isStaff() {
    return this.type === 'staff_member';
  }

  importErrorMessage() {
    if (this.alreadyExists) {
      return '.already_exists';
    }
    if (!this.first_name) {
      return '.missing_first_name';
    }
    if (!this.last_name) {
      return '.missing_last_name';
    }
    if (!this.email) {
      return '.missing_email';
    }
    return '';
  }

  garbageCollect() {
    if (this.profilePictureURL) {
      window.URL.revokeObjectURL(this.profilePictureURL);
    }
  }
}

export default NativeContact;
