import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@upperhand/playmaker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import ImageUpload from 'components/ImageUpload';
import CopyHref from 'shared/components/CopyHref.jsx';
import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';
import { FlexBoxColumnLayout } from 'shared/components/FlexBox.jsx';

import uhTheme from '_uh_theme.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { shortHumanUrl } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

const styles = {
  labelStyle: merge(boldText, {
    color: uhColors.darkGrey,
    paddingBottom: '1em',
    display: 'block',
  }),
};

class TabMarketing extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = `
      .description-editor { font-family: ${uhTheme.fontFamily}; }
      .public-DraftEditor-content { min-height: 250px; }
      a { color: ${uhColors.lightBlue}; }
    `;
  }

  shouldComponentUpdate(nextProps) {
    const { customerEvent: oldCustomerEvent } = this.props;
    const { customerEvent: newCustomerEvent } = nextProps;

    return (
      oldCustomerEvent.description !== newCustomerEvent.description ||
      oldCustomerEvent.path !== newCustomerEvent.path ||
      oldCustomerEvent.image.getSrc() !== newCustomerEvent.image.getSrc() ||
      oldCustomerEvent.external_url !== newCustomerEvent.external_url
    );
  }

  getEventMarketingUrl = () => {
    const { customerEvent } = this.props;

    return `${window.app_host || window.location.origin}${customerEvent.path}`;
  };

  imageContainer = () => {
    const { customerEvent } = this.props;

    return (
      <ImageUpload
        cropAspect={1.6}
        uploadContainerHeight={300}
        uploadContainerWidth={500}
        onChange={this.handleImageChange}
        onRemove={this.handleImageChange}
        image={customerEvent.image}
      />
    );
  };

  handleDescriptionChange = value => {
    const { handleInputChange } = this.props;

    handleInputChange({
      target: {
        type: 'text',
        name: 'description',
        value,
      },
    });
  };

  handleExternalUrlToggle = e => {
    const { handleInputChange } = this.props;

    handleInputChange({
      target: {
        type: 'text',
        name: 'external_url',
        value: e.target.checked ? '' : null,
      },
    });
  };

  handleExternalUrlChange = e => {
    const { handleInputChange } = this.props;

    handleInputChange({
      target: {
        type: 'text',
        name: 'external_url',
        value: e.target.value,
      },
    });
  };

  handleImageChange = image => {
    const { handleInputChange } = this.props;

    handleInputChange({
      target: {
        type: 'image',
        name: 'image',
        value: image,
      },
    });
  };

  eventLink = () => {
    const { intl, customerEvent } = this.props;

    if (customerEvent.path) {
      return (
        <CopyHref
          href={this.getEventMarketingUrl()}
          label={t('.event_url', intl, __filenamespace)}
          labelStyle={styles.labelStyle}
          text={shortHumanUrl(this.getEventMarketingUrl(), 70)}
        />
      );
    }
    return (
      <div style={{ paddingTop: 10 }}>
        <FormattedMessage id={messageId('.event_title', __filenamespace)} />
      </div>
    );
  };

  eventExternalLink = () => {
    const { intl, customerEvent } = this.props;
    const showExternalUrl =
      enabledCustomerFeatures(['external_event_url']) &&
      customerEvent.isFixedSchedule();
    const showExternalUrlField =
      showExternalUrl && customerEvent.external_url !== null;

    if (!showExternalUrl) return null;

    return (
      <>
        <FormControlLabel
          control={<Checkbox />}
          label={t('.external_event_url', intl, __filenamespace)}
          checked={customerEvent.external_url !== null}
          componentsProps={{
            typography: {
              sx: { fontWeight: 600, fontSize: '12px' },
            },
          }}
          onChange={this.handleExternalUrlToggle}
        />
        {showExternalUrlField && (
          <TextField
            fullWidth
            onChange={this.handleExternalUrlChange}
            value={customerEvent.external_url}
          />
        )}
      </>
    );
  };

  render() {
    const { customerEvent } = this.props;

    return (
      <section>
        <style
          type="text/css"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: this.stylesheet }}
        />
        <FlexBoxColumnLayout
          childrenStyle={{ maxWidth: 700 }}
          style={{ justifyContent: 'flex-start' }}
        >
          <div style={{ paddingBottom: '1em' }}>
            <div
              style={{
                maxWidth: 540,
                minWidth: 460,
                width: '90%',
                paddingBottom: '1.5em',
              }}
            >
              {this.eventLink()}
              {this.eventExternalLink()}
            </div>
            <div>
              <span style={styles.labelStyle}>
                <FormattedMessage
                  id={messageId('.event_description', __filenamespace)}
                />
              </span>
              <div style={{ maxWidth: 540, minWidth: 460, width: '90%' }}>
                <WysiwygEditor
                  useBase64={false}
                  value={customerEvent.description}
                  onChange={this.handleDescriptionChange}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: '1em' }}>
            <span style={styles.labelStyle}>
              <FormattedMessage
                id={messageId('.event_image', __filenamespace)}
              />
            </span>
            {this.imageContainer()}
          </div>
        </FlexBoxColumnLayout>
      </section>
    );
  }
}

export default injectIntl(TabMarketing);
