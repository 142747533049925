import * as React from 'react';
import { Grid } from '@upperhand/playmaker';

import { smallScreen } from 'shared/utils/DOMUtils';

import EmptyState from './EmptyState.jsx';
import TableHeader from './TableHeader.jsx';
import StaffCard from './StaffCard.jsx';

function Table({ staffIds, filtersPresent }) {
  return (
    <div>
      <Grid item container spacing={1}>
        {/* Add Hidden from MUI 4.0 to Playmaker and use it instead. */}
        {/* 960 is equivilent to the md breakpoint in PM. */}
        {!smallScreen(959) && (
          <Grid item md={12}>
            <TableHeader />
          </Grid>
        )}
        {staffIds.map(id => (
          <Grid item key={id} xs={12}>
            <StaffCard staffId={id} />
          </Grid>
        ))}
        {staffIds.isEmpty() && (
          <Grid item xs={12}>
            <EmptyState filtersPresent={filtersPresent} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Table;
