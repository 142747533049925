import { List, Iterable } from 'immutable';
import AutomationTemplateDescription from 'shared/records/AutomationTemplateDescription.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import RecurringPaymentPlanOption from 'shared/records/automations/RecurringPaymentPlanOption.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class RecurringPaymentPlanDescription extends AutomationTemplateDescription {
  constructor(obj = {}, options = { fromServer: false }) {
    if (Iterable.isIterable(obj)) {
      obj = obj.toJS();
    }

    const plans = List(obj.template_parameters || []).map(
      plan => new RecurringPaymentPlanOption(plan)
    );

    super(merge(obj, { template_parameters: plans }));
  }

  getPlan(uuid) {
    const option = super.getOption(uuid);

    return option ? new RecurringPaymentPlanOption(option) : option;
  }

  plans() {
    return this.template_parameters.map(
      plan => new RecurringPaymentPlanOption(plan)
    );
  }

  getMinimumPrice() {
    const plans = this.plans();

    return plans.size === 0 ? -1 : plans.map(p => p.totalPrice()).min();
  }

  validate() {
    let errors = new FieldErrors();

    if (!this.template_parameters || this.template_parameters.size === 0) {
      errors = errors.add(
        'template_parameters',
        'At least one plan is required.'
      );
    }

    this.template_parameters.forEach((params, optionIndex) => {
      if (!params.get('name')) {
        errors = errors.add(
          ['template_parameters', optionIndex, 'name'],
          'records.errors.required'
        );
      }

      if (!params.get('payments') || params.get('payments').size === 0) {
        errors = errors.add(
          ['template_parameters', optionIndex, 'payments'],
          'At least one payment is required.'
        );
      }

      params.get('payments').forEach((payment, paymentIndex) => {
        if (!payment.get('date')) {
          errors = errors.add(
            [
              'template_parameters',
              optionIndex,
              'payments',
              paymentIndex,
              'date',
            ],
            'records.errors.required'
          );
        }

        if (!payment.get('amount')) {
          errors = errors.add(
            [
              'template_parameters',
              optionIndex,
              'payments',
              paymentIndex,
              'amount',
            ],
            'records.errors.required'
          );
        }

        // Stripe minimum allowed charge is $0.50.
        if (payment.get('amount') < 50) {
          errors = errors.add(
            [
              'template_parameters',
              optionIndex,
              'payments',
              paymentIndex,
              'amount',
            ],
            'records.errors.below_stripe_minimum'
          );
        }
      });
    });

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }
}

RecurringPaymentPlanDescription.fromServer = obj =>
  new RecurringPaymentPlanDescription(obj, { fromServer: true });

export default RecurringPaymentPlanDescription;
