/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
import { OrderedMap } from 'immutable';
import Client from 'shared/records/Client.jsx';
import * as ClientSource from 'sources/ClientSource';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSClientActions from 'point_of_sale/actions/POSClientActions.jsx';
import POSClientStore from 'point_of_sale/stores/POSClientStore.jsx';
import POSClientListActions from 'point_of_sale/actions/POSClientListActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

export const FILTER_STATUS_ACTIVE = 'active';
export const FILTER_STATUS_INVITED = 'invited';
export const FILTER_STATUS_UNCLAIMED = 'unclaimed';

class POSClientListStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      listClients: POSActions.POS_CLICKED,
      listSuccess: POSClientListActions.LIST_SUCCESS,
      listError: POSClientListActions.LIST_ERROR,

      updateSearchString: POSClientListActions.SEARCH_STRING_UPDATED,
      requestPage: POSClientListActions.PAGE_SELECTED,

      createOrUpdateClient: POSClientActions.CREATE_OR_UPDATE_CLIENT_SUCCESS,

      reset: POSCheckoutActions.CLOSE_BUTTON_CLICKED,
    });
  }

  reset() {
    this.clients = OrderedMap();

    this.searchString = '';
    this.page = 1;
    this.perPage = 15;
    this.totalCount = 0;

    this.shouldFetch = true;
    this.isLoading = false;
  }

  listClients() {
    if (this.shouldFetch) {
      this.isLoading = true;
      this.shouldFetch = false;

      const params = {
        page: this.page,
        per_page: this.perPage,
        statuses: [
          FILTER_STATUS_ACTIVE,
          FILTER_STATUS_INVITED,
          FILTER_STATUS_UNCLAIMED,
        ],
      };

      if (this.searchString) {
        params.search = this.searchString;
      }

      ClientSource.list({
        params: {
          ...params,
          fields: [
            'agreement_details',
            'total_account_credit',
            'active_membership_id',
            'active_membership_tier_id',
          ],
        },
        success: POSClientListActions.listSuccess,
        error: POSClientListActions.listError,
      });
    }
  }

  listSuccess({ clients, page, perPage, totalCount }) {
    this.isLoading = false;
    this.page = page;
    this.perPage = perPage; // eslint-disable-line camelcase
    this.totalCount = totalCount; // eslint-disable-line camelcase

    this.clients = OrderedMap().withMutations(clientMap =>
      clients.forEach(c => clientMap.set(c.id, new Client(c)))
    );
  }

  listError() {
    this.isLoading = false;
    this.notifyError('error listing clients', arguments);
  }

  updateSearchString(args) {
    let newString = args;
    let force = false;

    if (Array.isArray(args)) {
      newString = args[0];
      force = args.length > 1 && args[1];
    }

    if (!force && this.searchString === newString) {
      return false;
    }

    this.searchString = newString;
    this.page = 1;
    this.shouldFetch = true;

    this.listClients();
  }

  requestPage(page) {
    if (this.page === page) {
      return false;
    }

    this.page = page;
    this.shouldFetch = true;

    this.listClients();
  }

  createOrUpdateClient() {
    this.waitFor(POSClientStore);

    const { client } = POSClientStore.getState();

    if (this.clients.has(client.id)) {
      this.clients = this.clients.set(client.id, client);
    } else {
      this.shouldFetch = true;
      this.listClients();
    }
  }
}

export default alt.createStore(POSClientListStore, 'POSClientListStore');
