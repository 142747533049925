class CreditPassPurchasingActions {
  constructor() {
    this.generateActions(
      'listingCreditPassPurchasesError',
      'listingCreditPassPurchasesSuccess',
      'mount',
      'profileAdded',
      'profileCreated',
      'purchaseClicked'
    );
  }
}

export default alt.createActions(CreditPassPurchasingActions);
