import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import EventStaffActions from 'event_mgmt/display/actions/EventStaffActions.js';
import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  expanderButton: {
    cursor: 'pointer',
    color: uhColors.activeBlue,
  },
};

const toggleExpand = () => EventStaffActions.toggleExpand();

const ShowMore = injectIntl(({ moreCount, intl }) => (
  <div style={styles.expanderButton} onClick={toggleExpand}>
    <AvatarWithName
      name={t('.n_more', intl, __filenamespace, { n: moreCount })}
      letter={moreCount.toString()}
      avatarOptions={{ backgroundColor: styles.expanderButton.color }}
    />
  </div>
));

const ShowLess = () => (
  <div style={styles.expanderButton} onClick={toggleExpand}>
    <span style={{ lineHeight: '32px' }}>
      <FormattedMessage id={messageId('.show_less', __filenamespace)} />
    </span>
  </div>
);

const MoreLessButton = ({ moreCount, expanded }) => {
  if (!expanded && moreCount === 0) {
    return null;
  }

  return expanded ? <ShowLess /> : <ShowMore moreCount={moreCount} />;
};

const StaffListItem = ({ staff, style }) => (
  <AvatarWithName user={staff} style={style} />
);

const StaffList = ({ staff, visibleStaff, expanded, style }) => {
  if (staff.isEmpty()) {
    return null;
  }

  return (
    <div style={style}>
      <SectionHeader style={{ marginBottom: 20 }}>
        <FormattedMessage id={messageId('.staff', __filenamespace)} />
      </SectionHeader>
      {visibleStaff.map(staff => (
        <StaffListItem
          key={`staff-list-item-${staff.id}`}
          staff={staff}
          style={{ marginBottom: 8 }}
        />
      ))}
      <MoreLessButton
        expanded={expanded}
        moreCount={staff.size - visibleStaff.size}
      />
    </div>
  );
};

export default StaffList;
