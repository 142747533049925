import * as React from 'react';
import PropTypes from 'prop-types';
import { ContentModal } from '@upperhand/playmaker';

import EventTypeForm from 'shared/components/EventTypeForm.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import EventTypeEditingStore from 'shared/stores/EventTypeEditingStore.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

function Modal({ eventType, isOpen, onSuccess, onAbort, intl }) {
  const isNew = eventType ? eventType.id === null : false;
  const title = isNew
    ? t('.title_create_mode', intl, __filenamespace)
    : t('.title_edit_mode', intl, __filenamespace);

  return (
    <ContentModal
      open={isOpen}
      title={title}
      onSuccess={onSuccess}
      onAbort={onAbort}
      cancelLabel={t('.cancel_button', intl, __filenamespace)}
      successLabel={t('.success_button', intl, __filenamespace)}
    >
      <EventTypeForm eventType={eventType} />
    </ContentModal>
  );
}

Modal.propTypes = {
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
};

export default compose(
  altContainer({
    stores: {
      eventType: () => ({
        store: EventTypeEditingStore,
        value: EventTypeEditingStore.getState().record,
      }),
    },
  })
)(Modal);
