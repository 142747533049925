export default alt.generateActions(
  'mounted',
  'contactsMapResultLoaded',
  'contactsMapResultError',
  'contactsListResultLoaded',
  'contactsListResultError',
  'locationsMapResultLoaded',
  'locationsMapResultError',
  // export
  'downloadContactsList',
  'downloadContactsListSuccess',
  'downloadContactsListError',
  'applyFilters',
  'clearFilters',
  'filterUpdated'
);
