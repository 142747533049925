export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'AR Baseline | Athletic Republic',
  siteURL: 'https://www.athleticrepublic.com/',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/athletic_republic_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#BD2436',
    mobileSignInColor: '#BD2436',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#BD2436',
    accent1Color: '#BD2436',

    primary: {
      main: '#BD2436',
    },
    secondary: {
      main: '#BD2436',
    },
  },
  sideNav: {
    selectedColor: '#BD2436',
  },
  icons: {
    cart: '#BD2436',
  },
  statusBar: {
    backgroundColor: '#BD2436',
  },
};
