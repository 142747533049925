import { Set } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';

class MembershipPermissionStore extends UpperHandStore {
  constructor() {
    super();

    this.permittedMembershipIds = Set([]);
    this.isPermittedToPurchase = membership =>
      !membership.invite_only ||
      (isLoggedIn() && this.permittedMembershipIds.has(membership.id));

    this.bindListeners({
      handleInitialize: [
        AthleteActions.listSuccess,
        CurrentContextActions.fetchCustomerUserSuccess,
      ],
    });
  }

  handleInitialize() {
    this.waitFor(AthleteStore);
    this.waitFor(CurrentContextStore);
    const { allAthletes } = AthleteStore.getState();
    const currentCustomerUser = CurrentContextStore.getState().customerUser;

    if (currentCustomerUser) {
      this.permittedMembershipIds = currentCustomerUser
        .get('permitted_membership_ids')
        .toSet();
    }

    if (allAthletes.size > 0 && currentCustomerUser) {
      this.permittedMembershipIds = allAthletes
        .flatMap(p => p.get('permitted_membership_ids'))
        .toSet()
        .union(currentCustomerUser.get('permitted_membership_ids'));
    }
  }
}

export default alt.createStore(
  MembershipPermissionStore,
  'MembershipPermissionStore'
);
