import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  ButtonBase,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NoteIcon from '@mui/icons-material/InsertDriveFile';
import NoShowIcon from '@mui/icons-material/RemoveCircle';
import NewClientIcon from '@mui/icons-material/FiberNewOutlined';
import CheckedInIcon from '@mui/icons-material/CheckCircle';
import UnpaidIcon from 'shared/components/icons/UnpaidIcon.jsx';
import CalendarLegendIcon from 'shared/components/icons/CalendarLegendIcon.jsx';
import UnsignedWaiverIcon from 'shared/components/icons/UnsignedWaiver.jsx';
import BirthdayIcon from 'shared/components/icons/Gift.jsx';

import { t } from 'shared/utils/LocaleUtils';
import useIsMobile from 'shared/utils/hooks/useIsMobile';

const styles = {
  buttonBase: {
    color: 'inherit',
    ':hover': { color: 'var(--color-secondary)' },
    marginRight: '1rem',
  },
  menu: {
    '& .UH-MuiMenuItem-root': {
      pointerEvents: 'none', // disable click events
      color: 'var(--color-primary)',
    },
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px !important',
    color: 'var(--color-tab-gray)',
    padding: '0px 8px 6px 16px',
  },
};

function LegendButton({ isMobile, hover, intl }) {
  if (isMobile) {
    return (
      <CalendarLegendIcon
        color={hover ? 'var(--color-secondary)' : 'var(--color-tab-gray'}
      />
    );
  }
  return (
    <>
      <Typography sx={{ marginRight: '1rem', fontSize: '14px' }}>
        {t('.btn_label', intl, __filenamespace)}
      </Typography>
      <CalendarLegendIcon
        color={hover ? 'var(--color-secondary)' : 'var(--color-nav-icon-gray'}
      />
    </>
  );
}

function LegendMenuItem({ children, text }) {
  return (
    <MenuItem>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={text} />
    </MenuItem>
  );
}

function CalendarLegend({ intl }) {
  const isMobile = useIsMobile();
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const menuItems = React.useMemo(
    () => [
      {
        IconComponent: <BirthdayIcon color="#4AD669" />,
        textKey: t('.client_birthday', intl, __filenamespace),
      },
      {
        IconComponent: (
          <UnsignedWaiverIcon fillColor="var(--color-unpaid-red)" />
        ),
        textKey: t('.no_waiver_on_file', intl, __filenamespace),
      },
      {
        IconComponent: <UnpaidIcon />,
        textKey: t('.session_unpaid', intl, __filenamespace),
      },
      {
        IconComponent: (
          <NewClientIcon
            sx={{ color: 'var(--color-green)', fontSize: '2rem' }}
          />
        ),
        textKey: t('.first_booked_event', intl, __filenamespace),
      },
      {
        IconComponent: <NoteIcon sx={{ color: '#FFBC07' }} />,
        textKey: t('.client_or_session_note', intl, __filenamespace),
      },
      {
        IconComponent: <NoShowIcon sx={{ color: '#FF9800' }} />,
        textKey: t('.no_show', intl, __filenamespace),
      },
      {
        IconComponent: <CheckedInIcon sx={{ color: 'var(--color-green)' }} />,
        textKey: t('.checked_in', intl, __filenamespace),
      },
    ],
    [intl]
  );

  return (
    <>
      <ButtonBase
        aria-label="more"
        id="legend-button"
        aria-controls={open ? 'legend-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={styles.buttonBase}
      >
        <LegendButton isMobile={isMobile} hover={hover} intl={intl} />
      </ButtonBase>
      <Menu
        id="legend-menu"
        MenuListProps={{
          'aria-labelledby': 'legend-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '258px',
          },
        }}
        sx={styles.menu}
      >
        <Box sx={styles.box}>
          <Typography> {t('.menu_title', intl, __filenamespace)}</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon sx={{ color: 'var(--color-tab-gray)' }} />
          </IconButton>
        </Box>
        {menuItems.map(({ IconComponent, textKey }) => (
          <LegendMenuItem key={textKey} text={textKey}>
            {IconComponent}
          </LegendMenuItem>
        ))}
      </Menu>
    </>
  );
}

export default injectIntl(CalendarLegend);
