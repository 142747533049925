import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import WaiversReportActions from 'reporting/waivers/actions/WaiversReportActions';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { snakeToTitleCase } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { Widget } from 'reporting/index/shared/components/ReportWidget.jsx';
import { WAIVERS_REPORT_NAMES } from 'reporting/waivers/stores/WaiversReportStore';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

function CustomWaiverCountsforBilling({ waiversForBilling, dateRange, intl }) {
  return (
    <FlexBox style={{ justifyContent: 'center' }}>
      {waiversForBilling.size ? (
        <Widget
          title={
            <FormattedMessage
              id={messageId('.title', __filenamespace)}
              values={{ dateRange: snakeToTitleCase(dateRange) }}
            />
          }
          style={{ width: 400 }}
          actions={[
            {
              icon: 'cloud_download',
              onClick: () =>
                WaiversReportActions.downloadReport(
                  WAIVERS_REPORT_NAMES.WAIVERS_FOR_BILLING
                ),
            },
          ]}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('.name', intl, __filenamespace)}</TableCell>
                  <TableCell>{t('.count', intl, __filenamespace)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {waiversForBilling.map((w, i) => (
                  /* eslint-disable react/no-array-index-key */
                  <TableRow key={i}>
                    <TableCell>{w.customer_name}</TableCell>
                    <TableCell>{w.count}</TableCell>
                  </TableRow>
                  /* eslint-enable react/no-array-index-key */
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Widget>
      ) : (
        <div style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold' }}>
          <FormattedMessage
            id={messageId('.no_data_available', __filenamespace)}
          />
        </div>
      )}
    </FlexBox>
  );
}

export default CustomWaiverCountsforBilling;
