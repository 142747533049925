import * as React from 'react';
import AltContainer from 'alt-container';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';

import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import DecoratedTextField from 'shared/components/DecoratedTextField.jsx';
import EventAutoComplete from 'shared/components/_EventAutoComplete.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import FilterStore from 'event_mgmt/index/stores/FilterStore.jsx';
import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';
import Form from 'shared/components/Form.jsx';
import SelectedEventOrType from 'shared/components/SelectedEventOrType.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import SpecificEventsActions from 'event_mgmt/index/actions/SpecificEventsActions.jsx';

import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { ABSOLUTE, PERCENT } from 'shared/records/EventRate.jsx';

const styles = {
  header: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#9A9EAD',
  },
  tooltip: {
    lineHeight: 1.4,
    fontWeight: 500,
    paddingRight: 25,
  },
  typeIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
    position: 'absolute',
    right: 0,
  },
  formLabel: {
    fontSize: '17px',
    fontWeight: 600,
  },
};

function FormHeader({ eventRate }) {
  return (
    <div style={styles.header}>
      {eventRate && eventRate.id ? (
        <FormattedMessage id={messageId('.edit_rate', __filenamespace)} />
      ) : (
        <div>
          <FormattedMessage id={messageId('.add_rate', __filenamespace)} />
          <InfoIcon
            data-tip
            data-for="event-type-info"
            style={styles.typeIcon}
          />
          <ReactTooltip
            id="event-type-info"
            effect="solid"
            style={{ maxWidth: 240 }}
            className="charge-failure-tooltip uh-tooltip"
          >
            <div style={styles.tooltip}>
              <div style={{ marginBottom: 5 }}>
                <span style={{ fontWeight: 'bold', display: 'inline' }}>
                  <FormattedMessage
                    id={messageId('.booking', __filenamespace)}
                  />
                </span>
                <FormattedMessage
                  id={messageId('.booking-info', __filenamespace)}
                />
              </div>
              <span style={{ fontWeight: 'bold', display: 'inline' }}>
                <FormattedMessage id={messageId('.fixed', __filenamespace)} />
              </span>
              <FormattedMessage
                id={messageId('.fixed-info', __filenamespace)}
              />
            </div>
          </ReactTooltip>
        </div>
      )}
    </div>
  );
}

function CheckboxLabel() {
  return (
    <FlexBoxCenter style={{ flex: '1 1 90%' }}>
      <FormattedMessage
        id={messageId('.discount_sensitive_rate', __filenamespace)}
      />
      <InfoIcon
        data-tip
        data-for="usage-info"
        style={{ height: 20, width: 20, marginLeft: 5 }}
      />
      <ReactTooltip
        id="usage-info"
        effect="solid"
        style={{ maxWidth: 240 }}
        className="charge-failure-tooltip uh-tooltip"
      >
        <div style={{ lineHeight: 1.4 }}>
          <FormattedMessage
            id={messageId('.sensitive_rate', __filenamespace)}
          />
        </div>
      </ReactTooltip>
    </FlexBoxCenter>
  );
}

function SelectedEventsList({ selectedEvents, selectedEventIds, onRemove }) {
  return (
    <div style={{ marginTop: 5 }}>
      {selectedEvents.map(event => (
        <SelectedEventOrType
          key={event.id}
          event={event}
          title={event.title}
          id={event.id}
          barColor={event.event_type.color}
          onRemove={id => {
            onRemove(id);
            EventActions.list({ exceptIds: selectedEventIds.toArray() });
          }}
        />
      ))}
    </div>
  );
}

function EventRateForm({
  selectedEvents,
  eventListStore: { events },
  eventRateStore: {
    eventRate,
    isSaving,
    actions: {
      save,
      cancel,
      updated,
      eventRateEventSelected,
      eventRateEventRemoved,
    },
  },
  filterStore: { titleFilter },
  selectedEventIds,
  intl,
  style,
}) {
  return (
    <Paper style={merge({ padding: 16, position: 'relative' }, style)}>
      <FormHeader eventRate={eventRate} />

      <Form onSubmit={save}>
        <EventAutoComplete
          events={events}
          hintText={t('.search_event', intl, __filenamespace)}
          errorText={eventRate.errors.getErrors('event', intl)}
          onChange={searchText => {
            FilterActions.updateTitleFilter(searchText);
            EventActions.list({ exceptIds: selectedEventIds.toArray() });
          }}
          onEventSelect={eventId => {
            FilterActions.updateTitleFilter('');
            SpecificEventsActions.addSpecificEvent(eventId);
            eventRateEventSelected(eventId);
          }}
          onLeave={() => {
            FilterActions.updateTitleFilter('');
            EventActions.list({ exceptIds: selectedEventIds.toArray() });
          }}
          withIcon={false}
        />
        {selectedEvents && (
          <SelectedEventsList
            selectedEvents={selectedEvents}
            selectedEventIds={selectedEventIds}
            onRemove={eventRateEventRemoved}
          />
        )}
        <FlexBox style={{ marginTop: '1rem' }}>
          <FormControl fullWidth variant="standard">
            <InputLabel sx={styles.formLabel}>
              {t('.type', intl, __filenamespace)}
            </InputLabel>
            <Select
              label={t('.type', intl, __filenamespace)}
              sx={{ fontSize: '14px' }}
              value={eventRate.rate_type || ''}
              onChange={e => updated({ rate_type: e.target.value, value: 0 })}
            >
              <MenuItem
                key="amount-discount"
                value={ABSOLUTE}
                sx={{ fontSize: '14px' }}
              >
                {t('.amount', intl, __filenamespace)}
              </MenuItem>
              <MenuItem
                key="percent-discount"
                value={PERCENT}
                sx={{ fontSize: '14px' }}
              >
                {t('.percent', intl, __filenamespace)}
              </MenuItem>
            </Select>
          </FormControl>
          {eventRate.rate_type === ABSOLUTE ? (
            <CurrencyTextField
              variant="standard"
              name="value"
              floatingLabelText={t('.value', intl, __filenamespace)}
              onChange={(_, v) => updated({ value: v })}
              value={eventRate.value}
              errorText={eventRate.errors.getErrors('value', intl)}
              style={{ marginLeft: 10 }}
              converter={e => parseInt(e, 10)}
              inputLabelProps={{ sx: styles.formLabel }}
            />
          ) : (
            <DecoratedTextField
              variant="standard"
              name="value"
              floatingLabelText={t('.value', intl, __filenamespace)}
              fullWidth
              onChange={(_, v) => updated({ value: v })}
              value={eventRate.value}
              decoration="%"
              inputProps={{ min: 0, max: 100 }}
              errorText={eventRate.errors.getErrors('value', intl)}
              style={{ marginLeft: 10 }}
              inputLabelProps={{ sx: styles.formLabel }}
            />
          )}
        </FlexBox>
        <FlexBoxCenter style={{ marginTop: '1rem' }}>
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': { fontSize: 'initial' },
            }}
            control={
              <Checkbox
                checked={eventRate.discount_sensitive}
                onChange={e =>
                  updated({ discount_sensitive: e.target.checked })
                }
              />
            }
            label={<CheckboxLabel />}
          />
        </FlexBoxCenter>
        <FlexBox style={{ justifyContent: 'flex-end', marginTop: 10 }}>
          <Button
            style={{ marginRight: 14 }}
            onClick={() => {
              cancel();
              if (titleFilter.length > 0) {
                FilterActions.updateTitleFilter('');
                EventActions.list({ exceptIds: selectedEventIds.toArray() });
              }
            }}
          >
            {t('.cancel', intl, __filenamespace)}
          </Button>
          <StateChangingButton
            label={t('.save', intl, __filenamespace)}
            inProgress={isSaving}
            onClick={save}
          />
        </FlexBox>
      </Form>
    </Paper>
  );
}

function EventRateFormWrapper(props) {
  return (
    <AltContainer
      stores={{
        eventListStore: EventListStore,
        filterStore: FilterStore,
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <EventRateForm {...props} />
    </AltContainer>
  );
}

export default injectIntl(EventRateFormWrapper);
