export default alt.generateActions(
  'addAttendeeClicked',
  'allAvailableSingleSessionsAdded',
  'allAvailableSingleSessionsRemoved',
  'automatedPackageSelected',
  'defaultPackageSelected',
  'fixedScheduleAthleteAdded',
  'fixedScheduleAthleteRemoved',
  'fixedScheduleProfileCreated',
  'paymentPlanSelected',
  'profileCreatedOpenBooking',
  'registrationAthleteChanged',
  'registrationBooked',
  'registrationCancelled',
  'registrationStaffChanged',
  'registrationDateChanged',
  'registrationTimeChanged',
  'scheduleLaterClicked',
  'scheduleNowClicked',
  'singleSessionAdded',
  'singleSessionAttendeeAdded',
  'singleSessionAttendeeRemoved',
  'singleSessionProfileCreated',
  'singleSessionRemoved',
  'fetchAvailabilities'
);
