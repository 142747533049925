/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';

import AccountSetupPaysafeStore from 'onboarding/stores/AccountSetupPaysafeStore.js';
import AccountStepper from 'onboarding/components/AccountSetupPaysafe/_Stepper.jsx';
import BankStepActions from 'onboarding/actions/BankStepActions.js';
import BankStepStore from 'onboarding/stores/BankStepStore.js';
import BusinessStepStore from 'onboarding/stores/BusinessStepStore.js';
import BusinessStepActions from 'onboarding/actions/BusinessStepActions.js';
import CompleteStep from 'onboarding/components/AccountSetupPaysafe/steps/_CompleteStep.jsx';
import IdentityStepStore from 'onboarding/stores/IdentityStepStore.js';
import TOSStepStore from 'onboarding/stores/TOSStepStore.js';
import { t } from 'shared/utils/LocaleUtils.js';

import './styles.scss';

import AccountSetupPaysafeActions from '../../actions/AccountSetupPaysafeActions';

function StepperWrapper(props) {
  const {
    accountSetupPaysafeStore: { finished, currentStep },
    steps,
  } = props;
  return finished ? (
    <CompleteStep {...props} />
  ) : (
    <AccountStepper steps={steps} step={currentStep} {...props} />
  );
}

class AccountSetupPaysafe extends React.Component {
  componentDidMount() {
    AccountSetupPaysafeActions.fetchStatus();
    BusinessStepActions.mounted();
    BankStepActions.mounted();
  }

  get steps() {
    const { intl } = this.props;
    return [
      t('.business', intl, __filenamespace),
      t('.bank_account', intl, __filenamespace),
      t('.ownership_information', intl, __filenamespace),
      t('.tos', intl, __filenamespace),
    ];
  }

  render() {
    return (
      <AltContainer
        stores={{
          accountSetupPaysafeStore: AccountSetupPaysafeStore,
          businessStepStore: BusinessStepStore,
          bankStepStore: BankStepStore,
          identityStepStore: IdentityStepStore,
          tosStepStore: TOSStepStore,
        }}
      >
        <StepperWrapper steps={this.steps} />
      </AltContainer>
    );
  }
}

export default injectIntl(AccountSetupPaysafe);
