import * as React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import { Expander, Grid, Typography } from '@upperhand/playmaker';

import Divider from '@mui/material/Divider';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';

import Circle from 'shared/components/Circle.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import TeamTitle from 'team_mgmt/shared/components/_TeamTitle.jsx';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import DetailsActions from 'containers/events/admin/sessionSummaryDrawer/components/details/actions.js';
import { smallScreen } from 'shared/utils/DOMUtils.js';
import { withStyles } from '@material-ui/styles';
import EventNav from './EventNav.jsx';
import StaffList from '../../sessionSummaryDrawer/components/details/components/StaffList.jsx';
import Resources from '../../sessionSummaryDrawer/components/details/components/Resources.jsx';

const styles = {
  root: {
    padding: '0 24px',
  },
  bottomPadding: {
    paddingBottom: '8px',
  },
  chip: {
    alignItems: 'center',
    border: `thin solid ${uhColors.primaryMain}`,
    borderRadius: '25px',
    display: 'flex',
    padding: '1px 12px',
  },
  headerTypographyRoot: {
    maxWidth: '55px',
  },
  address: {
    marginBottom: '4px',
  },
};

function RevenueRenderer({ revenue, intl }) {
  const isManager = currentUser().isManager();
  if (isManager) {
    return (
      <Typography variant="h4">
        {t('.cannot_view_revenue', intl, __filenamespace)}
      </Typography>
    );
  }
  return (
    <Typography variant="h4">
      <FormattedCurrency value={revenue} fromCents />
    </Typography>
  );
}
const DescriptionExpander = withStyles(styles)(
  ({ event, intl, expanded, classes }) => (
    <Expander
      classes={{
        headerTypographyRoot: classes.headerTypographyRoot,
      }}
      header={
        <Typography variant="subtitle2">
          {t('.details', intl, __filenamespace)}
        </Typography>
      }
      expanded={expanded}
      behavior="controlled"
      onChange={DetailsActions.descriptionChanged}
      contentOffset={false}
      arrowRight
    >
      <Typography
        variant="body"
        className={clsx('admin-events__details-wrapper', 'event-description')}
      >
        <Box dangerouslySetInnerHTML={{ __html: event.getHtmlDescription() }} />
      </Typography>
    </Expander>
  )
);

export function Overview({
  event,
  revenue,
  intl,
  staffIds,
  resources,
  resourcesExpanded,
  staffExpanded,
  isClient,
  descriptionExpanded,
}) {
  return (
    <Box sx={styles.root}>
      <Grid container justify="flex-start" spacing={1}>
        <TeamScheduleInfo
          intl={intl}
          event={event}
          revenue={revenue}
          isClient={isClient}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container xs={12}>
          <Box className="staff-list-container">
            <StaffList
              expanded={staffExpanded}
              intl={intl}
              staffIds={staffIds}
              isTeamEvent
            />
          </Box>
        </Grid>
        {isClient && (
          <>
            <Grid item container xs={12}>
              <Box className="resource-list-container">
                <DescriptionExpander
                  event={event}
                  intl={intl}
                  expanded={descriptionExpanded}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {event.image?.url && (
              <Grid item container xs={12}>
                <img src={event.image?.url} alt="" width={200} height={100} />
              </Grid>
            )}
          </>
        )}
        {!isClient && (
          <Grid item container xs={12}>
            <Box className="resource-list-container">
              <Resources
                expanded={resourcesExpanded}
                teamResources={resources}
                intl={intl}
                isTeamEvent
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

function ScheduleInfo({ event }) {
  // TODO: Handle classes.
  const schedule = event.schedules.first();

  if (event.schedule_type === 'fixed_schedule') {
    const startDate = moment(schedule.availability_schedule.start_date);
    const endDate = moment(schedule.availability_schedule.end_date);

    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ color: 'var(--color-nav-icon-gray)' }}>
              <Typography variant="button2">
                {startDate.format('MMM')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">{startDate.format('D')}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>&mdash;</Grid>
        <Grid item>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ color: 'var(--color-nav-icon-gray)' }}>
              <Typography variant="button2">{endDate.format('MMM')}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">{endDate.format('D')}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return <Typography variant="h6">{event.scheduleText()}</Typography>;
}

function TeamScheduleInfo({ intl, event, revenue, isClient }) {
  const contentBox = {
    color: 'var(--color-nav-icon-gray)',
    marginBottom: '.5rem',
  };
  const participantIds = event.get('registered_client_ids');
  const participantCount = participantIds ? participantIds.size : 0;
  const rosterCount = event.getIn(['team_detail', 'roster_size']);

  return (
    <>
      {!isClient && (
        <>
          <Grid item xs={6} className="admin-events__event-info">
            <Box sx={contentBox}>
              <Typography variant="button2">
                {t('.price', intl, __filenamespace)}
              </Typography>
            </Box>
            <Typography variant="h4">
              <FormattedCurrency value={event.price} fromCents={!event.price} />
            </Typography>
          </Grid>
          <Grid item xs={6} className="admin-events__event-info">
            <Box sx={contentBox}>
              <Typography variant="button2">
                {t('.revenue', intl, __filenamespace)}
              </Typography>
            </Box>
            <RevenueRenderer revenue={revenue} intl={intl} />
          </Grid>
        </>
      )}
      <Grid item xs={6} className="admin-events__event-info">
        <Box sx={contentBox}>
          <Typography variant="button2">
            {t('.roster_size', intl, __filenamespace)}
          </Typography>
        </Box>
        <Typography variant="h4">
          {`${participantCount}/${rosterCount}`}
        </Typography>
      </Grid>
      <Grid item xs={6} className="admin-events__event-info">
        <Box sx={contentBox}>
          <Typography variant="button2">
            {t('.age_range', intl, __filenamespace)}
          </Typography>
        </Box>
        <Typography variant="h4">{event.getFutureAgeRestrictions()}</Typography>
      </Grid>
      <Grid item xs={6} className="admin-events__event-info">
        <Box sx={contentBox}>
          <Typography variant="button2">
            {t('.seasons', intl, __filenamespace)}
          </Typography>
        </Box>
        <Typography variant="h4">{event.seasons.join(', ')}</Typography>
      </Grid>
    </>
  );
}

function EventScheduleInfo({ intl, event, revenue, isClient }) {
  return (
    <>
      <Grid
        item
        xs={hasAdminPermission() ? 6 : 12}
        className="admin-events__event-info"
      >
        <Box
          sx={{ color: 'var(--color-nav-icon-gray)', marginBottom: '.5rem' }}
        >
          <Typography variant="button2">
            {t('.price', intl, __filenamespace)}
          </Typography>
        </Box>
        <Typography variant="h4">
          <FormattedCurrency
            value={
              event.price === null || event.price === ''
                ? event.pricing_options.first()
                : event.price
            }
            fromCents={!event.price}
          />
        </Typography>
      </Grid>
      {hasAdminPermission() && (
        <Grid item xs={6} className="admin-events__event-info">
          <Box
            sx={{ color: 'var(--color-nav-icon-gray)', marginBottom: '.5rem' }}
          >
            <Typography variant="button2">
              {t('.revenue', intl, __filenamespace)}
            </Typography>
          </Box>
          <RevenueRenderer revenue={revenue} intl={intl} />
        </Grid>
      )}
      {!isClient && (
        <>
          {event.isFixedSchedule() && (
            <Grid item xs={6} className="admin-events__event-info">
              <Box
                sx={{
                  color: 'var(--color-nav-icon-gray)',
                  marginBottom: '.5rem',
                }}
              >
                <Typography variant="button2">
                  {t('.seasons', intl, __filenamespace)}
                </Typography>
              </Box>
              <Typography variant="h4">{event.seasons.join(', ')}</Typography>
            </Grid>
          )}
          <Grid item xs={6} className="admin-events__event-info">
            <Box
              sx={{
                color: 'var(--color-nav-icon-gray)',
                marginBottom: '.5rem',
              }}
            >
              <Typography variant="button2">
                {t('.age_range', intl, __filenamespace)}
              </Typography>
            </Box>
            <Typography variant="h4">
              {event.getFutureAgeRestrictions()}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
}

function EventTitle({ event, intl }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">{event.title}</Typography>
      </Grid>
      <Grid item container xs={12} spacing={1} alignItems="center">
        <Grid item>
          <Circle color={event.event_type.color} diameter={6} />
        </Grid>
        <Grid item>
          <Typography variant="h6">{event.event_type.name}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <ScheduleInfo event={event} />
      </Grid>
      <Grid item container xs={12} alignItems="flex-start" spacing={1}>
        <Grid item>
          <LocationOnIcon />
        </Grid>
        <Grid item xs>
          {event.locationName && (
            <div style={styles.address}>{event.locationName}</div>
          )}
          {event.streetAddress && (
            <div style={styles.address}>{event.streetAddress}</div>
          )}
          {event.additionalAddress && (
            <div style={styles.address}>{event.additionalAddress}</div>
          )}
          {event.locationAddress && <div>{event.locationAddress}</div>}
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          {event.allow_waitlist && (
            <Box sx={styles.chip}>
              <CheckIcon />
              {t('.waitlist', intl, __filenamespace)}
            </Box>
          )}
        </Grid>
        {event.privacy && (
          <Grid item>
            <Box sx={styles.chip}>
              <CheckIcon />
              {t('.private', intl, __filenamespace)}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function TeamsSidebar({
  event,
  revenue,
  intl,
  staffIds,
  resources,
  resourcesExpanded,
  staffExpanded,
  isClient,
  descriptionExpanded,
}) {
  if (smallScreen())
    return (
      <Box sx={styles.root}>
        <Grid container justify="flex-start" spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ margin: '0 -16px' }}>
              <EventNav event={event} isClient={isClient} />
            </Box>
          </Grid>
          <TeamTitle
            team={event}
            isClient={isClient}
            hasRegisterBtn={isClient}
          />
        </Grid>
      </Box>
    );
  return (
    <Box sx={styles.root}>
      <Grid container justify="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ margin: '0 -16px' }}>
            <EventNav event={event} isClient={isClient} />
          </Box>
        </Grid>

        <TeamTitle team={event} isClient={isClient} hasRegisterBtn={isClient} />

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <TeamScheduleInfo
          intl={intl}
          event={event}
          revenue={revenue}
          isClient={isClient}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className="staff-list-container">
            <StaffList
              expanded={staffExpanded}
              intl={intl}
              staffIds={staffIds}
              isTeamEvent
            />
          </Box>
        </Grid>
        {isClient && (
          <>
            <Grid item xs={12}>
              <Box className="resource-list-container">
                <DescriptionExpander
                  event={event}
                  intl={intl}
                  expanded={descriptionExpanded}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {event.image?.url && (
              <Grid item container xs={12}>
                <img src={event.image?.url} alt="" width={200} height={100} />
              </Grid>
            )}
          </>
        )}
        {!isClient && (
          <Grid item xs={12}>
            <Box className="resource-list-container">
              <Resources
                expanded={resourcesExpanded}
                teamResources={resources}
                intl={intl}
                isTeamEvent
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

function EventSidebar({ event, revenue, isClient, intl }) {
  return (
    <Box sx={styles.root}>
      <Grid container justify="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ margin: '0 -16px' }}>
            <EventNav event={event} />
          </Box>
        </Grid>
        <EventTitle intl={intl} event={event} />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <EventScheduleInfo
          intl={intl}
          event={event}
          revenue={revenue}
          isClient={isClient}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
}

function Sidebar({
  event,
  revenue,
  intl,
  staffIds,
  resources,
  resourcesExpanded,
  staffExpanded,
  descriptionExpanded,
  isClient,
  isTeamEvent,
}) {
  if (typeof event === 'undefined') {
    return <Box />;
  }

  return isTeamEvent ? (
    <TeamsSidebar
      event={event}
      revenue={revenue}
      intl={intl}
      staffIds={staffIds}
      resources={resources}
      resourcesExpanded={resourcesExpanded}
      staffExpanded={staffExpanded}
      isClient={isClient}
      descriptionExpanded={descriptionExpanded}
    />
  ) : (
    <EventSidebar
      event={event}
      revenue={revenue}
      intl={intl}
      staffIds={staffIds}
      resources={resources}
      resourcesExpanded={resourcesExpanded}
      staffExpanded={staffExpanded}
      isClient={isClient}
    />
  );
}

export default injectIntl(Sidebar);
