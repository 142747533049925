import CapacitorPlugin from 'shared/native/helpers/CapacitorPlugin';
import { Filesystem } from '@capacitor/filesystem';

// Wrapper for https://capacitorjs.com/docs/apis/filesystem
class File extends CapacitorPlugin {
  pluginName = 'Filesystem';

  /**
   * Retrieves a blob from a local file URL
   * @param  {String} fileURL
   * @return {Blob}
   */
  async blobFromFileURL(fileURL) {
    await this.ready();
    return Filesystem.readFile({ path: fileURL });
  }

  /**
   * Converts a blob into a Base64 data URL
   * @param  {Blob}    blob
   * @return {String}  Data URL
   */
  // eslint-disable-next-line class-methods-use-this
  async convertToDataURL(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const dataURL = reader.result; // result is a Data URL

        const valid =
          typeof dataURL === 'string' &&
          dataURL.startsWith('data:') &&
          dataURL.includes(',');

        if (!valid) {
          reject(new Error('Not a valid data URL'));
          return;
        }

        resolve(dataURL);
      };

      reader.onerror = reject;

      reader.readAsDataURL(blob);
    });
  }
}

export default new File();
