import { List } from 'immutable';
import EventType from 'event_mgmt/shared/records/EventType.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSEventTypeListActions from 'point_of_sale/actions/POSEventTypeListActions';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class POSEventTypeListStore extends UpperHandStore {
  constructor() {
    super();

    this.eventTypes = List();
    this.isLoading = false;

    // TODO: Watch for the creation of new event types and the updating of existing ones.
    this.bindListeners({
      list: [
        POSEventTypeListActions.LIST,
        POSProductListActions.MEMBERSHIP_SELECTED,
        POSProductListActions.CREDIT_PASS_SELECTED,
      ],
      loadEventTypes: [
        POSCartActions.ITEM_EDIT_SELECTED,
        POSCartActions.ITEM_VIEW_SELECTED,
      ],
      listSuccess: POSEventTypeListActions.LIST_SUCCESS,
      listError: POSEventTypeListActions.LIST_ERROR,
    });
  }

  loadEventTypes(orderItem) {
    if (orderItem.isCreditPassItem()) {
      this.list();
    }
  }

  list(options) {
    const { page = 1 } = options || {};
    this.isLoading = true;

    return uhApiClient.get({
      url: 'event_types',
      data: { page, per_page: 50 },
      success: POSEventTypeListActions.listSuccess,
      error: POSEventTypeListActions.listError,
    });
  }

  listSuccess({
    event_types: eventTypes,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    const partitionedEventTypes = this.sorted(
      List(eventTypes.map(object => new EventType(object)))
    );
    this.eventTypes =
      page === 1
        ? partitionedEventTypes
        : this.eventTypes.concat(partitionedEventTypes);

    if (eventTypes.length === perPage && totalCount > this.eventTypes.size) {
      this.list({ page: page + 1 });
    } else {
      this.isLoading = false;
    }
  }

  listError() {
    this.isLoading = false;
    this.notifyError('error while listing event types', arguments);
  }

  sorted(eventTypes) {
    return eventTypes.sortBy(t => t.name);
  }
}

export default alt.createStore(POSEventTypeListStore, 'POSEventTypeListStore');
