class ScheduleDrawerActions {
  constructor() {
    this.generateActions(
      'addResource',
      'addStaff',
      'cancelUpdating',
      'closeDrawer',
      'confirmScheduleUpdate',
      'createSchedule',
      'createScheduleError',
      'createScheduleSuccess',
      'editSchedule',
      'filterResources',
      'filterStaff',
      'listLocationError',
      'listLocationSuccess',
      'listResourceError',
      'listResourceSuccess',
      'listStaffError',
      'listStaffSuccess',
      'mounted',
      'openDrawer',
      'postUpdatedSchedule',
      'postUpdatedScheduleSuccess',
      'postUpdatedScheduleError',
      'removeResource',
      'removeStaff',
      'setFrequency',
      'setInterval',
      'setLocation',
      'setNonRepeatedDayTimes',
      'setRepeatDuration',
      'setRepeatMode',
      'setRepeatingWeekdays',
      'setStartDate',
      'setStopsByDate',
      'updateNote',
      'updateRepeatStatus',
      'updateSchedule',
      'updateSearchText'
    );
  }
}

export default alt.createActions(ScheduleDrawerActions);
