const SpecificEventsActions = alt.generateActions(
  'list',
  'listError',
  'listSuccess',

  'addSpecificEvent',
  'removeSpecificEvent'
);

export default SpecificEventsActions;
