import * as React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import RetailCategory from 'shared/records/RetailCategory.js';
import RetailCategoryDiscount from 'memberships/components/_DiscountBenefits/_RetailCategoryDiscount.jsx';
import mapDiscounts from 'memberships/components/_DiscountBenefits/_mapDiscounts.js';
import { t } from 'shared/utils/LocaleUtils.js';

class RetailCategoryDiscountBenefits extends React.Component {
  retailCategoriesBenefits() {
    const { membership, retailCategories, intl } = this.props;

    return mapDiscounts(
      membership.membership_retail_discounts,
      retailDiscount => {
        if (retailDiscount.all_retail_products) {
          const all = new RetailCategory({
            id: 'retail-category-all',
            name: t('.all_retail_products', intl, __filenamespace),
          });
          return Set([all]);
        }
        if (retailCategories.isEmpty()) {
          return Set([]);
        }
        return retailDiscount.retail_category_ids.map(id =>
          retailCategories.find(c => c.id === id)
        );
      }
    );
  }

  render() {
    const retailBenefitsList = this.retailCategoriesBenefits();
    const { itemStyle, textStyle, containerStyles } = this.props;
    if (retailBenefitsList.isEmpty()) {
      return null;
    }
    return (
      <div style={containerStyles}>
        {retailBenefitsList
          .map((categories, discount) => (
            <RetailCategoryDiscount
              key={discount.value}
              discount={discount}
              retailCategories={categories}
              style={itemStyle}
              textStyle={textStyle}
            />
          ))
          .toList()}
      </div>
    );
  }
}

RetailCategoryDiscountBenefits.propTypes = {
  membership: PropTypes.object.isRequired,
  retailCategories: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(RetailCategoryDiscountBenefits);
