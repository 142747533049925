export default alt.generateActions(
  'clientsLoaded',
  'invite',
  'inviteSearchStringUpdated',
  'inviteSuccess',
  'listClients',
  'queriedClientsLoaded',
  'remindClicked',
  'remindSuccess',
  'selectPage',
  'searchStringUpdated',
  'toggleExpanded',
  'transactionsClicked',
  'updateStore',
  'removeRequested',
  'removeConfirmed',
  'removeAborted',
  'removeSuccess',
  'removeError',
  'filterDrawerOpen',
  'filterDrawerClose',
  'onFilterChange',
  'downloadList',
  'downloadListSuccess',
  'downloadListError',
  'error',
  'listTiersSuccess',
  'listTiersError'
);
