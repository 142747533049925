import * as React from 'react';
import Leaflet from 'leaflet';
import { Marker } from 'react-leaflet';
import { merge } from 'shared/utils/ObjectUtils.jsx';

function buildMarkerStyle(props) {
  const color = props.color || '#583470';

  return `
    background-color: ${color};
    width: 3rem;
    height: 3rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid #333;
    box-shadow: 5px 1px 5px 1px rgba(51, 51, 51, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  `;
}

function buildBullet() {
  const style = `
    color: #fff;
    font-size: 2em;
    transform: rotate(-45deg);
  `;
  const content = '&#9679;';
  return `<div style="${style}">${content}</div>`;
}

function buildStar() {
  const style = `
    color: #fff;
    font-size: 1.4em;
    transform: rotate(-45deg);
  `;
  const content = '&#x2605;';
  return `<div style="${style}">${content}</div>`;
}

function buildIcon(props) {
  switch (props.icon) {
    case 'star':
      return buildStar(props);
    case 'bullet':
      return buildBullet(props);
    default:
      return '';
  }
}

export function buildLeafletIcon(props) {
  const markerStyle = buildMarkerStyle(props);
  const icon = buildIcon(props);
  const html = `<div style="${markerStyle}">${icon}</div>`;

  return new Leaflet.DivIcon({
    className: 'custom-map-marker',
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html,
  });
}

export default function CustomMapMarker(props = {}) {
  const icon = buildLeafletIcon({ color: props.color, icon: props.icon });
  const newProps = merge(props, { icon });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Marker {...newProps}>{props.children}</Marker>;
}
