import React from 'react';
import { Button, Dropdown, Grid, TextField } from '@upperhand/playmaker';
import { Box } from '@mui/material';
import { t } from 'shared/utils/LocaleUtils';
import { smallScreen } from 'shared/utils/DOMUtils';
import {
  FILTER_RESOURCE_ALL,
  FILTER_STAFF_ALL,
} from '../completeScheduleModal/Store';

const styles = {
  filtersContainer: {
    position: 'relative',
    width: '100%',
    padding: '8px 0',
  },
  dropdownsContainer: {
    width: '100%',
    padding: '0 8px',
  },
  dropdownsMobileContainer: {
    padding: '50px 8px 8px 8px',
  },
  toggleViewButtonPadding: {
    paddingRight: '142px',
  },

  scheduleToggleBtnContainer: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
};
const getFilterContainerStyles = canShowToggleViewModeButton =>
  smallScreen()
    ? {
        ...styles.dropdownsContainer,
        ...(canShowToggleViewModeButton && styles.dropdownsMobileContainer),
      }
    : {
        ...styles.dropdownsContainer,
        ...(canShowToggleViewModeButton && styles.toggleViewButtonPadding),
      };

function EventSessionHeader({
  search,
  staffItems,
  resourceItems,
  resourceFilter,
  searchUpdated,
  staffFilter,
  staffFilterUpdated,
  resourceFilterUpdated,
  canShowToggleViewModeButton,
  onToggleViewMode,
  intl,
}) {
  return (
    <Box sx={styles.filtersContainer}>
      <Box sx={getFilterContainerStyles(canShowToggleViewModeButton)}>
        <Grid
          alignItems="center"
          container
          justify="space-between"
          spacing={1}
          xs={12}
        >
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              size="2x"
              icon="search"
              onChange={e => searchUpdated(e.target.value)}
              outline={false}
              placeholder={t('.search', intl, __filenamespace)}
              rounded
              value={search}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              id="staff"
              name="staff"
              fullWidth
              items={[{ label: 'Staff: All', value: FILTER_STAFF_ALL }].concat(
                staffItems
              )}
              onChange={e => staffFilterUpdated(e.target.value)}
              outline={false}
              rounded
              value={staffFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              id="resource"
              name="resource"
              rounded
              fullWidth
              outline={false}
              items={[
                { label: 'Resource: All', value: FILTER_RESOURCE_ALL },
              ].concat(resourceItems)}
              value={resourceFilter}
              onChange={e => resourceFilterUpdated(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.scheduleToggleBtnContainer}>
        {canShowToggleViewModeButton && (
          <Button
            onClick={onToggleViewMode}
            type="tertiary"
            size="2x"
            fullWidth
            rounded
          >
            {t('.view_schedule', intl, __filenamespace)}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default EventSessionHeader;
