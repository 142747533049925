/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { Chip } from '@upperhand/playmaker';

import { snakeToTitleCase } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';

function ActiveFilters({
  appliedFilters = Map(),
  onRemoveFilter = () => null,
  onClearFilters = () => null,
}) {
  const intl = useIntl();
  const filters = appliedFilters.toJS();
  const filtersKeys = Object.keys(filters);
  const filtersValues = Object.values(filters);

  const getChipLabel = (filter, index) => {
    if (Boolean(filter?.from) && Boolean(filter?.to)) {
      return `${moment(filtersValues[index].from).format(
        'MM/DD/YYYY'
      )} - ${moment(filtersValues[index].to).format('MM/DD/YYYY')}`;
    }

    return snakeToTitleCase(filtersValues[index]);
  };

  if (appliedFilters.size === 0) return null;

  return (
    <div className="marketing-active-filters">
      <div className="marketing-active-filters__filters">
        {filtersKeys.map((filter, index) => {
          const filterValue = filtersValues[index];

          return (
            <Chip
              key={filter}
              label={getChipLabel(filterValue, index)}
              onDelete={() => onRemoveFilter(filter)}
              classes={{ root: 'contacts__active-filters-chip' }}
            />
          );
        })}
      </div>
      <div
        role="presentation"
        className="marketing-active-filters__clear"
        onClick={onClearFilters}
      >
        {t('.clear', intl, __filenamespace)}
      </div>
    </div>
  );
}

ActiveFilters.propTypes = {
  appliedFilters: PropTypes.instanceOf(Map),
  onRemoveFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
};

export default memo(ActiveFilters);
