import moment from 'moment-timezone';
import { Set, Record, Map } from 'immutable';
import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class MembershipSubscriptionPackage extends Record({
  id: null,
  buyer_id: null,
  customer_user_ids: Set(),
  membership_id: null,
  embed_card_ids: Map(),
  membership_active_at: 0,
  subscribable_base_price: 0,
  membership_color: null,
  subscribable_commitment_months: null,
  subscribable_interval_months: null,
  subscribable_join_fee: 0,
  subscribable_name: '',
  membership_subscriptions: Set(),
  membership_tier_id: null,
  price_multiplier: 1,
}) {
  constructor(object = {}, _options = {}) {
    super(
      merge(object, {
        customer_user_ids: Set(object.customer_user_ids),
        membership_subscriptions: Set(
          (object.membership_subscriptions || []).map(
            ms => new MembershipSubscription(ms)
          )
        ),
      })
    );
  }

  addCustomerUser(customerUserId) {
    return this.update('customer_user_ids', (v = Set()) =>
      v.add(customerUserId)
    );
  }

  removeCustomerUser(customerUserId) {
    return this.update('customer_user_ids', (v = Set()) =>
      v.delete(customerUserId)
    );
  }

  hasCustomerUser(customerUserOrCustomerUserId) {
    const customerUserId =
      customerUserOrCustomerUserId.id || customerUserOrCustomerUserId;

    return this.customer_user_ids.has(customerUserId);
  }

  isPreSale() {
    return (
      this.membership_active_at && moment(this.membership_active_at) > moment()
    );
  }

  toServer() {
    const payload = this.toJS();
    payload.membership_subscriptions_attributes =
      this.membership_subscriptions.toJS();
    delete payload.membership_subscriptions;
    return payload;
  }
}
export default MembershipSubscriptionPackage;
