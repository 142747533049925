import * as React from 'react';
import { injectIntl } from 'react-intl';

import SelectedTeamType from 'shared/components/SelectedTeamType.jsx';
import TeamTypeInlineEditor from 'shared/components/TeamTypeInlineEditor.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { uhFormElement } from 'shared/styles/uhStyles.jsx';

const TeamTypeSelector = injectIntl(
  ({
    accountingCode,
    teamTypes,
    onCreateOrUpdateSuccess,
    onTeamTypeSelect,
    onTeamTypeRemoved,
    intl,
  }) => (
    <>
      <TeamTypeInlineEditor
        teamTypes={teamTypes}
        errorText={accountingCode.errors.getErrors('team_type_ids', intl)}
        label={t('.assign_team_types', intl, __filenamespace)}
        floatingLabelFixed
        floatingLabelStyle={uhFormElement.floatingLabel}
        hintText={t('.choose_team_types', intl, __filenamespace)}
        onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
        onTeamTypeSelect={onTeamTypeSelect}
        fullWidth
      />

      {accountingCode.team_types.map(et => (
        <SelectedTeamType
          teamType={et}
          onRemove={onTeamTypeRemoved}
          style={{ fontWeight: 'bold' }}
          key={et.id}
        />
      ))}
    </>
  )
);

export default TeamTypeSelector;
