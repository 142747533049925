import * as React from 'react';

import Header from 'retail/components/OrderHistory/Header.jsx';
import Content from 'retail/components/OrderHistory/Content.jsx';
import OrderHistoryActions from 'retail/actions/OrderHistoryActions';

const styles = {
  container: {
    height: '100%',
    marginTop: 16,
  },
};

class TabOrders extends React.Component {
  componentDidMount() {
    OrderHistoryActions.purchaseOrderList();
  }

  render() {
    return (
      <div style={styles.container}>
        <Header />
        <Content />
      </div>
    );
  }
}

export default TabOrders;
