import * as React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';

import StatisticItem from './StatisticItem.jsx';

function Statistics({ intl, statistics }) {
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      <Grid item xs>
        <StatisticItem
          title={t('.transaction_count', intl, __filenamespace)}
          tooltip={t('.transaction_count_tooltip', intl, __filenamespace)}
          value={statistics.get('transaction_count') || 0}
        />
      </Grid>
      <Grid item xs>
        <StatisticItem
          title={t('.buyers_count', intl, __filenamespace)}
          tooltip={t('.buyers_count_tooltip', intl, __filenamespace)}
          value={statistics.get('buyers_count') || 0}
        />
      </Grid>
    </Grid>
  );
}

Statistics.propTypes = {
  statistics: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Statistics);
