import { Map } from 'immutable';

import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import MembershipSubscriptionActions from 'sources/MembershipSubscriptionActions';

class MembershipSubscriptionDataStore extends UpperHandStore {
  constructor() {
    super();

    this.membershipSubscriptions = Map();

    this.bindListeners({
      listSuccess: MembershipSubscriptionActions.listSuccess,
      listError: MembershipSubscriptionActions.listError,
    });
  }

  listSuccess({ membership_subscriptions: membershipSubscriptions }) {
    const fetchedMembershipSubscriptions = membershipSubscriptions
      .groupBy(m => m.id)
      .map(m => new MembershipSubscription(m.first()));

    this.membershipSubscriptions = this.membershipSubscriptions.merge(
      fetchedMembershipSubscriptions
    );
  }

  listError(...args) {
    this.notifyError('error fetching membership subscriptions', args);
  }
}

export default alt.createStore(
  MembershipSubscriptionDataStore,
  'MembershipSubscriptionDataStore'
);
