import * as React from 'react';
import { List } from 'immutable';

import AvailabilityOverlay from 'calendar/components/views/day/_AvailabilityOverlay.jsx';
import EventCards from 'calendar/components/views/shared/_EventCards.jsx';
import FooterCell from 'calendar/components/views/day/_FooterCell.jsx';
import HeaderCell from 'calendar/components/views/day/_HeaderCell.jsx';
import NoEventsOverlay from 'calendar/components/views/shared/_NoEventsOverlay.jsx';
import TimeBar from 'calendar/components/views/shared/_TimeBar.jsx';
import TimeCells from 'calendar/components/views/shared/_TimeCells.jsx';

import StaffAvailabilityStore from 'shared/stores/StaffAvailabilityStore.jsx';

import OWNER_TYPE from 'calendar/types/OwnerType.jsx';
import * as DateUtils from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import * as CalendarUtils from 'calendar/utils/CalendarUtils.jsx';

const styles = {
  Columns: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    flex: '1',
  },
  Column: {
    position: 'relative',
    flex: '1',
    minWidth: '60px',
  },
  TimeCells: {
    paddingTop: '42px',
  },
};

const availabilityForCalendar = calendar => {
  switch (calendar.ownerType) {
    case OWNER_TYPE.STAFF: {
      return StaffAvailabilityStore.getState().findByOwnerId(calendar.id);
    }
    case OWNER_TYPE.RESOURCE:
    default:
      return undefined;
  }
};

class Column extends React.Component {
  state = {
    smallCellMode: false,
  };

  componentDidMount() {
    this.updateCellMode();
    window.addEventListener('resize', this.updateCellMode);
  }

  componentDidUpdate() {
    this.updateCellMode();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCellMode);
  }

  updateCellMode = () => {
    const { smallCellMode } = this.state;
    if (this.el) {
      const isSmallCell = this.el.clientWidth < 140;
      if (smallCellMode !== isSmallCell) {
        this.setState({ smallCellMode: isSmallCell });
      }
    }
  };

  render() {
    const { smallCellMode } = this.state;
    const {
      availableStaffOnly,
      calendar,
      date,
      owner,
      allStaffMap,
      eventTimes,
      clientsMap,
      eventsMap,
      membershipsMap,
      resourcesMap,
      showAvailability,
      showPaid,
      showUnpaid,
      staffIds,
      showAllStaff,
      sessions,
    } = this.props;

    const filteredEventTimes = CalendarUtils.filteredEventTimes(
      eventTimes,
      showPaid,
      showUnpaid,
      showAllStaff,
      staffIds
    );
    const calendarEventTimes = CalendarUtils.eventTimesForCalendar(
      filteredEventTimes,
      calendar
    );
    const availability = availabilityForCalendar(calendar);
    let availabilityDaytimes = List();
    if (availability) {
      availabilityDaytimes = availability.daytimesFor(date) || List();
    }

    if (
      calendar.ownerType === OWNER_TYPE.STAFF &&
      availableStaffOnly &&
      List(availabilityDaytimes).size === 0
    ) {
      return null;
    }

    return (
      <div
        style={styles.Column}
        ref={c => {
          this.el = c;
        }}
      >
        <HeaderCell
          smallCellMode={smallCellMode}
          calendar={calendar}
          owner={owner}
          onUpdateCellMode={this.updateCellMode}
        />
        {!smallCellMode && (
          <FooterCell
            calendar={calendar}
            eventTimes={calendarEventTimes}
            owner={owner}
          />
        )}

        <div style={styles.TimeCells}>
          <TimeCells date={date} calendar={calendar} />
        </div>
        {!calendarEventTimes.size && <NoEventsOverlay />}
        {showAvailability &&
          availabilityDaytimes.map(daytime => {
            const timeRange = DateUtils.daytimeToTimeRange(daytime, date);
            return (
              <AvailabilityOverlay
                key={timeRange.toString()}
                timeRange={timeRange}
              />
            );
          })}
        <EventCards
          allStaffMap={allStaffMap}
          calendarEventTimes={calendarEventTimes}
          clientsMap={clientsMap}
          eventsMap={eventsMap}
          membershipsMap={membershipsMap}
          resourcesMap={resourcesMap}
          sessions={sessions}
        />
      </div>
    );
  }
}

function Columns({
  allStaffMap,
  availableStaffOnly,
  calendars,
  clientsMap,
  date,
  eventsMap,
  eventTimes,
  membershipsMap,
  resourcesMap,
  showAvailability,
  showPaid,
  showUnpaid,
  staffIds,
  showAllStaff,
  sessions,
}) {
  return (
    <div style={styles.Columns}>
      <TimeBar />
      {calendars.map(calendar => {
        let owner;

        switch (calendar.ownerType) {
          case OWNER_TYPE.STAFF: {
            owner = allStaffMap.get(calendar.id);
            break;
          }
          case OWNER_TYPE.RESOURCE: {
            owner = resourcesMap.get(calendar.id);
            break;
          }
          default:
            break;
        }

        return (
          owner && (
            <Column
              key={`${calendar.ownerType + calendar.id}`}
              allStaffMap={allStaffMap}
              availableStaffOnly={availableStaffOnly}
              calendar={calendar}
              clientsMap={clientsMap}
              date={date}
              eventsMap={eventsMap}
              eventTimes={eventTimes}
              membershipsMap={membershipsMap}
              owner={owner}
              resourcesMap={resourcesMap}
              showAvailability={showAvailability}
              showPaid={showPaid}
              showUnpaid={showUnpaid}
              staffIds={staffIds}
              showAllStaff={showAllStaff}
              sessions={sessions}
            />
          )
        );
      })}
    </div>
  );
}

export default Columns;
