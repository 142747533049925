class LocationActions {
  constructor() {
    this.generateActions(
      'fetchSuccess',
      'fetchError',
      'listSuccess',
      'listError'
    );
  }
}

export default alt.createActions(LocationActions);
