/**
 * used to determine type of Payment service response
 * @type {{}}
 */
const PaymentServiceResponse = {
  PAYMENT_SUCCESS: 'paymentSuccess',
  PAYMENT_ERROR: 'paymentError',
  TOKEN_SUCCESS: 'tokenSuccess',
  TOKEN_ERROR: 'tokenError',
};

export default PaymentServiceResponse;
