import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function EventAthletes({ athletes, style }) {
  if (athletes.size === 0) {
    return null;
  }

  return (
    <div style={style}>
      <SectionHeader style={{ marginBottom: 20 }}>
        <FormattedMessage id={messageId('.event_athletes', __filenamespace)} />
      </SectionHeader>
      {athletes.map(athlete => (
        <AvatarWithName
          key={athlete.id}
          user={athlete}
          style={{ marginBottom: 8 }}
        />
      ))}
    </div>
  );
}

export default EventAthletes;
