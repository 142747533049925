import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function AddMoreEventCard({ style, mainFontSize, descriptionFontSize }) {
  return (
    <Paper
      style={merge(
        {
          width: '30rem',
          margin: '1rem',
          padding: '1.5rem',
          border: `3px solid ${uhColors.activeBlue}`,
          backgroundColor: 'var(--color-bg-gray)',
        },
        style
      )}
    >
      <ReactRouterLink
        to={customerScopedRoute('/events')}
        style={{ textDecoration: 'none', width: '100%' }}
      >
        <FlexBoxJustifyCenter
          style={{
            color: uhColors.textGrey,
            height: '100%',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: mainFontSize || 22,
              marginBottom: 5,
            }}
          >
            <AddIcon
              sx={{ verticalAlign: 'middle', color: uhColors.activeBlue }}
            />
            <span style={{ verticalAlign: 'middle' }}>
              <FormattedMessage id={messageId('.add_event', __filenamespace)} />
            </span>
          </div>
          <div
            style={{
              margin: '0 auto',
              fontSize: descriptionFontSize || 16,
            }}
          >
            <FormattedMessage
              id={messageId('.add_event_description', __filenamespace)}
            />
          </div>
        </FlexBoxJustifyCenter>
      </ReactRouterLink>
    </Paper>
  );
}

export default AddMoreEventCard;
