import { List, Record, Set } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import OptionType from 'shared/records/OptionType.js';
import ProductImage from 'shared/records/ProductImage.js';
import RetailCategory from 'shared/records/RetailCategory.js';
import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';

const READONLY_FIELDS = [
  'featured_image',
  'max_price',
  'min_price',
  'max_cost',
  'min_cost',
  'errors',
  'option_type_ids',
  'variant_ids',
];

class Product extends Record({
  id: null,
  name: '',
  description: '',
  base_price: 0,
  base_cost: 0,
  featured_image: new ProductImage(),
  retail_vendor_id: null,
  retail_category: new RetailCategory(),
  retail_category_id: null,
  max_price: 0,
  min_price: 0,
  max_cost: 0,
  min_cost: 0,
  errors: new FieldErrors(),
  option_type_ids: Set(),
  variant_ids: Set(),
  published: false,
  swing_card: false,

  // Optional exposures.
  option_types: List(),
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        featured_image: new ProductImage(obj.featured_image || {}),
        option_type_ids: Set(obj.option_type_ids),
        variant_ids: Set(obj.variant_ids),
        retail_category: new RetailCategory(obj.retail_category || {}),
        option_types: List(
          (obj.option_types || []).map(t => new OptionType(t))
        ),
      })
    );
  }

  togglePublished() {
    return this.set('published', !this.published);
  }

  addOptionType() {
    return this.update('option_types', (ls = List()) =>
      ls.push(new OptionType())
    );
  }

  removeOptionType(index) {
    return this.deleteIn(['option_types', index]);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  validate() {
    let errors = new FieldErrors();

    ['name', 'base_price', 'base_cost'].forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });
    const name = this.retail_category.name
      ? this.retail_category.name.trim()
      : this.retail_category.name;

    if (name === '') {
      errors = errors.add(
        'retail_category',
        'retail.RetailCategoryEditing.required'
      );
    }

    if (this.swing_card) {
      const regex = /^[1-9][0-9]*$/;
      const emptyOption = { option_values: List() };
      const embedOption = this.option_types.first();
      const { option_values: optionValues } = embedOption || emptyOption;
      const isValid =
        optionValues.size > 0
          ? optionValues.every(option => regex.test(option.value))
          : false;

      if (!isValid) {
        errors = errors.add(
          'option_types',
          'retail.RetailCategoryEditing.swing_option_required'
        );
      }
    }
    return this.merge({ errors });
  }

  toServer() {
    const serverValue = this.toJS();

    READONLY_FIELDS.forEach(field => delete serverValue[field]);
    serverValue.option_types = serverValue.option_types.filter(
      t => t.option_values.length > 0
    );
    serverValue.retail_category_id = this.retail_category.id;
    return serverValue;
  }
}

export default Product;
