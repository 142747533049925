import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionHeader from 'event_mgmt/display/components/_SectionHeader.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function EventDescription({ event, style }) {
  return (
    <div style={style}>
      <SectionHeader style={{ marginBottom: 20 }}>
        <FormattedMessage id={messageId('.details', __filenamespace)} />
      </SectionHeader>
      <div
        className="event-description"
        dangerouslySetInnerHTML={{ __html: event.getHtmlDescription() }}
      />
    </div>
  );
}

export default EventDescription;
