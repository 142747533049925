/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { logError } from 'ErrorLogger.js';
import { Capacitor } from '@capacitor/core';

/**
 * Class for interacting with native code
 */
class CapacitorPlugin {
  /**
   * Override with the name of the plugin as defined in the Capacitor plugin registry
   */
  pluginName = null;

  constructor() {
    if (this.isInstalled) {
      document.addEventListener('deviceready', this.onDeviceReady.bind(this), {
        once: true,
      });
      document.addEventListener('pause', this.onPause.bind(this));
      document.addEventListener('resume', this.onResume.bind(this));
    }
  }

  /**
   * Whether the plugin is available or not.
   * @return {Boolean}
   */
  get isInstalled() {
    return Capacitor.isPluginAvailable(this.pluginName);
  }

  /**
   * Whether the plugin is running in a native environment or not.
   * @return {Boolean}
   */
  get isNative() {
    return Capacitor.isNativePlatform();
  }

  /**
   * Whether the plugin is available or not.
   * @return {Promise}
   */
  async isAvailable() {
    try {
      await this.ready();
      return true;
    } catch (e) {
      this.notifyError(e);
      return false;
    }
  }

  /**
   * Wait for the plugin to be installed and ready. Throws an error if the plugin is not installed.
   * @return {Promise}
   */
  async ready() {
    if (!this.isInstalled) {
      throw new Error(`Plugin ${this.pluginName} not installed`);
    }
    return this._waitForDeviceReady();
  }

  /**
   * Hook for when Capacitor is ready
   */
  onDeviceReady() {
    /* Intentially empty. Intended for subclasses to optionally override */
  }

  /**
   * Hook for when app is moved from foreground to background
   */
  onPause() {
    /* Intentially empty. Intended for subclasses to optionally override */
  }

  /**
   * Hook for when app is moved from background to foreground
   */
  onResume() {
    /* Intentially empty. Intended for subclasses to optionally override */
  }

  notifyError(error) {
    const errorHandler = logError(`${this.constructor.name}.NativePluginError`);
    if (error && error.message) {
      errorHandler(error);
    } else if (['string', 'number', 'boolean'].includes(typeof error)) {
      errorHandler(new Error(error));
    } else {
      errorHandler('Unknown error');
    }
  }

  async _waitForDeviceReady() {
    if (!this._waitForReadyPromise) {
      this._waitForReadyPromise = new Promise(resolve => {
        if (this.isNative) {
          window.document.addEventListener('deviceready', resolve, {
            once: true,
          });
        } else {
          resolve();
        }
      });
    }
    return this._waitForReadyPromise;
  }
}

export default CapacitorPlugin;
