import { List, Set } from 'immutable';
import CardReader from 'records/CardReader.js';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { subscribe } from 'cable.js';
import { onSuccess } from 'sources/handlers';
import DataStoreActions from './CardReaderActions';

const parserTo = { type: CardReader, paginationKey: 'card_readers' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error,
  });
};

export const put = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify({ attributes: params }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error,
  });
};

export const sync = ({ customerIds, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/sync`,
    data: JSON.stringify({ customer_ids: customerIds }),
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.syncSuccess, success])
    ),
    error,
  });
};

/**
 * Fetches the CardReader, pinging it in the process to get the most up-to-date status information.
 *
 * id: the id of the Card Reader to ping
 */
export const ping = ({ id, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/${id}/ping`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess].concat(success || []))
    ),
    error,
  });
};

export const groupPing = ({ cardReaderIds, success, error }) => {
  uhApiClient.post({
    url: `${parserTo.paginationKey}/ping`,
    data: JSON.stringify({ card_reader_ids: cardReaderIds }),
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.syncSuccess].concat(success || []))
    ),
    error,
  });
};

// By storing previous success functions, we effectively add onto
// the success functions by calling subscribeToChanges anew.
// By using a Set, we prevent duplicate actions from building up and running twice.
let subscribeSuccessFunctions = Set([DataStoreActions.fetchSuccess]);

export const subscribeToChanges = ({ customerIds, success }) => {
  if (success) {
    subscribeSuccessFunctions = subscribeSuccessFunctions.add(success);
  }

  customerIds.map(customerId =>
    subscribe(
      {
        channel: 'CardReaderChannel',
        customer_id: customerId,
      },
      onSuccess(
        data => parser.parseSingle(data),
        subscribeSuccessFunctions.toList()
      )
    )
  );
};
