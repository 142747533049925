import { List } from 'immutable';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSEventStaffActions from 'point_of_sale/actions/POSEventStaffActions.js';
import POSEventStore from 'point_of_sale/stores/POSEventStore.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import Staff from 'shared/records/Staff.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { StaffSource } from 'sources';

class POSEventStaffStore extends UpperHandStore {
  constructor() {
    super();

    this.staff = List();

    this.bindListeners({
      loadEventStaff: [
        POSProductListActions.EVENT_SELECTED,
        POSEventActions.FETCH_EVENT_SUCCESS,
      ],

      handleListSuccess: POSEventStaffActions.listSuccess,
      handleListError: POSEventStaffActions.listError,
      handleResetStaff: POSEventStaffActions.HANDLE_RESET_STAFF,
    });
  }

  loadEventStaff() {
    this.waitFor(POSEventStore);
    this.handleResetStaff();

    const { event } = POSEventStore.getState();

    if (event && event.allow_staff_selection) {
      this.listStaff(event.id);
    }
  }

  handleResetStaff() {
    this.staff = List();
  }

  // eslint-disable-next-line class-methods-use-this
  listStaff(eventId) {
    const params = {
      access_revoked: false,
      per_page: 100,
      event_ids: [eventId],
    };

    StaffSource.list({
      params,
      success: POSEventStaffActions.listSuccess,
      error: POSEventStaffActions.listError,
    });
  }

  handleListSuccess(data) {
    this.staff = List(data.staff.map(s => new Staff(s)));
  }

  handleListError(...args) {
    this.notifyError('error retrieving event staff', args);
  }
}

export default alt.createStore(POSEventStaffStore, 'POSEventStaffStore');
