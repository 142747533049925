import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Box } from '@mui/material';
import { TextField } from '@upperhand/playmaker';

import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import RestrictionsSectionDobRows from 'shared/components/RestrictionsSectionDobRows.jsx';

import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';

const styles = {
  subheader: {
    fontWeight: 'bold',
    padding: '15px 0px 10px 0px',
    lineHeight: '15px',
  },
  ageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  ageDivider: {
    padding: '0 8px 14px',
  },
  eventSizeSubheader: {
    fontWeight: 'bold',
    padding: '15px 0px 10px 0px',
    lineHeight: '15px',
    marginBottom: '8px',
    paddingBottom: '',
  },
};

function RosterInfo({ participants, handleInputChange, fieldErrors }) {
  return (
    <Box>
      <Typography sx={styles.eventSizeSubheader}>
        <FormattedMessage id={messageId('.roster_size', __filenamespace)} />
      </Typography>
      <TextField
        classes={{
          root: 'teams-editing__text-field',
        }}
        min={0}
        name="roster_size"
        type="number"
        value={participants.roster_size || ''}
        onChange={handleInputChange}
        errorText={fieldErrors.get('roster_size', []).join(',')}
      />
    </Box>
  );
}

function AgeRows({ participants, handleMinChange, handleMaxChange, intl }) {
  const hasDobRestriction = participants?.date_of_birth?.hasDobRestriction();
  return (
    <Box>
      <Typography sx={styles.eventSizeSubheader}>
        <FormattedMessage id={messageId('.age', __filenamespace)} />
      </Typography>
      <Box sx={styles.ageContainer}>
        <TextField
          classes={{
            root: 'teams-editing__text-field',
          }}
          label={t('.min_age', intl, __filenamespace)}
          min={0}
          name="min_age"
          type="number"
          value={participants.min_age || ''}
          disabled={hasDobRestriction}
          onChange={handleMinChange}
        />

        <Box sx={styles.ageDivider}>&mdash;</Box>
        <TextField
          classes={{
            root: 'teams-editing__text-field',
          }}
          label={t('.max_age', intl, __filenamespace)}
          min={participants.min_age ? participants.min_age : 0}
          name="max_age"
          type="number"
          value={participants.max_age || ''}
          disabled={hasDobRestriction}
          onChange={handleMaxChange}
        />
      </Box>
    </Box>
  );
}

function GenderRows({ intl, participants, handleInputChange }) {
  const genderRestriction = participants.gender_restriction;
  return (
    <Box sx={{ clear: 'both' }}>
      <Typography sx={styles.subheader}>
        <FormattedMessage id={messageId('.gender', __filenamespace)} />
      </Typography>
      <Box sx={styles.tableBorder}>
        <RadioGroup
          row
          defaultValue={genderRestriction}
          name="gender_restriction"
          onChange={handleInputChange}
          value={genderRestriction}
          sx={{ width: '200px', display: 'inline' }}
        >
          <FormControlLabel
            label={t('.coed', intl, __filenamespace)}
            value="coed"
            control={<Radio />}
          />
          <FormControlLabel
            value="male"
            label={t('.male', intl, __filenamespace)}
            control={<Radio />}
          />
          <FormControlLabel
            value="female"
            label={t('.female', intl, __filenamespace)}
            control={<Radio />}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
}

function TabParticipants(props) {
  const { participants, handleParticipantChange, fieldErrors, intl } = props;
  const handleInputChange = (e, value) => {
    let updateValue =
      value !== null && value !== undefined ? value : e.target.value;
    updateValue =
      e.target.name === 'gender_restriction'
        ? updateValue
        : parseInt(updateValue, 10);
    handleParticipantChange([e.target.name], updateValue);
  };

  const handleMinChange = (e, value) => {
    const min = value ? parseInt(value, 10) : null;
    const maxValue = Math.max(participants.max_age, min);

    handleInputChange(e, min);
    handleInputChange({ target: { value: maxValue, name: 'max_age' } });
    TeamActions.resetDobFields();
  };

  const handleMaxChange = (e, value) => {
    handleInputChange(e, value);
    TeamActions.resetDobFields();
  };
  return (
    <Box sx={{ width: SINGLE_COLUMN_WIDTH }}>
      <RosterInfo
        intl={intl}
        participants={participants}
        handleInputChange={handleInputChange}
        fieldErrors={fieldErrors}
      />
      <AgeRows
        actions={TeamActions}
        intl={intl}
        participants={participants}
        handleMinChange={handleMinChange}
        handleMaxChange={handleMaxChange}
      />
      <GenderRows
        intl={intl}
        participants={participants}
        handleInputChange={handleInputChange}
      />
      <RestrictionsSectionDobRows
        actions={TeamActions}
        participants={participants}
        intl={intl}
        handleParticipantChange={handleParticipantChange}
      />
    </Box>
  );
}

export default injectIntl(TabParticipants);
