import { Record } from 'immutable';
import { toCamelCase, toSnakeCase } from 'shared/utils/ObjectUtils.jsx';

class PreferencesCalendar extends Record({
  defaultView: -3,
}) {
  constructor(object = {}) {
    const parsedObject = toCamelCase(object);

    super(parsedObject);
  }

  toServer() {
    const payload = this.toJS();

    return toSnakeCase(payload);
  }
}

export default PreferencesCalendar;
