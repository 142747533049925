import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function TeamPrice({ team, automations }) {
  const scheduleType = SCHEDULE_TYPE.fixedSchedule;

  const collection = new PricingAutomationCollection({
    automations: automations.filter(
      atd =>
        atd &&
        atd.event_id === team.id &&
        atd.supported_event_type === scheduleType
    ),
  });

  if (collection.isEmpty()) {
    const { base_price: price } = team;
    return <FormattedCurrency value={price} />;
  }
  return (
    <FormattedMessage
      id={messageId('currency.starting_at', __filenamespace)}
      values={{
        amount: (
          <FormattedCurrency value={collection.getMinimumPrice()} fromCents />
        ),
      }}
    />
  );
}

export default TeamPrice;
