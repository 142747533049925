import * as React from 'react';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import EventPrice from 'shared/components/EventPrice.jsx';

import BrowseEventsBookDrawerActions from 'containers/browseEventsBookDrawer/Actions.js';

const styles = {
  button: {
    height: 50,
  },

  twoColumnButtonLabel: {
    fontSize: 16,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

function ActionButton({
  label = '',
  onClick = () => {},
  isPurchased,
  disabled = false,
  style,
}) {
  return (
    <Button
      fullWidth
      variant="contained"
      disabled={disabled}
      color={isPurchased ? 'primary' : 'secondary'}
      style={merge(styles.button, style)}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

function PurchaseButton({
  event,
  automations,
  isAvailableForPurchase,
  isAvailableForWaitlist,
  isPurchased,
  onPurchaseClick,
  onWaitlistClick,
  sessions,
  style,
  intl,
}) {
  if (event.isClassSchedule()) {
    const deadlines = Map(
      event.schedules.map(s => [s.id, s.scheduling_deadline])
    );
    const allSessionsClosed = sessions.valueSeq().every(session => {
      const deadline = deadlines.get(session.schedule_id);

      return deadline
        ? moment().add(deadline, 'seconds').isSameOrAfter(session.starts_at)
        : false;
    });

    return (
      <ActionButton
        isPurchased
        label={t(
          allSessionsClosed ? '.closed' : '.reserve',
          intl,
          __filenamespace
        )}
        disabled={allSessionsClosed}
        onClick={() =>
          BrowseEventsBookDrawerActions.openDrawer({
            eventId: event.get('id'),
            eventTitle: event.get('title'),
            eventTypeColor: event.typeColor(),
            eventTypeName: event.get('event_type')
              ? event.get('event_type').name
              : '',
          })
        }
        style={style}
      />
    );
  }

  if (isAvailableForPurchase) {
    const purchasedLabel = (
      <FlexBoxJustify style={{ margin: '0 1em', width: '100%' }}>
        {isPurchased ? (
          <FormattedMessage id={messageId('.buy_more', __filenamespace)} />
        ) : (
          <FormattedMessage id={messageId('.purchase', __filenamespace)} />
        )}
        <EventPrice event={event} automations={automations} />
      </FlexBoxJustify>
    );

    return (
      <Button
        fullWidth
        variant="contained"
        color={isPurchased ? 'secondary' : 'primary'}
        sx={{ minHeight: '50px' }}
        onClick={onPurchaseClick}
      >
        {purchasedLabel}
      </Button>
    );
  }

  if (isAvailableForWaitlist) {
    const waitlistLabel = (
      <FlexBoxJustify style={{ margin: '0 1em', width: '100%' }}>
        <FormattedMessage
          id={messageId('.waiting_list_full', __filenamespace)}
        />
        <FormattedMessage
          id={messageId('.join_waiting_list', __filenamespace)}
        />
      </FlexBoxJustify>
    );

    return (
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        sx={{ minHeight: '50px' }}
        onClick={onWaitlistClick}
      >
        {waitlistLabel}
      </Button>
    );
  }

  return (
    <ActionButton
      disabled
      label={t('.event_unavailable', intl, __filenamespace)}
      style={style}
      isPurchased={isPurchased}
    />
  );
}

export default injectIntl(PurchaseButton);
