import { List, Set } from 'immutable';

import MembershipCreditPassDiscount from 'shared/records/MembershipCreditPassDiscount.jsx';
import MembershipRetailDiscount from 'shared/records/MembershipRetailDiscount.jsx';
import MembershipEventDiscount from 'shared/records/MembershipEventDiscount.jsx';

import { MEMBERSHIP_DISCOUNTS_TYPES } from 'shared/records/Membership.jsx';

export const getParams = (discount, membership) => {
  const allDiscounts = membership.get('membership_all_discounts', List());
  const creditPassDiscounts = allDiscounts.filter(
    d => d instanceof MembershipCreditPassDiscount
  );
  const retailDiscounts = allDiscounts.filter(
    d => d instanceof MembershipRetailDiscount
  );
  const eventDiscounts = allDiscounts.filter(
    d => d instanceof MembershipEventDiscount
  );

  switch (true) {
    case discount instanceof MembershipCreditPassDiscount:
      return {
        field: 'all_credit_passes',
        filedIds: 'credit_pass_ids',
        discountType: MEMBERSHIP_DISCOUNTS_TYPES.CREDIT_PASS,
        allValue: discount.all_credit_passes,
        options: [
          {
            label: 'All Passes',
            value: true,
            disabled:
              creditPassDiscounts.size > 1 &&
              creditPassDiscounts.some(d => d.all_credit_passes),
          },
          {
            label: 'Credit Passes',
            value: false,
          },
        ],
      };

    case discount instanceof MembershipRetailDiscount:
      return {
        field: 'all_retail_products',
        filedIds: 'retail_category_ids',
        discountType: MEMBERSHIP_DISCOUNTS_TYPES.RETAIL,
        allValue: discount.all_retail_products,
        options: [
          {
            label: 'All Products',
            value: true,
            disabled:
              retailDiscounts.size > 1 &&
              retailDiscounts.some(d => d.all_retail_products),
          },
          {
            label: 'Retail Category',
            value: false,
          },
        ],
      };

    default:
      return {
        field: 'all_events',
        filedIds: 'event_type_ids',
        discountType: MEMBERSHIP_DISCOUNTS_TYPES.EVENT,
        allValue: discount.all_events,
        options: [
          {
            label: 'All Events',
            value: true,
            disabled:
              eventDiscounts.size > 1 && eventDiscounts.some(d => d.all_events),
          },
          {
            label: 'Event Type(s)',
            value: false,
          },
        ],
      };
  }
};

export const getUnselectedItems = (
  membership,
  discountType,
  selectedField,
  allItems
) => {
  const selectedIds = membership.membership_all_discounts
    .filter(d => d instanceof discountType)
    .reduce((s, d) => s.union(d.get(selectedField) || Set()), Set());
  const unselectedItems = allItems.filter(item => !selectedIds.has(item.id));

  return unselectedItems;
};

export default { getParams, getUnselectedItems };
