import * as React from 'react';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { v1 as uuidv1 } from 'uuid';
import { List } from 'immutable';

import Button from '@mui/material/Button';

import uhTheme from '_uh_theme.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  Dates: {
    width: '100%',
  },
  Week: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'relative',
    width: '100%',
  },
  Day: {
    flex: '1',
    fontSize: '0.8667em',
    padding: 'calc(100% / 7) 0 0',
    position: 'relative',
    width: 'calc(100% / 7)',
  },
  DayButton: {
    borderRadius: '50%',
    fontSize: 'inherit',
    height: '90%',
    margin: '5%',
    minWidth: '0',
    left: '0',
    position: 'absolute',
    textAlign: 'center',
    top: '0',
    width: '90%',
  },
  DayButtonSelected: {
    backgroundColor: uhColors.activeBlue,
    borderRadius: '50%',
    color: '#fff',
  },
};

const weekdays = moment.weekdaysMin();

const weeksInMonth = currentDate => {
  const daysInMonth = currentDate.daysInMonth();
  let weeks = List();

  for (let i = 1; i <= daysInMonth; i += 1) {
    const day = currentDate.clone().date(i);
    const dayOfWeekOffset = day.weekday();

    if (dayOfWeekOffset === 0 || i === 1) {
      let week = List();
      let currentDayOfWeek = day.clone().subtract(dayOfWeekOffset, 'day');

      weekdays.forEach((weekday, weekdayIndex) => {
        week = week.set(weekdayIndex, currentDayOfWeek);
        currentDayOfWeek = currentDayOfWeek.clone().add(1, 'day');
      });

      weeks = weeks.push(week);
    }
  }

  return weeks;
};

function Week({
  currentDate,
  dayTooltip,
  onSelectDate,
  selectedDate,
  shouldSelectDate,
  weekDates,
}) {
  return (
    <div className="Week" style={styles.Week}>
      {weekDates.map(date => {
        const key = `Day-${uuidv1()}`;
        const differentMonth = !date.isSame(currentDate, 'month');
        let flagDay = date.isSame(selectedDate, 'day');
        if (typeof shouldSelectDate === 'function') {
          flagDay = shouldSelectDate(date);
        }
        if (differentMonth) {
          return <div key={key} style={styles.Day} />;
        }
        const hasTooltip =
          typeof dayTooltip === 'function' ? !!dayTooltip(date) : false;

        return (
          <div key={key} data-tip data-for={`${date}-tip`} style={styles.Day}>
            <Button
              variant="text"
              disabled={differentMonth || hasTooltip}
              sx={{
                '&:hover': { backgroundColor: uhTheme.palette.hoverColor },
                color: uhTheme.palette.textColor,
              }}
              onClick={e => {
                onSelectDate(e, date);
              }}
              style={merge(
                styles.DayButton,
                flagDay ? styles.DayButtonSelected : {}
              )}
            >
              {date.format('D')}
            </Button>
            {hasTooltip && (
              <ReactTooltip
                id={`${date}-tip`}
                className="uh-tooltip"
                effect="solid"
                disable={!dayTooltip(date)}
              >
                {dayTooltip(date)}
              </ReactTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
}

function Dates({
  currentDate,
  dayTooltip,
  onSelectDate,
  selectedDate,
  shouldSelectDate,
}) {
  const weeks = weeksInMonth(currentDate);

  return (
    <div style={styles.Dates}>
      {weeks.map(weekDates => (
        <Week
          key={`Week-${uuidv1()}`}
          currentDate={currentDate}
          onSelectDate={onSelectDate}
          selectedDate={selectedDate}
          shouldSelectDate={shouldSelectDate}
          dayTooltip={dayTooltip}
          weekDates={weekDates}
        />
      ))}
    </div>
  );
}

export default Dates;
