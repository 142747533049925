import moment from 'moment-timezone';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import DateRange, { THIS_YEAR } from 'shared/records/DateRange';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { MembershipSource } from 'sources';
import MrrReportActions from '../actions/MonthlyRecurringRevenueReportActions.jsx';

const INTERVALS = ['Weeks', 'Months'];
// 'Quarters' and 'Years' were originally included too.

const filterProperties = ['dateRange', 'membership_ids', 'interval'];

class MonthlyRecurringRevenueReportStore {
  constructor() {
    this.reset();

    this.bindListeners({
      mounted: MrrReportActions.MOUNTED,
      setMonthlyRecurringRevenueListResult:
        MrrReportActions.MONTHLY_RECURRING_REVENUE_LIST_RESULT_LOADED,
      setMonthlyRecurringRevenueListError:
        MrrReportActions.MONTHLY_RECURRING_REVENUE_LIST_RESULT_ERROR,

      fetchMembershipTypesList: MrrReportActions.MOUNTED,
      setMembershipTypesListResult:
        MrrReportActions.MEMBERSHIP_TYPES_LIST_RESULT_LOADED,
      setMembershipTypesListError:
        MrrReportActions.MEMBERSHIP_TYPES_LIST_RESULT_ERROR,

      // Commented until this is updated after initial version and ready to go.
      // fetchSpendingList: MrrReportActions.MOUNTED,
      setSpendingListResult: MrrReportActions.SPENDING_LIST_RESULT_LOADED,
      setSpendingListError: MrrReportActions.SPENDING_LIST_RESULT_ERROR,

      // Commented until this is updated after initial version and ready to go.
      // fetchAverageMembershipLengths: MrrReportActions.MOUNTED,
      setAverageMembershipLengthsResult:
        MrrReportActions.AVERAGE_MEMBERSHIP_LENGTHS_RESULT_LOADED,
      setAverageMembershipLengthsError:
        MrrReportActions.AVERAGE_MEMBERSHIP_LENGTHS_RESULT_ERROR,

      downloadMonthlyRecurringRevenueList:
        MrrReportActions.DOWNLOAD_MONTHLY_RECURRING_REVENUE_LIST,
      downloadMonthlyRecurringRevenueListSuccess:
        MrrReportActions.DOWNLOAD_MONTHLY_RECURRING_REVENUE_LIST_SUCCESS,
      downloadMonthlyRecurringRevenueListError:
        MrrReportActions.DOWNLOAD_MONTHLY_RECURRING_REVENUE_LIST_ERROR,

      downloadDetails: MrrReportActions.DOWNLOAD_DETAILS,
      downloadDetailsSuccess: MrrReportActions.DOWNLOAD_DETAILS_SUCCESS,
      downloadDetailsError: MrrReportActions.DOWNLOAD_DETAILS_ERROR,

      fetchDetailsList: MrrReportActions.FETCH_DETAILS_LIST,
      setDetailsListResult: MrrReportActions.DETAILS_LIST_RESULT_LOADED,
      setDetailsListError: MrrReportActions.DETAILS_LIST_RESULT_ERROR,
      hideDetails: MrrReportActions.HIDE_DETAILS,

      applyFilters: MrrReportActions.APPLY_FILTERS,
      clearFilters: MrrReportActions.CLEAR_FILTERS,
      updateFilters: MrrReportActions.FILTER_UPDATED,

      setMetricsPageParams: MrrReportActions.UPDATE_METRICS_PAGE_PARAMS,
      setDetailsPageParams: MrrReportActions.UPDATE_DETAILS_PAGE_PARAMS,
    });
  }

  reset() {
    this.monthlyRecurringRevenueListResult = [];
    this.monthlyRecurringRevenueListResultTransposed = [];
    this.membershipTypes = [];
    this.detailsListResult = [];
    this.spendingListResult = [];
    this.averageMembershipLengthsResult = [];
    this.detailLabel = '';
    this.detailPeriod = null;
    this.metricsPageParams = {
      page: 1,
      perPage: 10,
    };
    this.activeCell = {
      row: null,
      column: null,
    };

    this.detailsPageParams = {
      page: 1,
      perPage: 10,
    };

    this.isMonthlyRecurringRevenueListLoading = false;
    this.isMonthlyRecurringRevenueListDownloading = false;
    this.isDetailsDownloading = false;
    this.isSpendingListLoading = false;
    this.isAverageMembershipLengthsLoading = false;
    this.isMembershipTypesListLoading = false;

    this.intervals = INTERVALS;
    this.interval = 'Months';
    this.membershipType = 'all';
    this.rows = MonthlyRecurringRevenueReportStore.ROWS;
    this.columnsWithDetail = [
      'new_members_revenue',
      'cost_of_cancellations',
      'cost_of_suspensions',
      'reactivation_revenue',
      'expansion_contractions',
      'clients',
    ];

    this.initializeFilters();
  }

  mounted() {
    this.reset();

    this.fetchMonthlyRecurringRevenueList();
  }

  initializeFilters() {
    this.isApplyingFilter = false;
    this.appliedFilters = {};
    this.isFilterDirty = false;
    this.dateRange = new DateRange({ value: THIS_YEAR });
    this.membership_ids = [];

    filterProperties.forEach(p => {
      if (!['dateRange', 'interval'].includes(p)) {
        this[p] = null;
      }

      this.appliedFilters[p] = this[p];
    });

    this.interval = 'Months';

    this.updateStates();
  }

  fetchMonthlyRecurringRevenueList() {
    this.isMonthlyRecurringRevenueListLoading = true;
    this.updateStates();

    return uhApiClient.get({
      url: 'analytics/monthly_recurring_revenue_list',
      data: this.getParams(),
      success: MrrReportActions.monthlyRecurringRevenueListResultLoaded,
      error: MrrReportActions.monthlyRecurringRevenueListResultError,
    });
  }

  fetchMembershipTypesList() {
    this.isMembershipTypesListLoading = true;
    this.updateStates();

    return MembershipSource.list({
      params: {
        per_page: 50,
      },
      success: MrrReportActions.membershipTypesListResultLoaded,
      error: MrrReportActions.membershipTypesListResultError,
    });
  }

  setMembershipTypesListResult({ memberships }) {
    this.membershipTypes = memberships;
    this.isMembershipTypesListLoading = false;
    this.updateStates();
  }

  setMembershipTypesListError() {
    this.isMembershipTypesListLoading = false;
    this.updateStates();
  }

  setMonthlyRecurringRevenueListResult(data) {
    // The initial version of this UI was written to accept floats rather than values in minor units.
    // So, we will float divide every relevant value by 100.
    const floatRecords = data.map(record => ({
      ...record,
      cost_of_cancellations: (record.cost_of_cancellations / 100).toFixed(2),
      cost_of_suspensions: (record.cost_of_suspensions / 100).toFixed(2),
      expansion_contractions: (record.expansion_contractions / 100).toFixed(2),
      mrr: (record.mrr / 100).toFixed(2),
      net_mrr_change: (record.net_mrr_change / 100).toFixed(2),
      new_members_revenue: (record.new_members_revenue / 100).toFixed(2),
      reactivation_revenue: (record.reactivation_revenue / 100).toFixed(2),
    }));

    this.monthlyRecurringRevenueListResult = floatRecords;
    this.monthlyRecurringRevenueListResultTransposed =
      MonthlyRecurringRevenueReportStore.transpose(
        this.monthlyRecurringRevenueListResult
      );

    const totalPages = Math.ceil(floatRecords.length / 10);
    const percentageNow = moment();

    if (percentageNow.diff(this.dateRange.endTime) <= 0) {
      // clamp to [0, 1]
      const percentageThrough = Math.min(
        Math.max(
          percentageNow.diff(this.dateRange.startTime) /
            this.dateRange.endTime.diff(this.dateRange.startTime),
          0
        ),
        1
      );

      this.metricsPageParams = {
        // clamp to [1, totalPages]
        page: Math.min(
          Math.max(Math.floor(totalPages * percentageThrough), 1),
          totalPages
        ),
        perPage: 10,
      };
    } else {
      this.metricsPageParams = {
        page: totalPages,
        perPage: 10,
      };
    }

    this.isMonthlyRecurringRevenueListLoading = false;
    this.updateStates();
  }

  setMonthlyRecurringRevenueListError() {
    this.isMonthlyRecurringRevenueListLoading = false;
    this.updateStates();
  }

  fetchDetailsList([row, column]) {
    if (row === this.activeCell.row && column === this.activeCell.column) {
      return this.hideDetails();
    }

    const { detailType } = this.rows[row + 1];
    this.isDetailsLoading = true;
    this.detailLabel = this.rows[row + 1].label;

    const { page, perPage } = this.metricsPageParams;
    const paginatedColumn = (page - 1) * perPage + column;
    this.activeCell = { row, column, paginatedColumn };
    this.detailPeriod =
      this.monthlyRecurringRevenueListResultTransposed[0][paginatedColumn];

    this.detailsPageParams = {
      page: 1,
      perPage: 10,
    };
    this.updateStates();

    return uhApiClient.get({
      url: `analytics/${detailType}_details_list`,
      data: {
        ...this.getParams(),
        start_date: moment(this.detailPeriod)
          .tz(currentCustomer().tz_name)
          .format(),
        end_date: moment
          .min(
            moment(this.detailPeriod)
              .tz(currentCustomer().tz_name)
              .add(1, this.interval),
            this.appliedFilters.dateRange.endTime
          )
          .format(),
      },
      success: MrrReportActions.detailsListResultLoaded,
      error: MrrReportActions.detailsListResultError,
    });
  }

  setDetailsListResult(data) {
    this.detailsListResult = data;
    this.isDetailsLoading = false;
    this.updateStates();
  }

  setDetailsListError() {
    this.isDetailsLoading = false;
    this.updateStates();
  }

  fetchAverageMembershipLengths() {
    this.isAverageMembershipLengthsLoading = true;
    this.updateStates();

    return uhApiClient.get({
      url: 'analytics/average_membership_lengths',
      data: this.getParams(),
      success: MrrReportActions.averageMembershipLengthsResultLoaded,
      error: MrrReportActions.averageMembershipLengthsResultError,
    });
  }

  setAverageMembershipLengthsResult(data) {
    this.averageMembershipLengthsResult = data.records;
    this.isAverageMembershipLengthsLoading = false;
    this.updateStates();
  }

  setAverageMembershipLengthsError() {
    this.isAverageMembershipLengthsLoading = false;
    this.updateStates();
  }

  fetchSpendingList() {
    this.isSpendingListLoading = true;
    this.updateStates();

    return uhApiClient.get({
      url: 'analytics/member_vs_nonmember_spending',
      data: this.getParams(),
      success: MrrReportActions.spendingListResultLoaded,
      error: MrrReportActions.spendingListResultError,
    });
  }

  setSpendingListResult(data) {
    this.spendingListResult = data.records;
    this.isSpendingListLoading = false;
    this.updateStates();
  }

  setSpendingListError() {
    this.isSpendingListLoading = false;
    this.updateStates();
  }

  setMetricsPageParams(params) {
    this.metricsPageParams = params;
  }

  setDetailsPageParams(params) {
    this.detailsPageParams = params;
  }

  updateFilters([filter, value]) {
    this[filter] = value;

    if (filter === 'membershipType') {
      this.membership_ids = value === 'all' ? [] : [value];
    }

    this.applyFilters();
    this.updateStates();
  }

  hideDetails() {
    this.detailsListResult = [];
    this.detailLabel = '';
    this.detailPeriod = null;
    this.activeCell = {
      row: null,
      column: null,
    };
  }

  clearFilters() {
    filterProperties.forEach(p => {
      this[p] = null;
    });

    this.updateStates();
    this.applyFilters();
  }

  getParams() {
    const filters = this.appliedFilters;

    return {
      start_date: filters.dateRange.startTime.format(),
      end_date: filters.dateRange.endTime.format(),
      interval: filters.interval.toLowerCase(),
      membership_ids: filters.membership_ids,
    };
  }

  applyFilters() {
    this.appliedFilters = {};
    filterProperties.forEach(p => {
      this.appliedFilters[p] = this[p];
    });

    this.fetchMonthlyRecurringRevenueList();
    this.fetchMembershipTypesList();
  }

  updateStates() {
    this.isApplyingFilter = filterProperties.some(
      p => this.appliedFilters[p] !== this[p]
    );
    this.isApplyingFilter = [
      this.isMonthlyRecurringRevenueListLoading,
      this.isDetailsLoading,
      this.isSPendingListLoading,
      this.isAverageMembershipLengthsLoading,
    ].some(x => x);
    this.canAdjustFilter = !this.isApplyingFilter;
    this.canApplyFilter = this.canAdjustFilter && this.isFilterDirty;
    const hasFilter = filterProperties.some(p => this[p]);
    this.canClearFilter = this.canAdjustFilter && hasFilter;
  }

  downloadMonthlyRecurringRevenueList() {
    return uhApiClient.get({
      url: 'analytics/monthly_recurring_revenue_list_csv',
      headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
      data: this.getParams(),
      success: MrrReportActions.downloadMonthlyRecurringRevenueListSuccess,
      error: MrrReportActions.downloadMonthlyRecurringRevenueListError,
    });
  }

  downloadMonthlyRecurringRevenueListSuccess(data) {
    this.isMonthlyRecurringRevenueListDownloading = true;
    const a = document.createElement('a');
    a.style = 'display: none';

    document.body.appendChild(a);
    const blob = new Blob([data], { type: 'application/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'monthly_recurring_revenue_list.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    this.isMonthlyRecurringRevenueListDownloading = false;
  }

  downloadMonthlyRecurringRevenueListError() {
    this.isMonthlyRecurringRevenueListDownloading = false;
  }

  // Future to-do get this from the API?
  downloadDetails() {
    if (!this.detailsListResult.length) {
      return this.downloadDetailsError();
    }

    const columns = Object.keys(this.detailsListResult[0]);
    const data = [columns.join(',')]
      .concat(
        this.detailsListResult.map(row =>
          Object.values(row)
            .map(v => `"${v}"`)
            .join(',')
        )
      )
      .join('\n');
    return this.downloadDetailsSuccess(data);
  }

  downloadDetailsSuccess(data) {
    this.isDetailsDownloading = false;
    const a = document.createElement('a');
    a.style = 'display: none';

    document.body.appendChild(a);
    const blob = new Blob([data], { type: 'application/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${this.detailLabel} Details.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    this.isMonthlyRecurringRevenueListDownloading = false;
  }

  downloadDetailsError() {
    this.isDetailsDownloading = false;
  }

  static formatCurrency(intl, value) {
    const fractionDigits = value.includes('.00') ? 0 : 2;

    return intl.formatNumber(value, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }

  static formatPercentage(intl, value) {
    return intl.formatNumber(Number(value), {
      style: 'percent',
      minimumSignificantDigits: 2,
    });
  }

  static ROWS = [
    { column: 'Date', label: '', format: (_, v) => v },
    {
      column: 'new_members_revenue',
      detailType: 'new_member',
      label: 'New Members Revenue',
      format: MonthlyRecurringRevenueReportStore.formatCurrency,
    },
    {
      column: 'cost_of_cancellations',
      detailType: 'cancellation',
      label: 'Cost of Cancellations',
      format: MonthlyRecurringRevenueReportStore.formatCurrency,
    },
    {
      column: 'cost_of_suspensions',
      detailType: 'suspension',
      label: 'Cost of Suspensions',
      format: MonthlyRecurringRevenueReportStore.formatCurrency,
    },
    {
      column: 'reactivation_revenue',
      detailType: 'reactivation',
      label: 'Reactivation Revenue',
      format: MonthlyRecurringRevenueReportStore.formatCurrency,
    },
    {
      column: 'expansion_contractions',
      detailType: 'expansion_contractions',
      label: 'Expansions / Contractions',
      format: MonthlyRecurringRevenueReportStore.formatCurrency,
    },
    {
      column: 'net_mrr_change',
      bold: true,
      label: 'Net MRR Change',
      format: MonthlyRecurringRevenueReportStore.formatCurrency,
    },
    {
      column: 'mrr',
      style: {},
      label: 'MRR',
      format: MonthlyRecurringRevenueReportStore.formatCurrency,
    },
    {
      column: 'percentage_change',
      label: 'Percentage Change',
      format: MonthlyRecurringRevenueReportStore.formatPercentage,
    },
    {
      column: 'clients',
      detailType: 'clients',
      label: 'Clients',
      format: (_, v) => v,
    },
  ];

  static transpose(a) {
    if (!a.length) {
      return a;
    }

    return MonthlyRecurringRevenueReportStore.ROWS.map(r =>
      a.map(c => c[r.column])
    );
  }
}

export default alt.createStore(
  MonthlyRecurringRevenueReportStore,
  'MonthlyRecurringRevenueReportStore'
);
