import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Drawer } from '@upperhand/playmaker';

import Paginator from 'shared/components/Paginator.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

import { ClientDataStore } from 'dataStores';

import Content from 'containers/creditListDrawer/components/Content.jsx';
import CreditAdjustmentModal from 'containers/creditListDrawer/components/CreditAdjustmentModal.jsx';

import CreditListDrawerActions from 'containers/creditListDrawer/Actions';

import CreditListDrawerStore, {
  TABS,
} from 'containers/creditListDrawer/Store.js';

import './styles.scss';

function CreditListDrawer({
  creditListDrawerStore: {
    clientId,
    isOpen,
    adjustableCreditGrantId,
    activeTab,
    pagination,
  },
  clientDataStore: { clients },
  intl,
}) {
  const client = clients.get(clientId);

  if (!client) return null;

  const isGrantsActive = activeTab === TABS.CREDIT_GRANTS;
  const registrationsPagination = pagination.get('registrationPackages');
  const grantsPagination = pagination.get('creditGrants');
  const page = isGrantsActive
    ? grantsPagination.get('page')
    : registrationsPagination.get('page');
  const perPage = isGrantsActive
    ? grantsPagination.get('perPage')
    : registrationsPagination.get('perPage');
  const totalCount = isGrantsActive
    ? grantsPagination.get('totalCount')
    : registrationsPagination.get('totalCount');
  const paginationAction = isGrantsActive
    ? CreditListDrawerActions.listGrantsPrevNext
    : CreditListDrawerActions.listCountsPrevNext;
  const footer = totalCount > perPage && (
    <Paginator
      className="credit-list-drawer__tab-pagination"
      currentPage={page}
      perPage={perPage}
      totalCount={totalCount}
      onPageSelect={paginationAction}
      pageLimit={0}
      showInfo
    />
  );

  return (
    <>
      <Drawer
        classes={{
          root: 'credit-list-drawer',
        }}
        open={isOpen}
        onClose={CreditListDrawerActions.closeDrawer}
        headerType="light"
        title={t('.title', intl, __filenamespace)}
        content={<Content client={client} />}
        footer={footer}
      />
      <CreditAdjustmentModal
        intl={intl}
        creditGrantId={adjustableCreditGrantId}
      />
    </>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      creditListDrawerStore: CreditListDrawerStore,
      clientDataStore: ClientDataStore,
    },
  }),
  React.memo
)(CreditListDrawer);
