import * as React from 'react';
import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';

function DeleteConfirmationModal({
  awaitingConfirmation,
  cancelDelete,
  doDelete,
  name,
  title,
}) {
  return (
    <ConfirmationDialog
      awaitingConfirmation={awaitingConfirmation}
      onCancel={cancelDelete}
      onConfirm={doDelete}
      title={title}
    >
      {`Are you sure you want to remove this ${name}?`}
    </ConfirmationDialog>
  );
}

export default DeleteConfirmationModal;
