import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography, Button, Menu, Icon } from '@upperhand/playmaker';
import { useNavigate } from 'react-router-dom';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import Membership from 'shared/records/Membership.jsx';

import { t, messageId } from 'shared/utils/LocaleUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { isSimilarColor } from 'shared/utils/StyleUtils';

import './styles.scss';

function MembershipCard({ purchased, actions, readOnly, membership }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const historyLink = () => {
    navigate(customerScopedRoute(`/memberships/${membership.id}/edit`));
  };

  const { tiered } = membership;

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className="membership-card">
      {purchased && (
        <Icon
          name="triangleCheckmark"
          size="x-large"
          className="membership-card__purchased-icon"
        />
      )}
      {!readOnly && (
        <>
          <Button
            classes={{ root: 'membership-card__menu-btn' }}
            type="tertiary"
            icon="moreVert"
            rounded
            onClick={handleMenuOpen}
          />
          <Menu
            anchor={anchorEl}
            onClose={handleMenuClose}
            items={[
              {
                label: t('.edit', intl, __filenamespace),
                onClick: historyLink,
              },
            ]}
          />
        </>
      )}
      <div
        className="membership-card__info"
        style={{
          borderLeftColor: isSimilarColor(membership.color, '#393c44', 50)
            ? '#fff'
            : membership.color,
        }}
      >
        <div className="membership-card__info-wrapper">
          <div className="membership-card__info-wrapper-content">
            <Typography
              variant="subtitle1"
              className="membership-card__info-name"
            >
              {membership.name}
            </Typography>
          </div>
          {tiered && (
            <Typography
              variant="subtitle2"
              className="membership-card__info-tiered-pricing"
            >
              {t('.tiers_count', intl, __filenamespace, {
                count: membership.membership_tier_count,
              })}
            </Typography>
          )}
          {!tiered && (
            <Typography
              variant="subtitle2"
              className="membership-card__info-tiered-pricing"
            >
              {Boolean(membership.price) && (
                <>
                  <span className="bold-text">
                    <FormattedCurrency fromCents value={membership.price} />
                    &nbsp;
                  </span>
                  {t(
                    `.${membership.intervalMonths || 'one_time'}`,
                    intl,
                    __filenamespace
                  )}
                  {Boolean(membership.join_fee) && (
                    <FormattedMessage
                      id={messageId('.join_fee', __filenamespace)}
                      values={{
                        fee: (
                          <>
                            &nbsp;+&nbsp;
                            <span className="bold-text">
                              <FormattedCurrency
                                fromCents
                                value={membership.join_fee}
                              />
                            </span>
                          </>
                        ),
                      }}
                    />
                  )}
                </>
              )}
              {!membership.price && (
                <p className="bold-text">
                  <FormattedMessage id={messageId('.free', __filenamespace)} />
                </p>
              )}
            </Typography>
          )}
          <Typography className="membership-card__info-description">
            {membership.description}
          </Typography>
        </div>
      </div>
      <div className="membership-card__actions">{actions}</div>
    </div>
  );
}

MembershipCard.propTypes = {
  purchased: PropTypes.bool,
  actions: PropTypes.node,
  readOnly: PropTypes.bool,
  membership: PropTypes.instanceOf(Membership),
};

MembershipCard.defaultProps = {
  purchased: false,
  actions: null,
  readOnly: false,
  membership: new Membership(),
};

export default memo(MembershipCard);
