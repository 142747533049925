import { Map, Set } from 'immutable';
import RetailEditingActions from 'retail/actions/RetailEditingActions.js';
import RetailEditingStore from 'retail/stores/RetailEditingStore.js';
import StoreActions from 'shared/actions/StoreActions.jsx';
import VariantEditingActions from 'retail/actions/VariantEditingActions.js';
import Variant from 'shared/records/Variant.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { keyIn } from 'shared/utils/ImmutableUtils.js';
import { isNumber } from 'shared/utils/MathUtils.js';

class VariantEditingStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      reset: [
        StoreActions.PREPARE_FOR_REUSE,
        RetailEditingActions.PRODUCT_RESET,
      ],

      listVariants: [
        RetailEditingActions.FETCH,
        RetailEditingActions.SAVE_OR_UPDATE_SUCCESS,
        InventoryDialogActions.CREATE_PURCHASE_ORDER_SUCCESS,
      ],
      variantListSuccess: VariantEditingActions.VARIANT_LIST_SUCCESS,
      variantListError: VariantEditingActions.VARIANT_LIST_ERROR,

      updatePaginationOptions: VariantEditingActions.PAGINATION_OPTIONS_UPDATED,

      updateVariant: VariantEditingActions.VARIANT_FIELD_UPDATED,
      saveVariants: RetailEditingActions.VALIDATE_AND_SAVE,

      productSaveSuccess: RetailEditingActions.SAVE_OR_UPDATE_SUCCESS,
      variantsSaveSuccess: VariantEditingActions.VARIANTS_SAVE_SUCCESS,

      variantsSaveError: VariantEditingActions.VARIANTS_SAVE_ERROR,
    });
  }

  reset() {
    this.productId = null;
    this.variantMap = Map();
    this.dirtyVariantIds = Set();

    this.page = 1;
    this.perPage = 100;
    this.totalCount = 0;

    this.reloadVariantsOnSaveSuccess = false;
    this.isLoading = false;
  }

  listVariants(productId) {
    const checkedProductId = isNumber(productId)
      ? productId
      : this.productId || false;

    if (checkedProductId) {
      this.productId = checkedProductId;
      this.isLoading = true;

      uhApiClient.get({
        url: `products/${checkedProductId}/variants`,
        data: {
          fields: ['option_values', 'image', 'qty_on_hand'],
          page: this.page,
          per_page: this.perPage,
        },
        success: VariantEditingActions.variantListSuccess,
        error: VariantEditingActions.variantListError,
      });
    }
  }

  updatePaginationOptions([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listVariants(this.productId);
  }

  variantListSuccess({
    variants,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.isLoading = false;

    this.page = page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.variantMap = Map().withMutations(m =>
      variants.forEach(v => m.set(v.id, new Variant(v)))
    );
  }

  variantListError(...args) {
    this.isLoading = false;

    this.notifyError('error listing product variants', args);
  }

  updateVariant([variantId, field, value]) {
    this.variantMap = this.variantMap.update(variantId, v =>
      v.isValid() ? v.set(field, value) : v.set(field, value).validate()
    );

    this.dirtyVariantIds = this.dirtyVariantIds.add(variantId);
  }

  productSaveSuccess() {
    this.waitFor(RetailEditingStore);

    const { product } = RetailEditingStore.getState();

    if (this.reloadVariantsOnSaveSuccess || this.productId === null) {
      this.listVariants(product.id);
    }
  }

  saveVariants() {
    this.waitFor(RetailEditingStore);

    if (
      RetailEditingStore.getState().optionTypesDirty ||
      this.productId === null
    ) {
      // If the option types have been edited then our variants changes are no
      // longer valid.
      this.reloadVariantsOnSaveSuccess = true;
      return;
    }

    const variantsToUpdate = this.variantMap
      .filter(keyIn(this.dirtyVariantIds))
      .toList()
      .map(v => v.validate());

    let allValid = true;

    variantsToUpdate.forEach(v => {
      allValid = allValid && v.isValid();
      this.variantMap = this.variantMap.set(v.id, v);
    });

    if (allValid) {
      uhApiClient.patch({
        url: `products/${this.productId}/variants`,
        data: JSON.stringify({
          variants: variantsToUpdate.map(v => v.toServer()).toJS(),
          fields: ['option_values'],
        }),
        success: VariantEditingActions.variantsSaveSuccess,
        error: VariantEditingActions.variantsSaveError,
      });
    }
  }

  variantsSaveSuccess(data) {
    this.variantMap = this.variantMap.withMutations(m =>
      data.variants.forEach(v => m.set(v.id, new Variant(v)))
    );

    this.dirtyVariantIds = Set();
  }

  variantsSaveError(...args) {
    this.notifyError('error saving variants', args);
  }
}

export default alt.createStore(VariantEditingStore, 'VariantEditingStore');
